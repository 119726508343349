import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers'
import { ContextMenu, TMenuItemProps } from 'App/components'
import { showModalAction } from 'store'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'

import styles from './CaseMembersItemKebabMenu.module.scss'

export type TCaseMembersItemKebabMenuProps = {
  memberId: string
  active: boolean
  showModal: typeof showModalAction
}

type TParams = {
  id: string
}

export const CaseMembersItemKebabMenu = ({
  memberId,
  active,
  showModal
}: TCaseMembersItemKebabMenuProps) => {
  const {
    params: { id: caseId }
  } = useRouteMatch<TParams>()

  const { t } = useTranslation()

  const menuItems: TMenuItemProps[] = useMemo(
    () => [
      {
        content: t('cases.details.membersSection.kebabMenu.remove'),
        icon: <AccountRemoveOutlineIcon />,
        hidden: !active,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_REMOVE_MEMBER_CONFIRM,
            modalTitle: t('cases.modal.removeMemberConfirm.title_remove'),
            modalProps: {
              caseId,
              memberId,
              description: t('cases.modal.removeMemberConfirm.content_remove'),
              submitLabel: t('cases.modal.removeMemberConfirm.submitButton_remove')
            }
          })
      },
      {
        content: t('cases.details.membersSection.kebabMenu.withdraw'),
        icon: <TrashIcon />,
        hidden: active,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_REMOVE_MEMBER_CONFIRM,
            modalTitle: t('cases.modal.removeMemberConfirm.title_withdraw'),
            modalProps: {
              caseId,
              memberId,
              description: t('cases.modal.removeMemberConfirm.content_withdraw'),
              submitLabel: t('cases.modal.removeMemberConfirm.submitButton_withdraw')
            }
          })
      }
    ],
    [memberId, caseId, active, showModal, t]
  )

  return (
    <div className={styles.root}>
      <ContextMenu menuItems={menuItems} />
    </div>
  )
}
