import { useMemo } from 'react'
import { ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { FormattedText } from 'App/components'
import { TChatRoomLastMessage } from 'store'
import { getMapComponent } from 'utils'
import { IChatRoomsDTO } from 'interfaces'

import { TChatRoomItemP2PProps } from '../ChatRoomItemP2P'

import styles from './LastMessageText.module.scss'

type TLastMessageTextProps = Pick<TChatRoomItemP2PProps, 'unreadCount' | 'isLastMessageEditing'> &
  Pick<IChatRoomsDTO, 'draft' | 'lastMessage' | 'clearedUnixTs'> & {
    chatType: ChatTypeEnum

    chatName?: string | null
    caseTitle?: string
    interlocutorName?: string
    isSelected?: boolean
    showDraft?: boolean
  }

type TDefaultMessagePlaceholderProps = Pick<
  TLastMessageTextProps,
  'caseTitle' | 'interlocutorName' | 'chatName'
> & {
  t: TFunction
}

type TMessagePlaceholderProps = Pick<
  TChatRoomLastMessage,
  'message' | 'yourMessage' | 'mentionedAccounts' | 'mentionedDepartments'
> &
  Pick<TLastMessageTextProps, 'isSelected'> & {
    t: TFunction
  }

const DEFAULT_MESSAGE_PLACEHOLDER = new Map<
  ChatTypeEnum,
  ({ interlocutorName, caseTitle }: TDefaultMessagePlaceholderProps) => JSX.Element
>()
  .set(ChatTypeEnum.GROUP, ({ chatName, t }) => <>{t('chat.placeholder_group', { chatName })}</>)
  .set(ChatTypeEnum.CASE_GROUP, ({ caseTitle, t }) => (
    <>{t('chat.placeholder_case', { caseTitle })}</>
  ))
  .set(ChatTypeEnum.DIALOGUE, ({ interlocutorName, t }) => (
    <>{t('chat.placeholder_p2p', { interlocutorName })}</>
  ))

const MESSAGE_PLACEHOLDER = new Map<
  MessageTypeEnum,
  (props: TMessagePlaceholderProps) => JSX.Element
>()
  .set(MessageTypeEnum.TEXT, ({ message, mentionedAccounts, mentionedDepartments, isSelected }) => (
    <FormattedText
      text={message as string}
      mentions={{
        accounts: mentionedAccounts,
        departments: mentionedDepartments,
        clickable: false
      }}
      formatters={['emoji', 'mention']}
      classes={{ mention: cls({ [styles.mention]: isSelected }) }}
    />
  ))
  .set(MessageTypeEnum.PINNED_MESSAGE, ({ t }) => (
    <FormattedText text={t('chat.list.lastMessage.placeholder_pinned')} />
  ))
  .set(MessageTypeEnum.AUDIO, ({ t }) => <>{t('chat.list.lastMessage.placeholder_audio')}</>)
  .set(MessageTypeEnum.CALL, ({ yourMessage, t }) => (
    <>
      {yourMessage
        ? t('chat.list.lastMessage.placeholder_outgoingCall')
        : t('chat.list.lastMessage.placeholder_incomingCall')}
    </>
  ))
  .set(MessageTypeEnum.FILE, ({ t }) => <>{t('chat.list.lastMessage.placeholder_file')}</>)
  .set(MessageTypeEnum.DELETED, ({ t }) => <>{t('chat.list.lastMessage.placeholder_deleted')}</>)
  .set(MessageTypeEnum.SYSTEM, ({ message }) => <>{message}</>)
  .set(MessageTypeEnum.CHAT_REQUEST, ({ message, t }) => (
    <>{message ? message : t('chat.list.lastMessage.placeholder_chatRequest')}</>
  ))
  .set(MessageTypeEnum.MEETING, ({ t }) => <>{t('chat.list.lastMessage.placeholder_meeting')}</>)

export const LastMessageText = ({
  unreadCount,
  isLastMessageEditing,
  draft,
  lastMessage,
  caseTitle,
  interlocutorName,
  chatType,
  clearedUnixTs,
  chatName,
  isSelected,
  showDraft
}: TLastMessageTextProps) => {
  const { t } = useTranslation()

  const text = useMemo(() => {
    if (showDraft && (draft?.message || draft?.quoteMessage)) {
      return (
        <FormattedText
          text={draft?.message}
          mentions={{
            clickable: false,
            accounts: draft?.mentionedAccounts,
            departments: draft?.mentionedDepartments
          }}
          formatters={['emoji', 'mention']}
          classes={{ mention: cls({ [styles.mention]: isSelected }) }}
        />
      )
    }

    if (unreadCount) {
      return t('chat.list.lastMessage.placeholder_unread', { count: unreadCount })
    }

    if (isLastMessageEditing) {
      return t('chat.list.lastMessage.placeholder_editing')
    }

    if (lastMessage) {
      return getMapComponent(MESSAGE_PLACEHOLDER, lastMessage.type, {
        message: lastMessage.message,
        yourMessage: lastMessage.yourMessage,
        mentionedAccounts: lastMessage.mentionedAccounts,
        mentionedDepartments: lastMessage.mentionedDepartments,
        isSelected,
        t
      })
    }

    if (Boolean(clearedUnixTs)) {
      return t('chat.placeholder')
    }

    return getMapComponent(DEFAULT_MESSAGE_PLACEHOLDER, chatType, {
      caseTitle,
      interlocutorName,
      chatName,
      t
    })
  }, [
    draft?.message,
    draft?.quoteMessage,
    isLastMessageEditing,
    unreadCount,
    clearedUnixTs,
    draft?.mentionedAccounts,
    draft?.mentionedDepartments,
    chatType,
    lastMessage,
    caseTitle,
    interlocutorName,
    chatName,
    isSelected,
    showDraft,
    t
  ])

  return <>{text}</>
}
