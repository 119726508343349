import { useParams } from 'react-router'

import { useAppSelector } from 'store'
import { EventsView } from 'App/components/Events'

export type TEventsContentProps = {
  loading: boolean
}

export const EventsContent = ({ loading }: TEventsContentProps) => {
  const { id } = useParams<{ id?: string }>()

  const event = useAppSelector((state) => (id ? state.events.data.list[id] : undefined))

  if (!event) {
    return null
  }

  return <EventsView loading={loading} event={event} />
}
