import cls from 'classnames'

import { TProfileLink } from 'interfaces'
import { getMapComponent } from 'utils'

import { SOCIAL_ICON_MAP } from './UserProfileSocialLinks.utils'
import styles from './UserProfileSocialLinks.module.scss'

export type TUserProfileSocialLinksProps = PropsWithClassName<{
  links: Pick<TProfileLink, 'type' | 'url'>[]

  size?: 'md' | 'xs'
}>

export const UserProfileSocialLinks = ({
  links,
  className,
  size = 'md'
}: TUserProfileSocialLinksProps) => (
  <div className={cls(styles.root, className, styles[size])}>
    {links.map(({ type, url }, index) => (
      <a target="_blank" rel="noopener noreferrer" href={url} key={index} className={styles.item}>
        {getMapComponent(SOCIAL_ICON_MAP, type)}
      </a>
    ))}
  </div>
)
