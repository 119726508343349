import cls from 'classnames'

import styles from './Skeleton.module.scss'

export type TSkeletonProps = {
  className?: string
}

export const Skeleton = ({ className }: TSkeletonProps) => (
  <div className={cls(styles.root, className)} />
)
