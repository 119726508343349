import { memo } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'
import {
  AccountTypeNames,
  ContactStatusEnum,
  BusinessAccountRoleNameEnum,
  BusinessAccountStatusEnum,
  EventInviteStatusEnum,
  InviteStatusEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { formatLongTimeDate } from 'utils'
import { EAvatarSize } from 'enums'
import {
  AccountName,
  TAccountNameProps,
  Button,
  DisplayProfessionsSpecializations,
  StatusColored,
  IconLabel
} from 'App/components'
import { AvatarContainer } from 'App/containers'
import { ReactComponent as RemoveIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { ReactComponent as BusinessAccountIcon } from 'assets/icons/BusinessAccount.svg'
import { AvatarWrapper } from 'App/components/common/Avatar/AvatarWrapper'
import { useAdaptiveLayout } from 'App/hooks'
import { TAccountData } from 'store'
import { IEntityTime } from 'interfaces/api'

import styles from './InvitationItemSent.module.scss'

type TCreateAccount = Partial<
  Pick<
    TAccountData,
    'firstName' | 'lastName' | 'displayUserName' | 'type' | 'professions' | 'id' | 'email'
  >
>

export type TInvitationItemSentProps = Pick<IEntityTime, 'createdAt'> &
  Pick<TAccountNameProps, 'invitationEmail'> &
  PropsWithClasses<
    'root',
    {
      status:
        | ContactStatusEnum
        | BusinessAccountStatusEnum
        | InviteStatusEnum
        | EventInviteStatusEnum
      statusLabel: string

      loading?: boolean
      message?: string | null
      email?: string | null
      createdAccount?: TCreateAccount
      removeProcessing?: boolean
      withdrawProcessing?: boolean
      resendProcessing?: boolean
      variant: 'internal' | 'external'
      role?: BusinessAccountRoleNameEnum
      contactTypeName?: AccountTypeNames
      onRemove?: () => void
      onWithdraw?: () => void
      onResend?: () => void
      registeredAt?: string | Date
      deletedAt?: string | Date
    }
  >

const InvitationItemSentView = ({
  loading,
  status,
  statusLabel,
  removeProcessing,
  withdrawProcessing,
  resendProcessing,
  createdAt,
  invitationEmail,
  createdAccount,
  email,
  message,
  role,
  contactTypeName,
  classes,
  onWithdraw,
  onResend,
  onRemove,
  registeredAt,
  deletedAt,
  variant = 'internal'
}: TInvitationItemSentProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const {
    id: userId,
    firstName,
    lastName,
    displayUserName,
    type,
    professions = []
  } = createdAccount ?? {}

  const isStatusPending = status === ContactStatusEnum.PENDING
  const isStatusExpired = status === EventInviteStatusEnum.EXPIRED
  const isStatusLeft = status === EventInviteStatusEnum.LEFT
  const isRegisteredUser = variant === 'internal' || createdAccount
  const shouldShowActions = !isMobile || isStatusPending
  const accountName = (isRegisteredUser ? displayUserName : email) ?? ''
  const accountTypeName = isRegisteredUser ? type?.name : contactTypeName

  const getAvatar = (size = EAvatarSize.LG) => {
    if (firstName && lastName && displayUserName && userId) {
      return (
        <AvatarContainer
          userId={userId}
          size={size}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          type={accountTypeName}
          hasTooltip={false}
        />
      )
    }

    return (
      <AvatarWrapper size={size} className={styles.unregisteredAvatar} color={accountTypeName}>
        <EmailIcon />
      </AvatarWrapper>
    )
  }

  const getStatus = () => (
    <StatusColored status={status} statusLabel={statusLabel} className={styles.status} />
  )
  const getLabel = () => {
    if (isStatusLeft && deletedAt) {
      return t('invitations.leftAtLabel', { date: formatLongTimeDate(deletedAt) })
    }

    if (registeredAt) {
      return t('invitations.registeredAtLabel', { date: formatLongTimeDate(registeredAt) })
    }

    return t('invitations.invitedAtLabel', { date: formatLongTimeDate(createdAt) })
  }

  const getRemoveButton = () =>
    !isStatusPending &&
    onRemove && (
      <button
        type="button"
        className={styles.remove}
        onClick={onRemove}
        disabled={removeProcessing}
      >
        <span className={styles.removeText}>{t('invitations.removeButton')}</span> <RemoveIcon />
      </button>
    )

  const getDetails = () => (
    <div className={styles.details}>
      <div className={styles.account}>
        {isMobile && getAvatar(EAvatarSize.MD)}
        <AccountName
          size={EAvatarSize.MD}
          firstName={firstName}
          lastName={lastName}
          displayUserName={accountName}
          type={accountTypeName}
          showAvatar={false}
          invitationEmail={invitationEmail}
          classes={{ title: styles.name }}
        />
      </div>

      {isRegisteredUser && (
        <>
          <DisplayProfessionsSpecializations data={professions} />
          <div className={styles.email}>{createdAccount?.email}</div>
        </>
      )}

      {role && (
        <IconLabel
          classes={{ label: styles.label }}
          icon={<BusinessAccountIcon />}
          label={t('invitations.inviteToLabel', { context: role })}
        />
      )}

      <div className={styles.label}>{getLabel()}</div>
    </div>
  )
  const getActions = () => (
    <>
      {shouldShowActions && (
        <div className={styles.actions}>
          {!isMobile && (
            <>
              {getRemoveButton()}
              {getStatus()}
            </>
          )}

          {isStatusPending && onWithdraw && (
            <Button
              className={styles.button}
              size={EAvatarSize.SM}
              variant="outlined"
              disabled={withdrawProcessing}
              loading={withdrawProcessing}
              onClick={onWithdraw}
            >
              {t('contacts.invitations.withdrawButton')}
            </Button>
          )}

          {(isStatusPending || isStatusExpired || isStatusLeft) && onResend && (
            <Button
              className={styles.button}
              size={EAvatarSize.SM}
              variant="outlined"
              disabled={resendProcessing}
              loading={resendProcessing}
              onClick={onResend}
            >
              {t('invitations.resendButton')}
            </Button>
          )}
        </div>
      )}
    </>
  )
  const getMessage = () => <>{message && <p className={styles.message}>{message}</p>}</>

  return (
    <Skeleton
      className={styles.skeleton}
      loading={loading}
      avatar={{ size: 'large' }}
      active={true}
    >
      <div className={cls(styles.root, classes?.root)}>
        {isMobile && (
          <div className={styles.header}>
            {getStatus()}
            {getRemoveButton()}
          </div>
        )}

        {!isMobile && getAvatar()}

        {isMobile ? (
          <>
            {getDetails()} {getMessage()} {getActions()}
          </>
        ) : (
          <div className={styles.content}>
            <div className={styles.holder}>
              {getDetails()}
              {getActions()}
            </div>
            {getMessage()}
          </div>
        )}
      </div>
    </Skeleton>
  )
}

export const InvitationItemSent = memo(InvitationItemSentView)
