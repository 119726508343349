import { ICallCountsForWidgetDTO } from 'interfaces'

export const getMeetingsAmount = ({
  ongoingCalls,
  todayMeetings,
  tomorrowMeetings,
  activeVoiceRooms
}: ICallCountsForWidgetDTO) => {
  if (ongoingCalls || activeVoiceRooms) {
    const total = ongoingCalls + Number(activeVoiceRooms)

    return `${total} ongoing meeting${total > 1 ? 's' : ''}`
  }

  const today = todayMeetings ? `${todayMeetings} today` : ''
  const tomorrow = tomorrowMeetings ? `${tomorrowMeetings} tomorrow` : ''

  if (today && tomorrow) {
    return `${today}, ${tomorrow}`
  }

  if (today) {
    return today
  }

  if (tomorrow) {
    return tomorrow
  }

  return 'No Meetings yet'
}
