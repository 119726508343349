import { useCallback, useLayoutEffect, useRef } from 'react'
import cls from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'

import { SYSTEM_USER_ID } from 'globalConstants'
import { formatNotificationDate, longDateTime } from 'utils'
import {
  NotificationsP2PDetailsType,
  NotificationsP2PDetailsDescription,
  TSetRowHeight
} from 'App/components'
import { INotificationsProtocolDTO } from 'interfaces'

import styles from './NotificationsP2PDetailsRow.module.scss'

export type TNotificationsP2PDetailsRowProps = Omit<
  INotificationsProtocolDTO,
  'accompanyingMessage'
> & {
  setRowHeight: TSetRowHeight
  index: number
  hasNotifications: boolean

  className?: string
}

const VISIBILITY_HEIGHT = 72

export const NotificationsP2PDetailsRow = ({
  viewedProtocol,
  originatedFrom,
  createdAt,
  type,
  case: caseData,
  receiver,
  producer,
  payload,
  file,
  index,
  setRowHeight,
  className,
  hasNotifications
}: TNotificationsP2PDetailsRowProps) => {
  const rowWrapperRef = useRef<HTMLDivElement>(null)

  const handleResize = useCallback(() => {
    if (rowWrapperRef.current) {
      setRowHeight(index, rowWrapperRef.current.clientHeight)
    }
  }, [index, setRowHeight])

  useLayoutEffect(() => {
    const el = rowWrapperRef.current

    const resizeObserver = new ResizeObserver(handleResize)

    handleResize()
    el && resizeObserver.observe(el)

    return () => {
      el && resizeObserver.unobserve(el)
    }
  }, [handleResize])

  return (
    <div
      className={cls(
        styles.root,
        { [styles.rootHighlighted]: !viewedProtocol, [styles.rootShifted]: hasNotifications },
        className
      )}
      ref={rowWrapperRef}
    >
      <div className={styles.type}>
        <div className={styles.date}>{formatNotificationDate(createdAt, longDateTime)}</div>
        <NotificationsP2PDetailsType type={originatedFrom} />
      </div>

      <NotificationsP2PDetailsDescription
        type={type}
        file={file}
        payload={payload}
        receiver={receiver}
        caseId={caseData?.id}
        caseTitle={caseData?.title}
        isCaseOwner={receiver?.id === caseData?.ownerId}
        isSystem={producer?.id === SYSTEM_USER_ID}
        visibleHeight={VISIBILITY_HEIGHT}
        classes={{ root: styles.description }}
      />
    </div>
  )
}
