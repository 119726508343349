import { useTranslation } from 'react-i18next'

import { TContactsData } from 'store'
import { Button, StatusColored } from 'App/components/common'
import { ReactComponent as AccountRemoveIcon } from 'assets/icons/AccountRemove.svg'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import styles from './UserProfileContactWithdraw.module.scss'

export type TUserProfileContactWithdrawProps = Pick<TContactsData, 'status'> & {
  contactId: string
  onSuccess: () => void
}

export const UserProfileContactWithdraw = ({
  contactId,
  status,
  onSuccess
}: TUserProfileContactWithdrawProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { withdrawContactInvitation } = useContactInvitation()

  const handleClick = () => {
    withdrawContactInvitation({ contactId, onSuccess })
  }

  return (
    <div className={styles.root}>
      <StatusColored status={status} statusLabel={t(`enum.contactStatusEnum.${status}`)} />
      <Button
        size="sm"
        fontWeight="bold"
        variant={isDesktop ? 'outlined' : 'inline'}
        onClick={handleClick}
        icon={<AccountRemoveIcon />}
      >
        {t('contacts.invitations.withdrawButton')}
      </Button>
    </div>
  )
}
