import { useCallback, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { Location } from 'history'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { showModalAction } from 'store'

import { EModalComponents } from '../containers'

import { useRefValue } from './useRefValue'

export const useUnsavedDataPrompt = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [formChanged, setFormChanged] = useState<boolean>(false)

  const { getValue: getIgnoreUnsavedData, setValue: setIgnoreUnsavedData } = useRefValue(false)

  const onLeavePage = (location: Location) => {
    if (getIgnoreUnsavedData()) {
      return true
    }

    dispatch(
      showModalAction({
        modalTitle: t('modal.leavePageConfirm.title'),
        modalType: EModalComponents.LEAVE_PAGE_CONFIRM,
        modalProps: {
          location,
          onConfirm: () => {
            setFormChanged(false)
            return Promise.resolve()
          }
        }
      })
    )

    return false
  }

  const ignore = useCallback(() => setIgnoreUnsavedData(true), [setIgnoreUnsavedData])
  const unIgnore = useCallback(() => setIgnoreUnsavedData(false), [setIgnoreUnsavedData])

  const prompt = <Prompt message={onLeavePage} when={formChanged} />

  return {
    prompt,
    ignore,
    unIgnore,
    formChanged,
    setFormChanged
  }
}
