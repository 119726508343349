import { api, API } from 'services/api'
import { IInvitationsDataByLinkTokenDTO } from 'interfaces'
import { TGetUserProfileByIdResponse } from 'store/userProfile'

export const getDataByInvitationToken = async (token: string) => {
  const { data } = await api.get<IInvitationsDataByLinkTokenDTO>(
    API.INVITATIONS_DATA_BY_LINK_TOKEN(token)
  )

  return data
}

export const connectByLinkToken = async (token: string) =>
  await api.post(API.CONNECT_DATA_BY_LINK_TOKEN(token))

export type TSwitchAccountPayload = {
  accountId: string
}

export const switchAccount = async ({ accountId }: TSwitchAccountPayload) => {
  const response = await api.post(`${API.AUTH_URL}${API.USER_CHANGE}`, {
    accountId
  })

  return response
}

export const getUserProfile = async (id: string) => {
  const { data } = await api.get<TGetUserProfileByIdResponse>(API.USER_PROFILE_CONTACT_VIEW(id))

  return data
}
