import { IShowcaseItem } from 'interfaces'

export const parseResponseItems = ({
  categories,
  departments,
  shareOuterCategories,
  departmentsOuterOrganizations
}: Pick<
  IShowcaseItem,
  'categories' | 'departments' | 'shareOuterCategories' | 'departmentsOuterOrganizations'
>) => [
  ...(shareOuterCategories
    ? [{ id: 'no-category', name: 'No category', category: 'Contacts' }]
    : []),
  ...(categories?.map((item) => ({ ...item, category: 'Contacts' })) ?? []),
  ...departments.map((department) => ({ ...department, id: `department-${department.id}` })),
  ...departmentsOuterOrganizations.map((organization) => ({
    name: organization.displayUserName,
    id: `no-department-${organization.id}`
  }))
]

export const prepareRequestPayload = (ids: string[]) => {
  const {
    categoryIds,
    shareOuterCategories: shareOuterCategoriesFlag,
    departmentsOuterOrganizationIds,
    departmentIds
  } = ids.reduce<{
    categoryIds: string[]
    departmentIds: string[]
    shareOuterCategories: boolean
    departmentsOuterOrganizationIds: string[]
  }>(
    (res, item) => {
      if (item.startsWith('department-')) {
        const itemId = item.split('department-')[1]

        return {
          ...res,
          departmentIds: [...res.departmentIds, itemId]
        }
      }

      if (item.startsWith('no-department-')) {
        const itemId = item.split('no-department-')[1]

        return {
          ...res,
          departmentsOuterOrganizationIds: [...res.departmentsOuterOrganizationIds, itemId]
        }
      }
      if (item === 'no-category') {
        return {
          ...res,
          shareOuterCategories: true
        }
      }
      if (item.startsWith('organization-')) {
        const itemId = item.split('organization-')[1]

        return {
          ...res,
          departmentsOuterOrganizationIds: [...res.departmentsOuterOrganizationIds, itemId]
        }
      }

      return {
        ...res,
        categoryIds: [...res.categoryIds, item]
      }
    },
    {
      categoryIds: [],
      departmentIds: [],
      shareOuterCategories: false,
      departmentsOuterOrganizationIds: []
    }
  )
  return {
    categoryIds,
    departmentIds,
    departmentsOuterOrganizationIds,
    shareOuterCategories: shareOuterCategoriesFlag
  }
}
