import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg'

export type TToastCustomCloseButtonProps = {
  closeToast?: () => void
  onClick?: () => void
}

export const ToastCustomCloseButton = ({ closeToast, onClick }: TToastCustomCloseButtonProps) => {
  const handleClick = () => {
    if (closeToast) {
      closeToast()
    }

    if (onClick) {
      onClick()
    }
  }

  return <CloseIcon className="ToastCustomCloseButton" onClick={handleClick} />
}
