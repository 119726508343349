import uniq from 'lodash/uniq'
import { UploadSourcesEnum } from '@medentee/enums'
import axios from 'axios'

import { EFileUploadStatus } from 'enums'
import { medCloudFileNormalize } from 'store'
import { TIds } from 'store/store.types'

import {
  TMedCloudAction,
  TMedCloudState,
  TFilesToAttach,
  TFileUpload,
  TAttachSelectedCaseMedCloudFilesRequestPayload,
  TAttachSelectedChatMedCloudFilesRequestPayload,
  TAttachMedcloudFilesList
} from './medCloud.types'
import * as actionTypes from './medCloud.actionTypes'

export const initialMedCloudState: TMedCloudState = {
  upload: {
    fileQueue: [],
    filesInProgress: []
  },
  widget: {
    errors: {},
    ids: [],
    list: {},
    collapse: false,
    show: false
  },
  attachFiles: {
    uploadingFiles: [],
    selectedFiles: [],
    ids: [],
    list: {},
    total: 0,
    search: '',
    filters: {
      page: 0,
      directions: []
    }
  },
  filesToAttach: [],
  filters: {}
}

export const medCloudReducer = (
  state: TMedCloudState = initialMedCloudState,
  action: TMedCloudAction
): TMedCloudState => {
  const { CancelToken } = axios

  switch (action.type) {
    case actionTypes.SET_UPLOAD_FILE_STATUS: {
      const { id, status } = action.payload

      return {
        ...state,
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [id]: {
              ...state.widget.list[id],
              uploadStatus: status
            }
          }
        }
      }
    }

    case actionTypes.SET_UPLOAD_FILE_PERCENT: {
      const { id, percent } = action.payload
      return {
        ...state,
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [id]: {
              ...state.widget.list[id],
              uploadPercentage: percent
            }
          }
        }
      }
    }
    case actionTypes.SET_UPLOAD_FILE_PRE_SIGN_KEY: {
      const { id, key } = action.payload
      return {
        ...state,
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [id]: {
              ...state.widget.list[id],
              preSignKey: key
            }
          }
        }
      }
    }

    case actionTypes.SET_NEW_NAME_UPLOAD_FILE_IN_WIDGET: {
      const { name, id } = action.payload

      if (!state.widget.list[id]?.name) {
        return state
      }

      return {
        ...state,
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [id]: {
              ...state.widget.list[id],
              name
            }
          }
        }
      }
    }

    case actionTypes.UPLOAD_FILES_REQUEST: {
      const {
        files,
        onAfterUploadFile,
        uploadedFrom,
        chatId,
        caseId,
        removeOnCancel,
        isBroadcast,
        maxFileSize,
        onlyUpload
      } = action.payload
      const newFiles: TFileUpload[] = []
      const selectedFiles = [...state.attachFiles.selectedFiles]
      const uploadingFiles = [...state.attachFiles.uploadingFiles]

      files.forEach((item, index) => {
        const fileType = item.type
        const fakeFileId = `${index}-${item.size}-${Date.now()}`

        newFiles.push({
          uploadedFrom,
          onAfterUploadFile,
          cancelTokenSource: CancelToken.source(),
          uploadStatus: EFileUploadStatus.PENDING,
          uploadPercentage: 0,
          id: fakeFileId,

          lastModified: item.lastModified,
          type: fileType,
          size: item.size,
          name: item.name,
          file: item,
          selected: true,
          chatId,
          caseId,
          removeOnCancel,
          maxFileSize
        })

        if (!onlyUpload && (caseId || chatId || isBroadcast)) {
          selectedFiles.push(fakeFileId)
          uploadingFiles.push(fakeFileId)
        }
      })

      const { fileIds, fileList } = medCloudFileNormalize(newFiles)

      return {
        ...state,
        upload: {
          ...state.upload,
          fileQueue: [...state.upload.fileQueue, ...fileIds]
        },
        widget: {
          ...state.widget,
          show: true,
          ids: [...state.widget.ids, ...fileIds],
          list: {
            ...state.widget.list,
            ...fileList
          }
        },
        attachFiles: {
          ...state.attachFiles,
          uploadingFiles,
          selectedFiles
        }
      }
    }

    case actionTypes.UPLOAD_FILES_SUCCESS: {
      return {
        ...state
      }
    }

    case actionTypes.UPLOAD_FILES_MOVE_FILE_TO_PROGRESS: {
      const { id } = action.payload
      const fileQueue = state.upload.fileQueue.filter((item) => item !== id)
      const filesInProgress: TIds = [...state.upload.filesInProgress, id]

      return {
        ...state,
        upload: {
          ...state.upload,
          filesInProgress,
          fileQueue
        }
      }
    }

    case actionTypes.UPLOAD_FILES_REMOVE_FILE_FROM_PROGRESS: {
      const { id } = action.payload
      const filesInProgress = state.upload.filesInProgress.filter((itemId) => itemId !== id)

      return {
        ...state,
        upload: {
          ...state.upload,
          filesInProgress
        }
      }
    }

    case actionTypes.REMOVE_FILE_FROM_FOR_UPLOAD_LIST: {
      const { fakeId, id } = action.payload

      const widgetIds = id
        ? state.widget.ids.map((item) => (item === fakeId ? id : item))
        : state.widget.ids

      const widgetList = id
        ? {
            ...state.widget.list,
            [id]: {
              ...state.widget.list[fakeId],
              id
            }
          }
        : state.widget.list

      if (id && widgetList[fakeId]) {
        delete widgetList[fakeId]
      }

      const selectedFiles = id
        ? state.attachFiles.selectedFiles.map((item) => (item === fakeId ? id : item))
        : state.attachFiles.selectedFiles

      const uploadingFiles = id
        ? state.attachFiles.uploadingFiles.map((item) => (item === fakeId ? id : item))
        : state.attachFiles.uploadingFiles

      return {
        ...state,
        upload: {
          ...state.upload,
          filesInProgress: state.upload.filesInProgress.filter((item) => item !== fakeId),
          fileQueue: state.upload.fileQueue.filter((item) => item !== fakeId)
        },
        widget: {
          ...state.widget,
          ids: widgetIds,
          list: widgetList
        },
        attachFiles: {
          ...state.attachFiles,
          uploadingFiles,
          selectedFiles
        }
      }
    }

    case actionTypes.UPLOAD_FILES_COLLAPSE_WIDGET: {
      return {
        ...state,
        widget: {
          ...state.widget,
          collapse: state.widget.show ? action.payload.collapse : state.widget.collapse
        }
      }
    }

    case actionTypes.UPLOAD_FILES_CLOSE_WIDGET: {
      return {
        ...state,
        upload: initialMedCloudState.upload,
        widget: {
          ...state.widget,
          errors: {},
          ids: [],
          list: {},
          show: false,
          collapse: false
        }
      }
    }

    case actionTypes.GET_CHAT_MED_CLOUD_FILES_SUCCESS:
    case actionTypes.GET_CASE_MED_CLOUD_FILES_SUCCESS: {
      const { ids, list, total, filters } = action.payload

      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          ids,
          list,
          total
        },
        filters
      }
    }

    case actionTypes.SET_SELECTED_ATTACH_FILES: {
      const { ids } = action.payload

      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          selectedFiles: ids
        }
      }
    }

    case actionTypes.SELECT_ATTACH_FILE_ACTION: {
      const { fileId } = action.payload

      let selectedFiles = [...state.attachFiles.selectedFiles]

      if (selectedFiles.includes(fileId)) {
        selectedFiles = selectedFiles.filter((id) => id !== fileId)
      } else {
        selectedFiles.push(fileId)
      }

      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          selectedFiles: uniq(selectedFiles)
        },
        widget: {
          ...state.widget,
          list: {
            ...state.widget.list,
            [fileId]: state.widget.list[fileId]
              ? {
                  ...state.widget.list[fileId],
                  selected: !state.widget.list[fileId].selected
                }
              : state.widget.list[fileId]
          }
        }
      }
    }

    case actionTypes.SET_ATTACHED_FILES: {
      const { ids, isAttached = false } = action.payload

      const list: TAttachMedcloudFilesList = {}
      const selectedFiles = uniq([...state.attachFiles.selectedFiles, ...ids])

      state.attachFiles.ids.forEach((id) => {
        const item = state.attachFiles.list[id]

        if (item.id) {
          list[id] = ids.includes(id) ? { ...item, isAttached } : item
        }
      })

      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          selectedFiles,
          list
        }
      }
    }

    case actionTypes.ATTACH_SELECTED_CASE_MED_CLOUD_FILES_REQUEST:
    case actionTypes.ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_REQUEST: {
      const { chatId, caseId } = action.payload as TAttachSelectedCaseMedCloudFilesRequestPayload &
        TAttachSelectedChatMedCloudFilesRequestPayload // TODO: unobvious type merging

      const filesToAttach: TFilesToAttach[] = state.attachFiles.selectedFiles.reduce<
        TFilesToAttach[]
      >((acc, id) => {
        const widgetFile = state.widget.list[id]

        if (!widgetFile) {
          return acc
        }

        if (
          widgetFile.selected &&
          (widgetFile.chatId === chatId || widgetFile.caseId === caseId) &&
          (widgetFile.uploadStatus === EFileUploadStatus.PROGRESS ||
            widgetFile.uploadStatus === EFileUploadStatus.PENDING)
        ) {
          acc.push({
            id,
            chatId,
            caseId
          })
        }

        return acc
      }, [])

      return {
        ...state,
        filesToAttach,
        widget: {
          ...state.widget,
          collapse: false
        }
      }
    }

    case actionTypes.RESET_ATTACH_FILES: {
      return {
        ...state,
        attachFiles: {
          ...initialMedCloudState.attachFiles
        },
        filters: {}
      }
    }

    case actionTypes.REMOVE_FILE_FROM_WIDGET_ACTION: {
      const { fileId } = action.payload

      return {
        ...state,
        widget: {
          ...state.widget,
          ids: state.widget.ids.filter((item) => item !== fileId)
        },
        attachFiles: {
          ...state.attachFiles,
          uploadingFiles: state.attachFiles.uploadingFiles.filter((item) => item !== fileId),
          selectedFiles: state.attachFiles.selectedFiles.filter((item) => item !== fileId)
        }
      }
    }

    case actionTypes.SET_FAKE_FILE_IN_WIDGET: {
      const { onUpload, fileId, fileType, fileName, fromTrashBin = false } = action.payload

      const newFile: TFileUpload = {
        uploadedFrom: UploadSourcesEnum.CLOUD,
        cancelTokenSource: CancelToken.source(),
        uploadStatus: EFileUploadStatus.PROGRESS,
        uploadPercentage: 0,
        id: fileId,
        lastModified: Date.now(),
        type: fileType,
        size: 0,
        name: `${fileName}${fileType}`,
        selected: false,
        onUpload,
        fromTrashBin
      }

      return {
        ...state,
        widget: {
          ...state.widget,
          show: true,
          collapse: false,
          ids: uniq([...state.widget.ids, fileId]),
          list: {
            ...state.widget.list,
            [fileId]: newFile
          }
        }
      }
    }

    case actionTypes.MED_CLOUD_FILE_INVALIDATE: {
      const { id, errorType, message } = action.payload

      return {
        ...state,
        widget: {
          ...state.widget,
          errors: {
            ...state.widget.errors,
            [id]: {
              errorType,
              message
            }
          }
        }
      }
    }

    case actionTypes.SET_UPLOADING_START_DATE: {
      return {
        ...state,
        upload: {
          ...state.upload,
          startedAt: action.payload.date
        }
      }
    }

    case actionTypes.GET_CASE_LINKED_FILES_SUCCESS: {
      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          ...action.payload
        }
      }
    }

    case actionTypes.SET_CASE_LINKED_FILES_FILTER: {
      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          filters: {
            ...state.attachFiles.filters,
            ...action.payload
          }
        }
      }
    }

    case actionTypes.SET_CASE_LINKED_FILES_SEARCH: {
      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          search: action.payload.search
        }
      }
    }

    case actionTypes.RESET_CASE_LINKED_FILES_FILTER: {
      return {
        ...state,
        attachFiles: {
          ...state.attachFiles,
          filters: initialMedCloudState.attachFiles.filters
        }
      }
    }

    default:
      return state
  }
}
