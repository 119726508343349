import { ListHeader } from 'App/components/Organizations/InfoDrawer/Lists'

import { useToggle } from '../../../../hooks'

import { useKebabMenu } from './useKebabMenu'

type TListHeaderContainerProps = {
  total: number
  isStaff: boolean
  loading: boolean
  onRefresh: () => void
}

export const ListHeaderContainer = ({
  total,
  isStaff,
  loading,
  onRefresh
}: TListHeaderContainerProps) => {
  const { setValue, value, toggle } = useToggle()
  const { getMenuItems } = useKebabMenu({ isStaff, onClickInviteStaff: toggle, onRefresh })

  return (
    <ListHeader
      total={total}
      loading={loading}
      getMenuItems={getMenuItems}
      invitePopoverProps={{ visible: value, onVisibleChange: setValue }}
    />
  )
}
