import { createContext, FC, useState } from 'react'

export type TBroadcastContext = {
  broadcastRoomContainer: HTMLDivElement | null
  setBroadcastRoomContainer: (el: HTMLDivElement | null) => void
}
export const BroadcastContext = createContext<TBroadcastContext>({} as TBroadcastContext)

export const BroadcastContextProvider: FC = ({ children }) => {
  const [broadcastRoomContainer, setBroadcastRoomContainer] = useState<HTMLDivElement | null>(null)

  return (
    <BroadcastContext.Provider value={{ broadcastRoomContainer, setBroadcastRoomContainer }}>
      {children}
    </BroadcastContext.Provider>
  )
}
