import { useMemo } from 'react'
import cls from 'classnames'
import { MeetingInviteStatusEnum, MeetingStatusEnum } from '@medentee/enums'
import { Col, Row } from 'antd/lib/grid'
import { Trans, useTranslation } from 'react-i18next'

import {
  Actions,
  MeetingStatus,
  Duration,
  Label,
  Title,
  Participants
} from 'App/components/Chat/ChatMessageMeeting'
import { ChatMessageSenderName } from 'App/components/Chat/ChatMessageSenderName'
import { useAppSelector } from 'store'
import { formatDate, timeFormat } from 'utils'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'
import { AnchorifedText, useBusinessAccountMessageAuthor } from 'App/components'

import styles from './ChatMessageMeetingContainer.module.scss'

type TChatMessageMeetingContainerProps = {
  id: string

  isMessageOutgoing?: boolean
}

export const ChatMessageMeetingContainer = ({
  id,
  isMessageOutgoing
}: TChatMessageMeetingContainerProps) => {
  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const { chatId, meeting } =
    useAppSelector((state) => state.chat.chatMessages.messages.list[id]) || {}

  const { t } = useTranslation()

  const {
    startOn,
    endOn,
    organizer,
    originalOrganizer,
    title,
    status,
    invites,
    id: meetingId,
    location,
    type
  } = meeting ?? {}

  const isOrganizer = accountId === organizer?.id
  const isPendingMeeting = status === MeetingStatusEnum.PENDING
  const isOnlineMeeting = type === 'ONLINE'

  const incomingInviteStatus = useMemo(() => {
    if (isOrganizer) {
      return
    }

    return invites?.find(({ account }) => account.id === accountId)?.status
  }, [accountId, invites, isOrganizer])

  const shouldShowMeetingStatus =
    (status === MeetingStatusEnum.OPEN && isOnlineMeeting) ||
    status === MeetingStatusEnum.EXPIRED ||
    status === MeetingStatusEnum.CANCELLED ||
    (status === MeetingStatusEnum.REJECTED && isOrganizer)

  const shouldShowMeetingInviteActions =
    isPendingMeeting ||
    (status !== MeetingStatusEnum.EXPIRED &&
      status !== MeetingStatusEnum.CANCELLED &&
      !isOrganizer) ||
    (incomingInviteStatus === MeetingInviteStatusEnum.REJECTED &&
      !isOrganizer &&
      status !== MeetingStatusEnum.EXPIRED)

  const senderName = useBusinessAccountMessageAuthor(organizer, originalOrganizer)

  if (!startOn || !endOn || !status || !meetingId || !chatId) {
    return null
  }

  return (
    <div
      className={cls(styles.root, {
        [styles.disabled]: !isPendingMeeting,
        [styles.messageOutgoing]: isMessageOutgoing
      })}
    >
      <div className={styles.header}>
        <ChatMessageSenderName senderName={senderName} className={styles.senderName} />
        <Label className={styles.label} label={t('chat.message.meetingInvitation.title')} />
      </div>
      <Title
        className={styles.title}
        title={
          isOrganizer ? (
            <Trans
              t={t}
              i18nKey="chat.message.meetingInvitation.subtitle"
              components={{ span: <span className={styles.title} /> }}
              values={{ title }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="chat.message.meetingInvitation.subtitle_incoming"
              components={{ span: <span className={styles.title} /> }}
              values={{ title }}
            />
          )
        }
      />
      <Row gutter={[0, 12]}>
        <Col xs={24}>
          <div className={styles.timeInfo}>
            <div className={styles.date}>
              <ScheduleIcon />
              <span>
                {formatDate(startOn, 'eeee, MMM d · ')}
                {formatDate(startOn, timeFormat)} - {formatDate(endOn, timeFormat)}
              </span>
            </div>
            <Duration
              className={styles.duration}
              startOn={startOn}
              endOn={endOn}
              disabled={!isPendingMeeting}
            />
            {location && (
              <div className={styles.location}>
                <LocationIcon />
                <AnchorifedText text={location} />
              </div>
            )}
          </div>
        </Col>

        {shouldShowMeetingInviteActions && (
          <Col xs={24}>
            {!isOrganizer ? (
              <Actions id={meetingId} status={incomingInviteStatus} />
            ) : (
              <Participants
                chatId={chatId}
                invites={invites}
                isMessageOutgoing={isMessageOutgoing}
              />
            )}
          </Col>
        )}

        {shouldShowMeetingStatus && (
          <Col xs={24}>
            <MeetingStatus status={status} variant={isMessageOutgoing ? 'light' : 'default'} />
          </Col>
        )}
      </Row>
    </div>
  )
}
