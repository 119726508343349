import { useMemo, useState } from 'react'
import { Field, Form, FormSpy } from 'react-final-form'
import { Row, Col, Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { IDepartment } from 'interfaces'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'
import { originalAccountIdSelector, useAppSelector } from 'store'
import { isMatchErrorCode } from 'utils/errorParsing'

import { Button } from '../../../components'
import { DepartmentMainFields } from '../DepartmentMainFields'
import { updateDepartment, handleError } from '../../../../api'
import { ManageDepartmentStaff } from '../ManageDepartmentStaff'
import { ManageDepartmentLead } from '../ManageDepartmentLead'

import styles from './EditDepartment.module.scss'

export type TEditDepartmentProps = {
  data?: IDepartment
  loading?: boolean
}

export const EditDepartment = ({ data, loading }: TEditDepartmentProps) => {
  const { replace } = useHistory()
  const { prompt, setFormChanged, ignore } = useUnsavedDataPrompt()

  const { t } = useTranslation()

  const [nameError, setNameError] = useState('')

  const {
    mutate,
    isLoading,
    data: savedDepartment
  } = useMutation({
    mutationKey: ['update-department'],
    mutationFn: updateDepartment,
    onSuccess: () => setNameError(''),
    onError: (error) => {
      if (isMatchErrorCode(error, ErrorCodesEnum.DEPARTMENT_WITH_SAME_NAME_EXIST)) {
        setNameError(t('serverError.DEPARTMENT_WITH_SAME_NAME_EXIST', { ns: 'errors' }))
        return
      }

      if (isMatchErrorCode(error, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        ignore()
        replace('/organization/administration')
      }

      setNameError('')
      handleError(error)
    }
  })

  const accountId = useAppSelector(originalAccountIdSelector)

  const initialValues = useMemo<IDepartment | undefined>(() => {
    const initialData = savedDepartment ?? data
    const leader = initialData?.leader

    return initialData
      ? {
          ...initialData,
          leader: leader
            ? {
                ...leader,
                displayUserName:
                  leader.id === accountId
                    ? t('organizations.editDepartment.leaderLabel', {
                        name: leader?.displayUserName
                      })
                    : leader.displayUserName
              }
            : undefined
        }
      : undefined
  }, [accountId, data, savedDepartment, t])

  const onSubmit = ({
    id,
    name,
    description,
    staffs,
    leader,
    organization: { id: organizationId }
  }: IDepartment) =>
    mutate({
      id,
      name,
      organizationId,
      leaderId: leader?.id ?? null,
      description: description || '',
      accountsIds: staffs.length ? staffs.map(({ id: staffId }) => staffId) : []
    })

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, valid, dirty, values, form: { change } }) => (
          <Row gutter={[0, 28]}>
            {data && !loading && !isLoading ? (
              <>
                <DepartmentMainFields nameError={nameError} classes={{ name: styles.name }} />
                <FormSpy
                  subscription={{ dirty: true }}
                  onChange={(state) => setFormChanged(state.dirty)}
                />
                <Col xs={24}>
                  <Field name="staffs">
                    {({ input }) => (
                      <ManageDepartmentStaff
                        accounts={input.value}
                        organizationId={data.organization.id}
                        onChange={(value) => {
                          input.onChange(value)
                          if (values.leader && !value.find(({ id }) => id === values.leader?.id)) {
                            change('leader', undefined)
                          }
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={24}>
                  <Field name="leader">
                    {({ input }) => (
                      <ManageDepartmentLead
                        account={input.value}
                        organizationId={data.organization.id}
                        onChange={(value) => input.onChange(value)}
                      />
                    )}
                  </Field>
                </Col>
              </>
            ) : (
              <Skeleton className={styles.skeleton} title={false} paragraph={{ rows: 5 }} />
            )}
            <Col xs={24} style={{ marginTop: 'auto' }}>
              <Row justify="center">
                <Button
                  loading={isLoading}
                  disabled={!valid || !dirty || isLoading}
                  onClick={handleSubmit}
                >
                  {t('organizations.editDepartment.submitButton')}
                </Button>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
      {prompt}
    </div>
  )
}
