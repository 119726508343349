import { useCallback } from 'react'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { useTranslation } from 'react-i18next'

import { EToggleCallOrSoundType } from 'enums'
import {
  SettingsItemDescription,
  SettingsItemSubtitle,
  SettingsItemTitle,
  Switch
} from 'App/components'
import { TCallsContainerProps } from 'App/containers'

export type TCallsProps = TCallsContainerProps

export const Calls = ({ rejectCalls, toggleCallsOrSounds }: TCallsProps) => {
  const { t } = useTranslation()

  const handleChange: SwitchChangeEventHandler = useCallback(
    (checked) => {
      toggleCallsOrSounds({ type: EToggleCallOrSoundType.REJECT_CALLS, currentStatus: checked })
    },
    [toggleCallsOrSounds]
  )

  return (
    <>
      <SettingsItemTitle>{t('settings.notifications.calls.title')}</SettingsItemTitle>
      <SettingsItemSubtitle addon={<Switch onChange={handleChange} checked={rejectCalls} />}>
        {t('settings.notifications.calls.rejectIncomingCalls')}
      </SettingsItemSubtitle>

      <SettingsItemDescription>
        {t('settings.notifications.calls.rejectIncomingCalls_description')}
      </SettingsItemDescription>
    </>
  )
}
