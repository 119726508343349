import { useCallback } from 'react'

import { TIds } from 'store'

const MAX_SELECTED_IDS = 2

export const useListContextMenu = (ids: TIds) => {
  const shouldShowHeadContextmenu = ids.length >= MAX_SELECTED_IDS

  const shouldShowContextMenu = useCallback(
    (id: string) => !shouldShowHeadContextmenu || !ids.includes(id),
    [ids, shouldShowHeadContextmenu]
  )

  return {
    shouldShowHeadContextmenu,
    shouldShowContextMenu
  }
}
