import cls from 'classnames'

import { getMapComponent } from 'utils'
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'

import styles from './ItemInfoLabel.module.scss'

export type TItemInfoLabelType = 'location' | 'looking'
export type TItemInfoLabelProps = PropsWithClassName & {
  text: string
  labelType: TItemInfoLabelType
}

const LABEL_MAP = new Map<TItemInfoLabelType, () => JSX.Element>()
  .set('location', () => <span className={cls(styles.label, styles.location)}>Based in:</span>)
  .set('looking', () => <span className={cls(styles.label, styles.looking)}>Looking for:</span>)

const ICON_MAP = new Map<TItemInfoLabelType, () => JSX.Element>()
  .set('location', () => <LocationIcon className={styles.icon} />)
  .set('looking', () => <AccountIcon className={styles.icon} />)

export const ItemInfoLabel = ({ className, text, labelType }: TItemInfoLabelProps) => (
  <div className={cls(styles.root, className)}>
    {getMapComponent(LABEL_MAP, labelType)}
    <div className={styles.description}>
      {getMapComponent(ICON_MAP, labelType)}
      {text}
    </div>
  </div>
)
