import { FC, useMemo } from 'react'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { AccountsSelect } from '../../common'
import { TAccountsSelectOptionData } from '../../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

export type TMembersSelectProps = {
  members: TAccountsSelectOptionData[]
  selectedMembers: TAccountsSelectOptionData[]
  onBack: () => void
  onClear: () => void
  onSubmit: () => void
  onSelect: (value: TAccountsSelectOptionData) => void
  onUnselect: (id: string) => void
  onSelectAll: (value: TAccountsSelectOptionData[]) => void

  search?: string
  loading?: boolean
  submitDisabled?: boolean
  onSearch?: (value: string) => void
}

export const MembersSelect: FC<TMembersSelectProps> = (props) => {
  const {
    members,
    selectedMembers,
    loading,
    submitDisabled,
    onSubmit,
    onSelect,
    onUnselect,
    onClear,
    onBack,
    onSelectAll,
    onSearch,
    search = ''
  } = props

  const { t } = useTranslation()

  const availableMembers = useMemo(
    () =>
      members.filter((item) => item.displayUserName.toLowerCase().includes(search.toLowerCase())),
    [members, search]
  )

  const handleSelectAll = () => {
    onSelectAll(members)
  }

  return (
    <Col xs={24}>
      <AccountsSelect
        search={search}
        items={availableMembers}
        selectedItems={selectedMembers}
        submitDisabled={submitDisabled}
        loading={loading}
        submitLabel={t('modal.scheduleMeeting.detailsStep.inviteMembers.submitButton')}
        selectTitle={t('modal.scheduleMeeting.detailsStep.inviteMembers.selectTitle')}
        emptyListText={t('modal.scheduleMeeting.detailsStep.inviteMembers.placeholder')}
        emptyContentText={t(
          'modal.scheduleMeeting.detailsStep.inviteMembers.selectedListPlaceholder'
        )}
        onSelectAll={handleSelectAll}
        onUnselect={onUnselect}
        onUnselectAll={onClear}
        onSubmit={onSubmit}
        onClickBack={onBack}
        onSearch={onSearch}
        onSelect={onSelect}
      />
    </Col>
  )
}
