import { PortalWrapper } from 'App/components'
import { FileUploadWidgetContainer } from 'App/containers'

import styles from './WidgetsWrapper.module.scss'

export const WidgetsWrapper = () => (
  <PortalWrapper portalCssSelector="widgets-wrapper-portal" className={styles.root}>
    <FileUploadWidgetContainer />
  </PortalWrapper>
)
