import { useState, useRef, useLayoutEffect, ReactNode } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { stopPropagation } from 'utils'

import { AnchorifedText, TAnchorifedTextProps } from '../AnchorifedText'

import styles from './ShowMore.module.scss'

type TShowMoreClasses = 'root' | 'wrapper'

export type TShowMoreProps = Pick<TAnchorifedTextProps, 'nonUrlPartsRenderer'> & {
  text: ReactNode
  visibleHeight?: number
  classes?: Partial<Record<TShowMoreClasses, string>>
  limitExpandedHeight?: boolean
}

const VISIBLE_HEIGHT = 42

export const ShowMore = ({
  text,
  classes,
  nonUrlPartsRenderer,
  visibleHeight = VISIBLE_HEIGHT,
  limitExpandedHeight = true
}: TShowMoreProps) => {
  const { t } = useTranslation()

  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isOverflow, setIsOverflow] = useState(false)

  const wrapperRef = useRef<HTMLDivElement>(null)

  const isTextString = typeof text === 'string'

  const toggleCollapsingState = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopPropagation(e)
    setIsCollapsed((prev) => !prev)
  }

  useLayoutEffect(() => {
    if (!wrapperRef.current) {
      return
    }

    const wrapperHeight = wrapperRef.current.getBoundingClientRect().height

    if (wrapperHeight > visibleHeight) {
      setIsOverflow(true)
    }

    // `text` property is essential here
  }, [visibleHeight, text])

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = 0
    }
  }, [isCollapsed])

  const getContent = () =>
    typeof text === 'string' ? (
      <AnchorifedText nonUrlPartsRenderer={nonUrlPartsRenderer} text={text} />
    ) : (
      <>{text}</>
    )

  if (!text) {
    return null
  }

  return (
    <div className={cls(styles.root, classes?.root)}>
      <div
        className={cls(
          'show-more-wrapper',
          styles.wrapper,
          !isCollapsed && styles.wrapperExpanded,
          !isCollapsed && limitExpandedHeight && styles.wrapperExpandedLimited,
          isTextString && styles.wrapperText,
          classes?.wrapper
        )}
        style={{ maxHeight: isOverflow ? visibleHeight : 'unset' }}
        ref={wrapperRef}
      >
        {getContent()}
      </div>
      {isOverflow && (
        <button
          className={cls(styles.button, {
            [styles.buttonExpanded]: !isCollapsed
          })}
          onClick={toggleCollapsingState}
        >
          {isCollapsed ? t('common.showMore.more') : t('common.showMore.less')} <ChevronDownIcon />
        </button>
      )}
    </div>
  )
}
