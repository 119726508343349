import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import {
  createProcessingBySelector,
  setCaseCloudContactPermissionRequest,
  SET_CASE_CLOUD_CONTACT_PERMISSION
} from 'store'
import { FileContactItem } from 'App/components'

export type TCaseCloudContactPermissionItemContainerOwnProps = {
  fileId: string
  userId: string
  caseId: string
}

const processingSelector = createProcessingBySelector([SET_CASE_CLOUD_CONTACT_PERMISSION])

const mapStateToProps = (
  state: State,
  { fileId, caseId, userId }: TCaseCloudContactPermissionItemContainerOwnProps
) => {
  const { id, firstName, lastName, companyName, type, displayUserName } =
    state.caseCloud.permissionContacts.list[userId] ?? {}

  const permissions = state.caseCloud.permissionContacts.permissions[id] ?? []

  return {
    contactUserId: id,
    fileId,
    caseId,
    firstName,
    lastName,
    companyName,
    type,
    processing: processingSelector(id)(state),
    permissions,
    displayUserName,
    permissionsMode: state.caseView.data?.permissionsMode
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeUserPermission: setCaseCloudContactPermissionRequest
    },
    dispatch
  )

export const CaseCloudContactPermissionItemContainer: FC<TCaseCloudContactPermissionItemContainerOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(FileContactItem)
