import { FC, ReactNode } from 'react'
import cls from 'classnames'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'

import { Tooltip } from 'App/components'
import { EAvatarSize } from 'enums'
import { useSetShadow } from 'App/hooks'

import styles from './AvatarWrapper.module.scss'

export type TAvatarWrapperProps = PropsWithClassName<{
  size: EAvatarSize

  online?: boolean
  onlineBorderColor?: string
  onlineBorderCssRootPropertyValue?: string
  border?: boolean
  color?: AccountTypeNames | ChatTypeEnum
  tooltip?: ReactNode | null
  muted?: boolean
  onClick?: () => void
}>

export const AvatarWrapper: FC<TAvatarWrapperProps> = ({
  size,
  tooltip,
  className,
  children,
  onlineBorderColor,
  onlineBorderCssRootPropertyValue,
  onClick,
  online = false,
  border = false,
  muted = false,
  color = AccountTypeNames.PROFESSIONAL
}) => {
  const onlineRef = useSetShadow({
    shadowSize: size === EAvatarSize.XS || size === EAvatarSize.XXS ? 2 : 3,
    shadowColor: onlineBorderColor,
    cssRootPropertyValue: onlineBorderCssRootPropertyValue
  })

  return (
    <Tooltip title={tooltip}>
      <div
        onClick={onClick}
        className={cls(styles.root, styles[size], styles[color], className, {
          [styles.border]: border,
          [styles.muted]: muted,
          [styles.clickable]: Boolean(onClick)
        })}
      >
        {children}

        <span
          ref={onlineRef}
          className={cls(styles.online, {
            [styles.show]: online
          })}
        />
      </div>
    </Tooltip>
  )
}
