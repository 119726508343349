import { useCallback, useMemo } from 'react'
import { FileExtensionCategoryEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useAdaptiveLayout } from 'App/hooks'
import { EFilterTypes, TFiltersProps, useFilters } from 'App/components'
import { TCaseMemberCloudContainerProps } from 'App/containers'

import styles from './Filter.module.scss'

export type TFilterProps = Pick<
  TCaseMemberCloudContainerProps,
  'resetCaseCloudFilters' | 'setCaseCloudFilters' | 'filters' | 'caseView'
> & {
  disabled?: boolean
}

export const Filter = ({
  disabled,
  caseView,
  filters: filterValue,
  resetCaseCloudFilters,
  setCaseCloudFilters
}: TFilterProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleFilterChange: TFiltersProps['onChange'] = useCallback(
    (key, value) => setCaseCloudFilters({ [key]: value }),
    [setCaseCloudFilters]
  )

  const filterItems = useMemo<TFiltersProps['filters']>(
    () => [
      {
        title: t(`cases.details.attachments.filter.fileCategories`),
        sectionKey: 'extensionCategories',
        items: [
          {
            type: EFilterTypes.CHECKBOX,
            options: Object.values(FileExtensionCategoryEnum).map((value) => ({
              label: t(`enum.fileExtensionCategoryEnum.${value}`),
              value
            })),
            filterKey: 'extensionCategories'
          }
        ]
      }
    ],
    [t]
  )

  const { filters } = useFilters({
    popover: true,
    tooltip: t(`cases.details.attachments.filter.tooltip`),
    filters: filterItems,
    value: filterValue,
    buttonDisabled: disabled,
    buttonVariant: 'inline',
    classes: { popover: styles.popover },
    innerPopoverProps: {
      getPopupContainer: (node) => (isDesktop && caseView === 'expanded' ? node : document.body)
    },
    onReset: resetCaseCloudFilters,
    onChange: handleFilterChange
  })

  return <>{filters}</>
}
