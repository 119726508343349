import { FC } from 'react'
import AntdCollapse, { CollapseProps } from 'antd/lib/collapse/Collapse'
import cls from 'classnames'

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'

import styles from './Collapse.module.scss'

export type TCollapseProps = CollapseProps & { variant?: 'highlighted' }

export const Collapse: FC<TCollapseProps> = ({
  children,
  variant,
  className = '',
  accordion = true,
  ...props
}) => (
  <AntdCollapse
    accordion={accordion}
    destroyInactivePanel={true}
    className={cls({
      [styles.root]: true,
      [className]: !!className,
      [styles.highlighted]: variant === 'highlighted'
    })}
    expandIconPosition="right"
    expandIcon={({ isActive }) => (
      <ChevronDownIcon className={cls({ [styles.active]: isActive })} />
    )}
    {...props}
  >
    {children}
  </AntdCollapse>
)
