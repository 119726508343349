import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './UnityHubLayout.module.scss'

export type TUnityHubLayoutProps = PropsWithChildren<PropsWithClasses<'container' | 'root'>>

export const UnityHubLayout = ({ children, classes }: TUnityHubLayoutProps) => (
  <div className={cls(styles.container, classes?.container)}>
    <div className={cls(styles.root, classes?.root)}>{children}</div>
  </div>
)
