import cls from 'classnames'

import { TChatMessageAudioContainerProps } from 'App/containers'
import {
  ChatMessageAudioListen,
  TChatMessageAudioListenProps,
  ChatQuotedMessage
} from 'App/components'

import { ChatMessageSenderName } from '../ChatMessageSenderName/ChatMessageSenderName'

import styles from './ChatMessageAudio.module.scss'

export type TChatMessageAudioBaseProps = {
  id: string
  chatId: string
  showUserName?: boolean
}
export type TChatMessageAudioProps = TChatMessageAudioBaseProps &
  TChatMessageAudioContainerProps &
  Pick<TChatMessageAudioListenProps, 'chatId'>

export const ChatMessageAudio = ({
  quoteMessage,
  quoteSender,
  senderName,
  isMessageOutgoing,
  durationSec,
  audioFileId,
  chatId
}: TChatMessageAudioProps) => {
  if (!audioFileId) {
    return null
  }

  return (
    <div
      className={cls({
        [styles.root]: true
      })}
    >
      <ChatMessageSenderName senderName={senderName} />
      {quoteMessage && (
        <ChatQuotedMessage quote={quoteMessage} sender={quoteSender} outgoing={isMessageOutgoing} />
      )}

      <ChatMessageAudioListen
        white={isMessageOutgoing}
        small={true}
        chatId={chatId}
        audioFileId={audioFileId}
        defaultDuration={durationSec}
      />
    </div>
  )
}
