import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import {
  TChatDefaultMessageContainerProps,
  AvatarContainer,
  EChatDefaultMessageSize
} from 'App/containers'
import {
  ChatDefaultMessageWrapper,
  ChatDefaultMessageText,
  TChatDefaultMessageWrapperClasses,
  ConnectionLabelIcon
} from 'App/components'
import { ReactComponent as WavingHandIcon } from 'assets/icons/WavingHand.svg'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './ChatDefaultMessageDialog.module.scss'

export type TChatDefaultMessageDialogProps = Pick<
  TChatDefaultMessageContainerProps,
  'createdAt' | 'interlocutorAccount' | 'size' | 'chatType' | 'contactCreationLocation'
> &
  TChatDefaultMessageWrapperClasses

export const ChatDefaultMessageDialog = ({
  createdAt,
  interlocutorAccount,
  chatType,
  classes,
  contactCreationLocation,
  size = EChatDefaultMessageSize.MD
}: TChatDefaultMessageDialogProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  if (!interlocutorAccount) {
    return null
  }

  const { firstName, lastName, type, displayUserName, id } = interlocutorAccount

  const defaultMessageText = (
    <Row align="middle" justify="center">
      {t('chat.placeholder_p2p', { interlocutorName: displayUserName })}
      <WavingHandIcon className={styles.icon} />
      <ConnectionLabelIcon
        className={styles.connection}
        type={contactCreationLocation}
        size={isDesktop ? 'sm' : 'md'}
      />
    </Row>
  )

  return (
    <ChatDefaultMessageWrapper
      chatType={chatType}
      createdAt={createdAt}
      size={size}
      classes={classes}
    >
      <AvatarContainer
        userId={id}
        displayUserName={displayUserName}
        size={EAvatarSize[size]}
        firstName={firstName}
        lastName={lastName}
        type={type.name}
      />
      <ChatDefaultMessageText text={defaultMessageText} />
    </ChatDefaultMessageWrapper>
  )
}
