import { ReactNode } from 'react'
import { FormApi } from 'final-form'
import { Field } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import { TCountry, TCity, getCitiesRequest, TError } from 'store'
import { validation } from 'utils'
import {
  Checkbox,
  ErrorModal,
  Select,
  TCreateBusinessAccountFormValues,
  TextField
} from 'App/components'
import { MAX_LENGTH_100, TERMS_OF_USE_URL } from 'globalConstants'

import styles from '../BusinessAccountCreateDialog.module.scss'

type TCompanyAddressProps = {
  form: FormApi<TCreateBusinessAccountFormValues>
  countries: TCountry[]
  cities: TCity[]
  error: TError
  actions: ReactNode
  loadingCountries: boolean
  loadingCities: boolean
  getCities: typeof getCitiesRequest
}

export const CompanyAddress = ({
  form,
  countries,
  cities,
  error,
  actions,
  loadingCities,
  loadingCountries,
  getCities
}: TCompanyAddressProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Field name="country" validate={validation.required()}>
        {({ input, meta }) => {
          const onInputChange = (value: TCountry) => {
            input.onChange(value)
            form.change('city', undefined)
            getCities({ countryCode: value.code })
          }

          return (
            <Select
              {...input}
              value={input.value}
              options={countries}
              labelKey="countryName"
              valueKey="code"
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              wrapperClassName={styles.input}
              topLabel={t('modal.createBusinessAccount.companyAddressStep.country')}
              isLoading={loadingCountries}
              onChange={onInputChange}
            />
          )
        }}
      </Field>
      <Field name="city" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            options={cities}
            labelKey="cityName"
            valueKey="id"
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            wrapperClassName={styles.input}
            topLabel={t('modal.createBusinessAccount.companyAddressStep.city')}
            isLoading={loadingCities}
          />
        )}
      </Field>
      <Field
        name="zip"
        validate={validation.composeValidators(
          validation.required(),
          validation.isZip(),
          validation.onlySpaces()
        )}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            classes={{ wrapper: styles.input }}
            topLabel={t('modal.createBusinessAccount.companyAddressStep.zip')}
          />
        )}
      </Field>
      <Field
        name="addressLine1"
        validate={validation.composeValidators(
          validation.required(),
          validation.onlySpaces(),
          validation.maxLength(MAX_LENGTH_100)
        )}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            classes={{ wrapper: styles.input }}
            topLabel={t('modal.createBusinessAccount.companyAddressStep.address1')}
            valueLengthMax={MAX_LENGTH_100}
          />
        )}
      </Field>
      <Field
        name="addressLine2"
        validate={validation.composeValidators(
          validation.onlySpaces(),
          validation.maxLength(MAX_LENGTH_100)
        )}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            classes={{ wrapper: styles.input }}
            topLabel={t('modal.createBusinessAccount.companyAddressStep.address2')}
            valueLengthMax={MAX_LENGTH_100}
          />
        )}
      </Field>
      <Field name="terms" type="checkbox" validate={validation.required()}>
        {({ input }) => (
          <Checkbox
            {...input}
            classes={{ root: styles.input, label: styles.checkboxLabel }}
            label={
              <Trans t={t} i18nKey="modal.createBusinessAccount.companyAddressStep.privacyPolicy">
                <span>I agree with</span>
                <a
                  className={styles.termsLink}
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                *
              </Trans>
            }
          />
        )}
      </Field>

      {actions}

      <ErrorModal error={error} />
    </>
  )
}
