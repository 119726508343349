import cls from 'classnames'

import { INotificationsProtocolDTO } from 'interfaces'
import { AvatarContainer } from 'App/containers/AvatarContainer'
import { EAvatarSize } from 'enums'
import { useRedirectToUserProfile } from 'App/hooks'
import { formatNotificationDate } from 'utils'
import {
  NotificationsP2PDetailsDescription,
  NotificationsP2PDetailsType,
  TruncatedText
} from 'App/components'
import { SYSTEM_USER_ID } from 'globalConstants'

import styles from './NotificationsMainItem.module.scss'

type TNotificationsMainItemProps = INotificationsProtocolDTO

export const NotificationsMainItem = ({
  producer,
  originatedFrom,
  viewedProtocol,
  createdAt,
  case: caseData,
  type,
  file,
  payload,
  receiver
}: TNotificationsMainItemProps) => {
  const { displayUserName, firstName, id, lastName, type: producerType } = producer

  const { redirectToUserProfile } = useRedirectToUserProfile()

  const handleProfileClick = () => {
    redirectToUserProfile(id, producerType.name)()
  }

  return (
    <div className={cls(styles.root, { [styles.new]: !viewedProtocol })}>
      <AvatarContainer
        displayUserName={displayUserName}
        firstName={firstName}
        userId={id}
        lastName={lastName}
        type={producerType.name}
        size={EAvatarSize.XS}
        onClick={handleProfileClick}
      />
      <div className={styles.content}>
        <div className={styles.authorDetails}>
          <div className={styles.authorName} onClick={handleProfileClick}>
            <TruncatedText text={displayUserName} />
          </div>
          <div className={styles.date}>
            <span>{formatNotificationDate(createdAt)}</span>
            •
            <NotificationsP2PDetailsType type={originatedFrom} classes={{ root: styles.type }} />
          </div>
        </div>
        <NotificationsP2PDetailsDescription
          type={type}
          file={file}
          payload={payload}
          receiver={receiver}
          caseId={caseData?.id}
          caseTitle={caseData?.title}
          isCaseOwner={receiver?.id === caseData?.ownerId}
          isSystem={producer?.id === SYSTEM_USER_ID}
          classes={{ root: styles.description }}
        />
      </div>
    </div>
  )
}
