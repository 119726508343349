import { components, GroupBase, OptionProps } from 'react-select'
import cls from 'classnames'

import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg'

import styles from './Option.module.scss'

export const Option = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: OptionProps<Option, IsMulti, Group>
) => (
  <components.Option
    {...props}
    className={cls(styles.root, {
      [styles.selected]: props.isSelected,
      [styles.focused]: props.isFocused,
      [styles.disabled]: props.isDisabled
    })}
  >
    {props.children}
    {props.selectProps.isMulti && props.isSelected && <CheckIcon className={styles.selectedIcon} />}
  </components.Option>
)
