import cls from 'classnames'
import { CSSProperties, memo, useMemo } from 'react'
import { areEqual } from 'react-window'

import { Image } from 'App/components/common'

import styles from './ImagePreview.module.scss'

export enum EImagePreviewVariant {
  CHAT,
  CASE
}

export type TImagePreviewProps = {
  src: string
  alt: string
  className?: string
  size?: Pick<CSSProperties, 'width' | 'height'>
  variant?: EImagePreviewVariant
  onError?: () => void
}

export const ImagePreviewView = ({
  className,
  variant = EImagePreviewVariant.CHAT,
  alt,
  src,
  size,
  onError
}: TImagePreviewProps) => {
  const previewSize = useMemo<TImagePreviewProps['size']>(() => {
    const sizeRegExp = /#(\d+)x(\d+)?/gm

    if (size) {
      return size
    }

    const execArray = sizeRegExp.exec(src)

    return { width: Number(execArray?.[1]), height: Number(execArray?.[2]) }
  }, [size, src])

  return (
    <Image
      src={src}
      alt={alt}
      className={cls(
        styles.root,
        { [styles.rootChat]: variant === EImagePreviewVariant.CHAT },
        className
      )}
      width={previewSize?.width || 'auto'}
      height={previewSize?.height || 'auto'}
      placeholderClasses={{ icon: styles.placeholderIcon }}
      onError={onError}
    />
  )
}

export const ImagePreview = memo(ImagePreviewView, areEqual)
