import { useCallback } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TFilesViewVariant, Tooltip } from 'App/components'
import { ReactComponent as FormatListBulletedIcon } from 'assets/icons/FormatListBulleted.svg'
import { ReactComponent as ViewGridOutlineIcon } from 'assets/icons/ViewGridOutline.svg'

import styles from './FileViewToggle.module.scss'

export type TFileViewToggleProps = {
  onChange: (variant: TFilesViewVariant) => void

  variant?: TFilesViewVariant
  disabled?: boolean
}

export const FileViewToggle = ({ disabled, onChange, variant = 'list' }: TFileViewToggleProps) => {
  const { t } = useTranslation()

  const isGrid = variant === 'grid'

  const className = cls(styles.icon, disabled && styles.disabled)

  const handelClick = useCallback(() => {
    if (disabled) {
      return
    }

    const nextVariant = isGrid ? 'list' : 'grid'

    onChange(nextVariant)
  }, [disabled, isGrid, onChange])

  return (
    <Tooltip title={t('files.viewToggle', { context: variant })}>
      {isGrid ? (
        <FormatListBulletedIcon className={className} onClick={handelClick} />
      ) : (
        <ViewGridOutlineIcon className={className} onClick={handelClick} />
      )}
    </Tooltip>
  )
}
