import { useTranslation } from 'react-i18next'

import { CaseItemMembers, CaseItemHeader, CaseItemNotificationWrapper } from 'App/components/index'
import { TCaseItemWrapperContainerProps } from 'App/containers'

export const CaseItemLocked = ({
  isOwner,
  loading,
  caseData,
  memberList,
  showModal
}: TCaseItemWrapperContainerProps) => {
  const { id, title, notificationCount, type, lockedAt, createdAt, owner, status, classification } =
    caseData

  const { t } = useTranslation()

  return (
    <CaseItemNotificationWrapper notificationCount={notificationCount}>
      <CaseItemHeader
        id={id}
        title={title}
        isOwner={isOwner}
        status={status}
        showModal={showModal}
        type={type}
        classification={classification.name}
        createdAt={createdAt}
        updatedAt={lockedAt}
        getUpdatedAtLabel={(date) => t('cases.list.item.lockedAt', { date })}
      />

      <CaseItemMembers memberList={memberList} owner={owner} loading={loading} />
    </CaseItemNotificationWrapper>
  )
}
