import { useHistory, useRouteMatch } from 'react-router-dom'
import { useCallback } from 'react'

import { useAppSelector } from 'store'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'

import { useKebabMenu } from '../useKebabMenu'

import { Participants } from './Participants'

type TVoiceRoomProps = {
  voiceRoomId: string
  organizationId: string
} & ReturnType<typeof useKebabMenu>

export const VoiceRoom = ({ voiceRoomId, organizationId, getMenuItems }: TVoiceRoomProps) => {
  const { accounts, name, isPublic, departments } = useAppSelector(
    (state) => state.organizations.voiceRooms.list[voiceRoomId]
  )

  const { push } = useHistory()

  const { url } = useRouteMatch()

  const to = `${url}/voice-chat/${voiceRoomId}`

  const subscriptionCallback = useCallback(() => push(to), [push, to])

  const { getOnClickHandler } = useOrganizationSubscription({
    organizationId,
    callback: subscriptionCallback
  })

  if (!name) {
    return null
  }

  return (
    <>
      <UnityHubRoomNavLink
        to={to}
        icon={<UnityHubChannelIcon variant="voice" />}
        name={name}
        isPublic={isPublic}
        onClick={getOnClickHandler()}
        menuItems={getMenuItems({
          variant: 'voice',
          channelId: voiceRoomId,
          channelName: name,
          isPublic,
          isGroup: !!departments?.length
        })}
      />
      {accounts && <Participants participants={accounts} />}
    </>
  )
}
