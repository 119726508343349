import {
  CaseCloudFileDetailsContainer,
  CaseCloudFileDetailsMemberContainer,
  CaseCloudFileDetailsSharedWithMeContainer
} from 'App/containers'

export type TFileDetailsProps = {
  caseId: string
  isSharedWithMe: boolean
  isUploadedByMe: boolean
  isMember: boolean
}

export const FileDetails = ({
  caseId,
  isMember,
  isSharedWithMe,
  isUploadedByMe
}: TFileDetailsProps) => {
  if (isUploadedByMe && isMember) {
    return <CaseCloudFileDetailsContainer isMember={true} caseId={caseId} />
  }

  if (isSharedWithMe) {
    return <CaseCloudFileDetailsSharedWithMeContainer />
  }

  return isMember ? (
    <CaseCloudFileDetailsMemberContainer />
  ) : (
    <CaseCloudFileDetailsContainer caseId={caseId} />
  )
}
