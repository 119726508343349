import { PropsWithChildren, ReactNode, useCallback, useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import { accountIdSelector, useAppSelector } from 'store'
import { Collapse, CollapsePanel } from 'App/components/common'
import { TChannelVariant } from 'interfaces'

import styles from './UnityHubRoomsNavCollapse.module.scss'

export type TUnityHubRoomsNavCollapseView = 'collapsed' | 'expanded'

export type TUnityHubRoomsNavCollapseProps = PropsWithChildren<{
  title: string
  variant: TChannelVariant
  localStorageKey: string

  action?: ReactNode
  onChangeView?: (view: TUnityHubRoomsNavCollapseView) => void
}>

export const UnityHubRoomsNavCollapse = ({
  localStorageKey,
  variant,
  children,
  title,
  action,
  onChangeView
}: TUnityHubRoomsNavCollapseProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const [view, setView] = useLocalStorageState<TUnityHubRoomsNavCollapseView>(
    `${variant}-channel-${accountId}-${localStorageKey}`,
    { defaultValue: 'expanded' }
  )

  const toggleChangeView = useCallback(() => {
    setView((prev) => (prev === 'collapsed' ? 'expanded' : 'collapsed'))
  }, [setView])

  useEffect(() => {
    if (onChangeView) {
      onChangeView(view)
    }
  }, [onChangeView, view])

  return (
    <Collapse
      className={styles.collapse}
      defaultActiveKey={view === 'expanded' ? variant : undefined}
      onChange={toggleChangeView}
    >
      <CollapsePanel
        key={variant}
        header={
          <h3 className={styles.header}>
            {title}
            {action}
          </h3>
        }
      >
        {children}
      </CollapsePanel>
    </Collapse>
  )
}
