import { forwardRef, lazy, Suspense } from 'react'

import type { TQRCodeProps } from './QRCode'

import { Spinner } from '../../Spinner'

const QRCodeInternal = lazy(() => import('./QRCode'))

export { TQRCodeProps }
export const QRCode = forwardRef<SVGAElement, TQRCodeProps>(function QrCode(props, ref) {
  return (
    <Suspense fallback={<Spinner />}>
      <QRCodeInternal ref={ref} {...props} />
    </Suspense>
  )
})
