import { IVoiceRoomDTO, IVoiceRoomIdDTO } from 'interfaces/api/organizations/voiceRoom'
import { actionCreator, TError } from 'store'
import { Pagination } from 'types'

import * as actionTypes from './organizations.actionTypes'
import {
  TGetOrganizationMembersPayload,
  TGetOrganizationMembersSuccessPayload,
  TGetOrganizationsSuccessPayload,
  TSetOrganizationMemberCurrentPagePayload,
  TSetOrganizationMemberSearchPayload,
  TGetOrganizationRegisteredUsersSuccessPayload,
  TGetVoiceRoomsRequestPayload,
  TVoiceRooms,
  TGetOrganizationsInvitationsSuccessPayload,
  TGetOrganizationAdministrationsSuccessPayload,
  TCreateOrganizationChatRequestPayload,
  TGetOrganizationNewUsersSuccessPayload,
  TGetOrganizationsInvitationsRequestPayload,
  TInviteAllOrganizationMembersPayload,
  TReceiveRemoveFromAdminPayload,
  TGetOrganizationsRequestPayload,
  TReceiveLeaderRightsInDepartmentsPayload,
  TUpdateOrganizationPayload,
  TReceiveAccessToVoiceChannelPayload,
  TSetOrganizationIdPayload,
  TGetOrganizationRegisteredUsersRequestPayload
} from './organizations.types'

export const getOrganizationsRequest = (payload?: TGetOrganizationsRequestPayload) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_REQUEST, payload)
export const getOrganizationsSuccess = (payload: TGetOrganizationsSuccessPayload) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_SUCCESS, payload)
export const getOrganizationsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_ERROR, payload)

export const updateOrganization = (payload: TUpdateOrganizationPayload) =>
  actionCreator(actionTypes.UPDATE_ORGANIZATION, payload)

export const getOrganizationMembersRequest = (payload: TGetOrganizationMembersPayload) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_MEMBERS_REQUEST, payload)
export const getOrganizationMembersSuccess = (payload: TGetOrganizationMembersSuccessPayload) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_MEMBERS_SUCCESS, payload)
export const getOrganizationMembersError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_MEMBERS_ERROR, payload)

export const setOrganizationMemberSearch = (payload: TSetOrganizationMemberSearchPayload) =>
  actionCreator(actionTypes.SET_ORGANIZATIONS_MEMBERS_SEARCH, payload)

export const setOrganizationMemberCurrentPage = (
  payload: TSetOrganizationMemberCurrentPagePayload
) => actionCreator(actionTypes.SET_ORGANIZATIONS_MEMBERS_CURRENT_PAGE, payload)

export const resetOrganizations = () => actionCreator(actionTypes.RESET_ORGANIZATIONS)
export const resetOrganizationMember = () => actionCreator(actionTypes.RESET_ORGANIZATION_MEMBERS)

export const getOrganizationRegisteredUsersRequest = (
  payload?: TGetOrganizationRegisteredUsersRequestPayload
) => actionCreator(actionTypes.GET_ORGANIZATION_REGISTERED_USERS_REQUEST, payload)
export const getOrganizationRegisteredUsersSuccess = (
  payload: TGetOrganizationRegisteredUsersSuccessPayload
) => actionCreator(actionTypes.GET_ORGANIZATION_REGISTERED_USERS_SUCCESS, payload)
export const getOrganizationRegisteredUsersError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATION_REGISTERED_USERS_ERROR, payload)

export const createOrganizationChatRequest = (payload: TCreateOrganizationChatRequestPayload) =>
  actionCreator(actionTypes.CREATE_ORGANIZATION_CHAT_REQUEST, payload)
export const createOrganizationChatSuccess = () =>
  actionCreator(actionTypes.CREATE_ORGANIZATION_CHAT_SUCCESS)
export const createOrganizationChatError = (payload: TError) =>
  actionCreator(actionTypes.CREATE_ORGANIZATION_CHAT_ERROR, payload)

export const getVoiceRoomsRequest = (payload: TGetVoiceRoomsRequestPayload) =>
  actionCreator(actionTypes.GET_VOICE_ROOMS_REQUEST, payload)
export const getVoiceRoomsSuccess = (payload: TVoiceRooms) =>
  actionCreator(actionTypes.GET_VOICE_ROOMS_SUCCESS, payload)
export const getVoiceRoomsError = (payload: TError) =>
  actionCreator(actionTypes.GET_VOICE_ROOMS_ERROR, payload)

export const joinVoiceRoomRequest = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.JOIN_VOICE_ROOM_REQUEST, payload)
export const joinVoiceRoomSuccess = (payload: IVoiceRoomDTO) =>
  actionCreator(actionTypes.JOIN_VOICE_ROOM_SUCCESS, payload)
export const joinVoiceRoomError = (payload: TError) =>
  actionCreator(actionTypes.JOIN_VOICE_ROOM_ERROR, payload)

export const leaveVoiceRoomRequest = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.LEAVE_VOICE_ROOM_REQUEST, payload)
export const leaveVoiceRoomSuccess = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.LEAVE_VOICE_ROOM_SUCCESS, payload)
export const leaveVoiceRoomError = (payload: TError) =>
  actionCreator(actionTypes.LEAVE_VOICE_ROOM_ERROR, payload)

export const receiveJoinVoiceRoom = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.RECEIVE_JOIN_VOICE_ROOM, payload)

export const receiveLeaveVoiceRoom = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.RECEIVE_LEAVE_VOICE_ROOM, payload)

export const updateVoiceRoomMembers = (payload: IVoiceRoomDTO) =>
  actionCreator(actionTypes.UPDATE_VOICE_ROOM_MEMBERS, payload)

export const closeVoiceRoom = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.CLOSE_VOICE_ROOM, payload)

export const getOrganizationsInvitationsRequest = (
  payload?: TGetOrganizationsInvitationsRequestPayload
) => actionCreator(actionTypes.GET_ORGANIZATIONS_INVITATIONS_REQUEST, payload)
export const getOrganizationsInvitationsSuccess = (
  payload: TGetOrganizationsInvitationsSuccessPayload
) => actionCreator(actionTypes.GET_ORGANIZATIONS_INVITATIONS_SUCCESS, payload)
export const getOrganizationsInvitationsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATIONS_INVITATIONS_ERROR, payload)

export const resetOrganizationsInvitationsFilters = () =>
  actionCreator(actionTypes.RESET_ORGANIZATIONS_INVITATIONS_FILTERS)

export const getOrganizationAdministrationsRequest = (
  payload?: Partial<Pick<Pagination, 'current' | 'showBy'>>
) => actionCreator(actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_REQUEST, payload)
export const getOrganizationAdministrationsSuccess = (
  payload: TGetOrganizationAdministrationsSuccessPayload
) => actionCreator(actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_SUCCESS, payload)
export const getOrganizationAdministrationsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_ERROR, payload)

export const getOrganizationNewUsersRequest = (
  payload?: Partial<Pick<Pagination, 'current' | 'showBy'>>
) => actionCreator(actionTypes.GET_ORGANIZATION_NEW_USERS_REQUEST, payload)
export const getOrganizationNewUsersSuccess = (payload: TGetOrganizationNewUsersSuccessPayload) =>
  actionCreator(actionTypes.GET_ORGANIZATION_NEW_USERS_SUCCESS, payload)
export const getOrganizationNewUsersError = (payload: TError) =>
  actionCreator(actionTypes.GET_ORGANIZATION_NEW_USERS_ERROR, payload)

export const kickUserFromVoiceRoom = (payload: IVoiceRoomIdDTO) =>
  actionCreator(actionTypes.KICK_USER_FROM_VOICE_ROOM, payload)

export const inviteAllOrganizationMembersRequest = (
  payload: TInviteAllOrganizationMembersPayload
) => actionCreator(actionTypes.INVITE_ALL_ORGANIZATION_MEMBERS_REQUEST, payload)
export const inviteAllOrganizationMembersSuccess = () =>
  actionCreator(actionTypes.INVITE_ALL_ORGANIZATION_MEMBERS_SUCCESS)
export const inviteAllOrganizationMembersError = (payload: TError) =>
  actionCreator(actionTypes.INVITE_ALL_ORGANIZATION_MEMBERS_ERROR, payload)

export const receiveRemoveFromAdmin = (payload: TReceiveRemoveFromAdminPayload) =>
  actionCreator(actionTypes.RECEIVE_REMOVED_FROM_ADMIN, payload.payload)

export const receiveLostLeaderRightsInDepartments = (
  payload: TReceiveLeaderRightsInDepartmentsPayload
) => actionCreator(actionTypes.RECEIVE_LOST_LEADER_RIGHTS_IN_DEPARTMENTS, payload)

export const receiveDelegatedLeaderRightsInDepartments = (
  payload: TReceiveLeaderRightsInDepartmentsPayload
) => actionCreator(actionTypes.RECEIVE_DELEGATED_LEADER_RIGHTS_IN_DEPARTMENTS, payload)

export const receiveLostAccessToVoiceChannel = (payload: TReceiveAccessToVoiceChannelPayload) =>
  actionCreator(actionTypes.RECEIVE_LOST_ACCESS_TO_VOICE_CHANNEL, payload)
export const receiveAddedUserToVoiceChannel = (payload: TReceiveAccessToVoiceChannelPayload) =>
  actionCreator(actionTypes.RECEIVE_ADDED_USER_TO_VOICE_CHANNEL, payload)

export const setOrganizationId = (payload: TSetOrganizationIdPayload) =>
  actionCreator(actionTypes.SET_ORGANIZATION_ID, payload)
