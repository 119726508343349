import { Row, Col, Skeleton } from 'antd'
import cls from 'classnames'

import {
  TUseFileMouseEventsProps,
  useFileMouseEvents,
  useFileContextMenu,
  TMenuItemProps,
  FileName,
  ContextMenu
} from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { formatBytes, formatLongDate } from 'utils'

import styles from './ChatFileRow.module.scss'

export type TChatFileRowProps = {
  fileId: string
  fileName: string
  fileSize: string
  extension: string
  loading: boolean
  mimeType: string

  sharedAt?: string | Date
  menuItems?: TMenuItemProps[]
} & Partial<Pick<TUseFileMouseEventsProps, 'disableOpen'>> &
  Pick<TUseFileMouseEventsProps, 'openHandlerProps'>

export const ChatFileRow = ({
  fileId,
  fileName,
  fileSize,
  extension,
  menuItems,
  disableOpen,
  openHandlerProps,
  sharedAt,
  loading
}: TChatFileRowProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()

  const { handleVisibilityChange, visible, ref } = useFileContextMenu()

  const { longPressBind, onClick } = useFileMouseEvents({
    file: { id: fileId },
    openHandlerProps,
    disableOpen,
    disableSelect: true,
    onTouch: handleVisibilityChange
  })

  return (
    <Skeleton
      active={true}
      loading={loading}
      title={false}
      paragraph={{ width: '100%', rows: 1, className: styles.skeleton }}
    >
      <Row
        ref={ref}
        className={cls(styles.root, { [styles.context]: visible })}
        gutter={[8, 0]}
        {...longPressBind}
        onClick={onClick}
        onContextMenu={isDesktop ? handleVisibilityChange : undefined}
      >
        {menuItems && <ContextMenu disableIcon={true} menuItems={menuItems} visible={visible} />}
        <Col flex={isMobile ? 1 : 10} className={styles.name}>
          <FileName
            extension={extension}
            fileName={fileName}
            className={styles.fileName}
            fileNameDisabled={disableOpen}
          />
        </Col>
        {!isMobile && (
          <>
            <Col span={3} className={styles.date}>
              {sharedAt && formatLongDate(sharedAt)}
            </Col>
            <Col span={3} className={styles.size}>
              {formatBytes(fileSize)}
            </Col>
          </>
        )}
      </Row>
    </Skeleton>
  )
}
