import { useCallback } from 'react'
import { Col } from 'antd'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { TextField } from 'App/components'
import { MIN_LENGTH_1, MAX_LENGTH_100, MAX_LENGTH_150 } from 'globalConstants'

export const UserProfileEducationDialogFirstStep = () => {
  const { t } = useTranslation()

  const validate = useCallback(
    (required = false, maxLength: number = MAX_LENGTH_100) =>
      validation.composeValidators(
        validation.onlySpaces(),
        required ? validation.required() : null,
        validation.minLength(MIN_LENGTH_1),
        validation.maxLength(maxLength)
      ),
    []
  )

  return (
    <>
      <Col xs={24}>
        <Field name="organization" validate={validate(true, MAX_LENGTH_150)}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userEducation.form.institutionField')}
              valueLengthMax={MAX_LENGTH_150}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="degree" validate={validate()}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userEducation.form.degreeField')}
              valueLengthMax={MAX_LENGTH_100}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="subjectField" validate={validate()}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userEducation.form.subjectField')}
              valueLengthMax={MAX_LENGTH_100}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
