import { useState } from 'react'
import { Row, Col } from 'antd'
import { Form } from 'react-final-form'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { hideModalAction, useAppDispatch } from 'store'
import { queryClient } from 'queryClient'
import { isMatchErrorCode } from 'utils/errorParsing'

import { Button } from '../../../components'
import { DepartmentMainFields } from '../DepartmentMainFields'
import { createDepartment } from '../../../../api/organizations'
import { handleError } from '../../../../api'
import { ORGANIZATION_DEPARTMENTS_QUERY_KEY } from '../StaffTab/StaffTabAccountItem/useKebabMenu'

export type TValues = {
  name: string
  description: string
}

const INITIAL_VALUES: TValues = {
  name: '',
  description: ''
}

export type TCreateDepartmentModalProps = { id: string }

export const CreateDepartmentModal = ({ id }: TCreateDepartmentModalProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [nameError, setNameError] = useState('')

  const { mutate, isLoading } = useMutation({
    mutationKey: ['create-department'],
    mutationFn: createDepartment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_DEPARTMENTS_QUERY_KEY],
        type: 'active'
      })
      dispatch(hideModalAction())
    },
    onError: (error) => {
      if (isMatchErrorCode(error, ErrorCodesEnum.DEPARTMENT_WITH_SAME_NAME_EXIST)) {
        setNameError(t('serverError.DEPARTMENT_WITH_SAME_NAME_EXIST', { ns: 'errors' }))
        return
      } else {
        setNameError('')
        handleError(error)
      }
    }
  })

  const onSubmit = (values: TValues) => mutate({ id, ...values })

  return (
    <Form initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
      {({ handleSubmit, valid }) => (
        <Row gutter={[0, 28]}>
          <DepartmentMainFields nameError={nameError} />
          <Col xs={24}>
            <Row justify="center">
              <Button loading={isLoading} disabled={!valid} onClick={handleSubmit}>
                {t('organizations.editDepartment.submitButton_create')}
              </Button>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  )
}
