import { Action } from 'store'

import { TLoadingState } from './loading.types'

export const loadingTypeRegExp = /(.*)_(REQUEST|SUCCESS|ERROR|CANCEL)$/
export const metaWithoutLoading = 'withoutLoading'

export const initialLoadingState: TLoadingState = {
  singular: {
    GET_CASE_DESCRIPTION: true,
    GET_CASE_OPINIONS_MEMBERS: true,
    GET_CASE_SUMMARY: true,
    GET_CASE_OPINIONS: true,
    GET_CASE_OPINIONS_BY_MEMBER: true,
    GET_MESSAGES_HISTORY: false,
    GET_CONTACTS: true
  },
  plural: {}
}

export const loadingReducer = (state: TLoadingState = initialLoadingState, action: Action) => {
  if (action.meta && typeof action.meta === 'object' && action.meta[metaWithoutLoading]) {
    return state
  }

  const matches = loadingTypeRegExp.exec(action.type)

  const { processingId } = action.payload || {}

  if (matches) {
    const [, requestName, requestState] = matches

    return !action.payload?.processingId
      ? {
          ...state,
          singular: {
            ...state.singular,
            [requestName]: requestState === 'REQUEST'
          }
        }
      : {
          ...state,
          plural: {
            ...state.plural,
            [requestName]: {
              ...state.plural[requestName],
              [processingId]: requestState === 'REQUEST'
            }
          }
        }
  }

  return state
}
