import { connect } from 'react-redux'
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, GET_CASE_OPINIONS_MEMBERS, setOpenedOpinionAction } from 'store'
import { CaseOpinionsMembersItem } from 'App/components'

export type TCaseOpinionsMembersItemContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export type TCaseOpinionsMembersItemContainerOwnProps = Omit<
  CollapsePanelProps,
  'key' | 'header'
> & {
  id: string
}

const loadingSelector = createLoadingSelector([GET_CASE_OPINIONS_MEMBERS])

const mapStateToProps = (state: State, ownProps: TCaseOpinionsMembersItemContainerOwnProps) => {
  const { firstName, lastName, displayUserName, type } =
    state.caseDetails.opinions.opinionsMembersList[ownProps.id] || {}

  const firstOpinionId = state.caseDetails.opinions.opinionsIds[0]
  const { displayMemberName, createdAt } =
    state.caseDetails.opinions.opinionsList[firstOpinionId] || {}

  return {
    firstName,
    lastName,
    displayUserName,
    type,
    memberId: ownProps.id,
    loading: loadingSelector(state),
    isMyAccount: state.global.accountData?.id === ownProps.id,
    openOpinionMemberId: state.caseDetails.opinions.openOpinionMemberId,
    opinionCreator: displayMemberName,
    createdAt,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setOpenedOpinion: setOpenedOpinionAction
    },
    dispatch
  )

export const CaseOpinionsMembersItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseOpinionsMembersItem)
