import cls from 'classnames'

import { formatShortDate } from 'utils'
import { TruncatedText } from 'App/components'

import styles from './FileTilesViewOwnerAndDate.module.scss'

export type TFileTilesViewOwnerAndDateProps = PropsWithClasses<
  'name' | 'date',
  {
    name: string
    date: Date | string
  }
>

export const FileTilesViewOwnerAndDate = ({
  name,
  date,
  classes
}: TFileTilesViewOwnerAndDateProps) => (
  <div className={styles.root}>
    <TruncatedText classes={{ text: cls(styles.name, classes?.name) }} text={name} />
    <span className={styles.separator}>&bull;</span>
    <span className={cls(styles.date, classes?.date)}>{formatShortDate(date)}</span>
  </div>
)
