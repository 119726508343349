import { TOption } from 'App/components/common/Fields/Select'
import { TEducation, TWorkingExperience } from 'store'

import { action } from '../store.utils'
import { TError } from '../store.types'

import {
  TAddUserProfileProfessionRequestPayload,
  TAddUserProfileSpecializationRequestPayload,
  TChangeUserProfileAvatarPayload,
  TDeleteUserProfileNewProfessionPayload,
  TDeleteUserProfileProfessionRequestPayload,
  TDeleteUserProfileSpecializationRequestPayload,
  TDeleteUserProfileWebsite,
  TEditUserProfileBasicInformationPayload,
  TGetUserProfileByIdPayload,
  TEditUserProfileProfessionRequestPayload,
  TOnTheWebChangeEditStatePayload,
  TOnTheWebChangeUrlPayload,
  TProfessionsList,
  TSetUserProfileProfessionLoadersPayload,
  TEditUserProfileWebsitePayload,
  TSetUserProfileWebsiteProcessingPayload,
  TUserProfileBasicInformationInfo,
  TWebsiteList,
  TSwitchAccountRequestPayload,
  TGetRelatedAccountsSuccessPayload,
  TSwitchAccountEventPayload,
  TEditBusinessAccountRequestPayload,
  TOnTheWeb,
  TGetWorkExperienceSuccessPayload,
  TRemoveLicenseRequestPayload,
  TGetEducationSuccessPayload,
  TAddLicenseRequestPayload,
  TUpdateLicenseRequestPayload,
  TRemoveWorkExperienceRequestPayload,
  TRemoveEducationRequestPayload,
  TGetLicensesSuccessPayload,
  TGetPublicationsSuccessPayload,
  TRemovePublicationRequestPayload,
  TAddPublicationRequestPayload,
  TUpdatePublicationRequestPayload,
  TUpdateEducationErrorPayload,
  TUpdateEducationRequestPayload,
  TUpdateEducationSuccessPayload,
  TUpdateWorkingExperienceErrorPayload,
  TUpdateWorkingExperienceRequestPayload,
  TUpdateWorkingExperienceSuccessPayload,
  TUpdateLicenseErrorPayload,
  TUpdateLicenseSuccessPayload,
  TUpdatePublicationErrorPayload,
  TUpdatePublicationSuccessPayload,
  TGetUserProfileByIdSuccessPayload
} from './userProfile.types'

export const GET_USER_PROFILE_BASIC_INFORMATION = 'GET_USER_PROFILE_BASIC_INFORMATION'
export const GET_USER_PROFILE_BASIC_INFORMATION_REQUEST =
  'GET_USER_PROFILE_BASIC_INFORMATION_REQUEST'
export const GET_USER_PROFILE_BASIC_INFORMATION_SUCCESS =
  'GET_USER_PROFILE_BASIC_INFORMATION_SUCCESS'
export const GET_USER_PROFILE_BASIC_INFORMATION_ERROR = 'GET_USER_PROFILE_BASIC_INFORMATION_ERROR'

export const EDIT_USER_PROFILE_BASIC_INFORMATION = 'EDIT_USER_PROFILE_BASIC_INFORMATION'
export const EDIT_USER_PROFILE_BASIC_INFORMATION_REQUEST =
  'EDIT_USER_PROFILE_BASIC_INFORMATION_REQUEST'
export const EDIT_USER_PROFILE_BASIC_INFORMATION_SUCCESS =
  'EDIT_USER_PROFILE_BASIC_INFORMATION_SUCCESS'
export const EDIT_USER_PROFILE_BASIC_INFORMATION_ERROR = 'EDIT_USER_PROFILE_BASIC_INFORMATION_ERROR'

export const CHANGE_USER_PROFILE_AVATAR = 'CHANGE_USER_PROFILE_AVATAR'
export const CHANGE_USER_PROFILE_AVATAR_REQUEST = 'CHANGE_USER_PROFILE_AVATAR_REQUEST'
export const CHANGE_USER_PROFILE_AVATAR_SUCCESS = 'CHANGE_USER_PROFILE_AVATAR_SUCCESS'
export const CHANGE_USER_PROFILE_AVATAR_ERROR = 'CHANGE_USER_PROFILE_AVATAR_ERROR'

export const DELETE_USER_PROFILE_AVATAR = 'DELETE_USER_PROFILE_AVATAR'
export const DELETE_USER_PROFILE_AVATAR_REQUEST = 'DELETE_USER_PROFILE_AVATAR_REQUEST'
export const DELETE_USER_PROFILE_AVATAR_SUCCESS = 'DELETE_USER_PROFILE_AVATAR_SUCCESS'
export const DELETE_USER_PROFILE_AVATAR_ERROR = 'DELETE_USER_PROFILE_AVATAR_ERROR'

export const GET_USER_PROFILE_ON_THE_WEB = 'GET_USER_PROFILE_ON_THE_WEB'
export const GET_USER_PROFILE_ON_THE_WEB_REQUEST = 'GET_USER_PROFILE_ON_THE_WEB_REQUEST'
export const GET_USER_PROFILE_ON_THE_WEB_SUCCESS = 'GET_USER_PROFILE_ON_THE_WEB_SUCCESS'
export const GET_USER_PROFILE_ON_THE_WEB_ERROR = 'GET_USER_PROFILE_ON_THE_WEB_ERROR'

export const SET_USER_PROFILE_ON_THE_WEB_IDS = 'SET_USER_PROFILE_ON_THE_WEB_IDS'
export const SET_USER_PROFILE_ON_THE_WEB_LIST = 'SET_USER_PROFILE_ON_THE_WEB_LIST'

export const SET_USER_PROFILE_ON_THE_WEB_EDIT_STATE = 'SET_USER_PROFILE_ON_THE_WEB_EDIT_STATE'
export const SET_USER_PROFILE_ON_THE_WEB_URL = 'SET_USER_PROFILE_ON_THE_WEB_URL'

export const EDIT_USER_PROFILE_ON_THE_WEB_REQUEST = 'EDIT_USER_PROFILE_ON_THE_WEB_REQUEST'
export const EDIT_USER_PROFILE_ON_THE_WEB_SUCCESS = 'EDIT_USER_PROFILE_ON_THE_WEB_SUCCESS'
export const EDIT_USER_PROFILE_ON_THE_WEB_ERROR = 'EDIT_USER_PROFILE_ON_THE_WEB_ERROR'

export const ADD_USER_PROFILE_WEBSITE = 'ADD_USER_PROFILE_WEBSITE'

export const DELETE_USER_PROFILE_WEBSITE_REQUEST = 'DELETE_USER_PROFILE_WEBSITE_REQUEST'
export const DELETE_USER_PROFILE_WEBSITE_SUCCESS = 'DELETE_USER_PROFILE_WEBSITE_SUCCESS'
export const DELETE_USER_PROFILE_WEBSITE_ERROR = 'DELETE_USER_PROFILE_WEBSITE_ERROR'

export const SET_USER_PROFILE_WEBSITE_PROCESSING = 'SET_USER_PROFILE_WEBSITE_PROCESSING'

export const GET_USER_PROFILE_PROFESSIONS = 'GET_USER_PROFILE_PROFESSIONS'
export const GET_USER_PROFILE_PROFESSIONS_REQUEST = 'GET_USER_PROFILE_PROFESSIONS_REQUEST'
export const GET_USER_PROFILE_PROFESSIONS_SUCCESS = 'GET_USER_PROFILE_PROFESSIONS_SUCCESS'
export const GET_USER_PROFILE_PROFESSIONS_ERROR = 'GET_USER_PROFILE_PROFESSIONS_ERROR'

export const SET_USER_PROFILE_PROFESSIONS_IDS = 'SET_USER_PROFILE_PROFESSIONS_IDS'
export const SET_USER_PROFILE_PROFESSIONS_LIST = 'SET_USER_PROFILE_PROFESSIONS_LIST'
export const SET_USER_PROFILE_PROFESSIONS_NEW_IDS = 'SET_USER_PROFILE_PROFESSIONS_NEW_IDS'

export const ADD_USER_PROFILE_PROFESSIONS_NEW_ID = 'ADD_USER_PROFILE_PROFESSIONS_NEW_ID'

export const GET_AVAILABLE_PROFESSIONS = 'GET_AVAILABLE_PROFESSIONS'
export const GET_AVAILABLE_PROFESSIONS_REQUEST = 'GET_AVAILABLE_PROFESSIONS_REQUEST'
export const GET_AVAILABLE_PROFESSIONS_SUCCESS = 'GET_AVAILABLE_PROFESSIONS_SUCCESS'
export const GET_AVAILABLE_PROFESSIONS_ERROR = 'GET_AVAILABLE_PROFESSIONS_ERROR'

export const DELETE_USER_PROFILE_NEW_PROFESSION = 'DELETE_USER_PROFILE_NEW_PROFESSION'

export const ADD_USER_PROFILE_PROFESSION = 'ADD_USER_PROFILE_PROFESSION'
export const ADD_USER_PROFILE_PROFESSION_REQUEST = 'ADD_USER_PROFILE_PROFESSION_REQUEST'
export const ADD_USER_PROFILE_PROFESSION_SUCCESS = 'ADD_USER_PROFILE_PROFESSION_SUCCESS'
export const ADD_USER_PROFILE_PROFESSION_ERROR = 'ADD_USER_PROFILE_PROFESSION_ERROR'

export const DELETE_USER_PROFILE_PROFESSION = 'DELETE_USER_PROFILE_PROFESSION'
export const DELETE_USER_PROFILE_PROFESSION_REQUEST = 'DELETE_USER_PROFILE_PROFESSION_REQUEST'
export const DELETE_USER_PROFILE_PROFESSION_SUCCESS = 'DELETE_USER_PROFILE_PROFESSION_SUCCESS'
export const DELETE_USER_PROFILE_PROFESSION_ERROR = 'DELETE_USER_PROFILE_PROFESSION_ERROR'

export const EDIT_USER_PROFILE_PROFESSION = 'EDIT_USER_PROFILE_PROFESSION'
export const EDIT_USER_PROFILE_PROFESSION_REQUEST = 'EDIT_USER_PROFILE_PROFESSION_REQUEST'
export const EDIT_USER_PROFILE_PROFESSION_SUCCESS = 'EDIT_USER_PROFILE_PROFESSION_SUCCESS'
export const EDIT_USER_PROFILE_PROFESSION_ERROR = 'EDIT_USER_PROFILE_PROFESSION_ERROR'

export const ADD_USER_PROFILE_SPECIALIZATION = 'ADD_USER_PROFILE_SPECIALIZATION'
export const ADD_USER_PROFILE_SPECIALIZATION_REQUEST = 'ADD_USER_PROFILE_SPECIALIZATION_REQUEST'
export const ADD_USER_PROFILE_SPECIALIZATION_SUCCESS = 'ADD_USER_PROFILE_SPECIALIZATION_SUCCESS'
export const ADD_USER_PROFILE_SPECIALIZATION_ERROR = 'ADD_USER_PROFILE_SPECIALIZATION_ERROR'

export const DELETE_USER_PROFILE_SPECIALIZATION = 'DELETE_USER_PROFILE_SPECIALIZATION'
export const DELETE_USER_PROFILE_SPECIALIZATION_REQUEST =
  'DELETE_USER_PROFILE_SPECIALIZATION_REQUEST'
export const DELETE_USER_PROFILE_SPECIALIZATION_SUCCESS =
  'DELETE_USER_PROFILE_SPECIALIZATION_SUCCESS'
export const DELETE_USER_PROFILE_SPECIALIZATION_ERROR = 'DELETE_USER_PROFILE_SPECIALIZATION_ERROR'

export const SET_USER_PROFILE_PROFESSION_LOADERS = 'SET_USER_PROFILE_PROFESSION_LOADERS'

export const GET_USER_PROFILE_BY_ID = 'GET_USER_PROFILE_BY_ID'
export const GET_USER_PROFILE_BY_ID_REQUEST = 'GET_USER_PROFILE_BY_ID_REQUEST'
export const GET_USER_PROFILE_BY_ID_SUCCESS = 'GET_USER_PROFILE_BY_ID_SUCCESS'
export const GET_USER_PROFILE_BY_ID_ERROR = 'GET_USER_PROFILE_BY_ID_ERROR'

export const GET_RELATED_ACCOUNTS = 'GET_RELATED_ACCOUNTS'
export const GET_RELATED_ACCOUNTS_REQUEST = 'GET_RELATED_ACCOUNTS_REQUEST'
export const GET_RELATED_ACCOUNTS_SUCCESS = 'GET_RELATED_ACCOUNTS_SUCCESS'
export const GET_RELATED_ACCOUNTS_ERROR = 'GET_RELATED_ACCOUNTS_ERROR'

export const SWITCH_ACCOUNT = 'SWITCH_ACCOUNT'
export const SWITCH_ACCOUNT_REQUEST = 'SWITCH_ACCOUNT_REQUEST'
export const SWITCH_ACCOUNT_EVENT = 'SWITCH_ACCOUNT_EVENT'
export const SWITCH_ACCOUNT_ERROR = 'SWITCH_ACCOUNT_ERROR'

export const EDIT_BUSINESS_ACCOUNT = 'EDIT_BUSINESS_ACCOUNT'
export const EDIT_BUSINESS_ACCOUNT_REQUEST = 'EDIT_BUSINESS_ACCOUNT_REQUEST'
export const EDIT_BUSINESS_ACCOUNT_SUCCESS = 'EDIT_BUSINESS_ACCOUNT_SUCCESS'
export const EDIT_BUSINESS_ACCOUNT_ERROR = 'EDIT_BUSINESS_ACCOUNT_ERROR'

export const ADD_WORKING_EXPERIENCE = 'ADD_WORKING_EXPERIENCE'
export const ADD_WORKING_EXPERIENCE_REQUEST = 'ADD_WORKING_EXPERIENCE_REQUEST'
export const ADD_WORKING_EXPERIENCE_SUCCESS = 'ADD_WORKING_EXPERIENCE_SUCCESS'
export const ADD_WORKING_EXPERIENCE_ERROR = 'ADD_WORKING_EXPERIENCE_ERROR'

export const UPDATE_WORKING_EXPERIENCE = 'UPDATE_WORKING_EXPERIENCE'
export const UPDATE_WORKING_EXPERIENCE_REQUEST = 'UPDATE_WORKING_EXPERIENCE_REQUEST'
export const UPDATE_WORKING_EXPERIENCE_SUCCESS = 'UPDATE_WORKING_EXPERIENCE_SUCCESS'
export const UPDATE_WORKING_EXPERIENCE_ERROR = 'UPDATE_WORKING_EXPERIENCE_ERROR'

export const ADD_EDUCATION = 'ADD_EDUCATION'
export const ADD_EDUCATION_REQUEST = 'ADD_EDUCATION_REQUEST'
export const ADD_EDUCATION_SUCCESS = 'ADD_EDUCATION_SUCCESS'
export const ADD_EDUCATION_ERROR = 'ADD_EDUCATION_ERROR'

export const UPDATE_EDUCATION = 'UPDATE_EDUCATION'
export const UPDATE_EDUCATION_REQUEST = 'UPDATE_EDUCATION_REQUEST'
export const UPDATE_EDUCATION_SUCCESS = 'UPDATE_EDUCATION_SUCCESS'
export const UPDATE_EDUCATION_ERROR = 'UPDATE_EDUCATION_ERROR'

export const ADD_LICENSE = 'ADD_LICENSE'
export const ADD_LICENSE_REQUEST = 'ADD_LICENSE_REQUEST'
export const ADD_LICENSE_SUCCESS = 'ADD_LICENSE_SUCCESS'
export const ADD_LICENSE_ERROR = 'ADD_LICENSE_ERROR'

export const UPDATE_LICENSE = 'UPDATE_LICENSE'
export const UPDATE_LICENSE_REQUEST = 'UPDATE_LICENSE_REQUEST'
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS'
export const UPDATE_LICENSE_ERROR = 'UPDATE_LICENSE_ERROR'

export const GET_WORK_EXPERIENCE = 'GET_WORK_EXPERIENCE'
export const GET_WORK_EXPERIENCE_REQUEST = 'GET_WORK_EXPERIENCE_REQUEST'
export const GET_WORK_EXPERIENCE_SUCCESS = 'GET_WORK_EXPERIENCE_SUCCESS'
export const GET_WORK_EXPERIENCE_ERROR = 'GET_WORK_EXPERIENCE_ERROR'

export const GET_EDUCATION = 'GET_EDUCATION'
export const GET_EDUCATION_REQUEST = 'GET_EDUCATION_REQUEST'
export const GET_EDUCATION_SUCCESS = 'GET_EDUCATION_SUCCESS'
export const GET_EDUCATION_ERROR = 'GET_EDUCATION_ERROR'

export const REMOVE_LICENSE = 'REMOVE_LICENSE'
export const REMOVE_LICENSE_REQUEST = 'REMOVE_LICENSE_REQUEST'
export const REMOVE_LICENSE_SUCCESS = 'REMOVE_LICENSE_SUCCESS'
export const REMOVE_LICENSE_ERROR = 'REMOVE_LICENSE_ERROR'

export const REMOVE_WORK_EXPERIENCE = 'REMOVE_WORK_EXPERIENCE'
export const REMOVE_WORK_EXPERIENCE_REQUEST = 'REMOVE_WORK_EXPERIENCE_REQUEST'
export const REMOVE_WORK_EXPERIENCE_SUCCESS = 'REMOVE_WORK_EXPERIENCE_SUCCESS'
export const REMOVE_WORK_EXPERIENCE_ERROR = 'REMOVE_WORK_EXPERIENCE_ERROR'

export const REMOVE_EDUCATION = 'REMOVE_EDUCATION'
export const REMOVE_EDUCATION_REQUEST = 'REMOVE_EDUCATION_REQUEST'
export const REMOVE_EDUCATION_SUCCESS = 'REMOVE_EDUCATION_SUCCESS'
export const REMOVE_EDUCATION_ERROR = 'REMOVE_EDUCATION_ERROR'

export const GET_LICENSES = 'GET_LICENSES'
export const GET_LICENSES_REQUEST = 'GET_LICENSES_REQUEST'
export const GET_LICENSES_SUCCESS = 'GET_LICENSES_SUCCESS'
export const GET_LICENSES_ERROR = 'GET_LICENSES_ERROR'

export const GET_PUBLICATIONS = 'GET_PUBLICATIONS'
export const GET_PUBLICATIONS_REQUEST = 'GET_PUBLICATIONS_REQUEST'
export const GET_PUBLICATIONS_SUCCESS = 'GET_PUBLICATIONS_SUCCESS'
export const GET_PUBLICATIONS_ERROR = 'GET_PUBLICATIONS_ERROR'

export const ADD_PUBLICATION = 'ADD_PUBLICATION'
export const ADD_PUBLICATION_REQUEST = 'ADD_PUBLICATION_REQUEST'
export const ADD_PUBLICATION_SUCCESS = 'ADD_PUBLICATION_SUCCESS'
export const ADD_PUBLICATION_ERROR = 'ADD_PUBLICATION_ERROR'

export const REMOVE_PUBLICATION = 'REMOVE_PUBLICATION'
export const REMOVE_PUBLICATION_REQUEST = 'REMOVE_PUBLICATION_REQUEST'
export const REMOVE_PUBLICATION_SUCCESS = 'REMOVE_PUBLICATION_SUCCESS'
export const REMOVE_PUBLICATION_ERROR = 'REMOVE_PUBLICATION_ERROR'

export const UPDATE_PUBLICATION = 'UPDATE_PUBLICATION'
export const UPDATE_PUBLICATION_REQUEST = 'UPDATE_PUBLICATION_REQUEST'
export const UPDATE_PUBLICATION_SUCCESS = 'UPDATE_PUBLICATION_SUCCESS'
export const UPDATE_PUBLICATION_ERROR = 'UPDATE_PUBLICATION_ERROR'

export const RESET_USER_PROFILE_VIEW = 'RESET_USER_PROFILE_VIEW'

export const UPDATE_RELATED_ACCOUNTS = 'UPDATE_RELATED_ACCOUNTS'

export const getUserProfileBasicInformationRequest = () =>
  action(GET_USER_PROFILE_BASIC_INFORMATION_REQUEST)
export const getUserProfileBasicInformationSuccess = (payload: TUserProfileBasicInformationInfo) =>
  action(GET_USER_PROFILE_BASIC_INFORMATION_SUCCESS, payload)
export const getUserProfileBasicInformationError = (payload: TError) =>
  action(GET_USER_PROFILE_BASIC_INFORMATION_ERROR, payload)

export const changeUserProfileAvatarRequest = (payload: TChangeUserProfileAvatarPayload) =>
  action(CHANGE_USER_PROFILE_AVATAR_REQUEST, payload)
export const changeUserProfileAvatarSuccess = () => action(CHANGE_USER_PROFILE_AVATAR_SUCCESS)
export const changeUserProfileAvatarError = (payload: TError) =>
  action(CHANGE_USER_PROFILE_AVATAR_ERROR, payload)

export const deleteUserProfileAvatarRequest = () => action(DELETE_USER_PROFILE_AVATAR_REQUEST)
export const deleteUserProfileAvatarSuccess = () => action(DELETE_USER_PROFILE_AVATAR_SUCCESS)
export const deleteUserProfileAvatarError = (payload: TError) =>
  action(DELETE_USER_PROFILE_AVATAR_ERROR, payload)

export const editUserProfileBasicInformationRequest = (
  payload: TEditUserProfileBasicInformationPayload
) => action(EDIT_USER_PROFILE_BASIC_INFORMATION_REQUEST, payload)
export const editUserProfileBasicInformationSuccess = () =>
  action(EDIT_USER_PROFILE_BASIC_INFORMATION_SUCCESS)
export const editUserProfileBasicInformationError = (payload: TError) =>
  action(EDIT_USER_PROFILE_BASIC_INFORMATION_ERROR, payload)

export const getUserProfileOnTheWebRequest = () => action(GET_USER_PROFILE_ON_THE_WEB_REQUEST)
export const getUserProfileOnTheWebSuccess = () => action(GET_USER_PROFILE_ON_THE_WEB_SUCCESS)
export const getUserProfileOnTheWebError = (payload: TError) =>
  action(GET_USER_PROFILE_ON_THE_WEB_ERROR, payload)

export const setUserProfileOnTheWebIds = (payload: string[]) =>
  action(SET_USER_PROFILE_ON_THE_WEB_IDS, payload)
export const setUserProfileOnTheWebList = (payload: TWebsiteList) =>
  action(SET_USER_PROFILE_ON_THE_WEB_LIST, payload)

export const setUserProfileOnTheWebEditState = (payload: TOnTheWebChangeEditStatePayload) =>
  action(SET_USER_PROFILE_ON_THE_WEB_EDIT_STATE, payload)
export const setUserProfileOnTheWebUrl = (payload: TOnTheWebChangeUrlPayload) =>
  action(SET_USER_PROFILE_ON_THE_WEB_URL, payload)

export const addUserProfileWebsite = () => action(ADD_USER_PROFILE_WEBSITE)

// --- GET_USER_PROFILE_BY_ID ---

export const getUserProfileByIdRequest = (payload: TGetUserProfileByIdPayload) =>
  action(GET_USER_PROFILE_BY_ID_REQUEST, payload)
export const getUserProfileByIdSuccess = (payload: TGetUserProfileByIdSuccessPayload) =>
  action(GET_USER_PROFILE_BY_ID_SUCCESS, payload)
export const getUserProfileByIdError = (payload: TError) =>
  action(GET_USER_PROFILE_BY_ID_ERROR, payload)

export const getUserProfileProfessionsRequest = () => action(GET_USER_PROFILE_PROFESSIONS_REQUEST)
export const getUserProfileProfessionsSuccess = () => action(GET_USER_PROFILE_PROFESSIONS_SUCCESS)
export const getUserProfileProfessionsError = (payload: TError) =>
  action(GET_USER_PROFILE_PROFESSIONS_ERROR, payload)

export const setUserProfileProfessionsIds = (payload: string[]) =>
  action(SET_USER_PROFILE_PROFESSIONS_IDS, payload)
export const setUserProfileProfessionsList = (payload: TProfessionsList) =>
  action(SET_USER_PROFILE_PROFESSIONS_LIST, payload)
export const setUserProfileProfessionsNewIds = (payload: string[]) =>
  action(SET_USER_PROFILE_PROFESSIONS_NEW_IDS, payload)

export const addUserProfileProfessionNewId = () => action(ADD_USER_PROFILE_PROFESSIONS_NEW_ID)

export const getAvailableProfessionsRequest = () => action(GET_AVAILABLE_PROFESSIONS_REQUEST)
export const getAvailableProfessionsSuccess = (payload: TOption[]) =>
  action(GET_AVAILABLE_PROFESSIONS_SUCCESS, payload)
export const getAvailableProfessionsError = (payload: TError) =>
  action(GET_AVAILABLE_PROFESSIONS_ERROR, payload)

export const deleteUserProfileNewProfession = (payload: TDeleteUserProfileNewProfessionPayload) =>
  action(DELETE_USER_PROFILE_NEW_PROFESSION, payload)

export const addUserProfileProfessionRequest = (payload: TAddUserProfileProfessionRequestPayload) =>
  action(ADD_USER_PROFILE_PROFESSION_REQUEST, payload)
export const addUserProfileProfessionSuccess = () => action(ADD_USER_PROFILE_PROFESSION_SUCCESS)
export const addUserProfileProfessionError = (payload: TError) =>
  action(ADD_USER_PROFILE_PROFESSION_ERROR, payload)

export const deleteUserProfileProfessionRequest = (
  payload: TDeleteUserProfileProfessionRequestPayload
) => action(DELETE_USER_PROFILE_PROFESSION_REQUEST, payload)
export const deleteUserProfileProfessionSuccess = () =>
  action(DELETE_USER_PROFILE_PROFESSION_SUCCESS)
export const deleteUserProfileProfessionError = (payload: TError) =>
  action(DELETE_USER_PROFILE_PROFESSION_ERROR, payload)

export const editUserProfileProfessionRequest = (
  payload: TEditUserProfileProfessionRequestPayload
) => action(EDIT_USER_PROFILE_PROFESSION_REQUEST, payload)
export const editUserProfileProfessionSuccess = () => action(EDIT_USER_PROFILE_PROFESSION_SUCCESS)
export const editUserProfileProfessionError = (payload: TError) =>
  action(EDIT_USER_PROFILE_PROFESSION_ERROR, payload)

export const addUserProfileSpecializationRequest = (
  payload: TAddUserProfileSpecializationRequestPayload
) => action(ADD_USER_PROFILE_SPECIALIZATION_REQUEST, payload)
export const addUserProfileSpecializationSuccess = () =>
  action(ADD_USER_PROFILE_SPECIALIZATION_SUCCESS)
export const addUserProfileSpecializationError = (payload: TError) =>
  action(ADD_USER_PROFILE_SPECIALIZATION_ERROR, payload)

export const deleteUserProfileSpecializationRequest = (
  payload: TDeleteUserProfileSpecializationRequestPayload
) => action(DELETE_USER_PROFILE_SPECIALIZATION_REQUEST, payload)
export const deleteUserProfileSpecializationSuccess = () =>
  action(DELETE_USER_PROFILE_SPECIALIZATION_SUCCESS)
export const deleteUserProfileSpecializationError = (payload: TError) =>
  action(DELETE_USER_PROFILE_SPECIALIZATION_ERROR, payload)

export const setUserProfileProfessionLoaders = (payload: TSetUserProfileProfessionLoadersPayload) =>
  action(SET_USER_PROFILE_PROFESSION_LOADERS, payload)

export const editUserProfileOnTheWebRequest = (payload: TEditUserProfileWebsitePayload) =>
  action(EDIT_USER_PROFILE_ON_THE_WEB_REQUEST, payload)
export const editUserProfileOnTheWebSuccess = (payload: TOnTheWeb) =>
  action(EDIT_USER_PROFILE_ON_THE_WEB_SUCCESS, payload)
export const editUserProfileOnTheWebError = (payload: TError) =>
  action(EDIT_USER_PROFILE_ON_THE_WEB_ERROR, payload)

export const deleteUserProfileWebsiteRequest = (payload: TDeleteUserProfileWebsite) =>
  action(DELETE_USER_PROFILE_WEBSITE_REQUEST, payload)
export const deleteUserProfileWebsiteSuccess = () => action(DELETE_USER_PROFILE_WEBSITE_SUCCESS)
export const deleteUserProfileWebsiteError = (payload: TError) =>
  action(DELETE_USER_PROFILE_WEBSITE_ERROR, payload)

export const setUserProfileWebsiteProcessing = (payload: TSetUserProfileWebsiteProcessingPayload) =>
  action(SET_USER_PROFILE_WEBSITE_PROCESSING, payload)

export const getRelatedAccountsRequest = () => action(GET_RELATED_ACCOUNTS_REQUEST)
export const getRelatedAccountsSuccess = (payload: TGetRelatedAccountsSuccessPayload) =>
  action(GET_RELATED_ACCOUNTS_SUCCESS, payload)
export const getRelatedAccountsError = (payload: TError) =>
  action(GET_RELATED_ACCOUNTS_ERROR, payload)

export const switchAccountRequest = (payload: TSwitchAccountRequestPayload) =>
  action(SWITCH_ACCOUNT_REQUEST, payload)
export const switchAccountEvent = (payload: TSwitchAccountEventPayload) =>
  action(SWITCH_ACCOUNT_EVENT, payload)
export const switchAccountError = (payload: TError) => action(SWITCH_ACCOUNT_ERROR, payload)

export const editBusinessAccountRequest = (payload: TEditBusinessAccountRequestPayload) =>
  action(EDIT_BUSINESS_ACCOUNT_REQUEST, payload)
export const editBusinessAccountSuccess = () => action(EDIT_BUSINESS_ACCOUNT_SUCCESS)
export const editBusinessAccountError = (payload: TError) =>
  action(EDIT_BUSINESS_ACCOUNT_ERROR, payload)

export const addWorkingExperienceRequest = (payload: TWorkingExperience) =>
  action(ADD_WORKING_EXPERIENCE_REQUEST, payload)
export const addWorkingExperienceSuccess = () => action(ADD_WORKING_EXPERIENCE_SUCCESS)
export const addWorkingExperienceError = (payload: TError) =>
  action(ADD_WORKING_EXPERIENCE_ERROR, payload)

export const updateWorkingExperienceRequest = (payload: TUpdateWorkingExperienceRequestPayload) =>
  action(UPDATE_WORKING_EXPERIENCE_REQUEST, payload)
export const updateWorkingExperienceSuccess = (payload: TUpdateWorkingExperienceSuccessPayload) =>
  action(UPDATE_WORKING_EXPERIENCE_SUCCESS, payload)
export const updateWorkingExperienceError = (payload: TUpdateWorkingExperienceErrorPayload) =>
  action(UPDATE_WORKING_EXPERIENCE_ERROR, payload)

export const addLicenseRequest = (payload: TAddLicenseRequestPayload) =>
  action(ADD_LICENSE_REQUEST, payload)
export const addLicenseSuccess = () => action(ADD_LICENSE_SUCCESS)
export const addLicenseError = (payload: TError) => action(ADD_LICENSE_ERROR, payload)

export const updateLicenseRequest = (payload: TUpdateLicenseRequestPayload) =>
  action(UPDATE_LICENSE_REQUEST, payload)
export const updateLicenseSuccess = (payload: TUpdateLicenseSuccessPayload) =>
  action(UPDATE_LICENSE_SUCCESS, payload)
export const updateLicenseError = (payload: TUpdateLicenseErrorPayload) =>
  action(UPDATE_LICENSE_ERROR, payload)

export const addEducationRequest = (payload: TEducation) => action(ADD_EDUCATION_REQUEST, payload)
export const addEducationSuccess = () => action(ADD_EDUCATION_SUCCESS)
export const addEducationError = (payload: TError) => action(ADD_EDUCATION_ERROR, payload)

export const updateEducationRequest = (payload: TUpdateEducationRequestPayload) =>
  action(UPDATE_EDUCATION_REQUEST, payload)
export const updateEducationSuccess = (payload: TUpdateEducationSuccessPayload) =>
  action(UPDATE_EDUCATION_SUCCESS, payload)
export const updateEducationError = (payload: TUpdateEducationErrorPayload) =>
  action(UPDATE_EDUCATION_ERROR, payload)

export const getWorkExperienceRequest = () => action(GET_WORK_EXPERIENCE_REQUEST)
export const getWorkExperienceSuccess = (payload: TGetWorkExperienceSuccessPayload) =>
  action(GET_WORK_EXPERIENCE_SUCCESS, payload)
export const getWorkExperienceError = (payload: TError) =>
  action(GET_WORK_EXPERIENCE_ERROR, payload)

export const removeWorkExperienceRequest = (payload: TRemoveWorkExperienceRequestPayload) =>
  action(REMOVE_WORK_EXPERIENCE_REQUEST, payload)
export const removeWorkExperienceSuccess = () => action(REMOVE_WORK_EXPERIENCE_SUCCESS)
export const removeWorkExperienceError = (payload: TError) =>
  action(REMOVE_WORK_EXPERIENCE_ERROR, payload)

export const getEducationRequest = () => action(GET_EDUCATION_REQUEST)
export const getEducationSuccess = (payload: TGetEducationSuccessPayload) =>
  action(GET_EDUCATION_SUCCESS, payload)
export const getEducationError = (payload: TError) => action(GET_EDUCATION_ERROR, payload)

export const removeLicenseRequest = (payload: TRemoveLicenseRequestPayload) =>
  action(REMOVE_LICENSE_REQUEST, payload)
export const removeLicenseSuccess = () => action(REMOVE_LICENSE_SUCCESS)
export const removeLicenseError = (payload: TError) => action(REMOVE_LICENSE_ERROR, payload)

export const removeEducationRequest = (payload: TRemoveEducationRequestPayload) =>
  action(REMOVE_EDUCATION_REQUEST, payload)
export const removeEducationSuccess = () => action(REMOVE_EDUCATION_SUCCESS)
export const removeEducationError = (payload: TError) => action(REMOVE_EDUCATION_ERROR, payload)

export const getLicensesRequest = () => action(GET_LICENSES_REQUEST)
export const getLicensesSuccess = (payload: TGetLicensesSuccessPayload) =>
  action(GET_LICENSES_SUCCESS, payload)
export const getLicensesError = (payload: TError) => action(GET_LICENSES_ERROR, payload)

export const getPublicationsRequest = () => action(GET_PUBLICATIONS_REQUEST)
export const getPublicationsSuccess = (payload: TGetPublicationsSuccessPayload) =>
  action(GET_PUBLICATIONS_SUCCESS, payload)
export const getPublicationsError = (payload: TError) => action(GET_PUBLICATIONS_ERROR, payload)

export const addPublicationRequest = (payload: TAddPublicationRequestPayload) =>
  action(ADD_PUBLICATION_REQUEST, payload)
export const addPublicationSuccess = () => action(ADD_PUBLICATION_SUCCESS)
export const addPublicationError = (payload: TError) => action(ADD_PUBLICATION_ERROR, payload)

export const removePublicationRequest = (payload: TRemovePublicationRequestPayload) =>
  action(REMOVE_PUBLICATION_REQUEST, payload)
export const removePublicationSuccess = () => action(REMOVE_PUBLICATION_SUCCESS)
export const removePublicationError = (payload: TError) => action(REMOVE_PUBLICATION_ERROR, payload)

export const updatePublicationRequest = (payload: TUpdatePublicationRequestPayload) =>
  action(UPDATE_PUBLICATION_REQUEST, payload)
export const updatePublicationSuccess = (payload: TUpdatePublicationSuccessPayload) =>
  action(UPDATE_PUBLICATION_SUCCESS, payload)
export const updatePublicationError = (payload: TUpdatePublicationErrorPayload) =>
  action(UPDATE_PUBLICATION_ERROR, payload)

export const resetUserProfileView = () => action(RESET_USER_PROFILE_VIEW)

export const updateRelatedAccounts = () => action(UPDATE_RELATED_ACCOUNTS)
