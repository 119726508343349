import { useCallback, useRef, useState } from 'react'
import { ReactCrop, ReactCropProps, centerCrop, makeAspectCrop, Crop } from 'react-image-crop'
import cls from 'classnames'

import { getCroppedBlob } from 'utils'
import { useEvent } from 'App/hooks'
import 'react-image-crop/src/ReactCrop.scss'

import styles from './CropAvatar.module.scss'

export type TCropAvatarProps = Pick<ReactCropProps, 'circularCrop'> & {
  imageSrc: string
  onCrop: (croppedFile: File) => void

  initialCrop?: Partial<Pick<Crop, 'width'>> & {
    aspect?: number
  }
  allowBlank?: boolean
}

const DEFAULT_ASPECT = 1
const DEFAULT_WIDTH = 50
const MIN_HEIGHT = 40
const MIN_WIDTH = 40

export const CropAvatar = ({
  imageSrc,
  initialCrop,
  circularCrop = true,
  allowBlank = false,
  onCrop
}: TCropAvatarProps) => {
  const [crop, setCrop] = useState<Crop>()

  const imageRef = useRef<HTMLImageElement>(null)
  const imageWrapper = useRef<HTMLDivElement>(null)

  const initialAspect = initialCrop?.aspect ?? DEFAULT_ASPECT
  const initialCropWidth = initialCrop?.width ?? DEFAULT_WIDTH

  const handleComplete = useEvent((completedCrop: Crop) => {
    if (completedCrop && imageRef.current) {
      const blob = getCroppedBlob(completedCrop, imageRef.current, imageWrapper.current)

      if (blob) {
        blob.then((value) => {
          const result = new File([value as Blob], `${new Date().getTime()}.jpeg`, {
            type: (value as Blob)?.type
          })
          onCrop(result)
        })
      }
    }
  })

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget
      const rect = imageWrapper.current?.getBoundingClientRect()

      const updatedCrop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: initialCropWidth
          },
          initialAspect,
          rect?.width ?? width,
          rect?.height ?? height
        ),
        rect?.width ?? width,
        rect?.height ?? height
      )

      setCrop(updatedCrop)
    },
    [initialAspect, initialCropWidth]
  )

  return (
    <ReactCrop
      aspect={initialAspect}
      crop={crop}
      minWidth={MIN_WIDTH}
      minHeight={MIN_HEIGHT}
      keepSelection={true}
      circularCrop={circularCrop}
      className={styles.reactCrop}
      onChange={setCrop}
      onComplete={handleComplete}
    >
      <div
        ref={imageWrapper}
        className={cls(styles.imageWrapper, {
          [styles.withBlank]: allowBlank
        })}
        style={{ aspectRatio: initialCrop?.aspect?.toString() }}
      >
        <img ref={imageRef} alt="crop" src={imageSrc} onLoad={onImageLoad} />
      </div>
    </ReactCrop>
  )
}
