import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { ICaseMembersAccount } from 'interfaces'
import { AccountName } from 'App/components'

import styles from './CaseItemOwner.module.scss'

export type TCaseItemOwnerProps = PropsWithClassName<{
  owner: ICaseMembersAccount
}>

export const CaseItemOwner = ({ owner, className }: TCaseItemOwnerProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, className)}>
      <AccountName
        type={owner.type.name}
        firstName={owner.firstName}
        lastName={owner.lastName}
        userId={owner.id}
        displayUserName={owner.displayUserName}
        size={EAvatarSize.XXS}
        classes={{ title: styles.title, root: styles.account }}
        hideIcon={true}
        shouldRedirectToUserProfile={true}
        showAvatar={true}
      />

      <span className={styles.tag}>({t('cases.details.ownerLabel')})</span>
    </div>
  )
}
