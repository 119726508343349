import { ProfileLinkTypeNamesEnum } from '@medentee/enums'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip, ShowMore } from 'App/components/common'
import { UserProfileDetailsItem } from 'App/components/UserProfile/UserProfileDetailsItem'
import { TGetUserProfileByIdResponse } from 'store/userProfile'
import { ButtonScheduleMeeting } from 'App/components/ButtonScheduleMeeting'
import { ICommunityMemberDTO } from 'interfaces/api/communities'
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg'
import { ReactComponent as WebIcon } from 'assets/icons/Web.svg'
import { formatProfiledProfessions, getTimezone } from 'utils'
import { PublicationsItem } from 'App/components/UserProfile'
import { EducationItem } from 'App/components/UserProfile/Education/EducationItem'
import { UnityHubNetworkingDrawerContent } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerContent'

import { UnityHubNetworkingDrawerItem } from '../../../UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerItem'

import styles from './CommunityMemberDrawerContent.module.scss'

export type TCommunityMemberDrawerContentProps = Pick<ICommunityMemberDTO, 'contact'> & {
  member: TGetUserProfileByIdResponse
  onSuccess: () => void
}

export const CommunityMemberDrawerContent = ({
  member,
  contact,
  onSuccess
}: TCommunityMemberDrawerContentProps) => {
  const { t } = useTranslation()

  const {
    displayUserName,
    city,
    country,
    profileLinks,
    id,
    chatId,
    about,
    professions,
    educations,
    publications,
    isContact,
    isCoworker
  } = member

  const hasConnection = isContact || isCoworker
  const visibleHeight = styles.visibilityHeight

  const formattedProfessions = useMemo(() => formatProfiledProfessions(professions), [professions])
  const websites = useMemo(
    () => profileLinks?.filter((link) => link.type === ProfileLinkTypeNamesEnum.WEBSITE),
    [profileLinks]
  )

  return (
    <UnityHubNetworkingDrawerContent
      account={member}
      contact={contact}
      profileButtonLabel={t('communities.members.member.drawer.goToProfileButton')}
      chatId={member.chatId}
      headline={member.headline}
      actions={
        hasConnection && (
          <ButtonScheduleMeeting
            chatId={chatId}
            partnerAccountId={id}
            userName={displayUserName}
            tooltip={t('communities.members.member.drawer.meetingButtonTooltip')}
          />
        )
      }
      details={
        <>
          <UnityHubNetworkingDrawerItem>
            <UserProfileDetailsItem
              variant="text"
              icon={<LocationOutlineIcon />}
              text={`${country.countryName}, ${city.cityName}`}
            />
            <UserProfileDetailsItem
              variant="text"
              icon={<ClockIcon />}
              text={getTimezone(city.timezone)}
            />
            {!!websites?.length &&
              websites.map(
                (item) =>
                  !!item.url && (
                    <UserProfileDetailsItem
                      key={item.url}
                      variant="link"
                      icon={<WebIcon />}
                      link={item.url}
                    />
                  )
              )}
          </UnityHubNetworkingDrawerItem>

          {about && (
            <UnityHubNetworkingDrawerItem
              title={t('communities.members.member.drawer.section.about')}
            >
              <ShowMore
                text={about}
                classes={{ wrapper: styles.about }}
                visibleHeight={Number(visibleHeight)}
                limitExpandedHeight={false}
              />
            </UnityHubNetworkingDrawerItem>
          )}

          {!!formattedProfessions.length && (
            <UnityHubNetworkingDrawerItem
              title={t('communities.members.member.drawer.section.profession')}
            >
              <div className={styles.professions}>
                {formattedProfessions.map((item) => (
                  <Chip key={item} variant="secondary" text={item} />
                ))}
              </div>
            </UnityHubNetworkingDrawerItem>
          )}

          {!!educations.length && (
            <UnityHubNetworkingDrawerItem
              title={t('communities.members.member.drawer.section.education')}
            >
              <div className={styles.educations}>
                {educations.map((education) => (
                  <EducationItem
                    key={education.id}
                    data={education}
                    disableContextMenu={true}
                    limitExpandedHeight={false}
                  />
                ))}
              </div>
            </UnityHubNetworkingDrawerItem>
          )}

          {!!publications.length && (
            <UnityHubNetworkingDrawerItem
              title={t('communities.members.member.drawer.section.publications')}
            >
              <div className={styles.publications}>
                {publications.map((publication) => (
                  <PublicationsItem
                    key={publication.id}
                    data={publication}
                    disableContextMenu={true}
                  />
                ))}
              </div>
            </UnityHubNetworkingDrawerItem>
          )}
        </>
      }
      onSuccess={onSuccess}
    />
  )
}
