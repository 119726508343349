export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST'
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'

export const RESET_EVENTS = 'RESET_EVENTS'

export const SET_EVENT_ID = 'SET_EVENT_ID'

export const LOST_ACCESS_TO_EVENT = 'LOST_ACCESS_TO_EVENT'

export const DELETE_EVENT = 'DELETE_EVENT'
