import { useTranslation } from 'react-i18next'

import { isAdminAccountSelector, useAppSelector } from 'store'

export const useCaseNotePlaceholder = (nonAdminPlaceholder: string) => {
  const isAdmin = useAppSelector(isAdminAccountSelector)
  const accountData = useAppSelector((state) => state.global.accountData)

  const { t } = useTranslation()

  return isAdmin
    ? t('cases.details.notes.placeholder_admin', { organizationName: accountData?.displayUserName })
    : nonAdminPlaceholder
}
