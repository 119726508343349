import { NotificationListTypeEnum } from '@medentee/enums'

import { Pagination, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { QueryBuilder } from '../utils'
import { INotificationsProtocolDTO, IShowcaseNotificationDTO } from '../interfaces'
import { API, api, APIResultsResponse } from '../services/api'

type TGetNotificationsProtocolListPayload = Partial<Pick<Pagination, 'current' | 'showBy'>>

export const getNotificationsProtocolList = async ({
  current = PAGINATION_DEFAULT_PAGE,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetNotificationsProtocolListPayload) => {
  const url = new QueryBuilder(API.NOTIFICATIONS_PROTOCOL_LIST).page(current).showBy(showBy).build()

  const { data }: APIResultsResponse<INotificationsProtocolDTO[]> = await api.get(url)

  return data
}

type TGetShowcasesNotificationListPayload = Partial<Pick<Pagination, 'current' | 'showBy'>>

export const getShowcasesNotificationList = async ({
  current = PAGINATION_DEFAULT_PAGE,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetShowcasesNotificationListPayload) => {
  const url = new QueryBuilder(API.NOTIFICATIONS_SHOWCASES_LIST)
    .page(current)
    .showBy(showBy)
    .build()

  const { data }: APIResultsResponse<IShowcaseNotificationDTO[]> = await api.get(url)

  return data
}

type TReadNotificationsPayload = {
  types: NotificationListTypeEnum[]

  notificationIds?: string[]
}

export const readNotifications = async ({
  notificationIds,
  types = [NotificationListTypeEnum.DEFAULT]
}: TReadNotificationsPayload) => {
  await api.patch(API.NOTIFICATIONS, {
    types,
    notificationIds
  })
}
