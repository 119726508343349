import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CaseClassificationNameEnum, CaseStatusesEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ContextMenu, StatusColored, TMenuItemProps } from 'App/components/common'
import { EModalComponents } from 'App/containers'
import { showModalAction } from 'store'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
// import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive.svg'
import { ReactComponent as ViewLinkedIcon } from 'assets/icons/EyeOutline.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { useAdaptiveLayout } from 'App/hooks'

import { useUnlockCaseModal } from '../../useUnlockModal'
import { CaseItemInformation, TCaseItemInformationProps } from '../CaseItemInformation'

import styles from './CaseItemHeader.module.scss'

export type TCaseItemHeaderProps = {
  id: string
  isOwner: boolean
  title: string
  status: CaseStatusesEnum
  classification: CaseClassificationNameEnum
  showModal: typeof showModalAction

  autoApproveLabel?: boolean
  type?: null | CaseTypesEnum
} & TCaseItemInformationProps

export const CaseItemHeader = ({
  id,
  isOwner,
  title,
  status,
  classification,
  type,
  autoApproveLabel,
  createdAt,
  updatedAt,
  getCreatedAtLabel,
  getUpdatedAtLabel,
  showModal
}: TCaseItemHeaderProps) => {
  const { t } = useTranslation()
  const { isMobile } = useAdaptiveLayout()

  const casePath = `/cases/${id}/${isOwner ? 'owner' : 'member'}`
  const isProfessionalInitCase =
    classification === CaseClassificationNameEnum.PROFESSIONAL && type === null

  const { showModal: showUnlockModal } = useUnlockCaseModal({
    caseId: id,
    caseName: title,
    caseType: type
  })

  const menuItems: TMenuItemProps[] = useMemo(
    () => [
      {
        content: t('cases.details.header.kebabMenu.lock'),
        icon: <LockIcon />,
        hidden:
          !isOwner ||
          status !== CaseStatusesEnum.ACTIVE ||
          classification === CaseClassificationNameEnum.PRIVATE ||
          isProfessionalInitCase,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_LOCK_CONFIRM,
            modalTitle: t('cases.modal.lockCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.unlock'),
        icon: <LockIcon />,
        hidden: !isOwner || status !== CaseStatusesEnum.LOCKED,
        onClick: showUnlockModal
      },
      // FOR SPECIAL TASK MED-6480
      // {
      //   content: t('cases.details.header.kebabMenu.archive'),
      //   icon: <ArchiveIcon />,
      //   hidden: !isOwner || status === CaseStatusesEnum.ARCHIVED,
      //   onClick: () =>
      //     showModal({
      //       modalType: EModalComponents.CASE_ARCHIVE_CONFIRM,
      //       modalTitle: 'Archive the case?',
      //       modalProps: {
      //         caseId: id
      //       }
      //     })
      // },
      {
        content: t('cases.details.header.kebabMenu.viewLinked'),
        icon: <ViewLinkedIcon />,
        onClick: () =>
          showModal({
            modalType: EModalComponents.LIST_OF_LINKED_CASES,
            modalTitle: t('cases.modal.linkCase.title_list'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.leave'),
        icon: <LogoutIcon />,
        hidden: isOwner,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_MEMBER_LEAVE_CONFIRM,
            modalTitle: t('cases.modal.leaveCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.delete'),
        icon: <TrashIcon />,
        hidden: !isOwner,
        onClick: () =>
          showModal({
            modalType: EModalComponents.DELETE_CASE,
            modalTitle: t('cases.modal.deleteCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      }
    ],
    [isOwner, status, classification, isProfessionalInitCase, showUnlockModal, showModal, id, t]
  )

  return (
    <>
      <div className={styles.root}>
        <div className={styles.main}>
          <StatusColored
            status={status}
            statusLabel={t(`cases.caseStatus.${status}`)}
            className={styles.status}
            variant="chip"
          />

          {!isMobile && (
            <CaseItemInformation
              type={type}
              classification={classification}
              createdAt={createdAt}
              updatedAt={updatedAt}
              getCreatedAtLabel={getCreatedAtLabel}
              getUpdatedAtLabel={getUpdatedAtLabel}
            />
          )}
        </div>

        <div className={styles.aside}>
          {autoApproveLabel && (
            <div className={styles.autoApprove}>{t('cases.list.item.autoApproveLabel')}</div>
          )}

          <ContextMenu menuItems={menuItems} />
        </div>
      </div>

      {isMobile && (
        <CaseItemInformation
          type={type}
          classification={classification}
          createdAt={createdAt}
          updatedAt={updatedAt}
          getCreatedAtLabel={getCreatedAtLabel}
          getUpdatedAtLabel={getUpdatedAtLabel}
        />
      )}

      <Link to={casePath} className={styles.title}>
        {title}
      </Link>
    </>
  )
}
