import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { UnityHubCreateLayout } from 'App/components'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { createCommunity } from '../../../api'
import { CommunityFormContent, TFormValues } from '../../components/Communities'

export const CreateCommunity = () => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation({
    mutationFn: createCommunity,
    onSuccess: ({ id }) => {
      toast.success(t('common.toast.communityCreated'), toastDefaultOptions)
      push(`/community/${id}/info`)
    }
  })

  const initialValues = useMemo<TFormValues>(
    () => ({
      title: '',
      description: '',
      location: { code: 'WORLDWIDE', countryName: t('communities.createCommunity.worldwideLabel') },
      tags: []
    }),
    [t]
  )

  const handleCancel = useCallback(() => push('/community'), [push])

  const handleSubmit = useCallback(
    ({ location, description, tags, title }: TFormValues) =>
      mutate({
        description,
        tags: tags.map((item) => item.text),
        title,
        countryCode: location?.code
      }),
    [mutate]
  )

  return (
    <UnityHubCreateLayout title={t('communities.createCommunityTitle')}>
      <CommunityFormContent
        loading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        initialValues={initialValues}
      />
    </UnityHubCreateLayout>
  )
}
