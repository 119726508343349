import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TChatDefaultMessageContainerProps, EChatDefaultMessageSize } from 'App/containers'
import { ChatDefaultMessageWrapper, ChatDefaultMessageText, AvatarTeam } from 'App/components'

export type TChatDefaultMessageCaseGroupProps = Pick<
  TChatDefaultMessageContainerProps,
  'createdAt' | 'size' | 'chatType' | 'caseTitle'
>

export const ChatDefaultMessageCaseGroup = ({
  createdAt,
  chatType,
  size = EChatDefaultMessageSize.MD,
  caseTitle = ''
}: TChatDefaultMessageCaseGroupProps) => {
  const { t } = useTranslation()

  return (
    <ChatDefaultMessageWrapper chatType={chatType} createdAt={createdAt} size={size}>
      <AvatarTeam border={true} size={EAvatarSize[size]} />
      <ChatDefaultMessageText text={t('chat.placeholder_case', { caseTitle })} />
    </ChatDefaultMessageWrapper>
  )
}
