import uniq from 'lodash/uniq'
import { MessageTypeEnum } from '@medentee/enums'

import { EChatHistoryEvents, EChatMessageAction, ESendMessageStatus } from 'enums'
import { sort } from 'utils'
import {
  DELETE_ALL_MESSAGES_SUCCESS,
  DELETE_FAILED_MESSAGE,
  DELETE_MESSAGE_PERMANENTLY_SUCCESS,
  END_MESSAGE_EDITING_ERROR,
  END_MESSAGE_EDITING_REQUEST,
  END_MESSAGE_EDITING_SUCCESS,
  FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS,
  FILE_REVOKE_PERMISSION_FROM_CHAT_SUCCESS,
  GET_MESSAGES_HISTORY_ERROR,
  GET_MESSAGES_HISTORY_SUCCESS,
  LOSE_ACCESS_TO_FILE,
  QUOTE_MESSAGE_CANCEL,
  READ_ALL_MESSAGES_SUCCESS,
  READ_MESSAGES_ERROR,
  READ_MESSAGES_SUCCESS,
  RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS,
  RECEIVE_MESSAGE_EDITING_END_SUCCESS,
  RECEIVE_MESSAGE_EDITING_START_SUCCESS,
  RECEIVE_NEW_MESSAGE_SUCCESS,
  RESET_CHAT,
  RESET_SELECTED_CHAT,
  SELECT_CHAT,
  SEND_NEW_MESSAGE_ERROR,
  SEND_NEW_MESSAGE_SUCCESS,
  SET_CHAT_HISTORY_EVENT_ACTION,
  SET_CHAT_MESSAGES,
  SET_FAILED_MESSAGES,
  START_MESSAGE_EDITING_ERROR,
  START_MESSAGE_EDITING_SUCCESS,
  TAction,
  TChatHistoryMessage,
  TChatMessagesState,
  TDeleteFailedMessageAction,
  TEndMessageEditingError,
  TEndMessageEditingSuccess,
  TGetMessagesHistoryError,
  TGetMessagesHistorySuccess,
  TLoseAccessToFile,
  TQuoteMessageRequest,
  TReadMessagesError,
  TReadMessagesSuccess,
  TReceiveDeleteMessagePermanentlySuccess,
  TReceiveMessageEditingEndSuccess,
  TReceiveMessageEditingStartSuccess,
  TReceiveNewMessageSuccess,
  TSelectChatAction,
  TSendNewMessageError,
  TSendNewMessageSuccess,
  TSetChatHistoryEventAction,
  TSetChatMessages,
  TSetFailedMessages,
  TSetChatIntermediateStateAction,
  TStartMessageEditingError,
  TStartMessageEditingSuccess,
  TRenameMessageFileAction,
  TUpdateChatDraftSuccess,
  TGetChatDraftSuccess,
  MOVE_FILE_TO_TRASH_BIN_SUCCESS,
  moveFileToTrashBinSuccess,
  TChatMessagesList,
  TChatHistoryEvent,
  TSetMessagesReactionsActionPayload,
  TReceiveAddMessageReactionSuccessPayload,
  TReceiveRemoveMessageReactionPayload,
  PINNED_MESSAGE,
  TPinnedMessagePayload,
  UNPINNED_MESSAGE,
  DELETED_MESSAGE,
  TDeletedMessagePayload,
  SET_MESSAGES_TYPE,
  TSetMessagesTypePayload,
  QUOTE_MESSAGE_SUCCESS
} from 'store'

import {
  DISCARD_FILE_PERMISSION,
  GET_CHAT_DRAFT_SUCCESS,
  RECEIVE_ADD_MESSAGE_REACTION_SUCCESS,
  RECEIVE_REMOVE_MESSAGE_REACTION_SUCCESS,
  RENAME_MESSAGE_FILE,
  RESET_MESSAGES,
  SET_CHAT_INTERMEDIATE_STATE,
  SET_MESSAGES_REACTIONS_ACTION,
  UPDATE_CHAT_DRAFT_SUCCESS
} from './chatMessages.actions'

export const initialChatMessagesState: TChatMessagesState = {
  chatHistoryEvent: {
    type: null,
    payload: undefined
  },
  newLineMessageId: null,
  messages: {
    ids: [],
    list: {}
  },
  reactions: {
    accountList: {},
    messageList: {},
    outgoingList: {}
  },
  selectedChatId: null,
  error: undefined,
  actions: {
    message: null,
    type: null
  },
  hasBefore: true,
  hasAfter: true,
  editingIds: [],
  failedMessages: [],
  draft: {
    message: '',
    quoteMessage: null,
    mentionedAccounts: [],
    mentionedDepartments: []
  },
  type: 'regular'
}

export const chatMessagesReducer = (
  state = initialChatMessagesState,
  action: TAction
): TChatMessagesState => {
  switch (action.type) {
    case RECEIVE_NEW_MESSAGE_SUCCESS: {
      const { message } = (action as TReceiveNewMessageSuccess).payload

      if (state.type === 'pinned' && !message.pin) {
        return state
      }

      const { chatId, id, yourMessage } = message
      const isActiveChat = state.selectedChatId === chatId
      const newMessage: TChatHistoryMessage = {
        ...state.messages.list[id],
        ...message
      }
      const list = { ...state.messages.list }

      if (message.tempId) {
        delete list[message.tempId]
      }

      const chatHistoryEvent: TChatHistoryEvent =
        isActiveChat && !yourMessage
          ? {
              type: EChatHistoryEvents.RECEIVE_MESSAGE,
              payload: { chatId }
            }
          : state.chatHistoryEvent

      return {
        ...state,
        chatHistoryEvent,
        newLineMessageId:
          isActiveChat &&
          state.newLineMessageId &&
          !state.messages.list[state.newLineMessageId]?.recipientRead
            ? state.newLineMessageId
            : id,
        messages: isActiveChat
          ? {
              ids: sort(
                uniq([
                  ...state.messages.ids.map((item) => (item === message.tempId ? id : item)),
                  id
                ])
              ),
              list: {
                ...list,
                [id]: newMessage
              }
            }
          : state.messages
      }
    }

    case SEND_NEW_MESSAGE_SUCCESS:
    case READ_ALL_MESSAGES_SUCCESS: {
      const { data, sender, chatHistoryEvent, fakeMessageId } = (action as TSendNewMessageSuccess)
        .payload
      const { type, id } = data
      const newMessage: TChatHistoryMessage = {
        ...data,
        sender,
        type: type || MessageTypeEnum.TEXT,
        sendStatus: ESendMessageStatus.SENT,
        yourMessage: true
      }

      const { [fakeMessageId]: omitted, ...list } = { ...state.messages.list }

      return {
        ...state,
        chatHistoryEvent,
        messages: {
          ids: sort(uniq(state.messages.ids.map((item) => (item === fakeMessageId ? id : item)))),
          list: {
            ...list,
            [id]: newMessage
          }
        },
        actions: initialChatMessagesState.actions,
        draft: initialChatMessagesState.draft
      }
    }

    case GET_MESSAGES_HISTORY_SUCCESS: {
      const { list, ids, newLineMessageId, chatHistoryEvent, hasBefore, hasAfter } = (
        action as TGetMessagesHistorySuccess
      ).payload

      return {
        ...state,
        chatHistoryEvent,
        newLineMessageId,
        messages: {
          ids,
          list
        },
        hasBefore,
        hasAfter
      }
    }

    case GET_MESSAGES_HISTORY_ERROR: {
      return {
        ...state,
        error: (action as TGetMessagesHistoryError).payload.error
      }
    }

    case READ_MESSAGES_SUCCESS: {
      const { messageIds, readByYou } = (action as TReadMessagesSuccess).payload
      const messages = { ...state.messages }
      const maxId = Math.max(0, ...messageIds.map((id) => parseInt(id, 10))).toString()
      const indexOfMaxId = messages.ids.indexOf(maxId)

      state.messages.ids.forEach((id, index) => {
        const message = messages.list[id]

        if (
          !message.recipientRead &&
          // Mark previous messages as read in a case when they were not tracked
          index <= indexOfMaxId &&
          ((readByYou && !message.yourMessage) || (!readByYou && message.yourMessage))
        ) {
          messages.list[id].recipientRead = true
        }
      })

      return {
        ...state,
        messages: {
          ...state.messages,
          list: {
            ...state.messages.list,
            ...messages.list
          }
        }
      }
    }

    case READ_MESSAGES_ERROR: {
      const { error } = (action as TReadMessagesError).payload

      return {
        ...state,
        error
      }
    }

    case SELECT_CHAT: {
      const { nextChatId } = (action as TSelectChatAction).payload

      return {
        ...state,
        chatHistoryEvent: { ...initialChatMessagesState.chatHistoryEvent },
        newLineMessageId: null,
        messages: {
          ...state.messages,
          list: {},
          ids: []
        },
        type: 'regular',
        selectedChatId: nextChatId,
        actions: initialChatMessagesState.actions,
        hasBefore: true,
        hasAfter: true,
        draft: initialChatMessagesState.draft,
        reactions: initialChatMessagesState.reactions
      }
    }

    case RESET_CHAT:
    case RESET_SELECTED_CHAT: {
      return initialChatMessagesState
    }

    case RESET_MESSAGES: {
      return {
        ...state,
        messages: initialChatMessagesState.messages,
        chatHistoryEvent: initialChatMessagesState.chatHistoryEvent,
        reactions: initialChatMessagesState.reactions,
        newLineMessageId: initialChatMessagesState.newLineMessageId
      }
    }

    case QUOTE_MESSAGE_SUCCESS: {
      const { quoteId } = (action as TQuoteMessageRequest).payload
      const message = state.selectedChatId && quoteId ? state.messages.list[quoteId] ?? null : null

      return {
        ...state,
        actions: {
          message,
          type: EChatMessageAction.QUOTE
        }
      }
    }

    case QUOTE_MESSAGE_CANCEL:
    case END_MESSAGE_EDITING_REQUEST: {
      return {
        ...state,
        actions: initialChatMessagesState.actions
      }
    }

    case START_MESSAGE_EDITING_SUCCESS: {
      const { messageId } = (action as TStartMessageEditingSuccess).payload

      return {
        ...state,
        actions: {
          message: state.messages.list[messageId],
          type: EChatMessageAction.EDIT
        }
      }
    }

    case START_MESSAGE_EDITING_ERROR: {
      return {
        ...state,
        error: (action as TStartMessageEditingError).payload
      }
    }

    case END_MESSAGE_EDITING_SUCCESS: {
      const { messageId, message } = (action as TEndMessageEditingSuccess).payload

      return {
        ...state,
        editingIds: state.editingIds.filter((id) => id !== messageId),
        messages: {
          ...state.messages,
          list: {
            ...Object.values(state.messages.list).reduce<TChatMessagesList>(
              (previous, current) => ({
                ...previous,
                [current.id]: {
                  ...current,
                  quoteMessage:
                    current.quoteMessage?.id === messageId
                      ? {
                          ...current.quoteMessage,
                          message: message ?? current.quoteMessage.message
                        }
                      : current.quoteMessage
                }
              }),
              {}
            ),
            [messageId]: {
              ...state.messages.list[messageId],
              message: state.messages.list[messageId]?.message ?? String(message),
              edited: !!message ?? false,
              recipientRead: false
            }
          }
        }
      }
    }

    case END_MESSAGE_EDITING_ERROR: {
      return {
        ...state,
        error: (action as TEndMessageEditingError).payload
      }
    }

    case RECEIVE_MESSAGE_EDITING_START_SUCCESS: {
      const { id } = (action as TReceiveMessageEditingStartSuccess).payload

      return {
        ...state,
        editingIds: uniq([...state.editingIds, id])
      }
    }

    case RECEIVE_MESSAGE_EDITING_END_SUCCESS: {
      const message = (action as TReceiveMessageEditingEndSuccess).payload

      return {
        ...state,
        messages: {
          ...state.messages,
          list: {
            ...Object.values(state.messages.list).reduce<TChatMessagesList>(
              (previous, current) => ({
                ...previous,
                [current.id]: {
                  ...current,
                  quoteMessage:
                    current.quoteMessage?.id === message.id
                      ? {
                          ...current.quoteMessage,
                          message: message.message,
                          mentionedAccounts: message.mentionedAccounts,
                          mentionedDepartments: message.mentionedDepartments
                        }
                      : current.quoteMessage
                }
              }),
              {}
            ),
            [message.id]: {
              ...state.messages.list[message.id],
              updatedAt: message.updatedAt,
              message: message.message,
              mentionedAccounts: message.mentionedAccounts,
              mentionedDepartments: message.mentionedDepartments
            }
          }
        },
        editingIds: [...state.editingIds.filter((item) => item !== message.id)]
      }
    }

    case SET_CHAT_INTERMEDIATE_STATE: {
      const { editingIds = [] } = (action as TSetChatIntermediateStateAction).payload

      return {
        ...state,
        editingIds: uniq([...state.editingIds, ...editingIds])
      }
    }

    case SET_CHAT_MESSAGES: {
      const { ids, list } = (action as TSetChatMessages).payload

      return {
        ...state,
        messages: {
          ids: ids ?? state.messages.ids,
          list: list ?? state.messages.list
        }
      }
    }

    case DELETE_ALL_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: {
          ...state.messages,
          ids: []
        },
        newLineMessageId: null,
        actions: {
          message: null,
          type: null
        }
      }
    }

    case DELETE_FAILED_MESSAGE: {
      const { messageId } = (action as TDeleteFailedMessageAction).payload

      const { [messageId]: omitted, ...list } = { ...state.messages.list }

      return {
        ...state,
        messages: {
          ...state.messages,
          ids: state.messages.ids.filter((item) => item !== messageId),
          list
        }
      }
    }

    case SEND_NEW_MESSAGE_ERROR: {
      const { messageId } = (action as TSendNewMessageError).payload

      return {
        ...state,
        chatHistoryEvent: {
          type: EChatHistoryEvents.SEND_MESSAGE
        },
        messages: {
          ...state.messages,
          list: {
            ...state.messages.list,
            [messageId]: {
              ...state.messages.list[messageId],
              sendStatus: ESendMessageStatus.FAILED
            }
          }
        },
        actions: initialChatMessagesState.actions
      }
    }

    case DELETE_MESSAGE_PERMANENTLY_SUCCESS:
    case RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS: {
      const { messageId, chatId } = (action as TReceiveDeleteMessagePermanentlySuccess).payload

      if (chatId !== state.selectedChatId) {
        return state
      }

      const list = { ...state.messages.list }
      const ids = [...state.messages.ids]

      if (list[messageId].type === MessageTypeEnum.CHAT_REQUEST) {
        ids.splice(ids.indexOf(messageId), 1)
      } else {
        ids.forEach((item) => {
          if (list[item].quoteMessage?.id === messageId) {
            list[item].quoteMessage = {
              ...list[item].quoteMessage,
              type: MessageTypeEnum.DELETED
            } as TChatHistoryMessage
          }
        })
        list[messageId].type = MessageTypeEnum.DELETED
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          list,
          ids
        }
      }
    }

    case SET_CHAT_HISTORY_EVENT_ACTION: {
      return {
        ...state,
        chatHistoryEvent: { ...(action as TSetChatHistoryEventAction).payload }
      }
    }

    case SET_FAILED_MESSAGES: {
      const failedMessages = (action as TSetFailedMessages).payload

      return {
        ...state,
        failedMessages
      }
    }

    case LOSE_ACCESS_TO_FILE:
    case DISCARD_FILE_PERMISSION: {
      const {
        type,
        payload: { fileId, messageIDs = [] }
      } = action as TLoseAccessToFile
      const messageId = messageIDs[0]

      return {
        ...state,
        messages: {
          ...state.messages,
          list: Object.entries(state.messages.list).reduce<TChatMessagesList>(
            (res, [id, message]) => {
              res[id] = message

              if (message.quoteMessage?.file?.id === fileId) {
                res[id] = {
                  ...message,
                  quoteMessage: {
                    ...message.quoteMessage,
                    file: null,
                    fileId: null,
                    type:
                      type === DISCARD_FILE_PERMISSION
                        ? MessageTypeEnum.DISCARDED_FILE_PERMISSION
                        : MessageTypeEnum.DELETED
                  }
                }
              }

              if (message.file?.id === fileId) {
                res[id] = {
                  ...message,
                  file: null,
                  fileId: null,
                  type:
                    type === DISCARD_FILE_PERMISSION
                      ? MessageTypeEnum.DISCARDED_FILE_PERMISSION
                      : MessageTypeEnum.DELETED
                }
              }

              return res
            },
            {}
          )
        },
        newLineMessageId: messageId === state.newLineMessageId ? null : state.newLineMessageId
      }
    }

    case MOVE_FILE_TO_TRASH_BIN_SUCCESS:
    case FILE_REVOKE_PERMISSION_FROM_CHAT_SUCCESS:
    case FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS: {
      const { fileIds } = (action as ReturnType<typeof moveFileToTrashBinSuccess>).payload

      if (!state.selectedChatId) {
        return state
      }

      return {
        ...state,
        messages: {
          ...state.messages,
          list: state.messages.ids.reduce((res, id) => {
            const message = state.messages.list[id]

            if (
              message &&
              message.quoteMessage?.fileId &&
              fileIds.includes(message.quoteMessage.fileId)
            ) {
              return {
                ...res,
                [id]: {
                  ...message,
                  quoteMessage: {
                    ...message.quoteMessage,
                    file: null,
                    fileId: null,
                    type:
                      action.type === FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS
                        ? MessageTypeEnum.DISCARDED_FILE_PERMISSION
                        : MessageTypeEnum.DELETED
                  }
                }
              }
            }

            if (message && message?.fileId && fileIds.includes(message.fileId)) {
              return {
                ...res,
                [id]: {
                  ...message,
                  file: null,
                  fileId: null,
                  type:
                    action.type === FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS
                      ? MessageTypeEnum.DISCARDED_FILE_PERMISSION
                      : MessageTypeEnum.DELETED
                }
              }
            }

            return { ...res, [id]: message }
          }, {})
        }
      }
    }

    case RENAME_MESSAGE_FILE: {
      const { messageId, fileName } = (action as TRenameMessageFileAction).payload
      const message = state.messages.list[messageId]

      if (message && message.file) {
        return {
          ...state,
          messages: {
            ...state.messages,
            list: {
              ...state.messages.list,
              [messageId]: {
                ...message,
                file: {
                  ...message.file,
                  fileName
                }
              }
            }
          }
        }
      }

      return state
    }

    case GET_CHAT_DRAFT_SUCCESS: {
      const {
        message = state.draft.message,
        quoteMessage = state.draft.quoteMessage,
        mentionedAccounts = state.draft.mentionedAccounts,
        mentionedDepartments = state.draft.mentionedDepartments
      } = (action as TGetChatDraftSuccess).payload || {}

      return {
        ...state,
        draft: {
          message,
          quoteMessage,
          mentionedAccounts,
          mentionedDepartments
        },
        actions: {
          message: quoteMessage,
          type: quoteMessage ? EChatMessageAction.QUOTE : null
        }
      }
    }

    case UPDATE_CHAT_DRAFT_SUCCESS: {
      const { message, quoteMessage, mentionedAccounts, mentionedDepartments } =
        (action as TUpdateChatDraftSuccess).payload || {}

      return {
        ...state,
        draft: {
          message,
          quoteMessage,
          mentionedAccounts,
          mentionedDepartments
        }
      }
    }

    case SET_MESSAGES_REACTIONS_ACTION: {
      const { accountList, messageList, outgoingList } =
        action.payload as TSetMessagesReactionsActionPayload

      return {
        ...state,
        reactions: {
          ...state.reactions,
          accountList: {
            ...state.reactions.accountList,
            ...accountList
          },
          messageList: {
            ...state.reactions.messageList,
            ...messageList
          },
          outgoingList: {
            ...state.reactions.outgoingList,
            ...outgoingList
          }
        }
      }
    }

    case RECEIVE_ADD_MESSAGE_REACTION_SUCCESS: {
      const { account, accountId, messageId, reaction, currentAccountId } =
        action.payload as TReceiveAddMessageReactionSuccessPayload

      const isOutgoing = accountId === currentAccountId

      const { emojis, list } = state.reactions.messageList[messageId] ?? {
        emojis: new Set(),
        list: {}
      }

      const listItem = list[reaction] ?? new Set()

      listItem.add(accountId)
      emojis.add(reaction)

      return {
        ...state,
        reactions: {
          ...state.reactions,
          accountList: {
            ...state.reactions.accountList,
            [accountId]: account
          },
          outgoingList: isOutgoing
            ? {
                ...state.reactions.outgoingList,
                [messageId]: reaction
              }
            : state.reactions.outgoingList,
          messageList: {
            ...state.reactions.messageList,
            [messageId]: {
              emojis: new Set(emojis),
              list: {
                ...list,
                [reaction]: new Set(listItem)
              }
            }
          }
        }
      }
    }

    case RECEIVE_REMOVE_MESSAGE_REACTION_SUCCESS: {
      const { messageId, reaction, accountId, currentAccountId } =
        action.payload as TReceiveRemoveMessageReactionPayload

      const { outgoingList } = state.reactions

      if (accountId === currentAccountId) {
        delete outgoingList[messageId]
      }

      const { emojis, list } = state.reactions.messageList[messageId] ?? {
        emojis: new Set(),
        list: {}
      }

      const listItem = list[reaction] ?? new Set()

      listItem.delete(accountId)

      if (!listItem.size) {
        emojis.delete(reaction)
      }

      return {
        ...state,
        reactions: {
          ...state.reactions,
          outgoingList: {
            ...state.reactions.outgoingList,
            ...outgoingList
          },
          messageList: {
            ...state.reactions.messageList,
            [messageId]: {
              emojis: new Set(emojis),
              list: {
                ...list,
                [reaction]: new Set(listItem)
              }
            }
          }
        }
      }
    }

    case PINNED_MESSAGE: {
      const { messageId } = action.payload as TPinnedMessagePayload

      if (messageId in state.messages.list) {
        return {
          ...state,
          messages: {
            ...state.messages,
            list: {
              ...state.messages.list,
              [messageId]: {
                ...state.messages.list[messageId],
                pin: action.payload
              }
            }
          }
        }
      }

      return state
    }

    case UNPINNED_MESSAGE: {
      const { messageId } = action.payload as TPinnedMessagePayload

      if (messageId in state.messages.list) {
        return {
          ...state,
          messages: {
            ...state.messages,
            list: {
              ...state.messages.list,
              [messageId]: {
                ...state.messages.list[messageId],
                pin: null
              }
            }
          }
        }
      }

      return state
    }

    case DELETED_MESSAGE: {
      const { messageId } = action.payload as TDeletedMessagePayload

      const {
        list: { [messageId]: _, ...newList },
        ids
      } = state.messages

      return {
        ...state,
        messages: {
          list: newList,
          ids: ids.filter((id) => id !== messageId)
        }
      }
    }

    case SET_MESSAGES_TYPE: {
      const { type } = action.payload as TSetMessagesTypePayload

      return {
        ...state,
        type
      }
    }

    default:
      return state
  }
}
