import { useEffect, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import cls from 'classnames'

import { accountAvatarURLSelector, setFoldCall, useAppDispatch, useAppSelector } from 'store'
import { useCallCore } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { useVoiceChannelStyles } from 'App/components/Organizations/hooks'

import { CallContext } from '../../Calls/CallContext'

import styles from './VoiceRoom.module.scss'

export const VoiceRoom = () => {
  const dispatch = useAppDispatch()
  const { voiceRoomContainer } = useContext(CallContext)

  const { isMobile } = useAdaptiveLayout()

  const match = useRouteMatch(['/organization/voice-chat/:id', '/organizations/:id/voice-chat/:id'])

  const activeVoiceRoom = useAppSelector((state) => state.organizations.activeVoiceRoom)
  const { id, jwt, organizationId } = activeVoiceRoom ?? {}

  const avatarURL = useAppSelector(accountAvatarURLSelector)
  const accountData = useAppSelector((state) => state.global.accountData)

  const { render, setAnsweredCall, isFullscreen } = useCallCore({
    avatarURL,
    accountData,
    callId: id,
    jwt,
    fixed: !match?.isExact,
    organizationId,
    type: 'channel',
    isMicOn: false
  })

  useEffect(() => {
    if (activeVoiceRoom) {
      setAnsweredCall(true)
    }
  }, [activeVoiceRoom, setAnsweredCall])

  const { style } = useVoiceChannelStyles({
    container: voiceRoomContainer,
    isFullscreen
  })

  useEffect(() => {
    if (isMobile && !match?.isExact) {
      dispatch(setFoldCall({ isFolded: true }))
    }
  }, [match?.isExact, isMobile, dispatch])

  useEffect(
    () => () => {
      dispatch(setFoldCall({ isFolded: false }))
    },
    [dispatch]
  )

  return (
    <div
      style={match?.isExact ? style : undefined}
      className={cls(styles.root, { [styles.fixed]: match?.isExact })}
    >
      {render()}
    </div>
  )
}
