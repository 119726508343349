import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'App/components'
import { ReactComponent as CaseStandardViewIcon } from 'assets/icons/CaseStandardView.svg'
import { ReactComponent as CaseExpandedViewIcon } from 'assets/icons/CaseExpandedView.svg'

import styles from './CaseViewSelect.module.scss'

export type TCaseViewSelectValue = 'standard' | 'expanded'

export type TCaseViewSelectProps = {
  className?: string
  value: TCaseViewSelectValue
  onChange: (value: TCaseViewSelectValue) => void
}

export const CaseViewSelect = ({ onChange, value, className }: TCaseViewSelectProps) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <span className={styles.label}>{t('cases.details.viewSelect.title')}</span>
      <div className={styles.icons}>
        <Tooltip overlay={t('cases.details.viewSelect.tooltip')}>
          <CaseStandardViewIcon
            onClick={() => onChange('standard')}
            className={cls(styles.icon, { [styles.selected]: value === 'standard' })}
          />
        </Tooltip>
        <Tooltip overlay={t('cases.details.viewSelect.tooltip_extended')}>
          <CaseExpandedViewIcon
            onClick={() => onChange('expanded')}
            className={cls(styles.icon, { [styles.selected]: value === 'expanded' })}
          />
        </Tooltip>
      </div>
    </div>
  )
}
