import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import {
  EModalComponents,
  HeaderNotificationsBadgeContainer,
  THeaderNotificationsContainerProps
} from 'App/containers'
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg'
import { Popover } from 'App/components/common'
import { NotificationsPopover } from 'App/containers/Notifications/NotificationsPopover'
import { useAdaptiveLayout, useCloseModal, useDialog } from 'App/hooks'
import { showModalAction, useAppDispatch } from 'store'

import styles from './HeaderNotifications.module.scss'

export type THeaderNotificationsProps = THeaderNotificationsContainerProps

export const HeaderNotifications = () => {
  const dispatch = useAppDispatch()

  const { isMobile } = useAdaptiveLayout()

  const { pathname, search } = useLocation()

  const { open, close, setOpen } = useDialog()

  const handleCloseModal = useCloseModal([EModalComponents.NOTIFICATIONS])

  useEffect(
    () => () => {
      setOpen(false)
    },
    // `pathname` and `search` properties are essential here
    [pathname, search, setOpen]
  )

  useEffect(() => {
    handleCloseModal()
    setOpen(false)
    // `isMobile`, `pathname`, `search` properties are essential here
  }, [dispatch, setOpen, isMobile, pathname, search, handleCloseModal])

  const handleOpenModal = () => {
    if (isMobile) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.NOTIFICATIONS,
          modalTitle: '',
          className: styles.modal,
          modalProps: {
            onClick: handleCloseModal
          }
        })
      )
    }
  }

  const handleVisibilityChange = (value: boolean) => {
    isMobile ? setOpen(false) : setOpen(value)
  }

  return (
    <div className={styles.root}>
      <Popover
        overlayClassName={styles.popover}
        trigger="click"
        arrow={false}
        content={<NotificationsPopover onClick={close} />}
        destroyTooltipOnHide={true}
        visible={open}
        onVisibleChange={handleVisibilityChange}
      >
        <div className={styles.container} onClick={handleOpenModal}>
          <BellIcon className={styles.icon} />
          <HeaderNotificationsBadgeContainer />
        </div>
      </Popover>
    </div>
  )
}
