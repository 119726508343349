import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { EModalComponents } from 'App/containers'

import { AccountsList, Button, FieldLabel } from '../../../components'
import { TAccountsSelectOptionData } from '../../../components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import styles from './ManageDepartmentStaff.module.scss'
import { TManageDepartmentStaffModalProps } from './ManageDepartmentStaffModal'

export type TManageDepartmentStaffProps = Pick<
  TManageDepartmentStaffModalProps,
  'accounts' | 'organizationId'
> & {
  onChange: (accounts: TAccountsSelectOptionData[]) => void
}

export const ManageDepartmentStaff = ({
  organizationId,
  onChange,
  accounts = []
}: TManageDepartmentStaffProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.manageDepartmentStaff.title'),
        modalType: EModalComponents.SELECT_DEPARTMENT_STAFF,
        modalProps: {
          accounts,
          organizationId,
          onSubmit: (value: TAccountsSelectOptionData[]) => {
            onChange(value)
            dispatch(hideModalAction())
          }
        }
      })
    )
  }

  return (
    <div className={styles.root}>
      <FieldLabel label={t('organizations.editDepartment.form.staff')} />
      <div className={styles.staffs}>
        <Button
          variant="text"
          onClick={handleClick}
          className={styles.button}
          icon={<AccountMultiplePlusIcon />}
        >
          {t('organizations.editDepartment.staffButton')}
        </Button>
        <AccountsList
          avatarSize={EAvatarSize.XS}
          accounts={accounts}
          getTitle={(number) =>
            t(`organizations.editDepartment.selectedMembersPopover.title`, { number })
          }
        />
      </div>
    </div>
  )
}
