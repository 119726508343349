import { ItemInfoLabel } from '../ItemInfoLabel'
import { ItemInfoPurpose } from '../ItemInfoPurpose'

import styles from './ItemInfo.module.scss'

type TItemInfoProps = PropsWithClassName & {
  locations: string

  lookingFor?: null | string
  purposes?: null | string[]
}

export const ItemInfo = ({ className, purposes, locations, lookingFor }: TItemInfoProps) => (
  <div className={className}>
    {lookingFor && (
      <ItemInfoLabel className={styles.looking} labelType="looking" text={lookingFor} />
    )}
    <ItemInfoLabel className={styles.location} labelType="location" text={locations} />
    {purposes?.length && <ItemInfoPurpose purposes={purposes} />}
  </div>
)
