import {
  AvailableSubscriptionContainer,
  TAvailableSubscriptionContainerProps
} from 'App/containers'

import { TChangeSubscriptionPlanDialogProps } from '../ChangeSubscriptionPlanDialog'

import styles from './FirstStep.module.scss'

type TFirstStepProps = Pick<TChangeSubscriptionPlanDialogProps, 'ids'> &
  Pick<TAvailableSubscriptionContainerProps, 'handleChooseSubscription'>

export const FirstStep = ({ ids, handleChooseSubscription }: TFirstStepProps) => (
  <div className={styles.root}>
    {ids.map((id) => (
      <AvailableSubscriptionContainer
        id={id}
        key={id}
        handleChooseSubscription={handleChooseSubscription}
      />
    ))}
  </div>
)
