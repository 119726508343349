export enum EModalComponents {
  INVITE_TO_CONTACT_DIALOG = 'INVITE_TO_CONTACT_DIALOG',
  CATEGORY_DIALOG = 'CATEGORY_DIALOG',
  CONFIRM_REMOVE_CATEGORY = 'CONFIRM_REMOVE_CATEGORY',
  CONFIRM_REJECT_WITHDRAW_CONTACT = 'CONFIRM_REJECT_WITHDRAW_CONTACT',
  CASE_INVITE_MEMBERS_DIALOG = 'CASE_INVITE_MEMBERS_DIALOG',
  CASE_CREATE_DIALOG = 'CASE_CREATE_DIALOG',
  CASE_MEMBER_INVITE_REJECT_DIALOG = 'CASE_MEMBER_INVITE_REJECT_DIALOG',
  CASE_MEMBER_INVITE_APPROVE_DIALOG = 'CASE_MEMBER_INVITE_APPROVE_DIALOG',
  CASE_MEMBER_LEAVE_CONFIRM = 'CASE_MEMBER_LEAVE_CONFIRM',
  CASE_REMOVE_MEMBER_CONFIRM = 'CASE_REMOVE_MEMBER_CONFIRM',
  CASE_EDIT_TITLE_DIALOG = 'CASE_EDIT_TITLE_DIALOG',
  CASE_LOCK_CONFIRM = 'CASE_LOCK_CONFIRM',
  CASE_UNLOCK_CONFIRM = 'CASE_UNLOCK_CONFIRM',
  UPGRADE_SUBSCRIPTION = 'UPGRADE_SUBSCRIPTION',
  UPGRADE_SUBSCRIPTION_FAILED = 'UPGRADE_SUBSCRIPTION_FAILED',
  CASE_ARCHIVE_CONFIRM = 'CASE_ARCHIVE_CONFIRM',
  CASE_ATTACH_FILE_DIALOG = 'CASE_ATTACH_FILE_DIALOG',
  CASE_FILE_RENAME_DIALOG = 'CASE_FILE_RENAME_DIALOG',
  FILE_RENAME_DIALOG = 'FILE_RENAME_DIALOG',
  CASE_FILE_DISCARD_PERMISSIONS_CONFIRM = 'CASE_FILE_DISCARD_PERMISSIONS_CONFIRM',
  FILE_DISCARD_PERMISSIONS_CONFIRM = 'FILE_DISCARD_PERMISSIONS_CONFIRM',
  DELETE_USER_PROFILE_PROFESSION_CONFIRM = 'DELETE_USER_PROFILE_PROFESSION_CONFIRM',
  REDIRECT_CONFIRM = 'REDIRECT_CONFIRM',
  DELETE_ALL_MESSAGES_CONFIRM = 'DELETE_ALL_MESSAGES_CONFIRM',
  BROADCAST_MESSAGE_DIALOG = 'BROADCAST_MESSAGE_DIALOG',
  DISCARD_CASES_PERMISSIONS_CONFIRM = 'DISCARD_CASES_PERMISSIONS_CONFIRM',
  CHAT_ATTACH_FILE_DIALOG = 'CHAT_ATTACH_FILE_DIALOG',
  REMOVE_CONTACT_CONFIRM = 'REMOVE_CONTACT_CONFIRM',
  COPY_TO_CASE_CLOUD = 'COPY_TO_CASE_CLOUD',
  CREATE_BUSINESS_ACCOUNT_DIALOG = 'CREATE_BUSINESS_ACCOUNT_DIALOG',
  DELETE_FILE_FROM_MEDCLOUD_CONFIRM = 'DELETE_FILE_FROM_MEDCLOUD_CONFIRM',
  CHAT_DISCARD_PERMISSION_CONFIRM = 'CHAT_DISCARD_PERMISSION_CONFIRM',
  START_GROUP_CALL_DIALOG = 'START_GROUP_CALL_DIALOG',
  ADD_TO_GROUP_CALL_DIALOG = 'ADD_TO_GROUP_CALL_DIALOG',
  LEAVE_PAGE_CONFIRM = 'LEAVE_PAGE_CONFIRM',
  CASE_LINK_DIALOG = 'CASE_LINK_DIALOG',
  LIST_OF_LINKED_CASES = 'LIST_OF_LINKED_CASES',
  CASE_CLOUD_PERMISSIONS_MODE_CHANGE_CONFIRM = 'CASE_CLOUD_PERMISSIONS_MODE_CHANGE_CONFIRM',
  ADD_ADMIN_DIALOG = 'ADD_ADMIN_DIALOG',
  ADD_SUPERADMIN_DIALOG = 'ADD_SUPERADMIN_DIALOG',
  SETTINGS_CASE_AUTO_APPROVE = 'SETTINGS_CASE_AUTO_APPROVE',
  SETTINGS_2FA_CONFIRM = 'SETTINGS_2FA_CONFIRM',
  RESIGN_FROM_ADMIN_ROLE_CONFIRM = 'RESIGN_FROM_ADMIN_ROLE_CONFIRM',
  REMOVE_ADMIN_CONFIRM = 'REMOVE_ADMIN_CONFIRM',
  CONFIRM_BUSINESS_ADMIN_INVITE = 'CONFIRM_BUSINESS_ADMIN_INVITE',
  CHANGE_SUBSCRIPTION_PLAN_DIALOG = 'CHANGE_SUBSCRIPTION_PLAN_DIALOG',
  CHANGE_DEFAULT_ACCOUNT = 'CHANGE_DEFAULT_ACCOUNT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PERSONAL_EMAIL = 'CHANGE_PERSONAL_EMAIL',
  SET_CASE_HISTORY_FILTERS = 'SET_CASE_HISTORY_FILTERS',
  CREATE_ADVERT_DIALOG = 'CREATE_ADVERT_DIALOG',
  REMOVE_REQUEST_DIALOG = 'REMOVE_REQUEST_DIALOG',
  REPOST_ADVERT_DIALOG = 'REPOST_ADVERT_DIALOG',
  DEACTIVATE_ADVERT_DIALOG = 'DEACTIVATE_ADVERT_DIALOG',
  IGNORE_REQUEST_DIALOG = 'IGNORE_REQUEST_DIALOG',
  BECOME_CONTACTS_DIALOG = 'BECOME_CONTACTS_DIALOG',
  WITHDRAW_REQUEST_DIALOG = 'WITHDRAW_REQUEST_DIALOG',
  REMOVE_ADVERT_DIALOG = 'REMOVE_ADVERT_DIALOG',
  CONNECTION_REQUEST_DIALOG = 'CONNECTION_REQUEST_DIALOG',
  REMOVE_LICENSE_DIALOG = 'REMOVE_LICENSE_DIALOG',
  REMOVE_WORKPLACE_DIALOG = 'REMOVE_WORKPLACE_DIALOG',
  REMOVE_EDUCATION_DIALOG = 'REMOVE_EDUCATION_DIALOG',
  WORKPLACE_DIALOG = 'WORKPLACE_DIALOG',
  LICENSE_DIALOG = 'LICENSE_DIALOG',
  EDUCATION_DIALOG = 'EDUCATION_DIALOG',
  PUBLICATION_DIALOG = 'PUBLICATION_DIALOG',
  REMOVE_PUBLICATION_DIALOG = 'REMOVE_PUBLICATION_DIALOG',
  MOVE_FILE_TO_TRASH_BIN_DIALOG = 'MOVE_FILE_TO_TRASH_BIN_DIALOG',
  RESTORE_FILE_DIALOG = 'RESTORE_FILE_DIALOG',
  RENEW_SUBSCRIPTION_PLAN_DIALOG = 'RENEW_SUBSCRIPTION_PLAN_DIALOG',
  DELETE_CASE = 'DELETE_CASE',
  CASE_CONVERT = 'CASE_CONVERT',
  FILES_TILE_VIEW_SORTING_DIALOG = 'FILES_TILE_VIEW_SORTING_DIALOG',
  RELATED_ACCOUNTS_DIALOG = 'RELATED_ACCOUNTS_DIALOG',
  FILE_UPLOAD_WIDGET_DISCARD = 'FILE_UPLOAD_WIDGET_DISCARD',
  SCHEDULE_MEETING_MODAL = 'SCHEDULE_MEETING_MODAL',
  ATTACH_FILES_FROM_LINKED_CASE = 'ATTACH_FILES_FROM_LINKED_CASE',
  MEETINGS = 'MEETINGS',
  CANCEL_MEETING = 'CANCEL_MEETING',
  EDIT_SCHEDULED_MEETING = 'EDIT_SCHEDULED_MEETING',
  EDIT_ONGOING_MEETING = 'EDIT_ONGOING_MEETING',
  CALL_PERFORMANCE_SETTINGS_DIALOG = 'CALL_PERFORMANCE_SETTINGS_DIALOG',
  REMOVE_ORGANIZATION_STAFF_DIALOG = 'REMOVE_ORGANIZATION_STAFF_DIALOG',
  LEAVE_ORGANIZATION_DIALOG = 'LEAVE_ORGANIZATION_DIALOG',
  INVITE_ALL_ORGANIZATION_MEMBERS_DIALOG = 'INVITE_ALL_ORGANIZATION_MEMBERS_DIALOG',
  REDIRECT_TO_SUBSCRIPTION_PAGE = 'REDIRECT_TO_SUBSCRIPTION_PAGE',
  REDIRECT_TO_ORGANIZATION_ADMIN_CHAT = 'REDIRECT_TO_ORGANIZATION_ADMIN_CHAT',
  TOP_UP_ONE_TIME = 'TOP_UP_ONE_TIME',
  TOP_UP_HOLD_ON = 'TOP_UP_HOLD_ON',
  TOP_UP_PAYMENT_SUCCEEDED = 'TOP_UP_PAYMENT_SUCCEEDED',
  TOP_UP_PAYMENT_FAILED = 'TOP_UP_PAYMENT_FAILED',
  TOP_UP_MONTHLY = 'TOP_UP_MONTHLY',
  TOP_UP_MONTHLY_CANCEL = 'TOP_UP_MONTHLY_CANCEL',
  TOP_UP_MONTHLY_SUCCEEDED = 'TOP_UP_MONTHLY_SUCCEEDED',
  UPDATE_SHOWCASE_DIALOG = 'UPDATE_SHOWCASE_DIALOG',
  DEACTIVATE_SHOWCASE = 'DEACTIVATE_SHOWCASE',
  DELETE_SHOWCASE = 'DELETE_SHOWCASE',
  CREATE_TEXT_CHANNEL = 'CREATE_TEXT_CHANNEL',
  COMMUNITY_CREATE_CHANNEL = 'COMMUNITY_CREATE_CHANNEL',
  INVITE_MEMBERS_TEXT_CHANNEL = 'INVITE_MEMBERS_TEXT_CHANNEL',
  CREATE_VOICE_CHANNEL = 'CREATE_VOICE_CHANNEL',
  CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
  SELECT_DEPARTMENT_STAFF = 'SELECT_DEPARTMENT_STAFF',
  SELECT_DEPARTMENT_LEAD = 'SELECT_DEPARTMENT_LEAD',
  ORGANIZATION_DELETE_TEXT_CHANNEL_CONFIRM = 'ORGANIZATION_DELETE_TEXT_CHANNEL_CONFIRM',
  ORGANIZATION_DELETE_VOICE_CHANNEL_CONFIRM = 'ORGANIZATION_DELETE_VOICE_CHANNEL_CONFIRM',
  COMMUNITY_DELETE_TEXT_CHANNEL_CONFIRM = 'COMMUNITY_DELETE_TEXT_CHANNEL_CONFIRM',
  DELETE_DEPARTMENT_DIALOG = 'DELETE_DEPARTMENT_DIALOG',
  REMOVE_STAFF_FROM_DEPARTMENT_DIALOG = 'REMOVE_STAFF_FROM_DEPARTMENT_DIALOG',
  DELETE_SELF_ACCOUNT_CONFIRM = 'DELETE_SELF_ACCOUNT_CONFIRM',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CONNECT_BY_LINK_DIALOG = 'CONNECT_BY_LINK_DIALOG',
  SWITCH_ACCOUNT_DIALOG = 'SWITCH_ACCOUNT_DIALOG',
  INVITATION_LINK_SETTINGS = 'INVITATION_LINK_SETTINGS',
  INVITE_BY_EMAIL = 'INVITE_BY_EMAIL',
  INVITE_TO_EXTERNAL_MEETING_MODAL = 'INVITE_TO_EXTERNAL_MEETING_MODAL',
  REMOVE_GROUP_CHAT_MEMBER_CONFIRM = 'REMOVE_GROUP_CHAT_MEMBER_CONFIRM',
  GROUP_CHAT_ADD_MEMBERS = 'GROUP_CHAT_ADD_MEMBERS',
  DELETE_CHAT_CONFIRM = 'DELETE_CHAT_CONFIRM',
  LEAVE_CHAT_CONFIRM = 'LEAVE_CHAT_CONFIRM',
  DELETE_CHAT_MESSAGE_CONFIRM = 'DELETE_CHAT_MESSAGE_CONFIRM',
  UPLOAD_IMAGE_DIALOG = 'UPLOAD_IMAGE_DIALOG',
  DELETE_IMAGE_DIALOG = 'DELETE_IMAGE_DIALOG',
  COMMUNITY_LEAVE_CHANNEL_CONFIRM = 'COMMUNITY_LEAVE_CHANNEL_CONFIRM',
  REMOVE_COMMUNITY_MEMBER_CONFIRM = 'REMOVE_COMMUNITY_MEMBER_CONFIRM',
  GENERIC_CONFIRMATION = 'GENERIC_CONFIRMATION',
  CHAT_PIN_CONFIRM = 'CHAT_PIN_CONFIRM',
  CHAT_UNPIN_CONFIRM = 'CHAT_UNPIN_CONFIRM',
  EVENT_CREATE_CHANNEL = 'EVENT_CREATE_CHANNEL',
  EVENT_LEAVE_CHANNEL_CONFIRM = 'EVENT_LEAVE_CHANNEL_CONFIRM',
  EVENT_DELETE_TEXT_CHANNEL_CONFIRM = 'EVENT_DELETE_TEXT_CHANNEL_CONFIRM',
  EVENT_CREATE_CONFIRM = 'EVENT_CREATE_CONFIRM',
  CREATE_BROADCAST = 'CREATE_BROADCAST',
  DELETE_BROADCAST_CONFIRM = 'DELETE_BROADCAST_CONFIRM'
}
