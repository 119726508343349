import {
  useEffect,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
  WheelEvent,
  RefObject
} from 'react'
import cls from 'classnames'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'

import { EChatHistoryEvents, EChatViewType } from 'enums'
import { isScrollAtBottom } from 'utils'
import { EHistoryType } from 'globalConstants'
import {
  TChatMessageListContainerProps,
  ChatMessageContainer,
  ChatDefaultMessageContainer,
  EChatDefaultMessageSize
} from 'App/containers'
import { EmptyList, P2P_CHAT_FOOTER_WRAPPER_ID, P2P_CHAT_HEADER_WRAPPER_ID } from 'App/components'
import { useAdaptiveLayout, useLoadingIds, useRefValue } from 'App/hooks'

import { useChatContext } from '../../../context'

import styles from './ChatMessageList.module.scss'
import { ChatMessageListGoToLast } from './ChatMessageListGoToLast'

export type TChatMessageListProps = TChatMessageListContainerProps & {
  classes?: Partial<Record<'scrollInner' | 'scrollContent', string>>
  chatViewType?: EChatViewType
  footerWrapperHeight?: number
  defaultMessageClassName?: string
  caseView?: string
}
export type TScrollChatMessageListRef = HTMLDivElement & { isScrollPositionBottom?: null | boolean }

const DEFAULT_MESSAGES_LENGTH = 4
const TOP_LOADING_THRESHOLD = 0.2
const BOTTOM_LOADING_THRESHOLD = 0.8
const BOTTOM_MARGIN_THRESHOLD = 1.5
const JUMP_TO_LAST_MESSAGE_THRESHOLD = 52

export const ChatMessageList = ({
  ids,
  chatId,
  loading,
  firstMessage,
  lastMessage,
  chatHistoryEvent,
  hasBefore,
  hasAfter,
  browserFocus,
  classes,
  showDefaultMessage,
  defaultMessageClassName,
  caseView,
  chatType,
  noData,
  getMessagesHistory,
  resetMessages,
  chatViewType = EChatViewType.CASE
}: TChatMessageListProps) => {
  const newMessageLineRef = useRef<HTMLDivElement>(null)
  const scrollListRef = useRef<TScrollChatMessageListRef>(null)
  const prevScrollTop = useRef<number>(0)
  const { getValue: getKeepScrollBottom, setValue: setKeepScrollBottom } = useRefValue(true)

  const [showJumpToLastMessage, setShowJumpToLastMessage] = useState<boolean | null>(null)
  const [isScrollPositionBottom, setScrollPositionBottom] = useState<boolean>(false)
  const [messageRef, setMessageRef] = useState<RefObject<HTMLDivElement>>()

  const { isDesktop } = useAdaptiveLayout()
  const { showPinnedMessages } = useChatContext()
  const { getValue: getShowPinnedMessages } = useRefValue(showPinnedMessages)

  const { t } = useTranslation()

  const windowScrollUsed = chatViewType === EChatViewType.P2P && !isDesktop

  const messages = useLoadingIds({ ids, loading, limit: DEFAULT_MESSAGES_LENGTH, direction: 'ASC' })

  useEffect(
    () => () => {
      resetMessages()
    },
    [resetMessages]
  )

  useEffect(() => {
    setKeepScrollBottom(true)
    setShowJumpToLastMessage(null)
    setScrollPositionBottom(true)
  }, [chatId, setKeepScrollBottom, setShowJumpToLastMessage])

  useEffect(() => {
    if (showPinnedMessages) {
      getMessagesHistory({
        chatId,
        onlyPinned: true
      })
    } else {
      getMessagesHistory({
        chatId,
        historyType:
          chatHistoryEvent.type === EChatHistoryEvents.JUMP ? EHistoryType.AROUND : undefined,
        messageId:
          chatHistoryEvent.type === EChatHistoryEvents.JUMP
            ? chatHistoryEvent.payload?.messageId
            : undefined
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, showPinnedMessages, getMessagesHistory])

  const scrollToBottom = (
    isWindowScroll: boolean,
    containerRef: RefObject<HTMLDivElement>,
    containerHeight: number,
    behavior?: ScrollBehavior
  ) => {
    if (isWindowScroll) {
      window.scrollTo({ top: containerHeight, behavior })
    } else {
      containerRef.current && containerRef.current.scrollTo({ top: containerHeight, behavior })
    }
  }

  const scrollToNewMessageLine = (behavior: ScrollBehavior = 'auto') => {
    newMessageLineRef.current?.scrollIntoView({
      behavior,
      block: 'center',
      inline: 'start'
    })
  }

  useLayoutEffect(() => {
    if (
      !chatHistoryEvent ||
      chatHistoryEvent.type !== EChatHistoryEvents.HISTORY_FIRST ||
      !scrollListRef.current
    ) {
      return
    }

    if (newMessageLineRef.current) {
      scrollToNewMessageLine()

      setScrollPositionBottom(false)
    }

    if (!newMessageLineRef.current) {
      setScrollPositionBottom(true)
    }
  }, [ids, chatHistoryEvent, windowScrollUsed])

  useEffect(() => {
    if (chatHistoryEvent && scrollListRef.current) {
      const { scrollHeight, scrollTop } = scrollListRef.current

      switch (chatHistoryEvent.type) {
        case EChatHistoryEvents.SEND_MESSAGE: {
          scrollToBottom(windowScrollUsed, scrollListRef, scrollHeight)
          setScrollPositionBottom(true)

          break
        }
        case EChatHistoryEvents.RECEIVE_MESSAGE: {
          if (chatHistoryEvent.payload?.chatId !== chatId) {
            break
          }

          if (isScrollPositionBottom && browserFocus) {
            scrollToBottom(windowScrollUsed, scrollListRef, scrollHeight)
          }

          if (!isScrollPositionBottom && browserFocus) {
            if (windowScrollUsed) {
              const windowScrollTop = window.scrollY

              window.scrollTo({ top: windowScrollTop })
            } else {
              scrollListRef.current.scrollTo({ top: scrollTop })
            }
          }

          if (newMessageLineRef.current && isScrollPositionBottom && !browserFocus) {
            scrollToNewMessageLine()
          }

          break
        }
        case EChatHistoryEvents.JUMP: {
          const payload = chatHistoryEvent.payload

          if (payload && !ids.includes(payload?.messageId)) {
            getMessagesHistory({
              chatId,
              historyType: EHistoryType.AROUND,
              messageId: payload.messageId
            })

            break
          }

          if (messageRef && messageRef.current) {
            messageRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start'
            })
          }

          break
        }
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids, chatHistoryEvent, messageRef, getMessagesHistory, windowScrollUsed])

  useEffect(() => {
    const callback = debounce(() => {
      if (getKeepScrollBottom()) {
        const scrollHeight = scrollListRef?.current?.scrollHeight || 0
        scrollToBottom(windowScrollUsed, scrollListRef, scrollHeight, 'auto')
      }
    }, 50)

    const resizeObserver = new ResizeObserver(callback)
    const mutationObserver = new MutationObserver(callback)

    if (scrollListRef?.current) {
      resizeObserver.observe(scrollListRef?.current)
      mutationObserver.observe(scrollListRef?.current, { childList: true, subtree: true })
    }

    return () => {
      callback.cancel()
      resizeObserver.disconnect()
      mutationObserver.disconnect()
    }
  }, [windowScrollUsed, scrollListRef.current, getKeepScrollBottom])

  const getBeforeMessages = useCallback(() => {
    if (firstMessage) {
      getMessagesHistory({
        chatId,
        onlyPinned: getShowPinnedMessages(),
        historyType: EHistoryType.BEFORE,
        messageId: firstMessage.id
      })
    }
  }, [firstMessage, chatId, getMessagesHistory])

  const getAfterMessages = useCallback(() => {
    if (lastMessage) {
      getMessagesHistory({
        chatId,
        onlyPinned: getShowPinnedMessages(),
        historyType: EHistoryType.AFTER,
        messageId: lastMessage.id
      })
    }
  }, [lastMessage, chatId, getMessagesHistory])

  const onScroll = useCallback(
    (event) => {
      let scrollTop: number
      let clientHeight: number
      let scrollHeight: number
      let margin: number | undefined

      if (windowScrollUsed) {
        const headerWrapperHeight =
          document.getElementById(P2P_CHAT_HEADER_WRAPPER_ID)?.clientHeight || 0
        const footerWrapperHeight =
          document.getElementById(P2P_CHAT_FOOTER_WRAPPER_ID)?.clientHeight || 0

        scrollTop = window.scrollY
        scrollHeight = scrollListRef?.current?.scrollHeight || 0
        clientHeight = window.screen.height - footerWrapperHeight - headerWrapperHeight
        margin = footerWrapperHeight / BOTTOM_MARGIN_THRESHOLD
      } else {
        scrollTop = event.currentTarget.scrollTop
        clientHeight = event.currentTarget.clientHeight
        scrollHeight = event.currentTarget.scrollHeight
      }

      const isBottom = isScrollAtBottom(scrollTop, scrollHeight, clientHeight, margin)
      setKeepScrollBottom(isBottom)

      const scrollUp = scrollTop <= prevScrollTop.current
      if (scrollUp && scrollTop / scrollHeight <= TOP_LOADING_THRESHOLD && hasBefore && !loading) {
        getBeforeMessages()
      }

      if (
        !scrollUp &&
        (scrollTop + clientHeight) / scrollHeight >= BOTTOM_LOADING_THRESHOLD &&
        hasAfter &&
        !loading
      ) {
        getAfterMessages()
      }

      if (isBottom && !isScrollPositionBottom) {
        setScrollPositionBottom(true)

        if (scrollListRef.current) {
          scrollListRef.current.isScrollPositionBottom = true
        }
      }

      if (!isBottom && isScrollPositionBottom) {
        setScrollPositionBottom(false)

        if (scrollListRef.current) {
          scrollListRef.current.isScrollPositionBottom = false
        }
      }

      const distanceToBottom = scrollHeight - (scrollTop + clientHeight)

      setShowJumpToLastMessage(distanceToBottom > JUMP_TO_LAST_MESSAGE_THRESHOLD)

      prevScrollTop.current = scrollTop
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasBefore,
      hasAfter,
      loading,
      isScrollPositionBottom,
      getBeforeMessages,
      getAfterMessages,
      setKeepScrollBottom
    ]
  )

  useEffect(() => {
    if (scrollListRef.current) {
      scrollListRef.current.isScrollPositionBottom = null
    }
  }, [chatId])

  useEffect(() => {
    if (windowScrollUsed) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, windowScrollUsed])

  const onWheel = useCallback(
    (event: WheelEvent<HTMLDivElement>) => {
      if (event.deltaY <= 0) {
        onScroll(event)
      }
    },
    [onScroll]
  )

  const handleSetMessageRef = useCallback(
    (ref: RefObject<HTMLDivElement>) => {
      setMessageRef(ref)
    },
    [setMessageRef]
  )

  const handleScrollToLastUnread = useCallback(
    (unreadCount: number) => {
      if (newMessageLineRef?.current && unreadCount) {
        scrollToNewMessageLine('smooth')
        return
      }

      const scrollHeight = scrollListRef.current?.scrollHeight ?? 0

      if (scrollHeight) {
        scrollToBottom(windowScrollUsed, scrollListRef, scrollHeight, 'smooth')
      }
    },
    [windowScrollUsed]
  )

  if (noData && showPinnedMessages) {
    return <EmptyList text={t('chat.placeholder_pinned')} />
  }

  if (showDefaultMessage) {
    return (
      <ChatDefaultMessageContainer
        size={EChatDefaultMessageSize.LG}
        classes={defaultMessageClassName ? { root: defaultMessageClassName } : undefined}
      />
    )
  }

  return (
    <div className={cls(styles.scrollContent, classes?.scrollContent)}>
      <div
        className={cls(
          styles.scrollInner,
          { [styles.scrollInnerSkeleton]: loading && !ids.length },
          classes?.scrollInner
        )}
        onScroll={!windowScrollUsed ? onScroll : undefined}
        onWheel={!windowScrollUsed ? onWheel : undefined}
        ref={scrollListRef}
      >
        {messages.map((messageId) => (
          <ChatMessageContainer
            scrollListRef={scrollListRef}
            newMessageLineRef={newMessageLineRef}
            key={messageId}
            id={messageId}
            caseView={caseView}
            onSetMessageRef={handleSetMessageRef}
          />
        ))}

        {showJumpToLastMessage && ids.length ? (
          <ChatMessageListGoToLast chatType={chatType} onClick={handleScrollToLastUnread} />
        ) : null}
      </div>
    </div>
  )
}
