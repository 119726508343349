import { UploadSourcesEnum } from '@medentee/enums'

import { TCallbackAfterUploadFile } from 'store'
import { DragAndDropContainer, TCaseMemberCloudContainerProps } from 'App/containers'
import {
  CaseCloudFilesList,
  CaseCloudFilesListHeader,
  TCaseCloudFilesListProps,
  useSelection
} from 'App/components'

import { EmptyLists, TEmptyListsProps } from '../EmptyLists'

export type TCloudProps = {
  onAfterUploadFile: TCallbackAfterUploadFile
  isMember: boolean
  isCaseLocked: boolean
  isCaseArchived: boolean
  loading: boolean
} & Pick<
  TCaseMemberCloudContainerProps,
  | 'caseType'
  | 'cloudFilesIds'
  | 'caseId'
  | 'caseStatus'
  | 'permissionsMode'
  | 'caseClassificationName'
  | 'showModal'
> &
  Pick<TCaseCloudFilesListProps, 'getKebabMenu' | 'caseView' | 'filesView'> &
  Pick<TEmptyListsProps, 'isFiltered'>

export const Cloud = ({
  isMember,
  isCaseArchived,
  isCaseLocked,
  loading,
  cloudFilesIds,
  caseType,
  caseId,
  caseStatus,
  permissionsMode,
  caseClassificationName,
  caseView,
  filesView,
  isFiltered,
  onAfterUploadFile,
  getKebabMenu,
  showModal
}: TCloudProps) => {
  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(cloudFilesIds)

  const showHeader =
    !isCaseArchived && !loading && Boolean(cloudFilesIds.length) && caseView === 'expanded'

  return (
    <>
      {showHeader && (
        <CaseCloudFilesListHeader
          variant="case-cloud"
          spaces={[0, 12]}
          ids={cloudFilesIds}
          selectedIds={selectedIds}
          isCaseArchived={isCaseArchived}
          getKebabMenu={getKebabMenu}
          setSelectedIds={setSelectedIds}
        />
      )}

      <DragAndDropContainer
        centerOnDropAlert
        onAfterUploadFile={onAfterUploadFile}
        caseId={caseId}
        uploadedFrom={UploadSourcesEnum.CASE}
        hide={isCaseLocked || isCaseArchived}
      >
        {!loading && !cloudFilesIds.length ? (
          <EmptyLists
            variant="case-cloud"
            isCaseArchived={isCaseArchived}
            isCaseLocked={isCaseLocked}
            isMember={isMember}
            isFiltered={isFiltered}
          />
        ) : (
          <CaseCloudFilesList
            caseId={caseId}
            isMember={isMember}
            caseStatus={caseStatus}
            cloudFilesIds={cloudFilesIds}
            loading={loading}
            permissionsMode={permissionsMode}
            caseType={caseType}
            caseClassificationName={caseClassificationName}
            selectedIds={selectedIds}
            caseView={caseView}
            filesView={filesView}
            selectMode={selectMode}
            getKebabMenu={getKebabMenu}
            showModal={showModal}
            onSelect={onSelect}
          />
        )}
      </DragAndDropContainer>
    </>
  )
}
