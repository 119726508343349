import { ReactNode, memo } from 'react'
import cls from 'classnames'

import { NOTIFICATION_COUNTER_MAX_VALUE } from 'globalConstants'
import { ENotificationBadgeColor, ENotificationBadgeSize } from 'enums'
import { useSetShadow } from 'App/hooks'

import styles from './NotificationBadge.module.scss'

export type TNotificationBadgeProps = {
  count: number

  className?: string
  max?: number
  size?: ENotificationBadgeSize
  color?: ENotificationBadgeColor
  border?: boolean
  borderColor?: string
  borderCssRootPropertyValue?: string
  variant?: 'default' | 'dot' | 'icon'
  gutter?: boolean
  icon?: ReactNode
}

const NotificationBadgeView = ({
  count,
  borderColor,
  borderCssRootPropertyValue,
  icon,
  className = '',
  max = NOTIFICATION_COUNTER_MAX_VALUE,
  size = ENotificationBadgeSize.MD,
  color = ENotificationBadgeColor.SECONDARY,
  border = false,
  variant = 'default',
  gutter = false
}: TNotificationBadgeProps) => {
  const counter = count && count > max ? `${max}+` : count

  const counterRef = useSetShadow({
    shadowSize: size === ENotificationBadgeSize.SM ? 2 : 3,
    shadowColor: borderColor,
    cssRootPropertyValue: borderCssRootPropertyValue,
    hasBorder: border
  })

  if (!count) {
    return null
  }

  const getCounter = () => {
    if (variant === 'icon') {
      return icon
    }

    if (variant === 'default') {
      return counter
    }

    return null
  }

  return (
    <div
      ref={counterRef}
      className={cls(styles.count, className, styles[size], styles[color], styles[variant], {
        [styles.gutter]: gutter
      })}
    >
      {getCounter()}
    </div>
  )
}

export const NotificationBadge = memo(NotificationBadgeView)
