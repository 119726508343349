import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { searchChatAction, setChatFilters, showModalAction } from 'store'
import { ChatRoomsHeader } from 'App/components'

export type TChatRoomsHeaderContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  search: state.chat.chatRooms.search,
  filters: state.chat.chatRooms.filters,
  chatType: state.chat.chatRooms.chatType,
  accountType: state.global.accountData?.type.name
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      setSearch: searchChatAction,
      setChatFilters
    },
    dispatch
  )

export const ChatRoomsHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRoomsHeader)
