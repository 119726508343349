import { useCallback } from 'react'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'
import { generatePath, useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { CREATE_GROUP_CHAT_PATH, EChatTypeMap } from 'globalConstants'
import { getMapComponent } from 'utils'
import { TChatRoomCaseRolesFilter } from 'store'
import { EModalComponents, TChatRoomsHeaderContainerProps } from 'App/containers'
import { Popover, FilterButton, Button, Tooltip } from 'App/components'
import { useDialog } from 'App/hooks'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { TBroadcastRecipientsType } from 'App/containers/Contact/BroadcastMessageDialog/SelectRecipients/useSelectRecipientsRequests'

import { ChatRoomsHeaderSearch, Filter } from './components'
import styles from './ChatRoomsHeader.module.scss'

export type TChatRoomsHeaderProps = TChatRoomsHeaderContainerProps

type TButtonMapProps = {
  open: boolean
  handleFilterSubmit: (value: TChatRoomCaseRolesFilter) => void
  toggle: () => void
  close: () => void
  handleOpenBroadcast: (recipientsType: TBroadcastRecipientsType) => () => void
  handleOpenCreateGroupChat: () => void
  t: TFunction

  accountType?: AccountTypeNames
  caseRole?: TChatRoomCaseRolesFilter
}

const BUTTON_MAP = new Map<ChatTypeEnum, (props: TButtonMapProps) => JSX.Element>()
  .set(ChatTypeEnum.CASE_GROUP, ({ caseRole, open, handleFilterSubmit, toggle, close }) => (
    <Popover
      content={<Filter defaultValue={caseRole} onClose={close} onSubmit={handleFilterSubmit} />}
      placement="bottomRight"
      trigger="click"
      visible={open}
      onVisibleChange={toggle}
      overlayClassName={styles.popover}
      arrow={false}
      getPopupContainer={(node) => node}
    >
      <FilterButton className={styles.filterButton} appliedFilter={Boolean(caseRole)} />
    </Popover>
  ))
  .set(ChatTypeEnum.DIALOGUE, ({ handleOpenBroadcast, handleOpenCreateGroupChat, t }) => (
    <>
      <Tooltip title={t('chat.list.header.broadcastButtonTooltip')}>
        <Button className={styles.button} onClick={handleOpenBroadcast('all')}>
          <EmailIcon />
        </Button>
      </Tooltip>
      <Tooltip title={t('chat.list.header.groupChatButtonTooltip')}>
        <Button className={styles.button} onClick={handleOpenCreateGroupChat}>
          <AccountMultiplePlusOutlineIcon />
        </Button>
      </Tooltip>
    </>
  ))

export const ChatRoomsHeader = ({
  setSearch,
  search,
  chatType,
  accountType,
  filters,
  showModal,
  setChatFilters
}: TChatRoomsHeaderProps) => {
  const { toggle, open, close } = useDialog()

  const { push } = useHistory()

  const { t } = useTranslation()

  const { caseRole } = filters

  const handleChange = useCallback((value: string) => setSearch({ search: value }), [setSearch])
  const handlePressEnter = useCallback(() => handleChange(search), [handleChange, search])
  const handleFilterSubmit = useCallback(
    (value: TChatRoomCaseRolesFilter) =>
      setChatFilters({ caseRole: value === 'all' ? undefined : value }),
    [setChatFilters]
  )

  const handleOpenBroadcast = useCallback(
    (recipientsType: TBroadcastRecipientsType) => () => {
      showModal({
        modalTitle: t('modal.broadcastMessage.title'),
        modalType: EModalComponents.BROADCAST_MESSAGE_DIALOG,
        modalProps: {
          recipientsType
        }
      })
    },
    [showModal, t]
  )
  const handleOpenCreateGroupChat = useCallback(() => {
    push(
      generatePath(CREATE_GROUP_CHAT_PATH, {
        chatType: EChatTypeMap.DIALOGUE
      })
    )
  }, [push])

  return (
    <div className={styles.root}>
      <ChatRoomsHeaderSearch
        chatType={chatType}
        onChange={handleChange}
        onPressEnter={handlePressEnter}
      />

      {getMapComponent(BUTTON_MAP, chatType, {
        caseRole,
        accountType,
        open,
        toggle,
        close,
        handleFilterSubmit,
        handleOpenBroadcast,
        handleOpenCreateGroupChat,
        t
      })}
    </div>
  )
}
