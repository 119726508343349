import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { EmptyList, TCaseCloudVariant } from 'App/components'
import { ReactComponent as TextBoxIcon } from 'assets/icons/TextBox.svg'
import { ReactComponent as TextBoxPlusIcon } from 'assets/icons/TextBoxPlus.svg'
import { getMapComponent } from 'utils'

import styles from './EmptyLists.module.scss'

export type TEmptyListsProps = {
  variant: TCaseCloudVariant
  isMember: boolean
  isCaseLocked: boolean
  isCaseArchived: boolean

  isFiltered?: boolean
}

type TTextMapProps = Pick<TEmptyListsProps, 'isMember' | 'isCaseLocked'> & {
  t: TFunction
}

type TIconMapProps = Pick<TEmptyListsProps, 'isMember'>

const ICON_MAP = new Map<TCaseCloudVariant, (props: TIconMapProps) => JSX.Element>()
  .set('case-cloud', ({ isMember }) => (isMember ? <TextBoxIcon /> : <TextBoxPlusIcon />))
  .set('shard-with-me', () => <TextBoxIcon />)
  .set('upload-by-me', () => <TextBoxIcon />)

const TEXT_MAP = new Map<TCaseCloudVariant, (props: TTextMapProps) => string>()
  .set('case-cloud', ({ isMember, isCaseLocked, t }) => {
    if (isMember && isCaseLocked) {
      return t('cases.details.attachments.placeholder.locked')
    }

    return isMember
      ? t('cases.details.attachments.placeholder.shared')
      : t('cases.details.attachments.placeholder.uploaded')
  })
  .set('shard-with-me', ({ isMember, t }) =>
    isMember
      ? t('cases.details.attachments.placeholder.uploaded')
      : t('cases.details.attachments.placeholder.shared')
  )
  .set('upload-by-me', ({ isMember, t }) =>
    isMember
      ? t('cases.details.attachments.placeholder.uploaded')
      : t('cases.details.attachments.placeholder.shared')
  )

export const EmptyLists = ({
  isMember,
  variant,
  isCaseArchived,
  isCaseLocked,
  isFiltered
}: TEmptyListsProps) => {
  const { t } = useTranslation()

  const text = useMemo(() => {
    if (isCaseArchived) {
      return t('cases.details.attachments.placeholder.archived')
    }

    return isFiltered
      ? t('common.field.search.listPlaceholder_filter')
      : getMapComponent<TCaseCloudVariant, TTextMapProps, string>(TEXT_MAP, variant, {
          isMember,
          isCaseLocked,
          t
        })
  }, [isCaseArchived, isCaseLocked, isFiltered, isMember, variant, t])

  return (
    <div className={styles.root}>
      <EmptyList icon={getMapComponent(ICON_MAP, variant, { isMember })} text={text} />
    </div>
  )
}
