import { useEffect, useState } from 'react'

export type TUseCaseNewLineOptions = {
  lineId: null | string

  readNotifications?: () => void
}

export const useCaseNewDividerLine = ({ lineId, readNotifications }: TUseCaseNewLineOptions) => {
  const [dividerRef, newDividerLineRef] = useState<HTMLDivElement | null>(null)
  const elem = dividerRef

  useEffect(() => {
    if (elem && lineId) {
      if (readNotifications) {
        readNotifications()
      }

      elem.scrollIntoView({ block: 'center' })
    }
  }, [elem, lineId])

  return { newDividerLineRef }
}
