import cls from 'classnames'

import { useAdaptiveLayout } from 'App/hooks'
import { Button } from 'App/components'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import { FixedAddButton } from '../FixedAddButton'

import styles from './AsideMenuHeaderAction.module.scss'

export enum EAsideMenuHeaderActionVariant {
  BUTTON_ICON = 'BUTTON_ICON',
  BUTTON_BLOCK = 'BUTTON_BLOCK'
}

export type TAsideMenuHeaderActionClasses = 'wrapper' | 'title' | 'button'

export type TAsideMenuHeaderActionProps = PropsWithClasses<
  TAsideMenuHeaderActionClasses,
  {
    title: string
    icon: JSX.Element

    buttonText?: string
    variant?: EAsideMenuHeaderActionVariant
    buttonContainer?: React.ComponentType
    onClick?: () => void
  }
>

export const AsideMenuHeaderAction = ({
  title,
  buttonText,
  icon,
  classes,
  onClick,
  buttonContainer: ButtonContainer,
  variant = EAsideMenuHeaderActionVariant.BUTTON_ICON
}: TAsideMenuHeaderActionProps) => {
  const { isMobile, isTablet, isDesktop } = useAdaptiveLayout()

  const shouldShowButton = (buttonText && onClick) || (buttonText && ButtonContainer)
  const shouldShowMobileButton =
    (isMobile || (isTablet && variant === EAsideMenuHeaderActionVariant.BUTTON_ICON)) &&
    (onClick || ButtonContainer)

  if (shouldShowMobileButton) {
    return ButtonContainer ? (
      <ButtonContainer>
        <FixedAddButton icon={icon} onClick={onClick} />
      </ButtonContainer>
    ) : (
      <FixedAddButton icon={icon} onClick={onClick} />
    )
  }

  if (isDesktop || (isTablet && variant === EAsideMenuHeaderActionVariant.BUTTON_BLOCK)) {
    const button = (
      <Button className={classes?.button} onClick={onClick} icon={<PlusIcon />}>
        {buttonText}
      </Button>
    )
    return (
      <div className={cls(styles.wrapper, classes?.wrapper)}>
        <div className={styles.icon}>{icon}</div>
        <p className={cls(styles.title, classes?.title)}>{title}</p>
        {shouldShowButton &&
          (ButtonContainer ? <ButtonContainer>{button}</ButtonContainer> : button)}
      </div>
    )
  }

  return null
}
