import { useMemo } from 'react'

import { AvatarContainer } from 'App/containers'
import { ChatInterlocutorOnline, TruncatedText } from 'App/components'
import { useAdaptiveLayout, useRedirectToUserProfile } from 'App/hooks'
import { selectLastSeen, selectOnline, useAppSelector } from 'store'
import { EAvatarSize } from 'enums'

import styles from './Interlocutor.module.scss'

export const Interlocutor = () => {
  const interlocutorAccount = useAppSelector(
    (state) => state.chat.chatRooms.selectedChat?.interlocutorAccount
  )
  const online = useAppSelector(selectOnline)
  const lastSeen = useAppSelector(selectLastSeen)
  const isContact = useAppSelector((state) => state.chat.chatRooms.selectedChat?.isContact)
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const { isMobile, isTablet } = useAdaptiveLayout()

  const avatarSize = useMemo(() => {
    switch (true) {
      case isMobile:
        return EAvatarSize.MD
      case isTablet:
        return EAvatarSize.LG
      default:
        return EAvatarSize.LLG
    }
  }, [isMobile, isTablet])

  if (!interlocutorAccount) {
    return null
  }

  return (
    <div className={styles.root}>
      <AvatarContainer
        userId={interlocutorAccount.id}
        displayUserName={interlocutorAccount.displayUserName}
        size={avatarSize}
        firstName={interlocutorAccount.firstName}
        lastName={interlocutorAccount.lastName}
        type={interlocutorAccount.type.name}
        hasTooltip={false}
        showOnline={false}
        onClick={redirectToUserProfile(interlocutorAccount?.id, interlocutorAccount?.type.name)}
      />
      <span
        onClick={redirectToUserProfile(interlocutorAccount?.id, interlocutorAccount?.type.name)}
        className={styles.name}
      >
        {interlocutorAccount.displayUserName}
      </span>

      {isContact && (
        <>
          <ChatInterlocutorOnline
            lastSeen={lastSeen}
            online={online}
            color={online ? 'secondary' : 'default'}
          />
          <TruncatedText classes={{ text: styles.headline }} text={interlocutorAccount.headline} />
        </>
      )}
    </div>
  )
}
