import { Row } from 'antd'
import { useCallback, useEffect } from 'react'
import { CategoryName } from 'emoji-mart'

import { Popover, useContextMenuContext } from 'App/components/common'
import { EmojiPicker } from 'App/components/common/EmojiPicker/EmojiPicker'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'

import { useChatMessageReactionsRequests } from '../useChatMessageReactionsRequests'

import styles from './ChatMessageReactionsPicker.module.scss'

export type TChatMessageReactionsPickerProps = {
  chatId: string
  messageId: string
  open: boolean
  toggleOn: () => void
  toggleOff: () => void
}

const EXCLUDE_EMOJI_CATEGORIES: CategoryName[] = [
  'activity',
  'custom',
  'flags',
  'symbols',
  'foods',
  'nature',
  'objects',
  'people',
  'places',
  'search'
]

export const ChatMessageReactionsPicker = ({
  open,
  chatId,
  messageId,
  toggleOn,
  toggleOff
}: TChatMessageReactionsPickerProps) => {
  const { close } = useContextMenuContext()
  const { outgoingReaction, addReaction, removeReaction } = useChatMessageReactionsRequests({
    messageId,
    chatId
  })

  const handleSelect = useCallback(
    (emoji: string) => {
      close()

      if (outgoingReaction === emoji) {
        removeReaction()
        return
      }

      addReaction(emoji)
    },
    [outgoingReaction, close, addReaction, removeReaction]
  )

  useEffect(
    () => () => {
      toggleOff()
    },
    [toggleOff]
  )

  if (open) {
    return (
      <div className={styles.picker}>
        <EmojiPicker onSelect={handleSelect} />
      </div>
    )
  }

  return (
    <Popover
      getPopupContainer={(node) => node}
      overlayClassName={styles.popover}
      visible={true}
      arrow={false}
      placement="topRight"
      content={
        <Row className={styles.shortPicker} wrap={false} align="middle">
          <EmojiPicker onSelect={handleSelect} perLine={6} exclude={EXCLUDE_EMOJI_CATEGORIES} />

          {!open && <ChevronRightIcon className={styles.icon} onClick={toggleOn} />}
        </Row>
      }
    >
      <div />
    </Popover>
  )
}
