import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { setCasesAutoApproveRequest, TContactsData } from 'store'
import { State } from 'redux/rootReducer'
import { CasesAutoApprove } from 'App/components'

export type TCasesAutoApproveContainerOwnProps = {
  id: string
}

export type TCasesAutoApproveContainerProps = TCasesAutoApproveContainerOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, ownProps: TCasesAutoApproveContainerOwnProps) => {
  const contact: Partial<TContactsData> = state.contacts.list[ownProps.id]

  return {
    caseAutoApproveMode: contact.caseAutoApproveMode ?? false
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCasesAutoApprove: setCasesAutoApproveRequest
    },
    dispatch
  )

export const CasesAutoApproveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasesAutoApprove)
