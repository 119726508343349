import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { contactsInvitationNestedRoutes } from 'App/App.config'
import { TNavMenuItem } from 'App/components'
import { TRoleRouteContainerOwnProps } from 'App/containers'
import { DEFAULT_ROUTE } from 'globalConstants'
import { isBusinessAccountSelector, useAppSelector } from 'store'

type TUseInvitationsRoutesResponse = {
  navMenu: {
    received: TNavMenuItem[]
    sent: TNavMenuItem[]
  }
  nestedRoutes: TRoleRouteContainerOwnProps[]
  defaultPath: string
}

const CONTACTS_BASE_PATH = '/contacts/invitations'
const CONTACTS_DEFAULT_PATH = `${CONTACTS_BASE_PATH}/received`

export const useInvitationsRoutes = (): TUseInvitationsRoutesResponse => {
  const match = useRouteMatch([CONTACTS_BASE_PATH])

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const contactsNotifications = useAppSelector((state) => state.notifications.general.contacts)

  const { t } = useTranslation()

  const config = useMemo((): TUseInvitationsRoutesResponse => {
    switch (match?.path ?? '') {
      case CONTACTS_BASE_PATH:
        return {
          navMenu: {
            received: [
              {
                link: `${CONTACTS_BASE_PATH}/received`,
                exact: true,
                title: t('contacts.invitations.tabs.contacts'),
                notification: { count: contactsNotifications }
              }
            ],
            sent: [
              {
                exact: true,
                link: `${CONTACTS_BASE_PATH}/sent`,
                title: t('contacts.invitations.tabs.contacts')
              },
              {
                hidden: isBusinessAccount,
                link: `${CONTACTS_BASE_PATH}/sent/new-users`,
                exact: true,
                title: t('contacts.invitations.tabs.newUsers')
              }
            ]
          },
          defaultPath: CONTACTS_DEFAULT_PATH,
          nestedRoutes: contactsInvitationNestedRoutes
        }

      default:
        return {
          defaultPath: DEFAULT_ROUTE,
          navMenu: { received: [], sent: [] },
          nestedRoutes: []
        }
    }
  }, [contactsNotifications, isBusinessAccount, match?.path, t])

  return config
}
