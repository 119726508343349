import { Dispatch, SetStateAction } from 'react'

import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { useQuery } from 'services/query'
import { getBroadcastMembers } from 'api/broadcasts'

type TUseBroadcastMembersRequestProps = {
  setSelectedAccounts: Dispatch<SetStateAction<TAccountsSelectOptionData[]>>

  broadcastId?: string
}
export const GET_BROADCAST_MEMBERS_QUERY_KEY = 'get_broadcast_members'

export const useBroadcastMembersRequest = ({
  broadcastId,
  setSelectedAccounts
}: TUseBroadcastMembersRequestProps) =>
  useQuery({
    queryKey: [GET_BROADCAST_MEMBERS_QUERY_KEY],
    queryFn: () =>
      broadcastId
        ? getBroadcastMembers({
            broadcastId,
            showBy: ''
          })
        : undefined,
    select: (data) =>
      data?.results.map<TAccountsSelectOptionData>(
        ({ account: { id, displayUserName, firstName, lastName, type } }) => ({
          id,
          displayUserName,
          firstName,
          lastName,
          type
        })
      ) ?? [],
    enabled: !!broadcastId,
    cacheTime: 0,
    onSuccess: setSelectedAccounts
  })
