import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { EHotkeysScope } from 'enums'
import { Carousel, TFileViewerProps } from 'App/components'
import { TFullscreenContainerProps } from 'App/containers'
import { TExtendableCardOptions } from 'store'

import { useSwiper } from '../hooks'
import { Placeholder } from '../Placeholder'

import { HeaderToolbar } from './HeaderToolbar'
import styles from './FullscreenView.module.scss'

export type TFullscreenViewProps = Pick<TFullscreenContainerProps, 'hide' | 'slides'> &
  NonNullableBy<
    Pick<TFullscreenContainerProps, 'initialSlideId' | 'type'>,
    'initialSlideId' | 'type'
  > & { options?: TExtendableCardOptions }

const getPlaceholder = (props: TFileViewerProps) => (
  <Placeholder id={props.id} source={props.source} originalEntityId={props.originalEntityId} />
)

export const FullscreenView = ({
  slides,
  initialSlideId,
  type,
  hide,
  options
}: TFullscreenViewProps) => {
  useHotkeys('esc', hide, { scopes: [EHotkeysScope.FULLSCREEN_VIEWER] })

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  const { elements, thumbnails, currentSlide, currentSlideIndex, rootRef, onSlideChange } =
    useSwiper({
      slides,
      initialSlideId,
      type,
      getPlaceholder,
      isThumbnails: slides.length > 1,
      thumbnailOptions: options?.thumbnailOptions
    })

  return (
    <div className={styles.root}>
      <div className={styles.wrapper} ref={rootRef}>
        <HeaderToolbar
          type={type}
          currentSlide={currentSlide}
          hide={hide}
          options={options}
          currentSlideIndex={currentSlideIndex}
          slides={slides}
        />

        <Carousel
          slides={elements}
          thumbnails={thumbnails}
          initialSlide={currentSlideIndex}
          onSlideChange={onSlideChange}
        />
      </div>
    </div>
  )
}
