import { memo, PropsWithChildren } from 'react'
import cls from 'classnames'

import { EIconSize } from 'enums'
import { stopPropagation } from 'utils'
import { IconButton, Popover } from 'App/components'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'

import styles from './DevicesWrapper.module.scss'

export type TDevicesWrapperProps = PropsWithChildren<
  PropsWithClassName<{
    shouldHideDevices: boolean
    disabledIcon: boolean
    isVisible: boolean
    onVisibleChange: (visible: boolean) => void
  }>
>

const DevicesView = ({
  children,
  shouldHideDevices,
  disabledIcon,
  isVisible,
  className,
  onVisibleChange
}: TDevicesWrapperProps) => (
  <>
    {shouldHideDevices ? null : (
      <div className={styles.root} onClick={stopPropagation}>
        <Popover
          placement="topLeft"
          overlayClassName={cls(styles.popover, className)}
          arrow={false}
          visible={isVisible}
          onVisibleChange={onVisibleChange}
          getPopupContainer={(node) => node}
          align={{ offset: [-22, -42] }}
          content={children}
        >
          <IconButton
            classes={{ root: styles.iconWrapper }}
            iconSize={EIconSize.XS}
            disabled={disabledIcon}
            iconComponent={
              <ChevronDownIcon className={cls(styles.icon, isVisible && styles.iconOpen)} />
            }
          />
        </Popover>
      </div>
    )}
  </>
)

export const DevicesWrapper = memo(DevicesView)
