import { getEventName } from './utils'

export type TOptions = {
  extraEventName: string | unknown[]
}

export type TCallback<D> = (data: D) => void

export const createBusEvent = <D>(eventName: string) => {
  const broadcast = (data: D, options?: TOptions) => core.broadcast<D>(eventName, data, options)
  const subscribe = (callback: TCallback<D>, options?: TOptions) =>
    core.subscribe(eventName, callback, options)

  return { subscribe, broadcast }
}

type Subs<D = any> = Record<string, Set<TCallback<D>>>

const subscriptions: Subs = {}

const subscribe = <D>(baseName: string, callback: TCallback<D>, options?: TOptions) => {
  const eventName = getEventName(baseName, options?.extraEventName)

  if (!subscriptions[eventName]) {
    subscriptions[eventName] = new Set()
  }

  const callbacks = subscriptions[eventName]
  callbacks.add(callback)

  return () => {
    callbacks.delete(callback)

    if (callbacks.size === 0) {
      delete subscriptions[eventName]
    }
  }
}

const broadcast = <D>(baseName: string, data: D, options?: TOptions) => {
  const eventName = getEventName(baseName, options?.extraEventName)

  if (!subscriptions[eventName]) {
    return
  }

  subscriptions[eventName].forEach((callback) => callback(data))
}

const core = { subscribe, broadcast }
export default core
