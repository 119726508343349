import { parseTextWithEmoji } from 'App/components/common'

import styles from './ChatMessageReactionEmoji.module.scss'

export type TChatMessageReactionEmojiProps = {
  emoji: string
}

export const ChatMessageReactionEmoji = ({ emoji }: TChatMessageReactionEmojiProps) => (
  <>{parseTextWithEmoji({ text: emoji, className: styles.emoji })}</>
)
