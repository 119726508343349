import { ESendMessageStatus, EChatHistoryEvents } from 'enums'
import { EHistoryType } from 'globalConstants'

import { TChatHistoryMessage, TGenerateFakeMessagePayload } from './chatMessages.types'

export const generateFakeMessage = (payload: TGenerateFakeMessagePayload): TChatHistoryMessage => {
  const { message, messageId, messageType, chatId, quoteMessage, sender, originalSender } = payload
  const currentDate = new Date()

  return {
    id: messageId,
    recipientRead: false,
    edited: false,
    senderId: sender.id,
    type: messageType,
    unixTs: currentDate.getTime(),
    createdAt: currentDate.toISOString(),
    sendStatus: ESendMessageStatus.SENDING,
    yourMessage: true,
    quoteMessage,
    message,
    sender,
    originalSender,
    chatId,
    updatedAt: currentDate.toISOString(),
    receivers: [],
    mentionedAccounts: [],
    mentionedDepartments: [],
    pin: null
  }
}

export const historyTypeEventsMap = {
  [EHistoryType.BEFORE]: EChatHistoryEvents.HISTORY_BEFORE,
  [EHistoryType.AFTER]: EChatHistoryEvents.HISTORY_AFTER,
  [EHistoryType.AROUND]: EChatHistoryEvents.JUMP
}
