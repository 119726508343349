import { ErrorCodesEnum } from '@medentee/enums'

import { toast } from '../App/components/ToastContainer'
import { toastDefaultOptions } from '../globalConstants'
import i18n from '../i18n'
import { handleError } from '../api/utils'

import { isMatchErrorCode, TResponseError } from './errorParsing'

export const handleDefaultError = (e: TResponseError) => {
  if (isMatchErrorCode(e, ErrorCodesEnum.CHAT_ROOM_NOT_FOUND)) {
    toast.warn(i18n.t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }), {
      ...toastDefaultOptions,
      toastId: ErrorCodesEnum.CHAT_ROOM_NOT_FOUND
    })

    return
  }

  handleError(e)
}
