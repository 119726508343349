import cls from 'classnames'
import { formatDuration, intervalToDuration } from 'date-fns'

import { TMeeting } from 'store'
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg'

import styles from './Duration.module.scss'

export type TDurationProps = Pick<TMeeting, 'startOn' | 'endOn'> &
  PropsWithClassName<{
    disabled?: boolean
    gutter?: boolean
    ascendant?: boolean
  }>

export const Duration = ({
  startOn,
  endOn,
  disabled,
  gutter,
  className,
  ascendant = false
}: TDurationProps) => (
  <div
    className={cls(styles.root, className, {
      [styles.disabled]: disabled,
      [styles.gutter]: gutter,
      [styles.rootAscendant]: ascendant
    })}
  >
    <ClockIcon />
    <span>
      Duration:{' '}
      {formatDuration(intervalToDuration({ start: new Date(startOn), end: new Date(endOn) }), {
        format: ['hours', 'minutes']
      })}
    </span>
  </div>
)
