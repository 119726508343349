import { ReactNode, SVGProps } from 'react'
import cls from 'classnames'
import { addMonths, intervalToDuration } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { formatMonthYear } from 'utils'

import styles from './ItemDateRange.module.scss'

export type TItemDateRangeProps = {
  startDate: string | Date
  endDate: null | string | Date
  icon: ReactNode | SVGProps<SVGSVGElement>
  isCurrent?: boolean
}

export const ItemDateRange = ({
  className,
  startDate,
  endDate,
  icon,
  isCurrent
}: PropsWithClassName<TItemDateRangeProps>) => {
  const { t } = useTranslation()

  const { years, months } = intervalToDuration({
    start: new Date(startDate),
    end: endDate ? addMonths(new Date(endDate), 1) : new Date()
  })

  const year = years ? t('userProfile.details.yearsLabel', { years }) : ''
  const month = months ? t('userProfile.details.monthsLabel', { months }) : ''

  return (
    <div className={cls(styles.root, className)}>
      <div className={styles.rootIcon}>{icon}</div>
      {formatMonthYear(startDate)}
      {' — '}
      {endDate && !isCurrent
        ? formatMonthYear(endDate)
        : t('userProfile.details.presentLabel')}{' '}
      {year} {month}
    </div>
  )
}
