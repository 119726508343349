import { useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  useAppSelector,
  createLoadingSelector,
  GET_ORGANIZATION_REGISTERED_USERS,
  deleteBusinessAdminInviteRequest,
  useAppDispatch,
  showModalAction,
  REMOVE_BUSINESS_ADMIN_INVITE,
  createProcessingBySelector
} from 'store'
import { EBusinessAdminDeleteAction, EBusinessAdminConfirmFrom } from 'enums'
import { InvitationItemSent } from 'App/components'
import { EModalComponents } from 'App/containers'

export type TOrganizationInvitationsRegisteredItemProps = {
  id: string
}

export const OrganizationInvitationsRegisteredItem = ({
  id
}: TOrganizationInvitationsRegisteredItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const list = useAppSelector(
    (state) => state.organizations.invitations.registeredUsers.list,
    isEqual
  )
  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATION_REGISTERED_USERS]))
  const removeProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_BUSINESS_ADMIN_INVITE])(id)
  )

  const handleWithdraw = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id,
          type: EBusinessAdminDeleteAction.WITHDRAW,
          from: EBusinessAdminConfirmFrom.ORGANIZATION_REGISTERED,
          direction: BusinessAccountDirectionEnum.OUTGOING
        }
      })
    )
  }, [dispatch, id, t])

  const handleRemove = useCallback(() => {
    dispatch(
      deleteBusinessAdminInviteRequest({
        id,
        from: EBusinessAdminConfirmFrom.ORGANIZATION_REGISTERED,
        onlyHidden: true,
        direction: BusinessAccountDirectionEnum.OUTGOING,
        processingId: id
      })
    )
  }, [dispatch, id])

  const { account, createdAt, status, invitation } = list[id] ?? {}

  return (
    <InvitationItemSent
      variant="internal"
      email={account.email}
      loading={loading}
      createdAt={createdAt}
      status={status}
      statusLabel={t(`enum.businessAccountStatusEnum.${status}`)}
      createdAccount={account}
      invitationEmail={invitation?.email}
      removeProcessing={removeProcessing}
      withdrawProcessing={removeProcessing}
      onWithdraw={handleWithdraw}
      onRemove={handleRemove}
    />
  )
}
