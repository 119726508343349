import { Redirect, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getKeyForRoute } from 'App/App.config'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'
import { OrganizationContentHeader, Scrollable, TabMenu } from 'App/components'
import { RoleRouteContainer } from 'App/containers'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'

import { useOrganizationId } from '../hooks'

import { useRoutes } from './useRoutes'
import styles from './OrganizationInvitations.module.scss'
import { InviteButtonContainer } from './InviteButtonContainer'

export const OrganizationInvitations = () => {
  const organizationId = useOrganizationId()

  const { pathList, navMenu, nestedRoutes } = useRoutes()

  const { isAvailable, getOnClickHandler } = useOrganizationSubscription({
    variant: 'upgrade',
    organizationId
  })

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <OrganizationContentHeader
        buttonIcon={<AccountMultiplePlusOutlineIcon />}
        buttonText={t('organizations.invitations.inviteButton')}
        title={t('organizations.invitations.title')}
        iconColor="accent"
        buttonContainer={({ children }) => (
          <InviteButtonContainer disabled={!isAvailable}>{children}</InviteButtonContainer>
        )}
        onClick={getOnClickHandler()}
        icon={<AccountPlusIcon />}
      />
      <div className={styles.main}>
        <TabMenu
          menuList={navMenu}
          navMenuProps={{ tabPosition: 'top', size: 'md' }}
          classes={{ root: styles.nav }}
          tabVariant="static"
        />

        <Scrollable>
          <Switch>
            {nestedRoutes.map((item, index) => (
              <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
            ))}

            <Redirect to={pathList.default} />
          </Switch>
        </Scrollable>
      </div>
    </div>
  )
}
