import { generatePath, useHistory } from 'react-router'
import { ContactStatusEnum } from '@medentee/enums'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { TAccountData, TContactsData, accountIdSelector, useAppSelector } from 'store'
import { AccountName, AnchorifedText, Button } from 'App/components/common'
import { AvatarContainer } from 'App/containers/AvatarContainer'
import { EAvatarSize } from 'enums'
import { useRedirectToUserProfile } from 'App/hooks'
import {
  ButtonMessageContainer,
  TButtonMessageContainerOwnProps
} from 'App/containers/ButtonMessageContainer'
import { CHAT_PATH, EChatTypeMap } from 'globalConstants'
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'
import { ButtonWithdrawInvitation } from 'App/components/ButtonWithdrawInvitation'
import { UnityHubNetworkingDrawerItem } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerItem'
import { TAvatarProps } from 'App/components/common/Avatar'

import styles from './UnityHubNetworkingDrawerContent.module.scss'

export type TUnityHubNetworkingDrawerContentProps = Pick<
  TButtonMessageContainerOwnProps,
  'chatId'
> & {
  account: Pick<
    TAccountData,
    'id' | 'displayUserName' | 'firstName' | 'lastName' | 'isContact' | 'isCoworker'
  > & { type: TAccountData['type']['name'] }
  actions: ReactNode
  details: ReactNode
  profileButtonLabel: string
  onSuccess: () => void

  contact?: Pick<TContactsData, 'id' | 'status' | 'toId'>
  headline?: string
  avatarProps?: Pick<TAvatarProps, 'isEventProfile' | 'eventId' | 'userId'>
  editProfileButtonLabel?: string
  shouldShowProfileButton?: boolean
  shouldShowEditProfileButton?: boolean
  onEditProfile?: () => void
}

export const UnityHubNetworkingDrawerContent = ({
  account,
  contact,
  chatId,
  actions,
  headline,
  details,
  profileButtonLabel,
  editProfileButtonLabel,
  avatarProps,
  shouldShowProfileButton = true,
  shouldShowEditProfileButton = false,
  onSuccess,
  onEditProfile
}: TUnityHubNetworkingDrawerContentProps) => {
  const { push } = useHistory()

  const accountId = useAppSelector(accountIdSelector)

  const { redirectToUserProfile } = useRedirectToUserProfile()

  const { t } = useTranslation()

  const isYourAccount = accountId === account.id
  const { displayUserName, firstName, lastName, type, isContact, isCoworker } = account

  const hasConnection = isYourAccount && (isContact || isCoworker)

  const shouldShowWithdrawButton =
    !isYourAccount && contact?.status === ContactStatusEnum.PENDING && contact.toId === account.id
  const shouldShowActionButtons = !isYourAccount || hasConnection || shouldShowWithdrawButton

  const handleGoToProfile = () => {
    redirectToUserProfile(account.id, type)()
  }

  const handleChatClick = () => {
    const path = generatePath(CHAT_PATH, { chatId, chatType: EChatTypeMap.DIALOGUE })
    push(path)
  }

  return (
    <div>
      <UnityHubNetworkingDrawerItem>
        <div className={styles.header}>
          <div className={styles.avatar}>
            <AvatarContainer
              userId={account.id}
              size={EAvatarSize.LG}
              firstName={firstName}
              lastName={lastName}
              displayUserName={displayUserName}
              type={type}
              hasTooltip={false}
              {...avatarProps}
            />
            <div className={styles.account}>
              <AccountName
                userId={account.id}
                classes={{ root: styles.accountName, caption: styles.accountCaption }}
                displayUserName={displayUserName}
                type={type}
                isYourAccountIndicator="caption"
                truncateRows="two"
              />
              {shouldShowProfileButton && (
                <Button
                  variant="inline"
                  fontWeight="bold"
                  className={styles.actionButton}
                  icon={<AccountIcon />}
                  onClick={handleGoToProfile}
                >
                  {profileButtonLabel}
                </Button>
              )}
              {shouldShowEditProfileButton && (
                <Button
                  variant="inline"
                  fontWeight="bold"
                  className={styles.actionButton}
                  icon={<PencilIcon />}
                  onClick={onEditProfile}
                >
                  {editProfileButtonLabel}
                </Button>
              )}
            </div>
          </div>

          {shouldShowActionButtons && (
            <div className={styles.buttons}>
              {hasConnection && (
                <ButtonMessageContainer
                  chatId={chatId}
                  tooltip={t('unityHub.networking.member.drawer.chatButtonTooltip')}
                  onClick={handleChatClick}
                />
              )}

              {actions}

              {shouldShowWithdrawButton && (
                <ButtonWithdrawInvitation
                  variant="outline"
                  contactId={contact.id}
                  tooltip={t('unityHub.networking.member.drawer.withdrawButtonTooltip')}
                  onSuccess={onSuccess}
                />
              )}
            </div>
          )}

          {headline && <AnchorifedText text={headline} />}
        </div>
      </UnityHubNetworkingDrawerItem>

      {details}
    </div>
  )
}
