import { useCallback } from 'react'
import { CaseTypesEnum, ProducedNotificationsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { CaseDetailsContainer, CaseDetailsPlaceholder, Collapse } from 'App/components'
import {
  CaseOpinionsMembersItemContainer,
  TCaseOpinionsMembersContainerProps
} from 'App/containers'
import { generateRandomArray } from 'utils'

export type TCaseOpinionsMembersListProps = Omit<
  TCaseOpinionsMembersContainerProps,
  'caseId' | 'getCaseOpinionsMembers'
>

const DEFAULT_MEMBERS_LENGTH = 10

export const CaseOpinionsMembersList = ({
  ids,
  loading,
  withoutWrapper,
  caseType,
  openOpinionMemberId,
  clearNotification,
  setOpenedOpinion
}: TCaseOpinionsMembersListProps) => {
  const members = loading ? generateRandomArray(DEFAULT_MEMBERS_LENGTH) : ids

  const { t } = useTranslation()

  const handleChange = useCallback(
    (active) => {
      setOpenedOpinion({ memberId: openOpinionMemberId === active ? null : active })

      clearNotification({
        producedNotificationType: ProducedNotificationsEnum.CASE_OPINION_CREATED,
        userId: active
      })
    },
    [openOpinionMemberId, setOpenedOpinion, clearNotification]
  )

  const content = members.length ? (
    <Collapse onChange={handleChange} activeKey={openOpinionMemberId ?? undefined}>
      {members.map((id) => (
        <CaseOpinionsMembersItemContainer key={id} id={id} />
      ))}
    </Collapse>
  ) : (
    <CaseDetailsPlaceholder>
      {t('cases.details.opinions.placeholder', {
        context: caseType?.toLowerCase() as Lowercase<CaseTypesEnum>
      })}
    </CaseDetailsPlaceholder>
  )

  if (withoutWrapper) {
    return content
  }

  return <CaseDetailsContainer content={content} />
}
