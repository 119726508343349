import { useEffect } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import { TCurrentBalanceContainerProps } from 'App/containers'

import styles from './CurrentBalance.module.scss'

export type TCurrentBalanceProps = TCurrentBalanceContainerProps & {
  className?: string
}

export const CurrentBalance = ({
  amount,
  loading,
  className = '',
  getUserBalance
}: TCurrentBalanceProps) => {
  useEffect(() => {
    getUserBalance()
  }, [getUserBalance])

  return (
    <Skeleton
      active={true}
      loading={loading}
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
      className={styles.skeleton}
    >
      {amount !== null && <div className={cls(styles.root, className)}>{amount} MDT</div>}
    </Skeleton>
  )
}
