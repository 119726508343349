import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form'

import { AccountsSelect } from 'App/components/common'
import { useSelectAccounts } from 'App/hooks'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { useBroadcastMembersStepRequests } from './useBroadcastMembersStepRequests'

export type TCreateBroadcastMembersStepProps = {
  entityId: string
  type: BroadcastTypeEnum
  selectedAccounts: TAccountsSelectOptionData[]
  onSelectedAccountsChange: (accounts: TAccountsSelectOptionData[]) => void
  onOpenMainStep: () => void
}

export const CreateBroadcastMembersStep = ({
  type,
  entityId,
  selectedAccounts: accounts,
  onSelectedAccountsChange,
  onOpenMainStep
}: TCreateBroadcastMembersStepProps) => {
  const { t } = useTranslation()

  const { change } = useForm()

  const onSubmit = (selected: TAccountsSelectOptionData[]) => {
    onSelectedAccountsChange(selected)
    onOpenMainStep()
    change('accounts', selected)
  }

  const {
    state: { page, search, selectedAccounts: selectedItems },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({
    accounts,
    onSubmit
  })

  const { data, isFetching } = useBroadcastMembersStepRequests({
    entityId,
    type,
    page,
    search
  })

  const isSubmitDisabled = !accounts.length && !selectedItems.length

  return (
    <AccountsSelect
      items={data?.results ?? []}
      selectedItems={selectedItems}
      search={search}
      page={page}
      total={data?.total}
      loading={isFetching}
      autoOnline={true}
      emptyListText={t('modal.createBroadcast.selectMembersStep.placeholder')}
      submitLabel={t('modal.createBroadcast.selectMembersStep.submitButton')}
      submitDisabled={isSubmitDisabled}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
      onClickBack={onOpenMainStep}
    />
  )
}
