import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { TMenuItemProps } from 'App/components'
import {
  hideModalAction,
  isAdminAccountSelector,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { EModalComponents } from 'App/containers/ModalRoot'
import { updateDepartment } from 'api/organizations'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'
import { queryClient } from 'queryClient'
import { ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY } from 'App/hooks/Organizations/useFetchDepartmentStaff'

type TUseKebabMenuProps = {
  organizationId: string
  setStaffTotal: (total: number) => void
}

type TMenuItemsProps = {
  departmentId: string
  departmentName: string
  isDepartmentLead: boolean
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const UPDATE_DEPARTMENT_MUTATION_KEY = 'update-department'

export const useKebabMenu = ({
  organizationId,
  setStaffTotal
}: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { push } = useHistory()
  const isBusiness = useAppSelector(isAdminAccountSelector)

  const { mutate } = useMutation({
    mutationKey: [UPDATE_DEPARTMENT_MUTATION_KEY],
    mutationFn: updateDepartment
  })

  const handleSubmit = useCallback(
    (departmentId: string) => (accounts: TAccountsSelectOptionData[]) => {
      mutate(
        {
          id: departmentId,
          organizationId,
          accountsIds: accounts.length ? accounts.map(({ id }) => id) : undefined
        },
        {
          onSuccess: () => {
            dispatch(hideModalAction())
            setStaffTotal(accounts.length)
            queryClient.refetchQueries({
              queryKey: [ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY, 0, organizationId, departmentId],
              type: 'active'
            })
          }
        }
      )
    },
    [dispatch, mutate, organizationId, setStaffTotal]
  )

  const { getOnClickHandler: getEditDepartmentHandler } = useOrganizationSubscription<string>({
    variant: 'upgrade',
    organizationId,
    callback: (departmentId) => {
      push(`/organization/administration/departments/${departmentId}`)
    }
  })

  const { getOnClickHandler: getManageStaffHandler } = useOrganizationSubscription<
    Pick<TMenuItemsProps, 'departmentId' | 'departmentName'>
  >({
    variant: 'adminChat',
    organizationId,
    callback: (props) => {
      props?.departmentId &&
        dispatch(
          showModalAction({
            modalTitle: t('modal.manageDepartmentStaff.title'),
            modalType: EModalComponents.SELECT_DEPARTMENT_STAFF,
            modalProps: {
              organizationId,
              shouldFetchStaff: true,
              departmentId: props.departmentId,
              departmentName: props.departmentName,
              onSubmit: handleSubmit(props.departmentId)
            }
          })
        )
    }
  })

  const menuItems = useMemo(
    () => ({
      edit: ({ departmentId }: TMenuItemsProps) => ({
        hidden: !isBusiness,
        content: t('organizations.departments.kebabMenu.editDepartment'),
        icon: <PencilOutlineIcon />,
        onClick: getEditDepartmentHandler(departmentId)
      }),
      delete: ({ departmentId }: TMenuItemsProps) => ({
        hidden: !isBusiness,
        content: t('organizations.departments.kebabMenu.deleteDepartment'),
        icon: <TrashIcon />,
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteDepartmentConfirm.title'),
              modalType: EModalComponents.DELETE_DEPARTMENT_DIALOG,
              modalProps: { departmentId, organizationId }
            })
          )
        }
      }),
      manageStaff: ({ isDepartmentLead, departmentId, departmentName }: TMenuItemsProps) => ({
        hidden: !isDepartmentLead,
        content: t('organizations.departments.kebabMenu.manageStaff'),
        icon: <AccountMultiplePlusIcon />,
        onClick: getManageStaffHandler({ departmentId, departmentName })
      })
    }),
    [isBusiness, getEditDepartmentHandler, dispatch, organizationId, getManageStaffHandler, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.edit(props),
      menuItems.delete(props),
      menuItems.manageStaff(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
