import { useMemo } from 'react'
import { Col } from 'antd'
import { Field } from 'react-final-form'
import { PublicationTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { createSelectorOptions, validation } from 'utils'
import { Select, TextField } from 'App/components'
import { MAX_LENGTH_200, MIN_LENGTH_1 } from 'globalConstants'

const TYPES_SELECTOR_OPTIONS = createSelectorOptions(Object.values(PublicationTypeEnum))

export const FirstStep = () => {
  const { t } = useTranslation()

  const validate = useMemo(
    () =>
      validation.composeValidators(
        validation.onlySpaces(),
        validation.required(),
        validation.minLength(MIN_LENGTH_1),
        validation.maxLength(MAX_LENGTH_200)
      ),
    []
  )

  return (
    <>
      <Col xs={24}>
        <Field name="name" validate={validate}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userPublication.form.nameField')}
              valueLengthMax={MAX_LENGTH_200}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="type" validate={validation.composeValidators(validation.required())}>
          {({ input, meta }) => (
            <Select
              {...input}
              topLabel={t('modal.userPublication.form.typeField')}
              placeholder={t('modal.userPublication.form.typeFieldPlaceholder')}
              options={TYPES_SELECTOR_OPTIONS}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="authors" validate={validate}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userPublication.form.authorsFiled')}
              valueLengthMax={MAX_LENGTH_200}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
