import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'

import styles from './SandboxScreen.module.scss'

type TSandboxScreenProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const SandboxScreenComponent: FC<TSandboxScreenProps> = () => <div className={styles.root}></div>

const mapStateToProps = (state: State) => ({ state })
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const SandboxScreen = connect(mapStateToProps, mapDispatchToProps)(SandboxScreenComponent)
