import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { EModalComponents } from 'App/containers'
import { showModalAction } from 'store'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

export type TUserProfileWorkExperienceButtonProps = {
  disabled?: boolean
}

export const UserProfileWorkExperienceButton = (props: TUserProfileWorkExperienceButtonProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { disabled, ...modalProps } = props

  const handleClick = () => {
    dispatch(
      showModalAction({
        modalProps,
        modalTitle: t('modal.userWorkplaces.title_add'),
        modalType: EModalComponents.WORKPLACE_DIALOG
      })
    )
  }

  return (
    <Button icon={<PlusIcon />} variant="text" disabled={disabled} onClick={handleClick}>
      {t('userProfile.workplaces.addWorkplaceButton')}
    </Button>
  )
}
