import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'

import { useEvent } from 'App/hooks'
import { LOCAL_DEVICE_INFO_NOT_FOUND } from 'globalConstants/errors'

const SENSIBILITY = 5

export const useOutputLevel = (deviceLabel: string, kind: string, pidCount: number) => {
  const [hide, setHide] = useState<boolean>(false)
  const [level, setLevel] = useState<number[]>([])

  const onMessage = useEvent((event: MessageEvent) => {
    const volume = event.data.volume ?? 0

    const range = Array.from({ length: pidCount })
      .map((_, index) => index)
      .slice(0, Math.round((volume * 100) / SENSIBILITY))

    if (!isEqual(level, range)) {
      setLevel(range)
    }
  })

  useEffect(() => {
    let stream: MediaStream | null = null
    let audioContext: AudioContext | null = null
    let node: AudioWorkletNode | null = null

    const stopListening = () => {
      if (audioContext && audioContext?.state !== 'closed') {
        audioContext.close()
      }

      if (stream) {
        stream.getAudioTracks().forEach((item) => {
          item.stop()
        })
      }

      if (node) {
        node.port.close()
        node.port.onmessage = null
        node.disconnect()
      }
    }

    const init = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const device = devices
          .filter((item) => item.kind === kind)
          .find((item) => item.label === deviceLabel)

        if (!device?.deviceId) {
          throw new Error(LOCAL_DEVICE_INFO_NOT_FOUND)
        }

        stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: device.deviceId }
        })

        audioContext = new AudioContext()
        await audioContext.audioWorklet.addModule('/vumeter-processor.js')

        node = new AudioWorkletNode(audioContext, 'vumeter')
        node.port.onmessage = onMessage

        const microphone = audioContext.createMediaStreamSource(stream)
        microphone.connect(node).connect(audioContext.destination)
      } catch (error) {
        setHide(true)
        stopListening()
        console.error(error)
      }
    }

    init()

    return () => {
      stopListening()
    }
  }, [deviceLabel, kind, onMessage, pidCount])

  return { hide, level }
}
