import { useCallback, useEffect } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { Skeleton } from 'antd'
import cls from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { generateRandomArray, getMapComponent } from 'utils'
import { Button, FormSubmitButtonSkeleton } from 'App/components/common'
import {
  UserProfileNewProfessionContainer,
  UserProfileProfessionsProps,
  UserProfileProfessionContainer
} from 'App/containers'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './UserProfileProfessions.module.scss'

const LABEL_MAP = new Map<AccountTypeNames, (t: TFunction) => string>()
  .set(AccountTypeNames.BUSINESS, (t) => t('userProfile.professions.addCompanyTypeButton'))
  .set(AccountTypeNames.PROFESSIONAL, (t) => t('userProfile.professions.addProfessionButton'))

export const UserProfileProfessions = ({
  ids,
  newIds,
  accountType,
  loading,
  addNewProfession,
  loadUserProfessions,
  loadAvailableProfessions
}: UserProfileProfessionsProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    loadUserProfessions()
    loadAvailableProfessions()
  }, [loadAvailableProfessions, loadUserProfessions])

  const onAddNewProfession = useCallback(() => {
    addNewProfession()
  }, [addNewProfession])

  const professionIds = loading ? generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH) : ids

  return (
    <div className={styles.root}>
      <div>
        {professionIds.map((id) => (
          <div className={cls(styles.item, loading && styles.itemSkeleton)} key={id}>
            <Skeleton
              className={styles.skeleton}
              loading={loading}
              active={true}
              title={true}
              paragraph={{ rows: 2 }}
            >
              <UserProfileProfessionContainer id={id} />
            </Skeleton>
          </div>
        ))}
        {!!newIds.length &&
          newIds.map((id) => (
            <div className={styles.item} key={id}>
              <UserProfileNewProfessionContainer id={id} />
            </div>
          ))}
      </div>
      <FormSubmitButtonSkeleton loading={loading} size="s" className={styles.skeletonButton}>
        <div className={styles.button}>
          <Button
            variant="text"
            icon={<PlusIcon />}
            onClick={onAddNewProfession}
            disabled={!!newIds.length}
          >
            {getMapComponent(LABEL_MAP, accountType, t)}
          </Button>
        </div>
      </FormSubmitButtonSkeleton>
    </div>
  )
}
