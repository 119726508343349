import { isSameDay, startOfDay } from 'date-fns'
import { ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import differenceInHours from 'date-fns/differenceInHours'
import copy from 'copy-to-clipboard'

import { formatDate, formatLongDate, formatShortDate, timeFormat } from 'utils'
import {
  TGetImportantMessages,
  TIsMessageGroupEnd,
  TIsMessageGroupStart,
  TShowMessageDate
} from 'store'
import {
  EChatTypeMap,
  IMAGE_WHITELIST,
  MAX_HOURS_TEMPORARY_MESSAGE_ACTION_ALLOWED
} from 'globalConstants'
import { TAccountSuggestion } from 'interfaces/api/showcase'
import { TDepartmentSuggestion } from 'interfaces/api/organizations/departments'

import { getExtensionWithoutDot } from './files'
import { TAccountMention, TDepartmentMention } from './mentions'

const isYourMessageId = (accountId: string) => (senderId?: string) => senderId === accountId

export const getGroupMessageDate: TShowMessageDate = ({
  currentTimestamp,
  prevTimestamp,
  isFirst,
  alreadySawDates
}) => {
  const date = new Date(currentTimestamp)
  const prevDate = prevTimestamp ? new Date(prevTimestamp) : date
  const matchDate = formatLongDate(date)
  const matchPrevDate = formatLongDate(prevDate)

  if (isFirst) {
    return [true, matchDate]
  }

  if (alreadySawDates.has(matchDate) && alreadySawDates.has(matchPrevDate)) {
    return [false, matchDate]
  }
  if (
    date.getFullYear() !== prevDate.getFullYear() ||
    date.getMonth() !== prevDate.getMonth() ||
    date.getDate() !== prevDate.getDate()
  ) {
    return [true, matchDate]
  }

  return [false, matchDate]
}

export const isMessageGroupStart: TIsMessageGroupStart = ({
  isShowDate,
  accountId,
  prevMessageSenderId: prev,
  currentMessageSenderId: current,
  prevMessageType
}) => {
  const isYourMessage = isYourMessageId(accountId)

  let groupStart = true

  if (isYourMessage(current) && isYourMessage(prev)) {
    groupStart = false
  }

  if (
    isShowDate ||
    prevMessageType === MessageTypeEnum.FILE ||
    prevMessageType === MessageTypeEnum.SYSTEM
  ) {
    groupStart = true
  }

  return groupStart
}

export const isMessageGroupEnd: TIsMessageGroupEnd = ({
  accountId,
  nextMessageSenderId: next,
  currentMessageSenderId: current,
  nextMessageType
}) => {
  const isYourMessage = isYourMessageId(accountId)
  const yourMessageNext = isYourMessage(next)
  const yourMessageCurrent = isYourMessage(current)

  let groupEnd = false

  if (yourMessageCurrent && yourMessageNext) {
    groupEnd = false
  }

  if ((yourMessageCurrent && !yourMessageNext) || nextMessageType === MessageTypeEnum.SYSTEM) {
    groupEnd = true
  }

  return groupEnd
}

export const getImportantMessages: TGetImportantMessages = ({ currentId, ids, index, list }) => ({
  nextMessage: index + 1 <= ids.length - 1 ? list[ids[index + 1]] : null,
  currentMessage: list[currentId],
  prevMessage: index > 0 ? list[ids[index - 1]] : null
})

export const isScrollAtBottom = (
  scrollTop: number,
  scrollHeight: number,
  clientHeight: number,
  margin?: number
) => {
  const defaultMargin = margin ?? 1

  return Math.abs(scrollTop + clientHeight - scrollHeight) < defaultMargin
}

export const mapCaseTypeToChatType = (caseType: ChatTypeEnum): EChatTypeMap => {
  switch (caseType) {
    case ChatTypeEnum.CASE_GROUP:
      return EChatTypeMap.CASE_GROUP
    default:
      return EChatTypeMap.DIALOGUE
  }
}

export const isTemporaryActionAllowed = (createdAt: string): boolean => {
  const diff = differenceInHours(new Date(), new Date(createdAt))

  return diff < MAX_HOURS_TEMPORARY_MESSAGE_ACTION_ALLOWED
}

export const copyMessage = (message: {
  message?: string | Blob
  type: MessageTypeEnum
  file?: { id: string; fileName: string; extension: string } | null
}) => {
  if (message.type === MessageTypeEnum.TEXT) {
    copy(String(message.message))
    return
  }

  if (
    message.type === MessageTypeEnum.FILE &&
    message.file &&
    IMAGE_WHITELIST.includes(getExtensionWithoutDot(message.file.extension))
  ) {
    const { fileName, extension, id } = message.file

    copy(JSON.stringify({ id, fileName, extension }))
    return
  }
}

type TIsChatRoomNotificationVisibleProps = {
  isMobile: boolean
  unreadCount?: number
  mentionCount?: number
}

export const isChatRoomNotificationVisible = ({
  isMobile,
  unreadCount,
  mentionCount
}: TIsChatRoomNotificationVisibleProps) =>
  Boolean(
    (isMobile && !mentionCount) ||
      (!isMobile && unreadCount && unreadCount > 1 && mentionCount) ||
      (!isMobile && unreadCount && !mentionCount)
  )

export const formatAccountMentions = (mentions: TAccountSuggestion[], channelManagerId?: string) =>
  mentions.map<TAccountMention>((mention) => ({
    ...mention,
    variant: 'account',
    name: mention.displayUserName,
    trigger: '@',
    channelManagerId,
    clickable: true
  }))

export const formatDepartmentMentions = (mentions: TDepartmentSuggestion[]) =>
  mentions.map<TDepartmentMention>((mention) => ({
    ...mention,
    trigger: '@',
    variant: 'department'
  }))

export const getSearchedMessageTime = (createdAt: string): string => {
  const today = startOfDay(new Date())

  const isToday = isSameDay(new Date(createdAt), today)

  if (!isToday) {
    return formatShortDate(createdAt)
  }

  const time = formatDate(createdAt, timeFormat)

  return time
}
