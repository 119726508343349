import { useTranslation } from 'react-i18next'

import { TError } from 'store'
import { Button, ErrorModal } from 'App/components'

import styles from './TwoFactorConfirm.module.scss'

export type TTwoFactorConfirmProps = {
  onClick: () => void

  title?: string | null
  buttonText?: string
  error?: TError
  processing?: boolean
}

export const TwoFactorConfirm = ({
  processing,
  error,
  buttonText,
  title,
  onClick
}: TTwoFactorConfirmProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.rootTitle}>{title ?? t('common.2FA.confirm.title')}</div>
      <Button onClick={onClick} className={styles.rootButton} loading={processing}>
        {buttonText ?? t('common.2FA.confirm.submitButton')}
      </Button>
      <ErrorModal error={error} />
    </div>
  )
}
