import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { useQuery } from 'services/query'

import { useAdaptiveLayout } from '../../../hooks'
import { getSubscriptionsInfo } from '../../../../api/subscriptions'

import styles from './SubscriptionPlans.module.scss'
import { MobilePlansTable } from './MobilePlansTable'
import { DesktopPlansTable } from './DesktopPlansTable'

export const SubscriptionPlans = () => {
  const { isDesktop } = useAdaptiveLayout()
  const { data = [] } = useQuery({
    queryKey: ['subscriptions-info'],
    queryFn: getSubscriptionsInfo
  })

  const plans = useMemo(() => new Map(data.map((item) => [item.id, item])), [data])

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h3>{t('subscriptions.plansTable.title')}</h3>
      {isDesktop ? <DesktopPlansTable data={plans} /> : <MobilePlansTable data={plans} />}
    </div>
  )
}
