export const SCHEDULE_MEETING = 'SCHEDULE_MEETING'
export const SCHEDULE_MEETING_REQUEST = 'SCHEDULE_MEETING_REQUEST'
export const SCHEDULE_MEETING_SUCCESS = 'SCHEDULE_MEETING_SUCCESS'
export const SCHEDULE_MEETING_ERROR = 'SCHEDULE_MEETING_ERROR'

export const GET_MEETINGS = 'GET_MEETINGS'
export const GET_MEETINGS_REQUEST = 'GET_MEETINGS_REQUEST'
export const GET_MEETINGS_SUCCESS = 'GET_MEETINGS_SUCCESS'
export const GET_MEETINGS_ERROR = 'GET_MEETINGS_ERROR'

export const CANCEL_MEETING = 'CANCEL_MEETING'
export const CANCEL_MEETING_REQUEST = 'CANCEL_MEETING_REQUEST'
export const CANCEL_MEETING_SUCCESS = 'CANCEL_MEETING_SUCCESS'
export const CANCEL_MEETING_ERROR = 'CANCEL_MEETING_ERROR'

export const REJECT_MEETING_INVITE = 'REJECT_MEETING_INVITE'
export const REJECT_MEETING_INVITE_REQUEST = 'REJECT_MEETING_INVITE_REQUEST'
export const REJECT_MEETING_INVITE_SUCCESS = 'REJECT_MEETING_INVITE_SUCCESS'
export const REJECT_MEETING_INVITE_ERROR = 'REJECT_MEETING_INVITE_ERROR'

export const ACCEPT_MEETING_INVITE = 'ACCEPT_MEETING_INVITE'
export const ACCEPT_MEETING_INVITE_REQUEST = 'ACCEPT_MEETING_INVITE_REQUEST'
export const ACCEPT_MEETING_INVITE_SUCCESS = 'ACCEPT_MEETING_INVITE_SUCCESS'
export const ACCEPT_MEETING_INVITE_ERROR = 'ACCEPT_MEETING_INVITE_ERROR'
