import { PropsWithChildren } from 'react'
import { PopoverProps } from 'antd'

import { Popover } from 'App/components'

import styles from './FilterPopover.module.scss'

export type TFilterPopoverProps = PropsWithChildren<{
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}> &
  Pick<PopoverProps, 'content'>

export const FilterPopover = ({
  children,
  content,
  visible,
  onVisibleChange
}: TFilterPopoverProps) => (
  <Popover
    overlayClassName={styles.popover}
    arrow={false}
    placement="bottom"
    visible={visible}
    onVisibleChange={onVisibleChange}
    content={content}
  >
    {children}
  </Popover>
)
