import { SubscriptionIdsEnum } from '@medentee/enums'

import { ESubscriptionName } from 'enums'

export const SUBSCRIPTION_NAME_MAP = new Map<SubscriptionIdsEnum, () => ESubscriptionName>()
  .set(SubscriptionIdsEnum.BASIC_PROFESSIONAL, () => ESubscriptionName.BASIC)
  .set(SubscriptionIdsEnum.BASIC_BUSINESS, () => ESubscriptionName.BASIC)
  .set(SubscriptionIdsEnum.PROFESSIONAL, () => ESubscriptionName.PROFESSIONAL)
  .set(SubscriptionIdsEnum.BUSINESS, () => ESubscriptionName.BUSINESS)

export const BASIC_SUBSCRIPTION_IDS = new Set([
  SubscriptionIdsEnum.BASIC_PROFESSIONAL,
  SubscriptionIdsEnum.BASIC_BUSINESS
])
