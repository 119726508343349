import { useTranslation } from 'react-i18next'

import { isAdminAccountSelector, useAppSelector } from 'store'
import {
  UnityHubCreateChannelsMainStep,
  TUnityHubCreateChannelsMainStepProps
} from 'App/components/UnityHub'

import { useRequest, TUseRequest } from './useRequest'

export type TCreateTextChannelDialogMainStepProps = Pick<
  TUnityHubCreateChannelsMainStepProps,
  | 'channelManager'
  | 'isEdit'
  | 'loading'
  | 'isLeaderInDepartments'
  | 'name'
  | 'isPublic'
  | 'setIsPublic'
  | 'setName'
  | 'onOpenMembers'
  | 'channelMembers'
  | 'onOpenChanelManager'
  | 'dirty'
  | 'channelDepartments'
  | 'initialValues'
> &
  Pick<TUseRequest, 'organizationId' | 'channelId' | 'onEditSuccess'>

export const CreateTextChannelDialogMainStep = ({
  channelMembers,
  organizationId,
  channelManager,
  channelDepartments,
  channelId,
  name,
  isEdit,
  loading,
  isLeaderInDepartments,
  dirty,
  isPublic,
  setName,
  setIsPublic,
  onOpenChanelManager,
  onOpenMembers,
  onEditSuccess,
  initialValues
}: TCreateTextChannelDialogMainStepProps) => {
  const isBusiness = useAppSelector(isAdminAccountSelector)

  const { error, isFieldError, processing, handleFormSubmit } = useRequest({
    organizationId,
    channelId,
    channelMembers,
    channelDepartments,
    channelManager,
    isEdit,
    isLeaderInDepartments,
    onEditSuccess
  })

  const { t } = useTranslation()

  const selectManagerDisabled = !isBusiness

  const shouldShowAlert = isLeaderInDepartments && !isEdit

  return (
    <UnityHubCreateChannelsMainStep
      isEdit={isEdit}
      name={name}
      channelMembers={channelMembers}
      channelManager={channelManager}
      channelDepartments={channelDepartments}
      isLeaderInDepartments={isLeaderInDepartments}
      selectManagerDisabled={selectManagerDisabled}
      showAlert={shouldShowAlert}
      alert={t('modal.createChannel.alert_organization')}
      processing={processing}
      loading={loading}
      isFieldError={isFieldError}
      error={error}
      isPublic={isPublic}
      dirty={dirty}
      initialValues={initialValues}
      setName={setName}
      onFormSubmit={handleFormSubmit}
      onOpenChanelManager={onOpenChanelManager}
      onOpenMembers={onOpenMembers}
      setIsPublic={setIsPublic}
    />
  )
}
