import { useEffect, useRef } from 'react'

export const useDidMountEffect = (fn: () => void, dependencies: unknown[]) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      fn()
    } else {
      didMount.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
