import { useMemo } from 'react'
import cls from 'classnames'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { ReactComponent as MoreIcon } from 'assets/icons/More.svg'

import { NavItem } from '../../NavItem'
import { Popover } from '../../../../common'
import { NavItemBadge } from '../../NavItemBadge'
import { isActiveRoute, TNavItem } from '../nav.constants'

import styles from './MoreItems.module.scss'

export type TMoreItemsProps = {
  items: TNavItem[]
}

export const MoreItems = ({ items }: TMoreItemsProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const notifications = useAppSelector((state) => state.notifications.general)

  const isActive = useMemo(
    () =>
      items.some((item) =>
        item.isActive
          ? item.isActive(null, location)
          : isActiveRoute(item.startsWith, item.to, location)
      ),
    [items, location]
  )
  const count = useMemo(
    () =>
      items.reduce(
        (res, { counterStateString = '' }) =>
          (res += (get(notifications, counterStateString) as number) || 0),
        0
      ),
    [items, notifications]
  )

  if (items.length === 0) {
    return null
  }

  return (
    <div>
      <Popover
        arrow={false}
        overlayClassName={styles.popover}
        content={
          <>
            {items.map((item) => (
              <NavItem key={item.itemKey} variant="secondary" {...item} />
            ))}
          </>
        }
        placement="right"
      >
        <div className={cls({ [styles.active]: isActive }, styles.button)}>
          <div className={styles.iconWrapper}>
            <MoreIcon />
            <NavItemBadge isActive={() => isActive} count={count} />
          </div>
          <span>{t('navigation.more')}</span>
        </div>
      </Popover>
    </div>
  )
}
