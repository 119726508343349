import { useMemo, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  hideModalAction,
  PIN_CHAT_MESSAGE,
  pinChatMessageRequest,
  useAppDispatch,
  useAppSelector
} from 'store'

import { Button, Checkbox } from '../../../components'

import styles from './ChatPinMessageConfirmationDialog.module.scss'

export type TChatPinMessageConfirmationDialogProps = {
  chatId: string
  messageId: string
  shareable: boolean
}

export const ChatPinMessageConfirmationDialog = ({
  chatId,
  messageId,
  shareable
}: TChatPinMessageConfirmationDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [shared, setShared] = useState(shareable)

  const processingSelector = useMemo(
    () => createProcessingBySelector([PIN_CHAT_MESSAGE])(messageId),
    [messageId]
  )

  const processing = useAppSelector(processingSelector)

  const handleSubmit = () =>
    dispatch(
      pinChatMessageRequest({
        chatId,
        messageId,
        shared,
        processingId: messageId,
        onSuccess: () => dispatch(hideModalAction())
      })
    )
  const handleChange = (e: CheckboxChangeEvent) => {
    setShared(!e.target.checked)
  }

  return (
    <div className={styles.root}>
      <p>{t('modal.pinMessageConfirm.content')}</p>
      {shareable && (
        <Checkbox
          label={t('modal.pinMessageConfirm.privatePingCheckbox')}
          onChange={handleChange}
          checked={!shared}
        />
      )}
      <Button loading={processing} onClick={handleSubmit}>
        {t('modal.pinMessageConfirm.submitButton')}
      </Button>
    </div>
  )
}
