import { useLocation } from 'react-router-dom'
import cls from 'classnames'

import { NotificationBadge } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './NavItemBadge.module.scss'

export type TNavItemBadgeProps = PropsWithClassName<{
  count: number
  isActive: (match: any, location: any) => boolean
}>

export const NavItemBadge = ({ count, isActive, className }: TNavItemBadgeProps) => {
  const location = useLocation()
  const { isMobile } = useAdaptiveLayout()
  const active = isActive(null, location)

  return (
    <NotificationBadge
      borderCssRootPropertyValue={active ? '--palette-primary-dark' : '--palette-primary'}
      className={cls(styles.root, isMobile && styles.mobile, className)}
      count={count}
      border={true}
    />
  )
}
