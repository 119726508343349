const config = {
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  apiAuthUrl: process.env.REACT_APP_API_AUTH_URL || '',
  apiUrl: process.env.REACT_APP_API_URL || '',
  apiUrlWS: process.env.REACT_APP_API_URL_WS || '',
  apiPort: process.env.REACT_APP_API_PORT || '',
  wsApi: process.env.REACT_APP_SOCET_URL || '',
  defaultRedirectUrl: process.env.REACT_APP_DEFAULT_REDIRECT_URL || '',
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY || '',
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || '',

  // 1=disabled; 0=enabled
  recaptchaDisabled: process.env.REACT_APP_RECAPTCHA_DISABLED === '1',
  jitsiMeetUrl: process.env.REACT_APP_JITSI_MEET_URL || 'https://localhost:8080'
}

export default config
