import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { UploadSourcesEnum } from '@medentee/enums'

import {
  getChatMedCloudFilesRequest,
  resetAttachFilesAction,
  useAppDispatch,
  removeErrorNotification,
  ATTACH_SELECTED_CHAT_MED_CLOUD_FILES,
  useAppSelector,
  createLoadingSelector,
  GET_CHAT_MED_CLOUD_FILES,
  getBroadcastSelectedFilesIdsSelector,
  getAttachFilesErrorSelector,
  getAttachFilesTotalSelector,
  getAttachFilesSearchSelector,
  getAttachFilesUploadingFilesSelector,
  getAttachFilesIdsSelector,
  getAttachFilesSelectedIdsSelector,
  getAttachFilesListSelector,
  setAttachedFiles,
  getAttachFilesAlreadyFilesSelector,
  selectBroadcastFiles,
  TAttachMedcloudFilesList
} from 'store'
import { FileAttachDialog } from 'App/components'

export type TAttachFilesProps = {
  onBack: () => void
  attachedFiles: TAttachMedcloudFilesList
}

const loadingSelector = createLoadingSelector([GET_CHAT_MED_CLOUD_FILES])

export const AttachFiles = ({ onBack, attachedFiles }: TAttachFilesProps) => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const search = useAppSelector(getAttachFilesSearchSelector)
  const total = useAppSelector(getAttachFilesTotalSelector)

  const fileList = useAppSelector(getAttachFilesListSelector, isEqual)
  const fileIds = useAppSelector(getAttachFilesIdsSelector, isEqual)
  const error = useAppSelector(getAttachFilesErrorSelector, isEqual)
  const uploadFileIds = useAppSelector(getAttachFilesUploadingFilesSelector, isEqual)
  const broadcastSelectedFileIds = useAppSelector(getBroadcastSelectedFilesIdsSelector, isEqual)
  const selectedFileIds = useAppSelector(getAttachFilesSelectedIdsSelector, isEqual)
  const alreadyFileIds = useAppSelector(getAttachFilesAlreadyFilesSelector(), isEqual)

  const handleHideAttachModal = useCallback(() => dispatch(resetAttachFilesAction()), [dispatch])
  const handleRemoveErrorNotification = useCallback(
    () => dispatch(removeErrorNotification(ATTACH_SELECTED_CHAT_MED_CLOUD_FILES)),
    [dispatch]
  )
  const handleGetChatMedCloudFiles = useCallback<typeof getChatMedCloudFilesRequest>(
    (payload) => dispatch(getChatMedCloudFilesRequest(payload)),
    [dispatch]
  )
  const handleAttachSelectedFiles = useCallback(() => {
    if (alreadyFileIds.length) {
      dispatch(selectBroadcastFiles({ ids: alreadyFileIds, onSuccess: onBack }))
    }
  }, [dispatch, onBack, alreadyFileIds])

  useEffect(() => {
    if (
      broadcastSelectedFileIds.length &&
      !selectedFileIds.length &&
      Object.values(fileList).length
    ) {
      dispatch(setAttachedFiles({ ids: broadcastSelectedFileIds, isAttached: true }))
    }
  }, [broadcastSelectedFileIds, dispatch, fileList, selectedFileIds.length])

  return (
    <div>
      <FileAttachDialog
        isBroadcast={true}
        error={error}
        fileIds={fileIds}
        total={total}
        uploadFileIds={uploadFileIds}
        search={search}
        loading={loading}
        processing={false}
        attachedFiles={attachedFiles}
        selectedFileIds={selectedFileIds}
        onBack={onBack}
        getChatMedCloudFiles={handleGetChatMedCloudFiles}
        attachSelectedFilesToBroadcast={handleAttachSelectedFiles}
        hideAttachModal={handleHideAttachModal}
        removeErrorNotification={handleRemoveErrorNotification}
        uploadedFrom={UploadSourcesEnum.CHAT}
      />
    </div>
  )
}
