import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { addUsersToCallRequest } from 'store/calls'
import { ChatStartGroupCallDialog } from 'App/components/Chat/ChatStartGroupCallDialog'
import { useAppDispatch } from 'store'

type TChatAddGroupCallDialogContainerProps = {
  callId: string
  caseId: string
}

export const ChatAddGroupCallDialogContainer = ({
  callId,
  caseId
}: TChatAddGroupCallDialogContainerProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleSubmit = useCallback(
    (userIds: string[]) =>
      dispatch(
        addUsersToCallRequest({
          callId,
          userIds
        })
      ),
    [callId, dispatch]
  )

  return (
    <ChatStartGroupCallDialog
      caseId={caseId}
      submitLabel={t('modal.addGroupCallMember.submitButton_add')}
      emptyContentText={t('modal.addGroupCallMember.selectedListPlaceholder_add')}
      onSubmit={handleSubmit}
    />
  )
}
