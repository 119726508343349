import { ReactElement, useEffect, useState } from 'react'

import { EInputSize } from 'enums'
import { useAppSelector } from 'store/store.utils'
import { useAdaptiveLayout } from 'App/hooks'
import { SearchCascader, SearchWrapper, TOption } from 'App/components'

type TSearchProps = {
  filters: ReactElement | null
  searchOptions: TOption[]
  onChange: (search: string, searchBy: (string | number)[]) => void
}

export const Search = ({ searchOptions, filters, onChange }: TSearchProps) => {
  const search = useAppSelector((state) => state.adverts.search)
  const { isDesktop } = useAdaptiveLayout()

  const [key, setKey] = useState<string>('')

  useEffect(() => {
    if (search === '') {
      setKey(Date.now().toString())
    }
  }, [onChange, search])

  return (
    <SearchWrapper filters={filters} hiddenFilter={isDesktop}>
      <SearchCascader
        key={key}
        searchSize={EInputSize.L}
        options={searchOptions}
        onSearchChange={onChange}
        onCascaderChange={onChange}
      />
    </SearchWrapper>
  )
}
