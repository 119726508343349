import { useState } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { FileHistoryWrapperContainer } from 'App/containers'
import { Tabs, DropDownMenu, TabPane, FileDetails } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './FileInformationPanel.module.scss'

export type TFileInformationPanelProps = {
  id: string
  fileSize: string
  createdAt: string
  updatedAt: string

  sources?: FileHistorySourcesEnum[]
  actorAccountIds?: string[]
  withoutPermissions?: boolean
}

export const FileInformationPanel = ({
  id,
  updatedAt,
  createdAt,
  fileSize,
  sources,
  actorAccountIds,
  withoutPermissions
}: TFileInformationPanelProps) => {
  const [tabTitle, setTabTitle] = useState('0')

  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleTabChange = (key: string) => {
    setTabTitle(key)
  }
  return (
    <>
      {isMobile && (
        <DropDownMenu
          list={t('files.information.tabs', { returnObjects: true })}
          title={tabTitle}
          onChange={setTabTitle}
        />
      )}
      <Tabs className={styles.root} activeKey={tabTitle} onChange={handleTabChange}>
        <TabPane tab={t('files.information.tabs.0')} key="0">
          <FileDetails
            withoutPermissions={withoutPermissions}
            id={id}
            updatedAt={updatedAt}
            createdAt={createdAt}
            fileSize={fileSize}
          />
        </TabPane>
        <TabPane tab={t('files.information.tabs.1')} key="1">
          <FileHistoryWrapperContainer
            fileId={id}
            sources={sources}
            actorAccountIds={actorAccountIds}
          />
        </TabPane>
      </Tabs>
    </>
  )
}
