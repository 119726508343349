import { useRef } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'
import { QRCode, TQRCodeProps } from '../QRCode'
import { downloadFileFromUrl } from '../../../../utils'

import styles from './DownloadableQRCode.module.scss'

export type TDownloadableQRCodeProps = TQRCodeProps & {
  fileName?: string
  imageToDownloadSize?: number
}

const downloadFile = ({
  el,
  type = 'image/png',
  fileName
}: {
  el: Element
  type?: string
  fileName: string
}) => {
  const string = new XMLSerializer().serializeToString(el)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const img = new Image()

  img.onload = () => {
    if (ctx) {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)

      const content = canvas.toDataURL(type)

      downloadFileFromUrl({
        content,
        name: fileName
      })
    }
  }
  img.src = `data:image/svg+xml;base64,${btoa(string)}`
}

export const DownloadableQRCode = ({
  className,
  fileName = 'QR code',
  imageToDownloadSize = 1000,
  ...props
}: TDownloadableQRCodeProps) => {
  const { t } = useTranslation()
  const ref = useRef<SVGAElement>(null)

  return (
    <>
      <div className={cls(styles.root, className)}>
        <QRCode className={styles.code} {...props} />
        <div className={styles.buttons}>
          <b>{t('common.qrCode.downloadbale.title')}</b>
          <div className={styles.buttonsWrapper}>
            <Button
              size="xs"
              variant="outlined"
              onClick={() => {
                if (ref.current) {
                  downloadFile({ el: ref.current, fileName })
                }
              }}
            >
              .PNG
            </Button>
            <Button
              size="xs"
              variant="outlined"
              onClick={() => {
                if (ref.current) {
                  downloadFile({ el: ref.current, type: 'image/jpeg', fileName })
                }
              }}
            >
              .JPG
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.downloadable}>
        <QRCode ref={ref} {...props} size={imageToDownloadSize} />
      </div>
    </>
  )
}
