import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { UserProfileBasicInformation } from 'App/components'
import { showModalAction } from 'store/modal'
import {
  GET_USER_PROFILE_BASIC_INFORMATION,
  editBusinessAccountRequest,
  editUserProfileBasicInformationRequest,
  getUserProfileBasicInformationRequest
} from 'store/userProfile'
import {
  createLoadingSelector,
  getCompanySpecializationsRequest,
  getCountriesRequest,
  GET_CITIES,
  GET_COMPANY_SPECIALIZATIONS,
  GET_COUNTRIES
} from 'store'

export type TUserProfileBasicInformationProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([
  GET_USER_PROFILE_BASIC_INFORMATION,
  GET_COUNTRIES,
  GET_COMPANY_SPECIALIZATIONS,
  GET_CITIES
])

const mapStateToProps = (state: State) => {
  const userId = state.global.accountData?.id || ''
  const { firstName, lastName, displayUserName } = state.userProfile.basicInformation || {}

  return {
    userId,
    type: state.global.accountData?.type.name || AccountTypeNames.PROFESSIONAL,
    firstName,
    lastName,
    displayUserName,
    loading: loadingSelector(state),
    accountType: state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      editProfile: editUserProfileBasicInformationRequest,
      editBusinessProfile: editBusinessAccountRequest,
      loadUserProfile: getUserProfileBasicInformationRequest,
      loadCountries: getCountriesRequest,
      loadCompanySpecializations: getCompanySpecializationsRequest
    },
    dispatch
  )

export const UserProfileBasicInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBasicInformation)
