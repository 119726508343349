import { Skeleton, SkeletonProps } from 'antd'
import cls from 'classnames'

import styles from './SkeletonList.module.scss'

export type TSkeletonListProps = SkeletonProps & {
  rows?: number
}
const DEFAULT_LIST_LENGTH = 50

export const SkeletonList = ({
  children,
  className,
  loading,
  rows = DEFAULT_LIST_LENGTH,
  ...rest
}: TSkeletonListProps) => (
  <div className={cls(loading && styles.root, className)}>
    <Skeleton
      loading={loading}
      active
      title={false}
      paragraph={{ rows, width: '100%', className: styles.rows }}
      {...rest}
    >
      {children}
    </Skeleton>
  </div>
)
