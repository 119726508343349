// import * as actionTypes from './system.actionTypes'
import { TSystemAction, TSystemState } from './system.types'

export const initialSystemState: TSystemState = {}

export const systemReducer = (
  state: TSystemState = initialSystemState,
  action: TSystemAction
): TSystemState => {
  switch (action.type) {
    default:
      return state
  }
}
