import { useCallback } from 'react'
import { Row, Col } from 'antd'
import { isEqual } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ICaseDescriptionEntity } from 'interfaces'
import { CaseDetailsDate, CaseDetailsInfo, CaseDetailsPlaceholder, Tooltip } from 'App/components'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import { useCaseNotePlaceholder } from '../useCaseNotePlaceholder'

import styles from './CaseSummaryItem.module.scss'

export type TCaseSummaryItemProps = Pick<
  ICaseDescriptionEntity,
  'data' | 'createdAt' | 'updatedAt'
> & {
  onEdit: () => void
}

export const CaseSummaryItem = ({ createdAt, updatedAt, data, onEdit }: TCaseSummaryItemProps) => {
  const { t } = useTranslation()

  const placeholder = useCaseNotePlaceholder(t('cases.details.notes.placeholder'))

  const dateLabel = useCallback(
    (formattedDate?: string) =>
      createdAt && updatedAt && !isEqual(new Date(createdAt), new Date(updatedAt))
        ? t('cases.details.notes.editedLabel', { date: formattedDate })
        : formattedDate,
    [createdAt, t, updatedAt]
  )

  const showDate = data.length && (updatedAt || createdAt)
  const title = data ? t('cases.details.notes.title') : t('cases.details.notes.title_edit')

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          {showDate ? (
            <CaseDetailsDate date={updatedAt || createdAt} getLabel={dateLabel} />
          ) : (
            <CaseDetailsPlaceholder className={styles.placeholder}>
              {placeholder}
            </CaseDetailsPlaceholder>
          )}
        </Col>

        <Col>
          <Tooltip title={title}>
            <div className={styles.link} onClick={onEdit}>
              {data ? (
                <PencilIcon className={styles.linkIcon} />
              ) : (
                <PlusIcon className={styles.linkIcon} />
              )}
              <span>{title}</span>
            </div>
          </Tooltip>
        </Col>
      </Row>

      <CaseDetailsInfo data={data} />
    </>
  )
}
