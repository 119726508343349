import { useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { useQuery, useQueryClient } from 'services/query'
import { TCascaderOption, TSearchCascaderProps } from 'App/components'
import { stopPropagation } from 'utils'
import { getOrganizationsRequest, useAppDispatch, useAppSelector } from 'store'
import { getOrganizationDepartments } from 'api/organizations'
import { TSearchBy } from 'api/contacts'
import { getCategories } from 'api'

type TDefaultCascaderOption = NonNullableBy<TCascaderOption, 'label'>

type TOption = TDefaultCascaderOption & {
  value: string
  children?: TOption[]
}

export type TUseAllContactsSearchByOptionsProps = {
  onChange: (searchBy: TSearchBy) => void
}

type TUseContactsSearchByOptions = (props: TUseAllContactsSearchByOptionsProps) => {
  options: TOption[]
  onLoadDepartments: (options: TCascaderOption[]) => void
  onCascaderChange: TSearchCascaderProps['onSearchChange']
}

export const useAllContactsSearchByOptions: TUseContactsSearchByOptions = ({ onChange }) => {
  const dispatch = useAppDispatch()

  const organizations = useAppSelector((state) => state.organizations.data, isEqual)

  const { t } = useTranslation()

  const { data: categoryList = [] } = useQuery({
    queryKey: ['categories'],
    queryFn: () => getCategories()
  })

  const organizationList = useMemo(
    () => organizations.ids.map((id) => organizations.list[id]),
    [organizations.ids, organizations.list]
  )

  useEffect(() => {
    dispatch(getOrganizationsRequest({ sortBy: 'NAME' }))
  }, [dispatch])

  const defaultOptions = useMemo(() => {
    const options = [
      {
        value: '',
        label: <span className="cascader-default">{t('contacts.contactsSearchByOptions.all')}</span>
      },
      {
        value: 'contacts',
        label: t('contacts.contactsSearchByOptions.contacts'),
        children: [
          {
            value: 'allContacts',
            label: t('contacts.contactsSearchByOptions.all_contacts')
          },
          ...categoryList.map(({ id, name }) => ({
            value: id,
            label: name
          }))
        ]
      },
      ...(!!organizationList.length
        ? organizationList.map(({ id, businessProfile: { name } }) => ({
            value: id,
            label: name
          }))
        : [
            {
              value: 'placeholder',
              disabled: true,
              label: (
                <span onClick={stopPropagation} className="cascader-placeholder">
                  {t('contacts.contactsSearchByOptions.noOrganizationsPlaceholder')}
                </span>
              )
            }
          ])
    ]

    return options
  }, [categoryList, organizationList, t])

  const [options, setOptions] = useState<TOption[]>(defaultOptions)

  const queryClient = useQueryClient()

  const accountId = useAppSelector((state) => state.global.accountData?.id)

  const handleLoadDepartments = useCallback(
    (selectedOptions: TCascaderOption[]) => {
      const targetOption = selectedOptions[selectedOptions.length - 1]

      if (!targetOption.children) {
        targetOption.loading = true

        queryClient
          .fetchQuery({
            queryKey: ['departments', targetOption.value, accountId],
            queryFn: () =>
              getOrganizationDepartments({
                organizationId: String(targetOption.value) || '',
                nonEmpty: true,
                excludeAccountIds: accountId ? [accountId] : undefined
              })
          })
          .then((res) => {
            targetOption.loading = false
            targetOption.children = [
              { value: 'allStaff', label: t('contacts.contactsSearchByOptions.all_staff') },
              ...res.results.map(({ id, name }) => ({
                value: id,
                label: name
              }))
            ]
            setOptions([...options])
          })
      }
    },
    [accountId, options, queryClient, t]
  )

  const handleChange = useCallback(
    (_: string, searchBy: (string | number)[]) => {
      const isAllContacts = searchBy[1]?.toString() === 'allContacts'
      const isAllStaff = searchBy[1]?.toString() === 'allStaff'
      const isOrganizations = !isNaN(Number(searchBy[0]))
      const isDepartments = isOrganizations && !isAllStaff
      const isCategory = searchBy[0]?.toString() === 'contacts' && !isAllContacts

      if (isCategory) {
        onChange({ group: 'categories', id: searchBy[1]?.toString() })
        return
      }

      if (isDepartments) {
        onChange({ group: 'departments', id: searchBy[1]?.toString() })
        return
      }

      if (isOrganizations) {
        onChange({ group: 'organizations', id: searchBy[0]?.toString() })
        return
      }

      onChange({ group: searchBy[searchBy.length - 1]?.toString() })
    },
    [onChange]
  )

  return {
    options,
    onLoadDepartments: handleLoadDepartments,
    onCascaderChange: handleChange
  }
}
