import { TOptions } from './core'

export const getEventName = (eventName: string, value?: TOptions['extraEventName']): string => {
  if (!value?.length) {
    return eventName
  }

  if (Array.isArray(value)) {
    try {
      return JSON.stringify([eventName, ...value])
    } catch (error) {
      throw new Error(error?.message ?? 'JSON is invalid')
    }
  }

  return `${eventName}-${value}`
}
