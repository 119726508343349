import { useCallback, useMemo } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList, ListItemKeySelector } from 'react-window'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'

import { getMoreItemsLoader } from 'utils/virtualList'

import { ChatRoomSearchResultsItem, TChatRoomSearchResultsItem } from '../ChatRoomSearchResultsItem'

import styles from './ChatRoomSearchResults.module.scss'

export type TChatRoomSearchResultsProps = TChatRoomSearchResultsItem & {
  total: number
  onLoadMore: () => void
}

const ITEM_SIZE = 48
const VISIBLE_ITEMS_AMOUNT = 6
const THRESHOLD = 12
const LIMIT = 50

export const ChatRoomSearchResults = ({
  data,
  total,
  currentIndex,
  chatType,
  onLoadMore,
  onClick
}: TChatRoomSearchResultsProps) => {
  const isItemLoaded = useCallback((index: number) => !!data[index], [data])

  const loadMoreItems = useMemo(
    () =>
      getMoreItemsLoader({
        limit: LIMIT,
        onLoadNext: onLoadMore
      }),
    [onLoadMore]
  )

  const itemData = useMemo<TChatRoomSearchResultsItem>(
    () => ({ data, currentIndex, chatType, onClick }),
    [data, currentIndex, chatType, onClick]
  )

  const getItemKey = useCallback<ListItemKeySelector>(
    (index: number, listData: TChatRoomSearchResultsItem) => listData.data[index].id,
    []
  )

  const listHeight =
    data.length < VISIBLE_ITEMS_AMOUNT ? data.length * ITEM_SIZE : ITEM_SIZE * VISIBLE_ITEMS_AMOUNT
  const initialScrollOffset = currentIndex
    ? currentIndex * ITEM_SIZE - ITEM_SIZE * (VISIBLE_ITEMS_AMOUNT / 2) + ITEM_SIZE / 2
    : 0

  return (
    <div className={styles.root} style={{ height: listHeight }}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            itemCount={total}
            threshold={THRESHOLD}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                ref={ref}
                height={height}
                width={width}
                itemCount={data.length}
                itemData={itemData}
                itemSize={ITEM_SIZE}
                itemKey={getItemKey}
                initialScrollOffset={initialScrollOffset}
                onItemsRendered={onItemsRendered}
              >
                {ChatRoomSearchResultsItem}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  )
}
