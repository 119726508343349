import { TAction } from 'store/store.utils'
import { CASE_DETAILS_RESET, PUBLICATION_OPINIONS_SUCCESS } from 'store'
import { ICaseDetailsDTO } from 'interfaces'

import {
  CASE_LOCK_ERROR,
  CASE_LOCK_REQUEST,
  CASE_UNLOCK_ERROR,
  CASE_UNLOCK_REQUEST,
  CASE_VIEW_ERROR,
  CASE_VIEW_REQUEST,
  CASE_VIEW_SUCCESS,
  CASE_STATUS_CHANGED_SUCCESS,
  CASE_CLEAR_NOTIFICATION,
  SET_CASE_NOTIFICATION,
  CASE_CONVERT_SUCCESS,
  SET_CASE_CLOUD_PERMISSIONS_MODE_SUCCESS,
  UPDATE_CASE_VIEW_DATA_ACTION,
  CASE_LOCK_SUCCESS,
  CASE_UNLOCK_SUCCESS
} from './caseView.actions'
import {
  TCaseLockError,
  TCaseViewError,
  TCaseViewState,
  TCaseViewSuccess,
  TCaseStatusChangedSuccess,
  TCaseClearNotification,
  TPublicationOpinionsRequest,
  TSetCaseNotificationAction,
  TCaseConvertSuccess,
  TSetCaseCloudPermissionsModeSuccess,
  TUpdateCaseViewDataAction
} from './caseView.types'

export const initialCaseViewState: TCaseViewState = {
  data: null,
  lock: {},
  unlock: {},
  error: null
}

export const caseViewReducer = (state = initialCaseViewState, action: TAction): TCaseViewState => {
  switch (action.type) {
    case CASE_VIEW_REQUEST: {
      return {
        ...state,
        error: null
      }
    }

    case CASE_VIEW_SUCCESS: {
      return {
        ...state,
        error: null,
        data: (action as TCaseViewSuccess).payload.data
      }
    }

    case CASE_VIEW_ERROR: {
      return {
        ...state,
        error: (action as TCaseViewError).payload
      }
    }

    case UPDATE_CASE_VIEW_DATA_ACTION: {
      return {
        ...state,
        data: {
          ...(state.data as ICaseDetailsDTO),
          ...(action as TUpdateCaseViewDataAction).payload
        }
      }
    }

    case CASE_LOCK_REQUEST:
    case CASE_LOCK_SUCCESS: {
      return {
        ...state,
        lock: {
          ...state.lock,
          error: null
        }
      }
    }

    case CASE_LOCK_ERROR: {
      return {
        ...state,
        lock: {
          ...state.lock,
          error: (action as TCaseLockError).payload
        }
      }
    }

    case CASE_UNLOCK_REQUEST:
    case CASE_UNLOCK_SUCCESS: {
      return {
        ...state,
        unlock: {
          ...state.unlock,
          error: null
        }
      }
    }

    case CASE_UNLOCK_ERROR: {
      return {
        ...state,
        unlock: {
          ...state.unlock,
          error: (action as TCaseLockError).payload
        }
      }
    }

    case CASE_STATUS_CHANGED_SUCCESS: {
      return {
        ...state,
        data: {
          ...(state.data as ICaseDetailsDTO),
          status: (action as TCaseStatusChangedSuccess).payload.status
        }
      }
    }

    case CASE_DETAILS_RESET: {
      return initialCaseViewState
    }

    case CASE_CLEAR_NOTIFICATION: {
      return {
        ...state,
        data: {
          ...(state.data as ICaseDetailsDTO),
          notifications: (action as TCaseClearNotification).payload.notifications
        }
      }
    }

    case PUBLICATION_OPINIONS_SUCCESS: {
      const oldData = state.data as ICaseDetailsDTO
      const { caseId, currentPublicOpinionStatus } = (action as TPublicationOpinionsRequest).payload

      return {
        ...state,
        data: {
          ...oldData,
          publicOpinions:
            oldData.id === caseId ? currentPublicOpinionStatus : oldData.publicOpinions
        }
      }
    }

    case SET_CASE_NOTIFICATION: {
      const oldData = state.data as ICaseDetailsDTO
      const { notification } = (action as TSetCaseNotificationAction).payload

      return {
        ...state,
        data: {
          ...oldData,
          notifications: [...oldData.notifications, notification]
        }
      }
    }

    case CASE_CONVERT_SUCCESS: {
      const { classification, type = null } = (action as TCaseConvertSuccess).payload

      return {
        ...state,
        data: {
          ...(state.data as ICaseDetailsDTO),
          classification,
          type
        }
      }
    }

    case SET_CASE_CLOUD_PERMISSIONS_MODE_SUCCESS: {
      return {
        ...state,
        data: {
          ...(state.data as ICaseDetailsDTO),
          permissionsMode: (action as TSetCaseCloudPermissionsModeSuccess).payload.permissionsMode
        }
      }
    }

    default:
      return state
  }
}
