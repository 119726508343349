import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TCaseMemberChatContainerProps } from 'App/containers'
import { EmptyList } from 'App/components/common'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

export type TPlaceholderProps = Pick<
  TCaseMemberChatContainerProps,
  'chatRoomId' | 'caseOwnerId' | 'isCoworker' | 'caseClassification' | 'createOrganizationChat'
> & {
  fetchChatRooms: () => void
}

export const Placeholder = ({
  chatRoomId,
  caseOwnerId,
  isCoworker,
  caseClassification,
  fetchChatRooms,
  createOrganizationChat
}: TPlaceholderProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (isCoworker && caseOwnerId && !chatRoomId) {
      createOrganizationChat({
        partnerAccountId: caseOwnerId,
        onSuccess: fetchChatRooms
      })
    }
  }, [
    isCoworker,
    caseOwnerId,
    createOrganizationChat,
    fetchChatRooms,
    chatRoomId,
    caseClassification
  ])

  return <EmptyList text={t('cases.details.chatSection.placeholder_member')} icon={<ForumIcon />} />
}
