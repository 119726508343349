import { useCallback } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { parse, stringify } from 'query-string'
import { useLocation, useHistory, useRouteMatch, generatePath } from 'react-router-dom'
import { ChatTypeEnum } from '@medentee/enums'

import { EAvatarSize, ENotificationBadgeColor, ENotificationBadgeSize } from 'enums'
import { ChatRoomItemWrapper, NotificationBadge, AvatarTeam } from 'App/components'
import { AvatarContainer, TChatRoomItemContainerViewProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import { isChatRoomNotificationVisible } from 'utils/chat'

import styles from './ChatRoomItemCase.module.scss'

export type TChatRoomItemCaseProps = Omit<
  TChatRoomItemContainerViewProps,
  'isLastMessageEditing' | 'draft' | 'chatType' | 'isContact' | 'isCoworker'
>

type TParams = {
  id?: string
  who?: string
}

export const ChatRoomItemCase = ({
  chatId,
  chat,
  loading,
  isSelected,
  unreadCount,
  mentionCount,
  isChatRoomMuted
}: TChatRoomItemCaseProps) => {
  const {
    path,
    params: { id: caseId, who }
  } = useRouteMatch<TParams>()
  const { search } = useLocation()
  const { chatId: queryChatId } = parse(search)
  const { push } = useHistory()

  const { isMobile } = useAdaptiveLayout()

  const isGroupChat = chat && chat.type === ChatTypeEnum.CASE_GROUP

  const shouldShowUnreadCounter = isChatRoomNotificationVisible({
    isMobile,
    mentionCount,
    unreadCount
  })

  const handleChatSelect = useCallback(() => {
    if (queryChatId !== chatId && !loading) {
      push({
        pathname: generatePath(path, { id: caseId, who }),
        search: stringify({ chatId })
      })
    }
  }, [queryChatId, chatId, loading, push, path, caseId, who])

  return (
    <ChatRoomItemWrapper
      isSelected={isSelected}
      className={styles.wrapper}
      onChatSelect={handleChatSelect}
    >
      <Skeleton
        loading={loading}
        active={true}
        avatar={{ shape: 'circle' }}
        paragraph={false}
        title={false}
        className={styles.skeleton}
      >
        <div className={styles.avatarContainer}>
          {isGroupChat ? (
            <AvatarTeam
              border={true}
              muted={isChatRoomMuted}
              size={EAvatarSize.XS}
              onlineBorderColor={isSelected ? undefined : '#FFFFFF'}
              onlineBorderCssRootPropertyValue={isSelected ? '--palette-primary' : undefined}
            />
          ) : (
            chat?.interlocutorAccount && (
              <AvatarContainer
                size={EAvatarSize.XS}
                displayUserName={chat?.interlocutorAccount.displayUserName}
                onlineBorderColor={isSelected ? undefined : '#FFFFFF'}
                onlineBorderCssRootPropertyValue={isSelected ? '--palette-primary' : undefined}
                firstName={chat?.interlocutorAccount.firstName}
                lastName={chat?.interlocutorAccount.lastName}
                type={chat?.interlocutorAccount.type.name}
                border={true}
                userId={chat?.interlocutorAccount.id}
              />
            )
          )}
          <NotificationBadge
            className={cls(styles.count, { [styles.countActive]: isSelected })}
            count={mentionCount}
            icon="@"
            variant="icon"
            size={ENotificationBadgeSize.SM}
          />
          {shouldShowUnreadCounter && (
            <NotificationBadge
              className={cls(styles.count, {
                [styles.countActive]: isSelected && !isChatRoomMuted,
                [styles.countBottom]: mentionCount
              })}
              color={isChatRoomMuted ? ENotificationBadgeColor.MUTED : undefined}
              count={unreadCount}
              size={ENotificationBadgeSize.SM}
            />
          )}
        </div>
      </Skeleton>
    </ChatRoomItemWrapper>
  )
}
