import cls from 'classnames'
import { Skeleton } from 'antd'

import { ChatFieldWrapper } from 'App/components'
import { TChatFieldContainerProps } from 'App/containers'

import styles from './ChatFieldP2P.module.scss'

type TChatFieldP2P = TChatFieldContainerProps

export const ChatFieldP2P = ({
  actionMessage,
  actionType,
  readAll,
  accountType,
  isContact,
  draftMessage,
  isLockedCase,
  receiverId,
  chatId,
  caseId,
  chatStatus,
  ownerId,
  receiverAccountType,
  loading,
  isDormant,
  chatType,
  inactive,
  isCoworker,
  mentionedAccounts,
  mentionedDepartments,
  organizationId,
  channelManagerId,
  sendTypingStart,
  sendNewMessage,
  sendTypingDone,
  sendNewAudioMessage,
  endMessageEditing,
  cancelQuoting,
  showModal,
  updateChatDraft,
  redirectToCaseFromChat
}: TChatFieldP2P) => (
  <Skeleton
    active
    className={cls(styles.skeleton, styles.rootWrapper)}
    title={{ width: '100%', className: styles.skeletonTitle }}
    paragraph={false}
    loading={loading}
  >
    <ChatFieldWrapper
      receiverAccountType={receiverAccountType}
      receiverId={receiverId}
      chatId={chatId}
      ownerId={ownerId}
      caseId={caseId}
      chatStatus={chatStatus}
      actionMessage={actionMessage}
      actionType={actionType}
      readAll={readAll}
      draftMessage={draftMessage}
      sendTypingStart={sendTypingStart}
      sendNewMessage={sendNewMessage}
      sendTypingDone={sendTypingDone}
      sendNewAudioMessage={sendNewAudioMessage}
      endMessageEditing={endMessageEditing}
      cancelQuoting={cancelQuoting}
      updateChatDraft={updateChatDraft}
      redirectToCaseFromChat={redirectToCaseFromChat}
      classes={{
        editorContainer: cls(
          styles.editorContainer,
          isDormant && styles.editorContainerDisabled,
          inactive && styles.editorContainerInactive
        ),
        editorWrapper: cls(styles.editorWrapper, inactive && styles.editorWrapperInactive),
        editorEndAdornment: cls(inactive && styles.editorEndAdornmentInactive),
        rootWrapper: cls(styles.rootWrapper, inactive && styles.rootWrapperInactive),
        audio: styles.audio
      }}
      showModal={showModal}
      isDormant={isDormant}
      accountType={accountType}
      isContact={isContact}
      isLockedCase={isLockedCase}
      loading={loading}
      chatType={chatType}
      isCoworker={isCoworker}
      mentionedAccounts={mentionedAccounts}
      mentionedDepartments={mentionedDepartments}
      organizationId={organizationId}
      channelManagerId={channelManagerId}
      showMaxLength={false}
    />
  </Skeleton>
)
