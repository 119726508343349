import { ProfileLinkTypeNamesEnum } from '@medentee/enums'
import { Col, Row, Skeleton } from 'antd'
import { Field, Form } from 'react-final-form'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { getMapComponent, validation } from 'utils'
import { MAX_LINK_LENGTH, MIN_LINK_LENGTH } from 'globalConstants'
import { ETextFieldSize, TextField, Button, TruncatedText, IconButton } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { SOCIAL_ICON_MAP } from 'App/components/UserProfile/UserProfileSocialLinks'
import { TProfileLink } from 'interfaces'
import { useAdaptiveLayout, useToggle } from 'App/hooks'

import styles from './SocialLink.module.scss'
import { SocialLinkSubmit } from './SocialLinkSubmit'

const LINK_LABEL_MAP = new Map<ProfileLinkTypeNamesEnum, () => string>()
  .set(ProfileLinkTypeNamesEnum.FACEBOOK, () => 'Facebook')
  .set(ProfileLinkTypeNamesEnum.LINKEDIN, () => 'LinkedIn')
  .set(ProfileLinkTypeNamesEnum.TWITTER, () => 'X')
  .set(ProfileLinkTypeNamesEnum.WEBSITE, () => 'Website')

export type TSocialLink = TProfileLink & {
  isNew?: boolean
}

export type TSocialLinkProps = {
  data: TSocialLink
  loading: boolean
  submitProcessing: boolean
  deleteProcessing: boolean
  onCreate: (linkId: string, linkUrl: string, onSuccess: () => void) => void
  onUpdate: (linkId: string, linkUrl: string, onSuccess: () => void) => void
  onDelete: (linkId: string, type: ProfileLinkTypeNamesEnum) => void
  onCancel: (linkId: string) => void
}

export type TSocialLinkFormValues = {
  url: string
}

export const SocialLink = ({
  data,
  loading,
  submitProcessing,
  deleteProcessing,
  onCreate,
  onDelete,
  onUpdate,
  onCancel
}: TSocialLinkProps) => {
  const { value: isEditing, toggleOn: startEditing, toggleOff: endEditing } = useToggle(false)

  const { isMobile } = useAdaptiveLayout()

  const { type, url, id, isNew } = data

  const { t } = useTranslation()

  const onDeleteClick = () => {
    if (isEditing) {
      endEditing()

      return
    }

    if (isNew) {
      onCancel(id)
    } else {
      onDelete(id, type)
    }
  }

  const onSubmit = (values: TSocialLinkFormValues) => {
    if (isNew) {
      onCreate(id, values.url, endEditing)
    } else {
      onUpdate(id, values.url, endEditing)
    }
  }

  const shouldShowUrl = url && !isEditing
  const shouldShowField = isEditing
  const shouldShowSubmitButton = isEditing
  const shouldShowLinkButton = !url && !isEditing
  const shouldShowDeleteButton = !(type !== ProfileLinkTypeNamesEnum.WEBSITE && !url && !isEditing)

  return (
    <Form<TSocialLinkFormValues> onSubmit={onSubmit} initialValues={{ url }}>
      {({ handleSubmit, submitting, valid, touched }) => (
        <div
          className={cls(styles.root, {
            [styles.error]: touched?.url && !valid,
            [styles.editing]: isEditing
          })}
        >
          <Skeleton
            key={id}
            className={styles.skeleton}
            active={true}
            loading={loading}
            avatar={false}
            title={false}
            paragraph={{ rows: 4 }}
          >
            <Row gutter={[8, 0]} align="middle" wrap={false}>
              <Col>{getMapComponent(SOCIAL_ICON_MAP, type, { className: styles.icon })}</Col>
              <Col className={styles.label}>{getMapComponent(LINK_LABEL_MAP, type)}</Col>
            </Row>

            <Row align="middle" gutter={[16, 0]} wrap={false} className={styles.urlContainer}>
              {shouldShowUrl && (
                <Col className={styles.urlWrapper}>
                  <TruncatedText text={url} classes={{ text: styles.url }} />{' '}
                </Col>
              )}

              {shouldShowField && (
                <Col>
                  <Field
                    name="url"
                    validate={validation.composeValidators(
                      validation.required(),
                      validation.isUrl(),
                      validation.minLength(
                        MIN_LINK_LENGTH,
                        t('validationErrors.invalidLink', { ns: 'errors' })
                      ),
                      validation.maxLength(
                        MAX_LINK_LENGTH,
                        t('validationErrors.longLink', { length: MAX_LINK_LENGTH, ns: 'errors' })
                      )
                    )}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        size={ETextFieldSize.SM}
                        placeholder={t('common.socialLink.placeholder')}
                        hideCounter={true}
                        valueLengthMax={MAX_LINK_LENGTH}
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                        disabled={submitting || submitProcessing || deleteProcessing}
                      />
                    )}
                  </Field>
                </Col>
              )}

              {shouldShowSubmitButton && !isMobile && (
                <SocialLinkSubmit
                  deleteProcessing={deleteProcessing}
                  submitProcessing={submitProcessing}
                  handleSubmit={handleSubmit}
                />
              )}

              {shouldShowLinkButton && (
                <Col>
                  <Button size="xs" onClick={startEditing}>
                    {t('common.socialLink.linkButton')}
                  </Button>
                </Col>
              )}

              {shouldShowDeleteButton && (
                <Col>
                  <IconButton
                    classes={{ root: styles.deleteButton }}
                    iconComponent={<CrossIcon />}
                    iconSize={EIconSize.CUSTOM}
                    disabled={deleteProcessing}
                    loading={deleteProcessing}
                    onClick={onDeleteClick}
                  />
                </Col>
              )}
            </Row>
            {shouldShowSubmitButton && isMobile && (
              <Row align="middle">
                <SocialLinkSubmit
                  deleteProcessing={deleteProcessing}
                  submitProcessing={submitProcessing}
                  handleSubmit={handleSubmit}
                />
              </Row>
            )}
          </Skeleton>
        </div>
      )}
    </Form>
  )
}
