import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers/ModalRoot'
import { useOrganizationId } from 'App/components/Organizations/hooks'
import { accountRoleNameSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'

type TUseKebabMenuProps = {
  isStaff: boolean
  onRefresh: () => void
  onClickInviteStaff: () => void
}

export const useKebabMenu = ({ isStaff, onRefresh, onClickInviteStaff }: TUseKebabMenuProps) => {
  const dispatch = useAppDispatch()

  const organizationId = useOrganizationId()

  const accountRoleName = useAppSelector(accountRoleNameSelector)

  const { t } = useTranslation()

  const enableInviteAll = !accountRoleName

  const { getOnClickHandler } = useOrganizationSubscription({
    variant: 'upgrade',
    organizationId,
    callback: onClickInviteStaff
  })

  const menuItems = useMemo(
    () => ({
      inviteStaff: () => ({
        hidden: isStaff,
        content: t('organizations.infoDrawer.kebabMenu.inviteNewStaff'),
        icon: <AccountPlusIcon />,
        onClick: getOnClickHandler()
      }),
      inviteAll: () => ({
        hidden: !isStaff && !enableInviteAll,
        content: t('organizations.infoDrawer.kebabMenu.inviteToContacts'),
        icon: <AccountMultiplePlusIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalType: EModalComponents.INVITE_ALL_ORGANIZATION_MEMBERS_DIALOG,
              modalTitle: t('modal.inviteAllStaffToContactsConfirm.title'),
              modalProps: { organizationId, onSuccess: onRefresh }
            })
          )
      }),
      broadcastMessage: () => ({
        content: t('organizations.infoDrawer.kebabMenu.broadcastMessage'),
        icon: <EmailIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalType: EModalComponents.BROADCAST_MESSAGE_DIALOG,
              modalTitle: t('modal.broadcastMessage.title'),
              modalProps: { organizationId, recipientsType: 'staff' }
            })
          )
      })
    }),
    [isStaff, t, getOnClickHandler, enableInviteAll, dispatch, organizationId, onRefresh]
  )

  const getMenuItems = useCallback(
    () => [menuItems.inviteStaff(), menuItems.inviteAll(), menuItems.broadcastMessage()],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
