import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppSelector } from 'store'
import { formatMonthYear } from 'utils'
import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as BusinessAccountIcon } from 'assets/icons/BusinessAccount.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'

import { ItemHeader, ItemLabel, Item, ItemId, ItemLink } from '../../common'

export type TLicensesItemProps = {
  id: string

  disableContextMenu?: boolean
}

export const LicensesItem = ({ id, disableContextMenu }: TLicensesItemProps) => {
  const dispatch = useDispatch()

  const data = useAppSelector((state) => state.userProfile.viewProfile.licenses.list[id] || {})

  const { t } = useTranslation()

  const { organization, link, name, number, endDate, startDate } = data

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <PencilOutlineIcon />,
        content: t('userProfile.licenses.kebabMenu.edit'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.userLicenses.title_edit'),
              modalType: EModalComponents.LICENSE_DIALOG,
              modalProps: {
                data
              }
            })
          )
      },
      {
        icon: <TrashIcon />,
        content: t('userProfile.licenses.kebabMenu.remove'),
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.removeLicenseConfirm.title'),
              modalType: EModalComponents.REMOVE_LICENSE_DIALOG,
              modalProps: {
                id: data.id
              }
            })
          )
        }
      }
    ],
    [data, dispatch, t]
  )

  const dateLabel = endDate
    ? `${formatMonthYear(startDate)} — ${formatMonthYear(endDate)}`
    : t('userProfile.licenses.noExpiationDateLabel', { startDate: formatMonthYear(startDate) })

  return (
    <Item>
      <Row gutter={[0, 8]}>
        {name && (
          <Col xs={24}>
            <ItemHeader title={name} menuItems={!disableContextMenu ? menuItems : undefined} />
          </Col>
        )}

        <Col xs={24}>
          <ItemId>{t('userProfile.licenses.licenseNumberLabel', { number })}</ItemId>
        </Col>

        {startDate && (
          <Col xs={24}>
            <ItemLabel icon={<ScheduleIcon />} label={dateLabel} />
          </Col>
        )}

        {organization && (
          <Col xs={24}>
            <ItemLabel icon={<BusinessAccountIcon />} label={organization} />
          </Col>
        )}

        {link && (
          <Col xs={24} md={5}>
            <ItemLink link={link} />
          </Col>
        )}
      </Row>
    </Item>
  )
}
