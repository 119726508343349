import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatQuotingMessage } from 'App/components'
import { quoteMessageCancel } from 'store'

export type TChatQuotingMessageContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  quote: state.chat.chatMessages.actions.message,
  type: state.chat.chatMessages.actions.type,
  chatId: state.chat.chatMessages.selectedChatId
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      quoteMessageCancel
    },
    dispatch
  )

export const ChatQuotingMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatQuotingMessage)
