import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { CallOutgoingFooter } from 'App/components'
import { showModalAction } from 'store'

export type TCallOutgoingFooterContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  caseId: state.calls.answer.createCall?.caseId,
  chatType: state.calls.answer.createCall?.type,
  startTime: state.calls.answer.createCall?.startTime
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const CallOutgoingFooterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallOutgoingFooter)
