import { useEffect } from 'react'
import { AxiosError } from 'axios'
import {
  useInfiniteQuery,
  useQuery as useQueryOriginal,
  useMutation as useMutationOriginal,
  useIsMutating,
  useQueryClient,
  QueryClientProvider,
  UseMutationOptions,
  UseMutationResult,
  UseQueryResult,
  UseQueryOptions,
  useIsFetching
} from '@tanstack/react-query'

import type {
  QueryFunction,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  QueryObserverResult,
  MutationKey
} from '@tanstack/react-query'

import { handleError } from '../api'
import { useRefValue } from '../App/hooks/useRefValue'

export function useQuery<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  onError,
  onSuccess,
  ...options
}: Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'onSuccess' | 'onError' | 'initialData'
> & {
  initialData?: () => undefined
  onSuccess?: (data: TData) => void
  onError?: (error: TError) => void
}): UseQueryResult<TData, TError> {
  const { getValue: getOnError } = useRefValue(onError)
  const { getValue: getOnSuccess } = useRefValue(onSuccess)
  const queryResult = useQueryOriginal(options)

  useEffect(() => {
    const cb = getOnSuccess()
    if (queryResult.data && cb) {
      cb(queryResult.data)
    }
  }, [queryResult.data, getOnSuccess])
  useEffect(() => {
    const cb = getOnError()
    if (queryResult.error) {
      if (cb) {
        cb(queryResult.error)
      } else {
        handleError(queryResult.error as unknown as AxiosError)
      }
    }
  }, [queryResult.error, getOnError])

  return queryResult
}

export function useMutation<
  TData = unknown,
  TError = AxiosError,
  TVariables = void,
  TContext = unknown
>(
  options: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'initialData'> & {
    initialData?: () => undefined
  }
): UseMutationResult<TData, TError, TVariables, TContext> {
  return useMutationOriginal(options)
}

export {
  InfiniteData,
  useInfiniteQuery,
  QueryKey,
  QueryClientProvider,
  useIsMutating,
  useQueryClient,
  useIsFetching,
  UseInfiniteQueryOptions,
  QueryFunction,
  QueryObserverResult,
  MutationKey
}
