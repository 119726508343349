import { put, takeLeading, call } from 'redux-saga/effects'

import { API, api } from 'services/api'
import { getRedirectToastInfo, TUnAuthorizedRedirectOptions, unAuthorizedRedirect } from 'utils'
import { handleError } from 'api/utils'

import { sockets } from '../../services/webSocket'
import { getWhoAmiRequest } from '../store.actions'
import i18n from '../../i18n'

import { logoutError, logoutRequest, logoutSuccess, logoutWSAction } from './logout.actions'
import * as actionTypes from './logout.actionTypes'

function* logoutSocket() {
  const socket = sockets.system

  if (socket) {
    yield socket.disconnect()
  }
}

function* logoutRedirect(redirectState?: null | TUnAuthorizedRedirectOptions) {
  yield call(unAuthorizedRedirect, getRedirectToastInfo() ?? redirectState)
}

function* logout({ payload }: ReturnType<typeof logoutRequest>) {
  try {
    yield call(logoutSocket)
    yield call(api.post, `${API.AUTH_URL}${API.LOGOUT}`)

    yield logoutRedirect(payload)
    yield put(logoutSuccess())
  } catch (e) {
    yield put(getWhoAmiRequest())
    yield put(logoutError(e))
    yield handleError(e)
  }
}

function* logoutWS({ payload: { expired, deleted } }: ReturnType<typeof logoutWSAction>) {
  try {
    yield call(logoutSocket)

    let options: TUnAuthorizedRedirectOptions | null = null

    if (expired) {
      options = {
        type: 'warn',
        message: i18n.t('common.toast.expiredSession')
      }
    }

    if (deleted) {
      options = {
        type: 'success',
        message: i18n.t('common.toast.accountDeleted')
      }
    }

    yield logoutRedirect(options)
  } catch (e) {
    yield handleError(e)
    yield console.error(e)
  }
}

export function* logoutSaga() {
  yield takeLeading(actionTypes.GET_LOGOUT_REQUEST, logout)
  yield takeLeading(actionTypes.LOGOUT_WS, logoutWS)
}
