import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { LOCAL_DEVICE_INFO_NOT_FOUND } from 'globalConstants/errors'

import styles from './VideoEntry.module.scss'

export type TVideoEntryProps = {
  label: string
  kind: string
}

const VideoEntryView = ({ kind, label }: TVideoEntryProps) => {
  const [hide, setHide] = useState<boolean>(false)
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  useLayoutEffect(() => {
    let stream: MediaStream | null = null

    const stopWatching = () => {
      if (stream) {
        stream.getVideoTracks().forEach((item) => {
          item.stop()
        })
      }
    }

    const init = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const device = devices
          .filter((item) => item.kind === kind)
          .find((item) => item.label === label)

        if (!device?.deviceId) {
          throw new Error(LOCAL_DEVICE_INFO_NOT_FOUND)
        }

        stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: device.deviceId } }
        })

        setVideoStream(stream)
      } catch (error) {
        setHide(true)
        stopWatching()
        console.error(error)
      }
    }

    init()

    return () => {
      stopWatching()
    }
  }, [kind, label])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = videoStream
    }
  }, [videoStream])

  return hide || !videoStream ? null : (
    <video className={styles.root} ref={videoRef} controls={false} autoPlay={true} />
  )
}

export const VideoEntry = memo(VideoEntryView)
