import { ReactNode, useCallback, useMemo } from 'react'
import cls from 'classnames'
import { CaseCombinedTypeEnum, CaseRolesEnum, CaseStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import capitalize from 'lodash/capitalize'

import { ECaseListSearchBy, EInputSize } from 'enums'
import { TCaseLinksFilters, TCasesFilters, TError, TIds } from 'store'
import {
  EEmptyListIconSize,
  EFilterTypes,
  EmptyList,
  TEmptyList,
  ErrorModal,
  SearchCascader,
  SearchWrapper,
  useFilters,
  Button,
  TButtonProps,
  FilteringResultsPlaceholder,
  TCaseLinkItemBaseProps
} from 'App/components'
import { CaseLinkItemContainer } from 'App/containers'
import { createSelectorOptions } from 'utils'

import styles from './CaseLinkListWrapper.module.scss'

const DEFAULT_CASE_LENGTH = 3

export type TCaseLinkListWrapperProps = Pick<
  TCaseLinksFilters,
  'classifications' | 'roles' | 'statuses' | 'search'
> & {
  caseId: string
  ids: TIds
  emptyIcon: TEmptyList['icon']
  emptyText: TEmptyList['text']
  loading: boolean
  setSearch: (options: Record<'search' | 'searchBy', string>) => void
  setFilter: (options: Record<string, TCasesFilters[]>) => void
  resetFilter: () => void

  actions?: Pick<TButtonProps, 'icon' | 'children' | 'onClick'>[]
  addonBefore?: ReactNode
  isView?: boolean
  shouldShowFilesCount?: boolean
  error?: TError
  onItemClick?: TCaseLinkItemBaseProps['onClick']
}

export const CaseLinkListWrapper = ({
  caseId,
  search,
  classifications,
  roles,
  statuses,
  error,
  ids,
  emptyIcon,
  emptyText,
  actions,
  loading,
  isView,
  shouldShowFilesCount,
  addonBefore,
  setSearch,
  resetFilter,
  setFilter,
  onItemClick
}: TCaseLinkListWrapperProps) => {
  const { t } = useTranslation()

  const notFoundSearch = Boolean(!ids.length && search.length)
  const notFoundFilter = Boolean(
    !ids.length && (classifications.length || roles.length || statuses.length)
  )

  const hasShowEmptyList =
    !ids.length && !search.length && !classifications.length && !roles.length && !statuses.length
  const hasShowList = !hasShowEmptyList && !notFoundFilter && !notFoundSearch

  const handleSearch = useCallback(
    (newSearch: string, newSearchBy: (string | number)[]) => {
      setSearch({
        search: newSearch,
        searchBy: newSearchBy[newSearchBy.length - 1].toString()
      })
    },
    [setSearch]
  )

  const caseRoleOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(CaseRolesEnum), (value) =>
        t(`cases.filters.roles.options.${value}`)
      ),
    [t]
  )

  const caseStatusOptions = useMemo(
    () =>
      // FROM
      // FOR SPECIAL TASK MED-6480
      createSelectorOptions(
        Object.values(CaseStatusesEnum).filter((status) => status !== CaseStatusesEnum.ARCHIVED),
        (value) => capitalize(t(`enum.caseStatusesEnum.${value}`))
      ),
    [t]
  )

  const caseTypeOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(CaseCombinedTypeEnum), (value) =>
        capitalize(t(`cases.filters.type.options.${value}`))
      ),
    [t]
  )

  const searchOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(ECaseListSearchBy), (value) =>
        capitalize(t(`cases.modal.linkCase.search.options.${value}`))
      ),
    [t]
  )

  const { filters } = useFilters({
    innerPopoverProps: { getPopupContainer: (node) => node },
    classes: { popover: styles.filterPopover },
    popover: true,
    collapse: false,
    buttonVariant: 'button',
    value: { roles, statuses, classifications },
    filters: [
      {
        title: t('cases.filters.roles.title'),
        sectionKey: 'roles',
        items: [
          {
            filterKey: 'roles',
            type: EFilterTypes.CHECKBOX,
            options: caseRoleOptions
          }
        ]
      },
      {
        title: t('cases.filters.status.title'),
        sectionKey: 'statuses',
        items: [
          {
            filterKey: 'statuses',
            type: EFilterTypes.CHECKBOX,
            options: caseStatusOptions
          }
        ]
      },
      {
        title: t('cases.filters.type.title'),
        sectionKey: 'classifications',
        items: [
          {
            filterKey: 'classifications',
            type: EFilterTypes.CHECKBOX,
            options: caseTypeOptions
          }
        ]
      }
    ],
    onReset: resetFilter,
    onChange: (key, value) => setFilter({ [key]: value as TCasesFilters[] })
  })

  return (
    <>
      {hasShowEmptyList && (
        <EmptyList
          className={styles.empty}
          icon={emptyIcon}
          text={emptyText}
          iconSize={EEmptyListIconSize.SM}
          hasAppliedFilters={notFoundFilter}
          hasAppliedSearch={notFoundSearch}
        />
      )}

      {actions && (
        <div
          className={cls(
            hasShowEmptyList ? styles.actionsEmptyWrapper : styles.actionsWrapper,
            actions.length === 1 && styles.actionsCenter
          )}
        >
          {actions.map(({ icon, onClick, children }, index) => (
            <Button
              key={index}
              className={styles.button}
              variant="text"
              icon={icon}
              onClick={onClick}
            >
              {children}
            </Button>
          ))}
        </div>
      )}

      {addonBefore && !hasShowEmptyList && addonBefore}

      {!hasShowEmptyList && (
        <SearchWrapper filters={filters} className={styles.search}>
          <SearchCascader
            searchSize={EInputSize.L}
            options={searchOptions}
            onSearchChange={handleSearch}
            onCascaderChange={handleSearch}
          />
        </SearchWrapper>
      )}

      <FilteringResultsPlaceholder
        className={styles.notFound}
        notFoundSearch={notFoundSearch}
        notFoundFilter={notFoundFilter}
      />

      {hasShowList && (
        <div
          className={cls({
            [styles.listWrapper]: true,
            [styles.listSkeleton]: loading,
            [styles.listEmpty]: !hasShowEmptyList,
            [styles.listWrapperScroll]: ids.length > DEFAULT_CASE_LENGTH
          })}
        >
          {ids.map((id: string) => (
            <div key={id} className={styles.listItem}>
              <CaseLinkItemContainer
                caseItemId={id}
                parentCaseId={caseId}
                isView={isView}
                shouldShowFilesCount={shouldShowFilesCount}
                onClick={onItemClick}
              />
            </div>
          ))}
        </div>
      )}

      <ErrorModal error={error} />
    </>
  )
}
