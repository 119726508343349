import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, GET_CASE_DESCRIPTION } from 'store'
import { CaseDescriptionItem, TCaseDescriptionItemBaseProps } from 'App/components'

export type TCaseDescriptionItemContainerOwnProps = TCaseDescriptionItemBaseProps & {
  id: string
}

export type TCaseDescriptionItemContainerProps = ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_CASE_DESCRIPTION])

const mapStateToProps = (state: State, ownProps: TCaseDescriptionItemContainerOwnProps) => {
  const { data, createdAt } = state.caseDetails.description.list[ownProps.id] || {}

  return {
    data,
    createdAt,
    loading: loadingSelector(state),
    showDividerLine: state.caseDetails.description.newLineDescriptionId === ownProps.id,
    ...ownProps
  }
}

export const CaseDescriptionItemContainer = connect(mapStateToProps)(CaseDescriptionItem)
