import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseAutoApproveDialogItem } from 'App/components'
import { createLoadingSelector, GET_CASE_AUTO_APPROVE_CONTACTS, useAppSelector } from 'store'

export type TCaseAutoApproveDialogItemContainerOwnProps = {
  id: string
  onSwitch: (checked: boolean, defaultChecked: boolean, id: string) => void
}

export type TCaseAutoApproveDialogItemContainerProps = ReturnType<
  typeof useCaseAutoApproveDialogItemContainer
> &
  TCaseAutoApproveDialogItemContainerOwnProps

const loadingSelector = createLoadingSelector([GET_CASE_AUTO_APPROVE_CONTACTS])

const useCaseAutoApproveDialogItemContainer = ({
  id
}: TCaseAutoApproveDialogItemContainerOwnProps) => {
  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators({}, dispatch), [dispatch])
  const {
    caseAutoApproveMode,
    id: contactId,
    to
  } = useSelector(
    (state: State) => state.settings.accountAndNotifications.caseAutoApprove.list[id] ?? {}
  )

  const { firstName, lastName, displayUserName, type, id: userId } = to ?? {}
  const loading = useAppSelector(loadingSelector)

  return {
    ...actions,
    caseAutoApproveMode,
    contactId,
    firstName,
    lastName,
    type: type?.name,
    displayUserName,
    userId,
    loading
  }
}

export const CaseAutoApproveDialogItemContainer = (
  ownProps: TCaseAutoApproveDialogItemContainerOwnProps
) => {
  const {
    caseAutoApproveMode,
    contactId,
    firstName,
    lastName,
    displayUserName,
    type,
    userId,
    loading
  } = useCaseAutoApproveDialogItemContainer(ownProps)

  return (
    <CaseAutoApproveDialogItem
      {...ownProps}
      caseAutoApproveMode={caseAutoApproveMode}
      contactId={contactId}
      firstName={firstName}
      lastName={lastName}
      displayUserName={displayUserName}
      type={type}
      userId={userId}
      loading={loading}
    />
  )
}
