import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyFileList } from 'App/components'
import { FileListContainer, TFileListWrapperContainerProps } from 'App/containers'

import styles from './FileListWrapper.module.scss'

export type TFileListWrapperProps = TFileListWrapperContainerProps

// TODO: REFACTOR make a component unifying FileSharedListWrapper && FileListWrapper

const FileListWrapperView = ({
  loadFileList,
  fileIds,
  search,
  pagination,
  sorting,
  loading,
  resetFiles,
  extensionCategories,
  accountId
}: TFileListWrapperProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    loadFileList({
      filters: {
        search,
        extensionCategories
      },
      ownerId: accountId
    })
  }, [search, extensionCategories, accountId, loadFileList])

  useEffect(
    () => () => {
      resetFiles()
    },
    [resetFiles]
  )

  const fileListContainer =
    loading || fileIds.length ? (
      <FileListContainer pagination={pagination} sorting={sorting} loading={loading} />
    ) : (
      <EmptyFileList
        defaultText={t('files.placeholder')}
        extensionCategories={extensionCategories}
        search={search}
        upload={true}
      />
    )

  return <div className={styles.root}>{fileListContainer}</div>
}

export const FileListWrapper = memo(FileListWrapperView)
