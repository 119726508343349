import Skeleton from 'antd/lib/skeleton'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { EAvatarSize } from 'enums'
import { AccountsList } from 'App/components'
import { getChatMembers } from 'api/chats'

const CASE_GROUP_CHAT_MEMBERS_QUERY_KEY = 'case_group_chat_members'

type TCaseAll2AllMembersListContainerProps = {
  chatId: string
}

export const CaseAll2AllMembersListContainer = ({
  chatId
}: TCaseAll2AllMembersListContainerProps) => {
  const { data, isFetching } = useQuery({
    queryKey: [CASE_GROUP_CHAT_MEMBERS_QUERY_KEY],
    queryFn: () => getChatMembers({ chatId }),
    cacheTime: 0
  })

  const { t } = useTranslation()

  if (!data && isFetching) {
    return <Skeleton.Input active />
  }

  if (!data) {
    return null
  }

  return (
    <AccountsList
      accounts={data?.results}
      avatarSize={EAvatarSize.XXS}
      placement="bottomLeft"
      getTitle={(number) =>
        t(`cases.details.chatSection.groupChatMembersPopover.title`, { number })
      }
    />
  )
}
