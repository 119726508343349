import { useCallback, useState, useEffect, useMemo } from 'react'
import { FileExtensionCategoryEnum } from '@medentee/enums'
import { Skeleton } from 'antd'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  GET_FILE_LIST,
  GET_FILE_SHARED_LIST,
  useAppDispatch,
  useAppSelector,
  getFileListRequest,
  getFileSharedListRequest,
  EPermissionSpecificationScope,
  resetFilesForCategory,
  resetFilesSharedForCategory,
  FILE_LIST_DEFAULT_PAGINATION,
  GET_FILES_COUNTS,
  GET_FILES_SHARED_COUNTS
} from 'store'
import {
  EmptyList,
  TabPane,
  Tabs,
  ChatFilesCategoryWrapper,
  TFilesViewVariant,
  InfiniteScroll,
  DropDownMenu
} from 'App/components'
import { ChatFilesItem, CHAT_DETAILS_ID } from 'App/containers'
import { ESorting } from 'enums'
import { useAdaptiveLayout, useLoadingIds } from 'App/hooks'
import { ReactComponent as TextBoxIcon } from 'assets/icons/TextBox.svg'

import styles from './ChatFilesCategories.module.scss'

type TChatFilesCategoriesProps = {
  variant: 'file' | 'fileShared'
  entityId?: string
}

const DEFAULT_IDS_LENGTH = 12

const loadingFilesSelector = createLoadingSelector([GET_FILE_LIST, GET_FILE_SHARED_LIST])
const loadingCountsSelector = createLoadingSelector([GET_FILES_COUNTS, GET_FILES_SHARED_COUNTS])

export const ChatFilesCategories = ({ variant, entityId }: TChatFilesCategoriesProps) => {
  const dispatch = useAppDispatch()

  const { isDesktop, isMobile } = useAdaptiveLayout()

  const { ids, counts, countsTotal, filters } = useAppSelector((state) => state[variant])
  const { id: chatId, interlocutorAccount } =
    useAppSelector((state) => state.chat.chatRooms.selectedChat) || {}
  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const loadingFiles = useAppSelector(loadingFilesSelector)
  const loadingCounts = useAppSelector(loadingCountsSelector)

  const fileIds = useLoadingIds({ ids, loading: loadingFiles, limit: DEFAULT_IDS_LENGTH })

  const { t } = useTranslation()

  const categories = useMemo(
    () =>
      counts
        ? (Object.keys(counts).filter(
            (key) => !!counts[key as FileExtensionCategoryEnum]
          ) as FileExtensionCategoryEnum[])
        : [],
    [counts]
  )

  const [tab, setTab] = useState<FileExtensionCategoryEnum>()

  const handleFetchFiles = useCallback(
    (category: FileExtensionCategoryEnum, page?: number, loadMore?: boolean) => {
      dispatch(
        variant === 'file'
          ? getFileListRequest({
              permissionSpecificationScope: EPermissionSpecificationScope.P2P,
              permissionUserIdScope: interlocutorAccount?.id,
              ownerId: accountId,
              chatId,
              sorting: {
                direction: ESorting.DESC,
                name: 'sharedAt'
              },
              extensionCategories: [category],
              filters: { page },
              loadMore
            })
          : getFileSharedListRequest({
              permissionSpecificationScope: EPermissionSpecificationScope.P2P,
              permissionUserIdScope: accountId,
              ownerId: interlocutorAccount?.id,
              chatId,
              sorting: {
                direction: ESorting.DESC,
                name: 'sharedAt'
              },
              extensionCategories: [category],
              filters: { page },
              loadMore
            })
      )
    },
    [accountId, chatId, dispatch, interlocutorAccount?.id, variant]
  )

  useEffect(() => {
    categories.length && handleFetchFiles(categories[0])
  }, [categories, handleFetchFiles])

  const handleChangeTab = useCallback(
    (category: string) => {
      dispatch(resetFilesForCategory())
      dispatch(resetFilesSharedForCategory())

      if (category !== tab) {
        setTab(category as FileExtensionCategoryEnum)
        handleFetchFiles(category as FileExtensionCategoryEnum)
      }
    },
    [dispatch, handleFetchFiles, tab]
  )

  const handleChangeDropdown = useCallback(
    (id: string) => {
      handleChangeTab(categories[Number(id)])
    },
    [categories, handleChangeTab]
  )

  const handleScrollDown = useCallback(
    (category: FileExtensionCategoryEnum) => (page: number) => {
      handleFetchFiles(category, page, true)
    },
    [handleFetchFiles]
  )

  const isGridView = (category: FileExtensionCategoryEnum): TFilesViewVariant =>
    category === FileExtensionCategoryEnum.IMAGE || category === FileExtensionCategoryEnum.VIDEO
      ? 'grid'
      : 'list'

  useEffect(() => {
    if (categories.length && tab && !categories.includes(tab)) {
      // set new tab if current tab is not available anymore
      setTab(categories[0])
    }
  }, [tab, categories])

  if ((!chatId || !countsTotal) && !loadingFiles && !loadingCounts) {
    return (
      <EmptyList
        icon={<TextBoxIcon />}
        text={
          variant === 'file'
            ? t('chat.details.sharedFilesPlaceholder_owner')
            : t('chat.details.sharedFilesPlaceholder')
        }
      />
    )
  }

  return (
    <>
      {isMobile && (
        <Skeleton
          active={true}
          loading={loadingCounts}
          title={false}
          paragraph={{ className: styles.skeleton, width: '50%', rows: 1 }}
        >
          <DropDownMenu
            classes={{
              root: cls(styles.dropdown, {
                [styles.tabsTop]: Boolean(entityId)
              })
            }}
            list={categories}
            title={tab ? categories.indexOf(tab).toString() : '0'}
            onChange={handleChangeDropdown}
          />
        </Skeleton>
      )}
      <Tabs
        activeKey={tab}
        onChange={handleChangeTab}
        destroyInactiveTabPane={true}
        className={cls(styles.tabs, {
          [styles.infiniteScroll]: !loadingFiles && !fileIds.length,
          [styles.tabsTop]: Boolean(entityId)
        })}
      >
        {chatId &&
          categories.map((category) => (
            <TabPane tab={t(`enum.fileExtensionCategoryEnum.${category}`)} key={category}>
              <InfiniteScroll
                scrollableTarget={isDesktop && CHAT_DETAILS_ID}
                next={handleScrollDown(category)}
                loading={loadingFiles}
                total={counts?.[category] ?? 0}
                page={filters.page ?? 0}
                dataLength={ids.length}
                limit={FILE_LIST_DEFAULT_PAGINATION.showBy - 4}
              >
                <ChatFilesCategoryWrapper
                  view={isGridView(category)}
                  ids={fileIds}
                  loading={loadingFiles}
                >
                  {(id) => (
                    <ChatFilesItem
                      view={isGridView(category)}
                      key={id}
                      id={id}
                      chatId={chatId}
                      variant={variant}
                      loading={loadingFiles}
                      category={category}
                    />
                  )}
                </ChatFilesCategoryWrapper>
              </InfiniteScroll>
            </TabPane>
          ))}
      </Tabs>
    </>
  )
}
