import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { UserProfileProfession } from 'App/components'
import {
  addUserProfileSpecializationRequest,
  deleteUserProfileSpecializationRequest,
  editUserProfileProfessionRequest
} from 'store/userProfile'
import { showModalAction } from 'store/modal'

type UserProfileProfessionProps = {
  id: string
}

export type TUserProfileProfessionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, ownProps: UserProfileProfessionProps) => {
  const { name, isDefault, isActive, specializations, professionLoading, specializationsLoading } =
    state.userProfile.professions.professionsList[ownProps.id] || {}

  return {
    accountType: state.global.accountData?.type.name || AccountTypeNames.PROFESSIONAL,
    id: ownProps.id,
    name,
    isDefault,
    isActive,
    specializations,
    professionLoading,
    specializationsLoading
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editProfession: editUserProfileProfessionRequest,
      showModal: showModalAction,
      addSpecialization: addUserProfileSpecializationRequest,
      deleteSpecialization: deleteUserProfileSpecializationRequest
    },
    dispatch
  )

export const UserProfileProfessionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileProfession)
