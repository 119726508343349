import { useHistory } from 'react-router-dom'

import { Header } from 'App/components/Organizations/InfoDrawer/Header'
import { useAppSelector } from 'store'
import { getTimezone } from 'utils'

type THeaderContainerProps = {
  organizationId: string
}

export const HeaderContainer = ({ organizationId }: THeaderContainerProps) => {
  const { push } = useHistory()
  const {
    id,
    city: { cityName, timezone },
    country: { countryName },
    businessProfile: { name }
  } = useAppSelector((state) => state.organizations.data.list[organizationId])

  const handleUserProfile = () => {
    push(`/profile/${organizationId}`)
  }

  return (
    <Header
      userId={id || ''}
      firstName=""
      lastName=""
      displayUserName={name}
      city={cityName}
      country={countryName}
      timezone={getTimezone(timezone)}
      onClick={handleUserProfile}
    />
  )
}
