import { ReactNode } from 'react'
import { useRouteMatch } from 'react-router'
import cls from 'classnames'

import { accountRoleNameSelector, useAppSelector } from 'store'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './UnityHubViewLayout.module.scss'

type TUnityHubViewLayoutProps = {
  content: ReactNode
  header: ReactNode
  nav: ReactNode
}

export const UnityHubViewLayout = ({ content, header, nav }: TUnityHubViewLayoutProps) => {
  const { isDesktop } = useAdaptiveLayout()
  const { isExact } = useRouteMatch()

  const roleName = useAppSelector(accountRoleNameSelector)
  const isAdminView = !!roleName

  const showSidebar = isDesktop || (!isDesktop && isExact)
  const showContent = isDesktop || (!isDesktop && !isExact)

  return (
    <div className={styles.root}>
      {showSidebar && (
        <div className={cls(styles.sidebar, { [styles.sidebarAdminView]: isAdminView })}>
          <div className={styles.header}>{header}</div>
          {nav}
        </div>
      )}

      {showContent && <div className={styles.content}>{content}</div>}
    </div>
  )
}
