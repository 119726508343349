import { Fragment, useCallback, useState } from 'react'

import { useAdaptiveLayout } from 'App/hooks'
import { Tooltip } from 'App/components'

import { Divider } from '../Divider'

import styles from './MenuList.module.scss'
import { MenuItem, TMenuItemProps } from './MenuItem'

export type TMenuListProps = {
  closeAfterClick: () => void

  menuItems?: TMenuItemProps[]
}

export const MenuList = ({ menuItems, closeAfterClick }: TMenuListProps) => {
  const { isDesktop } = useAdaptiveLayout()
  const [innerItems, setInnerItems] = useState<TMenuItemProps[]>([])
  const [topInnerItem, setTopInnerItem] = useState<TMenuItemProps | null>(null)

  const handleItemClick = useCallback(
    ({ content, disabled, icon, innerMenuItems, onClick }: TMenuItemProps) =>
      () => {
        if (innerMenuItems?.length) {
          setTopInnerItem({ icon, content, disabled })
          setInnerItems((prev) => (prev.length ? [] : innerMenuItems))
          return
        }

        if (onClick) {
          onClick()
        }
      },
    []
  )

  const items = menuItems
    ?.filter(({ hidden }) => !hidden)
    .map((itemProps, index: number) => {
      const {
        content,
        disabled,
        disabledTooltip,
        hidden,
        icon,
        dividerTop,
        innerMenuItems,
        render
      }: TMenuItemProps = itemProps

      const item = render ? (
        render({
          content,
          disabled,
          disabledTooltip,
          hidden,
          icon,
          innerMenuItems,
          onClick: handleItemClick(itemProps),
          closeAfterClick
        })
      ) : (
        <MenuItem
          content={content}
          disabled={disabled}
          disabledTooltip={disabledTooltip}
          hidden={hidden}
          icon={icon}
          shouldShowInnerIcon={Boolean(innerMenuItems?.length)}
          onClick={handleItemClick(itemProps)}
          closeAfterClick={innerMenuItems?.length ? undefined : closeAfterClick}
        />
      )

      return (
        <Fragment key={index}>
          {dividerTop && <Divider className={styles.divider} />}
          <Tooltip key={index} className={styles.tooltip} title={disabledTooltip}>
            {/* https://github.com/ant-design/ant-design/issues/15909#issuecomment-1030778255 */}
            <div>{item}</div>
          </Tooltip>
        </Fragment>
      )
    })

  if (innerItems.length) {
    return (
      <div className={styles.container}>
        {isDesktop && <div>{items}</div>}

        <div>
          {!isDesktop && topInnerItem && (
            <MenuItem
              content={topInnerItem.content}
              icon={topInnerItem.icon}
              shouldShowInnerIcon={true}
              innerIconPosition="left"
              onClick={() => {
                setTopInnerItem(null)
                setInnerItems([])
              }}
            />
          )}

          <MenuList menuItems={innerItems} closeAfterClick={closeAfterClick} />
        </div>
      </div>
    )
  }

  return <>{items}</>
}
