/*
  Dummy component and must not contain business logic
  New component with business logic must be created over this component ex. MembersSelect
*/

import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  ErrorModal,
  TErrorModalProps,
  StepsFooterActions,
  TStepsFooterActionsProps
} from 'App/components'

import { AccountsSelectSelected, TAccountsSelectSelectedItemProps } from './AccountsSelectSelected'
import {
  AccountsSelectOptionsList,
  TAccountsSelectOptionsListProps
} from './AccountsSelectOptionsList'
import styles from './AccountsSelect.module.scss'
import { AccountsSelectRadioGroup } from './AccountsSelectRadioGroup'
import { AccountsSelectGroups, TAccountsSelectGroupsItem } from './AccountsSelectGroups'
import { TAccountsSelectOptionData } from './AccountsSelectOption'

export type TAccountsSelectProps = Pick<
  TAccountsSelectOptionsListProps,
  | 'CustomSearchComponent'
  | 'onSelect'
  | 'items'
  | 'selectedItems'
  | 'getItems'
  | 'onPageChange'
  | 'onSearch'
  | 'loading'
  | 'onSelectAll'
  | 'search'
  | 'hideSearch'
  | 'autoOnline'
  | 'searchPlaceholder'
  | 'page'
  | 'total'
  | 'limit'
  | 'searchBy'
> &
  Pick<TAccountsSelectSelectedItemProps, 'onUnselect' | 'onUnselectAll' | 'hideClearAll'> &
  Pick<
    TStepsFooterActionsProps,
    'submitLabel' | 'submitDisabled' | 'submitLoading' | 'onClickBack' | 'onSubmit'
  > &
  Pick<TErrorModalProps, 'error'> & {
    emptyContentText?: string
    emptyListText?: string
    selectTitle?: string
    maxSelected?: number
    alert?: ReactNode
    submitDisabled?: boolean
    allowGroupSelect?: boolean
    groups?: TAccountsSelectGroupsItem[]
    groupsLoading?: boolean
    groupsPlaceholder?: string
    groupsSearchPlaceholder?: string
    selectType?: EAccountsSelectType
    selectedGroupItems?: TAccountsSelectOptionData[]
    groupsTitle?: string
    getGroups?: () => void
    onGroupsChange?: (selectedIds: string[]) => void
    onTypeChange?: Dispatch<SetStateAction<EAccountsSelectType>>
  }

export enum EAccountsSelectType {
  BY_USER = 'BY_USER',
  BY_GROUP = 'BY_GROUP'
}

export const AccountsSelect: FC<TAccountsSelectProps> = ({
  submitLoading,
  error,
  items,
  groups,
  selectedItems,
  emptyContentText,
  selectTitle,
  submitLabel,
  maxSelected = Infinity,
  search,
  hideSearch,
  alert,
  autoOnline,
  loading,
  searchPlaceholder,
  page,
  CustomSearchComponent,
  total,
  limit,
  submitDisabled,
  hideClearAll,
  emptyListText,
  allowGroupSelect,
  groupsLoading,
  groupsSearchPlaceholder,
  groupsPlaceholder,
  selectType,
  selectedGroupItems,
  groupsTitle,
  onSubmit,
  onClickBack,
  onPageChange,
  onSearch,
  onUnselect,
  onSelect,
  getItems,
  onUnselectAll,
  onSelectAll,
  getGroups,
  onGroupsChange,
  onTypeChange
}) => {
  const [type, setType] = useState<EAccountsSelectType>(EAccountsSelectType.BY_USER)

  const defaultSubmitDisabled = useMemo(
    () =>
      Boolean(
        !selectedItems.length ||
          (maxSelected && selectedItems?.length > maxSelected) ||
          submitLoading
      ),
    [selectedItems.length, maxSelected, submitLoading]
  )

  const selectedGroups = useMemo(() => selectedItems.map(({ id }) => id), [selectedItems])

  const handleChangeType = useCallback(
    (value: EAccountsSelectType) => {
      if (onTypeChange) {
        onTypeChange(value)
      }

      setType(value)
    },
    [onTypeChange]
  )

  useEffect(() => {
    selectType && setType(selectType)
  }, [selectType])

  const shouldShowAccounts = type === EAccountsSelectType.BY_USER || !allowGroupSelect
  const shouldShowGroups =
    type === EAccountsSelectType.BY_GROUP && groupsPlaceholder && onGroupsChange

  return (
    <div className={styles.root}>
      {alert}

      <div className={styles.content}>
        {allowGroupSelect && <AccountsSelectRadioGroup type={type} onChange={handleChangeType} />}

        <div className={styles.selectedBlock}>
          <AccountsSelectSelected
            selected={selectedGroupItems ?? selectedItems}
            emptyContent={emptyContentText}
            hideClearAll={hideClearAll}
            popoverVariant={type === EAccountsSelectType.BY_GROUP ? 'chip' : 'accountName'}
            onUnselect={onUnselect}
            onUnselectAll={onUnselectAll}
          />
        </div>

        {selectTitle && <h3 className={styles.subTitle}>{selectTitle}</h3>}

        {shouldShowAccounts && (
          <AccountsSelectOptionsList
            selectedItems={selectedItems}
            items={items}
            loading={loading}
            disabled={!!maxSelected && maxSelected <= selectedItems.length}
            emptyContent={emptyListText}
            search={search}
            hideSearch={hideSearch}
            autoOnline={autoOnline}
            searchPlaceholder={searchPlaceholder}
            page={page}
            CustomSearchComponent={CustomSearchComponent}
            total={total}
            limit={limit}
            getItems={getItems}
            onSearch={onSearch}
            onSelect={onSelect}
            onUnselect={onUnselect}
            onSelectAll={onSelectAll}
            onPageChange={onPageChange}
          />
        )}

        {shouldShowGroups && (
          <AccountsSelectGroups
            items={groups}
            selectedItems={selectedGroups}
            loading={groupsLoading}
            search={search}
            hideSearch={hideSearch}
            searchPlaceholder={groupsSearchPlaceholder}
            placeholder={groupsPlaceholder}
            title={groupsTitle}
            getGroups={getGroups}
            onSearch={onSearch}
            onChange={onGroupsChange}
          />
        )}

        <StepsFooterActions
          submitLabel={submitLabel}
          submitDisabled={submitDisabled ?? defaultSubmitDisabled}
          submitLoading={submitLoading}
          onClickBack={onClickBack}
          onSubmit={onSubmit}
        />
        <ErrorModal error={error} />
      </div>
    </div>
  )
}
