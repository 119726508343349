import { useMemo } from 'react'
import { sub } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Timer } from 'App/components'

import { ActiveCallLabel } from '../ActiveCallLabel'
import { CallLabel } from '../CallLabel'
import { PendingCallLabel } from '../PendingCallLabel'

type TCallInfoProps = {
  isMyActiveCall: boolean
  ableToSwitch: boolean
  isOnCurrentCall: boolean

  durationMs?: number
}

export const CallInfo = ({
  isMyActiveCall,
  ableToSwitch,
  isOnCurrentCall,
  durationMs
}: TCallInfoProps) => {
  const { t } = useTranslation()

  const startTime = useMemo(() => {
    if (!durationMs) {
      return
    }

    return sub(new Date(), { seconds: durationMs / 1000 })
  }, [durationMs])

  return isMyActiveCall ? (
    <>
      <CallLabel
        ableToSwitch={ableToSwitch}
        tooltip={t('meetings.anotherDeviceCallTooltip')}
        label={<Timer startTime={startTime} />}
      />
      <ActiveCallLabel isOnCurrentCall={isOnCurrentCall} />
    </>
  ) : (
    <PendingCallLabel />
  )
}
