import { FC, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from '../common/Button'

import styles from './WarningPage.module.scss'

export type TWarningPageProps = {
  image: ReactNode
  title: string
  description: string
}

export const WarningPage: FC<TWarningPageProps> = ({ image, title, description }) => {
  const { replace } = useHistory()

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.image}>{image}</div>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <Button onClick={() => replace('/cases')}>{t('common.warningPage.actionButton')}</Button>
      </div>
    </div>
  )
}
