import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Skeleton } from 'antd'
import { Link, useHistory, useParams } from 'react-router-dom'
import { CaseClassificationNameEnum, CaseStatusesEnum, CaseTypesEnum } from '@medentee/enums'
import ResizeObserver from 'resize-observer-polyfill'
import { useTranslation } from 'react-i18next'

import { ECaseAccountType } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { showModalAction, TCaseParams } from 'store'
import {
  CaseClassification,
  CaseDetailsWarningPanel,
  CaseType,
  ContextMenu,
  ContextMenuContext,
  StatusColored,
  TMenuItemProps
} from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as GearSettingIcon } from 'assets/icons/GearSettingFill.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
// import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive.svg'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { ReactComponent as UnLockIcon } from 'assets/icons/UnLock.svg'
import { ReactComponent as ViewLinkedIcon } from 'assets/icons/EyeOutline.svg'
import { ReactComponent as HistoryIcon } from 'assets/icons/File.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { Breadcrumbs, BreadcrumbsItem } from 'App/components/common/Breadcrumbs'

import { CaseViewSelect, TCaseViewSelectValue } from '../CaseViewSelect'
import { useUnlockCaseModal } from '../useUnlockModal'

import styles from './CaseHeader.module.scss'

type TCaseHeader = {
  processing: boolean
  caseName: string
  classificationName: CaseClassificationNameEnum
  caseType: CaseTypesEnum | null
  caseStatus: CaseStatusesEnum
  chatId?: string
  showModal: typeof showModalAction
  setHeaderHeight: (size: number) => void

  view: TCaseViewSelectValue
  onChangeView: (value: TCaseViewSelectValue) => void
}

const SKELETON_ROWS = 2

export function CaseHeader({
  processing,
  caseName,
  classificationName,
  caseType,
  caseStatus,
  showModal,
  setHeaderHeight,
  view,
  onChangeView
}: TCaseHeader) {
  const { push } = useHistory()
  const { who, id } = useParams<TCaseParams>()

  const headerRef = useRef<HTMLDivElement>(null)

  const { isTablet, isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { showModal: showUnlockModal } = useUnlockCaseModal({
    caseId: id,
    caseName,
    caseType
  })

  const isPrivateCase = classificationName === CaseClassificationNameEnum.PRIVATE
  const isProfessionalInitCase =
    classificationName === CaseClassificationNameEnum.PROFESSIONAL && caseType === null

  const isCaseActive = caseStatus === CaseStatusesEnum.ACTIVE
  const isCaseLocked = caseStatus === CaseStatusesEnum.LOCKED
  const isCaseArchived = caseStatus === CaseStatusesEnum.ARCHIVED

  const isOwner = who === ECaseAccountType.OWNER
  const isMember = who === ECaseAccountType.MEMBER

  const isCaseViewSelectAvailable = isDesktop && !isCaseArchived && !isPrivateCase

  const handleResize = useCallback(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight)
    }
  }, [setHeaderHeight])

  useEffect(() => {
    if (!isTablet) {
      setHeaderHeight(0)
      return
    }

    const el = headerRef.current
    const resizeObserver = new ResizeObserver(handleResize)

    handleResize()
    el && resizeObserver.observe(el)

    return () => {
      el && resizeObserver.unobserve(el)
    }
  }, [isTablet, handleResize, setHeaderHeight])

  const menuItems: TMenuItemProps[] = useMemo(
    () => [
      {
        content: t('cases.details.header.kebabMenu.lock'),
        icon: <LockIcon />,
        hidden:
          !isOwner || isCaseLocked || isPrivateCase || isCaseArchived || isProfessionalInitCase,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_LOCK_CONFIRM,
            modalTitle: t('cases.modal.lockCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.unlock'),
        icon: <UnLockIcon />,
        hidden: !isOwner || !isCaseLocked || isPrivateCase,
        onClick: showUnlockModal
      },

      // FOR SPECIAL TASK MED-6480
      // {
      //   content: t('cases.details.header.kebabMenu.archive'),
      //   icon: <ArchiveIcon />,
      //   hidden: !isOwner || isCaseArchived,
      //   onClick: () =>
      //     showModal({
      //       modalType: EModalComponents.CASE_ARCHIVE_CONFIRM,
      //       modalTitle: 'Archive the case?',
      //       modalProps: {
      //         caseId: id
      //       }
      //     })
      // },
      {
        content: t('cases.details.header.kebabMenu.viewLinked'),
        icon: <ViewLinkedIcon />,
        onClick: () =>
          showModal({
            modalType: EModalComponents.LIST_OF_LINKED_CASES,
            modalTitle: t('cases.modal.linkCase.title_list'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.history'),
        icon: <HistoryIcon />,
        hidden: isMember ? isCaseLocked : false,
        onClick: () =>
          push({
            pathname: `/cases/${id}/${isOwner ? 'owner' : 'member'}/history`,
            state: { caseTitle: caseName }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.leave'),
        icon: <LogoutIcon />,
        hidden: isOwner,
        onClick: () =>
          showModal({
            modalType: EModalComponents.CASE_MEMBER_LEAVE_CONFIRM,
            modalTitle: t('cases.modal.leaveCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      },
      {
        content: t('cases.details.header.kebabMenu.delete'),
        icon: <TrashIcon />,
        hidden: !isOwner,
        onClick: () =>
          showModal({
            modalType: EModalComponents.DELETE_CASE,
            modalTitle: t('cases.modal.deleteCaseConfirm.title'),
            modalProps: {
              caseId: id
            }
          })
      }
    ],
    [
      id,
      isOwner,
      isMember,
      isCaseLocked,
      isCaseArchived,
      isPrivateCase,
      isProfessionalInitCase,
      caseName,
      showModal,
      push,
      showUnlockModal,
      t
    ]
  )

  return (
    <div className={styles.root} ref={headerRef}>
      <div className={styles.wrapper}>
        <Skeleton
          loading={processing}
          active={true}
          paragraph={{ rows: SKELETON_ROWS }}
          title={false}
          className={styles.skeleton}
        >
          <Breadcrumbs className={styles.breadcrumbs}>
            <BreadcrumbsItem>
              <Link to="/cases">Cases</Link>
            </BreadcrumbsItem>
            <BreadcrumbsItem>{caseName}</BreadcrumbsItem>
          </Breadcrumbs>

          <div className={styles.headerActionsWrapper}>
            {classificationName === CaseClassificationNameEnum.PRIVATE && (
              <CaseClassification classification={classificationName} />
            )}
            <CaseType type={caseType} classification={classificationName} />
            <ContextMenu
              menuItems={menuItems}
              iconComponent={<GearSettingIcon />}
              startAdornment={
                <StatusColored
                  fill={true}
                  status={caseStatus}
                  statusLabel={t(`cases.caseStatus.${caseStatus}`)}
                  className={styles.statusTitle}
                />
              }
              endAdornment={
                isCaseViewSelectAvailable && (
                  <div className={styles.viewSelect}>
                    <ContextMenuContext.Consumer>
                      {({ close }) => (
                        <CaseViewSelect
                          value={view}
                          onChange={(value) => {
                            close()
                            onChangeView(value)
                          }}
                          className={styles.viewSelectHolder}
                        />
                      )}
                    </ContextMenuContext.Consumer>
                  </div>
                )
              }
              placement="bottomRight"
              classes={{ root: styles.contextMenuRoot, icon: styles.headerActionsWrapperIcon }}
            />
          </div>
        </Skeleton>
        {!isCaseActive && <CaseDetailsWarningPanel isMember={isMember} status={caseStatus} />}
      </div>
    </div>
  )
}
