import { FC } from 'react'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { PlatformInvitationType } from '@medentee/enums'

import { InvitePopover } from '../../containers/InvitePopover'
import { useAdaptiveLayout } from '../../hooks'

export type TInviteButtonContainerProps = {
  invitationType: PlatformInvitationType
}

export const InviteButtonContainer: FC<TInviteButtonContainerProps> = ({
  children,
  invitationType
}) => {
  const { isMobile, isTablet } = useAdaptiveLayout()

  let placement: TooltipPlacement = 'bottom'

  if (isMobile) {
    placement = 'topRight'
  } else if (isTablet) {
    placement = 'bottomRight'
  }

  return (
    <InvitePopover invitationType={invitationType} placement={placement}>
      {children}
    </InvitePopover>
  )
}
