import { useTranslation } from 'react-i18next'

import { TConfirmInviteToContactProps } from 'App/containers/ConfirmInviteToContact'
import { TConfirmRejectWithdrawContactBaseProps } from 'App/containers/ConfirmRejectWithdrawContact'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { EInvitationAction } from 'globalConstants'
import {
  TAcceptContactInviteRequestPayload,
  acceptContactsInviteRequest,
  showModalAction,
  useAppDispatch
} from 'store'

export type TUseContactInvitationResponse = {
  inviteToContacts: (props: TConfirmInviteToContactProps) => void
  rejectContactInvitation: (
    props: Pick<TConfirmRejectWithdrawContactBaseProps, 'contactId' | 'onSuccess'>
  ) => void
  withdrawContactInvitation: (
    props: Pick<TConfirmRejectWithdrawContactBaseProps, 'contactId' | 'onSuccess'>
  ) => void
  acceptContactInvitation: (
    props: PartialBy<
      Pick<TAcceptContactInviteRequestPayload, 'accountId' | 'onSuccess'>,
      'onSuccess'
    >
  ) => void
}

export const useContactInvitation = (): TUseContactInvitationResponse => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const rejectContactInvitation: TUseContactInvitationResponse['rejectContactInvitation'] = ({
    contactId,
    onSuccess
  }) => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.rejectContactInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_REJECT_WITHDRAW_CONTACT,
        modalProps: {
          type: EInvitationAction.REJECT,
          contactId,
          onSuccess
        }
      })
    )
  }

  const withdrawContactInvitation: TUseContactInvitationResponse['withdrawContactInvitation'] = ({
    contactId,
    onSuccess
  }) => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.withdrawContactInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_REJECT_WITHDRAW_CONTACT,
        modalProps: {
          type: EInvitationAction.WITHDRAW,
          contactId,
          onSuccess
        }
      })
    )
  }

  const acceptContactInvitation: TUseContactInvitationResponse['acceptContactInvitation'] = ({
    accountId,
    onSuccess
  }) => {
    dispatch(
      acceptContactsInviteRequest({
        accountId,
        processingId: accountId,
        onSuccess
      })
    )
  }

  const inviteToContacts: TUseContactInvitationResponse['inviteToContacts'] = ({
    accountId,
    caseId,
    onSuccess
  }) => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.inviteToContact.title'),
        modalType: EModalComponents.INVITE_TO_CONTACT_DIALOG,
        modalProps: { caseId, accountId, onSuccess }
      })
    )
  }

  return {
    inviteToContacts,
    rejectContactInvitation,
    withdrawContactInvitation,
    acceptContactInvitation
  }
}
