import { ComponentProps, useCallback, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { hideModalAction } from 'store'
import { State } from 'redux/rootReducer'
import { Modal } from 'App/components'
import { UserProfileWorkExperienceDialog } from 'App/components/UserProfile/UserProfileWorkExperience'
import { WorkExperienceRemoveDialog } from 'App/components/UserProfile/WorkExperience'
import { UserProfileEducationDialog } from 'App/components/UserProfile/UserProfileEducation'
import { UserProfileLicensesDialog } from 'App/components/UserProfile/UserProfileLicenses'
import { LicensesRemoveDialog } from 'App/components/UserProfile/Licenses/LicensesRemoveDialog'
import { PublicationDialog } from 'App/components/UserProfile/Publications/PublicationDialog'
import { PublicationRemoveDialog } from 'App/components/UserProfile/Publications/PublicationRemoveDialog'
import { FileUploadWidgetDiscardDialog } from 'App/components/File/FileUploadWidgetDiscardDialog'
import { RepostAdvertDialog } from 'App/components/Adverts/RepostAdvert/RepostAdvertDialog'
import { DeactivateAdvertDialog } from 'App/components/Adverts/DeactivateAdvert/DeactivateAdvertDialog'
import { RemoveAdvertDialog } from 'App/components/Adverts/RemoveAdvert/RemoveAdvertDialog'
import { EducationRemoveDialog } from 'App/components/UserProfile/Education/EducationRemoveDialog'
import { FileDeletePermanentlyConfirm } from 'App/components/File/FileDeletePermanentlyConfirm'
import { FileMoveToTrashBinDialog } from 'App/components/File/FileMoveToTrashBinDialog'
import { FilesTilesViewSortingDialog } from 'App/components/File/FileTilesView/FilesTilesViewSortingDialog'
import { ScheduleMeetingModal } from 'App/components/ScheduleMeetingModal'
import { LeaveOrganizationDialog } from 'App/components/Organizations/LeaveOrganizationDialog'
import { OneTimeDialog as TopUpOneTimeDialog } from 'App/components/Payments/TopUp/OneTimeDialog'
import { HoldOnDialog as TopUpHoldOnDialog } from 'App/components/Payments/TopUp/HoldOnDialog'
import { PaymentSucceededDialog as TopUpPaymentSucceededDialog } from 'App/components/Payments/TopUp/Result/PaymentSucceededDialog'
import { PaymentFailedDialog as TopUpPaymentFailedDialog } from 'App/components/Payments/TopUp/Result/PaymentFailedDialog'
import { CategoryDialog } from 'App/components/CategoryDialog'
import { SettingsSecurityDeleteAccountConfirm } from 'App/components/Settings/SettingsSecurityAndLogin/SettingsSecurityDeleteAccountConfirm'
import { ChatCreateGroupRemoveMemberConfirm } from 'App/components/Chat/ChatOneToOne/ChatCreateGroup/ChatCreateGroupRemoveMemberConfirm'
import { ChatCreateGroupAddMembersDialog } from 'App/components/Chat/ChatOneToOne/ChatCreateGroup/ChatCreateGroupAddMembersDialog'
import { DeleteChatConfirm } from 'App/components/Chat/ChatOneToOne/ChatCreateGroup/DeleteChatConfirm'
import { LeaveChatConfirm } from 'App/components/Chat/ChatOneToOne/ChatCreateGroup/LeaveChatConfirm'
import { DeleteChatMessageConfirm } from 'App/components/Chat/DeleteChatMessageConfirm'
import { UploadImageDialog } from 'App/components/Dialogs/UploadImageDialog'
import { DeleteImageDialog } from 'App/components/Dialogs/DeleteImageDialog'
import { CommunityCreateChannelDialog } from 'App/components/Communities/CommunityCreateChannels'
import { CommunityLeaveChannelConfirm } from 'App/components/Communities/CommunityLeaveChannelConfirm'
import { RedirectConfirm } from 'App/components/Dialogs/RedirectConfirm'
import { CommunityDeleteTextChannelConfirm } from 'App/components/Communities/CommunityDeleteTextChannelConfirm'
import { EditScheduledMeetingDialog } from 'App/components/ScheduleMeetingModal/EditScheduledMeetingDialog'
import { EditOngoingMeetingDialog } from 'App/components/ScheduleMeetingModal/EditOngoingMeetingDialog'
import { EventCreateChannelDialog } from 'App/components/Events/EventCreateChannels/EventCreateChannelDialog'
import { EventDeleteTextChannelConfirm } from 'App/components/Events/EventDeleteTextChannelConfirm'
import { EventLeaveChannelConfirm } from 'App/components/Events/EventLeaveChannelConfirm'
import { EventsCreateConfirm } from 'App/components/Events/EventsCreateConfirm'
import { BroadcastCreateDialog } from 'App/components/Broadcasts/BroadcastCreateDialog'
import { BroadcastDeleteConfirm } from 'App/components/Broadcasts/BroadcastDeleteConfirm'
import { UserProfileAdministrationAddSuperAdminDialog } from 'App/components/UserProfile/UserProfileAdministration/UserProfileAdministrationAddSuperAdminDialog'
import { ContactRemoveConfirm } from 'App/components/Contact/common/ContactRemoveConfirm'

import { ConfirmInviteToContact } from '../ConfirmInviteToContact'
import { ConfirmRemoveCategory } from '../ConfirmRemoveCategory'
import { ConfirmRejectWithdrawContact } from '../ConfirmRejectWithdrawContact'
import { CaseCreateDialog } from '../Case/CaseCreateDialog'
import { CaseMemberInviteApproveDialog } from '../Case/CaseMemberInviteApproveDialog'
import { CaseMemberInviteRejectDialog } from '../Case/CaseMemberInviteRejectDialog'
import { CaseRemoveMemberConfirm } from '../Case/CaseRemoveMemberConfirm'
import { CaseMemberLeaveConfirm } from '../Case/CaseMemberLeaveConfirm'
import { CaseLockConfirm } from '../Case/CaseLockConfirm'
import { CaseUnLockConfirm } from '../Case/CaseUnLockConfirm'
import { UserProfileDeleteProfessionConfirmContainer } from '../UserProfile/UserProfileDeleteProfessionConfirmContainer'
import { ChatDeleteAllMessagesConfirmContainer } from '../Chat/ChatDeleteAllMessagesConfirmContainer'
import { CaseAttachFileDialogContainer } from '../Case/CaseAttachFileDialog'
import { ChatAttachFileDialogContainer } from '../Chat/ChatAttachFileDialogContainer'
import { CaseArchiveConfirmContainer } from '../Case/CaseArchiveConfirmContainer'
import { CaseCloudCopySharedWithMeConfirmContainer } from '../Case/CaseCloudCopySharedWithMeConfirmContainer'
import { BusinessAccountCreateDialogContainer } from '../BusinessAccount/BusinessAccountCreateDialogContainer'
import { ChatDiscardPermissionConfirmContainer } from '../Chat/ChatDiscardPermissionConfirmContainer'
import { ChatStartGroupCallDialogContainer } from '../Chat/ChatStartGroupCallDialogContainer'
import { LeavePageConfirmContainer } from '../LeavePageConfirmContainer'
import { ChatAddGroupCallDialogContainer } from '../Chat/ChatAddGroupCallDialogContainer'
import { CaseCloudPermissionsModeChangeConfirmContainer } from '../Case/CaseCloudPermissionsModeChangeConfirmContainer'
import { UserProfileAdministrationAddAdminDialogContainer } from '../UserProfile/UserProfileAdministrationContainer/UserProfileAdministrationAddAdminDialogContainer'
import { CaseAutoApproveDialogContainer } from '../Settings/AccountAndNotifications/CaseAutoApproveDialogContainer'
import { SettingsSecurityAndLogin2FAConfirmContainer } from '../Settings/SettingsSecurityAndLogin/SettingsSecurityAndLogin2FAConfirmContainer'
import { UserProfileResignFromRoleConfirmContainer } from '../UserProfile/UserProfileAdministrationContainer/UserProfileResignFromRoleConfirmContainer'
import { UserProfileAdministrationRemoveAdminConfirmContainer } from '../UserProfile/UserProfileAdministrationContainer/UserProfileAdministrationRemoveAdminConfirmContainer'
import { ConfirmBusinessAdminInviteContainer } from '../Dialogs/ConfirmBusinessAdminInviteContainer'
import { SettingsSecurityDefaultAccountDialogContainer } from '../Settings/SettingsSecurityAndLogin/SettingsSecurityDefaultAccountDialogContainer'
import { SettingsSecurityChangePasswordConfirmContainer } from '../Settings/SettingsSecurityAndLogin/SettingsSecurityChangePasswordConfirmContainer'
import { SettingsInfoChangeEmailConfirmContainer } from '../Settings/SettingsInfo/SettingsInfoChangeEmailConfirmContainer'
import { CaseHistoryFilterModalContainer } from '../CaseHistoryContainer'
import { CreateAdvertDialog } from '../Adverts/CreateAdvertDialog'
import { RemoveRequestDialog } from '../Adverts/RemoveRequest'
import { IgnoreRequestDialog } from '../Adverts/IgnoreRequest'
import { BecomeContactsDialog } from '../Adverts/BecomeContacts'
import { WithdrawRequestDialog } from '../Adverts/WithdrawRequest'
import { ConnectionRequestDialog } from '../Adverts/ConnectionRequestDialog'
import { FileRestoreDialog } from '../TrashBin/FileRestoreDialog'
import { RenewSubscriptionPlanDialogContainer } from '../Subscriptions/RenewSubscriptionPlanDialogContainer'
import { CaseDeleteDialog } from '../Case/CaseDeleteDialog'
import { CaseConvertModal } from '../Case/CaseConvertModal'
import { AttachFilesFromLinkedCaseDialog } from '../AttachFilesFromLinkedCaseDialog'
import { FileDiscardCasesPermissionsConfirmContainer } from '../File/FileInformation/FileDiscardCasesPermissionsConfirm'
import { CaseEditTitleDialogContainer } from '../Case/CaseEditTitleDialog'
import { CaseLinkDialogContainer } from '../Case/CaseLinkDialogContainer'
import { BroadcastMessageDialog } from '../Contact/BroadcastMessageDialog'
import { ChangeSubscriptionPlanDialogContainer } from '../Subscriptions/ChangeSubscriptionPlanDialogContainer'
import { RelatedAccountsListDialogContainer } from '../RelatedAccounts/RelatedAccountsListDialogContainer'
import { MeetingsModal } from '../Meetings/MeetingsModal'
import { CancelMeetingDialog } from '../Meetings/CancelMeetingDialog'
import { CallPerformanceSettingsDialog } from '../Calls/CallPerformanceSettingsDialog'
import { RemoveOrganizationStaffDialog } from '../Organization/InfoDrawer/RemoveOrganizationStaffDialog'
import { UpgradeSubscription } from '../Subscriptions/UpgradeSubscription/UpgradeSubscription'
import { CaseListLinkedCasesDialogContainer } from '../Case/CaseListLinkedCasesDialogContainer'
import { FileDiscardPermissionsConfirmContainer } from '../File/FileDiscardPermissionsConfirm'
import { CaseCloudFileDiscardPermissionsConfirmContainer } from '../Case/CaseCloudFileDiscardPermissionsConfirm'
import { FileRenameDialogContainer } from '../File/FileRenameDialog'
import { CaseCloudRenameFileDialog } from '../Case/CaseCloudRenameFileDialog'
import {
  InviteAllOrganizationMembersDialog,
  RedirectToOrganizationAdminChat,
  RedirectToSubscriptionPage
} from '../Organization'
import { UpdateShowcaseDialog } from '../Showcase/UpdateShowcaseDialog'
import { DeactivateShowcaseConfirmationModal } from '../Showcase/common/DeactivateShowcaseConfirmationModal'
import { DeleteShowcaseConfirmationModal } from '../Showcase/common/DeleteShowcaseConfirmationModal'
import { CreateTextChannelDialog } from '../Organization/CreateChannels/CreateTextChannelDialog'
import { InviteMembersTextChannelDialog } from '../Organization/CreateChannels/InviteMembersTextChannelDialog'
import { CreateVoiceChannelDialog } from '../Organization/CreateChannels/CreateVoiceChannelDialog'
import { CreateDepartmentModal } from '../Organization/CreateDepartmentModal'
import { ManageDepartmentStaffModal } from '../Organization/ManageDepartmentStaff/ManageDepartmentStaffModal'
import { ManageDepartmentLeadModal } from '../Organization/ManageDepartmentLead/ManageDepartmentLeadModal'
import { OrganizationDeleteTextChannelConfirm } from '../Organization/OrganizationDeleteTextChannelConfirm'
import { OrganizationDeleteVoiceChannelConfirm } from '../Organization/OrganizationDeleteVoiceChannelConfirm'
import { DeleteDepartmentDialog } from '../Organization/StaffTab/DeleteDepartmentDialog'
import { RemoveStaffFromDepartmentDialog } from '../Organization/StaffTab/RemoveStaffFromDepartmentDialog'
import { MonthlyTopUpDialog } from '../../components/Payments/TopUp/MonthlyTopUpDialog'
import { MonthlyTopUpCancelDialog } from '../../components/Payments/TopUp/MonthlyTopUpCancelDialog'
import { MonthlyTopUpSucceededDialog } from '../../components/Payments/TopUp/Result/MonthlyTopUpSucceededDialog'
import { NotificationsPopover } from '../Notifications/NotificationsPopover'
import { UpgradeSubscriptionFailed } from '../Subscriptions/UpgradeSubscriptionFailed'
import { UserProfileConnectByLinkDialog } from '../UserProfile/UserProfileConnectByLinkDialog'
import { UserProfileSwitchAccountDialog } from '../UserProfile/UserProfileSwitchAccountDialog'
import { InvitationLinkSettings } from '../Invitations/InvitationLinkSettings'
import { InviteByEmail } from '../Invitations/InviteByEmail'
import { RemoveCommunityMemberConfirm } from '../Communities/RemoveCommunityMemberConfirm'
import { GenericConfirmation } from '../GenericConfirmation'
import { ChatPinMessageConfirmationDialog } from '../Chat/ChatPinMessageConfirmationDialog'
import { ChatUnpinConfirmationDialog } from '../Chat/ChatUnpinComfirmationDialog'
import { CaseMembersSelect } from '../Case/CaseMembersSelect'
import { InviteToExternalMeetingModal } from '../Meetings/InviteToExternalMeetingModal'

import { EModalComponents } from './ModalRoot.enums'

const MODAL_COMPONENTS_MAP = {
  [EModalComponents.CATEGORY_DIALOG]: CategoryDialog,
  [EModalComponents.CONFIRM_REMOVE_CATEGORY]: ConfirmRemoveCategory,
  [EModalComponents.CONFIRM_REJECT_WITHDRAW_CONTACT]: ConfirmRejectWithdrawContact,
  [EModalComponents.INVITE_TO_CONTACT_DIALOG]: ConfirmInviteToContact,
  [EModalComponents.CASE_INVITE_MEMBERS_DIALOG]: CaseMembersSelect,
  [EModalComponents.CASE_CREATE_DIALOG]: CaseCreateDialog,
  [EModalComponents.CASE_MEMBER_INVITE_APPROVE_DIALOG]: CaseMemberInviteApproveDialog,
  [EModalComponents.CASE_MEMBER_INVITE_REJECT_DIALOG]: CaseMemberInviteRejectDialog,
  [EModalComponents.CASE_EDIT_TITLE_DIALOG]: CaseEditTitleDialogContainer,
  [EModalComponents.CASE_MEMBER_LEAVE_CONFIRM]: CaseMemberLeaveConfirm,
  [EModalComponents.CASE_REMOVE_MEMBER_CONFIRM]: CaseRemoveMemberConfirm,
  [EModalComponents.CASE_LOCK_CONFIRM]: CaseLockConfirm,
  [EModalComponents.CASE_UNLOCK_CONFIRM]: CaseUnLockConfirm,
  [EModalComponents.UPGRADE_SUBSCRIPTION]: UpgradeSubscription,
  [EModalComponents.UPGRADE_SUBSCRIPTION_FAILED]: UpgradeSubscriptionFailed,
  [EModalComponents.CASE_ARCHIVE_CONFIRM]: CaseArchiveConfirmContainer,
  [EModalComponents.LIST_OF_LINKED_CASES]: CaseListLinkedCasesDialogContainer,
  [EModalComponents.CASE_LINK_DIALOG]: CaseLinkDialogContainer,
  [EModalComponents.CASE_ATTACH_FILE_DIALOG]: CaseAttachFileDialogContainer,
  [EModalComponents.CASE_FILE_RENAME_DIALOG]: CaseCloudRenameFileDialog,
  [EModalComponents.FILE_RENAME_DIALOG]: FileRenameDialogContainer,
  [EModalComponents.CASE_FILE_DISCARD_PERMISSIONS_CONFIRM]:
    CaseCloudFileDiscardPermissionsConfirmContainer,
  [EModalComponents.FILE_DISCARD_PERMISSIONS_CONFIRM]: FileDiscardPermissionsConfirmContainer,
  [EModalComponents.DELETE_USER_PROFILE_PROFESSION_CONFIRM]:
    UserProfileDeleteProfessionConfirmContainer,
  [EModalComponents.REDIRECT_CONFIRM]: RedirectConfirm,
  [EModalComponents.DISCARD_CASES_PERMISSIONS_CONFIRM]: FileDiscardCasesPermissionsConfirmContainer,
  [EModalComponents.DELETE_ALL_MESSAGES_CONFIRM]: ChatDeleteAllMessagesConfirmContainer,
  [EModalComponents.BROADCAST_MESSAGE_DIALOG]: BroadcastMessageDialog,
  [EModalComponents.CHAT_ATTACH_FILE_DIALOG]: ChatAttachFileDialogContainer,
  [EModalComponents.REMOVE_CONTACT_CONFIRM]: ContactRemoveConfirm,
  [EModalComponents.COPY_TO_CASE_CLOUD]: CaseCloudCopySharedWithMeConfirmContainer,
  [EModalComponents.CREATE_BUSINESS_ACCOUNT_DIALOG]: BusinessAccountCreateDialogContainer,
  [EModalComponents.DELETE_FILE_FROM_MEDCLOUD_CONFIRM]: FileDeletePermanentlyConfirm,
  [EModalComponents.CHAT_DISCARD_PERMISSION_CONFIRM]: ChatDiscardPermissionConfirmContainer,
  [EModalComponents.START_GROUP_CALL_DIALOG]: ChatStartGroupCallDialogContainer,
  [EModalComponents.ADD_TO_GROUP_CALL_DIALOG]: ChatAddGroupCallDialogContainer,
  [EModalComponents.LEAVE_PAGE_CONFIRM]: LeavePageConfirmContainer,
  [EModalComponents.CASE_CLOUD_PERMISSIONS_MODE_CHANGE_CONFIRM]:
    CaseCloudPermissionsModeChangeConfirmContainer,
  [EModalComponents.ADD_ADMIN_DIALOG]: UserProfileAdministrationAddAdminDialogContainer,
  [EModalComponents.ADD_SUPERADMIN_DIALOG]: UserProfileAdministrationAddSuperAdminDialog,
  [EModalComponents.SETTINGS_CASE_AUTO_APPROVE]: CaseAutoApproveDialogContainer,
  [EModalComponents.SETTINGS_2FA_CONFIRM]: SettingsSecurityAndLogin2FAConfirmContainer,
  [EModalComponents.RESIGN_FROM_ADMIN_ROLE_CONFIRM]: UserProfileResignFromRoleConfirmContainer,
  [EModalComponents.REMOVE_ADMIN_CONFIRM]: UserProfileAdministrationRemoveAdminConfirmContainer,
  [EModalComponents.CREATE_ADVERT_DIALOG]: CreateAdvertDialog,
  [EModalComponents.REMOVE_REQUEST_DIALOG]: RemoveRequestDialog,
  [EModalComponents.WITHDRAW_REQUEST_DIALOG]: WithdrawRequestDialog,
  [EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE]: ConfirmBusinessAdminInviteContainer,
  [EModalComponents.CHANGE_SUBSCRIPTION_PLAN_DIALOG]: ChangeSubscriptionPlanDialogContainer,
  [EModalComponents.CHANGE_DEFAULT_ACCOUNT]: SettingsSecurityDefaultAccountDialogContainer,
  [EModalComponents.CHANGE_PASSWORD]: SettingsSecurityChangePasswordConfirmContainer,
  [EModalComponents.CHANGE_PERSONAL_EMAIL]: SettingsInfoChangeEmailConfirmContainer,
  [EModalComponents.SET_CASE_HISTORY_FILTERS]: CaseHistoryFilterModalContainer,
  [EModalComponents.DEACTIVATE_ADVERT_DIALOG]: DeactivateAdvertDialog,
  [EModalComponents.IGNORE_REQUEST_DIALOG]: IgnoreRequestDialog,
  [EModalComponents.BECOME_CONTACTS_DIALOG]: BecomeContactsDialog,
  [EModalComponents.CONNECTION_REQUEST_DIALOG]: ConnectionRequestDialog,
  [EModalComponents.REMOVE_LICENSE_DIALOG]: LicensesRemoveDialog,
  [EModalComponents.REMOVE_WORKPLACE_DIALOG]: WorkExperienceRemoveDialog,
  [EModalComponents.REMOVE_EDUCATION_DIALOG]: EducationRemoveDialog,
  [EModalComponents.WORKPLACE_DIALOG]: UserProfileWorkExperienceDialog,
  [EModalComponents.LICENSE_DIALOG]: UserProfileLicensesDialog,
  [EModalComponents.EDUCATION_DIALOG]: UserProfileEducationDialog,
  [EModalComponents.PUBLICATION_DIALOG]: PublicationDialog,
  [EModalComponents.REMOVE_PUBLICATION_DIALOG]: PublicationRemoveDialog,
  [EModalComponents.MOVE_FILE_TO_TRASH_BIN_DIALOG]: FileMoveToTrashBinDialog,
  [EModalComponents.RESTORE_FILE_DIALOG]: FileRestoreDialog,
  [EModalComponents.RENEW_SUBSCRIPTION_PLAN_DIALOG]: RenewSubscriptionPlanDialogContainer,
  [EModalComponents.DELETE_CASE]: CaseDeleteDialog,
  [EModalComponents.CASE_CONVERT]: CaseConvertModal,
  [EModalComponents.RELATED_ACCOUNTS_DIALOG]: RelatedAccountsListDialogContainer,
  [EModalComponents.FILES_TILE_VIEW_SORTING_DIALOG]: FilesTilesViewSortingDialog,
  [EModalComponents.FILE_UPLOAD_WIDGET_DISCARD]: FileUploadWidgetDiscardDialog,
  [EModalComponents.SCHEDULE_MEETING_MODAL]: ScheduleMeetingModal,
  [EModalComponents.ATTACH_FILES_FROM_LINKED_CASE]: AttachFilesFromLinkedCaseDialog,
  [EModalComponents.MEETINGS]: MeetingsModal,
  [EModalComponents.CANCEL_MEETING]: CancelMeetingDialog,
  [EModalComponents.EDIT_SCHEDULED_MEETING]: EditScheduledMeetingDialog,
  [EModalComponents.EDIT_ONGOING_MEETING]: EditOngoingMeetingDialog,
  [EModalComponents.CALL_PERFORMANCE_SETTINGS_DIALOG]: CallPerformanceSettingsDialog,
  [EModalComponents.REMOVE_ORGANIZATION_STAFF_DIALOG]: RemoveOrganizationStaffDialog,
  [EModalComponents.LEAVE_ORGANIZATION_DIALOG]: LeaveOrganizationDialog,
  [EModalComponents.INVITE_ALL_ORGANIZATION_MEMBERS_DIALOG]: InviteAllOrganizationMembersDialog,
  [EModalComponents.REDIRECT_TO_SUBSCRIPTION_PAGE]: RedirectToSubscriptionPage,
  [EModalComponents.REDIRECT_TO_ORGANIZATION_ADMIN_CHAT]: RedirectToOrganizationAdminChat,
  [EModalComponents.TOP_UP_ONE_TIME]: TopUpOneTimeDialog,
  [EModalComponents.TOP_UP_HOLD_ON]: TopUpHoldOnDialog,
  [EModalComponents.TOP_UP_PAYMENT_SUCCEEDED]: TopUpPaymentSucceededDialog,
  [EModalComponents.TOP_UP_PAYMENT_FAILED]: TopUpPaymentFailedDialog,
  [EModalComponents.TOP_UP_MONTHLY]: MonthlyTopUpDialog,
  [EModalComponents.TOP_UP_MONTHLY_CANCEL]: MonthlyTopUpCancelDialog,
  [EModalComponents.TOP_UP_MONTHLY_SUCCEEDED]: MonthlyTopUpSucceededDialog,
  [EModalComponents.UPDATE_SHOWCASE_DIALOG]: UpdateShowcaseDialog,
  [EModalComponents.DEACTIVATE_SHOWCASE]: DeactivateShowcaseConfirmationModal,
  [EModalComponents.DELETE_SHOWCASE]: DeleteShowcaseConfirmationModal,
  [EModalComponents.CREATE_DEPARTMENT]: CreateDepartmentModal,
  [EModalComponents.SELECT_DEPARTMENT_STAFF]: ManageDepartmentStaffModal,
  [EModalComponents.SELECT_DEPARTMENT_LEAD]: ManageDepartmentLeadModal,
  [EModalComponents.REMOVE_ADVERT_DIALOG]: RemoveAdvertDialog,
  [EModalComponents.REPOST_ADVERT_DIALOG]: RepostAdvertDialog,
  [EModalComponents.ORGANIZATION_DELETE_TEXT_CHANNEL_CONFIRM]: OrganizationDeleteTextChannelConfirm,
  [EModalComponents.ORGANIZATION_DELETE_VOICE_CHANNEL_CONFIRM]:
    OrganizationDeleteVoiceChannelConfirm,
  [EModalComponents.COMMUNITY_DELETE_TEXT_CHANNEL_CONFIRM]: CommunityDeleteTextChannelConfirm,
  [EModalComponents.CREATE_TEXT_CHANNEL]: CreateTextChannelDialog,
  [EModalComponents.COMMUNITY_CREATE_CHANNEL]: CommunityCreateChannelDialog,
  [EModalComponents.CREATE_VOICE_CHANNEL]: CreateVoiceChannelDialog,
  [EModalComponents.INVITE_MEMBERS_TEXT_CHANNEL]: InviteMembersTextChannelDialog,
  [EModalComponents.DELETE_DEPARTMENT_DIALOG]: DeleteDepartmentDialog,
  [EModalComponents.REMOVE_STAFF_FROM_DEPARTMENT_DIALOG]: RemoveStaffFromDepartmentDialog,
  [EModalComponents.DELETE_SELF_ACCOUNT_CONFIRM]: SettingsSecurityDeleteAccountConfirm,
  [EModalComponents.NOTIFICATIONS]: NotificationsPopover,
  [EModalComponents.CONNECT_BY_LINK_DIALOG]: UserProfileConnectByLinkDialog,
  [EModalComponents.SWITCH_ACCOUNT_DIALOG]: UserProfileSwitchAccountDialog,
  [EModalComponents.INVITATION_LINK_SETTINGS]: InvitationLinkSettings,
  [EModalComponents.INVITE_BY_EMAIL]: InviteByEmail,
  [EModalComponents.INVITE_TO_EXTERNAL_MEETING_MODAL]: InviteToExternalMeetingModal,
  [EModalComponents.REMOVE_GROUP_CHAT_MEMBER_CONFIRM]: ChatCreateGroupRemoveMemberConfirm,
  [EModalComponents.GROUP_CHAT_ADD_MEMBERS]: ChatCreateGroupAddMembersDialog,
  [EModalComponents.DELETE_CHAT_CONFIRM]: DeleteChatConfirm,
  [EModalComponents.LEAVE_CHAT_CONFIRM]: LeaveChatConfirm,
  [EModalComponents.DELETE_CHAT_MESSAGE_CONFIRM]: DeleteChatMessageConfirm,
  [EModalComponents.UPLOAD_IMAGE_DIALOG]: UploadImageDialog,
  [EModalComponents.DELETE_IMAGE_DIALOG]: DeleteImageDialog,
  [EModalComponents.COMMUNITY_LEAVE_CHANNEL_CONFIRM]: CommunityLeaveChannelConfirm,
  [EModalComponents.REMOVE_COMMUNITY_MEMBER_CONFIRM]: RemoveCommunityMemberConfirm,
  [EModalComponents.GENERIC_CONFIRMATION]: GenericConfirmation,
  [EModalComponents.CHAT_PIN_CONFIRM]: ChatPinMessageConfirmationDialog,
  [EModalComponents.CHAT_UNPIN_CONFIRM]: ChatUnpinConfirmationDialog,
  [EModalComponents.EVENT_CREATE_CHANNEL]: EventCreateChannelDialog,
  [EModalComponents.EVENT_LEAVE_CHANNEL_CONFIRM]: EventLeaveChannelConfirm,
  [EModalComponents.EVENT_DELETE_TEXT_CHANNEL_CONFIRM]: EventDeleteTextChannelConfirm,
  [EModalComponents.EVENT_CREATE_CONFIRM]: EventsCreateConfirm,
  [EModalComponents.CREATE_BROADCAST]: BroadcastCreateDialog,
  [EModalComponents.DELETE_BROADCAST_CONFIRM]: BroadcastDeleteConfirm

  /*
    Use relative imports for components
    Example:
    import { ScheduleMeetingModal } from 'App/components/ScheduleMeetingModal'
  */
} as const

export type TModalProps<T extends EModalComponents> = ComponentProps<
  typeof MODAL_COMPONENTS_MAP[T]
> & {
  onClose?: () => void
}

export type TModalPropsMap = {
  [Key in EModalComponents]: TModalProps<Key>
}

export type TModalPropsUnion = TModalPropsMap[keyof typeof EModalComponents]

const getModalComponent = <T extends EModalComponents>(
  modalType: T,
  modalProps: TModalProps<T> | null
) => {
  const Component = MODAL_COMPONENTS_MAP[modalType]

  return <Component {...(typeof modalProps === 'object' ? modalProps : ([] as any))} />
}

export type TModalRootProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const ModalRootComponent = ({
  modalTitle,
  modalType,
  hideModal,
  modalProps,
  modalWidth,
  getContainer,
  className,
  modalIcon,
  closable = true
}: TModalRootProps) => {
  const { pathname } = useLocation()

  useEffect(() => {
    hideModal()
  }, [pathname, hideModal])

  const handleModalClose = useCallback(() => {
    hideModal()

    if (modalProps?.onClose) {
      modalProps.onClose()
    }
  }, [hideModal, modalProps])

  if (!modalType) {
    return null
  }

  const ModalChildren = getModalComponent(modalType, modalProps)

  return (
    <Modal
      visible={!!modalType}
      onCancel={handleModalClose}
      title={modalTitle}
      footer={null}
      width={modalWidth}
      getContainer={getContainer}
      closable={closable}
      keyboard={closable}
      maskClosable={closable}
      className={className}
      icon={modalIcon}
    >
      {ModalChildren}
    </Modal>
  )
}

const mapStateToProps = (state: State) => ({
  modalProps: state.modal.modalProps,
  modalTitle: state.modal.modalTitle,
  modalIcon: state.modal.modalIcon,
  modalType: state.modal.modalType,
  modalWidth: state.modal.modalWidth,
  getContainer: state.modal.getContainer,
  closable: state.modal.closable,
  className: state.modal.className
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalAction
    },
    dispatch
  )

export const ModalRoot = connect(mapStateToProps, mapDispatchToProps)(ModalRootComponent)
