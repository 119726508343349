import { actionCreator, TError } from 'store'

import * as actionTypes from './broadcast.actionTypes'
import {
  TBroadcastAudioPayload,
  TBroadcastMessageRequestPayload,
  TBroadcastSelectedCategoriesPayload,
  TBroadcastSelectedDepartmentsPayload,
  TBroadcastSelectedOrganizationsPayload,
  TBroadcastSelectedRecipientsPayload,
  TRemoveBroadcastSelectedFilePayload,
  TSelectBroadcastFilesPayload,
  TSetBroadcastSelectedFilesPayload
} from './broadcast.types'

export const broadcastMessageRequest = (payload: TBroadcastMessageRequestPayload) =>
  actionCreator(actionTypes.BROADCAST_MESSAGE_REQUEST, payload)
export const broadcastMessageSuccess = () => actionCreator(actionTypes.BROADCAST_MESSAGE_SUCCESS)
export const broadcastMessageError = (payload: TError) =>
  actionCreator(actionTypes.BROADCAST_MESSAGE_ERROR, payload)

export const selectBroadcastFiles = (payload: TSelectBroadcastFilesPayload) =>
  actionCreator(actionTypes.SELECT_BROADCAST_FILES, payload)

export const setBroadcastSelectedFiles = (payload: TSetBroadcastSelectedFilesPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_SELECTED_FILES, payload)

export const removeBroadcastSelectedFile = (payload: TRemoveBroadcastSelectedFilePayload) =>
  actionCreator(actionTypes.REMOVE_BROADCAST_SELECTED_FILE, payload)

export const setBroadcastRecipients = (payload: TBroadcastSelectedRecipientsPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_SELECTED_RECIPIENTS, payload)

export const setBroadcastAudio = (payload: TBroadcastAudioPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_AUDIO, payload)

export const setBroadcastDepartments = (payload: TBroadcastSelectedDepartmentsPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_SELECTED_DEPARTMENTS, payload)

export const setBroadcastCategories = (payload: TBroadcastSelectedCategoriesPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_SELECTED_CATEGORIES, payload)

export const setBroadcastOrganizations = (payload: TBroadcastSelectedOrganizationsPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_SELECTED_ORGANIZATIONS, payload)

export const setBroadcastAllContacts = (payload: boolean) =>
  actionCreator(actionTypes.SET_BROADCAST_ALL_CONTACTS, payload)

export const setBroadcastOrganizationId = (payload: string | null) =>
  actionCreator(actionTypes.SET_BROADCAST_ORGANIZATION_ID, payload)

export const resetBroadcast = () => actionCreator(actionTypes.RESET_BROADCAST)
