import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { CaseMembersItemActive } from 'App/components'
import { State } from 'redux/rootReducer'
import { showModalAction, getCaseMembersRequest } from 'store'
import { ECaseAccountType } from 'enums'

export type TCaseMembersItemActiveContainerBaseProps = {
  id: string
  who?: ECaseAccountType
}

export type TCaseMembersItemActiveContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  TCaseMembersItemActiveContainerBaseProps

const mapStateToProps = (state: State, { id }: TCaseMembersItemActiveContainerBaseProps) => ({
  member: state.caseMembers.members.active.list[id],
  caseOwnerId: state.caseView.data?.owner.id,
  caseId: state.caseView.data?.id || '',
  caseTitle: state.caseView.data?.title || ''
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      getMembers: getCaseMembersRequest
    },
    dispatch
  )

export const CaseMembersItemActiveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMembersItemActive)
