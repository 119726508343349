import { useLayoutEffect, useMemo, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import cls from 'classnames'

import {
  DropDownMenu,
  NavMenu,
  TNavMenuProps,
  TNavMenuItem,
  NotificationBadge
} from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './TabMenu.module.scss'

type TTabMenuClasses = 'root'

export type TTabMenuProps = {
  menuList: TNavMenuItem[]

  endAdornment?: JSX.Element
  tabVariant?: 'fixed' | 'static'
  classes?: Partial<Record<TTabMenuClasses, string>>
  navMenuProps?: Partial<TNavMenuProps>
}

export const TabMenu = ({
  menuList,
  endAdornment,
  classes,
  navMenuProps,
  tabVariant = 'fixed'
}: TTabMenuProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()
  const { push, location } = useHistory()
  const [title, setTitle] = useState('0')

  const titleList = useMemo(
    () =>
      menuList
        .filter(({ hidden }) => !hidden)
        .map((item) => (
          <div key={item.link} className={styles.title}>
            {item.title}
            {item.notification && <NotificationBadge {...item.notification} />}
          </div>
        )),
    [menuList]
  )
  const tabPosition = navMenuProps?.tabPosition ?? (isDesktop ? 'left' : 'top')

  const handleDropDownChange = useCallback(
    (key: string) => {
      const link = menuList[Number(key)].link

      setTitle(key)
      push(link)
    },
    [menuList, push]
  )

  useLayoutEffect(() => {
    const newTitle = menuList
      .findIndex((item) =>
        item.exact
          ? item.link === location.pathname
          : new RegExp(`^(${item.link}).*`, 'igm').test(location.pathname)
      )
      ?.toString()

    if (newTitle !== title) {
      setTitle(newTitle)
    }
  }, [location.pathname, menuList, title])

  return (
    <div
      className={cls(styles.root, classes?.root, {
        [styles.rootLeft]: tabPosition === 'left',
        [styles.rootTop]: tabPosition === 'top',
        [styles.rootFixed]: tabVariant === 'fixed'
      })}
    >
      {isMobile ? (
        <DropDownMenu list={titleList} title={title} onChange={handleDropDownChange} />
      ) : (
        <NavMenu tabPosition={tabPosition} items={menuList} hasTitle={false} {...navMenuProps} />
      )}
      {endAdornment && <div className={styles.endAdornment}>{endAdornment}</div>}
    </div>
  )
}
