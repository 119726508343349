import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatEditingMessage } from 'App/components'
import { endMessageEditingRequest } from 'store'

export type TChatEditingMessageContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  message: state.chat.chatMessages.actions.message,
  type: state.chat.chatMessages.actions.type
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onCancel: endMessageEditingRequest
    },
    dispatch
  )

export const ChatEditingMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatEditingMessage)
