import { useEffect, useState } from 'react'
import { utcToZonedTime } from 'date-fns-tz'

import { SECOND } from 'globalConstants'
import { formatDate } from 'utils'

export type TClockProps = {
  format?: string
  intervalSecond?: number
  timeZone?: string
}

export const Clock = ({
  format = 'HH:mm',
  intervalSecond = 5.1,
  timeZone = 'UTC'
}: TClockProps) => {
  const [time, setTime] = useState(utcToZonedTime(new Date(), timeZone))

  useEffect(() => {
    const timeId = setInterval(
      () => setTime(utcToZonedTime(new Date(), timeZone)),
      SECOND * intervalSecond
    )

    return () => {
      clearInterval(timeId)
    }
  }, [intervalSecond, timeZone])

  return <>{formatDate(time, format)}</>
}
