import { useCallback, useMemo } from 'react'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components/common/MenuList'
import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'
import { queryClient } from 'queryClient'
import { ReactComponent as AccountPlusOutlineIcon } from 'assets/icons/AccountPlusOutline.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { TScheduleMeetingModalProps } from 'App/components/ScheduleMeetingModal'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import { COMMUNITY_MEMBERS_QUERY_KEY } from '../CommunityMembers/CommunityMembers'

type TMenuItemsProps = {
  userId: string
  userName: string

  toId?: string
  contactId?: string
  status?: ContactStatusEnum
  chatId?: string
}

type TMenuItemKey =
  | 'scheduleMeeting'
  | 'inviteToContacts'
  | 'withdrawInvitation'
  | 'approveInvitation'
  | 'rejectInvitation'
  | 'removeMember'

export type TUseCommunityMemberItemKebabMenuProps = {
  communityId: string
  isBusinessAccount: boolean
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const REMOVE_MEMBER_QUERY_KEY = 'remove_member'

export const useCommunityMemberItemKebabMenu = ({
  communityId,
  isBusinessAccount
}: TUseCommunityMemberItemKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [COMMUNITY_MEMBERS_QUERY_KEY], type: 'active' })
  }, [])

  const {
    withdrawContactInvitation,
    acceptContactInvitation,
    rejectContactInvitation,
    inviteToContacts
  } = useContactInvitation()

  const handleScheduleMeeting = useCallback(
    ({ userName, chatId }: Pick<TScheduleMeetingModalProps, 'chatId' | 'userName'>) =>
      dispatch(
        showModalAction({
          modalTitle: t('modal.scheduleMeeting.title'),
          modalType: EModalComponents.SCHEDULE_MEETING_MODAL,
          modalProps: {
            chatId,
            userName,
            onSuccess: () => dispatch(hideModalAction())
          }
        })
      ),
    [dispatch, t]
  )

  const handleRemoveMember = useCallback(
    (memberId: string) =>
      dispatch(
        showModalAction({
          modalType: EModalComponents.REMOVE_COMMUNITY_MEMBER_CONFIRM,
          modalTitle: t('modal.removeCommunityMemberConfirm.title'),
          modalProps: {
            communityId,
            memberId,
            onSuccess
          }
        })
      ),
    [communityId, dispatch, onSuccess, t]
  )

  const menuItems = useMemo<Record<TMenuItemKey, (props: TMenuItemsProps) => TMenuItemProps>>(
    () => ({
      scheduleMeeting: ({ status, userName, chatId }) => ({
        hidden: status !== ContactStatusEnum.APPROVED,
        content: t('unityHub.networking.member.kebabMenu.scheduleMeeting'),
        icon: <ScheduleIcon />,
        onClick: () => chatId && handleScheduleMeeting({ userName, chatId })
      }),
      inviteToContacts: ({ status, userId }) => ({
        hidden: status === ContactStatusEnum.PENDING || status === ContactStatusEnum.APPROVED,
        content: t('unityHub.networking.member.kebabMenu.inviteToContacts'),
        icon: <AccountPlusOutlineIcon />,
        onClick: () => inviteToContacts({ accountId: userId, onSuccess })
      }),
      withdrawInvitation: ({ status, userId, toId, contactId }) => ({
        hidden: status !== ContactStatusEnum.PENDING || (!!toId && toId !== userId),
        content: t('unityHub.networking.member.kebabMenu.withdrawInvitation'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => contactId && withdrawContactInvitation({ contactId, onSuccess })
      }),
      approveInvitation: ({ status, userId, toId }) => ({
        hidden: status !== ContactStatusEnum.PENDING || (!!toId && toId === userId),
        content: t('unityHub.networking.member.kebabMenu.approveInvitation'),
        icon: <AccountMultiplePlusOutlineIcon />,
        onClick: () => acceptContactInvitation({ accountId: userId, onSuccess })
      }),
      rejectInvitation: ({ userId, status, toId, contactId }) => ({
        hidden: status !== ContactStatusEnum.PENDING || (!!toId && toId === userId),
        content: t('unityHub.networking.member.kebabMenu.rejectInvitation'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => contactId && rejectContactInvitation({ contactId, onSuccess })
      }),
      removeMember: ({ userId }) => ({
        hidden: !isBusinessAccount,
        content: t('communities.members.member.kebabMenu.removeMember'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => handleRemoveMember(userId)
      })
    }),
    [
      acceptContactInvitation,
      handleRemoveMember,
      handleScheduleMeeting,
      inviteToContacts,
      isBusinessAccount,
      onSuccess,
      rejectContactInvitation,
      t,
      withdrawContactInvitation
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.scheduleMeeting(props),
      menuItems.inviteToContacts(props),
      menuItems.withdrawInvitation(props),
      menuItems.approveInvitation(props),
      menuItems.rejectInvitation(props),
      menuItems.removeMember(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
