import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { FileContactItem } from 'App/components'
import {
  changeFileContactPermissionRequest,
  CHANGE_FILE_CONTACT_PERMISSION,
  showModalAction,
  createProcessingBySelector
} from 'store'

export type TFileContactItemContainerOwnProps = {
  id: string
}

export type TFileContactItemContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingBySelector([CHANGE_FILE_CONTACT_PERMISSION])

const mapStateToProps = (state: State, { id }: TFileContactItemContainerOwnProps) => {
  const {
    lastName,
    firstName,
    contactUserId,
    permissions,
    displayUserName,
    accountType,
    sharedInCasesCount
  } = state.file.fileContacts.list[id] || {}

  return {
    fileId: state.file.recordId,
    lastName,
    firstName,
    displayUserName,
    type: {
      id: '',
      name: accountType
    },
    contactUserId,
    permissions,
    sharedInCasesCount,
    processing: processingSelector(id)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeUserPermission: changeFileContactPermissionRequest,
      showModal: showModalAction
    },
    dispatch
  )

export const FileContactItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileContactItem)
