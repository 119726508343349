import { useCallback, useMemo } from 'react'

import { TCommentProps, TMenuItemProps } from 'App/components'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as ShareOutlineIcon } from 'assets/icons/ShareOutline.svg'

export type TMenuItemsProps = Parameters<NonNullable<TCommentProps['getMenuItems']>>['0']

type TUseKebabMenuProps = {
  isAllTab: boolean
  handleReply: (props: TMenuItemsProps) => void

  handleDelete?: (commentId: string) => void
  handleEdit?: (props: TMenuItemsProps) => void
  isReplyItem?: boolean
  isShowcaseOwner?: boolean
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = ({
  isAllTab,
  handleEdit,
  handleDelete,
  handleReply,
  isReplyItem = false,
  isShowcaseOwner = false
}: TUseKebabMenuProps): TUseKebabMenu => {
  const menuItems = useMemo(
    () => ({
      reply: (props: TMenuItemsProps) => ({
        hidden: isAllTab,
        icon: <ShareOutlineIcon />,
        content: 'Reply',
        onClick: () => handleReply(props)
      }),
      edit: (props: TMenuItemsProps) => ({
        hidden: isAllTab || !props.isOwner || isReplyItem,
        icon: <PencilOutlineIcon />,
        content: 'Edit',
        onClick: () => handleEdit && handleEdit(props)
      }),
      delete: (props: TMenuItemsProps) => ({
        hidden: !isShowcaseOwner && !props.isOwner,
        icon: <TrashIcon />,
        content: isReplyItem ? 'Delete reply' : 'Delete comment',
        onClick: () => handleDelete && handleDelete(props.id)
      })
    }),
    [handleReply, isAllTab, isReplyItem, handleEdit, handleDelete, isShowcaseOwner]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.reply(props),
      menuItems.edit(props),
      menuItems.delete(props)
    ],
    [menuItems]
  )

  return { getMenuItems }
}
