import {
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SHOW_BY,
  PAGINATION_DEFAULT_TOTAL
} from 'types'

import { TSettingsAction, TSettingsState } from './settings.types'
import * as actionTypes from './settings.actionTypes'

export const initialSettingsState: TSettingsState = {
  info: {
    email: {
      firstExpiredTokenDate: null,
      first2FAStepFinished: false,
      secondExpiredTokenDate: null
    }
  },
  accountAndNotifications: {
    caseAutoApprove: {
      list: {},
      ids: [],
      filters: {
        search: '',
        page: PAGINATION_DEFAULT_PAGE,
        total: PAGINATION_DEFAULT_TOTAL,
        showBy: PAGINATION_DEFAULT_SHOW_BY,
        categoryId: ''
      }
    },
    calls: {
      rejectCalls: false
    },
    sounds: {
      muteCalls: false,
      muteNotifications: false
    }
  },
  securityAndLogin: {
    defaultAccount: {
      id: ''
    },
    twoFA: {
      enabled: false,
      expiredTokenDate: null
    },
    password: {
      expiredTokenDate: null
    }
  }
}

export const settingsReducer = (
  state: TSettingsState = initialSettingsState,
  action: TSettingsAction
): TSettingsState => {
  switch (action.type) {
    case actionTypes.GET_CASE_AUTO_APPROVE_CONTACTS_SUCCESS: {
      return {
        ...state,
        accountAndNotifications: {
          ...state.accountAndNotifications,
          caseAutoApprove: action.payload
        }
      }
    }

    case actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_SEARCH_ACTION: {
      return {
        ...state,
        accountAndNotifications: {
          ...state.accountAndNotifications,
          caseAutoApprove: {
            ...state.accountAndNotifications.caseAutoApprove,
            filters: {
              ...state.accountAndNotifications.caseAutoApprove.filters,
              search: action.payload.search
            }
          }
        }
      }
    }

    case actionTypes.SET_2FA_SETTINGS_SUCCESS: {
      const { expiredTokenDate } = action.payload

      return {
        ...state,
        securityAndLogin: {
          ...state.securityAndLogin,
          twoFA: {
            ...state.securityAndLogin.twoFA,
            expiredTokenDate
          }
        }
      }
    }

    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      const { expiredTokenDate } = action.payload

      return {
        ...state,
        securityAndLogin: {
          ...state.securityAndLogin,
          password: {
            ...state.securityAndLogin.password,
            expiredTokenDate
          }
        }
      }
    }

    case actionTypes.GET_ACCOUNT_SETTINGS_SUCCESS: {
      const { twoFactorEnabled, defaultAccountId, muteCalls, muteNotifications, rejectCalls } =
        action.payload

      return {
        ...state,
        securityAndLogin: {
          ...state.securityAndLogin,
          twoFA: {
            ...state.securityAndLogin.twoFA,
            enabled: twoFactorEnabled
          },
          defaultAccount: {
            ...state.securityAndLogin.defaultAccount,
            id: defaultAccountId
          }
        },
        accountAndNotifications: {
          ...state.accountAndNotifications,
          calls: {
            rejectCalls
          },
          sounds: {
            muteCalls,
            muteNotifications
          }
        }
      }
    }

    case actionTypes.CASE_AUTO_APPROVE_CONTACTS_CLEAR_ACTION: {
      return {
        ...state,
        accountAndNotifications: {
          ...state.accountAndNotifications,
          caseAutoApprove: initialSettingsState.accountAndNotifications.caseAutoApprove
        }
      }
    }

    case actionTypes.CLEAR_CHANGE_PASSWORD_ACTION: {
      return {
        ...state,
        securityAndLogin: {
          ...state.securityAndLogin,
          password: initialSettingsState.securityAndLogin.password
        }
      }
    }

    case actionTypes.CHANGE_EMAIL_SUCCESS: {
      return {
        ...state,
        info: {
          ...state.info,
          email: {
            ...state.info.email,
            ...action.payload
          }
        }
      }
    }

    case actionTypes.CLEAR_CHANGE_EMAIL_ACTION: {
      return {
        ...state,
        info: {
          ...state.info,
          email: initialSettingsState.info.email
        }
      }
    }

    case actionTypes.CLEAR_2FA_SETTINGS_ACTION: {
      return {
        ...state,
        securityAndLogin: {
          ...state.securityAndLogin,
          twoFA: initialSettingsState.securityAndLogin.twoFA
        }
      }
    }

    case actionTypes.TOGGLE_CALL_OR_SOUND_SUCCESS: {
      const {
        muteCalls = state.accountAndNotifications.sounds.muteCalls,
        muteNotifications = state.accountAndNotifications.sounds.muteNotifications,
        rejectCalls = state.accountAndNotifications.calls.rejectCalls
      } = action.payload

      return {
        ...state,
        accountAndNotifications: {
          ...state.accountAndNotifications,
          calls: { rejectCalls },
          sounds: { muteCalls, muteNotifications }
        }
      }
    }

    case actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_CATEGORY: {
      return {
        ...state,
        accountAndNotifications: {
          ...state.accountAndNotifications,
          caseAutoApprove: {
            ...state.accountAndNotifications.caseAutoApprove,
            filters: {
              ...state.accountAndNotifications.caseAutoApprove.filters,
              categoryId: action.payload.categoryId
            }
          }
        }
      }
    }

    default:
      return state
  }
}
