import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { createOrganizationChatRequest, hideModalAction, showModalAction } from 'store'
import { ButtonChip, TButtonChipProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'

export type TButtonScheduleMeetingProps = {
  userName: string
  disabled?: boolean
  chatId?: string | null
  partnerAccountId?: string
  onCreateChat?: (chatId: string) => void
} & TButtonChipProps

export const ButtonScheduleMeeting: FC<TButtonScheduleMeetingProps> = (props) => {
  const { chatId, userName, disabled, partnerAccountId, onClick, onCreateChat, ...rest } = props

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const showModal = (id: string) => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.scheduleMeeting.title'),
        modalType: EModalComponents.SCHEDULE_MEETING_MODAL,
        modalProps: {
          userName,
          chatId: id,
          onSuccess: () => dispatch(hideModalAction())
        }
      })
    )
  }
  const handleClick = () => {
    if (chatId) {
      showModal(chatId)
    } else if (partnerAccountId) {
      dispatch(
        createOrganizationChatRequest({
          partnerAccountId,
          onSuccess: (id) => {
            if (id) {
              showModal(id)
              if (onCreateChat) {
                onCreateChat(id)
              }
            }
          }
        })
      )
    }
    if (onClick) {
      onClick()
    }
  }

  if (!chatId && !partnerAccountId) {
    return null
  }

  return (
    <ButtonChip disabled={disabled} onClick={handleClick} {...rest}>
      <ScheduleIcon />
    </ButtonChip>
  )
}
