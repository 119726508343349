import { useCallback } from 'react'
import { LongPressDetectEvents, useLongPress } from 'use-long-press'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import {
  EExtendableCardType,
  showExtendableCardAction,
  TShowExtendableCardActionPayload,
  useAppDispatch
} from 'store'
import { useAdaptiveLayout, useDoubleClick } from 'App/hooks'
import { IUploadStorageDTO } from 'interfaces'
import { hasClassName, stopPropagation } from 'utils'
import { ANTD_TABLE_SELECTION_COLUMN_CLASS_NAME } from 'globalConstants'
import { CONTEXT_MENU_ICON_CLASS_NAME } from 'App/components'

import { TUseSelection } from './useSelection'

export type TUseFileMouseEventsProps = Partial<Pick<TUseSelection, 'selectMode' | 'onSelect'>> & {
  file: Pick<IUploadStorageDTO, 'id'>
  onTouch: (e?: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => void

  openHandlerProps?: Partial<TShowExtendableCardActionPayload>
  disableOpen?: boolean
  disableSelect?: boolean
}

export const useFileMouseEvents = ({
  file: { id },
  selectMode,
  onTouch,
  onSelect,
  openHandlerProps = {},
  disableOpen = false,
  disableSelect = false
}: TUseFileMouseEventsProps) => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useAdaptiveLayout()

  const handleSelect = useCallback(
    (
      event?:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.TouchEvent<HTMLElement>
        | CheckboxChangeEvent,
      path?: EventTarget[]
    ) => {
      if (
        disableSelect ||
        !onSelect ||
        (event &&
          hasClassName(event.nativeEvent.composedPath(), ANTD_TABLE_SELECTION_COLUMN_CLASS_NAME)) ||
        (path && hasClassName(path, CONTEXT_MENU_ICON_CLASS_NAME)) ||
        event?.nativeEvent.type === 'touchend'
      ) {
        return
      }

      if (event) {
        stopPropagation(event)

        onSelect(event, id)
      }
    },
    [disableSelect, onSelect, id]
  )

  const handleOpen = useCallback(
    (
      event?:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.TouchEvent<HTMLElement>
        | CheckboxChangeEvent
    ) => {
      if (disableOpen) {
        return
      }

      if (event) {
        stopPropagation(event)
      }

      dispatch(
        showExtendableCardAction({
          ...openHandlerProps,
          type: EExtendableCardType.FILE_VIEWER,
          initialSlideId: id
        })
      )
    },
    [disableOpen, dispatch, openHandlerProps, id]
  )

  const handleTouch = useCallback(
    (event?: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => {
      if (event) {
        stopPropagation(event)
      }

      if (event?.type === 'touchend') {
        return
      }

      onTouch(event)
    },
    [onTouch]
  )

  const hybridClick = useDoubleClick(handleOpen, handleSelect)

  const longPressBind = useLongPress<HTMLDivElement>(!isDesktop ? handleSelect : null, {
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
    onCancel: selectMode ? handleSelect : handleTouch
  })

  return {
    longPressBind,
    onClick: isDesktop ? hybridClick : stopPropagation,
    handleSelect,
    handleOpen
  }
}
