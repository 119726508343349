import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { FileIcon } from 'App/components'
import { FileNameTruncatedText } from 'App/components/File/FileNameTruncatedText'
import { ReactComponent as DeleteRestoreIcon } from 'assets/icons/DeleteRestore.svg'

import { ErrorMessage, Status } from './components'
import styles from './FileUploadWidgetItem.module.scss'
import { useFileUploadWidgetItem } from './useFileUploadWidgetItem'

type TFileUploadWidgetItemClasses = 'root' | 'cancelBtn'

type TFileUploadWidgetItemProps = {
  id: string

  removeOnCancel?: boolean
  classes?: Partial<Record<TFileUploadWidgetItemClasses, string>>
}

export const FileUploadWidgetItem = ({
  removeOnCancel = false,
  classes,
  id
}: TFileUploadWidgetItemProps) => {
  const {
    fromTrashBin,
    showCancel,
    uploadStatus,
    uploadPercentage,
    message,
    errorType,
    fileName,
    extension,
    handleCancel
  } = useFileUploadWidgetItem({ id, removeOnCancel })

  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, classes?.root)}>
      <div className={styles.row}>
        <FileIcon extension={extension} subIcon={fromTrashBin && <DeleteRestoreIcon />} />

        <div className={styles.name}>
          <FileNameTruncatedText extension={extension} fileName={fileName} />
        </div>

        <div className={styles.rowEnd}>
          {showCancel && (
            <button className={styles.cancel} tabIndex={2} type="button" onClick={handleCancel}>
              {t('common.uploadWidget.cancelButton')}
            </button>
          )}
          <Status status={uploadStatus} uploadPercentage={uploadPercentage} />
        </div>
      </div>

      {(message || errorType) && (
        <div className={styles.errors}>
          <ErrorMessage message={message} />
        </div>
      )}
    </div>
  )
}
