import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { getMemberListSelector } from 'store/cases/cases.selectors'
import { createLoadingSelector, GET_CASE_LIST, showModalAction } from 'store'
import { CaseItemWrapper } from 'App/components'

export type TCaseItemWrapperOwnProps = {
  id: string
}

export type TCaseItemWrapperContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_CASE_LIST])

const mapStateToProps = (state: State, ownProps: TCaseItemWrapperOwnProps) => {
  const caseData = state.cases.caseList[ownProps.id] || {}

  return {
    isOwner: caseData.owner?.id === state.global.accountData?.id,
    loading: loadingSelector(state),
    memberList: getMemberListSelector(state, caseData.members),
    caseData
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const CaseItemWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseItemWrapper)
