import cls from 'classnames'

import { EIconSize } from 'enums'
import {
  ECardSize,
  EExtendableCardType,
  showExtendableCardAction,
  TShowExtendableCardSlide,
  useAppDispatch
} from 'store'
import { IconButton } from 'App/components'
import { ReactComponent as FullscreenIcon } from 'assets/icons/Fullscreen.svg'

import styles from './FullScreenCarouselButton.module.scss'

export type TFullScreenCarouselButtonProps = PropsWithClassName<{
  initialSlideId: string
  slides: TShowExtendableCardSlide[]

  showcaseId?: string
  ownerId?: string
}>

export const FullScreenCarouselButton = ({
  slides,
  className,
  initialSlideId,
  ownerId,
  showcaseId
}: TFullScreenCarouselButtonProps) => {
  const dispatch = useAppDispatch()

  const handleClick = () => {
    dispatch(
      showExtendableCardAction({
        slides,
        initialSlideId,
        size: ECardSize.FULLSCREEN,
        type: EExtendableCardType.SHOWCASE_GALLERY,
        options: {
          thumbnailOptions: { showSlideIndex: true },
          showcaseOptions: {
            showcaseId,
            ownerId
          }
        }
      })
    )
  }

  return (
    <IconButton
      onClick={handleClick}
      iconSize={EIconSize.MD}
      iconComponent={<FullscreenIcon />}
      classes={{ root: cls(styles.root, className) }}
    />
  )
}
