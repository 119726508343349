import { IJitsiMeetAPI, TJitsiAvailableDevices } from 'types/jitsi'

export const changeDevice = async (
  jitsiAPI: IJitsiMeetAPI,
  deviceType?: 'output' | 'input',
  deviceLabel?: string,
  deviceId?: string
) => {
  try {
    const isDeviceChangeAvailable = await jitsiAPI.isDeviceChangeAvailable(deviceType)

    if (!(isDeviceChangeAvailable && deviceLabel && deviceId)) {
      return
    }

    if (deviceType === 'input') {
      await jitsiAPI.setAudioInputDevice(deviceLabel, deviceId)
    }

    if (deviceType === 'output') {
      await jitsiAPI.setAudioOutputDevice(deviceLabel, deviceId)
    }
  } catch (error) {
    console.error(error)
  }
}

export const removeEmptyValues = (devices: TJitsiAvailableDevices) => {
  const audioInput = devices.audioInput.filter((item) => Boolean(item.label))
  const audioOutput = devices.audioOutput.filter((item) => Boolean(item.label))
  const videoInput = devices.videoInput.filter((item) => Boolean(item.label))

  return {
    audioInput,
    audioOutput,
    videoInput
  }
}
