import { useTranslation } from 'react-i18next'

import { ReactComponent as BusinessIcon } from 'assets/images/Business.svg'
import { Button } from 'App/components/common/Button'

import styles from './BusinessAccountInfo.module.scss'

export type TBusinessAccountInfoProps = {
  onClick: () => void
}

export const BusinessAccountInfo = ({ onClick }: TBusinessAccountInfoProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.infoWrapper}>
      <BusinessIcon className={styles.infoIcon} />
      <span className={styles.infoTitle}>{t('modal.createBusinessAccount.info.title')}</span>
      <span className={styles.infoSubtitle}>{t('modal.createBusinessAccount.info.subtitle')}</span>
      <ol className={styles.infoList}>
        {t('modal.createBusinessAccount.info.features', { returnObjects: true }).map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ol>
      <Button className={styles.infoButtonNext} onClick={onClick}>
        {t('modal.createBusinessAccount.info.submitButton')}
      </Button>
    </div>
  )
}
