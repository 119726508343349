import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './SettingsItemDescription.module.scss'

export type TSettingsItemDescriptionProps = {
  className?: string
}

export const SettingsItemDescription = ({
  children,
  className = ''
}: PropsWithChildren<TSettingsItemDescriptionProps>) => (
  <div className={cls(styles.root, className)}>{children}</div>
)
