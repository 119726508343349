import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isUploadingSelector, useAppSelector } from 'store'

export const FileUploadingPrompt = () => {
  const isUploading = useAppSelector(isUploadingSelector)

  const { t } = useTranslation()

  useEffect(() => {
    const message = t('common.uploadWidget.interruptFileUploadingPrompt')

    const listener = (e: any) => {
      e.returnValue = message

      return message
    }

    if (isUploading) {
      window.addEventListener('beforeunload', listener)
    }

    return () => {
      window.onbeforeunload = null
      window.removeEventListener('beforeunload', listener)
    }
  }, [isUploading, t])

  return null
}
