import { useTranslation } from 'react-i18next'

import { TAutoPaymentDTO } from 'interfaces'

import styles from './MonthlyTopUpSetUp.module.scss'

export const MonthlyTopUpCardDetails = ({ data }: { data?: TAutoPaymentDTO }) => {
  const { t } = useTranslation()

  return (
    <>
      {data && data?.status !== 'canceled' && (
        <div>
          <h3 className={styles.title}>{t('payments.ballance.monthlyTopUp.cardDetailsTitle')}</h3>
          **** {data.last4}
        </div>
      )}
    </>
  )
}
