import { Col, Row, Skeleton, Space } from 'antd'

import { generateRandomArray } from 'utils'

import styles from './CaseMemberListSkeleton.module.scss'

export type TCaseMemberListSkeletonProps = {
  group?: boolean
}

export const CaseMemberListSkeleton = ({ group }: TCaseMemberListSkeletonProps) => (
  <div className={styles.root}>
    {group && (
      <div className={styles.group}>
        <Skeleton active paragraph={false} />

        <Row justify="space-between">
          <Space>
            <Skeleton.Avatar active />
            <Skeleton.Avatar active />
            <Skeleton.Avatar active />
            <Skeleton.Avatar active />
            <Skeleton.Avatar active />
          </Space>
          <Col>
            <Skeleton.Button active />
          </Col>
        </Row>
      </div>
    )}

    {generateRandomArray().map((item) => (
      <div key={item}>
        <Skeleton active paragraph={false} />
        <Row justify="space-between">
          <Col>
            <Skeleton.Input active style={{ width: 218 }} />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Skeleton.Input active style={{ width: 218 }} />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Skeleton.Input active style={{ width: 218 }} />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
        </Row>
      </div>
    ))}
  </div>
)
