import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { inviteAllOrganizationMembersRequest } from 'store'
import { Button, ConfirmationModal } from 'App/components/common'

type TInviteAllOrganizationMembersDialogProps = {
  organizationId: string
  onSuccess?: () => void
}

export const InviteAllOrganizationMembersDialog = ({
  onSuccess,
  organizationId
}: TInviteAllOrganizationMembersDialogProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const onClick = () => {
    dispatch(inviteAllOrganizationMembersRequest({ onSuccess, organizationId }))
  }

  return (
    <ConfirmationModal
      content={t('modal.inviteAllStaffToContactsConfirm.content')}
      controls={
        <Button onClick={onClick}>{t('modal.inviteAllStaffToContactsConfirm.submitButton')}</Button>
      }
    />
  )
}
