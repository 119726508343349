import { useCallback } from 'react'
import { BusinessAccountRoleIdEnum, BusinessAccountStatusEnum } from '@medentee/enums'

import { UserProfileAdministrationAddAdminDialog } from 'App/components'
import {
  addToBusinessRoleRequest,
  ADD_TO_BUSINESS_ROLE,
  createProcessingSelector,
  TIds,
  useAppDispatch,
  useAppSelector
} from 'store'

export type TUserProfileAdministrationAddAdminDialogContainerProps = {
  maxSelected: number

  excludedIds?: string[]
}
const processingSelector = createProcessingSelector([ADD_TO_BUSINESS_ROLE])
export const UserProfileAdministrationAddAdminDialogContainer = ({
  maxSelected,
  excludedIds
}: TUserProfileAdministrationAddAdminDialogContainerProps) => {
  const dispatch = useAppDispatch()
  const accountRoleId = useAppSelector(
    (state) => state.global.accountData?.role?.id || BusinessAccountRoleIdEnum.SUPERADMIN
  )
  const processing = useAppSelector(processingSelector)

  const addAdmin = useCallback(
    (ids: TIds) => {
      dispatch(
        addToBusinessRoleRequest({
          ids,
          roleId: BusinessAccountRoleIdEnum.ADMIN,
          statuses:
            accountRoleId === BusinessAccountRoleIdEnum.SUPERADMIN
              ? [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
              : [BusinessAccountStatusEnum.APPROVED]
        })
      )
    },
    [accountRoleId, dispatch]
  )

  return (
    <UserProfileAdministrationAddAdminDialog
      addAdmin={addAdmin}
      maxSelected={maxSelected}
      excludedIds={excludedIds}
      processing={processing}
    />
  )
}
