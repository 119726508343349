import { useCallback } from 'react'
import cls from 'classnames'
import { UploadSourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import {
  EModalComponents,
  FileUploadButtonContainer,
  TFileUploadButtonContainerProps
} from 'App/containers'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { ReactComponent as AttachIcon } from 'assets/icons/Attach.svg'
import { showModalAction, useAppDispatch } from 'store'

import styles from './Actions.module.scss'

export type TActionsProps = Pick<
  TFileUploadButtonContainerProps,
  'caseId' | 'chatId' | 'uploadedFrom' | 'maxFileSize'
> & {
  isEmpty?: boolean
  isBroadcast?: boolean
}

export const Actions = ({
  caseId,
  chatId,
  uploadedFrom,
  isEmpty,
  isBroadcast,
  maxFileSize
}: TActionsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const shouldShowAttachFromLinkedCase = uploadedFrom === UploadSourcesEnum.CASE

  const handleAttachFromLinkedCase = useCallback(
    () =>
      caseId &&
      dispatch(
        showModalAction({
          modalTitle: t('modal.attachFile.title_case'),
          modalType: EModalComponents.ATTACH_FILES_FROM_LINKED_CASE,
          modalProps: { caseId }
        })
      ),
    [caseId, dispatch, t]
  )

  return (
    <div className={cls(styles.root, isEmpty && styles.empty)}>
      <FileUploadButtonContainer
        chatId={chatId}
        caseId={caseId}
        removeOnCancel={true}
        uploadedFrom={uploadedFrom}
        isBroadcast={isBroadcast}
        maxFileSize={maxFileSize}
      >
        <Button className={cls(styles.button, styles.upload)} variant="text" icon={<PlusIcon />}>
          {t('modal.attachFile.uploadFromDeviceButton')}
        </Button>
      </FileUploadButtonContainer>

      {shouldShowAttachFromLinkedCase && (
        <Button
          className={styles.button}
          variant="text"
          icon={<AttachIcon />}
          onClick={handleAttachFromLinkedCase}
        >
          {t('modal.attachFile.attachFromLinkedCase')}
        </Button>
      )}
    </div>
  )
}
