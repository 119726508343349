import { useMemo } from 'react'
import cls from 'classnames'
import indexOf from 'lodash/indexOf'
import without from 'lodash/without'
import uniq from 'lodash/uniq'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { stopPropagation } from 'utils'

import { Checkbox } from '../common'
import { useToggle } from '../../hooks'

import styles from './CheckableListItem.module.scss'
import { TFilterNewValue } from './CheckableList'

export type TCheckableListItemClasses = 'item'

export type TCheckableListItemProps<
  Filter extends string | undefined,
  Item extends { [key: string]: any },
  Selected extends string
> = {
  data: Item
  items: Item[]
  value: Selected[]
  valueSet: Set<Selected>
  onChange: (newValue: TFilterNewValue<Filter, Selected>) => void

  valueKey?: keyof Item
  labelKey?: keyof Item
  filterType?: Filter
  classes?: Partial<Record<TCheckableListItemClasses, string>>
  formatLabel?: (label: string) => JSX.Element
}

export const SELECT_ALL_VALUE = 'all'

export const CheckableListItem = <
  Filter extends string | undefined = undefined,
  Item extends { [key: string]: any; items?: Item[] } = Record<string, unknown>,
  Selected extends string = string
>({
  data,
  items,
  classes,
  filterType,
  value,
  valueSet,
  valueKey = 'value',
  labelKey = 'label',
  formatLabel,
  onChange
}: TCheckableListItemProps<Filter, Item, Selected>) => {
  const { value: open, toggle } = useToggle()

  const onClick = (itemValue: Selected, nestedItems?: Item[]) => {
    let newValue: Selected[]

    if (nestedItems?.length) {
      const newValues = nestedItems.map((item) => item[valueKey])

      newValue = isSelectedAll ? without(value, ...newValues) : uniq([...value, ...newValues])
    } else if (itemValue === SELECT_ALL_VALUE) {
      newValue =
        !!value.length && value.length === items.length ? [] : items.map((item) => item[valueKey])
    } else {
      const index = indexOf(value, itemValue)

      newValue = index >= 0 ? without(value, itemValue) : [...value, itemValue]
    }

    const formattedValue = filterType ? { [filterType]: newValue } : newValue

    onChange(formattedValue as TFilterNewValue<Filter, Selected>)
  }

  const isSelectedAll = useMemo(() => {
    if (data[valueKey] === SELECT_ALL_VALUE) {
      return !!value.length && value.length === items.length
    }

    return !!data.items?.length ? data.items.every((item) => valueSet.has(item[valueKey])) : false
  }, [data, valueKey, value, items, valueSet])

  const indeterminate = useMemo(() => {
    if (data[valueKey] === SELECT_ALL_VALUE) {
      return !!value.length && value.length < items.length
    }

    return !isSelectedAll && data.items?.some((item) => valueSet.has(item[valueKey]))
  }, [data, isSelectedAll, items.length, value, valueKey, valueSet])

  const isChecked = value.includes(data[valueKey])
  const disabledCollapse = !data?.items?.length || data?.items?.length === 1

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    stopPropagation(e)
    onClick(data[valueKey], data.items)
  }

  return (
    <div key={data[valueKey]} className={classes?.item}>
      <div className={styles.collapsible}>
        {data.items && (
          <ChevronDownIcon
            className={cls(styles.icon, {
              [styles.open]: open,
              [styles.disabled]: disabledCollapse
            })}
            onClick={disabledCollapse ? undefined : toggle}
          />
        )}
        <Checkbox
          capitalized
          indeterminate={indeterminate}
          label={formatLabel ? formatLabel(data[labelKey]) : data[labelKey]}
          checked={isSelectedAll || isChecked}
          classes={{ root: styles.checkboxWrapper }}
          onChange={handleCheckboxChange}
        />
      </div>
      {open && !!data.items?.length && (
        <div className={styles.itemsWrapper}>
          {data.items.map((item) => (
            <CheckableListItem
              key={item[valueKey]}
              value={value}
              data={item}
              items={items}
              classes={classes}
              labelKey={labelKey}
              valueKey={valueKey}
              valueSet={valueSet}
              filterType={filterType}
              onChange={onChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}
