import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'

import config from 'config'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init<HttpBackendOptions>({
    fallbackLng: 'en',
    debug: config.isDevelopment,
    interpolation: {
      escapeValue: false
    },
    supportedLngs: ['en', 'pt', 'es', 'uk', 'pl'],
    ns: ['translation', 'errors', 'notifications'],
    load: 'currentOnly',
    fallbackNS: 'translation'
  })

export default i18n
