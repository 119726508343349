import { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EChatTypeMap, CHAT_PATH } from 'globalConstants'
import { useAppSelector, getOrganizationBasePathSelector } from 'store'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg'

import styles from './CallOutsideButtonContainer.module.scss'
import { CallChatMessagesIndicator } from './CallChatMessagesIndicator'

export type TCallOutsideButtonContainerProps = {
  isOwner: boolean
  hide: boolean

  chatId?: string | null
  organizationId?: string | null
  isFullScreen: boolean
}

type TProps = {
  path?: string
  label?: string
}

export const CallOutsideButtonContainer = ({
  chatId,
  isOwner,
  organizationId,
  hide = false,
  isFullScreen
}: TCallOutsideButtonContainerProps) => {
  const { location, push } = useHistory()

  const organizationPath = useAppSelector(getOrganizationBasePathSelector(organizationId))
  const caseId = useAppSelector((state) => state.calls.answer.createCall?.caseId)

  const { t } = useTranslation()

  const { label, path } = useMemo<TProps>(() => {
    if (chatId && !caseId) {
      return {
        path: generatePath(CHAT_PATH, {
          chatId,
          chatType: EChatTypeMap.DIALOGUE
        }),
        label: t('chat.call.goToChatButton')
      }
    }

    if (chatId && caseId) {
      return {
        path: `/cases/${caseId}/${isOwner ? 'owner' : 'member'}?chatId=${chatId}`,
        label: t('chat.call.goToCaseButton')
      }
    }

    if (organizationId) {
      return {
        path: organizationPath,
        label: t('chat.call.goToOrganizationButton')
      }
    }

    return {}
  }, [caseId, chatId, isOwner, organizationId, organizationPath, t])

  const handleOutside = useCallback(() => path && push(path), [path, push])

  if (hide || !path || !label || `${location.pathname}${location.search}` === path) {
    return null
  }

  return (
    <div
      className={cls(styles.root, { [styles.fullScreen]: isFullScreen })}
      onClick={handleOutside}
    >
      {chatId && <CallChatMessagesIndicator collapsed={!isFullScreen} chatId={chatId} />} {label}{' '}
      <ArrowRightIcon />
    </div>
  )
}
