import { PlatformInvitationType } from '@medentee/enums'

import { EAvatarSize } from 'enums'
import { getMapComponent } from 'utils'
import { hideModalAction, useAppDispatch, useAppSelector } from 'store'
import { IInvitationsDataByLinkTokenDTO } from 'interfaces'
import { AccountName, Button, ConfirmationModal } from 'App/components'

import { useUserProfileInvitationByLinkRequest } from '../UserProfileInvitationByLink/useUserProfileInvitationByLinkRequest'

import styles from './UserProfileConnectByLinkDialog.module.scss'

export type TUserProfileConnectByLinkDialogProps = Pick<
  IInvitationsDataByLinkTokenDTO,
  'inviteContact' | 'inviteOrganization' | 'token' | 'type'
> & {
  profileId?: string
}

const CONTENT_MAP = new Map<PlatformInvitationType, () => JSX.Element>()
  .set(PlatformInvitationType.CONTACT, () => (
    <p className={styles.content}>
      To proceed with connecting, click on the button below, and we will send a request to this User
      to become contacts with you.
    </p>
  ))
  .set(PlatformInvitationType.STAFF, () => (
    <p className={styles.content}>
      To proceed with connecting, click on the button below, and we will send a request to this
      Organization to join as a Staff.
    </p>
  ))

const BUTTON_CONTENT_MAP = new Map<PlatformInvitationType, () => JSX.Element>()
  .set(PlatformInvitationType.CONTACT, () => <>Invite to contacts</>)
  .set(PlatformInvitationType.STAFF, () => <>Join Organization</>)

export const UserProfileConnectByLinkDialog = ({
  token,
  type,
  inviteContact,
  inviteOrganization,
  profileId
}: TUserProfileConnectByLinkDialogProps) => {
  const dispatch = useAppDispatch()
  const {
    lastName,
    firstName,
    id: userId,
    displayUserName,
    type: accountType
  } = useAppSelector((state) => state.userProfile.viewProfile)
  const { error, isLoading, mutate } = useUserProfileInvitationByLinkRequest({
    token,
    type,
    inviteContact,
    inviteOrganization,
    profileId,
    hideDefaultError: true
  })

  const handleClick = () => {
    mutate(token, {
      onSuccess: () => {
        dispatch(hideModalAction())
      }
    })
  }

  return (
    <ConfirmationModal
      error={error}
      classes={{ controls: styles.controls, content: styles.wrapper }}
      content={
        <>
          <AccountName
            classes={{ root: styles.account }}
            showAvatar={true}
            displayUserName={displayUserName}
            firstName={firstName}
            lastName={lastName}
            userId={userId}
            type={accountType}
            truncate={true}
            size={EAvatarSize.XS}
          />

          {getMapComponent(CONTENT_MAP, type)}
        </>
      }
      controls={
        <Button loading={isLoading} onClick={handleClick}>
          {getMapComponent(BUTTON_CONTENT_MAP, type)}
        </Button>
      }
    />
  )
}
