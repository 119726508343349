import { useCallback, useEffect, useState } from 'react'
import cls from 'classnames'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TabPane, Tabs } from 'App/components'
import { isBusinessAccountSelector, useAppSelector } from 'store'
import { TChatFilesContainerProps, ChatFilesCategories } from 'App/containers'
import { ChannelInfo } from 'App/containers/Organizations/ChannelInfo'
import { CommunityChannelMembers } from 'App/components/Communities/CommunityChannelMembers'
import { EventChannelMembers } from 'App/components/Events/EventChannelMembers'

import { CaseChatMembers } from '../ChatMembers/CaseChatMembers'
import { GroupChatMembers } from '../ChatMembers/GroupChatMembers'

import styles from './ChatFiles.module.scss'

enum EChatFiles {
  MEMBERS = 'MEMBERS',
  MY_FILES = 'MY_FILES',
  SHARED_FILES = 'SHARED_FILES'
}

export type TChatFilesBaseProps = {
  caseId?: string
  entityId?: string
}

type TChatFiles = TChatFilesBaseProps & TChatFilesContainerProps

export const ChatFiles = ({
  interlocutorAccountId,
  accountId,
  getFilesCounts,
  getFilesSharedCounts,
  resetFileSharedList,
  filesReset,
  chatId,
  loading,
  filesTotal,
  filesSharedTotal,
  caseId,
  membersTotal,
  entityId,
  chatType,
  channelManager,
  isChannelManager
}: TChatFiles) => {
  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const isGroupChat = chatType === ChatTypeEnum.GROUP
  const isCommunityChannel =
    chatType === ChatTypeEnum.COMMUNITY_CHANNEL || chatType === ChatTypeEnum.COMMUNITY_NEWS
  const isEventChannel =
    chatType === ChatTypeEnum.EVENT_CHANNEL || chatType === ChatTypeEnum.EVENT_NEWS
  const shouldShowMembers = Boolean(
    caseId || entityId || isGroupChat || isCommunityChannel || isEventChannel
  )

  const [tab, setTab] = useState(shouldShowMembers ? EChatFiles.MEMBERS : EChatFiles.MY_FILES)
  const [tabMembersTotal, setTabMembersTotal] = useState<number | undefined>(0)

  const empty = tab === EChatFiles.MY_FILES ? !filesTotal : !filesSharedTotal

  const handleFetchFileCounts = useCallback(() => {
    getFilesCounts({
      permissionUserIdScope: shouldShowMembers ? undefined : interlocutorAccountId,
      ownerId: accountId,
      chatId
    })

    getFilesSharedCounts({
      permissionUserIdScope: accountId,
      chatId
    })
  }, [
    interlocutorAccountId,
    accountId,
    chatId,
    shouldShowMembers,
    getFilesCounts,
    getFilesSharedCounts
  ])

  useEffect(() => {
    handleFetchFileCounts()

    return () => {
      filesReset()
      resetFileSharedList()
    }
  }, [handleFetchFileCounts, filesReset, resetFileSharedList])

  const handleChangeTab = useCallback(
    (activeKey: string) => {
      filesReset()
      resetFileSharedList()

      if (activeKey !== tab) {
        setTab(activeKey as EChatFiles)
        handleFetchFileCounts()
      }
    },
    [filesReset, handleFetchFileCounts, resetFileSharedList, tab]
  )

  const getMembersComponent = () => {
    if (isGroupChat && chatId) {
      return (
        <GroupChatMembers
          chatId={chatId}
          ownerAccountId={channelManager?.id}
          setTabMembersTotal={setTabMembersTotal}
        />
      )
    }

    if (caseId) {
      return <CaseChatMembers caseId={caseId} />
    }

    if (isCommunityChannel && entityId && chatId) {
      return (
        <CommunityChannelMembers
          channelId={chatId}
          communityId={entityId}
          setTabMembersTotal={setTabMembersTotal}
        />
      )
    }

    if (isEventChannel && entityId && chatId) {
      return (
        <EventChannelMembers
          channelId={chatId}
          eventId={entityId}
          setTabMembersTotal={setTabMembersTotal}
        />
      )
    }

    if (entityId && chatId) {
      return (
        <ChannelInfo
          setTabMembersTotal={setTabMembersTotal}
          channelId={chatId}
          organizationId={entityId}
        />
      )
    }

    return null
  }

  useEffect(() => {
    setTabMembersTotal(membersTotal)
  }, [membersTotal])

  const isNewsChannelMember =
    !isBusinessAccount &&
    !isChannelManager &&
    (chatType === ChatTypeEnum.COMMUNITY_NEWS || chatType === ChatTypeEnum.EVENT_NEWS)
  const sharedWithMeTabLabel = isNewsChannelMember
    ? t('chat.details.tabs.files', { quantity: filesSharedTotal })
    : t('chat.details.tabs.shared', { quantity: filesSharedTotal })

  return (
    <div className={cls(styles.root, { [styles.empty]: empty && !loading })}>
      <Tabs
        activeKey={tab}
        variant="fill"
        onChange={handleChangeTab}
        destroyInactiveTabPane={true}
        className={cls(styles.tabs, {
          [styles.tabsTop]: Boolean(entityId)
        })}
      >
        {shouldShowMembers && (
          <TabPane
            tab={t('chat.details.tabs.members', { number: tabMembersTotal })}
            key={EChatFiles.MEMBERS}
          >
            {getMembersComponent()}
          </TabPane>
        )}
        {!isNewsChannelMember && (
          <TabPane
            tab={t('chat.details.tabs.myFiles', { quantity: filesTotal })}
            key={EChatFiles.MY_FILES}
          >
            <ChatFilesCategories entityId={entityId} variant="file" />
          </TabPane>
        )}
        <TabPane tab={sharedWithMeTabLabel} key={EChatFiles.SHARED_FILES}>
          <ChatFilesCategories entityId={entityId} variant="fileShared" />
        </TabPane>
      </Tabs>
    </div>
  )
}
