import { GET_WHO_AMI_SUCCESS, TAction, TGetWhoAmiSuccess, TGlobalState } from 'store'

import { GET_LOGOUT_SUCCESS } from './logout'
import {
  GET_WHO_AMI_ERROR,
  GET_FOCUS_IN_BROWSER,
  LOST_FOCUS_IN_BROWSER,
  SET_CONNECT_WS_STATUS
} from './store.actions'
import { TSetConnectWebSocketStatusAction } from './store.types'

export const initialGlobalState: TGlobalState = {
  browserFocus: false,
  accountData: null,
  webSocketConnected: false
}

export const globalReducer = (state = initialGlobalState, action: TAction) => {
  switch (action.type) {
    case GET_WHO_AMI_SUCCESS: {
      return {
        ...state,
        accountData: (action as TGetWhoAmiSuccess).payload.accountData
      }
    }

    case GET_WHO_AMI_ERROR: {
      return {
        ...state,
        accountData: null
      }
    }

    case SET_CONNECT_WS_STATUS: {
      return {
        ...state,
        webSocketConnected: (action as TSetConnectWebSocketStatusAction).payload.status
      }
    }

    case GET_FOCUS_IN_BROWSER: {
      return {
        ...state,
        browserFocus: true
      }
    }

    case LOST_FOCUS_IN_BROWSER: {
      return {
        ...state,
        browserFocus: false
      }
    }

    case GET_LOGOUT_SUCCESS:
      return initialGlobalState

    default:
      return state
  }
}
