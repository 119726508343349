import { Trans, useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { hideModalAction, useAppDispatch } from 'store'

import { Button, ConfirmationModal } from '../../../common'
import { cancelAutoPayment } from '../../../../../api'

import styles from './MonthlyTopUpCancelDialog.module.scss'

export const MonthlyTopUpCancelDialog = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: ['cancel_top_up'],
    mutationFn: () => cancelAutoPayment(),
    onSuccess: () => {
      dispatch(hideModalAction())
    }
  })

  return (
    <ConfirmationModal
      content={
        <Trans
          t={t}
          i18nKey="modal.cancelMonthlyTopUpConfirm.content"
          components={{ span: <span className={styles.mark} /> }}
        />
      }
      controls={
        <Button loading={isLoading} onClick={() => mutate()}>
          {t('modal.cancelMonthlyTopUpConfirm.submitButton')}
        </Button>
      }
    />
  )
}
