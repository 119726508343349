import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { ChatMessageList } from 'App/components'
import {
  createLoadingSelector,
  GET_MESSAGES_HISTORY,
  getMessagesHistoryRequest,
  getChatPinRequest,
  resetMessages,
  getSelectedChatRoomSelector
} from 'store'

export type TChatMessageListContainerOwnProps = {
  chatId: string
}

export type TChatMessageListContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  TChatMessageListContainerOwnProps

const loadingSelector = createLoadingSelector([GET_MESSAGES_HISTORY])

const mapStateToProps = (state: State, { chatId }: TChatMessageListContainerOwnProps) => {
  const ids = state.chat.chatMessages.messages.ids
  const selectedChat = getSelectedChatRoomSelector(chatId)(state)

  const loading = loadingSelector(state)
  const noData = !ids.length && !loading

  return {
    ids,
    loading,
    chatHistoryEvent: state.chat.chatMessages.chatHistoryEvent,
    firstMessage: ids.length ? state.chat.chatMessages.messages.list[ids[0]] : null,
    lastMessage: ids.length ? state.chat.chatMessages.messages.list[ids[ids.length - 1]] : null,
    hasBefore: state.chat.chatMessages.hasBefore,
    hasAfter: state.chat.chatMessages.hasAfter,
    browserFocus: state.global.browserFocus,
    noData,
    showDefaultMessage:
      noData &&
      ((!selectedChat?.lastMessageDate && !selectedChat?.unreadCount) ||
        selectedChat?.clearedUnixTs),
    chatType: selectedChat?.type
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMessagesHistory: getMessagesHistoryRequest,
      getPinnedMessages: getChatPinRequest,
      resetMessages
    },
    dispatch
  )

export const ChatMessageListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageList)
