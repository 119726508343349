import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SubscriptionIdsEnum } from '@medentee/enums'
import { FC } from 'react'

import { State } from 'redux/rootReducer'
import { ACTIVATE_SUBSCRIPTIONS, createProcessingBySelector, removeErrorNotification } from 'store'
import { RenewSubscriptionPlanDialog } from 'App/components/Subscriptions/RenewSubscriptionPlanDialog'

type TRenewSubscriptionPlanDialogOwnProps = {
  id: SubscriptionIdsEnum
  onConfirm: () => void
}

export type TRenewSubscriptionPlanDialogContainerProps = TRenewSubscriptionPlanDialogOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingBySelector = createProcessingBySelector([ACTIVATE_SUBSCRIPTIONS])

const mapStateToProps = (state: State, { id }: TRenewSubscriptionPlanDialogOwnProps) => ({
  processing: processingBySelector(id)(state),
  error: state.errors[ACTIVATE_SUBSCRIPTIONS]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { removeErrorNotification: () => removeErrorNotification(ACTIVATE_SUBSCRIPTIONS) },
    dispatch
  )

export const RenewSubscriptionPlanDialogContainer: FC<TRenewSubscriptionPlanDialogOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(RenewSubscriptionPlanDialog)
