import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components/common'
import { setDepartmentMembers } from 'api/organizations'
import { queryClient } from 'queryClient'
import { hideModalAction, useAppDispatch } from 'store'
import { ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY } from 'App/hooks/Organizations/useFetchDepartmentStaff'

export type TRemoveStaffFromDepartmentDialogProps = {
  staffId: string
  departmentId: string
  organizationId: string
}

export const SET_DEPARTMENT_MEMBERS_MUTATION_KEY = 'set-department-members'

export const RemoveStaffFromDepartmentDialog = ({
  staffId,
  departmentId,
  organizationId
}: TRemoveStaffFromDepartmentDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: [SET_DEPARTMENT_MEMBERS_MUTATION_KEY],
    mutationFn: setDepartmentMembers
  })

  const onClick = useCallback(() => {
    mutate(
      {
        id: departmentId,
        organizationId,
        setAccountIds: [],
        unsetAccountIds: [staffId]
      },
      {
        onSuccess: () => {
          dispatch(hideModalAction())
          queryClient.refetchQueries({
            queryKey: [ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY, 0, organizationId, departmentId],
            type: 'active'
          })
        }
      }
    )
  }, [departmentId, dispatch, mutate, organizationId, staffId])

  return (
    <ConfirmationModal
      content={t('modal.removeStaffFromDepartmentConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.removeStaffFromDepartmentConfirm.submitButton')}
        </Button>
      }
    />
  )
}
