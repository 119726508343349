import { useContext, useLayoutEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  useAppSelector,
  createLoadingSelector,
  GET_BROADCASTS,
  openBroadcast,
  getCommunityBasePathSelector,
  RECEIVE_BROADCAST_DELETED
} from 'store'
import { usePrevious } from 'App/hooks'
import { shouldSkipAction } from 'services/skipWSActions'
import { toastDefaultOptions } from 'globalConstants'

import { toast } from '../../ToastContainer'
import { BroadcastContext } from '../BroadcastContext'
import { BroadcastRoomHeader } from '../BroadcastRoomHeader'

import styles from './BroadcastRoomContainer.module.scss'

const loadingSelector = createLoadingSelector([GET_BROADCASTS])

export const BroadcastRoomContainer = () => {
  const dispatch = useDispatch()

  const { broadcastId } = useParams<{ broadcastId: string }>()

  const { setBroadcastRoomContainer } = useContext(BroadcastContext)

  const { replace } = useHistory()

  const currentCommunityId = useAppSelector((state) => state.communities.currentCommunityId)
  const currentEventId = useAppSelector((state) => state.events.currentEventId)
  const ids = useAppSelector((state) => state.broadcasts.data.ids)
  const loading = useAppSelector(loadingSelector)
  const activeBroadcast = useAppSelector((state) =>
    state.broadcasts.activeBroadcast?.id === broadcastId ? state.broadcasts.activeBroadcast : null
  )
  const communityPath = useAppSelector(getCommunityBasePathSelector(currentCommunityId))

  const prevLoading = usePrevious(loading)
  const prevActiveBroadcastId = usePrevious(activeBroadcast?.id)

  const { t } = useTranslation()

  const wasActive = prevActiveBroadcastId === broadcastId

  useLayoutEffect(() => {
    if (broadcastId && !activeBroadcast && !wasActive && !loading) {
      dispatch(openBroadcast({ broadcastId, entityId: currentCommunityId || currentEventId }))
    }
  }, [
    dispatch,
    broadcastId,
    activeBroadcast,
    wasActive,
    loading,
    currentCommunityId,
    currentEventId
  ])

  useLayoutEffect(() => {
    let path = communityPath

    if (currentEventId) {
      path = `/events/${currentEventId}`
    }

    if (broadcastId && !activeBroadcast && prevLoading && !loading && !ids.includes(broadcastId)) {
      replace(path)
      if (!shouldSkipAction({ type: RECEIVE_BROADCAST_DELETED, payload: null })) {
        toast.warn(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    }
  }, [
    broadcastId,
    activeBroadcast,
    wasActive,
    communityPath,
    replace,
    loading,
    ids,
    currentCommunityId,
    currentEventId,
    prevLoading,
    t
  ])

  return (
    <div className={styles.root}>
      {activeBroadcast && (
        <BroadcastRoomHeader
          id={activeBroadcast.id}
          name={activeBroadcast.name}
          type={activeBroadcast.type}
        />
      )}

      <div className={styles.room} ref={setBroadcastRoomContainer} />
    </div>
  )
}
