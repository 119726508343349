import indexOf from 'lodash/indexOf'
import without from 'lodash/without'

import { Checkbox, TCaseHistoryFilter } from 'App/components'

import styles from './CaseHistoryFilterItem.module.scss'

type TCaseHistoryFilterItem = Pick<
  TCaseHistoryFilter<string[]>,
  'setSelectedKeys' | 'selectedKeys'
> & {
  filterValue: string
  filterText: string
}

export const CaseHistoryFilterItem = ({
  filterValue,
  filterText,
  selectedKeys,
  setSelectedKeys
}: TCaseHistoryFilterItem) => {
  const handleCheckboxClick = () => {
    const index = indexOf(selectedKeys, filterValue)
    const newFilterList =
      index >= 0 ? without(selectedKeys, filterValue) : [...selectedKeys, filterValue]
    setSelectedKeys(newFilterList)
  }

  return (
    <Checkbox
      key={filterValue}
      label={filterText}
      checked={selectedKeys.includes(filterValue)}
      classes={{ root: styles.checkbox }}
      onChange={handleCheckboxClick}
    />
  )
}
