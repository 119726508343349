import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { Help } from '../../common'
import { TSubscriptionPlans, useSubscriptionPlans } from '../useSubscriptionPlans'

import { FeatureValue } from './FeatureValue'
import styles from './SubscriptionPlans.module.scss'
import { useSubscriptionPlansControls } from './useSubscriptionPlansControls'

export type TMobilePlansTableProps = {
  data: TSubscriptionPlans
}

export const MobilePlansTable = ({ data }: TMobilePlansTableProps) => {
  const { professionalControls, businessControls } = useSubscriptionPlansControls()

  const subscriptionPlans = useSubscriptionPlans(data)

  const { t } = useTranslation()

  return (
    <table className={styles.table}>
      <thead className={styles.header}>
        <tr className={styles.heading}>
          <th className={styles.professional} colSpan={2}>
            {t('subscriptions.plansTable.professionalAccount')}
          </th>
        </tr>
        <tr>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BASIC')}</strong>
              {t('subscriptions.freePriceLabel')}
            </div>
          </th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.PROFESSIONAL')}</strong>
              {t('subscriptions.plansTable.price', {
                price: data.get(SubscriptionIdsEnum.PROFESSIONAL)?.price
              })}
            </div>
          </th>
        </tr>
        <tr className={styles.controls}>{professionalControls}</tr>
      </thead>
      {subscriptionPlans.map(({ title, features }, index) => (
        <tbody key={index}>
          <tr className={styles.headingRow}>
            <th colSpan={2} className={styles.title}>
              {title}
            </th>
          </tr>
          {features.map(({ tooltip, title: featureTitle, professional }, featureIndex) => {
            const rowClassName = featureIndex % 2 === 0 ? styles.highlighted : undefined

            return (
              <React.Fragment key={featureIndex}>
                <tr className={rowClassName}>
                  <td colSpan={2}>
                    {featureTitle} {tooltip && <Help size="md" content={tooltip} />}
                  </td>
                </tr>
                <tr className={rowClassName}>
                  <td>
                    <FeatureValue value={professional.basic} />
                  </td>
                  <td>
                    <FeatureValue value={professional.professional} />
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      ))}
      <thead className={styles.header}>
        <tr className={styles.heading}>
          <th className={styles.business} colSpan={2}>
            {t('subscriptions.plansTable.businessAccount')}
          </th>
        </tr>
        <tr>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BASIC')}</strong>
              {t('subscriptions.freePriceLabel')}
            </div>
          </th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BUSINESS')}</strong>
              {t('subscriptions.plansTable.price', {
                price: data.get(SubscriptionIdsEnum.BUSINESS)?.price
              })}
            </div>
          </th>
        </tr>
        <tr className={styles.controls}>{businessControls}</tr>
      </thead>
      {subscriptionPlans.map(({ title, features }, index) => (
        <tbody key={index}>
          <tr>
            <th colSpan={2} className={styles.title}>
              {title}
            </th>
          </tr>
          {features.map(({ tooltip, title: featureTitle, business }, featureIndex) => (
            <React.Fragment key={featureIndex}>
              <tr>
                <td colSpan={2}>
                  {featureTitle} {tooltip && <Help content={tooltip} />}
                </td>
              </tr>
              <tr>
                <td>
                  <FeatureValue value={business.basic} />
                </td>
                <td>
                  <FeatureValue value={business.business} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      ))}
    </table>
  )
}
