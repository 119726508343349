import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PlatformInvitationType } from '@medentee/enums'

import { useAppSelector } from 'store'

import { useRefValue } from './useRefValue'

export const useInvitationRedirect = () => {
  const { push, location } = useHistory()
  const isFirstLogin = useAppSelector((state) => state.global.accountData?.isFirstLogin)
  const invitation = useAppSelector((state) => state.global.accountData?.invitation)

  const { getValue: getPathname } = useRefValue(location.pathname)

  useLayoutEffect(() => {
    const pathname = getPathname()
    const nextPath = `/profile/t/${invitation?.token}`

    if (
      isFirstLogin &&
      invitation?.token &&
      nextPath !== pathname &&
      (invitation.type === PlatformInvitationType.STAFF ||
        invitation.type === PlatformInvitationType.CONTACT)
    ) {
      push(nextPath)
    }
  }, [isFirstLogin, invitation, push, getPathname])

  return null
}
