import cls from 'classnames'
import { isSameDay, isValid } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import { formatShortDate, formatDate, timeFormat } from 'utils/formats'

import styles from './ChatTime.module.scss'

// ------------------ Types ------------------
export type TChatTimeProps = {
  time: string
  className?: string
}
// ------------------  END  ------------------

export const ChatTime = ({ className = '', time }: TChatTimeProps) => {
  if (!isValid(time)) {
    return null
  }

  const today = isSameDay(zonedTimeToUtc(new Date(time), 'UTC'), zonedTimeToUtc(new Date(), 'UTC'))

  return (
    <div className={cls({ [styles.time]: true, [className]: !!className })}>
      {!today ? formatShortDate(time) : formatDate(time, timeFormat)}
    </div>
  )
}
