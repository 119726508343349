import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChatRoomHeaderCaseOwnerContainer,
  ChatFieldContainer,
  ChatMessageListContainer,
  ChatQuotingMessageContainer,
  ChatEditingMessageContainer
} from 'App/containers'
import { ChatRoomWrapper, EEmptyListIconSize, EmptyList } from 'App/components'
import { useChatContext } from 'App/context'
import { getChatPinRequest, useAppDispatch, useAppSelector } from 'store'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

import styles from './ChatRoomCase.module.scss'

export type TChatRoomCaseProps = {
  activeChatId?: string | null
  caseView?: string
  owner?: boolean
}

export const CASE_OWNER_FOOTER_WRAPPER_ID = 'case-owner-chat-footer-wrapper'
export const CASE_MEMBER_FOOTER_WRAPPER_ID = 'case-member-chat-footer-wrapper'

export const ChatRoomCase = ({ activeChatId, caseView, owner }: TChatRoomCaseProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const pinnedMessagesCount = useAppSelector((state) => state.chat.chatMessages.messages.ids.length)

  const { showPinnedMessages } = useChatContext()

  const main = useMemo(() => {
    if (!activeChatId) {
      return null
    }

    if (showPinnedMessages && !pinnedMessagesCount) {
      return (
        <EmptyList
          iconSize={EEmptyListIconSize.MD}
          icon={<ForumIcon />}
          text={t('chat.placeholder_pinned')}
        />
      )
    }

    return <ChatMessageListContainer caseView={caseView} chatId={activeChatId} />
  }, [activeChatId, caseView, pinnedMessagesCount, showPinnedMessages, t])

  const footer = useMemo(() => {
    if (!activeChatId) {
      return null
    }

    if (showPinnedMessages) {
      return <div className={styles.pinnedFooter} />
    }

    return (
      <div
        className={styles.footer}
        id={owner ? CASE_OWNER_FOOTER_WRAPPER_ID : CASE_MEMBER_FOOTER_WRAPPER_ID}
      >
        <div className={styles.indicators}>
          <ChatQuotingMessageContainer />
          <ChatEditingMessageContainer />
        </div>
        <ChatFieldContainer />
      </div>
    )
  }, [activeChatId, owner, showPinnedMessages])

  useEffect(() => {
    if (activeChatId) {
      dispatch(getChatPinRequest({ chatId: activeChatId, limit: 1 }))
    }
  }, [activeChatId, dispatch])

  return (
    <ChatRoomWrapper
      activeChatId={activeChatId}
      header={owner && <ChatRoomHeaderCaseOwnerContainer />}
      main={main}
      footer={footer}
      classes={{ headerWrapper: styles.header, main: styles.main }}
    />
  )
}
