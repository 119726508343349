import { useRouteMatch } from 'react-router-dom'

import { TCaseParams } from 'store'
import { ButtonMessageContainer } from 'App/containers'
import { useChatRedirect } from 'App/components/Organizations/hooks'

export type TCaseMembersItemMessageButtonProps = {
  chatId: string

  unreadCount?: number
  disabled?: boolean
  unreadAll2AllChat?: boolean
  partnerAccountId?: string
}

export const CaseMembersItemMessageButton = ({
  chatId,
  unreadCount,
  disabled,
  unreadAll2AllChat,
  partnerAccountId
}: TCaseMembersItemMessageButtonProps) => {
  const {
    params: { id: caseId }
  } = useRouteMatch<TCaseParams>()

  const { redirectToChat } = useChatRedirect()

  return (
    <ButtonMessageContainer
      chatId={chatId}
      unreadCount={unreadCount}
      onClick={() => redirectToChat({ caseId, chatId, partnerAccountId })}
      unreadAll2AllChat={unreadAll2AllChat}
      disabled={disabled}
    />
  )
}
