import { TIds } from 'store'
import { CaseCloudContactPermissionItemContainer } from 'App/containers'
import { SkeletonList } from 'App/components/File'

import { PermissionTitle } from '../PermissionTitle'

import styles from './PermissionList.module.scss'

export type TPermissionListProps = {
  fileId: string
  caseId: string
  activeIds: TIds
  inactiveIds: TIds

  loading?: boolean
  isMember?: boolean
}

export const PermissionList = ({
  activeIds,
  inactiveIds,
  caseId,
  fileId,
  isMember,
  loading
}: TPermissionListProps) => (
  <SkeletonList className={styles.permissionList} loading={loading}>
    {Boolean(activeIds.length) && (
      <>
        <PermissionTitle isMember={isMember} />
        {activeIds.map((id: string) => (
          <CaseCloudContactPermissionItemContainer
            key={id}
            userId={id}
            fileId={fileId}
            caseId={caseId}
          />
        ))}
      </>
    )}

    {!isMember && Boolean(inactiveIds.length) && (
      <>
        <PermissionTitle variant="inactive" />
        {inactiveIds.map((id: string) => (
          <CaseCloudContactPermissionItemContainer
            key={id}
            userId={id}
            fileId={fileId}
            caseId={caseId}
          />
        ))}
      </>
    )}
  </SkeletonList>
)
