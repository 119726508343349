import { useEffect, useLayoutEffect } from 'react'

import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { useQuery } from 'services/query'
import { TChannelVariant } from 'interfaces'
import { getChatRoomById, getCommunityRoomAccounts } from 'api'
import { updateChatRoomAction, useAppDispatch, useAppSelector } from 'store'

type TVariant = Extract<TChannelVariant, 'chat' | 'news'>
export type TUseRequest = {
  variant: TVariant
  communityId: string
  setChannelMembers: (acc: TAccountsSelectOptionData[], prefetched?: boolean) => void

  channelId?: string
  isEdit?: boolean
}

const CHANNEL_KEY_MAP = new Map<TVariant, string>()
  .set('news', 'news-room-accounts')
  .set('chat', 'text-room-accounts')

export const useMembersRequest = ({
  isEdit,
  communityId,
  variant,
  setChannelMembers,
  channelId = ''
}: TUseRequest) => {
  const dispatch = useAppDispatch()
  const organizationId = useAppSelector(
    (state) => state.communities.data.list[communityId]?.organizationId
  )
  const chatRoomsState = useAppSelector((state) => state.chat.chatRooms)

  const {
    data: accounts = [],
    refetch,
    isInitialLoading: isLoading
  } = useQuery({
    queryKey: [CHANNEL_KEY_MAP.get(variant), channelId],
    queryFn: () => getCommunityRoomAccounts({ channelId }),
    select: (data) =>
      data.results.map<TAccountsSelectOptionData>(
        ({ id, displayUserName, firstName, lastName, type }) => ({
          id,
          displayUserName,
          firstName,
          lastName,
          type
        })
      ),
    enabled: false,
    cacheTime: 0
  })

  useQuery({
    queryKey: ['update-channel-room-before-edit'],
    queryFn: () => getChatRoomById(channelId ?? ''),
    enabled: isEdit,
    onSuccess: (data) => {
      if (channelId) {
        dispatch(
          updateChatRoomAction({
            ids: chatRoomsState.ids,
            list: {
              ...chatRoomsState.list,
              [channelId]: data
            }
          })
        )
      }
    }
  })

  useLayoutEffect(() => {
    if (isEdit) {
      refetch()
    }
  }, [isEdit, refetch])

  useEffect(() => {
    if (isEdit && accounts?.length) {
      setChannelMembers(
        accounts.filter((item) => item.id !== organizationId),
        true
      )
    }
  }, [isEdit, organizationId, accounts, setChannelMembers])

  return {
    accounts,
    isLoading
  }
}
