import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import {
  getFileSharedListRequest,
  resetFileSharedListAction,
  createLoadingSelector,
  GET_FILE_SHARED_LIST,
  resetFileSharedFilters
} from 'store'
import { FileSharedListWrapper } from 'App/components/File/FileSharedListWrapper'

export type TFileSharedListWrapperContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_FILE_SHARED_LIST])

const mapStateToProps = (state: State) => ({
  fileIds: state.fileShared.ids,
  pagination: state.fileShared.pagination,
  sorting: state.fileShared.sorting,
  search: state.fileShared.filters.search,
  extensionCategories: state.fileShared.filters.extensionCategories,
  accountId: state.global.accountData?.id,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadFileSharedList: getFileSharedListRequest,
      resetFileSharedList: resetFileSharedListAction,
      resetFilters: resetFileSharedFilters
    },
    dispatch
  )

export const FileSharedListWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileSharedListWrapper)
