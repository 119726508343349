import { Row, Skeleton } from 'antd'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { StepsFooterActions, TStepsFooterActionsProps, Alert, Checkbox } from 'App/components'
import { ReactComponent as AccountMultipleRemoveIcon } from 'assets/icons/AccountMultipleRemove.svg'
import { ReactComponent as CaseLockedIcon } from 'assets/icons/CaseLocked.svg'
import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'
import { ReactComponent as ContactRemoveIcon } from 'assets/icons/ContactRemove.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { TChangeSubscriptionPlanDialogContainerProps } from 'App/containers'

import { Feature } from '../Feature'
import { getAutoPayment } from '../../../../../api'

import styles from './SecondStep.module.scss'

type TSecondStepProps = Required<
  Pick<TStepsFooterActionsProps, 'onSubmit' | 'onClickBack'> &
    Pick<TChangeSubscriptionPlanDialogContainerProps, 'accountType'>
>

export const SecondStep = ({ accountType, onSubmit, onClickBack }: TSecondStepProps) => {
  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: ['auto-payment'],
    queryFn: () => getAutoPayment(),
    enabled: false
  })

  return (
    <Skeleton
      active
      title={false}
      loading={isFetching}
      paragraph={{ rows: 3 }}
      className={styles.skeleton}
    >
      <p className={styles.title}>{t('modal.changeSubscriptionPlan.subtitle')}</p>

      <Alert variant="info" className={styles.alert}>
        <Row gutter={[0, 12]}>
          <Feature
            icon={<AccountMultipleRemoveIcon />}
            description={t('modal.changeSubscriptionPlan.features.createCases')}
          />
          <Feature
            icon={<CaseLockedIcon />}
            description={t('modal.changeSubscriptionPlan.features.lockedCases')}
          />
          <Feature
            icon={<CloudUploadIcon />}
            description={t('modal.changeSubscriptionPlan.features.medCloudCapacity')}
          />
          {accountType?.name === AccountTypeNames.BUSINESS && (
            <>
              <Feature
                icon={<AccountGroupIcon />}
                description={t('modal.changeSubscriptionPlan.features.manageOrganization')}
              />
              <Feature
                icon={<ContactRemoveIcon />}
                description={t('modal.changeSubscriptionPlan.features.adminAccount')}
              />
            </>
          )}
        </Row>
      </Alert>

      {data?.status !== 'canceled' && (
        <Checkbox
          checked
          disabled
          classes={{ root: styles.checkbox }}
          label={t('modal.changeSubscriptionPlan.monthlyTopUpCheckbox')}
        />
      )}

      <StepsFooterActions
        submitLabel={t('modal.changeSubscriptionPlan.submitButton')}
        onSubmit={onSubmit}
        onClickBack={onClickBack}
      />
    </Skeleton>
  )
}
