import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  deleteCaseRequest,
  DELETE_CASE,
  createProcessingSelector,
  useAppSelector,
  hideModalAction
} from 'store'
import { Button, ConfirmationModal } from 'App/components'

type TCaseDeleteDialogProps = {
  caseId: string
}

const processingSelector = createProcessingSelector([DELETE_CASE])

export const CaseDeleteDialog = ({ caseId }: TCaseDeleteDialogProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const loading = useAppSelector(processingSelector)

  const handleDelete = () => {
    dispatch(
      deleteCaseRequest({
        id: caseId,
        onSuccess: () => dispatch(hideModalAction())
      })
    )
  }

  return (
    <ConfirmationModal
      content={t('cases.modal.deleteCaseConfirm.content')}
      // FOR SPECIAL TASK MED-6480
      // content="This case will be deleted completely and permanently without the possibility of recovery.
      // You can store it inactive in archived state instead. Are you sure you want to delete the
      // Case anyway?"
      controls={
        <Button onClick={handleDelete} loading={loading}>
          {t('cases.modal.deleteCaseConfirm.submitButton')}
        </Button>
      }
    />
  )
}
