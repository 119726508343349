import { useMemo } from 'react'
import cls from 'classnames'
import { NotificationListTypeEnum, ShowCaseNotificationTypeEnum } from '@medentee/enums'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IShowcaseNotificationDTO } from 'interfaces'
import { AvatarContainer } from 'App/containers/AvatarContainer'
import { EAvatarSize, ENotificationBadgeColor, ENotificationBadgeSize } from 'enums'
import { useAdaptiveLayout, useRedirectToUserProfile } from 'App/hooks'
import { FormattedText, Image, NotificationBadge, ShowMore, TruncatedText } from 'App/components'
import { ReactComponent as CommentIcon } from 'assets/icons/Comment.svg'
import { ReactComponent as AtIcon } from 'assets/icons/At.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg'
import { formatNotificationDate, getMapComponent, stopPropagation } from 'utils'

import { useReadNotifications } from '../../NotificationsReadAllButton/useReadNotifications'

import styles from './NotificationsShowcasesItem.module.scss'

export type TNotificationsShowcasesItemProps = IShowcaseNotificationDTO & {
  size?: 'sm' | 'md'
  onClick?: () => void
}

const TYPE_ICON_MAP = new Map<IShowcaseNotificationDTO['type'], () => JSX.Element>()
  .set(ShowCaseNotificationTypeEnum.COMMENT, () => <CommentIcon />)
  .set(ShowCaseNotificationTypeEnum.MENTION, () => <AtIcon />)
  .set(ShowCaseNotificationTypeEnum.REPLY, () => <ShareIcon />)

export const NotificationsShowcasesItem = ({
  producer,
  type,
  visited,
  viewed,
  createdAt,
  comment,
  showcase,
  id,
  onClick,
  size = 'sm'
}: TNotificationsShowcasesItemProps) => {
  const { displayUserName, firstName, id: producerId, lastName, type: producerType } = producer
  const isSM = size === 'sm'

  const { isMobile } = useAdaptiveLayout()
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const { push } = useHistory()

  const { mutate } = useReadNotifications()

  const { t } = useTranslation()

  const visitedBadgeCount = visited ? 0 : 1
  const avatarSize = isSM || isMobile ? EAvatarSize.XS : EAvatarSize.MD
  const visibleHeight = isSM ? undefined : Number(styles.visibleHeight)

  const description = useMemo(
    () =>
      type
        ? t(`showcases.type.${type}`, { ns: 'notifications', message: comment.message })
        : comment.message,
    [comment.message, type, t]
  )

  const handleProfileClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e)
    redirectToUserProfile(producerId, producerType.name)()
  }

  const anchorId = comment.relatedCommentId ?? comment.id

  const handleItemClick = () => {
    push(`/showcases/p/${showcase.id}?anchorId=${anchorId}`, {
      slideId: comment.showcaseFile.file.id
    })

    mutate({ types: [NotificationListTypeEnum.SHOWCASE], notificationIds: [id] })

    onClick && onClick()
  }

  const getVisitedBadge = () => (
    <div className={styles.badge}>
      <NotificationBadge
        count={visitedBadgeCount}
        variant="dot"
        color={ENotificationBadgeColor.PRIMARY}
        size={ENotificationBadgeSize.MD}
      />
    </div>
  )

  return (
    <div
      className={cls(styles.root, styles[size], { [styles.new]: !viewed })}
      onClick={handleItemClick}
    >
      <div className={styles.header}>
        <div className={styles.author}>
          {!isMobile && getVisitedBadge()}
          <div className={styles.avatar} onClick={handleProfileClick}>
            <AvatarContainer
              displayUserName={displayUserName}
              firstName={firstName}
              userId={producerId}
              lastName={lastName}
              type={producerType.name}
              size={avatarSize}
              online={false}
            />
            <div className={styles.type}>{getMapComponent(TYPE_ICON_MAP, type)}</div>
          </div>
        </div>
        <div className={styles.authorDetails}>
          <div className={styles.authorName} onClick={handleProfileClick}>
            <TruncatedText text={displayUserName} />
          </div>
          {createdAt && <div className={styles.date}>{formatNotificationDate(createdAt)}</div>}
        </div>
        <Image
          src={comment.showcaseFile.file.thumbnailUrl}
          alt={comment.showcaseFile.file.fileName}
          className={styles.image}
          placeholderClasses={{ root: styles.image }}
        />
      </div>
      <div className={styles.description}>
        {isMobile && getVisitedBadge()}
        <ShowMore
          visibleHeight={visibleHeight}
          text={
            <FormattedText
              text={description}
              mentions={{
                clickable: false,
                accounts: comment.mentions,
                files: showcase.files
              }}
              formatters={['emoji', 'mention']}
            />
          }
          classes={{ root: styles.comment, wrapper: styles.commentWrapper }}
        />
      </div>
    </div>
  )
}
