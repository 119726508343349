import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { CaseMemberStatusesEnum } from '@medentee/enums'
import { useFormState, Field, useForm } from 'react-final-form'
import { useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'

import { CHAT_PATH } from 'globalConstants'
import {
  CREATE_ORGANIZATION_CHAT,
  createLoadingSelector,
  createProcessingSelector,
  GET_CASE_MEMBERS,
  getCaseMembersRequest,
  SCHEDULE_MEETING,
  setModalTitleAction,
  useAppSelector
} from 'store'
import {
  Checkbox,
  useMembersSelect,
  Help,
  StepsFooterActions,
  TStepsFooterActionsProps,
  Alert
} from 'App/components'

import { TAccountsSelectOptionData } from '../../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { TScheduleMeetingModalFormValues } from '../ScheduleMeetingModal'
import { ScheduleMeetingMainFields } from '../ScheduleMeetingMainFields'

import styles from './ScheduleMeetingModalSecondStep.module.scss'

const processingSelector = createProcessingSelector([SCHEDULE_MEETING, CREATE_ORGANIZATION_CHAT])
const loadingMembersSelector = createLoadingSelector([GET_CASE_MEMBERS])

export type TScheduleMeetingModalSecondStepProps = Pick<
  TStepsFooterActionsProps,
  'onSubmit' | 'onClickBack' | 'submitLabel' | 'submitLoading' | 'submitDisabled'
> & {
  isGroupMeeting: boolean
  onToggleMembers: (value: boolean) => void

  alert?: ReactNode
  shouldShowInviteAll?: boolean
  shouldShowMainFields?: boolean
  shouldShowReminder?: boolean
  modalTitle?: string
  caseId?: string
}

export const ScheduleMeetingModalSecondStep: FC<TScheduleMeetingModalSecondStepProps> = (props) => {
  const {
    isGroupMeeting,
    caseId,
    submitDisabled,
    submitLoading,
    alert,
    modalTitle,
    submitLabel,
    onSubmit,
    onClickBack,
    onToggleMembers,
    shouldShowInviteAll = true,
    shouldShowMainFields = true,
    shouldShowReminder = true
  } = props
  const isOnChatPage = !!useRouteMatch(CHAT_PATH)?.isExact

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { change } = useForm()
  const { values, invalid, dirty } = useFormState<TScheduleMeetingModalFormValues>()

  const members = useAppSelector((state) => Object.values(state.caseMembers.members.active.list))
  const loading = useAppSelector(loadingMembersSelector)

  const [search, setSearch] = useState('')

  const accounts = useMemo(
    () =>
      members
        ?.filter((item) => item.status === CaseMemberStatusesEnum.ACTIVE)
        ?.map((item) => item.account) ?? [],
    [members]
  )

  const { open, content } = useMembersSelect({
    loading,
    members: accounts,
    disabled: values.inviteAll,
    selectedMembers: values.inviteAll ? members.map(({ account }) => account) : values.participants,
    search,
    getTitle: (number) => t('modal.scheduleMeeting.detailsStep.selectedMembersPopover', { number }),
    onBack: () => change('participants', values.participants),
    onSubmit: (value: TAccountsSelectOptionData[]) => change('participants', value),
    onSearch: setSearch
  })

  const validateMembers = (value: boolean) =>
    !value && !values.participants.length
      ? t('modal.scheduleMeeting.detailsStep.participantsError')
      : undefined
  const validateParticipants = (value: TAccountsSelectOptionData[], allValues = {}) => {
    const { inviteAll } = allValues as TScheduleMeetingModalFormValues

    return !value.length && !inviteAll
      ? t('modal.scheduleMeeting.detailsStep.participantsError')
      : undefined
  }

  const processing = useAppSelector(processingSelector)

  useEffect(() => {
    dispatch(
      setModalTitleAction({
        modalTitle: open
          ? t('modal.scheduleMeeting.title_group')
          : modalTitle ?? t('modal.scheduleMeeting.title')
      })
    )
  }, [dispatch, modalTitle, open, t])

  useEffect(() => {
    if (isOnChatPage && caseId) {
      dispatch(getCaseMembersRequest({ caseId }))
    }
    // `search` dependency is required
  }, [caseId, dispatch, isOnChatPage, search])

  useEffect(() => {
    onToggleMembers(!open)
  }, [open, onToggleMembers])

  const showInviteAll = isGroupMeeting && !open && shouldShowInviteAll
  const showReminder = !open && shouldShowReminder
  const showMainFields = !open && shouldShowMainFields
  const showAlert = !open && !!alert

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        {showAlert && (
          <Row gutter={[0, 20]}>
            <Col xs={24}>
              <Alert className={styles.alert} variant="info" size="sm">
                {alert}
              </Alert>
            </Col>
          </Row>
        )}
        <Row gutter={[0, 20]}>
          {showMainFields && <ScheduleMeetingMainFields />}
          {isGroupMeeting && (
            <Field name="participants" validate={validateParticipants}>
              {() => content}
            </Field>
          )}
          {showInviteAll && (
            <Col xs={24}>
              <Field name="inviteAll" type="checkbox" validate={validateMembers}>
                {({ input }) => (
                  <Row align="middle" wrap={false}>
                    <Checkbox
                      {...input}
                      classes={{ root: styles.checkbox }}
                      onChange={(e) => {
                        input.onChange(e)
                        change('participants', [])
                      }}
                      label={t('modal.scheduleMeeting.detailsStep.inviteAllCheckbox')}
                    />
                    <Help content={t('modal.scheduleMeeting.detailsStep.inviteAllHelp')} />
                  </Row>
                )}
              </Field>
            </Col>
          )}
          {showReminder && (
            <Col xs={24}>
              <Field name="hasReminder" type="checkbox">
                {({ input }) => (
                  <Checkbox
                    {...input}
                    truncate={false}
                    label={t('modal.scheduleMeeting.detailsStep.emailReminderCheckbox')}
                    classes={{ root: styles.checkbox }}
                  />
                )}
              </Field>
            </Col>
          )}
        </Row>
      </Col>
      {!open && (
        <Col xs={24}>
          <Row justify="center">
            <StepsFooterActions
              submitLoading={processing || submitLoading}
              submitDisabled={submitDisabled ?? (invalid || !dirty)}
              submitLabel={submitLabel ?? t('modal.scheduleMeeting.detailsStep.submitButton')}
              onSubmit={onSubmit}
              onClickBack={onClickBack}
            />
          </Row>
        </Col>
      )}
    </Row>
  )
}
