import { useCallback, useMemo } from 'react'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { toastDefaultOptions } from 'globalConstants'
import { generateJoinToUnityHubChannelLink } from 'utils'
import { TMenuItemProps, UnityHubMarkAsRead } from 'App/components'
import {
  accountIdSelector,
  isAdminAccountSelector,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TChannelVariant } from 'interfaces'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'
import { ReactComponent as PinOutlinedIcon } from 'assets/icons/PinOutlined.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/VolumeUp.svg'
import { ReactComponent as VolumeOffIcon } from 'assets/icons/VolumeOff.svg'
import { toast } from 'App/components/ToastContainer'
import { useMuteTextChannel } from 'App/containers'

import { EModalComponents } from '../../../containers/ModalRoot'

import { useEventChannelHighlight } from './useEventChannelHighlight'

type TUseEventChannelKebabMenuProps = {
  eventId: string
}

type TMenuItemsProps = {
  channelId: string
  variant: Extract<TChannelVariant, 'chat' | 'news'>
  channelName: string

  inviteToken?: string
  isMuted?: boolean
  isPublic?: boolean
  isHighlight?: string | null
  channelManagerId?: string
  onEditSuccess?: () => void
}

export type TUseEventChannelKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useEventChannelKebabMenu = ({
  eventId
}: TUseEventChannelKebabMenuProps): TUseEventChannelKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const isAdmin = useAppSelector(isAdminAccountSelector)
  const eventOrganizerId = useAppSelector((state) => state.events.data.list[eventId]?.organizerId)

  const { handleHighlight } = useEventChannelHighlight(eventId)
  const { handleUnmuteTextChannel, getInnerMenuItems } = useMuteTextChannel()

  const menuItems = useMemo(
    () => ({
      markAsRead: ({ channelId }: TMenuItemsProps) => ({
        content: '',
        render: () => (
          <UnityHubMarkAsRead channelId={channelId} entity="events" entityId={eventId} />
        )
      }),
      mute: ({ channelId, isMuted }: TMenuItemsProps) => ({
        content: isMuted
          ? t('unityHub.channelsList.kebabMenu.unmute')
          : t('unityHub.channelsList.kebabMenu.mute'),
        icon: isMuted ? <VolumeUpIcon /> : <VolumeOffIcon />,
        innerMenuItems: isMuted ? undefined : getInnerMenuItems(channelId),
        onClick: isMuted ? () => handleUnmuteTextChannel(channelId) : undefined
      }),
      edit: ({
        channelId,
        channelName,
        channelManagerId,
        variant,
        isPublic,
        onEditSuccess
      }: TMenuItemsProps) => ({
        hidden: !(isAdmin || channelManagerId === accountId),
        content: t('unityHub.channelsList.kebabMenu.edit'),
        icon: <PencilIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.createChannel.edit.title', { context: variant }),
              modalType: EModalComponents.EVENT_CREATE_CHANNEL,
              modalProps: {
                variant,
                eventId,
                channelName,
                channelId,
                isPublic,
                onEditSuccess
              }
            })
          )
      }),
      leave: ({ channelId, isPublic }: TMenuItemsProps) => ({
        hidden: isPublic || isAdmin,
        content: t('unityHub.channelsList.kebabMenu.leave'),
        icon: <LogoutIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.leaveChannelConfirm.title'),
              modalType: EModalComponents.EVENT_LEAVE_CHANNEL_CONFIRM,
              modalProps: { eventId, channelId }
            })
          )
      }),
      delete: ({ channelId }: TMenuItemsProps) => ({
        hidden: !isAdmin,
        content: t('unityHub.channelsList.kebabMenu.delete'),
        icon: <TrashIcon />,
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteChannelConfirm.title'),
              modalType: EModalComponents.EVENT_DELETE_TEXT_CHANNEL_CONFIRM,
              modalProps: {
                channelId,
                eventId
              }
            })
          )
        }
      }),
      invite: ({ inviteToken, variant, isPublic, channelManagerId }: TMenuItemsProps) => ({
        hidden: isPublic || !(isAdmin || channelManagerId === accountId),
        content: t('unityHub.channelsList.kebabMenu.copyLink'),
        icon: <LinkChainIcon />,
        onClick: () => {
          if (!inviteToken) {
            return
          }

          copy(generateJoinToUnityHubChannelLink('event', variant, eventId, inviteToken))

          toast.success(t('common.toast.linkCopied'), toastDefaultOptions)
        }
      }),
      highlight: ({ isHighlight, channelId }: TMenuItemsProps) => ({
        hidden: eventOrganizerId !== accountId,
        content: isHighlight
          ? t('unityHub.channelsList.kebabMenu.removeFromHighlights')
          : t('unityHub.channelsList.kebabMenu.moveToHighlights'),
        icon: <PinOutlinedIcon />,
        onClick: () => handleHighlight(channelId, isHighlight)
      })
    }),
    [
      eventId,
      getInnerMenuItems,
      handleUnmuteTextChannel,
      isAdmin,
      accountId,
      dispatch,
      eventOrganizerId,
      handleHighlight,
      t
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.markAsRead(props),
      menuItems.mute(props),
      menuItems.edit(props),
      menuItems.leave(props),
      menuItems.highlight(props),
      menuItems.invite(props),
      menuItems.delete(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
