import { useEffect, useRef, useState } from 'react'
import cls from 'classnames'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/CheckCircleOutline.svg'

import { Button, TButtonProps } from '../Button'

import styles from './CopyToClipboard.module.scss'

export type TCopyToClipboardProps = Omit<TButtonProps, 'onClick'> & { getContent: () => string }

export const CopyToClipboard = ({ getContent, className, ...props }: TCopyToClipboardProps) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const [isCopied, setIsCopied] = useState(false)

  const { t } = useTranslation()

  const handleClick = () => {
    copy(getContent())
    setIsCopied(true)
    timerRef.current = setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    },
    []
  )

  return isCopied ? (
    <span className={styles.successfulMessage}>
      {t('common.copyToClipboard.copiedLabel')} <CheckIcon />
    </span>
  ) : (
    <Button
      variant="text"
      size="sm"
      icon={<CopyIcon />}
      className={cls(styles.button, className)}
      {...props}
      onClick={handleClick}
    >
      {t('common.copyToClipboard.copyButton')}
    </Button>
  )
}
