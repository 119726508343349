import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { State } from 'redux/rootReducer'
import {
  getCitiesRequest,
  getCompanySpecializationsRequest,
  getCountriesRequest,
  GET_CITIES,
  GET_COMPANY_SPECIALIZATIONS,
  GET_COUNTRIES
} from 'store/misc'
import { setModalTitleAction } from 'store/modal'
import { createLoadingSelector } from 'store/loading'
import { BusinessAccountCreateDialog } from 'App/components/BusinessAccount/BusinessAccountCreateDialog'
import { getAvailableSubscriptionsRequest, GET_AVAILABLE_SUBSCRIPTIONS } from 'store'

export type TBusinessAccountCreateDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_AVAILABLE_SUBSCRIPTIONS])
const loadingCountriesSelector = createLoadingSelector([GET_COUNTRIES])
const loadingCitiesSelector = createLoadingSelector([GET_CITIES])
const loadingSpecializationsSelector = createLoadingSelector([GET_COMPANY_SPECIALIZATIONS])

const mapStateToProps = (state: State) => ({
  countries: state.misc.countries,
  cities: state.misc.cities,
  companySpecializations: state.misc.companySpecializations,
  ids: state.subscriptions.available.ids,
  loadingAvailableSubscriptions: loadingSelector(state),
  loadingCountries: loadingCountriesSelector(state),
  loadingCities: loadingCitiesSelector(state),
  loadingSpecializations: loadingSpecializationsSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCountries: getCountriesRequest,
      getCities: getCitiesRequest,
      getCompanySpecializations: getCompanySpecializationsRequest,
      setModalTitle: setModalTitleAction,
      getAvailableSubscriptions: getAvailableSubscriptionsRequest
    },
    dispatch
  )

export const BusinessAccountCreateDialogContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAccountCreateDialog)
