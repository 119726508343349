import {
  EventInviteStatusEnum,
  EventTypeEnum,
  EventRepresentativeTypeEnum,
  ChatTypeEnum,
  EventPublicityEnum
} from '@medentee/enums'

import {
  IEventDTO,
  IEventInvitationDTO,
  IEventJoinToChannelLinkDTO,
  IInvitationLinkDTO,
  TChannelVariant,
  TEventAttendee,
  TEventPlacementCommunity,
  TEventPlacementOrganization,
  TEventRepresantatives,
  TProfileLink
} from 'interfaces'
import { ICreateTextChannelDTO } from 'interfaces/api/organizations/textChannel'
import { API, APIData, APIResultsResponse, api } from 'services/api'
import { TAccount } from 'store/chatRooms'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types/pagination'
import { QueryBuilder } from 'utils'

export type TCreateEventPayload = {
  type: EventTypeEnum
  title: string
  startOn: string | Date
  endOn: string | Date
  cityId: string

  location?: string
  headline?: string
  about?: string
  representativeId?: string
  representativeType?: EventRepresentativeTypeEnum
}

export const createEvent = async (payload: TCreateEventPayload) => {
  const { data }: APIData<IEventDTO> = await api.post(API.EVENTS, payload)

  return data
}

export const createEventInviteLink = async (id: string) => {
  const { data }: APIData<IInvitationLinkDTO> = await api.post(API.EVENT_INVITE_BY_LINK(id))

  return data
}

export const getEventInviteLink = async (id: string) => {
  const { data }: APIData<IInvitationLinkDTO> = await api.get(API.EVENT_INVITE_BY_LINK(id))

  return data
}

export const deactivateEventInviteLink = async (id: string) => {
  const { data }: APIData<IInvitationLinkDTO> = await api.delete(API.EVENT_INVITE_BY_LINK(id))

  return data
}

export const inviteEventByEmail = async ({ id, emails }: { id: string; emails: string[] }) => {
  const { data }: APIData<IInvitationLinkDTO> = await api.post(API.EVENT_INVITE_BY_EMAIL(id), {
    emails
  })

  return data
}

export type TEditEventPayload = {
  id: string

  payload:
    | (Partial<Omit<TCreateEventPayload, 'type'>> & {
        interests?: string[]
      })
    | FormData
}

export const editEvent = async ({ id, payload }: TEditEventPayload) => {
  const { data }: APIData<IEventDTO> = await api.patch(
    API.EVENT(id),
    payload,
    payload instanceof FormData
      ? {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      : undefined
  )

  return data
}

export const getEvent = async (id: string) => {
  const { data }: APIData<IEventDTO> = await api.get(API.EVENT(id))

  return data
}

type TGetEventInvitesPayload = {
  id: string
  search: string
  searchBy: string
  page: number
  showBy?: number
  status?: EventInviteStatusEnum
}

export const getEventInvites = async ({
  id,
  status,
  page,
  search,
  searchBy,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetEventInvitesPayload) => {
  const url = new QueryBuilder(API.EVENT_INVITES(id))
    .page(page)
    .showBy(showBy)
    .searchBy(searchBy)
    .searchQuery(search)
    .custom('statuses', status)
    .build()

  const { data }: APIResultsResponse<IEventInvitationDTO[]> = await api.get(url)

  return data
}

type TResendEventInviteByEmailPayload = {
  email: string
  eventId: string
}

export const resendEventInviteByEmail = async ({
  email,
  eventId
}: TResendEventInviteByEmailPayload) =>
  await api.post(API.EVENT_INVITE_BY_EMAIL(eventId), { emails: [email] })

export const getEventRepresentatives = async (id: string) => {
  const { data }: APIData<TEventRepresantatives> = await api.get(API.EVENT_REPRESANTATIVES(id))

  return data
}

export type TEditEventOrganizationSettingsPayload = {
  id: string
  representativeId: string
  publicity: EventPublicityEnum
  representativeType: EventRepresentativeTypeEnum
  placementCommunities: TEventPlacementCommunity[]
  placementOrganizations: TEventPlacementOrganization[]
}

export const editEventOrganizationSettings = async ({
  id,
  publicity,
  representativeId,
  representativeType,
  placementCommunities,
  placementOrganizations
}: TEditEventOrganizationSettingsPayload) =>
  await api.patch(API.EVENT_ORGANIZER_SETTINGS(id), {
    publicity,
    representativeId,
    representativeType,
    placementCommunities,
    placementOrganizations
  })

export type TGetEventsPayload =
  | { organizationId: string; communityId?: never; isOrganizer?: never }
  | { communityId: string; organizationId?: never; isOrganizer?: never }
  | { isOrganizer: true; communityId?: never; organizationId?: never }

export const getEvents = async ({
  organizationId,
  communityId,
  isOrganizer
}: TGetEventsPayload) => {
  const url = new QueryBuilder(API.EVENTS)
    .custom('organizationId', organizationId)
    .custom('communityId', communityId)
    .custom('isOrganizer', isOrganizer)
    .build()

  const { data }: APIResultsResponse<IEventDTO[]> = await api.get(url)

  return data
}

export const getEventChannelMembers = async (channelId: string) => {
  const { data }: APIResultsResponse<TAccount[]> = await api.get(API.CHAT_MEMBERS(channelId))

  return data
}

type TCreateEventChannelPayload = {
  eventId: string
  name: string
  accountIds: string[]
  variant: Extract<TChannelVariant, 'chat' | 'news'>
  isPublic: boolean

  channelManagerId?: string | null
}

export const createEventChannel = async ({
  eventId,
  name,
  channelManagerId,
  accountIds,
  variant,
  isPublic
}: TCreateEventChannelPayload) => {
  const { data }: APIData<ICreateTextChannelDTO> = await api.post(
    API.EVENT_TEXT_CHANNELS(eventId),
    {
      isPublic,
      name,
      channelManagerId,
      accountIds,
      type: variant === 'news' ? ChatTypeEnum.EVENT_NEWS : ChatTypeEnum.EVENT_CHANNEL
    }
  )

  return data
}

type TEditEventChannelPayload = PartialBy<
  TCreateEventChannelPayload,
  'accountIds' | 'channelManagerId' | 'name' | 'variant' | 'isPublic'
> & {
  channelId: string

  isHighlight?: boolean
}

export const editEventChannel = async ({
  eventId,
  channelId,
  name,
  channelManagerId,
  accountIds,
  isHighlight,
  isPublic
}: TEditEventChannelPayload) => {
  const { data }: APIData = await api.patch(API.EVENT_TEXT_CHANNEL(eventId, channelId), {
    name,
    channelManagerId,
    accountIds,
    isHighlight,
    isPublic
  })

  return data
}

export type TGetEventAttendeesPayload = {
  eventId: string

  search?: string
  searchBy?: string
  page?: number
  showBy?: number | string
  chatId?: string
  isOnline?: boolean
  isFavorite?: boolean
  isContact?: boolean
  isOpenForNetworking?: boolean
  interestIds?: string[]
  markChatIds?: string[]
  markVoiceRoomIds?: string[]
}

type TEventDeleteChannelPayload = {
  eventId: string
  channelId: string
}

export const eventDeleteChannel = async ({ eventId, channelId }: TEventDeleteChannelPayload) => {
  const response = await api.delete(API.EVENT_TEXT_CHANNEL(eventId, channelId))

  return response
}

type TEventLeaveChannelPayload = {
  eventId: string
  channelId: string
}

export const eventLeaveChannel = async ({ eventId, channelId }: TEventLeaveChannelPayload) =>
  await api.delete(API.EVENT_TEXT_CHANNEL_LEAVE(eventId, channelId))

export const getEventAttendees = async ({
  eventId,
  search,
  searchBy,
  page,
  chatId,
  interestIds,
  isOnline,
  isContact,
  isFavorite,
  isOpenForNetworking,
  markChatIds,
  markVoiceRoomIds,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetEventAttendeesPayload) => {
  const url = new QueryBuilder(API.EVENT_ATTENDEES(eventId))
    .searchQuery(search)
    .page(page)
    .showBy(showBy)
    .searchBy(searchBy)
    .multiSearch('interestIds', interestIds)
    .multiSearch('markChatIds', markChatIds)
    .multiSearch('markVoiceRoomIds', markVoiceRoomIds)
    .custom('chatId', chatId)
    .custom('isOnline', isOnline)
    .custom('isContact', isContact)
    .custom('isFavorite', isFavorite)
    .custom('isOpenForNetworking', isOpenForNetworking)
    .build()

  const { data }: APIResultsResponse<TEventAttendee[]> = await api.get(url)

  return data
}

type TUpdatePersonalEventProfilePayload = {
  eventId: string
  payload:
    | {
        openForNetworking: boolean
        company?: string
        position?: string
        about?: string
        interestIds?: string[]
      }
    | FormData
}

export const updatePersonalEventProfile = async ({
  eventId,
  payload
}: TUpdatePersonalEventProfilePayload) => {
  const { data }: APIData<TEventAttendee> = await api.patch(
    API.EVENT_PERSONAL_PROFILE(eventId),
    payload,
    payload instanceof FormData
      ? {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      : undefined
  )

  return data
}

export type TCreateEventLinkPayload = {
  eventId: string
  id: string
  url: string
}

export const createEventLink = async ({ eventId, url }: TCreateEventLinkPayload) => {
  const { data }: APIData<TProfileLink> = await api.post(API.EVENT_LINKS(eventId), { url })

  return data
}

export type TUpdateEventLinkPayload = {
  eventId: string
  id: string
  url: string
}

export const updateEventLink = async ({ eventId, url, id }: TUpdateEventLinkPayload) =>
  await api.patch(API.EVENT_LINK(eventId, id), { url })

export type TDeleteEventLinkPayload = {
  eventId: string
  id: string
}

export const deleteEventLink = async ({ eventId, id }: TDeleteEventLinkPayload) =>
  await api.delete(API.EVENT_LINK(eventId, id))

export const deleteEvent = async (eventId: string) => await api.delete(API.EVENT(eventId))

export const getPersonalEventProfile = async (eventId: string) => {
  const { data }: APIData<TEventAttendee> = await api.get(API.EVENT_PERSONAL_PROFILE(eventId))

  return data
}

type TGetAttendeeEventProfilePayload = {
  eventId: string
  attendeeId: string
}

export const getAttendeeEventProfile = async ({
  eventId,
  attendeeId
}: TGetAttendeeEventProfilePayload) => {
  const { data }: APIData<TEventAttendee> = await api.get(
    API.EVENT_ATTENDEE_PROFILE(eventId, attendeeId)
  )

  return data
}

type TDeleteAttendeeEventProfilePayload = {
  eventId: string
  attendeeId: string
}

export const deleteAttendeeEventProfile = async ({
  eventId,
  attendeeId
}: TDeleteAttendeeEventProfilePayload) => await api.delete(API.EVENT_ATTENDEE(eventId, attendeeId))

type TToggleEventAttendeeFavoritePayload = {
  eventId: string
  attendeeId: string
  isFavorite: boolean
}

export const toggleEventAttendeeFavorite = async ({
  eventId,
  attendeeId,
  isFavorite
}: TToggleEventAttendeeFavoritePayload) => {
  const { data }: APIData<IEventDTO> = await api.post(
    API.EVENT_ATTENDEE_FAVORITE_TOGGLE(eventId, attendeeId),
    { favorite: isFavorite }
  )

  return data
}

export const joinEvent = async (token: string) => {
  const { data } = await api.post(API.EVENT_JOIN(token))

  return data
}

type TEventTokenPayload = {
  eventId: string
  token: string
}

export const getEventJoinToChannelTokenInfo = async ({ eventId, token }: TEventTokenPayload) => {
  const { data }: APIData<IEventJoinToChannelLinkDTO> = await api.get(
    API.EVENT_CHANNEL_JOIN(eventId, token)
  )

  return data
}

export const joinToEventChannel = async ({ eventId, token }: TEventTokenPayload) => {
  const { data }: APIData<IEventJoinToChannelLinkDTO> = await api.post(
    API.EVENT_CHANNEL_JOIN(eventId, token)
  )

  return data
}

export const leaveEvent = async (eventId: string) => await api.delete(API.LEAVE_EVENT(eventId))
