import { memo, useCallback, useMemo, useEffect } from 'react'

import { getSortDirection } from 'utils'
import { createSlidesEntity, TSlideEntity } from 'store'
import { ESorting } from 'enums'
import { ICasesCloudPermission, IFilesSharedEntityOwner } from 'interfaces'
import { Pagination as TPagination, PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { FilePagination, useFilesView, useSelectedFilesSizeCalculation } from 'App/components'
import { TFileSharedListContainerProps } from 'App/containers'

import { useSelection } from '../../File/common'

import { TableView } from './TableView'
import { FileGridView } from './FileGridView'
import { useKebabMenu } from './useKebabMenu'
import styles from './FileSharedList.module.scss'

/** Table have own Props */
export type TFileSharedTableProps = {
  key: string
  fileName: string
  fileSize: string
  fileId: string
  permissions: ICasesCloudPermission[]
  owner: IFilesSharedEntityOwner

  sharedAt: string | Date | null
  mimeType: string
  extension: string
  thumbnailUrl: string | null
  slidesEntity: TSlideEntity

  isUnopenable?: boolean
}

const FileSharedListView = ({
  fileIds,
  fileList,
  loading,
  fileTotal = 0,
  pagination,
  sorting,
  videoStreamingList,
  accountId,
  getFilesShared
}: TFileSharedListContainerProps) => {
  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(fileIds)
  const [view, setView] = useFilesView('med-cloud-shared-with-me')
  const selectedFileSize = useSelectedFilesSizeCalculation(selectedIds, fileList)

  useEffect(() => {
    setSelectedIds([])
    // `fileIds` property is required
  }, [setSelectedIds, fileIds])

  const handleGetSharedFiles = useCallback(
    (name?: string, direction?: ESorting) => {
      getFilesShared({
        sorting: name && direction ? { name, direction } : sorting,
        pagination,
        permissionUserIdScope: accountId
      })
    },
    [getFilesShared, sorting, pagination, accountId]
  )

  const onTableSorting = useCallback(
    (key: string) => {
      const sortDirection = getSortDirection(key, sorting?.name, sorting?.direction)

      handleGetSharedFiles(key, sortDirection)
    },
    [sorting?.name, sorting?.direction, handleGetSharedFiles]
  )

  const onPaginationChange = ({ current, showBy, total }: TPagination) => {
    getFilesShared({
      filters: {
        page: current
      },
      pagination: {
        current,
        showBy: showBy || PAGINATION_DEFAULT_SHOW_BY,
        total
      },
      sorting,
      permissionUserIdScope: accountId
    })
  }

  const dataSource: TFileSharedTableProps[] = useMemo(
    () =>
      fileIds.map((id) => {
        const {
          fileName,
          owner,
          p2pPermissions: permissions,
          sharedAt = '',
          fileSize,
          extension,
          mimeType,
          thumbnailUrl
        } = fileList[id] ?? {}

        return {
          key: id,
          fileName,
          owner,
          fileSize,
          permissions,
          sharedAt,
          fileId: id,
          extension,
          mimeType,
          thumbnailUrl,
          isUnopenable: videoStreamingList[id]?.videoMetadata?.quality === null,
          slidesEntity: createSlidesEntity(fileIds, fileList)
        }
      }),
    [fileIds, fileList, videoStreamingList]
  )

  const { getMenuItems } = useKebabMenu({
    discardPermissionCallback: handleGetSharedFiles,
    setSelectedIds
  })

  return (
    <div className={styles.fileList}>
      {view === 'list' ? (
        <TableView
          selectedIds={selectedIds}
          dataSource={dataSource}
          view={view}
          loading={loading}
          sorting={sorting}
          selectMode={selectMode}
          filesSize={selectedFileSize}
          setSelectedIds={setSelectedIds}
          onViewChange={setView}
          onSorting={onTableSorting}
          onSelect={onSelect}
          getMenuItems={getMenuItems}
        />
      ) : (
        <FileGridView
          view={view}
          dataSource={dataSource}
          sorting={sorting}
          fileIds={fileIds}
          selectedIds={selectedIds}
          selectMode={selectMode}
          loading={loading}
          filesSize={selectedFileSize}
          onViewChange={setView}
          onSorting={handleGetSharedFiles}
          setSelectedIds={setSelectedIds}
          onSelect={onSelect}
          getMenuItems={getMenuItems}
        />
      )}

      <FilePagination
        current={pagination.current}
        total={fileTotal}
        showBy={pagination.showBy}
        onPaginationChange={onPaginationChange}
      />
    </div>
  )
}

export const FileSharedList = memo(FileSharedListView)
