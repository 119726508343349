import { useCallback, useEffect } from 'react'
import cls from 'classnames'
import { Row, Col } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'

import { hideModalAction, showModalAction, TIds, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers'
import {
  Popover,
  IconLabel,
  Checkbox,
  HeadContextMenu,
  THeadContextMenuProps
} from 'App/components'
import { useAdaptiveLayout, useDialog } from 'App/hooks'
import { ReactComponent as SortReverseIcon } from 'assets/icons/SortReverse.svg'

import { FileViewToggle, TFileViewToggleProps } from '../FileViewToggle'
import {
  FilesTilesViewSortingDialog,
  TFilesTilesViewSortingDialogProps
} from '../FilesTilesViewSortingDialog'

import styles from './FileTilesViewHeader.module.scss'

export type TFileTilesViewHeaderProps = Pick<
  TFilesTilesViewSortingDialogProps,
  'onSorting' | 'sorting' | 'sortingList'
> &
  Pick<TFileViewToggleProps, 'variant'> &
  Pick<THeadContextMenuProps, 'filesSize' | 'menuItems'> &
  PropsWithClassName<{
    fileIds: TIds
    selectedIds: TIds
    shouldShowHeadContextmenu: boolean
    onViewChange: TFileViewToggleProps['onChange']
    setSelectedIds: (ids: TIds) => void
  }>

export const FileTilesViewHeader = ({
  variant,
  onSorting,
  onViewChange,
  setSelectedIds,
  menuItems,
  className,
  sorting,
  sortingList,
  fileIds,
  selectedIds,
  filesSize,
  shouldShowHeadContextmenu
}: TFileTilesViewHeaderProps) => {
  const { open, setOpen, close } = useDialog()

  const { isMobile } = useAdaptiveLayout()

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const showModal = useAppSelector(
    (state) => state.modal.modalType === EModalComponents.FILES_TILE_VIEW_SORTING_DIALOG
  )

  const handleHideModal = useCallback(() => dispatch(hideModalAction()), [dispatch])

  const handelSorting = useCallback<typeof onSorting>(
    (key, direction) => {
      onSorting(key, direction)
      isMobile ? handleHideModal() : close()
    },
    [close, handleHideModal, onSorting, isMobile]
  )

  const handleShowModal = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.filesSortBy.title'),
        modalType: EModalComponents.FILES_TILE_VIEW_SORTING_DIALOG,
        modalProps: {
          sortingList,
          sorting,
          onSorting: handelSorting
        }
      })
    )
  }, [dispatch, handelSorting, sorting, sortingList, t])

  const handelSelectAll = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => {
      setSelectedIds(checked ? fileIds : [])
    },
    [fileIds, setSelectedIds]
  )

  useEffect(() => {
    if (isMobile && open) {
      close()
    }

    if (!isMobile && !open && showModal) {
      handleHideModal()
    }
  }, [close, handleHideModal, isMobile, open, showModal])

  return (
    <div className={cls(styles.root, className)}>
      <Row gutter={[16, 0]} align="middle">
        <Col flex="none">
          <Checkbox
            checked={!!selectedIds.length && selectedIds.length === fileIds.length}
            indeterminate={!!selectedIds.length && selectedIds.length < fileIds.length}
            onChange={handelSelectAll}
          />
        </Col>
        <Col flex="auto">
          <HeadContextMenu
            menuItems={menuItems}
            visible={shouldShowHeadContextmenu}
            total={selectedIds.length}
            filesSize={filesSize}
          >
            {isMobile ? (
              <IconLabel
                classes={{ root: styles.sort, icon: styles.sortIcon }}
                icon={<SortReverseIcon />}
                label={t('modal.filesSortBy.title')}
                onClick={handleShowModal}
              />
            ) : (
              <Popover
                content={
                  <FilesTilesViewSortingDialog
                    sortingList={sortingList}
                    sorting={sorting}
                    onSorting={handelSorting}
                  />
                }
                visible={open}
                onVisibleChange={setOpen}
                placement="bottomLeft"
                trigger="click"
                overlayClassName={styles.popover}
                arrow={false}
              >
                <IconLabel
                  classes={{ root: styles.sort, icon: styles.sortIcon }}
                  icon={<SortReverseIcon />}
                  label={t('modal.filesSortBy.title')}
                />
              </Popover>
            )}
          </HeadContextMenu>
        </Col>
      </Row>

      <FileViewToggle variant={variant} onChange={onViewChange} />
    </div>
  )
}
