import { useHistory } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'

import { useAdaptiveLayout } from 'App/hooks'
import {
  UnityHubChannelIcon,
  UnityHubLayout,
  TUnityHubChannelIconProps
} from 'App/components/UnityHub'
import { DOWNLOAD_MOBILE_APP_PAGE_URL } from 'globalConstants'
import { ReactComponent as EventsIcon } from 'assets/icons/Events.svg'
import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'
import { EEmptyListIconSize, EmptyList } from 'App/components/common/EmptyList'
import { Button } from 'App/components/common/Button'

import styles from './EventsEmptyState.module.scss'
import eventsPlaceholderScreenshot from './screenshot.jpg'
import eventsPlaceholderScreenshotX2 from './screenshot@2.jpg'

type TFeature = Extract<TUnityHubChannelIconProps['variant'], 'members' | 'news' | 'broadcast'>

const FEATURES: TFeature[] = ['members', 'news', 'broadcast']

export const EventsEmptyState = () => {
  const { isDesktop, isMobile } = useAdaptiveLayout()

  const { push } = useHistory()

  const { t } = useTranslation()

  const handleOnCreate = () => {
    push('/events/create')
  }

  return (
    <>
      {!isDesktop && (
        <div className={styles.alert}>
          <div className={styles.alertIconWrapper}>
            <EventsIcon className={styles.alertIcon} />
          </div>

          <p className={styles.alertDescription}>
            <Trans
              t={t}
              i18nKey="events.emptyPage.alert"
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a key={0} target="_blank" rel="noreferrer" href={DOWNLOAD_MOBILE_APP_PAGE_URL} />
              ]}
            />
          </p>
        </div>
      )}

      <UnityHubLayout>
        <div className={styles.root}>
          <div className={styles.content}>
            <div className={styles.start}>
              <h2 className={styles.title}>{t('events.emptyPage.title')}</h2>

              <p className={styles.description}>{t('events.emptyPage.description')}</p>

              {isDesktop && (
                <div className={styles.screenshot}>
                  <img
                    src={eventsPlaceholderScreenshot}
                    srcSet={`${eventsPlaceholderScreenshot} 1x, ${eventsPlaceholderScreenshotX2} 2x`}
                    width="512"
                    height="530"
                    className={styles.screenshotImg}
                    alt="screenshot"
                  />
                </div>
              )}
            </div>

            <div className={styles.end}>
              <div className={styles.features}>
                <h2 className={styles.featuresTitle}>
                  {!isMobile && (
                    <div className={styles.featuresIconWrapper}>
                      <CloudUploadIcon className={styles.featuresIcon} />
                    </div>
                  )}
                  {t('events.emptyPage.features.title')}
                </h2>

                <div className={styles.featuresList}>
                  {FEATURES.map((feature, index) => (
                    <div key={index} className={styles.feature}>
                      <UnityHubChannelIcon gutter={false} variant={feature} />

                      <div>
                        <h3 className={styles.featureTitle}>
                          {t(`events.emptyPage.features.${feature}.title`)}
                        </h3>
                        <div className={styles.featureDescription}>
                          {t(`events.emptyPage.features.${feature}.description`)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {isDesktop && (
                <EmptyList
                  text={
                    <Trans
                      t={t}
                      i18nKey="events.emptyPage.placeholder"
                      components={{ br: <br /> }}
                    />
                  }
                  icon={<EventsIcon />}
                  iconSize={EEmptyListIconSize.SM}
                >
                  <Button onClick={handleOnCreate}>{t('events.emptyPage.createButton')}</Button>
                </EmptyList>
              )}
            </div>
          </div>
        </div>
      </UnityHubLayout>
    </>
  )
}
