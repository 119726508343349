import { ReactNode, memo } from 'react'

import {
  UnityHubRoomNavLink,
  TUnityHubRoomNavLinkProps
} from 'App/components/UnityHub/UnityHubRoomNavLink'

import { Skeleton } from './Skeleton'
import styles from './UnityHubRoomsNavLayout.module.scss'

export type TUnityHubRoomsNavLayoutControl = Omit<TUnityHubRoomNavLinkProps, 'mentionCount'> & {
  key: string

  hidden?: boolean
}

export type TUnityHubRoomsNavLayoutItem = {
  key: string
  item: ReactNode

  loading?: boolean
  hidden?: boolean
}

export type TUnityHubRoomsNavLayoutProps = {
  items: TUnityHubRoomsNavLayoutItem[]

  addonBefore?: ReactNode
  controls?: TUnityHubRoomsNavLayoutControl[]
}

const UnityHubRoomsNavLayoutView = ({
  controls,
  items,
  addonBefore
}: TUnityHubRoomsNavLayoutProps) => (
  <>
    {addonBefore && <div className={styles.addonBefore}>{addonBefore}</div>}

    <div className={styles.root}>
      <div className={styles.start}>
        {items
          .filter(({ hidden }) => !hidden)
          .map(({ key, item, loading }) => (
            <div key={key} className={styles.item}>
              {loading ? <Skeleton /> : item}
            </div>
          ))}
      </div>

      {controls && (
        <div className={styles.end}>
          {controls
            ?.filter(({ hidden }) => !hidden)
            .map(({ key, to, icon, name, count, isPublic, isMuted, menuItems, onClick }) => (
              <div key={key} className={styles.item}>
                <UnityHubRoomNavLink
                  to={to}
                  icon={icon}
                  name={name}
                  count={count}
                  isMuted={isMuted}
                  isPublic={isPublic}
                  menuItems={menuItems}
                  onClick={onClick}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  </>
)

export const UnityHubRoomsNavLayout = memo(UnityHubRoomsNavLayoutView)
