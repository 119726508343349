import { useCallback } from 'react'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  createProcessingBySelector,
  deleteBusinessAdminInviteRequest,
  GET_ORGANIZATION_REGISTERED_USERS,
  REMOVE_BUSINESS_ADMIN_INVITE,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EBusinessAdminConfirmFrom, EBusinessAdminDeleteAction } from 'enums'
import { InvitationItemSent } from 'App/components'
import { EModalComponents } from 'App/containers'

export type TOrganizationsInvitationsSentItemProps = {
  id: string
}

export const OrganizationsInvitationsSentItem = ({
  id
}: TOrganizationsInvitationsSentItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const list = useAppSelector(
    (state) => state.organizations.invitations.registeredUsers.list,
    isEqual
  )
  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATION_REGISTERED_USERS]))
  const removeProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_BUSINESS_ADMIN_INVITE])(id)
  )

  const handleWithdraw = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id,
          type: EBusinessAdminDeleteAction.WITHDRAW,
          from: EBusinessAdminConfirmFrom.STAFF_SENT,
          direction: BusinessAccountDirectionEnum.INCOMING
        }
      })
    )
  }, [dispatch, id, t])

  const handleRemove = useCallback(() => {
    dispatch(
      deleteBusinessAdminInviteRequest({
        id,
        from: EBusinessAdminConfirmFrom.STAFF_SENT,
        onlyHidden: true,
        direction: BusinessAccountDirectionEnum.INCOMING,
        processingId: id
      })
    )
  }, [dispatch, id])

  const { businessAccount, createdAt, status } = list[id] ?? {}

  const { displayUserName, professions, firstName, lastName, type } = businessAccount ?? {}

  return (
    <InvitationItemSent
      variant="internal"
      loading={loading}
      createdAt={createdAt}
      status={status}
      statusLabel={t(`enum.businessAccountStatusEnum.${status}`)}
      createdAccount={{
        displayUserName,
        firstName,
        id: businessAccount.id,
        professions,
        lastName,
        type
      }}
      removeProcessing={removeProcessing}
      withdrawProcessing={removeProcessing}
      onWithdraw={handleWithdraw}
      onRemove={handleRemove}
    />
  )
}
