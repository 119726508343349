import { ChatTypeEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'

export const selectGroupChat = (state: State) =>
  Object.values(state.chat.chatRooms.list).find(
    (chatRoom) => chatRoom.type === ChatTypeEnum.CASE_GROUP
  )

export const selectOnline = (state: State) => {
  const userId = state.chat.chatRooms.selectedChat?.interlocutorAccount?.id

  return userId ? state.online.list[userId]?.online ?? false : false
}

export const selectLastSeen = (state: State) => {
  const userId = state.chat.chatRooms.selectedChat?.interlocutorAccount?.id

  return userId
    ? state.online.list[userId]?.lastSeen ??
        state.chat.chatRooms.selectedChat?.interlocutorAccount?.lastSeen
    : ''
}

export const isChatRoomMutedSelector = (id?: string) => (state: State) =>
  Boolean(id && state.chat.chatRooms.list[id]?.mutedUntil)

export const selectChatIdsByType = (type: ChatTypeEnum) => (state: State) =>
  state.chat.chatRooms.ids.filter((id) => state.chat.chatRooms.list[id]?.type === type)

export const selectHighlightedChatIdsByType =
  (type: ChatTypeEnum, highlights = true) =>
  (state: State) =>
    state.chat.chatRooms.ids.filter(
      (id) =>
        state.chat.chatRooms.list[id]?.type === type &&
        !!state.chat.chatRooms.list[id]?.isHighlight === highlights
    )

export const selectHighlightedChatIds = (state: State) =>
  state.chat.chatRooms.ids.filter((id) => !!state.chat.chatRooms.list[id]?.isHighlight)

export const getSelectedChatRoomSelector = (chatId?: string | null) => (state: State) =>
  chatId && state.chat.chatRooms.selectedChat?.id === chatId
    ? state.chat.chatRooms.selectedChat
    : undefined

export const isSelectedChatP2PSelector = (state: State) =>
  state.chat.chatRooms.selectedChat?.type === ChatTypeEnum.DIALOGUE
