import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { hideModalAction, medCloudAbortUpload, uploadFilesCloseWidget, useAppDispatch } from 'store'
import { Button, ConfirmationModal } from 'App/components'

export const FileUploadWidgetDiscardDialog = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = useCallback(() => dispatch(uploadFilesCloseWidget()), [dispatch])
  const onConfirm = useCallback(() => {
    dispatch(hideModalAction())
    dispatch(medCloudAbortUpload({ onSuccess }))
  }, [dispatch, onSuccess])

  return (
    <ConfirmationModal
      content={t('modal.cancelUploadConfirm.content')}
      controls={<Button onClick={onConfirm}>{t('modal.cancelUploadConfirm.submitButton')}</Button>}
    />
  )
}
