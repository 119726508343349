import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  GET_FILES_HISTORY,
  getFilesHistoryRequest,
  resetFilesHistory
} from 'store'
import { FileHistoryListContainer } from 'App/containers'
import { generateRandomArray } from 'utils'
import { EEmptyListIconSize, EmptyList } from 'App/components'
import { ReactComponent as SharedFilesIcon } from 'assets/icons/SharedFiles.svg'

import styles from './FileHistoryWrapperContainer.module.scss'

const loadingSelector = createLoadingSelector([GET_FILES_HISTORY])

export type TFileHistoryWrapperContainerProps = {
  fileId: string
  caseId?: string

  displayInline?: boolean
  sources?: FileHistorySourcesEnum[]
  actorAccountIds?: string[]
}

const DEFAULT_IDS_LENGTH = 6

export const FileHistoryWrapperContainer = ({
  fileId,
  sources,
  actorAccountIds,
  displayInline,
  caseId
}: TFileHistoryWrapperContainerProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { dateIds, loading } = useSelector((state: State) => ({
    dateIds: state.file.fileHistory.dateIds,
    loading: loadingSelector(state)
  }))

  useEffect(() => {
    dispatch(
      getFilesHistoryRequest({
        fileIds: [fileId],
        page: 0,
        actorAccountIds,
        sources,
        originalEntityIds: caseId ? [caseId] : []
      })
    )

    return () => {
      dispatch(resetFilesHistory())
    }
  }, [])

  const fileHistoryIds = loading ? generateRandomArray(DEFAULT_IDS_LENGTH) : dateIds

  return fileHistoryIds.length ? (
    <Skeleton
      loading={loading}
      active={true}
      title={false}
      paragraph={{
        rows: DEFAULT_IDS_LENGTH
      }}
      className={styles.skeleton}
    >
      <FileHistoryListContainer
        fileId={fileId}
        sources={sources}
        actorAccountIds={actorAccountIds}
        displayInline={displayInline}
      />
    </Skeleton>
  ) : (
    <EmptyList
      icon={<SharedFilesIcon />}
      text={t('cases.details.attachments.fileDetails.tabs.history.placeholder')}
      iconSize={EEmptyListIconSize.MD}
      className={styles.emptyList}
    />
  )
}
