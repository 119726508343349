import { FC, useCallback, useState, ChangeEvent, ReactNode, KeyboardEvent } from 'react'
import { Row, Col } from 'antd'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { TextField, Button, IconButton, Help } from 'App/components'
import { enterKeyCodes } from 'globalConstants'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'

import styles from './DynamicList.module.scss'

export type TDynamicListProps = {
  valueLengthMax?: number
  maxLength?: number
  value?: string[] | null
  disabled?: boolean
  title: ReactNode
  tooltip: ReactNode
  onChange: (newValue: string[]) => void
}

export const DynamicList: FC<TDynamicListProps> = (props) => {
  const {
    maxLength = 1,
    valueLengthMax = 1,
    value = [],
    tooltip,
    title,
    onChange,
    disabled: propDisabled
  } = props
  const [fieldValue, setFieldValue] = useState('')

  const { t } = useTranslation()

  const disabled = propDisabled || (value?.length ?? -Infinity) >= maxLength
  const isFieldValueValid = fieldValue.length <= valueLengthMax

  const handleTextFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.value)
    },
    [setFieldValue]
  )
  const handleAdd = () => {
    setFieldValue('')
    onChange([...(value || []), fieldValue])
  }
  const removeItem = (index: number) => {
    onChange([...(value || []).slice(0, index), ...(value || []).slice(index + 1)])
  }
  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (isFieldValueValid && enterKeyCodes.includes(event.key)) {
      event.preventDefault()
      handleAdd()
    }
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <TextField
          value={fieldValue}
          disabled={disabled}
          topLabel={
            <>
              {title} {tooltip ? <Help content={tooltip} /> : null}
            </>
          }
          onKeyDown={onKeyDown}
          valueLengthMax={valueLengthMax}
          onChange={handleTextFieldChange}
          InputProps={{
            endAdornment: (
              <Button
                onClick={handleAdd}
                className={cls(styles.addButton, { [styles.addButtonDisabled]: disabled })}
                disabled={!fieldValue.length || disabled || !isFieldValueValid}
                icon={<PlusIcon />}
                variant="text"
              >
                {t('common.dynamicList.submitButton')}
              </Button>
            )
          }}
        />
      </Col>
      <ul className={styles.list}>
        {value?.map((item, index) => (
          <li key={index} className={styles.item}>
            {item}{' '}
            <IconButton
              iconComponent={<TrashIcon />}
              iconSize={EIconSize.SM}
              onClick={() => removeItem(index)}
            />
          </li>
        ))}
      </ul>
    </Row>
  )
}
