import { useParams } from 'react-router-dom'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { MODELS_WHITELIST } from 'globalConstants'

import { getExtensionWithoutDot } from '../../../utils/files'
import { FileViewerProgress, ModelViewer } from '../../components'

import styles from './Mobile3DViewer.module.scss'

export const Mobile3DViewer = () => {
  const { fileId, extension } = useParams<{ fileId: string; extension: string }>()

  const { t } = useTranslation()

  if (!MODELS_WHITELIST.includes(getExtensionWithoutDot(extension))) {
    return (
      <FileViewerProgress
        percent={100}
        status="exception"
        label={t('common.fileViewer.wrongExtension')}
      />
    )
  }

  return (
    <div className={styles.root}>
      <ModelViewer id={fileId} extension={extension} source={FileHistorySourcesEnum.MED_CLOUD} />
    </div>
  )
}
