import { useState, useRef, useEffect, useCallback } from 'react'
import Draggable from 'react-draggable'
import cls from 'classnames'
import { useDispatch } from 'react-redux'

import { openFullscreen, closeFullscreen, getBase64ImageFromUrl, importJitsiApi } from 'utils'
import {
  setFoldCall,
  TAccountData,
  useAppSelector,
  sendEndCallRequest,
  leaveVoiceRoomRequest
} from 'store'
import config from 'config'
import { IConfigOptions, IInterfaceConfigOptions, IJitsiMeetAPI, IJitsiMeetAPIOptions } from 'types'
import { useAdaptiveLayout, usePrevious, useDidMountEffect } from 'App/hooks'
import { CallOutgoingFooterContainer, CallOutsideButtonContainer } from 'App/containers'
import { CallStatus, IconButton } from 'App/components'
import { EIconSize } from 'enums'
import { ReactComponent as ArrowIcon } from 'assets/icons/ChevronRight.svg'
import { FAILED_TO_CREATE_JITSI_API_INSTANCE, JITSI_API_NOT_LOADED } from 'globalConstants/errors'
import { handleError } from 'api/utils'

import styles from '../Call.module.scss'

export type TUseCallCoreOptions = {
  avatarURL: string
  accountData: TAccountData | null

  callId?: string | null
  jwt?: string | null
  chatId?: string | null
  organizationId?: string | null
  startTime?: Date | null // call only
  fixed?: boolean
  isOwner?: boolean
  isVideoCall?: boolean
  isMicOn?: boolean
  isOneToOne?: boolean
  type?: 'call' | 'channel'
}

const CANCEL_DRAGGABLE_SELECTOR = 'cancel-draggable-answered'

export const useCallCore = ({
  chatId,
  accountData,
  avatarURL,
  startTime,
  jwt,
  callId,
  organizationId,
  fixed = true,
  isVideoCall = false,
  isOneToOne = true,
  isOwner = false,
  type = 'call',
  isMicOn = true
}: TUseCallCoreOptions) => {
  const dispatch = useDispatch()
  const { isMobile } = useAdaptiveLayout()
  const rootRef = useRef<HTMLDivElement>(null)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [isAnsweredCall, setAnsweredCall] = useState<boolean>(false)
  const [jitsiApi, setJitsiApi] = useState<IJitsiMeetAPI | null>(null)
  const [isFilmstripDisplay, setFilmstripDisplay] = useState(false)
  const [isGridView, setGridView] = useState<boolean>(false)
  const isMobilePrev = usePrevious(isMobile)

  const isFolded = useAppSelector((state) => state.calls.isFolded)

  const isMobileFullScreen = isMobile && isFullscreen && !isFolded
  const isNonNativeFullScreen = !document.fullscreenEnabled
  const isDraggable = !isFullscreen && fixed

  useEffect(() => {
    if (callId && jwt) {
      const interfaceConfigOverwrite: IInterfaceConfigOptions = {
        IS_ONE_TO_ONE_MEETING: isOneToOne,
        TOOLBAR_BUTTONS: [],
        TOOLBAR_ALWAYS_VISIBLE: true,
        VERTICAL_FILMSTRIP: true,
        APP_NAME: 'Medentee',
        DISPLAY_WELCOME_PAGE_CONTENT: true,
        GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
        HIDE_INVITE_MORE_HEADER: true,
        JITSI_WATERMARK_LINK: '',
        LANG_DETECTION: false,
        RECENT_LIST_ENABLED: false,
        SETTINGS_SECTIONS: ['devices'],
        SHOW_JITSI_WATERMARK: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        DISABLE_FOCUS_INDICATOR: true,
        FILM_STRIP_MAX_HEIGHT: 180,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_PRESENCE_STATUS: true
      }

      const configOverwrite: IConfigOptions = {
        showSkipPrejoin: false,
        startWithVideoMuted: !isVideoCall,
        disableDeepLinking: true,
        prejoinPageEnabled: false,
        startWithAudioMuted: !isMicOn,
        defaultLanguage: 'en'
      }

      const jitsiMeetOptions: IJitsiMeetAPIOptions = {
        lang: 'en',
        roomName: callId,
        parentNode: rootRef.current,
        height: '100%',
        width: '100%',
        jwt,
        configOverwrite,
        interfaceConfigOverwrite,
        userInfo: {
          displayName: accountData?.displayUserName
        }
      }

      importJitsiApi()
        .then((JitsiMeetExternalAPI) => {
          const api = new JitsiMeetExternalAPI(
            config.jitsiMeetUrl.replace(/^https:\/\//, ''),
            jitsiMeetOptions
          )

          if (!api) {
            throw new Error(FAILED_TO_CREATE_JITSI_API_INSTANCE)
          }

          api.executeCommand('toggleFilmStrip')
          api.addEventListener<{
            visible: boolean // Whether or not the filmstrip is displayed or hidden.
          }>('filmstripDisplayChanged', ({ visible }) => setFilmstripDisplay(visible))

          api.addEventListener<{
            enabled: boolean // whether tile view is not displayed or not
          }>('tileViewChanged', ({ enabled }) => setGridView(enabled))

          setJitsiApi(api)
        })
        .catch((err) => {
          console.error(JITSI_API_NOT_LOADED, err)
          handleError(err)
        })
    }
  }, [jwt, accountData?.displayUserName, isOneToOne, isVideoCall, callId, isMicOn])

  const toggleFilmStrip = () => jitsiApi?.executeCommand('toggleFilmStrip')

  const handleFullscreen = async () => {
    if (isFullscreen) {
      if (!isNonNativeFullScreen) {
        await closeFullscreen<HTMLDivElement>(rootRef.current)
      }
      setIsFullscreen(false)

      return
    }

    toggleFilmStrip()

    if (!isNonNativeFullScreen) {
      await openFullscreen<HTMLDivElement>(rootRef.current)
    }

    setIsFullscreen(true)
  }

  const handleHideFullscreenOnMobile = useCallback(() => {
    setIsFullscreen(false)
    dispatch(setFoldCall({ isFolded: true }))
  }, [dispatch])

  const handleShowFullscreenOnMobile = useCallback(() => {
    setIsFullscreen(true)
    dispatch(setFoldCall({ isFolded: false }))
  }, [dispatch])

  const handleFullscreenOnMobile = useCallback(
    () => (isFullscreen ? handleHideFullscreenOnMobile() : handleShowFullscreenOnMobile()),
    [handleHideFullscreenOnMobile, handleShowFullscreenOnMobile, isFullscreen]
  )

  const handleEndCall = useCallback(() => {
    if (callId) {
      type === 'call'
        ? dispatch(sendEndCallRequest({ callId }))
        : dispatch(leaveVoiceRoomRequest({ voiceRoomId: callId }))
    }
  }, [callId, dispatch, type])

  useEffect(() => {
    const sendAvatar = async () => {
      const url = await getBase64ImageFromUrl(avatarURL)

      jitsiApi?.executeCommand('avatarUrl', url ?? '')
    }

    if (jitsiApi && avatarURL) {
      sendAvatar()
    }
  }, [avatarURL, jitsiApi])

  useEffect(() => {
    const listener = () => {
      if (!isMobile && isFullscreen && !document.fullscreenElement) {
        setIsFullscreen(false)
      }
    }

    document.addEventListener('fullscreenchange', listener)

    return () => {
      document.removeEventListener('fullscreenchange', listener)
    }
  }, [isFullscreen, isMobile])

  useEffect(
    () => {
      // When on the phone we connect to the call, we go into full screen view
      if (isMobile && !isMobileFullScreen && type === 'call' && !isFolded) {
        handleShowFullscreenOnMobile()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] /* Only on the mount */
  )

  useDidMountEffect(() => {
    if (isMobile && !isMobileFullScreen && !isFolded) {
      handleShowFullscreenOnMobile()
    }
  }, [isFolded])

  useEffect(() => {
    // For the desktop in the mobile view.
    // When the screen size becomes larger than the mobile screen
    if (isMobilePrev && !isMobile && !isMobileFullScreen && isFullscreen) {
      handleHideFullscreenOnMobile()
    }
  }, [handleHideFullscreenOnMobile, isFullscreen, isMobileFullScreen, isMobilePrev, isMobile])

  useEffect(() => {
    // For the desktop in the mobile view.
    // When the screen becomes smaller than the screen of a tablet device
    if (!isMobilePrev && isMobile && !isMobileFullScreen && isFullscreen) {
      handleHideFullscreenOnMobile()
    }
  }, [handleHideFullscreenOnMobile, isFullscreen, isMobileFullScreen, isMobile, isMobilePrev])

  useEffect(() => {
    document.body.style.overflow = isMobileFullScreen ? 'hidden' : ''
  }, [isMobileFullScreen])

  useEffect(
    () => () => {
      document.body.style.overflow = ''
    },
    []
  )

  const render = () => (
    <Draggable
      bounds="body"
      disabled={isFullscreen || isMobile || !fixed}
      cancel={`.${CANCEL_DRAGGABLE_SELECTOR}`}
      position={isMobile || !fixed ? { x: 0, y: 0 } : undefined}
    >
      <div
        className={cls(styles.container, {
          [styles.mobileFullScreen]: isMobileFullScreen || (isNonNativeFullScreen && isFullscreen),
          [styles.static]: !fixed,
          [styles.fullscreen]: isFullscreen
        })}
      >
        <div className={styles.videoCallContainer} ref={rootRef}>
          <CallOutsideButtonContainer
            hide={!fixed}
            chatId={chatId}
            organizationId={organizationId}
            isOwner={isOwner}
            isFullScreen={isFullscreen}
          />

          {isDraggable && (
            <>
              <div className={styles.draggableBoxTop} />
              <div className={styles.draggableBoxLeft} />
              <div className={styles.draggableBoxRight} />
              <div className={styles.draggableBoxFooter} />
            </>
          )}

          {isFullscreen && !isGridView && (
            <IconButton
              iconComponent={<ArrowIcon />}
              iconSize={EIconSize.XXL}
              onClick={toggleFilmStrip}
              classes={{
                root: cls(styles.toggleFilmStrip, !isFilmstripDisplay && styles.toggleFilmStripFlip)
              }}
            />
          )}

          {isMobileFullScreen && (
            <div className={styles.callStatusWrapper}>
              <CallStatus isAnsweredCall={isAnsweredCall} startTime={startTime} />
            </div>
          )}

          {jitsiApi && callId && (
            <CallOutgoingFooterContainer
              jitsiAPI={jitsiApi}
              callId={callId}
              isCallOwner={isOwner}
              isAnsweredCall={isAnsweredCall}
              isFullscreen={isFullscreen}
              cancelDraggableClassName={CANCEL_DRAGGABLE_SELECTOR}
              rootRef={rootRef}
              fixed={fixed}
              onFullscreen={isMobile ? handleFullscreenOnMobile : handleFullscreen}
              onEnd={handleEndCall}
              type={type}
            />
          )}
        </div>
      </div>
    </Draggable>
  )

  return {
    render,
    rootRef,
    isFullscreen,
    setIsFullscreen,
    setAnsweredCall
  }
}
