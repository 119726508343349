import { ReactNode } from 'react'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EEmptyListIconSize, EmptyList } from 'App/components'
import { EmptyListInviteButton } from 'App/containers'
import { TChatRoomsType } from 'store'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

export type TEmptyContactListProps = {
  hideInvite?: boolean
  chatType: TChatRoomsType
  emptyListText?: ReactNode
  accountType: AccountTypeNames
}

export const ChatEmptyContactList = ({
  chatType,
  accountType,
  hideInvite: hideInviteProp,
  emptyListText
}: TEmptyContactListProps) => {
  const { t } = useTranslation()

  const hideInvite = hideInviteProp || chatType === ChatTypeEnum.CASE_GROUP

  return (
    <EmptyList
      icon={<ForumIcon />}
      text={emptyListText ?? t('chat.list.placeholder')}
      iconSize={EEmptyListIconSize.MD}
    >
      {!hideInvite && (
        <EmptyListInviteButton
          accountType={accountType}
          emptyListLinkText={t('chat.list.inviteButton')}
          contactType={undefined}
        />
      )}
    </EmptyList>
  )
}
