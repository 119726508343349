import { memo } from 'react'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { Avatar, TAvatarProps } from 'App/components'

export type TAvatarContainerOwnProps = {
  userId: string
  showOnline?: boolean
} & TAvatarProps

export type TAvatarContainerProps = TAvatarContainerOwnProps & ReturnType<typeof mapStateToProps>

const mapStateToProps = (
  state: State,
  { userId, showOnline = true, online }: TAvatarContainerOwnProps
) => ({
  online: online ?? (showOnline && state.online.list[userId]?.online),
  userId
})

export const AvatarContainer = connect(mapStateToProps)(memo(Avatar))
