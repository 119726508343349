import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import { CaseAutoApprove } from 'App/components'
import { showModalAction } from 'store'

export type TCaseAutoApproveContainerProps = ReturnType<typeof useCaseAutoApproveContainer>

const useCaseAutoApproveContainer = () => {
  const dispatch = useDispatch()
  const actions = bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

  return actions
}

export const CaseAutoApproveContainer = () => {
  const { showModal } = useCaseAutoApproveContainer()

  return <CaseAutoApprove showModal={showModal} />
}
