import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TruncatedText } from '../../../common'

import styles from './CaseLabel.module.scss'

type TCaseLabelProps = {
  caseId: string
  caseTitle: string
  isOwner: boolean
}

export const CaseLabel = ({ caseId, caseTitle, isOwner }: TCaseLabelProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {t('meetings.caseLabel')}
      <Link to={`/cases/${caseId}/${isOwner ? 'owner' : 'member'}`} className={styles.name}>
        <TruncatedText text={caseTitle} />
      </Link>
    </div>
  )
}
