import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { State } from 'redux/rootReducer'
import { InvitationItemSent } from 'App/components'
import {
  createLoadingSelector,
  createProcessingBySelector,
  GET_CONTACTS,
  removeContactInviteRequest,
  REMOVE_CONTACT_INVITE
} from 'store'
import { EItemSentType } from 'enums'
import { getMapComponent } from 'utils'
import {
  InvitationItemSentNewUsersContainer,
  TInvitationItemSentNewUsersContainerProps
} from 'App/containers'
import { useContactInvitation, TUseContactInvitationResponse } from 'App/hooks/useContactInvitation'
import { EInvitationAction } from 'globalConstants'

export type TInvitationItemSentContainerOwnProps = Pick<
  TInvitationItemSentNewUsersContainerProps,
  'contactTypeName'
> & {
  id: string

  itemSentType?: EItemSentType
}

export type TInvitationItemSentContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TInvitationItemSentContainerOwnProps

const loadingSelector = createLoadingSelector([GET_CONTACTS])

const removeProcessingSelector = createProcessingBySelector([REMOVE_CONTACT_INVITE])

type TInvitationSentItemMapProps = Omit<TInvitationItemSentContainerProps, 'itemSentType'> &
  Pick<TUseContactInvitationResponse, 'withdrawContactInvitation'> & {
    t: TFunction
  }

const INVITATION_SENT_ITEM_MAP = new Map<
  EItemSentType,
  (props: TInvitationSentItemMapProps) => JSX.Element
>()
  .set(
    EItemSentType.INTERNAL,
    ({
      id,
      status,
      loading,
      removeProcessing,
      email,
      createdAt,
      type,
      firstName,
      lastName,
      displayUserName,
      userId,
      professions,
      invitation,
      message,
      removeContact,
      withdrawContactInvitation,
      t
    }) => (
      <InvitationItemSent
        variant="internal"
        status={status}
        statusLabel={t(`enum.contactStatusEnum.${status}`)}
        loading={loading}
        removeProcessing={removeProcessing}
        withdrawProcessing={removeProcessing}
        email={email}
        message={message}
        createdAt={createdAt}
        createdAccount={{
          type,
          firstName,
          lastName,
          displayUserName,
          id: userId,
          professions,
          email
        }}
        invitationEmail={invitation?.email}
        onRemove={() =>
          removeContact({ contactId: id, processingId: id, type: EInvitationAction.REMOVE })
        }
        onWithdraw={() => withdrawContactInvitation({ contactId: id })}
      />
    )
  )
  .set(EItemSentType.EXTERNAL, ({ id, contactTypeName }) => (
    <InvitationItemSentNewUsersContainer
      source="invitations"
      id={id}
      contactTypeName={contactTypeName}
    />
  ))

const mapStateToProps = (state: State, { id }: TInvitationItemSentContainerOwnProps) => {
  const { status, to, createdAt, invitation, message } =
    state.contacts.contactInvitations.list[id] || {}

  const { email, firstName, lastName, displayUserName, type, id: userId, professions } = to ?? {}

  return {
    loading: loadingSelector(state),
    removeProcessing: removeProcessingSelector(id)(state),
    id,
    status,
    createdAt,
    email,
    firstName,
    lastName,
    displayUserName,
    userId,
    type,
    professions,
    invitation,
    message
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeContact: removeContactInviteRequest
    },
    dispatch
  )

const InvitationItemSentContainerView = ({
  itemSentType = EItemSentType.INTERNAL,
  ...rest
}: TInvitationItemSentContainerProps) => {
  const { t } = useTranslation()

  const { withdrawContactInvitation } = useContactInvitation()

  return getMapComponent(INVITATION_SENT_ITEM_MAP, itemSentType, {
    ...rest,
    t,
    withdrawContactInvitation
  })
}

export const InvitationItemSentContainer: FC<TInvitationItemSentContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationItemSentContainerView)
