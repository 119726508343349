import { ButtonChip, TButtonChipProps } from 'App/components'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

export type TButtonWithdrawInvitationProps = TButtonChipProps & {
  contactId: string

  onSuccess?: () => void
}

export const ButtonWithdrawInvitation = ({
  contactId,
  onSuccess,
  ...rest
}: TButtonWithdrawInvitationProps) => {
  const { withdrawContactInvitation } = useContactInvitation()

  const handleClick = () => {
    contactId && withdrawContactInvitation({ contactId, onSuccess })
  }

  return (
    <ButtonChip {...rest} onClick={handleClick}>
      <AccountRemoveOutlineIcon />
    </ButtonChip>
  )
}
