import { useMemo, useState } from 'react'
import { Swiper, SwiperProps } from 'swiper/react'
import { Swiper as ISwiper } from 'swiper/types'
import { Thumbs, FreeMode, Navigation, Pagination } from 'swiper'
import cls from 'classnames'

import media from 'assets/style/_variables.module.scss'

import { ControlButton, TControlButtonProps, EControlButtonClassName } from './ControlButton'
import styles from './Carousel.module.scss'

// Styles must use direct files imports
import 'swiper/scss' // core Swiper
import 'swiper/scss/navigation' // Navigation module
import 'swiper/scss/thumbs' // Thumbs module
import 'swiper/scss/free-mode' // Free-mode module

export type TCarouselProps = Pick<
  SwiperProps,
  'onSlideChange' | 'initialSlide' | 'pagination' | 'onSwiper'
> &
  PropsWithClasses<
    'carousel',
    {
      slides: JSX.Element[]

      controlButtonPosition?: TControlButtonProps['position']
      thumbnails?: JSX.Element[] | null
      currentSlideIndex?: number
    }
  >

const BASE_MODULES: SwiperProps['modules'] = [FreeMode, Navigation]
const WITH_THUMBS_MODULES: SwiperProps['modules'] = [...BASE_MODULES, Thumbs]

export const Carousel = ({
  slides,
  thumbnails,
  classes,
  initialSlide,
  controlButtonPosition,
  pagination,
  currentSlideIndex,
  onSlideChange,
  onSwiper
}: TCarouselProps) => {
  const { desktopMinWidth, mobileMaxWidth } = media
  const [thumbsSwiper, setThumbsSwiper] = useState<ISwiper | null>(null)

  const isThumbnails = Boolean(thumbnails?.length)

  const mainSwiperModules = useMemo(() => {
    const modules = (isThumbnails ? WITH_THUMBS_MODULES : BASE_MODULES).slice(0) // make a copy

    if (pagination) {
      modules.push(Pagination)
    }

    return modules
  }, [isThumbnails, pagination])

  return (
    <div className={styles.root}>
      <Swiper
        onSwiper={onSwiper}
        freeMode={false}
        pagination={pagination ? { clickable: true } : undefined}
        initialSlide={initialSlide}
        centeredSlides={true}
        uniqueNavElements
        className={cls(
          styles.carousel,
          classes?.carousel,
          isThumbnails && styles.carouselWithThumbnails
        )}
        spaceBetween={10}
        navigation={{
          disabledClass: EControlButtonClassName.DISABLE,
          hiddenClass: EControlButtonClassName.HIDDEN,
          prevEl: `.${EControlButtonClassName.PREV}`,
          nextEl: `.${EControlButtonClassName.NEXT}`
        }}
        onSlideChange={onSlideChange}
        thumbs={isThumbnails ? { swiper: thumbsSwiper } : undefined}
        modules={mainSwiperModules}
      >
        <ControlButton type="prev" position={controlButtonPosition} />
        <ControlButton type="next" position={controlButtonPosition} />
        {slides}
      </Swiper>
      {currentSlideIndex !== undefined && (
        <span className={styles.currentSlideIndex}>{currentSlideIndex + 1}</span>
      )}

      {isThumbnails && (
        <Swiper
          initialSlide={initialSlide}
          onSwiper={setThumbsSwiper}
          grabCursor={true}
          slidesPerView={4}
          spaceBetween={20}
          uniqueNavElements
          breakpoints={{
            [mobileMaxWidth]: {
              slidesPerView: 6,
              spaceBetween: 0
            },
            [desktopMinWidth]: {
              slidesPerView: 8,
              spaceBetween: 0
            }
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={WITH_THUMBS_MODULES}
          className={styles.thumbnails}
          resizeObserver={false}
          resistance={false}
        >
          {thumbnails}
        </Swiper>
      )}
    </div>
  )
}
