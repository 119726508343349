import { FC, useMemo, useCallback, useEffect, useState, ChangeEvent } from 'react'
import { Field, FieldInputProps, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { v4 as uuidV4 } from 'uuid'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { Skeleton } from 'antd'

import { useQuery } from 'services/query'
import { MIN_LENGTH_1, MAX_LENGTH_120, MAX_LENGTH_500, DEFAULT_THROTTLE_MS } from 'globalConstants'
import { validation } from 'utils'
import { Button, FieldLabel, TextField, TextFieldSkeleton, Tooltip } from 'App/components'
import { getShowcaseDraft, TUpdateShowcaseDraftPayload } from 'api/showcase'
import { CategoriesChipList } from 'App/containers'
import { queryClient } from 'queryClient'
import { IFilesEntity } from 'interfaces'
import { accountIdSelector, useAppSelector } from 'store'

import { useFileSuggestions } from '../useFileSuggestions'
import {
  parseResponseItems,
  prepareRequestPayload
} from '../../CategoriesChipList/categoriesChipList.utils'
import { useCategoriesChipRequest } from '../../CategoriesChipList/useCategoriesChipRequest'

import { Files } from './Files'
import { useRequest } from './useRequest'
import styles from './UpdateShowcaseDialog.module.scss'
import { getUploadedFileList, TShowcaseFile } from './Files/Files.utils'
import { DescriptionField } from './DescriptionField'

export type TUpdateShowcaseDialogProps = {
  id?: string
}

export type TUpdateShowcaseDialogValues = {
  title: string
  description: string
  fileIds: string[]
  categoryIds: string[]
}

export const UpdateShowcaseDialog: FC<TUpdateShowcaseDialogProps> = ({ id }) => {
  const [showcaseId] = useState(id ?? uuidV4())
  const [touchedDescription, setTouchedDescription] = useState<boolean>(false)
  const [attachedFiles, setAttachedFiles] = useState<IFilesEntity[]>([])
  const [submitError, setSubmitError] = useState<string | boolean>(true)
  const [filesLoading, setFilesLoading] = useState<boolean>(false)

  const accountId = useAppSelector(accountIdSelector)
  const { loading: loadingCategories, items } = useCategoriesChipRequest()

  const { onUpdateShowcaseDraft, onPublishShowcase, processing } = useRequest()

  const { data, isLoading } = useQuery({
    queryKey: ['showcase-draft'],
    queryFn: () => getShowcaseDraft(showcaseId),
    enabled: !!id,
    onSuccess: ({ files }) => setAttachedFiles(files)
  })

  const { categories, departments, shareOuterCategories, departmentsOuterOrganizations } =
    data || {}

  const {
    fileMentions,
    fileSuggestions = [],
    onSearchChange
  } = useFileSuggestions({
    files: attachedFiles,
    showcaseId,
    ownerId: accountId
  })

  useEffect(() => {
    if (!id && items) {
      onUpdateShowcaseDraft({
        id: showcaseId,
        departmentIds: [],
        shareOuterCategories: true,
        departmentsOuterOrganizationIds: [],
        categoryIds: items
          .find((item) => item.category === 'Contacts')
          ?.items?.reduce<string[]>((res, item) => {
            if (item.id === 'no-category') {
              return res
            }

            return [...res, item.id]
          }, [])
      })
    }
  }, [onUpdateShowcaseDraft, id, items, showcaseId])

  const initialValues = useMemo<TUpdateShowcaseDialogValues>(
    () => ({
      title: data?.title ?? '',
      description: data?.description ?? '',
      fileIds: data?.files.map((file) => file.id) ?? [],
      categoryIds: data
        ? parseResponseItems(data).map((item) => item.id)
        : items?.find((item) => item.category === 'Contacts')?.items?.map((item) => item.id) ?? []
    }),
    [data, items]
  )

  const initialFiles = useMemo<TShowcaseFile[] | undefined>(
    () => data?.files.map((file, index) => ({ file, uuid: uuidV4(), orderNumber: index + 1 })),
    [data?.files]
  )

  const handleDraftUpdate = useCallback(
    ({ categoryIds, ...formData }: Partial<TUpdateShowcaseDraftPayload>) =>
      () =>
        onUpdateShowcaseDraft({
          id: showcaseId,
          ...formData,
          ...(categoryIds?.length ? prepareRequestPayload(categoryIds) : {})
        }),
    [onUpdateShowcaseDraft, showcaseId]
  )

  const debouncedTitleChange = useMemo(
    () =>
      debounce(
        (formData: Partial<TUpdateShowcaseDialogValues>) => handleDraftUpdate(formData)(),
        DEFAULT_THROTTLE_MS
      ),
    [handleDraftUpdate]
  )

  const debouncedDescriptionChange = useMemo(
    () =>
      debounce(
        (formData: Partial<TUpdateShowcaseDialogValues>) => handleDraftUpdate(formData)(),
        DEFAULT_THROTTLE_MS
      ),
    [handleDraftUpdate]
  )

  const initialSelected = useMemo(() => {
    if (
      !categories?.length &&
      !departments?.length &&
      !departmentsOuterOrganizations?.length &&
      shareOuterCategories === undefined
    ) {
      return parseResponseItems({
        categories: items?.find((item) => item.category === 'Contacts')?.items,
        departments: [],
        shareOuterCategories: false,
        departmentsOuterOrganizations: []
      })
    }

    return parseResponseItems({
      categories,
      departments: departments || [],
      departmentsOuterOrganizations: departmentsOuterOrganizations || [],
      shareOuterCategories: shareOuterCategories === undefined ? false : shareOuterCategories
    })
  }, [items, categories, departments, shareOuterCategories, departmentsOuterOrganizations])

  const handleTitleChange = useCallback(
    (input: FieldInputProps<number[]>) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      input.onChange(value)
      debouncedTitleChange({ title: value })
    },
    [debouncedTitleChange]
  )

  const handleDescriptionChange = useCallback(
    (input: FieldInputProps<number[]>) => (value: string) => {
      input.onChange(value)
      setTouchedDescription(true)
      debouncedDescriptionChange({ description: value })
    },
    [debouncedDescriptionChange]
  )

  const handleCategoriesChange = useCallback(
    (changeForm: FormApi<TUpdateShowcaseDialogValues>['change']) => (categoryIds: string[]) => {
      changeForm('categoryIds', categoryIds)
      onUpdateShowcaseDraft({
        id: showcaseId,
        ...prepareRequestPayload(categoryIds)
      })
    },
    [onUpdateShowcaseDraft, showcaseId]
  )

  const handleFilesChange = useCallback(
    (changeForm: FormApi<TUpdateShowcaseDialogValues>['change']) =>
      (files: TShowcaseFile[], update = true) => {
        const uploadedFiles = getUploadedFileList(files)
        const fileIds = uploadedFiles.map((file) => file.id)

        setAttachedFiles(uploadedFiles)
        changeForm('fileIds', fileIds)
        update &&
          onUpdateShowcaseDraft({
            id: showcaseId,
            fileIds
          })
      },
    [onUpdateShowcaseDraft, showcaseId]
  )

  const onSubmit = useCallback(
    (value: Partial<TUpdateShowcaseDialogValues>) => {
      onUpdateShowcaseDraft({
        id: showcaseId,
        fileIds: value.fileIds,
        onSuccess: () => onPublishShowcase(showcaseId)
      })
    },
    [onPublishShowcase, onUpdateShowcaseDraft, showcaseId]
  )

  const submitTooltip = submitError && typeof submitError === 'string' ? submitError : undefined

  useEffect(() => () => queryClient.removeQueries({ queryKey: ['showcase-draft'] }), [])

  return (
    <div className={styles.root}>
      <Form<TUpdateShowcaseDialogValues>
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
        initialValuesEqual={(a, b) => isEqual(a, b)}
      >
        {({ handleSubmit, form }) => (
          <>
            <TextFieldSkeleton loading={isLoading}>
              <Field
                name="title"
                validate={validation.composeValidators(
                  validation.required(),
                  validation.onlySpaces(),
                  validation.minLength(MIN_LENGTH_1),
                  validation.maxLength(MAX_LENGTH_120)
                )}
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    onChange={handleTitleChange(input)}
                    invalid={meta.touched && meta.invalid}
                    error={meta.error}
                    topLabel="Title *"
                    valueLengthMax={MAX_LENGTH_120}
                    autoFocus={true}
                  />
                )}
              </Field>
            </TextFieldSkeleton>

            <TextFieldSkeleton loading={isLoading} variant="textArea">
              <Field
                name="description"
                validate={validation.composeValidators(
                  validation.onlySpaces(),
                  validation.maxLength(MAX_LENGTH_500)
                )}
              >
                {({ input, meta }) => (
                  <div>
                    <FieldLabel label="Description" />
                    <DescriptionField
                      onChange={handleDescriptionChange(input)}
                      initialValue={data?.description ?? ''}
                      error={meta.error}
                      invalid={touchedDescription && meta.invalid}
                      fileMentions={fileMentions}
                      fileSuggestions={fileSuggestions}
                      onSearchChange={onSearchChange}
                    />
                  </div>
                )}
              </Field>
            </TextFieldSkeleton>

            <Field name="categoryIds">
              {() => (
                <div>
                  {!isLoading && <FieldLabel label="Share to*" />}
                  <CategoriesChipList
                    editable
                    items={items || []}
                    loading={isLoading}
                    className={styles.categories}
                    title="Share to professionals"
                    loadingItems={loadingCategories}
                    initialSelected={initialSelected}
                    onChange={handleCategoriesChange(form.change)}
                  />
                </div>
              )}
            </Field>

            <Field name="fileIds">
              {() => (
                <Files
                  loading={isLoading}
                  initialFiles={initialFiles}
                  showcaseId={showcaseId}
                  onChange={handleFilesChange(form.change)}
                  setSubmitError={setSubmitError}
                  setFilesLoading={setFilesLoading}
                />
              )}
            </Field>

            <div className={styles.actions}>
              <Skeleton
                active={true}
                loading={isLoading}
                title={false}
                paragraph={{ rows: 1 }}
                className={styles.submitSkeleton}
              >
                <Tooltip title={submitTooltip}>
                  <span>
                    <Button
                      loading={processing}
                      onClick={handleSubmit}
                      disabled={
                        form.getState().invalid ||
                        isLoading ||
                        processing ||
                        !!submitError ||
                        filesLoading
                      }
                    >
                      Publish
                    </Button>
                  </span>
                </Tooltip>
              </Skeleton>
              <Skeleton
                active={true}
                loading={isLoading}
                title={false}
                paragraph={{ rows: 1 }}
                className={styles.draftSkeleton}
              >
                <button
                  className={styles.draftButton}
                  onClick={handleDraftUpdate({ ...form.getState().values, hideModal: true })}
                  disabled={processing || filesLoading}
                >
                  Save as draft
                </button>
              </Skeleton>
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
