import { useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, ErrorModal } from 'App/components'
import { TCaseArchiveConfirmContainerProps } from 'App/containers'

import styles from './CaseArchiveConfirm.module.scss'

export type TCaseArchiveConfirmProps = TCaseArchiveConfirmContainerProps

export const CaseArchiveConfirm = ({
  processing,
  caseId,
  error,
  action,
  removeErrorNotification,
  archiveCase
}: TCaseArchiveConfirmProps) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification(action)
    },
    [action, removeErrorNotification]
  )

  const onClick = () => {
    archiveCase({ caseId })
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{t('cases.modal.archiveConfirm.content')}</p>
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={onClick}
      >
        {t('cases.modal.archiveConfirm.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
