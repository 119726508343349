import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { NotificationsP2P } from 'App/components'
import {
  createLoadingSelector,
  getNotificationsProtocolUsersRequest,
  GET_NOTIFICATIONS_PROTOCOL_USERS,
  refreshNotificationCountersSubscribe,
  refreshNotificationCountersUnsubscribe
} from 'store'

export type TNotificationsP2PContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_NOTIFICATIONS_PROTOCOL_USERS])

const mapStateToProps = (state: State) => ({
  ids: state.notifications.protocolUsers.ids,
  page: state.notifications.protocolUsers.pagination.current,
  total: state.notifications.protocolUsers.pagination.total,
  showBy: state.notifications.protocolUsers.pagination.showBy,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      subscribe: refreshNotificationCountersSubscribe,
      unsubscribe: refreshNotificationCountersUnsubscribe,
      getNotificationsProtocolUsers: getNotificationsProtocolUsersRequest
    },
    dispatch
  )

export const NotificationsP2PContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsP2P)
