import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './Grid.module.scss'

export type TGridProps = PropsWithChildren<PropsWithClassName>

export const Grid = ({ children, className }: TGridProps) => (
  <div className={cls(styles.grid, className)}>{children}</div>
)
