import { useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { useDialog, useRefValue } from 'App/hooks'
import {
  Button,
  AccountsList,
  MembersSelect,
  TMembersSelectProps,
  TAccountsListProps
} from 'App/components'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

import { TAccountsSelectOptionData } from '../../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import styles from './useMembersSelect.module.scss'

export type TUseMembersSelectProps = Pick<TMembersSelectProps, 'onSearch' | 'search'> &
  Pick<TAccountsListProps, 'getTitle'> & {
    disabled: boolean
    members: TAccountsSelectOptionData[]
    selectedMembers: TAccountsSelectOptionData[]
    onSubmit: (data: TAccountsSelectOptionData[]) => void

    loading?: boolean
    onBack?: () => void
  }

export const useMembersSelect = (props: TUseMembersSelectProps) => {
  const {
    disabled,
    selectedMembers,
    onSubmit,
    members,
    onBack,
    loading,
    getTitle,
    search,
    onSearch
  } = props

  const [tempSelectedMembers, setTempSelectedMembers] = useState(selectedMembers)

  const { toggle, open } = useDialog()

  const { t } = useTranslation()

  const { getValue: getSelectedMembers } = useRefValue(selectedMembers)
  const { getValue: getTempSelectedMembers } = useRefValue(tempSelectedMembers)

  const reversedSelectedMembers = useMemo(() => [...selectedMembers].reverse(), [selectedMembers])

  const handleBack = useCallback(() => {
    if (onBack) {
      onBack()
    }
    toggle()
  }, [onBack, toggle])

  const handleSubmit = useCallback(() => {
    onSubmit(getTempSelectedMembers())
    toggle()
  }, [onSubmit, getTempSelectedMembers, toggle])

  const handleClear = useCallback(() => {
    setTempSelectedMembers([])
    setTempSelectedMembers([])
  }, [])

  const handleSelect = useCallback(
    (value) => setTempSelectedMembers([...getTempSelectedMembers(), value]),
    [getTempSelectedMembers]
  )

  const handleUnselect = useCallback(
    (id: string) => {
      const data = getTempSelectedMembers().filter(
        (item: TAccountsSelectOptionData) => item.id !== id
      )

      setTempSelectedMembers(data)
    },
    [getTempSelectedMembers]
  )

  const handleOpen = useCallback(() => {
    toggle()
    setTempSelectedMembers(getSelectedMembers())
    onSearch && onSearch('')
  }, [onSearch, toggle, getSelectedMembers, setTempSelectedMembers])

  const isSubmitDisabled = !selectedMembers.length && !tempSelectedMembers.length

  const content = (
    <>
      {!open && (
        <div className={styles.buttonsWrapper}>
          <Button
            variant="text"
            disabled={disabled}
            onClick={handleOpen}
            className={styles.button}
            icon={<AccountMultiplePlusIcon />}
          >
            {t('modal.scheduleMeeting.detailsStep.inviteMembers.inviteMembersButton')}
          </Button>
          <AccountsList
            accounts={reversedSelectedMembers}
            avatarSize={EAvatarSize.XS}
            getTitle={getTitle}
          />
        </div>
      )}
      {open && (
        <MembersSelect
          loading={loading}
          members={members}
          selectedMembers={tempSelectedMembers}
          search={search}
          submitDisabled={isSubmitDisabled}
          onClear={handleClear}
          onSelect={handleSelect}
          onUnselect={handleUnselect}
          onSelectAll={setTempSelectedMembers}
          onBack={handleBack}
          onSubmit={handleSubmit}
          onSearch={onSearch}
        />
      )}
    </>
  )

  return {
    toggle,
    open,
    content
  }
}
