import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Help } from 'App/components'

import styles from './PermissionTitle.module.scss'

export type TPermissionTitleVariant = 'active' | 'inactive'

export type TPermissionTitleProps = {
  isMember?: boolean
  variant?: TPermissionTitleVariant
}

export const PermissionTitle = ({ isMember, variant = 'active' }: TPermissionTitleProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, variant === 'inactive' && styles.inactive)}>
      {variant === 'active' ? (
        <>
          <span>
            {isMember
              ? t('cases.details.attachments.permissions.caseOwner')
              : t('cases.details.attachments.permissions.activeMembers')}
          </span>
          <span>{t('cases.details.attachments.permissions.permissions')}</span>
        </>
      ) : (
        <>
          <span>{t('cases.details.attachments.permissions.pendingMembers')}</span>
          <Help content={t('cases.details.attachments.permissions.pendingMembersHelp')} />
        </>
      )}
    </div>
  )
}
