import { AccountTypeNames } from '@medentee/enums'

import { getInitials } from 'utils'

import { TAvatarProps } from '../Avatar'

import styles from './AvatarInitials.module.scss'

export type TAvatarInitialsProps = Pick<
  TAvatarProps,
  'displayUserName' | 'firstName' | 'lastName' | 'type' | 'chatType'
>

export const AvatarInitials = ({
  displayUserName,
  firstName,
  lastName,
  chatType,
  type = AccountTypeNames.PROFESSIONAL
}: TAvatarInitialsProps) => {
  const initials = getInitials(displayUserName, firstName, lastName, type, chatType)

  return initials ? <span className={styles.initials}>{initials?.toUpperCase()}</span> : null
}
