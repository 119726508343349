import { ChatTypeEnum } from '@medentee/enums'
import { useRouteMatch } from 'react-router-dom'
import { useRef } from 'react'

import { useAppSelector } from 'store'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'
import { TChannelVariant } from 'interfaces'

import { TUseEventChannelKebabMenu } from '../useEventChannelKebabMenu'

type TVariant = Extract<TChannelVariant, 'chat' | 'news'>

type TEventsChannelRoomProps = TUseEventChannelKebabMenu & {
  chatRoomId: string
  eventId: string
  defaultVariant?: TVariant
}

const VARIANT_MAP = new Map<ChatTypeEnum, TVariant>([
  [ChatTypeEnum.EVENT_CHANNEL, 'chat'],
  [ChatTypeEnum.EVENT_NEWS, 'news']
])

export const EventsChannelRoom = ({
  chatRoomId,
  defaultVariant,
  eventId,
  getMenuItems
}: TEventsChannelRoomProps) => {
  const { url } = useRouteMatch()
  const roomRef = useRef<HTMLDivElement>(null)

  const { name, mutedUntil, channelManager, isPublic, inviteToken, isHighlight, type } =
    useAppSelector((state) => state.chat.chatRooms.list[chatRoomId]) ?? {}

  const { count, mentionCount } = useAppSelector(
    (state) => state.notifications.general.events.list?.[eventId]?.chats?.[chatRoomId] ?? {}
  )

  const variant = defaultVariant ?? VARIANT_MAP.get(type)
  const isMuted = Boolean(mutedUntil)

  const handleEditSuccess = () =>
    roomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  if (!name || !variant) {
    return null
  }

  return (
    <UnityHubRoomNavLink
      to={`${url}/${variant}/${chatRoomId}`}
      icon={<UnityHubChannelIcon variant={variant} isMuted={isMuted} />}
      name={name}
      isMuted={isMuted}
      count={count}
      mentionCount={mentionCount}
      ref={roomRef}
      isPublic={isPublic}
      menuItems={getMenuItems({
        variant,
        channelId: chatRoomId,
        channelName: name,
        channelManagerId: channelManager?.id,
        inviteToken,
        isMuted,
        isPublic,
        isHighlight,
        onEditSuccess: handleEditSuccess
      })}
    />
  )
}
