import { useCallback } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'App/components'
import { TChatMessageContainerViewProps } from 'App/containers'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'
import { ReactComponent as CaseRejectedIcon } from 'assets/icons/CaseRejected.svg'

import styles from './ChatMessageCaseLabel.module.scss'

export type TChatMessageCaseLabelVariant = 'default' | 'icon'
export type TChatMessageCaseLabelProps = Pick<
  TChatMessageContainerViewProps,
  'case' | 'yourMessage' | 'chatId' | 'redirectToCaseFromChatRequest'
> & {
  rejected?: boolean
  variant?: TChatMessageCaseLabelVariant
  className?: string
  onVisibleChange?: (visible: boolean) => void
}

export const ChatMessageCaseLabel = ({
  case: caseData,
  yourMessage,
  chatId,
  className,
  redirectToCaseFromChatRequest,
  onVisibleChange,
  rejected = false,
  variant = 'default'
}: TChatMessageCaseLabelProps) => {
  const { t } = useTranslation()

  const redirectToCase = useCallback(() => {
    if (caseData) {
      redirectToCaseFromChatRequest({
        caseId: caseData.id,
        ownerId: caseData.ownerId,
        chatId
      })
    }
  }, [caseData, chatId, redirectToCaseFromChatRequest])

  // TODO: Extend MessageIconTypeEnum with a CASE_MESSAGE property and relay on it here
  if (!caseData) {
    return null
  }

  const baseClassName = cls(yourMessage && styles.yourMessage, className)
  const icon = rejected ? <CaseRejectedIcon /> : <CaseIcon />
  const title = rejected
    ? caseData.title
    : t('chat.message.caseLabelTooltip', {
        caseId: caseData.humanReadableId,
        caseTitle: caseData.title
      })

  return (
    <Tooltip title={title} onVisibleChange={onVisibleChange}>
      {variant === 'icon' ? (
        <span onClick={redirectToCase} className={cls(styles.icon, baseClassName)}>
          {icon}
        </span>
      ) : (
        <div onClick={redirectToCase} className={cls(styles.caseLabel, baseClassName)}>
          {t('chat.message.caseLabel', { caseId: caseData.id })}
        </div>
      )}
    </Tooltip>
  )
}
