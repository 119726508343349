import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TGetMapComponentProps } from 'App/containers/Meetings'
import { Avatar } from 'App/components/common'

import { CallInfo, Duration, Header, Organizer, Button, Wrapper } from '../common'

type TP2PMeetingCallProps = Omit<
  TGetMapComponentProps,
  'meetingCase' | 'answerersMap' | 'isMyActiveCall'
>

export const P2PMeetingCall = ({
  meeting,
  accountId,
  durationMs,
  ableToSwitch,
  isOnCurrentCall,
  onMeetingActionClick
}: TP2PMeetingCallProps) => {
  const { t } = useTranslation()

  if (!meeting) {
    return null
  }

  const { organizer, title, startOn, endOn } = meeting
  const {
    id,
    lastName,
    firstName,
    displayUserName,
    type: { name: typeName }
  } = organizer

  const isOrganizer = organizer.id === accountId
  const organizerName = isOrganizer ? t('meetings.userIndicatorLabel') : organizer?.displayUserName

  return (
    <Wrapper
      avatar={
        <Avatar
          size={EAvatarSize.MD}
          userId={id}
          type={typeName}
          lastName={lastName}
          firstName={firstName}
          displayUserName={displayUserName}
        />
      }
      content={
        <>
          <Header title={title} />
          <Duration startOn={startOn} endOn={endOn} />
          <Organizer name={organizerName} />
          <CallInfo
            isOnCurrentCall={isOnCurrentCall}
            ableToSwitch={ableToSwitch}
            durationMs={durationMs}
            isMyActiveCall={true}
          />
        </>
      }
      action={
        <Button
          isOnCurrentCall={isOnCurrentCall}
          ableToSwitch={ableToSwitch}
          onClick={onMeetingActionClick}
        />
      }
    />
  )
}
