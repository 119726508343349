import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseCloudFilesItem, TCaseCloudFilesItemBaseProps } from 'App/components'
import { createSlidesEntity } from 'store'

export type TCaseCloudSharedWithMeFilesItemContainer = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, { id }: TCaseCloudFilesItemBaseProps) => {
  const { ids, list } = state.caseCloud.sharedWithMeFiles
  const { owner, fileName, extension, thumbnailUrl, sharedAt, mimeType } = list[id] || {}

  return {
    extension,
    fileName,
    fileOwner: owner,
    thumbnailUrl,
    sharedAt,
    videoMetadata: state.videoStreaming.list[id]?.videoMetadata,
    mimeType,
    slidesEntity: createSlidesEntity(ids, list)
  }
}

export const CaseCloudSharedWithMeFilesItemContainer = connect(mapStateToProps)(CaseCloudFilesItem)
