import { useState } from 'react'
import { Col } from 'antd'
import { Field, FieldRenderProps } from 'react-final-form'
import { isBefore, isAfter, add } from 'date-fns'
import { RangePickerProps } from 'antd/es/date-picker/generatePicker'
import { RangeValue } from 'rc-picker/lib/interface'
import { useTranslation } from 'react-i18next'

import { rangeDate, validation } from 'utils'
import { TextField } from 'App/components'
import { RangeDatePicker } from 'App/components/common/Fields/DatePicker'
import { MAX_LENGTH_1000, MIN_PICKER_DATE } from 'globalConstants'

const MAX_PICKER_DATE = add(new Date(), { years: 10 })

export const UserProfileEducationDialogSecondStep = () => {
  const [firstDate, setFirstDate] = useState(false)

  const { t } = useTranslation()

  const handlePanelChange =
    (callback: RangePickerProps<Date>['onPanelChange']) => (value: RangeValue<Date>) => {
      if (!value?.every(Boolean)) {
        setFirstDate(true)
      }

      return callback
    }

  const handleOpenChange =
    (
      callback: RangePickerProps<Date>['onOpenChange'],
      value: FieldRenderProps<HTMLInputElement>['value']
    ) =>
    (open: boolean) => {
      if (!open && !!value) {
        setFirstDate(false)
      }

      return callback
    }

  const handleDisabledDate: RangePickerProps<Date>['disabledDate'] = (date) => {
    if (!firstDate) {
      return isAfter(date, new Date())
    }

    return isBefore(date, MIN_PICKER_DATE) || isAfter(date, MAX_PICKER_DATE)
  }

  return (
    <>
      <Col xs={24}>
        <Field
          name="date"
          validate={(value) =>
            validation.minDate(MIN_PICKER_DATE)(value ? value[0] : null) ||
            validation.maxDate(MAX_PICKER_DATE)(value ? value[1] : null)
          }
        >
          {({ input, meta }) => (
            <RangeDatePicker
              {...input}
              topLabel={t('modal.userEducation.form.dateField')}
              picker="month"
              defaultValue={input.value}
              format={rangeDate}
              onPanelChange={handlePanelChange(input.onPanelChange)}
              onOpenChange={handleOpenChange(input.onOpenChange, input.value)}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              disabledDate={handleDisabledDate}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field
          name="description"
          validate={validation.composeValidators(
            validation.onlySpaces(),
            validation.maxLength(MAX_LENGTH_1000)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              multiline
              rows={4}
              topLabel={t('modal.userEducation.form.descriptionField')}
              valueLengthMax={MAX_LENGTH_1000}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
