import { useCallback, useMemo, useState } from 'react'
import noop from 'lodash/noop'

import { useQuery, QueryFunction } from 'services/query'
import {
  TGetOrganizationMembersPayload,
  getOrganizationDepartments,
  getOrganizationMembers
} from 'api/organizations'
import { accountIdSelector, TOrganizationMember, useAppSelector } from 'store'
import { PAGINATION_DEFAULT } from 'types'
import { TSearchBy } from 'api/contacts'
import { TAccountsSelectGroupsItem } from 'App/components/common/AccountsSelect/AccountsSelectGroups'
import { EAccountsSelectType } from 'App/components/common/AccountsSelect'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

type TUseRequest = Pick<
  TGetOrganizationMembersPayload,
  'markChatIds' | 'markDepartmentIds' | 'markVoiceRoomIds'
> & {
  organizationId: string

  isLeaderInDepartments?: boolean
  channelManagerId?: string
}

export const useRequest = ({
  organizationId,
  isLeaderInDepartments,
  markChatIds,
  markVoiceRoomIds,
  channelManagerId
}: TUseRequest) => {
  const accountId = useAppSelector(accountIdSelector)

  const [search, setSearch] = useState('')
  const [departmentId, setDepartmentId] = useState<string | undefined | null>()
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState(0)
  const [selectType, setSelectType] = useState<EAccountsSelectType>(EAccountsSelectType.BY_USER)

  const isChannelManager = channelManagerId === accountId

  const excludeAccountIds = useMemo(
    () => (isLeaderInDepartments && !isChannelManager && accountId ? [accountId] : undefined),
    [isLeaderInDepartments, isChannelManager, accountId]
  )

  const queryFn: QueryFunction<TOrganizationMember[]> = async () => {
    const data = await getOrganizationMembers({
      page,
      search,
      departmentIds: departmentId ? [departmentId] : [],
      organizationId,
      excludeAccountIds,
      showBy: PAGINATION_DEFAULT.showBy,
      markChatIds,
      markVoiceRoomIds
    })

    setTotal(data.total)

    return data.results
  }

  const {
    data: result,
    isInitialLoading: isLoading,
    error
  } = useQuery({
    queryKey: ['text-channel-select-members', page, search, excludeAccountIds, departmentId],
    queryFn,
    cacheTime: 0,
    enabled: selectType === EAccountsSelectType.BY_USER,
    onError: noop,
    select: (data) =>
      data.map<TAccountsSelectOptionData>(
        ({ account: { firstName, lastName, displayUserName, id, type } }) => ({
          id,
          firstName,
          lastName,
          type,
          displayUserName
        })
      )
  })

  const {
    data: departments,
    isInitialLoading: isDepartmentsLoading,
    error: departmentsError
  } = useQuery({
    queryKey: ['text-channel-select-departments', search, organizationId],
    queryFn: () =>
      getOrganizationDepartments({
        search,
        organizationId,
        showBy: Number.MAX_SAFE_INTEGER,
        markChatIds,
        markVoiceRoomIds
      }),
    cacheTime: 0,
    enabled: selectType === EAccountsSelectType.BY_GROUP,
    onError: noop,
    select: (data) =>
      data.results?.map<TAccountsSelectGroupsItem>(
        ({ id, name, isChatsParticipant, isVoiceRoomsParticipant }) => ({
          label: name,
          value: id,
          selected: Boolean(isChatsParticipant || isVoiceRoomsParticipant)
        })
      )
  })

  const handleSearchBy = useCallback((searchBy: TSearchBy) => {
    setDepartmentId(searchBy.id)
  }, [])

  return {
    showBy: PAGINATION_DEFAULT.showBy,
    results: result ?? [],
    total,
    loading: isLoading,
    search,
    page,
    error,
    departments,
    isDepartmentsLoading,
    departmentsError,
    departmentId,
    handleSearch: setSearch,
    setPage,
    handleSearchBy,
    setSelectType
  }
}
