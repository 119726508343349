import { call, put, select, takeLatest, cancel } from 'redux-saga/effects'
import {
  BusinessAccountStatusEnum,
  BusinessAccountRoleIdEnum,
  PlatformInvitationType
} from '@medentee/enums'
import { toast } from 'react-toastify'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import { normalizeList } from 'store/utils'
import history from 'utils/history'
import { QueryBuilder, ContactsQueryBuilder } from 'utils'
import { State } from 'redux/rootReducer'
import { IInvitationByEmailDTO, IOrganizationRegisteredUsers } from 'interfaces'
import { EContactsSort, EBusinessAdminConfirmFrom, EOrganizationMemberSort, ESorting } from 'enums'
import { toastDefaultOptions } from 'globalConstants'
import { IVoiceRoomDTO } from 'interfaces/api/organizations/voiceRoom'
import {
  ESocketEmitEventNamesCall,
  ESocketNameSpaces,
  wrapperForSocketEmit
} from 'services/webSocket'
import { TModalState } from 'store/modal'
import { createProcessingSelector } from 'store/loading'
import { hideModalAction, logoutRequest, originalAccountIdSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

import {
  getOrganizationsInvitationsFiltersSelector,
  getIsParticipantsLimitReachedSelector,
  getIsVoiceRoomParticipantSelector,
  getOrganizationBasePathSelector
} from './organizations.selectors'
import {
  TOrganization,
  TOrganizationMember,
  TOrganizationMembers,
  TOrganizationsInvitationsFilters
} from './organizations.types'
import * as actions from './organizations.actions'
import * as actionTypes from './organizations.actionTypes'

function* getOrganizationsSaga({ payload }: ReturnType<typeof actions.getOrganizationsRequest>) {
  try {
    const url = new QueryBuilder(API.ORGANIZATIONS).sortBy(payload?.sortBy ?? 'INVITED_AT').build()

    const {
      data: { results, total }
    }: APIResultsResponse<TOrganization[]> = yield call(api.get, url)

    const normalizeOrganization = normalizeList(results)

    yield put(
      actions.getOrganizationsSuccess({
        ...normalizeOrganization,
        total
      })
    )

    if (payload?.onSuccess) {
      payload.onSuccess()
    }
  } catch (e) {
    yield put(actions.getOrganizationsError(e))
  }
}

function* getOrganizationMembers({
  payload: {
    id,
    sortBy = EOrganizationMemberSort.USER_NAME,
    sortOrder = ESorting.ASC,
    withPagination
  }
}: ReturnType<typeof actions.getOrganizationMembersRequest>) {
  try {
    const {
      list,
      filters: { search, current, showBy }
    }: TOrganizationMembers = yield select((state: State) => state.organizations.members)

    const urlBuilder = new QueryBuilder(API.ORGANIZATION_MEMBERS(id))
      .searchQuery(search)
      .page(current)
      .showBy(showBy)
      .sort(sortOrder, sortBy)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<TOrganizationMember[]> = yield call(api.get, urlBuilder)

    yield put(
      actions.getOrganizationMembersSuccess({
        list: !!current && !withPagination ? [...list, ...results] : results,
        total
      })
    )
  } catch (e) {
    yield put(actions.getOrganizationMembersError(e))
  }
}

function* getOrganizationRegisteredUsersSaga({
  payload: {
    direction,
    statuses = [
      BusinessAccountStatusEnum.APPROVED,
      BusinessAccountStatusEnum.REJECTED,
      BusinessAccountStatusEnum.PENDING
    ]
  }
}: ReturnType<typeof actions.getOrganizationRegisteredUsersRequest>) {
  try {
    const { showBy, current }: TOrganizationsInvitationsFilters = yield select(
      getOrganizationsInvitationsFiltersSelector
    )

    const url = new ContactsQueryBuilder(API.BUSINESS_USERS)
      .withoutHidden(true)
      .sortBy(EContactsSort.CREATED_AT)
      .multiSearch('statuses', statuses)
      .multiSearch('roleIds', [BusinessAccountRoleIdEnum.STAFF])
      .showBy(showBy)
      .page(current)
      .custom('directions', direction)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<IOrganizationRegisteredUsers[]> = yield call(api.get, url)

    yield put(
      actions.getOrganizationRegisteredUsersSuccess({
        ...normalizeList<IOrganizationRegisteredUsers>(results),
        current,
        total
      })
    )
  } catch (e) {
    yield put(actions.getOrganizationRegisteredUsersError(e))
  }
}

function* createOrganizationChatSaga({
  payload: { partnerAccountId, onSuccess }
}: ReturnType<typeof actions.createOrganizationChatRequest>) {
  try {
    const { data }: APIData<{ chatId: string }> = yield call(
      api.post,
      API.ORGANIZATIONS_CHATS(partnerAccountId)
    )

    if (onSuccess) {
      onSuccess(data.chatId)
    }
    yield put(actions.createOrganizationChatSuccess())
  } catch (e) {
    yield handleError(e)
    yield put(actions.createOrganizationChatError(e))
  }
}

function* getVoiceRoomsSaga({ payload }: ReturnType<typeof actions.getVoiceRoomsRequest>) {
  try {
    const { data }: APIData<IVoiceRoomDTO[]> = yield call(
      api.get,
      API.ORGANIZATIONS_VOICE_CHANNELS(payload.organizationId)
    )

    yield put(actions.getVoiceRoomsSuccess(normalizeList(data)))
  } catch (e) {
    handleError(e)
    yield put(actions.getVoiceRoomsError(e))
  }
}

function* joinVoiceRoomSaga({ payload }: ReturnType<typeof actions.joinVoiceRoomRequest>) {
  try {
    const isParticipantsLimitReached: boolean = yield select(
      getIsParticipantsLimitReachedSelector(payload.voiceRoomId)
    )

    if (isParticipantsLimitReached) {
      toast.warn(i18n.t('common.toast.voiceChannelLimitReached'), toastDefaultOptions)
      yield cancel()
    }

    const activeVoiceRoomId: string | undefined = yield select(
      (state: State) => state.organizations.activeVoiceRoom?.id
    )

    if (activeVoiceRoomId) {
      yield put(actions.leaveVoiceRoomRequest({ voiceRoomId: activeVoiceRoomId }))
    }

    const data: IVoiceRoomDTO = yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CALL,
      ESocketEmitEventNamesCall.JOIN_VOICE_ROOM,
      payload
    )

    yield put(actions.joinVoiceRoomSuccess(data))
  } catch (e) {
    handleError(e)
    yield put(actions.joinVoiceRoomError(e))
  }
}

function* leaveVoiceRoomSaga({ payload }: ReturnType<typeof actions.leaveVoiceRoomRequest>) {
  try {
    yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CALL,
      ESocketEmitEventNamesCall.LEAVE_VOICE_ROOM,
      payload
    )

    yield put(actions.leaveVoiceRoomSuccess(payload))
  } catch (e) {
    handleError(e)
    yield put(actions.leaveVoiceRoomError(e))
  }
}

function* closeVoiceRoomSaga({
  payload: { voiceRoomId }
}: ReturnType<typeof actions.receiveJoinVoiceRoom>) {
  const isJoiningAnotherVoiceRoom: boolean = yield select(
    createProcessingSelector([actionTypes.JOIN_VOICE_ROOM])
  )
  const isLeavingAnotherVoiceRoom: boolean = yield select(
    createProcessingSelector([actionTypes.LEAVE_VOICE_ROOM])
  )
  const { id, accounts }: IVoiceRoomDTO = yield select(
    (state: State) => state.organizations.voiceRooms.list[voiceRoomId] ?? {}
  )

  const isVoiceRoomParticipant: boolean = yield select(getIsVoiceRoomParticipantSelector(accounts))

  if (!isJoiningAnotherVoiceRoom && !isLeavingAnotherVoiceRoom && isVoiceRoomParticipant) {
    yield put(actions.closeVoiceRoom({ voiceRoomId: id }))
    yield toast.warn(i18n.t('common.toast.unavailableChat_channel'), toastDefaultOptions)
  }
}

function* getOrganizationsInvitationsSaga({
  payload: { markRead, from, organizationId, direction, onSuccess }
}: ReturnType<typeof actions.getOrganizationsInvitationsRequest>) {
  try {
    const { showBy, current }: TOrganizationsInvitationsFilters = yield select(
      getOrganizationsInvitationsFiltersSelector
    )

    const isAdminRoles = from === EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION

    const url = new ContactsQueryBuilder(API.BUSINESS_USERS)
      .withoutHidden(true)
      .sortBy(EContactsSort.CREATED_AT)
      .multiSearch('statuses', [BusinessAccountStatusEnum.PENDING])
      .multiSearch(
        'roleIds',
        isAdminRoles
          ? [BusinessAccountRoleIdEnum.ADMIN, BusinessAccountRoleIdEnum.SUPERADMIN]
          : [BusinessAccountRoleIdEnum.STAFF]
      )
      .multiSearch('organizationIds', isAdminRoles && organizationId ? [organizationId] : undefined)
      .showBy(showBy)
      .page(current)
      .custom('directions', direction)
      .stringify({ markRead })
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<IOrganizationRegisteredUsers[]> = yield call(api.get, url)

    yield put(
      actions.getOrganizationsInvitationsSuccess({
        ...normalizeList<IOrganizationRegisteredUsers>(results),
        current,
        total
      })
    )

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(actions.getOrganizationsInvitationsError(e))
  }
}

function* getOrganizationAdministrations() {
  try {
    const { showBy, current }: TOrganizationsInvitationsFilters = yield select(
      getOrganizationsInvitationsFiltersSelector
    )

    const url = new ContactsQueryBuilder(API.BUSINESS_USERS)
      .withoutHidden(true)
      .sortBy(EContactsSort.CREATED_AT)
      .showBy(showBy)
      .page(current)
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<IOrganizationRegisteredUsers[]> = yield call(api.get, url)

    yield put(
      actions.getOrganizationAdministrationsSuccess({
        ...normalizeList<IOrganizationRegisteredUsers>(results),
        current,
        total
      })
    )
  } catch (e) {
    yield put(actions.getOrganizationAdministrationsError(e))
    yield handleError(e)
  }
}

function* getOrganizationNewUsersSaga() {
  try {
    const { showBy, current }: TOrganizationsInvitationsFilters = yield select(
      getOrganizationsInvitationsFiltersSelector
    )

    const url = new ContactsQueryBuilder(API.INVITATIONS_BY_EMAIL)
      .multiSearch('types', [PlatformInvitationType.STAFF])
      .page(current)
      .showBy(showBy)
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<IInvitationByEmailDTO[]> = yield call(api.get, url)

    yield put(
      actions.getOrganizationNewUsersSuccess({
        ...normalizeList<IInvitationByEmailDTO>(results),
        current,
        total
      })
    )
  } catch (e) {
    yield put(actions.getOrganizationNewUsersError(e))
    yield handleError(e)
  }
}

function* kickUserFromVoiceRoomSaga({
  payload: { voiceRoomId }
}: ReturnType<typeof actions.kickUserFromVoiceRoom>) {
  toast.info(i18n.t('common.toast.alineInVoiceChannel'), toastDefaultOptions)

  yield put(actions.closeVoiceRoom({ voiceRoomId }))
}

function* inviteAllOrganizationMembersSaga({
  payload: { onSuccess, organizationId }
}: ReturnType<typeof actions.inviteAllOrganizationMembersRequest>) {
  try {
    yield call(api.post, API.ORGANIZATION_INVITE_ALL_MEMBERS(organizationId))

    yield put(actions.inviteAllOrganizationMembersSuccess())
    yield put(hideModalAction())
    yield put(actions.setOrganizationMemberCurrentPage({ current: 0 }))
    yield put(actions.getOrganizationMembersRequest({ id: organizationId }))
    yield toast.success(i18n.t('common.toast.invitationSent'), toastDefaultOptions)
    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(actions.inviteAllOrganizationMembersError(e))
    yield handleError(e)
  }
}

function* receiveRemovedFromAdminSaga({
  payload: { accountId }
}: ReturnType<typeof actions.receiveRemoveFromAdmin>) {
  try {
    const originalAccountId: string = yield select(originalAccountIdSelector)

    if (originalAccountId === accountId) {
      yield put(logoutRequest())
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveLostLeaderRightsInDepartmentsSaga({
  payload
}: ReturnType<typeof actions.receiveLostLeaderRightsInDepartments>) {
  const currentModal: TModalState = yield select((state: State) => state.modal)
  yield put(
    actions.updateOrganization({
      id: payload.organizationId,
      isLeaderInDepartments: false
    })
  )

  if (
    currentModal.modalType !== EModalComponents.CREATE_TEXT_CHANNEL ||
    (currentModal.modalProps?.hasOwnProperty('organizationId') &&
      currentModal.modalProps?.organizationId !== payload.organizationId)
  ) {
    yield cancel()
    return
  }

  yield put(hideModalAction())
  yield toast.warn(
    i18n.t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
    toastDefaultOptions
  )
}

function* receiveDelegatedLeaderRightsInDepartmentsSaga({
  payload
}: ReturnType<typeof actions.receiveDelegatedLeaderRightsInDepartments>) {
  yield put(
    actions.updateOrganization({
      id: payload.organizationId,
      isLeaderInDepartments: true
    })
  )
}

function* receiveAccessToVoiceChannelSaga({
  payload,
  type
}: ReturnType<typeof actions.receiveLostAccessToVoiceChannel>) {
  const organizationPath: string = yield select(
    getOrganizationBasePathSelector(payload.organizationId)
  )
  const activeVoiceRoomId: string | undefined = yield select(
    (state: State) => state.organizations.activeVoiceRoom?.id
  )

  const isCurrentOrganization = history.location.pathname.includes(organizationPath)

  if (
    activeVoiceRoomId === payload.voiceRoomId &&
    type === actionTypes.RECEIVE_LOST_ACCESS_TO_VOICE_CHANNEL
  ) {
    yield toast.warn(i18n.t('common.toast.unavailableChat_channel'), toastDefaultOptions)
  }

  if (isCurrentOrganization) {
    yield put(
      actions.getVoiceRoomsRequest({
        organizationId: payload.organizationId
      })
    )
  }
}

export function* organizationsSaga() {
  yield takeLatest(actionTypes.GET_ORGANIZATIONS_REQUEST, getOrganizationsSaga)
  yield takeLatest(actionTypes.GET_ORGANIZATIONS_MEMBERS_REQUEST, getOrganizationMembers)

  yield takeLatest(
    actionTypes.GET_ORGANIZATION_REGISTERED_USERS_REQUEST,
    getOrganizationRegisteredUsersSaga
  )
  yield takeLatest(actionTypes.CREATE_ORGANIZATION_CHAT_REQUEST, createOrganizationChatSaga)
  yield takeLatest(actionTypes.JOIN_VOICE_ROOM_REQUEST, joinVoiceRoomSaga)
  yield takeLatest(actionTypes.LEAVE_VOICE_ROOM_REQUEST, leaveVoiceRoomSaga)
  yield takeLatest(actionTypes.GET_VOICE_ROOMS_REQUEST, getVoiceRoomsSaga)
  yield takeLatest(
    [actionTypes.RECEIVE_JOIN_VOICE_ROOM, actionTypes.RECEIVE_LEAVE_VOICE_ROOM],
    closeVoiceRoomSaga
  )
  yield takeLatest(
    actionTypes.GET_ORGANIZATIONS_INVITATIONS_REQUEST,
    getOrganizationsInvitationsSaga
  )
  yield takeLatest(
    actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_REQUEST,
    getOrganizationAdministrations
  )
  yield takeLatest(actionTypes.GET_ORGANIZATION_NEW_USERS_REQUEST, getOrganizationNewUsersSaga)
  yield takeLatest(actionTypes.KICK_USER_FROM_VOICE_ROOM, kickUserFromVoiceRoomSaga)
  yield takeLatest(
    actionTypes.INVITE_ALL_ORGANIZATION_MEMBERS_REQUEST,
    inviteAllOrganizationMembersSaga
  )
  yield takeLatest(actionTypes.RECEIVE_REMOVED_FROM_ADMIN, receiveRemovedFromAdminSaga)
  yield takeLatest(
    actionTypes.RECEIVE_LOST_LEADER_RIGHTS_IN_DEPARTMENTS,
    receiveLostLeaderRightsInDepartmentsSaga
  )
  yield takeLatest(
    actionTypes.RECEIVE_DELEGATED_LEADER_RIGHTS_IN_DEPARTMENTS,
    receiveDelegatedLeaderRightsInDepartmentsSaga
  )

  yield takeLatest(
    [
      actionTypes.RECEIVE_LOST_ACCESS_TO_VOICE_CHANNEL,
      actionTypes.RECEIVE_ADDED_USER_TO_VOICE_CHANNEL
    ],
    receiveAccessToVoiceChannelSaga
  )
}
