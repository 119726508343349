import { useCallback } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  hideModalAction,
  joinToCallRequest,
  JOIN_TO_CALL,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ActiveCallLabel } from 'App/components/Meetings/common'
import { Button } from 'App/components'

import styles from './Join.module.scss'

export type TJoinProps = {
  callId: string
}

const processingSelector = createProcessingBySelector([JOIN_TO_CALL])

export const Join = ({ callId }: TJoinProps) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector(callId))
  const activeCallId = useAppSelector((state) => state.calls.answer.createCall?.id)

  const { t } = useTranslation()

  const isOnCurrentCall = callId === activeCallId

  const handleJoinToMeeting = useCallback(() => {
    dispatch(
      joinToCallRequest({
        callId,
        processingId: callId,
        onSuccess: () => {
          dispatch(hideModalAction())
        }
      })
    )
  }, [dispatch, callId])

  return isOnCurrentCall ? (
    <ActiveCallLabel className={cls(styles.root, styles.label)} isOnCurrentCall />
  ) : (
    <div className={styles.root}>
      <span className={styles.label}>{t('modal.meetings.meetingAvailableLabel')}</span>
      <Button
        onClick={handleJoinToMeeting}
        variant="underlined"
        adaptive={false}
        loading={processing}
      >
        {t('modal.meetings.joinButton')}
      </Button>
    </div>
  )
}
