import { call, delay, put, takeEvery, takeLatest, select } from 'redux-saga/effects'
import { FileHistorySourcesEnum, ErrorCodesEnum } from '@medentee/enums'
import sum from 'lodash/sum'
import uniq from 'lodash/uniq'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import { CloudFilesQueryBuilder, isMatchErrorCode, QueryBuilder } from 'utils'
import { IFileContactPermissionObject, ICasesCloudFile } from 'interfaces'
import { State } from 'redux/rootReducer'
import {
  hideModalAction,
  setVideoStreamingData,
  videoStreamingNormalize,
  TFilesFilters,
  TFilesCounts,
  TIds
} from 'store'
import { SortingTable } from 'types'
import { handleError } from 'api/utils'

import {
  CHANGE_FILE_SHARED_CONTACT_PERMISSION_REQUEST,
  changeFileSharedContactPermissionSuccess,
  GET_FILE_SHARED_LIST_REQUEST,
  getFileSharedListRequest,
  RENAME_FILE_SHARED_REQUEST,
  renameFileSharedError,
  renameFileSharedSuccess,
  setFileSharedIds,
  setFileSharedList,
  setFileSharedPagination,
  setFileSharedFiltersRequest,
  setFileSharedFiltersSuccess,
  setFileSharedSorting,
  DISCARD_FILE_SHARED_PERMISSION_REQUEST,
  discardFileSharedPermissionError,
  discardFileSharedPermissionSuccess,
  SET_FILE_SHARED_LIST_FILTERS_REQUEST,
  changeFileSharedContactPermissionError,
  getFileSharedListSuccess,
  getFileSharedListError,
  GET_FILES_SHARED_COUNTS_REQUEST,
  getFilesSharedCountsError,
  getFilesSharedCountsSuccess
} from './files.actions'
import { fileSharedNormalize, TFileSharedNormalized } from './files.normalization'
import { FILE_SHARED_LIST_DEFAULT_PAGINATION } from './files.reducer'
import {
  TChangeFileSharedContactPermissionRequest,
  TDiscardFileSharedPermissionRequest,
  TFileSharedList,
  TGetFileSharedListRequest,
  TGetFilesSharedCountsRequest,
  TRenameFileSharedRequest,
  TSetFileSharedFiltersRequest
} from './files.types'

function* getFileSharedListSaga({ payload }: TGetFileSharedListRequest) {
  const currentFilters: TFilesFilters = yield select((state: State) => state.fileShared.filters)
  const currentSorting: SortingTable = yield select((state: State) => state.fileShared.sorting)
  const currentIds: TIds = yield select((state: State) => state.fileShared.ids)
  const currentFileList: TFileSharedList = yield select((state: State) => state.fileShared.fileList)

  try {
    const {
      ownerId,
      permissionSpecificationScope,
      permissionUserIdScope,
      chatId,
      extensionCategories,
      loadMore,
      pagination = FILE_SHARED_LIST_DEFAULT_PAGINATION
    } = payload
    const filters = {
      ...currentFilters,
      ...payload.filters
    }
    const sorting = {
      ...currentSorting,
      ...payload.sorting
    }

    const queryBuilderUrl = new CloudFilesQueryBuilder(API.CLOUD_FILES)
      .searchQuery(filters?.search)
      .page(filters?.page)
      .sortFiles(sorting?.direction, sorting?.name)
      .showBy(pagination.showBy)
      .permissionSpecificationScope(permissionSpecificationScope)
      .permissionUserIdScope(permissionUserIdScope)
      .extensionCategories(extensionCategories ?? filters?.extensionCategories)
      .fileOwnerId(ownerId)
      .chatId(chatId)
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<ICasesCloudFile[]> = yield call(api.get, queryBuilderUrl)

    const { fileList, ids }: TFileSharedNormalized = fileSharedNormalize(results)

    const normalizedData: TFileSharedNormalized =
      loadMore && ids.length
        ? {
            fileList: {
              ...currentFileList,
              ...fileList
            },
            ids: uniq([...currentIds, ...ids])
          }
        : { fileList, ids }

    yield put(setVideoStreamingData(videoStreamingNormalize(results)))
    yield put(setFileSharedList({ fileList: normalizedData.fileList }))
    yield put(setFileSharedIds({ ids: normalizedData.ids }))
    yield put(setFileSharedFiltersRequest({ ...filters, total }))
    yield put(setFileSharedPagination({ pagination }))
    yield put(setFileSharedSorting({ sorting }))
    yield put(getFileSharedListSuccess())
  } catch (e) {
    yield put(getFileSharedListError())
    handleError(e)
  }
}

function* changeFileSharedContactPermissions({
  payload: { fileId, userId, enabled, permissionId }
}: TChangeFileSharedContactPermissionRequest) {
  try {
    const { data }: APIData<IFileContactPermissionObject[]> = yield call(
      api.patch,
      `${API.FILE_CONTACT_CHANGE_PERMISSION(fileId)}`,
      {
        permissionId,
        userId,
        enabled
      }
    )

    yield delay(300)

    const permissions = (data || []).map((permission) => permission.id)

    yield put(
      changeFileSharedContactPermissionSuccess({
        userId,
        permissions,
        processingId: userId
      })
    )
  } catch (e) {
    handleError(e)
    yield put(changeFileSharedContactPermissionError({ ...e, processingId: userId }))
  }
}

function* renameFileSharedSaga({ payload: { fileId, fileName } }: TRenameFileSharedRequest) {
  try {
    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )

    yield call(api.patch, API.FILE_EDIT(fileId), {
      source: FileHistorySourcesEnum.CASE_CLOUD,
      fileName
    })

    yield put(renameFileSharedSuccess())
    yield put(hideModalAction())
    yield put(
      getFileSharedListRequest({
        permissionUserIdScope: accountId
      })
    )
  } catch (e) {
    yield put(renameFileSharedError(e))
  }
}

function* discardFileSharedPermissionSaga({
  payload: { fileId, onSuccess, onError }
}: TDiscardFileSharedPermissionRequest) {
  try {
    yield call(api.delete, API.FILE_DISCARD_PERMISSIONS(fileId))

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(discardFileSharedPermissionSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield put(discardFileSharedPermissionError(e))
    if (isMatchErrorCode(e, ErrorCodesEnum.FILE_NOT_FOUND)) {
      handleError(e)
      yield put(hideModalAction())

      if (onError) {
        yield call(onError)
      }
    }
  }
}

function* setFileSharedFiltersSaga({ payload }: TSetFileSharedFiltersRequest) {
  try {
    yield put(setFileSharedFiltersSuccess({ ...payload }))
  } catch (e) {
    handleError(e)
  }
}

function* getFilesSharedCountsSaga({
  payload: {
    searchQuery,
    caseId,
    ownerId,
    permissionSpecificationScope,
    permissionUserIdScope,
    chatId
  }
}: TGetFilesSharedCountsRequest) {
  try {
    const url = new QueryBuilder(API.CLOUD_COUNTS)
      .searchQuery(searchQuery)
      .custom('ownerId', ownerId)
      .custom('caseId', caseId)
      .custom('chatId', chatId)
      .custom('permissionSpecificationScope[]', permissionSpecificationScope)
      .custom('permissionUserIdScope', permissionUserIdScope)
      .build()

    const { data }: APIData<TFilesCounts> = yield call(api.get, url)

    const payload = {
      counts: data,
      total: sum(Object.values(data))
    }

    yield put(getFilesSharedCountsSuccess(payload))
  } catch (e) {
    yield put(getFilesSharedCountsError(e))
  }
}

export function* fileSharedListSaga() {
  yield takeLatest(GET_FILE_SHARED_LIST_REQUEST, getFileSharedListSaga)
  yield takeEvery(CHANGE_FILE_SHARED_CONTACT_PERMISSION_REQUEST, changeFileSharedContactPermissions)
  yield takeLatest(RENAME_FILE_SHARED_REQUEST, renameFileSharedSaga)
  yield takeEvery(DISCARD_FILE_SHARED_PERMISSION_REQUEST, discardFileSharedPermissionSaga)
  yield takeLatest(SET_FILE_SHARED_LIST_FILTERS_REQUEST, setFileSharedFiltersSaga)
  yield takeLatest(GET_FILES_SHARED_COUNTS_REQUEST, getFilesSharedCountsSaga)
}
