import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseCloudFileDetails, TCaseCloudFileDetailsBaseProps } from 'App/components'
import {
  getCaseContactPermissionsRequest,
  GET_CASE_CLOUD_CONTACT_PERMISSION,
  setCaseCloudActiveFileId
} from 'store/caseCloud'
import { createLoadingSelector, GET_CASE_MEMBERS } from 'store'

export type TCaseCloudFileDetailsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_CASE_CLOUD_CONTACT_PERMISSION, GET_CASE_MEMBERS])

const mapStateToProps = (state: State, { isMember }: TCaseCloudFileDetailsBaseProps) => {
  const { fileDetailsId } = state.caseCloud.cloudFiles

  const fileDetails = fileDetailsId ? state.caseCloud.cloudFiles.list[fileDetailsId] : null
  const fileMemberDetails = fileDetailsId
    ? state.caseCloud.uploadedByMeFiles.list[fileDetailsId]
    : null

  return {
    fileDetails: isMember ? fileMemberDetails : fileDetails,
    caseStatus: state.caseView.data?.status,
    caseClassification: state.caseView.data?.classification.name,
    activeIds: state.caseCloud.permissionContacts.activeIds,
    inactiveIds: state.caseCloud.permissionContacts.inactiveIds,
    loading: loadingSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeFileDetails: setCaseCloudActiveFileId,
      getCaseContactPermissions: getCaseContactPermissionsRequest
    },
    dispatch
  )

export const CaseCloudFileDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseCloudFileDetails)
