import { Row } from 'antd'
import { useHistory } from 'react-router'
import cls from 'classnames'

import { IEventDTO } from 'interfaces'
import { EAvatarSize } from 'enums'
import { Avatar } from 'App/components/common'
import { accountIdSelector, useAppSelector } from 'store'

import styles from './EventsInfoMembers.module.scss'

export type TEventsInfoMembersProps = PartialBy<
  Pick<IEventDTO, 'attendees' | 'isEventMember' | 'organizerId' | 'id'>,
  'isEventMember'
>

export const EventsInfoMembers = ({
  attendees,
  isEventMember,
  organizerId,
  id: eventId
}: TEventsInfoMembersProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const { push } = useHistory()

  const accessible = isEventMember || organizerId === accountId

  const handleClick = () => {
    if (accessible) {
      push(`/events/${eventId}/highlights/networking`)
    }
  }

  return (
    <Row className={cls(styles.root, { [styles.accessible]: accessible })} onClick={handleClick}>
      {attendees.map(({ account: { id, displayUserName, lastName, firstName, type } }) => (
        <Avatar
          key={id}
          userId={id}
          size={EAvatarSize.XXS}
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          type={type.name}
          hasTooltip={false}
          border={false}
          online={false}
        />
      ))}
    </Row>
  )
}
