import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg'
import { TChatEditingMessageContainerProps, ChatMessageReferenceContainer } from 'App/containers'
import { EChatMessageAction } from 'enums'

import styles from './ChatEditingMessage.module.scss'

type TChatEditingMessageProps = TChatEditingMessageContainerProps

export const ChatEditingMessage = ({ message, type, onCancel }: TChatEditingMessageProps) => {
  const { t } = useTranslation()

  const handleCancel = useCallback(() => {
    if (message) {
      onCancel({ chatId: message?.chatId, messageId: message?.id })
    }
  }, [message, onCancel])

  if (!message || type !== EChatMessageAction.EDIT) {
    return null
  }

  return (
    <ChatMessageReferenceContainer
      classes={{ root: styles.root }}
      message={message}
      title={t('chat.editingMessageTitle')}
      icon={ShareIcon}
      onCancel={handleCancel}
    />
  )
}
