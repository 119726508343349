import { Trans, useTranslation } from 'react-i18next'

import { TruncatedText } from 'App/components'

import { TOperationDetailsProps } from '../OperationDetails'

import styles from './Transfer.module.scss'

export type TTransferProps = Pick<TOperationDetailsProps, 'accountType' | 'displayUserName'>

export const Transfer = ({ accountType, displayUserName = '' }: TTransferProps) => {
  const { t } = useTranslation()

  return (
    <Trans
      t={t}
      i18nKey="payments.operationHistory.details.TRANSFER"
      context={accountType}
      components={{
        label: <span className={styles.text} />,
        name: (
          <TruncatedText
            classes={{ root: styles.name, text: styles.nameLabel }}
            text={displayUserName}
            wrapperString={['(', ')']}
          />
        )
      }}
    />
  )
}
