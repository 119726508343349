import { useCallback } from 'react'

import { useMutation, useQueryClient } from 'services/query'
import { hideModalAction, useAppDispatch } from 'store'
import { publishShowcase, TUpdateShowcaseDraftPayload, updateShowcaseDraft } from 'api/showcase'
import { IShowcaseItem } from 'interfaces'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

export const useRequest = () => {
  const dispatch = useAppDispatch()

  const queryClient = useQueryClient()

  const { mutate: update, isLoading } = useMutation({
    mutationFn: ({ hideModal, ...payload }) => updateShowcaseDraft(payload),
    onSuccess: (_: IShowcaseItem, { hideModal, onSuccess }: TUpdateShowcaseDraftPayload) => {
      if (onSuccess) {
        onSuccess()
        return
      }

      if (hideModal) {
        queryClient.invalidateQueries({ queryKey: ['showcases-feed'], type: 'active' })
        queryClient.invalidateQueries({ queryKey: ['my-showcases'], type: 'active' })
        dispatch(hideModalAction())
        toast.success('Draft saved', toastDefaultOptions)
      }
    }
  })

  const onUpdateShowcaseDraft = useCallback(update, [update])

  const { mutate: publish } = useMutation({
    mutationKey: ['publish-showcase'],
    mutationFn: publishShowcase,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['showcases-feed'], type: 'active' })
      queryClient.invalidateQueries({ queryKey: ['my-showcases'], type: 'active' })
      dispatch(hideModalAction())
      toast.success('Published successfully', toastDefaultOptions)
    }
  })

  const onPublishShowcase = useCallback(publish, [publish])

  return {
    processing: isLoading,
    onUpdateShowcaseDraft,
    onPublishShowcase
  }
}
