import { normalize, schema } from 'normalizr'

import { ICaseMember } from 'interfaces'

import {
  TCaseMembersNormalized,
  TCaseMembersNormalizeResult,
  TCaseMembersUsersNormalizeResult
} from './caseMembers.types'

const user = new schema.Entity('caseMemberAccountList')
const account = new schema.Entity('account', {
  account: user,
  users: [user]
})

const caseMembersSchema = new schema.Entity('caseMemberList')
const caseMembersListSchema = new schema.Array(caseMembersSchema)

export const caseMembersNormalize = (originalData: ICaseMember[]): TCaseMembersNormalized => {
  const {
    entities: { caseMemberList },
    result: caseMemberIds
  }: TCaseMembersNormalizeResult = normalize(originalData, caseMembersListSchema)

  const {
    entities: { caseMemberAccountList }
  }: TCaseMembersUsersNormalizeResult = normalize(originalData, new schema.Array(account))

  return {
    list: caseMemberList || {},
    ids: caseMemberIds || [],
    accountList: caseMemberAccountList || {}
  }
}
