import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { FC } from 'react'

import {
  createLoadingSelector,
  getLinkCaseListRequest,
  GET_LINK_CASE_LIST,
  removeErrorNotification,
  resetLinkCaseList,
  resetLinkCaseListFilter,
  setLinkCaseListFilter,
  setLinkCaseListSearch,
  showModalAction,
  TOGGLE_CASE_LINK
} from 'store'
import { State } from 'redux/rootReducer'
import {
  CaseLinkDialog,
  TCaseLinkDialogPropsBase
} from 'App/components/Case/CaseLinkDialog/CaseLinkDialog'

export type TCaseLinkDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_LINK_CASE_LIST])

const mapStateToProps = (state: State, { caseId }: TCaseLinkDialogPropsBase) => {
  const { classifications, roles, statuses } = state.cases.linkCases.filters
  const { title, humanReadableId } = state.cases.caseList[caseId] ?? state.caseView.data ?? {}

  return {
    roles,
    title,
    statuses,
    classifications,
    humanReadableId,
    search: state.cases.linkCases.filters.search,
    searchBy: state.cases.linkCases.filters.searchBy,
    ids: state.cases.linkCases.ids,
    loading: loadingSelector(state),
    error: state.errors[TOGGLE_CASE_LINK]
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getLinkCaseList: getLinkCaseListRequest,
      setLinkCaseListFilter,
      setLinkCaseListSearch,
      resetLinkCaseList,
      resetLinkCaseListFilter,
      removeErrorNotification,
      showModal: showModalAction
    },
    dispatch
  )

export const CaseLinkDialogContainer: FC<TCaseLinkDialogPropsBase> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseLinkDialog)
