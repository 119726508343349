import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TChatPinItem } from 'store'
import { EChatHistoryEvents } from 'enums'
import { TChatPinItemContainerProps } from 'App/containers'
import { FileImage, TruncatedText } from 'App/components'

import { useFormattedText } from '../../common/DraftEditor/FormattedText/useFormattedText'

import styles from './ChatPinItem.module.scss'

export type TChatPinItemBaseProps = {
  data: TChatPinItem | null | undefined
  chatId: string
  index: number

  onClickPin?: () => void
}

export type TChatPinItemProps = TChatPinItemContainerProps

export const ChatPinItem = ({ data, index, setEvent, onClickPin }: TChatPinItemProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    onClickPin && onClickPin()
    setEvent({
      type: EChatHistoryEvents.JUMP,
      payload: { messageId: data?.id ?? '', eventTs: new Date().getTime() }
    })
  }

  const message = useMemo(() => {
    if (data?.file) {
      return `${data.file.fileName}${data.file.extension}`
    }

    if (data?.audioFile) {
      return t('chat.pinnedMessages.item.audioMessage')
    }

    return data?.message
  }, [data, t])

  const text = useFormattedText({
    text: message,
    mentions: {
      accounts: data?.mentionedAccounts,
      departments: data?.mentionedDepartments
    }
  })
  const file = data?.file

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.content}>
        {file && (
          <div className={styles.contentFile}>
            <FileImage
              preview
              size="xss"
              fileId={file.id}
              thumbnailUrl={file.thumbnailUrl}
              extension={file.extension}
              fileName={file.fileName}
              className={styles.contentFileInner}
              imagePreviewSize={{ width: 30, height: 30 }}
            />
          </div>
        )}
        <div className={styles.contentBody}>
          <b className={styles.contentTitle}>
            {t('chat.pinnedMessages.item.pinnedMessageLabel', { index: index + 1 })}
          </b>
          <TruncatedText text={text} />
        </div>
      </div>
    </div>
  )
}
