import { memo } from 'react'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TChatRoomsType, useAppSelector } from 'store'
import { ChatRoomsContainer, ChatRoomsHeaderContainer, ChatTabBadgeContainer } from 'App/containers'
import { TabPane, Tabs, ChatEmptyContactList } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './ChatTabs.module.scss'

type TGetChatTabs = {
  loading: boolean
  lengthChatRooms: number
  chatType: TChatRoomsType
  accountType: AccountTypeNames
  onChangeTab: (activeKey: string) => void
}

type TTabTitleProps = {
  chatType: ChatTypeEnum.DIALOGUE | ChatTypeEnum.CASE_GROUP
}

const TabTitle = ({ chatType }: TTabTitleProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.tab}>
      {t(`chat.list.tabs.${chatType}`)}
      <ChatTabBadgeContainer itemKey={chatType} variant="dot" gutter={true} />
    </div>
  )
}

const ChatTabsView = ({
  chatType,
  lengthChatRooms,
  loading,
  onChangeTab,
  accountType
}: TGetChatTabs) => {
  const { isMobile } = useAdaptiveLayout()

  const search = useAppSelector((state) => state.chat.chatRooms.search)

  const showChatRoomsPlaceholder = !search && !lengthChatRooms && !loading && !isMobile

  return (
    <div className={styles.root}>
      <Tabs activeKey={chatType} onChange={onChangeTab} className={styles.tabs}>
        <TabPane tab={<TabTitle chatType={ChatTypeEnum.DIALOGUE} />} key={ChatTypeEnum.DIALOGUE} />
        <TabPane
          tab={<TabTitle chatType={ChatTypeEnum.CASE_GROUP} />}
          key={ChatTypeEnum.CASE_GROUP}
        />
      </Tabs>

      <ChatRoomsHeaderContainer />

      {showChatRoomsPlaceholder ? (
        <ChatEmptyContactList chatType={chatType} accountType={accountType} />
      ) : (
        <ChatRoomsContainer />
      )}
    </div>
  )
}

export const ChatTabs = memo(ChatTabsView)
