import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { TCaseAutoApproveDialogViewProps, CaseAutoApproveDialogItemContainer } from 'App/containers'
import {
  Button,
  ErrorModal,
  InfiniteScroll,
  CaseAutoApproveDialogEmptyList,
  SearchCascader
} from 'App/components'
import { generateRandomArray } from 'utils'
import { useContactsSearchByOptions } from 'App/hooks/SearchCascader/useContactsSearchByOptions'
import { TSearchBy } from 'api/contacts'

import styles from './CaseAutoApproveDialog.module.scss'

export type TCaseAutoApproveDialogProps = TCaseAutoApproveDialogViewProps

export const CaseAutoApproveDialog = ({
  ids,
  search,
  showBy,
  total,
  processing,
  page,
  contactsError,
  caseAutoApproveSettingsError,
  categoryId,
  loading,
  getContacts,
  setSearch,
  setCaseAutoApproveSettings,
  setCategory
}: TCaseAutoApproveDialogProps) => {
  const [contactIds, setContactIds] = useState<string[]>([])

  const { t } = useTranslation()

  const generatedIds = loading ? generateRandomArray(showBy) : ids

  const handleSearch = useCallback((value: string) => setSearch({ search: value }), [setSearch])
  const handleSwitch = useCallback(
    (checked: boolean, caseAutoApproveMode: boolean, id: string) => {
      const included = contactIds.includes(id)
      const include = () => setContactIds([...contactIds, id])
      const exclude = () => setContactIds(contactIds.filter((contactId) => contactId !== id))

      if (!caseAutoApproveMode && !included && checked) {
        // When disabled by default
        // Not included in array
        // The current action of the switch - on
        // Include in array
        return include()
      }

      if (!caseAutoApproveMode && included && !checked) {
        // When disabled by default
        // Included in the array when the switch is turned on
        // The current action of the switch - off
        // Exclude from the array
        return exclude()
      }

      if (caseAutoApproveMode && !included && !checked) {
        // When enabled by default
        // Not included in array
        // The current action of the switch - off
        // Include in array
        return include()
      }

      if (caseAutoApproveMode && included && checked) {
        // When enabled by default
        // Included in the array after the switch is turned on
        // The current action of the switch - on
        // Exclude from the array
        return exclude()
      }
    },
    [contactIds]
  )
  const handleSave = useCallback(
    () => setCaseAutoApproveSettings({ contactIds }),
    [contactIds, setCaseAutoApproveSettings]
  )
  const fetchMoreData = useCallback(
    (nextPage: number) => getContacts({ page: nextPage }),
    [getContacts]
  )

  const handleCascaderChange = useCallback(
    (searchBy: TSearchBy) => {
      setCategory({ categoryId: searchBy.id ?? '' })
    },
    [setCategory]
  )

  const { options, onCascaderChange } = useContactsSearchByOptions({
    onChange: handleCascaderChange
  })

  return (
    <div className={styles.root}>
      <p className={styles.description}>{t('modal.caseAutoApprove.content')}</p>

      <div className={styles.search}>
        <SearchCascader
          searchSize={EInputSize.L}
          placeholder={t('modal.caseAutoApprove.searchPlaceholder')}
          options={options}
          onSearchChange={handleSearch}
          onCascaderChange={onCascaderChange}
        />
      </div>

      <div className={styles.list}>
        {generatedIds.length ? (
          <InfiniteScroll
            next={fetchMoreData}
            height={230}
            total={total}
            page={page}
            dataLength={generatedIds.length}
            limit={showBy}
          >
            {generatedIds.map((id) => (
              <CaseAutoApproveDialogItemContainer id={id} key={id} onSwitch={handleSwitch} />
            ))}
          </InfiniteScroll>
        ) : (
          <CaseAutoApproveDialogEmptyList search={search} categoryId={categoryId} />
        )}
      </div>

      <Button
        className={styles.button}
        loading={processing}
        disabled={!contactIds.length}
        onClick={handleSave}
      >
        {t('modal.caseAutoApprove.submitButton')}
      </Button>

      <ErrorModal error={contactsError || caseAutoApproveSettingsError} />
    </div>
  )
}
