import { useLayoutEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { useAppSelector, getBroadcastSelectedFilesIdsSelector } from 'store'
import { StepsFooterActions } from 'App/components'
import { usePrevious } from 'App/hooks'

import { AttachedItem } from '../AttachedItem'
import { Grid } from '../Grid'

import styles from './AttachedFiles.module.scss'

export type TAttachedFilesProps = {
  onBack: () => void
}

export const AttachedFiles = ({ onBack }: TAttachedFilesProps) => {
  const ids = useAppSelector(getBroadcastSelectedFilesIdsSelector, isEqual)
  const prevIds = usePrevious(ids)

  useLayoutEffect(() => {
    if (prevIds?.length && !ids.length) {
      onBack()
    }
  }, [ids.length, onBack, prevIds?.length])

  return (
    <div className={styles.root}>
      <Grid>
        {ids.map((id) => (
          <AttachedItem key={id} variant="icon" classes={{ root: styles.item }} id={id} />
        ))}
      </Grid>

      <StepsFooterActions className={styles.actions} onClickBack={onBack} backType="button" />
    </div>
  )
}
