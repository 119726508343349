import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'

import { AccountName } from 'App/components/common'
import { EAvatarSize } from 'enums'
import { UserProfileDetailsItem } from 'App/components/UserProfile'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'

import styles from './Header.module.scss'

type THeaderProps = {
  userId: string
  firstName: string
  lastName: string
  displayUserName: string
  country: string
  city: string
  timezone: string
  onClick: () => void
}

export const Header = ({
  userId,
  firstName,
  lastName,
  displayUserName,
  country,
  city,
  timezone,
  onClick
}: THeaderProps) => (
  <div className={styles.root}>
    <AccountName
      showAvatar={true}
      displayUserName={displayUserName}
      type={AccountTypeNames.BUSINESS}
      userId={userId}
      firstName={firstName}
      lastName={lastName}
      size={EAvatarSize.LG}
      classes={{ avatarWrapper: styles.avatar, title: styles.name }}
      onClick={onClick}
    >
      <UserProfileDetailsItem
        variant="text"
        icon={<LocationIcon className={cls(styles.icon, styles.locationIcon)} />}
        classes={{ root: styles.location, icon: styles.locationIconWrapper }}
        text={`${country}, ${city}, (${timezone})`}
      />
    </AccountName>
  </div>
)
