import { useEffect, useState } from 'react'

import { useQueryClient } from 'services/query'

import { TChipListItem } from '../../components'
import {
  getCategories,
  getOrganizationDepartments,
  getOrganizations,
  handleError
} from '../../../api'

export const useCategoriesChipRequest = () => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<TChipListItem[] | null>(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    setLoading(true)
    Promise.all([
      queryClient.fetchQuery({ queryKey: ['categories'], queryFn: () => getCategories() }),
      queryClient.fetchQuery({ queryKey: ['organizations'], queryFn: () => getOrganizations() })
    ])
      .then(([categoriesRes, organizationsRes]) => {
        const organizations = organizationsRes.results.map(({ id, businessProfile: { name } }) => ({
          id,
          name
        }))
        return Promise.all(
          organizationsRes.results.map((item) =>
            queryClient.fetchQuery({
              queryKey: ['load-departments', item.id],
              queryFn: () => getOrganizationDepartments({ organizationId: item.id })
            })
          )
        ).then((res) => {
          setItems([
            {
              id: 'contacts',
              name: 'Contacts',
              category: 'Contacts',
              items: [
                { id: 'no-category', name: 'No category', category: 'Contacts' },
                ...categoriesRes.map((item) => ({ ...item, category: 'Contacts' }))
              ]
            },
            ...organizations.map((item, index) => {
              const departments = res[index]?.results

              if (departments?.length > 0) {
                return {
                  ...item,
                  items: [
                    { name: 'No department', id: `no-department-${item.id}` },
                    ...departments.map(({ id, name }) => ({ id: `department-${id}`, name }))
                  ]
                }
              }

              return {
                ...item,
                items: [{ name: 'No department', id: `no-department-${item.id}` }]
              }
            })
          ])
        })
      })
      .catch((e) => {
        handleError(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [queryClient])

  return { loading, items }
}
