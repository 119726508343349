import { useTranslation } from 'react-i18next'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { formatBytes } from 'utils/formats'
import { TSubscriptionInfo } from 'api/subscriptions'

export type TFeatureValue = boolean | string | number

export type TSubscriptionPlanFeature = {
  title: string
  tooltip?: string
  professional: { basic: TFeatureValue; professional: TFeatureValue }
  business: { basic: TFeatureValue; business: TFeatureValue }
}
export type TSubscriptionPlanSection = {
  title: string
  features: TSubscriptionPlanFeature[]
}

export type TSubscriptionPlans = Map<SubscriptionIdsEnum, TSubscriptionInfo>

export const useSubscriptionPlans = (plans: TSubscriptionPlans): TSubscriptionPlanSection[] => {
  const { t } = useTranslation()

  return [
    {
      title: t('subscriptions.planDetails.cases.title'),
      features: [
        {
          title: t('subscriptions.planDetails.cases.features.privateCase'),
          tooltip: t('subscriptions.planDetails.cases.features.privateCaseTooltip'),
          business: { basic: true, business: true },
          professional: { basic: true, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.professionalGroupCase'),
          tooltip: t('subscriptions.planDetails.cases.features.professionalGroupCaseTooltip'),
          business: { basic: false, business: true },
          professional: { basic: false, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.professionalDirectCase'),
          tooltip: t('subscriptions.planDetails.cases.features.professionalDirectCaseTooltip'),
          business: { basic: false, business: true },
          professional: { basic: false, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.linkCase'),
          business: { basic: true, business: true },
          professional: { basic: true, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.lockCase'),
          tooltip: t('subscriptions.planDetails.cases.features.lockCaseTooltip'),
          business: { basic: false, business: true },
          professional: { basic: false, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.caseHistory'),
          business: { basic: true, business: true },
          professional: { basic: true, professional: true }
        },
        {
          title: t('subscriptions.planDetails.cases.features.professionalCaseMember'),
          business: { basic: true, business: true },
          professional: { basic: true, professional: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.chats.title'),
      features: [
        {
          title: t('subscriptions.planDetails.chats.features.caseChats'),
          tooltip: t('subscriptions.planDetails.chats.features.caseChatsTooltip'),
          professional: { basic: false, professional: true },
          business: { basic: false, business: true }
        },
        {
          title: t('subscriptions.planDetails.chats.features.groupChats'),
          tooltip: t('subscriptions.planDetails.chats.features.groupChatsTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.chats.features.directCalls'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.chats.features.groupCalls'),
          tooltip: t('subscriptions.planDetails.chats.features.groupCallsTooltip'),
          professional: { basic: false, professional: true },
          business: { basic: false, business: true }
        },
        {
          title: t('subscriptions.planDetails.chats.features.broadcastMessage'),
          tooltip: t('subscriptions.planDetails.chats.features.broadcastMessageTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.medCloud.title'),
      features: [
        {
          title: t('subscriptions.planDetails.medCloud.features.storageSpace'),
          tooltip: t('subscriptions.planDetails.medCloud.features.storageTooltip'),
          professional: {
            basic: formatBytes(
              plans.get(SubscriptionIdsEnum.BASIC_PROFESSIONAL)?.medCloudCapacity ?? 0
            ),
            professional: formatBytes(
              plans.get(SubscriptionIdsEnum.PROFESSIONAL)?.medCloudCapacity ?? 0
            )
          },
          business: {
            basic: formatBytes(
              plans.get(SubscriptionIdsEnum.BASIC_BUSINESS)?.medCloudCapacity ?? 0
            ),
            business: formatBytes(plans.get(SubscriptionIdsEnum.BUSINESS)?.medCloudCapacity ?? 0)
          }
        },
        {
          title: t('subscriptions.planDetails.medCloud.features.fileSize'),
          tooltip: t('subscriptions.planDetails.medCloud.features.storageTooltip'),
          professional: {
            basic: formatBytes(
              plans.get(SubscriptionIdsEnum.BASIC_PROFESSIONAL)?.medCloudMaxUploadFileSize ?? 0
            ),
            professional: formatBytes(
              plans.get(SubscriptionIdsEnum.PROFESSIONAL)?.medCloudMaxUploadFileSize ?? 0
            )
          },
          business: {
            basic: formatBytes(
              plans.get(SubscriptionIdsEnum.BASIC_BUSINESS)?.medCloudMaxUploadFileSize ?? 0
            ),
            business: formatBytes(
              plans.get(SubscriptionIdsEnum.BUSINESS)?.medCloudMaxUploadFileSize ?? 0
            )
          }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.relatedAccounts.title'),
      features: [
        {
          title: t('subscriptions.planDetails.relatedAccounts.features.createBusinessAccount'),
          professional: {
            basic: t('subscriptions.planDetails.values.upTo', {
              count: 3
            }),
            professional: t('subscriptions.planDetails.values.upTo', {
              count: 3
            })
          },
          business: {
            basic: t('subscriptions.planDetails.values.upTo', {
              count: 0
            }),
            business: t('subscriptions.planDetails.values.upTo', {
              count: 0
            })
          }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.businessAccountAdministration.title'),
      features: [
        {
          title: t('subscriptions.planDetails.businessAccountAdministration.features.setAdmins'),
          professional: {
            basic: t('subscriptions.planDetails.values.upTo', {
              count: plans.get(SubscriptionIdsEnum.BASIC_PROFESSIONAL)?.businessAdminLimit
            }),
            professional: t('subscriptions.planDetails.values.upTo', {
              count: plans.get(SubscriptionIdsEnum.PROFESSIONAL)?.businessAdminLimit
            })
          },
          business: {
            basic: t('subscriptions.planDetails.values.upTo', {
              count: plans.get(SubscriptionIdsEnum.BASIC_BUSINESS)?.businessAdminLimit
            }),
            business: t('subscriptions.planDetails.values.upTo', {
              count: plans.get(SubscriptionIdsEnum.BUSINESS)?.businessAdminLimit
            })
          }
        },
        {
          title: t('subscriptions.planDetails.businessAccountAdministration.features.beAdmin'),
          professional: {
            basic: t('subscriptions.planDetails.values.inUpTo', {
              count: plans.get(SubscriptionIdsEnum.BASIC_PROFESSIONAL)?.participatingAdminLimit
            }),
            professional: t('subscriptions.planDetails.values.inUpTo', {
              count: plans.get(SubscriptionIdsEnum.PROFESSIONAL)?.participatingAdminLimit
            })
          },
          business: {
            basic: t('subscriptions.planDetails.values.inUpTo', {
              count: plans.get(SubscriptionIdsEnum.BASIC_BUSINESS)?.participatingAdminLimit
            }),
            business: t('subscriptions.planDetails.values.inUpTo', {
              count: plans.get(SubscriptionIdsEnum.BUSINESS)?.participatingAdminLimit
            })
          }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.organization.title'),
      features: [
        {
          title: t('subscriptions.planDetails.organization.features.ownOrganization'),
          professional: { basic: false, professional: false },
          business: { basic: 1, business: 1 }
        },
        {
          title: t('subscriptions.planDetails.organization.features.inviteStaff'),
          professional: { basic: false, professional: false },
          business: { basic: false, business: t('subscriptions.planDetails.values.unlimited') }
        },
        {
          title: t('subscriptions.planDetails.organization.features.beStaff'),
          professional: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            professional: t('subscriptions.planDetails.values.unlimited')
          },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.organization.features.departments'),
          tooltip: t('subscriptions.planDetails.organization.features.departmentsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: false, business: t('subscriptions.planDetails.values.unlimited') }
        },
        {
          title: t('subscriptions.planDetails.organization.features.departmentsMember'),
          professional: { basic: true, professional: true },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.organization.features.textChannels'),
          tooltip: t('subscriptions.planDetails.organization.features.textChannelsTooltip'),
          professional: {
            basic: t('subscriptions.planDetails.values.onlyChannelManager'),
            professional: t('subscriptions.planDetails.values.onlyChannelManager')
          },
          business: { basic: 1, business: t('subscriptions.planDetails.values.unlimited') }
        },
        {
          title: t('subscriptions.planDetails.organization.features.useTextChannels'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.organization.features.voiceChannels'),
          tooltip: t('subscriptions.planDetails.organization.features.voiceChannelsTooltip'),
          professional: {
            basic: t('subscriptions.planDetails.values.onlyChannelManager'),
            professional: t('subscriptions.planDetails.values.onlyChannelManager')
          },
          business: { basic: 1, business: t('subscriptions.planDetails.values.unlimited') }
        },
        {
          title: t('subscriptions.planDetails.organization.features.useVoiceChannels'),
          professional: { basic: true, professional: true },
          business: { basic: false, business: true }
        },
        {
          title: t('subscriptions.planDetails.organization.features.broadcastMessage'),
          tooltip: t('subscriptions.planDetails.organization.features.broadcastMessageTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.organization.features.directAdminMessages'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.community.title'),
      features: [
        {
          title: t('subscriptions.planDetails.community.features.createCommunity'),
          professional: { basic: false, professional: false },
          business: { basic: 1, business: 1 }
        },
        {
          title: t('subscriptions.planDetails.community.features.manageCommunity'),
          tooltip: t('subscriptions.planDetails.community.features.manageCommunityTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.community.features.inviteMembers'),
          professional: { basic: false, professional: false },
          business: {
            basic: 'Unlimited',
            business: t('subscriptions.planDetails.values.unlimited')
          }
        },
        {
          title: t('subscriptions.planDetails.community.features.joinMultipleCommunities'),
          professional: { basic: true, professional: true },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.community.features.textChannels'),
          tooltip: t('subscriptions.planDetails.community.features.textChannelsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.community.features.newsChannels'),
          tooltip: t('subscriptions.planDetails.community.features.newsChannelsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.community.features.liveStreams'),
          tooltip: t('subscriptions.planDetails.community.features.liveStreamsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.community.features.useChannels'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.community.features.networking'),
          tooltip: t('subscriptions.planDetails.community.features.networkingTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.events.title'),
      features: [
        {
          title: t('subscriptions.planDetails.events.features.createEvent'),
          professional: { basic: false, professional: false },
          business: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            business: t('subscriptions.planDetails.values.unlimited')
          }
        },
        {
          title: t('subscriptions.planDetails.events.features.manageEvent'),
          tooltip: t('subscriptions.planDetails.events.features.manageEventTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.inviteAttendees'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.eventMember'),
          professional: { basic: 'Unlimited', professional: 'Unlimited' },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.events.features.attendeeProfile'),
          tooltip: t('subscriptions.planDetails.events.features.attendeeProfileTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.events.features.textChannels'),
          tooltip: t('subscriptions.planDetails.events.features.textChannelsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.newsChannels'),
          tooltip: t('subscriptions.planDetails.events.features.newsChannelsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.liveStreams'),
          tooltip: t('subscriptions.planDetails.events.features.liveStreamsTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.useChannels'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.events.features.networking'),
          tooltip: t('subscriptions.planDetails.events.features.networkingTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.contactsList.title'),
      features: [
        {
          title: t('subscriptions.planDetails.contactsList.features.inviteToContacts'),
          tooltip: t('subscriptions.planDetails.contactsList.features.inviteToContactsTooltip'),
          professional: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            professional: t('subscriptions.planDetails.values.unlimited')
          },
          business: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            business: t('subscriptions.planDetails.values.unlimited')
          }
        },
        {
          title: t('subscriptions.planDetails.contactsList.features.manageList'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        },
        {
          title: t('subscriptions.planDetails.contactsList.features.categories'),
          tooltip: t('subscriptions.planDetails.contactsList.features.categoriesTooltip'),
          professional: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            professional: t('subscriptions.planDetails.values.unlimited')
          },
          business: {
            basic: t('subscriptions.planDetails.values.unlimited'),
            business: t('subscriptions.planDetails.values.unlimited')
          }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.profile.title'),
      features: [
        {
          title: t('subscriptions.planDetails.profile.features.createProfessionalProfile'),
          tooltip: t('subscriptions.planDetails.profile.features.createProfessionalProfileTooltip'),
          professional: { basic: true, professional: true },
          business: { basic: false, business: false }
        },
        {
          title: t('subscriptions.planDetails.profile.features.createBusinessProfile'),
          tooltip: t('subscriptions.planDetails.profile.features.createBusinessProfileTooltip'),
          professional: { basic: false, professional: false },
          business: { basic: true, business: true }
        }
      ]
    },
    {
      title: t('subscriptions.planDetails.support.title'),
      features: [
        {
          title: t('subscriptions.planDetails.support.features.askMedentee'),
          professional: { basic: true, professional: true },
          business: { basic: true, business: true }
        }
      ]
    }
  ]
}
