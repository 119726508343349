import { useCallback } from 'react'

import { TCaseMembersItemActiveContainerProps } from 'App/containers'
import {
  CaseMembersItemInvite,
  CaseMembersItemKebabMenu,
  CaseMembersItemMessageButton,
  CaseMembersItemWrapper
} from 'App/components'
import { ECaseAccountType } from 'enums'

export type TCaseMembersItemActiveProps = TCaseMembersItemActiveContainerProps & {
  caseView?: string
}

export const CaseMembersItemActive = ({
  member: {
    id: memberId,
    chatId,
    account: { id, firstName, lastName, displayUserName, type, isCoworker, isContact },
    contactStatus,
    contactId
  },
  caseOwnerId,
  who,
  caseId,
  showModal,
  getMembers
}: TCaseMembersItemActiveProps) => {
  const isOwner = who === ECaseAccountType.OWNER
  const showInviteActions = !isContact && (!isOwner || (isOwner && isCoworker))

  const disabledChatButton = !isContact && !isCoworker

  const onSuccess = useCallback(() => {
    getMembers({ caseId })
  }, [getMembers, caseId])

  return (
    <CaseMembersItemWrapper
      id={id}
      firstName={firstName}
      lastName={lastName}
      displayUserName={displayUserName}
      type={type?.name}
      caseOwnerId={caseOwnerId}
      isCoworker={isCoworker}
    >
      {showInviteActions && (
        <CaseMembersItemInvite
          accountId={id}
          contactStatus={contactStatus ?? null}
          contactId={contactId}
          onSuccess={onSuccess}
          caseId={caseId}
        />
      )}
      <CaseMembersItemMessageButton
        chatId={chatId}
        disabled={disabledChatButton}
        partnerAccountId={id}
      />
      {who === ECaseAccountType.OWNER && (
        <CaseMembersItemKebabMenu memberId={memberId} active={true} showModal={showModal} />
      )}
    </CaseMembersItemWrapper>
  )
}
