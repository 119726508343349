import cls from 'classnames'

import { SortingTable } from 'types'
import { ReactComponent as ArrowIcon } from 'assets/icons/ArrowUp.svg'

import styles from './TableTitle.module.scss'

export type TTableTitleProps = {
  title: string
  sortId: string

  sorter?: boolean
  sorting?: SortingTable
  className?: string
}

export const TableTitle = ({
  title,
  sortId,
  sorting,
  sorter = false,
  className = ''
}: TTableTitleProps) => {
  const direction = (sorter && sorting?.name === sortId && sorting?.direction) || ''

  return (
    <div className={cls(styles.root, 'table-header', direction, className)}>
      <span className={cls(sorter && 'table-header-title')}>{title}</span>
      {sorter && <ArrowIcon className={cls(Boolean(direction) && styles.active)} />}
    </div>
  )
}
