import { AccountTypeIdEnum } from '@medentee/enums'
import omit from 'lodash/omit'

import { TProfessionDTO, TSplittedProfessions } from './misc.types'

export const splitProfessions = (professions: TProfessionDTO[]) =>
  professions.reduce<TSplittedProfessions>(
    (acc, entry) => {
      switch (entry.accountTypeId) {
        case AccountTypeIdEnum.PROFESSIONAL: {
          return {
            ...acc,
            professional: [...acc.professional, omit(entry, ['createdAt', 'updatedAt'])]
          }
        }
        case AccountTypeIdEnum.BUSINESS: {
          return {
            ...acc,
            business: [...acc.business, omit(entry, ['createdAt', 'updatedAt'])]
          }
        }
        default: {
          return acc
        }
      }
    },
    {
      professional: [],
      business: []
    }
  )
