import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'

import { useQuery, useQueryClient } from 'services/query'
import { ICommunityDTO } from 'interfaces'
import { getCommunitiesRequest, useAppDispatch } from 'store'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { CommunityFormContent, TFormValues } from '../CommunityFormContent'
import { getCommunity, updateCommunity, handleError } from '../../../../api'

import styles from './CommunityBasicInfo.module.scss'

const formatData = ({ country: location, title, description, tags }: ICommunityDTO) => ({
  tags: tags.map((tag) => ({ text: tag })),
  title,
  location,
  description
})

export const CommunityBasicInfo = () => {
  const dispatch = useAppDispatch()

  const queryClient = useQueryClient()

  const { id } = useParams<{ id: string }>()
  const [initialValues, setInitialValues] = useState<null | TFormValues>({
    description: '',
    location: null,
    tags: [],
    title: ''
  })

  const { t } = useTranslation()

  const handleSuccess = useCallback(() => {
    toast.success(t('common.toast.changesSaved'), toastDefaultOptions)
    dispatch(getCommunitiesRequest())
  }, [dispatch, t])

  const { isFetching } = useQuery({
    queryKey: ['get-community'],
    queryFn: () => getCommunity({ id }),
    enabled: Boolean(id),
    onSuccess: (data) => setInitialValues(formatData(data))
  })

  const onSubmit = (values: TFormValues) => {
    const prevValues = { ...initialValues }
    const newValues = { ...values }
    const requests: Promise<AxiosResponse>[] = []

    if (!isEqual(prevValues, newValues)) {
      requests.push(
        queryClient.fetchQuery({
          queryKey: ['update-community'],
          queryFn: () =>
            updateCommunity({
              id,
              payload: {
                description: newValues.description,
                tags: newValues.tags.map((item) => item.text),
                title: newValues.title,
                countryCode: newValues.location?.code
              }
            })
        })
      )
    }

    if (requests.length) {
      return Promise.all(requests)
        .then(() => {
          handleSuccess()
        })
        .catch((e) => handleError(e))
    }

    return
  }

  return (
    <div className={styles.root}>
      <CommunityFormContent
        loading={isFetching}
        mode="edit"
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </div>
  )
}
