import { useTranslation } from 'react-i18next'

import { contactsNestedRoutes } from 'App/App.config'
import { TNavMenuItem } from 'App/components'
import { TRoleRouteContainerOwnProps } from 'App/containers'
import { useAppSelector } from 'store'

const CONTACTS_DEFAULT_PATH = '/contacts'

type TUseContactsRoutesResponse = {
  nestedRoutes: TRoleRouteContainerOwnProps[]
  defaultPath: string
  navMenu: TNavMenuItem[]
}

export const useContactsRoutes = (): TUseContactsRoutesResponse => {
  const contactsNotifications = useAppSelector((state) => state.notifications.general.contacts)

  const { t } = useTranslation()

  return {
    nestedRoutes: contactsNestedRoutes,
    defaultPath: CONTACTS_DEFAULT_PATH,
    navMenu: [
      {
        link: CONTACTS_DEFAULT_PATH,
        title: t('contacts.tabs.contacts'),
        exact: true
      },
      {
        link: '/contacts/invitations',
        title: t('contacts.tabs.invitations'),
        notification: {
          count: contactsNotifications,
          variant: 'dot'
        }
      }
    ]
  }
}
