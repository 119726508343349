import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSteps } from 'App/hooks'
import {
  showModalAction,
  useAppDispatch,
  useAppSelector,
  createLoadingSelector,
  GET_CASES_LINKS,
  GET_CASE_LINKED_FILES,
  ATTACH_CASE_LINKED_FILES,
  TError,
  TLinkedCasesItem
} from 'store'
import { ErrorModal, Steps } from 'App/components'
import { EModalComponents } from 'App/containers'

import { FirstStep, TFirstStepProps } from './FirstStep'
import { SecondStep } from './SecondStep'
import styles from './AttachFilesFromLinkedCaseDialog.module.scss'

export type TAttachFilesFromLinkedCaseDialogProps = {
  caseId: string
}

const DEFAULT_STEPS = [{}, {}]
const loadingSelector = createLoadingSelector([GET_CASES_LINKS])

enum STEPS {
  FIRST,
  SECOND
}

export const AttachFilesFromLinkedCaseDialog = ({
  caseId
}: TAttachFilesFromLinkedCaseDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)
  const [selectedCase, setSelectedCase] = useState<TLinkedCasesItem | null>(null)

  const error: TError = useAppSelector(
    (state) =>
      state.errors[GET_CASES_LINKS] ||
      state.errors[GET_CASE_LINKED_FILES] ||
      state.errors[ATTACH_CASE_LINKED_FILES]
  )

  const isEmptyFilterList = useAppSelector((state) => {
    const { classifications, roles, statuses, search } = state.cases.caseLinks.filters

    return Boolean(
      !state.cases.caseLinks.ids.length &&
        !search.length &&
        !classifications.length &&
        !roles.length &&
        !statuses.length &&
        !loadingSelector(state)
    )
  })

  const shouldShowSteps = !(isEmptyFilterList && step === STEPS.FIRST)

  const handleFirstStepBack = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.attachFile.title'),
        modalType: EModalComponents.CASE_ATTACH_FILE_DIALOG,
        modalProps: { caseId }
      })
    )
  }, [caseId, dispatch, t])

  const handleFirstStepNext: TFirstStepProps['onNext'] = useCallback(
    (item) => {
      setSelectedCase(item)
      setNextStep()
    },
    [setNextStep]
  )

  return (
    <>
      {shouldShowSteps && (
        <Steps className={styles.steps} current={step} stepsArray={DEFAULT_STEPS} />
      )}

      {step === STEPS.FIRST && (
        <FirstStep caseId={caseId} onNext={handleFirstStepNext} onBack={handleFirstStepBack} />
      )}
      {step === STEPS.SECOND && selectedCase && (
        <SecondStep selectedCase={selectedCase} caseId={caseId} onBack={setPrevStep} />
      )}

      <ErrorModal error={error} />
    </>
  )
}
