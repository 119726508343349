import { memo, FocusEvent, useMemo } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { HelperText } from 'App/components'
import { Error, TErrorProps } from 'App/components/common/Fields/Error'

import styles from './CheckableList.module.scss'
import { CheckableListItem, SELECT_ALL_VALUE } from './CheckableListItem'

type TCheckableListClasses = 'root' | 'title' | 'item'

type TItem = {
  [key: string]: any
  items?: { [key: string]: any }
}

export type TFilterNewValue<
  Filter extends string | undefined = undefined,
  Value extends string = string
> = Filter extends string ? Record<Filter, Value[]> : Value[]

export type TCheckableListProps<
  Filter extends string | undefined,
  Item extends TItem,
  Value extends string
> = {
  title?: string
  items: Item[]
  value: Value[]
  onChange: (newValue: TFilterNewValue<Filter, Value>) => void

  loading?: boolean
  valueKey?: keyof Item
  labelKey?: keyof Item
  filterType?: Filter
  classes?: Partial<Record<TCheckableListClasses, string>>
  withSelectAll?: boolean
  onBlur?: (e: FocusEvent<HTMLElement>) => void
  onFocus?: (e: FocusEvent<HTMLElement>) => void
  formatLabel?: (label: string) => JSX.Element
} & TErrorProps

const CheckableListComponent = <
  Filter extends string | undefined = undefined,
  Item extends { [key: string]: any } = Record<string, unknown>,
  Value extends string = string
>({
  title,
  items,
  value,
  classes,
  loading,
  filterType,
  error,
  invalid,
  withSelectAll,
  onChange,
  onBlur,
  onFocus,
  formatLabel,
  labelKey = 'label',
  valueKey = 'value'
}: TCheckableListProps<Filter, Item, Value>) => {
  const { t } = useTranslation()

  const valueSet = useMemo(() => new Set(value), [value])

  const itemsList = useMemo(
    () =>
      withSelectAll
        ? [
            {
              [labelKey]: t('common.filters.allOption'),
              [valueKey]: SELECT_ALL_VALUE
            } as Item,
            ...items
          ]
        : items,
    [items, labelKey, t, valueKey, withSelectAll]
  )
  return (
    <div className={cls(styles.root, classes?.root)} onBlur={onBlur} onFocus={onFocus}>
      {!!title && <p className={cls(styles.title, classes?.title)}>{title}</p>}
      <Skeleton
        active={true}
        avatar={false}
        title={false}
        paragraph={{ rows: 5, width: '100%' }}
        loading={loading}
      >
        {itemsList.map((item) => (
          <CheckableListItem
            key={item[valueKey]}
            value={value}
            classes={classes}
            data={item}
            items={items}
            labelKey={labelKey}
            valueKey={valueKey}
            valueSet={valueSet}
            filterType={filterType}
            onChange={onChange}
            formatLabel={formatLabel}
          />
        ))}
      </Skeleton>
      <HelperText>
        <Error error={error} invalid={invalid} />
      </HelperText>
    </div>
  )
}

export const CheckableList = memo(CheckableListComponent) as typeof CheckableListComponent
