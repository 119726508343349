import {
  forwardRef,
  KeyboardEvent,
  Ref,
  useImperativeHandle,
  useRef,
  ClipboardEvent,
  useEffect
} from 'react'

import { removeAllEmptyCharacters } from 'utils'

import { SUBMIT_KEYS } from '../chipInput.constants'

import styles from './ChipInputTexrtField.module.scss'

export type TChipInputTextField = {
  maxSize?: number
  paste?: boolean
  autoFocus?: boolean
  onDelete: () => void
  submitKeys?: Set<string>
  onSubmit: (value: string | string[]) => void
}

const ChipInputTextFieldView = (
  {
    autoFocus,
    onSubmit,
    onDelete,
    maxSize = Infinity,
    submitKeys = SUBMIT_KEYS,
    paste = true
  }: TChipInputTextField,
  forwardedRef: Ref<HTMLDivElement | null>
) => {
  const inputRef = useRef<HTMLDivElement>(null)
  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (inputRef.current) {
      let value: string | string[] = removeAllEmptyCharacters(
        e.clipboardData?.getData('text')
      ).split(',')

      if (Array.isArray(value) && value.length === 1) {
        value = value[0]
      }

      onSubmit(Array.isArray(value) ? value.slice(0, maxSize) : value)
    }
  }
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      if (submitKeys.has(e.key)) {
        e.preventDefault()

        if (inputRef.current.innerText) {
          onSubmit(inputRef.current.innerText)
        }

        inputRef.current.innerText = ''
      }

      if (e.key === 'Backspace' && !inputRef.current.innerText) {
        onDelete()
      }
    }
  }

  useImperativeHandle(forwardedRef, () => inputRef.current)

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  return (
    <div
      ref={inputRef}
      contentEditable="true"
      className={styles.root}
      onKeyDown={handleKeyDown}
      onPaste={paste ? handlePaste : undefined}
    />
  )
}

export const ChipInputTextField = forwardRef<HTMLInputElement, TChipInputTextField>(
  ChipInputTextFieldView
)
