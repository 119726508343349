import { MouseEvent } from 'react'
import AnchorifyText, {
  TAnchorifyElementProps as TOriginAnchorifyElementProps
} from 'react-anchorify-text'
import cls from 'classnames'
import { useHistory } from 'react-router-dom'

import { showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import { getPathnameFromUrl, isInternalUrl } from 'utils'

import { FormattedText } from '../DraftEditor'

import styles from './AnchorifedText.module.scss'

export type TAnchorifedTextProps = {
  text: string

  nonUrlPartsRenderer?: (text: string) => React.ReactNode
  color?: 'default' | 'white'
}

type TAnchorifyElementProps = TOriginAnchorifyElementProps &
  Omit<TAnchorifedTextProps, 'text' | 'nonUrlPartsRenderer'>

type TLinkHandlerProps = (event: MouseEvent<HTMLAnchorElement>) => void

const emojiRenderer = (text: string) => <FormattedText text={text} formatters={['emoji']} />

const AnchorifyElement = ({ url, match, color = 'default' }: TAnchorifyElementProps) => {
  const { isDesktop } = useAdaptiveLayout()
  const dispatch = useAppDispatch()
  const { push } = useHistory()

  const onLinkClick: TLinkHandlerProps = (event) => {
    event.preventDefault()

    if (url) {
      if (isInternalUrl(url)) {
        return push(getPathnameFromUrl(url))
      }

      dispatch(
        showModalAction({
          modalType: EModalComponents.REDIRECT_CONFIRM,
          modalTitle: 'Follow the link?',
          modalProps: {
            link: url
          }
        })
      )
    }
  }

  const onContextMenu: TLinkHandlerProps = (event) => {
    if (isDesktop) {
      event.stopPropagation()
    }
  }

  return (
    <a
      href={url}
      onClick={onLinkClick}
      onContextMenu={onContextMenu}
      className={cls(styles.root, [styles[color]])}
    >
      {match?.text}
    </a>
  )
}

export const AnchorifedText = ({ text, color, nonUrlPartsRenderer }: TAnchorifedTextProps) => {
  if (!text) {
    return null
  }

  return (
    <AnchorifyText text={text} nonUrlPartsRenderer={nonUrlPartsRenderer ?? emojiRenderer}>
      <AnchorifyElement color={color} />
    </AnchorifyText>
  )
}
