import cls from 'classnames'

import { EAvatarSize } from 'enums'
import { AccountName, Checkbox } from 'App/components'
import { TAccountData } from 'store'

import styles from './AccountsSelectOption.module.scss'

// ------------------ Types ------------------
export type TAccountsSelectOptionData = PartialBy<
  Pick<TAccountData, 'id' | 'firstName' | 'lastName' | 'type' | 'displayUserName'>,
  'firstName' | 'lastName' | 'type'
> & {
  contactId?: string
  chatId?: string
  disabled?: boolean
}

export type TAccountsSelectOptionProps = {
  data: TAccountsSelectOptionData
  onClick: (value: TAccountsSelectOptionData) => void

  disabled?: boolean
  selected: boolean
  autoOnline?: boolean
}
// ------------------  END  ------------------

export const AccountsSelectOption = ({
  data,
  onClick,
  disabled,
  selected,
  autoOnline
}: TAccountsSelectOptionProps) => {
  const { firstName, lastName, type, displayUserName } = data

  const onAdd = () => {
    if (disabled) {
      return
    }

    onClick(data)
  }

  return (
    <div className={cls(styles.root, disabled && styles.rootDisabled)} onClick={onAdd}>
      <Checkbox checked={selected} disabled={disabled} classes={{ root: styles.checkbox }} />
      <AccountName
        showAvatar
        userId={data.id}
        size={EAvatarSize.XS}
        lastName={lastName}
        firstName={firstName}
        displayUserName={displayUserName}
        type={type?.name}
        disabled={disabled}
        autoOnline={autoOnline}
      />
    </div>
  )
}
