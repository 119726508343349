import { FC } from 'react'
import { Tabs as AntTabs } from 'antd'
import { TabsProps as AntTabsProps } from 'antd/lib/tabs'
import cls from 'classnames'

import styles from './Tabs.module.scss'

type TTabsProps = AntTabsProps & {
  variant?: 'default' | 'fill'
}

export const Tabs: FC<TTabsProps> = ({ className, children, variant = 'default', ...props }) => (
  <div className={cls(styles.root, styles[variant], className)}>
    <AntTabs {...props}>{children}</AntTabs>
  </div>
)
