import { api } from 'services/api'

const profileImg = '' // TODO -- add default img

export const onLoadImg = (image: HTMLImageElement | null, canvas: HTMLCanvasElement | null) => {
  let result = ''

  if (image !== null && canvas !== null) {
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d')
    if (ctx !== null) {
      ctx.drawImage(image, 0, 0)
    }

    result = canvas.toDataURL()
  }

  return result
}

export type TOnLoadImg = (
  image: HTMLImageElement | null,
  canvas: HTMLCanvasElement | null
) => string | void

export const getImage = (src: string, defaultImg: string = profileImg) =>
  new Promise((resolve) => {
    const image = new Image()
    const canvas = document.createElement('canvas')
    image.src = src
    image.setAttribute('crossorigin', 'anonymous')

    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      const ctx = canvas.getContext('2d')

      if (ctx !== null) {
        ctx.drawImage(image, 0, 0)
      }

      resolve(canvas.toDataURL())
    }

    image.onerror = () => {
      resolve(defaultImg)
    }
  })

export const getBase64ImageFromUrl = async (url: string): Promise<string | undefined> => {
  const { data } = await api.get(url, { responseType: 'blob' })

  const toBase64 = (file: File) =>
    new Promise<string | undefined>((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result?.toString())
      reader.onerror = (error) => {
        console.error(error)
        resolve('')
      }
    })

  return toBase64(data)
}
