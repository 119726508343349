import { CaseClassificationIdEnum, CaseTypesEnum } from '@medentee/enums'
import cls from 'classnames'

import { ReactComponent as SelectedIcon } from 'assets/icons/CheckCircle.svg'

import styles from './CaseConvert.module.scss'

export type TCaseConvertProps<Value extends CaseClassificationIdEnum | CaseTypesEnum> =
  PropsWithClassName<{
    title: string
    description: string
    icon: JSX.Element
    selected: boolean
    value: Value
    onSelect: (value: Value) => void
  }>

export const CaseConvert = <Value extends CaseClassificationIdEnum | CaseTypesEnum>({
  title,
  description,
  icon,
  value,
  selected,
  className,
  onSelect
}: TCaseConvertProps<Value>) => {
  const handleClick = () => {
    onSelect(value)
  }

  return (
    <div
      className={cls(styles.root, { [styles.rootSelected]: selected }, className)}
      onClick={handleClick}
    >
      <div className={styles.icon}>{icon}</div>

      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>{description}</p>
      </div>

      {selected && <SelectedIcon className={styles.selectedIcon} />}
    </div>
  )
}
