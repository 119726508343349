import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'

import styles from './UpgradeSubscription.module.scss'

export type TUpgradeSubscriptionProps = {
  text: string
}

export const UpgradeSubscription = ({ text }: TUpgradeSubscriptionProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleRedirect = () => {
    push('/subscription-and-payments/subscription')
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{text}</p>
      <Button className={styles.rootButton} onClick={handleRedirect}>
        {t('modal.upgradeSubscription.submitButton')}
      </Button>
    </div>
  )
}
