import { useCallback, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
  BROADCAST_MESSAGE,
  getBroadcastMessageErrorSelector,
  getBroadcastSelectedFilesListSelector,
  removeErrorNotification,
  resetBroadcast,
  setModalTitleAction,
  TAttachMedcloudFilesList,
  useAppDispatch,
  useAppSelector
} from 'store'
import { getMapComponent } from 'utils'
import { useSteps } from 'App/hooks'
import { ErrorModal } from 'App/components'

import { MessageContainer } from './MessageContainer'
import { AttachedFilesContainer } from './AttachedFilesContainer'
import { AttachedFiles } from './AttachedFiles'
import { AttachFiles } from './AttachFiles'
import { SelectRecipients, TSelectRecipientsProps } from './SelectRecipients'

export type TBroadcastMessageDialogProps = Pick<
  TSelectRecipientsProps,
  'accountType' | 'recipientsType' | 'organizationId'
> & { message?: string; readonly?: boolean }

enum steps {
  MAIN = 0,
  RECIPIENTS = 1,
  ATTACH = 2,
  ATTACHED_FILES = 3
}

type TStepsMapProps = TBroadcastMessageDialogProps & {
  textMessage: string
  attachedFiles: TAttachMedcloudFilesList
  setTextMessage: (msg: string) => void
  handleOpenSelectRecipients: () => void
  handleOpenMain: () => void
  handleOpenAttach: () => void
  handleOpenAttachedFiles: () => void

  readonly?: boolean
}

const STEPS_MAP = new Map<steps, (props: TStepsMapProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({
      readonly,
      recipientsType,
      textMessage,
      organizationId,
      setTextMessage,
      handleOpenSelectRecipients,
      handleOpenAttach,
      handleOpenAttachedFiles
    }) => (
      <>
        <MessageContainer
          readonly={readonly}
          textMessage={textMessage}
          recipientsType={recipientsType}
          organizationId={organizationId}
          setTextMessage={setTextMessage}
          onOpenSelectRecipients={handleOpenSelectRecipients}
          onOpenAttach={handleOpenAttach}
        />

        <AttachedFilesContainer onOpenAttachedFiles={handleOpenAttachedFiles} />
      </>
    )
  )
  .set(steps.RECIPIENTS, ({ recipientsType, accountType, organizationId, handleOpenMain }) => (
    <SelectRecipients
      recipientsType={recipientsType}
      organizationId={organizationId}
      accountType={accountType}
      onBack={handleOpenMain}
    />
  ))
  .set(steps.ATTACH, ({ handleOpenMain, attachedFiles }) => (
    <AttachFiles attachedFiles={attachedFiles} onBack={handleOpenMain} />
  ))
  .set(steps.ATTACHED_FILES, ({ handleOpenMain }) => <AttachedFiles onBack={handleOpenMain} />)

export const BroadcastMessageDialog = ({
  accountType,
  recipientsType,
  organizationId,
  message = '',
  readonly = false
}: TBroadcastMessageDialogProps) => {
  const dispatch = useAppDispatch()

  const { setStep, step } = useSteps(steps.MAIN)

  const [textMessage, setTextMessage] = useState<string>(message)

  const attachedFiles = useAppSelector(getBroadcastSelectedFilesListSelector)
  const error = useAppSelector(getBroadcastMessageErrorSelector, isEqual)

  const { t } = useTranslation()

  const handleOpenSelectRecipients = useCallback(() => {
    setStep(steps.RECIPIENTS)
    dispatch(
      setModalTitleAction({
        modalTitle: t('modal.broadcastMessage.title', { context: recipientsType })
      })
    )
  }, [dispatch, setStep, recipientsType, t])

  const removeError = useCallback(() => {
    dispatch(removeErrorNotification(BROADCAST_MESSAGE))
  }, [dispatch])

  const handleOpenMain = useCallback(() => {
    setStep(steps.MAIN)

    dispatch(setModalTitleAction({ modalTitle: t('modal.broadcastMessage.title') }))
  }, [dispatch, setStep, t])

  const handleOpenAttach = useCallback(() => {
    setStep(steps.ATTACH)

    dispatch(setModalTitleAction({ modalTitle: t('modal.broadcastMessage.title_attachFile') }))
  }, [dispatch, setStep, t])

  const handleOpenAttachedFiles = useCallback(() => {
    setStep(steps.ATTACHED_FILES)

    dispatch(setModalTitleAction({ modalTitle: t('modal.broadcastMessage.title_attachedFiles') }))
  }, [dispatch, setStep, t])

  useEffect(() => {
    removeError()
  }, [removeError, step])

  useEffect(
    () => () => {
      dispatch(resetBroadcast())
    },
    [dispatch]
  )

  return (
    <>
      {getMapComponent(STEPS_MAP, step, {
        readonly,
        textMessage,
        attachedFiles,
        accountType,
        recipientsType,
        organizationId,
        setTextMessage,
        handleOpenSelectRecipients,
        handleOpenMain,
        handleOpenAttach,
        handleOpenAttachedFiles
      })}

      <ErrorModal error={error} />
    </>
  )
}
