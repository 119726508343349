import { useCallback, useRef } from 'react'

const TIMEOUT = 350

export const useDoubleClick = (
  doubleClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  click?: (event: React.MouseEvent<HTMLElement, MouseEvent>, path: EventTarget[]) => void
) => {
  const clickTimeout = useRef<NodeJS.Timeout | null>(null)

  const clearClickTimeout = () => {
    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current)
      clickTimeout.current = null
    }
  }

  return useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.persist()

      const path = event.nativeEvent.composedPath()

      clearClickTimeout()

      if (click && event.detail === 1) {
        clickTimeout.current = setTimeout(() => {
          click(event, path)
        }, TIMEOUT)
      }

      if (doubleClick && event.detail % 2 === 0) {
        doubleClick(event)
      }
    },
    [click, doubleClick]
  )
}
