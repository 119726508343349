import {
  ContactStatusEnum,
  BusinessAccountStatusEnum,
  CaseMemberStatusesEnum,
  CaseStatusesEnum,
  EventInviteStatusEnum,
  InviteStatusEnum,
  MeetingInviteStatusEnum,
  MeetingStatusEnum,
  ShowCaseStatusEnum
} from '@medentee/enums'
import cls from 'classnames'
import { useMemo } from 'react'

import styles from './StatusColored.module.scss'

export type TStatus =
  | MeetingInviteStatusEnum
  | MeetingStatusEnum
  | InviteStatusEnum
  | CaseMemberStatusesEnum
  | ShowCaseStatusEnum
  | ContactStatusEnum
  | BusinessAccountStatusEnum
  | EventInviteStatusEnum
  | CaseStatusesEnum

export enum EStatusColors {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

type TStatusColoredTextWithStatus = {
  status: TStatus
  statusLabel: string
}

type TStatusColoredTextWithLabel = {
  label: string
}

type TStatusColoredProps = PropsWithClassName<{
  variant?: 'text' | 'chip'
  color?: Lowercase<keyof typeof EStatusColors>
  fill?: boolean
  format?: (label: string) => string
}> &
  (TStatusColoredTextWithStatus | TStatusColoredTextWithLabel)

const getStatusColorName = (status?: TStatus): EStatusColors => {
  switch (status) {
    case InviteStatusEnum.REJECTED:
    case MeetingStatusEnum.REJECTED:
    case MeetingInviteStatusEnum.REJECTED:
    case CaseMemberStatusesEnum.INVITE_REJECTED:
    case CaseMemberStatusesEnum.LEFT:
    case ShowCaseStatusEnum.DEACTIVATED:
    case ContactStatusEnum.REJECTED:
    case BusinessAccountStatusEnum.REJECTED:
    case InviteStatusEnum.EXPIRED:
    case EventInviteStatusEnum.EXPIRED:
    case CaseStatusesEnum.ARCHIVED:
    case EventInviteStatusEnum.LEFT:
      return EStatusColors.ERROR

    case InviteStatusEnum.PENDING:
    case MeetingStatusEnum.PENDING:
    case MeetingInviteStatusEnum.PENDING:
    case MeetingStatusEnum.EXPIRED:
    case CaseMemberStatusesEnum.INVITE_PENDING:
    case ShowCaseStatusEnum.DRAFT:
    case ContactStatusEnum.PENDING:
    case BusinessAccountStatusEnum.PENDING:
    case EventInviteStatusEnum.PENDING:
    case CaseStatusesEnum.LOCKED:
      return EStatusColors.WARNING

    case MeetingStatusEnum.OPEN:
      return EStatusColors.INFO

    default: {
      return EStatusColors.SUCCESS
    }
  }
}

export const StatusColored = ({
  className,
  fill,
  format,
  variant = 'text',
  ...props
}: TStatusColoredProps) => {
  const text = useMemo(() => {
    if (props.hasOwnProperty('label')) {
      return props.label
    }

    return props.statusLabel
  }, [props])

  const color = useMemo(() => {
    const status = props.hasOwnProperty('status') ? props.status : undefined

    return props.color ?? getStatusColorName(status)
  }, [props])

  const formattedText = format && text ? format(text) : text

  return (
    <span
      className={cls(styles.root, styles[variant], styles[color], className, {
        [styles.fill]: fill
      })}
    >
      {formattedText}
    </span>
  )
}
