import cls from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import {
  Checkbox,
  ContextMenu,
  TCheckboxProps,
  TMenuItemProps,
  AccountName,
  TAccountNameProps
} from 'App/components/common'
import { getMapComponent } from 'utils'

import styles from './ChatCreateGroupMembersItem.module.scss'

type TVariant = 'default' | 'checkbox' | 'kebab'

export type TChatCreateGroupMembersItemProps = Required<
  Pick<TAccountNameProps, 'userId' | 'displayUserName' | 'firstName' | 'lastName' | 'type'>
> &
  Pick<TCheckboxProps, 'onChange' | 'checked'> & {
    variant?: TVariant
    showOwnerLabel?: boolean
    getMenuItems?: (props: { userId: string }) => TMenuItemProps[]
  }

type TVariantMapProps = Omit<TChatCreateGroupMembersItemProps, 'variant'> & {
  t: TFunction
}

const VARIANT_MAP = new Map<TVariant, (props: TVariantMapProps) => JSX.Element>()
  .set('default', ({ displayUserName, firstName, lastName, type, userId }) => (
    <AccountName
      classes={{ content: styles.content, root: styles.wrapper }}
      showAvatar={true}
      truncate={true}
      hideAccountType={true}
      userId={userId}
      displayUserName={displayUserName}
      firstName={firstName}
      lastName={lastName}
      type={type}
      size={EAvatarSize.XS}
    />
  ))
  .set('checkbox', ({ displayUserName, firstName, lastName, type, userId, checked, onChange }) => (
    <Checkbox
      reverse={true}
      classes={{ root: cls(styles.wrapper, styles.checkbox) }}
      checked={checked}
      label={
        <AccountName
          classes={{ content: styles.content }}
          showAvatar={true}
          truncate={true}
          hideAccountType={true}
          userId={userId}
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          type={type}
          size={EAvatarSize.XS}
        />
      }
      onChange={onChange}
    />
  ))
  .set(
    'kebab',
    ({ displayUserName, firstName, lastName, type, userId, showOwnerLabel, getMenuItems, t }) => (
      <div className={cls(styles.wrapper, styles.kebab)}>
        <AccountName
          classes={{ title: styles.content }}
          showAvatar={true}
          truncate={true}
          hideAccountType={true}
          userId={userId}
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          type={type}
          size={EAvatarSize.XS}
          chipList={[
            {
              text: t('chat.details.ownerChip'),
              color: 'primary-dark',
              variant: 'square',
              hidden: !showOwnerLabel
            }
          ]}
        />

        {getMenuItems && (
          <ContextMenu menuItems={getMenuItems({ userId })} classes={{ root: styles.context }} />
        )}
      </div>
    )
  )

export const ChatCreateGroupMembersItem = ({
  displayUserName,
  firstName,
  lastName,
  type,
  userId,
  checked,
  showOwnerLabel,
  onChange,
  getMenuItems,
  variant = 'default'
}: TChatCreateGroupMembersItemProps) => {
  const { t } = useTranslation()

  return getMapComponent(VARIANT_MAP, variant, {
    displayUserName,
    firstName,
    lastName,
    type,
    userId,
    checked,
    showOwnerLabel,
    onChange,
    getMenuItems,
    t
  })
}
