import { FC, ReactNode } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import styles from './Card.module.scss'

export type TCardProps = {
  actions?: ReactNode
  subtitleChildren?: ReactNode
  title?: string | ReactNode
  loading?: boolean
  isFullHeight?: boolean
  classes?: Partial<Record<TCardClasses, string>>
}

export type TCardClasses = 'title' | 'actions' | 'header' | 'root' | 'headerWrapper'

export const Card: FC<TCardProps> = ({
  children,
  actions = null,
  title = null,
  subtitleChildren,
  loading = false,
  isFullHeight = false,
  classes
}) => (
  <div
    className={cls(
      styles.root,
      isFullHeight && styles.rootFullHeight,
      loading && styles.rootSkeleton,
      classes?.root
    )}
  >
    <Skeleton
      loading={loading}
      active={true}
      avatar={isFullHeight}
      title={!isFullHeight}
      className={styles.skeleton}
      paragraph={{ rows: 25 }}
    >
      <div className={cls(styles.header, classes?.header)}>
        <div className={cls(styles.headerWrapper, classes?.headerWrapper)}>
          {typeof title === 'string' ? (
            <h3 className={cls(styles.title, classes?.title)}>{title}</h3>
          ) : (
            title
          )}
          <div className={styles.actions}>{actions}</div>
        </div>
        {subtitleChildren}
      </div>
      {children}
    </Skeleton>
  </div>
)
