import { FC } from 'react'
import { connect } from 'react-redux'
import { RouteProps } from 'react-router-dom'
import {
  AccountTypeNames,
  BusinessAccountRoleNameEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { RoleRoute } from 'App/components'

export type TRoleRouteContainerOwnProps = RouteProps & {
  allowed?: boolean
  allowedAccountTypes?: AccountTypeNames[]
  allowedBusinessRoles?: BusinessAccountRoleNameEnum[]
  trackNotifications?: Set<ProducedNotificationsEnum>
}

export type TRoleRouteContainerProps = TRoleRouteContainerOwnProps &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  accountType: state.global.accountData?.type.name,
  role: state.global.accountData?.role?.name
})

export const RoleRouteContainer: FC<TRoleRouteContainerOwnProps> =
  connect(mapStateToProps)(RoleRoute)
