import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getLicensesRequest,
  GET_LICENSES,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as FileCertificateOutlineIcon } from 'assets/icons/FileCertificateOutline.svg'

import { List } from '../../common'
import { LicensesItem } from '../LicensesItem'
import { UserProfileLicensesButton } from '../../UserProfileLicenses'

const loadingSelector = createLoadingSelector([GET_LICENSES])

export const LicensesList = () => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) => state.userProfile.viewProfile.licenses.ids)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getLicensesRequest())
  }, [dispatch])

  return (
    <List
      loading={loading}
      ids={ids}
      item={LicensesItem}
      footer={<UserProfileLicensesButton />}
      emptyIcon={<FileCertificateOutlineIcon />}
      emptyText={t('userProfile.licenses.placeholder')}
    />
  )
}
