import { FC } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import { FileViewerProgress } from 'App/components'

import { useDownloadFile } from '../../../hooks/useDownloadFile'

import styles from './AudioViewer.module.scss'

export type TAudioViewerProps = PartialBy<Pick<IFilesEntity, 'id' | 'mimeType'>, 'mimeType'> & {
  source?: FileHistorySourcesEnum
  originalEntityId?: string
}

export const AudioViewer: FC<TAudioViewerProps> = (props) => {
  const { source, id, originalEntityId, mimeType } = props

  const { error, progress, src } = useDownloadFile({
    source,
    id,
    originalEntityId,
    type: mimeType
  })

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {progress !== 100 ? (
        <FileViewerProgress
          percent={progress}
          label={!!error ? t('common.fileViewer.failure') : undefined}
          status={!!error ? 'exception' : undefined}
        />
      ) : (
        <audio controls={true} src={src} className={styles.player} />
      )}
    </div>
  )
}
