import { useCallback, useEffect } from 'react'
import { ErrorCodesEnum } from '@medentee/enums'

import { isMatchErrorCode } from 'utils'
import { ESet2FAType } from 'enums'
import { ErrorModal, TwoFactorChecker } from 'App/components'
import { TSettingsSecurityAndLogin2FAConfirmContainerProps } from 'App/containers'

import styles from './SettingsSecurityAndLogin2FAConfirm.module.scss'

export type TSettingsSecurityAndLogin2FAConfirmBaseProps = {
  type: ESet2FAType
}

export type TSettingsSecurityAndLogin2FAConfirmProps =
  TSettingsSecurityAndLogin2FAConfirmBaseProps &
    Pick<
      TSettingsSecurityAndLogin2FAConfirmContainerProps,
      | 'error'
      | 'set2FASettings'
      | 'expiredTokenDate'
      | 'processing'
      | 'clearError'
      | 'clear2FASettings'
    >

export const SettingsSecurityAndLogin2FAConfirm = ({
  error,
  set2FASettings,
  type,
  expiredTokenDate,
  processing,
  clearError,
  clear2FASettings
}: TSettingsSecurityAndLogin2FAConfirmProps) => {
  const codeInvalid = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_INVALID)
  const codeExhausted = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_EXHAUSTED)

  const handleClearError = useCallback(() => {
    clearError(null)
  }, [clearError])

  const handleConfirm = useCallback(
    (code: string) => {
      set2FASettings({
        type,
        token: code
      })
    },
    [set2FASettings, type]
  )

  const handleResendCode = useCallback(() => {
    set2FASettings({
      type,
      isResend: true
    })
  }, [set2FASettings, type])

  useEffect(
    () => () => {
      clear2FASettings()
      clearError(null)
    },
    [clear2FASettings, clearError]
  )

  return (
    <div className={styles.root}>
      <TwoFactorChecker
        processing={processing}
        onConfirm={handleConfirm}
        onResendCode={handleResendCode}
        expiredTokenDate={expiredTokenDate}
        invalid={codeInvalid}
        onClearInvalid={handleClearError}
        codeExhausted={codeExhausted}
      />

      {!codeInvalid && !codeExhausted && <ErrorModal error={error} />}
    </div>
  )
}
