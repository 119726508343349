import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useLocation, useParams } from 'react-router-dom'
import { parse } from 'query-string'

import { CaseMembers } from 'App/components'
import { GET_CASE_SUMMARY, createLoadingSelector, selectChatAction, resetChatAction } from 'store'
import { State } from 'redux/rootReducer'
import { ECaseAccountType } from 'enums'

export type TCaseMembersContainerViewProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & { caseView?: string }

type TUrlParams = {
  id: string
  who?: ECaseAccountType
}

const CaseMembersContainerView = ({
  selectChat,
  resetChat,
  caseType,
  caseView
}: TCaseMembersContainerViewProps) => {
  const { search, pathname } = useLocation()
  const { chatId } = parse(search)
  const { who } = useParams<TUrlParams>()

  useEffect(() => {
    if (chatId && !Array.isArray(chatId)) {
      selectChat({
        nextChatId: chatId
      })
    }
  }, [chatId, pathname, selectChat])

  useEffect(
    () => () => {
      resetChat()
    },
    [resetChat]
  )

  return <CaseMembers caseView={caseView} who={who} caseType={caseType} />
}

const loadingSelector = createLoadingSelector([GET_CASE_SUMMARY])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  caseType: state.caseView.data?.type
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectChat: selectChatAction,
      resetChat: resetChatAction
    },
    dispatch
  )

export const CaseMembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMembersContainerView)
