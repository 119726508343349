import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { Sounds } from 'App/components'
import { toggleCallsOrSoundsRequest } from 'store'

export type TSoundsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  muteCalls: state.settings.accountAndNotifications.sounds.muteCalls,
  muteNotifications: state.settings.accountAndNotifications.sounds.muteNotifications
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleCallsOrSounds: toggleCallsOrSoundsRequest
    },
    dispatch
  )

export const SoundsContainer = connect(mapStateToProps, mapDispatchToProps)(Sounds)
