import { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { TCaseMembersItemInactiveContainerProps } from 'App/containers'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './CaseMembersItemRemoveIcon.module.scss'

export type TCaseMembersItemRemoveIconProps = Pick<
  TCaseMembersItemInactiveContainerProps,
  'removeMemberFromCase'
> & {
  memberId: string
  isCaseOwner: boolean
}

type TParams = {
  id: string
}

export const CaseMembersItemRemoveIcon = ({
  memberId,
  isCaseOwner,
  removeMemberFromCase
}: TCaseMembersItemRemoveIconProps) => {
  const {
    params: { id: caseId }
  } = useRouteMatch<TParams>()

  const handleRemove = useCallback(
    () => removeMemberFromCase({ memberId, caseId }),
    [caseId, memberId, removeMemberFromCase]
  )

  return isCaseOwner ? <CrossIcon className={styles.root} onClick={handleRemove} /> : null
}
