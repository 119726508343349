import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { InvitationsSentNewUsers } from 'App/components'
import {
  createLoadingSelector,
  getInvitationsNewUsersRequest,
  GET_INVITATIONS_NEW_USERS,
  resetInvitationsNewUsers
} from 'store'

export type TInvitationsSentNewUsersContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_INVITATIONS_NEW_USERS])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  ids: state.invitations.sent.newUsers.ids,
  total: state.invitations.sent.newUsers.pagination.total,
  showBy: state.invitations.sent.newUsers.pagination.showBy
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvitationsNewUsers: getInvitationsNewUsersRequest,
      resetInvitationsNewUsers
    },
    dispatch
  )

export const InvitationsSentNewUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationsSentNewUsers)
