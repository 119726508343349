import { put, takeLatest, call, select } from 'redux-saga/effects'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import { QueryBuilder } from 'utils'
import { IBalanceDTO, IOperationsHistoryDTO } from 'interfaces'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { State } from 'redux/rootReducer'
import { showModalAction } from 'store/modal'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { AUTO_TOP_UP_SET, TPaymentsHistoryFilters } from 'store'
import { handleError } from 'api/utils'

import {
  getOperationsHistoryError,
  getOperationsHistoryRequest,
  getOperationsHistorySuccess,
  getUserBalanceError,
  getUserBalanceRequest,
  getUserBalanceSuccess,
  receivePaymentSucceeded,
  transferMDTError,
  transferMDTRequest,
  transferMDTSuccess
} from './payments.actions'
import {
  GET_USER_BALANCE_REQUEST,
  GET_OPERATIONS_HISTORY_REQUEST,
  RECEIVE_PAYMENT_SUCCEEDED,
  RECEIVE_PAYMENT_FAILED,
  TRANSFER_MDT_REQUEST
} from './payments.actionTypes'

function* getUserBalanceSaga() {
  try {
    const { data }: APIData<IBalanceDTO> = yield call(api.get, API.FINANCES_BALANCE)

    yield put(getUserBalanceSuccess(data))
  } catch (e) {
    yield put(getUserBalanceError(e))
    yield handleError(e)
  }
}

function* getOperationsHistorySaga({
  payload: { page }
}: ReturnType<typeof getOperationsHistoryRequest>) {
  try {
    const { dateRange, operationType }: TPaymentsHistoryFilters = yield select(
      (state: State) => state.payments.history.filters
    )

    const urlBuilder = new QueryBuilder(API.FINANCES_OPERATIONS)
      .page(page)
      .showBy(PAGINATION_DEFAULT_SHOW_BY)
      .multiSearch('types', operationType)

    if (dateRange) {
      urlBuilder.custom('fromDate', dateRange[0]?.toISOString())
      urlBuilder.custom('toDate', dateRange[1]?.toISOString())
    }

    const url = urlBuilder.build()

    const {
      data: { results, total }
    }: APIResultsResponse<IOperationsHistoryDTO[]> = yield call(api.get, url)

    const data = {
      data: results,
      total,
      page
    }

    yield put(getOperationsHistorySuccess(data))
  } catch (e) {
    yield put(getOperationsHistoryError(e))
    yield handleError(e)
  }
}

function* receiveSucceededPaymentSaga({
  payload: {
    payload: { tokenAmount }
  }
}: ReturnType<typeof receivePaymentSucceeded>) {
  const currentModalType: EModalComponents = yield select((state: State) => state.modal.modalType)

  if (
    currentModalType !== EModalComponents.TOP_UP_HOLD_ON &&
    currentModalType !== EModalComponents.TOP_UP_PAYMENT_FAILED
  ) {
    return
  }

  yield put(getUserBalanceRequest())

  yield put(
    showModalAction({
      modalType: EModalComponents.TOP_UP_PAYMENT_SUCCEEDED,
      modalTitle: null,
      modalProps: { tokenAmount }
    })
  )
}

function* receiveFailedPaymentSaga() {
  const currentModalType: EModalComponents = yield select((state: State) => state.modal.modalType)

  if (currentModalType !== EModalComponents.TOP_UP_HOLD_ON) {
    return
  }

  yield put(
    showModalAction({
      modalType: EModalComponents.TOP_UP_PAYMENT_FAILED,
      modalTitle: null
    })
  )
}
function* transferMDTSaga({
  payload: { amount, accountIds, onSuccess }
}: ReturnType<typeof transferMDTRequest>) {
  try {
    yield call(api.post, API.FINANCES_TRANSFER, { amount, accountIds })

    yield put(transferMDTSuccess())

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(transferMDTError(e))
    yield handleError(e)
  }
}

function* receiveSucceededMonthlyTopUpSaga() {
  const currentModalType: EModalComponents = yield select((state: State) => state.modal.modalType)

  if (
    currentModalType !== EModalComponents.TOP_UP_HOLD_ON &&
    currentModalType !== EModalComponents.TOP_UP_PAYMENT_FAILED
  ) {
    return
  }

  yield put(
    showModalAction({
      modalType: EModalComponents.TOP_UP_MONTHLY_SUCCEEDED,
      modalTitle: null
    })
  )
}

export function* paymentsSaga() {
  yield takeLatest(GET_USER_BALANCE_REQUEST, getUserBalanceSaga)
  yield takeLatest(GET_OPERATIONS_HISTORY_REQUEST, getOperationsHistorySaga)
  yield takeLatest(RECEIVE_PAYMENT_SUCCEEDED, receiveSucceededPaymentSaga)
  yield takeLatest(AUTO_TOP_UP_SET, receiveSucceededMonthlyTopUpSaga)
  yield takeLatest(RECEIVE_PAYMENT_FAILED, receiveFailedPaymentSaga)
  yield takeLatest(TRANSFER_MDT_REQUEST, transferMDTSaga)
}
