import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  getActiveSubscriptionsRequest,
  resetSubscriptions,
  GET_ACTIVE_SUBSCRIPTIONS
} from 'store'
import { ActiveSubscriptionsList } from 'App/components'

export type TActiveSubscriptionsListContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_ACTIVE_SUBSCRIPTIONS])

const mapStateToProps = (state: State) => ({
  ids: state.subscriptions.active.ids,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getActiveSubscriptions: getActiveSubscriptionsRequest,
      resetSubscriptions
    },
    dispatch
  )

export const ActiveSubscriptionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveSubscriptionsList)
