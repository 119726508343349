import { ReactNode } from 'react'

import styles from './ChatDefaultMessageText.module.scss'

export type TChatDefaultMessageTextProps = {
  text: ReactNode
}

export const ChatDefaultMessageText = ({ text }: TChatDefaultMessageTextProps) => (
  <div className={styles.root}>{text}</div>
)
