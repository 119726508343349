import { useCallback, useState } from 'react'

export const useToggle = (defaultValue = false) => {
  const [value, setValue] = useState<boolean>(defaultValue)

  const toggle = useCallback(() => setValue((prev) => !prev), [])

  const toggleOn = useCallback(() => setValue(true), [])

  const toggleOff = useCallback(() => setValue(false), [])

  return {
    value,
    setValue,
    toggle,
    toggleOn,
    toggleOff
  }
}
