import { useCallback, useEffect } from 'react'

import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { useAppDispatch, useAppSelector } from 'store/store.utils'
import { getAdvertsRequest, resetAdverts, GET_ADVERTS } from 'store/adverts'
import { createLoadingSelector } from 'store/loading/loading.selectors'
import { InfiniteScroll } from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'
import { ADVERTS_WRAPPER_ID, DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { generateRandomArray } from 'utils'

import { ConnectorItem } from '../ConnectorItem'
import { AdvertsEmptyList } from '../../common'

const loadingSelector = createLoadingSelector([GET_ADVERTS])

export const ConnectorList = () => {
  const { isDesktop } = useAdaptiveLayout()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingSelector)
  const { ids, total } = useAppSelector((state) => state.adverts.connector)
  const { locations, professions, purposes, specializations, current } = useAppSelector(
    (state) => state.adverts.filters
  )
  const search = useAppSelector((state) => state.adverts.search)
  const lastUnixTs = useAppSelector(
    (state) => state.adverts.connector.list[ids[ids.length - 1]]?.unixTs
  )

  const advertsIds = loading && !lastUnixTs ? generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH) : ids

  useEffect(() => {
    dispatch(getAdvertsRequest())
    // Filters are required to trigger list update
  }, [dispatch, locations, professions, purposes, specializations, search])

  useEffect(
    () => () => {
      dispatch(resetAdverts())
    },
    [dispatch]
  )

  const handleScrollDown = useCallback(
    (nextPage: number) => {
      dispatch(getAdvertsRequest({ unixTs: lastUnixTs, current: nextPage }))
    },
    [dispatch, lastUnixTs]
  )

  if (!loading && !advertsIds.length) {
    return (
      <AdvertsEmptyList
        search={search}
        filters={{ locations, professions, purposes, specializations }}
      />
    )
  }

  return (
    <InfiniteScroll
      scrollableTarget={isDesktop && ADVERTS_WRAPPER_ID}
      next={handleScrollDown}
      loading={loading}
      total={total}
      page={current}
      dataLength={advertsIds.length}
      limit={PAGINATION_DEFAULT_SHOW_BY}
    >
      {advertsIds.map((id) => (
        <ConnectorItem key={id} id={id} loading={loading} />
      ))}
    </InfiniteScroll>
  )
}
