import { useCallback, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'
import { AccountTypeNames } from '@medentee/enums'

import { usePinnedChannels } from 'App/components/Organizations/hooks'
import { UnityHubRoomsNavCollapse, TUnityHubRoomsNavCollapseView } from 'App/components/UnityHub'
import { accountTypeNameSelector, selectHighlightedChatIds, useAppSelector } from 'store'

import { EventsHighlightRoom, TEventsHighlightRoomProps } from '../EventsHighlightRoom'
import { EventsChannelRoom } from '../EventsChannelRoom'
import { useEventChannelKebabMenu } from '../useEventChannelKebabMenu'

type TEventsRoomsNavHighlightsProps = {
  eventId: string
}

export const EventsRoomsNavHighlights = ({ eventId }: TEventsRoomsNavHighlightsProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const match = useRouteMatch<{ path?: string }>(`/events/${eventId}/highlights/:path`)

  const ids = useAppSelector(selectHighlightedChatIds)
  const accountType = useAppSelector(accountTypeNameSelector)

  const { getMenuItems } = useEventChannelKebabMenu({ eventId })

  const { pinnedIds } = usePinnedChannels({ ids, entity: 'events', entityId: eventId })

  const { t } = useTranslation()

  const shouldShowPinnedRooms =
    (Boolean(pinnedIds.length) && isCollapsed) || (match?.isExact && isCollapsed)

  const handleChangeView = useCallback((view: TUnityHubRoomsNavCollapseView) => {
    setIsCollapsed(view === 'collapsed')
  }, [])

  const highlights: (Omit<TEventsHighlightRoomProps, 'eventId'> & { hidden?: boolean })[] = useMemo(
    () => [
      {
        variant: 'highlights',
        name: t('events.highlights.info'),
        iconVariant: 'info',
        path: 'info'
      },
      {
        hidden: accountType !== AccountTypeNames.PROFESSIONAL,
        variant: 'highlights',
        name: t('events.highlights.profile'),
        iconVariant: 'profile',
        path: 'profile'
      },
      {
        variant: 'highlights',
        name: t('events.highlights.networking'),
        iconVariant: 'networking',
        path: 'networking'
      }
    ],
    [t, accountType]
  )

  return (
    <>
      <UnityHubRoomsNavCollapse
        variant="highlights"
        title={t('events.highlights.label')}
        localStorageKey={`event-highlights-${eventId}`}
        onChangeView={handleChangeView}
      >
        <>
          {highlights
            .filter(({ hidden }) => !hidden)
            .map(({ variant, iconVariant, name, path }) => (
              <EventsHighlightRoom
                key={path}
                name={name}
                variant={variant}
                iconVariant={iconVariant}
                path={path}
                eventId={eventId}
              />
            ))}

          {ids.map((id) => (
            <EventsChannelRoom
              key={id}
              chatRoomId={id}
              eventId={eventId}
              getMenuItems={getMenuItems}
            />
          ))}
        </>
      </UnityHubRoomsNavCollapse>

      {shouldShowPinnedRooms && (
        <div>
          {highlights
            .filter(({ hidden }) => !hidden)
            .filter((item) => item.path === match?.params?.path)
            .map(({ variant, iconVariant, name, path }) => (
              <EventsHighlightRoom
                key={path}
                name={name}
                variant={variant}
                iconVariant={iconVariant}
                path={path}
                eventId={eventId}
              />
            ))}

          {pinnedIds.map((id) => (
            <EventsChannelRoom
              key={id}
              chatRoomId={id}
              eventId={eventId}
              getMenuItems={getMenuItems}
            />
          ))}
        </div>
      )}
    </>
  )
}
