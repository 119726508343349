import cls from 'classnames'
import { CaseClassificationNameEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { formatShortDate } from 'utils'
import { CaseType, IconLabel, CaseClassification } from 'App/components'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'

import styles from './CaseItemInformation.module.scss'

export type TCaseItemInformationProps = {
  type: CaseTypesEnum
  classification: CaseClassificationNameEnum

  createdAt?: string | Date
  updatedAt?: string | Date
  getUpdatedAtLabel?: (date: string) => string
  getCreatedAtLabel?: (date: string) => string
}

export const CaseItemInformation = ({
  type,
  classification,
  createdAt,
  updatedAt,
  getUpdatedAtLabel,
  getCreatedAtLabel
}: TCaseItemInformationProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {classification === CaseClassificationNameEnum.PRIVATE && (
        <div className={styles.item}>
          <CaseClassification classification={classification} />
        </div>
      )}

      <div className={styles.item}>
        <CaseType type={type} classification={classification} />
      </div>

      {createdAt && (
        <div className={cls(styles.item, styles.date)}>
          <IconLabel
            icon={<ScheduleIcon />}
            label={
              (getCreatedAtLabel && getCreatedAtLabel(formatShortDate(createdAt))) ??
              t('cases.list.item.createdAt', { date: formatShortDate(createdAt) })
            }
          />
        </div>
      )}

      {updatedAt && (
        <div className={cls(styles.item, styles.date)}>
          <IconLabel
            icon={<ScheduleIcon />}
            label={getUpdatedAtLabel && getUpdatedAtLabel(formatShortDate(updatedAt))}
          />
        </div>
      )}
    </div>
  )
}
