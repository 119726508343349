import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { setContactsFilters, useAppDispatch, useAppSelector } from 'store'
import { EFilterTypes, TFilterItem, TFiltersProps, useFilters } from 'App/components'
import { FilterCategories } from 'App/containers'

type TUseContactsFiltersProps = Pick<TFiltersProps, 'classes'> & {
  onReset: () => void

  contactType?: AccountTypeNames
  accountType?: AccountTypeNames
}

export const useContactsFilters = ({ contactType, classes, onReset }: TUseContactsFiltersProps) => {
  const dispatch = useAppDispatch()

  const { online, selectedAccountTypes, selectedCategories, notAssigned } = useAppSelector(
    (state) => state.contacts.filters
  )

  const { t } = useTranslation()

  const CONTACTS_FILTER_OPTIONS = useMemo<TFilterItem[]>(
    () => [
      {
        title: t('contacts.filters.status'),
        sectionKey: 'online',
        items: [
          {
            filterKey: 'online',
            valueKey: 'key',
            labelKey: 'label',
            type: EFilterTypes.CHECKBOX,
            options: [
              {
                key: 'online',
                label: t('contacts.filters.online')
              }
            ]
          }
        ]
      },
      {
        title: t('contacts.filters.accountType'),
        sectionKey: 'selectedAccountTypes',
        items: [
          {
            filterKey: 'selectedAccountTypes',
            valueKey: 'key',
            labelKey: 'label',
            type: EFilterTypes.CHECKBOX,
            options: [
              {
                key: AccountTypeNames.PROFESSIONAL,
                label: t('contacts.filters.professionalAccount')
              },
              {
                key: AccountTypeNames.BUSINESS,
                label: t('contacts.filters.businessAccount')
              }
            ]
          }
        ]
      }
    ],
    [t]
  )

  return useFilters({
    value: {
      online,
      selectedAccountTypes
    },
    selectedFilter: [
      ...selectedCategories.map(({ id, name }) => ({ id, name })),
      ...(notAssigned ? [{ id: 'not-assigned', name: t('contacts.filters.noCategory') }] : [])
    ],
    classes,
    collapse: false,
    notCollapsedDivider: true,
    addonAfter: <FilterCategories contactType={contactType} />,
    filters: CONTACTS_FILTER_OPTIONS,
    onReset,
    onChange: (key, value) => dispatch(setContactsFilters({ [key]: value }))
  })
}
