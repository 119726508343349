import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
  BROADCAST_MESSAGE,
  createProcessingSelector,
  getBroadcastAudioBlobSelector,
  getBroadcastAudioUrlSelector,
  useAppSelector
} from 'store'
import { TBroadcastMessageDialogProps } from 'App/containers'
import { Alert } from 'App/components'

import { SelectAccounts, TSelectAccountsProps } from './SelectAccounts'
import { TextField } from './TextField'
import { AudioField } from './AudioField'
import styles from './MessageContainer.module.scss'

export type TMessageContainerProps = Pick<TBroadcastMessageDialogProps, 'recipientsType'> &
  Pick<TSelectAccountsProps, 'onOpenSelectRecipients' | 'organizationId'> & {
    readonly?: boolean
    textMessage: string
    setTextMessage: (message: string) => void
    onOpenAttach: () => void
  }
const processingSelector = createProcessingSelector([BROADCAST_MESSAGE])

export const MessageContainer = ({
  recipientsType,
  textMessage,
  readonly,
  organizationId,
  setTextMessage,
  onOpenSelectRecipients,
  onOpenAttach
}: TMessageContainerProps) => {
  const audioBlob = useAppSelector(getBroadcastAudioBlobSelector, isEqual)
  const audioUrl = useAppSelector(getBroadcastAudioUrlSelector)
  const processing = useAppSelector(processingSelector)

  const [isShowAudio, setShowAudio] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (!isShowAudio && audioBlob && audioUrl) {
      setShowAudio(true)
    }
  }, [audioBlob, audioUrl, isShowAudio])

  return (
    <div className={styles.root}>
      <Alert className={styles.alert} variant="info" size="md">
        {t('modal.broadcastMessage.messageStep.alert', { context: recipientsType })}
      </Alert>

      <SelectAccounts
        recipientsType={recipientsType}
        organizationId={organizationId}
        onOpenSelectRecipients={onOpenSelectRecipients}
      />

      <span className={styles.label}>{t('modal.broadcastMessage.messageStep.messageField')}</span>

      {isShowAudio ? (
        <AudioField
          processing={processing}
          setShowAudio={setShowAudio}
          onOpenAttach={onOpenAttach}
        />
      ) : (
        <TextField
          readonly={readonly}
          textMessage={textMessage}
          setTextMessage={setTextMessage}
          processing={processing}
          setShowAudio={setShowAudio}
          onOpenAttach={onOpenAttach}
        />
      )}
    </div>
  )
}
