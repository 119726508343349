import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { FC } from 'react'

import { State } from 'redux/rootReducer'
import {
  activateSubscriptionsRequest,
  ACTIVATE_SUBSCRIPTIONS,
  createLoadingSelector,
  getActiveSubscriptionsRequest,
  getAvailableSubscriptionsRequest,
  GET_AVAILABLE_SUBSCRIPTIONS,
  removeErrorNotification
} from 'store'
import { ChangeSubscriptionPlanDialog } from 'App/components/Subscriptions/ChangeSubscriptionPlanDialog'

export type TChangeSubscriptionPlanDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_AVAILABLE_SUBSCRIPTIONS])

const mapStateToProps = (state: State) => ({
  ids: state.subscriptions.available.ids,
  error: state.errors[ACTIVATE_SUBSCRIPTIONS],
  loading: loadingSelector(state),
  accountType: state.global.accountData?.type
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAvailableSubscriptions: getAvailableSubscriptionsRequest,
      getActiveSubscriptions: getActiveSubscriptionsRequest,
      removeErrorNotification: () => removeErrorNotification(ACTIVATE_SUBSCRIPTIONS),
      activateSubscriptions: activateSubscriptionsRequest
    },
    dispatch
  )

export const ChangeSubscriptionPlanDialogContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeSubscriptionPlanDialog)
