import { useCallback, useState } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { selectHighlightedChatIdsByType, useAppSelector } from 'store'
import { UnityHubRoomsNavCollapse, TUnityHubRoomsNavCollapseView } from 'App/components/UnityHub'
import { TChannelVariant } from 'interfaces'
import { usePinnedChannels } from 'App/components/Organizations/hooks'

import { EventsChannelRoom } from '../EventsChannelRoom'
import { EventCreateChannelButton } from '../../EventCreateChannels/EventCreateChannelButton'
import { useEventChannelKebabMenu } from '../useEventChannelKebabMenu'

export type TEventsRoomsNavTextListProps = {
  variant: Extract<TChannelVariant, 'chat' | 'news'>
  eventId: string
}

export const EventsRoomsNavTextList = ({ variant, eventId }: TEventsRoomsNavTextListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const chatType = variant === 'news' ? ChatTypeEnum.EVENT_NEWS : ChatTypeEnum.EVENT_CHANNEL

  const ids = useAppSelector(selectHighlightedChatIdsByType(chatType, false))

  const { getMenuItems } = useEventChannelKebabMenu({ eventId })

  const { pinnedIds } = usePinnedChannels({ ids, entity: 'events', entityId: eventId })

  const { t } = useTranslation()

  const shouldShowPinnedRooms = Boolean(pinnedIds.length) && isCollapsed

  const handleChangeView = useCallback((view: TUnityHubRoomsNavCollapseView) => {
    setIsCollapsed(view === 'collapsed')
  }, [])

  return (
    <>
      <UnityHubRoomsNavCollapse
        variant={variant}
        title={
          variant === 'news'
            ? t('unityHub.channelsList.newsChannelsLabel')
            : t('unityHub.channelsList.textChannelsLabel')
        }
        localStorageKey={`event-${eventId}`}
        action={<EventCreateChannelButton variant={variant} eventId={eventId} />}
        onChangeView={handleChangeView}
      >
        {ids.map((id) => (
          <EventsChannelRoom
            key={id}
            defaultVariant={variant}
            chatRoomId={id}
            eventId={eventId}
            getMenuItems={getMenuItems}
          />
        ))}
      </UnityHubRoomsNavCollapse>

      {shouldShowPinnedRooms && (
        <div>
          {pinnedIds.map((id) => (
            <EventsChannelRoom
              key={id}
              defaultVariant={variant}
              chatRoomId={id}
              eventId={eventId}
              getMenuItems={getMenuItems}
            />
          ))}
        </div>
      )}
    </>
  )
}
