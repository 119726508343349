import { FC } from 'react'
import cls from 'classnames'

import styles from './Chip.module.scss'

export const Chip: FC<PropsWithClassName> = ({ children, className, ...props }) => (
  <div {...props} className={cls(styles.root, className)}>
    {children}
  </div>
)
