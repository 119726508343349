import { memo } from 'react'
import { Link } from 'react-router-dom'
import { AccountTypeNames } from '@medentee/enums'
import { Skeleton } from 'antd'

import { EAvatarSize } from 'enums'
import { AvatarContainer, TContactsItemContainerProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import {
  AccountName,
  DisplayCategories,
  AccountType,
  DisplayLocationTime,
  DisplayProfessionsSpecializations,
  ContextMenu
} from 'App/components'
import { getMapComponent, getProfileLink } from 'utils'

import { Actions } from './Actions'
import styles from './ContactsItem.module.scss'
import { useContactsItemKebabMenu } from './useContactsItemKebabMenu'

// ------------------ Types ------------------
export type TContactsItemProps = TContactsItemContainerProps

// ------------------  END  ------------------

const ACCOUNT_DESCRIPTION = new Map<AccountTypeNames, () => JSX.Element>()
  .set(AccountTypeNames.BUSINESS, () => <>Business account</>)
  .set(AccountTypeNames.PROFESSIONAL, () => <>Professional account</>)

const SKELETON_PARAGRAPHS_LENGTH = 3

const ContactsItemView = ({ categories, chatId, to, id, loading }: TContactsItemProps) => {
  const { isMobile } = useAdaptiveLayout()

  const {
    lastName,
    firstName,
    city,
    country,
    type,
    professions,
    id: userId,
    displayUserName
  } = to || {}

  const pathToUserProfile = getProfileLink(userId)

  const { getMenuItems } = useContactsItemKebabMenu({
    defaultCategories: categories ?? [],
    contactId: id,
    userId,
    chatId
  })

  return (
    <Skeleton
      active={true}
      avatar={true}
      title={false}
      paragraph={{ rows: SKELETON_PARAGRAPHS_LENGTH, width: '100%' }}
      loading={loading}
      className={styles.skeleton}
    >
      <div className={styles.root}>
        <div className={styles.rootIntro}>
          <div className={styles.avatar}>
            <Link to={pathToUserProfile}>
              <AvatarContainer
                userId={userId}
                size={EAvatarSize.LG}
                firstName={firstName}
                lastName={lastName}
                displayUserName={displayUserName}
                type={type?.name}
                hasTooltip={false}
              />
            </Link>
          </div>
          {!isMobile && <Actions chatId={chatId} userName={displayUserName} />}
        </div>
        <div className={styles.rootInfo}>
          <div className={styles.account}>
            <Link to={pathToUserProfile}>
              <AccountName
                displayUserName={displayUserName}
                type={type?.name}
                hideIcon={isMobile}
                classes={{
                  root: styles.accountRoot
                }}
              />
            </Link>
            {isMobile && <Actions chatId={chatId} userName={displayUserName} />}
          </div>
          {isMobile && (
            <div className={styles.accountType}>
              <AccountType type={type?.name} />
              {getMapComponent(ACCOUNT_DESCRIPTION, type?.name)}
            </div>
          )}
          <div className={styles.location}>
            <DisplayLocationTime
              city={city?.cityName}
              country={country?.countryName ?? ''}
              timeZone={city?.timezone}
            />
          </div>
          <DisplayProfessionsSpecializations
            data={professions}
            classes={{ root: styles.professions }}
          />
          <DisplayCategories categories={categories} />
        </div>
        {getMenuItems && (
          <div className={styles.menuContainer}>
            <ContextMenu classes={{ root: styles.contextMenuRoot }} menuItems={getMenuItems()} />
          </div>
        )}
      </div>
    </Skeleton>
  )
}

export const ContactsItem = memo(ContactsItemView)
