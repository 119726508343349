import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import {
  resetCaseListAction,
  resetCaseFilterAction,
  setCaseListSearchAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { casesNestedRoutes } from 'App/App.config'
import { useAdaptiveLayout } from 'App/hooks'
import { EModalComponents } from 'App/containers'
import { PageWrapper, SearchCascader, SearchWrapper } from 'App/components'
import { ReactComponent as CaseCreateIcon } from 'assets/icons/CaseCreate.svg'

import { useCasesFilter } from './useCasesFilter'
import { useCasesRoutes } from './useCasesRoutes'
import { useCasesSearchOptions } from './useCasesSearchOptions'

export const Cases = () => {
  const { location } = useHistory()

  const { isDesktop } = useAdaptiveLayout()

  const dispatch = useAppDispatch()

  const accountType = useAppSelector((state) => state.global.accountData?.type.name)
  const page = useAppSelector((state) => state.cases.filters.page)

  const resetFilter = useCallback(() => dispatch(resetCaseFilterAction()), [dispatch])

  const { defaultPath, navMenu } = useCasesRoutes()

  const { t } = useTranslation()

  const isDefaultPath = location.pathname === defaultPath

  const { filters, content, selectedFilters } = useCasesFilter({ onReset: resetFilter })

  const searchOptions = useCasesSearchOptions(accountType)

  const handleSearch = useCallback(
    (search: string, searchBy: (string | number)[]) => {
      dispatch(
        setCaseListSearchAction({ search, searchBy: searchBy[searchBy.length - 1].toString() })
      )
    },
    [dispatch]
  )
  const resetPage = useCallback(() => dispatch(resetCaseListAction()), [dispatch])

  return (
    <PageWrapper
      navMenu={navMenu}
      defaultPath={defaultPath}
      nestedRoutes={casesNestedRoutes}
      autoScrollOnPagination={isDefaultPath ? page : undefined}
      filterContent={isDefaultPath ? content : undefined}
      selectedFilters={isDefaultPath ? selectedFilters : undefined}
      aside={{
        buttonText: t('cases.aside.createButton'),
        modalTitle: t('cases.modal.createCase.title'),
        modalType: EModalComponents.CASE_CREATE_DIALOG,
        title: t('cases.aside.title'),
        icon: <CaseCreateIcon />
      }}
      search={
        isDefaultPath ? (
          <SearchWrapper filters={filters} hiddenFilter={isDesktop}>
            <SearchCascader
              searchSize={EInputSize.L}
              options={searchOptions}
              onSearchChange={handleSearch}
              onCascaderChange={handleSearch}
            />
          </SearchWrapper>
        ) : undefined
      }
      resetPage={resetPage}
      resetFilter={resetFilter}
    />
  )
}
