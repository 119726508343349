import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'

import {
  createProcessingSelector,
  removeMemberFromCaseRequest,
  removeMemberFromCaseError,
  REMOVE_MEMBER_FROM_CASE
} from 'store'
import { State } from 'redux/rootReducer'
import { Button, ConfirmationModal } from 'App/components'

import styles from './CaseRemoveMemberConfirm.module.scss'

export type TCaseRemoveMemberConfirmBaseProps = {
  caseId: string
  memberId: string
  description: string
  submitLabel: string
}

export type TCaseRemoveMemberConfirmProps = TCaseRemoveMemberConfirmBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseRemoveMemberConfirmComponent: FC<TCaseRemoveMemberConfirmProps> = ({
  processing,
  caseId,
  memberId,
  error,
  description,
  submitLabel,
  removeMemberFromCase,
  setRemoveMemberFromCaseError
}) => (
  <ConfirmationModal
    error={error}
    content={description}
    resetError={() => setRemoveMemberFromCaseError(null)}
    controls={
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={() =>
          removeMemberFromCase({
            caseId,
            memberId
          })
        }
      >
        {submitLabel}
      </Button>
    }
  />
)
const processingSelector = createProcessingSelector([REMOVE_MEMBER_FROM_CASE])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.caseMembers.remove.error
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeMemberFromCase: removeMemberFromCaseRequest,
      setRemoveMemberFromCaseError: removeMemberFromCaseError
    },
    dispatch
  )

export const CaseRemoveMemberConfirm: FC<TCaseRemoveMemberConfirmBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseRemoveMemberConfirmComponent)
