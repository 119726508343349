import { useMemo } from 'react'
import { CaseStatusesEnum, FileHistorySourcesEnum } from '@medentee/enums'

import { TCaseCloudFilesItemContainer } from 'App/containers'
import { TCaseViewSelectValue, TFilesViewVariant } from 'App/components'
import { createSlides, ECardSize, TAccount } from 'store'
import {
  FileTilesViewGridItem,
  FileTilesViewOwnerAndDate,
  TUseSelection
} from 'App/components/File'
import { useAdaptiveLayout } from 'App/hooks'

import { CaseCloudFilesListItem } from '../CaseCloudFilesListItem'
import { TGetKebabMenu } from '../CaseCloud/hooks/useKebabMenu'

import styles from './CaseCloudFilesItem.module.scss'

export type TCaseCloudFilesItemBaseProps = {
  id: string
  caseStatus: CaseStatusesEnum
  originalEntityId: string

  isMember?: boolean
  getKebabMenu?: TGetKebabMenu
  source?: FileHistorySourcesEnum
  fileOwner?: TAccount
  caseView?: TCaseViewSelectValue
  selected?: boolean
  filesView?: TFilesViewVariant
  sharedAt?: string | Date
  selectedIdsLength?: number
} & Pick<TUseSelection, 'onSelect' | 'selectMode'>

export type TCaseCloudFilesItemProps = TCaseCloudFilesItemBaseProps &
  PartialBy<TCaseCloudFilesItemContainer, 'videoMetadata'>

export const CaseCloudFilesItem = ({
  extension,
  fileName,
  id,
  originalEntityId,
  fileOwner,
  selected,
  caseStatus,
  caseView,
  filesView,
  thumbnailUrl,
  sharedAt,
  videoMetadata,
  selectMode,
  mimeType,
  slidesEntity,
  getKebabMenu,
  onSelect,
  source = FileHistorySourcesEnum.CASE_CLOUD,
  selectedIdsLength = 0
}: TCaseCloudFilesItemProps) => {
  const { isDesktop } = useAdaptiveLayout()
  const isUnopenable = videoMetadata?.quality === null

  const slides = useMemo(
    () => createSlides(slidesEntity, source, originalEntityId),
    [originalEntityId, slidesEntity, source]
  )

  const menuItems = useMemo(
    () =>
      selectedIdsLength < 2 && getKebabMenu
        ? getKebabMenu({
            fileName,
            fileId: id,
            fileExtension: extension,
            mimeType,
            isUnopenable,
            slidesEntity
          })
        : undefined,
    [extension, fileName, getKebabMenu, id, isUnopenable, selectedIdsLength, mimeType, slidesEntity]
  )

  return filesView === 'grid' ? (
    <FileTilesViewGridItem
      classes={
        isDesktop
          ? {
              root: styles.grid,
              containerThumbnail: styles.containerThumbnail,
              checkbox: styles.checkbox,
              footer: styles.footer,
              addonAfter: styles.addonAfter,
              contextMenu: styles.contextMenu,
              videoStatus: styles.videoStatus
            }
          : undefined
      }
      fileId={id}
      extension={extension}
      fileName={fileName}
      fileNameDisabled={isUnopenable}
      selected={selected}
      selectMode={selectMode}
      thumbnailUrl={thumbnailUrl}
      addonAfter={
        fileOwner &&
        sharedAt && (
          <FileTilesViewOwnerAndDate
            classes={isDesktop ? { name: styles.addonName, date: styles.addonDate } : undefined}
            name={fileOwner.displayUserName}
            date={sharedAt}
          />
        )
      }
      menuItems={menuItems}
      openHandlerProps={{
        allowPosition: true,
        allowSize: true,
        size: ECardSize.COLLAPSED,
        slides
      }}
      disableOpen={isUnopenable}
      onSelect={onSelect}
      mimeType={mimeType}
    />
  ) : (
    <CaseCloudFilesListItem
      extension={extension}
      mimeType={mimeType}
      fileName={fileName}
      id={id}
      fileOwner={fileOwner}
      selected={selected}
      caseStatus={caseStatus}
      caseView={caseView}
      selectMode={selectMode}
      openHandlerProps={{
        allowPosition: true,
        allowSize: true,
        size: ECardSize.COLLAPSED,
        slides
      }}
      thumbnailUrl={thumbnailUrl}
      menuItems={menuItems}
      isUnopenable={isUnopenable}
      videoMetadata={videoMetadata}
      onSelect={onSelect}
    />
  )
}
