import { FC, useCallback } from 'react'
import cls from 'classnames'

import { EKeyCodes } from 'enums'

import styles from './ChatRoomItemWrapper.module.scss'

export type TChatRoomItemWrapperProps = PropsWithClassName<{
  isSelected: boolean
  onChatSelect: () => void
}>

export const ChatRoomItemWrapper: FC<TChatRoomItemWrapperProps> = ({
  children,
  isSelected,
  className,
  onChatSelect
}) => {
  const onKeyDown = useCallback(
    (event) => {
      if (event.keyCode === EKeyCodes.SPACE || event.keyCode === EKeyCodes.ENTER) {
        onChatSelect()
      }
    },
    [onChatSelect]
  )

  return (
    <button
      tabIndex={0}
      className={cls(
        styles.root,
        {
          [styles.active]: isSelected
        },
        className
      )}
      onClick={onChatSelect}
      onKeyDown={onKeyDown}
    >
      {children}
    </button>
  )
}
