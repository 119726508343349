import { useCallback } from 'react'

import { useToggle } from 'App/hooks'
import { DisplayProfessionsSpecializations } from 'App/components'
import { ICommunityMemberDTO } from 'interfaces/api/communities'
import {
  UnityHubNetworkingItem,
  TUnityHubNetworkingItemProps
} from 'App/components/UnityHub/UnityHubNetworkingItem'
import { queryClient } from 'queryClient'

import { CommunityMemberDrawer } from '../CommunityMemberDrawer'
import { COMMUNITY_MEMBERS_QUERY_KEY } from '../CommunityMembers'

import {
  useCommunityMemberItemKebabMenu,
  TUseCommunityMemberItemKebabMenuProps
} from './useCommunityMemberItemKebabMenu'

type TCommunityMembersItemProps = Pick<ICommunityMemberDTO, 'contact'> &
  Pick<TUseCommunityMemberItemKebabMenuProps, 'communityId' | 'isBusinessAccount'> &
  Pick<TUnityHubNetworkingItemProps, 'currentAccountId' | 'account' | 'chatId'>

export const CommunityMembersItem = ({
  account,
  contact,
  currentAccountId,
  chatId,
  communityId,
  isBusinessAccount
}: TCommunityMembersItemProps) => {
  const { professions, id: userId, displayUserName } = account || {}

  const { getMenuItems } = useCommunityMemberItemKebabMenu({
    communityId,
    isBusinessAccount
  })

  const menuItems = getMenuItems({
    chatId,
    userId,
    toId: contact?.toId,
    contactId: contact?.id,
    status: contact?.status,
    userName: displayUserName
  })

  const { value: isDrawerVisible, toggleOff: hideDrawer, toggleOn: showDrawer } = useToggle()

  const handleContactInviteSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [COMMUNITY_MEMBERS_QUERY_KEY], type: 'active' })
  }, [])

  return (
    <>
      <UnityHubNetworkingItem
        account={account}
        contact={contact}
        currentAccountId={currentAccountId}
        details={<DisplayProfessionsSpecializations data={professions} />}
        chatId={chatId}
        menuItems={menuItems}
        onAccountClick={showDrawer}
        onContactInviteSuccess={handleContactInviteSuccess}
      />
      <CommunityMemberDrawer
        memberId={account.id}
        accountId={currentAccountId}
        userName={displayUserName}
        visible={isDrawerVisible}
        contact={contact}
        onClose={hideDrawer}
      />
    </>
  )
}
