import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components'
import { getRelatedAccountsRequest, useAppDispatch, useAppSelector } from 'store'
import { switchAccount } from 'api/profile'

export type TUserProfileSwitchAccountDialogProps = {
  content?: string
  accountId?: string
  confirmText?: string
  onSuccess?: () => void
}

export const UserProfileSwitchAccountDialog = ({
  content,
  accountId,
  confirmText,
  onSuccess
}: TUserProfileSwitchAccountDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const professionalAccount = useAppSelector(
    (state) => state.global.accountData?.professionalAccount
  )

  useEffect(() => {
    dispatch(getRelatedAccountsRequest())
  }, [dispatch])

  const { mutate, isLoading, error } = useMutation({
    mutationKey: ['switch-account-to-professional'],
    mutationFn: switchAccount,
    onSuccess
  })

  const handleClick = useCallback(() => {
    const id = accountId ?? professionalAccount?.id
    if (!id) {
      return
    }

    mutate({ accountId: id })
  }, [accountId, mutate, professionalAccount?.id])

  return (
    <ConfirmationModal
      error={error}
      content={content ?? t('modal.switchAccountConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={handleClick}>
          {confirmText ?? t('modal.switchAccountConfirm.submitButton')}
        </Button>
      }
    />
  )
}
