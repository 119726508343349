import { ReactNode } from 'react'
import { Col, Row } from 'antd'

import { useAdaptiveLayout } from 'App/hooks'

type TWrapperProps = {
  avatar: ReactNode
  content: ReactNode
  action: ReactNode
}

export const Wrapper = ({ avatar, content, action }: TWrapperProps) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <Row gutter={[12, 0]} wrap={false} style={{ width: '100%' }}>
      <Col>{avatar}</Col>
      <Col flex={1}>
        <Row gutter={[14, 0]} wrap={!isDesktop} align={isDesktop ? 'middle' : 'top'}>
          <Col flex={1} style={{ width: isDesktop ? 'auto' : '100%' }}>
            {content}
          </Col>
          <Col>{action}</Col>
        </Row>
      </Col>
    </Row>
  )
}
