import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SET_CASE_CLOUD_PERMISSIONS_MODE } from 'store'
import { Button, ErrorModal } from 'App/components'
import { TCaseCloudPermissionsModeChangeConfirmContainerProps } from 'App/containers'

import styles from './CaseCloudPermissionsModeChangeConfirm.module.scss'

type TCaseCloudPermissionsModeChangeConfirmProps =
  TCaseCloudPermissionsModeChangeConfirmContainerProps

export const CaseCloudPermissionsModeChangeConfirm = ({
  caseId,
  permissionsMode,
  error,
  processing,
  removeErrorNotification,
  setCaseCloudPermissionsMode
}: TCaseCloudPermissionsModeChangeConfirmProps) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification(SET_CASE_CLOUD_PERMISSIONS_MODE)
    },
    [removeErrorNotification]
  )

  const handleClick = useCallback(() => {
    setCaseCloudPermissionsMode({ id: caseId, permissionsMode })
  }, [caseId, permissionsMode, setCaseCloudPermissionsMode])

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>
        {t('cases.modal.changePermissionModeConfirm.content', {
          context: permissionsMode.toLowerCase() as Lowercase<typeof permissionsMode>
        })}
      </p>

      <Button className={styles.rootButton} loading={processing} onClick={handleClick}>
        {t('cases.modal.changePermissionModeConfirm.submitButton', {
          context: permissionsMode.toLowerCase() as Lowercase<typeof permissionsMode>
        })}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
