import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { CasePendingList } from 'App/components'
import {
  getCasesInviteListRequest,
  setCasesInviteListPagination,
  resetCaseInviteListAction
} from 'store/casesInvite'

export type TInvitationCasesPendingContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  casesInvitationIds: state.casesInvite.ids,
  isLoading: state.casesInvite.isLoading,
  total: state.casesInvite.pagination.total,
  current: state.casesInvite.pagination.current,
  showBy: state.casesInvite.pagination.showBy
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadCasesInviteList: getCasesInviteListRequest,
      setCasesInviteListPagination,
      resetCaseInviteList: resetCaseInviteListAction
    },
    dispatch
  )

export const InvitationCasesPendingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasePendingList)
