import { useCallback, useState } from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button, ErrorModal, RelatedAccountBaseItem } from 'App/components'
import {
  TSettingsSecurityDefaultAccountDialogContainerProps,
  SettingsSecurityDefaultAccountItemContainer
} from 'App/containers'

import styles from './SettingsSecurityDefaultAccountDialog.module.scss'

export type TSettingsSecurityDefaultAccountDialogProps = Pick<
  TSettingsSecurityDefaultAccountDialogContainerProps,
  | 'error'
  | 'ids'
  | 'displayUserName'
  | 'defaultAccountId'
  | 'firstName'
  | 'lastName'
  | 'role'
  | 'type'
  | 'setDefaultAccount'
  | 'id'
  | 'disabledList'
>

export const SettingsSecurityDefaultAccountDialog = ({
  error,
  ids,
  displayUserName,
  defaultAccountId,
  firstName,
  lastName,
  role,
  type,
  setDefaultAccount,
  id,
  disabledList
}: TSettingsSecurityDefaultAccountDialogProps) => {
  const [selectedId, setSelectedId] = useState<string>(defaultAccountId)

  const { t } = useTranslation()

  const handleAccountChange = useCallback(() => {
    setDefaultAccount({ defaultAccountId: selectedId })
  }, [selectedId, setDefaultAccount])

  const handleChooseAccount = useCallback(({ target: { value } }: RadioChangeEvent) => {
    setSelectedId(value)
  }, [])

  return (
    <div className={styles.root}>
      <Radio.Group
        name="radiogroup"
        className={styles.group}
        defaultValue={selectedId}
        onChange={handleChooseAccount}
      >
        <Radio value={id} className={styles.radio}>
          <RelatedAccountBaseItem
            id={id}
            type={type}
            role={role}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            showOnline={false}
            hasTooltip={false}
          />
        </Radio>

        {ids.map((item) => {
          const disabled = disabledList.has(item)

          return (
            <Radio key={item} value={item} className={styles.radio} disabled={disabled}>
              <SettingsSecurityDefaultAccountItemContainer id={item} disabled={disabled} />
            </Radio>
          )
        })}
      </Radio.Group>

      <Button onClick={handleAccountChange} className={styles.rootButton}>
        {t('modal.setDefaultAccount.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
