import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { LeavePageConfirm } from 'App/components/LeavePageConfirm'
import { hideModalAction } from 'store/modal'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalAction
    },
    dispatch
  )

export const LeavePageConfirmContainer = connect(null, mapDispatchToProps)(LeavePageConfirm)
