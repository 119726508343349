import { useCallback, useMemo } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'
import {
  BusinessAccountDirectionEnum,
  BusinessAccountRoleIdEnum,
  BusinessAccountRoleNameEnum,
  BusinessAccountStatusEnum
} from '@medentee/enums'
import capitalize from 'lodash/capitalize'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { showModalAction, TBusinessUser, useAppDispatch } from 'store'
import { AvatarContainer, EModalComponents } from 'App/containers'
import { EAvatarSize, EBusinessAdminDeleteAction, EBusinessAdminConfirmFrom } from 'enums'
import { Button, ContextMenu, TMenuItemProps, TruncatedText } from 'App/components/common'
import {
  UserProfileAdministrationInviteLabel,
  UserProfileAdministrationRole,
  DisplayProfessionsSpecializations,
  DisplayLocationTime
} from 'App/components'
import { ReactComponent as AccountArrowRightOutlineIcon } from 'assets/icons/AccountArrowRightOutline.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'

import styles from './UserProfileAdministrationItem.module.scss'

export type TUserProfileAdministrationItemProps = {
  profile: TBusinessUser
  loading: boolean
  accountRoleId: BusinessAccountRoleIdEnum
  canAddSuperadmin: number
  online: boolean
  isBasicSubscription: boolean
  isMyProfile: boolean
}

export const UserProfileAdministrationItem = ({
  profile,
  loading,
  accountRoleId,
  canAddSuperadmin,
  online,
  isBasicSubscription,
  isMyProfile
}: TUserProfileAdministrationItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { accountId, account, roleId, role, status, id: businessUserId, alterRolePending } = profile
  const { firstName, lastName, type, professions, city, country, displayUserName } = account || {}
  const isSuperAdmin = accountRoleId === BusinessAccountRoleIdEnum.SUPERADMIN
  const isAdmin = accountRoleId === BusinessAccountRoleIdEnum.ADMIN
  const isPending = status === BusinessAccountStatusEnum.PENDING || alterRolePending

  const shouldShowResignButton = isMyProfile && isAdmin
  const shouldShowWithdrawButton = isSuperAdmin && isPending
  const shouldShowStatusLabel = isPending && isSuperAdmin

  const onTransferSuperAdminRole = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.ADD_SUPERADMIN_DIALOG,
        modalTitle: t('modal.addSuperadmin.title')
      })
    )
  }, [dispatch, t])

  const onResignFromRole = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.RESIGN_FROM_ADMIN_ROLE_CONFIRM,
        modalTitle: t('modal.resignFromAdminRoleConfirm.title'),
        modalProps: {
          businessUserId
        }
      })
    )
  }, [businessUserId, dispatch, t])

  const onRemoveAdmin = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.REMOVE_ADMIN_CONFIRM,
        modalTitle: t('modal.removeAdminConfirm.title'),
        modalProps: {
          businessUserId
        }
      })
    )
  }, [businessUserId, dispatch, t])

  const menuItems: TMenuItemProps[] = useMemo(
    () => [
      {
        content: t('organizations.administration.kebabMenu.transferSuperadminRole'),
        icon: <AccountArrowRightOutlineIcon />,
        onClick: onTransferSuperAdminRole,
        disabled: !canAddSuperadmin || isBasicSubscription,
        hidden: roleId === BusinessAccountRoleIdEnum.ADMIN
      },
      {
        content: t('organizations.administration.kebabMenu.removeAdmin'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: onRemoveAdmin,
        hidden: roleId === BusinessAccountRoleIdEnum.SUPERADMIN
      }
    ],
    [onTransferSuperAdminRole, onRemoveAdmin, canAddSuperadmin, roleId, isBasicSubscription, t]
  )

  const profileLink = `/profile/${accountId}`

  const handleWithdrawInvite = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id: profile.id,
          type: EBusinessAdminDeleteAction.WITHDRAW,
          from: EBusinessAdminConfirmFrom.ADMINISTRATION,
          onlyDowngrade: true,
          direction: BusinessAccountDirectionEnum.OUTGOING
        }
      })
    )
  }, [dispatch, profile.id, t])

  return (
    <Skeleton loading={loading} active={true} className={styles.skeleton}>
      {account && (
        <div className={styles.root}>
          <div className={styles.leftColumn}>
            <div className={styles.avatarWrapper}>
              <Link to={profileLink}>
                <AvatarContainer
                  userId={accountId}
                  size={EAvatarSize.LG}
                  firstName={firstName}
                  lastName={lastName}
                  displayUserName={displayUserName}
                  type={type.name}
                  hasTooltip={false}
                  showOnline={false}
                />
              </Link>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.headerWrapper}>
                <Link to={profileLink} className={styles.accountName}>
                  <TruncatedText text={displayUserName} />
                </Link>
                {status !== BusinessAccountStatusEnum.PENDING && (
                  <div className={styles.accountRole}>
                    <UserProfileAdministrationRole role={role?.name} online={online} />
                  </div>
                )}
              </div>
              <DisplayLocationTime
                city={city?.cityName}
                country={country.countryName}
                timeZone={city?.timezone}
              />
              {shouldShowStatusLabel && (
                <UserProfileAdministrationInviteLabel
                  role={
                    alterRolePending && status !== BusinessAccountStatusEnum.PENDING
                      ? BusinessAccountRoleNameEnum.SUPERADMIN
                      : role?.name
                  }
                />
              )}
              <DisplayProfessionsSpecializations
                data={professions}
                classes={{ root: styles.professions }}
              />
            </div>
          </div>
          <div className={styles.rightColumn}>
            {!isPending && isSuperAdmin && (
              <ContextMenu classes={{ icon: styles.contextMenuIcon }} menuItems={menuItems} />
            )}
            {shouldShowWithdrawButton && (
              <>
                <span className={styles.pendingText}>
                  {capitalize(t('enum.businessAccountStatusEnum.PENDING'))}
                </span>

                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleWithdrawInvite}
                  className={cls(styles.button, styles.buttonWithdraw)}
                >
                  {t('organizations.administration.withdrawInvitationButton')}
                </Button>
              </>
            )}
            {shouldShowResignButton && (
              <Button
                onClick={onResignFromRole}
                variant="outlined"
                size="sm"
                className={styles.button}
              >
                {t('organizations.administration.resignFromAdminButton')}
              </Button>
            )}
          </div>
        </div>
      )}
    </Skeleton>
  )
}
