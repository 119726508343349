import { useRef } from 'react'
import { InputRef } from 'antd'

import { EInputSize } from 'enums'
import { stopPropagation } from 'utils'

import { CopyToClipboard } from '../../CopyToClipboard'
import { Input } from '../Input'

import styles from './InvitationLinkField.module.scss'

export type TInvitationLinkFieldProps = {
  value: string
  className?: string
}

export const InvitationLinkField = ({ value }: TInvitationLinkFieldProps) => {
  const inputRef = useRef<InputRef>(null)

  const getContent = () => inputRef.current?.input?.value ?? ''

  return (
    <div className={styles.root} onClick={stopPropagation}>
      <Input readOnly bordered ref={inputRef} inputSize={EInputSize.XS} value={value} />
      <CopyToClipboard getContent={getContent} />
    </div>
  )
}
