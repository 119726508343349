import { Skeleton } from 'antd'

import { RelatedAccountBaseItem } from 'App/components'
import { TRelatedAccountsListItemContainerProps } from 'App/containers'
import { useInterruptionConfirm } from 'App/hooks'

import styles from './RelatedAccountsListItem.module.scss'

export type TRelatedAccountsListItemProps = TRelatedAccountsListItemContainerProps

export const RelatedAccountsListItem = ({
  id,
  type,
  firstName,
  lastName,
  displayUserName,
  loading,
  switchAccount,
  role,
  notificationsCount
}: TRelatedAccountsListItemProps) => {
  const handleSwitchAccount = (accountId: string) => switchAccount({ accountId })
  const handleClick = useInterruptionConfirm(handleSwitchAccount, 'switch')

  return (
    <Skeleton
      loading={loading}
      active={true}
      avatar={{ shape: 'circle' }}
      paragraph={{ rows: 1, width: '50%' }}
      title={{ width: '100%' }}
      className={styles.skeleton}
    >
      <div className={styles.wrapper}>
        <RelatedAccountBaseItem
          id={id}
          type={type}
          role={role}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          onClick={handleClick}
          notificationsCount={notificationsCount}
        />
      </div>
    </Skeleton>
  )
}
