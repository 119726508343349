import { useMemo } from 'react'
import { isToday, isTomorrow, isValid } from 'date-fns'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { TIds, useAppSelector } from 'store'
import { formatDate } from 'utils'

import { Meeting } from '../Meeting'

import styles from './MeetingGroup.module.scss'

type TMeetingGroupProps = {
  id: string
  loading: boolean

  anchorId?: string
}

export const MeetingGroup = ({ id, loading, anchorId }: TMeetingGroupProps) => {
  const meetingIds = useAppSelector<TIds | undefined>((state) => state.meetings.groupList[id])

  const { t } = useTranslation()

  const label = useMemo(() => {
    if (!isValid(new Date(id))) {
      return null
    }

    if (isToday(new Date(id))) {
      return t('modal.meetings.todayLabel')
    }

    if (isTomorrow(new Date(id))) {
      return t('modal.meetings.tomorrowLabel')
    }

    return formatDate(new Date(id), 'd MMMM')
  }, [id, t])

  return (
    <div className={styles.root}>
      <Skeleton active={true} loading={loading} avatar={true} title={true} paragraph={{ rows: 2 }}>
        {label && <span className={styles.dayLabel}>{label}</span>}
        {meetingIds?.map((meetingId) => (
          <Meeting key={meetingId} id={meetingId} anchorId={anchorId} />
        ))}
      </Skeleton>
    </div>
  )
}
