import { ReactElement, useCallback, useRef, ComponentType, useMemo } from 'react'

import { Popover, TPopoverProps } from 'App/components/common'

import { useClickAwayListener, useToggle } from '../../../hooks'

import { BadgeListValue } from './BadgeListValue'

export type TBadge = {
  id: string
  label: string

  className?: string
  disabled?: boolean
}

export type TBadgeListProps<L> = {
  list: L[]

  className?: string
  editable?: boolean
  removable?: boolean
  popoverContent?: ReactElement | ComponentType
  onRemove?: (id: string) => void
} & Pick<TPopoverProps, 'onVisibleChange' | 'placement' | 'align'>

export const BadgeList = <L extends TBadge>({
  list,
  onRemove,
  editable,
  className,
  popoverContent,
  onVisibleChange,
  removable = true,
  placement = 'bottomRight',
  align = { offset: [0, -31] }
}: TBadgeListProps<L>) => {
  const { value: visible, toggle, toggleOff } = useToggle()
  const contentRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const handleOutsideClick = useCallback(
    (_: MouseEvent | TouchEvent, outside: boolean) => {
      if (outside) {
        toggleOff()
      }
    },
    [toggleOff]
  )

  useClickAwayListener(contentRef, handleOutsideClick, popoverRef)

  const handleRemove = useMemo(
    () =>
      onRemove
        ? (id: string) => {
            if (onRemove) {
              onRemove(id)
            }
          }
        : undefined,
    [onRemove]
  )

  return (
    <Popover
      align={align}
      visible={visible}
      placement={placement}
      onVisibleChange={onVisibleChange}
      content={<div ref={popoverRef}>{popoverContent}</div>}
      getPopupContainer={(node) => node}
    >
      <BadgeListValue
        value={list}
        editable={editable}
        className={className}
        contentRef={contentRef}
        togglePopover={toggle}
        onRemove={onRemove && removable ? handleRemove : undefined}
      />
    </Popover>
  )
}
