import { useLayoutEffect, useRef, useState, useCallback, useMemo, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import cls from 'classnames'

import { EAvatarSize } from 'enums'

import { useToggle } from '../../../hooks'
import { Popover, TPopoverProps } from '../Popover'
import { AvatarContainer } from '../../../containers'
import { ClickAwayListener } from '../ClickAwayListener'
import { BadgeListValue, TBadgeListValueProps } from '../BadgeList/BadgeListValue'
import { TStatus } from '../StatusColored'
import { TBadge } from '../BadgeList'

import { AccountsListOthers } from './AccountsListOthers'
import {
  AccountsListPopover,
  TAccountsListPopoverProps,
  TAccountsItem
} from './AccountsListPopover'
import styles from './AccountsList.module.scss'

export type TAccountsListBasicProps<Status = TStatus> = Pick<
  TPopoverProps,
  'getPopupContainer' | 'placement'
> &
  Pick<TAccountsListPopoverProps<Status>, 'accounts' | 'getTitle' | 'onRemove' | 'getGroupLabel'> &
  Pick<TBadgeListValueProps<TAccountsItem>, 'hideChips'> & {
    avatarSize?: EAvatarSize
    overlap?: boolean
    limit?: number
    popoverVariant?: TAccountsListPopoverProps<Status>['variant']
  }

export type TVariant = 'chips'

export type TAccountsListProps<Status = TStatus> = TAccountsListBasicProps<Status> &
  (
    | {
        limit?: number
        variant?: never
        overlap?: boolean
      }
    | { variant: TVariant; limit?: never; overlap?: never }
  )

const avatarSizeMap: Record<EAvatarSize, number> = {
  [EAvatarSize.XXS]: 28,
  [EAvatarSize.XS]: 36,
  [EAvatarSize.SM]: 46,
  [EAvatarSize.MD]: 58,
  [EAvatarSize.LMD]: 62,
  [EAvatarSize.LG]: 70,
  [EAvatarSize.LLG]: 100
}

export const AccountsList = <Status extends TStatus>({
  accounts,
  placement,
  limit = 3,
  variant,
  popoverVariant,
  hideChips,
  overlap = true,
  avatarSize = EAvatarSize.SM,
  getTitle,
  getGroupLabel,
  getPopupContainer,
  onRemove
}: TAccountsListProps<Status>) => {
  const { value: visible, toggle, toggleOff: hidePopover } = useToggle()
  const [avatarAmount, setAvatarAmount] = useState(0)
  const wrapperEl = useRef<HTMLDivElement>(null)

  const hasHiddenItems = !variant && accounts.length > avatarAmount

  const calculateAvatarsAmount = useCallback(() => {
    const wrapperElement = wrapperEl.current

    if (wrapperElement) {
      const maxAmount = Math.floor(wrapperElement.offsetWidth / avatarSizeMap[avatarSize])

      if (limit) {
        setAvatarAmount(limit)
        return
      }

      setAvatarAmount(maxAmount > accounts.length ? accounts.length : maxAmount)
    }
  }, [accounts.length, avatarSize, limit])

  useLayoutEffect(() => {
    const el = wrapperEl.current

    const resizeObserver = new ResizeObserver(calculateAvatarsAmount)

    if (!variant) {
      calculateAvatarsAmount()
      el && resizeObserver.observe(el)
    }

    return () => {
      !variant && el && resizeObserver.unobserve(el)
    }
  }, [variant, calculateAvatarsAmount])

  useLayoutEffect(() => {
    const childNodes = wrapperEl.current?.childNodes

    childNodes?.forEach((node, key) => {
      const child = node as HTMLDivElement

      child.style.zIndex = `${childNodes?.length + key}`
    })
  }, [accounts, avatarAmount])

  const { mainAccounts, otherAccounts } = useMemo(() => {
    if (variant === 'chips') {
      return { mainAccounts: accounts, otherAccounts: [] }
    }

    const other = [...accounts]
    const main: TAccountsItem[] = other.splice(0, avatarAmount)

    return {
      mainAccounts: main,
      otherAccounts: other
    }
  }, [accounts, variant, avatarAmount])

  useEffect(() => {
    if (!hasHiddenItems) {
      hidePopover()
    }
  }, [hidePopover, hasHiddenItems])

  return (
    <Popover
      getPopupContainer={getPopupContainer}
      content={
        <AccountsListPopover<Status>
          accounts={accounts}
          variant={popoverVariant}
          onRemove={onRemove}
          getTitle={getTitle}
          getGroupLabel={getGroupLabel}
        />
      }
      visible={visible}
      placement={placement}
    >
      <ClickAwayListener onOutsideClick={hidePopover}>
        <div
          ref={wrapperEl}
          onClick={toggle}
          className={cls(styles.clickable, styles.membersWrapper, styles[avatarSize], {
            [styles.overlap]: !variant && overlap
          })}
        >
          {variant === 'chips' ? (
            <BadgeListValue
              editable={false}
              hideChips={hideChips}
              value={mainAccounts.map<TBadge>(({ id, displayUserName, disabled }) => ({
                id,
                label: displayUserName,
                disabled
              }))}
              onRemove={onRemove}
            />
          ) : (
            mainAccounts.map(
              ({ id, displayUserName, firstName, lastName, type }) =>
                firstName &&
                lastName && (
                  <AvatarContainer
                    key={id}
                    userId={id}
                    size={avatarSize}
                    displayUserName={displayUserName}
                    firstName={firstName}
                    lastName={lastName}
                    type={type?.name}
                    hasTooltip={false}
                    border={true}
                    className={styles.item}
                  />
                )
            )
          )}

          {hasHiddenItems && (
            <div className={styles.item}>
              <AccountsListOthers size={avatarSize} accounts={otherAccounts} />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Popover>
  )
}
