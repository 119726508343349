import { FC, useMemo, useState } from 'react'
import { Skeleton } from 'antd'

import { useQuery } from 'services/query'

import { createExternalMeeting, getExternalMeetingLink } from '../../../api'
import { InvitationLinkField } from '../../components/common/Fields/InvitationLinkField'

type TExternalMeetingLinkProps = {
  onCreate?: (id: string) => void
}
export const ExternalMeetingLink = ({ onCreate }: TExternalMeetingLinkProps) => {
  const { data } = useQuery({
    queryKey: ['create-external-meeting'],
    queryFn: createExternalMeeting,
    onSuccess: onCreate
      ? ({ id }) => {
          onCreate(id)
        }
      : undefined
  })

  const link = data ? getExternalMeetingLink(data.id) : ''

  return (
    <Skeleton active loading={!link} avatar={false} title paragraph={false}>
      <InvitationLinkField value={link} />
    </Skeleton>
  )
}

export const useExternalMeeting = () => {
  const [meetingId, setMeetingId] = useState<string | null>(null)
  const MeetingLink = useMemo<FC>(() => {
    const Comp: FC = () => <ExternalMeetingLink onCreate={setMeetingId} />
    Comp.displayName = 'ExternalMeetingLink'

    return Comp
  }, [setMeetingId])

  return {
    meetingId,
    MeetingLink
  }
}
