import { useState, useLayoutEffect, useCallback } from 'react'
import { Row } from 'antd'
import { SliderMarks } from 'antd/lib/slider'
import { useTranslation } from 'react-i18next'

import { IJitsiMeetAPI } from 'types'
import { Slider } from 'App/components'
import { JITSI_VIDEO_QUALITY_LEVELS } from 'globalConstants'

import styles from './CallPerformanceSettingsDialog.module.scss'

export type TCallPerformanceSettingsDialogProps = {
  jitsiAPI: IJitsiMeetAPI
}

const MARKS_LEVELS: Record<'LOW' | 'STANDARD' | 'HIGH' | 'ULTRA', number> = {
  LOW: 0,
  STANDARD: 30,
  HIGH: 70,
  ULTRA: 100
}

const MARKS: SliderMarks = {
  [MARKS_LEVELS.LOW]: <></>,
  [MARKS_LEVELS.STANDARD]: <></>,
  [MARKS_LEVELS.HIGH]: <></>,
  [MARKS_LEVELS.ULTRA]: <></>
}

const VIDEO_QUALITY: Record<number, number> = {
  [MARKS_LEVELS.LOW]: JITSI_VIDEO_QUALITY_LEVELS.LOW,
  [MARKS_LEVELS.STANDARD]: JITSI_VIDEO_QUALITY_LEVELS.STANDARD,
  [MARKS_LEVELS.HIGH]: JITSI_VIDEO_QUALITY_LEVELS.HIGH,
  [MARKS_LEVELS.ULTRA]: JITSI_VIDEO_QUALITY_LEVELS.ULTRA
}

const VIDEO_QUALITY_SLIDER_VALUE: Record<number, number> = {
  [JITSI_VIDEO_QUALITY_LEVELS.LOW]: MARKS_LEVELS.LOW,
  [JITSI_VIDEO_QUALITY_LEVELS.STANDARD]: MARKS_LEVELS.STANDARD,
  [JITSI_VIDEO_QUALITY_LEVELS.HIGH]: MARKS_LEVELS.HIGH,
  [JITSI_VIDEO_QUALITY_LEVELS.ULTRA]: MARKS_LEVELS.ULTRA
}

export const CallPerformanceSettingsDialog = ({
  jitsiAPI
}: TCallPerformanceSettingsDialogProps) => {
  const { t } = useTranslation()

  const [defaultVideoQuality, setDefaultVideoQuality] = useState<number | null>(null)
  const [currentVideoQuality, setCurrentVideoQuality] = useState<number>(MARKS_LEVELS.LOW)

  useLayoutEffect(() => {
    const videoQuality = jitsiAPI.getVideoQuality()

    setDefaultVideoQuality(videoQuality)
    setCurrentVideoQuality(VIDEO_QUALITY_SLIDER_VALUE[videoQuality] ?? MARKS_LEVELS.LOW)
  }, [jitsiAPI])

  const handleChange = useCallback(
    (value: number) => {
      setCurrentVideoQuality(value)
      jitsiAPI.executeCommand('setVideoQuality', VIDEO_QUALITY[value])
    },
    [jitsiAPI]
  )

  return (
    <>
      <h2 className={styles.subtitle}>{t('modal.callPerformanceSettings.subtitle')}</h2>

      {defaultVideoQuality !== null && (
        <div className={styles.wrapper}>
          <Row wrap={false} justify="space-between" align="middle">
            {t('modal.callPerformanceSettings.content', { returnObjects: true }).map((item) => (
              <span key={item} className={styles.markTitle}>
                {item}
              </span>
            ))}
          </Row>

          <Slider
            className={styles.slider}
            marks={MARKS}
            step={null}
            defaultValue={defaultVideoQuality}
            value={currentVideoQuality}
            onChange={handleChange}
          />
        </div>
      )}
    </>
  )
}
