import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { ChatStatusEnum } from '@medentee/enums'

import { ChatRoomP2P, ChatTypingIndicator } from 'App/components'
import { EChatViewType } from 'enums'
import {
  accountIdSelector,
  isBusinessAccountSelector,
  selectChatAction,
  useAppSelector
} from 'store'
import { TruncatedText } from 'App/components/common'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'
import { TUseKeepEntityPathProps, useSessionStorage } from 'App/hooks/useSessionStorage'

import { ChatContextProvider } from '../../../../context'
import { UnityHubChannelIcon, TUnityHubChannelIconProps } from '../../../UnityHub'

import styles from './ChatOrganization.module.scss'

type TChatOrganizationProps = Pick<TUnityHubChannelIconProps, 'variant'> &
  Pick<TUseKeepEntityPathProps, 'keySuffix'> & {
    id: string
    path: string
  }

const ChatOrganizationInternal = ({ path, variant, id, keySuffix }: TChatOrganizationProps) => {
  const dispatch = useDispatch()

  const { id: activeChatId } = useParams<{ id: string }>()
  const match = useRouteMatch(path)
  const { pathname } = useLocation()

  const chatRoom = useAppSelector((state) => state.chat.chatRooms.list[activeChatId])
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const isChannelManager = useAppSelector(
    (state) => accountIdSelector(state) === chatRoom?.channelManager?.id
  )

  const { setSessionStorageData, clearSessionStorageData } = useSessionStorage({ keySuffix })

  useEffect(() => {
    setSessionStorageData(pathname)
  }, [setSessionStorageData, pathname])

  const isMuted = Boolean(chatRoom?.mutedUntil)
  const shouldRedirect = activeChatId && !chatRoom && match?.url

  useEffect(() => {
    if (activeChatId) {
      dispatch(
        selectChatAction({
          nextChatId: activeChatId
        })
      )
    }
  }, [dispatch, activeChatId])

  useEffect(
    () => () => {
      if (shouldRedirect) {
        clearSessionStorageData()
      }
    },
    [shouldRedirect, clearSessionStorageData]
  )

  if (shouldRedirect) {
    return <Redirect to={match.url} />
  }

  return (
    <ChatContextProvider initialState={EChatViewType.P2P}>
      <ChatRoomP2P
        entityId={id}
        type={chatRoom?.type}
        activeChatId={activeChatId}
        selectedChatStatus={ChatStatusEnum.ACTIVE}
        isBusinessAccount={isBusinessAccount}
        isChannelManager={isChannelManager}
        isMuted={isMuted}
        title={
          <div className={styles.title}>
            <UnityHubChannelIcon variant={variant} isMuted={isMuted} />
            <div className={styles.container}>
              <div className={styles.nameWrapper}>
                <TruncatedText text={chatRoom?.name ?? ''} />
                {isMuted && <ChatMutedIcon className={styles.mutedIcon} />}
              </div>

              <ChatTypingIndicator chatId={chatRoom.id} />
            </div>
          </div>
        }
      />
    </ChatContextProvider>
  )
}

export const ChatOrganization = ({ path, variant, id, keySuffix }: TChatOrganizationProps) => {
  const roomsIds = useAppSelector((state) => state.chat.chatRooms.ids)

  if (!roomsIds.length) {
    return null
  }

  return <ChatOrganizationInternal id={id} path={path} variant={variant} keySuffix={keySuffix} />
}
