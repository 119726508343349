import { ReactNode } from 'react'
import cls from 'classnames'

import styles from './Alert.module.scss'

type TAlertVariant = 'success' | 'info' | 'warning' | 'error'
type TAlertSize = 'sm' | 'md'

export type TAlertProps = {
  variant: TAlertVariant
  children: ReactNode

  size?: TAlertSize
  className?: string
}

export const Alert = ({ variant, size = 'md', children, className }: TAlertProps) => {
  if (!children) {
    return null
  }

  return (
    <div className={cls(styles.root, [styles[variant]], [styles[size]], className)}>{children}</div>
  )
}
