import { useParams } from 'react-router-dom'

import { useAppSelector } from 'store'
import { CommunityView } from 'App/components/Communities'

type TCommunityContentProps = {
  loading: boolean
}

export const CommunityContent = ({ loading }: TCommunityContentProps) => {
  const { id } = useParams<{ id?: string }>()

  const community = useAppSelector((state) => (id ? state.communities.data.list[id] : undefined))

  if (!community) {
    return null
  }

  return <CommunityView loading={loading} community={community} />
}
