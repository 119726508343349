import { ProducedNotificationsEnum, ProducedOrganizationNotificationsEnum } from '@medentee/enums'

import { actionCreator } from 'store'

import {
  ESocketEmitEventNamesCommunities,
  ESocketEmitEventNamesEvents
} from '../../services/webSocket'

import {
  STOP_NOTIFICATION_TRACKING,
  START_NOTIFICATION_TRACKING,
  RECEIVED_NOTIFICATION
} from './trackedNotifications.actionTypes'

export const startNotificationTracking = (payload: {
  channel: string
  notifications: Set<
    | ProducedNotificationsEnum
    | ProducedOrganizationNotificationsEnum
    | ESocketEmitEventNamesCommunities
    | ESocketEmitEventNamesEvents
  >
  filter?: (payload: unknown) => boolean
}) => actionCreator(START_NOTIFICATION_TRACKING, payload)
export const stopNotificationTracking = (payload: { channel: string }) =>
  actionCreator(STOP_NOTIFICATION_TRACKING, payload)
export const receivedNotification = (payload: {
  channel: string
  payload: Record<string, unknown>
}) => actionCreator(RECEIVED_NOTIFICATION, payload)
