import { FC } from 'react'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { PlatformInvitationType } from '@medentee/enums'

import { useAdaptiveLayout } from '../../../hooks'
import { InvitePopover, TInvitePopoverProps } from '../../../containers/InvitePopover'

export type TInviteButtonContainerProps = Partial<TInvitePopoverProps>

export const InviteButtonContainer: FC<TInviteButtonContainerProps> = ({ children, ...props }) => {
  const { isMobile } = useAdaptiveLayout()

  let placement: TooltipPlacement = 'bottomRight'

  if (isMobile) {
    placement = 'topRight'
  }

  return (
    <InvitePopover {...props} invitationType={PlatformInvitationType.STAFF} placement={placement}>
      {children}
    </InvitePopover>
  )
}
