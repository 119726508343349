import { useCallback } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { AccountName, NotificationBadge } from 'App/components'
import { AvatarContainer, TRelatedAccountsListItemContainerProps } from 'App/containers'

import styles from './RelatedAccountBaseItem.module.scss'

export type TRelatedAccountBaseItemProps = {
  onClick?: (id: string) => void
  hasTooltip?: boolean
  showOnline?: boolean
  disabled?: boolean
  notificationsCount?: number
} & Partial<
  Pick<
    TRelatedAccountsListItemContainerProps,
    'displayUserName' | 'role' | 'type' | 'firstName' | 'lastName' | 'id'
  >
>

export const RelatedAccountBaseItem = ({
  id,
  type,
  displayUserName,
  role,
  hasTooltip,
  showOnline,
  onClick,
  disabled = false,
  firstName = '',
  lastName = '',
  notificationsCount
}: TRelatedAccountBaseItemProps) => {
  const { t } = useTranslation()

  const handleAccountClick = useCallback(() => {
    if (onClick && id) {
      onClick(id)
    }
  }, [id, onClick])

  if (!type || !id || !displayUserName) {
    return null
  }

  return (
    <div className={cls(styles.root, { [styles.disabled]: disabled })} onClick={handleAccountClick}>
      <AvatarContainer
        firstName={firstName}
        lastName={lastName}
        userId={id}
        displayUserName={displayUserName}
        type={type}
        size={EAvatarSize.SM}
        hasTooltip={hasTooltip}
        showOnline={showOnline}
      />
      <div className={styles.details}>
        <AccountName
          displayUserName={displayUserName}
          type={type}
          classes={{ root: styles.accountNameRoot }}
        />
        <span className={styles.role}>{t(`relatedAccounts.type.${type}`, { role })}</span>
      </div>
      {notificationsCount ? (
        <div className={styles.badge}>
          <NotificationBadge count={notificationsCount} className={styles.badgeContent} />
        </div>
      ) : null}
    </div>
  )
}
