import { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from 'react-final-form'
import { Col, Row } from 'antd'
import { EventRepresentativeTypeEnum, EventPublicityEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { getEvent, editEventOrganizationSettings, getEventRepresentatives } from 'api/events'
import { toastDefaultOptions } from 'globalConstants'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'
import { Button } from 'App/components/common/Button'
import {
  TEventPlacementCommunity,
  TEventPlacementOrganization,
  TEventRepresantatives
} from 'interfaces/api/events'
import { Divider, SpyOnFormChanged } from 'App/components'

import styles from './OrganizerSettings.module.scss'
import { OrganizerPublicity } from './OrganizerPublicity'
import { OrganizerPlacement } from './OrganizerPlacement'
import { OrganizerProfile } from './OrganizerProfile'

const EVENT_QUERY_KEY = 'get-event'
const EVENT_REPRESENTATIVES_QUERY_KEY = 'get-event-representatives'

export type TPlacementItem = {
  id: string
  name: string
  active: boolean
  visible: boolean
}

export type TOrganizerSettingsFormValues = {
  representativeType: EventRepresentativeTypeEnum
  publicity: EventPublicityEnum
  placementOrganizations: TPlacementItem[]
  placementCommunities: TPlacementItem[]
}

const REPRESENTATIVE_KEY_MAP = new Map<EventRepresentativeTypeEnum, keyof TEventRepresantatives>()
  .set(EventRepresentativeTypeEnum.BUSINESS_ACCOUNT, 'businessAccounts')
  .set(EventRepresentativeTypeEnum.ORGANIZATION, 'organizations')
  .set(EventRepresentativeTypeEnum.COMMUNITY, 'communities')

export const OrganizerSettings = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const [initialValues, setInitialValues] = useState<TOrganizerSettingsFormValues>()

  const { ignore, unIgnore, prompt, setFormChanged } = useUnsavedDataPrompt()

  const { t } = useTranslation()

  const eventId = params.id

  const { data: representatives, isFetching: isFetchingRepresentatives } = useQuery({
    queryKey: [EVENT_REPRESENTATIVES_QUERY_KEY],
    queryFn: () => getEventRepresentatives(eventId),
    enabled: Boolean(eventId)
  })

  const { isFetching: isFetchingEvent } = useQuery({
    queryKey: [EVENT_QUERY_KEY],
    queryFn: () => getEvent(eventId),
    enabled: !!eventId && !!representatives,
    onSuccess: ({
      settings: { representative, publicity, placementOrganizationsMap, placementCommunitiesMap }
    }) => {
      setInitialValues({
        publicity,

        representativeType: representative.type,
        placementCommunities:
          representatives?.communities?.map(({ id, title }) => {
            const selectedItem = placementCommunitiesMap.find(
              ({ communityId }) => communityId === id
            )

            return {
              id,
              name: title,
              active: !!selectedItem,
              visible: selectedItem?.visible ?? false
            }
          }) ?? [],
        placementOrganizations:
          representatives?.organizations?.map(({ id, displayUserName }) => {
            const selectedItem = placementOrganizationsMap.find(
              ({ organizationId }) => organizationId === id
            )

            return {
              id,
              name: displayUserName,
              active: !!selectedItem,
              visible: selectedItem?.visible ?? false
            }
          }) ?? []
      })
    }
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: editEventOrganizationSettings,
    onSuccess: () => {
      toast.success(t('common.toast.updatesSaved'), toastDefaultOptions)
    }
  })

  const onSubmit = useCallback(
    (payload: TOrganizerSettingsFormValues) => {
      ignore()

      const key = REPRESENTATIVE_KEY_MAP.get(payload.representativeType)
      const representativeId = key && representatives?.[key][0].id

      if (!representativeId) {
        return
      }

      return mutate({
        id: eventId,
        representativeId,
        publicity: payload.publicity,
        representativeType: payload.representativeType,
        placementOrganizations: payload.placementOrganizations.reduce<
          TEventPlacementOrganization[]
        >((res, { id, active, visible }) => {
          if (active) {
            return [...res, { organizationId: id, visible }]
          }
          return res
        }, []),
        placementCommunities: payload.placementCommunities.reduce<TEventPlacementCommunity[]>(
          (res, { id, active, visible }) => {
            if (active) {
              return [...res, { communityId: id, visible }]
            }
            return res
          },
          []
        )
      })
    },
    [eventId, ignore, mutate, representatives]
  )
  const handleSpyChange = (value: boolean) => {
    unIgnore()
    setFormChanged(value)
  }

  const loading = isFetchingRepresentatives || isFetchingEvent

  if (!initialValues) {
    return null
  }

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, valid, dirty, dirtySinceLastSubmit, submitSucceeded }) => (
          <Row gutter={[0, 50]} justify="center">
            <SpyOnFormChanged onChange={handleSpyChange} prompt={prompt} />

            <Col xs={24}>
              <Row gutter={[0, 30]}>
                <OrganizerPublicity loading={loading} />

                <Divider />

                {representatives && (
                  <OrganizerProfile loading={loading} representatives={representatives} />
                )}

                <Divider />

                <OrganizerPlacement loading={loading} />
              </Row>
            </Col>

            <Col>
              <Button
                onClick={handleSubmit}
                disabled={
                  !valid ||
                  (!submitSucceeded && !dirty) ||
                  (submitSucceeded && !dirtySinceLastSubmit)
                }
                loading={submitting || isLoading}
              >
                {t('events.organizerSettings.submitButton')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}
