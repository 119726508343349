import { normalize, schema } from 'normalizr'
import groupBy from 'lodash/groupBy'
import { startOfDay } from 'date-fns'

import { TIds } from 'store/store.types'

import { TMeetingsList, TMeeting } from './meetings.types'

const listSchema = new schema.Entity('list')
const ids = new schema.Array(listSchema)

export const meetingsNormalize = (originalData: TMeeting[]) => {
  const { entities, result } = normalize<TMeeting, Normalized<TMeetingsList>, TIds>(
    originalData,
    ids
  )

  const groupList = groupBy(result, (id) => startOfDay(new Date(entities.list[id].startOn)))
  const groupIds = Object.keys(groupList).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  )

  return {
    list: entities.list || {},
    groupIds,
    groupList
  }
}
