import { useMemo } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import cls from 'classnames'
import { CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction, getCaseInvitationInactiveMembersQuantity } from 'store'
import { EModalComponents } from 'App/containers'
import { Tooltip } from 'App/components'
import { State } from 'redux/rootReducer'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

import styles from './CaseInviteMembersButton.module.scss'

export type TCaseInviteMembersButtonProps = {
  link?: boolean
  className?: string
  disabled?: boolean
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseInviteMembersButtonView = ({
  link,
  className = '',
  showModal,
  disabled,
  maxMembersReached
}: TCaseInviteMembersButtonProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (disabled || maxMembersReached) {
      return
    }

    showModal({
      modalType: EModalComponents.CASE_INVITE_MEMBERS_DIALOG,
      modalTitle: t('cases.modal.inviteMembers.title')
    })
  }

  const tooltip = useMemo(() => {
    if (maxMembersReached) {
      return t('cases.details.membersSection.inviteMembersButton.tooltip_limit')
    }

    return t('cases.details.membersSection.inviteMembersButton.tooltip')
  }, [maxMembersReached, t])

  return link ? (
    <div className={styles.linkContainer}>
      <button
        onClick={handleClick}
        className={styles.linkBtn}
        disabled={disabled || maxMembersReached}
      >
        {tooltip}
      </button>
    </div>
  ) : (
    <Tooltip title={tooltip}>
      <AccountMultiplePlusIcon
        className={cls({
          [styles.btn]: !disabled,
          [styles.disabledBtn]: disabled || maxMembersReached,
          [className]: !!className
        })}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

const mapStateToProps = (state: State) => {
  const caseMembersDirectLimit = state.subscriptions.current?.subscription?.caseMembersDirectLimit
  const caseMembersGroupLimit = state.subscriptions.current?.subscription?.caseMembersGroupLimit
  const activeMembersQuantity = state.caseMembers.members.active.ids.length
  const inactiveMembersQuantity = getCaseInvitationInactiveMembersQuantity(state)
  const type = state.caseView.data?.type
  const caseMembersLimit =
    type === CaseTypesEnum.A2O ? caseMembersDirectLimit : caseMembersGroupLimit

  return {
    caseClassification: state.caseView.data?.classification.name,
    maxMembersReached: caseMembersLimit
      ? activeMembersQuantity + inactiveMembersQuantity >= caseMembersLimit
      : false
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const CaseInviteMembersButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseInviteMembersButtonView)
