import { useEffect } from 'react'

import { IFilesEntity } from 'interfaces'
import { setVideoStreamingData, useAppDispatch, videoStreamingNormalize } from 'store'

export const useVideoStreaming = (files: IFilesEntity[] | undefined) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (files?.length) {
      dispatch(setVideoStreamingData(videoStreamingNormalize(files)))
    }
  }, [files, dispatch])
}
