import { useLayoutEffect } from 'react'

import {
  FAILED_TO_CREATE_YOUTUBE_API_INSTANCE,
  YOUTUBE_API_NOT_LOADED
} from 'globalConstants/errors'
import { IYoutubeErrorEvent, IYoutubePlayerAPI } from 'types'

export type TUseBroadcastPlayerProps = {
  videoId: string
  iframePlace: string | HTMLElement
}

export const useBroadcastPlayer = ({ videoId, iframePlace }: TUseBroadcastPlayerProps) => {
  const handlePlayerError = (event: IYoutubeErrorEvent) => {
    console.error(event.data)
  }

  useLayoutEffect(() => {
    let player: IYoutubePlayerAPI | null = null

    const initPlayer = () => {
      if (!window.YT?.Player) {
        console.error(YOUTUBE_API_NOT_LOADED)
        return
      }

      try {
        player = new window.YT.Player(iframePlace, {
          height: '100%',
          width: '100%',
          videoId,
          origin: window.location.origin,
          events: {
            onError: handlePlayerError
          }
        })
      } catch (e) {
        console.error(FAILED_TO_CREATE_YOUTUBE_API_INSTANCE, e)
      }
    }

    initPlayer()

    return () => {
      player?.destroy()
    }
  }, [videoId, iframePlace])

  return []
}
