import { Col, Skeleton } from 'antd'
import cls from 'classnames'

import styles from './FileTilesViewGridItemSkeleton.module.scss'

export type TFileTilesViewGridItemSkeletonProps = PropsWithClassName

export const FileTilesViewGridItemSkeleton = ({
  className
}: TFileTilesViewGridItemSkeletonProps) => (
  <Col className={cls(styles.root, className)} xs={12} sm={6} md={6}>
    <Skeleton.Image />
    <Skeleton className={styles.title} active title paragraph={false} />
  </Col>
)
