import { useTranslation } from 'react-i18next'

import { FileContactItemContainer, TFileContactListContainerProps } from 'App/containers'
import { generateRandomArray } from 'utils'
import { EmptyList } from 'App/components'
import { SkeletonList } from 'App/components/File/common'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { ReactComponent as AccountSupervisorIcon } from 'assets/icons/AccountSupervisor.svg'

import styles from './FileContactList.module.scss'

export const FileContactList = ({
  fileContactIds,
  loading,
  search
}: TFileContactListContainerProps) => {
  const { t } = useTranslation()

  const fileAContactIdList = loading ? generateRandomArray() : fileContactIds

  return fileAContactIdList.length ? (
    <SkeletonList className={styles.list} loading={loading}>
      {fileContactIds.map((id: string) => (
        <FileContactItemContainer id={id} key={id} />
      ))}
    </SkeletonList>
  ) : (
    <EmptyList
      className={styles.emptyList}
      icon={
        search ? (
          <AccountSupervisorIcon className={styles.emptyListIcon} />
        ) : (
          <AccountPlusIcon className={styles.emptyListIcon} />
        )
      }
      text={t('files.information.contactsList.placeholder')}
      hasAppliedSearch={!!search}
    />
  )
}
