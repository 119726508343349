import { useCallback, useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation } from 'react-i18next'

import { NotificationsP2PRowContainer, TNotificationsP2PContainerProps } from 'App/containers'
import { Pagination, Collapse, EmptyList, Scrollable } from 'App/components'
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg'

import styles from './NotificationsP2P.module.scss'

export type TNotificationsP2PProps = TNotificationsP2PContainerProps

const SKELETON_PARAGRAPHS_LENGTH = 10
const PAGINATION_SOURCE = 'notifications_main_tab'

export const NotificationsP2P = ({
  getNotificationsProtocolUsers,
  ids,
  page,
  showBy,
  total,
  loading,
  subscribe,
  unsubscribe
}: TNotificationsP2PProps) => {
  const [currentPage, setCurrentPage] = useState<number>(page)
  const [currentShowBy, setCurrentShowBy] = useLocalStorageState<number>(PAGINATION_SOURCE, {
    defaultValue: showBy
  })

  const { t } = useTranslation()

  const handlePagination = useCallback(
    (nextPage: number, nextPageSize: number) => {
      setCurrentPage(nextPage)
      setCurrentShowBy(nextPageSize)
    },
    [setCurrentShowBy]
  )

  useEffect(() => {
    getNotificationsProtocolUsers({
      page: currentPage,
      showBy: currentShowBy
    })
  }, [currentPage, currentShowBy, getNotificationsProtocolUsers])

  useEffect(() => {
    subscribe()

    return () => {
      unsubscribe()
    }
  }, [subscribe, unsubscribe])

  return (
    <>
      <Scrollable page={currentPage} className={styles.root}>
        <Skeleton
          active={true}
          title={false}
          paragraph={{ rows: SKELETON_PARAGRAPHS_LENGTH, width: '100%' }}
          loading={loading}
          className={styles.skeleton}
        >
          {!ids.length ? (
            <EmptyList icon={<BellIcon />} text={t('placeholder', { ns: 'notifications' })} />
          ) : (
            <Collapse accordion={true} className={styles.collapse}>
              {ids.map((id) => (
                <NotificationsP2PRowContainer key={id} id={id} />
              ))}
            </Collapse>
          )}
        </Skeleton>
      </Scrollable>

      <Pagination
        current={currentPage}
        total={total}
        pageSize={currentShowBy}
        showSizeChanger={true}
        onShowSizeChange={setCurrentShowBy}
        onChange={handlePagination}
      />
    </>
  )
}
