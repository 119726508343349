import { getVideoStreamingItemSelector, useAppSelector } from 'store'
import { Image } from 'App/components/common/Image'
import { ReactComponent as PlayIcon } from 'assets/icons/Play.svg'
import { IFilesEntity } from 'interfaces'

import { TFileViewerProps } from '../FileViewer'

import styles from './VideoViewer.module.scss'

export type TVideoViewerProps = Partial<Pick<IFilesEntity, 'fileName' | 'thumbnailUrl'>> &
  Pick<TFileViewerProps, 'preview' | 'onPreviewClick' | 'isActive'> & {
    id: string
  }

export const VideoViewer = ({
  id,
  preview,
  fileName,
  thumbnailUrl,
  onPreviewClick,
  isActive = true
}: TVideoViewerProps) => {
  const { bunnyNetMetadata, videoMetadata } = useAppSelector(getVideoStreamingItemSelector(id))

  if (preview) {
    return (
      <div className={styles.preview} onClick={onPreviewClick}>
        <Image
          src={thumbnailUrl}
          alt={fileName}
          className={styles.thumbnail}
          placeholderClasses={{ root: styles.placeholder, icon: styles.placeholderIcon }}
        />

        <div className={styles.play}>
          <PlayIcon />
        </div>
      </div>
    )
  }

  return isActive && bunnyNetMetadata?.libraryId && videoMetadata?.quality && videoMetadata.guid ? (
    <iframe
      title="video"
      className={styles.iframe}
      src={`https://iframe.mediadelivery.net/embed/${bunnyNetMetadata.libraryId}/${videoMetadata.guid}`}
      allow="accelerometer; gyroscope; encrypted-media;"
      allowFullScreen={true}
    />
  ) : null
}
