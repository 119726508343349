import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { EventRepresentativeTypeEnum } from '@medentee/enums'

import { hideModalAction, useAppDispatch } from 'store'
import { Button, ConfirmationModal } from 'App/components/common'
import { useCreateEventRequest } from 'App/screens/Events/CreateEvent/useCreateEventRequest'
import { TCreateEventPayload } from 'api/events'

import { TEventInfoFormValues } from '../EventInfoForm'

type TEventsCreateConfirmProps = TEventInfoFormValues &
  Pick<TCreateEventPayload, 'representativeId' | 'representativeType'>

export const EventsCreateConfirm = ({
  representativeId,
  representativeType,
  city,
  country,
  dates,
  isOneDay,
  title,
  about,
  headline,
  location
}: TEventsCreateConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = useCallback(() => dispatch(hideModalAction()), [dispatch])

  const { handleSubmit, isLoading } = useCreateEventRequest({
    representativeId,
    representativeType,
    onSuccess
  })

  const handleClick = useCallback(
    () => handleSubmit({ city, country, dates, isOneDay, title, about, headline, location }),
    [about, city, country, dates, handleSubmit, headline, isOneDay, location, title]
  )

  return (
    <ConfirmationModal
      content={t('modal.createEventConfirm.content', {
        context: representativeType?.toLowerCase() as Lowercase<EventRepresentativeTypeEnum>
      })}
      controls={
        <Button loading={isLoading} disabled={isLoading} onClick={handleClick}>
          {t('modal.createEventConfirm.submitButton')}
        </Button>
      }
    />
  )
}
