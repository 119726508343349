import { useTranslation } from 'react-i18next'

import { formatLongDate } from 'utils'

import styles from './GracePeriodDate.module.scss'

type TGracePeriodDateProps = {
  endsOn: Date | string
  graceOn: Date | string
}

export const GracePeriodDate = ({ endsOn, graceOn }: TGracePeriodDateProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {t('subscriptions.activeSubscription.gracePeriodLabel', {
        expiredAt: formatLongDate(endsOn),
        gracePeriodDate: formatLongDate(graceOn)
      })}
    </div>
  )
}
