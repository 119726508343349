import { ReactNode } from 'react'
import cls from 'classnames'

import styles from './SettingsInfoRow.module.scss'

export type TSettingsInfoRowClasses = 'action' | 'description' | 'label'

export type TSettingsInfoRowProps = {
  label: ReactNode
  description: ReactNode

  action?: ReactNode
  classes?: Partial<Record<TSettingsInfoRowClasses, string>>
}

export const SettingsInfoRow = ({ label, description, action, classes }: TSettingsInfoRowProps) => (
  <div className={styles.root}>
    <div className={cls(styles.label, classes?.label)}>{label}</div>
    <div className={cls(styles.description, classes?.description)}>{description}</div>
    {action && <div className={cls(styles.action, classes?.action)}>{action}</div>}
  </div>
)
