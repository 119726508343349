import { TIds } from 'store'

import { getChatRoomsSuccess } from '../chatRooms'

import * as actionCreators from './online.actions'

export type TOnlineUser = {
  online: boolean
  lastSeen: string

  id?: string
  privateUserId?: string
  socketId?: string
}

export type TOnlineNormalizedResult = {
  entities: {
    list: TOnlineList
  }
  result: TIds
}

export type TOnlineNormalized = {
  list: TOnlineList
  ids: TIds
}

export type TOnlineList = Record<string, TOnlineUser>

export type TOnlineAdmin = TOnlineNormalized

export type TOnlineState = TOnlineNormalized & {
  admin: TOnlineNormalized
}

export type TOnlinePayload = {
  socketId: string
  userId: string
  privateUserId: string
}

export type TSetOnlineActionPayload = TOnlineNormalized
export type TReceiveOnlinePayload = TOnlinePayload
export type TReceiveOfflinePayload = TOnlinePayload

export type TSetAdminOnlineActionPayload = TOnlineNormalized
export type TReceiveAdminOnlinePayload = TOnlinePayload
export type TReceiveAdminOfflinePayload = TOnlinePayload

const allActionCreators = { ...actionCreators, getChatRoomsSuccess }

export type TOnlineAction = ReturnType<TInferValueTypes<typeof allActionCreators>>
