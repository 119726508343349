import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './HelperText.module.scss'

export type THelperTextProps = {
  align?: 'left' | 'right'
  position?: 'absolute' | 'static'
}

export const HelperText = ({
  children,
  align = 'left',
  position = 'absolute'
}: PropsWithChildren<THelperTextProps>) =>
  children ? (
    <div className={cls(styles.root, styles[position], styles[align])}>{children}</div>
  ) : null
