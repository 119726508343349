import { useAppSelector, createLoadingSelector, GET_CHAT_ROOMS } from 'store'

import { ChatCreateGroupNameField } from '../ChatCreateGroupNameField'
import { ChatCreateGroupUploadAvatar } from '../ChatCreateGroupUploadAvatar'
import { useChatCreateGroupContext } from '../ChatCreateGroupContext'
import { GroupChatEditMembers } from '../GroupChatEditMembers'

import styles from './GroupChatEdit.module.scss'

const loadingSelector = createLoadingSelector([GET_CHAT_ROOMS])

export const GroupChatEdit = () => {
  const { chatName, setChatName, prompt } = useChatCreateGroupContext()
  const loading = useAppSelector(loadingSelector)

  return (
    <div className={styles.root}>
      <ChatCreateGroupUploadAvatar />

      <ChatCreateGroupNameField loading={loading} value={chatName} onChange={setChatName} />

      <GroupChatEditMembers />

      {prompt}
    </div>
  )
}
