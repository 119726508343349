import { actionCreator, TError, TMoveFileToTrashBinRequestPayload } from 'store'

import * as actionTypes from './trashBin.actionTypes'
import {
  TDeleteFilesFromMedCloudRequestPayload,
  TGetTrashBinRequestPayload,
  TGetTrashBinSuccessPayload,
  TMoveFileToTrashBinSuccessPayload,
  TRestoreFileRequestPayload,
  TSetSearchFilterPayload,
  TSetSearchPaginationPayload,
  TSetSearchSortingPayload
} from './trashBin.types'

export const getTrashBinRequest = (payload?: TGetTrashBinRequestPayload) =>
  actionCreator(actionTypes.GET_TRASH_BIN_LIST_REQUEST, payload)
export const getTrashBinSuccess = (payload: TGetTrashBinSuccessPayload) =>
  actionCreator(actionTypes.GET_TRASH_BIN_LIST_SUCCESS, payload)
export const getTrashBinError = (payload: TError) =>
  actionCreator(actionTypes.GET_TRASH_BIN_LIST_ERROR, payload)

export const setTrashBinSearch = (payload: TSetSearchFilterPayload) =>
  actionCreator(actionTypes.SET_TRASH_BIN_SEARCH, payload)

export const setTrashBinPagination = (payload: TSetSearchPaginationPayload) =>
  actionCreator(actionTypes.SET_TRASH_BIN_PAGINATION, payload)

export const setTrashBinSorting = (payload: TSetSearchSortingPayload) =>
  actionCreator(actionTypes.SET_TRASH_BIN_SORTING, payload)

export const moveFileToTrashBinRequest = (payload: TMoveFileToTrashBinRequestPayload) =>
  actionCreator(actionTypes.MOVE_FILE_TO_TRASH_BIN_REQUEST, payload)
export const moveFileToTrashBinSuccess = (payload: TMoveFileToTrashBinSuccessPayload) =>
  actionCreator(actionTypes.MOVE_FILE_TO_TRASH_BIN_SUCCESS, payload)
export const moveFileToTrashBinError = (payload: TError) =>
  actionCreator(actionTypes.MOVE_FILE_TO_TRASH_BIN_ERROR, payload)

export const restoreFileRequest = (payload: TRestoreFileRequestPayload) =>
  actionCreator(actionTypes.RESTORE_FILE_REQUEST, payload)
export const restoreFileSuccess = () => actionCreator(actionTypes.RESTORE_FILE_SUCCESS)
export const restoreFileError = (payload: TError) =>
  actionCreator(actionTypes.RESTORE_FILE_ERROR, payload)

export const deleteFilesFromMedCloudRequest = (payload: TDeleteFilesFromMedCloudRequestPayload) =>
  actionCreator(actionTypes.DELETE_FILES_FROM_MEDCLOUD_REQUEST, payload)
export const deleteFilesFromMedCloudSuccess = () =>
  actionCreator(actionTypes.DELETE_FILES_FROM_MEDCLOUD_SUCCESS)
export const deleteFilesFromMedCloudError = (payload: TError) =>
  actionCreator(actionTypes.DELETE_FILES_FROM_MEDCLOUD_ERROR, payload)

export const resetTrashBin = () => actionCreator(actionTypes.RESET_TRASH_BIN)

export const setSelectedTrashBinFileId = (id: string | null) =>
  actionCreator(actionTypes.SET_SELECTED_TRASH_BIN_FILE_ID, id)
