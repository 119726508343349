import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { PlatformInvitationType } from '@medentee/enums'
import { useTranslation, Trans } from 'react-i18next'

import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'

export const useUserProfileInvitationToast = () => {
  const { t } = useTranslation()

  const showToast = useCallback(
    (type: PlatformInvitationType) => {
      const toastId = toast.success(
        <Trans
          t={t}
          i18nKey="userProfile.inviteByLinkActions.greetingsSuccessToast"
          components={[
            <Link
              key={0}
              to={
                type === PlatformInvitationType.STAFF
                  ? '/organizations/invitations/sent'
                  : '/contacts/invitations/sent'
              }
              onClick={() => toast.dismiss(toastId)}
            />
          ]}
        />,
        { ...toastDefaultOptions, autoClose: false }
      )
    },
    [t]
  )

  return { showToast }
}
