import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { AccountName, ContextMenu } from 'App/components/common'
import { EAvatarSize } from 'enums'
import { accountRoleNameSelector, useAppSelector } from 'store'
import { TUseOrganizationGroupedMember } from 'App/containers/Organizations/useOrganizationGroupedMembers'
import { useOrganizationMemberKebabMenu } from 'App/containers/Organization/useOrganizationMemberKebabMenu'
import { ReactComponent as StarFillIcon } from 'assets/icons/StarFill.svg'
import { KebabContactStatus } from 'App/components/common/KebabContactStatus'

import styles from './Item.module.scss'

type TItemProps = {
  member: TUseOrganizationGroupedMember
  membersQueryKeyPrefix: string
  handleRefreshMemberList: (page: number) => void

  isLead?: boolean
}

export const Item = ({
  member,
  membersQueryKeyPrefix,
  isLead,
  handleRefreshMemberList
}: TItemProps) => {
  const { id: accountId, professionalAccount } =
    useAppSelector((state) => state.global.accountData) || {}
  const accountRoleName = useAppSelector(accountRoleNameSelector)

  const { t } = useTranslation()

  const { account, contact, online } = member
  const {
    type: { name },
    displayUserName,
    firstName,
    lastName,
    id: memberAccountId
  } = account
  const { status, fromId } = contact || {}

  const onlineIndicator =
    accountId === memberAccountId || professionalAccount?.id === memberAccountId || online

  const { getMenuItems } = useOrganizationMemberKebabMenu({
    membersQueryKeyPrefix,
    accountId,
    accountRoleName,
    professionalAccountId: professionalAccount?.id,
    onRefreshMemberListCallback: handleRefreshMemberList
  })

  return (
    <div className={styles.item}>
      <div className={styles.wrapper}>
        <AccountName
          showAvatar
          classes={{ root: styles.account }}
          type={name}
          displayUserName={displayUserName}
          size={EAvatarSize.XS}
          firstName={firstName}
          lastName={lastName}
          userId={memberAccountId}
          online={onlineIndicator}
          hideAccountType={true}
          shouldRedirectToUserProfile={true}
          isContact={!!status && status === ContactStatusEnum.APPROVED}
          chipList={[
            {
              hidden: !isLead,
              color: 'primary',
              icon: <StarFillIcon />,
              text: t('organizations.infoDrawer.staffList.leadChip')
            }
          ]}
        />
      </div>

      <ContextMenu
        menuItems={getMenuItems({ member })}
        endAdornment={<KebabContactStatus accountId={accountId} fromId={fromId} status={status} />}
        classes={{ root: styles.context }}
      />
    </div>
  )
}
