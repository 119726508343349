import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention'

import { TMention, TTrigger } from 'utils/mentions'
import { getMapComponent } from 'utils'

import { FileMention, TFileMentionProps } from '../FileMention'
import { AccountMention, TAccountMentionProps } from '../AccountMention'

type TMentionProps = PartialBy<
  Omit<SubMentionComponentProps, 'mention' | 'theme' | 'entityKey'>,
  'children'
> & {
  mention: TMention
}

const MENTIONS_MAP = new Map<
  TTrigger,
  (props: TFileMentionProps & TAccountMentionProps) => JSX.Element
>()
  .set('/', (props) => <FileMention {...props} />)
  .set('@', (props) => <AccountMention {...props} />)

export const Mention = ({ mention, className, decoratedText, children }: TMentionProps) =>
  mention.trigger ? (
    getMapComponent(MENTIONS_MAP, mention.trigger, { mention, className, decoratedText })
  ) : (
    <span className={className}>{children}</span>
  )
