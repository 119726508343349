import { CaseTypesEnum } from '@medentee/enums'

import { EAvatarSize } from 'enums'
import { Avatar, Timer, TAvatarProps, TTimerProps } from 'App/components/common'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'

import styles from './CallLabel.module.scss'

type TCallLabelProps = Partial<
  Pick<TAvatarProps, 'firstName' | 'lastName' | 'displayUserName' | 'type'>
> &
  Pick<TTimerProps, 'startTime'> & {
    callType?: CaseTypesEnum
    userId?: string
  }

export const CallLabel = ({
  callType,
  startTime,
  type,
  userId = '',
  firstName = '',
  lastName = '',
  displayUserName = ''
}: TCallLabelProps) => (
  <>
    {callType === CaseTypesEnum.A2A ? (
      <AccountGroupIcon />
    ) : (
      <Avatar
        userId={userId}
        firstName={firstName}
        lastName={lastName}
        size={EAvatarSize.XXS}
        displayUserName={displayUserName}
        type={type}
      />
    )}

    <div className={styles.timer}>
      {startTime ? <Timer startTime={startTime} /> : displayUserName}
    </div>
  </>
)
