import { ReactNode, useContext, useEffect, useRef } from 'react'
import cls from 'classnames'

import { EIconSize } from 'enums'
import { IconButton } from 'App/components/common'
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'

import { useToggle } from '../../../hooks'

import styles from './ScrollToEdgeButton.module.scss'
import { scrollToEdgeButtonContext } from './ScrollToEdgeButtonContext'

export type TChatMessageListGoToLastProps = {
  onClick?: () => void
  className?: string
  children?: ReactNode
  direction?: 'top' | 'bottom'
}

const getScrollPosition = (el: HTMLElement | Window | null) => {
  if (!el) {
    return { top: 0, left: 0 }
  }

  if (el instanceof Window) {
    return { top: el.scrollY, left: el.scrollX }
  }

  return { top: el.scrollTop, left: el.scrollLeft }
}

export const ScrollToEdgeButton = ({
  onClick,
  children,
  className,
  direction = 'top'
}: TChatMessageListGoToLastProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const { targetElement } = useContext(scrollToEdgeButtonContext)
  const { value: isVisible, setValue: setIsVisible } = useToggle()
  const handleClick = () => {
    if (targetElement) {
      const scrollPos =
        direction === 'top'
          ? {
              top: 0
            }
          : {
              bottom: 0
            }
      targetElement.scroll({
        ...scrollPos,
        left: getScrollPosition(targetElement).left,
        behavior: 'smooth'
      })
    }
    if (onClick) {
      onClick()
    }
  }
  useEffect(() => {
    const handler = () => {
      const { top } = getScrollPosition(targetElement)
      const buttonHeight = rootRef.current?.offsetHeight ?? 0

      setIsVisible(top > buttonHeight)
    }
    if (targetElement) {
      targetElement.addEventListener('scroll', handler, false)
      targetElement.addEventListener('resize', handler, false)
      targetElement.addEventListener('orientationchange', handler, false)
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener('scroll', handler)
        targetElement.removeEventListener('resize', handler)
        targetElement.removeEventListener('orientationchange', handler)
      }
    }
  }, [targetElement])

  if (!isVisible) {
    return null
  }

  return (
    <div ref={rootRef} className={cls(styles.root, className)}>
      {children}
      <IconButton
        classes={{ root: styles.button }}
        iconComponent={direction === 'top' ? <ChevronUpIcon /> : <ChevronDownIcon />}
        iconSize={EIconSize.CUSTOM}
        variant="filled"
        onClick={handleClick}
      />
    </div>
  )
}
