import { Field, Form } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { CurrentBalanceContainer } from 'App/containers'
import { Button, TextField, ErrorModal } from 'App/components'

import { useRequest } from './useRequest'
import styles from './OneTimeDialog.module.scss'

const AMOUNT_VALIDATION = {
  MIN: 1,
  MAX: 99999
}

export const OneTimeDialog = () => {
  const { processing, handleFormSubmit, error } = useRequest()

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <p className={styles.description}>{t('modal.oneTimeTopUp.content')}</p>

      <div className={styles.balance}>
        <Trans
          t={t}
          i18nKey="modal.oneTimeTopUp.yourBallanceLabel"
          components={{ ballance: <CurrentBalanceContainer /> }}
        />
      </div>

      <Form onSubmit={handleFormSubmit}>
        {({ handleSubmit, form }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <Field
              name="amount"
              parse={(value) => value.replace(validation.rules.notDigits, '')}
              validate={validation.composeValidators(
                validation.required(),
                validation.min(
                  AMOUNT_VALIDATION.MIN,
                  t('validationErrors.minAmount', { amount: AMOUNT_VALIDATION.MIN, ns: 'errors' })
                ),
                validation.max(
                  AMOUNT_VALIDATION.MAX,
                  t('validationErrors.maxAmount', { amount: AMOUNT_VALIDATION.MAX, ns: 'errors' })
                )
              )}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  autoFocus={true}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                  topLabel={t('modal.oneTimeTopUp.amountField')}
                />
              )}
            </Field>

            <Button type="submit" disabled={form.getState().invalid} loading={processing}>
              {t('modal.oneTimeTopUp.submitButton')}
            </Button>
          </form>
        )}
      </Form>
      {/* TODO: error */}
      <ErrorModal error={error} />
    </div>
  )
}
