import { useCallback, useMemo } from 'react'
import {
  ContactStatusEnum,
  BusinessAccountRoleNameEnum,
  PlatformInvitationType
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useQueryClient, useMutation } from 'services/query'
import {
  acceptContactsInviteRequest,
  removeContactInviteRequest,
  useAppDispatch,
  TContactsData
} from 'store'
import { TMenuItemProps } from 'App/components/common'
import { ReactComponent as AccountPlusOutlineIcon } from 'assets/icons/AccountPlusOutline.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { ReactComponent as MessageTextOutlineIcon } from 'assets/icons/MessageTextOutline.svg'
import { useChatRedirect, useOrganizationId } from 'App/components/Organizations/hooks'
import { TUseOrganizationGroupedMember } from 'App/containers/Organizations/useOrganizationGroupedMembers'
import { sendInviteUsersByEmail } from 'api/invitations'
import { EInvitationAction, toastDefaultOptions } from 'globalConstants'

type TUseOrganizationMemberKebabMenuProps = {
  membersQueryKeyPrefix: string

  accountId?: string
  accountRoleName?: BusinessAccountRoleNameEnum
  professionalAccountId?: string
  onRefreshMemberListCallback?: (fromPage: number) => void
}

type TMenuItemsProps = {
  member: TUseOrganizationGroupedMember
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const isHideInvitationReply = (contact?: TContactsData, status?: ContactStatusEnum) =>
  !contact || status !== ContactStatusEnum.PENDING
export const isSender = (accountId?: string, fromId?: string) => accountId === fromId

export const useOrganizationMemberKebabMenu = ({
  membersQueryKeyPrefix,
  accountId,
  accountRoleName,
  professionalAccountId,
  onRefreshMemberListCallback
}: TUseOrganizationMemberKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const queryClient = useQueryClient()

  const organizationId = useOrganizationId()

  const { redirectToChat } = useChatRedirect()

  const { t } = useTranslation()

  const { mutate } = useMutation({
    mutationKey: ['invite-to-contacts'],
    mutationFn: sendInviteUsersByEmail
  })

  const handleRefreshMemberList = useCallback(
    (fromPage = 0) =>
      () => {
        if (onRefreshMemberListCallback) {
          onRefreshMemberListCallback(fromPage)
        }
      },
    [membersQueryKeyPrefix, onRefreshMemberListCallback, organizationId, queryClient]
  )

  const menuItems = useMemo(
    () => ({
      invite: ({ member }: TMenuItemsProps) => ({
        content: t('organizations.infoDrawer.staffList.kebabMenu.inviteToContacts'),
        hidden:
          !!accountRoleName ||
          (!!member.contact?.status && member.contact.status !== ContactStatusEnum.REJECTED),
        icon: <AccountPlusOutlineIcon />,
        onClick: () => {
          mutate(
            { emails: [member.account.email], type: PlatformInvitationType.CONTACT },
            {
              onSuccess: () => {
                handleRefreshMemberList(member.fromPage)()
                toast.success(t('common.toast.invitationSent'), toastDefaultOptions)
              }
            }
          )
        }
      }),
      withdraw: ({ member: { contact, fromPage } }: TMenuItemsProps) => ({
        content: t('organizations.infoDrawer.staffList.kebabMenu.withdraw'),
        hidden:
          !!accountRoleName ||
          isHideInvitationReply(contact, contact?.status) ||
          !isSender(accountId, contact?.fromId),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => {
          contact?.id &&
            dispatch(
              removeContactInviteRequest({
                contactId: contact.id,
                processingId: contact.id,
                type: EInvitationAction.WITHDRAW,
                onSuccess: handleRefreshMemberList(fromPage)
              })
            )
        }
      }),
      approve: ({ member: { account, contact, fromPage } }: TMenuItemsProps) => ({
        content: t('organizations.infoDrawer.staffList.kebabMenu.approve'),
        hidden:
          !!accountRoleName ||
          isHideInvitationReply(contact, contact?.status) ||
          isSender(accountId, contact?.fromId),
        icon: <AccountMultiplePlusOutlineIcon />,
        onClick: () => {
          dispatch(
            acceptContactsInviteRequest({
              accountId: account.id,
              processingId: account.id,
              onSuccess: handleRefreshMemberList(fromPage)
            })
          )
        }
      }),
      reject: ({ member: { contact, fromPage } }: TMenuItemsProps) => ({
        content: t('organizations.infoDrawer.staffList.kebabMenu.reject'),
        hidden:
          !!accountRoleName ||
          isHideInvitationReply(contact, contact?.status) ||
          isSender(accountId, contact?.fromId),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => {
          contact?.id &&
            dispatch(
              removeContactInviteRequest({
                contactId: contact.id,
                processingId: contact.id,
                type: EInvitationAction.REJECT,
                onSuccess: handleRefreshMemberList(fromPage)
              })
            )
        }
      }),
      chat: ({ member: { chatId, account } }: TMenuItemsProps) => ({
        content: t('organizations.infoDrawer.staffList.kebabMenu.chat'),
        icon: <MessageTextOutlineIcon />,
        onClick: () => redirectToChat({ chatId, partnerAccountId: account.id })
      })
    }),

    [accountId, accountRoleName, dispatch, handleRefreshMemberList, mutate, redirectToChat, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => {
      const { account } = props.member
      const shouldShowKebab = !!accountRoleName
        ? professionalAccountId !== account.id
        : accountId !== account.id

      return shouldShowKebab
        ? [
            menuItems.invite(props),
            menuItems.withdraw(props),
            menuItems.approve(props),
            menuItems.reject(props),
            menuItems.chat(props)
          ]
        : []
    },
    [accountId, accountRoleName, menuItems, professionalAccountId]
  )

  return {
    getMenuItems
  }
}
