import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { setCaseCloudActiveFileId } from 'store/caseCloud'
import { CaseCloudFileDetailsMember } from 'App/components'

export type TCaseCloudFileDetailsSharedWithMeContainerOwnProps = {
  caseId: string
}

const mapStateToProps = (state: State) => {
  const { fileDetailsId } = state.caseCloud.cloudFiles
  const fileDetails = fileDetailsId ? state.caseCloud.sharedWithMeFiles.list[fileDetailsId] : null

  return { fileDetails, owner: fileDetails?.owner }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeFileDetails: setCaseCloudActiveFileId
    },
    dispatch
  )

export const CaseCloudFileDetailsSharedWithMeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseCloudFileDetailsMember)
