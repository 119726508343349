import { useCallback, useEffect } from 'react'
import cls from 'classnames'
import { AccountTypeNames, ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EEmptyListIconSize, EmptyList, Pagination } from 'App/components'
import { ContactHeaderList, ContactsItemContainer, EmptyListInviteButton } from 'App/containers'
import { generateRandomArray } from 'utils'
import {
  contactsEmptyAfterFilterSelector,
  createLoadingSelector,
  createProcessingSelector,
  DELETE_CATEGORY,
  GET_CONTACTS,
  getContactsRequest,
  SEND_CATEGORY,
  setPageContactsFilter,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'

import styles from './ContactsList.module.scss'

const loadingSelector = createLoadingSelector([GET_CONTACTS])
const processingSelector = createProcessingSelector([SEND_CATEGORY, DELETE_CATEGORY])

export const ContactsList = () => {
  const dispatch = useAppDispatch()

  const ids = useAppSelector((state) => state.contacts.ids)
  const total = useAppSelector((state) => state.contacts.total)
  const accountType = useAppSelector((state) => state.global.accountData?.type.name)
  const isEmptyAfterFilter = useAppSelector(contactsEmptyAfterFilterSelector)
  const loading = useAppSelector(
    (state) =>
      loadingSelector(state) || processingSelector(state) || state.contacts.filters.loadingContacts
  )
  const {
    page: currentPage,
    showBy,
    search,
    searchBy,
    online,
    selectedAccountTypes,
    selectedCategories,
    notAssigned
  } = useAppSelector((state) => state.contacts.filters)

  const { t } = useTranslation()

  const handleSetPage = useCallback(
    (page: number) => dispatch(setPageContactsFilter({ page })),
    [dispatch]
  )

  useEffect(() => {
    dispatch(
      getContactsRequest({
        status: ContactStatusEnum.APPROVED
      })
    )
    // Filters are required to trigger list update
  }, [
    dispatch,
    accountType,
    search,
    searchBy,
    currentPage,
    online.length,
    notAssigned,
    selectedCategories.length,
    selectedAccountTypes.length
  ])

  const contactIds = loading ? generateRandomArray(showBy) : ids

  return (
    <>
      <ContactHeaderList hideBroadcast={!contactIds.length} />
      {contactIds.length ? (
        <div className={cls(styles.root, loading && styles.rootSkeleton)}>
          <div className={styles.list}>
            {contactIds.map((id) => (
              <ContactsItemContainer key={id} id={id} />
            ))}
          </div>

          <Pagination
            pageSize={showBy}
            total={total}
            current={currentPage}
            onChange={handleSetPage}
            wrapperClassName={styles.pagination}
          />
        </div>
      ) : (
        <div className={cls(styles.root, styles.rootEmpty)}>
          <EmptyList
            icon={isEmptyAfterFilter ? <AccountMultipleIcon /> : <AccountMultiplePlusIcon />}
            text={t('contacts.list.placeholderExtended')}
            iconSize={EEmptyListIconSize.MD}
            hasAppliedFilters={isEmptyAfterFilter}
          >
            {!isEmptyAfterFilter && (
              <EmptyListInviteButton
                contactType={AccountTypeNames.PROFESSIONAL}
                accountType={accountType}
              />
            )}
          </EmptyList>
        </div>
      )}
    </>
  )
}
