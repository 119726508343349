import { ChangeEvent, useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import {
  broadcastMessageRequest,
  getBroadcastRecipientIdsSelector,
  getBroadcastDepartmentIdsSelector,
  getBroadcastCategoryIdsSelector,
  useAppDispatch,
  useAppSelector,
  getBroadcastOrganizationIdsSelector
} from 'store'
import { useEvent } from 'App/hooks'
import { Editor } from 'App/components'

import { TextFieldFooter } from '../TextFieldFooter'
import { TMessageContainerProps } from '../MessageContainer'

import styles from './TextField.module.scss'

export type TTextFieldProps = Pick<
  TMessageContainerProps,
  'textMessage' | 'setTextMessage' | 'onOpenAttach'
> & {
  readonly?: boolean
  processing: boolean
  setShowAudio: (arg: boolean) => void
}

const MESSAGE_LENGTH_MAX = 5000
const MIN_ROWS = 1
const MAX_ROWS = 5

export const TextField = ({
  processing,
  textMessage,
  setTextMessage,
  setShowAudio,
  onOpenAttach,
  readonly
}: TTextFieldProps) => {
  const dispatch = useAppDispatch()

  const recipientIds = useAppSelector(getBroadcastRecipientIdsSelector, isEqual)
  const departmentIds = useAppSelector(getBroadcastDepartmentIdsSelector, isEqual)
  const categoryIds = useAppSelector(getBroadcastCategoryIdsSelector, isEqual)
  const organizationIds = useAppSelector(getBroadcastOrganizationIdsSelector, isEqual)
  const allContacts = useAppSelector((state) => state.chat.broadcast.allContacts)
  const organizationId = useAppSelector((state) => state.chat.broadcast.organizationId)

  const disabledSubmit =
    !recipientIds.length &&
    !departmentIds.length &&
    !categoryIds.length &&
    !organizationIds.length &&
    !allContacts &&
    !organizationId

  const [message, setMessage] = useState<string>(textMessage)

  const handleMessageSend = useEvent(() =>
    dispatch(
      broadcastMessageRequest({
        recipientIds,
        categoryIds,
        departmentIds,
        organizationIds,
        allContacts,
        organizationId,
        message
      })
    )
  )

  const saveMessage = useEvent(() => setTextMessage(message))
  const handleMessageChange = useEvent(({ target }: ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(target.value)
  )

  useEffect(
    () => () => {
      saveMessage()
    },
    [saveMessage]
  )

  return (
    <Editor
      classes={{ input: styles.input }}
      value={message}
      readOnly={readonly}
      onChange={handleMessageChange}
      valueLengthMax={MESSAGE_LENGTH_MAX}
      loading={processing}
      autoSize={{
        minRows: MIN_ROWS,
        maxRows: MAX_ROWS
      }}
      customBottom={
        <TextFieldFooter
          readonly={readonly}
          processing={processing}
          disabledSubmit={disabledSubmit}
          shouldShowMessageIcon={Boolean(message.length)}
          setShowAudio={setShowAudio}
          onSend={handleMessageSend}
          onOpenAttach={onOpenAttach}
        />
      }
    />
  )
}
