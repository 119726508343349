import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsSecurityAndLogin2FA } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsSecurityAndLogin2FAContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  enabled: state.settings.securityAndLogin.twoFA.enabled
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const SettingsSecurityAndLogin2FAContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityAndLogin2FA)
