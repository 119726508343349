import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'
import { useAppSelector } from 'store'

type TRedirectToOrganizationAdminChatProps = {
  organizationId: string
}

export const RedirectToOrganizationAdminChat = ({
  organizationId
}: TRedirectToOrganizationAdminChatProps) => {
  const { push } = useHistory()

  const businessChatId = useAppSelector(
    (state) => state.organizations.data.list[organizationId].businessChatId
  )

  const { t } = useTranslation()

  const handleButtonClick = () => {
    push(`/chat/dialog/${businessChatId}`)
  }

  return (
    <ConfirmationModal
      content={t('modal.unavailableOrganizationFeature.content')}
      controls={
        <Button onClick={handleButtonClick}>
          {t('modal.unavailableOrganizationFeature.submitButton')}
        </Button>
      }
    />
  )
}
