import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'
import { hideModalAction, useAppDispatch } from 'store'

type TChatRedirectConfirmProps = {
  link: string
}

export const RedirectConfirm: FC<TChatRedirectConfirmProps> = ({ link }) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onClick = () => {
    window.open(link, '_blank')
    dispatch(hideModalAction())
  }

  return (
    <ConfirmationModal
      content={t('modal.redirectConfirm.content')}
      controls={<Button onClick={onClick}>{t('modal.redirectConfirm.submitButton')}</Button>}
    />
  )
}
