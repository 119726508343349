import { useEffect } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import cls from 'classnames'

import { useBasicSubscription } from 'App/hooks/useBasicSubscription'
import { getKeyForRoute } from 'App/App.config'
import {
  EModalComponents,
  RoleRouteContainer,
  ShowModalContainer,
  TRoleRouteContainerOwnProps,
  TShowModalContainerProps
} from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'

import {
  AsideMenuHeaderAction,
  EAsideMenuHeaderActionVariant,
  Scrollable,
  TabMenu,
  TAsideMenuHeaderActionProps,
  TNavMenuItem
} from '../common'

import styles from './PageWrapper.module.scss'

export type TPageWrapperProps<ModalType extends EModalComponents> = PropsWithClasses<
  'filters' | 'main',
  {
    defaultPath: string
    nestedRoutes: TRoleRouteContainerOwnProps[]
    navMenu: TNavMenuItem[]

    autoScrollOnPagination?: null | number
    filterContent?: JSX.Element | null
    selectedFilters?: JSX.Element
    search?: JSX.Element
    addonBeforeAside?: JSX.Element
    addonAfterAside?: JSX.Element
    aside?: Partial<
      Pick<TShowModalContainerProps<ModalType>, 'modalTitle' | 'modalType' | 'modalProps'> &
        Pick<
          TAsideMenuHeaderActionProps,
          'icon' | 'buttonText' | 'title' | 'variant' | 'buttonContainer'
        >
    > & {
      showBasicSubscriptionDialog?: boolean
    }
    resetPage?: () => void
    resetFilter?: () => void
  }
>

export const PageWrapper = <ModalType extends EModalComponents>({
  defaultPath,
  nestedRoutes,
  navMenu,
  autoScrollOnPagination,
  filterContent,
  selectedFilters,
  search,
  classes,
  addonBeforeAside,
  addonAfterAside,
  resetPage,
  resetFilter,
  aside = {}
}: TPageWrapperProps<ModalType>) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()
  const {
    buttonContainer,
    buttonText,
    modalType,
    modalTitle,
    modalProps,
    title: asideTitle,
    icon,
    showBasicSubscriptionDialog,
    variant = EAsideMenuHeaderActionVariant.BUTTON_BLOCK
  } = aside

  const shouldShowFilterContent = isDesktop && Boolean(filterContent)
  const shouldShowSelectedFilter = !isMobile && Boolean(filterContent)
  const shouldShowAside =
    (asideTitle && icon) ||
    (isDesktop && addonBeforeAside) ||
    shouldShowFilterContent ||
    (isDesktop && addonAfterAside)

  const { shouldShowUpgradeDialog, getOnClickHandler } = useBasicSubscription()

  useEffect(() => {
    if (resetFilter) {
      resetFilter()
    }
  }, [resetFilter])

  useEffect(
    () => () => {
      if (resetPage) {
        resetPage()
      }
    },
    [resetPage]
  )

  return (
    <div className={styles.root}>
      {shouldShowAside && (
        <Scrollable variant="aside">
          <div className={styles.content}>
            {isDesktop && <div className={styles.addonBeforeAside}>{addonBeforeAside}</div>}

            {asideTitle && icon && (
              <>
                {buttonText && modalType && modalTitle ? (
                  <ShowModalContainer
                    modalTitle={modalTitle}
                    modalType={modalType}
                    modalProps={modalProps}
                  >
                    {({ showModalHandler }) => (
                      <AsideMenuHeaderAction
                        title={asideTitle}
                        buttonText={buttonText}
                        variant={variant}
                        icon={icon}
                        onClick={
                          showBasicSubscriptionDialog && shouldShowUpgradeDialog
                            ? getOnClickHandler()
                            : showModalHandler
                        }
                        buttonContainer={buttonContainer}
                      />
                    )}
                  </ShowModalContainer>
                ) : (
                  <AsideMenuHeaderAction
                    title={asideTitle}
                    icon={icon}
                    variant={variant}
                    buttonText={buttonText}
                    buttonContainer={buttonContainer}
                  />
                )}
              </>
            )}

            {shouldShowFilterContent && (
              <div className={cls(isDesktop && classes?.filters)}>{filterContent}</div>
            )}
            {isDesktop && addonAfterAside}
          </div>
        </Scrollable>
      )}

      <div className={cls(styles.main, classes?.main)}>
        {!isDesktop && addonBeforeAside}

        <TabMenu
          menuList={navMenu}
          navMenuProps={{ tabPosition: 'top', size: 'md' }}
          classes={{ root: styles.nav }}
          tabVariant="static"
        />

        {search}

        {shouldShowSelectedFilter && selectedFilters}

        {!isDesktop && addonAfterAside}

        <Scrollable page={autoScrollOnPagination}>
          <Switch>
            {nestedRoutes.map((item, index) => (
              <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
            ))}

            <Redirect to={defaultPath} />
          </Switch>
        </Scrollable>
      </div>
    </div>
  )
}
