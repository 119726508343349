import { normalize, schema } from 'normalizr'

import { IChatPinnedDTO } from 'interfaces'
import { TChatPinListNormalizeResult, TChatPinNormalized } from 'store'

const listSchema = new schema.Entity('list', {}, { idAttribute: 'id' })
const pinndeIds = new schema.Array(listSchema)

export const chatPinNormalize = (originalData: IChatPinnedDTO[]): TChatPinNormalized => {
  const normalizedData: TChatPinListNormalizeResult = normalize(originalData, pinndeIds)

  return {
    list: normalizedData.entities.list || {},
    ids: normalizedData.result || []
  }
}
