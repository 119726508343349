import { useEffect, useMemo } from 'react'
import { Col } from 'antd'
import { Field, useForm, useFormState } from 'react-final-form'
import { isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { validation, rangeDate } from 'utils'
import { TextField, Checkbox } from 'App/components'
import { DatePicker } from 'App/components/common/Fields/DatePicker'
import { MIN_LENGTH_1, MAX_LENGTH_100, MIN_PICKER_DATE } from 'globalConstants'

import { TValues } from '../../UserProfileWorkExperienceDialog'

const StartDateField = () => {
  const { values } = useFormState<TValues>()

  const { t } = useTranslation()

  return (
    <Field
      name="startDate"
      validate={validation.composeValidators(
        validation.required(),
        validation.minDate(MIN_PICKER_DATE)
      )}
    >
      {({ input, meta }) => (
        <DatePicker
          {...input}
          picker="month"
          format={rangeDate}
          topLabel={t('modal.userWorkplaces.form.startDateField')}
          disabledDate={(date) =>
            (values?.endDate && isAfter(date, values?.endDate)) ||
            isBefore(date, MIN_PICKER_DATE) ||
            isAfter(date, new Date())
          }
          invalid={meta.touched && meta.invalid}
          error={meta.error}
        />
      )}
    </Field>
  )
}

const EndDateField = () => {
  const { values } = useFormState<TValues>()
  const { change } = useForm()

  const { t } = useTranslation()

  useEffect(() => {
    if (values.isCurrent) {
      change('endDate', null)
    }
  }, [values.isCurrent, change])

  return (
    <Field
      name="endDate"
      validate={values.isCurrent ? undefined : validation.required()}
      key={values.isCurrent ? 'endDate' : 'endDate-optional'}
    >
      {({ input, meta }) => (
        <DatePicker
          {...input}
          picker="month"
          format={rangeDate}
          topLabel={t('modal.userWorkplaces.form.endDateField')}
          disabled={values.isCurrent}
          disabledDate={(date) =>
            (values?.startDate && isBefore(date, values?.startDate)) || isAfter(date, new Date())
          }
          invalid={meta.touched && meta.invalid}
          error={meta.error}
        />
      )}
    </Field>
  )
}

export const UserProfileWorkExperienceDialogFirstStep = () => {
  const { t } = useTranslation()

  const validate = useMemo(
    () =>
      validation.composeValidators(
        validation.onlySpaces(),
        validation.required(),
        validation.minLength(MIN_LENGTH_1),
        validation.maxLength(MAX_LENGTH_100)
      ),
    []
  )

  return (
    <>
      <Col xs={24}>
        <Field name="position" validate={validate}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userWorkplaces.form.positionField')}
              valueLengthMax={MAX_LENGTH_100}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="organization" validate={validate}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userWorkplaces.form.organizationField')}
              valueLengthMax={MAX_LENGTH_100}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <StartDateField />
      </Col>
      <Col xs={24}>
        <EndDateField />
      </Col>
      <Field name="isCurrent" type="checkbox">
        {({ input: checkboxInput }) => (
          <Col xs={24}>
            <Checkbox
              {...checkboxInput}
              label={t('modal.userWorkplaces.form.currentWorkplaceCheckbox')}
            />
          </Col>
        )}
      </Field>
    </>
  )
}
