import { useCallback, useLayoutEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { Skeleton } from 'antd'

import {
  broadcastMessageRequest,
  getBroadcastAudioBlobSelector,
  getBroadcastAudioUrlSelector,
  getBroadcastRecipientIdsSelector,
  setBroadcastAudio,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EIconSize } from 'enums'
import { ChatMessageAudioRecord, IconButton } from 'App/components'
import { ReactComponent as AttachIcon } from 'assets/icons/Attach.svg'

import styles from './AudioField.module.scss'

export type TAudioFieldProps = {
  processing: boolean
  setShowAudio: (arg: boolean) => void
  onOpenAttach: () => void
}

const AUDIO_MESSAGE_LENGTH_MAX = 1000 * 60 + 500 // 1min + 0.5sec

export const AudioField = ({ processing, setShowAudio, onOpenAttach }: TAudioFieldProps) => {
  const dispatch = useAppDispatch()
  const recipientIds = useAppSelector(getBroadcastRecipientIdsSelector, isEqual)
  const audioBlob = useAppSelector(getBroadcastAudioBlobSelector, isEqual)
  const audioUrl = useAppSelector(getBroadcastAudioUrlSelector)
  const [recording, setRecording] = useState<boolean>(true)

  const disabledSend = !recipientIds.length || !audioBlob

  const handleAudioEnd = useCallback(() => {
    setShowAudio(false)

    dispatch(
      setBroadcastAudio({
        audioUrl: '',
        audioBlob: undefined
      })
    )
  }, [dispatch, setShowAudio])

  const handleAudioRecorded = useCallback(
    (blob: Blob, url: string) => {
      setRecording(false)

      dispatch(
        setBroadcastAudio({
          audioUrl: url,
          audioBlob: blob
        })
      )
    },
    [dispatch]
  )

  const handleAudioSend = useCallback(
    () => dispatch(broadcastMessageRequest({ recipientIds })),
    [dispatch, recipientIds]
  )

  useLayoutEffect(() => {
    if (recording && audioBlob && audioUrl) {
      setRecording(false)
    }
  }, [audioBlob, audioUrl, recording])

  return (
    <div className={styles.root}>
      <Skeleton
        loading={processing}
        active={true}
        title={false}
        paragraph={{ rows: 4, width: 'initial', className: styles.skeleton }}
      >
        <>
          {!recording && (
            <IconButton
              iconComponent={<AttachIcon />}
              iconSize={EIconSize.MD}
              classes={{ root: styles.attachIcon }}
              onClick={onOpenAttach}
            />
          )}

          <ChatMessageAudioRecord
            defaultAudioUrl={audioUrl}
            defaultAudioBlob={audioBlob}
            onAudioEnd={handleAudioEnd}
            onAudioRecorded={handleAudioRecorded}
            onAudioSend={handleAudioSend}
            disabledSend={disabledSend}
            maxRecordTime={AUDIO_MESSAGE_LENGTH_MAX}
            classes={{
              audioSend: disabledSend ? undefined : styles.audioSend,
              audioIcon: recording ? styles.recordingIcon : undefined
            }}
          />
        </>
      </Skeleton>
    </div>
  )
}
