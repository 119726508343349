import { useMemo } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ESorting } from 'enums'
import { SortingTable } from 'types'
import { createSlides, FILE_SHARED_LIST_DEFAULT_PAGINATION, TIds } from 'store'
import {
  FileTilesViewGrid,
  FileTilesViewGridItem,
  FileTilesViewHeader,
  FileTilesViewOwnerAndDate,
  TFileSharedTableProps,
  TFileTilesViewHeaderProps
} from 'App/components'
import { TFileSharedListContainerProps } from 'App/containers'
import { getSelectedFiles } from 'utils/files'
import { useListContextMenu } from 'App/hooks'

import { THeadContextMenuProps, TUseSelection } from '../../common'
import { TUseKebabMenu } from '../useKebabMenu'

export type TGridViewProps = Pick<TFileTilesViewHeaderProps, 'onViewChange' | 'onSorting'> &
  Pick<TFileSharedListContainerProps, 'sorting'> &
  Pick<TUseSelection, 'onSelect' | 'selectMode'> &
  Pick<THeadContextMenuProps, 'filesSize'> &
  TUseKebabMenu & {
    setSelectedIds: (ids: TIds) => void
    fileIds: TIds
    selectedIds: TIds
    view: TFileTilesViewHeaderProps['variant']
    dataSource: TFileSharedTableProps[]

    loading?: boolean
  }

const SKELETON_ROWS = FILE_SHARED_LIST_DEFAULT_PAGINATION.showBy

const defaultSorting: SortingTable = {
  direction: ESorting.DESC,
  name: 'sharedAt'
}

export const FileGridView = ({
  dataSource,
  onSorting,
  onViewChange,
  view,
  setSelectedIds,
  selectedIds,
  fileIds,
  loading,
  selectMode,
  onSelect,
  getMenuItems,
  filesSize,
  sorting = defaultSorting
}: TGridViewProps) => {
  const { shouldShowContextMenu, shouldShowHeadContextmenu } = useListContextMenu(selectedIds)

  const { t } = useTranslation()

  const selectedFiles = useMemo(
    () => getSelectedFiles(selectedIds, dataSource),
    [selectedIds, dataSource]
  )

  const sortingList = useMemo<TFileTilesViewHeaderProps['sortingList']>(
    () => [
      {
        title: t('files.columns.name'),
        key: 'fileName'
      },
      {
        title: t('files.columns.owner'),
        key: 'owner'
      },
      {
        title: t('files.columns.sharedOn'),
        key: 'sharedAt'
      },
      {
        title: t('files.columns.fileSize'),
        key: 'fileSize'
      }
    ],
    [t]
  )

  return (
    <>
      <FileTilesViewHeader
        sorting={sorting}
        variant={view}
        fileIds={fileIds}
        selectedIds={selectedIds}
        sortingList={sortingList}
        onSorting={onSorting}
        onViewChange={onViewChange}
        filesSize={filesSize}
        menuItems={getMenuItems(selectedFiles)}
        shouldShowHeadContextmenu={shouldShowHeadContextmenu}
        setSelectedIds={setSelectedIds}
      />

      <FileTilesViewGrid<TFileSharedTableProps>
        data={dataSource}
        loading={loading}
        skeletonLength={SKELETON_ROWS}
      >
        {({
          fileId,
          fileName,
          extension,
          thumbnailUrl,
          owner,
          sharedAt,
          isUnopenable,
          key,
          mimeType,
          slidesEntity
        }) => (
          <FileTilesViewGridItem
            disableOpen={isUnopenable}
            key={fileId}
            fileId={fileId}
            fileName={fileName}
            selected={selectedIds.includes(fileId)}
            extension={extension}
            thumbnailUrl={thumbnailUrl}
            addonAfter={
              <FileTilesViewOwnerAndDate name={owner.displayUserName} date={sharedAt || ''} />
            }
            selectMode={selectMode}
            openHandlerProps={{
              slides: createSlides(slidesEntity, FileHistorySourcesEnum.MED_CLOUD)
            }}
            fileNameDisabled={isUnopenable}
            menuItems={
              shouldShowContextMenu(fileId)
                ? getMenuItems({ extension, fileId, fileName, key, isUnopenable, slidesEntity })
                : undefined
            }
            onSelect={onSelect}
            mimeType={mimeType}
          />
        )}
      </FileTilesViewGrid>
    </>
  )
}
