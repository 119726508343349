import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { uploadFilesCloseWidget, uploadFilesCollapseWidget } from 'store'
import { FileUploadWidget } from 'App/components'

export type TFileUploadWidgetContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  ids: state.medCloud.widget.ids,
  show: state.medCloud.widget.show,
  list: state.medCloud.widget.list,
  errors: state.medCloud.widget.errors,
  collapse: state.medCloud.widget.collapse
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toCollapseWidget: uploadFilesCollapseWidget,
      toCloseWidget: uploadFilesCloseWidget
    },
    dispatch
  )

export const FileUploadWidgetContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploadWidget)
