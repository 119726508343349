import { useCallback, useMemo } from 'react'
import cls from 'classnames'
import { useDispatch } from 'react-redux'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction } from 'store'
import { EIconSize, EFileUploadStatus } from 'enums'
import {
  TFileUploadWidgetContainerProps,
  FileUploadWidgetItem,
  EModalComponents
} from 'App/containers'
import { IconButton } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as DashIcon } from 'assets/icons/Dash.svg'
import { ReactComponent as ExpandIcon } from 'assets/icons/Expand.svg'

import { Title } from './components'
import styles from './FileUploadWidget.module.scss'

export type TFileUploadWidgetProps = TFileUploadWidgetContainerProps

export const FileUploadWidget = ({
  collapse,
  ids,
  show,
  list,
  errors,
  toCloseWidget,
  toCollapseWidget
}: TFileUploadWidgetProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const toggleCollapse = useCallback(
    () => toCollapseWidget({ collapse: !collapse }),
    [collapse, toCollapseWidget]
  )

  const hasError = useMemo(
    () =>
      Object.values(errors).some(
        (error) =>
          error?.errorType === ErrorCodesEnum.FILE_EXIST ||
          error?.errorType === ErrorCodesEnum.FILE_NAME_CONTAINS_PROPHIBITED_SYMBOLS ||
          error?.errorType === ErrorCodesEnum.FILE_EXTENSION_NOT_SUPPORTED ||
          error?.errorType === ErrorCodesEnum.FILE_NAME_IS_EMPTY ||
          error?.errorType === ErrorCodesEnum.FILE_NAME_IS_LIMIT_EXCEEDED
      ),
    [errors]
  )
  const inProcess = useMemo(
    () =>
      Object.values(list).some(
        (item) =>
          item?.uploadStatus === EFileUploadStatus.PENDING ||
          item?.uploadStatus === EFileUploadStatus.PROGRESS
      ),
    [list]
  )

  const handleClose = useCallback(() => {
    if (!hasError && !inProcess) {
      toCloseWidget()
    } else {
      dispatch(
        showModalAction({
          modalTitle: t('modal.cancelUploadConfirm.title'),
          modalType: EModalComponents.FILE_UPLOAD_WIDGET_DISCARD
        })
      )
    }
  }, [dispatch, hasError, inProcess, toCloseWidget, t])

  return show ? (
    <div className={styles.root}>
      <div className={cls(styles.header, collapse && styles.headerCollapse)}>
        <Title />

        <div className={styles.actions}>
          <div className={styles.actionsIcon}>
            <IconButton
              iconComponent={collapse ? <ExpandIcon /> : <DashIcon />}
              iconSize={EIconSize.MD}
              onClick={toggleCollapse}
              classes={{ root: styles.icon }}
            />
          </div>

          <div className={styles.actionsIcon}>
            <IconButton
              iconComponent={<CrossIcon />}
              iconSize={EIconSize.SM}
              onClick={handleClose}
              classes={{ root: styles.icon }}
            />
          </div>
        </div>
      </div>

      {!collapse && (
        <ul className={styles.list}>
          {ids.map((itemId) => (
            <li key={itemId}>
              <FileUploadWidgetItem id={itemId} />
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null
}
