import { api, API } from '../services/api'
import { TIds } from '../store'

type TInviteMemberPayload = { caseId: string; addAccountIds: TIds }

export const inviteMember = async ({ caseId, addAccountIds }: TInviteMemberPayload) => {
  const response = await api.patch(API.CASE_MEMBERS(caseId), {
    addAccountIds
  })

  return response
}
