import { ReactNode } from 'react'
import cls from 'classnames'

import type { ActionType } from 'rc-trigger/lib/interface'
import { Popover } from 'App/components'
import { ReactComponent as HelpCircleOutlineIcon } from 'assets/icons/HelpCircleOutline.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { getMapComponent } from 'utils'

import styles from './Help.module.scss'

type TIconVariant = 'help' | 'info'
type THelpProps = {
  content: ReactNode
  className?: string

  iconVariant?: TIconVariant
  trigger?: ActionType | ActionType[]
  size?: 'default' | 'md'
  shouldShowSub?: boolean
}

const ICON_MAP = new Map<
  TIconVariant,
  (props: Required<Pick<THelpProps, 'size' | 'className'>>) => JSX.Element
>()
  .set('help', ({ size, className }) => (
    <HelpCircleOutlineIcon className={cls(styles.icon, styles[size], className)} />
  ))
  .set('info', ({ size, className }) => (
    <InfoIcon className={cls(styles.icon, styles[size], className)} />
  ))

export const Help = ({
  content,
  className = '',
  trigger = 'hover',
  iconVariant = 'help',
  shouldShowSub = true,
  size = 'default'
}: THelpProps) => (
  <Popover trigger={trigger} content={content} overlayClassName={styles.root}>
    {shouldShowSub ? (
      <sup>{getMapComponent(ICON_MAP, iconVariant, { size, className })}</sup>
    ) : (
      getMapComponent(ICON_MAP, iconVariant, { size, className })
    )}
  </Popover>
)
