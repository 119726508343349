import { useMemo } from 'react'
import { AccountTypeNames } from '@medentee/enums'

import { RelatedAccountsList } from 'App/components'
import { createLoadingSelector, GET_RELATED_ACCOUNTS, useAppSelector } from 'store'
import { BUSINESS_ACCOUNT_LIMIT } from 'globalConstants'

export type TRelatedAccountsListContainerProps = {
  onCreateBusinessAccount: () => void
  type?: AccountTypeNames
}

const loadingSelector = createLoadingSelector([GET_RELATED_ACCOUNTS])

export const RelatedAccountsListContainer = (props: TRelatedAccountsListContainerProps) => {
  const { ids, list } = useAppSelector((state) => state.userProfile.relatedAccounts)
  const loading = useAppSelector(loadingSelector)

  const hasBusinessAccountsLimit = useMemo(
    () =>
      ids.filter((id) => list[id]?.type.name === AccountTypeNames.BUSINESS).length ===
      BUSINESS_ACCOUNT_LIMIT,
    [ids, list]
  )

  return (
    <RelatedAccountsList
      {...props}
      loading={loading}
      hasBusinessAccountsLimit={hasBusinessAccountsLimit}
      ids={ids}
    />
  )
}
