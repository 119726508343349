import { useEffect, useState } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { InvitationsList } from 'App/components'
import { TInvitationsSentNewUsersContainerProps } from 'App/containers'
import { EInvitationType } from 'globalConstants'
import { EItemSentType } from 'enums'

export type TInvitationsSentNewUsersProps = TInvitationsSentNewUsersContainerProps & {
  invitationType?: AccountTypeNames
}

export const InvitationsSentNewUsers = ({
  ids,
  loading,
  showBy,
  total,
  invitationType,
  getInvitationsNewUsers,
  resetInvitationsNewUsers
}: TInvitationsSentNewUsersProps) => {
  const [currentPage, setCurrentPage] = useState(0)

  const { t } = useTranslation()

  useEffect(() => {
    getInvitationsNewUsers({
      invitationType,
      page: currentPage
    })
  }, [currentPage, getInvitationsNewUsers, invitationType])

  useEffect(
    () => () => {
      resetInvitationsNewUsers()
    },
    [resetInvitationsNewUsers]
  )

  return (
    <InvitationsList
      type={EInvitationType.SENT}
      itemSentType={EItemSentType.EXTERNAL}
      showBy={showBy}
      total={total}
      loading={loading}
      page={currentPage}
      ids={ids}
      setPage={setCurrentPage}
      accountTypeName={invitationType}
      emptyListText={t('contacts.invitations.placeholder_newUsers')}
      emptyListLinkText={t('contacts.invitations.inviteButton')}
    />
  )
}
