import { ECaseAccountType, ECaseCloudTabKey } from 'enums'
import { TAction } from 'store/store.utils'

import { CASE_DETAILS_RESET } from '../caseDetails'

import {
  CASE_UPLOAD_FILE_ERROR,
  CASE_UPLOAD_FILE_REQUEST,
  CASE_UPLOAD_FILE_SUCCESS,
  GET_CASE_FILES_ERROR,
  GET_CASE_FILES_SUCCESS,
  SET_CASE_CLOUD_ACTIVE_FILE_ID,
  GET_CASE_SHARED_WITH_ME_FILES_ERROR,
  GET_CASE_SHARED_WITH_ME_FILES_SUCCESS,
  GET_CASE_UPLOADED_BY_ME_FILES_SUCCESS,
  GET_CASE_UPLOADED_BY_ME_FILES_ERROR,
  SET_CASE_CLOUD_WHO,
  REMOVE_CASE_CLOUD_CONTACT_PERMISSION,
  ADD_CASE_CLOUD_CONTACT_PERMISSION,
  SET_CASE_CLOUD_CURRENT_TAB_ACTION,
  LOSE_ACCESS_TO_CASE_FILE_SUCCESS,
  RESET_CLOUD_FILES_ACTION,
  RESET_CLOUD_FILES_LIST_ACTION,
  SET_CASE_CLOUD_FILTERS,
  RESET_CASE_CLOUD_FILTERS,
  GET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS
} from './caseCloud.actions'
import {
  TCaseCloudState,
  TCaseUploadFileError,
  TGetCaseFilesSuccess,
  TGetCaseSharedWithMeFilesSuccess,
  TGetCaseUploadedByMeFilesSuccess,
  TSetCaseCloudCurrentTabAction,
  TSetCaseCloudWho,
  TLoseAccessToCaseFileSuccess,
  TSetCaseCloudActiveFileId,
  TRremoveCaseCloudContactPermission,
  TAddCaseCloudContactPermission,
  TSetCaseCloudFilters,
  TGetCaseContactPermissionsSuccess
} from './caseCloud.types'

export const initialCaseCloudState: TCaseCloudState = {
  who: ECaseAccountType.MEMBER,
  cloudFiles: {
    ids: [],
    list: {},
    total: 0,
    fileDetailsId: null
  },
  permissionContacts: {
    activeIds: [],
    inactiveIds: [],
    list: {},
    permissions: {}
  },
  sharedWithMeFiles: {
    ids: [],
    list: {},
    total: 0
  },
  uploadedByMeFiles: {
    ids: [],
    list: {},
    total: 0
  },
  currentTab: ECaseCloudTabKey.FIRST,
  filters: {}
}

export const caseCloudReducer = (state = initialCaseCloudState, action: TAction) => {
  switch (action.type) {
    case CASE_UPLOAD_FILE_REQUEST:
    case CASE_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,

        error: null
      }
    }

    case SET_CASE_CLOUD_WHO: {
      return {
        ...state,
        who: (action as TSetCaseCloudWho).payload.who
      }
    }

    case SET_CASE_CLOUD_ACTIVE_FILE_ID: {
      const fileDetailsId = (action as TSetCaseCloudActiveFileId).payload?.id || null

      return {
        ...state,
        cloudFiles: {
          ...state.cloudFiles,
          fileDetailsId
        }
      }
    }

    case GET_CASE_FILES_SUCCESS: {
      return {
        ...state,
        cloudFiles: {
          ...state.cloudFiles,
          ...(action as TGetCaseFilesSuccess).payload
        }
      }
    }

    case GET_CASE_SHARED_WITH_ME_FILES_SUCCESS: {
      return {
        ...state,
        sharedWithMeFiles: {
          ...state.sharedWithMeFiles,
          ...(action as TGetCaseSharedWithMeFilesSuccess).payload
        }
      }
    }

    case GET_CASE_UPLOADED_BY_ME_FILES_SUCCESS: {
      return {
        ...state,
        uploadedByMeFiles: {
          ...state.uploadedByMeFiles,
          ...(action as TGetCaseUploadedByMeFilesSuccess).payload
        }
      }
    }

    case ADD_CASE_CLOUD_CONTACT_PERMISSION: {
      const { contactId, permissionId } = (action as TAddCaseCloudContactPermission).payload

      const userPermission = (state.permissionContacts.permissions[contactId] || []).slice(0)
      userPermission.push(permissionId)
      const permissionsClone = {
        ...state.permissionContacts.permissions,
        [contactId]: userPermission
      }

      return {
        ...state,
        permissionContacts: {
          ...state.permissionContacts,
          permissions: {
            ...permissionsClone
          }
        }
      }
    }

    case REMOVE_CASE_CLOUD_CONTACT_PERMISSION: {
      const { contactId, permissionId } = (action as TRremoveCaseCloudContactPermission).payload

      let userPermission = (state.permissionContacts.permissions[contactId] || []).slice(0)
      userPermission = userPermission.filter((permission) => permission !== permissionId)

      const permissionsClone = {
        ...state.permissionContacts.permissions,
        [contactId]: userPermission
      }

      return {
        ...state,
        permissionContacts: {
          ...state.permissionContacts,
          permissions: {
            ...permissionsClone
          }
        }
      }
    }

    case GET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS: {
      return {
        ...state,
        permissionContacts: {
          ...state.permissionContacts,
          ...(action as TGetCaseContactPermissionsSuccess).payload
        }
      }
    }

    case GET_CASE_UPLOADED_BY_ME_FILES_ERROR:
    case GET_CASE_SHARED_WITH_ME_FILES_ERROR:
    case CASE_UPLOAD_FILE_ERROR: {
      return {
        ...state,

        error: (action as TCaseUploadFileError).payload
      }
    }

    case GET_CASE_FILES_ERROR: {
      return {
        ...state,
        cloudFiles: initialCaseCloudState.cloudFiles,
        error: (action as TCaseUploadFileError).payload
      }
    }

    case SET_CASE_CLOUD_CURRENT_TAB_ACTION: {
      const currentTab = (action as TSetCaseCloudCurrentTabAction).payload

      return {
        ...state,
        currentTab
      }
    }

    case CASE_DETAILS_RESET: {
      return initialCaseCloudState
    }

    case LOSE_ACCESS_TO_CASE_FILE_SUCCESS: {
      const { fileId } = (action as TLoseAccessToCaseFileSuccess).payload

      const { [fileId]: omittedFile, ...list } = { ...state.sharedWithMeFiles.list }
      const ids = [...state.sharedWithMeFiles.ids.filter((id) => id !== fileId)]

      return {
        ...state,
        sharedWithMeFiles: {
          ids,
          list,
          total: state.sharedWithMeFiles.total - 1
        }
      }
    }

    case RESET_CLOUD_FILES_ACTION: {
      return {
        ...state,
        cloudFiles: {
          ...initialCaseCloudState.cloudFiles
        }
      }
    }

    case RESET_CLOUD_FILES_LIST_ACTION: {
      return {
        ...state,
        cloudFiles: {
          ...initialCaseCloudState.cloudFiles,
          total: state.cloudFiles.total
        },
        sharedWithMeFiles: {
          ...initialCaseCloudState.sharedWithMeFiles,
          total: state.sharedWithMeFiles.total
        },
        uploadedByMeFiles: {
          ...initialCaseCloudState.uploadedByMeFiles,
          total: state.uploadedByMeFiles.total
        }
      }
    }

    case SET_CASE_CLOUD_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as TSetCaseCloudFilters).payload
        }
      }
    }

    case RESET_CASE_CLOUD_FILTERS: {
      return {
        ...state,
        filters: initialCaseCloudState.filters
      }
    }

    default:
      return state
  }
}
