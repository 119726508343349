import { CaseStatusesEnum } from '@medentee/enums'
import { Skeleton } from 'antd'

import { CaseItem, CaseItemArchived, CaseItemLocked } from 'App/components/Case/CaseItem'
import { TCaseItemWrapperContainerProps } from 'App/containers'
import { getMapComponent } from 'utils'

import styles from './CaseItemWrapper.module.scss'

const CASE_ITEM_MAP = new Map<
  CaseStatusesEnum,
  ({
    isOwner,
    loading,
    caseData,
    memberList,
    showModal
  }: TCaseItemWrapperContainerProps) => JSX.Element
>()
  .set(CaseStatusesEnum.ACTIVE, ({ isOwner, loading, caseData, memberList, showModal }) => (
    <CaseItem
      isOwner={isOwner}
      loading={loading}
      caseData={caseData}
      memberList={memberList}
      showModal={showModal}
    />
  ))
  .set(CaseStatusesEnum.LOCKED, ({ isOwner, loading, caseData, memberList, showModal }) => (
    <CaseItemLocked
      isOwner={isOwner}
      loading={loading}
      caseData={caseData}
      memberList={memberList}
      showModal={showModal}
    />
  ))
  .set(CaseStatusesEnum.ARCHIVED, ({ isOwner, loading, caseData, memberList, showModal }) => (
    <CaseItemArchived
      isOwner={isOwner}
      loading={loading}
      caseData={caseData}
      memberList={memberList}
      showModal={showModal}
    />
  ))

export const CaseItemWrapper = ({
  isOwner,
  loading,
  caseData,
  memberList,
  showModal
}: TCaseItemWrapperContainerProps) => (
  <Skeleton paragraph={{ rows: 4 }} active={true} loading={loading} className={styles.skeleton}>
    {getMapComponent(CASE_ITEM_MAP, caseData.status, {
      isOwner,
      loading,
      caseData,
      memberList,
      showModal
    })}
  </Skeleton>
)
