export const BROADCAST_MESSAGE = 'BROADCAST_MESSAGE'
export const BROADCAST_MESSAGE_REQUEST = 'BROADCAST_MESSAGE_REQUEST'
export const BROADCAST_MESSAGE_SUCCESS = 'BROADCAST_MESSAGE_SUCCESS'
export const BROADCAST_MESSAGE_ERROR = 'BROADCAST_MESSAGE_ERROR'

export const SELECT_BROADCAST_FILES = 'SELECT_BROADCAST_FILES'
export const SET_BROADCAST_SELECTED_FILES = 'SET_BROADCAST_SELECTED_FILES'
export const REMOVE_BROADCAST_SELECTED_FILE = 'REMOVE_BROADCAST_SELECTED_FILE'

export const SET_BROADCAST_SELECTED_RECIPIENTS = 'SET_BROADCAST_SELECTED_RECIPIENTS'
export const SET_BROADCAST_AUDIO = 'SET_BROADCAST_AUDIO'
export const SET_BROADCAST_SELECTED_DEPARTMENTS = 'SET_BROADCAST_SELECTED_DEPARTMENTS'
export const SET_BROADCAST_SELECTED_CATEGORIES = 'SET_BROADCAST_SELECTED_CATEGORIES'
export const SET_BROADCAST_SELECTED_ORGANIZATIONS = 'SET_BROADCAST_SELECTED_ORGANIZATIONS'
export const SET_BROADCAST_ALL_CONTACTS = 'SET_BROADCAST_ALL_CONTACTS'
export const SET_BROADCAST_ORGANIZATION_ID = 'SET_BROADCAST_ORGANIZATION_ID'

export const RESET_BROADCAST = 'RESET_BROADCAST'
