import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TTypingInterlocutor } from 'store/chatRooms/chatRooms.types'

type TTypingPlaceholderParameters = {
  names: string[]
  extraNamesNumber: number
}

type TCreateTypingPlaceholder = TTypingPlaceholderParameters & {
  typingTotal: number

  namesLimit?: number
}

const NAMES_LIMIT_DEFAULT = 3

export const getTypingPlaceholderParameters = (
  typingInterlocutors: Pick<TTypingInterlocutor, 'displayUserName'>[] = [],
  namesLimit = NAMES_LIMIT_DEFAULT
) =>
  typingInterlocutors.reduce<TTypingPlaceholderParameters>(
    (acc, { displayUserName }) =>
      acc.names.length < namesLimit
        ? { ...acc, names: [...acc.names, displayUserName] }
        : { ...acc, extraNamesNumber: ++acc.extraNamesNumber },
    { names: [], extraNamesNumber: 0 }
  )

export const useTypingPlaceholder = () => {
  const { t } = useTranslation()

  const createPlaceholder = useCallback(
    ({
      typingTotal,
      extraNamesNumber,
      names,
      namesLimit = NAMES_LIMIT_DEFAULT
    }: TCreateTypingPlaceholder) => {
      const typingNames = names.join(', ')

      switch (true) {
        case !!names.length && typingTotal === 1: {
          return (
            <Trans t={t} i18nKey="chat.typingIndicator.placeholder_oneTyping">
              <strong>{{ typingNames }}</strong> is typing...
            </Trans>
          )
        }
        case !!names.length && typingTotal > 1 && typingTotal <= namesLimit: {
          return (
            <Trans t={t} i18nKey="chat.typingIndicator.placeholder_groupTyping">
              <strong>{{ typingNames }}</strong> are typing...
            </Trans>
          )
        }
        case !names.length && typingTotal === 1: {
          return t('chat.typingIndicator.placeholder_unknownOneTyping')
        }
        case !names.length && typingTotal > 1: {
          return t('chat.typingIndicator.placeholder_unknownGroupTyping')
        }
        default: {
          return (
            <Trans t={t} i18nKey="chat.typingIndicator.placeholder">
              <strong>{{ typingNames }}</strong> <strong>and +{{ extraNamesNumber }}</strong> are
              typing...
            </Trans>
          )
        }
      }
    },
    [t]
  )

  return { createPlaceholder }
}
