import { useTranslation } from 'react-i18next'

import { TChatDefaultMessageContainerProps, EChatDefaultMessageSize } from 'App/containers'
import { ChatDefaultMessageWrapper, ChatDefaultMessageText } from 'App/components'

export type TChatDefaultMessageOrganizationProps = Pick<
  TChatDefaultMessageContainerProps,
  'createdAt' | 'size' | 'chatType' | 'chatName'
>

export const ChatDefaultMessageOrganization = ({
  createdAt,
  chatType,
  chatName,
  size = EChatDefaultMessageSize.MD
}: TChatDefaultMessageOrganizationProps) => {
  const { t } = useTranslation()

  return (
    <ChatDefaultMessageWrapper chatType={chatType} createdAt={createdAt} size={size}>
      <ChatDefaultMessageText text={t('chat.placeholder_channel', { chatName })} />
    </ChatDefaultMessageWrapper>
  )
}
