import { useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'

import { EIconSize } from 'enums'
import { IconButton } from 'App/components/common'
import { ReactComponent as PaperAirplaneIcon } from 'assets/icons/PaperAirplane.svg'

import styles from './EndAdornment.module.scss'

export type SenMessageButtonProps = {
  isSendActionDisabled: boolean
  onClick: () => void
}

export const SendMessageButton = ({ isSendActionDisabled, onClick }: SenMessageButtonProps) => {
  // When input focus returns, it restores the current state(without new message text).
  // Therefore, we have to introduce a delay before applying new state
  const onSendMessage = useMemo(() => debounce(onClick, 0), [onClick])

  useEffect(
    () => () => {
      onSendMessage.cancel()
    },
    [onSendMessage]
  )

  return (
    <IconButton
      iconComponent={<PaperAirplaneIcon />}
      disabled={isSendActionDisabled}
      iconSize={EIconSize.MD}
      onClick={onSendMessage}
      classes={{ root: styles.iconSending }}
    />
  )
}
