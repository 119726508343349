import { useMemo } from 'react'

import { getMapComponent } from 'utils'
import { TUseUnityHubCreateChannel, useUnityHubCreateChannel } from 'App/components/UnityHub'

import { MembersStep, TMembersStepProps } from '../MembersStep'
import { useMembersRequest } from '../useMembersRequest'
import { useVoiceRoomDepartmentsRequest } from '../useVoiceRoomDepartmentsRequest'

import {
  CreateVoiceChannelDialogMainStep,
  TCreateVoiceChannelDialogMainStepProps
} from './CreateVoiceChannelDialogMainStep'

export type TCreateVoiceChannelDialogProps = Pick<
  TUseUnityHubCreateChannel,
  'channelId' | 'channelName' | 'isPublic'
> & {
  organizationId: string
}

enum steps {
  MAIN = 0,
  MEMBERS = 1
}

type TStepsMapProps = Pick<
  TCreateVoiceChannelDialogMainStepProps,
  | 'channelId'
  | 'setName'
  | 'isEdit'
  | 'loading'
  | 'dirty'
  | 'isPublic'
  | 'setIsPublic'
  | 'initialValues'
> &
  Pick<
    TMembersStepProps,
    | 'setChannelMembers'
    | 'setChannelManager'
    | 'channelMembers'
    | 'organizationId'
    | 'channelDepartments'
    | 'setChannelDepartments'
  > & {
    name: string
    handleOpenMain: () => void
    handleOpenMembers: () => void
  }

const STEPS_MAP = new Map<steps, (props: TStepsMapProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({
      name,
      organizationId,
      channelId,
      channelMembers,
      channelDepartments,
      isEdit,
      loading,
      dirty,
      handleOpenMembers,
      setName,
      isPublic,
      setIsPublic,
      initialValues
    }) => (
      <CreateVoiceChannelDialogMainStep
        dirty={dirty}
        organizationId={organizationId}
        name={name}
        channelId={channelId}
        channelMembers={channelMembers}
        channelDepartments={channelDepartments}
        isEdit={isEdit}
        isPublic={isPublic}
        loading={loading}
        initialValues={initialValues}
        setName={setName}
        setIsPublic={setIsPublic}
        onOpenMembers={handleOpenMembers}
      />
    )
  )
  .set(
    steps.MEMBERS,
    ({
      channelMembers,
      channelDepartments,
      organizationId,
      channelId,
      handleOpenMain,
      setChannelMembers,
      setChannelManager,
      setChannelDepartments
    }) => (
      <MembersStep
        variant="members"
        organizationId={organizationId}
        channelMembers={channelMembers}
        channelDepartments={channelDepartments}
        markVoiceRoomIds={channelId ? [channelId] : undefined}
        onBack={handleOpenMain}
        setChannelMembers={setChannelMembers}
        setChannelManager={setChannelManager}
        setChannelDepartments={setChannelDepartments}
      />
    )
  )

export const CreateVoiceChannelDialog = ({
  organizationId,
  channelName,
  channelId = '',
  isPublic: propIsPublic
}: TCreateVoiceChannelDialogProps) => {
  const {
    name,
    channelMembers,
    channelDepartments,
    step,
    isEdit,
    dirty,
    isPublic,
    setIsPublic,
    handleOpenMain,
    handleOpenMembers,
    setName,
    setChannelMembers,
    setChannelDepartments
  } = useUnityHubCreateChannel({ channelName, channelId, variant: 'voice', isPublic: propIsPublic })

  const { isLoading: membersLoading } = useMembersRequest({
    variant: 'voice',
    channelId,
    organizationId,
    isEdit,
    setChannelMembers
  })

  const { isLoading: departmentsLoading } = useVoiceRoomDepartmentsRequest({
    channelId,
    organizationId,
    isEdit,
    setChannelDepartments
  })

  const initialValues = useMemo(
    () => ({ name: channelName || '', isPublic: propIsPublic || false }),
    [channelName, propIsPublic]
  )

  return (
    <>
      {getMapComponent(STEPS_MAP, step, {
        name,
        channelMembers,
        channelDepartments,
        channelId,
        organizationId,
        isEdit,
        loading: membersLoading || departmentsLoading,
        dirty,
        isPublic,
        setName,
        setIsPublic,
        setChannelMembers,
        setChannelDepartments,
        handleOpenMain,
        handleOpenMembers,
        initialValues
      })}
    </>
  )
}
