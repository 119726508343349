import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { FilterDropdown } from 'App/components/Case/CaseHistory/FilterDropdown'
import { hideModalAction, showModalAction } from 'store'

export type TFilterDropdownContainerProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      hideModal: hideModalAction
    },
    dispatch
  )

export const FilterDropdownContainer = connect(null, mapDispatchToProps)(FilterDropdown)
