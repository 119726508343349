import { CaseItemMembers, CaseItemNotificationWrapper, CaseItemHeader } from 'App/components/index'
import { TCaseItemWrapperContainerProps } from 'App/containers'

export const CaseItem = ({
  isOwner,
  loading,
  caseData,
  memberList,
  showModal
}: TCaseItemWrapperContainerProps) => {
  const {
    id,
    title,
    notificationCount,
    type,
    createdAt,
    owner,
    status,
    classification,
    autoApproveLabel
  } = caseData

  return (
    <CaseItemNotificationWrapper notificationCount={notificationCount}>
      <CaseItemHeader
        id={id}
        title={title}
        isOwner={isOwner}
        status={status}
        showModal={showModal}
        classification={classification.name}
        type={type}
        createdAt={createdAt}
        autoApproveLabel={autoApproveLabel}
      />

      <CaseItemMembers memberList={memberList} owner={owner} loading={loading} />
    </CaseItemNotificationWrapper>
  )
}
