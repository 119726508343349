import { Col } from 'antd'
import { Field } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import { TPlacementItem } from '../../OrganizerSettings'
import { Checkbox, Switch } from '../../../../../components'

import styles from './OrganizerPlacementItem.module.scss'

export type TOrganizerPlacementItemProps = {
  name: string
  type: 'organization' | 'community'
}

export const OrganizerPlacementItem = ({ name, type }: TOrganizerPlacementItemProps) => {
  const { t } = useTranslation()

  return (
    <Field<TPlacementItem> key={name} name={name}>
      {({ input: { value, onChange } }) => (
        <Col xs={24} className={styles.root}>
          <Checkbox
            classes={{ root: styles.checkbox }}
            checked={value.active}
            label={
              <Trans
                t={t}
                i18nKey={`events.organizerSettings.placementCheckbox_${type}`}
                values={{ name: value.name }}
                components={[<b key={0} />]}
              />
            }
            onChange={(e) => {
              onChange({
                target: {
                  value: { ...value, active: e.target.checked }
                }
              })
            }}
          />
          {value.active && (
            <Switch
              label={t('events.organizerSettings.placementVisibleSwitch')}
              classes={{ root: styles.switch }}
              checked={value.visible}
              onChange={(checked) => {
                onChange({
                  target: {
                    value: { ...value, visible: checked }
                  }
                })
              }}
            />
          )}
        </Col>
      )}
    </Field>
  )
}
