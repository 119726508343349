import { Skeleton } from 'antd'
import { FC, ReactNode, useCallback, useLayoutEffect, useRef } from 'react'
import OriginInfiniteScroll, {
  Props as OriginInfiniteScrollProps
} from 'react-infinite-scroll-component'
import ResizeObserver from 'resize-observer-polyfill'

import { useAdaptiveLayout, useRefValue } from 'App/hooks'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

export type TInfiniteScrollProps = Omit<
  OriginInfiniteScrollProps,
  'loader' | 'hasMore' | 'next'
> & {
  total: number
  page: number
  next: (nextPage: number) => void

  limit?: number
  loading?: boolean
  loader?: ReactNode
  nestIn?: 'parent' | 'body'
}

export const InfiniteScroll: FC<TInfiniteScrollProps> = ({
  children,
  loader,
  next,
  total,
  nestIn = 'body',
  loading = false,
  limit = PAGINATION_DEFAULT_SHOW_BY,
  page = 0,
  ...rest
}) => {
  const nextPage = page + 1
  const hasMore: boolean = nextPage < Math.ceil(total / limit)
  const handleNext = useCallback(() => next(nextPage), [next, nextPage])

  const { getValue: getHasMore } = useRefValue(hasMore)
  const { getValue: getNextPage } = useRefValue(nextPage)

  const ref = useRef<OriginInfiniteScroll>(null)

  const { isDesktop } = useAdaptiveLayout()

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return
        }

        if (!loading && ref.current) {
          const rootElement =
            isDesktop || nestIn === 'parent' ? ref.current.getScrollableTarget() : document.body
          const infiniteScrollElement = rootElement?.querySelector<HTMLDivElement>(
            '.infinite-scroll-component'
          )

          if (rootElement?.clientHeight && infiniteScrollElement?.clientHeight) {
            const offset = Math.max(infiniteScrollElement.getBoundingClientRect().y, 0)

            const rootHeight = isDesktop
              ? rootElement?.clientHeight
              : rootElement?.clientHeight - offset

            const infiniteScrollHeight = infiniteScrollElement.clientHeight

            if (rootHeight >= infiniteScrollHeight && getHasMore()) {
              next(getNextPage())
            }
          }
        }
      })
    },
    [getHasMore, getNextPage, isDesktop, loading, next, nestIn]
  )

  useLayoutEffect(() => {
    const rootElement = isDesktop ? ref.current?.getScrollableTarget() : document.body

    const resizeObserver = new ResizeObserver(handleResize)

    rootElement && resizeObserver.observe(rootElement)

    return () => {
      rootElement && resizeObserver.unobserve(rootElement)
      resizeObserver.disconnect()
    }
  }, [isDesktop, handleResize])

  return (
    <OriginInfiniteScroll
      {...rest}
      next={handleNext}
      hasMore={hasMore}
      ref={ref}
      loader={
        loader ?? (
          <Skeleton
            loading={loading}
            active={true}
            title={false}
            paragraph={{
              rows: 1,
              width: '100%'
            }}
          />
        )
      }
    >
      {children}
    </OriginInfiniteScroll>
  )
}
