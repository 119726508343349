import { useTranslation } from 'react-i18next'

import {
  TUnityHubCreateChannelsMembersStepProps,
  UnityHubCreateChannelsMembersStep
} from 'App/components/UnityHub/UnityHubCreateChannels'
import { TGetCommunityMembersPayload } from 'api/communities'

import { useCommunityCreateChannelMembersStepRequest } from './useCommunityCreateChannelMembersStepRequest'

export type TCommunityCreateChannelMembersStepProps = Pick<
  TUnityHubCreateChannelsMembersStepProps,
  | 'onBack'
  | 'onSubmit'
  | 'setChannelManager'
  | 'setChannelMembers'
  | 'variant'
  | 'channelMembers'
  | 'channelManagerId'
  | 'error'
  | 'maxSelected'
  | 'submitLoading'
> &
  Pick<TGetCommunityMembersPayload, 'markChatIds'> & {
    communityId: string
  }

export const CommunityCreateChannelMembersStep = ({
  channelMembers,
  communityId,
  channelManagerId,
  variant,
  maxSelected,
  submitLoading,
  error,
  markChatIds,
  onBack,
  onSubmit,
  setChannelManager,
  setChannelMembers
}: TCommunityCreateChannelMembersStepProps) => {
  const { t } = useTranslation()

  const {
    error: innerError,
    results,
    total,
    page,
    search,
    showBy,
    loading,
    setPage,
    handleSearch
  } = useCommunityCreateChannelMembersStepRequest({
    communityId,
    markChatIds
  })

  return (
    <UnityHubCreateChannelsMembersStep
      emptyListText={t('modal.createChannel.selectMembers.placeholder')}
      variant={variant}
      items={results}
      total={total}
      limit={showBy}
      page={page}
      search={search}
      maxSelected={maxSelected}
      error={error ?? innerError}
      channelMembers={channelMembers}
      channelManagerId={channelManagerId}
      submitLoading={submitLoading}
      loading={loading}
      onBack={onBack}
      onSubmit={onSubmit}
      onPageChange={setPage}
      onSearch={handleSearch}
      setChannelMembers={setChannelMembers}
      setChannelManager={setChannelManager}
    />
  )
}
