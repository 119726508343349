import { EHistoryEvents } from 'enums'

type TUseBidirectionalSkeleton = {
  dataLength: number
  loading: boolean
  hasBefore: boolean
  hasAfter: boolean

  historyEvent?: EHistoryEvents
}

export const useBidirectionalSkeleton = ({
  dataLength,
  loading,
  hasAfter,
  hasBefore,
  historyEvent
}: TUseBidirectionalSkeleton) => {
  const shouldShowSkeleton = Boolean(
    (loading && !dataLength) ||
      (loading && historyEvent === EHistoryEvents.JUMP) ||
      (loading && historyEvent === EHistoryEvents.HISTORY_FIRST)
  )

  const infinitySkeleton = Boolean(loading && dataLength)
  const shouldShowTopSkeleton =
    historyEvent === EHistoryEvents.HISTORY_BEFORE && infinitySkeleton && hasBefore
  const shouldShowBottomSkeleton =
    historyEvent === EHistoryEvents.HISTORY_AFTER && infinitySkeleton && hasAfter

  const shouldShowEmptyScreen =
    !shouldShowSkeleton && !shouldShowBottomSkeleton && !shouldShowTopSkeleton && !dataLength
  return {
    shouldShowSkeleton,
    shouldShowTopSkeleton,
    shouldShowBottomSkeleton,
    shouldShowEmptyScreen
  }
}
