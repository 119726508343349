import { IShowcaseReply } from 'interfaces'

import { createBusEvent } from '../core'

const eventName = 'showcaseReplySuccess'
const showcaseReplySuccess = createBusEvent<
  Pick<IShowcaseReply, 'repliesAccounts'> & {
    commentId: string
    anchorId: string
  }
>(eventName)

export default showcaseReplySuccess
