import { call, put, select, takeLatest, cancel } from 'redux-saga/effects'
import { AxiosError } from 'axios'
import {
  CaseStatusesEnum,
  ChatTypeEnum,
  ProducedNotificationsEnum,
  ErrorCodesEnum
} from '@medentee/enums'
import { generatePath } from 'react-router-dom'

import { toast } from 'App/components/ToastContainer'
import { isMatchErrorCode } from 'utils'
import history from 'utils/history'
import { API, api, APIData } from 'services/api'
import { ECaseAccountType } from 'enums'
import { EChatTypeMap, toastDefaultOptions, CHAT_PATH } from 'globalConstants'
import { State } from 'redux/rootReducer'
import {
  CASE_LOCK_REQUEST,
  CASE_UNLOCK_REQUEST,
  CASE_VIEW_REQUEST,
  caseLockError,
  caseLockSuccess,
  caseUnLockError,
  caseUnLockSuccess,
  caseViewError,
  caseViewSuccess,
  CHECK_CASE_UNLOCKING,
  getCaseFilesRequest,
  hideModalAction,
  TCaseLockRequest,
  TCaseViewRequest,
  TCheckCaseUnlocking,
  TCaseStatusChangedRequest,
  CASE_STATUS_CHANGED_REQUEST,
  caseArchiveError,
  caseStatusChangedSuccess,
  CASE_ARCHIVE_REQUEST,
  caseArchiveSuccess,
  publicationOpinionsError,
  publicationOpinionsSuccess,
  PUBLICATION_OPINIONS_REQUEST,
  TPublicationOpinionsRequest,
  RECEIVE_PUBLIC_OPINIONS_CHANGED,
  getCaseOpinionsMembersRequest,
  resetCloudFilesAction,
  TCaseMemberKickedAction,
  CASE_MEMBER_KICKED_ACTION,
  getNotificationsGeneralRequest,
  RECEIVE_CASE_OPINION_CREATED,
  caseClearNotificationAction,
  setNewOpinionAction,
  setNewLineOpinionAction,
  TChatRoomsType,
  getChatRoomsRequest,
  TChatRoomsFilters
} from 'store'
import { ICaseDetailsDTO, IChatRoomsDTO, IDefaultStandaloneNotificationDTO } from 'interfaces'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

import {
  TCaseConvertRequest,
  TCaseStatusChangedEventPayload,
  TCaseViewData,
  TReceiveCaseOpinionCreated,
  TReceivePublicOpinionsChanged,
  TSetCaseCloudPermissionsModeRequest
} from './caseView.types'
import {
  caseConvertError,
  caseConvertSuccess,
  CASE_CONVERT_REQUEST,
  setCaseCloudPermissionsModeError,
  setCaseCloudPermissionsModeSuccess,
  SET_CASE_CLOUD_PERMISSIONS_MODE_REQUEST,
  setCaseNotificationAction,
  updateCaseViewDataAction
} from './caseView.actions'

function* caseViewCatch(e: AxiosError, type: ECaseAccountType) {
  yield put(caseViewError(e))
  yield history.replace(`/cases/${type}`)

  yield isMatchErrorCode(e, [
    ErrorCodesEnum.CASE_NOT_FOUND,
    ErrorCodesEnum.IS_NOT_ACTIVE_CASE_MEMBER
  ])
    ? toast.warn(i18n.t('common.toast.unavailableCase'), toastDefaultOptions)
    : handleError(e)
}

function* caseView({ payload: { type, id } }: TCaseViewRequest) {
  try {
    const { data }: APIData<ICaseDetailsDTO> = yield call(api.get, API.CASE(id))
    const accountId: undefined | string = yield select(
      (state: State) => state.global.accountData?.id
    )

    if (accountId !== data.owner.id && type === ECaseAccountType.OWNER) {
      yield history.replace('/cases')
      yield toast.warn(i18n.t('common.toast.unavailableCase'), toastDefaultOptions)
      return
    }

    yield put(caseViewSuccess({ data }))
  } catch (e) {
    yield caseViewCatch(e, type)
  }
}

function* caseLockSaga({ payload: { caseId } }: TCaseLockRequest) {
  try {
    const caseViewData: TCaseViewData = yield select((state: State) => state.caseView.data)

    yield call(api.patch, API.CASE(caseId), {
      status: CaseStatusesEnum.LOCKED
    })

    if (caseViewData) {
      yield put(updateCaseViewDataAction({ status: CaseStatusesEnum.LOCKED }))
    }

    yield put(caseLockSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield put(caseLockError(e))
  }
}

function* checkCaseUnlockingSaga({ payload: { type, id, callback } }: TCheckCaseUnlocking) {
  try {
    const { data }: APIData<ICaseDetailsDTO> = yield call(api.get, API.CASE(id))

    const isLockedCase = data?.status === CaseStatusesEnum.LOCKED

    if (isLockedCase) {
      yield toast.error(
        i18n.t('cases.lockedCaseError', { caseId: data.humanReadableId }),
        toastDefaultOptions
      )
    }

    if (!isLockedCase) {
      yield call(callback)
    }

    yield put(getCaseFilesRequest({ caseId: id }))
    yield put(caseViewSuccess({ data }))
  } catch (e) {
    yield caseViewCatch(e, type)
  }
}

function* caseUnLockSaga({ payload: { caseId } }: TCaseLockRequest) {
  try {
    const caseViewData: TCaseViewData = yield select((state: State) => state.caseView.data)

    yield call(api.patch, API.CASE(caseId), {
      status: CaseStatusesEnum.ACTIVE
    })

    if (caseViewData) {
      yield put(updateCaseViewDataAction({ status: CaseStatusesEnum.ACTIVE }))
    }

    yield put(caseUnLockSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield put(caseUnLockError(e))
  }
}

function* caseAccessLostSaga({ caseId }: Pick<TCaseStatusChangedEventPayload, 'caseId'>) {
  const currentCaseId: string | undefined = yield select((state: State) => state.caseView.data?.id)
  const selectedChat: IChatRoomsDTO | null | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat
  )
  const chatType: TChatRoomsType = yield select((state: State) => state.chat.chatRooms.chatType)

  if (!currentCaseId && caseId === selectedChat?.case?.id) {
    yield history.replace(
      generatePath(CHAT_PATH, {
        chatType: EChatTypeMap.CASE_GROUP
      })
    )

    yield toast.warn(i18n.t('common.toast.unavailableCase'), toastDefaultOptions)
    yield cancel()
  }

  if (!currentCaseId && chatType === ChatTypeEnum.CASE_GROUP && caseId !== selectedChat?.case?.id) {
    const { page, showBy }: TChatRoomsFilters = yield select(
      (state: State) => state.chat.chatRooms.filters
    )

    yield put(getChatRoomsRequest({ chatType: [ChatTypeEnum.CASE_GROUP], page, showBy }))
    yield cancel()
  }
}

function* changeCaseStatusSaga({ payload }: TCaseStatusChangedRequest) {
  try {
    const { caseId, toStatus } = payload.payload ?? payload

    const currentCaseId: string | undefined = yield select(
      (state: State) => state.caseView.data?.id
    )
    const isOwner: boolean = yield select(
      (state: State) => state.global.accountData?.id === state.caseView.data?.owner.id
    )

    if (caseId === currentCaseId) {
      yield put(caseStatusChangedSuccess({ status: toStatus }))
    }

    if (caseId === currentCaseId && !isOwner && toStatus === CaseStatusesEnum.LOCKED) {
      yield put(resetCloudFilesAction())
      yield cancel()
    }

    if (toStatus === CaseStatusesEnum.ARCHIVED) {
      yield caseAccessLostSaga({ caseId })
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* caseArchiveSaga({ payload: { caseId } }: TCaseLockRequest) {
  try {
    const caseViewData: TCaseViewData = yield select((state: State) => state.caseView.data)

    yield call(api.patch, API.CASE(caseId), {
      status: CaseStatusesEnum.ARCHIVED
    })

    if (caseViewData) {
      yield put(updateCaseViewDataAction({ status: CaseStatusesEnum.ARCHIVED }))
    }

    yield put(caseArchiveSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield handleError(e)
    yield put(caseArchiveError(e))
  }
}

function* caseMemberKickedSaga({
  payload: {
    payload: { caseId }
  }
}: TCaseMemberKickedAction) {
  const currentCaseId: string | undefined = yield select((state: State) => state.caseView.data?.id)

  if (currentCaseId === caseId) {
    yield history.replace('/cases')
    yield toast.warn(i18n.t('common.toast.unavailableCase'), toastDefaultOptions)
    yield cancel()
  }

  yield caseAccessLostSaga({ caseId })
}

function* publicationOpinionsSaga({
  payload: { caseId, currentPublicOpinionStatus }
}: TPublicationOpinionsRequest) {
  try {
    yield call(api.patch, API.PUBLICATION_OPINIONS(caseId))

    yield put(publicationOpinionsSuccess({ caseId, currentPublicOpinionStatus }))
  } catch (e) {
    yield put(publicationOpinionsError(e))
    yield handleError(e)
  }
}

function* receivePublicationOpinionsChangedSaga({
  payload: {
    payload: { caseId, currentPublicOpinionStatus }
  }
}: TReceivePublicOpinionsChanged) {
  const currentCaseId: string | undefined = yield select((state: State) => state.caseView.data?.id)

  if (caseId !== currentCaseId) {
    yield cancel()
  }

  yield put(publicationOpinionsSuccess({ caseId, currentPublicOpinionStatus }))
  yield put(getCaseOpinionsMembersRequest({ caseId }))
  yield put(getNotificationsGeneralRequest())

  if (!currentPublicOpinionStatus) {
    const notifications: IDefaultStandaloneNotificationDTO[] = yield select(
      (state: State) => state.caseView.data?.notifications ?? []
    )
    yield put(
      caseClearNotificationAction({
        notifications: notifications.filter(
          ({ type }) => type !== ProducedNotificationsEnum.CASE_OPINION_CREATED
        )
      })
    )
  }
}

function* receiveCaseOpinionCreatedSaga({
  payload: { notificationId, ...notificationRest }
}: TReceiveCaseOpinionCreated) {
  const { caseId, data, id, createdAt, updatedAt, userId, memberId, displayMemberName } =
    notificationRest.payload
  const currentCaseId: string | undefined = yield select((state: State) => state.caseView.data?.id)
  const opinionsMembersIds: string[] = yield select(
    (state: State) => state.caseDetails.opinions.opinionsMembersIds
  )
  const openOpinionMemberId: string | null = yield select(
    (state: State) => state.caseDetails.opinions.openOpinionMemberId
  )

  if (caseId !== currentCaseId) {
    yield cancel()
  }

  if (!opinionsMembersIds.includes(userId)) {
    yield put(getCaseOpinionsMembersRequest({ caseId }))
  }

  yield put(getNotificationsGeneralRequest())

  if (openOpinionMemberId === userId) {
    yield put(
      setNewOpinionAction({
        opinion: {
          caseId,
          data,
          id,
          createdAt,
          updatedAt,
          memberId,
          displayMemberName
        }
      })
    )
  }

  yield put(
    setCaseNotificationAction({
      notification: {
        ...notificationRest,
        id: notificationId
      }
    })
  )

  yield put(setNewLineOpinionAction({ newLineOpinionId: id }))
}

function* caseConvertSaga({
  payload: { id, classification, processingId, type }
}: TCaseConvertRequest) {
  try {
    const currentCaseId: string | undefined = yield select(
      (state: State) => state.caseView.data?.id
    )

    if (id !== currentCaseId) {
      yield cancel()
    }

    yield call(api.patch, API.CASE(id), {
      classificationId: classification.id,
      type
    })

    yield put(caseConvertSuccess({ classification, processingId, type }))
    yield put(hideModalAction())
  } catch (e) {
    yield put(caseConvertError({ ...e, processingId }))
    yield handleError(e)
  }
}

function* setCaseCloudPermissionsModeSaga({
  payload: { id, permissionsMode }
}: TSetCaseCloudPermissionsModeRequest) {
  try {
    yield call(api.patch, API.CASES_PERMISSIONS_MODE(id), { mode: permissionsMode })

    yield put(setCaseCloudPermissionsModeSuccess({ permissionsMode }))
    yield put(hideModalAction())
  } catch (e) {
    yield put(setCaseCloudPermissionsModeError(e))
    yield handleError(e)
  }
}

export function* caseViewSaga() {
  yield takeLatest(CASE_VIEW_REQUEST, caseView)
  yield takeLatest(CASE_LOCK_REQUEST, caseLockSaga)
  yield takeLatest(CASE_UNLOCK_REQUEST, caseUnLockSaga)
  yield takeLatest(CHECK_CASE_UNLOCKING, checkCaseUnlockingSaga)
  yield takeLatest(CASE_STATUS_CHANGED_REQUEST, changeCaseStatusSaga)
  yield takeLatest(CASE_ARCHIVE_REQUEST, caseArchiveSaga)
  yield takeLatest(CASE_MEMBER_KICKED_ACTION, caseMemberKickedSaga)
  yield takeLatest(PUBLICATION_OPINIONS_REQUEST, publicationOpinionsSaga)
  yield takeLatest(RECEIVE_PUBLIC_OPINIONS_CHANGED, receivePublicationOpinionsChangedSaga)
  yield takeLatest(RECEIVE_CASE_OPINION_CREATED, receiveCaseOpinionCreatedSaga)
  yield takeLatest(CASE_CONVERT_REQUEST, caseConvertSaga)
  yield takeLatest(SET_CASE_CLOUD_PERMISSIONS_MODE_REQUEST, setCaseCloudPermissionsModeSaga)
}
