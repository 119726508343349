import { Col, Row } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { editMeeting } from 'api'
import { toastDefaultOptions } from 'globalConstants'
import { TMeeting } from 'store'
import { toast } from 'App/components/ToastContainer'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { ScheduleMeetingSubTitle } from '../ScheduleMeetingSubTitle'
import { ScheduleMeetingModalSecondStep } from '../ScheduleMeetingModalSecondStep'

export type TEditOngoingMeetingDialogFormValues = {
  participants: TAccountsSelectOptionData[]
  inviteAll: boolean
}

export type TEditOngoingMeetingDialogProps = {
  meeting: TMeeting

  onSuccess?: () => void
}

const getSubmitDisabled = (
  initialValues: TEditOngoingMeetingDialogFormValues,
  values: TEditOngoingMeetingDialogFormValues
) => isEqual(initialValues?.participants, values?.participants)

export const EditOngoingMeetingDialog = ({
  meeting,
  onSuccess
}: TEditOngoingMeetingDialogProps) => {
  const [shouldShowSubtitle, setShouldShowSubtitle] = useState(true)

  const { t } = useTranslation()

  const { case: caseData, invites, inviteAllActiveMembers } = meeting ?? {}

  const initialValues = useMemo<TEditOngoingMeetingDialogFormValues>(
    () => ({
      inviteAll: inviteAllActiveMembers,
      participants: invites.map((item) => item.account)
    }),
    [inviteAllActiveMembers, invites]
  )

  const { mutate, isLoading } = useMutation({
    mutationKey: ['edit-ongoing-meeting-request'],
    mutationFn: editMeeting,
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()
      }
      toast.success(t('common.toast.changesSaved'), toastDefaultOptions)
    }
  })

  const onSubmit = useCallback(
    ({ inviteAll, participants }: TEditOngoingMeetingDialogFormValues) => {
      if (meeting.id) {
        mutate({
          meetingId: meeting.id,
          inviteAllActiveMembers: inviteAll,
          guestAccountIds: participants.map(({ id }) => id)
        })
      }
    },
    [meeting.id, mutate]
  )

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        {shouldShowSubtitle && (
          <ScheduleMeetingSubTitle isGroupMeeting={true} caseTitle={caseData?.title} />
        )}
      </Col>

      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, values, invalid }) => (
            <ScheduleMeetingModalSecondStep
              modalTitle={t('modal.scheduleMeeting.title_edit')}
              submitLabel={t('modal.scheduleMeeting.detailsStep.submitButton_edit')}
              alert={t('modal.scheduleMeeting.detailsStep.alert')}
              caseId={caseData?.id}
              isGroupMeeting={true}
              shouldShowReminder={false}
              shouldShowMainFields={false}
              submitLoading={isLoading}
              onSubmit={handleSubmit}
              onToggleMembers={setShouldShowSubtitle}
              submitDisabled={invalid || getSubmitDisabled(initialValues, values)}
            />
          )}
        </Form>
      </Col>
    </Row>
  )
}
