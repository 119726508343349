import {
  TAdministration,
  TGetBusinessUsersSuccess
} from 'store/administration/administration.types'
import { TAction } from 'store/store.utils'
import {
  GET_BUSINESS_USERS_SUCCESS,
  RESET_ADMINISTRATION_STATE_ACTION
} from 'store/administration/administration.actions'

const initialAdministrationState: TAdministration = {
  ids: [],
  list: {},
  canAddAdmin: 0,
  canAddSuperadmin: 0
}

export const administrationReducer = (state = initialAdministrationState, action: TAction) => {
  switch (action.type) {
    case GET_BUSINESS_USERS_SUCCESS: {
      return {
        ...state,
        ...(action as TGetBusinessUsersSuccess).payload
      }
    }

    case RESET_ADMINISTRATION_STATE_ACTION: {
      return {
        ...initialAdministrationState
      }
    }

    default: {
      return state
    }
  }
}
