import { normalize, schema } from 'normalizr'

import {
  TAdministrationNormalized,
  TAdministrationNormalizeResult,
  TBusinessUser
} from 'store/administration/administration.types'

const administrationSchema = new schema.Entity('list', {}, { idAttribute: 'accountId' })
const administrationListSchema = new schema.Array(administrationSchema)

export const administrationNormalize = (
  originalData: TBusinessUser[],
  skipFilter = false
): TAdministrationNormalized => {
  const filteredData: TBusinessUser[] = skipFilter
    ? originalData
    : originalData.filter(({ alterRolePending }) => !alterRolePending)

  const {
    entities: { list },
    result: ids
  }: TAdministrationNormalizeResult = normalize(filteredData, administrationListSchema)

  return {
    list,
    ids
  }
}
