import { useEffect, useLayoutEffect } from 'react'
import { Switch, useLocation, useRouteMatch } from 'react-router-dom'
import {
  AccountTypeNames,
  BusinessAccountRoleNameEnum,
  ChatTypeEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  getChatRoomsRequest,
  getVoiceRoomsRequest,
  resetChatAction,
  setOrganizationId,
  TOrganization,
  useAppDispatch
} from 'store'
import { ChatOrganization, EmptyList, OrganizationHeader } from 'App/components'
import { OrganizationInvitations } from 'App/components/Organizations/OrganizationInvitations'
import { Administration, RoleRouteContainer } from 'App/containers'
import { OrganizationRoomsNav } from 'App/components/Organizations/OrganizationRoomsNav'
import { VoiceRoomContainer } from 'App/containers/Organizations/VoiceRoomContainer'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { AdministrationInvitations } from 'App/containers/Organization/AdministrationInvitations'
import { useSessionStorage } from 'App/hooks/useSessionStorage'
import { ELayoutNavKey } from 'enums'
import { UnityHubViewLayout } from 'App/components/UnityHub'

import { useJoinToChannel } from './useJoinToChannel'

export type TOrganizationViewProps = {
  loading: boolean
  showAvatar: boolean
  organization: TOrganization
}

export const OrganizationView = (props: TOrganizationViewProps) => {
  const { loading, organization, showAvatar } = props
  const { path, url } = useRouteMatch()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { setSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.ORGANIZATIONS })

  const { t } = useTranslation()

  useJoinToChannel(organization.id)

  useLayoutEffect(() => {
    setSessionStorageData(url)
  }, [setSessionStorageData, pathname, url])

  useEffect(
    () => () => {
      dispatch(resetChatAction())
      dispatch(setOrganizationId({ nextOrganizationId: null }))
    },
    [dispatch]
  )
  useEffect(() => {
    if (organization.id) {
      dispatch(
        getChatRoomsRequest({
          organizationId: organization.id,
          chatType: [ChatTypeEnum.ORGANIZATION]
        })
      )
      dispatch(getVoiceRoomsRequest({ organizationId: organization.id }))
      dispatch(setOrganizationId({ nextOrganizationId: organization.id }))
    }
  }, [dispatch, organization.id])

  return (
    <UnityHubViewLayout
      header={
        <OrganizationHeader
          loading={loading}
          showAvatar={showAvatar}
          id={organization.id}
          name={organization.businessProfile.name}
        />
      }
      content={
        <Switch>
          <RoleRouteContainer
            exact
            path={`${path}/chat/:id`}
            render={() => (
              <ChatOrganization
                id={organization.id}
                path={path}
                keySuffix={ELayoutNavKey.ORGANIZATIONS}
                variant="chat"
              />
            )}
          />

          <RoleRouteContainer
            exact
            path={`${path}/voice-chat/:id`}
            render={() => <VoiceRoomContainer organizationId={organization.id} />}
          />

          <RoleRouteContainer
            path={`${path}/invitations`}
            component={OrganizationInvitations}
            allowedAccountTypes={[AccountTypeNames.BUSINESS]}
          />

          <RoleRouteContainer
            path={`${path}/received-administrations`}
            render={() => <AdministrationInvitations />}
            allowedAccountTypes={[AccountTypeNames.PROFESSIONAL]}
            allowedBusinessRoles={[
              BusinessAccountRoleNameEnum.ADMIN,
              BusinessAccountRoleNameEnum.STAFF
            ]}
            trackNotifications={
              new Set([
                ProducedNotificationsEnum.RECEIVED_INVITATION_TO_ADMIN,
                ProducedNotificationsEnum.RECEIVED_INVITATION_TO_SUPERADMIN
              ])
            }
          />

          <RoleRouteContainer
            path={`${path}/administration`}
            render={() => <Administration path={path} organizationId={organization.id} />}
            allowed={organization.isLeaderInDepartments}
            allowedAccountTypes={[AccountTypeNames.BUSINESS]}
          />

          <RoleRouteContainer
            render={() => (
              <EmptyList icon={<AccountGroupIcon />} text={t('organizations.placeholder')} />
            )}
          />
        </Switch>
      }
      nav={<OrganizationRoomsNav organizationId={organization.id} />}
    />
  )
}
