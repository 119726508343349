import { useQuery } from 'services/query'
import { TChannelVariant } from 'interfaces'
import { Spinner } from 'App/components'
import { handleError } from 'api'
import { getEventJoinToChannelTokenInfo } from 'api/events'

import { useEventJoinViaLinkCore } from '../useEventJoinViaLinkCore'

const QUERY_KEY = 'get-event-join-to-channel-token-info'

type TCommunitiesJoinViaLinkToChatProps = {
  variant: Extract<TChannelVariant, 'chat' | 'news'>
}

export const JoinViaLinkToEventChannel = ({ variant }: TCommunitiesJoinViaLinkToChatProps) => {
  const { id, token, isNotProfessional, redirectToDefaultPage, redirectToEvent } =
    useEventJoinViaLinkCore({ variant })

  const { isFetching } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getEventJoinToChannelTokenInfo({ token, eventId: id }),
    enabled: !isNotProfessional,
    onSuccess: ({ name, isMember, id: channelId }) =>
      redirectToEvent({
        title: name,
        channelId,
        isMember
      }),
    onError: (err) => {
      handleError(err)
      redirectToDefaultPage()
    }
  })

  if (isFetching) {
    return <Spinner contentCenter />
  }

  return null
}
