import { useCallback } from 'react'
import { AccountTypeNames, SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers/ModalRoot'
import { useAppSelector, useAppDispatch, showModalAction, accountTypeNameSelector } from 'store'
import { stopPropagation } from 'utils'

export type TUseOrganizationSubscriptionProps<P> = {
  callback?: (props?: P) => void
  allowedAccountTypes?: AccountTypeNames[]
}

export const useBasicSubscription = <P>(props: TUseOrganizationSubscriptionProps<P> = {}) => {
  const {
    callback,
    allowedAccountTypes = [AccountTypeNames.PROFESSIONAL, AccountTypeNames.BUSINESS]
  } = props

  const dispatch = useAppDispatch()

  const accountType = useAppSelector(accountTypeNameSelector)
  const currentSubscription = useAppSelector((state) => state.subscriptions.current)

  const { t } = useTranslation()

  const isBusinessAccount = accountType === AccountTypeNames.BUSINESS

  const allowed = accountType && allowedAccountTypes.includes(accountType)

  const hasPaidSubscription = Boolean(
    currentSubscription?.subscription.id === SubscriptionIdsEnum.BUSINESS ||
      currentSubscription?.subscription.id === SubscriptionIdsEnum.PROFESSIONAL ||
      currentSubscription?.graceOn
  )

  const getOnClickHandler = useCallback(
    (callbackProps?: P) => (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (hasPaidSubscription || !allowed) {
        callback && callback(callbackProps)

        return
      }

      event && stopPropagation(event)
      event?.nativeEvent?.preventDefault()
      event?.preventDefault()

      dispatch(
        showModalAction({
          modalType: EModalComponents.REDIRECT_TO_SUBSCRIPTION_PAGE,
          modalTitle: t('modal.unavailableFeature.title'),
          modalProps: { isBusinessAccount }
        })
      )
    },
    [callback, dispatch, isBusinessAccount, allowed, hasPaidSubscription, t]
  )

  return {
    allowed,
    hasPaidSubscription,
    shouldShowUpgradeDialog: !hasPaidSubscription && allowed,
    getOnClickHandler
  }
}
