import { ShowCaseStatusEnum } from '@medentee/enums'
import { AxiosRequestConfig } from 'axios'

import { TUseRequestComments } from 'App/containers/Showcase/Comments/useRequestComments'
import {
  IShowcaseComment,
  IFilesEntity,
  IShowcaseItem,
  IPreSignUrl,
  TAccountSuggestion,
  IShowcaseReply
} from 'interfaces'
import { ESocketNameSpaces, sockets } from 'services/webSocket'
import { PAGINATION_DEFAULT } from 'types'

import { EHistoryEvents } from '../enums'
import { MAX_SHOWCASE_FILES_AMOUNT } from '../globalConstants'
import { QueryBuilder } from '../utils'
import { API, api, APIData, APIResultsResponse } from '../services/api'

import { biDirectionalQueryBuilder } from './utils'

export type TGetMyShowcasesPayload = {
  page?: number
  showBy?: number
  searchQuery?: string
  searchBy?: 'TITLE' | 'DESCRIPTION'
  status?: ShowCaseStatusEnum[]
}

export const getMyShowcases = async (payload?: TGetMyShowcasesPayload) => {
  const { showBy, page, searchQuery, status, searchBy } = payload || {}
  const url = new QueryBuilder(API.MY_SHOWCASES)
    .searchQuery(searchQuery)
    .searchBy(searchBy)
    .showBy(showBy || PAGINATION_DEFAULT.showBy)
    .page(page)
    .multiSearch('status', status)
    .build()
  const { data }: APIResultsResponse<IShowcaseItem[]> = await api.get(url)

  return data
}

export type TGetShowcasesFeedPayload = {
  page?: number
  showBy?: number
  searchQuery?: string
  searchBy?: 'TITLE' | 'DESCRIPTION' | 'OWNER'
}

export const getShowcasesFeed = async (payload?: TGetShowcasesFeedPayload) => {
  const { searchQuery, showBy, page, searchBy } = payload || {}
  const url = new QueryBuilder(API.SHOWCASES_FEED)
    .searchQuery(searchQuery)
    .searchBy(searchBy)
    .showBy(showBy || PAGINATION_DEFAULT.showBy)
    .page(page)
    .build()
  const { data }: APIResultsResponse<IShowcaseItem[]> = await api.get(url)

  return data
}

export const getShowcaseDraft = async (id: string) => {
  const { data }: APIData<IShowcaseItem> = await api.get(API.SHOWCASE_DRAFT(id))

  return data
}

export type TUpdateShowcaseDraftPayload = {
  id: string
  fileIds?: string[]
  hideModal?: boolean
  onSuccess?: () => void
} & Partial<Pick<IShowcaseItem, 'title' | 'description'>> &
  Partial<TUpdateAudiencePayload>

export const updateShowcaseDraft = async ({ id, ...rest }: TUpdateShowcaseDraftPayload) => {
  const { data }: APIData<IShowcaseItem> = await api.post(API.SHOWCASE_DRAFT(id), rest)

  return data
}

export type TSavedShowcaseType = ('OWN' | 'SHARED')[]

export type TGetSavedShowcasesPayload = {
  page?: number
  showBy?: number
  searchQuery?: string
  searchBy?: 'TITLE' | 'DESCRIPTION' | 'OWNER'
  type?: TSavedShowcaseType
}

export type TGetCommentsRequestPayload = Pick<TUseRequestComments, 'fileId' | 'showcaseId'> & {
  anchorId?: string
  historyEvent?: EHistoryEvents
}
export type TGetRepliesRequestPayload = Pick<TUseRequestComments, 'showcaseId'> & {
  anchorId?: string
  commentId: string
  historyEvent?: EHistoryEvents
}

export type TGetCommentBiIdPayload = {
  id: string
  showcaseId: string
}

type TGetDraftCommentRequestPayload = {
  fileId: string
  showcaseId: string
}

export type TPublishCommentsRequestPayload = {
  fileId: string
  message: string
  showcaseId: string

  quoteCommentId?: string
  isDraft?: boolean
}

export type TEditCommentsRequestPayload = {
  message: string
  showcaseId: string
  commentId: string
}

export type TDeleteCommentsRequestPayload = {
  showcaseId: string
  commentId: string
}

export const getSavedShowcases = async (payload?: TGetSavedShowcasesPayload) => {
  const { showBy, page, searchQuery, searchBy, type } = payload || {}
  const url = new QueryBuilder(API.SAVED_SHOWCASES)
    .showBy(showBy || PAGINATION_DEFAULT.showBy)
    .searchQuery(searchQuery)
    .searchBy(searchBy)
    .multiSearch('type', type)
    .page(page)
    .build()
  const { data }: APIData<{ results: IShowcaseItem[]; total: number }> = await api.get(url)

  return data
}

export const deactivateShowcase = (id: string) =>
  api.patch(API.CHANGE_SHOWCASE_STATUS(id, ShowCaseStatusEnum.DEACTIVATED))

export const getDraftCommentRequest = async ({
  showcaseId,
  fileId
}: TGetDraftCommentRequestPayload) => {
  const { data }: APIData<IShowcaseComment> = await api.get(
    API.SHOWCASES_COMMENT_DRAFT(showcaseId, fileId)
  )

  return data
}

export const publishCommentRequest = async ({
  showcaseId,
  fileId,
  message,
  quoteCommentId,
  isDraft = false
}: TPublishCommentsRequestPayload) => {
  const { data }: APIData<IShowcaseComment> = await api.post(
    API.SHOWCASES_COMMENT(showcaseId, fileId),
    {
      message,
      quoteCommentId,
      isDraft
    }
  )

  return data
}

export const editCommentRequest = async ({
  showcaseId,
  commentId,
  message
}: TEditCommentsRequestPayload) => {
  const { data }: APIData<IShowcaseComment> = await api.patch(
    API.SHOWCASES_COMMENT(showcaseId, commentId),
    { message }
  )

  return data
}
export const deleteCommentById = async ({
  showcaseId,
  commentId
}: TDeleteCommentsRequestPayload) => {
  await api.delete(API.SHOWCASES_COMMENT(showcaseId, commentId))
}

export const getComments = async ({
  showcaseId,
  fileId,
  anchorId,
  historyEvent = EHistoryEvents.HISTORY_FIRST
}: TGetCommentsRequestPayload) => {
  const url = biDirectionalQueryBuilder({
    url: API.SHOWCASES_COMMENTS(showcaseId),
    historyEvent,
    anchorId
  })
    .custom('fileId', fileId)
    .build()

  const { data }: APIData<IShowcaseComment[]> = await api.get(url)

  return data
}

export const getCommentById = async ({ showcaseId, id }: TGetCommentBiIdPayload) => {
  const { data }: APIData<IShowcaseComment> = await api.get(API.SHOWCASES_COMMENT(showcaseId, id))

  return data
}

export const getReplies = async ({
  showcaseId,
  anchorId,
  commentId,
  historyEvent = EHistoryEvents.HISTORY_FIRST
}: TGetRepliesRequestPayload) => {
  const url = biDirectionalQueryBuilder({
    url: API.SHOWCASES_REPLIES(showcaseId, commentId),
    historyEvent,
    anchorId
  }).build()

  const { data }: APIData<IShowcaseReply[]> = await api.get(url)

  return data
}

export const saveToggleShowcase = (id: string) => api.patch(API.SAVE_UNSAVE_SHOWCASE(id))
export const publishShowcase = (id: string) => api.post(API.PUBLISH_SHOWCASE(id))
export const deleteShowcase = (id: string) => api.delete(API.DELETE_SHOWCASE(id))

export type TGetShowcasePresignUrlPayload = {
  showcaseId: string
  fileName: string
  fileSize: number
}

export const getShowcasePresignUrl = async (payload: TGetShowcasePresignUrlPayload) => {
  const { fileName, fileSize, showcaseId } = payload
  const { data }: APIData<IPreSignUrl> = await api.post(API.SHOWCASE_PRESIGN_URL(showcaseId), {
    fileName,
    fileSize,
    socketSessionId: sockets[ESocketNameSpaces.SYSTEM]?.id ?? ''
  })

  return data
}

export type TUploadFilePayload = {
  file: File
} & Pick<AxiosRequestConfig, 'onUploadProgress' | 'signal'> &
  IPreSignUrl

export const uploadFile = async (payload: TUploadFilePayload) => {
  const { url, fields, file, signal, onUploadProgress } = payload

  const formData = new FormData()

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key])
  })

  formData.append('file', file)

  await api.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    withCredentials: false,
    onUploadProgress,
    signal
  })
}

export const confirmUpload = async (key: string) => {
  const { data }: APIData<IFilesEntity> = await api.post(API.UPLOAD_CONFIRM, { key })

  return data
}
export const cancelUpload = async (key: string) => await api.post(API.UPLOAD_CANCEL, { key })
export const continueUpload = async (key: string) => await api.post(API.UPLOAD_CONTINUE, { key })

type TGetMentionsPayload = {
  showcaseId: string

  search?: string
}

export const getAccountSuggestions = async ({ showcaseId, search }: TGetMentionsPayload) => {
  const url = new QueryBuilder(API.SHOWCASE_ACCOUNT_SUGGESTIONS(showcaseId))
    .searchQuery(search)
    .showBy(PAGINATION_DEFAULT.showBy * 2)
    .build()

  const { data }: APIResultsResponse<TAccountSuggestion[]> = await api.get(url)

  return data
}

export const getFileSuggestions = async ({ showcaseId, search }: TGetMentionsPayload) => {
  const url = new QueryBuilder(API.SHOWCASE_FILE_SUGGESTIONS(showcaseId))
    .searchQuery(search)
    .showBy(MAX_SHOWCASE_FILES_AMOUNT)
    .build()

  const { data }: APIResultsResponse<IFilesEntity[]> = await api.get(url)

  return data
}

export type TUpdateAudiencePayload = {
  showcaseId: string
  categoryIds: string[]
  departmentIds: string[]
  shareOuterCategories: boolean
  departmentsOuterOrganizationIds: string[]
}

export const updateAudience = async ({ showcaseId, ...data }: TUpdateAudiencePayload) => {
  const url = new QueryBuilder(API.SHOWCASE_AUDIENCE(showcaseId)).build()

  return api.patch(url, data)
}

export const getShowcase = async (id: string) => {
  const { data }: APIData<IShowcaseItem> = await api.get(API.SHOWCASE(id))

  return data
}
