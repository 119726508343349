import cls from 'classnames'
import { Slider as OriginalSlider, SliderSingleProps } from 'antd'
import { SliderRangeProps } from 'antd/lib/slider'

import styles from './Slider.module.scss'

export type TSliderProps = SliderSingleProps | SliderRangeProps

export const Slider = ({ className, ...rest }: TSliderProps) => (
  <OriginalSlider className={cls(styles.root, className)} {...rest} />
)
