import { TruncatedText } from 'App/components/common'

import styles from './UnityHubInfoLink.module.scss'

export type TUnityHubInfoLinkProps = {
  link: string
}

export const UnityHubInfoLink = ({ link }: TUnityHubInfoLinkProps) => (
  <a target="_blank" rel="noopener noreferrer" href={link} className={styles.root}>
    <TruncatedText rows="two" text={link} />
  </a>
)
