import { ChangeEvent, useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { TextField, Button } from 'App/components'
import { MAX_LENGTH_255 } from 'globalConstants'

import styles from './SettingsInfoEnterEmailStep.module.scss'

export type TSettingsInfoEnterEmailStepProps = {
  onConfirm: (email: string) => void

  onChange?: (email: string) => void
  loading?: boolean
}

type TFormValues = {
  email?: string
}

export const SettingsInfoEnterEmailStep = ({
  onConfirm,
  loading,
  onChange
}: TSettingsInfoEnterEmailStepProps) => {
  const { t } = useTranslation()

  const handleConfirm = useCallback(
    ({ email }: TFormValues) => {
      if (email) {
        onConfirm(email)
      }
    },
    [onConfirm]
  )
  const handleChange = useCallback(
    (input) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim()

      input.onChange(value)

      if (onChange) {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Form<TFormValues> onSubmit={handleConfirm}>
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={styles.root}>
          <p className={styles.description}>{t('modal.changeEmail.subtitle')}</p>

          <Field
            name="email"
            validate={validation.composeValidators(
              validation.required(),
              validation.isEmail(),
              validation.maxLength(MAX_LENGTH_255)
            )}
          >
            {({ input, meta }) => (
              <TextField
                topLabel={t('modal.changeEmail.newEmailField')}
                classes={{ topLabel: styles.topLabel }}
                {...input}
                onChange={handleChange(input)}
                invalid={meta.touched && meta.invalid}
                error={meta.error}
                hideCounter={true}
              />
            )}
          </Field>

          <Button className={styles.button} loading={loading} type="submit" disabled={invalid}>
            {t('modal.changeEmail.submitButton')}
          </Button>
        </form>
      )}
    </Form>
  )
}
