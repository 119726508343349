import { getTimezoneOffset } from 'date-fns-tz'
import { getAllTimezones } from 'countries-and-timezones'

import { formatTimeZoneOffset } from 'utils/formats'

import { Select, TOption, TSelectProps } from '../Fields'

export type TTimeZoneSelectOption = TOption & { offset: number }

export const timeZones: TTimeZoneSelectOption[] = (() => {
  const date = new Date()

  return Object.values(getAllTimezones())
    .map(({ name }) => ({
      value: name,
      offset: getTimezoneOffset(name, date)
    }))
    .sort((a, b) => {
      if (a.offset === b.offset) {
        return a.value > b.value ? 1 : -1
      }

      return a.offset > b.offset ? 1 : -1
    })
    .map(({ value, offset }) => ({
      value,
      offset,
      label: `(UTC${formatTimeZoneOffset(offset)}) ${value}`
    }))
})()

export type TTimeZoneSelectProps<
  Option extends Record<string, any> = Record<string, any>,
  Multi extends boolean = boolean
> = Omit<TSelectProps<Option, Multi>, 'options'>

export const TimeZoneSelect = (props: TTimeZoneSelectProps<TTimeZoneSelectOption, false>) => (
  <Select {...props} labelKey="label" valueKey="value" options={timeZones} />
)
