import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { parse } from 'query-string'
import { EventRepresentativeTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppDispatch } from 'store'
import { EventInfoForm, TEventInfoFormValues } from 'App/components/Events/EventInfoForm'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { UnityHubCreateLayout } from 'App/components'

import { useCreateEventRequest } from './useCreateEventRequest'

export const CreateEvent = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { goBack, location } = useHistory()

  const { representativeId, representativeType } = useMemo(() => {
    const { representativeId: representativeIds, representativeType: representativeTypes } = parse(
      location.search
    )

    return {
      representativeId: Array.isArray(representativeIds)
        ? representativeIds[0]
        : representativeIds ?? undefined,
      representativeType: (Array.isArray(representativeTypes)
        ? representativeTypes[0]
        : representativeTypes ?? undefined) as EventRepresentativeTypeEnum | undefined
    }
  }, [location.search])

  const shouldConfirm = Boolean(representativeId && representativeType)

  const { isLoading, handleSubmit } = useCreateEventRequest()

  const handleConfirm = useCallback(
    (values: TEventInfoFormValues) =>
      dispatch(
        showModalAction({
          modalType: EModalComponents.EVENT_CREATE_CONFIRM,
          modalTitle: t('modal.createEventConfirm.title'),
          modalProps: {
            representativeId,
            representativeType,
            ...values
          }
        })
      ),
    [dispatch, representativeId, representativeType, t]
  )

  return (
    <UnityHubCreateLayout title={t('events.createEventTitle')}>
      <EventInfoForm
        mode="create"
        shouldDisableAfterSubmitSucceeded={!shouldConfirm}
        processing={isLoading}
        onSubmit={shouldConfirm ? handleConfirm : handleSubmit}
        onCancel={goBack}
      />
    </UnityHubCreateLayout>
  )
}
