import { useCallback, useEffect, useRef } from 'react'

export const useRefValue = <T>(value: T) => {
  const ref = useRef(value)

  useEffect(() => {
    ref.current = value
  }, [value])

  const getValue = useCallback(() => ref.current, [])
  const setValue = useCallback((newValue: T) => (ref.current = newValue), [])

  return {
    getValue,
    setValue
  }
}
