import { Skeleton as OriginalSkeleton } from 'antd'

import styles from './Skeleton.module.scss'

export const Skeleton = () => (
  <div className={styles.skeleton}>
    <OriginalSkeleton.Input className={styles.skeletonTitle} size="small" />
    <OriginalSkeleton.Input block size="large" />
  </div>
)
