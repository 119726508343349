import { PropsWithChildren } from 'react'
import cls from 'classnames'

import { NotificationBadge, TNotificationBadgeProps } from 'App/components/common/NotificationBadge'

import styles from './CallOutgoingFooterNotification.module.scss'

export type TCallOutgoingFooterNotificationProps = PropsWithChildren<
  Pick<TNotificationBadgeProps, 'variant' | 'count'>
> & {
  shouldHide?: boolean
}

export const CallOutgoingFooterNotification = ({
  children,
  variant,
  count,
  shouldHide = false
}: TCallOutgoingFooterNotificationProps) =>
  shouldHide ? (
    <>{children}</>
  ) : (
    <div className={styles.root}>
      <NotificationBadge
        count={count}
        variant={variant}
        className={cls(styles.badge, { [styles.dot]: variant === 'dot' })}
        border={true}
      />
      {children}
    </div>
  )
