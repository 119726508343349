import { useMemo, useState } from 'react'
import cls from 'classnames'
import { ProfileLinkTypeNamesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  deleteUserProfileWebsiteRequest,
  editUserProfileOnTheWebRequest,
  setUserProfileOnTheWebEditState,
  setUserProfileOnTheWebUrl,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EOnTheWebState } from 'enums'
import { urlValid, getMapComponent } from 'utils'
import { MAX_LINK_LENGTH } from 'globalConstants'
import { Spinner, ETextFieldSize, TextField, Button, TruncatedText } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { SOCIAL_ICON_MAP } from '../UserProfileSocialLinks'

import styles from './UserProfileWebsite.module.scss'

const WEBSITE_MAP = new Map<ProfileLinkTypeNamesEnum, () => JSX.Element>()
  .set(ProfileLinkTypeNamesEnum.FACEBOOK, () => (
    <>
      {getMapComponent(SOCIAL_ICON_MAP, ProfileLinkTypeNamesEnum.FACEBOOK, {
        className: styles.icon
      })}
      <span className={styles.iconText}>Facebook</span>
    </>
  ))
  .set(ProfileLinkTypeNamesEnum.LINKEDIN, () => (
    <>
      {getMapComponent(SOCIAL_ICON_MAP, ProfileLinkTypeNamesEnum.LINKEDIN, {
        className: styles.icon
      })}
      <span className={styles.iconText}>LinkedIn</span>
    </>
  ))
  .set(ProfileLinkTypeNamesEnum.TWITTER, () => (
    <>
      {getMapComponent(SOCIAL_ICON_MAP, ProfileLinkTypeNamesEnum.TWITTER, {
        className: styles.icon
      })}
      <span className={styles.iconText}>X</span>
    </>
  ))
  .set(ProfileLinkTypeNamesEnum.WEBSITE, () => (
    <>
      {getMapComponent(SOCIAL_ICON_MAP, ProfileLinkTypeNamesEnum.WEBSITE, {
        className: styles.icon
      })}
      <span className={styles.iconText}>Website</span>
    </>
  ))

export type UserProfileWebsiteProps = {
  id: string
}

export const UserProfileWebsite = ({ id }: UserProfileWebsiteProps) => {
  const dispatch = useAppDispatch()

  const [error, setError] = useState<string>('')

  const { t } = useTranslation()

  const {
    editState,
    processing,
    type,
    deletable,
    isNew,
    url = ''
  } = useAppSelector((state) => state.userProfile.onTheWeb.websiteList[id]) || {}

  const isUrlValid = urlValid(url)

  const errorMessage = useMemo(
    () =>
      url.length > MAX_LINK_LENGTH
        ? t('validationErrors.longLink', { length: MAX_LINK_LENGTH, ns: 'errors' })
        : t('validationErrors.invalidLink', { ns: 'errors' }),
    [t, url.length]
  )

  const onChangeEditState = (edit: EOnTheWebState) => {
    dispatch(
      setUserProfileOnTheWebEditState({
        editState: edit,
        id
      })
    )
  }

  const onUrlChange = (value: string) => {
    setError('')
    dispatch(
      setUserProfileOnTheWebUrl({
        url: value,
        id
      })
    )
  }

  const onCloseClick = () => {
    if (editState === EOnTheWebState.EMPTY && deletable) {
      dispatch(deleteUserProfileWebsiteRequest({ id, isNew }))
      return
    }

    onUrlChange('')
    onChangeEditState(EOnTheWebState.EMPTY)

    if (editState === EOnTheWebState.WITH_DATA) {
      dispatch(
        editUserProfileOnTheWebRequest({
          url: null,
          id,
          isNew
        })
      )
    }
  }

  const onSubmit = () => {
    if (url.length === 0) {
      return
    }

    if (isUrlValid) {
      onChangeEditState(EOnTheWebState.WITH_DATA)
      dispatch(
        editUserProfileOnTheWebRequest({
          url,
          isNew,
          id
        })
      )
    } else {
      setError(errorMessage)
    }
  }

  const onLinkClick = () => {
    onChangeEditState(EOnTheWebState.EDITING)
  }

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUrlChange(e.currentTarget.value)
  }

  const onTextFieldBlur = () => {
    if (!isUrlValid) {
      setError(errorMessage)
    }
  }

  const isNeedCloseIcon: boolean =
    (deletable && editState === EOnTheWebState.EMPTY) || editState !== EOnTheWebState.EMPTY

  const isEmpty = editState === EOnTheWebState.EMPTY
  const isEditing = editState === EOnTheWebState.EDITING

  const linkElEmpty = (
    <Button size="xs" onClick={onLinkClick}>
      {t('common.socialLink.linkButton')}
    </Button>
  )

  const isSubmitBtnDisabled = url.length === 0 || !!error

  const linkElEditing = (
    <div className={styles.form}>
      <div
        className={cls({
          [styles.field]: true,
          [styles.fieldWErr]: !!error
        })}
      >
        <TextField
          size={ETextFieldSize.SM}
          placeholder={t('common.socialLink.placeholder')}
          invalid={!!error}
          error={error}
          value={url}
          hideCounter={true}
          onChange={onTextFieldChange}
          onBlur={onTextFieldBlur}
        />
      </div>
      <button className={styles.submitBtn} disabled={isSubmitBtnDisabled} onClick={onSubmit}>
        {t('common.socialLink.submitButton')}
      </button>
    </div>
  )

  const showLink = (isEmpty && linkElEmpty) || (isEditing && linkElEditing)

  const component = processing ? (
    <Spinner contentCenter={true} />
  ) : (
    <>
      <div className={cls({ [styles.info]: true, [styles.infoWErr]: error })}>
        {getMapComponent(WEBSITE_MAP, type)}
      </div>
      <div className={cls({ [styles.details]: true, [styles.detailsWErr]: error })}>
        {showLink || <TruncatedText text={url} classes={{ text: styles.urlText }} />}
        {isNeedCloseIcon && (
          <CrossIcon
            className={cls({
              [styles.closeIcon]: true,
              [styles.closeIconWErr]: error,
              [styles.closeIconWForm]: isEditing
            })}
            onClick={onCloseClick}
          />
        )}
      </div>
    </>
  )

  return <div className={cls({ [styles.root]: true, [styles.rootWErr]: error })}>{component}</div>
}
