import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { CallWrapper } from 'App/components'

export type TCallContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  isInitiatedCall: state.calls.init.isInitiatedCall,
  isAcceptedCall: state.calls.answer.isAcceptedCall,
  isComingCall: state.calls.answer.isComingCall,
  activeVoiceRoom: state.organizations.activeVoiceRoom
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const CallContainer = connect(mapStateToProps, mapDispatchToProps)(CallWrapper)
