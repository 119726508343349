import { MeetingInviteStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { acceptMeetingInviteRequest, rejectMeetingInviteRequest, useAppDispatch } from 'store'

import { InviteStatus } from '../InviteStatus'

import styles from './Actions.module.scss'

export type TActionsProps = {
  id: string

  status?: MeetingInviteStatusEnum
}

export const Actions = ({ id, status }: TActionsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleAccept = () => {
    dispatch(acceptMeetingInviteRequest({ id, processingId: id }))
  }

  const handleReject = () => {
    dispatch(
      rejectMeetingInviteRequest({
        id,
        processingId: id
      })
    )
  }

  return (
    <div className={styles.root}>
      {status === MeetingInviteStatusEnum.ACCEPTED ? (
        <InviteStatus status={status} />
      ) : (
        <>
          {(status === MeetingInviteStatusEnum.PENDING ||
            status === MeetingInviteStatusEnum.REJECTED) && (
            <Button size="xs" onClick={handleAccept}>
              {t('invitations.acceptButton')}
            </Button>
          )}
        </>
      )}
      {status !== MeetingInviteStatusEnum.REJECTED && (
        <Button size="xs" variant="outlined" onClick={handleReject}>
          {t('invitations.rejectButton')}
        </Button>
      )}
    </div>
  )
}
