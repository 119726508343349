import { MentionData } from '@draft-js-plugins/mention'
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftEntity,
  RawDraftEntityRange
} from 'draft-js'

import { IFilesEntity } from 'interfaces/api/files'
import { IDepartment } from 'interfaces/api/organizations'
import { TExtendableCardOptions } from 'store/extendableCard/extendableCard.types'
import { TAccountData } from 'store/store.types'

export type TTrigger = '@' | '/' | '#'

export const DELETED_FILE_MENTION = `/deleted item`

const getIndicesOf = (searchStr: string, str: string, caseSensitive?: boolean) => {
  let tempStr = str
  let tempSearchStr = searchStr

  const searchStrLen = tempSearchStr.length

  if (searchStrLen === 0) {
    return []
  }

  let startIndex = 0
  let index
  const indices = []

  if (!caseSensitive) {
    tempStr = tempStr.toLowerCase()
    tempSearchStr = tempSearchStr.toLowerCase()
  }

  while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
    indices.push(index)
    startIndex = index + searchStrLen
  }

  return indices
}

const getEntityRanges = (text: string, mentionName: string, mentionKey: number) => {
  const indices = getIndicesOf(mentionName, text)

  if (indices.length > 0) {
    return indices.map((offset) => ({
      key: mentionKey,
      length: mentionName.length,
      offset
    }))
  }

  return null
}

type TMentionBase = Pick<MentionData, 'link' | 'avatar' | 'name' | 'id'> & {
  trigger: TTrigger

  clickable?: boolean
}

export type TAccountMention = Pick<
  TAccountData,
  'id' | 'firstName' | 'lastName' | 'displayUserName' | 'type'
> &
  TMentionBase & {
    channelManagerId?: string
    variant: 'account'
  }

export type TDepartmentMention = Pick<IDepartment, 'id' | 'name' | 'deletedAt'> &
  TMentionBase & {
    variant: 'department'
  }

export type TGeneralMention = TMentionBase & {
  variant: 'general'
}

export type TFileMention = IFilesEntity &
  Pick<TExtendableCardOptions, 'showcaseOptions'> &
  TMentionBase & {
    variant: 'file'
  }

export type TGroupHeader = TMentionBase & {
  variant: 'groupHeader'
}

export type TMention =
  | TFileMention
  | TAccountMention
  | TDepartmentMention
  | TGeneralMention
  | TGroupHeader

export type TTriggerMap = [TTrigger, TTrigger]

export const createMentionEntities = (text: string, mentions?: TMention[]) => {
  if (!text.length) {
    return ContentState.createFromText('')
  }

  const expandedText = expandMentions(text, mentions)

  const rawContent = convertToRaw(ContentState.createFromText(expandedText))
  const rawState =
    mentions?.reduce<Record<string, RawDraftEntity>>(
      (previousValue, currentValue, index) => ({
        ...previousValue,
        [index.toString()]: {
          type: currentValue.trigger === '@' ? 'mention' : `${currentValue.trigger}mention`,
          mutability: 'IMMUTABLE',
          data: { mention: currentValue }
        }
      }),
      {}
    ) ?? {}
  rawContent.entityMap = rawState

  rawContent.blocks = rawContent.blocks.map((block) => {
    const ranges: RawDraftEntityRange[] = []

    mentions?.forEach((mention, index) => {
      const name = mention.trigger ? `${mention.trigger}${mention.name}` : mention.name
      const entityRanges = getEntityRanges(block.text, name, index)

      if (entityRanges) {
        ranges.push(...entityRanges)
      }
    })

    return { ...block, entityRanges: ranges }
  })

  return convertFromRaw(rawContent)
}

const replaceNameWithId =
  (name: string, id: string) =>
  (_: string, p1 = '', p2 = '') => {
    const replaced = `<${p2}>`.replace(new RegExp(name, 'gm'), id)

    return `${p1}${replaced}`
  }

const replaceTrigger = (triggersMap: TTriggerMap) => (mention: string) =>
  `${mention}`.replace(new RegExp(`^${triggersMap[0]}`, 'gm'), triggersMap[1])

export const wrapMentions = (editorState: EditorState) => {
  let plainText = editorState.getCurrentContent().getPlainText()

  const draftContentState = convertToRaw(editorState.getCurrentContent())

  Object.values(draftContentState.entityMap).forEach((entity) => {
    const { id, name } = (entity?.data?.mention as MentionData) ?? {}

    const isMention = /mention/.test(entity?.type)

    // eslint-disable-next-line no-useless-escape
    const mentionRegExp = `(^|[^<])(@${name}|\/${name})`
    const accountMentionRegExp = `@${name}`

    const isDepartmentMention =
      new RegExp(accountMentionRegExp, 'gm').test(plainText) &&
      entity?.data?.mention.variant === 'department'

    if (isMention) {
      plainText = plainText.replace(
        new RegExp(mentionRegExp, 'gm'),
        replaceNameWithId(name, `${id}`)
      )
    }

    const mentionWithIdRegExp = `(/|@)${id}`

    if (isDepartmentMention) {
      plainText = plainText.replace(
        new RegExp(mentionWithIdRegExp, 'gm'),
        replaceTrigger(['@', '#'])
      )
    }
  })

  return plainText
}

const replaceDeletedFileMentions = (message: string) =>
  // eslint-disable-next-line no-useless-escape
  message.replace(/<\/\d+>/gm, DELETED_FILE_MENTION)

const expandMentions = (message: string, mentions?: TMention[]) => {
  if (!mentions?.length) {
    return replaceDeletedFileMentions(message)
  }

  let formattedMessage = message

  Object.values(mentions).forEach(({ id, name, trigger, ...rest }) => {
    const label = rest.variant === 'department' && rest.deletedAt ? `${name} (deleted)` : name
    const currentTrigger = rest.variant === 'department' ? '#' : trigger

    formattedMessage = formattedMessage.replace(
      new RegExp(`<${currentTrigger}${id}>`, 'gm'),
      `${trigger}${label}`
    )
  })

  return replaceDeletedFileMentions(formattedMessage)
}

export const createShowcaseFileName = (index: string | number, withTrigger = true) => {
  const name = `Item ${index}`

  return withTrigger ? `/${name}` : name
}
