import { useEffect, useLayoutEffect } from 'react'

import { useQuery } from 'services/query'
import { getRoomAccounts } from 'api/organizations'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { TChannelVariant } from 'interfaces'

import { TCreateVoiceChannelDialogProps } from './CreateVoiceChannelDialog/CreateVoiceChannelDialog'

type TVariant = Extract<TChannelVariant, 'chat' | 'voice'>

export type TUseRequest = Pick<
  Required<TCreateVoiceChannelDialogProps>,
  'channelId' | 'organizationId'
> & {
  variant: TVariant
  setChannelMembers: (acc: TAccountsSelectOptionData[], prefetched?: boolean) => void

  isEdit?: boolean
}

const CHANNEL_KEY_MAP = new Map<TChannelVariant, string>()
  .set('news', 'news-room-accounts')
  .set('voice', 'voice-room-accounts')
  .set('chat', 'chat-room-accounts')

export const useMembersRequest = ({
  isEdit,
  organizationId,
  channelId,
  variant,
  setChannelMembers
}: TUseRequest) => {
  const {
    data: accounts = [],
    refetch,
    isInitialLoading: isLoading
  } = useQuery({
    queryKey: [CHANNEL_KEY_MAP.get(variant), organizationId, channelId],
    queryFn: () => getRoomAccounts({ organizationId, channelId, variant }),
    select: (data) =>
      data.results.map<TAccountsSelectOptionData>(({ account }) => ({
        id: account.id,
        displayUserName: account.displayUserName,
        firstName: account.firstName,
        lastName: account.lastName,
        type: account.type
      })),
    enabled: false,
    cacheTime: 0
  })

  useLayoutEffect(() => {
    if (isEdit) {
      refetch()
    }
  }, [isEdit, refetch])

  useEffect(() => {
    if (isEdit && accounts?.length) {
      setChannelMembers(
        accounts.filter((item) => item.id !== organizationId),
        true
      )
    }
  }, [isEdit, organizationId, accounts, setChannelMembers])

  return {
    accounts,
    isLoading
  }
}
