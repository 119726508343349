import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useSessionStorage } from 'App/hooks/useSessionStorage'
import { EInputSize, ELayoutNavKey } from 'enums'
import { SearchWrapper } from 'App/components/common/SearchWrapper'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'
import {
  PageWrapperContext,
  PageWrapperContextProvider
} from 'App/components/PageWrapper/PageWrapperContext'
import { TNavMenuItem } from 'App/components/common/NavMenu'
import { SearchCascader, TSearchCascaderProps } from 'App/components/common/Fields/SearchCascader'
import { PageWrapper } from 'App/components/PageWrapper'
import { UnityHubBackButton } from 'App/components/UnityHub/UnityHubBackButton'
import { eventNetworkingNestedRoutes } from 'App/App.config'
import { setEventId, useAppDispatch } from 'store'
import { useEventNotificationTracker } from 'App/screens/Events/useEventNotificationTracker'

import styles from './EventNetworking.module.scss'
import { useEventNetworkingFilters } from './useEventNetworkingFilters'

export const EventNetworkingView = () => {
  const dispatch = useAppDispatch()

  const { url, params } = useRouteMatch<{ id?: string }>()
  const { pathname } = useLocation()

  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const {
    page,
    selectedFiltersComponent,
    filtersComponent,
    filtersContent,
    reset,
    setSearch,
    setSearchBy
  } = useContext(PageWrapperContext)

  useEventNotificationTracker('eventNetworking')

  const eventId = params?.id

  useEffect(() => {
    if (eventId) {
      dispatch(setEventId({ nextEventId: eventId }))
    }
  }, [dispatch, eventId])

  const { filters } = useEventNetworkingFilters(eventId)

  const handleSearch = useCallback(
    (searchValue: string, searchByValue: (string | number)[]) => {
      setSearchBy(searchByValue[searchByValue.length - 1].toString())
      setSearch(searchValue)
    },
    [setSearch, setSearchBy]
  )

  const handleResetFilters = useCallback(
    () =>
      reset({
        filters
      }),
    // pathname - is essential
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, reset, pathname]
  )

  const { setSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.EVENTS })

  useEffect(() => {
    setSessionStorageData(url)
  }, [setSessionStorageData, pathname, url])

  const navMenu = useMemo<TNavMenuItem[]>(
    () => [
      {
        link: `/events/${eventId}/highlights/networking`,
        title: t('events.networking.tabs.all'),
        exact: true
      },
      {
        link: `/events/${eventId}/highlights/networking/favorites`,
        title: t('events.networking.tabs.favorites'),
        exact: true
      }
    ],
    [eventId, t]
  )

  const searchOptions = useMemo<TSearchCascaderProps['options']>(
    () => [
      {
        label: t('events.networking.searchByOptions.name'),
        value: 'NAME'
      },
      {
        label: t('events.networking.searchByOptions.location'),
        value: 'LOCATION'
      },
      {
        label: t('events.networking.searchByOptions.company'),
        value: 'COMPANY'
      },
      {
        label: t('events.networking.searchByOptions.position'),
        value: 'POSITION'
      },
      {
        label: t('events.networking.searchByOptions.profession'),
        value: 'PROFESSION'
      }
    ],
    [t]
  )

  return (
    <PageWrapper
      autoScrollOnPagination={page}
      defaultPath={`/events/${eventId}`}
      nestedRoutes={eventNetworkingNestedRoutes}
      navMenu={navMenu}
      classes={{ main: styles.main }}
      search={
        <SearchWrapper filters={filtersComponent} hiddenFilter={isDesktop}>
          <SearchCascader
            options={searchOptions}
            allowClear={true}
            searchSize={EInputSize.L}
            onSearchChange={handleSearch}
            onCascaderChange={handleSearch}
          />
        </SearchWrapper>
      }
      filterContent={filtersContent}
      selectedFilters={selectedFiltersComponent}
      addonBeforeAside={
        <UnityHubBackButton path={`/events/${eventId}`} className={styles.backButton} />
      }
      resetFilter={handleResetFilters}
    />
  )
}

export const EventNetworking = () => (
  <PageWrapperContextProvider>
    <EventNetworkingView />
  </PageWrapperContextProvider>
)
