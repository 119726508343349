import { Link } from 'react-router-dom'
import cls from 'classnames'

import { isAdminAccountSelector, TOrganizationMember, useAppSelector } from 'store'
import { AvatarContainer } from 'App/containers'
import { EAvatarSize } from 'enums'
import { ContextMenu, TruncatedText } from 'App/components/common'
import {
  DisplayProfessionsSpecializations,
  DisplayLocationTime,
  DisplayDepartments,
  UserProfileAdministrationRole
} from 'App/components'

import styles from './StaffTabAccountItem.module.scss'
import { TUseKebabMenuProps, useKebabMenu } from './useKebabMenu'

export type TUserProfileAdministrationItemProps = Pick<
  TUseKebabMenuProps,
  'departmentId' | 'isDepartmentLead' | 'organizationId' | 'onChange'
> & {
  data: TOrganizationMember
}

export const StaffTabAccountItem = ({
  data,
  onChange,
  organizationId,
  departmentId,
  isDepartmentLead
}: TUserProfileAdministrationItemProps) => {
  const {
    role,
    online,
    departments,
    chatId,
    userId,
    account: { id, firstName, lastName, displayUserName, type, city, country, professions }
  } = data
  const isAdmin = useAppSelector(isAdminAccountSelector)

  const profileLink = `/profile/${id}`

  const { getMenuItems } = useKebabMenu({
    organizationId,
    departmentId,
    isDepartmentLead,
    onChange
  })

  const menuItems = getMenuItems({
    departments: departments ?? [],
    staffId: id,
    staffRole: role?.name,
    chatId,
    userId
  })

  const fixedMenuWidth = menuItems.filter(({ hidden }) => !hidden).length > 1

  return (
    <div className={styles.root}>
      <Link to={profileLink}>
        <AvatarContainer
          userId={id}
          size={EAvatarSize.LG}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          type={type.name}
          hasTooltip={false}
          showOnline={online}
        />
      </Link>
      <div className={styles.infoWrapper}>
        <div className={styles.headerWrapper}>
          <Link to={profileLink} className={styles.accountName}>
            <TruncatedText text={displayUserName} />
          </Link>
          {isAdmin && role && (
            <div className={styles.accountRole}>
              <UserProfileAdministrationRole role={role?.name} online={false} />
            </div>
          )}
        </div>
        <DisplayLocationTime
          city={city?.cityName}
          country={country.countryName}
          timeZone={city?.timezone}
        />
        <DisplayProfessionsSpecializations
          data={professions}
          classes={{ root: styles.professions }}
        />
        {departments && <DisplayDepartments items={departments} staffId={id} />}
      </div>
      <ContextMenu
        menuItems={menuItems}
        classes={{
          icon: styles.contextMenuIcon,
          root: cls({ [styles.contextMenuRoot]: fixedMenuWidth })
        }}
      />
    </div>
  )
}
