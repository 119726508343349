import { normalize, schema } from 'normalizr'

import { ICaseEntity } from 'interfaces'
import { TIds } from 'store/store.types'

import { TCasesInvitesList } from './casesInvite.types'

const casesInviteListSchema = new schema.Entity('casesInviteList', {}, { idAttribute: 'id' })
const invitesIds = new schema.Array(casesInviteListSchema)

export type TCaseNormalized = {
  casesInviteList: TCasesInvitesList
  ids: TIds
}

type TCasesInviteListNormalizeResult = {
  entities: {
    casesInviteList: TCasesInvitesList
  }
  result: TIds
}

export const casesInviteListNormalize = (originalData: ICaseEntity[]): TCaseNormalized => {
  const normalizedData: TCasesInviteListNormalizeResult = normalize(originalData, invitesIds)
  return {
    casesInviteList: normalizedData.entities.casesInviteList || {},
    ids: normalizedData.result || []
  }
}
