import { ReactNode } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'

import { ENotificationBadgeSize } from 'enums'

import { UnityHubNavItem, TUnityHubNavItemProps } from './UnityHubNavItem'
import { UnityHubNavItemBadge, TUnityHubNavItemBadgeProps } from './UnityHubNavItemBadge'
import styles from './UnityHubNav.module.scss'

const AVATAR_SIZE = 54

export type TUnityHubNavItem = Pick<TUnityHubNavItemBadgeProps, 'count'> &
  Pick<TUnityHubNavItemProps, 'id' | 'name' | 'to' | 'live'>

export type TUnityHubNavControlItem = Pick<TUnityHubNavItem, 'count' | 'id' | 'to'> & {
  icon: ReactNode
}

export type TUnityHubNavControls = {
  items: TUnityHubNavControlItem[]

  options?: {
    bottom: boolean
  }
}

export type TUnityHubNavType = 'organization' | 'community' | 'event'

export type TUnityHubNavProps = {
  loading: boolean
  items: TUnityHubNavItem[]

  type?: TUnityHubNavType
  controls?: TUnityHubNavControls
}

export const UnityHubNav = ({
  loading,
  items,
  controls = {
    items: [],
    options: {
      bottom: true
    }
  },
  type = 'organization'
}: TUnityHubNavProps) => (
  <div className={styles.root}>
    <nav className={styles.nav}>
      {loading ? (
        <>
          <Skeleton.Avatar size={AVATAR_SIZE} />
          <Skeleton.Avatar size={AVATAR_SIZE} />
        </>
      ) : (
        items.map(({ id, count, name, to, live }) => (
          <UnityHubNavItemBadge
            key={id}
            variant="dot"
            count={count}
            border={true}
            size={ENotificationBadgeSize.LG}
          >
            <UnityHubNavItem type={type} to={to} id={id} live={live} name={name} />
          </UnityHubNavItemBadge>
        ))
      )}
    </nav>

    {!!controls.items.length && (
      <div className={cls(styles.controls, { [styles.bottom]: controls.options?.bottom })}>
        {loading ? (
          <Skeleton.Avatar size={AVATAR_SIZE} />
        ) : (
          controls.items.map(({ id, icon, to, count }) => (
            <UnityHubNavItemBadge key={id} count={count} border={true}>
              <UnityHubNavItem to={to}>
                <div className={styles.controlIcon}>{icon}</div>
              </UnityHubNavItem>
            </UnityHubNavItemBadge>
          ))
        )}
      </div>
    )}
  </div>
)
