import { connect } from 'react-redux'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { NotificationBadge, TNotificationBadgeProps } from 'App/components'
import { TItemKey } from 'types'
import { ECasesDocumentKey } from 'enums'
import { getCaseNotificationsSelector } from 'store'

export type TCaseDocumentBadgeContainerProps = ReturnType<typeof mapStateToProps> &
  TNotificationBadgeProps

const mapStateToProps = (state: State, { itemKey }: TItemKey<ECasesDocumentKey>) => {
  const counters: { [key in ECasesDocumentKey]: number } = {
    [ECasesDocumentKey.DESCRIPTION]: getCaseNotificationsSelector(
      state,
      ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED
    ).length,
    [ECasesDocumentKey.OPINION]: getCaseNotificationsSelector(
      state,
      ProducedNotificationsEnum.CASE_OPINION_CREATED
    ).filter(({ viewed }) => !viewed).length
  }

  return {
    count: counters[itemKey]
  }
}

export const CaseDocumentBadgeContainer = connect(mapStateToProps)(NotificationBadge)
