import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store/errors'
import { RENAME_FILE, renameFileRequest } from 'store/files/myFiles'
import {
  FileRenameDialog,
  TFileRenameDialogContainerOwnProps
} from 'App/components/File/FileRenameDialog'

export type TFileRenameDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const action = RENAME_FILE
const loadingSelector = createProcessingSelector([action])

const mapStateToProps = (state: State) => ({
  processing: loadingSelector(state),
  error: state.errors[action],
  action
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      renameFile: renameFileRequest,
      removeErrorNotification
    },
    dispatch
  )

export const FileRenameDialogContainer: FC<TFileRenameDialogContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileRenameDialog)
