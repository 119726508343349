import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector, showModalAction } from 'store'
import { formatMonthYear } from 'utils'
import { ShowMore, TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as SchoolIcon } from 'assets/icons/School.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { IEducationDTO } from 'interfaces'

import { ItemHeader, ItemLabel, Item } from '../../common'
import styles from '../../common/common.module.scss'

export type TEducationItemProps = {
  data: IEducationDTO

  disableContextMenu?: boolean
  limitExpandedHeight?: boolean
}

export type TEducationItemContainerProps = Pick<
  TEducationItemProps,
  'disableContextMenu' | 'limitExpandedHeight'
> & {
  id: string
}

const SHOW_MORE_ROWS_HEIGHT = 63

export const EducationItemContainer = ({
  id,
  disableContextMenu,
  limitExpandedHeight
}: TEducationItemContainerProps) => {
  const data = useAppSelector((state) => state.userProfile.viewProfile.education.list[id] || {})

  return (
    <Item>
      <EducationItem
        data={data}
        disableContextMenu={disableContextMenu}
        limitExpandedHeight={limitExpandedHeight}
      />
    </Item>
  )
}

export const EducationItem = ({
  data,
  disableContextMenu,
  limitExpandedHeight
}: TEducationItemProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { organization, degree, description, endDate, startDate, subjectField } = data

  const getDegreeLabel = () => {
    if (!degree) {
      return ''
    }

    if (subjectField) {
      return `${degree}, ${subjectField}`
    }

    return degree
  }

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <PencilOutlineIcon />,
        content: t('userProfile.education.kebabMenu.edit'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.userEducation.title_edit'),
              modalType: EModalComponents.EDUCATION_DIALOG,
              modalProps: {
                data
              }
            })
          )
      },
      {
        icon: <TrashIcon />,
        content: t('userProfile.education.kebabMenu.remove'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.removeEducationConfirm.title'),
              modalType: EModalComponents.REMOVE_EDUCATION_DIALOG,
              modalProps: { id: data.id }
            })
          )
      }
    ],
    [data, dispatch, t]
  )

  return (
    <Row gutter={[0, 8]}>
      <Col xs={24}>
        <ItemHeader title={organization} menuItems={!disableContextMenu ? menuItems : undefined} />
      </Col>

      {degree && (
        <Col xs={24}>
          <ItemLabel icon={<SchoolIcon />} label={getDegreeLabel()} />
        </Col>
      )}

      {startDate && endDate && (
        <Col xs={24}>
          <ItemLabel
            icon={<ScheduleIcon />}
            label={`${formatMonthYear(startDate)} — ${formatMonthYear(endDate)}`}
          />
        </Col>
      )}

      {description && (
        <Col xs={24}>
          <ShowMore
            text={description}
            classes={{ wrapper: styles.showMore }}
            visibleHeight={SHOW_MORE_ROWS_HEIGHT}
            limitExpandedHeight={limitExpandedHeight}
          />
        </Col>
      )}
    </Row>
  )
}
