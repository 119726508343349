import { memo, useEffect, useRef } from 'react'
import cls from 'classnames'

import { ReactComponent as SpinnerIcon } from 'assets/icons/Spinner.svg'

import styles from './Spinner.module.scss'

export type TSpinnerProps = PropsWithClassName<{
  width?: number
  height?: number
  contentCenter?: boolean
}>

const SpinnerView = ({
  className,
  width = 30,
  height = 30,
  contentCenter = false
}: TSpinnerProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const { style, parentElement } = ref.current
      style.minHeight = parentElement ? `${parentElement.offsetHeight}px` : '100%'
    }
  }, [ref])

  return (
    <div
      ref={ref}
      className={cls(
        {
          [styles.contentCenter]: contentCenter
        },
        className
      )}
    >
      <div className={styles.root}>
        <SpinnerIcon width={width} height={height} />
      </div>
    </div>
  )
}

export const Spinner = memo(SpinnerView)
