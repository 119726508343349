import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { getCurrentSubscription, useAppSelector } from 'store'

import { AvailableSubscriptionView } from '../../../Subscriptions'
import { MonthlyTopUpButton } from '../MonthlyTopUpButton'

import styles from './MonthlyTopUpDialog.module.scss'

export const MonthlyTopUpDialog = () => {
  const currentSubscription = useAppSelector(getCurrentSubscription)

  const { t } = useTranslation()

  if (!currentSubscription) {
    return null
  }

  return (
    <div className={styles.root}>
      <p className={styles.description}>{t('modal.monthlyTimeTopUp.content')} </p>
      <AvailableSubscriptionView
        {...currentSubscription}
        {...currentSubscription.subscription}
        loading={false}
        processing={false}
        isActive
        isFuture={false}
        className={styles.card}
        subTitle={
          <div className={cls(styles.status, { [styles.grace]: currentSubscription.graceOn })}>
            {currentSubscription.graceOn
              ? t('modal.monthlyTimeTopUp.subscriptionTitle_grace')
              : t('modal.monthlyTimeTopUp.subscriptionTitle')}
          </div>
        }
      />
      <MonthlyTopUpButton>{t('modal.monthlyTimeTopUp.submitButton')}</MonthlyTopUpButton>
    </div>
  )
}
