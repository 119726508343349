import { Skeleton } from 'antd'
import cls from 'classnames'
import { useMemo } from 'react'

import { TruncatedText } from 'App/components/common'

import styles from './UserProfileDetailsItem.module.scss'

type UserProfileDetailsItemClasses = 'root' | 'icon'

type TVariant = 'text' | 'link'

type TTextVariant = {
  variant: Extract<TVariant, 'text'>
  text: string | JSX.Element | null
}

type TLinkVariant = {
  variant: Extract<TVariant, 'link'>
  link: string
}

export type TUserProfileDetailsItemBaseProps = PropsWithClasses<UserProfileDetailsItemClasses> &
  PropsWithClassName<{
    icon: JSX.Element

    loading?: boolean
  }>

type TUserProfileDetailsItemProps = TUserProfileDetailsItemBaseProps & (TTextVariant | TLinkVariant)

export const UserProfileDetailsItem = ({
  icon,
  classes,
  loading = false,
  ...props
}: TUserProfileDetailsItemProps) => {
  const content = useMemo(() => {
    if (props.variant === 'text') {
      return props.text
    }

    if (props.variant === 'link') {
      return (
        <TruncatedText
          text={
            <a target="_blank" rel="noopener noreferrer" href={props.link} className={styles.link}>
              {props.link}
            </a>
          }
        />
      )
    }

    return null
  }, [props.link, props.text, props.variant])

  const shouldShow = loading || (!loading && !!content)

  return shouldShow ? (
    <Skeleton loading={loading} active={true} paragraph={{ rows: 1, width: '100%' }} title={false}>
      <div className={cls(styles.root, classes?.root)}>
        <div className={cls(styles.icon, classes?.icon)}>{icon}</div>
        {content}
      </div>
    </Skeleton>
  ) : null
}
