import { AxiosError } from 'axios'

import {
  action,
  TSendNewMessagePayload,
  TSendNewAudioMessageRequestPayload,
  TGetMessagesHistoryRequestPayload,
  TGetMessagesHistorySuccessPayload,
  TGetMessagesHistoryErrorPayload,
  TReadMessagesRequestPayload,
  TReadMessagesSuccessPayload,
  TRedirectToCaseFromChatPayload,
  TSendNewMessageSuccessPayload,
  TReadAllMessagesRequestPayload,
  TReadAllMessagesSuccessPayload,
  TEndMessageEditingRequestPayload,
  TEndMessageEditingSuccessPayload,
  TReceiveMessageEditingEndSuccessPayload,
  TReceiveMessageEditingStartSuccessPayload,
  TStartMessageEditingRequestPayload,
  TStartMessageEditingSuccessPayload,
  TReadMessagesErrorPayload,
  TSetChatMessagesPayload,
  TError,
  TDeleteAllMessagesRequestPayload,
  TDeleteFailedMessagePayload,
  TSendNewMessageErrorPayload,
  TDeleteMessagePermanentlyRequestPayload,
  TDeleteMessagePermanentlySuccessPayload,
  TReceiveDeleteMessagePermanentlySuccessPayload,
  TChatHistoryEvent,
  TLoseAccessToFilePayload,
  TDiscardFilePermissionPayload,
  TFileRevokePermissionFromChatPayload,
  TDownloadFileFromChatPayload,
  TDeleteFileSuccessPayload,
  TReceiveChatIntermediateStateActionPayload,
  TSetChatIntermediateStateActionPayload,
  TRenameMessageFilePayload,
  TPluralProcessingPayload,
  TDeletedMessagePayload,
  TSetMessagesTypePayload
} from 'store'
import { actionCreator } from 'store/store.utils'

import {
  TChatHistoryMessage,
  TDeleteFileFromChatRequestPayload,
  TGetChatDraftRequestPayload,
  TGetChatDraftSuccessPayload,
  TQuoteMessageCancelPayload,
  TQuoteMessageRequestPayload,
  TAddMessageReactionRequestPayload,
  TUpdateChatDraftRequestPayload,
  TUpdateChatDraftSuccessPayload,
  TRemoveMessageReactionRequestPayload,
  TReceiveAddMessageReactionRequestPayload,
  TReceiveRemoveMessageReactionPayload,
  TSetMessagesReactionsActionPayload,
  TReceiveAddMessageReactionSuccessPayload,
  TReceiveNewMessageSuccessPayload
} from './chatMessages.types'

// ------------------ RECEIVE_NEW_MESSAGE ------------------

export const RECEIVE_NEW_MESSAGE_REQUEST = 'RECEIVE_NEW_MESSAGE_REQUEST'
export const RECEIVE_NEW_MESSAGE_SUCCESS = 'RECEIVE_NEW_MESSAGE_SUCCESS'

export const receiveNewMessageRequest = (payload: TChatHistoryMessage) =>
  actionCreator(RECEIVE_NEW_MESSAGE_REQUEST, payload)
export const receiveNewMessageSuccess = (payload: TReceiveNewMessageSuccessPayload) =>
  actionCreator(RECEIVE_NEW_MESSAGE_SUCCESS, payload)

// ------------------ SEND_NEW_MESSAGE ------------------

export const SEND_NEW_AUDIO_MESSAGE_REQUEST = 'SEND_NEW_AUDIO_MESSAGE_REQUEST'
export const SEND_NEW_MESSAGE_REQUEST = 'SEND_NEW_MESSAGE_REQUEST'
export const SEND_NEW_MESSAGE_SUCCESS = 'SEND_NEW_MESSAGE_SUCCESS'
export const SEND_NEW_MESSAGE_ERROR = 'SEND_NEW_MESSAGE_ERROR'
export const READ_ALL_MESSAGES_REQUEST = 'READ_ALL_MESSAGES_REQUEST'
export const READ_ALL_MESSAGES_SUCCESS = 'READ_ALL_MESSAGES_SUCCESS'

export const sendNewMessageRequest = (payload: TSendNewMessagePayload) =>
  action(SEND_NEW_MESSAGE_REQUEST, payload)

export const sendNewAudioMessageRequest = (payload: TSendNewAudioMessageRequestPayload) =>
  action(SEND_NEW_AUDIO_MESSAGE_REQUEST, payload)

export const sendNewMessageSuccess = (payload: TSendNewMessageSuccessPayload) =>
  action(SEND_NEW_MESSAGE_SUCCESS, payload)

export const sendNewMessageError = (payload: TSendNewMessageErrorPayload) =>
  action(SEND_NEW_MESSAGE_ERROR, payload)

export const readAllMessagesRequest = (payload: TReadAllMessagesRequestPayload) =>
  action(READ_ALL_MESSAGES_REQUEST, payload)

export const readAllMessagesSuccess = (payload: TReadAllMessagesSuccessPayload) =>
  action(READ_ALL_MESSAGES_SUCCESS, payload)

// ------------------ GET_MESSAGES_HISTORY ------------------

export const GET_MESSAGES_HISTORY = 'GET_MESSAGES_HISTORY'
export const GET_MESSAGES_HISTORY_REQUEST = 'GET_MESSAGES_HISTORY_REQUEST'
export const GET_MESSAGES_HISTORY_SUCCESS = 'GET_MESSAGES_HISTORY_SUCCESS'
export const GET_MESSAGES_HISTORY_ERROR = 'GET_MESSAGES_HISTORY_ERROR'

export const getMessagesHistoryRequest = (payload: TGetMessagesHistoryRequestPayload) =>
  action(GET_MESSAGES_HISTORY_REQUEST, payload)

export const getMessagesHistorySuccess = (payload: TGetMessagesHistorySuccessPayload) =>
  action(GET_MESSAGES_HISTORY_SUCCESS, payload)

export const getMessagesHistoryError = (payload: TGetMessagesHistoryErrorPayload) =>
  action(GET_MESSAGES_HISTORY_ERROR, payload)

// ------------------ READ_MESSAGES ------------------

export const READ_MESSAGES = 'READ_MESSAGES'
export const READ_MESSAGES_REQUEST = 'READ_MESSAGES_REQUEST'
export const READ_MESSAGES_SUCCESS = 'READ_MESSAGES_SUCCESS'
export const READ_MESSAGES_ERROR = 'READ_MESSAGES_ERROR'

export const readMessagesRequest = (payload: TReadMessagesRequestPayload) =>
  action(READ_MESSAGES_REQUEST, payload)

export const readMessagesSuccess = (payload: TReadMessagesSuccessPayload) =>
  action(READ_MESSAGES_SUCCESS, payload)

export const readMessagesError = (payload: TReadMessagesErrorPayload) =>
  action(READ_MESSAGES_ERROR, payload)

// ------------------ QUOTE_MESSAGE ------------------

export const QUOTE_MESSAGE_REQUEST = 'QUOTE_MESSAGE_REQUEST'
export const QUOTE_MESSAGE_SUCCESS = 'QUOTE_MESSAGE_SUCCESS'
export const QUOTE_MESSAGE_CANCEL = 'QUOTE_MESSAGE_CANCEL'

export const quoteMessageRequest = (payload: TQuoteMessageRequestPayload) =>
  action(QUOTE_MESSAGE_REQUEST, payload)
export const quoteMessageSuccess = (payload: TQuoteMessageRequestPayload) =>
  action(QUOTE_MESSAGE_SUCCESS, payload)

export const quoteMessageCancel = (payload: TQuoteMessageCancelPayload) =>
  action(QUOTE_MESSAGE_CANCEL, payload)

// ------------------ REDIRECT_TO_CASE_FROM_CHAT ------------------

export const REDIRECT_TO_CASE_FROM_CHAT = 'REDIRECT_TO_CASE_FROM_CHAT'

export const redirectToCaseFromChat = (payload: TRedirectToCaseFromChatPayload) =>
  action(REDIRECT_TO_CASE_FROM_CHAT, payload)

// ------------------ START_MESSAGE_EDITING ------------------

export const START_MESSAGE_EDITING_REQUEST = 'START_MESSAGE_EDITING_REQUEST'
export const START_MESSAGE_EDITING_SUCCESS = 'START_MESSAGE_EDITING_SUCCESS'
export const START_MESSAGE_EDITING_ERROR = 'START_MESSAGE_EDITING_ERROR'

export const startMessageEditingRequest = (payload: TStartMessageEditingRequestPayload) =>
  action(START_MESSAGE_EDITING_REQUEST, payload)

export const startMessageEditingSuccess = (payload: TStartMessageEditingSuccessPayload) =>
  action(START_MESSAGE_EDITING_SUCCESS, payload)

export const startMessageEditingError = (payload: AxiosError | null) =>
  action(START_MESSAGE_EDITING_ERROR, payload)

// ------------------ END_MESSAGE_EDITING ------------------

export const END_MESSAGE_EDITING = 'END_MESSAGE_EDITING'
export const END_MESSAGE_EDITING_REQUEST = 'END_MESSAGE_EDITING_REQUEST'
export const END_MESSAGE_EDITING_SUCCESS = 'END_MESSAGE_EDITING_SUCCESS'
export const END_MESSAGE_EDITING_ERROR = 'END_MESSAGE_EDITING_ERROR'

export const endMessageEditingRequest = (payload: TEndMessageEditingRequestPayload) =>
  action(END_MESSAGE_EDITING_REQUEST, payload)

export const endMessageEditingSuccess = (payload: TEndMessageEditingSuccessPayload) =>
  action(END_MESSAGE_EDITING_SUCCESS, payload)

export const endMessageEditingError = (payload: AxiosError | null) =>
  action(END_MESSAGE_EDITING_ERROR, payload)

// ------------------ RECEIVE_MESSAGE_EDITING_START ------------------

export const RECEIVE_MESSAGE_EDITING_START_SUCCESS = 'RECEIVE_MESSAGE_EDITING_START_SUCCESS'

export const receiveMessageEditingStartSuccess = (
  payload: TReceiveMessageEditingStartSuccessPayload
) => action(RECEIVE_MESSAGE_EDITING_START_SUCCESS, payload)

// ------------------ RECEIVE_MESSAGE_EDITING_END ------------------

export const RECEIVE_MESSAGE_EDITING_END_SUCCESS = 'RECEIVE_MESSAGE_EDITING_END_SUCCESS'

export const receiveMessageEditingEndSuccess = (payload: TReceiveMessageEditingEndSuccessPayload) =>
  action(RECEIVE_MESSAGE_EDITING_END_SUCCESS, payload)

// ------------------ SET_CHAT_MESSAGES ------------------

export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'

export const setChatMessages = (payload: TSetChatMessagesPayload) =>
  action(SET_CHAT_MESSAGES, payload)

// ------------------ DELETE_ALL_MESSAGES ------------------

export const DELETE_ALL_MESSAGES = 'DELETE_ALL_MESSAGES'
export const DELETE_ALL_MESSAGES_REQUEST = 'DELETE_ALL_MESSAGES_REQUEST'
export const DELETE_ALL_MESSAGES_SUCCESS = 'DELETE_ALL_MESSAGES_SUCCESS'
export const DELETE_ALL_MESSAGES_ERROR = 'DELETE_ALL_MESSAGES_ERROR'

export const deleteAllMessagesRequest = (payload: TDeleteAllMessagesRequestPayload) =>
  action(DELETE_ALL_MESSAGES_REQUEST, payload)
export const deleteAllMessagesSuccess = () => action(DELETE_ALL_MESSAGES_SUCCESS)
export const deleteAllMessagesError = (payload: TError) =>
  action(DELETE_ALL_MESSAGES_ERROR, payload)

// ------------------ DELETE_FAILED_MESSAGE ------------------

export const DELETE_FAILED_MESSAGE = 'DELETE_FAILED_MESSAGE'

export const deleteFailedMessageAction = (payload: TDeleteFailedMessagePayload) =>
  action(DELETE_FAILED_MESSAGE, payload)

// ------------------ DELETE_MESSAGE_PERMANENTLY ------------------

export const DELETE_MESSAGE_PERMANENTLY = 'DELETE_MESSAGE_PERMANENTLY'
export const DELETE_MESSAGE_PERMANENTLY_REQUEST = 'DELETE_MESSAGE_PERMANENTLY_REQUEST'
export const DELETE_MESSAGE_PERMANENTLY_SUCCESS = 'DELETE_MESSAGE_PERMANENTLY_SUCCESS'
export const DELETE_MESSAGE_PERMANENTLY_ERROR = 'DELETE_MESSAGE_PERMANENTLY_ERROR'

export const RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS =
  'RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS'

export const deleteMessagePermanentlyRequest = (payload: TDeleteMessagePermanentlyRequestPayload) =>
  action(DELETE_MESSAGE_PERMANENTLY_REQUEST, payload)
export const deleteMessagePermanentlySuccess = (payload: TDeleteMessagePermanentlySuccessPayload) =>
  action(DELETE_MESSAGE_PERMANENTLY_SUCCESS, payload)
export const deleteMessagePermanentlyError = (payload: TPluralProcessingPayload & TError) =>
  action(DELETE_MESSAGE_PERMANENTLY_ERROR, payload)
export const receiveDeleteMessagePermanentlySuccess = (
  payload: TReceiveDeleteMessagePermanentlySuccessPayload
) => action(RECEIVE_DELETE_MESSAGE_PERMANENTLY_SUCCESS, payload)

// ------------------ SET_CHAT_HISTORY_EVENT_ACTION ------------------

export const SET_CHAT_HISTORY_EVENT_ACTION = 'SET_CHAT_HISTORY_EVENT_ACTION'

export const setChatHistoryEventAction = (payload: TChatHistoryEvent) =>
  action(SET_CHAT_HISTORY_EVENT_ACTION, payload)

// ------------------ SET_FAILED_MESSAGES ------------------

export const SET_FAILED_MESSAGES = 'SET_FAILED_MESSAGES'

export const setFailedMessages = (payload: TSendNewMessagePayload[]) =>
  action(SET_FAILED_MESSAGES, payload)

// ------------------ LOSE_ACCESS_TO_FILE ------------------

export const LOSE_ACCESS_TO_FILE = 'LOSE_ACCESS_TO_FILE'

export const loseAccessToFile = (payload: TLoseAccessToFilePayload) =>
  action(LOSE_ACCESS_TO_FILE, payload)

// -------------------- DISCARD_FILE_PERMISSION--------------------------
export const DISCARD_FILE_PERMISSION = 'DISCARD_FILE_PERMISSION'
export const discardFilePermission = (payload: TDiscardFilePermissionPayload) =>
  action(DISCARD_FILE_PERMISSION, payload)

// ------------------ FILE_DISCARD_PERMISSION_FROM_CHAT ------------------

export const FILE_DISCARD_PERMISSION_FROM_CHAT = 'FILE_DISCARD_PERMISSION_FROM_CHAT'
export const FILE_DISCARD_PERMISSION_FROM_CHAT_REQUEST = 'FILE_DISCARD_PERMISSION_FROM_CHAT_REQUEST'
export const FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS = 'FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS'
export const FILE_DISCARD_PERMISSION_FROM_CHAT_ERROR = 'FILE_DISCARD_PERMISSION_FROM_CHAT_ERROR'

export const fileDiscardPermissionFromChatRequest = (payload: TDiscardFilePermissionPayload) =>
  action(FILE_DISCARD_PERMISSION_FROM_CHAT_REQUEST, payload)
export const fileDiscardPermissionFromChatSuccess = (payload: TDeleteFileSuccessPayload) =>
  action(FILE_DISCARD_PERMISSION_FROM_CHAT_SUCCESS, payload)
export const fileDiscardPermissionFromChatError = (payload: TError) =>
  action(FILE_DISCARD_PERMISSION_FROM_CHAT_ERROR, payload)

// ------------------ FILE_PERMISSION_CHANGE_FROM_CHAT ------------------

export const FILE_REVOKE_PERMISSION_FROM_CHAT = 'FILE_REVOKE_PERMISSION_FROM_CHAT'
export const FILE_REVOKE_PERMISSION_FROM_CHAT_REQUEST = 'FILE_REVOKE_PERMISSION_FROM_CHAT_REQUEST'
export const FILE_REVOKE_PERMISSION_FROM_CHAT_SUCCESS = 'FILE_REVOKE_PERMISSION_FROM_CHAT_SUCCESS'
export const FILE_REVOKE_PERMISSION_FROM_CHAT_ERROR = 'FILE_REVOKE_PERMISSION_FROM_CHAT_ERROR'

export const fileRevokePermissionFromChatRequest = (
  payload: TFileRevokePermissionFromChatPayload
) => action(FILE_REVOKE_PERMISSION_FROM_CHAT_REQUEST, payload)
export const fileRevokePermissionFromChatSuccess = (payload: TDeleteFileSuccessPayload) =>
  action(FILE_REVOKE_PERMISSION_FROM_CHAT_SUCCESS, payload)
export const fileRevokePermissionFromChatError = (payload: TError) =>
  action(FILE_REVOKE_PERMISSION_FROM_CHAT_ERROR, payload)

// ------------------ DOWNLOAD_FILE_FROM_CHAT ------------------

export const DOWNLOAD_FILE_FROM_CHAT = 'DOWNLOAD_FILE_FROM_CHAT'
export const DOWNLOAD_FILE_FROM_CHAT_REQUEST = 'DOWNLOAD_FILE_FROM_CHAT_REQUEST'
export const DOWNLOAD_FILE_FROM_CHAT_SUCCESS = 'DOWNLOAD_FILE_FROM_CHAT_SUCCESS'
export const DOWNLOAD_FILE_FROM_CHAT_ERROR = 'DOWNLOAD_FILE_FROM_CHAT_ERROR'

export const downloadFileFromChatRequest = (payload: TDownloadFileFromChatPayload) =>
  action(DOWNLOAD_FILE_FROM_CHAT_REQUEST, payload)

export const downloadFileFromChatSuccess = () => action(DOWNLOAD_FILE_FROM_CHAT_SUCCESS)

export const downloadFileFromChatError = (payload: TError) =>
  action(DOWNLOAD_FILE_FROM_CHAT_ERROR, payload)

// ------------------ RECEIVE_CHAT_INTERMEDIATE_STATE ------------------

export const RECEIVE_CHAT_INTERMEDIATE_STATE = 'RECEIVE_CHAT_INTERMEDIATE_STATE'

export const receiveChatIntermediateStateAction = (
  payload: TReceiveChatIntermediateStateActionPayload
) => action(RECEIVE_CHAT_INTERMEDIATE_STATE, payload)

// ------------------ SET_CHAT_INTERMEDIATE_STATE ------------------

export const SET_CHAT_INTERMEDIATE_STATE = 'SET_INTERMEDIATE_STATE'

export const setChatIntermediateStateAction = (payload: TSetChatIntermediateStateActionPayload) =>
  action(SET_CHAT_INTERMEDIATE_STATE, payload)

// ----------------- RENAME_MESSAGE_FILE --------------------------

export const RENAME_MESSAGE_FILE = 'RENAME_MESSAGE_FILE'

export const renameMessageFile = (payload: TRenameMessageFilePayload) =>
  action(RENAME_MESSAGE_FILE, payload)

// ----------------- GET_CHAT_DRAFT --------------------------

export const UPDATE_CHAT_DRAFT = 'UPDATE_CHAT_DRAFT'
export const UPDATE_CHAT_DRAFT_REQUEST = 'UPDATE_CHAT_DRAFT_REQUEST'
export const UPDATE_CHAT_DRAFT_SUCCESS = 'UPDATE_CHAT_DRAFT_SUCCESS'
export const UPDATE_CHAT_DRAFT_ERROR = 'UPDATE_CHAT_DRAFT_ERROR'

export const updateChatDraftRequest = (payload: TUpdateChatDraftRequestPayload) =>
  action(UPDATE_CHAT_DRAFT_REQUEST, payload)
export const updateChatDraftSuccess = (payload: TUpdateChatDraftSuccessPayload) =>
  action(UPDATE_CHAT_DRAFT_SUCCESS, payload)
export const updateChatDraftError = (payload: TError) => action(UPDATE_CHAT_DRAFT_ERROR, payload)

export const GET_CHAT_DRAFT = 'GET_CHAT_DRAFT'
export const GET_CHAT_DRAFT_REQUEST = 'GET_CHAT_DRAFT_REQUEST'
export const GET_CHAT_DRAFT_SUCCESS = 'GET_CHAT_DRAFT_SUCCESS'
export const GET_CHAT_DRAFT_ERROR = 'GET_CHAT_DRAFT_ERROR'
export const GET_CHAT_DRAFT_CANCEL = 'GET_CHAT_DRAFT_CANCEL'

export const getChatDraftRequest = (payload: TGetChatDraftRequestPayload) =>
  action(GET_CHAT_DRAFT_REQUEST, payload)
export const getChatDraftSuccess = (payload: TGetChatDraftSuccessPayload) =>
  action(GET_CHAT_DRAFT_SUCCESS, payload)
export const getChatDraftError = (payload: TError) => action(GET_CHAT_DRAFT_ERROR, payload)
export const getChatDraftCancel = () => action(GET_CHAT_DRAFT_CANCEL)

// ------------------ RESET_MESSAGES ------------------

export const RESET_MESSAGES = 'RESET_MESSAGES'
export const resetMessages = () => action(RESET_MESSAGES)

// ----------------- DELETE_FILE_FROM_CHAT --------------------------

export const DELETE_FILE_FROM_CHAT = 'DELETE_FILE_FROM_CHAT'
export const DELETE_FILE_FROM_CHAT_REQUEST = 'DELETE_FILE_FROM_CHAT_REQUEST'
export const DELETE_FILE_FROM_CHAT_SUCCESS = 'DELETE_FILE_FROM_CHAT_SUCCESS'
export const DELETE_FILE_FROM_CHAT_ERROR = 'DELETE_FILE_FROM_CHAT_ERROR'

export const deleteFileFromChatRequest = (payload: TDeleteFileFromChatRequestPayload) =>
  action(DELETE_FILE_FROM_CHAT_REQUEST, payload)
export const deleteFileFromChatSuccess = () => action(DELETE_FILE_FROM_CHAT_SUCCESS)
export const deleteFileFromChatError = (payload: TError) =>
  action(DELETE_FILE_FROM_CHAT_ERROR, payload)

// ------------------ END ------------------

// ----------------- MESSAGE_REACTION --------------------------
export const SET_MESSAGES_REACTIONS_ACTION = 'SET_MESSAGES_REACTIONS_ACTION'

export const setMessagesReactionsAction = (payload: TSetMessagesReactionsActionPayload) =>
  action(SET_MESSAGES_REACTIONS_ACTION, payload)

export const ADD_MESSAGE_REACTION = 'ADD_MESSAGE_REACTION'
export const ADD_MESSAGE_REACTION_REQUEST = 'ADD_MESSAGE_REACTION_REQUEST'
export const ADD_MESSAGE_REACTION_SUCCESS = 'ADD_MESSAGE_REACTION_SUCCESS'
export const ADD_MESSAGE_REACTION_ERROR = 'ADD_MESSAGE_REACTION_ERROR'

export const addMessageReactionRequest = (payload: TAddMessageReactionRequestPayload) =>
  action(ADD_MESSAGE_REACTION_REQUEST, payload)
export const addMessageReactionSuccess = (payload: TPluralProcessingPayload) =>
  action(ADD_MESSAGE_REACTION_SUCCESS, payload)
export const addMessageReactionError = (payload: TError & TPluralProcessingPayload) =>
  action(ADD_MESSAGE_REACTION_ERROR, payload)

export const REMOVE_MESSAGE_REACTION = 'REMOVE_MESSAGE_REACTION'
export const REMOVE_MESSAGE_REACTION_REQUEST = 'REMOVE_MESSAGE_REACTION_REQUEST'
export const REMOVE_MESSAGE_REACTION_SUCCESS = 'REMOVE_MESSAGE_REACTION_SUCCESS'
export const REMOVE_MESSAGE_REACTION_ERROR = 'REMOVE_MESSAGE_REACTION_ERROR'

export const removeMessageReactionRequest = (payload: TRemoveMessageReactionRequestPayload) =>
  action(REMOVE_MESSAGE_REACTION_REQUEST, payload)
export const removeMessageReactionSuccess = (payload: TPluralProcessingPayload) =>
  action(REMOVE_MESSAGE_REACTION_SUCCESS, payload)
export const removeMessageReactionError = (payload: TError & TPluralProcessingPayload) =>
  action(REMOVE_MESSAGE_REACTION_ERROR, payload)

// ------------------ END ------------------

// ----------------- RECEIVE_MESSAGE_REACTION --------------------------

export const RECEIVE_ADD_MESSAGE_REACTION_REQUEST = 'RECEIVE_ADD_MESSAGE_REACTION_REQUEST'

export const receiveAddMessageReactionRequest = (
  payload: TReceiveAddMessageReactionRequestPayload
) => action(RECEIVE_ADD_MESSAGE_REACTION_REQUEST, payload)

export const RECEIVE_ADD_MESSAGE_REACTION_SUCCESS = 'RECEIVE_ADD_MESSAGE_REACTION_SUCCESS'

export const receiveAddMessageReactionSuccess = (
  payload: TReceiveAddMessageReactionSuccessPayload
) => action(RECEIVE_ADD_MESSAGE_REACTION_SUCCESS, payload)

export const RECEIVE_REMOVE_MESSAGE_REACTION_REQUEST = 'RECEIVE_REMOVE_MESSAGE_REACTION_REQUEST'

export const receiveRemoveMessageReactionRequest = (
  payload: TReceiveRemoveMessageReactionPayload
) => action(RECEIVE_REMOVE_MESSAGE_REACTION_REQUEST, payload)

export const RECEIVE_REMOVE_MESSAGE_REACTION_SUCCESS = 'RECEIVE_REMOVE_MESSAGE_REACTION_SUCCESS'

export const receiveRemoveMessageReactionSuccess = (
  payload: TReceiveRemoveMessageReactionPayload
) => action(RECEIVE_REMOVE_MESSAGE_REACTION_SUCCESS, payload)

// ------------------ END ------------------

// ------------------ DELETED_MESSAGE ------------------

export const DELETED_MESSAGE = 'DELETED_MESSAGE'
export const deletedMessage = (payload: TDeletedMessagePayload) => action(DELETED_MESSAGE, payload)

// ------------------ END ------------------

// ------------------ MESSAGES_TYPE ------------------

export const SET_MESSAGES_TYPE = 'SET_MESSAGES_TYPE'
export const setMessagesType = (payload: TSetMessagesTypePayload) =>
  action(SET_MESSAGES_TYPE, payload)

// ------------------ END ------------------
