import { AccountTypeNames } from '@medentee/enums'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { State } from 'redux/rootReducer'
import { TAccountRole } from 'store'
import { subscriptionAndPaymentsNestedRoutes } from 'App/App.config'
import { TNavMenuItem } from 'App/components'
import { SettingsPageLayout } from 'App/components/common/SettingsPageLayout'
import { getMapComponent } from 'utils'

type TNavMapProps = { role?: TAccountRole; t: TFunction }

const NAV_ITEMS_MAP = new Map<AccountTypeNames, (props: TNavMapProps) => TNavMenuItem[]>()
  .set(AccountTypeNames.BUSINESS, ({ t }) => [
    {
      link: '/subscription-and-payments/subscription',
      title: t('subscriptions.tabs.subscription'),
      exact: true
    },
    {
      link: '/subscription-and-payments/balance',
      title: t('subscriptions.tabs.balance'),
      exact: true
    },
    {
      link: '/subscription-and-payments/transfer',
      title: t('subscriptions.tabs.transfer'),
      exact: true
    }
  ])
  .set(AccountTypeNames.PROFESSIONAL, ({ t }) => [
    {
      link: '/subscription-and-payments/subscription',
      title: t('subscriptions.tabs.subscription'),
      exact: true
    },
    {
      link: '/subscription-and-payments/balance',
      title: t('subscriptions.tabs.balance'),
      exact: true
    }
  ])

const DEFAULT_PATH = '/subscription-and-payments/balance'

export const SubscriptionAndPayments = () => {
  const { accountType, role } = useSelector((state: State) => ({
    accountType: state.global.accountData?.type.name,
    role: state.global.accountData?.role
  }))

  const { t } = useTranslation()

  const menuList = useMemo(
    () => getMapComponent(NAV_ITEMS_MAP, accountType, { role, t }) ?? [],
    [accountType, role, t]
  )

  if (!accountType) {
    return null
  }

  return (
    <SettingsPageLayout
      title={t('subscriptions.title')}
      menu={{ menuList }}
      defaultPath={DEFAULT_PATH}
      nestedRoutes={subscriptionAndPaymentsNestedRoutes}
    />
  )
}
