import cls from 'classnames'
import {
  AccountTypeNames,
  AccountTypeIdEnum,
  ProducedNotificationsEnum,
  SubscriptionIdsEnum,
  CaseStatusesEnum
} from '@medentee/enums'
import capitalize from 'lodash/capitalize'
import { Trans, useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ReactNode } from 'react'

import {
  NotificationsP2PDetailsLinkToCase,
  ShowMore,
  TNotificationsP2PDetailsLinkToCaseProps,
  TShowMoreProps
} from 'App/components'
import { formatShortDate, getMapComponent } from 'utils'
import { INotificationsProtocolDTO, INotificationsProtocolUserDTO } from 'interfaces'
import { TClasses } from 'types'

import styles from './NotificationsP2PDetailsDescription.module.scss'

export type TNotificationsP2PDetailsDescriptionBase = {
  type: ProducedNotificationsEnum

  classes?: TClasses<'root'>
}

export type TNotificationsP2PDetailsDescriptionProps = TNotificationsP2PDetailsDescriptionBase &
  Omit<TNotificationDescriptionMapProps, 't'> &
  Pick<TShowMoreProps, 'visibleHeight'>

type TNotificationDescriptionMapProps = Pick<INotificationsProtocolDTO, 'file'> &
  Partial<TNotificationsP2PDetailsLinkToCaseProps> & {
    payload?: any
    receiver: INotificationsProtocolUserDTO
    isSystem: boolean
    t: TFunction
  }

const NOTIFICATION_DESCRIPTION_MAP = new Map<
  ProducedNotificationsEnum,
  (props: TNotificationDescriptionMapProps) => ReactNode
>()
  .set(ProducedNotificationsEnum.RECEIVED_NEW_CONTACT_INVITE, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.RECEIVED_NEW_CONTACT_INVITE}_contact`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.ACCEPTED_CONTACT_INVITE, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.ACCEPTED_CONTACT_INVITE}_contact`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.REMOVED_CONTACT, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.REMOVED_CONTACT}`, {
      ns: 'notifications'
    })
  )

  .set(ProducedNotificationsEnum.WITHDREW_CONTACT_INVITE, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.WITHDREW_CONTACT_INVITE}_contact`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.RECEIVED_NEW_CASE_INVITE, ({ caseTitle, t }) =>
    t(`content.${ProducedNotificationsEnum.RECEIVED_NEW_CASE_INVITE}`, {
      ns: 'notifications',
      caseTitle
    })
  )
  .set(ProducedNotificationsEnum.ACCEPTED_CASE_INVITE, ({ caseId, caseTitle, isCaseOwner, t }) => (
    <Trans
      t={t}
      i18nKey={`content.${ProducedNotificationsEnum.ACCEPTED_CASE_INVITE}`}
      ns="notifications"
      components={{
        link: (
          <NotificationsP2PDetailsLinkToCase
            caseId={caseId}
            caseTitle={caseTitle}
            isCaseOwner={isCaseOwner}
          />
        )
      }}
    />
  ))
  .set(ProducedNotificationsEnum.REJECTED_CASE_INVITE, ({ caseId, caseTitle, isCaseOwner, t }) => (
    <Trans
      t={t}
      i18nKey={`content.${ProducedNotificationsEnum.REJECTED_CASE_INVITE}`}
      ns="notifications"
      components={{
        link: (
          <NotificationsP2PDetailsLinkToCase
            caseId={caseId}
            caseTitle={caseTitle}
            isCaseOwner={isCaseOwner}
          />
        )
      }}
    />
  ))
  .set(ProducedNotificationsEnum.WITHDREW_CASE_INVITE, ({ caseId, caseTitle, isCaseOwner, t }) => (
    <Trans
      t={t}
      i18nKey={`content.${ProducedNotificationsEnum.WITHDREW_CASE_INVITE}`}
      ns="notifications"
      components={{
        link: (
          <NotificationsP2PDetailsLinkToCase
            caseId={caseId}
            caseTitle={caseTitle}
            isCaseOwner={isCaseOwner}
          />
        )
      }}
    />
  ))
  .set(ProducedNotificationsEnum.CASE_MEMBER_LEFT, ({ caseId, caseTitle, isCaseOwner, t }) => (
    <Trans
      t={t}
      i18nKey={`content.${ProducedNotificationsEnum.CASE_MEMBER_LEFT}`}
      ns="notifications"
      components={{
        link: (
          <NotificationsP2PDetailsLinkToCase
            caseId={caseId}
            caseTitle={caseTitle}
            isCaseOwner={isCaseOwner}
          />
        )
      }}
    />
  ))
  .set(ProducedNotificationsEnum.CASE_MEMBER_KICKED, ({ caseTitle, t }) =>
    t(`content.${ProducedNotificationsEnum.CASE_MEMBER_KICKED}`, {
      ns: 'notifications',
      caseTitle
    })
  )
  .set(ProducedNotificationsEnum.CASE_OPINION_CREATED, ({ caseId, caseTitle, isCaseOwner, t }) => (
    <Trans
      t={t}
      i18nKey={`content.${ProducedNotificationsEnum.CASE_OPINION_CREATED}`}
      ns="notifications"
      components={{
        link: (
          <NotificationsP2PDetailsLinkToCase
            caseId={caseId}
            caseTitle={caseTitle}
            isCaseOwner={isCaseOwner}
          />
        )
      }}
    />
  ))
  .set(
    ProducedNotificationsEnum.PUBLIC_OPINIONS_CHANGED,
    ({ caseId, caseTitle, payload, isCaseOwner, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.PUBLIC_OPINIONS_CHANGED}_${
          !!payload?.currentPublicOpinionStatus ? 'on' : 'off'
        }`}
        ns="notifications"
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(
    ProducedNotificationsEnum.CASE_STATUS_CHANGED,
    ({ caseId, caseTitle, isCaseOwner, payload, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.CASE_STATUS_CHANGED}_${
          payload?.toStatus as CaseStatusesEnum
        }`}
        ns="notifications"
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(
    ProducedNotificationsEnum.GRANTED_CASE_FILE_PERMISSION,
    ({ isCaseOwner, caseId, caseTitle, file, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.GRANTED_CASE_FILE_PERMISSION}`}
        ns="notifications"
        values={{ fileName: file?.fileName, extension: file?.extension }}
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(
    ProducedNotificationsEnum.GRANTED_AUTO_CASE_FILE_PERMISSION,
    ({ payload, caseTitle, caseId, isCaseOwner, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.GRANTED_AUTO_CASE_FILE_PERMISSION}`}
        ns="notifications"
        values={{ filesCount: payload?.filesCount }}
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(ProducedNotificationsEnum.GRANTED_FILE_PERMISSION, ({ file, t }) =>
    t(`content.${ProducedNotificationsEnum.GRANTED_FILE_PERMISSION}`, {
      ns: 'notifications',
      fileName: file?.fileName,
      extension: file?.extension
    })
  )
  .set(
    ProducedNotificationsEnum.REMOVED_CASE_FILE_PERMISSION,
    ({ isCaseOwner, caseId, caseTitle, file, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.REMOVED_CASE_FILE_PERMISSION}`}
        ns="notifications"
        values={{ fileName: file?.fileName, extension: file?.extension }}
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(ProducedNotificationsEnum.REMOVED_FILE_PERMISSION, ({ file, t }) =>
    t(`content.${ProducedNotificationsEnum.REMOVED_FILE_PERMISSION}`, {
      ns: 'notifications',
      fileName: file?.fileName,
      extension: file?.extension
    })
  )
  .set(
    ProducedNotificationsEnum.DISCARDED_CASE_FILE_PERMISSION,
    ({ isCaseOwner, caseId, caseTitle, file, t }) => (
      <Trans
        t={t}
        i18nKey={`content.${ProducedNotificationsEnum.DISCARDED_CASE_FILE_PERMISSION}`}
        ns="notifications"
        values={{ fileName: file?.fileName, extension: file?.extension }}
        components={{
          link: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseId}
              caseTitle={caseTitle}
              isCaseOwner={isCaseOwner}
            />
          )
        }}
      />
    )
  )
  .set(ProducedNotificationsEnum.DISCARDED_FILE_PERMISSION, ({ file, t }) =>
    t(`content.${ProducedNotificationsEnum.DISCARDED_FILE_PERMISSION}`, {
      ns: 'notifications',
      fileName: file?.fileName,
      extension: file?.extension
    })
  )
  // SYSTEM
  .set(ProducedNotificationsEnum.TWO_FA_ACTIVATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.TWO_FA_ACTIVATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.TWO_FA_DEACTIVATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.TWO_FA_DEACTIVATION}`, {
      ns: 'notifications'
    })
  )
  .set(
    ProducedNotificationsEnum.SUBSCRIBING,
    ({ payload: { subscriptionName, subscriptionId, isCurrentRenew }, t }) => {
      if (subscriptionId === SubscriptionIdsEnum.BASIC_BUSINESS) {
        return (
          <>
            {t(`content.${ProducedNotificationsEnum.SUBSCRIBING}_basicBusiness`, {
              ns: 'notifications'
            })}
            <ol>
              {t(`content.${ProducedNotificationsEnum.SUBSCRIBING}_basicBusinessList`, {
                ns: 'notifications',
                returnObjects: true
              }).map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          </>
        )
      }

      if (subscriptionId === SubscriptionIdsEnum.BASIC_PROFESSIONAL) {
        return (
          <>
            {t(`content.${ProducedNotificationsEnum.SUBSCRIBING}_basicProfessional`, {
              ns: 'notifications'
            })}
            <ol>
              {t(`content.${ProducedNotificationsEnum.SUBSCRIBING}_basicProfessionalList`, {
                ns: 'notifications',
                returnObjects: true
              }).map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          </>
        )
      }

      return isCurrentRenew
        ? t(`content.${ProducedNotificationsEnum.SUBSCRIBING}_renew`, {
            ns: 'notifications'
          })
        : t(`content.${ProducedNotificationsEnum.SUBSCRIBING}`, {
            ns: 'notifications',
            subscriptionName
          })
    }
  )
  .set(
    ProducedNotificationsEnum.AUTO_RENEWING_SUBSCRIPTION,
    ({ payload: { subscriptionName }, t }) =>
      t(`content.${ProducedNotificationsEnum.AUTO_RENEWING_SUBSCRIPTION}`, {
        ns: 'notifications',
        subscriptionName
      })
  )
  .set(ProducedNotificationsEnum.CREATED_ACCOUNT, ({ payload, receiver, t }) => {
    const { accountTypeId, companyName, createdBy } = payload || {}

    if (companyName) {
      if (receiver.type.name === AccountTypeNames.BUSINESS) {
        return t(`content.${ProducedNotificationsEnum.CREATED_ACCOUNT}_receiverBusiness`, {
          ns: 'notifications',
          companyName,
          createdBy
        })
      }

      return t(`content.${ProducedNotificationsEnum.CREATED_ACCOUNT}_company`, {
        ns: 'notifications',
        companyName
      })
    }

    return t(
      `content.${ProducedNotificationsEnum.CREATED_ACCOUNT}_${accountTypeId as AccountTypeIdEnum}`,
      { ns: 'notifications', companyName }
    )
  })
  .set(ProducedNotificationsEnum.CREATED_CONNECTED_ACCOUNT, ({ payload: { accountTypeId }, t }) =>
    t(
      `content.${ProducedNotificationsEnum.CREATED_CONNECTED_ACCOUNT}_${
        accountTypeId as AccountTypeIdEnum
      }`,
      { ns: 'notifications' }
    )
  )
  .set(ProducedNotificationsEnum.ACCEPTED_ADMIN_INVITATION, ({ payload, isSystem, t }) => {
    if (isSystem) {
      return t(`content.${ProducedNotificationsEnum.ACCEPTED_ADMIN_INVITATION}_system`, {
        ns: 'notifications',
        companyName: payload?.companyName
      })
    }

    return t(`content.${ProducedNotificationsEnum.ACCEPTED_ADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  })
  .set(ProducedNotificationsEnum.ACCEPTED_SUPERADMIN_INVITATION, ({ payload, isSystem, t }) => {
    if (isSystem) {
      return t(`content.${ProducedNotificationsEnum.ACCEPTED_SUPERADMIN_INVITATION}_system`, {
        ns: 'notifications',
        companyName: payload?.companyName
      })
    }

    return t(`content.${ProducedNotificationsEnum.ACCEPTED_SUPERADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  })
  .set(ProducedNotificationsEnum.REMOVED_FROM_ADMIN, ({ payload, isSystem, t }) => {
    if (isSystem) {
      return t(`content.${ProducedNotificationsEnum.REMOVED_FROM_ADMIN}_system`, {
        ns: 'notifications',
        companyName: payload?.companyName
      })
    }

    return t(`content.${ProducedNotificationsEnum.REMOVED_FROM_ADMIN}`, {
      ns: 'notifications'
    })
  })
  .set(ProducedNotificationsEnum.REMOVED_FROM_STAFF, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.REMOVED_FROM_STAFF}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.RESIGNED_FROM_ADMIN, ({ payload, isSystem, t }) => {
    if (isSystem) {
      return t(`content.${ProducedNotificationsEnum.RESIGNED_FROM_ADMIN}_system`, {
        ns: 'notifications',
        companyName: payload?.companyName
      })
    }

    return t(`content.${ProducedNotificationsEnum.RESIGNED_FROM_ADMIN}`, {
      ns: 'notifications'
    })
  })
  .set(ProducedNotificationsEnum.RESIGNED_FROM_STAFF, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.RESIGNED_FROM_STAFF}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.GOT_ADMIN_RIGHT, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.GOT_ADMIN_RIGHT}`, {
      ns: 'notifications',
      companyName: payload?.companyName
    })
  )
  .set(ProducedNotificationsEnum.RECEIVED_INVITATION_TO_ADMIN, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.RECEIVED_INVITATION_TO_ADMIN}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.RECEIVED_INVITATION_TO_SUPERADMIN, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.RECEIVED_INVITATION_TO_SUPERADMIN}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.REJECTED_ADMIN_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.REJECTED_ADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.REJECTED_STAFF_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.REJECTED_STAFF_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.REJECTED_SUPERADMIN_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.REJECTED_SUPERADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.WITHDREW_ADMIN_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.WITHDREW_ADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.WITHDREW_STAFF_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.WITHDREW_STAFF_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.WITHDREW_SUPERADMIN_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.WITHDREW_SUPERADMIN_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.ACCEPTED_ADVERT_REQUEST, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.ACCEPTED_ADVERT_REQUEST}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.CASE_DELETED, ({ caseTitle, t }) =>
    t(`content.${ProducedNotificationsEnum.CASE_DELETED}`, {
      ns: 'notifications',
      caseTitle
    })
  )
  .set(ProducedNotificationsEnum.TRANSFER_TOKENS, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.TRANSFER_TOKENS}`, {
      ns: 'notifications',
      amount: payload?.amount
    })
  )
  .set(ProducedNotificationsEnum.STARTING_GRACE_PERIOD, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.STARTING_GRACE_PERIOD}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.TOP_UP_CHARGED, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.TOP_UP_CHARGED}`, {
      ns: 'notifications',
      tokenAmount: payload?.tokenAmount
    })
  )
  .set(ProducedNotificationsEnum.AUTO_TOP_UP_SET, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.AUTO_TOP_UP_SET}`, {
      ns: 'notifications',
      tokenAmount: payload?.tokenAmount,
      date: formatShortDate(payload?.nextChargeAt)
    })
  )
  .set(ProducedNotificationsEnum.AUTO_TOP_UP_CHARGED, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.AUTO_TOP_UP_CHARGED}`, {
      ns: 'notifications',
      tokenAmount: payload?.tokenAmount,
      date: formatShortDate(payload?.nextChargeAt)
    })
  )
  .set(ProducedNotificationsEnum.AUTO_TOP_UP_CANCELED, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.AUTO_TOP_UP_CANCELED}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.ACCEPTED_STAFF_INVITATION, ({ t }) =>
    t(`content.${ProducedNotificationsEnum.ACCEPTED_STAFF_INVITATION}`, {
      ns: 'notifications'
    })
  )
  .set(ProducedNotificationsEnum.REMOVED_USER_FROM_COMMUNITY, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.REMOVED_USER_FROM_COMMUNITY}`, {
      ns: 'notifications',
      communityTitle: payload?.communityTitle
    })
  )
  .set(ProducedNotificationsEnum.REMOVED_USER_FROM_EVENT, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.REMOVED_USER_FROM_EVENT}`, {
      ns: 'notifications',
      eventTitle: payload?.eventTitle
    })
  )
  .set(ProducedNotificationsEnum.GOT_SUPERADMIN_RIGHT, ({ payload, t }) =>
    t(`content.${ProducedNotificationsEnum.GOT_SUPERADMIN_RIGHT}`, {
      ns: 'notifications',
      companyName: payload?.companyName
    })
  )

export const NotificationsP2PDetailsDescription = ({
  classes,
  type,
  caseId,
  caseTitle,
  isCaseOwner,
  payload,
  file,
  isSystem,
  receiver,
  visibleHeight
}: TNotificationsP2PDetailsDescriptionProps) => {
  const { t } = useTranslation()

  const Component = getMapComponent(NOTIFICATION_DESCRIPTION_MAP, type, {
    payload,
    caseId,
    caseTitle,
    isCaseOwner,
    file,
    isSystem,
    receiver,
    t
  })

  return (
    <ShowMore
      visibleHeight={visibleHeight}
      text={
        <div className={cls(styles.root, classes?.root)}>
          {Component ?? <>{capitalize(type).replace(/_/g, ' ')}</>}
        </div>
      }
    />
  )
}
