import { useTranslation } from 'react-i18next'

import {
  ACCEPT_CONTACT_INVITE,
  REMOVE_CONTACT_INVITE,
  createProcessingBySelector,
  useAppSelector
} from 'store'
import { Button, StatusColored } from 'App/components/common'
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import styles from './UserProfileContactRequest.module.scss'

export type TUserProfileContactRequestProps = {
  contactId: string
  accountId: string
  onSuccess: () => void
}

export const UserProfileContactRequest = ({
  contactId,
  accountId,
  onSuccess
}: TUserProfileContactRequestProps) => {
  const { t } = useTranslation()

  const { acceptContactInvitation, rejectContactInvitation } = useContactInvitation()

  const acceptProcessing = useAppSelector(
    createProcessingBySelector([ACCEPT_CONTACT_INVITE])(accountId)
  )
  const rejectProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_CONTACT_INVITE])(contactId)
  )

  const handleApprove = () => {
    acceptContactInvitation({ accountId, onSuccess })
  }

  const handleReject = () => {
    rejectContactInvitation({ contactId, onSuccess })
  }

  return (
    <div className={styles.root}>
      <StatusColored label={t('invitations.invitedToContacts')} color="info" />
      <div className={styles.actions}>
        <Button
          size="md"
          fontWeight="bold"
          variant="inline"
          adaptive={false}
          disabled={acceptProcessing}
          loading={acceptProcessing}
          icon={<CheckIcon />}
          onClick={handleApprove}
        >
          {t('invitations.approveButton')}
        </Button>
        <Button
          size="md"
          fontWeight="bold"
          variant="inline"
          color="error"
          adaptive={false}
          disabled={rejectProcessing}
          loading={rejectProcessing}
          icon={<CrossIcon />}
          onClick={handleReject}
        >
          {t('invitations.rejectButton')}
        </Button>
      </div>
    </div>
  )
}
