import { PropsWithChildren, useEffect, useRef } from 'react'
import cls from 'classnames'

import { useAdaptiveLayout } from 'App/hooks'

import { ScrollToEdgeButtonContextProvider } from '../ScrollToEdgeButton'

import styles from './Scrollable.module.scss'

type TScrollableVariant = 'main' | 'aside'

type TScrollableProps = {
  variant?: TScrollableVariant
  className?: string
  id?: string
  page?: number | null
}

type TScrollable = PropsWithChildren<TScrollableProps>

export const Scrollable = ({ children, className, id, page, variant = 'main' }: TScrollable) => {
  const { isDesktop } = useAdaptiveLayout()
  const refList = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isDesktop && refList.current) {
      refList.current.scrollTo(0, 0)
    }

    if (!isDesktop) {
      window.scrollTo(0, 0)
    }
  }, [isDesktop, page])

  return (
    <div className={cls(styles[variant], styles.root, className)} id={id} ref={refList}>
      <ScrollToEdgeButtonContextProvider targetElement={isDesktop ? refList.current : window}>
        {children}
      </ScrollToEdgeButtonContextProvider>
    </div>
  )
}
