import { ReactElement } from 'react'
import { SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TAvailableSubscriptionContainerProps } from 'App/containers'
import { SubscriptionInfoCard, SubscriptionIcon, SubscriptionPrice } from 'App/components'
import { getMapComponent } from 'utils'
import { SUBSCRIPTION_NAME_MAP } from 'globalConstants'
import { BasicSubscriptionOption } from 'App/components/common/SubscriptionInfoCard/BasicSubscriptionOption'

import { useSubscriptionDetails } from '../useSubscriptionDetails'

import { Actions } from './Actions'
import styles from './AvailableSubscriptionView.module.scss'

export type TAvailableSubscriptionViewProps = Omit<
  TAvailableSubscriptionContainerProps,
  'activateSubscriptions' | 'handleChooseSubscription' | 'showModal'
> & {
  showIcon?: boolean
  subTitle?: ReactElement
  className?: string
  action?: () => void
  showModal?: TAvailableSubscriptionContainerProps['showModal']
}

export const AvailableSubscriptionView = ({
  price,
  duration,
  medCloudCapacity,
  businessAdminLimit,
  id,
  isFuture,
  loading,
  processing,
  active,
  endsOn,
  isActive,
  startedOn,
  actionLabel,
  subTitle,
  showModal,
  action,
  showIcon = true
}: TAvailableSubscriptionViewProps) => {
  const { t } = useTranslation()

  const isBasic =
    id === SubscriptionIdsEnum.BASIC_PROFESSIONAL || id === SubscriptionIdsEnum.BASIC_BUSINESS

  const name = getMapComponent(SUBSCRIPTION_NAME_MAP, id)

  const detailsList = useSubscriptionDetails(id, medCloudCapacity, businessAdminLimit)

  return (
    <SubscriptionInfoCard
      detailList={detailsList}
      detailListAddonBefore={!isBasic && <BasicSubscriptionOption className={styles.sameAsBasic} />}
      loading={loading}
      title={name && t(`subscriptions.plan.${name}`)}
      subTitle={subTitle}
      footerAction={
        action &&
        showModal && (
          <Actions
            id={id}
            isFuture={isFuture}
            isActive={isActive}
            endsOn={endsOn}
            startedOn={startedOn}
            processing={processing}
            actionLabel={actionLabel}
            onClick={action}
            showModal={showModal}
          />
        )
      }
      classes={{
        details: styles.details,
        title: styles.title
      }}
      isFuture={isFuture}
      active={active}
      startAdornment={
        <>
          {showIcon && (
            <SubscriptionIcon
              id={id}
              classes={{
                root: styles.iconRoot,
                icon: styles.icon
              }}
            />
          )}
          <SubscriptionPrice
            classes={{
              root: styles.priceRoot,
              price: styles.price
            }}
            price={price}
            duration={duration}
          />
        </>
      }
    />
  )
}
