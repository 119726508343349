import { memo, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { areEqual, FixedSizeList, ListChildComponentProps } from 'react-window'
import { Skeleton } from 'antd'
import memoize from 'memoize-one'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  GET_CATEGORY_LIST,
  getCategoryListRequest,
  selectCategoriesContactsFilter,
  selectAllCategoriesContactsFilter,
  createProcessingSelector,
  SEND_CATEGORY,
  DELETE_CATEGORY,
  setNotAssignedContactsFilter,
  showModalAction
} from 'store'
import { EIconSize } from 'enums'
import { getVirtualListHeight, stopPropagation } from 'utils'
import { State } from 'redux/rootReducer'
import { useAdaptiveLayout, useDialog } from 'App/hooks'
import { IconButton, Checkbox, ECategoryDialogAction } from 'App/components'
import { TCategoryListData } from 'store/categoryList/categoryList.types'
import { CreateCategory, EModalComponents } from 'App/containers'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'

import styles from './FilterCategories.module.scss'

export type TFilterCategoriesProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & {
    contactType?: AccountTypeNames
  }

export type TSelectCategoryType = 'edit' | 'delete' | 'check'
export type TSelectCategory = (obj: { type: TSelectCategoryType; item: TCategoryListData }) => void

enum EListConfig {
  WIDTH = 100,
  HEIGHT = 144,
  HEIGHT_TABLET = 451,
  ITEM_HEIGHT = 36
}

const SKELETON_ROWS_NUM = 4
const CUSTOM_DESKTOP_WIDTH = '1155'

const createItemData = memoize(
  (
    items: TCategoryListData[],
    toggleItemActive: TSelectCategory,
    checked: TCategoryListData[]
  ) => ({
    items,
    toggleItemActive,
    checked
  })
)

const RowView = ({ style, index, data }: ListChildComponentProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { items, toggleItemActive, checked }: ReturnType<typeof createItemData> = data
  const { id, name }: TCategoryListData = items[index]

  const endAdornmentComponent = (
    <div className={styles.rowActions} onClick={stopPropagation}>
      {isDesktop && (
        <IconButton
          iconComponent={<PencilOutlineIcon />}
          toolTip={isDesktop ? t('contacts.categoriesFilters.editCategoryActionTooltip') : ''}
          iconSize={EIconSize.MD}
          onClick={() => toggleItemActive({ type: 'edit', item: items[index] })}
          classes={{ root: styles.icon }}
        />
      )}
      <IconButton
        iconComponent={<TrashIcon />}
        toolTip={isDesktop ? t('contacts.categoriesFilters.removeCategoryActionTooltip') : ''}
        iconSize={EIconSize.MD}
        onClick={() => toggleItemActive({ type: 'delete', item: items[index] })}
        classes={{ root: styles.icon }}
      />
    </div>
  )

  return (
    <div style={style}>
      <Checkbox
        checked={checked.findIndex((predicate) => predicate.id === id) !== -1}
        label={name}
        endAdornment={endAdornmentComponent}
        classes={{ root: styles.row, label: styles.rowTitle }}
        onChange={() => toggleItemActive({ type: 'check', item: items[index] })}
      />
    </div>
  )
}

const Row = memo(RowView, areEqual)

const FilterCategoriesComponent = ({
  data,
  loading,
  processing,
  checked,
  notAssigned,
  contactType,
  getCategories,
  setChecked,
  setAllChecked,
  setNotAssigned,
  showModal
}: TFilterCategoriesProps) => {
  const { toggle: toggleConfirmDialog, open: openConfirmDialog } = useDialog()

  const { isDesktop, isTablet, customMaxWidth } = useAdaptiveLayout(CUSTOM_DESKTOP_WIDTH)

  const { t } = useTranslation()

  useEffect(() => {
    getCategories({ accountType: contactType })
  }, [contactType, getCategories])

  useEffect(() => {
    if (openConfirmDialog) {
      toggleConfirmDialog()
    }
  }, [openConfirmDialog, toggleConfirmDialog])

  const onRowClick: TSelectCategory = ({ item, type }) => {
    const { id, name } = item
    switch (type) {
      case 'check':
        setChecked({ category: item })
        return

      case 'edit':
        showModal({
          modalType: EModalComponents.CATEGORY_DIALOG,
          modalTitle: t('modal.createCategory.title_edit'),
          modalProps: {
            initialCategoryId: id,
            initialCategoryName: name,
            action: ECategoryDialogAction.EDIT
          }
        })
        return

      case 'delete':
        showModal({
          modalType: EModalComponents.CONFIRM_REMOVE_CATEGORY,
          modalTitle: t('modal.removeCategoryConfirm.title'),
          modalProps: {
            selectedCategoryId: id,
            categoryName: name
          }
        })
        return

      default:
        return
    }
  }

  const selectAllCategory = () => {
    if (data.length === checked.length && notAssigned) {
      setAllChecked({ checked: [] })
      setNotAssigned({ notAssigned: false })
    } else {
      setAllChecked({ checked: data })
      setNotAssigned({ notAssigned: true })
    }
  }

  const listHeight = useMemo(
    () =>
      getVirtualListHeight({
        arr: data,
        itemHeight: EListConfig.ITEM_HEIGHT,
        defaultHeight: isDesktop ? EListConfig.HEIGHT : EListConfig.HEIGHT_TABLET,
        min: 0,
        max: isDesktop ? 4 : data.length
      }),
    [data, isDesktop]
  )

  const handleEditButtonClick = () =>
    showModal({
      modalType: EModalComponents.CATEGORY_DIALOG,
      modalTitle: t('modal.createCategory.title_edit'),
      modalProps: {
        action: ECategoryDialogAction.EDIT
      }
    })

  const list = data.length ? (
    <>
      <div className={styles.header}>
        <p className={styles.title}>{t('contacts.categoriesFilters.title')}</p>
        {!isDesktop && (
          <button className={styles.link} onClick={handleEditButtonClick}>
            <PencilOutlineIcon />{' '}
            <span>{t('contacts.categoriesFilters.editCategoriesButton')}</span>
          </button>
        )}
      </div>

      <Checkbox
        checked={notAssigned && data.length === checked.length}
        indeterminate={
          data.length !== checked.length &&
          ((checked.length !== 0 && (notAssigned || !notAssigned)) || notAssigned)
        }
        label={t('contacts.categoriesFilters.selectAllCheckbox')}
        classes={{ root: styles.row, label: styles.rowTitleAccented }}
        onChange={selectAllCategory}
      />

      <Checkbox
        checked={notAssigned}
        label={t('contacts.categoriesFilters.noCategoryCheckbox')}
        classes={{ root: styles.row }}
        onChange={() => setNotAssigned({ notAssigned: !notAssigned })}
      />

      <FixedSizeList
        height={listHeight}
        itemSize={EListConfig.ITEM_HEIGHT}
        width={`${EListConfig.WIDTH}%`}
        itemData={createItemData(data, onRowClick, checked)}
        itemCount={data.length}
      >
        {Row}
      </FixedSizeList>
    </>
  ) : (
    <p className={styles.emptyTitle}>{t('contacts.categoriesFilters.placeholder')}</p>
  )

  return (
    <div className={styles.root}>
      <Skeleton
        active={true}
        title={false}
        loading={loading || processing}
        paragraph={{ rows: SKELETON_ROWS_NUM, width: '100%' }}
      >
        {list}
      </Skeleton>
      <div className={styles.createCategoryButton}>
        <CreateCategory contactType={contactType} hideIcon={customMaxWidth && !isTablet} />
      </div>
    </div>
  )
}

const loadingSelector = createLoadingSelector([GET_CATEGORY_LIST])
const processingSelector = createProcessingSelector([SEND_CATEGORY, DELETE_CATEGORY])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  processing: processingSelector(state),
  data: state.categoryList.results,
  checked: state.contacts.filters.selectedCategories,
  notAssigned: state.contacts.filters.notAssigned
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCategories: getCategoryListRequest,
      setChecked: selectCategoriesContactsFilter,
      setAllChecked: selectAllCategoriesContactsFilter,
      setNotAssigned: setNotAssignedContactsFilter,
      showModal: showModalAction
    },
    dispatch
  )

export const FilterCategories = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCategoriesComponent)
