import { ReactElement, useState } from 'react'
import cls from 'classnames'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

import { useQuery } from 'services/query'
import { stopPropagation, minuteFormat, formatDate } from 'utils'
import { ReactComponent as PlayCircleIcon } from 'assets/icons/PlayCircle.svg'
import { ReactComponent as PauseIcon } from 'assets/icons/Pause.svg'
import { getAudioMessageFileUrl } from 'api'

import styles from './ChatMessageAudioListen.module.scss'

export type TChatMessageAudioListenProps = {
  url?: string
  chatId?: string
  audioFileId?: string
  defaultDuration?: number
  autoPlay?: boolean
  white?: boolean
  small?: boolean
  className?: string
}

type CustomUIModule = RHAP_UI | ReactElement

export const ChatMessageAudioListen = ({
  chatId,
  audioFileId,
  url,
  defaultDuration = 0,
  autoPlay = false,
  white = false,
  small = false,
  className = ''
}: TChatMessageAudioListenProps) => {
  const [autoPlayAfterSrcChange, setAutoPlayAfterSrcChange] = useState(autoPlay)

  const { data: audioMessageFileUrl, refetch: fetchUrl } = useQuery({
    queryKey: ['get-audio-message-file-url', audioFileId],
    queryFn: () =>
      chatId && audioFileId ? getAudioMessageFileUrl(chatId, audioFileId) : undefined,
    onSuccess: () => {
      setAutoPlayAfterSrcChange(true)
    },
    enabled: false
  })

  const onPlay = () => {
    !url && !audioMessageFileUrl ? fetchUrl() : setAutoPlayAfterSrcChange(true)
  }

  const customProgressBarSection: CustomUIModule[] = [
    RHAP_UI.PROGRESS_BAR,
    RHAP_UI.DURATION,
    RHAP_UI.CURRENT_TIME
  ]

  return (
    <div
      className={cls({
        [styles.audioPlayer]: true,
        [styles.white]: white,
        [styles.small]: small,
        [className]: !!className
      })}
    >
      <div onClick={stopPropagation}>
        <AudioPlayer
          preload="none"
          src={url ?? audioMessageFileUrl}
          timeFormat={minuteFormat}
          autoPlay={autoPlay}
          autoPlayAfterSrcChange={autoPlayAfterSrcChange}
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          customProgressBarSection={customProgressBarSection}
          customIcons={{
            play: <PlayCircleIcon onClick={onPlay} />,
            pause: <PauseIcon />
          }}
          defaultDuration={formatDate(new Date(defaultDuration * 1000), minuteFormat)}
        />
      </div>
    </div>
  )
}
