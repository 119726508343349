import { ReactNode } from 'react'
import { Skeleton } from 'antd'
import { formatDistanceToNowStrict } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { ReactComponent as LinkIcon } from 'assets/icons/LinkChain.svg'
import { showModalAction, useAppDispatch } from 'store'
import { InvitationLinkField } from 'App/components/common/Fields/InvitationLinkField'
import { DownloadableQRCode } from 'App/components/common/DownloadableQRCode'

import { EModalComponents } from '../ModalRoot'
import { TGenericConfirmationProps } from '../GenericConfirmation'
import { Button, Help, Popover, TPopoverProps } from '../../components'
import { HOUR, getLocale, stopPropagation } from '../../../utils'

import styles from './InvitePopover.module.scss'

export type TInvitePopoverProps = Required<Pick<TPopoverProps, 'visible' | 'onVisibleChange'>> &
  Partial<Pick<TPopoverProps, 'children' | 'placement'>> & {
    disabled?: boolean
    byEmail?: {
      modalTitle: string
      modalProps: {
        description: ReactNode
        onConfirm: (emails: string[]) => Promise<any>
      }
    }
    nestIn?: 'body' | 'parent'
    visible: boolean
    byLink?: {
      isFetching: boolean
      qrCode?: { fileName: string }
      data?: {
        token: string
        maxRegistrants?: number
        createdAccountsCount?: number
        expiredAt: string
      }
      inviteLink: string
      tooltip?: ReactNode
      settings?: true
      deactivate?: {
        modalTitle: string
        modalProps: TGenericConfirmationProps
      }
      onClick?: () => void
    }
  }

export const InvitePopover = ({
  disabled,
  visible: controlledVisible,
  onVisibleChange,
  byEmail,
  byLink,
  visible,
  nestIn = 'parent',
  placement = 'bottomRight',
  ...props
}: TInvitePopoverProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleSettingsClick = () => {
    onVisibleChange(false)

    dispatch(
      showModalAction({
        modalType: EModalComponents.INVITATION_LINK_SETTINGS,
        modalTitle: t('modal.invitationLinkSettings.title'),
        modalProps: {
          maxRegistrants: byLink?.data?.maxRegistrants ?? 0,
          createdAccountsNumber: byLink?.data?.createdAccountsCount ?? 0
        }
      })
    )
  }
  const deactivate = byLink?.deactivate
  const handleDeactivateClick = deactivate
    ? () => {
        onVisibleChange(false)
        dispatch(
          showModalAction({
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalTitle: deactivate.modalTitle,
            modalProps: deactivate.modalProps
          })
        )
      }
    : undefined

  const handleInviteByEmailClick = byEmail
    ? () => {
        onVisibleChange(false)

        dispatch(
          showModalAction({
            modalType: EModalComponents.INVITE_BY_EMAIL,
            modalTitle: byEmail.modalTitle,
            modalProps: byEmail.modalProps
          })
        )
      }
    : undefined

  const tooltip = byLink?.tooltip && <Help shouldShowSub={false} content={byLink.tooltip} />
  const expiredAt = new Date(byLink?.data?.expiredAt ?? 0)
  const unit = expiredAt.getTime() - Date.now() > HOUR ? 'hour' : 'minute'

  return (
    <Popover
      {...props}
      placement={placement}
      visible={!disabled && visible}
      content={
        <div className={styles.popoverContent} onClick={stopPropagation}>
          {byEmail && (
            <Button
              variant="text"
              icon={<EmailIcon />}
              className={styles.button}
              onClick={handleInviteByEmailClick}
            >
              {t('common.invitationPopover.inviteByEmailButton')}l
            </Button>
          )}
          {byLink && (
            <Skeleton
              active
              className={styles.skeleton}
              loading={byLink?.isFetching}
              title={false}
              paragraph={{ rows: 1 }}
            >
              {byLink?.data ? (
                <>
                  <div className={styles.linkBlock}>
                    <InvitationLinkField value={byLink.inviteLink} />

                    {byLink.settings && (
                      <div className={styles.settings}>
                        <Button variant="underlined" onClick={handleSettingsClick}>
                          {t('common.invitationPopover.linkSettingsButton')}
                        </Button>
                        {tooltip}
                        <span className={styles.expiration}>
                          {t('common.invitationPopover.expiresInLabel', {
                            time: formatDistanceToNowStrict(expiredAt, {
                              unit,
                              locale: getLocale()
                            })
                          })}
                        </span>
                      </div>
                    )}
                  </div>
                  {byLink.deactivate && (
                    <Button
                      variant="underlined"
                      color="error"
                      adaptive={false}
                      onClick={handleDeactivateClick}
                    >
                      {t('common.invitationPopover.deactivateButton')}
                    </Button>
                  )}
                  {byLink.qrCode && (
                    <DownloadableQRCode
                      size={160}
                      value={byLink.inviteLink}
                      fileName={byLink.qrCode.fileName}
                    />
                  )}
                </>
              ) : (
                <>
                  {byLink.onClick && (
                    <Button
                      className={styles.button}
                      variant="text"
                      icon={<LinkIcon />}
                      loading={byLink.isFetching}
                      onClick={byLink.onClick}
                    >
                      {t('common.invitationPopover.inviteByLinkButton')}&nbsp;{tooltip}
                    </Button>
                  )}
                </>
              )}
            </Skeleton>
          )}
        </div>
      }
      getPopupContainer={(node) => {
        if (nestIn === 'parent') {
          return node
        }

        return document.body
      }}
      onVisibleChange={onVisibleChange}
    />
  )
}
