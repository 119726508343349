import { CaseStatusesEnum } from '@medentee/enums'

import { TruncatedText, ChatTypingIndicator, TChatTypingIndicatorProps } from 'App/components'
import { ReactComponent as CaseLockedIcon } from 'assets/icons/CaseLocked.svg'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'

import styles from './ChatRoomCaseName.module.scss'

type TChatRoomCaseNameProps = Pick<TChatTypingIndicatorProps, 'chatId'> & {
  title: string
  caseStatus: CaseStatusesEnum

  isChatRoomMuted?: boolean
}

export const ChatRoomCaseName = ({
  chatId,
  title,
  caseStatus,
  isChatRoomMuted
}: TChatRoomCaseNameProps) => (
  <div className={styles.container}>
    <div className={styles.root}>
      <TruncatedText text={title} />
      {caseStatus === CaseStatusesEnum.LOCKED && <CaseLockedIcon className={styles.icon} />}
      {isChatRoomMuted && <ChatMutedIcon size="md" />}
    </div>

    <ChatTypingIndicator chatId={chatId} />
  </div>
)
