import { memo } from 'react'
import { MessageTypeEnum } from '@medentee/enums'
import cls from 'classnames'

import { useAppSelector } from 'store'

import { ChatMessageReactionItem, TChatMessageReactionItemProps } from '../ChatMessageReactionItem'

import styles from './ChatMessageReactionList.module.scss'

export type TChatMessageReactionListProps = Pick<TChatMessageReactionItemProps, 'isImage'> &
  PropsWithClassName<{
    chatId: string
    messageId: string
    messageType: MessageTypeEnum

    isMessageOutgoing?: boolean
  }>

const ChatMessageReactionListView = ({
  chatId,
  messageId,
  messageType,
  isMessageOutgoing,
  className,
  isImage
}: TChatMessageReactionListProps) => {
  const emojis = useAppSelector(
    (state) => state.chat.chatMessages.reactions.messageList[messageId]?.emojis
  )

  const isMessageTypeFile = messageType === MessageTypeEnum.FILE

  const shouldShowReactions =
    isMessageTypeFile ||
    messageType === MessageTypeEnum.TEXT ||
    messageType === MessageTypeEnum.AUDIO

  if (!shouldShowReactions || !emojis?.size) {
    return null
  }

  return (
    <div className={cls(styles.root, className)}>
      {Array.from(emojis).map((emoji) => (
        <ChatMessageReactionItem
          key={emoji}
          chatId={chatId}
          isImage={isImage}
          isMessageOutgoing={isMessageOutgoing}
          reaction={emoji}
          messageId={messageId}
        />
      ))}
    </div>
  )
}

export const ChatMessageReactionList = memo(ChatMessageReactionListView)
