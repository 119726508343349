import { TFileViewerProps } from 'App/components'

import { TUseSwiperOptions } from '../../App/components/common/ExtendableCard/hooks'

import * as actionCreators from './extendableCard.actions'

export type TExtendableCardInnerComponentProps = TFileViewerProps | Record<string, unknown>
export type TShowExtendableCardSlide = TFileViewerProps

export type TExtendableCardOptions = Pick<
  TUseSwiperOptions,
  'thumbnailOptions' | 'isThumbnails'
> & {
  showcaseOptions?: Partial<{
    showcaseId: string
    ownerId: string
  }>
}

export type TExtendableCardState = {
  type: null | EExtendableCardType
  position: ECardPosition
  size: ECardSize
  slides: TShowExtendableCardSlide[]

  initialSlideId?: null | string
  allowPosition?: boolean
  allowSize?: boolean
  options?: TExtendableCardOptions
}

export type TShowExtendableCardActionPayload = {
  type: EExtendableCardType

  position?: ECardPosition
  size?: ECardSize
  allowPosition?: boolean
  allowSize?: boolean
  initialSlideId?: string
  slides?: TShowExtendableCardSlide[]

  options?: TExtendableCardOptions
}

export type TExpandExtendableCardActionPayload = NonNullableBy<
  Pick<TExtendableCardState, 'initialSlideId'>,
  'initialSlideId'
>

export enum EExtendableCardType {
  FILE_VIEWER = 'FILE_VIEWER',
  SHOWCASE_GALLERY = 'SHOWCASE_GALLERY'
}

export enum ECardPosition {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ECardSize {
  FULLSCREEN = 'fullscreen',
  COLLAPSED = 'collapsed'
}

export type TCollapseExtendableCardActionPayload = {
  position: ECardPosition
}

export type TMoveExtendableCardActionPayload = {
  position: ECardPosition
}

export type TExtendableCardAction = ReturnType<TInferValueTypes<typeof actionCreators>>
