import { useCallback, useState } from 'react'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { Skeleton } from 'antd'

import { AvatarContainer, TCaseAutoApproveDialogItemContainerProps } from 'App/containers'
import { AccountName, Switch } from 'App/components'
import { EAvatarSize } from 'enums'

import styles from './CaseAutoApproveDialogItem.module.scss'

export type TCaseAutoApproveDialogItemProps = TCaseAutoApproveDialogItemContainerProps

export const CaseAutoApproveDialogItem = ({
  contactId,
  caseAutoApproveMode,
  displayUserName,
  firstName,
  lastName,
  type,
  userId,
  loading,
  onSwitch
}: TCaseAutoApproveDialogItemProps) => {
  const [value, setValue] = useState<boolean>(caseAutoApproveMode)
  const handleSwitch: SwitchChangeEventHandler = useCallback(
    (checked) => {
      onSwitch(checked, caseAutoApproveMode, contactId)
      setValue(checked)
    },
    [contactId, caseAutoApproveMode, onSwitch]
  )

  return (
    <Skeleton loading={loading} title={false} paragraph={{ rows: 3 }} className={styles.skeleton}>
      <div className={styles.root}>
        <div className={styles.user}>
          <AvatarContainer
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type}
            userId={userId}
            size={EAvatarSize.XS}
            hasTooltip={false}
          />
          <AccountName
            classes={{ root: styles.userName }}
            displayUserName={displayUserName}
            type={type}
          />
        </div>

        <Switch checked={value} onChange={handleSwitch} />
      </div>
    </Skeleton>
  )
}
