import { useCallback } from 'react'
import { FormApi } from 'final-form'
import noop from 'lodash/noop'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { businessAccountValidateCode, businessAccountSendCode } from 'api/invitations'
import { isMatchErrorCode } from 'utils'
import { ErrorModal } from 'App/components/ErrorModal'
import { TwoFactorChecker } from 'App/components/common'

import { TCreateBusinessAccountFormValues } from '../BusinessAccountCreateDialog'
import styles from '../BusinessAccountCreateDialog.module.scss'

type TCompanyEmailProps = {
  form: FormApi<TCreateBusinessAccountFormValues>
  handleStepForward: () => void
  handleStepBack: () => void
}

export const CompanyConfirmCode = ({
  form,
  handleStepBack,
  handleStepForward
}: TCompanyEmailProps) => {
  const { t } = useTranslation()

  const { email, expiredTokenDate } = form.getState().values

  const { error, isLoading, reset, mutate } = useMutation({
    mutationKey: ['validate-business-code'],
    mutationFn: businessAccountValidateCode,

    onError: noop,
    onSuccess: handleStepForward
  })

  const {
    mutate: resend,
    isLoading: isResendLoading,
    error: resendError
  } = useMutation({
    mutationKey: ['resend-code-to-business-email'],
    mutationFn: businessAccountSendCode,
    onError: noop,
    onSuccess: (data) => {
      form.change('expiredTokenDate', data)
    }
  })

  const codeInvalid = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_INVALID)
  const codeExhausted = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_EXHAUSTED)
  const limitExpired = isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)

  const shouldShowModalError =
    (!!error && !codeInvalid && !codeExhausted && !limitExpired) || !!resendError

  const handleConfirm = useCallback(
    (code: string) => {
      form.change('confirmationCode', code)
      mutate({ token: code, email })
    },
    [form, email, mutate]
  )

  const handleResendOldCode = useCallback(() => {
    form.change('confirmationCode', '')
    resend(email)
  }, [form, email, resend])

  return (
    <>
      <TwoFactorChecker
        processing={isLoading || isResendLoading}
        onConfirm={handleConfirm}
        onResendCode={handleResendOldCode}
        expiredTokenDate={expiredTokenDate}
        invalid={codeInvalid}
        onClearInvalid={reset}
        subDescription={<p className={styles.email}>{email}</p>}
        codeExhausted={codeExhausted}
        limitExpired={limitExpired}
        onClickBack={handleStepBack}
        submitLabel={t('modal.createBusinessAccount.codeConfirmationStep.submitButton')}
      />
      {/* TODO: error */}
      {shouldShowModalError && <ErrorModal error={resendError || error} />}
    </>
  )
}
