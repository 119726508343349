import { FC, useCallback } from 'react'

import { EModalComponents, TModalProps } from 'App/containers'
import { showModalAction, useAppDispatch } from 'store'

type TShowModalContainerChildrenProps = {
  showModalHandler: () => void
}

export type TShowModalContainerProps<ModalType extends EModalComponents> = {
  children: FC<TShowModalContainerChildrenProps>
  modalTitle: string
  modalType: ModalType
  modalProps?: TModalProps<ModalType>
}

export const ShowModalContainer = <ModalType extends EModalComponents>({
  children,
  modalTitle,
  modalType,
  modalProps
}: TShowModalContainerProps<ModalType>) => {
  const dispatch = useAppDispatch()

  const showModalHandler = useCallback(() => {
    dispatch(
      showModalAction<ModalType>({
        modalTitle,
        modalType,
        modalProps
      })
    )
  }, [dispatch, modalProps, modalTitle, modalType])

  return children({ showModalHandler })
}
