import { FC, useMemo } from 'react'
import { ChatTypeEnum, MeetingInviteStatusEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TMeetingInvite, useAppSelector, getSelectedChatRoomSelector } from 'store'
import { AvatarContainer } from 'App/containers'
import { InviteStatus } from 'App/components/Chat/ChatMessageMeeting'
import { AccountsList } from 'App/components'
import { ParticipantsStatuses } from 'App/components/Meetings/common'

import styles from './Participants.module.scss'

export type TParticipantsProps = {
  chatId: string

  invites?: TMeetingInvite[]
  isMessageOutgoing?: boolean
}

export const Participants: FC<TParticipantsProps> = (props) => {
  const { chatId, isMessageOutgoing, invites = [] } = props

  const chat = useAppSelector(getSelectedChatRoomSelector(chatId))

  const accounts = useMemo(
    () => invites.map(({ account, status }) => ({ ...account, status })),
    [invites]
  )

  const { t } = useTranslation()

  if (!chat) {
    return null
  }

  if (chat.type === ChatTypeEnum.DIALOGUE) {
    const invite = invites[0]

    return (
      <div className={cls(styles.guest, styles.wrapper)}>
        <AvatarContainer
          size={EAvatarSize.XS}
          userId={invite.account.id}
          displayUserName={invite.account.displayUserName}
          type={invite.account.type.name}
          firstName={invite.account.firstName}
          lastName={invite.account.lastName}
          showOnline={false}
        />
        <InviteStatus variant={isMessageOutgoing ? 'light' : 'default'} status={invite.status} />
      </div>
    )
  }

  return (
    <div className={cls(styles.organizer, styles.wrapper)}>
      <AccountsList<MeetingInviteStatusEnum>
        accounts={accounts}
        avatarSize={EAvatarSize.XS}
        getTitle={(number) => t('chat.message.meeting.participantsPopover.title', { number })}
        getGroupLabel={(groupLabel) => t(`enum.meetingInviteStatusEnum.${groupLabel}`)}
      />
      <ParticipantsStatuses variant={isMessageOutgoing ? 'light' : 'default'} invites={invites} />
    </div>
  )
}
