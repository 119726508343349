import styles from './FileDetailsItem.module.scss'

export type TFileDetailsItemProps = {
  label: string
  value: string
}

export const FileDetailsItem = ({ label, value }: TFileDetailsItemProps) => (
  <li className={styles.wrapper}>
    <span className={styles.label}>{label}</span>
    <span className={styles.value}>{value}</span>
  </li>
)
