import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window'
import { memo, useMemo } from 'react'

import { useAppSelector } from 'store'
import { AccountsListItem } from 'App/components/common/AccountsList/AccountsListItem'

import styles from './ChatMessageReactionItemPopoverContent.module.scss'

export type TChatMessageReactionItemPopoverContentProps = {
  reaction: string
  messageId: string
}

const LIST_CONFIG = {
  ITEM_HEIGHT: 46,
  HEIGHT: 300,
  WIDTH: 192
}

const getHeight = (length: number) => {
  if (length * LIST_CONFIG.ITEM_HEIGHT > LIST_CONFIG.HEIGHT) {
    return LIST_CONFIG.HEIGHT
  }

  return length * LIST_CONFIG.ITEM_HEIGHT
}

const RowView = ({ style, index, data }: ListChildComponentProps) => {
  const accountId = (data as string[])[index]
  const account = useAppSelector(
    (state) => state.chat.chatMessages.reactions.accountList[accountId]
  )

  return (
    <div style={style} className={styles.row}>
      <AccountsListItem
        key={account.id}
        className={styles.item}
        displayUserName={account.displayUserName}
        firstName={account.firstName}
        lastName={account.lastName}
        type={account.type?.name}
        userId={account.id}
        hasTooltip={true}
        hideIcon={true}
      />
    </div>
  )
}

const Row = memo(RowView, areEqual)

export const ChatMessageReactionItemPopoverContent = ({
  messageId,
  reaction
}: TChatMessageReactionItemPopoverContentProps) => {
  const accountIds = useAppSelector((state) =>
    Array.from(state.chat.chatMessages.reactions.messageList[messageId]?.list[reaction] ?? {})
  )

  const itemCount = accountIds.length
  const height = useMemo(() => getHeight(itemCount), [itemCount])

  return (
    <FixedSizeList
      itemSize={LIST_CONFIG.ITEM_HEIGHT}
      width={LIST_CONFIG.WIDTH}
      height={height}
      itemCount={itemCount}
      itemData={accountIds}
    >
      {Row}
    </FixedSizeList>
  )
}
