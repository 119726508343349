import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { enterKeyCodes } from 'globalConstants'
import { Button, Select, TOption } from 'App/components'
import { TUserProfileNewProfessionProps } from 'App/containers'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './UserProfileNewProfession.module.scss'

export const UserProfileNewProfession = ({
  availableProfessions,
  loadingProfessions,
  id,
  processing,
  deleteNewProfession,
  addProfession,
  accountType
}: TUserProfileNewProfessionProps) => {
  const [selectedProfession, selSelectedProfession] = useState<TOption | null>(null)

  const { t } = useTranslation()

  const onChange = (value: TOption | null) => {
    selSelectedProfession(value)
  }

  const onDelete = () => {
    deleteNewProfession({ id })
  }

  const onAdd = () => {
    selectedProfession &&
      addProfession({
        professionId: selectedProfession.value,
        tempProfessionId: id
      })
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (enterKeyCodes.includes(event.keyCode)) {
      onAdd()
    }
  }

  const topLabel = t('userProfile.professions.selectTopLabel', { context: accountType })

  return (
    <div className={styles.root}>
      <div className={styles.specWrapper}>
        <div className={styles.inputWrapper}>
          <Select
            topLabel={<span className={styles.inputLabel}>{topLabel}</span>}
            onChange={onChange}
            isMulti={false}
            value={selectedProfession}
            options={availableProfessions}
            onKeyDown={onKeyDown}
            isLoading={loadingProfessions}
            hideChevron={true}
            isDisabled={processing}
            endAdornment={
              <Button
                onClick={onAdd}
                onTouchEnd={onAdd}
                className={styles.addButton}
                disabled={!selectedProfession || processing}
                icon={<PlusIcon />}
                variant="text"
                loading={processing}
              >
                {t('userProfile.professions.addButton')}
              </Button>
            }
          />
        </div>
      </div>
      <button className={styles.removeBtn} onClick={onDelete}>
        {t('userProfile.professions.removeButton')}
        <CrossIcon className={styles.removeBtnIcon} />
      </button>
    </div>
  )
}
