import { call, put, takeLatest } from 'redux-saga/effects'
import { AccountTypeNames } from '@medentee/enums'

import { API, api } from 'services/api'
import { getCategoryListRequest } from 'store/categoryList'

import { hideModalAction } from '../modal'

import {
  DELETE_CATEGORY_REQUEST,
  deleteCategoryError,
  deleteCategorySuccess,
  SEND_CATEGORY_REQUEST,
  sendCategoryError,
  sendCategorySuccess,
  TDeleteCategoryRequest,
  TSendCategoryRequest
} from './category.actions'

function* createUpdateCategorySaga({
  payload: {
    categoryId,
    categoryName,
    type,
    selectedIds: selected,
    initialSelectedIds: initialSelected,
    onSuccess
  }
}: TSendCategoryRequest) {
  const deleteContactIds: string[] = []

  initialSelected?.forEach((id) => {
    if (!selected.includes(id)) {
      deleteContactIds.push(id)
    }
  }, [])

  try {
    if (categoryId) {
      yield call(api.patch, API.CATEGORY(categoryId), {
        name: categoryName,
        addContactIds: selected,
        deleteContactIds
      })
    } else {
      yield call(api.post, API.CATEGORIES, {
        name: categoryName,
        contactIds: selected
      })
    }
    yield put(sendCategorySuccess())
    yield put(hideModalAction())
    yield put(getCategoryListRequest({ accountType: type }))

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(sendCategoryError(e))
  }
}

function* deleteCategorySaga({ payload: { id, onSuccess } }: TDeleteCategoryRequest) {
  try {
    yield call(api.delete, API.CATEGORY(id))
    yield put(deleteCategorySuccess({ id }))
    yield put(hideModalAction())
    yield put(
      getCategoryListRequest({
        accountType: AccountTypeNames.PROFESSIONAL
      })
    )

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(deleteCategoryError(e))
  }
}

export function* categorySaga() {
  yield takeLatest(DELETE_CATEGORY_REQUEST, deleteCategorySaga)
  yield takeLatest(SEND_CATEGORY_REQUEST, createUpdateCategorySaga)
}
