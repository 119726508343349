import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'
import {
  useAppDispatch,
  DELETE_FILES_FROM_MEDCLOUD,
  useAppSelector,
  createProcessingSelector,
  TDeleteFilesFromMedCloudRequestPayload,
  deleteFilesFromMedCloudRequest
} from 'store'

type TFileDeletePermanentlyConfirmProps = Partial<TDeleteFilesFromMedCloudRequestPayload>

const processingSelector = createProcessingSelector([DELETE_FILES_FROM_MEDCLOUD])

export const FileDeletePermanentlyConfirm = ({ fileIds }: TFileDeletePermanentlyConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector)

  const handleDeleteFile = () => {
    dispatch(deleteFilesFromMedCloudRequest({ fileIds }))
  }

  return (
    <ConfirmationModal
      content={t('modal.deleteFilePermanentlyConfirm.content', {
        postProcess: 'interval',
        count: fileIds?.length ?? 0
      })}
      controls={
        <Button onClick={handleDeleteFile} loading={processing}>
          {t('modal.deleteFilePermanentlyConfirm.submitButton', {
            postProcess: 'interval',
            count: fileIds?.length ?? 0
          })}
        </Button>
      }
    />
  )
}
