import { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { hideExtendableCardAction, ECardSize } from 'store'
import { State } from 'redux/rootReducer'
import { FullscreenView } from 'App/components/common/ExtendableCard/FullscreenView'
import { useAdaptiveLayout } from 'App/hooks'

export type TFullscreenContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const FullscreenContainerView = ({
  type,
  size,
  slides,
  initialSlideId,
  hide,
  options
}: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>) => {
  const { isDesktop } = useAdaptiveLayout()
  const { pathname } = useLocation()

  useEffect(
    () => () => {
      hide()
    },
    [pathname, hide]
  )

  if (!type || !initialSlideId || (size !== ECardSize.FULLSCREEN && isDesktop)) {
    return null
  }

  return (
    <FullscreenView
      slides={slides}
      initialSlideId={initialSlideId}
      type={type}
      hide={hide}
      options={options}
    />
  )
}

const mapStateToProps = (state: State) => ({
  type: state.extendableCard.type,
  initialSlideId: state.extendableCard.initialSlideId,
  slides: state.extendableCard.slides,
  size: state.extendableCard.size,
  options: state.extendableCard.options
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hide: hideExtendableCardAction
    },
    dispatch
  )

export const FullscreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FullscreenContainerView)
