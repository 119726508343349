import { useLayoutEffect, useRef, useState } from 'react'
import cls from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'

import { Tooltip } from 'App/components'
import { NO_SPACE_FOR_TRUNCATED_TEXT } from 'globalConstants/errors'

import styles from './FileNameTruncatedText.module.scss'

export type TFileNameTruncatedTextProps = {
  extension: string
  fileName: string

  className?: string
  twoLine?: boolean
  disabled?: boolean
}

export const FileNameTruncatedText = ({
  extension = '',
  fileName = '',
  className = '',
  twoLine = false,
  disabled = false
}: TFileNameTruncatedTextProps) => {
  const content = `${fileName}${extension}`
  const fakeElRef = useRef<HTMLSpanElement>(null)
  const rootRef = useRef<HTMLSpanElement>(null)
  const [visibleContent, setVisibleContent] = useState(content)

  useLayoutEffect(() => {
    const handler = () => {
      if (fakeElRef.current) {
        fakeElRef.current.innerHTML = content

        const isOverflowed = () => {
          if (fakeElRef.current) {
            const el = fakeElRef.current

            if (el.offsetHeight === 0 || el.offsetWidth === 0) {
              return false
            }

            return el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight
          }

          return false
        }

        if (isOverflowed()) {
          let i = 2
          const endingLength = extension.length + 3

          while (isOverflowed()) {
            fakeElRef.current.innerHTML = `${content.slice(
              0,
              -(endingLength + i)
            )}...${content.slice(-endingLength)}`

            if (i + endingLength > content.length - 1) {
              console.error(NO_SPACE_FOR_TRUNCATED_TEXT)
              break
            }

            i++
          }

          setVisibleContent(fakeElRef.current.innerHTML)
        } else {
          setVisibleContent(content)
        }
      }
    }
    const element = rootRef.current
    const resizeObserver = new ResizeObserver(handler)

    handler()
    element && resizeObserver.observe(element)

    return () => {
      element && resizeObserver.unobserve(element)
    }
  }, [content, extension.length])

  return (
    <span ref={rootRef} className={styles.root}>
      <Tooltip title={visibleContent !== content ? content : undefined}>
        <span
          ref={fakeElRef}
          className={cls(
            styles.text,
            className,
            twoLine && styles.textTwoLine,
            disabled && styles.disabled
          )}
        />
      </Tooltip>
    </span>
  )
}
