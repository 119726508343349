import { call, put, takeLatest } from 'redux-saga/effects'

import { API, api, APIResultsResponse } from 'services/api'
import { normalizeList } from 'store/utils'
import { ICommunityDTO } from 'interfaces'
import { handleError } from 'api/utils'

import {
  RECEIVE_BROADCAST_CREATED,
  RECEIVE_BROADCAST_DELETED,
  RECEIVE_UPDATE_BROADCAST_STATUS,
  receiveBroadcastDeleted
} from '../broadcasts'

import * as actions from './communities.actions'
import * as actionTypes from './communities.actionTypes'

function* getCommunitiesSaga({ payload }: ReturnType<typeof actions.getCommunitiesRequest>) {
  try {
    const {
      data: { results, total }
    }: APIResultsResponse<ICommunityDTO[]> = yield call(api.get, API.COMMUNITIES)

    const normalizeCommunities = normalizeList(results)

    yield put(
      actions.getCommunitiesSuccess({
        ...normalizeCommunities,
        total
      })
    )

    if (payload?.onSuccess) {
      yield call(payload.onSuccess)
    }
  } catch (e) {
    handleError(e)
    yield put(actions.getCommunitiesError(e))
  }
}

function* refreshCommunitiesSaga({ payload }: ReturnType<typeof receiveBroadcastDeleted>) {
  if (payload.communityId) {
    yield put(actions.getCommunitiesRequest())
  }
}

export function* communitiesSaga() {
  yield takeLatest(actionTypes.GET_COMMUNITIES_REQUEST, getCommunitiesSaga)
  yield takeLatest(
    [RECEIVE_UPDATE_BROADCAST_STATUS, RECEIVE_BROADCAST_CREATED, RECEIVE_BROADCAST_DELETED],
    refreshCommunitiesSaga
  )
}
