import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ECaseListSearchBy } from 'enums'
import {
  getCaseListRequest,
  resetCaseListAction,
  setCaseListPaginationAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { CaseItemListContainer } from 'App/containers'
import { Pagination } from 'App/components'
import { useRefreshNotification } from 'App/hooks'

import styles from './CaseList.module.scss'

export type TCaseSearchOption = {
  value: ECaseListSearchBy
  label: string
}

export const CaseList = () => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const page = useAppSelector((state) => state.cases.filters.page)
  const showBy = useAppSelector((state) => state.cases.filters.showBy)
  const total = useAppSelector((state) => state.cases.filters.total)
  const classifications = useAppSelector((state) => state.cases.filters.classifications)
  const roles = useAppSelector((state) => state.cases.filters.roles)
  const statuses = useAppSelector((state) => state.cases.filters.statuses)
  const search = useAppSelector((state) => state.cases.filters.search)
  const searchBy = useAppSelector((state) => state.cases.filters.searchBy)

  const { t } = useTranslation()

  const getCaseList = useCallback(() => {
    dispatch(getCaseListRequest())
  }, [dispatch])

  const { removeToast } = useRefreshNotification({
    message: t('cases.list.newEventsToast'),
    onRefresh: getCaseList,
    notificationsFilter: (payload: { producerId: string }) => payload.producerId !== accountId
  })

  useEffect(() => {
    getCaseList()
    removeToast()
  }, [getCaseList, classifications, roles, statuses, removeToast, search, searchBy])

  const handlePaginationChange = useCallback(
    (newPage: number, newShowBy?: number) => {
      removeToast()
      dispatch(
        setCaseListPaginationAction({
          page: newPage,
          showBy: newShowBy
        })
      )
    },
    [dispatch, removeToast]
  )

  useEffect(
    () => () => {
      dispatch(resetCaseListAction())
    },
    [dispatch]
  )

  return (
    <>
      <CaseItemListContainer />

      <Pagination
        total={total}
        pageSize={showBy}
        current={page}
        onChange={handlePaginationChange}
        wrapperClassName={styles.pagination}
      />
    </>
  )
}
