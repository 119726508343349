import cls from 'classnames'
import { FilePermissionsIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { PERMISSION_FULL_VIEW, PERMISSION_NAME_MAP } from 'globalConstants'
import { Tooltip } from 'App/components'
import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg'
import { ReactComponent as EyeCrossIcon } from 'assets/icons/EyeCross.svg'
import { getMapComponent } from 'utils'

import styles from './PermissionIcons.module.scss'

/** All enabled buttons on ui */
const ENABLED_PERMISSION_ICON = new Map().set(FilePermissionsIdsEnum.OPEN, EyeIcon)

/** All disabled buttons on ui */
const DISABLED_PERMISSION_ICON = new Map().set(FilePermissionsIdsEnum.OPEN, EyeCrossIcon)

export type TChangePermissionProps = {
  permissionId: FilePermissionsIdsEnum
  enabled: boolean
}

export type TPermissionIconsProps = {
  permissions: FilePermissionsIdsEnum[]
  id: string
  isYourFile?: boolean
  autoPermissionsMode?: boolean
  changePermission?: (props: TChangePermissionProps) => void
}

export const PermissionIcons = ({
  permissions,
  id,
  autoPermissionsMode,
  changePermission,
  isYourFile
}: TPermissionIconsProps) => {
  const { t } = useTranslation()

  const permissionIcons = useMemo(() => {
    const permissionIconsList: JSX.Element[] = []

    PERMISSION_FULL_VIEW.forEach((permissionId) => {
      const enabled: boolean = permissions.includes(permissionId)

      const PermissionIcon = enabled
        ? ENABLED_PERMISSION_ICON.get(permissionId)
        : DISABLED_PERMISSION_ICON.get(permissionId)

      const permissionIconHandler = () =>
        changePermission &&
        (!autoPermissionsMode || isYourFile) &&
        changePermission({ permissionId, enabled: !enabled })

      const getTooltip = () => {
        if (autoPermissionsMode && !isYourFile) {
          return !enabled ? t('files.discardedPermission') : t('files.automaticPermission')
        }

        const permissionName = getMapComponent(PERMISSION_NAME_MAP, permissionId)

        if (!permissionName) {
          return
        }

        return t(`enum.filePermissionsIdsEnum.${permissionName}`)
      }

      if (PermissionIcon) {
        permissionIconsList.push(
          <Tooltip
            title={getTooltip()}
            overlayClassName={styles.tooltip}
            key={`${id}-${permissionId}`}
          >
            <PermissionIcon
              key={permissionId}
              onClick={permissionIconHandler}
              className={cls({
                [styles.icon]: true,
                [styles.iconEnabled]: enabled,
                [styles.iconInactive]: autoPermissionsMode && !isYourFile
              })}
            />
          </Tooltip>
        )
      }
    })

    return permissionIconsList
  }, [autoPermissionsMode, changePermission, id, isYourFile, permissions, t])

  return <button className={styles.btn}>{permissionIcons}</button>
}
