import {
  UnityHubCreateChannelsMainStep,
  TUnityHubCreateChannelsMainStepProps
} from 'App/components/UnityHub'

import { useRequest, TUseRequest } from './useRequest'

export type TCreateVoiceChannelDialogMainStepProps = Pick<
  TUnityHubCreateChannelsMainStepProps,
  | 'isEdit'
  | 'loading'
  | 'name'
  | 'setName'
  | 'onOpenMembers'
  | 'channelMembers'
  | 'channelDepartments'
  | 'dirty'
  | 'isPublic'
  | 'setIsPublic'
  | 'initialValues'
> &
  Pick<TUseRequest, 'organizationId' | 'channelId'>

export const CreateVoiceChannelDialogMainStep = ({
  channelMembers,
  channelDepartments,
  organizationId,
  channelId,
  name,
  isEdit,
  loading,
  dirty,
  isPublic,
  setName,
  setIsPublic,
  onOpenMembers,
  initialValues
}: TCreateVoiceChannelDialogMainStepProps) => {
  const { error, isFieldError, processing, handleFormSubmit } = useRequest({
    organizationId,
    channelId,
    channelMembers,
    channelDepartments,
    isEdit
  })

  return (
    <UnityHubCreateChannelsMainStep
      dirty={dirty}
      isEdit={isEdit}
      name={name}
      channelMembers={channelMembers}
      channelDepartments={channelDepartments}
      setName={setName}
      onFormSubmit={handleFormSubmit}
      onOpenMembers={onOpenMembers}
      loading={loading}
      isFieldError={isFieldError}
      error={error}
      isPublic={isPublic}
      initialValues={initialValues}
      setIsPublic={setIsPublic}
      processing={processing}
    />
  )
}
