import cls from 'classnames'

import { formatBytes } from 'utils'
import { EIconSize } from 'enums'
import { MAX_SHOWCASE_FILES_AMOUNT, MAX_SHOWCASE_FILES_SIZE } from 'globalConstants'
import { IconButton } from 'App/components'
import { ReactComponent as CameraPlusIcon } from 'assets/icons/CameraPlus.svg'

import styles from './FilesLimit.module.scss'

export type TFilesLimitProps = {
  amount: number
  size: number
  onAttachFiles: () => void
}

export const FilesLimit = ({ amount, size, onAttachFiles }: TFilesLimitProps) => {
  const error = size > MAX_SHOWCASE_FILES_SIZE
  const disabled = amount >= MAX_SHOWCASE_FILES_AMOUNT
  const initial = amount === 0

  return (
    <div className={styles.root}>
      <div className={cls(styles.size, { [styles.error]: error, [styles.initial]: initial })}>
        {formatBytes(size)}/{formatBytes(MAX_SHOWCASE_FILES_SIZE)}
      </div>
      <div className={styles.amount}>
        <IconButton
          iconComponent={<CameraPlusIcon className={cls({ [styles.disabled]: disabled })} />}
          iconSize={EIconSize.MD}
          disabled={disabled}
          onClick={onAttachFiles}
        />
        {amount}/{MAX_SHOWCASE_FILES_AMOUNT}
      </div>
    </div>
  )
}
