import { takeLatest, call, put, select } from 'redux-saga/effects'
import { ErrorCodesEnum } from '@medentee/enums'

import { isMatchErrorCode, QueryBuilder } from 'utils'
import { API, api, APIResultsResponse } from 'services/api'
import { EModalComponents } from 'App/containers'
import { showModalAction, hideModalAction, getCaseCountRequest } from 'store'
import { State } from 'redux/rootReducer'
import { Pagination } from 'types'
import { ECaseAccountType, ETargetSearchedEntity } from 'enums'
import { ICaseEntity } from 'interfaces'
import { handleError } from 'api/utils'

import {
  TCaseMemberInviteApproveRequest,
  TCaseMemberInviteRejectRequest,
  TGetCasesInviteListRequest
} from './casesInvite.types'
import { casesInviteListNormalize } from './casesInvite.normalization'
import {
  caseMemberInviteApproveError,
  caseMemberInviteApproveSuccess,
  caseMemberInviteRejectError,
  caseMemberInviteRejectSuccess,
  CASE_MEMBER_INVITE_APPROVE_REQUEST,
  CASE_MEMBER_INVITE_REJECT_REQUEST,
  getCasesInviteListRequest,
  GET_CASES_INVITE_LIST_REQUEST,
  setCasesInviteList,
  setCasesInviteListIds,
  setCasesInviteListLoading,
  setCasesInviteListPagination
} from './casesInvite.actions'

function* getCasesInviteListSaga({ payload }: TGetCasesInviteListRequest) {
  try {
    yield put(setCasesInviteListLoading({ isLoading: true }))

    const currentPagination: Pagination = yield select(
      (state: State) => state.casesInvite.pagination
    )
    const showBy = payload?.showBy ?? currentPagination.showBy
    const current = payload?.current ?? currentPagination.current

    const queryBuilderUrl = new QueryBuilder(API.CASES_INVITE_LIST)
      .page(current)
      .showBy(showBy)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<ICaseEntity[]> = yield call(api.get, queryBuilderUrl)
    const { casesInviteList, ids } = casesInviteListNormalize(results)

    yield put(setCasesInviteListIds({ ids }))
    yield put(setCasesInviteList({ casesInviteList }))
    yield put(setCasesInviteListPagination({ showBy, current, total }))
    yield put(
      getCaseCountRequest({
        targetSearchedEntity: ETargetSearchedEntity.CASE_INVITE,
        caseRole: ECaseAccountType.MEMBER.toUpperCase() as Uppercase<ECaseAccountType>
      })
    )
  } catch (e) {
    console.error(e)
    handleError(e)
  } finally {
    yield put(setCasesInviteListLoading({ isLoading: false }))
  }
}

function* caseMemberInviteReject({
  payload: { inviteId, reason, processingId }
}: TCaseMemberInviteRejectRequest) {
  try {
    yield call(api.patch, API.CASES_INVITES(inviteId), {
      action: 'reject',
      rejectReason: reason
    })

    const { current, showBy }: Pagination = yield select(
      (state: State) => state.casesInvite.pagination
    )

    yield put(hideModalAction())
    yield put(caseMemberInviteRejectSuccess({ processingId }))

    yield put(getCasesInviteListRequest({ current, showBy }))
  } catch (e) {
    if (
      isMatchErrorCode(e, ErrorCodesEnum.CASE_INVITE_LOCKED) ||
      isMatchErrorCode(e, ErrorCodesEnum.CASE_INVITE_NOT_FOUND)
    ) {
      handleError(e)
      yield put(hideModalAction())
      yield put(getCasesInviteListRequest())
    } else {
      yield put(caseMemberInviteRejectError(e))
    }
  }
}

function* caseMemberInviteApprove({
  payload: { inviteId, title, processingId }
}: TCaseMemberInviteApproveRequest) {
  try {
    const {
      data: { caseId }
    } = yield call(api.patch, API.CASES_INVITES(inviteId), {
      action: 'approve'
    })
    const { current, showBy }: Pagination = yield select(
      (state: State) => state.casesInvite.pagination
    )

    yield put(
      showModalAction({
        // TODO: This is very strange behavior. Here you need to figure it out, if you do that.
        // modalType: EModalComponents.CASE_MEMBER_INVITE_APPROVE_DIALOG
        // Then for some reason the webpack builder crashes
        modalType:
          'CASE_MEMBER_INVITE_APPROVE_DIALOG' as EModalComponents.CASE_MEMBER_INVITE_APPROVE_DIALOG,
        modalTitle: null,
        modalProps: {
          caseId,
          title
        }
      })
    )
    yield put(caseMemberInviteApproveSuccess({ processingId }))
    yield put(getCasesInviteListRequest({ current, showBy }))
  } catch (e) {
    yield handleError(e)
    yield put(getCasesInviteListRequest())
    yield put(caseMemberInviteApproveError(e))
  }
}

export function* casesInviteListSaga() {
  yield takeLatest(CASE_MEMBER_INVITE_APPROVE_REQUEST, caseMemberInviteApprove)
  yield takeLatest(CASE_MEMBER_INVITE_REJECT_REQUEST, caseMemberInviteReject)
  yield takeLatest(GET_CASES_INVITE_LIST_REQUEST, getCasesInviteListSaga)
}
