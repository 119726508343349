import { END, EventChannel, eventChannel } from 'redux-saga'
import {
  call,
  cancel,
  fork,
  put,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import axios, { AxiosResponse } from 'axios'
import { FileHistorySourcesEnum, ErrorCodesEnum } from '@medentee/enums'
import { differenceInMilliseconds } from 'date-fns'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import {
  REFRESH_UPLOADING_SESSION_DELAY,
  toastDefaultOptions,
  WHITELIST_FILES
} from 'globalConstants'
import { IFilesEntity, IPreSignUrl, IUploadStorageDTO } from 'interfaces'
import { EFileUploadStatus, EUploadDefaults } from 'enums'
import {
  parseError,
  getFileName,
  QueryBuilder,
  getExtension,
  checkLocalFileExist,
  formatBytes,
  CloudFilesQueryBuilder,
  isMatchErrorCode
} from 'utils'
import {
  caseMedCloudFileNormalize,
  caseUploadFileSuccess,
  clearErrorSaga,
  FILE_LIST_DEFAULT_PAGINATION,
  getAttachFilesAlreadyFilesSelector,
  getEmptySpaceSelector,
  getFileListRequest,
  getMedCloudCapacityRequest,
  hideModalAction,
  requestAllFiles,
  TAttachMedcloudFiles
} from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { ESocketNameSpaces, sockets } from 'services/webSocket'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types/pagination'
import { handleError } from 'api/utils'
import { SYSTEM_ABORTED_UPLOAD } from 'globalConstants/errors'

import { fileNormalize } from '../files/myFiles/files.normalization'
import { State } from '../../redux/rootReducer'
import i18n from '../../i18n'

import * as actionTypes from './medCloud.actionTypes'
import {
  TCreateUploadFunc,
  TCreateUploadOptions,
  TFilesToAttach,
  TFileUpload,
  TGetCaseMedCloudFilesSuccessPayload,
  TMedCloudFilters,
  TMedCloudFileList,
  TMedCloudUpload,
  TAttachMedcloudFile,
  TAttachMedcloudFilters
} from './medCloud.types'
import {
  attachSelectedCaseMedCloudFilesError,
  attachSelectedCaseMedCloudFilesRequest,
  attachSelectedCaseMedCloudFilesSuccess,
  attachSelectedChatMedCloudFilesError,
  attachSelectedChatMedCloudFilesRequest,
  attachSelectedChatMedCloudFilesSuccess,
  cancelUploadFile,
  copyFileToMedcloudError,
  copyFileToMedcloudRequest,
  copyFileToMedcloudSuccess,
  getCaseMedCloudFilesError,
  getCaseMedCloudFilesRequest,
  getCaseMedCloudFilesSuccess,
  getChatMedCloudFilesError,
  getChatMedCloudFilesRequest,
  getChatMedCloudFilesSuccess,
  medCloudAbortUpload,
  medCloudFileInvalidate,
  moveFileToProgress,
  removeFileFromForUploadList,
  removeFileFromWidgetAction,
  setFakeFileInWidget,
  setFilesToAttachAction,
  setUploadingStartDate,
  setUploadFilePercent,
  setUploadFilePreSignKey,
  setUploadFileStatus,
  startFakeUploadAction,
  getCaseLinkedFilesRequest,
  getCaseLinkedFilesError,
  getCaseLinkedFilesSuccess,
  attachCaseLinkedFilesRequest,
  attachCaseLinkedFilesError,
  attachCaseLinkedFilesSuccess,
  removeFileFromProgress,
  attachChatMedCloudFileRequest,
  attachChatMedCloudFileSuccess,
  attachChatMedCloudFileError,
  setNewNameUploadFileInWidget
} from './medCloud.actions'

function* watchOnProgress(chan: EventChannel<any>) {
  while (true) {
    const { id, percent } = yield take(chan)
    const oldPercent: number = yield select(
      (state: State) => state.medCloud.widget.list[id].uploadPercentage
    )

    if (percent > oldPercent) {
      yield call(refreshUploadingSession, id)
      yield put(setUploadFilePercent({ id, percent }))
    }
  }
}

function* uploadSource(func: TCreateUploadFunc, options: TCreateUploadOptions) {
  const [uploadPromise, chan] = func(options)
  yield fork(watchOnProgress, chan)

  const response: Promise<AxiosResponse<unknown>> = yield call(() => uploadPromise)

  return response
}

const createUploadFunc: TCreateUploadFunc = (options) => {
  const { cancelToken, data, url, id } = options
  let emit: (input: any) => void

  const chan = eventChannel((emitter) => {
    emit = emitter
    return () => {
      // it's necessarily. event channel should
      // return unsubscribe function.
      // In our case cancel request is empty function
    }
  })

  const uploadPromise = api.post(url, data, {
    withCredentials: false,
    cancelToken,
    onUploadProgress: ({ loaded, total }: ProgressEvent) => {
      const percent = Math.floor((loaded / total) * 100)

      if (percent <= 99) {
        emit({ percent, id })
      } else {
        emit(END)
      }
    }
  })

  return [uploadPromise, chan]
}

export function* showFileErrorToast() {
  const show: boolean = yield select(
    (state: State) =>
      state.modal.modalType !== EModalComponents.CASE_ATTACH_FILE_DIALOG &&
      state.modal.modalType !== EModalComponents.CHAT_ATTACH_FILE_DIALOG &&
      !state.medCloud.widget.show
  )

  if (show) {
    yield toast.error(i18n.t('common.toast.fileUploading'), toastDefaultOptions)
  }
}

export function* fileValidation({
  id,
  name,
  size,
  maxFileSize
}: Pick<TFileUpload, 'id' | 'name' | 'size' | 'maxFileSize'>) {
  const emptySpace: number = yield select(getEmptySpaceSelector)
  const fileName = getFileName(name)

  switch (true) {
    case maxFileSize && size > maxFileSize: {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(removeFileFromForUploadList({ fakeId: id }))
      yield put(
        medCloudFileInvalidate({
          id,
          errorType: ErrorCodesEnum.FILE_ALLOWED_SIZE_EXCEEDED,
          message: i18n.t('serverError.FILE_ALLOWED_SIZE_EXCEEDED', {
            ns: 'errors',
            max: formatBytes(maxFileSize as number)
          })
        })
      )

      return false
    }
    case size > emptySpace: {
      const formattedBytes = emptySpace <= 0 ? '0 MB' : formatBytes(emptySpace)

      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(removeFileFromForUploadList({ fakeId: id }))
      yield put(
        medCloudFileInvalidate({
          id,
          errorType: ErrorCodesEnum.STORAGE_CAPACITY_NOT_ENOUGH,
          message: i18n.t('serverError.STORAGE_CAPACITY_NOT_ENOUGH', {
            ns: 'errors',
            max: formattedBytes
          })
        })
      )
      return false
    }

    case !WHITELIST_FILES.includes(getExtension(name, true).toLowerCase()): {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(removeFileFromForUploadList({ fakeId: id }))
      yield put(
        medCloudFileInvalidate({
          id,
          errorType: ErrorCodesEnum.FILE_EXTENSION_NOT_SUPPORTED,
          message: i18n.t('serverError.FILE_EXTENSION_NOT_SUPPORTED', {
            ns: 'errors'
          })
        })
      )
      return false
    }

    case fileName.length <= EUploadDefaults.MIN_FILE_NAME_LENGTH:
    case fileName.trim().length <= EUploadDefaults.MIN_FILE_NAME_LENGTH: {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.PENDING, id }))
      yield put(removeFileFromForUploadList({ fakeId: id }))
      yield put(
        medCloudFileInvalidate({
          id,
          errorType: ErrorCodesEnum.FILE_NAME_IS_EMPTY,
          message: i18n.t('serverError.FILE_NAME_IS_EMPTY', {
            ns: 'errors'
          })
        })
      )
      return false
    }

    default: {
      return true
    }
  }
}

function* startFileUpload({
  id,
  name,
  file,
  uploadedFrom,
  cancelTokenSource,
  onAfterUploadFile,
  removeOnCancel,
  caseId,
  chatId,
  maxFileSize
}: TFileUpload) {
  const formData = new FormData()

  try {
    if (!file) {
      return
    }

    const fileValid: boolean = yield call(fileValidation, {
      id,
      name,
      size: file.size,
      maxFileSize
    })

    if (!fileValid) {
      yield put(removeFileFromProgress({ id }))
      return
    }

    yield put(setUploadFileStatus({ status: EFileUploadStatus.PROGRESS, id }))

    const originalEntityId = chatId || caseId

    yield call(checkLocalFileExist, file)

    // Get S3 URL for UPLOAD
    const {
      data: { fields, url: preSignURL }
    }: APIData<IPreSignUrl> = yield call(
      api.post,
      API.UPLOAD_PRE_SIGN_URL,
      {
        fileName: name,
        uploadedFrom,
        originalEntityId,
        fileSize: file.size,
        socketSessionId: sockets[ESocketNameSpaces.SYSTEM]?.id ?? ''
      },
      { cancelToken: cancelTokenSource.token }
    )

    yield put(setUploadingStartDate({ date: new Date() }))
    yield put(setUploadFilePreSignKey({ id, key: fields.key }))

    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key])
    })

    // Actual file has to be appended last.
    formData.append('file', file)

    yield call(uploadSource, createUploadFunc, {
      url: preSignURL,
      data: formData,
      id,
      cancelToken: cancelTokenSource.token
    })

    const confirmFormData = new FormData()

    confirmFormData.append('key', fields.key)

    const { data: uploadResponse }: APIData<IUploadStorageDTO> = yield call(
      api.post,
      API.UPLOAD_CONFIRM,
      confirmFormData,
      { cancelToken: cancelTokenSource.token }
    )

    yield put(setUploadFilePercent({ id, percent: 100 }))
    yield put(setUploadFileStatus({ status: EFileUploadStatus.SUCCESS, id }))

    const uploadedFileName = `${uploadResponse.fileName}${uploadResponse.extension}`

    if (name !== uploadedFileName) {
      yield put(
        setNewNameUploadFileInWidget({
          id,
          name: uploadedFileName
        })
      )
    }

    const filesToAttach: TFilesToAttach[] = yield select(
      (state: State) => state.medCloud.filesToAttach
    )

    // attach files right after upload
    // TODO: Need decomposition attachFiles to new saga
    for (const attachFile of filesToAttach) {
      const isFileUploadSame: boolean = attachFile.id === id
      const isChatIdSame: boolean = !!attachFile.chatId && attachFile.chatId === chatId
      const isCaseIdSame: boolean = !!attachFile.caseId && attachFile.caseId === caseId

      if (isFileUploadSame && (isChatIdSame || isCaseIdSame)) {
        const attachUrl = new QueryBuilder(
          isChatIdSame ? API.CHAT_ATTACH_FILES(chatId || '') : API.CASE_FILE(caseId || '')
        )
        const source = isChatIdSame
          ? FileHistorySourcesEnum.P2P_CLOUD
          : FileHistorySourcesEnum.CASE_CLOUD

        if (isCaseIdSame) {
          attachUrl.custom('source', source).custom('originalEntityId', originalEntityId)
        }

        yield call(api.post, attachUrl.build(), {
          fileIds: [uploadResponse.id],
          source: isChatIdSame ? source : undefined,
          originalEntityId: isChatIdSame ? originalEntityId : undefined
        })

        if (isCaseIdSame) {
          yield requestAllFiles(attachFile.caseId || '')
        }

        yield put(
          setFilesToAttachAction({
            filesToAttach: filesToAttach.filter((item) => item.id !== id)
          })
        )

        break
      }
    }

    yield put(removeFileFromForUploadList({ fakeId: id, id: uploadResponse.id }))
    yield put(getMedCloudCapacityRequest())

    if (onAfterUploadFile) {
      yield onAfterUploadFile(uploadResponse)
    }

    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )

    if (!caseId && !chatId) {
      yield put(
        getFileListRequest({
          ownerId: accountId
        })
      )
    }
  } catch (e) {
    yield put(removeFileFromForUploadList({ fakeId: id }))
    const isCancel = axios.isCancel(e)

    if (isCancel) {
      if (removeOnCancel) {
        yield put(removeFileFromWidgetAction({ fileId: id }))
        return
      }

      yield put(setUploadFileStatus({ status: EFileUploadStatus.CANCELLED, id }))
    } else {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(medCloudFileInvalidate({ id, message: parseError(e) }))
    }

    if (!isCancel) {
      yield showFileErrorToast()
    }
  }
}

function* uploadQueueFlow(): unknown {
  try {
    if (yield select((state: State) => !state.medCloud.upload.fileQueue.length)) {
      yield cancel()
      return
    }

    for (const id of yield select((state: State) => state.medCloud.upload.fileQueue)) {
      const list: TMedCloudFileList = yield select((state: State) => state.medCloud.widget.list)

      if (!list[id]) {
        break
      }

      yield put(moveFileToProgress({ id }))
      yield call(startFileUpload, list[id])
    }

    const fileQueueLength = yield select((state: State) => state.medCloud.upload.fileQueue.length)
    const filesInProgressLength: boolean = yield select(
      (state: State) => state.medCloud.upload.filesInProgress.length
    )

    if (fileQueueLength && !filesInProgressLength) {
      yield call(uploadQueueFlow)
    }
  } catch (e) {
    throw e
  }
}

function* upload() {
  try {
    const { fileQueue, filesInProgress }: TMedCloudUpload = yield select(
      (state: State) => state.medCloud.upload
    )

    if (!fileQueue.length || filesInProgress.length) {
      yield cancel()
    }

    yield spawn(uploadQueueFlow)
  } catch (e) {
    yield handleError(e)
  }
}

function* getCaseMedCloudFilesSaga({
  payload: { caseId, filters }
}: ReturnType<typeof getCaseMedCloudFilesRequest>) {
  const { search = '', page } = filters || {}

  try {
    const { search: currentSearch }: TMedCloudFilters = yield select(
      (state: State) => state.medCloud.filters
    )
    const { ids: attachIds, list: attachList }: TAttachMedcloudFiles = yield select(
      (state: State) => state.medCloud.attachFiles
    )

    const url = new QueryBuilder(API.CASES_FILES_NON_ATTACHED(caseId))
      .searchQuery(search)
      .showBy(FILE_LIST_DEFAULT_PAGINATION.showBy)
      .page(page)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<TAttachMedcloudFile[]> = yield call(api.get, url)

    const { ids, list } = caseMedCloudFileNormalize(results)

    const data: TGetCaseMedCloudFilesSuccessPayload =
      search !== currentSearch && page === 0
        ? {
            ids,
            list,
            total,
            filters: {
              search,
              page,
              total
            }
          }
        : {
            ids: [...attachIds, ...ids],
            list: { ...attachList, ...list },
            total,
            filters: {
              search,
              page,
              total
            }
          }

    yield put(getCaseMedCloudFilesSuccess(data))
  } catch (e) {
    yield put(getCaseMedCloudFilesError(e))
  }
}
function* getCaseLinkedFilesSaga({
  payload: { caseId, linkedCaseId, onError }
}: ReturnType<typeof getCaseLinkedFilesRequest>) {
  try {
    const search: string = yield select((state: State) => state.medCloud.attachFiles.search)

    const { directions = [], page = 0 }: TAttachMedcloudFilters = yield select(
      (state: State) => state.medCloud.attachFiles.filters
    )

    const url = new QueryBuilder(API.CASE_LINKED_FILES(caseId, linkedCaseId))
      .multiSearch('directions', directions)
      .searchQuery(search)
      .showBy(FILE_LIST_DEFAULT_PAGINATION.showBy)
      .page(page)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<TAttachMedcloudFile[]> = yield call(api.get, url)

    yield put(
      getCaseLinkedFilesSuccess({
        total,
        ...caseMedCloudFileNormalize(results)
      })
    )
  } catch (e) {
    yield call(onError)
    yield put(getCaseLinkedFilesError(e))
    yield call(clearErrorSaga, actionTypes.GET_CASE_LINKED_FILES)
  }
}

function* attachCaseLinkedFilesSaga({
  payload: { caseId, onError }
}: ReturnType<typeof attachCaseLinkedFilesRequest>) {
  try {
    const { list, selectedFiles }: TAttachMedcloudFiles = yield select(
      (state: State) => state.medCloud.attachFiles
    )

    const accountId: string | undefined = yield select(
      (state: State) => state.global.accountData?.id
    )

    const url = API.ATTACH_CASE_LINKED_FILES(caseId)

    const onUpload = function* (fileName: string, fileId?: string) {
      yield call(api.post, url, { fileName, fileId })

      yield put(attachCaseLinkedFilesSuccess())
      yield requestAllFiles(caseId)
    }

    for (const fileId of selectedFiles) {
      const { fileName, owner, extension } = list[fileId] || {}
      const isFileHolder = accountId === owner?.id

      if (fileName && isFileHolder) {
        yield call(onUpload, fileName, fileId)
      }

      if (!isFileHolder) {
        yield put(
          startFakeUploadAction({
            fileId,
            fileName,
            fileType: extension,
            onUpload
          })
        )
      }
    }

    yield put(hideModalAction())
  } catch (e) {
    const hasShowError = isMatchErrorCode(e, [
      ErrorCodesEnum.CASE_NOT_FOUND,
      ErrorCodesEnum.IS_NOT_ACTIVE_CASE_MEMBER
    ])

    if (hasShowError) {
      yield call(onError)
      yield put(attachCaseLinkedFilesError(e))
      yield call(clearErrorSaga, actionTypes.ATTACH_CASE_LINKED_FILES)
    }

    if (!hasShowError) {
      yield put(hideModalAction())
    }
  }
}

function* attachSelectedCaseMedCloudFilesSaga({
  payload: { caseId }
}: ReturnType<typeof attachSelectedCaseMedCloudFilesRequest>) {
  try {
    let fileIds: string[] = yield select((state: State) => state.medCloud.attachFiles.selectedFiles)
    const list: TMedCloudFileList = yield select((state: State) => state.medCloud.widget.list)

    fileIds = fileIds.filter((id) => {
      if (!list[id]) {
        return true
      }

      const isSelected = list[id].selected
      const isCaseIdSame = list[id].caseId === caseId
      const isUploading = list[id].uploadStatus !== EFileUploadStatus.SUCCESS

      return !(isSelected && isCaseIdSame && isUploading)
    })

    if (fileIds.length) {
      const url = new QueryBuilder(API.CASE_FILE(caseId))
        .custom('source', FileHistorySourcesEnum.CASE_CLOUD)
        .custom('originalEntityId', caseId)
        .build()

      yield call(api.post, url, {
        fileIds
      })

      yield requestAllFiles(caseId)
    }

    yield put(caseUploadFileSuccess())
    yield put(hideModalAction())
    yield put(attachSelectedCaseMedCloudFilesSuccess())
  } catch (e) {
    yield put(attachSelectedCaseMedCloudFilesError(e))
  }
}

function* attachChatMedCloudFiles(chatId: string, fileIds: string[]) {
  if (fileIds.length) {
    yield call(api.post, API.CHAT_ATTACH_FILES(chatId), {
      source: FileHistorySourcesEnum.P2P_CLOUD,
      originalEntityId: chatId,
      fileIds
    })
  }
}

function* attachSelectedChatMedCloudFilesSaga({
  payload: { chatId }
}: ReturnType<typeof attachSelectedChatMedCloudFilesRequest>) {
  try {
    // do not attach files that are still uploading
    const fileIds: string[] = yield select(getAttachFilesAlreadyFilesSelector(chatId))

    yield call(attachChatMedCloudFiles, chatId, fileIds)

    yield put(hideModalAction())
    yield put(attachSelectedChatMedCloudFilesSuccess())
  } catch (e) {
    yield put(attachSelectedChatMedCloudFilesError(e))
  }
}

function* getChatMedCloudFilesSaga({
  payload: { filters }
}: ReturnType<typeof getChatMedCloudFilesRequest>) {
  const { page, search = '', showBy = PAGINATION_DEFAULT_SHOW_BY, maxFileSize } = filters || {}

  try {
    const { search: currentSearch }: TMedCloudFilters = yield select(
      (state: State) => state.medCloud.filters
    )
    const { ids: attachIds, list: attachList }: TAttachMedcloudFiles = yield select(
      (state: State) => state.medCloud.attachFiles
    )
    const accountId: string = yield select((state: State) => state.global.accountData?.id)

    const url = new CloudFilesQueryBuilder(API.CLOUD_FILES)
      .searchQuery(search)
      .showBy(showBy)
      .page(page)
      .fileOwnerId(accountId)
      .maxFileSize(maxFileSize)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<IFilesEntity[]> = yield call(api.get, url)
    const { fileList, ids } = fileNormalize(results)

    const data: TGetCaseMedCloudFilesSuccessPayload =
      search !== currentSearch && page === 0
        ? {
            ids,
            list: fileList,
            total,
            filters: {
              search,
              page,
              total
            }
          }
        : {
            ids: [...attachIds, ...ids],
            list: { ...attachList, ...fileList },
            total,
            filters: {
              search,
              page,
              total
            }
          }

    yield put(getChatMedCloudFilesSuccess(data))
  } catch (e) {
    yield put(getChatMedCloudFilesError(e))
  }
}

function* startFakeUploadActionSaga({
  payload: { onUpload, fileId: id, fileName, fileType }
}: ReturnType<typeof startFakeUploadAction>) {
  try {
    yield put(setFakeFileInWidget({ fileId: id, fileName, fileType, onUpload }))

    yield onUpload(fileName, id)

    yield put(setUploadFilePercent({ id, percent: 100 }))
    yield put(setUploadFileStatus({ id, status: EFileUploadStatus.SUCCESS }))
  } catch (e) {
    if (e.response?.data?.code === ErrorCodesEnum.CASE_NOT_FOUND) {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(
        medCloudFileInvalidate({
          id,
          errorType: e?.response?.data.code,
          message: i18n.t('common.toast.fileNotFound')
        })
      )
    } else {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id }))
      yield put(medCloudFileInvalidate({ id, message: e.response?.data?.message }))
    }

    yield showFileErrorToast()
  }
}

function* copyFileToMedcloudSaga({
  payload: { fileId, fileName, fileType, source, originalEntityId, onSuccess }
}: ReturnType<typeof copyFileToMedcloudRequest>) {
  try {
    const queryBuilder = new QueryBuilder(API.FILE_COPY_TO_MEDCLOUD(fileId))

    const url = queryBuilder
      .custom('source', source)
      .custom('originalEntityId', originalEntityId)
      .build()

    const onUpload = function* (name: string) {
      yield call(api.post, url, {
        fileName: name
      })

      yield put(copyFileToMedcloudSuccess())
      yield put(getMedCloudCapacityRequest())
    }

    yield put(
      startFakeUploadAction({
        fileId,
        fileName,
        fileType,
        onUpload
      })
    )

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield put(copyFileToMedcloudError(e))
    handleError(e)
  }
}

// This function to stop the uploading of files
// If you do not pass an array of fileIds, all files will be aborted
function* medCloudAbortUploadSaga({
  payload: { fileIds, onSuccess }
}: ReturnType<typeof medCloudAbortUpload>) {
  try {
    const list: TMedCloudFileList = yield select((state: State) => state.medCloud.widget.list)
    const { fileQueue, filesInProgress }: TMedCloudUpload = yield select(
      (state: State) => state.medCloud.upload
    )

    const uploadingFiles = fileIds ? fileIds : [...fileQueue, ...filesInProgress]

    for (const id of uploadingFiles) {
      const item = list[id]

      yield put(medCloudFileInvalidate({ id }))
      if (item) {
        yield call(item.cancelTokenSource.cancel, SYSTEM_ABORTED_UPLOAD)
      }
    }

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    console.error(e)
  }
}

function* cancelUploadFileSaga({ payload }: ReturnType<typeof cancelUploadFile>) {
  try {
    yield call(api.post, API.UPLOAD_CANCEL, payload)
  } catch (e) {
    console.error(e)
  }
}
function* refreshUploadingSession(id: string) {
  try {
    const startedAt: Date | undefined = yield select(
      (state: State) => state.medCloud.upload.startedAt
    )
    const key: string | undefined = yield select(
      (state: State) => state.medCloud.widget.list[id].preSignKey
    )

    if (
      key &&
      startedAt &&
      differenceInMilliseconds(new Date(), startedAt) >= REFRESH_UPLOADING_SESSION_DELAY
    ) {
      yield call(api.post, API.UPLOAD_CONTINUE, { key })
      yield put(setUploadingStartDate({ date: new Date() }))
    }
  } catch (e) {
    console.error(e)
  }
}

function* attachChatFileSaga({
  payload: { chatId, fileIds }
}: ReturnType<typeof attachChatMedCloudFileRequest>) {
  try {
    yield call(attachChatMedCloudFiles, chatId, fileIds)

    yield put(attachChatMedCloudFileSuccess())
  } catch (e) {
    yield put(attachChatMedCloudFileError(e))
  }
}

export function* medCloudSaga() {
  yield takeLatest([actionTypes.UPLOAD_FILES_REQUEST], upload)
  yield takeLatest(actionTypes.GET_CASE_MED_CLOUD_FILES_REQUEST, getCaseMedCloudFilesSaga)
  yield takeLatest(
    actionTypes.ATTACH_SELECTED_CASE_MED_CLOUD_FILES_REQUEST,
    attachSelectedCaseMedCloudFilesSaga
  )
  yield takeLatest(
    actionTypes.ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_REQUEST,
    attachSelectedChatMedCloudFilesSaga
  )
  yield takeLatest(actionTypes.GET_CHAT_MED_CLOUD_FILES_REQUEST, getChatMedCloudFilesSaga)
  yield takeEvery(actionTypes.START_FAKE_UPLOAD_ACTION, startFakeUploadActionSaga)
  yield takeEvery(actionTypes.COPY_FILE_TO_MEDCLOUD_REQUEST, copyFileToMedcloudSaga)
  yield takeLatest(actionTypes.MED_CLOUD_ABORT_UPLOAD, medCloudAbortUploadSaga)
  yield takeLatest(actionTypes.CANCEL_UPLOAD_FILE, cancelUploadFileSaga)
  yield takeLatest(actionTypes.GET_CASE_LINKED_FILES_REQUEST, getCaseLinkedFilesSaga)
  yield takeLatest(actionTypes.ATTACH_CASE_LINKED_FILES_REQUEST, attachCaseLinkedFilesSaga)
  yield takeLatest(actionTypes.ATTACH_CHAT_MED_CLOUD_FILE_REQUEST, attachChatFileSaga)
}
