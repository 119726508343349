import cls from 'classnames'

import { getMapComponent } from 'utils'
import { Button, Tooltip } from 'App/components'
import { ReactComponent as FilterOutlineIcon } from 'assets/icons/FilterOutline.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg'

import styles from './FilterButton.module.scss'

export type TFilterButtonVariant = 'button' | 'inline' | 'inline-filled'

export type TFilterButtonProps = {
  appliedFilter?: boolean
  onClick?: () => void
  variant?: TFilterButtonVariant
  disabled?: boolean
  className?: string
  tooltip?: string
}

const VARIANT = new Map<
  TFilterButtonVariant,
  (props: Omit<TFilterButtonProps, 'variant'>) => JSX.Element
>()
  .set('button', ({ appliedFilter, onClick, disabled, className }) => (
    <Button
      className={cls(styles.filterButton, className)}
      adaptive={false}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.container}>
        <FilterOutlineIcon className={cls(disabled && styles.disabled)} />
        {appliedFilter && <div className={styles.badge} />}
      </div>
    </Button>
  ))
  .set('inline', ({ appliedFilter, onClick, disabled, className, tooltip }) => (
    <Tooltip title={tooltip}>
      <div
        className={cls(styles.container, styles.inline, className)}
        onClick={disabled ? undefined : onClick}
      >
        <FilterOutlineIcon className={cls(disabled && styles.disabled)} />
        {appliedFilter && <div className={styles.badge} />}
      </div>
    </Tooltip>
  ))
  .set('inline-filled', ({ appliedFilter, onClick, disabled, className, tooltip }) => (
    <Tooltip title={tooltip}>
      <div
        className={cls(styles.container, styles.inline, className)}
        onClick={disabled ? undefined : onClick}
      >
        <FilterIcon className={cls(disabled && styles.disabled)} />
        {appliedFilter && <div className={styles.badge} />}
      </div>
    </Tooltip>
  ))

export const FilterButton = ({
  variant = 'button',
  appliedFilter,
  disabled,
  className,
  tooltip,
  onClick
}: TFilterButtonProps) =>
  getMapComponent(VARIANT, variant, { appliedFilter, disabled, onClick, className, tooltip })
