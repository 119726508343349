import cls from 'classnames'

import { Chip } from 'App/components/common'

import styles from './ItemInfoPurpose.module.scss'

export type TItemInfoPurposeProps = PropsWithClassName & {
  purposes: string[]
}

export const ItemInfoPurpose = ({ className, purposes }: TItemInfoPurposeProps) => (
  <div className={cls(styles.root, className)}>
    {purposes.map((purpose) => (
      <Chip key={purpose} className={styles.purpose} text={purpose} />
    ))}
  </div>
)
