import { normalize, schema } from 'normalizr'
import { ProfileLinkTypeNamesEnum } from '@medentee/enums'

import { TIds } from 'store'
import { EOnTheWebState } from 'enums'
import {
  IEducationDTO,
  ILicenseDTO,
  IPublicationDTO,
  IRelatedAccountDTO,
  IWorkExperienceDTO
} from 'interfaces'

import {
  TProfessionsItem,
  TProfessionsList,
  TOnTheWeb,
  TWebsiteList,
  TWorkExperienceNormalized,
  TEducationNormalized,
  TLicensesNormalized,
  TPublicationsNormalized
} from './userProfile.types'

const listSchema = new schema.Array(new schema.Entity('list'))

export const professionNormalize = (
  originalData: TProfessionsItem[]
): Normalized<TProfessionsList> => {
  const { entities, result } = normalize<TProfessionsItem, Normalized<TProfessionsList>, TIds>(
    originalData,
    listSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export type TWebsiteDTO = {
  id: string
  url: string | null
  type: ProfileLinkTypeNamesEnum
}

export const websiteNormalize = (originalData: TWebsiteDTO[]): TOnTheWeb => {
  const { entities, result } = normalize<TOnTheWeb, Normalized<TWebsiteList>, TIds>(
    originalData,
    listSchema
  )

  let firstWebsiteEncountered = false
  result.forEach((item) => {
    const listItem = entities.list[item]

    listItem.processing = false
    listItem.isNew = false
    listItem.editState = listItem.url ? EOnTheWebState.WITH_DATA : EOnTheWebState.EMPTY
    listItem.deletable =
      listItem.type === ProfileLinkTypeNamesEnum.WEBSITE && firstWebsiteEncountered
    listItem.url = listItem.url === null ? '' : listItem.url

    if (listItem.type === ProfileLinkTypeNamesEnum.WEBSITE) {
      firstWebsiteEncountered = true
    }
  })

  return {
    websiteList: entities.list || {},
    ids: result || []
  }
}

export type TAccountsNormalized = {
  ids: string[]
  list: Record<string, IRelatedAccountDTO>
}

export const accountsNormalize = (
  originalData: IRelatedAccountDTO[]
): TAccountsNormalized & { notifications: { total: number; accounts: Record<string, number> } } => {
  const { entities, result } = normalize<IRelatedAccountDTO, TAccountsNormalized, TIds>(
    originalData,
    listSchema
  )
  const notifications = result.reduce(
    (res, id) => {
      const account = entities.list[id]

      if (account) {
        return {
          total: res.total + account.notificationsCount,
          accounts: { ...res.accounts, [id]: account.notificationsCount }
        }
      }

      return res
    },
    { total: 0, accounts: {} }
  )

  return {
    notifications,
    ids: result || [],
    list: entities.list || {}
  }
}

export const workExperienceNormalize = (originalData: IWorkExperienceDTO[] = []) => {
  const { entities, result } = normalize<IWorkExperienceDTO, TWorkExperienceNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const educationNormalize = (originalData: IEducationDTO[] = []) => {
  const { entities, result } = normalize<IEducationDTO, TEducationNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const licensesNormalize = (originalData: ILicenseDTO[] = []) => {
  const { entities, result } = normalize<ILicenseDTO, TLicensesNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const publicationsNormalize = (originalData: IPublicationDTO[] = []) => {
  const { entities, result } = normalize<IPublicationDTO, TPublicationsNormalized, TIds>(
    originalData,
    listSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}
