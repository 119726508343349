import cls from 'classnames'

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'

import styles from './ControlButton.module.scss'

export enum EControlButtonClassName {
  NEXT = 'swiper_nav_control_next',
  PREV = 'swiper_nav_control_prev',
  DISABLE = 'swiper_nav_control_disabled',
  HIDDEN = 'swiper_nav_control_hidden'
}

export type TControlButtonProps = {
  type: 'next' | 'prev'

  position?: 'absolute' | 'fixed'
}

export const ControlButton = ({ type, position = 'fixed' }: TControlButtonProps) =>
  type === 'next' ? (
    <ChevronRightIcon
      className={cls(styles.root, styles[position], styles.next, EControlButtonClassName.NEXT)}
    />
  ) : (
    <ChevronLeftIcon
      className={cls(styles.root, styles[position], styles.prev, EControlButtonClassName.PREV)}
    />
  )
