import { normalize, schema } from 'normalizr'

import { ICaseEntity } from 'interfaces/api/cases'
import { ICaseDescriptionEntity } from 'interfaces/api/caseDetailsDTO'
import { TIds } from 'store/store.types'

import {
  TCaseLinks,
  TCaseLinksTypes,
  TCaseList,
  TCaseMembersList,
  TLinkCaseListNormalized
} from './cases.types'

const caseListSchema = new schema.Entity('caseList', {}, { idAttribute: 'id' })
const memberSchema = new schema.Entity('memberList')
caseListSchema.define({
  members: [memberSchema]
})

const caseLinksSchema = new schema.Entity(
  'linkedCases',
  {},
  {
    idAttribute: 'id',
    processStrategy: (entity) => {
      const { id, owner, classification, type, status, humanReadableId, title, filesCount } = entity
      return {
        id,
        title,
        type,
        status,
        humanReadableId,
        owner,
        classification,
        filesCount
      }
    }
  }
)

export type TCaseNormalized = {
  caseList: TCaseList
  ids: TIds
  memberList: TCaseMembersList
}

type TCaseNormalizedList = Record<string, ICaseEntity & { description: ICaseDescriptionEntity[] }>

type TCaseNormalizeResult = {
  entities: {
    caseList: TCaseNormalizedList
    memberList: TCaseMembersList
  }
  result: TIds
}

export const caseLinksNormalize = (
  originalData: TCaseLinksTypes[]
): Pick<TCaseLinks, 'ids' | 'linkedCases'> => {
  const normalizedData = normalize(originalData, [caseLinksSchema])

  return {
    linkedCases: normalizedData.entities.linkedCases || {},
    ids: normalizedData.result || []
  }
}

export const caseNormalize = (originalData: ICaseEntity[]): TCaseNormalized => {
  const normalizedData: TCaseNormalizeResult = normalize(originalData, [caseListSchema])

  const ids = normalizedData.result || []
  const caseList: TCaseList = normalizedData.entities.caseList || {}

  ids.forEach((id) => {
    caseList[id] = {
      ...caseList[id],
      description:
        (normalizedData.entities.caseList[id].description &&
          normalizedData.entities.caseList[id].description[0]?.data) ||
        ''
    }
  })

  return {
    caseList,
    memberList: normalizedData.entities.memberList || {},
    ids
  }
}

const listSchema = new schema.Entity('list')
const ids = new schema.Array(listSchema)

export const linkCasesNormalize = (originalData: ICaseEntity[] = []) => {
  const { entities, result } = normalize<ICaseEntity, TLinkCaseListNormalized, TIds>(
    originalData,
    ids
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}
