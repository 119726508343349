import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { copyToCaseCloudRequest } from 'store/caseCloud'
import { CaseCloudCopySharedWithMeConfirm } from 'App/components/Case/CaseCloud/CaseCloudCopySharedWithMeConfirm'

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      copyToCaseCloud: copyToCaseCloudRequest
    },
    dispatch
  )

export const CaseCloudCopySharedWithMeConfirmContainer = connect(
  null,
  mapDispatchToProps
)(CaseCloudCopySharedWithMeConfirm)
