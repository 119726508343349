import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { removeErrorNotification } from 'store/errors'
import { State } from 'redux/rootReducer'
import { DISCARD_CASES_PERMISSIONS, discardCasesPermissionsRequest } from 'store/files/myFiles'
import { createProcessingSelector } from 'store/loading'
import {
  FileDiscardCasesPermissionsConfirm,
  TFileDiscardCasesPermissionsConfirmOwnProps
} from 'App/components/File/FileInformation/FileDetails/FileDiscardCasesPermissionsConfirm'

export type TFileDiscardCasesPermissionsConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingSelector([DISCARD_CASES_PERMISSIONS])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[DISCARD_CASES_PERMISSIONS]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      discardPermissions: discardCasesPermissionsRequest,
      removeErrorNotification: () => removeErrorNotification(DISCARD_CASES_PERMISSIONS)
    },
    dispatch
  )

export const FileDiscardCasesPermissionsConfirmContainer: FC<TFileDiscardCasesPermissionsConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(FileDiscardCasesPermissionsConfirm)
