import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { TOption } from 'App/components'
import { createSelectorOptions } from 'utils'

export const CONTACTS_SEARCH_OPTIONS = [
  'name',
  'location',
  'professions',
  'specializations'
] as const

export const useContactsSearchOptions = (): TOption[] => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      createSelectorOptions<TupleUnion<typeof CONTACTS_SEARCH_OPTIONS>>(
        CONTACTS_SEARCH_OPTIONS,
        (item) => t('contacts.list.searchByOptions', { context: item })
      ),
    [t]
  )
}
