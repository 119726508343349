import { Trans, useTranslation } from 'react-i18next'

import { TSettingsPersonalInfoFullNameContainerProps } from 'App/containers'
import { SettingsInfoRow, Help } from 'App/components'
import { SUPPORT_EMAIL } from 'globalConstants'

import styles from './SettingsPersonalInfoFullName.module.scss'

export type TSettingsPersonalInfoFullNameProps = TSettingsPersonalInfoFullNameContainerProps

export const SettingsPersonalInfoFullName = ({ fullName }: TSettingsPersonalInfoFullNameProps) => {
  const { t } = useTranslation()

  return (
    <SettingsInfoRow
      label={
        <div className={styles.name}>
          <Trans
            t={t}
            i18nKey="settings.personalInformation.fullNameLabel"
            components={{
              help: <Help content={t('userProfile.changeNameHint', { email: SUPPORT_EMAIL })} />
            }}
          />
        </div>
      }
      description={fullName}
    />
  )
}
