import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'

import { OngoingMeeting } from './OngoingMeeting'
import styles from './OngoingMeetingCalls.module.scss'

export const OngoingMeetingCalls = () => {
  const ids = useAppSelector((state) => state.calls.ongoingCalls.ids)

  const { t } = useTranslation()

  if (!ids.length) {
    return null
  }

  return (
    <div className={styles.root}>
      <p className={styles.title}>{t('modal.meetings.ongoingLabel')}</p>
      {ids.map((id) => (
        <OngoingMeeting key={id} id={id} />
      ))}
    </div>
  )
}
