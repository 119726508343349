import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { UnityHubChannelMembers } from 'App/components/UnityHub/UnityHubChannelMembers'
import { getEventAttendees } from 'api/events'

type TEventChannelMembersProps = {
  channelId: string
  eventId: string
  setTabMembersTotal: (total: number) => void
}

export const EventChannelMembers = ({
  channelId,
  eventId,
  setTabMembersTotal
}: TEventChannelMembersProps) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['get-event-channel-members', eventId, channelId, page],
    queryFn: () => getEventAttendees({ eventId, chatId: channelId, page }),
    cacheTime: 0
  })

  return (
    <UnityHubChannelMembers
      channelId={channelId}
      data={data}
      page={page}
      loading={isLoading}
      alert={t('events.members.alert')}
      setTabMembersTotal={setTabMembersTotal}
      setPage={setPage}
      loadData={refetch}
    />
  )
}
