import { NavMenu, TNavMenuItem } from 'App/components'

import styles from './InvitationMenuGroup.module.scss'

export type InvitationMenuGroupProps = {
  items: TNavMenuItem[]
  title: string
}

export const InvitationMenuGroup = ({ items, title }: InvitationMenuGroupProps) => (
  <div className={styles.root}>
    <h3 className={styles.title}>{title}</h3>
    <NavMenu
      tabPosition="left"
      items={items}
      classes={{
        root: styles.navMenuRoot,
        item: styles.navMenuItem,
        icon: styles.navMenuIcon,
        title: styles.navMenuTitle
      }}
    />
  </div>
)
