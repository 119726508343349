import { useCallback } from 'react'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  approveBusinessAdminInviteRequest,
  APPROVE_BUSINESS_ADMIN_INVITE,
  createLoadingSelector,
  createProcessingBySelector,
  GET_ORGANIZATION_REGISTERED_USERS,
  REMOVE_BUSINESS_ADMIN_INVITE,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EBusinessAdminDeleteAction, EBusinessAdminConfirmFrom } from 'enums'
import { InvitationItemReceived } from 'App/components'
import { EModalComponents } from 'App/containers'

export type TOrganizationStaffRequestsItemProps = {
  id: string
}

export const OrganizationStaffRequestsItem = ({ id }: TOrganizationStaffRequestsItemProps) => {
  const dispatch = useAppDispatch()

  const { account, role, createdAt, invitation, viewed } = useAppSelector(
    (state) => state.organizations.invitations.registeredUsers.list[id] ?? {}
  )

  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATION_REGISTERED_USERS]))
  const acceptProcessing = useAppSelector(
    createProcessingBySelector([APPROVE_BUSINESS_ADMIN_INVITE])(id)
  )
  const rejectProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_BUSINESS_ADMIN_INVITE])(id)
  )

  const { t } = useTranslation()

  const handleAcceptInvite = useCallback(() => {
    dispatch(
      approveBusinessAdminInviteRequest({
        id,
        from: EBusinessAdminConfirmFrom.STAFF_REQUESTS,
        direction: BusinessAccountDirectionEnum.INCOMING,
        processingId: id
      })
    )
  }, [dispatch, id])

  const handleRejectInvite = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title_reject'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id,
          type: EBusinessAdminDeleteAction.REJECT,
          from: EBusinessAdminConfirmFrom.STAFF_REQUESTS,
          role: role.name,
          direction: BusinessAccountDirectionEnum.INCOMING,
          description: t('modal.businessAdminInvitationConfirm.content_rejectStaffRequest')
        }
      })
    )
  }, [dispatch, id, role.name, t])

  return (
    <InvitationItemReceived
      sender={account}
      createdAt={createdAt}
      invitationEmail={invitation?.email}
      viewed={viewed}
      loading={loading}
      acceptProcessing={acceptProcessing}
      rejectProcessing={rejectProcessing}
      chipList={[
        {
          text: t('invitations.newComerLabel'),
          color: 'primary',
          variant: 'square',
          hidden: !account?.invitation
        }
      ]}
      onAccept={handleAcceptInvite}
      onReject={handleRejectInvite}
    />
  )
}
