import { useEffect, useMemo } from 'react'
import { Field, Form, useFormState } from 'react-final-form'
import { Col, Row } from 'antd'
import { GetCountryQueryModeEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import { getCountriesRequest, TCountry, useAppDispatch, useAppSelector } from 'store'
import { TChipInputItemValue } from 'App/components/common/ChipInput/ChipInputItem'
import { MAX_LENGTH_100, MAX_LENGTH_1000, MAX_LENGTH_50, MIN_LENGTH_1 } from 'globalConstants'

import {
  Button,
  ChipInput,
  FormSubmitButtonSkeleton,
  Help,
  Select,
  TextField,
  TextFieldSkeleton
} from '../../index'
import { validation } from '../../../../utils'
import { useUnsavedDataPrompt } from '../../../hooks/useUnsavedDataPrompt'

import styles from './CommunityFormContent.module.scss'

export type TFormValues = {
  title: string
  description: string
  location: TCountry | null
  tags: TChipInputItemValue[]
}

export type TCommunityFormContentProps = {
  initialValues: TFormValues | null
  onSubmit: (values: TFormValues) => void

  mode?: 'edit'
  loading?: boolean
  onCancel?: () => void
}

const SUBMIT_KEYS = new Set(['Enter'])

const formatNewItem = (item: TChipInputItemValue): TChipInputItemValue => ({
  ...item,
  text: item.text.trim()
})

const SpyOnFormChanged = ({
  onChange,
  prompt
}: {
  onChange: (value: boolean) => void
  prompt: JSX.Element
}) => {
  const { pristine, dirtySinceLastSubmit, submitSucceeded } = useFormState()

  useEffect(() => {
    onChange(submitSucceeded ? dirtySinceLastSubmit : !pristine)
  }, [pristine, onChange, submitSucceeded, dirtySinceLastSubmit])

  return prompt
}

export const CommunityFormContent = ({
  mode,
  loading,
  onSubmit,
  onCancel,
  initialValues
}: TCommunityFormContentProps) => {
  const dispatch = useAppDispatch()

  const { countries } = useAppSelector((state) => state.misc)

  const { ignore, unIgnore, prompt, setFormChanged } = useUnsavedDataPrompt()

  const { t } = useTranslation()

  const { validateField, validateChipInput } = useMemo(() => {
    const tagsValidation = [
      validation.required(t('validationErrors.onlySpaces', { ns: 'errors' })),
      validation.maxLength(MAX_LENGTH_50)
    ]

    const validateChipInputItem = validation.composeValidators(...tagsValidation)
    const validateChipInputField = validation.composeValidatorsForArray(...tagsValidation)

    return {
      validateField: (value: TChipInputItemValue[], values: Record<string, any>) =>
        validateChipInputField(value, values, (item: TChipInputItemValue) => item.text),
      validateChipInput: (value: TChipInputItemValue) =>
        validateChipInputItem(value.text, undefined)
    }
  }, [t])

  const isEdit = mode === 'edit'

  const handleCancel = () => {
    ignore()
    onCancel && onCancel()
  }
  const handleSubmit = (values: TFormValues) => {
    ignore()
    onSubmit(values)
  }
  const handleSpyChange = (value: boolean) => {
    unIgnore()
    setFormChanged(value)
  }

  useEffect(() => {
    dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.ALL_WITH_WORLDWIDE }))
  }, [dispatch])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({
        handleSubmit: onSubmitClick,
        submitting,
        valid,
        dirty,
        dirtySinceLastSubmit,
        submitSucceeded
      }) => (
        <>
          <SpyOnFormChanged onChange={handleSpyChange} prompt={prompt} />

          <Row gutter={[22, 28]}>
            <Col xs={24} md={12}>
              <TextFieldSkeleton loading={loading}>
                <Field
                  name="title"
                  validate={validation.composeValidators(
                    validation.onlySpaces(),
                    validation.required(),
                    validation.minLength(MIN_LENGTH_1),
                    validation.maxLength(MAX_LENGTH_100)
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      topLabel={t('communities.detailsForm.title')}
                      valueLengthMax={MAX_LENGTH_100}
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      {...input}
                    />
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
            <Col xs={24} md={12}>
              <TextFieldSkeleton loading={loading}>
                <Field name="location">
                  {({ input, meta }) => (
                    <Select
                      value={input.value}
                      options={countries}
                      labelKey="countryName"
                      valueKey="code"
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      topLabel={t('communities.detailsForm.location')}
                      onChange={(value: TCountry) => {
                        input.onChange(value)
                      }}
                    />
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
            <Col xs={24}>
              <TextFieldSkeleton loading={loading} variant="textArea">
                <Field
                  name="description"
                  validate={validation.composeValidators(
                    validation.onlySpaces(),
                    validation.required(),
                    validation.minLength(MIN_LENGTH_1),
                    validation.maxLength(MAX_LENGTH_1000)
                  )}
                >
                  {({ input, meta }) => (
                    <TextField
                      multiline
                      rows={4}
                      valueLengthMax={MAX_LENGTH_1000}
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      topLabel={t('communities.detailsForm.description')}
                      {...input}
                    />
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
            <Col xs={24}>
              <TextFieldSkeleton loading={loading} variant="textArea">
                <Field name="tags" validate={validateField}>
                  {({ input, meta }) => (
                    <ChipInput
                      paste={false}
                      label={
                        <Trans
                          t={t}
                          i18nKey="communities.detailsForm.tags"
                          components={{
                            help: <Help content={t('communities.detailsForm.help')} />
                          }}
                        />
                      }
                      maxSize={5}
                      formatNewItem={formatNewItem}
                      error={meta.error}
                      submitKeys={SUBMIT_KEYS}
                      validate={validateChipInput}
                      {...input}
                    />
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
            <Col xs={24}>
              <Row gutter={[12, 20]} justify="center" className={styles.actions}>
                {!isEdit && (
                  <Col>
                    <FormSubmitButtonSkeleton loading={loading}>
                      <Button variant="outlined" onClick={handleCancel}>
                        {t('communities.detailsForm.cancelButton')}
                      </Button>
                    </FormSubmitButtonSkeleton>
                  </Col>
                )}
                <Col>
                  <FormSubmitButtonSkeleton loading={loading}>
                    <Button
                      onClick={onSubmitClick}
                      disabled={!valid || !dirty || (submitSucceeded && !dirtySinceLastSubmit)}
                      loading={submitting || loading}
                    >
                      {t('communities.detailsForm.submitButton', { context: mode })}
                    </Button>
                  </FormSubmitButtonSkeleton>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </Form>
  )
}
