import { useCallback } from 'react'
import { Col, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { isBusinessAccountSelector, useAppSelector } from 'store'
import { Chip, ContextMenu, IconButton, TruncatedText } from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'
import { UnityHubChannelIcon } from 'App/components/UnityHub'
import { ReactComponent as EyeOutlineIcon } from 'assets/icons/EyeOutline.svg'
import { ReactComponent as FormatListBulletedIcon } from 'assets/icons/FormatListBulleted.svg'
import { TBroadcast } from 'interfaces/api/broadcasts'

import { useBroadcastRoomHeaderKebabMenu } from './useBroadcastRoomHeaderKebabMenu'
import styles from './BroadcastRoomHeader.module.scss'

type TBroadcastRoomHeaderProps = Pick<TBroadcast, 'id' | 'type' | 'name'>

export const BroadcastRoomHeader = ({ id, type, name }: TBroadcastRoomHeaderProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const watchers = useAppSelector((state) => state.broadcasts.watchers[id]) ?? 0

  const { push } = useHistory()

  const { t } = useTranslation()

  const { getMenuItems } = useBroadcastRoomHeaderKebabMenu()

  const handleBack = useCallback(() => {
    if (type === BroadcastTypeEnum.EVENT) {
      push('/events')
    }

    if (type === BroadcastTypeEnum.EVENT) {
      push(isBusinessAccount ? '/community' : '/communities')
    }
  }, [type, isBusinessAccount, push])

  return (
    <Row className={styles.root} align="middle" justify="space-between" wrap={false}>
      <Col className={styles.start}>
        <Row align="middle" wrap={false}>
          {!isDesktop && (
            <IconButton
              classes={{ root: styles.action }}
              iconComponent={<FormatListBulletedIcon />}
              iconSize={EIconSize.SM}
              onClick={handleBack}
            />
          )}

          <UnityHubChannelIcon variant="broadcast" />
          <TruncatedText classes={{ text: styles.title }} text={name} />
        </Row>
      </Col>

      <Col className={styles.end}>
        <Row align="middle" className={styles.actions} wrap={false}>
          {!isMobile && (
            <Chip
              className={styles.chip}
              truncate={false}
              hasTooltip={false}
              variant="light"
              shape="square"
              prefix={<EyeOutlineIcon className={styles.watchingIcon} />}
              text={t('liveStream.watchingNow', { watchersNumber: watchers })}
            />
          )}

          <ContextMenu
            classes={{ root: styles.kebabMenu }}
            menuItems={getMenuItems({ broadcastId: id })}
            nestIn="body"
          />
        </Row>
      </Col>
    </Row>
  )
}
