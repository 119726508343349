import cls from 'classnames'
import { NotificationListTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Button, TButtonProps } from 'App/components/common'
import { ReactComponent as CheckReadIcon } from 'assets/icons/CheckRead.svg'
import { useAppSelector } from 'store'
import { queryClient } from 'queryClient'

import { GET_SHOWCASE_NOTIFICATIONS_QUERY_KEY } from '../NotificationsShowcases'
import { GET_MAIN_NOTIFICATIONS_QUERY_KEY } from '../NotificationsMain'

import { useReadNotifications } from './useReadNotifications'
import styles from './NotificationsReadAllButton.module.scss'

type TNotificationsReadAllButtonProps = PropsWithClassName &
  Pick<TButtonProps, 'iconPosition' | 'size'>

export const NotificationsReadAllButton = ({
  className,
  size = 'md',
  iconPosition = 'right'
}: TNotificationsReadAllButtonProps) => {
  const { t } = useTranslation()

  const disabled = useAppSelector((state) => !state.notifications.general.headerNotification)

  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_SHOWCASE_NOTIFICATIONS_QUERY_KEY],
      type: 'active'
    })
    queryClient.invalidateQueries({ queryKey: [GET_MAIN_NOTIFICATIONS_QUERY_KEY], type: 'active' })
  }

  const { mutate, isLoading } = useReadNotifications({ onSuccess })

  const handleClick = () => {
    mutate({ types: [NotificationListTypeEnum.DEFAULT, NotificationListTypeEnum.SHOWCASE] })
  }

  return (
    <Button
      onClick={handleClick}
      className={cls(styles.root, className)}
      iconPosition={iconPosition}
      icon={<CheckReadIcon />}
      loading={isLoading}
      disabled={disabled}
      adaptive={false}
      size={size}
      variant="inline"
    >
      {t('markAllAsReadButton', { ns: 'notifications' })}
    </Button>
  )
}
