import { TAccount } from 'store'

import { createBusEvent } from '../core'

const eventName = 'showcaseEditCommentSuccess'
const showcaseEditCommentSuccess = createBusEvent<{
  commentId: string
  message: string
  updatedAt: string
  mentions: TAccount[]
}>(eventName)

export default showcaseEditCommentSuccess
