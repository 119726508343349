import { useState } from 'react'

import { CollapsePanel, ContextMenu, Help, TruncatedText } from 'App/components/common'
import { accountIdSelector, useAppSelector } from 'store'
import { IDepartment } from 'interfaces'

import { DepartmentStaffList } from '../DepartmentStaffList'

import styles from './StaffTabDepartmentItem.module.scss'
import { useKebabMenu } from './useKebabMenu'

export type TStaffTabDepartmentItemProps = Pick<
  IDepartment,
  'description' | 'id' | 'membersCount' | 'name' | 'leader'
> & {
  organizationId: string
}

export const StaffTabDepartmentItem = ({
  organizationId,
  description,
  id,
  membersCount,
  name,
  leader,
  ...rest
}: TStaffTabDepartmentItemProps) => {
  const accountId = useAppSelector(accountIdSelector)
  const [staffTotal, setStaffTotal] = useState(Number(membersCount))
  const isDepartmentLead = leader?.id === accountId

  const { getMenuItems } = useKebabMenu({ organizationId, setStaffTotal })

  return (
    <CollapsePanel
      {...rest}
      key={id}
      variant="highlighted"
      className={styles.panel}
      collapsible={staffTotal === 0 ? 'disabled' : undefined}
      header={
        <div className={styles.header}>
          <TruncatedText text={`${name} (${staffTotal})`} />

          <div className={styles.indicators}>
            {description && (
              <div className={styles.collapseArrow}>
                <Help
                  shouldShowSub={false}
                  iconVariant="info"
                  content={description}
                  className={styles.icon}
                />
              </div>
            )}
            <ContextMenu
              classes={{ icon: styles.contextMenu }}
              menuItems={getMenuItems({
                departmentId: id,
                isDepartmentLead,
                departmentName: name
              })}
            />
          </div>
        </div>
      }
    >
      <DepartmentStaffList
        organizationId={organizationId}
        departmentId={id}
        staffTotal={staffTotal}
        setStaffTotal={setStaffTotal}
        isDepartmentLead={isDepartmentLead}
      />
    </CollapsePanel>
  )
}
