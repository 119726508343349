import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { stringify } from 'query-string'
import { EventRepresentativeTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { isBusinessAccountSelector, useAppSelector } from 'store'
import { IconButton } from 'App/components'
import { ReactComponent as EventPlusIcon } from 'assets/icons/EventPlus.svg'

type TEventsCreateButtonProps = {
  organizationId?: string
  communityId?: string
}

export const EventsCreateButton = ({ communityId, organizationId }: TEventsCreateButtonProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const handleCreateChannel = useCallback(() => {
    let representativeType: EventRepresentativeTypeEnum | undefined = undefined

    if (organizationId) {
      representativeType = EventRepresentativeTypeEnum.ORGANIZATION
    }

    if (communityId) {
      representativeType = EventRepresentativeTypeEnum.COMMUNITY
    }

    push({
      pathname: '/events/create',
      search: stringify({
        representativeId: organizationId || communityId,
        representativeType
      })
    })
  }, [communityId, organizationId, push])

  return isBusinessAccount ? (
    <IconButton
      iconComponent={<EventPlusIcon />}
      iconSize={EIconSize.CUSTOM}
      toolTip={t('events.createNewEventButton')}
      onClick={handleCreateChannel}
    />
  ) : null
}
