import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import {
  UserProfileContact,
  TUserProfileContactBaseProps
} from 'App/components/UserProfile/UserProfileContact'
import { createLoadingSelector, GET_USER_PROFILE_BY_ID } from 'store'

export type TUserProfileContactContainerProps = ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_USER_PROFILE_BY_ID])

const mapStateToProps = (state: State, ownProps: TUserProfileContactBaseProps) => {
  const {
    id,
    headline,
    facebook,
    linkedIn,
    twitter,
    websites,
    timezone,
    city,
    country,
    type,
    chatId,
    companyInfo,
    displayUserName,
    firstName,
    lastName,
    contact,
    isContact,
    isCoworker
  } = state.userProfile.viewProfile

  const { id: accountId, type: accountType } = state.global?.accountData || {}

  return {
    isOwner: id === accountId,
    accountTypeName: accountType?.name,
    isPreview: ownProps.isPreview,
    id,
    type,
    headline,
    facebook,
    linkedIn,
    twitter,
    websites,
    timezone,
    city,
    country,
    chatId,
    companyInfo,
    displayUserName,
    firstName,
    lastName,
    contact,
    loading: loadingSelector(state),
    isContact,
    isCoworker
  }
}

export const UserProfileContactContainer = connect(mapStateToProps)(UserProfileContact)
