import { useMemo } from 'react'

import { ESorting } from 'enums'
import { TIds } from 'store'
import { generateRandomArray } from 'utils'

type TTseLoadingIdsProps = {
  ids: TIds
  loading: boolean
  limit: number

  direction?: keyof typeof ESorting
}

export const useLoadingIds = ({ ids, loading, limit, direction = 'DESC' }: TTseLoadingIdsProps) =>
  useMemo(() => {
    const randomArray = generateRandomArray(limit)

    if (loading && !ids.length) {
      return randomArray
    }

    if (loading && ids.length) {
      return direction === 'DESC' ? [...ids, ...randomArray] : [...randomArray, ...ids]
    }

    return ids
  }, [direction, ids, limit, loading])
