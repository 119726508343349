import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { fileDetailsResetAction, getFileContactListRequest, setFileContactListSearch } from 'store'
import { FileDetailsP2P } from 'App/components'

export type TFileDetailsP2PContainerOwnProps = {
  fileId: string
}

export type TFileDetailsP2PContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const mapStateToProps = (state: State, ownProps: TFileDetailsP2PContainerOwnProps) => ({
  fileContactIds: state.file.fileContacts.ids,
  fileId: ownProps.fileId
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFileContactList: getFileContactListRequest,
      onSearch: setFileContactListSearch,
      fileDetailsReset: fileDetailsResetAction
    },
    dispatch
  )

export const FileDetailsP2PContainer: FC<TFileDetailsP2PContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileDetailsP2P)
