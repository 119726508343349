import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { UserProfile } from 'App/screens'

export type TUserProfileContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  accountType: state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL
})

export const UserProfileContainer = connect(mapStateToProps)(UserProfile)
