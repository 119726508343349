import { ReactNode, useEffect, useRef, ReactElement, useMemo, RefObject } from 'react'
import cls from 'classnames'
import capitalize from 'lodash/capitalize'
import { Radio } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  CheckableList,
  Collapse,
  CollapsePanel,
  Drawer,
  FilterButton,
  Select,
  TSelectProps,
  Popover,
  TPopoverProps,
  SelectedFilters,
  TSelectedFilter,
  TFilterButtonVariant,
  Button,
  TRangePickerValue,
  TRangePickerProps
} from 'App/components'
import { useDialog, useAdaptiveLayout } from 'App/hooks'

import { RangeDatePicker } from '../Fields/DatePicker'

import { DrawerFilterActions } from './DrawerFilterActions'
import styles from './styles.module.scss'

export enum EFilterTypes {
  CHECKBOX,
  SELECT,
  DATE,
  RADIO
}

type TValue = number | string | null | boolean | TRangePickerValue
type TOption = { [key: string]: number | string }
type TCommonFilterItemContent<FilterKey = string> = {
  filterKey: FilterKey
  onMount?: () => void
  valueKey?: string
  labelKey?: string
  options?: TOption[]
}
export type TFilterItemContent<FilterKey = string> =
  | ({
      type: EFilterTypes.CHECKBOX
      options: TOption[]

      loading?: boolean
      withSelectAll?: boolean
    } & TCommonFilterItemContent<FilterKey>)
  | ({
      type: EFilterTypes.SELECT
      options: Record<string, TValue>[]
    } & TCommonFilterItemContent<FilterKey> &
      Partial<TSelectProps<Record<string, TValue>, boolean>>)
  | ({
      type: EFilterTypes.DATE
    } & TCommonFilterItemContent<FilterKey> &
      Partial<TRangePickerProps>)
  | ({
      type: EFilterTypes.RADIO
      options: TOption[]
    } & TCommonFilterItemContent<FilterKey>)
export type TFilterItem<FilterKey = string> = {
  sectionKey: string
  items: TFilterItemContent<FilterKey>[]

  title?: ReactNode
}
export type TObjectValue = { [key: string]: TValue | TValue[] }
export type TFilterValue = TValue | TValue[] | TObjectValue | TObjectValue[] | null | undefined
export type TFiltersProps = PropsWithClasses<
  'popover' | 'itemWrapper' | 'filterButton',
  {
    value: { [key: string]: TFilterValue }
    filters: TFilterItem[]
    onReset: () => void
    onChange: (key: string, value: TFilterValue) => void

    selectedFilter?: TSelectedFilter[]
    buttonVariant?: TFilterButtonVariant
    buttonDisabled?: boolean
    popover?: boolean
    collapse?: boolean
    innerPopoverProps?: Partial<
      Omit<TPopoverProps, 'content' | 'visible' | 'onVisibleChange' | 'overlayClassName'>
    >
    tooltip?: string
    addonAfter?: ReactNode
    notCollapsedDivider?: boolean
    shouldHideAppliedFilter?: boolean
  }
>
type TOptionsMap = Map<string, Map<any, string>>

const processOption = (
  option: TOption,
  { type, valueKey, labelKey }: { valueKey: string; labelKey: string; type: EFilterTypes }
): [any, string] => {
  let key: any = option[valueKey]
  const value = option[labelKey]

  if (type === EFilterTypes.SELECT) {
    key = option
  }

  return [key, value as any]
}
const FilterItem = ({
  children,
  filter,
  optionsMapRef
}: {
  children: ReactElement
  filter: TFilterItemContent
  optionsMapRef: RefObject<TOptionsMap>
}) => {
  const onMountRef = useRef(filter.onMount)
  const { valueKey = 'value', labelKey = 'label', options, filterKey, type } = filter

  useEffect(() => {
    if (onMountRef.current) {
      onMountRef.current()
    }
  }, [])

  useEffect(() => {
    if (options?.length && optionsMapRef.current) {
      optionsMapRef.current.set(
        filterKey,
        new Map(
          options.map<[any, string]>((option) =>
            processOption(option, { valueKey, labelKey, type })
          )
        )
      )
    }
  }, [options, valueKey, labelKey, optionsMapRef, filterKey, type])

  return children
}

const FilterItemContent = ({
  filter,
  onChange,
  filterValue,
  optionsMapRef
}: {
  filterValue: any
  filter: TFilterItemContent
  optionsMapRef: RefObject<TOptionsMap>
  onChange: (key: string, newValue: TValue | TValue[]) => void
}) => {
  const { isDesktop } = useAdaptiveLayout()

  let content: ReactElement | null

  switch (filter.type) {
    case EFilterTypes.DATE: {
      const { filterKey, type, ...rest } = filter

      content = (
        <RangeDatePicker
          {...rest}
          value={filterValue ?? ''}
          inputReadOnly={!isDesktop}
          onChange={(value) => onChange(filterKey, value)}
        />
      )
      break
    }
    case EFilterTypes.SELECT: {
      content = (
        <Select
          value={filterValue ?? []}
          menuPlacement="top"
          onChange={((value: TValue) => onChange(filter.filterKey, value)) as any}
          {...filter}
        />
      )
      break
    }
    case EFilterTypes.RADIO: {
      content = (
        <Radio.Group
          value={filterValue}
          className={styles.radio}
          defaultValue={filterValue}
          onChange={({ target }) => onChange(filter.filterKey, target.value)}
        >
          {filter.options.map((item) => (
            <Radio key={item.key} value={item.key} className={styles.radioItem}>
              {capitalize(String(item.label))}
            </Radio>
          ))}
        </Radio.Group>
      )
      break
    }
    default: {
      content = (
        <CheckableList
          value={filterValue ?? []}
          items={filter.options}
          withSelectAll={filter.withSelectAll}
          labelKey={filter.labelKey}
          valueKey={filter.valueKey}
          onChange={(value) => onChange(filter.filterKey, value)}
          {...filter}
        />
      )
    }
  }

  return (
    <FilterItem optionsMapRef={optionsMapRef} filter={filter}>
      <>{content}</>
    </FilterItem>
  )
}

export const useFilters = (props: TFiltersProps) => {
  const {
    filters,
    value,
    popover,
    buttonVariant,
    buttonDisabled,
    classes,
    innerPopoverProps,
    tooltip,
    addonAfter,
    notCollapsedDivider,
    shouldHideAppliedFilter,
    onChange,
    onReset,
    selectedFilter = [],
    collapse = true
  } = props

  const { open, close, toggle } = useDialog()

  const optionsMapRef = useRef<TOptionsMap>(new Map())

  const { t } = useTranslation()

  const hasAppliedFilter =
    !shouldHideAppliedFilter &&
    Object.keys(value).some((filterKey) => {
      const filterValue = value[filterKey]

      return (
        filterValue !== null &&
        filterValue !== undefined &&
        (Array.isArray(filterValue) ? filterValue.length > 0 : true)
      )
    })
  const sectionKeys = filters.map(({ sectionKey }) => sectionKey)
  const sectionKeysString = sectionKeys.join('')
  const isCollapsible = collapse && filters.length > 1

  const collapsePanelKey = useMemo(
    () => Date.now(),
    // `sectionKeysString` is required
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sectionKeysString]
  )

  const filtersContent = useMemo(
    () => (
      <>
        {filters.map((filter) => {
          const itemContent = filter.items.map((filterIem, index) => (
            <div key={index} className={styles.block}>
              <FilterItemContent
                onChange={onChange}
                optionsMapRef={optionsMapRef}
                filter={filterIem}
                filterValue={value[filterIem.filterKey]}
              />
            </div>
          ))

          return !isCollapsible || !filter.title ? (
            <div
              key={filter.sectionKey}
              className={cls(
                styles.itemWrapper,
                {
                  [styles.notCollapsedDivider]: notCollapsedDivider,
                  [styles.titleLess]: !filter.title
                },
                classes?.itemWrapper
              )}
            >
              {filter.title && <strong className={styles.title}>{filter.title}</strong>}
              {itemContent}
            </div>
          ) : (
            <CollapsePanel
              key={filter.sectionKey}
              className={cls(styles.collapsePanel, {
                [styles.select]: filter.items.some((item) => item.type === EFilterTypes.SELECT)
              })}
              header={<span className={styles.panelTitle}>{filter.title}</span>}
            >
              {itemContent}
            </CollapsePanel>
          )
        })}

        {popover && (
          <Button
            variant="inline"
            disabled={!hasAppliedFilter}
            onClick={onReset}
            className={styles.popoverReset}
          >
            {t('common.filters.resetButton')}
          </Button>
        )}
      </>
    ),
    [
      classes?.itemWrapper,
      filters,
      hasAppliedFilter,
      isCollapsible,
      notCollapsedDivider,
      onChange,
      onReset,
      popover,
      value,
      t
    ]
  )

  const content = useMemo(() => {
    if (!filters.length && addonAfter) {
      return <>{addonAfter}</>
    }

    return filters.length ? (
      <>
        {(popover && filters.length === 1) || !collapse ? (
          filtersContent
        ) : (
          <Collapse
            accordion={false}
            key={collapsePanelKey}
            defaultActiveKey={sectionKeys}
            className={styles.collapse}
          >
            {filtersContent}
          </Collapse>
        )}

        {addonAfter}
      </>
    ) : null
  }, [addonAfter, collapse, collapsePanelKey, filters, filtersContent, popover, sectionKeys])

  const filtersComponent = useMemo(() => {
    if (popover) {
      return (
        <Popover
          arrow={false}
          trigger="click"
          placement="bottomRight"
          {...innerPopoverProps}
          content={content}
          visible={open}
          onVisibleChange={buttonDisabled ? undefined : toggle}
          overlayClassName={cls(styles.popover, classes?.popover)}
        >
          <FilterButton
            appliedFilter={hasAppliedFilter}
            variant={buttonVariant}
            disabled={buttonDisabled}
            tooltip={tooltip}
            className={classes?.filterButton}
          />
        </Popover>
      )
    }

    return content ? (
      <>
        <FilterButton
          appliedFilter={hasAppliedFilter}
          onClick={toggle}
          variant={buttonVariant}
          disabled={buttonDisabled}
          className={classes?.filterButton}
        />
        {filters && (
          <Drawer
            title={t('common.filters.drawer.title')}
            onClose={close}
            visible={open}
            footerActions={<DrawerFilterActions resetFilter={onReset} onDrawerClose={close} />}
          >
            {content}
          </Drawer>
        )}
      </>
    ) : null
  }, [
    buttonDisabled,
    buttonVariant,
    classes?.filterButton,
    classes?.popover,
    close,
    content,
    filters,
    hasAppliedFilter,
    innerPopoverProps,
    onReset,
    open,
    popover,
    toggle,
    tooltip,
    t
  ])

  const selectedFilterValues = useMemo(
    () =>
      filters
        .reduce<{ sectionKey: string }[]>(
          (res, filterItem) => [
            ...res,
            ...filterItem.items
              .filter(({ filterKey }) => !!filterKey)
              .map(({ filterKey }) => ({
                sectionKey: filterKey
              }))
          ],
          []
        )
        .reduce<TSelectedFilter[]>((res, filterItem) => {
          const { sectionKey: key } = filterItem
          const itemValue = value[key]

          if (!itemValue) {
            return [...res]
          }

          const section = optionsMapRef.current.get(key)

          return [
            ...res,
            ...(Array.isArray(itemValue)
              ? itemValue.map((filterItemValue) => ({
                  id: `${key}-${filterItemValue as string}`,
                  name: capitalize(
                    section && filterItemValue !== undefined
                      ? section.get(filterItemValue as string | number)
                      : (filterItemValue as string)
                  )
                }))
              : [{ id: `${key}-${itemValue as string}`, name: capitalize(itemValue as string) }])
          ]
        }, []),
    [filters, value]
  )

  const selectedValues = [...selectedFilterValues, ...selectedFilter]

  const selectedFilters = selectedValues.length ? (
    <SelectedFilters filters={selectedValues} onClear={onReset} />
  ) : undefined

  return { content, filters: filtersComponent, selectedFilters, hasAppliedFilter }
}
