import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation, Trans } from 'react-i18next'

import { EAccountsSelectType } from '../AccountsSelect'
import { Help } from '../../Help'
import { Alert } from '../../Alert'

import styles from './AccountsSelectRadioGroup.module.scss'

type TAccountsSelectRadioGroupProps = {
  type: EAccountsSelectType
  onChange: (value: EAccountsSelectType) => void
}

export const AccountsSelectRadioGroup = ({ type, onChange }: TAccountsSelectRadioGroupProps) => {
  const { t } = useTranslation()

  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value)
  }

  return (
    <div className={styles.root}>
      <Alert variant="info" size="sm">
        {t('modal.accountsSelect.typeRadioAlert')}
      </Alert>

      <Radio.Group value={type} className={styles.radio} onChange={handleChange}>
        <Radio value={EAccountsSelectType.BY_USER}>
          <Trans
            t={t}
            i18nKey="modal.accountsSelect.typeRadioOption_byUser"
            components={{
              help: <Help content={t('modal.accountsSelect.typeRadioOptionHelp_byUser')} />
            }}
          />
        </Radio>
        <Radio value={EAccountsSelectType.BY_GROUP}>
          <Trans
            t={t}
            i18nKey="modal.accountsSelect.typeRadioOption_byGroup"
            components={{
              help: <Help content={t('modal.accountsSelect.typeRadioOptionHelp_byGroup')} />
            }}
          />
        </Radio>
      </Radio.Group>
    </div>
  )
}
