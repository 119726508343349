import { useEffect } from 'react'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { TActiveSubscriptionsListContainerProps, ActiveSubscriptionContainer } from 'App/containers'
import { generateRandomArray } from 'utils'

import { ScrollToEdgeButton } from '../../common/ScrollToEdgeButton'
import { SubscriptionPlans } from '../SubscriptionPlans'

import styles from './ActiveSubscriptionsList.module.scss'

type TActiveSubscriptionsListProps = TActiveSubscriptionsListContainerProps

const DEFAULT_SUBSCRIPTIONS_LENGTH = 1

export const ActiveSubscriptionsList = ({
  ids,
  loading,
  getActiveSubscriptions,
  resetSubscriptions
}: TActiveSubscriptionsListProps) => {
  useEffect(() => {
    getActiveSubscriptions()
  }, [getActiveSubscriptions])

  useEffect(
    () => () => {
      resetSubscriptions()
    },
    [resetSubscriptions]
  )

  const subscriptionsIds = loading ? generateRandomArray(DEFAULT_SUBSCRIPTIONS_LENGTH) : ids

  return (
    <>
      {subscriptionsIds.map((id) => (
        <div className={styles.subscription} key={id}>
          <ActiveSubscriptionContainer id={id as SubscriptionIdsEnum} />
        </div>
      ))}
      <SubscriptionPlans />
      <ScrollToEdgeButton />
    </>
  )
}
