import { useState } from 'react'
import Draft, { ContentState, EditorState } from 'draft-js'

import { useAdaptiveLayout } from 'App/hooks'
import { EditorCommandHandlerState, EditorCommands, EKeyCodes } from 'enums'

export type TUseDraftEditor = {
  draftMessage: string | ContentState
}

export const useDraftEditor = ({ draftMessage }: TUseDraftEditor) => {
  const { isDesktop } = useAdaptiveLayout()

  const [editorState, setEditorState] = useState<EditorState>(
    draftMessage instanceof ContentState
      ? EditorState.moveSelectionToEnd(EditorState.createWithContent(draftMessage))
      : EditorState.moveFocusToEnd(
          EditorState.push(
            EditorState.createEmpty(),
            ContentState.createFromText(draftMessage),
            'insert-characters'
          )
        )
  )

  const plainText = editorState.getCurrentContent().getPlainText()

  const setEditorStateText = (text: string) => {
    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText(text),
      'insert-characters'
    )
    // moving caret to the end of input after clearing editor state
    // without this, after clearing state and inserting one char, caret will be moved to start
    setEditorState(EditorState.moveFocusToEnd(newEditorState))
  }

  const baseKeyCommandHandler = (callback?: (command: string) => void) => (command: string) => {
    if (command !== EditorCommands.ENTER) {
      return EditorCommandHandlerState.NOT_HANDLED
    }

    if (!isDesktop) {
      setEditorStateText(plainText + '\n')
      return EditorCommandHandlerState.HANDLED
    }

    if (callback) {
      callback(command)
    }
    return EditorCommandHandlerState.HANDLED
  }

  const baseKeyBindingHandler =
    (callback?: (e: React.KeyboardEvent<HTMLElement>) => void) =>
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === EKeyCodes.ENTER && !e.nativeEvent.shiftKey) {
        return EditorCommands.ENTER
      }

      if (callback) {
        callback(e)
      }
      return Draft.getDefaultKeyBinding(e)
    }

  return {
    plainText,
    editorState,
    setEditorState,
    setEditorStateText,
    baseKeyCommandHandler,
    baseKeyBindingHandler
  }
}
