import { FC } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction, setSearchContactsFilter } from 'store'
import { useAdaptiveLayout } from 'App/hooks'
import { EModalComponents } from 'App/containers'
import { Button, ListCounter } from 'App/components'
import { ReactComponent as EnvelopIcon } from 'assets/icons/Email.svg'

import styles from './ContactHeaderList.module.scss'

export type TContactHeaderListProps = {
  title?: string

  hideBroadcast?: boolean
  accountType?: AccountTypeNames
} & ReturnType<typeof mapDispatchToProps>

const ContactHeaderListView: FC<TContactHeaderListProps> = ({
  hideBroadcast,
  title,
  accountType,
  showModal
}) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const onBroadcastMessage = () => {
    showModal({
      modalTitle: t('modal.broadcastMessage.title'),
      modalType: EModalComponents.BROADCAST_MESSAGE_DIALOG,
      modalProps: {
        recipientsType: 'contacts',
        accountType
      }
    })
  }

  if (!title && hideBroadcast) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        {title && <ListCounter text={title} />}
        <div className={styles.actions}>
          {!hideBroadcast && (
            <Button
              size="sm"
              variant="outlined"
              className={styles.button}
              onClick={onBroadcastMessage}
            >
              {isMobile ? <EnvelopIcon /> : t('contacts.list.broadcastMessageButton')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSearch: setSearchContactsFilter,
      showModal: showModalAction
    },
    dispatch
  )

export const ContactHeaderList = connect(null, mapDispatchToProps)(ContactHeaderListView)
