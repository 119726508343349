import { Skeleton } from 'antd'

import styles from './NotificationsPopoverSkeleton.module.scss'

export const NotificationsPopoverSkeleton = () => (
  <Skeleton
    paragraph={{ rows: 3 }}
    avatar={true}
    active={true}
    loading={true}
    className={styles.root}
  />
)
