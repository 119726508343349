import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store/errors'
import {
  DISCARD_FILE_SHARED_PERMISSION,
  discardFileSharedPermissionRequest
} from 'store/files/sharedFiles'
import {
  FileDiscardPermissionsConfirm,
  FileDiscardPermissionsConfirmOwnProps
} from 'App/components/File/FileDiscardPermissionsConfirm'

export type TFileDiscardPermissionsConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingSelector([DISCARD_FILE_SHARED_PERMISSION])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[DISCARD_FILE_SHARED_PERMISSION]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      discardFilePermissions: discardFileSharedPermissionRequest,
      removeErrorNotification: () => removeErrorNotification(DISCARD_FILE_SHARED_PERMISSION)
    },
    dispatch
  )

export const FileDiscardPermissionsConfirmContainer: FC<FileDiscardPermissionsConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(FileDiscardPermissionsConfirm)
