import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getChatMembers } from 'api/chats'
import { InfiniteScroll } from 'App/components/common'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types/pagination'
import { accountIdSelector, useAppSelector } from 'store'
import { CHAT_DETAILS_ID } from 'App/containers'
import { useAccumulate, useAdaptiveLayout } from 'App/hooks'

import { ChatRoomInfoUserItem } from '../CaseChatMembers/components/ChatRoomInfoUserItem'
import { Skeleton } from '../CaseChatMembers/components/Skeleton'

import styles from './GroupChatMembers.module.scss'

export type TGroupChatMembersProps = {
  chatId: string
  ownerAccountId?: string
  setTabMembersTotal: (total: number) => void
}

const CHAT_MEMBERS_QUERY_KEY = 'chat_members_query_key'

export const GroupChatMembers = ({
  chatId,
  ownerAccountId,
  setTabMembersTotal
}: TGroupChatMembersProps) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)

  const { isDesktop } = useAdaptiveLayout()

  const accountId = useAppSelector(accountIdSelector)

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [CHAT_MEMBERS_QUERY_KEY],
    queryFn: () =>
      getChatMembers({
        chatId,
        page
      }),
    cacheTime: 0,
    onSuccess: ({ total }) => {
      setTabMembersTotal(total)
    }
  })

  const items = useAccumulate({ items: data?.results, page })

  useEffect(() => {
    refetch()
    // `page` - is essential
  }, [refetch, page])

  return isLoading ? (
    <Skeleton />
  ) : (
    <div className={styles.root}>
      <InfiniteScroll
        page={page}
        next={setPage}
        loading={isFetching}
        total={data?.total ?? 0}
        limit={PAGINATION_DEFAULT_SHOW_BY}
        dataLength={items.length}
        className={styles.infiniteScroll}
        scrollableTarget={isDesktop && CHAT_DETAILS_ID}
      >
        {items.map((account) => {
          const isOwner = account?.id === ownerAccountId

          return (
            <ChatRoomInfoUserItem
              key={account.id}
              member={account}
              accountId={accountId}
              isOnline={account.online}
              chipList={
                isOwner
                  ? [
                      {
                        text: t('chat.details.ownerChip'),
                        variant: 'square',
                        color: 'primary-dark'
                      }
                    ]
                  : undefined
              }
            />
          )
        })}
      </InfiniteScroll>
    </div>
  )
}
