import { useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useAppSelector } from 'store'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'

import { useKebabMenu } from '../useKebabMenu'

type TChatRoomProps = {
  chatRoomId: string
  organizationId: string
} & ReturnType<typeof useKebabMenu>

export const ChatRoom = ({ chatRoomId, organizationId, getMenuItems }: TChatRoomProps) => {
  const { url } = useRouteMatch()
  const roomRef = useRef<HTMLDivElement>(null)

  const { name, channelManager, mutedUntil, isPublic, inviteToken, departments } =
    useAppSelector((state) => state.chat.chatRooms.list[chatRoomId]) ?? {}
  const { count, mentionCount } = useAppSelector(
    (state) =>
      state.notifications.general.organizations.list?.[organizationId]?.chats?.[chatRoomId] ?? {}
  )

  const isMuted = Boolean(mutedUntil)

  const handleEditSuccess = () =>
    roomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  if (!name) {
    return null
  }

  return (
    <UnityHubRoomNavLink
      to={`${url}/chat/${chatRoomId}`}
      icon={<UnityHubChannelIcon variant="chat" isMuted={isMuted} />}
      name={name}
      isMuted={isMuted}
      count={count}
      mentionCount={mentionCount}
      ref={roomRef}
      isPublic={isPublic}
      menuItems={getMenuItems({
        variant: 'chat',
        channelId: chatRoomId,
        channelName: name,
        isPublic,
        channelManagerId: channelManager?.id,
        isMuted,
        isGroup: !!departments?.length,
        inviteToken,
        onEditSuccess: handleEditSuccess
      })}
    />
  )
}
