import 'emoji-mart/css/emoji-mart.css'
import emojiTwitterData from 'emoji-mart/data/twitter.json'
import createEmojiMartPlugin from 'draft-js-emoji-mart-plugin'
import { EmojiData, PickerProps, BaseEmoji, CustomEmoji, store as emojiMartStore } from 'emoji-mart'
import { useCallback } from 'react'

import { useIsMounted } from 'App/hooks'
import { getCssPropertyValue } from 'utils'

import { filterEmoji } from './EmojiPicker.utils'

export const emojiPlugin = createEmojiMartPlugin({
  data: emojiTwitterData,
  set: 'twitter'
})

export const { Picker, Emoji } = emojiPlugin

export type TEmojiPickerProps = Omit<PickerProps, 'onSelect' | 'emojisToShowFilter'> & {
  onSelect: (emoji: string) => void
}

export type EmojiProps = {
  added_in: string
  unified: string
} & EmojiData

const DEFAULT_FREQUENTLY_LIST = {
  '+1': 6,
  heart: 5,
  smile: 4,
  thinking_face: 3,
  clap: 2,
  '-1': 1
}

export const EmojiPicker = ({
  onSelect,
  exclude = ['flags'],
  color = getCssPropertyValue('--palette-secondary-light') || '#3299BF',
  showPreview = false,
  showSkinTones = false,
  defaultSkin = 1,
  ...rest
}: TEmojiPickerProps) => {
  const isMounted = useIsMounted()

  const frequentlyState = emojiMartStore.get('frequently')

  if (!frequentlyState && !isMounted.current) {
    emojiMartStore.set('frequently', DEFAULT_FREQUENTLY_LIST)
  }

  const handleSelect = useCallback(
    (emojiSymbol: EmojiData) => {
      let emoji = ''
      const baseEmojiSymbol = emojiSymbol as BaseEmoji
      const customEmojiSymbol = emojiSymbol as CustomEmoji

      if (baseEmojiSymbol.native) {
        emoji = baseEmojiSymbol.native
      }

      if (!emoji && customEmojiSymbol.short_names && customEmojiSymbol.short_names.length > 0) {
        // Custom emoji case
        emoji = `:${customEmojiSymbol.short_names[0]}:`
      }

      onSelect(emoji)
    },
    [onSelect]
  )

  return (
    <Picker
      {...rest}
      showPreview={showPreview}
      showSkinTones={showSkinTones}
      defaultSkin={defaultSkin}
      exclude={exclude}
      color={color}
      emojisToShowFilter={filterEmoji}
      onSelect={handleSelect}
    />
  )
}
