import { useCallback, useMemo } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'
import { SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EModalComponents, TActiveSubscriptionContainerProps } from 'App/containers'
import { SubscriptionIcon, SubscriptionPrice, Button, DetailList } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { getMapComponent } from 'utils'
import { SUBSCRIPTION_NAME_MAP } from 'globalConstants'
import { BasicSubscriptionOption } from 'App/components/common/SubscriptionInfoCard/BasicSubscriptionOption'

import { useSubscriptionDetails } from '../useSubscriptionDetails'

import { ExpirationDate } from './ExpirationDate'
import { GracePeriodDate } from './GracePeriodDate'
import styles from './ActiveSubscription.module.scss'

export type TActiveSubscriptionProps = TActiveSubscriptionContainerProps

export const ActiveSubscription = ({
  price,
  duration,
  medCloudCapacity,
  loading,
  autoRenewal,
  endsOn,
  businessAdminLimit,
  isBusinessAccount,
  id,
  graceOn,
  showModal,
  activateSubscriptions
}: TActiveSubscriptionProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleShowModal = useCallback(
    () =>
      showModal({
        modalType: EModalComponents.CHANGE_SUBSCRIPTION_PLAN_DIALOG,
        modalTitle: t('modal.changeSubscriptionPlan.title'),
        modalWidth: 700
      }),
    [showModal, t]
  )

  const handleShowRenewModal = useCallback(
    () =>
      showModal({
        modalTitle: t('modal.renewSubscriptionPlan.title'),
        modalType: EModalComponents.RENEW_SUBSCRIPTION_PLAN_DIALOG,
        modalProps: {
          id,
          onConfirm: () => activateSubscriptions({ processingId: id, subscriptionId: id })
        }
      }),
    [id, activateSubscriptions, showModal, t]
  )

  const generateDetailsList = useSubscriptionDetails(id, medCloudCapacity, businessAdminLimit)

  const isBasic =
    id === SubscriptionIdsEnum.BASIC_PROFESSIONAL || id === SubscriptionIdsEnum.BASIC_BUSINESS
  const name = getMapComponent(SUBSCRIPTION_NAME_MAP, id)
  const shouldShowExpirationDate = !isBasic && !graceOn

  const button = useMemo(() => {
    if (!autoRenewal && !isBasic) {
      return (
        <Button onClick={handleShowRenewModal} size="sm" className={styles.button}>
          {t('subscriptions.activeSubscription.renewButton')}
        </Button>
      )
    }

    return (
      <Button
        onClick={handleShowModal}
        size="sm"
        className={styles.button}
        variant={!isBasic && autoRenewal ? 'outlined' : 'primary'}
      >
        {!isBasic && autoRenewal
          ? t('subscriptions.activeSubscription.changeToBasicButton')
          : t('subscriptions.activeSubscription.upgradeButton')}
      </Button>
    )
  }, [autoRenewal, handleShowModal, handleShowRenewModal, isBasic, t])

  const date = (
    <div className={styles.date}>
      {!!graceOn && (
        <GracePeriodDate graceOn={new Date() || graceOn} endsOn={new Date() || endsOn} />
      )}

      {shouldShowExpirationDate && <ExpirationDate endsOn={endsOn} autoRenewal={autoRenewal} />}
    </div>
  )

  if (!name) {
    return null
  }

  return (
    <div className={styles.root}>
      <Skeleton
        active={true}
        loading={loading}
        avatar={true}
        title={false}
        paragraph={{ rows: 7 }}
        className={cls(styles.skeleton, { [styles.withoutButton]: isBusinessAccount })}
      >
        <div className={styles.header}>
          <SubscriptionIcon
            id={id}
            classes={{
              root: styles.iconRoot,
              icon: styles.icon
            }}
          />

          <div className={styles.headerContainer}>
            <div className={styles.titleWrapper}>
              {name && (
                <div className={styles.title}>{t('subscriptions.planLabel', { plan: name })}</div>
              )}
              {isMobile && (
                <SubscriptionPrice
                  price={price}
                  duration={duration}
                  classes={{ root: styles.priceRoot, price: styles.price }}
                />
              )}

              {!isMobile && date}
            </div>

            {!isMobile && button}
          </div>

          {!isMobile && (
            <SubscriptionPrice
              price={price}
              duration={duration}
              classes={{ root: styles.priceRoot }}
            />
          )}
        </div>

        {isMobile && date}
        {isMobile && button}

        <DetailList
          variant={isMobile ? 'column' : 'row'}
          list={generateDetailsList}
          addonBefore={!isBasic && <BasicSubscriptionOption />}
        />
      </Skeleton>
    </div>
  )
}
