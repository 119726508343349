import { ReactNode, SVGProps, useMemo } from 'react'
import cls from 'classnames'

import { getExtension } from 'utils'
import { getFileIcon } from 'utils/files'
import { WHITELIST_FILES } from 'globalConstants'

import styles from './FileIcon.module.scss'

export type TFileIconProps = {
  extension: string

  className?: string
  disabled?: boolean
  subIcon?: ReactNode | SVGProps<SVGSVGElement>
}

export const FileIcon = ({ disabled, subIcon, extension = '', className = '' }: TFileIconProps) => {
  const fileExtension = useMemo(
    () => (getExtension(extension, true) || extension).toLowerCase(),
    [extension]
  )
  const Icon = getFileIcon(extension)
  const invalid = !WHITELIST_FILES.includes(fileExtension)

  return (
    <div
      className={cls({
        fileIcon: true,
        [styles.fileIcon]: true,
        [fileExtension]: true && !invalid,
        [className]: Boolean(className),
        [styles.disabled]: disabled,
        [styles.invalid]: invalid,
        [styles.fileIconSubIcon]: Boolean(subIcon)
      })}
    >
      <Icon />
      {subIcon && <div className={styles.subIcon}>{subIcon}</div>}
    </div>
  )
}
