import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  activateSubscriptionsRequest,
  createLoadingSelector,
  GET_ACTIVE_SUBSCRIPTIONS,
  isBusinessAccountSelector,
  showModalAction
} from 'store'
import { ActiveSubscription } from 'App/components'

type TActiveSubscriptionContainerBaseProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type TActiveSubscriptionContainerOwnProps = {
  id: SubscriptionIdsEnum
}

export type TActiveSubscriptionContainerProps = TActiveSubscriptionContainerBaseProps &
  TActiveSubscriptionContainerOwnProps

const loadingSelector = createLoadingSelector([GET_ACTIVE_SUBSCRIPTIONS])

const mapStateToProps = (state: State, { id }: TActiveSubscriptionContainerOwnProps) => {
  const { subscription, endsOn, autoRenewal, graceOn } = state.subscriptions.active.list[id] ?? {}
  const { price, duration, medCloudCapacity, businessAdminLimit } = subscription ?? {
    medCloudCapacity: '0'
  }

  return {
    graceOn,
    price,
    duration,
    medCloudCapacity,
    businessAdminLimit,
    endsOn,
    autoRenewal,
    loading: loadingSelector(state),
    isBusinessAccount: isBusinessAccountSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      activateSubscriptions: activateSubscriptionsRequest
    },
    dispatch
  )

export const ActiveSubscriptionContainer: FC<TActiveSubscriptionContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveSubscription)
