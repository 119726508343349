import { useCallback, useEffect, useMemo, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import cls from 'classnames'
import { Skeleton, SkeletonProps } from 'antd'
import { v4 as uuidV4 } from 'uuid'
import sumBy from 'lodash/sumBy'
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor
} from '@dnd-kit/core'
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { restrictToParentElement } from '@dnd-kit/modifiers'

import { Button } from 'App/components'
import {
  MAX_SHOWCASE_FILES_SIZE,
  MAX_SHOWCASE_FILES_AMOUNT,
  TOUCH_SENSOR_TOLERANCE,
  TOUCH_SENSOR_DELAY
} from 'globalConstants'
import { IFilesEntity } from 'interfaces'
import { getMapComponent } from 'utils'
import { ReactComponent as ImagePlaceholderIcon } from 'assets/images/ImagePlaceholder.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { handleError } from 'api/utils'

import { FileWrapper } from '../FileWrapper'
import { FilesHeader } from '../FilesHeader'
import { useVideoStreaming } from '../../useVideoStreaming'

import styles from './Files.module.scss'
import {
  ACCEPTED_IMAGES_EXTENSIONS,
  ACCEPTED_VIDEOS_EXTENSIONS,
  ERROR_MESSAGES_MAP,
  getUploadedFiles,
  TShowcaseFile
} from './Files.utils'

type TFilesProps = NonNullableBy<SkeletonProps, 'loading'> & {
  showcaseId: string
  setSubmitError: (error: string | boolean) => void
  setFilesLoading: (lading: boolean) => void
  onChange: (files: TShowcaseFile[], update?: boolean) => void

  initialFiles?: TShowcaseFile[]
}

export const Files = ({
  loading,
  initialFiles,
  showcaseId,
  onChange,
  setSubmitError,
  setFilesLoading
}: TFilesProps) => {
  const [files, setFiles] = useState<TShowcaseFile[]>([])
  const [failedFileIds, setFailedFileIds] = useState<string[]>([])

  const uploadedFiles = useMemo(() => getUploadedFiles(files), [files])

  useEffect(() => {
    initialFiles && setFiles(initialFiles)
  }, [initialFiles])

  useEffect(() => {
    const failedFiles = failedFileIds.length

    if (failedFiles) {
      return setSubmitError(
        `You have ${failedFiles} failed ${failedFiles === 1 ? 'file' : 'files'}`
      )
    }

    if (!uploadedFiles.length) {
      return setSubmitError(true)
    }

    setSubmitError(false)
  }, [failedFileIds.length, files.length, setSubmitError, uploadedFiles.length])

  useEffect(() => {
    if (files.length > uploadedFiles.length) {
      return setFilesLoading(true)
    }

    setFilesLoading(false)
  }, [files.length, setFilesLoading, uploadedFiles.length])

  const uploadedSize = useMemo(
    () => sumBy(uploadedFiles, ({ file }) => Number(file.fileSize)),
    [uploadedFiles]
  )

  const maxSize = MAX_SHOWCASE_FILES_SIZE - uploadedSize
  const maxFiles = MAX_SHOWCASE_FILES_AMOUNT - uploadedFiles.length

  const shouldShowPlaceholder = !files.length && !loading
  const shouldShowFilesList = !!files.length && !loading

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: TOUCH_SENSOR_DELAY,
        tolerance: TOUCH_SENSOR_TOLERANCE
      }
    })
  )

  const sortableItems = useMemo(() => files.map(({ uuid }) => uuid), [files])

  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        handleError({
          message:
            getMapComponent(ERROR_MESSAGES_MAP, rejectedFiles[0].errors[0].code, {
              maxSize,
              file: rejectedFiles[0].file
            }) ?? rejectedFiles[0].errors[0].message
        })
      } else {
        setFiles([...files, ...acceptedFiles.map((file) => ({ file, uuid: uuidV4() }))])
      }
    },
    [files, maxSize]
  )

  const handleSetFileError = useCallback((id: string) => {
    setFailedFileIds((prev) => [...prev, id])
  }, [])

  const handleFilterFileError = useCallback((id: string) => {
    setFailedFileIds((prev) => prev.filter((i) => i !== id))
  }, [])

  const handleDelete = useCallback(
    (uuid: string, update?: boolean) => {
      let prevOrderNumber = 0

      setFiles((prev) => {
        const newFiles = prev
          .filter((data) => data.uuid !== uuid)
          .map((item) => {
            if (item.orderNumber) {
              prevOrderNumber++

              return { ...item, orderNumber: prevOrderNumber }
            }

            return item
          })

        onChange(newFiles, update)

        return newFiles
      })
      handleFilterFileError(uuid)
    },
    [handleFilterFileError, onChange]
  )

  const handleFileUpload = useCallback(
    (file: IFilesEntity, uuid: string) => {
      let prevOrderNumber = 0

      setFiles((prev) => {
        const newFiles = prev.map((item) => {
          if (item.orderNumber) {
            prevOrderNumber++

            return { ...item, orderNumber: prevOrderNumber }
          }

          if (item.uuid === uuid) {
            prevOrderNumber++

            return { file, uuid, orderNumber: prevOrderNumber }
          }

          return item
        })

        onChange(newFiles, false)

        return newFiles
      })
      handleFilterFileError(uuid)
    },
    [handleFilterFileError, onChange]
  )

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = files.findIndex(({ uuid }) => uuid === active.id)
        const newIndex = files.findIndex(({ uuid }) => uuid === over?.id)

        let prevOrderNumber = 0

        const updatedFilesList = arrayMove(files.slice(), oldIndex, newIndex).map((item) => {
          if (item.orderNumber) {
            prevOrderNumber++

            return { ...item, orderNumber: prevOrderNumber }
          }

          return item
        })

        setFiles(updatedFilesList)
        onChange(updatedFilesList)
      }
    },
    [files, onChange]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: [...ACCEPTED_IMAGES_EXTENSIONS, ...ACCEPTED_VIDEOS_EXTENSIONS],
    onDrop: handleDrop,
    noClick: !!files.length,
    noKeyboard: true,
    maxSize,
    maxFiles,
    disabled: loading
  })

  const slides = useMemo(() => files.map(({ file }) => file as IFilesEntity), [files])

  useVideoStreaming(slides)

  return (
    <div>
      <FilesHeader
        amount={uploadedFiles.length}
        size={uploadedSize}
        loading={loading}
        onAttachFiles={open}
      />
      <div className={cls(styles.root, { [styles.dragAndDrop]: isDragActive })} {...getRootProps()}>
        <input {...getInputProps()} />

        {shouldShowPlaceholder ? (
          <div className={styles.placeholder}>
            <ImagePlaceholderIcon className={styles.image} />
            <Button className={styles.button} variant="text" icon={<PlusIcon />}>
              Add Photos or Videos
            </Button>
          </div>
        ) : (
          <Skeleton
            loading={loading}
            active={true}
            title={false}
            className={styles.skeleton}
            paragraph={{ rows: 6 }}
          />
        )}

        {shouldShowFilesList && (
          <div className={styles.list}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              modifiers={[restrictToParentElement]}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                disabled={!uploadedFiles.length}
                items={sortableItems}
                strategy={rectSortingStrategy}
              >
                {files.map(({ file, uuid, orderNumber }) => (
                  <FileWrapper
                    key={uuid}
                    file={file}
                    uuid={uuid}
                    files={slides}
                    orderNumber={orderNumber}
                    showcaseId={showcaseId}
                    onDelete={handleDelete}
                    onUploadSuccess={handleFileUpload}
                    onUploadError={handleSetFileError}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </div>
        )}
      </div>
    </div>
  )
}
