import { ReactNode, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import cls from 'classnames'

import { EStorageTaken } from 'enums'
import { formatBytes } from 'utils'
import { ReactComponent as CloudIcon } from 'assets/icons/CloudUpload.svg'

import styles from './CloudWidget.module.scss'

export type TCloudWidgetVariant = 'bin' | 'cloud' | 'menu'

export type TCloudWidget = {
  occupied: number
  available: number
  title: string

  variant?: TCloudWidgetVariant
  linkTo?: string
  icon?: ReactNode
  footerAdornment?: ReactNode
}

export const CloudWidget = ({
  occupied,
  available,
  title,
  linkTo,
  variant = 'cloud',
  icon = <CloudIcon />,
  footerAdornment
}: TCloudWidget) => {
  const { push } = useHistory()
  const handleClick = () => (linkTo ? push(linkTo) : undefined)
  const percent = useMemo(() => Math.floor((occupied / available) * 100), [occupied, available])

  const progressClass = useMemo(() => {
    if (percent >= EStorageTaken.HIGH) {
      return 'high'
    }

    if (percent >= EStorageTaken.MIDDLE && percent < EStorageTaken.HIGH) {
      return 'middle'
    }

    return 'low'
  }, [percent])

  return (
    <div className={cls(styles.root, styles[`${variant}Root`])} onClick={handleClick}>
      <div className={cls(styles.iconWrapper, styles[`${variant}Icon`])}>{icon}</div>

      <div className={cls(styles.content, styles[`${variant}Content`])}>
        <span className={cls(styles.title, styles[`${variant}Title`])}>{title}</span>

        <div className={cls(styles.progress, styles[`${variant}Progress`])}>
          <div
            className={cls(styles.progressBackground, styles[progressClass])}
            style={{
              width: `${percent >= EStorageTaken.FULL ? EStorageTaken.FULL : percent}%`
            }}
          />
        </div>

        <div className={cls(styles.footer, styles[`${variant}Footer`])}>
          <span className={styles[`${variant}Capacity`]}>{`${formatBytes(
            occupied
          )} of ${formatBytes(available)} used`}</span>
          {footerAdornment}
        </div>
      </div>
    </div>
  )
}
