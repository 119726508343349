import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  activateSubscriptionsRequest,
  ACTIVATE_SUBSCRIPTIONS,
  createLoadingSelector,
  createProcessingBySelector,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_AVAILABLE_SUBSCRIPTIONS,
  showModalAction
} from 'store'
import { AvailableSubscription } from 'App/components'

type TAvailableSubscriptionContainerBaseProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type TAvailableSubscriptionContainerOwnProps = {
  id: string

  showIcon?: boolean
  actionLabel?: string
  active?: boolean
  handleChooseSubscription?: (id: SubscriptionIdsEnum) => void
}

export type TAvailableSubscriptionContainerProps = TAvailableSubscriptionContainerBaseProps &
  TAvailableSubscriptionContainerOwnProps

const loadingSelector = createLoadingSelector([
  GET_AVAILABLE_SUBSCRIPTIONS,
  GET_ACTIVE_SUBSCRIPTIONS
])
const processingBySelector = createProcessingBySelector([ACTIVATE_SUBSCRIPTIONS])

const mapStateToProps = (state: State, { id }: TAvailableSubscriptionContainerOwnProps) => ({
  price: state.subscriptions.available.list[id]?.price,
  duration: state.subscriptions.available.list[id]?.duration,
  medCloudCapacity: state.subscriptions.available.list[id]?.medCloudCapacity ?? '0',
  businessAdminLimit: state.subscriptions.available.list[id]?.businessAdminLimit,
  id: state.subscriptions.available.list[id]?.id,
  isFuture: state.subscriptions.available.list[id]?.isFuture,
  isActive: state.subscriptions.available.list[id]?.isActive,
  loading: loadingSelector(state),
  processing: processingBySelector(id)(state),
  startedOn: state.subscriptions.active.list[id]?.startedOn,
  endsOn: state.subscriptions.active.list[id]?.endsOn
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      activateSubscriptions: activateSubscriptionsRequest,
      showModal: showModalAction
    },
    dispatch
  )

export const AvailableSubscriptionContainer: FC<TAvailableSubscriptionContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableSubscription)
