import { useCallback } from 'react'
import { Col, Skeleton } from 'antd'
import cls from 'classnames'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { EFileUploadStatus } from 'enums'
import { stopPropagation } from 'utils'
import { selectAttachFileAction, useAppDispatch, useAppSelector } from 'store'
import { useFileMouseEvents, FileImage, Checkbox, Tooltip, Button } from 'App/components'
import { FileNameTruncatedText } from 'App/components/File/FileNameTruncatedText'
import { useAdaptiveLayout } from 'App/hooks'
import { Status } from 'App/containers/File/FileUploadWidgetItem/components/Status'
import { useFileUploadWidgetItem } from 'App/containers/File/FileUploadWidgetItem/useFileUploadWidgetItem'

import styles from './FileAttachTile.module.scss'

type TFileAttachTileProps = {
  id: string
  loading: boolean
  upload: boolean

  isAttached?: boolean
}

export const FileAttachTile = ({ id, loading, upload, isAttached }: TFileAttachTileProps) => {
  const { isMobile } = useAdaptiveLayout()

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const {
    fileName,
    extension,
    thumbnailUrl,
    isAttached: isAlreadyAttached
  } = useAppSelector((state) => state.medCloud.attachFiles.list[id]) ?? {}
  const selected = useAppSelector((state) =>
    upload
      ? state.medCloud.widget.list[id]?.selected
      : state.medCloud.attachFiles.selectedFiles.includes(id)
  )

  const attached = isAttached || isAlreadyAttached

  const {
    showCancel,
    uploadStatus,
    uploadPercentage,
    message,
    fileName: uploadFileName,
    extension: uploadExtension,
    handleCancel
  } = useFileUploadWidgetItem({ id })

  const showMessage = Boolean(
    message ||
      uploadStatus === EFileUploadStatus.FAIL ||
      uploadStatus === EFileUploadStatus.CANCELLED
  )

  const displayFileName = fileName || uploadFileName
  const displayFileExtension = extension || uploadExtension

  const onSelect = useCallback(() => {
    dispatch(selectAttachFileAction({ fileId: id }))
    return [id]
  }, [dispatch, id])

  const { longPressBind, handleSelect, onClick } = useFileMouseEvents({
    disableSelect: showMessage || attached,
    file: { id },
    openHandlerProps: {
      // An empty array is set because we have "disableOpen:true" file browsing here
      slides: []
    },
    selectMode: true,
    disableOpen: true,
    onSelect,
    onTouch: noop
  })

  return (
    <Col span={isMobile ? 12 : 6}>
      <div
        className={cls(styles.root, {
          [styles.selected]: selected,
          [styles.clickable]: !loading && !message,
          [styles.disabled]: attached
        })}
      >
        {loading ? (
          <>
            <Skeleton.Image className={styles.skeletonImg} />
            <Skeleton paragraph={false} className={styles.skeleton} />
          </>
        ) : (
          <>
            {showMessage ? (
              <Tooltip title={message}>
                <div className={styles.uploadError}>
                  <Status shouldShowLabel={false} status={EFileUploadStatus.FAIL} />
                </div>
              </Tooltip>
            ) : (
              <Checkbox
                classes={{ root: styles.checkbox }}
                checked={selected || attached}
                onChange={handleSelect}
                onClick={stopPropagation}
                disabled={attached}
              />
            )}

            <div className={styles.item} {...longPressBind} onClick={onClick}>
              <div className={styles.imageWrapper}>
                <FileImage
                  variant="icon"
                  size="custom"
                  thumbnailUrl={thumbnailUrl}
                  fileId={id}
                  fileName={displayFileName}
                  extension={message ? '' : displayFileExtension}
                  classes={{
                    root: styles.image,
                    fileIcon: styles.fileIcon,
                    videoStatus: styles.videoStatus
                  }}
                />
                {showCancel && (
                  <div className={styles.cancel}>
                    <Button
                      className={styles.button}
                      fontWeight="semi-bold"
                      variant="text"
                      iconPosition="right"
                      icon={<Status status={uploadStatus} uploadPercentage={uploadPercentage} />}
                      onClick={handleCancel}
                    >
                      {t('modal.attachFile.cancelButton')}
                    </Button>
                  </div>
                )}
              </div>

              <FileNameTruncatedText
                disabled={attached}
                className={styles.fileName}
                extension={displayFileExtension}
                fileName={displayFileName}
                twoLine={true}
              />
            </div>
          </>
        )}
      </div>
    </Col>
  )
}
