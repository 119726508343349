import { PropsWithChildren } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'

import styles from './SectionWrapper.module.scss'

type TSectionWrapperProps = {
  title: string
  loading: boolean

  classes?: Partial<Record<'title', string>>
}
type TSectionWrapper = PropsWithChildren<TSectionWrapperProps>

const SKELETON_ROWS_NUMBER = 4

export const SectionWrapper = ({ children, title, loading, classes }: TSectionWrapper) => {
  const showSection = loading || children

  return showSection ? (
    <Skeleton
      title={true}
      paragraph={{ rows: SKELETON_ROWS_NUMBER, width: '100%' }}
      loading={loading}
      className={styles.skeleton}
    >
      <div className={styles.section}>
        <span className={cls(styles.title, classes?.title)}>{title}</span>
        {children}
      </div>
    </Skeleton>
  ) : null
}
