import { useState } from 'react'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { generateRandomArray } from 'utils'
import { LIST_SKELETON_IDS_LENGTH } from 'globalConstants'
import { getOrganizationDepartments } from 'api/organizations'
import {
  Collapse,
  EEmptyListIconSize,
  EmptyList,
  Pagination,
  Scrollable
} from 'App/components/common'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { ReactComponent as AccountMultipleRemoveIcon } from 'assets/icons/AccountMultipleRemove.svg'

import { StaffTabDepartmentItem } from '../StaffTabDepartmentItem'
import { ORGANIZATION_DEPARTMENTS_QUERY_KEY } from '../StaffTabAccountItem/useKebabMenu'

import styles from './StaffTabDepartments.module.scss'

export type TStaffTabDepartmentsProps = {
  search: string
  organizationId: string
}
const FAKE_ITEMS = generateRandomArray(LIST_SKELETON_IDS_LENGTH)

export const StaffTabDepartments = ({ organizationId, search }: TStaffTabDepartmentsProps) => {
  const [page, setPage] = useState(0)

  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: [ORGANIZATION_DEPARTMENTS_QUERY_KEY, search, page, organizationId],
    queryFn: () =>
      getOrganizationDepartments({
        page,
        search,
        organizationId,
        showBy: PAGINATION_DEFAULT_SHOW_BY
      })
  })

  if (isFetching) {
    return (
      <Scrollable>
        <div className={styles.skeletonWrapper}>
          {FAKE_ITEMS.map((index) => (
            <Skeleton
              key={index}
              paragraph={{ rows: 1 }}
              title={false}
              active={true}
              loading={true}
              className={styles.skeleton}
            />
          ))}
        </div>
      </Scrollable>
    )
  }

  return (
    <Scrollable>
      {data?.results?.length ? (
        <Collapse variant="highlighted" accordion={false} className={styles.collapse}>
          {data.results.map(({ id, name, membersCount, description, leader }) => (
            <StaffTabDepartmentItem
              key={id}
              id={id}
              name={name}
              membersCount={membersCount}
              description={description}
              leader={leader}
              organizationId={organizationId}
            />
          ))}
        </Collapse>
      ) : (
        <EmptyList
          iconSize={EEmptyListIconSize.MD}
          icon={<AccountMultipleRemoveIcon />}
          text={t('organizations.departments.placeholder')}
          hasAppliedSearch={!!search}
        />
      )}

      <Pagination
        current={page}
        onChange={setPage}
        total={data?.total}
        classes={{ container: styles.pagination }}
      />
    </Scrollable>
  )
}
