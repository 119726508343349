import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { ContextMenuContext, MenuItem } from 'App/components/common'
import { ReactComponent as CommentOutlineIcon } from 'assets/icons/CommentOutline.svg'
import { getNotificationsGeneralRequest, useAppDispatch, useAppSelector } from 'store'
import { markAsRead } from 'api'

export type TUnityHubMarkAsReadProps = {
  channelId: string
  entity: 'communities' | 'organizations' | 'events'
  entityId: string
}

export const UnityHubMarkAsRead = ({ channelId, entity, entityId }: TUnityHubMarkAsReadProps) => {
  const dispatch = useAppDispatch()

  const unreadMessages = useAppSelector(
    (state) => state.notifications.general[entity]?.list[entityId]?.chats[channelId]
  )

  const { t } = useTranslation()

  const { mutate, isIdle, reset } = useMutation({
    mutationKey: ['mark-as-read', channelId],
    mutationFn: () => markAsRead(channelId),
    onSuccess: () => {
      dispatch(getNotificationsGeneralRequest())
    }
  })

  useEffect(() => {
    if (unreadMessages) {
      reset()
    }
  }, [reset, unreadMessages])

  return (
    <ContextMenuContext.Consumer>
      {({ close }) => (
        <MenuItem
          onClick={mutate}
          closeAfterClick={close}
          content={t('unityHub.channelsList.kebabMenu.markAsRead')}
          icon={<CommentOutlineIcon />}
          disabled={!isIdle || !unreadMessages}
        />
      )}
    </ContextMenuContext.Consumer>
  )
}
