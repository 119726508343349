import { ReactNode } from 'react'
import cls from 'classnames'
import { Switch as SwitchAnt } from 'antd'
import { SwitchProps } from 'antd/lib/switch'

import styles from './Switch.module.scss'

type TSwitchClasses = 'root'

export type TSwitchProps = SwitchProps &
  PropsWithClasses<
    TSwitchClasses,
    {
      label?: ReactNode
      reverse?: boolean
    }
  >

export const Switch = ({ classes, label, reverse, ...restProps }: TSwitchProps) => (
  <div className={cls(styles.root, { [styles.reverse]: reverse }, classes?.root)}>
    <SwitchAnt {...restProps} />
    {label && <div className={styles.label}>{label}</div>}
  </div>
)
