import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { generateRandomArray } from 'utils'
import { TOGGLE_CASE_LINK } from 'store'
import { EModalComponents, TCaseLinkDialogContainerProps } from 'App/containers'
import { StepsFooterActions, CaseLinkListWrapper } from 'App/components'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './CaseLinkDialog.module.scss'

export type TCaseLinkDialogPropsBase = {
  caseId: string
}

export type TCaseLinkDialogProps = TCaseLinkDialogContainerProps & TCaseLinkDialogPropsBase

const DEFAULT_CASE_LENGTH = 3

export const CaseLinkDialog = ({
  search,
  searchBy,
  loading,
  ids,
  caseId,
  error,
  humanReadableId,
  title,
  classifications = [],
  roles = [],
  statuses = [],
  getLinkCaseList,
  resetLinkCaseList,
  removeErrorNotification,
  showModal,
  resetLinkCaseListFilter,
  setLinkCaseListFilter,
  setLinkCaseListSearch
}: TCaseLinkDialogProps) => {
  const { t } = useTranslation()

  const caseIdList = loading ? generateRandomArray(DEFAULT_CASE_LENGTH) : ids

  const handleCreateLinkedCase = useCallback(
    () =>
      showModal({
        modalType: EModalComponents.CASE_CREATE_DIALOG,
        modalTitle: t('cases.modal.linkCase.title_create'),
        modalProps: {
          caseIdToLink: caseId,
          caseHumanReadableId: humanReadableId,
          caseName: title,
          backModalType: EModalComponents.CASE_LINK_DIALOG
        }
      }),
    [caseId, humanReadableId, showModal, title, t]
  )

  const handleBack = useCallback(() => {
    showModal({
      modalType: EModalComponents.LIST_OF_LINKED_CASES,
      modalTitle: t('cases.modal.linkCase.title_list'),
      modalProps: { caseId }
    })
  }, [caseId, showModal, t])

  useEffect(() => {
    getLinkCaseList({ caseId })

    // Filters are required to trigger list update
  }, [
    classifications.length,
    roles.length,
    statuses.length,
    caseId,
    getLinkCaseList,
    search,
    searchBy
  ])

  useEffect(
    () => () => {
      resetLinkCaseList()
      removeErrorNotification(TOGGLE_CASE_LINK)
    },
    [resetLinkCaseList, removeErrorNotification]
  )

  return (
    <>
      <CaseLinkListWrapper
        caseId={caseId}
        search={search}
        classifications={classifications}
        roles={roles}
        statuses={statuses}
        error={error}
        ids={caseIdList}
        emptyIcon={<CaseIcon />}
        emptyText={t('cases.modal.linkCase.placeholder')}
        loading={loading}
        addonBefore={<p className={styles.title}>{t('cases.modal.linkCase.subtitle')}</p>}
        actions={[
          {
            icon: <PlusIcon />,
            onClick: handleCreateLinkedCase,
            children: t('cases.modal.linkCase.title_create')
          }
        ]}
        setSearch={setLinkCaseListSearch}
        resetFilter={resetLinkCaseListFilter}
        setFilter={setLinkCaseListFilter}
      />

      <StepsFooterActions
        className={styles.footerActions}
        submitLabel={null}
        onClickBack={handleBack}
      />
    </>
  )
}
