import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  createLoadingSelector,
  GET_ORGANIZATIONS,
  getOrganizationsRequest,
  useAppSelector,
  resetOrganizations
} from 'store'
import { OrganizationView } from 'App/components'
import { UnityHubLayout } from 'App/components/UnityHub'

const loadingSelector = createLoadingSelector([GET_ORGANIZATIONS])

export const Organization = () => {
  const dispatch = useDispatch()

  const loading = useAppSelector(loadingSelector)
  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const organization = useAppSelector((state) => state.organizations.data.list[accountId || ''])

  useEffect(() => {
    dispatch(getOrganizationsRequest())

    return () => {
      dispatch(resetOrganizations())
    }
  }, [dispatch])

  if (loading || !organization) {
    return null
  }

  return (
    <UnityHubLayout>
      <OrganizationView showAvatar loading={loading} organization={organization} />
    </UnityHubLayout>
  )
}
