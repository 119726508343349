import { Route, Redirect } from 'react-router-dom'

import { TRoleRouteContainerProps } from 'App/containers'

import { validateRoute } from './RoleRoute.utils'

type TRoleRouteProps = TRoleRouteContainerProps

export const RoleRoute = ({
  accountType,
  allowedAccountTypes,
  role,
  allowedBusinessRoles,
  allowed: allowedProp,
  ...rest
}: TRoleRouteProps) => {
  if (!accountType) {
    return null
  }

  const allowed =
    allowedProp || validateRoute(accountType, allowedAccountTypes, role, allowedBusinessRoles)

  return allowed ? <Route {...rest} /> : <Redirect to="/" />
}
