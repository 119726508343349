import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention'

import { useRedirectToUserProfile } from 'App/hooks'
import { TMention } from 'utils/mentions'

export type TAccountMentionProps = Pick<SubMentionComponentProps, 'className' | 'decoratedText'> & {
  mention: TMention
}

export const AccountMention = ({ decoratedText, mention, className }: TAccountMentionProps) => {
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const handleClick = () => {
    if (mention.clickable && mention.variant === 'account') {
      return redirectToUserProfile(mention.id.toString(), mention.type?.name)()
    }

    return null
  }

  return (
    <span className={className} onClick={handleClick}>
      {decoratedText}
    </span>
  )
}
