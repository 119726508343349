import cls from 'classnames'
import { useCallback } from 'react'

import { TChatMessageTextContainerProps } from 'App/containers'
import {
  ChatQuotedMessage,
  ChatMessageSenderName,
  AnchorifedText,
  FormattedText
} from 'App/components'

import styles from './ChatMessageText.module.scss'

export type TChatMessageTextBaseProps = {
  id: string
  chatId: string
  senderName?: string
  showUserName?: boolean
}
export type TChatMessageTextProps = TChatMessageTextBaseProps & TChatMessageTextContainerProps

export const ChatMessageText = ({
  message,
  quoteMessage,
  isMessageOutgoing,
  senderName,
  mentionedAccounts,
  mentionedDepartments
}: TChatMessageTextProps) => {
  const nonUrlPartsRenderer = useCallback(
    (content: string) => (
      <FormattedText
        text={content}
        mentions={{
          accounts: mentionedAccounts,
          departments: mentionedDepartments,
          clickable: true
        }}
        formatters={['emoji', 'mention']}
        classes={{ mention: cls(styles.mention, { [styles.outgoingMessage]: isMessageOutgoing }) }}
      />
    ),
    [mentionedAccounts, mentionedDepartments, isMessageOutgoing]
  )

  if (!message) {
    return null
  }

  return (
    <div
      className={cls(styles.root, {
        [styles.outgoingMessage]: isMessageOutgoing
      })}
    >
      <ChatMessageSenderName senderName={senderName} />
      {quoteMessage && (
        <ChatQuotedMessage
          quote={quoteMessage}
          sender={quoteMessage?.sender}
          originalSender={quoteMessage?.originalSender}
          outgoing={isMessageOutgoing}
        />
      )}

      {typeof message === 'string' && (
        <AnchorifedText
          text={message}
          nonUrlPartsRenderer={nonUrlPartsRenderer}
          color={isMessageOutgoing ? 'white' : 'default'}
        />
      )}
    </div>
  )
}
