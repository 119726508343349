import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { NotificationsP2PDetailsList } from 'App/components'
import {
  clearNotificationsProtocolUserCountAction,
  createLoadingSelector,
  getNotificationsProtocolRequest,
  GET_NOTIFICATIONS_PROTOCOL
} from 'store'

export type TNotificationsP2PDetailsListContainerOwnProps = {
  id: string
}

export type TNotificationsP2PDetailsListContainerProps =
  TNotificationsP2PDetailsListContainerOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_NOTIFICATIONS_PROTOCOL])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  ids: state.notifications.protocol.ids,
  total: state.notifications.protocol.pagination.total,
  showBy: state.notifications.protocol.pagination.showBy,
  current: state.notifications.protocol.pagination.current
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getNotificationsProtocol: getNotificationsProtocolRequest,
      clearNotificationsProtocolUserCount: clearNotificationsProtocolUserCountAction
    },
    dispatch
  )

export const NotificationsP2PDetailsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsP2PDetailsList)
