import { useTranslation } from 'react-i18next'

import styles from './SettingsSecurityDeleteAccountConfirmList.module.scss'

export type TListProps = {
  items: React.ReactNode[]
}

export const SettingsSecurityDeleteAccountConfirmList = ({ items }: TListProps) => {
  const { t } = useTranslation()

  return (
    <>
      <p className={styles.label}>{t('modal.deleteAccountConfirm.subtitle')}</p>

      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </>
  )
}
