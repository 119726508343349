import { getAllTimezones } from 'countries-and-timezones'
import { Crop } from 'react-image-crop'
import validator from 'validator'
import { AccountTypeNames, ChatTypeEnum, PreferenceTypesEnum } from '@medentee/enums'
import convert from 'convert-units'
import { AxiosError } from 'axios'
import { SyntheticEvent } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import isArray from 'lodash/isArray'
import { isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { TChannelVariant } from 'interfaces'
import {
  DOWNLOAD_IFRAME_NAME,
  ESessionsStorageKeys,
  extensionRegExp,
  MAX_LINK_LENGTH
} from 'globalConstants'
import { ESorting } from 'enums'
import { TDateOfBirth, TProfileProfession } from 'store/userProfile'
import { TOption } from 'App/components'
import { handleError } from 'api/utils'

import config from '../config'
import i18n from '../i18n'

import { QueryBuilder } from './QueryBuilder'

export const allTimezones = getAllTimezones()

export type QueryDataObject = {
  [key: string]: string | number
}

export const getQueryString = (data: QueryDataObject): string =>
  Object.entries(data).reduce((res, item) => {
    const [key, value] = item
    let str = ''

    if (value !== null) {
      if (Array.isArray(value)) {
        if (value.length) {
          str = value
            .reduce<string[]>((subRes, subValue: string) => [...subRes, `${key}[]=${subValue}`], [])
            .join('&')
        }
      } else {
        str = `${key}=${value}`
      }
    }

    const delimiter = res.length === 0 ? '?' : '&'

    return `${res}${str.length ? delimiter : ''}${str}`
  }, '')

export const timezoneFormatter = (timezone: number | null) => {
  if (timezone !== null) {
    const sign = timezone > 0 ? '+' : ''

    return `UTC ${sign}${timezone}:00`
  } else {
    return null
  }
}

export const readAsFile = (file: File) =>
  new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })

export function readBlobAsText(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const getValidHref = (href: string) => {
  if (href.indexOf('http://') === 0 || href.indexOf('https://') === 0) {
    return href
  } else {
    return `//${href}`
  }
}

export const jsonParser = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return null
  }
}

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

type TChatSenderName = {
  senderName: string
  yourMessage: boolean

  showUserName?: boolean
}

export const getChatSenderName = ({ showUserName, senderName, yourMessage }: TChatSenderName) => {
  const isNeedToShowUserName = !yourMessage && showUserName

  return isNeedToShowUserName ? senderName : ''
}

export const getInitials = (
  displayUserName: string,
  firstName: string,
  lastName: string,
  type: AccountTypeNames,
  chatType?: ChatTypeEnum
): string => {
  if (chatType === ChatTypeEnum.GROUP) {
    const [first = '', second = ''] = displayUserName.match(/[\p{L}0-9]+/gu) ?? []

    const firstChar = first.charAt(0)
    const secondChar = second.charAt(0)

    return `${firstChar}${secondChar}`.toUpperCase()
  }

  if (type === AccountTypeNames.BUSINESS) {
    return displayUserName[0]?.toUpperCase()
  }

  return firstName && lastName ? `${firstName[0]}${lastName[0]}`?.toUpperCase() : ''
}

export const downloadFileFromUrl = ({
  content,
  name
}: {
  name?: string
  content: string
}): void => {
  const element = document.createElement('a')

  element.href = content
  if (name) {
    element.download = name
  }
  element.target = DOWNLOAD_IFRAME_NAME
  element.click()
}

export const isImage = (extension: string) =>
  ['.jpg', '.jpeg', '.tif', '.tiff', '.png', '.gif', '.bmp', '.dib'].includes(extension)

export const getExtension = (name: string, sliceDot = false): string => {
  const extensionMatch = name.match(extensionRegExp)
  const extension = extensionMatch !== null ? extensionMatch[0] : ''

  if (sliceDot) {
    return extension.slice(1)
  }

  return extension
}

export const getFileName = (name: string): string => name.replace(extensionRegExp, '')

export type TUnAuthorizedRedirectOptions = Partial<{
  type: 'success' | 'info' | 'warn' | 'error'
  message: string
}>

export const getRedirectToastInfo = () => {
  const data = window.sessionStorage.getItem(ESessionsStorageKeys.REDIRECT_TOAST_INFO)
  const serializedData: null | Required<TUnAuthorizedRedirectOptions> = data
    ? (JSON.parse(data) as Required<TUnAuthorizedRedirectOptions>)
    : null

  return !serializedData?.message || !serializedData?.type ? null : serializedData
}
export const setRedirectToastInfo = (data: null | Required<TUnAuthorizedRedirectOptions>) =>
  window.sessionStorage.setItem(ESessionsStorageKeys.REDIRECT_TOAST_INFO, JSON.stringify(data))

export const unAuthorizedRedirect = (options?: null | TUnAuthorizedRedirectOptions) =>
  new Promise<void>((resolve) => {
    const { message, type } = options ?? {}

    setRedirectToastInfo(null)
    window.location.href = new QueryBuilder(config.defaultRedirectUrl)
      .custom('r', window.location.href)
      .custom('type', type)
      .custom('message', message)
      .build()
    resolve()
  })

export const getCityNameWithTimezone = (cityName: string, timezone: string) => {
  if (allTimezones[timezone]) {
    const utc = allTimezones[timezone].dstOffsetStr

    return `${cityName} (UTC ${utc[0]} ${utc[1] !== '0' ? utc[1] : ''}${utc.substring(2)})`
  }

  return cityName
}

export const getTimezone = (timezone: string) => {
  if (allTimezones[timezone]) {
    const utc = allTimezones[timezone].dstOffsetStr

    return `GMT ${utc[0]} ${utc[1] !== '0' ? utc[1] : ''}${utc.substring(2)}`
  }

  return timezone
}

export const getCroppedBlob = async (
  crop: Crop,
  image: HTMLImageElement,
  container: HTMLDivElement | null
) => {
  if (
    !crop.x === undefined ||
    !crop.y === undefined ||
    !crop.width ||
    !crop.height ||
    !image ||
    !container
  ) {
    return
  }

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const containerRect = container.getBoundingClientRect()

  const scale = image.naturalWidth / image.width

  const cropX = crop.x * scale
  const cropY = crop.y * scale

  const imageX = ((containerRect.width - image.width) / 2) * scale
  const imageY = ((containerRect.height - image.height) / 2) * scale

  const scaledCropWidth = crop.width * scale
  const scaledCropHeight = crop.height * scale

  canvas.width = scaledCropWidth
  canvas.height = scaledCropHeight

  if (ctx) {
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(
      image,
      cropX - imageX,
      cropY - imageY,
      scaledCropWidth,
      scaledCropHeight,
      0,
      0,
      scaledCropWidth,
      scaledCropHeight
    )
  }

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      'image/jpeg',
      0.8
    )
  })
}

export const urlValid = (url: string) =>
  url && url.length > 0 && url.length <= MAX_LINK_LENGTH && validator.isURL(url)

export const getMapComponent = <D, P, R = JSX.Element | null>(
  map: Map<D, (props: P) => R>,
  decider: D,
  props?: P
) => {
  const component = map.has(decider) ? map.get(decider) : null

  return component ? component(props as P) : null
}

export const displayError = (error: AxiosError) => {
  if (error && error.response && error.response.data instanceof Blob) {
    const reader = new FileReader()

    reader.onload = () => {
      const result = jsonParser(reader.result as string)

      const errorMessage = error?.code ? handleError(error) : result?.message

      handleError(errorMessage)
    }

    reader.readAsText(error.response.data)

    return
  }

  if (error?.message) {
    handleError(error)
  }
}

export type ConvertUnitType =
  | 'mm'
  | 'cm'
  | 'm'
  | 'km'
  | 'in'
  | 'ft-us'
  | 'ft'
  | 'mi'
  | 'mcg'
  | 'mg'
  | 'g'
  | 'kg'
  | 'oz'
  | 'lb'
  | 'mt'
  | 't'

export const getProfileLink = (userId: string) => `/profile/${userId}`

export const convertUnits = (unit: number, from: ConvertUnitType, to: ConvertUnitType): number => {
  try {
    return convert(unit).from(from).to(to)
  } catch {
    return unit
  }
}

export const formatDateOfBirth = (dateOfBirth: TDateOfBirth) =>
  `${dateOfBirth.day}/${dateOfBirth.month}/${dateOfBirth.year}`

export type TGetMeasurementsProps = {
  preferences: PreferenceTypesEnum

  height?: number | null
  weight?: number | null
}

export type TGetMeasurementsReturn = {
  ft?: string
  inch?: string
  cm?: string
  weight?: string
}

export const getMeasurements = ({
  height,
  weight,
  preferences
}: TGetMeasurementsProps): TGetMeasurementsReturn => {
  const measurements: TGetMeasurementsReturn = {}

  if (height) {
    if (preferences === PreferenceTypesEnum.METRIC) {
      measurements.cm = height.toString()
    } else {
      measurements.ft = Math.floor(convertUnits(height, 'cm', 'ft')).toString()
      measurements.inch = Math.round(convertUnits(height, 'cm', 'in') % 12).toString()
    }
  }

  if (weight) {
    if (preferences === PreferenceTypesEnum.METRIC) {
      measurements.weight = weight.toString()
    } else {
      measurements.weight = Math.round(convertUnits(weight, 'kg', 'lb')).toString()
    }
  }

  return measurements
}

export const createSelectorOptions = <Value extends string = string>(
  values: readonly Value[],
  formatLabel?: (label: Value, index: number) => string
): TOption<Value>[] =>
  values.map((value, index) => ({
    label: formatLabel ? formatLabel(value, index) : value,
    value
  }))

export const getISOStringNow = () => new Date().toISOString()

type TCheckLocalFileExistOptions = {
  errorCode?: string
  customErrorMessage?: string
}

export const checkLocalFileExist = (
  file: File,
  options?: TCheckLocalFileExistOptions
): Promise<void | string> =>
  new Promise((resolve, reject) => {
    const {
      errorCode = 'NotFoundError',
      customErrorMessage = i18n.t('common.toast.fileNotFound')
    } = options ?? {}
    const fileReader = new FileReader()

    fileReader.readAsArrayBuffer(file.slice(0, 1))
    fileReader.onloadstart = () => {
      fileReader.abort()
      resolve()
    }
    fileReader.onerror = () => {
      reject(
        fileReader.error?.name === errorCode ? { message: customErrorMessage } : fileReader.error
      )
    }
  })

export function stopPropagation(event: SyntheticEvent<HTMLElement> | CheckboxChangeEvent | Event) {
  event.stopPropagation()

  if (event.hasOwnProperty('nativeEvent')) {
    event.nativeEvent.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
}

export const hasClassName = (path: EventTarget[], className: string) =>
  path.some((target) => (target as HTMLElement).classList?.contains(className))

export const parseSpaces = (spaces: PropsWithSpaces['spaces']) => ({
  marginTop: isArray(spaces) ? spaces[0] : spaces,
  marginBottom: isArray(spaces) ? spaces[1] : ''
})

export const getSortDirection = (key: string, sortName?: string, sortDirection?: ESorting) => {
  let newSortDirection = ESorting.DESC

  if (key === sortName) {
    newSortDirection = sortDirection === ESorting.DESC ? ESorting.ASC : ESorting.DESC
  }

  return newSortDirection
}

export const sortByName = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())

export const generateSignupLink = (domain: string, token: string) =>
  `${config.defaultRedirectUrl}/signup/${domain}/${token}`

export const generateJoinToEventLink = (token: string) =>
  `${config.defaultRedirectUrl}/join/event/${token}`

export const generateJoinToUnityHubChannelLink = (
  entity: 'event' | 'community' | 'organization',
  variant: Extract<TChannelVariant, 'broadcast' | 'chat' | 'news'>,
  entityId: string,
  token: string
) => `${config.defaultRedirectUrl}/join/${entity}/${entityId}/${variant}/${token}`

export const formatProfiledProfessions = (professions: TProfileProfession[]) =>
  professions.map((profession) => {
    const specializations = profession.specializations.map((item) => item.name).join(', ')

    const specializationsView = specializations ? ` (${specializations})` : ''

    return `${profession.name}${specializationsView}`
  })

export const isSameDate = (startDate: string | Date, endDate: string | Date) => {
  const startOnUTC = utcToZonedTime(new Date(startDate), 'UTC')
  const endOnUTC = utcToZonedTime(new Date(endDate), 'UTC')

  return isSameDay(startOnUTC, endOnUTC)
}
