import { CSSProperties, ReactNode, useEffect, useRef } from 'react'

export type TClickAwayListenerProps = {
  onOutsideClick: () => void
  children: ReactNode

  className?: string
  style?: CSSProperties
  events?: (keyof DocumentEventMap)[]
  useCapture?: boolean
}

export const ClickAwayListener = (props: TClickAwayListenerProps) => {
  const {
    children,
    className,
    style,
    onOutsideClick,
    useCapture = false,
    events = ['click']
  } = props
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onOutsideClick()
      }
    }

    events.forEach((eventName) => {
      document.addEventListener(eventName, handler, useCapture)
    })

    return () => {
      events.forEach((eventName) => {
        document.removeEventListener(eventName, handler)
      })
    }
  }, [onOutsideClick, ref, events, useCapture])

  return (
    <div ref={ref} style={style} className={className}>
      {children}
    </div>
  )
}
