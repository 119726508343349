import { CallStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TGetMapComponentProps } from 'App/containers/Meetings'
import { Avatar } from 'App/components/common'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'

import { CallInfo, Header, Button, Wrapper } from '../common'

type TP2PCallProps = Omit<TGetMapComponentProps, 'meetingCase' | 'meeting'>

export const P2PCall = ({
  accountId: currentAccountId,
  durationMs,
  answerersMap,
  ableToSwitch,
  isOnCurrentCall,
  isMyActiveCall,
  onMeetingActionClick
}: TP2PCallProps) => {
  const { t } = useTranslation()

  if (!answerersMap) {
    return null
  }

  const {
    id,
    lastName,
    firstName,
    displayUserName,
    type: { name: typeName }
  } = answerersMap.filter(({ accountId }) => accountId !== currentAccountId)[0].account

  const inactiveCall = answerersMap?.some(({ status }) => status !== CallStatusEnum.ACTIVE)

  return (
    <Wrapper
      avatar={
        <Avatar
          size={EAvatarSize.MD}
          type={typeName}
          userId={id}
          lastName={lastName}
          firstName={firstName}
          displayUserName={displayUserName}
        />
      }
      content={
        <>
          <Header title={displayUserName} />
          <CallInfo
            isOnCurrentCall={isOnCurrentCall}
            ableToSwitch={ableToSwitch}
            durationMs={durationMs}
            isMyActiveCall={!inactiveCall}
          />
        </>
      }
      action={
        <Button
          resolveText={t('meetings.answerButton')}
          resolveIcon={<PhoneIcon />}
          isOnCurrentCall={!ableToSwitch && isMyActiveCall}
          ableToSwitch={ableToSwitch}
          onClick={onMeetingActionClick}
        />
      }
    />
  )
}
