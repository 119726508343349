import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, SettingsItemDescription, SettingsItemTitle } from 'App/components'
import { EModalComponents, TSettingsSecurityPasswordContainerProps } from 'App/containers'

import styles from './SettingsSecurityPassword.module.scss'

export type TSettingsSecurityPasswordProps = TSettingsSecurityPasswordContainerProps

export const SettingsSecurityPassword = ({ showModal }: TSettingsSecurityPasswordProps) => {
  const { t } = useTranslation()

  const handleChange = useCallback(() => {
    showModal({
      modalTitle: t('modal.changePassword.title'),
      modalType: EModalComponents.CHANGE_PASSWORD
    })
  }, [showModal, t])

  return (
    <>
      <SettingsItemTitle className={styles.title}>
        {t('settings.security.password.title')}
      </SettingsItemTitle>
      <SettingsItemDescription>
        {t('settings.security.password.description')}
      </SettingsItemDescription>

      <Button variant="outlined" size="sm" onClick={handleChange} className={styles.button}>
        {t('settings.security.password.changeButton')}
      </Button>
    </>
  )
}
