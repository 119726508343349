import { useMemo } from 'react'
import cls from 'classnames'
import { MeetingInviteStatusEnum, MeetingStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Status } from 'App/components/Meetings/common/Status'
import { TMeetingType } from 'store'

import styles from './MeetingStatus.module.scss'

export type TMeetingStatusProps = {
  meetingStatus: MeetingStatusEnum
  meetingType: TMeetingType
  isOrganizer: boolean

  inviteStatus?: MeetingInviteStatusEnum
}

export const MeetingStatus = ({
  meetingStatus,
  meetingType,
  inviteStatus,
  isOrganizer
}: TMeetingStatusProps) => {
  const { t } = useTranslation()

  const status = useMemo(() => {
    if (isOrganizer && inviteStatus === MeetingInviteStatusEnum.ACCEPTED) {
      return inviteStatus
    }

    if (isOrganizer) {
      return meetingType === 'OFFLINE' && meetingStatus === MeetingStatusEnum.OPEN
        ? ''
        : meetingStatus
    }

    if (
      !isOrganizer &&
      (inviteStatus === MeetingInviteStatusEnum.CANCELLED ||
        inviteStatus === MeetingInviteStatusEnum.REJECTED)
    ) {
      return meetingStatus
    }

    return ''
  }, [isOrganizer, inviteStatus, meetingType, meetingStatus])

  return status ? (
    <Status label={t(`meetings.status.${status}`)} status={status} className={cls(styles.root)} />
  ) : null
}
