import { action, TError, TPluralProcessingPayload } from 'store'

import {
  TApproveBusinessAdminInviteRequestPayload,
  TGetInvitationsNewUsersRequestPayload,
  TGetInvitationsNewUsersSuccessPayload,
  TInvitationsSuccessPayload,
  TDeleteBusinessAdminInviteRequestPayload,
  TRemoveNewUsersInviteRequestPayload,
  TWithdrawNewUsersInviteRequestPayload
} from './invitations.types'

// ------------------ GET_INVITATIONS_REQUEST ------------------

export const GET_INVITATIONS_REQUEST = 'GET_INVITATIONS_REQUEST'
export const GET_INVITATIONS_SUCCESS = 'GET_INVITATIONS_SUCCESS'
export const GET_INVITATIONS_ERROR = 'GET_INVITATIONS_ERROR'

export const getInvitationsRequest = () => action(GET_INVITATIONS_REQUEST)
export const getInvitationsSuccess = (payload: TInvitationsSuccessPayload) =>
  action(GET_INVITATIONS_SUCCESS, payload)
export const getInvitationsError = (payload: TError) => action(GET_INVITATIONS_ERROR, payload)

// ------------------ GET_INVITATIONS_NEW_USERS ------------------

export const GET_INVITATIONS_NEW_USERS = 'GET_INVITATIONS_NEW_USERS'
export const GET_INVITATIONS_NEW_USERS_REQUEST = 'GET_INVITATIONS_NEW_USERS_REQUEST'
export const GET_INVITATIONS_NEW_USERS_SUCCESS = 'GET_INVITATIONS_NEW_USERS_SUCCESS'
export const GET_INVITATIONS_NEW_USERS_ERROR = 'GET_INVITATIONS_NEW_USERS_ERROR'

export const getInvitationsNewUsersRequest = (payload: TGetInvitationsNewUsersRequestPayload) =>
  action(GET_INVITATIONS_NEW_USERS_REQUEST, payload)
export const getInvitationsNewUsersSuccess = (payload: TGetInvitationsNewUsersSuccessPayload) =>
  action(GET_INVITATIONS_NEW_USERS_SUCCESS, payload)
export const getInvitationsNewUsersError = (payload: TError) =>
  action(GET_INVITATIONS_NEW_USERS_ERROR, payload)

// ------------------ RESET_INVITATIONS_NEW_USERS ------------------

export const RESET_INVITATIONS_NEW_USERS = 'RESET_INVITATIONS_NEW_USERS'

export const resetInvitationsNewUsers = () => action(RESET_INVITATIONS_NEW_USERS)

// ------------------ REMOVE_NEW_USERS_INVITE ------------------

export const REMOVE_NEW_USERS_INVITE = 'REMOVE_NEW_USERS_INVITE'
export const REMOVE_NEW_USERS_INVITE_REQUEST = 'REMOVE_NEW_USERS_INVITE_REQUEST'
export const REMOVE_NEW_USERS_INVITE_SUCCESS = 'REMOVE_NEW_USERS_INVITE_SUCCESS'
export const REMOVE_NEW_USERS_INVITE_ERROR = 'REMOVE_NEW_USERS_INVITE_ERROR'

export const removeNewUsersInviteRequest = (payload: TRemoveNewUsersInviteRequestPayload) =>
  action(REMOVE_NEW_USERS_INVITE_REQUEST, payload)
export const removeNewUsersInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(REMOVE_NEW_USERS_INVITE_SUCCESS, payload)
export const removeNewUsersInviteError = (payload: TError & TPluralProcessingPayload) =>
  action(REMOVE_NEW_USERS_INVITE_ERROR, payload)

// ------------------ WITHDRAW_NEW_USERS_INVITE ------------------

export const WITHDRAW_NEW_USERS_INVITE = 'WITHDRAW_NEW_USERS_INVITE'
export const WITHDRAW_NEW_USERS_INVITE_REQUEST = 'WITHDRAW_NEW_USERS_INVITE_REQUEST'
export const WITHDRAW_NEW_USERS_INVITE_SUCCESS = 'WITHDRAW_NEW_USERS_INVITE_SUCCESS'
export const WITHDRAW_NEW_USERS_INVITE_ERROR = 'WITHDRAW_NEW_USERS_INVITE_ERROR'

export const withdrawNewUsersInviteRequest = (payload: TWithdrawNewUsersInviteRequestPayload) =>
  action(WITHDRAW_NEW_USERS_INVITE_REQUEST, payload)
export const withdrawNewUsersInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(WITHDRAW_NEW_USERS_INVITE_SUCCESS, payload)
export const withdrawNewUsersInviteError = (payload: TError & TPluralProcessingPayload) =>
  action(WITHDRAW_NEW_USERS_INVITE_ERROR, payload)

// ------------------ REMOVE_BUSINESS_ADMIN_INVITE ------------------

export const REMOVE_BUSINESS_ADMIN_INVITE = 'REMOVE_BUSINESS_ADMIN_INVITE'
export const REMOVE_BUSINESS_ADMIN_INVITE_REQUEST = 'REMOVE_BUSINESS_ADMIN_INVITE_REQUEST'
export const REMOVE_BUSINESS_ADMIN_INVITE_SUCCESS = 'REMOVE_BUSINESS_ADMIN_INVITE_SUCCESS'
export const REMOVE_BUSINESS_ADMIN_INVITE_ERROR = 'REMOVE_BUSINESS_ADMIN_INVITE_ERROR'

export const deleteBusinessAdminInviteRequest = (
  payload: TDeleteBusinessAdminInviteRequestPayload
) => action(REMOVE_BUSINESS_ADMIN_INVITE_REQUEST, payload)
export const deleteBusinessAdminInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(REMOVE_BUSINESS_ADMIN_INVITE_SUCCESS, payload)
export const deleteBusinessAdminInviteError = (payload: TError & TPluralProcessingPayload) =>
  action(REMOVE_BUSINESS_ADMIN_INVITE_ERROR, payload)

// ------------------ APPROVE_BUSINESS_ADMIN_INVITE ------------------

export const APPROVE_BUSINESS_ADMIN_INVITE = 'APPROVE_BUSINESS_ADMIN_INVITE'
export const APPROVE_BUSINESS_ADMIN_INVITE_REQUEST = 'APPROVE_BUSINESS_ADMIN_INVITE_REQUEST'
export const APPROVE_BUSINESS_ADMIN_INVITE_SUCCESS = 'APPROVE_BUSINESS_ADMIN_INVITE_SUCCESS'
export const APPROVE_BUSINESS_ADMIN_INVITE_ERROR = 'APPROVE_BUSINESS_ADMIN_INVITE_ERROR'

export const approveBusinessAdminInviteRequest = (
  payload: TApproveBusinessAdminInviteRequestPayload
) => action(APPROVE_BUSINESS_ADMIN_INVITE_REQUEST, payload)
export const approveBusinessAdminInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(APPROVE_BUSINESS_ADMIN_INVITE_SUCCESS, payload)
export const approveBusinessAdminInviteError = (payload: TError & TPluralProcessingPayload) =>
  action(APPROVE_BUSINESS_ADMIN_INVITE_ERROR, payload)
