import { memo, useCallback, useEffect, useMemo } from 'react'

import { getSortDirection } from 'utils'
import { createSlidesEntity, TSlideEntity } from 'store'
import { ESorting } from 'enums'
import { PAGINATION_DEFAULT_SHOW_BY, Pagination as TPagination } from 'types'
import { FilePagination, useSelectedFilesSizeCalculation } from 'App/components'
import { TFileListContainerProps } from 'App/containers'

import { useFilesView } from '../FileTilesView'
import { useSelection } from '../common'

import { FileGridView } from './FileGridView'
import { TableView } from './TableView'
import { useKebabMenu } from './useKebabMenu'
import styles from './FileList.module.scss'

/** Table have own Props */
export type TFileTableProps = {
  key: string
  fileName: string
  fileSize: string
  fileId: string
  createdAt: string | Date | undefined
  updatedAt: string | Date | undefined
  mimeType: string
  extension: string
  thumbnailUrl: string | null
  slidesEntity: TSlideEntity

  isUnopenable?: boolean
}

const FileListView = ({
  fileIds,
  fileList,
  loading,
  pagination,
  sorting,
  videoStreamingList,
  accountId,
  getFiles,
  fileTotal = 0
}: TFileListContainerProps) => {
  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(fileIds)
  const selectedFileSize = useSelectedFilesSizeCalculation(selectedIds, fileList)
  const [view, setView] = useFilesView('med-cloud-my-files')

  useEffect(() => {
    setSelectedIds([])
    // `fileIds` property is required
  }, [setSelectedIds, fileIds])

  const handleGetFiles = useCallback(
    (name?: string, direction?: ESorting) => {
      getFiles({
        sorting: name && direction ? { name, direction } : sorting,
        pagination,
        ownerId: accountId
      })
    },
    [getFiles, sorting, pagination, accountId]
  )

  const onTableSorting = useCallback(
    (key: string) => {
      const sortDirection = getSortDirection(key, sorting?.name, sorting?.direction)

      handleGetFiles(key, sortDirection)
    },
    [sorting?.name, sorting?.direction, handleGetFiles]
  )

  const onPaginationChange = ({ current, showBy, total }: TPagination) => {
    getFiles({
      filters: {
        page: current
      },
      pagination: {
        current,
        showBy: showBy || PAGINATION_DEFAULT_SHOW_BY,
        total
      },
      sorting,
      ownerId: accountId
    })
  }

  const dataSource: TFileTableProps[] = useMemo(
    () =>
      fileIds.map((id) => {
        const { fileName, createdAt, fileSize, mimeType, extension, updatedAt, thumbnailUrl } =
          fileList[id] ?? {}

        return {
          key: id,
          fileName,
          createdAt,
          fileSize,
          mimeType,
          extension,
          fileId: id,
          updatedAt,
          thumbnailUrl,
          isUnopenable: videoStreamingList[id]?.videoMetadata?.quality === null,
          slidesEntity: createSlidesEntity(fileIds, fileList)
        }
      }),
    [fileIds, fileList, videoStreamingList]
  )

  const { getMenuItems } = useKebabMenu({
    moveToTrashBinCallback: handleGetFiles,
    renameCallback: handleGetFiles
  })

  return (
    <div className={styles.fileList}>
      {view === 'list' ? (
        <TableView
          selectedIds={selectedIds}
          dataSource={dataSource}
          view={view}
          loading={loading}
          sorting={sorting}
          selectMode={selectMode}
          filesSize={selectedFileSize}
          setSelectedIds={setSelectedIds}
          onViewChange={setView}
          onSorting={onTableSorting}
          onSelect={onSelect}
          getMenuItems={getMenuItems}
        />
      ) : (
        <FileGridView
          view={view}
          dataSource={dataSource}
          sorting={sorting}
          selectMode={selectMode}
          selectedIds={selectedIds}
          fileIds={fileIds}
          loading={loading}
          filesSize={selectedFileSize}
          onViewChange={setView}
          onSorting={handleGetFiles}
          setSelectedIds={setSelectedIds}
          onSelect={onSelect}
          getMenuItems={getMenuItems}
        />
      )}
      <FilePagination
        current={pagination.current}
        total={fileTotal}
        showBy={pagination.showBy}
        onPaginationChange={onPaginationChange}
      />
    </div>
  )
}

export const FileList = memo(FileListView)
