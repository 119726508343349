import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { createLoadingSelector, getMeetingsRequest, GET_MEETINGS, useAppSelector } from 'store'
import { EEmptyListIconSize, EmptyList } from 'App/components/common'
import { generateRandomArray } from 'utils'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'

import { MeetingGroup } from '../MeetingGroup'
import { OngoingMeetingCalls } from '../OngoingMeetingCalls'

import { MeetingsModalScheduleButton } from './MeetingsModalScheduleButton'
import styles from './MeetingsModal.module.scss'

const loadingSelector = createLoadingSelector([GET_MEETINGS])

const DEFAULT_GROUPS_LENGTH = 3

type TMeetingsModalProps = { anchorId?: string }

export const MeetingsModal = ({ anchorId }: TMeetingsModalProps) => {
  const dispatch = useDispatch()

  const loading = useAppSelector(loadingSelector)
  const meetingsGroupIds = useAppSelector((state) => state.meetings.groupIds)
  const ongoingCallsIds = useAppSelector((state) => state.calls.ongoingCalls.ids)

  const { t } = useTranslation()

  const ids = loading ? generateRandomArray(DEFAULT_GROUPS_LENGTH) : meetingsGroupIds
  const shouldShowPlaceholder = !loading && !meetingsGroupIds.length && !ongoingCallsIds.length

  useEffect(() => {
    dispatch(getMeetingsRequest())
  }, [dispatch])

  if (shouldShowPlaceholder) {
    return (
      <>
        <MeetingsModalScheduleButton className={styles.scheduleButtonMargin} />

        <EmptyList
          text={t('modal.meetings.placeholder')}
          icon={<ScheduleIcon />}
          iconSize={EEmptyListIconSize.MD}
        />
      </>
    )
  }

  return (
    <>
      <MeetingsModalScheduleButton className={styles.scheduleButtonMargin} />

      {!loading && <OngoingMeetingCalls />}
      {ids.map((day) => (
        <MeetingGroup key={day} id={day} loading={loading} anchorId={anchorId} />
      ))}
    </>
  )
}
