import {
  TAction,
  GET_CASE_MEMBERS_SUCCESS,
  LEAVE_MEMBER_ERROR,
  LEAVE_MEMBER_SUCCESS,
  LEAVE_MEMBER_REQUEST,
  REMOVE_MEMBER_FROM_CASE_REQUEST,
  REMOVE_MEMBER_FROM_CASE_ERROR,
  REMOVE_MEMBER_FROM_CASE_SUCCESS,
  CASE_DETAILS_RESET,
  RECEIVE_NEW_MESSAGE_SUCCESS,
  SEND_NEW_MESSAGE_SUCCESS,
  TReceiveNewMessageSuccess,
  TSendNewMessageSuccess,
  CASE_MEMBER_STATUS_CHANGED_TO_ACTIVE_SUCCESS,
  CASE_MEMBER_STATUS_CHANGED_TO_INACTIVE_SUCCESS,
  CASE_MEMBER_STATUS_CHANGED_SUCCESS,
  RESET_CASE_MEMBERS
} from 'store'
import { moveToStart } from 'utils'

import {
  TCaseMembersState,
  TCaseMemberStatusChangedToActiveSuccess,
  TCaseMemberStatusChangedToInactiveSuccess,
  TGetCaseMembersSuccess,
  TLeaveMembersError,
  TRemoveMemberFromCaseError
} from './caseMembers.types'

export const initialCaseMembersState: TCaseMembersState = {
  members: {
    active: {
      ids: [],
      list: {},
      accountList: {}
    },
    inactive: {
      ids: [],
      list: {},
      accountList: {}
    }
  },
  leave: {},
  remove: {}
}

export const caseMembersReducer = (state = initialCaseMembersState, action: TAction) => {
  switch (action.type) {
    case CASE_DETAILS_RESET: {
      return initialCaseMembersState
    }

    case GET_CASE_MEMBERS_SUCCESS: {
      const { activeMembers, inactiveMembers } = (action as TGetCaseMembersSuccess).payload
      return {
        ...state,
        members: {
          active: {
            ids: activeMembers.ids,
            list: activeMembers.list,
            accountList: activeMembers.accountList
          },
          inactive: {
            ids: inactiveMembers.ids,
            list: inactiveMembers.list,
            accountList: inactiveMembers.accountList
          }
        }
      }
    }

    case LEAVE_MEMBER_REQUEST:
    case LEAVE_MEMBER_SUCCESS: {
      return {
        ...state,
        leave: {
          ...state.leave,
          error: null
        }
      }
    }

    case LEAVE_MEMBER_ERROR: {
      return {
        ...state,
        leave: {
          ...state.leave,
          error: (action as TLeaveMembersError).payload
        }
      }
    }

    case REMOVE_MEMBER_FROM_CASE_REQUEST:
    case REMOVE_MEMBER_FROM_CASE_SUCCESS: {
      return {
        ...state,
        remove: {
          ...state.remove,
          error: null
        }
      }
    }

    case REMOVE_MEMBER_FROM_CASE_ERROR: {
      return {
        ...state,
        remove: {
          ...state.remove,
          error: (action as TRemoveMemberFromCaseError).payload
        }
      }
    }

    case RECEIVE_NEW_MESSAGE_SUCCESS: {
      const { senderId } = (action as TReceiveNewMessageSuccess).payload.message
      const { ids, list } = state.members.active

      const member = (Object.values(list).filter((value) => value?.account.id === senderId) ||
        [])[0]

      return {
        ...state,
        members: {
          ...state.members,
          active: {
            ...state.members.active,
            ids: moveToStart(ids, list[member?.id]?.id)
          }
        }
      }
    }

    case SEND_NEW_MESSAGE_SUCCESS: {
      const { receiverId } = (action as TSendNewMessageSuccess).payload
      const { ids, list } = state.members.active

      const member = (Object.values(list).filter((value) => value?.account.id === receiverId) ||
        [])[0]

      return {
        ...state,
        members: {
          ...state.members,
          active: {
            ...state.members.active,
            ids: moveToStart(ids, list[member?.id]?.id)
          }
        }
      }
    }

    case CASE_MEMBER_STATUS_CHANGED_TO_ACTIVE_SUCCESS: {
      const { caseMemberId, status } = (action as TCaseMemberStatusChangedToActiveSuccess).payload

      const { [caseMemberId]: omittedMember, ...inactiveList } = { ...state.members.inactive.list }
      const { [caseMemberId]: omittedAccount, ...inactiveAccountList } = {
        ...state.members.inactive.accountList
      }

      return {
        ...state,
        members: {
          active: {
            ids: [caseMemberId, ...state.members.active.ids],
            list: {
              ...state.members.active.list,
              [caseMemberId]: {
                ...omittedMember,
                status
              }
            },
            accountList: {
              ...state.members.active.accountList,
              [omittedMember.account.id]: { ...omittedMember.account }
            }
          },
          inactive: {
            ids: [...state.members.inactive.ids.filter((id) => id !== caseMemberId)],
            list: { ...inactiveList },
            accountList: { ...inactiveAccountList }
          }
        }
      }
    }

    case CASE_MEMBER_STATUS_CHANGED_TO_INACTIVE_SUCCESS: {
      const { caseMemberId, status } = (action as TCaseMemberStatusChangedToInactiveSuccess).payload

      const { [caseMemberId]: omittedMember, ...activeList } = { ...state.members.active.list }
      const { [caseMemberId]: omittedAccount, ...activeAccountList } = {
        ...state.members.active.accountList
      }

      return {
        ...state,
        members: {
          active: {
            ids: [...state.members.active.ids.filter((id) => id !== caseMemberId)],
            list: { ...activeList },
            accountList: { ...activeAccountList }
          },
          inactive: {
            ids: [caseMemberId, ...state.members.inactive.ids],
            list: {
              ...state.members.inactive.list,
              [caseMemberId]: {
                ...omittedMember,
                status
              }
            },
            accountList: {
              ...state.members.inactive.accountList,
              [omittedMember.account.id]: { ...omittedMember.account }
            }
          }
        }
      }
    }

    case CASE_MEMBER_STATUS_CHANGED_SUCCESS: {
      const { caseMemberId, status } = (action as TCaseMemberStatusChangedToInactiveSuccess).payload
      const { ids, list } = state.members.inactive

      return {
        ...state,
        members: {
          ...state.members,
          inactive: {
            ...state.members.inactive,
            ids: moveToStart(ids, list[caseMemberId]?.id),
            list: {
              ...state.members.inactive.list,
              [caseMemberId]: {
                ...state.members.inactive.list[caseMemberId],
                status
              }
            }
          }
        }
      }
    }
    case RESET_CASE_MEMBERS: {
      return {
        ...state,
        members: initialCaseMembersState.members
      }
    }
    default:
      return state
  }
}
