import { MouseEvent } from 'react'

import styles from './EmptyListActionLink.module.scss'

export type TEmptyListActionLinkProps = {
  title: string

  onClick?: (e?: MouseEvent) => void
}

export const EmptyListActionLink = ({ title, onClick }: TEmptyListActionLinkProps) => (
  <button className={styles.root} onClick={onClick}>
    {title}
  </button>
)
