import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { useToggle } from 'App/hooks'
import { IconLabel, Popover } from 'App/components'
import { useBasicSubscription } from 'App/hooks/useBasicSubscription'
import { ICON_HEIGHT, ContextMenu } from 'App/components/common/ContextMenu'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'

import { ExternalMeetingLink } from '../../useExternalMeeting'

import { useMeetingsModalScheduleMenuItems } from './useMeetingsModalScheduleMenuItems'
import styles from './MeetingsModalScheduleButton.module.scss'

export const MeetingsModalScheduleButton = ({ className }: PropsWithClassName) => {
  const { getMenuItems } = useMeetingsModalScheduleMenuItems()

  const {
    value: linkPopoverVisible,
    setValue: setLinkPopoverVisible,
    toggleOn: showLinkPopover
  } = useToggle()

  const { getOnClickHandler } = useBasicSubscription({
    callback: showLinkPopover
  })

  const menuItems = getMenuItems({ showLinkPopover: () => getOnClickHandler()() })

  const { t } = useTranslation()

  return (
    <Row className={className} justify="center">
      <Popover
        placement="bottom"
        visible={linkPopoverVisible}
        align={{ offset: [0, -ICON_HEIGHT] }}
        content={
          <div className={styles.linkPopover}>
            <ExternalMeetingLink />
          </div>
        }
        onVisibleChange={setLinkPopoverVisible}
      >
        <ContextMenu menuItems={menuItems} align={{ offset: [0, -ICON_HEIGHT] }} placement="bottom">
          <IconLabel
            classes={{ label: styles.label }}
            label={t('modal.scheduleMeeting.title')}
            icon={<ScheduleIcon />}
          />
        </ContextMenu>
      </Popover>
    </Row>
  )
}
