import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { Pagination, SortingTable } from 'types'
import { State } from 'redux/rootReducer'
import { FileList } from 'App/components/File/FileList'
import { getFileListRequest } from 'store'

/** Top Component Props */
export interface IFileListContainerProps {
  pagination: Pagination
  loading: boolean

  sorting?: SortingTable
}

/** Container Props */
export type TFileListContainerProps = IFileListContainerProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => {
  const { fileList, ids, filters, pagination } = state.file

  return {
    fileList,
    pagination,
    fileIds: ids,
    fileTotal: filters.total,
    videoStreamingList: state.videoStreaming.list,
    accountId: state.global.accountData?.id
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFiles: getFileListRequest
    },
    dispatch
  )

export const FileListContainer: FC<IFileListContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileList)
