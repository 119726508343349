import { PropsWithChildren, useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { UploadSourcesEnum } from '@medentee/enums'

import { TCallbackAfterUploadFile, uploadFilesRequest, useAppDispatch } from 'store'
import { WHITELIST_FILES_DOT } from 'globalConstants'

export type TFileUploadButtonContainerProps = PropsWithChildren<{
  uploadedFrom?: UploadSourcesEnum
  disabled?: boolean
  multiple?: boolean
  onAfterUploadFile?: TCallbackAfterUploadFile
  chatId?: string
  caseId?: string
  removeOnCancel?: boolean
  isBroadcast?: boolean
  maxFileSize?: number
}>

export const FileUploadButtonContainer = ({
  onAfterUploadFile,
  caseId,
  chatId,
  children,
  isBroadcast,
  maxFileSize,
  multiple = true,
  disabled = false,
  removeOnCancel = false,
  uploadedFrom = UploadSourcesEnum.CLOUD
}: TFileUploadButtonContainerProps) => {
  const dispatch = useAppDispatch()

  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      dispatch(
        uploadFilesRequest({
          files: [...acceptedFiles, ...fileRejections.map((item) => item.file)],
          uploadedFrom,
          onAfterUploadFile,
          caseId,
          chatId,
          removeOnCancel,
          isBroadcast,
          maxFileSize
        })
      )
    },
    [
      uploadedFrom,
      caseId,
      chatId,
      removeOnCancel,
      isBroadcast,
      dispatch,
      maxFileSize,
      onAfterUploadFile
    ]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: WHITELIST_FILES_DOT,
    multiple,
    onDrop: handleDrop,
    disabled,
    noDrag: true,
    maxSize: maxFileSize
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}
