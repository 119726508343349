import { useRef, useState, useEffect, useCallback, PropsWithChildren } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import cls from 'classnames'

import { useAppSelector } from 'store'

import styles from './ChatTypingIndicator.module.scss'
import { getTypingPlaceholderParameters, useTypingPlaceholder } from './ChatTypingIndicator.utils'

export type TChatTypingIndicatorProps = PropsWithClassName<
  PropsWithChildren<{
    chatId: string

    namesLimit?: number
  }>
>

type TTypingPlaceholderParameters = {
  names: string[]
  extraNamesNumber: number
}

type TCreateTypingPlaceholder = TTypingPlaceholderParameters & {
  typingTotal: number
}

export const ChatTypingIndicator = ({
  chatId,
  className,
  namesLimit,
  children = null
}: TChatTypingIndicatorProps) => {
  const typingInterlocutors = useAppSelector(
    (state) => state.chat.chatRooms.typingInterlocutors[chatId]
  )

  const ref = useRef<HTMLDivElement>(null)

  const [typingPlaceholder, setTypingPlaceholder] = useState<JSX.Element>()

  const [typingPlaceholderParameters, setTypingPlaceholderParameters] =
    useState<TTypingPlaceholderParameters>({ names: [], extraNamesNumber: 0 })

  useEffect(() => {
    setTypingPlaceholderParameters(getTypingPlaceholderParameters(typingInterlocutors, namesLimit))
  }, [namesLimit, typingInterlocutors])

  const { createPlaceholder } = useTypingPlaceholder()

  const getTypingPlaceholder = useCallback(
    ({ extraNamesNumber, names, typingTotal }: TCreateTypingPlaceholder) =>
      () => {
        const placeholder = createPlaceholder({
          typingTotal,
          extraNamesNumber,
          names,
          namesLimit
        })

        setTypingPlaceholder(placeholder)
      },
    [createPlaceholder, namesLimit]
  )

  useEffect(() => {
    if (ref.current && ref.current?.offsetWidth < ref.current?.scrollWidth) {
      const nameOverflows = typingPlaceholderParameters.names.length === 1

      const extraNamesQuantity = nameOverflows
        ? typingPlaceholderParameters.extraNamesNumber + 2
        : typingPlaceholderParameters.extraNamesNumber + 1
      const names = typingPlaceholderParameters.names.slice(1)

      getTypingPlaceholder({
        extraNamesNumber: extraNamesQuantity,
        names,
        typingTotal: typingInterlocutors?.length
      })()

      setTypingPlaceholderParameters({
        extraNamesNumber: extraNamesQuantity,
        names
      })
    }
  }, [
    typingPlaceholderParameters.extraNamesNumber,
    typingPlaceholderParameters.names,
    typingInterlocutors?.length,
    typingPlaceholder,
    getTypingPlaceholder
  ])

  useEffect(() => {
    const el = ref.current

    const resizeObserver = new ResizeObserver(
      getTypingPlaceholder({
        names: typingPlaceholderParameters.names,
        extraNamesNumber: typingPlaceholderParameters.extraNamesNumber,
        typingTotal: typingInterlocutors?.length
      })
    )

    el && resizeObserver.observe(el)

    return () => {
      el && resizeObserver.unobserve(el)
    }
    // It's unnecessary to trigger this useEffect more than once
    // It's responsible only for ResizeObserver creation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getTypingPlaceholder({
      names: typingPlaceholderParameters.names,
      extraNamesNumber: typingPlaceholderParameters.extraNamesNumber,
      typingTotal: typingInterlocutors?.length
    })()
  }, [
    typingPlaceholderParameters.extraNamesNumber,
    typingPlaceholderParameters.names,
    typingInterlocutors?.length,
    getTypingPlaceholder
  ])

  return typingInterlocutors?.length ? (
    <div className={styles.container}>
      <p className={cls(styles.root, className)} ref={ref}>
        {typingPlaceholder}
      </p>
    </div>
  ) : (
    <>{children}</>
  )
}
