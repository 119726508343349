import { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import cls from 'classnames'
import {
  ContactStatusEnum,
  BusinessAccountStatusEnum,
  PlatformInvitationType,
  ProfileLinkTypeNamesEnum
} from '@medentee/enums'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import {
  AvatarContainer,
  ButtonMessageContainer,
  TUserProfileContactContainerProps
} from 'App/containers'
import {
  AccountName,
  Button,
  Scrollable,
  ButtonScheduleMeeting,
  UserProfileBreadcrumbs,
  UserProfileDetailsItem,
  UserProfilePreviewArea,
  AnchorifedText
} from 'App/components'
import { COMPANY_SIZE_TYPE_MAP, GUEST_USER_PROFILE_SEARCH_QUERY_KEY } from 'globalConstants'
import { useChatRedirect } from 'App/components/Organizations/hooks'
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg'
import { ReactComponent as WebIcon } from 'assets/icons/Web.svg'
import { ReactComponent as AccountMultipleOutlineIcon } from 'assets/icons/AccountMultipleOutline.svg'
import {
  accountIdSelector,
  createOrganizationChatRequest,
  useAppDispatch,
  useAppSelector
} from 'store'
import {
  UserProfileInvitationByLink,
  TUserProfileInvitationByLinkProps
} from 'App/containers/UserProfile/UserProfileInvitationByLink'
import { UserProfileContactInviteActions } from 'App/containers/UserProfile/UserProfileContactInviteActions'

import { TUserProfileSocialLinksProps, UserProfileSocialLinks } from '../UserProfileSocialLinks'

import { Details } from './Details'
import styles from './UserProfileContact.module.scss'

export type TUserProfileContactBaseProps = {
  isPreview: boolean

  invitationByLink?: Omit<TUserProfileInvitationByLinkProps, 'className'>
}

export type TUserProfileContactProps = TUserProfileContactBaseProps &
  TUserProfileContactContainerProps

type TParams = {
  id?: string
  token?: string
}

export const UserProfileContact = ({
  isOwner,
  isPreview,
  id,
  type,
  facebook,
  linkedIn,
  twitter,
  headline,
  city,
  country,
  timezone,
  websites,
  chatId: initialChatId,
  companyInfo,
  displayUserName,
  firstName,
  lastName,
  loading,
  isContact,
  isCoworker,
  invitationByLink,
  contact
}: TUserProfileContactProps) => {
  const dispatch = useAppDispatch()

  const { isMobile, isTablet, isDesktop } = useAdaptiveLayout()

  const [chatId, setChatId] = useState(initialChatId)

  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const socialLinks = useMemo<TUserProfileSocialLinksProps['links']>(
    () => [
      {
        type: ProfileLinkTypeNamesEnum.FACEBOOK,
        url: facebook
      },
      {
        type: ProfileLinkTypeNamesEnum.LINKEDIN,
        url: linkedIn
      },
      {
        type: ProfileLinkTypeNamesEnum.TWITTER,
        url: twitter
      }
    ],
    [facebook, linkedIn, twitter]
  )

  const { search } = useLocation()
  const { token } = useParams<TParams>()

  const searchQuery = parse(search)

  const { address1, address2, size } = companyInfo || {}

  const avatarSize = isMobile ? EAvatarSize.LG : EAvatarSize.LLG

  const showBreadcrumbs = isContact && !isOwner && !isPreview && !isMobile && chatId

  const showEditButton = isOwner && !isPreview
  const breadcrumbLinkName = t('userProfile.details.breadcrumbs.contacts')
  const breadcrumbPath = '/contacts'

  const hasApprovedStaffInvitation =
    invitationByLink?.type === PlatformInvitationType.STAFF &&
    id === invitationByLink?.inviteOrganization?.businessAccountId &&
    invitationByLink?.inviteOrganization?.status === BusinessAccountStatusEnum.APPROVED

  const hasApprovedContactInvitation =
    invitationByLink?.type === PlatformInvitationType.CONTACT &&
    (accountId === invitationByLink.inviteContact?.fromId ||
      accountId === invitationByLink.inviteContact?.toId) &&
    invitationByLink?.inviteContact?.status === ContactStatusEnum.APPROVED

  const hasApprovedInvitation = hasApprovedStaffInvitation || hasApprovedContactInvitation

  const showInvitationsContainer =
    !isOwner &&
    !invitationByLink?.shouldShowAccountSwitchModal &&
    !hasApprovedInvitation &&
    Boolean(invitationByLink?.type && invitationByLink?.token)

  const showButtonsContainer =
    !isOwner && !showInvitationsContainer && (chatId || isCoworker || !contact)

  const disabledChatButton = (!isOwner || isPreview) && !isContact && !isCoworker

  const disabledScheduleButton = !isContact && !isCoworker

  const shouldShowContactInviteActions =
    !showInvitationsContainer &&
    !isContact &&
    !loading &&
    accountId !== id &&
    !searchQuery[GUEST_USER_PROFILE_SEARCH_QUERY_KEY] &&
    !invitationByLink?.shouldShowAccountSwitchModal

  const { redirectToChat } = useChatRedirect()

  const handleMessageButtonClick = () => {
    if (chatId) {
      redirectToChat({ chatId, partnerAccountId: id })
    } else {
      dispatch(
        createOrganizationChatRequest({
          partnerAccountId: id,
          onSuccess: (newChatId) => {
            if (newChatId) {
              setChatId(newChatId)
              redirectToChat({ chatId: newChatId, partnerAccountId: id })
            }
          }
        })
      )
    }
  }

  const getLocationText = (address?: string) => {
    if (address) {
      return `${address}, ${country}, ${city}`
    }

    return `${country}, ${city}`
  }

  useEffect(() => {
    if (initialChatId) {
      setChatId(initialChatId)
    }
  }, [initialChatId])

  return (
    <div className={styles.root}>
      {isPreview && <UserProfilePreviewArea />}
      {showBreadcrumbs && (
        <UserProfileBreadcrumbs
          accountName={displayUserName}
          path={breadcrumbPath}
          linkName={breadcrumbLinkName}
        />
      )}
      <div className={styles.rootWrapper}>
        <Scrollable className={styles.rootScrollable} variant="aside">
          <div className={styles.info}>
            <div className={styles.infoWrapper}>
              <div className={styles.avatar}>
                <AvatarContainer
                  userId={id}
                  type={type}
                  firstName={firstName}
                  lastName={lastName}
                  displayUserName={displayUserName}
                  showOnline={false}
                  size={avatarSize}
                  hasTooltip={false}
                />
              </div>

              {showButtonsContainer && (
                <div className={styles.buttons}>
                  <ButtonMessageContainer
                    chatId={chatId}
                    disabled={disabledChatButton}
                    tooltip={
                      disabledChatButton ? t('userProfile.details.chatButtonTooltip') : undefined
                    }
                    onClick={handleMessageButtonClick}
                  />

                  <ButtonScheduleMeeting
                    chatId={chatId}
                    partnerAccountId={id}
                    userName={displayUserName}
                    disabled={disabledScheduleButton}
                    tooltip={
                      disabledScheduleButton
                        ? t('userProfile.details.meetingButtonTooltip')
                        : undefined
                    }
                    onCreateChat={setChatId}
                  />
                </div>
              )}

              {shouldShowContactInviteActions && (isDesktop || isMobile) && (
                <UserProfileContactInviteActions
                  contactId={contact?.id}
                  fromId={contact?.fromId}
                  status={contact?.status}
                  toId={contact?.toId}
                  profileId={id}
                />
              )}
            </div>
            <div className={cls(styles.infoWrapper, styles.infoWrapperDetails)}>
              <div className={styles.header}>
                <div className={styles.nameAndLinks}>
                  <AccountName
                    displayUserName={displayUserName}
                    type={type}
                    truncate={false}
                    classes={{
                      root: styles.accountNameRoot,
                      icon: styles.accountNameIcon,
                      name: styles.accountNameName,
                      avatarWrapper: styles.accountNameAvatarWrapper
                    }}
                  />

                  {showInvitationsContainer && (
                    <UserProfileInvitationByLink
                      className={styles.invitationByLink}
                      token={token}
                      inviteContact={invitationByLink?.inviteContact}
                      inviteOrganization={invitationByLink?.inviteOrganization}
                      type={invitationByLink?.type}
                      profileId={invitationByLink?.profileId}
                    />
                  )}

                  {socialLinks.length && (
                    <UserProfileSocialLinks
                      className={styles.links}
                      links={socialLinks}
                      size={isDesktop ? 'xs' : 'md'}
                    />
                  )}
                </div>
                {shouldShowContactInviteActions && isTablet && (
                  <UserProfileContactInviteActions
                    contactId={contact?.id}
                    fromId={contact?.fromId}
                    status={contact?.status}
                    toId={contact?.toId}
                    profileId={id}
                  />
                )}
              </div>
              {headline && (
                <div className={styles.headline}>
                  <AnchorifedText text={headline} />
                </div>
              )}
              <div className={styles.detailsList}>
                <UserProfileDetailsItem
                  variant="text"
                  icon={<LocationOutlineIcon />}
                  text={getLocationText(address1)}
                  loading={loading}
                />
                {address2 && (
                  <UserProfileDetailsItem
                    variant="text"
                    icon={<LocationOutlineIcon />}
                    text={getLocationText(address2)}
                    loading={loading}
                  />
                )}
                <UserProfileDetailsItem
                  variant="text"
                  icon={<ClockIcon />}
                  text={timezone}
                  loading={loading}
                />
                <UserProfileDetailsItem
                  variant="text"
                  icon={<AccountMultipleOutlineIcon />}
                  text={(size && COMPANY_SIZE_TYPE_MAP[size]) ?? ''}
                  loading={loading}
                />
                {!!websites.length &&
                  websites.map(
                    (item, index) =>
                      item && (
                        <UserProfileDetailsItem
                          key={index}
                          variant="link"
                          icon={<WebIcon />}
                          link={item}
                          loading={loading}
                        />
                      )
                  )}
              </div>
              {showEditButton && (
                <Link to="/profile/edit/basic" className={styles.editLink}>
                  <Button variant="outlined" size="sm">
                    {t('userProfile.details.editProfileButton')}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Scrollable>
        <Details />
      </div>
    </div>
  )
}
