import { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { hideModalAction } from 'store'
import { Button } from 'App/components/'
import { ReactComponent as SuccessLightIcon } from 'assets/icons/SuccessLight.svg'

import styles from './CaseMemberInviteApproveDialog.module.scss'

export type TCaseMemberInviteApproveDialogBaseProps = {
  caseId: string
  title: string
}

const CaseMemberInviteApproveDialogComponent = ({
  hideModal,
  caseId,
  title
}: TCaseMemberInviteApproveDialogBaseProps & ReturnType<typeof mapDispatchToProps>) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleViewClick = useCallback(() => {
    push(`/cases/${caseId}/member`)
    hideModal()
  }, [hideModal, caseId, push])

  const handleLaterClick = useCallback(() => hideModal(), [hideModal])

  return (
    <div className={styles.root}>
      <SuccessLightIcon className={cls(styles.rootIcon, styles.successIcon)} />

      <p className={styles.rootTitle}>{t('cases.modal.accentCaseInvitation.content', { title })}</p>

      <Button className={styles.rootButton} onClick={handleViewClick}>
        {t('cases.modal.accentCaseInvitation.viewCaseButton')}
      </Button>

      <button className={styles.rootLink} onClick={handleLaterClick}>
        {t('cases.modal.accentCaseInvitation.laterButton')}
      </button>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalAction
    },
    dispatch
  )

export const CaseMemberInviteApproveDialog = connect(
  null,
  mapDispatchToProps
)(CaseMemberInviteApproveDialogComponent)
