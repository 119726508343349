import { call, put, takeLatest } from 'redux-saga/effects'

import { API, api, APIResultsResponse } from 'services/api'
import { normalizeList } from 'store/utils'
import { IEventDTO } from 'interfaces'
import { handleError } from 'api/utils'

import {
  RECEIVE_BROADCAST_DELETED,
  RECEIVE_BROADCAST_CREATED,
  RECEIVE_UPDATE_BROADCAST_STATUS,
  receiveBroadcastDeleted
} from '../broadcasts'

import * as actions from './events.actions'
import * as actionTypes from './events.actionTypes'

function* getEventsSaga({ payload }: ReturnType<typeof actions.getEventsRequest>) {
  try {
    const {
      data: { results, total }
    }: APIResultsResponse<IEventDTO[]> = yield call(api.get, API.EVENTS)

    const normalizeEvents = normalizeList(results)

    yield put(
      actions.getEventsSuccess({
        ...normalizeEvents,
        total
      })
    )

    if (payload?.onSuccess) {
      yield call(payload.onSuccess)
    }
  } catch (e) {
    handleError(e)
    yield put(actions.getEventsError(e))
  }
}

function* refreshEventsSaga({ payload }: ReturnType<typeof receiveBroadcastDeleted>) {
  if (payload.eventId) {
    yield put(actions.getEventsRequest())
  }
}

export function* eventsSaga() {
  yield takeLatest(actionTypes.GET_EVENTS_REQUEST, getEventsSaga)
  yield takeLatest(
    [RECEIVE_UPDATE_BROADCAST_STATUS, RECEIVE_BROADCAST_CREATED, RECEIVE_BROADCAST_DELETED],
    refreshEventsSaga
  )
}
