import { components, MultiValueProps, GroupBase } from 'react-select'

import { TOption } from '../../Select'

import styles from './MultiValue.module.scss'

export const MultiValue = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MultiValueProps<Option, IsMulti, Group>
) => (
  <components.MultiValue {...props} className={styles.root}>
    {props.selectProps.originalValue ? (props.data as TOption).value : props.children}
  </components.MultiValue>
)
