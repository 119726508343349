import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TChatDefaultMessageContainerProps, EChatDefaultMessageSize } from 'App/containers'
import {
  ChatDefaultMessageWrapper,
  ChatDefaultMessageText,
  Avatar,
  TChatDefaultMessageWrapperClasses
} from 'App/components'

export type TChatDefaultMessageGroupProps = Pick<
  TChatDefaultMessageContainerProps,
  'createdAt' | 'size' | 'chatType' | 'chatName'
> &
  TChatDefaultMessageWrapperClasses & {
    chatId?: string
  }

export const ChatDefaultMessageGroup = ({
  createdAt,
  chatType,
  chatName,
  classes,
  chatId = '',
  size = EChatDefaultMessageSize.MD
}: TChatDefaultMessageGroupProps) => {
  const { t } = useTranslation()

  return (
    <ChatDefaultMessageWrapper
      chatType={chatType}
      createdAt={createdAt}
      size={size}
      classes={classes}
    >
      <Avatar
        border={true}
        size={EAvatarSize[size]}
        userId={chatId}
        firstName=""
        lastName=""
        chatType={ChatTypeEnum.GROUP}
        displayUserName={chatName ?? ''}
      />
      <ChatDefaultMessageText text={t('chat.placeholder_group', { chatName })} />
    </ChatDefaultMessageWrapper>
  )
}
