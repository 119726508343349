import { TVideoStreamingState, TVideoStreamingAction } from './videoStreaming.types'
import * as actionTypes from './videoStreaming.actionTypes'

const initialState: TVideoStreamingState = {
  list: {}
}

export const videoStreamingReducer = (
  state = initialState,
  action: TVideoStreamingAction
): TVideoStreamingState => {
  switch (action.type) {
    case actionTypes.SET_VIDEO_STREAMING_DATA: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        }
      }
    }

    case actionTypes.UPDATE_VIDEO_STREAMING_ITEM: {
      const { fileId, bunnyNetMetadata, quality, videoMetadata } = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          [fileId]: {
            ...state.list[fileId],
            bunnyNetMetadata: {
              ...state.list[fileId]?.bunnyNetMetadata,
              ...bunnyNetMetadata
            },
            videoMetadata: {
              ...state.list[fileId]?.videoMetadata,
              ...videoMetadata,
              quality
            }
          }
        }
      }
    }

    default:
      return state
  }
}
