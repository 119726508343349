import { PropsWithChildren } from 'react'
import cls from 'classnames'

import { ILicenseDTO } from 'interfaces'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'

import styles from './ItemLink.module.scss'

type TItemLink = PropsWithChildren<PropsWithClassName<Pick<ILicenseDTO, 'link'>>>

export const ItemLink = ({ className, link }: TItemLink) =>
  link ? (
    <div className={cls(styles.root, className)}>
      <LinkChainIcon className={styles.icon} />

      <a className={styles.link} href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    </div>
  ) : null
