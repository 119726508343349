import { ErrorCodesEnum } from '@medentee/enums'

import { EHistoryEvents } from 'enums'
import { PAGINATION_DEFAULT } from 'types'

import { formatBytes, isMatchErrorCode, parseError, QueryBuilder, TResponseError } from '../utils'
import { EHistoryType, toastDefaultOptions } from '../globalConstants'
import { toast } from '../App/components/ToastContainer'
import i18n from '../i18n'

export const handleError = (e: TResponseError) => {
  if (isMatchErrorCode(e, [ErrorCodesEnum.FILE_ALLOWED_SIZE_EXCEEDED])) {
    toast.error(
      i18n.t('serverError.FILE_ALLOWED_SIZE_EXCEEDED', {
        ns: 'errors',
        max: formatBytes(e?.response?.data.max)
      }),
      toastDefaultOptions
    )

    return
  }

  toast.error(parseError(e), toastDefaultOptions)
}

export const handleWarning = (e: TResponseError) => {
  toast.warn(parseError(e), toastDefaultOptions)
}

type TBiDirectionalQueryBuilder = {
  url: string
  historyEvent: EHistoryEvents

  anchorId?: string
  showBy?: number | string
}

export const biDirectionalQueryBuilder = ({
  url,
  anchorId,
  historyEvent,
  showBy
}: TBiDirectionalQueryBuilder) => {
  const historyEventTypesMap = new Map<EHistoryEvents, EHistoryType>()
    .set(EHistoryEvents.HISTORY_BEFORE, EHistoryType.BEFORE)
    .set(EHistoryEvents.HISTORY_AFTER, EHistoryType.AFTER)
    .set(EHistoryEvents.JUMP, EHistoryType.AROUND)

  return new QueryBuilder(url)
    .showBy(showBy ?? PAGINATION_DEFAULT.showBy)
    .anchor(anchorId, historyEventTypesMap.get(historyEvent))
}
