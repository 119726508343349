import { PropsWithChildren, ReactNode } from 'react'
import cls from 'classnames'

import styles from './UnityHubCreateLayout.module.scss'

type TUnityHubCreateLayoutProps = PropsWithChildren<
  PropsWithClasses<'root' | 'content' | 'title'>
> & {
  title: ReactNode
}

export const UnityHubCreateLayout = ({ title, children, classes }: TUnityHubCreateLayoutProps) => (
  <div className={cls(styles.root, classes?.root)}>
    <div className={cls(styles.content, classes?.content)}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  </div>
)
