import { Button, ComingSoon, TButtonProps } from '../../../common'

import { useMonthlyTopUpRequest } from './useMonthlyTopUpButtonRequest'

export type TMonthlyTopUpButtonProps = TButtonProps

export const MonthlyTopUpButton = ({ children, ...props }: TMonthlyTopUpButtonProps) => {
  const { mutate, processing } = useMonthlyTopUpRequest()

  return (
    <ComingSoon>
      <Button disabled loading={processing} onClick={mutate} {...props}>
        {children}
      </Button>
    </ComingSoon>
  )
}
