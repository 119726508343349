import { AccountTypeNames } from '@medentee/enums'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TOption } from 'App/components'
import { ECaseListSearchBy } from 'enums'
import { createSelectorOptions } from 'utils'

const SEARCH_OPTIONS: ECaseListSearchBy[] = [ECaseListSearchBy.TITLE, ECaseListSearchBy.PARTICIPANT]

export const useCasesSearchOptions = (
  accountType?: AccountTypeNames
): TOption<ECaseListSearchBy>[] => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!accountType) {
      return []
    }

    return createSelectorOptions(SEARCH_OPTIONS, (item) =>
      t('cases.list.searchByOptions', { context: item })
    )
  }, [accountType, t])
}
