import { useEffect } from 'react'
import {
  CaseStatusesEnum,
  FileHistorySourcesEnum,
  CaseClassificationNameEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { generateRandomArray } from 'utils'
import { Tabs, TabPane, EmptyList, FileInformationTitle } from 'App/components'
import {
  CaseInviteMembersButton,
  FileHistoryWrapperContainer,
  TCaseCloudFileDetailsContainerProps
} from 'App/containers'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import Details from './Details'
import { PermissionList } from './PermissionList'
import styles from './CaseCloudFileDetails.module.scss'

export type TCaseCloudFileDetailsBaseProps = {
  caseId: string

  isMember?: boolean
}

export type TCaseCloudFileDetailsProps = TCaseCloudFileDetailsContainerProps &
  TCaseCloudFileDetailsBaseProps

export const CaseCloudFileDetails = ({
  fileDetails,
  caseId,
  caseStatus,
  inactiveIds,
  activeIds,
  caseClassification,
  closeFileDetails,
  getCaseContactPermissions,
  isMember = false,
  loading
}: TCaseCloudFileDetailsProps) => {
  const { t } = useTranslation()

  const {
    id = '',
    fileName = '',
    extension = '',
    fileSize = '',
    createdAt = '',
    updatedAt = ''
  } = fileDetails || {}

  useEffect(() => {
    getCaseContactPermissions && getCaseContactPermissions({ fileId: id, isMember })
  }, [id, getCaseContactPermissions, isMember])

  const activeIdList = loading ? generateRandomArray() : activeIds
  const inactiveIdList = loading ? generateRandomArray() : inactiveIds

  const shouldShowEmptyPlaceholder = !activeIdList.length && !inactiveIdList.length

  const isLocked = caseStatus === CaseStatusesEnum.LOCKED
  const isPrivate = caseClassification === CaseClassificationNameEnum.PRIVATE

  const closeFileDetailsHandler = () => closeFileDetails()

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <FileInformationTitle extension={extension} fileName={fileName} />
        <CrossIcon className={styles.closeIcon} onClick={closeFileDetailsHandler} />
      </div>

      {isPrivate ? (
        <div className={styles.details}>
          <p className={styles.detailsTitle}>{t('cases.details.attachments.fileDetails.title')}</p>
          <Details fileSize={fileSize} createdAt={createdAt} updatedAt={updatedAt} />
        </div>
      ) : (
        <Tabs defaultActiveKey="Permissions" className={styles.tabsRoot}>
          <TabPane
            tab={t('cases.details.attachments.fileDetails.tabs.permissions.title')}
            key="Permissions"
            className={styles.permissionsTab}
          >
            {shouldShowEmptyPlaceholder ? (
              <EmptyList
                className={styles.permissionListEmpty}
                text={t('cases.details.attachments.fileDetails.tabs.permissions.placeholder', {
                  context: caseStatus?.toLowerCase() as Lowercase<CaseStatusesEnum>
                })}
              >
                {!isLocked && <CaseInviteMembersButton link={true} />}
              </EmptyList>
            ) : (
              <PermissionList
                isMember={isMember}
                caseId={caseId}
                fileId={id}
                activeIds={activeIdList}
                inactiveIds={inactiveIdList}
                loading={loading}
              />
            )}
          </TabPane>

          <TabPane
            tab={t('cases.details.attachments.fileDetails.tabs.details.title')}
            key="Details"
          >
            <Details fileSize={fileSize} createdAt={createdAt} updatedAt={updatedAt} />
          </TabPane>

          <TabPane
            tab={t('cases.details.attachments.fileDetails.tabs.history.title')}
            key="History"
            className={styles.historyTab}
          >
            <FileHistoryWrapperContainer
              fileId={id}
              sources={[FileHistorySourcesEnum.CASE_CLOUD]}
              displayInline={true}
              caseId={caseId}
            />
          </TabPane>
        </Tabs>
      )}
    </section>
  )
}
