import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FileTilesViewGrid,
  FileTilesViewGridItem,
  FileTilesViewHeader,
  TFileTilesViewHeaderProps,
  THeadContextMenuProps,
  TUseSelection
} from 'App/components'
import { TRASH_BIN_DEFAULT_PAGINATION, TIds } from 'store'
import { SortingTable } from 'types'
import { ESorting } from 'enums'
import { useListContextMenu } from 'App/hooks'
import { getSelectedFiles } from 'utils/files'

import { TUseKebabMenu } from '../useKebabMenu'
import { TDataSource } from '../TrashBin'

export type TGridViewProps = Pick<TFileTilesViewHeaderProps, 'onViewChange' | 'onSorting'> &
  Omit<TUseSelection, 'setSelectMode'> &
  Pick<THeadContextMenuProps, 'filesSize'> &
  TUseKebabMenu & {
    view: TFileTilesViewHeaderProps['variant']
    dataSource: TDataSource[]
    fileIds: TIds
    selectedIds: TIds
    setSelectedIds: (ids: TIds) => void

    sorting?: SortingTable
    loading?: boolean
  }

const SKELETON_ROWS = TRASH_BIN_DEFAULT_PAGINATION.showBy

const defaultSorting: SortingTable = {
  direction: ESorting.DESC,
  name: 'deletedAt'
}

export const FileGridView = ({
  dataSource,
  onSorting,
  onViewChange,
  view,
  setSelectedIds,
  fileIds,
  selectedIds,
  loading,
  selectMode,
  onSelect,
  getMenuItems,
  filesSize,
  sorting = defaultSorting
}: TGridViewProps) => {
  const { shouldShowContextMenu, shouldShowHeadContextmenu } = useListContextMenu(selectedIds)

  const { t } = useTranslation()

  const selectedFiles = useMemo(
    () => getSelectedFiles(selectedIds, dataSource),
    [selectedIds, dataSource]
  )

  const sortingList = useMemo<TFileTilesViewHeaderProps['sortingList']>(
    () => [
      {
        title: t('files.columns.name'),
        key: 'fileName'
      },
      {
        title: t('files.columns.deletionDate'),
        key: 'deletedAt'
      },
      {
        title: t('files.columns.fileSize'),
        key: 'fileSize'
      }
    ],
    [t]
  )

  return (
    <>
      <FileTilesViewHeader
        sorting={sorting}
        onSorting={onSorting}
        variant={view}
        fileIds={fileIds}
        selectedIds={selectedIds}
        onViewChange={onViewChange}
        setSelectedIds={setSelectedIds}
        sortingList={sortingList}
        filesSize={filesSize}
        menuItems={getMenuItems(selectedFiles)}
        shouldShowHeadContextmenu={shouldShowHeadContextmenu}
      />

      <FileTilesViewGrid<TDataSource>
        data={dataSource}
        loading={loading}
        skeletonLength={SKELETON_ROWS}
      >
        {({ fileId, fileName, extension, thumbnailUrl, mimeType }) => (
          <FileTilesViewGridItem
            key={fileId}
            fileId={fileId}
            fileName={fileName}
            selected={selectedIds.includes(fileId)}
            extension={extension}
            thumbnailUrl={thumbnailUrl}
            selectMode={selectMode}
            disableOpen={true}
            openHandlerProps={{
              // An empty array is set because we have "disableOpen:true" file browsing here
              slides: []
            }}
            menuItems={
              shouldShowContextMenu(fileId)
                ? getMenuItems({ extension, fileId, fileName })
                : undefined
            }
            onSelect={onSelect}
            mimeType={mimeType}
          />
        )}
      </FileTilesViewGrid>
    </>
  )
}
