import { useState, useCallback, useLayoutEffect, useMemo } from 'react'
import {
  CaseClassificationNameEnum,
  CaseClassificationIdEnum,
  CaseTypesEnum
} from '@medentee/enums'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useAppSelector, createProcessingBySelector, CASE_CONVERT, caseConvertRequest } from 'store'
import {
  CaseConvert,
  CaseInviteMembersAlert,
  StepsFooterActions,
  TStepsFooterActionsProps
} from 'App/components'
import { ReactComponent as AccountSupervisorIcon } from 'assets/icons/AccountSupervisor.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'

import styles from './CaseConvertModal.module.scss'

const processingProfessionalSelector = createProcessingBySelector([CASE_CONVERT])(
  CaseClassificationIdEnum.PROFESSIONAL
)

export type TCaseConvertModalProps = {
  id?: string
  initialCaseType?: CaseTypesEnum
  onChangeCaseType?: (type: CaseTypesEnum) => void
} & Pick<TStepsFooterActionsProps, 'onClickBack' | 'onSubmit' | 'submitLoading' | 'submitLabel'>

export const CaseConvertModal = ({
  id,
  initialCaseType,
  submitLabel,
  submitLoading,
  onChangeCaseType,
  onSubmit,
  onClickBack
}: TCaseConvertModalProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [caseType, setCaseType] = useState<CaseTypesEnum>(CaseTypesEnum.A2O)
  const convertType = CaseClassificationNameEnum.PROFESSIONAL

  const processingProfessional = useAppSelector(processingProfessionalSelector)
  const caseMembersDirectLimit = useAppSelector(
    (state) => state.subscriptions.current?.subscription?.caseMembersDirectLimit
  )
  const caseMembersGroupLimit = useAppSelector(
    (state) => state.subscriptions.current?.subscription?.caseMembersGroupLimit
  )

  const caseMembersLimit =
    caseType === CaseTypesEnum.A2O ? caseMembersDirectLimit : caseMembersGroupLimit

  const defaultSubmitLabel = useMemo(() => {
    if (submitLabel) {
      return submitLabel
    }

    return t('cases.modal.caseConvert.submitButton')
  }, [submitLabel, t])

  const handleConvert = useCallback(() => {
    if (id) {
      dispatch(
        caseConvertRequest({
          id,
          classification: { name: convertType, id: CaseClassificationIdEnum[convertType] },
          processingId: CaseClassificationIdEnum[convertType],
          type: caseType
        })
      )
    }
  }, [id, dispatch, caseType, convertType])

  const handleChangeCaseType = useCallback(
    (type: CaseTypesEnum) => {
      if (onChangeCaseType) {
        onChangeCaseType(type)
      }

      setCaseType(type)
    },
    [onChangeCaseType]
  )

  useLayoutEffect(() => {
    if (initialCaseType) {
      setCaseType(initialCaseType)
    }
  }, [initialCaseType])

  return (
    <div className={styles.root}>
      <CaseInviteMembersAlert caseMembersLimit={caseMembersLimit} type={caseType} />

      <div className={styles.wrapper}>
        <CaseConvert
          title={t('enum.caseTypesEnum.Direct')}
          description={t('cases.modal.caseConvert.typeStep.description_direct')}
          icon={<AccountSupervisorIcon />}
          value={CaseTypesEnum.A2O}
          selected={caseType === CaseTypesEnum.A2O}
          onSelect={handleChangeCaseType}
        />
        <CaseConvert
          title={t('enum.caseTypesEnum.Group')}
          description={t('cases.modal.caseConvert.typeStep.description_group')}
          icon={<AccountGroupIcon />}
          value={CaseTypesEnum.A2A}
          selected={caseType === CaseTypesEnum.A2A}
          onSelect={handleChangeCaseType}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <StepsFooterActions
          onClickBack={onClickBack}
          onSubmit={onSubmit ?? handleConvert}
          submitLoading={submitLoading || processingProfessional}
          submitLabel={defaultSubmitLabel}
        />
      </div>
    </div>
  )
}
