import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { useRefValue } from '../../../hooks'
import { Error } from '../Fields/Error'
import { FieldLabel } from '../Fields'

import styles from './ChipInput.module.scss'
import { ChipInputItem, TChipInputItemValue } from './ChipInputItem'
import { ChipInputTextField } from './ChipInputTextField'

export type TChipInputProps<T extends TChipInputItemValue> = {
  value: T[]
  label?: ReactNode
  error?: string
  maxSize?: number
  paste?: boolean
  autoFocus?: boolean
  submitKeys?: Set<string>
  formatNewItem?: (item: T) => T
  onChange: (value: T[]) => void
  classes?: Partial<Record<'root', string>>
  validate?: (value: T, values?: any) => string | null
  onValidate?: (isValid: boolean) => void
}

export const ChipInput = <T extends TChipInputItemValue>({
  autoFocus,
  value,
  label,
  onChange,
  validate,
  onValidate,
  submitKeys,
  classes,
  paste,
  maxSize = Infinity,
  formatNewItem,
  error: errorMessage
}: TChipInputProps<T>) => {
  const [errors, setErrors] = useState<Record<number, string>>({})

  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { getValue: getValidate } = useRefValue(validate)
  const { getValue: getOnValidate } = useRefValue(onValidate)
  const { getValue } = useRefValue(value)

  const isInvalid = Object.keys(errors).length > 0

  const { t } = useTranslation()

  const validateItems = useCallback(
    (newItems: T[]) => {
      const validateFn = getValidate()

      if (validateFn) {
        const validateCb = getOnValidate()
        const newErrors = newItems.reduce((res, item, index) => {
          const error = validateFn(item)

          if (!error) {
            return res
          }

          return { ...res, [index]: error }
        }, {})

        setErrors(newErrors)

        if (validateCb) {
          validateCb(Object.keys(newErrors).length === 0)
        }
      }
    },
    [getOnValidate, getValidate]
  )

  const handleChange = (newValue: T[]) => {
    onChange(newValue)
    validateItems(newValue)
  }

  const handleAdd = (newItemText: string | string[]) => {
    if (Array.isArray(newItemText)) {
      const formattedItems: T[] = formatNewItem
        ? newItemText.map((text) => formatNewItem({ text } as T))
        : newItemText.map((text) => ({ text } as T))

      const newValue = [...value, ...formattedItems]

      handleChange(newValue)
    } else {
      const formattedItem = formatNewItem
        ? formatNewItem({ text: newItemText } as T)
        : ({ text: newItemText } as T)

      const newValue = [...value, formattedItem]

      handleChange(newValue)

      if (rootRef.current) {
        rootRef.current.scrollTop = rootRef.current.offsetHeight
      }
    }
  }

  const handleFocus = () => {
    inputRef.current?.focus()
  }

  const handleRemove = (index: number) => {
    const newValue = [...value.slice(0, index), ...value.slice(index + 1)]

    handleChange(newValue)
  }

  const handleEdit = (index: number, itemValue: string) => {
    if (itemValue !== value[index]?.text) {
      const newValue = [...value]
      newValue[index].text = itemValue
      handleChange(newValue)
      inputRef.current?.focus()
    }
  }

  const handleDelete = () => {
    const lastIndex = value.length - 1
    const isRemovable = value[lastIndex]?.removable ?? true

    if (value.length && isRemovable) {
      handleChange(value.slice(0, lastIndex))
    }
  }

  // initial validation
  useEffect(() => {
    validateItems(getValue())
  }, [getValue, validateItems])

  return (
    <>
      {label && <FieldLabel label={label} />}
      <div
        tabIndex={1}
        ref={rootRef}
        onFocus={handleFocus}
        className={cls(styles.root, { [styles.error]: isInvalid }, classes?.root)}
      >
        {value.map((item, index) => (
          <ChipInputItem
            key={index}
            value={item}
            index={index}
            onEdit={handleEdit}
            error={errors[index]}
            submitKeys={submitKeys}
            onRemove={handleRemove}
          />
        ))}
        {maxSize > value.length && (
          <ChipInputTextField
            paste={paste}
            ref={inputRef}
            maxSize={maxSize}
            onSubmit={handleAdd}
            submitKeys={submitKeys}
            onDelete={handleDelete}
            autoFocus={autoFocus}
          />
        )}
      </div>
      {errorMessage ? (
        <Error invalid={!!errorMessage} error={errorMessage} />
      ) : (
        <Error invalid={isInvalid} error={t('validationErrors.invalidItems', { ns: 'errors' })} />
      )}
    </>
  )
}
