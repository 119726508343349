import { ReactNode } from 'react'
import cls from 'classnames'

import { Tooltip } from 'App/components'

import styles from './Actions.module.scss'

export type TActionsItem = {
  title?: string
  icon?: ReactNode
  disabled?: boolean
  hidden?: boolean
  onClick?: () => void
}

export type TActionsProps = {
  items: TActionsItem[]
}

export const Actions = ({ items }: TActionsProps) => (
  <>
    {items
      .filter(({ hidden }) => !hidden)
      .map(({ onClick, disabled, title, icon }, index) => (
        <Tooltip key={title ?? index} title={title}>
          <div
            className={cls(styles.icon, disabled && styles.iconDisable)}
            onClick={disabled ? undefined : onClick}
          >
            {icon}
          </div>
        </Tooltip>
      ))}
  </>
)
