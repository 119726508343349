import { useEffect, useCallback, useMemo } from 'react'

import { InfiniteData, useMutation } from 'services/query'
import { getShowcaseCommentCountersRequest, useAppDispatch } from 'store'
import { IShowcaseComment } from 'interfaces'
import eventBus from 'services/eventBus'
import { EHistoryEvents } from 'enums'
import { getComments, deleteCommentById } from 'api/showcase'
import { TUseBidirectionalRequest, useBidirectionalRequest } from 'App/hooks/BidirectionalList'
import { TComment } from 'App/components'

import { getSelectFormatter } from './utils'

export type TUseRequestComments = Pick<TUseBidirectionalRequest, 'queryKey' | 'initialAnchorId'> & {
  showcaseId: string

  fileId?: string
}

const MUTATION_KEY_DELETE_COMMENT = 'MUTATION_KEY_DELETE_COMMENT'

export const useRequestComments = ({
  queryKey,
  fileId,
  showcaseId,
  initialAnchorId
}: TUseRequestComments) => {
  const dispatch = useAppDispatch()

  const select = useMemo(() => getSelectFormatter({ isAllComments: !fileId }), [fileId])

  const {
    loading,
    anchorId,
    data: comments,
    hasAfter,
    hasBefore,
    historyEvent,
    queryClient,
    fetchData
  } = useBidirectionalRequest<IShowcaseComment, TComment>({
    queryKey,
    select,
    initialAnchorId,
    queryFn: (payload) =>
      getComments({
        anchorId: payload.anchorId,
        historyEvent: payload.historyEvent,
        showcaseId,
        fileId
      })
  })

  const { isLoading: deleteCommentProcessing, mutate: deleteComment } = useMutation({
    mutationKey: [MUTATION_KEY_DELETE_COMMENT, showcaseId, fileId],
    mutationFn: deleteCommentById
  })
  const fetchShowcaseCommentCounters = useCallback(() => {
    dispatch(getShowcaseCommentCountersRequest({ showcaseId }))
  }, [dispatch, showcaseId])

  const updatePages = useCallback(
    ({ commentId, message, updatedAt, mentions }) => {
      queryClient.setQueryData<InfiniteData<IShowcaseComment[]>>(queryKey, (data) => {
        const pages = data?.pages.map((page) => {
          const foundIndex = page.findIndex((comment) => comment.id === commentId)
          let nextPage = [...page]

          if (foundIndex !== -1 && message) {
            const comment = page[foundIndex]

            nextPage[foundIndex] = {
              ...comment,
              message,
              mentions,
              updatedAt
            }
          }

          if (foundIndex !== -1 && !message) {
            nextPage = page.filter((comment) => comment.id !== commentId)
          }

          return nextPage
        })

        return {
          pages: pages ?? [],
          pageParams: data?.pageParams ?? []
        }
      })
    },
    [queryClient, queryKey]
  )

  const handleCommentDelete = useCallback(
    (commentId: string) => () => {
      deleteComment(
        { showcaseId, commentId },
        {
          onSuccess: () => {
            updatePages({ commentId })
            fetchShowcaseCommentCounters()
          }
        }
      )
    },
    [deleteComment, updatePages, showcaseId, fetchShowcaseCommentCounters]
  )

  useEffect(() => {
    const unsubscribe = eventBus.showcaseCommentSuccess.subscribe(
      (id) => {
        fetchData(id, EHistoryEvents.JUMP)
        fetchShowcaseCommentCounters()
      },
      { extraEventName: String(queryKey) }
    )

    return () => {
      unsubscribe()
    }
  }, [fetchData, fetchShowcaseCommentCounters, queryKey])

  useEffect(() => {
    const unsubscribe = eventBus.showcaseEditCommentSuccess.subscribe(updatePages, {
      extraEventName: String(queryKey)
    })

    return () => {
      unsubscribe()
    }
  }, [queryKey, updatePages])

  useEffect(() => {
    fetchShowcaseCommentCounters()
  }, [fetchShowcaseCommentCounters])

  return {
    loading,
    hasAfter,
    hasBefore,
    historyEvent,
    anchorId,
    comments,
    deleteCommentProcessing,
    handleCommentDelete,
    fetchData
  }
}
