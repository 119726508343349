import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'

import { Steps, StepsFooterActions } from 'App/components'
import {
  createProcessingSelector,
  CREATE_ADVERT,
  createAdvertRequest,
  TCountry,
  TProfession
} from 'store'
import { useSteps } from 'App/hooks'

import styles from './CreateAdvertDialog.module.scss'
import { CreateAdvertFirstStep, CreateAdvertSecondStep, CreateAdvertThirdStep } from './components'

const processingSelector = createProcessingSelector([CREATE_ADVERT])

const DEFAULT_STEPS = [{}, {}, {}]
enum STEPS {
  FIRST = 0,
  SECOND = 1,
  THIRD = 2
}

type TValues = {
  title: string
  purpose: string[]
  location: TCountry[]
  profession?: (TProfession & { accountType: string }) | null
  specialization: []
}

export type CreateAdvertDialogProps = {
  onSuccess: () => void
}

export const CreateAdvertDialog: FC<CreateAdvertDialogProps> = ({ onSuccess }) => {
  const dispatch = useDispatch()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)

  const processing = useSelector(processingSelector)

  const initialFormValues = useMemo<TValues>(
    () => ({
      title: '',
      purpose: [],
      location: [{ code: 'any', countryName: 'Anywhere' }],
      profession: null,
      specialization: []
    }),
    []
  )

  const onSubmit = (values: TValues) => {
    dispatch(
      createAdvertRequest({
        title: values.title,
        countryCodes: values.location?.find((item) => item.code === 'any')
          ? []
          : values.location.map(({ code }) => code),
        profession: values.profession
          ? { ...omit(values.profession, 'name'), specializations: values.specialization }
          : null,
        purposeIds: values.purpose,
        onSuccess
      })
    )
  }

  return (
    <Row gutter={[0, 29]}>
      <Col xs={24}>
        <Steps current={step} stepsArray={DEFAULT_STEPS} className={styles.steps} />
      </Col>
      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialFormValues}>
          {({ handleSubmit, form }) => (
            <Row gutter={[0, 24]}>
              {step === STEPS.FIRST && <CreateAdvertFirstStep />}
              {step === STEPS.SECOND && <CreateAdvertSecondStep />}
              {step === STEPS.THIRD && <CreateAdvertThirdStep />}
              <Col xs={24}>
                <Row>
                  <StepsFooterActions
                    onSubmit={step === STEPS.THIRD ? handleSubmit : setNextStep}
                    submitLoading={processing}
                    submitDisabled={form.getState().invalid}
                    onClickBack={step === STEPS.FIRST ? undefined : setPrevStep}
                    submitLabel={step === STEPS.THIRD ? 'Post advert' : 'Next'}
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  )
}
