import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import cls from 'classnames'

import { Image } from 'App/components'
import { TFileMention } from 'utils/mentions'

import styles from './FileSuggestion.module.scss'

type TFileSuggestionProps = EntryComponentProps

export const FileSuggestion = ({
  mention,
  theme,
  searchValue,
  isFocused,
  selectMention,
  ...parentProps
}: TFileSuggestionProps) => {
  const { thumbnailUrl, name } = (mention as TFileMention) ?? {}

  return (
    <div {...parentProps} className={cls(styles.root, theme?.mentionSuggestionsEntry)}>
      <Image
        src={thumbnailUrl}
        alt={name}
        className={styles.thumbnail}
        placeholderClasses={{ icon: styles.placeholder }}
      />

      <span>{name}</span>
    </div>
  )
}
