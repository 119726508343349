import { Component, ReactNode } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { ESessionsStorageKeys } from 'globalConstants'

export type TFallbackProps = {
  errorCount: number
}

export type TErrorBoundaryProps = RouteComponentProps & {
  fallback: ReactNode
}

export type TErrorBoundaryState = {
  hasError: boolean
}
class ErrorBoundary extends Component<TErrorBoundaryProps, TErrorBoundaryState> {
  public static getDerivedStateFromError() {
    const rawCount = Number(window.sessionStorage.getItem(ESessionsStorageKeys.ERROR_COUNT))
    const count = isNaN(rawCount) ? 0 : rawCount

    window.sessionStorage.setItem(ESessionsStorageKeys.ERROR_COUNT, `${count + 1}`)

    return {
      hasError: true,
      errorCount: count
    }
  }

  public state = {
    hasError: false,
    errorCount: 0
  }

  public render() {
    const { children, fallback, history } = this.props
    const { hasError } = this.state

    history.listen(() => {
      if (hasError) {
        this.setState({
          hasError: false
        })
      }
    })

    if (hasError) {
      return fallback
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
