import { FC } from 'react'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { ContactsItem } from 'App/components'
import {
  createLoadingSelector,
  createProcessingSelector,
  DELETE_CATEGORY,
  GET_CONTACTS,
  SEND_CATEGORY
} from 'store'

export type TContactsItemContainerOwnProps = {
  id: string
}

export type TContactsItemContainerProps = ReturnType<typeof mapStateToProps> &
  TContactsItemContainerOwnProps

const loadingSelector = createLoadingSelector([GET_CONTACTS])
const processingSelector = createProcessingSelector([SEND_CATEGORY, DELETE_CATEGORY])

const mapStateToProps = (state: State, { id }: TContactsItemContainerOwnProps) => {
  const { categories, chatId, to } = state.contacts.list[id] || {}

  const loadingContacts = state.contacts.filters.loadingContacts
  const loading = loadingSelector(state)
  const processing = processingSelector(state)

  return {
    loading: loading || processing || loadingContacts,
    id,
    to,
    categories,
    chatId
  }
}

export const ContactsItemContainer: FC<TContactsItemContainerOwnProps> =
  connect(mapStateToProps)(ContactsItem)
