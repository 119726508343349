import cls from 'classnames'

import { ReactComponent as PublicIcon } from 'assets/icons/Earth.svg'

import styles from './ChatPublicIcon.module.scss'

export type TChatPublicIconProps = {
  className?: string
}

export const ChatPublicIcon = ({ className }: TChatPublicIconProps) => (
  <PublicIcon className={cls(styles.root, className)} />
)
