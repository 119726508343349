import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './DeleteConfirm.module.scss'

export type TDeleteConfirmProps = {
  id: string
  onConfirm: (id: string) => void
  onClose: (id: string) => void
  confirmationText?: string

  processing?: boolean
}

export const DeleteConfirm = ({
  id,
  processing,
  onClose,
  onConfirm,
  confirmationText
}: TDeleteConfirmProps) => {
  const { t } = useTranslation()

  const handleConfirm = useCallback(() => {
    onConfirm(id)
  }, [id, onConfirm])

  const handleClose = useCallback(() => {
    onClose(id)
  }, [id, onClose])

  return (
    <div className={styles.root}>
      <CrossIcon className={styles.cross} onClick={handleClose} />

      <div className={styles.container}>
        <span className={styles.title}>
          {confirmationText ?? t('showcases.item.comments.deleteConfirm.content_comment')}
        </span>

        <Button size="xs" loading={processing} onClick={handleConfirm}>
          {t('showcases.item.comments.deleteConfirm.deleteButton')}
        </Button>
      </div>
    </div>
  )
}
