import { useState } from 'react'

import { useQuery } from 'services/query'
import { EOrganizationMemberSort, ESorting } from 'enums'
import { getOrganizationMembers } from 'api/organizations'

import { useAccumulate } from '../useAccumulate'

export const ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY = 'organization-department-staff'

export type TUseFetchDepartmentStaffProps = {
  organizationId: string

  departmentId?: string
  sortOrder?: ESorting
  onChangeTotal?: (total: number) => void
  shouldFetchStaff?: boolean
}

export const useFetchDepartmentStaff = ({
  organizationId,
  departmentId,
  onChangeTotal,
  sortOrder,
  shouldFetchStaff = false
}: TUseFetchDepartmentStaffProps) => {
  const [page, setPage] = useState(0)

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: [ORGANIZATION_DEPARTMENT_STAFF_QUERY_KEY, page, organizationId, departmentId],
    queryFn: () =>
      departmentId
        ? getOrganizationMembers({
            page,
            organizationId,
            sortOrder,
            departmentIds: [departmentId],
            sortBy: EOrganizationMemberSort.DEPARTMENT_LEADER
          })
        : undefined,
    enabled: shouldFetchStaff && !!departmentId,
    cacheTime: 0,
    onSuccess: (response) => {
      if (onChangeTotal && response) {
        onChangeTotal(response.total)
      }
    }
  })

  const items = useAccumulate({ items: data?.results, page })

  return {
    page,
    items,
    setPage,
    isSuccess,
    isLoading: isFetching
  }
}
