import { useTranslation } from 'react-i18next'

import { SearchCascader, TSearchCascaderProps } from 'App/components/common/Fields/SearchCascader'
import {
  useAllContactsSearchByOptions,
  TUseAllContactsSearchByOptionsProps
} from 'App/hooks/SearchCascader/useAllContactsSearchByOptions'
import { EInputSize } from 'enums'

export type TAllContactsSearchCascaderProps = Pick<TSearchCascaderProps, 'onSearchChange'> &
  TUseAllContactsSearchByOptionsProps

export const AllContactsSearchCascader = ({
  onSearchChange,
  onChange
}: TAllContactsSearchCascaderProps) => {
  const { onCascaderChange, options, onLoadDepartments } = useAllContactsSearchByOptions({
    onChange
  })

  const { t } = useTranslation()

  return (
    <SearchCascader
      searchSize={EInputSize.L}
      placeholder={t('modal.broadcastMessage.recipientsStep.searchPlaceholder')}
      options={options}
      triggerInitialOnChange={true}
      loadData={onLoadDepartments}
      onSearchChange={onSearchChange}
      onCascaderChange={onCascaderChange}
    />
  )
}
