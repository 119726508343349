import { ProducedNotificationsEnum } from '@medentee/enums'

import { IDefaultStandaloneNotificationDTO } from 'interfaces'
import { State } from 'redux/rootReducer'
import { TIds } from 'store'

export const getCaseNotificationsSelector = (
  state: State,
  type: ProducedNotificationsEnum
): IDefaultStandaloneNotificationDTO[] =>
  state.caseView.data?.notifications.filter((item) => item.type === type) ?? []

export const getDescriptionLineId = (state: State, ids: TIds): string | null => {
  const notifications = getCaseNotificationsSelector(
    state,
    ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED
  )

  let result: null | string = null
  if (!ids.length || !notifications.length) {
    return null
  }

  const notificationsDescriptionIds = notifications.reduce<TIds>((acc, { payload }) => {
    acc.push(payload.id as string)
    return acc
  }, [])

  ids.forEach((id) => {
    if (result === null && notificationsDescriptionIds.includes(id)) {
      result = id
    }
  })

  return result
}

export const getIsActiveMemberSelector = (state: State) => {
  const accountId = state.global.accountData?.id

  return state.caseView.data?.owner?.id !== accountId
    ? state.caseView.data?.members?.some((member) => member.account.id === accountId)
    : true
}

export const getOpinionLineId = (state: State, ids: TIds): string | null => {
  const notifications = getCaseNotificationsSelector(
    state,
    ProducedNotificationsEnum.CASE_OPINION_CREATED
  )

  let lineId: null | string = null
  if (!ids.length || !notifications.length) {
    return null
  }

  const notificationsOpinionIds = notifications.reduce<TIds>((acc, { payload }) => {
    acc.push(payload.id as string)
    return acc
  }, [])

  ids.forEach((id) => {
    if (lineId === null && notificationsOpinionIds.includes(id)) {
      lineId = id
    }
  })

  return lineId
}
