import { TSubscriptionsAction, TSubscriptionsState } from 'store'

import * as actionTypes from './subscriptions.actionTypes'

export const initialSubscriptionsState: TSubscriptionsState = {
  active: {
    ids: [],
    list: {}
  },
  available: {
    ids: [],
    list: {}
  },
  current: null,
  capacity: {
    medCloudCapacityOccupied: 0,
    medCloudCapacityAvailable: 0,
    trashBinCapacityOccupied: 0,
    trashBinCapacityAvailable: 0
  }
}

export const subscriptionsReducer = (
  state: TSubscriptionsState = initialSubscriptionsState,
  action: TSubscriptionsAction
): TSubscriptionsState => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        active: action.payload,
        current: action.payload.list[Object.values(action.payload.ids)[0]]
      }
    }

    case actionTypes.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        available: action.payload
      }
    }

    case actionTypes.RESET_SUBSCRIPTIONS: {
      return {
        ...state,
        active: initialSubscriptionsState.active,
        available: initialSubscriptionsState.available
      }
    }

    case actionTypes.GET_MED_CLOUD_CAPACITY_SUCCESS: {
      return {
        ...state,
        capacity: action.payload
      }
    }

    default:
      return state
  }
}
