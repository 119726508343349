import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import { ContentState, EditorState } from 'draft-js'

import { validation } from 'utils'
import { EKeyCodes, EIconSize } from 'enums'
import { useEvent, usePrevious } from 'App/hooks'
import {
  DraftEditor,
  IconButton,
  TCommentProps,
  TDraftEditorClasses,
  TDraftEditorProps,
  useDraftEditor
} from 'App/components'
import { AccountSuggestion } from 'App/components/common/DraftEditor/AccountSuggestion'
import { FileSuggestion } from 'App/components/common/DraftEditor/FileSuggestion'
import {
  createMentionEntities,
  TAccountMention,
  TFileMention,
  TTrigger,
  wrapMentions
} from 'utils/mentions'
import { ReactComponent as PaperAirplaneIcon } from 'assets/icons/PaperAirplane.svg'
import { createMentionPlugin } from 'App/components/common/DraftEditor/Mention/Mention.utils'
import { IShowcaseComment } from 'interfaces/api/showcase'

import { EventBar, TEventBarProps } from './EventBar'
import styles from './CommentFieldWrapper.module.scss'

export type TOnSearchChangePayload = {
  trigger: TTrigger
  value: string
}

export type TCommentFieldWrapperProps = PropsWithClasses<
  TDraftEditorClasses | 'rootWrapper',
  Pick<TDraftEditorProps, 'helperText' | 'placeholder'>
> & {
  fileId: string
  draftMessage: string
  sendMessage: (text: string, callback: () => void) => void
  replyMessage: (text: string, quoteCommentId: string, callback: () => void) => void
  editMessage: (text: string, commentId: string, callback: () => void) => void
  updateDraftMessage: (text: string) => void
  refetchDraft: (callback?: () => void) => void
  onSearchChange: (event: TOnSearchChangePayload) => void

  loading?: boolean
  processing?: boolean
  editProcessing?: boolean
  replyProcessing?: boolean
  event?: Pick<TEventBarProps, 'close' | 'type'> &
    Partial<Pick<TCommentProps, 'author'>> & {
      commentId: string

      content?: string
      mentions?: IShowcaseComment['mentions']
    }
  accountSuggestions?: TAccountMention[]
  fileSuggestions?: TFileMention[]
  accountMentions?: TAccountMention[]
  fileMentions?: TFileMention[]
}

const MAX_LENGTH = 10000

const { Suggestions: AccountsSuggestions, plugins: accountPlugins } = createMentionPlugin({
  mentionPrefix: '@'
})

const { Suggestions: FileSuggestions, plugins: filePlugins } = createMentionPlugin({
  mentionTrigger: '/',
  mentionPrefix: '/'
})

const CommentFieldWrapperView = ({
  draftMessage,
  helperText,
  classes,
  event,
  editProcessing,
  replyProcessing,
  refetchDraft,
  editMessage,
  sendMessage,
  updateDraftMessage,
  replyMessage,
  onSearchChange,
  accountMentions = [],
  fileMentions = [],
  accountSuggestions = [],
  fileSuggestions = [],
  processing = false,
  placeholder = 'Tag item with /, users with @'
}: TCommentFieldWrapperProps) => {
  const [open, setOpen] = useState<'accounts' | 'files'>()

  const prevEvent = usePrevious(event)

  const mentions = useMemo(
    () => [...fileMentions, ...accountMentions],
    [accountMentions, fileMentions]
  )

  const {
    editorState,
    plainText,
    setEditorState,
    setEditorStateText,
    baseKeyCommandHandler,
    baseKeyBindingHandler
  } = useDraftEditor({
    draftMessage: createMentionEntities(draftMessage, mentions)
  })

  const isSendActionDisabled =
    plainText.length > MAX_LENGTH ||
    processing ||
    editProcessing ||
    replyProcessing ||
    !plainText.length

  const onSend = useCallback(() => {
    const message = wrapMentions(editorState)

    if (validation.maxLength(MAX_LENGTH)(plainText) || validation.required()(plainText)) {
      return
    }

    const handleSuccess = () => {
      setEditorStateText('')
    }

    if (!event?.type) {
      sendMessage(message, handleSuccess)
    }

    if (event?.type === 'reply' && event?.commentId) {
      replyMessage(message, event.commentId, handleSuccess)
    }

    if (event?.type === 'edit') {
      editMessage(message, event.commentId, handleSuccess)
    }
  }, [editMessage, editorState, event, plainText, sendMessage, setEditorStateText, replyMessage])

  const onEditorChange = (editorValue: EditorState) => {
    const message = editorValue.getCurrentContent().getPlainText()

    setEditorState(editorValue)

    if (!message.trim()) {
      setOpen(undefined)
    }

    if (message !== plainText && event?.type !== 'edit') {
      const formattedMessage = wrapMentions(editorValue)

      updateDraftMessage(formattedMessage)
    }
  }

  const keyBindingHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === EKeyCodes.ESCAPE && (event?.type === 'edit' || event?.type === 'reply')) {
      event.close()
    }
  }

  const moveFocusToEnd = useEvent((content?: string) => {
    setEditorState(
      EditorState.moveFocusToEnd(
        content
          ? EditorState.push(
              EditorState.createEmpty(),
              createMentionEntities(content, mentions),
              'insert-characters'
            )
          : editorState
      )
    )
  })

  const moveDraftFocusToEnd = useEvent(() => moveFocusToEnd(draftMessage))

  const clearReply = useEvent(() => {
    if (event?.type === 'reply') {
      updateDraftMessage('')
    }
  })

  const clearState = useEvent(() => {
    setEditorState(
      EditorState.push(editorState, ContentState.createFromText(''), 'delete-character')
    )
  })

  useEffect(() => {
    if (event?.type === 'edit') {
      moveFocusToEnd(event?.content)
    }
  }, [event, moveFocusToEnd])

  useEffect(() => {
    if (event?.type === 'reply') {
      moveFocusToEnd()
    }
  }, [event, moveFocusToEnd])

  useEffect(() => {
    if (prevEvent?.type === 'edit' && (!event || event?.type === 'reply')) {
      refetchDraft(() => {
        clearState()
        moveDraftFocusToEnd()
      })
    }
  }, [event, moveDraftFocusToEnd, clearState, prevEvent?.type, refetchDraft])

  useEffect(() => {
    if (prevEvent?.type && !event?.type) {
      moveFocusToEnd()
    }
  }, [event?.type, moveFocusToEnd, prevEvent?.type])

  useEffect(
    () => () => {
      clearReply()
    },
    [clearReply]
  )

  return (
    <>
      {event && <EventBar type={event.type} author={event.author} close={event.close} />}

      <div className={cls(styles.rootWrapper, classes?.rootWrapper)}>
        <DraftEditor
          editorState={editorState}
          onChange={onEditorChange}
          keyBindingFn={baseKeyBindingHandler(keyBindingHandler)}
          handleKeyCommand={baseKeyCommandHandler(onSend)}
          classes={classes}
          valueLengthMax={MAX_LENGTH}
          showMaxLength={false}
          placeholder={placeholder}
          helperText={helperText}
          readOnly={editProcessing || replyProcessing}
          endAdornment={
            <IconButton
              iconComponent={<PaperAirplaneIcon />}
              disabled={isSendActionDisabled}
              iconSize={EIconSize.MD}
              onClick={onSend}
              classes={{ root: styles.iconSending }}
            />
          }
          plugins={[...filePlugins, ...accountPlugins]}
        />
        <AccountsSuggestions
          open={open === 'accounts'}
          suggestions={accountSuggestions}
          entryComponent={AccountSuggestion}
          onOpenChange={(value) => setOpen(value ? 'accounts' : undefined)}
          onSearchChange={onSearchChange}
        />
        <FileSuggestions
          open={open === 'files'}
          suggestions={fileSuggestions}
          entryComponent={FileSuggestion}
          onOpenChange={(value) => setOpen(value ? 'files' : undefined)}
          onSearchChange={onSearchChange}
        />
      </div>
    </>
  )
}

export const CommentFieldWrapper = memo(CommentFieldWrapperView)
