import uniq from 'lodash/uniq'

import { PAGINATION_DEFAULT } from 'types'

import * as actionTypes from './broadcasts.actionTypes'
import { TBroadcastsAction, TBroadcastsState } from './broadcasts.types'

const initialState: TBroadcastsState = {
  activeBroadcast: null,
  watchers: {},
  data: {
    ids: [],
    list: {},
    filters: PAGINATION_DEFAULT
  }
}

export const broadcastsReducer = (
  state: TBroadcastsState = initialState,
  action: TBroadcastsAction
): TBroadcastsState => {
  switch (action.type) {
    case actionTypes.SET_BROADCAST_DATA: {
      const { list, ids, total } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            ...list
          },

          ids: uniq([...state.data.ids, ...ids]),
          filters: {
            ...state.data.filters,
            total
          }
        }
      }
    }

    case actionTypes.GET_BROADCASTS_SUCCESS: {
      const { list, ids, total } = action.payload
      const activeBroadcast = state.activeBroadcast

      return {
        ...state,
        activeBroadcast:
          activeBroadcast?.id && ids.includes(activeBroadcast.id)
            ? {
                ...activeBroadcast,
                ...list[activeBroadcast.id]
              }
            : activeBroadcast,
        data: {
          ...state.data,
          list,
          ids,
          filters: {
            ...state.data.filters,
            total
          }
        }
      }
    }

    case actionTypes.SET_ACTIVE_BROADCAST: {
      const { broadcastId, entityId } = action.payload
      const activeBroadcast = broadcastId ? { ...state.data.list[broadcastId], entityId } : null

      return {
        ...state,
        activeBroadcast
      }
    }

    case actionTypes.RECEIVE_UPDATE_BROADCAST_WATCHERS:
    case actionTypes.RECEIVE_UPDATE_BROADCAST_STATUS: {
      const { id, watchers = 0 } = action.payload

      return {
        ...state,
        watchers: {
          ...state.watchers,
          [id]: watchers
        }
      }
    }

    case actionTypes.RESET_BROADCAST_LIST: {
      return {
        ...state,
        data: initialState.data
      }
    }

    default:
      return state
  }
}
