import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppSelector } from 'store'
import { formatMonthYear } from 'utils'
import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as WebIcon } from 'assets/icons/Web.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'
import { IPublicationDTO } from 'interfaces/api/userProfile'

import { ItemHeader, ItemLabel, Item, ItemLink } from '../../common'

export type TPublicationsItemProps = {
  data: IPublicationDTO

  disableContextMenu?: boolean
}

export type TPublicationsItemContainerProps = Pick<TPublicationsItemProps, 'disableContextMenu'> & {
  id: string
}

export const PublicationsItemContainer = ({
  id,
  disableContextMenu
}: TPublicationsItemContainerProps) => {
  const data = useAppSelector((state) => state.userProfile.viewProfile.publications.list[id] || {})

  return (
    <Item>
      <PublicationsItem data={data} disableContextMenu={disableContextMenu} />
    </Item>
  )
}

export const PublicationsItem = ({ data, disableContextMenu }: TPublicationsItemProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { link, name, startDate, authors, source, type } = data

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <PencilOutlineIcon />,
        content: t('userProfile.publications.kebabMenu.edit'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.userPublication.title_edit'),
              modalType: EModalComponents.PUBLICATION_DIALOG,
              modalProps: {
                data
              }
            })
          )
      },
      {
        icon: <TrashIcon />,
        content: t('userProfile.publications.kebabMenu.remove'),
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.removePublicationConfirm.title'),
              modalType: EModalComponents.REMOVE_PUBLICATION_DIALOG,
              modalProps: {
                id: data.id
              }
            })
          )
        }
      }
    ],
    [data, dispatch, t]
  )

  return (
    <Row gutter={[0, 8]}>
      {name && (
        <Col xs={24}>
          <ItemHeader title={name} menuItems={!disableContextMenu ? menuItems : undefined} />
        </Col>
      )}
      {type && (
        <Col xs={24}>
          <ItemLabel icon={<FileIcon />} label={type} />
        </Col>
      )}

      {startDate && (
        <Col xs={24}>
          <ItemLabel icon={<ScheduleIcon />} label={formatMonthYear(startDate)} />
        </Col>
      )}

      {source && (
        <Col xs={24}>
          <ItemLabel icon={<WebIcon />} label={source} />
        </Col>
      )}

      {authors && (
        <Col xs={24}>
          <ItemLabel icon={<AccountMultipleIcon />} label={authors} />
        </Col>
      )}

      {link && (
        <Col xs={24}>
          <ItemLink link={link} />
        </Col>
      )}
    </Row>
  )
}
