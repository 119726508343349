import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { EChatTypeMap, CHAT_PATH } from 'globalConstants'
import { ButtonScheduleMeeting, TButtonScheduleMeetingProps } from 'App/components'
import { ButtonMessageContainer, TButtonMessageContainerProps } from 'App/containers'

import styles from './Actions.module.scss'

type TActions = Pick<TButtonScheduleMeetingProps, 'userName'> &
  Pick<TButtonMessageContainerProps, 'chatId'>

export const Actions = ({ chatId, userName }: TActions) => {
  const { push } = useHistory()

  const handleMessageClick = useCallback(() => {
    push(
      generatePath(CHAT_PATH, {
        chatId,
        chatType: EChatTypeMap.DIALOGUE
      })
    )
  }, [chatId, push])

  return (
    <div className={styles.root}>
      <ButtonMessageContainer chatId={chatId} onClick={handleMessageClick} />

      <ButtonScheduleMeeting chatId={chatId} userName={userName} />
    </div>
  )
}
