import { ReactNode } from 'react'
import { Row, Col } from 'antd'

type TFeatureProps = {
  icon: ReactNode
  description: string
}

export const Feature = ({ icon, description }: TFeatureProps) => (
  <Col xs={24}>
    <Row gutter={[12, 0]} wrap={false} align="middle">
      <Col>{icon}</Col>
      <Col>{description}</Col>
    </Row>
  </Col>
)
