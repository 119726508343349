import {
  actionCreator,
  TError,
  TGetOperationsHistoryRequestPayload,
  TGetOperationsHistorySuccessPayload,
  TGetUserBalanceSuccessPayload,
  TReceivePaymentSucceededPayload,
  TTransferMDTRequestPayload,
  TPaymentsHistoryFilters
} from 'store'

import * as actionTypes from './payments.actionTypes'

// ------------------ GET_USER_BALANCE ------------------

export const getUserBalanceRequest = () => actionCreator(actionTypes.GET_USER_BALANCE_REQUEST)
export const getUserBalanceSuccess = (payload: TGetUserBalanceSuccessPayload) =>
  actionCreator(actionTypes.GET_USER_BALANCE_SUCCESS, payload)
export const getUserBalanceError = (payload: TError) =>
  actionCreator(actionTypes.GET_USER_BALANCE_ERROR, payload)

// ------------------ GET_OPERATIONS_HISTORY ------------------

export const getOperationsHistoryRequest = (payload: TGetOperationsHistoryRequestPayload) =>
  actionCreator(actionTypes.GET_OPERATIONS_HISTORY_REQUEST, payload)
export const getOperationsHistorySuccess = (payload: TGetOperationsHistorySuccessPayload) =>
  actionCreator(actionTypes.GET_OPERATIONS_HISTORY_SUCCESS, payload)
export const getOperationsHistoryError = (payload: TError) =>
  actionCreator(actionTypes.GET_OPERATIONS_HISTORY_ERROR, payload)

export const clearOperationsHistoryAction = () =>
  actionCreator(actionTypes.CLEAR_OPERATIONS_HISTORY)

// ------------------ TRANSFER_MDT ------------------

export const transferMDTRequest = (payload: TTransferMDTRequestPayload) =>
  actionCreator(actionTypes.TRANSFER_MDT_REQUEST, payload)
export const transferMDTSuccess = () => actionCreator(actionTypes.TRANSFER_MDT_SUCCESS)
export const transferMDTError = (payload: TError) =>
  actionCreator(actionTypes.TRANSFER_MDT_ERROR, payload)
export const setOperationsHistoryFilter = (payload: Partial<TPaymentsHistoryFilters> | null) =>
  actionCreator(actionTypes.SET_OPERATIONS_HISTORY_FILTER, payload)

// ------------------ PAYMENT ------------------

export const receivePaymentSucceeded = (payload: TReceivePaymentSucceededPayload) =>
  actionCreator(actionTypes.RECEIVE_PAYMENT_SUCCEEDED, payload)

export const receivePaymentFailed = (payload: never) =>
  actionCreator(actionTypes.RECEIVE_PAYMENT_FAILED, payload)

export const autoTopUpSet = () => actionCreator(actionTypes.AUTO_TOP_UP_SET)
export const autoTopUpCanceled = () => actionCreator(actionTypes.AUTO_TOP_UP_CANCELED)
