import { TAction, TGetFilesHistorySuccess } from 'store'
import { Pagination, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_TOTAL } from 'types'
import { FILE_HISTORY_DEFAULT_SHOW_BY } from 'globalConstants'

import * as FILE_ACTIONS from './files.actions'
import {
  TChangeFileContactPermissionSuccess,
  TDiscardCasesPermissionsSuccess,
  TSetFileSorting,
  TFileTypes,
  TSetFileContactIds,
  TSetFileContactList,
  TSetFileDetailsId,
  TSetFileFiltersSuccess,
  TSetFilePagination,
  TSetFileList,
  TSetFileIds,
  TSetFileContactListSearch,
  TGetFilesCountsSuccess
} from './files.types'

export const FILE_LIST_DEFAULT_PAGINATION: Pagination = {
  current: PAGINATION_DEFAULT_PAGE,
  total: PAGINATION_DEFAULT_TOTAL,
  showBy: 20
}

export const initialFileListState: TFileTypes = {
  ids: [],
  fileList: {},
  counts: null,
  countsTotal: 0,
  pagination: FILE_LIST_DEFAULT_PAGINATION,
  filters: {},
  recordId: null,
  fileContacts: {
    search: '',
    list: {},
    ids: []
  },
  fileHistory: {
    historyItems: [],
    dateIds: [],
    groupedList: {},
    filters: {
      page: 0,
      total: 0,
      showBy: FILE_HISTORY_DEFAULT_SHOW_BY
    }
  }
}

export const filesReducer = (state = initialFileListState, action: TAction) => {
  switch (action.type) {
    case FILE_ACTIONS.SET_FILE_LIST: {
      return { ...state, fileList: (action as TSetFileList).payload.fileList }
    }
    case FILE_ACTIONS.SET_FILE_LIST_IDS: {
      return { ...state, ids: (action as TSetFileIds).payload.ids }
    }
    case FILE_ACTIONS.SET_FILE_LIST_PAGINATION: {
      return { ...state, pagination: (action as TSetFilePagination).payload.pagination }
    }
    case FILE_ACTIONS.SET_FILE_LIST_SORTING: {
      return { ...state, sorting: (action as TSetFileSorting).payload.sorting }
    }
    case FILE_ACTIONS.SET_FILE_LIST_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as TSetFileFiltersSuccess).payload
        },
        pagination: {
          ...state.pagination,
          current: 0
        }
      }
    }
    case FILE_ACTIONS.SET_FILE_DETAILS_ID: {
      return { ...state, recordId: (action as TSetFileDetailsId).payload.recordId }
    }
    case FILE_ACTIONS.SET_FILE_CONTACT_LIST: {
      const { fileContactList, fileContactIds } = (action as TSetFileContactList).payload

      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          list: fileContactList,
          ids: fileContactIds
        }
      }
    }

    case FILE_ACTIONS.SET_FILE_CONTACT_LIST_SEARCH: {
      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          search: (action as TSetFileContactListSearch).payload.search
        }
      }
    }

    case FILE_ACTIONS.CHANGE_FILE_CONTACT_PERMISSION_SUCCESS: {
      const { userId, permissions } = (action as TChangeFileContactPermissionSuccess).payload

      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          list: {
            ...state.fileContacts.list,
            [userId]: {
              ...state.fileContacts.list[userId],
              permissions
            }
          }
        }
      }
    }
    case FILE_ACTIONS.SET_FILE_CONTACT_IDS: {
      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          ids: (action as TSetFileContactIds).payload.fileContactIds
        }
      }
    }
    case FILE_ACTIONS.DISCARD_CASES_PERMISSIONS_SUCCESS: {
      const { contactId } = (action as TDiscardCasesPermissionsSuccess).payload

      return {
        ...state,
        fileContacts: {
          ...state.fileContacts,
          list: {
            ...state.fileContacts.list,
            [contactId]: {
              ...state.fileContacts.list[contactId],
              sharedInCasesCount: 0
            }
          }
        }
      }
    }

    case FILE_ACTIONS.FILES_RESET_ACTION: {
      return {
        ...initialFileListState
      }
    }

    case FILE_ACTIONS.FILE_DETAILS_RESET_ACTION: {
      return {
        ...state,
        recordId: null,
        fileContacts: {
          ...initialFileListState.fileContacts
        }
      }
    }

    case FILE_ACTIONS.GET_FILES_HISTORY_SUCCESS: {
      const { dateIds, groupedList, total, page, historyItems } = (
        action as TGetFilesHistorySuccess
      ).payload

      return {
        ...state,
        fileHistory: {
          ...state.fileHistory,
          historyItems,
          dateIds,
          groupedList,
          filters: {
            ...state.fileHistory.filters,
            page,
            total
          }
        }
      }
    }

    case FILE_ACTIONS.RESET_FILES_HISTORY: {
      return {
        ...state,
        fileHistory: {
          ...initialFileListState.fileHistory
        }
      }
    }

    case FILE_ACTIONS.GET_FILES_COUNTS_SUCCESS: {
      const { counts, total } = (action as TGetFilesCountsSuccess).payload

      return {
        ...state,
        counts,
        countsTotal: total
      }
    }

    case FILE_ACTIONS.RESET_FILES_FOR_CATEGORY: {
      return {
        ...state,
        ids: initialFileListState.ids,
        fileList: initialFileListState.fileList,
        pagination: initialFileListState.pagination,
        filters: initialFileListState.filters
      }
    }

    default:
      return state
  }
}
