import { useMemo } from 'react'

import { TFileList, TIds, TTrashBinList, TFileSharedList } from 'store'
import { formatBytes } from 'utils'

type TUseSelectedFilesSizeCalculation = (
  ids: TIds,
  fileList: TFileList | TTrashBinList | TFileSharedList
) => string

export const useSelectedFilesSizeCalculation: TUseSelectedFilesSizeCalculation = (ids, fileList) =>
  useMemo(() => {
    const totalFileSize = ids.reduce((prev, current) => {
      const fileSize = fileList[current]?.fileSize

      return fileSize ? prev + Number(fileSize) : 0
    }, 0)

    return formatBytes(totalFileSize)
  }, [ids, fileList])
