import { useMemo } from 'react'

import { isAdminAccountSelector, useAppSelector } from 'store'
import { UnityHubNav, TUnityHubNavProps, TUnityHubNavItem } from 'App/components/UnityHub'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

type TEventsNavProps = Pick<TUnityHubNavProps, 'loading'>

export const EventsNav = ({ loading }: TEventsNavProps) => {
  const { list, ids } = useAppSelector((state) => state.events.data)
  const notifications = useAppSelector((state) => state.notifications.general.events.list)
  const isAdminAccount = useAppSelector(isAdminAccountSelector)

  const items = useMemo<TUnityHubNavItem[]>(
    () =>
      ids.map((id) => ({
        id,
        to: `/events/${id}`,
        name: list[id]?.title,
        live: list[id]?.isLiveBroadcast,
        count: notifications?.[id]?.totalUnreadCount
      })),
    [ids, list, notifications]
  )

  const controls = useMemo<TUnityHubNavProps['controls']>(() => {
    if (!isAdminAccount) {
      return
    }

    return {
      items: [{ icon: <PlusIcon />, to: '/events/create', id: 'create-event' }],
      options: { bottom: false }
    }
  }, [isAdminAccount])

  if (!loading && !ids.length) {
    return null
  }

  return <UnityHubNav type="event" items={items} controls={controls} loading={loading} />
}
