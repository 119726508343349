import { normalize, schema } from 'normalizr'

import { IShowcaseCommentCounts } from '../../interfaces'
import { TIds } from '../store.types'

import { TCommentCountsNormalized, TCommentCountsList } from './showcases.types'

const commentCountersList = new schema.Entity(
  'list',
  {},
  {
    idAttribute: (value) => value.fileId
  }
)
const commentCountersListSchema = new schema.Array(commentCountersList)

export const commentCountersNormalize = (
  originalData: IShowcaseCommentCounts[]
): TCommentCountsNormalized => {
  const { entities, result } = normalize<
    IShowcaseCommentCounts,
    Normalized<TCommentCountsList>,
    TIds
  >(originalData, commentCountersListSchema)

  return {
    list: entities.list || {},
    ids: result || []
  }
}
