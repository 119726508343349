import { ChangeEventHandler } from 'react'
import cls from 'classnames'

import { TJitsiDevice } from 'types'
import { TruncatedText } from 'App/components'

import { VideoEntry } from '../VideoEntry'

import styles from './VideoDevicesList.module.scss'

export type TVideoDevicesListProps = {
  devices: TJitsiDevice[]

  currentDeviceId?: null | string
  onChange: ChangeEventHandler<HTMLInputElement>
}
const DEFAULT_DEVICES_LENGTH = 2

export const VideoDevicesList = ({
  currentDeviceId,
  devices,
  onChange
}: TVideoDevicesListProps) => (
  <div className={cls(styles.root, devices.length > DEFAULT_DEVICES_LENGTH && styles.rootScroll)}>
    {devices.map(({ deviceId, label, kind }) => (
      <label
        key={deviceId}
        className={cls(styles.item, currentDeviceId === deviceId && styles.itemActive)}
      >
        <input
          name="videoinput"
          type="radio"
          defaultValue={currentDeviceId ?? ''}
          value={deviceId}
          onChange={onChange}
        />
        <VideoEntry label={label} kind={kind} />

        <TruncatedText classes={{ text: styles.label }} text={label} />
      </label>
    ))}
  </div>
)
