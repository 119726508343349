export const isInternalUrl = (value: string) => {
  const { origin } = window.location
  const url = new URL(value)

  return origin.includes(url.origin)
}

export const getPathnameFromUrl = (value: string) => {
  const url = new URL(value)

  return url.pathname
}
