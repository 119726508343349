import { useEffect } from 'react'
import { Col } from 'antd'
import { useDispatch } from 'react-redux'
import { Field, useFormState, useForm } from 'react-final-form'
import { GetCountryQueryModeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { TextField, Select } from 'App/components'
import {
  getCountriesRequest,
  useAppSelector,
  getCitiesRequest,
  createLoadingSelector,
  GET_COUNTRIES,
  GET_CITIES
} from 'store'

import { TValues } from '../../UserProfileWorkExperienceDialog'

const DESCRIPTION_MAX_LENGTH = 1000
const countriesLoadingSelector = createLoadingSelector([GET_COUNTRIES])
const citiesLoadingSelector = createLoadingSelector([GET_CITIES])

const CityField = () => {
  const dispatch = useDispatch()

  const { values } = useFormState<TValues>()

  const { cities } = useAppSelector((state) => state.misc)
  const isCitiesLoading = useAppSelector(citiesLoadingSelector)
  const isCountriesLoading = useAppSelector(countriesLoadingSelector)

  const { t } = useTranslation()

  useEffect(() => {
    if (values.country) {
      dispatch(getCitiesRequest({ countryCode: values.country.code }))
    }
  }, [dispatch, values.country, isCountriesLoading])

  return (
    <Field name="city">
      {({ input, meta }) => (
        <Select
          {...input}
          options={cities}
          valueKey="id"
          labelKey="cityName"
          isLoading={isCitiesLoading || isCountriesLoading}
          invalid={meta.touched && meta.invalid}
          error={meta.error}
          topLabel={t('modal.userWorkplaces.form.cityField')}
        />
      )}
    </Field>
  )
}

export const UserProfileWorkExperienceDialogSecondStep = () => {
  const dispatch = useDispatch()

  const { change } = useForm()

  const { countries } = useAppSelector((state) => state.misc)
  const isCountriesLoading = useAppSelector(countriesLoadingSelector)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.ALL }))
  }, [dispatch])

  return (
    <>
      <Col xs={24}>
        <Field name="country">
          {({ input, meta }) => (
            <Select
              {...input}
              options={countries}
              valueKey="code"
              labelKey="countryName"
              isLoading={isCountriesLoading}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              topLabel={t('modal.userWorkplaces.form.countryField')}
              onChange={(value) => {
                change('city', undefined)
                input.onChange(value)
              }}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <CityField />
      </Col>
      <Col xs={24}>
        <Field
          name="description"
          validate={validation.composeValidators(
            validation.onlySpaces(),
            validation.maxLength(DESCRIPTION_MAX_LENGTH)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              multiline
              rowsMax={4}
              topLabel={t('modal.userWorkplaces.form.descriptionField')}
              valueLengthMax={DESCRIPTION_MAX_LENGTH}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
