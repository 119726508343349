import { useCallback } from 'react'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  approveBusinessAdminInviteRequest,
  APPROVE_BUSINESS_ADMIN_INVITE,
  createLoadingSelector,
  createProcessingBySelector,
  GET_ORGANIZATIONS_INVITATIONS,
  REMOVE_BUSINESS_ADMIN_INVITE,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EBusinessAdminConfirmFrom, EBusinessAdminDeleteAction } from 'enums'
import { InvitationItemReceived } from 'App/components'
import { EModalComponents } from 'App/containers'

import { OrganizationsInvitationsReceivedItemMessage } from '../OrganizationsInvitationsReceivedItemMessage'

export type TOrganizationsInvitationsReceivedItemProps = {
  id: string

  organizationId?: string
  from?: EBusinessAdminConfirmFrom
}

export const OrganizationsInvitationsReceivedItem = ({
  id,
  organizationId,
  from = EBusinessAdminConfirmFrom.STAFF_RECEIVED
}: TOrganizationsInvitationsReceivedItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { businessAccount, role, createdAt, viewed } = useAppSelector(
    (state) => state.organizations.invitations.received.list[id] ?? {}
  )

  const { city, country, displayUserName, firstName, id: userId, lastName, type } = businessAccount

  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATIONS_INVITATIONS]))
  const acceptProcessing = useAppSelector(
    createProcessingBySelector([APPROVE_BUSINESS_ADMIN_INVITE])(id)
  )
  const rejectProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_BUSINESS_ADMIN_INVITE])(id)
  )

  const handleAcceptInvite = useCallback(() => {
    dispatch(
      approveBusinessAdminInviteRequest({
        id,
        from,
        organizationId,
        direction: BusinessAccountDirectionEnum.OUTGOING,
        processingId: id
      })
    )
  }, [dispatch, id, from, organizationId])

  const handleRejectInvite = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title_reject'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id,
          organizationId,
          type: EBusinessAdminDeleteAction.REJECT,
          from,
          role: role.name,
          direction: BusinessAccountDirectionEnum.OUTGOING
        }
      })
    )
  }, [id, from, role.name, organizationId, dispatch, t])

  return (
    <InvitationItemReceived
      sender={{
        city,
        country,
        displayUserName,
        firstName,
        id: userId,
        lastName,
        type
      }}
      createdAt={createdAt}
      viewed={viewed}
      message={
        <OrganizationsInvitationsReceivedItemMessage
          displayUserName={businessAccount.displayUserName}
          role={role}
        />
      }
      loading={loading}
      acceptProcessing={acceptProcessing}
      rejectProcessing={rejectProcessing}
      onAccept={handleAcceptInvite}
      onReject={handleRejectInvite}
    />
  )
}
