import { normalize, schema } from 'normalizr'

import { ISetAllUsersOnlineDTO } from 'interfaces'

import { TOnlineNormalized, TOnlineNormalizedResult } from './online.types'

const onlineSchema = new schema.Entity('list')
const onlineListSchema = new schema.Array(onlineSchema)

export const onlineNormalize = (originalData: ISetAllUsersOnlineDTO[]): TOnlineNormalized => {
  const { entities, result }: TOnlineNormalizedResult = normalize(originalData, onlineListSchema)

  return {
    list: entities.list || {},
    ids: result || []
  }
}
