import { PropsWithChildren, useEffect } from 'react'
import { createPortal } from 'react-dom'

export type TPortalWrapper = PropsWithChildren<
  PropsWithClassName<{
    portalCssSelector: string
  }>
>

export const PortalWrapper = ({ children, portalCssSelector, className }: TPortalWrapper) => {
  const mount = document.getElementById(portalCssSelector)

  useEffect(() => {
    if (className && mount) {
      mount.classList.add(className)
    }
  }, [mount, className])

  if (!mount) {
    return null
  }

  return createPortal(children, mount)
}
