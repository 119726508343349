import { components, DropdownIndicatorProps, GroupBase } from 'react-select'
import cls from 'classnames'

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'

import styles from './DropdownIndicator.module.scss'

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>({
  selectProps,
  ...rest
}: DropdownIndicatorProps<Option, IsMulti, Group>) =>
  !selectProps.hideChevron ? (
    <components.DropdownIndicator {...rest} selectProps={selectProps} className={styles.root}>
      <ChevronDownIcon
        className={cls(styles.icon, {
          [styles.iconOpen]: selectProps.menuIsOpen
        })}
      />
    </components.DropdownIndicator>
  ) : (
    <></>
  )
