export const GET_BROADCASTS = 'GET_BROADCASTS'
export const GET_BROADCASTS_REQUEST = 'GET_BROADCASTS_REQUEST'
export const GET_BROADCASTS_SUCCESS = 'GET_BROADCASTS_SUCCESS'
export const GET_BROADCASTS_ERROR = 'GET_BROADCASTS_ERROR'

export const RECEIVE_BROADCAST_CREATED = 'RECEIVE_BROADCAST_CREATED'
export const RECEIVE_BROADCAST_DELETED = 'RECEIVE_BROADCAST_DELETED'

export const RECEIVE_UPDATE_BROADCAST_WATCHERS = 'RECEIVE_UPDATE_BROADCAST_WATCHERS'
export const RECEIVE_UPDATE_BROADCAST_STATUS = 'RECEIVE_UPDATE_BROADCAST_STATUS'

export const RECEIVE_BROADCAST_WATCHED = 'RECEIVE_BROADCAST_WATCHED'

export const SET_BROADCAST_DATA = 'SET_BROADCAST_DATA'

export const SET_ACTIVE_BROADCAST = 'SET_ACTIVE_BROADCAST'

export const OPEN_BROADCAST = 'OPEN_BROADCAST'

export const RESET_BROADCAST_LIST = 'RESET_BROADCAST_LIST'
