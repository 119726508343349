import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { CaseHistoryFilterModal } from 'App/components/Case/CaseHistory/CaseHistoryFilterModal'
import { hideModalAction } from 'store'

export type CaseHistoryFilterModalContainerProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalAction
    },
    dispatch
  )

export const CaseHistoryFilterModalContainer = connect(
  null,
  mapDispatchToProps
)(CaseHistoryFilterModal)
