import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import copy from 'copy-to-clipboard'

import { useMutation } from 'services/query'
import { TMenuItemProps } from 'App/components'
import {
  accountIdSelector,
  isAdminAccountSelector,
  RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TChannelVariant } from 'interfaces'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'
import { UnityHubMarkAsRead } from 'App/components/UnityHub'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/VolumeUp.svg'
import { ReactComponent as VolumeOffIcon } from 'assets/icons/VolumeOff.svg'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { EModalComponents } from '../../../containers/ModalRoot'
import { useMuteTextChannel } from '../../../containers/Chat/useMuteTextChannel'
import { generateJoinToUnityHubChannelLink } from '../../../../utils'
import { leaveOrganizationChannel, leaveOrganizationVoiceRoom } from '../../../../api'
import { skipWSAction } from '../../../../services/skipWSActions'

type TUseKebabMenuProps = {
  organizationId: string
}

type TMenuItemsProps = {
  channelId: string
  variant: TChannelVariant
  channelName: string

  inviteToken?: string
  isMuted?: boolean
  isPublic: boolean
  isGroup?: boolean
  channelManagerId?: string
  onEditSuccess?: () => void
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = ({ organizationId }: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const isAdmin = useAppSelector(isAdminAccountSelector)

  const { getOnClickHandler: onEditVoiceChannel } = useOrganizationSubscription<
    Pick<TMenuItemsProps, 'channelId' | 'channelName' | 'isPublic'>
  >({
    variant: 'upgrade',
    organizationId,
    callback: (props) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.createChannel.edit.title_voice'),
          modalType: EModalComponents.CREATE_VOICE_CHANNEL,
          modalProps: {
            organizationId,
            isPublic: props?.isPublic,
            channelName: props?.channelName,
            channelId: props?.channelId
          }
        })
      )
    }
  })

  const { getOnClickHandler: onEditTextChannel } = useOrganizationSubscription<
    Pick<TMenuItemsProps, 'channelId' | 'channelName' | 'onEditSuccess' | 'isPublic'>
  >({
    organizationId,
    callback: (props) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.createChannel.edit.title_chat'),
          modalType: EModalComponents.CREATE_TEXT_CHANNEL,
          modalProps: {
            organizationId,
            channelName: props?.channelName,
            channelId: props?.channelId,
            onEditSuccess: props?.onEditSuccess,
            isPublic: props?.isPublic
          }
        })
      )
    }
  })

  const { getOnClickHandler: onManageMembers } = useOrganizationSubscription<
    Pick<TMenuItemsProps, 'channelId'>
  >({
    organizationId,
    callback: (props) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.createChannel.selectMembers.title'),
          modalType: EModalComponents.INVITE_MEMBERS_TEXT_CHANNEL,
          modalProps: {
            organizationId,
            channelId: props?.channelId ?? ''
          }
        })
      )
    }
  })

  const { handleUnmuteTextChannel, getInnerMenuItems } = useMuteTextChannel()

  const { mutateAsync: leaveChannel } = useMutation({
    mutationKey: ['leave-organization-channel', organizationId],
    mutationFn: leaveOrganizationChannel
  })
  const { mutateAsync: leaveVoiceChannel } = useMutation({
    mutationKey: ['leave-organization-voice-channel', organizationId],
    mutationFn: leaveOrganizationVoiceRoom
  })

  const menuItems = useMemo(
    () => ({
      markAsRead: ({ channelId }: TMenuItemsProps) => ({
        content: '',
        render: () => (
          <UnityHubMarkAsRead
            channelId={channelId}
            entity="organizations"
            entityId={organizationId}
          />
        )
      }),
      mute: ({ channelId, isMuted }: TMenuItemsProps) => ({
        content: isMuted
          ? t('unityHub.channelsList.kebabMenu.unmute')
          : t('unityHub.channelsList.kebabMenu.mute'),
        icon: isMuted ? <VolumeUpIcon /> : <VolumeOffIcon />,
        innerMenuItems: isMuted ? undefined : getInnerMenuItems(channelId),
        onClick: isMuted ? () => handleUnmuteTextChannel(channelId) : undefined
      }),
      manageMembers: ({ channelId, channelManagerId, isPublic }: TMenuItemsProps) => ({
        hidden: isPublic || !(isAdmin || channelManagerId === accountId),
        content: t('unityHub.channelsList.kebabMenu.manageMembers'),
        icon: <AccountMultiplePlusIcon />,
        onClick: onManageMembers({ channelId })
      }),
      edit: ({
        channelId,
        channelName,
        channelManagerId,
        onEditSuccess,
        isPublic
      }: TMenuItemsProps) => ({
        hidden: !(isAdmin || channelManagerId === accountId),
        content: t('unityHub.channelsList.kebabMenu.edit'),
        icon: <PencilIcon />,
        onClick: onEditTextChannel({ channelId, channelName, onEditSuccess, isPublic })
      }),
      editVoice: ({ channelId, channelName, isPublic }: TMenuItemsProps) => ({
        hidden: !isAdmin,
        content: t('unityHub.channelsList.kebabMenu.edit'),
        icon: <PencilIcon />,
        onClick: onEditVoiceChannel({ channelId, channelName, isPublic })
      }),
      delete: ({ channelId, variant }: TMenuItemsProps) => ({
        hidden: !isAdmin,
        content: t('unityHub.channelsList.kebabMenu.delete'),
        icon: <TrashIcon />,
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteChannelConfirm.title'),
              modalType:
                variant === 'voice'
                  ? EModalComponents.ORGANIZATION_DELETE_VOICE_CHANNEL_CONFIRM
                  : EModalComponents.ORGANIZATION_DELETE_TEXT_CHANNEL_CONFIRM,
              modalProps: {
                channelId,
                organizationId
              }
            })
          )
        }
      }),
      invite: ({ isPublic, isGroup, variant, channelManagerId, inviteToken }: TMenuItemsProps) => ({
        hidden: variant !== 'chat' || isPublic || !(isAdmin || channelManagerId === accountId),
        content: t('unityHub.channelsList.kebabMenu.copyLink'),
        icon: <LinkChainIcon />,
        disabled: isGroup,
        disabledTooltip: t('unityHub.channelsList.kebabMenu.disabledHint'),
        onClick: () => {
          if (!inviteToken) {
            return
          }

          copy(
            generateJoinToUnityHubChannelLink('organization', 'chat', organizationId, inviteToken)
          )

          toast.success(t('common.toast.linkCopied'), toastDefaultOptions)
        }
      }),
      leave: ({ isPublic, isGroup, channelId, variant }: TMenuItemsProps) => ({
        hidden: isPublic || isAdmin,
        content: t('unityHub.channelsList.kebabMenu.leave'),
        icon: <LogoutIcon />,
        disabled: isGroup,
        disabledTooltip: t('unityHub.channelsList.kebabMenu.disabledHint'),
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.leaveChannelConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                content: t('modal.leaveChannelConfirm.content_organization'),
                confirmLabel: t('modal.leaveChannelConfirm.submitButton'),
                onConfirm: () => {
                  skipWSAction({
                    type: RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL,
                    payload: { chatId: channelId }
                  })

                  if (variant === 'voice') {
                    return leaveVoiceChannel({ organizationId, roomId: channelId })
                  }

                  return leaveChannel({ id: organizationId, channelId })
                }
              }
            })
          )
        }
      })
    }),
    [
      organizationId,
      t,
      getInnerMenuItems,
      handleUnmuteTextChannel,
      isAdmin,
      accountId,
      onManageMembers,
      onEditTextChannel,
      onEditVoiceChannel,
      dispatch,
      leaveChannel,
      leaveVoiceChannel
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) =>
      props.variant === 'chat'
        ? [
            menuItems.markAsRead(props),
            menuItems.mute(props),
            menuItems.manageMembers(props),
            menuItems.edit(props),
            menuItems.invite(props),
            menuItems.delete(props),
            menuItems.leave(props)
          ]
        : [menuItems.editVoice(props), menuItems.delete(props), menuItems.leave(props)],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
