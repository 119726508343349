import { normalize, schema } from 'normalizr'
import { CallTypeEnum } from '@medentee/enums'

import { TIds } from 'store/store.types'

import { TOngoingCall, TOngoingCalls } from './calls.types'

const listSchema = new schema.Entity('list', undefined, {
  idAttribute: (value) => value.callId || value.voiceRoom.id
})
const callId = new schema.Array(listSchema)

export const ongoingCallsNormalize = (originalData: TOngoingCall[] = []) => {
  const { entities, result } = normalize<TOngoingCall, TOngoingCalls, TIds>(originalData, callId)

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const isMicOn = (type: CallTypeEnum) =>
  type === CallTypeEnum.P2P || type === CallTypeEnum.P2P_MEETING
