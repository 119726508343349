import { PropsWithChildren } from 'react'
import { Col, Row, Skeleton as OriginalSkeleton } from 'antd'

import { useAdaptiveLayout } from 'App/hooks'

import styles from './Skeleton.module.scss'

export type TSkeletonProps = PropsWithChildren<{
  loading: boolean
}>

const SKELETON_ROWS = 10
const LIST = Array.from({ length: SKELETON_ROWS })

export const Skeleton = ({ children, loading }: TSkeletonProps) => {
  const { isMobile } = useAdaptiveLayout()

  return (
    <>
      {loading
        ? LIST.map((_, index) => (
            <Row key={index} align="middle" justify="space-between" className={styles.root}>
              <Col flex={3}>
                <Row align="middle">
                  <OriginalSkeleton.Avatar
                    active={true}
                    size={24}
                    shape="square"
                    className={styles.avatarSquare}
                  />
                  <OriginalSkeleton.Avatar
                    active={true}
                    size={isMobile ? 20 : 30}
                    className={styles.avatar}
                  />
                  <OriginalSkeleton.Input active={true} size="small" className={styles.input} />
                </Row>
              </Col>

              {!isMobile && (
                <Row align="middle" justify="space-between">
                  <OriginalSkeleton.Button active={true} size="small" className={styles.button} />
                  <OriginalSkeleton.Input active={true} size="small" className={styles.input} />
                </Row>
              )}
            </Row>
          ))
        : children}
    </>
  )
}
