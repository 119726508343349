import { memo, PropsWithChildren, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd'
import isEqual from 'lodash/isEqual'

import { IJitsiMeetAPI, TJitsiCurrentDevices, TJitsiAvailableDevices } from 'types/jitsi'
import { Divider } from 'App/components'
import { useEvent } from 'App/hooks'

import { AudioDevicesList } from '../AudioDevicesList'
import { DevicesWrapper } from '../DevicesWrapper'

import { changeDevice, removeEmptyValues } from './utils'

export type TAudioDevicesWrapperProps = PropsWithChildren<{
  jitsiAPI: IJitsiMeetAPI
  isFullscreen: boolean

  shouldShowWrapper?: boolean
}>

const AudioDevicesView = ({
  jitsiAPI,
  isFullscreen,
  children,
  shouldShowWrapper = true
}: TAudioDevicesWrapperProps) => {
  const [isVisible, setVisibleChange] = useState<boolean>(false)
  const [isMultipleAudioInputSupported, setMultipleAudioInputSupported] = useState<boolean>(false)

  const [availableDevices, setAvailableDevices] = useState<
    Omit<TJitsiAvailableDevices, 'videoInput'>
  >({ audioInput: [], audioOutput: [] })
  const [currentDevices, setCurrentDevices] = useState<Omit<TJitsiCurrentDevices, 'videoInput'>>({
    audioInput: null,
    audioOutput: null
  })

  const hasAvailableInputDevices = Boolean(availableDevices.audioInput.length)
  const hasAvailableOutputDevices = Boolean(availableDevices.audioOutput.length)

  const shouldHideDevices =
    (!hasAvailableInputDevices && !hasAvailableOutputDevices) || !isFullscreen

  const updateCurrentDevices = useEvent((devices: TJitsiCurrentDevices) => {
    if (!isEqual(currentDevices, devices)) {
      setCurrentDevices(devices)
    }
  })

  const updateAvailableDevices = useEvent(async (devices: TJitsiAvailableDevices) => {
    if (!isEqual(availableDevices, devices)) {
      setAvailableDevices(devices)
    }
  })

  const getDevicesList = useCallback(async () => {
    try {
      const isMultiple = await jitsiAPI.isMultipleAudioInputSupported()
      setMultipleAudioInputSupported(isMultiple)

      if (!isMultiple) {
        return
      }

      const available = await jitsiAPI.getAvailableDevices()
      const current = await jitsiAPI.getCurrentDevices()

      setAvailableDevices(removeEmptyValues(available))
      setCurrentDevices(current)
    } catch (error) {
      console.error(error)
    }
  }, [jitsiAPI])

  const handleVisibleChange = (visible: boolean) => {
    if (visible) {
      getDevicesList()
    }

    setVisibleChange(visible)
  }

  const handleInputChange = useCallback(
    async ({ target: { value } }: RadioChangeEvent) => {
      const nextDevice = availableDevices.audioInput.find(({ deviceId }) => deviceId === value)

      await changeDevice(jitsiAPI, 'input', nextDevice?.label, nextDevice?.deviceId)
      await getDevicesList()
    },
    [availableDevices.audioInput, getDevicesList, jitsiAPI]
  )

  const handleOutputChange = useCallback(
    async ({ target: { value } }: RadioChangeEvent) => {
      const nextDevice = availableDevices.audioOutput.find(({ deviceId }) => deviceId === value)

      await changeDevice(jitsiAPI, 'output', nextDevice?.label, nextDevice?.deviceId)
      await getDevicesList()
    },
    [availableDevices.audioOutput, getDevicesList, jitsiAPI]
  )

  useLayoutEffect(() => {
    getDevicesList()

    const handler = ({ devices }: { devices: TJitsiAvailableDevices }) => {
      updateAvailableDevices(removeEmptyValues(devices))
    }

    jitsiAPI.addEventListener('deviceListChanged', handler)

    return () => {
      jitsiAPI.removeEventListener('deviceListChanged', handler)
    }
  }, [jitsiAPI, getDevicesList, updateAvailableDevices])

  useEffect(() => {
    const getCurrentDevices = async () => {
      try {
        const devices = await jitsiAPI.getCurrentDevices()
        updateCurrentDevices(devices)
      } catch (error) {
        console.error(error)
      }
    }

    getCurrentDevices()
  }, [jitsiAPI, updateCurrentDevices, isVisible])

  return (
    <>
      {children}

      {shouldShowWrapper && (
        <DevicesWrapper
          isVisible={isVisible}
          onVisibleChange={handleVisibleChange}
          shouldHideDevices={shouldHideDevices}
          disabledIcon={!isMultipleAudioInputSupported}
        >
          {hasAvailableInputDevices && (
            <AudioDevicesList
              type="input"
              devices={availableDevices.audioInput}
              currentDeviceId={currentDevices.audioInput?.deviceId}
              onChange={handleInputChange}
            />
          )}

          {hasAvailableInputDevices && hasAvailableOutputDevices && <Divider spaces={[20, 16]} />}

          {hasAvailableOutputDevices && (
            <AudioDevicesList
              type="output"
              devices={availableDevices.audioOutput}
              currentDeviceId={currentDevices.audioOutput?.deviceId}
              onChange={handleOutputChange}
            />
          )}
        </DevicesWrapper>
      )}
    </>
  )
}

export const AudioDevicesWrapper = memo(AudioDevicesView)
