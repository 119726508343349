import { useTranslation } from 'react-i18next'

import { CurrentBalanceContainer } from 'App/containers'
import { ReactComponent as CoinsOutlineIcon } from 'assets/icons/CoinsOutline.svg'

import styles from './HeaderUserBalance.module.scss'

export const HeaderUserBalance = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.balance}>
        <CoinsOutlineIcon className={styles.icon} />
        {t('headerMenu.links.balance')}
      </div>
      <CurrentBalanceContainer />
    </div>
  )
}
