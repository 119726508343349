import { FC, ReactNode, RefObject } from 'react'

import styles from './CaseDetailsContainer.module.scss'

export type TCaseDetailsContainerProps = {
  children?: ReactNode
  footer?: ReactNode
  content: ReactNode
  wrapperRef?: RefObject<HTMLDivElement>
}

export const CaseDetailsContainer: FC<TCaseDetailsContainerProps> = ({
  children,
  footer,
  content,
  wrapperRef
}) => (
  <>
    <div className={styles.wrapper} ref={wrapperRef}>
      {content}
      {children}
    </div>
    {footer && <div className={styles.footer}>{footer}</div>}
  </>
)
