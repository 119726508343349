import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CallRecipient } from 'App/components'
import { accountAvatarURLSelector } from 'store'

export type TCallRecipientContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  accountData: state.global.accountData,
  avatarURL: accountAvatarURLSelector(state),
  chatId: state.calls.answer.createCall?.chatId ?? null,
  selectedChatId: state.chat.chatRooms.selectedChat?.id ?? null,
  startTime: state.calls.answer.createCall?.startTime ?? null,
  callId: state.calls.answer.createCall?.id ?? null,
  jwt: state.calls.answer.createCall?.jwt ?? '',
  isMicOn: state.calls.answer.isMicOn
})

export const CallRecipientContainer = connect(mapStateToProps)(CallRecipient)
