import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'

import styles from './DrawerFilterActions.module.scss'

type TDrawerFilterActionsProps = {
  onDrawerClose: () => void
  resetFilter: () => void
}

export const DrawerFilterActions = ({ onDrawerClose, resetFilter }: TDrawerFilterActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Button className={styles.button} onClick={onDrawerClose}>
        {t('common.filters.drawer.showResultsButton')}
      </Button>
      <button className={styles.link} onClick={resetFilter}>
        {t('common.filters.drawer.clearAllButton')}
      </button>
    </div>
  )
}
