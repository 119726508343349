import { ReactNode, useMemo } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import {
  Avatar,
  Button,
  Chip,
  IconLabel,
  TAvatarProps,
  TIconLabelProps,
  UploadCover
} from 'App/components/common'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'

import { UnityHubInfoSkeleton } from './UnityHubInfoSkeleton'
import styles from './UnityHubInfo.module.scss'
import { TUnityHubInfoOwnerProps, UnityHubInfoOwner } from './UnityHubInfoOwner'
import { EUnityHubSharePopoverType, UnityHubSharePopover } from './UnityHubSharePopover'

type TUnityHubInfoProps = Pick<TAvatarProps, 'invitationToken' | 'isCommunity'> &
  Pick<TUnityHubInfoOwnerProps, 'socialLinks'> & {
    details: TIconLabelProps[]
    title: string
    aboutTitle: string
    joinButtonLabel: string
    shouldShowJoin: boolean
    shouldShowGoToChannels: boolean
    isFetching: boolean
    isJoinProcessing: boolean
    onJoin: () => void
    onGoToChannels: () => void
    isPublic: boolean

    id?: string
    coverFileUrl?: string
    owner?: Omit<TUnityHubInfoOwnerProps, 'socialLinks'>
    about?: string
    headline?: ReactNode
    members?: ReactNode
    tags?: string[]
    event?: { dateLabel: string | null | undefined }
  }

export const UnityHubInfo = ({
  id,
  invitationToken,
  owner,
  details,
  headline,
  members,
  tags,
  coverFileUrl,
  title,
  about,
  aboutTitle,
  joinButtonLabel,
  socialLinks,
  shouldShowJoin,
  shouldShowGoToChannels,
  isCommunity,
  event,
  isFetching,
  isJoinProcessing,
  onJoin,
  onGoToChannels,
  isPublic
}: TUnityHubInfoProps) => {
  const { isTablet, isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()
  const sharePageData = useMemo(() => {
    if (isCommunity) {
      return { text: title, type: EUnityHubSharePopoverType.COMMUNITY }
    }
    if (event) {
      return { text: `${event.dateLabel} ${title}`, type: EUnityHubSharePopoverType.EVENT }
    }

    return null
  }, [event, title, isCommunity])

  const avatarSize = useMemo(() => {
    if (isMobile) {
      return EAvatarSize.MD
    }

    if (isTablet) {
      return EAvatarSize.LG
    }

    return EAvatarSize.LLG
  }, [isMobile, isTablet])

  const shouldShowDescription = shouldShowJoin || headline || members

  if (isFetching || !owner || !id || !coverFileUrl) {
    return (
      <div className={styles.root}>
        <UnityHubInfoSkeleton />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.images}>
        <div className={styles.cover}>
          <UploadCover file={coverFileUrl} />
        </div>

        <Avatar
          invitationToken={invitationToken}
          firstName=""
          lastName=""
          size={avatarSize}
          isEvent={!!event}
          isCommunity={isCommunity}
          userId={id}
          type={AccountTypeNames.BUSINESS}
          displayUserName={title}
          border={true}
          className={styles.avatar}
        />
      </div>

      <div
        className={cls(styles.content, {
          [styles.content]: about,
          [styles.withDescription]: shouldShowDescription && !about,
          [styles.withAbout]: about && !shouldShowDescription,
          [styles.complete]: about && shouldShowDescription
        })}
      >
        <div className={styles.head}>
          {shouldShowGoToChannels && (
            <Button
              variant="inline"
              fontWeight="bold"
              icon={<ChevronLeftIcon />}
              onClick={onGoToChannels}
            >
              {t('unityHub.goToChannelsButton')}
            </Button>
          )}
          <h3 className={styles.title}>{title}</h3>

          <UnityHubInfoOwner
            displayUserName={owner.displayUserName}
            firstName={owner.firstName}
            lastName={owner.lastName}
            userId={owner.userId}
            isCommunity={owner.isCommunity}
            socialLinks={socialLinks}
            shouldRedirectToUserProfile={owner.shouldRedirectToUserProfile}
          />
        </div>

        {shouldShowDescription && (
          <div className={styles.description}>
            {shouldShowJoin && (
              <Button className={styles.action} onClick={onJoin} loading={isJoinProcessing}>
                {joinButtonLabel}
              </Button>
            )}

            {headline}

            {members}
          </div>
        )}

        {isPublic && sharePageData && (
          <div>
            <UnityHubSharePopover id={id} {...sharePageData} />
          </div>
        )}

        <div className={styles.user}>
          {details.map(({ icon, label, align }, index) => (
            <IconLabel
              key={index}
              icon={icon}
              label={label}
              align={align}
              classes={{
                root: styles.details,
                label: styles.detailsLabel,
                icon: styles.detailsIcon
              }}
            />
          ))}

          {!!tags?.length && (
            <div className={styles.tags}>
              {tags.map((tag, index) => (
                <Chip variant="light" key={index} text={tag} className={styles.tag} />
              ))}
            </div>
          )}
        </div>

        {about && (
          <div className={styles.about}>
            <h3 className={styles.aboutTitle}>{aboutTitle}</h3>

            <div>{about}</div>
          </div>
        )}
      </div>
    </div>
  )
}
