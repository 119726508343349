import { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import { ESet2FAType } from 'enums'
import {
  createProcessingSelector,
  set2FASettingsError,
  set2FASettingsRequest,
  clear2FASettingsAction,
  SET_2FA_SETTINGS,
  hideModalAction
} from 'store'
import { SettingsSecurityAndLogin2FAConfirm, TwoFactorConfirm } from 'App/components'
import { parseError, isMatchErrorCode } from 'utils'

export type TSettingsSecurityAndLogin2FAConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const SettingsSecurityAndLogin2FAConfirmView = ({
  hasConnectedAccount,
  error,
  set2FASettings,
  enabled,
  expiredTokenDate,
  processing,
  clearError,
  clear2FASettings,
  hideModal
}: TSettingsSecurityAndLogin2FAConfirmContainerProps) => {
  const { t } = useTranslation()

  const [show2FA, setShow2FA] = useState(false)

  const type = enabled ? ESet2FAType.DISABLE : ESet2FAType.ENABLE

  const handleAcceptClick = useCallback(() => {
    set2FASettings({ type })
  }, [set2FASettings, type])

  useEffect(() => {
    if (!hasConnectedAccount) {
      set2FASettings({ type })
    }
  }, [set2FASettings, type, hasConnectedAccount])

  useEffect(() => {
    if (expiredTokenDate && !show2FA) {
      setShow2FA(true)
    }
  }, [expiredTokenDate, show2FA])

  if (!show2FA && isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)) {
    return (
      <TwoFactorConfirm
        buttonText={t('common.2FA.confirm.submitButton_gotIt')}
        title={parseError(error)}
        onClick={hideModal}
      />
    )
  }

  if (hasConnectedAccount && !show2FA) {
    return <TwoFactorConfirm onClick={handleAcceptClick} error={error} processing={processing} />
  }

  return show2FA ? (
    <SettingsSecurityAndLogin2FAConfirm
      error={error}
      set2FASettings={set2FASettings}
      type={type}
      expiredTokenDate={expiredTokenDate}
      processing={processing && Boolean(expiredTokenDate)}
      clearError={clearError}
      clear2FASettings={clear2FASettings}
    />
  ) : null
}

const processingSelector = createProcessingSelector([SET_2FA_SETTINGS])

const mapStateToProps = (state: State) => ({
  hasConnectedAccount: state.global.accountData?.hasConnectedAccount ?? false,
  processing: processingSelector(state),
  enabled: state.settings.securityAndLogin.twoFA.enabled,
  expiredTokenDate: state.settings.securityAndLogin.twoFA.expiredTokenDate,
  error: state.errors[SET_2FA_SETTINGS]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      set2FASettings: set2FASettingsRequest,
      clear2FASettings: clear2FASettingsAction,
      clearError: set2FASettingsError,
      hideModal: hideModalAction
    },
    dispatch
  )

export const SettingsSecurityAndLogin2FAConfirmContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityAndLogin2FAConfirmView)
