import { useCallback } from 'react'
import { Skeleton } from 'antd'
import { AccountTypeNames, CaseMemberStatusesEnum } from '@medentee/enums'
import cls from 'classnames'

import { useRedirectToUserProfile } from 'App/hooks'
import { AvatarContainer, TAvatarContainerOwnProps } from 'App/containers'
import { EAvatarSize } from 'enums'

import styles from './CaseAccount.module.scss'

export type TCaseMemberAccountProps = PropsWithClassName<{
  loading?: boolean
  status?: CaseMemberStatusesEnum
  shouldRedirectToUserProfile?: boolean
}> &
  Pick<
    PartialBy<TAvatarContainerOwnProps, 'size'>,
    | 'userId'
    | 'displayUserName'
    | 'firstName'
    | 'lastName'
    | 'type'
    | 'hasTooltip'
    | 'size'
    | 'onClick'
  >

export const CaseAccount = ({
  userId,
  displayUserName,
  firstName,
  lastName,
  loading,
  status,
  className,
  onClick,
  type = AccountTypeNames.PROFESSIONAL,
  hasTooltip = false,
  shouldRedirectToUserProfile = false,
  size = EAvatarSize.SM
}: TCaseMemberAccountProps) => {
  const isInvitePending = status === CaseMemberStatusesEnum.INVITE_PENDING
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const handleClick = useCallback(() => {
    if (!shouldRedirectToUserProfile && onClick) {
      onClick()
      return
    }

    if (shouldRedirectToUserProfile) {
      redirectToUserProfile(userId, type)()
      return
    }
  }, [onClick, redirectToUserProfile, shouldRedirectToUserProfile, type, userId])

  return (
    <div className={cls(isInvitePending && styles.pendingWrapper, className)}>
      {loading ? (
        <Skeleton.Avatar active={true} />
      ) : (
        <AvatarContainer
          userId={userId}
          size={size}
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          type={type}
          hasTooltip={hasTooltip}
          border={true}
          onClick={handleClick}
        />
      )}
    </div>
  )
}
