import { ReactNode, RefAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cls from 'classnames'
import ReactSelect, {
  FormatOptionLabelMeta,
  Props,
  OnChangeValue,
  GroupBase,
  InputActionMeta
} from 'react-select'
import ReactSelectComponent from 'react-select/dist/declarations/src/Select'
import Creatable, { CreatableProps } from 'react-select/creatable'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import Highlighter, { HighlighterProps } from 'react-highlight-words'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { deleteKeyCodes } from 'globalConstants'
import { Error } from 'App/components/common/Fields/Error'

import { HelperText } from '../HelperText'
import { LengthCounter } from '../LengthCounter'

import {
  Option,
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  Input,
  LoadingMessage,
  Menu,
  MenuList,
  MultiValue,
  MultiValueRemove,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer
} from './components'
import styles from './Select.module.scss'

const getComponents = <
  Option extends Record<string, any>,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(): Partial<SelectComponents<Option, IsMulti, Group>> => ({
  Option,
  MultiValue,
  SingleValue,
  ValueContainer,
  Input,
  Menu,
  MenuList,
  Control,
  NoOptionsMessage,
  MultiValueRemove,
  Placeholder,
  LoadingMessage,
  IndicatorsContainer,
  DropdownIndicator,
  IndicatorSeparator: () => <></>,
  LoadingIndicator: () => <></>
})

export type TOption<T extends string | number = string> = {
  value: T
  label: string
}

export type TSelectProps<
  Option extends Record<string, any>,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsCreatable extends boolean = false
> = RefAttributes<ReactSelectComponent<Option, IsMulti, Group>> &
  (IsCreatable extends false
    ? Props<Option, IsMulti, Group>
    : CreatableProps<Option, IsMulti, Group>) & {
    labelKey?: keyof Option
    valueKey?: keyof Option
    topLabel?: ReactNode | string | null
    noOptionsText?: string
    wrapperClassName?: string
    isClearable?: boolean
    isCreatable?: IsCreatable
    onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  }

export const Select = <
  Option extends Record<string, any>,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsCreatable extends boolean = false
>(
  props: TSelectProps<Option, IsMulti, Group, IsCreatable>
) => {
  const {
    isDisabled,
    topLabel,
    options,
    endAdornment,
    wrapperClassName,
    hideSelectedOptions,
    hideChevron,
    invalid,
    limit,
    maxLength,
    showLimitCounter,
    isLoading,
    isMulti,
    onChange,
    onKeyDown,
    labelKey = 'label',
    valueKey = 'value',
    error = null,
    placeholder = null,
    isClearable = false,
    isCreatable = false,
    menuPlacement = 'auto',
    ...rest
  } = props

  const BaseSelect = isCreatable ? Creatable : ReactSelect

  const valueSetRef = useRef<Set<Option> | null>(new Set())

  const { t } = useTranslation()

  const [optionsState, setOptionsState] = useState<
    TSelectProps<Option, IsMulti, Group, IsCreatable>['options']
  >(options || [])
  const [inputValue, setInputValue] = useState<string>('')

  const valuesSet = useMemo(() => {
    if (Array.isArray(rest.value)) {
      return new Set(rest.value.map((item: Option) => item[valueKey]))
    }

    return null
  }, [rest.value, valueKey])

  const noOptionsText = rest.noOptionsText ?? t('common.field.search.listPlaceholder')

  useEffect(() => {
    if (isMulti && limit && rest.value?.length >= limit) {
      setOptionsState(
        options?.map((item) => ({
          ...item,
          isDisabled: item.hasOwnProperty(valueKey) && !valuesSet?.has(item[valueKey])
        }))
      )
    } else {
      setOptionsState(options || [])
    }
  }, [options, limit, isMulti, valuesSet, rest.value?.length, valueKey])

  const formatOptionLabel = (
    option: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>
  ) => {
    const getHighlightTag = ({ children }: HighlighterProps) => (
      <span className={styles.highlightText}>{children}</span>
    )

    const textToHighlight = option[labelKey]

    if (!textToHighlight) {
      return null
    }

    return (
      <Highlighter
        searchWords={[formatOptionLabelMeta.inputValue.trim()]}
        textToHighlight={textToHighlight}
        highlightTag={getHighlightTag}
        autoEscape={true}
      />
    )
  }

  const onInputChange = useCallback(
    (value: string, actionMeta: InputActionMeta) => {
      if (!options) {
        return
      }

      const formattedValue = value.trim().toLowerCase()

      let newOptionsState = options.filter(
        (item) =>
          item.hasOwnProperty(labelKey) && item[labelKey].toLowerCase().indexOf(formattedValue) >= 0
      )

      if (isMulti && limit && (valueSetRef.current?.size ?? 0) >= limit) {
        newOptionsState = newOptionsState.map((item) => ({
          ...item,
          isDisabled: item.hasOwnProperty(valueKey) && !valueSetRef.current?.has(item[valueKey])
        }))
      }

      newOptionsState.sort((a, b) => {
        const indexOfA =
          a.hasOwnProperty(labelKey) && a[labelKey].toLowerCase().indexOf(formattedValue)
        const indexOfB =
          b.hasOwnProperty(labelKey) && b[labelKey].toLowerCase().indexOf(formattedValue)

        return indexOfA - indexOfB
      })

      const exceedsMaxLength =
        maxLength && value.length > maxLength && actionMeta.action === 'input-change'
      const newValue = exceedsMaxLength ? value.slice(0, maxLength) : value

      setInputValue(newValue)
      setOptionsState(newOptionsState)
    },
    [maxLength, options, isMulti, limit, labelKey, valueKey]
  )

  const onKeyDownEvent = (event: React.KeyboardEvent<HTMLElement>) => {
    onKeyDown && onKeyDown(event)

    if (isClearable && deleteKeyCodes.includes(event.keyCode)) {
      !isMulti &&
        onChange &&
        onChange(null as OnChangeValue<Option, IsMulti>, {
          action: 'deselect-option',
          option: undefined
        })
    }
  }

  const getOptionLabel = (option: Option) => option[labelKey]
  const getOptionValue = (option: Option) => option[valueKey]

  useEffect(() => {
    valueSetRef.current = valuesSet
  }, [valuesSet])

  return (
    <div className={cls(styles.wrapper, wrapperClassName)}>
      {topLabel && <div className={styles.topLabel}>{topLabel}</div>}
      <BaseSelect
        components={getComponents<Option, IsMulti, Group>()}
        options={optionsState}
        {...rest}
        className={styles.root}
        hideSelectedOptions={isMulti ? false : hideSelectedOptions}
        menuPlacement={menuPlacement}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsText}
        hideChevron={hideChevron}
        invalid={invalid}
        endAdornment={endAdornment}
        inputValue={inputValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isClearable={isClearable}
        isLoading={isLoading}
        onChange={onChange}
        onKeyDown={onKeyDownEvent}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
      />
      <HelperText>
        <Row gutter={[20, 0]} wrap={false}>
          {!!error && (
            <Col flex="auto">
              <Error error={error} invalid={invalid} />
            </Col>
          )}
          {showLimitCounter && limit && (
            <Col flex="auto">
              <LengthCounter currentLength={rest.value?.length} maxLength={limit} />
            </Col>
          )}
        </Row>
      </HelperText>
    </div>
  )
}
