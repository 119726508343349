import { useMemo } from 'react'

import { IAdvertsDTO } from 'interfaces'

export const usePrepareItemData = ({
  locations,
  purposes,
  profession,
  specializations
}: Partial<Pick<IAdvertsDTO, 'profession' | 'specializations' | 'purposes' | 'locations'>>) => {
  const purposesNames = useMemo(() => purposes?.map(({ name }) => name), [purposes])

  const locationsNames = useMemo(() => {
    const countriesNames = locations?.map(({ countryName }) => countryName).join(', ')

    return countriesNames?.length ? countriesNames : 'Anywhere'
  }, [locations])

  const lookingFor = useMemo(() => {
    const specializationsList = specializations?.length ? ` (${specializations?.join(', ')})` : ''

    return profession?.name ? `${profession.name}${specializationsList}` : null
  }, [profession?.name, specializations])

  return { purposesNames, locationsNames, lookingFor }
}
