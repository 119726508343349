import { useEffect } from 'react'

import { useCallCore } from 'App/components'
import { TCallRecipientContainerProps } from 'App/containers'

export type TCallRecipientProps = TCallRecipientContainerProps

export const CallRecipient = (props: TCallRecipientProps) => {
  const { render, setAnsweredCall } = useCallCore(props)

  useEffect(() => {
    setAnsweredCall(true)
  }, [setAnsweredCall])

  return render()
}
