import { useCallback, useEffect, useMemo } from 'react'

import {
  createLoadingSelector,
  GET_ORGANIZATIONS_MEMBERS,
  LOAD_CHAT_INFO,
  useAppDispatch,
  loadChatInfoRequest,
  useAppSelector
} from 'store'

import { TCaseChatMembersProps } from './CaseChatMembers'
import { TChatRoomInfoUsersListProps } from './components/ChatRoomInfoUsersList'

export type TUseLoadMembersProps = TCaseChatMembersProps
type TParticipants = TChatRoomInfoUsersListProps['data']

const loadingSelector = createLoadingSelector([LOAD_CHAT_INFO, GET_ORGANIZATIONS_MEMBERS])

export const useLoadMembers = ({ caseId }: TUseLoadMembersProps) => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const usersOnline = useAppSelector((state) => state.online.ids)
  const loading = useAppSelector(loadingSelector)

  const info = useAppSelector((state) => state.chat.chatRooms.selectedChatInfo)
  const ownerId = useAppSelector((state) => state.chat.chatRooms?.selectedChat?.case?.owner.id)

  const { offlineParticipants, onlineParticipants } = useMemo(() => {
    const result: { onlineParticipants: TParticipants; offlineParticipants: TParticipants } = {
      onlineParticipants: [],
      offlineParticipants: []
    }

    if (caseId) {
      info?.allUsers.forEach((account) => {
        if (usersOnline.includes(account.id)) {
          result.onlineParticipants.push({ account })
        } else {
          result.offlineParticipants.push({ account })
        }
      })
    }

    return result
  }, [caseId, info?.allUsers, usersOnline])

  const fetchData = useCallback(() => {
    if (caseId) {
      dispatch(loadChatInfoRequest({ caseId }))
    }
  }, [caseId, dispatch])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    ownerId,
    loading,
    accountId,
    onlineParticipants,
    offlineParticipants,
    fetchData
  }
}
