import { useEffect } from 'react'

import { useAppDispatch } from 'store'
import { TCommonCleanFileList, TCommonGetFileList } from 'store/files/common'

import styles from './FileWrapper.module.scss'

type FileWrapperProps = {
  current: number
  showBy: number
  loading: boolean
  fileList: JSX.Element
  placeholder: JSX.Element
  total: number
  search: string
  fetchFileList: TCommonGetFileList
  resetFileList: TCommonCleanFileList

  name?: string
  direction?: string
}

export const FileWrapper = ({
  name,
  direction,
  current,
  showBy,
  loading,
  search,
  total,
  fileList,
  placeholder,
  fetchFileList,
  resetFileList
}: FileWrapperProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchFileList())
  }, [dispatch, fetchFileList, name, direction, current, showBy, search])

  useEffect(
    () => () => {
      dispatch(resetFileList())
    },
    [dispatch, resetFileList]
  )

  return (
    <div className={styles.root}>
      <div className={styles.fileList}>{loading || total ? fileList : placeholder}</div>
    </div>
  )
}
