import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BusinessAccountRoleIdEnum, BusinessAccountStatusEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  GET_BUSINESS_USERS,
  getBusinessUsersRequest,
  resetAdministrationStateAction
} from 'store'
import { AdministrationList } from 'App/components'

const loadingSelector = createLoadingSelector([GET_BUSINESS_USERS])

export const AdministrationListContainer = () => {
  const { ids, loading, accountRoleId } = useSelector((state: State) => ({
    ids: state.administration.ids,
    loading: loadingSelector(state),
    accountRoleId: state.global.accountData?.role?.id || BusinessAccountRoleIdEnum.SUPERADMIN
  }))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getBusinessUsersRequest({
        excludeAlterRoleFilter: accountRoleId === BusinessAccountRoleIdEnum.ADMIN,
        statuses:
          accountRoleId === BusinessAccountRoleIdEnum.SUPERADMIN
            ? [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
            : [BusinessAccountStatusEnum.APPROVED]
      })
    )

    return () => {
      dispatch(resetAdministrationStateAction())
    }
  }, [accountRoleId, dispatch])

  return <AdministrationList ids={ids} loading={loading} accountRoleId={accountRoleId} />
}
