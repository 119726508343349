import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'

import styles from './FilterPopoverFooter.module.scss'

export type TFilterPopoverFooterProps = PropsWithClassName<{
  onConfirm: () => void

  shouldHideResetButton?: boolean
  disableReset?: boolean
  onReset?: () => void
}>

export const FilterPopoverFooter = ({
  className,
  disableReset,
  shouldHideResetButton,
  onConfirm,
  onReset
}: TFilterPopoverFooterProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, className)}>
      {!shouldHideResetButton && (
        <Button
          size="sm"
          variant="text"
          className={styles.reset}
          onClick={onReset}
          disabled={disableReset}
        >
          {t('common.filters.resetButton')}
        </Button>
      )}

      <Button size="sm" className={styles.okButton} onClick={onConfirm}>
        {t('common.filters.okButton')}
      </Button>
    </div>
  )
}
