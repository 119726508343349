import { useTranslation } from 'react-i18next'

import {
  deletedMessage,
  hideModalAction,
  REMOVE_CHAT_PIN_MESSAGE,
  removeChatPinMessageRequest,
  useAppDispatch
} from 'store'

import { GenericConfirmation } from '../../GenericConfirmation'

export type TChatUnpinConfirmationDialogProps = {
  isPinShared?: boolean
  isPinInitiator?: boolean
  messageId: string
  chatId: string
  showPinnedMessages?: boolean
}

const loadingActions = [REMOVE_CHAT_PIN_MESSAGE]

export const ChatUnpinConfirmationDialog = ({
  isPinShared = false,
  isPinInitiator = false,
  messageId,
  showPinnedMessages = false,
  chatId
}: TChatUnpinConfirmationDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleConfirm = () => {
    dispatch(
      removeChatPinMessageRequest({
        messageId,
        chatId,
        processingId: messageId,
        onSuccess: () => {
          if (showPinnedMessages) {
            dispatch(
              deletedMessage({
                messageId
              })
            )
          }
          dispatch(hideModalAction())
        }
      })
    )
  }
  return (
    <GenericConfirmation
      confirmLabel={t('modal.unpinMessageConfirm.submitButton')}
      processingId={messageId}
      loadingActions={loadingActions}
      content={
        isPinInitiator && isPinShared
          ? t('modal.unpinMessageConfirm.content_all')
          : t('modal.unpinMessageConfirm.content')
      }
      onConfirm={handleConfirm}
    />
  )
}
