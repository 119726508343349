import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { CaseOpinionsPublicSwitch } from 'App/components'
import { publicationOpinionsRequest } from 'store'

export type TCaseOpinionsPublicSwitchContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  caseType: state.caseView.data?.type,
  publicOpinions: state.caseView.data?.publicOpinions
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      publicationOpinions: publicationOpinionsRequest
    },
    dispatch
  )

export const CaseOpinionsPublicSwitchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseOpinionsPublicSwitch)
