import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  isChatRoomMutedSelector,
  redirectToCaseFromChat,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Button, CaseItemOwner, CaseItemSubTitle, StatusColored } from 'App/components'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'

import styles from './ChatCaseInfo.module.scss'

export const ChatCaseInfo = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const {
    title,
    owner,
    status: caseStatus,
    humanReadableId,
    id,
    type
  } = useAppSelector((state) => state.chat.chatRooms.selectedChat?.case) ?? {}
  const chatId = useAppSelector((state) => state.chat.chatRooms.selectedChat?.id)
  const selectedChatInfoStatus = useAppSelector(
    (store) => store.chat.chatRooms.selectedChatInfo?.status
  )
  const isChatRoomMuted = useAppSelector(isChatRoomMutedSelector(chatId))

  const status = selectedChatInfoStatus ?? caseStatus

  const handleRedirectToCase = useCallback(() => {
    if (id && owner?.id && chatId) {
      dispatch(
        redirectToCaseFromChat({
          caseId: id,
          ownerId: owner.id,
          chatId,
          caseType: type,
          shouldOpenChat: true
        })
      )
    }
  }, [chatId, dispatch, id, owner?.id, type])

  return (
    <div className={styles.root}>
      {status && (
        <StatusColored
          className={styles.status}
          status={status}
          statusLabel={t(`cases.caseStatus.${status}`)}
        />
      )}

      <div className={styles.title}>
        {title} {isChatRoomMuted && <ChatMutedIcon size="md" />}
      </div>

      {owner && <CaseItemOwner className={styles.owner} owner={owner} />}

      {humanReadableId && (
        <CaseItemSubTitle className={styles.humanReadableId} humanReadableId={humanReadableId} />
      )}

      <Button variant="inline" className={styles.goToCase} onClick={handleRedirectToCase}>
        {t('chat.details.goToCaseButton')}
      </Button>
    </div>
  )
}
