import { InfoDrawer } from 'App/components/Organizations'
import { accountRoleNameSelector, useAppSelector } from 'store'

export type TInfoDrawerContainerProps = {
  organizationId: string
  visible: boolean
  onClose: () => void
}

export const InfoDrawerContainer = ({
  organizationId,
  visible,
  onClose
}: TInfoDrawerContainerProps) => {
  const businessChatId = useAppSelector(
    (state) => state.organizations.data.list[organizationId].businessChatId
  )
  const roleName = useAppSelector(accountRoleNameSelector)

  return (
    <InfoDrawer
      visible={visible}
      isStaff={!roleName}
      organizationId={organizationId}
      businessChatId={businessChatId}
      onClose={onClose}
    />
  )
}
