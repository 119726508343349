import { FileImage, TFileImageProps } from 'App/components'

import styles from './Thumbnail.module.scss'

export type TThumbnailProps = TFileImageProps & { slideIndex?: number }

export const Thumbnail = ({ slideIndex, ...props }: TThumbnailProps) => (
  <>
    {slideIndex !== undefined && <span className={styles.slideIndex}>{slideIndex + 1}</span>}
    <FileImage size="md" classes={{ root: styles.root }} {...props} />
  </>
)
