import cls from 'classnames'

import { Help, TruncatedText } from 'App/components/common'

import styles from './GroupedMembersItemDepartment.module.scss'

export type TGroupedMembersItemDepartmentProps = PropsWithClasses<
  'root' | 'name',
  {
    name: string
    count: string | number

    description?: string | null
  }
>

export const GroupedMembersItemDepartment = ({
  name,
  description,
  count,
  classes
}: TGroupedMembersItemDepartmentProps) => (
  <div className={cls(styles.root, classes?.root)}>
    <span className={cls(styles.name, classes?.name)}>
      <TruncatedText text={name} /> {`(${count})`}
    </span>

    {description && <Help iconVariant="info" shouldShowSub={false} content={description} />}
  </div>
)
