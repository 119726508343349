import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { DISCARD_CASE_FILE_PERMISSIONS, discardCaseFilePermissions } from 'store/caseCloud'
import { removeErrorNotification } from 'store/errors'
import {
  FileDiscardPermissionsConfirm,
  FileDiscardPermissionsConfirmOwnProps
} from 'App/components/File/FileDiscardPermissionsConfirm'

export type TCaseCloudFileDiscardPermissionsConfirmContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingSelector([DISCARD_CASE_FILE_PERMISSIONS])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[DISCARD_CASE_FILE_PERMISSIONS]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      discardCaseFilePermissions,
      removeErrorNotification: () => removeErrorNotification(DISCARD_CASE_FILE_PERMISSIONS)
    },
    dispatch
  )

export const CaseCloudFileDiscardPermissionsConfirmContainer: FC<FileDiscardPermissionsConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(FileDiscardPermissionsConfirm)
