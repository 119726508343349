import { PAGINATION_DEFAULT } from 'types'

import * as actionTypes from './communities.actionTypes'
import { TCommunitiesAction, TCommunitiesState } from './communities.types'

const initialState: TCommunitiesState = {
  currentCommunityId: null,
  data: {
    list: {},
    ids: [],
    filters: PAGINATION_DEFAULT
  }
}

export const communitiesReducer = (
  state: TCommunitiesState = initialState,
  action: TCommunitiesAction
): TCommunitiesState => {
  switch (action.type) {
    case actionTypes.GET_COMMUNITIES_SUCCESS: {
      const { list, ids, total } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          list,
          ids,
          filters: {
            ...state.data.filters,
            total
          }
        }
      }
    }

    case actionTypes.RESET_COMMUNITIES: {
      return initialState
    }

    case actionTypes.SET_COMMUNITY_ID: {
      return {
        ...state,
        currentCommunityId: action.payload.nextCommunityId
      }
    }

    default:
      return state
  }
}
