import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { UserProfileAdministrationOnline } from 'App/components'

export type TUserProfileAdministrationOnlineContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => {
  const { ids, list } = state.online.admin
  const onlineAmount = ids.reduce<number>((acc, id) => (list[id]?.online ? (acc += 1) : acc), 0)

  return { onlineAmount }
}

export const UserProfileAdministrationOnlineContainer = connect(mapStateToProps)(
  UserProfileAdministrationOnline
)
