import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getOrganizationsInvitationsFiltersSelector,
  GET_ORGANIZATIONS_INVITATIONS,
  useAppDispatch,
  useAppSelector,
  getOrganizationsInvitationsRequest,
  getNotificationsGeneralRequest,
  resetOrganizationsInvitationsFilters
} from 'store'
import { List } from 'App/components/Organizations/OrganizationInvitations/List'
import { useRefreshNotification } from 'App/hooks'
import { EBusinessAdminConfirmFrom } from 'enums'

export type TOrganizationsInvitationsReceivedProps = {
  from:
    | EBusinessAdminConfirmFrom.STAFF_RECEIVED
    | EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION

  organizationId?: string
  defaultPath?: string
}

export const OrganizationsInvitationsReceived = ({
  from,
  organizationId,
  defaultPath
}: TOrganizationsInvitationsReceivedProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { replace } = useHistory()

  const ids = useAppSelector((state) => state.organizations.invitations.received.ids, isEqual)
  const total = useAppSelector((state) => state.organizations.invitations.received.total)
  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATIONS_INVITATIONS]))
  const { current, showBy } = useAppSelector(getOrganizationsInvitationsFiltersSelector, isEqual)

  const isInvitationToAdministrations =
    from === EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(
        getOrganizationsInvitationsRequest({
          current: page,
          showBy,
          from,
          direction: BusinessAccountDirectionEnum.OUTGOING
        })
      )
    },
    [dispatch, showBy, from]
  )

  const getOrganizationsInvitations = useCallback(() => {
    const handleRefreshNotificationsGeneral = () => dispatch(getNotificationsGeneralRequest())

    dispatch(
      getOrganizationsInvitationsRequest({
        from,
        organizationId,
        direction: BusinessAccountDirectionEnum.OUTGOING,
        onSuccess: handleRefreshNotificationsGeneral
      })
    )
  }, [dispatch, from, organizationId])

  useRefreshNotification({
    onRefresh: getOrganizationsInvitations,
    message: t('common.toast.newInvitations')
  })

  useEffect(() => {
    getOrganizationsInvitations()
  }, [getOrganizationsInvitations])

  useEffect(
    () => () => {
      dispatch(resetOrganizationsInvitationsFilters())
    },
    [dispatch]
  )

  useEffect(() => {
    if (!total && defaultPath && isInvitationToAdministrations) {
      replace(defaultPath)
    }
  }, [defaultPath, total, isInvitationToAdministrations, replace])

  return (
    <List
      itemProps={{ from, organizationId }}
      ids={ids}
      type="staff-received"
      loading={loading}
      pagination={{
        total,
        current,
        showBy,
        onChange: handlePaginationChange
      }}
      emptyText={t('invitations.placeholder_received')}
    />
  )
}
