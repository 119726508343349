import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  isUploadingSelector,
  medCloudAbortUpload,
  showModalAction,
  hideModalAction,
  useAppDispatch,
  useAppSelector,
  isCallInProgressSelector
} from 'store'
import { EModalComponents } from 'App/containers'

import { useRefValue } from './useRefValue'

type TEvent = 'logout' | 'switch'

export const useInterruptionConfirm = <T extends (...args: any[]) => void>(
  originalCallback: T,
  event: TEvent
): T => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isUploading = useAppSelector(isUploadingSelector)
  const isCallInProgress = useAppSelector(isCallInProgressSelector)

  const { getValue: getIsUploading } = useRefValue(isUploading)

  const handleInterrupt = useCallback(
    (...args) => {
      const handleModalOnClick = () => {
        const onSuccess = () => {
          originalCallback(...args)
          dispatch(hideModalAction())
        }

        if (getIsUploading()) {
          dispatch(medCloudAbortUpload({ onSuccess }))
        } else {
          onSuccess()
        }
      }

      dispatch(
        showModalAction({
          modalTitle: t('modal.interruptFileUploadingConfirm.title', { context: event }),
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalProps: {
            confirmLabel: t('modal.interruptFileUploadingConfirm.submitButton', { context: event }),
            content: t('modal.interruptFileUploadingConfirm.content'),
            onConfirm: handleModalOnClick
          }
        })
      )
    },
    [dispatch, t, event, getIsUploading, originalCallback]
  )

  return isUploading || isCallInProgress ? (handleInterrupt as T) : originalCallback
}
