import { useTranslation } from 'react-i18next'

import { Button, TButtonProps, ComingSoon } from '../../common'
import { EModalComponents, ShowModalContainer } from '../../../containers'

export type TOneTimeTopUpButtonProps = TButtonProps

export const OneTimeTopUpButton = (props: TOneTimeTopUpButtonProps) => {
  const { t } = useTranslation()

  return (
    <ShowModalContainer
      modalTitle={t('modal.oneTimeTopUp.title')}
      modalType={EModalComponents.TOP_UP_ONE_TIME}
    >
      {({ showModalHandler }) => (
        <ComingSoon>
          <Button size="xs" onClick={showModalHandler} {...props} disabled>
            {t('payments.ballance.oneTimeTopUpButton')}
          </Button>
        </ComingSoon>
      )}
    </ShowModalContainer>
  )
}
