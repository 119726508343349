import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { UserProfileProfessions } from 'App/components'
import {
  addUserProfileProfessionNewId,
  getAvailableProfessionsRequest,
  getUserProfileProfessionsRequest,
  GET_USER_PROFILE_PROFESSIONS
} from 'store/userProfile'
import { createLoadingSelector } from 'store'

export type UserProfileProfessionsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_USER_PROFILE_PROFESSIONS])

const mapStateToProps = (state: State) => {
  const { ids, newIds } = state.userProfile.professions
  const accountType = state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL

  return {
    accountType,
    ids,
    newIds,
    loading: loadingSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addNewProfession: addUserProfileProfessionNewId,
      loadUserProfessions: getUserProfileProfessionsRequest,
      loadAvailableProfessions: getAvailableProfessionsRequest
    },
    dispatch
  )

export const UserProfileProfessionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileProfessions)
