import { FC, useMemo, useState } from 'react'
import { AccountTypeIdEnum, AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getStaffs } from 'api/business-users'

import { AccountsSelect, TAccountsSelectProps } from '../common'
import { TAccountsSelectOptionData } from '../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

export type TStaffSelectProps = Partial<Omit<TAccountsSelectProps, 'onSelectAll'>> & {
  onSelectAll: (value: TAccountsSelectOptionData[]) => void
}

export const StaffSelect: FC<TStaffSelectProps> = ({ selectedItems, onSelectAll, ...props }) => {
  const [search, setSearch] = useState('')

  const { t } = useTranslation()

  const { data: result, isLoading } = useQuery({
    queryKey: ['staffs', search],
    queryFn: () => getStaffs({ search }),
    select: (data) => ({
      ...data,
      results: data.results.map(
        ({ accountId, account: { firstName, lastName, displayUserName } }) => ({
          id: accountId,
          firstName,
          lastName,
          type: {
            id: AccountTypeIdEnum.PROFESSIONAL,
            name: AccountTypeNames.PROFESSIONAL
          },
          displayUserName
        })
      )
    })
  })
  const selectedAccountsSet = useMemo(() => new Set(selectedItems), [selectedItems])
  const availableAccounts = useMemo(
    () =>
      result?.results.filter(
        (item) =>
          !selectedAccountsSet.has(item) &&
          item.displayUserName.toLowerCase().includes(search.toLowerCase())
      ) ?? [],
    [result, search, selectedAccountsSet]
  )

  const handleSelectAll = () => {
    onSelectAll(result?.results ?? [])
  }

  return (
    <AccountsSelect
      {...(props as TAccountsSelectProps)}
      items={availableAccounts}
      selectedItems={selectedItems || []}
      onSearch={setSearch}
      loading={isLoading}
      search={search}
      submitLabel={t('modal.addAdmin.submitButton')}
      searchPlaceholder={t('modal.addAdmin.searchPlaceholder')}
      emptyListText={t('modal.addAdmin.placeholder')}
      onSelectAll={handleSelectAll}
    />
  )
}
