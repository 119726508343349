import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EFileUploadStatus } from 'enums'
import {
  cancelUploadFile,
  medCloudFileInvalidate,
  removeFileFromForUploadList,
  removeFileFromWidgetAction,
  setUploadFileStatus,
  useAppDispatch,
  useAppSelector
} from 'store'
import { getExtension, getFileName } from 'utils'

export type TUseFileUploadWidgetItemProps = {
  id: string
  removeOnCancel?: boolean
}

export const useFileUploadWidgetItem = ({
  id,
  removeOnCancel = false
}: TUseFileUploadWidgetItemProps) => {
  const dispatch = useAppDispatch()

  const uploadFile = useAppSelector((state) => state.medCloud.widget.list[id])
  const {
    uploadPercentage,
    uploadStatus,
    cancelTokenSource,
    fromTrashBin,
    preSignKey,
    name = ''
  } = uploadFile || {}
  const { errorType, message } = useAppSelector((state) => state.medCloud.widget.errors[id] || {})

  const { t } = useTranslation()

  const { extension, fileName } = useMemo(
    () => ({
      fileName: getFileName(name),
      extension: getExtension(name)
    }),
    [name]
  )

  const showCancel =
    uploadStatus === EFileUploadStatus.PENDING || uploadStatus === EFileUploadStatus.PROGRESS

  const handleCancel = useCallback(() => {
    cancelTokenSource.cancel(t('common.uploadWidget.canceledUploadOperation'))
    dispatch(medCloudFileInvalidate({ id }))
    dispatch(removeFileFromForUploadList({ fakeId: id }))

    if (preSignKey) {
      dispatch(cancelUploadFile({ key: preSignKey }))
    }

    if (removeOnCancel) {
      dispatch(removeFileFromWidgetAction({ fileId: id }))
      return
    }

    dispatch(setUploadFileStatus({ id, status: EFileUploadStatus.CANCELLED }))
  }, [cancelTokenSource, dispatch, id, preSignKey, removeOnCancel, t])

  return {
    uploadPercentage,
    uploadStatus,
    cancelTokenSource,
    fromTrashBin,
    preSignKey,
    errorType,
    message,
    showCancel,
    fileName,
    extension,
    handleCancel
  }
}
