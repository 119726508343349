import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { TNotificationsState, TNotificationsAction } from './notifications.types'
import {
  GET_NOTIFICATIONS_GENERAL_SUCCESS,
  GET_NOTIFICATIONS_GENERAL_ERROR,
  MESSAGE_PUSH_UP,
  GET_NOTIFICATIONS_PROTOCOL_USERS_SUCCESS,
  GET_NOTIFICATIONS_PROTOCOL_SUCCESS,
  CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT,
  REFRESH_NOTIFICATION_COUNTERS_SUCCESS,
  REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE,
  REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE,
  RECEIVE_NEW_MESSAGE_NOTIFICATIONS_SUCCESS,
  RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS_SUCCESS,
  RECEIVE_NEW_COMMUNITY_NOTIFICATIONS_SUCCESS
} from './notifications.actionTypes'

export const initialNotificationsState: TNotificationsState = {
  general: {
    contacts: 0,
    cases: {
      invitations: 0,
      notifications: 0,
      totalUnreadCount: 0
    },
    chats: {
      totalUnreadCount: 0,
      totalUnreadContactCount: 0,
      totalUnreadCaseCount: 0,
      ids: [],
      list: {}
    },
    invitations: {
      totalUnreadCount: 0,
      cases: 0,
      contacts: 0,
      businessAdmin: 0
    },
    connector: {
      totalUnreadCount: 0
    },
    headerNotification: 0,
    organizations: {
      list: {},
      totalUnreadCount: 0,
      totalInvitesToOrganization: 0
    },
    communities: {
      list: {},
      totalUnreadCount: 0
    },
    events: {
      list: {},
      totalUnreadCount: 0
    },
    showCaseNotification: 0
  },
  messagePushUp: {
    chatId: null,
    show: false
  },
  protocolUsers: {
    list: {},
    ids: [],
    pagination: {
      current: 0,
      total: 0,
      showBy: PAGINATION_DEFAULT_SHOW_BY
    }
  },
  protocol: {
    list: {},
    ids: [],
    pagination: {
      current: 0,
      total: 0,
      showBy: PAGINATION_DEFAULT_SHOW_BY
    }
  },
  subscribed: false
}

export const notificationsReducer = (
  state: TNotificationsState = initialNotificationsState,
  action: TNotificationsAction
): TNotificationsState => {
  switch (action.type) {
    case GET_NOTIFICATIONS_GENERAL_SUCCESS: {
      return {
        ...state,
        general: {
          ...state.general,
          ...action.payload
        }
      }
    }

    case GET_NOTIFICATIONS_GENERAL_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    case MESSAGE_PUSH_UP: {
      return {
        ...state,
        messagePushUp: {
          ...state.messagePushUp,
          ...action.payload
        }
      }
    }

    case GET_NOTIFICATIONS_PROTOCOL_USERS_SUCCESS: {
      return {
        ...state,
        protocolUsers: action.payload
      }
    }

    case GET_NOTIFICATIONS_PROTOCOL_SUCCESS: {
      return {
        ...state,
        protocol: action.payload
      }
    }

    case CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT: {
      const { userId } = action.payload
      return {
        ...state,
        protocolUsers: {
          ...state.protocolUsers,
          list: {
            ...state.protocolUsers.list,
            [userId]: {
              ...state.protocolUsers.list[userId],
              unreadCount: 0
            }
          }
        }
      }
    }

    case REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE: {
      return {
        ...state,
        subscribed: true
      }
    }

    case REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE: {
      return {
        ...state,
        subscribed: false
      }
    }

    case REFRESH_NOTIFICATION_COUNTERS_SUCCESS: {
      return {
        ...state,
        protocolUsers: {
          ...state.protocolUsers,
          list: action.payload.reduce((res, { id, unreadCount }) => {
            if (!res[id]) {
              return res
            }

            return { ...res, [id]: { ...res[id], unreadCount } }
          }, state.protocolUsers.list)
        }
      }
    }

    case RECEIVE_NEW_MESSAGE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        general: {
          ...state.general,
          chats: {
            ...state.general.chats,
            ...action.payload
          }
        }
      }
    }

    case RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS_SUCCESS: {
      const { id, totalUnreadCount, invitesToOrganization } = action.payload

      return {
        ...state,
        general: {
          ...state.general,
          organizations: {
            list: {
              ...state.general.organizations.list,
              [id]: action.payload
            },
            totalUnreadCount:
              state.general.organizations.totalUnreadCount -
              state.general.organizations.list?.[id]?.totalUnreadCount +
              totalUnreadCount,
            totalInvitesToOrganization:
              state.general.organizations.totalInvitesToOrganization -
              state.general.organizations.list?.[id]?.invitesToOrganization +
              invitesToOrganization
          }
        }
      }
    }

    case RECEIVE_NEW_COMMUNITY_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        general: {
          ...state.general,
          communities: action.payload
        }
      }
    }

    default:
      return state
  }
}
