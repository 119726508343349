import { useTranslation } from 'react-i18next'

import { TOrganizationMember } from 'store'

import { ChatRoomInfoUserItem, TChatRoomInfoItemMember } from './ChatRoomInfoUserItem'
import styles from './ChatRoomInfoUsersList.module.scss'

export type TChatRoomInfoUsersListProps = {
  data: {
    account: TChatRoomInfoItemMember
    contact?: TOrganizationMember['contact']
  }[]

  ownerId?: string
  accountId?: string
  isOnline?: boolean
}

export const ChatRoomInfoUsersList = ({
  data,
  ownerId,
  accountId,
  isOnline = false
}: TChatRoomInfoUsersListProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {data.map(({ account }) => (
        <ChatRoomInfoUserItem
          key={account.id}
          member={account}
          isOnline={isOnline}
          accountId={accountId}
          chipList={[
            {
              hidden: ownerId !== account.id,
              color: 'primary-dark',
              text: t('chat.details.ownerChip'),
              variant: 'square'
            }
          ]}
        />
      ))}
    </div>
  )
}
