import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FileHistoryItemContainer, TFileHistoryGroupedItemContainerProps } from 'App/containers'

import styles from './FileHistoryGroupedItem.module.scss'

export const FileHistoryGroupedItem = ({
  dateId,
  ids,
  displayInline
}: TFileHistoryGroupedItemContainerProps) => {
  const { t } = useTranslation()

  const date = useMemo(() => {
    const currentDate = new Date()

    const currentDateString = currentDate.toLocaleDateString('en-gb')
    const yesterdayDateString = new Date(currentDate.getTime() - 86400000).toLocaleDateString(
      'en-gb'
    )
    const dateString = new Date(dateId).toLocaleDateString('en-gb')

    if (currentDateString === dateString) {
      return t('files.information.history.today')
    }

    if (yesterdayDateString === dateString) {
      return t('files.information.history.yesterday')
    }

    return dateString
  }, [dateId, t])

  return (
    <div className={styles.wrapper}>
      <span className={styles.date}>{date}</span>
      <div className={styles.itemsWrapper}>
        {ids.map((id) => (
          <FileHistoryItemContainer
            dateId={dateId}
            itemId={id}
            key={id}
            displayInline={displayInline}
          />
        ))}
      </div>
    </div>
  )
}
