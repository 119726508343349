import { useCallback, useLayoutEffect } from 'react'
import { useHistory } from 'react-router'
import isString from 'lodash/isString'
import { parse } from 'query-string'
import { BroadcastTypeEnum, ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import {
  GET_BROADCASTS,
  GET_CHAT_ROOMS,
  getBroadcastsRequest,
  getChatRoomsRequest,
  hideModalAction,
  showModalAction,
  useAppDispatch
} from 'store'
import { TChannelVariant } from 'interfaces'
import { joinToBroadcast } from 'api/broadcasts'
import { joinToCommunityChannel } from 'api/communities'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

type TVariant = Extract<TChannelVariant, 'chat' | 'broadcast' | 'news'>

export const useJoinToChannel = (communityId: string) => {
  const dispatch = useAppDispatch()

  const { replace, location } = useHistory()

  const { t } = useTranslation()

  const { pathname, search } = location
  const { title, token, variant } = parse(search)

  const handleClose = useCallback(() => replace({ pathname, search: '' }), [pathname, replace])

  const joinToBroadcastMutation = useMutation({
    mutationKey: ['join-broadcast'],
    mutationFn: joinToBroadcast,
    onSuccess: ({ id }) => {
      dispatch(
        getBroadcastsRequest({
          communityIds: [communityId],
          type: BroadcastTypeEnum.COMMUNITY,
          onSuccess: () => {
            toast.success(t('common.toast.joinedSuccessfully'), toastDefaultOptions)
            replace({ pathname: `/communities/${communityId}/broadcast/${id}`, search: '' })
            dispatch(hideModalAction())
          }
        })
      )
    }
  })

  const joinToChannelMutation = useMutation({
    mutationKey: ['join-to-community-channel'],
    mutationFn: joinToCommunityChannel
  })

  const handleConfirmJoinToBroadcast = useCallback(
    () => joinToBroadcastMutation.mutateAsync(isString(token) ? token : ''),
    [joinToBroadcastMutation, token]
  )

  const handleConfirmJoinToChannel = useCallback(
    () =>
      joinToChannelMutation.mutateAsync(
        { communityId, token: isString(token) ? token : '' },
        {
          onSuccess: ({ id }) => {
            dispatch(
              getChatRoomsRequest({
                communityId,
                chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS],
                onSuccess: () => {
                  toast.success(t('common.toast.joinedSuccessfully'), toastDefaultOptions)
                  if (isString(variant)) {
                    replace({
                      pathname: `/communities/${communityId}/${variant}/${id}`,
                      search: ''
                    })
                  }
                  dispatch(hideModalAction())
                }
              })
            )
          }
        }
      ),
    [communityId, dispatch, joinToChannelMutation, replace, t, token, variant]
  )

  useLayoutEffect(() => {
    if (isString(token) && isString(title) && isString(variant)) {
      if ((variant as TVariant) === 'broadcast') {
        dispatch(
          showModalAction({
            modalTitle: t('modal.joinLiveEventConfirm.title'),
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalProps: {
              loadingActions: [GET_BROADCASTS],
              content: title,
              confirmLabel: t('modal.joinLiveEventConfirm.submitButton'),
              onConfirm: handleConfirmJoinToBroadcast,
              onClose: handleClose
            }
          })
        )
      } else {
        dispatch(
          showModalAction({
            modalTitle: t('modal.joinChannelConfirm.title'),
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalProps: {
              loadingActions: [GET_CHAT_ROOMS],
              content: title,
              confirmLabel: t('modal.joinLiveEventConfirm.submitButton'),
              onConfirm: handleConfirmJoinToChannel,
              onClose: handleClose
            }
          })
        )
      }
    }
  }, [
    dispatch,
    handleClose,
    handleConfirmJoinToBroadcast,
    handleConfirmJoinToChannel,
    t,
    title,
    token,
    variant
  ])
}
