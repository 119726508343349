import { TSettingsSecurityDefaultAccountItemContainerProps } from 'App/containers'
import { RelatedAccountBaseItem } from 'App/components'

export type TSettingsSecurityDefaultAccountItemProps =
  TSettingsSecurityDefaultAccountItemContainerProps

export const SettingsSecurityDefaultAccountItem = ({
  displayUserName,
  firstName,
  id,
  lastName,
  role,
  type,
  disabled
}: TSettingsSecurityDefaultAccountItemProps) => (
  <RelatedAccountBaseItem
    disabled={disabled}
    id={id}
    displayUserName={displayUserName}
    firstName={firstName}
    lastName={lastName}
    role={role}
    type={type}
    showOnline={false}
    hasTooltip={false}
  />
)
