import { memo, useCallback, useEffect, useState } from 'react'
import { areEqual, FixedSizeList } from 'react-window'
import memoize from 'memoize-one'
import { AccountTypeNames, ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { TResponseError, getVirtualListHeight, validation } from 'utils'
import { Search, Steps, TextField, EmptyList, AccountsSelect } from 'App/components'
import { EContactsSort, EInputSize } from 'enums'
import { useAdaptiveLayout, useSelectAccounts, useSteps } from 'App/hooks'
import { TCategoryListData } from 'store/categoryList/categoryList.types'
import {
  accountIdSelector,
  getContactsRequest,
  removeErrorNotification,
  sendCategoryRequest,
  SEND_CATEGORY,
  setCategorySearchAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { MAX_LENGTH_50 } from 'globalConstants'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'
import { getContacts } from 'api/contacts'

import { TAccountsSelectOptionData } from '../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import styles from './CategoryDialog.module.scss'

export enum ECategoryDialogAction {
  EDIT = 'edit',
  CREATE = 'create'
}

export type TCategoryDialogProps = {
  action: ECategoryDialogAction

  preSelectedIds?: string[]
  initialCategoryName?: string
  initialCategoryId?: string
}

type TFilterItem = {
  index: number
  data: ReturnType<typeof createCategoriesData>
}

export enum ECategoriesMaxLengths {
  NAME = 50
}

enum EListConfig {
  HEIGHT = 459,
  ITEM_HEIGHT = 51
}

const DEFAULT_STEPS = [{}, {}]

const isDisabledButton = (categoryName: string, error?: TResponseError) => {
  const invalid = validation.composeValidators(
    validation.required(),
    validation.maxLength(ECategoriesMaxLengths.NAME)
  )(categoryName, { categoryName })

  return Boolean(error?.response?.status === 400 || invalid)
}

const createCategoriesData = memoize(
  (categories: TCategoryListData[], onCategoryClick: (name: string, id: string) => void) => ({
    categories,
    onCategoryClick
  })
)

const FilterItemView = ({ index, data }: TFilterItem) => {
  const { categories, onCategoryClick } = data
  const { id, name }: TCategoryListData = categories[index]

  return (
    <button onClick={() => onCategoryClick(name, id)} className={styles.categoryItem}>
      {name}
      <ChevronRightIcon />
    </button>
  )
}

const FilterItem = memo(FilterItemView, areEqual)

const CategoryDialogView = ({
  initialCategoryId,
  initialCategoryName,
  action = ECategoryDialogAction.CREATE
}: TCategoryDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { data: categoryContacts } = useQuery({
    queryKey: ['contacts', initialCategoryId],
    queryFn: () =>
      getContacts({
        searchBy: { group: 'categories', id: initialCategoryId },
        accountId,
        sortBy: EContactsSort.DATE,
        showBy: Number.MAX_SAFE_INTEGER
      }),
    enabled: !!initialCategoryId
  })

  const accountId = useAppSelector(accountIdSelector)
  const accountType = useAppSelector((state) => state.global.accountData?.type.name)
  const error = useAppSelector((state) => state.errors[SEND_CATEGORY])
  const categories = useAppSelector((state) => state.categoryList.results)

  const { isDesktop } = useAdaptiveLayout()
  const { step, setNextStep } = useSteps(0)
  const [categoryName, setCategoryName] = useState(initialCategoryName || '')
  const [categoryId, setCategoryId] = useState(initialCategoryId || '')
  const listHeight = getVirtualListHeight({
    arr: categories,
    itemHeight: EListConfig.ITEM_HEIGHT,
    defaultHeight: EListConfig.HEIGHT,
    min: 0,
    max: 9
  })

  const isUpdate = !!categoryId && action === ECategoryDialogAction.EDIT
  const isEditModeTabletView = !isDesktop && isUpdate
  const showTabletFirstStep = isEditModeTabletView && step === 0

  useEffect(
    () => () => {
      dispatch(removeErrorNotification(SEND_CATEGORY))
    },
    [dispatch]
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      dispatch(removeErrorNotification(SEND_CATEGORY))
    }
    setCategoryName(e.target.value)
  }

  const handleButtonClick = (name: string, id: string) => {
    setCategoryName(name)
    setNextStep()
    setCategoryId(id)
  }

  const onSuccess = useCallback(() => {
    dispatch(
      getContactsRequest({
        status: ContactStatusEnum.APPROVED
      })
    )
  }, [dispatch])

  const onSendCategory = () => {
    dispatch(
      sendCategoryRequest({
        type: AccountTypeNames.PROFESSIONAL,
        categoryId,
        categoryName,
        selectedIds: (selectedAccounts as Required<TAccountsSelectOptionData>[]).map(
          ({ contactId }) => contactId
        ),
        initialSelectedIds: categoryContacts?.results?.map(({ contactId }) => contactId),
        onSuccess
      })
    )
  }

  const handleSearchChange = useCallback(
    (value: string) => {
      if (accountType) {
        dispatch(setCategorySearchAction({ accountType, search: value }))
      }
    },
    [accountType, dispatch]
  )

  const {
    state: { page, search, selectedAccounts, idle },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({ onSubmit: onSendCategory, accounts: [] })

  const { data: contactsData, isLoading } = useQuery({
    queryKey: ['contacts', page, search],
    queryFn: () =>
      getContacts({
        page,
        search,
        searchBy: { group: 'categories' },
        accountId,
        sortBy: EContactsSort.DATE
      }),
    keepPreviousData: true,
    enabled: !idle || !isUpdate
  })

  useEffect(() => {
    if (categoryContacts?.results) {
      onSelectAll(categoryContacts.results)
    }
  }, [categoryContacts, onSelectAll])

  const selectedContactsEmptyText = t('modal.createCategory.selectedListPlaceholder', {
    categoryName: isUpdate && categoryName ? categoryName : 'new'
  })
  const buttonText = isUpdate
    ? t('modal.createCategory.submitButton_save')
    : t('modal.createCategory.submitButton')
  const selectTitle = t('modal.createCategory.selectTitle')
  const emptyContent = search
    ? t('modal.createCategory.searchPlaceholder')
    : t('modal.createCategory.placeholder')

  return (
    <div className={styles.root}>
      {isEditModeTabletView && (
        <Steps current={step} stepsArray={DEFAULT_STEPS} className={styles.steps} />
      )}
      {showTabletFirstStep ? (
        <>
          <p className={styles.description}>{t('modal.createCategory.categoriesTitle')}</p>
          <Search
            allowClear={true}
            searchSize={EInputSize.L}
            placeholder={t('modal.createCategory.categoriesSearchPlaceholder')}
            onChange={handleSearchChange}
            onPressEnter={handleSearchChange}
          />
          {categories.length ? (
            <div className={styles.categoryList}>
              <FixedSizeList
                height={listHeight}
                itemSize={EListConfig.ITEM_HEIGHT}
                width="auto"
                itemData={createCategoriesData(categories, handleButtonClick)}
                itemCount={categories.length}
              >
                {FilterItem}
              </FixedSizeList>
            </div>
          ) : (
            <EmptyList
              text={t('modal.createCategory.categoriesListPlaceholder')}
              className={styles.categoryListEmpty}
            />
          )}
        </>
      ) : (
        <>
          <TextField
            value={categoryName}
            onChange={onChange}
            topLabel={t('modal.createCategory.categoryFiled')}
            autoFocus={true}
            valueLengthMax={MAX_LENGTH_50}
            classes={{ wrapper: styles.searchWrapper }}
          />
          <AccountsSelect
            selectTitle={selectTitle}
            loading={isLoading}
            items={contactsData?.results ?? []}
            selectedItems={selectedAccounts}
            search={search}
            page={page}
            total={contactsData?.total}
            emptyListText={emptyContent}
            emptyContentText={selectedContactsEmptyText}
            autoOnline={true}
            submitLabel={buttonText}
            error={error}
            submitDisabled={isDisabledButton(categoryName, error)}
            onSelectAll={onSelectAll}
            onUnselectAll={onUnselectAll}
            onSelect={onSelect}
            onUnselect={onUnselect}
            onSearch={onSearch}
            onPageChange={onPageChange}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  )
}

export const CategoryDialog = memo(CategoryDialogView)
