import { useEffect } from 'react'
import { Skeleton } from 'antd'
import { formatDistanceToNowStrict } from 'date-fns'
import { PlatformInvitationType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { ReactComponent as LinkIcon } from 'assets/icons/LinkChain.svg'
import { hideModalAction, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { InvitationLinkField } from 'App/components/common/Fields/InvitationLinkField'

import {
  createInvitationLink,
  getInvitationLink,
  sendInviteUsersByEmail
} from '../../../api/invitations'
import { useToggle } from '../../hooks'
import { useInvitationDialogDescription } from '../Invitations'
import { EModalComponents } from '../ModalRoot'
import { HOUR, generateSignupLink, getLocale } from '../../../utils'
import { toastDefaultOptions } from '../../../globalConstants'
import { Button, Help, Popover, TPopoverProps } from '../../components'

import styles from './InvitePopover.module.scss'

export type TInvitePopoverProps = TPopoverProps & {
  disabled?: boolean
  invitationType: PlatformInvitationType
}

export const InvitePopover = ({
  invitationType,
  disabled,
  visible: controlledVisible,
  onVisibleChange,
  ...props
}: TInvitePopoverProps) => {
  const dispatch = useAppDispatch()

  const { value: open, setValue } = useToggle()

  const accountType = useAppSelector((state) => state.global.accountData?.type.name)

  const { t } = useTranslation()

  const { refetch, data, isFetching } = useQuery({
    queryKey: ['get-invitation-link'],
    queryFn: () => getInvitationLink(),
    enabled: false
  })
  const { mutateAsync: inviteByEmail } = useMutation({
    mutationKey: ['invite-users', invitationType],
    mutationFn: (emails: string[]) => sendInviteUsersByEmail({ type: invitationType, emails }),
    onSuccess: () => {
      dispatch(hideModalAction())
      toast.success(t('modal.inviteByEmail.invitationSentSuccessToast'), toastDefaultOptions)
    }
  })

  const { mutate: createLink, isLoading: isCreating } = useMutation({
    mutationKey: ['create-invitation-link'],
    mutationFn: () => createInvitationLink(),
    onSuccess: () => refetch()
  })

  const toggle = (visible: boolean) => {
    if (onVisibleChange) {
      onVisibleChange(visible)
    } else {
      setValue(visible)
    }
  }

  const handleSettingsClick = () => {
    toggle(false)
    dispatch(
      showModalAction({
        modalType: EModalComponents.INVITATION_LINK_SETTINGS,
        modalTitle: t('modal.invitationLinkSettings.title'),
        modalProps: {
          maxRegistrants: data?.data?.maxRegistrants ?? 0,
          createdAccountsNumber: data?.data?.createdAccountsCount ?? 0
        }
      })
    )
  }

  const handleVisibilityChange = (visible: boolean) => {
    toggle(visible)
    if (visible) {
      refetch()
    }
  }

  const description = useInvitationDialogDescription(accountType, invitationType)

  const handleInviteByEmailClick = () => {
    toggle(false)
    dispatch(
      showModalAction({
        modalType: EModalComponents.INVITE_BY_EMAIL,
        modalTitle:
          invitationType === PlatformInvitationType.STAFF
            ? t('modal.inviteByEmail.title_staff')
            : t('modal.inviteByEmail.title'),
        modalProps: {
          description,
          onConfirm: inviteByEmail
        }
      })
    )
  }

  const tooltip = (
    <Help shouldShowSub={false} content={t('common.invitationPopover.inviteByLinkHelp')} />
  )
  const expiredAt = new Date(data?.data?.expiredAt ?? 0)
  const unit = expiredAt.getTime() - Date.now() > HOUR ? 'hour' : 'minute'

  useEffect(() => {
    if (controlledVisible !== undefined) {
      setValue(controlledVisible)
    }
    if (controlledVisible) {
      refetch()
    }
  }, [setValue, controlledVisible, refetch])

  return (
    <Popover
      placement="bottomRight"
      {...props}
      visible={!disabled && open}
      content={
        <div className={styles.popoverContent}>
          <Button
            variant="text"
            icon={<EmailIcon />}
            className={styles.button}
            onClick={handleInviteByEmailClick}
          >
            {t('common.invitationPopover.inviteByEmailButton')}
          </Button>
          <Skeleton
            active
            className={styles.skeleton}
            loading={isFetching}
            title={false}
            paragraph={{ rows: 1 }}
          >
            {data?.data ? (
              <div className={styles.linkBlock}>
                <InvitationLinkField value={generateSignupLink('invite', data.data.token)} />

                <div className={styles.settings}>
                  <Button variant="underlined" onClick={handleSettingsClick}>
                    {t('common.invitationPopover.linkSettingsButton')}
                  </Button>
                  {tooltip}
                  <span className={styles.expiration}>
                    {t('common.invitationPopover.expiresInLabel', {
                      time: formatDistanceToNowStrict(expiredAt, {
                        unit,
                        locale: getLocale()
                      })
                    })}
                  </span>
                </div>
              </div>
            ) : (
              <Button
                className={styles.button}
                variant="text"
                icon={<LinkIcon />}
                loading={isCreating}
                onClick={() => createLink()}
              >
                {t('common.invitationPopover.inviteByLinkButton')}&nbsp;{tooltip}
              </Button>
            )}
          </Skeleton>
        </div>
      }
      onVisibleChange={handleVisibilityChange}
    />
  )
}
