import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { accountRoleNameSelector, isAdminAccountSelector, useAppSelector } from 'store'
import { ChatRoomInfoUserItem } from 'App/components/Chat/ChatMembers/CaseChatMembers/components/ChatRoomInfoUserItem'
import { ReactComponent as StarFillIcon } from 'assets/icons/StarFill.svg'
import {
  GroupedMembersList,
  GroupedMembersItemDepartment
} from 'App/components/Organizations/Members'
import { useAdaptiveLayout } from 'App/hooks'
import { Alert } from 'App/components'
import { useOrganizationMemberKebabMenu } from 'App/containers/Organization/useOrganizationMemberKebabMenu'
import { KebabContactStatus } from 'App/components/common/KebabContactStatus'

import { useOrganizationGroupedMembers } from '../useOrganizationGroupedMembers'

import styles from './ChannelInfo.module.scss'

type TChannelInfoProps = {
  organizationId: string
  channelId: string
  setTabMembersTotal: (total: number) => void
}

const MEMBERS_QUERY_KEY_PREFIX = 'organization-channel-members'

export const ChannelInfo = ({
  organizationId,
  channelId,
  setTabMembersTotal
}: TChannelInfoProps) => {
  const { isMobile } = useAdaptiveLayout()

  const accountRole = useAppSelector(accountRoleNameSelector)
  const channelManagerId = useAppSelector(
    (state) => state.chat.chatRooms.selectedChat?.channelManager?.id
  )
  const { id: accountId, professionalAccount } =
    useAppSelector((state) => state.global.accountData) || {}
  const isBusiness = useAppSelector(isAdminAccountSelector)

  const { t } = useTranslation()

  const shouldShowAlert = !isBusiness

  const {
    data,
    dataLength,
    loading,
    processing,
    total,
    totalUniq,
    page,
    refetch,
    setPage,
    handleRefreshMemberList
  } = useOrganizationGroupedMembers({
    membersQueryKeyPrefix: MEMBERS_QUERY_KEY_PREFIX,
    organizationId,
    channelId
  })

  useEffect(() => {
    refetch()
    // `page` - is essential
  }, [refetch, page])

  const { getMenuItems } = useOrganizationMemberKebabMenu({
    accountId,
    accountRoleName: accountRole,
    membersQueryKeyPrefix: MEMBERS_QUERY_KEY_PREFIX,
    professionalAccountId: professionalAccount?.id,
    onRefreshMemberListCallback: handleRefreshMemberList
  })

  useEffect(() => {
    setTabMembersTotal(totalUniq)
  }, [setTabMembersTotal, totalUniq])

  return (
    <div className={styles.root}>
      {shouldShowAlert && (
        <Alert variant="info" size="sm" className={styles.alert}>
          {t('organizations.channelInfo.alert')}
        </Alert>
      )}

      <GroupedMembersList
        classes={{ root: styles.list, skeleton: styles.skeleton }}
        loading={loading}
        processing={processing}
        total={total}
        page={page}
        dataLength={dataLength}
        setPage={setPage}
      >
        {data.ids.map((id) => {
          const {
            departmentMembersCount,
            departmentName,
            members,
            departmentLeaderId,
            organizationDepartmentsCount
          } = data.list[id] ?? {}

          const shouldShowDepartment = Number(organizationDepartmentsCount) > 0

          return (
            <div key={id} className={styles.department}>
              {shouldShowDepartment && (
                <GroupedMembersItemDepartment
                  classes={{ root: styles.departmentName }}
                  name={departmentName}
                  count={departmentMembersCount}
                />
              )}

              {members?.ids.map((memberId) => {
                const member = members.list[memberId] ?? {}
                const { account, online, contact } = member

                return (
                  <ChatRoomInfoUserItem
                    key={memberId}
                    classes={{ root: styles.member }}
                    member={account}
                    isOnline={online}
                    accountId={accountId}
                    chipList={[
                      {
                        hidden: memberId !== departmentLeaderId,
                        color: 'primary',
                        variant: 'square',
                        icon: <StarFillIcon />,
                        text: t('organizations.channelInfo.leadChip')
                      },
                      {
                        hidden: memberId !== channelManagerId,
                        color: 'success',
                        variant: 'square',
                        text: isMobile
                          ? t('organizations.channelInfo.channelManagerChip_short')
                          : t('organizations.channelInfo.channelManagerChip')
                      }
                    ]}
                    menuItems={getMenuItems({ member })}
                    endAdornment={
                      <KebabContactStatus
                        status={contact?.status}
                        accountId={accountId}
                        fromId={contact?.fromId}
                      />
                    }
                  />
                )
              })}
            </div>
          )
        })}
      </GroupedMembersList>
    </div>
  )
}
