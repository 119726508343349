import { ChatTypeEnum, ErrorCodesEnum } from '@medentee/enums'

import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { TChatRoomsList, TChatRoomsType, TError, TIds } from 'store'
import { isMatchErrorCode, moveToStart } from 'utils'

import i18n from '../../i18n'

export const orderChatRooms = (
  list: TChatRoomsList,
  ids: TIds,
  id: string,
  chatType?: ChatTypeEnum
) => {
  if (
    chatType === ChatTypeEnum.ORGANIZATION ||
    chatType === ChatTypeEnum.COMMUNITY_CHANNEL ||
    chatType === ChatTypeEnum.COMMUNITY_NEWS ||
    chatType === ChatTypeEnum.EVENT_CHANNEL ||
    chatType === ChatTypeEnum.EVENT_NEWS
  ) {
    return ids
  }

  const groupChatId = ids.find((i) => list[i]?.type === ChatTypeEnum.CASE_GROUP)
  const p2pChatsIds = ids.filter((i) => i !== groupChatId)

  if (groupChatId && groupChatId !== id) {
    const orderedIds = moveToStart(p2pChatsIds, id)

    return [groupChatId, ...orderedIds]
  }

  return moveToStart(ids, id)
}

export const onChatRoomNotFoundError = (e: TError, callback?: () => void) => {
  if (isMatchErrorCode(e, ErrorCodesEnum.CHAT_ROOM_NOT_FOUND)) {
    toast.warn(i18n.t('common.toast.unavailableChat_channel'), toastDefaultOptions)
    callback && callback()
  }
}

export const isSameChatTab = (currentChatType: TChatRoomsType, newChatType: ChatTypeEnum) => {
  let newChatTabType = newChatType

  if (newChatType === ChatTypeEnum.GROUP) {
    newChatTabType = ChatTypeEnum.DIALOGUE
  }

  return currentChatType === newChatTabType
}
