import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { FileHistorySourcesEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, GET_FILES_HISTORY, getFilesHistoryRequest } from 'store'
import { FileHistoryList } from 'App/components'

const loadingSelector = createLoadingSelector([GET_FILES_HISTORY])

export type TFileHistoryListContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    fileId: string

    displayInline?: boolean
    sources?: FileHistorySourcesEnum[]
    actorAccountIds?: string[]
  }

const mapStateToProps = (state: State) => {
  const { dateIds, filters } = state.file.fileHistory

  return {
    loading: loadingSelector(state),
    dateIds,
    filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFileHistory: getFilesHistoryRequest
    },
    dispatch
  )

export const FileHistoryListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileHistoryList)
