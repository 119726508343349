import { PAGINATION_DEFAULT, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'types'

import * as actionTypes from './organizations.actionTypes'
import { TOrganizationsAction, TOrganizationsState } from './organizations.types'

export const ORGANIZATION_MEMBER_DEFAULT_SHOW_BY = 10

const initialState: TOrganizationsState = {
  currentOrganizationId: null,
  data: {
    list: {},
    ids: [],
    filters: PAGINATION_DEFAULT
  },
  members: {
    list: [],
    filters: {
      ...PAGINATION_DEFAULT,
      showBy: ORGANIZATION_MEMBER_DEFAULT_SHOW_BY,
      search: ''
    }
  },
  invitations: {
    filters: {
      showBy: PAGINATION_DEFAULT_SHOW_BY,
      current: PAGINATION_DEFAULT_PAGE
    },
    received: {
      ids: [],
      list: {},
      total: 0
    },
    registeredUsers: {
      ids: [],
      list: {},
      total: 0
    },
    administrations: {
      ids: [],
      list: {},
      total: 0
    },
    newUsers: {
      ids: [],
      list: {},
      total: 0
    }
  },
  voiceRooms: {
    ids: [],
    list: {}
  },
  activeVoiceRoom: null,
  pinnedChannelIds: []
}

export const organizationsReducer = (
  state: TOrganizationsState = initialState,
  action: TOrganizationsAction
): TOrganizationsState => {
  switch (action.type) {
    case actionTypes.GET_ORGANIZATIONS_SUCCESS: {
      const { list, ids, total } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          list,
          ids,
          filters: {
            ...state.data.filters,
            total
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATIONS_MEMBERS_REQUEST: {
      const { sortBy, sortOrder } = action.payload

      return {
        ...state,
        members: {
          ...state.members,

          filters: {
            ...state.members.filters,
            sortBy,
            sortOrder
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATIONS_MEMBERS_SUCCESS: {
      const { list, total } = action.payload

      return {
        ...state,
        members: {
          ...state.members,
          list,
          filters: {
            ...state.members.filters,
            total
          }
        }
      }
    }
    case actionTypes.SET_ORGANIZATIONS_MEMBERS_SEARCH: {
      return {
        ...state,
        members: {
          ...state.members,
          filters: {
            ...state.members.filters,
            search: action.payload.search
          }
        }
      }
    }
    case actionTypes.SET_ORGANIZATIONS_MEMBERS_CURRENT_PAGE: {
      return {
        ...state,
        members: {
          ...state.members,
          filters: {
            ...state.members.filters,
            current: action.payload.current
          }
        }
      }
    }
    case actionTypes.RESET_ORGANIZATIONS: {
      return {
        ...initialState,
        activeVoiceRoom: state.activeVoiceRoom
      }
    }

    case actionTypes.RESET_ORGANIZATION_MEMBERS: {
      return {
        ...state,
        members: initialState.members
      }
    }

    case actionTypes.GET_ORGANIZATION_REGISTERED_USERS_SUCCESS: {
      return {
        ...state,
        invitations: {
          ...state.invitations,
          registeredUsers: {
            ...state.invitations.registeredUsers,
            list: action.payload.list,
            ids: action.payload.ids,
            total: action.payload.total
          },
          filters: {
            ...state.invitations.filters,
            current: action.payload.current
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATION_NEW_USERS_REQUEST:
    case actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_REQUEST:
    case actionTypes.GET_ORGANIZATION_REGISTERED_USERS_REQUEST:
    case actionTypes.GET_ORGANIZATIONS_INVITATIONS_REQUEST: {
      const {
        showBy = state.invitations.filters.showBy,
        current = state.invitations.filters.current
      } = action.payload || {}

      return {
        ...state,
        invitations: {
          ...state.invitations,
          filters: {
            ...state.invitations.filters,
            showBy,
            current
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATIONS_INVITATIONS_SUCCESS: {
      return {
        ...state,
        invitations: {
          ...state.invitations,
          received: {
            ...state.invitations.received,
            list: action.payload.list,
            ids: action.payload.ids,
            total: action.payload.total
          },
          filters: {
            ...state.invitations.filters,
            current: action.payload.current
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATION_ADMINISTRATIONS_SUCCESS: {
      return {
        ...state,
        invitations: {
          ...state.invitations,
          administrations: {
            ...state.invitations.administrations,
            list: action.payload.list,
            ids: action.payload.ids,
            total: action.payload.total
          },
          filters: {
            ...state.invitations.filters,
            current: action.payload.current
          }
        }
      }
    }

    case actionTypes.GET_ORGANIZATION_NEW_USERS_SUCCESS: {
      return {
        ...state,
        invitations: {
          ...state.invitations,
          newUsers: {
            ...state.invitations.newUsers,
            list: action.payload.list,
            ids: action.payload.ids,
            total: action.payload.total
          },
          filters: {
            ...state.invitations.filters,
            current: action.payload.current
          }
        }
      }
    }

    case actionTypes.RESET_ORGANIZATIONS_INVITATIONS_FILTERS: {
      return {
        ...state,
        invitations: {
          ...state.invitations,
          filters: initialState.invitations.filters
        }
      }
    }

    case actionTypes.GET_VOICE_ROOMS_SUCCESS: {
      const { ids, list } = action.payload

      return {
        ...state,
        voiceRooms: {
          ...state.voiceRooms,
          ids,
          list
        }
      }
    }

    case actionTypes.JOIN_VOICE_ROOM_SUCCESS: {
      const { id } = action.payload

      return {
        ...state,
        voiceRooms: {
          ...state.voiceRooms,
          list: {
            ...state.voiceRooms.list,
            [id]: {
              ...state.voiceRooms.list[id],
              ...action.payload
            }
          }
        },
        activeVoiceRoom: action.payload
      }
    }

    case actionTypes.CLOSE_VOICE_ROOM:
    case actionTypes.LEAVE_VOICE_ROOM_SUCCESS: {
      const { voiceRoomId } = action.payload

      return {
        ...state,
        voiceRooms: {
          ...state.voiceRooms,
          list: {
            ...state.voiceRooms.list,
            [voiceRoomId]: {
              ...state.voiceRooms.list[voiceRoomId],
              jwt: undefined
            }
          }
        },
        activeVoiceRoom: null
      }
    }

    case actionTypes.UPDATE_VOICE_ROOM_MEMBERS: {
      const { id, accounts } = action.payload

      return {
        ...state,
        voiceRooms: {
          ...state.voiceRooms,
          list: {
            ...state.voiceRooms.list,
            [id]: {
              ...state.voiceRooms.list[id],
              accounts
            }
          }
        }
      }
    }

    case actionTypes.UPDATE_ORGANIZATION: {
      const { id, ...rest } = action.payload

      if (!state.data.list[id]) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          list: {
            ...state.data.list,
            [id]: {
              ...state.data.list[id],
              id,
              ...rest
            }
          }
        }
      }
    }

    case actionTypes.SET_ORGANIZATION_ID: {
      return {
        ...state,
        currentOrganizationId: action.payload.nextOrganizationId
      }
    }

    default:
      return state
  }
}
