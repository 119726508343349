export type TVirtualListHeightOptions = {
  arr: unknown[]
  min: number
  max: number
  itemHeight: number
  defaultHeight: number
}
export type TGetVirtualListHeight = (options: TVirtualListHeightOptions) => number

export const getVirtualListHeight: TGetVirtualListHeight = ({
  arr,
  min,
  max,
  itemHeight,
  defaultHeight
}) => {
  if (arr.length === 0) {
    return itemHeight / 2
  }

  if (arr.length > min && arr.length <= max) {
    return itemHeight * arr.length
  }

  return defaultHeight
}

type TLoadMoreItemsProps = {
  limit: number
  onLoadNext: (page: number) => void

  callback?: () => void
}

export const getMoreItemsLoader =
  ({ limit, onLoadNext, callback }: TLoadMoreItemsProps) =>
  (start: number) =>
    new Promise<void>((resolve) => {
      const nextPage = Math.floor(start / limit)

      callback && callback()

      onLoadNext(nextPage)
      resolve()
    })
