import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import {
  GET_USER_PROFILE_BY_ID,
  getUserProfileByIdRequest,
  resetUserProfileView
} from 'store/userProfile'
import { createLoadingSelector } from 'store/loading'
import { UserProfilePreviewWrapper } from 'App/components/UserProfile/UserProfilePreviewWrapper'

export type TUserProfilePreviewWrapperProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_USER_PROFILE_BY_ID])

const mapStateToProps = (state: State) => ({
  id: state.global.accountData?.id || '',
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserProfile: getUserProfileByIdRequest,
      resetUserProfileView
    },
    dispatch
  )

export const UserProfilePreviewWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilePreviewWrapper)
