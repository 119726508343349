import { FC, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getCasesLinksRequest,
  GET_CASES_LINKS,
  removeErrorNotification,
  resetCaseLinksAction,
  resetCasesLinksFilter,
  setCasesLinksSearchAction,
  showModalAction,
  setCasesLinksFilterAction,
  TOGGLE_CASE_LINK
} from 'store'
import { State } from 'redux/rootReducer'
import { CaseLinkListWrapper } from 'App/components'
import { EModalComponents } from 'App/containers'
import { generateRandomArray } from 'utils'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

type TCaseLinkDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

type TCaseListLinkedCasesDialogContainerBase = {
  caseId: string
}

type TCaseListLinkedCasesDialogContainerView = TCaseLinkDialogContainerProps &
  TCaseListLinkedCasesDialogContainerBase

const DEFAULT_CASE_LENGTH = 3

const CaseListLinkedCasesDialogContainerView = ({
  loading,
  caseId,
  ids,
  humanReadableId,
  title,
  error,
  search,
  searchBy,
  classifications,
  roles,
  statuses,
  getCasesLinks,
  resetCaseLinks,
  showModal,
  removeError,
  setCasesLinksSearch,
  setCasesLinksFilter,
  resetFilter
}: TCaseListLinkedCasesDialogContainerView) => {
  const { t } = useTranslation()

  const linkedCasesIds = loading ? generateRandomArray(DEFAULT_CASE_LENGTH) : ids

  const handleCreateLinkedCase = useCallback(
    () =>
      showModal({
        modalType: EModalComponents.CASE_CREATE_DIALOG,
        modalTitle: t('cases.modal.linkCase.title_create'),
        modalProps: {
          caseIdToLink: caseId,
          caseHumanReadableId: humanReadableId,
          caseName: title,
          backModalType: EModalComponents.LIST_OF_LINKED_CASES
        }
      }),
    [caseId, humanReadableId, showModal, title, t]
  )

  const handleLinkCase = useCallback(
    () =>
      showModal({
        modalType: EModalComponents.CASE_LINK_DIALOG,
        modalTitle: t('cases.modal.linkCase.title'),
        modalProps: { caseId }
      }),
    [caseId, showModal, t]
  )

  useEffect(() => {
    getCasesLinks({ caseId })

    // Filters are required to trigger list update
  }, [caseId, getCasesLinks, search, searchBy, roles, statuses, classifications])

  useEffect(
    () => () => {
      resetCaseLinks()
      removeError(TOGGLE_CASE_LINK)
    },
    [resetCaseLinks, removeError]
  )

  return (
    <>
      <CaseLinkListWrapper
        isView={true}
        caseId={caseId}
        search={search}
        classifications={classifications}
        roles={roles}
        statuses={statuses}
        error={error}
        ids={linkedCasesIds}
        emptyIcon={<LinkChainIcon />}
        emptyText={t('cases.modal.linkCase.placeholder_linked')}
        loading={loading}
        actions={[
          {
            icon: <PlusIcon />,
            onClick: handleCreateLinkedCase,
            children: t('cases.modal.linkCase.title_create')
          },
          {
            icon: <LinkChainIcon />,
            onClick: handleLinkCase,
            children: t('cases.modal.linkCase.title')
          }
        ]}
        setSearch={setCasesLinksSearch}
        resetFilter={resetFilter}
        setFilter={setCasesLinksFilter}
      />
    </>
  )
}

const loadingSelector = createLoadingSelector([GET_CASES_LINKS])

const mapStateToProps = (state: State, { caseId }: TCaseListLinkedCasesDialogContainerBase) => {
  const { title, humanReadableId } = state.cases.caseList[caseId] ?? state.caseView.data ?? {}
  const { search, searchBy, classifications, roles, statuses } = state.cases.caseLinks.filters

  return {
    loading: loadingSelector(state),
    ids: state.cases.caseLinks.ids,
    humanReadableId,
    title,
    error: state.errors[TOGGLE_CASE_LINK],
    search,
    searchBy,
    classifications,
    roles,
    statuses
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCasesLinks: getCasesLinksRequest,
      resetCaseLinks: resetCaseLinksAction,
      showModal: showModalAction,
      removeError: removeErrorNotification,
      setCasesLinksSearch: setCasesLinksSearchAction,
      setCasesLinksFilter: setCasesLinksFilterAction,
      resetFilter: resetCasesLinksFilter
    },
    dispatch
  )

export const CaseListLinkedCasesDialogContainer: FC<TCaseListLinkedCasesDialogContainerBase> =
  connect(mapStateToProps, mapDispatchToProps)(CaseListLinkedCasesDialogContainerView)
