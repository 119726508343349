import {
  ContactStatusEnum,
  BusinessAccountRoleIdEnum,
  BusinessAccountStatusEnum
} from '@medentee/enums'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { AccountsSelect } from 'App/components/common/AccountsSelect'
import { useSelectAccounts } from 'App/hooks/useSelectAccounts'
import { getBusinessUsers } from 'api/business-users'
import {
  ADD_TO_BUSINESS_ROLE,
  addToBusinessRoleRequest,
  createProcessingSelector,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

const MAX_SUPER_ADMINS_SELECTED = 1

export const UserProfileAdministrationAddSuperAdminDialog = () => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(createProcessingSelector([ADD_TO_BUSINESS_ROLE]))

  const { t } = useTranslation()

  const onSubmitHandler = useCallback(
    (value: TAccountsSelectOptionData[]) => {
      const ids = value.map(({ id }) => id)

      dispatch(
        addToBusinessRoleRequest({
          ids,
          roleId: BusinessAccountRoleIdEnum.SUPERADMIN,
          statuses: [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
        })
      )
    },
    [dispatch]
  )

  const {
    state: { selectedAccounts },
    handleSubmit,
    onSelect,
    onUnselect
  } = useSelectAccounts({ onSubmit: onSubmitHandler, accounts: [] })

  const {
    data = [],
    isFetching,
    isLoading,
    error
  } = useQuery({
    queryKey: ['business_users', selectedAccounts],
    queryFn: () =>
      getBusinessUsers({
        roleIds: [BusinessAccountRoleIdEnum.ADMIN],
        statuses: [ContactStatusEnum.APPROVED]
      }),
    keepPreviousData: true,
    select: ({ results }) => results.map((item) => item.account)
  })

  return (
    <AccountsSelect
      items={data}
      selectedItems={selectedAccounts}
      hideSearch={true}
      loading={isLoading}
      submitLoading={processing || isFetching}
      error={error}
      maxSelected={MAX_SUPER_ADMINS_SELECTED}
      submitLabel={t('modal.addSuperadmin.submitButton')}
      selectTitle={t('modal.addSuperadmin.selectTitle')}
      emptyListText={t('modal.addSuperadmin.placeholder')}
      emptyContentText={t('modal.addSuperadmin.selectedListPlaceholder', {
        limit: MAX_SUPER_ADMINS_SELECTED
      })}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSubmit={handleSubmit}
    />
  )
}
