import { useEffect, useCallback } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Skeleton } from 'antd'
import { useParams } from 'react-router-dom'
import { ChatTypeEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  selectChatAction,
  getChatRoomsRequest,
  setChatTypeAction,
  createOrganizationChatRequest,
  createLoadingSelector,
  GET_CHAT_ROOMS
} from 'store'
import { CaseMemberChat } from 'App/components'
import { ChatRoomHeaderCaseMemberContainer } from 'App/containers/Chat/ChatRoomHeaderContainer'
import { Card } from 'App/components/common/Card'
import { Placeholder } from 'App/components/Case/CaseMemberChat/Placeholder'
import { PAGINATION_DEFAULT_PAGE } from 'types'
import { CHAT_ROOMS_DEFAULT_SHOW_BY } from 'globalConstants'

import styles from './CaseMemberChatContainer.module.scss'

export type TCaseMemberChatContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & { caseView?: string }

const CaseMemberChatView = ({
  getChatRooms,
  loading,
  chatRoomId,
  caseOwnerId,
  caseClassification,
  createOrganizationChat,
  isCoworker,
  selectChat,
  setChatType,
  caseView
}: TCaseMemberChatContainerProps) => {
  const { id: caseId } = useParams<{ id?: string }>()

  const chatType = ChatTypeEnum.DIALOGUE

  const fetchChatRooms = useCallback(() => {
    getChatRooms({
      caseId,
      chatType: [chatType],
      page: PAGINATION_DEFAULT_PAGE,
      showBy: CHAT_ROOMS_DEFAULT_SHOW_BY
    })
  }, [caseId, chatType, getChatRooms])

  useEffect(() => {
    if (caseId) {
      fetchChatRooms()
    }
  }, [caseId, fetchChatRooms])

  useEffect(() => {
    setChatType(chatType)
  }, [setChatType, chatType])

  return (
    <div className={styles.root}>
      <Card title={<ChatRoomHeaderCaseMemberContainer />} classes={{ header: styles.cardHeader }}>
        {!chatRoomId && !loading ? (
          <Placeholder
            chatRoomId={chatRoomId}
            caseOwnerId={caseOwnerId}
            isCoworker={isCoworker}
            caseClassification={caseClassification}
            fetchChatRooms={fetchChatRooms}
            createOrganizationChat={createOrganizationChat}
          />
        ) : (
          <Skeleton
            loading={loading}
            className={styles.skeleton}
            active={true}
            title={{ width: '55%' }}
            paragraph={{ rows: 10 }}
          >
            <CaseMemberChat caseView={caseView} chatRoomId={chatRoomId} selectChat={selectChat} />
          </Skeleton>
        )}
      </Card>
    </div>
  )
}

const mapStateToProps = (state: State) => {
  const chatRoom = Object.values(state.chat.chatRooms.list)[0]

  return {
    chatRoomId: chatRoom?.id,
    caseClassification: state.caseView.data?.classification?.name,
    caseOwnerId: state.caseView.data?.owner?.id,
    isCoworker:
      chatRoom?.interlocutorAccount?.isCoworker ?? state.caseView?.data?.owner?.isCoworker,
    loading:
      state.chat.chatRooms.status === 'IDLE' || createLoadingSelector([GET_CHAT_ROOMS])(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getChatRooms: getChatRoomsRequest,
      selectChat: selectChatAction,
      setChatType: setChatTypeAction,
      createOrganizationChat: createOrganizationChatRequest
    },
    dispatch
  )

export const CaseMemberChatContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMemberChatView)
