import { action, TError, TIds, TPluralProcessingPayload } from 'store'

import {
  TCaseMemberInviteApproveRequestPayload,
  TCaseMemberInviteRejectRequestPayload,
  TCasesInvitesList,
  TGetCasesInviteListRequestPayload,
  TSetCasesInviteListPaginationPayload
} from './casesInvite.types'

export const GET_CASES_INVITE_LIST_REQUEST = 'GET_CASES_INVITE_LIST_REQUEST'

export const SET_CASES_INVITE_LIST = 'SET_CASES_INVITE_LIST'
export const SET_CASES_INVITE_IDS = 'SET_CASES_INVITE_IDS'
export const SET_CASES_INVITE_LIST_LOADING = 'SET_CASES_INVITE_LIST_LOADING'
export const SET_CASES_INVITE_LIST_PAGINATION = 'SET_CASES_INVITE_LIST_PAGINATION'

export const getCasesInviteListRequest = (payload?: TGetCasesInviteListRequestPayload) =>
  action(GET_CASES_INVITE_LIST_REQUEST, payload)

export type TSetCasesInviteList = {
  casesInviteList: TCasesInvitesList
}

export const setCasesInviteList = (payload: TSetCasesInviteList) =>
  action(SET_CASES_INVITE_LIST, payload)

export type TSetCasesInviteIds = {
  ids: TIds
}

export const setCasesInviteListIds = (payload: TSetCasesInviteIds) =>
  action(SET_CASES_INVITE_IDS, payload)

export type TSetCasesInviteListLoading = {
  isLoading: boolean
}

export const setCasesInviteListLoading = (payload: TSetCasesInviteListLoading) =>
  action(SET_CASES_INVITE_LIST_LOADING, payload)

export const setCasesInviteListPagination = (payload: TSetCasesInviteListPaginationPayload) =>
  action(SET_CASES_INVITE_LIST_PAGINATION, payload)

// ------------------ INVITE_MEMBERS ------------------

export const CASE_MEMBER_INVITE_APPROVE = 'CASE_MEMBER_INVITE_APPROVE'
export const CASE_MEMBER_INVITE_APPROVE_REQUEST = 'CASE_MEMBER_INVITE_APPROVE_REQUEST'
export const CASE_MEMBER_INVITE_APPROVE_SUCCESS = 'CASE_MEMBER_INVITE_APPROVE_SUCCESS'
export const CASE_MEMBER_INVITE_APPROVE_ERROR = 'CASE_MEMBER_INVITE_APPROVE_ERROR'

export const CASE_MEMBER_INVITE_REJECT = 'CASE_MEMBER_INVITE_REJECT'
export const CASE_MEMBER_INVITE_REJECT_REQUEST = 'CASE_MEMBER_INVITE_REJECT_REQUEST'
export const CASE_MEMBER_INVITE_REJECT_SUCCESS = 'CASE_MEMBER_INVITE_REJECT_SUCCESS'
export const CASE_MEMBER_INVITE_REJECT_ERROR = 'CASE_MEMBER_INVITE_REJECT_ERROR'

export const caseMemberInviteApproveRequest = (payload: TCaseMemberInviteApproveRequestPayload) =>
  action(CASE_MEMBER_INVITE_APPROVE_REQUEST, payload)
export const caseMemberInviteApproveSuccess = (payload: TPluralProcessingPayload) =>
  action(CASE_MEMBER_INVITE_APPROVE_SUCCESS, payload)
export const caseMemberInviteApproveError = (payload: TError) =>
  action(CASE_MEMBER_INVITE_APPROVE_ERROR, payload)

export const caseMemberInviteRejectRequest = (payload: TCaseMemberInviteRejectRequestPayload) =>
  action(CASE_MEMBER_INVITE_REJECT_REQUEST, payload)
export const caseMemberInviteRejectSuccess = (payload: TPluralProcessingPayload) =>
  action(CASE_MEMBER_INVITE_REJECT_SUCCESS, payload)
export const caseMemberInviteRejectError = (payload: TError) =>
  action(CASE_MEMBER_INVITE_REJECT_ERROR, payload)

// ------------------ RESET_CASE_INVITE_LIST ------------------

export const RESET_CASE_INVITE_LIST_ACTION = 'RESET_CASE_INVITE_LIST_ACTION'

export const resetCaseInviteListAction = () => action(RESET_CASE_INVITE_LIST_ACTION)
