import { FC, useEffect } from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { QueryClientProvider } from 'services/query'
import config from 'config'
import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  createProcessingSelector,
  GET_INITIALIZE_APP,
  GET_LOGOUT,
  initializeApp,
  getFocusInBrowserAction,
  lostFocusInBrowserAction,
  SWITCH_ACCOUNT
} from 'store'
import {
  FileUploadingPrompt,
  ModalRoot,
  CallContainer,
  HeadNotificationContainer,
  RoleRouteContainer,
  MessagePushUpContainer,
  FullscreenContainer
} from 'App/containers'
import {
  Layout,
  ErrorBoundary,
  PageNotFound,
  Spinner,
  FallbackUI,
  WidgetsWrapper
} from 'App/components'
import { ToastContainer } from 'App/components/ToastContainer'
import { closeWebSocket } from 'services/webSocket'
import { DEFAULT_ROUTE, DOWNLOAD_IFRAME_NAME } from 'globalConstants'
import { setPageVisibilityFocusCallbacks } from 'utils'

import { queryClient } from '../queryClient'

import { MobileVideoPlayer, Mobile3DViewer } from './screens'
import { getKeyForRoute, routes } from './App.config'
import styles from './App.module.scss'
import { CallContextProvider } from './containers/Calls/CallContext'
import { useInvitationRedirect } from './hooks/useInvitationRedirect'
import { BroadcastContextProvider } from './components/Broadcasts/BroadcastContext'
import { BroadcastContainer } from './components/Broadcasts/BroadcastContainer'

type TAppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export enum ECustomEventsName {
  RESIZE = 'optimizedResize'
}
export enum ECustomEventsType {
  RESIZE = 'optimizedResize'
}

export const registerCustomEventsWithRequestAnimationFrame = () => {
  const throttle = (type: ECustomEventsType, name: ECustomEventsName, obj = window) => {
    let running = false
    const func = () => {
      if (running) {
        return
      }
      running = true

      requestAnimationFrame(() => {
        obj.dispatchEvent(new CustomEvent(name))
        running = false
      })
    }
    obj.addEventListener(type, func)
  }

  /* init - you can init any event */
  throttle(ECustomEventsType.RESIZE, ECustomEventsName.RESIZE)
}

const handleCloseTab = () => {
  window.addEventListener('beforeunload', () => {
    closeWebSocket()
  })
}

const AppComponent: FC<TAppProps> = ({
  loading,
  processing,
  accountType,
  initApp,
  getFocusInBrowser,
  lostFocusInBrowser
}) => {
  useInvitationRedirect()

  useEffect(() => {
    initApp()
    setPageVisibilityFocusCallbacks(getFocusInBrowser, lostFocusInBrowser)

    // registerCustomEventsWithRequestAnimationFrame()
    handleCloseTab()

    // eslint-disable-next-line no-console
    console.log(process.env.REACT_APP_GIT_HASH || '', ' <=== GIT_HASH ===') // This need for production build.

    return () => {
      closeWebSocket()
    }
  }, [getFocusInBrowser, initApp, lostFocusInBrowser])

  return (
    <CallContextProvider>
      <BroadcastContextProvider>
        <QueryClientProvider client={queryClient}>
          {loading || processing || !accountType ? (
            <Spinner />
          ) : (
            <Switch>
              <RoleRouteContainer
                exact
                path="/mobile-video-player/:fileId"
                component={MobileVideoPlayer}
              />
              <RoleRouteContainer
                exact
                path="/mobile-3d-viewer/:fileId/:extension"
                component={Mobile3DViewer}
              />
              <Layout>
                <ErrorBoundary fallback={<FallbackUI />}>
                  <Switch>
                    <Redirect exact={true} path="/" to={DEFAULT_ROUTE} />
                    {routes.map((item, index) => (
                      <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
                    ))}

                    {/* 404 */}
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                </ErrorBoundary>
              </Layout>
            </Switch>
          )}

          <iframe
            className={styles.hidden}
            id={DOWNLOAD_IFRAME_NAME}
            title={DOWNLOAD_IFRAME_NAME}
            name={DOWNLOAD_IFRAME_NAME}
          ></iframe>

          <ModalRoot />
          <CallContainer />
          <BroadcastContainer />
          <FullscreenContainer />
          <HeadNotificationContainer />

          <ToastContainer
            autoClose={false}
            closeOnClick={false}
            draggable={false}
            className={styles.toast}
          />

          <FileUploadingPrompt />
          <WidgetsWrapper />
          <MessagePushUpContainer />

          {config.isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
      </BroadcastContextProvider>
    </CallContextProvider>
  )
}

const loadingSelector = createLoadingSelector([GET_INITIALIZE_APP, GET_LOGOUT])
const processingSelector = createProcessingSelector([SWITCH_ACCOUNT])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  processing: processingSelector(state),
  accountType: state.global.accountData?.type.name
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      initApp: initializeApp,
      getFocusInBrowser: getFocusInBrowserAction,
      lostFocusInBrowser: lostFocusInBrowserAction
    },
    dispatch
  )

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent)
