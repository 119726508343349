import { ErrorCode } from 'react-dropzone'

import { IFilesEntity } from 'interfaces'
import { formatBytes } from 'utils'
import { MAX_SHOWCASE_FILES_AMOUNT } from 'globalConstants'

import { TFileWrapperProps } from '../FileWrapper'

export const ACCEPTED_IMAGES_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.bmp', '.dib', '.gif']
export const ACCEPTED_VIDEOS_EXTENSIONS = ['.mp4', '.webm', '.ogg', '.mov', '.wmv', '.avi', '.flv']

type TErrorMapPayload = {
  maxSize: number
  file: File
}

export const ERROR_MESSAGES_MAP = new Map<
  ErrorCode,
  ({ maxSize, file }: TErrorMapPayload) => string
>()
  .set(ErrorCode.TooManyFiles, () => `You can add up to ${MAX_SHOWCASE_FILES_AMOUNT} items`)
  .set(
    ErrorCode.FileTooLarge,
    ({ maxSize, file }) =>
      `Your upload size ${formatBytes(
        file.size
      )} exceeds the remaining space in ShowCase ${formatBytes(maxSize)}`
  )
  .set(ErrorCode.FileInvalidType, () => `File's extension is not allowed`)

export type TShowcaseFile = Pick<TFileWrapperProps, 'file' | 'uuid' | 'orderNumber'>

export const getUploadedFiles = (files: TShowcaseFile[]) =>
  files.filter(({ file }) => !(file instanceof File)) as (Pick<
    TFileWrapperProps,
    'uuid' | 'orderNumber'
  > & { file: IFilesEntity })[]

export const getUploadedFileList = (files: TShowcaseFile[]) =>
  getUploadedFiles(files).map(({ file }) => file)
