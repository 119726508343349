import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components/common'
import {
  createProcessingSelector,
  getOrganizationsRequest,
  removeAdminRequest,
  REMOVE_ADMIN,
  useAppDispatch,
  useAppSelector
} from 'store'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

type TLeaveOrganizationDialogProps = {
  organizationId: string
}

const processingSelector = createProcessingSelector([REMOVE_ADMIN])

export const LeaveOrganizationDialog = ({ organizationId }: TLeaveOrganizationDialogProps) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector)
  const accountRoleName = useAppSelector((state) => state.global.accountData?.role)
  const businessUserId = useAppSelector(
    (state) => state.organizations.data.list[organizationId]?.businessUser.id
  )

  const { t } = useTranslation()

  const handleLeaveSuccess = () => {
    if (accountRoleName) {
      return
    }

    dispatch(getOrganizationsRequest())
    toast.warn(t('common.toast.unavailableOrganization'), toastDefaultOptions)
  }

  const handleButtonClick = () => {
    dispatch(
      removeAdminRequest({
        businessUserId,
        onSuccess: handleLeaveSuccess
      })
    )
  }

  return (
    <ConfirmationModal
      content={t('modal.leaveOrganizationConfirm.content')}
      controls={
        <Button disabled={processing} loading={processing} onClick={handleButtonClick}>
          {t('modal.leaveOrganizationConfirm.submitButton')}
        </Button>
      }
    />
  )
}
