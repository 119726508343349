import { FC } from 'react'
import AntdCollapsePanel, { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel'
import cls from 'classnames'

import styles from './CollapsePanel.module.scss'

export type TCollapsePanelProps = CollapsePanelProps & { variant?: 'highlighted' }

export const CollapsePanel: FC<TCollapsePanelProps> = ({
  variant,
  className = '',
  children,
  collapsible,
  ...props
}) => {
  const mainClassname = cls({
    [styles.root]: true,
    [className]: !!className,
    [styles.highlighted]: variant === 'highlighted'
  })

  // fix broken collapsible property on Panel component
  return collapsible === 'disabled' ? (
    <div className={cls('ant-collapse-item', mainClassname, styles.disabled)}>
      <div className="ant-collapse-header">
        <div />
        {props.header}
      </div>
    </div>
  ) : (
    <AntdCollapsePanel {...props} collapsible={collapsible} className={mainClassname}>
      {children}
    </AntdCollapsePanel>
  )
}
