import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  cancelMeetingRequest,
  createProcessingBySelector,
  CANCEL_MEETING,
  useAppSelector
} from 'store'
import { Button, ConfirmationModal } from 'App/components/common'

const processingSelector = createProcessingBySelector([CANCEL_MEETING])

export type TCancelMeetingDialogProps = {
  id: string

  onSuccess?: () => void
}

export const CancelMeetingDialog = ({ id, onSuccess }: TCancelMeetingDialogProps) => {
  const dispatch = useDispatch()

  const processing = useAppSelector((state) => processingSelector(id)(state))

  const { t } = useTranslation()

  const onClick = () =>
    dispatch(
      cancelMeetingRequest({
        id,
        processingId: id,
        onSuccess
      })
    )

  return (
    <ConfirmationModal
      content={t('modal.cancelMeetingConfirm.content')}
      controls={
        <Button loading={processing} onClick={onClick}>
          {t('modal.cancelMeetingConfirm.submitButton')}
        </Button>
      }
    />
  )
}
