import { useMemo } from 'react'
import { ChatStatusEnum, ChatTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { useAppSelector } from 'store'
import { EmptyList } from 'App/components'
import { Interlocutor, ChatFilesContainer, ChatCaseInfo } from 'App/containers'
import { useOrganizationId } from 'App/components/Organizations/hooks'
import { ReactComponent as AccountRemoveIcon } from 'assets/icons/AccountRemove.svg'

import { GroupChatInfo } from '../GroupChatInfo'

import styles from './ChatDetails.module.scss'

export const CHAT_DETAILS_ID = 'chat-details'

export type TChatDetailsProps = {
  entityId?: string
}

const EMPTY_TEXTS = new Map<ChatTypeEnum, (t: TFunction) => string>().set(
  ChatTypeEnum.DIALOGUE,
  (t) => t('chat.details.placeholder_dialogue')
)

export const ChatDetails = ({ entityId }: TChatDetailsProps) => {
  const {
    interlocutorAccount,
    type,
    status,
    case: caseData
  } = useAppSelector((state) => state.chat.chatRooms.selectedChat) ?? {}

  const { t } = useTranslation()

  const { isContact, isCoworker } = interlocutorAccount ?? {}

  const isChatActive = status === ChatStatusEnum.ACTIVE
  const isCaseGroup = type === ChatTypeEnum.CASE_GROUP
  const isOrganization = type === ChatTypeEnum.ORGANIZATION
  const isCommunity =
    type === ChatTypeEnum.COMMUNITY_CHANNEL || type === ChatTypeEnum.COMMUNITY_NEWS
  const isEvent = type === ChatTypeEnum.EVENT_CHANNEL || type === ChatTypeEnum.EVENT_NEWS
  const isGroupChat = type === ChatTypeEnum.GROUP
  const shouldShowChatDetails =
    ((isContact || isCoworker || isOrganization || isGroupChat || isCommunity || isEvent) &&
      isChatActive) ||
    isCaseGroup

  const organizationId = useOrganizationId()

  const getInfoComponent = () => {
    if (isCaseGroup) {
      return <ChatCaseInfo />
    }

    if (isGroupChat) {
      return <GroupChatInfo />
    }

    return <Interlocutor />
  }

  const placeholder = useMemo(
    () => getMapComponent(EMPTY_TEXTS, type, t) || t('chat.details.placeholder'),
    [type, t]
  )

  return (
    <div className={cls(styles.root)} id={CHAT_DETAILS_ID}>
      {shouldShowChatDetails && getInfoComponent()}

      {shouldShowChatDetails ? (
        <ChatFilesContainer caseId={caseData?.id} entityId={entityId || organizationId} />
      ) : (
        <EmptyList icon={<AccountRemoveIcon />} text={placeholder} />
      )}
    </div>
  )
}
