import cls from 'classnames'

import { TChatMessageSystemContainerProps } from 'App/containers'

import { ChatMessageMeetingSystem } from '../ChatMessageMeetingSystem'

import styles from './ChatMessageSystem.module.scss'

export type TChatMessageSystemBaseProps = {
  id: string
  chatId: string
}

export type ChatMessageSystemProps = TChatMessageSystemBaseProps & TChatMessageSystemContainerProps

export const ChatMessageSystem = ({ message, meeting, isOrganizer }: ChatMessageSystemProps) => {
  if (meeting) {
    return <ChatMessageMeetingSystem {...meeting} isOrganizer={isOrganizer} />
  }

  // TODO: system messages must contain code property
  const isChatStatusMessage = String(message).startsWith('Chat has been')

  return (
    <div className={cls(styles.root, { [styles.chatStatus]: isChatStatusMessage })}>{message}</div>
  )
}
