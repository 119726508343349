import { SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Help, Button as OriginalButton } from 'App/components/common'
import { useAppSelector, useAppDispatch, showModalAction } from 'store'
import { EModalComponents } from 'App/containers'
import { MAX_ADMIN_ACCOUNTS_MANAGED } from 'globalConstants'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './Button.module.scss'

export const Button = () => {
  const {
    canAddAdmin,
    subscriptionId,
    ids: userIds
  } = useAppSelector((state) => ({
    canAddAdmin: state.administration.canAddAdmin,
    subscriptionId: state.subscriptions.current?.subscription?.id,
    ids: state.administration.ids
  }))

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onAdd = () =>
    dispatch(
      showModalAction({
        modalTitle: t('modal.addAdmin.title'),
        modalType: EModalComponents.ADD_ADMIN_DIALOG,
        modalProps: {
          maxSelected: canAddAdmin,
          excludedIds: userIds
        }
      })
    )

  const isBasicPlan = subscriptionId === SubscriptionIdsEnum.BASIC_BUSINESS
  const disabled = !canAddAdmin || isBasicPlan

  return (
    <>
      <OriginalButton
        variant="text"
        onClick={onAdd}
        disabled={disabled}
        icon={<PlusIcon />}
        className={styles.button}
      >
        {t('organizations.addAdminButton')}
      </OriginalButton>
      {disabled && (
        <Help
          content={
            isBasicPlan
              ? t('organizations.addAdminHelp_basic')
              : t('organizations.addAdminHelp', { limit: MAX_ADMIN_ACCOUNTS_MANAGED })
          }
        />
      )}
    </>
  )
}
