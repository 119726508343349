import noop from 'lodash/noop'

import { useMutation } from 'services/query'
import { deleteShowcase } from 'api/showcase'
import { hideModalAction, useAppDispatch } from 'store'

import { Button, ConfirmationModal } from '../../../../components'

export type TDeactivateShowcaseConfirmationModalProps = {
  id: string
  onSuccess: () => void
}

export const DeleteShowcaseConfirmationModal = ({
  id,
  onSuccess
}: TDeactivateShowcaseConfirmationModalProps) => {
  const dispatch = useAppDispatch()

  const { isLoading, mutate, error } = useMutation({
    mutationKey: ['delete-showcase'],
    mutationFn: () => deleteShowcase(id),
    onSuccess: () => {
      onSuccess()
      dispatch(hideModalAction())
    },
    // disable default toast notification
    onError: () => noop()
  })

  const handleConfirm = () => {
    mutate()
  }

  return (
    <ConfirmationModal
      error={error}
      content="Are you sure you want to delete this Showcase?"
      controls={
        <Button onClick={handleConfirm} loading={isLoading}>
          Confirm
        </Button>
      }
    />
  )
}
