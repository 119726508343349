import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADD_TO_BUSINESS_ROLE_REQUEST,
  addToBusinessRoleError,
  addToBusinessRoleSuccess,
  GET_BUSINESS_USERS_REQUEST,
  getBusinessUsersError,
  getBusinessUsersRequest,
  getBusinessUsersSuccess,
  REMOVE_ADMIN_REQUEST,
  removeAdminError,
  removeAdminSuccess,
  ADMIN_LOST_ACCESS_ACTION,
  RECEIVE_ACCEPTED_SUPERADMIN_INVITATION
} from 'store/administration/administration.actions'
import { ContactsQueryBuilder, QueryBuilder } from 'utils'
import { API, api, APIData } from 'services/api'
import {
  TAddToBusinessRoleRequest,
  TGetBusinessUsersRequest,
  TGetBusinessUsersResponse,
  TRemoveAdminRequest
} from 'store/administration/administration.types'
import { administrationNormalize } from 'store/administration/administration.normalization'
import { hideModalAction } from 'store/modal'
import { getWhoAmiRequest } from 'store'
import { EContactsSort } from 'enums'
import { logoutRequest } from 'store/logout'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

function* getBusinessUsersSaga({ payload }: TGetBusinessUsersRequest) {
  try {
    const url = new ContactsQueryBuilder(API.BUSINESS_USERS)
      .sortBy(EContactsSort.ROLES)
      .multiSearch('statuses', payload?.statuses ?? [])
      .build()

    const { data }: APIData<TGetBusinessUsersResponse> = yield call(api.get, url)
    const { results, canAddSuperadmin, canAddAdmin } = data

    const { ids, list } = administrationNormalize(results, payload?.excludeAlterRoleFilter)

    yield put(
      getBusinessUsersSuccess({
        ids,
        list,
        canAddSuperadmin,
        canAddAdmin
      })
    )
  } catch (e) {
    yield put(getBusinessUsersError(e))
    handleError(e)
  }
}

function* addToBusinessRoleSaga({ payload }: TAddToBusinessRoleRequest) {
  try {
    const { roleId, statuses, ids } = payload

    yield call(api.post, API.BUSINESS_USERS, {
      roleId,
      accountIds: ids
    })

    yield put(addToBusinessRoleSuccess())
    yield put(hideModalAction())
    yield put(getBusinessUsersRequest({ statuses }))
  } catch (e) {
    yield put(addToBusinessRoleError(e))
  }
}

function* removeAdminSaga({
  payload: { businessUserId, onSuccess, onError, onlyDowngrade = false, onlyHidden = false }
}: TRemoveAdminRequest) {
  try {
    const url = new QueryBuilder(API.BUSINESS_USERS_ID(businessUserId))
      .stringify({ onlyHidden })
      .stringify({ onlyDowngrade })
      .build()

    yield call(api.delete, url)

    yield put(hideModalAction())
    yield put(removeAdminSuccess())

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield handleError(e)
    yield put(removeAdminError(e))
    yield put(hideModalAction())

    if (onError) {
      onError()
    }
  }
}

function* adminLostAccessSaga() {
  try {
    yield put(
      logoutRequest({
        type: 'warn',
        message: i18n.t('common.toast.unavailableOrganization')
      })
    )
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveAcceptedSuperAdminInvitationSaga() {
  yield put(getWhoAmiRequest())
  yield put(getBusinessUsersRequest())
}

export function* administrationSaga() {
  yield takeLatest(GET_BUSINESS_USERS_REQUEST, getBusinessUsersSaga)
  yield takeLatest(ADD_TO_BUSINESS_ROLE_REQUEST, addToBusinessRoleSaga)
  yield takeLatest(REMOVE_ADMIN_REQUEST, removeAdminSaga)
  yield takeLatest(ADMIN_LOST_ACCESS_ACTION, adminLostAccessSaga)
  yield takeLatest(RECEIVE_ACCEPTED_SUPERADMIN_INVITATION, receiveAcceptedSuperAdminInvitationSaga)
}
