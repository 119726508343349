import { Skeleton } from 'antd'
import { useSelector } from 'react-redux'
import { CasePermissionsModeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import { EAvatarSize } from 'enums'
import { EModalComponents, TFileContactItemContainerProps } from 'App/containers'
import { AccountName, PermissionIcons, TChangePermissionProps } from 'App/components'

import styles from './FileContactItem.module.scss'

export type TFileContactItemProps = PartialBy<
  TFileContactItemContainerProps,
  'sharedInCasesCount' | 'showModal'
> & {
  caseId?: string
  permissionsMode?: CasePermissionsModeEnum
}

export const FileContactItem = ({
  firstName,
  lastName,
  contactUserId,
  displayUserName,
  type,
  permissions,
  fileId,
  processing,
  changeUserPermission,
  showModal,
  sharedInCasesCount,
  caseId,
  permissionsMode
}: TFileContactItemProps) => {
  const { t } = useTranslation()

  const isCasesDisabled = sharedInCasesCount === 0

  const isYourFile = useSelector((state: State) =>
    fileId ? !!state.caseCloud.uploadedByMeFiles.list[fileId] : false
  )

  const changeUserPermissionHandler = ({ enabled, permissionId }: TChangePermissionProps) => {
    fileId &&
      changeUserPermission({
        enabled,
        permissionId,
        fileId,
        userId: contactUserId,
        caseId,
        processingId: contactUserId
      })
  }

  const onCasesCountClick = () => {
    showModal &&
      sharedInCasesCount &&
      fileId &&
      showModal({
        modalType: EModalComponents.DISCARD_CASES_PERMISSIONS_CONFIRM,
        modalTitle: t('modal.discardCaseFilePermissionsConfirm.title'),
        modalProps: {
          fileId,
          contactUserId
        }
      })
  }

  const autoPermissionsMode = permissionsMode === CasePermissionsModeEnum.AUTO

  return (
    <div key={contactUserId} className={styles.root}>
      <Skeleton
        loading={processing}
        active={true}
        avatar={true}
        title={true}
        className={styles.skeleton}
      >
        <AccountName
          showAvatar
          type={type?.name ?? ''}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          userId={contactUserId}
          size={EAvatarSize.XXS}
        />

        <div className={styles.permissions}>
          <PermissionIcons
            permissions={permissions}
            changePermission={changeUserPermissionHandler}
            id={contactUserId}
            isYourFile={isYourFile}
            autoPermissionsMode={autoPermissionsMode}
          />
          {sharedInCasesCount !== undefined && (
            <button
              className={styles.btnPermissions}
              disabled={isCasesDisabled}
              onClick={onCasesCountClick}
            >
              {sharedInCasesCount}
            </button>
          )}
        </div>
      </Skeleton>
    </div>
  )
}
