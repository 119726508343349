import { useCallback } from 'react'
import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  getBroadcastsRequest,
  hideModalAction,
  onChatRoomNotFoundError,
  openBroadcast,
  RECEIVE_BROADCAST_DELETED,
  useAppDispatch
} from 'store'
import { Button, ConfirmationModal } from 'App/components/common'
import { deleteBroadcast } from 'api/broadcasts'
import { skipWSAction } from 'services/skipWSActions'

type TBroadcastDeleteConfirmProps = {
  broadcastId: string
  type: BroadcastTypeEnum
  entityId: string
}

export const BroadcastDeleteConfirm = ({
  broadcastId,
  type,
  entityId
}: TBroadcastDeleteConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = () => {
    dispatch(hideModalAction())
    dispatch(getBroadcastsRequest({ communityIds: [entityId], eventIds: [entityId], type }))
    dispatch(openBroadcast({ broadcastId: null, entityId: null }))
  }

  const { mutate, error, isLoading } = useMutation({
    mutationKey: ['delete_broadcast', broadcastId],
    mutationFn: deleteBroadcast,
    onSuccess,
    onError: (e) => onChatRoomNotFoundError(e, onSuccess)
  })

  const onClick = useCallback(() => {
    mutate(broadcastId)
    skipWSAction({ type: RECEIVE_BROADCAST_DELETED, payload: null })
  }, [broadcastId, mutate])

  return (
    <ConfirmationModal
      error={error}
      content={t('modal.deleteLiveEventConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteLiveEventConfirm.submitButton')}
        </Button>
      }
    />
  )
}
