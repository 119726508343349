import { TAction } from 'store'
import { Pagination, PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_TOTAL } from 'types'

import * as FILE_SHARED_ACTIONS from './files.actions'
import {
  TChangeFileSharedContactPermissionSuccess,
  TFileSharedTypes,
  TGetFilesSharedCountsSuccess,
  TSetFileSharedContactIds,
  TSetFileSharedContactList,
  TSetFileSharedDetailsId,
  TSetFileSharedFiltersSuccess,
  TSetFileSharedIds,
  TSetFileSharedList,
  TSetFileSharedPagination,
  TSetFileSharedSorting
} from './files.types'

export const FILE_SHARED_LIST_DEFAULT_PAGINATION: Pagination = {
  current: PAGINATION_DEFAULT_PAGE,
  total: PAGINATION_DEFAULT_TOTAL,
  showBy: 20
}

export const initialFileSharedListState: TFileSharedTypes = {
  ids: [],
  fileList: {},
  counts: null,
  countsTotal: 0,
  pagination: FILE_SHARED_LIST_DEFAULT_PAGINATION,
  filters: {},
  fileSharedDetailsId: null,
  fileSharedContactList: {},
  fileSharedContactIds: []
}

export const filesSharedReducer = (state = initialFileSharedListState, action: TAction) => {
  switch (action.type) {
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_LIST: {
      return { ...state, fileList: (action as TSetFileSharedList).payload.fileList }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_LIST_IDS: {
      return { ...state, ids: (action as TSetFileSharedIds).payload.ids }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_LIST_PAGINATION: {
      return { ...state, pagination: (action as TSetFileSharedPagination).payload.pagination }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_LIST_SORTING: {
      return { ...state, sorting: (action as TSetFileSharedSorting).payload.sorting }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_LIST_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as TSetFileSharedFiltersSuccess).payload
        },
        pagination: {
          ...state.pagination,
          current: 0
        }
      }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_DETAILS_ID: {
      return {
        ...state,
        fileSharedDetailsId: (action as TSetFileSharedDetailsId).payload.fileSharedDetailsId
      }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_CONTACT_LIST: {
      const { fileSharedContactList, fileSharedContactIds } = (action as TSetFileSharedContactList)
        .payload

      return {
        ...state,
        fileSharedContactList,
        fileSharedContactIds
      }
    }
    case FILE_SHARED_ACTIONS.CHANGE_FILE_SHARED_CONTACT_PERMISSION_SUCCESS: {
      const { userId, permissions } = (action as TChangeFileSharedContactPermissionSuccess).payload

      return {
        ...state,
        fileSharedContactList: {
          ...state.fileSharedContactList,
          [userId]: {
            ...state.fileSharedContactList[userId],
            permissions
          }
        }
      }
    }
    case FILE_SHARED_ACTIONS.SET_FILE_SHARED_CONTACT_IDS: {
      return {
        ...state,
        fileSharedContactIds: (action as TSetFileSharedContactIds).payload.fileSharedContactIds
      }
    }
    case FILE_SHARED_ACTIONS.RESET_FILE_SHARED_LIST_ACTION: {
      return {
        ...initialFileSharedListState
      }
    }
    case FILE_SHARED_ACTIONS.RESET_FILE_SHARED_LIST_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          extensionCategories: undefined
        }
      }
    }
    case FILE_SHARED_ACTIONS.GET_FILES_SHARED_COUNTS_SUCCESS: {
      const { counts, total } = (action as TGetFilesSharedCountsSuccess).payload

      return {
        ...state,
        counts,
        countsTotal: total
      }
    }
    case FILE_SHARED_ACTIONS.RESET_FILES_SHARED_FOR_CATEGORY: {
      return {
        ...state,
        ids: initialFileSharedListState.ids,
        fileList: initialFileSharedListState.fileList,
        pagination: initialFileSharedListState.pagination,
        filters: initialFileSharedListState.filters
      }
    }

    default:
      return state
  }
}
