import { AccountTypeNames, ContactStatusEnum } from '@medentee/enums'

import { EContactsSort } from 'enums'
import { formatContactsData } from 'store/contacts/utils'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { ContactsQueryBuilder } from '../utils'
import { api, API, APIResultsResponse } from '../services/api'
import { TContactsData } from '../store'

export type TSearchBy = {
  group?: string | null
  id?: string | null
}

export type TGetContactsPayload = {
  accountId: string

  search?: string
  searchBy?: TSearchBy
  caseIds?: string[]
  excludeIds?: string[]
  excludeChatIds?: string[]
  accountType?: AccountTypeNames

  page?: number
  showBy?: number
  organizationIds?: string[]
  sortBy?: EContactsSort
  withoutHidden?: boolean
}

export const getContacts = async (payload: TGetContactsPayload) => {
  const {
    accountId,
    searchBy,
    accountType,
    page,
    withoutHidden,
    showBy = PAGINATION_DEFAULT_SHOW_BY,
    caseIds = [],
    excludeIds = [],
    excludeChatIds = [],
    search = '',
    sortBy = EContactsSort.NAME
  } = payload

  const queryBuilder = new ContactsQueryBuilder(API.CONTACTS)

  const categoryId = searchBy?.group === 'categories' ? searchBy.id : ''
  const organizationId = searchBy?.group === 'organizations' ? searchBy.id : ''
  const departmentId = searchBy?.group === 'departments' ? searchBy.id : ''

  const organizationIds = payload.organizationIds ?? (organizationId ? [organizationId] : [])

  const url = queryBuilder
    .search(search)
    .categoryIds(categoryId ? [categoryId] : [])
    .organizationIds(organizationIds)
    .departmentIds(departmentId ? [departmentId] : [])
    .marks(
      searchBy?.group === 'allContacts' || searchBy?.group === 'categories'
        ? ['NOT_VIRTUAL']
        : ['NOT_VIRTUAL', 'VIRTUAL']
    )
    .status(ContactStatusEnum.APPROVED)
    .excludeIds(excludeIds)
    .excludeIds(excludeChatIds, 'excludeChatIds')
    .accountType(accountType ? [accountType] : null)
    .page(page)
    .sortBy(sortBy)
    .showBy(showBy)
    .withoutHidden(withoutHidden)
    .caseIds(caseIds)
    .build()

  const { data }: APIResultsResponse<TContactsData[]> = await api.get(url)

  return {
    results: formatContactsData(data.results, accountId).map<
      TContactsData['to'] & { contactId: TContactsData['id']; chatId?: string }
    >(({ to, id, chatId }) => ({
      ...to,
      chatId,
      contactId: id
    })),
    total: data.total
  }
}
