import { CasePermissionsModeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Switch } from 'App/components'
import { parseSpaces } from 'utils'

import styles from './CaseCloudPermissionsMode.module.scss'

export type TCaseCloudPermissionsModeProps = PropsWithSpaces<{
  onChange: (value: boolean) => void

  permissionsMode?: CasePermissionsModeEnum
}>

export const CaseCloudPermissionsMode = ({
  spaces,
  onChange,
  permissionsMode = CasePermissionsModeEnum.MANUAL
}: TCaseCloudPermissionsModeProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root} style={parseSpaces(spaces)}>
      <div className={styles.title}>{t('cases.details.attachments.permissionsMode')}</div>
      <Switch checked={permissionsMode === CasePermissionsModeEnum.AUTO} onChange={onChange} />
    </div>
  )
}
