import {
  ChangeEventHandler,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import isEqual from 'lodash/isEqual'

import { IJitsiMeetAPI, TJitsiCurrentDevices, TJitsiAvailableDevices } from 'types/jitsi'
import { useEvent } from 'App/hooks'

import { DevicesWrapper } from '../DevicesWrapper'
import { VideoDevicesList } from '../VideoDevicesList'

import styles from './VideoDevicesWrapper.module.scss'

export type TVideoDevicesWrapperProps = PropsWithChildren<{
  jitsiAPI: IJitsiMeetAPI
  isFullscreen: boolean

  shouldShowWrapper?: boolean
}>

const changeDevice = async (jitsiAPI: IJitsiMeetAPI, deviceLabel?: string, deviceId?: string) => {
  try {
    const isDeviceChangeAvailable = await jitsiAPI.isDeviceChangeAvailable('input')

    if (!(isDeviceChangeAvailable && deviceLabel && deviceId)) {
      return
    }

    await jitsiAPI.setVideoInputDevice(deviceLabel, deviceId)
  } catch (error) {
    console.error(error)
  }
}

const removeEmptyValues = (devices: TJitsiAvailableDevices['videoInput']) =>
  devices.filter((item) => Boolean(item.label))

const VideoDevicesWrapperView = ({
  jitsiAPI,
  isFullscreen,
  children,
  shouldShowWrapper = true
}: TVideoDevicesWrapperProps) => {
  const [isVisible, setVisibleChange] = useState<boolean>(false)
  const [isMultipleAudioInputSupported, setMultipleAudioInputSupported] = useState<boolean>(false)

  const [availableDevices, setAvailableDevices] = useState<TJitsiAvailableDevices['videoInput']>([])
  const [currentDevices, setCurrentDevices] = useState<TJitsiCurrentDevices['videoInput']>(null)

  const shouldHideDevices = Boolean(!availableDevices.length || !isFullscreen)

  const updateCurrentDevices = useEvent((device: TJitsiCurrentDevices['videoInput']) => {
    if (!isEqual(currentDevices, device)) {
      setCurrentDevices(device)
    }
  })

  const updateAvailableDevices = useEvent(async (devices: TJitsiAvailableDevices['videoInput']) => {
    if (!isEqual(availableDevices, devices)) {
      setAvailableDevices(devices)
    }
  })

  const getDevicesList = useCallback(async () => {
    try {
      const isMultiple = await jitsiAPI.isMultipleAudioInputSupported()
      setMultipleAudioInputSupported(isMultiple)

      if (!isMultiple) {
        return
      }

      const available = await jitsiAPI.getAvailableDevices()
      const current = await jitsiAPI.getCurrentDevices()

      setAvailableDevices(removeEmptyValues(available.videoInput))
      setCurrentDevices(current.videoInput)
    } catch (error) {
      console.error(error)
    }
  }, [jitsiAPI])

  const handleVisibleChange = (visible: boolean) => {
    getDevicesList()
    setVisibleChange(visible)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async ({ target: { value } }) => {
      const nextDevice = availableDevices.find(({ deviceId }) => deviceId === value)

      await changeDevice(jitsiAPI, nextDevice?.label, nextDevice?.deviceId)
      await getDevicesList()
    },
    [availableDevices, getDevicesList, jitsiAPI]
  )

  useLayoutEffect(() => {
    getDevicesList()

    const handler = ({ devices }: { devices: TJitsiAvailableDevices }) => {
      updateAvailableDevices(removeEmptyValues(devices.videoInput))
    }

    jitsiAPI.addEventListener('deviceListChanged', handler)

    return () => {
      jitsiAPI.removeEventListener('deviceListChanged', handler)
    }
  }, [jitsiAPI, getDevicesList, updateAvailableDevices])

  useEffect(() => {
    const getCurrentDevices = async () => {
      try {
        const devices = await jitsiAPI.getCurrentDevices()
        updateCurrentDevices(devices.videoInput)
      } catch (error) {
        console.error(error)
      }
    }

    getCurrentDevices()
  }, [jitsiAPI, isVisible, updateCurrentDevices])

  return (
    <>
      {children}

      {shouldShowWrapper && (
        <DevicesWrapper
          isVisible={isVisible}
          onVisibleChange={handleVisibleChange}
          shouldHideDevices={shouldHideDevices}
          disabledIcon={!isMultipleAudioInputSupported}
          className={styles.popover}
        >
          <VideoDevicesList
            devices={availableDevices}
            currentDeviceId={currentDevices?.deviceId}
            onChange={handleChange}
          />
        </DevicesWrapper>
      )}
    </>
  )
}

export const VideoDevicesWrapper = memo(VideoDevicesWrapperView)
