import { MouseEventHandler, ReactNode, useCallback, useMemo } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'
import capitalize from 'lodash/capitalize'
import { useTranslation } from 'react-i18next'

import { accountIdSelector, useAppSelector } from 'store'
import { useRedirectToUserProfile } from 'App/hooks'
import { stopPropagation } from 'utils'
import { EAvatarSize } from 'enums'
import {
  AccountType,
  TruncatedText,
  Tooltip,
  AvatarTeam,
  TTruncatedTextProps
} from 'App/components'
import { AvatarContainer } from 'App/containers'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as MedicalProvidersIcon } from 'assets/icons/MedicalProviders.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'

import styles from './AccountName.module.scss'
import { AccountNameChip, TAccountNameChipProps } from './AccountNameChip'

export type TAccountNameProps = {
  displayUserName: string

  type?: AccountTypeNames
  hideIcon?: boolean
  truncate?: boolean
  showAvatar?: boolean
  classes?: Partial<Record<TAccountNameClasses, string>>
  size?: EAvatarSize
  firstName?: string
  lastName?: string
  onClick?: () => void
  userId?: string
  children?: ReactNode
  disabled?: boolean
  link?: boolean
  online?: boolean
  autoOnline?: boolean
  shouldRedirectToUserProfile?: boolean
  isCoworker?: boolean
  isContact?: boolean
  hideAccountType?: boolean
  chipList?: TAccountNameChipProps[]
  invitationEmail?: string | null
  variant?: 'account' | 'team'
  isYourAccountIndicator?: 'name' | 'caption'
  truncateRows?: TTruncatedTextProps['rows']
}

export type TAccountNameClasses =
  | 'root'
  | 'title'
  | 'avatarWrapper'
  | 'icon'
  | 'content'
  | 'chipList'
  | 'name'
  | 'caption'

export const AccountName = ({
  displayUserName,
  classes,
  hideIcon,
  onClick,
  userId,
  firstName,
  lastName,
  children,
  disabled,
  chipList,
  invitationEmail,
  truncateRows,
  type = AccountTypeNames.PROFESSIONAL,
  truncate = true,
  size = EAvatarSize.MD,
  isContact = false,
  isCoworker = false,
  link = false,
  showAvatar = false,
  online = false,
  autoOnline = false,
  shouldRedirectToUserProfile = false,
  hideAccountType = false,
  variant = 'account',
  isYourAccountIndicator
}: TAccountNameProps) => {
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const isYourAccount = accountId === userId

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!shouldRedirectToUserProfile && onClick) {
        onClick()
        return
      }

      if (shouldRedirectToUserProfile) {
        stopPropagation(event)
        redirectToUserProfile(userId, type)()
        return
      }
    },
    [onClick, redirectToUserProfile, shouldRedirectToUserProfile, type, userId]
  )

  const getInvitationIcon = () => {
    if (invitationEmail === undefined) {
      return null
    }

    if (invitationEmail === null) {
      return <LinkChainIcon />
    }

    return <EmailIcon />
  }

  const icon = !hideIcon ? (
    <div
      className={cls(
        styles.icon,
        { [styles.iconInline]: !truncate, [styles.iconDisabled]: disabled },
        classes?.icon
      )}
    >
      {!hideAccountType && (
        <Tooltip overlay={capitalize(t(`enum.accountTypeNames.${type}`))}>
          <div className={styles.accountType}>
            <AccountType type={type} />
          </div>
        </Tooltip>
      )}
      {isCoworker && (
        <Tooltip title={t('common.accountName.tooltip.coworker')}>
          <AccountGroupIcon />
        </Tooltip>
      )}
      {isContact && (
        <Tooltip title={t('common.accountName.tooltip.contact')}>
          <MedicalProvidersIcon />
        </Tooltip>
      )}
      {getInvitationIcon()}
    </div>
  ) : null

  const avatar = showAvatar && (
    <div className={cls(styles.avatar, { [styles.avatarDisabled]: disabled })}>
      {variant === 'account' ? (
        <AvatarContainer
          type={type}
          hasTooltip={false}
          displayUserName={displayUserName}
          size={size}
          firstName={firstName || ''}
          lastName={lastName || ''}
          userId={userId || ''}
          online={autoOnline && !online ? undefined : online}
        />
      ) : (
        <AvatarTeam size={size} border={true} />
      )}
    </div>
  )

  const selfIndicatorText = t('common.accountName.you')
  const selfIndicator = isYourAccountIndicator === 'name' && isYourAccount && (
    <>({selfIndicatorText})</>
  )
  const captionIndicator = isYourAccountIndicator === 'caption' && isYourAccount && (
    <span>({capitalize(selfIndicatorText)})</span>
  )

  const captionContent = (
    <>
      {children}
      {captionIndicator}
    </>
  )
  const caption = (children || captionIndicator) && (
    <span className={cls(styles.caption, classes?.caption)}>{captionContent}</span>
  )
  const filteredChipList = useMemo(() => chipList?.filter(({ hidden }) => !hidden), [chipList])

  return (
    <div
      className={cls(styles.root, !truncate && styles.rootWrap, classes?.root, {
        [styles.md]: size === EAvatarSize.MD,
        [styles.rootDisabled]: disabled,
        [styles.clickable]: shouldRedirectToUserProfile || Boolean(onClick)
      })}
      onClick={handleClick}
    >
      {truncate ? (
        <>
          {avatar}
          <div className={styles.contentWrapper}>
            <div className={cls(styles.content, classes?.content)}>
              <div
                className={cls(styles.name, classes?.name, {
                  [styles.twoLine]: truncateRows === 'two'
                })}
              >
                <TruncatedText
                  text={displayUserName}
                  classes={{ text: cls(classes?.title, { [styles.link]: link }) }}
                  rows={truncateRows}
                />
                {selfIndicator && <span className={styles.postfix}>{selfIndicator}</span>} {icon}
              </div>
              {!!filteredChipList?.length && (
                <div className={cls(styles.chipList, classes?.chipList)}>
                  {filteredChipList?.map((item, index) => (
                    <AccountNameChip key={index} {...item} />
                  ))}
                </div>
              )}
            </div>
            {caption}
          </div>
        </>
      ) : (
        <div className={cls(styles.avatarWrapper, classes?.avatarWrapper)}>
          {avatar}
          <div className={styles.contentWrapper}>
            <div className={cls(styles.content, link && styles.link, classes?.content)}>
              <div className={cls(styles.name, classes?.name)}>
                {displayUserName}
                {icon}
              </div>
              {!!filteredChipList?.length && (
                <div className={cls(styles.chipList, classes?.chipList)}>
                  {filteredChipList?.map((item, index) => (
                    <AccountNameChip key={index} {...item} />
                  ))}
                </div>
              )}
            </div>
            {caption}
          </div>
        </div>
      )}
    </div>
  )
}
