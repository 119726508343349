import { EFileUploadStatus } from 'enums'
import { State } from 'redux/rootReducer'
import { ATTACH_SELECTED_CHAT_MED_CLOUD_FILES } from 'store'

export const isUploadingSelector = (state: State) =>
  Boolean([...state.medCloud.upload.fileQueue, ...state.medCloud.upload.filesInProgress].length)

export const getAttachFilesSelectedIdsSelector = (state: State) =>
  state.medCloud.attachFiles.selectedFiles
export const getAttachFilesListSelector = (state: State) => state.medCloud.attachFiles.list

export const getAttachFilesErrorSelector = (state: State) =>
  state.errors[ATTACH_SELECTED_CHAT_MED_CLOUD_FILES]
export const getAttachFilesTotalSelector = (state: State) => state.medCloud.attachFiles.total
export const getAttachFilesSearchSelector = (state: State) => state.medCloud.filters.search
export const getAttachFilesIdsSelector = (state: State) => state.medCloud.attachFiles.ids
export const getAttachFilesUploadingFilesSelector = (state: State) =>
  state.medCloud.attachFiles.uploadingFiles

export const getAttachFilesAlreadyFilesSelector = (chatId?: string) => (state: State) => {
  let fileIds: string[] = state.medCloud.attachFiles.selectedFiles
  const list = state.medCloud.widget.list

  // do not attach files that are still uploading

  fileIds = fileIds.filter((id) => {
    if (!list[id]) {
      return true
    }

    const isSelected = list[id].selected
    const isCaseIdSame = list[id].chatId === chatId
    const isUploading = list[id].uploadStatus !== EFileUploadStatus.SUCCESS

    return !(isSelected && isCaseIdSame && isUploading)
  })

  return fileIds
}
