import { API, api, APIData } from '../services/api'
import { TAutoPaymentDTO, ITopUpOneTimeCheckoutInfoDTO } from '../interfaces'

export type TTopUpMonthlyPayload = {
  socketSessionId: string
}

export const topUpMonthly = async (payload: TTopUpMonthlyPayload) => {
  const { data }: APIData<ITopUpOneTimeCheckoutInfoDTO> = await api.post(
    API.TOP_UP_MONTHLY,
    payload
  )

  return data
}

export const getAutoPayment = async () => {
  const { data }: APIData<TAutoPaymentDTO> = await api.get(API.TOP_UP_MONTHLY)

  return data
}

export const cancelAutoPayment = async () => {
  await api.delete(API.TOP_UP_MONTHLY)
}
