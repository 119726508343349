import { useEffect } from 'react'
import { Row } from 'antd'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { ErrorCodesEnum } from '@medentee/enums'

import { useIsMutating, useIsFetching } from 'services/query'
import { TResponseError, parseError, isMatchErrorCode, validation } from 'utils'
import { RECOMMENDED_BROADCAST_TOOL_LINK, MAX_LENGTH_150 } from 'globalConstants'
import { EAvatarSize } from 'enums'
import {
  AccountsList,
  Button,
  StepsFooterActions,
  TStepsFooterActionsProps,
  TextField,
  Help,
  Switch
} from 'App/components/common'
import { useRefValue, useToggle } from 'App/hooks'
import { setModalTitleAction, useAppDispatch, useAppSelector } from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'
import { ReactComponent as MedicalProvidersIcon } from 'assets/icons/MedicalProviders.svg'

import {
  CREATE_BROADCAST_MUTATION_KEY,
  UPDATE_BROADCAST_MUTATION_KEY
} from '../useBroadcastCreateDialogRequests'
import { GET_BROADCAST_MEMBERS_QUERY_KEY } from '../useBroadcastMembersRequest'
import { ErrorModal } from '../../../ErrorModal'

import styles from './CreateBroadcastMainStep.module.scss'

export type TCreateBroadcastMainStepProps = Pick<TStepsFooterActionsProps, 'onSubmit'> & {
  error: TResponseError
  selectedAccounts: TAccountsSelectOptionData[]
  isAccountFieldDirty: boolean
  onOpenMembersStep: () => void

  isEdit?: boolean
}

export const CreateBroadcastMainStep = ({
  selectedAccounts,
  error,
  isEdit,
  isAccountFieldDirty,
  onSubmit,
  onOpenMembersStep
}: TCreateBroadcastMainStepProps) => {
  const dispatch = useAppDispatch()

  const modalTitle = useAppSelector((state) => state.modal.modalTitle)

  const formState = useFormState()

  const { value: shouldConfirm, toggle } = useToggle()

  const submitLoading = Boolean(
    useIsMutating({
      mutationKey: [isEdit ? UPDATE_BROADCAST_MUTATION_KEY : CREATE_BROADCAST_MUTATION_KEY]
    })
  )
  const membersFetching = Boolean(useIsFetching({ queryKey: [GET_BROADCAST_MEMBERS_QUERY_KEY] }))

  const { t } = useTranslation()

  const isFieldError = isMatchErrorCode(error, ErrorCodesEnum.INCORRECT_URL_FOR_LIVE_EVENT)

  const { setValue: setRootModalTitle, getValue: getRootModalTitle } = useRefValue('')

  const submitDisabled =
    (!isAccountFieldDirty && !formState.dirty) ||
    formState.invalid ||
    (!formState.modifiedSinceLastSubmit && isFieldError) ||
    membersFetching

  useEffect(() => {
    if (shouldConfirm) {
      dispatch(
        setModalTitleAction({ modalTitle: t('modal.createBroadcast.mainStep.title_public') })
      )
    } else if (getRootModalTitle()) {
      dispatch(setModalTitleAction({ modalTitle: getRootModalTitle() }))
    }
  }, [dispatch, shouldConfirm, getRootModalTitle, t])

  useEffect(() => {
    if (modalTitle && !getRootModalTitle()) {
      setRootModalTitle(modalTitle)
    }
  }, [modalTitle, getRootModalTitle, setRootModalTitle])

  return (
    <>
      {!shouldConfirm && (
        <p className={styles.description}>{t('modal.createBroadcast.mainStep.description')}</p>
      )}

      {shouldConfirm && (
        <div>
          <p className={styles.text}>{t('modal.createBroadcast.mainStep.publicAlert')}</p>
          <StepsFooterActions
            submitLabel={
              isEdit
                ? t('modal.createBroadcast.mainStep.submitButton_edit')
                : t('modal.createBroadcast.mainStep.submitButton_create')
            }
            onSubmit={onSubmit}
            onClickBack={toggle}
            submitLoading={submitLoading}
            submitDisabled={submitDisabled}
          />
          <ErrorModal error={!formState.modifiedSinceLastSubmit ? error : undefined} />
        </div>
      )}
      {/* hide fields to preserve form modifiedSinceLastSubmit value */}
      <div style={{ display: shouldConfirm ? 'none' : undefined }}>
        <Field
          name="name"
          validate={validation.composeValidators(
            validation.required(),
            validation.maxLength(MAX_LENGTH_150)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              classes={{ wrapper: styles.field }}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              valueLengthMax={MAX_LENGTH_150}
              topLabel={t('modal.createBroadcast.mainStep.nameField')}
              autoFocus={true}
            />
          )}
        </Field>
        <Field
          name="url"
          validate={validation.composeValidators(validation.required(), validation.isUrl())}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              classes={{ wrapper: styles.field }}
              invalid={
                (meta.touched && meta.invalid) || (!meta.modifiedSinceLastSubmit && isFieldError)
              }
              error={!meta.modifiedSinceLastSubmit && isFieldError ? parseError(error) : meta.error}
              topLabel={t('modal.createBroadcast.mainStep.urlField')}
            />
          )}
        </Field>
        <Field name="isPublic">
          {({ input: { value, onChange } }) => (
            <div className={styles.row}>
              <span>
                {t('modal.createBroadcast.mainStep.publicField')}
                <Help iconVariant="info" content={t('modal.createBroadcast.mainStep.publicHelp')} />
              </span>
              <Switch checked={value} onChange={(checked) => onChange(checked)} />
            </div>
          )}
        </Field>
      </div>
      {!formState.values.isPublic && (
        <Field name="accounts">
          {() => (
            <div className={styles.members}>
              <p className={styles.label}>{t('modal.createBroadcast.mainStep.membersLabel')}</p>

              <Row align="bottom" justify="space-between">
                <Button
                  fontWeight="bold"
                  disabled={submitLoading || membersFetching}
                  variant="inline"
                  icon={<MedicalProvidersIcon />}
                  onClick={onOpenMembersStep}
                >
                  {t('modal.createBroadcast.mainStep.selectMembersButton')}
                </Button>

                <AccountsList
                  accounts={selectedAccounts}
                  avatarSize={EAvatarSize.XS}
                  getTitle={(number) =>
                    t('modal.createBroadcast.mainStep.selectedMembersPopover.title', { number })
                  }
                />
              </Row>
            </div>
          )}
        </Field>
      )}

      {!shouldConfirm && (
        <>
          <StepsFooterActions
            submitLabel={
              isEdit
                ? t('modal.createBroadcast.mainStep.submitButton_edit')
                : t('modal.createBroadcast.mainStep.submitButton_create')
            }
            onSubmit={
              !formState.initialValues.isPublic && formState?.values.isPublic ? toggle : onSubmit
            }
            submitLoading={submitLoading}
            submitDisabled={submitDisabled}
          />
          <Row align="middle" justify="center" className={styles.recommend}>
            <a
              className={styles.recommendLink}
              rel="noopener noreferrer"
              target="_blank"
              href={RECOMMENDED_BROADCAST_TOOL_LINK}
            >
              <CheckCircleOutlineIcon className={styles.recommendIcon} />
              <span>{t('modal.createBroadcast.mainStep.recommendedToolsLink')}</span>
            </a>
          </Row>
        </>
      )}
    </>
  )
}
