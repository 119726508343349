import { useState, useCallback } from 'react'

export const useProgress = <
  T extends Pick<ProgressEvent<EventTarget>, 'lengthComputable' | 'loaded' | 'total'>
>() => {
  const [progress, setProgress] = useState<number>(0)

  const handleOnProgress = useCallback(
    (e: T) => {
      if (e.lengthComputable) {
        const percentComplete = parseFloat(((e.loaded * 100) / e.total).toFixed(2))

        setProgress(percentComplete)
      }
    },
    [setProgress]
  )

  return { progress, setProgress, onProgress: handleOnProgress } as const
}
