import { useTranslation } from 'react-i18next'

import { InvitationItemSent, TInvitationItemSentProps } from 'App/components'
import {
  createLoadingSelector,
  createProcessingBySelector,
  GET_INVITATIONS_NEW_USERS,
  REMOVE_NEW_USERS_INVITE,
  removeNewUsersInviteRequest,
  TRemoveNewUsersInviteSource,
  useAppDispatch,
  useAppSelector,
  WITHDRAW_NEW_USERS_INVITE,
  withdrawNewUsersInviteRequest
} from 'store'

export type TInvitationItemSentNewUsersContainerProps = Pick<
  TInvitationItemSentProps,
  'contactTypeName'
> & {
  id: string
  source: TRemoveNewUsersInviteSource
}

export const InvitationItemSentNewUsersContainer = ({
  source,
  id,
  contactTypeName
}: TInvitationItemSentNewUsersContainerProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { createdAt, email, createdAccount, status, token, message } = useAppSelector(
    (state) => state.invitations.sent.newUsers.list[id] ?? {}
  )

  const loading = useAppSelector(createLoadingSelector([GET_INVITATIONS_NEW_USERS]))
  const removeProcessing = useAppSelector(
    createProcessingBySelector([WITHDRAW_NEW_USERS_INVITE])(token)
  )
  const withdrawProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_NEW_USERS_INVITE])(token)
  )

  const handleRemove = () => {
    dispatch(
      removeNewUsersInviteRequest({
        source,
        token,
        processingId: token,
        contactTypeName
      })
    )
  }

  const handleWithdraw = () => {
    dispatch(
      withdrawNewUsersInviteRequest({
        source,
        token,
        processingId: token,
        contactTypeName
      })
    )
  }

  return (
    <InvitationItemSent
      variant="external"
      status={status}
      statusLabel={t(`enum.inviteStatusEnum.${status}`)}
      loading={loading}
      removeProcessing={removeProcessing}
      withdrawProcessing={withdrawProcessing}
      createdAccount={createdAccount}
      email={email}
      message={message}
      contactTypeName={contactTypeName}
      createdAt={createdAt}
      invitationEmail={email}
      onRemove={handleRemove}
      onWithdraw={handleWithdraw}
    />
  )
}
