import { FileHistorySourcesEnum } from '@medentee/enums'

import { TAccount } from 'store'
import { ICasesCloudPermission } from 'interfaces'
import { TFileDetailsIdPayload } from 'store/files/myFiles'
import {
  FileInformationTitle,
  FileInformationPanel,
  CaseCloudFileDetailsMember,
  Drawer
} from 'App/components'

import styles from './FileInformationPanelDrawer.module.scss'

export type TFileInformationPanelDrawerProps = {
  id: string
  extension: string
  fileName: string
  fileSize: string
  createdAt: string
  updatedAt: string
  accountId: string
  casesPermissions: ICasesCloudPermission[]
  p2pPermissions: ICasesCloudPermission[]

  owner?: TAccount | null
  sharedAt?: string
  sources?: FileHistorySourcesEnum[]
  actorAccountIds?: string[]
  onCloseFileInfoPanel?: (props: TFileDetailsIdPayload) => any
  withoutPermissions?: boolean
}

export const FileInformationPanelDrawer = ({
  id,
  extension,
  fileName,
  fileSize,
  createdAt,
  updatedAt,
  onCloseFileInfoPanel,
  sources,
  actorAccountIds,
  owner,
  accountId,
  casesPermissions,
  sharedAt,
  p2pPermissions,
  withoutPermissions
}: TFileInformationPanelDrawerProps) => {
  const onClose = () => onCloseFileInfoPanel && onCloseFileInfoPanel({ recordId: null })

  return id ? (
    <Drawer
      className={styles.root}
      title={<FileInformationTitle extension={extension} fileName={fileName} />}
      visible={true}
      onClose={onClose}
    >
      {owner?.id === accountId ? (
        <FileInformationPanel
          id={id}
          fileSize={fileSize}
          createdAt={createdAt}
          updatedAt={updatedAt}
          sources={sources}
          withoutPermissions={withoutPermissions}
          actorAccountIds={actorAccountIds}
        />
      ) : (
        <CaseCloudFileDetailsMember
          owner={owner}
          fileDetails={{
            extension,
            sharedAt,
            casesPermissions,
            p2pPermissions,
            fileName,
            fileSize
          }}
          noTitle={true}
          classes={{
            root: styles.fileDetailsRoot
          }}
        />
      )}
    </Drawer>
  ) : null
}
