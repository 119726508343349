import { useTranslation } from 'react-i18next'

import { Button } from 'App/components/common'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { useAdaptiveLayout } from 'App/hooks'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

export type TUserProfileContactInviteProps = {
  accountId: string
  onSuccess: () => void
}

export const UserProfileContactInvite = ({
  accountId,
  onSuccess
}: TUserProfileContactInviteProps) => {
  const { t } = useTranslation()

  const { inviteToContacts } = useContactInvitation()

  const { isDesktop } = useAdaptiveLayout()

  const handleClick = () => {
    inviteToContacts({ accountId, onSuccess })
  }

  return (
    <Button
      size="sm"
      fontWeight="bold"
      variant={isDesktop ? 'primary' : 'inline'}
      onClick={handleClick}
      icon={<AccountPlusIcon />}
    >
      {t('userProfile.inviteToContactButton')}
    </Button>
  )
}
