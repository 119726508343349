import { generatePath, useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ChatTypeEnum } from '@medentee/enums'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components/common'
import { leaveGroupChat } from 'api'
import { getChatRoomsRequest, hideModalAction, useAppDispatch, useAppSelector } from 'store'
import { CHAT_PATH, EChatTypeMap } from 'globalConstants'

type TDeleteChatConfirmProps = {
  chatId: string
}

export const DeleteChatConfirm = ({ chatId }: TDeleteChatConfirmProps) => {
  const dispatch = useAppDispatch()

  const { push } = useHistory()

  const { t } = useTranslation()

  const { page, showBy } = useAppSelector((state) => state.chat.chatRooms.filters)

  const { mutate: deleteChat, isLoading } = useMutation({
    mutationKey: ['delete-group-chat'],
    mutationFn: leaveGroupChat,
    onSuccess: () => {
      push(generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE }))
      dispatch(hideModalAction())
      dispatch(getChatRoomsRequest({ chatType: [ChatTypeEnum.DIALOGUE], page, showBy }))
    }
  })

  const handleClick = () => {
    deleteChat(chatId)
  }

  return (
    <ConfirmationModal
      content={t('modal.deleteGroupChatConfirm.content')}
      controls={
        <Button loading={isLoading} disabled={isLoading} onClick={handleClick}>
          {t('modal.deleteGroupChatConfirm.submitButton')}
        </Button>
      }
    />
  )
}
