import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  joinToCallRequest,
  JOIN_TO_CALL,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Button } from 'App/components'

export type TJoinProps = {
  callId: string
}

const processingSelector = createProcessingBySelector([JOIN_TO_CALL])

export const Join = ({ callId }: TJoinProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector(callId))

  const handleJoinToMeeting = useCallback(() => {
    if (callId) {
      dispatch(
        joinToCallRequest({
          callId,
          processingId: callId
        })
      )
    }
  }, [callId, dispatch])

  return (
    <Button loading={processing} size="sm" onClick={handleJoinToMeeting}>
      {t('chat.message.meeting.joinButton')}
    </Button>
  )
}
