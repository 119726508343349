import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Steps, StepsFooterActions } from 'App/components'
import {
  createProcessingSelector,
  ADD_EDUCATION,
  updateEducationRequest,
  addEducationRequest,
  UPDATE_EDUCATION,
  createProcessingBySelector,
  useAppSelector
} from 'store'
import { serverRangeDate, formatDate } from 'utils/formats'
import { IEducationDTO } from 'interfaces'
import { useSteps } from 'App/hooks'

import {
  UserProfileEducationDialogFirstStep,
  UserProfileEducationDialogSecondStep
} from './components'

const addingProcessingSelector = createProcessingSelector([ADD_EDUCATION])
const updatingProcessingSelector = createProcessingBySelector([UPDATE_EDUCATION])

const DEFAULT_STEPS = [{}, {}]
enum STEPS {
  FIRST,
  SECOND
}

export type TValues = {
  organization: string
  degree?: string | null
  subjectField?: string | null
  date?: [Date, Date] | null
  description?: string | null
}

const initialFormValues: TValues = {
  organization: '',
  degree: '',
  subjectField: '',
  description: ''
}

export type TUserProfileEducationDialogProps = {
  data?: IEducationDTO
}

export const UserProfileEducationDialog: FC<TUserProfileEducationDialogProps> = ({ data }) => {
  const dispatch = useDispatch()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)

  const adding = useAppSelector(addingProcessingSelector)
  const updating = useAppSelector((state) => data && updatingProcessingSelector(data.id)(state))

  const { t } = useTranslation()

  const initialValues = useMemo<TValues>(() => {
    if (data) {
      return {
        ...data,
        date:
          data.startDate && data.endDate ? [new Date(data.startDate), new Date(data.endDate)] : null
      }
    }

    return initialFormValues
  }, [data])

  const submitButtonText = data
    ? t('modal.userEducation.submitButton_save')
    : t('modal.userEducation.submitButton_add')

  const onSubmit = ({ subjectField, degree, organization, date, description }: TValues) => {
    const normalizedData = {
      organization,
      degree: degree || null,
      subjectField: subjectField || null,
      description: description || null,
      ...(date
        ? {
            startDate: formatDate(date[0], serverRangeDate),
            endDate: formatDate(date[1], serverRangeDate)
          }
        : { startDate: null, endDate: null })
    }

    dispatch(
      data
        ? updateEducationRequest({ ...normalizedData, id: data.id, processingId: data.id })
        : addEducationRequest(normalizedData)
    )
  }

  return (
    <Row gutter={[0, 29]}>
      <Col xs={24}>
        <Steps current={step} stepsArray={DEFAULT_STEPS} />
      </Col>
      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <Row gutter={[0, 24]}>
              {step === STEPS.FIRST && <UserProfileEducationDialogFirstStep />}
              {step === STEPS.SECOND && <UserProfileEducationDialogSecondStep />}
              <Col xs={24}>
                <Row>
                  <StepsFooterActions
                    onSubmit={step === STEPS.SECOND ? handleSubmit : setNextStep}
                    submitLoading={data ? updating : adding}
                    submitDisabled={form.getState().invalid}
                    onClickBack={step === STEPS.FIRST ? undefined : setPrevStep}
                    submitLabel={
                      step === STEPS.SECOND
                        ? submitButtonText
                        : t('modal.userEducation.submitButton_next')
                    }
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  )
}
