import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { FileHistoryItem } from 'App/components'

export type TFileHistoryItemContainerProps = ReturnType<typeof mapStateToProps> &
  TFileHistoryItemContainerOwnProps & {
    displayInline?: boolean
    accountId: string
  }

export type TFileHistoryItemContainerOwnProps = {
  dateId: string
  itemId: string
}

const mapStateToProps = (state: State, ownProps: TFileHistoryItemContainerOwnProps) => ({
  historyItem: state.file.fileHistory.groupedList[ownProps.dateId].list[ownProps.itemId],
  accountId: state.global.accountData?.id || ''
})

export const FileHistoryItemContainer = connect(mapStateToProps)(FileHistoryItem)
