import { InputHTMLAttributes, ReactNode, useCallback, useEffect, useRef } from 'react'
import cls from 'classnames'

import styles from './TwoFactorCodeInput.module.scss'

export type TTwoFactorCodeInputProps = InputHTMLAttributes<HTMLInputElement> & {
  index: number
  focus: boolean
  last: boolean

  invalid?: boolean
  separator?: ReactNode
  shouldAutoFocus?: boolean
  shouldShowSeparator?: boolean
}

export const TwoFactorCodeInput = ({
  index,
  shouldAutoFocus,
  shouldShowSeparator,
  separator,
  last,
  focus,
  invalid,
  ...rest
}: TTwoFactorCodeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (shouldAutoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [shouldAutoFocus, inputRef])

  useEffect(() => {
    const { current } = inputRef

    if (current && focus) {
      current.focus()
      current.select()
    }
  }, [focus])

  const renderSeparator = useCallback(() => {
    if (!shouldShowSeparator || last) {
      return
    }

    return (
      <div className={styles.item}>
        {separator ? separator : <div className={styles.divider} />}
      </div>
    )
  }, [last, separator, shouldShowSeparator])

  return (
    <>
      <input
        className={cls(styles.input, styles.item, invalid && styles.invalid)}
        ref={inputRef}
        name={`${index}`}
        maxLength={1}
        tabIndex={index}
        autoComplete="off"
        type="tel"
        {...rest}
      />

      {renderSeparator()}
    </>
  )
}
