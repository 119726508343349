import { useMemo } from 'react'
import { MeetingStatusEnum } from '@medentee/enums'
import { isAfter } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'

import { TMeeting, useAppSelector } from 'store'
import { ActiveCallLabel } from 'App/components/Meetings/common'

import { Duration, Label, Title } from '../ChatMessageMeeting'

import { Join } from './Join'
import styles from './ChatMessageMeetingSystem.module.scss'

export type TChatMessageMeetingSystemProps = TMeeting & {
  isOrganizer: boolean
}

export const ChatMessageMeetingSystem = ({
  startOn,
  endOn,
  title,
  status,
  call
}: TChatMessageMeetingSystemProps) => {
  const { t } = useTranslation()

  const activeCallId = useAppSelector((state) => state.calls.answer.createCall?.id)

  const isOnCurrentCall = call?.id === activeCallId
  const hasMeetingEnded = status === MeetingStatusEnum.EXPIRED
  const hasMeetingStarted =
    status === MeetingStatusEnum.OPEN && isAfter(new Date(), new Date(startOn))

  const inviteTitle = useMemo(
    () =>
      hasMeetingStarted ? (
        <Trans t={t} i18nKey="chat.message.meeting.content_ongoing">
          Meeting <span className={styles.title}>"{{ title }}"</span> has been started
        </Trans>
      ) : (
        <Trans t={t} i18nKey="chat.message.meeting.content_reminder">
          Meeting <span className={styles.title}>"{{ title }}"</span> is starting in less than 10
          min
        </Trans>
      ),
    [title, hasMeetingStarted, t]
  )
  const isJoinAble = !isOnCurrentCall && call?.id

  return (
    <div className={styles.root}>
      <Label
        label={
          hasMeetingEnded
            ? t('chat.message.meeting.title_ended')
            : t('chat.message.meeting.title_available')
        }
        className={styles.label}
      />
      {!hasMeetingEnded && <Title title={inviteTitle} align={isJoinAble ? 'center' : undefined} />}
      <Duration startOn={startOn} endOn={endOn} gutter={!hasMeetingEnded} ascendant />
      {!hasMeetingEnded && (
        <>
          {isJoinAble && <Join callId={call.id} />}

          <ActiveCallLabel className={styles.active} isOnCurrentCall={isOnCurrentCall} />
        </>
      )}
    </div>
  )
}
