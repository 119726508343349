import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { UnityHubChannelMembers } from 'App/components/UnityHub/UnityHubChannelMembers'

import { getCommunityMembers } from '../../../../api'

type TCommunityChannelMembersProps = {
  channelId: string
  communityId: string
  setTabMembersTotal: (total: number) => void
}

export const CommunityChannelMembers = ({
  channelId,
  communityId,
  setTabMembersTotal
}: TCommunityChannelMembersProps) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['get-community-channel-members', communityId, channelId, page],
    queryFn: () => getCommunityMembers({ communityId, chatId: channelId, page }),
    cacheTime: 0
  })

  return (
    <UnityHubChannelMembers
      channelId={channelId}
      data={data}
      page={page}
      loading={isLoading}
      alert={t('communities.members.alert')}
      setTabMembersTotal={setTabMembersTotal}
      setPage={setPage}
      loadData={refetch}
    />
  )
}
