import { useCallback, useState } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { selectChatIdsByType, useAppSelector } from 'store'
import { UnityHubRoomsNavCollapse, TUnityHubRoomsNavCollapseView } from 'App/components/UnityHub'
import { TChannelVariant } from 'interfaces'
import { usePinnedChannels } from 'App/components/Organizations/hooks'

import { CommunityChannelRoom } from '../CommunityChannelRoom'
import { CommunityCreateChannelButton } from '../../CommunityCreateChannels'
import { useKebabMenu } from '../useKebabMenu'

export type TCommunityRoomsNavTextListProps = {
  variant: Extract<TChannelVariant, 'chat' | 'news'>
  communityId: string
}

export const CommunityRoomsNavTextList = ({
  variant,
  communityId
}: TCommunityRoomsNavTextListProps) => {
  const { t } = useTranslation()

  const [isCollapsed, setIsCollapsed] = useState(false)

  const chatType = variant === 'news' ? ChatTypeEnum.COMMUNITY_NEWS : ChatTypeEnum.COMMUNITY_CHANNEL

  const ids = useAppSelector(selectChatIdsByType(chatType))

  const { getMenuItems } = useKebabMenu({ communityId })

  const { pinnedIds } = usePinnedChannels({ ids, entity: 'communities', entityId: communityId })

  const shouldShowPinnedRooms = Boolean(pinnedIds.length) && isCollapsed

  const handleChangeView = useCallback((view: TUnityHubRoomsNavCollapseView) => {
    setIsCollapsed(view === 'collapsed')
  }, [])

  return (
    <>
      <UnityHubRoomsNavCollapse
        variant={variant}
        title={
          variant === 'news'
            ? t('unityHub.channelsList.newsChannelsLabel')
            : t('unityHub.channelsList.textChannelsLabel')
        }
        localStorageKey={`community-${communityId}`}
        onChangeView={handleChangeView}
        action={<CommunityCreateChannelButton variant={variant} communityId={communityId} />}
      >
        {ids.map((id) => (
          <CommunityChannelRoom
            key={id}
            variant={variant}
            chatRoomId={id}
            communityId={communityId}
            getMenuItems={getMenuItems}
          />
        ))}
      </UnityHubRoomsNavCollapse>

      {shouldShowPinnedRooms && (
        <div>
          {pinnedIds.map((id) => (
            <CommunityChannelRoom
              key={id}
              variant={variant}
              chatRoomId={id}
              communityId={communityId}
              getMenuItems={getMenuItems}
            />
          ))}
        </div>
      )}
    </>
  )
}
