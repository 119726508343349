import { useTranslation } from 'react-i18next'

import { ChatRoomInfoUsersList } from './components/ChatRoomInfoUsersList'
import { Skeleton } from './components/Skeleton'
import { useLoadMembers } from './useLoadMembers'
import styles from './CaseChatMembers.module.scss'

export type TCaseChatMembersProps = {
  caseId?: string
}

export const CaseChatMembers = ({ caseId }: TCaseChatMembersProps) => {
  const { t } = useTranslation()

  const { accountId, offlineParticipants, onlineParticipants, loading, ownerId } = useLoadMembers({
    caseId
  })

  return loading ? (
    <Skeleton />
  ) : (
    <>
      <span className={styles.caption}>
        {t('chat.details.onlineMembersGroup', { number: onlineParticipants.length })}
      </span>
      <ChatRoomInfoUsersList
        ownerId={ownerId}
        data={onlineParticipants}
        accountId={accountId}
        isOnline={true}
      />
      <span className={styles.caption}>
        {t('chat.details.offlineMembersGroup', { number: offlineParticipants.length })}
      </span>
      <ChatRoomInfoUsersList ownerId={ownerId} data={offlineParticipants} accountId={accountId} />
    </>
  )
}
