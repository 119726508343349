import { Popover as OriginalPopover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import cls from 'classnames'

import styles from './Popover.module.scss'

export type TPopoverProps = {
  arrow?: boolean
} & PopoverProps

export const Popover = ({
  children,
  overlayClassName,
  placement = 'top',
  arrow = true,
  autoAdjustOverflow = true,
  destroyTooltipOnHide = true,
  trigger = 'click',
  ...rest
}: TPopoverProps) => (
  <OriginalPopover
    overlayClassName={cls(styles.root, !arrow && styles.hiddenArrow, overlayClassName)}
    placement={placement}
    autoAdjustOverflow={autoAdjustOverflow}
    destroyTooltipOnHide={destroyTooltipOnHide}
    trigger={trigger}
    {...rest}
  >
    {children}
  </OriginalPopover>
)
