import { useCallback, useEffect, useMemo, useState } from 'react'

import { createShowcaseFileName } from 'utils/mentions'

import { TCommentsContainerProps } from './CommentsContainer'
import { TCommentFieldEvent } from './CommentsWrapper'
import { TMenuItemsProps, useKebabMenu } from './useKebabMenu'

export const TAB_TITLE_ID = {
  FOR_ITEM: '0',
  ALL: '1'
}

const BASE_QUERY_KEYS = {
  FOR_ITEM: 'showcases-for-item-comments',
  ALL: 'showcases-all-comments'
}

export type TUseCommentsWrapper = Required<Pick<TCommentsContainerProps, 'showcaseId'>> & {
  fileOrderId: number
  isShowcaseOwner: boolean

  fileId?: string
  anchorId?: string
}

export const useCommentsWrapper = ({
  fileId,
  anchorId,
  fileOrderId,
  showcaseId,
  isShowcaseOwner
}: TUseCommentsWrapper) => {
  const [currentTab, setCurrentTab] = useState<string>(TAB_TITLE_ID.FOR_ITEM)
  const [fieldEvent, setFieldEvent] = useState<TCommentFieldEvent>()
  const [commentEvent, setCommentEvent] = useState<TCommentsContainerProps['commentEvent']>()

  const isAllTab = currentTab === TAB_TITLE_ID.ALL

  const tabList = useMemo(() => {
    const itemName = createShowcaseFileName(fileOrderId + 1)

    return {
      [TAB_TITLE_ID.FOR_ITEM]: <span>{`For ${itemName}`}</span>,
      [TAB_TITLE_ID.ALL]: <span>All comments</span>
    }
  }, [fileOrderId])

  const dropDownList = useMemo(() => Object.values(tabList), [tabList])

  const queryKeys = useMemo(
    () => ({
      [TAB_TITLE_ID.FOR_ITEM]: [BASE_QUERY_KEYS.FOR_ITEM, showcaseId, fileId, anchorId],
      [TAB_TITLE_ID.ALL]: [BASE_QUERY_KEYS.ALL, showcaseId]
    }),
    [anchorId, fileId, showcaseId]
  )

  const handleCloseEvent = useCallback(() => {
    setFieldEvent(undefined)
  }, [])

  const handleDeleteCommentClose = useCallback(() => {
    setCommentEvent(undefined)
  }, [])

  const handleEdit = useCallback(
    ({ text, id, mentions }: TMenuItemsProps) => {
      setFieldEvent({
        type: 'edit',
        content: text,
        mentions: mentions?.accounts,
        commentId: id,
        close: handleCloseEvent
      })
    },
    [handleCloseEvent]
  )

  const handleReply = useCallback(
    ({ id, author }: TMenuItemsProps) => {
      setFieldEvent({
        type: 'reply',
        commentId: id,
        author,
        close: handleCloseEvent
      })
    },
    [handleCloseEvent]
  )

  const handleDelete = useCallback(
    (commentId: string) => {
      setCommentEvent({
        type: 'delete',
        commentId,
        close: handleDeleteCommentClose
      })
    },
    [handleDeleteCommentClose]
  )

  const { getMenuItems } = useKebabMenu({
    handleEdit,
    handleDelete,
    handleReply,
    isAllTab,
    isShowcaseOwner
  })

  useEffect(() => {
    if (isAllTab && fieldEvent?.type) {
      setFieldEvent(undefined)
    }
  }, [fieldEvent?.type, isAllTab])

  useEffect(() => {
    setFieldEvent(undefined)

    // Do not remove fileId
  }, [fileId])

  return {
    isAllTab,
    queryKeys,
    dropDownList,
    tabList,
    currentTab,
    fieldEvent,
    commentEvent,
    setFieldEvent,
    setCurrentTab,
    setCommentEvent,
    getMenuItems
  }
}
