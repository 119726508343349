import { useState } from 'react'
import { Table, Skeleton } from 'antd'
import cls from 'classnames'
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface'
import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { formatShortDate } from 'utils/formats'
import { VIDEO_WHITELIST } from 'globalConstants'
import { getExtensionWithoutDot } from 'utils/files'
import { createSlides, FILE_LIST_DEFAULT_PAGINATION, TIds } from 'store'
import { formatBytes } from 'utils'
import { TFileListContainerProps, VideoReadinessStatus } from 'App/containers'
import {
  ContextMenu,
  FileName,
  FileViewToggle,
  TableTitle,
  TFileViewToggleProps,
  VideoStatusContextWrapper
} from 'App/components'
import { useAdaptiveLayout, useListContextMenu } from 'App/hooks'

import { TFileTableProps } from '../FileList'
import { TUseSelection, TableRow, HeadContextMenu, THeadContextMenuProps } from '../../common'
import { TUseKebabMenu } from '../useKebabMenu'

import styles from './TableView.module.scss'

export type TTableViewProps = Pick<TFileListContainerProps, 'sorting'> &
  Omit<TUseSelection, 'setSelectMode'> &
  TUseKebabMenu &
  Pick<THeadContextMenuProps, 'filesSize'> & {
    dataSource: TFileTableProps[]
    view: TFileViewToggleProps['variant']
    onViewChange: TFileViewToggleProps['onChange']
    onSorting: (key: string) => void

    loading?: boolean
  }

const SKELETON_ROWS = FILE_LIST_DEFAULT_PAGINATION.showBy

export const TableView = ({
  dataSource,
  selectedIds,
  loading,
  view,
  sorting,
  selectMode,
  filesSize,
  setSelectedIds,
  onViewChange,
  onSorting,
  onSelect,
  getMenuItems
}: TTableViewProps) => {
  const [visible, setVisible] = useState<string | null>(null)

  const { isDesktop } = useAdaptiveLayout()

  const { shouldShowContextMenu, shouldShowHeadContextmenu } = useListContextMenu(selectedIds)

  const { t } = useTranslation()

  const COLUMNS: ColumnsType<TFileTableProps> = [
    {
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: true,
      className: styles.fileNameWrapper,
      render: (fileName: string, record: TFileTableProps) => (
        <div className={cls(styles.sortable, styles.fileName)}>
          <FileName
            twoLine
            extension={record.extension}
            fileName={fileName}
            fileNameDisabled={record.isUnopenable}
          />
          {isDesktop && (
            <VideoReadinessStatus className={styles.videoStatus} fileId={record.fileId} size="sm" />
          )}
        </div>
      ),
      title: () => (
        <HeadContextMenu
          menuItems={getMenuItems(selectedIds)}
          visible={shouldShowHeadContextmenu}
          total={selectedIds.length}
          filesSize={filesSize}
        >
          <TableTitle
            title={t('files.columns.name')}
            sortId="fileName"
            sorting={sorting}
            sorter={true}
          />
        </HeadContextMenu>
      ),
      onHeaderCell: (column: any) => ({
        onClick: () => onSorting(column.key)
      })
    },
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      className: styles.updatedAtWrapper,
      render: (createdAt: string | Date) => (
        <div className={styles.sortable}>{formatShortDate(createdAt)}</div>
      ),
      title: () => (
        <TableTitle
          title={t('files.columns.uploaded')}
          sorting={sorting}
          sortId="createdAt"
          sorter={true}
        />
      ),
      onHeaderCell: (column: any) => ({
        onClick: () => onSorting(column.key)
      })
    },
    {
      dataIndex: 'fileSize',
      key: 'fileSize',
      sorter: true,
      className: styles.fileSizeWrapper,
      render: (fileSize: string) => <div className={styles.sortable}>{formatBytes(fileSize)}</div>,
      title: () => (
        <TableTitle
          title={t('files.columns.fileSize')}
          sorting={sorting}
          sortId="fileSize"
          sorter={true}
        />
      ),
      onHeaderCell: (column: any) => ({
        onClick: () => onSorting(column.key)
      })
    },
    {
      title: <FileViewToggle variant={view} onChange={onViewChange} />,
      dataIndex: 'actions',
      key: 'actions',
      className: styles.kebabMenuWrapper,
      render: (key: string, record: TFileTableProps) =>
        shouldShowContextMenu(record.fileId) && (
          <ContextMenu
            key={key}
            menuItems={getMenuItems(record)}
            visible={record.fileId === visible}
            endAdornment={
              VIDEO_WHITELIST.includes(getExtensionWithoutDot(record.extension)) &&
              !isDesktop && <VideoStatusContextWrapper fileId={record.fileId} />
            }
            classes={{ icon: styles.contextMenuIcon }}
          />
        )
    }
  ]

  const rowSelection: TableRowSelection<TFileTableProps> = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowIds) => setSelectedIds(selectedRowIds as TIds),
    preserveSelectedRowKeys: true
  }

  return (
    <Skeleton
      loading={loading}
      active={true}
      title={{ width: '100%' }}
      paragraph={{ rows: SKELETON_ROWS }}
    >
      <Table
        columns={COLUMNS}
        dataSource={dataSource}
        loading={loading}
        rowSelection={rowSelection}
        pagination={false}
        showSorterTooltip={false}
        tableLayout="fixed"
        onRow={({ fileId, isUnopenable, slidesEntity }) =>
          TableRow({
            file: { id: fileId },
            openHandlerProps: {
              slides: createSlides(slidesEntity, FileHistorySourcesEnum.MED_CLOUD)
            },
            selectMode,
            disableOpen: isUnopenable,
            onSelect,
            setVisible
          })
        }
      />
    </Skeleton>
  )
}
