import { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import { CallTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { isMobileBrowser } from 'utils'
import { IJitsiMeetAPI } from 'types'
import { CallStatus, ContextMenu, IconButton, TUseCallCoreOptions } from 'App/components'
import { EModalComponents, TCallOutgoingFooterContainerProps } from 'App/containers'
import { useAdaptiveLayout, useCloseModal } from 'App/hooks'
import { EIconSize } from 'enums'
import { ReactComponent as FullscreenIcon } from 'assets/icons/Fullscreen.svg'
import { ReactComponent as FullscreenExitIcon } from 'assets/icons/FullscreenExit.svg'
import { ReactComponent as ViewAgendaOutlineIcon } from 'assets/icons/ViewAgendaOutline.svg'
import { ReactComponent as ViewGridOutlineIcon } from 'assets/icons/ViewGridOutline.svg'
import { ReactComponent as EndCallIcon } from 'assets/icons/EndCall.svg'
import { ReactComponent as AccountPlusOutlineIcon } from 'assets/icons/AccountPlusOutline.svg'
import { ReactComponent as MicrophoneIcon } from 'assets/icons/Record.svg'
import { ReactComponent as MicrophoneOffIcon } from 'assets/icons/RecordOffFill.svg'
import { ReactComponent as VideoIcon } from 'assets/icons/Video.svg'
import { ReactComponent as VideoOffIcon } from 'assets/icons/VideoOff.svg'
import { ReactComponent as MonitorScreenshotIcon } from 'assets/icons/MonitorScreenshot.svg'
import { ReactComponent as MonitorScreenshotOffIcon } from 'assets/icons/MonitorScreenshotOff.svg'
import { ReactComponent as HandBackRightOutlineIcon } from 'assets/icons/HandBackRightOutline.svg'
import { ReactComponent as HandBackRightOffOutlineIcon } from 'assets/icons/HandBackRightOffOutline.svg'
import { ReactComponent as MediaSkeletonIcon } from 'assets/icons/MediaSkeleton.svg'
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/DotsHorizontal.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'
import { ReactComponent as MessageTextOffIcon } from 'assets/icons/MessageTextOff.svg'

import { useKebabMenu } from './useKebabMenu'
import { VideoDevicesWrapper } from './VideoDevicesWrapper'
import { AudioDevicesWrapper } from './AudioDevicesWrapper'
import { CallOutgoingFooterNotification } from './CallOutgoingFooterNotification'
import styles from './CallOutgoingFooter.module.scss'

type TCameraMicError = {
  type: string // A constant representing the overall type of the error.
  message: string // Additional information about the error.
}

export type TCallOutgoingFooterProps = TCallOutgoingFooterContainerProps &
  Pick<TUseCallCoreOptions, 'type'> & {
    isFullscreen: boolean
    isAnsweredCall: boolean
    onFullscreen: () => void
    onEnd: () => void
    isCallOwner: boolean
    callId: string
    jitsiAPI: IJitsiMeetAPI
    fixed: boolean

    rootRef?: null | RefObject<HTMLElement>
    cancelDraggableClassName?: string
  }

export const CallOutgoingFooter = ({
  isFullscreen,
  isAnsweredCall,
  isCallOwner,
  callId,
  jitsiAPI,
  caseId,
  chatType,
  type,
  startTime,
  cancelDraggableClassName,
  rootRef,
  fixed,
  onFullscreen,
  onEnd,
  showModal
}: TCallOutgoingFooterProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const hideCurrentModal = useCloseModal([
    EModalComponents.CALL_PERFORMANCE_SETTINGS_DIALOG,
    EModalComponents.ADD_TO_GROUP_CALL_DIALOG
  ])

  const isButtonSizeSM = isDesktop && !isFullscreen
  const [isGridView, setGridView] = useState<boolean>(false)
  const [isAudioMute, setAudioMute] = useState<boolean>(false)
  const [isVideoMute, setVideoMute] = useState<boolean>(true)
  const [isAudioDisable, setAudioDisable] = useState<boolean>(true)
  const [isVideoDisable, setVideoDisable] = useState<boolean>(true)
  const [isShareScreen, setShareScreen] = useState<boolean>(false)
  const [isRiseHand, setRiseHand] = useState<boolean>(false)
  const [isVisibleContext, setVisibleContext] = useState<boolean>(false)
  const [isChatOpen, setChatOpen] = useState<boolean>(false)
  const [chatUnreadCount, setChatUnreadCount] = useState<number>(0)

  const shouldShowAddButton = caseId && isCallOwner && chatType === CallTypeEnum.CASE_GROUP
  const shouldShowShareScreen = isDesktop && !(shouldShowAddButton && !isFullscreen)
  const shouldShowChatButton =
    !(shouldShowAddButton && !isFullscreen) &&
    (chatType === CallTypeEnum.CASE_GROUP ||
      chatType === CallTypeEnum.CASE_MEETING ||
      type === 'channel')
  const shouldShowVideoWrapper = !isMobileBrowser()

  const getContainer = (isFullscreen && rootRef?.current) || undefined

  const handleEndCall = useCallback(() => {
    onEnd()

    jitsiAPI.dispose()
    jitsiAPI.executeCommand('hangup')
  }, [jitsiAPI, onEnd])

  const handleAddMembers = () => {
    caseId &&
      showModal({
        modalTitle: t('modal.addGroupCallMember.title_add'),
        modalType: EModalComponents.ADD_TO_GROUP_CALL_DIALOG,
        modalProps: {
          callId,
          caseId
        },
        getContainer
      })
  }

  const handleGridView = () => jitsiAPI.executeCommand('toggleTileView')
  const handleScreenShare = () => jitsiAPI.executeCommand('toggleShareScreen')
  const handleAudio = () => jitsiAPI.executeCommand('toggleAudio')
  const handleVideo = () => jitsiAPI.executeCommand('toggleVideo')
  const handleRiseHand = () => {
    jitsiAPI.executeCommand('toggleRaiseHand')
    setRiseHand((prev) => !prev)
  }
  const handleBackground = () => jitsiAPI.executeCommand('toggleVirtualBackgroundDialog')
  const toggleCamera = () => jitsiAPI.executeCommand('toggleCamera')
  const handlePerformanceSettings = () => {
    showModal({
      modalType: EModalComponents.CALL_PERFORMANCE_SETTINGS_DIALOG,
      modalTitle: t('modal.callPerformanceSettings.title'),
      modalProps: {
        jitsiAPI
      },
      getContainer
    })
  }

  const handleChat = useCallback(() => jitsiAPI.executeCommand('toggleChat'), [jitsiAPI])

  const handleSwitchToChat = () => {
    handleChat()
    onFullscreen()
  }

  const { getMenuItems } = useKebabMenu({
    isVideoDisable,
    isShareScreen,
    isDesktop,
    isMobile,
    isGridView,
    isRiseHand,
    isVideoMute,
    isMobileBrowser: isMobileBrowser(),
    isFullscreen,
    isChatOpen,
    shouldShowChatButton,
    shouldShowChatButtonNotification: Boolean(chatUnreadCount),
    handleChat,
    handleGridView,
    onFullscreen,
    handleRiseHand,
    handleBackground,
    handleScreenShare,
    handlePerformanceSettings,
    toggleCamera
  })

  const menuItems = useMemo(() => getMenuItems(), [getMenuItems])

  useLayoutEffect(() => {
    const setDefaultAvailable = async () => {
      try {
        const isAudioAvailable = await jitsiAPI.isAudioAvailable()
        const isVideoAvailable = await jitsiAPI.isVideoAvailable()

        setAudioDisable(!isAudioAvailable)
        setVideoDisable(!isVideoAvailable)
      } catch (error) {
        console.error(error)
      }
    }

    setDefaultAvailable()
  }, [jitsiAPI])

  useEffect(() => {
    const setDefault = async () => {
      setShareScreen(await jitsiAPI.isSharingScreen())
      setAudioMute(await jitsiAPI.isAudioMuted())
      setVideoMute(await jitsiAPI.isVideoMuted())
    }

    jitsiAPI.addEventListener<{
      enabled: boolean // whether tile view is not displayed or not
    }>('tileViewChanged', ({ enabled }) => setGridView(enabled))

    jitsiAPI.addEventListener<{
      muted: boolean // new muted status - boolean
    }>('audioMuteStatusChanged', ({ muted }) => setAudioMute(muted))

    jitsiAPI.addEventListener<{
      available: boolean // new available status - boolean
    }>('audioAvailabilityChanged', ({ available }) => setAudioDisable(!available))

    jitsiAPI.addEventListener<{
      available: boolean // new available status - boolean
    }>('videoAvailabilityChanged', ({ available }) => setVideoDisable(!available))

    jitsiAPI.addEventListener<{
      muted: boolean // new muted status - boolean
    }>('videoMuteStatusChanged', ({ muted }) => setVideoMute(muted))

    jitsiAPI.addEventListener<TCameraMicError>('micError', (error) => {
      console.error('micError', error)

      setAudioDisable((prev) => !prev)
    })

    jitsiAPI.addEventListener<TCameraMicError>('cameraError', (error) => {
      console.error('cameraError', error)

      setVideoDisable((prev) => !prev)
    })

    jitsiAPI.addEventListener<{
      on: boolean // whether screen sharing is on
      details: {
        // From where the screen sharing is capturing, if known. Values which are
        // passed include 'window', 'screen', 'proxy', 'device'. The value undefined
        // will be passed if the source type is unknown or screen share is off.
        sourceType: string | undefined
      }
    }>('screenSharingStatusChanged', ({ on }) => setShareScreen(on))

    jitsiAPI.addEventListener<{
      error: string // The error which occurred during submission, if any.
    }>('feedbackSubmitted', ({ error }) => {
      console.error('feedbackSubmitted', error)
    })

    jitsiAPI.addEventListener<{
      isOpen: boolean // Whether the chat panel is open or not
      unreadCount: number // The unread messages counter
    }>('chatUpdated', ({ isOpen, unreadCount }) => {
      setChatOpen(isOpen)
      setChatUnreadCount(unreadCount)
    })

    setDefault()
  }, [jitsiAPI])

  useEffect(() => {
    if (fixed && !isFullscreen && isChatOpen) {
      handleChat()
    }
  }, [fixed, isChatOpen, isFullscreen, handleChat])

  useEffect(
    () => () => {
      hideCurrentModal()
    },
    [hideCurrentModal, isFullscreen]
  )

  return (
    <div
      className={cls(styles.root, { [styles.static]: !fixed, [styles.fullscreen]: isFullscreen })}
    >
      {!isMobile && <CallStatus isAnsweredCall={isAnsweredCall} startTime={startTime} />}

      <div className={styles.center}>
        {shouldShowAddButton && (
          <IconButton
            toolTip={t('chat.call.toolbar.addMembers')}
            iconComponent={<AccountPlusOutlineIcon />}
            iconSize={EIconSize.CUSTOM}
            onClick={handleAddMembers}
            classes={{
              root: cls(
                styles.button,
                cancelDraggableClassName,
                isButtonSizeSM && styles.buttonSmall
              )
            }}
          />
        )}
        {shouldShowShareScreen && (
          <IconButton
            toolTip={
              isShareScreen
                ? t('chat.call.toolbar.shareScreen_stop')
                : t('chat.call.toolbar.shareScreen')
            }
            iconComponent={isShareScreen ? <MonitorScreenshotIcon /> : <MonitorScreenshotOffIcon />}
            iconSize={EIconSize.CUSTOM}
            onClick={handleScreenShare}
            classes={{
              root: cls(
                styles.button,
                cancelDraggableClassName,
                isButtonSizeSM && styles.buttonSmall
              )
            }}
          />
        )}
        <IconButton
          toolTip={t('chat.call.toolbar.video')}
          iconComponent={
            <VideoDevicesWrapper
              shouldShowWrapper={shouldShowVideoWrapper}
              isFullscreen={isFullscreen}
              jitsiAPI={jitsiAPI}
            >
              {isVideoMute || isShareScreen ? <VideoOffIcon /> : <VideoIcon />}
            </VideoDevicesWrapper>
          }
          iconSize={EIconSize.CUSTOM}
          onClick={handleVideo}
          disabled={isVideoDisable}
          classes={{
            root: cls(styles.button, cancelDraggableClassName, isButtonSizeSM && styles.buttonSmall)
          }}
        />

        {shouldShowChatButton && fixed && !isFullscreen && (
          <IconButton
            toolTip={t('chat.call.toolbar.chat')}
            iconComponent={<MessageTextIcon />}
            iconSize={EIconSize.CUSTOM}
            onClick={handleSwitchToChat}
            classes={{
              root: cls(
                styles.button,
                cancelDraggableClassName,
                isButtonSizeSM && styles.buttonSmall
              )
            }}
          />
        )}

        <IconButton
          toolTip={t('chat.call.toolbar.microphone')}
          iconComponent={
            <AudioDevicesWrapper isFullscreen={isFullscreen} jitsiAPI={jitsiAPI}>
              {isAudioMute ? <MicrophoneOffIcon /> : <MicrophoneIcon />}
            </AudioDevicesWrapper>
          }
          iconSize={EIconSize.CUSTOM}
          onClick={handleAudio}
          disabled={isAudioDisable}
          classes={{
            root: cls(styles.button, cancelDraggableClassName, isButtonSizeSM && styles.buttonSmall)
          }}
        />

        {(isFullscreen || !fixed) && (
          <>
            {!isMobile && (
              <IconButton
                toolTip={
                  isRiseHand ? t('chat.call.toolbar.hand_lower') : t('chat.call.toolbar.hand')
                }
                iconComponent={
                  isRiseHand ? <HandBackRightOutlineIcon /> : <HandBackRightOffOutlineIcon />
                }
                iconSize={EIconSize.CUSTOM}
                onClick={handleRiseHand}
                classes={{
                  root: cls(
                    styles.button,
                    cancelDraggableClassName,
                    isButtonSizeSM && styles.buttonSmall
                  )
                }}
              />
            )}

            {shouldShowChatButton && isDesktop && (
              <IconButton
                toolTip={t('chat.call.toolbar.chat')}
                iconComponent={
                  <CallOutgoingFooterNotification count={chatUnreadCount}>
                    {isChatOpen ? <MessageTextIcon /> : <MessageTextOffIcon />}
                  </CallOutgoingFooterNotification>
                }
                iconSize={EIconSize.CUSTOM}
                onClick={handleChat}
                classes={{
                  root: cls(
                    styles.button,
                    cancelDraggableClassName,
                    isButtonSizeSM && styles.buttonSmall
                  )
                }}
              />
            )}

            {isDesktop && (
              <IconButton
                toolTip={t('chat.call.toolbar.background')}
                iconComponent={<MediaSkeletonIcon />}
                iconSize={EIconSize.CUSTOM}
                onClick={handleBackground}
                classes={{
                  root: cls(
                    styles.button,
                    cancelDraggableClassName,
                    isButtonSizeSM && styles.buttonSmall
                  )
                }}
              />
            )}

            <IconButton
              iconComponent={
                <CallOutgoingFooterNotification
                  count={chatUnreadCount}
                  shouldHide={isDesktop || !shouldShowChatButton}
                >
                  <ContextMenu
                    visible={isVisibleContext}
                    menuItems={menuItems}
                    onVisibleChange={setVisibleContext}
                    classes={{ icon: styles.contextIcon }}
                    iconComponent={<DotsHorizontalIcon />}
                    align={{ offset: [0, -20] }}
                    placement={isMobile ? 'top' : 'topLeft'}
                  />
                </CallOutgoingFooterNotification>
              }
              iconSize={EIconSize.CUSTOM}
              classes={{
                root: cls(
                  styles.button,
                  cancelDraggableClassName,
                  isButtonSizeSM && styles.buttonSmall
                )
              }}
            />
          </>
        )}
        <IconButton
          toolTip={isCallOwner ? t('chat.call.toolbar.endCall') : t('chat.call.toolbar.leaveCall')}
          iconComponent={<EndCallIcon />}
          iconSize={EIconSize.CUSTOM}
          onClick={handleEndCall}
          classes={{
            root: cls(
              styles.button,
              styles.buttonEnd,
              cancelDraggableClassName,
              isButtonSizeSM && styles.buttonSmall
            )
          }}
        />
      </div>

      {!isMobile && (
        <div className={styles.right}>
          <IconButton
            iconComponent={isGridView ? <ViewAgendaOutlineIcon /> : <ViewGridOutlineIcon />}
            iconSize={EIconSize.CUSTOM}
            onClick={handleGridView}
            classes={{
              root: cls(
                styles.button,
                cancelDraggableClassName,
                isButtonSizeSM && styles.buttonSmall
              )
            }}
          />

          <IconButton
            iconComponent={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            iconSize={EIconSize.CUSTOM}
            onClick={onFullscreen}
            classes={{
              root: cls(
                styles.button,
                cancelDraggableClassName,
                isButtonSizeSM && styles.buttonSmall
              )
            }}
          />
        </div>
      )}
    </div>
  )
}
