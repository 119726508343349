import { memo, Fragment, ReactNode, useCallback, useRef, useState, useEffect } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EHistoryEvents } from 'enums'
import { generateRandomArray } from 'utils'
import { useBidirectionalScroll, useBidirectionalSkeleton } from 'App/hooks/BidirectionalList'
import { EEmptyListIconSize, EmptyList } from 'App/components/common'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

import { Comment, TCommentProps, TEvent } from '../Comment'

import { Snapshot } from './Snapshot'
import styles from './CommentList.module.scss'

export type TComment = Pick<
  TCommentProps,
  'author' | 'createdAt' | 'updatedAt' | 'id' | 'text' | 'mentions'
> & {
  repliesCount: string
}
export type TCommentEvent = PartialBy<TEvent, 'confirmationText'>
export type TCommentListProps = Pick<TCommentProps, 'getMenuItems' | 'event' | 'variant'> &
  PropsWithClasses<
    'root' | 'scrollInner',
    {
      hasAfter: boolean
      hasBefore: boolean
      comments: TComment[]
      onNext: (anchorId: string, historyEvent: EHistoryEvents) => void

      getReplies?: (props: TComment) => React.ReactNode
      commentEvent?: TCommentEvent
      historyEvent?: EHistoryEvents
      loading?: boolean
      itemSkeleton?: ReactNode
      anchorId?: string
      placeholderIcon?: ReactNode
      placeholderText?: string
      showcaseId?: string
      accountId?: string
      initialAnchorId?: string
    }
  >

const CommentListView = ({
  accountId,
  hasAfter,
  hasBefore,
  comments,
  historyEvent,
  commentEvent,
  anchorId,
  initialAnchorId,
  variant,
  classes,
  showcaseId,
  placeholderText,
  getReplies,
  getMenuItems,
  onNext,
  loading = false,
  itemSkeleton = <Skeleton loading active title avatar paragraph={{ rows: 2 }} />,
  placeholderIcon = <ForumIcon />
}: TCommentListProps) => {
  const { t } = useTranslation()

  const commentRefMap = useRef<Map<string, HTMLDivElement | null>>(new Map())

  const [snapshot, setSnapshot] = useState<number>(0)
  const [highlightedCommentId, setHighlightedCommentId] = useState<string | null>(null)

  const firstComment = comments[0] ?? {}
  const lastComment = comments[comments.length - 1] ?? {}

  const { replace } = useHistory()

  const {
    shouldShowSkeleton,
    shouldShowBottomSkeleton,
    shouldShowTopSkeleton,
    shouldShowEmptyScreen
  } = useBidirectionalSkeleton({
    loading,
    dataLength: comments.length,
    hasAfter,
    hasBefore,
    historyEvent
  })

  const getAfterMessages = useCallback(() => {
    if (lastComment.id) {
      onNext(lastComment.id, EHistoryEvents.HISTORY_AFTER)
    }
  }, [lastComment.id, onNext])

  const getBeforeMessages = useCallback(() => {
    if (firstComment.id) {
      onNext(firstComment.id, EHistoryEvents.HISTORY_BEFORE)
    }
  }, [firstComment.id, onNext])

  const { scrollListRef, onScroll, onWheel } = useBidirectionalScroll({
    loading,
    hasAfter,
    hasBefore,
    getAfterMessages,
    getBeforeMessages
  })

  useEffect(() => {
    // Keep the scroll position after loading the data
    if (historyEvent === EHistoryEvents.HISTORY_BEFORE && scrollListRef.current && snapshot) {
      const { scrollHeight } = scrollListRef.current

      scrollListRef.current.scrollTo({
        top:
          scrollHeight - snapshot - (commentRefMap.current.get(firstComment.id)?.clientHeight ?? 0)
      })
    }
  }, [firstComment.id, historyEvent, scrollListRef, snapshot])

  const handleScrollToNewComment = useCallback((id: string) => {
    const element = commentRefMap.current.get(id)

    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'start'
      })

      setHighlightedCommentId(null)
    }
  }, [])

  const handleScrollToTargetComment = useCallback(
    (id: string) => {
      const element = commentRefMap.current.get(id)

      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        })

        setHighlightedCommentId(id)
        showcaseId && replace(`/showcases/p/${showcaseId}`)
      }
    },
    [replace, showcaseId]
  )

  useEffect(() => {
    if (!loading && historyEvent === EHistoryEvents.JUMP) {
      if (initialAnchorId) {
        handleScrollToTargetComment(initialAnchorId)

        return
      }

      if (anchorId) {
        handleScrollToNewComment(anchorId)
      }
    }
  }, [
    anchorId,
    comments,
    handleScrollToNewComment,
    handleScrollToTargetComment,
    historyEvent,
    initialAnchorId,
    loading
  ])

  return (
    <>
      {Boolean(comments.length) && (
        <Snapshot
          listRef={scrollListRef}
          dataLength={comments.length}
          setSnapshot={setSnapshot}
          historyEvent={historyEvent}
        />
      )}
      <div className={cls(styles.root, classes?.root)}>
        <div className={styles.scrollContent}>
          <div
            className={cls(
              styles.scrollInner,
              classes?.scrollInner,
              shouldShowSkeleton && styles.scrollInnerSkeleton
            )}
            onScroll={onScroll}
            onWheel={onWheel}
            ref={scrollListRef}
          >
            {shouldShowTopSkeleton && <>{itemSkeleton}</>}

            {shouldShowSkeleton
              ? generateRandomArray().map((key) => <Fragment key={key}>{itemSkeleton}</Fragment>)
              : comments.map((item) => (
                  <Comment
                    key={item.id}
                    ref={(ref) => commentRefMap.current.set(item.id, ref)}
                    getMenuItems={getMenuItems}
                    event={commentEvent?.commentId === item.id ? commentEvent : undefined}
                    isOwner={accountId === item.author.userId}
                    replies={getReplies ? getReplies(item) : undefined}
                    variant={variant}
                    author={item.author}
                    createdAt={item.createdAt}
                    id={item.id}
                    text={item.text}
                    updatedAt={item.updatedAt}
                    mentions={item.mentions}
                    animateHighlight={highlightedCommentId === item.id}
                  />
                ))}

            {shouldShowBottomSkeleton && <>{itemSkeleton}</>}

            {shouldShowEmptyScreen && (
              <EmptyList
                iconSize={EEmptyListIconSize.MD}
                text={placeholderText ?? t('showcases.item.comments.placeholder')}
                icon={placeholderIcon}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const CommentList = memo(CommentListView)
