export const SET_CASE_AUTO_APPROVE_CONTACTS_SEARCH_ACTION =
  'SET_CASE_AUTO_APPROVE_CONTACTS_SEARCH_ACTION'

export const CASE_AUTO_APPROVE_CONTACTS_CLEAR_ACTION = 'CASE_AUTO_APPROVE_CONTACTS_CLEAR_ACTION'

export const GET_CASE_AUTO_APPROVE_CONTACTS = 'GET_CASE_AUTO_APPROVE_CONTACTS'
export const GET_CASE_AUTO_APPROVE_CONTACTS_REQUEST = 'GET_CASE_AUTO_APPROVE_CONTACTS_REQUEST'
export const GET_CASE_AUTO_APPROVE_CONTACTS_SUCCESS = 'GET_CASE_AUTO_APPROVE_CONTACTS_SUCCESS'
export const GET_CASE_AUTO_APPROVE_CONTACTS_ERROR = 'GET_CASE_AUTO_APPROVE_CONTACTS_ERROR'

export const SET_CASE_AUTO_APPROVE_SETTINGS = 'SET_CASE_AUTO_APPROVE_SETTINGS'
export const SET_CASE_AUTO_APPROVE_SETTINGS_REQUEST = 'SET_CASE_AUTO_APPROVE_SETTINGS_REQUEST'
export const SET_CASE_AUTO_APPROVE_SETTINGS_SUCCESS = 'SET_CASE_AUTO_APPROVE_SETTINGS_SUCCESS'
export const SET_CASE_AUTO_APPROVE_SETTINGS_ERROR = 'SET_CASE_AUTO_APPROVE_SETTINGS_ERROR'

export const SET_2FA_SETTINGS = 'SET_2FA_SETTINGS'
export const SET_2FA_SETTINGS_REQUEST = 'SET_2FA_SETTINGS_REQUEST'
export const SET_2FA_SETTINGS_SUCCESS = 'SET_2FA_SETTINGS_SUCCESS'
export const SET_2FA_SETTINGS_ERROR = 'SET_2FA_SETTINGS_ERROR'

export const CLEAR_2FA_SETTINGS_ACTION = 'CLEAR_2FA_SETTINGS_ACTION'

export const GET_ACCOUNT_SETTINGS = 'GET_ACCOUNT_SETTINGS'
export const GET_ACCOUNT_SETTINGS_REQUEST = 'GET_ACCOUNT_SETTINGS_REQUEST'
export const GET_ACCOUNT_SETTINGS_SUCCESS = 'GET_ACCOUNT_SETTINGS_SUCCESS'
export const GET_ACCOUNT_SETTINGS_ERROR = 'GET_ACCOUNT_SETTINGS_ERROR'

export const SET_DEFAULT_ACCOUNT = 'SET_DEFAULT_ACCOUNT'
export const SET_DEFAULT_ACCOUNT_REQUEST = 'SET_DEFAULT_ACCOUNT_REQUEST'
export const SET_DEFAULT_ACCOUNT_SUCCESS = 'SET_DEFAULT_ACCOUNT_SUCCESS'
export const SET_DEFAULT_ACCOUNT_ERROR = 'SET_DEFAULT_ACCOUNT_ERROR'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

export const CLEAR_CHANGE_PASSWORD_ACTION = 'CLEAR_CHANGE_PASSWORD_ACTION'

export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR'

export const CLEAR_CHANGE_EMAIL_ACTION = 'CLEAR_CHANGE_EMAIL_ACTION'

export const TOGGLE_CALL_OR_SOUND = 'TOGGLE_CALL_OR_SOUND'
export const TOGGLE_CALL_OR_SOUND_REQUEST = 'TOGGLE_CALL_OR_SOUND_REQUEST'
export const TOGGLE_CALL_OR_SOUND_SUCCESS = 'TOGGLE_CALL_OR_SOUND_SUCCESS'
export const TOGGLE_CALL_OR_SOUND_ERROR = 'TOGGLE_CALL_OR_SOUND_ERROR'

export const SET_CASE_AUTO_APPROVE_CONTACTS_CATEGORY = 'SET_CASE_AUTO_APPROVE_CONTACTS_CATEGORY'
