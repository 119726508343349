import { API, api, APIData } from 'services/api'

export const deleteAccount = async () => api.delete(API.USER_PROFILE)

export const getInitialBonuses = async () => {
  const { data }: APIData<{ businessInitialBonus: number; professionalInitialBonus: number }> =
    await api.get(API.BUSINESS_ACCOUNT_REGISTRATION)

  return data
}
