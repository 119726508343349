import cls from 'classnames'

import styles from './Label.module.scss'

export type TLabelProps = PropsWithClassName<{
  label: string
}>

export const Label = ({ label, className }: TLabelProps) => (
  <span className={cls(styles.root, className)}>{label}</span>
)
