import { Field, FormRenderProps, useForm, useFormState } from 'react-final-form'
import { CaseClassificationIdEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import {
  StepsFooterActions,
  TStepsFooterActionsProps,
  TextField,
  CaseConvert
} from 'App/components'
import { TCaseCreateValues } from 'App/containers'
import { MAX_LENGTH_50 } from 'globalConstants'
import { ReactComponent as AccountSupervisorIcon } from 'assets/icons/AccountSupervisor.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as CaseGeneralIcon } from 'assets/icons/CaseGeneral.svg'

import styles from './CaseCreateDialogFieldsStep.module.scss'

export type TCaseCreateDialogFieldsStepProps = Pick<
  FormRenderProps<TCaseCreateValues>,
  'handleSubmit'
> &
  Pick<TStepsFooterActionsProps, 'onClickBack' | 'submitLoading' | 'submitDisabled'>

export const CaseCreateDialogFieldsStep = ({
  submitLoading,
  submitDisabled,
  handleSubmit,
  onClickBack
}: TCaseCreateDialogFieldsStepProps) => {
  const { t } = useTranslation()
  const form = useForm()
  const { invalid } = useFormState()

  return (
    <form onSubmit={handleSubmit} className={styles.root}>
      <Field
        name="title"
        validate={validation.composeValidators(
          validation.required(),
          validation.maxLength(MAX_LENGTH_50)
        )}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            topLabel={t('cases.modal.createCase.form.titleField')}
            autoFocus={true}
            classes={{ wrapper: styles.field }}
            valueLengthMax={MAX_LENGTH_50}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
          />
        )}
      </Field>

      <Field
        type="radio"
        name="value"
        value={CaseClassificationIdEnum.PRIVATE}
        validate={validation.required()}
      >
        {({ input }) => (
          <CaseConvert
            title={t('enum.caseClassificationNameEnum.PRIVATE')}
            description={t('cases.modal.caseConvert.typeStep.description_private')}
            icon={<CaseGeneralIcon />}
            value={CaseClassificationIdEnum.PRIVATE}
            selected={!!input.checked}
            onSelect={(v) => form.change(input.name, v)}
          />
        )}
      </Field>
      <Field type="radio" name="value" value={CaseTypesEnum.A2O} validate={validation.required()}>
        {({ input }) => (
          <CaseConvert
            title={t('enum.caseTypesEnum.Direct')}
            description={t('cases.modal.caseConvert.typeStep.description_direct')}
            icon={<AccountSupervisorIcon />}
            value={CaseTypesEnum.A2O}
            selected={!!input.checked}
            onSelect={(v) => form.change(input.name, v)}
          />
        )}
      </Field>

      <Field type="radio" name="value" value={CaseTypesEnum.A2A} validate={validation.required()}>
        {({ input }) => (
          <CaseConvert
            title={t('enum.caseTypesEnum.Group')}
            description={t('cases.modal.caseConvert.typeStep.description_group')}
            icon={<AccountGroupIcon />}
            value={CaseTypesEnum.A2A}
            selected={!!input.checked}
            onSelect={(v) => form.change(input.name, v)}
          />
        )}
      </Field>

      <StepsFooterActions
        submitLabel={t('cases.modal.createCase.form.submitButton_create')}
        submitType="submit"
        submitLoading={submitLoading}
        submitDisabled={submitDisabled || invalid}
        onClickBack={onClickBack}
      />
    </form>
  )
}
