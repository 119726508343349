import { useEffect, RefObject } from 'react'

export const useClickAwayListener = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent, outside: boolean) => void,
  excludeElementRef?: RefObject<T>
) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (
          !ref.current ||
          ref.current.contains(event.target as Node) ||
          excludeElementRef?.current?.contains(event.target as Node)
        ) {
          handler(event, false)

          return
        }

        handler(event, true)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, excludeElementRef]
  )
}
