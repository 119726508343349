import {
  actionCreator,
  TActivateSubscriptionErrorPayload,
  TActivateSubscriptionRequestPayload,
  TActivateSubscriptionSuccessPayload,
  TError,
  TGetActiveSubscriptionsSuccessPayload,
  TGetAvailableSubscriptionsRequestPayload,
  TGetAvailableSubscriptionsSuccessPayload,
  TGetMedCloudCapacityPayloadSuccess
} from 'store'

import * as actionTypes from './subscriptions.actionTypes'

// ------------------ GET_ACTIVE_SUBSCRIPTIONS ------------------

export const getActiveSubscriptionsRequest = () =>
  actionCreator(actionTypes.GET_ACTIVE_SUBSCRIPTIONS_REQUEST)

export const getActiveSubscriptionsSuccess = (payload: TGetActiveSubscriptionsSuccessPayload) =>
  actionCreator(actionTypes.GET_ACTIVE_SUBSCRIPTIONS_SUCCESS, payload)

export const getActiveSubscriptionsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ACTIVE_SUBSCRIPTIONS_ERROR, payload)

// ------------------ GET_AVAILABLE_SUBSCRIPTIONS ------------------

export const getAvailableSubscriptionsRequest = (
  payload: TGetAvailableSubscriptionsRequestPayload
) => actionCreator(actionTypes.GET_AVAILABLE_SUBSCRIPTIONS_REQUEST, payload)

export const getAvailableSubscriptionsSuccess = (
  payload: TGetAvailableSubscriptionsSuccessPayload
) => actionCreator(actionTypes.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS, payload)

export const getAvailableSubscriptionsError = (payload: TError) =>
  actionCreator(actionTypes.GET_AVAILABLE_SUBSCRIPTIONS_ERROR, payload)

// ------------------ ACTIVATE_SUBSCRIPTIONS ------------------

export const activateSubscriptionsRequest = (payload: TActivateSubscriptionRequestPayload) =>
  actionCreator(actionTypes.ACTIVATE_SUBSCRIPTIONS_REQUEST, payload)

export const activateSubscriptionsSuccess = (payload: TActivateSubscriptionSuccessPayload) =>
  actionCreator(actionTypes.ACTIVATE_SUBSCRIPTIONS_SUCCESS, payload)

export const activateSubscriptionsError = (payload: TActivateSubscriptionErrorPayload) =>
  actionCreator(actionTypes.ACTIVATE_SUBSCRIPTIONS_ERROR, payload)

// ------------------ RESET_SUBSCRIPTIONS ------------------

export const resetSubscriptions = () => actionCreator(actionTypes.RESET_SUBSCRIPTIONS)

// ------------------ GET_MED_CLOUD_CAPACITY ------------------

export const getMedCloudCapacityRequest = () =>
  actionCreator(actionTypes.GET_MED_CLOUD_CAPACITY_REQUEST)

export const getMedCloudCapacitySuccess = (payload: TGetMedCloudCapacityPayloadSuccess) =>
  actionCreator(actionTypes.GET_MED_CLOUD_CAPACITY_SUCCESS, payload)

export const getMedCloudCapacityError = (payload: TError) =>
  actionCreator(actionTypes.GET_MED_CLOUD_CAPACITY_ERROR, payload)
