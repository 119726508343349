import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import {
  ChatDeleteAllMessagesConfirm,
  TChatDeleteAllMessagesConfirmOwnProps
} from 'App/components/Chat/ChatDeleteAllMessagesConfirm'
import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { DELETE_ALL_MESSAGES, deleteAllMessagesRequest } from 'store/chatMessages'
import { removeErrorNotification } from 'store'

export type TChatDeleteAllMessagesConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingSelector([DELETE_ALL_MESSAGES])

const mapStateToProps = (state: State) => ({
  loading: processingSelector(state),
  error: state.errors[DELETE_ALL_MESSAGES]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteAllMessages: deleteAllMessagesRequest,
      removeErrorNotification: () => removeErrorNotification(DELETE_ALL_MESSAGES)
    },
    dispatch
  )

export const ChatDeleteAllMessagesConfirmContainer: FC<TChatDeleteAllMessagesConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(ChatDeleteAllMessagesConfirm)
