import { useCallback, useMemo } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import {
  createSlides,
  DELETE_CASE_FILE,
  deleteCaseFileRequest,
  ECardSize,
  EExtendableCardType,
  TSlideEntity,
  useAppDispatch
} from 'store'
import { TMenuItemProps } from 'App/components'
import { EModalComponents, TCaseMemberCloudContainerProps } from 'App/containers'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as PencilOutlineWriteIcon } from 'assets/icons/PencilOutlineWrite.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'
import { ReactComponent as CopyFileIcon } from 'assets/icons/CopyFile.svg'

export type TGetKebabMenuProps = {
  fileId: string
  fileName: string
  fileExtension: string
  slidesEntity: TSlideEntity

  mimeType?: string
  isUnopenable?: boolean
}

export type TGetMultipleKebabMenuProps = {
  fileId: string
  fileName: string
  fileExtension: string
}[]

export type TGetKebabMenu = (
  props: TGetKebabMenuProps | TGetMultipleKebabMenuProps
) => TMenuItemProps[]

type TKebabMapType =
  | 'open'
  | 'rename'
  | 'details-permissions'
  | 'copy-to-case-files'
  | 'download-to-my-device'
  | 'download-to-my-cloud'
  | 'discard-permission'
  | 'delete'

type TKebabMapProps = Partial<TGetKebabMenuProps> &
  Pick<
    TCaseMemberCloudContainerProps,
    'showModal' | 'who' | 'copyToMedcloud' | 'setCaseCloudActiveFileIdAction' | 'showExtendableCard'
  > & {
    ownerRights: boolean
    caseId: string
    isSharedWithMe: boolean
    isPrivate: boolean
    handleDownloadToDevice: (id: string) => () => void
    handleDelete: (ids: string[]) => void
    t: TFunction

    multiple?: TGetMultipleKebabMenuProps
  }

type TUseKebabMenuOptions = Omit<
  TKebabMapProps,
  | 'ownerRights'
  | 'fileId'
  | 'fileName'
  | 'fileExtension'
  | 'multiple'
  | 'handleDelete'
  | 'deleteProcessing'
  | 't'
> & {
  isMember: boolean
  isCaseArchived: boolean
  isUploadedByMe: boolean
}

const KEBAB_MAP = new Map<TKebabMapType, (props: TKebabMapProps) => TMenuItemProps>()
  .set('delete', ({ showModal, fileId, ownerRights, handleDelete, t, multiple = [] }) => ({
    icon: <TrashIcon />,
    content: t('cases.details.attachments.kebabMenu.delete'),
    hidden: !ownerRights,
    onClick: () => {
      const count = fileId ? 1 : multiple.length

      return showModal({
        modalTitle: t('modal.deleteFileConfirm.title', { count }),
        modalType: EModalComponents.GENERIC_CONFIRMATION,
        modalProps: {
          content: t('modal.deleteFileConfirm.content', { count }),
          confirmLabel: t('modal.deleteFileConfirm.submitButton'),
          loadingActions: [DELETE_CASE_FILE],
          onConfirm: () => handleDelete(fileId ? [fileId] : multiple.map((item) => item.fileId))
        }
      })
    }
  }))
  .set('discard-permission', ({ showModal, caseId, fileId, ownerRights, t, multiple = [] }) => ({
    icon: <TrashIcon />,
    content: t('cases.details.attachments.kebabMenu.discardPermission'),
    hidden: ownerRights,
    onClick: () =>
      showModal({
        modalType: EModalComponents.CASE_FILE_DISCARD_PERMISSIONS_CONFIRM,
        modalTitle: t('modal.discardFilePermissions.title'),
        modalProps: {
          caseId,
          fileIds: fileId ? [fileId] : multiple.map((item) => item.fileId)
        }
      })
  }))
  .set(
    'download-to-my-cloud',
    ({
      copyToMedcloud,
      caseId,
      who,
      fileId,
      fileName,
      fileExtension,
      ownerRights,
      t,
      multiple = []
    }) => {
      const onClick = () =>
        fileId && fileName && fileExtension
          ? copyToMedcloud({
              caseType: who,
              fileType: fileExtension,
              caseId,
              fileName,
              fileId,
              source: FileHistorySourcesEnum.CASE_CLOUD,
              originalEntityId: caseId
            })
          : multiple.forEach((item) =>
              copyToMedcloud({
                caseId,
                caseType: who,
                source: FileHistorySourcesEnum.CASE_CLOUD,
                originalEntityId: caseId,
                fileId: item.fileId,
                fileName: item.fileName,
                fileType: item.fileExtension
              })
            )

      return {
        icon: <DownloadIcon />,
        content: t('cases.details.attachments.kebabMenu.copyToMEDcloud'),
        hidden: ownerRights,
        onClick
      }
    }
  )
  .set('download-to-my-device', ({ handleDownloadToDevice, fileId, t }) => ({
    icon: <DownloadIcon />,
    content: t('cases.details.attachments.kebabMenu.downloadToDevice'),
    onClick: fileId ? () => handleDownloadToDevice(fileId)() : undefined
  }))
  .set(
    'copy-to-case-files',
    ({ showModal, caseId, isSharedWithMe, fileId, fileName, fileExtension, t }) => ({
      icon: <CopyFileIcon />,
      content: t('cases.details.attachments.kebabMenu.copyToCaseFiles'),
      hidden: !isSharedWithMe,
      onClick: () =>
        fileId &&
        fileName &&
        fileExtension &&
        showModal({
          modalType: EModalComponents.COPY_TO_CASE_CLOUD,
          modalTitle: t('cases.details.attachments.kebabMenu.copyToCaseFiles'),
          modalProps: {
            fileType: fileExtension,
            caseId,
            fileId,
            fileName
          }
        })
    })
  )
  .set(
    'details-permissions',
    ({ setCaseCloudActiveFileIdAction, isPrivate, ownerRights, fileId, t }) => ({
      icon: <InfoIcon />,
      content:
        !ownerRights || isPrivate
          ? t('cases.details.attachments.kebabMenu.details')
          : t('cases.details.attachments.kebabMenu.permissions'),
      onClick: fileId ? () => setCaseCloudActiveFileIdAction({ id: fileId }) : undefined
    })
  )
  .set('rename', ({ showModal, caseId, ownerRights, fileId, fileName, t }) => ({
    icon: <PencilOutlineWriteIcon />,
    content: t('cases.details.attachments.kebabMenu.rename'),
    hidden: !ownerRights,
    onClick: () =>
      showModal({
        modalTitle: t('cases.details.attachments.kebabMenu.rename'),
        modalType: EModalComponents.CASE_FILE_RENAME_DIALOG,
        modalProps: {
          fileId,
          defaultFileName: fileName,
          caseId
        }
      })
  }))
  .set('open', ({ showExtendableCard, caseId, fileId, isUnopenable, t, slidesEntity = [] }) => ({
    icon: <FileIcon />,
    content: t('cases.details.attachments.kebabMenu.open'),
    hidden: isUnopenable,
    onClick: fileId
      ? () =>
          showExtendableCard({
            type: EExtendableCardType.FILE_VIEWER,
            allowPosition: true,
            allowSize: true,
            size: ECardSize.COLLAPSED,
            initialSlideId: fileId,
            slides: createSlides(slidesEntity, FileHistorySourcesEnum.CASE_CLOUD, caseId)
          })
      : undefined
  }))

export const useKebabMenu = ({
  isCaseArchived,
  isSharedWithMe,
  isUploadedByMe,
  isMember,
  isPrivate,
  caseId,
  who,
  setCaseCloudActiveFileIdAction,
  showExtendableCard,
  copyToMedcloud,
  handleDownloadToDevice,
  showModal
}: TUseKebabMenuOptions) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleDelete = useCallback(
    (fileIds: string[]) => {
      dispatch(
        deleteCaseFileRequest({
          caseId,
          fileIds
        })
      )
    },
    [caseId, dispatch]
  )

  const getKebabMenu = useMemo(() => {
    if (isCaseArchived) {
      return
    }

    const handler: TGetKebabMenu = (props) => {
      const common = {
        ownerRights: isUploadedByMe || (!isMember && !isSharedWithMe),
        isPrivate,
        isSharedWithMe,
        who,
        caseId,
        showModal,
        copyToMedcloud,
        setCaseCloudActiveFileIdAction,
        showExtendableCard,
        handleDownloadToDevice,
        handleDelete,
        t
      }

      if (isArray(props)) {
        const multipleProps = {
          multiple: props,
          ...common
        }

        return [
          KEBAB_MAP.get('delete')!(multipleProps),
          KEBAB_MAP.get('download-to-my-cloud')!(multipleProps),
          KEBAB_MAP.get('discard-permission')!(multipleProps)
        ]
      }

      const regularProps = {
        ...props,
        ...common
      }

      return [
        KEBAB_MAP.get('open')!(regularProps),
        KEBAB_MAP.get('rename')!(regularProps),
        KEBAB_MAP.get('details-permissions')!(regularProps),
        KEBAB_MAP.get('copy-to-case-files')!(regularProps),
        KEBAB_MAP.get('download-to-my-device')!(regularProps),
        KEBAB_MAP.get('download-to-my-cloud')!(regularProps),
        KEBAB_MAP.get('discard-permission')!(regularProps),
        KEBAB_MAP.get('delete')!(regularProps)
      ]
    }

    return handler
  }, [
    caseId,
    copyToMedcloud,
    handleDownloadToDevice,
    isPrivate,
    isMember,
    isSharedWithMe,
    isUploadedByMe,
    setCaseCloudActiveFileIdAction,
    showModal,
    who,
    isCaseArchived,
    showExtendableCard,
    handleDelete,
    t
  ])

  return { getKebabMenu }
}
