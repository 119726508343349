import { FC, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'

import {
  caseMemberInviteApproveRequest,
  CASE_MEMBER_INVITE_APPROVE,
  CASE_MEMBER_INVITE_REJECT,
  createProcessingBySelector,
  showModalAction
} from 'store'
import { State } from 'redux/rootReducer'
import { EModalComponents } from 'App/containers'

import { Button } from '../../../components/common/Button'

type TCaseMemberInviteApproveRejectButtonBaseProps = {
  title: string
  inviteId: string
  type: 'approve' | 'reject'

  className?: string
}

export type TCaseMemberInviteApproveRejectButtonProps =
  TCaseMemberInviteApproveRejectButtonBaseProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const CaseMemberInviteApproveRejectButtonComponent: FC<TCaseMemberInviteApproveRejectButtonProps> =
  ({
    type,
    showModal,
    caseMemberInviteApprove,
    inviteId,
    className,
    title,
    approveProcessing,
    rejectProcessing
  }) => {
    const { t } = useTranslation()

    const isApprove = useMemo(() => type === 'approve', [type])

    const handleClick = useCallback(() => {
      if (isApprove) {
        caseMemberInviteApprove({ inviteId, title, processingId: inviteId })
        return
      }

      showModal({
        modalTitle: t('cases.modal.memberInviteReject.title'),
        modalType: EModalComponents.CASE_MEMBER_INVITE_REJECT_DIALOG,
        modalProps: {
          title,
          inviteId
        }
      })
    }, [title, inviteId, isApprove, showModal, caseMemberInviteApprove, t])

    return (
      <Button
        className={className}
        size="md"
        variant={isApprove ? 'primary' : 'outlined'}
        onClick={handleClick}
        loading={approveProcessing && isApprove}
        disabled={approveProcessing || rejectProcessing}
      >
        {isApprove ? t('invitations.approveButton') : t('invitations.rejectButton')}
      </Button>
    )
  }

const rejectProcessingBySelector = createProcessingBySelector([CASE_MEMBER_INVITE_REJECT])
const approveProcessingBySelector = createProcessingBySelector([CASE_MEMBER_INVITE_APPROVE])

const mapStateToProps = (
  state: State,
  { inviteId }: TCaseMemberInviteApproveRejectButtonBaseProps
) => ({
  rejectProcessing: rejectProcessingBySelector(inviteId)(state),
  approveProcessing: approveProcessingBySelector(inviteId)(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction,
      caseMemberInviteApprove: caseMemberInviteApproveRequest
    },
    dispatch
  )

export const CaseMemberInviteApproveRejectButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMemberInviteApproveRejectButtonComponent)
