import { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  hideExtendableCardAction,
  moveExtendableCardAction,
  expandExtendableCardAction,
  ECardSize
} from 'store'
import { State } from 'redux/rootReducer'
import { CardView } from 'App/components/common/ExtendableCard/CardView'
import { useAdaptiveLayout } from 'App/hooks'

type TCardContainerOwnProps = {
  allowPosition?: boolean
  allowSize?: boolean
}

export type TCardContainerProps = TCardContainerOwnProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CardContainerView = ({
  type,
  size,
  slides,
  initialSlideId,
  position,
  allowPosition,
  allowSize,
  hide,
  move,
  expand
}: ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>) => {
  const { isDesktop } = useAdaptiveLayout()
  const { pathname } = useLocation()

  useEffect(
    () => () => {
      hide()
    },
    [pathname, hide]
  )

  if (!type || !initialSlideId || size === ECardSize.FULLSCREEN || !isDesktop) {
    return null
  }

  return (
    <CardView
      slides={slides}
      initialSlideId={initialSlideId}
      type={type}
      position={position}
      size={size}
      allowPosition={allowPosition}
      allowSize={allowSize}
      hide={hide}
      move={move}
      expand={expand}
    />
  )
}

const mapStateToProps = (state: State, ownProps: TCardContainerOwnProps) => ({
  type: state.extendableCard.type,
  position: state.extendableCard.position,
  initialSlideId: state.extendableCard.initialSlideId,
  slides: state.extendableCard.slides,
  allowPosition:
    ownProps.allowPosition !== undefined
      ? ownProps.allowPosition
      : state.extendableCard.allowPosition,
  size: state.extendableCard.size,
  allowSize: ownProps.allowSize !== undefined ? ownProps.allowSize : state.extendableCard.allowSize
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hide: hideExtendableCardAction,
      move: moveExtendableCardAction,
      expand: expandExtendableCardAction
    },
    dispatch
  )

export const CardContainer = connect(mapStateToProps, mapDispatchToProps)(CardContainerView)
