import uniq from 'lodash/uniq'

import { TBroadcastState, TBroadcastAction } from './broadcast.types'
import * as actionTypes from './broadcast.actionTypes'

const initialState: TBroadcastState = {
  recipients: [],
  categories: [],
  departments: [],
  organizations: [],
  allContacts: false,
  organizationId: null,
  selectedFiles: {
    ids: [],
    list: {}
  },
  audioUrl: ''
}

export const broadcastReducer = (
  state = initialState,
  action: TBroadcastAction
): TBroadcastState => {
  switch (action.type) {
    case actionTypes.SET_BROADCAST_SELECTED_RECIPIENTS: {
      return {
        ...state,
        departments: [],
        categories: [],
        organizations: [],
        recipients: action.payload.recipients,
        allContacts: false,
        organizationId: null
      }
    }

    case actionTypes.SET_BROADCAST_SELECTED_DEPARTMENTS: {
      return {
        ...state,
        recipients: [],
        departments: action.payload.departments,
        allContacts: false,
        organizationId: null
      }
    }

    case actionTypes.SET_BROADCAST_SELECTED_CATEGORIES: {
      return {
        ...state,
        recipients: [],
        categories: action.payload.categories,
        allContacts: false,
        organizationId: null
      }
    }

    case actionTypes.SET_BROADCAST_SELECTED_ORGANIZATIONS: {
      return {
        ...state,
        recipients: [],
        organizations: action.payload.organizations,
        allContacts: false,
        organizationId: null
      }
    }

    case actionTypes.SET_BROADCAST_ALL_CONTACTS: {
      return {
        ...state,
        recipients: [],
        departments: [],
        organizations: [],
        categories: [],
        allContacts: action.payload,
        organizationId: null
      }
    }

    case actionTypes.SET_BROADCAST_ORGANIZATION_ID: {
      return {
        ...state,
        recipients: [],
        departments: [],
        organizations: [],
        categories: [],
        allContacts: false,
        organizationId: action.payload
      }
    }

    case actionTypes.SET_BROADCAST_AUDIO: {
      const { audioUrl, audioBlob } = action.payload

      return {
        ...state,
        audioUrl,
        audioBlob
      }
    }

    case actionTypes.SET_BROADCAST_SELECTED_FILES: {
      const { ids, list } = action.payload

      return {
        ...state,
        selectedFiles: {
          ids: uniq([...state.selectedFiles.ids, ...ids]),
          list: { ...state.selectedFiles.list, ...list }
        }
      }
    }

    case actionTypes.REMOVE_BROADCAST_SELECTED_FILE: {
      const { id } = action.payload
      const { [id]: _, ...list } = state.selectedFiles.list

      return {
        ...state,
        selectedFiles: {
          ...state.selectedFiles,
          list,
          ids: state.selectedFiles.ids.filter((item) => item !== id)
        }
      }
    }

    case actionTypes.RESET_BROADCAST: {
      return initialState
    }

    default:
      return state
  }
}
