import { useCallback } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { queryClient } from 'queryClient'
import { InvitationItemSent } from 'App/components/Invitations'
import { IEventInvitationDTO } from 'interfaces'
import { hideEventAttendeeInvitation, invitationsByEmailWithdraw } from 'api/invitations'
import { deleteAttendeeEventProfile, resendEventInviteByEmail } from 'api/events'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'

import { EVENTS_ATTENDEES_LIST_QUERY_KEY } from '../EventsAttendeesList'

import styles from './EventsAttendeesItem.module.scss'

export type TEventsAttendeesItemProps = Pick<
  IEventInvitationDTO,
  'attendee' | 'token' | 'email' | 'status' | 'createdAt'
> & {
  eventId: string
}

const WITHDRAW_KEY = 'events-attendees-invitation-withdraw'
const RESEND_KEY = 'events-attendees-invitation-resend'
const REMOVE_KEY = 'events-attendees-remove'
const HIDE_KEY = 'events-attendees-invitation-hide'

export const EventsAttendeesItem = ({
  token,
  attendee,
  email,
  status,
  createdAt,
  eventId
}: TEventsAttendeesItemProps) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const handleActionSuccess = useCallback(
    (toastMessage?: string) => () => {
      queryClient.invalidateQueries({ queryKey: [EVENTS_ATTENDEES_LIST_QUERY_KEY], type: 'active' })

      toastMessage && toast.success(toastMessage, toastDefaultOptions)
    },
    []
  )

  const { mutate, isLoading: withdrawProcessing } = useMutation({
    mutationKey: [WITHDRAW_KEY],
    mutationFn: invitationsByEmailWithdraw,
    onSuccess: handleActionSuccess(t('common.toast.invitationWithdrew'))
  })

  const { mutate: resend, isLoading: resendProcessing } = useMutation({
    mutationKey: [RESEND_KEY],
    mutationFn: resendEventInviteByEmail,
    onSuccess: handleActionSuccess(t('common.toast.invitationResent'))
  })

  const { mutate: hideInvitation, isLoading: isHiding } = useMutation({
    mutationKey: [HIDE_KEY],
    mutationFn: () => hideEventAttendeeInvitation(token),
    onSuccess: handleActionSuccess()
  })

  const { mutateAsync: removeAttendee, isLoading: isRemoving } = useMutation({
    mutationKey: [REMOVE_KEY],
    mutationFn: attendee
      ? () => deleteAttendeeEventProfile({ attendeeId: attendee?.id, eventId })
      : undefined,
    onSuccess: handleActionSuccess()
  })

  const handleWithdraw = useCallback(() => {
    mutate(token)
  }, [mutate, token])

  const handleResend = useCallback(() => {
    if (!email) {
      return
    }

    resend({ email, eventId })
  }, [email, eventId, resend])

  const handleRemove = useCallback(() => {
    if (attendee) {
      dispatch(
        showModalAction({
          modalTitle: t('modal.removeEventAttendeeConfirm.title'),
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalProps: {
            confirmLabel: t('modal.removeEventAttendeeConfirm.submitButton'),
            content: t('modal.removeEventAttendeeConfirm.content'),
            onConfirm: removeAttendee
          }
        })
      )

      return
    }

    hideInvitation()
  }, [attendee, dispatch, hideInvitation, removeAttendee, t])

  return (
    <InvitationItemSent
      classes={{ root: styles.root }}
      variant="external"
      status={status}
      statusLabel={t(`enum.eventInviteStatusEnum.${status}`)}
      createdAccount={attendee?.account}
      email={email}
      invitationEmail={email}
      createdAt={createdAt}
      registeredAt={attendee?.createdAt}
      deletedAt={attendee?.deletedAt}
      contactTypeName={AccountTypeNames.PROFESSIONAL}
      withdrawProcessing={withdrawProcessing}
      resendProcessing={resendProcessing}
      removeProcessing={isHiding || isRemoving}
      onWithdraw={handleWithdraw}
      onResend={email ? handleResend : undefined}
      onRemove={handleRemove}
    />
  )
}
