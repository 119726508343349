import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { formatLongDate } from 'utils'

import styles from './ExpirationDate.module.scss'

export type TExpirationDateProps = {
  startedOn: string
  endsOn: string
  isFuture: boolean
  isActive: boolean
}

export const ExpirationDate = ({ endsOn, startedOn, isActive, isFuture }: TExpirationDateProps) => {
  const { t } = useTranslation()

  const showStartsOn = isFuture && !isActive && startedOn
  const showEndsOn = !isFuture && isActive && endsOn

  return (
    <>
      {showStartsOn && (
        <div className={cls(styles.base, styles.success)}>
          {t('subscriptions.availableSubscription.startsOnLabel', {
            date: formatLongDate(startedOn)
          })}
        </div>
      )}
      {showEndsOn && (
        <div className={cls(styles.base, styles.alert)}>
          {t('subscriptions.availableSubscription.endsOnLabel', {
            date: formatLongDate(endsOn)
          })}
        </div>
      )}
    </>
  )
}
