import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { Avatar } from 'App/components/common'
import { joinVoiceRoomRequest, TOngoingCall, useAppDispatch } from 'store'

import { Header, Button, Wrapper, CallLabel, ActiveCallLabel } from '../common'

type TVoiceRoomProps = Pick<TOngoingCall, 'voiceRoom'> & {
  ableToSwitch: boolean
}

export const VoiceRoom = ({ voiceRoom, ableToSwitch }: TVoiceRoomProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleSwitch = useCallback(() => {
    if (voiceRoom?.id && ableToSwitch) {
      dispatch(joinVoiceRoomRequest({ voiceRoomId: voiceRoom?.id }))
    }
  }, [ableToSwitch, dispatch, voiceRoom?.id])

  if (!voiceRoom?.organization) {
    return null
  }

  const {
    id,
    lastName,
    firstName,
    displayUserName,
    type: { name: typeName }
  } = voiceRoom.organization

  return (
    <Wrapper
      avatar={
        <Avatar
          size={EAvatarSize.MD}
          type={typeName}
          userId={id}
          lastName={lastName}
          firstName={firstName}
          displayUserName={displayUserName}
        />
      }
      content={
        <>
          <Header title={displayUserName} />
          <CallLabel
            ableToSwitch={ableToSwitch}
            tooltip={t('meetings.anotherDeviceCallTooltip')}
            label={t('meetings.voiceRoomLabel', { voiceRoomName: voiceRoom.name })}
          />
          <ActiveCallLabel isOnCurrentCall={!ableToSwitch} label={t('meetings.activeCallLabel')} />
        </>
      }
      action={
        <Button
          isOnCurrentCall={!ableToSwitch}
          ableToSwitch={ableToSwitch}
          onClick={handleSwitch}
        />
      }
    />
  )
}
