import { ReactNode } from 'react'
import cls from 'classnames'

import { formatShortDate } from 'utils'
import { TChatDefaultMessageContainerProps, EChatDefaultMessageSize } from 'App/containers'

import styles from './ChatDefaultMessageWrapper.module.scss'

export type TChatDefaultMessageWrapperClasses = {
  classes?: Partial<Record<'root' | 'wrapper', string>>
}

export type TChatDefaultMessageWrapperProps = Pick<
  TChatDefaultMessageContainerProps,
  'createdAt' | 'chatType' | 'size'
> & {
  children: ReactNode
} & TChatDefaultMessageWrapperClasses

export const ChatDefaultMessageWrapper = ({
  children,
  createdAt,
  size = EChatDefaultMessageSize.MD,
  classes
}: TChatDefaultMessageWrapperProps) => {
  if (!createdAt) {
    return null
  }

  return (
    <div className={cls(styles.root, styles[size], classes?.root)}>
      <div className={cls(styles.wrapper, classes?.wrapper)}>
        <div className={styles.time}>{formatShortDate(createdAt)}</div>
        {children}
      </div>
    </div>
  )
}
