import { useCallback } from 'react'
import { ChatTypeEnum, ErrorCodesEnum } from '@medentee/enums'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { createTextChannel, editTextChannel } from 'api/organizations'
import { isMatchErrorCode } from 'utils'
import {
  accountIdSelector,
  getChatRoomsRequest,
  hideModalAction,
  isBusinessAccountSelector,
  RECEIVE_ADDED_USER_TO_CHAT,
  RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL,
  useAppDispatch,
  useAppSelector
} from 'store'
import { skipWSAction } from 'services/skipWSActions'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { toast } from 'App/components/ToastContainer'
import { TChannelDepartment } from 'App/components/UnityHub/UnityHubCreateChannels'

export type TUseRequest = {
  organizationId: string

  channelMembers?: TAccountsSelectOptionData[]
  channelDepartments?: TChannelDepartment[]
  channelManager?: TAccountsSelectOptionData
  channelId?: string
  isEdit?: boolean
  isLeaderInDepartments?: boolean
  onEditSuccess?: () => void
}

const MUTATION_KEY = 'create-text-channel'
const MUTATION_KEY_EDIT = 'edit-text-channel'

export const useRequest = ({
  organizationId,
  channelMembers,
  channelDepartments,
  channelManager,
  isEdit,
  channelId,
  isLeaderInDepartments,
  onEditSuccess
}: TUseRequest) => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector(accountIdSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const { push } = useHistory()

  const { t } = useTranslation()

  const handleSubmitError = useCallback(
    (error) => {
      if (isMatchErrorCode(error, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        dispatch(hideModalAction())

        toast.warn(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    },
    [dispatch, t]
  )

  const {
    error: editError,
    isLoading: editLoading,
    mutate: edit
  } = useMutation({
    mutationKey: [MUTATION_KEY_EDIT],
    mutationFn: editTextChannel,
    onError: handleSubmitError,
    onSuccess: () => {
      dispatch(hideModalAction())
      dispatch(
        getChatRoomsRequest({
          organizationId,
          chatType: [ChatTypeEnum.ORGANIZATION]
        })
      )

      onEditSuccess && onEditSuccess()
    }
  })

  const { error, isLoading, mutate } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: createTextChannel,
    onError: handleSubmitError,
    onSuccess: ({ id }) => {
      dispatch(hideModalAction())
      dispatch(
        getChatRoomsRequest({
          organizationId,
          chatType: [ChatTypeEnum.ORGANIZATION],
          onSuccess: () => {
            push(
              isBusinessAccount
                ? `/organization/chat/${id}`
                : `/organizations/${organizationId}/chat/${id}`
            )
          }
        })
      )
    }
  })

  const handleFormSubmit = useCallback(
    (values: { name: string; isPublic: boolean }) => {
      const accountIds = !values.isPublic ? channelMembers?.map(({ id }) => id) : []
      const departmentIds = !values.isPublic ? channelDepartments?.map(({ id }) => id) : []
      const channelManagerId = channelManager?.id

      if (isLeaderInDepartments && !isEdit && accountId) {
        accountIds?.push(accountId)
      }

      skipWSAction({ type: RECEIVE_ADDED_USER_TO_CHAT, payload: { organizationId } })
      skipWSAction({ type: RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL, payload: { organizationId } })

      if (isEdit && channelId) {
        edit({
          ...values,
          organizationId,
          accountIds,
          departmentIds,
          channelManagerId: channelManagerId ? channelManagerId : null,
          channelId
        })
        return
      }

      mutate({
        ...values,
        organizationId,
        accountIds,
        departmentIds,
        channelManagerId
      })
    },
    [
      channelMembers,
      channelDepartments,
      channelManager?.id,
      isLeaderInDepartments,
      isEdit,
      accountId,
      organizationId,
      channelId,
      mutate,
      edit
    ]
  )

  const isFieldError = isMatchErrorCode(error, [
    ErrorCodesEnum.TEXT_CHANNEL_ALREADY_EXIST,
    ErrorCodesEnum.CHANNEL_MANAGER_MUST_BE_ACTIVE_MEMBER
  ])

  return {
    error: error || editError,
    processing: isLoading || editLoading,
    isFieldError,
    handleFormSubmit
  }
}
