import { ReactNode, useCallback, useEffect } from 'react'
import cls from 'classnames'
import { useDispatch } from 'react-redux'
import { ChatTypeEnum, UploadSourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { DragAndDropContainer } from 'App/containers'
import { IUploadStorageDTO } from 'interfaces'
import {
  attachChatMedCloudFileRequest,
  getChatRoomRequest,
  useAppSelector,
  getSelectedChatRoomSelector
} from 'store'
import { MAX_GROUP_CHAT_FILE_SIZE } from 'globalConstants'

import styles from './ChatRoomWrapper.module.scss'

type TChatRoomWrapperClasses = 'root' | 'headerWrapper' | 'main' | 'footerWrapper'

export type TChatRoomWrapperProps = {
  main: ReactNode
  footer: ReactNode
  header?: ReactNode
  dndDisabled?: boolean
  dndHide?: boolean
  dndLayout?: 'fixed'
  classes?: Partial<Record<TChatRoomWrapperClasses, string>>
  activeChatId?: string | null
}

export const ChatRoomWrapper = ({
  main,
  footer,
  header,
  classes,
  dndLayout,
  dndDisabled,
  dndHide,
  activeChatId
}: TChatRoomWrapperProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { type: chatType } = useAppSelector(getSelectedChatRoomSelector(activeChatId)) ?? {}

  const attachFile = useCallback(
    (file: IUploadStorageDTO) => {
      if (activeChatId) {
        dispatch(
          attachChatMedCloudFileRequest({
            chatId: activeChatId,
            fileIds: [file.id]
          })
        )
      }
    },
    [dispatch, activeChatId]
  )

  useEffect(() => {
    activeChatId && dispatch(getChatRoomRequest({ chatId: activeChatId }))
  }, [activeChatId, dispatch])

  return (
    <DragAndDropContainer
      hide={dndHide}
      disabled={dndDisabled}
      centerOnDropAlert
      onlyUpload
      layout={dndLayout}
      chatId={activeChatId}
      uploadedFrom={UploadSourcesEnum.CHAT}
      onAfterUploadFile={attachFile}
      maxFileSize={chatType === ChatTypeEnum.CASE_GROUP ? MAX_GROUP_CHAT_FILE_SIZE : undefined}
      onDropText={t('common.dragAndDrop.placeholder_attach')}
    >
      <div className={cls(styles.root, classes?.root)}>
        {header && (
          <div className={cls(styles.headerWrapper, classes?.headerWrapper)}>{header}</div>
        )}
        <div className={cls(styles.main, classes?.main)}>{main}</div>
        {footer && (
          <div className={cls(styles.footerWrapper, classes?.footerWrapper)}>{footer}</div>
        )}
      </div>
    </DragAndDropContainer>
  )
}
