import { Redirect, Switch } from 'react-router-dom'

import { getKeyForRoute } from 'App/App.config'
import { RoleRouteContainer } from 'App/containers'

import { useInvitationsRoutes } from './useInvitationsRoutes'

export const Invitations = () => {
  const { defaultPath, nestedRoutes } = useInvitationsRoutes()

  return (
    <Switch>
      {nestedRoutes.map((item, index) => (
        <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
      ))}

      <Redirect to={defaultPath} />
    </Switch>
  )
}
