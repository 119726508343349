import { put, select, takeLatest } from 'redux-saga/effects'
import uniq from 'lodash/uniq'

import { State } from 'redux/rootReducer'
import { setAdminOnlineAction } from 'store'
import { TOnlineList, TOnlineAdmin, TOnlineUser } from 'store/online'
import { getISOStringNow } from 'utils'

import { receiveSystemIntermediateStateAction } from './system.actions'
import { RECEIVE_SYSTEM_INTERMEDIATE_STATE } from './system.actionTypes'

function* receiveSystemIntermediateStateSaga({
  payload: { onlineAdminIds }
}: ReturnType<typeof receiveSystemIntermediateStateAction>) {
  const admin: TOnlineAdmin = yield select((state: State) => state.online.admin)
  const ids = uniq([...admin.ids, ...onlineAdminIds])
  const list = {
    ...admin.list,
    ...onlineAdminIds.reduce<TOnlineList>((acc, id) => {
      const item: TOnlineUser = {
        privateUserId: id,
        online: true,
        lastSeen: getISOStringNow()
      }

      return {
        ...acc,
        [id]: item
      }
    }, {})
  }

  yield put(
    setAdminOnlineAction({
      ids,
      list
    })
  )
}

export function* systemSaga() {
  yield takeLatest(RECEIVE_SYSTEM_INTERMEDIATE_STATE, receiveSystemIntermediateStateSaga)
}
