import { PropsWithChildren, ReactElement } from 'react'
import cls from 'classnames'

import styles from './SearchWrapper.module.scss'

type TSearchWrapperProps = PropsWithClassName<{
  filters?: ReactElement | null
  hiddenFilter?: boolean
}>

export const SearchWrapper = ({
  children,
  filters,
  hiddenFilter,
  className
}: PropsWithChildren<TSearchWrapperProps>) => (
  <div className={cls(styles.header, className)}>
    <div className={styles.search}>{children}</div>
    {!hiddenFilter && filters}
  </div>
)
