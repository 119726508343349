import { Fragment, useMemo } from 'react'

import { parseTextWithEmoji } from '../TextWithEmoji'
import { parseTextWithMention, TTextWithMentionProps } from '../TextWithMention'

type TFormatters = 'emoji' | 'mention'

export type TUseFormattedTextProps = Pick<TTextWithMentionProps, 'mentions'> &
  PropsWithClasses<
    TFormatters,
    {
      text?: string
      formatters?: TFormatters[]
    }
  >

const MENTION_WITHOUT_START_SPACE_REG = /[\d|\w]<(\/|@|#)(here|everyone|\d+)>/gm
const MENTION_WITHOUT_END_SPACE_REG = /<(\/|@|#)(here|everyone|\d+)>[\d|\w]/gm
const SEPARATOR = '%%$separator$%%'

export const useFormattedText = ({
  mentions,
  formatters,
  classes,
  text = ''
}: TUseFormattedTextProps): JSX.Element => {
  const textArray = useMemo(
    () =>
      text
        .replace(MENTION_WITHOUT_START_SPACE_REG, (match) => ` ${match.substring(1)}`)
        .replace(
          MENTION_WITHOUT_END_SPACE_REG,
          (match) => `${match.substring(0, match.length - 1)} `
        )
        .replace(/[\n]/g, `${SEPARATOR}\n`)
        .replace(/ /g, `${SEPARATOR} `)
        .split(SEPARATOR),
    [text]
  )

  const result = useMemo(
    () =>
      textArray.map((item, index) => {
        let nextItem: string | JSX.Element = item

        if (typeof nextItem === 'string' && formatters?.includes('mention')) {
          nextItem = parseTextWithMention({ text: nextItem, mentions, className: classes?.mention })
        }

        if (typeof nextItem === 'string' && formatters?.includes('emoji')) {
          nextItem = parseTextWithEmoji({ text: nextItem, className: classes?.emoji })
        }

        return <Fragment key={index}>{nextItem}</Fragment>
      }),
    [classes, formatters, mentions, textArray]
  )

  return <>{result}</>
}
