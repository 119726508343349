import { useCallback } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { CaseStatusesEnum, ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  ChatReadUnreadIcon,
  ChatRoomItemWrapper,
  ChatTime,
  ChatTypingIndicator,
  NotificationBadge,
  Tooltip,
  TruncatedText
} from 'App/components'
import { AvatarContainer, TChatRoomItemContainerViewProps } from 'App/containers'
import { EAvatarSize, ENotificationBadgeColor } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { TChatPathParams } from 'App/screens'
import { ReactComponent as CaseLockedIcon } from 'assets/icons/CaseLocked.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { isChatRoomNotificationVisible } from 'utils/chat'

import { LastMessagePrefix } from '../LastMessagePrefix'
import { LastMessageText } from '../LastMessageText'
import { ChatMutedIcon } from '../../ChatMutedIcon'

import styles from './ChatRoomItemP2P.module.scss'

export type TChatRoomItemP2PProps = TChatRoomItemContainerViewProps

const TYPING_INDICATOR_LIMIT = 1

export const ChatRoomItemP2P = ({
  chatId: selectedChatId,
  chat,
  loading,
  unreadCount,
  mentionCount,
  draft,
  isSelected,
  isLastMessageEditing,
  isContact,
  isCoworker,
  isChatRoomMuted
}: TChatRoomItemP2PProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()
  const {
    path,
    params: { chatId, chatType }
  } = useRouteMatch<TChatPathParams>()

  const { push } = useHistory()

  const { t } = useTranslation()

  const senderName =
    chat?.lastMessage?.type !== MessageTypeEnum.SYSTEM
      ? chat?.lastMessage?.sender?.displayUserName
      : ''

  const isCaseGroupChat = chat?.type === ChatTypeEnum.CASE_GROUP
  const isGroupChat = chat?.type === ChatTypeEnum.GROUP

  const shouldShowDraft = isContact || isCoworker || isCaseGroupChat || isGroupChat
  const shouldShowAvatar = !isCaseGroupChat && (chat?.interlocutorAccount || isGroupChat)
  const shouldShowUnreadCounter = isChatRoomNotificationVisible({
    isMobile,
    mentionCount,
    unreadCount
  })

  const handleChatSelect = useCallback(() => {
    if (chatId !== selectedChatId && !loading) {
      push(generatePath(path, { chatType, chatId: selectedChatId }))
    }
  }, [chatId, selectedChatId, loading, push, path, chatType])

  const getChatName = () => {
    if (isGroupChat && chat.name) {
      return chat.name
    }

    return (chat?.interlocutorAccount?.displayUserName || chat?.case?.title) ?? ''
  }

  return (
    <ChatRoomItemWrapper
      isSelected={isSelected}
      className={styles.wrapper}
      onChatSelect={handleChatSelect}
    >
      <Skeleton
        loading={loading}
        active={true}
        avatar={{ shape: 'circle' }}
        paragraph={{ rows: 1 }}
        className={styles.skeleton}
      >
        {shouldShowAvatar && (
          <div className={styles.avatarContainer}>
            <AvatarContainer
              size={isDesktop ? EAvatarSize.LMD : EAvatarSize.MD}
              displayUserName={
                isGroupChat && chat.name
                  ? chat.name
                  : chat?.interlocutorAccount?.displayUserName ?? ''
              }
              onlineBorderColor={isSelected ? undefined : '#FFFFFF'}
              onlineBorderCssRootPropertyValue={isSelected ? '--palette-primary' : undefined}
              firstName={chat?.interlocutorAccount?.firstName ?? ''}
              lastName={chat?.interlocutorAccount?.lastName ?? ''}
              type={chat?.interlocutorAccount?.type?.name}
              chatType={isGroupChat ? ChatTypeEnum.GROUP : undefined}
              border={true}
              userId={isGroupChat ? chat.id : chat.interlocutorAccount?.id ?? ''}
              hasTooltip={false}
              showOnline={!isGroupChat}
            />
          </div>
        )}
        <div className={cls({ [styles.mainContainer]: true, [styles.active]: isSelected })}>
          <div className={styles.innerContainer}>
            <div className={styles.title}>
              <TruncatedText classes={{ text: styles.name }} text={getChatName()} />
              {isCoworker && (
                <Tooltip title={t('common.accountName.tooltip.coworker')}>
                  <AccountGroupIcon className={cls(styles.icon, styles.coworker)} />
                </Tooltip>
              )}
              {chat?.case?.status === CaseStatusesEnum.LOCKED && (
                <CaseLockedIcon className={styles.icon} />
              )}
              {isChatRoomMuted && (
                <ChatMutedIcon className={cls(isSelected && styles.icon)} size="md" />
              )}
            </div>
            <div className={styles.innerRight}>
              {chat?.lastMessage?.yourMessage && (
                <ChatReadUnreadIcon read={chat?.lastMessage?.recipientRead} white={isSelected} />
              )}
              <ChatTime
                time={chat?.lastMessage?.createdAt ?? chat?.createdAt}
                className={styles.time}
              />
            </div>
          </div>
          <div className={styles.innerContainer}>
            <div className={styles.message}>
              <ChatTypingIndicator
                chatId={chat?.id}
                className={cls(styles.typingIndicator, {
                  [styles.outgoing]: !!chat?.lastMessage?.yourMessage
                })}
                namesLimit={TYPING_INDICATOR_LIMIT}
              >
                <LastMessagePrefix
                  draftMessage={Boolean(draft?.message && shouldShowDraft)}
                  draftQuote={Boolean(draft?.quoteMessage && shouldShowDraft)}
                  senderName={isCaseGroupChat ? senderName : ''}
                  showSenderName={Boolean(isCaseGroupChat && senderName && !unreadCount)}
                  yourMessage={!!chat?.lastMessage?.yourMessage}
                  active={isSelected}
                />
                <LastMessageText
                  chatType={chat?.type}
                  draft={draft}
                  showDraft={shouldShowDraft}
                  caseTitle={chat?.case?.title}
                  interlocutorName={chat?.interlocutorAccount?.displayUserName}
                  lastMessage={chat?.lastMessage}
                  isLastMessageEditing={isLastMessageEditing}
                  unreadCount={unreadCount}
                  chatName={chat?.name}
                  clearedUnixTs={chat?.clearedUnixTs}
                  isSelected={isSelected}
                />
              </ChatTypingIndicator>
            </div>

            <div className={styles.badgeList}>
              <NotificationBadge
                variant="icon"
                className={styles.count}
                count={mentionCount}
                icon="@"
                color={ENotificationBadgeColor.PRIMARY}
              />

              {shouldShowUnreadCounter && (
                <NotificationBadge
                  className={styles.count}
                  count={unreadCount}
                  color={
                    isChatRoomMuted
                      ? ENotificationBadgeColor.MUTED
                      : ENotificationBadgeColor.PRIMARY
                  }
                />
              )}
            </div>
          </div>
        </div>
      </Skeleton>
    </ChatRoomItemWrapper>
  )
}
