import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsBusinessInfoCompanyName } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsBusinessInfoCompanyNameContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  companyName: state.global.accountData?.companyName
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ showModal: showModalAction }, dispatch)

export const SettingsBusinessInfoCompanyNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsBusinessInfoCompanyName)
