import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { BusinessAccountDirectionEnum, BusinessAccountRoleNameEnum } from '@medentee/enums'
import { FC } from 'react'

import {
  REMOVE_BUSINESS_ADMIN_INVITE,
  deleteBusinessAdminInviteRequest,
  removeErrorNotification,
  createProcessingBySelector
} from 'store'
import { State } from 'redux/rootReducer'
import { EBusinessAdminDeleteAction, EBusinessAdminConfirmFrom } from 'enums'
import { ConfirmBusinessAdminInvite } from 'App/components/Dialogs/ConfirmBusinessAdminInvite'

export type TConfirmBusinessAdminInviteContainerOwnProps = {
  id: string
  type: EBusinessAdminDeleteAction
  from: EBusinessAdminConfirmFrom
  direction: BusinessAccountDirectionEnum

  organizationId?: string
  role?: BusinessAccountRoleNameEnum
  onlyDowngrade?: boolean
  description?: string
}

export type TConfirmBusinessAdminInviteContainerProps =
  TConfirmBusinessAdminInviteContainerOwnProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const processingSelector = createProcessingBySelector([REMOVE_BUSINESS_ADMIN_INVITE])

const mapStateToProps = (state: State, { id }: TConfirmBusinessAdminInviteContainerOwnProps) => ({
  processing: processingSelector(id)(state),
  error: state.errors[REMOVE_BUSINESS_ADMIN_INVITE]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteBusinessAdminInvite: deleteBusinessAdminInviteRequest,
      removeErrorNotification: () => removeErrorNotification(REMOVE_BUSINESS_ADMIN_INVITE)
    },
    dispatch
  )

export const ConfirmBusinessAdminInviteContainer: FC<TConfirmBusinessAdminInviteContainerOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(ConfirmBusinessAdminInvite)
