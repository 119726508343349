import { useCallback, useEffect, useMemo, useState } from 'react'
import { Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { useQuery } from 'services/query'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { generateRandomArray } from 'utils'
import { getContacts } from 'api/contacts'
import { accountIdSelector, useAppSelector } from 'store'
import { useAccumulate, useAdaptiveLayout } from 'App/hooks'
import { EmptyList, InfiniteScroll, SearchWithChips } from 'App/components/common'

import { ChatCreateGroupMembersItem } from '../ChatCreateGroupMembersItem'
import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

import styles from './ChatCreateGroupSelectMembers.module.scss'

const FAKE_ITEMS = generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH)

export const ChatCreateGroupSelectMembers = () => {
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState('')
  const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null)
  const accountId = useAppSelector(accountIdSelector)
  const { isDesktop } = useAdaptiveLayout()

  const { selectedMembers, setSelectedMembers } = useChatCreateGroupContext()

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['chat-create-group-get-contacts'],
    queryFn: () =>
      getContacts({
        accountId,
        page,
        search
      }),
    onSuccess: (response) => {
      if (response) {
        setTotal(response.total)
      }
    },
    cacheTime: 0
  })

  const handleSearch = (value: string) => {
    setSearch(value)
    setPage(0)
  }

  const handleChange = useCallback(
    (item) => (e: CheckboxChangeEvent) => {
      const checked = e.target.checked

      setSelectedMembers((prev) =>
        checked ? [...prev, item] : prev.filter((value) => value.id !== item.id)
      )
    },
    [setSelectedMembers]
  )

  const isChecked = useCallback(
    (id: string) => selectedMembers.findIndex((value) => value.id === id) !== -1,
    [selectedMembers]
  )

  const handleUnselect = (id: string) => {
    setSelectedMembers((prev) => prev.filter((value) => value.id !== id))
  }

  const items = useAccumulate({ items: data?.results, page, total, args: { search } })

  const chips = useMemo(
    () =>
      selectedMembers.map(({ id, displayUserName }) => ({
        id,
        label: displayUserName
      })),
    [selectedMembers]
  )

  const showSkeleton = isLoading && !items.length
  const showPlaceholder = Boolean(!items.length && !isLoading && search)
  const showList = Boolean(rootEl && items.length && !isLoading)

  useEffect(() => {
    refetch()
  }, [refetch, search, page])

  return (
    <div className={styles.root}>
      <div className={styles.search}>
        <SearchWithChips chips={chips} onChange={handleSearch} onRemove={handleUnselect} />
      </div>

      <div ref={setRootEl} className={styles.list}>
        {showSkeleton &&
          FAKE_ITEMS.map((index) => <Skeleton active paragraph={false} key={index} />)}

        {showPlaceholder && <EmptyList />}

        {showList && (
          <InfiniteScroll
            page={page}
            next={setPage}
            loading={isFetching}
            scrollableTarget={isDesktop && rootEl}
            total={total}
            limit={PAGINATION_DEFAULT_SHOW_BY}
            dataLength={items.length}
          >
            {items.map((item) => (
              <ChatCreateGroupMembersItem
                key={item.id}
                displayUserName={item.displayUserName}
                userId={item.id}
                firstName={item.firstName}
                lastName={item.lastName}
                type={item.type.name}
                variant="checkbox"
                checked={isChecked(item.id)}
                onChange={handleChange(item)}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}
