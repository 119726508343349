import styles from './FixedAddButton.module.scss'

export type TFixedAddButtonProps = {
  icon: JSX.Element
  onClick?: () => void
}

export const FixedAddButton = ({ icon, onClick }: TFixedAddButtonProps) => (
  <button className={styles.iconFixed} onClick={onClick}>
    {icon}
  </button>
)
