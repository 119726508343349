import { useEffect } from 'react'
import { Skeleton } from 'antd'
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel'
import cls from 'classnames'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { CaseDetailsDate, CollapsePanel, TruncatedText } from 'App/components'
import {
  CaseOwnerOpinionsContainer,
  AvatarContainer,
  CaseDocumentOpinionItemBadgeContainer,
  TCaseOpinionsMembersItemContainerProps
} from 'App/containers'
import { EAvatarSize } from 'enums'

import styles from './CaseOpinionsMembersItem.module.scss'

export type TCaseOpinionsMembersItemProps = Omit<CollapsePanelProps, 'key' | 'header'> &
  TCaseOpinionsMembersItemContainerProps

export const CaseOpinionsMembersItem = ({
  firstName,
  lastName,
  displayUserName,
  type,
  memberId,
  loading,
  isMyAccount,
  openOpinionMemberId,
  opinionCreator,
  createdAt,
  setOpenedOpinion,
  ...rest
}: TCaseOpinionsMembersItemProps) => {
  const { t } = useTranslation()

  const accountName = isMyAccount ? t('cases.details.opinions.myOpinionLabel') : displayUserName

  const collapsed = openOpinionMemberId !== memberId

  useEffect(() => {
    if (isMyAccount) {
      setOpenedOpinion({ memberId })
    }
  }, [memberId, setOpenedOpinion, isMyAccount])

  return (
    <Skeleton
      loading={loading}
      active={true}
      avatar={{ size: 'small', shape: 'circle' }}
      paragraph={false}
      title={true}
      className={styles.skeleton}
    >
      <CollapsePanel
        {...rest}
        className={styles.collapseRoot}
        key={memberId}
        header={
          <div className={styles.header}>
            <AvatarContainer
              type={type?.name}
              firstName={firstName}
              lastName={lastName}
              userId={memberId}
              displayUserName={displayUserName}
              size={EAvatarSize.XXS}
            />

            <div className={styles.author}>
              <div className={styles.authorName}>
                <TruncatedText
                  classes={{ text: cls(styles.truncated, styles.truncatedAccount) }}
                  text={accountName}
                />
                <CaseDocumentOpinionItemBadgeContainer
                  userId={memberId}
                  className={styles.badge}
                  gutter={true}
                />
              </div>
              {!collapsed && type?.name === AccountTypeNames.BUSINESS && isMyAccount && (
                <TruncatedText
                  classes={{ text: cls(styles.truncated, styles.truncatedCreator) }}
                  text={opinionCreator}
                />
              )}
            </div>

            {!collapsed && <CaseDetailsDate date={createdAt} className={styles.date} />}
          </div>
        }
      >
        <CaseOwnerOpinionsContainer memberId={memberId} />
      </CollapsePanel>
    </Skeleton>
  )
}
