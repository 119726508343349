import { Ref } from 'react'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { CaseDetailsDate, CaseDetailsInfo, NewDividerLine } from 'App/components'
import { TCaseDescriptionItemContainerProps } from 'App/containers'

import styles from './CaseDescriptionItem.module.scss'

export type TCaseDescriptionItemBaseProps = {
  newDividerLineRef: Ref<HTMLDivElement>
}

export type TCaseDescriptionItemProps = TCaseDescriptionItemContainerProps &
  TCaseDescriptionItemBaseProps

export const CaseDescriptionItem = ({
  createdAt,
  data,
  loading,
  newDividerLineRef,
  showDividerLine
}: TCaseDescriptionItemProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <Skeleton
        loading={loading}
        active={true}
        paragraph={{ rows: 3 }}
        title={{ width: '25%' }}
        className={styles.skeleton}
      >
        {showDividerLine && !loading && (
          <NewDividerLine classNames={styles.divider} ref={newDividerLineRef}>
            {t('cases.details.description.newDescriptionDivider')}
          </NewDividerLine>
        )}
        <CaseDetailsDate date={createdAt} />
        <CaseDetailsInfo data={data} />
      </Skeleton>
    </div>
  )
}
