import { useState } from 'react'
import noop from 'lodash/noop'

import { useQuery, QueryFunction } from 'services/query'
import { PAGINATION_DEFAULT } from 'types'
import { TGetCommunityMembersPayload, getCommunityMembers } from 'api'
import { ICommunityMemberDTO } from 'interfaces'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

type TUseCommunityCreateChannelMembersStepRequest = Pick<
  TGetCommunityMembersPayload,
  'markChatIds'
> & {
  communityId: string
}

export const useCommunityCreateChannelMembersStepRequest = ({
  communityId,
  markChatIds
}: TUseCommunityCreateChannelMembersStepRequest) => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(0)
  const [total, setTotal] = useState(0)

  const queryFn: QueryFunction<ICommunityMemberDTO[]> = async () => {
    const data = await getCommunityMembers({
      page,
      search,
      communityId,
      showBy: PAGINATION_DEFAULT.showBy,
      markChatIds
    })

    setTotal(data.total)

    return data.results
  }

  const {
    data: result,
    isInitialLoading,
    error
  } = useQuery({
    queryKey: ['text-channel-select-members', page, search],
    queryFn,
    cacheTime: 0,
    onError: noop,
    select: (data) =>
      data.map<TAccountsSelectOptionData>(
        ({ account: { firstName, lastName, displayUserName, id, type } }) => ({
          id,
          firstName,
          lastName,
          type,
          displayUserName
        })
      )
  })

  return {
    showBy: PAGINATION_DEFAULT.showBy,
    results: result ?? [],
    total,
    loading: isInitialLoading,
    search,
    page,
    error,
    handleSearch: setSearch,
    setPage
  }
}
