import { useCallback } from 'react'
import { ChatTypeEnum, ErrorCodesEnum } from '@medentee/enums'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { isMatchErrorCode } from 'utils'
import {
  accountIdSelector,
  getChatRoomsRequest,
  hideModalAction,
  isBusinessAccountSelector,
  getCommunityBasePathSelector,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { toast } from 'App/components/ToastContainer'
import { TChannelVariant } from 'interfaces'
import { createCommunityChannel, editCommunityChannel } from 'api/communities'

export type TUseCommunityCreateChannelMainStepRequest = {
  communityId: string
  channelMembers: TAccountsSelectOptionData[]
  variant: Extract<TChannelVariant, 'chat' | 'news'>

  channelManager?: TAccountsSelectOptionData
  channelId?: string
  isEdit?: boolean
  isLeaderInDepartments?: boolean
  onEditSuccess?: () => void
}

const MUTATION_KEY = 'create-text-channel'
const MUTATION_KEY_EDIT = 'edit-text-channel'

export const useCommunityCreateChannelMainStepRequest = ({
  communityId,
  channelMembers,
  channelManager,
  isEdit,
  channelId,
  variant,
  isLeaderInDepartments,
  onEditSuccess
}: TUseCommunityCreateChannelMainStepRequest) => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector(accountIdSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const communityPath = useAppSelector(getCommunityBasePathSelector(communityId))

  const { push } = useHistory()

  const { t } = useTranslation()

  const handleSubmitError = useCallback(
    (error) => {
      if (isMatchErrorCode(error, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        dispatch(hideModalAction())

        toast.warn(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    },
    [dispatch, t]
  )

  const {
    error: editError,
    isLoading: editLoading,
    mutate: edit
  } = useMutation({
    mutationKey: [MUTATION_KEY_EDIT],
    mutationFn: editCommunityChannel,
    onError: handleSubmitError,
    onSuccess: () => {
      dispatch(hideModalAction())
      dispatch(
        getChatRoomsRequest({
          communityId,
          chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS]
        })
      )

      onEditSuccess && onEditSuccess()
    }
  })

  const { error, isLoading, mutate } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: createCommunityChannel,
    onError: handleSubmitError,
    onSuccess: ({ id }) => {
      dispatch(hideModalAction())
      dispatch(
        getChatRoomsRequest({
          communityId,
          chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS],
          onSuccess: () => {
            push(`${communityPath}/${variant}/${id}`)
          }
        })
      )
    }
  })

  const handleFormSubmit = useCallback(
    (values: { name: string; isPublic: boolean }) => {
      const accountIds = !values.isPublic ? channelMembers.map(({ id }) => id) : []
      const channelManagerId = channelManager?.id

      if (isLeaderInDepartments && !isEdit && accountId) {
        accountIds.push(accountId)
      }

      if (isEdit && channelId) {
        edit({
          ...values,
          communityId,
          accountIds,
          channelManagerId: channelManagerId ? channelManagerId : null,
          channelId
        })
        return
      }

      mutate({
        ...values,
        communityId,
        accountIds,
        channelManagerId,
        variant
      })
    },
    [
      channelMembers,
      channelManager?.id,
      communityId,
      variant,
      isEdit,
      channelId,
      isLeaderInDepartments,
      accountId,
      mutate,
      edit
    ]
  )

  const isFieldError = isMatchErrorCode(error || editError, [
    ErrorCodesEnum.TEXT_CHANNEL_ALREADY_EXIST,
    ErrorCodesEnum.VOICE_CHANNEL_ALREADY_EXIST,
    ErrorCodesEnum.CHANNEL_MANAGER_MUST_BE_ACTIVE_MEMBER
  ])

  return {
    isBusinessAccount,
    error: error || editError,
    processing: isLoading || editLoading,
    isFieldError,
    handleFormSubmit
  }
}
