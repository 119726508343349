import { useCallback, useMemo, useState } from 'react'
import { FinancialOperationTypeEnum } from '@medentee/enums'
import { FilterValue } from 'antd/lib/table/interface'
import { useTranslation } from 'react-i18next'

import { createSelectorOptions, stopPropagation } from 'utils'
import { useAdaptiveLayout } from 'App/hooks'
import { TOperationsHistoryContainerProps } from 'App/containers'
import {
  EFilterTypes,
  FilterPopover,
  FilterPopoverContent,
  TFilterValue,
  useFilters,
  TFilterItem,
  TFilterPopoverContentProps
} from 'App/components'
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg'
import { TPaymentsHistoryFilters } from 'store'

import styles from './OperationsHistory.module.scss'

export type TUseFilterProps = Pick<
  TOperationsHistoryContainerProps,
  'setOperationsHistoryFilter' | 'selectedFilters'
>

type TTableFilter = Pick<TFilterPopoverContentProps, 'contentType'> & {
  filterKey: keyof TPaymentsHistoryFilters
}

export const useFilter = ({ selectedFilters, setOperationsHistoryFilter }: TUseFilterProps) => {
  const { isDesktop, isMobile } = useAdaptiveLayout()

  const [filterVisible, setFilterVisible] = useState<Record<string, boolean>>({})

  const { t } = useTranslation()

  const handleVisible = (key: string) => (value: boolean) => {
    setFilterVisible((prev) => ({ ...prev, [key]: value }))
  }

  const close = useCallback((key: string) => {
    setFilterVisible((prev) => ({ ...prev, [key]: false }))
  }, [])

  const handleResetFilter = useCallback(() => {
    setOperationsHistoryFilter(null)
  }, [setOperationsHistoryFilter])

  const handleChangeFilter = useCallback(
    (key: string, value: TFilterValue) => {
      setOperationsHistoryFilter({ [key]: value })
      if (isDesktop) {
        close(key)
      }
    },
    [close, isDesktop, setOperationsHistoryFilter]
  )

  const typeOptions = useMemo(
    () =>
      createSelectorOptions(
        [
          FinancialOperationTypeEnum.SUBSCRIPTION,
          FinancialOperationTypeEnum.BONUS,
          FinancialOperationTypeEnum.TRANSFER
        ],
        (value) => t(`enum.financialOperationTypeEnum.${value}`)
      ),
    [t]
  )

  const filters = useMemo<TFilterItem[]>(
    () => [
      {
        title: t('payments.operationHistory.filters.date'),
        sectionKey: 'date',
        items: [
          {
            filterKey: 'dateRange',
            type: EFilterTypes.DATE
          }
        ]
      },
      {
        title: t('payments.operationHistory.filters.type'),
        sectionKey: 'type',
        items: [
          {
            filterKey: 'operationType',
            type: EFilterTypes.CHECKBOX,
            options: typeOptions
          }
        ]
      }
    ],
    [t, typeOptions]
  )

  const { filters: filtersComponent } = useFilters({
    value: selectedFilters,
    buttonVariant: isMobile ? 'inline' : 'inline-filled',
    classes: { filterButton: styles.filterButton },
    shouldHideAppliedFilter: !isMobile,
    popover: isDesktop,
    filters,
    onReset: handleResetFilter,
    onChange: handleChangeFilter
  })

  const getFilterIcon = useCallback(
    ({ contentType, filterKey }: TTableFilter) => (
      <div onClick={stopPropagation} className={styles.filterContainer}>
        {isDesktop ? (
          <FilterPopover
            visible={filterVisible[filterKey]}
            onVisibleChange={handleVisible(filterKey)}
            content={
              <FilterPopoverContent
                filterKey={filterKey}
                contentType={contentType}
                onChange={handleChangeFilter}
                value={selectedFilters[filterKey]}
                filters={typeOptions}
              />
            }
          >
            <FilterIcon className={styles.filterIcon} />
          </FilterPopover>
        ) : (
          filtersComponent
        )}
      </div>
    ),
    [isDesktop, filterVisible, handleChangeFilter, selectedFilters, typeOptions, filtersComponent]
  )

  const getTableFilter = useCallback(
    ({ filterKey, contentType }: TTableFilter) => ({
      filterIcon: getFilterIcon({ filterKey, contentType }),
      filteredValue: (selectedFilters[filterKey] ?? []) as FilterValue,
      filters: []
    }),
    [getFilterIcon, selectedFilters]
  )

  return { getTableFilter, filtersComponent }
}
