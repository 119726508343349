import {
  call,
  cancel,
  debounce,
  join,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects'
import uniq from 'lodash/uniq'
import {
  MessageTypeEnum,
  FilePermissionsIdsEnum,
  FileHistorySourcesEnum,
  CaseTypesEnum,
  MeetingStatusEnum,
  ChatTypeEnum,
  ErrorCodesEnum
} from '@medentee/enums'
import { differenceInMinutes } from 'date-fns'
import { Task } from 'redux-saga'
import { Trans } from 'react-i18next'

import { toast } from 'App/components/ToastContainer'
import {
  wrapperForSocketEmit,
  ESocketNameSpaces,
  ESocketEmitEventNamesChat
} from 'services/webSocket'
import { api, API, APIData, APIResultsResponse } from 'services/api'
import history from 'utils/history'
import { downloadFileFromUrl, isMatchErrorCode, QueryBuilder, sort } from 'utils'
import {
  EHistoryType,
  toastDefaultOptions,
  DEFAULT_CHAT_MESSAGES_LIMIT,
  DEFAULT_THROTTLE_MS
} from 'globalConstants'
import { State } from 'redux/rootReducer'
import { NotificationsP2PDetailsLinkToCase } from 'App/components/Notifications/NotificationsP2PDetailsLinkToCase'
import {
  chatMessagesNormalize,
  DELETE_ALL_MESSAGES_REQUEST,
  deleteAllMessagesError,
  deleteAllMessagesSuccess,
  deleteFailedMessageAction,
  END_MESSAGE_EDITING_REQUEST,
  endMessageEditingError,
  endMessageEditingSuccess,
  generateFakeMessage,
  GET_MESSAGES_HISTORY_ERROR,
  GET_MESSAGES_HISTORY_REQUEST,
  GET_MESSAGES_HISTORY_SUCCESS,
  getMessagesHistoryError,
  getMessagesHistoryRequest,
  getMessagesHistorySuccess,
  hideModalAction,
  isChatActionOfType,
  READ_ALL_MESSAGES_REQUEST,
  READ_MESSAGES_REQUEST,
  readAllMessagesRequest,
  readAllMessagesSuccess,
  readMessagesError,
  readMessagesSuccess,
  RECEIVE_NEW_MESSAGE_SUCCESS,
  REDIRECT_TO_CASE_FROM_CHAT,
  SEND_NEW_AUDIO_MESSAGE_REQUEST,
  SEND_NEW_MESSAGE_REQUEST,
  sendNewMessageError,
  sendNewMessageSuccess,
  updateChatRoomAction,
  setChatMessages,
  START_MESSAGE_EDITING_REQUEST,
  startMessageEditingError,
  startMessageEditingSuccess,
  TAccountData,
  TChatBaseMessage,
  TChatMessagesNormalized,
  TDeleteAllMessagesRequest,
  TEndMessageEditingRequest,
  TGetMessagesHistoryRequest,
  TReadAllMessagesRequest,
  TReadMessagesRequest,
  TReceiveNewMessageSuccess,
  TReceiveNewMessageRequest,
  TRedirectToCaseFromChat,
  TSendNewAudioMessageRequest,
  TSendNewMessageRequest,
  TStartMessageEditingRequest,
  TDeleteMessagePermanentlyRequest,
  deleteMessagePermanentlySuccess,
  DELETE_MESSAGE_PERMANENTLY_REQUEST,
  deleteMessagePermanentlyError,
  CONNECT_WS_STATUS,
  DOWNLOAD_FILE_FROM_CHAT_REQUEST,
  sendNewMessageRequest,
  setFailedMessages,
  TSendNewMessagePayload,
  TIds,
  historyTypeEventsMap,
  TFileDiscardPermissionFromChatRequest,
  fileDiscardPermissionFromChatError,
  fileDiscardPermissionFromChatSuccess,
  FILE_DISCARD_PERMISSION_FROM_CHAT_REQUEST,
  TFileRevokePermissionFromChatRequest,
  fileRevokePermissionFromChatError,
  fileRevokePermissionFromChatSuccess,
  FILE_REVOKE_PERMISSION_FROM_CHAT_REQUEST,
  RECEIVE_CHAT_INTERMEDIATE_STATE,
  setChatIntermediateStateAction,
  TChatRoomsState,
  setVideoStreamingData,
  videoStreamingNormalize,
  TMeeting,
  TChatHistoryEvent,
  getFilesCountsRequest,
  getChatRoomsRequest,
  TChatRoomsFilters,
  accountIdSelector,
  chatMessagesReactionsNormalize,
  TChatRoomsType,
  quoteMessageSuccess
} from 'store'
import { EChatHistoryEvents, EChatMessageAction } from 'enums'
import { receiveMessageSoundBase64 } from 'assets/sounds/receiveMessageSound'
import { IChatRoomsDTO } from 'interfaces'
import { getChatRoomMessages } from 'api/chats'
import { handleError, handleWarning } from 'api/utils'

import { handleDefaultError } from '../../utils/toast'
import i18n from '../../i18n'

import {
  TChatDraft,
  TChatHistoryMessage,
  TChatMessageActions,
  TDownloadFileFromChatRequest,
  TReceiveChatIntermediateStateAction
} from './chatMessages.types'
import {
  quoteMessageCancel,
  endMessageEditingRequest,
  receiveNewMessageSuccess,
  RECEIVE_NEW_MESSAGE_REQUEST,
  updateChatDraftRequest,
  updateChatDraftError,
  updateChatDraftSuccess,
  UPDATE_CHAT_DRAFT_REQUEST,
  getChatDraftRequest,
  GET_CHAT_DRAFT_REQUEST,
  getChatDraftError,
  getChatDraftSuccess,
  getChatDraftCancel,
  QUOTE_MESSAGE_REQUEST,
  quoteMessageRequest,
  QUOTE_MESSAGE_CANCEL,
  setChatHistoryEventAction,
  downloadFileFromChatSuccess,
  downloadFileFromChatError,
  DELETE_FILE_FROM_CHAT_REQUEST,
  deleteFileFromChatSuccess,
  deleteFileFromChatError,
  deleteFileFromChatRequest,
  REMOVE_MESSAGE_REACTION_REQUEST,
  ADD_MESSAGE_REACTION_REQUEST,
  addMessageReactionRequest,
  addMessageReactionSuccess,
  addMessageReactionError,
  removeMessageReactionRequest,
  removeMessageReactionError,
  removeMessageReactionSuccess,
  setMessagesReactionsAction,
  RECEIVE_ADD_MESSAGE_REACTION_REQUEST,
  RECEIVE_REMOVE_MESSAGE_REACTION_REQUEST,
  receiveAddMessageReactionRequest,
  receiveRemoveMessageReactionRequest,
  receiveRemoveMessageReactionSuccess,
  receiveAddMessageReactionSuccess
} from './chatMessages.actions'

const MEETING_STARTS_IN = 10

function* readAllMessagesSaga({
  payload: { chatId, sender, data, chatHistoryEvent, fakeMessageId, receiverId }
}: TReadAllMessagesRequest) {
  yield put(getMessagesHistoryRequest({ chatId }))

  const { error } = yield race({
    newRequest: take(GET_MESSAGES_HISTORY_REQUEST),
    success: take(isChatActionOfType(chatId, GET_MESSAGES_HISTORY_SUCCESS)),
    error: take(isChatActionOfType(chatId, GET_MESSAGES_HISTORY_ERROR))
  })

  if (error) {
    yield cancel()
  }

  yield put(
    readAllMessagesSuccess({ chatId, sender, data, fakeMessageId, chatHistoryEvent, receiverId })
  )
}

function* sendNewAudioMessageSaga({ payload }: TSendNewAudioMessageRequest) {
  const sender: TAccountData = yield select((state: State) => state.global.accountData)
  const { chatId, message, quoteId, readAll, messageId, caseId, receiverId } = payload

  const fakeMessageId = `${-new Date().getTime()}`

  try {
    if (messageId !== undefined) {
      yield put(
        deleteFailedMessageAction({
          messageId
        })
      )
    }

    const currentMessages: TChatMessagesNormalized = yield select(
      (state: State) => state.chat.chatMessages.messages
    )

    yield put(
      setChatMessages({
        ids: [...currentMessages.ids, fakeMessageId],
        list: {
          ...currentMessages.list,
          [fakeMessageId]: {
            ...generateFakeMessage({
              messageId: fakeMessageId,
              messageType: MessageTypeEnum.AUDIO,
              quoteMessage: quoteId ? currentMessages.list[quoteId] : null,
              message,
              chatId,
              sender,
              originalSender: sender
            })
          }
        }
      })
    )

    const formData = new FormData()

    yield formData.append('file', message)
    yield formData.append('tempId', fakeMessageId)

    if (quoteId) {
      yield formData.append('quoteId', quoteId)
    }

    if (caseId) {
      yield formData.append('caseId', caseId)
    }

    const postTask: Task = yield fork(api.post, API.SEND_CHAT_AUDIO_MESSAGE(chatId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    const response: TReceiveNewMessageSuccess = yield take(
      isChatActionOfType(chatId, RECEIVE_NEW_MESSAGE_SUCCESS)
    )

    yield join(postTask)

    const data = response?.payload

    if (!readAll) {
      yield put(
        sendNewMessageSuccess({
          data: data.message,
          sender,
          fakeMessageId,
          chatHistoryEvent: { type: EChatHistoryEvents.SEND_MESSAGE },
          receiverId
        })
      )
    } else {
      yield put(
        readAllMessagesRequest({
          chatId,
          data: data.message,
          sender,
          fakeMessageId,
          chatHistoryEvent: { type: EChatHistoryEvents.SEND_MESSAGE },
          receiverId
        })
      )
    }
  } catch {
    yield put(
      sendNewMessageError({
        messageId: fakeMessageId
      })
    )
  } finally {
    if (quoteId) {
      yield put(quoteMessageCancel({ chatId, message: '' }))
    }
    yield put(updateChatDraftRequest({ chatId, message: '', quoteMessage: null }))
  }
}

function* sendNewMessageSaga({ payload }: TSendNewMessageRequest) {
  const sender: TAccountData = yield select((state: State) => state.global.accountData)
  const failedMessages: TSendNewMessagePayload[] = yield select(
    (state: State) => state.chat.chatMessages.failedMessages
  )
  const { chatId, readAll, quoteId, message, messageId, receiverId } = payload

  const fakeMessageId = `${-new Date().getTime()}`

  try {
    if (messageId !== undefined) {
      yield put(
        deleteFailedMessageAction({
          messageId
        })
      )
    }

    const currentMessages: TChatMessagesNormalized = yield select(
      (state: State) => state.chat.chatMessages.messages
    )

    yield put(
      setChatMessages({
        ids: [...currentMessages.ids, fakeMessageId],
        list: {
          ...currentMessages.list,
          [fakeMessageId]: {
            ...generateFakeMessage({
              messageId: fakeMessageId,
              messageType: MessageTypeEnum.TEXT,
              quoteMessage: quoteId ? currentMessages.list[quoteId] : null,
              message,
              chatId,
              sender,
              originalSender: sender
            })
          }
        }
      })
    )

    yield put(setChatHistoryEventAction({ type: EChatHistoryEvents.SEND_MESSAGE }))

    yield put(setFailedMessages([...failedMessages, { ...payload, messageId: fakeMessageId }]))

    const data: TChatBaseMessage = yield wrapperForSocketEmit<typeof payload, TChatBaseMessage>(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.SEND_NEW_MESSAGE,
      {
        ...payload,
        tempId: fakeMessageId
      }
    )

    yield put(setFailedMessages([...failedMessages]))

    if (readAll) {
      yield put(
        readAllMessagesRequest({
          chatId,
          data,
          sender,
          fakeMessageId,
          chatHistoryEvent: { type: EChatHistoryEvents.SEND_MESSAGE },
          receiverId
        })
      )
    }
  } catch {
    yield put(
      sendNewMessageError({
        messageId: fakeMessageId
      })
    )
  } finally {
    if (quoteId) {
      yield put(quoteMessageCancel({ chatId, message: '' }))
    }
    yield put(updateChatDraftRequest({ chatId, message: '', quoteMessage: null }))
  }
}

function* setReactions(data: TChatBaseMessage[]) {
  const currentAccountId: string = yield select(accountIdSelector)

  const { accountList, outgoingList, messageList } = yield call(
    chatMessagesReactionsNormalize,
    data,
    currentAccountId
  )

  yield put(setMessagesReactionsAction({ messageList, accountList, outgoingList }))
}

function* getHistoryMessagesSaga({
  payload: { chatId, limit, historyType, messageId, onlyPinned, searchQuery }
}: TGetMessagesHistoryRequest) {
  try {
    const messagesIds: TIds = yield select((state: State) => state.chat.chatMessages.messages.ids)

    const currentMessages: TChatMessagesNormalized = yield select(
      (state: State) => state.chat.chatMessages.messages
    )

    let newLineMessageId: string | null = yield select(
      (state: State) => state.chat.chatMessages.newLineMessageId
    )

    const chatHistoryEvent: TChatHistoryEvent = {
      type: historyType ? historyTypeEventsMap[historyType] : EChatHistoryEvents.HISTORY_FIRST
    }

    if (
      messagesIds.length < DEFAULT_CHAT_MESSAGES_LIMIT / 2 &&
      historyType &&
      historyType !== EHistoryType.AROUND
    ) {
      yield put(
        getMessagesHistorySuccess({
          newLineMessageId,
          list: currentMessages.list,
          ids: currentMessages.ids,
          chatHistoryEvent,
          hasBefore: false,
          hasAfter: false,
          chatId
        })
      )
      yield cancel()
    }

    const { results }: APIResultsResponse<TChatBaseMessage[]>['data'] = yield call(
      getChatRoomMessages,
      {
        chatId,
        limit,
        historyType,
        messageId,
        onlyPinned,
        searchQuery
      }
    )

    let { list, ids } = chatMessagesNormalize(results)

    const files = []

    for (const id of ids) {
      const message = list[id]

      if (message.type === MessageTypeEnum.FILE && message.file) {
        files.push(message.file)
      }
    }

    yield put(setVideoStreamingData(videoStreamingNormalize(files)))

    const sortedIds = sort(ids)

    if (sortedIds.some((id) => list[id].recipientRead)) {
      const unreadMessageId = sortedIds.find((id) => !list[id].recipientRead)

      newLineMessageId = unreadMessageId ?? newLineMessageId
    }

    const hasBefore: boolean = yield select((state: State) =>
      historyType && (historyType === EHistoryType.BEFORE || historyType === EHistoryType.AROUND)
        ? !!results.length
        : state.chat.chatMessages.hasBefore
    )
    const hasAfter: boolean = yield select((state: State) =>
      historyType && (historyType === EHistoryType.AFTER || historyType === EHistoryType.AROUND)
        ? !!results.length
        : state.chat.chatMessages.hasAfter
    )

    if (historyType === EHistoryType.BEFORE || historyType === EHistoryType.AFTER) {
      list = { ...currentMessages.list, ...list }
      ids = sort(uniq([...currentMessages.ids, ...ids]))
    }

    const targetMessage = results.find(({ id }) => id === messageId)

    if (chatHistoryEvent.type === EChatHistoryEvents.JUMP && targetMessage) {
      chatHistoryEvent.payload = {
        messageId: targetMessage.id,
        eventTs: new Date().getTime()
      }
    }

    yield fork(setReactions, results)

    yield put(
      getMessagesHistorySuccess({
        newLineMessageId,
        list,
        ids,
        chatHistoryEvent,
        hasBefore,
        hasAfter,
        chatId
      })
    )

    const { list: chatRoomsList, selectedChat }: TChatRoomsState = yield select(
      (state: State) => state.chat.chatRooms
    )

    if (
      historyType !== EHistoryType.BEFORE &&
      ids.length &&
      !hasAfter &&
      selectedChat?.id === chatId
    ) {
      const lastMessage = list[ids[ids.length - 1]]

      yield put(
        updateChatRoomAction({
          list: {
            ...chatRoomsList,
            [chatId]: {
              ...chatRoomsList[chatId],
              lastMessage
            }
          },
          selectedChat: {
            ...selectedChat,
            lastMessage
          }
        })
      )
    }
  } catch (e) {
    yield put(getMessagesHistoryError({ chatId, error: e }))

    if (isMatchErrorCode(e, ErrorCodesEnum.CHAT_ROOM_NOT_FOUND)) {
      const { page, showBy }: TChatRoomsFilters = yield select(
        (state: State) => state.chat.chatRooms.filters
      )
      const caseId: string = yield select((state: State) => state.caseView.data?.id)

      yield put(getChatRoomsRequest({ page, showBy, caseId }))
    }

    yield call(handleDefaultError, e)
  }
}

function* readMessagesSaga({ payload: { chatId, messageId } }: TReadMessagesRequest) {
  try {
    const read: boolean = yield wrapperForSocketEmit(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.READ_MESSAGE,
      {
        chatId,
        messageIds: [messageId]
      }
    )

    if (read) {
      yield put(
        readMessagesSuccess({
          messageIds: [messageId],
          readByYou: true,
          chatId
        })
      )
    }
  } catch (e) {
    yield put(readMessagesError({ messageId, error: e }))
    handleError(e)
  }
}

function* receiveMessagePlayAudio({ payload }: TReceiveNewMessageSuccess) {
  const { chatId, type, yourMessage, chat, createdAt, updatedAt } = payload.message

  const browserFocus: boolean = yield select((state: State) => state.global.browserFocus)
  const muteNotifications: boolean = yield select(
    (state: State) => state.global.accountData?.muteNotifications ?? false
  )
  const selectedChatId: string | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat?.id
  )

  const isUpdated = createdAt !== updatedAt
  const isChatRoomMuted = Boolean(chat?.mutedUntil)

  const play = () => {
    const audio = document.createElement('audio')
    audio.src = receiveMessageSoundBase64

    audio.play()
  }

  if (
    !isUpdated &&
    !muteNotifications &&
    !isChatRoomMuted &&
    (!browserFocus || selectedChatId !== chatId) &&
    type !== MessageTypeEnum.SYSTEM &&
    !yourMessage
  ) {
    play()
  }
}

function* redirectToCaseFromChat({
  payload: { caseId, ownerId, chatId, caseType, shouldOpenChat = false }
}: TRedirectToCaseFromChat) {
  try {
    const { id } = yield select((state: State) => state.global.accountData)
    const isOwner = id === ownerId

    yield call(api.get, API.CASE(caseId))

    if (shouldOpenChat && caseType === CaseTypesEnum.A2A) {
      yield history.push(`/cases/${caseId}/${isOwner ? 'owner' : 'member'}?chatId=${chatId}`)
    } else {
      yield history.push(
        isOwner ? `/cases/${caseId}/owner?chatId=${chatId}` : `/cases/${caseId}/member`
      )
    }
  } catch (e) {
    yield isMatchErrorCode(e, [
      ErrorCodesEnum.CASE_NOT_FOUND,
      ErrorCodesEnum.IS_NOT_ACTIVE_CASE_MEMBER
    ])
      ? toast.warn(i18n.t('common.toast.unavailableCase'), toastDefaultOptions)
      : handleError(e)
  }
}

function* startMessageEditingSaga({ payload }: TStartMessageEditingRequest) {
  try {
    const { type, message }: TChatMessageActions = yield select(
      (state: State) => state.chat.chatMessages.actions
    )

    yield put(updateChatDraftRequest({ chatId: payload.chatId, message: '', quoteMessage: null }))

    if (type === EChatMessageAction.EDIT && message) {
      yield put(endMessageEditingRequest({ chatId: message?.chatId, messageId: message?.id }))
    }

    yield wrapperForSocketEmit(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.EDITING_STARTED,
      payload
    )

    yield put(startMessageEditingSuccess(payload))
  } catch (e) {
    yield put(startMessageEditingError(e))
    handleError(e)
  }
}

function* endMessageEditingSaga({ payload }: TEndMessageEditingRequest) {
  try {
    yield wrapperForSocketEmit(
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.EDITING_ENDED,
      payload
    )

    yield put(endMessageEditingSuccess(payload))
  } catch (e) {
    yield put(endMessageEditingError(e))
    handleError(e)
  }
}

function* deleteAllMessagesSaga({ payload }: TDeleteAllMessagesRequest) {
  const { chatId } = payload

  try {
    yield call(api.delete, API.CHAT_CLEAR_ALL_MESSAGES(chatId))

    const { list: chatRoomsList, selectedChat }: TChatRoomsState = yield select(
      (state: State) => state.chat.chatRooms
    )

    if (selectedChat?.id === chatId) {
      yield put(
        updateChatRoomAction({
          list: {
            ...chatRoomsList,
            [chatId]: {
              ...chatRoomsList[chatId],
              lastMessage: undefined,
              clearedUnixTs: Date.now()
            }
          },
          selectedChat: {
            ...selectedChat,
            lastMessage: undefined,
            clearedUnixTs: Date.now()
          }
        })
      )
    }

    yield put(hideModalAction())

    yield put(deleteAllMessagesSuccess())
  } catch (e) {
    yield put(deleteAllMessagesError(e))
  }
}

function* deleteMessagePermanentlySaga({ payload }: TDeleteMessagePermanentlyRequest) {
  try {
    yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.REMOVE_MESSAGE,
      payload
    )

    yield put(deleteMessagePermanentlySuccess(payload))

    if (payload.onSuccess) {
      yield call(payload.onSuccess)
    }
  } catch (e) {
    handleError(e)
    yield put(deleteMessagePermanentlyError({ ...e, processingId: payload.processingId }))
  }
}

function* sendFailedMessagesSaga() {
  const failedMessages: TSendNewMessagePayload[] = yield select(
    (state: State) => state.chat.chatMessages.failedMessages
  )

  yield put(setFailedMessages([]))

  for (const item of failedMessages) {
    yield put(sendNewMessageRequest(item))
  }
}

function* fileDiscardPermissionFromChatSaga({
  payload: { fileId, onError, onSuccess }
}: TFileDiscardPermissionFromChatRequest) {
  try {
    yield call(api.delete, API.FILE_DISCARD_PERMISSIONS(fileId))

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(hideModalAction())
    yield put(
      fileDiscardPermissionFromChatSuccess({
        fileIds: [fileId]
      })
    )
  } catch (e) {
    if (onError) {
      yield call(onError)
    }

    yield put(fileDiscardPermissionFromChatError(e))
  }
}

function* fileRevokePermissionFromChatSaga({
  payload: { fileId, onSuccess }
}: TFileRevokePermissionFromChatRequest) {
  try {
    const { id: userId } = yield select(
      (state: State) => state.chat.chatRooms.selectedChat?.interlocutorAccount
    )

    yield call(api.patch, API.FILE_CONTACT_CHANGE_PERMISSION(fileId), {
      permissionId: FilePermissionsIdsEnum.OPEN,
      enabled: false,
      userId
    })

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(hideModalAction())
    yield put(
      fileRevokePermissionFromChatSuccess({
        fileIds: [fileId]
      })
    )
  } catch (e) {
    yield put(fileRevokePermissionFromChatError(e))
  }
}

function* downloadFileSaga({ payload }: TDownloadFileFromChatRequest) {
  try {
    const { fileId, originalEntityId } = payload

    const checkDownloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('checkRightsOnly', 'true')
      .build()
    const downloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('source', FileHistorySourcesEnum.P2P_CLOUD)
      .custom('originalEntityId', originalEntityId)
      .build()

    yield call(api.get, checkDownloadUrl)

    const { data }: APIData<string> = yield call(api.get, downloadUrl)

    yield downloadFileFromUrl({ content: data })
    yield put(downloadFileFromChatSuccess())
  } catch (e) {
    yield put(downloadFileFromChatError(e))

    yield isMatchErrorCode(e, [
      ErrorCodesEnum.FILE_NOT_ENOUGH_ACCESS_RIGHTS,
      ErrorCodesEnum.FILE_NOT_FOUND
    ])
      ? handleWarning(e)
      : handleError(e)
  }
}

function* receiveChatIntermediateState({
  payload: { editingIds }
}: TReceiveChatIntermediateStateAction) {
  try {
    yield put(setChatIntermediateStateAction({ editingIds }))
  } catch (e) {
    handleError(e)
  }
}

function* showMeetingOpenNotification({ title, invites, organizer, case: caseData }: TMeeting) {
  const accountId: string = yield select((state: State) => state.global.accountData?.id)

  const interlocutor =
    accountId === organizer?.id ? invites[0]?.account.displayUserName : organizer?.displayUserName

  if (caseData) {
    toast.info(
      <Trans
        t={i18n.t}
        i18nKey="common.toast.caseMeetingAnnouncement"
        components={{
          caseLabel: (
            <NotificationsP2PDetailsLinkToCase
              caseId={caseData.id}
              caseTitle={caseData.title}
              isCaseOwner={organizer?.id === accountId}
            />
          )
        }}
        values={{ title, time: MEETING_STARTS_IN }}
      />,
      {
        ...toastDefaultOptions,
        closeOnClick: false,
        autoClose: undefined
      }
    )

    return
  }

  toast.info(
    <Trans
      t={i18n.t}
      i18nKey="common.toast.contactMeetingAnnouncement"
      values={{ title, time: MEETING_STARTS_IN, name: interlocutor }}
    />,
    toastDefaultOptions
  )
}

function* receiveNewMessageSaga({ payload }: TReceiveNewMessageRequest) {
  try {
    if (payload.type === MessageTypeEnum.FILE && payload.file?.videoMetadata) {
      yield put(
        setVideoStreamingData({
          [payload.file.id]: {
            videoMetadata: payload.file.videoMetadata,
            bunnyNetMetadata: payload.file.bunnyNetMetadata
          }
        })
      )
    }

    if (
      payload.meeting &&
      payload.type === MessageTypeEnum.SYSTEM &&
      payload.meeting.status === MeetingStatusEnum.OPEN &&
      differenceInMinutes(new Date(payload.meeting.startOn), new Date(payload.updatedAt), {
        roundingMethod: 'round'
      }) === MEETING_STARTS_IN
    ) {
      yield call(showMeetingOpenNotification, payload.meeting)
    }

    const chatRoomIds: string[] = yield select((state: State) => state.chat.chatRooms.ids)
    const chatType: TChatRoomsType = yield select((state: State) => state.chat.chatRooms.chatType)

    if (
      !chatRoomIds.includes(payload.chatId) &&
      payload.chat?.type !== ChatTypeEnum.ORGANIZATION &&
      payload.chat?.type !== ChatTypeEnum.COMMUNITY_CHANNEL &&
      payload.chat?.type !== ChatTypeEnum.COMMUNITY_NEWS &&
      payload.chat?.type !== ChatTypeEnum.EVENT_CHANNEL &&
      payload.chat?.type !== ChatTypeEnum.EVENT_NEWS &&
      payload.chat?.type === chatType
    ) {
      const { data }: APIData<IChatRoomsDTO> = yield call(api.get, API.CHAT_ROOM(payload.chatId))

      yield put(receiveNewMessageSuccess({ message: payload, chat: data }))
    }

    yield put(receiveNewMessageSuccess({ message: payload }))
  } catch (e) {
    handleError(e)
  }
}

function* updateChatDraftSaga({
  payload: { chatId, message = '', quoteMessage = null }
}: ReturnType<typeof updateChatDraftRequest>) {
  try {
    const editing: boolean = yield select(
      (state: State) => state.chat.chatMessages.actions.type === EChatMessageAction.EDIT
    )

    const draftMessage = editing ? '' : message
    const draftQuoteMessage = editing ? null : quoteMessage

    const { data }: APIData<TChatDraft> = yield call(api.post, API.CHAT_DRAFT(chatId), {
      message: draftMessage,
      quoteMessageId: draftQuoteMessage?.id
    })

    yield put(updateChatDraftSuccess({ ...data, chatId }))
  } catch (e) {
    yield put(updateChatDraftError(e))
    yield handleError(e)
  }
}

function* getChatDraftSaga({ payload: { chatId } }: ReturnType<typeof getChatDraftRequest>) {
  try {
    const { data }: APIData<TChatDraft> = yield call(api.get, API.CHAT_DRAFT(chatId))

    const selectedChatId: string | null = yield select(
      (state: State) => state.chat.chatMessages.selectedChatId
    )

    if (selectedChatId !== chatId) {
      yield put(getChatDraftCancel())
      yield cancel()
    }

    const { list, selectedChat }: TChatRoomsState = yield select(
      (state: State) => state.chat.chatRooms
    )

    yield put(
      updateChatRoomAction({
        list: {
          ...list,
          [chatId]: {
            ...list[chatId],
            draft: data
          }
        },
        selectedChat: selectedChat
          ? {
              ...selectedChat,
              draft: data
            }
          : selectedChat
      })
    )

    yield put(getChatDraftSuccess(data))
  } catch (e) {
    yield put(getChatDraftError(e))

    yield call(handleDefaultError, e)
  }
}

function* quoteMessageSaga({
  payload: { quoteId, chatId }
}: ReturnType<typeof quoteMessageRequest>) {
  try {
    const { type, message: chatMessage }: TChatMessageActions = yield select(
      (state: State) => state.chat.chatMessages.actions
    )
    const message: string = yield select((state: State) => state.chat.chatMessages.draft.message)
    const quoteMessage: TChatHistoryMessage = yield select(
      (state: State) => state.chat.chatMessages.messages.list[quoteId]
    )

    if (type === EChatMessageAction.EDIT && chatMessage) {
      yield put(
        endMessageEditingRequest({ chatId: chatMessage?.chatId, messageId: chatMessage?.id })
      )
    }

    yield put(quoteMessageSuccess({ quoteId, chatId }))

    yield put(updateChatDraftRequest({ chatId, message, quoteMessage }))
  } catch (e) {
    yield handleError(e)
  }
}

function* quoteMessageCancelSaga({
  payload: { chatId, message }
}: ReturnType<typeof quoteMessageCancel>) {
  try {
    const draft: TChatDraft = yield select((state: State) => state.chat.chatMessages.draft)

    yield put(
      updateChatDraftRequest({ chatId, message: message ?? draft.message, quoteMessage: null })
    )
  } catch (e) {
    yield handleError(e)
  }
}

function* deleteFileFromChatSaga({
  payload: { chatId, fileIds, isChatInfo, onSuccess }
}: ReturnType<typeof deleteFileFromChatRequest>) {
  try {
    yield call(api.delete, API.CHAT_ATTACH_FILES(chatId), { data: { fileIds } })
    yield put(deleteFileFromChatSuccess())

    if (isChatInfo) {
      const accountId: string | undefined = yield select(
        (state: State) => state.global.accountData?.id
      )

      yield put(
        getFilesCountsRequest({
          ownerId: accountId,
          chatId
        })
      )
    }

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield handleError(e)
    yield put(deleteFileFromChatError(e))
  }
}

function* addMessageReactionSaga({
  payload: { chatId, messageId, reaction, processingId }
}: ReturnType<typeof addMessageReactionRequest>) {
  try {
    yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.ADD_MESSAGE_REACTION,
      {
        chatId,
        messageId,
        reaction
      }
    )

    yield put(addMessageReactionSuccess({ processingId }))
  } catch (e) {
    yield handleError(e)
    yield put(addMessageReactionError({ ...e, processingId }))
  }
}

function* removeMessageReactionSaga({
  payload: { chatId, messageId, processingId }
}: ReturnType<typeof removeMessageReactionRequest>) {
  try {
    yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CHAT,
      ESocketEmitEventNamesChat.REMOVE_MESSAGE_REACTION,
      {
        chatId,
        messageId
      }
    )

    yield put(removeMessageReactionSuccess({ processingId }))
  } catch (e) {
    yield handleError(e)
    yield put(removeMessageReactionError({ ...e, processingId }))
  }
}

function* receiveAddMessageReactionSaga({
  payload
}: ReturnType<typeof receiveAddMessageReactionRequest>) {
  try {
    const { message } = payload
    const selectedChatId: string | undefined = yield select(
      (state: State) => state.chat.chatRooms.selectedChat?.id
    )

    if (message?.chatId !== selectedChatId) {
      return
    }

    const currentAccountId: string = yield select(accountIdSelector)

    yield put(receiveAddMessageReactionSuccess({ ...payload, currentAccountId }))
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveRemoveMessageReactionSaga({
  payload
}: ReturnType<typeof receiveRemoveMessageReactionRequest>) {
  try {
    const { message } = payload
    const selectedChatId: string | undefined = yield select(
      (state: State) => state.chat.chatRooms.selectedChat?.id
    )

    if (message?.chatId !== selectedChatId) {
      return
    }

    const currentAccountId: string = yield select(accountIdSelector)

    yield put(receiveRemoveMessageReactionSuccess({ ...payload, currentAccountId }))
  } catch (e) {
    yield handleError(e)
  }
}

export function* chatMessagesSaga() {
  yield takeEvery(SEND_NEW_MESSAGE_REQUEST, sendNewMessageSaga)
  yield takeEvery(SEND_NEW_AUDIO_MESSAGE_REQUEST, sendNewAudioMessageSaga)
  yield takeLatest(GET_MESSAGES_HISTORY_REQUEST, getHistoryMessagesSaga)
  yield takeLatest(REDIRECT_TO_CASE_FROM_CHAT, redirectToCaseFromChat)
  yield takeEvery(READ_MESSAGES_REQUEST, readMessagesSaga)
  yield takeEvery(READ_ALL_MESSAGES_REQUEST, readAllMessagesSaga)
  yield takeLatest(START_MESSAGE_EDITING_REQUEST, startMessageEditingSaga)
  yield takeLatest(END_MESSAGE_EDITING_REQUEST, endMessageEditingSaga)
  yield takeLatest(DELETE_ALL_MESSAGES_REQUEST, deleteAllMessagesSaga)
  yield takeEvery(DELETE_MESSAGE_PERMANENTLY_REQUEST, deleteMessagePermanentlySaga)
  yield takeLatest(CONNECT_WS_STATUS, sendFailedMessagesSaga)
  yield takeEvery(FILE_DISCARD_PERMISSION_FROM_CHAT_REQUEST, fileDiscardPermissionFromChatSaga)
  yield takeLatest(FILE_REVOKE_PERMISSION_FROM_CHAT_REQUEST, fileRevokePermissionFromChatSaga)
  yield takeLatest(DOWNLOAD_FILE_FROM_CHAT_REQUEST, downloadFileSaga)
  yield takeLatest(RECEIVE_CHAT_INTERMEDIATE_STATE, receiveChatIntermediateState)
  yield debounce(50, RECEIVE_NEW_MESSAGE_SUCCESS, receiveMessagePlayAudio)
  yield takeEvery(RECEIVE_NEW_MESSAGE_REQUEST, receiveNewMessageSaga)
  yield debounce(DEFAULT_THROTTLE_MS, UPDATE_CHAT_DRAFT_REQUEST, updateChatDraftSaga)
  yield takeLatest(GET_CHAT_DRAFT_REQUEST, getChatDraftSaga)
  yield takeLatest(QUOTE_MESSAGE_REQUEST, quoteMessageSaga)
  yield takeLatest(QUOTE_MESSAGE_CANCEL, quoteMessageCancelSaga)
  yield takeLatest(DELETE_FILE_FROM_CHAT_REQUEST, deleteFileFromChatSaga)
  yield takeEvery(ADD_MESSAGE_REACTION_REQUEST, addMessageReactionSaga)
  yield takeEvery(REMOVE_MESSAGE_REACTION_REQUEST, removeMessageReactionSaga)
  yield takeEvery(RECEIVE_ADD_MESSAGE_REACTION_REQUEST, receiveAddMessageReactionSaga)
  yield takeEvery(RECEIVE_REMOVE_MESSAGE_REACTION_REQUEST, receiveRemoveMessageReactionSaga)
}
