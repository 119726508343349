import { ESorting } from 'enums'

const DEFAULT_RANDOM_ARRAY_LENGTH = 10

export function generateRandomArray(length: number = DEFAULT_RANDOM_ARRAY_LENGTH): string[] {
  return Array.from({ length }, () => `${Math.random()}`)
}

export const sort = (data: string[], order: keyof typeof ESorting = 'ASC') => {
  const reverse = order === 'ASC' ? 1 : -1

  return data.sort((prev, next) => (parseFloat(prev) - parseFloat(next)) * reverse)
}

export const moveToStart = (arr: string[], id: string) => [
  ...(id ? [id] : []),
  ...arr.filter((item) => item !== id)
]
