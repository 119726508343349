import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { CaseStatusesEnum, ChatStatusEnum, ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'

import {
  accountIdSelector,
  hideModalAction,
  isAdminAccountSelector,
  isBusinessAccountSelector,
  isSelectedChatP2PSelector,
  pinChatMessageRequest,
  quoteMessageRequest,
  showModalAction,
  startMessageEditingRequest,
  TChatHistoryMessage
} from 'store'
import { State } from 'redux/rootReducer'
import { ESendMessageStatus } from 'enums'
import { ChatMessageKebabMenu, TChatMessageKebabMenuBaseProps } from 'App/components'

type TChatMessageKebabMenuOwnProps = {
  messageId: string
  sendStatus: ESendMessageStatus
  receiverId?: string
  readAll: boolean
  fileId?: string
}

export type TChatMessageKebabMenuContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  TChatMessageKebabMenuBaseProps

const BUSINESS_CHATS = new Set([
  ChatTypeEnum.COMMUNITY_NEWS,
  ChatTypeEnum.ORGANIZATION,
  ChatTypeEnum.COMMUNITY_CHANNEL,
  ChatTypeEnum.EVENT_NEWS,
  ChatTypeEnum.EVENT_CHANNEL
])

const mapStateToProps = (
  state: State,
  { messageId, fileId, sendStatus, receiverId, readAll }: TChatMessageKebabMenuOwnProps
) => {
  const selectedChat = state.chat.chatRooms.selectedChat
  const accountId = accountIdSelector(state)
  const message: TChatHistoryMessage | undefined = state.chat.chatMessages.messages.list[messageId]

  return {
    readAll,
    sendStatus,
    receiverId,
    message,
    isChatAdmin: selectedChat?.type
      ? isAdminAccountSelector(state) && BUSINESS_CHATS.has(selectedChat.type)
      : false,
    isBusinessAccount: isBusinessAccountSelector(state),
    isCaseOwner: state.global.accountData?.id === selectedChat?.case?.owner.id,
    isChannelManager: accountId === selectedChat?.channelManager?.id,
    isPinned: !!message?.pin,
    isActive: selectedChat?.status === ChatStatusEnum.ACTIVE,
    isLockedCase: selectedChat?.case?.status === CaseStatusesEnum.LOCKED,
    isCaseGroup: selectedChat?.type === ChatTypeEnum.CASE_GROUP,
    isOrganization: selectedChat?.type === ChatTypeEnum.ORGANIZATION,
    isCommunityChannel: selectedChat?.type === ChatTypeEnum.COMMUNITY_CHANNEL,
    isCommunityNews: selectedChat?.type === ChatTypeEnum.COMMUNITY_NEWS,
    isEventChannel: selectedChat?.type === ChatTypeEnum.EVENT_CHANNEL,
    isEventNews: selectedChat?.type === ChatTypeEnum.EVENT_NEWS,
    isGroup: selectedChat?.type === ChatTypeEnum.GROUP,
    isPinAble: message?.type !== MessageTypeEnum.CALL,
    isPinShared: message?.pin?.shared ?? false,
    isPinInitiator: message?.pin?.initiatorId === accountId,
    isP2P: isSelectedChatP2PSelector(state),
    isUnopenable: Boolean(
      fileId && state.videoStreaming.list[fileId]?.videoMetadata?.quality === null
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      pinMessage: pinChatMessageRequest,
      quoteMessage: quoteMessageRequest,
      editMessage: startMessageEditingRequest,
      hideModalAction,
      showModalAction
    },
    dispatch
  )

export const ChatMessageKebabMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageKebabMenu)
