import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { ChatMessageSystem, TChatMessageSystemBaseProps } from 'App/components'

export type TChatMessageSystemContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, { id }: TChatMessageSystemBaseProps) => {
  const { message, meeting } = state.chat.chatMessages.messages.list[id] || {}
  const isOrganizer = state.global.accountData?.id === meeting?.organizer.id

  return {
    message,
    meeting,
    isOrganizer
  }
}

export const ChatMessageSystemContainer = connect(mapStateToProps)(ChatMessageSystem)
