import { useCallback, useEffect } from 'react'
import {
  ContactStatusEnum,
  BusinessAccountDirectionEnum,
  BusinessAccountStatusEnum,
  PlatformInvitationType
} from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { accountIdSelector, useAppSelector } from 'store'
import { getMapComponent } from 'utils'
import { IInvitationsDataByLinkTokenDTO } from 'interfaces'
import { Alert, Button } from 'App/components/common'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'

import { useUserProfileInvitationToast } from './useUserProfileInvitationToast'
import { useUserProfileInvitationByLinkRequest } from './useUserProfileInvitationByLinkRequest'
import styles from './UserProfileInvitationByLink.module.scss'

export type TUserProfileInvitationByLinkProps = PropsWithClassName<
  Partial<Pick<IInvitationsDataByLinkTokenDTO, 'inviteContact' | 'inviteOrganization' | 'type'>>
> & {
  token?: string
  profileId?: string
  shouldShowAccountSwitchModal?: boolean
}

type TButtonsMapProps = {
  isLoading: boolean
  handleClick: () => void
  t: TFunction
}

const BUTTONS_MAP = new Map<PlatformInvitationType, (props: TButtonsMapProps) => JSX.Element>()
  .set(PlatformInvitationType.CONTACT, ({ handleClick, isLoading, t }) => (
    <Button loading={isLoading} onClick={handleClick} icon={<AccountPlusIcon />}>
      {t('userProfile.inviteByLinkActions.inviteToContactButton')}
    </Button>
  ))
  .set(PlatformInvitationType.STAFF, ({ handleClick, isLoading, t }) => (
    <Button loading={isLoading} onClick={handleClick} icon={<AccountGroupIcon />}>
      {t('userProfile.inviteByLinkActions.joinOrganizationButton')}
    </Button>
  ))

const SENT_MAP = new Map<PlatformInvitationType, (t: TFunction) => string>()
  .set(PlatformInvitationType.CONTACT, (t) =>
    t('userProfile.inviteByLinkActions.invitationSentLabel')
  )
  .set(PlatformInvitationType.STAFF, (t) => t('userProfile.inviteByLinkActions.requestSentLabel'))

export const UserProfileInvitationByLink = ({
  inviteContact,
  inviteOrganization,
  token,
  type,
  className,
  profileId
}: TUserProfileInvitationByLinkProps) => {
  const isFirstLogin = useAppSelector((state) => state.global.accountData?.isFirstLogin)
  const invitation = useAppSelector((state) => state.global.accountData?.invitation)
  const accountId = useAppSelector(accountIdSelector)

  const { isLoading, mutate } = useUserProfileInvitationByLinkRequest({
    token,
    type,
    inviteContact,
    inviteOrganization,
    profileId
  })

  const { showToast } = useUserProfileInvitationToast()

  const { t } = useTranslation()

  const hasPendingStaffInvitation =
    type === PlatformInvitationType.STAFF &&
    profileId === inviteOrganization?.businessAccountId &&
    inviteOrganization?.direction === BusinessAccountDirectionEnum.INCOMING &&
    inviteOrganization?.status !== BusinessAccountStatusEnum.REJECTED

  const hasPendingContactInvitation =
    type === PlatformInvitationType.CONTACT &&
    accountId === inviteContact?.fromId &&
    inviteContact.status === ContactStatusEnum.PENDING

  const shouldShowAlert = hasPendingStaffInvitation || hasPendingContactInvitation

  const handleClick = useCallback(() => {
    if (!token) {
      return
    }

    mutate(token)
  }, [mutate, token])

  useEffect(() => {
    if (isFirstLogin && invitation) {
      showToast(invitation.type)
    }
  }, [invitation, isFirstLogin, showToast])

  if (!type || !token) {
    return null
  }

  return (
    <div className={cls(styles.root, className)}>
      {shouldShowAlert ? (
        <>
          <Alert variant="success" className={styles.alert}>
            <CheckCircleOutlineIcon />
            {getMapComponent(SENT_MAP, type, t)}
          </Alert>

          <span className={styles.status}>
            {t('userProfile.inviteByLinkActions.pendingStatusLabel')}
          </span>
        </>
      ) : (
        getMapComponent(BUTTONS_MAP, type, { isLoading, handleClick, t })
      )}
    </div>
  )
}
