export const PAGINATION_DEFAULT_PAGE = 0
export const PAGINATION_DEFAULT_TOTAL = 10
export const PAGINATION_DEFAULT_SHOW_BY = 10
export const PAGE_SIZE_OPTIONS = ['10', '20', '50']

/** Common type for PaginationItem */
export type Pagination = {
  /** Current active pages starts from 1 */
  current: number
  /** Total found items */
  total: number
  /** Page Size */
  showBy: number
}

/** Common type for Total in PaginationItem */
export type PaginationTotal = {
  total: number
}

export const PAGINATION_DEFAULT: Pagination = {
  current: PAGINATION_DEFAULT_PAGE,
  total: PAGINATION_DEFAULT_TOTAL,
  showBy: PAGINATION_DEFAULT_SHOW_BY
}
