import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Steps, StepsFooterActions } from 'App/components'
import {
  createProcessingSelector,
  ADD_LICENSE,
  updateLicenseRequest,
  addLicenseRequest,
  TUpdateLicenseRequestPayload,
  UPDATE_LICENSE,
  createProcessingBySelector,
  useAppSelector
} from 'store'
import { serverRangeDate, formatDate } from 'utils/formats'
import { useSteps } from 'App/hooks'

import { FirstStep, SecondStep } from './components'

const addingProcessingSelector = createProcessingSelector([ADD_LICENSE])
const updatingProcessingSelector = createProcessingBySelector([UPDATE_LICENSE])

const DEFAULT_STEPS = [{}, {}]
enum STEPS {
  FIRST,
  SECOND
}

export type TValues = {
  name: string
  number: string
  organization: string

  startDate?: Date
  endDate?: Date
  link?: string | null
  noExpirationDate?: boolean
}

const initialFormValues: TValues = {
  noExpirationDate: false,
  name: '',
  link: '',
  number: '',
  organization: ''
}

export type TUserProfileLicensesDialogProps = {
  data?: Omit<TUpdateLicenseRequestPayload, 'processingId'>
}

export const UserProfileLicensesDialog: FC<TUserProfileLicensesDialogProps> = ({ data }) => {
  const dispatch = useDispatch()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)

  const adding = useAppSelector(addingProcessingSelector)
  const updating = useAppSelector((state) => data && updatingProcessingSelector(data.id)(state))

  const { t } = useTranslation()

  const initialValues = useMemo<TValues>(() => {
    if (data) {
      return {
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined,
        noExpirationDate: !data.endDate
      }
    }

    return initialFormValues
  }, [data])

  const submitButtonText = data
    ? t('modal.userLicenses.submitButton_save')
    : t('modal.userLicenses.submitButton_add')

  const onSubmit = ({
    name,
    organization,
    noExpirationDate,
    startDate,
    endDate,
    link,
    number
  }: TValues) => {
    const normalizedData = {
      name,
      number,
      organization,
      link: link || null,
      startDate: formatDate(startDate || new Date(), serverRangeDate),
      endDate: noExpirationDate || !endDate ? null : formatDate(endDate, serverRangeDate)
    }

    dispatch(
      data
        ? updateLicenseRequest({ ...normalizedData, id: data.id, processingId: data.id })
        : addLicenseRequest(normalizedData)
    )
  }

  return (
    <Row gutter={[0, 29]}>
      <Col xs={24}>
        <Steps current={step} stepsArray={DEFAULT_STEPS} />
      </Col>
      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <Row gutter={[0, 24]}>
              {step === STEPS.FIRST && <FirstStep />}
              {step === STEPS.SECOND && <SecondStep />}
              <Col xs={24}>
                <Row>
                  <StepsFooterActions
                    onSubmit={step === STEPS.SECOND ? handleSubmit : setNextStep}
                    submitLoading={data ? updating : adding}
                    submitDisabled={form.getState().invalid}
                    onClickBack={step === STEPS.FIRST ? undefined : setPrevStep}
                    submitLabel={
                      step === STEPS.SECOND
                        ? submitButtonText
                        : t('modal.userLicenses.submitButton_next')
                    }
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  )
}
