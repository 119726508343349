import { FileHistorySourcesEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import { FileViewerProgress, Image } from 'App/components/common'
import { useDownloadFile } from 'App/hooks'

import styles from './ImageViewer.module.scss'

export type TImageViewerProps = Pick<IFilesEntity, 'id'> &
  PropsWithClassName<{
    source?: FileHistorySourcesEnum
    originalEntityId?: string
  }>

export const ImageViewer = ({ id, source, originalEntityId, className }: TImageViewerProps) => {
  const { progress, error, src } = useDownloadFile({ source, id, originalEntityId })

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Image
        className={cls(styles.image, className)}
        src={src}
        alt=""
        placeholder={
          <FileViewerProgress
            percent={progress}
            label={!!error ? t('common.fileViewer.failure') : undefined}
            status={!!error ? 'exception' : undefined}
          />
        }
        hidden={progress !== 100}
      />
    </div>
  )
}
