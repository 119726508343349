import { AccountTypeNames, PlatformInvitationType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

export const useInvitationDialogDescription = (
  accountType: AccountTypeNames | undefined,
  invitationType: PlatformInvitationType
) => {
  const { t } = useTranslation()

  if (invitationType === PlatformInvitationType.STAFF) {
    return t('modal.inviteByEmail.description_staff')
  }

  if (
    invitationType === PlatformInvitationType.CONTACT &&
    accountType === AccountTypeNames.BUSINESS
  ) {
    return t('modal.inviteByEmail.description_contact')
  }

  return t('modal.inviteByEmail.description')
}
