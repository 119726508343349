import { components, ValueContainerProps, GroupBase } from 'react-select'
import cls from 'classnames'

import styles from './ValueContainer.module.scss'

export const ValueContainer = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ValueContainerProps<Option, IsMulti, Group>
) => (
  <components.ValueContainer
    {...props}
    className={cls(styles.root, props.selectProps.endAdornment && styles.withAdornment)}
  />
)
