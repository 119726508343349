import cls from 'classnames'

import { ReactComponent as GeneralIcon } from 'assets/icons/Forum.svg'
import { ReactComponent as BullhornIcon } from 'assets/icons/Bullhorn.svg'
import { ReactComponent as VoiceIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { ReactComponent as GearSettingFillIcon } from 'assets/icons/GearSettingFill.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as StreamingIcon } from 'assets/icons/Streaming.svg'
import { ReactComponent as EventsIcon } from 'assets/icons/Events.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'
import { getMapComponent } from 'utils'
import { TChannelVariant } from 'interfaces'

import styles from './UnityHubChannelIcon.module.scss'

type TVariant =
  | TChannelVariant
  | 'invitation'
  | 'administration'
  | 'members'
  | 'info'
  | 'events'
  | 'networking'
  | 'profile'

export type TUnityHubChannelIconProps = {
  variant: TVariant

  gutter?: boolean
  isMuted?: boolean
  isInvisible?: boolean
}

const ICONS_MAP = new Map<TVariant, () => JSX.Element>()
  .set('news', () => <BullhornIcon />)
  .set('chat', () => <GeneralIcon />)
  .set('voice', () => <VoiceIcon />)
  .set('invitation', () => <AccountPlusIcon />)
  .set('administration', () => <GearSettingFillIcon />)
  .set('members', () => <AccountGroupIcon />)
  .set('broadcast', () => <StreamingIcon />)
  .set('events', () => <EventsIcon />)
  .set('info', () => <InfoIcon />)
  .set('networking', () => <AccountGroupIcon />)
  .set('profile', () => <AccountIcon />)

export const UnityHubChannelIcon = ({
  variant,
  isMuted,
  isInvisible,
  gutter = true
}: TUnityHubChannelIconProps) => (
  <span
    className={cls(styles.root, styles[variant], {
      [styles.gutter]: gutter,
      [styles.muted]: isMuted,
      [styles.invisible]: isInvisible
    })}
  >
    {getMapComponent(ICONS_MAP, variant)}
  </span>
)
