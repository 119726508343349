import { useEffect } from 'react'
import { AccountTypeNames, BusinessAccountRoleNameEnum } from '@medentee/enums'

import {
  CallsContainer,
  CaseAutoApproveContainer,
  SoundsContainer,
  TAccountAndNotificationsContainerProps
} from 'App/containers'
import { SettingsList } from 'App/components'
import { getMapComponent } from 'utils'

export type TAccountAndNotificationsProps = TAccountAndNotificationsContainerProps

const SETTINGS_BUSINESS_ACCOUNT_NOTIFICATIONS_MAP = new Map<
  BusinessAccountRoleNameEnum,
  () => JSX.Element
>()
  .set(BusinessAccountRoleNameEnum.SUPERADMIN, () => (
    <>
      <CaseAutoApproveContainer />
      <CallsContainer />
      <SoundsContainer />
    </>
  ))
  .set(BusinessAccountRoleNameEnum.ADMIN, () => (
    <>
      <CaseAutoApproveContainer />
      <CallsContainer />
      <SoundsContainer />
    </>
  ))

const SETTINGS_ACCOUNT_NOTIFICATIONS_MAP = new Map<
  AccountTypeNames,
  (props: Pick<TAccountAndNotificationsProps, 'role'>) => JSX.Element | null
>()
  .set(AccountTypeNames.PROFESSIONAL, () => (
    <>
      <CaseAutoApproveContainer />
      <CallsContainer />
      <SoundsContainer />
    </>
  ))
  .set(AccountTypeNames.BUSINESS, ({ role }) =>
    getMapComponent(SETTINGS_BUSINESS_ACCOUNT_NOTIFICATIONS_MAP, role)
  )

export const AccountAndNotifications = ({
  type,
  getAccountSettings,
  loading,
  role
}: TAccountAndNotificationsProps) => {
  useEffect(() => {
    getAccountSettings()
  }, [getAccountSettings])

  return (
    <SettingsList loading={loading}>
      {getMapComponent(SETTINGS_ACCOUNT_NOTIFICATIONS_MAP, type, { role })}
    </SettingsList>
  )
}
