import { useCallback } from 'react'
import { AccountTypeNames } from '@medentee/enums'

import { SYSTEM_USER_ID } from 'globalConstants'
import { redirectToUserProfileAction, useAppDispatch } from 'store'

export const useRedirectToUserProfile = () => {
  const dispatch = useAppDispatch()

  const redirectToUserProfile = useCallback(
    (userId?: string | null, type?: AccountTypeNames, searchQuery?: string) => () => {
      if (!type || !userId || userId === SYSTEM_USER_ID) {
        return
      }

      dispatch(
        redirectToUserProfileAction({
          type,
          userId,
          searchQuery
        })
      )
    },
    [dispatch]
  )

  return { redirectToUserProfile }
}
