import { ContactStatusEnum } from '@medentee/enums'
import { useCallback, useMemo } from 'react'

import { TContactsData, getUserProfileByIdRequest, useAppDispatch } from 'store'

import { UserProfileContactWithdraw } from './UserProfileContactWithdraw'
import { UserProfileContactRequest } from './UserProfileContactRequest'
import { UserProfileContactInvite } from './UserProfileContactInvite/UserProfileContactInvite'

export type TUserProfileContactInviteActionsProps = Partial<
  Pick<TContactsData, 'status' | 'toId' | 'fromId'>
> & {
  profileId: string

  contactId?: string
}

export const UserProfileContactInviteActions = ({
  fromId,
  status,
  toId,
  profileId,
  contactId
}: TUserProfileContactInviteActionsProps) => {
  const dispatch = useAppDispatch()

  const onSuccess = useCallback(() => {
    dispatch(getUserProfileByIdRequest({ id: profileId }))
  }, [dispatch, profileId])

  const content = useMemo(() => {
    if (status === ContactStatusEnum.PENDING && profileId === toId && contactId) {
      return (
        <UserProfileContactWithdraw contactId={contactId} status={status} onSuccess={onSuccess} />
      )
    }

    if (status === ContactStatusEnum.PENDING && profileId === fromId && contactId) {
      return (
        <UserProfileContactRequest contactId={contactId} accountId={fromId} onSuccess={onSuccess} />
      )
    }

    return <UserProfileContactInvite accountId={profileId} onSuccess={onSuccess} />
  }, [contactId, fromId, onSuccess, status, toId, profileId])

  if (status === ContactStatusEnum.APPROVED) {
    return null
  }

  return content
}
