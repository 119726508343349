import { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  CaseClassificationNameEnum,
  CaseStatusesEnum,
  CaseTypesEnum,
  ChatTypeEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  GET_CASE_MEMBERS,
  getCaseMembersRequest,
  getChatRoomsRequest,
  setChatTypeAction,
  selectGroupChat,
  isChatRoomMutedSelector
} from 'store'
import {
  CaseMembersList,
  Card,
  CaseMemberListSkeleton,
  CaseGroupMembersListEmpty,
  CaseProfessionalMembersListEmpty
} from 'App/components'
import { CaseInviteMembersButton, CaseOwnerChatContainer } from 'App/containers'
import { ECaseAccountType } from 'enums'
import { PAGINATION_DEFAULT_PAGE } from 'types'
import { CHAT_ROOMS_DEFAULT_SHOW_BY } from 'globalConstants'

import styles from './CaseMembersListContainer.module.scss'

export type TCaseMembersListContainerProps = {
  who?: ECaseAccountType
  caseType?: CaseTypesEnum | null
  caseView?: string
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseMembersListView = ({
  who,
  caseType,
  activeIds,
  inactiveIds,
  loading,
  caseStatus,
  chatId,
  caseOwnerId,
  accountId,
  all2allChatId,
  unreadAll2AllChat,
  caseClassification,
  caseView,
  isChatRoomMuted,
  getMembers,
  getChatRooms,
  setChatRoomsType
}: TCaseMembersListContainerProps) => {
  const { id: caseId } = useParams<{ id?: string }>()

  const { t } = useTranslation()

  const isCaseLocked = caseStatus === CaseStatusesEnum.LOCKED
  const isCaseArchived = caseStatus === CaseStatusesEnum.ARCHIVED
  const isMember = caseOwnerId !== accountId
  const action = !loading && caseId && who === ECaseAccountType.OWNER && (
    <CaseInviteMembersButton disabled={isCaseArchived || isCaseLocked} />
  )
  const title = t('cases.details.membersSection.title_members')

  useEffect(() => {
    if (caseId) {
      setChatRoomsType(ChatTypeEnum.DIALOGUE)
      getMembers({ caseId })
    }
  }, [caseId, caseClassification, caseStatus, getMembers, setChatRoomsType])

  useEffect(() => {
    if (caseId) {
      getChatRooms({ caseId, page: PAGINATION_DEFAULT_PAGE, showBy: CHAT_ROOMS_DEFAULT_SHOW_BY })
    }
  }, [caseId, inactiveIds.length, activeIds.length, getChatRooms, caseStatus])

  const getCaseContent = () => {
    if (chatId && !isCaseArchived) {
      return <CaseOwnerChatContainer caseView={caseView} chatId={chatId} caseId={caseId} />
    }

    if (loading) {
      return <CaseMemberListSkeleton group={caseType === CaseTypesEnum.A2A} />
    }

    if (caseId && !chatId && !isCaseArchived) {
      return (
        <CaseMembersList
          activeIds={activeIds}
          inactiveIds={inactiveIds}
          all2allChatId={all2allChatId}
          unreadAll2AllChat={unreadAll2AllChat}
          who={who}
          caseType={caseType}
          caseClassification={caseClassification}
          caseStatus={caseStatus}
          isChatRoomMuted={isChatRoomMuted}
        />
      )
    }

    if (isMember && caseType === CaseTypesEnum.A2A && caseStatus) {
      return <CaseGroupMembersListEmpty caseStatus={caseStatus} />
    }

    if (caseClassification === CaseClassificationNameEnum.PROFESSIONAL && isCaseArchived) {
      return <CaseProfessionalMembersListEmpty />
    }

    return null
  }

  return caseId ? (
    <Card
      title={title}
      actions={action}
      loading={loading}
      classes={{ root: styles.card, header: styles.membersHeader }}
    >
      {getCaseContent()}
    </Card>
  ) : null
}

const loadingSelector = createLoadingSelector([GET_CASE_MEMBERS])

const mapStateToProps = (state: State) => {
  const groupChat = selectGroupChat(state)

  return {
    loading: loadingSelector(state),
    activeIds: state.caseMembers.members.active.ids,
    inactiveIds: state.caseMembers.members.inactive.ids,
    caseStatus: state.caseView.data?.status,
    chatId: state.chat.chatMessages.selectedChatId,
    caseOwnerId: state.caseView.data?.owner.id,
    accountId: state.global.accountData?.id,
    all2allChatId: groupChat?.id,
    caseClassification: state.caseView.data?.classification.name,
    unreadAll2AllChat: state.notifications.general.chats.isChatUnread,
    isChatRoomMuted: isChatRoomMutedSelector(groupChat?.id)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMembers: getCaseMembersRequest,
      getChatRooms: getChatRoomsRequest,
      setChatRoomsType: setChatTypeAction
    },
    dispatch
  )

export const CaseMembersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMembersListView)
