import { ReactNode } from 'react'

import { IFilesEntity } from 'interfaces'
import { VIDEO_WHITELIST } from 'globalConstants'
import { formatBytes } from 'utils'
import { getExtensionWithoutDot } from 'utils/files'
import { Image } from 'App/components'
import { ReactComponent as PlayIcon } from 'assets/icons/Play.svg'
import { useAppSelector } from 'store'
import { VideoReadinessStatus } from 'App/containers/File/VideoReadinessStatus'

import { Chip } from '../Chip'
import { FullScreenCarouselButton } from '../../common/FullScreenCarouselButton'

import styles from './Preview.module.scss'

export type TPreviewPops = {
  file: IFilesEntity
  files: IFilesEntity[]
  orderNumber: number
  children?: ReactNode
}

export const Preview = ({ file, orderNumber, files }: TPreviewPops) => {
  const isUnopenable = useAppSelector(
    (state) => (state.videoStreaming.list[file.id]?.videoMetadata?.quality ?? null) === null
  )

  const shouldShowPlay =
    VIDEO_WHITELIST.includes(getExtensionWithoutDot(file.extension)) && !isUnopenable

  return (
    <>
      <Image
        src={file.thumbnailUrl}
        alt={file.fileName}
        className={styles.thumbnail}
        placeholderClasses={{ root: styles.placeholder, icon: styles.placeholder }}
      />

      {shouldShowPlay && (
        <div className={styles.play}>
          <PlayIcon />
        </div>
      )}

      <div className={styles.size}>{formatBytes(file.fileSize)}</div>

      <Chip className={styles.orderNumber}>{orderNumber}</Chip>

      {file.id && (
        <VideoReadinessStatus className={styles.fullScreenButton} fileId={file.id} size="sm" />
      )}

      {!isUnopenable && (
        <FullScreenCarouselButton
          slides={files}
          initialSlideId={file.id}
          className={styles.fullScreenButton}
        />
      )}
    </>
  )
}
