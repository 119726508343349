import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { EInputSize } from 'enums'
import {
  RoleRouteContainer,
  DragAndDropContainer,
  TrashBinWidget,
  FileInformationPanelContainer,
  FileUploadButtonContainer
} from 'App/containers'
import {
  TabMenu,
  TNavMenuItem,
  Scrollable,
  SearchWrapper,
  Search,
  Alert,
  useFilePageFilters,
  AsideMenuHeaderAction,
  EAsideMenuHeaderActionVariant
} from 'App/components'
import { fileNestedRoutes, getKeyForRoute } from 'App/App.config'
import { useAdaptiveLayout } from 'App/hooks'
import {
  setFileFiltersRequest,
  setFileSharedFiltersRequest,
  setTrashBinSearch,
  useAppSelector
} from 'store'
import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'
import { ReactComponent as FileAddOutlineIcon } from 'assets/icons/FileAddOutline.svg'

import styles from './FilePage.module.scss'

export const TRASH_BIN_PATH = '/files/trash-bin'
export const FILES_PATH = '/files'
export const SHARED_FILES_PATH = '/files/shared'

export const FilePage = () => {
  const dispatch = useDispatch()

  const { isDesktop, isMobile } = useAdaptiveLayout()

  const { pathname } = useLocation()

  const { filters, content, selectedFilters } = useFilePageFilters()

  const { current: myFilesCurrentPage = 0 } = useAppSelector((state) => state.file.pagination)
  const { current: sharedFilesCurrentPage = 0 } = useAppSelector(
    (state) => state.fileShared.pagination
  )
  const { current: trashBinCurrentPage = 0 } = useAppSelector(
    (state) => state.trashBin.filters.pagination
  )

  const { t } = useTranslation()

  const menuList = useMemo<TNavMenuItem[]>(
    () => [
      {
        link: FILES_PATH,
        title: t('files.tabs.myFiles'),
        exact: true
      },
      {
        link: SHARED_FILES_PATH,
        title: t('files.tabs.sharedFiles'),
        exact: true
      },
      {
        link: TRASH_BIN_PATH,
        title: t('files.tabs.trashBin'),
        exact: true
      }
    ],
    [t]
  )

  const onSearch = (value: string) => {
    const payload = { search: value }

    if (pathname === SHARED_FILES_PATH) {
      return dispatch(setFileSharedFiltersRequest(payload))
    }

    if (pathname === TRASH_BIN_PATH) {
      return dispatch(setTrashBinSearch(payload))
    }

    return dispatch(setFileFiltersRequest(payload))
  }

  const isTrashBin = pathname === TRASH_BIN_PATH
  const isFiles = pathname === FILES_PATH
  const isSharedFiles = pathname === SHARED_FILES_PATH
  const showDragAndDrop = pathname === FILES_PATH
  const showWidget = isDesktop && isTrashBin

  return (
    <DragAndDropContainer hide={!(isDesktop && showDragAndDrop)} centerOnDropAlert={true}>
      <div className={styles.root}>
        <Scrollable variant="aside">
          {!isTrashBin && (
            <div className={cls(styles.sidebar, { [styles.shared]: isSharedFiles })}>
              {isFiles && (
                <AsideMenuHeaderAction
                  title={t('files.asideSubtitle')}
                  buttonContainer={FileUploadButtonContainer}
                  buttonText={t('files.asideButton')}
                  icon={isMobile ? <FileAddOutlineIcon /> : <CloudUploadIcon />}
                  variant={EAsideMenuHeaderActionVariant.BUTTON_BLOCK}
                  classes={{ wrapper: styles.upload }}
                />
              )}

              {isDesktop && <div className={styles.filters}>{content}</div>}
            </div>
          )}

          {showWidget && <TrashBinWidget />}
        </Scrollable>
        <div className={styles.rootContainer}>
          <TabMenu tabVariant="static" menuList={menuList} navMenuProps={{ tabPosition: 'top' }} />

          <DragAndDropContainer
            hide={!(!isDesktop && showDragAndDrop)}
            centerOnDropAlert={true}
            onDropAlertLayout="fixed"
          >
            {isTrashBin && (
              <>
                {!isDesktop && <TrashBinWidget />}

                <Alert variant="info" size="sm" className={styles.alert}>
                  {t('files.trashBinAlert')}
                </Alert>
              </>
            )}
            <SearchWrapper filters={!isDesktop && !isTrashBin ? filters : undefined}>
              <Search
                placeholder={t('files.searchPlaceholder')}
                allowClear={true}
                searchSize={EInputSize.L}
                onChange={onSearch}
              />
            </SearchWrapper>
            {!isMobile && !isTrashBin && selectedFilters}
            <Scrollable
              className={styles.rootWrapper}
              page={myFilesCurrentPage || sharedFilesCurrentPage || trashBinCurrentPage}
            >
              {fileNestedRoutes.map((item, index) => (
                <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
              ))}
            </Scrollable>
          </DragAndDropContainer>
        </div>

        <FileInformationPanelContainer />
      </div>
    </DragAndDropContainer>
  )
}
