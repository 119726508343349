import { delay, fork, put, select } from 'redux-saga/effects'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store'
import { SECOND, SHOW_SECOND_MODAL_ERROR } from 'globalConstants'
import { AppDispatch } from 'redux/store'

import { TError } from './store.types'

// ------------------ action ------------------

export type TAction = {
  type: string
  payload?: any
  meta?: any
}

// TODO: REFACTOR replace action with actionCreator
export function action(type: string): { type: string }
export function action<P = undefined>(type: string, payload?: P): { type: string; payload: P }
export function action<P = undefined, M = undefined>(
  type: string,
  payload: P,
  meta: M
): { type: string; payload: P; meta: M }

export function action<P = undefined, M = undefined>(type: string, payload?: P, meta?: M) {
  return { type, meta, payload }
}

export function actionCreator<T>(type: keyof T): { type: keyof T }
export function actionCreator<T, P = undefined>(
  type: keyof T,
  payload?: P
): { type: keyof T; payload: P }
export function actionCreator<T, P = undefined, M = undefined>(
  type: keyof T,
  payload: P,
  meta: M
): { type: keyof T; payload: P; meta: M }

export function actionCreator<T, P = undefined, M = undefined>(
  type: keyof T,
  payload?: P,
  meta?: M
) {
  return { type, meta, payload }
}

export const isChatActionOfType = (chatId: string, type: string) => (currentAction: TAction) =>
  currentAction.type === type && currentAction.payload?.chatId === chatId
// ------------------  END  ------------------

export function* clearErrorSaga(actionType: string, seconds = SHOW_SECOND_MODAL_ERROR) {
  function* removeErrorNotificationSaga() {
    const error: TError = yield select((state: State) => state.errors[actionType])

    if (error) {
      yield put(removeErrorNotification(actionType))
    }
  }

  yield delay(seconds * SECOND)
  yield fork(removeErrorNotificationSaga)
}
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<State> = useSelector
