import { useCallback, useMemo } from 'react'
import { BroadcastStatusEnum, BroadcastTypeEnum } from '@medentee/enums'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { isAdminAccountSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { generateJoinToUnityHubChannelLink } from 'utils'

type TMenuItemsProps = {
  broadcastId: string
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useBroadcastRoomHeaderKebabMenu = (): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const currentCommunityId = useAppSelector((state) => state.communities.currentCommunityId)
  const currentEventId = useAppSelector((state) => state.events.currentEventId)
  const activeBroadcastStatus = useAppSelector((state) => state.broadcasts.activeBroadcast?.status)
  const activeBroadcastType = useAppSelector((state) => state.broadcasts.activeBroadcast?.type)
  const activeBroadcastInviteToken = useAppSelector(
    (state) => state.broadcasts.activeBroadcast?.inviteToken
  )
  const isBusiness = useAppSelector(isAdminAccountSelector)

  const ENTITY_ID_MAP = new Map<BroadcastTypeEnum, string | null>()
    .set(BroadcastTypeEnum.COMMUNITY, currentCommunityId)
    .set(BroadcastTypeEnum.EVENT, currentEventId)

  const entityId = useMemo(
    () => activeBroadcastType && ENTITY_ID_MAP.get(activeBroadcastType),
    [ENTITY_ID_MAP, activeBroadcastType]
  )

  const menuItems = useMemo(
    () => ({
      edit: ({ broadcastId }: TMenuItemsProps) => ({
        hidden: !isBusiness || activeBroadcastStatus !== BroadcastStatusEnum.UPCOMING,
        content: t('liveStream.kebabMenu.edit'),
        icon: <PencilIcon />,
        onClick: () =>
          activeBroadcastType &&
          entityId &&
          dispatch(
            showModalAction({
              modalTitle: t('modal.createBroadcast.mainStep.title_edit'),
              modalType: EModalComponents.CREATE_BROADCAST,
              modalProps: {
                broadcastId,
                type: activeBroadcastType,
                entityId
              }
            })
          )
      }),

      delete: ({ broadcastId }: TMenuItemsProps) => ({
        hidden: !isBusiness,
        content: t('liveStream.kebabMenu.delete'),
        icon: <TrashIcon />,
        onClick: () => {
          activeBroadcastType &&
            entityId &&
            dispatch(
              showModalAction({
                modalTitle: t('modal.deleteLiveEventConfirm.title'),
                modalType: EModalComponents.DELETE_BROADCAST_CONFIRM,
                modalProps: {
                  broadcastId,
                  type: activeBroadcastType,
                  entityId
                }
              })
            )
        }
      }),
      invite: (_: TMenuItemsProps) => ({
        hidden: !isBusiness || !activeBroadcastInviteToken,
        content: t('liveStream.kebabMenu.invitationLink'),
        icon: <LinkChainIcon />,
        onClick: () => {
          if (!activeBroadcastInviteToken || !entityId || !activeBroadcastType) {
            return
          }

          copy(
            generateJoinToUnityHubChannelLink(
              activeBroadcastType === BroadcastTypeEnum.EVENT ? 'event' : 'community',
              'broadcast',
              entityId,
              activeBroadcastInviteToken
            )
          )

          toast.success(t('common.toast.linkCopied'), toastDefaultOptions)
        }
      })
    }),
    [
      isBusiness,
      activeBroadcastStatus,
      dispatch,
      activeBroadcastType,
      activeBroadcastInviteToken,
      entityId,
      t
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.invite(props),
      menuItems.edit(props),
      menuItems.delete(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
