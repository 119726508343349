import { forwardRef, PropsWithChildren, LegacyRef } from 'react'
import cls from 'classnames'

import styles from './NewDividerLine.module.scss'

export type TNewDividerLineProps = {
  onClick?: () => void
  classNames?: string
}

const NewDividerLineView = (
  { onClick, children, classNames = '' }: PropsWithChildren<TNewDividerLineProps>,
  ref: LegacyRef<HTMLDivElement>
) => (
  <div
    ref={ref}
    className={cls({
      [styles.root]: true,
      [classNames]: !!classNames
    })}
  >
    <button onClick={onClick} className={styles.label}>
      {children}
    </button>
  </div>
)

export const NewDividerLine = forwardRef<HTMLDivElement, PropsWithChildren<TNewDividerLineProps>>(
  NewDividerLineView
)
