import { useCallback, useEffect } from 'react'
import cls from 'classnames'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  sendInviteToContactRequest,
  SEND_INVITE_TO_CONTACT,
  useAppDispatch,
  useAppSelector,
  removeErrorNotification
} from 'store'
import { Button, ErrorModal, TextField } from 'App/components'
import { TSendInviteToContactPayload } from 'store/inviteContacts/inviteContacts.types'
import { MAX_LENGTH_1000 } from 'globalConstants'
import { validation } from 'utils/validation'

import styles from './ConfirmInviteToContact.module.scss'

export type TConfirmInviteToContactProps = Pick<TSendInviteToContactPayload, 'caseId'> & {
  accountId: string

  onSuccess?: () => void
}

type FormValue = {
  message?: string
}

const TEXT_AREA_ROWS = 4

export const ConfirmInviteToContact = ({
  accountId,
  caseId,
  onSuccess
}: TConfirmInviteToContactProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(createProcessingBySelector([SEND_INVITE_TO_CONTACT])(accountId))
  const error = useAppSelector((state) => state.errors[SEND_INVITE_TO_CONTACT])

  useEffect(
    () => () => {
      dispatch(removeErrorNotification(SEND_INVITE_TO_CONTACT))
    },
    [dispatch]
  )

  const onSubmit = useCallback(
    (values: FormValue) => {
      dispatch(
        sendInviteToContactRequest({
          onSuccess,
          accountId,
          processingId: accountId,
          caseId,
          message: values.message
        })
      )
    },
    [dispatch, onSuccess, accountId, caseId]
  )

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Field
              name="message"
              validate={validation.composeValidators(
                validation.required(),
                validation.maxLength(MAX_LENGTH_1000)
              )}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  topLabel={t('modal.inviteToContact.messageField')}
                  autoFocus={true}
                  multiline={true}
                  rows={TEXT_AREA_ROWS}
                  rowsMax={TEXT_AREA_ROWS}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                  valueLengthMax={MAX_LENGTH_1000}
                  classes={{ topLabel: styles.topLabel }}
                />
              )}
            </Field>

            <Button
              type="submit"
              className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
              loading={processing}
              disabled={form.getState().invalid || processing}
            >
              {t('modal.inviteToContact.submitButton')}
            </Button>
          </form>
        )}
      </Form>

      <ErrorModal error={error} />
    </div>
  )
}
