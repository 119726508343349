import { useState, useCallback } from 'react'
import { ChatTypeEnum, ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { editTextChannel } from 'api/organizations'
import {
  accountIdSelector,
  getChatRoomsRequest,
  hideModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { isMatchErrorCode } from 'utils'
import { getChatMembers, getChatRoomById } from 'api/chats'
import { TChannelDepartment } from 'App/components/UnityHub/UnityHubCreateChannels'
import { EAccountsSelectType } from 'App/components/common/AccountsSelect'

import { MembersStep } from '../MembersStep'

export type TInviteMembersTextChannelDialogProps = {
  organizationId: string
  channelId: string
}

const TEXT_CHANNEL_MEMBERS_QUERY_KEY = 'text_channel_members'
const TEXT_CHANNEL_DEPARTMENTS_QUERY_KEY = 'text_channel_department'

export const InviteMembersTextChannelDialog = ({
  channelId,
  organizationId
}: TInviteMembersTextChannelDialogProps) => {
  const dispatch = useAppDispatch()

  const [channelMembers, setChannelMembers] = useState<TAccountsSelectOptionData[]>([])
  const [channelDepartments, setChannelDepartments] = useState<TChannelDepartment[]>([])

  const channelManagerId = useAppSelector(
    (state) => state.chat.chatRooms.list[channelId]?.channelManager?.id
  )

  const accountId = useAppSelector(accountIdSelector)
  const isLeaderInDepartments = useAppSelector(
    (state) => state.organizations.data.list[organizationId]?.isLeaderInDepartments
  )

  const { t } = useTranslation()

  const { error, isLoading, mutate } = useMutation({
    mutationKey: ['invite-members-text-channel'],
    mutationFn: editTextChannel,
    onSuccess: () => {
      dispatch(hideModalAction())
      dispatch(
        getChatRoomsRequest({
          organizationId,
          chatType: [ChatTypeEnum.ORGANIZATION]
        })
      )
    },
    onError: (err) => {
      if (isMatchErrorCode(err, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        dispatch(hideModalAction())

        toast.warn(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    }
  })

  const { isFetching: isMembersLoading } = useQuery({
    queryKey: [TEXT_CHANNEL_MEMBERS_QUERY_KEY],
    queryFn: () => getChatMembers({ chatId: channelId, showBy: Number.MAX_SAFE_INTEGER }),

    cacheTime: 0,
    onSuccess: ({ results }) => {
      if (results?.length) {
        let filteredAccounts = results.filter((item) => item.id !== organizationId)

        if (isLeaderInDepartments && accountId) {
          filteredAccounts = filteredAccounts.filter((item) => item.id !== accountId)
        }

        setChannelMembers(filteredAccounts)
      }
    }
  })

  const { isLoading: isDepartmentsLoading } = useQuery({
    queryKey: [TEXT_CHANNEL_DEPARTMENTS_QUERY_KEY, channelId],
    queryFn: () => getChatRoomById(channelId),
    cacheTime: 0,
    onSuccess: (data) => {
      if (data?.departments?.length) {
        setChannelDepartments(data?.departments)
      }
    }
  })

  const handleSelect = useCallback(
    (accounts: TAccountsSelectOptionData[], type: EAccountsSelectType) => {
      const selectedIds = accounts.map(({ id }) => id)

      const accountIds = type === EAccountsSelectType.BY_USER ? selectedIds : []
      const departmentIds = type === EAccountsSelectType.BY_GROUP ? selectedIds : []

      mutate({ accountIds, departmentIds, channelId, organizationId })
    },
    [channelId, mutate, organizationId]
  )

  return (
    <MembersStep
      variant="members"
      organizationId={organizationId}
      error={error}
      submitLoading={isLoading}
      channelMembers={channelMembers}
      channelDepartments={channelDepartments}
      isLeaderInDepartments={isLeaderInDepartments}
      loading={isMembersLoading || isDepartmentsLoading}
      markChatIds={[channelId]}
      onSubmit={handleSelect}
      channelManagerId={channelManagerId}
    />
  )
}
