import { Trans, useTranslation } from 'react-i18next'

import { formatShortyDate } from 'utils'
import { TAutoPaymentDTO } from 'interfaces'
import { getBalanceAmount, getCurrentSubscription, useAppSelector } from 'store'

import styles from './MonthlyTopUpSetUp.module.scss'

export const MonthlyTopUpDescription = ({ data }: { data?: TAutoPaymentDTO }) => {
  const currentSubscription = useAppSelector(getCurrentSubscription)
  const balanceAmount = useAppSelector(getBalanceAmount)

  const { t } = useTranslation()

  const month = currentSubscription
    ? Math.floor(balanceAmount / currentSubscription.subscription.price)
    : 0

  if (data && data.status !== 'canceled') {
    return (
      <p>
        {t('payments.ballance.monthlyTopUp.nextPaymentDate', {
          date: formatShortyDate(data.nextChargeAt)
        })}
        <br />
        <Trans
          t={t}
          i18nKey="payments.ballance.monthlyTopUp.topUpAmount"
          components={{ amount: <span className={styles.amount}>{data.amount} MDT</span> }}
        />
      </p>
    )
  }

  return (
    <div className={styles.content}>
      {t('payments.ballance.monthlyTopUp.content', { month, returnObjects: true }).map((item) => (
        <div key={item}>{item}</div>
      ))}
    </div>
  )
}
