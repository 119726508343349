import { CaseMemberStatusesEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'

const IGNORED_CASE_MEMBERS = new Set([
  CaseMemberStatusesEnum.INVITE_REJECTED,
  CaseMemberStatusesEnum.LEFT
])

export const getCaseInvitationInactiveMembersQuantity = (state: State) =>
  Object.values(state.caseMembers.members.inactive.list).filter(
    ({ status }) => !IGNORED_CASE_MEMBERS.has(status)
  ).length

export const getCaseMemberIdsSelector = (state: State) => [
  ...Object.values(state.caseMembers.members.active.accountList).map(({ id }) => id),
  ...Object.values(state.caseMembers.members.inactive.accountList).map(({ id }) => id)
]
