import isEqual from 'lodash/isEqual'
import { Trans, useTranslation } from 'react-i18next'

import {
  getBroadcastSelectedFilesIdsByCountSelector,
  getBroadcastSelectedRestFilesSelector,
  isBroadcastSelectedFilesMoreSelector,
  useAppSelector
} from 'store'

import { AttachedItem } from '../AttachedItem'
import { Grid } from '../Grid'

import styles from './AttachedFilesContainer.module.scss'

export type TAttachedFilesContainerProps = {
  onOpenAttachedFiles: () => void
}

const MAX_ITEMS_LENGTH = 4
const MAX_ITEMS_SHOW = 3

export const AttachedFilesContainer = ({ onOpenAttachedFiles }: TAttachedFilesContainerProps) => {
  const ids = useAppSelector(getBroadcastSelectedFilesIdsByCountSelector(MAX_ITEMS_LENGTH), isEqual)
  const shouldShowMoreButton = useAppSelector(
    isBroadcastSelectedFilesMoreSelector(MAX_ITEMS_LENGTH)
  )
  const extraFilesNumber = useAppSelector(getBroadcastSelectedRestFilesSelector(MAX_ITEMS_SHOW))

  const { t } = useTranslation()

  return ids.length ? (
    <div className={styles.root}>
      <h2 className={styles.title}>{t('modal.broadcastMessage.attachedFiledSubtitle')}</h2>

      <Grid className={styles.grid}>
        {ids.map((id, index) =>
          shouldShowMoreButton && index === MAX_ITEMS_SHOW ? (
            <div className={styles.moreWrapper} key={id}>
              <div className={styles.more} onClick={onOpenAttachedFiles}>
                <div className={styles.moreContainer}>
                  <Trans
                    t={t}
                    i18nKey="modal.broadcastMessage.viewMoreFilesButton"
                    components={{ span: <span className={styles.moreText} /> }}
                    values={{ extraFilesNumber }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <AttachedItem
              key={id}
              variant="icon"
              classes={{ root: styles.item, remove: styles.itemRemove }}
              id={id}
            />
          )
        )}
      </Grid>
    </div>
  ) : null
}
