import { useMemo } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './LastMessagePrefix.module.scss'

type TLastMessagePrefixProps = {
  yourMessage: boolean
  draftMessage: boolean
  draftQuote: boolean
  active: boolean
  showSenderName: boolean

  senderName?: string
}

export const LastMessagePrefix = ({
  yourMessage,
  draftMessage,
  draftQuote,
  active,
  senderName,
  showSenderName
}: TLastMessagePrefixProps) => {
  const { t } = useTranslation()

  const prefix = useMemo(() => {
    switch (true) {
      case draftQuote:
      case draftMessage: {
        return t('chat.list.lastMessage.prefix_draft')
      }
      case yourMessage: {
        return t('chat.list.lastMessage.prefix_you')
      }
      case showSenderName && !!senderName: {
        return senderName
      }
      default: {
        return ''
      }
    }
  }, [draftQuote, draftMessage, senderName, showSenderName, yourMessage, t])

  const showColon = prefix && (!draftQuote || draftMessage)

  return (
    <span
      className={cls(styles.root, {
        [styles.yourMessage]: yourMessage && !active,
        [styles.draft]: draftMessage || draftQuote
      })}
    >
      {prefix}
      {showColon && ': '}
    </span>
  )
}
