import { ReactNode, useRef } from 'react'
import { InputRef } from 'antd'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { Search, TSearchProps } from '../Search'

import styles from './SearchWithChips.module.scss'

type TChip = {
  id: string
  label: ReactNode
}

export type TSearchWithChipsProps = {
  chips: TChip[]
  onRemove: (id: string) => void
} & Pick<TSearchProps, 'onChange'>

export const SearchWithChips = ({ chips, onChange, onRemove }: TSearchWithChipsProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<InputRef>(null)

  const { t } = useTranslation()

  const handleFocus = () => inputRef.current?.focus()
  const handleRemove = (id: string) => () => onRemove(id)

  return (
    <div className={styles.root} tabIndex={1} ref={rootRef} onFocus={handleFocus}>
      {chips.map((item) => (
        <div key={item.id} className={styles.chip}>
          {item.label}
          <CrossIcon className={styles.chipIcon} onClick={handleRemove(item.id)} />
        </div>
      ))}

      <Search
        ref={inputRef}
        searchSize={EInputSize.M}
        classes={{ root: styles.search }}
        onChange={onChange}
        placeholder={chips.length ? '' : t('common.field.search.placeholder')}
      />
    </div>
  )
}
