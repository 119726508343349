import { FilePermissionsIdsEnum } from '@medentee/enums'

import { TIds } from 'store/store.types'

import { TPermissionContactsList } from './caseCloud.types'

export const getCaseContactsPermissionsSort = (
  arr: TIds,
  list: TPermissionContactsList,
  permission: Record<string, FilePermissionsIdsEnum[]>
): TIds => {
  const withPermissions = arr.filter((item) => !!permission[list[item]?.id ?? ''].length)
  const withoutPermissions = arr.filter((item) => !permission[list[item]?.id ?? ''].length)

  const compare = (idA: string, idB: string) => {
    const itemA = list[idA]
    const itemB = list[idB]

    if (!itemA || !itemB) {
      return 0
    }

    return itemA.displayUserName.localeCompare(itemB.displayUserName)
  }

  withPermissions.sort(compare)
  withoutPermissions.sort(compare)

  return [...withPermissions, ...withoutPermissions]
}
