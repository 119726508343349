import { BroadcastTypeEnum } from '@medentee/enums'

import { TBroadcast } from 'interfaces/api/broadcasts'
import { API, APIData, APIResultsResponse, api } from 'services/api'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { QueryBuilder } from 'utils/QueryBuilder'

type TCreateBroadcastPayload = {
  entityId: string
  type: BroadcastTypeEnum
  name: string
  url: string
  isPublic: boolean
  accountIds: string[]
}

export const createBroadcast = async (payload: TCreateBroadcastPayload) => {
  const { data }: APIData = await api.post(API.BROADCASTS, payload)

  return data
}

type TEditBroadcastPayload = Pick<
  TCreateBroadcastPayload,
  'name' | 'accountIds' | 'url' | 'isPublic'
> & {
  broadcastId: string
}

export const editBroadcast = async ({ broadcastId, ...payload }: TEditBroadcastPayload) => {
  const { data }: APIData = await api.patch(API.BROADCAST(broadcastId), payload)

  return data
}

export const deleteBroadcast = async (broadcastId: string) => {
  const response = await api.delete(API.BROADCAST(broadcastId))

  return response
}

export const joinToBroadcast = async (token: string) => {
  const { data }: APIData<TBroadcast> = await api.post(API.BROADCAST_JOIN(token))

  return data
}

export const getBroadcastJoinTokenInfo = async (token: string) => {
  const { data }: APIData<TBroadcast> = await api.get(API.BROADCAST_JOIN(token))

  return data
}

type TGetBroadcastMembersPayload = {
  broadcastId: string

  page?: number
  showBy?: number | string
}

export const getBroadcastMembers = async ({
  broadcastId,
  page,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetBroadcastMembersPayload) => {
  const url = new QueryBuilder(API.BROADCAST_MEMBERS(broadcastId)).page(page).showBy(showBy).build()

  const { data }: APIResultsResponse<any[]> = await api.get(url)

  return data
}
