import { FC, ReactNode, useRef } from 'react'
import cls from 'classnames'

import { InfiniteScroll } from '../../common'
import { useAdaptiveLayout } from '../../../hooks'
import { CHAT_DETAILS_ID } from '../../../containers'
import { PAGINATION_DEFAULT_SHOW_BY } from '../../../../types'

import styles from './ChatMembersList.module.scss'

export type TChatMembersListProps = {
  total: number
  page: number
  loading: boolean
  limit?: number
  dataLength: number
  className?: string
  header?: ReactNode
  scrollableTarget?: ReactNode
  onChangePage: (page: number) => void
}

export const ChatMembersList: FC<TChatMembersListProps> = ({
  total,
  page,
  dataLength,
  children,
  loading,
  onChangePage,
  header,
  className,
  scrollableTarget,
  limit = PAGINATION_DEFAULT_SHOW_BY
}) => {
  const rootRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={rootRef} className={cls(styles.root, className)}>
      {header}

      <InfiniteScroll
        next={onChangePage}
        loading={loading}
        total={total}
        page={page}
        dataLength={dataLength}
        limit={limit}
        scrollableTarget={scrollableTarget || rootRef.current}
      >
        {children}
      </InfiniteScroll>
    </div>
  )
}

export const ChatMembersListTab: FC<TChatMembersListProps> = ({ scrollableTarget, ...props }) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <ChatMembersList {...props} scrollableTarget={isDesktop ? CHAT_DETAILS_ID : scrollableTarget} />
  )
}
