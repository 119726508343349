export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST'
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR'
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'

export const GET_ORGANIZATIONS_MEMBERS = 'GET_ORGANIZATIONS_MEMBERS'
export const GET_ORGANIZATIONS_MEMBERS_REQUEST = 'GET_ORGANIZATIONS_MEMBERS_REQUEST'
export const GET_ORGANIZATIONS_MEMBERS_ERROR = 'GET_ORGANIZATIONS_MEMBERS_ERROR'
export const GET_ORGANIZATIONS_MEMBERS_SUCCESS = 'GET_ORGANIZATIONS_MEMBERS_SUCCESS'

export const SET_ORGANIZATIONS_MEMBERS_SEARCH = 'SET_ORGANIZATIONS_MEMBERS_SEARCH'

export const SET_ORGANIZATIONS_MEMBERS_CURRENT_PAGE = 'SET_ORGANIZATIONS_MEMBERS_CURRENT_PAGE'

export const RESET_ORGANIZATIONS = 'RESET_ORGANIZATIONS'
export const RESET_ORGANIZATION_MEMBERS = 'RESET_ORGANIZATION_MEMBERS'

export const GET_ORGANIZATION_REGISTERED_USERS = 'GET_ORGANIZATION_REGISTERED_USERS'
export const GET_ORGANIZATION_REGISTERED_USERS_REQUEST = 'GET_ORGANIZATION_REGISTERED_USERS_REQUEST'
export const GET_ORGANIZATION_REGISTERED_USERS_ERROR = 'GET_ORGANIZATION_REGISTERED_USERS_ERROR'
export const GET_ORGANIZATION_REGISTERED_USERS_SUCCESS = 'GET_ORGANIZATION_REGISTERED_USERS_SUCCESS'

export const CREATE_ORGANIZATION_CHAT = 'CREATE_ORGANIZATION_CHAT'
export const CREATE_ORGANIZATION_CHAT_REQUEST = 'CREATE_ORGANIZATION_CHAT_REQUEST'
export const CREATE_ORGANIZATION_CHAT_SUCCESS = 'CREATE_ORGANIZATION_CHAT_SUCCESS'
export const CREATE_ORGANIZATION_CHAT_ERROR = 'CREATE_ORGANIZATION_CHAT_ERROR'

export const GET_VOICE_ROOMS = 'GET_VOICE_ROOMS'
export const GET_VOICE_ROOMS_REQUEST = 'GET_VOICE_ROOMS_REQUEST'
export const GET_VOICE_ROOMS_SUCCESS = 'GET_VOICE_ROOMS_SUCCESS'
export const GET_VOICE_ROOMS_ERROR = 'GET_VOICE_ROOMS_ERROR'

export const JOIN_VOICE_ROOM = 'JOIN_VOICE_ROOM'
export const JOIN_VOICE_ROOM_REQUEST = 'JOIN_VOICE_ROOM_REQUEST'
export const JOIN_VOICE_ROOM_SUCCESS = 'JOIN_VOICE_ROOM_SUCCESS'
export const JOIN_VOICE_ROOM_ERROR = 'JOIN_VOICE_ROOM_ERROR'

export const LEAVE_VOICE_ROOM = 'LEAVE_VOICE_ROOM'
export const LEAVE_VOICE_ROOM_REQUEST = 'LEAVE_VOICE_ROOM_REQUEST'
export const LEAVE_VOICE_ROOM_SUCCESS = 'LEAVE_VOICE_ROOM_SUCCESS'
export const LEAVE_VOICE_ROOM_ERROR = 'LEAVE_VOICE_ROOM_ERROR'

export const RECEIVE_JOIN_VOICE_ROOM = 'RECEIVE_JOIN_VOICE_ROOM'

export const RECEIVE_LEAVE_VOICE_ROOM = 'RECEIVE_LEAVE_VOICE_ROOM'

export const UPDATE_VOICE_ROOM_MEMBERS = 'UPDATE_VOICE_ROOM_MEMBERS'

export const CLOSE_VOICE_ROOM = 'CLOSE_VOICE_ROOM'

export const GET_ORGANIZATIONS_INVITATIONS = 'GET_ORGANIZATIONS_INVITATIONS'
export const GET_ORGANIZATIONS_INVITATIONS_REQUEST = 'GET_ORGANIZATIONS_INVITATIONS_REQUEST'
export const GET_ORGANIZATIONS_INVITATIONS_ERROR = 'GET_ORGANIZATIONS_INVITATIONS_ERROR'
export const GET_ORGANIZATIONS_INVITATIONS_SUCCESS = 'GET_ORGANIZATIONS_INVITATIONS_SUCCESS'

export const RESET_ORGANIZATIONS_INVITATIONS_FILTERS = 'RESET_ORGANIZATIONS_INVITATIONS_FILTERS'

export const GET_ORGANIZATION_ADMINISTRATIONS = 'GET_ORGANIZATION_ADMINISTRATIONS'
export const GET_ORGANIZATION_ADMINISTRATIONS_REQUEST = 'GET_ORGANIZATION_ADMINISTRATIONS_REQUEST'
export const GET_ORGANIZATION_ADMINISTRATIONS_SUCCESS = 'GET_ORGANIZATION_ADMINISTRATIONS_SUCCESS'
export const GET_ORGANIZATION_ADMINISTRATIONS_ERROR = 'GET_ORGANIZATION_ADMINISTRATIONS_ERROR'

export const GET_ORGANIZATION_NEW_USERS = 'GET_ORGANIZATION_NEW_USERS'
export const GET_ORGANIZATION_NEW_USERS_REQUEST = 'GET_ORGANIZATION_NEW_USERS_REQUEST'
export const GET_ORGANIZATION_NEW_USERS_SUCCESS = 'GET_ORGANIZATION_NEW_USERS_SUCCESS'
export const GET_ORGANIZATION_NEW_USERS_ERROR = 'GET_ORGANIZATION_NEW_USERS_ERROR'

export const KICK_USER_FROM_VOICE_ROOM = 'KICK_USER_FROM_VOICE_ROOM'

export const INVITE_ALL_ORGANIZATION_MEMBERS = 'INVITE_ALL_ORGANIZATION_MEMBERS'
export const INVITE_ALL_ORGANIZATION_MEMBERS_REQUEST = 'INVITE_ALL_ORGANIZATION_MEMBERS_REQUEST'
export const INVITE_ALL_ORGANIZATION_MEMBERS_SUCCESS = 'INVITE_ALL_ORGANIZATION_MEMBERS_SUCCESS'
export const INVITE_ALL_ORGANIZATION_MEMBERS_ERROR = 'INVITE_ALL_ORGANIZATION_MEMBERS_ERROR'

export const RECEIVE_REMOVED_FROM_ADMIN = 'RECEIVE_REMOVED_FROM_ADMIN'

export const RECEIVE_LOST_LEADER_RIGHTS_IN_DEPARTMENTS = 'RECEIVE_LOST_LEADER_RIGHTS_IN_DEPARTMENTS'
export const RECEIVE_DELEGATED_LEADER_RIGHTS_IN_DEPARTMENTS =
  'RECEIVE_DELEGATED_LEADER_RIGHTS_IN_DEPARTMENTS'

export const RECEIVE_LOST_ACCESS_TO_VOICE_CHANNEL = 'RECEIVE_LOST_ACCESS_TO_VOICE_CHANNEL'
export const RECEIVE_ADDED_USER_TO_VOICE_CHANNEL = 'RECEIVE_ADDED_USER_TO_VOICE_CHANNEL'

export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID'
