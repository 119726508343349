import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { RelatedAccountsListItem } from 'App/components'
import { createLoadingSelector, GET_RELATED_ACCOUNTS, switchAccountRequest } from 'store'

export type TRelatedAccountsListItemContainerOwnProps = {
  id: string
}

export type TRelatedAccountsListItemContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_RELATED_ACCOUNTS])

const mapStateToProps = (state: State, { id }: TRelatedAccountsListItemContainerOwnProps) => ({
  id: state.userProfile.relatedAccounts.list[id]?.id,
  type: state.userProfile.relatedAccounts.list[id]?.type?.name,
  firstName: state.userProfile.relatedAccounts.list[id]?.firstName,
  lastName: state.userProfile.relatedAccounts.list[id]?.lastName,
  displayUserName: state.userProfile.relatedAccounts.list[id]?.displayUserName,
  role: state.userProfile.relatedAccounts.list[id]?.role?.name,
  notificationsCount: state.userProfile.relatedAccounts.notifications.accounts[id] || 0,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      switchAccount: switchAccountRequest
    },
    dispatch
  )

export const RelatedAccountsListItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedAccountsListItem)
