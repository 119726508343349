import { Dispatch, SetStateAction } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import uniqBy from 'lodash/uniqBy'
import { MutationFunction } from '@tanstack/react-query'

import { EAccountsSelectType } from 'App/components/common'
import { getContacts } from 'api/contacts'
import { TOrganization, accountIdSelector, useAppSelector } from 'store'
import { getCategories } from 'api/categories'
import { getOrganizationDepartments, getOrganizations } from 'api/organizations'
import { TAccountsSelectGroupsItem } from 'App/components/common/AccountsSelect/AccountsSelectGroups'
import { IDepartment } from 'interfaces/api/organizations/departments'
import { useSelectAccounts } from 'App/hooks/useSelectAccounts'
import { useMutation, useQuery } from 'services/query'

import { parseCategoriesResponse, parseDepartmentsResponse } from './SelectRecipients.utils'

export type TBroadcastRecipientsType = 'contacts' | 'staff' | 'all'

export type TUseSelectRecipientsRequestsProps = Pick<
  ReturnType<typeof useSelectAccounts>['state'],
  'page' | 'search' | 'searchBy' | 'selectedAccounts'
> & {
  selectType: EAccountsSelectType
  recipientsType: TBroadcastRecipientsType
  setCategories: Dispatch<SetStateAction<TAccountsSelectGroupsItem[]>>
  setOrganizations: Dispatch<SetStateAction<TAccountsSelectGroupsItem[]>>

  accountType?: AccountTypeNames
  organizationId?: string
}

export const useSelectRecipientsRequests = ({
  selectType,
  accountType,
  recipientsType,
  page,
  search,
  searchBy,
  selectedAccounts,
  organizationId,
  setCategories,
  setOrganizations
}: TUseSelectRecipientsRequestsProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const { data: contacts, isInitialLoading: isContactsLoading } = useQuery({
    queryKey: ['contacts', page, search, searchBy],
    queryFn: () =>
      getContacts({
        page,
        search,
        accountType,
        searchBy,
        accountId,
        organizationIds: organizationId ? [organizationId] : undefined
      }),

    keepPreviousData: true,
    cacheTime: 0,
    enabled: !!searchBy.group && selectType === EAccountsSelectType.BY_USER
  })

  const { isInitialLoading: isCategoriesLoading } = useQuery({
    queryKey: ['categories', page, search, searchBy],
    queryFn: () => getCategories(search),
    cacheTime: 0,
    enabled:
      selectType === EAccountsSelectType.BY_GROUP &&
      (recipientsType === 'all' || recipientsType === 'contacts'),
    onSuccess: (response) => {
      if (recipientsType === 'contacts') {
        const data =
          response?.map<TAccountsSelectGroupsItem>(({ id, name }) => ({
            label: name,
            value: id,
            selected: !!selectedAccounts.find((item) => item.id === id)
          })) ?? []

        setCategories(data)
      }

      if (recipientsType === 'all') {
        const parsedCategories = parseCategoriesResponse({ categories: response })

        setCategories(parsedCategories)
      }
    }
  })

  const { isInitialLoading: isDepartmentsLoading } = useQuery({
    queryKey: ['departments', page, search, searchBy],
    queryFn: () =>
      organizationId
        ? getOrganizationDepartments({ organizationId, showBy: Number.MAX_SAFE_INTEGER, search })
        : undefined,
    cacheTime: 0,
    enabled: selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'staff',
    onSuccess: (response) => {
      const data =
        response?.results?.map<TAccountsSelectGroupsItem>(({ id, name }) => ({
          label: name,
          value: id,
          selected: !!selectedAccounts.find((item) => item.id === id)
        })) ?? []

      setOrganizations(data)
    }
  })

  const handleGetDepartments: MutationFunction<IDepartment[], TOrganization> = async (
    organization
  ) => {
    const response = await getOrganizationDepartments({
      organizationId: organization.id,
      showBy: Number.MAX_SAFE_INTEGER
    })

    return response.results
  }

  const { mutate: getDepartments, isLoading: isOrganizationDepartmentsLoading } = useMutation({
    mutationKey: ['organization_departments'],
    mutationFn: handleGetDepartments,
    onSuccess: (response, organization) => {
      const parsedOrganization = parseDepartmentsResponse({
        organization,
        departments: response
      })

      setOrganizations((prev) =>
        parsedOrganization ? uniqBy([...prev, parsedOrganization], 'value') : prev
      )
    }
  })

  const { isInitialLoading: isOrganizationsLoading } = useQuery({
    queryKey: ['organizations', page, search, searchBy],
    queryFn: () => getOrganizations(),
    cacheTime: 0,
    enabled: selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'all',
    onSuccess: (response) =>
      response.results?.forEach((organization) => getDepartments(organization))
  })

  return {
    groupsLoading:
      isCategoriesLoading ||
      isOrganizationsLoading ||
      isDepartmentsLoading ||
      isOrganizationDepartmentsLoading,
    isContactsLoading,
    contacts
  }
}
