import { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { getCommunity, editCommunityOrganizationSettings } from 'api/communities'
import { toastDefaultOptions } from 'globalConstants'
import { getEvents } from 'api/events'

import { TCommunityOrganizerSettingsFormValues } from './CommunityOrganizerSettings'

type TUseCommunityOrganizerSettingsRequestProps = { ignore: () => void }

const COMMUNITY_QUERY_KEY = 'get-community'
const COMMUNITY_EVENTS_QUERY_KEY = 'get-community-events'

export const useCommunityOrganizerSettingsRequest = ({
  ignore
}: TUseCommunityOrganizerSettingsRequestProps) => {
  const { params } = useRouteMatch<{ id: string }>()

  const [initialValues, setInitialValues] = useState<TCommunityOrganizerSettingsFormValues>()

  const { t } = useTranslation()

  const communityId = params.id

  const { data: events, isFetching: isFetchingEvents } = useQuery({
    queryKey: [COMMUNITY_EVENTS_QUERY_KEY],
    queryFn: () => getEvents({ isOrganizer: true })
  })

  const { isFetching } = useQuery({
    queryKey: [COMMUNITY_QUERY_KEY],
    queryFn: () => getCommunity({ id: communityId }),
    enabled: Boolean(communityId),
    onSuccess: ({ publicity, membershipEvents }) => {
      setInitialValues({
        publicity,
        membershipEventIds: membershipEvents.map(({ id }) => id)
      })
    }
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: editCommunityOrganizationSettings,
    onSuccess: () => {
      toast.success(t('common.toast.changesSaved'), toastDefaultOptions)
    }
  })

  const handleFormSubmit = useCallback(
    ({ membershipEventIds, publicity }: TCommunityOrganizerSettingsFormValues) => {
      ignore()

      return mutate({
        id: communityId,
        membershipEventIds,
        publicity
      })
    },
    [communityId, ignore, mutate]
  )

  return {
    events,
    initialValues,
    isLoading,
    loading: isFetchingEvents || isFetching,
    handleFormSubmit
  }
}
