import { PAGINATION_DEFAULT } from 'types'

import * as actionTypes from './events.actionTypes'
import { TEventsAction, TEventsState } from './events.types'

const initialState: TEventsState = {
  currentEventId: null,
  data: {
    list: {},
    ids: [],
    filters: PAGINATION_DEFAULT
  }
}

export const eventsReducer = (
  state: TEventsState = initialState,
  action: TEventsAction
): TEventsState => {
  switch (action.type) {
    case actionTypes.GET_EVENTS_SUCCESS: {
      const { list, ids, total } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          list,
          ids,
          filters: {
            ...state.data.filters,
            total
          }
        }
      }
    }

    case actionTypes.RESET_EVENTS: {
      return initialState
    }

    case actionTypes.SET_EVENT_ID: {
      return {
        ...state,
        currentEventId: action.payload.nextEventId
      }
    }

    default:
      return state
  }
}
