import { useCallback, useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TChatRoomHeaderContainerProps, TChatRoomHeaderP2PContainerProps } from 'App/containers'
import {
  ChatRoomHeaderWrapper,
  ChatRoomHeaderUser,
  ChatRoomHeaderActions,
  Button,
  ChatPinTitle
} from 'App/components'
import { EIconSize } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'
import { stopPropagation } from 'utils'

import { ChatRoomCaseName } from '../ChatRoomCaseName'
import { ChatRoomBackAction } from '../ChatRoomBackAction'
import { useChatContext } from '../../../../context'

import styles from './ChatRoomHeaderP2P.module.scss'

export type TChatRoomHeaderP2PProps = TChatRoomHeaderContainerProps &
  TChatRoomHeaderP2PContainerProps

export const ChatRoomHeaderP2P = ({
  selectedChat,
  online,
  lastSeen,
  caseType,
  accountId,
  filters,
  title,
  isChatRoomMuted,
  loading,
  setChatFilters,
  resetSelectedChat
}: TChatRoomHeaderP2PProps) => {
  const { t } = useTranslation()

  const { isDesktop } = useAdaptiveLayout()

  const {
    showPinnedMessages,
    showChatDetails,
    togglePinnedMessages,
    displayChatDetails,
    hideChatDetails
  } = useChatContext()

  const handleHideChatDetails = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e && stopPropagation(e)

      hideChatDetails()
    },
    [hideChatDetails]
  )

  const getTitle = () => {
    if (showPinnedMessages) {
      return <ChatPinTitle />
    }

    return (
      !showChatDetails &&
      (title ||
        (selectedChat?.case?.title ? (
          <ChatRoomCaseName
            chatId={selectedChat.id}
            caseStatus={selectedChat.case.status}
            title={selectedChat.case.title}
            isChatRoomMuted={isChatRoomMuted}
          />
        ) : (
          <ChatRoomHeaderUser
            disabled={!!selectedChat?.case?.id}
            avatar={true}
            online={online}
            lastSeen={lastSeen}
            selectedChat={selectedChat}
            classes={{ name: styles.userName }}
            isChatRoomMuted={isChatRoomMuted}
          />
        )))
    )
  }

  useEffect(
    () => () => {
      handleHideChatDetails()
    },
    // `selectedChat?.id` is required
    [selectedChat?.id, handleHideChatDetails]
  )

  const handleResetChat = useCallback(() => {
    resetSelectedChat()

    if (!isDesktop) {
      setChatFilters(filters)
    }
  }, [filters, isDesktop, resetSelectedChat, setChatFilters])

  return (
    <ChatRoomHeaderWrapper
      loading={loading}
      onClick={showPinnedMessages ? undefined : displayChatDetails}
      backButton={
        showChatDetails || showPinnedMessages ? (
          <Button
            icon={<ChevronLeftIcon />}
            variant="inline"
            onClick={showPinnedMessages ? togglePinnedMessages : handleHideChatDetails}
            size="md"
            className={styles.backButton}
          >
            {t('chat.details.backButton')}
          </Button>
        ) : (
          <ChatRoomBackAction caseType={caseType} onResetChat={handleResetChat} />
        )
      }
      title={getTitle()}
      actions={
        selectedChat &&
        !showPinnedMessages && (
          <ChatRoomHeaderActions
            id={selectedChat.id}
            type={selectedChat.type}
            status={selectedChat.status}
            iconSize={EIconSize.MD}
            caseId={selectedChat.case?.id}
            isOwner={selectedChat.case?.owner.id === accountId}
            isChatOwner={selectedChat.channelManager?.id === accountId}
            userName={selectedChat.interlocutorAccount?.displayUserName}
            isContact={selectedChat.isContact}
          />
        )
      }
      classes={{ root: cls(styles.wrapperRoot, { [styles.clickable]: !showChatDetails }) }}
    />
  )
}
