import { useMemo } from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { useAppSelector } from 'store'
import { TabMenu } from 'App/components/common/TabMenu'
import { TNavMenuItem } from 'App/components/common/NavMenu'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { RoleRouteContainer } from 'App/containers/RoleRouteContainer'
import { EventAttendeesInterests } from 'App/components/Events/EventAttendeesInterests'
import { EventsAttendeesList } from 'App/components/Events/EventsAttendeesList'

import { Button } from '../../../components'
import { getEvent } from '../../../../api/events'
import { EventInvitePopover } from '../../../containers/InvitePopover'

import styles from './AttendeesInfo.module.scss'

export const AttendeesInfo = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const accountData = useAppSelector((state) => state.global.accountData)

  const { t } = useTranslation()

  const eventId = params.id
  const invitesPath = `/events/${eventId}/edit/attendees/invites`
  const interestsPath = `/events/${eventId}/edit/attendees/interests`

  const { data } = useQuery({ queryKey: ['event'], queryFn: () => getEvent(eventId) })
  const isEventOrganizer = data?.organizerId === accountData?.id

  const menuList = useMemo<TNavMenuItem[]>(
    () => [
      {
        link: invitesPath,
        title: t('events.attendees.tabs.invites'),
        exact: true
      },
      {
        link: interestsPath,
        title: t('events.attendees.tabs.interests'),
        exact: true
      }
    ],
    [interestsPath, invitesPath, t]
  )

  return (
    <>
      <header className={styles.header}>
        {isEventOrganizer && (
          <EventInvitePopover id={eventId} name={data?.title ?? ''} placement="bottomRight">
            <Button icon={<PlusIcon />} size="xs">
              {t('events.attendees.inviteButton')}
            </Button>
          </EventInvitePopover>
        )}
      </header>
      <TabMenu
        tabVariant="static"
        navMenuProps={{ tabPosition: 'top' }}
        menuList={menuList}
        classes={{ root: styles.tabMenu }}
      />

      <Switch>
        <RoleRouteContainer
          exact
          path={invitesPath}
          render={() => <EventsAttendeesList eventId={eventId} />}
        />

        <RoleRouteContainer
          exact
          path={interestsPath}
          render={() => <EventAttendeesInterests eventId={eventId} />}
        />

        <Redirect to={invitesPath} />
      </Switch>
    </>
  )
}
