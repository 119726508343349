import {
  put,
  takeLatest,
  call,
  select,
  fork,
  delay,
  debounce,
  take,
  race
} from 'redux-saga/effects'
import {
  CaseTypesEnum,
  ChatTypeEnum,
  MessageTypeEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'

import {
  IDefaultStandaloneNotificationDTO,
  INotificationsGeneralDTO,
  INotificationsProtocolDTO,
  INotificationsProtocolUserDTO,
  IChatRoomsDTO
} from 'interfaces'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { SECOND, SHOW_SECOND_MESSAGE_PUSH_UP } from 'globalConstants'
import { QueryBuilder } from 'utils'
import {
  DELETE_ALL_MESSAGES_SUCCESS,
  READ_ALL_MESSAGES_SUCCESS,
  READ_MESSAGES_SUCCESS,
  RECEIVE_NEW_MESSAGE_SUCCESS,
  TReceiveNewMessageSuccess
} from 'store/chatMessages'
import {
  SET_CASES_INVITE_LIST,
  GET_CONTACTS_SUCCESS,
  caseClearNotificationAction,
  setNewLineDescriptionAction,
  generalNotificationNormalize,
  setNewLineOpinionAction,
  CASE_MEMBER_KICKED_ACTION,
  notificationProtocolUsersNormalize,
  CASE_VIEW_SUCCESS,
  notificationProtocolNormalize,
  selectGroupChat,
  TAction,
  organizationsNotificationsNormalize,
  organizationNotificationsNormalize,
  unityHubNotificationsNormalize,
  RECEIVE_BROADCAST_CREATED,
  RECEIVE_UPDATE_BROADCAST_STATUS,
  RECEIVE_BROADCAST_DELETED,
  RECEIVE_BROADCAST_WATCHED
} from 'store'
import { LOST_ACCESS_TO_EVENT } from 'store/events'
import { State } from 'redux/rootReducer'
import { Pagination, PAGINATION_DEFAULT_SHOW_BY } from 'types'
import {
  RECEIVE_ADDED_USER_TO_CHAT,
  RECEIVE_CHAT_MUTED,
  RECEIVE_CHAT_UNMUTED
} from 'store/chatRooms'
import { handleError } from 'api/utils'

import { TNotificationsProtocol } from './notifications.types'
import {
  getNotificationsGeneralSuccess,
  getNotificationsGeneralError,
  readNotificationFromIdSuccess,
  readNotificationFromIdError,
  messagePushUp,
  getNotificationsProtocolUsersError,
  getNotificationsProtocolUsersSuccess,
  getNotificationsProtocolSuccess,
  getNotificationsProtocolError,
  refreshNotificationCountersSuccess,
  refreshNotificationCountersError,
  readNotificationFromIdRequest,
  getNotificationsProtocolUsersRequest,
  getNotificationsProtocolRequest,
  clearNotificationAction,
  receiveNewMessageNotificationsRequest,
  receiveNewMessageNotificationsSuccess,
  receiveNewOrganizationNotifications,
  receiveNewOrganizationNotificationsSuccess,
  receiveNewCommunityNotificationsSuccess,
  receiveNewCommunityNotifications,
  receiveNewEventNotifications,
  receiveNewEventNotificationsSuccess
} from './notifications.actions'
import {
  CLEAR_NOTIFICATION,
  CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT,
  GET_NOTIFICATIONS_GENERAL_REQUEST,
  GET_NOTIFICATIONS_PROTOCOL_REQUEST,
  GET_NOTIFICATIONS_PROTOCOL_USERS_REQUEST,
  READ_NOTIFICATION_FROM_ID_REQUEST,
  READ_NOTIFICATION_FROM_ID_SUCCESS,
  RECEIVE_NEW_COMMUNITY_NOTIFICATIONS,
  RECEIVE_NEW_EVENT_NOTIFICATIONS,
  RECEIVE_NEW_MESSAGE_NOTIFICATIONS_REQUEST,
  RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS,
  REFRESH_NOTIFICATION_COUNTERS_REQUEST,
  REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE,
  REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE
} from './notifications.actionTypes'

function* readNotificationFromId({
  payload: { notificationIds, callbackAction }
}: ReturnType<typeof readNotificationFromIdRequest>) {
  try {
    const notifications: IDefaultStandaloneNotificationDTO[] = yield select(
      (state: State) => state.caseView.data?.notifications ?? []
    )
    yield call(api.patch, API.NOTIFICATIONS, { notificationIds })

    yield put(readNotificationFromIdSuccess())

    const newNotifications = notifications.filter(({ id }) => !notificationIds.includes(id))

    yield put(caseClearNotificationAction({ notifications: newNotifications }))

    if (callbackAction) {
      yield put(callbackAction)
    }
  } catch (e) {
    yield put(readNotificationFromIdError(e))
    yield handleError(e)
  }
}

function* general(action: TAction) {
  try {
    // API returns group chat data only when chat id is passed
    const groupChat: IChatRoomsDTO | undefined = yield select(selectGroupChat)

    // ignore case group chat messages
    if (action.payload?.case?.type === CaseTypesEnum.A2A) {
      return
    }

    const searchQuery = groupChat ? `?chatId=${groupChat.id}` : ''

    const {
      data: {
        cases,
        chats,
        invitations,
        headerNotification,
        connector,
        contacts,
        organizations,
        communities,
        events,
        showCaseNotification
      }
    }: APIData<INotificationsGeneralDTO> = yield call(
      api.get,
      `${API.NOTIFICATIONS_GENERAL}${searchQuery}`
    )

    const { ids, list } = generalNotificationNormalize(chats?.unreadNotificationsPerUser)
    const totalUnreadCount = chats?.unreadNotificationsPerUser.filter(
      (item) => !item.mutedUntil
    ).length

    yield put(
      getNotificationsGeneralSuccess({
        contacts,
        connector,
        cases,
        invitations,
        chats: {
          totalUnreadCount,
          totalUnreadContactCount: chats?.totalUnreadContactCount,
          totalUnreadCaseCount: chats?.totalUnreadCaseCount,
          isChatUnread: chats?.isChatUnread,
          ids,
          list
        },
        headerNotification,
        organizations: organizationsNotificationsNormalize(organizations),
        communities: unityHubNotificationsNormalize(communities),
        events: unityHubNotificationsNormalize(events),
        showCaseNotification
      })
    )
  } catch (e) {
    yield put(getNotificationsGeneralError(e))
    yield handleError(e)
  }
}

function* clearNotificationSaga({
  payload: { producedNotificationType, userId }
}: ReturnType<typeof clearNotificationAction>) {
  switch (producedNotificationType) {
    case ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED: {
      const notifications: IDefaultStandaloneNotificationDTO[] = yield select(
        (state: State) => state.caseView.data?.notifications ?? []
      )

      const newNotifications: IDefaultStandaloneNotificationDTO[] = notifications.filter(
        ({ type }) => type !== producedNotificationType
      )

      yield put(caseClearNotificationAction({ notifications: newNotifications }))
      yield put(setNewLineDescriptionAction({ newLineDescriptionId: null }))
      break
    }

    case ProducedNotificationsEnum.CASE_OPINION_CREATED: {
      if (userId) {
        const notifications: IDefaultStandaloneNotificationDTO[] = yield select(
          (state: State) => state.caseView.data?.notifications ?? []
        )

        const newNotifications = notifications.reduce<IDefaultStandaloneNotificationDTO[]>(
          (acc, item) => {
            if (item.payload?.userId === userId) {
              item.viewed = true
            }

            acc.push(item)
            return acc
          },
          []
        )

        yield put(caseClearNotificationAction({ notifications: newNotifications }))
      }

      yield put(setNewLineOpinionAction({ newLineOpinionId: null }))
      break
    }

    default:
      break
  }
}

function* hideMessagePushUpSaga() {
  yield put(messagePushUp({ show: false, chatId: null }))
}

function* showMessagePushUpSaga({ payload }: TReceiveNewMessageSuccess) {
  const {
    chatId,
    case: chatCase,
    type: messageType,
    yourMessage,
    sender,
    caseStatus,
    chat,
    createdAt,
    updatedAt
  } = payload.message
  const { id: caseId, ownerId } = chatCase || {}
  const isUpdated = createdAt !== updatedAt

  const accountId: string | undefined = yield select((state: State) => state.global.accountData?.id)
  const selectedChatId: string | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat?.id
  )
  const show =
    !isUpdated &&
    !selectedChatId &&
    messageType !== MessageTypeEnum.SYSTEM &&
    !yourMessage &&
    chat?.type !== ChatTypeEnum.ORGANIZATION &&
    chat?.type !== ChatTypeEnum.COMMUNITY_CHANNEL &&
    chat?.type !== ChatTypeEnum.COMMUNITY_NEWS &&
    chat?.type !== ChatTypeEnum.EVENT_CHANNEL &&
    chat?.type !== ChatTypeEnum.EVENT_NEWS &&
    chat?.type !== ChatTypeEnum.CASE_GROUP &&
    chat?.type !== ChatTypeEnum.GROUP

  yield put(
    messagePushUp({
      show,
      chatId,
      caseId,
      caseStatus,
      isCaseOwner: accountId === ownerId,
      accountTypeId: sender?.type?.id
    })
  )

  if (show) {
    yield delay(SHOW_SECOND_MESSAGE_PUSH_UP * SECOND)
    yield fork(hideMessagePushUpSaga)
  }
}

function* getNotificationsProtocolUsersSaga({
  payload
}: ReturnType<typeof getNotificationsProtocolUsersRequest>) {
  try {
    const pagination: Pagination = yield select(
      (state: State) => state.notifications.protocolUsers.pagination
    )

    const { page = pagination.current, showBy = pagination.showBy } = payload ?? {}

    const url = new QueryBuilder(API.NOTIFICATIONS_PROTOCOL_USERS).page(page).showBy(showBy).build()

    const {
      data: { results, total }
    }: APIResultsResponse<INotificationsProtocolUserDTO[]> = yield call(api.get, url)

    yield put(
      getNotificationsProtocolUsersSuccess({
        ...notificationProtocolUsersNormalize(results),
        pagination: {
          total,
          current: page,
          showBy
        }
      })
    )
  } catch (e) {
    yield put(getNotificationsProtocolUsersError(e))
    yield handleError(e)
  }
}

function* refreshCountersRequestSaga() {
  try {
    const pagination: Pagination = yield select(
      (state: State) => state.notifications.protocolUsers.pagination
    )
    const url = new QueryBuilder(API.NOTIFICATIONS_PROTOCOL_USERS)
      .showBy(pagination.showBy ?? PAGINATION_DEFAULT_SHOW_BY)
      .page(pagination.current ?? 0)
      .build()

    const {
      data: { results }
    }: APIResultsResponse<INotificationsProtocolUserDTO[]> = yield call(api.get, url)

    yield put(refreshNotificationCountersSuccess(results))
  } catch (e) {
    yield put(refreshNotificationCountersError(e))
    yield handleError(e)
  }
}

function* getNotificationsProtocolSaga({
  payload: { id, page, showBy = PAGINATION_DEFAULT_SHOW_BY }
}: ReturnType<typeof getNotificationsProtocolRequest>) {
  try {
    const { ids: protocolIds, list: protocolList }: TNotificationsProtocol = yield select(
      (state: State) => state.notifications.protocol
    )

    const url = new QueryBuilder(API.NOTIFICATIONS_PROTOCOL)
      .custom('userId', id)
      .page(page)
      .showBy(showBy)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<INotificationsProtocolDTO[]> = yield call(api.get, url)

    const { ids, list } = notificationProtocolNormalize(results)

    let payload = {
      ids,
      list,
      pagination: {
        total,
        current: page,
        showBy
      }
    }

    if (page > 0) {
      payload = {
        ids: [...protocolIds, ...ids],
        list: { ...protocolList, ...list },
        pagination: {
          total,
          current: page,
          showBy
        }
      }
    }

    yield put(getNotificationsProtocolSuccess(payload))
  } catch (e) {
    yield put(getNotificationsProtocolError(e))
    yield handleError(e)
  }
}

function* refreshCountersWatchSaga() {
  yield debounce(1000, [REFRESH_NOTIFICATION_COUNTERS_REQUEST], refreshCountersRequestSaga)
}

function* refreshCountersSubscriptionSaga() {
  while (true) {
    yield take(REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE)

    yield race({
      task: call(refreshCountersWatchSaga),
      cancel: take(REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE)
    })
  }
}

function* receiveNewMessageNotificationsSaga({
  payload: {
    totalUnreadContactCount,
    totalUnreadCaseCount,
    unreadNotificationsPerUser,
    isChatUnread
  }
}: ReturnType<typeof receiveNewMessageNotificationsRequest>) {
  try {
    const { ids, list } = generalNotificationNormalize(unreadNotificationsPerUser)

    const totalUnreadCount = unreadNotificationsPerUser.filter((item) => !item.mutedUntil).length

    yield put(
      receiveNewMessageNotificationsSuccess({
        totalUnreadCount,
        totalUnreadContactCount,
        totalUnreadCaseCount,
        isChatUnread,
        ids,
        list
      })
    )
  } catch (e) {
    handleError(e)
  }
}

function* receiveNewOrganizationNotificationsSaga({
  payload
}: ReturnType<typeof receiveNewOrganizationNotifications>) {
  try {
    const normalizedData = organizationNotificationsNormalize(payload)

    yield put(receiveNewOrganizationNotificationsSuccess(normalizedData))
  } catch (e) {
    handleError(e)
  }
}

function* receiveNewCommunityNotificationsSaga({
  payload
}: ReturnType<typeof receiveNewCommunityNotifications>) {
  try {
    const normalizedData = unityHubNotificationsNormalize(payload)

    yield put(receiveNewCommunityNotificationsSuccess(normalizedData))
  } catch (e) {
    handleError(e)
  }
}

function* receiveNewEventNotificationsSaga({
  payload
}: ReturnType<typeof receiveNewEventNotifications>) {
  try {
    const normalizedData = unityHubNotificationsNormalize(payload)

    yield put(receiveNewEventNotificationsSuccess(normalizedData))
  } catch (e) {
    handleError(e)
  }
}

export function* notificationsSaga() {
  yield debounce(
    1000,
    [
      GET_NOTIFICATIONS_GENERAL_REQUEST,
      SET_CASES_INVITE_LIST,
      GET_CONTACTS_SUCCESS,
      READ_ALL_MESSAGES_SUCCESS,
      READ_MESSAGES_SUCCESS,
      READ_NOTIFICATION_FROM_ID_SUCCESS,
      CASE_MEMBER_KICKED_ACTION,
      DELETE_ALL_MESSAGES_SUCCESS,
      CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT,
      CASE_VIEW_SUCCESS,
      RECEIVE_CHAT_MUTED,
      RECEIVE_CHAT_UNMUTED,
      RECEIVE_ADDED_USER_TO_CHAT,
      LOST_ACCESS_TO_EVENT,
      RECEIVE_BROADCAST_CREATED,
      RECEIVE_UPDATE_BROADCAST_STATUS,
      RECEIVE_BROADCAST_DELETED,
      RECEIVE_BROADCAST_WATCHED
    ],
    general
  )
  yield takeLatest(READ_NOTIFICATION_FROM_ID_REQUEST, readNotificationFromId)
  yield takeLatest(CLEAR_NOTIFICATION, clearNotificationSaga)
  yield takeLatest(RECEIVE_NEW_MESSAGE_SUCCESS, showMessagePushUpSaga)
  yield takeLatest(GET_NOTIFICATIONS_PROTOCOL_USERS_REQUEST, getNotificationsProtocolUsersSaga)
  yield takeLatest(GET_NOTIFICATIONS_PROTOCOL_REQUEST, getNotificationsProtocolSaga)
  yield takeLatest(RECEIVE_NEW_MESSAGE_NOTIFICATIONS_REQUEST, receiveNewMessageNotificationsSaga)
  yield takeLatest(RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS, receiveNewOrganizationNotificationsSaga)
  yield takeLatest(RECEIVE_NEW_COMMUNITY_NOTIFICATIONS, receiveNewCommunityNotificationsSaga)
  yield takeLatest(RECEIVE_NEW_EVENT_NOTIFICATIONS, receiveNewEventNotificationsSaga)
  yield call(refreshCountersSubscriptionSaga)
}
