import { ProfileLinkTypeNamesEnum } from '@medentee/enums'

import { ReactComponent as FacebookIcon } from 'assets/icons/Facebook.svg'
import { ReactComponent as LinkedinIcon } from 'assets/icons/LinkedIn.svg'
import { ReactComponent as XTwitterIcon } from 'assets/icons/XTwitter.svg'
import { ReactComponent as SiteIcon } from 'assets/icons/Site.svg'

export const SOCIAL_ICON_MAP = new Map<
  ProfileLinkTypeNamesEnum,
  (props?: PropsWithClassName) => JSX.Element
>()
  .set(ProfileLinkTypeNamesEnum.FACEBOOK, (props) => <FacebookIcon className={props?.className} />)
  .set(ProfileLinkTypeNamesEnum.LINKEDIN, (props) => <LinkedinIcon className={props?.className} />)
  .set(ProfileLinkTypeNamesEnum.TWITTER, (props) => <XTwitterIcon className={props?.className} />)
  .set(ProfileLinkTypeNamesEnum.WEBSITE, (props) => <SiteIcon className={props?.className} />)
