import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TIds } from 'store'
import { StaffSelect } from 'App/components'
import { TUserProfileAdministrationAddAdminDialogContainerProps } from 'App/containers'

import { TAccountsSelectOptionData } from '../../../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

export type TUserProfileAdministrationAddAdminDialogProps =
  TUserProfileAdministrationAddAdminDialogContainerProps & {
    processing: boolean
    addAdmin: (ids: TIds) => void
  }

export const UserProfileAdministrationAddAdminDialog = ({
  maxSelected,
  processing,
  addAdmin
}: TUserProfileAdministrationAddAdminDialogProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<TAccountsSelectOptionData[]>([])

  const { t } = useTranslation()

  const handleSubmit = () => {
    addAdmin(selectedAccounts.map((item) => item.id))
  }

  return (
    <StaffSelect
      hideClearAll={true}
      maxSelected={maxSelected}
      onSubmit={handleSubmit}
      selectTitle={t('modal.addAdmin.selectTitle')}
      selectedItems={selectedAccounts}
      onSelect={(item) => setSelectedAccounts((currentValue) => [...currentValue, item])}
      onUnselect={(id) =>
        setSelectedAccounts((currentValue) => currentValue.filter((value) => value.id !== id))
      }
      onSelectAll={setSelectedAccounts}
      emptyContentText={t('modal.addAdmin.selectedListPlaceholder', { limit: maxSelected })}
      submitLoading={processing}
    />
  )
}
