import { useCallback } from 'react'

import { useQuery, useMutation } from 'services/query'
import { getPersonalEventProfile, updatePersonalEventProfile } from 'api/events'

import { TEventProfileEditValues } from './EventProfileEdit'

type TUseEventProfileEditRequestsProps = {
  eventId: string
}

export const useEventProfileEditRequests = ({ eventId }: TUseEventProfileEditRequestsProps) => {
  const { data: profileData, isLoading } = useQuery({
    queryKey: ['get-event-profile', eventId],
    queryFn: () => getPersonalEventProfile(eventId)
  })

  const { mutate, isLoading: isProcessing } = useMutation({
    mutationKey: ['update-event-profile'],
    mutationFn: updatePersonalEventProfile
  })

  const submit = useCallback(
    (values: TEventProfileEditValues, onSuccess?: () => void) => {
      const formData = new FormData()

      formData.set('about', values.about || '')
      formData.set('company', values.company || '')
      formData.set('position', values.position || '')
      formData.set('openForNetworking', values.openForNetworking.toString())
      formData.append('interestIds', JSON.stringify(values.interests?.map(({ id }) => id) ?? []))

      if (values.avatar instanceof File || values.avatar === null) {
        formData.set('avatar', values.avatar || 'null')
      }

      mutate({ eventId, payload: formData }, { onSuccess })
    },
    [eventId, mutate]
  )

  return {
    profileData,
    isLoading,
    isProcessing,
    submit
  }
}
