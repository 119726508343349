import { BusinessAccountRoleNameEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { useSetShadow } from 'App/hooks'

import styles from './UserProfileAdministrationRole.module.scss'

export type TUserProfileAdministrationRoleProps = {
  role: BusinessAccountRoleNameEnum
  online: boolean
}

export const UserProfileAdministrationRole = ({
  role,
  online
}: TUserProfileAdministrationRoleProps) => {
  const onlineRef = useSetShadow({ shadowSize: 3 })

  const { t } = useTranslation()

  const isAdmin =
    role === BusinessAccountRoleNameEnum.ADMIN || role === BusinessAccountRoleNameEnum.SUPERADMIN

  return (
    <div className={styles.wrapper}>
      <div
        className={cls({
          [styles.text]: true,
          [styles.admin]: role === BusinessAccountRoleNameEnum.ADMIN,
          [styles.superAdmin]: role === BusinessAccountRoleNameEnum.SUPERADMIN
        })}
      >
        {t(`enum.businessAccountRoleNameEnum.${role}`)}
      </div>
      <span
        ref={onlineRef}
        className={cls({
          [styles.online]: true,
          [styles.show]: isAdmin && online
        })}
      />
    </div>
  )
}
