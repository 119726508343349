import { ReactNode } from 'react'

import { Tooltip } from 'App/components/common'
import { ReactComponent as DevicesIcon } from 'assets/icons/Devices.svg'

import styles from './CallLabel.module.scss'

type TCallLabelProps = {
  ableToSwitch: boolean
  tooltip: string
  label: ReactNode
}

export const CallLabel = ({ ableToSwitch, tooltip, label }: TCallLabelProps) => (
  <div className={styles.root}>
    {ableToSwitch && (
      <Tooltip title={tooltip}>
        <DevicesIcon className={styles.icon} />
      </Tooltip>
    )}
    {label}
  </div>
)
