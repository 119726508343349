import { useEffect } from 'react'

import {
  getUserProfileByIdRequest,
  resetUserProfileView,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Spinner } from 'App/components'

import { UserProfileContactContainer } from '../UserProfileContactContainer'

import { useUserProfileConnectByInvitation } from './useUserProfileConnectByInvitation'

export const UserProfileWrapperContainer = () => {
  const dispatch = useAppDispatch()
  const invitationToken = useAppSelector((state) => state.global.accountData?.invitation?.token)

  const {
    isFetching,
    profileId,
    token,
    inviteOrganization,
    inviteContact,
    type,
    shouldShowAccountSwitchModal
  } = useUserProfileConnectByInvitation(invitationToken)

  useEffect(() => {
    if (profileId) {
      dispatch(
        getUserProfileByIdRequest({
          id: profileId,
          token: token || invitationToken
        })
      )
    }
  }, [profileId, token, invitationToken, dispatch])

  useEffect(() => {
    dispatch(resetUserProfileView())
    // `profileId` is essential here
  }, [dispatch, profileId])

  if (isFetching) {
    return <Spinner />
  }

  return (
    <UserProfileContactContainer
      isPreview={false}
      invitationByLink={{
        inviteOrganization,
        inviteContact,
        token,
        type,
        profileId,
        shouldShowAccountSwitchModal
      }}
    />
  )
}
