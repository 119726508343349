import {
  actionCreator,
  TError,
  TRemoveFileFromForUploadListPayload,
  TSetNewNameUploadFileInWidgetPayload,
  TSetUploadFilePercentPayload,
  TSetUploadFileStatusPayload,
  TUploadFilesCollapseWidgetPayload,
  TUploadFilesRequestPayload,
  TUploadFilesSuccessPayload,
  TGetCaseMedCloudFilesRequestPayload,
  TGetCaseMedCloudFilesSuccessPayload,
  TAttachSelectedCaseMedCloudFilesRequestPayload,
  TSelectAttachFilePayload,
  TAttachSelectedChatMedCloudFilesRequestPayload,
  TGetChatMedCloudFilesRequestPayload,
  TStartFakeUploadActionPayload,
  TSetFilesToAttachPayload,
  TRemoveFileFromWidgetPayload,
  TCopyFileToMedcloudPayload,
  TMoveFileToProgress,
  TMedCloudAbortUpload,
  TMedCloudFileInvalidate,
  TSetFakeFileInWidgetPayload,
  TSetUploadFilePreSignKeyPayload,
  TCancelUploadFilePayload,
  TSetUploadingStartDatePayload,
  TGetCaseLinkedFilesRequestPayload,
  TGetCaseLinkedFilesSuccessPayload,
  TSetSelectedAttachFilesPayload,
  TAttachCaseLinkedFilesRequestPayload,
  TSetCaseLinkedFilesSearchPayload,
  TSetCaseLinkedFilesFilterPayload,
  TSetAttachedFilesPayload,
  TAttachChatMedCloudFileRequestPayload
} from 'store'

import * as actionTypes from './medCloud.actionTypes'

export const uploadFilesRequest = (payload: TUploadFilesRequestPayload) =>
  actionCreator(actionTypes.UPLOAD_FILES_REQUEST, payload)
export const uploadFilesSuccess = (payload?: TUploadFilesSuccessPayload) =>
  actionCreator(actionTypes.UPLOAD_FILES_SUCCESS, payload)
export const uploadFilesError = (payload: TError) =>
  actionCreator(actionTypes.UPLOAD_FILES_ERROR, payload)

export const moveFileToProgress = (payload: TMoveFileToProgress) =>
  actionCreator(actionTypes.UPLOAD_FILES_MOVE_FILE_TO_PROGRESS, payload)

export const removeFileFromProgress = (payload: TMoveFileToProgress) =>
  actionCreator(actionTypes.UPLOAD_FILES_REMOVE_FILE_FROM_PROGRESS, payload)

export const uploadFilesCollapseWidget = (payload: TUploadFilesCollapseWidgetPayload) =>
  actionCreator(actionTypes.UPLOAD_FILES_COLLAPSE_WIDGET, payload)

export const uploadFilesCloseWidget = () => actionCreator(actionTypes.UPLOAD_FILES_CLOSE_WIDGET)

export const setUploadFileStatus = (payload: TSetUploadFileStatusPayload) =>
  actionCreator(actionTypes.SET_UPLOAD_FILE_STATUS, payload)

export const setUploadFilePercent = (payload: TSetUploadFilePercentPayload) =>
  actionCreator(actionTypes.SET_UPLOAD_FILE_PERCENT, payload)

export const removeFileFromForUploadList = (payload: TRemoveFileFromForUploadListPayload) =>
  actionCreator(actionTypes.REMOVE_FILE_FROM_FOR_UPLOAD_LIST, payload)

export const setNewNameUploadFileInWidget = (payload: TSetNewNameUploadFileInWidgetPayload) =>
  actionCreator(actionTypes.SET_NEW_NAME_UPLOAD_FILE_IN_WIDGET, payload)

export const getCaseMedCloudFilesRequest = (payload: TGetCaseMedCloudFilesRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_MED_CLOUD_FILES_REQUEST, payload)
export const getCaseMedCloudFilesSuccess = (payload: TGetCaseMedCloudFilesSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_MED_CLOUD_FILES_SUCCESS, payload)
export const getCaseMedCloudFilesError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_MED_CLOUD_FILES_ERROR, payload)

export const getCaseLinkedFilesRequest = (payload: TGetCaseLinkedFilesRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_LINKED_FILES_REQUEST, payload)
export const getCaseLinkedFilesSuccess = (payload: TGetCaseLinkedFilesSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_LINKED_FILES_SUCCESS, payload)
export const getCaseLinkedFilesError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_LINKED_FILES_ERROR, payload)

export const attachCaseLinkedFilesRequest = (payload: TAttachCaseLinkedFilesRequestPayload) =>
  actionCreator(actionTypes.ATTACH_CASE_LINKED_FILES_REQUEST, payload)
export const attachCaseLinkedFilesSuccess = () =>
  actionCreator(actionTypes.ATTACH_CASE_LINKED_FILES_SUCCESS)
export const attachCaseLinkedFilesError = (payload: TError) =>
  actionCreator(actionTypes.ATTACH_CASE_LINKED_FILES_ERROR, payload)

export const attachSelectedCaseMedCloudFilesRequest = (
  payload: TAttachSelectedCaseMedCloudFilesRequestPayload
) => actionCreator(actionTypes.ATTACH_SELECTED_CASE_MED_CLOUD_FILES_REQUEST, payload)
export const attachSelectedCaseMedCloudFilesSuccess = () =>
  actionCreator(actionTypes.ATTACH_SELECTED_CASE_MED_CLOUD_FILES_SUCCESS)
export const attachSelectedCaseMedCloudFilesError = (payload: TError) =>
  actionCreator(actionTypes.ATTACH_SELECTED_CASE_MED_CLOUD_FILES_ERROR, payload)

export const selectAttachFileAction = (payload: TSelectAttachFilePayload) =>
  actionCreator(actionTypes.SELECT_ATTACH_FILE_ACTION, payload)

export const attachSelectedChatMedCloudFilesRequest = (
  payload: TAttachSelectedChatMedCloudFilesRequestPayload
) => actionCreator(actionTypes.ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_REQUEST, payload)
export const attachSelectedChatMedCloudFilesSuccess = () =>
  actionCreator(actionTypes.ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_SUCCESS)
export const attachSelectedChatMedCloudFilesError = (payload: TError) =>
  actionCreator(actionTypes.ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_ERROR, payload)

export const attachChatMedCloudFileRequest = (payload: TAttachChatMedCloudFileRequestPayload) =>
  actionCreator(actionTypes.ATTACH_CHAT_MED_CLOUD_FILE_REQUEST, payload)
export const attachChatMedCloudFileSuccess = () =>
  actionCreator(actionTypes.ATTACH_CHAT_MED_CLOUD_FILE_SUCCESS)
export const attachChatMedCloudFileError = (payload: TError) =>
  actionCreator(actionTypes.ATTACH_CHAT_MED_CLOUD_FILE_ERROR, payload)

export const getChatMedCloudFilesRequest = (payload: TGetChatMedCloudFilesRequestPayload) =>
  actionCreator(actionTypes.GET_CHAT_MED_CLOUD_FILES_REQUEST, payload)
export const getChatMedCloudFilesSuccess = (payload: TGetCaseMedCloudFilesSuccessPayload) =>
  actionCreator(actionTypes.GET_CHAT_MED_CLOUD_FILES_SUCCESS, payload)
export const getChatMedCloudFilesError = (payload: TError) =>
  actionCreator(actionTypes.GET_CHAT_MED_CLOUD_FILES_ERROR, payload)

export const startFakeUploadAction = (payload: TStartFakeUploadActionPayload) =>
  actionCreator(actionTypes.START_FAKE_UPLOAD_ACTION, payload)

export const selectAttachUploadFileAction = (payload: TSelectAttachFilePayload) =>
  actionCreator(actionTypes.SELECT_ATTACH_UPLOAD_FILE_ACTION, payload)

export const setFilesToAttachAction = (payload: TSetFilesToAttachPayload) =>
  actionCreator(actionTypes.SET_FILES_TO_ATTACH_ACTION, payload)

export const resetAttachFilesAction = () => actionCreator(actionTypes.RESET_ATTACH_FILES)

export const removeFileFromWidgetAction = (payload: TRemoveFileFromWidgetPayload) =>
  actionCreator(actionTypes.REMOVE_FILE_FROM_WIDGET_ACTION, payload)

export const copyFileToMedcloudRequest = (payload: TCopyFileToMedcloudPayload) =>
  actionCreator(actionTypes.COPY_FILE_TO_MEDCLOUD_REQUEST, payload)
export const copyFileToMedcloudSuccess = () =>
  actionCreator(actionTypes.COPY_FILE_TO_MEDCLOUD_SUCCESS)
export const copyFileToMedcloudError = (payload: TError) =>
  actionCreator(actionTypes.COPY_FILE_TO_MEDCLOUD_ERROR, payload)

export const medCloudAbortUpload = (payload: TMedCloudAbortUpload) =>
  actionCreator(actionTypes.MED_CLOUD_ABORT_UPLOAD, payload)

export const medCloudFileInvalidate = (payload: TMedCloudFileInvalidate) =>
  actionCreator(actionTypes.MED_CLOUD_FILE_INVALIDATE, payload)

export const setFakeFileInWidget = (payload: TSetFakeFileInWidgetPayload) =>
  actionCreator(actionTypes.SET_FAKE_FILE_IN_WIDGET, payload)

export const setUploadFilePreSignKey = (payload: TSetUploadFilePreSignKeyPayload) =>
  actionCreator(actionTypes.SET_UPLOAD_FILE_PRE_SIGN_KEY, payload)

export const cancelUploadFile = (payload: TCancelUploadFilePayload) =>
  actionCreator(actionTypes.CANCEL_UPLOAD_FILE, payload)

export const setUploadingStartDate = (payload: TSetUploadingStartDatePayload) =>
  actionCreator(actionTypes.SET_UPLOADING_START_DATE, payload)

export const setSelectedAttachFiles = (payload: TSetSelectedAttachFilesPayload) =>
  actionCreator(actionTypes.SET_SELECTED_ATTACH_FILES, payload)

export const setCaseLinkedFilesSearch = (payload: TSetCaseLinkedFilesSearchPayload) =>
  actionCreator(actionTypes.SET_CASE_LINKED_FILES_SEARCH, payload)

export const setCaseLinkedFilesFilter = (payload: TSetCaseLinkedFilesFilterPayload) =>
  actionCreator(actionTypes.SET_CASE_LINKED_FILES_FILTER, payload)

export const resetCaseLinkedFilesFilter = () =>
  actionCreator(actionTypes.RESET_CASE_LINKED_FILES_FILTER)

export const setAttachedFiles = (payload: TSetAttachedFilesPayload) =>
  actionCreator(actionTypes.SET_ATTACHED_FILES, payload)
