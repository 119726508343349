import { Fragment, useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import cls from 'classnames'

import { TAccount } from 'store'
import { getMapComponent, stopPropagation } from 'utils'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { StatusColored, TStatus } from '../../StatusColored'
import { AccountsListItem } from '../AccountsListItem'
import { TruncatedText } from '../../TruncatedText'

import styles from './AccountsListPopover.module.scss'

export type TAccountsItem = PartialBy<
  Pick<TAccount, 'id' | 'firstName' | 'lastName' | 'companyName' | 'type'>,
  'companyName' | 'type' | 'firstName' | 'lastName'
> & {
  displayUserName: string

  status?: TStatus
  disabled?: boolean
}

export type TAccountsListPopoverProps<Status> = Pick<
  TRenderContentProps<Status>,
  'onRemove' | 'getGroupLabel' | 'variant'
> & {
  accounts: TAccountsItem[]

  getTitle?: (title: number) => string
}

export type TAccountListPopoverItemVariant = 'chip' | 'accountName'

type TRenderContentProps<Status> = {
  groupedAccounts: Record<string, TAccountsItem[]>

  variant?: TAccountListPopoverItemVariant
  getGroupLabel?: (groupLabel: Status) => string
  onRemove?: (id: string) => void
}

const RENDER_ITEM_MAP = new Map<
  TAccountListPopoverItemVariant,
  (props: TAccountsItem) => JSX.Element
>()
  .set('accountName', (account) =>
    !!(account.firstName && account.lastName) ? (
      <AccountsListItem
        className={styles.accountItem}
        displayUserName={account.displayUserName}
        firstName={account.firstName}
        lastName={account.lastName}
        type={account.type?.name}
        userId={account.id}
        hasTooltip={true}
        hideIcon={true}
      />
    ) : (
      <></>
    )
  )
  .set('chip', ({ displayUserName }) => <TruncatedText text={displayUserName} />)

const renderContent = <Status extends TStatus>({
  groupedAccounts,
  variant = 'accountName',
  onRemove,
  getGroupLabel
}: TRenderContentProps<Status>) => {
  const groups = Object.keys(groupedAccounts)
  const groupedByStatus = groups.filter((groupName) => groupName !== 'single').length > 0

  return Object.entries(groupedAccounts).map(([groupName, items]) => (
    <Fragment key={groupName}>
      {groupedByStatus && getGroupLabel && (
        <StatusColored
          status={groupName as Status}
          statusLabel={getGroupLabel(groupName as Status)}
          format={(value: string) => `${value} (${items.length})`}
        />
      )}
      <div className={cls(styles.list, styles[variant])}>
        {items.map((account) => (
          <div key={account.id} className={styles.item}>
            {getMapComponent(RENDER_ITEM_MAP, variant, account)}
            {onRemove && !account.disabled && (
              <CrossIcon onClick={() => onRemove(account.id)} className={styles.remove} />
            )}
          </div>
        ))}
      </div>
    </Fragment>
  ))
}

export const AccountsListPopover = <Status extends TStatus>(
  props: TAccountsListPopoverProps<Status>
) => {
  const { getTitle, accounts, variant, onRemove, getGroupLabel } = props

  const groupedAccounts = useMemo(
    () => groupBy(accounts, ({ status }) => status || 'single'),
    [accounts]
  )

  return (
    <div className={styles.root} onClick={stopPropagation}>
      {getTitle && <strong className={styles.title}>{getTitle(accounts.length)}</strong>}

      {renderContent<Status>({ groupedAccounts, variant, onRemove, getGroupLabel })}
    </div>
  )
}
