import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ReceivedCall } from 'App/components'
import { redirectToCaseFromChat, sendAllowCallRequest, sendEndCallRequest } from 'store'

export type TReceivedCallContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  firstName: state.calls.answer.createCall?.offerer?.firstName,
  lastName: state.calls.answer.createCall?.offerer?.lastName,
  displayUserName: state.calls.answer.createCall?.offerer?.displayUserName,
  offererUserId: state.calls.answer.createCall?.offererId,
  chatId: state.calls.answer.createCall?.chatId,
  callId: state.calls.answer.createCall?.id,
  callType: state.calls.answer.createCall?.type,
  caseData: state.calls.answer.createCall?.case,
  muteCalls: state.global.accountData?.muteCalls,
  type: state.calls.answer.createCall?.offerer?.type.name
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendEndCall: sendEndCallRequest,
      sendAllowCall: sendAllowCallRequest,
      redirectToCaseFromChatRequest: redirectToCaseFromChat
    },
    dispatch
  )

export const ReceivedCallContainer = connect(mapStateToProps, mapDispatchToProps)(ReceivedCall)
