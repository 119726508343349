import { useCallback } from 'react'
import cls from 'classnames'
import { stringify } from 'query-string'

import { EAvatarSize } from 'enums'
import { AccountName, TAvatarProps } from 'App/components'
import { useRedirectToUserProfile } from 'App/hooks'
import { GUEST_USER_PROFILE_SEARCH_QUERY_KEY } from 'globalConstants'

import styles from './Author.module.scss'

type TAuthorProps = PropsWithClassName &
  Required<Pick<TAvatarProps, 'lastName' | 'firstName' | 'userId' | 'displayUserName' | 'type'>> & {
    country: string
    city: string

    disableLink?: boolean
  }

export const Author = ({
  className,
  displayUserName,
  lastName,
  firstName,
  userId,
  type,
  city,
  country,
  disableLink = false
}: TAuthorProps) => {
  const { redirectToUserProfile } = useRedirectToUserProfile()

  const handleUserClick = useCallback(() => {
    if (disableLink) {
      return
    }

    redirectToUserProfile(
      userId,
      type,
      stringify({ [GUEST_USER_PROFILE_SEARCH_QUERY_KEY]: true })
    )()
  }, [disableLink, redirectToUserProfile, userId, type])

  return (
    <div className={cls(styles.root, className)}>
      <AccountName
        link
        showAvatar
        userId={userId}
        displayUserName={displayUserName}
        size={EAvatarSize.XS}
        lastName={lastName}
        firstName={firstName}
        type={type}
        onClick={handleUserClick}
      >
        <>{`${city}, ${country}`}</>
      </AccountName>
    </div>
  )
}
