import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { TJitsiDevice } from 'types'
import { getMapComponent, isMobileBrowser } from 'utils'
import { IconLabel, TruncatedText } from 'App/components'
import { ReactComponent as RecordIcon } from 'assets/icons/Record.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/VolumeUp.svg'

import { MicrophoneEntry } from '../MicrophoneEntry'

import styles from './AudioDevicesList.module.scss'

export type TAudioDevicesListType = 'input' | 'output'

export type TAudioDevicesListProps = {
  type: TAudioDevicesListType
  devices: TJitsiDevice[]

  currentDeviceId?: null | string
  onChange: (e: RadioChangeEvent) => void
}

const LABEL_MAP = new Map<TAudioDevicesListType, (t: TFunction) => JSX.Element>()
  .set('input', (t) => (
    <IconLabel
      icon={<RecordIcon />}
      label={t('chat.call.audioDevicesList.microphones')}
      classes={{
        root: styles.iconLabel,
        icon: styles.iconLabelIcon,
        label: styles.iconLabelLabel
      }}
    />
  ))
  .set('output', (t) => (
    <IconLabel
      icon={<VolumeUpIcon />}
      label={t('chat.call.audioDevicesList.speakers')}
      classes={{
        root: styles.iconLabel,
        icon: styles.iconLabelIcon,
        label: styles.iconLabelLabel
      }}
    />
  ))

export const AudioDevicesList = ({
  type,
  currentDeviceId,
  devices,
  onChange
}: TAudioDevicesListProps) => {
  const { t } = useTranslation()

  const shouldShowEntry = type === 'input' && !isMobileBrowser()

  return (
    <>
      {getMapComponent(LABEL_MAP, type, t)}

      <Radio.Group defaultValue={currentDeviceId} className={styles.radio} onChange={onChange}>
        {devices.map(({ label, deviceId, kind }) => (
          <div key={deviceId} className={styles.radioItemWrapper}>
            <Radio name={type} value={deviceId} className={styles.radioItem}>
              <TruncatedText
                text={deviceId === 'default' ? t('chat.call.audioDevicesList.default') : label}
              />
            </Radio>

            {shouldShowEntry && (
              <MicrophoneEntry className={styles.microphoneEntry} label={label} kind={kind} />
            )}
          </div>
        ))}
      </Radio.Group>
    </>
  )
}
