import { useContext, useEffect } from 'react'

import { useQuery } from 'services/query'
import { getSavedShowcases, TGetMyShowcasesPayload, TSavedShowcaseType } from 'api/showcase'

import { ShowcaseMainList, ShowcasesListItem } from '../common'
import { EFilterTypes, TFilterItem } from '../../../components'
import { PageWrapperContext } from '../../../components/PageWrapper/PageWrapperContext'

const FILTERS: TFilterItem[] = [
  {
    title: 'Role',
    sectionKey: 'type',
    items: [
      {
        type: EFilterTypes.CHECKBOX,
        filterKey: 'type',
        options: [
          { label: 'Author', value: 'OWN' },
          { label: 'Member', value: 'SHARED' }
        ]
      }
    ]
  }
]

const SEARCH_OPTIONS = [
  {
    label: 'Title',
    value: 'TITLE'
  },
  {
    label: 'Description',
    value: 'DESCRIPTION'
  },
  {
    label: 'Author',
    value: 'OWNER'
  }
]

export const SavedShowcases = () => {
  const { search, searchBy, selectedFilters, reset, page, setPage } = useContext(PageWrapperContext)
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['saved-showcases', page, searchBy, search, selectedFilters],
    queryFn: () =>
      getSavedShowcases({
        page,
        searchQuery: search,
        searchBy: searchBy as TGetMyShowcasesPayload['searchBy'],
        type: selectedFilters?.type as TSavedShowcaseType
      })
  })

  useEffect(() => {
    reset({
      filters: FILTERS,
      searchBy: 'TITLE',
      searchOptions: SEARCH_OPTIONS
    })
  }, [reset])

  return (
    <>
      <ShowcaseMainList
        data={data?.results}
        loading={isLoading}
        onDeactivate={refetch}
        onSaveToggle={refetch}
        onPublish={refetch}
        onDelete={refetch}
        component={ShowcasesListItem}
        page={page}
        total={data?.total}
        onPageChange={setPage}
      />
    </>
  )
}
