import { useCallback } from 'react'

import { useMutation } from 'services/query'
import { editEventChannel } from 'api/events'
import { updateChatRoomAction, useAppDispatch, useAppSelector } from 'store'
import { sortByName } from 'utils'

export const useEventChannelHighlight = (eventId: string) => {
  const dispatch = useAppDispatch()
  const chatRoomsState = useAppSelector((state) => state.chat.chatRooms)

  const { mutate, isLoading, error } = useMutation({
    mutationKey: ['highlight-channel'],
    mutationFn: editEventChannel
  })

  const handleHighlight = useCallback(
    (channelId: string, highlighted = false) => {
      const nextHighlight = highlighted ? null : new Date().toISOString()

      mutate(
        { channelId, eventId, isHighlight: !!nextHighlight },
        {
          onSuccess: () => {
            const list = {
              ...chatRoomsState.list,
              [channelId]: {
                ...(chatRoomsState.list[channelId] ?? {}),
                isHighlight: nextHighlight
              }
            }

            let ids: string[] = chatRoomsState.ids

            let publicIds: string[] = []
            let privateIds: string[] = []
            let highlightedIds: string[] = []

            if (nextHighlight) {
              ids = [...chatRoomsState.ids.filter((id) => id !== channelId), channelId]
            } else {
              publicIds = chatRoomsState.ids.filter(
                (id) => id === list[id]?.id && !!list[id]?.isPublic && !list[id]?.isHighlight
              )
              privateIds = chatRoomsState.ids.filter(
                (id) => id === list[id]?.id && !list[id]?.isPublic && !list[id]?.isHighlight
              )
              highlightedIds = chatRoomsState.ids.filter(
                (id) => !!list[id]?.isHighlight && id !== channelId
              )

              publicIds.sort((a, b) => sortByName(list[a]?.name ?? '', list[b]?.name ?? ''))
              privateIds.sort((a, b) => sortByName(list[a]?.name ?? '', list[b]?.name ?? ''))
            }

            dispatch(
              updateChatRoomAction({
                ...chatRoomsState,
                ids: nextHighlight ? ids : [...highlightedIds, ...publicIds, ...privateIds],
                list,
                selectedChat:
                  chatRoomsState.selectedChat?.id === channelId
                    ? {
                        ...chatRoomsState.selectedChat,
                        isHighlight: nextHighlight
                      }
                    : chatRoomsState.selectedChat
              })
            )
          }
        }
      )
    },
    [chatRoomsState, dispatch, eventId, mutate]
  )

  return {
    isLoading,
    error,
    handleHighlight
  }
}
