import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { formatLongTimeDate } from 'utils'
import { useAdaptiveLayout, useRedirectToUserProfile } from 'App/hooks'
import {
  CaseMemberInviteApproveRejectButton,
  AvatarContainer,
  TCasesInvitationItemContainerProps
} from 'App/containers'
import { ShowMore, CaseType } from 'App/components'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { IconLabel } from 'App/components/common'

import styles from './CasesInviteItem.module.scss'

type TCasesInviteItemProps = TCasesInvitationItemContainerProps

export const CasesInviteItem = ({
  id,
  createdAt,
  case: caseItem,
  viewed,
  description
}: TCasesInviteItemProps) => {
  const { t } = useTranslation()

  const { isMobile } = useAdaptiveLayout()

  const { title, humanReadableId, owner, type, classification } = caseItem || {}
  const { id: ownerId, lastName, firstName, displayUserName, type: ownerType } = owner || {}

  const { redirectToUserProfile } = useRedirectToUserProfile()

  return (
    <div
      key={id}
      className={cls({
        [styles.root]: true,
        [styles.rootViewed]: !viewed
      })}
    >
      <div className={styles.header}>
        <h5 className={styles.headerTitle}>{title}</h5>

        <div className={styles.avatar}>
          <span className={styles.avatarTitle}>{t('cases.list.item.ownerLabel')}</span>
          {ownerId && (
            <AvatarContainer
              size={isMobile ? EAvatarSize.XXS : EAvatarSize.XS}
              userId={ownerId}
              lastName={lastName}
              firstName={firstName}
              displayUserName={displayUserName}
              type={ownerType.name}
              onClick={redirectToUserProfile(ownerId, ownerType.name)}
            />
          )}
        </div>
      </div>
      <p className={styles.subTitle}>{t('cases.caseIdLabel', { caseId: humanReadableId })}</p>
      <div className={styles.info}>
        <div className={styles.infoItem}>
          <CaseType
            type={type}
            label={t(`cases.caseType.${type}`)}
            withTooltip={true}
            classification={classification?.name}
          />
        </div>
        <div className={styles.infoItem}>
          <IconLabel
            icon={<ScheduleIcon />}
            label={t('invitations.invitedAtLabel', { date: formatLongTimeDate(createdAt) })}
          />
        </div>
      </div>
      {!!description && (
        <div className={styles.description}>
          <ShowMore text={description} />
        </div>
      )}
      <div className={styles.actions}>
        <CaseMemberInviteApproveRejectButton
          className={cls(styles.actionsBtn, styles.actionsBtnApprove)}
          inviteId={id}
          title={title}
          type="approve"
        />

        <CaseMemberInviteApproveRejectButton
          className={styles.actionsBtn}
          inviteId={id}
          title={title}
          type="reject"
        />
      </div>
    </div>
  )
}
