import queryString from 'query-string'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { API, api, APIResultsResponse } from 'services/api'
import { IChatPinnedDTO } from 'interfaces'
import { QueryBuilder } from 'utils'
import {
  GET_CHAT_PIN_REQUEST,
  TGetChatPinRequest,
  getChatPinError,
  getChatPinSuccess,
  REMOVE_CHAT_PIN_MESSAGE_REQUEST,
  TPinChatMessageRequest,
  pinChatMessageError,
  pinChatMessageSuccess,
  PIN_CHAT_MESSAGE_REQUEST,
  removeChatPinMessageSuccess,
  PINNED_MESSAGE,
  UNPINNED_MESSAGE,
  getChatPinRequest,
  TPinnedChatMessage
} from 'store'
import { State } from 'redux/rootReducer'
import { handleError } from 'api/utils'

import { handleDefaultError } from '../../utils/toast'

import { TRemoveAllChatPinMessagesRequest, TRemoveChatPinMessageRequest } from './chatPin.types'
import {
  removeAllChatPinMessagesSuccess,
  removeAllChatPinMessagesError,
  removeChatPinMessageError,
  REMOVE_ALL_CHAT_PIN_MESSAGES_REQUEST
} from './chatPin.actions'

function* getChatPinSaga({
  payload: { chatId, before, after, limit, onSuccess }
}: TGetChatPinRequest) {
  try {
    const query = queryString.stringify({
      limit,
      after,
      before,
      onlyPinned: true
    })
    const {
      data: { results, total }
    }: APIResultsResponse<IChatPinnedDTO[]> = yield call(
      api.get,
      `${API.CHAT_MESSAGES(chatId)}?${query}`
    )

    yield put(getChatPinSuccess({ data: results[0], ...(!after && !before ? { total } : {}) }))
    if (onSuccess) {
      yield call(onSuccess, results[0])
    }
  } catch (e) {
    yield put(getChatPinError(e))

    yield call(handleDefaultError, e)
  }
}

function* removeAllChatPinMessagesSaga({
  payload: { chatId, onSuccess }
}: TRemoveAllChatPinMessagesRequest) {
  try {
    yield call(api.delete, API.CHAT_UNPIN(chatId))

    yield put(removeAllChatPinMessagesSuccess())

    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield handleError(e)
    yield put(removeAllChatPinMessagesError(e))
  }
}

function* removeChatPinMessageSaga({
  payload: { chatId, messageId, onSuccess, processingId }
}: TRemoveChatPinMessageRequest) {
  try {
    yield call(api.delete, API.CHAT_UNPIN(chatId), {
      data: {
        messageIds: [messageId]
      }
    })
    const url = new QueryBuilder(API.CHAT_MESSAGES(chatId))
      .custom('onlyPinned', true)
      .custom('limit', 0)
      .build()

    const {
      data: { total }
    }: APIResultsResponse = yield call(api.get, url)

    yield put(removeChatPinMessageSuccess({ total, processingId }))

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield handleError(e)
    yield put(removeChatPinMessageError({ ...e, processingId }))
  }
}

function* pinChatMessageSaga({
  payload: { chatId, onSuccess, processingId, messageId, shared }
}: TPinChatMessageRequest) {
  try {
    yield call(api.post, API.CHAT_PIN(chatId), { shared, messageId })

    yield put(pinChatMessageSuccess({ processingId }))

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield handleError(e)
    yield put(pinChatMessageError({ ...e, processingId }))
  }
}

function* refreshPinnedMessagesSaga({ payload: { chat } }: TPinnedChatMessage) {
  const chatId: string | undefined = yield select(
    (state: State) => state.chat.chatRooms.selectedChat?.id
  )

  if (chatId && chatId === chat.id) {
    yield put(getChatPinRequest({ chatId }))
  }
}

export function* chatPinSaga() {
  yield takeLatest(GET_CHAT_PIN_REQUEST, getChatPinSaga)
  yield takeLatest(REMOVE_ALL_CHAT_PIN_MESSAGES_REQUEST, removeAllChatPinMessagesSaga)
  yield takeLatest(REMOVE_CHAT_PIN_MESSAGE_REQUEST, removeChatPinMessageSaga)
  yield takeLatest(PIN_CHAT_MESSAGE_REQUEST, pinChatMessageSaga)
  yield takeLatest([PINNED_MESSAGE, UNPINNED_MESSAGE], refreshPinnedMessagesSaga)
}
