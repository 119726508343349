import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { SortingTable } from 'types'
import { ESorting } from 'enums'
import {
  FileTilesViewGrid,
  FileTilesViewGridItem,
  FileTilesViewHeader,
  TFileTableProps,
  TFileTilesViewHeaderProps
} from 'App/components'
import { TFileListContainerProps } from 'App/containers'
import { createSlides, FILE_LIST_DEFAULT_PAGINATION, TIds } from 'store'
import { useListContextMenu } from 'App/hooks'

import { THeadContextMenuProps, TUseSelection } from '../../common'
import { TUseKebabMenu } from '../useKebabMenu'

export type TGridViewProps = Pick<TFileTilesViewHeaderProps, 'onViewChange' | 'onSorting'> &
  Pick<TFileListContainerProps, 'sorting'> &
  Omit<TUseSelection, 'setSelectMode'> &
  Pick<THeadContextMenuProps, 'filesSize'> &
  TUseKebabMenu & {
    view: TFileTilesViewHeaderProps['variant']
    dataSource: TFileTableProps[]
    fileIds: TIds

    loading?: boolean
  }

const SKELETON_ROWS = FILE_LIST_DEFAULT_PAGINATION.showBy

const defaultSorting: SortingTable = {
  direction: ESorting.DESC,
  name: 'createdAt'
}

export const FileGridView = ({
  dataSource,
  onSorting,
  onViewChange,
  view,
  setSelectedIds,
  selectedIds,
  loading,
  fileIds,
  selectMode,
  onSelect,
  getMenuItems,
  filesSize,
  sorting = defaultSorting
}: TGridViewProps) => {
  const { shouldShowContextMenu, shouldShowHeadContextmenu } = useListContextMenu(selectedIds)

  const { t } = useTranslation()

  const sortingList = useMemo<TFileTilesViewHeaderProps['sortingList']>(
    () => [
      {
        title: t('files.columns.name'),
        key: 'fileName'
      },
      {
        title: t('files.columns.uploaded'),
        key: 'createdAt'
      },
      {
        title: t('files.columns.fileSize'),
        key: 'fileSize'
      }
    ],
    [t]
  )

  return (
    <>
      <FileTilesViewHeader
        sorting={sorting}
        onSorting={onSorting}
        variant={view}
        fileIds={fileIds}
        selectedIds={selectedIds}
        onViewChange={onViewChange}
        setSelectedIds={setSelectedIds}
        sortingList={sortingList}
        filesSize={filesSize}
        shouldShowHeadContextmenu={shouldShowHeadContextmenu}
        menuItems={getMenuItems(selectedIds)}
      />

      <FileTilesViewGrid<TFileTableProps>
        data={dataSource}
        loading={loading}
        skeletonLength={SKELETON_ROWS}
      >
        {({
          fileId,
          fileName,
          extension,
          thumbnailUrl,
          isUnopenable,
          key,
          mimeType,
          slidesEntity
        }) => (
          <FileTilesViewGridItem
            disableOpen={isUnopenable}
            key={fileId}
            fileId={fileId}
            fileName={fileName}
            extension={extension}
            thumbnailUrl={thumbnailUrl}
            selectMode={selectMode}
            selected={selectedIds.includes(fileId)}
            openHandlerProps={{
              slides: createSlides(slidesEntity, FileHistorySourcesEnum.MED_CLOUD)
            }}
            fileNameDisabled={isUnopenable}
            menuItems={
              shouldShowContextMenu(fileId)
                ? getMenuItems({
                    extension,
                    fileId,
                    fileName,
                    key,
                    isUnopenable,
                    slidesEntity
                  })
                : undefined
            }
            onSelect={onSelect}
            mimeType={mimeType}
          />
        )}
      </FileTilesViewGrid>
    </>
  )
}
