import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { FC } from 'react'

import {
  createProcessingSelector,
  CASE_ARCHIVE,
  caseArchiveRequest,
  removeErrorNotification
} from 'store'
import { State } from 'redux/rootReducer'
import { CaseArchiveConfirm } from 'App/components/Case/CaseArchiveConfirm'

export type TCaseArchiveConfirmContainerBaseProps = {
  caseId: string
}

export type TCaseArchiveConfirmContainerProps = TCaseArchiveConfirmContainerBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const processingSelector = createProcessingSelector([CASE_ARCHIVE])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[CASE_ARCHIVE],
  action: CASE_ARCHIVE
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      archiveCase: caseArchiveRequest,
      removeErrorNotification
    },
    dispatch
  )

export const CaseArchiveConfirmContainer: FC<TCaseArchiveConfirmContainerBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseArchiveConfirm)
