import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import debounce from 'lodash/debounce'

import { useAppSelector } from 'store'
import { useAdaptiveLayout, useRefValue } from 'App/hooks'
import { SUPPORT_EMAIL } from 'globalConstants'

import { NavItem } from '../NavItem'
import { validateRoute } from '../../../RoleRoute'
import { SupportNavItem } from '../SupportNavItem'

import { MoreItems } from './MoreItems'
import { TNavItem } from './nav.constants'

export type TNavClasses = {
  classes?: Partial<
    Record<'nav' | 'link' | 'linkActive' | 'iconWrapper' | 'text' | 'badge' | 'group', string>
  >
}

export type TNavProps = {
  list: TNavItem[]
} & TNavClasses

export const Nav = ({ classes, list }: TNavProps) => {
  const navRef = useRef<HTMLDivElement>(null)
  const navGroupRef = useRef<HTMLDivElement>(null)
  const accountType = useAppSelector((state) => state.global.accountData?.type.name)
  const accountEmail = useAppSelector((state) => state.global.accountData?.email)
  const [hiddenItemsCount, setHiddenItemsCount] = useState(0)

  const { isMobile } = useAdaptiveLayout()
  const { getValue: getIsMobile } = useRefValue(isMobile)

  const hiddenItems = useMemo(
    () => list.slice(list.length - hiddenItemsCount),
    [list, hiddenItemsCount]
  )

  const shouldShowSupport =
    accountEmail !== SUPPORT_EMAIL &&
    !isMobile &&
    validateRoute(accountType || AccountTypeNames.PROFESSIONAL, [
      AccountTypeNames.BUSINESS,
      AccountTypeNames.PROFESSIONAL
    ])

  useLayoutEffect(() => {
    const handler = debounce(() => {
      if (!getIsMobile() && navRef.current && navGroupRef.current) {
        const items: HTMLElement[] = Array.prototype.slice.call(
          navGroupRef.current.querySelectorAll('a')
        )

        items.forEach((el) => (el.hidden = false))

        let counter = 0
        let totalHeight = 0

        items.forEach((el: HTMLElement, index) => {
          totalHeight += el.offsetHeight

          if (totalHeight > (navGroupRef?.current?.offsetHeight ?? 0)) {
            if (counter === 0 && index > 0) {
              // hide previous element to make space for popover button
              items[index - 1].hidden = true
            }
            counter += 1
            el.hidden = true
          }
        })

        setHiddenItemsCount(counter > 0 ? counter + 1 : 0)
      }
    }, 100)
    window.addEventListener('resize', handler)
    window.addEventListener('orientationchange', handler)

    handler()

    return () => {
      handler.cancel()
      window.removeEventListener('resize', handler)
      window.removeEventListener('orientationchange', handler)
    }
  }, [getIsMobile])

  return accountType ? (
    <nav className={classes?.nav} ref={navRef}>
      <div className={classes?.group} ref={navGroupRef}>
        {list.map((item) => (
          <NavItem key={item.itemKey} {...item} />
        ))}
        {!isMobile && <MoreItems items={hiddenItems} />}
      </div>

      {shouldShowSupport && (
        <div className={classes?.group}>
          <SupportNavItem classes={classes} />
        </div>
      )}
    </nav>
  ) : null
}
