import { QueryBuilder } from '../utils'
import { api, API, APIData } from '../services/api'
import { TCategoryListData } from '../store'

export const getCategories = async (searchQuery?: string) => {
  const url = new QueryBuilder(API.CATEGORIES).searchQuery(searchQuery).build()

  const { data }: APIData<TCategoryListData[]> = await api.get(url)

  return data
}

export type TSetCategoryPayload = {
  categoryId: string
  addContactIds: string[]
  deleteContactIds: string[]
}

export const setCategory = async ({
  categoryId,
  addContactIds,
  deleteContactIds
}: TSetCategoryPayload) => {
  const response = api.patch(API.CATEGORY(categoryId), { addContactIds, deleteContactIds })

  return response
}
