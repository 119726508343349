import { useEffect } from 'react'

import { useCallCore } from 'App/components'
import { TCallOwnerContainerProps } from 'App/containers'
import { usePlaySound } from 'App/hooks'
import { outgoingCallSoundBase64 } from 'assets/sounds/outgoingCallSound'

export type TCallOwnerProps = TCallOwnerContainerProps & {
  isVideoCall: boolean
  isAnsweredCall: boolean
}

export const CallOwner = ({ isAnsweredCall, ...coreProps }: TCallOwnerProps) => {
  const callSound = usePlaySound({
    audioSrc: outgoingCallSoundBase64,
    volume: 0.1
  })

  const { render, setAnsweredCall } = useCallCore({
    isOwner: true,
    ...coreProps
  })

  useEffect(() => {
    if (isAnsweredCall) {
      callSound.pause()
      setAnsweredCall(true)
    }
  }, [callSound, isAnsweredCall, setAnsweredCall])

  return render()
}
