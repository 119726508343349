import { FC, ReactNode, useMemo } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './EmptyList.module.scss'

export type TEmptyList = {
  text?: null | ReactNode
  hideIcon?: boolean
  icon?: string | React.ReactNode
  className?: string
  iconSize?: EEmptyListIconSize
  hasAppliedFilters?: boolean
  hasAppliedSearch?: boolean
}

export enum EEmptyListIconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md'
}

export const EmptyList: FC<TEmptyList> = ({
  children,
  hideIcon = false,
  icon,
  text,
  className = '',
  iconSize = EEmptyListIconSize.XS,
  hasAppliedSearch,
  hasAppliedFilters
}) => {
  const { t } = useTranslation()

  const placeholder = useMemo(() => {
    if (hasAppliedFilters) {
      return t('common.field.search.listPlaceholder_filter')
    }

    if (hasAppliedSearch) {
      return t('common.field.search.listPlaceholder_search')
    }

    return text ?? t('common.field.search.listPlaceholder')
  }, [hasAppliedFilters, hasAppliedSearch, t, text])

  return (
    <div
      className={cls({
        [styles.root]: true,
        [className]: !!className
      })}
    >
      {!hideIcon && <div className={cls(styles.icon, styles[iconSize])}>{icon}</div>}
      <p className={cls({ [styles.text]: true, [styles.textWithoutIcon]: hideIcon })}>
        {placeholder}
      </p>
      {children && <div className={styles.children}>{children}</div>}
    </div>
  )
}
