import { useCallback, useMemo, useEffect } from 'react'
import { Skeleton, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import cls from 'classnames'
import { AccountTypeNames, FinancialOperationTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IOperationsHistoryDTO } from 'interfaces'
import { formatLongDateTime } from 'utils'
import { TOperationsHistoryContainerProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import {
  TableTitle,
  OperationDetails,
  Pagination,
  EmptyList,
  EEmptyListIconSize
} from 'App/components'
import { ReactComponent as TextBoxRemoveIcon } from 'assets/icons/TextBoxRemove.svg'

import { useFilter } from './useFilter'
import styles from './OperationsHistory.module.scss'

export type TOperationsHistoryProps = TOperationsHistoryContainerProps

export const OperationsHistory = ({
  loading,
  data,
  total,
  page,
  accountId,
  accountType,
  selectedFilters,
  getOperationsHistory,
  clearOperationsHistory,
  setOperationsHistoryFilter
}: TOperationsHistoryProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const fetchOperationsHistory = useCallback(
    (nextPage: number) => getOperationsHistory({ page: nextPage }),
    [getOperationsHistory]
  )

  const hasAppliedFilters = Boolean(
    selectedFilters.dateRange?.length || selectedFilters.operationType.length
  )

  const { filtersComponent, getTableFilter } = useFilter({
    selectedFilters,
    setOperationsHistoryFilter
  })

  useEffect(() => {
    fetchOperationsHistory(0)
  }, [fetchOperationsHistory, selectedFilters.dateRange, selectedFilters.operationType])

  useEffect(
    () => () => {
      clearOperationsHistory()
    },
    [clearOperationsHistory]
  )

  const COLUMNS = useMemo<ColumnsType<IOperationsHistoryDTO>>(
    () => [
      {
        className: styles.cellDate,
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: false,
        editable: false,
        render: (createdAt: string) => (
          <>
            {isMobile && (
              <div className={styles.label}>
                {t('payments.operationHistory.columns.dateAndTime')}:
              </div>
            )}
            {formatLongDateTime(createdAt)}
          </>
        ),
        title: () => (
          <TableTitle title={t('payments.operationHistory.columns.dateAndTime')} sortId="date" />
        ),
        ...getTableFilter({ filterKey: 'dateRange', contentType: 'DATE' })
      },
      {
        className: styles.cellType,
        dataIndex: 'type',
        key: 'type',
        sorter: false,
        editable: false,
        render: (type: FinancialOperationTypeEnum) => (
          <>
            {isMobile && (
              <div className={styles.label}>{t('payments.operationHistory.columns.type')}:</div>
            )}
            {t(`enum.financialOperationTypeEnum.${type}`)}
          </>
        ),
        title: () => (
          <TableTitle title={t('payments.operationHistory.columns.type')} sortId="Type" />
        ),
        ...getTableFilter({ filterKey: 'operationType', contentType: 'CHECKBOX' })
      },
      {
        dataIndex: '',
        key: 'details',
        sorter: false,
        editable: false,
        render: (_: string, { type, accountSubscription, toAccount, fromAccount }) => (
          <>
            {isMobile && (
              <div className={styles.label}>{t('payments.operationHistory.columns.details')}:</div>
            )}
            <OperationDetails
              type={type}
              subscriptionName={accountSubscription?.subscription?.name}
              accountType={accountType}
              displayUserName={
                accountType === AccountTypeNames.BUSINESS
                  ? toAccount.displayUserName
                  : fromAccount.displayUserName
              }
            />
          </>
        ),
        title: () => (
          <TableTitle title={t('payments.operationHistory.columns.details')} sortId="details" />
        )
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        sorter: false,
        editable: false,
        render: (amount: string, { fromAccount: { id } }) => {
          const spending = accountId === id
          return (
            <>
              {isMobile && <div className={styles.label}>MDT:</div>}

              <div className={cls(styles.amount, !spending && styles.amountPlus)}>
                {spending && Number(amount) > 0 && '-'}
                {amount}
              </div>
            </>
          )
        },
        title: () => <TableTitle title="MDT" sortId="amount" />
      }
    ],
    [getTableFilter, isMobile, t, accountType, accountId]
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t('payments.operationHistory.title')}</h3>
          {isMobile && <>{filtersComponent}</>}
        </div>

        {!data.length && loading ? (
          <Skeleton
            loading={loading}
            active={true}
            title={false}
            paragraph={{
              rows: 10,
              width: '100%',
              className: styles.skeleton
            }}
          />
        ) : (
          <div className={styles.table}>
            <Table
              rowKey="createdAt"
              columns={COLUMNS}
              dataSource={data}
              pagination={false}
              showSorterTooltip={false}
              locale={{
                emptyText: (
                  <EmptyList
                    className={styles.placeholder}
                    icon={<TextBoxRemoveIcon />}
                    iconSize={EEmptyListIconSize.MD}
                    hasAppliedFilters={hasAppliedFilters}
                  />
                )
              }}
            />
          </div>
        )}
      </div>
      <Pagination
        current={page}
        total={total}
        onChange={fetchOperationsHistory}
        classes={{ container: styles.pagination }}
      />
    </div>
  )
}
