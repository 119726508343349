import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { TBrandingFormValues, UnityHubBranding } from 'App/components/UnityHub/UnityHubBranding'

import { API } from '../../../../services/api'
import { getEvent, editEvent } from '../../../../api/events'

export const EventBranding = () => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation()

  const { data } = useQuery({ queryKey: ['get-event', id], queryFn: () => getEvent(id) })

  const { isLoading, mutate } = useMutation({
    mutationFn: editEvent,
    onSuccess: () => {
      toast.success(t('common.toast.updatesSaved'), toastDefaultOptions)
    }
  })

  const handleSubmit = (payload: FormData) => {
    mutate({ id, payload })
  }

  const initialValues: TBrandingFormValues = {
    cover: API.EVENT_COVER(id),
    avatar: API.EVENT_AVATAR(id)
  }

  return (
    <UnityHubBranding
      displayUserName={data?.title}
      initialValues={initialValues}
      loading={isLoading}
      onUpload={handleSubmit}
    />
  )
}
