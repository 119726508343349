import { Link } from 'react-router-dom'
import cls from 'classnames'

import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'

import styles from './CloudIcon.module.scss'

type TCloudIconProps = PropsWithClassName

export const CloudIcon = ({ className }: TCloudIconProps) => (
  <Link className={cls(styles.root, className)} to="/files">
    <CloudUploadIcon />
  </Link>
)
