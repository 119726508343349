import { Field } from 'react-final-form'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TEventAttendee } from 'interfaces'
import { UploadAvatar } from 'App/components/common/UploadAvatar'
import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'

import styles from './EventProfileEditAvatar.module.scss'

type TEventProfileEditAvatarProps = PropsWithClassName<{
  isLoading: boolean

  initialValue?: string | File | null
  profileData?: TEventAttendee
}>

export const EventProfileEditAvatar = ({
  profileData,
  className,
  isLoading,
  initialValue = ''
}: TEventProfileEditAvatarProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { firstName, displayUserName = '', lastName, type } = profileData?.account ?? {}

  const handleFileUpload = (value: File, onChange: (file: File) => void) => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
        modalTitle: t('modal.uploadImage.title_userAvatar'),
        modalProps: {
          imageSrc: URL.createObjectURL(value),
          onCrop: (newFile) => {
            dispatch(hideModalAction())
            onChange(newFile)
          }
        }
      })
    )
  }

  const handleRemoveAvatar = (
    onSuccess: () => void,
    onChange: (value: File | string | null) => void,
    hasFile = false
  ) =>
    dispatch(
      showModalAction({
        modalType: EModalComponents.DELETE_IMAGE_DIALOG,
        modalTitle: t('modal.removeImageConfirm.title_eventProfile'),
        modalProps: {
          onClick: () => {
            onSuccess()
            dispatch(hideModalAction())
            onChange(hasFile ? initialValue : null)
          }
        }
      })
    )

  return (
    <div className={cls(styles.root, className)}>
      <Field name="avatar">
        {({ input }) => (
          <UploadAvatar
            file={input.value || ''}
            loading={isLoading}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type?.name}
            onFileUpload={(value) => handleFileUpload(value, input.onChange)}
            onRemoveAvatar={(onSuccess) =>
              handleRemoveAvatar(onSuccess, input.onChange, input.value instanceof File)
            }
            classes={{
              root: styles.avatar,
              skeleton: styles.avatarSkeleton,
              remove: styles.avatarRemove,
              removeIcon: styles.avatarRemoveIcon
            }}
          />
        )}
      </Field>
    </div>
  )
}
