import { ComponentType } from 'react'
import makeMentionPlugin, { MentionPluginConfig } from '@draft-js-plugins/mention'
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions'
import cls from 'classnames'

import { Mention } from 'App/components/common/DraftEditor/Mention'

import styles from './Mention.module.scss'

type TCreateMentionPluginProps = Pick<
  MentionPluginConfig,
  | 'mentionTrigger'
  | 'mentionPrefix'
  | 'entityMutability'
  | 'supportWhitespace'
  | 'mentionComponent'
  | 'theme'
>

type TCreateMentionPluginResponse = {
  plugins: ReturnType<typeof makeMentionPlugin>[]
  Suggestions: ComponentType<MentionSuggestionsPubProps>
}

export const createMentionPlugin = ({
  mentionTrigger,
  mentionPrefix,
  theme,
  mentionComponent = Mention as ComponentType<any>,
  entityMutability = 'IMMUTABLE',
  supportWhitespace = true
}: TCreateMentionPluginProps): TCreateMentionPluginResponse => {
  const mentionPlugin = makeMentionPlugin({
    mentionTrigger,
    mentionPrefix,
    entityMutability,
    supportWhitespace,
    theme: {
      mention: cls(styles.mention, theme?.mention),
      mentionSuggestions: cls(styles.mentionSuggestions, theme?.mentionSuggestions),
      mentionSuggestionsEntry: cls(styles.mentionSuggestionsEntry, theme?.mentionSuggestionsEntry),
      mentionSuggestionsPopup: cls(styles.mentionSuggestionsPopup, theme?.mentionSuggestionsPopup)
    },
    popperOptions: { placement: 'top' },
    mentionComponent
  })

  return { plugins: [mentionPlugin], Suggestions: mentionPlugin.MentionSuggestions }
}
