import { ButtonChip, TButtonChipProps } from 'App/components'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

export type TButtonInviteToContactProps = TButtonChipProps & {
  userId?: string
  onSuccess?: () => void
}

export const ButtonInviteToContact = ({
  userId,
  onSuccess,
  ...rest
}: TButtonInviteToContactProps) => {
  const { inviteToContacts } = useContactInvitation()

  const handleClick = () => {
    userId && inviteToContacts({ accountId: userId, onSuccess })
  }

  return (
    <ButtonChip onClick={handleClick} {...rest}>
      <AccountPlusIcon />
    </ButtonChip>
  )
}
