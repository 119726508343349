import { useMemo } from 'react'

import { useAppSelector } from 'store'
import {
  UnityHubNav,
  TUnityHubNavProps,
  TUnityHubNavControls,
  TUnityHubNavItem
} from 'App/components/UnityHub'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'

type TOrganizationsNavProps = Pick<TUnityHubNavProps, 'loading'>

export const OrganizationsNav = ({ loading }: TOrganizationsNavProps) => {
  const { list, ids } = useAppSelector((state) => state.organizations.data)
  const organizationsNotifications = useAppSelector(
    (state) => state.notifications.general.organizations.list
  )
  const totalInvitesToOrganization = useAppSelector(
    (state) => state.notifications.general.organizations.totalInvitesToOrganization
  )
  const invitationIds = useAppSelector((state) => state.organizations.invitations.received.ids)

  const items = useMemo<TUnityHubNavItem[]>(
    () =>
      ids.map((id) => ({
        id,
        to: `/organizations/${id}`,
        name: list[id].businessProfile.name,
        count: organizationsNotifications?.[id]?.totalUnreadCount
      })),
    [ids, list, organizationsNotifications]
  )

  const controls = useMemo<TUnityHubNavControls>(
    () => ({
      items: [
        {
          id: 'invitations',
          to: '/organizations/invitations',
          icon: <AccountPlusIcon />,
          count: totalInvitesToOrganization
        }
      ]
    }),
    [totalInvitesToOrganization]
  )

  if (!loading && !ids.length && !invitationIds.length) {
    return null
  }

  return <UnityHubNav items={items} loading={loading} controls={controls} />
}
