import { useTranslation } from 'react-i18next'

import { MonthlyTopUpButton } from '../../../components/Payments/TopUp/MonthlyTopUpButton'

import styles from './UpgradeSubscriptionFailed.module.scss'

export const UpgradeSubscriptionFailed = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <p className={styles.description}>{t('modal.upgradeSubscriptionFailed.content')}</p>
      <MonthlyTopUpButton>{t('modal.upgradeSubscriptionFailed.submitButton')}</MonthlyTopUpButton>
    </div>
  )
}
