import { useCallback, useEffect, useMemo, useRef } from 'react'
import { VariableSizeList } from 'react-window'
import debounce from 'lodash/debounce'

type TProps = {
  defaultRowHeight: number
}

export const useVariableSizeListHeight = ({ defaultRowHeight }: TProps) => {
  const listRef = useRef<VariableSizeList | null>(null)
  const rowsHeights = useRef<Record<number, number>>({})

  const getRowHeight = useCallback(
    (index: number) => rowsHeights.current[index] || defaultRowHeight,
    [defaultRowHeight]
  )

  const applyChanges = useMemo(
    () =>
      debounce(() => {
        listRef.current?.resetAfterIndex(0)
      }, 20),
    []
  )

  const setRowHeight = useCallback(
    (index: number, size: number) => {
      rowsHeights.current = { ...rowsHeights.current, [index]: size }
      applyChanges()
    },
    [applyChanges]
  )

  useEffect(
    () => () => {
      applyChanges.cancel()
    },
    [applyChanges]
  )

  return {
    listRef,
    rowsHeights,
    getRowHeight,
    setRowHeight
  }
}
