import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { FileExtensionCategoryEnum } from '@medentee/enums'

import {
  useAppSelector,
  filesResetAction,
  setFileFiltersRequest,
  setFileSharedFiltersRequest,
  resetFileSharedFilters
} from 'store'
import { useFilters, TFilterValue, EFilterTypes, TFiltersProps } from 'App/components'
import { createSelectorOptions } from 'utils'

const SHARED_FILES_PATH = '/files/shared'

export const useFilePageFilters = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { pathname } = useLocation()

  const filesExtensionCategories = useAppSelector((state) => state.file.filters.extensionCategories)
  const sharedFilesExtensionCategories = useAppSelector(
    (state) => state.fileShared.filters.extensionCategories
  )

  const resetFilesFilters = () => dispatch(filesResetAction())
  const handleFilesFiltersChange = (key: string, value: TFilterValue) =>
    dispatch(setFileFiltersRequest({ [key]: value }))

  const extensionCategoriesOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(FileExtensionCategoryEnum), (value) =>
        t(`enum.fileExtensionCategoryEnum.${value}`)
      ),
    [t]
  )

  const filters: TFiltersProps['filters'] = [
    {
      title: t('files.filters.categories'),
      sectionKey: 'extensionCategories',
      items: [
        {
          filterKey: 'extensionCategories',
          type: EFilterTypes.CHECKBOX,
          options: extensionCategoriesOptions
        }
      ]
    }
  ]

  let config: TFiltersProps = {
    onReset: resetFilesFilters,
    value: {
      extensionCategories: filesExtensionCategories
    },
    onChange: handleFilesFiltersChange,
    filters
  }

  if (pathname === SHARED_FILES_PATH) {
    const resetFilters = () => dispatch(resetFileSharedFilters())

    const handleFiltersChange = (key: string, value: TFilterValue) =>
      dispatch(setFileSharedFiltersRequest({ [key]: value }))

    config = {
      onReset: resetFilters,
      value: {
        extensionCategories: sharedFilesExtensionCategories
      },
      onChange: handleFiltersChange,
      filters
    }
  }

  return useFilters(config)
}
