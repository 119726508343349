import { memo } from 'react'

import {
  TCallContainerProps,
  ReceivedCallContainer,
  CallOwnerContainer,
  CallRecipientContainer
} from 'App/containers'
import { PortalWrapper } from 'App/components'
import { VoiceRoom } from 'App/containers/Organizations/VoiceRoom'

export type TCallWrapperProps = TCallContainerProps

export const CallWrapperView = ({
  isInitiatedCall,
  isAcceptedCall,
  isComingCall,
  activeVoiceRoom
}: TCallWrapperProps) => {
  const callOwner = isInitiatedCall && !isAcceptedCall && !isComingCall
  const callRecipient = !isInitiatedCall && isAcceptedCall && !isComingCall
  const receivedCall = !isInitiatedCall && !isAcceptedCall && isComingCall

  const getContent = () => {
    if (activeVoiceRoom) {
      return <VoiceRoom />
    }

    if (callOwner) {
      return <CallOwnerContainer />
    }

    if (callRecipient) {
      return <CallRecipientContainer />
    }

    if (receivedCall) {
      return <ReceivedCallContainer />
    }

    return null
  }

  return (
    <>
      <PortalWrapper portalCssSelector="call-wrapper-portal">{getContent()}</PortalWrapper>
    </>
  )
}

export const CallWrapper = memo(CallWrapperView)
