import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  GET_CHAT_ROOM,
  GET_CHAT_ROOMS,
  getChatRoomsRequest,
  resetChatAction,
  setChatTypeAction,
  getSelectedChatRoomSelector
} from 'store'
import { ChatOneToOne } from 'App/components'

export type TChatOneToOneContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => {
  const activeChatId = state.chat.chatRooms.activeChatId

  return {
    loadingChatRooms: createLoadingSelector([GET_CHAT_ROOMS])(state),
    loadingChatRoom: createLoadingSelector([GET_CHAT_ROOM])(state),
    lengthChatRooms: state.chat.chatRooms.ids.length,
    selectedChat: getSelectedChatRoomSelector(activeChatId)(state),
    activeChatId,
    accountType: state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL,
    chatType: state.chat.chatRooms.chatType,
    search: state.chat.chatRooms.search
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getChatRooms: getChatRoomsRequest,
      resetChat: resetChatAction,
      setChatType: setChatTypeAction
    },
    dispatch
  )

export const ChatOneToOneContainer = connect(mapStateToProps, mapDispatchToProps)(ChatOneToOne)
