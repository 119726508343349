export const RESET_MY_ADVERT_REQUESTS = 'RESET_MY_ADVERT_REQUESTS'
export const RESET_ADVERTS = 'RESET_ADVERTS'
export const RESET_MY_ADVERTS = 'RESET_MY_ADVERTS'
export const RESET_MY_REQUESTS = 'RESET_MY_REQUESTS'

export const REDIRECT_TO_USER_PROFILE_ACTION = 'REDIRECT_TO_USER_PROFILE_ACTION'

export const GET_ADVERTS = 'GET_ADVERTS'
export const GET_ADVERTS_REQUEST = 'GET_ADVERTS_REQUEST'
export const GET_ADVERTS_SUCCESS = 'GET_ADVERTS_SUCCESS'
export const GET_ADVERTS_ERROR = 'GET_ADVERTS_ERROR'

export const GET_MY_ADVERTS = 'GET_MY_ADVERTS'
export const GET_MY_ADVERTS_REQUEST = 'GET_MY_ADVERTS_REQUEST'
export const GET_MY_ADVERTS_SUCCESS = 'GET_MY_ADVERTS_SUCCESS'
export const GET_MY_ADVERTS_ERROR = 'GET_MY_ADVERTS_ERROR'

export const GET_MY_ADVERT_REQUESTS = 'GET_MY_ADVERT_REQUESTS'
export const GET_MY_ADVERT_REQUESTS_REQUEST = 'GET_MY_ADVERT_REQUESTS_REQUEST'
export const GET_MY_ADVERT_REQUESTS_SUCCESS = 'GET_MY_ADVERT_REQUESTS_SUCCESS'
export const GET_MY_ADVERT_REQUESTS_ERROR = 'GET_MY_ADVERT_REQUESTS_ERROR'

export const GET_MY_REQUESTS = 'GET_MY_REQUESTS'
export const GET_MY_REQUESTS_REQUEST = 'GET_MY_REQUESTS_REQUEST'
export const GET_MY_REQUESTS_SUCCESS = 'GET_MY_REQUESTS_SUCCESS'
export const GET_MY_REQUESTS_ERROR = 'GET_MY_REQUESTS_ERROR'

export const CREATE_ADVERT = 'CREATE_ADVERT'
export const CREATE_ADVERT_REQUEST = 'CREATE_ADVERT_REQUEST'
export const CREATE_ADVERT_SUCCESS = 'CREATE_ADVERT_SUCCESS'
export const CREATE_ADVERT_ERROR = 'CREATE_ADVERT_ERROR'

export const REMOVE_REQUEST = 'REMOVE_REQUEST'
export const REMOVE_REQUEST_REQUEST = 'REMOVE_REQUEST_REQUEST'
export const REMOVE_REQUEST_SUCCESS = 'REMOVE_REQUEST_SUCCESS'
export const REMOVE_REQUEST_ERROR = 'REMOVE_REQUEST_ERROR'

export const IGNORE_REQUEST = 'IGNORE_REQUEST'
export const IGNORE_REQUEST_REQUEST = 'IGNORE_REQUEST_REQUEST'
export const IGNORE_REQUEST_SUCCESS = 'IGNORE_REQUEST_SUCCESS'
export const IGNORE_REQUEST_ERROR = 'IGNORE_REQUEST_ERROR'

export const BECOME_CONTACTS = 'BECOME_CONTACTS'
export const BECOME_CONTACT_REQUEST = 'BECOME_CONTACTS_REQUEST'
export const BECOME_CONTACT_SUCCESS = 'BECOME_CONTACTS_SUCCESS'
export const BECOME_CONTACT_ERROR = 'BECOME_CONTACTS_ERROR'

export const WITHDRAW_REQUEST = 'WITHDRAW_REQUEST'
export const WITHDRAW_REQUEST_REQUEST = 'WITHDRAW_REQUEST_REQUEST'
export const WITHDRAW_REQUEST_SUCCESS = 'WITHDRAW_REQUEST_SUCCESS'
export const WITHDRAW_REQUEST_ERROR = 'WITHDRAW_REQUEST_ERROR'

export const REMOVE_ADVERT = 'REMOVE_ADVERT'
export const REMOVE_ADVERT_REQUEST = 'REMOVE_ADVERT_REQUEST'
export const REMOVE_ADVERT_SUCCESS = 'REMOVE_ADVERT_SUCCESS'
export const REMOVE_ADVERT_ERROR = 'REMOVE_ADVERT_ERROR'

export const REPOST_ADVERT = 'REPOST_ADVERT'
export const REPOST_ADVERT_REQUEST = 'REPOST_ADVERT_REQUEST'
export const REPOST_ADVERT_SUCCESS = 'REPOST_ADVERT_SUCCESS'
export const REPOST_ADVERT_ERROR = 'REPOST_ADVERT_ERROR'

export const DEACTIVATE_ADVERT = 'DEACTIVATE_ADVERT'
export const DEACTIVATE_ADVERT_REQUEST = 'DEACTIVATE_ADVERT_REQUEST'
export const DEACTIVATE_ADVERT_SUCCESS = 'DEACTIVATE_ADVERT_SUCCESS'
export const DEACTIVATE_ADVERT_ERROR = 'DEACTIVATE_ADVERT_ERROR'

export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'

export const SET_SEARCH = 'SET_SEARCH'

export const CREATE_CONNECTION_REQUEST = 'CREATE_CONNECTION_REQUEST'
export const CREATE_CONNECTION_REQUEST_REQUEST = 'CREATE_CONNECTION_REQUEST_REQUEST'
export const CREATE_CONNECTION_REQUEST_SUCCESS = 'CREATE_CONNECTION_REQUEST_SUCCESS'
export const CREATE_CONNECTION_REQUEST_ERROR = 'CREATE_CONNECTION_REQUEST_ERROR'

export const SET_ADVERTS_DATA = 'SET_ADVERTS_DATA'
