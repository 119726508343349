import { State } from 'redux/rootReducer'
import { TCaseCloudVariant } from 'App/components'
import { TIds } from 'store/store.types'

export const getKebabMenuPropsSelector =
  (variant: TCaseCloudVariant, selectedIds: TIds) => (state: State) => {
    switch (variant) {
      case 'case-cloud':
        return selectedIds.map((fileId) => {
          const { fileName, extension } = state.caseCloud.cloudFiles.list[fileId] || {}

          return {
            fileId,
            fileName,
            fileExtension: extension
          }
        })

      case 'shard-with-me':
        return selectedIds.map((fileId) => {
          const { fileName, extension } = state.caseCloud.sharedWithMeFiles.list[fileId] || {}

          return {
            fileId,
            fileName,
            fileExtension: extension
          }
        })

      case 'upload-by-me':
        return selectedIds.map((fileId) => {
          const { fileName, extension } = state.caseCloud.uploadedByMeFiles.list[fileId] || {}

          return {
            fileId,
            fileName,
            fileExtension: extension
          }
        })

      default:
        return null
    }
  }
