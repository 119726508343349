// ------------- DEFAULT --------------
export enum ESocketEventNames {
  INVITE_APPROVED = 'invite_approved',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}

export enum ESocketEmitEventNames {
  INVITE_APPROVED = 'invite_approved'
}

// ------------- SYSTEM --------------
export enum ESocketEventNamesSystem {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ONLINE_ADMIN = 'online_admin',
  OFFLINE_ADMIN = 'offline_admin',
  LOGOUT = 'logout',
  ACCOUNT_SWITCHED = 'account_switched',
  ADMIN_LOST_ACCESS = 'admin_lost_access',
  REMOVED_FROM_ADMIN = 'removed_from_admin',
  INTERMEDIATE_STATE = 'intermediate_state',
  REFRESH_GENERAL_NOTIFICATIONS = 'refresh_general_notifications',
  VIDEO_QUALITY_UPDATED = 'video_quality_updated',
  ACCEPTED_ADMIN_INVITATION = 'accepted_admin_invitation',
  ACCEPTED_SUPERADMIN_INVITATION = 'accepted_superadmin_invitation',
  REJECTED_ADMIN_INVITATION = 'rejected_admin_invitation',
  REJECTED_SUPERADMIN_INVITATION = 'rejected_superadmin_invitation',
  PAYMENT_SUCCEEDED = 'payment_succeeded',
  PAYMENT_FAILED = 'payment_failed',
  UPDATE_RELATED_ACCOUNTS = 'update_related_accounts'
}

export enum ESocketEmitEventNamesSystem {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

// ------------- CHAT --------------
export enum ESocketEventNamesChat {
  RECEIVE_NEW_MESSAGE = 'receive_new_message',
  RECEIVE_NEW_MESSAGE_NOTIFICATIONS = 'receive_new_message_notifications',
  TYPING_STARTED = 'typing_started',
  TYPING_ENDED = 'typing_ended',
  READ_MESSAGE = 'read_message',
  EDITING_STARTED = 'editing_started',
  EDITING_ENDED = 'editing_ended',
  REMOVE_MESSAGE = 'remove_message',
  INTERMEDIATE_STATE = 'intermediate_state',
  CHAT_STATUS_CHANGED = 'chat_status_changed',
  CHAT_MUTED = 'chat_muted',
  CHAT_UNMUTED = 'chat_unmuted',
  ADDED_USER_TO_CHAT = 'added_user_to_chat',
  REMOVED_USER_FROM_CHAT = 'removed_user_from_chat',
  DELEGATED_MANAGER_IN_TEXT_CHANNEL = 'delegated_manager_in_text_channel',
  LOST_MANAGER_IN_TEXT_CHANNEL = 'lost_manager_in_text_channel',
  ADD_MESSAGE_REACTION = 'add_message_reaction',
  REMOVE_MESSAGE_REACTION = 'remove_message_reaction',
  PINNED_MESSAGE = 'pinned_message',
  UNPINNED_MESSAGE = 'unpinned_message'
}

export enum ESocketEmitEventNamesChat {
  SEND_NEW_MESSAGE = 'send_new_message',
  TYPING_STARTED = 'typing_started',
  TYPING_ENDED = 'typing_ended',
  READ_MESSAGE = 'read_message',
  EDITING_STARTED = 'editing_started',
  EDITING_ENDED = 'editing_ended',
  REMOVE_MESSAGE = 'remove_message',
  ADD_MESSAGE_REACTION = 'add_message_reaction',
  REMOVE_MESSAGE_REACTION = 'remove_message_reaction'
}

// ------------- CALL --------------

export enum ESocketEventNamesCall {
  END_CALL = 'end_call',
  CANCEL_OR_REJECT_CALL = 'cancel_or_reject_call',
  ALLOW_CALL = 'allow_call',
  CALL_ACCEPTED_MYSELF = 'call_accepted_myself',
  EXIST_CALLS = 'exist_calls',
  CREATE_CALL = 'create_call',
  SERVICE_MESSAGE = 'service_message',
  MISSED_CALL = 'missed_call',
  ADD_USERS_TO_CALL = 'add_users_to_call',
  CALL_COUNTS_FOR_WIDGET = 'call_counts_for_widget',
  JOIN_VOICE_ROOM = 'join_voice_room',
  LEAVE_VOICE_ROOM = 'leave_voice_room',
  UPDATE_VOICE_ROOM_MEMBERS = 'update_voice_room_members',
  KICK_USER_FROM_VOICE_ROOM = 'kick_user_from_voice_room'
}

export enum ESocketEmitEventNamesCall {
  END_CALL = 'end_call',
  CANCEL_OR_REJECT_CALL = 'cancel_or_reject_call',
  ALLOW_CALL = 'allow_call',
  CREATE_CALL = 'create_call',
  SERVICE_MESSAGE = 'service_message',
  ADD_USERS_TO_CALL = 'add_users_to_call',
  CALL_COUNTS_FOR_WIDGET = 'call_counts_for_widget',
  JOIN_VOICE_ROOM = 'join_voice_room',
  LEAVE_VOICE_ROOM = 'leave_voice_room'
}

// ------------- COMMUNITIES --------------

export enum ESocketEventNamesCommunities {
  LOST_ACCESS_TO_COMMUNITY = 'lost_access_to_community'
}

export enum ESocketEmitEventNamesCommunities {
  LOST_ACCESS_TO_COMMUNITY = 'lost_access_to_community'
}

// ------------- EVENTS --------------

export enum ESocketEventNamesEvents {
  LOST_ACCESS_TO_EVENT = 'lost_access_to_event'
}

export enum ESocketEmitEventNamesEvents {
  LOST_ACCESS_TO_EVENT = 'lost_access_to_event'
}

// ------------- BROADCASTS --------------

export enum ESocketEventNamesBroadcasts {
  BROADCAST_CREATED = 'live_event_created',
  BROADCAST_DELETED = 'live_event_deleted',
  BROADCAST_WATCHED = 'watch_live_event',
  UPDATE_BROADCAST_WATCHERS = 'update_live_event_watchers',
  UPDATE_BROADCAST_STATUS = 'update_live_event_status'
}

export enum ESocketEmitEventNamesBroadcasts {
  WATCH_BROADCAST = 'watch_live_event',
  UNWATCH_BROADCAST = 'unwatch_live_event'
}
