import useLocalStorageState from 'use-local-storage-state'

import { accountIdSelector, useAppSelector } from 'store'

export type TFilesViewVariant = 'staff' | 'departments' | undefined

export const useStaffTabView = (
  organizationId: string,
  defaultValue: TFilesViewVariant = 'staff'
) => {
  const accountId = useAppSelector(accountIdSelector)

  const state = useLocalStorageState<TFilesViewVariant>(`${accountId}-${organizationId}`, {
    defaultValue
  })

  return state
}
