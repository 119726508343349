import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { ReactComponent as PollIcon } from 'assets/icons/Poll.svg'
import { ReactComponent as MediaSkeletonIcon } from 'assets/icons/MediaSkeleton.svg'
import { ReactComponent as MonitorScreenshotIcon } from 'assets/icons/MonitorScreenshot.svg'
import { ReactComponent as MonitorScreenshotOffIcon } from 'assets/icons/MonitorScreenshotOff.svg'
import { ReactComponent as HandBackRightOutlineIcon } from 'assets/icons/HandBackRightOutline.svg'
import { ReactComponent as HandBackRightOffOutlineIcon } from 'assets/icons/HandBackRightOffOutline.svg'
import { ReactComponent as FullscreenExitIcon } from 'assets/icons/FullscreenExit.svg'
import { ReactComponent as FullscreenIcon } from 'assets/icons/Fullscreen.svg'
import { ReactComponent as ViewAgendaOutlineIcon } from 'assets/icons/ViewAgendaOutline.svg'
import { ReactComponent as ViewGridOutlineIcon } from 'assets/icons/ViewGridOutline.svg'
import { ReactComponent as VideoSwitchIcon } from 'assets/icons/VideoSwitch.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'
import { ReactComponent as MessageTextOffIcon } from 'assets/icons/MessageTextOff.svg'

import { CallOutgoingFooterNotification } from './CallOutgoingFooterNotification'

type TUseKebabMenuProps = {
  isVideoDisable: boolean
  isRiseHand: boolean
  isShareScreen: boolean
  isGridView: boolean
  isDesktop: boolean
  isMobile: boolean
  isVideoMute: boolean
  isMobileBrowser: boolean
  isFullscreen: boolean
  isChatOpen: boolean
  shouldShowChatButton: boolean
  shouldShowChatButtonNotification: boolean

  handleRiseHand: () => void
  handleScreenShare: () => void
  handleBackground: () => void
  handlePerformanceSettings: () => void
  handleGridView: () => void
  onFullscreen: () => void
  toggleCamera: () => void
  handleChat: () => void
}

export type TUseKebabMenu = {
  getMenuItems: () => TMenuItemProps[]
}

export const useKebabMenu = ({
  isDesktop,
  isMobile,
  isVideoDisable,
  isShareScreen,
  isRiseHand,
  isGridView,
  isVideoMute,
  isMobileBrowser,
  isFullscreen,
  isChatOpen,
  shouldShowChatButton,
  shouldShowChatButtonNotification,
  handlePerformanceSettings,
  handleScreenShare,
  handleBackground,
  handleRiseHand,
  handleGridView,
  onFullscreen,
  toggleCamera,
  handleChat
}: TUseKebabMenuProps): TUseKebabMenu => {
  const { t } = useTranslation()

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        hidden: isDesktop || isMobileBrowser,
        content: isShareScreen
          ? t('chat.call.toolbar.shareScreen_stop')
          : t('chat.call.toolbar.shareScreen'),
        icon: isShareScreen ? <MonitorScreenshotIcon /> : <MonitorScreenshotOffIcon />,
        onClick: handleScreenShare
      },
      {
        hidden: !isMobile,
        content: isGridView
          ? t('chat.call.toolbar.tileView_exit')
          : t('chat.call.toolbar.tileView'),
        icon: isGridView ? <ViewAgendaOutlineIcon /> : <ViewGridOutlineIcon />,
        onClick: handleGridView
      },
      {
        content: t('chat.call.toolbar.performanceSettings'),
        icon: <PollIcon />,
        disabled: isVideoDisable,
        onClick: handlePerformanceSettings
      },
      {
        hidden: !isMobile,
        content: isFullscreen
          ? t('chat.call.toolbar.fullscreen_exit')
          : t('chat.call.toolbar.fullscreen'),
        icon: isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />,
        onClick: onFullscreen
      },
      {
        hidden: isDesktop || isMobileBrowser,
        content: t('chat.call.toolbar.background'),
        icon: <MediaSkeletonIcon />,
        onClick: handleBackground
      },
      {
        hidden: !isMobile,
        content: isRiseHand ? t('chat.call.toolbar.hand_lower') : t('chat.call.toolbar.hand'),
        icon: isRiseHand ? <HandBackRightOutlineIcon /> : <HandBackRightOffOutlineIcon />,
        onClick: handleRiseHand
      },
      {
        hidden: !isMobileBrowser,
        content: t('chat.call.toolbar.switchCamera'),
        icon: <VideoSwitchIcon />,
        onClick: toggleCamera,
        disabled: isVideoMute
      },
      {
        hidden: isDesktop || !shouldShowChatButton,
        content: t('chat.call.toolbar.chat'),
        icon: (
          <CallOutgoingFooterNotification
            count={shouldShowChatButtonNotification ? 1 : 0}
            variant="dot"
          >
            {isChatOpen ? <MessageTextIcon /> : <MessageTextOffIcon />}
          </CallOutgoingFooterNotification>
        ),
        onClick: handleChat
      }
    ],
    [
      shouldShowChatButtonNotification,
      shouldShowChatButton,
      isChatOpen,
      isDesktop,
      isShareScreen,
      isMobile,
      isGridView,
      isVideoDisable,
      isRiseHand,
      isVideoMute,
      isMobileBrowser,
      isFullscreen,
      handleGridView,
      handleScreenShare,
      onFullscreen,
      handlePerformanceSettings,
      handleBackground,
      handleRiseHand,
      toggleCamera,
      handleChat,
      t
    ]
  )

  const getMenuItems = useCallback(() => menuItems, [menuItems])

  return { getMenuItems }
}
