import { action } from '../store.utils'
import { TError } from '../store.types'

import {
  TCity,
  TCompanySpecialization,
  TCountry,
  TGetCitiesRequestPayload,
  TProfession,
  TPurpose,
  TGetCountriesRequestPayload,
  TSplittedProfessions
} from './misc.types'

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS'
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR'

export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST'
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS'
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR'

export const GET_COMPANY_SPECIALIZATIONS = 'GET_COMPANY_SPECIALIZATIONS'
export const GET_COMPANY_SPECIALIZATIONS_REQUEST = 'GET_COMPANY_SPECIALIZATIONS_REQUEST'
export const GET_COMPANY_SPECIALIZATIONS_SUCCESS = 'GET_COMPANY_SPECIALIZATIONS_SUCCESS'
export const GET_COMPANY_SPECIALIZATIONS_ERROR = 'GET_COMPANY_SPECIALIZATIONS_ERROR'

export const GET_PROFESSIONS = 'GET_PROFESSIONS'
export const GET_PROFESSIONS_REQUEST = 'GET_PROFESSIONS_REQUEST'
export const GET_PROFESSIONS_SUCCESS = 'GET_PROFESSIONS_SUCCESS'
export const GET_PROFESSIONS_ERROR = 'GET_PROFESSIONS_ERROR'

export const GET_ALL_PROFESSIONS = 'GET_ALL_PROFESSIONS'
export const GET_ALL_PROFESSIONS_REQUEST = 'GET_ALL_PROFESSIONS_REQUEST'
export const GET_ALL_PROFESSIONS_SUCCESS = 'GET_ALL_PROFESSIONS_SUCCESS'
export const GET_ALL_PROFESSIONS_ERROR = 'GET_ALL_PROFESSIONS_ERROR'

export const GET_PURPOSES = 'GET_PURPOSES'
export const GET_PURPOSES_REQUEST = 'GET_PURPOSES_REQUEST'
export const GET_PURPOSES_SUCCESS = 'GET_PURPOSES_SUCCESS'
export const GET_PURPOSES_ERROR = 'GET_PURPOSES_ERROR'

export const GET_VIDEO_STREAM_URL = 'GET_VIDEO_STREAM_URL'
export const GET_VIDEO_STREAM_URL_REQUEST = 'GET_VIDEO_STREAM_URL_REQUEST'
export const GET_VIDEO_STREAM_URL_SUCCESS = 'GET_VIDEO_STREAM_URL_SUCCESS'
export const GET_VIDEO_STREAM_URL_ERROR = 'GET_VIDEO_STREAM_URL_ERROR'

export const getCountriesRequest = (payload?: TGetCountriesRequestPayload) =>
  action(GET_COUNTRIES_REQUEST, payload)
export const getCountriesSuccess = (payload: TCountry[]) => action(GET_COUNTRIES_SUCCESS, payload)
export const getCountriesError = (payload: TError) => action(GET_COUNTRIES_ERROR, payload)

export const getCitiesRequest = (payload: TGetCitiesRequestPayload) =>
  action(GET_CITIES_REQUEST, payload)
export const getCitiesSuccess = (payload: TCity[]) => action(GET_CITIES_SUCCESS, payload)
export const getCitiesError = (payload: TError) => action(GET_CITIES_ERROR, payload)

export const getCompanySpecializationsRequest = () => action(GET_COMPANY_SPECIALIZATIONS_REQUEST)
export const getCompanySpecializationsSuccess = (payload: TCompanySpecialization[]) =>
  action(GET_COMPANY_SPECIALIZATIONS_SUCCESS, payload)
export const getCompanySpecializationsError = (payload: TError) =>
  action(GET_COMPANY_SPECIALIZATIONS_ERROR, payload)

export const getProfessionsRequest = () => action(GET_PROFESSIONS_REQUEST)
export const getProfessionsSuccess = (payload: TProfession[]) =>
  action(GET_PROFESSIONS_SUCCESS, payload)
export const getProfessionsError = (payload: TError) => action(GET_PROFESSIONS_ERROR, payload)

export const getAllProfessionsRequest = () => action(GET_ALL_PROFESSIONS_REQUEST)
export const getAllProfessionsSuccess = (payload: TSplittedProfessions) =>
  action(GET_ALL_PROFESSIONS_SUCCESS, payload)
export const getAllProfessionsError = (payload: TError) =>
  action(GET_ALL_PROFESSIONS_ERROR, payload)

export const getPurposesRequest = () => action(GET_PURPOSES_REQUEST)
export const getPurposesSuccess = (payload: TPurpose[]) => action(GET_PURPOSES_SUCCESS, payload)
export const getPurposesError = (payload: TError) => action(GET_PURPOSES_ERROR, payload)

export const getVideoStreamUrlRequest = (fileId: string) =>
  action(GET_VIDEO_STREAM_URL_REQUEST, fileId)
export const getVideoStreamUrlSuccess = (url: string) => action(GET_VIDEO_STREAM_URL_SUCCESS, url)
export const getVideoStreamUrlError = (payload: TError) =>
  action(GET_VIDEO_STREAM_URL_ERROR, payload)
