import { FC, useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { ErrorModal, Button } from 'App/components'
import { TFileDiscardCasesPermissionsConfirmContainerProps } from 'App/containers'

import styles from './FileDiscardCasesPermissionsConfirm.module.scss'

export type TFileDiscardCasesPermissionsConfirmOwnProps = {
  fileId: string
  contactUserId: string
}

type TFileDiscardCasesPermissionsConfirmProps = TFileDiscardCasesPermissionsConfirmOwnProps &
  TFileDiscardCasesPermissionsConfirmContainerProps

export const FileDiscardCasesPermissionsConfirm: FC<TFileDiscardCasesPermissionsConfirmProps> = ({
  error,
  processing,
  discardPermissions,
  removeErrorNotification,
  fileId,
  contactUserId
}) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  const onClick = () => {
    discardPermissions({
      fileId,
      contactUserId
    })
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{t('modal.discardCaseFilePermissionsConfirm.content')}</p>
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={onClick}
      >
        {t('modal.discardCaseFilePermissionsConfirm.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
