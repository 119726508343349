import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import cls from 'classnames'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction } from 'store'
import { EModalComponents } from 'App/containers'
import { TButtonSize, ECategoryDialogAction, Button } from 'App/components'
import { State } from 'redux/rootReducer'
import { MAX_CATEGORIES_ALLOWED } from 'globalConstants'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './CreateCategory.module.scss'

export type TCreateCategoryProps = {
  preSelectedIds?: string[]
  contactType?: AccountTypeNames
  hideIcon?: boolean
  size?: TButtonSize
  className?: string
  onClick?: () => void
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CreateCategoryComponent = ({
  hideIcon,
  size = 'md',
  className = '',
  onClick,
  showModal,
  categoriesTotal,
  preSelectedIds
}: TCreateCategoryProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    showModal({
      modalType: EModalComponents.CATEGORY_DIALOG,
      modalTitle: t('modal.createCategory.title'),
      modalProps: {
        action: ECategoryDialogAction.CREATE,
        preSelectedIds
      }
    })
  }

  return (
    <Button
      variant="outlined"
      size={size}
      icon={<PlusIcon />}
      className={cls({
        [styles.button]: true,
        [className]: !!className
      })}
      hideIcon={hideIcon}
      onClick={handleClick}
      disabled={categoriesTotal >= MAX_CATEGORIES_ALLOWED}
    >
      {t('contacts.createNewCategoryButton')}
    </Button>
  )
}

const mapStateToProps = (state: State) => ({
  categoriesTotal: state.categoryList.total
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const CreateCategory = connect(mapStateToProps, mapDispatchToProps)(CreateCategoryComponent)
