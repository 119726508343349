import { EOrganizationMemberSort, ESorting } from 'enums'
import { IOrganizationJoinToChannelLinkDTO, TChannelVariant } from 'interfaces'
import { TOrganization } from 'store'
import { ICreateTextChannelDTO } from 'interfaces/api/organizations/textChannel'
import { IVoiceRoomDTO } from 'interfaces/api/organizations/voiceRoom'

import { QueryBuilder } from '../utils'
import { IDepartment, IDepartmentGroupedMembers } from '../interfaces'
import { API, api, APIData, APIResultsResponse } from '../services/api'
import { TOrganizationMember } from '../store'
import { PAGINATION_DEFAULT_SHOW_BY } from '../types'

export type TCreateDepartmentPayload = {
  id: string
  name: string
  description: string
}
export const createDepartment = ({ id, ...data }: TCreateDepartmentPayload) =>
  api.post(API.ORGANIZATION_DEPARTMENTS(id), data)

export type TUpdateDepartmentPayload = {
  id: string
  organizationId: string
  name?: string
  description?: string
  accountsIds?: string[]
  leaderId?: string | null
}
export const updateDepartment = async ({
  id,
  organizationId,
  ...data
}: TUpdateDepartmentPayload) => {
  const { data: result }: APIData<IDepartment> = await api.patch(
    API.ORGANIZATION_DEPARTMENT(organizationId, id),
    data
  )

  return result
}

export type TSetDepartmentMembersPayload = {
  id: string
  organizationId: string
  setAccountIds: string[]
  unsetAccountIds: string[]
}
export const setDepartmentMembers = async ({
  id,
  organizationId,
  setAccountIds,
  unsetAccountIds
}: TSetDepartmentMembersPayload) => {
  const { data: result }: APIData<IDepartment> = await api.patch(
    API.ORGANIZATION_DEPARTMENT_MEMBERS(organizationId, id),
    { setAccountIds, unsetAccountIds }
  )

  return result
}

export type TGetDepartmentPayload = {
  departmentId: string
  organizationId: string
}

export const getDepartment = async ({ departmentId, organizationId }: TGetDepartmentPayload) => {
  const url = new QueryBuilder(API.ORGANIZATION_DEPARTMENT(organizationId, departmentId)).build()

  const { data }: APIData<IDepartment> = await api.get(url)

  return data
}

export type TGetOrganizationMembersPayload = {
  organizationId: string

  search?: string
  page?: number
  showBy?: number
  departmentIds?: string[]
  excludeAccountIds?: string[]
  sortBy?: EOrganizationMemberSort
  sortOrder?: ESorting
  markChatIds?: string[]
  markVoiceRoomIds?: string[]
  markDepartmentIds?: string[]
}
export const getOrganizationMembers = async ({
  organizationId,
  search,
  page,
  departmentIds,
  excludeAccountIds,
  markChatIds,
  markVoiceRoomIds,
  markDepartmentIds,
  showBy = PAGINATION_DEFAULT_SHOW_BY,
  sortBy = EOrganizationMemberSort.USER_NAME,
  sortOrder = ESorting.ASC
}: TGetOrganizationMembersPayload) => {
  const url = new QueryBuilder(API.ORGANIZATION_MEMBERS(organizationId))
    .searchQuery(search)
    .page(page)
    .showBy(showBy)
    .sort(sortOrder, sortBy)
    .excludeIds(excludeAccountIds, 'excludeAccountIds')
    .multiSearch('departmentIds', departmentIds)
    .multiSearch('markChatIds', markChatIds)
    .multiSearch('markVoiceRoomIds', markVoiceRoomIds)
    .multiSearch('markDepartmentIds', markDepartmentIds)
    .build()

  const { data }: APIResultsResponse<TOrganizationMember[]> = await api.get(url)

  return data
}

export type TDeleteChannelPayload = {
  organizationId: string
  channelId: string
}

export const deleteTextChannel = async ({ organizationId, channelId }: TDeleteChannelPayload) => {
  const response = await api.delete(API.ORGANIZATIONS_TEXT_CHANNEL(organizationId, channelId))

  return response
}

export const deleteVoiceChannel = async ({ organizationId, channelId }: TDeleteChannelPayload) => {
  const response = await api.delete(API.ORGANIZATIONS_VOICE_CHANNEL(organizationId, channelId))

  return response
}

export type TGetOrganizationDepartmentsPayload = {
  organizationId: string
  search?: string
  searchBy?: 'NAME' | 'DEPARTMENT'
  page?: number
  showBy?: number
  nonEmpty?: boolean
  excludeAccountIds?: string[]
  markChatIds?: string[]
  markVoiceRoomIds?: string[]
}

export const getOrganizationDepartments = async ({
  organizationId,
  search,
  page,
  searchBy,
  showBy,
  nonEmpty,
  excludeAccountIds,
  markChatIds,
  markVoiceRoomIds
}: TGetOrganizationDepartmentsPayload) => {
  const url = new QueryBuilder(API.ORGANIZATION_DEPARTMENTS(organizationId))
    .searchQuery(search)
    .searchBy(search ? searchBy : undefined)
    .page(page)
    .showBy(showBy)
    .custom('nonEmpty', nonEmpty)
    .excludeIds(excludeAccountIds, 'excludeAccountIds')
    .multiSearch('markChatIds', markChatIds)
    .multiSearch('markVoiceRoomIds', markVoiceRoomIds)
    .build()

  const { data }: APIResultsResponse<IDepartment[]> = await api.get(url)

  return data
}

export type TDeleteDepartmentPayload = {
  departmentId: string
  organizationId: string
}

export const deleteDepartment = async ({
  departmentId,
  organizationId
}: TDeleteDepartmentPayload) => {
  const url = new QueryBuilder(API.ORGANIZATION_DEPARTMENT(organizationId, departmentId)).build()

  await api.delete(url)
}

type TCreateChannelPayload = {
  organizationId: string
  name: string
  isPublic: boolean

  accountIds?: string[]
  departmentIds?: string[]
  channelManagerId?: string | null
}

export const createTextChannel = async ({
  organizationId,
  name,
  isPublic,
  channelManagerId,
  accountIds,
  departmentIds
}: TCreateChannelPayload) => {
  const { data }: APIData<ICreateTextChannelDTO> = await api.post(
    API.ORGANIZATIONS_TEXT_CHANNELS(organizationId),
    {
      name,
      isPublic,
      channelManagerId,
      accountIds,
      departmentIds
    }
  )

  return data
}

export const createVoiceChannel = async ({
  organizationId,
  name,
  accountIds,
  departmentIds,
  isPublic
}: Pick<
  TCreateChannelPayload,
  'organizationId' | 'accountIds' | 'departmentIds' | 'name' | 'isPublic'
>) => {
  const { data }: APIData = await api.post(API.ORGANIZATIONS_VOICE_CHANNELS(organizationId), {
    name,
    isPublic,
    accountIds,
    departmentIds
  })

  return data
}

type TEditChannelPayload = PartialBy<
  TCreateChannelPayload,
  'accountIds' | 'departmentIds' | 'channelManagerId' | 'name' | 'isPublic'
> & {
  channelId: string
}

export const editTextChannel = async ({
  organizationId,
  channelId,
  name,
  isPublic,
  channelManagerId,
  accountIds,
  departmentIds
}: TEditChannelPayload) => {
  const { data }: APIData = await api.patch(
    API.ORGANIZATIONS_TEXT_CHANNEL(organizationId, channelId),
    {
      name,
      isPublic,
      channelManagerId,
      accountIds,
      departmentIds
    }
  )

  return data
}

export const editVoiceChannel = async ({
  organizationId,
  channelId,
  name,
  accountIds,
  departmentIds,
  isPublic
}: Pick<
  TEditChannelPayload,
  'accountIds' | 'departmentIds' | 'channelId' | 'name' | 'organizationId' | 'isPublic'
>) => {
  const { data }: APIData = await api.patch(
    API.ORGANIZATIONS_VOICE_CHANNEL(organizationId, channelId),
    {
      name,
      isPublic,
      accountIds,
      departmentIds
    }
  )

  return data
}

type TGetOrganizationVoiceRoomProps = {
  organizationId: string
  channelId: string
}

export const getOrganizationVoiceRoom = async ({
  organizationId,
  channelId
}: TGetOrganizationVoiceRoomProps) => {
  const url = new QueryBuilder(API.ORGANIZATIONS_VOICE_CHANNEL(organizationId, channelId)).build()

  const { data }: APIData<IVoiceRoomDTO> = await api.get(url)

  return data
}

type TGetRoomAccountsPayload = Pick<TEditChannelPayload, 'organizationId' | 'channelId'> & {
  variant: TChannelVariant
}

export const getRoomAccounts = async ({
  organizationId,
  channelId,
  variant
}: TGetRoomAccountsPayload) => {
  const urlBuilder = new QueryBuilder(API.ORGANIZATION_MEMBERS(organizationId))

  if (variant === 'voice') {
    urlBuilder.multiSearch('voiceRoomIds', [channelId])
  }

  if (variant === 'chat') {
    urlBuilder.multiSearch('chatIds', [channelId])
  }

  const url = urlBuilder.build()

  const { data }: APIResultsResponse<TOrganizationMember[]> = await api.get(url)

  return data
}

type TGetDepartmentsGroupedMembersPayload = {
  organizationId: string
  page: number

  searchBy?: string
  search?: string
  channelId?: string
}

export const getDepartmentsGroupedMembers = async ({
  organizationId,
  page,
  search,
  searchBy,
  channelId
}: TGetDepartmentsGroupedMembersPayload) => {
  const url = new QueryBuilder(API.ORGANIZATION_DEPARTMENTS_MEMBERS_GROUPED(organizationId))
    .custom('withNotDepartmentsCategory', true)
    .custom('chatIds', channelId)
    .page(page)
    .showBy(PAGINATION_DEFAULT_SHOW_BY)
    .searchQuery(search)
    .searchBy(searchBy)
    .build()

  const {
    data
  }: APIData<{
    results: IDepartmentGroupedMembers[]
    total: number
    totalUniq: number
  }> = await api.get(url)

  return data
}

export const getOrganizations = async () => {
  const url = new QueryBuilder(API.ORGANIZATIONS).build()

  const { data }: APIResultsResponse<TOrganization[]> = await api.get(url)

  return data
}

type TGetOrganizationTokenPayload = {
  organizationId: string
  token: string
}

type TJoinToOrganizationChannelPayload = TGetOrganizationTokenPayload

export const getOrganizationJoinToChannelTokenInfo = async ({
  organizationId,
  token
}: TGetOrganizationTokenPayload) => {
  const { data }: APIData<IOrganizationJoinToChannelLinkDTO> = await api.get(
    API.ORGANIZATION_CHANNEL_JOIN(organizationId, token)
  )

  return data
}

export const joinToOrganizationChannel = async ({
  organizationId,
  token
}: TJoinToOrganizationChannelPayload) => {
  const { data }: APIData<IOrganizationJoinToChannelLinkDTO> = await api.post(
    API.ORGANIZATION_CHANNEL_JOIN(organizationId, token)
  )

  return data
}

export type TLeaverOrganizationChannelPayload = {
  id: string
  channelId: string
}

export const leaveOrganizationChannel = async ({
  id,
  channelId
}: TLeaverOrganizationChannelPayload) => api.delete(API.ORGANIZATION_LEAVE_CHANNEL(id, channelId))

export type TLeaveOrganizationVoiceRoomPayload = {
  roomId: string
  organizationId: string
}

export const leaveOrganizationVoiceRoom = async ({
  roomId,
  organizationId
}: TLeaveOrganizationVoiceRoomPayload) => {
  await api.delete(API.ORGANIZATIONS_LEAVE_VOICE_CHANNEL(organizationId, roomId))
}
