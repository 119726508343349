import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import {
  createProcessingSelector,
  restoreFileRequest,
  RESTORE_FILE,
  TRestoreFileRequestPayload,
  useAppDispatch,
  useAppSelector
} from 'store'

import styles from './FileRestoreDialog.module.scss'

type TFileRestoreDialogProps = {
  files: Pick<TRestoreFileRequestPayload, 'extension' | 'fileId' | 'fileName'>[]
}

const processingSelector = createProcessingSelector([RESTORE_FILE])

export const FileRestoreDialog = ({ files }: TFileRestoreDialogProps) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector)

  const { t } = useTranslation()

  const handleFileRestore = () => {
    files.forEach(({ fileId, fileName, extension }) =>
      dispatch(restoreFileRequest({ fileId, fileName, extension }))
    )
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootText}>{t('modal.restoreFileConfirm.content')}</p>
      <Button className={styles.rootButton} onClick={handleFileRestore} disabled={processing}>
        {t('modal.restoreFileConfirm.submitButton')}
      </Button>
    </div>
  )
}
