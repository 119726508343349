import { ReactComponent as FeatureUnAvailableIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as FeatureAvailableIcon } from 'assets/icons/Check.svg'

import { TFeatureValue } from '../../useSubscriptionPlans'

import styles from './FeatureValue.module.scss'

export type TFeatureValueProps = {
  value: TFeatureValue
}

export const FeatureValue = ({ value }: TFeatureValueProps) => {
  if (typeof value !== 'boolean') {
    return <>{value}</>
  }

  return value ? (
    <FeatureAvailableIcon className={styles.available} />
  ) : (
    <FeatureUnAvailableIcon className={styles.unavailable} />
  )
}
