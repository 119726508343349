import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components/common'
import { hideModalAction, useAppDispatch } from 'store'
import { removeCommunityMember as removeCommunityMember22 } from 'api/communities'

type TRemoveCommunityMemberConfirmProps = {
  communityId: string
  memberId: string

  onSuccess?: () => void
}

export const REMOVE_COMMUNITY_MEMBER_QUERY_KEY = 'remove_community_member'

export const RemoveCommunityMemberConfirm = ({
  communityId,
  memberId,
  onSuccess
}: TRemoveCommunityMemberConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: [REMOVE_COMMUNITY_MEMBER_QUERY_KEY],
    mutationFn: removeCommunityMember22,
    onSuccess: () => {
      onSuccess && onSuccess()
      dispatch(hideModalAction())
    }
  })

  const handleClick = () => {
    mutate({ communityId, memberId })
  }

  return (
    <ConfirmationModal
      content={t('modal.removeCommunityMemberConfirm.content')}
      controls={
        <Button loading={isLoading} disabled={isLoading} onClick={handleClick}>
          {t('modal.removeCommunityMemberConfirm.submitButton')}
        </Button>
      }
    />
  )
}
