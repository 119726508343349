import { useCallback, useMemo, useState } from 'react'

import { IFilesEntity } from 'interfaces'
import { TFileMention, createShowcaseFileName } from 'utils/mentions'

import { TOnSearchChangePayload } from './Comments/Field/CommentFieldWrapper'

export type TUseFileSuggestionsProps = {
  files: IFilesEntity[]
} & TFileMention['showcaseOptions']

export type TUseFileSuggestionsResponse = {
  onSearchChange: (event: TOnSearchChangePayload) => void
  fileSuggestions: TFileMention[]
  fileMentions: TFileMention[]
}

export const useFileSuggestions = ({
  files,
  showcaseId,
  ownerId
}: TUseFileSuggestionsProps): TUseFileSuggestionsResponse => {
  const [suggestionSearch, setSuggestionSearch] = useState('')
  const [enableSuggestions, setEnableSuggestions] = useState<boolean>(false)

  const formattedFiles = useMemo(
    () =>
      files.map<TFileMention>((file, index) => ({
        ...file,
        showcaseOptions: { showcaseId, ownerId },
        name: createShowcaseFileName(index + 1, false),
        trigger: '/',
        variant: 'file'
      })),
    [files, ownerId, showcaseId]
  )

  const filteredFileSuggestions = useMemo(() => {
    if (!enableSuggestions) {
      return []
    }

    try {
      return formattedFiles?.filter(({ name }) => new RegExp(suggestionSearch, 'i').test(name))
    } catch (e) {
      return formattedFiles
    }
  }, [enableSuggestions, formattedFiles, suggestionSearch])

  const onSearchChange = useCallback(({ trigger, value }: TOnSearchChangePayload) => {
    if (trigger !== '/') {
      setEnableSuggestions(false)
      return
    }

    setSuggestionSearch(value)
    setEnableSuggestions(true)
  }, [])

  return {
    onSearchChange,
    fileSuggestions: filteredFileSuggestions ?? [],
    fileMentions: formattedFiles
  }
}
