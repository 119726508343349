import { actionCreator, TError } from 'store'

import {
  TGetAdvertsSuccessPayload,
  TGetMyAdvertRequestsRequestPayload,
  TGetMyAdvertRequestsSuccessPayload,
  TGetMyAdvertsSuccessPayload,
  TRedirectToUserProfileActionPayload,
  TRemoveRequestPayload,
  TRemoveAdvertPayload,
  TSetFiltersPayload,
  TBecomeContactsPayload,
  TWithdrawRequestPayload,
  TDeactivateAdvertPayload,
  TRepostAdvertPayload,
  TGetMyRequestsSuccessPayload,
  TSetSearchPayload,
  TCreateConnectionRequestRequestPayload,
  TGetMyAdvertsRequestPayload,
  TGetMyRequestsRequestPayload,
  TGetAdvertsRequestPayload,
  TSetAdvertsDataPayload,
  TCreateAdvertRequestPayload
} from './adverts.types'
import * as actionTypes from './adverts.actionTypes'

export const getAdvertsRequest = (payload?: TGetAdvertsRequestPayload) =>
  actionCreator(actionTypes.GET_ADVERTS_REQUEST, payload)
export const getAdvertsSuccess = (payload: TGetAdvertsSuccessPayload) =>
  actionCreator(actionTypes.GET_ADVERTS_SUCCESS, payload)
export const getAdvertsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ADVERTS_ERROR, payload)

export const getMyAdvertRequestsRequest = (payload: TGetMyAdvertRequestsRequestPayload) =>
  actionCreator(actionTypes.GET_MY_ADVERT_REQUESTS_REQUEST, payload)
export const getMyAdvertRequestsSuccess = (payload: TGetMyAdvertRequestsSuccessPayload) =>
  actionCreator(actionTypes.GET_MY_ADVERT_REQUESTS_SUCCESS, payload)
export const getMyAdvertRequestsError = (payload: TError) =>
  actionCreator(actionTypes.GET_MY_ADVERT_REQUESTS_ERROR, payload)

export const getMyRequestsRequest = (payload?: TGetMyRequestsRequestPayload) =>
  actionCreator(actionTypes.GET_MY_REQUESTS_REQUEST, payload)
export const getMyRequestsSuccess = (payload: TGetMyRequestsSuccessPayload) =>
  actionCreator(actionTypes.GET_MY_REQUESTS_SUCCESS, payload)
export const getMyRequestsError = (payload: TError) =>
  actionCreator(actionTypes.GET_MY_REQUESTS_ERROR, payload)

export const getMyAdvertsRequest = (payload?: TGetMyAdvertsRequestPayload) =>
  actionCreator(actionTypes.GET_MY_ADVERTS_REQUEST, payload)
export const getMyAdvertsSuccess = (payload: TGetMyAdvertsSuccessPayload) =>
  actionCreator(actionTypes.GET_MY_ADVERTS_SUCCESS, payload)
export const getMyAdvertsError = (payload: TError) =>
  actionCreator(actionTypes.GET_MY_ADVERTS_ERROR, payload)

export const redirectToUserProfileAction = (payload: TRedirectToUserProfileActionPayload) =>
  actionCreator(actionTypes.REDIRECT_TO_USER_PROFILE_ACTION, payload)

export const resetMyAdvertRequests = () => actionCreator(actionTypes.RESET_MY_ADVERT_REQUESTS)
export const resetAdverts = () => actionCreator(actionTypes.RESET_ADVERTS)
export const resetMyAdverts = () => actionCreator(actionTypes.RESET_MY_ADVERTS)
export const resetMyRequests = () => actionCreator(actionTypes.RESET_MY_REQUESTS)

export const createAdvertRequest = (payload: TCreateAdvertRequestPayload) =>
  actionCreator(actionTypes.CREATE_ADVERT_REQUEST, payload)
export const createAdvertSuccess = () => actionCreator(actionTypes.CREATE_ADVERT_SUCCESS)
export const createAdvertError = (payload: TError) =>
  actionCreator(actionTypes.CREATE_ADVERT_ERROR, payload)

export const removeRequestRequest = (payload: TRemoveRequestPayload) =>
  actionCreator(actionTypes.REMOVE_REQUEST_REQUEST, payload)
export const removeRequestSuccess = () => actionCreator(actionTypes.REMOVE_REQUEST_SUCCESS)
export const removeRequestError = (payload: TError) =>
  actionCreator(actionTypes.REMOVE_REQUEST_ERROR, payload)

export const ignoreRequestRequest = (payload: TRemoveRequestPayload) =>
  actionCreator(actionTypes.IGNORE_REQUEST_REQUEST, payload)
export const ignoreRequestSuccess = () => actionCreator(actionTypes.IGNORE_REQUEST_SUCCESS)
export const ignoreRequestError = (payload: TError) =>
  actionCreator(actionTypes.IGNORE_REQUEST_ERROR, payload)

export const becomeContactsRequest = (payload: TBecomeContactsPayload) =>
  actionCreator(actionTypes.BECOME_CONTACT_REQUEST, payload)
export const becomeContactsSuccess = () => actionCreator(actionTypes.BECOME_CONTACT_SUCCESS)
export const becomeContactsError = (payload: TError) =>
  actionCreator(actionTypes.BECOME_CONTACT_ERROR, payload)

export const withdrawRequestRequest = (payload: TWithdrawRequestPayload) =>
  actionCreator(actionTypes.WITHDRAW_REQUEST_REQUEST, payload)
export const withdrawRequestSuccess = () => actionCreator(actionTypes.WITHDRAW_REQUEST_SUCCESS)
export const withdrawRequestError = (payload: TError) =>
  actionCreator(actionTypes.WITHDRAW_REQUEST_ERROR, payload)

// ------------------ FILTERS --------------------
export const removeAdvertRequest = (payload: TRemoveAdvertPayload) =>
  actionCreator(actionTypes.REMOVE_ADVERT_REQUEST, payload)
export const removeAdvertSuccess = () => actionCreator(actionTypes.REMOVE_ADVERT_SUCCESS)
export const removeAdvertError = (payload: TError) =>
  actionCreator(actionTypes.REMOVE_ADVERT_ERROR, payload)

export const repostAdvertRequest = (payload: TRepostAdvertPayload) =>
  actionCreator(actionTypes.REPOST_ADVERT_REQUEST, payload)
export const repostAdvertSuccess = () => actionCreator(actionTypes.REPOST_ADVERT_SUCCESS)
export const repostAdvertError = (payload: TError) =>
  actionCreator(actionTypes.REPOST_ADVERT_ERROR, payload)

export const deactivateAdvertRequest = (payload: TDeactivateAdvertPayload) =>
  actionCreator(actionTypes.DEACTIVATE_ADVERT_REQUEST, payload)
export const deactivateAdvertSuccess = () => actionCreator(actionTypes.DEACTIVATE_ADVERT_SUCCESS)
export const deactivateAdvertError = (payload: TError) =>
  actionCreator(actionTypes.DEACTIVATE_ADVERT_ERROR, payload)

export const setFilters = (payload: TSetFiltersPayload) =>
  actionCreator(actionTypes.SET_FILTERS, payload)
export const resetFilters = () => actionCreator(actionTypes.RESET_FILTERS)

export const setSearch = (payload: TSetSearchPayload) =>
  actionCreator(actionTypes.SET_SEARCH, payload)

export const createConnectionRequestRequest = (payload: TCreateConnectionRequestRequestPayload) =>
  actionCreator(actionTypes.CREATE_CONNECTION_REQUEST_REQUEST, payload)
export const createConnectionRequestSuccess = () =>
  actionCreator(actionTypes.CREATE_CONNECTION_REQUEST_SUCCESS)
export const createConnectionRequestError = (payload: TError) =>
  actionCreator(actionTypes.CREATE_CONNECTION_REQUEST_ERROR, payload)

export const setAdvertsData = (payload: TSetAdvertsDataPayload) =>
  actionCreator(actionTypes.SET_ADVERTS_DATA, payload)
