import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { generateRandomArray } from 'utils'
import {
  createLoadingSelector,
  getCasesLinksRequest,
  GET_CASES_LINKS,
  resetCaseLinksAction,
  resetCasesLinksFilter,
  setCasesLinksFilterAction,
  setCasesLinksSearchAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { CaseLinkListWrapper, TCaseLinkListWrapperProps, StepsFooterActions } from 'App/components'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'

import { Description } from '../Description'

import styles from './FirstStep.module.scss'

export type TFirstStepProps = Pick<TCaseLinkListWrapperProps, 'caseId'> & {
  onNext: Exclude<TCaseLinkListWrapperProps['onItemClick'], undefined>
  onBack: () => void
}

const loadingSelector = createLoadingSelector([GET_CASES_LINKS])
const DEFAULT_CASE_LENGTH = 3

export const FirstStep = ({ caseId, onNext, onBack }: TFirstStepProps) => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) =>
    loading ? generateRandomArray(DEFAULT_CASE_LENGTH) : state.cases.caseLinks.ids
  )
  const { classifications, roles, statuses, search, searchBy } = useAppSelector(
    (state) => state.cases.caseLinks.filters
  )

  const { t } = useTranslation()

  const resetFilter = useCallback(() => dispatch(resetCasesLinksFilter()), [dispatch])
  const setSearch = useCallback<typeof setCasesLinksSearchAction>(
    (payload) => dispatch(setCasesLinksSearchAction(payload)),
    [dispatch]
  )
  const setFilter = useCallback<typeof setCasesLinksFilterAction>(
    (payload) => dispatch(setCasesLinksFilterAction(payload)),
    [dispatch]
  )

  useEffect(() => {
    dispatch(getCasesLinksRequest({ caseId }))

    // Filters are required to trigger list update
  }, [classifications.length, roles.length, statuses.length, caseId, search, searchBy, dispatch])

  useEffect(
    () => () => {
      dispatch(resetCaseLinksAction())
    },
    [dispatch]
  )

  return (
    <>
      <CaseLinkListWrapper
        isView={true}
        shouldShowFilesCount={true}
        caseId={caseId}
        search={search}
        classifications={classifications}
        roles={roles}
        statuses={statuses}
        ids={ids}
        emptyIcon={<CaseIcon />}
        emptyText={t('modal.attachFile.placeholder_linkedCases')}
        loading={loading}
        addonBefore={<Description>{t('modal.attachFile.subtitle')}</Description>}
        setSearch={setSearch}
        setFilter={setFilter}
        resetFilter={resetFilter}
        onItemClick={onNext}
      />

      <StepsFooterActions className={styles.footerActions} onClickBack={onBack} />
    </>
  )
}
