import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils/validation'
import { Button, ErrorModal, TextField } from 'App/components'
import {
  TCaseCloudRenameFileDialogContainerProps,
  TFileRenameDialogContainerProps
} from 'App/containers'

import styles from './FileRenameDialog.module.scss'

type FileRenameValues = {
  fileName?: string
}

export type TFileRenameDialogContainerOwnProps = {
  fileId?: string
  caseId?: string
  defaultFileName?: string
  onSuccess?: (fileName: string) => void
}

type TFileRenameDialogProps = TFileRenameDialogContainerOwnProps &
  (TFileRenameDialogContainerProps | TCaseCloudRenameFileDialogContainerProps)

const FILE_NAME_MAX_LENGTH = 240

export const FileRenameDialog = ({
  defaultFileName,
  fileId,
  processing,
  error,
  action,
  caseId,
  removeErrorNotification,
  renameFile,
  onSuccess
}: TFileRenameDialogProps) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification(action)
    },
    [removeErrorNotification, action]
  )

  const onSubmit = ({ fileName }: FileRenameValues) => {
    const payload: any = {
      fileName,
      fileId,
      onSuccess
    }

    if (caseId) {
      payload.caseId = caseId
    }

    renameFile(payload)
  }

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="fileName"
              validate={validation.composeValidators(
                validation.required(),
                validation.maxLength(FILE_NAME_MAX_LENGTH),
                validation.fileEscapeSymbols()
              )}
              initialValue={defaultFileName}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  autoFocus={true}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                  valueLengthMax={FILE_NAME_MAX_LENGTH}
                  InputLabelProps={{
                    shrink: false
                  }}
                />
              )}
            </Field>
            <ErrorModal error={error} />
            <Button
              type="submit"
              className={cls(styles.button, form.getState().invalid && styles.buttonError)}
              disabled={form.getState().invalid}
              loading={processing}
            >
              {t('modal.fileRename.submitButton')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}
