import uniqBy from 'lodash/uniqBy'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { AccountsSelect, TAccountsSelectProps } from 'App/components/common/AccountsSelect'
import { useSelectAccounts } from 'App/hooks/useSelectAccounts'
import { getContacts } from 'api/contacts'
import {
  SEND_CREATE_CALL,
  accountIdSelector,
  useAppSelector,
  createProcessingSelector
} from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

export type TChatStartGroupCallDialogProps = Pick<
  TAccountsSelectProps,
  'submitLabel' | 'emptyContentText'
> & {
  caseId: string
  onSubmit: (userIds: string[]) => void
}

export const ChatStartGroupCallDialog = ({
  caseId,
  submitLabel,
  emptyContentText,
  onSubmit
}: TChatStartGroupCallDialogProps) => {
  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const processing = useAppSelector(createProcessingSelector([SEND_CREATE_CALL]))
  const answerersMap = useAppSelector((state) => state.calls.answer.createCall?.answerersMap)

  const accounts = useMemo(
    () =>
      answerersMap
        ? uniqBy(Object.values(answerersMap), 'accountId')
            .map(({ account }) => account)
            .filter(({ id }) => id !== accountId)
        : [],
    [accountId, answerersMap]
  )

  const onSubmitHandler = useCallback(
    (value: TAccountsSelectOptionData[]) => {
      const userIds = value.map(({ id }) => id)

      onSubmit(userIds)
    },
    [onSubmit]
  )

  const {
    state: { page, search, selectedAccounts },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({ onSubmit: onSubmitHandler, accounts })

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ['group_call_contacts', page, search, selectedAccounts],
    queryFn: () => getContacts({ accountId, caseIds: [caseId], page }),
    keepPreviousData: true
  })

  return (
    <AccountsSelect
      items={data?.results ?? []}
      selectedItems={selectedAccounts}
      search={search}
      page={page}
      total={data?.total}
      autoOnline={true}
      loading={isLoading}
      submitDisabled={isFetching || processing}
      emptyContentText={emptyContentText}
      submitLabel={submitLabel}
      selectTitle={t('modal.addGroupCallMember.selectTitle')}
      emptyListText={t('modal.addGroupCallMember.placeholder')}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
    />
  )
}
