import { useParams } from 'react-router-dom'

import { useAppSelector } from 'store'
import { OrganizationView } from 'App/components'

export type OrganizationContentProps = {
  loading: boolean
}

export const OrganizationContent = ({ loading }: OrganizationContentProps) => {
  const { id } = useParams<{ id?: string }>()

  const organization = useAppSelector((state) =>
    id ? state.organizations.data.list[id] : undefined
  )

  if (!organization) {
    return null
  }

  return <OrganizationView loading={loading} showAvatar={false} organization={organization} />
}
