import { useTranslation } from 'react-i18next'

import { CloudWidget, EModalComponents } from 'App/containers'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { Button } from 'App/components'
import { ReactComponent as CloudTrashBinIcon } from 'assets/icons/CloudTrashBin.svg'

import styles from './TrashBinWidget.module.scss'

export const TrashBinWidget = () => {
  const dispatch = useAppDispatch()

  const { trashBinCapacityAvailable = 0, trashBinCapacityOccupied = 0 } = useAppSelector(
    (state) => state.subscriptions.capacity || {}
  )

  const { t } = useTranslation()

  const handleClearTrashBin = () =>
    dispatch(
      showModalAction({
        modalTitle: t('modal.deleteFilePermanentlyConfirm.title_clear'),
        modalType: EModalComponents.DELETE_FILE_FROM_MEDCLOUD_CONFIRM
      })
    )

  return (
    <CloudWidget
      variant="bin"
      occupied={trashBinCapacityOccupied}
      available={trashBinCapacityAvailable}
      title={t('files.trashBinWidget.title')}
      icon={<CloudTrashBinIcon />}
      footerAdornment={
        !!trashBinCapacityOccupied && (
          <Button className={styles.button} variant="text" onClick={handleClearTrashBin}>
            {t('files.trashBinWidget.emptyTrashBinButton')}
          </Button>
        )
      }
    />
  )
}
