import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components/common'

type TDeleteImageDialogProps = {
  onClick: () => void
  content?: ReactNode
  buttonContent?: ReactNode
}

export const DeleteImageDialog = ({ content, onClick }: TDeleteImageDialogProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={content ?? t('modal.removeImageConfirm.content')}
      controls={<Button onClick={onClick}>{t('modal.removeImageConfirm.submitButton')}</Button>}
    />
  )
}
