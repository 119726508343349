import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { createSlidesEntity } from 'store'
import { CaseCloudFilesItem, TCaseCloudFilesItemBaseProps } from 'App/components'

export type TCaseCloudFilesItemContainer = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, { id }: TCaseCloudFilesItemBaseProps) => {
  const { ids, list } = state.caseCloud.cloudFiles
  const { fileName, extension, thumbnailUrl, mimeType } = list[id] ?? {}

  return {
    id,
    fileName,
    extension,
    thumbnailUrl,
    videoMetadata: state.videoStreaming.list[id]?.videoMetadata,
    mimeType,
    slidesEntity: createSlidesEntity(ids, list)
  }
}

export const CaseCloudFilesItemContainer = connect(mapStateToProps)(CaseCloudFilesItem)
