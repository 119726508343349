import { useCallback, useLayoutEffect, useRef } from 'react'

type THandler<T extends unknown[]> = (...args: T) => void

export const useEvent = <T extends unknown[]>(handler: THandler<T>) => {
  const handlerRef = useRef<null | THandler<T>>(null)

  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback<THandler<T>>((...args) => {
    const fn = handlerRef.current

    if (!fn) {
      return
    }

    return fn(...args)
  }, [])
}
