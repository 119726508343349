import { useSelector } from 'react-redux'
import { AccountTypeIdEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { TAccount } from 'store'

import styles from './ChatMessageAuthor.module.scss'

export type TChatMessageAuthor = {
  sender: TAccount | null
  originalSender?: TAccount | null
}

export const useBusinessAccountMessageAuthor = (
  sender?: TAccount | null,
  originalSender?: TAccount | null
) => {
  const accountData = useSelector((state: State) => state.global.accountData)

  const isBusinessAccount = accountData?.type.id === AccountTypeIdEnum.BUSINESS
  const isSenderBusinessAccount = sender?.type.id === AccountTypeIdEnum.BUSINESS

  const isOriginalSender = originalSender
    ? accountData?.professionalAccount?.id === originalSender.id
    : true
  const isSender = sender?.id === accountData?.id

  if (!isBusinessAccount || isOriginalSender || !isSender || !isSenderBusinessAccount) {
    return null
  }

  return `${originalSender?.displayUserName}`
}

export const ChatMessageAuthor = ({ originalSender, sender }: TChatMessageAuthor) => {
  const authorName = useBusinessAccountMessageAuthor(sender, originalSender)

  if (!authorName) {
    return null
  }

  return <span className={styles.root}>{authorName}</span>
}
