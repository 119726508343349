import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  getVoiceRoomsRequest,
  hideModalAction,
  onChatRoomNotFoundError,
  useAppDispatch
} from 'store'
import { deleteVoiceChannel } from 'api/organizations'
import { Button, ConfirmationModal } from 'App/components/common'

type TOrganizationDeleteVoiceChannelConfirmProps = {
  channelId: string
  organizationId: string
}

export const OrganizationDeleteVoiceChannelConfirm = ({
  organizationId,
  channelId
}: TOrganizationDeleteVoiceChannelConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = () => {
    dispatch(hideModalAction())
    dispatch(getVoiceRoomsRequest({ organizationId }))
  }

  const { mutate, error, isLoading } = useMutation({
    mutationKey: ['organization_delete_voice_channel', organizationId, channelId],
    mutationFn: deleteVoiceChannel,
    onSuccess,
    onError: (e) => onChatRoomNotFoundError(e, onSuccess)
  })

  const onClick = useCallback(() => {
    mutate({ organizationId, channelId })
  }, [organizationId, channelId, mutate])

  return (
    <ConfirmationModal
      error={error}
      content={t('modal.deleteChannelConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteChannelConfirm.submitButton')}
        </Button>
      }
    />
  )
}
