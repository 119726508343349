import { useCallback, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { TIds, TAccountData } from 'store'
import { AccountsList, LongDash } from 'App/components'

import styles from './SelectedUsers.module.scss'

export type TSelectedUsersProps = {
  selectedAccounts: TAccountData[]
  setSelectedIds: Dispatch<SetStateAction<TIds>>
}

export const SelectedUsers = ({ selectedAccounts, setSelectedIds }: TSelectedUsersProps) => {
  const { t } = useTranslation()

  const handleRemove = useCallback(
    (id: string) => setSelectedIds((prev) => prev.filter((item) => item !== id)),
    [setSelectedIds]
  )

  const showAccounts = !!selectedAccounts.length

  return (
    <div className={styles.root}>
      {t('payments.transferMDT.transferToLabel')}
      {showAccounts ? (
        <AccountsList
          avatarSize={EAvatarSize.XS}
          accounts={selectedAccounts}
          getTitle={(number) =>
            t(`payments.transferMDT.selectedMembersPopover.title`, {
              number
            })
          }
          onRemove={handleRemove}
        />
      ) : (
        <LongDash />
      )}
    </div>
  )
}
