import { useMemo } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { formatLongDate } from 'utils'
import { TActiveSubscriptionProps } from 'App/components'

import styles from './ExpirationDate.module.scss'

export type TExpirationDateProps = Pick<TActiveSubscriptionProps, 'endsOn' | 'autoRenewal'>

export const ExpirationDate = ({ endsOn, autoRenewal }: TExpirationDateProps) => {
  const { t } = useTranslation()

  const expirationDate = useMemo(() => formatLongDate(endsOn), [endsOn])

  return (
    <div className={cls(styles.root, autoRenewal && styles.autoRenewal)}>
      {autoRenewal
        ? t('subscriptions.activeSubscription.autoRenewLabel', { date: expirationDate })
        : t('subscriptions.activeSubscription.endsOnLabel', { date: expirationDate })}
    </div>
  )
}
