import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { TMenuItemProps } from 'App/components/common'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'

import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

type TMenuItemsProps = {
  userId: string
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = (): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { setRemovedMemberIds, channelManagerId, setSelectedMembers } = useChatCreateGroupContext()

  const handleRemoveMember = useCallback(
    (id: string) => {
      setSelectedMembers((prev) => prev.filter((item) => item.id !== id))
      setRemovedMemberIds((prev) => [...prev, id])
    },
    [setRemovedMemberIds, setSelectedMembers]
  )

  const menuItems = useMemo(
    () => ({
      removeMember: ({ userId }: TMenuItemsProps) => ({
        content: t('chat.createGroupChat.members.kebabMenu.remove'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.removeGroupChatMemberConfirm.title'),
              modalType: EModalComponents.REMOVE_GROUP_CHAT_MEMBER_CONFIRM,
              modalProps: {
                onClick: () => {
                  handleRemoveMember(userId)
                  dispatch(hideModalAction())
                }
              }
            })
          )
        }
      })
    }),
    [dispatch, handleRemoveMember, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) =>
      props.userId === channelManagerId ? [] : [menuItems.removeMember(props)],
    [channelManagerId, menuItems]
  )

  return {
    getMenuItems
  }
}
