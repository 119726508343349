import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { messagePushUp } from 'store'
import { MessagePushUp } from 'App/components'

export type TMessagePushUpContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  show: state.notifications.messagePushUp.show,
  chatId: state.notifications.messagePushUp.chatId,
  caseId: state.notifications.messagePushUp.caseId,
  caseStatus: state.notifications.messagePushUp.caseStatus,
  isCaseOwner: state.notifications.messagePushUp.isCaseOwner
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hidePushUp: messagePushUp
    },
    dispatch
  )

export const MessagePushUpContainer = connect(mapStateToProps, mapDispatchToProps)(MessagePushUp)
