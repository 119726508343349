import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { FileHistoryGroupedItem } from 'App/components'

export type TFileHistoryGroupedItemContainerProps = ReturnType<typeof mapStateToProps> &
  TFileHistoryGroupedItemContainerOwnProps & {
    displayInline?: boolean
  }

export type TFileHistoryGroupedItemContainerOwnProps = {
  dateId: string
}

const mapStateToProps = (state: State, ownProps: TFileHistoryGroupedItemContainerOwnProps) => ({
  ids: state.file.fileHistory.groupedList[ownProps.dateId].ids
})

export const FileHistoryGroupedItemContainer = connect(mapStateToProps)(FileHistoryGroupedItem)
