import {
  actionCreator,
  TShowExtendableCardActionPayload,
  TCollapseExtendableCardActionPayload,
  TMoveExtendableCardActionPayload,
  EXPAND_EXTENDABLE_CARD,
  COLLAPSE_EXTENDABLE_CARD,
  HIDE_EXTENDABLE_CARD,
  MOVE_EXTENDABLE_CARD,
  SHOW_EXTENDABLE_CARD,
  TExpandExtendableCardActionPayload
} from 'store'

export const showExtendableCardAction = (payload: TShowExtendableCardActionPayload) =>
  actionCreator(SHOW_EXTENDABLE_CARD, payload)
export const hideExtendableCardAction = () => actionCreator(HIDE_EXTENDABLE_CARD)

export const expandExtendableCardAction = (payload: TExpandExtendableCardActionPayload) =>
  actionCreator(EXPAND_EXTENDABLE_CARD, payload)
export const collapseExtendableCardAction = (payload?: TCollapseExtendableCardActionPayload) =>
  actionCreator(COLLAPSE_EXTENDABLE_CARD, payload)

export const moveExtendableCardAction = (payload: TMoveExtendableCardActionPayload) =>
  actionCreator(MOVE_EXTENDABLE_CARD, payload)
