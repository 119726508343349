import { TContactsData } from './contacts.types'

export const formatContactsData = (data: TContactsData[], accountId: string) =>
  data.map((item) => {
    if (item.toId !== accountId) {
      return item
    }

    const to = { ...item.to }

    return {
      ...item,
      to: { ...item.from },
      from: to
    }
  })
