import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components/common'
import { getChatRoomsRequest, hideModalAction, useAppDispatch } from 'store'
import { communityLeaveChannel } from 'api/communities'

type TCommunityLeaveChannelConfirmProps = {
  communityId: string
  channelId: string
}

const COMMUNITY_LEAVE_CHANNEL_QUERY_KEY = 'community_leave_channel'

export const CommunityLeaveChannelConfirm = ({
  communityId,
  channelId
}: TCommunityLeaveChannelConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: [COMMUNITY_LEAVE_CHANNEL_QUERY_KEY],
    mutationFn: communityLeaveChannel,
    onSuccess: () => {
      dispatch(
        getChatRoomsRequest({
          communityId,
          chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS]
        })
      )
      dispatch(hideModalAction())
    }
  })

  const handleClick = () => {
    mutate({
      communityId,
      channelId
    })
  }

  return (
    <ConfirmationModal
      content={t('modal.leaveChannelConfirm.content')}
      controls={
        <Button loading={isLoading} disabled={isLoading} onClick={handleClick}>
          {t('modal.leaveChannelConfirm.submitButton')}
        </Button>
      }
    />
  )
}
