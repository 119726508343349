import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button, TFileViewerProps } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'

import { useDownload } from '../hooks'

import styles from './Placeholder.module.scss'

export type TPlaceholderProps = Pick<TFileViewerProps, 'id' | 'source' | 'originalEntityId'>

export const Placeholder = ({ id, source, originalEntityId }: TPlaceholderProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { onDownload, processing } = useDownload({ id, source, originalEntityId })

  const { t } = useTranslation()

  return (
    <Col className={styles.root}>
      <p className={styles.title}>{t('common.card.filePlaceholder.title')}</p>
      <p className={styles.text}>{t('common.card.filePlaceholder.content')}</p>

      <Button
        className={styles.button}
        loading={processing}
        variant={isMobile ? 'text' : 'primary'}
        onClick={onDownload}
        icon={<DownloadIcon />}
      >
        {t('common.card.filePlaceholder.downloadButton')}
      </Button>
    </Col>
  )
}
