import { CaseClassificationNameEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IconLabel, TIconLabelProps } from 'App/components'
import { getMapComponent } from 'utils'
import { ReactComponent as AccountSupervisorIcon } from 'assets/icons/AccountSupervisor.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'

export type TCaseTypeProps = Partial<Omit<TIconLabelProps, 'icon' | 'classes'>> & {
  type: CaseTypesEnum | null
  classification: CaseClassificationNameEnum
}

export const CASE_TYPE_ICON = new Map<CaseTypesEnum | null, () => JSX.Element>()
  .set(CaseTypesEnum.A2O, () => <AccountSupervisorIcon />)
  .set(CaseTypesEnum.A2A, () => <AccountGroupIcon />)

export const CaseType = ({ type, classification, label, tooltip, withTooltip }: TCaseTypeProps) => {
  const { t } = useTranslation()

  return classification === CaseClassificationNameEnum.PROFESSIONAL && type ? (
    <IconLabel
      icon={getMapComponent(CASE_TYPE_ICON, type)}
      label={label || t(`enum.caseTypesEnum.${type}`)}
      tooltip={tooltip}
      withTooltip={withTooltip}
    />
  ) : null
}
