import { EAvatarSize } from 'enums'
import { TGetMapComponentProps } from 'App/containers/Meetings'
import { AvatarTeam } from 'App/components/common'

import { Header, CallInfo, Button, Wrapper } from '../common'

type TCaseGroupCallProps = Omit<TGetMapComponentProps, 'meeting' | 'answerersMap' | 'accountId'>

export const CaseGroupCall = ({
  meetingCase,
  ableToSwitch,
  durationMs,
  isOnCurrentCall,
  isMyActiveCall,
  onMeetingActionClick
}: TCaseGroupCallProps) => {
  if (!meetingCase) {
    return null
  }

  return (
    <Wrapper
      avatar={<AvatarTeam size={EAvatarSize.MD} />}
      content={
        <>
          <Header title={meetingCase.title} />
          <CallInfo
            isOnCurrentCall={isOnCurrentCall}
            ableToSwitch={ableToSwitch}
            durationMs={durationMs}
            isMyActiveCall={isMyActiveCall}
          />
        </>
      }
      action={
        <Button
          isOnCurrentCall={isOnCurrentCall}
          ableToSwitch={ableToSwitch}
          onClick={onMeetingActionClick}
        />
      }
    />
  )
}
