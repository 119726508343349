import { useTranslation } from 'react-i18next'

import { ReactComponent as ErrorRhombusIcon } from 'assets/icons/ErrorRhombus.svg'

import { WarningPage } from '../WarningPage'

import styles from './FallbackUI.module.scss'

export const FallbackUI = () => {
  const { t } = useTranslation()

  return (
    <WarningPage
      image={<ErrorRhombusIcon className={styles.icon} />}
      title={t('common.errorFallback.title')}
      description={t('common.errorFallback.description')}
    />
  )
}
