import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './SettingsItemTitle.module.scss'

export type TSettingsItemTitleProps = {
  className?: string
}

export const SettingsItemTitle = ({
  children,
  className = ''
}: PropsWithChildren<TSettingsItemTitleProps>) => (
  <h3 className={cls(styles.title, className)}>{children}</h3>
)
