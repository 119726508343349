import {
  action,
  TError,
  TLeaveMembersRequestPayload,
  TRemoveMemberFromCaseRequestPayload,
  TGetCaseMembersSuccessPayload,
  TGetCaseMembersRequestPayload,
  TCaseMemberStatusChangedRequestPayload,
  TCaseMemberStatusChangedToActiveSuccessPayload,
  TCaseMemberStatusChangedToInactiveSuccessPayload,
  TCaseMemberStatusChangedSuccessPayload
} from 'store'

// ------------------ LEAVE_MEMBER ------------------

export const LEAVE_MEMBER = 'LEAVE_MEMBER'
export const LEAVE_MEMBER_REQUEST = 'LEAVE_MEMBER_REQUEST'
export const LEAVE_MEMBER_SUCCESS = 'LEAVE_MEMBER_SUCCESS'
export const LEAVE_MEMBER_ERROR = 'LEAVE_MEMBER_ERROR'

export const leaveMembersRequest = (payload: TLeaveMembersRequestPayload) =>
  action(LEAVE_MEMBER_REQUEST, payload)
export const leaveMembersSuccess = () => action(LEAVE_MEMBER_SUCCESS)
export const leaveMembersError = (payload: TError) => action(LEAVE_MEMBER_ERROR, payload)

// ------------------ REMOVE_MEMBER_FROM_CASE ------------------

export const REMOVE_MEMBER_FROM_CASE = 'REMOVE_MEMBER_FROM_CASE'
export const REMOVE_MEMBER_FROM_CASE_REQUEST = 'REMOVE_MEMBER_FROM_CASE_REQUEST'
export const REMOVE_MEMBER_FROM_CASE_SUCCESS = 'REMOVE_MEMBER_FROM_CASE_SUCCESS'
export const REMOVE_MEMBER_FROM_CASE_ERROR = 'REMOVE_MEMBER_FROM_CASE_ERROR'

export const removeMemberFromCaseRequest = (payload: TRemoveMemberFromCaseRequestPayload) =>
  action(REMOVE_MEMBER_FROM_CASE_REQUEST, payload)
export const removeMemberFromCaseSuccess = () => action(REMOVE_MEMBER_FROM_CASE_SUCCESS)
export const removeMemberFromCaseError = (payload: TError) =>
  action(REMOVE_MEMBER_FROM_CASE_ERROR, payload)

// ------------------ GET_CASE_MEMBERS ------------------

export const GET_CASE_MEMBERS = 'GET_CASE_MEMBERS'
export const GET_CASE_MEMBERS_REQUEST = 'GET_CASE_MEMBERS_REQUEST'
export const GET_CASE_MEMBERS_SUCCESS = 'GET_CASE_MEMBERS_SUCCESS'
export const GET_CASE_MEMBERS_ERROR = 'GET_CASE_MEMBERS_ERROR'

export const getCaseMembersRequest = (payload: TGetCaseMembersRequestPayload) =>
  action(GET_CASE_MEMBERS_REQUEST, payload)
export const getCaseMembersSuccess = (payload: TGetCaseMembersSuccessPayload) =>
  action(GET_CASE_MEMBERS_SUCCESS, payload)
export const getCaseMembersError = (payload: TError) => action(GET_CASE_MEMBERS_ERROR, payload)

// ------------------ CASE_MEMBER_STATUS_CHANGED ------------------

export const CASE_MEMBER_STATUS_CHANGED_REQUEST = 'CASE_MEMBER_STATUS_CHANGED_REQUEST'
export const CASE_MEMBER_STATUS_CHANGED_TO_ACTIVE_SUCCESS =
  'CASE_MEMBER_STATUS_CHANGED_TO_ACTIVE_SUCCESS'
export const CASE_MEMBER_STATUS_CHANGED_TO_INACTIVE_SUCCESS =
  'CASE_MEMBER_STATUS_CHANGED_TO_INACTIVE_SUCCESS'
export const CASE_MEMBER_STATUS_CHANGED_SUCCESS = 'CASE_MEMBER_STATUS_CHANGED_SUCCESS'

// ---------------- RESET_CASE_MEMBERS ----------------------
export const RESET_CASE_MEMBERS = 'RESET_CASE_MEMBERS'

export const caseMemberStatusChangedRequest = (payload: TCaseMemberStatusChangedRequestPayload) =>
  action(CASE_MEMBER_STATUS_CHANGED_REQUEST, payload)
export const caseMemberStatusChangedToActiveSuccess = (
  payload: TCaseMemberStatusChangedToActiveSuccessPayload
) => action(CASE_MEMBER_STATUS_CHANGED_TO_ACTIVE_SUCCESS, payload)
export const caseMemberStatusChangedToInactiveSuccess = (
  payload: TCaseMemberStatusChangedToInactiveSuccessPayload
) => action(CASE_MEMBER_STATUS_CHANGED_TO_INACTIVE_SUCCESS, payload)
export const caseMemberStatusChangedSuccess = (payload: TCaseMemberStatusChangedSuccessPayload) =>
  action(CASE_MEMBER_STATUS_CHANGED_SUCCESS, payload)
export const resetCaseMembers = () => action(RESET_CASE_MEMBERS)
