/** Common sorting enum */
export enum ESorting {
  ASC = 'asc',
  DESC = 'desc'
}

export enum EUploadDefaults {
  MAX_FILE_NAME_LENGTH = 120,
  MIN_FILE_NAME_LENGTH = 0
}

export enum EFileUploadStatus {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  CANCELLED = 'CANCELLED'
}

export enum EStorageTaken {
  MIDDLE = 70,
  HIGH = 90,
  FULL = 100
}

export enum EFileLocationType {
  P2P,
  CASES
}

export enum EInputSize {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l'
}

export enum ECaseListSearchBy {
  TITLE = 'title',
  PARTICIPANT = 'participant'
}

export enum ECaseAccountType {
  MEMBER = 'member',
  OWNER = 'owner'
}

export enum EAvatarSize {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LMD = 'lmd',
  LG = 'lg',
  LLG = 'llg'
}

export enum EStorageUnitsEnum {
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB'
}

export enum EOnTheWebState {
  EMPTY = 'EMPTY',
  EDITING = 'EDITING',
  WITH_DATA = 'WITH_DATA'
}

export enum EChatMessageAction {
  EDIT = 'EDIT',
  QUOTE = 'QUOTE'
}

export enum EHistoryEvents {
  HISTORY_FIRST = 'HISTORY_FIRST',
  HISTORY_BEFORE = 'HISTORY_BEFORE',
  HISTORY_AFTER = 'HISTORY_AFTER',
  JUMP = 'JUMP'
}

export enum EChatHistoryEvents {
  HISTORY_FIRST = 'HISTORY_FIRST',
  HISTORY_BEFORE = 'HISTORY_BEFORE',
  HISTORY_AFTER = 'HISTORY_AFTER',
  JUMP = 'JUMP',
  SEND_MESSAGE = 'SEND_MESSAGE',
  RECEIVE_MESSAGE = 'RECEIVE_MESSAGE'
}

export enum EditorCommandHandlerState {
  HANDLED = 'handled',
  NOT_HANDLED = 'not-handled'
}

export enum EditorCommands {
  ENTER = 'enter'
}

export enum EKeyCodes {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACE = 'Space'
}

export enum EChatViewType {
  P2P = 'P2P',
  CASE = 'CASE'
}

export enum ENotificationBadgeSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum ENotificationBadgeColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  MUTED = 'muted'
}

export enum ESendMessageStatus {
  SENDING = 'SENDING',
  SENT = 'SENT',
  FAILED = 'FAILED'
}

export enum EIconSize {
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
  CUSTOM = ''
}

export enum ELayoutNavKey {
  CASE = 'CASE',
  CHAT = 'CHAT',
  CONTACTS = 'CONTACTS',
  ORGANIZATIONS = 'ORGANIZATIONS',
  CONNECTOR = 'CONNECTOR',
  SHOWCASE = 'SHOWCASE',
  SUPPORT = 'SUPPORT',
  HELP_CENTER = 'HELP_CENTER',
  HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
  COMMUNITIES = 'COMMUNITIES',
  EVENTS = 'EVENTS'
}

export enum ECasesDocumentKey {
  DESCRIPTION = 'DESCRIPTION',
  OPINION = 'OPINION'
}

export enum EContactsSort {
  NAME = 'NAME',
  DATE = 'DATE',
  ROLES = 'ROLES',
  CREATED_AT = 'CREATED_AT'
}

export enum ECaseCloudTabKey {
  FIRST = '0',
  SECOND = '1'
}

export enum ETargetSearchedEntity {
  CASE = 'CASE',
  CASE_INVITE = 'CASE_INVITE'
}

export enum EItemSentType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export enum ESet2FAType {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE'
}
export enum EBusinessAdminDeleteAction {
  REJECT = 'REJECT',
  WITHDRAW = 'WITHDRAW'
}

export enum EBusinessAdminConfirmFrom {
  ADMINISTRATION = 'ADMINISTRATION',
  SENT = 'SENT',
  ORGANIZATION_RECEIVED_ADMINISTRATION = 'ORGANIZATION_RECEIVED_ADMINISTRATION',
  ORGANIZATION_REGISTERED = 'ORGANIZATION_REGISTERED',
  STAFF_REQUESTS = 'STAFF_REQUESTS',
  STAFF_RECEIVED = 'STAFF_RECEIVED',
  STAFF_SENT = 'STAFF_SENT'
}

export enum ESubscriptionName {
  BASIC = 'BASIC',
  PROFESSIONAL = 'PROFESSIONAL',
  BUSINESS = 'BUSINESS'
}

export enum EChangeEmailConfirmStep {
  SET_PASSWORD = 0,
  FIRST_2FA = 1,
  SET_EMAIL = 2,
  SECOND_2FA = 3
}

export enum EToggleCallOrSoundType {
  MUTE_NOTIFICATIONS = 'muteNotifications',
  MUTE_CALLS = 'muteCalls',
  REJECT_CALLS = 'rejectCalls'
}

export enum EAttachLinkedFilesFilterDirections {
  FORWARD = 'FORWARD',
  REVERSE = 'REVERSE'
}

export enum EOrganizationMemberSort {
  CREATED_AT = 'CREATED_AT',
  USER_NAME = 'USER_NAME',
  MDT_TOKENS = 'MDT_TOKENS',
  LAST_OPERATION = 'LAST_OPERATION',
  ROLE = 'ROLE',
  DEPARTMENT_LEADER = 'DEPARTMENT_LEADER'
}

export enum EHotkeysScope {
  FULLSCREEN_VIEWER = 'FULLSCREEN_VIEWER'
}
