import { ReactNode, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { MAX_LENGTH_50 } from 'globalConstants'
import { TResponseError, parseError } from 'utils'
import { validation } from 'utils/validation'
import { useRefValue, useToggle } from 'App/hooks'
import { setModalTitleAction, useAppDispatch, useAppSelector } from 'store'
import {
  Alert,
  ErrorModal,
  Help,
  StepsFooterActions,
  Switch,
  TChannelDepartment,
  TextField
} from 'App/components'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { SelectGroup } from './SelectGroup'
import styles from './UnityHubCreateChannelsMainStep.module.scss'

export type TUnityHubCreateChannelsMainStepProps = {
  channelMembers: TAccountsSelectOptionData[]
  setName: (name: string) => void
  setIsPublic: (value: boolean) => void
  onOpenMembers: () => void
  onFormSubmit: (values: { name: string; isPublic: boolean }) => void

  alert?: ReactNode
  name?: string
  isPublic?: boolean
  initialValues: {
    name: string
    isPublic: boolean
  }
  channelManager?: TAccountsSelectOptionData
  isEdit?: boolean
  isLeaderInDepartments?: boolean
  error?: TResponseError
  isFieldError?: boolean
  processing?: boolean
  loading?: boolean
  selectManagerDisabled?: boolean
  showAlert?: boolean
  dirty?: boolean
  channelDepartments?: TChannelDepartment[]
  onOpenChanelManager?: () => void
}

export const UnityHubCreateChannelsMainStep = ({
  channelMembers,
  channelManager,
  isFieldError,
  name,
  isEdit,
  loading,
  processing,
  isLeaderInDepartments,
  alert,
  showAlert,
  selectManagerDisabled,
  dirty,
  isPublic,
  channelDepartments,
  setName,
  setIsPublic,
  onOpenChanelManager,
  onOpenMembers,
  onFormSubmit,
  error = null,
  initialValues
}: TUnityHubCreateChannelsMainStepProps) => {
  const dispatch = useAppDispatch()

  const { value: shouldConfirm, toggle } = useToggle()
  const { value: modified, toggleOn: toggleOnModified, toggleOff: toggleOffModified } = useToggle()

  const modalTitle = useAppSelector((state) => state.modal.modalTitle)

  const { setValue: setRootModalTitle, getValue: getRootModalTitle } = useRefValue('')
  const { t } = useTranslation()

  const validateName = useMemo(
    () => validation.composeValidators(validation.required(), validation.maxLength(MAX_LENGTH_50)),
    []
  )
  const nameFieldError = validateName(name, {})

  const handleSubmit = () => {
    toggleOffModified()
    onFormSubmit({ name: name || '', isPublic: isPublic || false })
  }

  useEffect(() => {
    if (shouldConfirm) {
      dispatch(setModalTitleAction({ modalTitle: t('modal.createPublicChannelConfirm.title') }))
    } else if (getRootModalTitle()) {
      dispatch(setModalTitleAction({ modalTitle: getRootModalTitle() }))
    }
  }, [dispatch, shouldConfirm, getRootModalTitle, t])

  useEffect(() => {
    if (modalTitle && !getRootModalTitle()) {
      setRootModalTitle(modalTitle)
    }
  }, [modalTitle, getRootModalTitle, setRootModalTitle])

  return (
    <>
      {shouldConfirm ? (
        <div>
          <p className={styles.text}>{t('modal.createPublicChannelConfirm.content')}</p>
          <StepsFooterActions
            submitLabel={
              isEdit
                ? t('modal.createPublicChannelConfirm.submitButton_save')
                : t('modal.createPublicChannelConfirm.submitButton_create')
            }
            onSubmit={handleSubmit}
            submitLoading={loading}
            onClickBack={toggle}
            submitDisabled={(!modified && isFieldError) || !dirty}
          />
          <ErrorModal error={error} />
        </div>
      ) : (
        <div>
          {showAlert && (
            <Alert className={styles.alert} variant="info" size="sm">
              {alert}
            </Alert>
          )}

          <div className={styles.row}>
            <span>
              <Trans
                t={t}
                i18nKey="modal.createChannel.publicSwitch"
                components={{
                  help: (
                    <Help iconVariant="info" content={t('modal.createChannel.publicSwitchHelp')} />
                  )
                }}
              />
            </span>

            <Switch
              checked={isPublic}
              onChange={(checked) => {
                toggleOnModified()
                setIsPublic(checked)
              }}
            />
          </div>

          <TextField
            value={name}
            onChange={(event) => {
              toggleOnModified()
              setName(event.target.value)
            }}
            classes={{ wrapper: styles.field }}
            invalid={!!nameFieldError || isFieldError}
            error={isFieldError ? parseError(error) : nameFieldError}
            valueLengthMax={MAX_LENGTH_50}
            topLabel={t('modal.createChannel.nameField')}
            autoFocus={true}
          />

          {(!isPublic || (onOpenChanelManager && !isLeaderInDepartments)) && (
            <div className={styles.group}>
              {!isPublic && (
                <SelectGroup
                  label={<span>{t('modal.createChannel.membersTitle')}</span>}
                  variant="members"
                  loading={loading}
                  channelMembers={channelMembers}
                  channelDepartments={channelDepartments}
                  onClick={onOpenMembers}
                />
              )}

              {onOpenChanelManager && !isLeaderInDepartments && (
                <SelectGroup
                  disabled={selectManagerDisabled}
                  label={
                    <span>
                      <Trans
                        t={t}
                        i18nKey="modal.createChannel.managerTitle"
                        components={{
                          help: (
                            <Help
                              iconVariant="info"
                              content={t('modal.createChannel.managerTitleHelp')}
                            />
                          )
                        }}
                      />
                    </span>
                  }
                  variant="manager"
                  onClick={onOpenChanelManager}
                  channelManager={channelManager}
                />
              )}
            </div>
          )}

          <StepsFooterActions
            submitLabel={
              isEdit
                ? t('modal.createChannel.submitButton_save')
                : t('modal.createChannel.submitButton_create')
            }
            onSubmit={!initialValues.isPublic && isPublic ? toggle : handleSubmit}
            submitLoading={processing}
            submitDisabled={(!modified && isFieldError) || nameFieldError || !dirty}
          />

          <ErrorModal error={isFieldError ? undefined : error} />
        </div>
      )}
    </>
  )
}
