import { FC, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

import { CaseAutoApproveDialog } from 'App/components'
import {
  caseAutoApproveContactsClearAction,
  createLoadingSelector,
  createProcessingSelector,
  getCaseAutoApproveContactsRequest,
  GET_CASE_AUTO_APPROVE_CONTACTS,
  setCaseAutoApproveContactsCategory,
  setCaseAutoApproveContactsSearch,
  setCaseAutoApproveSettingsRequest,
  SET_CASE_AUTO_APPROVE_SETTINGS
} from 'store'
import { State } from 'redux/rootReducer'

export type TCaseAutoApproveDialogContainerProps = ReturnType<
  typeof useCaseAutoApproveDialogContainer
>

const loadingSelector = createLoadingSelector([GET_CASE_AUTO_APPROVE_CONTACTS])
const processingSelector = createProcessingSelector([SET_CASE_AUTO_APPROVE_SETTINGS])

const useCaseAutoApproveDialogContainer = () => {
  const dispatch = useDispatch()
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getContacts: getCaseAutoApproveContactsRequest,
          clear: caseAutoApproveContactsClearAction,
          setSearch: setCaseAutoApproveContactsSearch,
          setCaseAutoApproveSettings: setCaseAutoApproveSettingsRequest,
          setCategory: setCaseAutoApproveContactsCategory
        },
        dispatch
      ),
    [dispatch]
  )

  const props = useSelector((state: State) => {
    const { filters, ids } = state.settings.accountAndNotifications.caseAutoApprove
    const { page, showBy, total, search, categoryId } = filters

    return {
      page,
      showBy,
      total,
      search,
      ids,
      categoryId,
      caseAutoApproveSettingsError: state.errors[SET_CASE_AUTO_APPROVE_SETTINGS],
      contactsError: state.errors[GET_CASE_AUTO_APPROVE_CONTACTS],
      processing: processingSelector(state),
      loading: loadingSelector(state)
    }
  })

  return {
    ...actions,
    ...props
  }
}

export type TCaseAutoApproveDialogViewProps = Omit<TCaseAutoApproveDialogContainerProps, 'clear'>

export const CaseAutoApproveDialogContainer: FC = () => {
  const {
    clear,
    getContacts,
    contactsError,
    caseAutoApproveSettingsError,
    categoryId,
    ids,
    loading,
    page,
    processing,
    search,
    setCaseAutoApproveSettings,
    setCategory,
    setSearch,
    showBy,
    total
  } = useCaseAutoApproveDialogContainer()

  useEffect(() => {
    getContacts({ page: 0 })

    return () => {
      clear()
    }
  }, [clear, getContacts])

  return (
    <CaseAutoApproveDialog
      getContacts={getContacts}
      contactsError={contactsError}
      caseAutoApproveSettingsError={caseAutoApproveSettingsError}
      categoryId={categoryId}
      ids={ids}
      loading={loading}
      page={page}
      processing={processing}
      search={search}
      setCaseAutoApproveSettings={setCaseAutoApproveSettings}
      setCategory={setCategory}
      setSearch={setSearch}
      showBy={showBy}
      total={total}
    />
  )
}
