import { useTranslation } from 'react-i18next'

import { Button, SettingsItemDescription, SettingsItemTitle } from 'App/components'
import { EModalComponents, ShowModalContainer } from 'App/containers'

import styles from './SettingsSecurityDeleteAccount.module.scss'

type TSettingsSecurityDeleteAccountProps = {
  description: string
}

export const SettingsSecurityDeleteAccount = ({
  description
}: TSettingsSecurityDeleteAccountProps) => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsItemTitle className={styles.title}>
        {t('settings.security.deleteAccount.title')}
      </SettingsItemTitle>
      <SettingsItemDescription>{description}</SettingsItemDescription>

      <ShowModalContainer
        modalTitle={t('modal.deleteAccountConfirm.title')}
        modalType={EModalComponents.DELETE_SELF_ACCOUNT_CONFIRM}
      >
        {({ showModalHandler }) => (
          <Button variant="outlined" size="sm" onClick={showModalHandler} className={styles.button}>
            {t('settings.security.deleteAccount.deleteAccountButton')}
          </Button>
        )}
      </ShowModalContainer>
    </>
  )
}
