import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { setFileDetailsId } from 'store/files/myFiles'
import { FileInformationPanelDrawer } from 'App/components'

const mapStateToProps = (state: State) => {
  const recordId = state.file.recordId ?? ''

  const { file } =
    Object.values(state.chat.chatMessages.messages.list).find(
      ({ fileId }) => fileId === recordId
    ) || {}
  const {
    owner,
    fileName = '',
    extension = '',
    fileSize = '',
    createdAt = '',
    updatedAt = '',
    id = '',
    casesPermissions = [],
    sharedAt = '',
    p2pPermissions = []
  } = file || {
    ...(state.fileShared.fileList[recordId] || state.file.fileList[recordId]),
    casesPermissions: []
  } ||
  {}
  const { id: accountId = '' } = state.global.accountData || {}

  return {
    id,
    accountId,
    extension,
    fileName,
    fileSize,
    updatedAt,
    createdAt,
    owner,
    casesPermissions,
    sharedAt,
    p2pPermissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onCloseFileInfoPanel: setFileDetailsId
    },
    dispatch
  )

export const ChatFileInformationPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileInformationPanelDrawer)
