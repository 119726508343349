import { useCallback, useEffect, useState } from 'react'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  GET_CONTACTS,
  getContactsRequest,
  useAppDispatch,
  useAppSelector,
  resetContacts
} from 'store'
import { EInvitationType } from 'globalConstants'
import { InvitationsList } from 'App/components'
import { EContactsSort } from 'enums'
import { useRefreshNotification } from 'App/hooks'

export type TInvitationsContactsContainerProps = {
  type: EInvitationType
  status?: ContactStatusEnum
}

const loadingSelector = createLoadingSelector([GET_CONTACTS])

export const InvitationsContactsContainer = ({
  type,
  status
}: TInvitationsContactsContainerProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(0)

  const loading = useAppSelector(loadingSelector)
  const showBy = useAppSelector((state) => state.casesInvite.pagination.showBy)
  const total = useAppSelector((state) => state.contacts.contactInvitations.total)
  const ids = useAppSelector((state) => state.contacts.contactInvitations.ids)

  const getContacts = useCallback(() => {
    dispatch(
      getContactsRequest({
        page: currentPage,
        type,
        status,
        withoutHidden: true,
        sortBy: EContactsSort.DATE,
        selectedAccountTypes: []
      })
    )
  }, [currentPage, dispatch, status, type])

  const { removeToast } = useRefreshNotification({
    onRefresh: getContacts,
    message: t('common.toast.newInvitations')
  })

  useEffect(() => {
    if (type) {
      removeToast()
      getContacts()
    }
  }, [type, status, currentPage, getContacts, removeToast])

  useEffect(() => {
    setCurrentPage(0)
  }, [type])

  useEffect(
    () => () => {
      dispatch(resetContacts())
    },
    [dispatch]
  )

  return (
    <InvitationsList
      ids={ids}
      showBy={showBy}
      loading={loading}
      total={total}
      type={type}
      page={currentPage}
      setPage={setCurrentPage}
    />
  )
}
