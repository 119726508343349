import { memo, useCallback, useMemo, useState, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DropDownMenu, InvitationMenuGroup, TabPane, Tabs, TNavMenuItem } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './Menu.module.scss'

type TInvitationMenuViewProps = {
  received: TNavMenuItem[]
  sent: TNavMenuItem[]
}

const TABS = {
  RECEIVED: '0',
  SENT: '1'
}

const MenuView = ({ received, sent }: TInvitationMenuViewProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { push, location } = useHistory()

  const [currentTab, setCurrentTab] = useState(TABS.RECEIVED)
  const [title, setTitle] = useState('0')

  const { t } = useTranslation()

  const navMenu = useMemo(
    () => (TABS.SENT === currentTab ? sent : received).filter((item) => !item.hidden),
    [currentTab, received, sent]
  )
  const titleList = useMemo(() => navMenu.map((item) => item.title), [navMenu])

  const shouldShowTabs = received.length > 0 && sent.length > 0
  const shouldShowDropDownMenu = navMenu.length > 1

  const handleTabChange = useCallback(
    (key: string) => {
      if (shouldShowTabs) {
        const link = [received, sent][Number(key)][0]?.link
        push(link)
        setCurrentTab(key)
      }
    },
    [push, received, sent, shouldShowTabs]
  )

  const handleDropDownChange = useCallback(
    (key: string) => {
      const link = navMenu[Number(key)].link

      setTitle(key)
      push(link)
    },
    [navMenu, push]
  )

  useLayoutEffect(() => {
    const newTitle = navMenu.findIndex((item) => item.link === location.pathname)?.toString()

    if (newTitle !== title) {
      setTitle(newTitle)
    }
  }, [location.pathname, navMenu, title])

  useLayoutEffect(() => {
    const isSentTab = sent.findIndex((item) => item.link === location.pathname) !== -1

    setCurrentTab(isSentTab ? TABS.SENT : TABS.RECEIVED)
  }, [location.pathname, sent])

  if (isDesktop) {
    return (
      <div className={styles.wrapper}>
        {Boolean(received.length) && (
          <InvitationMenuGroup title={t('contacts.invitations.tabs.received')} items={received} />
        )}
        {Boolean(sent.length) && (
          <InvitationMenuGroup title={t('contacts.invitations.tabs.sent')} items={sent} />
        )}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {shouldShowTabs && (
        <Tabs
          activeKey={currentTab}
          variant="fill"
          onChange={handleTabChange}
          destroyInactiveTabPane={true}
        >
          <TabPane tab={t('contacts.invitations.tabs.received')} key={TABS.RECEIVED}></TabPane>
          <TabPane tab={t('contacts.invitations.tabs.sent')} key={TABS.SENT}></TabPane>
        </Tabs>
      )}

      {shouldShowDropDownMenu && (
        <DropDownMenu
          classes={{
            root: styles.dropDownMenu,
            title: styles.dropDownMenuTitle,
            item: styles.dropDownMenuItem,
            list: styles.dropDownMenuList
          }}
          list={titleList}
          title={title}
          placement="bottomLeft"
          onChange={handleDropDownChange}
        />
      )}
    </div>
  )
}

export const Menu = memo(MenuView)
