import { TTimeZoneSelectOption } from 'App/components'
import { API, api, APIData } from 'services/api'
import { TIds, TMeeting } from 'store'

type TEditMeetingPayload = {
  meetingId: string

  title?: string
  timezone?: TTimeZoneSelectOption['value']
  startOn?: string
  endOn?: string
  hasReminder?: boolean
  inviteAllActiveMembers?: boolean
  guestAccountIds?: TIds
  location?: string
  notifyUsers?: boolean
}

export const editMeeting = async ({
  startOn,
  endOn,
  timezone,
  title,
  location,
  hasReminder,
  inviteAllActiveMembers,
  meetingId,
  notifyUsers,
  guestAccountIds
}: TEditMeetingPayload) => {
  const { data }: APIData<TMeeting> = await api.patch(API.SCHEDULE_MEETING(meetingId), {
    startOn,
    endOn,
    timezone,
    title,
    location,
    hasReminder,
    notifyUsers,
    inviteAllActiveMembers,
    guestAccountIds
  })

  return data
}
export const getGoogleCalendarUrl = (meetingId: string) =>
  `${API.URL}${API.ADD_TO_GOOGLE_CALENDAR(meetingId)}`
export const getICalFile = (meetingId: string) =>
  `${API.URL}${API.EXPORT_TO_ICAL_CALENDAR(meetingId)}`

export const getExternalMeetingLink = (id: string) => `${API.URL}${API.EXTERNAL_MEETING_URL(id)}`

export const createExternalMeeting = async () => {
  const { data }: APIData<{ id: string; expiredAt: string }> = await api.post(
    API.CREATE_EXTERNAL_MEETING
  )

  return data
}

export type TSendExternalMeetingLinkToEmailsPayload = {
  id: string
  emails: string[]
}
export const sendExternalMeetingLinkToEmails = async ({
  id,
  emails
}: TSendExternalMeetingLinkToEmailsPayload) =>
  await api.post(API.SEND_EXTERNAL_MEETING_LINK_TO_EMAILS, { emails, uuid: id })
