import isArray from 'lodash/isArray'

import { parseError, TResponseError } from 'utils'

import styles from './ErrorModal.module.scss'

export type TErrorModalProps = {
  error?: TResponseError
}

export const ErrorModal = ({ error }: TErrorModalProps) => {
  const message = !!error?.response?.data?.message

  if (isArray(message)) {
    return (
      <div className={styles.error}>
        {message.map((msg, index) => (
          <p className={styles.errorParagraph} key={index}>
            {msg}
          </p>
        ))}
      </div>
    )
  }

  return error ? <div className={styles.error}>{parseError(error)}</div> : null
}
