import cls from 'classnames'
import { CallTypeEnum } from '@medentee/enums'

import {
  accountIdSelector,
  hideModalAction,
  joinToCallRequest,
  sendAllowCallRequest,
  TOngoingCall,
  TOngoingMeetingCase,
  useAppDispatch,
  useAppSelector
} from 'store'
import { getMapComponent } from 'utils'
import { P2PCall, P2PMeetingCall, CaseGroupCall, CaseMeetingCall } from 'App/components/Meetings'
import { VoiceRoom } from 'App/components/Meetings/VoiceRoom'

import styles from './OngoingMeeting.module.scss'

type TOngoingMeetingProps = {
  id: string
}

export type TGetMapComponentProps = Pick<
  TOngoingCall,
  'meeting' | 'answerersMap' | 'durationMs' | 'isMyActiveCall'
> & {
  accountId: string
  ableToSwitch: boolean
  isOnCurrentCall: boolean
  isMyActiveCall: boolean
  onMeetingActionClick: () => void

  meetingCase?: TOngoingMeetingCase
}

const ONGOING_MEETING_MAP = new Map<
  CallTypeEnum,
  (props: TGetMapComponentProps) => JSX.Element | null
>()
  .set(
    CallTypeEnum.P2P,
    ({
      answerersMap,
      durationMs,
      ableToSwitch,
      isOnCurrentCall,
      isMyActiveCall,
      accountId,
      onMeetingActionClick
    }) => (
      <P2PCall
        accountId={accountId}
        durationMs={durationMs}
        answerersMap={answerersMap}
        ableToSwitch={ableToSwitch}
        isOnCurrentCall={isOnCurrentCall}
        isMyActiveCall={isMyActiveCall}
        onMeetingActionClick={onMeetingActionClick}
      />
    )
  )
  .set(
    CallTypeEnum.P2P_MEETING,
    ({ meeting, durationMs, ableToSwitch, isOnCurrentCall, accountId, onMeetingActionClick }) => (
      <P2PMeetingCall
        meeting={meeting}
        accountId={accountId}
        durationMs={durationMs}
        ableToSwitch={ableToSwitch}
        isOnCurrentCall={isOnCurrentCall}
        onMeetingActionClick={onMeetingActionClick}
      />
    )
  )
  .set(
    CallTypeEnum.CASE_MEETING,
    ({
      meetingCase,
      ableToSwitch,
      durationMs,
      isOnCurrentCall,
      isMyActiveCall,
      onMeetingActionClick
    }) => (
      <CaseGroupCall
        meetingCase={meetingCase}
        ableToSwitch={ableToSwitch}
        durationMs={durationMs}
        isOnCurrentCall={isOnCurrentCall}
        isMyActiveCall={isMyActiveCall}
        onMeetingActionClick={onMeetingActionClick}
      />
    )
  )
  .set(
    CallTypeEnum.CASE_GROUP,
    ({
      meetingCase,
      ableToSwitch,
      durationMs,
      isOnCurrentCall,
      isMyActiveCall,
      onMeetingActionClick
    }) => (
      <CaseMeetingCall
        meetingCase={meetingCase}
        ableToSwitch={ableToSwitch}
        durationMs={durationMs}
        isOnCurrentCall={isOnCurrentCall}
        isMyActiveCall={isMyActiveCall}
        onMeetingActionClick={onMeetingActionClick}
      />
    )
  )

export const OngoingMeeting = ({ id }: TOngoingMeetingProps) => {
  const dispatch = useAppDispatch()
  const {
    meeting,
    durationMs,
    isMyActiveCall,
    case: meetingCase,
    answerersMap,
    callType,
    callId,
    voiceRoom
  } = useAppSelector((state) => state.calls.ongoingCalls.list[id] || {})
  const isFolded = useAppSelector((state) => state.calls.isFolded)
  const hasActiveCall = useAppSelector((state) => !!state.calls.answer.createCall)
  const accountId = useAppSelector(accountIdSelector)
  const activeVoiceRoomId = useAppSelector((state) => state.organizations.activeVoiceRoom?.id)

  const ableToSwitch = isMyActiveCall && !hasActiveCall
  const isOnCurrentCall = isMyActiveCall && hasActiveCall

  const ableToSwitchVoiceRoom = isMyActiveCall && !activeVoiceRoomId

  const handleMeetingCall = () => {
    if (!callId) {
      return
    }

    if (ableToSwitch) {
      dispatch(joinToCallRequest({ callId, processingId: callId }))
    } else {
      dispatch(sendAllowCallRequest({ callId }))
    }

    dispatch(hideModalAction())
  }

  return (
    <div className={cls(styles.root, isFolded && styles.rootFolded)}>
      {voiceRoom && <VoiceRoom voiceRoom={voiceRoom} ableToSwitch={ableToSwitchVoiceRoom} />}
      {getMapComponent(ONGOING_MEETING_MAP, callType, {
        meeting,
        meetingCase,
        answerersMap,
        accountId,
        durationMs,
        ableToSwitch,
        isOnCurrentCall,
        isMyActiveCall,
        onMeetingActionClick: handleMeetingCall
      })}
    </div>
  )
}
