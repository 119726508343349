import { LinkProps, match, NavLinkProps } from 'react-router-dom'
import { Location } from 'history'
import { ComponentType, ReactNode, useMemo } from 'react'
import { AccountTypeNames, BusinessAccountRoleNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ELayoutNavKey } from 'enums'
import { accountRoleNameSelector, useAppSelector } from 'store'
import { ReactComponent as AccountCorporateIcon } from 'assets/icons/AccountCorporate.svg'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as EventsIcon } from 'assets/icons/Events.svg'
import { ReactComponent as PeopleNearbyIcon } from 'assets/icons/PeopleNearby.svg'

import { validateRoute } from '../../../RoleRoute'

import { TNavClasses } from './Nav'

const NAV_CONFIG: Record<
  AccountTypeNames,
  {
    mainNav: ELayoutNavKey[]
    mobileNav: ELayoutNavKey[]
    headerNav: ELayoutNavKey[]
  }
> = {
  // Commented out due to the need to disable functionality for the task MED-7867

  [AccountTypeNames.BUSINESS]: {
    mainNav: [
      ELayoutNavKey.COMMUNITIES,
      ELayoutNavKey.EVENTS,
      ELayoutNavKey.CHAT,
      ELayoutNavKey.ORGANIZATIONS,
      ELayoutNavKey.CASE,
      ELayoutNavKey.CONTACTS
      // ELayoutNavKey.CONNECTOR
    ],
    mobileNav: [ELayoutNavKey.EVENTS, ELayoutNavKey.CHAT, ELayoutNavKey.COMMUNITIES],
    headerNav: [
      ELayoutNavKey.ORGANIZATIONS,
      ELayoutNavKey.CASE,
      ELayoutNavKey.CONTACTS
      // ELayoutNavKey.CONNECTOR
    ]
  },
  [AccountTypeNames.PROFESSIONAL]: {
    mainNav: [
      ELayoutNavKey.CHAT,
      ELayoutNavKey.EVENTS,
      ELayoutNavKey.COMMUNITIES,
      ELayoutNavKey.CASE,
      ELayoutNavKey.ORGANIZATIONS,
      ELayoutNavKey.CONTACTS
      // ELayoutNavKey.SHOWCASE,
      // ELayoutNavKey.CONNECTOR
    ],
    mobileNav: [ELayoutNavKey.EVENTS, ELayoutNavKey.CHAT, ELayoutNavKey.CONTACTS],
    headerNav: [
      ELayoutNavKey.COMMUNITIES,
      ELayoutNavKey.CASE,
      ELayoutNavKey.ORGANIZATIONS
      // ELayoutNavKey.SHOWCASE,
      // ELayoutNavKey.CONNECTOR
    ]
  }
}

export const useNavConfig = () => {
  const accountType = useAppSelector(
    (state) => state.global.accountData?.type.name ?? AccountTypeNames.PROFESSIONAL
  )

  return NAV_CONFIG[accountType]
}

export const isActiveRoute = (
  startsWith: boolean | undefined,
  to: LinkProps['to'],
  location: Location
) =>
  typeof to === 'string' &&
  (startsWith ? location.pathname.startsWith(to) : location.pathname.includes(to))

export type TNavItem = TNavClasses &
  Pick<NavLinkProps, 'to'> & {
    startsWith?: boolean
    label: string
    icon: ReactNode
    itemKey: ELayoutNavKey

    counterStateString?: string
    allowedAccountRoles?: BusinessAccountRoleNameEnum[]
    allowedAccountTypes?: AccountTypeNames[]
    component?: ComponentType
    onClick?: () => void
    isActive?: (match: match | null, location: Location) => boolean
  }

export const useNavItems = ({
  list,
  classes
}: { list: ELayoutNavKey[] } & TNavClasses): TNavItem[] => {
  const { t } = useTranslation()

  const accountType = useAppSelector(
    (state) => state.global.accountData?.type.name
  ) as AccountTypeNames
  const accountRole = useAppSelector(accountRoleNameSelector)

  return useMemo<TNavItem[]>(() => {
    const isProfessional = accountType === AccountTypeNames.PROFESSIONAL
    const itemsMap = new Map<ELayoutNavKey, TNavItem>([
      [
        ELayoutNavKey.CASE,
        {
          classes,
          startsWith: true,
          to: '/cases',
          label: t('navigation.cases'),
          icon: <CaseIcon />,
          itemKey: ELayoutNavKey.CASE,
          counterStateString: 'cases.totalUnreadCount'
        }
      ],
      [
        ELayoutNavKey.CHAT,
        {
          classes,
          startsWith: true,
          to: '/chat',
          label: t('navigation.chats'),
          icon: <MessageTextIcon />,
          itemKey: ELayoutNavKey.CHAT,
          counterStateString: 'chats.totalUnreadCount'
        }
      ],
      [
        ELayoutNavKey.CONTACTS,
        {
          classes,
          startsWith: true,
          to: '/contacts',
          label: t('navigation.contacts'),
          icon: <AccountCorporateIcon />,
          itemKey: ELayoutNavKey.CONTACTS,
          counterStateString: 'contacts',
          allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]
        }
      ],
      [
        ELayoutNavKey.ORGANIZATIONS,
        {
          classes,
          to: isProfessional ? '/organizations' : '/organization',
          label: isProfessional ? t('navigation.organizations') : t('navigation.organization'),
          icon: <AccountGroupIcon />,
          itemKey: ELayoutNavKey.ORGANIZATIONS,
          allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
          allowedAccountRoles: [
            BusinessAccountRoleNameEnum.SUPERADMIN,
            BusinessAccountRoleNameEnum.ADMIN
          ],
          counterStateString: 'organizations.totalUnreadCount'
        }
      ],
      [
        ELayoutNavKey.EVENTS,
        {
          classes,
          startsWith: true,
          to: '/events',
          label: t('navigation.events'),
          icon: <EventsIcon />,
          itemKey: ELayoutNavKey.EVENTS,
          allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
          allowedAccountRoles: [
            BusinessAccountRoleNameEnum.SUPERADMIN,
            BusinessAccountRoleNameEnum.ADMIN
          ],
          counterStateString: 'events.totalUnreadCount'
        }
      ],
      // Commented out due to the need to disable functionality for the task MED-7867

      // [
      //   ELayoutNavKey.CONNECTOR,
      //   {
      //     classes,
      //     to: '/adverts',
      //     label: t('navigation.connector'),
      //     icon: <GlobeIcon />,
      //     itemKey: ELayoutNavKey.CONNECTOR,
      //     counterStateString: 'connector.totalUnreadCount',
      //     isActive: (_, location) => location.pathname.startsWith('/adverts'),
      //     allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
      //     allowedAccountRoles: [BusinessAccountRoleNameEnum.SUPERADMIN]
      //   }
      // ],
      // [
      //   ELayoutNavKey.SHOWCASE,
      //   {
      //     classes,
      //     to: '/showcases/feed',
      //     label: t('navigation.showcases'),
      //     icon: <ShowcaseIcon />,
      //     itemKey: ELayoutNavKey.SHOWCASE,
      //     isActive: (_, location) => location.pathname.startsWith('/showcases'),
      //     allowedAccountTypes: [AccountTypeNames.PROFESSIONAL]
      //   }
      // ],
      [
        ELayoutNavKey.COMMUNITIES,
        {
          classes,
          to: isProfessional ? '/communities' : '/community',
          label: isProfessional ? t('navigation.communities') : t('navigation.community'),
          counterStateString: 'communities.totalUnreadCount',
          icon: <PeopleNearbyIcon />,
          itemKey: ELayoutNavKey.COMMUNITIES,
          allowedAccountTypes: [AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL],
          allowedAccountRoles: [
            BusinessAccountRoleNameEnum.SUPERADMIN,
            BusinessAccountRoleNameEnum.ADMIN
          ]
        }
      ]
    ])

    return list.reduce<TNavItem[]>((res, itemKey) => {
      const item = itemsMap.get(itemKey)

      if (
        item &&
        validateRoute(accountType, item.allowedAccountTypes, accountRole, item.allowedAccountRoles)
      ) {
        return [...res, item]
      }

      return res
    }, [])
  }, [list, accountType, classes, accountRole, t])
}
