import { PropsWithChildren } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { Divider, ContextMenu, TContextMenuProps } from 'App/components'

import styles from './HeadContextMenu.module.scss'

export type THeadContextMenuProps = PropsWithChildren<
  Required<Pick<TContextMenuProps, 'menuItems'>> & {
    visible: boolean
    filesSize: string
    total: number
  }
>

export const HeadContextMenu = ({
  children,
  menuItems,
  visible,
  filesSize,
  total
}: THeadContextMenuProps) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[24, 0]} align="middle">
      <Col>{children}</Col>
      {visible && (
        <>
          <Col className={styles.divider}>
            <Divider variant="vertical" />
          </Col>
          <Col>
            <ContextMenu
              menuItems={menuItems}
              startAdornment={
                <div className={styles.startAdornment}>
                  {t('files.header.contextMenuLabel', { total, filesSize })}
                </div>
              }
            />
          </Col>
        </>
      )}
    </Row>
  )
}
