import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'App/components'
import { createProcessingSelector, deactivateAdvertRequest, DEACTIVATE_ADVERT } from 'store'

import styles from './DeactivateAdvertDialog.module.scss'

export type TDeactivateAdvertDialogProps = {
  advertId: string
}

const processingRequest = createProcessingSelector([DEACTIVATE_ADVERT])

export const DeactivateAdvertDialog = (props: TDeactivateAdvertDialogProps) => {
  const dispatch = useDispatch()
  const processing = useSelector(processingRequest)

  const handleClick = () => {
    dispatch(deactivateAdvertRequest(props))
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Row justify="center">
          <span className={styles.text}>
            Are you sure you want to deactivate this Advert? The Advert will be removed from the
            General Advert List but you’ll still see it here on your board and can repost it later.
          </span>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Button loading={processing} onClick={handleClick}>
            Deactivate
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
