import { normalize, schema } from 'normalizr'

import { TIds } from 'store/store.types'

import {
  ICaseDescriptionDTO,
  ICaseOpinionsDTO,
  ICaseOpinionsMembersDTO
} from '../../interfaces/api/caseDetailsDTO'

import {
  TCaseDescriptionList,
  TCaseOpinionsMembersList,
  TCaseOpinionsList
} from './caseDetails.types'

/*
 ** CASE_DESCRIPTION
 */
const caseDescriptionListSchema = new schema.Entity(
  'caseDescriptionList',
  {},
  { idAttribute: 'id' }
)

export type TCaseDescriptionNormalized = {
  caseDescriptionCount: number
  caseDescriptionList: TCaseDescriptionList
  ids: TIds
}

export const caseDescriptionNormalize = (
  originalData: ICaseDescriptionDTO
): TCaseDescriptionNormalized => {
  const normalizedData = normalize<ICaseDescriptionDTO, TCaseDescriptionNormalized, TIds>(
    originalData.descriptions,
    [caseDescriptionListSchema]
  )
  return {
    caseDescriptionCount: originalData.count,
    caseDescriptionList: normalizedData.entities.caseDescriptionList || {},
    ids: normalizedData.result || []
  }
}

/*
 ** CASE_OPINIONS
 */
const caseOpinionsListSchema = new schema.Entity('caseOpinionsList', {}, { idAttribute: 'id' })

const caseOpinionsMembersListSchema = new schema.Entity(
  'caseOpinionsMembersList',
  {},
  { idAttribute: 'id' }
)

export type TCaseOpinionsNormalized = {
  caseOpinionsCount: number
  caseOpinionsList: TCaseOpinionsList
  caseOpinionsIds: TIds
}

export type TCaseOpinionsMembersNormalized = {
  caseOpinionsMembersList: TCaseOpinionsMembersList
  caseOpinionsMembersIds: TIds
}

export const caseOpinionsNormalize = (originalData: ICaseOpinionsDTO): TCaseOpinionsNormalized => {
  const normalizedData = normalize<ICaseOpinionsDTO, TCaseOpinionsNormalized, TIds>(
    originalData.opinions,
    [caseOpinionsListSchema]
  )
  return {
    caseOpinionsCount: originalData.count || 0,
    caseOpinionsList: normalizedData.entities.caseOpinionsList || {},
    caseOpinionsIds: normalizedData.result || []
  }
}

export const caseOpinionsMembersNormalize = (
  originalData: ICaseOpinionsMembersDTO[]
): TCaseOpinionsMembersNormalized => {
  const normalizedData = normalize<ICaseOpinionsMembersDTO, TCaseOpinionsMembersNormalized, TIds>(
    originalData,
    [caseOpinionsMembersListSchema]
  )
  return {
    caseOpinionsMembersList: normalizedData.entities.caseOpinionsMembersList || {},
    caseOpinionsMembersIds: normalizedData.result || []
  }
}
