import { useRouteMatch } from 'react-router-dom'
import { useRef } from 'react'

import {
  UnityHubChannelIcon,
  TUnityHubChannelIconProps,
  UnityHubRoomNavLink
} from 'App/components/UnityHub'
import { TChannelVariant } from 'interfaces'

export type TEventsHighlightRoomProps = {
  path: string
  name: string
  eventId: string
  variant: Extract<TChannelVariant, 'chat' | 'news' | 'highlights'>
  iconVariant: TUnityHubChannelIconProps['variant']
}

export const EventsHighlightRoom = ({
  path,
  variant,
  iconVariant,
  name
}: TEventsHighlightRoomProps) => {
  const { url } = useRouteMatch()
  const roomRef = useRef<HTMLDivElement>(null)

  return (
    <UnityHubRoomNavLink
      to={`${url}/${variant}/${path}`}
      icon={<UnityHubChannelIcon variant={iconVariant} />}
      name={name}
      ref={roomRef}
    />
  )
}
