import { action } from 'store/store.utils'
import { TError } from 'store/store.types'
import { ECaseCloudTabKey } from 'enums'

import {
  TCaseUploadFileRequestPayload,
  TCopyCaseFileToMedcloudRequestPayload,
  TCopyToCaseCloudPayload,
  TDeleteCaseFilePayload,
  TDiscardCaseFilePermissionsPayload,
  TDownloadCaseFilePayload,
  TGetCaseFilesRequestPayload,
  TGetCaseFilesSuccessPayload,
  TGetCaseSharedWithMeFilesRequestPayload,
  TGetCaseSharedWithMeFilesSuccessPayload,
  TGetCaseUploadedByMeFilesRequestPayload,
  TGetCaseUploadedByMeFilesSuccessPayload,
  TRenameCaseFilePayload,
  TSetCaseCloudWhoPayload,
  TLoseAccessToCaseFileRequestPayload,
  TLoseAccessToCaseFileSuccessPayload,
  TSetCaseCloudActiveFileIdPayload,
  TChangeCaseCloudContactPermissionPayload,
  TSetCaseCloudContactPermissionSuccessPayload,
  TSetCaseCloudContactPermissionErrorPayload,
  TSetCaseCloudContactPermissionRequestPayload,
  TGetCaseContactPermissionsRequestPayload,
  TSetCaseCloudFiltersPayload,
  TGetCaseContactPermissionsSuccessPayload
} from './caseCloud.types'

// ------------------ CASE_CLOUD_PERMISSIONS ------------------
export const SET_CASE_CLOUD_ACTIVE_FILE_ID = 'SET_CASE_CLOUD_ACTIVE_FILE_ID'

export const GET_CASE_CLOUD_CONTACT_PERMISSION = 'GET_CASE_CLOUD_CONTACT_PERMISSION'
export const GET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST = 'GET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST'
export const GET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS = 'GET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS'
export const GET_CASE_CLOUD_CONTACT_PERMISSION_ERROR = 'GET_CASE_CLOUD_CONTACT_PERMISSION_ERROR'

export const REMOVE_CASE_CLOUD_CONTACT_PERMISSION = 'REMOVE_CASE_CLOUD_CONTACT_PERMISSION'
export const ADD_CASE_CLOUD_CONTACT_PERMISSION = 'ADD_CASE_CLOUD_CONTACT_PERMISSION'

export const removeCaseCloudContactPermission = (
  payload: TChangeCaseCloudContactPermissionPayload
) => action(REMOVE_CASE_CLOUD_CONTACT_PERMISSION, payload)

export const addCaseCloudContactPermission = (payload: TChangeCaseCloudContactPermissionPayload) =>
  action(ADD_CASE_CLOUD_CONTACT_PERMISSION, payload)

export const getCaseContactPermissionsRequest = (
  payload: TGetCaseContactPermissionsRequestPayload
) => action(GET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST, payload)
export const getCaseContactPermissionsSuccess = (
  payload: TGetCaseContactPermissionsSuccessPayload
) => action(GET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS, payload)
export const getCaseContactPermissionsError = (payload: TError) =>
  action(GET_CASE_CLOUD_CONTACT_PERMISSION_ERROR, payload)

export const setCaseCloudActiveFileId = (payload?: TSetCaseCloudActiveFileIdPayload) =>
  action(SET_CASE_CLOUD_ACTIVE_FILE_ID, payload)

// ------------------ SET_CASE_CLOUD_CONTACT_PERMISSION ------------------
export const SET_CASE_CLOUD_CONTACT_PERMISSION = 'SET_CASE_CLOUD_CONTACT_PERMISSION'
export const SET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST = 'SET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST'
export const SET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS = 'SET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS'
export const SET_CASE_CLOUD_CONTACT_PERMISSION_ERROR = 'SET_CASE_CLOUD_CONTACT_PERMISSION_ERROR'

export const setCaseCloudContactPermissionRequest = (
  payload: TSetCaseCloudContactPermissionRequestPayload
) => action(SET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST, payload)
export const setCaseCloudContactPermissionSuccess = (
  payload: TSetCaseCloudContactPermissionSuccessPayload
) => action(SET_CASE_CLOUD_CONTACT_PERMISSION_SUCCESS, payload)
export const setCaseCloudContactPermissionError = (
  payload: TSetCaseCloudContactPermissionErrorPayload
) => action(SET_CASE_CLOUD_CONTACT_PERMISSION_ERROR, payload)

// ------------------ CASE_UPLOAD_FILE ------------------
export const CASE_UPLOAD_FILE = 'CASE_UPLOAD_FILE'
export const CASE_UPLOAD_FILE_REQUEST = 'CASE_UPLOAD_FILE_REQUEST'
export const CASE_UPLOAD_FILE_SUCCESS = 'CASE_UPLOAD_FILE_SUCCESS'
export const CASE_UPLOAD_FILE_ERROR = 'CASE_UPLOAD_FILE_ERROR'

export const caseUploadFileRequest = (payload: TCaseUploadFileRequestPayload) =>
  action(CASE_UPLOAD_FILE_REQUEST, payload)
export const caseUploadFileSuccess = () => action(CASE_UPLOAD_FILE_SUCCESS)
export const caseUploadFileError = (payload: TError) => action(CASE_UPLOAD_FILE_ERROR, payload)

// ------------------ GET_CASE_FILES ------------------
export const GET_CASE_FILES = 'GET_CASE_FILES'
export const GET_CASE_FILES_REQUEST = 'GET_CASE_FILES_REQUEST'
export const GET_CASE_FILES_SUCCESS = 'GET_CASE_FILES_SUCCESS'
export const GET_CASE_FILES_ERROR = 'GET_CASE_FILES_ERROR'

export const getCaseFilesRequest = (payload: TGetCaseFilesRequestPayload) =>
  action(GET_CASE_FILES_REQUEST, payload)
export const getCaseFilesSuccess = (payload: TGetCaseFilesSuccessPayload) =>
  action(GET_CASE_FILES_SUCCESS, payload)
export const getCaseFilesError = (payload: TError) => action(GET_CASE_FILES_ERROR, payload)

// ------------------ GET_CASE_SHARED_WITH_ME_FILES ------------------
export const GET_CASE_SHARED_WITH_ME_FILES = 'GET_CASE_SHARED_WITH_ME_FILES'
export const GET_CASE_SHARED_WITH_ME_FILES_REQUEST = 'GET_CASE_SHARED_WITH_ME_FILES_REQUEST'
export const GET_CASE_SHARED_WITH_ME_FILES_SUCCESS = 'GET_CASE_SHARED_WITH_ME_FILES_SUCCESS'
export const GET_CASE_SHARED_WITH_ME_FILES_ERROR = 'GET_CASE_SHARED_WITH_ME_FILES_ERROR'

export const getCaseSharedWithMeFilesRequest = (payload: TGetCaseSharedWithMeFilesRequestPayload) =>
  action(GET_CASE_SHARED_WITH_ME_FILES_REQUEST, payload)
export const getCaseSharedWithMeFilesSuccess = (payload: TGetCaseSharedWithMeFilesSuccessPayload) =>
  action(GET_CASE_SHARED_WITH_ME_FILES_SUCCESS, payload)
export const getCaseSharedWithMeFilesError = (payload: TError) =>
  action(GET_CASE_SHARED_WITH_ME_FILES_ERROR, payload)

// ------------------ GET_CASE_UPLOADED_BY_ME_FILES ------------------
export const GET_CASE_UPLOADED_BY_ME_FILES = 'GET_CASE_UPLOADED_BY_ME_FILES'
export const GET_CASE_UPLOADED_BY_ME_FILES_REQUEST = 'GET_CASE_UPLOADED_BY_ME_FILES_REQUEST'
export const GET_CASE_UPLOADED_BY_ME_FILES_SUCCESS = 'GET_CASE_UPLOADED_BY_ME_FILES_SUCCESS'
export const GET_CASE_UPLOADED_BY_ME_FILES_ERROR = 'GET_CASE_UPLOADED_BY_ME_FILES_ERROR'

export const getCaseUploadedByMeFilesRequest = (payload: TGetCaseUploadedByMeFilesRequestPayload) =>
  action(GET_CASE_UPLOADED_BY_ME_FILES_REQUEST, payload)
export const getCaseUploadedByMeFilesSuccess = (payload: TGetCaseUploadedByMeFilesSuccessPayload) =>
  action(GET_CASE_UPLOADED_BY_ME_FILES_SUCCESS, payload)
export const getCaseUploadedByMeFilesError = (payload: TError) =>
  action(GET_CASE_UPLOADED_BY_ME_FILES_ERROR, payload)

// ------------------ COPY_CASE_FILE_TO_MEDCLOUD ------------------
export const COPY_CASE_FILE_TO_MEDCLOUD_REQUEST = 'COPY_CASE_FILE_TO_MEDCLOUD_REQUEST'
export const COPY_CASE_FILE_TO_MEDCLOUD_SUCCESS = 'COPY_CASE_FILE_TO_MEDCLOUD_SUCCESS'
export const COPY_CASE_FILE_TO_MEDCLOUD_ERROR = 'COPY_CASE_FILE_TO_MEDCLOUD_ERROR'

export const copyCaseFileToMedcloudRequest = (payload: TCopyCaseFileToMedcloudRequestPayload) =>
  action(COPY_CASE_FILE_TO_MEDCLOUD_REQUEST, payload)
export const copyCaseFileToMedcloudSuccess = () => action(COPY_CASE_FILE_TO_MEDCLOUD_SUCCESS)
export const copyCaseFileToMedcloudError = (payload: TError) =>
  action(COPY_CASE_FILE_TO_MEDCLOUD_ERROR, payload)

// ------------------ DELETE_CASE_FILE ------------------
export const DELETE_CASE_FILE = 'DELETE_CASE_FILE'
export const DELETE_CASE_FILE_REQUEST = 'DELETE_CASE_FILE_REQUEST'
export const DELETE_CASE_FILE_SUCCESS = 'DELETE_CASE_FILE_SUCCESS'
export const DELETE_CASE_FILE_ERROR = 'DELETE_CASE_FILE_ERROR'

export const deleteCaseFileRequest = (payload: TDeleteCaseFilePayload) =>
  action(DELETE_CASE_FILE_REQUEST, payload)
export const deleteCaseFileSuccess = () => action(DELETE_CASE_FILE_SUCCESS)
export const deleteCaseFileError = (payload: TError) => action(DELETE_CASE_FILE_ERROR, payload)

// ------------------ RENAME_CASE_FILE ------------------
export const RENAME_CASE_FILE = 'RENAME_CASE_FILE'
export const RENAME_CASE_FILE_REQUEST = 'RENAME_CASE_FILE_REQUEST'
export const RENAME_CASE_FILE_SUCCESS = 'RENAME_CASE_FILE_SUCCESS'
export const RENAME_CASE_FILE_ERROR = 'RENAME_CASE_FILE_ERROR'

export const renameCaseFileRequest = (payload: TRenameCaseFilePayload) =>
  action(RENAME_CASE_FILE_REQUEST, payload)
export const renameCaseFileSuccess = () => action(RENAME_CASE_FILE_SUCCESS)
export const renameCaseFileError = (payload: TError) => action(RENAME_CASE_FILE_ERROR, payload)

// ------------------ DISCARD_CASE_FILE_PERMISSIONS ------------------
export const DISCARD_CASE_FILE_PERMISSIONS = 'DISCARD_CASE_FILE_PERMISSIONS'
export const DISCARD_CASE_FILE_PERMISSIONS_REQUEST = 'DISCARD_CASE_FILE_PERMISSIONS_REQUEST'
export const DISCARD_CASE_FILE_PERMISSIONS_SUCCESS = 'DISCARD_CASE_FILE_PERMISSIONS_SUCCESS'
export const DISCARD_CASE_FILE_PERMISSIONS_ERROR = 'DISCARD_CASE_FILE_PERMISSIONS_ERROR'

export const discardCaseFilePermissions = (payload: TDiscardCaseFilePermissionsPayload) =>
  action(DISCARD_CASE_FILE_PERMISSIONS_REQUEST, payload)
export const discardCaseFilePermissionsSuccess = () => action(DISCARD_CASE_FILE_PERMISSIONS_SUCCESS)
export const discardCaseFilePermissionsError = (payload: TError) =>
  action(DISCARD_CASE_FILE_PERMISSIONS_ERROR, payload)

// ------------------ DOWNLOAD_CASE_FILE ------------------
export const DOWNLOAD_CASE_FILE = 'DOWNLOAD_CASE_FILE'
export const DOWNLOAD_CASE_FILE_REQUEST = 'DOWNLOAD_CASE_FILE_REQUEST'
export const DOWNLOAD_CASE_FILE_SUCCESS = 'DOWNLOAD_CASE_FILE_SUCCESS'
export const DOWNLOAD_CASE_FILE_ERROR = 'DOWNLOAD_CASE_FILE_ERROR'

export const downloadCaseFileRequest = (payload: TDownloadCaseFilePayload) =>
  action(DOWNLOAD_CASE_FILE_REQUEST, payload)

export const downloadCaseFileSuccess = () => action(DOWNLOAD_CASE_FILE_SUCCESS)

export const downloadCaseFileError = (payload: TError) => action(DOWNLOAD_CASE_FILE_ERROR, payload)

// ------------------ SET_CASE_CLOUD_WHO ------------------
export const SET_CASE_CLOUD_WHO = 'SET_CASE_CLOUD_WHO'

export const setCaseCloudWhoAction = (payload: TSetCaseCloudWhoPayload) =>
  action(SET_CASE_CLOUD_WHO, payload)

// ------------------ COPY_TO_CASE_CLOUD ------------------
export const COPY_TO_CASE_CLOUD = 'COPY_TO_CASE_CLOUD'
export const COPY_TO_CASE_CLOUD_REQUEST = 'COPY_TO_CASE_CLOUD_REQUEST'
export const COPY_TO_CASE_CLOUD_SUCCESS = 'COPY_TO_CASE_CLOUD_SUCCESS'
export const COPY_TO_CASE_CLOUD_ERROR = 'COPY_TO_CASE_CLOUD_ERROR'

export const copyToCaseCloudRequest = (payload: TCopyToCaseCloudPayload) =>
  action(COPY_TO_CASE_CLOUD_REQUEST, payload)
export const copyToCaseCloudSuccess = () => action(COPY_TO_CASE_CLOUD_SUCCESS)
export const copyToCaseCloudError = (payload: TError) => action(COPY_TO_CASE_CLOUD_ERROR, payload)

// ------------------ SET_CASE_CLOUD_CURRENT_TAB_ACTION ------------------
export const SET_CASE_CLOUD_CURRENT_TAB_ACTION = 'SET_CASE_CLOUD_CURRENT_TAB_ACTION'

export const setCaseCloudCurrentTabAction = (payload: ECaseCloudTabKey) =>
  action(SET_CASE_CLOUD_CURRENT_TAB_ACTION, payload)

// ------------------ LOSE_ACCESS_TO_CASE_FILE_REQUEST ------------------
export const LOSE_ACCESS_TO_CASE_FILE_REQUEST = 'LOSE_ACCESS_TO_CASE_FILE_REQUEST'
export const LOSE_ACCESS_TO_CASE_FILE_SUCCESS = 'LOSE_ACCESS_TO_CASE_FILE_SUCCESS'

export const loseAccessToCaseFileRequest = (payload: TLoseAccessToCaseFileRequestPayload) =>
  action(LOSE_ACCESS_TO_CASE_FILE_REQUEST, payload)
export const loseAccessToCaseFileSuccess = (payload: TLoseAccessToCaseFileSuccessPayload) =>
  action(LOSE_ACCESS_TO_CASE_FILE_SUCCESS, payload)

// ------------------ RESET_CLOUD_FILES_ACTION ------------------
export const RESET_CLOUD_FILES_ACTION = 'RESET_CLOUD_FILES_ACTION'

export const resetCloudFilesAction = () => action(RESET_CLOUD_FILES_ACTION)

// ------------------ RESET_CLOUD_FILES_LIST_ACTION ------------------
export const RESET_CLOUD_FILES_LIST_ACTION = 'RESET_CLOUD_FILES_LIST_ACTION'

export const resetCloudFilesListAction = () => action(RESET_CLOUD_FILES_LIST_ACTION)

// ------------------ SET_CASE_CLOUD_FILTERS ------------------
export const SET_CASE_CLOUD_FILTERS = 'SET_CASE_CLOUD_FILTERS'
export const setCaseCloudFilters = (payload: TSetCaseCloudFiltersPayload) =>
  action(SET_CASE_CLOUD_FILTERS, payload)

// ------------------ RESET_CASE_CLOUD_FILTERS ------------------
export const RESET_CASE_CLOUD_FILTERS = 'RESET_CASE_CLOUD_FILTERS'
export const resetCaseCloudFilters = () => action(RESET_CASE_CLOUD_FILTERS)
