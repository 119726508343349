import { useCallback, useMemo } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import {
  createSlides,
  downloadFileRequest,
  EExtendableCardType,
  setFileDetailsId,
  showExtendableCardAction,
  showModalAction,
  TIds,
  useAppDispatch
} from 'store'
import { ReactComponent as PencilOutlineWriteIcon } from 'assets/icons/PencilOutlineWrite.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'

import { TFileTableProps } from './FileList'

type TUseKebabMenuProps = {
  moveToTrashBinCallback: () => void
  renameCallback: () => void
}

type TFileRecord = Pick<
  TFileTableProps,
  'fileId' | 'fileName' | 'extension' | 'key' | 'isUnopenable' | 'slidesEntity'
>

type TMenuItemsProps = TFileRecord | TIds

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = ({
  moveToTrashBinCallback,
  renameCallback
}: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const menuItems = useMemo(
    () => ({
      open: ({ slidesEntity, fileId, isUnopenable }: TFileRecord) => ({
        hidden: isUnopenable,
        content: t('files.kebabMenu.open'),
        icon: <FileIcon />,
        onClick: () =>
          dispatch(
            showExtendableCardAction({
              type: EExtendableCardType.FILE_VIEWER,
              initialSlideId: fileId,
              slides: createSlides(slidesEntity, FileHistorySourcesEnum.MED_CLOUD)
            })
          )
      }),
      rename: ({ fileId, fileName }: TFileRecord) => ({
        icon: <PencilOutlineWriteIcon />,
        content: t('files.kebabMenu.rename'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalType: EModalComponents.FILE_RENAME_DIALOG,
              modalTitle: t('modal.fileRename.title'),
              modalProps: {
                fileId,
                defaultFileName: fileName,
                onSuccess: renameCallback
              }
            })
          )
      }),
      'download-to-my-device': ({ fileId }: TFileRecord) => ({
        icon: <DownloadIcon />,
        content: t('files.kebabMenu.downloadToDevice'),
        onClick: () =>
          dispatch(
            downloadFileRequest({
              fileId
            })
          )
      }),
      'permission-and-info': ({ key }: TFileRecord) => ({
        icon: <InfoIcon />,
        content: t('files.kebabMenu.permissions'),
        onClick: () => dispatch(setFileDetailsId({ recordId: key }))
      }),
      'move-to-trash-bin': (props: TMenuItemsProps) => ({
        icon: <TrashIcon />,
        content: t('files.kebabMenu.moveToTrashBin'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.moveToTrashBinConfirm.title'),
              modalType: EModalComponents.MOVE_FILE_TO_TRASH_BIN_DIALOG,
              modalProps: {
                fileIds: isArray(props) ? props : [props.fileId],
                onSuccess: moveToTrashBinCallback
              }
            })
          )
      })
    }),
    [dispatch, moveToTrashBinCallback, renameCallback, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => {
      if (isArray(props)) {
        return [menuItems['move-to-trash-bin'](props)]
      }

      return [
        menuItems.open(props),
        menuItems.rename(props),
        menuItems['download-to-my-device'](props),
        menuItems['permission-and-info'](props),
        menuItems['move-to-trash-bin'](props)
      ]
    },
    [menuItems]
  )

  return {
    getMenuItems
  }
}
