import { TError } from 'store/store.types'
import { actionCreator } from 'store/store.utils'

import * as actionTypes from './logout.actionTypes'
import { TLogoutWSActionPayload, TLogoutPayload } from './logout.types'

export const logoutRequest = (payload?: TLogoutPayload) =>
  actionCreator(actionTypes.GET_LOGOUT_REQUEST, payload)
export const logoutSuccess = () => actionCreator(actionTypes.GET_LOGOUT_SUCCESS)
export const logoutError = (payload: TError) => actionCreator(actionTypes.GET_LOGOUT_ERROR, payload)

export const logoutWSAction = (payload: TLogoutWSActionPayload) =>
  actionCreator(actionTypes.LOGOUT_WS, payload)
