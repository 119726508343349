import cls from 'classnames'
import { Trans, useTranslation } from 'react-i18next'

import { Timer } from 'App/components'

import styles from './CallTimer.module.scss'

export type TCallTimerProps = PropsWithClassName<{
  isAnsweredCall: boolean

  startTime?: Date | null
}>

export const CallTimer = ({ isAnsweredCall, startTime, className }: TCallTimerProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, className)}>
      {isAnsweredCall ? (
        startTime && <Timer startTime={startTime} />
      ) : (
        <span className={styles.calling}>
          <Trans
            t={t}
            i18nKey="chat.call.callingLabel"
            components={{ dots: <span className={styles.dots} /> }}
          />
        </span>
      )}
    </div>
  )
}
