import cls from 'classnames'

import { ReactComponent as CheckReadIcon } from 'assets/icons/CheckRead.svg'
import { ReactComponent as CheckUnreadIcon } from 'assets/icons/CheckUnread.svg'

import styles from './ChatReadUnreadIcon.module.scss'

type TChatReadUnreadIconProps = {
  read: boolean
  white?: boolean
}

export const ChatReadUnreadIcon = ({ read, white = false }: TChatReadUnreadIconProps) =>
  read ? (
    <CheckReadIcon className={cls({ [styles.icon]: true, [styles.iconWhite]: white })} />
  ) : (
    <CheckUnreadIcon className={cls({ [styles.icon]: true, [styles.iconWhite]: white })} />
  )
