import { useCallback, ChangeEvent } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { Button, PasswordField } from 'App/components'

import styles from './SettingsInfoEnterPasswordStep.module.scss'

export type TSettingsInfoEnterPasswordStepProps = {
  email: string
  onConfirm: (password: string) => void

  onChange?: (password: string) => void
  loading?: boolean
}

type TFormValues = {
  currentPassword?: string
}
const passwordValidation = validation.composeValidators(
  validation.required(),
  validation.isPassword()
)

export const SettingsInfoEnterPasswordStep = ({
  onConfirm,
  email,
  loading,
  onChange
}: TSettingsInfoEnterPasswordStepProps) => {
  const { t } = useTranslation()

  const handleConfirm = useCallback(
    ({ currentPassword }: TFormValues) => {
      if (currentPassword) {
        onConfirm(currentPassword)
      }
    },
    [onConfirm]
  )

  const handleChange = useCallback(
    (input) => (event: ChangeEvent<HTMLInputElement>) => {
      input.onChange(event)

      if (onChange) {
        onChange(event.target.value)
      }
    },
    [onChange]
  )

  return (
    <Form<TFormValues> onSubmit={handleConfirm}>
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={styles.root}>
          <p className={styles.description}>{t('modal.changeEmail.subtitle_confirm')}</p>

          <span className={styles.email}>{email}</span>

          <Field name="currentPassword" validate={passwordValidation}>
            {({ input, meta }) => (
              <PasswordField
                classes={{ topLabel: styles.topLabel }}
                topLabel={t('modal.changeEmail.passwordField')}
                {...input}
                invalid={meta.touched && meta.invalid}
                error={meta.error}
                onChange={handleChange(input)}
              />
            )}
          </Field>

          <Button className={styles.button} loading={loading} type="submit" disabled={invalid}>
            {t('modal.changeEmail.subtitle')}
          </Button>
        </form>
      )}
    </Form>
  )
}
