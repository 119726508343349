import { useCallback, useLayoutEffect, useState } from 'react'
import { Space } from 'antd'
import cls from 'classnames'
import { Swiper } from 'swiper/types'
import isNil from 'lodash/isNil'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { ECardPosition, ECardSize } from 'store'
import { Card, Tooltip, FileNameTruncatedText, Carousel } from 'App/components'
import { TCardContainerProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as ExpandIcon } from 'assets/icons/Expand.svg'
import { ReactComponent as DirectionLeftIcon } from 'assets/icons/DirectionLeft.svg'
import { ReactComponent as DirectionRightIcon } from 'assets/icons/DirectionRight.svg'

import { useSwiper } from '../hooks'

import styles from './CardView.module.scss'

export type TCardViewProps = Pick<
  TCardContainerProps,
  'move' | 'expand' | 'hide' | 'position' | 'allowSize' | 'allowPosition' | 'slides' | 'size'
> &
  NonNullableBy<Pick<TCardContainerProps, 'initialSlideId' | 'type'>, 'initialSlideId' | 'type'>

const POSITION_ICON = new Map<ECardPosition, (props: { onClick: () => void }) => JSX.Element>()
  .set(ECardPosition.LEFT, ({ onClick }) => <DirectionRightIcon onClick={onClick} />)
  .set(ECardPosition.RIGHT, ({ onClick }) => <DirectionLeftIcon onClick={onClick} />)

export const CardView = ({
  position,
  allowSize,
  allowPosition,
  initialSlideId,
  slides,
  size,
  type,
  expand,
  hide,
  move
}: TCardViewProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const [swiper, setSwiper] = useState<Swiper | undefined>()

  const { t } = useTranslation()

  const shouldHandlePosition = allowPosition && size !== ECardSize.FULLSCREEN && isDesktop

  const { elements, currentSlide, currentSlideIndex, rootRef, onSlideChange } = useSwiper({
    slides,
    initialSlideId,
    type
  })

  const handleMove = useCallback(() => {
    move({ position: position === ECardPosition.LEFT ? ECardPosition.RIGHT : ECardPosition.LEFT })
  }, [move, position])

  const handleExpand = useCallback(() => {
    expand({ initialSlideId })
  }, [expand, initialSlideId])

  useLayoutEffect(() => {
    const index = slides.findIndex((file) => file.id === initialSlideId)
    const shouldSlide =
      swiper && !isNil(swiper.activeIndex) && slides[swiper.activeIndex].id !== initialSlideId

    if (initialSlideId && shouldSlide && swiper) {
      swiper.slideTo(index)
    }
  }, [initialSlideId, slides, swiper])

  return (
    <div className={cls(styles.root, shouldHandlePosition && styles[position])} ref={rootRef}>
      <Card
        title={
          <FileNameTruncatedText
            className={styles.title}
            extension={currentSlide.extension ?? ''}
            fileName={currentSlide.fileName ?? ''}
          />
        }
        actions={
          <Space className={styles.actions} size={14}>
            {allowPosition && (
              <Tooltip
                title={t('common.card.positionButtonTooltip', { context: position })}
                trigger={['hover', 'click']}
              >
                {getMapComponent(POSITION_ICON, position, {
                  className: styles.icon,
                  onClick: handleMove
                })}
              </Tooltip>
            )}

            {allowSize && (
              <Tooltip title={t('common.card.expandButtonTooltip')} trigger={['hover', 'click']}>
                <ExpandIcon className={styles.icon} onClick={handleExpand} />
              </Tooltip>
            )}

            <Tooltip title={t('common.card.closeButtonTooltip')}>
              <CrossIcon className={styles.icon} onClick={hide} />
            </Tooltip>
          </Space>
        }
        classes={{
          headerWrapper: styles.headerWrapper,
          header: styles.header
        }}
      >
        <Carousel
          slides={elements}
          initialSlide={currentSlideIndex}
          controlButtonPosition="absolute"
          classes={{ carousel: styles.carousel }}
          onSlideChange={onSlideChange}
          currentSlideIndex={currentSlideIndex}
          onSwiper={setSwiper}
        />
      </Card>
    </div>
  )
}
