import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { HeaderNotificationsBadge } from 'App/components'

export type THeaderNotificationsBadgeContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  count: state.notifications.general.headerNotification ?? 0
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const HeaderNotificationsBadgeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNotificationsBadge)
