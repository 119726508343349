import { Skeleton } from 'antd'

import {
  TUseFileMouseEventsProps,
  useFileMouseEvents,
  useFileContextMenu,
  TMenuItemProps,
  FileImage,
  ContextMenu
} from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { FileNameTruncatedText } from 'App/components/File/FileNameTruncatedText'

import styles from './ChatFileTile.module.scss'

export type TChatFileTileProps = {
  fileId: string
  fileName: string
  extension: string
  loading: boolean
  mimeType: string

  menuItems?: TMenuItemProps[]
  thumbnailUrl?: string | null
} & Partial<Pick<TUseFileMouseEventsProps, 'disableOpen'>> &
  Pick<TUseFileMouseEventsProps, 'openHandlerProps'>

export const ChatFileTile = ({
  fileId,
  fileName,
  extension,
  thumbnailUrl,
  menuItems,
  disableOpen,
  openHandlerProps,
  loading
}: TChatFileTileProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { handleVisibilityChange, visible, ref } = useFileContextMenu()

  const { longPressBind, onClick } = useFileMouseEvents({
    file: { id: fileId },
    openHandlerProps,
    disableOpen,
    disableSelect: true,
    onTouch: handleVisibilityChange
  })

  return (
    <div
      ref={ref}
      className={styles.root}
      {...longPressBind}
      onClick={onClick}
      onContextMenu={isDesktop ? handleVisibilityChange : undefined}
    >
      {loading ? (
        <Skeleton.Image className={styles.skeleton} />
      ) : (
        <>
          {menuItems && (
            <ContextMenu
              disableIcon={true}
              menuItems={menuItems}
              visible={visible}
              startAdornment={
                <FileNameTruncatedText
                  extension={extension}
                  fileName={fileName}
                  className={styles.fileName}
                />
              }
              placement="bottomLeft"
              classes={{ root: styles.contextMenu }}
            />
          )}
          <FileImage
            size="md"
            thumbnailUrl={thumbnailUrl}
            fileName={fileName}
            fileId={fileId}
            extension={extension}
            classes={{ root: styles.image }}
          />
        </>
      )}
    </div>
  )
}
