import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ProducedNotificationsEnum, ProducedOrganizationNotificationsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { startNotificationTracking, stopNotificationTracking } from 'store'

import {
  ESocketEmitEventNamesCommunities,
  ESocketEmitEventNamesEvents
} from '../../services/webSocket'

export type TNotificationTrackerOptions<T = Record<string, unknown>> = {
  channel: string
  onOccurrence: (payload: T | null) => void
  notifications?: Set<
    | ProducedNotificationsEnum
    | ProducedOrganizationNotificationsEnum
    | ESocketEmitEventNamesCommunities
    | ESocketEmitEventNamesEvents
  >
  notificationsFilter?: (payload: any) => boolean
}

export const useNotificationTracker = <T = Record<string, unknown>>(
  opts: TNotificationTrackerOptions<T>
) => {
  const dispatch = useDispatch()
  const { onOccurrence, notifications, notificationsFilter, channel } = opts
  const {
    location: { pathname }
  } = useHistory()

  const isChanged = useSelector(
    (state: State) => state.trackedNotifications[channel]?.isChanged ?? null
  )
  const payload = useSelector((state: State) => state.trackedNotifications[channel]?.payload as T)

  const onOccurrenceRef = useRef(onOccurrence)

  useEffect(() => {
    onOccurrenceRef.current = onOccurrence
  }, [onOccurrence])

  useEffect(() => {
    if (notifications) {
      dispatch(startNotificationTracking({ channel, notifications, filter: notificationsFilter }))
    }

    return () => {
      dispatch(stopNotificationTracking({ channel }))
    }
  }, [pathname, notifications, dispatch, channel])

  useEffect(() => {
    if (isChanged !== null) {
      onOccurrenceRef.current(payload)
    }
  }, [isChanged])
}
