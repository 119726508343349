import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { TChipInputItemValue } from 'App/components/common/ChipInput/ChipInputItem'

import { InviteByEmail } from '../Invitations'
import { EModalComponents } from '../ModalRoot'
import { getExternalMeetingLink, sendExternalMeetingLinkToEmails } from '../../../api'

import { useExternalMeeting } from './useExternalMeeting'

export const InviteToExternalMeetingModal = () => {
  const dispatch = useAppDispatch()

  const { meetingId, MeetingLink } = useExternalMeeting()
  const accountEmail = useAppSelector((state) => state.global.accountData?.email)

  const link = meetingId ? getExternalMeetingLink(meetingId) : ''

  const { t } = useTranslation()

  const { mutateAsync } = useMutation({
    mutationKey: ['send-external-meeting-link-to-emails', meetingId],
    mutationFn: (emails: string[]) =>
      sendExternalMeetingLinkToEmails({ emails, id: meetingId || '' }),
    onSuccess: () => {
      if (link) {
        window.open(link, '_blank')
      }

      toast.success(
        t('modal.inviteByEmail.invitationSentSuccessToast_meeting'),
        toastDefaultOptions
      )
    }
  })

  const defaultEmails: TChipInputItemValue[] = accountEmail
    ? [{ text: accountEmail, removable: false, editable: false }]
    : []

  return (
    <InviteByEmail
      autoFocus={true}
      defaultEmails={defaultEmails}
      onConfirm={mutateAsync}
      onBack={() =>
        dispatch(
          showModalAction({
            modalTitle: t('modal.meetings.title'),
            modalType: EModalComponents.MEETINGS
          })
        )
      }
      confirmLabel={t('modal.inviteByEmail.submitButton_meeting')}
      disabled={!meetingId}
      content={<MeetingLink />}
    />
  )
}
