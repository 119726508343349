import { useCallback } from 'react'

import { getCssPropertyValue } from 'utils'

export type TSetShadowProperty = {
  shadowSize: number
  /**
   * @shadowColor or @cssRootPropertyValue required
   */
  shadowColor?: string
  /**
   * @cssRootPropertyValue or @shadowColor required
   */
  cssRootPropertyValue?: string
  hasBorder?: boolean
}

const getBoxShadowProperty = (size: number, color = '#FFFFFF') => `0 0 0 ${size}px ${color}`

/**
 * @cssRootPropertyValue or @shadowColor required
 */
export const useSetShadow = ({
  shadowSize,
  shadowColor,
  cssRootPropertyValue,
  hasBorder = true
}: TSetShadowProperty): ((node: HTMLElement | null) => void) => {
  const getColor = useCallback(() => {
    if (shadowColor) {
      return shadowColor
    }

    if (!shadowColor && cssRootPropertyValue && cssRootPropertyValue.includes('--')) {
      return getCssPropertyValue(cssRootPropertyValue)
    }

    return '#FFFFFF'
  }, [shadowColor, cssRootPropertyValue])

  const ref = useCallback(
    (node: HTMLElement | null) => {
      if (node && hasBorder) {
        node.style.boxShadow = getBoxShadowProperty(shadowSize, getColor())
      }
    },
    [getColor, hasBorder, shadowSize]
  )

  return ref
}
