import { ContactTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import styles from './CaseMembersItemInvite.module.scss'

export type TCaseMembersItemInviteProps = {
  accountId: string
  onSuccess: () => void

  contactStatus?: ContactTypeEnum | null
  contactId?: string | null
  caseId?: string
}

type TInviteMapProps = Omit<TCaseMembersItemInviteProps, 'contactStatus'>

const INVITE_MAP = new Map<
  ContactTypeEnum | null,
  ({ contactId }: TInviteMapProps) => JSX.Element | null
>()
  .set(ContactTypeEnum.APPROVED, () => null)
  .set(ContactTypeEnum.RECEIVED, ({ contactId, onSuccess, accountId }) => {
    const { t } = useTranslation()

    const { rejectContactInvitation, acceptContactInvitation } = useContactInvitation()

    const handleRejectClick = () => {
      contactId && rejectContactInvitation({ contactId, onSuccess })
    }

    const handleAcceptInvite = () => {
      accountId && acceptContactInvitation({ accountId, onSuccess })
    }

    return (
      <>
        <span>{t('cases.details.membersSection.contactInvitation.placeholder_received')}</span>
        <div className={styles.buttonWrapper}>
          <span onClick={handleAcceptInvite} className={cls(styles.button, styles.secondary)}>
            {t('invitations.acceptButton')}
          </span>
          <span onClick={handleRejectClick} className={cls(styles.button, styles.error)}>
            {t('invitations.rejectButton')}
          </span>
        </div>
      </>
    )
  })
  .set(ContactTypeEnum.SENT, ({ contactId, onSuccess }) => {
    const { t } = useTranslation()

    const { withdrawContactInvitation } = useContactInvitation()

    const handleRejectClick = () => {
      contactId && withdrawContactInvitation({ contactId, onSuccess })
    }

    return (
      <>
        <span>{t('cases.details.membersSection.contactInvitation.placeholder_send')}</span>
        <span onClick={handleRejectClick} className={cls(styles.button, styles.warning)}>
          {t('invitations.withdrawButton')}
        </span>
      </>
    )
  })
  .set(null, ({ onSuccess, caseId, accountId }) => {
    const { t } = useTranslation()

    const { inviteToContacts } = useContactInvitation()

    const handleInviteClick = () => {
      caseId && inviteToContacts({ accountId, caseId, onSuccess })
    }

    return (
      <>
        <span>{t('cases.details.membersSection.contactInvitation.placeholder')}</span>
        <span onClick={handleInviteClick} className={cls(styles.button, styles.secondary)}>
          {t('invitations.inviteButton')}
        </span>
      </>
    )
  })

export const CaseMembersItemInvite = ({
  contactStatus,
  contactId,
  onSuccess,
  accountId,
  caseId
}: TCaseMembersItemInviteProps) => (
  <div className={styles.wrapper}>
    {getMapComponent(INVITE_MAP, contactStatus, {
      contactId,
      onSuccess,
      accountId,
      caseId
    })}
  </div>
)
