import {
  ProducedNotificationsEnum,
  ProducedOrganizationNotificationsEnum,
  ShowCaseNotificationTypeEnum
} from '@medentee/enums'

import {
  receiveOffline,
  receiveOnline,
  receiveAdminOffline,
  receiveAdminOnline
} from 'store/online'
import { connectWebSocketAction, disconnectWebSocketAction } from 'store/store.actions'
import { logoutWSAction } from 'store/logout'
import {
  receiveNewMessageRequest,
  readMessagesSuccess,
  receiveMessageEditingEndSuccess,
  receiveMessageEditingStartSuccess,
  receiveDeleteMessagePermanentlySuccess,
  loseAccessToFile,
  receiveChatIntermediateStateAction,
  discardFilePermission,
  receiveAddMessageReactionRequest,
  receiveRemoveMessageReactionRequest
} from 'store/chatMessages'
import {
  receiveAllowCall,
  receiveEndCall,
  receiveMissedCall,
  receiveCreateCallAction,
  receiveAcceptedMyselfCall,
  receiveExistCallsAction,
  receiveCallCountsForWidget
} from 'store/calls'
import { caseMemberStatusChangedRequest } from 'store/caseMembers'
import {
  caseStatusChangedRequest,
  receivePublicOpinionsChanged,
  caseMemberKickedAction,
  receiveCaseOpinionCreated
} from 'store/caseView'
import {
  getNotificationsGeneralRequest,
  receiveNewMessageNotificationsRequest,
  removeContactReceive,
  receiveNewOrganizationNotifications,
  receiveNewCommunityNotifications,
  receiveNewEventNotifications
} from 'store/notifications'
import { loseAccessToCaseFileRequest } from 'store/caseCloud'
import { switchAccountEvent, updateRelatedAccounts } from 'store/userProfile'
import { acceptContactInviteSuccess } from 'store/contacts'
import {
  receiveAddedUserToChat,
  receiveChatMuted,
  receiveChatUnmuted,
  receiveLostAccessToTextChannel,
  receiveLostManagerInTextChannel,
  receiveDelegatedManagerInTextChannel,
  receiveTypingDone,
  receiveTyping,
  receiveChangeChatStatusAction
} from 'store/chatRooms'
import { adminLostAccessAction, receiveAcceptedSuperAdminInvitation } from 'store/administration'
import { receiveSystemIntermediateStateAction } from 'store/system'
import { updateVideoStreamingItem } from 'store/videoStreaming'
import {
  kickUserFromVoiceRoom,
  receiveJoinVoiceRoom,
  receiveLeaveVoiceRoom,
  receiveLostAccessToVoiceChannel,
  receiveDelegatedLeaderRightsInDepartments,
  receiveLostLeaderRightsInDepartments,
  receiveRemoveFromAdmin,
  updateVoiceRoomMembers,
  receiveAddedUserToVoiceChannel
} from 'store/organizations'
import {
  receivePaymentSucceeded,
  receivePaymentFailed,
  autoTopUpSet,
  autoTopUpCanceled
} from 'store/payments'
import { receiveLostAccessToCommunity } from 'store/communities'
import {
  receiveBroadcastCreated,
  receiveBroadcastDeleted,
  receiveBroadcastWatched,
  receiveUpdateBroadcastStatus,
  receiveUpdateBroadcastWatchers
} from 'store/broadcasts'
import { pinnedMessage, unPinnedMessage } from 'store/chatPin'
import { receiveLostAccessToEvent } from 'store/events'

import {
  ESocketEventNames,
  ESocketEventNamesBroadcasts,
  ESocketEventNamesCall,
  ESocketEventNamesChat,
  ESocketEventNamesCommunities,
  ESocketEventNamesEvents,
  ESocketEventNamesSystem
} from './webSocket.enums'

export enum ESocketNameSpaces {
  DEFAULT = 'default',
  CALL = 'call',
  NOTIFICATIONS = 'notifications',
  CHAT = 'chat',
  SYSTEM = 'system',
  ORGANIZATIONS = 'organizations',
  SHOWCASES = 'showcase-notifications',
  COMMUNITIES = 'communities',
  EVENTS = 'events',
  BROADCASTS = 'broadcasts'
}

export const socketNameSpaces: ESocketNameSpaces[] = Object.values(ESocketNameSpaces)

export const socketEventNamesMap = {
  [ESocketNameSpaces.DEFAULT]: ESocketEventNames,
  [ESocketNameSpaces.CALL]: ESocketEventNamesCall,
  [ESocketNameSpaces.NOTIFICATIONS]: ProducedNotificationsEnum,
  [ESocketNameSpaces.SYSTEM]: ESocketEventNamesSystem,
  [ESocketNameSpaces.CHAT]: ESocketEventNamesChat,
  [ESocketNameSpaces.ORGANIZATIONS]: ProducedOrganizationNotificationsEnum,
  [ESocketNameSpaces.SHOWCASES]: ShowCaseNotificationTypeEnum,
  [ESocketNameSpaces.COMMUNITIES]: ESocketEventNamesCommunities,
  [ESocketNameSpaces.EVENTS]: ESocketEventNamesEvents,
  [ESocketNameSpaces.BROADCASTS]: ESocketEventNamesBroadcasts
}

export const socketSubscribesEventsActionsMap: { [key in ESocketNameSpaces]: any } = {
  /*
   INFO: Please import each action from its essence here is an example:
   logout actions from 'store/logout'

   Like this: import { logoutWSAction } from 'store/logout'
  */

  [ESocketNameSpaces.DEFAULT]: {
    [ESocketEventNames.INVITE_APPROVED]: acceptContactInviteSuccess,
    [ESocketEventNames.CONNECT]: connectWebSocketAction,
    [ESocketEventNames.DISCONNECT]: disconnectWebSocketAction
  },
  [ESocketNameSpaces.SYSTEM]: {
    [ESocketEventNamesSystem.ACCOUNT_SWITCHED]: switchAccountEvent,
    [ESocketEventNamesSystem.OFFLINE]: receiveOffline,
    [ESocketEventNamesSystem.ONLINE]: receiveOnline,
    [ESocketEventNamesSystem.LOGOUT]: logoutWSAction,
    [ESocketEventNamesSystem.ADMIN_LOST_ACCESS]: adminLostAccessAction,
    [ESocketEventNamesSystem.INTERMEDIATE_STATE]: receiveSystemIntermediateStateAction,
    [ESocketEventNamesSystem.ONLINE_ADMIN]: receiveAdminOnline,
    [ESocketEventNamesSystem.OFFLINE_ADMIN]: receiveAdminOffline,
    [ESocketEventNamesSystem.REFRESH_GENERAL_NOTIFICATIONS]: getNotificationsGeneralRequest,
    [ESocketEventNamesSystem.VIDEO_QUALITY_UPDATED]: updateVideoStreamingItem,
    [ESocketEventNamesSystem.PAYMENT_FAILED]: receivePaymentFailed,
    [ESocketEventNamesSystem.UPDATE_RELATED_ACCOUNTS]: updateRelatedAccounts
  },
  [ESocketNameSpaces.CHAT]: {
    [ESocketEventNamesChat.RECEIVE_NEW_MESSAGE]: receiveNewMessageRequest,
    [ESocketEventNamesChat.RECEIVE_NEW_MESSAGE_NOTIFICATIONS]:
      receiveNewMessageNotificationsRequest,
    [ESocketEventNamesChat.TYPING_STARTED]: receiveTyping,
    [ESocketEventNamesChat.TYPING_ENDED]: receiveTypingDone,
    [ESocketEventNamesChat.READ_MESSAGE]: readMessagesSuccess,
    [ESocketEventNamesChat.EDITING_STARTED]: receiveMessageEditingStartSuccess,
    [ESocketEventNamesChat.EDITING_ENDED]: receiveMessageEditingEndSuccess,
    [ESocketEventNamesChat.REMOVE_MESSAGE]: receiveDeleteMessagePermanentlySuccess,
    [ESocketEventNamesChat.INTERMEDIATE_STATE]: receiveChatIntermediateStateAction,
    [ESocketEventNamesChat.CHAT_STATUS_CHANGED]: receiveChangeChatStatusAction,
    [ESocketEventNamesChat.CHAT_MUTED]: receiveChatMuted,
    [ESocketEventNamesChat.CHAT_UNMUTED]: receiveChatUnmuted,
    [ESocketEventNamesChat.ADDED_USER_TO_CHAT]: receiveAddedUserToChat,
    [ESocketEventNamesChat.REMOVED_USER_FROM_CHAT]: receiveLostAccessToTextChannel,
    [ESocketEventNamesChat.DELEGATED_MANAGER_IN_TEXT_CHANNEL]: receiveDelegatedManagerInTextChannel,
    [ESocketEventNamesChat.LOST_MANAGER_IN_TEXT_CHANNEL]: receiveLostManagerInTextChannel,
    [ESocketEventNamesChat.ADD_MESSAGE_REACTION]: receiveAddMessageReactionRequest,
    [ESocketEventNamesChat.REMOVE_MESSAGE_REACTION]: receiveRemoveMessageReactionRequest,
    [ESocketEventNamesChat.PINNED_MESSAGE]: pinnedMessage,
    [ESocketEventNamesChat.UNPINNED_MESSAGE]: unPinnedMessage
  },
  [ESocketNameSpaces.CALL]: {
    [ESocketEventNamesCall.CREATE_CALL]: receiveCreateCallAction,
    [ESocketEventNamesCall.ALLOW_CALL]: receiveAllowCall,
    [ESocketEventNamesCall.END_CALL]: receiveEndCall,
    [ESocketEventNamesCall.MISSED_CALL]: receiveMissedCall,
    [ESocketEventNamesCall.CALL_ACCEPTED_MYSELF]: receiveAcceptedMyselfCall,
    [ESocketEventNamesCall.EXIST_CALLS]: receiveExistCallsAction,
    [ESocketEventNamesCall.CALL_COUNTS_FOR_WIDGET]: receiveCallCountsForWidget,
    [ESocketEventNamesCall.JOIN_VOICE_ROOM]: receiveJoinVoiceRoom,
    [ESocketEventNamesCall.LEAVE_VOICE_ROOM]: receiveLeaveVoiceRoom,
    [ESocketEventNamesCall.UPDATE_VOICE_ROOM_MEMBERS]: updateVoiceRoomMembers,
    [ESocketEventNamesCall.KICK_USER_FROM_VOICE_ROOM]: kickUserFromVoiceRoom
  },
  [ESocketNameSpaces.NOTIFICATIONS]: {
    [ProducedNotificationsEnum.PUBLIC_OPINIONS_CHANGED]: receivePublicOpinionsChanged,
    [ProducedNotificationsEnum.ACCESS_TO_FILE_LOST]: loseAccessToFile,
    [ProducedNotificationsEnum.CASE_MEMBER_STATUS_CHANGED]: caseMemberStatusChangedRequest,
    [ProducedNotificationsEnum.CASE_STATUS_CHANGED]: caseStatusChangedRequest,
    [ProducedNotificationsEnum.ACCESS_TO_CASE_FILE_LOST]: loseAccessToCaseFileRequest,
    [ProducedNotificationsEnum.CASE_MEMBER_KICKED]: caseMemberKickedAction,
    [ProducedNotificationsEnum.CASE_OPINION_CREATED]: receiveCaseOpinionCreated,
    [ProducedNotificationsEnum.ACCEPTED_SUPERADMIN_INVITATION]: receiveAcceptedSuperAdminInvitation,
    [ProducedNotificationsEnum.REMOVED_CONTACT]: removeContactReceive,
    [ProducedNotificationsEnum.DISCARDED_FILE_PERMISSION]: discardFilePermission,
    [ProducedNotificationsEnum.RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS]:
      receiveNewOrganizationNotifications,
    [ProducedNotificationsEnum.RECEIVE_NEW_COMMUNITY_NOTIFICATIONS]:
      receiveNewCommunityNotifications,
    [ProducedNotificationsEnum.RECEIVE_NEW_EVENT_NOTIFICATIONS]: receiveNewEventNotifications,
    [ProducedNotificationsEnum.REMOVED_FROM_ADMIN]: receiveRemoveFromAdmin,
    [ProducedNotificationsEnum.TOP_UP_CHARGED]: receivePaymentSucceeded,
    [ProducedNotificationsEnum.AUTO_TOP_UP_SET]: autoTopUpSet,
    [ProducedNotificationsEnum.AUTO_TOP_UP_CANCELED]: autoTopUpCanceled
  },
  [ESocketNameSpaces.ORGANIZATIONS]: {
    [ProducedOrganizationNotificationsEnum.DELEGATED_LEADER_RIGHTS_IN_DEPARTMENTS]:
      receiveDelegatedLeaderRightsInDepartments,
    [ProducedOrganizationNotificationsEnum.LOST_LEADER_RIGHTS_IN_DEPARTMENTS]:
      receiveLostLeaderRightsInDepartments,
    [ProducedOrganizationNotificationsEnum.ADDED_USER_TO_VOICE_CHANNEL]:
      receiveAddedUserToVoiceChannel,
    [ProducedOrganizationNotificationsEnum.LOST_ACCESS_TO_VOICE_CHANNEL]:
      receiveLostAccessToVoiceChannel
  },
  [ESocketNameSpaces.SHOWCASES]: {},
  [ESocketNameSpaces.COMMUNITIES]: {
    [ESocketEventNamesCommunities.LOST_ACCESS_TO_COMMUNITY]: receiveLostAccessToCommunity
  },
  [ESocketNameSpaces.EVENTS]: {
    [ESocketEventNamesEvents.LOST_ACCESS_TO_EVENT]: receiveLostAccessToEvent
  },
  [ESocketNameSpaces.BROADCASTS]: {
    [ESocketEventNamesBroadcasts.BROADCAST_CREATED]: receiveBroadcastCreated,
    [ESocketEventNamesBroadcasts.BROADCAST_WATCHED]: receiveBroadcastWatched,
    [ESocketEventNamesBroadcasts.BROADCAST_DELETED]: receiveBroadcastDeleted,
    [ESocketEventNamesBroadcasts.UPDATE_BROADCAST_WATCHERS]: receiveUpdateBroadcastWatchers,
    [ESocketEventNamesBroadcasts.UPDATE_BROADCAST_STATUS]: receiveUpdateBroadcastStatus
  }
}
