import { components, GroupBase, NoticeProps } from 'react-select'
import { Skeleton } from 'antd'

import styles from './LoadingMessage.module.scss'

export const LoadingMessage = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: NoticeProps<Option, IsMulti, Group>
) => (
  <components.LoadingMessage {...props}>
    <Skeleton
      loading={true}
      active={true}
      title={false}
      avatar={false}
      paragraph={{ rows: 7, width: '100%', className: styles.root }}
    />
  </components.LoadingMessage>
)
