import { components, MultiValueRemoveProps, GroupBase } from 'react-select'

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './MultiValueRemove.module.scss'

export const MultiValueRemove = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>
) => (
  <components.MultiValueRemove {...props}>
    <CrossIcon className={styles.icon} />
  </components.MultiValueRemove>
)
