import { memo, useMemo } from 'react'
import { ListChildComponentProps, areEqual } from 'react-window'
import cls from 'classnames'
import { ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'

import { TChatBaseMessage } from 'store'
import { Avatar, TAvatarProps } from 'App/components/common/Avatar'
import { EAvatarSize } from 'enums'
import { FormattedText, TruncatedText } from 'App/components/common'
import { getSearchedMessageTime } from 'utils/chat'

import styles from './ChatRoomSearchResultsItem.module.scss'

export type TChatRoomSearchResultsItem = Pick<TAvatarProps, 'chatType'> & {
  data: TChatBaseMessage[]
  currentIndex: number | null
  onClick: (messageId: string, index: number) => void
}

type TChatRoomSearchResultsItemProps = Pick<ListChildComponentProps, 'style' | 'index'> & {
  data: TChatRoomSearchResultsItem
}

const ChatRoomSearchResultsItemView = ({ data, index, style }: TChatRoomSearchResultsItemProps) => {
  const { data: items, currentIndex, chatType, onClick } = data
  const {
    id,
    createdAt,
    sender,
    type,
    file,
    meeting,
    message,
    mentionedAccounts,
    mentionedDepartments
  } = items[index] ?? {}
  const active = currentIndex === index
  const isNewsChannel =
    chatType === ChatTypeEnum.COMMUNITY_NEWS || chatType === ChatTypeEnum.EVENT_NEWS

  const text = useMemo(() => {
    if (type === MessageTypeEnum.FILE) {
      return file?.fileName
    }

    if (type === MessageTypeEnum.MEETING) {
      return meeting?.title
    }

    return message as string
  }, [file?.fileName, meeting?.title, message, type])

  const time = useMemo(() => getSearchedMessageTime(createdAt), [createdAt])

  return (
    <div
      style={style}
      className={cls(styles.root, { [styles.active]: active })}
      onClick={() => onClick(id, index)}
    >
      {!isNewsChannel && (
        <Avatar
          userId={sender?.id}
          displayUserName={sender?.displayUserName}
          lastName={sender?.lastName}
          firstName={sender?.firstName}
          type={sender?.type?.name}
          size={EAvatarSize.XS}
          hasTooltip={false}
        />
      )}
      <div className={styles.content}>
        {!isNewsChannel && (
          <TruncatedText text={sender.displayUserName} classes={{ text: styles.senderName }} />
        )}
        <TruncatedText
          text={
            <FormattedText
              text={text}
              mentions={{
                accounts: mentionedAccounts,
                departments: mentionedDepartments,
                clickable: false
              }}
              formatters={['emoji', 'mention']}
              classes={{ mention: styles.mention }}
            />
          }
        />
      </div>
      <div className={styles.time}>{time}</div>
    </div>
  )
}

export const ChatRoomSearchResultsItem = memo(ChatRoomSearchResultsItemView, areEqual)
