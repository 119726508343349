import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store/errors'
import {
  FILE_DISCARD_PERMISSION_FROM_CHAT,
  fileDiscardPermissionFromChatRequest
} from 'store/chatMessages'
import {
  FileDiscardPermissionsConfirm,
  FileDiscardPermissionsConfirmOwnProps
} from 'App/components/File/FileDiscardPermissionsConfirm'

const processingSelector = createProcessingSelector([FILE_DISCARD_PERMISSION_FROM_CHAT])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[FILE_DISCARD_PERMISSION_FROM_CHAT]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      discardFilePermissions: fileDiscardPermissionFromChatRequest,
      removeErrorNotification: () => removeErrorNotification(FILE_DISCARD_PERMISSION_FROM_CHAT)
    },
    dispatch
  )

export const ChatDiscardPermissionConfirmContainer: FC<FileDiscardPermissionsConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(FileDiscardPermissionsConfirm)
