import { useTranslation, Trans } from 'react-i18next'

import { Button } from 'App/components/common/Button'
import {
  useAppSelector,
  ACCEPT_CONTACT_INVITE,
  REMOVE_CONTACT_INVITE,
  createProcessingBySelector
} from 'store'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import styles from './UnityHubNetworkingDrawerHeader.module.scss'

export type TUnityHubNetworkingDrawerHeaderProps = {
  userName: string
  contactId: string
  memberId: string
  onSuccess: () => void
}

export const UnityHubNetworkingDrawerHeader = ({
  userName,
  contactId,
  memberId,
  onSuccess
}: TUnityHubNetworkingDrawerHeaderProps) => {
  const { t } = useTranslation()

  const { acceptContactInvitation, rejectContactInvitation } = useContactInvitation()

  const acceptProcessing = useAppSelector(
    createProcessingBySelector([ACCEPT_CONTACT_INVITE])(memberId)
  )
  const rejectProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_CONTACT_INVITE])(contactId)
  )

  const handleApprove = () => {
    acceptContactInvitation({
      accountId: memberId,
      onSuccess
    })
  }

  const handleReject = () => {
    rejectContactInvitation({ contactId, onSuccess })
  }

  return (
    <div className={styles.root}>
      <div>
        <Trans
          t={t}
          i18nKey="unityHub.networking.member.drawer.header"
          components={{ span: <span className={styles.name} /> }}
          values={{ name: userName }}
        />
      </div>
      <div className={styles.actions}>
        <Button
          size="sm"
          disabled={acceptProcessing}
          loading={acceptProcessing}
          onClick={handleApprove}
        >
          {t('invitations.approveButton')}
        </Button>
        <Button
          size="sm"
          variant="outlined"
          disabled={rejectProcessing}
          loading={rejectProcessing}
          onClick={handleReject}
        >
          {t('invitations.rejectButton')}
        </Button>
      </div>
    </div>
  )
}
