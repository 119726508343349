import { useMemo } from 'react'

import { useAppSelector } from 'store'
import { UnityHubNav, TUnityHubNavProps } from 'App/components/UnityHub'

type TCommunitiesNavProps = Pick<TUnityHubNavProps, 'loading'>

export const CommunitiesNav = ({ loading }: TCommunitiesNavProps) => {
  const { list, ids } = useAppSelector((state) => state.communities.data)
  const notifications = useAppSelector((state) => state.notifications.general.communities.list)

  const items = useMemo(
    () =>
      ids.map((id) => ({
        id,
        to: `/communities/${id}`,
        name: list[id]?.title,
        live: list[id]?.isLiveBroadcast,
        count: notifications?.[id]?.totalUnreadCount
      })),
    [ids, list, notifications]
  )

  if (!loading && !ids.length) {
    return null
  }

  return <UnityHubNav type="community" items={items} loading={loading} />
}
