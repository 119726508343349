import { useCallback, useMemo } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { profileNestedRoutes } from 'App/App.config'
import { TUserProfileContainerProps } from 'App/containers'
import { TNavMenuItem, PreviewLink } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { SettingsPageLayout } from 'App/components/common/SettingsPageLayout'
import { getMapComponent } from 'utils'

import styles from './UserProfile.module.scss'

const MENU_ITEMS_MAP = new Map<AccountTypeNames, (t: TFunction) => TNavMenuItem[]>()
  .set(AccountTypeNames.PROFESSIONAL, (t) => [
    {
      link: '/profile/edit/basic',
      title: t('userProfile.tabs.basicInformation')
    },
    {
      link: '/profile/edit/professions',
      title: t('userProfile.tabs.professionsAndSpecializations')
    },
    {
      link: '/profile/edit/web',
      title: t('userProfile.tabs.onTheWeb')
    },
    {
      link: '/profile/edit/work-experience',
      title: t('userProfile.tabs.workExperience')
    },
    {
      link: '/profile/edit/education',
      title: t('userProfile.tabs.education')
    },
    {
      link: '/profile/edit/licenses',
      title: t('userProfile.tabs.licenses')
    },
    {
      link: '/profile/edit/publications',
      title: t('userProfile.tabs.publicationsAndPresentations')
    }
  ])
  .set(AccountTypeNames.BUSINESS, (t) => [
    {
      link: '/profile/edit/basic',
      title: t('userProfile.tabs.basicInformation')
    },
    {
      link: '/profile/edit/professions',
      title: t('userProfile.tabs.companyType')
    },
    {
      link: '/profile/edit/web',
      title: t('userProfile.tabs.onTheWeb')
    }
  ])

const DEFAULT_PATH = '/profile/edit/basic'

export const UserProfile = ({ accountType }: TUserProfileContainerProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const menuList = useMemo(
    () => getMapComponent(MENU_ITEMS_MAP, accountType, t) ?? [],
    [accountType, t]
  )

  const showPreviewLink = !isDesktop

  const getPreviewLink = useCallback(
    () => (
      <PreviewLink
        link="/profile/preview"
        label={t('userProfile.profilePreview.previewProfileButton')}
      />
    ),
    [t]
  )

  return (
    <SettingsPageLayout
      menu={{
        menuList,
        endAdornment: isDesktop ? getPreviewLink() : undefined,
        classes: { root: styles.menu }
      }}
      defaultPath={DEFAULT_PATH}
      nestedRoutes={profileNestedRoutes}
      contentPrefix={showPreviewLink && getPreviewLink()}
      paper={true}
      classes={{ content: styles.content }}
    />
  )
}
