import { PropsWithChildren, ReactNode } from 'react'
import { Skeleton } from 'antd'
import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'

import { EAvatarSize } from 'enums'
import {
  Avatar,
  Button,
  TButtonProps,
  ContextMenu,
  TMenuItemProps,
  TruncatedText
} from 'App/components/common'

import styles from './UnityHubHeaderLayout.module.scss'

export type TUnityHubHeaderLayoutAction = {
  key: string
  text: ReactNode
} & Omit<TButtonProps, 'children' | 'variant' | 'color' | 'size'>

export type TUnityHubHeaderLayoutProps = PropsWithChildren<{
  name: string
  loading: boolean
  id: string

  showKebab?: boolean
  showAvatar?: boolean
  type?: 'community' | 'organization' | 'event'
  menuItems?: TMenuItemProps[]
  actions?: TUnityHubHeaderLayoutAction[]
}>

export const UnityHubHeaderLayout = ({
  id,
  name,
  loading,
  children,
  actions,
  type = 'organization',
  showAvatar = false,
  showKebab = false,
  menuItems = []
}: TUnityHubHeaderLayoutProps) => {
  const shouldShowKebab = Boolean(menuItems.length) && showKebab

  return (
    <div className={styles.root}>
      {id &&
        showAvatar &&
        (loading ? (
          <Skeleton.Avatar size={50} />
        ) : (
          <div className={styles.avatar}>
            <Avatar
              type={AccountTypeNames.BUSINESS}
              size={EAvatarSize.LMD}
              userId={id}
              firstName={''}
              lastName={''}
              displayUserName={name}
              isCommunity={type === 'community'}
            />
          </div>
        ))}
      <div className={styles.content}>
        {loading ? (
          <Skeleton paragraph title={false} />
        ) : (
          <>
            <TruncatedText rows="two" text={name} classes={{ text: styles.name }} />

            {actions
              ?.filter(({ hidden }) => !hidden)
              .map(({ key, text, className, icon, onClick }) => (
                <Button
                  key={key}
                  variant="inline"
                  className={cls(styles.infoButton, className)}
                  onClick={onClick}
                  icon={icon}
                >
                  {text}
                </Button>
              ))}
          </>
        )}
      </div>

      {shouldShowKebab && (
        <ContextMenu classes={{ icon: styles.contextMenu }} menuItems={menuItems} />
      )}
      {children}
    </div>
  )
}
