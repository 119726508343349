import { useCallback, useState } from 'react'
import { Form } from 'react-final-form'
import { BroadcastTypeEnum } from '@medentee/enums'
import isEqual from 'lodash/isEqual'

import { getMapComponent } from 'utils'
import { useSteps } from 'App/hooks'
import { useAppSelector } from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { CreateBroadcastMainStep, TCreateBroadcastMainStepProps } from './CreateBroadcastMainStep'
import {
  CreateBroadcastMembersStep,
  TCreateBroadcastMembersStepProps
} from './CreateBroadcastMembersStep'
import styles from './BroadcastCreateDialog.module.scss'
import { useBroadcastMembersRequest } from './useBroadcastMembersRequest'
import { useBroadcastCreateDialogRequests } from './useBroadcastCreateDialogRequests'

export type TBroadcastCreateDialogFormValues = {
  name: string
  url: string
  isPublic: boolean
}

export type TBroadcastCreateDialogProps = {
  entityId: string
  type: BroadcastTypeEnum

  broadcastId?: string
}

type TBroadcastCreateDialogStepsProps = TCreateBroadcastMainStepProps &
  TCreateBroadcastMembersStepProps & {
    onOpenMainStep: () => void
  }

enum steps {
  MAIN = 0,
  MEMBERS = 1
}

const STEPS = new Map<steps, (props: TBroadcastCreateDialogStepsProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({ selectedAccounts, error, isEdit, isAccountFieldDirty, onSubmit, onOpenMembersStep }) => (
      <CreateBroadcastMainStep
        isEdit={isEdit}
        error={error}
        selectedAccounts={selectedAccounts}
        isAccountFieldDirty={isAccountFieldDirty}
        onSubmit={onSubmit}
        onOpenMembersStep={onOpenMembersStep}
      />
    )
  )
  .set(
    steps.MEMBERS,
    ({
      entityId,
      type,
      selectedAccounts,
      onOpenMainStep,
      onSelectedAccountsChange: setSelectedAccounts
    }) => (
      <CreateBroadcastMembersStep
        type={type}
        entityId={entityId}
        selectedAccounts={selectedAccounts}
        onOpenMainStep={onOpenMainStep}
        onSelectedAccountsChange={setSelectedAccounts}
      />
    )
  )

export const BroadcastCreateDialog = ({
  entityId,
  type,
  broadcastId
}: TBroadcastCreateDialogProps) => {
  const [selectedAccounts, setSelectedAccounts] = useState<TAccountsSelectOptionData[]>([])
  const [isAccountFieldDirty, setIsAccountFieldDirty] = useState(false)

  const { step, setNextStep, setPrevStep } = useSteps(steps.MAIN)

  const broadcast = useAppSelector((state) =>
    broadcastId ? state.broadcasts.data.list[broadcastId] : { isPublic: false }
  )

  const isEdit = Boolean(broadcastId)

  const { data } = useBroadcastMembersRequest({ broadcastId, setSelectedAccounts })

  const initialValues = {
    ...broadcast,
    accounts: data
  }

  const { error, handleFormSubmit } = useBroadcastCreateDialogRequests({
    isEdit,
    entityId,
    type,
    broadcastId,
    selectedAccounts
  })

  const handleChangeSelectedAccount = useCallback((accounts: TAccountsSelectOptionData[]) => {
    setSelectedAccounts((prev) => {
      const dirty = !isEqual(prev, accounts)

      setIsAccountFieldDirty(dirty)

      return accounts
    })
  }, [])

  return (
    <div className={styles.root}>
      <Form onSubmit={handleFormSubmit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <>
            {getMapComponent(STEPS, step, {
              isEdit,
              error,
              type,
              entityId,
              selectedAccounts,
              isAccountFieldDirty,
              onSelectedAccountsChange: handleChangeSelectedAccount,
              onSubmit: handleSubmit,
              onOpenMembersStep: setNextStep,
              onOpenMainStep: setPrevStep
            })}
          </>
        )}
      </Form>
    </div>
  )
}
