import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getEducationRequest,
  GET_EDUCATION,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as SchoolIcon } from 'assets/icons/School.svg'

import { List } from '../../common'
import { EducationItemContainer } from '../EducationItem'
import { UserProfileEducationButton } from '../../UserProfileEducation'

const loadingSelector = createLoadingSelector([GET_EDUCATION])

export const EducationList = () => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) => state.userProfile.viewProfile.education.ids)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getEducationRequest())
  }, [dispatch])

  return (
    <List
      loading={loading}
      ids={ids}
      item={EducationItemContainer}
      footer={<UserProfileEducationButton />}
      emptyIcon={<SchoolIcon />}
      emptyText={t('userProfile.education.placeholder')}
    />
  )
}
