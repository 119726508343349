import { useCallback, useEffect, useRef, useState } from 'react'
import { differenceInMilliseconds } from 'date-fns'

import { useMutation, useQuery } from 'services/query'
import {
  uploadFile,
  TUploadFilePayload,
  getShowcasePresignUrl,
  confirmUpload,
  cancelUpload,
  continueUpload
} from 'api/showcase'
import { IFilesEntity, IPreSignUrl } from 'interfaces'
import { REFRESH_UPLOADING_SESSION_DELAY } from 'globalConstants'
import { useEvent } from 'App/hooks'
import { queryClient } from 'queryClient'

export type TUseRequestProps = Pick<TUploadFilePayload, 'file'> & {
  showcaseId: string
  uuid: string
  onProgress: (percentage: number) => void
}

export const useRequest = ({ showcaseId, file, uuid, onProgress }: TUseRequestProps) => {
  const uploadKey = useRef<string>('')
  const timestamp = useRef<Date>(new Date())
  const controller = useRef<AbortController>()
  const [progress, setProgress] = useState<number | null>(null)

  const [uploadedFile, setUploadedFile] = useState<IFilesEntity | null>(null)

  const confirmUploadMutation = useMutation({
    mutationFn: confirmUpload,
    onSuccess: (data) => {
      setUploadedFile(data)
    }
  })

  const continueUploadMutation = useMutation({ mutationFn: continueUpload })

  const cancelUploadingMutation = useMutation({
    mutationKey: ['showcase-cancel-uploading'],
    mutationFn: () => {
      controller.current?.abort()

      return cancelUpload(uploadKey.current)
    }
  })

  const onCancelUploading = useCallback(
    (onSuccess?: () => void) => cancelUploadingMutation.mutate(undefined, { onSuccess }),
    [cancelUploadingMutation]
  )

  const uploadFileMutation = useMutation({
    mutationKey: ['showcase-uploading-file'],
    mutationFn: uploadFile,
    onSuccess: (_, { fields }) => {
      confirmUploadMutation.mutate(fields.key)
    },
    onError: () => undefined
  })

  const handleUploadFile = useCallback(
    ({ fields, url }: IPreSignUrl) => {
      const onUploadProgress = (event: ProgressEvent<EventTarget>) => {
        if (event.lengthComputable) {
          const percentage = (event.loaded / event.total) * 100

          onProgress(Math.round(percentage))

          setProgress(percentage)

          if (
            fields.key &&
            timestamp.current &&
            differenceInMilliseconds(new Date(), timestamp.current) >=
              REFRESH_UPLOADING_SESSION_DELAY
          ) {
            continueUploadMutation.mutate(fields.key)
            timestamp.current = new Date()
          }
        }
      }

      controller.current = new AbortController()

      uploadFileMutation.mutate({
        file,
        url,
        fields,
        signal: controller.current.signal,
        onUploadProgress
      })
    },
    [uploadFileMutation, file, onProgress, continueUploadMutation]
  )

  const presignUrlQuery = useQuery({
    queryKey: [uuid],
    queryFn: () =>
      getShowcasePresignUrl({
        fileName: file.name,
        fileSize: file.size,
        showcaseId
      }),
    enabled: !!showcaseId && !!file,
    onSuccess: (response) => {
      uploadKey.current = response.fields.key
      handleUploadFile(response)
    }
  })

  const onRefreshUploading = useCallback(() => presignUrlQuery.refetch(), [presignUrlQuery])

  const abortUploading = useEvent(() => {
    const isCancelUploadingMutation = queryClient.isMutating({
      mutationKey: ['showcase-cancel-uploading']
    })

    if (progress !== null && progress !== 100 && !isCancelUploadingMutation) {
      onCancelUploading(() =>
        queryClient.invalidateQueries({ queryKey: ['my-showcases'], type: 'active' })
      )
    }
  })

  useEffect(
    () => () => {
      abortUploading()
    },
    [abortUploading]
  )

  return {
    uploadedFile,
    isLoading: presignUrlQuery.isLoading || confirmUploadMutation.isLoading,
    isSuccess: confirmUploadMutation.isSuccess,
    isError: confirmUploadMutation.isError || presignUrlQuery.isError || uploadFileMutation.isError,
    onRefreshUploading,
    onCancelUploading
  }
}
