import { call, put, select, takeLatest } from 'redux-saga/effects'

import { API, api } from 'services/api'
import { QueryBuilder } from 'utils'
import { TGetCategoryListRequest } from 'store/categoryList/categoryList.types'
import { SET_CATEGORY_SEARCH } from 'store'
import { State } from 'redux/rootReducer'
import { handleError } from 'api/utils'

import {
  GET_CATEGORY_LIST_REQUEST,
  getCategoryListError,
  getCategoryListSuccess
} from './categoryList.actions'

function* getAllCategoriesSaga(_: TGetCategoryListRequest) {
  const search: string = yield select((state: State) => state.categoryList.filters.search)

  try {
    const urlBuilder = new QueryBuilder(API.CATEGORIES)

    if (search) {
      urlBuilder.searchQuery(search)
    }

    const url = urlBuilder.build()

    const { data } = yield call(api.get, url)

    yield put(getCategoryListSuccess(data))
  } catch (e) {
    yield put(getCategoryListError(e))

    handleError(e)
  }
}

export function* categoryListSaga() {
  yield takeLatest(GET_CATEGORY_LIST_REQUEST, getAllCategoriesSaga)
  yield takeLatest(SET_CATEGORY_SEARCH, getAllCategoriesSaga)
}
