import { Chip, ShowMore } from 'App/components/common'
import { TCategoryListData } from 'store/categoryList/categoryList.types'

import styles from './DisplayCategories.module.scss'

type TDisplayCategoriesProps = {
  categories: TCategoryListData[]
}

const VISIBLE_HEIGHT = 66

export const DisplayCategories = ({ categories }: TDisplayCategoriesProps) => {
  if (!categories) {
    return null
  }

  const elementsList = (
    <div className={styles.root}>
      {categories.map(({ id, name }) => (
        <Chip prefix="#" key={id} text={name} />
      ))}
    </div>
  )

  return <ShowMore text={elementsList} visibleHeight={VISIBLE_HEIGHT} />
}
