import { useCallback, useEffect, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'

import { Search } from 'App/components/common'
import { EInputSize } from 'enums'
import {
  setOrganizationMemberSearch,
  TAccountData,
  TIds,
  useAppDispatch,
  useAppSelector
} from 'store'

import { Balance } from './Balance'
import { Table } from './Table'
import { Transfer } from './Transfer'
import styles from './TransferMDT.module.scss'

export const TransferMDT = () => {
  const dispatch = useAppDispatch()

  const [totalAmount, setTotalAmount] = useState<number>()
  const [inputAmount, setInputAmount] = useState<number>()
  const [selectedIds, setSelectedIds] = useState<TIds>([])
  const [selectedAccounts, setSelectedAccounts] = useState<TAccountData[]>([])

  const amount = useAppSelector((state) => state.payments.balance.amount ?? 0)
  const organizationMembers = useAppSelector((state) =>
    state.organizations.members.list.map(({ account }) => account)
  )

  const { t } = useTranslation()

  const isExceedsBalance = (totalAmount ?? 0) > amount
  const buttonDisabled = !selectedIds.length || isExceedsBalance

  useEffect(() => {
    setTotalAmount(selectedIds.length * (inputAmount ?? 0))
  }, [inputAmount, selectedIds.length])

  const handleSearch = useCallback(
    (search: string) => {
      dispatch(setOrganizationMemberSearch({ search }))
    },
    [dispatch]
  )

  useEffect(() => {
    setSelectedAccounts((prev) => {
      const accounts = uniqBy([...prev, ...organizationMembers], 'id')

      return selectedIds
        .map((id) => accounts.filter((account) => account.id === id)[0])
        .filter((item) => !!item)
    })
  }, [selectedIds, organizationMembers])

  return (
    <div className={styles.root}>
      <Search
        allowClear={true}
        searchSize={EInputSize.L}
        placeholder={t('payments.transferMDT.searchPlaceholder')}
        onChange={handleSearch}
      />
      <div className={styles.row}>
        <Balance amount={amount} totalAmount={totalAmount} isExceedsBalance={isExceedsBalance} />
        <Transfer
          buttonDisabled={buttonDisabled}
          amount={inputAmount}
          selectedAccounts={selectedAccounts}
          setInputAmount={setInputAmount}
          setSelectedIds={setSelectedIds}
        />
      </div>
      <Table selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
    </div>
  )
}
