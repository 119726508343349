import { useContext, useEffect } from 'react'
import { ShowCaseStatusEnum } from '@medentee/enums'

import { useQuery } from 'services/query'

import { MyShowcasesListItem, ShowcaseMainList } from '../common'
import { getMyShowcases, TGetMyShowcasesPayload } from '../../../../api/showcase'
import { PageWrapperContext } from '../../../components/PageWrapper/PageWrapperContext'
import { EFilterTypes, TFilterItem } from '../../../components'

const SEARCH_OPTIONS = [
  {
    label: 'Title',
    value: 'TITLE'
  },
  {
    label: 'Description',
    value: 'DESCRIPTION'
  }
]
const FILTERS: TFilterItem[] = [
  {
    title: 'Status',
    sectionKey: 'status',
    items: [
      {
        type: EFilterTypes.CHECKBOX,
        filterKey: 'status',
        options: [
          { label: 'Drafts', value: ShowCaseStatusEnum.DRAFT },
          { label: 'Published', value: ShowCaseStatusEnum.PUBLISHED },
          { label: 'Deactivated', value: ShowCaseStatusEnum.DEACTIVATED }
        ]
      }
    ]
  }
]

export const MyShowcases = () => {
  const { selectedFilters, search, searchBy, reset, page, setPage } = useContext(PageWrapperContext)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['my-showcases', page, searchBy, search, selectedFilters],
    queryFn: () =>
      getMyShowcases({
        page,
        searchQuery: search,
        searchBy: searchBy as TGetMyShowcasesPayload['searchBy'],
        status: selectedFilters?.status ?? (undefined as any)
      })
  })

  useEffect(() => {
    reset({
      filters: FILTERS,
      searchBy: 'TITLE',
      searchOptions: SEARCH_OPTIONS
    })
  }, [reset])

  return (
    <>
      <ShowcaseMainList
        data={data?.results}
        loading={isLoading}
        onDeactivate={refetch}
        onSaveToggle={refetch}
        onPublish={refetch}
        onDelete={refetch}
        component={MyShowcasesListItem}
        page={page}
        total={data?.total}
        onPageChange={setPage}
      />
    </>
  )
}
