import { MeetingInviteStatusEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { Status, TStatusProps } from 'App/components/Meetings/common/Status'
import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'

import styles from './InviteStatus.module.scss'

export type TInviteStatusProps = Pick<TStatusProps, 'variant'> & {
  status: MeetingInviteStatusEnum
}

const ICON_MAP = new Map<MeetingInviteStatusEnum, () => JSX.Element>()
  .set(MeetingInviteStatusEnum.ACCEPTED, () => <CheckCircleOutlineIcon />)
  .set(MeetingInviteStatusEnum.PENDING, () => <InfoIcon />)
  .set(MeetingInviteStatusEnum.REJECTED, () => <CloseCircleOutlinedIcon />)

export const InviteStatus = ({ status, variant }: TInviteStatusProps) => {
  const { t } = useTranslation()

  return (
    <Status
      label={
        <>
          <span>{t(`enum.meetingInviteStatusEnum.${status}`)}</span>
          {getMapComponent(ICON_MAP, status)}
        </>
      }
      variant={variant}
      status={status}
      className={cls(styles.root)}
    />
  )
}
