import { Component } from 'react'

import { EHistoryEvents } from 'enums'

type TSnapshotProps = {
  setSnapshot: (snapshot: number) => void
  listRef: React.RefObject<HTMLDivElement>
  dataLength: number

  historyEvent?: EHistoryEvents
}

export class Snapshot extends Component<TSnapshotProps> {
  getSnapshotBeforeUpdate(prevProps: TSnapshotProps) {
    const list = this.props.listRef.current

    if (
      prevProps.dataLength < this.props.dataLength &&
      list &&
      this.props.historyEvent === EHistoryEvents.HISTORY_BEFORE
    ) {
      return list.scrollHeight - list.scrollTop
    }

    return null
  }

  componentDidUpdate(_: unknown, _$: unknown, snapshot: number | null) {
    if (snapshot !== null) {
      this.props.setSnapshot(snapshot)
    }
  }

  render() {
    return null
  }
}
