import { AccountTypeIdEnum, AccountTypeNames, BusinessAccountRoleIdEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { API } from 'services/api'

export const isBusinessAccountSelector = (state: State) =>
  state.global.accountData?.type.id === AccountTypeIdEnum.BUSINESS

export const isAdminAccountSelector = (state: State) =>
  state.global.accountData?.role?.id === BusinessAccountRoleIdEnum.ADMIN ||
  state.global.accountData?.role?.id === BusinessAccountRoleIdEnum.SUPERADMIN

export const accountIdSelector = (state: State) => {
  const accountData = state.global.accountData

  return accountData?.id ?? ''
}

export const accountRoleNameSelector = (state: State) => {
  const accountData = state.global.accountData

  return accountData?.role?.name
}

export const accountTypeNameSelector = (state: State) => {
  const accountData = state.global.accountData

  return accountData?.type.name
}
export const accountTypIdSelector = (state: State) => {
  const accountData = state.global.accountData

  return accountData?.type.id
}

export const originalAccountIdSelector = (state: State) => {
  const accountData = state.global.accountData?.professionalAccount

  return accountData?.id ?? ''
}

export const accountAvatarURLSelector = (state: State): string => {
  const accountId = accountIdSelector(state)

  return API.GET_AVATAR_URL(accountId)
}

export const isProfessionalAccountSelector = (state: State): boolean => {
  const accountType = accountTypeNameSelector(state)

  return accountType === AccountTypeNames.PROFESSIONAL
}

export const supportChatIdSelector = (state: State) => state.global.accountData?.supportChatId
