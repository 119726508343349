export const GET_TRASH_BIN_LIST = 'GET_TRASH_BIN_LIST'
export const GET_TRASH_BIN_LIST_REQUEST = 'GET_TRASH_BIN_LIST_REQUEST'
export const GET_TRASH_BIN_LIST_SUCCESS = 'GET_TRASH_BIN_LIST_SUCCESS'
export const GET_TRASH_BIN_LIST_ERROR = 'GET_TRASH_BIN_LIST_ERROR'

export const RESET_TRASH_BIN = 'RESET_TRASH_BIN'

export const SET_TRASH_BIN_SEARCH = 'SET_TRASH_BIN_SEARCH'

export const SET_TRASH_BIN_PAGINATION = 'SET_TRASH_BIN_PAGINATION'

export const SET_TRASH_BIN_SORTING = 'SET_TRASH_BIN_SORTING'

export const MOVE_FILE_TO_TRASH_BIN = 'MOVE_FILE_TO_TRASH_BIN'
export const MOVE_FILE_TO_TRASH_BIN_REQUEST = 'MOVE_FILE_TO_TRASH_BIN_REQUEST'
export const MOVE_FILE_TO_TRASH_BIN_SUCCESS = 'MOVE_FILE_TO_TRASH_BIN_SUCCESS'
export const MOVE_FILE_TO_TRASH_BIN_ERROR = 'MOVE_FILE_TO_TRASH_BIN_ERROR'

export const RESTORE_FILE = 'RESTORE_FILE'
export const RESTORE_FILE_REQUEST = 'RESTORE_FILE_REQUEST'
export const RESTORE_FILE_SUCCESS = 'RESTORE_FILE_SUCCESS'
export const RESTORE_FILE_ERROR = 'RESTORE_FILE_ERROR'

export const DELETE_FILES_FROM_MEDCLOUD = 'DELETE_FILES_FROM_MEDCLOUD'
export const DELETE_FILES_FROM_MEDCLOUD_REQUEST = 'DELETE_FILES_FROM_MEDCLOUD_REQUEST'
export const DELETE_FILES_FROM_MEDCLOUD_SUCCESS = 'DELETE_FILES_FROM_MEDCLOUD_SUCCESS'
export const DELETE_FILES_FROM_MEDCLOUD_ERROR = 'DELETE_FILES_FROM_MEDCLOUD_ERROR'

export const SET_SELECTED_TRASH_BIN_FILE_ID = 'SET_SELECTED_TRASH_BIN_FILE_ID'
