import { ReactNode } from 'react'
import cls from 'classnames'

import { Tooltip } from 'App/components'

import styles from './IconLabel.module.scss'

export type TIconLabelPropsClasses = 'root' | 'icon' | 'label'

export type TIconLabelProps = PropsWithClasses<
  TIconLabelPropsClasses,
  {
    icon: ReactNode
    label: ReactNode

    tooltip?: ReactNode
    withTooltip?: boolean
    onClick?: () => void
    align?: 'start' | 'center' | 'end'
  }
>

export const IconLabel = ({
  icon,
  label,
  tooltip,
  withTooltip,
  onClick,
  classes,
  align = 'center'
}: TIconLabelProps) => (
  <div className={cls(styles.root, 'icon-label', styles[align], classes?.root)} onClick={onClick}>
    {withTooltip ? (
      <Tooltip title={tooltip || label}>
        <span className={cls(styles.icon, classes?.icon)}>{icon}</span>
      </Tooltip>
    ) : (
      <span className={cls(styles.icon, classes?.icon)}>{icon}</span>
    )}
    <span className={cls(styles.label, classes?.label)}>{label}</span>
  </div>
)
