import { NotificationBadge } from 'App/components'
import { THeaderNotificationsBadgeContainerProps } from 'App/containers'

import styles from './HeaderNotificationsBadge.module.scss'

export type THeaderNotificationsBadgeProps = THeaderNotificationsBadgeContainerProps

export const HeaderNotificationsBadge = ({ count }: THeaderNotificationsBadgeProps) => (
  <NotificationBadge
    className={styles.root}
    borderCssRootPropertyValue="--palette-primary"
    count={count}
    border={true}
  />
)
