import { useCallback } from 'react'
import cls from 'classnames'

import { FileHistoryGroupedItemContainer, TFileHistoryListContainerProps } from 'App/containers'
import { InfiniteScroll } from 'App/components/common'
import { FILE_HISTORY_DEFAULT_SHOW_BY } from 'globalConstants'

import styles from './FileHistoryList.module.scss'

const WRAPPER_ID = 'wrapper'

export const FileHistoryList = ({
  getFileHistory,
  fileId,
  sources,
  actorAccountIds,
  filters,
  dateIds,
  loading,
  displayInline
}: TFileHistoryListContainerProps) => {
  const { page, total } = filters

  const getNextHistory = useCallback(
    (nextPage: number) =>
      getFileHistory({
        fileIds: [fileId],
        page: nextPage,
        sources,
        actorAccountIds
      }),
    [getFileHistory, fileId, sources, actorAccountIds]
  )

  return (
    <div className={styles.root}>
      <div
        className={cls(displayInline ? styles.wrapperCase : styles.wrapperCloud)}
        id={WRAPPER_ID}
      >
        <InfiniteScroll
          dataLength={dateIds.length}
          total={total}
          page={page}
          next={getNextHistory}
          loading={loading}
          limit={FILE_HISTORY_DEFAULT_SHOW_BY}
          scrollableTarget={WRAPPER_ID}
        >
          {dateIds.map((dateId) => (
            <FileHistoryGroupedItemContainer
              key={dateId}
              dateId={dateId}
              displayInline={displayInline}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}
