import { FileAttachFilesItemContainer, FileAttachUploadFilesItemContainer } from 'App/containers'

import { TFilesViewVariant } from '../../FileTilesView'
import { FileAttachTile } from '../FileAttachTile'

import styles from './FileAttachItem.module.scss'

export type TFileAttachItemProps = {
  id: string
  upload: boolean
  view: TFilesViewVariant
  loading: boolean
  isAttached: boolean
}

export const FileAttachItem = ({ view, id, isAttached, upload, loading }: TFileAttachItemProps) => (
  <>
    {view === 'grid' && (
      <FileAttachTile key={id} id={id} loading={loading} upload={upload} isAttached={isAttached} />
    )}

    {view === 'list' && (
      <div key={id} className={styles.listItem}>
        {upload ? (
          <FileAttachUploadFilesItemContainer id={id} isAttached={isAttached} />
        ) : (
          <FileAttachFilesItemContainer id={id} isAttached={isAttached} />
        )}
      </div>
    )}
  </>
)
