import cls from 'classnames'

import { EAvatarSize } from 'enums'

import { TAccountsItem } from '../AccountsListPopover'

import styles from './AccountsListOthers.module.scss'

export type TAccountsListOthersProps = {
  accounts: TAccountsItem[]
  size?: EAvatarSize
}

export const AccountsListOthers = ({
  accounts,
  size = EAvatarSize.XS
}: TAccountsListOthersProps) => (
  <span className={cls(styles.root, styles[size])}>
    <span className={styles.text}>+{accounts.length}</span>
  </span>
)
