import { useEffect, useRef } from 'react'
import { Form, Field } from 'react-final-form'
import { CaseStatusesEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { CaseDetailsContainer, CaseDetailsPlaceholder, Editor } from 'App/components'
import {
  CaseMemberOpinionsItemContainer,
  CaseOpinionsMembersContainer,
  TCaseMemberOpinionsContainerProps
} from 'App/containers'
import { usePrevious } from 'App/hooks'
import { TIds } from 'store'
import { generateRandomArray, validation } from 'utils'
import { ECasesDocumentKey } from 'enums'

export type TCaseMemberOpinionsListBaseProps = {
  caseId: string
}

export type TCaseMemberOpinionsListProps = Pick<
  TCaseMemberOpinionsContainerProps,
  | 'ids'
  | 'caseId'
  | 'count'
  | 'loading'
  | 'processing'
  | 'status'
  | 'addCaseOpinion'
  | 'caseType'
  | 'updateCaseDraft'
  | 'draft'
>

export type TAddOpinionValues = {
  opinion: string
}

const DEFAULT_OPINIONS_LENGTH = 5
const OPINIONS_MAX_LENGTH = 6000
const MIN_ROWS = 2
const MAX_ROWS = 4

export const CaseMemberOpinionsList = ({
  ids,
  caseId,
  count,
  loading,
  processing,
  status,
  caseType,
  draft,
  addCaseOpinion,
  updateCaseDraft
}: TCaseMemberOpinionsListProps) => {
  const opinions = loading ? generateRandomArray(DEFAULT_OPINIONS_LENGTH) : ids

  const { t } = useTranslation()

  const resetForm = useRef<(initialValues?: TAddOpinionValues) => void>()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const prevIds = usePrevious<TIds>(ids)

  useEffect(() => {
    resetForm.current && resetForm.current()
  }, [ids])

  useEffect(() => {
    if (wrapperRef.current && prevIds?.length && prevIds.length !== ids.length) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
    }
  }, [ids, prevIds])

  const handleAddOpinion = (values: Partial<TAddOpinionValues>) => {
    addCaseOpinion({ caseId, opinion: values.opinion || '' })
  }

  const getContent = () => {
    if (caseType === CaseTypesEnum.A2O) {
      return opinions.length ? (
        opinions.map((id) => (
          <CaseMemberOpinionsItemContainer key={id} id={id} newDividerLineRef={null} />
        ))
      ) : (
        <CaseDetailsPlaceholder>
          {t('cases.details.opinions.placeholder_member')}
        </CaseDetailsPlaceholder>
      )
    }

    return (
      caseType === CaseTypesEnum.A2A && (
        <CaseOpinionsMembersContainer caseId={caseId} withoutWrapper={true} />
      )
    )
  }

  const footer = status !== CaseStatusesEnum.LOCKED && (
    <Form onSubmit={handleAddOpinion} initialValues={{ opinion: draft }}>
      {({ handleSubmit, form: { getState, reset } }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event)
            resetForm.current = reset
          }}
        >
          <Field
            name="opinion"
            validate={validation.composeValidators(
              validation.required(),
              validation.maxLength(OPINIONS_MAX_LENGTH - count),
              validation.onlySpaces()
            )}
            // This huck is required to override default logic when
            // the filed value set to `undefined` instead of ''
            parse={(value) => value}
          >
            {({ input: { onChange, ...input } }) => {
              const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChange(event.target.value)
                updateCaseDraft({
                  message: event.target.value,
                  caseId,
                  type: ECasesDocumentKey.OPINION
                })
              }

              return (
                <Editor
                  {...input}
                  initialLength={count}
                  valueLengthMax={OPINIONS_MAX_LENGTH}
                  minRows={MIN_ROWS}
                  maxRows={MAX_ROWS}
                  placeholder={t('cases.details.opinions.editor.placeholder')}
                  error={getState().invalid}
                  loading={processing}
                  onChange={handleChange}
                />
              )
            }}
          </Field>
        </form>
      )}
    </Form>
  )

  return <CaseDetailsContainer content={getContent()} footer={footer} wrapperRef={wrapperRef} />
}
