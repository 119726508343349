import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { RENAME_CASE_FILE, renameCaseFileRequest } from 'store/caseCloud'
import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store/errors'
import {
  FileRenameDialog,
  TFileRenameDialogContainerOwnProps
} from 'App/components/File/FileRenameDialog'

export type TCaseCloudRenameFileDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const action = RENAME_CASE_FILE
const loadingSelector = createProcessingSelector([action])

const mapStateToProps = (state: State) => ({
  processing: loadingSelector(state),
  error: state.errors[action],
  action
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      renameFile: renameCaseFileRequest,
      removeErrorNotification
    },
    dispatch
  )

export const CaseCloudRenameFileDialog: FC<TFileRenameDialogContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileRenameDialog)
