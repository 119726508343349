import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { MAX_MESSAGE_REACTIONS } from 'globalConstants'
import {
  ADD_MESSAGE_REACTION,
  REMOVE_MESSAGE_REACTION,
  addMessageReactionRequest,
  createProcessingBySelector,
  hideModalAction,
  removeMessageReactionRequest,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'

type TMessageReactionPayload = {
  chatId: string
  messageId: string
  reaction: string
}

export type TUseChatMessageReactionsRequestsProps = Pick<
  TMessageReactionPayload,
  'chatId' | 'messageId'
>
const processingSelector = createProcessingBySelector([
  ADD_MESSAGE_REACTION,
  REMOVE_MESSAGE_REACTION
])

export const useChatMessageReactionsRequests = ({
  chatId,
  messageId
}: TUseChatMessageReactionsRequestsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector(messageId))
  const emojis = useAppSelector(
    (state) => state.chat.chatMessages.reactions.messageList[messageId]?.emojis
  )

  const outgoingReaction = useAppSelector(
    (state) => state.chat.chatMessages.reactions.outgoingList[messageId] ?? ''
  )

  const removeReaction = useCallback(() => {
    dispatch(removeMessageReactionRequest({ chatId, messageId, processingId: messageId }))
  }, [chatId, dispatch, messageId])

  const addReaction = useCallback(
    (reaction: string) => {
      const shouldShowLimitModal =
        (emojis?.size ?? 0) >= MAX_MESSAGE_REACTIONS && !emojis?.has(reaction)

      if (shouldShowLimitModal) {
        dispatch(
          showModalAction({
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalTitle: t('modal.reachReactionLimit.title'),
            modalProps: {
              onConfirm: () => {
                dispatch(hideModalAction())
              },
              confirmLabel: t('modal.reachReactionLimit.submitButton'),
              content: t('modal.reachReactionLimit.content')
            }
          })
        )
        return
      }

      dispatch(addMessageReactionRequest({ chatId, messageId, reaction, processingId: messageId }))
    },
    [chatId, dispatch, emojis, messageId, t]
  )

  return { outgoingReaction, addReaction, removeReaction, processing }
}
