import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './FilteringResultsPlaceholder.module.scss'

export type TFilteringResultsPlaceholderProps = PropsWithClassName<{
  notFoundFilter: boolean
  notFoundSearch: boolean
}>

export const FilteringResultsPlaceholder = ({
  notFoundFilter,
  notFoundSearch,
  className
}: TFilteringResultsPlaceholderProps) => {
  const { t } = useTranslation()

  return notFoundFilter || notFoundSearch ? (
    <p className={cls(styles.root, className)}>
      {t('common.field.search.listPlaceholder', { context: notFoundFilter ? 'filter' : 'search' })}
    </p>
  ) : null
}
