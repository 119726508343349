import { Col } from 'antd'
import { Field } from 'react-final-form'
import { addMonths, isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { rangeDate, validation } from 'utils'
import { TextField } from 'App/components'
import { DatePicker } from 'App/components/common/Fields/DatePicker'
import { MAX_LENGTH_200, MAX_LINK_LENGTH, MIN_LINK_LENGTH, MIN_PICKER_DATE } from 'globalConstants'

const ONE_MONTH = 1

export const SecondStep = () => {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={24}>
        <Field
          name="startDate"
          validate={validation.composeValidators(
            validation.minDate(MIN_PICKER_DATE),
            validation.maxDate(addMonths(new Date(), ONE_MONTH))
          )}
        >
          {({ input, meta }) => (
            <DatePicker
              {...input}
              topLabel={t('modal.userPublication.form.dateField')}
              picker="month"
              defaultValue={input.value}
              format={rangeDate}
              placeholder={t('modal.userPublication.form.dateFieldPlaceholder')}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              disabledDate={(date) => isBefore(date, MIN_PICKER_DATE) || isAfter(date, new Date())}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field
          name="source"
          validate={validation.composeValidators(
            validation.onlySpaces(),
            validation.maxLength(MAX_LENGTH_200)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userPublication.form.sourceField')}
              valueLengthMax={MAX_LENGTH_200}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field
          name="link"
          validate={validation.composeValidators(
            validation.minLength(
              MIN_LINK_LENGTH,
              t('validationErrors.invalidLink', { ns: 'errors' })
            ),
            validation.maxLength(
              MAX_LINK_LENGTH,
              t('validationErrors.longLink', { length: MAX_LINK_LENGTH, ns: 'errors' })
            ),
            validation.isUrl()
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userPublication.form.linkField')}
              placeholder={t('modal.userPublication.form.linkFieldPlaceholder')}
              invalid={meta.touched && meta.invalid}
              valueLengthMax={MAX_LINK_LENGTH}
              error={meta.error}
              hideCounter={true}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
