import { ContactCreationLocationTypeEnum, MessageIconTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'App/components'
import { ReactComponent as ConnectorIcon } from 'assets/icons/Web.svg'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'

import styles from './ConnectionLabelIcon.module.scss'

type ConnectionLabelIconProps = PropsWithClassName<{
  type?: ContactCreationLocationTypeEnum | MessageIconTypeEnum | null
  size?: 'sm' | 'md'
  isMessageOutgoing?: boolean
}>

export const ConnectionLabelIcon = ({
  type,
  className,
  size = 'sm',
  isMessageOutgoing = false
}: ConnectionLabelIconProps) => {
  const { t } = useTranslation()

  if (!type || type === MessageIconTypeEnum.CASE_INVITE_REJECTION) {
    return null
  }

  const isFromCase =
    type === ContactCreationLocationTypeEnum.GROUP_CASE ||
    type === MessageIconTypeEnum.GROUP_CASE_INVITATION

  return (
    <Tooltip
      title={t('chat.message.connectionTooltip', {
        context: isFromCase ? 'case' : 'connector'
      })}
      trigger="hover"
    >
      <span
        className={cls(styles.root, styles[size], className, {
          [styles.outgoingMessage]: isMessageOutgoing
        })}
      >
        {isFromCase ? <CaseIcon /> : <ConnectorIcon />}
      </span>
    </Tooltip>
  )
}
