import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { CasePermissionsModeEnum } from '@medentee/enums'
import { FC } from 'react'

import { CaseCloudPermissionsModeChangeConfirm } from 'App/components/Case/CaseCloud/CaseCloudPermissionsModeChangeConfirm'
import {
  createProcessingSelector,
  setCaseCloudPermissionsModeRequest,
  removeErrorNotification,
  SET_CASE_CLOUD_PERMISSIONS_MODE
} from 'store'
import { State } from 'redux/rootReducer'

export type TCaseCloudPermissionsModeChangeConfirmContainerOwnProps = {
  caseId: string
  permissionsMode: CasePermissionsModeEnum
}

export type TCaseCloudPermissionsModeChangeConfirmContainerProps =
  TCaseCloudPermissionsModeChangeConfirmContainerOwnProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>

const processingSelector = createProcessingSelector([SET_CASE_CLOUD_PERMISSIONS_MODE])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.errors[SET_CASE_CLOUD_PERMISSIONS_MODE]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCaseCloudPermissionsMode: setCaseCloudPermissionsModeRequest,
      removeErrorNotification
    },
    dispatch
  )

export const CaseCloudPermissionsModeChangeConfirmContainer: FC<TCaseCloudPermissionsModeChangeConfirmContainerOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(CaseCloudPermissionsModeChangeConfirm)
