import { actionCreator } from 'store'

import { TReceiveSystemIntermediateStateActionPayload } from './system.types'
import * as actionTypes from './system.actionTypes'

// ------------------ RECEIVE_SYSTEM_INTERMEDIATE_STATE ------------------

export const receiveSystemIntermediateStateAction = (
  payload: TReceiveSystemIntermediateStateActionPayload
) => actionCreator(actionTypes.RECEIVE_SYSTEM_INTERMEDIATE_STATE, payload)
