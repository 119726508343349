import { accountIdSelector, useAppSelector } from 'store'
import { Divider } from 'App/components/common'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { CommentsLayout } from '../../CommentsLayout'
import { CommentsContainer } from '../../CommentsContainer'
import { CommentField, TCommentFieldProps } from '../../Field/CommentField'
import { TAB_TITLE_ID, TUseCommentsWrapper, useCommentsWrapper } from '../../useCommentsWrapper'

import styles from './Content.module.scss'

type TContentProps = Pick<TUseCommentsWrapper, 'fileId' | 'fileOrderId' | 'showcaseId'> &
  Pick<TCommentFieldProps, 'files' | 'showcaseOwnerId'> & {
    onClose: () => void
  }

export const Content = ({
  files,
  fileId,
  fileOrderId,
  showcaseId,
  showcaseOwnerId,
  onClose
}: TContentProps) => {
  const accountId = useAppSelector(accountIdSelector)
  const commentCounter = useAppSelector((state) =>
    fileId ? state.showcases.commentCounts[showcaseId]?.list[fileId]?.commentCounter : 0
  )
  const isShowcaseOwner = showcaseOwnerId === accountId

  const { fieldEvent, queryKeys, commentEvent, setFieldEvent, getMenuItems } = useCommentsWrapper({
    fileId,
    fileOrderId,
    showcaseId,
    isShowcaseOwner
  })

  return (
    <CommentsLayout
      classes={{ header: styles.header }}
      header={
        <>
          <span className={styles.title}>
            {typeof commentCounter !== 'undefined' && <span>{commentCounter}</span>}
            <span>{` ${Number(commentCounter) > 1 ? 'Comments' : 'Comment'} for `}</span>
            <span className={styles.item}>{`/item${fileOrderId + 1}`}</span>
          </span>

          <CrossIcon className={styles.cross} onClick={onClose} />
        </>
      }
      content={
        <>
          <Divider spaces={[12, 12]} />

          <CommentsContainer
            classes={{
              comments: {
                root: styles.comments,
                scrollInner: styles.commentsScrollInner
              }
            }}
            fileId={fileId}
            showcaseId={showcaseId}
            queryKey={queryKeys[TAB_TITLE_ID.FOR_ITEM]}
            getMenuItems={getMenuItems}
            commentEvent={commentEvent}
            setFieldEvent={setFieldEvent}
            isShowcaseOwner={isShowcaseOwner}
          />
        </>
      }
      footer={
        fileId && (
          <CommentField
            variant="column"
            event={fieldEvent}
            fileId={fileId}
            showcaseId={showcaseId}
            getCommentsQueryKey={queryKeys[TAB_TITLE_ID.FOR_ITEM]}
            files={files}
            showcaseOwnerId={showcaseOwnerId}
          />
        )
      }
    />
  )
}
