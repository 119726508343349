import { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { Row } from 'antd'
import cls from 'classnames'
import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components/common'
import { useAppSelector, isBusinessAccountSelector } from 'store'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import {
  COMMUNITIES_BROADCAST_PATH,
  COMMUNITY_BROADCAST_PATH,
  EVENTS_BROADCAST_PATH
} from '../BroadcastRoom'

import styles from './BroadcastOutsidePanel.module.scss'

export type TBroadcastOutsidePanelProps = {
  onClose: () => void

  cancelDraggableClassName?: string
  hidden?: boolean
}

export const BroadcastOutsidePanel = ({
  cancelDraggableClassName,
  onClose,
  hidden = false
}: TBroadcastOutsidePanelProps) => {
  const { location, push } = useHistory()

  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const activeBroadcastId = useAppSelector((state) => state.broadcasts.activeBroadcast?.id)
  const activeBroadcastType = useAppSelector((state) => state.broadcasts.activeBroadcast?.type)
  const entityId = useAppSelector((state) => state.broadcasts.activeBroadcast?.entityId)

  const path = useMemo(() => {
    if (!activeBroadcastId) {
      return null
    }

    if (isBusinessAccount && entityId && activeBroadcastType === BroadcastTypeEnum.COMMUNITY) {
      return generatePath(COMMUNITY_BROADCAST_PATH, { broadcastId: activeBroadcastId })
    }

    if (!isBusinessAccount && entityId && activeBroadcastType === BroadcastTypeEnum.COMMUNITY) {
      return generatePath(COMMUNITIES_BROADCAST_PATH, {
        broadcastId: activeBroadcastId,
        id: entityId
      })
    }

    if (entityId && activeBroadcastType === BroadcastTypeEnum.EVENT) {
      return generatePath(EVENTS_BROADCAST_PATH, {
        broadcastId: activeBroadcastId,
        id: entityId
      })
    }

    return null
  }, [activeBroadcastId, activeBroadcastType, entityId, isBusinessAccount])

  const handleGoToClick = useCallback(() => path && push(path), [path, push])

  if (hidden || !path || `${location.pathname}${location.search}` === path) {
    return null
  }

  return (
    <Row className={styles.root} align="middle" justify="space-between">
      <Button
        className={cls(styles.goTo, cancelDraggableClassName)}
        onClick={handleGoToClick}
        variant="inline"
        fontWeight="bold"
        size="xs"
        iconPosition="right"
        icon={<ArrowRightIcon className={styles.icon} />}
      >
        {t('liveStream.goToLiveStreamButton', {
          context: activeBroadcastType?.toLowerCase() as Lowercase<BroadcastTypeEnum>
        })}
      </Button>

      <CrossIcon className={cls(styles.icon, cancelDraggableClassName)} onClick={onClose} />
    </Row>
  )
}
