import { Col, Row } from 'antd'
import { EventPublicityEnum } from '@medentee/enums'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
  UnityHubSettingsRadio,
  UnityHubSettingsRadioGroup
} from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsRadio'
import { UnityHubSettingsTitle } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsTitle'
import { UnityHubSettingsDescription } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsDescription'

import { validation } from '../../../../../utils'
import { TextFieldSkeleton } from '../../../../components'
import { TOrganizerSettingsFormValues } from '../OrganizerSettings'

export type TOrganizerPublicityProps = {
  loading: boolean
}

export const OrganizerPublicity = ({ loading }: TOrganizerPublicityProps) => {
  const { values } = useFormState<TOrganizerSettingsFormValues>()

  const { t } = useTranslation()

  return (
    <Col xs={24}>
      <Row gutter={[0, 12]}>
        <Col xs={24}>
          <UnityHubSettingsTitle title={t('events.organizerSettings.publicityTitle')} />
        </Col>
        <Col xs={24}>
          <UnityHubSettingsDescription
            description={t('events.organizerSettings.publicityDescription')}
          />
        </Col>
        <TextFieldSkeleton loading={loading}>
          <Field name="publicity" validate={validation.required()} type="radio">
            {({ input: { name, onChange } }) => (
              <UnityHubSettingsRadioGroup name={name} value={values.publicity} onChange={onChange}>
                <UnityHubSettingsRadio value={EventPublicityEnum.PUBLIC}>
                  {t('events.organizerSettings.publicityPublicOption')}
                </UnityHubSettingsRadio>
                <UnityHubSettingsRadio value={EventPublicityEnum.PRIVATE}>
                  {t('events.organizerSettings.publicityPrivateOption')}
                </UnityHubSettingsRadio>
              </UnityHubSettingsRadioGroup>
            )}
          </Field>
        </TextFieldSkeleton>
      </Row>
    </Col>
  )
}
