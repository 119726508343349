import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import {
  createProcessingSelector,
  MOVE_FILE_TO_TRASH_BIN,
  useAppDispatch,
  useAppSelector
} from 'store'
import { moveFileToTrashBinRequest, TMoveFileToTrashBinRequestPayload } from 'store/files/trashBin'

import styles from './FileMoveToTrashBinDialog.module.scss'

const processingSelector = createProcessingSelector([MOVE_FILE_TO_TRASH_BIN])

type TFileMoveToTrashBinDialog = TMoveFileToTrashBinRequestPayload & {
  onSuccess?: () => void
}

export const FileMoveToTrashBinDialog = ({
  fileIds,
  caseId,
  onSuccess
}: TFileMoveToTrashBinDialog) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector)

  const handleButtonClick = () => {
    dispatch(moveFileToTrashBinRequest({ fileIds, caseId, onSuccess }))
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootText}>{t('modal.moveToTrashBinConfirm.content')}</p>
      <Button
        className={styles.rootButton}
        onClick={handleButtonClick}
        tabIndex={2}
        loading={processing}
      >
        {t('modal.moveToTrashBinConfirm.submitButton')}
      </Button>
    </div>
  )
}
