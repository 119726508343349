import { useTranslation } from 'react-i18next'

import { useCloseModal } from 'App/hooks'
import { EModalComponents } from 'App/containers'

import { Wrapper } from '../Wrapper'

export const MonthlyTopUpSucceededDialog = () => {
  const { t } = useTranslation()

  const handleCloseModal = useCloseModal([EModalComponents.TOP_UP_MONTHLY_SUCCEEDED])

  return (
    <Wrapper
      icon="success"
      title={t('modal.monthlyTopUpSucceed.title')}
      description={t('modal.monthlyTopUpSucceed.content')}
      submitLabel={t('modal.monthlyTopUpSucceed.submitButton')}
      onClick={handleCloseModal}
    />
  )
}
