import { useTranslation } from 'react-i18next'

import { OrganizationContentHeader, Scrollable } from 'App/components'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { EBusinessAdminConfirmFrom } from 'enums'
import { useOrganizationId } from 'App/components/Organizations/hooks'

import { OrganizationsInvitationsReceived } from '../OrganizationsInvitations/OrganizationsInvitationsReceived'

import styles from './AdministrationInvitations.module.scss'

export const AdministrationInvitations = () => {
  const organizationId = useOrganizationId()

  const { t } = useTranslation()

  const defaultPath = `/organizations/${organizationId}`

  return (
    <div className={styles.root}>
      <OrganizationContentHeader
        title={t('organizations.invitationsLabel')}
        icon={<AccountPlusIcon />}
        iconVariant="square"
        iconColor="accent"
      />
      <div className={styles.main}>
        <Scrollable>
          <OrganizationsInvitationsReceived
            from={EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION}
            organizationId={organizationId}
            defaultPath={defaultPath}
          />
        </Scrollable>
      </div>
    </div>
  )
}
