import { useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { add, isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { validation, rangeDate } from 'utils'
import { DatePicker } from 'App/components/common/Fields/DatePicker'

import { TValues } from '../../UserProfileLicensesDialog'

const MAX_PICKER_DATE = add(new Date(), { years: 10 })

export const EndDateField = () => {
  const { values } = useFormState<TValues>()
  const { change, resetFieldState } = useForm()

  const { t } = useTranslation()

  useEffect(() => {
    if (values.noExpirationDate) {
      change('endDate', undefined)
    }
    resetFieldState('endDate')
  }, [values.noExpirationDate, change, resetFieldState])

  return (
    <Field name="endDate" validate={values.noExpirationDate ? undefined : validation.required()}>
      {({ input, meta }) => (
        <DatePicker
          {...input}
          picker="month"
          format={rangeDate}
          topLabel={t('modal.userLicenses.form.expirationDateField')}
          disabled={values.noExpirationDate}
          disabledDate={(date) =>
            (values?.startDate && isBefore(date, values?.startDate)) ||
            isAfter(date, MAX_PICKER_DATE)
          }
          invalid={meta.touched && meta.invalid}
          error={meta.error}
        />
      )}
    </Field>
  )
}
