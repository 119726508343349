import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector, showModalAction } from 'store'
import { ShowMore, TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as BriefcaseOutlineIcon } from 'assets/icons/BriefcaseOutline.svg'
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'

import { ItemHeader, ItemDateRange, ItemLabel, Item } from '../../common'
import styles from '../../common/common.module.scss'

export type TWorkExperienceItemProps = {
  id: string

  disableContextMenu?: boolean
}

const SHOW_MORE_ROWS_HEIGHT = 63

export const WorkExperienceItem = ({ id, disableContextMenu }: TWorkExperienceItemProps) => {
  const dispatch = useDispatch()

  const data = useAppSelector(
    (state) => state.userProfile.viewProfile.workExperience.list[id] || {}
  )

  const { t } = useTranslation()

  const { city, country, startDate, endDate, isCurrent, description, organization, position } = data

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <PencilOutlineIcon />,
        content: t('userProfile.workplaces.kebabMenu.edit'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.userWorkplaces.title_edit'),
              modalType: EModalComponents.WORKPLACE_DIALOG,
              modalProps: {
                data
              }
            })
          )
      },
      {
        icon: <TrashIcon />,
        content: t('userProfile.workplaces.kebabMenu.remove'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.removeWorkplaceConfirm.title'),
              modalType: EModalComponents.REMOVE_WORKPLACE_DIALOG,
              modalProps: { id: data.id }
            })
          )
      }
    ],
    [data, dispatch, t]
  )

  return (
    <Item>
      <Row gutter={[0, 8]}>
        <Col xs={24}>
          <ItemHeader title={position} menuItems={!disableContextMenu ? menuItems : undefined} />
        </Col>

        <Col xs={24}>
          <ItemDateRange
            icon={<ScheduleIcon />}
            startDate={startDate}
            endDate={endDate}
            isCurrent={isCurrent}
          />
        </Col>

        {organization && (
          <Col xs={24}>
            <ItemLabel icon={<BriefcaseOutlineIcon />} label={organization} />
          </Col>
        )}

        {(city || country) && (
          <Col xs={24}>
            <ItemLabel
              icon={<LocationOutlineIcon />}
              label={`${city?.cityName ?? ''}${city && country ? ',' : ''} ${
                country?.countryName ?? ''
              }`}
            />
          </Col>
        )}

        {description && (
          <Col xs={24}>
            <ShowMore
              visibleHeight={SHOW_MORE_ROWS_HEIGHT}
              classes={{ wrapper: styles.showMore }}
              text={description}
            />
          </Col>
        )}
      </Row>
    </Item>
  )
}
