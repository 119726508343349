import cls from 'classnames'
import { Tooltip as OriginalTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

import { useAdaptiveLayout } from 'App/hooks'

import styles from './Tooltip.module.scss'

export const Tooltip = ({
  placement = 'bottom',
  overlayClassName = '',
  title,
  ...rest
}: TooltipProps) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <OriginalTooltip
      overlayClassName={cls({
        [styles.root]: true,
        [overlayClassName]: !!overlayClassName
      })}
      placement={placement}
      title={isDesktop && title ? title : undefined}
      {...rest}
    />
  )
}
