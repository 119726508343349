import { useTranslation } from 'react-i18next'

import { ReactComponent as ShareIcon } from 'assets/icons/Share.svg'
import { TChatQuotingMessageContainerProps, ChatMessageReferenceContainer } from 'App/containers'
import { EChatMessageAction } from 'enums'

import styles from './ChatQuotingMessage.module.scss'

type TChatQuotingMessageProps = TChatQuotingMessageContainerProps

export const ChatQuotingMessage = ({
  quote,
  type,
  chatId,
  quoteMessageCancel
}: TChatQuotingMessageProps) => {
  const { t } = useTranslation()

  if (!quote || type !== EChatMessageAction.QUOTE || !chatId) {
    return null
  }

  const handleCancel = () => {
    quoteMessageCancel({ chatId })
  }

  return (
    <ChatMessageReferenceContainer
      classes={{ root: styles.root }}
      message={quote}
      title={t('chat.quotingMessageTitle')}
      icon={ShareIcon}
      onCancel={handleCancel}
    />
  )
}
