import { useCallback } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  getChatRoomsRequest,
  hideModalAction,
  onChatRoomNotFoundError,
  resetChatAction,
  useAppDispatch
} from 'store'
import { deleteTextChannel } from 'api/organizations'
import { Button, ConfirmationModal } from 'App/components/common'

type TOrganizationDeleteTextChannelConfirmProps = {
  channelId: string
  organizationId: string
}

export const OrganizationDeleteTextChannelConfirm = ({
  organizationId,
  channelId
}: TOrganizationDeleteTextChannelConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = () => {
    dispatch(hideModalAction())
    dispatch(resetChatAction())
    dispatch(
      getChatRoomsRequest({
        organizationId,
        chatType: [ChatTypeEnum.ORGANIZATION]
      })
    )
  }

  const { mutate, error, isLoading } = useMutation({
    mutationKey: ['organization_delete_text_channel', organizationId, channelId],
    mutationFn: deleteTextChannel,
    onSuccess,
    onError: (e) => onChatRoomNotFoundError(e, onSuccess)
  })

  const onClick = useCallback(() => {
    mutate({ organizationId, channelId })
  }, [organizationId, channelId, mutate])

  return (
    <ConfirmationModal
      error={error}
      content={t('modal.deleteChannelConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteChannelConfirm.submitButton')}
        </Button>
      }
    />
  )
}
