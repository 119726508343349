import { useEffect, useState } from 'react'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { TChangeSubscriptionPlanDialogContainerProps } from 'App/containers'
import { ErrorModal } from 'App/components'
import { generateRandomArray } from 'utils'
import { useSteps } from 'App/hooks'

import { FirstStep } from './FirstStep'
import { SecondStep } from './SecondStep'
import styles from './ChangeSubscriptionPlanDialog.module.scss'

export type TChangeSubscriptionPlanDialogProps = TChangeSubscriptionPlanDialogContainerProps

const DEFAULT_SUBSCRIPTIONS_LENGTH = 2

enum ESteps {
  FIRST,
  SECOND
}

export const ChangeSubscriptionPlanDialog = ({
  ids,
  error,
  loading,
  accountType,
  getAvailableSubscriptions,
  removeErrorNotification,
  getActiveSubscriptions,
  activateSubscriptions
}: TChangeSubscriptionPlanDialogProps) => {
  const { step, setNextStep, setPrevStep } = useSteps(ESteps.FIRST)
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<SubscriptionIdsEnum>()

  useEffect(() => {
    getActiveSubscriptions()
    if (accountType?.id) {
      getAvailableSubscriptions({ accountTypeId: accountType.id })
    }
  }, [accountType?.id, getActiveSubscriptions, getAvailableSubscriptions])

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  const handleActivateSubscription = (id: SubscriptionIdsEnum) => () => {
    activateSubscriptions({ processingId: id, subscriptionId: id })
  }

  const handleChooseSubscription = (id: SubscriptionIdsEnum) => {
    if (
      id === SubscriptionIdsEnum.BASIC_PROFESSIONAL ||
      id === SubscriptionIdsEnum.BASIC_BUSINESS
    ) {
      setSelectedSubscriptionId(id)
      setNextStep()

      return
    }

    handleActivateSubscription(id)()
  }

  const subscriptionIds = loading ? generateRandomArray(DEFAULT_SUBSCRIPTIONS_LENGTH) : ids

  return (
    <div className={styles.root}>
      {step === ESteps.FIRST && (
        <FirstStep ids={subscriptionIds} handleChooseSubscription={handleChooseSubscription} />
      )}

      {step === ESteps.SECOND && selectedSubscriptionId && (
        <SecondStep
          accountType={accountType}
          onSubmit={handleActivateSubscription(selectedSubscriptionId)}
          onClickBack={setPrevStep}
        />
      )}

      <ErrorModal error={error} />
    </div>
  )
}
