import { useCallback, useLayoutEffect } from 'react'
import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'
import { useHistory, useParams } from 'react-router'
import { stringify } from 'query-string'
import { useTranslation } from 'react-i18next'

import { TChannelVariant } from 'interfaces'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'

export type TUseCommunitiesJoinViaLinkCoreProps = {
  variant: Extract<TChannelVariant, 'chat' | 'broadcast' | 'news'>
}

type TParams = {
  id?: string
  token?: string
}

type TRedirectToCommunityPayload = {
  title?: string | null
  channelId?: string
  isMember?: boolean
  chatType?: ChatTypeEnum
}

export const useCommunitiesJoinViaLinkCore = ({ variant }: TUseCommunitiesJoinViaLinkCoreProps) => {
  const dispatch = useAppDispatch()

  const { id = '', token = '' } = useParams<TParams>()

  const { replace } = useHistory()

  const { t } = useTranslation()

  const isNotProfessional = useAppSelector(
    (state) => state.global.accountData?.type.name !== AccountTypeNames.PROFESSIONAL
  )

  const redirectToDefaultPage = useCallback(() => replace('/'), [replace])

  const redirectToCommunity = useCallback(
    ({ channelId, isMember, title }: TRedirectToCommunityPayload) => {
      if (isNotProfessional || !title) {
        return
      }

      if (isMember && channelId) {
        replace(`/communities/${id}/${variant}/${channelId}`)
        return
      }

      replace({
        pathname: `/communities/${id}/info`,
        search: stringify({ token, title, variant })
      })
    },
    [isNotProfessional, replace, id, token, variant]
  )

  useLayoutEffect(() => {
    if (isNotProfessional) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.SWITCH_ACCOUNT_DIALOG,
          modalTitle: t('modal.switchAccountConfirm.title'),
          modalProps: {
            onSuccess: () => {
              window.location.href = `${window.location.origin}/join/communities/${id}/${variant}/t/${token}`
            },
            onClose: redirectToDefaultPage
          }
        })
      )
    }
  }, [isNotProfessional, dispatch, redirectToDefaultPage, token, variant, id, t])

  return {
    id,
    token,
    isNotProfessional,
    redirectToDefaultPage,
    redirectToCommunity
  }
}
