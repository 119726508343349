import { useEffect } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { SettingsList, SettingsSecurityDeleteAccount } from 'App/components'
import {
  SettingsSecurityDefaultAccountContainer,
  SettingsSecurityAndLogin2FAContainer,
  TSettingsSecurityAndLoginContainerProps,
  SettingsSecurityPasswordContainer
} from 'App/containers'

export type TSettingsSecurityAndLoginProps = TSettingsSecurityAndLoginContainerProps

type TSettingsSecurityMapProps = Pick<
  TSettingsSecurityAndLoginContainerProps,
  'hasConnectedAccount'
> & { t: TFunction }

const SETTINGS_SECURITY_AND_LOGIN_MAP = new Map<
  AccountTypeNames,
  (props: TSettingsSecurityMapProps) => JSX.Element
>()
  .set(AccountTypeNames.PROFESSIONAL, ({ hasConnectedAccount, t }) =>
    hasConnectedAccount ? (
      <>
        <SettingsSecurityDefaultAccountContainer />
        <SettingsSecurityPasswordContainer />
        <SettingsSecurityAndLogin2FAContainer />
        <SettingsSecurityDeleteAccount
          description={t('settings.security.deleteAccount.description_professional')}
        />
      </>
    ) : (
      <>
        <SettingsSecurityPasswordContainer />
        <SettingsSecurityAndLogin2FAContainer />
        <SettingsSecurityDeleteAccount
          description={t('settings.security.deleteAccount.description_professional')}
        />
      </>
    )
  )
  .set(AccountTypeNames.BUSINESS, ({ t }) => (
    <>
      <SettingsSecurityDeleteAccount
        description={t('settings.security.deleteAccount.description_business')}
      />
    </>
  ))

export const SettingsSecurityAndLogin = ({
  accountType,
  loading,
  getAccountSettings,
  getRelatedAccounts,
  hasConnectedAccount,
  getWhoAmi
}: TSettingsSecurityAndLoginProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    getAccountSettings()
    getRelatedAccounts()
    getWhoAmi()
  }, [getAccountSettings, getRelatedAccounts, getWhoAmi])

  return (
    <SettingsList loading={loading}>
      {getMapComponent(SETTINGS_SECURITY_AND_LOGIN_MAP, accountType, { hasConnectedAccount, t })}
    </SettingsList>
  )
}
