import { actionCreator } from 'store'
import { TError } from 'store/store.types'
import {
  INotificationsCommunity,
  INotificationsEvent,
  INotificationsGeneralChats,
  INotificationsOrganization
} from 'interfaces'

import {
  TClearNotificationActionPayload,
  TClearNotificationsProtocolUserCountActionPayload,
  TGetNotificationsGeneralSuccessPayload,
  TGetNotificationsProtocolRequestPayload,
  TGetNotificationsProtocolSuccessPayload,
  TGetNotificationsProtocolUsersRequestPayload,
  TGetNotificationsProtocolUsersSuccessPayload,
  TMessagePushUpPayload,
  TNormalizedCommunitiesNotifications,
  TNormalizedEventsNotifications,
  TNormalizedOrganizationNotifications,
  TNotificationsGeneralChats,
  TReadNotificationFromIdRequestPayload,
  TRefreshNotificationCountersPayload,
  TRemovedContactReceivePayload
} from './notifications.types'
import * as actionTypes from './notifications.actionTypes'

// ------------------ GET_NOTIFICATIONS_GENERAL ------------------

export const getNotificationsGeneralRequest = () =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_GENERAL_REQUEST)
export const getNotificationsGeneralSuccess = (payload: TGetNotificationsGeneralSuccessPayload) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_GENERAL_SUCCESS, payload)
export const getNotificationsGeneralError = (payload: TError) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_GENERAL_ERROR, payload)

// ------------------ READ_NOTIFICATION_FROM_ID ------------------

export const readNotificationFromIdRequest = (payload: TReadNotificationFromIdRequestPayload) =>
  actionCreator(actionTypes.READ_NOTIFICATION_FROM_ID_REQUEST, payload)

export const readNotificationFromIdSuccess = () =>
  actionCreator(actionTypes.READ_NOTIFICATION_FROM_ID_SUCCESS)
export const readNotificationFromIdError = (payload: TError) =>
  actionCreator(actionTypes.READ_NOTIFICATION_FROM_ID_ERROR, payload)

// ------------------ CLEAR_NOTIFICATION ------------------

export const clearNotificationAction = (payload: TClearNotificationActionPayload) =>
  actionCreator(actionTypes.CLEAR_NOTIFICATION, payload)

// ------------------ MESSAGE_PUSH_UP ------------------

export const messagePushUp = (payload: TMessagePushUpPayload) =>
  actionCreator(actionTypes.MESSAGE_PUSH_UP, payload)

// ------------------ GET_NOTIFICATIONS_PROTOCOL_USERS ------------------

export const getNotificationsProtocolUsersRequest = (
  payload?: TGetNotificationsProtocolUsersRequestPayload
) => actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_USERS_REQUEST, payload)
export const getNotificationsProtocolUsersSuccess = (
  payload: TGetNotificationsProtocolUsersSuccessPayload
) => actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_USERS_SUCCESS, payload)
export const getNotificationsProtocolUsersError = (payload: TError) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_USERS_ERROR, payload)

// ------------------ GET_NOTIFICATIONS_PROTOCOL ------------------

export const getNotificationsProtocolRequest = (payload: TGetNotificationsProtocolRequestPayload) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_REQUEST, payload)
export const getNotificationsProtocolSuccess = (payload: TGetNotificationsProtocolSuccessPayload) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_SUCCESS, payload)
export const getNotificationsProtocolError = (payload: TError) =>
  actionCreator(actionTypes.GET_NOTIFICATIONS_PROTOCOL_ERROR, payload)

// ------------------ CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT ------------------

export const clearNotificationsProtocolUserCountAction = (
  payload: TClearNotificationsProtocolUserCountActionPayload
) => actionCreator(actionTypes.CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT, payload)

// ------------------ REFRESH_NOTIFICATION_COUNTERS ------------------

export const refreshNotificationCountersSubscribe = () =>
  actionCreator(actionTypes.REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE)
export const refreshNotificationCountersUnsubscribe = () =>
  actionCreator(actionTypes.REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE)
export const refreshNotificationCountersRequest = () =>
  actionCreator(actionTypes.REFRESH_NOTIFICATION_COUNTERS_REQUEST)
export const refreshNotificationCountersSuccess = (payload: TRefreshNotificationCountersPayload) =>
  actionCreator(actionTypes.REFRESH_NOTIFICATION_COUNTERS_SUCCESS, payload)
export const refreshNotificationCountersError = (payload: TError) =>
  actionCreator(actionTypes.REFRESH_NOTIFICATION_COUNTERS_ERROR, payload)

// ------------------ RECEIVE_NEW_MESSAGE_NOTIFICATIONS ------------------

export const receiveNewMessageNotificationsRequest = (payload: INotificationsGeneralChats) =>
  actionCreator(actionTypes.RECEIVE_NEW_MESSAGE_NOTIFICATIONS_REQUEST, payload)

export const receiveNewMessageNotificationsSuccess = (payload: TNotificationsGeneralChats) =>
  actionCreator(actionTypes.RECEIVE_NEW_MESSAGE_NOTIFICATIONS_SUCCESS, payload)

// ------------------ REMOVED_CONTACT_RECEIVE ------------------

export const removeContactReceive = (payload: TRemovedContactReceivePayload) =>
  actionCreator(actionTypes.REMOVED_CONTACT_RECEIVE, payload)

// ------------------ RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS ------------------

export const receiveNewOrganizationNotifications = (payload: INotificationsOrganization) =>
  actionCreator(actionTypes.RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS, payload)

export const receiveNewOrganizationNotificationsSuccess = (
  payload: TNormalizedOrganizationNotifications
) => actionCreator(actionTypes.RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS_SUCCESS, payload)

// ------------------ RECEIVE_NEW_COMMUNITY_NOTIFICATIONS ------------------

export const receiveNewCommunityNotifications = (payload: INotificationsCommunity[]) =>
  actionCreator(actionTypes.RECEIVE_NEW_COMMUNITY_NOTIFICATIONS, payload)

export const receiveNewCommunityNotificationsSuccess = (
  payload: TNormalizedCommunitiesNotifications
) => actionCreator(actionTypes.RECEIVE_NEW_COMMUNITY_NOTIFICATIONS_SUCCESS, payload)

// ------------------ RECEIVE_NEW_EVENT_NOTIFICATIONS ------------------

export const receiveNewEventNotifications = (payload: INotificationsEvent[]) =>
  actionCreator(actionTypes.RECEIVE_NEW_EVENT_NOTIFICATIONS, payload)

export const receiveNewEventNotificationsSuccess = (payload: TNormalizedEventsNotifications) =>
  actionCreator(actionTypes.RECEIVE_NEW_EVENT_NOTIFICATIONS_SUCCESS, payload)
