import { FC } from 'react'

import { useAdaptiveLayout } from 'App/hooks'

import { Nav, TNavClasses } from './components/Nav'
import { Header } from './components/Header'
import styles from './Layout.module.scss'
import { useNavConfig, useNavItems } from './components/Nav/nav.constants'

const classes: TNavClasses['classes'] = {
  nav: styles.nav,
  link: styles.navLink,
  linkActive: styles.navLinkActive,
  iconWrapper: styles.navLinkIconWrapper,
  text: styles.navLinkText,
  group: styles.group
}

export const Layout: FC = ({ children }) => {
  const { isMobile } = useAdaptiveLayout()
  const navConfig = useNavConfig()

  const navList = isMobile ? navConfig.mobileNav : navConfig.mainNav
  const list = useNavItems({ classes, list: navList })

  return (
    <div className={styles.root}>
      <Header />
      <section className={styles.wrapper}>
        <aside className={styles.aside}>
          <Nav list={list} classes={classes} />
        </aside>

        <main className={styles.main}>
          <div className={styles.mainContent}>{children}</div>
        </main>
      </section>
    </div>
  )
}
