import { createContext, ReactNode } from 'react'

export type TScrollToEdgeButtonContext = {
  targetElement: HTMLElement | Window | null
}

export const scrollToEdgeButtonContext = createContext<TScrollToEdgeButtonContext>({
  targetElement: null
})

export type TTScrollToEdgeButtonContextProviderProps = { children: ReactNode } & Pick<
  TScrollToEdgeButtonContext,
  'targetElement'
>

export const ScrollToEdgeButtonContextProvider = ({
  children,
  targetElement
}: TTScrollToEdgeButtonContextProviderProps) => (
  <scrollToEdgeButtonContext.Provider value={{ targetElement }}>
    {children}
  </scrollToEdgeButtonContext.Provider>
)
