import cls from 'classnames'
import { VideoQualityEnum } from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { TVideoMetadata } from 'interfaces'
import { getMapComponent } from 'utils'
import { useAppSelector, getVideoStreamingItemSelector } from 'store'
import { Tooltip } from 'App/components'
import { ReactComponent as SpinnerIcon } from 'assets/icons/Spinner.svg'

import styles from './VideoReadinessStatus.module.scss'

export type TVideoReadinessStatusSize = 'lg' | 'md' | 'sm'
export type TVideoReadinessStatusVariant = 'default' | 'inline' | 'icon'
export type TVideoReadinessStatusColor = 'default' | 'white'

export type TVideoReadinessStatusProps = PropsWithClassName<{
  fileId: string

  variant?: TVideoReadinessStatusVariant
  color?: TVideoReadinessStatusColor
  size?: TVideoReadinessStatusSize
}>

type TStatusMapProps = Pick<TVideoReadinessStatusProps, 'className' | 'variant'> & {
  t: TFunction
}

const STATUS = new Map<TVideoMetadata['quality'], (props: TStatusMapProps) => JSX.Element>()
  .set(VideoQualityEnum.HD, ({ className, t }) => (
    <Tooltip title={t('files.videoStatus.tooltip_hd')}>
      <div className={cls(className, styles.hd)}>HD</div>
    </Tooltip>
  ))
  .set(VideoQualityEnum.HQ, ({ className, t }) => (
    <Tooltip title={t('files.videoStatus.tooltip_sd')}>
      <div className={cls(className, styles.sd)}>SD</div>
    </Tooltip>
  ))
  .set(VideoQualityEnum.SD, ({ className, t }) => (
    <Tooltip title={t('files.videoStatus.tooltip_sd')}>
      <div className={cls(className, styles.sd)}>SD</div>
    </Tooltip>
  ))
  .set(null, ({ className, variant, t }) => (
    <Tooltip title={t('files.videoStatus.tooltip')}>
      <div className={cls(className, styles.processing)}>
        {variant !== 'icon' && t('files.videoStatus.processing')}
        <SpinnerIcon />
      </div>
    </Tooltip>
  ))

export const VideoReadinessStatus = ({
  fileId,
  className,
  variant = 'default',
  color = 'default',
  size = 'md'
}: TVideoReadinessStatusProps) => {
  const { t } = useTranslation()

  const { videoMetadata } = useAppSelector(getVideoStreamingItemSelector(fileId))

  return videoMetadata
    ? getMapComponent(STATUS, videoMetadata.quality, {
        variant,
        className: cls(styles.root, styles[size], styles[variant], styles[color], className),
        t
      })
    : null
}
