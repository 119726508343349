import { action, TError } from 'store'
import {
  TCategoryListData,
  TGetCategoryListRequestPayload,
  TSetCategorySearchActionPayload
} from 'store/categoryList/categoryList.types'

// ------------------ GET_CATEGORY_LIST ------------------

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST'
export const GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST'
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS'
export const GET_CATEGORY_LIST_ERROR = 'GET_CATEGORY_LIST_ERROR'

export const getCategoryListRequest = (payload: TGetCategoryListRequestPayload) =>
  action(GET_CATEGORY_LIST_REQUEST, payload)

export const getCategoryListSuccess = (payload: TCategoryListData[]) =>
  action(GET_CATEGORY_LIST_SUCCESS, payload)

export const getCategoryListError = (payload: TError) => action(GET_CATEGORY_LIST_ERROR, payload)

// ------------------ SET_CATEGORY_SEARCH ------------------
export const SET_CATEGORY_SEARCH = 'SET_CATEGORY_SEARCH'

export const setCategorySearchAction = (payload: TSetCategorySearchActionPayload) =>
  action(SET_CATEGORY_SEARCH, payload)

// ------------------ RESET_CATEGORY_LIST ------------------
export const RESET_CATEGORY_LIST = 'RESET_CATEGORY_LIST'

export const resetCategoryList = () => action(RESET_CATEGORY_LIST)
