import { normalize, schema } from 'normalizr'

import {
  TMedCloudFileNormalized,
  TMedCloudFileNormalizeResult,
  TFileUpload
} from './medCloud.types'

const filesSchema = new schema.Entity('list')
const fileListSchema = new schema.Array(filesSchema)

export const medCloudFileNormalize = (originalData: TFileUpload[]): TMedCloudFileNormalized => {
  const { entities, result }: TMedCloudFileNormalizeResult = normalize(originalData, fileListSchema)

  return {
    fileList: entities.list || {},
    fileIds: result || []
  }
}
