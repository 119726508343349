import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { Calls } from 'App/components'
import { toggleCallsOrSoundsRequest } from 'store'

export type TCallsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  rejectCalls: state.settings.accountAndNotifications.calls.rejectCalls
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleCallsOrSounds: toggleCallsOrSoundsRequest
    },
    dispatch
  )

export const CallsContainer = connect(mapStateToProps, mapDispatchToProps)(Calls)
