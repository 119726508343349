import { memo, useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
  ETableColumnFilters,
  FilterPopover,
  CaseHistoryFilter,
  TFilterDropdownProps,
  THandleFilterConfirm,
  TSelectedKeysOptions
} from 'App/components'
import { EModalComponents, TFilterDropdownContainerProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'

export type TFilterDropdownOwnProps<T extends TSelectedKeysOptions> = TFilterDropdownProps<T> & {
  type: ETableColumnFilters
}

type TFilterDropdown<T extends TSelectedKeysOptions> = TFilterDropdownContainerProps &
  TFilterDropdownOwnProps<T> & {
    setFilterDropdownVisible: (filter: ETableColumnFilters, state: boolean) => void
    handleFilterConfirm: THandleFilterConfirm
    visible: boolean
  }

const areEqual = (
  prevProps: TFilterDropdown<TSelectedKeysOptions>,
  nextProps: TFilterDropdown<TSelectedKeysOptions>
) =>
  isEqual(prevProps.filters, nextProps.filters) &&
  isEqual(prevProps.selectedKeys, nextProps.selectedKeys) &&
  prevProps.visible === nextProps.visible

const FilterDropdownView = ({
  type,
  visible,
  selectedKeys,
  filters,
  setFilterDropdownVisible,
  confirm,
  clearFilters,
  showModal,
  setSelectedKeys,
  handleFilterConfirm,
  hideModal
}: TFilterDropdown<TSelectedKeysOptions>) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleDropdownConfirm = useCallback(() => {
    handleFilterConfirm(selectedKeys, type, confirm)
    isMobile && hideModal()
  }, [selectedKeys, type, isMobile, hideModal, confirm, handleFilterConfirm])

  useEffect(() => {
    if (visible && isMobile) {
      showModal({
        modalType: EModalComponents.SET_CASE_HISTORY_FILTERS,
        modalTitle: t(`cases.caseHistory.filters.${type}.title`),
        modalProps: {
          type,
          filters,
          selectedKeys,
          setSelectedKeys,
          clearFilters,
          handleDropdownConfirm,
          onClose: () => setFilterDropdownVisible(type, false)
        }
      })
    }
  }, [
    visible,
    isMobile,
    type,
    filters,
    selectedKeys,
    showModal,
    setSelectedKeys,
    clearFilters,
    handleFilterConfirm,
    setFilterDropdownVisible,
    handleDropdownConfirm,
    t
  ])

  if (visible && !isMobile) {
    return (
      <FilterPopover
        visible={visible}
        onVisibleChange={(visibleState) => {
          setFilterDropdownVisible(type, visibleState)
        }}
        content={
          <CaseHistoryFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={handleDropdownConfirm}
            clearFilters={clearFilters}
            filters={filters}
            type={type}
          />
        }
      />
    )
  }

  return null
}

export const FilterDropdown = memo(FilterDropdownView, areEqual)
