export const GET_USER_BALANCE = 'GET_USER_BALANCE'
export const GET_USER_BALANCE_REQUEST = 'GET_USER_BALANCE_REQUEST'
export const GET_USER_BALANCE_SUCCESS = 'GET_USER_BALANCE_SUCCESS'
export const GET_USER_BALANCE_ERROR = 'GET_USER_BALANCE_ERROR'

export const GET_OPERATIONS_HISTORY = 'GET_OPERATIONS_HISTORY'
export const GET_OPERATIONS_HISTORY_REQUEST = 'GET_OPERATIONS_HISTORY_REQUEST'
export const GET_OPERATIONS_HISTORY_SUCCESS = 'GET_OPERATIONS_HISTORY_SUCCESS'
export const GET_OPERATIONS_HISTORY_ERROR = 'GET_OPERATIONS_HISTORY_ERROR'

export const SET_OPERATIONS_HISTORY_FILTER = 'SET_OPERATIONS_HISTORY_FILTER'

export const CLEAR_OPERATIONS_HISTORY = 'CLEAR_OPERATIONS_HISTORY'

export const RECEIVE_PAYMENT_SUCCEEDED = 'RECEIVE_PAYMENT_SUCCEEDED'
export const RECEIVE_PAYMENT_FAILED = 'RECEIVE_PAYMENT_FAILED'

export const TRANSFER_MDT = 'TRANSFER_MDT'
export const TRANSFER_MDT_REQUEST = 'TRANSFER_MDT_REQUEST'
export const TRANSFER_MDT_SUCCESS = 'TRANSFER_MDT_SUCCESS'
export const TRANSFER_MDT_ERROR = 'TRANSFER_MDT_ERROR'

export const AUTO_TOP_UP_SET = 'AUTO_TOP_UP_SET'
export const AUTO_TOP_UP_CANCELED = 'AUTO_TOP_UP_CANCELED'
