import { useTranslation } from 'react-i18next'

import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'

import { AvatarWrapper, TAvatarWrapperProps } from '../AvatarWrapper'

import styles from './AvatarTeam.module.scss'

export type TAvatarTeamProps = Pick<
  TAvatarWrapperProps,
  'size' | 'border' | 'onlineBorderColor' | 'onlineBorderCssRootPropertyValue' | 'online' | 'muted'
>

export const AvatarTeam = ({
  size,
  border,
  online,
  onlineBorderColor,
  onlineBorderCssRootPropertyValue,
  muted
}: TAvatarTeamProps) => {
  const { t } = useTranslation()

  return (
    <AvatarWrapper
      tooltip={t('common.avatar.teamTooltip')}
      size={size}
      online={online}
      onlineBorderColor={onlineBorderColor}
      onlineBorderCssRootPropertyValue={onlineBorderCssRootPropertyValue}
      border={border}
      muted={muted}
      className={styles.root}
    >
      <AccountGroupIcon />
    </AvatarWrapper>
  )
}
