import { useEffect } from 'react'
import { AccountTypeNames, CompanySizeType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  CHANGE_USER_PROFILE_AVATAR,
  changeUserProfileAvatarRequest,
  DELETE_USER_PROFILE_AVATAR,
  deleteUserProfileAvatarRequest,
  removeErrorNotification,
  useAppDispatch
} from 'store'
import { getMapComponent } from 'utils'
import {
  BusinessAccountBasicInformationFormContainer,
  EModalComponents,
  TUserProfileBasicInformationProps,
  UserProfileBasicInformationPrivateFormContainer,
  TUserProfileBasicInformationFormValues,
  TBusinessAccountBasicInformationFormValues
} from 'App/containers'
import { UploadAvatar } from 'App/components/common'

import { useUnsavedDataPrompt } from '../../../hooks/useUnsavedDataPrompt'

import styles from './UserProfileBasicInformation.module.scss'

export type TFormCommonMethods = {
  onFormChange: () => void
}

type TFromMapProps = TFormCommonMethods & {
  onSubmitBusinessAccount: (values: TBusinessAccountBasicInformationFormValues) => void
  onSubmit: (values: TUserProfileBasicInformationFormValues) => void
}

const FORM_MAP = new Map<AccountTypeNames, (props: TFromMapProps) => JSX.Element>()
  .set(AccountTypeNames.PROFESSIONAL, ({ onSubmit, onFormChange }) => (
    <UserProfileBasicInformationPrivateFormContainer
      onSubmit={onSubmit}
      onFormChange={onFormChange}
    />
  ))
  .set(AccountTypeNames.BUSINESS, ({ onSubmitBusinessAccount, onFormChange }) => (
    <BusinessAccountBasicInformationFormContainer
      onSubmit={onSubmitBusinessAccount}
      onFormChange={onFormChange}
    />
  ))

export const UserProfileBasicInformation = ({
  loading,
  userId,
  firstName,
  lastName,
  displayUserName,
  type,
  accountType,
  editProfile,
  showModal,
  editBusinessProfile,
  loadUserProfile,
  loadCountries,
  loadCompanySpecializations
}: TUserProfileBasicInformationProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { prompt, formChanged, setFormChanged } = useUnsavedDataPrompt()

  useEffect(() => {
    loadUserProfile()
    loadCountries()

    if (accountType === AccountTypeNames.BUSINESS) {
      loadCompanySpecializations()
    }
  }, [accountType, loadCompanySpecializations, loadCountries, loadUserProfile])

  const onFormChange = () => {
    if (!formChanged) {
      setFormChanged(true)
    }
  }

  const clearFormChanged = () => {
    setFormChanged(false)
  }

  const onSubmit = (values: TUserProfileBasicInformationFormValues) => {
    editProfile({
      about: values.about || '',
      headline: values.headline || '',
      cityId: `${values.city.id}`,
      countryCode: `${values.country.code}`,
      onSuccess: clearFormChanged
    })
  }

  const onSubmitBusinessAccount = (values: TBusinessAccountBasicInformationFormValues) => {
    editBusinessProfile({
      cityId: `${values.city.id}`,
      countryCode: values.country.code,
      zip: `${values.zip}`,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2 || '',
      size: values.size.value as CompanySizeType,
      about: values.about || '',
      headline: values.headline || '',
      onSuccess: clearFormChanged
    })
  }

  const onRemoveAvatar = () => {
    showModal({
      modalTitle: t('modal.removeImageConfirm.title_userAvatar'),
      modalType: EModalComponents.DELETE_IMAGE_DIALOG,
      modalProps: {
        content: t('modal.removeImageConfirm.content_userAvatar'),
        onClick: () => {
          dispatch(removeErrorNotification(DELETE_USER_PROFILE_AVATAR))
          dispatch(deleteUserProfileAvatarRequest())
        }
      }
    })
  }

  const onFileUpload = (file: File) => {
    showModal({
      modalTitle: t('modal.uploadImage.title_userAvatar'),
      modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
      modalProps: {
        buttonContent: t('modal.uploadImage.submitButton_userAvatar'),
        imageSrc: URL.createObjectURL(file),
        onCrop: (coppedFile) => {
          dispatch(removeErrorNotification(CHANGE_USER_PROFILE_AVATAR))
          dispatch(changeUserProfileAvatarRequest({ file: coppedFile }))
        }
      }
    })
  }

  return (
    <div className={styles.root}>
      {prompt}

      <UploadAvatar
        loading={loading}
        firstName={firstName}
        lastName={lastName}
        displayUserName={displayUserName}
        type={type}
        userId={userId}
        onFileUpload={onFileUpload}
        onRemoveAvatar={onRemoveAvatar}
        classes={{
          root: styles.avatar,
          skeleton: styles.avatarSkeleton,
          remove: styles.avatarRemove,
          removeIcon: styles.avatarRemoveIcon
        }}
      />

      {getMapComponent(FORM_MAP, type, {
        onFormChange,
        onSubmit,
        onSubmitBusinessAccount
      })}
    </div>
  )
}
