import { useCallback, useState } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import cls from 'classnames'

import { IconButton } from 'App/components'
import { EIconSize } from 'enums'
import { IFilesEntity } from 'interfaces'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { Preview } from '../Preview'
import { Uploading, TUploadingProps } from '../Uploading'
import { Chip } from '../Chip'

import styles from './FileWrapper.module.scss'

export type TFileWrapperProps = Pick<
  TUploadingProps,
  'uuid' | 'onUploadSuccess' | 'onUploadError' | 'showcaseId'
> & {
  file: File | IFilesEntity
  files: IFilesEntity[]
  onDelete: (id: string, update?: boolean) => void

  orderNumber?: number
}

export const FileWrapper = ({
  file,
  uuid,
  orderNumber,
  files,
  showcaseId,
  onDelete,
  onUploadSuccess,
  onUploadError
}: TFileWrapperProps) => {
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true)

  const isUploadedFile = !(file instanceof File)

  const shouldShowDelete = !deleteDisabled || isUploadedFile

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: uuid,
    disabled: !isUploadedFile
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const handleDelete = useCallback(
    (update?: boolean) => {
      onDelete(uuid, update)
    },
    [onDelete, uuid]
  )

  return (
    <div
      className={cls(styles.root, {
        [styles.dragging]: isDragging,
        [styles.disabled]: !isUploadedFile
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {shouldShowDelete && (
        <Chip className={styles.delete}>
          <IconButton
            iconComponent={<CrossIcon />}
            onClick={handleDelete}
            iconSize={EIconSize.SM}
          />
        </Chip>
      )}
      {file instanceof File ? (
        <Uploading
          file={file}
          uuid={uuid}
          showcaseId={showcaseId}
          onDelete={handleDelete}
          setDeleteDisabled={setDeleteDisabled}
          onUploadSuccess={onUploadSuccess}
          onUploadError={onUploadError}
        />
      ) : (
        orderNumber && <Preview file={file} files={files} orderNumber={orderNumber} />
      )}
    </div>
  )
}
