import { TruncatedText } from '../../../../../components'

import styles from './ErrorMessage.module.scss'

export type TErrorMessageProps = {
  message?: string
}

export const ErrorMessage = ({ message }: TErrorMessageProps) =>
  message ? <TruncatedText rows="two" classes={{ root: styles.root }} text={message} /> : null
