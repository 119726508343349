import { TMeetingsAction, TMeetingsState } from './meetings.types'
import * as actionTypes from './meetings.actionTypes'

export const initialMeetingsState: TMeetingsState = {
  list: {},
  groupIds: [],
  groupList: {}
}

export const meetingsReducer = (
  state: TMeetingsState = initialMeetingsState,
  action: TMeetingsAction
): TMeetingsState => {
  switch (action.type) {
    case actionTypes.GET_MEETINGS_SUCCESS: {
      return {
        ...state,
        ...action.payload.meetings
      }
    }
    default:
      return state
  }
}
