import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components/common'

type TChatCreateGroupRemoveMemberConfirmProps = {
  onClick: () => void
}

export const ChatCreateGroupRemoveMemberConfirm = ({
  onClick
}: TChatCreateGroupRemoveMemberConfirmProps) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={t('modal.removeGroupChatMemberConfirm.content')}
      controls={
        <Button onClick={onClick}>{t('modal.removeGroupChatMemberConfirm.submitButton')}</Button>
      }
    />
  )
}
