import config from 'config'
import { TJitsiMeetExternalAPI } from 'types'

export const importJitsiApi = (): Promise<TJitsiMeetExternalAPI> =>
  new Promise<TJitsiMeetExternalAPI>(async (resolve) => {
    if (window.JitsiMeetExternalAPI) {
      resolve(window.JitsiMeetExternalAPI)
    } else {
      const head = document.getElementsByTagName('head')[0]
      const script = document.createElement('script')

      script.setAttribute('type', 'text/javascript')
      script.setAttribute('src', `${config.jitsiMeetUrl}/external_api.js`)

      head.addEventListener(
        'load',
        (event) => {
          if ((event.target as HTMLScriptElement)?.nodeName === 'SCRIPT') {
            resolve(window.JitsiMeetExternalAPI)
          }
        },
        true
      )

      head.appendChild(script)
    }
  })
