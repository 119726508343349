import { useCallback, useState } from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { SortingTable } from 'types'
import { ESorting } from 'enums'
import { Button, Divider } from 'App/components'

import styles from './FilesTilesViewSortingDialog.module.scss'

type TSortingList = {
  title: string
  key: string
}

export type TFilesTilesViewSortingDialogProps = {
  onSorting: (key: string, direction: ESorting) => void
  sorting: SortingTable
  sortingList: TSortingList[]
}

export const FilesTilesViewSortingDialog = ({
  onSorting,
  sortingList,
  sorting
}: TFilesTilesViewSortingDialogProps) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(sorting.name)
  const [direction, setDirection] = useState<ESorting>(sorting.direction)

  const handleSort = useCallback(() => onSorting(value, direction), [direction, onSorting, value])

  const handleValueChange = useCallback(
    ({ target }: RadioChangeEvent) => setValue(target.value),
    []
  )

  const handleDirectionChange = useCallback(
    ({ target }: RadioChangeEvent) => setDirection(target.value),
    []
  )

  return (
    <>
      <Radio.Group value={value} className={styles.radio} onChange={handleValueChange}>
        {sortingList.map(({ key, title }) => (
          <Radio key={key} value={key} className={styles.radioItem}>
            {title}
          </Radio>
        ))}
      </Radio.Group>

      <Divider className={styles.divider} />

      <Radio.Group value={direction} className={styles.radio} onChange={handleDirectionChange}>
        <Radio value={ESorting.ASC} className={styles.radioItem}>
          {t('modal.filesSortBy.ascendingRadio')}
        </Radio>
        <Radio value={ESorting.DESC} className={styles.radioItem}>
          {t('modal.filesSortBy.descendingRadio')}
        </Radio>
      </Radio.Group>

      <Button className={styles.button} onClick={handleSort} size="xs">
        {t('modal.filesSortBy.submitButton')}
      </Button>
    </>
  )
}
