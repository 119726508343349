import { Ref } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'
import { CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ICaseOpinionEntity } from 'interfaces'
import { CaseDetailsDate, CaseDetailsInfo, NewDividerLine } from 'App/components'

import styles from './CaseOpinionsItem.module.scss'

export type TCaseOpinionsItemProps = Pick<ICaseOpinionEntity, 'data' | 'createdAt'> & {
  loading: boolean
  isOwner: boolean
  newDividerLineRef: Ref<HTMLDivElement>

  showDividerLine?: boolean
  publicOpinions?: boolean
  caseType?: CaseTypesEnum | null
  opinionCreator?: string
}

export const CaseOpinionsItem = ({
  createdAt,
  data,
  loading,
  isOwner,
  newDividerLineRef,
  opinionCreator,
  caseType,
  showDividerLine = false
}: TCaseOpinionsItemProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={cls({
        [styles.root]: true,
        [styles.allOpinions]: isOwner || caseType === CaseTypesEnum.A2A
      })}
    >
      <Skeleton
        loading={loading}
        active={true}
        paragraph={{ rows: 2, width: '100%' }}
        title={{ width: '25%' }}
        className={styles.skeleton}
      >
        {showDividerLine && !loading && (
          <NewDividerLine classNames={styles.divider} ref={newDividerLineRef}>
            {t('cases.details.opinions.newOpinionDivider')}
          </NewDividerLine>
        )}

        <CaseDetailsDate
          date={createdAt}
          getLabel={(formattedDate) => (
            <>
              <span className={styles.opinionCreator}>{!isOwner && opinionCreator}</span>
              {formattedDate}
            </>
          )}
          className={styles.date}
        />
        <CaseDetailsInfo data={data} className={styles.info} />
      </Skeleton>
    </div>
  )
}
