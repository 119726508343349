import { Link, LinkProps } from 'react-router-dom'

import styles from './AlertLink.module.scss'

export type TAlertLinkProps = LinkProps

export const AlertLink = ({ children, ...rest }: TAlertLinkProps) => (
  <Link {...rest}>
    <span className={styles.link}>{children}</span>
  </Link>
)
