import { SubscriptionIdsEnum } from '@medentee/enums'

import { API, api, APIData } from '../services/api'

export type TSubscriptionInfo = {
  id: SubscriptionIdsEnum
  name: string
  price: number
  duration: string
  businessAdminLimit: number
  caseMembersDirectLimit: number
  caseMembersGroupLimit: number
  medCloudCapacity: number
  medCloudMaxUploadFileSize: number
  isActive: boolean
  isFuture: boolean
  participatingAdminLimit: number
}

export const getSubscriptionsInfo = async () => {
  const { data }: APIData<TSubscriptionInfo[]> = await api.get(API.SUBSCRIPTIONS_INFO)

  return data
}
