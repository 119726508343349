import { useCallback, useMemo } from 'react'
import { ChatStatusEnum, ChatTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  createProcessingSelector,
  hideModalAction,
  isCallInProgressSelector,
  isChatRoomMutedSelector,
  SEND_CREATE_CALL,
  sendCreateCallRequest,
  showModalAction,
  useAppSelector
} from 'store'
import { EModalComponents, useMuteTextChannel } from 'App/containers'
import { ContextMenu, IconButton, TMenuItemProps } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { IChatRoomsDTO } from 'interfaces/api/chat/chatRooms'
import { useChatContext } from 'App/context'
import { EChatViewType, EIconSize } from 'enums'
import { CHAT_PATH, EChatTypeMap, EDIT_GROUP_CHAT_PATH, toastDefaultOptions } from 'globalConstants'
import { mapCaseTypeToChatType } from 'utils/chat'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as VideoIcon } from 'assets/icons/Video.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageTextOutline.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/VolumeUp.svg'
import { ReactComponent as VolumeOffIcon } from 'assets/icons/VolumeOff.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg'
import { ReactComponent as PinListIcon } from 'assets/icons/PinList.svg'
import { toast } from 'App/components/ToastContainer'

import styles from './ChatRoomHeaderActions.module.scss'

export type TChatRoomHeaderActionsProps = Pick<IChatRoomsDTO, 'id' | 'type' | 'status'> & {
  caseId?: string
  iconSize?: EIconSize
  isOwner?: boolean
  chatId?: string
  userName?: string
  classes?: Partial<Record<'icon', string>>
  isContact?: boolean
  isChatOwner?: boolean
}

const processingSelector = createProcessingSelector([SEND_CREATE_CALL])

export const ChatRoomHeaderActions = ({
  iconSize = EIconSize.MD,
  caseId,
  id,
  status,
  type,
  isOwner,
  classes,
  userName,
  isContact,
  isChatOwner
}: TChatRoomHeaderActionsProps) => {
  const dispatch = useDispatch()

  const { push } = useHistory()

  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const {
    chatViewType,
    showChatDetails,
    showPinnedMessages,
    displayChatRoomSearch,
    togglePinnedMessages
  } = useChatContext()

  const caseTitle = useAppSelector((state) =>
    state.chat.chatRooms.selectedChat?.case?.id === caseId
      ? state.chat.chatRooms.selectedChat?.case?.title
      : undefined
  )

  const processing = useAppSelector(processingSelector)
  const isChatRoomMuted = useAppSelector(isChatRoomMutedSelector(id))
  const isCallBusy = useAppSelector(isCallInProgressSelector)
  const isChatPinEmpty = useAppSelector((state) => !state.chat.chatPin.total)

  const match = useRouteMatch(CHAT_PATH)

  const { getInnerMenuItems, handleUnmuteTextChannel } = useMuteTextChannel()

  const isCaseGroupChat = type === ChatTypeEnum.CASE_GROUP
  const isGroupChat = type === ChatTypeEnum.GROUP
  const isOrganizationChannel = type === ChatTypeEnum.ORGANIZATION
  const isCommunityChannel = type === ChatTypeEnum.COMMUNITY_CHANNEL
  const isCommunityNews = type === ChatTypeEnum.COMMUNITY_NEWS
  const isEventChannel = type === ChatTypeEnum.EVENT_CHANNEL
  const isEventNews = type === ChatTypeEnum.EVENT_NEWS

  const isCallDisabled = !isOwner && isCaseGroupChat
  const isCallButtonDisabled = isCallDisabled || processing
  const isChatStatusActive = status === ChatStatusEnum.ACTIVE
  const isChatStatusInactive = status === ChatStatusEnum.INACTIVE

  const onClearAllMessages = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.DELETE_ALL_MESSAGES_CONFIRM,
        modalTitle: t('modal.deleteAllMessagesConfirm.title'),
        modalProps: { chatId: id }
      })
    )
  }, [id, dispatch, t])

  const startCall = useCallback(
    (video = false) => {
      if (isCallBusy) {
        toast.error(t('serverError.ANOTHER_CALL_EXIST', { ns: 'errors' }), toastDefaultOptions)
        return
      }

      if (isCaseGroupChat && caseId) {
        dispatch(
          showModalAction({
            modalType: EModalComponents.START_GROUP_CALL_DIALOG,
            modalTitle: t('modal.addGroupCallMember.title'),
            modalProps: { chatId: id, caseId, video }
          })
        )
        return
      }

      dispatch(sendCreateCallRequest({ chatId: id, caseId, video }))
    },
    [isCallBusy, isCaseGroupChat, id, caseId, dispatch, t]
  )

  const onInitAudioCall = useCallback(() => {
    startCall()
  }, [startCall])

  const onInitVideoCall = useCallback(() => {
    startCall(true)
  }, [startCall])

  const onScheduleMeting = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.scheduleMeeting.title'),
        modalType: EModalComponents.SCHEDULE_MEETING_MODAL,
        modalProps: {
          userName,
          chatId: id,
          caseTitle,
          caseId,
          isGroupMeeting: isCaseGroupChat,
          onSuccess: () => dispatch(hideModalAction())
        }
      })
    )
  }, [id, userName, caseId, dispatch, isCaseGroupChat, caseTitle, t])

  const openChat = useCallback(() => {
    push(
      generatePath(CHAT_PATH, {
        chatType: mapCaseTypeToChatType(type),
        chatId: id
      })
    )
  }, [id, type, push])

  const handleEditChat = useCallback(() => {
    push(
      generatePath(EDIT_GROUP_CHAT_PATH, {
        chatId: id,
        chatType: EChatTypeMap.DIALOGUE
      })
    )
  }, [id, push])

  const handleDeleteChat = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.deleteGroupChatConfirm.title'),
        modalType: EModalComponents.DELETE_CHAT_CONFIRM,
        modalProps: { chatId: id }
      })
    )
  }, [dispatch, id, t])

  const handleLeaveChat = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.leaveChatConfirm.title'),
        modalType: EModalComponents.LEAVE_CHAT_CONFIRM,
        modalProps: { chatId: id }
      })
    )
  }, [dispatch, id, t])

  const createMenuItems = useCallback(
    (kebab = false): TMenuItemProps[] => {
      const isCallsNotAvailable =
        !isChatStatusActive ||
        isCallDisabled ||
        isOrganizationChannel ||
        isGroupChat ||
        isCommunityChannel ||
        isCommunityNews ||
        isEventChannel ||
        isEventNews

      const hideOnMobile = kebab ? !isMobile : isMobile

      return [
        {
          icon: <PinListIcon />,
          hidden:
            isChatPinEmpty ||
            showPinnedMessages ||
            hideOnMobile ||
            chatViewType !== EChatViewType.CASE,
          onClick: togglePinnedMessages,
          content: t('chat.kebabMenu.pin')
        },
        {
          icon: <SearchIcon />,
          hidden: showChatDetails || hideOnMobile || chatViewType === EChatViewType.CASE,
          onClick: displayChatRoomSearch,
          content: t('chat.kebabMenu.search')
        },
        {
          icon: <MessageTextIcon />,
          hidden:
            !kebab ||
            !!match ||
            isOrganizationChannel ||
            isCommunityChannel ||
            isCommunityNews ||
            isEventChannel ||
            isEventNews,
          onClick: openChat,
          content: t('chat.kebabMenu.chat')
        },
        {
          icon: <PencilOutlineIcon />,
          content: t('chat.kebabMenu.edit'),
          hidden: !kebab || !isGroupChat || !isChatOwner,
          onClick: handleEditChat
        },
        {
          icon: <VideoIcon />,
          content: t('chat.kebabMenu.video'),
          disabled: isCallButtonDisabled,
          hidden:
            (chatViewType === EChatViewType.CASE && (isCallsNotAvailable || hideOnMobile)) ||
            (chatViewType === EChatViewType.P2P && (isCallsNotAvailable || !kebab)),
          onClick: onInitVideoCall
        },
        {
          icon: <PhoneIcon />,
          content: t('chat.kebabMenu.phone'),
          disabled: isCallButtonDisabled,
          hidden: hideOnMobile || isCallsNotAvailable,
          onClick: onInitAudioCall
        },
        {
          icon: <ScheduleIcon />,
          hidden:
            !id ||
            !kebab ||
            (isCaseGroupChat && !isOwner) ||
            isOrganizationChannel ||
            isCommunityChannel ||
            isCommunityNews ||
            isEventChannel ||
            isEventNews ||
            isGroupChat ||
            (!isContact && isChatStatusInactive),
          content: t('chat.kebabMenu.meeting'),
          onClick: onScheduleMeting
        },
        {
          icon: <TrashIcon />,
          content: t('chat.kebabMenu.clean'),
          hidden:
            !kebab ||
            isOrganizationChannel ||
            isGroupChat ||
            isCommunityChannel ||
            isCommunityNews ||
            isEventChannel ||
            isEventNews,
          onClick: onClearAllMessages
        },
        {
          hidden: !kebab || (!isCaseGroupChat && !isGroupChat),
          content: isChatRoomMuted ? t('chat.kebabMenu.unmute') : t('chat.kebabMenu.mute'),
          icon: isChatRoomMuted ? <VolumeUpIcon /> : <VolumeOffIcon />,
          innerMenuItems: isChatRoomMuted ? undefined : getInnerMenuItems(id),
          onClick: isChatRoomMuted ? () => handleUnmuteTextChannel(id) : undefined
        },
        {
          icon: <TrashIcon />,
          content: t('chat.kebabMenu.delete'),
          hidden: !kebab || !isGroupChat || !isChatOwner,
          onClick: handleDeleteChat
        },
        {
          icon: <LogoutIcon />,
          content: t('chat.kebabMenu.leave'),
          hidden: !kebab || !isGroupChat || isChatOwner,
          onClick: handleLeaveChat
        }
      ]
    },
    [
      isChatStatusActive,
      isCallDisabled,
      isOrganizationChannel,
      isGroupChat,
      isCommunityChannel,
      isCommunityNews,
      isEventChannel,
      isEventNews,
      isMobile,
      showPinnedMessages,
      togglePinnedMessages,
      showChatDetails,
      chatViewType,
      displayChatRoomSearch,
      match,
      openChat,
      isChatOwner,
      handleEditChat,
      isCallButtonDisabled,
      onInitVideoCall,
      onInitAudioCall,
      id,
      isCaseGroupChat,
      isOwner,
      isContact,
      isChatStatusInactive,
      onScheduleMeting,
      onClearAllMessages,
      isChatRoomMuted,
      getInnerMenuItems,
      handleDeleteChat,
      handleLeaveChat,
      handleUnmuteTextChannel,
      isChatPinEmpty,
      t
    ]
  )

  const menuItems = useMemo(() => createMenuItems(), [createMenuItems])

  const contextMenuItems = useMemo(() => createMenuItems(true), [createMenuItems])

  return (
    <div className={styles.root}>
      {menuItems
        .filter(({ hidden }) => !hidden)
        .map(
          ({ icon, content, disabled, onClick }) =>
            icon && (
              <IconButton
                key={content}
                iconSize={iconSize}
                iconComponent={icon}
                toolTip={content}
                disabled={disabled}
                onClick={onClick}
                classes={{
                  root: cls(styles.iconButton, styles[chatViewType], classes?.icon)
                }}
              />
            )
        )}

      <ContextMenu
        classes={{ icon: cls(styles.kebabIcon, classes?.icon) }}
        placement="bottomRight"
        menuItems={contextMenuItems}
      />
    </div>
  )
}
