import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './CaseItemNotificationWrapper.module.scss'

export type TCaseItemWrapperBaseProps = {
  notificationCount: number
}

export type TCaseItemWrapperProps = PropsWithChildren<TCaseItemWrapperBaseProps>

export const CaseItemNotificationWrapper = ({
  children,
  notificationCount
}: TCaseItemWrapperProps) => (
  <div
    className={cls({
      [styles.root]: true,
      [styles.active]: notificationCount
    })}
  >
    {children}
  </div>
)
