import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getShowcasesNotificationList } from 'api/notifications'
import { generateRandomArray } from 'utils'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg'
import { EEmptyListIconSize, EmptyList } from 'App/components'
import { getNotificationsGeneralRequest, useAppDispatch } from 'store'

import { NotificationsPopoverSkeleton } from '../NotificationsPopover/NotificationsPopoverSkeleton'

import styles from './NotificationsShowcases.module.scss'
import {
  NotificationsShowcasesItem,
  TNotificationsShowcasesItemProps
} from './NotificationsShowcasesItem'

type TNotificationsShowcasesProps = Pick<TNotificationsShowcasesItemProps, 'size' | 'onClick'> & {
  page?: number
  showBy?: number
  withPagination?: boolean
  setTotal?: (value: number) => void
}

const DEFAULT_SHOW_BY = 30

const FAKE_ITEMS = generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH)

export const GET_SHOWCASE_NOTIFICATIONS_QUERY_KEY = 'get-showcases-notifications'

export const NotificationsShowcases = ({
  page,
  size,
  showBy,
  setTotal,
  onClick,
  withPagination = false
}: TNotificationsShowcasesProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: [GET_SHOWCASE_NOTIFICATIONS_QUERY_KEY, page, showBy],
    queryFn: () =>
      getShowcasesNotificationList({
        current: page,
        showBy: withPagination ? showBy : DEFAULT_SHOW_BY
      }),
    onSuccess: ({ total }) => {
      setTotal && setTotal(total)
      dispatch(getNotificationsGeneralRequest())
    }
  })

  if (isFetching) {
    return (
      <div className={styles.root}>
        {FAKE_ITEMS.map((index) => (
          <NotificationsPopoverSkeleton key={index} />
        ))}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {data?.results.length ? (
        data.results.map((item) => (
          <NotificationsShowcasesItem key={item.id} {...item} size={size} onClick={onClick} />
        ))
      ) : (
        <EmptyList
          iconSize={EEmptyListIconSize.MD}
          icon={<BellIcon />}
          text={t('placeholder_main', { ns: 'notifications' })}
        />
      )}
    </div>
  )
}
