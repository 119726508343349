import { useFormState } from 'react-final-form'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { TSocialLinkFormValues } from '../SocialLink'
import { Button } from '../../Button'

import styles from './SocialLinkSubmit.module.scss'

type TSocialLinkSubmitProps = {
  submitProcessing: boolean
  deleteProcessing: boolean
  handleSubmit: () => void
}

export const SocialLinkSubmit = ({
  deleteProcessing,
  submitProcessing,
  handleSubmit
}: TSocialLinkSubmitProps) => {
  const { submitting, submitSucceeded, dirty, valid } = useFormState<TSocialLinkFormValues>()

  const { t } = useTranslation()

  return (
    <Col>
      <Button
        variant="inline"
        disabled={
          submitting ||
          submitProcessing ||
          deleteProcessing ||
          !valid ||
          (!submitSucceeded && !dirty)
        }
        loading={submitting || submitProcessing}
        className={styles.submitButton}
        onClick={handleSubmit}
      >
        {t('common.socialLink.submitButton')}
      </Button>
    </Col>
  )
}
