import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './CaseItemSubTitle.module.scss'

export type TCaseItemSubTitleProps = PropsWithClassName<{
  humanReadableId: string
}>

export const CaseItemSubTitle = ({ humanReadableId, className }: TCaseItemSubTitleProps) => {
  const { t } = useTranslation()

  return (
    <p className={cls(styles.root, className)}>
      {t('cases.caseIdLabel', { caseId: humanReadableId })}
    </p>
  )
}
