import { ReactNode, useEffect } from 'react'
import cls from 'classnames'

import { TResponseError } from 'utils'

import { ErrorModal } from '../../ErrorModal'

import styles from './ConfirmationModal.module.scss'

export type TConfirmationModalProps = PropsWithClasses<
  'root' | 'content' | 'controls',
  {
    content: ReactNode
    controls: ReactNode

    resetError?: () => void
    endAdornment?: ReactNode
    error?: TResponseError
  }
>

export const ConfirmationModal = ({
  content,
  controls,
  endAdornment,
  error,
  classes,
  resetError
}: TConfirmationModalProps) => {
  useEffect(
    () => () => {
      resetError && resetError()
    },
    []
  )

  return (
    <div className={cls(styles.root, classes?.root)}>
      <div className={cls(styles.rootText, classes?.content)}>{content}</div>
      {endAdornment}
      <div className={cls(styles.rootButton, classes?.controls)}>{controls}</div>

      <ErrorModal error={error} />
    </div>
  )
}
