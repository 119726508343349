import { PortalWrapper } from 'App/components/common'
import { useAppSelector } from 'store'

import { BroadcastRoom } from '../BroadcastRoom'

export const BroadcastContainer = () => {
  const activeBroadcastKey = useAppSelector((state) => state.broadcasts.activeBroadcast?.key)

  return (
    <PortalWrapper portalCssSelector="broadcast-wrapper-portal">
      {activeBroadcastKey && <BroadcastRoom broadcastKey={activeBroadcastKey} />}
    </PortalWrapper>
  )
}
