import { format, isValid, isToday } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import es from 'date-fns/locale/es'
import pt from 'date-fns/locale/pt'
import pl from 'date-fns/locale/pl'
import uk from 'date-fns/locale/uk'

import i18n from '../i18n'
import { EStorageUnitsEnum } from '../enums'

export const baseStorageUnit = 1024
export const monthFormat = 'MM'
export const yearFormat = 'yyyy'
export const longDate = 'dd/MM/yyyy'
export const serverRangeDate = 'yyyy/MM'
export const rangeDate = 'MM/yyyy'
export const shortDate = 'dd/MM/yy'
export const shortDefaultDate = 'MM/dd/yy'
export const timeFormat = 'HH:mm'
export const minuteFormat = 'mm:ss'
export const shortyDate = 'dd.MM'
export const longDateTime = `${longDate}, ${timeFormat}`
export const longTimeDate = `${timeFormat}, ${longDate}`
export const dateAndFullMonth = 'dd MMMM'
export const MINUTE = 60000
export const HOUR = 3600000

type TDate = string | number | Date

export const getLocale = () => {
  const locales = { enGB, es, pt, pl, uk }
  let localeId = i18n.language

  if (localeId === 'en') {
    localeId = 'enGB'
  }

  return locales[localeId as keyof typeof locales]
}

export const formatDate = (date?: TDate, template?: string) => {
  if (!date || !template || !isValid(new Date(date))) {
    return ''
  }

  const locale = getLocale()

  return format(new Date(date), template, { locale })
}

/**
 * @Function formatLongDate for return value in long date format
 * @param value { TDate } - date in different format
 * @return string - date in long format
 */
export const formatLongDate = (value?: TDate) => formatDate(value, longDate)

/**
 * @Function formatShortDate for return value in short date format
 * @param value { TDate } - date in different format
 * @return string - date in short format
 */
export const formatShortDate = (value?: TDate) => formatDate(value, shortDate)

export const formatShortyDate = (value?: TDate) => formatDate(value, shortyDate)

export const formatLongDateTime = (value?: TDate) => formatDate(value, longDateTime)

export const formatLongTimeDate = (value?: TDate) => formatDate(value, longTimeDate)

export const formatMonthYear = (value?: TDate) => formatDate(value, `${monthFormat}/${yearFormat}`)

export const formatBytes = (value: number | string = 0, decimals = 2) => {
  const bytes = Number(value)

  if (bytes === 0) {
    return `0 ${i18n.t('files.fileSizeUnit.0').toString()}`
  }

  const dm = decimals < 0 ? 0 : decimals

  const index = Math.floor(Math.log(bytes) / Math.log(baseStorageUnit))

  const unit = i18n.t('files.fileSizeUnit', { returnObjects: true })[index].toString()

  return `${parseFloat((bytes / Math.pow(baseStorageUnit, index)).toFixed(dm))} ${unit}`
}

export const formatNumber = (value: number | string, options?: { withoutSign: boolean }) => {
  const parsedValue = Number(value)
  const sign = parsedValue < 0 ? '-' : '+'

  if (isNaN(parsedValue)) {
    return value
  }

  const absValue = Math.abs(parsedValue)

  return `${options?.withoutSign || absValue === 0 ? '' : sign}${
    absValue < 10 ? `0${absValue}` : absValue
  }`
}
export const timerFormatted = (hours: number, minutes: number, seconds: number) => {
  const hoursFormatted = `${hours < 10 ? '0' : ''}${hours}`
  const minutesFormatted = `${minutes < 10 ? '0' : ''}${minutes}`
  const secondsFormatted = `${seconds < 10 ? '0' : ''}${seconds}`

  if (hours > 0) {
    return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`
  }

  return `${minutesFormatted}:${secondsFormatted}`
}

export const formatTimeZoneOffset = (offset: number) => {
  const signNumber = offset < 0 ? -1 : 1
  const absOffset = Math.abs(offset)
  const hours = Math.floor(absOffset / HOUR)
  const minutes = (absOffset - hours * HOUR) / MINUTE

  return `${formatNumber(signNumber * hours)}:${formatNumber(minutes, { withoutSign: true })}`
}

export const formatNotificationDate = (value: Date | string, formatTemplate = longDate) => {
  const date = new Date(value)

  if (!isValid(date)) {
    return null
  }

  if (isToday(date)) {
    return i18n.t('notificationTodayDateLabel', {
      ns: 'notifications',
      date: formatDate(date, timeFormat)
    })
  }

  return formatDate(date, formatTemplate)
}

export const convertStorageUnitsToBytes = (amount: number, storageUnit: EStorageUnitsEnum) => {
  const calcFunctions = {
    [EStorageUnitsEnum.KB]: baseStorageUnit,
    [EStorageUnitsEnum.MB]: baseStorageUnit ** 2,
    [EStorageUnitsEnum.GB]: baseStorageUnit ** 3,
    [EStorageUnitsEnum.TB]: baseStorageUnit ** 4
  }

  return amount * calcFunctions[storageUnit]
}
