import { ReactNode } from 'react'
import cls from 'classnames'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { AccountName } from 'App/components'

import styles from './CaseMembersItemWrapper.module.scss'

export type TCaseMembersItemWrapperProps = {
  id: string
  firstName: string
  lastName: string
  displayUserName: string
  type: AccountTypeNames
  children: ReactNode
  isCoworker: boolean

  caseOwnerId?: string
}

export const CaseMembersItemWrapper = ({
  id,
  firstName,
  lastName,
  displayUserName,
  type,
  children,
  caseOwnerId,
  isCoworker
}: TCaseMembersItemWrapperProps) => {
  const { t } = useTranslation()
  const isOwner = caseOwnerId === id

  return (
    <div className={styles.root}>
      <div className={cls(styles.wrapper, styles.user)}>
        <AccountName
          type={type}
          showAvatar
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          userId={id}
          size={EAvatarSize.XS}
          shouldRedirectToUserProfile={true}
          isCoworker={isCoworker}
        >
          {isOwner && <>({t('cases.details.ownerLabel')})</>}
        </AccountName>
      </div>
      <div className={cls(styles.wrapper, styles.actions)}>{children}</div>
    </div>
  )
}
