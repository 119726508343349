import { SettingsList } from 'App/components'
import {
  SettingsBusinessInfoEmailContainer,
  SettingsBusinessInfoCompanyNameContainer
} from 'App/containers'

export const SettingsBusinessInfo = () => (
  <SettingsList>
    <>
      <SettingsBusinessInfoCompanyNameContainer />
      <SettingsBusinessInfoEmailContainer />
    </>
  </SettingsList>
)
