import { call, put, select, takeLatest, delay, fork } from 'redux-saga/effects'
import { CaseCombinedTypeEnum, CaseClassificationNameEnum, CaseStatusesEnum } from '@medentee/enums'

import { toast } from 'App/components/ToastContainer'
import { QueryBuilder } from 'utils'
import history from 'utils/history'
import { toastDefaultOptions, TOAST_AUTO_CLOSE_DELAY } from 'globalConstants'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { hideModalAction } from 'store'
import { ICaseCountDTO, ICaseEntity } from 'interfaces'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { State } from 'redux/rootReducer'
import { ECaseAccountType } from 'enums'
import { LEAVE_MEMBER_SUCCESS } from 'store/caseMembers'
import { handleError } from 'api/utils'

import {
  CASE_ARCHIVE_SUCCESS,
  CASE_LOCK_SUCCESS,
  CASE_UNLOCK_SUCCESS,
  caseViewRequest
} from '../caseView'
import i18n from '../../i18n'

import {
  caseLinksNormalize,
  caseNormalize,
  linkCasesNormalize,
  TCaseNormalized
} from './cases.normalization'
import {
  TCaseHistoryFilters,
  TCaseListFilters,
  TGetCaseCountRequest,
  TGetCaseHistoryFiltersRequest,
  TGetCaseHistoryRequest,
  TRenameCaseTitleRequest,
  TToggleCaseLinks,
  TGetCasesListRequest,
  TGetCasesLinksProps,
  TSetCaseListSearch,
  TCaseLinksTypes,
  TCaseClassifications,
  TCaseLinksFilters,
  TGetLinkCaseListRequest
} from './cases.types'
import {
  GET_CASE_COUNT_REQUEST,
  GET_CASE_HISTORY_FILTERS_REQUEST,
  GET_CASE_HISTORY_REQUEST,
  GET_CASE_LIST_REQUEST,
  getCaseHistoryFiltersError,
  getCaseHistoryFiltersSuccess,
  getCaseHistoryListError,
  getCaseHistoryListSuccess,
  getCaseListError,
  getCaseListRequest,
  getCaseListSuccess,
  getCasesLinksSuccess,
  RENAME_CASE_TITLE_REQUEST,
  renameCaseTitleError,
  renameCaseTitleSuccess,
  SET_CASE_LIST_PAGINATION,
  setCaseCountLoading,
  setCasesCount,
  TOGGLE_CASE_LINK_REQUEST,
  GET_CASES_LINKS_REQUEST,
  getCasesLinksError,
  getCasesLinksRequest,
  deleteCaseSuccess,
  toggleCaseLinkSuccess,
  toggleCaseLinkError,
  GET_LINK_CASE_LIST_REQUEST,
  getLinkCaseListSuccess,
  getLinkCaseListError,
  getLinkCaseListRequest
} from './cases.actions'
import { deleteCaseError, DELETE_CASE_REQUEST, DELETE_CASE_SUCCESS, TDeleteCaseRequest } from '.'

function* getCaseListSaga({ payload }: TGetCasesListRequest) {
  const { disablePagination, excludeCaseId } = payload || {}
  try {
    const { page, showBy, search, searchBy, classifications, roles, statuses }: TCaseListFilters =
      yield select((state: State) => state.cases.filters)
    let withCombinedTypes: TCaseClassifications[] = []

    if (classifications.includes(CaseClassificationNameEnum.PROFESSIONAL)) {
      withCombinedTypes = [
        ...classifications.filter((type) => type !== CaseClassificationNameEnum.PROFESSIONAL),
        CaseCombinedTypeEnum.PROFESSION_DIRECT,
        CaseCombinedTypeEnum.PROFESSION_GROUP
      ]
    }

    const urlBuilder = new QueryBuilder(API.CASES)
      .multiSearch('statuses', statuses)
      .multiSearch('types', withCombinedTypes.length ? withCombinedTypes : classifications)
      .multiSearch('roles', roles)
      .custom('excludeLinkedCasesByCaseId', excludeCaseId)
      .searchQuery(search)

    if (!statuses.length) {
      // ADDED FOR SPECIAL TASK MED-6480
      urlBuilder.multiSearch('statuses', [CaseStatusesEnum.ACTIVE, CaseStatusesEnum.LOCKED])
    }

    if (searchBy) {
      urlBuilder.searchBy(searchBy)
    }

    if (!disablePagination) {
      urlBuilder.page(page).showBy(showBy)
    }

    const url = urlBuilder.build()

    const { data }: APIResultsResponse<ICaseEntity[]> = yield call(api.get, url)

    const { caseList, memberList, ids }: TCaseNormalized = caseNormalize(data.results)

    yield put(
      getCaseListSuccess({
        total: data.total,
        ids,
        caseList,
        memberList
      })
    )
  } catch (e) {
    handleError(e)
    yield put(getCaseListError(e))
  }
}

function* getLinkCaseListSaga({ payload: { caseId } }: TGetLinkCaseListRequest) {
  try {
    const { search, searchBy, statuses, classifications, roles }: TCaseLinksFilters = yield select(
      (state: State) => state.cases.linkCases.filters
    )

    const urlBuilder = new QueryBuilder(API.CASES)
      .custom('excludeLinkedCasesByCaseId', caseId)
      .multiSearch('statuses', statuses)
      .multiSearch('types', classifications)
      .multiSearch('roles', roles)
      .searchBy(searchBy)
      .searchQuery(search)

    if (!statuses.length) {
      // ADDED FOR SPECIAL TASK MED-6480
      urlBuilder.multiSearch('statuses', [CaseStatusesEnum.ACTIVE, CaseStatusesEnum.LOCKED])
    }

    const url = urlBuilder.build()

    const { data }: APIResultsResponse<ICaseEntity[]> = yield call(api.get, url)

    yield put(getLinkCaseListSuccess(linkCasesNormalize(data.results)))
  } catch (e) {
    yield put(getLinkCaseListError(e))
  }
}

export function* getCaseLinksSaga({ payload }: TGetCasesLinksProps) {
  try {
    const { search, searchBy, statuses, classifications, roles }: TCaseLinksFilters = yield select(
      (state: State) => state.cases.caseLinks.filters
    )

    const urlBuilder = new QueryBuilder(API.CASES_LINKS(payload.caseId))
      .multiSearch('statuses', statuses)
      .multiSearch('types', classifications)
      .multiSearch('roles', roles)
      .searchBy(searchBy)
      .searchQuery(search)

    if (!statuses.length) {
      // ADDED FOR SPECIAL TASK MED-6480
      urlBuilder.multiSearch('statuses', [CaseStatusesEnum.ACTIVE, CaseStatusesEnum.LOCKED])
    }

    const url = urlBuilder.build()

    const { data }: APIData<TCaseLinksTypes[]> = yield call(api.get, url)

    yield put(getCasesLinksSuccess(caseLinksNormalize(data)))
  } catch (e) {
    yield handleError(e)
    yield put(getCasesLinksError(e))
  }
}

export function* hideCaseLinksError() {
  yield delay(TOAST_AUTO_CLOSE_DELAY)
  yield put(toggleCaseLinkError(null))
}

export function* toggleCaseLinks({ payload }: TToggleCaseLinks) {
  const { caseId, caseIdToToggle } = payload

  try {
    const url = new QueryBuilder(API.CASE_LINKS_TOGGLE(caseId)).build()
    yield call(api.patch, url, { caseIdToToggle })
    yield put(toggleCaseLinkSuccess())
  } catch (e) {
    yield put(toggleCaseLinkError(e))
    yield fork(hideCaseLinksError)
  } finally {
    const { shouldRequestCasesLink, shouldRequestLinkCasesList }: Record<string, boolean> =
      yield select((state: State) => ({
        shouldRequestCasesLink: Boolean(state.cases.caseLinks.ids.length),
        shouldRequestLinkCasesList: Boolean(state.cases.linkCases.ids.length)
      }))

    if (shouldRequestCasesLink) {
      yield put(getCasesLinksRequest({ caseId }))
    }

    if (shouldRequestLinkCasesList) {
      yield put(getLinkCaseListRequest({ caseId }))
    }
  }
}

function* getCaseHistory({ payload }: TGetCaseHistoryRequest) {
  try {
    const { caseId, pageNumber, pageSize, filters, search } = payload || {}
    const { caseHistoryActionGroupIds, youInitiator, createdAt } = filters || {}

    const urlBuilder = new QueryBuilder(API.CASE_HISTORY(caseId))
      .page(pageNumber || PAGINATION_DEFAULT_PAGE)
      .showBy((pageSize as string) || PAGINATION_DEFAULT_SHOW_BY)
      .searchQuery(search)
      .multiSearch('caseHistoryActionGroupIds', caseHistoryActionGroupIds)
      .custom('youInitiator', youInitiator?.length === 1 ? youInitiator[0] : undefined)
    if (createdAt) {
      urlBuilder
        .custom('fromDate', createdAt[0]?.toISOString())
        .custom('toDate', createdAt[1]?.toISOString())
    }

    const url = urlBuilder.build()
    const {
      data: { results: caseHistoryList, total }
    } = yield call(api.get, url)
    yield put(getCaseHistoryListSuccess({ caseHistoryList, total }))
  } catch (e) {
    yield put(getCaseHistoryListError(e))
    yield handleError(e)
  }
}

function* getCaseHistoryFilters({ payload }: TGetCaseHistoryFiltersRequest) {
  try {
    const url = API.CASE_HISTORY_FILTERS(payload.caseId)

    const { data: caseHistoryFilters }: APIData<TCaseHistoryFilters> = yield call(api.get, url)
    yield put(getCaseHistoryFiltersSuccess({ caseHistoryFilters }))
  } catch (e) {
    yield put(getCaseHistoryFiltersError(e))
    yield handleError(e)
  }
}

function* getCaseCountSaga({ payload }: TGetCaseCountRequest) {
  try {
    yield put(setCaseCountLoading({ countLoading: true }))

    const queryBuilderUrl = new QueryBuilder(API.CASES_COUNT)
      .searchQuery(payload?.searchQuery)
      .searchBy(payload?.searchBy)
      .custom('status', payload?.status)
      .custom('caseRole', payload?.caseRole)
      .custom('targetSearchedEntity', payload?.targetSearchedEntity)
      .build()

    const { data: count }: APIData<ICaseCountDTO> = yield call(api.get, queryBuilderUrl)

    yield put(setCasesCount({ count }))
  } catch (e) {
    handleError(e)
  } finally {
    yield put(setCaseCountLoading({ countLoading: false }))
  }
}

export function* renameCaseTitleSaga({ payload }: TRenameCaseTitleRequest) {
  try {
    const { id, title } = payload

    yield call(api.patch, API.CASE(id), {
      title
    })

    yield put(renameCaseTitleSuccess())
    yield put(hideModalAction())

    yield put(
      caseViewRequest({
        type: ECaseAccountType.OWNER,
        id
      })
    )
  } catch (e) {
    yield put(renameCaseTitleError(e))
  }
}

function* refreshCaseListSaga({ payload }: TSetCaseListSearch) {
  yield put(getCaseListRequest(payload))
}

function* deleteCaseSaga({ payload: { id, onSuccess } }: TDeleteCaseRequest) {
  try {
    yield call(api.delete, API.CASE(id))

    yield put(deleteCaseSuccess())
    toast.success(i18n.t('common.toast.caseDeleted'), toastDefaultOptions)
    yield history.replace('/cases')
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    handleError(e)
    yield put(deleteCaseError(e))
  }
}

export function* caseListSaga() {
  yield takeLatest(GET_CASE_LIST_REQUEST, getCaseListSaga)
  yield takeLatest(GET_CASES_LINKS_REQUEST, getCaseLinksSaga)
  yield takeLatest(TOGGLE_CASE_LINK_REQUEST, toggleCaseLinks)
  yield takeLatest(GET_CASE_HISTORY_REQUEST, getCaseHistory)
  yield takeLatest(GET_CASE_HISTORY_FILTERS_REQUEST, getCaseHistoryFilters)
  yield takeLatest(GET_CASE_COUNT_REQUEST, getCaseCountSaga)
  yield takeLatest(RENAME_CASE_TITLE_REQUEST, renameCaseTitleSaga)
  yield takeLatest(
    [
      SET_CASE_LIST_PAGINATION,
      // TODO: don't fetch getCaseListRequest while in a cases item page
      CASE_ARCHIVE_SUCCESS,
      CASE_LOCK_SUCCESS,
      CASE_UNLOCK_SUCCESS,
      LEAVE_MEMBER_SUCCESS,
      DELETE_CASE_SUCCESS
    ],
    refreshCaseListSaga
  )
  yield takeLatest(DELETE_CASE_REQUEST, deleteCaseSaga)
  yield takeLatest(GET_LINK_CASE_LIST_REQUEST, getLinkCaseListSaga)
}
