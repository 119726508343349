import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  LicensesItem,
  PublicationsItemContainer,
  WorkExperienceItem,
  Chip,
  Scrollable,
  ShowMore,
  EducationItemContainer
} from 'App/components'
import { createLoadingSelector, GET_USER_PROFILE_BY_ID, useAppSelector } from 'store'
import { useAdaptiveLayout } from 'App/hooks/useAdaptiveLayout'

import { SectionWrapper } from '../SectionWrapper'

import styles from './Details.module.scss'

const loadingSelector = createLoadingSelector([GET_USER_PROFILE_BY_ID])

export const Details = () => {
  const {
    professions,
    type,
    about,
    education: { ids: educationIdList },
    workExperience: { ids: workExperienceIdList },
    licenses: { ids: licensesIdList },
    publications: { ids: publicationsIdList }
  } = useAppSelector((state) => state.userProfile.viewProfile)
  const { isDesktop } = useAdaptiveLayout()

  const loading = useAppSelector(loadingSelector)

  const { t } = useTranslation()

  const visibleHeight = isDesktop ? styles.visibilityHeight : styles.visibilityHeightTablet

  const professionTitle =
    type === AccountTypeNames.BUSINESS
      ? t('userProfile.details.sections.companyAndSpecialization')
      : t('userProfile.details.sections.professionAndSpecialization')

  return (
    <Scrollable className={cls(loading && styles.rootSkeleton)}>
      <SectionWrapper
        title={t('userProfile.details.sections.about')}
        loading={loading}
        classes={{ title: styles.aboutTitle }}
      >
        {about && (
          <ShowMore
            text={about}
            classes={{ wrapper: styles.about }}
            visibleHeight={Number(visibleHeight)}
          />
        )}
      </SectionWrapper>
      <SectionWrapper
        title={professionTitle}
        loading={loading}
        classes={{ title: styles.professionTitle }}
      >
        {professions.length && (
          <div className={styles.professionList}>
            {professions.map((item) => (
              <Chip key={item} variant="secondary" text={item} className={styles.professionItem} />
            ))}
          </div>
        )}
      </SectionWrapper>
      <SectionWrapper title={t('userProfile.details.sections.workExperience')} loading={loading}>
        {workExperienceIdList.length &&
          workExperienceIdList.map((itemId) => (
            <WorkExperienceItem key={itemId} id={itemId} disableContextMenu={true} />
          ))}
      </SectionWrapper>
      <SectionWrapper title={t('userProfile.details.sections.education')} loading={loading}>
        {educationIdList.length &&
          educationIdList.map((itemId) => (
            <EducationItemContainer key={itemId} id={itemId} disableContextMenu={true} />
          ))}
      </SectionWrapper>
      <SectionWrapper title={t('userProfile.details.sections.licenses')} loading={loading}>
        {licensesIdList.length &&
          licensesIdList.map((itemId) => (
            <LicensesItem key={itemId} id={itemId} disableContextMenu={true} />
          ))}
      </SectionWrapper>
      <SectionWrapper title={t('userProfile.details.sections.publications')} loading={loading}>
        {publicationsIdList.length &&
          publicationsIdList.map((itemId) => (
            <PublicationsItemContainer key={itemId} id={itemId} disableContextMenu={true} />
          ))}
      </SectionWrapper>
    </Scrollable>
  )
}
