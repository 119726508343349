import { connect } from 'react-redux'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { NotificationBadge, TNotificationBadgeProps } from 'App/components'
import { getCaseNotificationsSelector } from 'store'

export type TCaseDocumentOpinionItemBadgeContainerOwnProps = {
  userId: string
} & Omit<TNotificationBadgeProps, 'count'>
export type TCaseDocumentOpinionItemBadgeContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (
  state: State,
  { userId, ...rest }: TCaseDocumentOpinionItemBadgeContainerOwnProps
) => {
  const notifications = getCaseNotificationsSelector(
    state,
    ProducedNotificationsEnum.CASE_OPINION_CREATED
  )

  const count = notifications.reduce<number>((acc, { payload, viewed }) => {
    if (payload.userId === userId && !viewed) {
      acc += 1
    }

    return acc
  }, 0)

  return {
    ...rest,
    count
  }
}

export const CaseDocumentOpinionItemBadgeContainer = connect(mapStateToProps)(NotificationBadge)
