import { useCallback, useMemo } from 'react'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { Drawer, EmptyList, TDrawerProps } from 'App/components/common'
import { getAttendeeEventProfile } from 'api/events'
import {
  TUnityHubNetworkingDrawerHeaderProps,
  UnityHubNetworkingDrawerHeader
} from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerHeader'
import { UnityHubNetworkingDrawerSkeleton } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerSkeleton'
import { UnityHubNetworkingDrawerFooter } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerFooter'
import { queryClient } from 'queryClient'

import { EVENT_ATTENDEES_LIST_QUERY_KEY } from '../EventNetworkingList'

import styles from './EventNetworkingDrawer.module.scss'
import {
  EventNetworkingDrawerContent,
  TEventNetworkingDrawerContentProps
} from './EventNetworkingDrawerContent'

export type TEventNetworkingDrawerProps = Pick<TDrawerProps, 'visible' | 'onClose'> &
  Pick<TEventNetworkingDrawerContentProps, 'onToggleFavorite' | 'eventId' | 'isFavorite'> &
  Pick<TUnityHubNetworkingDrawerHeaderProps, 'userName'> & {
    attendeeId: string
    attendeeAccountId: string
    accountId: string
  }

const EVENT_ATTENDEE_PROFILE_QUERY_KEY = 'event_attendee_profile'

export const EventNetworkingDrawer = ({
  visible,
  eventId,
  attendeeId,
  attendeeAccountId,
  userName,
  accountId,
  isFavorite,
  onClose,
  onToggleFavorite
}: TEventNetworkingDrawerProps) => {
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useQuery({
    queryKey: [EVENT_ATTENDEE_PROFILE_QUERY_KEY, eventId, attendeeId],
    queryFn: () => getAttendeeEventProfile({ eventId, attendeeId }),
    enabled: visible && !!eventId && !!attendeeId,
    cacheTime: 0,
    staleTime: 0
  })

  const onInviteSuccess = useCallback(() => {
    refetch()
    queryClient.invalidateQueries({ queryKey: [EVENT_ATTENDEES_LIST_QUERY_KEY], type: 'active' })
  }, [refetch])

  const content = useMemo(() => {
    if (!data && isFetching) {
      return <UnityHubNetworkingDrawerSkeleton />
    }

    if (!data) {
      return <EmptyList text={t('unityHub.networking.member.drawer.placeholder')} />
    }

    return (
      <EventNetworkingDrawerContent
        attendee={data}
        eventId={eventId}
        isFavorite={isFavorite}
        onToggleFavorite={onToggleFavorite}
        onSuccess={onInviteSuccess}
      />
    )
  }, [data, eventId, isFavorite, isFetching, onInviteSuccess, onToggleFavorite, t])

  const shouldShowHeaderActions =
    data?.contact?.status === ContactStatusEnum.PENDING &&
    data?.contact?.fromId === attendeeAccountId
  const shouldShowFooterActions =
    !isFetching &&
    (!data?.contact || data?.contact.status === ContactStatusEnum.REJECTED) &&
    accountId !== attendeeAccountId

  return (
    <Drawer
      className={styles.root}
      visible={visible}
      headerActions={
        shouldShowHeaderActions ? (
          <UnityHubNetworkingDrawerHeader
            contactId={data?.contact.id}
            memberId={attendeeAccountId}
            userName={userName}
            onSuccess={onInviteSuccess}
          />
        ) : undefined
      }
      footerActions={
        shouldShowFooterActions ? (
          <UnityHubNetworkingDrawerFooter
            memberId={attendeeAccountId}
            onSuccess={onInviteSuccess}
          />
        ) : undefined
      }
      onClose={onClose}
    >
      {content}
    </Drawer>
  )
}
