import {
  FileExtensionCategoryEnum,
  FileHistoryEventsEnum,
  FileHistorySourcesEnum,
  FilePermissionsIdsEnum
} from '@medentee/enums'

import { Filters, Pagination, SortingTable } from 'types'
import { ICaseEntity, ICasesCloudFile, IFileContactPermission } from 'interfaces'
import { TAccount, TError, TIds, TPluralProcessingPayload } from 'store'

import {
  changeFileContactPermissionRequest,
  changeFileContactPermissionSuccess,
  discardCasesPermissionsRequest,
  discardCasesPermissionsSuccess,
  downloadFileRequest,
  getFileContactListRequest,
  getFileListRequest,
  getFilesCountsRequest,
  getFilesCountsSuccess,
  getFilesHistoryRequest,
  getFilesHistorySuccess,
  renameFileRequest,
  setFileContactIds,
  setFileContactList,
  setFileContactListSearch,
  setFileDetailsId,
  setFileFiltersRequest,
  setFileFiltersSuccess,
  setFileIds,
  setFileList,
  setFilePagination,
  setFileSorting
} from './files.actions'

/** File list object */
export type TFileItem = ICasesCloudFile

/** Object with [FiledId]: FileItem */
export type TFileList = {
  [key: string]: TFileItem
}

export type TFileContact = IFileContactPermission & {
  isLoading: boolean
  p2pPermissions: FilePermissionsIdsEnum[]
  sharedInCasesCount: number
}

export type TFileContactIds = string[]

export type TFileContactList = {
  [key: string]: TFileContact
}

export type TFileContacts = {
  search: string
  list: TFileContactList
  ids: TFileContactIds
}

export type TFilesFilters = Partial<Filters>

export type TFileTypes = {
  ids: TIds
  fileList: TFileList
  counts: TFilesCounts | null
  countsTotal: number
  pagination: Pagination
  filters: TFilesFilters
  recordId: string | null
  fileContacts: TFileContacts
  fileHistory: TFilesHistory

  sorting?: SortingTable
}

export type TFilesHistory = {
  historyItems: THistoryFile[]
  dateIds: TIds
  groupedList: TFilesHistoryGroupedList
  filters: TFilesHistoryFilters
}

export type TFilesHistoryGroupedList = Record<string, TFilesHistoryGroupedListItem>

export type TFilesHistoryGroupedListItem = {
  ids: TIds
  list: TFileHistoryListItem
}

export type TFileHistoryListItem = Record<string, THistoryFile>
type TOriginalActorAccount = Omit<TAccount, 'companyName'> | null

export type THistoryFile = {
  id: string
  actorAccount: TAccount
  createdAt: string
  event: FileHistoryEventsEnum
  file: TFileItem
  source: FileHistorySourcesEnum
  originalEntityId: string | null
  originalActorAccount: TOriginalActorAccount

  case?: ICaseEntity
}

export type TFilesHistoryFilters = {
  page: number
  total: number
  showBy: number
}

export type TGetFilesHistoryRequestPayload = {
  page: number
  fileIds: string[]
  originalEntityIds?: string[]

  actorAccountIds?: string[]
  sources?: FileHistorySourcesEnum[]
  showBy?: number
}

export type TGetFilesHistorySuccessPayload = {
  historyItems: THistoryFile[]
  dateIds: TIds
  groupedList: TFilesHistoryGroupedList
  page: number
  total: number
}

export type TGetFilesHistoryResponse = {
  results: THistoryFile[]
  total: number
}

export type TDiscardCasesPermissionsRequestPayload = {
  fileId: string
  contactUserId: string
}

export type TDiscardCasesPermissionsSuccessPayload = {
  contactId: string
}

export type TChangeFileContactPermissionRequestPayload = TPluralProcessingPayload & {
  permissionId: FilePermissionsIdsEnum
  userId: string
  enabled: boolean
  fileId: string
  caseId?: string
}

export type TChangeFileContactPermissionSuccessPayload = TPluralProcessingPayload & {
  permissions: FilePermissionsIdsEnum[]
  userId: string
}

export type TChangeFileContactPermissionErrorPayload = TError & TPluralProcessingPayload

export enum EPermissionSpecificationScope {
  P2P = 'p2p',
  CASE = 'cfp'
}

export type TGetFilesCountsRequestPayload = {
  ownerId?: string
  permissionUserIdScope?: string
  permissionSpecificationScope?: EPermissionSpecificationScope
  caseId?: string
  chatId?: string
  searchQuery?: string
}

export type TFilesCounts = Record<FileExtensionCategoryEnum, number>

export type TGetFilesCountsSuccessPayload = {
  counts: TFilesCounts
  total: number
}

export type TSetFileFiltersRequest = ReturnType<typeof setFileFiltersRequest>
export type TSetFileFiltersSuccess = ReturnType<typeof setFileFiltersSuccess>
export type TGetFileListRequest = ReturnType<typeof getFileListRequest>
export type TSetFileContactIds = ReturnType<typeof setFileContactIds>
export type TChangeFileContactPermissionSuccess = ReturnType<
  typeof changeFileContactPermissionSuccess
>
export type TSetFileContactList = ReturnType<typeof setFileContactList>
export type TSetFileDetailsId = ReturnType<typeof setFileDetailsId>
export type TDiscardCasesPermissionsSuccess = ReturnType<typeof discardCasesPermissionsSuccess>
export type TSetFileSorting = ReturnType<typeof setFileSorting>
export type TSetFilePagination = ReturnType<typeof setFilePagination>
export type TSetFileIds = ReturnType<typeof setFileIds>
export type TSetFileList = ReturnType<typeof setFileList>
export type TChangeFileContactPermissionRequest = ReturnType<
  typeof changeFileContactPermissionRequest
>
export type TGetFileContactListRequest = ReturnType<typeof getFileContactListRequest>
export type TDownloadFileRequest = ReturnType<typeof downloadFileRequest>
export type TRenameFileRequest = ReturnType<typeof renameFileRequest>
export type TDiscardCasesPermissionsRequest = ReturnType<typeof discardCasesPermissionsRequest>
export type TGetFilesHistoryRequest = ReturnType<typeof getFilesHistoryRequest>
export type TGetFilesHistorySuccess = ReturnType<typeof getFilesHistorySuccess>
export type TSetFileContactListSearch = ReturnType<typeof setFileContactListSearch>
export type TGetFilesCountsRequest = ReturnType<typeof getFilesCountsRequest>
export type TGetFilesCountsSuccess = ReturnType<typeof getFilesCountsSuccess>
