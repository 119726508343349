import { useRouteMatch } from 'react-router-dom'
import { useRef } from 'react'

import { useAppSelector } from 'store'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'
import { TChannelVariant } from 'interfaces'

import { useKebabMenu } from '../useKebabMenu'

type TCommunityChannelRoomProps = {
  chatRoomId: string
  communityId: string
  variant: Extract<TChannelVariant, 'chat' | 'news'>
} & ReturnType<typeof useKebabMenu>

export const CommunityChannelRoom = ({
  chatRoomId,
  variant,
  communityId,
  getMenuItems
}: TCommunityChannelRoomProps) => {
  const { url } = useRouteMatch()
  const roomRef = useRef<HTMLDivElement>(null)

  const { name, mutedUntil, channelManager, inviteToken, isPublic } =
    useAppSelector((state) => state.chat.chatRooms.list[chatRoomId]) ?? {}

  const { count, mentionCount } = useAppSelector(
    (state) =>
      state.notifications.general.communities.list?.[communityId]?.chats?.[chatRoomId] ?? {}
  )

  const isMuted = Boolean(mutedUntil)

  const handleEditSuccess = () =>
    roomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  if (!name) {
    return null
  }

  return (
    <UnityHubRoomNavLink
      to={`${url}/${variant}/${chatRoomId}`}
      icon={<UnityHubChannelIcon variant={variant} isMuted={isMuted} />}
      name={name}
      isMuted={isMuted}
      count={count}
      isPublic={isPublic}
      mentionCount={mentionCount}
      ref={roomRef}
      menuItems={getMenuItems({
        variant,
        channelId: chatRoomId,
        channelName: name,
        channelManagerId: channelManager?.id,
        inviteToken,
        isMuted,
        isPublic,
        onEditSuccess: handleEditSuccess
      })}
    />
  )
}
