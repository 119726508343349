import { useRouteMatch, useHistory, generatePath } from 'react-router-dom'
import { useCallback, useLayoutEffect, useMemo, useState, CSSProperties } from 'react'
import { stringify } from 'query-string'
import ResizeObserver from 'resize-observer-polyfill'

import {
  accountRoleNameSelector,
  useAppSelector,
  useAppDispatch,
  createOrganizationChatRequest,
  TCaseParams
} from 'store'
import { ORGANIZATION_CONTENT_PATH } from 'App/screens'
import { CHAT_PATH, EChatTypeMap } from 'globalConstants'
import { useAdaptiveLayout } from 'App/hooks'

type TQueryData = {
  id: string
}

export const useOrganizationId = () => {
  const firstOrganizationId = useAppSelector((state) => state.organizations.data.ids[0])
  const accountRoleName = useAppSelector(accountRoleNameSelector)
  const { params } = useRouteMatch<TQueryData>(ORGANIZATION_CONTENT_PATH) ?? {}

  return accountRoleName ? firstOrganizationId : params?.id || ''
}

type TUseChatRedirectCallbackProps = {
  chatId?: string
  partnerAccountId?: string
  caseId?: string
}

export const useChatRedirect = () => {
  const { push } = useHistory()
  const dispatch = useAppDispatch()
  const {
    path,
    params: { who }
  } = useRouteMatch<TCaseParams>()

  const callback = useCallback(
    ({ chatId, caseId, partnerAccountId }: TUseChatRedirectCallbackProps) => {
      if (caseId && chatId) {
        push({
          pathname: generatePath(path, { id: caseId, who }),
          search: stringify({ chatId })
        })
        return
      }

      if (chatId) {
        push(
          generatePath(CHAT_PATH, {
            chatType: EChatTypeMap.DIALOGUE,
            chatId
          })
        )
        return
      }

      const handleRelocateToChatRoom = (id?: string) => {
        push(`/chat/dialog/${id}`)
      }

      if (partnerAccountId) {
        dispatch(
          createOrganizationChatRequest({
            partnerAccountId,
            onSuccess: handleRelocateToChatRoom
          })
        )
      }
    },
    [dispatch, path, push, who]
  )

  return {
    redirectToChat: callback
  }
}

type TUsePinnedChannels = {
  ids: string[]
  entity: 'communities' | 'organizations' | 'events'
  entityId: string
}

export const usePinnedChannels = ({ ids, entity, entityId }: TUsePinnedChannels) => {
  const currentChatId = useAppSelector((state) => state.chat.chatRooms.selectedChat?.id)
  const list = useAppSelector((state) => state.chat.chatRooms.list)
  const notifications = useAppSelector(
    (state) => state.notifications.general[entity]?.list[entityId]?.chats ?? {}
  )

  const pinnedIds = useMemo(() => {
    const nextIds: string[] = []

    ids.forEach((id) => {
      const item = list[id]
      const count = notifications[id]?.count ?? 0
      if ((count > 0 && !item.mutedUntil) || id === currentChatId) {
        nextIds.push(id)
      }
    })

    return nextIds
  }, [ids, currentChatId, list, notifications])

  return { pinnedIds }
}

export const usePinnedVoiceChannels = () => {
  const currentVoiceId = useAppSelector((state) => state.organizations.activeVoiceRoom?.id)
  const ids = useAppSelector((state) => state.organizations.voiceRooms.ids)

  const pinnedIds = useMemo(() => ids.filter((id) => id === currentVoiceId), [ids, currentVoiceId])

  return {
    ids,
    pinnedIds
  }
}

type TUseUpdateEventInChannelRoom = {
  container: HTMLDivElement | null

  isFullscreen?: boolean
}

export const useVoiceChannelStyles = ({
  container,
  isFullscreen
}: TUseUpdateEventInChannelRoom) => {
  const [style, setStyle] = useState<Pick<CSSProperties, 'top' | 'left' | 'width' | 'height'>>()
  const { isDesktop } = useAdaptiveLayout()

  const updateStyles = useCallback(
    (el?: HTMLElement | null) => {
      if (el) {
        const {
          top = 0,
          left = 0,
          width = '100%',
          height = '100%'
        } = !isFullscreen ? el.getBoundingClientRect() : {}

        setStyle({
          top,
          // TODO: figure out how to get correct value from getBoundingClientRect
          // 4 - borders width
          left: isDesktop ? left + 4 : left,
          width,
          height
        })
      } else {
        setStyle(undefined)
      }
    },
    [isDesktop, isFullscreen]
  )

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => updateStyles(container))

    container && resizeObserver.observe(container)

    return () => {
      container && resizeObserver.unobserve(container)
    }
  }, [updateStyles, container])

  return {
    style
  }
}
