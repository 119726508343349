import { CLOSE_FULLSCREEN } from 'globalConstants/errors'

export async function openFullscreen<T extends HTMLElement>(elem: T | null) {
  if (!elem) {
    return
  }

  /* Open fullscreen */
  if (elem.requestFullscreen) {
    await elem.requestFullscreen()
  } else {
    if (elem.mozRequestFullScreen) {
      /* Firefox */
      await elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullScreen) {
      /* Chrome, Safari & Opera */
      await elem.webkitRequestFullScreen()
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      await elem.msRequestFullscreen()
    }
  }
}

export async function closeFullscreen<T extends HTMLElement>(elem: T | null) {
  if (!elem) {
    return
  }

  try {
    /* Close fullscreen */
    if (document.exitFullscreen) {
      await document.exitFullscreen()
    } else {
      if (document.mozCancelFullScreen) {
        /* Firefox */
        await document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        /* Chrome, Safari and Opera */
        await document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        await document.msExitFullscreen()
      }
    }
  } catch (error) {
    console.error(CLOSE_FULLSCREEN, error)
  }
}
