import { useMemo, useState } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { showModalAction, TMeeting, TMeetingType, useAppDispatch } from 'store'
import { TTimeRangePickerProps, TTimeZoneSelectOption, timeZones } from 'App/components/common'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { EModalComponents } from '../../../containers'
import { ScheduleMeetingSubTitle } from '../ScheduleMeetingSubTitle'
import { ScheduleMeetingModalForm } from '../ScheduleMeetingModalForm'
import { ScheduleMeetingModalSecondStep } from '../ScheduleMeetingModalSecondStep'

import { useEditScheduledMeetingRequest } from './useEditMeetingBeforeStartRequest'

export type TEditScheduledMeetingDialogFormValues = {
  type: TMeetingType
  title: string
  date: Date
  timeZone: TTimeZoneSelectOption | null
  time: TTimeRangePickerProps['value']
  hasReminder: boolean
  inviteAll: boolean
  participants: TAccountsSelectOptionData[]

  location?: string
  notifyUsers?: boolean
}

export type TEditScheduledMeetingDialogProps = {
  meeting: TMeeting

  onSuccess?: () => void
}

export const EditScheduledMeetingDialog = ({
  meeting,
  onSuccess
}: TEditScheduledMeetingDialogProps) => {
  const dispatch = useAppDispatch()
  const [shouldShowSubtitle, setShouldShowSubtitle] = useState(true)

  const { t } = useTranslation()

  const {
    id,
    location,
    title,
    type,
    startOn,
    endOn,
    invites,
    timezone,
    hasReminder,
    case: caseData
  } = meeting

  const isGroupMeeting = Boolean(caseData?.id)
  const userName = !isGroupMeeting && invites.length ? invites[0]?.account?.displayUserName : ''

  const initialValues = useMemo<TEditScheduledMeetingDialogFormValues>(
    () => ({
      type,
      title,
      date: new Date(startOn),
      time: [new Date(startOn), new Date(endOn)],
      timeZone: timeZones.find(({ value }) => value === timezone) ?? null,
      hasReminder,
      inviteAll: false,
      participants: invites.map((item) => item.account),
      location
    }),
    [type, title, startOn, endOn, hasReminder, invites, location, timezone]
  )

  const { onSubmit, isLoading } = useEditScheduledMeetingRequest({
    meetingId: id,
    onSuccess
  })

  const handleFormSubmit = (values: TEditScheduledMeetingDialogFormValues) => {
    const shouldConfirm =
      values.date?.getTime() !== initialValues.date.getTime() ||
      values.location !== initialValues.location ||
      values.timeZone !== initialValues.timeZone ||
      values.time?.[0]?.getTime() !== initialValues.time?.[0]?.getTime() ||
      values.time?.[1]?.getTime() !== initialValues.time?.[1]?.getTime()

    if (shouldConfirm) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalTitle: t('modal.scheduleMeeting.changesConfirmationStep.title'),
          modalProps: {
            onCancel: () => onSubmit(values),
            onConfirm: () => onSubmit({ ...values, notifyUsers: true }),
            content: t('modal.scheduleMeeting.changesConfirmationStep.content'),
            confirmLabel: t('modal.scheduleMeeting.changesConfirmationStep.confirmLabel'),
            cancelLabel: t('modal.scheduleMeeting.changesConfirmationStep.cancelLabel')
          }
        })
      )
    } else {
      onSubmit(values)
    }
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        {shouldShowSubtitle && (
          <ScheduleMeetingSubTitle
            isGroupMeeting={isGroupMeeting}
            caseTitle={caseData?.title}
            userName={userName}
          />
        )}
      </Col>

      <Col xs={24}>
        <ScheduleMeetingModalForm onSubmit={handleFormSubmit} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <ScheduleMeetingModalSecondStep
              shouldShowInviteAll={false}
              caseId={caseData?.id}
              isGroupMeeting={Boolean(isGroupMeeting)}
              onSubmit={handleSubmit}
              onToggleMembers={setShouldShowSubtitle}
              modalTitle={t('modal.scheduleMeeting.title_edit')}
              submitLabel={t('modal.scheduleMeeting.detailsStep.submitButton_edit')}
              submitLoading={isLoading}
            />
          )}
        </ScheduleMeetingModalForm>
      </Col>
    </Row>
  )
}
