import { FC, useEffect, useCallback } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TIds } from 'store'
import { ErrorModal, Button } from 'App/components'
import {
  TCaseCloudFileDiscardPermissionsConfirmContainerProps,
  TFileDiscardPermissionsConfirmContainerProps
} from 'App/containers'

import styles from './FileDiscardPermissionsConfirm.module.scss'

export type FileDiscardPermissionsConfirmOwnProps = {
  fileIds: TIds

  caseId?: string
  onSuccess?: () => void
  onError?: () => void
}

type FileDiscardPermissionsConfirmProps = PartialBy<
  TFileDiscardPermissionsConfirmContainerProps &
    TCaseCloudFileDiscardPermissionsConfirmContainerProps,
  'discardCaseFilePermissions' | 'discardFilePermissions'
> &
  FileDiscardPermissionsConfirmOwnProps

export const FileDiscardPermissionsConfirm: FC<FileDiscardPermissionsConfirmProps> = ({
  error,
  processing,
  discardFilePermissions,
  discardCaseFilePermissions,
  removeErrorNotification,
  fileIds,
  caseId,
  onSuccess,
  onError
}) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  const onClick = useCallback(() => {
    if (fileIds && !caseId && discardFilePermissions) {
      fileIds.forEach((fileId) => {
        discardFilePermissions({
          fileId,
          onSuccess,
          onError
        })
      })
      return
    }

    if (caseId && discardCaseFilePermissions) {
      discardCaseFilePermissions({
        fileIds,
        caseId,
        onSuccess
      })
    }
  }, [caseId, discardCaseFilePermissions, discardFilePermissions, fileIds, onError, onSuccess])

  const getText = () =>
    caseId
      ? t('modal.discardFilePermissions.content', { context: 'case' })
      : t('modal.discardFilePermissions.content', { context: 'all' })

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{getText()}</p>
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={onClick}
      >
        {t('modal.discardFilePermissions.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
