import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { isMessageOutgoingSelector, showExtendableCardAction } from 'store'
import { ChatMessageFile, TChatMessageFileBaseProps } from 'App/components'
import { getChatSenderName } from 'utils'

export type TChatMessageFileContainerOwnProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TChatMessageFileContainerProps = TChatMessageFileContainerOwnProps &
  TChatMessageFileBaseProps & {
    caseView?: string
  }

const mapStateToProps = (state: State, { showUserName, id }: TChatMessageFileBaseProps) => {
  const {
    fileName,
    extension,
    id: fileId,
    thumbnailUrl,
    fileSize,
    mimeType
  } = state.chat.chatMessages.messages.list[id]?.file || {}

  const { sender, yourMessage } = state.chat.chatMessages.messages.list[id] || {}
  const { displayUserName } = state.chat.chatRooms.selectedChat?.interlocutorAccount || {}

  const senderName = getChatSenderName({
    senderName: sender?.displayUserName,
    showUserName,
    yourMessage
  })

  return {
    slidesEntity:
      fileId && fileName && extension
        ? [
            {
              id: fileId,
              fileName,
              extension,
              mimeType,
              thumbnailUrl
            }
          ]
        : [],
    fileName: fileName || '',
    extension: extension || '',
    contactName: senderName || displayUserName,
    fileId,
    thumbnailUrl,
    fileSize,
    isUnopenable: Boolean(
      fileId && state.videoStreaming.list[fileId]?.videoMetadata?.quality === null
    ),
    mimeType,
    isMessageOutgoing: isMessageOutgoingSelector(yourMessage)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showExtendableCard: showExtendableCardAction
    },
    dispatch
  )

export const ChatMessageFileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageFile)
