import { actionCreator, TError, TPluralProcessingPayload } from 'store'

import {
  TScheduleMeetingRequestPayload,
  TGetMeetingsSuccessPayload,
  TCancelMeetingRequestPayload,
  TAcceptMeetingRequestPayload,
  TRejectMeetingRequestPayload
} from './meetings.types'
import * as actionTypes from './meetings.actionTypes'

export const scheduleMeetingRequest = (payload: TScheduleMeetingRequestPayload) =>
  actionCreator(actionTypes.SCHEDULE_MEETING_REQUEST, payload)
export const scheduleMeetingSuccess = () => actionCreator(actionTypes.SCHEDULE_MEETING_SUCCESS)
export const scheduleMeetingError = (payload: TError) =>
  actionCreator(actionTypes.SCHEDULE_MEETING_ERROR, payload)

export const getMeetingsRequest = () => actionCreator(actionTypes.GET_MEETINGS_REQUEST)
export const getMeetingsSuccess = (payload: TGetMeetingsSuccessPayload) =>
  actionCreator(actionTypes.GET_MEETINGS_SUCCESS, payload)
export const getMeetingsError = (payload: TError) =>
  actionCreator(actionTypes.GET_MEETINGS_ERROR, payload)

export const cancelMeetingRequest = (payload: TCancelMeetingRequestPayload) =>
  actionCreator(actionTypes.CANCEL_MEETING_REQUEST, payload)
export const cancelMeetingSuccess = (payload: TPluralProcessingPayload) =>
  actionCreator(actionTypes.CANCEL_MEETING_SUCCESS, payload)
export const cancelMeetingError = (payload: TError & TPluralProcessingPayload) =>
  actionCreator(actionTypes.CANCEL_MEETING_ERROR, payload)

export const rejectMeetingInviteRequest = (payload: TRejectMeetingRequestPayload) =>
  actionCreator(actionTypes.REJECT_MEETING_INVITE_REQUEST, payload)
export const rejectMeetingInviteSuccess = (payload: TPluralProcessingPayload) =>
  actionCreator(actionTypes.REJECT_MEETING_INVITE_SUCCESS, payload)
export const rejectMeetingInviteError = (payload: TError & TPluralProcessingPayload) =>
  actionCreator(actionTypes.REJECT_MEETING_INVITE_ERROR, payload)

export const acceptMeetingInviteRequest = (payload: TAcceptMeetingRequestPayload) =>
  actionCreator(actionTypes.ACCEPT_MEETING_INVITE_REQUEST, payload)
export const acceptMeetingInviteSuccess = (payload: TPluralProcessingPayload) =>
  actionCreator(actionTypes.ACCEPT_MEETING_INVITE_SUCCESS, payload)
export const acceptMeetingInviteError = (payload: TError & TPluralProcessingPayload) =>
  actionCreator(actionTypes.ACCEPT_MEETING_INVITE_ERROR, payload)
