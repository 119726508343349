import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useToggle } from 'App/hooks/useToggle'

import { CommunityInvitePopover, TCommunityInvitePopoverProps } from '../../InvitePopover'

type TUseCommunityInviteLinkProps = { communityId: string | null } & Pick<
  TCommunityInvitePopoverProps,
  'nestIn'
>

export const useCommunityInviteLink = ({ communityId, nestIn }: TUseCommunityInviteLinkProps) => {
  const { value, setValue, toggle } = useToggle()

  const { t } = useTranslation()

  const Component = useMemo(
    () =>
      communityId && (
        <>
          {t('communities.invitationLink.button')}
          <CommunityInvitePopover
            id={communityId}
            nestIn={nestIn}
            visible={value}
            onVisibleChange={setValue}
          />
        </>
      ),
    [communityId, nestIn, setValue, value, t]
  )

  return { CreateCommunityInviteLink: Component, toggleInviteLinkPopover: toggle }
}
