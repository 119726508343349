import { useEffect, useState } from 'react'
import { Skeleton, SkeletonProps } from 'antd'

import { ChipList, TChipListItem, TChipListProps } from '../../components'

import styles from './CategoriesChipList.module.scss'

export type TCategoriesChipListProps = {
  initialSelected?: TChipListItem[]
  items: TChipListItem[]
  loadingItems?: boolean
  onChange?: (newSelected: string[]) => void
} & Pick<TChipListProps, 'title' | 'className' | 'editable'> &
  Pick<SkeletonProps, 'loading'>

export const CategoriesChipList = ({
  initialSelected,
  className,
  title,
  editable,
  loading,
  onChange,
  items,
  loadingItems
}: TCategoriesChipListProps) => {
  const [selected, setSelected] = useState<TChipListItem[]>([])

  const handleChange = onChange
    ? (newValue: TChipListItem[]) => {
        const values = newValue

        setSelected(values)
        onChange(values.map((item) => item.id))
      }
    : undefined

  useEffect(() => {
    if (initialSelected) {
      setSelected(initialSelected)
    }
  }, [initialSelected])

  return (
    <Skeleton
      loading={loading || loadingItems}
      active={true}
      title={false}
      className={styles.skeleton}
      paragraph={{ rows: 3 }}
    >
      <ChipList
        title={title}
        items={items}
        className={className}
        onChange={handleChange}
        selected={selected}
        editable={editable}
        loading={loadingItems}
        placement="bottomLeft"
        categoryClasses={{
          Contacts: styles.contacts
        }}
        align={{ offset: [0, 0] }}
        popoverContentClass={styles.popoverContent}
      />
    </Skeleton>
  )
}
