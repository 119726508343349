import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { CaseStatusP2PChatMessage } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TMessagePushUpContainerProps } from 'App/containers'
import { EChatTypeMap, CHAT_PATH } from 'globalConstants'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'

import styles from './MessagePushUp.module.scss'

export type TMessagePushUpProps = TMessagePushUpContainerProps

const getRedirectPath = ({
  chatId,
  caseId,
  isCaseOwner,
  caseStatus
}: {
  chatId: string
  caseId?: string
  isCaseOwner?: boolean
  caseStatus?: CaseStatusP2PChatMessage
}): string => {
  if (caseId && caseStatus !== CaseStatusP2PChatMessage.INACTIVE) {
    return isCaseOwner
      ? `/cases/${caseId}/owner?chatId=${chatId}`
      : `/cases/${caseId}/member?chatId=${chatId}`
  }

  return generatePath(CHAT_PATH, {
    chatType: EChatTypeMap.DIALOGUE,
    chatId
  })
}

export const MessagePushUp = ({
  show,
  chatId,
  caseId,
  hidePushUp,
  isCaseOwner,
  caseStatus
}: TMessagePushUpProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleCloseWidget = useCallback(() => {
    hidePushUp({
      show: false,
      chatId: null
    })
  }, [hidePushUp])

  const handleRedirect = useCallback(() => {
    if (chatId) {
      handleCloseWidget()
      push(getRedirectPath({ chatId, caseId, isCaseOwner, caseStatus }))
    }
  }, [chatId, caseId, isCaseOwner, push, handleCloseWidget, caseStatus])

  return show ? (
    <div className={styles.root}>
      <MessageTextIcon className={styles.icon} />
      <span className={styles.label} onClick={handleRedirect}>
        {t('chat.pushUpMessage')}
      </span>
      <div className={styles.close} onClick={handleCloseWidget}>
        <CrossIcon />
      </div>
    </div>
  ) : null
}
