import { useCallback, useState } from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import capitalize from 'lodash/capitalize'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { TChatRoomCaseRolesFilter } from 'store'

import styles from './Filter.module.scss'

export type TFilterProps = {
  onSubmit: (value: TChatRoomCaseRolesFilter) => void
  onClose: () => void

  defaultValue?: TChatRoomCaseRolesFilter
}

const filters: TChatRoomCaseRolesFilter[] = ['all', 'owner', 'member']

export const Filter = ({ onSubmit, onClose, defaultValue = 'all' }: TFilterProps) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(defaultValue)

  const isDisableToReset = value === 'all'

  const handleSubmit = useCallback(
    (newValue: TChatRoomCaseRolesFilter) => {
      setValue(newValue)
      onSubmit(newValue)
      onClose()
    },
    [onSubmit, onClose]
  )

  const handleChange = useCallback(
    ({ target }: RadioChangeEvent) => handleSubmit(target.value),
    [handleSubmit]
  )

  const handleReset = useCallback(() => handleSubmit('all'), [handleSubmit])

  return (
    <div className={styles.root}>
      <div className={styles.title}>Case role</div>
      <Radio.Group
        value={value}
        className={styles.radio}
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {filters.map((item) => (
          <Radio key={item} value={item} className={styles.radioItem}>
            {capitalize(item)}
          </Radio>
        ))}
      </Radio.Group>

      <Button
        variant="text"
        className={styles.button}
        onClick={handleReset}
        disabled={isDisableToReset}
      >
        {t('common.filters.resetButton')}
      </Button>
    </div>
  )
}
