import { TAction } from 'store'
import { PAGINATION_DEFAULT } from 'types'

import * as CASES_INVITE_LIST_ACTIONS from './casesInvite.actions'
import {
  TCaseMemberInviteApproveError,
  TCaseMemberInviteRejectError,
  TCasesInvitesListTypes,
  TSetCasesInviteListPaginationPayload
} from './casesInvite.types'

export const initialCasesInviteListState: TCasesInvitesListTypes = {
  ids: [],
  casesInviteList: {},
  isLoading: true,
  pagination: PAGINATION_DEFAULT
}

export const casesInviteReducer = (
  state = initialCasesInviteListState,
  action: TAction
): TCasesInvitesListTypes => {
  switch (action.type) {
    case CASES_INVITE_LIST_ACTIONS.SET_CASES_INVITE_LIST: {
      return {
        ...state,
        casesInviteList: action.payload.casesInviteList
      }
    }
    case CASES_INVITE_LIST_ACTIONS.SET_CASES_INVITE_IDS: {
      return { ...state, ids: action.payload.ids }
    }
    case CASES_INVITE_LIST_ACTIONS.SET_CASES_INVITE_LIST_LOADING: {
      return { ...state, isLoading: action.payload.isLoading }
    }
    case CASES_INVITE_LIST_ACTIONS.SET_CASES_INVITE_LIST_PAGINATION: {
      const { total, current, showBy } = action.payload as TSetCasesInviteListPaginationPayload

      return {
        ...state,
        pagination: {
          ...state.pagination,
          total: total ?? state.pagination.total,
          current: current ?? state.pagination.current,
          showBy: showBy ?? state.pagination.showBy
        }
      }
    }

    case CASES_INVITE_LIST_ACTIONS.CASE_MEMBER_INVITE_APPROVE_ERROR: {
      return {
        ...state,
        inviteApproveError: (action as TCaseMemberInviteApproveError).payload
      }
    }

    case CASES_INVITE_LIST_ACTIONS.CASE_MEMBER_INVITE_REJECT_ERROR: {
      return {
        ...state,
        inviteRejectError: (action as TCaseMemberInviteRejectError).payload
      }
    }
    case CASES_INVITE_LIST_ACTIONS.RESET_CASE_INVITE_LIST_ACTION: {
      return {
        ...initialCasesInviteListState
      }
    }
    default:
      return state
  }
}
