import { useTranslation } from 'react-i18next'

import { Spinner } from 'App/components/Spinner'

import styles from './HoldOnDialog.module.scss'

const SIZE = 60

export const HoldOnDialog = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <span style={{ height: SIZE }}>
        <Spinner contentCenter={true} width={SIZE} height={SIZE} />
      </span>
      <span className={styles.title}>{t('modal.processTopUp.subtitle')}</span>
      <span>{t('modal.processTopUp.content')}</span>
    </div>
  )
}
