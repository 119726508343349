import { useCallback, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import {
  createProcessingSelector,
  getOrganizationMembersRequest,
  getUserBalanceRequest,
  transferMDTRequest,
  TRANSFER_MDT,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Button, ETextFieldSize, TextField } from 'App/components'

import { SelectedUsers, TSelectedUsersProps } from './SelectedUsers'
import styles from './Transfer.module.scss'

export type TTransferProps = Pick<TSelectedUsersProps, 'selectedAccounts' | 'setSelectedIds'> & {
  buttonDisabled: boolean
  setInputAmount: (amount?: number) => void

  amount?: number
}

const processingSelector = createProcessingSelector([TRANSFER_MDT])

const INPUT_LIMIT_MIN = 1
const INPUT_LIMIT_MAX = 9999

export const Transfer = ({
  buttonDisabled,
  selectedAccounts,
  setSelectedIds,
  setInputAmount,
  amount = 0
}: TTransferProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector)
  const organizationId = useAppSelector((state) => state.global.accountData?.id)

  const selectedIds = useMemo(() => selectedAccounts.map(({ id }) => id), [selectedAccounts])

  const handleRefreshData = useCallback(
    (formRestart: () => void) => {
      dispatch(getUserBalanceRequest())
      setSelectedIds([])
      formRestart()

      if (organizationId) {
        dispatch(getOrganizationMembersRequest({ id: organizationId, withPagination: true }))
      }
    },
    [dispatch, organizationId, setSelectedIds]
  )

  const handleFormSubmit = useCallback(
    (_, form: FormApi) => {
      if (!buttonDisabled) {
        dispatch(
          transferMDTRequest({
            amount,
            accountIds: selectedIds,
            onSuccess: () => handleRefreshData(form.restart)
          })
        )
      }
    },
    [buttonDisabled, amount, dispatch, handleRefreshData, selectedIds]
  )

  return (
    <div className={styles.root}>
      <div className={styles.transfer}>
        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, form }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.row}>
                <label className={styles.label}>
                  {t('payments.transferMDT.transferLabel')}
                  <Field
                    name="amount"
                    parse={(value) => value.replace(/[^\d]/g, '')}
                    validate={validation.composeValidators(
                      validation.required(),
                      validation.min(
                        INPUT_LIMIT_MIN,
                        t('validationErrors.minAmount', { amount: INPUT_LIMIT_MIN, ns: 'errors' })
                      ),
                      validation.max(
                        INPUT_LIMIT_MAX,
                        t('validationErrors.maxAmount', { amount: INPUT_LIMIT_MAX, ns: 'errors' })
                      )
                    )}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        onChange={(event) => {
                          input.onChange(event)

                          setInputAmount(Number(form.getFieldState('amount')?.value))
                        }}
                        placeholder={t('payments.transferMDT.fieldPlaceholder')}
                        invalid={meta.touched && meta.invalid}
                        error={meta.error}
                        size={ETextFieldSize.SM}
                      />
                    )}
                  </Field>
                </label>
                <SelectedUsers
                  selectedAccounts={selectedAccounts}
                  setSelectedIds={setSelectedIds}
                />
              </div>
              <Button
                type="submit"
                loading={processing}
                className={styles.button}
                disabled={form.getState().invalid || buttonDisabled}
                size="sm"
              >
                {t('payments.transferMDT.transferButton')}
              </Button>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}
