import { AccountTypeNames, BusinessAccountRoleNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { organizationInvitationNestedRoutes } from 'App/App.config'
import { TNavMenuItem, validateRoute } from 'App/components'
import { TRoleRouteContainerOwnProps } from 'App/containers'
import { useAppSelector } from 'store'

const DEFAULT_PATH = '/organization/invitations/received'
const REGISTERED_PATH = '/organization/invitations/registered'
const NEW_USERS_PATH = '/organization/invitations/new-users'
const ADMINISTRATION_PATH = '/organization/invitations/administration'

type TUseRoutes = () => {
  nestedRoutes: TRoleRouteContainerOwnProps[]
  pathList: Record<string, string>
  navMenu: TNavMenuItem[]
}

export const useRoutes: TUseRoutes = () => {
  const role = useAppSelector((state) => state.global.accountData?.role?.name)
  const accountType = useAppSelector((state) => state.global.accountData?.type.name)

  const { t } = useTranslation()

  const shouldHideAdministration =
    accountType &&
    !validateRoute(accountType, [AccountTypeNames.BUSINESS], role, [
      BusinessAccountRoleNameEnum.SUPERADMIN
    ])

  return {
    nestedRoutes: organizationInvitationNestedRoutes,
    pathList: {
      default: DEFAULT_PATH,
      registered: REGISTERED_PATH,
      newUsers: NEW_USERS_PATH,
      administration: ADMINISTRATION_PATH
    },
    navMenu: [
      {
        link: DEFAULT_PATH,
        title: t('organizations.invitations.tabs.received')
      },
      {
        link: REGISTERED_PATH,
        title: t('organizations.invitations.tabs.registered')
      },
      {
        link: NEW_USERS_PATH,
        title: t('organizations.invitations.tabs.newUsers')
      },
      {
        link: ADMINISTRATION_PATH,
        title: t('organizations.invitations.tabs.administration'),
        hidden: shouldHideAdministration
      }
    ]
  }
}
