import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { Search } from 'App/components'
import { FileContactListContainer, TFileDetailsP2PContainerProps } from 'App/containers'

import styles from './FileDetailsP2P.module.scss'

export type TFileDetailsP2PProps = TFileDetailsP2PContainerProps

export const FileDetailsP2P = ({
  fileId,
  getFileContactList,
  onSearch,
  fileDetailsReset
}: TFileDetailsP2PProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    getFileContactList({ fileId })

    return () => {
      fileDetailsReset()
    }
  }, [fileId, getFileContactList, fileDetailsReset])

  const handleSearchChange = useCallback(
    (search: string) => {
      onSearch({ search, fileId })
    },
    [fileId, onSearch]
  )

  return (
    <>
      <p className={styles.title}>{t('files.information.contactsList.title')}</p>
      <div className={styles.filter} key={fileId}>
        <Search
          placeholder={t('files.information.contactsList.searchPlaceholder')}
          onChange={handleSearchChange}
          searchSize={EInputSize.S}
        />
      </div>
      <div className={styles.contact}>
        {t('files.information.contactsList.columns', { returnObjects: true }).map((column) => (
          <span key={column} className={styles.contactSubTitle}>
            {column}
          </span>
        ))}
      </div>

      <FileContactListContainer />
    </>
  )
}
