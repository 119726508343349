import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { PublicationTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Steps, StepsFooterActions, TOption } from 'App/components'
import {
  createProcessingSelector,
  ADD_PUBLICATION,
  addPublicationRequest,
  updatePublicationRequest,
  UPDATE_PUBLICATION,
  createProcessingBySelector,
  useAppSelector
} from 'store'
import { serverRangeDate, formatDate } from 'utils/formats'
import { IPublicationDTO } from 'interfaces'
import { useSteps } from 'App/hooks'

import { FirstStep, SecondStep } from './components'

const addingProcessingSelector = createProcessingSelector([ADD_PUBLICATION])
const updatingProcessingSelector = createProcessingBySelector([UPDATE_PUBLICATION])

const DEFAULT_STEPS = [{}, {}]
enum STEPS {
  FIRST,
  SECOND
}

export type TValues = {
  name: string
  type: TOption<PublicationTypeEnum>
  authors: string
  startDate?: string | Date | null
  source?: string | null
  link?: string | null
}

type TInitialValues = Omit<TValues, 'type'> & {
  type: TOption<PublicationTypeEnum> | null
}

const initialFormValues: TInitialValues = {
  name: '',
  type: null,
  authors: '',
  link: '',
  source: '',
  startDate: null
}

export type TPublicationDialogProps = {
  data?: IPublicationDTO
}

export const PublicationDialog: FC<TPublicationDialogProps> = ({ data }) => {
  const dispatch = useDispatch()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)

  const adding = useAppSelector(addingProcessingSelector)
  const updating = useAppSelector((state) => data && updatingProcessingSelector(data.id)(state))

  const { t } = useTranslation()

  const initialValues = useMemo<TInitialValues>(() => {
    if (data) {
      return {
        ...data,
        date: data.startDate ? new Date(data.startDate) : null,
        type: data.type
          ? {
              value: data.type,
              label: data.type
            }
          : null
      }
    }

    return initialFormValues
  }, [data])

  const submitButtonText = data
    ? t('modal.userPublication.submitButton_save')
    : t('modal.userPublication.submitButton_add')

  const onSubmit = ({ name, type, authors, startDate, source, link }: TValues) => {
    const normalizedData = {
      name,
      type: type.value,
      authors,
      source: source || null,
      link: link || null,
      startDate: startDate ? formatDate(startDate, serverRangeDate) : null
    }

    dispatch(
      data
        ? updatePublicationRequest({ ...normalizedData, id: data.id, processingId: data.id })
        : addPublicationRequest(normalizedData)
    )
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Steps current={step} stepsArray={DEFAULT_STEPS} />
      </Col>
      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <Row gutter={[0, 24]}>
              {step === STEPS.FIRST && <FirstStep />}
              {step === STEPS.SECOND && <SecondStep />}
              <Col xs={24}>
                <Row>
                  <StepsFooterActions
                    onSubmit={step === STEPS.SECOND ? handleSubmit : setNextStep}
                    submitLoading={data ? updating : adding}
                    submitDisabled={form.getState().invalid}
                    onClickBack={step === STEPS.FIRST ? undefined : setPrevStep}
                    submitLabel={
                      step === STEPS.SECOND
                        ? submitButtonText
                        : t('modal.userPublication.submitButton_next')
                    }
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  )
}
