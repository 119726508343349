import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FC } from 'react'

import { RENAME_CASE_TITLE, renameCaseTitleRequest } from 'store/cases'
import { State } from 'redux/rootReducer'
import { createProcessingSelector } from 'store/loading'
import { removeErrorNotification } from 'store/errors'
import { CaseEditTitleDialog } from 'App/components/Case/CaseEditTitleDialog'

const loadingSelector = createProcessingSelector([RENAME_CASE_TITLE])

export type TCaseEditTitleDialogContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  id: state.caseView.data?.id,
  defaultTitle: state.caseView.data?.title,
  loadingButton: loadingSelector(state),
  error: state.errors.RENAME_CASE_TITLE
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      renameCaseTitle: renameCaseTitleRequest,
      removeErrorNotification
    },
    dispatch
  )

export const CaseEditTitleDialogContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseEditTitleDialog)
