import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EIconSize, EInputSize } from 'enums'
import { ReactComponent as FormatListBulletedIcon } from 'assets/icons/FormatListBulleted.svg'
import { ReactComponent as ViewAgendaOutlineIcon } from 'assets/icons/ViewAgendaOutline.svg'

import { IconButton, Search } from '../../../components'

import styles from './StaffTab.module.scss'
import { StaffTabList } from './StaffTabList'
import { StaffTabDepartments } from './StaffTabDepartments'
import { useStaffTabView } from './useStaffTabView'

export type TStaffTabProps = {
  organizationId: string
}

export const StaffTab = ({ organizationId }: TStaffTabProps) => {
  const [view, setView] = useStaffTabView(organizationId)

  const [search, setSearch] = useState('')

  const { t } = useTranslation()

  const toggleView = () => {
    setView((currentView) => (currentView === 'staff' ? 'departments' : 'staff'))
  }

  return (
    <>
      <div className={styles.search}>
        <Search
          searchSize={EInputSize.L}
          onChange={setSearch}
          placeholder={
            view === 'staff'
              ? t('organizations.staff.searchPlaceholder')
              : t('organizations.departments.searchPlaceholder')
          }
        />
        <IconButton
          variant="outlined"
          onClick={toggleView}
          iconSize={EIconSize.MD}
          classes={{ root: styles.viewToggle }}
          toolTip={
            view === 'staff'
              ? t('organizations.staff.viewSwitcherTooltip')
              : t('organizations.departments.viewSwitcherTooltip')
          }
          iconComponent={view === 'staff' ? <ViewAgendaOutlineIcon /> : <FormatListBulletedIcon />}
        />
      </div>
      {view === 'staff' ? (
        <StaffTabList search={search} organizationId={organizationId} />
      ) : (
        <StaffTabDepartments search={search} organizationId={organizationId} />
      )}
    </>
  )
}
