import { FileExtensionCategoryEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EEmptyListIconSize, EmptyList } from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'
import { FileUploadButtonContainer } from 'App/containers'
import { EmptyListActionLink } from 'App/components/EmptyListActionLink'
import { ReactComponent as EmptyListIcon } from 'assets/icons/TextBox.svg'

import styles from './EmptyFileList.module.scss'

type TEmptyFileListProps = {
  defaultText: string

  upload?: boolean
  icon?: JSX.Element
  search?: string
  extensionCategories?: FileExtensionCategoryEnum[]
}

export const EmptyFileList = ({
  defaultText,
  search,
  upload,
  extensionCategories,
  icon = <EmptyListIcon />
}: TEmptyFileListProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  return (
    <EmptyList
      icon={icon}
      iconSize={isDesktop ? EEmptyListIconSize.MD : EEmptyListIconSize.SM}
      text={defaultText}
      className={styles.emptyList}
      hasAppliedFilters={!!extensionCategories?.length}
      hasAppliedSearch={!!search}
    >
      {upload && (
        <FileUploadButtonContainer>
          <EmptyListActionLink title={t('files.uploadButton')} />
        </FileUploadButtonContainer>
      )}
    </EmptyList>
  )
}
