import { useCallback, useMemo } from 'react'
import { BusinessAccountRoleNameEnum, ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import {
  TMenuItemProps,
  MenuItemCollapse,
  TMenuItemCollapseOption
} from 'App/components/common/MenuList'
import {
  accountIdSelector,
  isAdminAccountSelector,
  originalAccountIdSelector,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { getOrganizationDepartments, setDepartmentMembers } from 'api/organizations'
import { EModalComponents } from 'App/containers'
import { queryClient } from 'queryClient'
import { IDepartment } from 'interfaces/api/organizations'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { ReactComponent as MessageTextOutlineIcon } from 'assets/icons/MessageTextOutline.svg'
import { useChatRedirect } from 'App/components/Organizations/hooks'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'
import { isMatchErrorCode } from 'utils'
import { handleError } from 'api'

import { useStaffTabView } from '../useStaffTabView'
import { SET_DEPARTMENT_MEMBERS_MUTATION_KEY } from '../RemoveStaffFromDepartmentDialog'

type TMenuItemsProps = {
  staffId: string
  userId: string
  departments: IDepartment[]

  chatId?: string
  staffRole?: BusinessAccountRoleNameEnum
}

export type TUseKebabMenuProps = {
  organizationId: string

  departmentId?: string
  isDepartmentLead?: boolean
  onChange?: () => void
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const ORGANIZATION_DEPARTMENTS_QUERY_KEY = 'organizations-departments'
export const ORGANIZATION_STAFF_QUERY_KEY = 'organization-staff'

export const useKebabMenu = ({
  organizationId,
  departmentId,
  isDepartmentLead,
  onChange
}: TUseKebabMenuProps): TUseKebabMenu => {
  const [view] = useStaffTabView(organizationId)
  const isStaffView = view === 'staff'

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const originalAccountId = useAppSelector(originalAccountIdSelector)
  const isBusiness = useAppSelector(isAdminAccountSelector)

  const { data, refetch } = useQuery({
    queryKey: [ORGANIZATION_DEPARTMENTS_QUERY_KEY, organizationId],
    queryFn: () => getOrganizationDepartments({ organizationId }),
    enabled: isStaffView
  })

  const getOptions = useCallback(
    ({ departments }: Pick<TMenuItemsProps, 'departments'>) =>
      data?.results?.map<TMenuItemCollapseOption>(({ id, name }) => ({
        id,
        name,
        checked: !!departments.find((department) => department.id === id)
      })) ?? [],
    [data?.results]
  )

  const { getOnClickHandler: getCreateDepartmentHandler } = useOrganizationSubscription({
    variant: 'upgrade',
    organizationId,
    callback: () => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.createOrganizationDepartment.title'),
          modalType: EModalComponents.CREATE_DEPARTMENT,
          modalProps: {
            id: organizationId
          }
        })
      )
    }
  })

  const { mutate, isLoading } = useMutation({
    mutationKey: [SET_DEPARTMENT_MEMBERS_MUTATION_KEY],
    mutationFn: setDepartmentMembers,
    onSuccess: () => {
      if (onChange) {
        onChange()
      }
    },
    onError: (e) => {
      handleError(e)

      if (isMatchErrorCode(e, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        refetch()
      }
    }
  })

  const { getOnClickHandler: getSetToDepartmentHandler } = useOrganizationSubscription<
    Pick<TMenuItemsProps, 'staffId'> & { checked: boolean; id: string }
  >({
    variant: 'upgrade',
    organizationId,
    callback: (props) => {
      props &&
        mutate({
          id: props.id,
          organizationId,
          setAccountIds: props.checked ? [props.staffId] : [],
          unsetAccountIds: props.checked ? [] : [props.staffId]
        })
    }
  })

  const { getOnClickHandler: getRemoveStaffFromDepartmentHandler } =
    useOrganizationSubscription<string>({
      organizationId,
      callback: (staffId) => {
        departmentId &&
          staffId &&
          dispatch(
            showModalAction({
              modalTitle: t('modal.removeStaffFromDepartmentConfirm.title'),
              modalType: EModalComponents.REMOVE_STAFF_FROM_DEPARTMENT_DIALOG,
              modalProps: {
                staffId,
                departmentId,
                organizationId
              }
            })
          )
      }
    })

  const handleRemoveStaffFromOrganization = useCallback(
    (userId: string) => () => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.removeStaffConfirm.title'),
          modalType: EModalComponents.REMOVE_ORGANIZATION_STAFF_DIALOG,
          modalProps: {
            businessUserId: userId,
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [ORGANIZATION_STAFF_QUERY_KEY],
                type: 'active'
              })
            }
          }
        })
      )
    },
    [dispatch, t]
  )

  const { redirectToChat } = useChatRedirect()

  const menuItems = useMemo<Record<string, (props: TMenuItemsProps) => TMenuItemProps>>(
    () => ({
      removeFromOrganization: ({ staffId, userId, staffRole }) => ({
        hidden:
          !isBusiness ||
          staffId === originalAccountId ||
          staffRole !== BusinessAccountRoleNameEnum.STAFF,
        content: t('organizations.staff.kebabMenu.removeFromOrganization'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: handleRemoveStaffFromOrganization(userId)
      }),
      removeFromDepartment: ({ departments, staffId }) => ({
        hidden:
          staffId === accountId ||
          isStaffView ||
          !departments.length ||
          (!isBusiness && !isDepartmentLead),
        content: t('organizations.staff.kebabMenu.removeFromDepartment'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: getRemoveStaffFromDepartmentHandler(staffId)
      }),
      chat: ({ staffId, chatId }) => ({
        hidden: staffId === accountId,
        content: t('organizations.staff.kebabMenu.chat'),
        icon: <MessageTextOutlineIcon />,
        onClick: () => redirectToChat({ partnerAccountId: staffId, chatId })
      }),
      setStaff: ({ departments, staffId }) => ({
        hidden: !isBusiness || !isStaffView,
        content: t('organizations.staff.kebabMenu.setToDepartments'),
        dividerTop: true,
        render: (item) => (
          <MenuItemCollapse
            item={item}
            buttonLabel={t('organizations.staff.kebabMenu.createNewDepartmentButton')}
            options={getOptions({ departments })}
            loading={isLoading}
            onChange={(checked, id) => getSetToDepartmentHandler({ staffId, checked, id })()}
          />
        ),
        onClick: getCreateDepartmentHandler()
      })
    }),
    [
      accountId,
      getOptions,
      getRemoveStaffFromDepartmentHandler,
      getCreateDepartmentHandler,
      getSetToDepartmentHandler,
      handleRemoveStaffFromOrganization,
      isBusiness,
      isDepartmentLead,
      isLoading,
      isStaffView,
      originalAccountId,
      redirectToChat,
      t
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.removeFromDepartment(props),
      menuItems.removeFromOrganization(props),
      menuItems.chat(props),
      menuItems.setStaff(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
