import { ChatTypeEnum } from '@medentee/enums'

import { IDefaultStandaloneNotificationDTO } from 'interfaces'
import { State } from 'redux/rootReducer'
import { TIds } from 'store'

export const getNotificationsIdsSelector = (notifications: IDefaultStandaloneNotificationDTO[]) =>
  notifications.reduce<TIds>((acc, { id }) => {
    acc.push(id)
    return acc
  }, [])

export const getViewedNotificationsIdsSelector = (
  notifications: IDefaultStandaloneNotificationDTO[]
) =>
  notifications.reduce<TIds>((acc, { id, viewed }) => {
    if (viewed) {
      acc.push(id)
    }
    return acc
  }, [])

export const getMainNotificationsTotalSelector = (state: State) =>
  state.notifications.general.headerNotification - state.notifications.general.showCaseNotification

export const getShowcasesNotificationsTotalSelector = (state: State) =>
  state.notifications.general.showCaseNotification

export const getNotificationsCountByChatTypeSelector =
  (chatType?: ChatTypeEnum) => (state: State) => {
    const chatId = state.chat.chatRooms.selectedChat?.id

    if (!chatType || !chatId) {
      return 0
    }

    switch (chatType) {
      case ChatTypeEnum.DIALOGUE:
      case ChatTypeEnum.GROUP:
      case ChatTypeEnum.CASE_GROUP:
      case ChatTypeEnum.COMMUNITY_CHANNEL:
      case ChatTypeEnum.COMMUNITY_NEWS: {
        const communityId = state.communities.currentCommunityId ?? ''

        return (
          state.notifications.general.communities.list?.[communityId]?.chats?.[chatId]?.count ?? 0
        )
      }
      case ChatTypeEnum.ORGANIZATION: {
        const organizationId = state.organizations.currentOrganizationId ?? ''

        return (
          state.notifications.general.organizations.list?.[organizationId]?.chats?.[chatId]
            ?.count ?? 0
        )
      }

      default:
        return 0
    }
  }

export const getChatUnreadNotificationsSelector = (state: State, chatId: string) =>
  state.notifications.general.chats.list[chatId]?.unreadCount ?? 0
