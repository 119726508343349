export const GET_ACTIVE_SUBSCRIPTIONS = 'GET_ACTIVE_SUBSCRIPTIONS'
export const GET_ACTIVE_SUBSCRIPTIONS_REQUEST = 'GET_ACTIVE_SUBSCRIPTIONS_REQUEST'
export const GET_ACTIVE_SUBSCRIPTIONS_SUCCESS = 'GET_ACTIVE_SUBSCRIPTIONS_SUCCESS'
export const GET_ACTIVE_SUBSCRIPTIONS_ERROR = 'GET_ACTIVE_SUBSCRIPTIONS_ERROR'

export const GET_AVAILABLE_SUBSCRIPTIONS = 'GET_AVAILABLE_SUBSCRIPTIONS'
export const GET_AVAILABLE_SUBSCRIPTIONS_REQUEST = 'GET_AVAILABLE_SUBSCRIPTIONS_REQUEST'
export const GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS = 'GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS'
export const GET_AVAILABLE_SUBSCRIPTIONS_ERROR = 'GET_AVAILABLE_SUBSCRIPTIONS_ERROR'

export const ACTIVATE_SUBSCRIPTIONS = 'ACTIVATE_SUBSCRIPTIONS'
export const ACTIVATE_SUBSCRIPTIONS_REQUEST = 'ACTIVATE_SUBSCRIPTIONS_REQUEST'
export const ACTIVATE_SUBSCRIPTIONS_SUCCESS = 'ACTIVATE_SUBSCRIPTIONS_SUCCESS'
export const ACTIVATE_SUBSCRIPTIONS_ERROR = 'ACTIVATE_SUBSCRIPTIONS_ERROR'

export const RESET_SUBSCRIPTIONS = 'RESET_SUBSCRIPTIONS'

export const GET_MED_CLOUD_CAPACITY = 'GET_MED_CLOUD_CAPACITY'
export const GET_MED_CLOUD_CAPACITY_REQUEST = 'GET_MED_CLOUD_CAPACITY_REQUEST'
export const GET_MED_CLOUD_CAPACITY_SUCCESS = 'GET_MED_CLOUD_CAPACITY_SUCCESS'
export const GET_MED_CLOUD_CAPACITY_ERROR = 'GET_MED_CLOUD_CAPACITY_ERROR'
