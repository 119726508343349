import { memo, useMemo } from 'react'
import cls from 'classnames'
import { isSameDay, startOfDay, subDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { formatDate, formatShortDate, timeFormat } from 'utils'

import styles from './ChatInterlocutorOnline.module.scss'

export type TChatInterlocutorOnlineProps = {
  online: boolean
  lastSeen: string

  color?: 'default' | 'secondary'
}

const ChatInterlocutorOnlineView = ({
  online,
  lastSeen,
  color = 'default'
}: TChatInterlocutorOnlineProps) => {
  const { t } = useTranslation()

  const onlineIndicator = useMemo(() => {
    if (!online && !lastSeen) {
      return ''
    }

    if (online) {
      return t('chat.onlineIndicator')
    }

    const time = formatDate(lastSeen, timeFormat)

    const today = startOfDay(new Date())
    const yesterday = startOfDay(subDays(new Date(), 1))

    const isToday = isSameDay(new Date(lastSeen), new Date(today))
    const isYesterday = isSameDay(new Date(lastSeen), new Date(yesterday))

    if (isToday) {
      return t('chat.onlineIndicator_today', { time })
    }

    if (isYesterday) {
      return t('chat.onlineIndicator_yesterday', { time })
    }

    return t('chat.onlineIndicator_longTime', { date: formatShortDate(lastSeen), time })
  }, [lastSeen, online, t])

  return <div className={cls(styles.root, styles[color])}>{onlineIndicator}</div>
}

export const ChatInterlocutorOnline = memo(ChatInterlocutorOnlineView)
