import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { AccountsList } from 'App/components'
import { MAX_VOICE_ROOM_PARTICIPANTS } from 'globalConstants'
import { IVoiceRoomParticipantDTO } from 'interfaces/api/organizations/voiceRoom'

import styles from './Participants.module.scss'

type TParticipantsProps = {
  participants: IVoiceRoomParticipantDTO[]
}

export const Participants = ({ participants }: TParticipantsProps) => {
  const { t } = useTranslation()

  if (!participants.length) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {t('unityHub.channelsList.voice.activeParticipants', {
          number: participants.length,
          limit: MAX_VOICE_ROOM_PARTICIPANTS
        })}
      </div>
      <AccountsList
        accounts={participants}
        avatarSize={EAvatarSize.XS}
        getTitle={(number) =>
          t('unityHub.channelsList.voice.activeParticipantsPopover', { number })
        }
      />
    </div>
  )
}
