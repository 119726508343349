import { useEffect, useState } from 'react'

import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { TAccountsSelectOptionData } from '../components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { useRefValue } from './useRefValue'

export const useAccumulate = <T extends Record<string, unknown>>({
  items,
  page = 0,
  limit = PAGINATION_DEFAULT_SHOW_BY,
  args = {}
}: {
  items?: T[]
  total?: number
  page?: number
  limit?: number
  args?: { search?: string; selectedItems?: TAccountsSelectOptionData[]; searchBy?: string }
}) => {
  const { search, selectedItems, searchBy } = args
  const { getValue: getPage } = useRefValue(page)
  const { getValue: getItems } = useRefValue<T[]>(items ?? [])
  const [accumulatedItems, setAccumulatedItems] = useState<T[]>([])

  useEffect(() => {
    setAccumulatedItems(getItems() || [])
  }, [search, getItems, selectedItems, searchBy])

  useEffect(() => {
    setAccumulatedItems((currentItems) => {
      const res = [...currentItems]

      items?.forEach((item, index) => {
        res[index + getPage() * limit] = item
      })

      return !getPage() ? items ?? [] : res
    })
  }, [items, getPage, limit])

  return accumulatedItems
}
