import { Skeleton } from 'antd'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { EventInviteStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import capitalize from 'lodash/capitalize'

import { useQuery } from 'services/query'
import { getEventInvites } from 'api/events'
import { EInputSize } from 'enums'
import {
  EFilterTypes,
  EmptyList,
  Pagination,
  Scrollable,
  SearchCascader,
  SearchWrapper,
  TFilterValue,
  TOption,
  useFilters
} from 'App/components/common'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import {
  PageWrapperContext,
  PageWrapperContextProvider
} from 'App/components/PageWrapper/PageWrapperContext'
import { createSelectorOptions, generateRandomArray } from 'utils'
import { SELECT_ALL_VALUE } from 'App/components/CheckableList/CheckableListItem'

import { EventsAttendeesItem } from '../EventsAttendeesItem'

import styles from './EventsAttendeesList.module.scss'

export const EVENTS_ATTENDEES_LIST_QUERY_KEY = 'event-attendees-list'

const FAKE_ITEMS = generateRandomArray(5)

type TEventsAttendeesListProps = { eventId: string }

const EventsAttendeesListView = ({ eventId }: TEventsAttendeesListProps) => {
  const { t } = useTranslation()

  const { page, search, searchBy, reset, setPage, setSearch, setSearchBy } =
    useContext(PageWrapperContext)

  const [filterValue, setFilterValue] = useState<TFilterValue>()

  const handleResetFilter = () => setFilterValue(undefined)
  const handleChangeFilter = (_: string, value: TFilterValue) => setFilterValue(value)

  const filterOptions = useMemo<TOption[]>(
    () =>
      createSelectorOptions(Object.values(EventInviteStatusEnum), (value) =>
        capitalize(t(`enum.eventInviteStatusEnum.${value}`))
      ),
    [t]
  )

  const { filters, hasAppliedFilter } = useFilters({
    popover: true,
    value: { status: filterValue },
    buttonVariant: 'button',
    classes: { popover: styles.popover },

    filters: [
      {
        sectionKey: 'status',
        items: [
          {
            filterKey: 'status',
            valueKey: 'value',
            labelKey: 'label',
            type: EFilterTypes.CHECKBOX,
            options: filterOptions,
            withSelectAll: true
          }
        ]
      }
    ],
    onReset: handleResetFilter,
    onChange: handleChangeFilter
  })

  const { data, isFetching } = useQuery({
    queryKey: [EVENTS_ATTENDEES_LIST_QUERY_KEY, search, searchBy, filterValue, page],
    queryFn: () =>
      getEventInvites({
        id: eventId,
        search,
        searchBy,
        page,
        status:
          filterValue === SELECT_ALL_VALUE ? undefined : (filterValue as EventInviteStatusEnum)
      }),
    keepPreviousData: true
  })

  const handleSearch = useCallback(
    (searchValue: string, searchByValue: (string | number)[]) => {
      setSearchBy(searchByValue[searchByValue.length - 1].toString())
      setSearch(searchValue)
    },
    [setSearch, setSearchBy]
  )

  const hideSearchAndFilter = !data?.results?.length && !search && !hasAppliedFilter

  const searchOptions = useMemo(
    () => [
      { label: t('events.attendees.searchOptions.name'), value: 'NAME' },
      { label: t('events.attendees.searchOptions.email'), value: 'EMAIL' }
    ],
    [t]
  )

  const content = useMemo(() => {
    if (isFetching) {
      return (
        <div className={styles.list}>
          {FAKE_ITEMS.map((_, index) => (
            <Skeleton
              key={index}
              loading={true}
              active={true}
              avatar={{ size: 'large' }}
              title={true}
              paragraph={{ rows: 3 }}
              className={styles.skeleton}
            />
          ))}
        </div>
      )
    }

    if (!data?.results.length) {
      return (
        <EmptyList
          text={t('invitations.placeholder_sent')}
          icon={<AccountMultipleIcon />}
          hasAppliedSearch={!!search}
          hasAppliedFilters={hasAppliedFilter}
        />
      )
    }

    return (
      <div className={styles.list}>
        {data?.results.map(({ attendee, createdAt, email, status, token }) => (
          <EventsAttendeesItem
            key={email || attendee?.account.email || Date.now()}
            status={status}
            attendee={attendee}
            createdAt={createdAt}
            email={email}
            token={token}
            eventId={eventId}
          />
        ))}
      </div>
    )
  }, [data?.results, eventId, hasAppliedFilter, isFetching, search, t])

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {!hideSearchAndFilter && (
          <SearchWrapper filters={filters}>
            <SearchCascader
              options={searchOptions}
              allowClear={true}
              searchSize={EInputSize.L}
              onSearchChange={handleSearch}
              onCascaderChange={handleSearch}
            />
          </SearchWrapper>
        )}

        <Scrollable page={page}>
          {content}

          <Pagination
            current={page}
            total={data?.total}
            onChange={setPage}
            wrapperClassName={styles.pagination}
          />
        </Scrollable>
      </div>
    </div>
  )
}
export const EventsAttendeesList = (props: TEventsAttendeesListProps) => (
  <PageWrapperContextProvider>
    <EventsAttendeesListView {...props} />
  </PageWrapperContextProvider>
)
