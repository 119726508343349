import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { editMeeting } from 'api'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { getMeetingTime } from '../ScheduleMeetingModal.utils'

import { TEditScheduledMeetingDialogFormValues } from './EditScheduledMeetingDialog'

type TUseEditScheduledMeetingRequestProps = {
  meetingId: string

  onSuccess?: () => void
}

export const useEditScheduledMeetingRequest = ({
  meetingId,
  onSuccess
}: TUseEditScheduledMeetingRequestProps) => {
  const { t } = useTranslation()

  const { mutateAsync, isLoading, error } = useMutation({
    mutationKey: ['edit-scheduled-meeting-request'],
    mutationFn: editMeeting,
    onSuccess: (_, { notifyUsers }) => {
      const message = notifyUsers
        ? t('common.toast.meetingUpdatedWithNotification')
        : t('common.toast.meetingUpdated')

      toast.success(message, toastDefaultOptions)
      onSuccess && onSuccess()
    }
  })

  const onSubmit = useCallback(
    ({
      date,
      hasReminder,
      inviteAll,
      participants,
      timeZone,
      title,
      location,
      time,
      notifyUsers
    }: TEditScheduledMeetingDialogFormValues) => {
      if (date && time && time[0] && time[1] && timeZone?.value) {
        const { endOn, startOn } = getMeetingTime(date, time, timeZone.value)

        return mutateAsync({
          meetingId,
          title,
          hasReminder,
          location,
          startOn,
          endOn,
          notifyUsers,
          timezone: timeZone.value,
          inviteAllActiveMembers: inviteAll,
          guestAccountIds:
            !inviteAll && participants.length ? participants.map(({ id }) => id) : undefined
        })
      }

      return
    },
    [meetingId, mutateAsync]
  )

  return { onSubmit, isLoading, error }
}
