import { actionCreator, TError } from 'store'

import {
  TChangeEmailRequestPayload,
  TChangeEmailSuccessPayload,
  TChangePasswordRequestPayload,
  TChangePasswordSuccessPayload,
  TGetAccountSettingsSuccessPayload,
  TGetCaseAutoApproveContactsRequestPayload,
  TGetCaseAutoApproveContactsSuccessPayload,
  TSet2FASettingsRequestPayload,
  TSet2FASettingsSuccessPayload,
  TToggleCallOrSoundRequestPayload,
  TToggleCallOrSoundSuccessPayload,
  TSetCaseAutoApproveContactsSearchPayload,
  TSetCaseAutoApproveSettingsRequestPayload,
  TSetDefaultAccountRequestPayload,
  TSetCaseAutoApproveContactsCategoryPayload
} from './settings.types'
import * as actionTypes from './settings.actionTypes'

export const getCaseAutoApproveContactsRequest = (
  payload: TGetCaseAutoApproveContactsRequestPayload
) => actionCreator(actionTypes.GET_CASE_AUTO_APPROVE_CONTACTS_REQUEST, payload)
export const getCaseAutoApproveContactsSuccess = (
  payload: TGetCaseAutoApproveContactsSuccessPayload
) => actionCreator(actionTypes.GET_CASE_AUTO_APPROVE_CONTACTS_SUCCESS, payload)
export const getCaseAutoApproveContactsError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_AUTO_APPROVE_CONTACTS_ERROR, payload)

export const setCaseAutoApproveContactsSearch = (
  payload: TSetCaseAutoApproveContactsSearchPayload
) => actionCreator(actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_SEARCH_ACTION, payload)

export const caseAutoApproveContactsClearAction = () =>
  actionCreator(actionTypes.CASE_AUTO_APPROVE_CONTACTS_CLEAR_ACTION)

export const setCaseAutoApproveSettingsRequest = (
  payload: TSetCaseAutoApproveSettingsRequestPayload
) => actionCreator(actionTypes.SET_CASE_AUTO_APPROVE_SETTINGS_REQUEST, payload)
export const setCaseAutoApproveSettingsSuccess = () =>
  actionCreator(actionTypes.SET_CASE_AUTO_APPROVE_SETTINGS_SUCCESS)
export const setCaseAutoApproveSettingsError = (payload: TError) =>
  actionCreator(actionTypes.SET_CASE_AUTO_APPROVE_SETTINGS_ERROR, payload)

export const set2FASettingsRequest = (payload: TSet2FASettingsRequestPayload) =>
  actionCreator(actionTypes.SET_2FA_SETTINGS_REQUEST, payload)
export const set2FASettingsSuccess = (payload: TSet2FASettingsSuccessPayload) =>
  actionCreator(actionTypes.SET_2FA_SETTINGS_SUCCESS, payload)
export const set2FASettingsError = (payload: TError) =>
  actionCreator(actionTypes.SET_2FA_SETTINGS_ERROR, payload)

export const clear2FASettingsAction = () => actionCreator(actionTypes.CLEAR_2FA_SETTINGS_ACTION)

export const getAccountSettingsRequest = () =>
  actionCreator(actionTypes.GET_ACCOUNT_SETTINGS_REQUEST)
export const getAccountSettingsSuccess = (payload: TGetAccountSettingsSuccessPayload) =>
  actionCreator(actionTypes.GET_ACCOUNT_SETTINGS_SUCCESS, payload)
export const getAccountSettingsError = (payload: TError) =>
  actionCreator(actionTypes.GET_ACCOUNT_SETTINGS_ERROR, payload)

export const setDefaultAccountRequest = (payload: TSetDefaultAccountRequestPayload) =>
  actionCreator(actionTypes.SET_DEFAULT_ACCOUNT_REQUEST, payload)
export const setDefaultAccountSuccess = () => actionCreator(actionTypes.SET_DEFAULT_ACCOUNT_SUCCESS)
export const setDefaultAccountError = (payload: TError) =>
  actionCreator(actionTypes.SET_DEFAULT_ACCOUNT_ERROR, payload)

export const changePasswordRequest = (payload: TChangePasswordRequestPayload) =>
  actionCreator(actionTypes.CHANGE_PASSWORD_REQUEST, payload)
export const changePasswordSuccess = (payload: TChangePasswordSuccessPayload) =>
  actionCreator(actionTypes.CHANGE_PASSWORD_SUCCESS, payload)
export const changePasswordError = (payload: TError) =>
  actionCreator(actionTypes.CHANGE_PASSWORD_ERROR, payload)

export const clearChangePasswordAction = () =>
  actionCreator(actionTypes.CLEAR_CHANGE_PASSWORD_ACTION)

export const changeEmailRequest = (payload: TChangeEmailRequestPayload) =>
  actionCreator(actionTypes.CHANGE_EMAIL_REQUEST, payload)
export const changeEmailSuccess = (payload: TChangeEmailSuccessPayload) =>
  actionCreator(actionTypes.CHANGE_EMAIL_SUCCESS, payload)
export const changeEmailError = (payload: TError) =>
  actionCreator(actionTypes.CHANGE_EMAIL_ERROR, payload)

export const clearChangeEmailAction = () => actionCreator(actionTypes.CLEAR_CHANGE_EMAIL_ACTION)

export const toggleCallsOrSoundsRequest = (payload: TToggleCallOrSoundRequestPayload) =>
  actionCreator(actionTypes.TOGGLE_CALL_OR_SOUND_REQUEST, payload)
export const toggleCallsOrSoundsSuccess = (payload: TToggleCallOrSoundSuccessPayload) =>
  actionCreator(actionTypes.TOGGLE_CALL_OR_SOUND_SUCCESS, payload)
export const toggleCallsOrSoundsError = (payload: TError) =>
  actionCreator(actionTypes.TOGGLE_CALL_OR_SOUND_ERROR, payload)

export const setCaseAutoApproveContactsCategory = (
  payload: TSetCaseAutoApproveContactsCategoryPayload
) => actionCreator(actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_CATEGORY, payload)
