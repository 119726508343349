import { CaseStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EmptyList, EEmptyListIconSize } from 'App/components/index'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

export type TCaseAll2AllMemberListEmptyProps = {
  caseStatus: CaseStatusesEnum
}

export const CaseGroupMembersListEmpty = ({ caseStatus }: TCaseAll2AllMemberListEmptyProps) => {
  const { t } = useTranslation()

  return (
    <EmptyList
      text={t(`cases.details.membersSection.placeholderGroup`, { context: caseStatus })}
      icon={<AccountMultiplePlusIcon />}
      iconSize={EEmptyListIconSize.MD}
    />
  )
}
