import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components/common'
import {
  addUserProfileWebsite,
  createLoadingSelector,
  getUserProfileOnTheWebRequest,
  SET_USER_PROFILE_ON_THE_WEB_IDS,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import { UserProfileWebsite } from '../UserProfileWebsite'
import { List } from '../common'

const MAX_WEBSITE_AMOUNT = 10

const loadingSelector = createLoadingSelector([SET_USER_PROFILE_ON_THE_WEB_IDS])

export const UserProfileOnTheWeb = () => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const websiteIds = useAppSelector((state) => state.userProfile.onTheWeb.ids)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getUserProfileOnTheWebRequest())
  }, [dispatch])

  const onAddWebsite = () => {
    dispatch(addUserProfileWebsite())
  }

  const showAddButton = websiteIds.length !== MAX_WEBSITE_AMOUNT

  return (
    // TODO: Use the same approach as in src\App\screens\Events\EventLinks\EventLinks.tsx
    <List
      loading={loading}
      ids={websiteIds}
      item={UserProfileWebsite}
      footer={
        showAddButton && (
          <Button variant="text" icon={<PlusIcon />} onClick={onAddWebsite}>
            {t('userProfile.addWebsiteButton')}
          </Button>
        )
      }
    />
  )
}
