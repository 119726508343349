import { useRouteMatch } from 'react-router-dom'

import { useAppSelector } from 'store'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'

import { BroadcastBadge } from './BroadcastBadge'

type TBroadcastNavItemProps = {
  broadcastId: string
}

export const BroadcastNavItem = ({ broadcastId }: TBroadcastNavItemProps) => {
  const { url } = useRouteMatch()

  const { name, status, isPublic } =
    useAppSelector((state) => state.broadcasts.data.list[broadcastId]) ?? {}

  if (!name) {
    return null
  }

  return (
    <UnityHubRoomNavLink
      isPublic={isPublic}
      to={`${url}/broadcast/${broadcastId}`}
      icon={<UnityHubChannelIcon variant="broadcast" />}
      name={name}
      customBadge={<BroadcastBadge status={status} />}
    />
  )
}
