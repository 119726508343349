import cls from 'classnames'

import { SYSTEM_USER_ID } from 'globalConstants'
import { EAvatarSize } from 'enums'
import { NotificationBadge, CollapsePanel, AccountName } from 'App/components'
import {
  NotificationsP2PDetailsListContainer,
  TNotificationsP2PRowContainerProps
} from 'App/containers'

import styles from './NotificationsP2PRow.module.scss'

export type TNotificationsP2PRowProps = TNotificationsP2PRowContainerProps

export const NotificationsP2PRow = ({
  firstName,
  lastName,
  unreadCount,
  type,
  id,
  displayUserName,
  hasNotifications,
  ...rest
}: TNotificationsP2PRowProps) => {
  const isSystemUser = id === SYSTEM_USER_ID

  return (
    <CollapsePanel
      {...rest}
      className={cls(styles.root, { [styles.systemUser]: isSystemUser })}
      key={id}
      showArrow={true}
      header={
        <div className={styles.header}>
          {hasNotifications && (
            <div className={styles.badge}>
              <NotificationBadge count={Number(unreadCount)} />
            </div>
          )}

          <AccountName
            type={type.name}
            displayUserName={isSystemUser ? firstName : displayUserName}
            classes={{ root: styles.name }}
            hideIcon={isSystemUser}
            showAvatar={true}
            firstName={firstName}
            lastName={lastName}
            userId={id}
            size={EAvatarSize.XS}
            shouldRedirectToUserProfile={true}
          />
        </div>
      }
    >
      <NotificationsP2PDetailsListContainer id={id} hasNotifications={hasNotifications} />
    </CollapsePanel>
  )
}
