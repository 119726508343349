import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { List } from 'App/components/Organizations/OrganizationInvitations/List'
import {
  createLoadingSelector,
  getOrganizationAdministrationsRequest,
  getOrganizationsInvitationsFiltersSelector,
  GET_ORGANIZATION_ADMINISTRATIONS,
  useAppDispatch,
  useAppSelector
} from 'store'
import { useNotificationTracker } from 'App/hooks'

const loadingSelector = createLoadingSelector([GET_ORGANIZATION_ADMINISTRATIONS])

const TRACKED_NOTIFICATIONS = new Set([
  ProducedNotificationsEnum.ACCEPTED_ADMIN_INVITATION,
  ProducedNotificationsEnum.ACCEPTED_SUPERADMIN_INVITATION,
  ProducedNotificationsEnum.REJECTED_ADMIN_INVITATION,
  ProducedNotificationsEnum.REJECTED_SUPERADMIN_INVITATION
])

export const OrganizationInvitationsAdministration = () => {
  const dispatch = useAppDispatch()
  const ids = useAppSelector(
    (state) => state.organizations.invitations.administrations.ids,
    isEqual
  )
  const { current, showBy } = useAppSelector(getOrganizationsInvitationsFiltersSelector, isEqual)
  const total = useAppSelector((state) => state.organizations.invitations.administrations.total)
  const loading = useAppSelector(loadingSelector)

  useNotificationTracker({
    channel: 'organizationAdministrationInvitations',
    onOccurrence: () => {
      dispatch(getOrganizationAdministrationsRequest())
    },
    notifications: TRACKED_NOTIFICATIONS
  })

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(getOrganizationAdministrationsRequest({ current: page, showBy }))
    },
    [dispatch, showBy]
  )

  useEffect(() => {
    dispatch(getOrganizationAdministrationsRequest())
  }, [dispatch])

  return (
    <List
      ids={ids}
      type="administration"
      loading={loading}
      pagination={{
        total,
        current,
        showBy,
        onChange: handlePaginationChange
      }}
    />
  )
}
