import { createContext, FC, useState } from 'react'

export type TCallContext = {
  setVoiceRoomContainer: (el: HTMLDivElement | null) => void
  voiceRoomContainer: HTMLDivElement | null
}
export const CallContext = createContext<TCallContext>({} as TCallContext)

export const CallContextProvider: FC = ({ children }) => {
  const [voiceRoomContainer, setVoiceRoomContainer] = useState<HTMLDivElement | null>(null)

  return (
    <CallContext.Provider value={{ voiceRoomContainer, setVoiceRoomContainer }}>
      {children}
    </CallContext.Provider>
  )
}
