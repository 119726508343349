import { actionCreator, TError } from 'store'

import * as actionTypes from './events.actionTypes'
import {
  TGetEventsSuccessPayload,
  TGetEventsRequestPayload,
  TSetEventIdPayload
} from './events.types'

export const getEventsRequest = (payload?: TGetEventsRequestPayload) =>
  actionCreator(actionTypes.GET_EVENTS_REQUEST, payload)
export const getEventsSuccess = (payload: TGetEventsSuccessPayload) =>
  actionCreator(actionTypes.GET_EVENTS_SUCCESS, payload)
export const getEventsError = (payload: TError) =>
  actionCreator(actionTypes.GET_EVENTS_ERROR, payload)

export const resetEvents = () => actionCreator(actionTypes.RESET_EVENTS)

export const setEventId = (payload: TSetEventIdPayload) =>
  actionCreator(actionTypes.SET_EVENT_ID, payload)

export const receiveLostAccessToEvent = () => actionCreator(actionTypes.LOST_ACCESS_TO_EVENT)
