import { useCallback, useEffect, useState } from 'react'
import { ErrorCodesEnum } from '@medentee/enums'

import { EChangeEmailConfirmStep } from 'enums'
import {
  ErrorModal,
  SettingsInfoEnterEmailStep,
  SettingsInfoEnterPasswordStep,
  Steps,
  TwoFactorChecker
} from 'App/components'
import { TSettingsInfoChangeEmailConfirmContainerProps } from 'App/containers'
import { getMapComponent, isMatchErrorCode, setRedirectToastInfo } from 'utils'
import { useSteps } from 'App/hooks'

import styles from './SettingsInfoChangeEmailConfirm.module.scss'

export type TSettingsInfoChangeEmailConfirmProps = Omit<
  TSettingsInfoChangeEmailConfirmContainerProps,
  'hasConnectedAccount' | 'email' | 'accountType' | 'hideModal'
> & {
  email: string
}

type TStepContentProps = Pick<
  TSettingsInfoChangeEmailConfirmProps,
  'processing' | 'email' | 'firstExpiredTokenDate' | 'secondExpiredTokenDate'
> & {
  codeInvalid: boolean
  codeExhausted: boolean
  limitExpired: boolean
  onChangePassword: (password: string) => void
  onChangeEmail: (email: string) => void
  onConfirmPassword: (password: string) => void
  onConfirmEmail: (email: string) => void
  onConfirmOldCode: (code: string) => void
  onConfirmNewCode: (code: string) => void
  onResendOldCode: () => void
  onResendNewCode: () => void
  onClearInvalid: () => void

  newEmail?: string
}

const STEP_CONTENT = new Map<EChangeEmailConfirmStep, (props: TStepContentProps) => JSX.Element>()
  .set(
    EChangeEmailConfirmStep.SET_PASSWORD,
    ({ onChangePassword, onConfirmPassword, processing, email }) => (
      <SettingsInfoEnterPasswordStep
        onConfirm={onConfirmPassword}
        onChange={onChangePassword}
        email={email}
        loading={processing}
      />
    )
  )
  .set(
    EChangeEmailConfirmStep.FIRST_2FA,
    ({
      firstExpiredTokenDate,
      processing,
      codeInvalid,
      codeExhausted,
      onClearInvalid,
      onConfirmOldCode,
      onResendOldCode,
      email,
      limitExpired
    }) => (
      <TwoFactorChecker
        processing={processing}
        onConfirm={onConfirmOldCode}
        onResendCode={onResendOldCode}
        expiredTokenDate={firstExpiredTokenDate}
        invalid={codeInvalid}
        onClearInvalid={onClearInvalid}
        subDescription={<p className={styles.email}>{email}</p>}
        codeExhausted={codeExhausted}
        limitExpired={limitExpired}
      />
    )
  )
  .set(EChangeEmailConfirmStep.SET_EMAIL, ({ onChangeEmail, onConfirmEmail, processing }) => (
    <SettingsInfoEnterEmailStep
      onChange={onChangeEmail}
      onConfirm={onConfirmEmail}
      loading={processing}
    />
  ))
  .set(
    EChangeEmailConfirmStep.SECOND_2FA,
    ({
      secondExpiredTokenDate,
      processing,
      codeInvalid,
      onClearInvalid,
      onConfirmNewCode,
      onResendNewCode,
      newEmail,
      codeExhausted,
      limitExpired
    }) => (
      <TwoFactorChecker
        processing={processing}
        onConfirm={onConfirmNewCode}
        onResendCode={onResendNewCode}
        expiredTokenDate={secondExpiredTokenDate}
        invalid={codeInvalid}
        onClearInvalid={onClearInvalid}
        subDescription={<p className={styles.email}>{newEmail}</p>}
        codeExhausted={codeExhausted}
        limitExpired={limitExpired}
      />
    )
  )

export const SettingsInfoChangeEmailConfirm = ({
  error,
  clearChangeEmail,
  clearError,
  changeEmail,
  email,
  processing,
  firstExpiredTokenDate,
  secondExpiredTokenDate,
  first2FAStepFinished
}: TSettingsInfoChangeEmailConfirmProps) => {
  const codeInvalid = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_INVALID)
  const codeExhausted = isMatchErrorCode(error, ErrorCodesEnum.TOKEN_EXHAUSTED)
  const { step, setStep } = useSteps(EChangeEmailConfirmStep.SET_PASSWORD)
  const [oldCode, setOldCode] = useState<string | undefined>()
  const [currentPassword, setCurrentPassword] = useState<string | undefined>()
  const [newEmail, setNewEmail] = useState<string | undefined>()

  useEffect(
    () => () => {
      clearChangeEmail()
      setRedirectToastInfo(null)
    },
    [clearError, clearChangeEmail]
  )

  useEffect(() => {
    if (currentPassword?.length && firstExpiredTokenDate) {
      setStep(EChangeEmailConfirmStep.FIRST_2FA)
    }

    if (currentPassword?.length && firstExpiredTokenDate && oldCode && first2FAStepFinished) {
      setStep(EChangeEmailConfirmStep.SET_EMAIL)
    }

    if (currentPassword?.length && oldCode && newEmail && secondExpiredTokenDate) {
      setStep(EChangeEmailConfirmStep.SECOND_2FA)
    }
  }, [
    currentPassword,
    firstExpiredTokenDate,
    newEmail,
    oldCode,
    secondExpiredTokenDate,
    first2FAStepFinished,
    setStep
  ])

  const handleConfirmPassword = useCallback(
    (value: string) => {
      setCurrentPassword(value)
      changeEmail({
        step: EChangeEmailConfirmStep.SET_PASSWORD,
        currentPassword: value
      })
    },
    [changeEmail]
  )

  const handleConfirmOldCode = useCallback(
    (value: string) => {
      setOldCode(value)

      if (currentPassword) {
        changeEmail({
          step: EChangeEmailConfirmStep.FIRST_2FA,
          currentPassword,
          oldToken: value
        })
      }
    },
    [changeEmail, currentPassword]
  )

  const handleConfirmEmail = useCallback(
    (value: string) => {
      setNewEmail(value)

      if (currentPassword) {
        changeEmail({
          step: EChangeEmailConfirmStep.SET_EMAIL,
          currentPassword,
          oldToken: oldCode,
          newEmail: value
        })
      }
    },
    [changeEmail, currentPassword, oldCode]
  )

  const handleConfirmNewCode = useCallback(
    (value: string) => {
      if (currentPassword) {
        changeEmail({
          step: EChangeEmailConfirmStep.SECOND_2FA,
          currentPassword,
          oldToken: oldCode,
          newEmail,
          newToken: value
        })
      }
    },
    [changeEmail, newEmail, currentPassword, oldCode]
  )

  const handleResendOldCode = useCallback(() => {
    if (currentPassword) {
      changeEmail({
        step: EChangeEmailConfirmStep.SET_PASSWORD,
        isResend: true,
        currentPassword
      })
    }
  }, [changeEmail, currentPassword])

  const handleResendNewCode = useCallback(() => {
    if (currentPassword) {
      changeEmail({
        step: EChangeEmailConfirmStep.SET_EMAIL,
        isResend: true,
        currentPassword,
        oldToken: oldCode,
        newEmail
      })
    }
  }, [changeEmail, currentPassword, oldCode, newEmail])

  const handleClearError = useCallback(() => {
    if (error) {
      clearError(null)
    }
  }, [clearError, error])

  return (
    <div className={styles.root}>
      <Steps className={styles.steps} current={step} stepsArray={[{}, {}, {}, {}]} />

      {getMapComponent(STEP_CONTENT, step, {
        email,
        newEmail,
        codeInvalid,
        codeExhausted,
        firstExpiredTokenDate,
        secondExpiredTokenDate,
        processing,
        onResendOldCode: handleResendOldCode,
        onResendNewCode: handleResendNewCode,
        onConfirmPassword: handleConfirmPassword,
        onConfirmOldCode: handleConfirmOldCode,
        onConfirmNewCode: handleConfirmNewCode,
        onConfirmEmail: handleConfirmEmail,
        onChangePassword: handleClearError,
        onChangeEmail: handleClearError,
        onClearInvalid: handleClearError,
        limitExpired: isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)
      })}

      {!codeInvalid && !codeExhausted && <ErrorModal error={error} />}
    </div>
  )
}
