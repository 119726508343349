import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'store'
import { getMapComponent } from 'utils'
import { TChannelVariant } from 'interfaces'
import { useUnityHubCreateChannel, TUseUnityHubCreateChannel } from 'App/components/UnityHub'

import {
  EventCreateChannelMainStep,
  TEventCreateChannelMainStepProps
} from '../EventCreateChannelMainStep'
import {
  EventCreateChannelMembersStep,
  TEventCreateChannelMembersStepProps
} from '../EventCreateChannelMembersStep'
import { useEventMembersRequest } from '../useEventMembersRequest'

enum steps {
  MAIN = 0,
  MEMBERS = 1,
  CHANEL_MANAGER = 2
}

const MAX_SELECTED_CHANNEL_MANAGER = 1

type TStepsMapProps = Pick<
  TEventCreateChannelMainStepProps,
  | 'channelId'
  | 'setName'
  | 'channelManager'
  | 'isEdit'
  | 'setIsPublic'
  | 'loading'
  | 'eventId'
  | 'variant'
  | 'onEditSuccess'
  | 'dirty'
  | 'isPublic'
  | 'initialValues'
> &
  Pick<
    TEventCreateChannelMembersStepProps,
    'setChannelMembers' | 'channelMembers' | 'setChannelManager'
  > & {
    name: string
    handleOpenMain: () => void
    handleOpenChanelManager: () => void
    handleOpenMembers: () => void
  }

const STEPS_MAP = new Map<steps, (props: TStepsMapProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({
      name,
      eventId,
      channelId,
      channelMembers,
      channelManager,
      isEdit,
      loading,
      variant,
      dirty,
      isPublic,
      handleOpenChanelManager,
      handleOpenMembers,
      setName,
      setIsPublic,
      onEditSuccess,
      initialValues
    }) => (
      <EventCreateChannelMainStep
        dirty={dirty}
        variant={variant}
        eventId={eventId}
        name={name}
        channelId={channelId}
        channelMembers={channelMembers}
        channelManager={channelManager}
        setName={setName}
        onOpenChanelManager={handleOpenChanelManager}
        onOpenMembers={handleOpenMembers}
        isEdit={isEdit}
        loading={loading}
        onEditSuccess={onEditSuccess}
        isPublic={isPublic}
        setIsPublic={setIsPublic}
        initialValues={initialValues}
      />
    )
  )
  .set(
    steps.MEMBERS,
    ({
      channelMembers,
      eventId,
      channelManager,
      channelId,
      handleOpenMain,
      setChannelMembers,
      setChannelManager
    }) => (
      <EventCreateChannelMembersStep
        variant="members"
        channelManagerId={channelManager?.id}
        eventId={eventId}
        channelMembers={channelMembers}
        markChatIds={channelId ? [channelId] : []}
        onBack={handleOpenMain}
        setChannelMembers={setChannelMembers}
        setChannelManager={setChannelManager}
      />
    )
  )
  .set(steps.CHANEL_MANAGER, ({ eventId, channelManager, setChannelManager, handleOpenMain }) => (
    <EventCreateChannelMembersStep
      variant="manager"
      maxSelected={MAX_SELECTED_CHANNEL_MANAGER}
      channelManagerId={channelManager?.id}
      eventId={eventId}
      channelMembers={channelManager ? [channelManager] : []}
      onBack={handleOpenMain}
      setChannelManager={setChannelManager}
    />
  ))

export type TEventCreateChannelDialogProps = Pick<
  TUseUnityHubCreateChannel,
  'channelId' | 'channelName' | 'isPublic'
> &
  Pick<TEventCreateChannelMainStepProps, 'onEditSuccess'> & {
    eventId: string
    variant?: Extract<TChannelVariant, 'chat' | 'news'>
  }

export const EventCreateChannelDialog = ({
  eventId,
  channelName,
  onEditSuccess,
  isPublic: channelIsPublic,
  channelId = '',
  variant = 'chat'
}: TEventCreateChannelDialogProps) => {
  const {
    name,
    channelMembers,
    channelManager,
    step,
    isEdit,
    dirty,
    isPublic,
    setIsPublic,
    handleOpenMain,
    handleOpenMembers,
    handleOpenChanelManager,
    setName,
    setChannelMembers,
    setChannelManager
  } = useUnityHubCreateChannel({
    channelName,
    channelId,
    isPublic: channelIsPublic,
    maxSelectedChannelManager: MAX_SELECTED_CHANNEL_MANAGER
  })

  const room = useAppSelector((state) => state.chat.chatRooms.list[channelId])

  const initialValues = useMemo(
    () => ({ name: channelName || '', isPublic: channelIsPublic || false }),
    [channelName, channelIsPublic]
  )

  const { isLoading, accounts } = useEventMembersRequest({
    variant,
    channelId,
    eventId,
    isEdit,
    setChannelMembers
  })

  useEffect(() => {
    if (isEdit && accounts?.length) {
      setChannelManager(room.channelManager ? [room.channelManager] : undefined, true)
    }
  }, [accounts?.length, isEdit, room?.channelManager, setChannelManager])

  return (
    <>
      {getMapComponent(STEPS_MAP, step, {
        dirty,
        variant,
        name,
        channelMembers,
        channelManager,
        channelId,
        isEdit,
        eventId,
        loading: isLoading,
        isPublic,
        initialValues,
        setIsPublic,
        setName,
        setChannelMembers,
        handleOpenMain,
        handleOpenMembers,
        handleOpenChanelManager,
        setChannelManager,
        onEditSuccess
      })}
    </>
  )
}
