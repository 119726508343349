import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'types'

import { TAdvertsAction, TAdvertsState } from './adverts.types'
import * as actionTypes from './adverts.actionTypes'

export const initialAdvertsState: TAdvertsState = {
  connector: {
    list: {},
    ids: [],
    total: 0
  },
  myRequests: {
    list: {},
    ids: [],
    total: 0
  },
  myAdverts: {
    list: {},
    ids: [],
    requests: {
      list: {},
      ids: []
    },
    total: 0
  },
  filters: {
    showBy: PAGINATION_DEFAULT_SHOW_BY,
    current: PAGINATION_DEFAULT_PAGE,
    unixTs: 0,
    locations: [],
    professions: [],
    specializations: [],
    purposes: [],
    statuses: []
  },
  search: ''
}

export const advertsReducer = (
  state: TAdvertsState = initialAdvertsState,
  action: TAdvertsAction
): TAdvertsState => {
  switch (action.type) {
    case actionTypes.GET_ADVERTS_REQUEST: {
      const { unixTs = state.filters.unixTs, current = 0 } = action.payload || {}

      return {
        ...state,
        filters: {
          ...state.filters,
          unixTs,
          current
        }
      }
    }
    case actionTypes.GET_ADVERTS_SUCCESS: {
      return {
        ...state,
        connector: {
          ...state.connector,
          total: action.payload.total,
          ids: [...action.payload.ids],
          list: { ...action.payload.list }
        }
      }
    }

    case actionTypes.SET_FILTERS: {
      const {
        locations = state.filters.locations,
        professions = state.filters.professions,
        purposes = state.filters.purposes,
        specializations = state.filters.specializations,
        statuses = state.filters.statuses
      } = action.payload
      return {
        ...state,
        filters: {
          current: initialAdvertsState.filters.current,
          unixTs: initialAdvertsState.filters.unixTs,
          showBy: initialAdvertsState.filters.showBy,
          locations,
          professions,
          purposes,
          specializations,
          statuses
        }
      }
    }

    case actionTypes.RESET_FILTERS: {
      return {
        ...state,
        filters: initialAdvertsState.filters
      }
    }

    case actionTypes.GET_MY_ADVERTS_REQUEST:
    case actionTypes.GET_MY_REQUESTS_REQUEST: {
      const { showBy = state.filters.showBy, current = state.filters.current } =
        action.payload || {}

      return {
        ...state,
        filters: {
          ...state.filters,
          showBy,
          current
        }
      }
    }

    case actionTypes.GET_MY_ADVERTS_SUCCESS: {
      return {
        ...state,
        myAdverts: {
          ...state.myAdverts,
          total: action.payload.total,
          ids: [...action.payload.ids],
          list: { ...action.payload.list }
        },
        filters: {
          ...state.filters,
          current: action.payload.current
        }
      }
    }

    case actionTypes.GET_MY_ADVERT_REQUESTS_SUCCESS: {
      return {
        ...state,
        myAdverts: {
          ...state.myAdverts,
          requests: action.payload
        }
      }
    }

    case actionTypes.GET_MY_REQUESTS_SUCCESS: {
      return {
        ...state,
        myRequests: {
          ...state.myRequests,
          total: action.payload.total,
          list: action.payload.list,
          ids: action.payload.ids
        },
        filters: {
          ...state.filters,
          current: action.payload.current
        }
      }
    }

    case actionTypes.RESET_ADVERTS: {
      return {
        ...state,
        connector: initialAdvertsState.connector,
        filters: initialAdvertsState.filters,
        search: initialAdvertsState.search
      }
    }

    case actionTypes.RESET_MY_ADVERTS: {
      return {
        ...state,
        myAdverts: initialAdvertsState.myAdverts,
        filters: initialAdvertsState.filters,
        search: initialAdvertsState.search
      }
    }

    case actionTypes.RESET_MY_ADVERT_REQUESTS: {
      return {
        ...state,
        myAdverts: {
          ...state.myAdverts,
          requests: initialAdvertsState.myAdverts.requests
        }
      }
    }

    case actionTypes.RESET_MY_REQUESTS: {
      return {
        ...state,
        myRequests: initialAdvertsState.myRequests,
        filters: initialAdvertsState.filters,
        search: initialAdvertsState.search
      }
    }

    case actionTypes.SET_SEARCH: {
      return {
        ...state,
        search: action.payload.search,
        filters: {
          ...state.filters,
          current: initialAdvertsState.filters.current,
          unixTs: initialAdvertsState.filters.unixTs
        }
      }
    }

    case actionTypes.SET_ADVERTS_DATA: {
      const { id, ...rest } = action.payload

      return {
        ...state,
        myAdverts: {
          ...state.myAdverts,
          list: {
            ...state.myAdverts.list,
            [id]: {
              ...state.myAdverts.list[id],
              ...rest
            }
          }
        }
      }
    }

    default:
      return state
  }
}
