import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { setCaseCloudActiveFileId } from 'store/caseCloud'
import { CaseCloudFileDetailsMember } from 'App/components'

const mapStateToProps = (state: State) => {
  const { fileDetailsId } = state.caseCloud.cloudFiles
  const { owner } = state.caseView.data || {}

  const fileDetails = fileDetailsId ? state.caseCloud.cloudFiles.list[fileDetailsId] : null

  return { fileDetails, owner }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeFileDetails: setCaseCloudActiveFileId
    },
    dispatch
  )

export const CaseCloudFileDetailsMemberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseCloudFileDetailsMember)
