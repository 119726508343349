import { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { AccountTypeNames, FileHistoryEventsEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { AvatarContainer, TFileHistoryItemContainerProps } from 'App/containers'
import { EAvatarSize } from 'enums'
import { useChatContext } from 'App/context'
import { TruncatedText } from 'App/components'
import { EChatTypeMap, CHAT_PATH } from 'globalConstants'

import styles from './FileHistoryItem.module.scss'

type TSourceInfo = {
  name: string

  link?: string
}

export const FileHistoryItem = ({
  historyItem,
  accountId,
  displayInline
}: TFileHistoryItemContainerProps) => {
  const { chatViewType } = useChatContext()

  const { t } = useTranslation()

  const {
    createdAt,
    actorAccount,
    event,
    source,
    originalEntityId,
    case: caseInfo,
    originalActorAccount
  } = historyItem
  const { firstName, lastName, type, displayUserName, id } = actorAccount

  const sourceInfo = useMemo(() => {
    const info: TSourceInfo = { name: t(`enum.fileHistorySourcesEnum.${source}`) }

    if (!originalEntityId) {
      return info
    }

    if (caseInfo) {
      info.name = t('files.information.history.caseLabel', { title: caseInfo.title })
      info.link = `/cases/${originalEntityId}/${
        caseInfo.owner.id === accountId ? 'owner' : 'member'
      }`

      return info
    }

    info.link = generatePath(CHAT_PATH, {
      chatType: EChatTypeMap.DIALOGUE,
      chatId: originalEntityId
    })

    return info
  }, [originalEntityId, accountId, caseInfo, source, t])

  const createdAtText = useMemo(
    () => new Date(createdAt).toLocaleTimeString(undefined, { hour12: false }),
    [createdAt]
  )

  const eventLabel = useMemo(() => {
    if (event === FileHistoryEventsEnum.DELETED) {
      return t('files.information.history.movedToTrashBin')
    }

    if (event === FileHistoryEventsEnum.RESTORED) {
      return t('files.information.history.restored')
    }

    return t(`enum.fileHistoryEventsEnum.${event}`)
  }, [event, t])

  const accountName = `${displayUserName}${
    originalActorAccount && actorAccount.type.name === AccountTypeNames.BUSINESS
      ? ` (${originalActorAccount.displayUserName})`
      : ''
  }`

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={cls(styles.text, styles.textAccount, displayInline && styles.textCase)}>
          <div className={styles.headerAvatarWrapper}>
            <AvatarContainer
              firstName={firstName}
              lastName={lastName}
              type={type.name}
              displayUserName={displayUserName}
              userId={id}
              size={EAvatarSize.XXS}
              hasTooltip={false}
            />
            <TruncatedText classes={{ text: styles.accountName }} text={accountName} />
          </div>
        </div>
        <div className={cls(styles.text, displayInline && styles.textCase)}>
          <span className={styles.eventName}>{eventLabel}</span>
        </div>
        {displayInline && (
          <div className={cls(styles.text, styles.textTime, displayInline && styles.textCase)}>
            <span>{createdAtText}</span>
          </div>
        )}
      </div>
      {!displayInline && (
        <div className={styles.bottom}>
          {sourceInfo.link && !chatViewType ? (
            <Link to={sourceInfo.link} className={styles.link}>
              <TruncatedText classes={{ text: styles.source }} text={sourceInfo.name} />
            </Link>
          ) : (
            <span className={styles.source}>{sourceInfo.name}</span>
          )}
          <span>{createdAtText}</span>
        </div>
      )}
    </div>
  )
}
