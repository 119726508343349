import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TChatMessageDeletedContainerProps } from 'App/containers'
import { ChatMessagePlaceholder, ChatMessageSenderName } from 'App/components'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'

import styles from './ChatMessageDeleted.module.scss'

export type TChatMessageDeletedBaseProps = {
  id: string
  showUserName?: boolean
}
export type TChatMessageDeletedProps = TChatMessageDeletedBaseProps &
  TChatMessageDeletedContainerProps

export const ChatMessageDeleted = ({ isMessageOutgoing, senderName }: TChatMessageDeletedProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <ChatMessageSenderName senderName={senderName} />
      <ChatMessagePlaceholder
        icon={<TrashIcon className={styles.icon} />}
        label={t('chat.list.lastMessage.placeholder_deleted')}
        isMessageOutgoing={isMessageOutgoing}
        classes={{
          placeholderIcon: styles.placeholderIcon,
          placeholder: cls(styles.placeholder, { [styles.outgoingMessage]: isMessageOutgoing })
        }}
      />
    </div>
  )
}
