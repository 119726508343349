import { call, cancel, put, select, takeLatest } from 'redux-saga/effects'
import { SubscriptionIdsEnum, ErrorCodesEnum } from '@medentee/enums'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIData } from 'services/api'
import { forwardTo, isMatchErrorCode } from 'utils'
import { toastDefaultOptions } from 'globalConstants'
import { hideModalAction, showModalAction } from 'store/modal'
import { State } from 'redux/rootReducer'
import { handleError } from 'api/utils'

import { EModalComponents } from '../../App/containers/ModalRoot/ModalRoot.enums'
import i18n from '../../i18n'

import {
  ACTIVATE_SUBSCRIPTIONS_REQUEST,
  GET_ACTIVE_SUBSCRIPTIONS_REQUEST,
  GET_AVAILABLE_SUBSCRIPTIONS_REQUEST,
  GET_MED_CLOUD_CAPACITY_REQUEST
} from './subscriptions.actionTypes'
import {
  activateSubscriptionsError,
  activateSubscriptionsRequest,
  activateSubscriptionsSuccess,
  getActiveSubscriptionsError,
  getActiveSubscriptionsRequest,
  getActiveSubscriptionsSuccess,
  getAvailableSubscriptionsError,
  getAvailableSubscriptionsRequest,
  getAvailableSubscriptionsSuccess,
  getMedCloudCapacityError,
  getMedCloudCapacitySuccess
} from './subscriptions.actions'
import {
  TActiveSubscription,
  TAvailableSubscription,
  TMedCloudCapacity
} from './subscriptions.types'
import { subscriptionsNormalize } from './subscriptions.normalization'

function* getActiveSubscriptionsSaga() {
  try {
    const { data }: APIData<TActiveSubscription[]> = yield call(api.get, API.ACTIVE_SUBSCRIPTIONS)

    const normalized = subscriptionsNormalize(data)

    yield put(getActiveSubscriptionsSuccess(normalized))
  } catch (e) {
    yield put(getActiveSubscriptionsError(e))
    yield handleError(e)
  }
}

function* getAvailableSubscriptionsSaga({
  payload: { accountTypeId }
}: ReturnType<typeof getAvailableSubscriptionsRequest>) {
  try {
    const { data }: APIData<TAvailableSubscription[]> = yield call(
      api.get,
      API.AVAILABLE_SUBSCRIPTIONS(accountTypeId)
    )

    const normalized = subscriptionsNormalize(data)

    yield put(getAvailableSubscriptionsSuccess(normalized))
  } catch (e) {
    yield put(getAvailableSubscriptionsError(e))
    yield handleError(e)
  }
}

function* activateSubscriptionSaga({
  payload: { subscriptionId }
}: ReturnType<typeof activateSubscriptionsRequest>) {
  try {
    const prevActiveSubscription: TActiveSubscription = yield select(
      (state: State) => state.subscriptions.active.list[subscriptionId]
    )

    const { data }: APIData<Omit<TActiveSubscription, 'operations'>> = yield call(
      api.post,
      API.ACTIVATE_SUBSCRIPTION,
      {
        subscriptionId
      }
    )

    if (
      subscriptionId === data.subscription.id &&
      prevActiveSubscription &&
      !prevActiveSubscription.autoRenewal &&
      data.autoRenewal
    ) {
      yield toast.success(i18n.t('common.toast.renewedSubscription'), toastDefaultOptions)
    }
    yield put(activateSubscriptionsSuccess({ processingId: subscriptionId }))
    yield put(getActiveSubscriptionsRequest())
    yield put(hideModalAction())
    yield call(forwardTo, '/subscription-and-payments/balance')

    if (
      subscriptionId === SubscriptionIdsEnum.BASIC_PROFESSIONAL ||
      subscriptionId === SubscriptionIdsEnum.BASIC_BUSINESS
    ) {
      yield toast.success(i18n.t('common.toast.canceledSubscription'), toastDefaultOptions)
      yield cancel()
    }
  } catch (e) {
    yield put(activateSubscriptionsError({ ...e, processingId: subscriptionId }))
    const isShowModal: boolean = yield select((state: State) => Boolean(state.modal.modalType))

    if (!isShowModal) {
      yield handleError(e)
    } else if (isMatchErrorCode(e, ErrorCodesEnum.NOT_ENOUGH_ACCOUNT_BALANCE_FOR_SUBSCRIPTION)) {
      yield put(
        showModalAction({
          modalTitle: i18n.t('modal.upgradeSubscriptionFailed.title'),
          modalIcon: 'error',
          modalType: EModalComponents.UPGRADE_SUBSCRIPTION_FAILED
        })
      )
    }
  }
}

function* getMedCloudCapacitySaga() {
  try {
    const { data }: APIData<TMedCloudCapacity> = yield call(api.get, API.MED_CLOUD_CAPACITY)

    yield put(getMedCloudCapacitySuccess(data))
  } catch (e) {
    yield put(getMedCloudCapacityError(e))
    yield handleError(e)
  }
}

export function* subscriptionsSaga() {
  yield takeLatest(GET_ACTIVE_SUBSCRIPTIONS_REQUEST, getActiveSubscriptionsSaga)
  yield takeLatest(GET_AVAILABLE_SUBSCRIPTIONS_REQUEST, getAvailableSubscriptionsSaga)
  yield takeLatest(ACTIVATE_SUBSCRIPTIONS_REQUEST, activateSubscriptionSaga)
  yield takeLatest(GET_MED_CLOUD_CAPACITY_REQUEST, getMedCloudCapacitySaga)
}
