import cls from 'classnames'

import styles from './LengthCounter.module.scss'

export type TLengthCounterProps = {
  currentLength: number
  maxLength: number
  initialLength?: number
}

export const LengthCounter = ({
  currentLength,
  maxLength,

  initialLength = 0
}: TLengthCounterProps) => (
  <div
    className={cls({
      [styles.root]: true,
      [styles.rootSuccess]: currentLength > 0,
      [styles.rootFail]: currentLength > maxLength - initialLength
    })}
  >
    {currentLength + initialLength}/{maxLength}
  </div>
)
