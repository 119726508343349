import { useRouteMatch } from 'react-router-dom'

import { useQuery } from 'services/query'
import { getEvent } from 'api/events'
import { SocialLinks } from 'App/components/common/SocialLinks'
import { EventLinksItem } from 'App/components/Events/EventLinksItem'

import styles from './EventLinks.module.scss'

const EVENT_QUERY_KEY = 'get-event'

export const EventLinks = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const eventId = params.id

  const { data, isFetching } = useQuery({
    queryKey: [EVENT_QUERY_KEY],
    queryFn: () => getEvent(eventId),
    enabled: Boolean(eventId)
  })

  return (
    <div className={styles.root}>
      <SocialLinks data={data?.links} loading={isFetching}>
        {({ data: item, loading, onCancel, onCreate, onDelete, onUpdate }) => (
          <EventLinksItem
            eventId={eventId}
            data={item}
            loading={loading}
            onCancel={onCancel}
            onCreate={onCreate}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        )}
      </SocialLinks>
    </div>
  )
}
