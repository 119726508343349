import { useCallback, ComponentProps } from 'react'
import { ProfileLinkTypeNamesEnum } from '@medentee/enums'

import { useMutation } from 'services/query'
import { createEventLink, deleteEventLink, updateEventLink } from 'api/events'
import { TSocialLinksProps } from 'App/components/common/SocialLinks'
import { SocialLink } from 'App/components/common/SocialLink'

const CREATE_MUTATION_KEY = 'create-event-link'
const UPDATE_MUTATION_KEY = 'update-event-link'
const DELETE_MUTATION_KEY = 'delete-event-link'

type TEventLinksItemProps = ComponentProps<TSocialLinksProps['children']> & {
  eventId: string
}

export const EventLinksItem = ({
  data,
  loading,
  eventId,
  onCreate,
  onDelete,
  onUpdate,
  onCancel
}: TEventLinksItemProps) => {
  const { isLoading: isCreateLoading, mutate: createLink } = useMutation({
    mutationKey: [CREATE_MUTATION_KEY, data.id],
    mutationFn: createEventLink
  })
  const { isLoading: isUpdateLoading, mutate: updateLink } = useMutation({
    mutationKey: [UPDATE_MUTATION_KEY, data.id],
    mutationFn: updateEventLink
  })
  const { isLoading: isDeleteLoading, mutate: deleteLink } = useMutation({
    mutationKey: [DELETE_MUTATION_KEY, data.id],
    mutationFn: deleteEventLink
  })

  const handleCreate = useCallback(
    (id: string, url: string, onSuccess: () => void) => {
      createLink(
        { eventId, id, url },
        {
          onSuccess: (response) => {
            onSuccess()
            onCreate({ createdId: response.id, id, url })
          }
        }
      )
    },
    [createLink, eventId, onCreate]
  )

  const handleUpdate = useCallback(
    (id: string, url: string, onSuccess: () => void) => {
      updateLink(
        { eventId, id, url },
        {
          onSuccess: () => {
            onSuccess()
            onUpdate({ id, url })
          }
        }
      )
    },
    [eventId, onUpdate, updateLink]
  )

  const handleDelete = useCallback(
    (id: string, type: ProfileLinkTypeNamesEnum) => {
      deleteLink(
        { eventId, id },
        {
          onSuccess: () => {
            onDelete({ id, type })
          }
        }
      )
    },
    [deleteLink, eventId, onDelete]
  )

  return (
    <SocialLink
      data={data}
      loading={loading}
      deleteProcessing={isDeleteLoading}
      submitProcessing={isCreateLoading || isUpdateLoading}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onCancel={onCancel}
    />
  )
}
