import { useTranslation } from 'react-i18next'

import { createLoadingSelector, GET_ORGANIZATIONS_INVITATIONS, useAppSelector } from 'store'
import { usePrevious } from 'App/hooks'
import { UnityHubChannelIcon, UnityHubRoomNavLink } from 'App/components/UnityHub'
import { Skeleton } from 'App/components/UnityHub/UnityHubRoomsNavLayout/Skeleton'

export type TReceivedAdministrationsProps = {
  url: string
  total: number
  count?: number
}

const loadingSelector = createLoadingSelector([GET_ORGANIZATIONS_INVITATIONS])

export const ReceivedAdministrations = ({ url, count, total }: TReceivedAdministrationsProps) => {
  const loading = useAppSelector(loadingSelector)

  const totalPrev = usePrevious(total)

  const { t } = useTranslation()

  if (!totalPrev && loading) {
    return <Skeleton />
  }

  return total ? (
    <UnityHubRoomNavLink
      to={`${url}/received-administrations`}
      icon={<UnityHubChannelIcon variant="invitation" />}
      name={t('organizations.invitationsLabel')}
      count={count}
    />
  ) : null
}
