import { AccountTypeNames, AccountTypeIdEnum, ContactStatusEnum } from '@medentee/enums'

import { EInvitationType } from 'globalConstants'
import { EContactsSort } from 'enums'

import { QueryBuilder } from './QueryBuilder'

export class ContactsQueryBuilder extends QueryBuilder {
  public constructor(url: string) {
    super(url)
  }

  public search(searchQuery: string, searchBy?: string | number) {
    this.url += `${this.separator}searchQuery=${searchQuery}&searchBy=${
      searchBy ? searchBy : 'name'
    }`

    return this
  }

  public sortBy<T extends string = EContactsSort>(sortBy?: T): ContactsQueryBuilder {
    this.url += `${this.separator}sortBy=${sortBy ?? EContactsSort.DATE}`

    return this
  }

  public categoryIds(categoryIds: string[]): ContactsQueryBuilder {
    if (categoryIds.length) {
      const query = categoryIds.map((category) => `categoryIds[]=${category}`).join('&')

      this.url += `${this.separator}${query}`
    }

    return this
  }

  public status(status?: ContactStatusEnum): ContactsQueryBuilder {
    if (status) {
      this.url += `${this.separator}statuses[]=${status}`
    }

    return this
  }

  public type(type?: EInvitationType): ContactsQueryBuilder {
    if (type) {
      this.url += `${this.separator}types[]=${type}`
    }

    return this
  }

  public accountType(types?: AccountTypeNames[] | null): ContactsQueryBuilder {
    if (types && types.length) {
      const query = types.map((type) => `accountTypes[]=${AccountTypeIdEnum[type]}`).join('&')

      this.url += `${this.separator}${query}`
    }

    return this
  }

  public isOnline(check?: boolean): ContactsQueryBuilder {
    if (check) {
      this.url += `${this.separator}onlineTypes[]=ONLINE`
    }

    return this
  }

  public withoutHidden(withoutHidden?: boolean): ContactsQueryBuilder {
    this.url += `${this.separator}withoutHidden=${(!!withoutHidden).toString()}`

    return this
  }

  public caseIds(caseIds: string[]): ContactsQueryBuilder {
    const query = caseIds.map((caseId) => `caseIds[]=${caseId}`).join('&')

    this.url += `${this.separator}${query}`

    return this
  }

  public organizationIds(organizationIds: string[]): ContactsQueryBuilder {
    if (organizationIds.length) {
      const query = organizationIds.map((id) => `organizationIds[]=${id}`).join('&')

      this.url += `${this.separator}${query}`
    }

    return this
  }

  public departmentIds(departmentIds: string[]): ContactsQueryBuilder {
    if (departmentIds.length) {
      const query = departmentIds.map((id) => `departmentIds[]=${id}`).join('&')

      this.url += `${this.separator}${query}`
    }

    return this
  }

  public marks(marks?: ('VIRTUAL' | 'NOT_VIRTUAL')[]): ContactsQueryBuilder {
    if (marks) {
      const query = marks.map((mark) => `marks[]=${mark}`).join('&')

      this.url += `${this.separator}${query}`
    }

    return this
  }
}
