import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { EAvatarSize } from 'enums'
import {
  getBroadcastSelectedAccountsSelector,
  getBroadcastSelectedCategoriesSelector,
  getBroadcastSelectedDepartmentsSelector,
  getBroadcastSelectedOrganizationsSelector,
  setBroadcastAllContacts,
  setBroadcastOrganizationId,
  useAppDispatch,
  useAppSelector
} from 'store'
import { AccountsList, Button, Checkbox } from 'App/components'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { TAccountsItem } from 'App/components/common/AccountsList/AccountsListPopover'

import { TBroadcastRecipientsType } from '../../SelectRecipients/useSelectRecipientsRequests'

import styles from './SelectAccounts.module.scss'

export type TSelectAccountsProps = {
  recipientsType: TBroadcastRecipientsType
  onOpenSelectRecipients: () => void

  organizationId?: string
}

export const SelectAccounts = ({
  recipientsType,
  organizationId,
  onOpenSelectRecipients
}: TSelectAccountsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const accounts = useAppSelector(getBroadcastSelectedAccountsSelector, isEqual)
  const categories = useAppSelector(getBroadcastSelectedCategoriesSelector, isEqual)
  const departments = useAppSelector(getBroadcastSelectedDepartmentsSelector, isEqual)
  const organizations = useAppSelector(getBroadcastSelectedOrganizationsSelector, isEqual)
  const allContacts = useAppSelector((state) => state.chat.broadcast.allContacts, isEqual)
  const currentOrganizationId = useAppSelector(
    (state) => state.chat.broadcast.organizationId,
    isEqual
  )

  const isGroupsSelected = Boolean(categories.length || departments.length || organizations.length)
  const isChecked = Boolean(allContacts || currentOrganizationId)
  const shouldShowSelectAll = recipientsType === 'contacts' || recipientsType === 'staff'

  const recipients = useMemo(
    () =>
      isGroupsSelected
        ? [...categories, ...departments, ...organizations].map<TAccountsItem>(({ id, name }) => ({
            id,
            displayUserName: name
          }))
        : accounts,
    [accounts, categories, departments, isGroupsSelected, organizations]
  )

  const getTitle = useCallback(
    (number: number) =>
      t('modal.broadcastMessage.messageStep.selectedMembersPopover.title', {
        number,
        context: !isGroupsSelected && recipientsType
      }),
    [isGroupsSelected, recipientsType, t]
  )

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const checked = e.target.checked

      if (organizationId) {
        dispatch(setBroadcastOrganizationId(checked ? organizationId : null))

        return
      }

      dispatch(setBroadcastAllContacts(checked))
    },
    [dispatch, organizationId]
  )

  return (
    <div className={styles.root}>
      <div className={styles.recipients}>
        <Button
          className={styles.button}
          variant="text"
          icon={<AccountMultiplePlusIcon />}
          disabled={isChecked}
          onClick={onOpenSelectRecipients}
        >
          {t('modal.broadcastMessage.messageStep.selectRecipientsButton', {
            context: recipientsType
          })}
        </Button>

        <AccountsList
          accounts={recipients}
          avatarSize={EAvatarSize.XXS}
          variant={isGroupsSelected ? 'chips' : undefined}
          popoverVariant={isGroupsSelected ? 'chip' : 'accountName'}
          hideChips={isGroupsSelected}
          getTitle={getTitle}
        />
      </div>

      {shouldShowSelectAll && (
        <Checkbox
          label={t('modal.broadcastMessage.messageStep.selectAllButton', {
            context: recipientsType
          })}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      )}
    </div>
  )
}
