import { useTranslation } from 'react-i18next'

import { Button, TButtonProps } from 'App/components/common'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ArrowRight.svg'

import styles from './NotificationsPopoverHeader.module.scss'

type TNotificationsPopoverHeaderProps = Required<Pick<TButtonProps, 'onClick'>>

export const NotificationsPopoverHeader = ({ onClick }: TNotificationsPopoverHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {t('title', { ns: 'notifications' })}
      <Button
        className={styles.button}
        variant="inline"
        iconPosition="right"
        icon={<ArrowRightIcon />}
        onClick={onClick}
      >
        {t('popover.goToNotificationsButton', { ns: 'notifications' })}
      </Button>
    </div>
  )
}
