import { useTranslation } from 'react-i18next'
import { CaseMemberStatusesEnum } from '@medentee/enums'

import { ICaseMembersAccount } from 'interfaces'
import { AccountsList, CaseAccount } from 'App/components'
import { EAvatarSize } from 'enums'

import styles from './CaseItemMembers.module.scss'

export type TCaseItemMembersProps = {
  owner: ICaseMembersAccount
  loading: boolean
  memberList: ICaseMembersAccount[]
}

export const CaseItemMembers = ({ owner, loading, memberList }: TCaseItemMembersProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <h5 className={styles.user}>{t('cases.list.item.ownerLabel')}</h5>
        {owner && (
          <CaseAccount
            size={EAvatarSize.XS}
            userId={owner.id}
            loading={loading}
            displayUserName={owner.displayUserName}
            firstName={owner.firstName}
            lastName={owner.lastName}
            type={owner.type.name}
            hasTooltip={true}
          />
        )}
      </div>

      {!!memberList?.length && (
        <div className={styles.item}>
          <div className={styles.membersWrapper}>
            <h5 className={styles.user}>{t('cases.list.item.membersLabel')}</h5>
            <AccountsList<CaseMemberStatusesEnum>
              accounts={memberList}
              getTitle={(number) => t('cases.list.item.membersPopover.title', { number })}
              getGroupLabel={(groupLabel) => t(`enum.caseMemberStatusesEnum.${groupLabel}`)}
              getPopupContainer={(node) => node}
              avatarSize={EAvatarSize.XS}
            />
          </div>
        </div>
      )}
    </div>
  )
}
