import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Skeleton, Table } from 'antd'
import cls from 'classnames'
import template from 'lodash/template'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import { ColumnType, FilterDropdownProps, FilterValue } from 'antd/lib/table/interface'
import { useTranslation } from 'react-i18next'

import { formatLongDateTime } from 'utils'
import { EInputSize, ECaseAccountType } from 'enums'
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { Pagination, TRangePickerValue, Search, useFilters } from 'App/components'
import { FilterDropdownContainer, TCaseHistoryContainerProps } from 'App/containers'
import {
  TCaseHistoryActionGroupName,
  TCaseHistoryFilters,
  TCaseHistoryList,
  TCaseHistoryTableFilters,
  TCaseParams
} from 'store'
import {
  EEmptyListIconSize,
  EFilterTypes,
  EmptyList,
  Scrollable,
  SearchWrapper,
  TOption
} from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as FilterIcon } from 'assets/icons/Filter.svg'
import { ReactComponent as EmptyIcon } from 'assets/icons/TextBoxRemove.svg'
import { Breadcrumbs, BreadcrumbsItem } from 'App/components/common/Breadcrumbs'

import styles from './CaseHistory.module.scss'

export enum ETableColumnFilters {
  DATE_AND_TIME = 'createdAt',
  SECTION = 'caseHistoryActionGroupIds',
  DETAILS = 'youInitiator'
}

export type TSelectedKeysOptions = TRangePickerValue | string[]

type TFilter = {
  text: string
  value: string
}

export type TFilterDropdownProps<T extends TSelectedKeysOptions> = Pick<
  FilterDropdownProps,
  'clearFilters'
> & {
  type: ETableColumnFilters
  selectedKeys: T
  setSelectedKeys: (selectedKeys: T) => void
  filters?: TFilter[]
  confirm: () => void
}

export type THandleFilterConfirm = (
  value: TSelectedKeysOptions,
  type: ETableColumnFilters,
  confirm: () => void
) => void

type TGetFilterProps = {
  type: ETableColumnFilters
  filterDropdownVisible: Record<ETableColumnFilters, boolean>
  setCurrentDropdownState: (filter: ETableColumnFilters, state: boolean) => void
  toggleCurrentDropdownState: (filter: ETableColumnFilters) => void
  handleFilterConfirm: THandleFilterConfirm

  filterList?: { value: string; label: string }[]
  selectedFilters: TCaseHistoryTableFilters | null
}

export type TColumns = {
  caseHistoryFilters: TCaseHistoryFilters
  filtersCount: number | null
  resetFilters: () => void
} & Omit<TGetFilterProps, 'type' | 'filterList'>

type TStateLocation = {
  caseTitle: string
}

const defaultDropdownState: Record<string, boolean> = Object.values(ETableColumnFilters).reduce(
  (obj, key) => ({ ...obj, [key]: false }),
  {}
)

const getFilterProps = ({
  type,
  selectedFilters,
  filterDropdownVisible,
  filterList,
  setCurrentDropdownState,
  toggleCurrentDropdownState,
  handleFilterConfirm
}: TGetFilterProps): Pick<
  ColumnType<TCaseHistoryList>,
  'defaultFilteredValue' | 'filterDropdown' | 'filterDropdownVisible' | 'filterIcon' | 'filters'
> => ({
  filterDropdown: ({
    selectedKeys,
    filters,
    setSelectedKeys,
    confirm,
    clearFilters
  }: TFilterDropdownProps<TSelectedKeysOptions>) => (
    <FilterDropdownContainer
      selectedKeys={selectedKeys}
      setSelectedKeys={setSelectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      filters={filters}
      type={type}
      visible={filterDropdownVisible[type]}
      setFilterDropdownVisible={setCurrentDropdownState}
      handleFilterConfirm={handleFilterConfirm}
    />
  ),
  defaultFilteredValue:
    (selectedFilters?.[type as keyof TCaseHistoryTableFilters] as FilterValue) || [],
  filterIcon: () => <FilterIcon onClick={() => toggleCurrentDropdownState(type)} />,
  filterDropdownVisible: filterDropdownVisible[type],
  filters: filterList?.map(({ value, label: text }) => ({
    text,
    value
  }))
})

const parseTemplate = (templateString: string, data: Record<string, unknown>) => {
  const parsedTemplateString = templateString.replace(/{{/g, '<%-').replace(/}}/g, '%>')
  const detailsTemplate = template(parsedTemplateString)

  return detailsTemplate(data)
}

const ACTION_GROUPS_ORDER: { [key: string]: number } = {
  document: 0,
  members: 1,
  attachments: 2,
  history: 3
}

export const CaseHistory = ({
  caseHistoryList,
  caseHistoryFilters,
  total,
  loading,
  getCaseHistory,
  getCaseHistoryFilters
}: TCaseHistoryContainerProps) => {
  const { id: caseId, who } = useParams<TCaseParams>()

  const { t } = useTranslation()

  const [showBy, changeShowBy] = useState(PAGINATION_DEFAULT_SHOW_BY)
  const [page, changePage] = useState(PAGINATION_DEFAULT_PAGE)
  const [search, setSearch] = useState('')
  const [selectedFilters, setSelectedFilters] = useState<TCaseHistoryTableFilters | null>(null)

  const [filterDropdownVisible, setFilterDropdownVisible] = useState(defaultDropdownState)

  const { isMobile, isDesktop } = useAdaptiveLayout()

  const isOwner = who === ECaseAccountType.OWNER

  const formattedData = useMemo(
    () =>
      caseHistoryList.map((item) => {
        const details = item.details
          .replace(
            '{{initiator.name}}',
            (item.initiator?.name ?? '').toLowerCase() !== 'you'
              ? '{{initiatorNavLink}}'
              : '{{initiator.name}}'
          )
          .replace(
            '{{receiver.name}}',
            (item.receiver?.name ?? '').toLowerCase() !== 'you'
              ? '{{receiverNavLink}}'
              : '{{receiver.name}}'
          )
          .split(' ')
          .map((temp) => {
            if (temp.includes('{{initiatorNavLink}}') && item.initiator) {
              return [
                <NavLink key={item.initiator.id} to={`/profile/${item.initiator.id}`}>
                  {item.initiator.name}
                </NavLink>,
                temp.replace('{{initiatorNavLink}}', '')
              ]
            }

            if (temp.includes('{{receiverNavLink}}') && item.receiver) {
              return [
                <NavLink key={item.receiver.id} to={`/profile/${item.receiver.id}`}>
                  {item.receiver.name}
                </NavLink>,
                temp.replace('{{receiverNavLink}}', '')
              ]
            }

            return temp
          })
          .flatMap((temp) => temp)

        return {
          ...item,
          details: (
            <>
              {details.map((temp, index) => (
                <Fragment key={index}>
                  {typeof temp === 'string' ? parseTemplate(temp, item) : temp}
                  {index !== details.length - 1 ? ' ' : ''}
                </Fragment>
              ))}
            </>
          ) as unknown as string
        }
      }),
    [caseHistoryList]
  )

  const setCurrentDropdownState = useCallback(
    (filter: string, state: boolean) => {
      setFilterDropdownVisible({ ...filterDropdownVisible, [filter]: state })
    },
    [setFilterDropdownVisible, filterDropdownVisible]
  )

  const toggleCurrentDropdownState = (filter: string) => {
    setFilterDropdownVisible((prev) => ({
      ...prev,
      [filter]: !prev[filter]
    }))
  }

  const {
    state: { caseTitle }
  } = useLocation<TStateLocation>()

  useEffect(() => {
    if (caseId) {
      getCaseHistoryFilters({ caseId })
      getCaseHistory({ caseId })
    }
  }, [])

  const handlePaginationChange = (pageNumber: number, pageSize: number | undefined) => {
    const isPageSame = pageNumber === page
    const isShowBySame = pageSize === showBy
    if (!isPageSame) {
      changePage(pageNumber)
      caseId && getCaseHistory({ caseId, pageSize, pageNumber, filters: selectedFilters })
    }
    if (pageSize && !isShowBySame) {
      changeShowBy(pageSize)
      caseId && getCaseHistory({ caseId, pageSize, pageNumber, filters: selectedFilters })
    }
  }

  useEffect(() => {
    if (caseId) {
      getCaseHistory({
        caseId,
        search,
        pageSize: showBy,
        pageNumber: PAGINATION_DEFAULT_PAGE,
        filters: selectedFilters
      })
    }
  }, [selectedFilters, search, caseId, showBy, getCaseHistory])

  const handleFilterConfirm: THandleFilterConfirm = useCallback(
    (value, type, confirm) => {
      confirm()
      setSelectedFilters({ ...selectedFilters, [type]: value })
      setCurrentDropdownState(type, false)
      changePage(PAGINATION_DEFAULT_PAGE)
    },
    [selectedFilters, setCurrentDropdownState]
  )

  const actionGroups = useMemo(
    () =>
      caseHistoryFilters.actionGroups.sort((a, b) => {
        const aVal = ACTION_GROUPS_ORDER[a.name.toLowerCase()] ?? Infinity
        const bVal = ACTION_GROUPS_ORDER[b.name.toLowerCase()] ?? Infinity

        return aVal - bVal
      }),
    [caseHistoryFilters?.actionGroups]
  )

  const detailsOptions = useMemo<TOption[]>(
    () => [
      {
        label: t('cases.caseHistory.filters.youInitiator.options.you'),
        value: 'true'
      },
      {
        label: t('cases.caseHistory.filters.youInitiator.options.other'),
        value: 'false'
      }
    ],
    [t]
  )

  const columns = useMemo<ColumnType<TCaseHistoryList>[]>(
    () => [
      {
        title: t('cases.caseHistory.filters.youInitiator.title'),
        dataIndex: 'details',
        key: 'details',
        render: (details: string) => <div className={styles.cellContent}>{details}</div>,
        ...getFilterProps({
          type: ETableColumnFilters.DETAILS,
          filterList: detailsOptions,
          selectedFilters,
          filterDropdownVisible,
          setCurrentDropdownState,
          toggleCurrentDropdownState,
          handleFilterConfirm
        })
      },
      {
        title: t('cases.caseHistory.filters.caseHistoryActionGroupIds.title'),
        dataIndex: 'caseHistoryActionGroupName',
        key: 'caseHistoryActionGroupName',
        className: cls(styles.cell, styles.cellActionGroup),
        render: (caseHistoryActionGroupName: TCaseHistoryActionGroupName) => (
          <div className={styles.cellContent}>
            <p className={styles.text}>{caseHistoryActionGroupName}</p>
          </div>
        ),
        ...getFilterProps({
          type: ETableColumnFilters.SECTION,
          filterList: caseHistoryFilters.actionGroups?.map(({ id: value, name: label }) => ({
            label,
            value
          })),
          selectedFilters,
          filterDropdownVisible,
          setCurrentDropdownState,
          toggleCurrentDropdownState,
          handleFilterConfirm
        })
      },
      {
        title: t('cases.caseHistory.filters.createdAt.title'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        className: cls(styles.cell, styles.cellDate),
        render: (createdAt: string) => (
          <div className={styles.cellContent}>
            <p className={styles.text}>{formatLongDateTime(createdAt)}</p>
          </div>
        ),
        ...getFilterProps({
          type: ETableColumnFilters.DATE_AND_TIME,
          selectedFilters,
          filterDropdownVisible,
          setCurrentDropdownState,
          toggleCurrentDropdownState,
          handleFilterConfirm
        })
      }
    ],
    [
      caseHistoryFilters.actionGroups,
      detailsOptions,
      filterDropdownVisible,
      handleFilterConfirm,
      selectedFilters,
      setCurrentDropdownState,
      t
    ]
  )

  const { filters, hasAppliedFilter } = useFilters({
    value: selectedFilters || {},
    filters: [
      {
        title: t('cases.caseHistory.filters.youInitiator.title'),
        sectionKey: 'details',
        items: [
          {
            type: EFilterTypes.CHECKBOX,
            filterKey: ETableColumnFilters.DETAILS,
            options: detailsOptions
          }
        ]
      },
      {
        title: t('cases.caseHistory.filters.caseHistoryActionGroupIds.title'),
        sectionKey: 'section',
        items: [
          {
            type: EFilterTypes.CHECKBOX,
            filterKey: ETableColumnFilters.SECTION,
            valueKey: 'id',
            labelKey: 'name',
            options: actionGroups
          }
        ]
      },
      {
        title: t('cases.caseHistory.filters.createdAt.title'),
        sectionKey: 'date',
        items: [
          {
            type: EFilterTypes.DATE,
            filterKey: ETableColumnFilters.DATE_AND_TIME
          }
        ]
      }
    ],
    onReset: () => setSelectedFilters(null),
    onChange: (key, value) =>
      setSelectedFilters((currentValue) => ({ ...currentValue, [key]: value }))
  })

  const emptyText = (
    <EmptyList
      className={styles.placeholder}
      icon={<EmptyIcon />}
      iconSize={EEmptyListIconSize.MD}
      hasAppliedFilters={hasAppliedFilter}
      hasAppliedSearch={!!search}
      text={t('cases.caseHistory.placeholder')}
    />
  )

  return (
    <div className={styles.root}>
      <Breadcrumbs>
        <BreadcrumbsItem>
          <Link to="/cases">{t('cases.caseHistory.breadcrumbs.cases')}</Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem>
          <Link to={`/cases/${caseId}/${isOwner ? 'owner' : 'member'}`}>{caseTitle}</Link>
        </BreadcrumbsItem>
        <BreadcrumbsItem>{t('cases.caseHistory.breadcrumbs.caseHistory')}</BreadcrumbsItem>
      </Breadcrumbs>
      <h3 className={styles.title}>{t('cases.caseHistory.title')}</h3>
      <div className={styles.wrapper}>
        <SearchWrapper filters={!isDesktop ? filters : undefined}>
          <Search
            placeholder={t('cases.caseHistory.searchPlaceholder')}
            onChange={setSearch}
            searchSize={EInputSize.L}
          />
        </SearchWrapper>
        <Scrollable
          className={cls({ [styles.content]: true, [styles.contentSkeleton]: loading })}
          page={page}
        >
          <Skeleton
            className={styles.skeleton}
            loading={loading}
            active={true}
            title={false}
            paragraph={{ rows: 12 }}
          >
            <div className={styles.table}>
              {isMobile ? (
                <ul className={styles.list}>
                  {!formattedData.length ? (
                    <li>{emptyText}</li>
                  ) : (
                    formattedData.map((item, index) => (
                      <li key={index}>
                        {columns.map((column, colIndex) => (
                          <div key={colIndex} className={styles.listItem}>
                            <span className={styles.listTitle}>{column.title}:</span>
                            {column.dataIndex === 'createdAt'
                              ? formatLongDateTime(item.createdAt)
                              : item[column.dataIndex as keyof TCaseHistoryList]}
                          </div>
                        ))}
                      </li>
                    ))
                  )}
                </ul>
              ) : (
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={formattedData}
                  showSorterTooltip={false}
                  pagination={false}
                  tableLayout="auto"
                  locale={{
                    emptyText
                  }}
                  className={cls({ [styles.tableWithHiddenFilter]: !isDesktop })}
                  scroll={{ x: true, scrollToFirstRowOnChange: true }}
                />
              )}
            </div>

            <Pagination
              wrapperClassName={styles.paginationWrapper}
              current={page}
              total={total}
              size="default"
              showTitle={false}
              pageSize={showBy}
              onChange={handlePaginationChange}
            />
          </Skeleton>
        </Scrollable>
      </div>
    </div>
  )
}
