import { useTranslation } from 'react-i18next'

import { InvitationItemReceived } from 'App/components'
import {
  ACCEPT_CONTACT_INVITE,
  createLoadingSelector,
  createProcessingBySelector,
  GET_CONTACTS,
  REMOVE_CONTACT_INVITE,
  useAppSelector
} from 'store'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

export type TInvitationItemReceivedContainerProps = {
  id: string
}

export const InvitationItemReceivedContainer = ({ id }: TInvitationItemReceivedContainerProps) => {
  const loading = useAppSelector(createLoadingSelector([GET_CONTACTS]))
  const acceptProcessing = useAppSelector(createProcessingBySelector([ACCEPT_CONTACT_INVITE])(id))
  const rejectProcessing = useAppSelector(createProcessingBySelector([REMOVE_CONTACT_INVITE])(id))
  const { from, viewed, createdAt, invitation, message } = useAppSelector(
    (state) => state.contacts.contactInvitations.list[id] || {}
  )

  const { acceptContactInvitation, rejectContactInvitation } = useContactInvitation()

  const { t } = useTranslation()

  const handleAcceptInvite = () => {
    acceptContactInvitation({ accountId: from.id })
  }

  const handleRejectInvite = () => {
    rejectContactInvitation({ contactId: id })
  }

  return (
    <InvitationItemReceived
      sender={from}
      createdAt={createdAt}
      viewed={viewed}
      loading={loading}
      message={message}
      acceptProcessing={acceptProcessing}
      rejectProcessing={rejectProcessing}
      invitationEmail={invitation?.email}
      chipList={[
        {
          text: t('invitations.newComerLabel'),
          color: 'primary',
          variant: 'square',
          hidden: !from?.invitation
        }
      ]}
      onAccept={handleAcceptInvite}
      onReject={handleRejectInvite}
    />
  )
}
