import { useSelector } from 'react-redux'
import { BusinessAccountRoleIdEnum, SubscriptionIdsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { UserProfileAdministrationItem } from 'App/components'
import { createLoadingSelector, GET_BUSINESS_USERS } from 'store'

export type TUserProfileAdministrationItemContainerProps = {
  id: string
}

const loadingSelector = createLoadingSelector([GET_BUSINESS_USERS])

export const UserProfileAdministrationItemContainer = ({
  id
}: TUserProfileAdministrationItemContainerProps) => {
  const {
    profile,
    accountRoleId,
    canAddSuperadmin,
    online,
    loading,
    isBasicSubscription,
    professionalAccountId
  } = useSelector((state: State) => ({
    profile: state.administration.list[id] || {},
    isBasicSubscription:
      state.subscriptions.current?.subscription.id === SubscriptionIdsEnum.BASIC_BUSINESS,
    accountRoleId: state.global.accountData?.role?.id || BusinessAccountRoleIdEnum.SUPERADMIN,
    canAddSuperadmin: state.administration.canAddSuperadmin,
    online: !!state.online.admin.list[id]?.online,
    loading: loadingSelector(state),
    professionalAccountId: state.global.accountData?.professionalAccount?.id
  }))

  return (
    <UserProfileAdministrationItem
      profile={profile}
      loading={loading}
      accountRoleId={accountRoleId}
      canAddSuperadmin={canAddSuperadmin}
      online={online}
      isBasicSubscription={isBasicSubscription}
      isMyProfile={professionalAccountId === profile?.accountId}
    />
  )
}
