import { BroadcastStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Tooltip } from 'App/components/common'
import { UnityHubNavItemBadge } from 'App/components/UnityHub'
import { getMapComponent } from 'utils'
import { ReactComponent as UpdateIcon } from 'assets/icons/Update.svg'

import styles from './BroadcastBadge.module.scss'

export type TBroadcastBadgeProps = {
  status: BroadcastStatusEnum
}

const ITEMS = new Map<BroadcastStatusEnum, (t: TFunction) => JSX.Element>()
  .set(BroadcastStatusEnum.LIVE, (t) => (
    <UnityHubNavItemBadge
      variant="icon"
      icon={<span className={styles.live}>{t('liveStream.liveLabel')}</span>}
      inline={true}
      count={1}
    />
  ))
  .set(BroadcastStatusEnum.UPCOMING, (t) => (
    <Tooltip title={t('liveStream.upcomingStreamTooltip')}>
      <div className={styles.upcoming}>
        <UpdateIcon className={styles.icon} />
      </div>
    </Tooltip>
  ))

export const BroadcastBadge = ({ status }: TBroadcastBadgeProps) => {
  const { t } = useTranslation()

  return <>{getMapComponent(ITEMS, status, t)}</>
}
