import { useCallback } from 'react'
import { Col } from 'antd'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'
import { TextField } from 'App/components'
import { MIN_LENGTH_1, MAX_LENGTH_100, MAX_LENGTH_255, MAX_LENGTH_200 } from 'globalConstants'

export const FirstStep = () => {
  const { t } = useTranslation()

  const validate = useCallback(
    (maxLength: number = MAX_LENGTH_255) =>
      validation.composeValidators(
        validation.onlySpaces(),
        validation.required(),
        validation.minLength(MIN_LENGTH_1),
        validation.maxLength(maxLength)
      ),
    []
  )

  return (
    <>
      <Col xs={24}>
        <Field name="name" validate={validate()}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userLicenses.form.nameField')}
              valueLengthMax={MAX_LENGTH_255}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="number" validate={validate(MAX_LENGTH_100)}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userLicenses.form.numberField')}
              valueLengthMax={MAX_LENGTH_100}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="organization" validate={validate(MAX_LENGTH_200)}>
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userLicenses.form.organizationField')}
              valueLengthMax={MAX_LENGTH_200}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
