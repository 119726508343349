import { formatDuration, intervalToDuration } from 'date-fns'

import { formatDate, timeFormat } from 'utils'
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg'

import styles from './Duration.module.scss'

type TDurationProps = {
  startOn: string
  endOn: string
}

export const Duration = ({ startOn, endOn }: TDurationProps) => (
  <div className={styles.root}>
    <ClockIcon className={styles.icon} />
    {formatDate(new Date(startOn), timeFormat)} - {formatDate(new Date(endOn), timeFormat)} (
    {formatDuration(intervalToDuration({ start: new Date(startOn), end: new Date(endOn) }), {
      format: ['hours', 'minutes']
    })}
    )
  </div>
)
