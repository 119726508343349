import { actionCreator } from 'store'

import * as actionTypes from './videoStreaming.actionTypes'
import {
  TSetVideoStreamingDataPayload,
  TUpdateVideoStreamingItemPayload
} from './videoStreaming.types'

export const setVideoStreamingData = (payload: TSetVideoStreamingDataPayload) =>
  actionCreator(actionTypes.SET_VIDEO_STREAMING_DATA, payload)

export const updateVideoStreamingItem = (payload: TUpdateVideoStreamingItemPayload) =>
  actionCreator(actionTypes.UPDATE_VIDEO_STREAMING_ITEM, payload)
