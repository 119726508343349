import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AccountTypeNames, BroadcastTypeEnum } from '@medentee/enums'

import { useAppSelector } from 'store'

import Button from '../../common/Button/ButtonBase'

export type TBroadCastStartedNotificationProps = {
  broadcast: {
    id: string
    name: string
    type: BroadcastTypeEnum
  }
  entity: {
    id: string
    name: string
  }
}

const useCommunityPath = () => {
  const accountType = useAppSelector((state) => state.global.accountData?.type.name)
  const isProfessional = accountType === AccountTypeNames.PROFESSIONAL

  return isProfessional ? 'communities' : 'community'
}

export const BroadCastStartedNotification = ({
  entity,
  broadcast
}: TBroadCastStartedNotificationProps) => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const communityPath = useCommunityPath()
  const rootPath = broadcast.type === BroadcastTypeEnum.EVENT ? 'events' : communityPath

  const handleClick = () => push(`/${rootPath}/${entity.id}/broadcast/${broadcast.id}`)

  return (
    <div>
      <p>
        {t('liveStream.toast.started', {
          name: broadcast.name,
          entityName: entity.name
        })}
      </p>
      <Button variant="underlined" onClick={handleClick}>
        {t('liveStream.toast.goToStreamButton')}
      </Button>
    </div>
  )
}
