import { useTranslation } from 'react-i18next'

import { TCopyToCaseCloudPayload } from 'store/caseCloud'
import { Button } from 'App/components'

import styles from './CaseCloudCopySharedWithMeConfirm.module.scss'

export type TCaseCloudCopySharedWithMeConfirmProps = {
  caseId: string
  fileId: string
  fileType: string
  fileName: string
  copyToCaseCloud: (payload: TCopyToCaseCloudPayload) => void
}

export const CaseCloudCopySharedWithMeConfirm = ({
  caseId,
  fileId,
  fileType,
  fileName,
  copyToCaseCloud
}: TCaseCloudCopySharedWithMeConfirmProps) => {
  const { t } = useTranslation()

  const onCopy = () => {
    copyToCaseCloud({
      caseId,
      fileId,
      fileType,
      fileName
    })
  }

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{t('cases.modal.copyToCaseFilesConfirm.content')}</p>
      <Button className={styles.rootButton} onClick={onCopy}>
        {t('cases.modal.copyToCaseFilesConfirm.submitButton')}
      </Button>
    </div>
  )
}
