import { FC, useEffect } from 'react'
import cls from 'classnames'

import { useAppSelector } from 'store'
import { Popover } from 'App/components/common'
import { useDialog, usePrevious } from 'App/hooks'

import { TCommentsWrapperProps } from '../CommentsWrapper'

import { Content } from './Content'
import styles from './PopoverCommentsWrapper.module.scss'

export type TPopoverCommentsWrapperProps = Pick<
  TCommentsWrapperProps,
  'files' | 'fileId' | 'fileOrderId' | 'showcaseId'
> & {
  ownerId: string
}

const OFFSET_BY_Y = 12

export const PopoverCommentsWrapper: FC<TPopoverCommentsWrapperProps> = ({
  children,
  files,
  fileId,
  fileOrderId,
  showcaseId,
  ownerId
}) => {
  const { open, close, setOpen } = useDialog()
  const isFewComments = useAppSelector(
    (state) =>
      Number(state.showcases.commentCounts[showcaseId]?.list[fileId ?? '']?.commentCounter) < 2
  )
  const prevFileId = usePrevious(fileId)

  useEffect(() => {
    if (prevFileId && prevFileId !== fileId && open) {
      close()
    }
  }, [close, fileId, open, prevFileId])

  return (
    <Popover
      visible={open}
      onVisibleChange={setOpen}
      overlayClassName={cls(styles.popover, {
        [styles.popoverFewComments]: isFewComments,
        [styles.popoverOneFile]: files.length === 1
      })}
      placement={'bottomLeft'}
      align={{ offset: [0, OFFSET_BY_Y] }}
      content={
        <Content
          onClose={close}
          files={files}
          fileId={fileId}
          fileOrderId={fileOrderId}
          showcaseId={showcaseId}
          showcaseOwnerId={ownerId}
        />
      }
    >
      {children}
    </Popover>
  )
}
