import cls from 'classnames'

import { ENotificationBadgeSize } from 'enums'
import { getChatUnreadNotificationsSelector, useAppSelector } from 'store'
import { ReactComponent as CommentMultipleIcon } from 'assets/icons/CommentMultiple.svg'

import { NotificationBadge } from '../../../../components'

import styles from './CallChatMessagesIndicator.module.scss'

export type TCallChatMessagesIndicatorProps = {
  chatId: string
  collapsed: boolean
}

export const CallChatMessagesIndicator = ({
  chatId,
  collapsed
}: TCallChatMessagesIndicatorProps) => {
  const unreadChatMessages = useAppSelector(
    (state) => getChatUnreadNotificationsSelector(state, chatId) || 0
  )

  return (
    <div className={cls(styles.root, { [styles.collapsed]: collapsed })}>
      <CommentMultipleIcon />
      <NotificationBadge
        border
        variant="dot"
        className={styles.badge}
        count={unreadChatMessages}
        size={ENotificationBadgeSize.SM}
      />
    </div>
  )
}
