import { Trans, useTranslation } from 'react-i18next'
import { useCallback } from 'react'

import {
  getCurrentSubscription,
  getIsBusinessSubscription,
  getIsProfessionalSubscription,
  useAppSelector
} from 'store'
import { BASIC_SUBSCRIPTION_IDS, INFO_EMAIL } from 'globalConstants'

import { Button, Help } from '../../common'

import { SelectSubscriptionPlanButton } from './SelectSubscriptionPlanButton'
import styles from './SubscriptionPlans.module.scss'

export const useSubscriptionPlansControls = () => {
  const currentSubscription = useAppSelector(getCurrentSubscription)
  const isBusinessSubscription = useAppSelector(getIsBusinessSubscription)
  const isProfessionalSubscription = useAppSelector(getIsProfessionalSubscription)

  const { t } = useTranslation()

  const getControls = useCallback(() => {
    if (currentSubscription?.subscription?.id) {
      return BASIC_SUBSCRIPTION_IDS.has(currentSubscription.subscription.id) ? (
        <>
          <th>
            <span className={styles.info}>{t('subscriptions.plansTable.currentButton')}</span>
          </th>
          <th>
            <SelectSubscriptionPlanButton />
          </th>
        </>
      ) : (
        <>
          <th>
            <SelectSubscriptionPlanButton />
          </th>
          <th>
            <span className={styles.info}>{t('subscriptions.plansTable.currentButton')}</span>
          </th>
        </>
      )
    }

    return null
  }, [currentSubscription?.subscription?.id, t])

  const professionalControls = isBusinessSubscription ? (
    <th colSpan={2}>
      <Button disabled size="xs">
        {t('subscriptions.plansTable.forProfessionalAccount')}
      </Button>
    </th>
  ) : (
    getControls()
  )

  const businessControls = isProfessionalSubscription ? (
    <th colSpan={2}>
      <Button disabled size="xs">
        <Trans
          t={t}
          i18nKey="subscriptions.plansTable.contactSales"
          components={{
            help: (
              <Help
                className={styles.disabledHelp}
                content={
                  <Trans
                    t={t}
                    i18nKey="subscriptions.plansTable.contactSalesHelp"
                    components={{
                      link: <a href={`mailto:${INFO_EMAIL}`}>{INFO_EMAIL}</a>
                    }}
                  />
                }
              />
            )
          }}
        />
      </Button>
    </th>
  ) : (
    getControls()
  )

  return { businessControls, professionalControls }
}
