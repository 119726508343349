import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { createProcessingSelector, removePublicationRequest, REMOVE_PUBLICATION } from 'store'

export type TPublicationRemoveDialogProps = {
  id: string
}

const processingRequest = createProcessingSelector([REMOVE_PUBLICATION])

export const PublicationRemoveDialog = ({ id }: TPublicationRemoveDialogProps) => {
  const dispatch = useDispatch()

  const processing = useSelector(processingRequest)

  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(removePublicationRequest({ id }))
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Row justify="center">
          <span>{t('modal.removePublicationConfirm.content')}</span>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Button loading={processing} onClick={handleClick}>
            {t('modal.removePublicationConfirm.submitButton')}
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
