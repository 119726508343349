import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import config from 'config'
import { initSaga } from 'store'

import rootReducer from './rootReducer'

export const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({
  autoPause: true
  // options like actionSanitizer, stateSanitizer
})

export const configureStore = (state = {}) => {
  let middleware = applyMiddleware(sagaMiddleware)

  if (!config.isProduction) {
    // middleware = composeWithDevTools(middleware)
    middleware = composeEnhancers(applyMiddleware(sagaMiddleware))
  }

  return createStore(rootReducer, state, middleware)
}

export const store = configureStore()

export type AppDispatch = typeof store.dispatch

sagaMiddleware.run(initSaga)
