import { connect } from 'react-redux'
import { ChatTypeEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { NotificationBadge, TNotificationBadgeProps } from 'App/components'

export type TChatTabBadgeContainerOwnProps = {
  itemKey: ChatTypeEnum.DIALOGUE | ChatTypeEnum.CASE_GROUP
} & Omit<TNotificationBadgeProps, 'count'>

const mapStateToProps = (
  state: State,
  { itemKey, ...rest }: TChatTabBadgeContainerOwnProps
): TNotificationBadgeProps => {
  switch (itemKey) {
    case ChatTypeEnum.DIALOGUE: {
      return {
        ...rest,
        count: state.notifications.general.chats.totalUnreadContactCount
      }
    }
    case ChatTypeEnum.CASE_GROUP: {
      return {
        ...rest,
        count: state.notifications.general.chats.totalUnreadCaseCount
      }
    }
    default: {
      return { ...rest, count: 0 }
    }
  }
}

export const ChatTabBadgeContainer = connect(mapStateToProps)(NotificationBadge)
