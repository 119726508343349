import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'

import {
  createLoadingSelector,
  GET_CHAT_ROOMS,
  getChatRoomsRequest,
  resetSelectedChatAction,
  selectChatAction
} from 'store'
import { State } from 'redux/rootReducer'
import { ChatRooms } from 'App/components'

export type TChatRoomsContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_CHAT_ROOMS])

const mapStateToProps = (state: State) => ({
  ids: state.chat.chatRooms.search.length
    ? state.chat.chatRooms.foundIds
    : state.chat.chatRooms.ids,
  total: state.chat.chatRooms.total,
  selectedChat: state.chat.chatRooms.selectedChat,
  activeChatId: state.chat.chatRooms.activeChatId,
  search: state.chat.chatRooms.search,
  filters: state.chat.chatRooms.filters,
  chatType: state.chat.chatRooms.chatType,
  accountType: state.global.accountData?.type.name || AccountTypeNames.PROFESSIONAL,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectChat: selectChatAction,
      resetChat: resetSelectedChatAction,
      getChatRooms: getChatRoomsRequest
    },
    dispatch
  )

export const ChatRoomsContainer = connect(mapStateToProps, mapDispatchToProps)(ChatRooms)
