import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { CaseMembersItemInactive } from 'App/components'
import { State } from 'redux/rootReducer'
import { showModalAction, removeMemberFromCaseRequest } from 'store'
import { ECaseAccountType } from 'enums'

export type TCaseMembersItemInactiveContainerBaseProps = {
  id: string
  who?: ECaseAccountType
}

export type TCaseMembersItemInactiveContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  TCaseMembersItemInactiveContainerBaseProps

const mapStateToProps = (state: State, { id }: TCaseMembersItemInactiveContainerBaseProps) => ({
  member: state.caseMembers.members.inactive.list[id],
  caseOwnerId: state.caseView.data?.owner.id,
  isCaseOwner: state.global.accountData?.id === state.caseView.data?.owner.id
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeMemberFromCase: removeMemberFromCaseRequest,
      showModal: showModalAction
    },
    dispatch
  )

export const CaseMembersItemInactiveContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMembersItemInactive)
