import { useCallback, useEffect, useRef } from 'react'
import { AxiosError } from 'axios'

import { InfiniteData, QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from 'services/query'
import { EHistoryEvents } from 'enums'
import { queryClient } from 'queryClient'

type TBasicPayload = {
  anchorId?: string
  historyEvent?: EHistoryEvents
}

export type TUseBidirectionalRequest<D = Record<string, string>, R = Record<string, string>> = Omit<
  UseInfiniteQueryOptions<D[], AxiosError, R>,
  'queryKey' | 'queryFn' | 'select' | 'getNextPageParam' | 'cacheTime'
> & {
  queryKey: QueryKey
  queryFn: (payload: TBasicPayload) => Promise<D[]>

  initialAnchorId?: string
  select?: (data: InfiniteData<D[]>) => InfiniteData<R>
}

export const useBidirectionalRequest = <
  D extends Record<string, any>,
  R extends Record<string, any>
>({
  queryKey,
  initialAnchorId,
  queryFn,
  select,
  ...options
}: TUseBidirectionalRequest<D, R>) => {
  useEffect(() => {
    historyEventValue.current = initialAnchorId ? EHistoryEvents.JUMP : EHistoryEvents.HISTORY_FIRST
    anchorIdValue.current = initialAnchorId
  }, [initialAnchorId])

  const historyEventValue = useRef<EHistoryEvents | undefined>()
  const anchorIdValue = useRef<string | undefined>(initialAnchorId)

  const {
    data: result,
    isFetching,
    refetch,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) =>
      queryFn({
        anchorId: pageParam ?? anchorIdValue.current,
        historyEvent: historyEventValue.current
      }),
    getPreviousPageParam: (firstPage = []) => firstPage[0]?.id ?? undefined,
    getNextPageParam: (lastPage = []) => lastPage[lastPage.length - 1]?.id ?? undefined,
    select,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    ...options
  })

  const resetPages = useCallback(() => {
    queryClient.setQueryData(queryKey, () => ({
      pages: [],
      pageParams: []
    }))
  }, [queryKey])

  // TODO: Add a cleanup of old records for example after 20 pages
  const fetchData = useCallback(
    (anchorId: string, historyEvent: EHistoryEvents) => {
      historyEventValue.current = historyEvent
      anchorIdValue.current = anchorId

      if (historyEvent === EHistoryEvents.HISTORY_AFTER) {
        fetchNextPage({ pageParam: anchorId })
      }

      if (historyEvent === EHistoryEvents.HISTORY_BEFORE) {
        fetchPreviousPage({ pageParam: anchorId })
      }

      if (historyEvent === EHistoryEvents.JUMP) {
        resetPages()
        refetch({
          type: 'active'
        })
      }
    },
    [fetchNextPage, fetchPreviousPage, refetch, resetPages]
  )

  return {
    loading: isFetching,
    hasAfter: Boolean(hasNextPage),
    hasBefore: Boolean(hasPreviousPage),
    historyEvent: historyEventValue.current,
    anchorId: anchorIdValue.current,
    data: result?.pages ?? [],
    queryClient,
    fetchData
  }
}
