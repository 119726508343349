import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { AccountsList } from '../../AccountsList'
import { TAccountsSelectOptionData } from '../AccountsSelectOption/AccountsSelectOption'
import { TAccountListPopoverItemVariant } from '../../AccountsList/AccountsListPopover'

import styles from './AccountsSelectSelected.module.scss'

// ------------------ Types ------------------
export type TAccountsSelectSelectedItemProps = {
  selected: TAccountsSelectOptionData[]
  emptyContent: ReactNode
  onUnselect: (id: string) => void

  popoverVariant?: TAccountListPopoverItemVariant
  hideClearAll?: boolean
  onUnselectAll?: () => void
}
// ------------------  END  ------------------

export const AccountsSelectSelected = ({
  selected,
  emptyContent,
  hideClearAll,
  popoverVariant,
  onUnselect,
  onUnselectAll
}: TAccountsSelectSelectedItemProps) => {
  const { t } = useTranslation()

  const shouldShowClearAll = useMemo(
    () =>
      Boolean(onUnselectAll && selected.filter(({ disabled }) => !disabled).length) &&
      !hideClearAll,
    [hideClearAll, onUnselectAll, selected]
  )

  return (
    <>
      {selected.length ? (
        <>
          {shouldShowClearAll && (
            <button onClick={onUnselectAll} className={styles.clearAll}>
              {t('modal.accountsSelect.selectedAccounts.clearAllButton', {
                selectedNumber: selected.length
              })}
              <CrossIcon />
            </button>
          )}
          <AccountsList
            variant="chips"
            popoverVariant={popoverVariant}
            getTitle={(selectedNumber) =>
              t('modal.accountsSelect.accountsList.title', {
                selectedNumber
              })
            }
            accounts={selected}
            onRemove={onUnselect}
          />
        </>
      ) : (
        <div className={styles.emptyContent}>{emptyContent}</div>
      )}
    </>
  )
}
