import { useState } from 'react'
import { Skeleton } from 'antd'

import { InfiniteScroll } from 'App/components/common'
import { generateRandomArray } from 'utils'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { useFetchDepartmentStaff } from 'App/hooks/Organizations/useFetchDepartmentStaff'
import { ESorting } from 'enums'

import { StaffTabAccountItem } from '../StaffTabAccountItem'

import styles from './DepartmentStaffList.module.scss'

export type TDepartmentStaffListProps = {
  departmentId: string
  organizationId: string
  isDepartmentLead: boolean
  staffTotal: number
  setStaffTotal: (total: number) => void
}

const FAKE_ITEMS = generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH)

export const DepartmentStaffList = ({
  organizationId,
  departmentId,
  isDepartmentLead,
  setStaffTotal,
  staffTotal
}: TDepartmentStaffListProps) => {
  const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null)

  const { setPage, isLoading, items, page } = useFetchDepartmentStaff({
    departmentId,
    organizationId,
    shouldFetchStaff: true,
    sortOrder: ESorting.DESC,
    onChangeTotal: setStaffTotal
  })

  return (
    <div ref={setRootEl} className={styles.root}>
      {rootEl && (
        <InfiniteScroll
          page={page}
          loader={true}
          next={setPage}
          loading={isLoading}
          scrollableTarget={rootEl}
          total={staffTotal}
          limit={PAGINATION_DEFAULT_SHOW_BY}
          dataLength={items.length ? items.length : FAKE_ITEMS.length}
        >
          {items.length > 0
            ? items.map((item) => (
                <StaffTabAccountItem
                  key={item.account.id}
                  data={item}
                  organizationId={organizationId}
                  departmentId={departmentId}
                  isDepartmentLead={isDepartmentLead}
                />
              ))
            : FAKE_ITEMS.map((index) => <Skeleton key={index} />)}
        </InfiniteScroll>
      )}
    </div>
  )
}
