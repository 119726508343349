import { CREATE_BUSINESS_ACCOUNT_MODAL_WIDTH } from 'globalConstants'
import { RelatedAccountsListContainer, EModalComponents } from 'App/containers'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'

import styles from './RelatedAccountsListDialogContainer.module.scss'

export const RelatedAccountsListDialogContainer = () => {
  const dispatch = useAppDispatch()
  const type = useAppSelector((state) => state.global.accountData?.type?.name)

  const handleCreateBusinessAccount = () => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.CREATE_BUSINESS_ACCOUNT_DIALOG,
        modalTitle: null,
        modalWidth: CREATE_BUSINESS_ACCOUNT_MODAL_WIDTH
      })
    )
  }

  return (
    <div className={styles.root}>
      <RelatedAccountsListContainer
        type={type}
        onCreateBusinessAccount={handleCreateBusinessAccount}
      />
    </div>
  )
}
