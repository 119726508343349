import { useEffect, useMemo } from 'react'
import { AccountTypeNames, ChatTypeEnum, FileHistorySourcesEnum } from '@medentee/enums'
import { generatePath, useHistory, useRouteMatch, Switch } from 'react-router-dom'
import cls from 'classnames'
import { Route } from 'react-router'

import { ChatContextProvider } from 'App/context'
import { EChatViewType } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { TChatRoomsType } from 'store'
import {
  ChatFileInformationPanelContainer,
  RoleRouteContainer,
  TChatOneToOneContainerProps
} from 'App/containers'
import { TChatPathParams } from 'App/screens'
import {
  EChatTypeMap,
  CHAT_PATH,
  CREATE_GROUP_CHAT_PATH,
  EDIT_GROUP_CHAT_PATH,
  CHAT_ROOMS_DEFAULT_SHOW_BY
} from 'globalConstants'
import { PAGINATION_DEFAULT_PAGE } from 'types'

import { ChatContent } from './ChatContent'
import { ChatTabs } from './ChatTabs'
import styles from './ChatOneToOne.module.scss'
import { EditGroupChatWrapper } from './EditGroupChatWrapper'
import { CreateGroupChatWrapper } from './CreateGroupChatWrapper'

export type TChatOneToOneProps = TChatOneToOneContainerProps

const sources = [FileHistorySourcesEnum.P2P_CLOUD]

export const ChatOneToOne = ({
  lengthChatRooms,
  selectedChat,
  loadingChatRoom,
  loadingChatRooms,
  accountType,
  chatType,
  search,
  activeChatId,
  getChatRooms,
  resetChat,
  setChatType
}: TChatOneToOneProps) => {
  const { push } = useHistory()
  const {
    params: { chatType: pathChatType }
  } = useRouteMatch<TChatPathParams>()

  const createUpdateChatMatch = useRouteMatch([CREATE_GROUP_CHAT_PATH, EDIT_GROUP_CHAT_PATH])

  const { isDesktop } = useAdaptiveLayout()

  useEffect(() => {
    const nextType = Object.entries(EChatTypeMap).find(
      ([_, val]) => val === pathChatType
    )?.[0] as TChatRoomsType

    if (nextType && chatType !== nextType) {
      setChatType(nextType)
    }
  }, [pathChatType, setChatType, chatType])

  useEffect(() => {
    if (chatType) {
      getChatRooms({
        chatType: [ChatTypeEnum[chatType]],
        page: PAGINATION_DEFAULT_PAGE,
        showBy: CHAT_ROOMS_DEFAULT_SHOW_BY
      })
    }
  }, [chatType, getChatRooms])

  useEffect(
    () => () => {
      resetChat()
    },
    [resetChat]
  )

  const onChangeTab = (activeKey: string) => {
    setChatType(activeKey as TChatRoomsType)

    push(
      generatePath(CHAT_PATH, {
        chatType: EChatTypeMap[activeKey as TChatRoomsType]
      })
    )
  }

  const actorAccountIds = useMemo(
    () => (selectedChat?.interlocutorAccount?.id ? [selectedChat.interlocutorAccount.id] : []),
    [selectedChat?.interlocutorAccount]
  )
  const showChatRooms =
    isDesktop || (!isDesktop && !activeChatId && !createUpdateChatMatch?.isExact)
  const showChatMessages = isDesktop || (!isDesktop && activeChatId)

  return (
    <ChatContextProvider initialState={EChatViewType.P2P}>
      <div className={cls({ [styles.root]: true, [styles.rootSelectedChat]: selectedChat })}>
        {showChatRooms && (
          <div className={styles.tabsWrapper}>
            <ChatTabs
              accountType={accountType}
              chatType={chatType}
              lengthChatRooms={lengthChatRooms}
              loading={loadingChatRooms}
              onChangeTab={onChangeTab}
            />
          </div>
        )}

        <Switch>
          <RoleRouteContainer
            exact={true}
            path={CREATE_GROUP_CHAT_PATH}
            allowedAccountTypes={[AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]}
          >
            <div className={styles.contentWrapper}>
              <CreateGroupChatWrapper />
            </div>
          </RoleRouteContainer>

          <RoleRouteContainer
            exact={true}
            path={EDIT_GROUP_CHAT_PATH}
            allowedAccountTypes={[AccountTypeNames.BUSINESS, AccountTypeNames.PROFESSIONAL]}
          >
            <div className={styles.contentWrapper}>
              <EditGroupChatWrapper />
            </div>
          </RoleRouteContainer>

          <Route path={CHAT_PATH} exact={true}>
            {showChatMessages && (
              <div
                className={cls({
                  [styles.contentWrapper]: true,
                  [styles.contentWrapperEmpty]:
                    (!lengthChatRooms && !loadingChatRoom && !search) ||
                    ((!lengthChatRooms || !activeChatId) && !loadingChatRoom)
                })}
              >
                <ChatContent
                  search={search}
                  lengthChatRooms={lengthChatRooms}
                  loading={loadingChatRoom}
                  activeChatId={activeChatId}
                  accountType={accountType}
                  chatType={chatType}
                  interlocutorAccount={selectedChat?.interlocutorAccount?.type.name}
                  selectedChatStatus={selectedChat?.status}
                  isContact={selectedChat?.isContact}
                />
              </div>
            )}
          </Route>
        </Switch>
      </div>
      <ChatFileInformationPanelContainer actorAccountIds={actorAccountIds} sources={sources} />
    </ChatContextProvider>
  )
}
