import {
  intervalToDuration,
  milliseconds,
  millisecondsToHours,
  millisecondsToMinutes,
  millisecondsToSeconds
} from 'date-fns'
import { CallStatusEnum } from '@medentee/enums'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TChatMessageCall } from 'store'

type TCallStatusProps = Partial<Pick<TChatMessageCall, 'startTime' | 'endTime'>> & {
  callStatus: CallStatusEnum
  memberCallStatus?: CallStatusEnum
  yourMessage?: boolean
}

export const useCallViewDuration = (start?: Date, end?: Date | null) => {
  const { t } = useTranslation()

  const callDuration = useMemo(() => {
    if (!start || !end) {
      return t('chat.message.call.duration')
    }

    const duration = milliseconds(
      intervalToDuration({
        start: new Date(start),
        end: new Date(end)
      })
    )
    const hours = Math.floor(millisecondsToHours(duration))
    const minutes = Math.floor(millisecondsToMinutes(duration)) - hours * 60
    const seconds = Math.floor(millisecondsToSeconds(duration)) - hours * 3600 - minutes * 60

    if (hours) {
      return t('chat.message.call.duration_hour', { hours, minutes })
    } else if (minutes) {
      return t('chat.message.call.duration_min', { minutes, seconds })
    }

    return t('chat.message.call.duration_sec', { seconds })
  }, [end, start, t])

  return callDuration
}

export const useMemberCallStatus = ({
  callStatus,
  startTime,
  endTime,
  memberCallStatus,
  yourMessage
}: TCallStatusProps) => {
  const { t } = useTranslation()

  const activeCallStatus =
    memberCallStatus !== CallStatusEnum.ACTIVE
      ? t('chat.message.call.joinInMeetingsButton')
      : t('chat.message.call.activeCallLabel')

  const duration = useCallViewDuration(startTime, endTime)

  const status = useMemo(() => {
    switch (callStatus) {
      case CallStatusEnum.COMPLETED:
        if (endTime) {
          return duration
        }

        return activeCallStatus
      case CallStatusEnum.REJECTED:
        if (endTime) {
          return t('chat.message.call.status_canceled')
        }

        return activeCallStatus
      case CallStatusEnum.BUSY:
        if (yourMessage) {
          return t('chat.message.call.status_busy')
        }

        return !!endTime ? t('chat.message.call.status_missed') : activeCallStatus

      case CallStatusEnum.MISSED:
        if (yourMessage) {
          return t('chat.message.call.status_noAnswer')
        }

        return !!endTime ? t('chat.message.call.status_missed') : activeCallStatus

      case CallStatusEnum.ACTIVE:
      case CallStatusEnum.WAITING:
        return activeCallStatus

      default:
        return ''
    }
  }, [activeCallStatus, callStatus, duration, endTime, yourMessage, t])

  return status
}
