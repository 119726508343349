import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { JoinToCall } from 'App/components'
import { joinToCallRequest } from 'store'

export type TJoinToCallContainerProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      joinToCall: joinToCallRequest
    },
    dispatch
  )

export const JoinToCallContainer = connect(null, mapDispatchToProps)(JoinToCall)
