import { ReactNode } from 'react'
import { MeetingStatusEnum, MeetingInviteStatusEnum } from '@medentee/enums'
import cls from 'classnames'

import styles from './Status.module.scss'

export type TStatusProps = PropsWithClassName<{
  status: MeetingInviteStatusEnum | MeetingStatusEnum
  label: ReactNode

  variant?: 'default' | 'light'
}>

export const Status = ({ label, status, className, variant = 'default' }: TStatusProps) => (
  <span className={cls(styles.root, styles[status.toLowerCase()], className, styles[variant])}>
    {label}
  </span>
)
