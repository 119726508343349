import { ReactNode, memo } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { formatLongTimeDate } from 'utils'
import {
  AccountName,
  Button,
  DisplayLocationTime,
  DisplayProfessionsSpecializations,
  IconLabel,
  TAccountNameProps
} from 'App/components'
import { AvatarContainer } from 'App/containers'
import { EAvatarSize } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as ScheduleIcon } from 'assets/icons/Schedule.svg'
import { IEntityTime } from 'interfaces'
import { TAccountData } from 'store'

import styles from './InvitationItemReceived.module.scss'

export type TInvitationItemReceivedProps = Pick<IEntityTime, 'createdAt'> &
  Pick<TAccountNameProps, 'invitationEmail' | 'chipList'> & {
    sender: PartialBy<
      Pick<
        TAccountData,
        | 'city'
        | 'country'
        | 'displayUserName'
        | 'firstName'
        | 'lastName'
        | 'id'
        | 'type'
        | 'professions'
      >,
      'professions'
    >
    viewed: boolean
    loading: boolean
    acceptProcessing: boolean
    rejectProcessing: boolean
    onAccept: () => void
    onReject: () => void

    message?: ReactNode
  }

const InvitationItemReceivedView = ({
  sender,
  message,
  viewed,
  acceptProcessing,
  rejectProcessing,
  createdAt,
  loading,
  invitationEmail,
  chipList,
  onAccept,
  onReject
}: TInvitationItemReceivedProps) => {
  const { t } = useTranslation()

  const {
    city,
    country,
    displayUserName,
    firstName,
    id: userId,
    lastName,
    type,
    professions = []
  } = sender ?? {}

  const { isMobile } = useAdaptiveLayout()

  if (loading) {
    return <Skeleton className={styles.skeleton} loading={loading} avatar={false} active={true} />
  }

  return (
    <div
      className={cls({
        [styles.root]: true,
        [styles.rootViewed]: !viewed
      })}
    >
      {!isMobile && (
        <AvatarContainer
          userId={userId}
          size={EAvatarSize.LG}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          type={type?.name}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.details}>
          <AccountName
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type?.name}
            size={EAvatarSize.MD}
            showAvatar={isMobile}
            classes={{
              root: styles.accountNameRoot,
              content: styles.accountNameContent,
              chipList: styles.accountNameChipList
            }}
            invitationEmail={invitationEmail}
            chipList={chipList}
          />

          <div className={styles.iconLabels}>
            <DisplayLocationTime
              city={city?.cityName}
              country={country?.countryName ?? ''}
              timeZone={city?.timezone}
            />
            <IconLabel
              classes={{ label: styles.label }}
              icon={<ScheduleIcon />}
              label={t('invitations.invitedAtLabel', { date: formatLongTimeDate(createdAt) })}
            />
          </div>

          <DisplayProfessionsSpecializations
            classes={{ root: styles.professions }}
            data={professions}
          />

          {message && <p className={styles.message}>{message}</p>}
        </div>

        <div className={styles.actions}>
          <Button
            className={cls(styles.actionsBtn, styles.actionsBtnApprove)}
            size={EAvatarSize.MD}
            disabled={acceptProcessing || rejectProcessing}
            loading={acceptProcessing}
            onClick={onAccept}
          >
            {t('invitations.approveButton')}
          </Button>
          <Button
            className={styles.actionsBtn}
            size={EAvatarSize.MD}
            variant="outlined"
            disabled={acceptProcessing || rejectProcessing}
            loading={rejectProcessing}
            onClick={onReject}
          >
            {t('invitations.rejectButton')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export const InvitationItemReceived = memo(InvitationItemReceivedView)
