import { CSSProperties } from 'react'
import cls from 'classnames'

import { ReactComponent as ImagePlaceholderIcon } from 'assets/images/ImagePlaceholder.svg'

import styles from './ImagePlaceholder.module.scss'

export type TImagePlaceholderPops = PropsWithClasses<
  'root' | 'icon',
  Pick<CSSProperties, 'height' | 'width'>
>

export const ImagePlaceholder = ({ classes, width, height }: TImagePlaceholderPops) => (
  <div className={cls(styles.root, classes?.root)} style={{ width, height }}>
    <ImagePlaceholderIcon className={cls(styles.icon, classes?.icon)} />
  </div>
)
