import { PropsWithChildren } from 'react'
import { Skeleton, SkeletonProps } from 'antd'
import cls from 'classnames'

import styles from './FormSubmitButtonSkeleton.module.scss'

type TFormSubmitButtonSkeletonProps = Pick<SkeletonProps, 'loading'> &
  PropsWithChildren<
    PropsWithClassName<{
      size?: 's' | 'm'
    }>
  >

export const FormSubmitButtonSkeleton = ({
  loading,
  children,
  className,
  size = 'm'
}: TFormSubmitButtonSkeletonProps) => (
  <Skeleton
    className={cls(styles.skeleton, styles[size], className)}
    loading={loading}
    active={true}
    title={false}
    paragraph={{ rows: 1 }}
  >
    {children}
  </Skeleton>
)
