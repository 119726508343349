import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EBusinessAdminDeleteAction } from 'enums'
import { Button, ConfirmationModal } from 'App/components'
import { TConfirmBusinessAdminInviteContainerProps } from 'App/containers'

export type TConfirmBusinessAdminInviteProps = TConfirmBusinessAdminInviteContainerProps

export const ConfirmBusinessAdminInvite = ({
  processing,
  error,
  id,
  type,
  from,
  role,
  organizationId,
  onlyDowngrade,
  direction,
  description,
  deleteBusinessAdminInvite,
  removeErrorNotification
}: TConfirmBusinessAdminInviteProps) => {
  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    deleteBusinessAdminInvite({
      from,
      id,
      organizationId,
      onlyDowngrade,
      direction,
      processingId: id
    })
  }, [deleteBusinessAdminInvite, direction, from, id, onlyDowngrade, organizationId])

  const getDescription = () => {
    if (description) {
      return description
    }

    return type === EBusinessAdminDeleteAction.REJECT && role
      ? t(`modal.businessAdminInvitationConfirm.content_reject${role}`)
      : t('modal.businessAdminInvitationConfirm.content')
  }

  return (
    <ConfirmationModal
      content={getDescription()}
      controls={
        <Button loading={processing} onClick={handleClick}>
          {t(`invitations.${type.toLowerCase() as Lowercase<typeof type>}Button`)}
        </Button>
      }
      error={error}
      resetError={removeErrorNotification}
    />
  )
}
