import { Search } from 'App/components'
import { EInputSize } from 'enums'
import { TChatRoomsType } from 'store'

export type TChatRoomsHeaderSearchProps = {
  chatType: TChatRoomsType
  onChange?: (value: string) => void
  onPressEnter?: (value: string) => void
}

export const ChatRoomsHeaderSearch = ({
  chatType,
  onChange,
  onPressEnter
}: TChatRoomsHeaderSearchProps) => (
  <Search
    allowClear={true}
    clearTrigger={chatType}
    searchSize={EInputSize.L}
    onPressEnter={onPressEnter}
    onChange={onChange}
  />
)
