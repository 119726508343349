import { Link } from 'react-router-dom'
import cls from 'classnames'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { getProfileLink } from 'utils'
import { TChatRoomHeaderContainerProps, AvatarContainer } from 'App/containers'
import { EAvatarSize } from 'enums'
import { TruncatedText } from 'App/components/common'
import { ChatInterlocutorOnline, ChatTypingIndicator } from 'App/components'
import { useRedirectToUserProfile } from 'App/hooks'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'

import styles from './ChatRoomHeaderUser.module.scss'

type ChatRoomHeaderUserClasses = 'name' | 'link' | 'root' | 'nameWrapper'

export type TChatRoomHeaderUserProps = Pick<
  TChatRoomHeaderContainerProps,
  'selectedChat' | 'online' | 'lastSeen'
> & {
  avatar?: boolean
  classes?: Partial<Record<ChatRoomHeaderUserClasses, string>>
  disabled?: boolean
  isCase?: boolean
  isChatRoomMuted?: boolean
}

export const ChatRoomHeaderUser = ({
  selectedChat,
  online,
  lastSeen,
  classes,
  isChatRoomMuted,
  disabled = false,
  avatar = false,
  isCase = false
}: TChatRoomHeaderUserProps) => {
  const { t } = useTranslation()

  const { redirectToUserProfile } = useRedirectToUserProfile()

  const {
    displayUserName = '',
    firstName = '',
    lastName = '',
    id = '',
    type
  } = selectedChat?.interlocutorAccount || {}

  const chatName = selectedChat?.name ?? ''

  const isCaseGroupChat = selectedChat?.type === ChatTypeEnum.CASE_GROUP
  const isGroupChat = selectedChat?.type === ChatTypeEnum.GROUP

  const showOnline = selectedChat?.type === ChatTypeEnum.DIALOGUE

  const showAvatar = (!isCaseGroupChat && avatar) || isGroupChat

  const userName = (
    <TruncatedText
      text={isGroupChat ? chatName : displayUserName}
      classes={{ root: styles.nameRoot, text: cls(styles.name, classes?.name) }}
      onClick={
        isCase && !disabled && !isGroupChat ? redirectToUserProfile(id, type?.name) : undefined
      }
    />
  )

  const chatTitle = isCaseGroupChat ? (
    <span className={cls(styles.name, classes?.name)}>
      {t('cases.details.membersSection.groupChatLabel')}
    </span>
  ) : (
    <>
      {disabled && type ? (
        <Link to={getProfileLink(id)} className={cls(styles.link, classes?.link)}>
          {userName}
        </Link>
      ) : (
        userName
      )}
    </>
  )

  return (
    <div className={cls(styles.root, classes?.root)}>
      {showAvatar && (
        <div className={styles.avatar}>
          <AvatarContainer
            size={EAvatarSize.XS}
            userId={isGroupChat ? selectedChat.id : id}
            displayUserName={isGroupChat ? chatName : displayUserName}
            firstName={firstName}
            lastName={lastName}
            type={type?.name}
            chatType={isGroupChat ? ChatTypeEnum.GROUP : undefined}
            onClick={isCase ? redirectToUserProfile(id, type?.name) : undefined}
            showOnline={!isGroupChat}
          />
        </div>
      )}

      <div className={cls(styles.nameWrapper, classes?.nameWrapper)}>
        <div className={styles.title}>
          {chatTitle}
          {isChatRoomMuted && <ChatMutedIcon />}
        </div>

        {selectedChat?.id && (
          <ChatTypingIndicator chatId={selectedChat?.id}>
            {showOnline && (
              <ChatInterlocutorOnline
                lastSeen={lastSeen}
                online={online}
                color={online ? 'secondary' : 'default'}
              />
            )}
          </ChatTypingIndicator>
        )}
      </div>
    </div>
  )
}
