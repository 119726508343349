import { TIds } from 'store'

export function generateTableDataSource<T>(
  obj: Record<string, T>,
  ids: TIds
): (T & {
  key: string
  fileId: string
})[] {
  return ids.map((id) => ({
    ...obj[id],
    key: id,
    fileId: id
  }))
}
