import { useCallback, useEffect, useMemo, useState } from 'react'
import { Row, Skeleton } from 'antd'
import { generatePath, useHistory } from 'react-router'
import uniqBy from 'lodash/uniqBy'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { handleError, getChatMembers } from 'api'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import {
  CHAT_PATH,
  DEFAULT_SKELETON_IDS_LENGTH,
  EChatTypeMap,
  MAX_GROUP_CHAT_PARTICIPANTS
} from 'globalConstants'
import { generateRandomArray, isMatchErrorCode, sortByName } from 'utils'
import { useAccumulate, useAdaptiveLayout } from 'App/hooks'
import { Button, EmptyList, InfiniteScroll } from 'App/components/common'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { useAppDispatch, showModalAction, hideModalAction } from 'store'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

import { ChatCreateGroupMembersItem } from '../ChatCreateGroupMembersItem'
import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

import { useKebabMenu } from './useKebabMenu'
import styles from './GroupChatEditMembers.module.scss'

const FAKE_ITEMS = generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH)

export const GroupChatEditMembers = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null)

  const { isDesktop } = useAdaptiveLayout()

  const { push } = useHistory()

  const { getMenuItems } = useKebabMenu()

  const {
    chatId,
    selectedMembers,
    channelManagerId,
    removedMemberIds,
    chatMembersTotal,
    setSelectedMembers,
    setRemovedMemberIds,
    setChatMembersTotal
  } = useChatCreateGroupContext()

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['chat-create-group-get-members'],
    queryFn: () =>
      getChatMembers({
        chatId,
        page
      }),
    cacheTime: 0,
    onSuccess: (response) => {
      if (response) {
        setChatMembersTotal(response.total)
      }
    },
    onError: (err) => {
      handleError(err)

      if (isMatchErrorCode(err, ErrorCodesEnum.CHAT_ROOM_NOT_FOUND)) {
        push(generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE }))
      }
    }
  })

  const items = useAccumulate({ items: data?.results, page, total: chatMembersTotal })
  const showSkeleton = isLoading && !items.length
  const showPlaceholder = Boolean(!items.length && !isLoading)
  const showList = Boolean(rootEl && items.length && !isLoading)

  const selectedCount = selectedMembers.length + chatMembersTotal

  const disabledAddButton = selectedCount >= MAX_GROUP_CHAT_PARTICIPANTS

  const list = useMemo(
    () =>
      uniqBy([...items, ...selectedMembers], 'id')
        .filter((item) => !removedMemberIds.includes(item.id))
        .sort((a, b) => sortByName(a.displayUserName, b.displayUserName)),
    [selectedMembers, removedMemberIds, items]
  )

  const handelOpenAddMembers = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.addGroupChatMembers.title'),
        modalType: EModalComponents.GROUP_CHAT_ADD_MEMBERS,
        modalProps: {
          chatId,
          selectedCount,
          selectedMembers,
          onSubmit: (selected) => {
            setSelectedMembers((prev) => uniqBy([...prev, ...selected], 'id'))
            setRemovedMemberIds((prev) =>
              prev.filter((id) => selected.findIndex((item) => item.id === id) === -1)
            )
            dispatch(hideModalAction())
          }
        }
      })
    )
  }, [dispatch, selectedCount, chatId, selectedMembers, setSelectedMembers, setRemovedMemberIds, t])

  useEffect(() => {
    refetch()
  }, [refetch, page])

  return (
    <div className={styles.root}>
      <Row wrap={false} align="bottom" justify="space-between" className={styles.header}>
        <span className={styles.title}>{t('chat.createGroupChat.members.title')}</span>

        <Button
          variant="text"
          disabled={disabledAddButton}
          onClick={handelOpenAddMembers}
          className={styles.button}
          icon={<AccountMultiplePlusIcon />}
        >
          {t('chat.createGroupChat.members.addMembersButton')}
        </Button>
      </Row>

      <div ref={setRootEl} className={styles.list}>
        {showSkeleton &&
          FAKE_ITEMS.map((index) => <Skeleton active paragraph={false} key={index} />)}

        {showPlaceholder && <EmptyList text={t('chat.createGroupChat.members.placeholder')} />}

        {showList && (
          <InfiniteScroll
            page={page}
            next={setPage}
            loading={isFetching}
            className={styles.infiniteScroll}
            scrollableTarget={isDesktop && rootEl}
            total={chatMembersTotal}
            limit={PAGINATION_DEFAULT_SHOW_BY}
            dataLength={selectedMembers.length}
          >
            {list.map((item) => (
              <ChatCreateGroupMembersItem
                key={item.id}
                displayUserName={item.displayUserName}
                userId={item.id}
                firstName={item.firstName}
                lastName={item.lastName}
                type={item.type.name}
                variant="kebab"
                getMenuItems={getMenuItems}
                showOwnerLabel={item.id === channelManagerId}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}
