import { AccountTypeNames, BusinessAccountRoleNameEnum } from '@medentee/enums'

export const validateRoute = (
  type: AccountTypeNames,
  allowedTypes: AccountTypeNames[] | undefined = [],
  role?: BusinessAccountRoleNameEnum,
  allowedRoles?: BusinessAccountRoleNameEnum[]
) => {
  if (type === AccountTypeNames.BUSINESS && role && allowedRoles?.length) {
    return allowedRoles.includes(role)
  }

  return !allowedTypes.length || allowedTypes.includes(type)
}
