import { useCallback, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'store/store.utils'
import { GET_MY_REQUESTS, resetMyRequests, getMyRequestsRequest } from 'store/adverts'
import { createLoadingSelector } from 'store'
import { Pagination } from 'App/components/common'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { generateRandomArray } from 'utils'

import { AdvertsEmptyList } from '../../common'
import { MyRequestsItem } from '../MyRequestsItem'
import { TAdvertsLocationState } from '../../Connector/ConnectorItemRequest'

import styles from './MyRequestsList.module.scss'

const loadingSelector = createLoadingSelector([GET_MY_REQUESTS])

export const MyRequestsList = () => {
  const dispatch = useAppDispatch()
  const { replace } = useHistory()

  const { ids, total } = useAppSelector((state) => state.adverts.myRequests)
  const loading = useAppSelector(loadingSelector)
  const { current, locations, professions, purposes, showBy, specializations } = useAppSelector(
    (state) => state.adverts.filters
  )
  const search = useAppSelector((state) => state.adverts.search)

  const location = useLocation<TAdvertsLocationState>()
  const requestId = useRef(location.state?.requestId)

  const requestsIds = loading ? generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH) : ids

  useEffect(() => {
    if (requestId.current) {
      replace({
        state: {}
      })
      dispatch(getMyRequestsRequest({ requestId: requestId.current }))
    }
  }, [dispatch, replace])

  useEffect(() => {
    if (!requestId.current) {
      dispatch(getMyRequestsRequest())
    }
    // Filters are required to trigger list update
  }, [dispatch, locations, professions, purposes, specializations, search])

  useEffect(
    () => () => {
      dispatch(resetMyRequests())
    },
    [dispatch]
  )

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(getMyRequestsRequest({ current: page, showBy }))
    },
    [dispatch, showBy]
  )

  if (!loading && !requestsIds.length) {
    return (
      <AdvertsEmptyList
        search={search}
        filters={{ locations, professions, purposes, specializations }}
      />
    )
  }

  return (
    <>
      <div>
        {requestsIds.map((id) => (
          <MyRequestsItem key={id} id={id} anchor={id === requestId.current} loading={loading} />
        ))}
      </div>

      <Pagination
        current={current}
        pageSize={showBy}
        total={total}
        wrapperClassName={styles.pagination}
        onChange={handlePaginationChange}
      />
    </>
  )
}
