import { useCallback } from 'react'
import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { isBusinessAccountSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot'
import { IconButton } from 'App/components'
import { ReactComponent as StreamingIcon } from 'assets/icons/Streaming.svg'

export type TBroadcastCreateIconButtonProps = {
  entityId: string
  type: BroadcastTypeEnum
}

export const BroadcastCreateIconButton = ({ entityId, type }: TBroadcastCreateIconButtonProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const handleCreateChannel = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.CREATE_BROADCAST,
        modalTitle: t('modal.createBroadcast.mainStep.title_create'),
        modalProps: { entityId, type }
      })
    )
  }, [dispatch, entityId, type, t])

  if (!isBusinessAccount) {
    return null
  }

  return (
    <IconButton
      iconComponent={<StreamingIcon />}
      iconSize={EIconSize.CUSTOM}
      toolTip={t('liveStream.createLiveStreamButtonTooltip')}
      onClick={handleCreateChannel}
    />
  )
}
