import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppDispatch } from 'store'

import { Button } from '../../common'
import { EModalComponents } from '../../../containers'

export const SelectSubscriptionPlanButton = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleClick = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.CHANGE_SUBSCRIPTION_PLAN_DIALOG,
        modalTitle: t('modal.changeSubscriptionPlan.title'),
        modalWidth: 700
      })
    )
  }, [dispatch, t])

  return (
    <Button color="primary" size="xs" onClick={handleClick}>
      {t('subscriptions.plansTable.selectButton')}
    </Button>
  )
}
