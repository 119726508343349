import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ChatPinItem, TChatPinItemBaseProps } from 'App/components'
import { removeChatPinMessageRequest, setChatHistoryEventAction } from 'store'

export type TChatPinItemContainerBaseProps = ReturnType<typeof mapDispatchToProps>

export type TChatPinItemContainerProps = TChatPinItemBaseProps & TChatPinItemContainerBaseProps

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removePin: removeChatPinMessageRequest,
      setEvent: setChatHistoryEventAction
    },
    dispatch
  )

export const ChatPinItemContainer = connect(null, mapDispatchToProps)(ChatPinItem)
