import { FC, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  caseMemberInviteRejectError,
  CASE_MEMBER_INVITE_REJECT,
  caseMemberInviteRejectRequest,
  CASE_MEMBER_INVITE_APPROVE
} from 'store'
import { State } from 'redux/rootReducer'
import { Button, ErrorModal, TextField } from 'App/components/'
import { validation } from 'utils'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './CaseMemberInviteRejectDialog.module.scss'

const REASON_MAX_LENGTH = 2000

export type TCaseMemberInviteRejectDialogBaseProps = {
  title: string
  inviteId: string
}

export type TCaseMemberInviteRejectDialogValues = {
  reason?: string
}

type TCaseMemberInviteRejectDialogComponentProps = TCaseMemberInviteRejectDialogBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseMemberInviteRejectDialogComponent = ({
  title,
  inviteId,
  processing,
  error,
  setCaseMemberInviteRejectError,
  caseMemberInviteReject
}: TCaseMemberInviteRejectDialogComponentProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  useEffect(
    () => () => {
      setCaseMemberInviteRejectError(null)
    },
    [setCaseMemberInviteRejectError]
  )

  const onSubmit = useCallback(
    (values: TCaseMemberInviteRejectDialogValues) => {
      caseMemberInviteReject({
        ...values,
        inviteId,
        processingId: inviteId
      })
    },
    [caseMemberInviteReject, inviteId]
  )

  return (
    <div className={styles.root}>
      <p className={styles.text}>{t('cases.modal.memberInviteReject.content', { title })}</p>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="reason"
              validate={validation.composeValidators(validation.maxLength(REASON_MAX_LENGTH))}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  topLabel={t('cases.modal.memberInviteReject.descriptionField')}
                  multiline={true}
                  rowsMax={isMobile ? 7 : 4}
                  rows={isMobile ? 7 : 4}
                  valueLengthMax={REASON_MAX_LENGTH}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                />
              )}
            </Field>
            <Button
              type="submit"
              loading={processing}
              disabled={form.getState().invalid}
              className={cls({
                [styles.button]: true,
                [styles.error]: !!error
              })}
            >
              {t('cases.modal.memberInviteReject.submitButton')}
            </Button>

            <ErrorModal error={error} />
          </form>
        )}
      </Form>
    </div>
  )
}
const processingSelector = createProcessingBySelector([
  CASE_MEMBER_INVITE_REJECT,
  CASE_MEMBER_INVITE_APPROVE
])

const mapStateToProps = (state: State, { inviteId }: TCaseMemberInviteRejectDialogBaseProps) => ({
  processing: processingSelector(inviteId)(state),
  error: state.casesInvite.inviteRejectError
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setCaseMemberInviteRejectError: caseMemberInviteRejectError,
      caseMemberInviteReject: caseMemberInviteRejectRequest
    },
    dispatch
  )

export const CaseMemberInviteRejectDialog: FC<TCaseMemberInviteRejectDialogBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMemberInviteRejectDialogComponent)
