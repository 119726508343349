import { useRouteMatch } from 'react-router-dom'

import { useMutation } from 'services/query'
import { readNotifications } from 'api/notifications'
import {
  getNotificationsGeneralRequest,
  getNotificationsProtocolUsersRequest,
  useAppDispatch
} from 'store'

type TUseReadNotificationsProps = {
  onSuccess?: () => void
}

export const useReadNotifications = (props?: TUseReadNotificationsProps) => {
  const dispatch = useAppDispatch()

  const notificationsRouteMatch = useRouteMatch('/notifications')

  const { mutate, isLoading } = useMutation({
    mutationKey: ['read-notifications'],
    mutationFn: readNotifications,
    onSuccess: () => {
      if (notificationsRouteMatch?.isExact) {
        dispatch(getNotificationsProtocolUsersRequest())
      }

      dispatch(getNotificationsGeneralRequest())

      props?.onSuccess && props.onSuccess()
    }
  })

  return {
    mutate,
    isLoading
  }
}
