import { useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { Trans, useTranslation } from 'react-i18next'

import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { ReactComponent as StarFillIcon } from 'assets/icons/StarFill.svg'
import { EModalComponents } from 'App/containers'
import { Button, FieldLabel, Help, TruncatedText } from 'App/components/common'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { EAvatarSize } from 'enums'
import { AvatarContainer } from 'App/containers/AvatarContainer'
import { IDepartment } from 'interfaces'
import { useAdaptiveLayout, useRefValue } from 'App/hooks'

import styles from './ManageDepartmentLead.module.scss'
import { TManageDepartmentLeadModalProps } from './ManageDepartmentLeadModal'

export type TManageDepartmentLeadProps = Pick<
  TManageDepartmentLeadModalProps,
  'organizationId' | 'account'
> & {
  onChange: (account: TAccountsSelectOptionData) => void
}

export const ManageDepartmentLead = ({
  organizationId,
  account,
  onChange
}: TManageDepartmentLeadProps) => {
  const dispatch = useAppDispatch()

  const { change } = useForm()

  const { values } = useFormState<IDepartment>()

  const { getValue: getStaff } = useRefValue(values.staffs)

  const { t } = useTranslation()

  const { isMobile } = useAdaptiveLayout()

  const handleClick = () => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.manageDepartmentLead.title'),
        modalType: EModalComponents.SELECT_DEPARTMENT_LEAD,
        modalProps: {
          account,
          organizationId,
          onSubmit: (value: TAccountsSelectOptionData[]) => {
            const newLeader = value[0]
            onChange(newLeader)
            dispatch(hideModalAction())

            const staff = getStaff()

            if (newLeader) {
              if (staff.every(({ id }) => id !== newLeader.id)) {
                change('staffs', [...staff, newLeader])
              }
            } else if (staff.find(({ id }) => id !== values.leader?.id)) {
              change(
                'staffs',
                staff.filter(({ id }) => id !== values.leader?.id)
              )
            }
          }
        }
      })
    )
  }

  useEffect(() => {
    const leader = values.leader

    if (leader) {
      if (getStaff().find(({ id }) => id !== leader.id)) {
        change('staff', [...getStaff(), leader])
      }
    }
  }, [change, getStaff, values.leader])

  return (
    <div className={styles.root}>
      <FieldLabel
        label={
          <Trans
            t={t}
            i18nKey="organizations.editDepartment.form.leader"
            components={{
              help: (
                <Help
                  iconVariant="info"
                  content={t('organizations.editDepartment.form.leaderHelp')}
                />
              )
            }}
          />
        }
      />
      <div className={styles.wrapper}>
        <Button
          variant="text"
          onClick={handleClick}
          className={styles.button}
          icon={<StarFillIcon />}
        >
          {t('organizations.editDepartment.leaderButton')}
        </Button>

        {account && (
          <div className={styles.lead}>
            {!isMobile && <TruncatedText text={account.displayUserName} />}
            {account.firstName && account.lastName && (
              <AvatarContainer
                userId={account.id}
                displayUserName={account.displayUserName}
                firstName={account.firstName}
                lastName={account.lastName}
                type={account.type?.name}
                size={EAvatarSize.MD}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
