import { useTranslation } from 'react-i18next'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { Help } from '../../common'
import { TSubscriptionPlans, useSubscriptionPlans } from '../useSubscriptionPlans'

import { FeatureValue } from './FeatureValue'
import styles from './SubscriptionPlans.module.scss'
import { useSubscriptionPlansControls } from './useSubscriptionPlansControls'

export type TDesktopPlansTableProps = {
  data: TSubscriptionPlans
}

export const DesktopPlansTable = ({ data }: TDesktopPlansTableProps) => {
  const { professionalControls, businessControls } = useSubscriptionPlansControls()

  const subscriptionPlans = useSubscriptionPlans(data)

  const { t } = useTranslation()

  return (
    <table className={styles.table}>
      <thead className={styles.header}>
        <tr className={styles.heading}>
          <th></th>
          <th></th>
          <th className={styles.professional} colSpan={2}>
            {t('subscriptions.plansTable.professionalAccount')}
          </th>
          <th className={styles.business} colSpan={2}>
            {t('subscriptions.plansTable.businessAccount')}
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BASIC')}</strong>
              {t('subscriptions.freePriceLabel')}
            </div>
          </th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.PROFESSIONAL')}</strong>
              {t('subscriptions.plansTable.price', {
                price: data.get(SubscriptionIdsEnum.PROFESSIONAL)?.price
              })}
            </div>
          </th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BASIC')}</strong>
              {t('subscriptions.freePriceLabel')}
            </div>
          </th>
          <th>
            <div className={styles.headerBlock}>
              <strong>{t('subscriptions.plan.BUSINESS')}</strong>
              {t('subscriptions.plansTable.price', {
                price: data.get(SubscriptionIdsEnum.BUSINESS)?.price
              })}
            </div>
          </th>
        </tr>
        <tr className={styles.controls}>
          <th></th>
          <th></th>
          {professionalControls}
          {businessControls}
        </tr>
      </thead>
      {subscriptionPlans.map(({ title, features }, index) => (
        <tbody key={index}>
          <tr className={styles.headingRow}>
            <th colSpan={6} className={styles.title}>
              {title}
            </th>
          </tr>
          {features.map(
            ({ tooltip, title: featureTitle, professional, business }, featureIndex) => (
              <tr
                key={featureIndex}
                className={featureIndex % 2 === 0 ? styles.highlighted : undefined}
              >
                <td className={styles.title}>{featureTitle}</td>
                <td>{tooltip && <Help size="md" content={tooltip} />}</td>
                <td>
                  <FeatureValue value={professional.basic} />
                </td>
                <td>
                  <FeatureValue value={professional.professional} />
                </td>
                <td>
                  <FeatureValue value={business.basic} />
                </td>
                <td>
                  <FeatureValue value={business.business} />
                </td>
              </tr>
            )
          )}
        </tbody>
      ))}
    </table>
  )
}
