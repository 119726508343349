import { useCallback, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { useAppDispatch, useAppSelector } from 'store/store.utils'
import { getMyAdvertsRequest, resetMyAdverts, GET_MY_ADVERTS } from 'store/adverts'
import { createLoadingSelector } from 'store'
import { Pagination } from 'App/components/common'
import { useRefreshNotification } from 'App/hooks'
import { generateRandomArray } from 'utils'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'

import { AdvertsEmptyList } from '../../common'
import { MyAdvertsItem } from '../MyAdvertsItem'
import { TAdvertsLocationState } from '../../Connector/ConnectorItemRequest'

import styles from './MyAdvertsList.module.scss'

const loadingSelector = createLoadingSelector([GET_MY_ADVERTS])
const notifications = new Set([ProducedNotificationsEnum.MY_ADVERTS_UPDATED])

export const MyAdvertsList = () => {
  const dispatch = useAppDispatch()
  const { replace } = useHistory()

  const { ids, total } = useAppSelector((state) => state.adverts.myAdverts)
  const loading = useAppSelector(loadingSelector)
  const { current, locations, professions, purposes, showBy, specializations, statuses } =
    useAppSelector((state) => state.adverts.filters)
  const search = useAppSelector((state) => state.adverts.search)

  const location = useLocation<TAdvertsLocationState>()
  const advertId = useRef(location.state?.advertId)

  const advertsIds = loading ? generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH) : ids

  useRefreshNotification({
    notifications,
    message: 'You have new event(s) in the Advert(s).',
    onRefresh: () => dispatch(getMyAdvertsRequest()),
    notificationsFilter: (payload: { type: string; advertId?: string }) => !!payload.advertId
  })

  useEffect(() => {
    if (advertId.current) {
      replace({
        state: {}
      })
      dispatch(getMyAdvertsRequest({ advertId: advertId.current }))
    }
  }, [dispatch, replace])

  useEffect(() => {
    if (!advertId.current) {
      dispatch(getMyAdvertsRequest())
    }
    // Filters are required to trigger list update
  }, [dispatch, locations, professions, purposes, specializations, statuses, search])

  useEffect(
    () => () => {
      dispatch(resetMyAdverts())
    },
    [dispatch]
  )

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(getMyAdvertsRequest({ current: page, showBy }))
    },
    [dispatch, showBy]
  )

  if (!loading && !advertsIds.length) {
    return (
      <AdvertsEmptyList
        search={search}
        filters={{ locations, professions, purposes, specializations, statuses }}
      />
    )
  }

  return (
    <>
      <div>
        {advertsIds.map((id) => (
          <MyAdvertsItem key={id} id={id} anchor={id === advertId.current} loading={loading} />
        ))}
      </div>

      <Pagination
        total={total}
        current={current}
        pageSize={showBy}
        wrapperClassName={styles.pagination}
        onChange={handlePaginationChange}
      />
    </>
  )
}
