import { Avatar } from 'App/components/common'
import { useAppSelector } from 'store'
import { EAvatarSize } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'

import styles from './GroupChatInfo.module.scss'

export const GroupChatInfo = () => {
  const { isDesktop } = useAdaptiveLayout()
  const { name, id, type, mutedUntil } =
    useAppSelector((state) => state.chat.chatRooms.selectedChat) || {}

  if (!name || !id || !type) {
    return null
  }

  return (
    <div className={styles.root}>
      <Avatar
        userId={id}
        displayUserName={name}
        size={EAvatarSize.LG}
        firstName=""
        lastName=""
        chatType={type}
      />
      <div className={styles.title}>
        <span className={styles.name}>{name}</span>
        {!isDesktop && mutedUntil && <ChatMutedIcon size="md" />}
      </div>
    </div>
  )
}
