import { useHistory, useParams } from 'react-router-dom'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { EIconSize } from 'enums'
import { OrganizationContentHeader } from 'App/components/Organizations'
import { IconButton } from 'App/components/common'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg'
import { ReactComponent as GearSettingFillIcon } from 'assets/icons/GearSettingFill.svg'
import { isMatchErrorCode } from 'utils'

import { getDepartment, handleError } from '../../../../../api'
import { EditDepartment } from '../../../../containers/Organization/EditDepartment'

import styles from './EditDepartmentPage.module.scss'

export type TEditDepartmentPageProps = {
  organizationId: string
}

export const EditDepartmentPage = ({ organizationId }: TEditDepartmentPageProps) => {
  const { push, replace } = useHistory()
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: ['get-department'],
    queryFn: () => getDepartment({ organizationId, departmentId: id }),
    onError: (error) => {
      handleError(error)
      if (isMatchErrorCode(error, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        replace('/organization/administration')
      }
    }
  })

  const handleBack = () => {
    push('/organization/administration')
  }

  return (
    <>
      <OrganizationContentHeader
        organizationId={organizationId}
        icon={<GearSettingFillIcon />}
        shouldShowBack={false}
        title={t('organizations.editDepartment.title')}
        shouldShowCreateDepartment={false}
        customAction={
          <IconButton
            onClick={handleBack}
            iconSize={EIconSize.MD}
            iconComponent={<ArrowLeftIcon />}
            classes={{ root: styles.editBackBtn }}
          />
        }
      />

      <EditDepartment data={data} loading={isFetching} />
    </>
  )
}
