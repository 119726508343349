import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { setFileDetailsId } from 'store/files/myFiles'
import { FileInformationPanelDrawer } from 'App/components'

const mapStateToProps = (state: State) => {
  const recordId = state.file.recordId ?? ''

  const {
    fileName,
    extension,
    id,
    createdAt,
    updatedAt,
    fileSize,
    owner,
    casesPermissions,
    sharedAt,
    p2pPermissions
  } = state.file.fileList[recordId] || state.fileShared.fileList[recordId] || {}
  const { id: accountId = '' } = state.global.accountData || {}

  return {
    id,
    accountId,
    extension,
    fileName,
    createdAt,
    updatedAt,
    fileSize,
    owner,
    casesPermissions,
    sharedAt,
    p2pPermissions
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onCloseFileInfoPanel: setFileDetailsId
    },
    dispatch
  )

export const FileInformationPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileInformationPanelDrawer)
