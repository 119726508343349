import { Checkbox, TCaseAttachFilesItemBaseProps } from 'App/components'
import { FileUploadWidgetItem, TFileAttachUploadFilesItemContainer } from 'App/containers'

import styles from './FileAttachUploadFileItem.module.scss'

export type FileAttachFilesItemProps = TFileAttachUploadFilesItemContainer &
  TCaseAttachFilesItemBaseProps

export const FileAttachUploadFileItem = ({
  id,
  selected,
  selectAttachFile
}: FileAttachFilesItemProps) => {
  const onChange = () => {
    selectAttachFile({ fileId: id })
  }

  return (
    <Checkbox
      checked={selected}
      classes={{ root: styles.checkbox }}
      label={<FileUploadWidgetItem id={id} classes={{ root: styles.root }} />}
      onChange={onChange}
    />
  )
}
