import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'App/components'
import { createProcessingSelector, ignoreRequestRequest, IGNORE_REQUEST } from 'store'

export type TIgnoreRequestDialogProps = {
  requestId: string
  advertId: string
}

const processingRequest = createProcessingSelector([IGNORE_REQUEST])

export const IgnoreRequestDialog = (props: TIgnoreRequestDialogProps) => {
  const dispatch = useDispatch()
  const processing = useSelector(processingRequest)

  const handleClick = () => {
    dispatch(ignoreRequestRequest(props))
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Row justify="center">
          <span>Are you sure you want to ignore this request?</span>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Button loading={processing} onClick={handleClick}>
            Ignore
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
