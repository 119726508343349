import { actionCreator } from 'store/store.utils'
import { TError } from 'store/store.types'

import {
  TGetCaseDescriptionRequestPayload,
  TGetCaseDescriptionSuccessPayload,
  TAddCaseDescriptionRequestPayload,
  TAddCaseDescriptionSuccessPayload,
  TGetCaseSummaryRequestPayload,
  TGetCaseSummarySuccessPayload,
  TAddCaseSummaryRequestPayload,
  TAddCaseSummarySuccessPayload,
  TUpdateCaseSummaryRequestPayload,
  TUpdateCaseSummarySuccessPayload,
  TGetCaseOpinionsMembersRequestPayload,
  TGetCaseOpinionsMembersSuccessPayload,
  TGetCaseOpinionsByMemberRequestPayload,
  TGetCaseOpinionsByMemberSuccessPayload,
  TGetCaseOpinionsRequestPayload,
  TGetCaseOpinionsSuccessPayload,
  TAddCaseOpinionRequestPayload,
  TAddCaseOpinionSuccessPayload,
  TSetNewLineDescriptionActionPayload,
  TSetNewLineOpinionActionPayload,
  TSetNewOpinionActionPayload,
  TSetOpenedOpinionActionPayload,
  TUpdateCaseDraftRequestPayload,
  TGetCaseDraftRequestPayload,
  TGetCaseDraftSuccessPayload
} from './caseDetails.types'
import * as actionTypes from './caseDetails.actionTypes'

// ------------------ CASE_DETAILS ------------------
export const caseDetailsReset = () => actionCreator(actionTypes.CASE_DETAILS_RESET)

// ------------------ CASE_DESCRIPTION ------------------
export const getCaseDescriptionRequest = (payload: TGetCaseDescriptionRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_DESCRIPTION_REQUEST, payload)
export const getCaseDescriptionSuccess = (payload: TGetCaseDescriptionSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_DESCRIPTION_SUCCESS, payload)
export const getCaseDescriptionError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_DESCRIPTION_ERROR, payload)

export const addCaseDescriptionRequest = (payload: TAddCaseDescriptionRequestPayload) =>
  actionCreator(actionTypes.ADD_CASE_DESCRIPTION_REQUEST, payload)
export const addCaseDescriptionSuccess = (payload: TAddCaseDescriptionSuccessPayload) =>
  actionCreator(actionTypes.ADD_CASE_DESCRIPTION_SUCCESS, payload)
export const addCaseDescriptionError = (payload: TError) =>
  actionCreator(actionTypes.ADD_CASE_DESCRIPTION_ERROR, payload)

// ------------------ CASE_SUMMARY ------------------
export const getCaseSummaryRequest = (payload: TGetCaseSummaryRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_SUMMARY_REQUEST, payload)
export const getCaseSummarySuccess = (payload: TGetCaseSummarySuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_SUMMARY_SUCCESS, payload)
export const getCaseSummaryError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_SUMMARY_ERROR, payload)

export const addCaseSummaryRequest = (payload: TAddCaseSummaryRequestPayload) =>
  actionCreator(actionTypes.ADD_CASE_SUMMARY_REQUEST, payload)
export const addCaseSummarySuccess = (payload: TAddCaseSummarySuccessPayload) =>
  actionCreator(actionTypes.ADD_CASE_SUMMARY_SUCCESS, payload)
export const addCaseSummaryError = (payload: TError) =>
  actionCreator(actionTypes.ADD_CASE_SUMMARY_ERROR, payload)

export const updateCaseSummaryRequest = (payload: TUpdateCaseSummaryRequestPayload) =>
  actionCreator(actionTypes.UPDATE_CASE_SUMMARY_REQUEST, payload)
export const updateCaseSummarySuccess = (payload: TUpdateCaseSummarySuccessPayload) =>
  actionCreator(actionTypes.UPDATE_CASE_SUMMARY_SUCCESS, payload)
export const updateCaseSummaryError = (payload: TError) =>
  actionCreator(actionTypes.UPDATE_CASE_SUMMARY_ERROR, payload)

// ------------------ CASE_OPINIONS ------------------
export const getCaseOpinionsMembersRequest = (payload: TGetCaseOpinionsMembersRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_MEMBERS_REQUEST, payload)
export const getCaseOpinionsMembersSuccess = (payload: TGetCaseOpinionsMembersSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_MEMBERS_SUCCESS, payload)
export const getCaseOpinionsMembersError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_MEMBERS_ERROR, payload)

export const getCaseOpinionsByMemberRequest = (payload: TGetCaseOpinionsByMemberRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_BY_MEMBER_REQUEST, payload)
export const getCaseOpinionsByMemberSuccess = (payload: TGetCaseOpinionsByMemberSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_BY_MEMBER_SUCCESS, payload)
export const getCaseOpinionsByMemberError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_BY_MEMBER_ERROR, payload)

export const getCaseOpinionsRequest = (payload: TGetCaseOpinionsRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_REQUEST, payload)
export const getCaseOpinionsSuccess = (payload: TGetCaseOpinionsSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_SUCCESS, payload)
export const getCaseOpinionsError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_OPINIONS_ERROR, payload)

export const addCaseOpinionRequest = (payload: TAddCaseOpinionRequestPayload) =>
  actionCreator(actionTypes.ADD_CASE_OPINION_REQUEST, payload)
export const addCaseOpinionSuccess = (payload: TAddCaseOpinionSuccessPayload) =>
  actionCreator(actionTypes.ADD_CASE_OPINION_SUCCESS, payload)
export const addCaseOpinionError = (payload: TError) =>
  actionCreator(actionTypes.ADD_CASE_OPINION_ERROR, payload)

export const setNewLineDescriptionAction = (payload: TSetNewLineDescriptionActionPayload) =>
  actionCreator(actionTypes.SET_NEW_LINE_DESCRIPTION, payload)
export const setNewLineOpinionAction = (payload: TSetNewLineOpinionActionPayload) =>
  actionCreator(actionTypes.SET_NEW_LINE_OPINION, payload)

export const setNewOpinionAction = (payload: TSetNewOpinionActionPayload) =>
  actionCreator(actionTypes.SET_NEW_OPINION, payload)

export const setOpenedOpinionAction = (payload: TSetOpenedOpinionActionPayload) =>
  actionCreator(actionTypes.SET_OPENED_OPINION, payload)

// ------------------ CASE_DRAFT ------------------
export const updateCaseDraftRequest = (payload: TUpdateCaseDraftRequestPayload) =>
  actionCreator(actionTypes.UPDATE_CASE_DRAFT_REQUEST, payload)
export const updateCaseDraftSuccess = (payload: TGetCaseDraftSuccessPayload) =>
  actionCreator(actionTypes.UPDATE_CASE_DRAFT_SUCCESS, payload)
export const updateCaseDraftError = (payload: TError) =>
  actionCreator(actionTypes.UPDATE_CASE_DRAFT_ERROR, payload)

export const getCaseDraftRequest = (payload: TGetCaseDraftRequestPayload) =>
  actionCreator(actionTypes.GET_CASE_DRAFT_REQUEST, payload)
export const getCaseDraftSuccess = (payload: TGetCaseDraftSuccessPayload) =>
  actionCreator(actionTypes.GET_CASE_DRAFT_SUCCESS, payload)
export const getCaseDraftError = (payload: TError) =>
  actionCreator(actionTypes.GET_CASE_DRAFT_ERROR, payload)
export const getCaseDraftCancel = () => actionCreator(actionTypes.GET_CASE_DRAFT_CANCEL)
