import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { TCompanySpecialization } from 'store'
import { COMPANY_SIZE_TYPE, MAX_LENGTH_50, SUPPORT_EMAIL } from 'globalConstants'
import { validation } from 'utils'
import { Help, Select, TextField } from 'App/components'

import styles from '../BusinessAccountCreateDialog.module.scss'

type TCompanyDetailsProps = {
  companySpecializations: TCompanySpecialization[]
  actions: ReactNode
  loadingSpecializations: boolean
  email: string
}

export const CompanyDetails = ({
  companySpecializations,
  actions,
  loadingSpecializations,
  email
}: TCompanyDetailsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.emailDescription}>
        <span className={styles.emailDescriptionTitle}>Email</span>
        <span className={styles.emailDescriptionValue}>{email}</span>
      </div>

      <Field
        name="companyName"
        validate={validation.composeValidators(
          validation.required(),
          validation.companyName(),
          validation.maxLength(MAX_LENGTH_50)
        )}
      >
        {({ input, meta }) => (
          <TextField
            {...input}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            classes={{ wrapper: styles.input }}
            topLabel={
              <span>
                {t('modal.createBusinessAccount.companyDetailsStep.companyName')}{' '}
                <Help
                  content={t('modal.createBusinessAccount.companyDetailsStep.companyNameHelp', {
                    email: SUPPORT_EMAIL
                  })}
                />
              </span>
            }
            valueLengthMax={MAX_LENGTH_50}
          />
        )}
      </Field>
      <Field name="size" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            options={COMPANY_SIZE_TYPE}
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            wrapperClassName={styles.input}
            topLabel={t('modal.createBusinessAccount.companyDetailsStep.companySize')}
          />
        )}
      </Field>
      <Field name="companyType" validate={validation.required()}>
        {({ input, meta }) => (
          <Select
            {...input}
            options={companySpecializations}
            valueKey="id"
            labelKey="name"
            invalid={meta.touched && meta.invalid}
            error={meta.error}
            wrapperClassName={styles.input}
            topLabel={t('modal.createBusinessAccount.companyDetailsStep.companyType')}
            isLoading={loadingSpecializations}
          />
        )}
      </Field>

      {actions}
    </>
  )
}
