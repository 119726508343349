import { useEffect, useRef, useCallback } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ProducedNotificationsEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import { routes } from 'App/App.config'
import { toast, ToastId } from 'App/components/ToastContainer'
import { State } from 'redux/rootReducer'
import { toastDefaultOptions } from 'globalConstants'
import { startNotificationTracking, stopNotificationTracking } from 'store'

export type TRefreshNotificationsOptions = {
  onRefresh: () => void
  message?: string
  notifications?: Set<ProducedNotificationsEnum>
  notificationsFilter?: (payload: any) => boolean
}

const CHANNEL_NAME = 'refresh'

export const useRefreshNotification = ({
  message,
  notifications,
  onRefresh,
  notificationsFilter
}: TRefreshNotificationsOptions) => {
  const {
    location: { pathname }
  } = useHistory()

  const onRefreshRef = useRef(onRefresh)
  const toastIdRef = useRef<ToastId>()

  const isChanged = useSelector(
    (state: State) => state.trackedNotifications[CHANNEL_NAME]?.isChanged ?? null
  )

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const removeToast = useCallback(() => {
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current)
      toastIdRef.current = undefined
    }
  }, [])

  useEffect(() => {
    onRefreshRef.current = onRefresh
  }, [onRefresh])

  useEffect(
    () => () => {
      removeToast()
    },
    [removeToast]
  )
  useEffect(() => {
    if (notifications) {
      dispatch(
        startNotificationTracking({
          channel: CHANNEL_NAME,
          notifications,
          filter: notificationsFilter
        })
      )
    } else {
      const matchedRoute = routes.find((route) =>
        matchPath(pathname, (route.path || '') as string[])
      )

      if (matchedRoute && matchedRoute.trackNotifications) {
        dispatch(
          startNotificationTracking({
            channel: CHANNEL_NAME,
            notifications: matchedRoute.trackNotifications,
            filter: notificationsFilter
          })
        )
      }
    }

    return () => {
      dispatch(stopNotificationTracking({ channel: CHANNEL_NAME }))
    }
  }, [pathname, notifications, dispatch])

  useEffect(() => {
    if (!toastIdRef.current && isChanged) {
      toastIdRef.current = toast.info(
        <span>
          {message ?? t('common.newNotificationsToast.message')}.
          <Trans
            t={t}
            i18nKey="common.newNotificationsToast.refreshLink"
            components={[
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                key={0}
                href="/"
                className="link"
                onClick={(e) => {
                  e.preventDefault()
                  if (onRefreshRef.current) {
                    onRefreshRef.current()
                  }
                  removeToast()
                }}
              />
            ]}
          />
        </span>,
        { ...toastDefaultOptions, autoClose: false }
      )
    }
  }, [isChanged, message, removeToast, t])

  return {
    removeToast
  }
}
