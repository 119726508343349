import { ReactNode } from 'react'
import cls from 'classnames'

import { formatLongDateTime } from 'utils/formats'

import styles from './CaseDetailsDate.module.scss'

export type TCaseDetailsDateProps = {
  date?: string | Date
  className?: string
  getLabel?: (formattedDate?: string) => ReactNode
}

export const CaseDetailsDate = ({ date, className, getLabel }: TCaseDetailsDateProps) => {
  const formattedDate = date && formatLongDateTime(date)
  const label = (getLabel && getLabel(formattedDate)) ?? formattedDate

  return <p className={cls(styles.root, className)}>{label}</p>
}
