import flatten from 'lodash/flatten'

import { InfiniteData } from 'services/query'
import { TComment } from 'App/components'
import { IShowcaseComment, IShowcaseReply } from 'interfaces'

type TFormatterOptions = { isAllComments?: boolean; isReply?: boolean }

const getText = ({
  message,
  showcaseFile,
  quoteComment,
  isAllComments = false,
  isReply = false
}: TFormatterOptions & Pick<IShowcaseComment, 'message' | 'showcaseFile' | 'quoteComment'>) => {
  const messageText = isReply ? `<@${quoteComment?.owner.id}> ${message}` : message

  if (isAllComments) {
    return `</${showcaseFile.file.id}> ${messageText}`
  }

  return messageText
}

export const commentsFormatter = (
  data: IShowcaseComment[] | IShowcaseReply[],
  { isAllComments = false, isReply = false }: TFormatterOptions
): TComment[] =>
  data.map(
    ({
      createdAt,
      updatedAt,
      message,
      id,
      owner,
      showcaseFile,
      showcase: {
        id: showcaseId,
        files,
        owner: { id: ownerId }
      },
      quoteComment,
      repliesCount,
      mentions = []
    }) => ({
      id,
      repliesCount,
      createdAt,
      updatedAt,
      text: getText({
        message,
        quoteComment,
        showcaseFile,
        isAllComments,
        isReply
      }),
      mentions: {
        accounts: isReply && quoteComment?.owner ? [quoteComment?.owner, ...mentions] : mentions,
        files,
        showcaseOptions: {
          showcaseId,
          ownerId
        },
        clickable: true
      },
      author: {
        ...owner,
        type: owner.type.name,
        userId: owner.id
      }
    })
  )

export const getSelectFormatter =
  (options: Partial<TFormatterOptions> | undefined = {}) =>
  (data: InfiniteData<IShowcaseComment[] | IShowcaseReply[]>): InfiniteData<TComment> => ({
    pages: commentsFormatter(flatten(data?.pages ?? []), options),
    pageParams: data.pageParams
  })
