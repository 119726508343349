import { useCallback, useEffect, useMemo, useState } from 'react'
import { ContactStatusEnum, PlatformInvitationType } from '@medentee/enums'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useMutation } from 'services/query'
import {
  TAccountData,
  TContactsData,
  accountRoleNameSelector,
  isAdminAccountSelector,
  useAppSelector,
  accountIdSelector,
  removeContactInviteRequest,
  useAppDispatch
} from 'store'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { ReactComponent as AccountPlusOutlineIcon } from 'assets/icons/AccountPlusOutline.svg'
import { ReactComponent as MessageTextOutlineIcon } from 'assets/icons/MessageTextOutline.svg'
import { APIResultsResponse } from 'services/api'
import { EInvitationAction, toastDefaultOptions } from 'globalConstants'

import { ChatMembersListTab } from '../../Chat/ChatMembersList'
import { ChatRoomInfoUserItem } from '../../Chat/ChatMembers/CaseChatMembers/components/ChatRoomInfoUserItem'
import { Alert } from '../..'
import commonStyles from '../../../containers/Organizations/ChannelInfo/ChannelInfo.module.scss'
import { useChatRedirect } from '../../Organizations/hooks'
import { sendInviteUsersByEmail } from '../../../../api/invitations'
import { useAccumulate, useLoadingIds, useRefValue } from '../../../hooks'
import { PAGINATION_DEFAULT_SHOW_BY } from '../../../../types'
import { KebabContactStatus } from '../../common/KebabContactStatus'
import {
  isHideInvitationReply,
  isSender
} from '../../../containers/Organization/useOrganizationMemberKebabMenu'

import styles from './UnityHubChannelMembers.module.scss'

type TUnityHubChannelMember = {
  account: TAccountData
  online: boolean

  chatId?: string
  contact?: TContactsData
}

type TUnityHubChannelMembersProps = {
  channelId: string
  page: number
  loading: boolean
  setTabMembersTotal: (total: number) => void
  setPage: (total: number) => void
  loadData: () => void

  data?: APIResultsResponse<TUnityHubChannelMember[]>['data']
  limit?: number
  alert?: string
}

export const UnityHubChannelMembers = ({
  limit = PAGINATION_DEFAULT_SHOW_BY,
  channelId,
  alert,
  page,
  data,
  loading,
  setTabMembersTotal,
  setPage,
  loadData
}: TUnityHubChannelMembersProps) => {
  const dispatch = useAppDispatch()
  const [total, setTotal] = useState(0)

  const { getValue: getCurrentPage } = useRefValue(page)

  const { t } = useTranslation()

  const accountRole = useAppSelector(accountRoleNameSelector)
  const chatRoom = useAppSelector((state) => state.chat.chatRooms.list[channelId])
  const { id: accountId, professionalAccount } =
    useAppSelector((state) => state.global.accountData) || {}
  const isAdmin = useAppSelector(isAdminAccountSelector)
  const currentAccountId = useAppSelector(accountIdSelector)

  const { redirectToChat } = useChatRedirect()

  const items = useAccumulate({ items: data?.results, page })

  const itemIds = useMemo(() => items.map(({ account }) => account.id), [items])
  const ids = useLoadingIds({ ids: itemIds, loading, limit })

  const { mutate } = useMutation({
    mutationKey: ['invite-to-contacts'],
    mutationFn: sendInviteUsersByEmail
  })

  const refreshList = useCallback(
    (memberPage: number) => {
      if (memberPage !== getCurrentPage()) {
        setPage(memberPage)
      } else {
        loadData()
      }
    },
    [getCurrentPage, loadData, setPage]
  )

  const shouldShowAlert = !isAdmin

  const menuItems = useMemo(
    () => ({
      withdraw: ({ contact }: TUnityHubChannelMember, memberPage: number) => ({
        content: t('unityHub.channelMembers.kebabMenu.withdraw'),
        hidden:
          !!accountRole ||
          isHideInvitationReply(contact, contact?.status) ||
          !isSender(accountId, contact?.fromId),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => {
          contact?.id &&
            dispatch(
              removeContactInviteRequest({
                type: EInvitationAction.WITHDRAW,
                contactId: contact.id,
                processingId: contact.id,
                onSuccess: () => refreshList(memberPage)
              })
            )
        }
      }),
      invite: (member: TUnityHubChannelMember, memberPage: number) => ({
        content: t('unityHub.channelMembers.kebabMenu.inviteToContact'),
        hidden: !!member.contact?.status && member.contact.status !== ContactStatusEnum.REJECTED,
        icon: <AccountPlusOutlineIcon />,
        onClick: () => {
          mutate(
            { emails: [member.account.email], type: PlatformInvitationType.CONTACT },
            {
              onSuccess: () => {
                refreshList(memberPage)
                toast.success(t('common.toast.invitationSent'), toastDefaultOptions)
              }
            }
          )
        }
      }),
      chat: ({ account: { id }, chatId }: TUnityHubChannelMember) => ({
        content: t('unityHub.channelMembers.kebabMenu.chat'),
        icon: <MessageTextOutlineIcon />,
        onClick: () => redirectToChat({ chatId, partnerAccountId: id })
      })
    }),

    [accountRole, accountId, dispatch, refreshList, t, mutate, redirectToChat]
  )

  const getMenuItems = useCallback(
    (member: TUnityHubChannelMember, memberPage: number) => {
      const shouldShowKebab = !!accountRole
        ? professionalAccount?.id !== member.account.id
        : accountId !== member.account.id

      return shouldShowKebab
        ? [
            menuItems.withdraw(member, memberPage),
            menuItems.invite(member, memberPage),
            menuItems.chat(member)
          ]
        : []
    },
    [accountId, accountRole, menuItems, professionalAccount?.id]
  )

  useEffect(() => {
    setTotal(data?.total ?? 0)
    setTabMembersTotal(data?.total ?? 0)
  }, [data, setTabMembersTotal])

  return (
    <ChatMembersListTab
      limit={limit}
      onChangePage={setPage}
      loading={loading}
      total={total}
      page={page}
      className={styles.root}
      dataLength={data?.results.length ?? 0}
      header={
        shouldShowAlert && (
          <Alert variant="info" size="sm" className={commonStyles.alert}>
            {alert}
          </Alert>
        )
      }
    >
      {ids.map((id, index) => {
        const memberPage = Math.floor(index / limit)
        const item = items.find(({ account }) => account.id === id)

        return (
          <Skeleton
            title={false}
            active={true}
            loading={!itemIds.includes(id) && loading}
            className={styles.skeleton}
            paragraph={{ rows: 1, width: '100%' }}
            key={id}
          >
            <ChatRoomInfoUserItem
              member={item?.account}
              isOnline={item?.online}
              contact={item?.contact}
              classes={{ root: commonStyles.member }}
              chipList={[
                {
                  hidden: item?.account.id !== chatRoom.channelManager?.id,
                  color: 'success',
                  variant: 'square',
                  text: t('unityHub.channelMembers.channelManagerChip')
                }
              ]}
              hideAccountType
              isContact={isAdmin ? undefined : item?.contact?.status === ContactStatusEnum.APPROVED}
              menuItems={item && getMenuItems(item, memberPage)}
              endAdornment={
                <KebabContactStatus
                  accountId={currentAccountId}
                  fromId={item?.contact?.fromId}
                  status={item?.contact?.status}
                />
              }
            />
          </Skeleton>
        )
      })}
    </ChatMembersListTab>
  )
}
