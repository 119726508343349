import { ReactNode } from 'react'
import cls from 'classnames'

import styles from './AccountNameChip.module.scss'

export type TAccountNameChipProps = {
  color: 'primary' | 'primary-dark' | 'success'
  text: ReactNode

  variant?: 'square' | 'round'
  hidden?: boolean
  icon?: ReactNode
}

export const AccountNameChip = ({
  color,
  icon,
  text,
  variant = 'round'
}: TAccountNameChipProps) => (
  <div className={cls(styles.root, styles[color], styles[variant])}>
    {icon}
    {text}
  </div>
)
