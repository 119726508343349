import { Trans, useTranslation } from 'react-i18next'

import { ReactComponent as StarIcon } from 'assets/icons/StarFill.svg'
import { IDepartment } from 'interfaces'

import { Chip } from '../../common'

import styles from './DisplayDepartments.module.scss'

export type TDisplayDepartmentsProps = {
  items: IDepartment[]
  staffId: string
}

export const DisplayDepartments = ({ items, staffId }: TDisplayDepartmentsProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {items.map(({ name, id, leader }) => (
        <Chip
          key={id}
          text={
            <>
              {name}
              {leader?.id === staffId && (
                <span className={styles.lead}>
                  <Trans
                    t={t}
                    i18nKey="organizations.staff.departmentLeadLabel"
                    components={{
                      icon: <StarIcon />
                    }}
                  />
                </span>
              )}
            </>
          }
          variant="light"
          className={styles.chip}
        />
      ))}
    </div>
  )
}
