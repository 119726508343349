import { useEffect } from 'react'
import { useFormState } from 'react-final-form'

export type TSpyOnFormChangedProps = {
  prompt: JSX.Element
  onChange: (value: boolean) => void
}

export const SpyOnFormChanged = ({ onChange, prompt }: TSpyOnFormChangedProps) => {
  const { dirty, valid, submitSucceeded, dirtySinceLastSubmit } = useFormState()

  useEffect(() => {
    onChange(
      !(!valid || (!submitSucceeded && !dirty) || (submitSucceeded && !dirtySinceLastSubmit))
    )
  }, [dirty, valid, submitSucceeded, dirtySinceLastSubmit, onChange])

  return prompt
}
