import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EEmptyListIconSize, EmptyList } from 'App/components'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'

export type TCaseAutoApproveDialogEmptyListProps = {
  search?: string
  categoryId?: string
}

export const CaseAutoApproveDialogEmptyList = ({
  search,
  categoryId
}: TCaseAutoApproveDialogEmptyListProps) => {
  const { t } = useTranslation()

  const placeholder = useMemo(() => {
    if (categoryId) {
      return t('modal.caseAutoApprove.placeholder_category')
    }

    return t('modal.caseAutoApprove.placeholder')
  }, [categoryId, t])

  return (
    <EmptyList
      icon={!!search || !!categoryId ? <AccountMultipleIcon /> : <AccountMultiplePlusIcon />}
      text={placeholder}
      iconSize={EEmptyListIconSize.XS}
      hasAppliedSearch={!!search}
    />
  )
}
