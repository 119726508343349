import { useState, useCallback } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import difference from 'lodash/difference'

import { TIds } from 'store'
import { useAdaptiveLayout } from 'App/hooks'

export type TUseSelection = {
  selectMode: boolean
  selectedIds: TIds
  setSelectMode: (mode: boolean) => void
  setSelectedIds: (ids: TIds, id?: string) => void
  onSelect: (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.TouchEvent<HTMLElement>
      | CheckboxChangeEvent,
    id: string
  ) => TIds
}

export const useSelection = (ids: TIds): TUseSelection => {
  const [selectMode, setSelectMode] = useState(false)
  const [selectedIds, setSelectedIds] = useState<TIds>([])
  const [lastSelectedId, setLastSelectedId] = useState<string | null>(null)
  const { isDesktop } = useAdaptiveLayout()

  const onSetSelectedIds = useCallback(
    (nextIds: TIds, id?: string) => {
      if (id) {
        setLastSelectedId(id)
      }

      setSelectedIds(nextIds)

      if (!isDesktop) {
        setSelectMode(!!nextIds.length)
      }
    },
    [isDesktop]
  )

  const onSelect = useCallback(
    (
      event:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.TouchEvent<HTMLElement>
        | CheckboxChangeEvent,
      id: string
    ) => {
      const shiftKey = event?.hasOwnProperty('shiftKey')
        ? event.shiftKey
        : event.nativeEvent.shiftKey
      const checked = selectedIds.includes(id)

      if (shiftKey) {
        const start = ids.indexOf(lastSelectedId ?? id)
        const end = ids.indexOf(id)

        const slicedIds = ids.slice(Math.min(start, end), Math.max(start, end) + 1)

        const mergedIds = checked
          ? difference(selectedIds, slicedIds)
          : Array.from(new Set([...slicedIds, ...selectedIds]))

        onSetSelectedIds(mergedIds, id)

        return mergedIds
      }

      const mergedIds = checked
        ? selectedIds.filter((selectedId) => selectedId !== id)
        : [...selectedIds, id]

      onSetSelectedIds(mergedIds, id)

      return mergedIds
    },
    [selectedIds, onSetSelectedIds, ids, lastSelectedId]
  )

  return {
    selectMode,
    selectedIds,
    setSelectMode,
    setSelectedIds: onSetSelectedIds,
    onSelect
  }
}
