import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { EInputSize } from 'enums'
import { accountIdSelector, setModalTitleAction, useAppDispatch, useAppSelector } from 'store'
import { AccountsSelect, SearchCascader, TStepsFooterActionsProps } from 'App/components'
import { useSelectAccounts } from 'App/hooks'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { getContacts } from 'api/contacts'
import { useAllContactsSearchByOptions } from 'App/hooks/SearchCascader/useAllContactsSearchByOptions'

export type TScheduleMeetingModalSelectContactStepProps = Pick<
  TStepsFooterActionsProps,
  'onClickBack'
> & {
  selectedAccount: TAccountsSelectOptionData | null
  onSubmit: (value: TAccountsSelectOptionData) => void
}

const MAX_SELECTED = 1

export const ScheduleMeetingModalSelectContactStep = ({
  selectedAccount,
  onSubmit,
  onClickBack
}: TScheduleMeetingModalSelectContactStepProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)

  const handleSend = useCallback(
    (values: TAccountsSelectOptionData[]) => {
      const value = values[0]

      if (!value) {
        return
      }

      dispatch(setModalTitleAction({ modalTitle: t('modal.scheduleMeeting.title') }))

      onSubmit(value)
    },
    [dispatch, onSubmit, t]
  )

  const {
    state: { page, search, selectedAccounts, searchBy },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll,
    onSearchBy
  } = useSelectAccounts({
    onSubmit: handleSend,
    accounts: selectedAccount ? [selectedAccount] : []
  })

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['schedule-meeting-contacts', page, search, searchBy],
    queryFn: () =>
      getContacts({
        page,
        search,
        searchBy,
        accountId
      }),
    keepPreviousData: true
  })

  const submitDisabled = isLoading || isFetching || !selectedAccounts.length

  const { onCascaderChange, options, onLoadDepartments } = useAllContactsSearchByOptions({
    onChange: onSearchBy
  })

  return (
    <AccountsSelect
      CustomSearchComponent={
        <SearchCascader
          searchSize={EInputSize.L}
          options={options}
          triggerInitialOnChange={true}
          loadData={onLoadDepartments}
          onSearchChange={onSearch}
          onCascaderChange={onCascaderChange}
        />
      }
      items={data?.results ?? []}
      selectedItems={selectedAccounts}
      search={search}
      page={page}
      total={data?.total}
      hideClearAll={true}
      autoOnline={true}
      maxSelected={MAX_SELECTED}
      loading={isLoading}
      submitDisabled={submitDisabled}
      emptyContentText={t('modal.scheduleMeeting.selectContactStep.placeholder')}
      emptyListText={t('modal.scheduleMeeting.selectContactStep.placeholder')}
      submitLabel={t('modal.scheduleMeeting.selectContactStep.submitButton')}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
      onClickBack={onClickBack}
    />
  )
}
