import { Skeleton } from 'antd'
import { PlatformInvitationType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ContextMenu, TMenuItemProps } from 'App/components'
import { ReactComponent as GearSettingIcon } from 'assets/icons/GearSettingFill.svg'

import { InvitePopover, TInvitePopoverProps } from '../../../../../containers/InvitePopover'

import styles from './ListHeader.module.scss'

type THeaderProps = {
  total: number
  loading: boolean
  getMenuItems: () => TMenuItemProps[]
  invitePopoverProps: Pick<TInvitePopoverProps, 'visible' | 'onVisibleChange'>
}

export const ListHeader = ({ total, loading, getMenuItems, invitePopoverProps }: THeaderProps) => {
  const { t } = useTranslation()

  return (
    <div>
      {loading ? (
        <Skeleton.Input active={true} className={styles.skeleton} />
      ) : (
        <div className={styles.header}>
          <span className={styles.title}>
            {t('organizations.infoDrawer.staffList.title', { total })}
          </span>

          <InvitePopover
            {...invitePopoverProps}
            align={{ offset: [0, -24] }}
            invitationType={PlatformInvitationType.STAFF}
          >
            <ContextMenu
              menuItems={getMenuItems()}
              iconComponent={<GearSettingIcon className={styles.contextIcon} />}
            />
          </InvitePopover>
        </div>
      )}
    </div>
  )
}
