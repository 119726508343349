import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { CaseOpinionsMembersList } from 'App/components'
import {
  clearNotificationAction,
  createLoadingSelector,
  getCaseOpinionsMembersRequest,
  GET_CASE_OPINIONS_MEMBERS,
  setOpenedOpinionAction
} from 'store'
import { State } from 'redux/rootReducer'

export type TCaseOpinionsMembersContainerProps = {
  caseId: string

  withoutWrapper?: boolean
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseOpinionsMembersContainerView = ({
  caseId,
  ids,
  loading,
  withoutWrapper,
  caseType,
  openOpinionMemberId,
  getCaseOpinionsMembers,
  setOpenedOpinion,
  clearNotification
}: TCaseOpinionsMembersContainerProps) => {
  useEffect(() => {
    getCaseOpinionsMembers({ caseId })

    return () => {
      setOpenedOpinion({ memberId: null })
    }
  }, [getCaseOpinionsMembers, caseId, setOpenedOpinion])

  return (
    <CaseOpinionsMembersList
      withoutWrapper={withoutWrapper}
      caseType={caseType}
      ids={ids}
      loading={loading}
      openOpinionMemberId={openOpinionMemberId}
      setOpenedOpinion={setOpenedOpinion}
      clearNotification={clearNotification}
    />
  )
}

const loadingSelector = createLoadingSelector([GET_CASE_OPINIONS_MEMBERS])

const mapStateToProps = (state: State) => ({
  ids: state.caseDetails.opinions.opinionsMembersIds,
  caseType: state.caseView.data?.type,
  openOpinionMemberId: state.caseDetails.opinions.openOpinionMemberId,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseOpinionsMembers: getCaseOpinionsMembersRequest,
      setOpenedOpinion: setOpenedOpinionAction,
      clearNotification: clearNotificationAction
    },
    dispatch
  )

export const CaseOpinionsMembersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseOpinionsMembersContainerView)
