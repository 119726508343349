import { normalize, schema } from 'normalizr'

import { IFilesEntity } from 'interfaces'
import { TIds } from 'store'

import {
  TFileContact,
  TFileContactIds,
  TFileContactList,
  TFileHistoryListItem,
  TFileList,
  TFilesHistory,
  TFilesHistoryGroupedList,
  THistoryFile
} from './files.types'

const fileSchema = new schema.Entity('fileList')

const fileListSchema = new schema.Array(fileSchema)

export type TFileNormalized = {
  fileList: TFileList
  ids: TIds
}

type TFileNormalizeResult = {
  entities: {
    fileList: TFileList
  }
  result: TIds
}

export const fileNormalize = (originalData: IFilesEntity[]): TFileNormalized => {
  const normalizedData: TFileNormalizeResult = normalize(originalData, fileListSchema)

  return {
    fileList: normalizedData.entities.fileList || {},
    ids: normalizedData.result || []
  }
}

const fileContactSchema = new schema.Entity('fileContactList', {}, { idAttribute: 'contactUserId' })

const fileContactListSchema = new schema.Array(fileContactSchema)

export type TFileContactNormalized = {
  fileContactList: TFileContactList
  fileContactIds: TFileContactIds
}

type TFileContactNormalizeResult = {
  entities: {
    fileContactList: TFileContactList
  }
  result: TFileContactIds
}

export const fileContactNormalize = (originalData: TFileContact[]): TFileContactNormalized => {
  const originalDataClone = (originalData || []).map((data) => ({
    ...data,
    permissions: data.p2pPermissions
  }))
  const normalizedData: TFileContactNormalizeResult = normalize(
    originalDataClone,
    fileContactListSchema
  )

  return {
    fileContactList: normalizedData.entities.fileContactList || {},
    fileContactIds: normalizedData.result || []
  }
}

const fileHistorySchema = new schema.Array(new schema.Entity('list'))

export type TFileHistoryNormalized = {
  dateIds: string[]
  groupedList: TFilesHistoryGroupedList
}

type TFileHistoryNormalizeResult = {
  entities: {
    list: TFileHistoryListItem
  }
  result: string[]
}

export const fileHistoryNormalize = (originalData: THistoryFile[]): TFileHistoryNormalized => {
  const dateIds: string[] = []

  const formattedResults = originalData.reduce<Record<string, THistoryFile[]>>((acc, item) => {
    const dateKey = item.createdAt.split('T')[0]

    if (acc[dateKey]) {
      acc[dateKey].push(item)
    } else {
      acc[dateKey] = [item]
      dateIds.push(dateKey)
    }

    return acc
  }, {})

  const normalizedResult: Omit<TFilesHistory, 'filters' | 'historyItems'> = {
    dateIds,
    groupedList: {}
  }

  dateIds.forEach((item) => {
    const normalizedData: TFileHistoryNormalizeResult = normalize(
      formattedResults[item],
      fileHistorySchema
    )

    normalizedResult.groupedList[item] = {
      ids: normalizedData.result,
      list: normalizedData.entities.list
    }
  })

  return normalizedResult
}
