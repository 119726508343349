import { Col, Row } from 'antd'
import { Field, useFormState } from 'react-final-form'
import { EventRepresentativeTypeEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import { TEventRepresantatives } from 'interfaces/api/events'
import {
  UnityHubSettingsRadio,
  UnityHubSettingsRadioGroup
} from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsRadio'
import { UnityHubSettingsTitle } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsTitle'
import { UnityHubSettingsDescription } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsDescription'

import { validation } from '../../../../../utils'
import { TextFieldSkeleton } from '../../../../components'
import { TOrganizerSettingsFormValues } from '../OrganizerSettings'

export type TOrganizerProfileProps = {
  loading: boolean
  representatives: TEventRepresantatives
}

export const OrganizerProfile = ({ loading, representatives }: TOrganizerProfileProps) => {
  const { values } = useFormState<TOrganizerSettingsFormValues>()

  const { t } = useTranslation()

  return (
    <Col xs={24}>
      <Row gutter={[0, 12]}>
        <Col xs={24}>
          <UnityHubSettingsTitle title={t('events.organizerSettings.organizerProfileTitle')} />
        </Col>
        <Col xs={24}>
          <UnityHubSettingsDescription
            description={t('events.organizerSettings.representativesListTitle')}
          />
        </Col>
        <Col xs={24}>
          <TextFieldSkeleton loading={loading}>
            <Field<TOrganizerSettingsFormValues['representativeType']>
              name="representativeType"
              validate={validation.required()}
              type="radio"
            >
              {({ input: { name, onChange } }) => (
                <UnityHubSettingsRadioGroup
                  name={name}
                  value={values.representativeType}
                  onChange={onChange}
                >
                  <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.BUSINESS_ACCOUNT}>
                    <Trans
                      t={t}
                      i18nKey="events.organizerSettings.representative_business"
                      components={{
                        name: (
                          <strong>{representatives?.businessAccounts[0]?.displayUserName}</strong>
                        )
                      }}
                    />
                  </UnityHubSettingsRadio>
                  <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.ORGANIZATION}>
                    <Trans
                      t={t}
                      i18nKey="events.organizerSettings.representative_organization"
                      components={{
                        name: <strong>{representatives?.organizations[0]?.displayUserName}</strong>
                      }}
                    />
                  </UnityHubSettingsRadio>
                  {!!representatives?.communities.length && (
                    <UnityHubSettingsRadio value={EventRepresentativeTypeEnum.COMMUNITY}>
                      <Trans
                        t={t}
                        i18nKey="events.organizerSettings.representative_community"
                        components={{
                          name: <strong>{representatives?.communities[0]?.title}</strong>
                        }}
                      />
                    </UnityHubSettingsRadio>
                  )}
                </UnityHubSettingsRadioGroup>
              )}
            </Field>
          </TextFieldSkeleton>
        </Col>
      </Row>
    </Col>
  )
}
