import { memo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TUserProfileAdministrationOnlineContainerProps } from 'App/containers'

import styles from './UserProfileAdministrationOnline.module.scss'

const UserProfileAdministrationOnlineView = ({
  onlineAmount
}: TUserProfileAdministrationOnlineContainerProps) => {
  const { t } = useTranslation()

  if (!onlineAmount) {
    return null
  }

  return (
    <Link to="/organization/administration" className={styles.link}>
      <span className={styles.text}>
        {t('organizations.administration.onlineLabel', { number: onlineAmount })}
      </span>
    </Link>
  )
}

export const UserProfileAdministrationOnline = memo(UserProfileAdministrationOnlineView)
