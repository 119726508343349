import { components, SingleValueProps, GroupBase } from 'react-select'

import { TOption } from '../../Select'

import styles from './SingleValue.module.scss'

export const SingleValue = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: SingleValueProps<Option, IsMulti, Group>
) => (
  <components.SingleValue {...props} className={styles.root}>
    {props.selectProps.originalValue ? (props.data as TOption).value : props.children}
  </components.SingleValue>
)
