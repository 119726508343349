import { ReactNode } from 'react'

import styles from './UnityHubSettingsDescription.module.scss'

export type TUnityHubSettingsDescriptionProps = {
  description: ReactNode
}

export const UnityHubSettingsDescription = ({ description }: TUnityHubSettingsDescriptionProps) => (
  <p className={styles.root}>{description}</p>
)
