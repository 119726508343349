import { useMemo } from 'react'
import Col from 'antd/lib/grid/col'
import Row, { Gutter } from 'antd/lib/grid/row'
import { useTranslation } from 'react-i18next'

import { TIds } from 'store'
import { getMapComponent } from 'utils'
import { EmptyList, TFilesViewVariant } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as TextBoxIcon } from 'assets/icons/TextBox.svg'

import styles from './ChatFilesCategoryWrapper.module.scss'

export type TChatFilesCategoryWrapperProps = {
  children: (id: string) => JSX.Element
  ids: TIds
  loading: boolean

  view?: TFilesViewVariant
}

const WRAPPER = new Map<
  TFilesViewVariant,
  (
    props: Omit<TChatFilesCategoryWrapperProps, 'view' | 'loading'> & { gutter: Gutter }
  ) => JSX.Element
>()
  .set('grid', ({ children, ids, gutter }) => (
    <Row justify="start" gutter={[gutter, gutter]} className={styles.root}>
      {ids.map((id) => (
        <Col xs={{ span: 12 }} sm={{ span: 6 }} key={id} className={styles.item}>
          {children(id)}
        </Col>
      ))}
    </Row>
  ))
  .set('list', ({ ids, children }) => <>{ids.map(children)}</>)

export const ChatFilesCategoryWrapper = ({
  ids,
  children,
  loading,
  view = 'list'
}: TChatFilesCategoryWrapperProps) => {
  const { isMobile, isTablet } = useAdaptiveLayout()

  const { t } = useTranslation()

  const gutter = useMemo<Gutter>(() => {
    switch (true) {
      case isMobile:
        return 4
      case isTablet:
        return 12
      default:
        return 16
    }
  }, [isMobile, isTablet])

  const shouldShowPlaceholder = !loading && !ids.length

  return shouldShowPlaceholder ? (
    <EmptyList icon={<TextBoxIcon />} text={t('chat.details.filesPlaceholder')} />
  ) : (
    getMapComponent(WRAPPER, view, { ids, children, view, gutter })
  )
}
