import { Field } from 'react-final-form'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils'

import { TextField } from '../../../components'

const TITLE_MAX_LENGTH = 50
const DESCRIPTION_MAX_LENGTH = 100

export type TDepartmentMainFieldsProps = {
  nameError?: string
  classes?: {
    name?: string
  }
}

export const DepartmentMainFields = ({ nameError, classes }: TDepartmentMainFieldsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={24}>
        <Field
          name="name"
          validate={validation.composeValidators(
            validation.required(),
            validation.onlySpaces(),
            validation.maxLength(TITLE_MAX_LENGTH)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              classes={{ wrapper: classes?.name }}
              error={nameError || meta.error}
              topLabel={t('organizations.editDepartment.form.name')}
              valueLengthMax={TITLE_MAX_LENGTH}
              invalid={(meta.touched && meta.invalid) || !!nameError}
              autoFocus={true}
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field
          name="description"
          validate={validation.composeValidators(
            validation.onlySpaces(),
            validation.maxLength(DESCRIPTION_MAX_LENGTH)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              multiline
              rows={3}
              error={meta.error}
              topLabel={t('organizations.editDepartment.form.description')}
              invalid={meta.touched && meta.invalid}
              valueLengthMax={DESCRIPTION_MAX_LENGTH}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
