import { SetStateAction, useCallback, useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { setModalTitleAction, useAppDispatch } from 'store'
import { useSteps } from 'App/hooks'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { IDepartment, TChannelVariant } from 'interfaces'

enum steps {
  MAIN = 0,
  MEMBERS = 1,
  CHANEL_MANAGER = 2
}

export type TUseUnityHubCreateChannel = {
  channelId?: string
  channelName?: string
  isPublic?: boolean
  variant?: TChannelVariant
  maxSelectedChannelManager?: number
}

export type TChannelDepartment = Pick<
  IDepartment,
  'id' | 'name' | 'isChatsParticipant' | 'isVoiceRoomsParticipant'
>

export const useUnityHubCreateChannel = ({
  maxSelectedChannelManager,
  channelId = '',
  channelName = '',
  isPublic: channelIsPublic,
  variant = 'chat'
}: TUseUnityHubCreateChannel) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { step, setStep } = useSteps(steps.MAIN)

  const [name, setName] = useState<string>(channelName)
  const [isPublic, setIsPublic] = useState<boolean>(channelIsPublic || false)
  const [channelMembers, setChannelMembers] = useState<TAccountsSelectOptionData[]>([])
  const [channelManager, setChannelManager] = useState<TAccountsSelectOptionData>()
  const [channelDepartments, setChannelDepartments] = useState<TChannelDepartment[]>([])

  const [prefetchedAccounts, setPrefetchedAccounts] = useState<TAccountsSelectOptionData[]>([])
  const [prefetchedChannelManager, setPrefetchedChannelManager] =
    useState<TAccountsSelectOptionData>()
  const [prefetchedChannelDepartments, setPrefetchedChannelDepartments] = useState<
    TChannelDepartment[]
  >([])

  const [dirtyManager, setDirtyManager] = useState(false)
  const [dirtyAccounts, setDirtyAccounts] = useState(false)
  const [dirtyDepartments, setDirtyDepartments] = useState(false)
  const [dirtyName, setDirtyName] = useState(false)
  const [dirtyIsPublic, setDirtyIsPublic] = useState(false)

  const isEdit = Boolean(channelId && channelName)

  const handleOpenMain = useCallback(() => {
    setStep(steps.MAIN)

    const textTitle = isEdit
      ? t('modal.createChannel.edit.title_chat')
      : t('modal.createChannel.create.title_chat')
    const voiceTitle = isEdit
      ? t('modal.createChannel.edit.title_voice')
      : t('modal.createChannel.create.title_voice')

    dispatch(setModalTitleAction({ modalTitle: variant === 'chat' ? textTitle : voiceTitle }))
  }, [dispatch, isEdit, setStep, variant, t])

  const handleOpenMembers = useCallback(() => {
    setStep(steps.MEMBERS)

    dispatch(setModalTitleAction({ modalTitle: t('modal.createChannel.selectMembers.title') }))
  }, [dispatch, setStep, t])

  const handleOpenChanelManager = useCallback(() => {
    setStep(steps.CHANEL_MANAGER)

    dispatch(
      setModalTitleAction({ modalTitle: t('modal.createChannel.selectChannelManager.title') })
    )
  }, [dispatch, setStep, t])

  const handleSetChannelManager = useCallback(
    (value?: TAccountsSelectOptionData[], prefetched = false) => {
      const manager = value?.[0]

      if (!manager || !maxSelectedChannelManager) {
        setChannelManager(undefined)

        return
      }

      if (prefetched) {
        setPrefetchedChannelManager(manager)
      }

      if (manager && value && value?.length === maxSelectedChannelManager) {
        // This condition is valid as long as `maxSelected` is equal to 1

        setChannelMembers((prev) => {
          const isAccountNotFound = prev.findIndex((item) => item.id === manager.id) === -1

          return isAccountNotFound ? [...prev, manager] : prev
        })
        setChannelManager(manager)
      }
    },
    [maxSelectedChannelManager]
  )

  const handleSetChannelMembers = useCallback(
    (value: SetStateAction<TAccountsSelectOptionData[]>, prefetched = false) => {
      if (prefetched) {
        setPrefetchedAccounts(value)
      }

      setChannelMembers(value)
    },
    []
  )

  const handleSetChannelDepartments = useCallback(
    (value: SetStateAction<TChannelDepartment[]>, prefetched = false) => {
      if (prefetched) {
        setPrefetchedChannelDepartments(value)
      }

      setChannelDepartments(value)
    },
    []
  )

  useEffect(() => {
    setDirtyAccounts(
      prefetchedAccounts.length !== channelMembers.length ||
        !isEqual(prefetchedAccounts, channelMembers)
    )
  }, [prefetchedAccounts, channelMembers])

  useEffect(() => {
    setDirtyDepartments(
      prefetchedChannelDepartments.length !== channelDepartments.length ||
        !isEqual(prefetchedChannelDepartments, channelDepartments)
    )
  }, [prefetchedChannelDepartments, channelDepartments])

  useEffect(() => {
    setDirtyManager(prefetchedChannelManager?.id !== channelManager?.id)
  }, [prefetchedChannelManager, channelManager])

  useEffect(() => {
    setDirtyName(channelName !== name)
  }, [channelName, name])

  useEffect(() => {
    setDirtyIsPublic(channelIsPublic !== isPublic)
  }, [channelIsPublic, isPublic])

  return {
    dirty: dirtyName || dirtyAccounts || dirtyManager || dirtyIsPublic || dirtyDepartments,
    name,
    channelMembers,
    channelManager,
    channelDepartments,
    step,
    isPublic,
    dirtyIsPublic,
    isEdit,
    handleOpenMain,
    handleOpenMembers,
    handleOpenChanelManager,
    setIsPublic,
    setName,
    setChannelMembers: handleSetChannelMembers,
    setChannelManager: handleSetChannelManager,
    setChannelDepartments: handleSetChannelDepartments
  }
}
