import { useTranslation } from 'react-i18next'

import { SearchCascader, TSearchCascaderProps } from 'App/components/common/Fields/SearchCascader'
import {
  useContactsSearchByOptions,
  TUseContactsSearchByOptionsProps
} from 'App/hooks/SearchCascader/useContactsSearchByOptions'
import { EInputSize } from 'enums'

export type TContactsSearchCascaderProps = Pick<TSearchCascaderProps, 'onSearchChange'> &
  TUseContactsSearchByOptionsProps

export const ContactsSearchCascader = ({
  onSearchChange,
  onChange
}: TContactsSearchCascaderProps) => {
  const { onCascaderChange, options } = useContactsSearchByOptions({
    onChange
  })

  const { t } = useTranslation()

  return (
    <SearchCascader
      searchSize={EInputSize.L}
      placeholder={t('modal.broadcastMessage.recipientsStep.searchPlaceholder_contacts')}
      options={options}
      triggerInitialOnChange={true}
      onSearchChange={onSearchChange}
      onCascaderChange={onCascaderChange}
    />
  )
}
