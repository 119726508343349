import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { SettingsSecurityPassword } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsSecurityPasswordContainerProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const SettingsSecurityPasswordContainer = connect(
  null,
  mapDispatchToProps
)(SettingsSecurityPassword)
