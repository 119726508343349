import { TAction } from 'store/store.utils'
import {
  DELETE_ALL_MESSAGES_SUCCESS,
  PIN_CHAT_MESSAGE_SUCCESS,
  RESET_CHAT,
  RESET_SELECTED_CHAT
} from 'store'

import { TChatPinState, TGetChatPinSuccess, TRemoveChatPinMessageSuccess } from './chatPin.types'
import {
  GET_CHAT_PIN_SUCCESS,
  CLEAR_CHAT_PIN,
  REMOVE_ALL_CHAT_PIN_MESSAGES_SUCCESS,
  REMOVE_CHAT_PIN_MESSAGE_SUCCESS
} from './chatPin.actions'

export const initialChatPinState: TChatPinState = {
  data: null,
  total: 0
}

export const chatPinReducer = (state = initialChatPinState, action: TAction) => {
  switch (action.type) {
    case GET_CHAT_PIN_SUCCESS: {
      return {
        ...state,
        ...(action as TGetChatPinSuccess).payload
      }
    }

    case REMOVE_ALL_CHAT_PIN_MESSAGES_SUCCESS:
    case DELETE_ALL_MESSAGES_SUCCESS:
    case CLEAR_CHAT_PIN: {
      return initialChatPinState
    }

    case REMOVE_CHAT_PIN_MESSAGE_SUCCESS:
    case PIN_CHAT_MESSAGE_SUCCESS: {
      const { total } = (action as TRemoveChatPinMessageSuccess).payload

      return {
        ...state,
        total
      }
    }

    case RESET_CHAT:
    case RESET_SELECTED_CHAT: {
      return initialChatPinState
    }

    default:
      return state
  }
}
