import { Radio, RadioProps, RadioGroupProps } from 'antd'

import styles from './UnityHubSettingsRadio.module.scss'

export const UnityHubSettingsRadio = ({ children, ...props }: RadioProps) => (
  <Radio {...props} className={styles.radio}>
    {children}
  </Radio>
)

export const UnityHubSettingsRadioGroup = ({ children, ...props }: RadioGroupProps) => (
  <Radio.Group {...props} className={styles.radioGroup}>
    {children}
  </Radio.Group>
)
