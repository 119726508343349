import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  createLoadingSelector,
  getCaseHistoryFilters,
  getCaseHistoryList,
  GET_CASE_HISTORY,
  GET_CASE_HISTORY_FILTERS
} from 'store'
import { State } from 'redux/rootReducer'
import { CaseHistory } from 'App/components/Case/CaseHistory'

export type TCaseHistoryContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_CASE_HISTORY_FILTERS, GET_CASE_HISTORY])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  caseHistoryList: state.cases.caseHistoryList,
  caseHistoryFilters: state.cases.caseHistoryFilters,
  total: state.cases.caseHistoryListTotal
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseHistory: getCaseHistoryList,
      getCaseHistoryFilters
    },
    dispatch
  )

export const CaseHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(CaseHistory)
