import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { CreateChannelButton } from 'App/containers/Organization/CreateChannels'
import { UnityHubRoomsNavCollapse, TUnityHubRoomsNavCollapseView } from 'App/components/UnityHub'

import { ChatRoom } from '../ChatRoom'
import { useKebabMenu } from '../useKebabMenu'
import { usePinnedChannels } from '../../hooks'

export type TRoomsNavTextListProps = {
  organizationId: string
}

export const RoomsNavTextList = ({ organizationId }: TRoomsNavTextListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const ids = useAppSelector((state) => state.chat.chatRooms.ids)

  const { pinnedIds } = usePinnedChannels({
    ids,
    entity: 'organizations',
    entityId: organizationId
  })

  const { t } = useTranslation()

  const shouldShowPinnedChannels = Boolean(pinnedIds.length) && isCollapsed

  const handleChangeView = useCallback((view: TUnityHubRoomsNavCollapseView) => {
    setIsCollapsed(view === 'collapsed')
  }, [])

  const { getMenuItems } = useKebabMenu({ organizationId })

  return (
    <>
      <UnityHubRoomsNavCollapse
        variant="chat"
        title={t('unityHub.channelsList.textChannelsLabel')}
        localStorageKey={organizationId}
        onChangeView={handleChangeView}
        action={<CreateChannelButton variant="chat" organizationId={organizationId} />}
      >
        {ids.map((id) => (
          <ChatRoom
            key={id}
            chatRoomId={id}
            organizationId={organizationId}
            getMenuItems={getMenuItems}
          />
        ))}
      </UnityHubRoomsNavCollapse>

      {shouldShowPinnedChannels && (
        <div>
          {pinnedIds.map((id) => (
            <ChatRoom
              key={id}
              chatRoomId={id}
              organizationId={organizationId}
              getMenuItems={getMenuItems}
            />
          ))}
        </div>
      )}
    </>
  )
}
