import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ProducedNotificationsEnum, CaseStatusesEnum } from '@medentee/enums'

import { CaseDescriptionList } from 'App/components'
import {
  getCaseDescriptionRequest,
  addCaseDescriptionRequest,
  ADD_CASE_DESCRIPTION,
  createProcessingSelector,
  GET_CASE_DESCRIPTION,
  createLoadingSelector,
  readNotificationFromIdRequest,
  getCaseNotificationsSelector,
  getIsActiveMemberSelector,
  updateCaseDraftRequest,
  getCaseDraftRequest,
  GET_CASE_DRAFT
} from 'store'
import { State } from 'redux/rootReducer'
import { ECaseAccountType, ECasesDocumentKey } from 'enums'

export type TCaseDescriptionContainerProps = {
  who: ECaseAccountType
  caseId: string
  isOwner: boolean
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseDescriptionContainerView = ({
  caseId,
  ids,
  count,
  who,
  processing,
  loading,
  isOwner,
  isCaseArchived,
  newLineDescriptionId,
  notifications,
  isActiveMember,
  classification,
  draft,
  getCaseDescription,
  addCaseDescription,
  readNotificationFromId,
  updateCaseDraft,
  getCaseDraft
}: TCaseDescriptionContainerProps) => {
  useEffect(() => {
    if (!(!isOwner && (!isActiveMember || isCaseArchived))) {
      getCaseDescription({ caseId })
    }
    if (isOwner && !isCaseArchived) {
      getCaseDraft({ caseId, type: ECasesDocumentKey.DESCRIPTION })
    }
    // Do not add `caseId` as a dependency to avoid unneeded fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [who, isOwner, isActiveMember, isCaseArchived, getCaseDescription, getCaseDraft])

  return (
    <CaseDescriptionList
      caseId={caseId}
      ids={ids}
      count={count}
      who={who}
      processing={processing}
      loading={loading}
      isOwner={isOwner}
      isCaseArchived={isCaseArchived}
      newLineDescriptionId={newLineDescriptionId}
      notifications={notifications}
      classification={classification}
      draft={draft}
      addCaseDescription={addCaseDescription}
      readNotificationFromId={readNotificationFromId}
      updateCaseDraft={updateCaseDraft}
    />
  )
}

const processingSelector = createProcessingSelector([ADD_CASE_DESCRIPTION])
const loadingDescriptionSelector = createLoadingSelector([GET_CASE_DESCRIPTION])
const loadingDraftSelector = createLoadingSelector([GET_CASE_DRAFT])

const mapStateToProps = (state: State) => ({
  ids: state.caseDetails.description.ids,
  count: state.caseDetails.description.count,
  processing: processingSelector(state),
  loading: loadingDescriptionSelector(state) || loadingDraftSelector(state),
  newLineDescriptionId: state.caseDetails.description.newLineDescriptionId,
  notifications: getCaseNotificationsSelector(
    state,
    ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED
  ),
  isCaseArchived: state.caseView.data?.status === CaseStatusesEnum.ARCHIVED,
  isActiveMember: getIsActiveMemberSelector(state),
  classification: state.caseView.data?.classification.name,
  draft: state.caseDetails.description.draft
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseDescription: getCaseDescriptionRequest,
      addCaseDescription: addCaseDescriptionRequest,
      readNotificationFromId: readNotificationFromIdRequest,
      updateCaseDraft: updateCaseDraftRequest,
      getCaseDraft: getCaseDraftRequest
    },
    dispatch
  )

export const CaseDescriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDescriptionContainerView)
