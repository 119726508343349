export const GET_COMMUNITIES = 'GET_COMMUNITIES'
export const GET_COMMUNITIES_REQUEST = 'GET_COMMUNITIES_REQUEST'
export const GET_COMMUNITIES_ERROR = 'GET_COMMUNITIES_ERROR'
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS'

export const RESET_COMMUNITIES = 'RESET_COMMUNITIES'

export const SET_COMMUNITY_ID = 'SET_COMMUNITY_ID'

export const LOST_ACCESS_TO_COMMUNITY = 'LOST_ACCESS_TO_COMMUNITY'
