import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { TAction, TGetCaseListSuccess } from 'store'
import { ICaseCountDTO } from 'interfaces'

import * as CASE_ACTIONS from './cases.actions'
import {
  TCaseTypes,
  TSetCaseCountLoading,
  TSetCasesCount,
  TGetCasesLinksSuccess,
  TToggleCaseFilterAction,
  TGetCaseHistoryListSuccess,
  TGetCaseHistoryFiltersSuccess,
  TSetCaseListSearch,
  TSetCaseListPagination,
  TSetCasesLinksSearch,
  TSetCasesLinksFilter,
  TGetLinkCaseListSuccess,
  TSetLinkCaseListFilter,
  TSetLinkCaseListSearch
} from './cases.types'

export const initialCaseCountState: ICaseCountDTO = {
  owner: {
    active: 0,
    locked: 0,
    archived: 0
  },
  member: {
    active: 0,
    locked: 0,
    pending: 0
  }
}

export const initialCaseListState: TCaseTypes = {
  ids: [],
  caseLinks: {
    linkedCases: {},
    ids: [],
    filters: {
      search: '',
      searchBy: '',
      classifications: [],
      roles: [],
      statuses: []
    }
  },
  caseList: {},
  memberList: {},
  caseHistoryList: [],
  caseHistoryFilters: {
    accounts: [],
    actionGroups: [],
    actions: []
  },
  caseHistoryListTotal: 0,
  countLoading: true,
  notifications: 0,
  count: initialCaseCountState,
  filters: {
    total: 0,
    page: 0,
    showBy: PAGINATION_DEFAULT_SHOW_BY,
    search: '',
    searchBy: '',
    classifications: [],
    roles: [],
    statuses: []
  },
  linkCases: {
    ids: [],
    list: {},
    filters: {
      search: '',
      searchBy: '',
      classifications: [],
      roles: [],
      statuses: []
    }
  }
}

export const casesReducer = (state = initialCaseListState, action: TAction) => {
  switch (action.type) {
    case CASE_ACTIONS.GET_CASE_LIST_SUCCESS: {
      const { ids, caseList, memberList, total } = (action as TGetCaseListSuccess).payload

      return {
        ...state,
        filters: {
          ...state.filters,
          total
        },
        ids,
        caseList,
        memberList
      }
    }

    case CASE_ACTIONS.GET_CASES_LINKS_SUCCESS: {
      return {
        ...state,
        caseLinks: {
          ...state.caseLinks,
          ...(action as TGetCasesLinksSuccess).payload
        }
      }
    }

    case CASE_ACTIONS.GET_CASE_HISTORY_SUCCESS: {
      return {
        ...state,
        caseHistoryList: (action as TGetCaseHistoryListSuccess).payload.caseHistoryList,
        caseHistoryListTotal: (action as TGetCaseHistoryListSuccess).payload.total
      }
    }

    case CASE_ACTIONS.GET_CASE_HISTORY_FILTERS_SUCCESS: {
      return {
        ...state,
        caseHistoryFilters: (action as TGetCaseHistoryFiltersSuccess).payload.caseHistoryFilters
      }
    }

    case CASE_ACTIONS.SET_CASE_COUNT: {
      return { ...state, count: (action as TSetCasesCount).payload.count }
    }

    case CASE_ACTIONS.SET_CASE_COUNT_LOADING: {
      return { ...state, countLoading: (action as TSetCaseCountLoading).payload.countLoading }
    }

    case CASE_ACTIONS.SET_CASE_LIST_SEARCH: {
      const { search, searchBy } = (action as TSetCaseListSearch).payload

      return {
        ...state,
        filters: {
          ...state.filters,
          page: 0,
          search,
          searchBy
        }
      }
    }

    case CASE_ACTIONS.SET_CASES_LINKS_SEARCH: {
      return {
        ...state,
        caseLinks: {
          ...state.caseLinks,
          filters: {
            ...state.caseLinks.filters,
            ...(action as TSetCasesLinksSearch).payload
          }
        }
      }
    }

    case CASE_ACTIONS.SET_CASE_LIST_PAGINATION: {
      const { page = 0, showBy = PAGINATION_DEFAULT_SHOW_BY } = (action as TSetCaseListPagination)
        .payload

      return {
        ...state,
        filters: {
          ...state.filters,
          page,
          showBy
        }
      }
    }

    case CASE_ACTIONS.RESET_CASE_LIST_ACTION: {
      return {
        ...initialCaseListState
      }
    }

    case CASE_ACTIONS.GET_LINK_CASE_LIST_SUCCESS: {
      return {
        ...state,
        linkCases: {
          ...state.linkCases,
          ...(action as TGetLinkCaseListSuccess).payload
        }
      }
    }

    case CASE_ACTIONS.RESET_LINK_CASE_LIST: {
      return {
        ...state,
        linkCases: initialCaseListState.linkCases
      }
    }

    case CASE_ACTIONS.TOGGLE_CASE_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as TToggleCaseFilterAction).payload,
          page: 0
        }
      }
    }

    case CASE_ACTIONS.SET_CASES_LINKS_FILTER: {
      return {
        ...state,
        caseLinks: {
          ...state.caseLinks,
          filters: {
            ...state.caseLinks.filters,
            ...(action as TSetCasesLinksFilter).payload
          }
        }
      }
    }

    case CASE_ACTIONS.RESET_CASES_LINKS_FILTER: {
      return {
        ...state,
        caseLinks: {
          ...state.caseLinks,
          filters: {
            ...state.caseLinks.filters,
            classifications: initialCaseListState.caseLinks.filters.classifications,
            roles: initialCaseListState.caseLinks.filters.roles,
            statuses: initialCaseListState.caseLinks.filters.statuses
          }
        }
      }
    }

    case CASE_ACTIONS.SET_LINK_CASE_LIST_SEARCH: {
      return {
        ...state,
        linkCases: {
          ...state.linkCases,
          filters: {
            ...state.linkCases.filters,
            ...(action as TSetLinkCaseListSearch).payload
          }
        }
      }
    }

    case CASE_ACTIONS.SET_LINK_CASE_LIST_FILTER: {
      return {
        ...state,
        linkCases: {
          ...state.linkCases,
          filters: {
            ...state.linkCases.filters,
            ...(action as TSetLinkCaseListFilter).payload
          }
        }
      }
    }

    case CASE_ACTIONS.RESET_LINK_CASE_LIST_FILTER: {
      return {
        ...state,
        linkCases: {
          ...state.linkCases,
          filters: {
            ...state.linkCases.filters,
            classifications: initialCaseListState.linkCases.filters.classifications,
            roles: initialCaseListState.linkCases.filters.roles,
            statuses: initialCaseListState.linkCases.filters.statuses
          }
        }
      }
    }

    case CASE_ACTIONS.RESET_CASE_FILTER_ACTION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          classifications: initialCaseListState.filters.classifications,
          roles: initialCaseListState.filters.roles,
          statuses: initialCaseListState.filters.statuses,
          page: initialCaseListState.filters.page
        }
      }
    }

    case CASE_ACTIONS.RESET_CASE_LINKS_ACTION: {
      return {
        ...state,
        caseLinks: { ...initialCaseListState.caseLinks }
      }
    }
    default:
      return state
  }
}
