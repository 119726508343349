import { useCallback, useEffect, useMemo } from 'react'
import cls from 'classnames'
import {
  CaseClassificationNameEnum,
  CaseStatusesEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { ECaseAccountType, ECaseCloudTabKey } from 'enums'
import { useAdaptiveLayout, useNotificationTracker } from 'App/hooks'
import {
  getIsBasicSubscription,
  getIsMedCloudCapacityExceed,
  TCallbackAfterUploadFile,
  useAppSelector
} from 'store'
import { Card, DropDownMenu, TabPane, Tabs, FileViewToggle, useFilesView } from 'App/components'
import { EModalComponents, TCaseMemberCloudContainerProps } from 'App/containers'
import { ReactComponent as TextBoxPlusIcon } from 'assets/icons/TextBoxPlus.svg'

import { Cloud } from './Cloud'
import { FileDetails } from './FileDetails'
import { SharedWithMe } from './SharedWithMe'
import { UploadedFilesByMe } from './UploadedFilesByMe'
import { Actions, TActionsItem } from './Actions'
import { useKebabMenu } from './hooks/useKebabMenu'
import { Filter } from './Filter'
import styles from './CaseCloud.module.scss'

export type TCaseCloudVariant = 'case-cloud' | 'shard-with-me' | 'upload-by-me'

type TTabTitleMapProps = {
  cloudFilesTotal: number
  uploadedByMeTotal: number
  sharedWithMeTotal: number
  isCaseArchived: boolean
  t: TFunction
}

const TAB_TITLES = new Map<ECaseAccountType, (props: TTabTitleMapProps) => string[]>()
  .set(ECaseAccountType.OWNER, ({ cloudFilesTotal, sharedWithMeTotal, isCaseArchived, t }) => [
    t('cases.details.attachments.tabs.owner_uploaded', { quantity: cloudFilesTotal }),
    ...(!isCaseArchived
      ? [
          t('cases.details.attachments.tabs.owner_shared', {
            quantity: sharedWithMeTotal
          })
        ]
      : [])
  ])
  .set(ECaseAccountType.MEMBER, ({ cloudFilesTotal, uploadedByMeTotal, t }) => [
    t('cases.details.attachments.tabs.member_shared', {
      quantity: cloudFilesTotal
    }),
    t('cases.details.attachments.tabs.member_uploaded', {
      quantity: uploadedByMeTotal
    })
  ])

const TRACKED_NOTIFICATIONS = new Set([
  ProducedNotificationsEnum.ACCESS_TO_CASE_FILE_ACQUIRED,
  ProducedNotificationsEnum.ACCESS_TO_CASE_FILE_LOST,
  ProducedNotificationsEnum.GRANTED_AUTO_CASE_FILE_PERMISSION,
  ProducedNotificationsEnum.DISCARDED_CASE_FILE_PERMISSION,
  ProducedNotificationsEnum.REMOVED_CASE_FILE_PERMISSION,
  ProducedNotificationsEnum.GRANTED_CASE_FILE_PERMISSION
])

export const CaseCloud = ({
  loadingCaseCloudFiles,
  loadingCaseCloudUploadedByMeFiles,
  loadingCaseCloudSharedWithMeFiles,
  loading,
  cloudFilesTotal,
  caseId,
  who,
  caseStatus,
  cloudFilesIds,
  showFileDetails,
  sharedWithMeTotal,
  sharedWithMeIds,
  uploadedByMeIds,
  uploadedByMeTotal,
  currentTab,
  caseClassificationName,
  isActiveMember,
  permissionsMode,
  caseType,
  caseView,
  filters,
  caseUploadFile,
  getCaseFiles,
  downloadFile,
  copyToMedcloud,
  setCaseCloudActiveFileIdAction,
  showModal,
  checkCaseUnlocking,
  getCaseSharedWithMeFiles,
  getCaseUploadedByMeFiles,
  setCaseCloudWho,
  showExtendableCard,
  setCurrentTab,
  resetFiles,
  resetCaseCloudFilters,
  setCaseCloudFilters
}: TCaseMemberCloudContainerProps) => {
  const { extensionCategories } = filters
  const [filesView, setFilesView] = useFilesView('case-cloud')

  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const isBasicSubscription = useAppSelector(getIsBasicSubscription)
  const isMedCloudCapacityExceed = useAppSelector(getIsMedCloudCapacityExceed)

  const isProfessionalInitial =
    caseClassificationName === CaseClassificationNameEnum.PROFESSIONAL && !caseType
  const isPrivate = caseClassificationName === CaseClassificationNameEnum.PRIVATE
  const isMember = who === ECaseAccountType.MEMBER
  const isCaseLocked = caseStatus === CaseStatusesEnum.LOCKED
  const isCaseArchived = caseStatus === CaseStatusesEnum.ARCHIVED
  const isUploadedByMe = isMember && currentTab === ECaseCloudTabKey.SECOND
  const isSharedWithMe = !isMember && currentTab === ECaseCloudTabKey.SECOND
  const isCloudFiles = currentTab === ECaseCloudTabKey.FIRST
  const disableAttach = (isMember && isCaseLocked) || isCaseArchived
  const disableFetch = !isCloudFiles || (isCaseLocked && isMember) || !isActiveMember
  const isFiltered = Boolean(extensionCategories?.length)

  const getFiles = useCallback(() => {
    if (!disableFetch) {
      getCaseFiles({ caseId })
    }

    if (isSharedWithMe) {
      getCaseSharedWithMeFiles({ caseId })
    }

    if (isUploadedByMe) {
      getCaseUploadedByMeFiles({ caseId })
    }
  }, [
    caseId,
    disableFetch,
    getCaseFiles,
    getCaseSharedWithMeFiles,
    getCaseUploadedByMeFiles,
    isSharedWithMe,
    isUploadedByMe
  ])

  useNotificationTracker({
    onOccurrence: getFiles,
    channel: 'case-attachments',
    notifications: TRACKED_NOTIFICATIONS,
    notificationsFilter: (payload: { caseIds?: string[]; caseId?: string }) =>
      payload.caseId === caseId || (payload.caseIds?.includes(caseId) ?? false)
  })

  const handleTabChange = useCallback(
    (key: string) => {
      if (currentTab !== key) {
        setCurrentTab(key as ECaseCloudTabKey)
        resetFiles()
      }
    },
    [currentTab, setCurrentTab, resetFiles]
  )

  const onAttachFile = useCallback(() => {
    if (disableAttach) {
      return
    }

    if (isBasicSubscription && isMedCloudCapacityExceed) {
      showModal({
        modalTitle: t('cases.modal.upgradeSubscription.attachFile.title'),
        modalType: EModalComponents.UPGRADE_SUBSCRIPTION,
        modalProps: {
          text: t('cases.modal.upgradeSubscription.attachFile.content')
        }
      })
      return
    }

    showModal({
      modalTitle: t('modal.attachFile.title'),
      modalType: EModalComponents.CASE_ATTACH_FILE_DIALOG,
      modalProps: {
        caseId
      }
    })
  }, [disableAttach, isBasicSubscription, isMedCloudCapacityExceed, showModal, caseId, t])

  const handleDownloadToDevice = useCallback(
    (fileId: string) => {
      const download = () => {
        downloadFile({
          fileId,
          caseId
        })
      }

      return () => {
        if (isMember) {
          return checkCaseUnlocking({
            type: who,
            id: caseId,
            callback: download
          })
        }

        return download()
      }
    },
    [caseId, who, isMember, checkCaseUnlocking, downloadFile]
  )

  const { getKebabMenu } = useKebabMenu({
    isCaseArchived,
    isSharedWithMe,
    isUploadedByMe,
    isMember,
    isPrivate,
    caseId,
    who,
    setCaseCloudActiveFileIdAction,
    showExtendableCard,
    copyToMedcloud,
    handleDownloadToDevice,
    showModal
  })

  const onAfterUploadFile: TCallbackAfterUploadFile = useCallback(
    ({ id: fileId }) => {
      caseUploadFile({
        fileIds: [fileId],
        caseId
      })
    },
    [caseId, caseUploadFile]
  )

  // needed for files counter of second tab in case cloud
  useEffect(() => {
    isMember ? getCaseUploadedByMeFiles({ caseId }) : getCaseSharedWithMeFiles({ caseId })
  }, [caseId, isMember, getCaseSharedWithMeFiles, getCaseUploadedByMeFiles])

  useEffect(() => {
    setCaseCloudWho({ who })
  }, [who, setCaseCloudWho])

  useEffect(() => {
    getFiles()
  }, [
    // The `caseStatus`, `permissionsMode` props are required
    // to be able to refetch files on case status change
    caseStatus,
    // Filters are required to trigger list update
    extensionCategories,
    currentTab,
    permissionsMode,
    getFiles
  ])

  useEffect(() => {
    resetCaseCloudFilters()
  }, [
    // The `currentTab` is required to be able
    // to reset selected files on case tab changed
    currentTab,
    resetCaseCloudFilters
  ])

  useEffect(() => {
    if (caseView === 'standard' && filesView === 'grid') {
      setFilesView('list')
    }
  }, [caseView, filesView, setFilesView])

  const tabsList = useMemo(
    () =>
      TAB_TITLES.has(who)
        ? TAB_TITLES.get(who)!({
            cloudFilesTotal,
            uploadedByMeTotal,
            sharedWithMeTotal,
            isCaseArchived,
            t
          })
        : [],
    [cloudFilesTotal, sharedWithMeTotal, uploadedByMeTotal, who, isCaseArchived, t]
  )

  const isTabsEmpty = useCallback(() => {
    // all in the name of UX

    if (currentTab === ECaseCloudTabKey.FIRST && !loadingCaseCloudFiles) {
      return !cloudFilesIds.length
    }

    if (currentTab === ECaseCloudTabKey.SECOND) {
      if (isSharedWithMe && !loadingCaseCloudSharedWithMeFiles) {
        return !sharedWithMeIds.length
      }

      if (isUploadedByMe && !loadingCaseCloudUploadedByMeFiles) {
        return !uploadedByMeIds.length
      }
    }

    return true
  }, [
    currentTab,
    isUploadedByMe,
    isSharedWithMe,
    cloudFilesIds,
    sharedWithMeIds,
    uploadedByMeIds,
    loadingCaseCloudFiles,
    loadingCaseCloudSharedWithMeFiles,
    loadingCaseCloudUploadedByMeFiles
  ])

  const actionsItems: TActionsItem[] = useMemo(
    () => [
      {
        hidden: isCaseArchived,
        icon: (
          <Filter
            disabled={!isFiltered && isTabsEmpty()}
            filters={filters}
            setCaseCloudFilters={setCaseCloudFilters}
            resetCaseCloudFilters={resetCaseCloudFilters}
            caseView={caseView}
          />
        )
      },
      {
        hidden: isCaseArchived || caseView !== 'expanded',
        icon: (
          <FileViewToggle
            disabled={isFiltered || isTabsEmpty()}
            variant={filesView}
            onChange={setFilesView}
          />
        )
      },
      {
        hidden: isCaseArchived,
        onClick: onAttachFile,
        disabled: disableAttach,
        icon: <TextBoxPlusIcon />,
        title: t('cases.details.attachments.actions')
      }
    ],
    [
      caseView,
      disableAttach,
      filesView,
      filters,
      isCaseArchived,
      isFiltered,
      isTabsEmpty,
      onAttachFile,
      resetCaseCloudFilters,
      setCaseCloudFilters,
      setFilesView,
      t
    ]
  )

  return (
    <Card
      title={t('cases.details.attachments.title')}
      loading={loading}
      actions={<Actions items={actionsItems} />}
    >
      {showFileDetails && (
        <FileDetails
          caseId={caseId}
          isMember={isMember}
          isSharedWithMe={isSharedWithMe}
          isUploadedByMe={isUploadedByMe}
        />
      )}

      {!showFileDetails && (
        <>
          {isPrivate || isProfessionalInitial ? (
            <div className={styles.container}>
              <Cloud
                loading={loadingCaseCloudFiles}
                caseId={caseId}
                caseClassificationName={caseClassificationName}
                caseStatus={caseStatus}
                caseType={caseType}
                cloudFilesIds={cloudFilesIds}
                isCaseArchived={isCaseArchived}
                isCaseLocked={isCaseLocked}
                isMember={isMember}
                onAfterUploadFile={onAfterUploadFile}
                permissionsMode={permissionsMode}
                showModal={showModal}
                getKebabMenu={getKebabMenu}
                caseView={caseView}
                filesView={filesView}
                isFiltered={isFiltered}
              />
            </div>
          ) : (
            <>
              {isMobile && (
                <DropDownMenu list={tabsList} title={currentTab} onChange={setCurrentTab} />
              )}
              {isCaseArchived ? (
                <div className={styles.tabs}>
                  <Cloud
                    loading={loadingCaseCloudFiles}
                    caseId={caseId}
                    caseClassificationName={caseClassificationName}
                    caseStatus={caseStatus}
                    caseType={caseType}
                    cloudFilesIds={cloudFilesIds}
                    isCaseArchived={isCaseArchived}
                    isCaseLocked={isCaseLocked}
                    isMember={isMember}
                    onAfterUploadFile={onAfterUploadFile}
                    permissionsMode={permissionsMode}
                    showModal={showModal}
                    getKebabMenu={getKebabMenu}
                  />
                </div>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  className={cls({
                    [styles.tabs]: true,
                    [styles.tabsEmpty]: isTabsEmpty()
                  })}
                  variant="fill"
                  onChange={handleTabChange}
                  destroyInactiveTabPane={true}
                >
                  <TabPane tab={tabsList[0]} key="0">
                    <Cloud
                      loading={loadingCaseCloudFiles}
                      caseId={caseId}
                      caseClassificationName={caseClassificationName}
                      caseStatus={caseStatus}
                      caseType={caseType}
                      cloudFilesIds={cloudFilesIds}
                      isCaseArchived={isCaseArchived}
                      isCaseLocked={isCaseLocked}
                      isMember={isMember}
                      onAfterUploadFile={onAfterUploadFile}
                      permissionsMode={permissionsMode}
                      showModal={showModal}
                      getKebabMenu={getKebabMenu}
                      caseView={caseView}
                      filesView={filesView}
                      isFiltered={isFiltered}
                    />
                  </TabPane>
                  <TabPane tab={tabsList[1]} key="1">
                    {isSharedWithMe ? (
                      <SharedWithMe
                        caseId={caseId}
                        caseStatus={caseStatus}
                        isCaseArchived={isCaseArchived}
                        isCaseLocked={isCaseLocked}
                        isMember={isMember}
                        loading={loadingCaseCloudSharedWithMeFiles}
                        sharedWithMeIds={sharedWithMeIds}
                        getKebabMenu={getKebabMenu}
                        caseView={caseView}
                        filesView={filesView}
                        isFiltered={isFiltered}
                      />
                    ) : (
                      <UploadedFilesByMe
                        loading={loadingCaseCloudUploadedByMeFiles}
                        caseId={caseId}
                        caseStatus={caseStatus}
                        caseType={caseType}
                        isCaseArchived={isCaseArchived}
                        isCaseLocked={isCaseLocked}
                        isMember={isMember}
                        uploadedByMeIds={uploadedByMeIds}
                        getKebabMenu={getKebabMenu}
                        onAfterUploadFile={onAfterUploadFile}
                        caseView={caseView}
                        filesView={filesView}
                        isFiltered={isFiltered}
                      />
                    )}
                  </TabPane>
                </Tabs>
              )}
            </>
          )}
        </>
      )}
    </Card>
  )
}
