import { CaseMemberStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TCaseMembersItemInactiveContainerProps } from 'App/containers'
import {
  CaseMembersItemWrapper,
  CaseMembersItemMessageButton,
  CaseMembersItemKebabMenu,
  CaseMembersItemRemoveIcon,
  StatusColored
} from 'App/components'

import styles from './CaseMembersItemInactive.module.scss'

export type TCaseMembersItemInactiveProps = TCaseMembersItemInactiveContainerProps

export const CaseMembersItemInactive = ({
  member: {
    id: memberId,
    status,
    chatId,
    account: { id, firstName, lastName, displayUserName, type, isCoworker }
  },
  isCaseOwner,
  caseOwnerId,
  removeMemberFromCase,
  showModal
}: TCaseMembersItemInactiveProps) => {
  const { t } = useTranslation()

  return (
    <CaseMembersItemWrapper
      id={id}
      firstName={firstName}
      lastName={lastName}
      displayUserName={displayUserName}
      type={type.name}
      caseOwnerId={caseOwnerId}
      isCoworker={isCoworker}
    >
      <StatusColored
        status={status}
        statusLabel={t(`enum.caseMemberStatusesEnum.${status}`)}
        className={styles.status}
      />
      <CaseMembersItemMessageButton chatId={chatId} partnerAccountId={id} />
      {status === CaseMemberStatusesEnum.INVITE_PENDING ? (
        <CaseMembersItemKebabMenu memberId={memberId} active={false} showModal={showModal} />
      ) : (
        <CaseMembersItemRemoveIcon
          isCaseOwner={isCaseOwner}
          memberId={memberId}
          removeMemberFromCase={removeMemberFromCase}
        />
      )}
    </CaseMembersItemWrapper>
  )
}
