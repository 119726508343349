import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import AudioRecorder from 'audio-recorder-polyfill'
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder'
import { HotkeysProvider } from 'react-hotkeys-hook'
import { init, browserTracingIntegration, replayIntegration } from '@sentry/react'

import { Spinner } from './App/components/Spinner'
import { App } from './App'
import { store } from './redux/store'
import history from './utils/history'
import 'react-toastify/dist/ReactToastify.min.css'
import './assets/style/index.scss'
import 'antd/dist/antd.less'
import { EHotkeysScope } from './enums'
import './i18n'
import config from './config'

AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

if (config.sentryDSN) {
  init({
    dsn: config.sentryDSN,
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [config.apiUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <HotkeysProvider initiallyActiveScopes={[EHotkeysScope.FULLSCREEN_VIEWER]}>
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </HotkeysProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
)
