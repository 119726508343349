import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, useRouteMatch, Redirect, generatePath, useHistory } from 'react-router-dom'
import { ProducedNotificationsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  GET_ORGANIZATIONS,
  getOrganizationsRequest,
  useAppSelector,
  resetOrganizations
} from 'store'
import { EmptyList } from 'App/components'
import { useAdaptiveLayout, useNotificationTracker } from 'App/hooks'
import { OrganizationsInvitations, RoleRouteContainer } from 'App/containers'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { INotificationEntity } from 'interfaces'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { UnityHubLayout } from 'App/components/UnityHub'

import { OrganizationContent } from './OrganizationContent'
import { OrganizationsNav } from './OrganizationsNav'

export const ORGANIZATION_CONTENT_PATH = '/organizations/:id'

const INVITATIONS_PATH = '/organizations/invitations'

const TRACKED_NOTIFICATIONS = new Set([
  ProducedNotificationsEnum.REMOVED_FROM_STAFF,
  ProducedNotificationsEnum.RESIGNED_FROM_STAFF
])

export const Organizations = () => {
  const dispatch = useDispatch()

  const { replace } = useHistory()

  const { isDesktop } = useAdaptiveLayout()

  const { isExact } = useRouteMatch()
  const organizationPageMatch = useRouteMatch<{ id: string }>(ORGANIZATION_CONTENT_PATH)
  const invitationsPageMatch = useRouteMatch(INVITATIONS_PATH)

  const loading = useAppSelector(createLoadingSelector([GET_ORGANIZATIONS]))
  const ids = useAppSelector((state) => state.organizations.data.ids)

  const loaded = useRef(false)

  const { t } = useTranslation()

  const shouldShowNav =
    isDesktop ||
    (!isDesktop && ((organizationPageMatch?.isExact && !invitationsPageMatch?.isExact) || isExact))

  const shouldRedirectToInvitations = !ids.length && !loading && loaded.current
  const shouldRedirectToFirstOrganization =
    !loading &&
    !!ids.length &&
    loaded.current &&
    (isExact ||
      (organizationPageMatch?.params.id && !ids.includes(organizationPageMatch.params.id)))

  useEffect(() => {
    dispatch(getOrganizationsRequest())

    return () => {
      dispatch(resetOrganizations())
    }
  }, [dispatch])

  useEffect(() => {
    loaded.current = true
  }, [loading])

  const handleRedirectFromOrganization = useCallback(() => {
    replace('/organizations')
    toast.warn(t('common.toast.unavailableOrganization'), toastDefaultOptions)
  }, [replace, t])

  useNotificationTracker({
    channel: 'organizations',
    onOccurrence: (payload: INotificationEntity | null) => {
      dispatch(
        getOrganizationsRequest({
          onSuccess:
            payload?.producerId === organizationPageMatch?.params.id
              ? handleRedirectFromOrganization
              : undefined
        })
      )
    },
    notifications: TRACKED_NOTIFICATIONS
  })

  return (
    <UnityHubLayout>
      {shouldShowNav && <OrganizationsNav loading={loading} />}

      <Switch>
        <RoleRouteContainer path={INVITATIONS_PATH}>
          <OrganizationsInvitations />
        </RoleRouteContainer>
        <RoleRouteContainer path={ORGANIZATION_CONTENT_PATH}>
          {shouldRedirectToInvitations && <Redirect push={false} to={INVITATIONS_PATH} />}
          {shouldRedirectToFirstOrganization ? (
            <Redirect push={false} to={generatePath(ORGANIZATION_CONTENT_PATH, { id: ids[0] })} />
          ) : (
            <OrganizationContent loading={loading} />
          )}
        </RoleRouteContainer>
        <RoleRouteContainer>
          {shouldRedirectToFirstOrganization && (
            <Redirect push={false} to={generatePath(ORGANIZATION_CONTENT_PATH, { id: ids[0] })} />
          )}
          {shouldRedirectToInvitations ? (
            <Redirect push={false} to={INVITATIONS_PATH} />
          ) : (
            <EmptyList
              icon={<AccountGroupIcon />}
              text={t('organizations.placeholder_choseOrganization')}
            />
          )}
        </RoleRouteContainer>
      </Switch>
    </UnityHubLayout>
  )
}
