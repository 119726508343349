import { FC, useCallback, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import cls from 'classnames'
import { CaseClassificationIdEnum, CaseTypesEnum, SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  CASE_CREATE,
  createProcessingSelector,
  caseCreateRequest,
  caseCreateError,
  showModalAction,
  useAppSelector,
  useAppDispatch
} from 'store'
import { ErrorModal } from 'App/components'
import { EModalComponents } from 'App/containers'

import styles from './CaseCreateDialog.module.scss'
import { CaseCreateDialogFieldsStep } from './CaseCreateDialogFieldsStep'
import { CaseCreateDialogSubscriptionAlert } from './CaseCreateDialogSubscriptionAlert'

export type TCaseCreateValues = {
  title?: string
  value?: CaseClassificationIdEnum | CaseTypesEnum
}

export type TCaseCreateDialogProps = {
  backModalType?: EModalComponents.CASE_LINK_DIALOG | EModalComponents.LIST_OF_LINKED_CASES
  caseIdToLink?: string
  caseName?: string
  caseHumanReadableId?: string
}

const processingSelector = createProcessingSelector([CASE_CREATE])

export const CaseCreateDialog: FC<TCaseCreateDialogProps> = ({
  caseIdToLink,
  caseHumanReadableId,
  caseName,
  backModalType
}) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector(processingSelector)
  const error = useAppSelector((state) => state.errors[CASE_CREATE])
  const subscriptionId = useAppSelector((state) => state.subscriptions.current?.subscription?.id)

  const shouldShowAlert =
    subscriptionId === SubscriptionIdsEnum.BASIC_PROFESSIONAL ||
    subscriptionId === SubscriptionIdsEnum.BASIC_BUSINESS

  const isLinkedCase = caseName && caseHumanReadableId && caseIdToLink

  const initialFormValues: TCaseCreateValues = useMemo(
    () => ({
      value: CaseClassificationIdEnum.PRIVATE
    }),
    []
  )

  const onSubmit = useCallback(
    ({ value, ...values }: TCaseCreateValues) => {
      const isPrivate = value === CaseClassificationIdEnum.PRIVATE

      dispatch(
        caseCreateRequest({
          ...values,
          caseIdToLink,
          classificationId: isPrivate
            ? CaseClassificationIdEnum.PRIVATE
            : CaseClassificationIdEnum.PROFESSIONAL,
          type: isPrivate ? undefined : (value as CaseTypesEnum)
        })
      )
    },
    [caseIdToLink, dispatch]
  )

  const getOnClickBack = useCallback(() => {
    if (!isLinkedCase) {
      return
    }

    return () => {
      if (backModalType && caseIdToLink) {
        dispatch(
          showModalAction({
            modalType: backModalType,
            modalTitle:
              backModalType === EModalComponents.LIST_OF_LINKED_CASES
                ? t('cases.modal.linkCase.title_list')
                : t('cases.modal.linkCase.title'),
            modalProps: {
              caseId: caseIdToLink
            }
          })
        )
      }
    }
  }, [backModalType, caseIdToLink, dispatch, isLinkedCase, t])

  useEffect(
    () => () => {
      dispatch(caseCreateError(null))
    },
    [dispatch]
  )

  return (
    <div
      className={cls({
        [styles.root]: true,
        // [styles.formStep]: isFormStep,
        [styles.formStep]: true,
        [styles.error]: !!error
      })}
    >
      {isLinkedCase && (
        <div>
          <div className={styles.caseTitle}>{t('cases.modal.createCase.subtitle')}</div>
          <div className={styles.caseName}>{caseName}</div>
        </div>
      )}

      <Form onSubmit={onSubmit} initialValues={initialFormValues}>
        {({ handleSubmit, values }) => {
          const isPrivate = values.value === CaseClassificationIdEnum.PRIVATE

          return (
            <>
              {shouldShowAlert && (
                <CaseCreateDialogSubscriptionAlert
                  className={styles.alert}
                  caseClassificationId={
                    isPrivate
                      ? CaseClassificationIdEnum.PRIVATE
                      : CaseClassificationIdEnum.PROFESSIONAL
                  }
                />
              )}

              <CaseCreateDialogFieldsStep
                handleSubmit={handleSubmit}
                submitLoading={processing}
                onClickBack={getOnClickBack()}
                submitDisabled={shouldShowAlert && !isPrivate}
              />
            </>
          )
        }}
      </Form>

      <ErrorModal error={error} />
    </div>
  )
}
