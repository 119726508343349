import {
  TTrackedNotificationsState,
  TTrackedNotificationsAction
} from './trackedNotifications.types'
import {
  START_NOTIFICATION_TRACKING,
  STOP_NOTIFICATION_TRACKING,
  RECEIVED_NOTIFICATION
} from './trackedNotifications.actionTypes'

const initialState: TTrackedNotificationsState = {}

export const trackedNotificationsReducer = (
  state = initialState,
  action: TTrackedNotificationsAction
): TTrackedNotificationsState => {
  switch (action.type) {
    case START_NOTIFICATION_TRACKING: {
      const { channel, ...rest } = action.payload
      return {
        ...state,
        [channel]: {
          ...rest,
          payload: null,
          isChanged: null
        }
      }
    }
    case STOP_NOTIFICATION_TRACKING:
      return {
        ...state,
        [action.payload.channel]: {
          notifications: null,
          filter: undefined,
          payload: null,
          isChanged: null
        }
      }
    case RECEIVED_NOTIFICATION: {
      const { channel, payload } = action.payload

      return {
        ...state,
        [channel]: {
          ...state[channel],
          payload: payload || null,
          isChanged: (state[channel].isChanged || 1) + 1
        }
      }
    }
    default:
      return state
  }
}
