import { ReactNode, RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react'
import cls from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'

import { BadgeItem } from '../BadgeItem'

import { TBadge } from './BadgeList'
import styles from './BadgeListValue.module.scss'

export type TBadgeListValueProps<T> = {
  value: T[]
  editable?: boolean
  className?: string
  popoverOpener?: ReactNode
  contentRef?: RefObject<HTMLDivElement>
  hideChips?: boolean
  togglePopover?: () => void
  onRemove?: (id: string) => void
}

export const BadgeListValue = <T extends TBadge>({
  value,
  editable,
  className,
  contentRef,
  hideChips,
  onRemove,
  togglePopover
}: TBadgeListValueProps<T>) => {
  const [hiddenItemCount, setHiddenItemCount] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  const countRef = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<HTMLDivElement[]>([])

  itemsRef.current = []

  const calculate = useCallback(() => {
    if (hideChips) {
      setHiddenItemCount(value.length)

      return
    }

    if (ref.current && itemsRef.current) {
      if (itemsRef.current) {
        itemsRef.current.forEach((el) => {
          el.hidden = false
        })
      }

      let i = 0
      let containerWidth = ref.current.offsetWidth

      itemsRef.current.forEach((el) => {
        const width = el.offsetWidth
        const countWidth = countRef.current?.offsetWidth ?? 0

        containerWidth -= width

        if (containerWidth - countWidth < 0) {
          el.hidden = true
          i++
        }
      })

      setHiddenItemCount(i)
    }
  }, [hideChips, value.length])

  useLayoutEffect(() => {
    const element = ref.current

    const resizeObserver = new ResizeObserver(calculate)

    calculate()
    element && resizeObserver.observe(element)

    return () => {
      element && resizeObserver.unobserve(element)
    }
  }, [calculate, value])

  const shouldShowPopover = editable || !!hiddenItemCount
  const shouldShowPlusButton = editable || (!editable && !!hiddenItemCount)

  return (
    <div ref={ref} className={cls(styles.root, className)}>
      <div ref={contentRef} className={styles.holder}>
        <div className={styles.list}>
          {!hideChips &&
            value.map(({ label, id, className: badgeClassName, disabled }, index) => (
              <div
                key={id}
                ref={(node) => {
                  if (node) {
                    itemsRef.current[index] = node
                  }
                }}
              >
                <BadgeItem
                  className={cls(styles.item, badgeClassName)}
                  id={id}
                  title={label}
                  disabled={disabled}
                  onRemove={onRemove}
                />
              </div>
            ))}
        </div>

        {shouldShowPlusButton && (
          <span
            className={styles.badge}
            ref={countRef}
            onClick={shouldShowPopover ? togglePopover : undefined}
          >
            {!hideChips && '+'}
            {!!hiddenItemCount && hiddenItemCount}
          </span>
        )}
      </div>
    </div>
  )
}
