import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'
import {
  REMOVE_ADMIN,
  createProcessingSelector,
  removeAdminRequest,
  useAppDispatch,
  useAppSelector
} from 'store'

type TRemoveOrganizationStaffDialogProps = {
  businessUserId: string
  onSuccess: () => void
}

const processingSelector = createProcessingSelector([REMOVE_ADMIN])

export const RemoveOrganizationStaffDialog = ({
  businessUserId,
  onSuccess
}: TRemoveOrganizationStaffDialogProps) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector)

  const { t } = useTranslation()

  const onClick = () => {
    dispatch(
      removeAdminRequest({
        businessUserId,
        onSuccess,
        onError: onSuccess
      })
    )
  }

  return (
    <ConfirmationModal
      content={t('modal.removeStaffConfirm.content')}
      controls={
        <Button loading={processing} onClick={onClick}>
          {t('modal.removeStaffConfirm.submitButton')}
        </Button>
      }
    />
  )
}
