import { useMutation } from 'services/query'
import { deactivateShowcase } from 'api/showcase'
import { hideModalAction, useAppDispatch } from 'store'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'

import { Button, ConfirmationModal } from '../../../../components'

export type TDeactivateShowcaseConfirmationModalProps = {
  id: string
  onSuccess: () => void
}

export const DeactivateShowcaseConfirmationModal = ({
  id,
  onSuccess
}: TDeactivateShowcaseConfirmationModalProps) => {
  const dispatch = useAppDispatch()

  const { isLoading, mutate } = useMutation({
    mutationKey: ['deactivate-showcase'],
    mutationFn: () => deactivateShowcase(id),
    onSuccess: () => {
      onSuccess()
      toast.success('Showcase deactivated', toastDefaultOptions)
    }
  })

  const handleConfirm = () => {
    mutate()
    dispatch(hideModalAction())
  }

  return (
    <ConfirmationModal
      content="Are you sure you want to deactivate this Showcase? The Showcase will be removed from the Feed but you can repost it later"
      controls={
        <Button onClick={handleConfirm} loading={isLoading}>
          Confirm
        </Button>
      }
    />
  )
}
