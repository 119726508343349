import { useCallback } from 'react'

import { ESorting } from 'enums'
import { useAppDispatch } from 'store'
import { TCommonFileSetSorting } from 'store/files/common'
import { getSortDirection } from 'utils'

export const useColumnSorting = (action: TCommonFileSetSorting) => {
  const dispatch = useAppDispatch()

  return useCallback(
    (key: string, sortName?: string, sortDirection?: ESorting) => {
      const newSortDirection = getSortDirection(key, sortName, sortDirection)

      return () =>
        dispatch(
          action({
            name: key,
            direction: newSortDirection
          })
        )
    },
    [action, dispatch]
  )
}
