import { Skeleton } from 'antd'
import { Fragment, Children } from 'react'

import styles from './SettingsList.module.scss'

export type TSettingsListProps = {
  children: null | JSX.Element

  loading?: boolean
}

export const SettingsList = ({ children, loading = false }: TSettingsListProps) => {
  if (!children) {
    return null
  }

  const settingsListChildren = children.type === Fragment ? children.props.children : children

  return (
    <div className={styles.root}>
      {Children.map(settingsListChildren, (child) => (
        <div className={styles.item}>
          <Skeleton loading={loading} active={true} avatar={false}>
            {child}
          </Skeleton>
        </div>
      ))}
    </div>
  )
}
