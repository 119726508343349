import { Skeleton } from 'antd'
import { SubscriptionIdsEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { SUBSCRIPTION_NAME_MAP } from 'globalConstants'
import { createLoadingSelector, useAppSelector, GET_ACTIVE_SUBSCRIPTIONS } from 'store'
import { formatLongDate, getMapComponent } from 'utils'
import { Tooltip } from 'App/components/common'
import { ReactComponent as StarIcon } from 'assets/icons/Star.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg'

import styles from './ActivePlan.module.scss'

const loadingSelector = createLoadingSelector([GET_ACTIVE_SUBSCRIPTIONS])

type TActivePlanProps = {
  updatable: boolean
  graceOn?: Date

  id?: SubscriptionIdsEnum
}

export const ActivePlan = ({ id, updatable, graceOn }: TActivePlanProps) => {
  const loading = useAppSelector(loadingSelector)

  const { t } = useTranslation()

  const name = getMapComponent(SUBSCRIPTION_NAME_MAP, id)

  return name ? (
    <Skeleton
      active={true}
      loading={loading}
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
      className={styles.skeleton}
    >
      <div className={styles.root}>
        <div className={styles.plan}>
          <StarIcon className={styles.icon} />
          {t('subscriptions.planLabel', { plan: name })}
        </div>
        {updatable ? (
          <span className={styles.link}>{t('subscriptions.activePlan.upgradeButton')}</span>
        ) : (
          !!graceOn && (
            <Tooltip
              title={t('subscriptions.activePlan.gracePeriodTooltip', {
                date: formatLongDate(graceOn)
              })}
            >
              <WarningIcon className={cls(styles.icon, styles.warning)} />
            </Tooltip>
          )
        )}
      </div>
    </Skeleton>
  ) : null
}
