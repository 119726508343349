import { Pagination, PAGINATION_DEFAULT_PAGE } from 'types'

import { TTrashBinAction, TTrashBinState } from './trashBin.types'
import * as actionTypes from './trashBin.actionTypes'

export const TRASH_BIN_DEFAULT_PAGINATION: Pagination = {
  current: PAGINATION_DEFAULT_PAGE,
  total: 0,
  showBy: 20
}

export const initialTrashBinState: TTrashBinState = {
  ids: [],
  list: {},
  search: '',
  filters: {
    pagination: TRASH_BIN_DEFAULT_PAGINATION
  },
  selectedFileId: null
}

export const trashBinReducer = (
  state: TTrashBinState = initialTrashBinState,
  action: TTrashBinAction
): TTrashBinState => {
  switch (action.type) {
    case actionTypes.GET_TRASH_BIN_LIST_SUCCESS: {
      const { total, list, ids } = action.payload
      return {
        ...state,
        list,
        ids,
        selectedFileId: null,
        filters: {
          ...state.filters,
          pagination: { ...state.filters.pagination, total }
        }
      }
    }

    case actionTypes.SET_TRASH_BIN_SEARCH: {
      return {
        ...state,
        search: action.payload.search
      }
    }

    case actionTypes.SET_TRASH_BIN_PAGINATION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          pagination: action.payload
        }
      }
    }

    case actionTypes.SET_TRASH_BIN_SORTING: {
      return {
        ...state,
        filters: {
          ...state.filters,
          sorting: action.payload
        }
      }
    }

    case actionTypes.RESET_TRASH_BIN: {
      return {
        ...initialTrashBinState
      }
    }

    case actionTypes.SET_SELECTED_TRASH_BIN_FILE_ID: {
      return {
        ...state,
        selectedFileId: action.payload
      }
    }

    default:
      return state
  }
}
