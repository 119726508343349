import { ControlProps, components, GroupBase } from 'react-select'
import cls from 'classnames'

import styles from './Control.module.scss'

export const Control = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: ControlProps<Option, IsMulti, Group>
) => {
  const {
    isFocused,
    hasValue,
    isMulti,
    selectProps: { label, invalid },
    children
  } = props

  return (
    <components.Control
      {...props}
      className={cls(styles.root, {
        [styles.focused]: isFocused,
        [styles.selected]: hasValue,
        [styles.isMulti]: isMulti,
        [styles.invalid]: invalid
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.baseBorder}>{children}</div>
    </components.Control>
  )
}
