import { AccountTypeNames, CompanySizeType } from '@medentee/enums'

import {
  EDIT_USER_PROFILE_ON_THE_WEB_SUCCESS,
  GET_AVAILABLE_PROFESSIONS_SUCCESS,
  GET_USER_PROFILE_BASIC_INFORMATION_SUCCESS,
  GET_USER_PROFILE_BY_ID_SUCCESS,
  SET_USER_PROFILE_ON_THE_WEB_EDIT_STATE,
  SET_USER_PROFILE_ON_THE_WEB_IDS,
  SET_USER_PROFILE_ON_THE_WEB_LIST,
  SET_USER_PROFILE_ON_THE_WEB_URL,
  SET_USER_PROFILE_PROFESSIONS_IDS,
  SET_USER_PROFILE_PROFESSIONS_LIST,
  SET_USER_PROFILE_PROFESSIONS_NEW_IDS,
  SET_USER_PROFILE_WEBSITE_PROCESSING,
  TAction,
  TEditUserProfileOnTheWebSuccess,
  TGetAvailableProfessionsSuccess,
  TGetUserProfileBasicInformationSuccess,
  TGetUserProfileByIdSuccess,
  TSetUserProfileOnTheWebEditState,
  TSetUserProfileOnTheWebIds,
  TSetUserProfileOnTheWebList,
  TSetUserProfileOnTheWebUrl,
  TSetUserProfileProfessionsIds,
  TSetUserProfileProfessionsList,
  TSetUserProfileProfessionsNewIds,
  TSetUserProfileWebsiteProcessing,
  TUserProfileTypes
} from 'store'

import {
  GET_EDUCATION_SUCCESS,
  GET_LICENSES_SUCCESS,
  GET_PUBLICATIONS_SUCCESS,
  GET_RELATED_ACCOUNTS_SUCCESS,
  GET_WORK_EXPERIENCE_SUCCESS,
  RESET_USER_PROFILE_VIEW
} from './userProfile.actions'
import {
  TGetEducationSuccess,
  TGetLicensesSuccess,
  TGetPublicationsSuccess,
  TGetRelatedAccountsSuccess,
  TGetWorkExperienceSuccess
} from './userProfile.types'

export const initialUserProfileState: TUserProfileTypes = {
  basicInformation: {
    type: AccountTypeNames.PROFESSIONAL,
    companyName: '',
    firstName: '',
    lastName: '',
    displayUserName: '',
    email: '',
    country: {
      countryName: '',
      code: ''
    },
    city: {
      id: '',
      cityName: '',
      countryCode: '',
      timezone: ''
    },
    about: '',
    headline: '',
    addressLine1: '',
    addressLine2: '',
    zip: '',
    size: CompanySizeType.UP_TO_5,
    dateOfBirth: null,
    sex: null
  },
  onTheWeb: {
    ids: [],
    websiteList: {}
  },
  viewProfile: {
    id: '',
    type: AccountTypeNames.PROFESSIONAL,
    displayUserName: '',
    firstName: '',
    lastName: '',
    headline: '',
    about: '',
    country: '',
    city: '',
    timezone: '',
    facebook: '',
    linkedIn: '',
    twitter: '',
    websites: [],
    professions: [],
    chatId: '',
    companyInfo: null,
    workExperience: {
      ids: [],
      list: {}
    },
    education: {
      ids: [],
      list: {}
    },
    licenses: {
      ids: [],
      list: {}
    },
    publications: {
      ids: [],
      list: {}
    },
    isContact: false,
    isCoworker: false,
    contact: undefined
  },
  professions: {
    ids: [],
    professionsList: {},
    newIds: [],
    availableProfessions: []
  },
  relatedAccounts: {
    ids: [],
    list: {},
    notifications: {
      total: 0,
      accounts: {}
    }
  }
}

export const userProfileReducer = (
  state = initialUserProfileState,
  action: TAction
): TUserProfileTypes => {
  switch (action.type) {
    case GET_USER_PROFILE_BASIC_INFORMATION_SUCCESS: {
      return {
        ...state,
        basicInformation: (action as TGetUserProfileBasicInformationSuccess).payload
      }
    }

    case SET_USER_PROFILE_ON_THE_WEB_IDS: {
      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          ids: (action as TSetUserProfileOnTheWebIds).payload
        }
      }
    }

    case SET_USER_PROFILE_ON_THE_WEB_LIST: {
      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          websiteList: (action as TSetUserProfileOnTheWebList).payload
        }
      }
    }

    case SET_USER_PROFILE_ON_THE_WEB_EDIT_STATE: {
      const { id, editState } = (action as TSetUserProfileOnTheWebEditState).payload

      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          websiteList: {
            ...state.onTheWeb.websiteList,
            [id]: {
              ...state.onTheWeb.websiteList[id],
              editState
            }
          }
        }
      }
    }

    case SET_USER_PROFILE_ON_THE_WEB_URL: {
      const { id, url } = (action as TSetUserProfileOnTheWebUrl).payload

      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          websiteList: {
            ...state.onTheWeb.websiteList,
            [id]: {
              ...state.onTheWeb.websiteList[id],
              url
            }
          }
        }
      }
    }

    case GET_USER_PROFILE_BY_ID_SUCCESS: {
      return {
        ...state,
        viewProfile: (action as TGetUserProfileByIdSuccess).payload
      }
    }

    case RESET_USER_PROFILE_VIEW: {
      return {
        ...state,
        viewProfile: initialUserProfileState.viewProfile
      }
    }

    case SET_USER_PROFILE_PROFESSIONS_IDS: {
      return {
        ...state,
        professions: {
          ...state.professions,
          ids: (action as TSetUserProfileProfessionsIds).payload
        }
      }
    }

    case SET_USER_PROFILE_PROFESSIONS_NEW_IDS: {
      return {
        ...state,
        professions: {
          ...state.professions,
          newIds: (action as TSetUserProfileProfessionsNewIds).payload
        }
      }
    }

    case SET_USER_PROFILE_PROFESSIONS_LIST: {
      return {
        ...state,
        professions: {
          ...state.professions,
          professionsList: (action as TSetUserProfileProfessionsList).payload
        }
      }
    }

    case GET_AVAILABLE_PROFESSIONS_SUCCESS: {
      return {
        ...state,
        professions: {
          ...state.professions,
          availableProfessions: (action as TGetAvailableProfessionsSuccess).payload
        }
      }
    }

    case SET_USER_PROFILE_WEBSITE_PROCESSING: {
      const { id, processing } = (action as TSetUserProfileWebsiteProcessing).payload

      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          websiteList: {
            ...state.onTheWeb.websiteList,
            [id]: {
              ...state.onTheWeb.websiteList[id],
              processing
            }
          }
        }
      }
    }

    case GET_RELATED_ACCOUNTS_SUCCESS: {
      const relatedAccounts = (action as TGetRelatedAccountsSuccess).payload

      return {
        ...state,
        relatedAccounts
      }
    }

    case EDIT_USER_PROFILE_ON_THE_WEB_SUCCESS: {
      const { ids, websiteList } = (action as TEditUserProfileOnTheWebSuccess).payload

      return {
        ...state,
        onTheWeb: {
          ...state.onTheWeb,
          ids,
          websiteList
        }
      }
    }

    case GET_WORK_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        viewProfile: {
          ...state.viewProfile,
          workExperience: (action as TGetWorkExperienceSuccess).payload
        }
      }
    }

    case GET_EDUCATION_SUCCESS: {
      return {
        ...state,
        viewProfile: {
          ...state.viewProfile,
          education: (action as TGetEducationSuccess).payload
        }
      }
    }

    case GET_LICENSES_SUCCESS: {
      return {
        ...state,
        viewProfile: {
          ...state.viewProfile,
          licenses: (action as TGetLicensesSuccess).payload
        }
      }
    }

    case GET_PUBLICATIONS_SUCCESS: {
      return {
        ...state,
        viewProfile: {
          ...state.viewProfile,
          publications: (action as TGetPublicationsSuccess).payload
        }
      }
    }

    default: {
      return state
    }
  }
}
