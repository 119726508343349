import { useTranslation } from 'react-i18next'

import { formatBytes, formatShortDate } from 'utils'
import { FileDetailsItem } from 'App/components'

import styles from './Details.module.scss'

export type TDetailsProps = {
  fileSize?: string
  createdAt?: string
  updatedAt?: string
}

const Details = ({ fileSize, createdAt, updatedAt }: TDetailsProps) => {
  const { t } = useTranslation()

  return (
    <ul className={styles.root}>
      <FileDetailsItem
        label={t('cases.details.attachments.fileDetails.sizeLabel')}
        value={formatBytes(fileSize)}
      />
      {createdAt && (
        <FileDetailsItem
          label={t('cases.details.attachments.fileDetails.uploadedLabel')}
          value={formatShortDate(createdAt)}
        />
      )}
      {updatedAt && (
        <FileDetailsItem
          label={t('cases.details.attachments.fileDetails.modifiedLabel')}
          value={formatShortDate(updatedAt)}
        />
      )}
    </ul>
  )
}

export default Details
