import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { useAppSelector } from 'store'
import { getEvents, TGetEventsPayload } from 'api/events'
import {
  UnityHubChannelIcon,
  UnityHubRoomNavLink,
  UnityHubRoomsNavCollapse
} from 'App/components/UnityHub'
import { EventsCreateButton } from 'App/components/Events/EventsCreateButton'
import { Skeleton } from 'App/components/common/Skeleton'

export type TUnityHubRoomsNavEventsListProps = TGetEventsPayload

export const UnityHubRoomsNavEventsList = ({
  communityId,
  organizationId
}: TUnityHubRoomsNavEventsListProps) => {
  const accountId = useAppSelector((state) => state.global.accountData?.id)

  const { data, isFetching } = useQuery({
    queryKey: ['available-events', communityId, organizationId],
    queryFn: () => getEvents({ communityId, organizationId } as TGetEventsPayload)
  })

  const { t } = useTranslation()

  if (isFetching) {
    return <Skeleton />
  }

  return (
    <UnityHubRoomsNavCollapse
      variant="broadcast"
      title={t('unityHub.channelsList.eventsLabel')}
      localStorageKey={`${organizationId ? 'organization' : 'community'}-events-${
        communityId || organizationId
      }`}
      action={<EventsCreateButton communityId={communityId} organizationId={organizationId} />}
    >
      {data?.results?.map(
        ({ id, title, organizerId, isEventMember, visibleInCommunity, visibleInOrganization }) => {
          const isOrganizer = accountId === organizerId
          const isInvisible =
            isOrganizer &&
            ((!!organizationId && !visibleInOrganization) || (!!communityId && !visibleInCommunity))

          return (
            <UnityHubRoomNavLink
              key={id}
              name={title}
              to={
                isOrganizer || isEventMember
                  ? `/events/${id}/highlights/info`
                  : `/events/${id}/p/info`
              }
              icon={<UnityHubChannelIcon variant="events" isInvisible={isInvisible} />}
              isInvisible={isInvisible}
            />
          )
        }
      )}
    </UnityHubRoomsNavCollapse>
  )
}
