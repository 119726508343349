import cls from 'classnames'
import { CallStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TChatHistoryMessage } from 'store'
import { TJoinToCallContainerProps } from 'App/containers'

import styles from './JoinToCall.module.scss'

export type TJoinToCallBase = {
  message: TChatHistoryMessage
}

export type TJoinToCallProps = TJoinToCallBase & TJoinToCallContainerProps

export const JoinToCall = ({ message, joinToCall }: TJoinToCallProps) => {
  const { t } = useTranslation()

  const { yourMessage, call } = message

  const handleJoinToCall = () => {
    if (call?.id) {
      joinToCall({ callId: call.id, processingId: call.id })
    }
  }

  const isCallActive =
    call?.status === CallStatusEnum.ACTIVE || call?.status === CallStatusEnum.WAITING

  if (call?.endTime || !isCallActive) {
    return null
  }

  return (
    <button
      type="button"
      className={cls({
        [styles.root]: true,
        [styles.you]: yourMessage
      })}
      onClick={handleJoinToCall}
    >
      {t('chat.call.joinCallButton')}
    </button>
  )
}
