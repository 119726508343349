import { useRouteMatch } from 'react-router'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SettingsPageLayout } from 'App/components/common/SettingsPageLayout'
import { TNavMenuItem } from 'App/components/common/NavMenu'
import { communitySettingsNestedRoutes } from 'App/App.config'
import { UnityHubBackButton } from 'App/components/UnityHub/UnityHubBackButton'

import styles from './CommunitySettings.module.scss'

export const CommunitySettings = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const { t } = useTranslation()

  const communityId = params.id

  const defaultPath = `/community/${communityId}/edit/basic`

  const menuList = useMemo<TNavMenuItem[]>(
    () => [
      {
        link: `/community/${communityId}/edit/basic`,
        title: t('communities.settings.basicInformation')
      },
      {
        link: `/community/${communityId}/edit/branding`,
        title: t('communities.settings.branding')
      },
      {
        link: `/community/${communityId}/edit/organizer-settings`,
        title: t('communities.settings.organizerSettings')
      }
    ],
    [communityId, t]
  )

  if (!communityId) {
    return null
  }

  return (
    <div className={styles.root}>
      <UnityHubBackButton path="/community" className={styles.backButton} />
      <SettingsPageLayout
        menu={{
          menuList,
          classes: { root: styles.menu }
        }}
        defaultPath={defaultPath}
        nestedRoutes={communitySettingsNestedRoutes}
        paper={true}
      />
    </div>
  )
}
