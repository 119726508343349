import { ReactNode, useMemo, useState } from 'react'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { hideModalAction, useAppDispatch } from 'store'
import { TChipInputItemValue } from 'App/components/common/ChipInput/ChipInputItem'

import { ChipInput, StepsFooterActions } from '../../../components'
import { useIsMounted, useToggle } from '../../../hooks'
import { validateEmail } from '../../../../utils/validation/composed'

import styles from './InviteByEmail.module.scss'

export type TInviteByEmailProps = {
  onConfirm: (emails: string[]) => Promise<any>

  description?: ReactNode
  defaultEmails?: TChipInputItemValue[]
  content?: ReactNode
  confirmLabel?: ReactNode
  backLabel?: ReactNode
  disabled?: boolean
  autoFocus?: boolean
  onBack?: () => void
}

export const InviteByEmail = ({
  content,
  disabled,
  autoFocus,
  backLabel,
  confirmLabel,
  onConfirm,
  onBack,
  description = '',
  defaultEmails = []
}: TInviteByEmailProps) => {
  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const [valid, setValid] = useState(false)
  const [emails, setEmails] = useState<TChipInputItemValue[]>(defaultEmails)
  const { value: processing, setValue: setProcessing } = useToggle()

  const { t } = useTranslation()

  const isValid = valid && emails.length > 0

  const notDefaultEmails = useMemo(
    () =>
      emails?.filter(
        (item, itemIndex) =>
          !defaultEmails.find(
            (value, valueIndex) => item.text === value.text && itemIndex === valueIndex
          )
      ),
    [defaultEmails, emails]
  )

  const handleClick = () => {
    const result = onConfirm(emails.map(({ text }) => text))

    setProcessing(true)
    result
      .then(() => {
        dispatch(hideModalAction())
      })
      .finally(() => {
        if (isMounted.current) {
          setProcessing(false)
        }
      })
      .catch(noop)
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <p>
          {description} {t('modal.inviteByEmail.content')}
        </p>
        {content && <div className={styles.customContent}>{content}</div>}
        <ChipInput
          autoFocus={autoFocus}
          label={t('modal.inviteByEmail.emailsField')}
          value={emails}
          onChange={setEmails}
          onValidate={setValid}
          validate={(value) => validateEmail(value.text)}
        />
      </div>
      <StepsFooterActions
        submitDisabled={disabled || !isValid || !notDefaultEmails.length}
        submitLoading={processing}
        backLabel={backLabel ?? t('modal.inviteByEmail.backButton')}
        submitLabel={confirmLabel ?? t('modal.inviteByEmail.submitButton')}
        onClickBack={onBack}
        onSubmit={handleClick}
      />
    </div>
  )
}
