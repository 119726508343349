import { useTranslation } from 'react-i18next'

import {
  UnityHubCreateChannelsMainStep,
  TUnityHubCreateChannelsMainStepProps
} from 'App/components/UnityHub'

import {
  useEventCreateChannelMainStepRequest,
  TUseEventCreateChannelMainStepRequest
} from './useEventCreateChannelMainStepRequest'

export type TEventCreateChannelMainStepProps = Pick<
  TUnityHubCreateChannelsMainStepProps,
  | 'channelManager'
  | 'isEdit'
  | 'loading'
  | 'name'
  | 'setName'
  | 'onOpenMembers'
  | 'channelMembers'
  | 'onOpenChanelManager'
  | 'dirty'
  | 'isPublic'
  | 'setIsPublic'
  | 'initialValues'
> &
  Pick<TUseEventCreateChannelMainStepRequest, 'variant' | 'eventId' | 'channelId' | 'onEditSuccess'>

export const EventCreateChannelMainStep = ({
  channelMembers,
  eventId,
  channelManager,
  channelId,
  name,
  isEdit,
  variant,
  loading,
  dirty,
  isPublic,
  setIsPublic,
  setName,
  onOpenChanelManager,
  onOpenMembers,
  onEditSuccess,
  initialValues
}: TEventCreateChannelMainStepProps) => {
  const { t } = useTranslation()

  const { error, isFieldError, processing, isBusinessAccount, handleFormSubmit } =
    useEventCreateChannelMainStepRequest({
      eventId,
      channelId,
      channelMembers,
      channelManager,
      isEdit,
      variant,
      onEditSuccess
    })

  const shouldShowAlert = variant === 'news' && !isEdit

  return (
    <UnityHubCreateChannelsMainStep
      dirty={dirty}
      isEdit={isEdit}
      name={name}
      channelMembers={channelMembers}
      channelManager={channelManager}
      showAlert={shouldShowAlert}
      alert={t('modal.createChannel.alert_news')}
      setName={setName}
      onFormSubmit={handleFormSubmit}
      onOpenChanelManager={onOpenChanelManager}
      onOpenMembers={onOpenMembers}
      loading={loading}
      isFieldError={isFieldError}
      error={error}
      processing={processing}
      selectManagerDisabled={!isBusinessAccount}
      isPublic={isPublic}
      setIsPublic={setIsPublic}
      initialValues={initialValues}
    />
  )
}
