import { State } from 'redux/rootReducer'

export const contactsEmptyAfterFilterSelector = (state: State): boolean => {
  const { notAssigned, online, search, selectedAccountTypes, selectedCategories } =
    state.contacts.filters

  return (
    !state.contacts.ids.length &&
    (notAssigned ||
      online.length > 0 ||
      search.length > 0 ||
      selectedAccountTypes.length > 0 ||
      selectedCategories.length > 0)
  )
}
