import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { createProcessingSelector, CASE_LOCK, caseLockRequest, caseLockError } from 'store'
import { State } from 'redux/rootReducer'
import { Button, ConfirmationModal } from 'App/components'

import styles from './CaseLockConfirm.module.scss'

export type TCaseLockConfirmBaseProps = {
  caseId: string
}

export type TCaseLockConfirmProps = TCaseLockConfirmBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseLockConfirmComponent: FC<TCaseLockConfirmProps> = ({
  processing,
  caseLock,
  caseId,
  error,
  setCaseLockError
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={t('cases.modal.lockCaseConfirm.content')}
      controls={
        <Button
          className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
          loading={processing}
          onClick={() => caseLock({ caseId })}
        >
          {t('cases.modal.lockCaseConfirm.submitButton')}
        </Button>
      }
      error={error}
      resetError={() => setCaseLockError(null)}
    />
  )
}

const processingSelector = createProcessingSelector([CASE_LOCK])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.caseView.lock.error
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      caseLock: caseLockRequest,
      setCaseLockError: caseLockError
    },
    dispatch
  )

export const CaseLockConfirm: FC<TCaseLockConfirmBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseLockConfirmComponent)
