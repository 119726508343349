import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseCloudFilesItem, TCaseCloudFilesItemBaseProps } from 'App/components'
import { createSlidesEntity } from 'store'

const mapStateToProps = (state: State, { id }: TCaseCloudFilesItemBaseProps) => {
  const { ids, list } = state.caseCloud.uploadedByMeFiles
  const { fileName, fileSize, extension, mimeType, casesPermissions, thumbnailUrl } = list[id] || {}

  return {
    id,
    fileName,
    fileSize,
    extension,
    mimeType,
    casesPermissions,
    accountId: state.global.accountData?.id,
    thumbnailUrl,
    videoMetadata: state.videoStreaming.list[id]?.videoMetadata,
    slidesEntity: createSlidesEntity(ids, list),
    members: state.caseMembers.members
  }
}

export type TCaseCloudUploadedByMeFilesItemContainer = ReturnType<typeof mapStateToProps>

export const CaseCloudUploadedByMeFilesItemContainer = connect(mapStateToProps)(CaseCloudFilesItem)
