import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Drawer } from 'App/components/common'
import { HeaderContainer, TInfoDrawerContainerProps } from 'App/containers/Organization'

import { List } from './Lists/List'
import styles from './InfoDrawer.module.scss'

type TInfoDrawerProps = TInfoDrawerContainerProps & {
  isStaff: boolean
  businessChatId: string
}

export const InfoDrawer = ({
  visible,
  organizationId,
  isStaff,
  businessChatId,
  onClose
}: TInfoDrawerProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleButtonClick = () => {
    if (isStaff) {
      push(`/chat/dialog/${businessChatId}`)
      return
    }

    push(`/profile/${organizationId}`)
  }

  return (
    <Drawer
      className={styles.root}
      title={t('organizations.infoDrawer.title')}
      visible={visible}
      onClose={onClose}
      footerActions={
        <div className={styles.footer}>
          <Button onClick={handleButtonClick}>
            {isStaff
              ? t('organizations.infoDrawer.chatWithAdminButton')
              : t('organizations.infoDrawer.goToBusinessProfileButton')}
          </Button>
        </div>
      }
    >
      <div className={styles.wrapper}>
        <HeaderContainer organizationId={organizationId} />

        <List organizationId={organizationId} isStaff={isStaff} />
      </div>
    </Drawer>
  )
}
