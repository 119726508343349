import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import {
  getFilesCountsRequest,
  getFilesSharedCountsRequest,
  createLoadingSelector,
  GET_FILE_SHARED_LIST,
  GET_FILE_LIST,
  filesResetAction,
  resetFileSharedListAction,
  GET_FILES_COUNTS,
  GET_FILES_SHARED_COUNTS
} from 'store'
import { ChatFiles, TChatFilesBaseProps } from 'App/components'

export type TChatFilesContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([
  GET_FILE_SHARED_LIST,
  GET_FILE_LIST,
  GET_FILES_COUNTS,
  GET_FILES_SHARED_COUNTS
])

const mapStateToProps = (state: State, { caseId }: TChatFilesBaseProps) => {
  let membersTotal = undefined

  if (caseId) {
    membersTotal = state.chat.chatRooms.selectedChatInfo?.allUsers.size
  }

  const accountData = state.global.accountData
  const chatType = state.chat.chatRooms.selectedChat?.type
  const channelManager = state.chat.chatRooms.selectedChat?.channelManager

  return {
    interlocutorAccountId: state.chat.chatRooms.selectedChat?.interlocutorAccount?.id,
    accountId: state.global.accountData?.id,
    chatId: state.chat.chatRooms.selectedChat?.id,
    chatType,
    filesTotal: state.file.countsTotal,
    filesSharedTotal: state.fileShared.countsTotal,
    loading: loadingSelector(state),
    membersTotal,
    channelManager,
    isChannelManager: accountData?.id === channelManager?.id
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFilesCounts: getFilesCountsRequest,
      getFilesSharedCounts: getFilesSharedCountsRequest,
      resetFileSharedList: resetFileSharedListAction,
      filesReset: filesResetAction
    },
    dispatch
  )

export const ChatFilesContainer = connect(mapStateToProps, mapDispatchToProps)(ChatFiles)
