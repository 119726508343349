import { action } from 'store/store.utils'
import { TSendInviteToContactPayload } from 'store/inviteContacts/inviteContacts.types'
import { TError } from 'store/store.types'
import { TPluralProcessingPayload } from 'store/loading'

import * as actionTypes from './inviteContacts.actionTypes'

// ------------------------- SEND_INVITE_TO_CONTACT -------------------------

export const sendInviteToContactRequest = (payload: TSendInviteToContactPayload) =>
  action(actionTypes.SEND_INVITE_TO_CONTACT_REQUEST, payload)

export const sendInviteToContactSuccess = (payload: TPluralProcessingPayload) =>
  action(actionTypes.SEND_INVITE_TO_CONTACT_SUCCESS, payload)

export const sendInviteToContactError = (payload: TPluralProcessingPayload & TError) =>
  action(actionTypes.SEND_INVITE_TO_CONTACT_ERROR, payload)
