import { ReactNode } from 'react'
import cls from 'classnames'

import { useChatContext } from 'App/context'
import { EChatViewType } from 'enums'

import styles from './ChatMessagePlaceholder.module.scss'

export type TChatMessagePlaceholderProps = {
  icon: ReactNode
  label: string
  isMessageOutgoing: boolean

  classes?: Partial<Record<TChatMessagePlaceholderClasses, string>>
}

export type TChatMessagePlaceholderClasses = 'placeholder' | 'placeholderIcon' | 'placeholderText'

export const ChatMessagePlaceholder = ({
  icon,
  label,
  isMessageOutgoing,
  classes
}: TChatMessagePlaceholderProps) => {
  const { chatViewType } = useChatContext()

  const isCaseChat = chatViewType === EChatViewType.CASE

  return (
    <div
      className={cls(styles.placeholder, classes?.placeholder, {
        [styles.outgoingMessage]: isMessageOutgoing
      })}
    >
      <div
        className={cls(
          styles.placeholderIcon,
          isCaseChat && styles.placeholderIconCase,
          classes?.placeholderIcon
        )}
      >
        {icon}
      </div>
      <span
        className={cls(
          styles.placeholderText,
          isCaseChat && styles.placeholderTextCase,
          classes?.placeholderText
        )}
      >
        {label}
      </span>
    </div>
  )
}
