import { put, takeLatest, call } from 'redux-saga/effects'

import { IShowcaseCommentCounts } from 'interfaces'
import { API, api, APIData } from 'services/api'

import * as actionTypes from './showcases.actionTypes'
import * as actions from './showcases.actions'
import { commentCountersNormalize } from './showcases.normalization'

function* getShowcaseCommentCountersSaga({
  payload: { showcaseId }
}: ReturnType<typeof actions.getShowcaseCommentCountersRequest>) {
  try {
    const { data }: APIData<IShowcaseCommentCounts[]> = yield call(
      api.get,
      API.SHOWCASES_COMMENT_COUNTS(showcaseId)
    )

    const { list, ids } = commentCountersNormalize(data)

    let total = 0

    ids.forEach((id) => {
      total += Number(list[id]?.commentCounter ?? '0')
    })

    yield put(actions.getShowcaseCommentCountersSuccess({ showcaseId, total, list, ids }))
  } catch (e) {
    yield put(actions.getShowcaseCommentCountersError(e))
  }
}

export function* showcasesSaga() {
  yield takeLatest(
    actionTypes.GET_SHOWCASE_COMMENT_COUNTERS_REQUEST,
    getShowcaseCommentCountersSaga
  )
}
