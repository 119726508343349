import { Field, FieldRenderProps, Form, useForm, useFormState } from 'react-final-form'
import { Row, Col } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { validation } from 'utils/validation'
import { Button, Select } from 'App/components/common'
import { TextFieldSkeleton } from 'App/components/common/Skeletons/TextFieldSkeleton'
import { editEvent, getEvent } from 'api/events'
import { TEventInterest } from 'interfaces'
import { MAX_LENGTH_50, toastDefaultOptions } from 'globalConstants'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'

import styles from './EventAttendeesInterests.module.scss'

const EVENT_QUERY_KEY = 'get-event'

type TEventAttendeesInterestsProps = {
  eventId: string
}

type TEventInterestsFormValues = {
  interests: TEventInterest[]
}

const SELECT_ALL_ID = 'select_all'

type TSpyOnFormChangedProps = {
  prompt: JSX.Element
  defaultOption: TEventInterest[]
  onChange: (value: boolean) => void
}

const SpyOnFormChanged = ({ onChange, prompt, defaultOption }: TSpyOnFormChangedProps) => {
  const {
    pristine,
    values: { interests }
  } = useFormState<Partial<TEventInterestsFormValues>>()
  const { change } = useForm<TEventInterestsFormValues>()

  useEffect(() => {
    const hasSelectAllOption = interests?.some(({ id }) => id === SELECT_ALL_ID)

    if (hasSelectAllOption) {
      const filteredValue = interests?.filter(({ id }) => id !== SELECT_ALL_ID) ?? []
      const newValue = uniqBy([...defaultOption, ...filteredValue], 'id')

      change('interests', newValue)
    }
  }, [change, defaultOption, interests])

  useEffect(() => {
    onChange(!pristine)
  }, [pristine, onChange])

  return prompt
}

export const EventAttendeesInterests = ({ eventId }: TEventAttendeesInterestsProps) => {
  const [initialValues, setInitialValues] = useState<TEventInterestsFormValues>()

  const { t } = useTranslation()

  const { ignore, prompt, setFormChanged } = useUnsavedDataPrompt()

  const { data, isFetching } = useQuery({
    queryKey: [EVENT_QUERY_KEY],
    queryFn: () => getEvent(eventId),
    enabled: Boolean(eventId),
    onSuccess: ({ interests }) => {
      const enabledInterests = interests.filter((interest) => interest.enabled)

      setInitialValues({ interests: enabledInterests })
    }
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: editEvent,
    onSuccess: () => {
      toast.success(t('common.toast.updatesSaved'), toastDefaultOptions)
    }
  })

  const onSubmit = useCallback(
    ({ interests }: TEventInterestsFormValues) => {
      const formattedInterests = interests.map(({ name }) => name)

      ignore()

      return mutate({
        id: eventId,
        payload: {
          interests: formattedInterests
        }
      })
    },
    [eventId, ignore, mutate]
  )

  const getNewOptionData = useCallback(
    (inputValue: string): TEventInterest => ({
      id: inputValue,
      name: inputValue,
      enabled: true,
      default: false
    }),
    []
  )

  const selectAllOption: TEventInterest = {
    id: SELECT_ALL_ID,
    name: t('events.attendeesInterests.selectAllLabel'),
    enabled: false,
    default: false
  }

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>{t('events.attendeesInterests.title')}</h3>
      <p className={styles.description}>{t('events.attendeesInterests.description')}</p>

      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting, valid, dirty, dirtySinceLastSubmit, submitSucceeded }) => (
          <Row gutter={[0, 50]} justify="center">
            <SpyOnFormChanged
              onChange={setFormChanged}
              prompt={prompt}
              defaultOption={data?.interests ?? []}
            />

            <Col xs={24} className={styles.selector}>
              <TextFieldSkeleton loading={isFetching}>
                <Field
                  name="interests"
                  validate={validation.composeValidators(
                    validation.required(),
                    validation.onlySpaces(),
                    validation.maxLength(MAX_LENGTH_50)
                  )}
                >
                  {({ input, meta }: FieldRenderProps<TEventInterest[]>) => (
                    <Select
                      {...input}
                      topLabel={t('events.attendeesInterests.selectLabel')}
                      valueKey="id"
                      labelKey="name"
                      options={[selectAllOption, ...(data?.interests ?? [])]}
                      invalid={meta.touched && meta.invalid}
                      error={meta.error}
                      isMulti={true}
                      isCreatable={true}
                      isLoading={isFetching}
                      showLimitCounter={true}
                      limit={MAX_LENGTH_50}
                      maxLength={MAX_LENGTH_50}
                      menuPlacement="bottom"
                      getNewOptionData={getNewOptionData}
                    />
                  )}
                </Field>
              </TextFieldSkeleton>
            </Col>
            <Col>
              <Button
                onClick={handleSubmit}
                disabled={!valid || !dirty || (submitSucceeded && !dirtySinceLastSubmit)}
                loading={submitting || isLoading}
              >
                {t('events.attendeesInterests.submitButton')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}
