import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SingleCascaderProps, DefaultOptionType } from 'rc-cascader/lib'
import { Cascader, CascaderProps } from 'antd'
import cls from 'classnames'

import { Search, TSearchProps } from 'App/components'
import { ReactComponent as ArrowIcon } from 'assets/icons/ChevronDown.svg'

import styles from './SearchCascader.module.scss'

export type TCascaderOption = Partial<DefaultOptionType>

export type TSearchCascaderProps = Omit<TSearchProps, 'onChange' | 'value'> & {
  options: TCascaderOption[]
  onSearchChange: (search: string, searchBy: (number | string)[]) => void

  onCascaderChange?: (search: string, searchBy: (number | string)[]) => void
  placeholder?: string
  search?: string
  loadData?: (selectOptions: TCascaderOption[]) => void
  value?: CascaderProps<TCascaderOption>['value']
  triggerInitialOnChange?: boolean
}

type THandleCascaderChange = SingleCascaderProps<TCascaderOption>['onChange']

export const SearchCascader: FC<TSearchCascaderProps> = ({
  options,
  searchSize,
  classes,
  value,
  search,
  triggerInitialOnChange,
  onCascaderChange,
  onSearchChange,
  loadData,
  ...rest
}) => {
  const defaultCascaderValue = useMemo<(string | number)[]>(
    () => [options[0]?.value ?? ''],
    [options]
  )

  const [cascaderValue, setCascaderValue] = useState<(string | number)[]>(defaultCascaderValue)
  const [searchValue, setSearchValue] = useState<string>(search ?? '')

  const handleChange = (currentValue: string) => {
    setSearchValue(currentValue)

    onSearchChange(currentValue, cascaderValue)
  }

  const handleClear = () => {
    setSearchValue('')

    onSearchChange('', defaultCascaderValue)
  }

  const handleCascaderChange: THandleCascaderChange = useCallback(
    (currentValue) => {
      if (onCascaderChange) {
        onCascaderChange(searchValue, currentValue)
      }

      setCascaderValue(currentValue)
    },
    [onCascaderChange, searchValue]
  )

  useEffect(() => {
    if (triggerInitialOnChange) {
      handleCascaderChange(defaultCascaderValue, options)
    }
  }, [defaultCascaderValue, handleCascaderChange, options, triggerInitialOnChange])

  function displayRender(label: string[]) {
    return (
      <div className={styles.cascaderChild}>
        <span className={styles.cascaderTitle}>{label[label.length - 1]}</span>
      </div>
    )
  }

  return (
    <Search
      value={search}
      {...rest}
      addonBefore={
        <Cascader
          value={value}
          className={styles.cascader}
          dropdownClassName={styles.cascaderList}
          defaultValue={defaultCascaderValue}
          expandIcon={<ArrowIcon className={cls(styles.arrowIcon, styles.arrowIconExpand)} />}
          options={options}
          expandTrigger="hover"
          allowClear={false}
          bordered={false}
          suffixIcon={<ArrowIcon className={styles.arrowIcon} />}
          onChange={handleCascaderChange}
          displayRender={displayRender}
          getPopupContainer={(node) => node}
          loadData={loadData}
        />
      }
      searchSize={searchSize}
      classes={{
        root: cls(styles.root, classes?.root),
        clearIcon: classes?.clearIcon
      }}
      onPressEnter={handleChange}
      onChange={handleChange}
      onClear={handleClear}
    />
  )
}
