import * as actionTypes from './caseDetails.actionTypes'
import { TCaseDetailsState, TCaseDetailsAction } from './caseDetails.types'

export const initialCaseDetailsState: TCaseDetailsState = {
  description: {
    newLineDescriptionId: null,
    ids: [],
    list: {},
    count: 0,
    error: null,
    draft: ''
  },
  summary: {
    summary: undefined,
    count: 0,
    error: null
  },
  opinions: {
    openOpinionMemberId: null,
    newLineOpinionId: null,
    opinionsIds: [],
    opinionsMembersIds: [],
    opinionsList: {},
    opinionsMembersList: {},
    count: 0,
    error: null,
    draft: ''
  }
}

export const caseDetailsReducer = (
  state = initialCaseDetailsState,
  action: TCaseDetailsAction
): TCaseDetailsState => {
  switch (action.type) {
    case actionTypes.CASE_DETAILS_RESET: {
      return initialCaseDetailsState
    }

    case actionTypes.GET_CASE_DESCRIPTION_REQUEST:
    case actionTypes.ADD_CASE_DESCRIPTION_REQUEST: {
      return {
        ...state,

        description: {
          ...state.description,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_DESCRIPTION_SUCCESS: {
      const { ids, caseDescriptionList, caseDescriptionCount } = action.payload

      return {
        ...state,

        description: {
          ...state.description,
          ids,
          list: caseDescriptionList,
          count: caseDescriptionCount,
          error: null
        }
      }
    }

    case actionTypes.ADD_CASE_DESCRIPTION_SUCCESS: {
      const { ids, caseDescriptionList, caseDescriptionCount } = action.payload

      return {
        ...state,

        description: {
          ...state.description,
          ids,
          list: caseDescriptionList,
          count: caseDescriptionCount,
          error: null,
          draft: ''
        }
      }
    }

    case actionTypes.GET_CASE_DESCRIPTION_ERROR:
    case actionTypes.ADD_CASE_DESCRIPTION_ERROR: {
      return {
        ...state,
        description: {
          ...state.description,
          error: action.payload
        }
      }
    }

    case actionTypes.GET_CASE_SUMMARY_REQUEST:
    case actionTypes.ADD_CASE_SUMMARY_REQUEST:
    case actionTypes.UPDATE_CASE_SUMMARY_REQUEST: {
      return {
        ...state,

        summary: {
          ...state.summary,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_SUMMARY_SUCCESS:
    case actionTypes.ADD_CASE_SUMMARY_SUCCESS:
    case actionTypes.UPDATE_CASE_SUMMARY_SUCCESS: {
      if (!action.payload) {
        return state
      }

      const { summary, count = state.summary.count } = action.payload
      return {
        ...state,

        summary: {
          ...state.summary,
          summary,
          count,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_SUMMARY_ERROR:
    case actionTypes.ADD_CASE_SUMMARY_ERROR:
    case actionTypes.UPDATE_CASE_SUMMARY_ERROR: {
      return {
        ...state,

        summary: {
          ...state.summary,
          error: action.payload
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_MEMBERS_REQUEST:
    case actionTypes.ADD_CASE_OPINION_REQUEST: {
      return {
        ...state,

        opinions: {
          ...state.opinions,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_REQUEST:
    case actionTypes.GET_CASE_OPINIONS_BY_MEMBER_REQUEST: {
      return {
        ...state,

        opinions: {
          ...state.opinions,
          opinionsIds: [],
          opinionsList: {},
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_MEMBERS_SUCCESS: {
      const { caseOpinionsMembersIds, caseOpinionsMembersList } = action.payload
      return {
        ...state,

        opinions: {
          ...state.opinions,
          opinionsMembersIds: caseOpinionsMembersIds,
          opinionsMembersList: caseOpinionsMembersList,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_BY_MEMBER_SUCCESS: {
      const { caseOpinionsIds, caseOpinionsList, caseOpinionsCount } = action.payload
      return {
        ...state,

        opinions: {
          ...state.opinions,
          opinionsIds: caseOpinionsIds,
          opinionsList: caseOpinionsList,
          count: caseOpinionsCount ?? 0,
          error: null
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_MEMBERS_ERROR:
    case actionTypes.GET_CASE_OPINIONS_BY_MEMBER_ERROR:
    case actionTypes.GET_CASE_OPINIONS_ERROR:
    case actionTypes.ADD_CASE_OPINION_ERROR: {
      return {
        ...state,

        opinions: {
          ...state.opinions,
          error: action.payload
        }
      }
    }

    case actionTypes.GET_CASE_OPINIONS_SUCCESS: {
      const { caseOpinionsIds, caseOpinionsList, caseOpinionsCount } = action.payload
      return {
        ...state,

        opinions: {
          ...state.opinions,
          opinionsIds: caseOpinionsIds,
          opinionsList: caseOpinionsList,
          count: caseOpinionsCount,
          error: null
        }
      }
    }

    case actionTypes.ADD_CASE_OPINION_SUCCESS: {
      const { caseOpinionsIds, caseOpinionsList, caseOpinionsCount } = action.payload
      return {
        ...state,

        opinions: {
          ...state.opinions,
          opinionsIds: caseOpinionsIds,
          opinionsList: caseOpinionsList,
          count: caseOpinionsCount,
          error: null,
          draft: ''
        }
      }
    }

    case actionTypes.SET_NEW_LINE_DESCRIPTION: {
      const { newLineDescriptionId } = action.payload
      return {
        ...state,
        description: {
          ...state.description,
          newLineDescriptionId
        }
      }
    }

    case actionTypes.SET_NEW_LINE_OPINION: {
      const { newLineOpinionId } = action.payload

      return {
        ...state,
        opinions: {
          ...state.opinions,
          newLineOpinionId
        }
      }
    }

    case actionTypes.SET_NEW_OPINION: {
      const { opinion } = action.payload

      return {
        ...state,
        opinions: {
          ...state.opinions,
          opinionsIds: [...state.opinions.opinionsIds, opinion.id],

          opinionsList: {
            ...state.opinions.opinionsList,
            [opinion.id]: opinion
          }
        }
      }
    }

    case actionTypes.SET_OPENED_OPINION: {
      return {
        ...state,
        opinions: {
          ...state.opinions,
          openOpinionMemberId: action.payload.memberId
        }
      }
    }

    case actionTypes.UPDATE_CASE_DRAFT_SUCCESS:
    case actionTypes.GET_CASE_DRAFT_SUCCESS: {
      const { description = state.description.draft, opinions = state.opinions.draft } =
        action.payload

      return {
        ...state,
        description: {
          ...state.description,
          draft: description
        },
        opinions: {
          ...state.opinions,
          draft: opinions
        }
      }
    }

    default:
      return state
  }
}
