import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseItemList } from 'App/components'
import { createLoadingSelector, GET_CASE_LIST } from 'store'

export type TCaseItemListContainerProps = ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_CASE_LIST])

const mapStateToProps = (state: State) => {
  const { classifications, roles, statuses, search } = state.cases.filters

  return {
    accountType: state.global.accountData?.type.name,
    caseIds: state.cases.ids,
    caseList: state.cases.caseList,
    isFiltersApplied: classifications.length || roles.length || statuses.length,
    loading: loadingSelector(state),
    search
  }
}

export const CaseItemListContainer = connect(mapStateToProps)(CaseItemList)
