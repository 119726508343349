import { useEffect, useState } from 'react'
import cls from 'classnames'

import { DEFAULT_RECORD_TIME, recordAudio, TRecordAudioReturn } from 'utils/recordAudio'
import { EIconSize } from 'enums'
import { ChatMessageAudioListen, Timer, IconButton } from 'App/components'
import { ReactComponent as TrashCircleIcon } from 'assets/icons/TrashCircle.svg'
import { ReactComponent as StopIcon } from 'assets/icons/Stop.svg'
import { ReactComponent as RadioBoxMarkedIcon } from 'assets/icons/RadioBoxMarked.svg'
import { ReactComponent as ArrowRightCircleIcon } from 'assets/icons/ArrowRightCircle.svg'

import { useIsMounted } from '../../../../hooks'

import 'react-h5-audio-player/lib/styles.css'
import styles from './ChatMessageAudioRecord.module.scss'

type TChatMessageAudioRecord = {
  onAudioEnd: () => void

  disabledSend?: boolean
  iconSize?: EIconSize
  maxRecordTime?: number
  classes?: Partial<Record<TChatMessageAudioRecordClasses, string>>
  defaultAudioUrl?: string
  defaultAudioBlob?: Blob
  onAudioRecorded?: (blob: Blob, url: string) => void
  onAudioSend?: (message: Blob) => void
}

export type TChatMessageAudioRecordClasses =
  | 'audio'
  | 'audioTrash'
  | 'audioEnd'
  | 'audioSend'
  | 'audioTimeIndicator'
  | 'audioListen'
  | 'audioIcon'

const DEFAULT_TIME_COUNTER = '00:00'

let timeout: NodeJS.Timeout

export const ChatMessageAudioRecord = ({
  classes,
  maxRecordTime,
  defaultAudioUrl,
  defaultAudioBlob,
  disabledSend,
  onAudioEnd,
  onAudioSend,
  onAudioRecorded,
  iconSize = EIconSize.LG
}: TChatMessageAudioRecord) => {
  const [audioUrl, setAudioUrl] = useState(defaultAudioUrl ?? '')
  const [audioBlob, setAudioBlob] = useState(defaultAudioBlob ?? new Blob())
  const [isRecordStart, changeRecordStart] = useState(false)
  const [audionEvents, changeAudioEvents] = useState({} as TRecordAudioReturn)

  const onAudioStart = async (audioEventsHandler: TRecordAudioReturn) => {
    if (!audioEventsHandler || !audioEventsHandler.start) {
      return
    }

    const { audioUrl: url } = await audioEventsHandler.start()

    setAudioUrl(url)
  }

  const isMounted = useIsMounted()

  useEffect(() => {
    if (!defaultAudioBlob && !defaultAudioUrl) {
      recordAudio().then((audioEventsHandler) => {
        if (isMounted.current) {
          if (!audioEventsHandler) {
            onAudioEnd()

            return
          }

          changeAudioEvents(audioEventsHandler)

          onAudioStart(audioEventsHandler)
          timeout = setTimeout(async () => {
            onAudionStop(audioEventsHandler)
          }, maxRecordTime ?? DEFAULT_RECORD_TIME)

          changeRecordStart(true)
        }
      })
    }

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted])

  const onAudionStop = async (audioEventsHandler: TRecordAudioReturn) => {
    clearTimeout(timeout)

    if (!audioEventsHandler || !audioEventsHandler.stop) {
      return
    }

    const { audioUrl: url, audioBlob: blob } = await audioEventsHandler.stop({ immediate: true })

    setAudioBlob(blob)
    setAudioUrl(url)
    onAudioRecorded && onAudioRecorded(blob, url)
  }

  const onDeleteAudio = async () => {
    if (!audioUrl) {
      await audionEvents.stop({ immediate: true })
    }

    onAudioEnd()
  }

  const onSendAudio = () => {
    onAudioSend && onAudioSend(audioBlob)
  }

  const onStopClick = () => {
    onAudionStop(audionEvents)
  }

  const audioRecord = !audioUrl && (
    <>
      <div className={styles.audioTime}>
        <RadioBoxMarkedIcon className={styles.recordingIcon} />
        <span className={cls(styles.audioTimeIndicator, classes?.audioTimeIndicator)}>
          {isRecordStart ? <Timer /> : DEFAULT_TIME_COUNTER}
        </span>
      </div>
      <IconButton
        iconComponent={<StopIcon />}
        onClick={onStopClick}
        iconSize={iconSize}
        classes={{ root: cls(styles.icon, styles.stop, classes?.audioIcon) }}
      />
    </>
  )

  const audioListen = audioUrl && (
    <>
      <ChatMessageAudioListen
        url={audioUrl}
        className={cls(styles.audioListen, classes?.audioListen)}
      />
      {onAudioSend && (
        <IconButton
          iconComponent={<ArrowRightCircleIcon />}
          disabled={disabledSend}
          iconSize={iconSize}
          onClick={onSendAudio}
          classes={{ root: cls(styles.icon, classes?.audioIcon, classes?.audioSend) }}
        />
      )}
    </>
  )

  return (
    <div className={cls(styles.audio, classes?.audio)}>
      <IconButton
        iconComponent={<TrashCircleIcon />}
        iconSize={iconSize}
        onClick={onDeleteAudio}
        classes={{ root: cls(styles.icon, classes?.audioIcon) }}
      />
      {audioRecord}
      {audioListen}
    </div>
  )
}
