import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { ErrorCodesEnum } from '@medentee/enums'

import { parseError, getFileName, isMatchErrorCode, QueryBuilder } from 'utils'
import { api, API, APIResultsResponse } from 'services/api'
import { State } from 'redux/rootReducer'
import {
  getCaseFilesRequest,
  getCaseUploadedByMeFilesRequest,
  getMedCloudCapacityRequest,
  hideModalAction,
  setVideoStreamingData,
  videoStreamingNormalize
} from 'store'
import {
  fileValidation,
  medCloudFileInvalidate,
  setFakeFileInWidget,
  setUploadFileStatus,
  showFileErrorToast
} from 'store/medCloud'
import { EFileUploadStatus } from 'enums'
import { handleError } from 'api/utils'

import * as actionTypes from './trashBin.actionTypes'
import * as actions from './trashBin.actions'
import { trashBinNormalize } from './trashBin.normalization'
import { TTrashBinFilters, TTrashBinItem } from './trashBin.types'

function* getTrashBinListSaga() {
  try {
    const {
      pagination: { current, showBy },
      sorting
    }: TTrashBinFilters = yield select((state: State) => state.trashBin.filters)
    const search: string = yield select((state: State) => state.trashBin.search)

    const url = new QueryBuilder(API.TRASH)
      .page(current)
      .showBy(showBy)
      .searchQuery(search)
      .sort(sorting?.direction, sorting?.name)
      .build()

    const {
      data: { total, results }
    }: APIResultsResponse<TTrashBinItem[]> = yield call(api.get, url)

    const { list, ids } = trashBinNormalize(results)

    yield put(setVideoStreamingData(videoStreamingNormalize<TTrashBinItem>(results)))
    yield put(actions.getTrashBinSuccess({ list, ids, total }))
  } catch (e) {
    yield put(actions.getTrashBinError(e))
    handleError(e)
  }
}

function* moveFileToTrashBinSaga({
  payload: { fileIds, caseId, onSuccess }
}: ReturnType<typeof actions.moveFileToTrashBinRequest>) {
  try {
    yield call(api.post, API.MOVE_FILE_TO_TRASH_BIN, { fileIds })

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(getMedCloudCapacityRequest())

    yield put(
      actions.moveFileToTrashBinSuccess({
        fileIds
      })
    )

    if (caseId) {
      yield put(getCaseFilesRequest({ caseId }))
      yield put(getCaseUploadedByMeFilesRequest({ caseId }))
    }

    yield put(hideModalAction())
  } catch (e) {
    yield put(actions.moveFileToTrashBinError(e))
    yield put(hideModalAction())
    yield handleError(e)
  }
}

function* restoreFileToCloud({
  payload: { fileId, fileName, extension, isRename }
}: ReturnType<typeof actions.restoreFileRequest>) {
  try {
    let fileValid = true

    if (isRename) {
      fileValid = yield fileValidation({ id: fileId, name: `${fileName}${extension}`, size: 0 })
    }

    if (!fileValid) {
      return
    }

    yield call(api.post, API.RESTORE_FILE, {
      fileId,
      fileName: isRename ? getFileName(fileName) : undefined
    })

    if (isRename) {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.SUCCESS, id: fileId }))
    }

    yield put(actions.restoreFileSuccess())
    yield put(actions.getTrashBinRequest())
    yield put(hideModalAction())
    yield put(getMedCloudCapacityRequest())
  } catch (e) {
    yield put(actions.restoreFileError(e))
    yield put(hideModalAction())
    const fileAlreadyInWidget: boolean = yield select((state: State) =>
      state.medCloud.widget.ids.includes(fileId)
    )

    if (!fileAlreadyInWidget) {
      yield put(setFakeFileInWidget({ fileId, fileName, fileType: extension, fromTrashBin: true }))
    }

    if (isMatchErrorCode(e, ErrorCodesEnum.FILE_EXIST)) {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.PENDING, id: fileId }))
      yield put(
        medCloudFileInvalidate({
          id: fileId,
          errorType: ErrorCodesEnum.FILE_EXIST,
          message: e.response?.data?.message
        })
      )
    } else if (
      isMatchErrorCode(e, [
        ErrorCodesEnum.FILE_NAME_CONTAINS_PROPHIBITED_SYMBOLS,
        ErrorCodesEnum.FILE_EXTENSION_NOT_SUPPORTED,
        ErrorCodesEnum.FILE_NAME_IS_EMPTY
      ])
    ) {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.PENDING, id: fileId }))
      yield put(
        medCloudFileInvalidate({
          id: fileId,
          errorType: e?.response?.data.code,
          message: e.response?.data?.message
        })
      )
    } else {
      yield put(setUploadFileStatus({ status: EFileUploadStatus.FAIL, id: fileId }))
      yield put(medCloudFileInvalidate({ id: fileId, message: parseError(e) }))
    }

    yield showFileErrorToast()
  }
}

function* deleteFileFromMedCloudSaga({
  payload: { fileIds }
}: ReturnType<typeof actions.deleteFilesFromMedCloudRequest>) {
  try {
    yield call(api.delete, API.TRASH_DELETE, { data: fileIds ? { fileIds } : {} })

    yield put(hideModalAction())

    yield put(actions.deleteFilesFromMedCloudSuccess())

    yield put(getMedCloudCapacityRequest())

    yield put(actions.getTrashBinRequest())
  } catch (e) {
    yield put(actions.deleteFilesFromMedCloudError(e))
    yield put(hideModalAction())

    handleError(e)
  }
}

export function* trashBinSaga() {
  yield takeLatest(actionTypes.MOVE_FILE_TO_TRASH_BIN_REQUEST, moveFileToTrashBinSaga)
  yield takeLatest(actionTypes.GET_TRASH_BIN_LIST_REQUEST, getTrashBinListSaga)
  yield takeEvery(actionTypes.RESTORE_FILE_REQUEST, restoreFileToCloud)
  yield takeLatest(actionTypes.DELETE_FILES_FROM_MEDCLOUD_REQUEST, deleteFileFromMedCloudSaga)
}
