import { put, takeLatest, call, select, cancel } from 'redux-saga/effects'
import { AdvertStatusEnum, AdvertRequestStatusEnum, ErrorCodesEnum } from '@medentee/enums'

import { API, api, APIData, APIResultsResponse } from 'services/api'
import { getProfileLink, isMatchErrorCode, QueryBuilder } from 'utils'
import { IAdvertsDTO, IMyAdvertsDTO, IMyAdvertRequestsDTO, IMyRequestsDTO } from 'interfaces'
import { State } from 'redux/rootReducer'
import { toastDefaultOptions } from 'globalConstants'
import history from 'utils/history'
import {
  deactivateAdvertRequest,
  deactivateAdvertError,
  deactivateAdvertSuccess,
  becomeContactsError,
  becomeContactsRequest,
  becomeContactsSuccess,
  hideModalAction,
  removeAdvertSuccess,
  removeAdvertRequest,
  removeAdvertError,
  ignoreRequestError,
  ignoreRequestRequest,
  ignoreRequestSuccess,
  repostAdvertSuccess,
  repostAdvertError,
  repostAdvertRequest,
  getMyAdvertsRequest,
  getMyRequestsRequest
} from 'store'
import { toast } from 'App/components/ToastContainer'
import { handleError } from 'api/utils'

import { TConnector, TAdvertsFilters } from './adverts.types'
import {
  getAdvertsError,
  getAdvertsSuccess,
  redirectToUserProfileAction,
  createAdvertRequest,
  createAdvertSuccess,
  createAdvertError,
  removeRequestError,
  removeRequestSuccess,
  removeRequestRequest,
  getMyAdvertsSuccess,
  getMyAdvertsError,
  withdrawRequestError,
  withdrawRequestRequest,
  withdrawRequestSuccess,
  getMyAdvertRequestsRequest,
  getMyAdvertRequestsError,
  getMyAdvertRequestsSuccess,
  getMyRequestsSuccess,
  getMyRequestsError,
  createConnectionRequestRequest,
  createConnectionRequestSuccess,
  createConnectionRequestError,
  getAdvertsRequest,
  setAdvertsData
} from './adverts.actions'
import {
  advertsNormalize,
  myAdvertsNormalize,
  myAdvertsRequestsNormalize,
  myRequestsNormalize
} from './adverts.normalization'
import * as actionTypes from './adverts.actionTypes'

function* redirectToUserProfileSaga({
  payload: { userId, searchQuery }
}: ReturnType<typeof redirectToUserProfileAction>) {
  try {
    const isMyAccount: boolean = yield select(
      (state: State) => state.global.accountData?.id === userId
    )

    if (isMyAccount) {
      yield history.push(`/profile/${userId}`)
      return
    }

    yield call(api.get, API.USER_PROFILE_CONTACT_VIEW(userId))

    const profileLink = getProfileLink(userId)

    yield history.push(searchQuery ? `${profileLink}?${searchQuery}` : profileLink)
  } catch (e) {
    yield handleError(e)
  }
}

function* getAdvertsSaga() {
  try {
    const { locations, professions, purposes, showBy, specializations, unixTs }: TAdvertsFilters =
      yield select((state: State) => state.adverts.filters)
    const search: string = yield select((state: State) => state.adverts.search)

    const url = new QueryBuilder(API.ADVERTS)
      .custom('limit', showBy)
      .custom('timestamp', unixTs)
      .multiSearch(
        'countryCodes',
        locations.map(({ code }) => code)
      )
      .multiSearch('professionProfessionalIds', professions)
      .multiSearch('professionBusinessIds', specializations)
      .multiSearch('purposeIds', purposes)
      .searchQuery(search)
      .build()

    const { data }: APIResultsResponse<IAdvertsDTO[]> = yield call(api.get, url)

    const { ids, list, total }: TConnector = yield select((state: State) => state.adverts.connector)
    const normalized = advertsNormalize(data.results)

    yield put(
      getAdvertsSuccess({
        ids: !unixTs ? [...normalized.ids] : [...ids, ...normalized.ids],
        list: !unixTs ? { ...normalized.list } : { ...list, ...normalized.list },
        total: !unixTs ? data.total : total
      })
    )
  } catch (e) {
    yield put(getAdvertsError(e))
    yield handleError(e)
  }
}

function* getMyAdvertsSaga({ payload }: ReturnType<typeof getMyAdvertsRequest>) {
  try {
    const advertId = payload?.advertId

    const {
      showBy,
      current,
      locations,
      professions,
      purposes,
      specializations,
      statuses
    }: TAdvertsFilters = yield select((state: State) => state.adverts.filters)
    const search: string = yield select((state: State) => state.adverts.search)

    const url = new QueryBuilder(API.MY_ADVERTS)
      .showBy(showBy)
      .page(current)
      .multiSearch(
        'countryCodes',
        locations.map(({ code }) => code)
      )
      .multiSearch('professionProfessionalIds', professions)
      .multiSearch('professionBusinessIds', specializations)
      .multiSearch('purposeIds', purposes)
      .multiSearch('statuses', statuses)
      .searchQuery(search)
      .custom('anchorId', advertId)
      .build()

    const {
      data: { results, total, page }
    }: APIResultsResponse<IMyAdvertsDTO[]> = yield call(api.get, url)

    yield put(
      getMyAdvertsSuccess({
        ...myAdvertsNormalize(results),
        total,
        current: page ?? current
      })
    )
  } catch (e) {
    yield put(getMyAdvertsError(e))
    yield handleError(e)
  }
}

function* getMyRequestsSaga({ payload }: ReturnType<typeof getMyRequestsRequest>) {
  try {
    const requestId = payload?.requestId

    const { showBy, current, locations, professions, purposes, specializations }: TAdvertsFilters =
      yield select((state: State) => state.adverts.filters)
    const search: string = yield select((state: State) => state.adverts.search)

    const url = new QueryBuilder(API.MY_REQUESTS)
      .showBy(showBy)
      .page(current)
      .multiSearch(
        'countryCodes',
        locations.map(({ code }) => code)
      )
      .multiSearch('professionProfessionalIds', professions)
      .multiSearch('professionBusinessIds', specializations)
      .multiSearch('purposeIds', purposes)
      .searchQuery(search)
      .custom('anchorId', requestId)
      .build()

    const {
      data: { results, total, page }
    }: APIResultsResponse<IMyRequestsDTO[]> = yield call(api.get, url)

    yield put(
      getMyRequestsSuccess({
        ...myRequestsNormalize(results),
        total,
        current: page ?? current
      })
    )
  } catch (e) {
    yield put(getMyRequestsError(e))
    yield handleError(e)
  }
}

function* getMyAdvertRequestsSaga({
  payload: { advertId }
}: ReturnType<typeof getMyAdvertRequestsRequest>) {
  try {
    const { data }: APIData<IMyAdvertRequestsDTO[]> = yield call(
      api.get,
      API.MY_ADVERT_REQUESTS(advertId)
    )

    yield put(getMyAdvertRequestsSuccess(myAdvertsRequestsNormalize(data)))
    yield put(
      setAdvertsData({
        id: advertId,
        requests: data.length,
        unviewedRequests: 0
      })
    )
  } catch (e) {
    yield put(getMyAdvertRequestsError(e))
    yield handleError(e)
  }
}

export function* createAdvertSaga({ payload }: ReturnType<typeof createAdvertRequest>) {
  try {
    const { onSuccess, ...data } = payload

    yield call(api.post, API.ADVERTS, data)

    yield put(hideModalAction())
    yield put(createAdvertSuccess())
    yield call(onSuccess)

    yield toast.success('Posted successfully', toastDefaultOptions)
  } catch (e) {
    yield handleError(e)
    yield put(createAdvertError(e))
  }
}

export function* removeRequestSaga({
  payload: { advertId, requestId }
}: ReturnType<typeof removeRequestRequest>) {
  try {
    yield call(api.delete, API.CHANGE_REQUEST_STATUS(advertId, requestId))

    yield put(hideModalAction())

    yield put(removeRequestSuccess())

    yield toast.success('Request removed', toastDefaultOptions)

    yield put(getMyRequestsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(removeRequestError(e))
    yield put(hideModalAction())
    yield put(getMyRequestsRequest())
  }
}

export function* ignoreRequestSaga({
  payload: { advertId, requestId }
}: ReturnType<typeof ignoreRequestRequest>) {
  try {
    yield call(api.delete, API.CHANGE_REQUEST_STATUS(advertId, requestId), {
      data: {
        status: AdvertRequestStatusEnum.REJECTED
      }
    })

    yield put(hideModalAction())

    yield put(ignoreRequestSuccess())

    yield put(getMyAdvertsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(ignoreRequestError(e))
    yield put(hideModalAction())
    yield put(getMyAdvertsRequest())
  }
}

export function* becomeContactsSaga({
  payload: { requestId }
}: ReturnType<typeof becomeContactsRequest>) {
  try {
    yield call(api.patch, API.BECOME_CONTACTS(requestId))

    yield put(hideModalAction())

    yield put(becomeContactsSuccess())

    yield toast.success('You are contacts', toastDefaultOptions)

    yield put(getMyAdvertsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(becomeContactsError(e))
    yield put(hideModalAction())
    yield put(getMyAdvertsRequest())
  }
}

export function* withdrawRequestSaga({
  payload: { advertId, requestId }
}: ReturnType<typeof withdrawRequestRequest>) {
  try {
    const {
      data: { isContact }
    }: APIData<IMyRequestsDTO> = yield call(
      api.delete,
      API.CHANGE_REQUEST_STATUS(advertId, requestId),
      {
        data: {
          status: AdvertRequestStatusEnum.WITHDRAWN
        }
      }
    )

    yield put(hideModalAction())

    yield put(withdrawRequestSuccess())
    yield isContact
      ? toast.info('You are already connected. Check your List of Contacts.', toastDefaultOptions)
      : toast.success('Request withdrawn', toastDefaultOptions)

    yield put(getMyRequestsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(withdrawRequestError(e))
    yield put(hideModalAction())
    yield put(getMyRequestsRequest())
  }
}

export function* removeAdvertSaga({
  payload: { advertId }
}: ReturnType<typeof removeAdvertRequest>) {
  try {
    yield call(api.patch, API.CHANGE_ADVERT_STATUS(advertId), { status: AdvertStatusEnum.REMOVED })

    yield put(hideModalAction())

    yield put(removeAdvertSuccess())

    yield toast.success('Advert removed', toastDefaultOptions)

    yield put(getMyAdvertsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(removeAdvertError(e))
    yield put(hideModalAction())
    yield put(getMyAdvertsRequest())
  }
}

export function* repostAdvertSaga({
  payload: { advertId }
}: ReturnType<typeof repostAdvertRequest>) {
  try {
    yield call(api.patch, API.CHANGE_ADVERT_STATUS(advertId), { status: AdvertStatusEnum.POSTED })

    yield put(hideModalAction())

    yield put(repostAdvertSuccess())

    yield toast.success('Posted successfully', toastDefaultOptions)

    yield put(getMyAdvertsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(repostAdvertError(e))
    yield put(hideModalAction())
    yield put(getMyAdvertsRequest())
  }
}

export function* deactivateAdvertSaga({
  payload: { advertId }
}: ReturnType<typeof deactivateAdvertRequest>) {
  try {
    yield call(api.patch, API.CHANGE_ADVERT_STATUS(advertId), {
      status: AdvertStatusEnum.DEACTIVATED
    })

    yield put(hideModalAction())

    yield put(deactivateAdvertSuccess())

    yield toast.success('Advert deactivated', toastDefaultOptions)

    yield put(getMyAdvertsRequest())
  } catch (e) {
    yield handleError(e)
    yield put(deactivateAdvertError(e))
    yield put(hideModalAction())
    yield put(getMyAdvertsRequest())
  }
}

export function* createConnectionRequestSaga({
  payload: { advertId, message }
}: ReturnType<typeof createConnectionRequestRequest>) {
  try {
    yield call(api.post, API.MY_ADVERT_REQUESTS(advertId), { message })

    yield put(hideModalAction())

    yield put(createConnectionRequestSuccess())

    yield toast.success('Connection request successfully sent', toastDefaultOptions)

    yield put(getAdvertsRequest({ unixTs: 0 }))
  } catch (e) {
    yield put(createConnectionRequestError(e))

    if (isMatchErrorCode(e, ErrorCodesEnum.ADVERT_ALREADY_CONNECTED)) {
      yield toast.info(e.response?.data?.message, toastDefaultOptions)
      yield cancel()
    }

    yield put(hideModalAction())
    yield put(getAdvertsRequest())

    yield handleError(e)
  }
}

export function* advertsSaga() {
  yield takeLatest(actionTypes.GET_ADVERTS_REQUEST, getAdvertsSaga)
  yield takeLatest(actionTypes.GET_MY_ADVERTS_REQUEST, getMyAdvertsSaga)
  yield takeLatest(actionTypes.GET_MY_REQUESTS_REQUEST, getMyRequestsSaga)
  yield takeLatest(actionTypes.REDIRECT_TO_USER_PROFILE_ACTION, redirectToUserProfileSaga)
  yield takeLatest(actionTypes.CREATE_ADVERT_REQUEST, createAdvertSaga)
  yield takeLatest(actionTypes.REMOVE_REQUEST_REQUEST, removeRequestSaga)
  yield takeLatest(actionTypes.IGNORE_REQUEST_REQUEST, ignoreRequestSaga)
  yield takeLatest(actionTypes.BECOME_CONTACT_REQUEST, becomeContactsSaga)
  yield takeLatest(actionTypes.WITHDRAW_REQUEST_REQUEST, withdrawRequestSaga)
  yield takeLatest(actionTypes.GET_MY_ADVERT_REQUESTS_REQUEST, getMyAdvertRequestsSaga)
  yield takeLatest(actionTypes.REMOVE_ADVERT_REQUEST, removeAdvertSaga)
  yield takeLatest(actionTypes.REPOST_ADVERT_REQUEST, repostAdvertSaga)
  yield takeLatest(actionTypes.DEACTIVATE_ADVERT_REQUEST, deactivateAdvertSaga)
  yield takeLatest(actionTypes.CREATE_CONNECTION_REQUEST_REQUEST, createConnectionRequestSaga)
}
