import uniq from 'lodash/uniq'

import { getISOStringNow } from '../../utils'
import { GET_CHAT_ROOMS_SUCCESS } from '../chatRooms'

import * as actionTypes from './online.actionTypes'
import { TOnlineState, TOnlineAction } from './online.types'

export const initialOnlineState: TOnlineState = {
  ids: [],
  list: {},
  admin: {
    ids: [],
    list: {}
  }
}

export const onlineReducer = (state = initialOnlineState, action: TOnlineAction) => {
  switch (action.type) {
    case actionTypes.SET_ONLINE: {
      const { list, ids } = action.payload

      return {
        ...state,
        list,
        ids
      }
    }

    case actionTypes.SET_ADMIN_ONLINE: {
      const { list, ids } = action.payload

      return {
        ...state,
        admin: {
          ...state.admin,
          list,
          ids
        }
      }
    }

    case GET_CHAT_ROOMS_SUCCESS: {
      const { list, ids } =
        Object.values(action.payload.list)?.reduce<TOnlineState>(
          (res, { isInterlocutorOnline, interlocutorAccount }): TOnlineState => {
            if (interlocutorAccount) {
              const { id, lastSeen } = interlocutorAccount

              if (isInterlocutorOnline) {
                return {
                  ...res,
                  list: {
                    ...res.list,
                    [id]: {
                      ...(res.list[id] || {}),
                      online: true,
                      lastSeen: getISOStringNow()
                    }
                  },
                  ids: [...res.ids, id]
                }
              } else {
                return {
                  ...res,
                  list: {
                    ...res.list,
                    [id]: {
                      ...(res.list[id] || {}),
                      online: false,
                      lastSeen
                    }
                  },
                  ids: res.ids.filter((item) => item !== id)
                }
              }
            }

            return res
          },
          { ...state } as TOnlineState
        ) ?? state

      return {
        ...state,
        list,
        ids: uniq(ids)
      }
    }

    default:
      return state
  }
}
