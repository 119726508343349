import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import cls from 'classnames'

import { ReactComponent as BullhornOutlineIcon } from 'assets/icons/BullhornOutline.svg'
import { TGeneralMention } from 'utils/mentions'

import styles from './GeneralSuggestion.module.scss'

export type TGeneralSuggestionProps = Omit<
  EntryComponentProps,
  'isFocused' | 'selectMention' | 'searchValue'
> & {
  label: string
}

export const GeneralSuggestion = ({
  mention,
  theme,
  label,
  ...parentProps
}: TGeneralSuggestionProps) => {
  const data = (mention as TGeneralMention) ?? {}

  return (
    <div {...parentProps} className={cls(styles.root, theme?.mentionSuggestionsEntry)}>
      <BullhornOutlineIcon />
      <div>
        <span className={styles.name}>@{data.name}</span> {label}
      </div>
    </div>
  )
}
