import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useMutation } from 'services/query'
import { createGroupeChat, updateGroupeChat, removeGroupChatAvatar } from 'api/chats'
import { CHAT_PATH, EChatTypeMap } from 'globalConstants'
import { useAppDispatch, useAppSelector, updateChatRoomAction } from 'store'

import { useChatCreateGroupContext } from './ChatCreateGroupContext'

export const useChatCreateGroupRequest = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const {
    chatId,
    chatName,
    selectedMembers,
    removedMemberIds,
    file,
    shouldRemovePhoto,
    ignorePrompt
  } = useChatCreateGroupContext()

  const chatRoomsList = useAppSelector((state) => state.chat.chatRooms.list)
  const selectedChat = useAppSelector((state) => state.chat.chatRooms.selectedChat)

  const { mutate: create } = useMutation({
    mutationKey: ['create-group-chat'],
    mutationFn: createGroupeChat,
    onSuccess: ({ id }) => {
      push(generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE, chatId: id }))
    }
  })

  const { mutate: update } = useMutation({
    mutationKey: ['update-group-chat'],
    mutationFn: updateGroupeChat,
    onSuccess: ({ name }) => {
      if (selectedChat?.name !== name) {
        dispatch(
          updateChatRoomAction({
            list: {
              ...chatRoomsList,
              [chatId]: {
                ...chatRoomsList[chatId],
                name
              }
            },
            selectedChat: selectedChat
              ? {
                  ...selectedChat,
                  name
                }
              : selectedChat
          })
        )
      }

      ignorePrompt()
      push(generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE, chatId }))
    }
  })

  const { mutate: removeAvatar } = useMutation({
    mutationKey: ['remove-group-chat-avatar'],
    mutationFn: removeGroupChatAvatar
  })

  const handleCreate = useCallback(() => {
    const accountIds = selectedMembers.map(({ id }) => id)

    create({ accountIds, name: chatName, file })
  }, [chatName, file, create, selectedMembers])

  const handleUpdate = useCallback(() => {
    const accountIds = selectedMembers.map(({ id }) => id)

    if (shouldRemovePhoto && !file) {
      removeAvatar({ chatId })
    }

    update({
      chatId,
      setAccountIds: accountIds,
      unsetAccountIds: removedMemberIds,
      name: chatName,
      file
    })
  }, [
    chatName,
    chatId,
    file,
    update,
    removeAvatar,
    shouldRemovePhoto,
    removedMemberIds,
    selectedMembers
  ])

  return { handleCreate, handleUpdate }
}
