import { action, TError } from 'store'
import { ICallsCreateCallDTO } from 'interfaces/api/calls/callsCreateCallDTO'
import { TAccount } from 'store/chatRooms'

import {
  TReceiveCreateCallPayload,
  TSendCreateCallSuccessPayload,
  TSendCreateCallRequestPayload,
  TSendAllowCallRequestPayload,
  TSendAllowCallSuccessPayload,
  TReceiveAllowCallPayload,
  TSendEndCallRequestPayload,
  TReceiveEndCallPayload,
  TReceiveMissedCallPayload,
  TReceiveCreateCallSuccessPayload,
  TAllowCallActionPayload,
  TReceiveAcceptedMyselfCallPayload,
  TJoinToCallRequestPayload,
  TJoinToCallSuccessPayload,
  TReceiveCallCountsForWidgetPayload,
  TSetFoldCallPayload,
  TAddUsersToCallRequestPayload,
  TAddUsersToCallSuccessPayload,
  TJoinToCallErrorPayload
} from './calls.types'

// ------------------ CREATE_CALL ------------------
export const SEND_CREATE_CALL = 'SEND_CREATE_CALL'
export const SEND_CREATE_CALL_REQUEST = 'SEND_CREATE_CALL_REQUEST'
export const SEND_CREATE_CALL_SUCCESS = 'SEND_CREATE_CALL_SUCCESS'
export const SEND_CREATE_CALL_ERROR = 'SEND_CREATE_CALL_ERROR'
export const SEND_CREATE_CALL_CANCEL = 'SEND_CREATE_CALL_CANCEL'

export const RECEIVE_CREATE_CALL = 'RECEIVE_CREATE_CALL'
export const RECEIVE_CREATE_CALL_SUCCESS = 'RECEIVE_CREATE_CALL_SUCCESS'

export const sendCreateCallRequest = (payload: TSendCreateCallRequestPayload) =>
  action(SEND_CREATE_CALL_REQUEST, payload)
export const sendCreateCallSuccess = (payload: TSendCreateCallSuccessPayload) =>
  action(SEND_CREATE_CALL_SUCCESS, payload)
export const sendCreateCallError = (payload: TError) => action(SEND_CREATE_CALL_ERROR, payload)
export const sendCreateCallCancel = () => action(SEND_CREATE_CALL_CANCEL)

export const receiveCreateCallAction = (payload: TReceiveCreateCallPayload) =>
  action(RECEIVE_CREATE_CALL, payload)
export const receiveCreateCallSuccess = (payload: TReceiveCreateCallSuccessPayload) =>
  action(RECEIVE_CREATE_CALL_SUCCESS, payload)

// ------------------ ADD_USERS_TO_CALL ------------------
export const ADD_USERS_TO_CALL = 'ADD_USERS_TO_CALL'
export const ADD_USERS_TO_CALL_REQUEST = 'ADD_USERS_TO_CALL_REQUEST'
export const ADD_USERS_TO_CALL_SUCCESS = 'ADD_USERS_TO_CALL_SUCCESS'
export const ADD_USERS_TO_CALL_ERROR = 'ADD_USERS_TO_CALL_ERROR'

export const addUsersToCallRequest = (payload: TAddUsersToCallRequestPayload) =>
  action(ADD_USERS_TO_CALL_REQUEST, payload)
export const addUsersToCallSuccess = (payload: TAddUsersToCallSuccessPayload) =>
  action(ADD_USERS_TO_CALL_SUCCESS, payload)
export const addUsersToCallError = (payload: TError) => action(ADD_USERS_TO_CALL_ERROR, payload)

// ------------------ ALLOW_CALL ------------------
export const SEND_ALLOW_CALL_REQUEST = 'SEND_ALLOW_CALL_REQUEST'
export const SEND_ALLOW_CALL_SUCCESS = 'SEND_ALLOW_CALL_SUCCESS'
export const SEND_ALLOW_CALL_ERROR = 'SEND_ALLOW_CALL_ERROR'
export const RECEIVE_ALLOW_CALL = 'RECEIVE_ALLOW_CALL'
export const ALLOW_CALL_ACTION = 'ALLOW_CALL_ACTION'

export const sendAllowCallRequest = (payload: TSendAllowCallRequestPayload) =>
  action(SEND_ALLOW_CALL_REQUEST, payload)
export const sendAllowCallSuccess = (payload: TSendAllowCallSuccessPayload) =>
  action(SEND_ALLOW_CALL_SUCCESS, payload)
export const sendAllowCallError = (payload: TError) => action(SEND_ALLOW_CALL_ERROR, payload)
export const receiveAllowCall = (payload: TReceiveAllowCallPayload) =>
  action(RECEIVE_ALLOW_CALL, payload)
export const allowCallAction = (payload: TAllowCallActionPayload) =>
  action(ALLOW_CALL_ACTION, payload)

// ------------------ JOIN_TO_CALL ------------------
export const JOIN_TO_CALL = 'JOIN_TO_CALL'
export const JOIN_TO_CALL_REQUEST = 'JOIN_TO_CALL_REQUEST'
export const JOIN_TO_CALL_SUCCESS = 'JOIN_TO_CALL_SUCCESS'
export const JOIN_TO_CALL_ERROR = 'JOIN_TO_CALL_ERROR'

export const joinToCallRequest = (payload: TJoinToCallRequestPayload) =>
  action(JOIN_TO_CALL_REQUEST, payload)
export const joinToCallSuccess = (payload: TJoinToCallSuccessPayload) =>
  action(JOIN_TO_CALL_SUCCESS, payload)
export const joinToCallError = (payload: TJoinToCallErrorPayload) =>
  action(JOIN_TO_CALL_ERROR, payload)

// ------------------ END_CALL ------------------
export const SEND_END_CALL_REQUEST = 'SEND_END_CALL_REQUEST'
export const SEND_END_CALL_SUCCESS = 'SEND_END_CALL_SUCCESS'
export const SEND_END_CALL_ERROR = 'SEND_END_CALL_ERROR'

export const RECEIVE_END_CALL = 'RECEIVE_END_CALL'
export const END_CALL = 'END_CALL'

export const sendEndCallRequest = (payload: TSendEndCallRequestPayload) =>
  action(SEND_END_CALL_REQUEST, payload)
export const sendEndCallSuccess = () => action(SEND_END_CALL_SUCCESS)
export const sendEndCallError = (payload: TError) => action(SEND_END_CALL_ERROR, payload)
export const receiveEndCall = (payload: TReceiveEndCallPayload) => action(RECEIVE_END_CALL, payload)
export const endCall = (payload: TReceiveEndCallPayload) => action(END_CALL, payload)

// ------------------ MISSED_CALL ------------------
export const RECEIVE_MISSED_CALL = 'RECEIVE_MISSED_CALL'

export const receiveMissedCall = (payload: TReceiveMissedCallPayload) =>
  action(RECEIVE_MISSED_CALL, payload)

// ------------------ UPDATE_CREATE_CALL ------------------
export const UPDATE_CREATE_CALL = 'UPDATE_CREATE_CALL'

export const updateCreateCall = (payload: ICallsCreateCallDTO) =>
  action(UPDATE_CREATE_CALL, payload)

// ------------------ CLEAR_CALL ------------------
export const CLEAR_CALL = 'CLEAR_CALL'

export const clearCallAction = () => action(CLEAR_CALL)

// ------------------ EXIST_CALLS ------------------
export const RECEIVE_EXIST_CALLS = 'RECEIVE_EXIST_CALLS'
export const RECEIVE_EXIST_CALLS_SUCCESS = 'RECEIVE_EXIST_CALLS_SUCCESS'

export const receiveExistCallsAction = (payload: ICallsCreateCallDTO | null) =>
  action(RECEIVE_EXIST_CALLS, payload)
export const receiveExistCallsSuccessAction = (payload: {
  call: ICallsCreateCallDTO
  account: TAccount
}) => action(RECEIVE_EXIST_CALLS_SUCCESS, payload)

// ------------------ FOLD_CALL ------------------
export const SET_FOLD_CALL = 'SET_FOLD_CALL'

export const setFoldCall = (payload: TSetFoldCallPayload) => action(SET_FOLD_CALL, payload)

// ------------------ RECEIVE_CALL_ACCEPTED_MYSELF ------------------
export const RECEIVE_CALL_ACCEPTED_MYSELF = 'RECEIVE_CALL_ACCEPTED_MYSELF'
export const UPDATE_CALL_ACCEPTED_MYSELF = 'UPDATE_CALL_ACCEPTED_MYSELF'

export const receiveAcceptedMyselfCall = (payload: TReceiveAcceptedMyselfCallPayload) =>
  action(RECEIVE_CALL_ACCEPTED_MYSELF, payload)
export const updateCallAcceptedMyself = () => action(UPDATE_CALL_ACCEPTED_MYSELF)

// ------------------ CALL_COUNTS_FOR_WIDGET ------------------
export const RECEIVE_CALL_COUNTS_FOR_WIDGET = 'RECEIVE_CALL_COUNTS_FOR_WIDGET'

export const receiveCallCountsForWidget = (payload: TReceiveCallCountsForWidgetPayload) =>
  action(RECEIVE_CALL_COUNTS_FOR_WIDGET, payload)
