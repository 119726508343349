import cls from 'classnames'

import { TButtonMessageContainerProps } from 'App/containers'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

import { ButtonChip, TButtonChipProps } from '../ButtonChip'

import styles from './ButtonMessage.module.scss'

export type TButtonMessageProps = TButtonMessageContainerProps &
  TButtonChipProps & {
    unreadCount?: number
  }

export const ButtonMessage = ({
  unreadCount,
  chatId: _chatId,
  unreadAll2AllChat,
  isChatRoomMuted,
  className = '',
  ...rest
}: TButtonMessageProps) => (
  <ButtonChip
    {...rest}
    className={cls(className, {
      [styles.unreadCount]: !!unreadCount || unreadAll2AllChat,
      [styles.muted]: isChatRoomMuted
    })}
  >
    <ForumIcon />
  </ButtonChip>
)
