import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { Button, Collapse, CollapsePanel, Checkbox } from 'App/components/common'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { stopPropagation } from 'utils'

import { TMenuItemProps } from '../MenuItem'
import { TruncatedText } from '../../TruncatedText'

import styles from './MenuItemCollapse.module.scss'

export type TMenuItemCollapseOption = {
  id: string
  name: string
  checked: boolean

  disabled?: boolean
}

export type TMenuItemCollapseProps = {
  item: Omit<TMenuItemProps, 'render'>
  buttonLabel: string
  options: TMenuItemCollapseOption[]
  onChange: (checked: boolean, id: string) => void

  loading?: boolean
}

export const MenuItemCollapse = ({
  item: { content, onClick },
  buttonLabel,
  options,
  loading,
  onChange
}: TMenuItemCollapseProps) => {
  const handleChange = (id: string) => (e: CheckboxChangeEvent) => {
    stopPropagation(e)
    onChange(e.target.checked, id)
  }

  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <div className={styles.root}>
      <Collapse accordion={false}>
        <CollapsePanel key={content} className={styles.collapsePanel} header={content}>
          <div className={styles.options}>
            {options.map(({ checked, id, name, disabled }) => (
              <Checkbox
                key={id}
                disabled={disabled || loading}
                classes={{ root: styles.checkbox }}
                checked={checked}
                label={<TruncatedText text={name} />}
                onChange={handleChange(id)}
              />
            ))}
          </div>
          <Button
            variant="outlined"
            size="xs"
            icon={<PlusIcon />}
            onClick={handleClick}
            disabled={loading}
            className={styles.button}
            adaptive={false}
          >
            {buttonLabel}
          </Button>
        </CollapsePanel>
      </Collapse>
    </div>
  )
}
