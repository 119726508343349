import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'store'
import { getMapComponent } from 'utils'
import { TChannelVariant } from 'interfaces'
import { useUnityHubCreateChannel, TUseUnityHubCreateChannel } from 'App/components/UnityHub'

import {
  CommunityCreateChannelMainStep,
  TCommunityCreateChannelMainStepProps
} from '../CommunityCreateChannelMainStep'
import {
  CommunityCreateChannelMembersStep,
  TCommunityCreateChannelMembersStepProps
} from '../CommunityCreateChannelMembersStep'
import { useMembersRequest } from '../useMembersRequest'

enum steps {
  MAIN = 0,
  MEMBERS = 1,
  CHANEL_MANAGER = 2
}

const MAX_SELECTED_CHANNEL_MANAGER = 1

type TStepsMapProps = Pick<
  TCommunityCreateChannelMainStepProps,
  | 'channelId'
  | 'setName'
  | 'channelManager'
  | 'isEdit'
  | 'loading'
  | 'communityId'
  | 'variant'
  | 'onEditSuccess'
  | 'dirty'
  | 'isPublic'
  | 'setIsPublic'
  | 'initialValues'
> &
  Pick<
    TCommunityCreateChannelMembersStepProps,
    'setChannelMembers' | 'channelMembers' | 'setChannelManager'
  > & {
    name: string
    handleOpenMain: () => void
    handleOpenChanelManager: () => void
    handleOpenMembers: () => void
  }

const STEPS_MAP = new Map<steps, (props: TStepsMapProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({
      name,
      communityId,
      channelId,
      channelMembers,
      channelManager,
      isEdit,
      loading,
      variant,
      dirty,
      isPublic,
      handleOpenChanelManager,
      handleOpenMembers,
      setName,
      setIsPublic,
      onEditSuccess,
      initialValues
    }) => (
      <CommunityCreateChannelMainStep
        dirty={dirty}
        variant={variant}
        communityId={communityId}
        name={name}
        channelId={channelId}
        channelMembers={channelMembers}
        channelManager={channelManager}
        isEdit={isEdit}
        isPublic={isPublic}
        loading={loading}
        initialValues={initialValues}
        setName={setName}
        setIsPublic={setIsPublic}
        onEditSuccess={onEditSuccess}
        onOpenMembers={handleOpenMembers}
        onOpenChanelManager={handleOpenChanelManager}
      />
    )
  )
  .set(
    steps.MEMBERS,
    ({
      channelId,
      channelMembers,
      communityId,
      channelManager,
      handleOpenMain,
      setChannelMembers,
      setChannelManager
    }) => (
      <CommunityCreateChannelMembersStep
        variant="members"
        channelManagerId={channelManager?.id}
        communityId={communityId}
        channelMembers={channelMembers}
        markChatIds={channelId ? [channelId] : []}
        onBack={handleOpenMain}
        setChannelMembers={setChannelMembers}
        setChannelManager={setChannelManager}
      />
    )
  )
  .set(
    steps.CHANEL_MANAGER,
    ({ communityId, channelManager, handleOpenMain, setChannelManager }) => (
      <CommunityCreateChannelMembersStep
        variant="manager"
        maxSelected={MAX_SELECTED_CHANNEL_MANAGER}
        channelManagerId={channelManager?.id}
        communityId={communityId}
        channelMembers={channelManager ? [channelManager] : []}
        onBack={handleOpenMain}
        setChannelManager={setChannelManager}
      />
    )
  )

export type TCommunityCreateChannelDialogProps = Pick<
  TUseUnityHubCreateChannel,
  'channelId' | 'channelName' | 'isPublic'
> &
  Pick<TCommunityCreateChannelMainStepProps, 'onEditSuccess'> & {
    communityId: string
    variant?: Extract<TChannelVariant, 'chat' | 'news'>
  }

export const CommunityCreateChannelDialog = ({
  communityId,
  channelName,
  onEditSuccess,
  channelId = '',
  isPublic: channelIsPublic,
  variant = 'chat'
}: TCommunityCreateChannelDialogProps) => {
  const {
    name,
    channelMembers,
    channelManager,
    step,
    isEdit,
    dirty,
    isPublic,
    setIsPublic,
    handleOpenMain,
    handleOpenMembers,
    handleOpenChanelManager,
    setName,
    setChannelMembers,
    setChannelManager
  } = useUnityHubCreateChannel({
    channelName,
    channelId,
    isPublic: channelIsPublic,
    maxSelectedChannelManager: MAX_SELECTED_CHANNEL_MANAGER
  })

  const room = useAppSelector((state) => state.chat.chatRooms.list[channelId])

  const initialValues = useMemo(
    () => ({ name: channelName || '', isPublic: channelIsPublic || false }),
    [channelName, channelIsPublic]
  )

  const { isLoading, accounts } = useMembersRequest({
    variant,
    channelId,
    communityId,
    isEdit,
    setChannelMembers
  })

  useEffect(() => {
    if (isEdit && accounts?.length) {
      setChannelManager(room.channelManager ? [room.channelManager] : undefined, true)
    }
  }, [accounts?.length, isEdit, room?.channelManager, setChannelManager])

  return (
    <>
      {getMapComponent(STEPS_MAP, step, {
        dirty,
        variant,
        name,
        channelMembers,
        channelManager,
        channelId,
        isEdit,
        communityId,
        loading: isLoading,
        isPublic,
        initialValues,
        setName,
        setIsPublic,
        setChannelMembers,
        setChannelManager,
        handleOpenMain,
        handleOpenMembers,
        handleOpenChanelManager,
        onEditSuccess
      })}
    </>
  )
}
