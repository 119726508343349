import { useCallback, useEffect, useRef } from 'react'

import { getViewedNotificationsIdsSelector } from 'store'
import { generateRandomArray } from 'utils'
import { CaseOwnerOpinionsItemContainer, TCaseOwnerOpinionsContainerProps } from 'App/containers'
import { useCaseNewDividerLine } from 'App/hooks'
import { DEFAULT_THROTTLE_MS } from 'globalConstants'

export type TCaseOwnerOpinionsListProps = {
  caseId: string
} & TCaseOwnerOpinionsContainerProps

const DEFAULT_OPINIONS_LENGTH = 3

export const CaseOwnerOpinionsList = ({
  ids,
  loading,
  newLineOpinionId,
  readNotificationFromId,
  notifications
}: TCaseOwnerOpinionsListProps) => {
  const opinions = loading ? generateRandomArray(DEFAULT_OPINIONS_LENGTH) : ids

  const scrolledIntoViewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (scrolledIntoViewRef.current) {
        scrolledIntoViewRef.current.scrollIntoView(false)
      }
    }, DEFAULT_THROTTLE_MS)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const readNotifications = useCallback(() => {
    readNotificationFromId({
      notificationIds: getViewedNotificationsIdsSelector(notifications)
    })
  }, [notifications, readNotificationFromId])

  const { newDividerLineRef } = useCaseNewDividerLine({
    lineId: newLineOpinionId,
    readNotifications
  })

  return (
    <>
      {opinions.map((id) => (
        <CaseOwnerOpinionsItemContainer key={id} id={id} newDividerLineRef={newDividerLineRef} />
      ))}

      <div ref={scrolledIntoViewRef} />
    </>
  )
}
