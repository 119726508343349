import { useMemo } from 'react'
import cls from 'classnames'

import { TContactsProfessions } from 'store/contacts/contacts.types'
import { ShowMore } from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './DisplayProfessionsSpecializations.module.scss'

export type TDisplayProfessionsSpecializationsProps = {
  data: TContactsProfessions[]

  classes?: Partial<Record<TDisplayProfessionsSpecializationsClasses, string>>
}

export type TDisplayProfessionsSpecializationsClasses = 'root' | 'text'

export const DisplayProfessionsSpecializations = ({
  data,
  classes
}: TDisplayProfessionsSpecializationsProps) => {
  const { isMobile } = useAdaptiveLayout()

  const visibleHeight = isMobile ? styles.visibleHeightMobile : styles.visibleHeight

  const formattedData = useMemo(
    () =>
      data.reduce<string>((acc, item, index) => {
        if (index !== 0) {
          acc += ', '
        }

        acc += item.name

        if (item.specializations.length) {
          acc += ` (${item.specializations
            .map((specialization) => specialization.name)
            .join(', ')})`
        }

        return acc
      }, ''),
    [data]
  )

  if (!data.length) {
    return null
  }

  return (
    <div className={classes?.root}>
      <ShowMore
        text={formattedData}
        classes={{ wrapper: cls(styles.text, classes?.text) }}
        visibleHeight={Number(visibleHeight)}
      />
    </div>
  )
}
