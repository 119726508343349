import { ReactNode } from 'react'
import { Col } from 'antd'
import cls from 'classnames'

import { Checkbox, TMenuItemProps, FileImage, ContextMenu } from 'App/components'
import { stopPropagation } from 'utils'

import {
  TUseFileMouseEventsProps,
  TUseSelection,
  useFileMouseEvents,
  useFileContextMenu
} from '../../common/hooks'
import { FileNameTruncatedText } from '../../FileNameTruncatedText'

import styles from './FileTilesViewGridItem.module.scss'

export type TFileTilesViewGridItemProps = PropsWithClassName<
  PropsWithClasses<
    | 'root'
    | 'checkbox'
    | 'item'
    | 'containerThumbnail'
    | 'thumbnail'
    | 'footer'
    | 'containerName'
    | 'fileName'
    | 'contextMenu'
    | 'addonAfter'
    | 'videoStatus',
    {
      fileId: string
      fileName: string
      extension: string
      mimeType: string

      menuItems?: TMenuItemProps[]
      thumbnailUrl?: string | null
      selected?: boolean
      addonAfter?: ReactNode
      fileNameDisabled?: boolean
    } & Partial<Pick<TUseSelection, 'onSelect' | 'selectMode'>> &
      Partial<Pick<TUseFileMouseEventsProps, 'disableOpen'>> &
      Pick<TUseFileMouseEventsProps, 'openHandlerProps'>
  >
>

export const FileTilesViewGridItem = ({
  fileId,
  fileName,
  className,
  selected,
  extension,
  thumbnailUrl,
  addonAfter,
  menuItems,
  selectMode,
  classes,
  disableOpen,
  openHandlerProps,
  fileNameDisabled,
  onSelect
}: TFileTilesViewGridItemProps) => {
  const { handleVisibilityChange, visible, ref } = useFileContextMenu()

  const { handleSelect, longPressBind, onClick } = useFileMouseEvents({
    file: { id: fileId },
    openHandlerProps,
    selectMode,
    disableOpen,
    onSelect,
    onTouch: handleVisibilityChange
  })

  return (
    <Col
      className={cls(styles.root, className, classes?.root, selected && styles.selected)}
      xs={12}
      sm={6}
      md={6}
    >
      <Checkbox
        classes={{ root: styles.checkbox }}
        checked={selected}
        onChange={handleSelect}
        onClick={stopPropagation}
      />

      <div
        ref={ref}
        className={cls(styles.item, classes?.item)}
        {...longPressBind}
        onClick={onClick}
        onContextMenu={handleVisibilityChange}
      >
        {menuItems && (
          <ContextMenu
            disableIcon={true}
            menuItems={menuItems}
            visible={visible}
            classes={{ root: cls(styles.contextMenu, classes?.contextMenu) }}
          />
        )}
        <FileImage
          size="lg"
          thumbnailUrl={thumbnailUrl}
          fileName={fileName}
          fileId={fileId}
          extension={extension}
          classes={{ root: styles.image, videoStatus: classes?.videoStatus }}
        />

        <footer className={cls(styles.footer, classes?.footer)}>
          <div className={cls(styles.containerName, classes?.containerName)}>
            <FileNameTruncatedText
              className={cls(styles.fileName, classes?.fileName)}
              extension={extension}
              fileName={fileName}
              twoLine={true}
              disabled={fileNameDisabled}
            />

            {menuItems && (
              <ContextMenu classes={{ icon: styles.contextMenuIcon }} menuItems={menuItems} />
            )}
          </div>

          {addonAfter && (
            <div className={cls(styles.addonAfter, classes?.addonAfter)}>{addonAfter}</div>
          )}
        </footer>
      </div>
    </Col>
  )
}
