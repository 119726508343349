import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { selectAttachFileAction } from 'store'
import { State } from 'redux/rootReducer'
import { FileAttachUploadFileItem, TCaseAttachFilesItemBaseProps } from 'App/components'

export type TFileAttachUploadFilesItemContainer = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, { id }: TCaseAttachFilesItemBaseProps) => {
  const { selected } = state.medCloud.widget.list[id] ?? {}

  return { selected }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectAttachFile: selectAttachFileAction
    },
    dispatch
  )

export const FileAttachUploadFilesItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileAttachUploadFileItem)
