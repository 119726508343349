import { FC, useCallback } from 'react'
import { CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { AccountsSelect, SearchCascader, TAccountsSelectProps } from 'App/components/common'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { EInputSize } from 'enums'
import {
  useAppSelector,
  accountIdSelector,
  getCaseInvitationInactiveMembersQuantity,
  getCaseMemberIdsSelector
} from 'store'
import { getContacts } from 'api/contacts'
import { useAllContactsSearchByOptions } from 'App/hooks/SearchCascader/useAllContactsSearchByOptions'
import { useSelectAccounts } from 'App/hooks/useSelectAccounts'
import { CaseInviteMembersAlert } from 'App/components/Case/CaseInviteMembersAlert'

import { useCaseInviteMembersRequest } from './useCaseInviteMembersRequest'

export type TCaseMembersSelectProps = Pick<TAccountsSelectProps, 'onClickBack'>

export const CaseMembersSelect: FC<TCaseMembersSelectProps> = ({ onClickBack }) => {
  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const caseId = useAppSelector((state) => state.caseView.data?.id)
  const caseType = useAppSelector((state) => state.caseView.data?.type)
  const caseMembersDirectLimit = useAppSelector(
    (state) => state.subscriptions.current?.subscription?.caseMembersDirectLimit
  )
  const caseMembersGroupLimit = useAppSelector(
    (state) => state.subscriptions.current?.subscription?.caseMembersGroupLimit
  )
  const activeMembersNumber = useAppSelector((state) => state.caseMembers.members.active.ids.length)
  const inactiveMembersNumber = useAppSelector(getCaseInvitationInactiveMembersQuantity)
  const caseMemberIds = useAppSelector(getCaseMemberIdsSelector)

  const { invite, isLoading: isProcessing } = useCaseInviteMembersRequest()

  const onSubmitHandler = useCallback(
    (value: TAccountsSelectOptionData[]) => {
      if (!caseId) {
        return
      }

      const addAccountIds = value.map(({ id }) => id)

      invite({ caseId, addAccountIds })
    },
    [caseId, invite]
  )

  const {
    state: { page, search, selectedAccounts, searchBy },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSearchBy,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({ onSubmit: onSubmitHandler, accounts: [] })

  const maxMembersDirectAllowed =
    caseMembersDirectLimit && caseMembersDirectLimit - activeMembersNumber - inactiveMembersNumber

  const maxMembersGroupAllowed =
    caseMembersGroupLimit && caseMembersGroupLimit - activeMembersNumber - inactiveMembersNumber

  const selectedDirectCounter =
    maxMembersDirectAllowed && maxMembersDirectAllowed > 0
      ? `${selectedAccounts.length}/${maxMembersDirectAllowed}`
      : ''

  const selectedGroupCounter =
    maxMembersGroupAllowed && maxMembersGroupAllowed > 0
      ? `${selectedAccounts.length}/${maxMembersGroupAllowed}`
      : ''

  const selectedCounter =
    caseType === CaseTypesEnum.A2O ? selectedDirectCounter : selectedGroupCounter
  const maxSelected =
    caseType === CaseTypesEnum.A2O ? maxMembersDirectAllowed : maxMembersGroupAllowed
  const caseMembersLimit =
    caseType === CaseTypesEnum.A2O ? caseMembersDirectLimit : caseMembersGroupLimit

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey: ['contacts', page, search, selectedAccounts, searchBy],
    queryFn: () =>
      getContacts({
        accountId,
        page,
        search,
        searchBy,
        excludeIds: caseMemberIds
      }),
    keepPreviousData: true
  })

  const { options, onCascaderChange, onLoadDepartments } = useAllContactsSearchByOptions({
    onChange: onSearchBy
  })

  return (
    <AccountsSelect
      CustomSearchComponent={
        <SearchCascader
          searchSize={EInputSize.L}
          placeholder={t('cases.modal.inviteMembers.searchPlaceholder')}
          options={options}
          onSearchChange={onSearch}
          loadData={onLoadDepartments}
          onCascaderChange={onCascaderChange}
        />
      }
      items={data?.results ?? []}
      total={data?.total}
      selectedItems={selectedAccounts}
      loading={isLoading}
      submitLoading={isProcessing || isFetching}
      page={page}
      search={search}
      error={error}
      alert={<CaseInviteMembersAlert type={caseType} caseMembersLimit={caseMembersLimit} />}
      submitLabel={t('cases.modal.inviteMembers.submitButton', {
        context: selectedCounter && 'count',
        number: selectedCounter
      })}
      maxSelected={maxSelected}
      selectTitle={t('cases.modal.inviteMembers.select.title')}
      emptyListText={t('cases.modal.inviteMembers.select.placeholder')}
      emptyContentText={t('cases.modal.inviteMembers.select.placeholder_selected')}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onUnselect={onUnselect}
      onUnselectAll={onUnselectAll}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
      onClickBack={onClickBack}
    />
  )
}
