import { actionCreator, TError } from 'store'

import * as actionTypes from './showcases.actionTypes'
import {
  TGetShowcaseCommentCountersSuccessPayload,
  TGetShowcaseCommentCountersRequestPayload
} from './showcases.types'

export const getShowcaseCommentCountersRequest = (
  payload: TGetShowcaseCommentCountersRequestPayload
) => actionCreator(actionTypes.GET_SHOWCASE_COMMENT_COUNTERS_REQUEST, payload)

export const getShowcaseCommentCountersSuccess = (
  payload: TGetShowcaseCommentCountersSuccessPayload
) => actionCreator(actionTypes.GET_SHOWCASE_COMMENT_COUNTERS_SUCCESS, payload)

export const getShowcaseCommentCountersError = (payload: TError) =>
  actionCreator(actionTypes.GET_SHOWCASE_COMMENT_COUNTERS_ERROR, payload)
