import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { Payments } from 'App/components'

export type TPaymentsContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  role: state.global.accountData?.role?.name,
  type: state.global.accountData?.type?.name,
  displayUserName: state.global.accountData?.displayUserName
})

export const PaymentsContainer = connect(mapStateToProps)(Payments)
