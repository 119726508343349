import { useCallback } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { useMutation } from 'services/query'
import { deleteAccount } from 'api/settings'
import { getMapComponent } from 'utils'
import { accountTypeNameSelector, useAppSelector } from 'store'
import { Button, ConfirmationModal, Alert } from 'App/components/common'

import { SettingsSecurityDeleteAccountConfirmList } from './SettingsSecurityDeleteAccountConfirmList'
import styles from './SettingsSecurityDeleteAccountConfirm.module.scss'

const CONTENT = new Map<AccountTypeNames, (t: TFunction) => JSX.Element>()
  .set(AccountTypeNames.BUSINESS, (t) => (
    <SettingsSecurityDeleteAccountConfirmList
      items={t('modal.deleteAccountConfirm.content_business', { returnObjects: true })}
    />
  ))
  .set(AccountTypeNames.PROFESSIONAL, (t) => (
    <>
      <Alert className={styles.note} variant="info" size="sm">
        {t('modal.deleteAccountConfirm.alert')}
      </Alert>

      <SettingsSecurityDeleteAccountConfirmList
        items={t('modal.deleteAccountConfirm.content_professional', { returnObjects: true })}
      />
    </>
  ))

export const SettingsSecurityDeleteAccountConfirm = () => {
  const accountType = useAppSelector(accountTypeNameSelector)

  const { t } = useTranslation()

  const { isLoading, error, mutate } = useMutation({
    mutationKey: ['delete-account'],
    mutationFn: deleteAccount
  })

  const handleClick = useCallback(() => {
    mutate()
  }, [mutate])

  return (
    <>
      <ConfirmationModal
        classes={{ content: styles.content, controls: styles.controls }}
        content={getMapComponent(CONTENT, accountType, t)}
        error={error}
        controls={
          <Button loading={isLoading} onClick={handleClick}>
            {t('modal.deleteAccountConfirm.submitButton')}
          </Button>
        }
      />
    </>
  )
}
