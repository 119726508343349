import { BusinessAccountRoleNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'

import styles from './UserProfileAdministrationInviteLabel.module.scss'

export type TUserProfileAdministrationInviteLabelProps = {
  role: BusinessAccountRoleNameEnum
}

export const UserProfileAdministrationInviteLabel = ({
  role
}: TUserProfileAdministrationInviteLabelProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <AccountPlusIcon className={styles.icon} />
      <span>{t('invitations.inviteToLabel', { context: role })}</span>
    </div>
  )
}
