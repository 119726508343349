import { useContext, useEffect, useLayoutEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  joinVoiceRoomRequest,
  useAppSelector,
  getOrganizationBasePathSelector,
  getIsParticipantsLimitReachedSelector,
  createProcessingSelector,
  JOIN_VOICE_ROOM
} from 'store'
import { OrganizationContentHeader, UnityHubChannelIcon } from 'App/components'
import { useAdaptiveLayout, usePrevious, useRefValue } from 'App/hooks'

import { CallContext } from '../../Calls/CallContext'

import styles from './VoiceRoomContainer.module.scss'

export type TVoiceRoomContainerProps = {
  organizationId: string
}

export const VoiceRoomContainer = ({ organizationId }: TVoiceRoomContainerProps) => {
  const { id: voiceRoomId } = useParams<{ id: string }>()

  const dispatch = useDispatch()
  const { push } = useHistory()
  const { setVoiceRoomContainer } = useContext(CallContext)

  const { isDesktop } = useAdaptiveLayout()

  const activeVoiceRoom = useAppSelector((state) =>
    state.organizations.activeVoiceRoom?.id === voiceRoomId
      ? state.organizations.activeVoiceRoom
      : null
  )
  const basePath = useAppSelector(getOrganizationBasePathSelector(organizationId))
  const isParticipantsLimitReached = useAppSelector(
    getIsParticipantsLimitReachedSelector(voiceRoomId)
  )

  const processing = useAppSelector(createProcessingSelector([JOIN_VOICE_ROOM]))
  const { getValue: getProcessing } = useRefValue(processing)

  const prevActiveVoiceRoomId = usePrevious(activeVoiceRoom?.id)
  const wasActive = prevActiveVoiceRoomId === voiceRoomId

  useEffect(() => {
    if (!activeVoiceRoom && (wasActive || isParticipantsLimitReached)) {
      push(basePath)
    }
  }, [activeVoiceRoom, basePath, isParticipantsLimitReached, push, wasActive])

  useLayoutEffect(() => {
    if (voiceRoomId && !activeVoiceRoom && !wasActive && !getProcessing()) {
      dispatch(joinVoiceRoomRequest({ voiceRoomId }))
    }
  }, [dispatch, voiceRoomId, activeVoiceRoom, wasActive, getProcessing])

  return (
    <div className={styles.root}>
      {!isDesktop && (
        <OrganizationContentHeader
          title={activeVoiceRoom?.name ?? ''}
          icon={<UnityHubChannelIcon gutter={false} variant="voice" />}
        />
      )}
      <div className={styles.voiceRoom} ref={setVoiceRoomContainer} />
    </div>
  )
}
