import { memo, useCallback } from 'react'
import { areEqual } from 'react-window'
import { Skeleton } from 'antd'

import { useRefValue } from 'App/hooks'

import { AccountsSelectOption, TAccountsSelectOptionData } from '../AccountsSelectOption'

import styles from './AccountsSelectListRow.module.scss'

export type TRowData = {
  results: TAccountsSelectOptionData[]
  selectedItemsSet: Set<string>
  onSelect: (value: TAccountsSelectOptionData) => void
  onUnselect: (id: string) => void
  disabled?: boolean
  autoOnline?: boolean
}

type TRowProps = {
  style: React.CSSProperties
  index: number
  data: TRowData
}

const RowView = ({ style, index, data }: TRowProps) => {
  const { results, selectedItemsSet, onSelect, onUnselect, disabled, autoOnline } = data
  const item = results[index]
  const selected = selectedItemsSet.has(item.id)

  const { getValue: getSelected } = useRefValue(selected)

  const handleClick = useCallback(
    (value: TAccountsSelectOptionData) => (getSelected() ? onUnselect(value.id) : onSelect(value)),
    [getSelected, onSelect, onUnselect]
  )

  if (!item) {
    return (
      <div style={style} className={styles.root}>
        <Skeleton active paragraph={false} />
      </div>
    )
  }

  return (
    <div style={style} className={styles.root}>
      <AccountsSelectOption
        data={item}
        disabled={(disabled && !selected) || item.disabled}
        selected={selected}
        autoOnline={autoOnline}
        onClick={handleClick}
      />
    </div>
  )
}

export const AccountsSelectListRow = memo(RowView, areEqual)
