import { Redirect, Switch } from 'react-router-dom'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { RoleRouteContainer } from 'App/containers'
import { getKeyForRoute, notificationsNestedRoutes } from 'App/App.config'
import { NotificationsReadAllButton } from 'App/containers/Notifications/NotificationsReadAllButton'

import styles from './Notifications.module.scss'

const DEFAULT_PATH = '/notifications'

// Commented out due to the need to disable functionality for the task MED-7867

// const accountTypeId = useAppSelector((state) => state.global.accountData?.type.id)

// const { menuList } = useNotificationsMenuList()

// const shouldShowTabs = accountTypeId === AccountTypeIdEnum.PROFESSIONAL
export const Notifications = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div
        className={cls(
          styles.header
          // { [styles.withTabs]: shouldShowTabs && !isDesktop }
        )}
      >
        <h1 className={styles.title}>{t('title', { ns: 'notifications' })}</h1>
        <NotificationsReadAllButton iconPosition="left" size="xs" />
      </div>

      <div className={styles.wrapper}>
        {/* {shouldShowTabs && (
          <TabMenu
            menuList={menuList}
            tabVariant="static"
            navMenuProps={{ classes: { item: styles.menuItem } }}
          />
        )} */}

        <main className={styles.main}>
          <Switch>
            {notificationsNestedRoutes.map((item, index) => (
              <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
            ))}

            <Redirect to={DEFAULT_PATH} />
          </Switch>
        </main>
      </div>
    </div>
  )
}
