import { normalize, schema } from 'normalizr'

import { TIds } from 'store'

import { TTrashBinNormalized, TTrashBinItem } from './trashBin.types'

const trashBinSchema = new schema.Entity('list')

const trashBinListSchema = new schema.Array(trashBinSchema)

export const trashBinNormalize = (originalData: TTrashBinItem[]) => {
  const normalizedData = normalize<TTrashBinItem, TTrashBinNormalized, TIds>(
    originalData,
    trashBinListSchema
  )

  return {
    list: normalizedData.entities.list || {},
    ids: normalizedData.result || []
  }
}
