import { useTranslation } from 'react-i18next'

import { ReactComponent as NotFoundImage } from 'assets/images/NotFound.svg'

import { WarningPage } from '../WarningPage'

import styles from './PageNotFound.module.scss'

export const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <WarningPage
      image={<NotFoundImage className={styles.image} />}
      title={t('common.pageNotFound.title')}
      description={t('common.pageNotFound.description')}
    />
  )
}
