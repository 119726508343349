import { Progress, ProgressProps } from 'antd'

import styles from './UploadingProgress.module.scss'

export type TUploadingProgressProps = Pick<ProgressProps, 'percent'>

export const UploadingProgress = ({ percent }: TUploadingProgressProps) => (
  <Progress
    className={styles.root}
    type="circle"
    width={20}
    strokeWidth={12}
    percent={percent}
    trailColor={styles.trailColor}
    strokeColor={styles.strokeColor}
    format={() => null}
  />
)
