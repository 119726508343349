import { useEffect, useRef, useCallback, useMemo, useLayoutEffect, memo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { ChatTypeEnum } from '@medentee/enums'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window'
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer'
import cls from 'classnames'

import { EmptyList, EEmptyListIconSize, ChatEmptyContactList } from 'App/components'
import { ChatRoomItemContainer, TChatRoomsContainerProps } from 'App/containers'
import { useAdaptiveLayout, useLoadingIds, TUseAdaptiveLayoutReturnProps } from 'App/hooks'
import { TChatPathParams } from 'App/screens'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import { getMoreItemsLoader } from 'utils/virtualList'
import { CHAT_ROOMS_DEFAULT_SHOW_BY, CREATE_GROUP_CHAT_PATH } from 'globalConstants'
import { useChatContext } from 'App/context'
import { EChatViewType } from 'enums'

import styles from './ChatRooms.module.scss'

export type TChatRoomsProps = TChatRoomsContainerProps & {
  caseId?: string
}

type TItemData = {
  roomIds: string[]
  chatViewType: EChatViewType

  selectedChatId?: string
}

type TChatRoomsItemViewProps = Pick<ListChildComponentProps, 'style' | 'index'> & {
  data: TItemData
}

const ITEM_HEIGHT: Record<
  TUseAdaptiveLayoutReturnProps['resolution'],
  Record<EChatViewType, number>
> = {
  desktop: {
    [EChatViewType.CASE]: 50,
    [EChatViewType.P2P]: 78
  },
  tablet: {
    [EChatViewType.CASE]: 50,
    [EChatViewType.P2P]: 74
  },
  mobile: {
    [EChatViewType.CASE]: 50,
    [EChatViewType.P2P]: 66
  }
}

const CASE_CHAT_ROOM_WIDTH = 78
const CHAT_ROOMS_LIST_THRESHOLD = CHAT_ROOMS_DEFAULT_SHOW_BY / 2
const CHAT_ROOMS_LIST_OVERSCAN_COUNT = 4

const ChatRoomsItemView = ({ data, index, style }: TChatRoomsItemViewProps) => {
  const { chatViewType, roomIds, selectedChatId } = data

  return (
    <div
      style={style}
      key={roomIds[index]}
      className={cls(styles.item, styles[`item${chatViewType}`], {
        [styles.itemActive]: selectedChatId && roomIds[index] === selectedChatId
      })}
    >
      <ChatRoomItemContainer chatId={roomIds[index]} />
    </div>
  )
}

const ChatRoomsItem = memo(ChatRoomsItemView, areEqual)

export const ChatRooms = ({
  ids,
  selectedChat,
  activeChatId,
  loading,
  search,
  accountType,
  chatType,
  filters,
  total,
  caseId,
  selectChat,
  resetChat,
  getChatRooms
}: TChatRoomsProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<FixedSizeList | null>(null)
  const scrolled = useRef<boolean>(false)

  const { chatId } = useParams<TChatPathParams>()
  const createChatRouteMatch = useRouteMatch(CREATE_GROUP_CHAT_PATH)

  const { isMobile, resolution } = useAdaptiveLayout()

  const { chatViewType } = useChatContext()

  const roomIds = useLoadingIds({ ids, loading, limit: CHAT_ROOMS_DEFAULT_SHOW_BY })

  useEffect(() => {
    if (chatId && !createChatRouteMatch?.isExact && ids.length && chatId !== activeChatId) {
      selectChat({
        nextChatId: chatId
      })

      return
    }

    if ((!chatId && selectedChat?.type === ChatTypeEnum.GROUP) || createChatRouteMatch?.isExact) {
      resetChat()
    }
  }, [
    chatId,
    ids,
    activeChatId,
    selectedChat?.id,
    selectChat,
    resetChat,
    selectedChat?.type,
    createChatRouteMatch?.isExact
  ])

  useLayoutEffect(() => {
    if (
      listRef.current &&
      selectedChat?.id &&
      !scrolled.current &&
      roomIds.includes(selectedChat.id) &&
      wrapperRef.current
    ) {
      const itemIndex = roomIds.findIndex((id) => id === selectedChat.id)

      listRef.current.scrollToItem(itemIndex, 'center')
      scrolled.current = true
    }
  }, [roomIds, selectedChat?.id])

  const isItemLoaded = useCallback((index: number) => !!roomIds[index], [roomIds])

  const loadMoreItems = useMemo(
    () =>
      getMoreItemsLoader({
        limit: CHAT_ROOMS_DEFAULT_SHOW_BY,
        onLoadNext: (page: number) =>
          !loading &&
          page > Number(filters.page) &&
          getChatRooms({ caseId, chatType: [chatType], page, showBy: CHAT_ROOMS_DEFAULT_SHOW_BY })
      }),
    [caseId, chatType, filters.page, getChatRooms, loading]
  )

  const itemSize = ITEM_HEIGHT[resolution][chatViewType]
  const itemData = useMemo<TItemData>(
    () => ({
      roomIds,
      chatViewType,
      selectedChatId: selectedChat?.id
    }),
    [chatViewType, roomIds, selectedChat?.id]
  )

  const getContent = useCallback(() => {
    const showMobileEmptyPlaceholder = !search && !roomIds.length && isMobile
    const showEmptyPlaceholder =
      (!isEmpty(filters) && !roomIds.length) || (search && !roomIds.length)
    const showChatRooms = loading || roomIds.length > 0
    const hasAppliedFilters = !!filters.caseRole && filters.caseRole !== 'all'

    if (showMobileEmptyPlaceholder) {
      return <ChatEmptyContactList chatType={chatType} accountType={accountType} />
    }

    if (showEmptyPlaceholder) {
      return (
        <EmptyList
          hasAppliedFilters={hasAppliedFilters}
          hasAppliedSearch={!!search}
          icon={<AccountMultipleIcon />}
          iconSize={EEmptyListIconSize.MD}
        />
      )
    }

    if (showChatRooms) {
      return (
        <AutoSizer className={styles.autoSizer}>
          {({ height, width }) => (
            <InfiniteLoader
              itemCount={total}
              threshold={CHAT_ROOMS_LIST_THRESHOLD}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  ref={(list) => {
                    listRef.current = list
                    ref(list)
                  }}
                  height={height}
                  width={chatViewType === EChatViewType.CASE ? CASE_CHAT_ROOM_WIDTH : width}
                  itemCount={roomIds.length}
                  itemData={itemData}
                  overscanCount={CHAT_ROOMS_LIST_OVERSCAN_COUNT}
                  itemSize={itemSize}
                  onItemsRendered={onItemsRendered}
                >
                  {ChatRoomsItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )
    }

    return null
  }, [
    search,
    roomIds.length,
    isMobile,
    filters,
    loading,
    chatType,
    accountType,
    total,
    isItemLoaded,
    loadMoreItems,
    chatViewType,
    itemData,
    itemSize
  ])

  return (
    <div className={styles.root} ref={wrapperRef}>
      {getContent()}
    </div>
  )
}
