import { useParams } from 'react-router-dom'

import { TChatRoomHeaderContainerProps } from 'App/containers'
import {
  ChatRoomHeaderWrapper,
  ChatRoomHeaderUser,
  ChatRoomHeaderActions,
  ChatPinTitle
} from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { ECaseAccountType, EIconSize } from 'enums'
import { TCaseParams } from 'store'
import { useChatContext } from 'App/context'

import { ChatRoomBackAction } from '../ChatRoomBackAction'

import styles from './ChatRoomHeaderCaseOwner.module.scss'

export type TChatRoomHeaderCaseOwnerProps = TChatRoomHeaderContainerProps

export const ChatRoomHeaderCaseOwner = ({
  selectedChat,
  online,
  lastSeen,
  caseId,
  caseType,
  isChatRoomMuted,
  loading
}: TChatRoomHeaderCaseOwnerProps) => {
  const { isMobile } = useAdaptiveLayout()
  const { who } = useParams<TCaseParams>()

  const { showPinnedMessages } = useChatContext()

  return (
    <ChatRoomHeaderWrapper
      loading={loading}
      backButton={<ChatRoomBackAction caseType={caseType} />}
      title={
        showPinnedMessages ? (
          <ChatPinTitle />
        ) : (
          <ChatRoomHeaderUser
            isChatRoomMuted={isChatRoomMuted}
            disabled={!!selectedChat?.case?.id}
            avatar={isMobile}
            online={online}
            lastSeen={lastSeen}
            selectedChat={selectedChat}
            isCase={true}
          />
        )
      }
      actions={
        selectedChat &&
        !showPinnedMessages && (
          <ChatRoomHeaderActions
            id={selectedChat.id}
            type={selectedChat.type}
            status={selectedChat.status}
            userName={selectedChat.interlocutorAccount?.displayUserName}
            iconSize={EIconSize.CUSTOM}
            caseId={caseId}
            isOwner={who === ECaseAccountType.OWNER}
            classes={{ icon: styles.icons }}
          />
        )
      }
      classes={{ root: styles.wrapperRoot }}
    />
  )
}
