import { Col } from 'antd'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { Checkbox, TextField } from 'App/components'
import { validation } from 'utils'
import { MAX_LINK_LENGTH, MIN_LINK_LENGTH } from 'globalConstants'

import { StartDateField } from '../StartDateField'
import { EndDateField } from '../EndDateField'

export const SecondStep = () => {
  const { t } = useTranslation()

  return (
    <>
      <Col xs={24}>
        <StartDateField />
      </Col>
      <Col xs={24}>
        <EndDateField />
      </Col>
      <Field name="noExpirationDate" type="checkbox">
        {({ input: checkboxInput }) => (
          <Col xs={24}>
            <Checkbox
              {...checkboxInput}
              label={t('modal.userLicenses.form.noExpiationDateCheckbox')}
            />
          </Col>
        )}
      </Field>

      <Col xs={24}>
        <Field
          name="link"
          validate={validation.composeValidators(
            validation.minLength(MIN_LINK_LENGTH),
            t('validationErrors.invalidLink', { ns: 'errors' }),
            validation.maxLength(MAX_LINK_LENGTH),
            t('validationErrors.longLink', { length: MAX_LINK_LENGTH, ns: 'errors' }),
            validation.isUrl()
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              topLabel={t('modal.userLicenses.form.linkField')}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              valueLengthMax={MAX_LINK_LENGTH}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
