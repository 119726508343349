import { Fragment, ReactNode } from 'react'
import { Row } from 'antd'

import { generateRandomArray } from 'utils'
import { FileTilesViewGridItemSkeleton } from 'App/components'

import styles from './FileTilesViewGrid.module.scss'

export type TFileTilesViewGridProps<D> = PropsWithClassName<{
  data: D[]
  children: (props: D) => JSX.Element

  loading?: boolean
  itemSkeleton?: ReactNode
  skeletonLength?: number
}>

export function FileTilesViewGrid<D>({
  data,
  children,
  loading,
  skeletonLength,
  itemSkeleton = <FileTilesViewGridItemSkeleton />
}: TFileTilesViewGridProps<D>) {
  return (
    <Row className={styles.root} justify="start">
      {skeletonLength && loading
        ? generateRandomArray(skeletonLength).map((key) => (
            <Fragment key={key}>{itemSkeleton}</Fragment>
          ))
        : data.map((item) => children(item))}
    </Row>
  )
}
