import cls from 'classnames'

import styles from './ChatMessageSenderName.module.scss'

type TChatMessageSenderName = PropsWithClassName<{
  senderName?: string | null
}>

export const ChatMessageSenderName = ({ senderName, className }: TChatMessageSenderName) =>
  senderName ? <div className={cls(styles.senderName, className)}>{senderName}</div> : null
