import { combineReducers, AnyAction } from 'redux'

import {
  globalReducer as global,
  loadingReducer as loading,
  errorsReducer as errors,
  contactsReducer as contacts,
  categoryListReducer as categoryList,
  modalReducer as modal,
  chatRoomsReducer as chatRooms,
  chatMessagesReducer as chatMessages,
  filesReducer as file,
  filesSharedReducer as fileShared,
  onlineReducer as online,
  medCloudReducer as medCloud,
  caseMembersReducer as caseMembers,
  caseCreateReducer as caseCreate,
  casesReducer as cases,
  casesInviteReducer as casesInvite,
  caseViewReducer as caseView,
  caseCloudReducer as caseCloud,
  caseDetailsReducer as caseDetails,
  userProfileReducer as userProfile,
  miscReducer as misc,
  chatPinReducer as chatPin,
  callsReducer as calls,
  notificationsReducer as notifications,
  extendableCardReducer as extendableCard,
  invitationsReducer as invitations,
  administrationReducer as administration,
  settingsReducer as settings,
  showcasesReducer as showcases,
  subscriptionsReducer as subscriptions,
  systemReducer as system,
  paymentsReducer as payments,
  trackedNotificationsReducer as trackedNotifications,
  advertsReducer as adverts,
  trashBinReducer as trashBin,
  videoStreamingReducer as videoStreaming,
  meetingsReducer as meetings,
  broadcastReducer as broadcast,
  organizationsReducer as organizations,
  communitiesReducer as communities,
  eventsReducer as events,
  broadcastsReducer as broadcasts
} from 'store'

const chatReducer = combineReducers({
  broadcast,
  chatMessages,
  chatPin,
  chatRooms
})

const appReducer = combineReducers({
  administration,
  adverts,
  contacts,
  broadcasts,
  calls,
  caseCloud,
  caseCreate,
  caseDetails,
  caseMembers,
  caseView,
  cases,
  casesInvite,
  categoryList,
  chat: chatReducer,
  communities,
  errors,
  events,
  extendableCard,
  file,
  fileShared,
  global,
  invitations,
  loading,
  medCloud,
  meetings,
  misc,
  modal,
  notifications,
  online,
  organizations,
  payments,
  settings,
  showcases,
  subscriptions,
  system,
  trackedNotifications,
  trashBin,
  userProfile,
  videoStreaming
})

export type State = ReturnType<typeof appReducer>

const rootReducer = (state: any, action = {}) => appReducer(state, action as AnyAction)

export default rootReducer
