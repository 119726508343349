import { generatePath, Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import {
  CHAT_PATH,
  EChatTypeMap,
  KNOWLEDGE_BASE_URL,
  PRIVACY_POLICY_URL,
  SUPPORT_EMAIL,
  TERMS_OF_USE_URL
} from 'globalConstants'
import { supportChatIdSelector, useAppSelector } from 'store'
import { ReactComponent as SupportIcon } from 'assets/icons/Support.svg'
import { ReactComponent as LightbulbOnIcon } from 'assets/icons/LightbulbOn.svg'
import imageSrc from 'assets/images/help_and_support.jpg'

import { Button } from '../../../common'

import styles from './HelpAndSupport.module.scss'
export const HelpAndSupport = () => {
  const supportChatId = useAppSelector(supportChatIdSelector)

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <img alt="Help and support" src={imageSrc} className={styles.img} />
      <p className={styles.description}>
        {t('settings.helpAndSupport.description')}{' '}
        <Trans
          t={t}
          i18nKey="settings.helpAndSupport.askMedentee"
          components={{
            link: (
              <Link
                to={generatePath(CHAT_PATH, {
                  chatType: EChatTypeMap.DIALOGUE,
                  chatId: supportChatId
                })}
              >
                {t('settings.helpAndSupport.askMedenteeLink')}
              </Link>
            )
          }}
        />
      </p>

      <div className={styles.info}>
        <div className={styles.infoBlock}>
          <SupportIcon className={styles.icon} />
          <p>{t('settings.helpAndSupport.contactSupport')}</p>
          <Button variant="outlined" onClick={() => window.open(`mailto:${SUPPORT_EMAIL}`)}>
            {t('settings.helpAndSupport.contactSupportButton')}
          </Button>
        </div>
        <div className={styles.infoBlock}>
          <LightbulbOnIcon className={styles.icon} />
          <p>{t('settings.helpAndSupport.knowledgeBase')}</p>
          <Button variant="outlined" onClick={() => window.open(KNOWLEDGE_BASE_URL)}>
            {t('settings.helpAndSupport.knowledgeBaseButton')}
          </Button>
        </div>
      </div>
      <div className={styles.links}>
        <Button variant="underlined" onClick={() => window.open(TERMS_OF_USE_URL)}>
          {t('settings.helpAndSupport.termsOfUse')}
        </Button>
        <Button variant="underlined" onClick={() => window.open(PRIVACY_POLICY_URL)}>
          {t('settings.helpAndSupport.privacyPolicy')}
        </Button>
      </div>
    </div>
  )
}
