import { useCallback, useEffect, useRef } from 'react'
import { Field, Form } from 'react-final-form'
import { CaseClassificationNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { CaseDetailsContainer, CaseDetailsPlaceholder, Editor } from 'App/components'
import { CaseDescriptionItemContainer } from 'App/containers'
import {
  getNotificationsIdsSelector,
  readNotificationFromIdRequest,
  TIds,
  updateCaseDraftRequest
} from 'store'
import { TAddCaseDescriptionRequestPayload } from 'store/caseDetails/caseDetails.types'
import { ECaseAccountType, ECasesDocumentKey } from 'enums'
import { validation, generateRandomArray, getMapComponent } from 'utils'
import { IDefaultStandaloneNotificationDTO } from 'interfaces'
import { useCaseNewDividerLine, usePrevious } from 'App/hooks'

export type TCaseDescriptionListProps = {
  newLineDescriptionId: null | string
  caseId: string
  ids: TIds
  count: number
  who: ECaseAccountType
  processing: boolean
  loading: boolean
  isOwner: boolean
  isCaseArchived: boolean
  notifications: IDefaultStandaloneNotificationDTO[]
  draft: string
  addCaseDescription: (payload: TAddCaseDescriptionRequestPayload) => void
  readNotificationFromId: typeof readNotificationFromIdRequest
  updateCaseDraft: typeof updateCaseDraftRequest

  classification?: CaseClassificationNameEnum
}

export type TAddDescriptionValues = {
  description: string
}

const DESCRIPTION_MAX_LENGTH = 6000
const DEFAULT_DESCRIPTION_LENGTH = 3

type TCaseOptionsLabelProps = {
  t: TFunction

  classification?: CaseClassificationNameEnum
}

const CASE_OPINIONS_LABEL = new Map<
  ECaseAccountType,
  (props: TCaseOptionsLabelProps) => JSX.Element
>()
  .set(ECaseAccountType.OWNER, ({ t }) => <>{t('cases.details.description.placeholder')}</>)
  .set(ECaseAccountType.MEMBER, ({ t }) => <>{t('cases.details.description.placeholder')}</>)

export const CaseDescriptionList = ({
  caseId,
  ids,
  count,
  who,
  processing,
  loading,
  isOwner,
  newLineDescriptionId,
  notifications,
  isCaseArchived,
  classification,
  draft,
  addCaseDescription,
  readNotificationFromId,
  updateCaseDraft
}: TCaseDescriptionListProps) => {
  const resetForm = useRef<(initialValues?: TAddDescriptionValues) => void>()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const prevIds = usePrevious<TIds>(ids)

  const { t } = useTranslation()

  const handleAddDescription = useCallback(
    (values: Partial<TAddDescriptionValues>) => {
      addCaseDescription({ caseId, description: values.description || '' })
    },
    [addCaseDescription, caseId]
  )

  const readNotifications = useCallback(() => {
    readNotificationFromId({
      notificationIds: getNotificationsIdsSelector(notifications)
    })
  }, [notifications, readNotificationFromId])

  const { newDividerLineRef } = useCaseNewDividerLine({
    lineId: newLineDescriptionId,
    readNotifications
  })

  useEffect(() => {
    resetForm.current && resetForm.current()
  }, [ids])

  useEffect(() => {
    if (wrapperRef.current && prevIds?.length && prevIds.length !== ids.length) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
    }
  }, [ids, prevIds])

  const description = loading ? generateRandomArray(DEFAULT_DESCRIPTION_LENGTH) : ids

  const content = description.length ? (
    description.map((id) => (
      <CaseDescriptionItemContainer key={id} id={id} newDividerLineRef={newDividerLineRef} />
    ))
  ) : (
    <CaseDetailsPlaceholder>
      {getMapComponent(CASE_OPINIONS_LABEL, who, { classification, t })}
    </CaseDetailsPlaceholder>
  )

  const footer = isOwner && !isCaseArchived && !loading && (
    <Form onSubmit={handleAddDescription}>
      {({ handleSubmit, form: { getState, reset } }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event)
            resetForm.current = reset
          }}
        >
          <Field
            name="description"
            validate={validation.composeValidators(
              validation.required(),
              validation.maxLength(DESCRIPTION_MAX_LENGTH - count),
              validation.onlySpaces()
            )}
            // This huck is required to override default logic when
            // the filed value set to `undefined` instead of ''
            parse={(value) => value}
            initialValue={draft}
          >
            {({ input: { onChange, ...input } }) => {
              const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChange(event.target.value)
                updateCaseDraft({
                  message: event.target.value,
                  caseId,
                  type: ECasesDocumentKey.DESCRIPTION
                })
              }

              return (
                <Editor
                  {...input}
                  initialLength={count}
                  valueLengthMax={DESCRIPTION_MAX_LENGTH}
                  minRows={2}
                  maxRows={4}
                  placeholder={t('cases.details.description.editor.placeholder')}
                  error={getState().invalid}
                  loading={processing}
                  onChange={handleChange}
                />
              )
            }}
          </Field>
        </form>
      )}
    </Form>
  )

  return <CaseDetailsContainer content={content} footer={footer} wrapperRef={wrapperRef} />
}
