import { Skeleton } from 'antd'
import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'

import { EAvatarSize } from 'enums'
import { AccountName } from 'App/components'
import { TAvatarContainerOwnProps } from 'App/containers'

export type TAccountsListItemProps = PropsWithClassName<{
  loading?: boolean
  hideIcon?: boolean
}> &
  Pick<
    PartialBy<TAvatarContainerOwnProps, 'size'>,
    'userId' | 'displayUserName' | 'firstName' | 'lastName' | 'type' | 'hasTooltip' | 'size'
  >

export const AccountsListItem = ({
  userId,
  displayUserName,
  firstName,
  lastName,
  loading,
  className,
  hideIcon,
  type = AccountTypeNames.PROFESSIONAL,
  size = EAvatarSize.XS
}: TAccountsListItemProps) => (
  <div className={cls(className)}>
    {loading ? (
      <Skeleton.Avatar active={true} />
    ) : (
      <AccountName
        autoOnline
        showAvatar
        size={size}
        type={type}
        userId={userId}
        lastName={lastName}
        firstName={firstName}
        displayUserName={displayUserName}
        hideIcon={hideIcon}
      />
    )}
  </div>
)
