import { useCallback, useEffect } from 'react'

import { InfiniteData, QueryObserverResult, useMutation, useQueryClient } from 'services/query'
import { TResponseError } from 'utils'
import { IShowcaseComment, IShowcaseReply } from 'interfaces'
import { EHistoryEvents } from 'enums'
import { deleteCommentById, getReplies } from 'api/showcase'
import eventBus from 'services/eventBus'
import { TUseBidirectionalRequest, useBidirectionalRequest } from 'App/hooks/BidirectionalList'
import { useEvent } from 'App/hooks'
import { TComment } from 'App/components'

import { getSelectFormatter } from './utils'

export type TUseRepliesRequest = Pick<
  TUseBidirectionalRequest<IShowcaseReply, TComment>,
  'queryKey'
> & {
  commentId: string
  showcaseId: string
  getComment: () => Promise<QueryObserverResult<IShowcaseReply, TResponseError>>
}

const MUTATION_KEY_DELETE_REPLY = 'MUTATION_KEY_DELETE_REPLY'

const select = getSelectFormatter({ isReply: true })

export const useRequestReplies = ({
  queryKey,
  showcaseId,
  commentId,
  getComment
}: TUseRepliesRequest) => {
  const queryClient = useQueryClient()

  const {
    loading,
    anchorId,
    data: replies,
    hasAfter,
    hasBefore,
    historyEvent,
    fetchData
  } = useBidirectionalRequest<IShowcaseReply, TComment>({
    queryKey,
    select,
    queryFn: (payload) =>
      getReplies({
        ...payload,
        commentId,
        showcaseId
      })
  })

  const { isLoading: deleteProcessing, mutate: deleteComment } = useMutation({
    mutationKey: [MUTATION_KEY_DELETE_REPLY, showcaseId],
    mutationFn: deleteCommentById
  })

  const updatePages = useCallback(
    (id: string) => {
      queryClient.setQueryData<InfiniteData<IShowcaseReply[]>>(queryKey, (data) => {
        const pages = data?.pages.map((page) => {
          const foundIndex = page.findIndex((item) => item.id === id)
          let nextPage = [...page]

          if (foundIndex !== -1) {
            nextPage = page.filter((item) => item.id !== id)
          }

          return nextPage
        })

        return {
          pages: pages ?? [],
          pageParams: data?.pageParams ?? []
        }
      })
    },
    [queryClient, queryKey]
  )

  const invalidateQuery = useEvent((repliesAccounts?: IShowcaseComment['repliesAccounts']) => {
    if (repliesAccounts?.length && !replies.length) {
      queryClient.invalidateQueries({ queryKey: [queryKey], type: 'active' })
    }
  })

  const handleDeleteSuccess = useCallback(
    async (id: string) => {
      updatePages(id)

      const res = await getComment()

      invalidateQuery(res.data?.repliesAccounts)
    },
    [getComment, invalidateQuery, updatePages]
  )

  const handleDelete = useCallback(
    (id: string) => () => {
      deleteComment(
        { showcaseId, commentId: id },
        {
          onSuccess: () => handleDeleteSuccess(id)
        }
      )
    },
    [deleteComment, showcaseId, handleDeleteSuccess]
  )

  useEffect(() => {
    const unsubscribe = eventBus.showcaseReplySuccess.subscribe((eventData) => {
      if (eventData.commentId === commentId) {
        fetchData(eventData.anchorId, EHistoryEvents.JUMP)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [fetchData, commentId])

  return {
    loading,
    deleteProcessing,
    hasAfter,
    hasBefore,
    historyEvent,
    anchorId,
    replies,
    fetchData,
    handleDelete
  }
}
