import { PropsWithChildren } from 'react'
import cls from 'classnames'
import { Trans, useTranslation } from 'react-i18next'

import styles from './BasicSubscriptionOption.module.scss'

export const BasicSubscriptionOption = ({
  children,
  className
}: PropsWithChildren<PropsWithClassName>) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, className)}>
      {children ?? (
        <Trans
          t={t}
          i18nKey="common.subscriptionInfoCard.detailsList.basic"
          components={{ b: <b /> }}
        />
      )}
    </div>
  )
}
