import { forwardRef, Ref, useImperativeHandle, useRef, MouseEvent } from 'react'
import cls from 'classnames'

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import { Chip, TChipProps } from '../Chip'

import styles from './BadgeItem.module.scss'

type TBadgeItemProps = PropsWithClassName<{
  title: string

  id?: string
  editable?: boolean
  disabled?: boolean
  onRemove?: (id: string) => void
}> &
  Omit<TChipProps, 'text'>

const BadgeItemView = (
  {
    title,
    id,
    className,
    onRemove,
    editable,
    disabled = false,
    variant = 'light',
    ...chipProps
  }: TBadgeItemProps,
  forwardedRef: Ref<HTMLDivElement | null>
) => {
  const ref = useRef<HTMLDivElement>(null)

  const iconClickHandler = (e: MouseEvent) => {
    e.stopPropagation()
    if (id && onRemove) {
      onRemove(id)
    }

    return
  }

  useImperativeHandle(forwardedRef, () => ref.current)

  const shouldShowRemovalButton = !!onRemove && !editable && !disabled

  return (
    <Chip
      className={cls(styles.chip, className)}
      ref={ref}
      variant={variant}
      text={title}
      editable={editable}
      postfix={
        shouldShowRemovalButton && <CrossIcon onClick={iconClickHandler} className={styles.icon} />
      }
      {...chipProps}
    />
  )
}

export const BadgeItem = forwardRef<HTMLDivElement, TBadgeItemProps>(BadgeItemView)
