import { action } from 'store/store.utils'
import { TError } from 'store/store.types'

import {
  TCaseLockRequestPayload,
  TCaseUnLockRequestPayload,
  TCaseViewRequestPayload,
  TCaseViewSuccessPayload,
  TCheckCaseUnlockingPayload,
  TCaseStatusChangedSuccessPayload,
  TCaseArchiveRequestPayload,
  TCaseStatusChangedRequestPayload,
  TCaseClearNotificationPayload,
  TPublicationOpinionsRequestPayload,
  TReceivePublicOpinionsChangedPayload,
  TPublicationOpinionsSuccessPayload,
  TCaseMemberKickedActionPayload,
  TReceiveCaseOpinionCreatedPayload,
  TSetCaseNotificationActionPayload,
  TCaseConvertRequestPayload,
  TCaseConvertErrorPayload,
  TCaseConvertSuccessPayload,
  TSetCaseCloudPermissionsModeRequestPayload,
  TSetCaseCloudPermissionsModeSuccessPayload,
  TUpdateCaseViewDataActionPayload
} from './caseView.types'

// ------------------ CASE_VIEW ------------------
export const CASE_VIEW = 'CASE_VIEW'
export const CASE_VIEW_ERROR = 'CASE_VIEW_ERROR'
export const CASE_VIEW_REQUEST = 'CASE_VIEW_REQUEST'
export const CASE_VIEW_SUCCESS = 'CASE_VIEW_SUCCESS'

export const caseViewRequest = (payload: TCaseViewRequestPayload) =>
  action(CASE_VIEW_REQUEST, payload)
export const caseViewSuccess = (payload: TCaseViewSuccessPayload) =>
  action(CASE_VIEW_SUCCESS, payload)
export const caseViewError = (payload: TError) => action(CASE_VIEW_ERROR, payload)

// ------------------ UPDATE_CASE_VIEW_DATA_ACTION ------------------

export const UPDATE_CASE_VIEW_DATA_ACTION = 'UPDATE_CASE_VIEW_DATA_ACTION'

export const updateCaseViewDataAction = (payload: TUpdateCaseViewDataActionPayload) =>
  action(UPDATE_CASE_VIEW_DATA_ACTION, payload)

// ------------------ CASE_LOCK ------------------
export const CASE_LOCK = 'CASE_LOCK'
export const CASE_LOCK_REQUEST = 'CASE_LOCK_REQUEST'
export const CASE_LOCK_SUCCESS = 'CASE_LOCK_SUCCESS'
export const CASE_LOCK_ERROR = 'CASE_LOCK_ERROR'

export const caseLockRequest = (payload: TCaseLockRequestPayload) =>
  action(CASE_LOCK_REQUEST, payload)
export const caseLockSuccess = () => action(CASE_LOCK_SUCCESS)
export const caseLockError = (payload: TError) => action(CASE_LOCK_ERROR, payload)

// ------------------ CASE_UNLOCK ------------------
export const CASE_UNLOCK = 'CASE_UNLOCK'
export const CASE_UNLOCK_REQUEST = 'CASE_UNLOCK_REQUEST'
export const CASE_UNLOCK_SUCCESS = 'CASE_UNLOCK_SUCCESS'
export const CASE_UNLOCK_ERROR = 'CASE_UNLOCK_ERROR'

export const caseUnLockRequest = (payload: TCaseUnLockRequestPayload) =>
  action(CASE_UNLOCK_REQUEST, payload)
export const caseUnLockSuccess = () => action(CASE_UNLOCK_SUCCESS)
export const caseUnLockError = (payload: TError) => action(CASE_UNLOCK_ERROR, payload)

// ------------------ CHECK_CASE_UNLOCKING ------------------
export const CHECK_CASE_UNLOCKING = 'CHECK_CASE_UNLOCKING'

export const checkCaseUnlockingAction = (payload: TCheckCaseUnlockingPayload) =>
  action(CHECK_CASE_UNLOCKING, payload)

// ------------------ CASE_STATUS_CHANGED ------------------
export const CASE_STATUS_CHANGED_REQUEST = 'CASE_STATUS_CHANGED_REQUEST'
export const CASE_STATUS_CHANGED_SUCCESS = 'CASE_STATUS_CHANGED_SUCCESS'

export const caseStatusChangedRequest = (payload: TCaseStatusChangedRequestPayload) =>
  action(CASE_STATUS_CHANGED_REQUEST, payload)
export const caseStatusChangedSuccess = (payload: TCaseStatusChangedSuccessPayload) =>
  action(CASE_STATUS_CHANGED_SUCCESS, payload)

// ------------------ RECEIVE_CASE_OPINION_CREATED ------------------
export const RECEIVE_CASE_OPINION_CREATED = 'RECEIVE_CASE_OPINION_CREATED'

export const receiveCaseOpinionCreated = (payload: TReceiveCaseOpinionCreatedPayload) =>
  action(RECEIVE_CASE_OPINION_CREATED, payload)

// ------------------ SET_CASE_NOTIFICATION ------------------
export const SET_CASE_NOTIFICATION = 'SET_CASE_NOTIFICATION'

export const setCaseNotificationAction = (payload: TSetCaseNotificationActionPayload) =>
  action(SET_CASE_NOTIFICATION, payload)

// ------------------ CASE_ARCHIVE ------------------
export const CASE_ARCHIVE = 'CASE_ARCHIVE'
export const CASE_ARCHIVE_REQUEST = 'CASE_ARCHIVE_REQUEST'
export const CASE_ARCHIVE_SUCCESS = 'CASE_ARCHIVE_SUCCESS'
export const CASE_ARCHIVE_ERROR = 'CASE_ARCHIVE_ERROR'

export const caseArchiveRequest = (payload: TCaseArchiveRequestPayload) =>
  action(CASE_ARCHIVE_REQUEST, payload)
export const caseArchiveSuccess = () => action(CASE_ARCHIVE_SUCCESS)
export const caseArchiveError = (payload: TError) => action(CASE_ARCHIVE_ERROR, payload)

export const CASE_CLEAR_NOTIFICATION = 'CASE_CLEAR_NOTIFICATION'

export const caseClearNotificationAction = (payload: TCaseClearNotificationPayload) =>
  action(CASE_CLEAR_NOTIFICATION, payload)

// ------------------ CASE_MEMBER_KICKED ------------------
export const CASE_MEMBER_KICKED_ACTION = 'CASE_MEMBER_KICKED_ACTION'

export const caseMemberKickedAction = (payload: TCaseMemberKickedActionPayload) =>
  action(CASE_MEMBER_KICKED_ACTION, payload)

// ------------------ PUBLICATION_OPINIONS ------------------
export const PUBLICATION_OPINIONS = 'PUBLICATION_OPINIONS'
export const PUBLICATION_OPINIONS_REQUEST = 'PUBLICATION_OPINIONS_REQUEST'
export const PUBLICATION_OPINIONS_SUCCESS = 'PUBLICATION_OPINIONS_SUCCESS'
export const PUBLICATION_OPINIONS_ERROR = 'PUBLICATION_OPINIONS_ERROR'

export const publicationOpinionsRequest = (payload: TPublicationOpinionsRequestPayload) =>
  action(PUBLICATION_OPINIONS_REQUEST, payload)
export const publicationOpinionsSuccess = (payload: TPublicationOpinionsSuccessPayload) =>
  action(PUBLICATION_OPINIONS_SUCCESS, payload)
export const publicationOpinionsError = (payload: TError) =>
  action(PUBLICATION_OPINIONS_ERROR, payload)

// ------------------ RECEIVE_PUBLIC_OPINIONS_CHANGED ------------------
export const RECEIVE_PUBLIC_OPINIONS_CHANGED = 'RECEIVE_PUBLIC_OPINIONS_CHANGED'

export const receivePublicOpinionsChanged = (payload: TReceivePublicOpinionsChangedPayload) =>
  action(RECEIVE_PUBLIC_OPINIONS_CHANGED, payload)

// ------------------ CASE_CONVERT ------------------
export const CASE_CONVERT = 'CASE_CONVERT'
export const CASE_CONVERT_REQUEST = 'CASE_CONVERT_REQUEST'
export const CASE_CONVERT_SUCCESS = 'CASE_CONVERT_SUCCESS'
export const CASE_CONVERT_ERROR = 'CASE_CONVERT_ERROR'

export const caseConvertRequest = (payload: TCaseConvertRequestPayload) =>
  action(CASE_CONVERT_REQUEST, payload)
export const caseConvertSuccess = (payload: TCaseConvertSuccessPayload) =>
  action(CASE_CONVERT_SUCCESS, payload)
export const caseConvertError = (payload: TCaseConvertErrorPayload) =>
  action(CASE_CONVERT_ERROR, payload)

// ------------------ SET_CASE_CLOUD_PERMISSIONS_MODE ------------------
export const SET_CASE_CLOUD_PERMISSIONS_MODE = 'SET_CASE_CLOUD_PERMISSIONS_MODE'
export const SET_CASE_CLOUD_PERMISSIONS_MODE_REQUEST = 'SET_CASE_CLOUD_PERMISSIONS_MODE_REQUEST'
export const SET_CASE_CLOUD_PERMISSIONS_MODE_SUCCESS = 'SET_CASE_CLOUD_PERMISSIONS_MODE_SUCCESS'
export const SET_CASE_CLOUD_PERMISSIONS_MODE_ERROR = 'SET_CASE_CLOUD_PERMISSIONS_MODE_ERROR'

export const setCaseCloudPermissionsModeRequest = (
  payload: TSetCaseCloudPermissionsModeRequestPayload
) => action(SET_CASE_CLOUD_PERMISSIONS_MODE_REQUEST, payload)
export const setCaseCloudPermissionsModeSuccess = (
  payload: TSetCaseCloudPermissionsModeSuccessPayload
) => action(SET_CASE_CLOUD_PERMISSIONS_MODE_SUCCESS, payload)
export const setCaseCloudPermissionsModeError = (payload: TCaseConvertErrorPayload) =>
  action(SET_CASE_CLOUD_PERMISSIONS_MODE_ERROR, payload)
