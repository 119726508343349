import {
  useState,
  useEffect,
  useCallback,
  createContext,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useContext
} from 'react'
import cls from 'classnames'
import { TooltipProps } from 'antd/lib/tooltip'

import { MenuList, TMenuItemProps, Popover } from 'App/components'
import { stopPropagation } from 'utils'
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/DotsVertical.svg'

import styles from './ContextMenu.module.scss'

type TContextMenuClasses = 'root' | 'icon'

export type TContextMenuProps = PropsWithChildren<{
  hideMenuItems?: boolean
  menuItems?: TMenuItemProps[]
  startAdornment?: ReactNode
  endAdornment?: ReactNode
  iconComponent?: JSX.Element
  classes?: Partial<Record<TContextMenuClasses, string>>
  disableIcon?: boolean
  nestIn?: 'body' | 'parent'
  onVisibleChange?: (visible: boolean) => void
}> &
  Partial<Pick<TooltipProps, 'placement' | 'visible' | 'align'>>

export const ContextMenuContext = createContext<{ close: () => void }>({ close: () => null })

export const useContextMenuContext = () => {
  const context = useContext(ContextMenuContext)

  if (context === undefined) {
    throw new Error('useContextMenuContext must be used within a ContextMenuContext')
  }

  return context
}

export const ICON_HEIGHT = 24

export const CONTEXT_MENU_ROOT_CLASS_NAME = 'context-menu-root'
export const CONTEXT_MENU_ICON_CLASS_NAME = 'context-menu-icon'

export const ContextMenu = ({
  menuItems,
  startAdornment,
  endAdornment,
  classes,
  align,
  onVisibleChange,
  nestIn = 'parent',
  children = null,
  disableIcon = false,
  visible = false,
  hideMenuItems = false,
  placement = 'bottomRight',
  iconComponent = <DotsVerticalIcon />
}: TContextMenuProps) => {
  const [menuVisible, setMenuVisible] = useState(visible)

  const showChildren = children || disableIcon
  const defaultAlign = placement === 'bottomRight' ? { offset: [0, -ICON_HEIGHT] } : undefined

  const hasMenuItems = useMemo(
    () => !!menuItems?.filter(({ hidden }) => !hidden).length,
    [menuItems]
  )

  useEffect(() => {
    setMenuVisible(visible)
  }, [visible])

  const handleCloseAfterClick = useCallback(() => {
    setMenuVisible(false)

    if (onVisibleChange) {
      onVisibleChange(false)
    }
  }, [onVisibleChange])

  const handleVisibleChange = useCallback(
    (isOpen: boolean) => {
      setMenuVisible(isOpen)

      if (onVisibleChange) {
        onVisibleChange(isOpen)
      }
    },
    [onVisibleChange]
  )

  if (!hasMenuItems) {
    return null
  }

  return (
    <Popover
      content={() => (
        <ContextMenuContext.Provider value={{ close: handleCloseAfterClick }}>
          {startAdornment}
          {!hideMenuItems && (
            <MenuList menuItems={menuItems} closeAfterClick={handleCloseAfterClick} />
          )}
          {endAdornment}
        </ContextMenuContext.Provider>
      )}
      placement={placement}
      trigger="click"
      visible={menuVisible}
      onVisibleChange={handleVisibleChange}
      overlayClassName={cls(styles.root, classes?.root, CONTEXT_MENU_ROOT_CLASS_NAME)}
      arrow={false}
      align={align ?? defaultAlign}
      getPopupContainer={(node) => {
        if (nestIn === 'parent') {
          return node
        }

        return document.body
      }}
    >
      {showChildren ? (
        children
      ) : (
        <div
          className={cls(styles.icon, classes?.icon, CONTEXT_MENU_ICON_CLASS_NAME)}
          onClick={stopPropagation}
        >
          {iconComponent}
        </div>
      )}
    </Popover>
  )
}
