import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { Field, Form, useFormState } from 'react-final-form'
import { Col, Row } from 'antd'
import { Trans, useTranslation } from 'react-i18next'

import { API } from 'services/api'
import { useSessionStorage } from 'App/hooks/useSessionStorage'
import { ELayoutNavKey } from 'enums'
import { useAdaptiveLayout } from 'App/hooks'
import {
  Alert,
  Button,
  Checkbox,
  FormSubmitButtonSkeleton,
  Help,
  TextField,
  TextFieldSkeleton
} from 'App/components/common'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'
import { validation } from 'utils/validation'
import { MAX_LENGTH_1000, MAX_LENGTH_50, SUPPORT_EMAIL, toastDefaultOptions } from 'globalConstants'
import { showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { toast } from 'App/components/ToastContainer'

import { EventProfileEditAvatar } from './EventProfileEditAvatar'
import styles from './EventProfileEdit.module.scss'
import { useEventProfileEditRequests } from './useEventProfileEditRequests'
import { EventProfileEditInterestsChipList } from './EventProfileEditInterestsChipList'

type TEventProfileEditProps = {
  eventId: string
}

export type TEventProfileEditValues = {
  firstName: string
  lastName: string
  openForNetworking: boolean

  about?: string
  company?: string
  position?: string
  interests?: Record<'id' | 'name', string>[]
  avatar?: File | string | null
}

type TSpyOnFormChangedProps = {
  prompt: JSX.Element
  onChange: (value: boolean) => void
}

const SpyOnFormChanged = ({ onChange, prompt }: TSpyOnFormChangedProps) => {
  const { pristine, modifiedSinceLastSubmit, submitSucceeded } = useFormState()

  useEffect(() => {
    onChange(submitSucceeded ? modifiedSinceLastSubmit : !pristine)
  }, [pristine, modifiedSinceLastSubmit, submitSucceeded, onChange])

  return prompt
}

export const EventProfileEdit = ({ eventId }: TEventProfileEditProps) => {
  const dispatch = useAppDispatch()

  const { url } = useRouteMatch()

  const { pathname } = useLocation()

  const { push } = useHistory()

  const { isDesktop, isMobile } = useAdaptiveLayout()

  const { prompt, setFormChanged } = useUnsavedDataPrompt()

  const { setSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.EVENTS })

  const { profileData, isLoading, isProcessing, submit } = useEventProfileEditRequests({ eventId })

  const { t } = useTranslation()

  const initialFormValues = useMemo<Partial<TEventProfileEditValues>>(
    () => ({
      firstName: profileData?.account.firstName,
      lastName: profileData?.account.lastName,
      openForNetworking: profileData?.openForNetworking,
      about: profileData?.about,
      company: profileData?.company,
      position: profileData?.position,
      avatar: profileData?.id ? API.EVENT_ATTENDEE_PROFILE_AVATAR(eventId, profileData.id) : '',
      interests:
        profileData?.interests
          ?.filter((interest) => interest.enabled)
          ?.map(({ id, name }) => ({ id, name })) ?? []
    }),
    [
      profileData?.account.firstName,
      profileData?.account.lastName,
      profileData?.openForNetworking,
      profileData?.about,
      profileData?.company,
      profileData?.position,
      profileData?.id,
      profileData?.interests,
      eventId
    ]
  )

  const handleGoToChannels = useCallback(() => {
    push(`/events/${eventId}`)
  }, [push, eventId])

  const onSubmit = useCallback(
    (values: TEventProfileEditValues) => {
      submit(values, () => {
        toast.success(t('common.toast.changesSaved'), toastDefaultOptions)
        setFormChanged(false)
      })
    },
    [setFormChanged, submit, t]
  )

  const handleOpenForNetworking = useCallback(
    (onConfirm) => {
      dispatch(
        showModalAction({
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalTitle: t('modal.openForNetworkingConfirm.title'),
          modalProps: {
            content: t('modal.openForNetworkingConfirm.content'),
            confirmLabel: t('modal.openForNetworkingConfirm.submitButton'),
            onConfirm
          }
        })
      )
    },
    [dispatch, t]
  )

  useEffect(() => {
    setSessionStorageData(url)
  }, [setSessionStorageData, pathname, url])

  return (
    <div className={styles.root}>
      {prompt}

      <Form<TEventProfileEditValues> onSubmit={onSubmit} initialValues={initialFormValues}>
        {({ handleSubmit, form }) => {
          const formState = form.getState()
          const disabled =
            isLoading ||
            formState.invalid ||
            (formState.submitSucceeded ? !formState.modifiedSinceLastSubmit : formState.pristine)

          return (
            <>
              <SpyOnFormChanged onChange={setFormChanged} prompt={prompt} />

              <div className={styles.wrapper}>
                {isDesktop && profileData?.account && (
                  <Col flex="none">
                    <EventProfileEditAvatar
                      initialValue={initialFormValues.avatar}
                      isLoading={isLoading}
                      profileData={profileData}
                    />
                  </Col>
                )}

                <div className={styles.content}>
                  <Alert className={styles.alert} variant="info">
                    {t('events.userProfile.alert')}
                  </Alert>

                  {!isDesktop && (
                    <EventProfileEditAvatar
                      initialValue={initialFormValues.avatar}
                      isLoading={isLoading}
                      profileData={profileData}
                      className={styles.avatar}
                    />
                  )}

                  <form onSubmit={handleSubmit}>
                    <Row gutter={[0, 24]}>
                      <Col xs={24}>
                        <Row gutter={[20, 20]}>
                          <Col xs={24} md={12}>
                            <TextFieldSkeleton loading={isLoading}>
                              <Field name="firstName" validate={validation.required()}>
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    invalid={meta.touched && meta.invalid}
                                    error={meta.error}
                                    disabled={true}
                                    topLabel={
                                      <Trans
                                        t={t}
                                        i18nKey="events.userProfile.fields.firstName"
                                        components={{
                                          help: (
                                            <Help
                                              content={t('userProfile.changeNameHint', {
                                                email: SUPPORT_EMAIL
                                              })}
                                            />
                                          )
                                        }}
                                      />
                                    }
                                  />
                                )}
                              </Field>
                            </TextFieldSkeleton>
                          </Col>
                          <Col xs={24} md={12}>
                            <TextFieldSkeleton loading={isLoading}>
                              <Field name="lastName" validate={validation.required()}>
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    invalid={meta.touched && meta.invalid}
                                    error={meta.error}
                                    disabled={true}
                                    topLabel={
                                      <Trans
                                        t={t}
                                        i18nKey="events.userProfile.fields.lastName"
                                        components={{
                                          help: (
                                            <Help
                                              content={t('userProfile.changeNameHint', {
                                                email: SUPPORT_EMAIL
                                              })}
                                            />
                                          )
                                        }}
                                      />
                                    }
                                  />
                                )}
                              </Field>
                            </TextFieldSkeleton>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24}>
                        <TextFieldSkeleton loading={isLoading}>
                          <Field name="openForNetworking" type="checkbox">
                            {({ input }) => (
                              <Checkbox
                                {...input}
                                label={t('events.userProfile.fields.openForNetworking')}
                                onChange={(event) => {
                                  input.checked
                                    ? handleOpenForNetworking(() => input.onChange(event))
                                    : input.onChange(event)
                                }}
                              />
                            )}
                          </Field>
                        </TextFieldSkeleton>
                      </Col>
                      <Col xs={24}>
                        <EventProfileEditInterestsChipList loading={isLoading} eventId={eventId} />
                      </Col>

                      <Col xs={24}>
                        <Row gutter={[20, 20]}>
                          <Col xs={24} md={12}>
                            <TextFieldSkeleton loading={isLoading}>
                              <Field
                                name="company"
                                validate={validation.composeValidators(
                                  validation.onlySpaces(),
                                  validation.maxLength(MAX_LENGTH_50)
                                )}
                              >
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    invalid={meta.touched && meta.invalid}
                                    error={meta.error}
                                    topLabel={t('events.userProfile.fields.company')}
                                    valueLengthMax={MAX_LENGTH_50}
                                  />
                                )}
                              </Field>
                            </TextFieldSkeleton>
                          </Col>
                          <Col xs={24} md={12}>
                            <TextFieldSkeleton loading={isLoading}>
                              <Field
                                name="position"
                                validate={validation.composeValidators(
                                  validation.onlySpaces(),
                                  validation.maxLength(MAX_LENGTH_50)
                                )}
                              >
                                {({ input, meta }) => (
                                  <TextField
                                    {...input}
                                    invalid={meta.touched && meta.invalid}
                                    error={meta.error}
                                    topLabel={t('events.userProfile.fields.position')}
                                    valueLengthMax={MAX_LENGTH_50}
                                  />
                                )}
                              </Field>
                            </TextFieldSkeleton>
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={24}>
                        <TextFieldSkeleton loading={isLoading} variant="textArea">
                          <Field
                            name="about"
                            validate={validation.composeValidators(
                              validation.onlySpaces(),
                              validation.maxLength(MAX_LENGTH_1000)
                            )}
                          >
                            {({ input, meta }) => (
                              <TextField
                                {...input}
                                invalid={meta.touched && meta.invalid}
                                error={meta.error}
                                multiline={true}
                                rows={4}
                                rowsMax={4}
                                topLabel={t('events.userProfile.fields.about')}
                                valueLengthMax={MAX_LENGTH_1000}
                              />
                            )}
                          </Field>
                        </TextFieldSkeleton>
                      </Col>
                    </Row>

                    <div className={styles.submitButton}>
                      <FormSubmitButtonSkeleton loading={isLoading}>
                        <Button type="submit" loading={isProcessing} disabled={disabled}>
                          {t('events.userProfile.submitButton')}
                        </Button>
                      </FormSubmitButtonSkeleton>

                      {isMobile && (
                        <Button variant="underlined" onClick={handleGoToChannels}>
                          {t('events.detailsForm.goToEventButton')}
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </>
          )
        }}
      </Form>
    </div>
  )
}
