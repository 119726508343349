import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  UnityHubHeaderLayout,
  TUnityHubHeaderLayoutAction,
  TUnityHubHeaderLayoutProps
} from 'App/components/UnityHub'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import {
  DELETE_EVENT,
  isAdminAccountSelector,
  LOST_ACCESS_TO_EVENT,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { TMenuItemProps } from 'App/components/common/MenuList/MenuItem'
import { deleteEvent, leaveEvent } from 'api/events'
import { skipWSAction } from 'services/skipWSActions'

import { useToggle } from '../../../hooks'
import { EventInvitePopover } from '../../../containers/InvitePopover'

type TEventHeaderProps = Pick<TUnityHubHeaderLayoutProps, 'name' | 'loading' | 'id'>

export const EventHeader = ({ name, loading, id }: TEventHeaderProps) => {
  const dispatch = useAppDispatch()

  const { push } = useHistory()

  const { t } = useTranslation()

  const { value, toggle, setValue } = useToggle()

  const isAdminAccount = useAppSelector(isAdminAccountSelector)

  const { mutateAsync: removeEventHandler } = useMutation({
    mutationKey: [DELETE_EVENT, id],
    mutationFn: () => {
      skipWSAction({ type: LOST_ACCESS_TO_EVENT, payload: { id } })

      return deleteEvent(id)
    }
  })

  const { mutateAsync: leaveEventHandler } = useMutation({
    mutationKey: ['leave-event', id],
    mutationFn: () => {
      skipWSAction({ type: LOST_ACCESS_TO_EVENT, payload: { id } })

      return leaveEvent(id)
    }
  })

  const actions = useMemo<TUnityHubHeaderLayoutAction[]>(
    () => [
      {
        key: 'edit',
        text: t('events.actions.edit'),
        icon: <PencilOutlineIcon />,
        hidden: !isAdminAccount,
        onClick: () => push(`/events/${id}/edit/basic`)
      },
      {
        key: 'invite',
        text: (
          <>
            {t('events.actions.inviteAttendees')}
            <EventInvitePopover visible={value} id={id} name={name} onVisibleChange={setValue} />
          </>
        ),
        hidden: !isAdminAccount,
        onClick: toggle,
        icon: <AccountMultiplePlusIcon />
      }
    ],
    [t, isAdminAccount, value, id, setValue, toggle, name, push]
  )

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <TrashIcon />,
        content: t('events.kebabMenu.delete'),
        hidden: !isAdminAccount,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteEventConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                content: t('modal.deleteEventConfirm.content'),
                confirmLabel: t('modal.deleteEventConfirm.submitButton'),
                onConfirm: removeEventHandler
              }
            })
          )
      },
      {
        icon: <LogoutIcon />,
        content: t('events.kebabMenu.leave'),
        hidden: isAdminAccount,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.leaveEventConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                content: t('modal.leaveEventConfirm.content'),
                confirmLabel: t('modal.leaveEventConfirm.submitButton'),
                onConfirm: leaveEventHandler
              }
            })
          )
      }
    ],
    [dispatch, isAdminAccount, leaveEventHandler, removeEventHandler, t]
  )

  return (
    <UnityHubHeaderLayout
      type="event"
      id={id}
      name={name}
      loading={loading}
      actions={actions}
      menuItems={menuItems}
      showKebab
    />
  )
}
