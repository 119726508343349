import cls from 'classnames'

import { MAX_MESSAGE_REACTION_ACCOUNTS_SHOW } from 'globalConstants'

import {
  ChatMessageReactionCountAvatars,
  TChatMessageReactionCountAvatarsProps
} from './ChatMessageReactionCountAvatars'
import styles from './ChatMessageReactionCount.module.scss'

export type TChatMessageReactionCountProps = TChatMessageReactionCountAvatarsProps & {
  accountsLength: number

  isOutgoing?: boolean
  isMessageOutgoing?: boolean
}

export const ChatMessageReactionCount = ({
  isOutgoing,
  isMessageOutgoing,
  accountsLength,
  messageId,
  reaction
}: TChatMessageReactionCountProps) => {
  const shouldShowCount = accountsLength > MAX_MESSAGE_REACTION_ACCOUNTS_SHOW
  return (
    <div
      className={cls(styles.root, {
        [styles.outgoing]: isOutgoing,
        [styles.messageOutgoing]: isMessageOutgoing,
        [styles.count]: shouldShowCount
      })}
    >
      {shouldShowCount ? (
        accountsLength
      ) : (
        <ChatMessageReactionCountAvatars messageId={messageId} reaction={reaction} />
      )}
    </div>
  )
}
