import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components/common'
import { EModalComponents } from 'App/containers/ModalRoot'
import { useCloseModal } from 'App/hooks/useCloseModal'
import {
  DELETE_MESSAGE_PERMANENTLY,
  TDeleteMessagePermanentlyRequestPayload,
  createProcessingBySelector,
  deleteMessagePermanentlyRequest,
  useAppDispatch,
  useAppSelector
} from 'store'

type TDeleteChatMessageConfirmProps = Pick<
  TDeleteMessagePermanentlyRequestPayload,
  'caseId' | 'chatId' | 'messageId'
>

export const DeleteChatMessageConfirm = ({
  chatId,
  messageId,
  caseId
}: TDeleteChatMessageConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processingId = `${chatId}-${messageId}`

  const loading = useAppSelector(
    createProcessingBySelector([DELETE_MESSAGE_PERMANENTLY])(processingId)
  )

  const handleCloseModal = useCloseModal([EModalComponents.DELETE_CHAT_MESSAGE_CONFIRM])

  const handleClick = () => {
    dispatch(
      deleteMessagePermanentlyRequest({
        chatId,
        messageId,
        caseId,
        processingId,
        onSuccess: handleCloseModal
      })
    )
  }

  return (
    <ConfirmationModal
      content={t('modal.deleteMessageConfirm.content')}
      controls={
        <Button loading={loading} disabled={loading} onClick={handleClick}>
          {t('modal.deleteMessageConfirm.submitButton')}
        </Button>
      }
    />
  )
}
