import {
  AUDIO_WHITELIST,
  VIDEO_WHITELIST,
  MODELS_WHITELIST,
  DOCUMENTS_WHITELIST,
  ARCHIVE_TAR_WHITELIST,
  SPREAD_WHITELIST,
  ARCHIVE_WHITELIST,
  PRESENTATION_WHITELIST,
  IMAGE_WHITELIST,
  DOT_REGEXP
} from 'globalConstants'
import { TIds } from 'store'
import { TFileSharedTableProps, TFileTableProps } from 'App/components'
import { TDataSource } from 'App/containers'

import { ReactComponent as VideoFileIcon } from '../assets/icons/VideoFile.svg'
import { ReactComponent as ModelFileIcon } from '../assets/icons/3dModelFile.svg'
import { ReactComponent as DocumentFileIcon } from '../assets/icons/DocumentFile.svg'
import { ReactComponent as AudioFileIcon } from '../assets/icons/AudioFile.svg'
import { ReactComponent as PresentationFileIcon } from '../assets/icons/PresentationFile.svg'
import { ReactComponent as SpreadSheetFileIcon } from '../assets/icons/SpreadSheetFile.svg'
import { ReactComponent as ArchiveFileIcon } from '../assets/icons/ArchiveFile.svg'
import { ReactComponent as UnknownFileIcon } from '../assets/icons/UnknownFile.svg'
import { ReactComponent as ImageFileIcon } from '../assets/icons/ImageFile.svg'

export const getExtensionWithoutDot = (extension?: string) =>
  extension?.replace(DOT_REGEXP, '').toLowerCase() ?? ''

export const getFileIcon = (value: string) => {
  const extension = getExtensionWithoutDot(value)

  if (AUDIO_WHITELIST.includes(extension)) {
    return AudioFileIcon
  }
  if (VIDEO_WHITELIST.includes(extension)) {
    return VideoFileIcon
  }
  if (PRESENTATION_WHITELIST.includes(extension)) {
    return PresentationFileIcon
  }
  if (MODELS_WHITELIST.includes(extension)) {
    return ModelFileIcon
  }
  if (ARCHIVE_WHITELIST.includes(extension) || ARCHIVE_TAR_WHITELIST.includes(extension)) {
    return ArchiveFileIcon
  }
  if (DOCUMENTS_WHITELIST.includes(extension)) {
    return DocumentFileIcon
  }
  if (SPREAD_WHITELIST.includes(extension)) {
    return SpreadSheetFileIcon
  }
  if (IMAGE_WHITELIST.includes(extension)) {
    return ImageFileIcon
  }

  return UnknownFileIcon
}

export const getSelectedFiles = <T extends TFileSharedTableProps | TFileTableProps | TDataSource>(
  selectedIds: TIds,
  dataSource: T[]
) => selectedIds.map((id) => dataSource.find(({ fileId }) => fileId === id)!).filter(Boolean)

export const downloadFile = ({
  el,
  type = 'image/png',
  name
}: {
  el: HTMLElement
  type: string
  name: string
}) => {
  const string = new XMLSerializer().serializeToString(el)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const img = new Image()

  img.onload = () => {
    if (ctx) {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)

      const e = canvas.toDataURL(type)
      const link = document.createElement('a')

      link.download = name
      link.href = `${e}`
      link.click()
    }
  }
  img.src = `data:image/svg+xml;base64,${btoa(string)}`
}
