import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents, ShowModalContainer } from 'App/containers'

import { Wrapper } from '../Wrapper'

export const PaymentFailedDialog: FC = () => {
  const { t } = useTranslation()

  return (
    <ShowModalContainer
      modalTitle={t('modal.oneTimeTopUp.title')}
      modalType={EModalComponents.TOP_UP_ONE_TIME}
    >
      {({ showModalHandler }) => (
        <Wrapper
          icon="fail"
          title={t('modal.paymentFailed.title')}
          description={t('modal.paymentFailed.content')}
          submitLabel={t('modal.paymentFailed.submitButton')}
          onClick={showModalHandler}
        />
      )}
    </ShowModalContainer>
  )
}
