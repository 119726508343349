import { FileHistorySourcesEnum } from '@medentee/enums'

import { TCallbackAfterUploadFile, TIds } from 'store'
import { CaseCloudUploadedByMeFilesItemContainer } from 'App/containers'
import { TCaseCloudFilesItemBaseProps } from 'App/components'

import { CaseCloudFilesListWrapper } from '../CaseCloudFilesListWrapper'

export type TCaseCloudUploadedByMeFilesListProps = Pick<
  TCaseCloudFilesItemBaseProps,
  'caseStatus' | 'caseView' | 'getKebabMenu' | 'onSelect' | 'filesView' | 'selectMode'
> & {
  uploadedByMeIds: TIds
  loading: boolean
  onAfterUploadFile: TCallbackAfterUploadFile
  caseId: string

  selectedIds?: TIds
}

export const CaseCloudUploadedByMeFilesList = ({
  uploadedByMeIds,
  caseStatus,
  loading,
  caseId,
  caseView,
  selectedIds,
  filesView,
  selectMode,
  getKebabMenu,
  onSelect
}: TCaseCloudUploadedByMeFilesListProps) => (
  <CaseCloudFilesListWrapper filesView={filesView} loading={loading} ids={uploadedByMeIds}>
    {(id) => (
      <CaseCloudUploadedByMeFilesItemContainer
        key={id}
        id={id}
        caseStatus={caseStatus}
        source={FileHistorySourcesEnum.CASE_CLOUD}
        originalEntityId={caseId}
        caseView={caseView}
        selected={selectedIds?.includes(id)}
        selectedIdsLength={selectedIds?.length}
        filesView={filesView}
        selectMode={selectMode}
        getKebabMenu={getKebabMenu}
        onSelect={onSelect}
      />
    )}
  </CaseCloudFilesListWrapper>
)
