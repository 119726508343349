import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAdaptiveLayout } from 'App/hooks'
import { ChatRoomCase } from 'App/components'
import { ChatRoomsContainer, TCaseOwnerChatContainerProps } from 'App/containers'
import { ReactComponent as DoubleChevronLeftIcon } from 'assets/icons/DoubleChevronLeft.svg'

import styles from './CaseOwnerChat.module.scss'

export type TCaseOwnerChatProps = TCaseOwnerChatContainerProps & {
  caseView?: string
  caseId?: string
}

export const CaseOwnerChat = ({
  lengthChatRooms,
  selectedChat,
  activeChatId,
  caseView,
  caseId,
  selectChat,
  resetSelectedChat
}: TCaseOwnerChatProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { pathname, search } = useLocation()

  const { t } = useTranslation()

  useEffect(
    () => () => {
      resetSelectedChat()
    },
    [search, resetSelectedChat]
  )

  useEffect(() => {
    if (activeChatId && !selectedChat && lengthChatRooms) {
      selectChat({
        nextChatId: activeChatId
      })
    }
  }, [activeChatId, selectedChat, lengthChatRooms, selectChat])

  return (
    <div className={styles.root}>
      {!isMobile && (
        <div className={styles.listContainer}>
          <Link to={pathname} className={styles.backButton}>
            <DoubleChevronLeftIcon className={styles.backButtonIcon} />
            <span>{t('cases.details.chatSection.backButton')}</span>
          </Link>
          <ChatRoomsContainer caseId={caseId} />
        </div>
      )}
      <div className={styles.roomContainer}>
        <ChatRoomCase owner={true} caseView={caseView} activeChatId={activeChatId} />
      </div>
    </div>
  )
}
