import { useEffect } from 'react'
import { BusinessAccountStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createProcessingSelector,
  getBusinessUsersRequest,
  removeAdminRequest,
  removeErrorNotification,
  REMOVE_ADMIN,
  useAppDispatch,
  useAppSelector
} from 'store'
import { useDialog } from 'App/hooks'
import { Button, Checkbox, ConfirmationModal, ErrorModal } from 'App/components'

import styles from './UserProfileAdministrationRemoveAdminConfirmContainer.module.scss'

type TUserProfileAdministrationRemoveAdminConfirmContainerProps = {
  businessUserId: string
}

const processingSelector = createProcessingSelector([REMOVE_ADMIN])

export const UserProfileAdministrationRemoveAdminConfirmContainer = ({
  businessUserId
}: TUserProfileAdministrationRemoveAdminConfirmContainerProps) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector)
  const error = useAppSelector((state) => state.errors[REMOVE_ADMIN])

  const { open: checkboxState, toggle } = useDialog()

  const { t } = useTranslation()

  useEffect(
    () => () => {
      dispatch(removeErrorNotification(REMOVE_ADMIN))
    },
    [dispatch]
  )

  const handleRemoveSuccess = () => {
    dispatch(
      getBusinessUsersRequest({
        statuses: [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
      })
    )
  }

  const handleRemoveAdmin = () => {
    dispatch(
      removeAdminRequest({
        onlyHidden: false,
        onlyDowngrade: !checkboxState,
        businessUserId,
        onSuccess: handleRemoveSuccess
      })
    )
  }

  return (
    <ConfirmationModal
      content={t('modal.removeAdminConfirm.content')}
      endAdornment={
        <Checkbox
          classes={{ root: styles.checkbox }}
          checked={checkboxState}
          label={t('modal.removeAdminConfirm.removeFromStaffCheckbox')}
          onChange={toggle}
        />
      }
      controls={
        <>
          <Button loading={processing} onClick={handleRemoveAdmin}>
            {t('modal.removeAdminConfirm.submitButton')}
          </Button>
          <ErrorModal error={error} />
        </>
      }
    />
  )
}
