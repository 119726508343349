import { useEffect, useState } from 'react'

import config from 'config'
import { jsonParser } from 'utils'

export enum EJitsiPostMessageTypes {
  JITSI_CONNECTION_STATUS = 'JITSI_CONNECTION_STATUS'
}

export type TJitsiPostMessageData<E> = E & {
  type: string
}

export const useJitsiPostMessage = <E extends Record<string, unknown>>(
  type: EJitsiPostMessageTypes
): TJitsiPostMessageData<E> | null => {
  const [data, setData] = useState<TJitsiPostMessageData<E> | null>(null)

  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (event.origin !== config.jitsiMeetUrl) {
        return
      }

      const serializedData: TJitsiPostMessageData<E> = jsonParser(event.data)

      if (serializedData?.type === type) {
        setData(serializedData)
      }
    }

    window.addEventListener('message', receiveMessage)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [type])

  return data
}
