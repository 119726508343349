import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TTwoFactorCheckerProps, TwoFactorCountdown } from 'App/components'

import styles from './TwoFactorTimer.module.scss'

export type TTwoFactorTimerProps = Pick<
  TTwoFactorCheckerProps,
  'codeExhausted' | 'expiredTokenDate' | 'processing'
> & {
  timeOver: boolean
  setTimeOver: (value: boolean) => void
}

const TwoFactorTimerView = ({
  codeExhausted,
  expiredTokenDate,
  processing,
  timeOver,
  setTimeOver
}: TTwoFactorTimerProps) => {
  const { t } = useTranslation()

  if (processing || !expiredTokenDate) {
    return null
  }

  if (codeExhausted) {
    return <p className={styles.error}>{t('common.2FA.codeLabel.exceededAttemptsCode')}</p>
  }

  if (timeOver) {
    return <p className={styles.error}>{t('common.2FA.codeLabel.expiredCode')}</p>
  }

  return (
    <p className={styles.time}>
      <Trans
        t={t}
        i18nKey="common.2FA.codeLabel.codeValidFor"
        components={{
          time: <TwoFactorCountdown end={new Date(expiredTokenDate)} onTimerStop={setTimeOver} />
        }}
      />
    </p>
  )
}

export const TwoFactorTimer = memo(TwoFactorTimerView)
