import { useEffect, useState, useCallback, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import cls from 'classnames'
import {
  CaseClassificationIdEnum,
  CaseStatusesEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation } from 'react-i18next'

import { toast, ToastId } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import {
  Card,
  CaseDetailsAlert,
  CaseDetailsMenu,
  CaseHeader,
  CaseItemOwner,
  IconButton,
  Spinner
} from 'App/components'
import { useAdaptiveLayout, useNotificationTracker, usePrevious, useRefValue } from 'App/hooks'
import {
  CaseCloudContainer,
  CaseConvertContainer,
  CaseMembersContainer,
  EModalComponents,
  CardContainer
} from 'App/containers'
import { State } from 'redux/rootReducer'
import { ECaseAccountType, EIconSize } from 'enums'
import {
  CASE_VIEW,
  caseDetailsReset,
  caseViewRequest,
  clearNotificationAction,
  createLoadingSelector,
  createProcessingSelector,
  setCaseCloudActiveFileId,
  showModalAction,
  TCaseParams,
  readNotificationFromIdRequest,
  resetCaseListAction,
  useAppSelector,
  accountIdSelector
} from 'store'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'

import { TCaseViewSelectValue } from '../../components/Case/CaseViewSelect'

import styles from './CaseDetails.module.scss'

const TRACKED_NOTIFICATIONS = new Set([ProducedNotificationsEnum.CASE_DELETED])

type TCaseDetailsProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

const CaseDetailsComponent = ({
  loading,
  processing,
  userId,
  data,
  getCase,
  showModal,
  resetCase,
  closeFileDetails,
  clearNotification,
  readNotificationFromId,
  resetCaseList
}: TCaseDetailsProps) => {
  const { isTablet, isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const location = useLocation()
  const { who, id } = useParams<TCaseParams>()
  const { replace, push } = useHistory()

  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null)
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [colHeight, setColHeight] = useState(0)
  const [caseHistoryToastId, setCaseHistoryToastId] = useState<ToastId | undefined>()

  const accountId = useAppSelector(accountIdSelector)

  const [view, setView] = useLocalStorageState<TCaseViewSelectValue>(`${id}-${accountId}`, {
    defaultValue: 'standard'
  })

  const prevStatus = usePrevious(data?.status)

  const { getValue: getLocation } = useRefValue(location)

  const isOwner = who === ECaseAccountType.OWNER
  const isMember = who === ECaseAccountType.MEMBER

  const isCaseArchived = data?.status === CaseStatusesEnum.ARCHIVED
  const isExpandedView = view === 'expanded'
  const expandedColStyle = isExpandedView && isDesktop ? { height: colHeight } : undefined

  const changeView = useCallback(
    (newView: TCaseViewSelectValue) => {
      const currentLocation = getLocation()
      setView(newView)
      // Allows to re-select a chat after changing the view
      replace({ ...currentLocation, search: currentLocation.search.replace(/(\?)?chatId=\d+/, '') })
    },
    [replace, setView, getLocation]
  )

  useEffect(() => {
    if (!isDesktop) {
      changeView('standard')
    }
  }, [isDesktop, changeView])

  const handleResize = useCallback(() => {
    if (contentEl) {
      setColHeight(contentEl.clientHeight - 1)
    }
  }, [setColHeight, contentEl])

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize)

    handleResize()
    contentEl && resizeObserver.observe(contentEl)

    return () => {
      contentEl && resizeObserver.unobserve(contentEl)
    }
  }, [handleResize])

  useNotificationTracker({
    channel: 'caseDetails',
    onOccurrence: (payload) => {
      if (payload?.caseId === data?.id) {
        toast.warn(t('common.toast.unavailableCase'), toastDefaultOptions)
        replace('/cases')
      }
    },
    notifications: TRACKED_NOTIFICATIONS
  })

  useEffect(
    () => () => {
      resetCaseList()
    },
    [resetCaseList]
  )

  useEffect(() => {
    if (isMember || isOwner) {
      getCase({
        id,
        type: who as ECaseAccountType
      })
    }

    return () => {
      resetCase()
      closeFileDetails()
    }
  }, [getCase, resetCase, id, isMember, isOwner, who, closeFileDetails])

  useEffect(() => {
    if (prevStatus && prevStatus !== data?.status) {
      isCaseArchived && isMember
        ? push('/cases')
        : getCase({
            id,
            type: who as ECaseAccountType
          })
    }
  }, [
    data?.status,
    data?.classification,
    prevStatus,
    id,
    who,
    isCaseArchived,
    isMember,
    push,
    getCase
  ])

  useEffect(() => {
    if (!isMember && !isOwner) {
      replace('/cases')
    }
  }, [isOwner, isMember, replace])

  useEffect(() => {
    if (data?.notifications.length) {
      const notificationIds = data.notifications.map((item) => item.id)
      const toastId = toast.info(
        <CaseDetailsAlert id={id} caseName={data.title} isOwner={isOwner} />,
        { ...toastDefaultOptions, autoClose: false }
      )

      setCaseHistoryToastId(toastId)
      readNotificationFromId({ notificationIds })
    }
  }, [readNotificationFromId, data?.notifications, data?.title, id, isOwner])

  useEffect(
    () => () => {
      if (caseHistoryToastId) {
        toast.dismiss(caseHistoryToastId)
      }
    },
    [caseHistoryToastId]
  )

  useLayoutEffect(() => {
    // ADDED FOR SPECIAL TASK MED-6480

    if (data?.status === CaseStatusesEnum.ARCHIVED) {
      toast.warn(t('common.toast.unavailableCase'), toastDefaultOptions)
    }
  }, [data?.status, t])

  if (data?.status === CaseStatusesEnum.ARCHIVED) {
    // ADDED FOR SPECIAL TASK MED-6480

    return <Redirect to="/cases" />
  }

  if (!data) {
    return null
  }

  const documentInfo = (
    <div className={styles.info}>
      <CaseItemOwner owner={data.owner} />

      <div className={styles.infoItem}>
        <span className={styles.infoId}>
          {t('cases.caseIdLabel', { caseId: data.humanReadableId })}
        </span>
      </div>
    </div>
  )

  const editIcon = userId === data.owner.id && !isCaseArchived && (
    <IconButton
      toolTip={t('cases.modal.renameCase.title')}
      iconComponent={<PencilIcon />}
      iconSize={EIconSize.MD}
      onClick={() =>
        showModal({
          modalType: EModalComponents.CASE_EDIT_TITLE_DIALOG,
          modalTitle: t('cases.modal.renameCase.title')
        })
      }
    />
  )

  if (loading) {
    return <Spinner contentCenter={true} />
  }

  const isPrivateCase = data.classification.id !== CaseClassificationIdEnum.PRIVATE

  const members = isPrivateCase && <CaseMembersContainer caseView={view} />
  const attachments = (
    <CaseCloudContainer
      caseView={view}
      caseId={id}
      who={who as ECaseAccountType}
      caseStatus={data.status}
    />
  )

  return (
    <div className={styles.root} style={{ paddingTop: `${headerHeight}px` }}>
      <CaseHeader
        chatId={data?.chat?.id}
        view={view}
        onChangeView={changeView}
        processing={processing}
        caseName={data.title}
        classificationName={data.classification.name}
        caseType={data.type}
        caseStatus={data.status}
        showModal={showModal}
        setHeaderHeight={setHeaderHeight}
      />

      {data.classification.id === CaseClassificationIdEnum.PRIVATE && !isCaseArchived && (
        <CaseConvertContainer />
      )}

      <div
        ref={setContentEl}
        className={cls(styles.content, {
          [styles.expanded]: isExpandedView,
          [styles.rowSkeleton]: processing
        })}
      >
        {(isCaseArchived || !isExpandedView) && <CardContainer />}

        <div className={styles.col} style={expandedColStyle}>
          <Card
            classes={isTablet ? { root: styles.colCard } : undefined}
            title={data.title}
            actions={editIcon}
            subtitleChildren={documentInfo}
            loading={processing}
          >
            <CaseDetailsMenu
              caseType={data.type}
              caseStatus={data.status}
              classification={data.classification.name}
              id={id}
              isOwner={isOwner}
              who={who as ECaseAccountType}
              clearNotification={clearNotification}
            />
          </Card>
        </div>
        {isExpandedView ? (
          <>
            {!isCaseArchived && (
              <div className={styles.col} style={expandedColStyle}>
                {members}
              </div>
            )}
            <div className={styles.col} style={expandedColStyle}>
              {attachments}
            </div>
            {!isCaseArchived && (
              <div className={styles.col} style={expandedColStyle}>
                <CardContainer allowPosition={false} allowSize={true} />
              </div>
            )}
          </>
        ) : (
          <div className={cls(styles.col, styles.colRight)}>
            {!isCaseArchived && isPrivateCase && <div className={styles.colItem}>{members}</div>}
            <div className={cls(styles.colItem, styles.colItemCloud)}>{attachments}</div>
          </div>
        )}
      </div>
    </div>
  )
}

const loadingSelector = createLoadingSelector([CASE_VIEW])
const processingSelector = createProcessingSelector([CASE_VIEW])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  processing: processingSelector(state),
  data: state.caseView.data,
  userId: state.global.accountData?.id
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCase: caseViewRequest,
      showModal: showModalAction,
      resetCase: caseDetailsReset,
      closeFileDetails: setCaseCloudActiveFileId,
      clearNotification: clearNotificationAction,
      readNotificationFromId: readNotificationFromIdRequest,
      resetCaseList: resetCaseListAction
    },
    dispatch
  )

export const CaseDetails = connect(mapStateToProps, mapDispatchToProps)(CaseDetailsComponent)
