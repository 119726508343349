import { ComponentType, ReactNode, SVGProps } from 'react'
import cls from 'classnames'
import { MessageTypeEnum } from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { TChatBaseMessage } from 'store'
import { getMapComponent } from 'utils'
import { IconButton, FormattedText, TruncatedText } from 'App/components'
import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'

import styles from './ChatMessageReference.module.scss'

export type TChatMessageReferenceProps = {
  message: TChatBaseMessage
  title: ReactNode
  icon?: ComponentType<SVGProps<SVGSVGElement>> | null
  classes?: Record<TChatMessageReferenceClasses, string>
  outgoing?: boolean
  onCancel?: () => void
  onClick?: () => void
}

type TChatMessageReferenceClasses = 'root'

type TMessageMapProps = {
  message: TChatBaseMessage
  t: TFunction
}

const MESSAGE_MAP = new Map<MessageTypeEnum, (props: TMessageMapProps) => JSX.Element>()
  .set(
    MessageTypeEnum.TEXT,
    ({ message: { message, mentionedAccounts, mentionedDepartments } }) => (
      <p className={styles.message}>
        <FormattedText
          text={message as string}
          mentions={{
            accounts: mentionedAccounts,
            departments: mentionedDepartments,
            clickable: true
          }}
          formatters={['emoji', 'mention']}
          classes={{ mention: styles.mention }}
        />
      </p>
    )
  )
  .set(MessageTypeEnum.AUDIO, ({ t }) => (
    <div className={cls(styles.message, styles.messagePlaceholder)}>
      {t('chat.list.lastMessage.placeholder_audio')}
    </div>
  ))
  .set(MessageTypeEnum.DELETED, ({ t }) => (
    <div className={cls(styles.message, styles.messagePlaceholder)}>
      {t('chat.list.lastMessage.placeholder_deleted')}
    </div>
  ))
  .set(MessageTypeEnum.DISCARDED_FILE_PERMISSION, ({ t }) => (
    <div className={cls(styles.message, styles.messagePlaceholder)}>
      {t('chat.list.lastMessage.placeholder_discardedPermission')}
    </div>
  ))
  .set(MessageTypeEnum.FILE, ({ message }) => (
    <TruncatedText
      text={`${message.file?.fileName ?? ''}${message.file?.extension ?? ''}`}
      classes={{ text: cls(styles.message, styles.messagePlaceholder) }}
    />
  ))

export const ChatMessageReference = ({
  message,
  title,
  classes,
  outgoing,
  icon: Icon,
  onCancel,
  onClick
}: TChatMessageReferenceProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, outgoing && styles.rootOutgoing, classes?.root)}>
      {onCancel && (
        <div className={styles.cancelAction}>
          <IconButton
            iconComponent={<CloseCircleOutlinedIcon />}
            iconSize={EIconSize.MD}
            onClick={onCancel}
          />
        </div>
      )}
      <div className={styles.wrapper} onClick={onClick}>
        <div className={styles.content}>
          <div className={styles.title}>
            {Icon && <Icon className={styles.titleIcon} />}
            <span>{title}</span>
          </div>
          {getMapComponent(MESSAGE_MAP, message.type, { message, t })}
        </div>
      </div>
    </div>
  )
}
