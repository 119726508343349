import { useCallback } from 'react'
import { AccountTypeNames, PlatformInvitationType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'

import { ShowModalContainer } from '../ShowModalContainer'
import { EModalComponents } from '../ModalRoot'
import { EmptyListActionLink } from '../../components'
import { InvitePopover } from '../InvitePopover'
import { sendInviteUsersByEmail } from '../../../api/invitations'
import { useInvitationDialogDescription } from '../Invitations'

export type TEmptyListInviteButtonProps = {
  accountType: AccountTypeNames | undefined
  contactType: AccountTypeNames | undefined
  emptyListLinkText?: string
}

export const EmptyListInviteButton = ({
  accountType,
  contactType,
  emptyListLinkText
}: TEmptyListInviteButtonProps) => {
  const { t } = useTranslation()

  const { mutateAsync } = useMutation({
    mutationKey: ['invite-users'],
    mutationFn: (data: { emails: string[]; type: PlatformInvitationType }) =>
      sendInviteUsersByEmail(data)
  })

  const handleConfirm = useCallback(
    (type: PlatformInvitationType) => (emails: string[]) =>
      mutateAsync(
        { emails, type },
        {
          onSuccess: () => {
            toast.success(t('modal.inviteByEmail.invitationSentSuccessToast'), toastDefaultOptions)
          }
        }
      ),
    [mutateAsync, t]
  )

  const contactsDescription = useInvitationDialogDescription(
    accountType,
    PlatformInvitationType.CONTACT
  )

  if (accountType === AccountTypeNames.BUSINESS && contactType === AccountTypeNames.PROFESSIONAL) {
    return (
      <ShowModalContainer
        modalTitle={t('modal.inviteByEmail.title')}
        modalType={EModalComponents.INVITE_BY_EMAIL}
        modalProps={{
          description: contactsDescription,
          onConfirm: handleConfirm(PlatformInvitationType.CONTACT)
        }}
      >
        {({ showModalHandler }) => (
          <EmptyListActionLink
            title={emptyListLinkText || t('invitations.inviteNewContactButton')}
            onClick={showModalHandler}
          />
        )}
      </ShowModalContainer>
    )
  }

  return (
    <InvitePopover
      invitationType={
        contactType === AccountTypeNames.PROFESSIONAL
          ? PlatformInvitationType.CONTACT
          : PlatformInvitationType.STAFF
      }
    >
      <EmptyListActionLink title={emptyListLinkText || t('invitations.inviteNewContactButton')} />
    </InvitePopover>
  )
}
