import { NotificationOriginSourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { IconLabel } from 'App/components'
import { ReactComponent as AccountSupervisorIcon } from 'assets/icons/AccountSupervisor.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { ReactComponent as TextBoxIcon } from 'assets/icons/TextBox.svg'
import { ReactComponent as ContactsIcon } from 'assets/icons/Contacts.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/GearSettingFill.svg'
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'
import { ReactComponent as StarIcon } from 'assets/icons/StarFill.svg'
import { ReactComponent as CommunityIcon } from 'assets/icons/PeopleNearby.svg'
import { ReactComponent as BusinessAccountIcon } from 'assets/icons/BusinessAccount.svg'
import { ReactComponent as TopUpIcon } from 'assets/icons/Coins.svg'
import { ReactComponent as EventsIcon } from 'assets/icons/Events.svg'

import styles from './NotificationsP2PDetailsType.module.scss'

export type TNotificationsP2PDetailsTypeClasses = 'root'

export type TNotificationsP2PDetailsTypeProps = {
  type: NotificationOriginSourcesEnum

  classes?: Partial<Record<TNotificationsP2PDetailsTypeClasses, string>>
}

const NOTIFICATION_TYPE_MAP = new Map<NotificationOriginSourcesEnum, () => JSX.Element>()
  .set(NotificationOriginSourcesEnum.CASES, () => <AccountGroupIcon />)
  .set(NotificationOriginSourcesEnum.FILES, () => <TextBoxIcon />)
  .set(NotificationOriginSourcesEnum.CASES, () => <AccountSupervisorIcon />)
  .set(NotificationOriginSourcesEnum.INVITATIONS, () => <AccountPlusIcon />)
  .set(NotificationOriginSourcesEnum.CONTACTS, () => <ContactsIcon />)
  .set(NotificationOriginSourcesEnum.SETTINGS, () => <SettingsIcon />)
  .set(NotificationOriginSourcesEnum.ACCOUNT, () => <AccountIcon />)
  .set(NotificationOriginSourcesEnum.ACCOUNT_ACCESS, () => <BusinessAccountIcon />)
  .set(NotificationOriginSourcesEnum.SUBSCRIPTION, () => <StarIcon />)
  .set(NotificationOriginSourcesEnum.ORGANIZATION, () => <BusinessAccountIcon />)
  .set(NotificationOriginSourcesEnum.TOP_UP, () => <TopUpIcon />)
  .set(NotificationOriginSourcesEnum.COMMUNITIES, () => <CommunityIcon />)
  .set(NotificationOriginSourcesEnum.EVENTS, () => <EventsIcon />)

export const NotificationsP2PDetailsType = ({
  type,
  classes
}: TNotificationsP2PDetailsTypeProps) => {
  const { t } = useTranslation()

  return (
    <IconLabel
      icon={getMapComponent(NOTIFICATION_TYPE_MAP, type)}
      label={t(`notificationDetailsType.${type}`, { ns: 'notifications' })}
      classes={{ root: classes?.root, icon: styles.icon }}
    />
  )
}
