import { Skeleton, SkeletonProps } from 'antd'
import cls from 'classnames'

import styles from './UnityHubNetworkingItemSkeleton.module.scss'

export type TUnityHubNetworkingItemSkeletonProps = PropsWithClassName<
  Pick<SkeletonProps, 'loading'>
>

export const UnityHubNetworkingItemSkeleton = ({
  loading,
  className
}: TUnityHubNetworkingItemSkeletonProps) => (
  <Skeleton
    loading={loading}
    active={true}
    avatar={{ size: 'large' }}
    title={true}
    paragraph={{ rows: 3 }}
    className={cls(styles.skeleton, className)}
  />
)
