import { ChangeEvent, useCallback, useRef } from 'react'
import { Skeleton } from 'antd'
import { toast } from 'react-toastify'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { convertStorageUnitsToBytes } from 'utils'
import { toastDefaultOptions } from 'globalConstants'
import { EStorageUnitsEnum } from 'enums'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { useToggle } from 'App/hooks'

import { Avatar, TAvatarProps } from './Avatar'
import styles from './UploadAvatar.module.scss'

export type TUploadAvatarProps = PropsWithClasses<
  'skeleton' | 'root' | 'remove' | 'removeIcon' | 'wrapper',
  {
    onFileUpload: (file: File) => void

    file?: File | null
    loading?: boolean
    removeButtonLabel?: string
    onRemoveAvatar?: (onSuccess: () => void) => void
  }
> &
  PartialBy<
    Pick<
      TAvatarProps,
      | 'userId'
      | 'displayUserName'
      | 'lastName'
      | 'firstName'
      | 'type'
      | 'chatType'
      | 'showEditPreview'
    >,
    'userId'
  >

const ACCEPTED_IMAGE_TYPES = 'image/jpeg, image/jpg, image/png'
const MAX_FILE_SIZE = 5 // MB

export const UploadAvatar = ({
  file,
  firstName,
  lastName,
  displayUserName,
  type,
  chatType,
  userId,
  loading,
  classes,
  showEditPreview,
  removeButtonLabel,
  onFileUpload,
  onRemoveAvatar
}: TUploadAvatarProps) => {
  const { value: hasAvatar, toggleOff, toggleOn } = useToggle(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const showRemoveButton = hasAvatar && onRemoveAvatar

  const handleAvatarClick = () => {
    inputRef?.current?.click()
  }

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) {
      return
    }

    const currentFile = event.currentTarget.files[0]

    if (currentFile.size > convertStorageUnitsToBytes(MAX_FILE_SIZE, EStorageUnitsEnum.MB)) {
      toast.error(
        t('common.uploadAvatar.maxSizeError', { maxSize: `${MAX_FILE_SIZE}MB` }),
        toastDefaultOptions
      )
    } else {
      onFileUpload(currentFile)
    }

    if (inputRef && inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleRemoveAvatar = useCallback(() => {
    if (onRemoveAvatar) {
      onRemoveAvatar(toggleOff)
    }
  }, [onRemoveAvatar, toggleOff])

  return (
    <Skeleton
      avatar={true}
      active={true}
      title={false}
      paragraph={{ rows: 1 }}
      loading={loading}
      className={cls(styles.skeleton, classes?.skeleton)}
    >
      <div className={cls(styles.root, classes?.root)}>
        <input
          ref={inputRef}
          accept={ACCEPTED_IMAGE_TYPES}
          type="file"
          onChange={handleFileUpload}
          className={styles.hidden}
        />
        <div onClick={handleAvatarClick} className={styles.wrapper}>
          <Avatar
            className={cls(styles.avatarWrapper, classes?.wrapper)}
            showEditPreview={showEditPreview}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type}
            chatType={chatType}
            userId={userId}
            file={file}
            onLoad={toggleOn}
            onError={toggleOff}
          />
        </div>

        {showRemoveButton && (
          <button
            type="button"
            className={cls(styles.remove, classes?.remove)}
            onClick={handleRemoveAvatar}
          >
            <TrashIcon className={cls(styles.icon, classes?.removeIcon)} />
            {removeButtonLabel ?? t('common.uploadAvatar.removeButton')}
          </button>
        )}
      </div>
    </Skeleton>
  )
}
