import { Fragment, ReactNode } from 'react'
import cls from 'classnames'

import { TIds } from 'store'
import { generateRandomArray, getMapComponent } from 'utils'
import { FileTilesViewGrid, TFilesViewVariant } from 'App/components'

import { CaseCloudFilesItemSkeleton } from '../CaseCloudFilesItemSkeleton'

import styles from './CaseCloudFilesListWrapper.module.scss'

export type TCaseCloudFilesListWrapperProps = PropsWithClassName<{
  children: (id: string) => JSX.Element
  ids: TIds

  filesView?: TFilesViewVariant
  loading?: boolean
  itemSkeleton?: ReactNode
}>

const DEFAULT_SKELETON_LENGTH = 50

const WRAPPER = new Map<
  TFilesViewVariant,
  (props: TCaseCloudFilesListWrapperProps) => JSX.Element
>()
  .set('grid', ({ loading, children, ids }) => (
    <FileTilesViewGrid<string>
      data={ids}
      loading={loading}
      skeletonLength={DEFAULT_SKELETON_LENGTH}
    >
      {children}
    </FileTilesViewGrid>
  ))
  .set('list', ({ loading, children, ids, itemSkeleton }) => (
    <>
      {loading
        ? generateRandomArray(DEFAULT_SKELETON_LENGTH).map((key) => (
            <Fragment key={key}>{itemSkeleton}</Fragment>
          ))
        : ids.map(children)}
    </>
  ))

export const CaseCloudFilesListWrapper = ({
  ids,
  children,
  className,
  loading,
  itemSkeleton = <CaseCloudFilesItemSkeleton />,
  filesView = 'list'
}: TCaseCloudFilesListWrapperProps) => (
  <div className={cls(styles.root, loading && styles.loading, className)}>
    {getMapComponent(WRAPPER, filesView, {
      ids,
      children,
      className,
      loading,
      itemSkeleton,
      filesView
    })}
  </div>
)
