import { SubscriptionDurationsEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './SubscriptionPrice.module.scss'

export type TSubscriptionPriceClasses = 'root' | 'price' | 'duration'
export type TSubscriptionPriceProps = {
  price: number

  classes?: Partial<Record<TSubscriptionPriceClasses, string>>
  duration?: SubscriptionDurationsEnum
}

export const SubscriptionPrice = ({ price, duration, classes }: TSubscriptionPriceProps) => {
  const { t } = useTranslation()

  return (
    <div className={cls(styles.root, classes?.root)}>
      {price === 0 ? (
        <span className={styles.price}>{t('subscriptions.freePriceLabel')}</span>
      ) : (
        <>
          <span className={cls(styles.price, classes?.price)}>{price} MDT&nbsp;</span>
          <span className={cls(styles.duration, classes?.duration)}>/&nbsp;{duration}</span>
        </>
      )}
    </div>
  )
}
