import { useAdaptiveLayout } from 'App/hooks'

import styles from './CountersLabel.module.scss'

type TCountersLabelProps = {
  text: string
}

export const CountersLabel = ({ text }: TCountersLabelProps) => {
  const { isMobile } = useAdaptiveLayout()

  return (
    <>
      {!isMobile && <span className={styles.title}>Meetings</span>}
      <span className={styles.text}>{text}</span>
    </>
  )
}
