import { useState, useEffect, ReactNode, Ref } from 'react'
import cls from 'classnames'
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'

import { Button, HelperText, LengthCounter } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { MAX_DRAFT_LENGTH } from 'globalConstants'

import styles from './Editor.module.scss'

export type TEditorProps = Omit<TextAreaProps, 'value'> & {
  loading: boolean
  minRows?: number
  maxRows?: number
  initialLength?: number
  valueLengthMax?: number
  value?: string
  error?: boolean
  submitLabel?: ReactNode
  inputRef?: Ref<HTMLInputElement>
  customBottom?: ReactNode
  autoSize?: TextAreaProps['autoSize']
  classes?: Partial<Record<TEditorClasses, string>>
}

type TEditorClasses = 'root' | 'wrapper' | 'input' | 'footer'

export const Editor = ({
  className,
  valueLengthMax,
  value,
  error,
  loading,
  inputRef,
  onFocus,
  customBottom,
  autoSize,
  classes,
  submitLabel,
  minRows = 2,
  maxRows = 4,
  initialLength = 0,
  ...rest
}: TEditorProps) => {
  const [valueLength, setValueLength] = useState<number>(value?.length ?? 0)

  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  useEffect(() => {
    const newValueLength = value?.length ?? 0

    if (newValueLength !== valueLength) {
      setValueLength(newValueLength)
    }
  }, [value, valueLength])

  const textAreaSize = isMobile ? { minRows: 4, maxRows: 7 } : { minRows, maxRows }

  return (
    <div className={cls(styles.root, className, classes?.root)}>
      <div className={cls(styles.wrapper, classes?.wrapper)}>
        <div className={cls(styles.input, classes?.input)}>
          <TextArea
            {...rest}
            value={value}
            disabled={loading}
            ref={inputRef}
            autoSize={autoSize ?? textAreaSize}
            maxLength={MAX_DRAFT_LENGTH}
            onFocus={onFocus}
          />
        </div>

        {customBottom ?? (
          <div className={cls(styles.footer, classes?.footer)}>
            <Button
              className={styles.footerBtn}
              type="submit"
              size="sm"
              disabled={error || loading}
              loading={loading}
            >
              {submitLabel ?? t('cases.details.textEditor.submitButton')}
            </Button>
          </div>
        )}
      </div>
      {valueLengthMax && (
        <HelperText position="static" align="right">
          <LengthCounter
            currentLength={valueLength}
            maxLength={valueLengthMax}
            initialLength={initialLength}
          />
        </HelperText>
      )}
    </div>
  )
}
