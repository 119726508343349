import { useMemo } from 'react'
import { BusinessAccountRoleNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useDialog } from 'App/hooks'
import { InfoDrawerContainer } from 'App/containers/Organization'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import {
  UnityHubHeaderLayout,
  TUnityHubHeaderLayoutAction,
  TUnityHubHeaderLayoutProps
} from 'App/components/UnityHub'

type TOrganizationHeaderProps = Pick<
  TUnityHubHeaderLayoutProps,
  'name' | 'loading' | 'showAvatar' | 'id'
>

export const OrganizationHeader = ({ name, loading, id, showAvatar }: TOrganizationHeaderProps) => {
  const dispatch = useAppDispatch()

  const { open, toggle } = useDialog()

  const accountRoleName = useAppSelector((state) => state.global.accountData?.role?.name)
  const roleName = useAppSelector(
    (state) => state.organizations.data.list[id]?.businessUser.role.name
  )

  const { t } = useTranslation()

  const shouldShowKebab =
    accountRoleName !== BusinessAccountRoleNameEnum.SUPERADMIN &&
    roleName !== BusinessAccountRoleNameEnum.SUPERADMIN

  const menuItems = useMemo(
    () => [
      {
        content: t('organizations.kebabMenu.leaveOrganization'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: () => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.leaveOrganizationConfirm.title'),
              modalType: EModalComponents.LEAVE_ORGANIZATION_DIALOG,
              modalProps: {
                organizationId: id
              }
            })
          )
        }
      }
    ],
    [dispatch, id, t]
  )
  const actions = useMemo<TUnityHubHeaderLayoutAction[]>(
    () => [
      {
        key: 'info',
        text: t('organizations.actions.organizationInfo'),
        icon: <InfoIcon />,
        onClick: toggle
      }
    ],
    [t, toggle]
  )

  return (
    <UnityHubHeaderLayout
      id={id}
      name={name}
      showAvatar={showAvatar}
      showKebab={shouldShowKebab}
      menuItems={menuItems}
      loading={loading}
      actions={actions}
    >
      <InfoDrawerContainer visible={open} onClose={toggle} organizationId={id} />
    </UnityHubHeaderLayout>
  )
}
