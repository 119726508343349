import { useQuery } from 'services/query'
import { TChannelVariant } from 'interfaces'
import { Spinner } from 'App/components'
import { getOrganizationJoinToChannelTokenInfo, handleError } from 'api'

import { useOrganizationsJoinViaLinkCore } from '../useOrganizationsJoinViaLinkCore'

const QUERY_KEY = 'get-community-join-to-channel-token-info'

type TCommunitiesJoinViaLinkToChatProps = {
  variant: Extract<TChannelVariant, 'chat'>
}

export const JoinViaLinkOrganizationChannel = ({ variant }: TCommunitiesJoinViaLinkToChatProps) => {
  const { id, token, isNotProfessional, redirectToDefaultPage, redirectToOrganization } =
    useOrganizationsJoinViaLinkCore({ variant })

  const { isFetching } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getOrganizationJoinToChannelTokenInfo({ token, organizationId: id }),
    enabled: !isNotProfessional,
    onSuccess: ({ name, isMember, id: channelId }) =>
      redirectToOrganization({
        title: name,
        channelId,
        isMember
      }),
    onError: (err) => {
      handleError(err)
      redirectToDefaultPage()
    }
  })

  if (isFetching) {
    return <Spinner contentCenter />
  }

  return null
}
