import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, createProcessingSelector } from 'store/loading'
import { removeErrorNotification } from 'store/errors'
import { EDIT_BUSINESS_ACCOUNT, GET_USER_PROFILE_BASIC_INFORMATION } from 'store/userProfile'
import { getCitiesRequest, GET_CITIES, GET_COUNTRIES, TCity, TCountry } from 'store/misc'
import { BusinessAccountBasicInformationForm, TFormCommonMethods, TOption } from 'App/components'

const processingEditSelector = createProcessingSelector([EDIT_BUSINESS_ACCOUNT])

const loadingSelector = createLoadingSelector([
  GET_USER_PROFILE_BASIC_INFORMATION,
  GET_COUNTRIES,
  GET_CITIES
])

export type TBusinessAccountBasicInformationFormValues = {
  size: TOption
  country: TCountry
  city: TCity
  zip: string
  addressLine1: string
  addressLine2: string
  about: string
  headline: string
}

export type TBusinessAccountBasicInformationFormContainerProps = {
  onSubmit: (values: TBusinessAccountBasicInformationFormValues) => void
}

export type TBusinessAccountBasicInformationFormProps = TFormCommonMethods &
  TBusinessAccountBasicInformationFormContainerProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => {
  const {
    companyName,
    email,
    country,
    city,
    about,
    headline,
    addressLine1,
    addressLine2,
    zip,
    size
  } = state.userProfile.basicInformation

  return {
    processing: processingEditSelector(state),
    loading: loadingSelector(state),
    error: state.errors[EDIT_BUSINESS_ACCOUNT],
    countries: state.misc.countries,
    cities: state.misc.cities,
    addressLine1,
    addressLine2,
    companyName,
    email,
    country,
    city,
    about,
    headline,
    zip,
    size
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCities: getCitiesRequest,
      removeErrorNotification: () => removeErrorNotification(EDIT_BUSINESS_ACCOUNT)
    },
    dispatch
  )

export const BusinessAccountBasicInformationFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessAccountBasicInformationForm)
