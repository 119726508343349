import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { useToggle } from '../../hooks'
import {
  createEventInviteLink,
  deactivateEventInviteLink,
  getEventInviteLink
} from '../../../api/events'
import { generateJoinToEventLink } from '../../../utils'

import { InvitePopover, TInvitePopoverProps } from './Popover'

export type TEventInvitePopoverProps = {
  id: string
  name: string
  visible?: boolean
  children?: ReactNode
  placement?: TInvitePopoverProps['placement']
  onVisibleChange?: (value: boolean) => void
}

export const EventInvitePopover = ({
  id,
  name,
  children,
  visible,
  onVisibleChange,
  placement = 'bottom'
}: TEventInvitePopoverProps) => {
  const { value: open, setValue } = useToggle(visible)

  const { t } = useTranslation()

  const { data: initialData, remove: removeInitialData } = useQuery({
    queryKey: ['get-invitation-link', id],
    queryFn: () => getEventInviteLink(id),
    cacheTime: 0,
    enabled: open
  })

  const {
    data,
    remove: removeData,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ['create-invitation-link', id],
    queryFn: () => createEventInviteLink(id),
    cacheTime: 0,
    enabled: false
  })

  const { mutateAsync: deactivateLink } = useMutation({
    mutationKey: ['deactivate-event-link', id],
    mutationFn: deactivateEventInviteLink,
    onSuccess: () => {
      removeData()
      removeInitialData()
      toast.success(t('common.toast.linkDeactivated'), toastDefaultOptions)
    }
  })

  const handleVisibleChange = (value: boolean) => {
    if (onVisibleChange) {
      onVisibleChange(value)
    }
    setValue(value)
  }

  useEffect(() => {
    if (visible !== undefined) {
      setValue(visible)
    }
  }, [visible, setValue])

  const linkData = data || initialData

  return (
    <InvitePopover
      visible={open}
      placement={placement}
      onVisibleChange={handleVisibleChange}
      byLink={{
        isFetching,
        qrCode: { fileName: t('common.invitationPopover.qrCodeFileName', { name }) },
        data: linkData,
        onClick: refetch,
        tooltip: t('common.invitationPopover.inviteByLinkHelp_event'),
        deactivate: {
          modalTitle: t('modal.deactivateInvitationLinkConfirm.title'),
          modalProps: {
            confirmLabel: t('modal.deactivateInvitationLinkConfirm.submitButton'),
            content: t('modal.deactivateInvitationLinkConfirm.content_event'),
            onConfirm: () => deactivateLink(id)
          }
        },
        inviteLink: generateJoinToEventLink(linkData?.token ?? '')
      }}
    >
      {children}
    </InvitePopover>
  )
}
