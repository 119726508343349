import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { UploadSourcesEnum } from '@medentee/enums'
import { FC } from 'react'

import { State } from 'redux/rootReducer'
import {
  createProcessingSelector,
  GET_CASE_MED_CLOUD_FILES,
  createLoadingSelector,
  getCaseMedCloudFilesRequest,
  ATTACH_SELECTED_CASE_MED_CLOUD_FILES,
  attachSelectedCaseMedCloudFilesRequest,
  removeErrorNotification,
  resetAttachFilesAction
} from 'store'
import {
  FileAttachDialog,
  TFileAttachDialogPropsOwnProps
} from 'App/components/File/FileAttachDialog'

export type TCaseAttachFileDialogContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_CASE_MED_CLOUD_FILES])
const processingSelector = createProcessingSelector([ATTACH_SELECTED_CASE_MED_CLOUD_FILES])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  processing: processingSelector(state),
  error: state.errors[ATTACH_SELECTED_CASE_MED_CLOUD_FILES],
  fileIds: state.medCloud.attachFiles.ids,
  selectedFileIds: state.medCloud.attachFiles.selectedFiles,
  total: state.medCloud.attachFiles.total,
  uploadFileIds: state.medCloud.attachFiles.uploadingFiles,
  search: state.medCloud.filters.search,
  uploadedFrom: UploadSourcesEnum.CASE
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseMedCloudFiles: getCaseMedCloudFilesRequest,
      attachSelectedFilesToCase: attachSelectedCaseMedCloudFilesRequest,
      hideAttachModal: resetAttachFilesAction,
      removeErrorNotification: () => removeErrorNotification(ATTACH_SELECTED_CASE_MED_CLOUD_FILES)
    },
    dispatch
  )

export const CaseAttachFileDialogContainer: FC<TFileAttachDialogPropsOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileAttachDialog)
