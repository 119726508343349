import { useEffect, useRef, useState, RefObject } from 'react'

import { TFileViewerProps, FileViewer } from 'App/components'
import { EExtendableCardType } from 'store'
import { getMapComponent } from 'utils'
import { useOnScreen } from 'App/hooks'

export type TSlideViewPlugProps = TFileViewerProps & {
  type: EExtendableCardType
  currentSlideId: string
  rootRef: RefObject<HTMLDivElement>
}

const COMPONENT = new Map<EExtendableCardType, (props: TFileViewerProps) => JSX.Element>()
  .set(EExtendableCardType.FILE_VIEWER, (props) => <FileViewer {...props} />)
  .set(EExtendableCardType.SHOWCASE_GALLERY, (props) => <FileViewer {...props} />)

export const getViewerComponent = (type: EExtendableCardType) => (props: TFileViewerProps) =>
  getMapComponent(COMPONENT, type, props)

export const SlideViewPlug = ({ currentSlideId, type, rootRef, ...item }: TSlideViewPlugProps) => {
  const slideRef = useRef<HTMLDivElement>(null)

  const [preload, setPreload] = useState(false)

  const { isIntersecting } = useOnScreen(slideRef, {
    root: rootRef.current
  })
  const isActive = currentSlideId === item.id

  useEffect(() => {
    if (isIntersecting) {
      setPreload(true)
    }
  }, [isIntersecting])

  if (!type) {
    return null
  }

  return getViewerComponent(type)({ ...item, slideRef, isActive, preload })
}
