import { useTranslation } from 'react-i18next'

import { Divider } from 'App/components'
import { TVideoReadinessStatusProps, VideoReadinessStatus } from 'App/containers'

import styles from './VideoStatusContextWrapper.module.scss'

export type TVideoStatusContextWrapperProps = Pick<TVideoReadinessStatusProps, 'fileId' | 'size'>

export const VideoStatusContextWrapper = ({ fileId, size }: TVideoStatusContextWrapperProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Divider spaces={[0, 16]} />
      <p className={styles.title}>{t('files.videoStatus.title')}</p>
      <VideoReadinessStatus fileId={fileId} size={size} />
    </div>
  )
}
