import { useMemo } from 'react'
import cls from 'classnames'
import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EItemSentType } from 'enums'
import { generateRandomArray, getMapComponent } from 'utils'
import { TIds, useAppSelector } from 'store'
import { EInvitationType } from 'globalConstants'
import { EEmptyListIconSize, EmptyList, Pagination } from 'App/components'
import {
  EmptyListInviteButton,
  InvitationItemReceivedContainer,
  TInvitationItemReceivedContainerProps,
  InvitationItemSentContainer,
  TInvitationItemSentContainerOwnProps
} from 'App/containers'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

import styles from './InvitationsList.module.scss'

export type TInvitationsListProps = {
  ids: TIds
  type: EInvitationType
  loading: boolean
  setPage: (page: number) => void

  itemSentType?: EItemSentType
  page?: number
  total?: number
  showBy?: number
  accountTypeName?: AccountTypeNames
  emptyListText?: string
  emptyListLinkText?: string
}

const INVITATION_ITEM_CONTAINER = new Map<
  EInvitationType,
  (
    props: TInvitationItemSentContainerOwnProps & TInvitationItemReceivedContainerProps
  ) => JSX.Element
>()
  .set(EInvitationType.SENT, ({ id, itemSentType, contactTypeName }) => (
    <InvitationItemSentContainer
      id={id}
      key={id}
      itemSentType={itemSentType}
      contactTypeName={contactTypeName}
    />
  ))
  .set(EInvitationType.RECEIVED, ({ id }) => <InvitationItemReceivedContainer id={id} key={id} />)

export const InvitationsList = ({
  ids,
  type,
  page,
  total,
  showBy,
  loading,
  itemSentType,
  emptyListText,
  emptyListLinkText,
  setPage,
  accountTypeName: contactTypeName = AccountTypeNames.PROFESSIONAL
}: TInvitationsListProps) => {
  const { t } = useTranslation()

  const accountTypeName = useAppSelector((state) => state.global.accountData?.type.name)

  const isInvitationTypeSent = type === EInvitationType.SENT

  const invitationsIds = loading ? generateRandomArray(showBy) : ids

  const icon = isInvitationTypeSent ? <AccountMultiplePlusIcon /> : <AccountMultipleIcon />

  const text = useMemo(() => {
    if (emptyListText) {
      return emptyListText
    }

    return isInvitationTypeSent
      ? t('invitations.placeholder_sent')
      : t('invitations.placeholder_received')
  }, [emptyListText, isInvitationTypeSent, t])

  return invitationsIds.length ? (
    <>
      <div className={cls(styles.list, loading && styles.listSkeleton)}>
        {invitationsIds.map((id) =>
          getMapComponent(INVITATION_ITEM_CONTAINER, type, {
            id,
            itemSentType,
            contactTypeName
          })
        )}
      </div>

      <Pagination
        pageSize={showBy}
        total={total}
        current={page}
        onChange={setPage}
        wrapperClassName={styles.pagination}
      />
    </>
  ) : (
    <div className={styles.listEmpty}>
      <EmptyList icon={icon} text={text} iconSize={EEmptyListIconSize.MD}>
        {isInvitationTypeSent && (
          <EmptyListInviteButton
            accountType={accountTypeName}
            contactType={contactTypeName}
            emptyListLinkText={emptyListLinkText}
          />
        )}
      </EmptyList>
    </div>
  )
}
