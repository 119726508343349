import { CaseStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EmptyList, EEmptyListIconSize } from 'App/components'
import { CaseInviteMembersButton } from 'App/containers'
import { ReactComponent as AccountMultiplePlusIcon } from 'assets/icons/AccountMultiplePlus.svg'

import styles from './CaseMemberListEmpty.module.scss'

export type TCaseMemberListEmptyProps = {
  caseStatus: CaseStatusesEnum
}

export const CaseMemberListEmpty = ({ caseStatus }: TCaseMemberListEmptyProps) => {
  const { t } = useTranslation()

  return (
    <EmptyList
      text={t(`cases.details.membersSection.placeholder.${caseStatus}`)}
      icon={<AccountMultiplePlusIcon />}
      iconSize={EEmptyListIconSize.MD}
      className={styles.emptyList}
    >
      {caseStatus === CaseStatusesEnum.ACTIVE && <CaseInviteMembersButton link={true} />}
    </EmptyList>
  )
}
