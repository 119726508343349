import { FC, useEffect } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, ErrorModal } from 'App/components'
import { TUserProfileDeleteProfessionConfirmContainerProps } from 'App/containers'

import styles from './UserProfileDeleteProfessionConfirm.module.scss'

export type TUserProfileDeleteProfessionConfirmOwnProps = {
  specializationsPresent: boolean
  professionId: string
}

type TUserProfileDeleteProfessionConfirmProps = TUserProfileDeleteProfessionConfirmOwnProps &
  TUserProfileDeleteProfessionConfirmContainerProps

export const UserProfileDeleteProfessionConfirm: FC<TUserProfileDeleteProfessionConfirmProps> = ({
  error,
  processing,
  removeErrorNotification,
  deleteProfession,
  specializationsPresent,
  professionId,
  accountType
}) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  const onClick = () => {
    deleteProfession({
      id: professionId
    })
  }

  const text = specializationsPresent
    ? t('modal.deleteUserProfessionConfirm.contentExtended', { context: accountType })
    : t('modal.deleteUserProfessionConfirm.content', { context: accountType })

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{text}</p>
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={onClick}
      >
        {t('modal.deleteUserProfessionConfirm.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
