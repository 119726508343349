import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { Redirect, Switch, generatePath, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  GET_COMMUNITIES,
  createLoadingSelector,
  getCommunitiesRequest,
  resetCommunities,
  useAppDispatch,
  useAppSelector,
  LOST_ACCESS_TO_COMMUNITY
} from 'store'
import { shouldSkipAction } from 'services/skipWSActions'
import { ESocketEmitEventNamesCommunities } from 'services/webSocket'
import { toastDefaultOptions } from 'globalConstants'
import { useAdaptiveLayout, useNotificationTracker } from 'App/hooks'
import { UnityHubLayout } from 'App/components/UnityHub'
import { RoleRouteContainer } from 'App/containers'
import { EmptyList, EEmptyListIconSize } from 'App/components'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as PeopleNearbyIcon } from 'assets/icons/PeopleNearby.svg'

import { toast } from '../../components/ToastContainer'

import { CommunitiesNav } from './CommunitiesNav'
import { CommunityContent } from './CommunityContent'

export const COMMUNITIES_CONTENT_PATH = '/communities/:id'
export const COMMUNITY_MEMBERS_PATH = '/communities/:id/members'

const loadingSelector = createLoadingSelector([GET_COMMUNITIES])
const NOTIFICATIONS = new Set([ESocketEmitEventNamesCommunities.LOST_ACCESS_TO_COMMUNITY])

export const Communities = () => {
  const { replace } = useHistory()

  const dispatch = useAppDispatch()

  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const loaded = useRef(false)
  useNotificationTracker({
    channel: 'community',
    notifications: NOTIFICATIONS,
    onOccurrence: (payload) => {
      dispatch(getCommunitiesRequest())

      replace('/communities')
      if (!shouldSkipAction({ type: LOST_ACCESS_TO_COMMUNITY, payload })) {
        toast.error(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    }
  })

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) => state.communities.data.ids)

  const { isExact } = useRouteMatch()
  const communitiesPageMatch = useRouteMatch<{ id: string }>(COMMUNITIES_CONTENT_PATH)
  const membersPageMatch = useRouteMatch(COMMUNITY_MEMBERS_PATH)

  const shouldShowNav =
    (isDesktop || (!isDesktop && (communitiesPageMatch?.isExact || isExact))) &&
    !membersPageMatch?.isExact
  const shouldShowEmpty = !ids.length && !loading

  const shouldRedirectToFirstCommunity =
    !loading &&
    !!ids.length &&
    loaded.current &&
    (isExact || (communitiesPageMatch?.params.id && !ids.includes(communitiesPageMatch.params.id)))

  useEffect(() => {
    loaded.current = true
  }, [loading])

  useEffect(() => {
    dispatch(getCommunitiesRequest())

    return () => {
      dispatch(resetCommunities())
    }
  }, [dispatch])

  if (shouldShowEmpty) {
    return (
      <EmptyList
        iconSize={EEmptyListIconSize.SM}
        icon={<PeopleNearbyIcon />}
        text={t('communities.placeholder_noCommunities')}
      />
    )
  }

  return (
    <UnityHubLayout>
      {shouldShowNav && <CommunitiesNav loading={loading} />}

      <Switch>
        <RoleRouteContainer path={COMMUNITIES_CONTENT_PATH}>
          {shouldRedirectToFirstCommunity ? (
            <Redirect push={false} to={generatePath(COMMUNITIES_CONTENT_PATH, { id: ids[0] })} />
          ) : (
            <CommunityContent loading={loading} />
          )}
        </RoleRouteContainer>

        <RoleRouteContainer>
          {shouldRedirectToFirstCommunity && (
            <Redirect push={false} to={generatePath(COMMUNITIES_CONTENT_PATH, { id: ids[0] })} />
          )}
          <EmptyList
            icon={<AccountGroupIcon />}
            text={t('communities.placeholder_choseCommunity')}
          />
        </RoleRouteContainer>
      </Switch>
    </UnityHubLayout>
  )
}
