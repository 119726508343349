import { memo, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { BroadcastTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  useAppSelector,
  GET_CHAT_ROOMS,
  isBusinessAccountSelector,
  GET_BROADCASTS
} from 'store'
import {
  TUnityHubRoomsNavLayoutItem,
  UnityHubRoomsNavLayout,
  UnityHubChannelIcon,
  TUnityHubRoomsNavLayoutControl
} from 'App/components/UnityHub'
import { UnityHubRoomsBroadcastList } from 'App/components/UnityHub/UnityHubRoomsBroadcastList'

import { UnityHubRoomsNavEventsList } from '../../UnityHub/UnityHubRoomsNavEventsList'

import { CommunityRoomsNavTextList } from './CommunityRoomsNavTextList'

const loadingChatRoomsSelector = createLoadingSelector([GET_CHAT_ROOMS])
const loadingBroadcastsSelector = createLoadingSelector([GET_BROADCASTS])

type TCommunityRoomsNavProps = {
  communityId: string
}

const CommunityRoomsNavView = ({ communityId }: TCommunityRoomsNavProps) => {
  const { t } = useTranslation()

  const loadingChatRooms = useAppSelector(
    (state) => loadingChatRoomsSelector(state) && !state.chat.chatRooms.ids.length
  )
  const loadingBroadcasts = useAppSelector(loadingBroadcastsSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const broadcastsIdsLength = useAppSelector((state) => !!state.broadcasts.data.ids.length)

  const { url } = useRouteMatch()

  const items = useMemo<TUnityHubRoomsNavLayoutItem[]>(
    () => [
      {
        key: 'broadcast',
        item: (
          <UnityHubRoomsBroadcastList entityId={communityId} type={BroadcastTypeEnum.COMMUNITY} />
        ),
        loading: loadingBroadcasts,
        hidden: !isBusinessAccount && !broadcastsIdsLength
      },
      {
        key: 'events',
        item: <UnityHubRoomsNavEventsList communityId={communityId} />
      },
      {
        key: 'news-channel',
        item: <CommunityRoomsNavTextList variant="news" communityId={communityId} />,
        loading: loadingChatRooms
      },
      {
        key: 'text-channel',
        item: <CommunityRoomsNavTextList variant="chat" communityId={communityId} />,
        loading: loadingChatRooms
      }
    ],
    [communityId, loadingBroadcasts, broadcastsIdsLength, isBusinessAccount, loadingChatRooms]
  )

  const controls = useMemo<TUnityHubRoomsNavLayoutControl[]>(
    () => [
      {
        key: 'members',
        to: `${url}/members`,
        icon: <UnityHubChannelIcon variant="members" />,
        name: t('communities.networkingLabel')
      }
    ],
    [url, t]
  )

  return <UnityHubRoomsNavLayout items={items} controls={controls} />
}

export const CommunityRoomsNav = memo(CommunityRoomsNavView)
