import { useCallback, useMemo } from 'react'
import { VariableSizeList } from 'react-window'
import { MenuListProps, GroupBase } from 'react-select'

import { useVariableSizeListHeight } from 'App/hooks'

import { MenuItem } from './MenuItem'

const GROUP_HEADER_HEIGHT = 0
const OPTION_HEIGHT = 32
const MIN_LITS_HEIGHT = 300
const LITS_WIDTH = '100%'

export const MenuList = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  options,
  children
}: MenuListProps<Option, IsMulti, Group>) => {
  const { listRef, getRowHeight, setRowHeight } = useVariableSizeListHeight({
    defaultRowHeight: OPTION_HEIGHT
  })

  const itemChildren = useMemo(() => (Array.isArray(children) ? children : [children]), [children])

  const getOptionSize = useCallback((option: Option | GroupBase<Option>) => {
    if (option?.hasOwnProperty('options') && Array.isArray(option.options)) {
      return option.options.length * OPTION_HEIGHT + GROUP_HEADER_HEIGHT
    }

    return OPTION_HEIGHT
  }, [])

  const totalHeight = useMemo(
    () => options.reduce((height, option) => Number(height) + getOptionSize(option), 0),
    [getOptionSize, options]
  )

  return (
    <VariableSizeList
      ref={listRef}
      width={LITS_WIDTH}
      height={Math.min(totalHeight, MIN_LITS_HEIGHT)}
      itemCount={itemChildren.length}
      itemSize={getRowHeight}
      itemData={options}
    >
      {({ index, style }) => (
        <MenuItem index={index} style={style} setRowHeight={setRowHeight}>
          {itemChildren[index]}
        </MenuItem>
      )}
    </VariableSizeList>
  )
}
