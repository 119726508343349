import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { EEmptyListIconSize, EmptyList, SearchCascader } from 'App/components/common'
import { ListHeaderContainer } from 'App/containers/Organization'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import { useOrganizationGroupedMembers } from 'App/containers/Organizations/useOrganizationGroupedMembers'
import {
  GroupedMembersList,
  GroupedMembersItemDepartment
} from 'App/components/Organizations/Members'

import { Item } from '../Item'

import styles from './List.module.scss'

export type TListProps = {
  organizationId: string
  isStaff: boolean
}

const MEMBERS_QUERY_KEY_PREFIX = 'departments-grouped-members'

export const List = ({ organizationId, isStaff }: TListProps) => {
  const {
    data,
    dataLength,
    initialData,
    loading,
    processing,
    shouldShowPlaceholder,
    search,
    searchBy,
    searchOptions,
    totalUniq,
    total,
    page,
    handleSearchBy,
    refetch,
    setData,
    setPage,
    setSearch,
    handleRefreshMemberList
  } = useOrganizationGroupedMembers({
    membersQueryKeyPrefix: MEMBERS_QUERY_KEY_PREFIX,
    organizationId
  })

  const { t } = useTranslation()
  const handleRefreshList = () => {
    setPage(0)
  }

  useEffect(() => {
    setPage(0)
    setData(initialData)
    refetch()
    // `debouncedSearch`. `searchBy` - are required
  }, [refetch, search, searchBy, setPage, setData, initialData])

  useEffect(() => {
    refetch()
    // `page` - is required
  }, [refetch, page])

  return (
    <div className={styles.root}>
      <ListHeaderContainer
        isStaff={isStaff}
        loading={loading}
        total={totalUniq}
        onRefresh={handleRefreshList}
      />

      <SearchCascader
        classes={{ root: styles.search }}
        searchSize={EInputSize.L}
        placeholder={t('organizations.infoDrawer.staffList.searchPlaceholder')}
        options={searchOptions}
        onSearchChange={setSearch}
        onCascaderChange={handleSearchBy}
      />

      {shouldShowPlaceholder ? (
        <EmptyList
          className={styles.placeholder}
          icon={<AccountMultipleIcon />}
          iconSize={EEmptyListIconSize.MD}
          hasAppliedSearch={!!search}
        />
      ) : (
        <GroupedMembersList
          loading={loading}
          processing={processing}
          total={total}
          page={page}
          dataLength={dataLength}
          setPage={setPage}
          classes={{ root: styles.groupedMembersRoot }}
        >
          {data.ids.map((id) => {
            const {
              departmentMembersCount,
              departmentName,
              departmentDescription,
              members,
              departmentLeaderId,
              organizationDepartmentsCount
            } = data.list[id] ?? {}

            const shouldShowDepartment = Number(organizationDepartmentsCount) > 0

            return (
              <div key={id}>
                {shouldShowDepartment && (
                  <GroupedMembersItemDepartment
                    classes={{ root: styles.groupedMembersItemRoot }}
                    name={departmentName}
                    count={departmentMembersCount}
                    description={departmentDescription}
                  />
                )}

                {members?.ids.map((memberId) => (
                  <Item
                    membersQueryKeyPrefix={MEMBERS_QUERY_KEY_PREFIX}
                    key={memberId}
                    member={members.list[memberId]}
                    isLead={memberId === departmentLeaderId}
                    handleRefreshMemberList={handleRefreshMemberList}
                  />
                ))}
              </div>
            )
          })}
        </GroupedMembersList>
      )}
    </div>
  )
}
