import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { AccountAndNotifications } from 'App/components'
import { createLoadingSelector, getAccountSettingsRequest, GET_ACCOUNT_SETTINGS } from 'store'

export type TAccountAndNotificationsContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_ACCOUNT_SETTINGS])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  type: state.global.accountData?.type.name,
  role: state.global.accountData?.role?.name
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAccountSettings: getAccountSettingsRequest
    },
    dispatch
  )

export const AccountAndNotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountAndNotifications)
