import { useCallback, useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import cls from 'classnames'
import get from 'lodash/get'

import { ELayoutNavKey } from 'enums'
import { useAppSelector } from 'store'
import { useSessionStorage } from 'App/hooks/useSessionStorage'

import { TNavClasses } from '../Nav'
import { isActiveRoute, TNavItem } from '../Nav/nav.constants'
import { NavItemBadge } from '../NavItemBadge'

import styles from './NavItem.module.scss'

export type TNavItemProps = TNavItem &
  TNavClasses & {
    variant?: 'primary' | 'secondary' | 'horizontal'
    hidden?: boolean
    badgeStateString?: string
    type?: 'external' | 'text'
  }

export const NavItem = ({
  label,
  startsWith = false,
  to,
  icon = null,
  itemKey,
  classes,
  onClick,
  isActive,
  variant,
  type,
  counterStateString
}: TNavItemProps) => {
  const { push } = useHistory()
  const count: number = useAppSelector((state) =>
    counterStateString ? get(state.notifications.general, counterStateString) : 0
  )
  const defaultIsActive = useCallback((_, location) => isActiveRoute(startsWith, to, location), [])
  const actualTo = useMemo(() => {
    if (itemKey === ELayoutNavKey.CONNECTOR && count > 0) {
      return `${to.toString()}/my-adverts`
    }
    return to
  }, [itemKey, to, count])

  const { getSessionStorageData } = useSessionStorage({ keySuffix: itemKey })

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      const keptPath = getSessionStorageData()

      if (keptPath) {
        e.preventDefault()
        push(keptPath)
      }

      onClick && onClick()
    },
    [getSessionStorageData, onClick, push]
  )

  const className = cls(
    styles.root,
    { [styles.secondary]: variant === 'secondary', [styles.horizontal]: variant === 'horizontal' },
    classes?.link
  )
  const content = (
    <>
      <div className={cls(styles.iconWrapper, classes?.iconWrapper)}>
        {icon}
        <NavItemBadge
          count={count}
          isActive={isActive ?? defaultIsActive}
          className={cls(styles.badge, classes?.badge)}
        />
      </div>
      <span className={cls(styles.text, classes?.text)}>{label}</span>
    </>
  )

  if (type === 'external') {
    return (
      <a target="_blank" rel="noreferrer" href={to.toString()} className={className}>
        {content}
      </a>
    )
  }

  if (type === 'text') {
    return (
      <span className={className} onClick={onClick}>
        {content}
      </span>
    )
  }

  return (
    <NavLink
      className={className}
      activeClassName={cls(styles.active, classes?.linkActive)}
      to={actualTo}
      onClick={handleClick}
      isActive={isActive ?? defaultIsActive}
    >
      {content}
    </NavLink>
  )
}
