import { memo, useMemo } from 'react'
import { BroadcastTypeEnum } from '@medentee/enums'

import {
  createLoadingSelector,
  useAppSelector,
  GET_CHAT_ROOMS,
  GET_BROADCASTS,
  isBusinessAccountSelector
} from 'store'
import { TUnityHubRoomsNavLayoutItem, UnityHubRoomsNavLayout } from 'App/components/UnityHub'
import { UnityHubRoomsBroadcastList } from 'App/components/UnityHub/UnityHubRoomsBroadcastList'

import { EventsRoomsNavTextList } from './EventsRoomsNavTextList'
import { EventsRoomsNavHighlights } from './EventsRoomsNavHighlights'

const loadingChatRoomsSelector = createLoadingSelector([GET_CHAT_ROOMS])

type TEventsRoomsNavProps = {
  eventId: string
}

const EventsRoomsNavView = ({ eventId }: TEventsRoomsNavProps) => {
  const loadingChatRooms = useAppSelector(
    (state) => loadingChatRoomsSelector(state) && !state.chat.chatRooms.ids.length
  )
  const loadingBroadcasts = useAppSelector(createLoadingSelector([GET_BROADCASTS]))
  const broadcastsIdsLength = useAppSelector((state) => !!state.broadcasts.data.ids.length)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const items = useMemo<TUnityHubRoomsNavLayoutItem[]>(
    () => [
      {
        key: 'broadcast',
        item: <UnityHubRoomsBroadcastList entityId={eventId} type={BroadcastTypeEnum.EVENT} />,
        loading: loadingBroadcasts,
        hidden: !isBusinessAccount && !broadcastsIdsLength
      },
      {
        key: 'highlights',
        item: <EventsRoomsNavHighlights eventId={eventId} />,
        loading: loadingChatRooms
      },
      {
        key: 'news-channel',
        item: <EventsRoomsNavTextList variant="news" eventId={eventId} />,
        loading: loadingChatRooms
      },
      {
        key: 'text-channel',
        item: <EventsRoomsNavTextList variant="chat" eventId={eventId} />,
        loading: loadingChatRooms
      }
    ],
    [eventId, loadingBroadcasts, isBusinessAccount, broadcastsIdsLength, loadingChatRooms]
  )

  return <UnityHubRoomsNavLayout items={items} />
}

export const EventsRoomsNav = memo(EventsRoomsNavView)
