import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { isBusinessAccountSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot'
import { IconButton } from 'App/components'
import { ReactComponent as CommentPlusIcon } from 'assets/icons/CommentPlus.svg'
import { TChannelVariant } from 'interfaces'

type TEventCreateChannelButtonProps = {
  eventId: string
  variant: Extract<TChannelVariant, 'chat' | 'news'>
}

export const EventCreateChannelButton = ({ eventId, variant }: TEventCreateChannelButtonProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const handleCreateChannel = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.EVENT_CREATE_CHANNEL,
        modalTitle: t('modal.createChannel.create.title', { context: variant }),
        modalProps: { eventId, variant }
      })
    )
  }, [eventId, dispatch, variant, t])

  return isBusinessAccount ? (
    <IconButton
      iconComponent={<CommentPlusIcon />}
      iconSize={EIconSize.CUSTOM}
      toolTip={t('unityHub.channelsList.createChannelButtonTooltip')}
      onClick={handleCreateChannel}
    />
  ) : null
}
