import { Col, Row } from 'antd'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { TextFieldSkeleton } from '../../../../components'
import { TOrganizerSettingsFormValues } from '../OrganizerSettings'
import { UnityHubSettingsTitle } from '../../../../components/UnityHub/UnityHubSettings/UnityHubSettingsTitle'
import { UnityHubSettingsDescription } from '../../../../components/UnityHub/UnityHubSettings/UnityHubSettingsDescription'

import { OrganizerPlacementItem } from './OrganizerPlacementItem'

export type TOrganizerPlacementProps = {
  loading: boolean
}

export const OrganizerPlacement = ({ loading }: TOrganizerPlacementProps) => {
  const { values } = useFormState<TOrganizerSettingsFormValues>()

  const { t } = useTranslation()

  return (
    <Col xs={24}>
      <Row gutter={[0, 12]}>
        <Col xs={24}>
          <UnityHubSettingsTitle title={t('events.organizerSettings.placementTitle')} />
        </Col>
        <Col xs={24}>
          <UnityHubSettingsDescription
            description={t('events.organizerSettings.placementDescription')}
          />
        </Col>
        <TextFieldSkeleton loading={loading}>
          {values.placementOrganizations.map((_, index) => {
            const name = `placementOrganizations[${index}]`

            return <OrganizerPlacementItem key={name} name={name} type="organization" />
          })}
        </TextFieldSkeleton>
        <TextFieldSkeleton loading={loading}>
          {values.placementCommunities.map((_, index) => {
            const name = `placementCommunities[${index}]`

            return <OrganizerPlacementItem key={name} name={name} type="community" />
          })}
        </TextFieldSkeleton>
      </Row>
    </Col>
  )
}
