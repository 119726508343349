import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { hideModalAction, useAppDispatch } from 'store'

import { StepsFooterActions } from '../../../components'
import { deleteInvitationLink } from '../../../../api/invitations'

export type TDeactivateLinkProps = {
  onBack: () => void
}

export const DeactivateLink = ({ onBack }: TDeactivateLinkProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationFn: () => deleteInvitationLink(),
    onSuccess: () => {
      dispatch(hideModalAction())
      toast.success(t('common.toast.linkDeactivated'), toastDefaultOptions)
    }
  })

  return (
    <div>
      <p>{t('modal.deactivateInvitationLinkConfirm.content')}</p>
      <StepsFooterActions
        onClickBack={onBack}
        submitLoading={isLoading}
        submitLabel={t('modal.deactivateInvitationLinkConfirm.submitButton')}
        onSubmit={() => mutate()}
      />
    </div>
  )
}
