import { ReactNode } from 'react'
import cls from 'classnames'

import { Button, TButtonType } from 'App/components'
import { ReactComponent as DoubleChevronLeftIcon } from 'assets/icons/DoubleChevronLeft.svg'

import styles from './StepsFooterActions.module.scss'

export type TStepsFooterActionsProps = PropsWithClassName<{
  submitLabel?: ReactNode
  submitDisabled?: boolean
  submitLoading?: boolean
  submitTabIndex?: number
  submitType?: TButtonType
  backLabel?: ReactNode
  backTabIndex?: number
  backType?: TButtonType
  onSubmit?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onClickBack?: () => void
}>

export const StepsFooterActions = ({
  submitDisabled,
  submitLoading,
  onClickBack,
  onSubmit,
  backTabIndex,
  submitTabIndex,
  submitType,
  className,
  submitLabel = 'Next',
  backLabel = 'Back'
}: TStepsFooterActionsProps) => {
  const hasPrev = !!onClickBack
  const hasSubmit = !!submitLabel

  return hasPrev || hasSubmit ? (
    <div
      className={cls(
        styles.root,
        hasPrev && styles.rootWithPrev,
        !hasSubmit && styles.rootWithoutSubmit,
        className,
        'steps-footer-actions'
      )}
    >
      {hasPrev && (
        <button
          type="button"
          tabIndex={backTabIndex}
          onClick={onClickBack}
          className={styles.backButton}
        >
          <DoubleChevronLeftIcon />
          {backLabel}
        </button>
      )}

      {hasSubmit && (
        <Button
          className={styles.nextButton}
          tabIndex={submitTabIndex}
          onClick={onSubmit}
          disabled={submitDisabled}
          loading={submitLoading}
          type={submitType}
        >
          {submitLabel}
        </Button>
      )}
    </div>
  ) : null
}
