import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getOrganizationsInvitationsFiltersSelector,
  GET_ORGANIZATION_REGISTERED_USERS,
  useAppDispatch,
  useAppSelector,
  getOrganizationRegisteredUsersRequest
} from 'store'
import { List } from 'App/components/Organizations/OrganizationInvitations/List'

const loadingSelector = createLoadingSelector([GET_ORGANIZATION_REGISTERED_USERS])

export const OrganizationsInvitationsSent = () => {
  const dispatch = useAppDispatch()

  const ids = useAppSelector(
    (state) => state.organizations.invitations.registeredUsers.ids,
    isEqual
  )
  const total = useAppSelector((state) => state.organizations.invitations.registeredUsers.total)
  const loading = useAppSelector(loadingSelector)
  const { current, showBy } = useAppSelector(getOrganizationsInvitationsFiltersSelector, isEqual)

  const { t } = useTranslation()

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(
        getOrganizationRegisteredUsersRequest({
          current: page,
          showBy,
          direction: BusinessAccountDirectionEnum.INCOMING
        })
      )
    },
    [dispatch, showBy]
  )

  useEffect(() => {
    dispatch(
      getOrganizationRegisteredUsersRequest({ direction: BusinessAccountDirectionEnum.INCOMING })
    )
  }, [dispatch])

  return (
    <List
      ids={ids}
      type="staff-sent"
      loading={loading}
      pagination={{
        total,
        current,
        showBy,
        onChange: handlePaginationChange
      }}
      emptyText={t('invitations.placeholder_sentRequests')}
    />
  )
}
