import { ChatTypeEnum } from '@medentee/enums'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { hideModalAction, showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'
import { UploadAvatar } from 'App/components/common'

import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

import styles from './ChatCreateGroupUploadAvatar.module.scss'

export const ChatCreateGroupUploadAvatar = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { chatId, chatName, file, setFile, shouldRemovePhoto, setShouldRemovePhoto } =
    useChatCreateGroupContext()

  const handleUpload = useCallback(
    (nextFile: File) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.uploadImage.title_groupChatAvatar'),
          modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
          modalProps: {
            imageSrc: URL.createObjectURL(nextFile),
            onCrop: (croppedFile) => {
              setFile(croppedFile)
              setShouldRemovePhoto(false)
              dispatch(hideModalAction())
            }
          }
        })
      )
    },
    [dispatch, setFile, setShouldRemovePhoto, t]
  )

  const handleRemove = useCallback(
    (onSuccess: () => void) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.removeImageConfirm.title_groupChatAvatar'),
          modalType: EModalComponents.DELETE_IMAGE_DIALOG,
          modalProps: {
            content: t('modal.removeImageConfirm.content_groupChatAvatar'),
            onClick: () => {
              setShouldRemovePhoto(true)
              setFile(null)
              onSuccess()
              dispatch(hideModalAction())
            }
          }
        })
      )
    },
    [dispatch, setFile, setShouldRemovePhoto, t]
  )

  return (
    <UploadAvatar
      file={file}
      userId={shouldRemovePhoto ? undefined : chatId}
      showEditPreview={!chatName.length}
      displayUserName={chatName}
      chatType={ChatTypeEnum.GROUP}
      onFileUpload={handleUpload}
      onRemoveAvatar={handleRemove}
      classes={{ root: styles.root, skeleton: styles.skeleton }}
    />
  )
}
