import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TIds } from 'store'
import { generateRandomArray, getMapComponent } from 'utils'
import { EEmptyListIconSize, EmptyList, Pagination, TPaginationProps } from 'App/components/common'
import {
  OrganizationInvitationsAdministrationItem,
  OrganizationInvitationsNewUsersItem,
  OrganizationInvitationsRegisteredItem
} from 'App/containers'
import {
  OrganizationsInvitationsReceivedItem,
  OrganizationsInvitationsSentItem,
  TOrganizationsInvitationsReceivedItemProps
} from 'App/containers/Organization'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'
import { OrganizationStaffRequestsItem } from 'App/containers/Organization/OrganizationStaffRequests/OrganizationStaffRequestsItem'

import styles from './List.module.scss'

export type TListProps = {
  ids: TIds
  loading: boolean
  type:
    | 'staff-requests'
    | 'registered'
    | 'new-users'
    | 'administration'
    | 'staff-received'
    | 'staff-sent'

  itemProps?: Partial<TItemProps>
  emptyText?: string
  pagination?: {
    showBy?: number
  } & Partial<Pick<TPaginationProps, 'current' | 'total' | 'onChange'>>
}

type TItemProps = { id: string } | TOrganizationsInvitationsReceivedItemProps

const INVITATION_ITEM_CONTAINER = new Map<TListProps['type'], (props: TItemProps) => JSX.Element>()
  .set('registered', ({ id }) => <OrganizationInvitationsRegisteredItem key={id} id={id} />)
  .set('staff-received', ({ id, ...props }) => (
    <OrganizationsInvitationsReceivedItem key={id} id={id} {...props} />
  ))
  .set('staff-sent', ({ id }) => <OrganizationsInvitationsSentItem key={id} id={id} />)
  .set('administration', ({ id }) => <OrganizationInvitationsAdministrationItem key={id} id={id} />)
  .set('new-users', ({ id }) => <OrganizationInvitationsNewUsersItem key={id} id={id} />)
  .set('staff-requests', ({ id }) => <OrganizationStaffRequestsItem key={id} id={id} />)

export const List = ({
  ids,
  loading,
  type,
  emptyText,
  itemProps = {},
  pagination = {}
}: TListProps) => {
  const { t } = useTranslation()

  const invitationsIds = loading ? generateRandomArray() : ids
  const { current = 0, showBy, total, onChange } = pagination

  return invitationsIds.length ? (
    <>
      <div className={cls(loading && styles.skeleton)}>
        {ids.map((id) => getMapComponent(INVITATION_ITEM_CONTAINER, type, { id, ...itemProps }))}
      </div>

      <Pagination
        current={current}
        pageSize={showBy}
        total={total}
        onChange={onChange}
        wrapperClassName={styles.pagination}
      />
    </>
  ) : (
    <EmptyList
      icon={<AccountMultipleIcon />}
      text={emptyText ?? t('invitations.placeholder_sent')}
      iconSize={EEmptyListIconSize.MD}
    />
  )
}
