import { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { CaseTypesEnum, AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppSelector, getIsBasicSubscription } from 'store'
import { EModalComponents } from 'App/containers'

export const useUnlockCaseModal = (data: {
  caseId: string
  caseName: string

  caseType?: CaseTypesEnum | null
}) => {
  const dispatch = useDispatch()

  const dataRef = useRef(data)

  const { t } = useTranslation()

  const accountType = useAppSelector((store) => store.global.accountData?.type)
  const isBasicSubscription = useAppSelector(getIsBasicSubscription)

  const showUnlockModal = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.CASE_UNLOCK_CONFIRM,
        modalTitle: t('cases.modal.unlockCaseConfirm.title'),
        modalProps: dataRef.current
      })
    )
  }, [dispatch, t])

  const showUpgradeSubscriptionModal = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.UPGRADE_SUBSCRIPTION,
        modalTitle: t('cases.modal.upgradeSubscription.unlockCaseConfirm.title'),
        modalProps: {
          text: t('cases.modal.upgradeSubscription.unlockCaseConfirm.content', {
            context:
              accountType?.name === AccountTypeNames.PROFESSIONAL ? 'professional' : 'business'
          })
        }
      })
    )
  }, [accountType?.name, dispatch, t])

  const showModal = useCallback(() => {
    dataRef.current.caseType === CaseTypesEnum.A2A && isBasicSubscription
      ? showUpgradeSubscriptionModal()
      : showUnlockModal()
  }, [isBasicSubscription, showUnlockModal, showUpgradeSubscriptionModal])

  return {
    showModal
  }
}
