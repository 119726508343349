import { connect } from 'react-redux'

import { createLoadingSelector, GET_CHAT_ROOMS, isChatRoomMutedSelector } from 'store'
import { State } from 'redux/rootReducer'
import { ChatRoomItemCase, ChatRoomItemP2P } from 'App/components'
import { useChatContext } from 'App/context'
import { EChatViewType } from 'enums'
import { getMapComponent } from 'utils'

export type TChatRoomItemContainerProps = ReturnType<typeof mapStateToProps>

export type TChatRoomItemContainerViewBaseProps = {
  chatId: string
}

export type TChatRoomItemContainerViewProps = TChatRoomItemContainerViewBaseProps &
  TChatRoomItemContainerProps

const CHAT_ROOM_ITEM = new Map<
  EChatViewType,
  (props: TChatRoomItemContainerViewProps) => JSX.Element
>()
  .set(
    EChatViewType.P2P,
    ({
      chatId,
      chat,
      loading,
      unreadCount,
      mentionCount,
      draft,
      isSelected,
      isLastMessageEditing,
      isContact,
      isCoworker,
      isChatRoomMuted
    }) => (
      <ChatRoomItemP2P
        chatId={chatId}
        chat={chat}
        loading={loading}
        unreadCount={unreadCount}
        mentionCount={mentionCount}
        draft={draft}
        isSelected={isSelected}
        isLastMessageEditing={isLastMessageEditing}
        isContact={isContact}
        isCoworker={isCoworker}
        isChatRoomMuted={isChatRoomMuted}
      />
    )
  )
  .set(
    EChatViewType.CASE,
    ({ chatId, chat, loading, mentionCount, unreadCount, isSelected, isChatRoomMuted }) => (
      <ChatRoomItemCase
        chatId={chatId}
        chat={chat}
        loading={loading}
        unreadCount={unreadCount}
        mentionCount={mentionCount}
        isSelected={isSelected}
        isChatRoomMuted={isChatRoomMuted}
      />
    )
  )

export const ChatRoomItemContainerView = ({
  chatId,
  chat,
  loading,
  unreadCount,
  mentionCount,
  draft,
  isSelected,
  isLastMessageEditing,
  isContact,
  isCoworker,
  isChatRoomMuted
}: TChatRoomItemContainerViewProps) => {
  const { chatViewType } = useChatContext()

  return getMapComponent(CHAT_ROOM_ITEM, chatViewType, {
    chatId,
    chat,
    loading,
    unreadCount,
    mentionCount,
    draft,
    isSelected,
    isLastMessageEditing,
    isContact,
    isCoworker,
    isChatRoomMuted
  })
}

const loadingSelector = createLoadingSelector([GET_CHAT_ROOMS])

const mapStateToProps = (state: State, { chatId }: TChatRoomItemContainerViewBaseProps) => {
  const chat = state.chat.chatRooms.list[chatId]
  const notificationChat = state.notifications.general.chats.list[chatId]
  const selectedChatId = state.chat.chatRooms.activeChatId
  const isSelected = chat?.id ? chat?.id === selectedChatId : false
  const lastMessageId = chat?.lastMessage?.id
  const isLastMessageEditing = lastMessageId
    ? state.chat.chatMessages.editingIds.includes(lastMessageId)
    : false

  return {
    unreadCount: notificationChat?.unreadCount,
    chat,
    isSelected,
    isLastMessageEditing,
    draft: chat?.draft,
    isContact: chat?.interlocutorAccount?.isContact,
    isCoworker: chat?.interlocutorAccount?.isCoworker,
    mentionCount: notificationChat?.mentionCount,
    loading: !state.chat.chatRooms.ids.includes(chatId) && loadingSelector(state),
    isChatRoomMuted: isChatRoomMutedSelector(chatId)(state)
  }
}

export const ChatRoomItemContainer = connect(mapStateToProps)(ChatRoomItemContainerView)
