import { CaseCombinedTypeEnum, CaseRolesEnum, CaseStatusesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import capitalize from 'lodash/capitalize'

import { TCasesFilters, toggleCaseFilterAction, useAppDispatch, useAppSelector } from 'store'
import { EFilterTypes, useFilters } from 'App/components'
import { createSelectorOptions } from 'utils'

type TUseCasesFilter = {
  onReset: () => void
}

export const useCasesFilter = ({ onReset }: TUseCasesFilter) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const roles = useAppSelector((state) => state.cases.filters.roles)
  const statuses = useAppSelector((state) => state.cases.filters.statuses)
  const classifications = useAppSelector((state) => state.cases.filters.classifications)

  const caseRoleOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(CaseRolesEnum), (value) =>
        t(`cases.filters.roles.options.${value}`)
      ),
    [t]
  )

  const caseStatusOptions = useMemo(
    () =>
      // FROM
      // FOR SPECIAL TASK MED-6480
      createSelectorOptions(
        Object.values(CaseStatusesEnum).filter((status) => status !== CaseStatusesEnum.ARCHIVED),
        (value) => capitalize(t(`enum.caseStatusesEnum.${value}`))
      ),
    [t]
  )

  const caseTypeOptions = useMemo(
    () =>
      createSelectorOptions(Object.values(CaseCombinedTypeEnum), (value) =>
        capitalize(t(`cases.filters.type.options.${value}`))
      ),
    [t]
  )

  const filters = useMemo(
    () => [
      {
        title: t('cases.filters.roles.title'),
        sectionKey: 'roles',
        items: [
          {
            filterKey: 'roles',
            type: EFilterTypes.CHECKBOX,
            options: caseRoleOptions
          }
        ]
      },
      {
        title: t('cases.filters.status.title'),
        sectionKey: 'statuses',
        items: [
          {
            filterKey: 'statuses',
            type: EFilterTypes.CHECKBOX,
            // FROM
            // FOR SPECIAL TASK MED-6480
            options: caseStatusOptions
          }
        ]
      },
      {
        title: t('cases.filters.type.title'),
        sectionKey: 'classifications',
        items: [
          {
            filterKey: 'classifications',
            type: EFilterTypes.CHECKBOX,
            options: caseTypeOptions
          }
        ]
      }
    ],
    [caseRoleOptions, caseStatusOptions, caseTypeOptions, t]
  )

  return useFilters({
    value: {
      roles,
      statuses,
      classifications
    },
    filters,
    onReset,
    onChange: (key, newValue) =>
      dispatch(toggleCaseFilterAction({ [key]: newValue as TCasesFilters[] }))
  })
}
