import { MouseEvent } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'
import { ParseKeys } from 'i18next'

import { EChatHistoryEvents } from 'enums'
import {
  setChatHistoryEventAction,
  TAccount,
  TChatQuoteMessage,
  useAppDispatch,
  useAppSelector
} from 'store'

import { TruncatedText } from '../../../components'

import styles from './ChatMessagePinnedMessage.module.scss'

export type TChatMessagePinnedMessageProps = {
  id: string
  chatId: string
  sender: TAccount | null
  chatType?: ChatTypeEnum
  quoteMessage?: TChatQuoteMessage | null
}

const getLabelKey = (isDeleted: boolean, isGroupChat: boolean) => {
  let i18nKey: ParseKeys = 'chat.message.pinnedMessageLabel'

  if (isDeleted) {
    if (isGroupChat) {
      i18nKey = 'chat.message.pinnedDeletedMessageLabelWithUserName'
    } else {
      i18nKey = 'chat.message.pinnedDeletedMessageLabel'
    }
  }

  return i18nKey
}

export const ChatMessagePinnedMessage = ({
  id,
  sender,
  chatType,
  quoteMessage
}: TChatMessagePinnedMessageProps) => {
  const dispatch = useAppDispatch()
  const clearedUnixTs = useAppSelector(
    (state) => state.chat.chatRooms?.selectedChat?.clearedUnixTs ?? 0
  )
  const isDeleted = clearedUnixTs > (quoteMessage?.unixTs ?? 0)
  const isGroupChat = chatType === ChatTypeEnum.CASE_GROUP

  const { t } = useTranslation()

  const scrollToMessage = (e: MouseEvent) => {
    e.preventDefault()
    dispatch(
      setChatHistoryEventAction({
        type: EChatHistoryEvents.JUMP,
        payload: { eventTs: Date.now(), messageId: id }
      })
    )
  }

  return (
    <div className={styles.root}>
      <Trans
        t={t}
        i18nKey={getLabelKey(isDeleted, isGroupChat)}
        components={[
          <TruncatedText key={0} text={sender?.displayUserName ?? ''} />,
          // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content
          <a key={1} href="#" onClick={scrollToMessage} />
        ]}
      />
    </div>
  )
}
