import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { selectChatAction, resetSelectedChatAction, getSelectedChatRoomSelector } from 'store'
import { CaseOwnerChat } from 'App/components'

type TCaseOwnerChatContainerOwnProps = {
  chatId?: string
}

export type TCaseOwnerChatContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, { chatId }: TCaseOwnerChatContainerOwnProps) => ({
  lengthChatRooms: state.chat.chatRooms.ids.length,
  selectedChat: getSelectedChatRoomSelector(chatId)(state),
  activeChatId: state.chat.chatRooms.activeChatId
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectChat: selectChatAction,
      resetSelectedChat: resetSelectedChatAction
    },
    dispatch
  )

export const CaseOwnerChatContainer = connect(mapStateToProps, mapDispatchToProps)(CaseOwnerChat)
