import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  TTrashBinItem,
  createLoadingSelector,
  GET_TRASH_BIN_LIST,
  useAppSelector,
  getTrashBinRequest,
  setTrashBinPagination,
  useAppDispatch,
  setTrashBinSorting,
  setSelectedTrashBinFileId,
  resetTrashBin,
  showExtendableCardAction,
  TShowExtendableCardActionPayload
} from 'store'
import {
  FilePagination,
  FileWrapper,
  useColumnSorting,
  generateTableDataSource,
  useSelectedFilesSizeCalculation,
  FileInformationPanelDrawer,
  useFilesView,
  useSelection,
  EmptyFileList
} from 'App/components'
import { Pagination } from 'types'
import { ESorting } from 'enums'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'

import { TableView } from './TableView'
import { FileGridView } from './FileGridView'
import { useKebabMenu } from './useKebabMenu'

export type TDataSource = TTrashBinItem & {
  key: string
  fileId: string
}

type TOnPaginationChange = ({ current, showBy, total }: Pagination) => void

const loadingSelector = createLoadingSelector([GET_TRASH_BIN_LIST])

export const TrashBin = () => {
  const dispatch = useAppDispatch()

  const sortColumnHandler = useColumnSorting(setTrashBinSorting)

  const loading = useAppSelector(loadingSelector)
  const {
    search,
    list,
    ids,
    filters: { sorting, pagination }
  } = useAppSelector((state) => state.trashBin)
  const accountData = useAppSelector((state) => state.global.accountData)
  const selectedFile = useAppSelector((state) => {
    const { selectedFileId, list: filesList } = state.trashBin

    if (selectedFileId && selectedFileId in filesList) {
      return filesList[selectedFileId]
    }

    return null
  })

  const [view, setView] = useFilesView('med-cloud-trash-bin')

  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(ids)

  const { t } = useTranslation()

  const { name, direction } = sorting ?? {}
  const { current: page, showBy: pageSize, total: totalList } = pagination

  const selectedFileSize = useSelectedFilesSizeCalculation(selectedIds, list)

  const dataSource = generateTableDataSource<TTrashBinItem>(list, ids)

  useEffect(() => {
    setSelectedIds([])
    // `ids` property is required
  }, [setSelectedIds, ids])

  const onClose = () => {
    dispatch(setSelectedTrashBinFileId(null))
  }

  const onPaginationChange = useCallback<TOnPaginationChange>(
    ({ current, showBy, total }) => {
      dispatch(setTrashBinPagination({ current, showBy, total }))
    },
    [dispatch]
  )

  const onGridSorting = useCallback(
    (newName: string, newDirection: ESorting) => {
      dispatch(
        setTrashBinSorting({
          name: newName,
          direction: newDirection
        })
      )
    },
    [dispatch]
  )

  const showExtendableCard = useCallback(
    (payload: TShowExtendableCardActionPayload) => dispatch(showExtendableCardAction(payload)),
    [dispatch]
  )

  const { getMenuItems } = useKebabMenu()

  return (
    <>
      <FileWrapper
        name={name}
        direction={direction}
        current={page}
        showBy={pageSize}
        loading={loading}
        search={search}
        total={totalList}
        fetchFileList={getTrashBinRequest}
        resetFileList={resetTrashBin}
        fileList={
          <>
            {view === 'list' ? (
              <TableView
                selectedIds={selectedIds}
                view={view}
                dataSource={dataSource}
                sorting={sorting}
                loading={loading}
                selectMode={selectMode}
                filesSize={selectedFileSize}
                sortColumnHandler={sortColumnHandler}
                setSelectedIds={setSelectedIds}
                onViewChange={setView}
                showExtendableCard={showExtendableCard}
                onSelect={onSelect}
                getMenuItems={getMenuItems}
              />
            ) : (
              <FileGridView
                view={view}
                dataSource={dataSource}
                sorting={sorting}
                selectedIds={selectedIds}
                fileIds={ids}
                loading={loading}
                selectMode={selectMode}
                filesSize={selectedFileSize}
                onViewChange={setView}
                onSorting={onGridSorting}
                setSelectedIds={setSelectedIds}
                onSelect={onSelect}
                getMenuItems={getMenuItems}
              />
            )}

            <FilePagination
              current={page}
              total={totalList}
              showBy={pageSize}
              onPaginationChange={onPaginationChange}
            />
          </>
        }
        placeholder={
          <EmptyFileList
            defaultText={t('files.placeholder_trashBin')}
            search={search}
            icon={<TrashIcon />}
          />
        }
      />
      <FileInformationPanelDrawer
        withoutPermissions
        casesPermissions={[]}
        p2pPermissions={[]}
        accountId={accountData?.id ?? ''}
        onCloseFileInfoPanel={onClose}
        createdAt={selectedFile?.createdAt ?? ''}
        updatedAt={selectedFile?.updatedAt ?? ''}
        id={selectedFile?.id || ''}
        fileName={selectedFile?.fileName ?? ''}
        extension={selectedFile?.extension ?? ''}
        fileSize={selectedFile?.fileSize ?? ''}
        owner={accountData}
      />
    </>
  )
}
