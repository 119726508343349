import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setModalTitleAction, useAppDispatch } from 'store'

import { LinkSettings, TLinkSettingsProps } from './LinkSettings'
import { DeactivateLink } from './DeactivateLink'
import styles from './InvitationLinkSettings.module.scss'

export type TInvitationLinkSettingsProps = Pick<
  TLinkSettingsProps,
  'maxRegistrants' | 'createdAccountsNumber'
>

export const InvitationLinkSettings = ({
  maxRegistrants,
  createdAccountsNumber
}: TInvitationLinkSettingsProps) => {
  const dispatch = useAppDispatch()

  const [tab, setTab] = useState(0)

  const { t } = useTranslation()

  let content
  if (tab === 1) {
    content = (
      <DeactivateLink
        onBack={() => {
          setTab(0)
          dispatch(setModalTitleAction({ modalTitle: t('modal.invitationLinkSettings.title') }))
        }}
      />
    )
  } else {
    content = (
      <LinkSettings
        maxRegistrants={maxRegistrants}
        createdAccountsNumber={createdAccountsNumber}
        onDeactivate={() => {
          setTab(1)
          dispatch(
            setModalTitleAction({ modalTitle: t('modal.deactivateInvitationLinkConfirm.title') })
          )
        }}
      />
    )
  }

  return <div className={styles.root}>{content}</div>
}
