import { useTranslation } from 'react-i18next'

import { TruncatedText } from 'App/components/common'

import styles from './ScheduleMeetingSubTitle.module.scss'

export type TScheduleMeetingSubTitleProps = {
  userName?: string
  caseTitle?: string
  isGroupMeeting?: boolean
}

export const ScheduleMeetingSubTitle = ({
  isGroupMeeting,
  caseTitle,
  userName
}: TScheduleMeetingSubTitleProps) => {
  const { t } = useTranslation()

  return (
    <span className={styles.subTitle}>
      {isGroupMeeting ? (
        <TruncatedText text={t('modal.scheduleMeeting.subtitle_case', { caseTitle })} />
      ) : (
        t('modal.scheduleMeeting.subtitle_user', { userName })
      )}
    </span>
  )
}
