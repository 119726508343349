import 'intersection-observer'
import { RefObject, useEffect, useState } from 'react'

export const useOnScreen = <R extends HTMLElement>(
  ref: RefObject<R>,
  { root, rootMargin, threshold }: IntersectionObserverInit
) => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const element = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // if root height is smaller than target
          if ((entry.rootBounds?.height ?? 0) < entry.boundingClientRect.height) {
            setIntersecting(true)
          } else if (threshold) {
            setIntersecting(
              Array.isArray(threshold)
                ? threshold.some((item) => item > 0 && item >= entry.intersectionRatio)
                : true
            )
          } else {
            setIntersecting(entry.isIntersecting)
          }
        } else {
          setIntersecting(false)
        }
      },
      {
        root,
        rootMargin,
        threshold: threshold
          ? [0, ...(Array.isArray(threshold) ? threshold : [threshold])]
          : undefined
      }
    )

    if (element) {
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [ref, root, rootMargin, threshold])

  return { isIntersecting }
}
