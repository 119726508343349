import { useCallback } from 'react'

import { accountIdSelector, useAppSelector } from 'store'

export type TUseKeepEntityPathProps = {
  keySuffix: string
}

export const useSessionStorage = ({ keySuffix }: TUseKeepEntityPathProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const key = `p-${accountId}-${keySuffix}`

  const setSessionStorageData = useCallback(
    (path: string) => {
      if (!path) {
        return
      }

      sessionStorage.setItem(key, path)
    },
    [key]
  )

  const clearSessionStorageData = useCallback(() => {
    sessionStorage.removeItem(key)
  }, [key])

  const getSessionStorageData = useCallback(() => sessionStorage.getItem(key), [key])

  return {
    setSessionStorageData,
    getSessionStorageData,
    clearSessionStorageData
  }
}
