import cls from 'classnames'
import Draggable from 'react-draggable'
import { CallTypeEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import {
  ReceivedCallGroup,
  ReceivedCallDialogue,
  TReceivedCallGroupProps,
  IconButton,
  TReceivedCallDialogueProps
} from 'App/components'
import { useAdaptiveLayout, usePlaySound } from 'App/hooks'
import { EIconSize } from 'enums'
import { getMapComponent } from 'utils'
import { TReceivedCallContainerProps } from 'App/containers'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as EndCallIcon } from 'assets/icons/EndCall.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/VolumeUp.svg'
import { ReactComponent as VolumeOffIcon } from 'assets/icons/VolumeOff.svg'
import { incomingCallSoundBase64 } from 'assets/sounds/incomingCallSound'

import styles from './ReceivedCall.module.scss'

export type TReceivedCallProps = TReceivedCallContainerProps

const RECEIVED_CALL = new Map<
  CallTypeEnum,
  (props: TReceivedCallGroupProps & TReceivedCallDialogueProps) => JSX.Element
>()
  .set(CallTypeEnum.CASE_GROUP, (props) => <ReceivedCallGroup {...props} />)
  .set(CallTypeEnum.GROUP, (props) => <ReceivedCallGroup {...props} />)
  .set(CallTypeEnum.P2P, ({ firstName, lastName, offererUserId, displayUserName, type }) => (
    <ReceivedCallDialogue
      firstName={firstName}
      lastName={lastName}
      offererUserId={offererUserId}
      displayUserName={displayUserName}
      type={type}
    />
  ))

const CANCEL_DRAGGABLE_SELECTOR = 'cancel-draggable-received'

export const ReceivedCall = ({
  chatId,
  offererUserId,
  callType,
  caseData,
  displayUserName,
  type,
  sendAllowCall,
  sendEndCall,
  redirectToCaseFromChatRequest,
  muteCalls,
  callId,
  lastName = '',
  firstName = ''
}: TReceivedCallProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { pause, toggleMute, isAudioMute } = usePlaySound({
    audioSrc: incomingCallSoundBase64,
    autoplay: !muteCalls,
    defaultMute: muteCalls
  })

  const onAnswer = () => {
    if (callId) {
      sendAllowCall({ callId })
    }

    pause()
  }

  const onReject = () => {
    if (callId) {
      sendEndCall({ callId })
    }

    pause()
  }

  if (!offererUserId || !displayUserName || !chatId || !type) {
    return null
  }

  return (
    <Draggable
      bounds="body"
      disabled={isMobile}
      position={isMobile ? { x: 0, y: 0 } : undefined}
      cancel={`.${CANCEL_DRAGGABLE_SELECTOR}`}
    >
      <div className={styles.root}>
        <div className={styles.info}>
          {getMapComponent(RECEIVED_CALL, callType, {
            chatId,
            firstName,
            lastName,
            offererUserId,
            caseData,
            displayUserName,
            type,
            redirectToCaseFromChatRequest
          })}

          <span className={styles.description}>
            <Trans
              t={t}
              i18nKey="chat.call.incomingLabel"
              components={{ dots: <span className={styles.dots} /> }}
            />
          </span>
        </div>

        <div className={styles.actions}>
          <IconButton
            iconComponent={isAudioMute ? <VolumeOffIcon /> : <VolumeUpIcon />}
            iconSize={EIconSize.MD}
            onClick={toggleMute}
            classes={{ root: cls(styles.button, CANCEL_DRAGGABLE_SELECTOR, styles.buttonMute) }}
          />
          <IconButton
            iconComponent={<PhoneIcon />}
            iconSize={EIconSize.MD}
            onClick={onAnswer}
            classes={{ root: cls(styles.button, CANCEL_DRAGGABLE_SELECTOR, styles.buttonAnswer) }}
          />
          <IconButton
            iconComponent={<EndCallIcon />}
            iconSize={EIconSize.XL}
            onClick={onReject}
            classes={{ root: cls(styles.button, CANCEL_DRAGGABLE_SELECTOR, styles.buttonReject) }}
          />
        </div>
      </div>
    </Draggable>
  )
}
