import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsSecurityDefaultAccount } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsSecurityDefaultAccountContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => {
  const defaultAccountId = state.settings.securityAndLogin.defaultAccount.id
  const accountData = state.global.accountData
  const hasAccount = defaultAccountId === accountData?.id
  const {
    role,
    type,
    firstName = '',
    lastName = '',
    displayUserName = ''
  } = hasAccount
    ? accountData ?? {}
    : state.userProfile.relatedAccounts.list[defaultAccountId] ?? {}

  return {
    defaultAccountId,
    firstName,
    lastName,
    displayUserName,
    type: type?.name,
    role: role?.name
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalAction
    },
    dispatch
  )

export const SettingsSecurityDefaultAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityDefaultAccount)
