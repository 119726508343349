import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { HeadNotification } from 'App/components'

export type THeadNotificationContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  count: state.notifications.general.headerNotification
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const HeadNotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeadNotification)
