import { useState, ImgHTMLAttributes, ReactNode, useEffect } from 'react'
import cls from 'classnames'

import { ImagePlaceholder, TImagePlaceholderPops } from './ImagePlaceholder'
import styles from './Image.module.scss'

export type TImagePops = PropsWithClassName<
  Omit<ImgHTMLAttributes<HTMLImageElement>, 'placeholder' | 'onLoad' | 'onError' | 'src'> & {
    src: ImgHTMLAttributes<HTMLImageElement>['src'] | null

    placeholder?: ReactNode
    placeholderClasses?: TImagePlaceholderPops['classes']
    onLoad?: () => void
    onError?: () => void
  }
>

export const Image = ({
  hidden,
  src,
  alt,
  className,
  width,
  height,
  placeholderClasses,
  onLoad,
  onError,
  placeholder = <ImagePlaceholder classes={placeholderClasses} width={width} height={height} />,
  ...props
}: TImagePops) => {
  const [showImage, setShowImage] = useState<boolean>(false)

  const handleOnLoad = () => {
    onLoad && onLoad()
    setShowImage(true)
  }

  const handleOnError = () => {
    onError && onError()
    setShowImage(false)
  }

  useEffect(() => {
    if (hidden && !src && showImage) {
      setShowImage(false)
    }
  }, [hidden, showImage, src])

  return (
    <>
      {!hidden && src && (
        <img
          {...props}
          src={src}
          alt={alt}
          width={width}
          height={height}
          className={cls(className, { [styles.hidden]: !showImage })}
          onLoad={handleOnLoad}
          onError={handleOnError}
        />
      )}

      {!showImage ? placeholder : null}
    </>
  )
}
