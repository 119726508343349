import { Drawer as DrawerBase } from 'antd'
import cls from 'classnames'
import { ReactNode } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './Drawer.module.scss'

export type TDrawerProps = PropsWithClassName<{
  children: JSX.Element
  visible: boolean
  onClose: () => void

  headerActions?: JSX.Element
  footerActions?: JSX.Element
  title?: ReactNode
}>

export const Drawer = ({
  children,
  title,
  visible,
  headerActions,
  footerActions,
  className,
  onClose
}: TDrawerProps) => (
  <DrawerBase
    className={cls(styles.root, className)}
    title={title}
    placement="right"
    closable={true}
    closeIcon={<CrossIcon className={styles.icon} />}
    onClose={onClose}
    visible={visible}
    destroyOnClose={true}
    footer={footerActions}
    extra={headerActions}
  >
    {children}
  </DrawerBase>
)
