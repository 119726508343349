import { components, IndicatorsContainerProps, GroupBase } from 'react-select'

export const IndicatorsContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: IndicatorsContainerProps<Option, IsMulti, Group>
) => {
  const endAdornmentElement = props.selectProps.endAdornment

  return endAdornmentElement ? (
    <components.IndicatorsContainer {...props}>
      {endAdornmentElement}
    </components.IndicatorsContainer>
  ) : (
    <components.IndicatorsContainer {...props} />
  )
}
