import cls from 'classnames'
import { Skeleton } from 'antd'

import styles from './CaseCloudFilesItemSkeleton.module.scss'

export type TCaseCloudFilesItemSkeletonProps = PropsWithClassName

export const CaseCloudFilesItemSkeleton = ({ className }: TCaseCloudFilesItemSkeletonProps) => (
  <Skeleton
    active
    title={{ width: '100%', className: cls(styles.root, className) }}
    paragraph={false}
  />
)
