import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CallOwner } from 'App/components'
import { accountAvatarURLSelector } from 'store'

export type TCallOwnerContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  accountData: state.global.accountData,
  avatarURL: accountAvatarURLSelector(state),
  chatId: state.calls.init.chatId,
  isAnsweredCall: state.calls.init.isAnsweredCall,
  isVideoCall: state.calls.init.isVideoCall,
  isMicOn: state.calls.init.isMicOn,
  selectedChatId: state.chat.chatRooms.selectedChat?.id ?? null,
  startTime: state.calls.answer.createCall?.startTime ?? null,
  callId: state.calls.answer.createCall?.id ?? null,
  jwt: state.calls.answer.createCall?.jwt ?? null
})

export const CallOwnerContainer = connect(mapStateToProps)(CallOwner)
