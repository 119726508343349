import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { CaseConvertWrapper } from 'App/components'

export type TCaseConvertContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State) => ({
  id: state.caseView.data?.id
})

export const CaseConvertContainer = connect(mapStateToProps)(CaseConvertWrapper)
