import { Pagination } from 'App/components'
import {
  Pagination as TPagination,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SHOW_BY
} from 'types'

import styles from './FilePagination.module.scss'

type TFilePagination = {
  onPaginationChange: ({ current, showBy, total }: TPagination) => void
} & TPagination

export const FilePagination = ({ current, total, showBy, onPaginationChange }: TFilePagination) => {
  const paginationChangeHandler = (page: number, pageSize: number | undefined) => {
    onPaginationChange({
      current: page,
      showBy: pageSize || PAGINATION_DEFAULT_SHOW_BY,
      total
    })
  }

  const handleShowSizeChange = (pageSize: number) => {
    paginationChangeHandler(PAGINATION_DEFAULT_PAGE, pageSize)
  }

  return (
    <Pagination
      current={current}
      total={total}
      showTitle={false}
      pageSize={showBy}
      onChange={paginationChangeHandler}
      wrapperClassName={styles.wrapper}
      onShowSizeChange={handleShowSizeChange}
    />
  )
}
