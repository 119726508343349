import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention'

import { ECardSize, EExtendableCardType, showExtendableCardAction, useAppDispatch } from 'store'
import { DELETED_FILE_MENTION, TMention } from 'utils/mentions'

export type TFileMentionProps = Pick<SubMentionComponentProps, 'className' | 'decoratedText'> & {
  mention: TMention
}

export const FileMention = ({ decoratedText, mention, className }: TFileMentionProps) => {
  const isFileMention = mention.variant === 'file'

  const dispatch = useAppDispatch()

  const handleClick = () => {
    if (!isFileMention || decoratedText.trim() === DELETED_FILE_MENTION || !mention.clickable) {
      return
    }

    dispatch(
      showExtendableCardAction({
        slides: [mention],
        initialSlideId: mention.id,
        size: ECardSize.FULLSCREEN,
        type: EExtendableCardType.SHOWCASE_GALLERY,
        options: {
          showcaseOptions: mention.showcaseOptions,
          isThumbnails: false
        }
      })
    )
  }

  return (
    <span className={className} onClick={handleClick}>
      {decoratedText}
    </span>
  )
}
