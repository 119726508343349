import { useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers'
import { EBusinessAdminConfirmFrom, EBusinessAdminDeleteAction } from 'enums'
import {
  createLoadingSelector,
  createProcessingSelector,
  deleteBusinessAdminInviteRequest,
  GET_ORGANIZATION_ADMINISTRATIONS,
  REMOVE_BUSINESS_ADMIN_INVITE,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { InvitationItemSent } from 'App/components'

export type TOrganizationInvitationsAdministrationItemProps = {
  id: string
}

const loadingSelector = createLoadingSelector([GET_ORGANIZATION_ADMINISTRATIONS])
const processingSelector = createProcessingSelector([REMOVE_BUSINESS_ADMIN_INVITE])

export const OrganizationInvitationsAdministrationItem = ({
  id
}: TOrganizationInvitationsAdministrationItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { account, role, status, createdAt } =
    useAppSelector((state) => state.organizations.invitations.administrations.list[id], isEqual) ??
    {}
  const loading = useAppSelector(loadingSelector)
  const removeProcessing = useAppSelector(processingSelector)

  const handleRemove = useCallback(() => {
    dispatch(
      deleteBusinessAdminInviteRequest({
        id,
        from: EBusinessAdminConfirmFrom.SENT,
        onlyHidden: true,
        direction: BusinessAccountDirectionEnum.OUTGOING,
        processingId: id
      })
    )
  }, [dispatch, id])

  const handleWithdraw = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.businessAdminInvitationConfirm.title'),
        modalType: EModalComponents.CONFIRM_BUSINESS_ADMIN_INVITE,
        modalProps: {
          id,
          type: EBusinessAdminDeleteAction.WITHDRAW,
          from: EBusinessAdminConfirmFrom.SENT,
          direction: BusinessAccountDirectionEnum.OUTGOING
        }
      })
    )
  }, [id, dispatch, t])

  return (
    <InvitationItemSent
      variant="external"
      status={status}
      statusLabel={t(`enum.businessAccountStatusEnum.${status}`)}
      loading={loading}
      removeProcessing={removeProcessing}
      withdrawProcessing={removeProcessing}
      email={account.email}
      createdAt={createdAt}
      createdAccount={account}
      role={role.name}
      onRemove={handleRemove}
      onWithdraw={handleWithdraw}
    />
  )
}
