import {
  getCaseDescriptionRequest,
  addCaseDescriptionRequest,
  getCaseSummaryRequest,
  addCaseSummaryRequest,
  updateCaseSummaryRequest,
  getCaseOpinionsMembersRequest,
  getCaseOpinionsByMemberRequest,
  getCaseOpinionsRequest,
  addCaseOpinionRequest,
  TError,
  TIds
} from 'store'
import { ECasesDocumentKey } from 'enums'
import {
  ICaseDescriptionEntity,
  ICaseSummaryEntity,
  ICaseOpinionsMembersDTO,
  ICaseOpinionEntity
} from 'interfaces/api/caseDetailsDTO'

import * as actionCreators from './caseDetails.actions'

export type TCaseDescriptionList = Record<string, ICaseDescriptionEntity>

export type TCaseDescription = {
  newLineDescriptionId: null | string
  ids: TIds
  list: TCaseDescriptionList
  count: number
  draft: string
  error?: TError
}

export type TCaseSummary = {
  summary?: ICaseSummaryEntity
  count: number
  error?: TError
}

export type TCaseOpinionsList = Record<string, ICaseOpinionEntity>

export type TCaseOpinionsMembersList = Record<string, ICaseOpinionsMembersDTO>

export type TCaseOpinions = {
  newLineOpinionId: null | string
  openOpinionMemberId: null | string
  opinionsIds: TIds
  opinionsMembersIds: TIds
  opinionsList: TCaseOpinionsList
  opinionsMembersList: TCaseOpinionsMembersList
  count: number
  draft: string
  error?: TError
}

export type TCaseDetailsState = {
  description: TCaseDescription
  summary: TCaseSummary
  opinions: TCaseOpinions
}

export type TGetCaseDescriptionRequestPayload = {
  caseId: string
}

export type TGetCaseDescriptionSuccessPayload = {
  caseDescriptionCount: number
  caseDescriptionList: TCaseDescriptionList
  ids: TIds
}

export type TAddCaseDescriptionRequestPayload = {
  caseId: string
  description: string
}

export type TAddCaseDescriptionSuccessPayload = {
  caseDescriptionCount: number
  caseDescriptionList: TCaseDescriptionList
  ids: TIds
}

export type TGetCaseSummaryRequestPayload = {
  caseId: string
}

export type TGetCaseSummarySuccessPayload = {
  summary: ICaseSummaryEntity
  count: number
} | null

export type TAddCaseSummaryRequestPayload = {
  caseId: string
  summary: string
}

export type TAddCaseSummarySuccessPayload = {
  summary?: ICaseSummaryEntity
  count?: number
}

export type TUpdateCaseSummaryRequestPayload = {
  caseId: string
  summary: string
}

export type TUpdateCaseSummarySuccessPayload = {
  summary?: ICaseSummaryEntity
  count?: number
}

export type TGetCaseOpinionsMembersRequestPayload = {
  caseId: string
}

export type TGetCaseOpinionsMembersSuccessPayload = {
  caseOpinionsMembersList: TCaseOpinionsMembersList
  caseOpinionsMembersIds: TIds
}

export type TGetCaseOpinionsByMemberRequestPayload = {
  caseId: string
  memberId: string
}

export type TGetCaseOpinionsByMemberSuccessPayload = {
  caseOpinionsCount: number
  caseOpinionsList: TCaseOpinionsList
  caseOpinionsIds: TIds
}

export type TGetCaseOpinionsRequestPayload = {
  caseId: string
}

export type TGetCaseOpinionsSuccessPayload = {
  caseOpinionsCount: number
  caseOpinionsList: TCaseOpinionsList
  caseOpinionsIds: TIds
}

export type TCaseOpinionList = Record<string, ICaseOpinionEntity>

export type TAddCaseOpinionRequestPayload = {
  caseId: string
  opinion: string
}

export type TAddCaseOpinionSuccessPayload = {
  caseOpinionsCount: number
  caseOpinionsList: TCaseOpinionList
  caseOpinionsIds: TIds
}

export type TSetNewLineDescriptionActionPayload = {
  newLineDescriptionId: string | null
}

export type TSetNewLineOpinionActionPayload = {
  newLineOpinionId: string | null
}

export type TSetNewOpinionActionPayload = {
  opinion: ICaseOpinionEntity
}

export type TSetOpenedOpinionActionPayload = {
  memberId: null | string
}

export type TUpdateCaseDraftRequestPayload = {
  message: string
  type: ECasesDocumentKey
  caseId: string
}

export type TUpdateCaseDraftSuccessPayload = Partial<{
  [key in keyof Pick<TCaseDetailsState, 'description' | 'opinions'>]: string
}>

export type TGetCaseDraftRequestPayload = {
  type: ECasesDocumentKey
  caseId: string
}

export type TGetCaseDraftSuccessPayload = Partial<{
  [key in keyof Pick<TCaseDetailsState, 'description' | 'opinions'>]: string
}>

export enum ECaseDraftMap {
  DESCRIPTION = 'description',
  OPINION = 'opinions'
}

export type TGetCaseDescriptionRequest = ReturnType<typeof getCaseDescriptionRequest>
export type TAddCaseDescriptionRequest = ReturnType<typeof addCaseDescriptionRequest>
export type TGetCaseSummaryRequest = ReturnType<typeof getCaseSummaryRequest>
export type TAddCaseSummaryRequest = ReturnType<typeof addCaseSummaryRequest>
export type TUpdateCaseSummaryRequest = ReturnType<typeof updateCaseSummaryRequest>
export type TGetCaseOpinionsMembersRequest = ReturnType<typeof getCaseOpinionsMembersRequest>
export type TGetCaseOpinionsByMemberRequest = ReturnType<typeof getCaseOpinionsByMemberRequest>
export type TGetCaseOpinionsRequest = ReturnType<typeof getCaseOpinionsRequest>
export type TAddCaseOpinionRequest = ReturnType<typeof addCaseOpinionRequest>

export type TCaseDetailsAction = ReturnType<TInferValueTypes<typeof actionCreators>>
