import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { sendCreateCallRequest } from 'store/calls'
import { ChatStartGroupCallDialog } from 'App/components/Chat/ChatStartGroupCallDialog'
import { useAppDispatch } from 'store'

type TChatStartGroupCallDialogContainerProps = {
  chatId: string
  caseId: string
  video: boolean
}

export const ChatStartGroupCallDialogContainer = ({
  caseId,
  chatId,
  video
}: TChatStartGroupCallDialogContainerProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleSubmit = useCallback(
    (userIds: string[]) =>
      dispatch(
        sendCreateCallRequest({
          chatId,
          caseId,
          video,
          userIds
        })
      ),
    [caseId, chatId, dispatch, video]
  )

  return (
    <ChatStartGroupCallDialog
      caseId={caseId}
      submitLabel={t('modal.addGroupCallMember.submitButton')}
      emptyContentText={t('modal.addGroupCallMember.selectedListPlaceholder')}
      onSubmit={handleSubmit}
    />
  )
}
