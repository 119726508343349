import { FC, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Steps, StepsFooterActions } from 'App/components'
import {
  createProcessingSelector,
  ADD_WORKING_EXPERIENCE,
  TCity,
  TCountry,
  addWorkingExperienceRequest,
  updateWorkingExperienceRequest,
  UPDATE_WORKING_EXPERIENCE,
  createProcessingBySelector,
  useAppSelector
} from 'store'
import { serverRangeDate, formatDate } from 'utils/formats'
import { IWorkExperienceDTO } from 'interfaces'
import { useSteps } from 'App/hooks'

import {
  UserProfileWorkExperienceDialogFirstStep,
  UserProfileWorkExperienceDialogSecondStep
} from './components'

const addingProcessingSelector = createProcessingSelector([ADD_WORKING_EXPERIENCE])
const updatingProcessingSelector = createProcessingBySelector([UPDATE_WORKING_EXPERIENCE])

const DEFAULT_STEPS = [{}, {}]
enum STEPS {
  FIRST,
  SECOND
}

export type TValues = {
  position: string
  organization: string
  startDate?: Date
  endDate?: Date
  isCurrent: boolean
  description: string | null
  country: TCountry | null
  city: TCity | null
}

const initialFormValues: TValues = {
  position: '',
  organization: '',
  isCurrent: false,
  description: '',
  country: null,
  city: null
}

export type TUserProfileWorkExperienceDialogProps = {
  data?: IWorkExperienceDTO
}

export const UserProfileWorkExperienceDialog: FC<TUserProfileWorkExperienceDialogProps> = ({
  data
}) => {
  const dispatch = useDispatch()

  const { step, setNextStep, setPrevStep } = useSteps(STEPS.FIRST)

  const adding = useAppSelector(addingProcessingSelector)
  const updating = useAppSelector((state) => data && updatingProcessingSelector(data.id)(state))

  const { t } = useTranslation()

  const initialValues = useMemo<TValues>(() => {
    if (data) {
      return {
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined
      }
    }

    return initialFormValues
  }, [data])

  const submitButtonText = data
    ? t('modal.userWorkplaces.submitButton_save')
    : t('modal.userWorkplaces.submitButton_add')

  const onSubmit = ({
    position,
    organization,
    isCurrent,
    startDate,
    endDate,
    description,
    country,
    city
  }: TValues) => {
    const normalizedData = {
      position,
      organization,
      isCurrent,
      countryCode: country?.code,
      cityId: city?.id ? String(city?.id) : undefined,
      description: description || null,
      startDate: formatDate(startDate || new Date(), serverRangeDate),
      endDate: isCurrent || !endDate ? undefined : formatDate(endDate, serverRangeDate)
    }

    dispatch(
      data
        ? updateWorkingExperienceRequest({ ...normalizedData, id: data.id, processingId: data.id })
        : addWorkingExperienceRequest(normalizedData)
    )
  }

  return (
    <Row gutter={[0, 29]}>
      <Col xs={24}>
        <Steps current={step} stepsArray={DEFAULT_STEPS} />
      </Col>
      <Col xs={24}>
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <Row gutter={[0, 24]}>
              {step === STEPS.FIRST && <UserProfileWorkExperienceDialogFirstStep />}
              {step === STEPS.SECOND && <UserProfileWorkExperienceDialogSecondStep />}
              <Col xs={24}>
                <Row>
                  <StepsFooterActions
                    onSubmit={step === STEPS.SECOND ? handleSubmit : setNextStep}
                    submitLoading={data ? updating : adding}
                    submitDisabled={form.getState().invalid}
                    onClickBack={step === STEPS.FIRST ? undefined : setPrevStep}
                    submitLabel={
                      step === STEPS.SECOND
                        ? submitButtonText
                        : t('modal.userWorkplaces.submitButton_next')
                    }
                  />
                </Row>
              </Col>
            </Row>
          )}
        </Form>
      </Col>
    </Row>
  )
}
