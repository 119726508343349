import { connect } from 'react-redux'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import {
  ChatDefaultMessageCaseGroup,
  ChatDefaultMessageDialog,
  ChatDefaultMessageGroup,
  ChatDefaultMessageOrganization,
  EmptyList,
  TChatDefaultMessageWrapperClasses
} from 'App/components'
import { getMapComponent } from 'utils'

export enum EChatDefaultMessageSize {
  MD = 'MD',
  LG = 'LG'
}

export type TChatDefaultMessageDialogOwnProps = {
  size?: EChatDefaultMessageSize
}

export type TChatDefaultMessageContainerProps = TChatDefaultMessageDialogOwnProps &
  ReturnType<typeof mapStateToProps> &
  TChatDefaultMessageWrapperClasses

const CHAT_DEFAULT_MESSAGE = new Map<
  ChatTypeEnum,
  (props: TChatDefaultMessageContainerProps) => JSX.Element
>()
  .set(
    ChatTypeEnum.DIALOGUE,
    ({ chatType, createdAt, interlocutorAccount, size, classes, contactCreationLocation }) => (
      <ChatDefaultMessageDialog
        chatType={chatType}
        createdAt={createdAt}
        interlocutorAccount={interlocutorAccount}
        size={size}
        classes={classes}
        contactCreationLocation={contactCreationLocation}
      />
    )
  )
  .set(ChatTypeEnum.CASE_GROUP, ({ chatType, createdAt, caseTitle, size }) => (
    <ChatDefaultMessageCaseGroup
      chatType={chatType}
      createdAt={createdAt}
      caseTitle={caseTitle}
      size={size}
    />
  ))
  .set(ChatTypeEnum.ORGANIZATION, ({ chatType, createdAt, chatName, size }) => (
    <ChatDefaultMessageOrganization
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
    />
  ))
  .set(ChatTypeEnum.COMMUNITY_CHANNEL, ({ chatType, createdAt, chatName, size }) => (
    <ChatDefaultMessageOrganization
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
    />
  ))
  .set(ChatTypeEnum.COMMUNITY_NEWS, ({ chatType, createdAt, chatName, size }) => (
    <ChatDefaultMessageOrganization
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
    />
  ))
  .set(ChatTypeEnum.EVENT_CHANNEL, ({ chatType, createdAt, chatName, size }) => (
    <ChatDefaultMessageOrganization
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
    />
  ))
  .set(ChatTypeEnum.EVENT_NEWS, ({ chatType, createdAt, chatName, size }) => (
    <ChatDefaultMessageOrganization
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
    />
  ))
  .set(ChatTypeEnum.GROUP, ({ chatType, createdAt, chatName, size, classes, chatId }) => (
    <ChatDefaultMessageGroup
      chatId={chatId}
      chatType={chatType}
      createdAt={createdAt}
      chatName={chatName}
      size={size}
      classes={classes}
    />
  ))

const ChatDefaultMessageContainerView = (props: TChatDefaultMessageContainerProps) => {
  const { t } = useTranslation()

  if (Boolean(props.clearedUnixTs)) {
    return <EmptyList text={t('chat.placeholder')} />
  }

  return getMapComponent(CHAT_DEFAULT_MESSAGE, props.selectedChatType, props)
}

const mapStateToProps = (state: State) => {
  const { chatType, selectedChat } = state.chat.chatRooms
  const {
    case: caseData,
    createdAt,
    interlocutorAccount,
    clearedUnixTs,
    type,
    name,
    contactCreationLocation,
    id
  } = selectedChat || {}

  return {
    createdAt,
    interlocutorAccount,
    clearedUnixTs,
    selectedChatType: type,
    chatType,
    chatId: id,
    chatName: name,
    caseTitle: caseData?.title,
    contactCreationLocation
  }
}

export const ChatDefaultMessageContainer = connect(mapStateToProps)(ChatDefaultMessageContainerView)
