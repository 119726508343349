import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatMessageAudio } from 'App/components'
import { getChatSenderName } from 'utils'
import { isMessageOutgoingSelector } from 'store/chatMessages'

export type TChatMessageAudioContainerBaseProps = {
  id: string
  chatId: string
  showUserName?: boolean
}

export type TChatMessageAudioContainerProps = TChatMessageAudioContainerBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (
  state: State,
  { id, showUserName }: TChatMessageAudioContainerBaseProps
) => {
  const data = state.chat.chatMessages.messages.list[id] ?? {}
  const quoteSender = data?.quoteMessage?.sender
  const senderName = getChatSenderName({
    senderName: data?.sender?.displayUserName,
    showUserName,
    yourMessage: data?.yourMessage
  })

  return {
    quoteMessage: data.quoteMessage,
    yourMessage: data.yourMessage,
    audioFileId: data.audioFileId,
    durationSec: data.audioFile?.durationSec,
    quoteSender,
    senderName,
    isMessageOutgoing: isMessageOutgoingSelector(data.yourMessage)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const ChatMessageAudioContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageAudio)
