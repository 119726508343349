import { FileExtensionCategoryEnum } from '@medentee/enums'

import { QueryBuilder } from 'utils/QueryBuilder'

export class CloudFilesQueryBuilder extends QueryBuilder {
  public constructor(url: string) {
    super(url)
  }

  public caseId(caseId?: string) {
    if (caseId) {
      this.url += `${this.separator}caseId=${caseId}`
    }

    return this
  }

  public fileOwnerId(id?: string) {
    if (id) {
      this.url += `${this.separator}ownerId=${id}`
    }

    return this
  }

  public chatId(id?: string) {
    if (id) {
      this.url += `${this.separator}chatId=${id}`
    }

    return this
  }

  public permissionUserIdScope(id?: string) {
    if (id) {
      this.url += `${this.separator}permissionUserIdScope=${id}`
    }

    return this
  }

  public permissionSpecificationScope(scope?: 'cfp' | 'p2p') {
    if (scope) {
      this.url += `${this.separator}permissionSpecificationScope=${scope}`
    }

    return this
  }

  public extensionCategories(categories: FileExtensionCategoryEnum[] = []) {
    const isAllSelected = Object.values(FileExtensionCategoryEnum).every((value) =>
      categories.includes(value)
    )

    return this.multiSearch('extensionCategories', isAllSelected ? [] : categories)
  }

  public maxFileSize(value?: number) {
    if (value) {
      this.url += `${this.separator}maxFileSize=${value}`
    }

    return this
  }
}
