import { PropsWithChildren } from 'react'
import cls from 'classnames'

import styles from './CaseDetailsPlaceholder.module.scss'

export type TCaseDetailsPlaceholderProps = PropsWithChildren<PropsWithClassName>

export const CaseDetailsPlaceholder = ({ children, className }: TCaseDetailsPlaceholderProps) => (
  <p className={cls(styles.root, className)}>{children}</p>
)
