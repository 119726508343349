import Row, { Gutter } from 'antd/lib/grid/row'

import { getMapComponent } from 'utils'
import { TFilesViewVariant } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './FileAttachWrapper.module.scss'

export type TFileAttachWrapperData = { id: string; upload?: boolean }

type TFileAttachWrapperProps = {
  children: (data: TFileAttachWrapperData) => JSX.Element
  data: TFileAttachWrapperData[]
  loading: boolean

  view?: TFilesViewVariant
}

const WRAPPER = new Map<
  TFilesViewVariant,
  (props: Omit<TFileAttachWrapperProps, 'view' | 'loading'> & { gutter: Gutter }) => JSX.Element
>()
  .set('grid', ({ children, data, gutter }) => (
    <Row justify="start" gutter={[0, gutter]} className={styles.grid}>
      {data.map((item) => children(item))}
    </Row>
  ))
  .set('list', ({ data, children }) => <>{data.map(children)}</>)

export const FileAttachWrapper = ({ data, children, view = 'list' }: TFileAttachWrapperProps) => {
  const { isMobile } = useAdaptiveLayout()

  return getMapComponent(WRAPPER, view, { data, children, view, gutter: isMobile ? 0 : 16 })
}
