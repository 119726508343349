import { useState, ChangeEvent, useMemo, useEffect, forwardRef, Ref, useCallback } from 'react'
import cls from 'classnames'
import Input, { InputProps, InputRef } from 'antd/lib/input'
import debounce from 'lodash/debounce'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { Spinner, Tooltip } from 'App/components'
import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg'

import styles from './Search.module.scss'

export type TSearchProps = Omit<InputProps, 'onChange' | 'onPressEnter'> & {
  searchSize: EInputSize

  classes?: Partial<Record<TSearchClasses, string>>
  debounced?: boolean
  clearTrigger?: unknown
  loading?: boolean
  onChange?: (value: string) => void
  onPressEnter?: (value: string) => void
  onClear?: () => void
}

export type TSearchClasses = 'root' | 'clearIcon' | 'suffix'

const ICON_SIZE = 13
const SPINNER_SIZE = 20

const SearchView = (
  {
    searchSize,
    value,
    classes,
    clearTrigger,
    loading,
    onChange,
    onPressEnter,
    onClear,
    debounced = true,
    ...rest
  }: TSearchProps,
  ref: Ref<InputRef>
) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')

  const { pathname } = useLocation()

  const trigger = clearTrigger !== undefined ? clearTrigger : pathname
  const { placeholder = t('common.field.search.placeholder') } = rest

  const debouncedOnSearch = useMemo(
    () => onChange && debounce(onChange, DEFAULT_THROTTLE_MS),
    [onChange]
  )

  useEffect(() => {
    setSearch('')
    debouncedOnSearch?.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  useEffect(() => () => debouncedOnSearch?.cancel(), [debouncedOnSearch])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: currentValue } = event.target

    setSearch(currentValue)

    if (onChange) {
      debounced && debouncedOnSearch && !value
        ? debouncedOnSearch(currentValue)
        : onChange(currentValue)
    }
  }

  const handlePressEnter = () => {
    if (onPressEnter) {
      onPressEnter(search)
    }
  }

  const handleClear = useCallback(() => {
    setSearch('')

    if (onChange) {
      onChange('')
    }

    if (onClear) {
      onClear()
    }
  }, [onChange, onClear])

  const suffix = useMemo(() => {
    if (loading) {
      return (
        <Spinner
          height={SPINNER_SIZE}
          width={SPINNER_SIZE}
          className={cls(styles.suffix, classes?.suffix)}
        />
      )
    }

    if (search) {
      return (
        <Tooltip title={t('common.field.search.clearIcon')}>
          <CrossIcon
            className={cls(styles.clearIcon, classes?.clearIcon)}
            onClick={handleClear}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </Tooltip>
      )
    }

    return <SearchIcon className={styles.suffix} />
  }, [classes?.clearIcon, classes?.suffix, handleClear, loading, search, t])

  return (
    <Input
      {...rest}
      ref={ref}
      className={cls(styles.root, searchSize, classes?.root)}
      value={value ?? search}
      onChange={handleChange}
      onPressEnter={handlePressEnter}
      suffix={suffix}
      allowClear={false}
      placeholder={placeholder}
    />
  )
}

export const Search = forwardRef<InputRef, TSearchProps>(SearchView)
