import { useCallback } from 'react'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { CloudWidget, TCloudWidget } from 'App/containers'
import { getMedCloudCapacityRequest, useAppDispatch, useAppSelector } from 'store'
import { useNotificationTracker } from 'App/hooks'

type THeaderMedCloudProps = Partial<Pick<TCloudWidget, 'variant'>>

const TRACKED_NOTIFICATIONS = new Set([ProducedNotificationsEnum.SUBSCRIBING])

export const HeaderMedCloud = ({ variant }: THeaderMedCloudProps) => {
  const dispatch = useAppDispatch()
  const { medCloudCapacityAvailable = 0, medCloudCapacityOccupied = 0 } = useAppSelector(
    (state) => state.subscriptions.capacity || {}
  )

  const handleGetMedCloudCapacityRequest = useCallback(() => {
    dispatch(getMedCloudCapacityRequest())
  }, [dispatch])

  useNotificationTracker({
    channel: 'capacity',
    onOccurrence: handleGetMedCloudCapacityRequest,
    notifications: TRACKED_NOTIFICATIONS
  })

  return (
    <CloudWidget
      occupied={medCloudCapacityOccupied}
      available={medCloudCapacityAvailable}
      variant={variant}
      title="medcloud"
      linkTo="/files"
    />
  )
}
