import { useTranslation } from 'react-i18next'

import { EmptyList, EEmptyListIconSize } from 'App/components'
import { ReactComponent as AccountMultipleIcon } from 'assets/icons/AccountMultiple.svg'

export const CaseProfessionalMembersListEmpty = () => {
  const { t } = useTranslation()

  return (
    <EmptyList
      text={t('cases.details.membersSection.placeholder.ARCHIVED')}
      icon={<AccountMultipleIcon />}
      iconSize={EEmptyListIconSize.XS}
    />
  )
}
