import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  createProcessingBySelector,
  GET_ORGANIZATION_NEW_USERS,
  REMOVE_NEW_USERS_INVITE,
  removeNewUsersInviteRequest,
  useAppDispatch,
  useAppSelector,
  WITHDRAW_NEW_USERS_INVITE,
  withdrawNewUsersInviteRequest
} from 'store'
import { InvitationItemSent } from 'App/components'

type TOrganizationInvitationsNewUsersItemProps = {
  id: string
}

const loadingSelector = createLoadingSelector([GET_ORGANIZATION_NEW_USERS])

export const OrganizationInvitationsNewUsersItem = ({
  id
}: TOrganizationInvitationsNewUsersItemProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { createdAt, email, createdAccount, status, token } =
    useAppSelector((state) => state.organizations.invitations.newUsers.list[id]) ?? {}
  const loading = useAppSelector(loadingSelector)
  const removeProcessing = useAppSelector(
    createProcessingBySelector([WITHDRAW_NEW_USERS_INVITE])(token)
  )
  const withdrawProcessing = useAppSelector(
    createProcessingBySelector([REMOVE_NEW_USERS_INVITE])(token)
  )

  const handleRemove = () =>
    dispatch(removeNewUsersInviteRequest({ source: 'organizations', token, processingId: token }))

  const handleWithdraw = () => {
    dispatch(withdrawNewUsersInviteRequest({ source: 'organizations', token, processingId: token }))
  }

  return (
    <InvitationItemSent
      variant="external"
      status={status}
      statusLabel={t(`enum.inviteStatusEnum.${status}`)}
      loading={loading}
      removeProcessing={removeProcessing}
      withdrawProcessing={withdrawProcessing}
      email={email}
      createdAt={createdAt}
      createdAccount={createdAccount}
      invitationEmail={email}
      onRemove={handleRemove}
      onWithdraw={handleWithdraw}
    />
  )
}
