import { useEffect } from 'react'
import { Skeleton } from 'antd'
import { AccountTypeIdEnum, SubscriptionIdsEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { AvailableSubscriptionContainer } from 'App/containers'
import { TBusinessAccountCreateDialogProps } from 'App/components'
import { generateRandomArray } from 'utils'

import { getInitialBonuses } from '../../../../../api/settings'

import styles from './Subscription.module.scss'

type TSubscriptionProps = Pick<
  TBusinessAccountCreateDialogProps,
  'getAvailableSubscriptions' | 'ids' | 'loadingAvailableSubscriptions'
> & {
  handleChooseSubscription: (id: string) => void
}

const DEFAULT_SUBSCRIPTIONS_LENGTH = 1

export const Subscription = ({
  loadingAvailableSubscriptions,
  ids,
  getAvailableSubscriptions,
  handleChooseSubscription
}: TSubscriptionProps) => {
  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: ['initial-balance'],
    queryFn: getInitialBonuses
  })

  useEffect(() => {
    getAvailableSubscriptions({ accountTypeId: AccountTypeIdEnum.BUSINESS })
  }, [getAvailableSubscriptions])

  const subscriptionsIds = loadingAvailableSubscriptions
    ? generateRandomArray(DEFAULT_SUBSCRIPTIONS_LENGTH)
    : ids

  return (
    <>
      {isFetching ? (
        <Skeleton active title={false} paragraph={{ rows: 2 }} />
      ) : (
        <div className={styles.description}>
          <Trans
            t={t}
            i18nKey="modal.createBusinessAccount.subscriptionStep.description"
            values={{ bonus: data?.businessInitialBonus }}
            components={{
              span: <span className={styles.price}>{data?.businessInitialBonus} MDT</span>,
              br: <br />
            }}
          />
        </div>
      )}
      <div className={styles.list}>
        {subscriptionsIds
          .filter((id) => id !== SubscriptionIdsEnum.BASIC_BUSINESS)
          .map((id) => (
            <div className={styles.item} key={id}>
              <AvailableSubscriptionContainer
                id={id}
                active={true}
                showIcon={false}
                handleChooseSubscription={handleChooseSubscription}
                actionLabel={t('modal.createBusinessAccount.subscriptionStep.submitButton')}
              />
            </div>
          ))}
      </div>
    </>
  )
}
