import { FC, useEffect } from 'react'
import { Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Field, FieldRenderProps } from 'react-final-form'

import { DynamicList, Select } from 'App/components'
import { validation } from 'utils'
import {
  createLoadingSelector,
  getAllProfessionsRequest,
  GET_ALL_PROFESSIONS,
  TProfessionDTO
} from 'store'
import { State } from 'redux/rootReducer'

const SPECIALIZATION_MAX_LENGTH = 3
const loadingAllProfessionsSelector = createLoadingSelector([GET_ALL_PROFESSIONS])

export const CreateAdvertSecondStep: FC = () => {
  const dispatch = useDispatch()
  const professions = useSelector((state: State) => [
    ...state.misc.allProfessions.professional,
    ...state.misc.allProfessions.business
  ])
  const loadingAllProfessions = useSelector(loadingAllProfessionsSelector)

  useEffect(() => {
    dispatch(getAllProfessionsRequest())
  }, [dispatch])

  return (
    <Field name="profession">
      {({ input: professionInput }: FieldRenderProps<TProfessionDTO[]>) => (
        <>
          <Col xs={24}>
            <Select
              topLabel="Looking for"
              labelKey="name"
              valueKey="id"
              isLoading={loadingAllProfessions}
              options={professions}
              placeholder="Select professional field"
              {...professionInput}
            />
          </Col>
          <Col xs={24}>
            <Field
              name="specialization"
              validate={validation.composeValidators(
                validation.maxLength(SPECIALIZATION_MAX_LENGTH)
              )}
            >
              {({ input }: FieldRenderProps<string[] | null>) => (
                <DynamicList
                  title="Specialization"
                  valueLengthMax={30}
                  disabled={!professionInput.value}
                  tooltip={`You can add maximum ${SPECIALIZATION_MAX_LENGTH} specializations`}
                  maxLength={SPECIALIZATION_MAX_LENGTH}
                  {...input}
                />
              )}
            </Field>
          </Col>
        </>
      )}
    </Field>
  )
}
