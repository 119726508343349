import {
  SHOW_EXTENDABLE_CARD,
  HIDE_EXTENDABLE_CARD,
  EXPAND_EXTENDABLE_CARD,
  COLLAPSE_EXTENDABLE_CARD,
  MOVE_EXTENDABLE_CARD,
  TExtendableCardAction,
  TExtendableCardState
} from 'store'

import { ECardPosition, ECardSize } from './extendableCard.types'

export const initialCardState: TExtendableCardState = {
  type: null,
  position: ECardPosition.LEFT,
  allowPosition: false,
  size: ECardSize.FULLSCREEN,
  allowSize: false,
  initialSlideId: null,
  slides: []
}

export const extendableCardReducer = (
  state: TExtendableCardState = initialCardState,
  action: TExtendableCardAction
): TExtendableCardState => {
  switch (action.type) {
    case SHOW_EXTENDABLE_CARD: {
      return {
        ...action.payload,
        position: action.payload.position ?? state.position,
        size: action.payload.size ?? state.size,
        initialSlideId: action.payload.initialSlideId,
        slides: action.payload.slides ?? []
      }
    }

    case HIDE_EXTENDABLE_CARD: {
      return {
        ...initialCardState
      }
    }

    case EXPAND_EXTENDABLE_CARD: {
      return {
        ...state,
        size: ECardSize.FULLSCREEN,
        initialSlideId: action.payload.initialSlideId
      }
    }

    case COLLAPSE_EXTENDABLE_CARD: {
      return {
        ...state,
        size: ECardSize.COLLAPSED,
        position: action.payload?.position || state.position
      }
    }

    case MOVE_EXTENDABLE_CARD: {
      return {
        ...state,
        position: action.payload.position
      }
    }

    default:
      return state
  }
}
