import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { CallStatusEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TChatMessageCallContainerProps } from 'App/containers'
import { ChatMessageSenderName } from 'App/components'
import { ReactComponent as VideoIcon } from 'assets/icons/Video.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'
import { ReactComponent as ArrowTopRightIcon } from 'assets/icons/ArrowTopRight.svg'
import { ReactComponent as ArrowBottomLeftIcon } from 'assets/icons/ArrowBottomLeft.svg'

import { useMemberCallStatus } from './hooks'
import styles from './ChatMessageCall.module.scss'

export type TChatMessageCallBaseProps = {
  id: string
  chatId: string
  isVideoCall?: boolean
  callTime?: number
  showUserName?: boolean
  footerRight?: ReactNode
}

export type TChatMessageCallProps = TChatMessageCallBaseProps & TChatMessageCallContainerProps

const DEFAULT_PADDING = 28

export const ChatMessageCall = ({
  privateAccountId,
  accountId,
  data,
  yourMessage,
  senderName,
  footerRight,
  isVideoCall = false
}: TChatMessageCallProps) => {
  const { t } = useTranslation()

  const footerRightRef = useRef<HTMLDivElement | null>(null)

  const [titlePaddingRight, setTitlePaddingRight] = useState<number>(DEFAULT_PADDING)

  const { status = CallStatusEnum.COMPLETED, startTime, endTime, answerersMap } = data?.call || {}
  const isCallFailed =
    status === CallStatusEnum.BUSY ||
    (status === CallStatusEnum.MISSED && (yourMessage || !!endTime))

  const { status: memberCallStatus } =
    answerersMap?.find(
      (item) => item.privateAccountId === privateAccountId && item.accountId === accountId
    ) || {}

  const callStatus = useMemberCallStatus({
    callStatus: status,
    memberCallStatus,
    startTime,
    endTime,
    yourMessage
  })

  useLayoutEffect(() => {
    const element = footerRightRef.current

    if (footerRight && element?.clientWidth) {
      setTitlePaddingRight(DEFAULT_PADDING + element.clientWidth)
    }
  }, [footerRight])

  if (!data) {
    return null
  }

  return (
    <div className={cls(styles.root, yourMessage && styles.yourMessage)}>
      <div className={styles.container}>
        {isVideoCall ? (
          <VideoIcon className={styles.icon} />
        ) : (
          <PhoneIcon className={styles.icon} />
        )}

        <div className={styles.content}>
          <ChatMessageSenderName senderName={senderName} />
          <span className={styles.title} style={{ paddingRight: titlePaddingRight }}>
            {yourMessage
              ? t('chat.message.call.outgoingCall')
              : t('chat.message.call.incomingCall')}
          </span>
          <div
            className={cls(styles.statusWrapper, {
              [styles.accent]: isCallFailed
            })}
          >
            {yourMessage ? (
              <ArrowTopRightIcon className={cls(styles.callIcon, styles.yourMessage)} />
            ) : (
              <ArrowBottomLeftIcon className={styles.callIcon} />
            )}

            {endTime ? (
              <span>{callStatus}</span>
            ) : (
              <span className={styles.status}>{callStatus}</span>
            )}

            <div className={styles.footerRight} ref={footerRightRef}>
              {footerRight}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
