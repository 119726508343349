import { FC, ReactNode } from 'react'
import cls from 'classnames'

import styles from './FieldLabel.module.scss'

export type TFieldLabelProps = PropsWithClassName<{
  label: ReactNode
}>

export const FieldLabel: FC<TFieldLabelProps> = ({ label, className }) => (
  <div className={cls(styles.root, className)}>{label}</div>
)
