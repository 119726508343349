import { AccountTypeNames } from '@medentee/enums'
import { Field, Form, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { hideModalAction, showModalAction, useAppDispatch } from 'store'

import { EModalComponents } from '../../../containers'
import { Button, UploadAvatar, UploadCover } from '../../../components'
import { useUnsavedDataPrompt } from '../../../hooks/useUnsavedDataPrompt'

import styles from './UnityHubBranding.module.scss'

export type TBrandingFormValues = {
  avatar?: File | string | null
  cover?: File | string | null
}

type TSpyOnFormChangedProps = {
  prompt: JSX.Element
  onChange: (value: boolean) => void
}

type TUnityHubBrandingProps = {
  initialValues: TBrandingFormValues
  loading: boolean
  onUpload: (payload: FormData) => void

  displayUserName?: string
}

const COVER_INITIAL_CROP_WIDTH = 90
const COVER_ASPECT_RATIO = 2.2

const SpyOnFormChanged = ({ onChange, prompt }: TSpyOnFormChangedProps) => {
  const { pristine, modifiedSinceLastSubmit, submitSucceeded } = useFormState()

  useEffect(() => {
    onChange(submitSucceeded ? modifiedSinceLastSubmit : !pristine)
  }, [pristine, modifiedSinceLastSubmit, submitSucceeded, onChange])

  return prompt
}

export const UnityHubBranding = ({
  displayUserName,
  initialValues,
  loading,
  onUpload
}: TUnityHubBrandingProps) => {
  const dispatch = useAppDispatch()

  const { prompt, setFormChanged } = useUnsavedDataPrompt()

  const { t } = useTranslation()

  const handleMainImageUpload = (value: File, onChange: (file: File) => void) => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
        modalTitle: t('modal.uploadImage.title_branding'),
        modalProps: {
          imageSrc: URL.createObjectURL(value),
          onCrop: (newFile) => {
            dispatch(hideModalAction())
            onChange(newFile)
          }
        }
      })
    )
  }

  const handleCoverUpload = (value: File, onChange: (file: File) => void) => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.UPLOAD_IMAGE_DIALOG,
        modalTitle: t('modal.uploadImage.title_brandingCover'),
        modalProps: {
          imageSrc: URL.createObjectURL(value),
          initialCrop: {
            width: COVER_INITIAL_CROP_WIDTH,
            aspect: COVER_ASPECT_RATIO
          },
          circularCrop: false,
          allowBlank: true,
          content: t('modal.uploadImage.content_brandingCover'),
          onCrop: (newFile) => {
            dispatch(hideModalAction())
            onChange(newFile)
          }
        }
      })
    )
  }

  const handleRemoveAvatar = (onSuccess: () => void, onChange: (value: File | null) => void) =>
    dispatch(
      showModalAction({
        modalType: EModalComponents.DELETE_IMAGE_DIALOG,
        modalTitle: t('modal.removeImageConfirm.title_eventBranding'),
        modalProps: {
          onClick: () => {
            onSuccess()
            dispatch(hideModalAction())
            onChange(null)
          }
        }
      })
    )

  const onSubmit = ({ avatar, cover }: TBrandingFormValues) => {
    const formData = new FormData()

    if (avatar instanceof File || avatar === null) {
      formData.set('avatar', avatar || 'null')
    }
    if (cover instanceof File || cover === null) {
      formData.set('cover', cover || 'null')
    }

    onUpload(formData)
  }

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, valid, pristine, submitSucceeded, modifiedSinceLastSubmit }) => (
        <>
          <SpyOnFormChanged onChange={setFormChanged} prompt={prompt} />
          <div className={styles.wrapper}>
            <div className={styles.cover}>
              <Field name="cover">
                {({ input }) => (
                  <UploadCover
                    file={input.value}
                    onFileUpload={(value) => handleCoverUpload(value, input.onChange)}
                    onRemove={() => input.onChange(null)}
                  />
                )}
              </Field>
            </div>
            <Field name="avatar">
              {({ input }) => (
                <UploadAvatar
                  type={AccountTypeNames.BUSINESS}
                  displayUserName={displayUserName ?? ''}
                  showEditPreview={!displayUserName && !input.value}
                  classes={{ root: styles.avatar }}
                  file={input.value || null}
                  removeButtonLabel={t('unityHub.branding.removeMainImageButton')}
                  onFileUpload={(value) => handleMainImageUpload(value, input.onChange)}
                  onRemoveAvatar={(onSuccess) => handleRemoveAvatar(onSuccess, input.onChange)}
                />
              )}
            </Field>
          </div>
          <div className={styles.content}>
            <div className={styles.info}>
              <div className={styles.infoBlock}>
                <b>{t('unityHub.branding.avatarImageLabel')}</b>
                <span>250x250px</span>
              </div>
              <div className={styles.infoBlock}>
                <b>{t('unityHub.branding.coverImageLabel')}</b>
                <span>1196x543px</span>
              </div>
            </div>
            <Button
              onClick={handleSubmit}
              disabled={!valid || pristine || (submitSucceeded && !modifiedSinceLastSubmit)}
              loading={loading}
            >
              {t('unityHub.branding.submitButton')}
            </Button>
          </div>
        </>
      )}
    </Form>
  )
}
