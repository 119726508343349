import { ReactNode, useState } from 'react'
import { Row } from 'antd'

import { TAttachMedcloudFilesList } from 'store'
import { InfiniteScroll } from 'App/components/common'

import { FileViewToggle, TFilesViewVariant } from '../../FileTilesView'
import { FileAttachWrapper, TFileAttachWrapperData } from '../FileAttachWrapper'
import { FileAttachItem } from '../FileAttachItem'

import styles from './FileAttachLists.module.scss'

export type TFileAttachListsProps = {
  loading: boolean
  total: number

  data: TFileAttachWrapperData[]
  next: (nextPage: number) => void

  title?: ReactNode
  attachedFiles?: TAttachMedcloudFilesList
  page?: number
}

const LIST_CONFIG = {
  HEIGHT: 360,
  SCROLLABLE_TARGET_CLASS_NAME: 'file-attach-dialog-list'
}

export const FileAttachLists = ({
  total,
  loading,
  attachedFiles,
  data,
  next,
  title = 'MEDcloud',
  page = 0
}: TFileAttachListsProps) => {
  const [filesViewVariant, setFilesViewVariant] = useState<TFilesViewVariant>('list')

  return (
    <>
      <Row className={styles.listHeader} justify="space-between" align="middle">
        <span className={styles.listTitle}>{title}</span>
        <FileViewToggle variant={filesViewVariant} onChange={setFilesViewVariant} />
      </Row>

      <div className={LIST_CONFIG.SCROLLABLE_TARGET_CLASS_NAME}>
        <InfiniteScroll
          nestIn="parent"
          scrollableTarget={LIST_CONFIG.SCROLLABLE_TARGET_CLASS_NAME}
          next={next}
          loading={loading}
          total={total}
          page={page}
          dataLength={data.length}
          height={LIST_CONFIG.HEIGHT}
        >
          <FileAttachWrapper view={filesViewVariant} data={data} loading={loading}>
            {({ id, upload }) => (
              <FileAttachItem
                view={filesViewVariant}
                key={id}
                id={id}
                loading={loading}
                upload={!!upload}
                isAttached={!!attachedFiles && id in attachedFiles}
              />
            )}
          </FileAttachWrapper>
        </InfiniteScroll>
      </div>
    </>
  )
}
