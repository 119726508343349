import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  AttachFileToCaseAdditionalActionEnum,
  CaseStatusesEnum,
  FileHistorySourcesEnum,
  FilePermissionsIdsEnum,
  ErrorCodesEnum
} from '@medentee/enums'

import { downloadFileFromUrl, isMatchErrorCode } from 'utils'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { PERMISSION_VIEW } from 'globalConstants'
import { ICaseMembersAccount, ICasesCloudFile } from 'interfaces'
import { State } from 'redux/rootReducer'
import {
  getMedCloudCapacityRequest,
  setVideoStreamingData,
  TIds,
  videoStreamingNormalize
} from 'store'
import { TCaseMembers } from 'store/caseMembers'
import { handleError, handleWarning } from 'api/utils'

import { hideModalAction } from '../modal'
import { caseViewRequest } from '../caseView'
import { ECaseAccountType, ECaseCloudTabKey, ESorting } from '../../enums'
import { CloudFilesQueryBuilder, QueryBuilder } from '../../utils/QueryBuilder'
import { setFileDetailsId } from '../files/myFiles'
import { startFakeUploadAction } from '../medCloud'

import { caseCloudFileNormalize } from './caseCloud.normalization'
import {
  TCaseCloudFilters,
  TCaseUploadFileRequest,
  TCopyCaseFileToMedcloudRequest,
  TCopyToCaseCloudRequest,
  TDeleteCaseFileRequest,
  TDiscardCaseFilePermissions,
  TDownloadCaseFileRequest,
  TGetCaseContactPermissionsRequest,
  TGetCaseFilesRequest,
  TGetCaseSharedWithMeFilesRequest,
  TLoseAccessToCaseFileRequest,
  TPermissionContactsList,
  TRenameCaseFile,
  TSetCaseCloudContactPermissionRequest
} from './caseCloud.types'
import {
  caseUploadFileSuccess,
  caseUploadFileError,
  CASE_UPLOAD_FILE_REQUEST,
  GET_CASE_FILES_REQUEST,
  getCaseFilesError,
  getCaseFilesSuccess,
  getCaseFilesRequest,
  copyCaseFileToMedcloudError,
  copyCaseFileToMedcloudSuccess,
  COPY_CASE_FILE_TO_MEDCLOUD_REQUEST,
  deleteCaseFileSuccess,
  deleteCaseFileError,
  DELETE_CASE_FILE_REQUEST,
  SET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST,
  RENAME_CASE_FILE_REQUEST,
  renameCaseFileError,
  renameCaseFileSuccess,
  setCaseCloudActiveFileId,
  discardCaseFilePermissionsSuccess,
  discardCaseFilePermissionsError,
  DISCARD_CASE_FILE_PERMISSIONS_REQUEST,
  GET_CASE_SHARED_WITH_ME_FILES_REQUEST,
  getCaseSharedWithMeFilesSuccess,
  getCaseSharedWithMeFilesError,
  DOWNLOAD_CASE_FILE_REQUEST,
  GET_CASE_UPLOADED_BY_ME_FILES_REQUEST,
  getCaseUploadedByMeFilesSuccess,
  getCaseUploadedByMeFilesError,
  getCaseUploadedByMeFilesRequest,
  getCaseSharedWithMeFilesRequest,
  GET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST,
  addCaseCloudContactPermission,
  removeCaseCloudContactPermission,
  copyToCaseCloudError,
  copyToCaseCloudSuccess,
  COPY_TO_CASE_CLOUD_REQUEST,
  setCaseCloudCurrentTabAction,
  LOSE_ACCESS_TO_CASE_FILE_REQUEST,
  loseAccessToCaseFileSuccess,
  setCaseCloudContactPermissionSuccess,
  setCaseCloudContactPermissionError,
  getCaseContactPermissionsSuccess,
  getCaseContactPermissionsError,
  downloadCaseFileSuccess,
  downloadCaseFileError
} from './caseCloud.actions'
import { getCaseContactsPermissionsSort } from './caseCloud.utils'

export type TCaseCaseCloudContactPermissionSagaProps = {
  caseId: string
  fileId: string
  permissionId: FilePermissionsIdsEnum
  accountId: string
}

export function* requestAllFiles(caseId: string) {
  const isMember: boolean = yield select(
    (state: State) => state.caseCloud.who === ECaseAccountType.MEMBER
  )
  const isCaseLocked: boolean = yield select(
    (state: State) => state.caseView.data?.status === CaseStatusesEnum.LOCKED
  )

  if (!(isCaseLocked && isMember)) {
    yield put(getCaseFilesRequest({ caseId }))
  }

  yield isMember
    ? put(getCaseUploadedByMeFilesRequest({ caseId }))
    : put(getCaseSharedWithMeFilesRequest({ caseId }))
}

function* caseUploadFileSaga({ payload: { fileIds, caseId, isModal } }: TCaseUploadFileRequest) {
  try {
    const url = new QueryBuilder(API.CASE_FILE(caseId))
      .custom('source', FileHistorySourcesEnum.CASE_CLOUD)
      .custom('originalEntityId', caseId)
      .build()

    yield call(api.post, url, {
      fileIds
    })
    yield put(caseUploadFileSuccess())
    yield requestAllFiles(caseId)

    if (isModal) {
      yield put(hideModalAction())
    }
  } catch (e) {
    console.error(e)
    yield put(caseUploadFileError(e))

    if (!isModal) {
      yield handleError(e)
    }
  }
}

function* deleteCaseCloudContactPermissionSaga({
  caseId,
  fileId,
  permissionId,
  accountId
}: TCaseCaseCloudContactPermissionSagaProps) {
  yield call(api.delete, API.DELETE_CASE_CLOUD_USER_PERMISSION(caseId), {
    data: {
      fileId,
      permissionId,
      accountId
    }
  })
}

function* addCaseCloudContactPermissionSaga({
  caseId,
  fileId,
  permissionId,
  accountId
}: TCaseCaseCloudContactPermissionSagaProps) {
  yield call(api.post, API.ADD_CASE_CLOUD_USER_PERMISSION_OWNED(caseId), {
    fileId,
    permissionId,
    accountId
  })
}

function* setCaseCloudContactPermissionSage({
  payload: { enabled, permissionId, fileId, userId, caseId }
}: TSetCaseCloudContactPermissionRequest) {
  if (!caseId) {
    return
  }

  try {
    if (enabled) {
      yield addCaseCloudContactPermissionSaga({
        permissionId,
        fileId,
        accountId: userId,
        caseId
      })
      yield put(
        addCaseCloudContactPermission({
          contactId: userId,
          permissionId
        })
      )
    } else {
      yield deleteCaseCloudContactPermissionSaga({
        permissionId,
        fileId,
        accountId: userId,
        caseId
      })
      yield put(
        removeCaseCloudContactPermission({
          contactId: userId,
          permissionId
        })
      )
    }
    yield put(setCaseCloudContactPermissionSuccess({ processingId: userId }))
  } catch (e) {
    console.error(e)
    yield handleError(e)
    yield put(setCaseCloudActiveFileId())
    yield put(setCaseCloudContactPermissionError({ ...e, processingId: userId }))
  } finally {
    yield requestAllFiles(caseId)
  }
}

function* getCaseFilesSaga({ payload: { caseId } }: TGetCaseFilesRequest) {
  try {
    const ownerId: string = yield select((state: State) => state.caseView.data?.owner.id)
    const memberId: string = yield select((state: State) => state.global.accountData?.id)
    const { extensionCategories }: TCaseCloudFilters = yield select(
      (state: State) => state.caseCloud.filters
    )

    const url =
      ownerId === memberId
        ? new CloudFilesQueryBuilder(API.CLOUD_FILES)
            .caseId(caseId)
            .fileOwnerId(ownerId)
            .extensionCategories(extensionCategories)
            .permissionSpecificationScope('cfp')
            .sortFiles(ESorting.DESC, 'createdAt')
            .build()
        : new CloudFilesQueryBuilder(API.CLOUD_FILES)
            .caseId(caseId)
            .permissionUserIdScope(memberId)
            .extensionCategories(extensionCategories)
            .permissionSpecificationScope('cfp')
            .sortFiles(ESorting.DESC, 'sharedAt')
            .build()

    const {
      data: { results, total }
    }: APIResultsResponse<ICasesCloudFile[]> = yield call(api.get, url)

    yield put(setVideoStreamingData(videoStreamingNormalize(results)))
    yield put(
      getCaseFilesSuccess({
        total,
        ...caseCloudFileNormalize(results)
      })
    )
  } catch (e) {
    console.error(e)
    yield put(getCaseFilesError(e))
    yield handleError(e)
  }
}

function* getCaseSharedWithMeFilesSaga({ payload: { caseId } }: TGetCaseSharedWithMeFilesRequest) {
  try {
    const ownerId: string = yield select((state: State) => state.caseView.data?.owner.id)
    const { extensionCategories }: TCaseCloudFilters = yield select(
      (state: State) => state.caseCloud.filters
    )
    const url = new CloudFilesQueryBuilder(API.CLOUD_FILES)
      .caseId(caseId)
      .permissionUserIdScope(ownerId)
      .permissionSpecificationScope('cfp')
      .extensionCategories(extensionCategories)
      .sortFiles(ESorting.DESC, 'sharedAt')
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<ICasesCloudFile[]> = yield call(api.get, url)

    yield put(setVideoStreamingData(videoStreamingNormalize(results)))
    yield put(
      getCaseSharedWithMeFilesSuccess({
        total,
        ...caseCloudFileNormalize(results)
      })
    )
  } catch (e) {
    console.error(e)
    yield put(getCaseSharedWithMeFilesError(e))
    yield handleError(e)
  }
}

function* getCaseUploadedByMeFilesSaga({ payload: { caseId } }: TGetCaseSharedWithMeFilesRequest) {
  try {
    const memberId: string = yield select((state: State) => state.global.accountData?.id)
    const { extensionCategories }: TCaseCloudFilters = yield select(
      (state: State) => state.caseCloud.filters
    )
    const url = new CloudFilesQueryBuilder(API.CLOUD_FILES)
      .caseId(caseId)
      .fileOwnerId(memberId)
      .permissionSpecificationScope('cfp')
      .extensionCategories(extensionCategories)
      .sortFiles(ESorting.DESC, 'createdAt')
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<ICasesCloudFile[]> = yield call(api.get, url)

    yield put(setVideoStreamingData(videoStreamingNormalize(results)))
    yield put(
      getCaseUploadedByMeFilesSuccess({
        total,
        ...caseCloudFileNormalize(results)
      })
    )
  } catch (e) {
    yield put(getCaseUploadedByMeFilesError(e))
    yield handleError(e)
  }
}

function* getCaseUploadedByMeContactsPermissions(fileId: string) {
  try {
    const accountId: string = yield select((state: State) => state.global.accountData?.id ?? '')
    const { casesPermissions = [] }: ICasesCloudFile = yield select(
      (state: State) => state.caseCloud.uploadedByMeFiles.list[fileId] ?? {}
    )
    const caseOwner: ICaseMembersAccount = yield select(
      (state: State) => state.caseView.data?.owner ?? {}
    )

    const permissions: Record<string, FilePermissionsIdsEnum[]> = {}
    const list: TPermissionContactsList = {}
    const ids: TIds = []

    if (caseOwner.id) {
      ids.push(caseOwner.id)
      list[caseOwner.id] = caseOwner
      permissions[caseOwner.id] = []
    }

    casesPermissions
      .filter(({ account }) => account.id !== accountId)
      .forEach(({ account }) => {
        if (!ids.includes(account.id)) {
          ids.push(account.id)
        }

        if (!list[account.id]?.id) {
          list[account.id] = account
        }
      })

    ids.forEach((id) => {
      permissions[id] = casesPermissions
        .filter(({ account }) => account.id === id)
        .map(({ permission }) => permission.id)
        .filter((permission) => PERMISSION_VIEW.includes(permission))
    })

    const activeIds = getCaseContactsPermissionsSort(ids, list, permissions)

    yield put(
      getCaseContactPermissionsSuccess({
        list,
        permissions,
        activeIds,
        inactiveIds: []
      })
    )
  } catch (e) {
    throw e
  }
}

function* getCaseContactsPermissions({ payload }: TGetCaseContactPermissionsRequest) {
  const { fileId, isMember } = payload

  try {
    if (isMember) {
      yield call(getCaseUploadedByMeContactsPermissions, fileId)
      return
    }

    const { casesPermissions = [] }: ICasesCloudFile = yield select(
      (state: State) => state.caseCloud.cloudFiles.list[fileId] ?? {}
    )
    const { active, inactive }: Record<'active' | 'inactive', TCaseMembers> = yield select(
      (state: State) => state.caseMembers.members
    )

    const permissions: Record<string, FilePermissionsIdsEnum[]> = {}
    const list: TPermissionContactsList = {}

    active.ids.forEach((id) => {
      list[id] = active.list[id].account
    })

    inactive.ids.forEach((id) => {
      list[id] = inactive.list[id].account
    })

    active.ids.concat(inactive.ids).forEach((id) => {
      const accountId = list[id]?.id

      if (accountId) {
        permissions[accountId] = casesPermissions
          .filter(({ account }) => account.id === accountId)
          .map(({ permission }) => permission.id)
          .filter((permission) => PERMISSION_VIEW.includes(permission))
      }
    })

    const activeIds = getCaseContactsPermissionsSort(active.ids, list, permissions)
    const inactiveIds = getCaseContactsPermissionsSort(inactive.ids, list, permissions)

    yield put(
      getCaseContactPermissionsSuccess({
        list,
        permissions,
        activeIds,
        inactiveIds
      })
    )
  } catch (e) {
    yield put(getCaseContactPermissionsError(e))
    handleError(e)
  }
}

function* copyCaseFileToMedCloudSaga({ payload }: TCopyCaseFileToMedcloudRequest) {
  const { caseId, fileId, caseType, fileName, fileType, source, originalEntityId } = payload

  try {
    const queryBuilder = new QueryBuilder(API.CASE_COPY_TO_MEDCLOUD(caseId))

    const url = queryBuilder
      .custom('source', source)
      .custom('originalEntityId', originalEntityId)
      .build()

    const onUpload = function* (name: string) {
      yield call(api.post, url, {
        fileName: name,
        fileId
      })

      yield put(copyCaseFileToMedcloudSuccess())
      yield requestAllFiles(caseId)
      yield put(getMedCloudCapacityRequest())
    }

    yield put(
      startFakeUploadAction({
        onUpload,
        fileId,
        fileName,
        fileType
      })
    )
  } catch (e) {
    yield put(copyCaseFileToMedcloudError(e))
    yield handleError(e)

    yield put(
      caseViewRequest({
        id: caseId,
        type: caseType
      })
    )
    yield requestAllFiles(caseId)
  }
}

function* deleteCaseFileSaga({ payload: { caseId, fileIds } }: TDeleteCaseFileRequest) {
  try {
    yield call(api.delete, API.CASE_FILE(caseId), {
      data: {
        fileIds
      }
    })

    yield put(deleteCaseFileSuccess())
    yield put(hideModalAction())
    yield requestAllFiles(caseId)
  } catch (e) {
    yield put(deleteCaseFileError(e))
    yield handleError(e)
  }
}

function* renameCaseFileSaga({ payload: { fileId, fileName, caseId } }: TRenameCaseFile) {
  try {
    yield call(api.patch, API.FILE_EDIT(fileId), {
      source: FileHistorySourcesEnum.CASE_CLOUD,
      fileName,
      originalEntityId: caseId
    })

    yield put(renameCaseFileSuccess())
    yield put(hideModalAction())
    yield requestAllFiles(caseId)
  } catch (e) {
    yield put(renameCaseFileError(e))
  }
}

function* discardCaseFilePermissionsSaga({
  payload: { caseId, fileIds }
}: TDiscardCaseFilePermissions) {
  try {
    const { id: accountId } = yield select((state: State) => state.global.accountData || {})

    yield call(api.delete, API.FILE_DISCARD_PERMISSIONS_CASE(caseId), {
      data: {
        permissionId: FilePermissionsIdsEnum.OPEN,
        fileIds,
        accountId
      }
    })

    yield requestAllFiles(caseId)
    yield put(hideModalAction())
    yield put(discardCaseFilePermissionsSuccess())
  } catch (e) {
    yield put(discardCaseFilePermissionsError(e))
  }
}

function* downloadCaseFileSaga({ payload: { caseId, fileId } }: TDownloadCaseFileRequest) {
  try {
    const downloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('source', FileHistorySourcesEnum.CASE_CLOUD)
      .custom('originalEntityId', caseId)
      .build()

    const checkDownloadUrl = new QueryBuilder(API.FILE_DOWNLOAD_URL(fileId))
      .custom('checkRightsOnly', 'true')
      .build()

    yield call(api.get, checkDownloadUrl)

    const { data }: APIData<string> = yield call(api.get, downloadUrl)

    yield downloadFileFromUrl({ content: data })
    yield put(downloadCaseFileSuccess())
  } catch (e) {
    yield put(setFileDetailsId({ recordId: null }))
    yield put(downloadCaseFileError(e))
    yield requestAllFiles(caseId)

    yield isMatchErrorCode(e, [
      ErrorCodesEnum.FILE_NOT_ENOUGH_ACCESS_RIGHTS,
      ErrorCodesEnum.FILE_NOT_FOUND
    ])
      ? handleWarning(e)
      : handleError(e)
  }
}

function* copyToCaseCloudSaga({
  payload: { caseId, fileId, fileName, fileType }
}: TCopyToCaseCloudRequest) {
  try {
    yield put(hideModalAction())

    const queryBuilder = new QueryBuilder(API.CASE_COPY_TO_MEDCLOUD(caseId))

    const url = queryBuilder.custom('source', FileHistorySourcesEnum.CASE_CLOUD).build()

    const onUpload = function* (name: string) {
      const { data }: APIData<{ id: string }> = yield call(api.post, url, {
        fileId,
        fileName: name
      })

      const uploadUrl = new QueryBuilder(API.CASE_FILE(caseId))
        .custom('additionalAction', AttachFileToCaseAdditionalActionEnum.COPY)
        .custom('source', FileHistorySourcesEnum.CASE_CLOUD)
        .custom('originalEntityId', caseId)
        .build()

      yield call(api.post, uploadUrl, {
        fileIds: [data.id]
      })

      yield put(setCaseCloudCurrentTabAction(ECaseCloudTabKey.FIRST))
    }

    yield put(
      startFakeUploadAction({
        onUpload,
        fileId,
        fileName,
        fileType
      })
    )

    yield put(copyToCaseCloudSuccess())
  } catch (e) {
    yield put(copyToCaseCloudError(e))
  }
}

function* loseAccessToCaseFileSaga({ payload: { caseId, fileId } }: TLoseAccessToCaseFileRequest) {
  try {
    const id: string = yield select((state: State) => state.caseView.data?.id)

    if (caseId === id) {
      yield put(loseAccessToCaseFileSuccess({ fileId }))
    }
  } catch (e) {
    yield handleError(e)
  }
}

export function* caseCloudSaga() {
  yield takeLatest(GET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST, getCaseContactsPermissions)

  yield takeLatest(CASE_UPLOAD_FILE_REQUEST, caseUploadFileSaga)
  yield takeLatest(GET_CASE_FILES_REQUEST, getCaseFilesSaga)
  yield takeLatest(COPY_CASE_FILE_TO_MEDCLOUD_REQUEST, copyCaseFileToMedCloudSaga)
  yield takeLatest(DELETE_CASE_FILE_REQUEST, deleteCaseFileSaga)
  yield takeEvery(SET_CASE_CLOUD_CONTACT_PERMISSION_REQUEST, setCaseCloudContactPermissionSage)
  yield takeLatest(RENAME_CASE_FILE_REQUEST, renameCaseFileSaga)
  yield takeLatest(DISCARD_CASE_FILE_PERMISSIONS_REQUEST, discardCaseFilePermissionsSaga)
  yield takeLatest(GET_CASE_SHARED_WITH_ME_FILES_REQUEST, getCaseSharedWithMeFilesSaga)
  yield takeLatest(GET_CASE_UPLOADED_BY_ME_FILES_REQUEST, getCaseUploadedByMeFilesSaga)
  yield takeLatest(DOWNLOAD_CASE_FILE_REQUEST, downloadCaseFileSaga)
  yield takeEvery(COPY_TO_CASE_CLOUD_REQUEST, copyToCaseCloudSaga)
  yield takeEvery(LOSE_ACCESS_TO_CASE_FILE_REQUEST, loseAccessToCaseFileSaga)
}
