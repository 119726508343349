import { FileExtensionCategoryEnum } from '@medentee/enums'

import { action, EPermissionSpecificationScope, TDiscardFilePermissionPayload, TError } from 'store'
import { Pagination, SortingTable } from 'types'

import {
  TChangeFileSharedContactPermissionErrorPayload,
  TChangeFileSharedContactPermissionRequestPayload,
  TChangeFileSharedContactPermissionSuccessPayload,
  TFileSharedContactIds,
  TFileSharedContactList,
  TFileSharedIds,
  TFileSharedList,
  TFilesSharedFilters,
  TGetFilesSharedCountsRequestPayload,
  TGetFilesSharedCountsSuccessPayload
} from './files.types'

export const GET_FILE_SHARED_LIST = 'GET_FILE_SHARED_LIST'
export const GET_FILE_SHARED_LIST_REQUEST = 'GET_FILE_SHARED_LIST_REQUEST'
export const GET_FILE_SHARED_LIST_SUCCESS = 'GET_FILE_SHARED_LIST_SUCCESS'
export const GET_FILE_SHARED_LIST_ERROR = 'GET_FILE_SHARED_LIST_ERROR'

export const CHANGE_FILE_SHARED_CONTACT_PERMISSION = 'CHANGE_FILE_SHARED_CONTACT_PERMISSION'
export const CHANGE_FILE_SHARED_CONTACT_PERMISSION_REQUEST =
  'CHANGE_FILE_SHARED_CONTACT_PERMISSION_REQUEST'
export const CHANGE_FILE_SHARED_CONTACT_PERMISSION_SUCCESS =
  'CHANGE_FILE_SHARED_CONTACT_PERMISSION_SUCCESS'
export const CHANGE_FILE_SHARED_CONTACT_PERMISSION_ERROR =
  'CHANGE_FILE_SHARED_CONTACT_PERMISSION_ERROR'

export const SET_FILE_SHARED_LIST = 'SET_FILE_SHARED_LIST'
export const SET_FILE_SHARED_LIST_IDS = 'SET_FILE_SHARED_LIST_IDS'
export const SET_FILE_SHARED_LIST_PAGINATION = 'SET_FILE_SHARED_LIST_PAGINATION'
export const SET_FILE_SHARED_LIST_SORTING = 'SET_FILE_SHARED_LIST_SORTING'

export const RESET_FILE_SHARED_LIST_FILTERS = 'RESET_FILE_SHARED_LIST_FILTERS'
export const SET_FILE_SHARED_LIST_FILTERS_REQUEST = 'SET_FILE_SHARED_LIST_FILTERS_REQUEST'
export const SET_FILE_SHARED_LIST_FILTERS_SUCCESS = 'SET_FILE_SHARED_LIST_FILTERS_SUCCESS'

export const SET_FILE_SHARED_DETAILS_ID = 'SET_FILE_SHARED_DETAILS_ID'
export const SET_FILE_SHARED_CONTACT_LIST = 'SET_FILE_SHARED_CONTACT_LIST'

export const SET_FILE_SHARED_CONTACT_IDS = 'SET_FILE_SHARED_CONTACT_IDS'

export const DOWNLOAD_FILE_SHARED_REQUEST = 'DOWNLOAD_FILE_SHARED_REQUEST'

export const RENAME_FILE_SHARED = 'RENAME_FILE_SHARED'
export const RENAME_FILE_SHARED_REQUEST = 'RENAME_FILE_SHARED_REQUEST'
export const RENAME_FILE_SHARED_SUCCESS = 'RENAME_FILE_SHARED_SUCCESS'
export const RENAME_FILE_SHARED_ERROR = 'RENAME_FILE_SHARED_ERROR'

export const DISCARD_FILE_SHARED_PERMISSION = 'DISCARD_FILE_SHARED_PERMISSION'
export const DISCARD_FILE_SHARED_PERMISSION_REQUEST = 'DISCARD_FILE_SHARED_PERMISSION_REQUEST'
export const DISCARD_FILE_SHARED_PERMISSION_SUCCESS = 'DISCARD_FILE_SHARED_PERMISSION_SUCCESS'
export const DISCARD_FILE_SHARED_PERMISSION_ERROR = 'DISCARD_FILE_SHARED_PERMISSION_ERROR'

export const RESET_FILE_SHARED_LIST_ACTION = 'RESET_FILE_SHARED_LIST_ACTION'

/** Type for Fetching File Shared list */
export type TGetFileSharedListRequestPayload = {
  permissionSpecificationScope?: EPermissionSpecificationScope
  caseId?: string
  permissionUserIdScope?: string
  filters?: TFilesSharedFilters
  /** File pagination */
  pagination?: Pagination
  sorting?: SortingTable
  ownerId?: string
  chatId?: string
  extensionCategories?: FileExtensionCategoryEnum[]
  loadMore?: boolean
}

export const getFileSharedListRequest = (payload: TGetFileSharedListRequestPayload) =>
  action(GET_FILE_SHARED_LIST_REQUEST, payload)
export const getFileSharedListSuccess = () => action(GET_FILE_SHARED_LIST_SUCCESS)
export const getFileSharedListError = () => action(GET_FILE_SHARED_LIST_ERROR)

export const changeFileSharedContactPermissionRequest = (
  payload: TChangeFileSharedContactPermissionRequestPayload
) => action(CHANGE_FILE_SHARED_CONTACT_PERMISSION_REQUEST, payload)

export const changeFileSharedContactPermissionSuccess = (
  payload: TChangeFileSharedContactPermissionSuccessPayload
) => action(CHANGE_FILE_SHARED_CONTACT_PERMISSION_SUCCESS, payload)

export const changeFileSharedContactPermissionError = (
  payload: TChangeFileSharedContactPermissionErrorPayload
) => action(CHANGE_FILE_SHARED_CONTACT_PERMISSION_ERROR, payload)

export type TSetFileSharedListPayload = {
  fileList: TFileSharedList
}
/** Set new fileList */
export const setFileSharedList = (payload: TSetFileSharedListPayload) =>
  action(SET_FILE_SHARED_LIST, payload)

export type TSetFileSharedIdsPayload = {
  ids: TFileSharedIds
}

/** Set new file ids */
export const setFileSharedIds = (payload: TSetFileSharedIdsPayload) =>
  action(SET_FILE_SHARED_LIST_IDS, payload)

export type TSetFileSharedPaginationPayload = {
  pagination: Pagination
}

export const setFileSharedPagination = (payload: TSetFileSharedPaginationPayload) =>
  action(SET_FILE_SHARED_LIST_PAGINATION, payload)

export type TSetFileSharedSortingPayload = {
  sorting?: SortingTable
}

export const setFileSharedSorting = (payload: TSetFileSharedSortingPayload) =>
  action(SET_FILE_SHARED_LIST_SORTING, payload)

export const setFileSharedFiltersRequest = (payload: TFilesSharedFilters) =>
  action(SET_FILE_SHARED_LIST_FILTERS_REQUEST, payload)
export const setFileSharedFiltersSuccess = (payload: TFilesSharedFilters) =>
  action(SET_FILE_SHARED_LIST_FILTERS_SUCCESS, payload)
export const resetFileSharedFilters = () => action(RESET_FILE_SHARED_LIST_FILTERS)

export type TFileSharedDetailsIdPayload = {
  fileSharedDetailsId: string | null
}

export const setFileSharedDetailsId = (payload: TFileSharedDetailsIdPayload) =>
  action(SET_FILE_SHARED_DETAILS_ID, payload)

export type TSetFileSharedContactListPayload = {
  fileSharedContactList: TFileSharedContactList
  fileSharedContactIds: TFileSharedContactIds
}

export const setFileSharedContactList = (payload: TSetFileSharedContactListPayload) =>
  action(SET_FILE_SHARED_CONTACT_LIST, payload)

export type TSetFileSharedContactIdsPayload = {
  fileSharedContactIds: TFileSharedContactIds
}

export const setFileSharedContactIds = (payload: TSetFileSharedContactIdsPayload) =>
  action(SET_FILE_SHARED_CONTACT_IDS, payload)

export type TRenameFileSharedPayload = {
  fileName: string
  fileId: string
}

export const renameFileSharedRequest = (payload: TRenameFileSharedPayload) =>
  action(RENAME_FILE_SHARED_REQUEST, payload)
export const renameFileSharedSuccess = () => action(RENAME_FILE_SHARED_SUCCESS)
export const renameFileSharedError = (payload: TError) => action(RENAME_FILE_SHARED_ERROR, payload)

export const discardFileSharedPermissionRequest = (payload: TDiscardFilePermissionPayload) =>
  action(DISCARD_FILE_SHARED_PERMISSION_REQUEST, payload)
export const discardFileSharedPermissionSuccess = () =>
  action(DISCARD_FILE_SHARED_PERMISSION_SUCCESS)
export const discardFileSharedPermissionError = (payload: TError) =>
  action(DISCARD_FILE_SHARED_PERMISSION_ERROR, payload)

export const resetFileSharedListAction = () => action(RESET_FILE_SHARED_LIST_ACTION)

// ------------------ GET_FILES_SHARED_COUNTS ----------------

export const GET_FILES_SHARED_COUNTS = 'GET_FILES_SHARED_COUNTS'
export const GET_FILES_SHARED_COUNTS_REQUEST = 'GET_FILES_SHARED_COUNTS_REQUEST'
export const GET_FILES_SHARED_COUNTS_SUCCESS = 'GET_FILES_SHARED_COUNTS_SUCCESS'
export const GET_FILES_SHARED_COUNTS_ERROR = 'GET_FILES_SHARED_COUNTS_ERROR'

export const getFilesSharedCountsRequest = (payload: TGetFilesSharedCountsRequestPayload) =>
  action(GET_FILES_SHARED_COUNTS_REQUEST, payload)
export const getFilesSharedCountsSuccess = (payload: TGetFilesSharedCountsSuccessPayload) =>
  action(GET_FILES_SHARED_COUNTS_SUCCESS, payload)
export const getFilesSharedCountsError = (payload: TError) =>
  action(GET_FILES_SHARED_COUNTS_ERROR, payload)

// ------------------ RESET_FILES_SHARED_FOR_CATEGORY ----------------

export const RESET_FILES_SHARED_FOR_CATEGORY = 'RESET_FILES_SHARED_FOR_CATEGORY'

export const resetFilesSharedForCategory = () => action(RESET_FILES_SHARED_FOR_CATEGORY)
