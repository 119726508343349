import { actionCreator, TReceiveOnlinePayload } from 'store'

import {
  TReceiveOfflinePayload,
  TReceiveAdminOfflinePayload,
  TReceiveAdminOnlinePayload,
  TSetOnlineActionPayload,
  TSetAdminOnlineActionPayload
} from './online.types'
import * as actionTypes from './online.actionTypes'

// ------------------ ONLINE ------------------
export const setOnlineAction = (payload: TSetOnlineActionPayload) =>
  actionCreator(actionTypes.SET_ONLINE, payload)
export const receiveOnline = (payload: TReceiveOnlinePayload) =>
  actionCreator(actionTypes.RECEIVE_ONLINE, payload)
export const receiveOffline = (payload: TReceiveOfflinePayload) =>
  actionCreator(actionTypes.RECEIVE_OFFLINE, payload)

// ------------------ ADMIN ------------------
export const setAdminOnlineAction = (payload: TSetAdminOnlineActionPayload) =>
  actionCreator(actionTypes.SET_ADMIN_ONLINE, payload)
export const receiveAdminOnline = (payload: TReceiveAdminOnlinePayload) =>
  actionCreator(actionTypes.RECEIVE_ADMIN_ONLINE, payload)
export const receiveAdminOffline = (payload: TReceiveAdminOfflinePayload) =>
  actionCreator(actionTypes.RECEIVE_ADMIN_OFFLINE, payload)
