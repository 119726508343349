import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ButtonMessage } from 'App/components'
import { isChatRoomMutedSelector } from 'store'

export type TButtonMessageContainerOwnProps = {
  chatId?: string

  unreadCount?: number
  disabled?: boolean
  unreadAll2AllChat?: boolean
}

export type TButtonMessageContainerProps = TButtonMessageContainerOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, ownProps: TButtonMessageContainerOwnProps) => {
  const item = state.notifications.general.chats.list[ownProps.chatId || ''] || {}

  return {
    unreadCount: item?.unreadCount ?? 0,
    isChatRoomMuted: isChatRoomMutedSelector(ownProps.chatId)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const ButtonMessageContainer = connect(mapStateToProps, mapDispatchToProps)(ButtonMessage)
