import { memo, useCallback, useEffect, useState } from 'react'
import cls from 'classnames'
import uniqBy from 'lodash/uniqBy'
import { useTranslation } from 'react-i18next'

import { InfiniteData, QueryKey, useQuery, useQueryClient } from 'services/query'
import { IShowcaseComment } from 'interfaces'
import eventBus from 'services/eventBus'
import { EAvatarSize } from 'enums'
import { useDialog } from 'App/hooks'
import { AccountsList, Button } from 'App/components'
import { TAccountsItem } from 'App/components/common/AccountsList/AccountsListPopover/AccountsListPopover'
import { TCommentFieldEvent } from 'App/containers/Showcase'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { getCommentById } from 'api/showcase'

import { RepliesContainer, TRepliesContainerProps } from '../RepliesContainer'
import { TMenuItemsProps, useKebabMenu } from '../../useKebabMenu'

import styles from './RepliesWrapper.module.scss'

export type TRepliesWrapperProps = {
  commentsQueryKey: QueryKey
  showcaseId: string
  commentId: string
  setFieldEvent: (event: TCommentFieldEvent) => void
  isShowcaseOwner: boolean
  repliesCount: string

  isAllTab?: boolean
}

const RepliesWrapperView = ({
  showcaseId,
  commentId,
  commentsQueryKey,
  isShowcaseOwner,
  repliesCount,
  setFieldEvent,
  isAllTab = false
}: TRepliesWrapperProps) => {
  const { open, toggle, setOpen } = useDialog()

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const [accounts, setAccounts] = useState<TAccountsItem[]>([])
  const [commentEvent, setCommentEvent] = useState<TRepliesContainerProps['commentEvent']>()

  const { refetch: getComment, data: commentData } = useQuery({
    queryKey: ['showcase-comment-by-id', showcaseId, commentId],
    queryFn: () => getCommentById({ showcaseId, id: commentId }),
    cacheTime: 0,
    enabled: false,
    onSuccess: ({ repliesAccounts }) => {
      setAccounts(repliesAccounts)
    }
  })

  const handleCloseEvent = useCallback(() => {
    setFieldEvent(undefined)
  }, [setFieldEvent])

  const handleDeleteReplyClose = useCallback(() => {
    setCommentEvent(undefined)
  }, [])

  const handleDelete = useCallback(
    (id: string) => {
      setCommentEvent({
        type: 'delete',
        commentId: id,
        confirmationText: t('showcases.item.comments.deleteConfirm.content_reply'),
        close: handleDeleteReplyClose
      })
    },
    [handleDeleteReplyClose, t]
  )

  const handleReply = useCallback(
    ({ id, author }: TMenuItemsProps) => {
      setFieldEvent({
        type: 'reply',
        commentId: id,
        author,
        close: handleCloseEvent
      })
    },
    [handleCloseEvent, setFieldEvent]
  )

  const handleRepliesToggle = useCallback(() => {
    toggle()

    if (open) {
      getComment()
    }
  }, [toggle, open, getComment])

  const { getMenuItems } = useKebabMenu({
    handleReply,
    handleDelete,
    isAllTab,
    isShowcaseOwner,
    isReplyItem: true
  })

  useEffect(() => {
    const data = queryClient.getQueryData<InfiniteData<IShowcaseComment[]>>(commentsQueryKey)

    data?.pages.forEach((page) => {
      const foundIndex = page.findIndex((comment) => comment.id === commentId)

      if (foundIndex !== -1) {
        const comment = page[foundIndex]
        setAccounts(comment.repliesAccounts)
      }
    })
  }, [commentId, commentsQueryKey, queryClient])

  useEffect(() => {
    const unsubscribe = eventBus.showcaseReplySuccess.subscribe((eventData) => {
      if (eventData.commentId === commentId && eventData.repliesAccounts.length) {
        setAccounts((prev) => uniqBy([...prev, ...eventData.repliesAccounts], 'id'))
        setOpen(true)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [commentId, setOpen])

  return (
    <>
      {Boolean(accounts.length) && (
        <div className={cls(open && styles.active)}>
          {open && (
            <RepliesContainer
              getComment={getComment}
              showcaseId={showcaseId}
              commentId={commentId}
              getMenuItems={getMenuItems}
              commentEvent={commentEvent}
            />
          )}

          <div className={styles.row}>
            {!open && (
              <AccountsList
                accounts={accounts}
                avatarSize={EAvatarSize.XS}
                getTitle={(number) =>
                  t(`showcases.item.comments.replies.selectedMembersPopover.title`, {
                    number
                  })
                }
              />
            )}

            <Button
              variant="text"
              iconPosition="right"
              icon={<ChevronDownIcon className={styles.icon} />}
              className={styles.button}
              onClick={handleRepliesToggle}
            >
              {open ? 'Hide replies' : `Show ${commentData?.repliesCount ?? repliesCount} replies`}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export const RepliesWrapper = memo(RepliesWrapperView)
