import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'
// import { useTranslation } from 'react-i18next'

import {
  RelatedAccountsListItemContainer,
  TRelatedAccountsListContainerProps
} from 'App/containers'
import { generateRandomArray } from 'utils'
// import { Button } from 'App/components'
import { TIds } from 'store'
// import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

// import { Placeholder } from './Placeholder'
import styles from './RelatedAccountsList.module.scss'

type TRelatedAccountsListProps = TRelatedAccountsListContainerProps & {
  loading: boolean
  ids: TIds
  hasBusinessAccountsLimit: boolean
}

const DEFAULT_RELATED_ACCOUNTS_LENGTH = 1

export const RelatedAccountsList = ({
  loading,
  ids,
  hasBusinessAccountsLimit,
  type
}: // onCreateBusinessAccount
TRelatedAccountsListProps) => {
  // const { t } = useTranslation()

  const relatedAccounts = loading ? generateRandomArray(DEFAULT_RELATED_ACCOUNTS_LENGTH) : ids

  const showBusinessButton =
    type === AccountTypeNames.PROFESSIONAL && !hasBusinessAccountsLimit && !loading

  if (!relatedAccounts.length) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={cls(styles.wrapper, showBusinessButton && styles.wrapperEndAdornment)}>
        {relatedAccounts.map((id) => (
          <RelatedAccountsListItemContainer id={id} key={id} />
        ))}
      </div>

      {/* Commented due to https://edento.atlassian.net/browse/MED-8417 */}
      {/* {showBusinessButton ? (
        <Button
          icon={<PlusIcon className={styles.buttonIcon} />}
          size="sm"
          className={styles.button}
          onClick={onCreateBusinessAccount}
        >
          {t('relatedAccounts.createBusinessAccountButton')}
        </Button>
      ) : (
        <Placeholder type={type} />
      )} */}
    </div>
  )
}
