import { useMemo } from 'react'
import cls from 'classnames'

import { CheckableList } from '../../CheckableList'
import { BadgeList, TBadge, TBadgeListProps } from '../BadgeList'

import styles from './ChipList.module.scss'

const processItem = (item: TChipListItem): [string, TChipListItem][] => {
  const itemValue: [string, TChipListItem] = [item.id, item]

  if (item.items) {
    return item.items.reduce<[string, TChipListItem][]>(
      (res, child) => [...res, [child.id, { ...child, name: `${child.name}(${item.name})` }]],
      [itemValue]
    )
  }

  return [itemValue]
}

export type TChipListItem = { name: string; id: string; category?: string; items?: TChipListItem[] }
export type TChipListProps = {
  title: string
  loading?: boolean
  className?: string
  items?: TChipListItem[]
  selected?: TChipListItem[]
  popoverContentClass?: string
  categoryClasses?: Record<string, string>
  onChange?: (newItems: TChipListItem[]) => void
} & Pick<
  TBadgeListProps<TBadge>,
  'editable' | 'onVisibleChange' | 'placement' | 'align' | 'removable'
>

export const ChipList = ({
  selected,
  onChange,
  className,
  items,
  title,
  editable,
  removable,
  loading,
  onVisibleChange,
  popoverContentClass,
  placement,
  align,
  categoryClasses = {}
}: TChipListProps) => {
  const itemsMap = useMemo(
    () =>
      new Map(
        items?.reduce<[string, TChipListItem][]>(
          (res, item): [string, TChipListItem][] => [...res, ...processItem(item)],
          []
        ) ?? []
      ),
    [items]
  )
  const list = useMemo<TBadge[]>(() => {
    const selectedItems: TBadge[] = []
    const selectedMap = new Map(selected?.map((item) => [item.id, item]) ?? [])
    itemsMap.forEach((itemMap) => {
      if (itemMap.items && itemMap.items.every((item) => selectedMap.has(item.id))) {
        itemMap.items.forEach((item) => selectedMap.delete(item.id))
        selectedItems.push({
          id: itemMap.id,
          label: itemMap.name,
          className: itemMap.category ? categoryClasses[itemMap.category] : undefined
        })
      }
    })

    selectedMap.forEach(({ id, name, category }) => {
      selectedItems.push({
        id,
        label: itemsMap.get(id)?.name ?? name,
        className: category ? categoryClasses[category] : undefined
      })
    })

    return selectedItems
  }, [categoryClasses, itemsMap, selected])

  const checkableListValues = useMemo(() => selected?.map(({ id }) => id) ?? [], [selected])

  const hasRemovableItems = (list?.length ?? 0) > 1

  const handleRemove =
    hasRemovableItems && onChange
      ? (id: string) => {
          const nestedItems = itemsMap.get(id)?.items
          const newValue = selected?.filter((item) => {
            if (nestedItems) {
              return !nestedItems.find((nestedItem) => nestedItem.id === item.id)
            }
            return item.id !== id
          })

          onChange(newValue || [])
        }
      : undefined

  const handleCheckableListChange = (newValue: string[]) => {
    if (onChange) {
      onChange(newValue.map((id) => itemsMap.get(id) as TChipListItem))
    }
  }

  const popoverContent = (
    <div className={cls(styles.popoverContent, popoverContentClass)}>
      <span className={styles.title}>{title}</span>

      <CheckableList
        valueKey="id"
        labelKey="name"
        loading={loading}
        items={items || []}
        value={checkableListValues}
        onChange={handleCheckableListChange}
      />
    </div>
  )

  return selected ? (
    <BadgeList
      list={list}
      onRemove={handleRemove}
      className={cls(className)}
      popoverContent={popoverContent}
      editable={editable}
      removable={removable}
      placement={placement}
      align={align}
      onVisibleChange={onVisibleChange}
    />
  ) : null
}
