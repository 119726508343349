import { useMemo } from 'react'
import { GetCountryQueryModeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  GET_ALL_PROFESSIONS,
  useAppDispatch,
  useAppSelector,
  createLoadingSelector,
  getAllProfessionsRequest,
  getCountriesRequest,
  GET_COUNTRIES,
  TCountry
} from 'store'
import { EFilterTypes, TFilterItem } from 'App/components/common/Filters'

export type TCommunityMembersSelectedFilters = Partial<{
  general: ('online' | 'onlyContacts')[]
  locations: TCountry[]
  professions: string[]
}>

export const useCommunityMembersFilters = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const loadingProfessions = useAppSelector(createLoadingSelector([GET_ALL_PROFESSIONS]))
  const loadingCountries = useAppSelector(createLoadingSelector([GET_COUNTRIES]))
  const { allProfessions, countries } = useAppSelector((state) => state.misc)

  const filters = useMemo<TFilterItem<keyof TCommunityMembersSelectedFilters>[]>(
    () => [
      {
        sectionKey: 'general',
        items: [
          {
            filterKey: 'general',
            valueKey: 'key',
            labelKey: 'label',
            type: EFilterTypes.CHECKBOX,
            options: [
              {
                key: 'online',
                label: t('communities.members.filters.online')
              },
              {
                key: 'onlyContacts',
                label: t('communities.members.filters.contacts')
              }
            ]
          }
        ]
      },
      {
        title: t('communities.members.filters.profession'),
        sectionKey: 'professions',
        items: [
          {
            filterKey: 'professions',
            valueKey: 'id',
            labelKey: 'name',
            type: EFilterTypes.CHECKBOX,
            options: allProfessions.professional,
            loading: loadingProfessions,
            onMount: () => dispatch(getAllProfessionsRequest())
          }
        ]
      },
      {
        title: t('communities.members.filters.basedIn'),
        sectionKey: 'locations',
        items: [
          {
            filterKey: 'locations',
            valueKey: 'code',
            labelKey: 'countryName',
            type: EFilterTypes.SELECT,
            options: countries,
            loading: loadingCountries,
            placeholder: t('communities.members.filters.basedInPlaceholder'),
            isMulti: true,
            onMount: () => dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.ALL }))
          }
        ]
      }
    ],
    [allProfessions.professional, countries, dispatch, loadingCountries, loadingProfessions, t]
  )

  return { filters }
}
