import { put, select, takeLatest } from 'redux-saga/effects'
import uniq from 'lodash/uniq'

import { State } from 'redux/rootReducer'
import {
  RECEIVE_OFFLINE,
  RECEIVE_ONLINE,
  RECEIVE_ADMIN_OFFLINE,
  RECEIVE_ADMIN_ONLINE,
  TOnlineNormalized,
  TOnlineAdmin,
  TOnlineUser
} from 'store'
import { getISOStringNow } from 'utils'

import {
  receiveOffline,
  receiveOnline,
  receiveAdminOffline,
  receiveAdminOnline,
  setOnlineAction,
  setAdminOnlineAction
} from './online.actions'

function* receiveOnlineSaga({
  payload: { privateUserId, socketId, userId }
}: ReturnType<typeof receiveOnline>) {
  const online: TOnlineNormalized = yield select((state: State) => state.online)
  const item: TOnlineUser = {
    privateUserId,
    id: userId,
    socketId,
    online: true,
    lastSeen: getISOStringNow()
  }
  const ids = uniq([...online.ids, userId])
  const list = { ...online.list, [userId]: item }

  yield put(setOnlineAction({ ids, list }))
}

function* receiveOfflineSaga({ payload: { userId } }: ReturnType<typeof receiveOffline>) {
  const online: TOnlineNormalized = yield select((state: State) => state.online)
  const item: TOnlineUser = {
    ...online.list[userId],
    online: false,
    lastSeen: getISOStringNow()
  }
  const list = { ...online.list, [userId]: item }

  yield put(setOnlineAction({ list, ids: online.ids }))
}

function* receiveAdminOnlineSaga({
  payload: { privateUserId, socketId, userId }
}: ReturnType<typeof receiveAdminOnline>) {
  const admin: TOnlineAdmin = yield select((state: State) => state.online.admin)
  const item: TOnlineUser = {
    privateUserId,
    id: userId,
    socketId,
    online: true,
    lastSeen: getISOStringNow()
  }
  const ids = uniq([...admin.ids, privateUserId])
  const list = { ...admin.list, [privateUserId]: item }

  yield put(setAdminOnlineAction({ ids, list }))
}

function* receiveAdminOfflineSaga({
  payload: { privateUserId }
}: ReturnType<typeof receiveAdminOffline>) {
  const admin: TOnlineAdmin = yield select((state: State) => state.online.admin)
  const item: TOnlineUser = {
    ...admin.list[privateUserId],
    online: false,
    lastSeen: getISOStringNow()
  }
  const list = { ...admin.list, [privateUserId]: item }

  yield put(setAdminOnlineAction({ list, ids: admin.ids }))
}

export function* onlineSaga() {
  yield takeLatest(RECEIVE_ONLINE, receiveOnlineSaga)
  yield takeLatest(RECEIVE_OFFLINE, receiveOfflineSaga)
  yield takeLatest(RECEIVE_ADMIN_ONLINE, receiveAdminOnlineSaga)
  yield takeLatest(RECEIVE_ADMIN_OFFLINE, receiveAdminOfflineSaga)
}
