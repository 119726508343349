import { FileHistorySourcesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IFilesEntity } from 'interfaces'
import { FileViewerProgress } from 'App/components'
import { useDownloadFile } from 'App/hooks'

import styles from './TextViewer.module.scss'

export type TTextViewerProps = Pick<IFilesEntity, 'id'> & {
  source?: FileHistorySourcesEnum
  originalEntityId?: string
}

export const TextViewer = ({ id, source, originalEntityId }: TTextViewerProps) => {
  const { error, progress, fileText } = useDownloadFile({
    source,
    id,
    originalEntityId
  })

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {progress !== 100 ? (
        <FileViewerProgress
          percent={progress}
          label={!!error ? t('common.fileViewer.failure') : undefined}
          status={!!error ? 'exception' : undefined}
        />
      ) : (
        <div className={styles.container}>{fileText}</div>
      )}
    </div>
  )
}
