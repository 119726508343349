import {
  CaseCloudFilesListHeader,
  CaseCloudSharedWithMeFilesList,
  TCaseCloudFilesListProps,
  useSelection
} from 'App/components'
import { TCaseMemberCloudContainerProps } from 'App/containers'

import { EmptyLists, TEmptyListsProps } from '../EmptyLists'

export type TSharedWithMeProps = {
  isMember: boolean
  isCaseLocked: boolean
  isCaseArchived: boolean
  loading: boolean
} & Pick<TCaseMemberCloudContainerProps, 'sharedWithMeIds' | 'caseId' | 'caseStatus'> &
  Pick<TCaseCloudFilesListProps, 'getKebabMenu' | 'caseView' | 'filesView'> &
  Pick<TEmptyListsProps, 'isFiltered'>

export const SharedWithMe = ({
  isCaseArchived,
  isCaseLocked,
  isMember,
  sharedWithMeIds,
  loading,
  caseId,
  caseStatus,
  caseView,
  filesView,
  isFiltered,
  getKebabMenu
}: TSharedWithMeProps) => {
  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(sharedWithMeIds)

  const showHeader =
    !isCaseArchived && !loading && Boolean(sharedWithMeIds.length) && caseView === 'expanded'

  return (
    <>
      {showHeader && (
        <CaseCloudFilesListHeader
          spaces={[0, 12]}
          ids={sharedWithMeIds}
          selectedIds={selectedIds}
          isCaseArchived={isCaseArchived}
          getKebabMenu={getKebabMenu}
          variant="shard-with-me"
          setSelectedIds={setSelectedIds}
        />
      )}

      {!loading && !sharedWithMeIds.length ? (
        <EmptyLists
          variant="shard-with-me"
          isCaseArchived={isCaseArchived}
          isCaseLocked={isCaseLocked}
          isMember={isMember}
          isFiltered={isFiltered}
        />
      ) : (
        <CaseCloudSharedWithMeFilesList
          caseId={caseId}
          sharedWithMeIds={sharedWithMeIds}
          caseStatus={caseStatus}
          loading={loading}
          selectedIds={selectedIds}
          caseView={caseView}
          filesView={filesView}
          selectMode={selectMode}
          getKebabMenu={getKebabMenu}
          onSelect={onSelect}
        />
      )}
    </>
  )
}
