import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, TAvailableSubscriptionProps } from 'App/components'
import { EModalComponents } from 'App/containers'

import { ActionLabel } from '../ActionLabel'
import { ExpirationDate } from '../ExpirationDate'

import styles from './Actions.module.scss'

type TActionsProps = Pick<
  TAvailableSubscriptionProps,
  | 'id'
  | 'isFuture'
  | 'isActive'
  | 'processing'
  | 'startedOn'
  | 'endsOn'
  | 'actionLabel'
  | 'showModal'
> & {
  onClick: () => void
}

export const Actions = ({
  id,
  isFuture,
  isActive,
  processing,
  endsOn,
  startedOn,
  onClick,
  actionLabel,
  showModal
}: TActionsProps) => {
  const { t } = useTranslation()

  const isRenew = !isFuture && isActive
  const showYourCurrentPlan = isFuture && isActive

  const handleClick = useCallback(() => {
    if (isRenew) {
      showModal({
        modalTitle: t('modal.renewSubscriptionPlan.title'),
        modalType: EModalComponents.RENEW_SUBSCRIPTION_PLAN_DIALOG,
        modalProps: { id, onConfirm: onClick }
      })
      return
    }

    onClick()
  }, [isRenew, onClick, id, showModal, t])

  return (
    <div className={styles.actions}>
      {isFuture ? (
        <ExpirationDate
          isFuture={isFuture}
          isActive={isActive}
          endsOn={endsOn}
          startedOn={startedOn}
        />
      ) : (
        <>
          {!actionLabel && (
            <ExpirationDate
              isFuture={isFuture}
              isActive={isActive}
              endsOn={endsOn}
              startedOn={startedOn}
            />
          )}
          <Button disabled={processing} loading={processing} onClick={handleClick}>
            {actionLabel || <ActionLabel id={id} isRenew={isRenew} />}
          </Button>
        </>
      )}

      {showYourCurrentPlan && (
        <Button disabled={true}>
          {t('subscriptions.availableSubscription.currentPlanButton')}
        </Button>
      )}
    </div>
  )
}
