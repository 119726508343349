import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { createLoadingSelector, filesResetAction, getFileListRequest, GET_FILE_LIST } from 'store'
import { State } from 'redux/rootReducer'
import { FileListWrapper } from 'App/components/File/FileListWrapper'

export type TFileListWrapperContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_FILE_LIST])

const mapStateToProps = (state: State) => ({
  fileIds: state.file.ids,
  pagination: state.file.pagination,
  sorting: state.file.sorting,
  search: state.file.filters.search,
  extensionCategories: state.file.filters.extensionCategories,
  accountId: state.global.accountData?.id,
  loading: loadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadFileList: getFileListRequest,
      resetFiles: filesResetAction
    },
    dispatch
  )

export const FileListWrapperContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListWrapper)
