import { useEffect } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getUserBalanceRequest,
  GET_USER_BALANCE,
  useAppDispatch,
  useAppSelector
} from 'store'

import styles from './Balance.module.scss'

type TBalanceProps = {
  isExceedsBalance: boolean

  amount?: number
  totalAmount?: number
}

const loadingSelector = createLoadingSelector([GET_USER_BALANCE])

export const Balance = ({ isExceedsBalance, amount = 0, totalAmount = 0 }: TBalanceProps) => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getUserBalanceRequest())
  }, [dispatch])

  return (
    <div className={cls(styles.root, isExceedsBalance && styles.rootError)}>
      <Skeleton loading={loading} active paragraph title={false}>
        <div className={styles.row}>
          {t('payments.transferMDT.ballanceLabel')} <span className={styles.amount}>{amount}</span>
        </div>
        <div className={styles.row}>
          {t('payments.transferMDT.totalTransferLabel')}
          <span className={cls(styles.amount, isExceedsBalance && styles.amountError)}>
            {totalAmount}
          </span>
        </div>
      </Skeleton>
    </div>
  )
}
