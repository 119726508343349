import { action } from 'store/store.utils'
import { TError } from 'store/store.types'
import {
  TAddToBusinessRoleRequestPayload,
  TGetBusinessAccountsRequestPayload,
  TGetBusinessUsersSuccessPayload,
  TRemoveAdminRequestPayload,
  TReceiveAcceptedSuperAdminInvitationPayload
} from 'store/administration/administration.types'

export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS'
export const GET_BUSINESS_USERS_REQUEST = 'GET_BUSINESS_USERS_REQUEST'
export const GET_BUSINESS_USERS_SUCCESS = 'GET_BUSINESS_USERS_SUCCESS'
export const GET_BUSINESS_USERS_ERROR = 'GET_BUSINESS_USERS_ERROR'

export const getBusinessUsersRequest = (payload?: TGetBusinessAccountsRequestPayload) =>
  action(GET_BUSINESS_USERS_REQUEST, payload)
export const getBusinessUsersSuccess = (payload: TGetBusinessUsersSuccessPayload) =>
  action(GET_BUSINESS_USERS_SUCCESS, payload)
export const getBusinessUsersError = (payload: TError) => action(GET_BUSINESS_USERS_ERROR, payload)

export const ADD_TO_BUSINESS_ROLE = 'ADD_TO_BUSINESS_ROLE'
export const ADD_TO_BUSINESS_ROLE_REQUEST = 'ADD_TO_BUSINESS_ROLE_REQUEST'
export const ADD_TO_BUSINESS_ROLE_SUCCESS = 'ADD_TO_BUSINESS_ROLE_SUCCESS'
export const ADD_TO_BUSINESS_ROLE_ERROR = 'ADD_TO_BUSINESS_ROLE_ERROR'

export const addToBusinessRoleRequest = (payload: TAddToBusinessRoleRequestPayload) =>
  action(ADD_TO_BUSINESS_ROLE_REQUEST, payload)
export const addToBusinessRoleSuccess = () => action(ADD_TO_BUSINESS_ROLE_SUCCESS)
export const addToBusinessRoleError = (payload: TError) =>
  action(ADD_TO_BUSINESS_ROLE_ERROR, payload)

export const REMOVE_ADMIN = 'REMOVE_ADMIN'
export const REMOVE_ADMIN_REQUEST = 'REMOVE_ADMIN_REQUEST'
export const REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS'
export const REMOVE_ADMIN_ERROR = 'REMOVE_ADMIN_ERROR'

export const removeAdminRequest = (payload: TRemoveAdminRequestPayload) =>
  action(REMOVE_ADMIN_REQUEST, payload)
export const removeAdminSuccess = () => action(REMOVE_ADMIN_SUCCESS)
export const removeAdminError = (payload: TError) => action(REMOVE_ADMIN_ERROR, payload)

export const ADMIN_LOST_ACCESS_ACTION = 'ADMIN_LOST_ACCESS_ACTION'

export const adminLostAccessAction = () => action(ADMIN_LOST_ACCESS_ACTION)

export const RESET_ADMINISTRATION_STATE_ACTION = 'RESET_ADMINISTRATION_STATE_ACTION'

export const resetAdministrationStateAction = () => action(RESET_ADMINISTRATION_STATE_ACTION)

export const RECEIVE_ACCEPTED_SUPERADMIN_INVITATION = 'RECEIVE_ACCEPTED_SUPERADMIN_INVITATION'

export const receiveAcceptedSuperAdminInvitation = (
  payload: TReceiveAcceptedSuperAdminInvitationPayload
) => action(RECEIVE_ACCEPTED_SUPERADMIN_INVITATION, payload)
