import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { CollapsePanelProps } from 'antd/lib/collapse/CollapsePanel'

import { State } from 'redux/rootReducer'
import { NotificationsP2PRow } from 'App/components'

export type TNotificationsP2PRowContainerOwnProps = Omit<CollapsePanelProps, 'key' | 'header'> & {
  id: string
}

export type TNotificationsP2PRowContainerProps = TNotificationsP2PRowContainerOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, ownProps: TNotificationsP2PRowContainerOwnProps) => {
  const { firstName, type, unreadCount, lastName, displayUserName } =
    state.notifications.protocolUsers.list[ownProps.id] || {}

  const hasNotifications = Object.values(state.notifications.protocolUsers.list).some(
    (item) => !!item.unreadCount
  )

  return {
    firstName,
    type,
    lastName,
    unreadCount,
    displayUserName,
    hasNotifications
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const NotificationsP2PRowContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsP2PRow)
