import cls from 'classnames'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { EAvatarSize } from 'enums'
import { AccountsList, Button, TChannelDepartment } from 'App/components'
import { TAccountsItem } from 'App/components/common/AccountsList/AccountsListPopover/AccountsListPopover'
import { AvatarContainer } from 'App/containers'
import { ReactComponent as MedicalProvidersIcon } from 'assets/icons/MedicalProviders.svg'
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'

import styles from './SelectGroup.module.scss'

export type TSelectGroupProps = {
  onClick: () => void
  label: React.ReactNode
  variant: 'members' | 'manager'

  channelMembers?: TAccountsItem[]
  channelManager?: TAccountsItem
  channelDepartments?: TChannelDepartment[]
  disabled?: boolean
  loading?: boolean
}

export const SelectGroup = ({
  variant,
  label,
  disabled,
  channelManager,
  loading = false,
  channelMembers = [],
  channelDepartments = [],
  onClick
}: TSelectGroupProps) => {
  const { t } = useTranslation()

  const isManagerVariant = variant === 'manager'

  const accounts = useMemo<TAccountsItem[]>(() => {
    if (!!channelDepartments.length) {
      return channelDepartments.map<TAccountsItem>(({ id, name }) => ({
        id,
        displayUserName: name
      }))
    }

    return channelMembers
  }, [channelMembers, channelDepartments])

  return (
    <div
      className={cls(styles.container, {
        [styles.containerEmpty]:
          !channelMembers.length && !channelManager && !channelDepartments.length
      })}
    >
      {label}

      <Skeleton active={true} title={{ width: '100%' }} paragraph={false} loading={loading}>
        <div className={styles.root}>
          <Button
            disabled={disabled}
            className={styles.button}
            variant="text"
            icon={isManagerVariant ? <AccountIcon /> : <MedicalProvidersIcon />}
            onClick={onClick}
          >
            {isManagerVariant
              ? t('modal.createChannel.selectedListButton_manager')
              : t('modal.createChannel.selectedListButton_members')}
          </Button>

          {isManagerVariant &&
          channelManager &&
          channelManager.firstName &&
          channelManager.lastName ? (
            <AvatarContainer
              border={true}
              hasTooltip={true}
              size={EAvatarSize.SM}
              userId={channelManager.id}
              displayUserName={channelManager.displayUserName}
              firstName={channelManager.firstName}
              lastName={channelManager.lastName}
              type={channelManager.type?.name}
            />
          ) : (
            <AccountsList
              accounts={accounts}
              variant={!!channelDepartments.length ? 'chips' : undefined}
              popoverVariant={!!channelDepartments.length ? 'chip' : 'accountName'}
              avatarSize={EAvatarSize.XS}
              hideChips={!!channelDepartments.length}
              getTitle={(number) =>
                t('modal.createChannel.selectedMembersPopover.title', {
                  number
                })
              }
            />
          )}
        </div>
      </Skeleton>
    </div>
  )
}
