import { useCallback, useEffect, useRef, useState, WheelEvent } from 'react'

import { isScrollAtBottom } from 'utils'

type TUseBidirectionalScrollList = {
  getBeforeMessages: () => void
  getAfterMessages: () => void
  hasBefore: boolean
  hasAfter: boolean
  loading: boolean

  topLoadingThreshold?: number
  bottomLoadingThreshold?: number
  bottomMarginThreshold?: number
  headerWrapperElementSelectorId?: string
  footerWrapperElementSelectorId?: string
  windowScrollUsed?: boolean
}

type TScrollListRef = HTMLDivElement & { isScrollPositionBottom?: null | boolean }

const TOP_LOADING_THRESHOLD = 0.2
const BOTTOM_LOADING_THRESHOLD = 0.8
const BOTTOM_MARGIN_THRESHOLD = 1.5

// TODO: Try to use in App/components/Chat/ChatMessageList/ChatMessageList.tsx
export const useBidirectionalScroll = ({
  hasAfter,
  hasBefore,
  loading,
  getAfterMessages,
  getBeforeMessages,
  windowScrollUsed = false,
  headerWrapperElementSelectorId = '',
  footerWrapperElementSelectorId = '',
  topLoadingThreshold = TOP_LOADING_THRESHOLD,
  bottomLoadingThreshold = BOTTOM_LOADING_THRESHOLD,
  bottomMarginThreshold = BOTTOM_MARGIN_THRESHOLD
}: TUseBidirectionalScrollList) => {
  const scrollListRef = useRef<TScrollListRef>(null)
  const prevScrollTop = useRef<number>(0)
  const [isScrollPositionBottom, setScrollPositionBottom] = useState<boolean>(false)

  const onScroll = useCallback(
    (event) => {
      let scrollTop: number
      let clientHeight: number
      let scrollHeight: number
      let margin: number | undefined

      if (windowScrollUsed) {
        const headerWrapperHeight =
          document.getElementById(headerWrapperElementSelectorId)?.clientHeight || 0
        const footerWrapperHeight =
          document.getElementById(footerWrapperElementSelectorId)?.clientHeight || 0

        scrollTop = window.scrollY
        scrollHeight = scrollListRef?.current?.scrollHeight || 0
        clientHeight = window.screen.height - footerWrapperHeight - headerWrapperHeight
        margin = footerWrapperHeight / bottomMarginThreshold
      } else {
        scrollTop = event.currentTarget.scrollTop
        clientHeight = event.currentTarget.clientHeight
        scrollHeight = event.currentTarget.scrollHeight
      }

      const isBottom = isScrollAtBottom(scrollTop, scrollHeight, clientHeight, margin)

      const scrollUp = scrollTop <= prevScrollTop.current
      if (scrollUp && scrollTop / scrollHeight <= topLoadingThreshold && hasBefore && !loading) {
        getBeforeMessages()
      }

      if (
        !scrollUp &&
        (scrollTop + clientHeight) / scrollHeight >= bottomLoadingThreshold &&
        hasAfter &&
        !loading
      ) {
        getAfterMessages()
      }

      if (isBottom && !isScrollPositionBottom) {
        setScrollPositionBottom(true)

        if (scrollListRef.current) {
          scrollListRef.current.isScrollPositionBottom = true
        }
      }

      if (!isBottom && isScrollPositionBottom) {
        setScrollPositionBottom(false)
        if (scrollListRef.current) {
          scrollListRef.current.isScrollPositionBottom = false
        }
      }

      prevScrollTop.current = scrollTop
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasBefore, hasAfter, loading, isScrollPositionBottom, getBeforeMessages, getAfterMessages]
  )

  const onWheel = useCallback(
    (event: WheelEvent<HTMLDivElement>) => {
      if (event.deltaY <= 0) {
        onScroll(event)
      }
    },
    [onScroll]
  )

  useEffect(() => {
    if (windowScrollUsed) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [onScroll, windowScrollUsed])

  return {
    scrollListRef,
    prevScrollTop,
    isScrollPositionBottom,
    setScrollPositionBottom,
    onScroll,
    onWheel
  }
}
