import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { createLoadingSelector, getUserBalanceRequest, GET_USER_BALANCE } from 'store'
import { CurrentBalance } from 'App/components'

export type TCurrentBalanceContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_USER_BALANCE])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  amount: state.payments.balance.amount
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserBalance: getUserBalanceRequest
    },
    dispatch
  )

export const CurrentBalanceContainer = connect(mapStateToProps, mapDispatchToProps)(CurrentBalance)
