import { useCallback, useRef, useState } from 'react'

import { useClickAwayListener } from 'App/hooks'
import { CONTEXT_MENU_ICON_CLASS_NAME, CONTEXT_MENU_ROOT_CLASS_NAME } from 'App/components'
import { ANTD_TABLE_SELECTION_COLUMN_CLASS_NAME } from 'globalConstants'
import { hasClassName } from 'utils'

export const useFileContextMenu = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const closedOutside = useRef<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleVisibilityChange = useCallback(
    (event?: React.MouseEvent<HTMLElement, MouseEvent> | React.TouchEvent<HTMLElement>) => {
      event?.preventDefault()

      const path = event?.nativeEvent.composedPath()
      // If the click target is ContextMenu icon, Context menu root or Table checkbox
      // we have to prevent the function execution
      if (
        path &&
        (hasClassName(path, CONTEXT_MENU_ROOT_CLASS_NAME) ||
          hasClassName(path, CONTEXT_MENU_ICON_CLASS_NAME) ||
          hasClassName(path, ANTD_TABLE_SELECTION_COLUMN_CLASS_NAME)) &&
        !visible
      ) {
        return
      }

      setVisible((prev) => {
        if (closedOutside.current) {
          closedOutside.current = false

          return true
        }

        return !!event && !prev
      })
    },
    [visible]
  )

  const handleCloseOutside = useCallback((event: MouseEvent | TouchEvent, outside: boolean) => {
    if (outside && !hasClassName(event.composedPath(), CONTEXT_MENU_ROOT_CLASS_NAME)) {
      closedOutside.current = true

      setVisible(false)
    }
  }, [])

  useClickAwayListener(ref, handleCloseOutside)

  return {
    ref,
    visible,
    handleVisibilityChange
  }
}
