import { normalize, schema } from 'normalizr'

import { IInvitationByEmailDTO } from 'interfaces'

import {
  TInvitationsNewUsersNormalized,
  TInvitationsNewUsersNormalizeResult
} from './invitations.types'

const invitationsSchema = new schema.Entity('list')
const invitationsListSchema = new schema.Array(invitationsSchema)

export const invitationsNewUsersNormalize = (
  originalData: IInvitationByEmailDTO[]
): TInvitationsNewUsersNormalized => {
  const {
    entities: { list },
    result: ids
  }: TInvitationsNewUsersNormalizeResult = normalize(originalData, invitationsListSchema)

  return {
    list: list || {},
    ids: ids || []
  }
}
