import { ReactNode } from 'react'
import cls from 'classnames'

import styles from './CommentsLayout.module.scss'

type TCommentsLayoutProps = PropsWithClasses<
  'root' | 'header' | 'footer',
  Partial<{
    header: ReactNode
    content: ReactNode
    footer: ReactNode
  }>
>

export const CommentsLayout = ({ header, content, footer, classes }: TCommentsLayoutProps) => (
  <div className={cls(styles.root, classes?.root)}>
    {header && <header className={cls(styles.header, classes?.header)}>{header}</header>}

    {content}

    {footer && <footer className={cls(styles.footer, classes?.footer)}>{footer}</footer>}
  </div>
)
