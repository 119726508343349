import { useCallback } from 'react'
import cls from 'classnames'
import { LongPressCallback, LongPressDetectEvents, useLongPress } from 'use-long-press'

import { useAppSelector } from 'store'
import { ClickAwayListener, Popover } from 'App/components/common'
import { useAdaptiveLayout, useToggle } from 'App/hooks'
import { stopPropagation } from 'utils'

import { ChatMessageReactionEmoji } from '../ChatMessageReactionEmoji'
import { ChatMessageReactionCount } from '../ChatMessageReactionCount'
import { useChatMessageReactionsRequests } from '../useChatMessageReactionsRequests'

import styles from './ChatMessageReactionItem.module.scss'
import { ChatMessageReactionItemPopoverContent } from './ChatMessageReactionItemPopoverContent'

export type TChatMessageReactionItemProps = {
  messageId: string
  chatId: string
  reaction: string

  disabled?: boolean
  isImage?: boolean
  isMessageOutgoing?: boolean
}

export const ChatMessageReactionItem = ({
  isImage,
  isMessageOutgoing,
  messageId,
  reaction,
  chatId,
  disabled
}: TChatMessageReactionItemProps) => {
  const { value: visible, toggleOn, toggleOff } = useToggle()
  const { isDesktop } = useAdaptiveLayout()
  const accountsLength = useAppSelector(
    (state) => state.chat.chatMessages.reactions.messageList[messageId]?.list[reaction].size
  )

  const { processing, outgoingReaction, addReaction, removeReaction } =
    useChatMessageReactionsRequests({
      messageId,
      chatId
    })

  const isOutgoing = outgoingReaction === reaction

  const handleClick = useCallback(() => {
    if (isOutgoing) {
      removeReaction()
      return
    }

    addReaction(reaction)
  }, [addReaction, isOutgoing, reaction, removeReaction])

  const handleContextMenu = useCallback<LongPressCallback<HTMLButtonElement>>(
    (event) => {
      if (disabled || processing) {
        return
      }

      if (event) {
        event.preventDefault()
        stopPropagation(event)
      }

      toggleOn()
    },
    [disabled, processing, toggleOn]
  )

  const longPressBind = useLongPress<HTMLButtonElement>(!isDesktop ? handleContextMenu : null, {
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
    onStart: (event) => {
      event && stopPropagation(event)
    },
    onCancel: isDesktop ? undefined : handleClick
  })

  return (
    <Popover
      arrow={false}
      visible={visible}
      placement="topLeft"
      overlayClassName={styles.popover}
      content={<ChatMessageReactionItemPopoverContent messageId={messageId} reaction={reaction} />}
      getPopupContainer={(node) => node}
    >
      <ClickAwayListener
        onOutsideClick={toggleOff}
        useCapture={true}
        events={['click', 'contextmenu', 'touchstart']}
      >
        <button
          className={cls(styles.root, {
            [styles.outgoing]: isOutgoing,
            [styles.messageOutgoing]: isMessageOutgoing,
            [styles.messageImage]: isImage
          })}
          disabled={disabled || processing}
          onClick={isDesktop ? handleClick : undefined}
          {...longPressBind}
          onContextMenu={isDesktop ? handleContextMenu : undefined}
        >
          <ChatMessageReactionEmoji emoji={reaction} />
          <ChatMessageReactionCount
            accountsLength={accountsLength}
            isOutgoing={isOutgoing}
            isMessageOutgoing={isMessageOutgoing}
            reaction={reaction}
            messageId={messageId}
          />
        </button>
      </ClickAwayListener>
    </Popover>
  )
}
