import { useTranslation } from 'react-i18next'

import { EInputSize } from 'enums'
import { SearchCascader } from 'App/components'
import { useStaffSearchByOptions } from 'App/hooks/SearchCascader/useStaffSearchByOptions'
import {
  UnityHubCreateChannelsMembersStep,
  TUnityHubCreateChannelsMembersStepProps
} from 'App/components/UnityHub'
import { TGetOrganizationMembersPayload } from 'api/organizations'

import { useRequest } from './useRequest'

export type TMembersStepProps = Pick<
  TUnityHubCreateChannelsMembersStepProps,
  | 'loading'
  | 'error'
  | 'onBack'
  | 'onSubmit'
  | 'setChannelMembers'
  | 'setChannelManager'
  | 'setChannelDepartments'
  | 'variant'
  | 'channelMembers'
  | 'channelDepartments'
> &
  Pick<TGetOrganizationMembersPayload, 'markChatIds' | 'markVoiceRoomIds'> & {
    organizationId: string

    isLeaderInDepartments?: boolean
    submitLoading?: boolean
    maxSelected?: number
    channelManagerId?: string
  }

export const MembersStep = ({
  channelMembers,
  channelDepartments,
  isLeaderInDepartments,
  organizationId,
  channelManagerId,
  variant,
  maxSelected,
  submitLoading,
  error,
  loading,
  markChatIds,
  markVoiceRoomIds,
  setChannelMembers,
  setChannelManager,
  setChannelDepartments,
  onBack,
  onSubmit
}: TMembersStepProps) => {
  const { t } = useTranslation()

  const shouldShowAlert = isLeaderInDepartments

  const {
    error: innerError,
    results,
    total,
    page,
    search,
    showBy,
    departments,
    departmentsError,
    isDepartmentsLoading,
    setPage,
    handleSearch,
    handleSearchBy,
    setSelectType
  } = useRequest({
    organizationId,
    isLeaderInDepartments,
    markChatIds,
    markVoiceRoomIds,
    channelManagerId
  })

  const { options, onCascaderChange } = useStaffSearchByOptions({
    onChange: handleSearchBy,
    organizationId
  })

  return (
    <UnityHubCreateChannelsMembersStep
      CustomSearchComponent={
        <SearchCascader
          searchSize={EInputSize.L}
          placeholder={t('modal.createChannel.selectMembers.searchPlaceholder_organization')}
          options={options}
          onSearchChange={handleSearch}
          onCascaderChange={onCascaderChange}
        />
      }
      emptyListText={t('modal.createChannel.selectMembers.placeholder_organization')}
      variant={variant}
      items={results}
      departmentItems={departments}
      total={total}
      limit={showBy}
      page={page}
      search={search}
      showAlert={shouldShowAlert}
      alert={t('modal.createChannel.selectMembers.alert_organization')}
      maxSelected={maxSelected}
      error={error ?? innerError ?? departmentsError}
      channelMembers={channelMembers}
      channelManagerId={channelManagerId}
      submitLoading={submitLoading}
      loading={loading}
      channelDepartments={channelDepartments}
      departmentsLoading={isDepartmentsLoading}
      allowGroupSelect={variant !== 'manager'}
      departmentsPlaceholder={t('modal.accountsSelect.departments.placeholder')}
      departmentsSearchPlaceholder={t('modal.accountsSelect.departments.searchPlaceholder')}
      onBack={onBack}
      onSubmit={onSubmit}
      onPageChange={setPage}
      onSearch={handleSearch}
      setChannelMembers={setChannelMembers}
      setChannelManager={setChannelManager}
      setChannelDepartments={setChannelDepartments}
      onSelectTypeChange={setSelectType}
    />
  )
}
