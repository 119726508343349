import { memo, useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import cls from 'classnames'
import { v4 as uuidV4 } from 'uuid'

import { EHistoryEvents } from 'enums'
import { accountIdSelector, useAppSelector } from 'store'
import { TCommentListProps, TCommentEvent, CommentList } from 'App/components/Comments/CommentList'

import { useRequestReplies, TUseRepliesRequest } from '../../useRequestReplies'

import styles from './RepliesContainer.module.scss'

export type TRepliesContainerProps = Pick<TCommentListProps, 'getMenuItems'> &
  Pick<TUseRepliesRequest, 'showcaseId' | 'getComment'> & {
    commentId: string

    commentEvent?: Omit<TCommentEvent, 'confirm' | 'processing'>
  }

const RepliesContainerView = ({
  showcaseId,
  commentId,
  commentEvent,
  getMenuItems,
  getComment
}: TRepliesContainerProps) => {
  const accountId = useAppSelector(accountIdSelector)
  const queryKey = useMemo(
    () => ['showcases-replies', showcaseId, commentId, uuidV4()],
    [commentId, showcaseId]
  )

  const {
    replies,
    hasAfter,
    hasBefore,
    historyEvent,
    loading,
    anchorId,
    deleteProcessing,
    fetchData,
    handleDelete
  } = useRequestReplies({
    queryKey,
    showcaseId,
    commentId,
    getComment
  })

  const isOneReply = replies.length === 1

  return (
    <div className={cls(styles.root, isOneReply && styles.rootOneReply)}>
      <CommentList
        variant="reply"
        loading={loading}
        hasAfter={hasAfter}
        hasBefore={hasBefore}
        comments={replies}
        historyEvent={historyEvent}
        anchorId={historyEvent === EHistoryEvents.JUMP ? anchorId : undefined}
        onNext={fetchData}
        placeholderText="There is no replies"
        accountId={accountId}
        getMenuItems={getMenuItems}
        commentEvent={
          commentEvent
            ? {
                ...commentEvent,
                confirm: handleDelete(commentEvent.commentId),
                processing: deleteProcessing
              }
            : undefined
        }
      />
    </div>
  )
}

export const RepliesContainer = memo(RepliesContainerView, isEqual)
