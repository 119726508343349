import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { HeaderNotifications } from 'App/components'

export type THeaderNotificationsContainerProps = ReturnType<typeof mapDispatchToProps>

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const HeaderNotificationsContainer = connect(null, mapDispatchToProps)(HeaderNotifications)
