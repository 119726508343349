import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { TAccount, TContactsDataFromTo, accountIdSelector, useAppSelector } from 'store'
import { AccountsSelect, SearchCascader } from 'App/components/common'
import { EInputSize } from 'enums'
import { useAllContactsSearchByOptions } from 'App/hooks/SearchCascader/useAllContactsSearchByOptions'
import { TSearchBy, getContacts } from 'api/contacts'
import { MAX_GROUP_CHAT_PARTICIPANTS } from 'globalConstants'
import { useSelectAccounts } from 'App/hooks'

type TChatCreateGroupAddMembersDialogProps = {
  chatId: string
  selectedCount: number

  selectedMembers: TContactsDataFromTo[] | TAccount[]
  onSubmit: (selected: TContactsDataFromTo[] | TAccount[]) => void
}

export const ChatCreateGroupAddMembersDialog = ({
  chatId,
  selectedMembers,
  selectedCount,
  onSubmit
}: TChatCreateGroupAddMembersDialogProps) => {
  const { t } = useTranslation()

  const [total, setTotal] = useState(0)
  const [searchBy, setSearchBy] = useState<TSearchBy>()

  const accountId = useAppSelector(accountIdSelector)

  const {
    state: { page, search, selectedAccounts },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({
    onSubmit: (accounts) => onSubmit(accounts as Parameters<typeof onSubmit>[0]),
    accounts: []
  })

  const excludeIds = useMemo(
    () => [...selectedMembers, ...selectedAccounts].map(({ id }) => id),
    [selectedAccounts, selectedMembers]
  )

  const { data, error } = useQuery({
    queryKey: ['chat-update-group-get-contacts', chatId, search, searchBy, page, excludeIds],
    queryFn: () =>
      getContacts({
        accountId,
        page,
        search,
        searchBy,
        excludeChatIds: [chatId],
        excludeIds
      }),
    onSuccess: (response) => {
      if (response) {
        setTotal(response.total)
      }
    },
    cacheTime: 0
  })

  const results = useMemo(() => data?.results ?? [], [data])

  const maxSelectedNumber = MAX_GROUP_CHAT_PARTICIPANTS - selectedCount

  const selectedAccountsNumber = selectedAccounts.length

  const submitDisabled =
    selectedAccountsNumber > maxSelectedNumber || !selectedAccountsNumber || !maxSelectedNumber

  const { options, onCascaderChange, onLoadDepartments } = useAllContactsSearchByOptions({
    onChange: setSearchBy
  })

  return (
    <AccountsSelect
      CustomSearchComponent={
        <SearchCascader
          searchSize={EInputSize.L}
          placeholder={t('modal.addGroupChatMembers.searchPlaceholder')}
          options={options}
          onSearchChange={onSearch}
          loadData={onLoadDepartments}
          onCascaderChange={onCascaderChange}
        />
      }
      items={results}
      total={total}
      page={page}
      search={search}
      selectedItems={selectedAccounts}
      submitDisabled={submitDisabled}
      submitLabel={t('modal.addGroupChatMembers.submitButton', {
        selectedAccountsNumber,
        maxSelectedNumber
      })}
      emptyContentText={t('modal.addGroupChatMembers.placeholder_selected')}
      emptyListText={t('modal.addGroupChatMembers.placeholder')}
      error={error}
      onPageChange={onPageChange}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      onSubmit={handleSubmit}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onUnselectAll={onUnselectAll}
      autoOnline={true}
    />
  )
}
