import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { FileSharedList } from 'App/components/File/FileSharedList'
import { Pagination, SortingTable } from 'types'
import { getFileSharedListRequest } from 'store'

/** Top Component Props */
export interface IFileSharedListContainerProps {
  loading: boolean
  pagination: Pagination

  sorting?: SortingTable
}

/** Container Props */
export type TFileSharedListContainerProps = IFileSharedListContainerProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => {
  const { fileList, ids, filters, pagination } = state.fileShared

  return {
    fileList,
    fileIds: ids,
    fileTotal: filters.total,
    pagination,
    videoStreamingList: state.videoStreaming.list,
    accountId: state.global.accountData?.id
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getFilesShared: getFileSharedListRequest
    },
    dispatch
  )

export const FileSharedListContainer: FC<IFileSharedListContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileSharedList)
