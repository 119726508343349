import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { EIconSize } from 'enums'
import { ReactComponent as SavedOutlineIcon } from 'assets/icons/SavedOutline.svg'
import { ReactComponent as SavedIcon } from 'assets/icons/Saved.svg'
import { IconButton } from 'App/components/common/IconButton'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { toggleEventAttendeeFavorite } from 'api/events'
import { ButtonChip } from 'App/components/common/Button/ButtonChip'

import styles from './EventNetworkingFavoriteToggle.module.scss'

export type TEventNetworkingFavoriteToggleProps = {
  eventId: string
  attendeeId: string
  isFavorite: boolean
  onSuccess: (attendeeId: string) => void

  variant?: 'chip' | 'icon'
}

const FAVORITE_EVENT_ATTENDEE_TOGGLE_QUERY_KEY = 'favorite_event_attendee_toggle'

export const EventNetworkingFavoriteToggle = ({
  eventId,
  attendeeId,
  isFavorite,
  onSuccess,
  variant = 'icon'
}: TEventNetworkingFavoriteToggleProps) => {
  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: [FAVORITE_EVENT_ATTENDEE_TOGGLE_QUERY_KEY],
    mutationFn: () => toggleEventAttendeeFavorite({ eventId, attendeeId, isFavorite: !isFavorite }),
    onSuccess: () => {
      onSuccess(attendeeId)
      toast.success(
        isFavorite
          ? t('events.networking.addToFavoriteToggle.successToast_removed')
          : t('events.networking.addToFavoriteToggle.successToast_added'),
        toastDefaultOptions
      )
    }
  })

  const tooltip = isFavorite
    ? t('events.networking.addToFavoriteToggle.tooltip_remove')
    : t('events.networking.addToFavoriteToggle.tooltip_add')
  const icon = isFavorite ? <SavedIcon /> : <SavedOutlineIcon />

  if (variant === 'chip') {
    return (
      <ButtonChip disabled={isLoading} variant="fill" tooltip={tooltip} onClick={mutate}>
        {icon}
      </ButtonChip>
    )
  }

  return (
    <IconButton
      iconComponent={icon}
      iconSize={EIconSize.MD}
      disabled={isLoading}
      classes={{
        root: cls(styles.root, { [styles.favorite]: isFavorite })
      }}
      toolTip={tooltip}
      onClick={mutate}
    />
  )
}
