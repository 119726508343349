import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getPublicationsRequest,
  GET_PUBLICATIONS,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as TextBoxLinkOutlineIcon } from 'assets/icons/TextBoxLinkOutline.svg'

import { List } from '../../common'
import { PublicationsItemContainer } from '../PublicationsItem'
import { PublicationsAddButton } from '../PublicationsAddButton'

const loadingSelector = createLoadingSelector([GET_PUBLICATIONS])

export const PublicationsList = () => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) => state.userProfile.viewProfile.publications.ids)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getPublicationsRequest())
  }, [dispatch])

  return (
    <List
      loading={loading}
      ids={ids}
      item={PublicationsItemContainer}
      footer={<PublicationsAddButton />}
      emptyIcon={<TextBoxLinkOutlineIcon />}
      emptyText={t('userProfile.publications.placeholder')}
    />
  )
}
