import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './ActiveCallLabel.module.scss'

type TActiveCallLabelProps = PropsWithClassName<{
  isOnCurrentCall: boolean

  label?: string
}>

export const ActiveCallLabel = ({ isOnCurrentCall, className, label }: TActiveCallLabelProps) => {
  const { t } = useTranslation()

  if (!isOnCurrentCall) {
    return null
  }

  return <p className={cls(styles.root, className)}>{label ?? t('meetings.activeCallLabel')}</p>
}
