import { EModalComponents } from 'App/containers'
import { actionCreator } from 'store/store.utils'

import { TShowModalActionPayload, TSetModalTitleActionPayload } from './modal.types'
import * as actionTypes from './modal.actionTypes'

export const showModalAction = <T extends EModalComponents>(payload: TShowModalActionPayload<T>) =>
  actionCreator(actionTypes.SHOW_MODAL, payload)

export const hideModalAction = () => actionCreator(actionTypes.HIDE_MODAL)

export const setModalTitleAction = (payload: TSetModalTitleActionPayload) =>
  actionCreator(actionTypes.SET_MODAL_TITLE, payload)
