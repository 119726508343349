import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  RelatedAccountBaseItem,
  SettingsItemTitle,
  SettingsItemDescription,
  SettingsItemSubtitle
} from 'App/components'
import { EModalComponents, TSettingsSecurityDefaultAccountContainerProps } from 'App/containers'

import styles from './SettingsSecurityDefaultAccount.module.scss'

export type TSettingsSecurityDefaultAccountProps = TSettingsSecurityDefaultAccountContainerProps

export const SettingsSecurityDefaultAccount = ({
  defaultAccountId,
  showModal,
  displayUserName,
  firstName,
  lastName,
  role,
  type
}: TSettingsSecurityDefaultAccountProps) => {
  const { t } = useTranslation()

  const handleAccountChange = useCallback(() => {
    showModal({
      modalTitle: t('modal.setDefaultAccount.title'),
      modalType: EModalComponents.CHANGE_DEFAULT_ACCOUNT
    })
  }, [showModal, t])

  return (
    <>
      <SettingsItemTitle className={styles.title}>
        {t('settings.security.defaultAccount.title')}
      </SettingsItemTitle>
      <SettingsItemDescription className={styles.description}>
        {t('settings.security.defaultAccount.description')}
      </SettingsItemDescription>
      <SettingsItemSubtitle classes={{ subtitle: styles.subtitle }}>
        {t('settings.security.defaultAccount.subtitle')}
      </SettingsItemSubtitle>

      <div className={styles.account}>
        <RelatedAccountBaseItem
          id={defaultAccountId}
          type={type}
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          role={role}
          hasTooltip={false}
          showOnline={false}
        />
      </div>

      <Button variant="outlined" size="sm" onClick={handleAccountChange} className={styles.button}>
        {t('settings.security.defaultAccount.changeButton')}
      </Button>
    </>
  )
}
