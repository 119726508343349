import cls from 'classnames'
import { MeetingStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Status, TStatusProps } from 'App/components/Meetings/common/Status'

import styles from './MeetingStatus.module.scss'

export type TMeetingStatusProps = Pick<TStatusProps, 'variant'> & {
  status: MeetingStatusEnum
}

export const MeetingStatus = ({ status, variant }: TMeetingStatusProps) => {
  const { t } = useTranslation()

  if (status === MeetingStatusEnum.PENDING) {
    return null
  }

  return (
    <Status
      label={t(`chat.message.meeting.status.${status}`)}
      status={status}
      className={cls(styles.root)}
      variant={variant}
    />
  )
}
