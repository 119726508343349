import { ChatStatusEnum, MessageTypeEnum, AccountTypeNames } from '@medentee/enums'
import { TFunction } from 'i18next'

import { EIconSize } from 'enums'
import { IconButton, Button } from 'App/components'
import { ReactComponent as RecordIcon } from 'assets/icons/Record.svg'

import styles from './EndAdornment.module.scss'
import { SendMessageButton } from './SendMessageButton'

type TEndAdornmentProps = {
  isAudioRecordEnabled: boolean
  isSendActionDisabled: boolean
  isDormant: boolean
  isDesktop: boolean
  isGroupChat: boolean
  isOwner: boolean
  accountType: AccountTypeNames
  changeMessageType: (key: MessageTypeEnum) => () => void
  onSendMessage: () => void
  t: TFunction

  isContact?: boolean
  onRedirectToCase?: () => void
}

export const END_ADORNMENT = new Map<
  ChatStatusEnum,
  (props: TEndAdornmentProps) => JSX.Element | null
>()
  .set(
    ChatStatusEnum.ACTIVE,
    ({
      isDesktop,
      isAudioRecordEnabled,
      isSendActionDisabled,
      changeMessageType,
      onSendMessage,
      t
    }) => {
      if (isDesktop) {
        return (
          <IconButton
            iconComponent={<RecordIcon />}
            toolTip={t('chat.messageField.audioMessageTooltip')}
            disabled={!isAudioRecordEnabled}
            iconSize={EIconSize.MD}
            onClick={changeMessageType(MessageTypeEnum.AUDIO)}
            classes={{ root: styles.icon }}
          />
        )
      }

      if (!isDesktop) {
        return isAudioRecordEnabled ? (
          <IconButton
            iconComponent={<RecordIcon />}
            toolTip={t('chat.messageField.audioMessageTooltip')}
            iconSize={EIconSize.MD}
            onClick={changeMessageType(MessageTypeEnum.AUDIO)}
            classes={{ root: styles.icon }}
          />
        ) : (
          <SendMessageButton isSendActionDisabled={isSendActionDisabled} onClick={onSendMessage} />
        )
      }

      return null
    }
  )
  .set(ChatStatusEnum.INACTIVE, ({ isGroupChat, isOwner, onRedirectToCase, t }) => {
    if (isOwner && isGroupChat && onRedirectToCase) {
      return (
        <Button size="sm" className={styles.requestButton} onClick={onRedirectToCase}>
          {t('chat.messageField.goToCaseButton')}
        </Button>
      )
    }

    return null
  })
