import { forwardRef, Ref } from 'react'
import { Input as AntdInput, InputRef } from 'antd'
import { InputProps } from 'antd/lib/input'
import cls from 'classnames'

import { EInputSize } from 'enums'

import styles from './Input.module.scss'

export type TInputProps = InputProps & {
  inputSize: EInputSize
}

const InputView = ({ inputSize, ...props }: TInputProps, ref: Ref<InputRef>) => (
  <AntdInput
    {...props}
    ref={ref}
    className={cls({
      [styles.input]: true,
      [inputSize]: true
    })}
  />
)

export const Input = forwardRef<InputRef, TInputProps>(InputView)
