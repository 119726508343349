import { ReactNode, createContext, useContext, useCallback, useEffect } from 'react'

import { EChatViewType } from 'enums'
import { setMessagesType, useAppDispatch, useAppSelector } from 'store'

import { useToggle } from '../hooks'

export type TChatContextProviderProps = {
  children: ReactNode
  initialState: EChatViewType
}

type TContextProps = {
  chatViewType: EChatViewType
  showPinnedMessages: boolean
  showChatRoomSearch: boolean
  showChatDetails: boolean
  showChatRoomSearchResults: boolean
  togglePinnedMessages: () => void
  displayChatRoomSearch: () => void
  hideChatRoomSearch: () => void
  displayChatDetails: () => void
  hideChatDetails: () => void
  displayChatRoomSearchResults: () => void
  hideChatRoomSearchResults: () => void
}

export const ChatContext = createContext<TContextProps>({} as TContextProps)

export const ChatContextProvider = ({ children, initialState }: TChatContextProviderProps) => {
  const dispatch = useAppDispatch()
  const activeChatId = useAppSelector((state) => state.chat.chatRooms.activeChatId)
  const { value: showPinnedMessages, setValue: setShowPinnedMessagesValue } = useToggle()

  const {
    value: showChatRoomSearch,
    toggleOn: displayChatRoomSearch,
    toggleOff: hideChatRoomSearch
  } = useToggle()

  const {
    value: showChatDetails,
    toggleOn: displayChatDetails,
    toggleOff: hideChatDetails
  } = useToggle()

  const {
    value: showChatRoomSearchResults,
    toggleOn: displayChatRoomSearchResults,
    toggleOff: hideChatRoomSearchResults
  } = useToggle()

  const togglePinnedMessages = useCallback(
    () =>
      setShowPinnedMessagesValue((value) => {
        dispatch(setMessagesType({ type: value ? 'regular' : 'pinned' }))
        return !value
      }),
    [dispatch, setShowPinnedMessagesValue]
  )

  useEffect(() => {
    setShowPinnedMessagesValue(false)
  }, [activeChatId, setShowPinnedMessagesValue])

  return (
    <ChatContext.Provider
      value={{
        chatViewType: initialState,
        showPinnedMessages,
        showChatRoomSearch,
        showChatDetails,
        showChatRoomSearchResults,
        togglePinnedMessages,
        displayChatRoomSearch,
        hideChatRoomSearch,
        displayChatDetails,
        hideChatDetails,
        displayChatRoomSearchResults,
        hideChatRoomSearchResults
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}

export const useChatContext = () => {
  const context = useContext(ChatContext)

  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }

  return context
}
