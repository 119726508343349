import { forwardRef, LegacyRef, ReactNode, FocusEvent, MouseEvent, KeyboardEvent } from 'react'
import cls from 'classnames'

import { TruncatedText, TTruncatedTextProps } from '../TruncatedText'

import styles from './Chip.module.scss'

export type TChipVariant = 'primary' | 'secondary' | 'light' | 'error' | 'success' | 'outlined'
export type TChipShape = 'square' | 'round'

export type TChipProps = PropsWithClassName<
  Pick<TTruncatedTextProps, 'text'> & {
    prefix?: ReactNode
    postfix?: ReactNode
    variant?: TChipVariant
    shape?: TChipShape
    hasTooltip?: boolean
    editable?: boolean
    truncate?: boolean
    onKeyUp?: (e: KeyboardEvent) => void
    onKeyPress?: (e: KeyboardEvent) => void
    onClick?: (e: MouseEvent<HTMLDivElement>) => void
    onFocus?: (e: FocusEvent<HTMLDivElement>) => void
    onBlur?: (e: FocusEvent<HTMLDivElement>) => void
  }
>

const ChipView = (
  {
    className,
    text,
    postfix,
    prefix,
    onFocus,
    editable,
    onClick,
    onBlur,
    onKeyPress,
    onKeyUp,
    variant = 'primary',
    shape = 'round',
    hasTooltip = true,
    truncate = true
  }: TChipProps,
  ref: LegacyRef<HTMLDivElement>
) => (
  <div
    ref={ref}
    tabIndex={1}
    onBlur={onBlur}
    onFocus={onFocus}
    onClick={onClick}
    onKeyUp={onKeyUp}
    onKeyPress={onKeyPress}
    contentEditable={editable}
    suppressContentEditableWarning={editable}
    className={cls(styles.root, styles[variant], className, styles[shape], {
      [styles.notTruncated]: !truncate
    })}
  >
    {editable || !truncate ? (
      <>
        {prefix}
        {text}
        {postfix}
      </>
    ) : (
      <>
        {prefix}
        {hasTooltip ? <TruncatedText classes={{ text: styles.text }} text={text} /> : text}
        {postfix}
      </>
    )}
  </div>
)

export const Chip = forwardRef<HTMLDivElement, TChipProps>(ChipView)
