import { AxiosError } from 'axios'

import { action, TError, TResetChatPayload, TUpdateChatRoomActionPayload } from 'store'
import { actionCreator } from 'store/store.utils'

import {
  TGetChatRoomsRequestPayload,
  TGetChatRoomsSuccessPayload,
  TSearchChatActionPayload,
  TSelectChatActionPayload,
  TChatRoomsType,
  TChatInfoRequestPayload,
  TChatInfoSuccessPayload,
  TSetChatFiltersPayload,
  TReceiveChatMutedPayload,
  TReceiveAccessToTextChannelPayload,
  TReceiveManagerInTextChannelPayload,
  TGetChatRoomRequestPayload,
  TGetChatRoomSuccessPayload,
  ISendTypingRequest,
  ISendTypingSuccess,
  TReceiveTypingActionPayload as TReceiveTypingPayload,
  TReceiveTypingDoneActionPayload as TReceiveTypingDonePayload,
  TSetTypingDoneSuccessPayload,
  TSetTypingSuccessPayload,
  TReceiveChangeChatStatusPayload
} from './chatRooms.types'

// ------------------ GET_CHAT_ROOMS ------------------

export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS'
export const GET_CHAT_ROOMS_REQUEST = 'GET_CHAT_ROOMS_REQUEST'
export const GET_CHAT_ROOMS_SUCCESS = 'GET_CHAT_ROOMS_SUCCESS'
export const GET_CHAT_ROOMS_ERROR = 'GET_CHAT_ROOMS_ERROR'

export const getChatRoomsRequest = (payload?: TGetChatRoomsRequestPayload) =>
  actionCreator(GET_CHAT_ROOMS_REQUEST, payload)

export const getChatRoomsSuccess = (payload: TGetChatRoomsSuccessPayload) =>
  actionCreator(GET_CHAT_ROOMS_SUCCESS, payload)

export const getChatRoomsError = (payload: TError) => actionCreator(GET_CHAT_ROOMS_ERROR, payload)

// ------------------ GET_CHAT_ROOM ------------------

export const GET_CHAT_ROOM = 'GET_CHAT_ROOM'
export const GET_CHAT_ROOM_REQUEST = 'GET_CHAT_ROOM_REQUEST'
export const GET_CHAT_ROOM_SUCCESS = 'GET_CHAT_ROOM_SUCCESS'
export const GET_CHAT_ROOM_ERROR = 'GET_CHAT_ROOM_ERROR'

export const getChatRoomRequest = (payload?: TGetChatRoomRequestPayload) =>
  actionCreator(GET_CHAT_ROOM_REQUEST, payload)

export const getChatRoomSuccess = (payload: TGetChatRoomSuccessPayload) =>
  actionCreator(GET_CHAT_ROOM_SUCCESS, payload)

export const getChatRoomError = (payload: TError) => actionCreator(GET_CHAT_ROOM_ERROR, payload)

// ------------------ SELECT_CHAT ------------------

export const SELECT_CHAT = 'SELECT_CHAT'
export const RESET_SELECTED_CHAT = 'RESET_SELECTED_CHAT'
export const RESET_CHAT = 'RESET_CHAT'

export const selectChatAction = (payload: TSelectChatActionPayload) =>
  actionCreator(SELECT_CHAT, payload)
export const resetSelectedChatAction = (payload?: TResetChatPayload) =>
  action(RESET_SELECTED_CHAT, payload)
export const resetChatAction = (payload?: TResetChatPayload) => actionCreator(RESET_CHAT, payload)

// ------------------ SEARCH_CHAT ------------------

export const SEARCH_CHAT = 'SEARCH_CHAT'

export const searchChatAction = (payload: TSearchChatActionPayload) => action(SEARCH_CHAT, payload)

// ------------------ UPDATE_CHAT_ROOM_ACTION ------------------

export const UPDATE_CHAT_ROOM_ACTION = 'UPDATE_CHAT_ROOM_ACTION'

export const updateChatRoomAction = (payload: TUpdateChatRoomActionPayload) =>
  action(UPDATE_CHAT_ROOM_ACTION, payload)

// ------------------ CHANGE_CHAT_TYPE ------------------

export const SET_CHAT_TYPE = 'SET_CHAT_TYPE'

export const setChatTypeAction = (payload: TChatRoomsType) => action(SET_CHAT_TYPE, payload)

// ------------------ RECEIVE_CHANGE_CHAT_STATUS ------------------

export const RECEIVE_CHANGE_CHAT_STATUS = 'RECEIVE_CHANGE_CHAT_STATUS'

export const receiveChangeChatStatusAction = (payload: TReceiveChangeChatStatusPayload) =>
  action(RECEIVE_CHANGE_CHAT_STATUS, payload)

// ------------- CHAT INFO --------------------
export const LOAD_CHAT_INFO = 'LOAD_CHAT_INFO'
export const LOAD_CHAT_INFO_REQUEST = 'LOAD_CHAT_INFO_REQUEST'
export const LOAD_CHAT_INFO_SUCCESS = 'LOAD_CHAT_INFO_SUCCESS'
export const LOAD_CHAT_INFO_ERROR = 'LOAD_CHAT_INFO_ERROR'

export const loadChatInfoRequest = (payload: TChatInfoRequestPayload) =>
  action(LOAD_CHAT_INFO_REQUEST, payload)
export const loadChatInfoSuccess = (payload: TChatInfoSuccessPayload) =>
  action(LOAD_CHAT_INFO_SUCCESS, payload)
export const loadChatInfoError = (payload: TError) => action(LOAD_CHAT_INFO_ERROR, payload)

// ------------------ SET_CHAT_FILTERS ------------------
export const SET_CHAT_FILTERS = 'SET_CHAT_FILTERS'

export const setChatFilters = (payload: TSetChatFiltersPayload) => action(SET_CHAT_FILTERS, payload)

// ------------------ RECEIVE_CHAT_MUTED ------------------
export const RECEIVE_CHAT_MUTED = 'RECEIVE_CHAT_MUTED'

export const receiveChatMuted = (payload: TReceiveChatMutedPayload) =>
  action(RECEIVE_CHAT_MUTED, payload)

// ------------------ RECEIVE_CHAT_UNMUTED ------------------
export const RECEIVE_CHAT_UNMUTED = 'RECEIVE_CHAT_UNMUTED'

export const receiveChatUnmuted = (payload: TReceiveChatMutedPayload) =>
  action(RECEIVE_CHAT_UNMUTED, payload)

// ------------------ RECEIVE_ADDED_USER_TO_CHAT ------------------
export const RECEIVE_ADDED_USER_TO_CHAT = 'RECEIVE_ADDED_USER_TO_CHAT'

export const receiveAddedUserToChat = (payload: TReceiveAccessToTextChannelPayload) =>
  action(RECEIVE_ADDED_USER_TO_CHAT, payload)

// ------------------ RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL ------------------
export const RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL = 'RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL'

export const receiveLostManagerInTextChannel = (payload: TReceiveManagerInTextChannelPayload) =>
  action(RECEIVE_LOST_MANAGER_IN_TEXT_CHANNEL, payload)

// ------------------ RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL ------------------
export const RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL = 'RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL'

export const receiveDelegatedManagerInTextChannel = (
  payload: TReceiveManagerInTextChannelPayload
) => action(RECEIVE_DELEGATED_MANAGER_IN_TEXT_CHANNEL, payload)

// ------------------ RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL ------------------
export const RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL = 'RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL'

export const receiveLostAccessToTextChannel = (payload: TReceiveAccessToTextChannelPayload) =>
  action(RECEIVE_LOST_ACCESS_TO_TEXT_CHANNEL, payload)

// ------------------ SEND_TYPING ------------------

export const START_TYPING = 'START_TYPING'
export const SEND_TYPING_REQUEST = 'SEND_TYPING_REQUEST'
export const SEND_TYPING_SUCCESS = 'SEND_TYPING_SUCCESS'
export const SEND_TYPING_ERROR = 'SEND_TYPING_ERROR'

export const SEND_TYPING_DONE_REQUEST = 'SEND_TYPING_DONE_REQUEST'
export const SEND_TYPING_DONE_SUCCESS = 'SEND_TYPING_DONE_SUCCESS'
export const SEND_TYPING_DONE_ERROR = 'SEND_TYPING_DONE_ERROR'

export const startTyping = () => action(START_TYPING)

export const sendTypingRequest = (payload: ISendTypingRequest) =>
  action(SEND_TYPING_REQUEST, payload)

export const sendTypingSuccess = (payload: ISendTypingSuccess) =>
  action(SEND_TYPING_SUCCESS, payload)

export const sendTypingError = (payload: AxiosError | null) => action(SEND_TYPING_ERROR, payload)

export const sendTypingDoneRequest = (payload: ISendTypingRequest) =>
  action(SEND_TYPING_DONE_REQUEST, payload)

export const sendTypingDoneSuccess = (payload: ISendTypingSuccess) =>
  action(SEND_TYPING_DONE_SUCCESS, payload)

export const sendTypingDoneError = (payload: AxiosError | null) =>
  action(SEND_TYPING_DONE_ERROR, payload)

// ------------------ Receive TYPING WS Events ------------------

export const RECEIVE_TYPING = 'RECEIVE_TYPING'
export const RECEIVE_TYPING_DONE = 'RECEIVE_TYPING_DONE'

export const receiveTyping = (payload: TReceiveTypingPayload) => action(RECEIVE_TYPING, payload)
export const receiveTypingDone = (payload: TReceiveTypingDonePayload) =>
  action(RECEIVE_TYPING_DONE, payload)

// ------------------ SET_TYPING ------------------

export const SET_TYPING_SUCCESS = 'SET_TYPING_SUCCESS'
export const SET_TYPING_ERROR = 'SET_TYPING_ERROR'

export const setTypingSuccess = (payload: TSetTypingSuccessPayload) =>
  action(SET_TYPING_SUCCESS, payload)
export const setTypingError = (payload: TError) => action(SET_TYPING_ERROR, payload)

// ------------------ SET_TYPING_DONE ------------------

export const SET_TYPING_DONE_SUCCESS = 'SET_TYPING_DONE_SUCCESS'
export const SET_TYPING_DONE_ERROR = 'SET_TYPING_DONE_ERROR'

export const setTypingDoneSuccess = (payload: TSetTypingDoneSuccessPayload) =>
  action(SET_TYPING_DONE_SUCCESS, payload)
export const setTypingDoneError = (payload: TError) => action(SET_TYPING_DONE_ERROR, payload)
