import { AccountTypeNames } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  AccountName,
  Avatar,
  Divider,
  TAccountNameProps,
  TAvatarProps
} from 'App/components/common'
import { EAvatarSize } from 'enums'
import {
  TUserProfileSocialLinksProps,
  UserProfileSocialLinks
} from 'App/components/UserProfile/UserProfileSocialLinks'

import styles from './UnityHubInfoOwner.module.scss'

export type TUnityHubInfoOwnerProps = Pick<
  TAvatarProps,
  'firstName' | 'lastName' | 'displayUserName' | 'userId' | 'isCommunity'
> &
  Pick<TAccountNameProps, 'shouldRedirectToUserProfile'> & {
    socialLinks?: TUserProfileSocialLinksProps['links']
  }

export const UnityHubInfoOwner = ({
  displayUserName,
  firstName,
  lastName,
  userId,
  isCommunity,
  socialLinks,
  shouldRedirectToUserProfile
}: TUnityHubInfoOwnerProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.profile}>
        <Avatar
          firstName={firstName}
          lastName={lastName}
          displayUserName={displayUserName}
          type={AccountTypeNames.BUSINESS}
          size={EAvatarSize.XS}
          userId={userId}
          hasTooltip={false}
          isCommunity={isCommunity}
        />

        <div className={styles.organizer}>
          <span className={styles.organizerLabel}>{t('unityHub.info.organizerLabel')}</span>
          <AccountName
            displayUserName={displayUserName}
            type={AccountTypeNames.BUSINESS}
            userId={userId}
            size={EAvatarSize.XS}
            hideAccountType
            hideIcon
            shouldRedirectToUserProfile={shouldRedirectToUserProfile}
          />
        </div>
      </div>

      {!!socialLinks?.length && (
        <>
          <Divider variant="vertical" />

          <UserProfileSocialLinks className={styles.socialLinks} links={socialLinks} size="md" />
        </>
      )}
    </div>
  )
}
