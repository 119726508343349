import { useCallback, useState, useEffect, useMemo, FC } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { ProfileLinkTypeNamesEnum } from '@medentee/enums'
import orderBy from 'lodash/orderBy'

import { Button, FormSubmitButtonSkeleton } from 'App/components/common'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import { TSocialLink, TSocialLinkProps } from '../SocialLink'

import styles from './SocialLinks.module.scss'

export type TOnCreateProps = {
  id: string
  url: string
  createdId: string
}

export type TOnUpdateProps = {
  id: string
  url: string
}

export type TOnDeleteProps = {
  id: string
  type: ProfileLinkTypeNamesEnum
}

export type TSocialLinksProps = {
  children: FC<
    Pick<TSocialLinkProps, 'data' | 'loading' | 'onCancel'> & {
      onCreate: (props: TOnCreateProps) => void
      onDelete: (props: TOnDeleteProps) => void
      onUpdate: (props: TOnUpdateProps) => void
    }
  >
  loading: boolean

  data?: TSocialLinkProps['data'][]
}

const SKELETON_ITEMS_AMOUNT = 3

export const SocialLinks = ({ data, loading, children: Item }: TSocialLinksProps) => {
  const [linksList, setLinkList] = useState<TSocialLinksProps['data']>([])

  useEffect(() => {
    data && setLinkList(orderBy(data, 'id'))
  }, [data])

  const generateEmptyLink = useCallback(
    (type: ProfileLinkTypeNamesEnum = ProfileLinkTypeNamesEnum.WEBSITE): TSocialLink => ({
      id: uuidV4(),
      type,
      url: '',
      isNew: true
    }),
    []
  )

  const handleAdd = useCallback(() => {
    setLinkList((prev = []) => [...prev, generateEmptyLink()])
  }, [generateEmptyLink])

  const handleCreate = useCallback(({ createdId, id, url }: TOnCreateProps) => {
    setLinkList((prev = []): TSocialLink[] =>
      prev.map((item) => (item.id === id ? { ...item, id: createdId, url, isNew: false } : item))
    )
  }, [])

  const handleUpdate = useCallback(({ id, url }: TOnUpdateProps) => {
    setLinkList((prev = []): TSocialLink[] =>
      prev?.map((item) => (item.id === id ? { ...item, url } : item))
    )
  }, [])

  const handleDelete = useCallback(({ id, type }: TOnDeleteProps) => {
    setLinkList((prev = []): TSocialLink[] =>
      type === ProfileLinkTypeNamesEnum.WEBSITE
        ? prev?.filter((item) => id !== item.id)
        : prev?.map((item) => (id === item.id ? { id, type, url: '' } : item))
    )
  }, [])

  const handleCancel = useCallback(
    (id: string) => {
      const link = linksList?.find((item) => item.id === id)

      if (link?.isNew && link?.type === ProfileLinkTypeNamesEnum.WEBSITE) {
        setLinkList((prev) => prev?.filter((item) => id !== item.id))
      }
    },
    [linksList]
  )

  const list = useMemo<TSocialLink[]>(
    () =>
      loading
        ? Array.from(Array(SKELETON_ITEMS_AMOUNT).keys()).map(() => generateEmptyLink())
        : linksList ?? [],
    [generateEmptyLink, linksList, loading]
  )

  return (
    <>
      {list?.map((item) => (
        <Item
          key={item.id}
          data={item}
          loading={loading}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          onCancel={handleCancel}
        />
      ))}
      <div className={styles.footer}>
        <FormSubmitButtonSkeleton loading={loading}>
          <Button variant="text" icon={<PlusIcon />} onClick={handleAdd}>
            Add website
          </Button>
        </FormSubmitButtonSkeleton>
      </div>
    </>
  )
}
