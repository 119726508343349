import { AccountTypeNames, BusinessAccountRoleNameEnum } from '@medentee/enums'

import { getMapComponent } from 'utils'
import { OperationsHistoryContainer, TPaymentsContainerProps } from 'App/containers'

import { Balance } from './Balance'
import { MonthlyTopUpSetUp } from './TopUp/MonthlyTopUpSetUp'

const VIEW_MAP = new Map<AccountTypeNames, (props: TPaymentsContainerProps) => JSX.Element | null>()
  .set(AccountTypeNames.BUSINESS, ({ role, displayUserName, type }) =>
    role === BusinessAccountRoleNameEnum.SUPERADMIN ? (
      <>
        <Balance displayUserName={displayUserName} accountType={type}>
          <MonthlyTopUpSetUp />
        </Balance>
        <OperationsHistoryContainer />
      </>
    ) : null
  )
  .set(AccountTypeNames.PROFESSIONAL, ({ displayUserName, type }) => (
    <>
      <Balance displayUserName={displayUserName} accountType={type}>
        <MonthlyTopUpSetUp />
      </Balance>
      <OperationsHistoryContainer />
    </>
  ))

type TPaymentsProps = TPaymentsContainerProps

export const Payments = (props: TPaymentsProps) => getMapComponent(VIEW_MAP, props.type, props)
