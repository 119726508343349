import { Fragment } from 'react'
import cls from 'classnames'

import { IDepartment, IFilesEntity } from 'interfaces'
import { TAccountData } from 'store'
import { createShowcaseFileName, DELETED_FILE_MENTION, TFileMention } from 'utils/mentions'

import { Mention } from '../Mention'

import styles from './TextWithMention.module.scss'

const TRIGGER_REGEXP = /<(\/|@|#)/
const MENTION_ID_REGEXP = /(<(\/|@|#))(everyone|here|\d+)(?=>)/
const MENTION_REGEXP = /<(\/|@|#)(everyone|here|\d+)>/gm

export type TTextWithMentionProps = {
  text: string
  index: number

  mentions?: Pick<TFileMention, 'showcaseOptions' | 'clickable'> & {
    accounts?: Pick<
      TAccountData,
      'id' | 'firstName' | 'lastName' | 'displayUserName' | 'type' | 'deletedAt'
    >[]
    departments?: Pick<IDepartment, 'id' | 'name' | 'deletedAt'>[]
    files?: IFilesEntity[]
  }
  className?: string
}

export const parseTextWithMention = ({
  text,
  mentions,
  className
}: Omit<TTextWithMentionProps, 'index'>) => {
  const {
    accounts = [],
    files = [],
    departments = [],
    showcaseOptions = {},
    clickable = true
  } = mentions ?? {}

  if (MENTION_REGEXP.test(text)) {
    let mentionComponent: JSX.Element = <>{text}</>

    const hasNewLineOnStart = /^[\n]/g.test(text)
    const hasNewLineOnEnd = /[\n]$/g.test(text)

    const triggerMatch = TRIGGER_REGEXP.exec(text)
    const trigger = triggerMatch ? triggerMatch[1] : null

    const mentionIdMatch = MENTION_ID_REGEXP.exec(text)
    const mentionId = mentionIdMatch?.[0].substring(2) ?? ''

    if (trigger === '@' && (mentionId === 'here' || mentionId === 'everyone')) {
      const decoratedText = text.replace(MENTION_REGEXP, `${trigger}${mentionId}`)

      mentionComponent = (
        <Mention
          decoratedText={decoratedText}
          className={cls(styles.root, className)}
          mention={{
            variant: 'general',
            id: mentionId,
            name: mentionId,
            trigger,
            clickable: false
          }}
        />
      )
    }

    if (trigger === '@' && mentionId !== 'here' && mentionId !== 'everyone') {
      const account = accounts.find(({ id }) => id === mentionId)
      const decoratedText = text.replace(MENTION_REGEXP, `${trigger}${account?.displayUserName}`)
      const disabled = !!account?.deletedAt
      const isClickable = !disabled && clickable

      mentionComponent = account ? (
        <Mention
          decoratedText={decoratedText}
          className={cls(styles.root, className, {
            [styles.clickable]: isClickable,
            [styles.disabled]: disabled
          })}
          mention={{
            variant: 'account',
            id: account?.id,
            name: account?.displayUserName,
            trigger,
            clickable: isClickable,
            displayUserName: account?.displayUserName,
            firstName: account?.firstName,
            lastName: account?.lastName,
            type: account?.type
          }}
        />
      ) : (
        mentionComponent
      )
    }

    if (trigger === '#') {
      const department = departments.find(({ id }) => id === mentionId)
      const name = !department?.deletedAt ? department?.name ?? '' : `${department.name} (deleted)`
      const decoratedText = text.replace(MENTION_REGEXP, `@${name}`)

      mentionComponent = department ? (
        <Mention
          decoratedText={decoratedText}
          className={cls(styles.root, className)}
          mention={{
            variant: 'department',
            id: department.id,
            trigger: '@',
            name,
            clickable: false,
            deletedAt: department.deletedAt
          }}
        />
      ) : (
        mentionComponent
      )
    }

    if (trigger === '/') {
      const fileIndex = files.findIndex(({ id }) => id === mentionId)
      const fileName =
        fileIndex === -1 ? DELETED_FILE_MENTION : createShowcaseFileName(fileIndex + 1)
      const decoratedText = text.replace(MENTION_REGEXP, fileName)

      mentionComponent = (
        <Mention
          decoratedText={decoratedText}
          className={cls(styles.root, className, { [styles.clickable]: clickable })}
          mention={{
            variant: 'file',
            trigger,
            name: fileName,
            showcaseOptions,
            clickable,
            ...files[fileIndex]
          }}
        />
      )
    }

    return (
      <>
        {hasNewLineOnStart && <>{'\n'}</>}
        {mentionComponent}
        {hasNewLineOnEnd && <>{'\n'}</>}
      </>
    )
  }

  return text
}

export const TextWithMention = ({ text, index, mentions }: TTextWithMentionProps) => (
  <Fragment key={index}>{parseTextWithMention({ text, mentions })}</Fragment>
)
