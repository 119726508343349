import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TEventAttendee } from 'interfaces'
import { DisplayProfessionsSpecializations } from 'App/components/DisplayProfessionsSpecializations'

import styles from './EventNetworkingWorkInformation.module.scss'

export type TUseEventNetworkingWorkInformationProps = Pick<
  TEventAttendee,
  'company' | 'position' | 'openForNetworking'
> &
  Pick<TEventAttendee['account'], 'professions'>

export const useEventNetworkingWorkInformation = ({
  company,
  position,
  professions,
  openForNetworking
}: TUseEventNetworkingWorkInformationProps) => {
  const { t } = useTranslation()

  const workInformation = useMemo(() => {
    if (company && !position) {
      return company
    }

    if (!company && position) {
      return position
    }

    if (company && position) {
      return t('events.networking.attendee.companyPosition', { position, company })
    }

    return
  }, [company, position, t])

  const shouldShowProfessions = !openForNetworking || (openForNetworking && !company && !position)
  const shouldShowCompany = openForNetworking && (company || position)

  const getComponent = useCallback(
    () => (
      <>
        {shouldShowProfessions && <DisplayProfessionsSpecializations data={professions} />}
        {shouldShowCompany && <div className={styles.workInformation}>{workInformation}</div>}
      </>
    ),
    [professions, shouldShowCompany, shouldShowProfessions, workInformation]
  )

  return {
    shouldShowProfessions,
    shouldShowCompany,
    EventNetworkingWorkInformation: getComponent
  }
}
