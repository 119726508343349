import { AccountTypeNames, ContactStatusEnum, ErrorCodesEnum } from '@medentee/enums'
import { put, takeLatest, call, select, cancel, throttle } from 'redux-saga/effects'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { DEFAULT_THROTTLE_MS, toastDefaultOptions } from 'globalConstants'
import { ContactsQueryBuilder, QueryBuilder, setRedirectToastInfo, isMatchErrorCode } from 'utils'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { contactsNormalize, TContactsData, clearErrorSaga } from 'store'
import { State } from 'redux/rootReducer'
import { hideModalAction } from 'store/modal'
import { formatContactsData } from 'store/contacts/utils'
import { ESet2FAType, EChangeEmailConfirmStep } from 'enums'
import { IAccountSettingsDTO } from 'interfaces'
import { getWhoAmiRequest } from 'store/store.actions'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

import { TAccountAndNotificationsCaseAutoApprove } from './settings.types'
import * as actionTypes from './settings.actionTypes'
import {
  getCaseAutoApproveContactsSuccess,
  getCaseAutoApproveContactsError,
  getCaseAutoApproveContactsRequest,
  setCaseAutoApproveSettingsError,
  setCaseAutoApproveSettingsSuccess,
  set2FASettingsError,
  set2FASettingsSuccess,
  getAccountSettingsSuccess,
  getAccountSettingsError,
  getAccountSettingsRequest,
  setDefaultAccountSuccess,
  setDefaultAccountError,
  setCaseAutoApproveContactsSearch,
  setCaseAutoApproveSettingsRequest,
  set2FASettingsRequest,
  setDefaultAccountRequest,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  changeEmailRequest,
  changeEmailSuccess,
  changeEmailError,
  toggleCallsOrSoundsRequest,
  toggleCallsOrSoundsSuccess,
  toggleCallsOrSoundsError,
  setCaseAutoApproveContactsCategory
} from './settings.actions'

function* caseAutoApproveContacts({
  payload: { page, search = '', showBy = PAGINATION_DEFAULT_SHOW_BY, categoryId = '' }
}: ReturnType<typeof getCaseAutoApproveContactsRequest>) {
  try {
    const { ids: currentIds, list: currentList }: TAccountAndNotificationsCaseAutoApprove =
      yield select((state: State) => state.settings.accountAndNotifications.caseAutoApprove)
    const accountId: string = yield select((state: State) => state.global.accountData?.id)

    const urlBuilder = new ContactsQueryBuilder(API.CONTACTS)
      .status(ContactStatusEnum.APPROVED)
      .showBy(showBy)
      .search(search)
      .page(page)

    if (categoryId) {
      urlBuilder.categoryIds([categoryId])
    }

    const url = urlBuilder.build()

    const {
      data: { results, total }
    }: APIResultsResponse<TContactsData[]> = yield call(api.get, url)

    const { ids, list } = contactsNormalize(formatContactsData(results, accountId))
    const filters = { page, showBy, total, search, categoryId }
    let payload = { ids, list, filters }

    if (page > 0) {
      payload = {
        ids: [...currentIds, ...ids],
        list: { ...currentList, ...list },
        filters
      }
    }
    yield put(getCaseAutoApproveContactsSuccess(payload))
  } catch (e) {
    yield put(getCaseAutoApproveContactsError(e))
  }
}

function* caseAutoApproveContactsSearch({
  payload: { search }
}: ReturnType<typeof setCaseAutoApproveContactsSearch>) {
  try {
    const categoryId: string = yield select(
      (state: State) => state.settings.accountAndNotifications.caseAutoApprove.filters.categoryId
    )

    yield put(getCaseAutoApproveContactsRequest({ page: 0, search, categoryId }))
  } catch (e) {
    yield put(getCaseAutoApproveContactsError(e))
  }
}

function* setCaseAutoApproveContactsCategorySaga({
  payload: { categoryId }
}: ReturnType<typeof setCaseAutoApproveContactsCategory>) {
  try {
    const search: string = yield select(
      (state: State) => state.settings.accountAndNotifications.caseAutoApprove.filters.search
    )
    yield put(getCaseAutoApproveContactsRequest({ page: 0, search, categoryId }))
  } catch (e) {
    yield put(getCaseAutoApproveContactsError(e))
  }
}

function* setCaseAutoApproveSettings({
  payload: { contactIds }
}: ReturnType<typeof setCaseAutoApproveSettingsRequest>) {
  try {
    yield call(api.patch, API.CONTACTS_SETTINGS_BULK, { contactIds })

    yield put(setCaseAutoApproveSettingsSuccess())
    yield put(hideModalAction())
  } catch (e) {
    yield put(setCaseAutoApproveSettingsError(e))
  }
}

/**
 *
 * @description This saga enable or disable 2FA.
 *
 * ```ts
 *  isResend?: boolean;
 *  token?: string;
 * ```
 *
 * 1) `isResend: empty;` and `token: empty;`. You send code.
 * 2) `isResend: true;` and `token: empty;`. You resend new code.
 * 3) `isResend: false;` and `token: fill;`. You enable or disable 2FA.
 */
function* set2FASettings({
  payload: { isResend, type, token }
}: ReturnType<typeof set2FASettingsRequest>) {
  try {
    const enable = type === ESet2FAType.ENABLE

    const url = enable ? API.ENABLE_2FA : API.DISABLE_2FA

    const { data }: APIData<string> = yield call(api.post, url, { isResend, token })

    if (token) {
      yield put(getAccountSettingsRequest())
      yield put(hideModalAction())
    }

    yield put(set2FASettingsSuccess({ type, expiredTokenDate: data, isResend }))

    if (token) {
      yield toast.success(
        enable
          ? i18n.t('common.toast.twoFactorSetUp')
          : i18n.t('common.toast.twoFactorDeactivated'),
        toastDefaultOptions
      )
    }
  } catch (e) {
    yield put(set2FASettingsError(e))
    if (
      isResend &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_INVALID) &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_EXHAUSTED)
    ) {
      yield clearErrorSaga(actionTypes.SET_2FA_SETTINGS)
    }
  }
}

/**
 *
 * @description This saga change email via 2FA.
 *
 * Current user password must be sent on the first and next steps.
 * Token from old email must be sent on the second and next steps.
 * New email must be sent on the third and next steps.
 * Token from new email must be sent on the fourth and next steps.
 *
 * ```ts
 *  currentPassword: string
 *  oldToken: string
 *  newEmail: string
 *  newToken: string
 *  isResend: boolean
 * ```
 */

function* changeEmailSaga({ payload: { step, ...body } }: ReturnType<typeof changeEmailRequest>) {
  try {
    const accountType: AccountTypeNames = yield select(
      (state: State) => state.global.accountData?.type.name
    )

    const business = accountType === AccountTypeNames.BUSINESS

    if (body.newToken && !business) {
      yield call(setRedirectToastInfo, {
        type: 'success',
        message: i18n.t('common.toast.emailChanged')
      })
    }

    const { data }: APIData<string> = yield call(api.post, API.CHANGE_EMAIL, body)
    switch (step) {
      case EChangeEmailConfirmStep.SET_PASSWORD: {
        yield put(changeEmailSuccess({ firstExpiredTokenDate: data }))
        break
      }
      case EChangeEmailConfirmStep.FIRST_2FA: {
        if (!body.isResend) {
          yield put(changeEmailSuccess({ first2FAStepFinished: true }))
        }

        break
      }
      case EChangeEmailConfirmStep.SET_EMAIL: {
        yield put(changeEmailSuccess({ secondExpiredTokenDate: data }))
        break
      }
      case EChangeEmailConfirmStep.SECOND_2FA: {
        yield put(hideModalAction())

        if (business) {
          yield put(getWhoAmiRequest())
          yield toast.success(i18n.t('common.toast.emailChanged'), toastDefaultOptions)
        }
        break
      }
      default:
        break
    }
  } catch (e) {
    yield put(changeEmailError(e))
    if (
      body.isResend &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_INVALID) &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_EXHAUSTED)
    ) {
      yield clearErrorSaga(actionTypes.CHANGE_EMAIL)
    }
  }
}

/**
 *
 * @description This saga change password via 2FA.
 *
 * Old user password must be sent on the first and next steps.
 * New user password must be sent on the first and next steps.
 * Token confirmation must be sent on the second and next steps.
 *
 * ```ts
 *  oldPassword: string;
 *  newPassword: string;
 *  isResend?: boolean;
 *  token?: string;
 * ```
 */
function* changePasswordSaga({ payload }: ReturnType<typeof changePasswordRequest>) {
  try {
    if (payload.token) {
      yield call(setRedirectToastInfo, {
        type: 'success',
        message: i18n.t('common.toast.passwordChanged')
      })
    }

    const { data }: APIData<string> = yield call(api.post, API.CHANGE_PASSWORD, payload)

    if (payload.token) {
      yield put(hideModalAction())
      yield cancel()
    }

    yield put(changePasswordSuccess({ expiredTokenDate: data }))
  } catch (e) {
    yield put(changePasswordError(e))
    if (
      payload.isResend &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_INVALID) &&
      !isMatchErrorCode(e, ErrorCodesEnum.TOKEN_EXHAUSTED)
    ) {
      yield clearErrorSaga(actionTypes.CHANGE_PASSWORD)
    }
  }
}

function* getAccountSettings() {
  try {
    const { data }: APIData<IAccountSettingsDTO> = yield call(api.get, API.SETTINGS)

    yield put(getAccountSettingsSuccess(data))
  } catch (e) {
    yield put(getAccountSettingsError(e))
    yield handleError(e)
  }
}

function* setDefaultAccount({ payload }: ReturnType<typeof setDefaultAccountRequest>) {
  try {
    const defaultAccountId: string = yield select(
      (state: State) => state.settings.securityAndLogin.defaultAccount.id
    )

    if (defaultAccountId === payload.defaultAccountId) {
      yield put(hideModalAction())
      yield cancel()
    }

    yield call(api.post, API.DEFAULT_ACCOUNT, payload)

    yield put(setDefaultAccountSuccess())
    yield put(getAccountSettingsRequest())
    yield put(hideModalAction())
    yield toast.success(i18n.t('common.toast.changesSaved'), toastDefaultOptions)
  } catch (e) {
    yield put(setDefaultAccountError(e))
  }
}

function* toggleCallsOrSoundsSaga({
  payload: { type, currentStatus }
}: ReturnType<typeof toggleCallsOrSoundsRequest>) {
  try {
    const url = new QueryBuilder(API.SETTINGS_SOUNDS_TOGGLE).custom('settingName', type).build()
    yield call(api.post, url)

    yield put(toggleCallsOrSoundsSuccess({ [type]: currentStatus }))
    yield put(getWhoAmiRequest())
    yield toast.success(i18n.t('common.toast.changesSaved'), toastDefaultOptions)
  } catch (e) {
    yield put(toggleCallsOrSoundsError(e))
    yield handleError(e)
  }
}

export function* settingsSaga() {
  yield takeLatest(actionTypes.GET_CASE_AUTO_APPROVE_CONTACTS_REQUEST, caseAutoApproveContacts)
  yield takeLatest(
    actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_SEARCH_ACTION,
    caseAutoApproveContactsSearch
  )
  yield takeLatest(actionTypes.SET_CASE_AUTO_APPROVE_SETTINGS_REQUEST, setCaseAutoApproveSettings)
  yield takeLatest(actionTypes.SET_2FA_SETTINGS_REQUEST, set2FASettings)
  yield takeLatest(actionTypes.GET_ACCOUNT_SETTINGS_REQUEST, getAccountSettings)
  yield takeLatest(actionTypes.SET_DEFAULT_ACCOUNT_REQUEST, setDefaultAccount)
  yield takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePasswordSaga)
  yield takeLatest(actionTypes.CHANGE_EMAIL_REQUEST, changeEmailSaga)
  yield takeLatest(
    actionTypes.SET_CASE_AUTO_APPROVE_CONTACTS_CATEGORY,
    setCaseAutoApproveContactsCategorySaga
  )
  yield throttle(
    DEFAULT_THROTTLE_MS,
    actionTypes.TOGGLE_CALL_OR_SOUND_REQUEST,
    toggleCallsOrSoundsSaga
  )
}
