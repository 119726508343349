import { useLayoutEffect, useCallback, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import cls from 'classnames'
import Draggable from 'react-draggable'

import { openBroadcast, useAppDispatch } from 'store'
import { useAdaptiveLayout } from 'App/hooks'
import { TBroadcast } from 'interfaces/api/broadcasts'
import { useVoiceChannelStyles } from 'App/components/Organizations/hooks'

import { BroadcastContext } from '../BroadcastContext'
import { BroadcastOutsidePanel } from '../BroadcastOutsidePanel'

import styles from './BroadcastRoom.module.scss'
import { useBroadcastPlayer } from './useBroadcastPlayer'

type TBroadcastRoomProps = {
  broadcastKey: TBroadcast['key']
}

export const COMMUNITY_BROADCAST_PATH = '/community/broadcast/:broadcastId'
export const COMMUNITIES_BROADCAST_PATH = '/communities/:id/broadcast/:broadcastId'
export const EVENTS_BROADCAST_PATH = '/events/:id/broadcast/:broadcastId'

/**
 * The div with id should be replaced with an iframe;
 * See more https://developers.google.com/youtube/iframe_api_reference?hl=ru#Loading_a_Video_Player
 */
const BROADCAST_PLAYER_ID = 'broadcast-player'
const CANCEL_DRAGGABLE_CLASS_NAME = 'broadcast-cancel-draggable'

export const BroadcastRoom = ({ broadcastKey }: TBroadcastRoomProps) => {
  const dispatch = useAppDispatch()
  const { broadcastRoomContainer } = useContext(BroadcastContext)

  const { isMobile } = useAdaptiveLayout()

  const match = useRouteMatch([
    COMMUNITY_BROADCAST_PATH,
    COMMUNITIES_BROADCAST_PATH,
    EVENTS_BROADCAST_PATH
  ])

  const fixed = match?.isExact

  const handleCloseEvent = useCallback(() => {
    dispatch(openBroadcast({ broadcastId: null, entityId: null }))
  }, [dispatch])

  useBroadcastPlayer({
    videoId: broadcastKey,
    iframePlace: BROADCAST_PLAYER_ID
  })

  const { style } = useVoiceChannelStyles({
    container: broadcastRoomContainer
  })

  useLayoutEffect(() => {
    if (isMobile && !fixed) {
      handleCloseEvent()
    }
  }, [isMobile, handleCloseEvent, fixed])

  return (
    <div
      style={match?.isExact ? style : undefined}
      className={cls(styles.root, { [styles.fixed]: fixed })}
    >
      <Draggable
        bounds="body"
        disabled={isMobile || fixed}
        cancel={`.${CANCEL_DRAGGABLE_CLASS_NAME}`}
        position={isMobile || fixed ? { x: 0, y: 0 } : undefined}
      >
        <div
          className={cls(styles.container, {
            [styles.mobileFullScreen]: isMobile,
            [styles.static]: fixed
          })}
        >
          <BroadcastOutsidePanel
            cancelDraggableClassName={CANCEL_DRAGGABLE_CLASS_NAME}
            onClose={handleCloseEvent}
            hidden={fixed}
          />
          <div className={styles.videoContainer}>
            <div id={BROADCAST_PLAYER_ID} />
          </div>
        </div>
      </Draggable>
    </div>
  )
}
