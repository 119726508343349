import cls from 'classnames'

import { FileName } from 'App/components'

import styles from './FileInformationTitle.module.scss'

export type TFileInformationTitleProps = {
  extension: string
  fileName: string
}

export const FileInformationTitle = ({ extension, fileName }: TFileInformationTitleProps) => (
  <div className={cls({ [styles.root]: true, 'file-information-title': true })}>
    <FileName extension={extension} twoLine fileName={fileName} textClassName={styles.title} />
  </div>
)
