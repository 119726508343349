import { AxiosError } from 'axios'
import { DefaultOptions, QueryClient } from '@tanstack/react-query'

import { handleError } from './api'

export const DEFAULT_QUERY_CLIENT_OPTIONS: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 100 // 0 may cause double fetching
  },
  mutations: {
    retry: false,
    onError: (e) => handleError(e as AxiosError)
  }
}

export const queryClient = new QueryClient({
  defaultOptions: DEFAULT_QUERY_CLIENT_OPTIONS
})
