import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { TCascaderOption, TSearchCascaderProps } from 'App/components'
import { stopPropagation } from 'utils'
import { getOrganizationDepartments } from 'api'
import { TSearchBy } from 'api/contacts'

export type TUseStaffSearchByOptionsProps = {
  onChange: (searchBy: TSearchBy) => void

  organizationId?: string
}

type TUseStaffSearchByOptions = (props: TUseStaffSearchByOptionsProps) => {
  options: TCascaderOption[]
  onCascaderChange: TSearchCascaderProps['onSearchChange']
}

export const useStaffSearchByOptions: TUseStaffSearchByOptions = ({ organizationId, onChange }) => {
  const { t } = useTranslation()

  const { data: departments } = useQuery({
    queryKey: ['organizations-departments', organizationId],
    queryFn: () => (organizationId ? getOrganizationDepartments({ organizationId }) : undefined)
  })

  const options = useMemo<TCascaderOption[]>(
    () => [
      {
        value: 'allStaff',
        label: (
          <span className="cascader-default">
            {t('contacts.contactsSearchByOptions.all_staff')}
          </span>
        )
      },
      {
        value: 'departments',
        label: t('contacts.contactsSearchByOptions.noDepartmentsPlaceholder'),
        children: !!departments?.results.length
          ? departments.results.map(({ id, name }) => ({
              value: id,
              label: name
            }))
          : [
              {
                value: t('contacts.contactsSearchByOptions.departmentsPlaceholder'),
                disabled: true,
                label: (
                  <span onClick={stopPropagation} className="cascader-placeholder">
                    {t('contacts.contactsSearchByOptions.noDepartmentsPlaceholder')}
                  </span>
                )
              }
            ]
      }
    ],
    [departments?.results, t]
  )

  const handleChange = useCallback(
    (_: string, searchByValue: (string | number)[]) => {
      const searchByLength = searchByValue.length

      const group =
        searchByLength > 1 ? searchByValue[searchByLength - 2] : searchByValue[searchByLength - 1]
      const id = searchByLength > 1 ? searchByValue[searchByLength - 1] : ''

      onChange({
        group: group.toString(),
        id: id.toString()
      })
    },
    [onChange]
  )

  return { options, onCascaderChange: handleChange }
}
