import { AccountTypeNames } from '@medentee/enums'

import { EAvatarSize } from 'enums'
import { Avatar, TruncatedText } from 'App/components'

import styles from './ReceivedCallDialogue.module.scss'

export type TReceivedCallDialogueProps = {
  offererUserId: string
  lastName: string
  firstName: string
  displayUserName: string
  type: AccountTypeNames
}

export const ReceivedCallDialogue = ({
  lastName,
  firstName,
  offererUserId,
  displayUserName,
  type
}: TReceivedCallDialogueProps) => (
  <>
    <div className={styles.avatar}>
      <Avatar
        hasTooltip={false}
        size={EAvatarSize.LG}
        userId={offererUserId}
        firstName={firstName}
        lastName={lastName}
        displayUserName={displayUserName}
        type={type}
        border={true}
      />
    </div>
    <div className={styles.userName}>
      <TruncatedText classes={{ text: styles.truncatedTitle }} text={displayUserName} />
    </div>
  </>
)
