import { useCallback, useEffect } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { generateRandomArray } from 'utils'
import { getCaseCountRequest } from 'store'
import { CasesInviteItemContainer, TInvitationCasesPendingContainerProps } from 'App/containers'
import { Pagination, EmptyList } from 'App/components'
import { useRefreshNotification } from 'App/hooks'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'

import styles from './CasePendingList.module.scss'

export type TCasePendingListProps = {
  getCaseCount?: typeof getCaseCountRequest
} & TInvitationCasesPendingContainerProps

export const CasePendingList = ({
  casesInvitationIds,
  isLoading = false,
  current,
  showBy,
  total,
  loadCasesInviteList,
  resetCaseInviteList
}: TCasePendingListProps) => {
  const { t } = useTranslation()

  const cases = isLoading ? generateRandomArray(showBy) : casesInvitationIds

  const { removeToast } = useRefreshNotification({
    onRefresh: loadCasesInviteList,
    message: t('common.toast.newInvitations')
  })

  useEffect(() => {
    loadCasesInviteList()

    return () => {
      resetCaseInviteList()
    }
  }, [loadCasesInviteList, resetCaseInviteList])

  const handlePaginationChange = useCallback(
    (newPage: number, newPageSize?: number) => {
      removeToast()
      loadCasesInviteList({
        showBy: newPageSize,
        current: newPage
      })
    },
    [loadCasesInviteList, removeToast]
  )

  return (
    <>
      {cases.length ? (
        <>
          <div className={cls({ [styles.list]: true, [styles.listSkeleton]: isLoading })}>
            {cases.map((caseId: string) => (
              <Skeleton
                key={caseId}
                className={styles.skeleton}
                loading={isLoading}
                avatar={true}
                active={true}
              >
                <CasesInviteItemContainer id={caseId} />
              </Skeleton>
            ))}
          </div>

          <Pagination
            total={total}
            pageSize={showBy}
            current={current}
            onChange={handlePaginationChange}
            wrapperClassName={styles.pagination}
          />
        </>
      ) : (
        <div className={styles.listEmpty}>
          <EmptyList
            icon={<CaseIcon className={styles.listEmptyIcon} />}
            text={t('cases.invitations.placeholder')}
          />
        </div>
      )}
    </>
  )
}
