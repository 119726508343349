import { PropsWithChildren } from 'react'
import { LinkProps, NavLink } from 'react-router-dom'
import { AccountTypeNames, BroadcastStatusEnum } from '@medentee/enums'

import { EAvatarSize } from 'enums'
import { Avatar } from 'App/components/common'

import { TUnityHubNavType } from '../UnityHubNav'
import { BroadcastBadge } from '../../../Broadcasts/BroadcastNavItem/BroadcastBadge'

import styles from './UnityHubNavItem.module.scss'

export type TUnityHubNavItemProps = Pick<LinkProps, 'to'> &
  PropsWithChildren<{
    id?: string
    name?: string
    live?: boolean
    type?: TUnityHubNavType
  }>

export const UnityHubNavItem = ({ id, name, to, children, type, live }: TUnityHubNavItemProps) => (
  <NavLink key={id} className={styles.root} to={to} activeClassName={styles.active}>
    {children}
    {live && (
      <div className={styles.live}>
        <BroadcastBadge status={BroadcastStatusEnum.LIVE} />
      </div>
    )}

    {!children && name && id && (
      <Avatar
        firstName=""
        lastName=""
        size={EAvatarSize.LMD}
        isCommunity={type === 'community'}
        isEvent={type === 'event'}
        userId={id}
        type={AccountTypeNames.BUSINESS}
        displayUserName={name}
      />
    )}
  </NavLink>
)
