import { TShowcasesAction, TShowcasesState } from './showcases.types'
import * as actionTypes from './showcases.actionTypes'

const initialShowcasesState: TShowcasesState = {
  commentCounts: {}
}

export const showcasesReducer = (
  state: TShowcasesState = initialShowcasesState,
  action: TShowcasesAction
): TShowcasesState => {
  switch (action.type) {
    case actionTypes.GET_SHOWCASE_COMMENT_COUNTERS_SUCCESS: {
      const { showcaseId, total, list, ids } = action.payload

      return {
        ...state,
        commentCounts: {
          ...state.commentCounts,
          [showcaseId]: {
            ...state.commentCounts[showcaseId],
            total,
            list,
            ids
          }
        }
      }
    }

    default:
      return state
  }
}
