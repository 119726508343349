import cls from 'classnames'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { EFileUploadStatus } from 'enums'
import { getMapComponent } from 'utils'
import { UploadingProgress } from 'App/components/common/UploadingProgress'
import { ReactComponent as CircleOutlineIcon } from 'assets/icons/CircleOutline.svg'
import { ReactComponent as AlertCircleOutlineIcon } from 'assets/icons/AlertCircleOutline.svg'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'

import styles from './Status.module.scss'

type TStatusProps = {
  status: EFileUploadStatus

  shouldShowLabel?: boolean
  uploadPercentage?: number
}

type TStatusIconMapProps = Pick<TStatusProps, 'uploadPercentage' | 'shouldShowLabel'> & {
  t: TFunction
}

const STATUS_ICON_MAP = new Map<EFileUploadStatus, (props: TStatusIconMapProps) => JSX.Element>()
  .set(EFileUploadStatus.PENDING, () => (
    <CircleOutlineIcon className={cls(styles.icon, styles.iconPending)} />
  ))
  .set(EFileUploadStatus.SUCCESS, () => (
    <CheckCircleOutlineIcon className={cls(styles.icon, styles.iconSuccess)} />
  ))
  .set(EFileUploadStatus.CANCELLED, ({ shouldShowLabel, t }) => (
    <>
      {shouldShowLabel && (
        <span className={styles.alert}>{t('common.uploadWidget.status_canceled')}</span>
      )}{' '}
      <AlertCircleOutlineIcon className={cls(styles.icon, styles.iconAlert)} />
    </>
  ))
  .set(EFileUploadStatus.FAIL, ({ shouldShowLabel, t }) => (
    <>
      {shouldShowLabel && (
        <span className={styles.alert}>{t('common.uploadWidget.status_failed')}</span>
      )}{' '}
      <AlertCircleOutlineIcon className={cls(styles.icon, styles.iconAlert)} />
    </>
  ))
  .set(EFileUploadStatus.PROGRESS, ({ uploadPercentage }) => (
    <UploadingProgress percent={uploadPercentage} />
  ))

export const Status = ({ status, uploadPercentage, shouldShowLabel = true }: TStatusProps) => {
  const { t } = useTranslation()

  return getMapComponent(STATUS_ICON_MAP, status, { uploadPercentage, shouldShowLabel, t })
}
