import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import {
  createProcessingSelector,
  removeWorkExperienceRequest,
  REMOVE_WORK_EXPERIENCE
} from 'store'

export type TWorkExperienceRemoveDialogProps = {
  id: string
}

const processingRequest = createProcessingSelector([REMOVE_WORK_EXPERIENCE])

export const WorkExperienceRemoveDialog = ({ id }: TWorkExperienceRemoveDialogProps) => {
  const dispatch = useDispatch()

  const processing = useSelector(processingRequest)

  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(removeWorkExperienceRequest({ id }))
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Row justify="center">
          <span>{t('modal.removeWorkplaceConfirm.content')}</span>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Button loading={processing} onClick={handleClick}>
            {t('modal.removeWorkplaceConfirm.submitButton')}
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
