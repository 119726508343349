import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, CropAvatar, TCropAvatarProps } from 'App/components/index'

import styles from './UploadImageDialog.module.scss'

export type TUploadImageDialogProps = Pick<
  TCropAvatarProps,
  'imageSrc' | 'initialCrop' | 'circularCrop' | 'allowBlank'
> & {
  onCrop: (file: File) => void

  buttonContent?: ReactNode
  content?: ReactNode
}

export const UploadImageDialog = ({
  imageSrc,
  initialCrop,
  content,
  circularCrop,
  allowBlank,
  onCrop,
  buttonContent
}: TUploadImageDialogProps) => {
  const { t } = useTranslation()

  const [croppedFile, setCroppedFile] = useState<File | null>(null)

  const handleClick = () => {
    if (croppedFile) {
      onCrop(croppedFile)
    }
  }

  return (
    <div className={styles.root}>
      {content}

      <CropAvatar
        imageSrc={imageSrc}
        initialCrop={initialCrop}
        circularCrop={circularCrop}
        allowBlank={allowBlank}
        onCrop={setCroppedFile}
      />

      <Button onClick={handleClick}>{buttonContent ?? t('modal.uploadImage.submitButton')}</Button>
    </div>
  )
}
