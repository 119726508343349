import { useTranslation } from 'react-i18next'

import { TAutoPaymentDTO } from 'interfaces'
import { showModalAction, useAppDispatch } from 'store'

import { Button, ComingSoon } from '../../../common'
import { EModalComponents } from '../../../../containers'

export const MonthlyTopUpControls = ({ data }: { data?: TAutoPaymentDTO }) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const showCancelModal = () =>
    dispatch(
      showModalAction({
        modalTitle: t('modal.cancelMonthlyTopUpConfirm.title'),
        modalType: EModalComponents.TOP_UP_MONTHLY_CANCEL
      })
    )

  return data && data?.status !== 'canceled' ? (
    <Button color="error" variant="underlined" onClick={showCancelModal}>
      {t('payments.ballance.monthlyTopUp.cancelButton')}
    </Button>
  ) : (
    <ComingSoon>
      <Button
        disabled
        onClick={() => {
          dispatch(
            showModalAction({
              modalTitle: t('modal.monthlyTimeTopUp.title'),
              modalType: EModalComponents.TOP_UP_MONTHLY,
              modalWidth: 400
            })
          )
        }}
      >
        {t('payments.ballance.monthlyTopUp.setUpButton')}
      </Button>
    </ComingSoon>
  )
}
