import { useQuery } from 'services/query'
import { Spinner } from 'App/components'
import { handleError } from 'api'
import { getBroadcastJoinTokenInfo } from 'api/broadcasts'

import { useEventJoinViaLinkCore } from '../useEventJoinViaLinkCore'

const QUERY_KEY = 'get-event-join-to-broadcast-token-info'

export const JoinViaLinkToEventBroadcast = () => {
  const { token, isNotProfessional, redirectToDefaultPage, redirectToEvent } =
    useEventJoinViaLinkCore({ variant: 'broadcast' })

  const { isFetching } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getBroadcastJoinTokenInfo(token),
    enabled: !isNotProfessional,
    onSuccess: ({ name, isMember, id: channelId }) =>
      redirectToEvent({
        title: name,
        channelId,
        isMember
      }),
    onError: (err) => {
      handleError(err)
      redirectToDefaultPage()
    }
  })

  if (isFetching) {
    return <Spinner contentCenter />
  }

  return null
}
