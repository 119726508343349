import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'

type TRedirectToSubscriptionPageProps = {
  isBusinessAccount?: boolean
}

export const RedirectToSubscriptionPage = ({
  isBusinessAccount
}: TRedirectToSubscriptionPageProps) => {
  const { push } = useHistory()

  const { t } = useTranslation()

  const handleButtonClick = () => {
    push('/subscription-and-payments/subscription')
  }

  return (
    <ConfirmationModal
      content={
        isBusinessAccount
          ? t('modal.unavailableFeature.content_business')
          : t('modal.unavailableFeature.content')
      }
      controls={
        <Button onClick={handleButtonClick}>{t('modal.unavailableFeature.submitButton')}</Button>
      }
    />
  )
}
