import { normalize, schema } from 'normalizr'

import { TSubscriptionsNormalized, TSubscriptionsNormalizedResult } from './subscriptions.types'

const subscriptions = new schema.Entity(
  'list',
  {},
  {
    idAttribute: (value) => (value.subscription ? value.subscription.id : value.id)
  }
)
const subscriptionsListSchema = new schema.Array(subscriptions)

export const subscriptionsNormalize = <T>(originalData: T[]): TSubscriptionsNormalized<T> => {
  const { entities, result }: TSubscriptionsNormalizedResult<T> = normalize(
    originalData,
    subscriptionsListSchema
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}
