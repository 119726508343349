import { TAction } from 'store/store.utils'

import { CASE_CREATE_ERROR, CASE_CREATE_REQUEST, CASE_CREATE_SUCCESS } from './caseCreate.actions'
import { TCaseCreateError, TCaseCreateState } from './caseCreate.types'

export const initialCaseCreateState: TCaseCreateState = {}

export const caseCreateReducer = (state = initialCaseCreateState, action: TAction) => {
  switch (action.type) {
    case CASE_CREATE_REQUEST:
    case CASE_CREATE_SUCCESS: {
      return {
        ...state,
        error: null
      }
    }

    case CASE_CREATE_ERROR: {
      return {
        ...state,
        error: (action as TCaseCreateError).payload
      }
    }

    default:
      return state
  }
}
