import { useCallback } from 'react'
import noop from 'lodash/noop'

import { useMutation } from 'services/query'
import { ITopUpOneTimeCheckoutInfoDTO } from 'interfaces'
import { showModalAction, useAppDispatch } from 'store'
import { EModalComponents } from 'App/containers'
import { topUpMonthly } from 'api'

import { ESocketNameSpaces, sockets } from '../../../../../services/webSocket'

export const useMonthlyTopUpRequest = () => {
  const dispatch = useAppDispatch()

  const handleSuccess = useCallback(
    ({ url }: ITopUpOneTimeCheckoutInfoDTO) => {
      window.open(url, '_blank')

      dispatch(
        showModalAction({
          modalType: EModalComponents.TOP_UP_HOLD_ON,
          modalTitle: null,
          closable: false
        })
      )
    },
    [dispatch]
  )

  const {
    mutate,
    isLoading: processing,
    error
  } = useMutation({
    mutationKey: ['top-up-monthly'],
    mutationFn: () =>
      topUpMonthly({ socketSessionId: sockets[ESocketNameSpaces.SYSTEM]?.id ?? '' }),
    onSuccess: handleSuccess,
    // disable default toast notification
    onError: noop
  })

  return {
    mutate,
    processing,
    error
  }
}
