import { memo } from 'react'
import cls from 'classnames'

import { useOutputLevel } from './useOutputLevel'
import styles from './MicrophoneEntry.module.scss'

export type TMicrophoneEntryProps = PropsWithClassName<{
  label: string
  kind: string
}>

const PID_COUNT = 8
const PID_ELEMENTS = Array.from({ length: PID_COUNT })

const MicrophoneEntryView = ({ label, kind, className }: TMicrophoneEntryProps) => {
  const { hide, level } = useOutputLevel(label, kind, PID_COUNT)

  return hide ? null : (
    <div className={cls(styles.root, className)}>
      {PID_ELEMENTS.map((_, index) => (
        <div key={index} className={cls(styles.pid, level.includes(index) && styles.pidActive)} />
      ))}
    </div>
  )
}

export const MicrophoneEntry = memo(MicrophoneEntryView)
