export enum ESteps {
  SELECT_MEMBERS = 0,
  CREATE = 1,
  EDIT = 2
}

export const STEP_KEYS = {
  0: 'SELECT_MEMBERS',
  1: 'CREATE',
  2: 'EDIT'
} as const
