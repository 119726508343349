import { ReactNode } from 'react'
import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { CurrentBalanceContainer } from 'App/containers'
import { TruncatedText } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as PaymentIcon } from 'assets/icons/Payment.svg'

import { OneTimeTopUpButton } from '../TopUp/OneTimeTopUpButton'

import styles from './Balance.module.scss'

type TBalanceProps = {
  children?: ReactNode
  displayUserName?: string
  accountType?: AccountTypeNames
}

export const Balance = ({ displayUserName = '', accountType, children }: TBalanceProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {!isMobile && (
            <div className={styles.iconWrapper}>
              <PaymentIcon className={styles.icon} />
            </div>
          )}

          <div className={styles.balanceWrapper}>
            <div className={styles.usernameWrapper}>
              <TruncatedText classes={{ text: styles.username }} text={displayUserName} />
            </div>
            {accountType && (
              <span className={styles.description}>{t(`payments.accountType.${accountType}`)}</span>
            )}
          </div>

          <div className={styles.end}>
            <OneTimeTopUpButton className={styles.topUpButton} />
            <div className={styles.amountWrapper}>
              <span className={cls(styles.description, styles.descriptionBalance)}>
                {t('payments.ballance.yourBallanceLabel')}
              </span>
              <div className={styles.amount}>
                <CurrentBalanceContainer />
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
