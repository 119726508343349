import cls from 'classnames'

import {
  ETableColumnFilters,
  FilterPopoverFooter,
  RangePicker,
  TFilterDropdownProps,
  TRangePickerValue,
  TSelectedKeysOptions
} from 'App/components'
import { CaseHistoryFilterModalContainerProps } from 'App/containers'

import { CaseHistoryFilterItem } from './CaseHistoryFilterItem'
import styles from './CaseHistoryFilter.module.scss'

export type TCaseHistoryFilter<T extends TSelectedKeysOptions> = TFilterDropdownProps<T> &
  Partial<CaseHistoryFilterModalContainerProps>

export const CaseHistoryFilter = ({
  selectedKeys,
  type,
  filters,
  setSelectedKeys,
  confirm,
  clearFilters
}: TCaseHistoryFilter<TSelectedKeysOptions>) => {
  const isDateFilter = type === ETableColumnFilters.DATE_AND_TIME

  return (
    <div
      className={cls({
        [styles.root]: true,
        [styles.rootDate]: isDateFilter
      })}
    >
      {isDateFilter ? (
        <RangePicker value={selectedKeys as TRangePickerValue} onChange={setSelectedKeys} />
      ) : (
        <div className={styles.list}>
          {filters?.map((filter) => (
            <CaseHistoryFilterItem
              key={filter.text}
              setSelectedKeys={setSelectedKeys}
              filterValue={filter.value}
              filterText={filter.text}
              selectedKeys={selectedKeys as string[]}
            />
          ))}
        </div>
      )}

      <FilterPopoverFooter
        className={cls({
          [styles.buttons]: true,
          [styles.buttonsDate]: isDateFilter
        })}
        onConfirm={confirm}
        onReset={clearFilters}
        disableReset={!selectedKeys?.length}
      />
    </div>
  )
}
