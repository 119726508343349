import { TPaymentsAction, TPaymentsState } from 'store'

import * as actionTypes from './payments.actionTypes'

export const initialPaymentsState: TPaymentsState = {
  balance: {
    amount: null
  },
  history: {
    data: [],
    total: 0,
    page: 0,
    filters: {
      dateRange: null,
      operationType: []
    }
  }
}

export const paymentsReducer = (
  state: TPaymentsState = initialPaymentsState,
  action: TPaymentsAction
): TPaymentsState => {
  switch (action.type) {
    case actionTypes.GET_USER_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: action.payload
      }
    }

    case actionTypes.GET_OPERATIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        history: {
          ...state.history,
          ...action.payload
        }
      }
    }

    case actionTypes.SET_OPERATIONS_HISTORY_FILTER: {
      const filters = action.payload
        ? {
            ...state.history.filters,
            ...action.payload
          }
        : initialPaymentsState.history.filters

      return {
        ...state,
        history: {
          ...state.history,
          filters
        }
      }
    }

    case actionTypes.CLEAR_OPERATIONS_HISTORY: {
      return {
        ...state,
        history: initialPaymentsState.history
      }
    }

    default:
      return state
  }
}
