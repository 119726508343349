import { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { AccountTypeNames, ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import { SettingsInfoChangeEmailConfirm, TwoFactorConfirm } from 'App/components'
import {
  changeEmailError,
  changeEmailRequest,
  CHANGE_EMAIL,
  clearChangeEmailAction,
  createProcessingSelector,
  hideModalAction
} from 'store'
import { parseError, isMatchErrorCode } from 'utils'

export type TSettingsInfoChangeEmailConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const SettingsInfoChangeEmailConfirmView = ({
  hasConnectedAccount,
  error,
  changeEmail,
  firstExpiredTokenDate,
  secondExpiredTokenDate,
  processing,
  clearError,
  clearChangeEmail,
  email,
  first2FAStepFinished,
  accountType,
  hideModal
}: TSettingsInfoChangeEmailConfirmContainerProps) => {
  const { t } = useTranslation()

  const [show2FA, setShow2FA] = useState(
    accountType === AccountTypeNames.BUSINESS || !hasConnectedAccount
  )
  const [showLimitExpired, setShowLimitExpired] = useState(false)

  const handleAcceptClick = useCallback(() => setShow2FA(true), [])

  const limitExpired = isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)

  useEffect(
    () => () => {
      clearError(null)
    },
    [clearError]
  )

  useEffect(() => {
    if (limitExpired && !first2FAStepFinished && !firstExpiredTokenDate) {
      setShow2FA(false)
      setShowLimitExpired(true)
    }
  }, [first2FAStepFinished, firstExpiredTokenDate, limitExpired])

  if (
    hasConnectedAccount &&
    accountType !== AccountTypeNames.BUSINESS &&
    !show2FA &&
    !showLimitExpired
  ) {
    return <TwoFactorConfirm onClick={handleAcceptClick} error={error} />
  }

  if (showLimitExpired && !show2FA) {
    return (
      <TwoFactorConfirm
        buttonText={t('common.2FA.confirm.submitButton_gotIt')}
        title={parseError(error)}
        onClick={hideModal}
      />
    )
  }

  return !showLimitExpired && show2FA && email ? (
    <SettingsInfoChangeEmailConfirm
      error={error}
      changeEmail={changeEmail}
      firstExpiredTokenDate={firstExpiredTokenDate}
      secondExpiredTokenDate={secondExpiredTokenDate}
      processing={processing}
      clearError={clearError}
      clearChangeEmail={clearChangeEmail}
      email={email}
      first2FAStepFinished={first2FAStepFinished}
    />
  ) : null
}

const processingSelector = createProcessingSelector([CHANGE_EMAIL])

const mapStateToProps = (state: State) => ({
  accountType: state.global.accountData?.type.name,
  hasConnectedAccount: state.global.accountData?.hasConnectedAccount ?? false,
  email: state.global.accountData?.email,
  processing: processingSelector(state),
  firstExpiredTokenDate: state.settings.info.email.firstExpiredTokenDate,
  secondExpiredTokenDate: state.settings.info.email.secondExpiredTokenDate,
  first2FAStepFinished: state.settings.info.email.first2FAStepFinished,
  error: state.errors[CHANGE_EMAIL]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changeEmail: changeEmailRequest,
      clearError: changeEmailError,
      hideModal: hideModalAction,
      clearChangeEmail: clearChangeEmailAction
    },
    dispatch
  )

export const SettingsInfoChangeEmailConfirmContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsInfoChangeEmailConfirmView)
