import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { accountIdSelector, useAppSelector } from 'store'
import { Chip, ShowMore } from 'App/components/common'
import { UserProfileDetailsItem } from 'App/components/UserProfile/UserProfileDetailsItem'
import { ReactComponent as LocationOutlineIcon } from 'assets/icons/LocationOutline.svg'
import { TEventAttendee } from 'interfaces/api/events'
import { UnityHubNetworkingDrawerItem } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerItem'
import { UnityHubNetworkingDrawerContent } from 'App/components/UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerContent'

import { useEventNetworkingWorkInformation } from '../../EventNetworkingWorkInformation'
import {
  EventNetworkingFavoriteToggle,
  TEventNetworkingFavoriteToggleProps
} from '../../EventNetworkingFavoriteToggle'

import styles from './EventNetworkingDrawerContent.module.scss'

export type TEventNetworkingDrawerContentProps = Pick<
  TEventNetworkingFavoriteToggleProps,
  'isFavorite'
> & {
  eventId: string
  attendee: TEventAttendee
  onSuccess: () => void
  onToggleFavorite: TEventNetworkingFavoriteToggleProps['onSuccess']
}

export const EventNetworkingDrawerContent = ({
  attendee,
  eventId,
  isFavorite,
  onSuccess,
  onToggleFavorite
}: TEventNetworkingDrawerContentProps) => {
  const accountId = useAppSelector(accountIdSelector)

  const { push } = useHistory()

  const { t } = useTranslation()

  const isYourAccount = accountId === attendee.account.id
  const {
    account: { city, country, professions },
    contact,
    about,
    openForNetworking,
    interests,
    company,
    position,
    id: attendeeId
  } = attendee

  const visibleHeight = styles.visibilityHeight

  const shouldShowProfileButton = isYourAccount || contact?.status === ContactStatusEnum.APPROVED

  const { EventNetworkingWorkInformation, shouldShowCompany, shouldShowProfessions } =
    useEventNetworkingWorkInformation({
      company,
      openForNetworking,
      position,
      professions
    })

  const handleEditEventProfile = () => {
    push(`/events/${eventId}/highlights/profile`)
  }

  return (
    <UnityHubNetworkingDrawerContent
      account={{ ...attendee.account, type: attendee.account.type.name }}
      contact={attendee.contact}
      chatId={attendee.chatId}
      profileButtonLabel={t('events.networking.attendee.drawer.viewFullProfileButton')}
      editProfileButtonLabel={t('events.networking.attendee.drawer.editEventProfileButton')}
      shouldShowProfileButton={shouldShowProfileButton}
      shouldShowEditProfileButton={isYourAccount}
      avatarProps={{ eventId, userId: attendee.id, isEventProfile: true }}
      actions={
        !isYourAccount && (
          <EventNetworkingFavoriteToggle
            variant="chip"
            eventId={eventId}
            attendeeId={attendeeId}
            isFavorite={isFavorite}
            onSuccess={onToggleFavorite}
          />
        )
      }
      details={
        <>
          <UnityHubNetworkingDrawerItem>
            <UserProfileDetailsItem
              variant="text"
              icon={<LocationOutlineIcon />}
              text={`${country.countryName}, ${city?.cityName}`}
            />
          </UnityHubNetworkingDrawerItem>

          {(shouldShowProfessions || shouldShowCompany || openForNetworking) && (
            <UnityHubNetworkingDrawerItem>
              <div className={styles.workInformation}>
                <EventNetworkingWorkInformation />
                {openForNetworking && (
                  <Chip
                    text={t('events.networking.attendee.openForNetworking')}
                    variant="success"
                    className={styles.openForNetworking}
                  />
                )}
              </div>
            </UnityHubNetworkingDrawerItem>
          )}

          {about && (
            <UnityHubNetworkingDrawerItem
              title={t('events.networking.attendee.drawer.section.about')}
            >
              <ShowMore
                text={about}
                classes={{ wrapper: styles.about }}
                visibleHeight={Number(visibleHeight)}
                limitExpandedHeight={false}
              />
            </UnityHubNetworkingDrawerItem>
          )}

          {!!interests?.length && (
            <UnityHubNetworkingDrawerItem
              title={t('events.networking.attendee.drawer.section.interests')}
            >
              <div className={styles.interests}>
                {interests.map((item) => (
                  <Chip key={item.id} variant="secondary" text={item.name} />
                ))}
              </div>
            </UnityHubNetworkingDrawerItem>
          )}
        </>
      }
      onSuccess={onSuccess}
      onEditProfile={handleEditEventProfile}
    />
  )
}
