import { SettingsList } from 'App/components'
import {
  SettingsPersonalInfoEmailContainer,
  SettingsPersonalInfoFullNameContainer
} from 'App/containers'

export const SettingsPersonalInfo = () => (
  <SettingsList>
    <>
      <SettingsPersonalInfoFullNameContainer />
      <SettingsPersonalInfoEmailContainer />
    </>
  </SettingsList>
)
