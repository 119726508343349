import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  getWorkExperienceRequest,
  GET_WORK_EXPERIENCE,
  useAppDispatch,
  useAppSelector
} from 'store'
import { generateRandomArray } from 'utils'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { ReactComponent as BriefcaseOutlineIcon } from 'assets/icons/BriefcaseOutline.svg'

import { List } from '../../common'
import { WorkExperienceItem } from '../WorkExperienceItem'
import { UserProfileWorkExperienceButton } from '../../UserProfileWorkExperience'

const loadingSelector = createLoadingSelector([GET_WORK_EXPERIENCE])

export const WorkExperienceList = () => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(loadingSelector)
  const workExperienceIds = useAppSelector(
    (state) => state.userProfile.viewProfile.workExperience.ids
  )

  const { t } = useTranslation()

  const ids = loading ? generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH) : workExperienceIds

  useEffect(() => {
    dispatch(getWorkExperienceRequest())
  }, [dispatch])

  return (
    <List
      loading={loading}
      ids={ids}
      item={WorkExperienceItem}
      footer={<UserProfileWorkExperienceButton />}
      emptyIcon={<BriefcaseOutlineIcon />}
      emptyText={t('userProfile.workplaces.placeholder')}
    />
  )
}
