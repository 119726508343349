import { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useQuery, useIsMutating } from 'services/query'
import { AccountsSelect, TruncatedText } from 'App/components/common'
import { getOrganizationMembers } from 'api/organizations'
import { useSelectAccounts, TUseSelectAccountsProps } from 'App/hooks'
import {
  useFetchDepartmentStaff,
  TUseFetchDepartmentStaffProps
} from 'App/hooks/Organizations/useFetchDepartmentStaff'
import { accountIdSelector, useAppSelector } from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

import { UPDATE_DEPARTMENT_MUTATION_KEY } from '../../StaffTab/StaffTabDepartmentItem/useKebabMenu'

import styles from './ManageDepartmentStaffModal.module.scss'

export type TManageDepartmentStaffModalProps = PartialBy<
  Pick<TUseSelectAccountsProps, 'accounts' | 'onSubmit'>,
  'accounts'
> &
  Pick<TUseFetchDepartmentStaffProps, 'shouldFetchStaff'> & {
    organizationId: string

    departmentId?: string
    departmentName?: string
  }

export const ManageDepartmentStaffModal = ({
  organizationId,
  onSubmit,
  departmentId,
  shouldFetchStaff,
  departmentName,
  accounts = []
}: TManageDepartmentStaffModalProps) => {
  const submitLoading = useIsMutating({ mutationKey: [UPDATE_DEPARTMENT_MUTATION_KEY] })

  const { isSuccess, items, isLoading } = useFetchDepartmentStaff({
    departmentId,
    organizationId,
    shouldFetchStaff
  })

  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const staff = useMemo(
    () =>
      shouldFetchStaff
        ? items.map<TAccountsSelectOptionData>(({ account }) =>
            account.id === accountId ? { ...account, disabled: true } : account
          )
        : accounts,
    [accountId, accounts, items, shouldFetchStaff]
  )

  const {
    state: { page, search, selectedAccounts },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({ onSubmit, accounts: staff })

  useEffect(() => {
    if (shouldFetchStaff && isSuccess && !selectedAccounts.length) {
      onSelectAll(staff)
    }
  }, [isSuccess, onSelectAll, selectedAccounts.length, shouldFetchStaff, staff])

  const { data, isFetching } = useQuery({
    queryKey: ['organization-staff', search, page],
    queryFn: () => getOrganizationMembers({ organizationId, search, page }),
    keepPreviousData: true,
    select: (response) => ({
      ...response,
      data: {
        ...response,
        results: response.results.map((item) => item.account)
      }
    }),
    cacheTime: 0
  })

  const submitDisabled = !!submitLoading || isFetching

  const getAlert = () =>
    departmentName ? (
      <div className={styles.alert}>
        <Trans
          t={t}
          i18nKey="modal.manageDepartmentStaff.alert"
          components={{
            name: <TruncatedText text={departmentName} wrapperString={`"`} />
          }}
        />
      </div>
    ) : null

  return (
    <AccountsSelect
      items={data?.data.results ?? []}
      selectedItems={selectedAccounts}
      search={search}
      page={page}
      total={data?.data?.total}
      loading={isFetching || isLoading}
      submitLoading={!!submitLoading}
      submitDisabled={submitDisabled}
      autoOnline={true}
      alert={getAlert()}
      searchPlaceholder={t('modal.manageDepartmentStaff.searchPlaceholder')}
      emptyListText={t('modal.manageDepartmentStaff.placeholder')}
      emptyContentText={t('modal.manageDepartmentStaff.selectedListPlaceholder')}
      submitLabel={t('modal.manageDepartmentStaff.submitButton')}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
    />
  )
}
