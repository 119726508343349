import { useMemo } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AccountTypeNames } from '@medentee/enums'

import { CaseOpinionsItem, TCaseOpinionsItemProps } from 'App/components'
import { State } from 'redux/rootReducer'
import { createLoadingSelector, GET_CASE_OPINIONS_BY_MEMBER, TCaseParams } from 'store'
import { ECaseAccountType } from 'enums'
import { ICaseMember } from 'interfaces'

export type TCaseOwnerOpinionsItemContainerProps = {
  id: string
} & Pick<TCaseOpinionsItemProps, 'newDividerLineRef'>

type TCaseOwnerOpinionsItemView = Pick<
  TCaseOpinionsItemProps,
  | 'data'
  | 'createdAt'
  | 'loading'
  | 'newDividerLineRef'
  | 'showDividerLine'
  | 'caseType'
  | 'publicOpinions'
  | 'opinionCreator'
>

const CaseOwnerOpinionsItemContainerView = (props: TCaseOwnerOpinionsItemView) => {
  const { who } = useParams<TCaseParams>()
  const isOwner = useMemo(() => who === ECaseAccountType.OWNER, [who])

  return <CaseOpinionsItem isOwner={isOwner} {...props} />
}

const loadingSelector = createLoadingSelector([GET_CASE_OPINIONS_BY_MEMBER])

const mapStateToProps = (
  state: State,
  { id, newDividerLineRef }: TCaseOwnerOpinionsItemContainerProps
): TCaseOwnerOpinionsItemView => {
  const { data, createdAt, memberId, displayMemberName } =
    state.caseDetails.opinions.opinionsList[id] || {}

  const { account } =
    state.caseView.data?.members?.find((member: ICaseMember) => member.id === memberId) || {}

  const opinionCreator = account?.type.name === AccountTypeNames.BUSINESS ? displayMemberName : ''

  return {
    data,
    createdAt,
    loading: loadingSelector(state),
    newDividerLineRef,
    showDividerLine: state.caseDetails.opinions.newLineOpinionId === id,
    publicOpinions: state.caseView.data?.publicOpinions,
    caseType: state.caseView.data?.type,
    opinionCreator
  }
}

export const CaseOwnerOpinionsItemContainer = connect(mapStateToProps)(
  CaseOwnerOpinionsItemContainerView
)
