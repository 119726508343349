import { useEffect } from 'react'

import { ChatRoomCase } from 'App/components'
import { TCaseMemberChatContainerProps } from 'App/containers'

export type TCaseMemberChatProps = NonNullableBy<
  Pick<TCaseMemberChatContainerProps, 'caseView' | 'selectChat' | 'chatRoomId'>,
  'chatRoomId'
>

export const CaseMemberChat = ({ chatRoomId, caseView, selectChat }: TCaseMemberChatProps) => {
  useEffect(() => {
    selectChat({ nextChatId: chatRoomId })
  }, [chatRoomId, selectChat])

  return <ChatRoomCase caseView={caseView} activeChatId={chatRoomId} />
}
