import { useMemo, useState } from 'react'
import { Table, Skeleton } from 'antd'
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { SortingTable } from 'types'
import { formatBytes, formatShortDate, stopPropagation } from 'utils'
import { TRASH_BIN_DEFAULT_PAGINATION, TIds, showExtendableCardAction } from 'store'
import {
  ContextMenu,
  FileName,
  FileViewToggle,
  HeadContextMenu,
  TableRow,
  TableTitle,
  TFileViewToggleProps,
  THeadContextMenuProps,
  TUseSelection,
  useColumnSorting
} from 'App/components'
import { VideoReadinessStatus } from 'App/containers'
import { getSelectedFiles } from 'utils/files'
import { useListContextMenu } from 'App/hooks'

import { TDataSource } from '../TrashBin'
import { TUseKebabMenu } from '../useKebabMenu'

import styles from './TableView.module.scss'

export type TTableViewProps = Omit<TUseSelection, 'setSelectMode'> &
  TUseKebabMenu &
  Pick<THeadContextMenuProps, 'filesSize'> & {
    dataSource: TDataSource[]
    view: TFileViewToggleProps['variant']
    onViewChange: TFileViewToggleProps['onChange']
    sortColumnHandler: ReturnType<typeof useColumnSorting>
    setSelectedIds: (ids: TIds) => void
    showExtendableCard: typeof showExtendableCardAction
    selectedIds: TIds

    sorting?: SortingTable
    loading?: boolean
  }

const SKELETON_ROWS = TRASH_BIN_DEFAULT_PAGINATION.showBy

export const TableView = ({
  dataSource,
  selectedIds,
  loading,
  view,
  sorting,
  selectMode,
  filesSize,
  setSelectedIds,
  onViewChange,
  sortColumnHandler,
  onSelect,
  getMenuItems
}: TTableViewProps) => {
  const [visible, setVisible] = useState<string | null>(null)

  const { shouldShowContextMenu, shouldShowHeadContextmenu } = useListContextMenu(selectedIds)

  const { t } = useTranslation()

  const selectedFiles = useMemo(
    () => getSelectedFiles(selectedIds, dataSource),
    [selectedIds, dataSource]
  )

  const columns = useMemo<ColumnsType<TDataSource>>(() => {
    const { direction, name } = sorting ?? {}

    return [
      {
        dataIndex: 'fileName',
        key: 'fileName',
        sorter: true,
        className: styles.fileNameWrapper,
        render: (fileName: string, record: TDataSource) => (
          <div className={cls(styles.sortable, styles.fileName)}>
            <FileName extension={record.extension} fileName={fileName} />

            <VideoReadinessStatus fileId={record.fileId} size="sm" />
          </div>
        ),
        title: () => (
          <HeadContextMenu
            menuItems={getMenuItems(selectedFiles)}
            visible={shouldShowHeadContextmenu}
            total={selectedIds.length}
            filesSize={filesSize}
          >
            <TableTitle
              title={t('files.columns.name')}
              sortId="fileName"
              sorting={sorting}
              sorter={true}
            />
          </HeadContextMenu>
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      },
      {
        dataIndex: 'deletedAt',
        key: 'deletedAt',
        sorter: true,
        className: styles.deletedAtWrapper,
        render: (deletedAt: string | Date) => (
          <div className={styles.sortable}>{formatShortDate(deletedAt)}</div>
        ),
        title: () => (
          <TableTitle
            title={t('files.columns.deletionDate')}
            sorting={sorting}
            sortId="deletedAt"
            sorter={true}
          />
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      },
      {
        dataIndex: 'fileSize',
        key: 'fileSize',
        sorter: true,
        className: styles.fileSizeWrapper,
        render: (fileSize: string) => (
          <div className={styles.sortable}>{formatBytes(fileSize)}</div>
        ),
        title: () => (
          <TableTitle
            title={t('files.columns.fileSize')}
            sorting={sorting}
            sortId="fileSize"
            sorter={true}
          />
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      },
      {
        title: <FileViewToggle variant={view} onChange={onViewChange} />,
        dataIndex: 'actions',
        key: 'actions',
        className: styles.kebabMenuWrapper,
        render: (key: string, { fileId, fileName, extension }: TDataSource) =>
          shouldShowContextMenu(fileId) && (
            <div onClick={stopPropagation}>
              <ContextMenu
                key={key}
                nestIn="body"
                menuItems={getMenuItems({ fileId, fileName, extension })}
                visible={fileId === visible}
                classes={{ icon: styles.contextMenuIcon }}
              />
            </div>
          )
      }
    ]
  }, [
    filesSize,
    getMenuItems,
    onViewChange,
    selectedFiles,
    selectedIds.length,
    shouldShowContextMenu,
    shouldShowHeadContextmenu,
    sortColumnHandler,
    sorting,
    view,
    visible,
    t
  ])

  const rowSelection: TableRowSelection<TDataSource> = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowIds) => setSelectedIds(selectedRowIds as TIds),
    preserveSelectedRowKeys: true
  }

  return (
    <Skeleton
      loading={loading}
      active={true}
      title={{ width: '100%' }}
      paragraph={{ rows: SKELETON_ROWS }}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        pagination={false}
        showSorterTooltip={false}
        tableLayout="fixed"
        onRow={({ fileId }) =>
          TableRow({
            file: { id: fileId },
            openHandlerProps: {
              // An empty array is set because we have "disableOpen:true" file browsing here
              slides: []
            },
            selectMode,
            disableOpen: true,
            onSelect,
            setVisible
          })
        }
      />
    </Skeleton>
  )
}
