import { action } from 'store/store.utils'
import { TError } from 'store/store.types'

import {
  TGetChatPinRequestPayload,
  TGetChatPinSuccessPayload,
  TPinChatMessageErrorPayload,
  TPinChatMessagePayload,
  TPinChatMessageSuccessPayload,
  TPinnedMessagePayload,
  TRemoveAllChatPinMessagesRequestPayload,
  TRemoveChatPinMessageRequestError,
  TRemoveChatPinMessageRequestPayload,
  TRemoveChatPinMessageRequestSuccess
} from './chatPin.types'

// ------------------ GET_CHAT_PIN ------------------
export const GET_CHAT_PIN = 'GET_CHAT_PIN'
export const GET_CHAT_PIN_REQUEST = 'GET_CHAT_PIN_REQUEST'
export const GET_CHAT_PIN_SUCCESS = 'GET_CHAT_PIN_SUCCESS'
export const GET_CHAT_PIN_ERROR = 'GET_CHAT_PIN_ERROR'

export const getChatPinRequest = (payload: TGetChatPinRequestPayload) =>
  action(GET_CHAT_PIN_REQUEST, payload)
export const getChatPinSuccess = (payload: TGetChatPinSuccessPayload) =>
  action(GET_CHAT_PIN_SUCCESS, payload)
export const getChatPinError = (payload: TError) => action(GET_CHAT_PIN_ERROR, payload)

// ------------------ CLEAR_CHAT_PIN ------------------
export const CLEAR_CHAT_PIN = 'CLEAR_CHAT_PIN'

export const clearChatPinAction = () => action(CLEAR_CHAT_PIN)

// ------------------ REMOVE_CHAT_PIN_MESSAGE ------------------
export const REMOVE_CHAT_PIN_MESSAGE = 'REMOVE_CHAT_PIN_MESSAGE'
export const REMOVE_CHAT_PIN_MESSAGE_REQUEST = 'REMOVE_CHAT_PIN_MESSAGE_REQUEST'
export const REMOVE_CHAT_PIN_MESSAGE_SUCCESS = 'REMOVE_CHAT_PIN_MESSAGE_SUCCESS'
export const REMOVE_CHAT_PIN_MESSAGE_ERROR = 'REMOVE_CHAT_PIN_MESSAGE_ERROR'

export const removeChatPinMessageRequest = (payload: TRemoveChatPinMessageRequestPayload) =>
  action(REMOVE_CHAT_PIN_MESSAGE_REQUEST, payload)
export const removeChatPinMessageSuccess = (payload: TRemoveChatPinMessageRequestSuccess) =>
  action(REMOVE_CHAT_PIN_MESSAGE_SUCCESS, payload)
export const removeChatPinMessageError = (payload: TRemoveChatPinMessageRequestError) =>
  action(REMOVE_CHAT_PIN_MESSAGE_ERROR, payload)

// ------------------ REMOVE_ALL_CHAT_PIN_MESSAGES ------------------
export const REMOVE_ALL_CHAT_PIN_MESSAGES = 'REMOVE_ALL_CHAT_PIN_MESSAGES'
export const REMOVE_ALL_CHAT_PIN_MESSAGES_REQUEST = 'REMOVE_ALL_CHAT_PIN_MESSAGES_REQUEST'
export const REMOVE_ALL_CHAT_PIN_MESSAGES_SUCCESS = 'REMOVE_ALL_CHAT_PIN_MESSAGES_SUCCESS'
export const REMOVE_ALL_CHAT_PIN_MESSAGES_ERROR = 'REMOVE_ALL_CHAT_PIN_MESSAGES_ERROR'

export const removeAllChatPinMessagesRequest = (payload: TRemoveAllChatPinMessagesRequestPayload) =>
  action(REMOVE_ALL_CHAT_PIN_MESSAGES_REQUEST, payload)
export const removeAllChatPinMessagesSuccess = () => action(REMOVE_ALL_CHAT_PIN_MESSAGES_SUCCESS)
export const removeAllChatPinMessagesError = (payload: TError) =>
  action(REMOVE_ALL_CHAT_PIN_MESSAGES_ERROR, payload)

// ------------------ PIN_CHAT_MESSAGE ------------------
export const PIN_CHAT_MESSAGE = 'PIN_CHAT_MESSAGE'
export const PIN_CHAT_MESSAGE_REQUEST = 'PIN_CHAT_MESSAGE_REQUEST'
export const PIN_CHAT_MESSAGE_SUCCESS = 'PIN_CHAT_MESSAGE_SUCCESS'
export const PIN_CHAT_MESSAGE_ERROR = 'PIN_CHAT_MESSAGE_ERROR'

export const pinChatMessageRequest = (payload: TPinChatMessagePayload) =>
  action(PIN_CHAT_MESSAGE_REQUEST, payload)
export const pinChatMessageSuccess = (payload: TPinChatMessageSuccessPayload) =>
  action(PIN_CHAT_MESSAGE_SUCCESS, payload)
export const pinChatMessageError = (payload: TPinChatMessageErrorPayload) =>
  action(PIN_CHAT_MESSAGE_ERROR, payload)

export const PINNED_MESSAGE = 'PINNED_MESSAGE'
export const pinnedMessage = (payload: TPinnedMessagePayload) => action(PINNED_MESSAGE, payload)

export const UNPINNED_MESSAGE = 'UNPINNED_MESSAGE'
export const unPinnedMessage = (payload: TPinnedMessagePayload) => action(UNPINNED_MESSAGE, payload)
