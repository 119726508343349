import { FC, useEffect } from 'react'
import { Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Field, FieldRenderProps } from 'react-final-form'

import { TextField, Help, CheckableList } from 'App/components'
import { validation } from 'utils'
import { getPurposesRequest, createLoadingSelector, GET_PURPOSES } from 'store'
import { State } from 'redux/rootReducer'

const TITLE_MAX_LENGTH = 200
const loadingSelector = createLoadingSelector([GET_PURPOSES])

export const CreateAdvertFirstStep: FC = () => {
  const dispatch = useDispatch()

  const loading = useSelector(loadingSelector)
  const purposes = useSelector((state: State) => state.misc.purposes)

  useEffect(() => {
    dispatch(getPurposesRequest())
  }, [dispatch])

  return (
    <>
      <Col xs={24}>
        <Field
          name="title"
          validate={validation.composeValidators(
            validation.required(),
            validation.maxLength(TITLE_MAX_LENGTH)
          )}
        >
          {({ input, meta }) => (
            <TextField
              {...input}
              valueLengthMax={TITLE_MAX_LENGTH}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
              topLabel={
                <span>
                  Title* <Help content="Describe here your advert" />
                </span>
              }
            />
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Field name="purpose" validate={validation.required()}>
          {({ input, meta }: FieldRenderProps<string[]>) => (
            <CheckableList
              {...input}
              loading={loading}
              valueKey="id"
              labelKey="name"
              title={'Indicate your Purpose*'}
              items={purposes}
              invalid={meta.touched && meta.invalid}
              error={meta.error}
            />
          )}
        </Field>
      </Col>
    </>
  )
}
