import { useCallback } from 'react'
import cls from 'classnames'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'

import { TIds, useAppSelector, getKebabMenuPropsSelector } from 'store'
import { parseSpaces } from 'utils'
import {
  Checkbox,
  ContextMenu,
  TCaseCloudFilesItemBaseProps,
  TCaseCloudVariant
} from 'App/components'

import { TGetMultipleKebabMenuProps } from '../CaseCloud/hooks/useKebabMenu'

import styles from './CaseCloudFilesListHeader.module.scss'

export type TCaseCloudFilesListHeaderProps = PropsWithClassName<
  PropsWithSpaces<{
    ids: TIds
    isCaseArchived: boolean
    variant: TCaseCloudVariant

    selectedIds?: TIds
    setSelectedIds?: (ids: TIds) => void
  }>
> &
  Pick<TCaseCloudFilesItemBaseProps, 'getKebabMenu'>

export const CaseCloudFilesListHeader = ({
  setSelectedIds,
  spaces,
  className,
  ids,
  isCaseArchived,
  getKebabMenu,
  variant,
  selectedIds = []
}: TCaseCloudFilesListHeaderProps) => {
  const { t } = useTranslation()

  const kebabMenuProps: TGetMultipleKebabMenuProps | null = useAppSelector(
    getKebabMenuPropsSelector(variant, selectedIds)
  )

  const onChange = useCallback(
    ({ target }: CheckboxChangeEvent) => {
      if (setSelectedIds) {
        setSelectedIds(target.checked ? ids : [])
      }
    },
    [ids, setSelectedIds]
  )

  const showContextMenu = selectedIds.length > 1 && !isCaseArchived

  return (
    <div className={cls(styles.root, className)} style={parseSpaces(spaces)}>
      <Checkbox
        label={t('cases.details.attachments.filesListHeader.selectAllLabel')}
        classes={{
          root: styles.checkbox,
          label: styles.checkboxLabel
        }}
        checked={!!selectedIds.length && selectedIds.length === ids.length}
        indeterminate={!!selectedIds.length && selectedIds.length < ids.length}
        onChange={onChange}
      />

      {getKebabMenu && kebabMenuProps && showContextMenu && (
        <ContextMenu
          classes={{ root: styles.context, icon: styles.contextIcon }}
          startAdornment={
            <div className={styles.contextAddon}>
              {t('cases.details.attachments.filesListHeader.selectedLabel', {
                quantity: selectedIds.length
              })}
            </div>
          }
          menuItems={getKebabMenu(kebabMenuProps)}
        />
      )}
    </div>
  )
}
