import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents, TSettingsPersonalInfoEmailContainerProps } from 'App/containers'
import { SettingsInfoRow } from 'App/components'
import { ReactComponent as PencilIcon } from 'assets/icons/Pencil.svg'

import styles from './SettingsPersonalInfoEmail.module.scss'

export type TSettingsPersonalInfoEmailProps = TSettingsPersonalInfoEmailContainerProps

export const SettingsPersonalInfoEmail = ({
  email,
  showModal
}: TSettingsPersonalInfoEmailProps) => {
  const { t } = useTranslation()

  const handleEmailEdit = useCallback(() => {
    showModal({
      modalTitle: t('modal.changeEmail.title'),
      modalType: EModalComponents.CHANGE_PERSONAL_EMAIL
    })
  }, [showModal, t])

  return (
    <SettingsInfoRow
      label={t('settings.personalInformation.emailLabel')}
      description={email}
      action={
        <div className={styles.link} onClick={handleEmailEdit}>
          <PencilIcon className={styles.linkIcon} />
          <span>{t('settings.personalInformation.editButton')}</span>
        </div>
      }
    />
  )
}
