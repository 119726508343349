import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components'
import { EModalComponents } from 'App/containers'
import { showModalAction } from 'store'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

export type TPublicationsAddButtonProps = {
  disabled?: boolean
}

export const PublicationsAddButton = ({ disabled, ...modalProps }: TPublicationsAddButtonProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const handleClick = () => {
    dispatch(
      showModalAction({
        modalProps,
        modalTitle: t('modal.userPublication.title_add'),
        modalType: EModalComponents.PUBLICATION_DIALOG
      })
    )
  }

  return (
    <Button icon={<PlusIcon />} variant="text" disabled={disabled} onClick={handleClick}>
      {t('userProfile.publications.addPublicationButton')}
    </Button>
  )
}
