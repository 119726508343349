import { useEffect } from 'react'
import { useParams, useHistory, Switch, generatePath } from 'react-router-dom'

import { ChatOneToOneContainer, RoleRouteContainer } from 'App/containers'
import {
  CHAT_PATH,
  CREATE_GROUP_CHAT_PATH,
  EChatTypeMap,
  EDIT_GROUP_CHAT_PATH
} from 'globalConstants'

// ------------------ Types ------------------
export type TChatPathParams = {
  chatType?: EChatTypeMap
  chatId?: string
}
// ------------------  END  ------------------

export const Chat = () => {
  const { replace } = useHistory()
  const { chatType } = useParams<TChatPathParams>()

  useEffect(() => {
    if (!chatType || !Object.values(EChatTypeMap).includes(chatType)) {
      replace(generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE }))
    }
  }, [chatType, replace])

  return (
    <Switch>
      <RoleRouteContainer
        component={ChatOneToOneContainer}
        path={[CHAT_PATH, CREATE_GROUP_CHAT_PATH, EDIT_GROUP_CHAT_PATH]}
      />
    </Switch>
  )
}
