import { useCallback } from 'react'
import { Form, Field } from 'react-final-form'

import { Button, TextField } from 'App/components'
import {
  createProcessingSelector,
  CREATE_CONNECTION_REQUEST,
  createConnectionRequestRequest,
  useAppDispatch,
  useAppSelector
} from 'store'
import { validation } from 'utils'
import { Author, ItemInfo } from 'App/components/Adverts/common'
import { usePrepareItemData } from 'App/components/Adverts/hooks'

import styles from './ConnectionRequestDialog.module.scss'

type TConnectionRequestDialogTypes = {
  advertId: string
}

type TValues = {
  message: string
}

const processingSelector = createProcessingSelector([CREATE_CONNECTION_REQUEST])

const initialFormValues: TValues = {
  message: ''
}

const MESSAGE_MAX_LENGTH = 150
const MESSAGE_ROWS = 3

export const ConnectionRequestDialog = ({ advertId }: TConnectionRequestDialogTypes) => {
  const dispatch = useAppDispatch()

  const processing = useAppSelector(processingSelector)
  const {
    title,
    purposes,
    locations,
    profession,
    owner,
    specializations = []
  } = useAppSelector((state) => state.adverts.connector.list[advertId] || {})

  const { locationsNames, lookingFor, purposesNames } = usePrepareItemData({
    purposes,
    locations,
    profession,
    specializations
  })

  const onSubmit = useCallback(
    ({ message }: TValues) => {
      dispatch(
        createConnectionRequestRequest({
          advertId,
          message
        })
      )
    },
    [advertId, dispatch]
  )

  return (
    <div className={styles.root}>
      <Form onSubmit={onSubmit} initialValues={initialFormValues}>
        {({ handleSubmit, form }) => (
          <>
            <Author
              className={styles.author}
              lastName={owner.lastName}
              firstName={owner.firstName}
              city={owner.city?.cityName ?? ''}
              country={owner.country.countryName}
              displayUserName={owner.displayUserName}
              type={owner.type.name}
              userId={owner.id}
              disableLink={true}
            />
            <div className={styles.title}>{title}</div>
            <ItemInfo lookingFor={lookingFor} locations={locationsNames} purposes={purposesNames} />

            <Field
              name="message"
              validate={validation.composeValidators(validation.maxLength(MESSAGE_MAX_LENGTH))}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  valueLengthMax={MESSAGE_MAX_LENGTH}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                  topLabel="Your message"
                  multiline={true}
                  rows={MESSAGE_ROWS}
                  placeholder="You can add a message"
                />
              )}
            </Field>

            <Button
              tabIndex={2}
              onClick={handleSubmit}
              disabled={form.getState().invalid}
              loading={processing}
              className={styles.submit}
            >
              Send
            </Button>
          </>
        )}
      </Form>
    </div>
  )
}
