import { action, TError, TGetCaseListSuccessPayload, TSetCaseListSearchPayload } from 'store'

import { TDeleteCaseRequestPayload } from '.'
import {
  TCaseCountLoadingPayload,
  TCasesCountPayload,
  TGetCaseListRequestPayload,
  TRenameCaseTitlePayload,
  TGetCaseCountRequestPayload,
  TGetCaseHistoryFiltersSuccessPayload,
  TGetCaseHistoryListSuccessPayload,
  TGetCaseHistoryFiltersRequestPayload,
  TGetCaseHistoryRequestPayload,
  TToggleCaseFilterActionPayload,
  TSetCaseListPaginationPayload,
  TSetCasesLinksSearchPayload,
  TGetCasesRequestPayload,
  TGetCaseLinksSuccessPayload,
  TSetCasesLinksFilterPayload,
  TGetLinkCaseListRequestPayload,
  TGetLinkCaseListSuccessPayload,
  TSetLinkCaseListSearchPayload,
  TSetLinkCaseListFilterPayload
} from './cases.types'

export const GET_CASE_COUNT_REQUEST = 'GET_CASE_COUNT_REQUEST'
export const SET_CASE_COUNT = 'SET_CASE_COUNT'
export const SET_CASE_COUNT_LOADING = 'SET_CASE_COUNT_LOADING'

export const RENAME_CASE_TITLE = 'RENAME_CASE_TITLE'
export const RENAME_CASE_TITLE_REQUEST = 'RENAME_CASE_TITLE_REQUEST'
export const RENAME_CASE_TITLE_SUCCESS = 'RENAME_CASE_TITLE_SUCCESS'
export const RENAME_CASE_TITLE_ERROR = 'RENAME_CASE_TITLE_ERROR'

// ------------------ GET_CASE_LIST ------------------
export const GET_CASE_LIST = 'GET_CASE_LIST'
export const GET_CASE_LIST_REQUEST = 'GET_CASE_LIST_REQUEST'
export const GET_CASE_LIST_SUCCESS = 'GET_CASE_LIST_SUCCESS'
export const GET_CASE_LIST_ERROR = 'GET_CASE_LIST_ERROR'

export const getCaseListRequest = (payload?: TGetCaseListRequestPayload) =>
  action(GET_CASE_LIST_REQUEST, payload)
export const getCaseListSuccess = (payload: TGetCaseListSuccessPayload) =>
  action(GET_CASE_LIST_SUCCESS, payload)
export const getCaseListError = (payload: TError) => action(GET_CASE_LIST_ERROR, payload)

// ------------------ SET_CASE_LIST_PAGINATION ------------------
export const SET_CASE_LIST_PAGINATION = 'SET_CASE_LIST_PAGINATION'

export const setCaseListPaginationAction = (payload: TSetCaseListPaginationPayload) =>
  action(SET_CASE_LIST_PAGINATION, payload)

// ------------------ SET_CASE_LIST_SEARCH ------------------
export const SET_CASE_LIST_SEARCH = 'SET_CASE_LIST_SEARCH'

export const setCaseListSearchAction = (payload: TSetCaseListSearchPayload) =>
  action(SET_CASE_LIST_SEARCH, payload)

// ------------------ GET_CASES_LINKS  ------------------

export const GET_CASES_LINKS = 'GET_CASES_LINKS'
export const GET_CASES_LINKS_REQUEST = 'GET_CASES_LINKS_REQUEST'
export const GET_CASES_LINKS_SUCCESS = 'GET_CASES_LINKS_SUCCESS'
export const GET_CASES_LINKS_ERROR = 'GET_CASES_LINKS_ERROR'

export const getCasesLinksRequest = (payload: TGetCasesRequestPayload) =>
  action(GET_CASES_LINKS_REQUEST, payload)
export const getCasesLinksSuccess = (payload: TGetCaseLinksSuccessPayload) =>
  action(GET_CASES_LINKS_SUCCESS, payload)
export const getCasesLinksError = (payload: TError) => action(GET_CASES_LINKS_ERROR, payload)

// ------------------ SET_CASES_LINKS_SEARCH ------------------
export const SET_CASES_LINKS_SEARCH = 'SET_CASES_LINKS_SEARCH'

export const setCasesLinksSearchAction = (payload: TSetCasesLinksSearchPayload) =>
  action(SET_CASES_LINKS_SEARCH, payload)

// ------------------ SET_CASES_LINKS_FILTER  ------------------
export const SET_CASES_LINKS_FILTER = 'SET_CASES_LINKS_FILTER'

export const setCasesLinksFilterAction = (payload: TSetCasesLinksFilterPayload) =>
  action(SET_CASES_LINKS_FILTER, payload)

// ------------------ RESET_CASES_LINKS_FILTER  ------------------
export const RESET_CASES_LINKS_FILTER = 'RESET_CASES_LINKS_FILTER'

export const resetCasesLinksFilter = () => action(RESET_CASES_LINKS_FILTER)

// ------------------ TOGGLE_CASE_LINK  ------------------

export const TOGGLE_CASE_LINK = 'TOGGLE_CASE_LINK'
export const TOGGLE_CASE_LINK_REQUEST = 'TOGGLE_CASE_LINK_REQUEST'
export const TOGGLE_CASE_LINK_SUCCESS = 'TOGGLE_CASE_LINK_SUCCESS'
export const TOGGLE_CASE_LINK_ERROR = 'TOGGLE_CASE_LINK_ERROR'

type TToggleCaseLinkRequest = TGetCasesRequestPayload & {
  caseIdToToggle: string
}

export const toggleCaseLinkRequest = (payload: TToggleCaseLinkRequest) =>
  action(TOGGLE_CASE_LINK_REQUEST, payload)
export const toggleCaseLinkSuccess = () => action(TOGGLE_CASE_LINK_SUCCESS)
export const toggleCaseLinkError = (payload: TError) => action(TOGGLE_CASE_LINK_ERROR, payload)

// ------------------ CASE_FILTER  ------------------
export const TOGGLE_CASE_FILTER = 'TOGGLE_CASE_FILTER'

export const toggleCaseFilterAction = (payload: TToggleCaseFilterActionPayload) =>
  action(TOGGLE_CASE_FILTER, payload)

// ------------------ RESET_CASE_FILTER  ------------------
export const RESET_CASE_FILTER_ACTION = 'RESET_CASE_FILTER_ACTION'

export const resetCaseFilterAction = () => action(RESET_CASE_FILTER_ACTION)

// ------------------ GET_CASE_HISTORY_LIST  ------------------
export const GET_CASE_HISTORY = 'GET_CASE_HISTORY'
export const GET_CASE_HISTORY_REQUEST = 'GET_CASE_HISTORY_REQUEST'
export const GET_CASE_HISTORY_SUCCESS = 'GET_CASE_HISTORY_SUCCESS'
export const GET_CASE_HISTORY_ERROR = 'GET_CASE_HISTORY_ERROR'

export const getCaseHistoryList = (payload: TGetCaseHistoryRequestPayload) =>
  action(GET_CASE_HISTORY_REQUEST, payload)

export const getCaseHistoryListSuccess = (payload: TGetCaseHistoryListSuccessPayload) =>
  action(GET_CASE_HISTORY_SUCCESS, payload)

export const getCaseHistoryListError = (payload: TError) => action(GET_CASE_HISTORY_ERROR, payload)

// ------------------ GET_CASE_HISTORY_FILTERS  ------------------
export const GET_CASE_HISTORY_FILTERS = 'GET_CASE_HISTORY_FILTERS'
export const GET_CASE_HISTORY_FILTERS_REQUEST = 'GET_CASE_HISTORY_FILTERS_REQUEST'
export const GET_CASE_HISTORY_FILTERS_SUCCESS = 'GET_CASE_HISTORY_FILTERS_SUCCESS'
export const GET_CASE_HISTORY_FILTERS_ERROR = 'GET_CASE_HISTORY_FILTERS_ERROR'

export const getCaseHistoryFilters = (payload: TGetCaseHistoryFiltersRequestPayload) =>
  action(GET_CASE_HISTORY_FILTERS_REQUEST, payload)

export const getCaseHistoryFiltersSuccess = (payload: TGetCaseHistoryFiltersSuccessPayload) =>
  action(GET_CASE_HISTORY_FILTERS_SUCCESS, payload)

export const getCaseHistoryFiltersError = (payload: TError) =>
  action(GET_CASE_HISTORY_FILTERS_ERROR, payload)

// ------------------ GET_CASE_COUNT ------------------

export const getCaseCountRequest = (payload?: TGetCaseCountRequestPayload) =>
  action(GET_CASE_COUNT_REQUEST, payload)

export const setCasesCount = (payload: TCasesCountPayload) => action(SET_CASE_COUNT, payload)

export const setCaseCountLoading = (payload: TCaseCountLoadingPayload) =>
  action(SET_CASE_COUNT_LOADING, payload)

// ------------------ RENAME_CASE_TITLE ------------------

export const renameCaseTitleRequest = (payload: TRenameCaseTitlePayload) =>
  action(RENAME_CASE_TITLE_REQUEST, payload)

export const renameCaseTitleSuccess = () => action(RENAME_CASE_TITLE_SUCCESS)

export const renameCaseTitleError = (payload: TError) => action(RENAME_CASE_TITLE_ERROR, payload)

// ------------------ RESET_CASE_LIST ------------------

export const RESET_CASE_LIST_ACTION = 'RESET_CASE_LIST_ACTION'

export const resetCaseListAction = () => action(RESET_CASE_LIST_ACTION)

// ------------------ RESET_CASE_LINKS_ACTION ------------------

export const RESET_CASE_LINKS_ACTION = 'RESET_CASE_LINKS_ACTION'

export const resetCaseLinksAction = () => action(RESET_CASE_LINKS_ACTION)

export const DELETE_CASE = 'DELETE_CASE'
export const DELETE_CASE_REQUEST = 'DELETE_CASE_REQUEST'
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS'
export const DELETE_CASE_ERROR = 'DELETE_CASE_ERROR'

export const deleteCaseRequest = (payload: TDeleteCaseRequestPayload) =>
  action(DELETE_CASE_REQUEST, payload)
export const deleteCaseSuccess = () => action(DELETE_CASE_SUCCESS)
export const deleteCaseError = (error: TError) => action(DELETE_CASE_ERROR, error)

// ------------------ GET_LINK_CASE_LIST ------------------
export const GET_LINK_CASE_LIST = 'GET_LINK_CASE_LIST'
export const GET_LINK_CASE_LIST_REQUEST = 'GET_LINK_CASE_LIST_REQUEST'
export const GET_LINK_CASE_LIST_SUCCESS = 'GET_LINK_CASE_LIST_SUCCESS'
export const GET_LINK_CASE_LIST_ERROR = 'GET_LINK_CASE_LIST_ERROR'

export const getLinkCaseListRequest = (payload: TGetLinkCaseListRequestPayload) =>
  action(GET_LINK_CASE_LIST_REQUEST, payload)
export const getLinkCaseListSuccess = (payload: TGetLinkCaseListSuccessPayload) =>
  action(GET_LINK_CASE_LIST_SUCCESS, payload)
export const getLinkCaseListError = (payload: TError) => action(GET_LINK_CASE_LIST_ERROR, payload)

// ------------------ SET_LINK_CASE_LIST_SEARCH ------------------
export const SET_LINK_CASE_LIST_SEARCH = 'SET_LINK_CASE_LIST_SEARCH'

export const setLinkCaseListSearch = (payload: TSetLinkCaseListSearchPayload) =>
  action(SET_LINK_CASE_LIST_SEARCH, payload)

// ------------------ SET_LINK_CASE_LIST_FILTER ------------------
export const SET_LINK_CASE_LIST_FILTER = 'SET_LINK_CASE_LIST_FILTER'

export const setLinkCaseListFilter = (payload: TSetLinkCaseListFilterPayload) =>
  action(SET_LINK_CASE_LIST_FILTER, payload)

// ------------------ RESET_LINK_CASE_LIST_FILTER  ------------------
export const RESET_LINK_CASE_LIST_FILTER = 'RESET_LINK_CASE_LIST_FILTER'

export const resetLinkCaseListFilter = () => action(RESET_LINK_CASE_LIST_FILTER)

// ------------------ RESET_LINK_CASE_LIST ------------------
export const RESET_LINK_CASE_LIST = 'RESET_LINK_CASE_LIST'

export const resetLinkCaseList = () => action(RESET_LINK_CASE_LIST)
