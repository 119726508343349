import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsPersonalInfoFullName } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsPersonalInfoFullNameContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => {
  const { firstName = '', lastName = '' } = state.global.accountData ?? {}

  return {
    fullName: `${firstName} ${lastName}`
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ showModal: showModalAction }, dispatch)

export const SettingsPersonalInfoFullNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPersonalInfoFullName)
