import { Trans, useTranslation } from 'react-i18next'

import { AlertLink } from 'App/components'

export type TCaseDetailsAlertProps = {
  id: string
  caseName: string

  isOwner?: boolean
}

export const CaseDetailsAlert = ({ id, caseName, isOwner }: TCaseDetailsAlertProps) => {
  const { t } = useTranslation()

  return (
    <Trans t={t} i18nKey="cases.details.historyAlert">
      You have new event(s) in the Case. For detailed information
      <AlertLink
        to={{
          pathname: `/cases/${id}/${isOwner ? 'owner' : 'member'}/history`,
          state: { caseTitle: caseName }
        }}
      >
        go to Case history
      </AlertLink>
    </Trans>
  )
}
