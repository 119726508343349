import { BusinessAccountRoleIdEnum } from '@medentee/enums'

import { TIds } from 'store'
import { UserProfileAdministrationItemContainer } from 'App/containers'
import { Scrollable } from 'App/components/common'

import { List } from '../../UserProfile/common'

import { Button } from './Button'
import styles from './AdministrationList.module.scss'

export type TAdministrationListProps = {
  ids: TIds
  loading: boolean
  accountRoleId: BusinessAccountRoleIdEnum
}

export const AdministrationList = ({ ids, loading, accountRoleId }: TAdministrationListProps) => (
  <Scrollable className={styles.scrollable}>
    <List
      loading={loading}
      ids={ids}
      item={UserProfileAdministrationItemContainer}
      footer={accountRoleId === BusinessAccountRoleIdEnum.SUPERADMIN && <Button />}
      className={styles.list}
    />
  </Scrollable>
)
