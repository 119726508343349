const { userAgent, maxTouchPoints, platform } = navigator
let OS: 'android' | 'ios' | 'macos' | 'windows' | undefined

if (/Android/i.exec(userAgent)) {
  OS = 'android'
} else if (
  /iP(ad|hone|od)/i.exec(userAgent) ||
  (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))
) {
  OS = 'ios'
} else if (/Mac(intosh| OS X)/i.exec(userAgent)) {
  OS = 'macos'
} else if (/Windows/i.exec(userAgent)) {
  OS = 'windows'
}

export const platformOS = OS

/**
 * Returns whether or not the current environment is a mobile device.
 * @returns {boolean}
 */
export const isMobileBrowser = (): boolean => OS === 'android' || OS === 'ios'
