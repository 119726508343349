import {
  ContactStatusEnum,
  BusinessAccountDirectionEnum,
  PlatformInvitationType
} from '@medentee/enums'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { useMutation, useQueryClient } from 'services/query'
import { getMapComponent } from 'utils'
import { connectByLinkToken } from 'api/profile'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { IInvitationsDataByLinkTokenDTO } from 'interfaces'
import { accountIdSelector, getUserProfileByIdRequest, useAppDispatch, useAppSelector } from 'store'

export type TUseUserProfileInvitationByLinkRequestProps = Partial<
  Pick<IInvitationsDataByLinkTokenDTO, 'inviteContact' | 'inviteOrganization' | 'token' | 'type'>
> & {
  profileId?: string
  hideDefaultError?: boolean
}

const TOAST_CONTENT_MAP = new Map<PlatformInvitationType, (t: TFunction) => string>()
  .set(PlatformInvitationType.CONTACT, (t) => t('common.toast.contactsRequestSent'))
  .set(PlatformInvitationType.STAFF, (t) => t('common.toast.staffRequestSent'))

export const useUserProfileInvitationByLinkRequest = ({
  type,
  token,
  hideDefaultError,
  inviteContact,
  inviteOrganization,
  profileId
}: TUseUserProfileInvitationByLinkRequestProps) => {
  const dispatch = useAppDispatch()

  const queryClient = useQueryClient()

  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  const { mutate, isLoading, error } = useMutation({
    mutationKey: ['connect-by-link', token],
    mutationFn: connectByLinkToken,
    onError: hideDefaultError ? noop : undefined,
    onSuccess: () => {
      const hasEmptyInvitationData = !inviteContact && !inviteOrganization
      const hasStaffInvitationWithAlreadyContact =
        type === PlatformInvitationType.STAFF &&
        inviteContact?.status === ContactStatusEnum.APPROVED &&
        !inviteOrganization?.status

      if (
        (accountId === inviteContact?.toId && type !== PlatformInvitationType.STAFF) ||
        inviteOrganization?.direction === BusinessAccountDirectionEnum.OUTGOING
      ) {
        toast.success(t('common.toast.youAreConnected'), toastDefaultOptions)
      }

      if (hasEmptyInvitationData || hasStaffInvitationWithAlreadyContact) {
        toast.success(getMapComponent(TOAST_CONTENT_MAP, type, t), toastDefaultOptions)
      }

      if (profileId) {
        dispatch(getUserProfileByIdRequest({ id: profileId, token }))
      }

      queryClient.invalidateQueries({
        queryKey: ['get-data-by-invitation-token', token],
        type: 'active'
      })
    }
  })

  return { mutate, isLoading, error }
}
