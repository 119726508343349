import { useState, useEffect, useCallback } from 'react'
import noop from 'lodash/noop'

export type TUsePlaySoundOptions = {
  audioSrc: string

  volume?: number
  loop?: boolean
  autoplay?: boolean
  defaultMute?: boolean
}

export const usePlaySound = ({
  audioSrc,
  volume = 1,
  loop = true,
  autoplay = true,
  defaultMute = false
}: TUsePlaySoundOptions) => {
  const [audio] = useState(new Audio(audioSrc))
  const [isAudioMute, setAudioMute] = useState<boolean>(defaultMute)
  const play = useCallback(() => audio.play(), [audio])
  const pause = useCallback(() => audio.pause(), [audio])
  const toggleMute = () => {
    if (!isAudioMute) {
      audio.pause()
      setAudioMute(true)
      return
    }

    // fix unhandled rejection error on iOS emulator(real device?)
    audio.play().catch(noop)
    setAudioMute(false)
  }

  useEffect(() => {
    audio.loop = loop
  }, [audio, loop])

  useEffect(() => {
    audio.volume = volume
  }, [audio, volume])

  useEffect(() => {
    if (autoplay) {
      // fix unhandled rejection error on iOS emulator(real device?)
      play().catch(noop)
    }

    return () => {
      pause()
    }
  }, [autoplay, pause, play])

  return {
    audio,
    isAudioMute,
    setAudioMute,
    pause,
    play,
    toggleMute
  }
}
