import { AxiosError } from 'axios'

import { action } from 'store'

import {
  TDeleteCategoryRequestPayload,
  TDeleteCategorySuccessPayload,
  TSendCategoryRequestPayload
} from './category.types'

// ------------------  SEND_CATEGORY  ------------------

export const SEND_CATEGORY = 'SEND_CATEGORY'
export const SEND_CATEGORY_REQUEST = 'SEND_CATEGORY_REQUEST'
export const SEND_CATEGORY_SUCCESS = 'SEND_CATEGORY_SUCCESS'
export const SEND_CATEGORY_ERROR = 'SEND_CATEGORY_ERROR'

export const sendCategoryRequest = (payload: TSendCategoryRequestPayload) =>
  action(SEND_CATEGORY_REQUEST, payload)
export type TSendCategoryRequest = ReturnType<typeof sendCategoryRequest>

export const sendCategorySuccess = () => action(SEND_CATEGORY_SUCCESS)
export type TSendCategorySuccess = ReturnType<typeof sendCategorySuccess>

export const sendCategoryError = (payload: null | AxiosError) =>
  action(SEND_CATEGORY_ERROR, payload)
export type TSendCategoryError = ReturnType<typeof sendCategoryError>

// ------------------  DELETE_CATEGORY  ------------------

export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'

export const deleteCategoryRequest = (payload: TDeleteCategoryRequestPayload) =>
  action(DELETE_CATEGORY_REQUEST, payload)
export type TDeleteCategoryRequest = ReturnType<typeof deleteCategoryRequest>

export const deleteCategorySuccess = (payload: TDeleteCategorySuccessPayload) =>
  action(DELETE_CATEGORY_SUCCESS, payload)
export type TDeleteCategorySuccess = ReturnType<typeof deleteCategorySuccess>

export const deleteCategoryError = (payload: AxiosError | null) =>
  action(DELETE_CATEGORY_ERROR, payload)
export type TDeleteCategoryError = ReturnType<typeof deleteCategoryError>
