import { useCallback, useMemo } from 'react'
import { ChatTypeEnum, FileExtensionCategoryEnum, FileHistorySourcesEnum } from '@medentee/enums'

import {
  TFileItem,
  TFileSharedItem,
  useAppDispatch,
  useAppSelector,
  getFileSharedListRequest,
  EPermissionSpecificationScope,
  getFileListRequest,
  createSlides,
  createSlidesEntity,
  hideModalAction,
  getFilesSharedCountsRequest
} from 'store'
import { ChatFileTile, ChatFileRow, TFilesViewVariant } from 'App/components'
import { useKebabMenu } from 'App/components/Chat/ChatFiles/useKebabMenu'

export type TChatFilesItemProps = {
  id: string
  chatId: string
  view: TFilesViewVariant
  variant: 'file' | 'fileShared'
  loading: boolean
  category: FileExtensionCategoryEnum
}

export const ChatFilesItem = ({
  id,
  chatId,
  view,
  variant,
  loading,
  category
}: TChatFilesItemProps) => {
  const dispatch = useAppDispatch()

  const file = useAppSelector((state) => state[variant].fileList[id])
  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const interlocutorAccountId = useAppSelector(
    (state) => state.chat.chatRooms.selectedChat?.interlocutorAccount?.id
  )
  const slidesEntity = useAppSelector((state) => {
    const { ids, fileList } = state[variant] ?? {}

    return createSlidesEntity(ids, fileList)
  })
  const chatType = useAppSelector((state) => state.chat.chatRooms.selectedChat?.type)

  const isCaseGroup = chatType === ChatTypeEnum.CASE_GROUP
  const isGroup = chatType === ChatTypeEnum.GROUP
  const isOrganization = chatType === ChatTypeEnum.ORGANIZATION
  const isCommunity =
    chatType === ChatTypeEnum.COMMUNITY_NEWS || chatType === ChatTypeEnum.COMMUNITY_CHANNEL
  const isEvent = chatType === ChatTypeEnum.EVENT_CHANNEL || chatType === ChatTypeEnum.EVENT_NEWS

  const slides = useMemo(
    () => createSlides(slidesEntity, FileHistorySourcesEnum.P2P_CLOUD, chatId),
    [chatId, slidesEntity]
  )

  const {
    extension,
    fileName,
    fileSize,
    thumbnailUrl,
    videoMetadata,
    sharedAt,
    mimeType
  }: Partial<TFileItem | TFileSharedItem> = file || {}

  const isUnopenable = videoMetadata?.quality === null

  const discardPermissionCallback = useCallback(() => {
    dispatch(
      getFilesSharedCountsRequest({
        permissionUserIdScope: accountId,
        chatId
      })
    )
    dispatch(
      getFileSharedListRequest({
        permissionSpecificationScope: EPermissionSpecificationScope.P2P,
        permissionUserIdScope: accountId,
        ownerId: interlocutorAccountId,
        chatId,
        extensionCategories: [category]
      })
    )
  }, [accountId, category, chatId, dispatch, interlocutorAccountId])

  const revokeP2PAccessCallback = useCallback(() => {
    dispatch(hideModalAction())
    dispatch(
      getFileListRequest({
        permissionSpecificationScope: EPermissionSpecificationScope.P2P,
        permissionUserIdScope: interlocutorAccountId,
        ownerId: accountId,
        chatId,
        extensionCategories: [category]
      })
    )
  }, [accountId, category, chatId, dispatch, interlocutorAccountId])

  const renameCallback = useCallback(
    () =>
      dispatch(
        getFileListRequest({
          permissionSpecificationScope: EPermissionSpecificationScope.P2P,
          permissionUserIdScope: interlocutorAccountId,
          ownerId: accountId,
          chatId,
          extensionCategories: [category]
        })
      ),
    [accountId, category, chatId, dispatch, interlocutorAccountId]
  )

  const { getMenuItems } = useKebabMenu({
    discardPermissionCallback,
    revokeP2PAccessCallback,
    renameCallback
  })

  const menuItems = useMemo(
    () =>
      getMenuItems({
        slidesEntity,
        chatId,
        extension,
        fileId: id,
        fileName,
        isUnopenable,
        yourMessage: variant === 'file',
        isCaseGroup,
        isOrganization,
        isCommunity,
        isEvent,
        isGroup,
        isChatInfo: true
      }),
    [
      getMenuItems,
      slidesEntity,
      chatId,
      extension,
      id,
      fileName,
      isUnopenable,
      variant,
      isCaseGroup,
      isGroup,
      isOrganization,
      isCommunity,
      isEvent
    ]
  )

  return view === 'grid' ? (
    <ChatFileTile
      fileId={id}
      extension={extension}
      fileName={fileName}
      thumbnailUrl={thumbnailUrl}
      openHandlerProps={{ slides }}
      disableOpen={isUnopenable}
      loading={!file && loading}
      menuItems={menuItems}
      mimeType={mimeType}
    />
  ) : (
    <ChatFileRow
      extension={extension}
      fileName={fileName}
      fileSize={fileSize}
      sharedAt={sharedAt}
      fileId={id}
      openHandlerProps={{ slides }}
      disableOpen={isUnopenable}
      loading={!file && loading}
      menuItems={menuItems}
      mimeType={mimeType}
    />
  )
}
