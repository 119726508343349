import { useEffect, useMemo } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { OrganizationContentHeader, Scrollable, TNavMenuItem, TabMenu } from 'App/components'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { RoleRouteContainer } from 'App/containers'
import { getKeyForRoute, organizationsInvitationsNestedRoutes } from 'App/App.config'
import { ELayoutNavKey } from 'enums'
import { useSessionStorage } from 'App/hooks/useSessionStorage'

import styles from './OrganizationsInvitations.module.scss'

export const OrganizationsInvitations = () => {
  const hasOrganizations = useAppSelector((state) => Boolean(state.organizations.data.ids.length))

  const { clearSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.ORGANIZATIONS })

  const { t } = useTranslation()

  useEffect(() => {
    clearSessionStorageData()
  }, [clearSessionStorageData])

  const menuList = useMemo<TNavMenuItem[]>(
    () => [
      {
        exact: true,
        link: '/organizations/invitations/received',
        title: t('organizations.invitations.tabs.received')
      },
      {
        exact: true,
        link: '/organizations/invitations/sent',
        title: t('organizations.invitations.tabs.sent')
      }
    ],
    [t]
  )

  return (
    <div className={styles.root}>
      <OrganizationContentHeader
        title={t('organizations.invitationsLabel')}
        icon={<AccountPlusIcon />}
        iconVariant="circle"
        iconColor="secondary"
        shouldShowBack={hasOrganizations}
      />
      <div className={styles.main}>
        <TabMenu
          menuList={menuList}
          navMenuProps={{ tabPosition: 'top', size: 'md' }}
          classes={{ root: styles.nav }}
          tabVariant="static"
        />

        <Scrollable>
          <Switch>
            {organizationsInvitationsNestedRoutes.map((item, index) => (
              <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
            ))}

            <Redirect to="/organizations/invitations/received" />
          </Switch>
        </Scrollable>
      </div>
    </div>
  )
}
