import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useNotificationTracker } from 'App/hooks/useNotificationTracker'
import { ESocketEmitEventNamesEvents } from 'services/webSocket/webSocket.enums'
import { toastDefaultOptions } from 'globalConstants'
import { useAppDispatch, useAppSelector } from 'store/store.utils'
import { accountIdSelector } from 'store/store.selectors'
import { toast } from 'App/components/ToastContainer'
import { shouldSkipAction } from 'services/skipWSActions'
import { LOST_ACCESS_TO_EVENT, getEventsRequest } from 'store/events'
import { IEventDTO } from 'interfaces'

const NOTIFICATIONS = new Set([ESocketEmitEventNamesEvents.LOST_ACCESS_TO_EVENT])

export const useEventNotificationTracker = (channel: string) => {
  const { replace } = useHistory()

  const dispatch = useAppDispatch()

  const accountId = useAppSelector(accountIdSelector)

  const { t } = useTranslation()

  useNotificationTracker<IEventDTO>({
    channel,
    notifications: NOTIFICATIONS,
    onOccurrence: (payload) => {
      dispatch(getEventsRequest())

      replace('/events')

      if (shouldSkipAction({ type: LOST_ACCESS_TO_EVENT, payload })) {
        const message =
          payload?.organizerId === accountId
            ? t('common.toast.eventDeleted')
            : t('common.toast.leftEvent')

        toast.success(message, toastDefaultOptions)
      } else {
        toast.error(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    }
  })
}
