import { CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { Alert } from 'App/components'

import styles from './CaseInviteMembersAlert.module.scss'

export type TCaseInviteMembersAlertProps = {
  caseMembersLimit?: number
  type?: CaseTypesEnum | null
}

export const CaseInviteMembersAlert = ({
  type,
  caseMembersLimit
}: TCaseInviteMembersAlertProps) => {
  const { t } = useTranslation()

  if (!type) {
    return null
  }

  return (
    <div className={styles.root}>
      <Alert variant="info" size="sm">
        {t('cases.inviteMembersAlert', { limit: caseMembersLimit })}
      </Alert>
    </div>
  )
}
