import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { OperationsHistory } from 'App/components'
import {
  clearOperationsHistoryAction,
  createLoadingSelector,
  getOperationsHistoryRequest,
  GET_OPERATIONS_HISTORY,
  setOperationsHistoryFilter
} from 'store'

export type TOperationsHistoryContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_OPERATIONS_HISTORY])

const mapStateToProps = (state: State) => ({
  loading: loadingSelector(state),
  total: state.payments.history.total,
  data: state.payments.history.data,
  page: state.payments.history.page,
  accountId: state.global.accountData?.id,
  selectedFilters: state.payments.history.filters,
  accountType: state.global.accountData?.type.name
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOperationsHistory: getOperationsHistoryRequest,
      clearOperationsHistory: clearOperationsHistoryAction,
      setOperationsHistoryFilter
    },
    dispatch
  )

export const OperationsHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationsHistory)
