import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { EFileUploadStatus } from 'enums'
import { isUploadingSelector, useAppSelector } from 'store'
import { getMapComponent } from 'utils'

import styles from './Title.module.scss'

type TTitleMapProps = {
  errorCount: number
  t: TFunction
}

type TUploadingStatus = 'completed' | 'completed_with_errors' | 'uploading' | null

const TITLE_MAP = new Map<TUploadingStatus, (props: TTitleMapProps) => string>()
  .set('completed', ({ t }) => t('common.uploadWidget.status_completed'))
  .set('completed_with_errors', ({ errorCount, t }) =>
    t('common.uploadWidget.status_errors', { errorCount })
  )
  .set('uploading', ({ t }) => t('common.uploadWidget.status_uploading'))

export const Title = () => {
  const [type, setType] = useState<TUploadingStatus>(null)

  const { list, errors, ids } = useAppSelector((state) => state.medCloud.widget)
  const uploading = useAppSelector(isUploadingSelector)

  const { t } = useTranslation()

  const listAsArray = useMemo(() => ids.map((id) => list[id]), [ids, list])

  const progressing = useMemo(
    () =>
      Boolean(
        listAsArray.filter(({ uploadStatus }) => uploadStatus === EFileUploadStatus.PROGRESS).length
      ),
    [listAsArray]
  )

  const completed = useMemo(
    () =>
      listAsArray.every(
        ({ uploadStatus }) =>
          uploadStatus === EFileUploadStatus.CANCELLED || uploadStatus === EFileUploadStatus.SUCCESS
      ),
    [listAsArray]
  )

  const errorCount = useMemo(
    () => Object.values(errors).filter(({ errorType, message }) => errorType || message).length,
    [errors]
  )

  const loading = uploading || progressing

  useEffect(() => {
    switch (true) {
      case completed && !errorCount:
        setType('completed')
        break

      case Boolean(errorCount) && !loading:
        setType('completed_with_errors')
        break

      case loading:
        setType('uploading')
        break

      default:
        break
    }
  }, [completed, errorCount, loading])

  return <span className={styles.root}>{getMapComponent(TITLE_MAP, type, { errorCount, t })}</span>
}
