import { useEffect } from 'react'
import {
  ContactStatusEnum,
  BusinessAccountDirectionEnum,
  BusinessAccountStatusEnum,
  PlatformInvitationType
} from '@medentee/enums'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import {
  accountIdSelector,
  isProfessionalAccountSelector,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EModalComponents } from 'App/containers'
import { getDataByInvitationToken } from 'api/profile'
import { DEFAULT_ROUTE } from 'globalConstants'
import { handleError } from 'api'

type TParams = {
  id?: string
  token?: string
}

export const useUserProfileConnectByInvitation = (invitationToken = '') => {
  const dispatch = useAppDispatch()

  const { replace } = useHistory()

  const { id, token = '' } = useParams<TParams>()

  const { t } = useTranslation()

  const isProfessionalAccount = useAppSelector(isProfessionalAccountSelector)

  const accountId = useAppSelector(accountIdSelector)

  const { data, isFetching } = useQuery({
    queryKey: ['get-data-by-invitation-token', token || invitationToken],
    queryFn: () => getDataByInvitationToken(token || invitationToken),
    cacheTime: 0,
    enabled: Boolean(token || invitationToken),
    onSuccess: ({ sender }) => {
      if (!sender?.id) {
        replace(DEFAULT_ROUTE)
        return
      }

      if (sender?.id === accountId) {
        replace(`/profile/${accountId}`)
        return
      }
    },
    onError: (e) => {
      handleError(e)
      replace(DEFAULT_ROUTE)
    }
  })

  const hasNotRejectedStaffInvitation =
    data?.type === PlatformInvitationType.STAFF &&
    data.sender.id === data.inviteOrganization?.businessAccountId &&
    data.inviteOrganization?.direction === BusinessAccountDirectionEnum.INCOMING &&
    data?.inviteOrganization?.status !== BusinessAccountStatusEnum.REJECTED

  const hasApprovedStaffCrossInvitation =
    data?.type === PlatformInvitationType.STAFF &&
    data.sender.id === data.inviteOrganization?.businessAccountId &&
    data.inviteOrganization?.direction === BusinessAccountDirectionEnum.OUTGOING &&
    data?.inviteOrganization?.status === BusinessAccountStatusEnum.APPROVED

  const hasNotRejectedContactInvitation =
    data?.type === PlatformInvitationType.CONTACT &&
    accountId === data.inviteContact?.fromId &&
    data.inviteContact.status !== ContactStatusEnum.REJECTED

  const hasApprovedContactCrossInvitation =
    data?.type === PlatformInvitationType.CONTACT &&
    accountId === data.inviteContact?.toId &&
    data.inviteContact.status === ContactStatusEnum.APPROVED

  const hasInvitation =
    hasNotRejectedStaffInvitation ||
    hasNotRejectedContactInvitation ||
    hasApprovedContactCrossInvitation ||
    hasApprovedStaffCrossInvitation

  const shouldShowInvitationModal = Boolean(
    isProfessionalAccount &&
      token &&
      !hasInvitation &&
      data?.sender?.id &&
      data.type !== PlatformInvitationType.STAFF
  )

  const shouldShowJoinModal = Boolean(
    isProfessionalAccount &&
      token &&
      !hasInvitation &&
      data?.sender?.id &&
      data.type === PlatformInvitationType.STAFF
  )

  const shouldShowInvitationAccountSwitchModal = Boolean(
    !isProfessionalAccount &&
      token &&
      data?.sender?.id &&
      data.type !== PlatformInvitationType.STAFF
  )
  const shouldShowJoinAccountSwitchModal = Boolean(
    !isProfessionalAccount &&
      token &&
      data?.sender?.id &&
      data.type === PlatformInvitationType.STAFF
  )

  const invitationSenderId = data?.sender?.id
  const profileId = id ?? invitationSenderId

  useEffect(() => {
    if ((shouldShowInvitationModal || shouldShowJoinModal) && data?.type) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.CONNECT_BY_LINK_DIALOG,
          modalTitle:
            data.type === PlatformInvitationType.STAFF
              ? 'Join Organization via link'
              : 'Connect via link',
          modalProps: {
            token,
            type: data.type,
            inviteContact: data?.inviteContact,
            inviteOrganization: data?.inviteOrganization,
            profileId
          }
        })
      )
    }

    if (shouldShowJoinAccountSwitchModal || shouldShowInvitationAccountSwitchModal) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.SWITCH_ACCOUNT_DIALOG,
          modalTitle: t('modal.switchAccountConfirm.title'),
          modalProps: {
            onSuccess: () => {
              window.location.href = `${window.location.origin}/profile/t/${token}`
            }
          }
        })
      )
    }
  }, [
    dispatch,
    token,
    shouldShowInvitationModal,
    shouldShowInvitationAccountSwitchModal,
    shouldShowJoinAccountSwitchModal,
    shouldShowJoinModal,
    data?.type,
    data?.inviteContact,
    data?.inviteOrganization,
    profileId,
    t
  ])

  return {
    isFetching,
    token,
    profileId,
    type: data?.type,
    inviteContact: data?.inviteContact,
    inviteOrganization: data?.inviteOrganization,
    shouldShowAccountSwitchModal:
      shouldShowJoinAccountSwitchModal || shouldShowInvitationAccountSwitchModal
  }
}
