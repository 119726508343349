import { Row, Col, Radio } from 'antd'
import { Field, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { StepsFooterActions, TStepsFooterActionsProps } from 'App/components'
import { validation } from 'utils/validation'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'

import { TScheduleMeetingModalFormValues } from '../ScheduleMeetingModal'

import styles from './ScheduleMeetingModalFirstStep.module.scss'

export type TScheduleMeetingModalFirstStepProps = Pick<
  TStepsFooterActionsProps,
  'onSubmit' | 'onClickBack'
>

export const ScheduleMeetingModalFirstStep = ({
  onSubmit,
  onClickBack
}: TScheduleMeetingModalFirstStepProps) => {
  const { change } = useForm<TScheduleMeetingModalFormValues>()

  const { t } = useTranslation()

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Field name="type" validate={validation.required()}>
          {({ input }) => (
            <Radio.Group
              {...input}
              onChange={(e) => {
                input.onChange(e)
                change('location', undefined)
              }}
              className={styles.radioGroup}
            >
              <Radio value="ONLINE">
                <span className={styles.radioLabel}>
                  {t('modal.scheduleMeeting.locationStep.onPlatformRadio')}{' '}
                  <MessageTextIcon className={styles.icon} />
                </span>
              </Radio>
              <div className={styles.description}>
                {t('modal.scheduleMeeting.locationStep.onPlatformDescription')}
              </div>

              <Radio value="OFFLINE">
                <span className={styles.radioLabel}>
                  {t('modal.scheduleMeeting.locationStep.anotherLocationRadio')}{' '}
                  <LocationIcon className={styles.icon} />
                </span>
              </Radio>
              <div className={styles.description}>
                {t('modal.scheduleMeeting.locationStep.anotherLocationDescription')}
              </div>
            </Radio.Group>
          )}
        </Field>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <StepsFooterActions
            onSubmit={onSubmit}
            onClickBack={onClickBack}
            submitLabel={t('modal.scheduleMeeting.locationStep.submitButton')}
          />
        </Row>
      </Col>
    </Row>
  )
}
