import { useCallback, useMemo } from 'react'
import { MeetingInviteStatusEnum, MeetingStatusEnum, MeetingTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import {
  REJECT_MEETING_INVITE,
  rejectMeetingInviteRequest,
  showModalAction,
  TMeeting,
  useAppDispatch
} from 'store'
import { ReactComponent as CancelIcon } from 'assets/icons/Cancel.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as ICalIcon } from 'assets/icons/ShareOutline.svg'

import { getGoogleCalendarUrl, getICalFile } from '../../../api'
import { downloadFileFromUrl } from '../../../utils'

const loadingActions = [REJECT_MEETING_INVITE]

type TUseKebabMenuProps = {
  meeting?: TMeeting
  cancelOnCloseCallback?: () => void
  cancelOnSuccessCallback?: () => void
  editOnCloseCallback?: () => void
  editOnSuccessCallback?: () => void
  rejectOnSuccessCallback?: () => void
}

type TMenuItemsProps = Pick<TMeeting, 'id' | 'status'> & {
  isOrganizer: boolean

  inviteStatus?: MeetingInviteStatusEnum
  disableReject?: boolean
}

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = ({
  meeting,
  cancelOnCloseCallback,
  cancelOnSuccessCallback,
  editOnCloseCallback,
  editOnSuccessCallback,
  rejectOnSuccessCallback
}: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isCaseMeeting = Boolean(meeting?.case?.id)

  const menuItems = useMemo(
    () => ({
      addToCalendar: ({ status, id }: TMenuItemsProps) => ({
        hidden: status === MeetingStatusEnum.CANCELLED || status === MeetingStatusEnum.EXPIRED,
        content: t('modal.meetings.kebabMenu.addToGoogleCalendar'),
        icon: <CalendarIcon />,
        onClick: () => {
          window.open(getGoogleCalendarUrl(id))
        }
      }),
      exportToICal: ({ status, id }: TMenuItemsProps) => ({
        hidden: status === MeetingStatusEnum.CANCELLED || status === MeetingStatusEnum.EXPIRED,
        content: t('modal.meetings.kebabMenu.exportAsICal'),
        icon: <ICalIcon />,
        onClick: () => {
          downloadFileFromUrl({ content: getICalFile(id) })
        }
      }),
      editScheduled: ({ status, isOrganizer }: TMenuItemsProps) => ({
        hidden:
          !isOrganizer ||
          status === MeetingStatusEnum.OPEN ||
          status === MeetingStatusEnum.CANCELLED ||
          status === MeetingStatusEnum.EXPIRED ||
          (meeting?.type === MeetingTypeEnum.OFFLINE &&
            new Date(meeting?.startOn ?? 0).getTime() < Date.now()),
        content: t('modal.meetings.kebabMenu.edit'),
        icon: <PencilOutlineIcon />,
        onClick: () => {
          meeting &&
            dispatch(
              showModalAction({
                modalTitle: t('modal.scheduleMeeting.title_edit'),
                modalType: EModalComponents.EDIT_SCHEDULED_MEETING,
                modalProps: {
                  meeting,
                  onClose: editOnCloseCallback,
                  onSuccess: editOnSuccessCallback
                }
              })
            )
        }
      }),
      editOngoing: ({ status, isOrganizer }: TMenuItemsProps) => ({
        hidden:
          !isOrganizer ||
          !isCaseMeeting ||
          status === MeetingStatusEnum.PENDING ||
          status === MeetingStatusEnum.CANCELLED ||
          status === MeetingStatusEnum.REJECTED ||
          status === MeetingStatusEnum.EXPIRED,
        content: t('modal.meetings.kebabMenu.edit'),
        icon: <PencilOutlineIcon />,
        onClick: () => {
          meeting &&
            dispatch(
              showModalAction({
                modalTitle: t('modal.scheduleMeeting.title_edit'),
                modalType: EModalComponents.EDIT_ONGOING_MEETING,
                modalProps: {
                  meeting,
                  onClose: editOnCloseCallback,
                  onSuccess: editOnSuccessCallback
                }
              })
            )
        }
      }),
      cancel: ({ id, status, isOrganizer }: TMenuItemsProps) => ({
        hidden: !isOrganizer || status !== MeetingStatusEnum.PENDING,
        content: t('modal.meetings.kebabMenu.cancel'),
        icon: <CancelIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.cancelMeetingConfirm.title'),
              modalType: EModalComponents.CANCEL_MEETING,
              modalProps: {
                id,
                onClose: cancelOnCloseCallback,
                onSuccess: cancelOnSuccessCallback
              }
            })
          )
      }),
      reject: ({ id, inviteStatus, isOrganizer, disableReject }: TMenuItemsProps) => ({
        hidden: isOrganizer || disableReject || inviteStatus !== MeetingInviteStatusEnum.ACCEPTED,
        content: t('modal.meetings.kebabMenu.reject'),
        icon: <CrossIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.rejectMeetingConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                loadingActions,
                processingId: id,
                cancelLabel: t('modal.rejectMeetingConfirm.closeButton'),
                confirmLabel: t('modal.rejectMeetingConfirm.submitButton'),
                onCancel: cancelOnCloseCallback,
                content: t('modal.rejectMeetingConfirm.content'),
                onConfirm: () => {
                  dispatch(
                    rejectMeetingInviteRequest({
                      id,
                      processingId: id,
                      onSuccess: rejectOnSuccessCallback
                    })
                  )
                }
              }
            })
          )
      })
    }),
    [
      meeting,
      dispatch,
      editOnCloseCallback,
      editOnSuccessCallback,
      isCaseMeeting,
      cancelOnCloseCallback,
      cancelOnSuccessCallback,
      rejectOnSuccessCallback,
      t
    ]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.addToCalendar(props),
      menuItems.exportToICal(props),
      menuItems.editScheduled(props),
      menuItems.editOngoing(props),
      menuItems.cancel(props),
      menuItems.reject(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
