import { UploadSourcesEnum } from '@medentee/enums'

import { TCallbackAfterUploadFile } from 'store'
import { DragAndDropContainer, TCaseMemberCloudContainerProps } from 'App/containers'
import {
  CaseCloudFilesListHeader,
  CaseCloudUploadedByMeFilesList,
  TCaseCloudFilesListProps,
  useSelection
} from 'App/components'

import { EmptyLists, TEmptyListsProps } from '../EmptyLists'

export type TUploadedFilesByMeProps = {
  onAfterUploadFile: TCallbackAfterUploadFile
  isMember: boolean
  isCaseLocked: boolean
  isCaseArchived: boolean
  loading: boolean
} & Pick<TCaseMemberCloudContainerProps, 'caseType' | 'uploadedByMeIds' | 'caseId' | 'caseStatus'> &
  Pick<TCaseCloudFilesListProps, 'getKebabMenu' | 'caseView' | 'filesView'> &
  Pick<TEmptyListsProps, 'isFiltered'>

export const UploadedFilesByMe = ({
  isMember,
  isCaseArchived,
  isCaseLocked,
  loading,
  uploadedByMeIds,
  caseId,
  caseStatus,
  caseView,
  filesView,
  isFiltered,
  onAfterUploadFile,
  getKebabMenu
}: TUploadedFilesByMeProps) => {
  const { selectMode, selectedIds, setSelectedIds, onSelect } = useSelection(uploadedByMeIds)

  const showHeader =
    !isCaseArchived && !loading && Boolean(uploadedByMeIds.length) && caseView === 'expanded'

  return (
    <>
      {showHeader && (
        <CaseCloudFilesListHeader
          spaces={[0, 12]}
          ids={uploadedByMeIds}
          selectedIds={selectedIds}
          isCaseArchived={isCaseArchived}
          getKebabMenu={getKebabMenu}
          variant={'upload-by-me'}
          setSelectedIds={setSelectedIds}
        />
      )}

      <DragAndDropContainer
        onAfterUploadFile={onAfterUploadFile}
        caseId={caseId}
        uploadedFrom={UploadSourcesEnum.CASE}
        hide={isCaseLocked || isCaseArchived}
      >
        {!loading && !uploadedByMeIds.length ? (
          <EmptyLists
            variant="upload-by-me"
            isCaseArchived={isCaseArchived}
            isCaseLocked={isCaseLocked}
            isMember={isMember}
            isFiltered={isFiltered}
          />
        ) : (
          <CaseCloudUploadedByMeFilesList
            caseId={caseId}
            caseStatus={caseStatus}
            uploadedByMeIds={uploadedByMeIds}
            loading={loading}
            selectedIds={selectedIds}
            caseView={caseView}
            filesView={filesView}
            selectMode={selectMode}
            getKebabMenu={getKebabMenu}
            onAfterUploadFile={onAfterUploadFile}
            onSelect={onSelect}
          />
        )}
      </DragAndDropContainer>
    </>
  )
}
