export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST'
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS'
export const UPLOAD_FILES_ERROR = 'UPLOAD_FILES_ERROR'

export const UPLOAD_FILES_MOVE_FILE_TO_PROGRESS = 'UPLOAD_FILES_MOVE_FILE_TO_PROGRESS'
export const UPLOAD_FILES_REMOVE_FILE_FROM_PROGRESS = 'UPLOAD_FILES_REMOVE_FILE_FROM_PROGRESS'

export const GET_CASE_MED_CLOUD_FILES = 'GET_CASE_MED_CLOUD_FILES'
export const GET_CASE_MED_CLOUD_FILES_REQUEST = 'GET_CASE_MED_CLOUD_FILES_REQUEST'
export const GET_CASE_MED_CLOUD_FILES_SUCCESS = 'GET_CASE_MED_CLOUD_FILES_SUCCESS'
export const GET_CASE_MED_CLOUD_FILES_ERROR = 'GET_CASE_MED_CLOUD_FILES_ERROR'

export const GET_CASE_LINKED_FILES = 'GET_CASE_LINKED_FILES'
export const GET_CASE_LINKED_FILES_REQUEST = 'GET_CASE_LINKED_FILES_REQUEST'
export const GET_CASE_LINKED_FILES_SUCCESS = 'GET_CASE_LINKED_FILES_SUCCESS'
export const GET_CASE_LINKED_FILES_ERROR = 'GET_CASE_LINKED_FILES_ERROR'

export const ATTACH_CASE_LINKED_FILES = 'ATTACH_CASE_LINKED_FILES'
export const ATTACH_CASE_LINKED_FILES_REQUEST = 'ATTACH_CASE_LINKED_FILES_REQUEST'
export const ATTACH_CASE_LINKED_FILES_SUCCESS = 'ATTACH_CASE_LINKED_FILES_SUCCESS'
export const ATTACH_CASE_LINKED_FILES_ERROR = 'ATTACH_CASE_LINKED_FILES_ERROR'

export const ATTACH_SELECTED_CASE_MED_CLOUD_FILES = 'ATTACH_SELECTED_CASE_MED_CLOUD_FILES'
export const ATTACH_SELECTED_CASE_MED_CLOUD_FILES_REQUEST =
  'ATTACH_SELECTED_CASE_MED_CLOUD_FILES_REQUEST'
export const ATTACH_SELECTED_CASE_MED_CLOUD_FILES_SUCCESS =
  'ATTACH_SELECTED_CASE_MED_CLOUD_FILES_SUCCESS'
export const ATTACH_SELECTED_CASE_MED_CLOUD_FILES_ERROR =
  'ATTACH_SELECTED_CASE_MED_CLOUD_FILES_ERROR'

export const ATTACH_SELECTED_CHAT_MED_CLOUD_FILES = 'ATTACH_SELECTED_CHAT_MED_CLOUD_FILES'
export const ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_REQUEST =
  'ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_REQUEST'
export const ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_SUCCESS =
  'ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_SUCCESS'
export const ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_ERROR =
  'ATTACH_SELECTED_CHAT_MED_CLOUD_FILES_ERROR'

export const ATTACH_CHAT_MED_CLOUD_FILE = 'ATTACH_CHAT_MED_CLOUD_FILE'
export const ATTACH_CHAT_MED_CLOUD_FILE_REQUEST = 'ATTACH_CHAT_MED_CLOUD_FILE_REQUEST'
export const ATTACH_CHAT_MED_CLOUD_FILE_SUCCESS = 'ATTACH_CHAT_MED_CLOUD_FILE_SUCCESS'
export const ATTACH_CHAT_MED_CLOUD_FILE_ERROR = 'ATTACH_CHAT_MED_CLOUD_FILE_ERROR'

export const GET_CHAT_MED_CLOUD_FILES = 'GET_CHAT_MED_CLOUD_FILES'
export const GET_CHAT_MED_CLOUD_FILES_REQUEST = 'GET_CHAT_MED_CLOUD_FILES_REQUEST'
export const GET_CHAT_MED_CLOUD_FILES_SUCCESS = 'GET_CHAT_MED_CLOUD_FILES_SUCCESS'
export const GET_CHAT_MED_CLOUD_FILES_ERROR = 'GET_CHAT_MED_CLOUD_FILES_ERROR'

export const COPY_FILE_TO_MEDCLOUD = 'COPY_FILE_TO_MEDCLOUD'
export const COPY_FILE_TO_MEDCLOUD_REQUEST = 'COPY_FILE_TO_MEDCLOUD_REQUEST'
export const COPY_FILE_TO_MEDCLOUD_SUCCESS = 'COPY_FILE_TO_MEDCLOUD_SUCCESS'
export const COPY_FILE_TO_MEDCLOUD_ERROR = 'COPY_FILE_TO_MEDCLOUD_ERROR'

export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR'

export const UPLOAD_FILES_COLLAPSE_WIDGET = 'UPLOAD_FILES_COLLAPSE_WIDGET'

export const UPLOAD_FILES_CLOSE_WIDGET = 'UPLOAD_FILES_CLOSE_WIDGET'

export const SET_UPLOAD_FILE_STATUS = 'SET_UPLOAD_FILE_STATUS'

export const SET_UPLOAD_FILE_PERCENT = 'SET_UPLOAD_FILE_PERCENT'

export const REMOVE_FILE_FROM_FOR_UPLOAD_LIST = 'REMOVE_FILE_FROM_FOR_UPLOAD_LIST'

export const SET_NEW_NAME_UPLOAD_FILE_IN_WIDGET = 'SET_NEW_NAME_UPLOAD_FILE_IN_WIDGET'

export const SELECT_ATTACH_FILE_ACTION = 'SELECT_ATTACH_FILE_ACTION'

export const START_FAKE_UPLOAD_ACTION = 'START_FAKE_UPLOAD_ACTION'

export const SELECT_ATTACH_UPLOAD_FILE_ACTION = 'SELECT_ATTACH_UPLOAD_FILE_ACTION'

export const SET_FILES_TO_ATTACH_ACTION = 'SET_FILES_TO_ATTACH_ACTION'

export const RESET_ATTACH_FILES = 'RESET_ATTACH_FILES'

export const REMOVE_FILE_FROM_WIDGET_ACTION = 'REMOVE_FILE_FROM_WIDGET_ACTION'

export const MED_CLOUD_ABORT_UPLOAD = 'MED_CLOUD_ABORT_UPLOAD'

export const MED_CLOUD_FILE_INVALIDATE = 'MED_CLOUD_FILE_INVALIDATE'

export const SET_FAKE_FILE_IN_WIDGET = 'SET_FAKE_FILE_IN_WIDGET'

export const SET_UPLOAD_FILE_PRE_SIGN_KEY = 'SET_UPLOAD_FILE_PRE_SIGN_KEY'

export const CANCEL_UPLOAD_FILE = 'CANCEL_UPLOAD_FILE'

export const SET_UPLOADING_START_DATE = 'SET_UPLOADING_START_DATE'

export const SET_SELECTED_ATTACH_FILES = 'SET_SELECTED_ATTACH_FILES'

export const SET_CASE_LINKED_FILES_SEARCH = 'SET_CASE_LINKED_FILES_SEARCH'
export const SET_CASE_LINKED_FILES_FILTER = 'SET_CASE_LINKED_FILES_FILTER'
export const RESET_CASE_LINKED_FILES_FILTER = 'RESET_CASE_LINKED_FILES_FILTER'

export const SET_ATTACHED_FILES = 'SET_ATTACHED_FILES'
