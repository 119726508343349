import { FormEventHandler, SyntheticEvent, useCallback, useEffect } from 'react'
import { Form } from 'react-final-form'
import { FormApi } from 'final-form'
import noop from 'lodash/noop'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { switchAccount } from 'api/profile'
import { businessAccountRegistration } from 'api/invitations'
import { TCity, TCompanySpecialization, TCountry, TError } from 'store'
import { Steps, BusinessAccountInfo, StepsFooterActions, TOption } from 'App/components'
import { TBusinessAccountCreateDialogContainerProps } from 'App/containers'
import { getMapComponent } from 'utils'
import { useSteps } from 'App/hooks'

import { CompanyDetails } from './CompanyDetails'
import { CompanyAddress } from './CompanyAddress'
import { Subscription } from './Subscription'
import { CompanyEmail } from './CompanyEmail'
import styles from './BusinessAccountCreateDialog.module.scss'
import { CompanyConfirmCode } from './CompanyConfirmCode'

export type TBusinessAccountCreateDialogProps = TBusinessAccountCreateDialogContainerProps

export type TCreateBusinessAccountFormValues = {
  companyName: string
  email: string
  size: TOption
  country: TCountry
  city: TCity
  zip: string
  addressLine1: string
  addressLine2: string
  companyType: TCompanySpecialization
  confirmationCode: string
  expiredTokenDate: string | null
}

const DEFAULT_STEPS = [{}, {}, {}, {}]

enum StepEnum {
  STEP_INFO = -2,
  STEP_SUBSCRIPTION = -1,
  STEP_ONE = 0,
  STEP_TWO = 1,
  STEP_THREE = 2,
  STEP_FOUR = 3
}

type TStepsMapProps = Pick<
  TBusinessAccountCreateDialogProps,
  | 'companySpecializations'
  | 'cities'
  | 'getCities'
  | 'countries'
  | 'getAvailableSubscriptions'
  | 'loadingAvailableSubscriptions'
  | 'ids'
  | 'loadingCities'
  | 'loadingCountries'
  | 'loadingSpecializations'
> & {
  form: FormApi<TCreateBusinessAccountFormValues>
  error: TError
  processing: boolean
  handleStepBack: () => void
  handleStepForward: () => void
  handleSubmit: (
    event?: Partial<SyntheticEvent>
  ) => Promise<Record<string, any> | undefined> | undefined
  handleConfirmInfo: () => void
  t: TFunction
}

const STEPS_MAP = new Map<StepEnum, (props: TStepsMapProps) => JSX.Element>()
  .set(StepEnum.STEP_INFO, ({ handleConfirmInfo }) => (
    <BusinessAccountInfo onClick={handleConfirmInfo} />
  ))
  .set(
    StepEnum.STEP_SUBSCRIPTION,
    ({ loadingAvailableSubscriptions, ids, getAvailableSubscriptions, handleStepForward }) => (
      <Subscription
        ids={ids}
        loadingAvailableSubscriptions={loadingAvailableSubscriptions}
        getAvailableSubscriptions={getAvailableSubscriptions}
        handleChooseSubscription={handleStepForward}
      />
    )
  )
  .set(StepEnum.STEP_ONE, ({ handleStepBack, handleStepForward, form }) => (
    <CompanyEmail
      form={form}
      handleStepForward={handleStepForward}
      handleStepBack={handleStepBack}
    />
  ))
  .set(StepEnum.STEP_TWO, ({ form, handleStepBack, handleStepForward }) => (
    <CompanyConfirmCode
      form={form}
      handleStepForward={handleStepForward}
      handleStepBack={handleStepBack}
    />
  ))
  .set(
    StepEnum.STEP_THREE,
    ({
      form,
      companySpecializations,
      processing,
      loadingSpecializations,
      handleStepBack,
      handleStepForward,
      t
    }) => {
      const formState = form.getState()

      return (
        <CompanyDetails
          email={formState.values.email}
          companySpecializations={companySpecializations}
          actions={
            <StepsFooterActions
              submitLoading={processing}
              submitLabel={t('modal.createBusinessAccount.companyDetailsStep.submitButton')}
              submitDisabled={formState.invalid}
              onClickBack={handleStepBack}
              onSubmit={handleStepForward}
            />
          }
          loadingSpecializations={loadingSpecializations}
        />
      )
    }
  )
  .set(
    StepEnum.STEP_FOUR,
    ({
      form,
      cities,
      countries,
      error,
      processing,
      loadingCities,
      loadingCountries,
      getCities,
      handleStepBack,
      handleSubmit,
      t
    }) => (
      <CompanyAddress
        form={form}
        cities={cities}
        countries={countries}
        error={error}
        actions={
          <StepsFooterActions
            submitLoading={processing}
            submitLabel={t('modal.createBusinessAccount.companyAddressStep.submitButton')}
            submitDisabled={form.getState().invalid}
            onClickBack={handleStepBack}
            onSubmit={handleSubmit}
          />
        }
        loadingCities={loadingCities}
        loadingCountries={loadingCountries}
        getCities={getCities}
      />
    )
  )

export const BusinessAccountCreateDialog = ({
  countries,
  cities,
  companySpecializations,
  loadingAvailableSubscriptions,
  ids,
  loadingCities,
  loadingCountries,
  loadingSpecializations,
  getCountries,
  getCities,
  setModalTitle,
  getCompanySpecializations,
  getAvailableSubscriptions
}: TBusinessAccountCreateDialogProps) => {
  const { t } = useTranslation()

  const { step, setNextStep, setPrevStep } = useSteps(StepEnum.STEP_INFO)

  const isInfoSteps = step === StepEnum.STEP_INFO || step === StepEnum.STEP_SUBSCRIPTION

  const {
    error: errorSwitchAccount,
    isLoading: isLoadingSwitchAccount,
    mutate: mutateSwitchAccount
  } = useMutation({
    mutationKey: ['business-account-registration-switch'],
    mutationFn: switchAccount,
    onError: noop,
    onSuccess: () => {
      window.location.pathname = '/profile/edit/basic'
    }
  })

  const { error, isLoading, mutate } = useMutation({
    mutationKey: ['business-account-registration'],
    mutationFn: businessAccountRegistration,
    onError: noop,
    onSuccess: (data) => {
      mutateSwitchAccount({ accountId: data.id })
    }
  })

  const handleConfirmInfo = useCallback(() => {
    setModalTitle({
      modalTitle: 'Create Business Account'
    })
    setNextStep()
  }, [setNextStep, setModalTitle])

  const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => e.preventDefault(),
    []
  )

  const onSubmit = (values: TCreateBusinessAccountFormValues) => {
    const {
      companyName,
      companyType,
      addressLine1,
      addressLine2,
      city,
      country,
      email,
      size,
      zip,
      confirmationCode
    } = values

    mutate({
      businessAccountTypeId: companyType.id,
      cityId: `${city.id}`,
      countryCode: country.code,
      size: size.value,
      companyName,
      addressLine2,
      addressLine1,
      email,
      zip,
      token: confirmationCode
    })
  }

  useEffect(() => {
    getCountries()
    getCompanySpecializations()
  }, [getCompanySpecializations, getCountries])

  return (
    <div className={styles.root}>
      {!isInfoSteps && <Steps current={step} stepsArray={DEFAULT_STEPS} className={styles.steps} />}

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleFormSubmit} className={styles.form}>
            {getMapComponent(STEPS_MAP, step, {
              form,
              countries,
              cities,
              companySpecializations,
              loadingAvailableSubscriptions,
              ids,
              loadingCities,
              loadingCountries,
              loadingSpecializations,
              processing: isLoading || isLoadingSwitchAccount,
              error: error || errorSwitchAccount,
              getCities,
              getAvailableSubscriptions,
              handleSubmit,
              handleConfirmInfo,
              handleStepBack: setPrevStep,
              handleStepForward: setNextStep,
              t
            })}
          </form>
        )}
      </Form>
    </div>
  )
}
