import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  GET_CASES_LINKS,
  GET_LINK_CASE_LIST,
  toggleCaseLinkRequest
} from 'store'
import { CaseLinkItem, TCaseLinkItemBaseProps } from 'App/components'

export type TCaseLinkItemContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const loadingSelector = createLoadingSelector([GET_CASES_LINKS, GET_LINK_CASE_LIST])

const mapStateToProps = (state: State, { caseItemId, isView }: TCaseLinkItemBaseProps) => {
  const caseItem = isView
    ? state.cases.caseLinks.linkedCases[caseItemId]
    : state.cases.linkCases.list[caseItemId]

  return {
    caseItem,
    accountId: state.global.accountData?.id ?? '',
    loading: loadingSelector(state),
    hasLink: state.cases.caseLinks.ids.includes(caseItemId)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleLink: toggleCaseLinkRequest
    },
    dispatch
  )

export const CaseLinkItemContainer = connect(mapStateToProps, mapDispatchToProps)(CaseLinkItem)
