import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EModalComponents, TSettingsSecurityAndLogin2FAContainerProps } from 'App/containers'
import {
  SettingsItemTitle,
  Switch,
  SettingsItemDescription,
  SettingsItemSubtitle
} from 'App/components'

export type TSettingsSecurityAndLogin2FAProps = TSettingsSecurityAndLogin2FAContainerProps

export const SettingsSecurityAndLogin2FA = ({
  showModal,
  enabled
}: TSettingsSecurityAndLogin2FAProps) => {
  const { t } = useTranslation()

  const handleChange = useCallback(() => {
    showModal({
      modalTitle: enabled ? t('modal.2FA.title_disable') : t('modal.2FA.title_enable'),
      modalType: EModalComponents.SETTINGS_2FA_CONFIRM
    })
  }, [showModal, enabled, t])

  return (
    <>
      <SettingsItemTitle>{t('settings.security.2FA.title')}</SettingsItemTitle>
      <SettingsItemSubtitle addon={<Switch onChange={handleChange} checked={enabled} />}>
        {t('settings.security.2FA.subtitle')}
      </SettingsItemSubtitle>
      <SettingsItemDescription>{t('settings.security.2FA.description')}</SettingsItemDescription>
    </>
  )
}
