import { ReactNode, useCallback, useEffect } from 'react'
import { AccountTypeNames, ChatMuteEnum, ChatStatusEnum, ChatTypeEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  ChatRoomHeaderP2PContainer,
  ChatFieldContainer,
  ChatMessageListContainer,
  ChatQuotingMessageContainer,
  ChatEditingMessageContainer,
  ChatDetails,
  useMuteTextChannel,
  ChatPinContainer,
  EModalComponents
} from 'App/containers'
import { ChatRoomWrapper } from 'App/components'
import { EChatViewType } from 'enums'
import { usePrevious } from 'App/hooks'
import { Button } from 'App/components/common/Button'
import {
  hideModalAction,
  isSelectedChatP2PSelector,
  REMOVE_ALL_CHAT_PIN_MESSAGES,
  removeAllChatPinMessagesRequest,
  resetSelectedChatAction,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { ReactComponent as UnpinOutlinedIcon } from 'assets/icons/UnpinOutlined.svg'

import { useChatContext } from '../../../../context'
import { ChatRoomSearch } from '../../ChatRoomSearch'

import styles from './ChatRoomP2P.module.scss'

export type TChatRoomP2PProps = {
  interlocutorAccount?: AccountTypeNames
  selectedChatStatus?: ChatStatusEnum
  activeChatId?: string | null
  isContact?: boolean
  title?: ReactNode
  type?: ChatTypeEnum
  entityId?: string
  isBusinessAccount?: boolean
  isChannelManager?: boolean
  isMuted?: boolean
}

export const P2P_CHAT_HEADER_WRAPPER_ID = 'p2p-chat-header-wrapper'
export const P2P_CHAT_FOOTER_WRAPPER_ID = 'p2p-chat-footer-wrapper'

export const ChatRoomP2P = ({
  activeChatId,
  selectedChatStatus,
  isContact,
  title,
  type,
  isBusinessAccount,
  isChannelManager,
  isMuted,
  entityId
}: TChatRoomP2PProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isP2P = useAppSelector(isSelectedChatP2PSelector)

  const {
    showPinnedMessages,
    showChatRoomSearch,
    showChatDetails,
    togglePinnedMessages,
    hideChatRoomSearch,
    hideChatDetails
  } = useChatContext()

  const prevIsContact = usePrevious(isContact)

  const isChatStatusInactive = selectedChatStatus === ChatStatusEnum.INACTIVE
  const isCommunityNews = type === ChatTypeEnum.COMMUNITY_NEWS
  const isEventNews = type === ChatTypeEnum.EVENT_NEWS

  const onRemoveAll = () => {
    if (!activeChatId) {
      return
    }

    dispatch(
      showModalAction({
        modalType: EModalComponents.GENERIC_CONFIRMATION,
        modalTitle: t('modal.unpinAllMessageConfirm.title'),
        modalProps: {
          onConfirm: () => {
            dispatch(
              removeAllChatPinMessagesRequest({
                chatId: activeChatId,
                onSuccess: () => {
                  dispatch(hideModalAction())
                  dispatch(resetSelectedChatAction())
                  togglePinnedMessages()
                }
              })
            )
          },
          confirmLabel: t('modal.unpinAllMessageConfirm.submitButton'),
          content: t('modal.unpinAllMessageConfirm.content'),
          loadingActions: [REMOVE_ALL_CHAT_PIN_MESSAGES]
        }
      })
    )
  }

  useEffect(() => {
    if (prevIsContact && isContact === false) {
      hideChatDetails()
    }
  }, [prevIsContact, isContact, hideChatDetails])

  useEffect(
    () => hideChatRoomSearch(),
    // `activeChatId` is essential
    [activeChatId, hideChatRoomSearch]
  )

  const {
    isLoading: isMuteLoading,
    handleMuteTextChannel,
    handleUnmuteTextChannel
  } = useMuteTextChannel()

  const handleToggleMute = useCallback(() => {
    if (!activeChatId) {
      return
    }

    isMuted
      ? handleUnmuteTextChannel(activeChatId)
      : handleMuteTextChannel(activeChatId, ChatMuteEnum.PERMANENT)()
  }, [handleMuteTextChannel, handleUnmuteTextChannel, isMuted, activeChatId])

  const getFooter = () => {
    const shouldShowMuteButton =
      (isCommunityNews || isEventNews) &&
      !isBusinessAccount &&
      !isChannelManager &&
      !showChatDetails &&
      !showPinnedMessages
    const shouldShowFooter = activeChatId && !showChatDetails

    if (shouldShowMuteButton) {
      return (
        <div className={styles.footerAction} id={P2P_CHAT_FOOTER_WRAPPER_ID}>
          <Button
            className={styles.footerActionButton}
            loading={isMuteLoading}
            disabled={isMuteLoading}
            variant="inline"
            onClick={handleToggleMute}
          >
            {isMuted ? t('chat.messages.unmuteButton') : t('chat.messages.muteButton')}
          </Button>
        </div>
      )
    }

    if (showPinnedMessages) {
      if (!isP2P) {
        return <div className={styles.unpinAllMessagesPlaceholder} />
      }

      return (
        <div className={styles.footerAction} id={P2P_CHAT_FOOTER_WRAPPER_ID}>
          <Button
            className={styles.footerActionButton}
            icon={<UnpinOutlinedIcon />}
            iconPosition="right"
            variant="inline"
            onClick={onRemoveAll}
          >
            {t('chat.messages.unpinAllMessageButton')}
          </Button>
        </div>
      )
    }

    if (shouldShowFooter) {
      return (
        <div className={styles.footer} id={P2P_CHAT_FOOTER_WRAPPER_ID}>
          <ChatQuotingMessageContainer />
          <ChatEditingMessageContainer />
          <ChatFieldContainer />
        </div>
      )
    }

    return null
  }

  return (
    <ChatRoomWrapper
      dndLayout="fixed"
      dndDisabled={isChatStatusInactive}
      activeChatId={activeChatId}
      header={
        <div className={styles.header} id={P2P_CHAT_HEADER_WRAPPER_ID}>
          <ChatRoomHeaderP2PContainer title={title} />
          {!showChatDetails && !showPinnedMessages && <ChatPinContainer />}
          {!showChatDetails && showChatRoomSearch && <ChatRoomSearch />}
        </div>
      }
      main={
        showChatDetails ? (
          <ChatDetails entityId={entityId} />
        ) : (
          activeChatId && (
            <div className={styles.mainWrapper}>
              <ChatMessageListContainer
                chatViewType={EChatViewType.P2P}
                chatId={activeChatId}
                classes={{
                  scrollInner: cls(
                    styles.scrollInner,
                    isChatStatusInactive && styles.scrollInnerInactive
                  )
                }}
                defaultMessageClassName={styles.defaultMessage}
              />
            </div>
          )
        )
      }
      footer={getFooter()}
      classes={{
        root: styles.root,
        headerWrapper: styles.headerWrapper,
        main: cls(styles.main, { [styles.details]: showChatDetails }),
        footerWrapper: styles.footerWrapper
      }}
    />
  )
}
