import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { CasesInviteItem } from 'App/components'

export type TCasesInvitationItemContainerOwnProps = {
  id: string
}

export type TCasesInvitationItemContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: State, ownProps: TCasesInvitationItemContainerOwnProps) => ({
  ...(state.casesInvite.casesInviteList[ownProps.id] || {}),
  description: state.casesInvite.casesInviteList[ownProps.id]?.description
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const CasesInviteItemContainer: FC<TCasesInvitationItemContainerOwnProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CasesInviteItem)
