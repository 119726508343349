import { useState } from 'react'
import cls from 'classnames'
import { PopoverProps } from 'antd'

import { Popover } from 'App/components'
import { ReactComponent as ChevronDown } from 'assets/icons/ChevronDown.svg'

import styles from './DropDownMenu.module.scss'

export type DropDownMenuProps = PropsWithClasses<
  'root' | 'title' | 'item' | 'list',
  {
    list: Array<JSX.Element | string | null>
    title: string
    onChange: (index: any) => void
  } & Pick<PopoverProps, 'placement'>
>

export const DropDownMenu = ({
  list,
  title,
  classes,
  onChange,
  placement = 'bottom'
}: DropDownMenuProps) => {
  const [open, setOpen] = useState(false)
  const toggleMenuShow = () => setOpen(!open)
  const validList = list.filter(Boolean)

  const handleTitleChange = (key: string) => {
    onChange(key)
    toggleMenuShow()
  }

  return validList.length !== 1 ? (
    <Popover
      className={styles.popover}
      arrow={false}
      placement={placement}
      autoAdjustOverflow={true}
      destroyTooltipOnHide={true}
      visible={open}
      trigger="click"
      onVisibleChange={setOpen}
      getPopupContainer={(node) => node}
      content={
        <div className={cls(styles.dropdownMenu, classes?.list)}>
          {list.map(
            (button, index) =>
              !!button && (
                <button
                  key={typeof button === 'string' ? button : `${button?.key ?? ''}${index}`}
                  className={cls(styles.dropdownMenuButton, classes?.item)}
                  onClick={() => handleTitleChange(String(index))}
                  disabled={Number(title) === index}
                >
                  {button}
                </button>
              )
          )}
        </div>
      }
    >
      <div className={cls(styles.root, classes?.root)}>
        <div
          className={cls({ [styles.title]: true, [styles.titleActive]: open }, classes?.title)}
          onClick={toggleMenuShow}
        >
          {list[Number(title)]} <ChevronDown />
        </div>
      </div>
    </Popover>
  ) : (
    <h5 className={cls(styles.title, classes?.root)}>{list[Number(title)]}</h5>
  )
}
