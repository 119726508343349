import { useCallback, useEffect, useState, ReactNode, useMemo } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  TwoFactorTimer,
  TwoFactorCodeGroupe,
  Spinner,
  StepsFooterActions,
  TStepsFooterActionsProps
} from 'App/components'
import { usePrevious } from 'App/hooks'

import styles from './TwoFactorChecker.module.scss'

export type TTwoFactorCheckerProps = {
  expiredTokenDate: null | string | Date
  onResendCode: () => void
  onConfirm: (code: string) => void
  processing: boolean

  subDescription?: ReactNode
  onClearInvalid?: () => void
  invalid?: boolean
  codeExhausted?: boolean
  limitExpired?: boolean
} & Pick<TStepsFooterActionsProps, 'submitLabel' | 'onClickBack'>

export const TwoFactorChecker = ({
  expiredTokenDate,
  processing,
  subDescription,
  codeExhausted,
  submitLabel,
  onResendCode,
  onConfirm,
  onClearInvalid,
  onClickBack,
  invalid = false
}: TTwoFactorCheckerProps) => {
  const { t } = useTranslation()

  const [code, setCode] = useState<string>('')
  const [filledCode, setFilledCode] = useState<boolean>(false)
  const [timeOver, setTimeOver] = useState<boolean>(false)

  const prevCode = usePrevious(code)

  const handleChangeCode = useCallback((value: string, filled: boolean) => {
    setCode(value)
    setFilledCode(filled)
  }, [])

  const handleConfirm = useCallback(() => {
    if (code) {
      onConfirm(code)
    }
  }, [code, onConfirm])

  const handleResendCode = useCallback(() => {
    onResendCode()
    setCode('')
    setTimeOver(false)

    if (onClearInvalid && codeExhausted) {
      onClearInvalid()
    }
  }, [onResendCode, onClearInvalid, codeExhausted])

  useEffect(() => {
    if (onClearInvalid && invalid && prevCode !== code) {
      onClearInvalid()
    }
  }, [onClearInvalid, code, prevCode, invalid])

  const disabled = useMemo(() => {
    if (timeOver || !code || !filledCode || invalid || codeExhausted || !expiredTokenDate) {
      return true
    }

    if (!timeOver && filledCode) {
      return false
    }

    return false
  }, [code, expiredTokenDate, filledCode, invalid, timeOver, codeExhausted])

  return (
    <div className={styles.root}>
      <p className={cls(styles.description, subDescription && styles.hasSubDescription)}>
        {subDescription ? t('common.2FA.codeDescription_email') : t('common.2FA.codeDescription')}
      </p>

      {subDescription}

      <TwoFactorTimer
        expiredTokenDate={expiredTokenDate}
        processing={processing}
        setTimeOver={setTimeOver}
        timeOver={timeOver}
        codeExhausted={codeExhausted}
      />

      <div className={styles.code}>
        <TwoFactorCodeGroupe invalid={invalid} onChange={handleChangeCode} value={code} />
      </div>

      <div className={styles.resend}>
        {processing ? (
          <Spinner />
        ) : (
          <button
            tabIndex={7}
            type="button"
            className={styles.buttonLink}
            onClick={handleResendCode}
          >
            {t('common.2FA.resendCodeButton')}
          </button>
        )}
      </div>

      <StepsFooterActions
        submitLabel={submitLabel ?? t('common.2FA.submitButton')}
        submitDisabled={disabled || processing}
        onClickBack={onClickBack}
        onSubmit={handleConfirm}
        submitTabIndex={6}
        backTabIndex={8}
      />
    </div>
  )
}
