import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { IconButton } from 'App/components'
import { ReactComponent as AttachIcon } from 'assets/icons/Attach.svg'
import { ReactComponent as RecordIcon } from 'assets/icons/Record.svg'
import { ReactComponent as PaperAirplaneIcon } from 'assets/icons/PaperAirplane.svg'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './TextFieldFooter.module.scss'

export type TTextFieldFooterProps = {
  processing: boolean
  shouldShowMessageIcon: boolean
  setShowAudio: (arg: boolean) => void
  onSend: () => void
  onOpenAttach: () => void

  readonly?: boolean
  disabledSubmit?: boolean
}

const TextFieldFooterView = ({
  processing,
  shouldShowMessageIcon,
  readonly,
  disabledSubmit,
  setShowAudio,
  onOpenAttach,
  onSend
}: TTextFieldFooterProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleShowAudio = useCallback(() => setShowAudio(true), [setShowAudio])

  return (
    <div className={styles.root}>
      {!readonly && (
        <IconButton
          iconComponent={<AttachIcon />}
          iconSize={isMobile ? EIconSize.LG : EIconSize.MD}
          classes={{ root: styles.icon }}
          onClick={onOpenAttach}
        />
      )}
      {shouldShowMessageIcon ? (
        <IconButton
          iconComponent={<PaperAirplaneIcon />}
          iconSize={isMobile ? EIconSize.LG : EIconSize.MD}
          classes={{ root: styles.sendIcon }}
          onClick={onSend}
          loading={processing}
          disabled={disabledSubmit || processing}
        />
      ) : (
        <IconButton
          iconComponent={<RecordIcon />}
          toolTip={t('modal.broadcastMessage.audioMessageTooltip')}
          iconSize={isMobile ? EIconSize.LG : EIconSize.MD}
          classes={{ root: styles.icon }}
          onClick={handleShowAudio}
        />
      )}
    </div>
  )
}

export const TextFieldFooter = memo(TextFieldFooterView)
