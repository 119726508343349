import { normalize, schema } from 'normalizr'

import { TContactsData, TContactsNormalized, TContactsNormalizeResult } from './contacts.types'

const contactsSchema = new schema.Entity('list')
const contactsListSchema = new schema.Array(contactsSchema)

export const contactsNormalize = (originalData: TContactsData[]): TContactsNormalized => {
  const {
    entities: { list },
    result: ids
  }: TContactsNormalizeResult = normalize(originalData, contactsListSchema)

  return {
    list: list || {},
    ids: ids || []
  }
}
