import { BroadcastTypeEnum } from '@medentee/enums'
import { useMemo } from 'react'

import { useQuery } from 'services/query'
import { getCommunityMembers } from 'api/communities'
import { getEventAttendees } from 'api/events'
import { TAccountData } from 'store/store.types'
import { APIResultsResponse } from 'services/api'

export type TUseBroadcastMembersStepRequestsProps = {
  entityId: string
  type: BroadcastTypeEnum

  page?: number
  search?: string
}

export const useBroadcastMembersStepRequests = ({
  entityId,
  type,
  page,
  search
}: TUseBroadcastMembersStepRequestsProps) => {
  const { data: communityMembers, isInitialLoading: isFetchingCommunityMembers } = useQuery({
    queryKey: ['community-members', search, page],
    queryFn: () => getCommunityMembers({ communityId: entityId, search, page }),
    keepPreviousData: true,
    select: (response) => ({
      ...response,
      results: response.results.map((item) => item.account)
    }),
    cacheTime: 0,
    enabled: type === BroadcastTypeEnum.COMMUNITY
  })

  const { data: eventAttendees, isInitialLoading: isFetchingEventAttendees } = useQuery({
    queryKey: ['event-attendees', search, page],
    queryFn: () => getEventAttendees({ eventId: entityId, search, page }),
    keepPreviousData: true,
    select: (response) => ({
      ...response,
      results: response.results.map((item) => item.account)
    }),
    cacheTime: 0,
    enabled: type === BroadcastTypeEnum.EVENT
  })

  const DATA_MAP = useMemo(
    () =>
      new Map<BroadcastTypeEnum, APIResultsResponse<TAccountData[]>['data'] | undefined>()
        .set(BroadcastTypeEnum.COMMUNITY, communityMembers)
        .set(BroadcastTypeEnum.EVENT, eventAttendees),
    [communityMembers, eventAttendees]
  )

  return {
    data: DATA_MAP.get(type),
    isFetching: isFetchingEventAttendees || isFetchingCommunityMembers
  }
}
