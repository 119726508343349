import { TAccountsSelectGroupsItem } from 'App/components/common/AccountsSelect/AccountsSelectGroups'
import { IDepartment } from 'interfaces'
import { TBroadcastGroupEntity, TCategoryListData, TOrganization } from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

import i18n from '../../../../../i18n'

import {
  ContactsSearchCascader,
  TContactsSearchCascaderProps
} from './SelectRecipientsSearchCascader/ContactsSearchCascader'
import {
  AllContactsSearchCascader,
  TAllContactsSearchCascaderProps
} from './SelectRecipientsSearchCascader/AllContactsSearchCascader'
import {
  StaffSearchCascader,
  TStaffSearchCascaderProps
} from './SelectRecipientsSearchCascader/StaffSearchCascader'
import { TBroadcastRecipientsType } from './useSelectRecipientsRequests'

const ALL_CONTACTS_PREFIX = 'allContacts'
const CATEGORY_PREFIX = 'category'
const ORGANIZATION_PREFIX = 'organization'
const DEPARTMENT_PREFIX = 'department'

type TParseCategoriesResponseProps = {
  categories?: TCategoryListData[]
}

export const parseCategoriesResponse = ({
  categories
}: TParseCategoriesResponseProps): TAccountsSelectGroupsItem[] => {
  const allContacts: TAccountsSelectGroupsItem = {
    label: i18n.t('modal.broadcastMessage.recipientsStep.contactsCheckbox'),
    value: ALL_CONTACTS_PREFIX,
    selected: false
  }

  return categories?.length
    ? [
        {
          ...allContacts,
          items: categories.map(({ id, name }) => ({
            label: name,
            value: `${CATEGORY_PREFIX}-${id}`,
            selected: false
          }))
        }
      ]
    : [allContacts]
}

type TParseDepartmentsResponseProps = {
  departments?: IDepartment[]
  organization?: TOrganization
}

export const parseDepartmentsResponse = ({
  departments,
  organization
}: TParseDepartmentsResponseProps): TAccountsSelectGroupsItem | null =>
  organization
    ? {
        label: organization.businessProfile.name,
        value: `${ORGANIZATION_PREFIX}-${organization.id}`,
        selected: false,
        items: departments?.map((department) => ({
          label: department.name,
          value: `${ORGANIZATION_PREFIX}-${organization.id}-${DEPARTMENT_PREFIX}-${department.id}`,
          selected: false
        }))
      }
    : null

export const SEARCH_CASCADER_MAP = new Map<
  TBroadcastRecipientsType,
  (
    props: TStaffSearchCascaderProps &
      TContactsSearchCascaderProps &
      TAllContactsSearchCascaderProps
  ) => JSX.Element
>()
  .set('contacts', ({ onChange, onSearchChange }) => (
    <ContactsSearchCascader onChange={onChange} onSearchChange={onSearchChange} />
  ))
  .set('staff', ({ organizationId, onChange, onSearchChange }) => (
    <StaffSearchCascader
      organizationId={organizationId}
      onChange={onChange}
      onSearchChange={onSearchChange}
    />
  ))
  .set('all', ({ onChange, onSearchChange }) => (
    <AllContactsSearchCascader onChange={onChange} onSearchChange={onSearchChange} />
  ))

export const flattenItem = (
  item: TAccountsSelectGroupsItem
): [string, TAccountsSelectGroupsItem][] => {
  const itemValue: [string, TAccountsSelectGroupsItem] = [item.value, item]

  if (item.items) {
    return item.items.reduce<[string, TAccountsSelectGroupsItem][]>(
      (res, child): [string, TAccountsSelectGroupsItem][] => {
        const name = /department/.test(child.value)
          ? `${child.label} (${item.label})`
          : i18n.t('modal.broadcastMessage.recipientsStep.selectedCategory', {
              category: child.label
            })

        return [...res, [child.value, { ...child, label: name }]]
      },
      [itemValue]
    )
  }

  return [itemValue]
}

type TPrepareRequestPayloadResponse = {
  categories: TBroadcastGroupEntity[]
  departments: TBroadcastGroupEntity[]
  organizations: TBroadcastGroupEntity[]
}

export const prepareRequestPayload = (ids: TAccountsSelectOptionData[]) => {
  const { categories, departments, organizations } = ids.reduce<TPrepareRequestPayloadResponse>(
    (res, item): TPrepareRequestPayloadResponse => {
      if (item.id.startsWith(ALL_CONTACTS_PREFIX)) {
        return {
          ...res,
          categories: [
            {
              id: ALL_CONTACTS_PREFIX,
              selectId: item.id,
              name: item.displayUserName
            }
          ]
        }
      }

      if (item.id.startsWith(CATEGORY_PREFIX)) {
        const itemId = item.id.split('-')[1]

        return {
          ...res,
          categories: [
            ...res.categories,
            { id: itemId, selectId: item.id, name: item.displayUserName }
          ]
        }
      }

      if (new RegExp(`${DEPARTMENT_PREFIX}`).test(item.id)) {
        const itemId = item.id.split('-')[1]

        return {
          ...res,
          departments: [
            ...res.departments,
            { id: itemId, selectId: item.id, name: item.displayUserName }
          ]
        }
      }

      const itemId = item.id.split('-')[1]

      return {
        ...res,
        organizations: [
          ...res.organizations,
          { id: itemId, selectId: item.id, name: item.displayUserName }
        ]
      }
    },
    { categories: [], departments: [], organizations: [] } as TPrepareRequestPayloadResponse
  )
  return {
    categories,
    departments,
    organizations
  }
}
