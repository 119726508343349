import { forwardRef, ForwardRefRenderFunction, memo, useCallback } from 'react'
import { Row } from 'antd'
import { differenceInSeconds, formatDistanceToNow } from 'date-fns'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import {
  AccountName,
  ContextMenu,
  ShowMore,
  TAccountNameProps,
  TMenuItemProps
} from 'App/components/common'
import { FormattedText, TFormattedTextProps } from 'App/components/common/DraftEditor'
import { useAdaptiveLayout } from 'App/hooks'

import { DeleteConfirm } from './DeleteConfirm'
import styles from './Comment.module.scss'

export type TEvent = {
  type: 'delete'
  commentId: string
  confirm: (id: string) => void
  close: (id: string) => void

  confirmationText?: string
  processing?: boolean
}

export type TCommentProps = Pick<TFormattedTextProps, 'mentions'> & {
  author: Pick<TAccountNameProps, 'firstName' | 'lastName' | 'displayUserName' | 'userId' | 'type'>
  id: string
  createdAt: string | Date
  updatedAt: string | Date
  text: string
  isOwner: boolean
  animateHighlight: boolean

  variant?: 'comment' | 'reply'
  replies?: React.ReactNode
  event?: TEvent
  getMenuItems?: (
    props: Pick<TCommentProps, 'author' | 'id' | 'text' | 'isOwner' | 'mentions'>
  ) => TMenuItemProps[]
}

const CommentView: ForwardRefRenderFunction<HTMLDivElement, TCommentProps> = (
  {
    author,
    createdAt,
    updatedAt,
    text,
    id,
    isOwner,
    event,
    replies,
    mentions,
    animateHighlight,
    getMenuItems,
    variant = 'comment'
  },
  ref
) => {
  const { t } = useTranslation()

  const { isDesktop } = useAdaptiveLayout()

  const visibleHeight = isDesktop ? styles.visibleHeight : styles.visibleHeightTablet

  const nonUrlPartsRenderer = useCallback(
    (content: string) => (
      <FormattedText text={content} mentions={mentions} formatters={['emoji', 'mention']} />
    ),
    [mentions]
  )
  const isEdited = differenceInSeconds(new Date(updatedAt), new Date(createdAt)) > 0

  return (
    <div
      className={cls(styles.root, {
        [styles.relative]: event?.type === 'delete',
        [styles[variant]]: !!variant,
        [styles.animateHighlight]: animateHighlight
      })}
      ref={ref}
    >
      {event?.type === 'delete' && (
        <DeleteConfirm
          id={id}
          processing={event.processing}
          onConfirm={event.confirm}
          onClose={event.close}
          confirmationText={event.confirmationText}
        />
      )}

      <Row wrap={false} justify="space-between" align="top">
        <AccountName size={EAvatarSize.XS} showAvatar={true} {...author}>
          <span className={styles.time}>
            {formatDistanceToNow(new Date(updatedAt), { addSuffix: true })}
          </span>
          {isEdited && (
            <span className={styles.edited}>{t('showcases.item.comments.editedLabel')}</span>
          )}
        </AccountName>

        {getMenuItems && (
          <ContextMenu menuItems={getMenuItems({ author, text, id, isOwner, mentions })} />
        )}
      </Row>

      <ShowMore
        text={text}
        classes={{ wrapper: styles.text }}
        nonUrlPartsRenderer={nonUrlPartsRenderer}
        visibleHeight={Number(visibleHeight)}
        limitExpandedHeight={false}
      />

      {replies}
    </div>
  )
}

export const Comment = memo(forwardRef(CommentView))
