import { useEffect, useMemo, useState } from 'react'

import { EChatHistoryEvents, EIconSize } from 'enums'
import { resetSelectedChatAction, setChatHistoryEventAction, useAppDispatch } from 'store'
import { ChatPinItemContainer, TChatPinContainerBaseProps } from 'App/containers'
import { IconButton, TChatPinItemBaseProps } from 'App/components'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg'
import { ReactComponent as PinListIcon } from 'assets/icons/PinList.svg'

import { useRefValue } from '../../../hooks'
import { useChatContext } from '../../../context'

import styles from './ChatPin.module.scss'

export type TChatPinBaseProps = Pick<TChatPinItemBaseProps, 'onClickPin'>

type TChatPinProps = TChatPinContainerBaseProps & TChatPinBaseProps

export const ChatPin = ({
  data,
  total,
  loading,
  selectedChat,
  getChatPin,
  onClickPin
}: TChatPinProps) => {
  const chatId = selectedChat?.id
  const dispatch = useAppDispatch()
  const { togglePinnedMessages, hideChatRoomSearch } = useChatContext()
  const [currentIndex, setCurrentIndex] = useState(0)

  const isDataEmpty = useMemo(() => !data && total === 0, [data, total])
  const { getValue: getData } = useRefValue(data)

  const scrollToMessage = (messageId: string) =>
    dispatch(
      setChatHistoryEventAction({
        type: EChatHistoryEvents.JUMP,
        payload: { eventTs: Date.now(), messageId }
      })
    )
  const nextItem = () => {
    setCurrentIndex((index) => {
      getChatPin({
        limit: 1,
        chatId: chatId || '',
        before: getData()?.id,
        onSuccess: (item) => {
          if (item) {
            scrollToMessage(item.id)
          }
        }
      })

      return index + 1
    })
  }
  const prevItem = () => {
    setCurrentIndex((index) => {
      getChatPin({
        limit: 1,
        chatId: chatId || '',
        after: getData()?.id,
        onSuccess: (item) => {
          if (item) {
            scrollToMessage(item.id)
          }
        }
      })

      return index - 1
    })
  }

  const handlePinnedMessagesListClick = () => {
    dispatch(resetSelectedChatAction({ type: 'pinned' }))
    togglePinnedMessages()
    hideChatRoomSearch()
  }

  useEffect(() => {
    if (chatId) {
      setCurrentIndex(0)
      getChatPin({ chatId, limit: 1 })
    }
  }, [chatId, total, setCurrentIndex, getChatPin])

  if (!chatId || isDataEmpty || (loading && isDataEmpty)) {
    return null
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.controls}>
          <IconButton
            iconComponent={<ChevronUpIcon />}
            iconSize={EIconSize.XL}
            onClick={prevItem}
            disabled={currentIndex === 0}
          />
          <IconButton
            iconComponent={<ChevronDownIcon />}
            iconSize={EIconSize.XL}
            onClick={nextItem}
            disabled={currentIndex === total - 1}
          />
        </div>
        <ChatPinItemContainer
          index={currentIndex}
          data={data}
          chatId={chatId}
          onClickPin={onClickPin}
        />
        <IconButton
          iconComponent={<PinListIcon />}
          iconSize={EIconSize.MD}
          onClick={handlePinnedMessagesListClick}
        />
      </div>
    </div>
  )
}
