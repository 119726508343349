import { memo } from 'react'
import isEqual from 'lodash/isEqual'

import { CommentList, TCommentListProps, TCommentEvent } from 'App/components/Comments/CommentList'
import { accountIdSelector, useAppSelector } from 'store'
import { TCommentFieldEvent } from 'App/containers'

import { RepliesWrapper } from '../Replies/RepliesWrapper'
import { TUseRequestComments, useRequestComments } from '../useRequestComments'

export type TCommentsContainerProps = Pick<TCommentListProps, 'getMenuItems'> &
  Pick<TUseRequestComments, 'fileId' | 'queryKey' | 'showcaseId' | 'initialAnchorId'> & {
    setFieldEvent: (event: TCommentFieldEvent) => void
    isShowcaseOwner: boolean

    commentEvent?: Omit<TCommentEvent, 'confirm' | 'processing'>
    classes?: {
      comments?: TCommentListProps['classes']
    }
  }

const CommentsContainerView = ({
  queryKey,
  showcaseId,
  fileId,
  commentEvent,
  isShowcaseOwner,
  classes,
  initialAnchorId,
  setFieldEvent,
  getMenuItems
}: TCommentsContainerProps) => {
  const accountId = useAppSelector(accountIdSelector)
  const {
    comments,
    hasAfter,
    hasBefore,
    historyEvent,
    loading,
    deleteCommentProcessing,
    anchorId,
    fetchData,
    handleCommentDelete
  } = useRequestComments({ queryKey, showcaseId, fileId, initialAnchorId })

  const isAllTab = !fileId

  return (
    <CommentList
      classes={classes?.comments}
      loading={loading}
      hasAfter={hasAfter}
      hasBefore={hasBefore}
      comments={comments}
      historyEvent={historyEvent}
      initialAnchorId={initialAnchorId}
      anchorId={anchorId}
      onNext={fetchData}
      placeholderText={
        isAllTab ? 'There is no comments for this showcase' : 'There is no comments for this item'
      }
      getMenuItems={getMenuItems}
      accountId={accountId}
      showcaseId={showcaseId}
      commentEvent={
        commentEvent
          ? {
              ...commentEvent,
              confirm: handleCommentDelete(commentEvent.commentId),
              processing: deleteCommentProcessing
            }
          : undefined
      }
      getReplies={({ id, repliesCount }) => (
        <RepliesWrapper
          commentsQueryKey={queryKey}
          showcaseId={showcaseId}
          commentId={id}
          isAllTab={isAllTab}
          setFieldEvent={setFieldEvent}
          isShowcaseOwner={isShowcaseOwner}
          repliesCount={repliesCount}
        />
      )}
    />
  )
}

export const CommentsContainer = memo(CommentsContainerView, isEqual)
