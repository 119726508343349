import { hideModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers'

import { useEvent } from './useEvent'

export const useCloseModal = (modalTypes: EModalComponents[]) => {
  const dispatch = useAppDispatch()
  const currentModalType = useAppSelector((state) => state.modal.modalType)

  const hideCurrentModal = useEvent(() => {
    if (currentModalType && modalTypes.includes(currentModalType)) {
      dispatch(hideModalAction())
    }
  })

  return hideCurrentModal
}
