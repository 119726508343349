import { normalize, schema } from 'normalizr'

import { TIds, TAttachMedcloudFile, TAttachMedcloudFilesList } from 'store'
import { ICasesCloudFile } from 'interfaces'

import { TCaseCloudFilesBase, TCaseCloudFilesList } from './caseCloud.types'

const caseListSchema = new schema.Entity('caseList', {}, { idAttribute: 'id' })
const memberSchema = new schema.Entity('memberList')
caseListSchema.define({
  members: [memberSchema]
})

type TNormalized<T> = Omit<TCaseCloudFilesBase<T>, 'total'>
type TNormalizeResult<T> = {
  entities: {
    list: T
  }
  result: TIds
}

const normalized =
  <D, T = Record<string, unknown>>() =>
  (originalData: D[]): TNormalized<T> => {
    const filesSchema = new schema.Entity('list', undefined, {
      idAttribute: (value) => {
        const fileId = value && value.file && value.file.id
        const id = value && value.id

        return fileId || id
      }
    })
    const fileListSchema = new schema.Array(filesSchema)
    const { entities, result }: TNormalizeResult<T> = normalize(originalData, fileListSchema)

    return {
      list: entities.list || ({} as T),
      ids: result || []
    }
  }

export const caseMedCloudFileNormalize = normalized<TAttachMedcloudFile, TAttachMedcloudFilesList>()

export const caseCloudFileNormalize = normalized<ICasesCloudFile, TCaseCloudFilesList>()
