import { FC, useEffect, useCallback } from 'react'
import cls from 'classnames'
import { ContactStatusEnum } from '@medentee/enums'
import { Trans, useTranslation } from 'react-i18next'

import {
  DELETE_CATEGORY,
  deleteCategoryRequest,
  createProcessingSelector,
  removeErrorNotification,
  getContactsRequest,
  useAppSelector,
  useAppDispatch
} from 'store'
import { ErrorModal, Button } from 'App/components'

import styles from './ConfirmRemoveCategory.module.scss'

export type TConfirmRemoveCategoryProps = {
  selectedCategoryId: string

  categoryName?: string
}

export const ConfirmRemoveCategory: FC<TConfirmRemoveCategoryProps> = ({
  categoryName,
  selectedCategoryId
}) => {
  const dispatch = useAppDispatch()

  const error = useAppSelector((state) => state.errors[DELETE_CATEGORY])
  const processing = useAppSelector(createProcessingSelector([DELETE_CATEGORY]))

  const { t } = useTranslation()

  useEffect(
    () => () => {
      dispatch(removeErrorNotification(DELETE_CATEGORY))
    },
    [dispatch]
  )

  const onSuccess = useCallback(() => {
    dispatch(
      getContactsRequest({
        status: ContactStatusEnum.APPROVED
      })
    )
  }, [dispatch])

  const handleRemove = useCallback(
    () => dispatch(deleteCategoryRequest({ id: selectedCategoryId, onSuccess })),
    [dispatch, onSuccess, selectedCategoryId]
  )

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>
        <Trans
          t={t}
          i18nKey="modal.removeCategoryConfirm.content"
          components={{
            categoryName: (
              <span className={styles.name}>
                {' '}
                &laquo;
                {categoryName ?? ''}
                &raquo;{' '}
              </span>
            )
          }}
        />
      </p>
      <Button
        className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
        loading={processing}
        onClick={handleRemove}
      >
        {t('modal.removeCategoryConfirm.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
