import { FC, ReactNode, RefObject, useEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import OriginalTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { Error, TErrorProps } from 'App/components/common/Fields/Error'
import { HelperText, LengthCounter, FieldLabel } from 'App/components'

import styles from './TextField.module.scss'

export enum ETextFieldSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md'
}

export type TTextFieldProps = {
  endAdornment?: ReactNode
  topLabel?: ReactNode | string | null
  valueLengthMax?: number
  size?: ETextFieldSize
  hideCounter?: boolean
  inputRef?: RefObject<HTMLInputElement>
  classes?: Partial<Record<TTextFieldClasses, string>>
} & Omit<TextFieldProps, 'variant' | 'helperText' | 'label' | 'size' | 'error'> &
  TErrorProps

type TTextFieldClasses = 'wrapper' | 'root' | 'topLabel'

export const TextField: FC<TTextFieldProps> = ({
  size = ETextFieldSize.MD,
  error,
  valueLengthMax,
  value: originalValue,
  defaultValue,
  topLabel,
  invalid,
  classes,
  endAdornment,
  onChange,
  hideCounter = false,
  inputRef,
  ...rest
}) => {
  const [valueLength, setValueLength] = useState<number>(0)
  const value = useMemo(() => {
    if (rest.rows && typeof originalValue === 'string') {
      return originalValue.replace(/(\r?\n)/g, '\r\n')
    }

    return originalValue
  }, [originalValue, rest.rows])

  const { t } = useTranslation()

  useEffect(() => {
    if (defaultValue && typeof defaultValue === 'string') {
      setValueLength(defaultValue.length)
    } else if (value && typeof value === 'string') {
      setValueLength(value.length)
    }
  }, [])

  useEffect(() => {
    const newValueLength = (value as any)?.length

    if (newValueLength !== valueLength) {
      setValueLength(newValueLength)
    }
  }, [value, valueLength])

  return (
    <div className={cls(styles.textFieldWrapper, classes?.wrapper)}>
      {topLabel && <FieldLabel className={classes?.topLabel} label={topLabel} />}
      <OriginalTextField
        className={cls(
          styles.rootField,
          size === ETextFieldSize.SM && styles.rootFieldSmall,
          classes?.root
        )}
        error={invalid}
        onChange={(event) => {
          if (onChange) {
            onChange(event)
          }
        }}
        value={value}
        defaultValue={defaultValue}
        variant={'outlined'}
        InputProps={{
          endAdornment,
          ref: inputRef
        }}
        {...rest}
      />
      <HelperText>
        <Row gutter={[20, 0]} wrap={false}>
          {!error?.includes(t('validationErrors.max', { value: valueLengthMax, ns: 'errors' })) && (
            <Col flex="auto">
              <Error error={error} invalid={invalid} />
            </Col>
          )}
          {!hideCounter && valueLengthMax && (
            <Col flex="auto">
              <LengthCounter currentLength={valueLength} maxLength={valueLengthMax} />
            </Col>
          )}
        </Row>
      </HelperText>
    </div>
  )
}
