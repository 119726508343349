import cls from 'classnames'

import { AnchorifedText, ContextMenu, TContextMenuProps } from 'App/components'

import styles from './ItemHeader.module.scss'

export type TItemHeaderProps = {
  title: string
} & Partial<Pick<TContextMenuProps, 'menuItems'>>

export const ItemHeader = ({
  title,
  className,
  menuItems
}: PropsWithClassName<TItemHeaderProps>) => (
  <div className={cls(styles.root, className)}>
    <div className={styles.title}>
      <AnchorifedText text={title} />
    </div>

    {menuItems && menuItems.length > 0 && <ContextMenu menuItems={menuItems} />}
  </div>
)
