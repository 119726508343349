import { AxiosError } from 'axios'

import { action, TError, TLastRequest, TPluralProcessingPayload } from 'store'
import { TCategoryListData } from 'store/categoryList/categoryList.types'

import {
  TAcceptContactInviteRequestPayload,
  TContactsSuccessPayload,
  TRemoveContactInviteRequestPayload,
  TSetContactsFiltersPayload,
  TSetCasesAutoApproveRequestPayload,
  TSetCasesAutoApproveSuccessPayload,
  TUpdatedContactCategoriesPayload
} from './contacts.types'

// ------------------ GET_CONTACTS ------------------

export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR'

export const getContactsRequest = (payload?: TLastRequest) => action(GET_CONTACTS_REQUEST, payload)
export type TGetContactsRequest = ReturnType<typeof getContactsRequest>

export const getContactsSuccess = (payload: TContactsSuccessPayload) =>
  action(GET_CONTACTS_SUCCESS, payload)
export type TGetContactsSuccess = ReturnType<typeof getContactsSuccess>

export const getContactsError = (payload: AxiosError) => action(GET_CONTACTS_ERROR, payload)
export type TGetContactsError = ReturnType<typeof getContactsError>

// ------------------ REMOVE_CONTACT_INVITE ---------------

export const REMOVE_CONTACT_INVITE = 'REMOVE_CONTACT_INVITE'
export const REMOVE_CONTACT_INVITE_REQUEST = 'REMOVE_CONTACT_INVITE_REQUEST'
export const REMOVE_CONTACT_INVITE_SUCCESS = 'REMOVE_CONTACT_INVITE_SUCCESS'
export const REMOVE_CONTACT_INVITE_ERROR = 'REMOVE_CONTACT_INVITE_ERROR'

export const removeContactInviteRequest = (payload: TRemoveContactInviteRequestPayload) =>
  action(REMOVE_CONTACT_INVITE_REQUEST, payload)
export type TRemoveSentContactsRequest = ReturnType<typeof removeContactInviteRequest>

export const removeContactInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(REMOVE_CONTACT_INVITE_SUCCESS, payload)

export const removeContactInviteError = (payload: TPluralProcessingPayload | AxiosError | null) =>
  action(REMOVE_CONTACT_INVITE_ERROR, payload)
export type TRemoveContactError = ReturnType<typeof removeContactInviteError>

// ------------------ ACCEPT_CONTACT_INVITE ------------------

export const ACCEPT_CONTACT_INVITE = 'ACCEPT_CONTACT_INVITE'
export const ACCEPT_CONTACT_INVITE_REQUEST = 'ACCEPT_CONTACT_INVITE_REQUEST'
export const ACCEPT_CONTACT_INVITE_SUCCESS = 'ACCEPT_CONTACT_INVITE_SUCCESS'
export const ACCEPT_CONTACT_INVITE_ERROR = 'ACCEPT_CONTACT_INVITE_ERROR'

export const acceptContactsInviteRequest = (payload: TAcceptContactInviteRequestPayload) =>
  action(ACCEPT_CONTACT_INVITE_REQUEST, payload)
export type TActionContactRequest = ReturnType<typeof acceptContactsInviteRequest>

export const acceptContactInviteSuccess = (payload: TPluralProcessingPayload) =>
  action(ACCEPT_CONTACT_INVITE_SUCCESS, payload)
export type TActionContactSuccess = ReturnType<typeof acceptContactInviteSuccess>

export const acceptContactInviteError = (payload: TPluralProcessingPayload | AxiosError | null) =>
  action(ACCEPT_CONTACT_INVITE_ERROR, payload)
export type TActionContactError = ReturnType<typeof acceptContactInviteError>

// ------------------ FILTER ------------------

export const SET_LOADING_CONTACTS_FILTER = 'SET_LOADING_CONTACTS_FILTER'
export const SET_SEARCH_CONTACTS_FILTER = 'SET_SEARCH_CONTACTS_FILTER'
export const SET_PAGE_CONTACTS_FILTER = 'SET_PAGE_CONTACTS_FILTER'
export const SELECT_CATEGORIES_CONTACTS_FILTER = 'SELECT_CATEGORIES_CONTACTS_FILTER'
export const SELECT_ALL_CATEGORIES_CONTACTS_FILTER = 'SELECT_ALL_CATEGORIES_CONTACTS_FILTER'
export const SET_NOT_ASSIGNED_CONTACTS_FILTER = 'SET_NOT_ASSIGNED_CONTACTS_FILTER'
export const SET_CONTACTS_FILTER = 'SET_CONTACTS_FILTER'
export const RESET_CONTACTS_FILTER = 'RESET_CONTACTS_FILTER'

export const setLoadingContactsFilter = (payload: { loading: boolean }) =>
  action(SET_LOADING_CONTACTS_FILTER, payload)
export type TSetLoadingContactsFilter = ReturnType<typeof setLoadingContactsFilter>

export const setSearchContactsFilter = (payload: { search: string; searchBy: string }) =>
  action(SET_SEARCH_CONTACTS_FILTER, payload)
export type TSetSearchContactsFilter = ReturnType<typeof setSearchContactsFilter>

export const setPageContactsFilter = (payload: { page: number }) =>
  action(SET_PAGE_CONTACTS_FILTER, payload)
export type TSetPageContactsFilter = ReturnType<typeof setPageContactsFilter>

export const selectCategoriesContactsFilter = (payload: { category: TCategoryListData }) =>
  action(SELECT_CATEGORIES_CONTACTS_FILTER, payload)
export type TSelectCategoriesContactsFilter = ReturnType<typeof selectCategoriesContactsFilter>

export const selectAllCategoriesContactsFilter = (payload: { checked: TCategoryListData[] }) =>
  action(SELECT_ALL_CATEGORIES_CONTACTS_FILTER, payload)
export type TSelectAllCategoriesContactsFilter = ReturnType<
  typeof selectAllCategoriesContactsFilter
>

export const setNotAssignedContactsFilter = (payload: { notAssigned: boolean }) =>
  action(SET_NOT_ASSIGNED_CONTACTS_FILTER, payload)
export type TSetNotAssignedContactsFilter = ReturnType<typeof setNotAssignedContactsFilter>

export const setContactsFilters = (payload: TSetContactsFiltersPayload) =>
  action(SET_CONTACTS_FILTER, payload)

export const resetContactsFilter = () => action(RESET_CONTACTS_FILTER)

// ------------------ RESET_CONTACTS ---------------

export const RESET_CONTACTS = 'RESET_CONTACTS'

export const resetContacts = () => action(RESET_CONTACTS)

// ------------------ SET_CASES_AUTO_APPROVE ---------------
export const SET_CASES_AUTO_APPROVE_REQUEST = 'SET_CASES_AUTO_APPROVE_REQUEST'
export const SET_CASES_AUTO_APPROVE_SUCCESS = 'SET_CASES_AUTO_APPROVE_SUCCESS'
export const SET_CASES_AUTO_APPROVE_ERROR = 'SET_CASES_AUTO_APPROVE_ERROR'

export const setCasesAutoApproveRequest = (payload: TSetCasesAutoApproveRequestPayload) =>
  action(SET_CASES_AUTO_APPROVE_REQUEST, payload)
export const setCasesAutoApproveSuccess = (payload: TSetCasesAutoApproveSuccessPayload) =>
  action(SET_CASES_AUTO_APPROVE_SUCCESS, payload)
export const setCasesAutoApproveError = (payload: TError) =>
  action(SET_CASES_AUTO_APPROVE_ERROR, payload)

// ------------------ UPDATED_CONTACT_CATEGORIES ------------------

export const UPDATED_CONTACT_CATEGORIES = 'SELECT_DEFAULT_CATEGORIES'

export const updatedContactCategories = (payload: TUpdatedContactCategoriesPayload) =>
  action(UPDATED_CONTACT_CATEGORIES, payload)
