import { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import { Tabs } from 'antd'
import cls from 'classnames'

import { isMobileBrowser } from 'utils'
import { useAdaptiveLayout } from 'App/hooks'
import { NotificationBadge, TNotificationBadgeProps } from 'App/components/common'

import styles from './NavMenu.module.scss'

type TNavMenuSize = 'sm' | 'md'

export type TNavMenuItem = {
  link: string
  title: string

  notification?: TNotificationBadgeProps
  exact?: boolean
  icon?: ReactElement
  hidden?: boolean
}

export type TNavMenuProps = {
  items: TNavMenuItem[]
  tabPosition: 'top' | 'left'

  classes?: Partial<Record<TNavMenuClasses, string>>
  hasTitle?: boolean
  size?: TNavMenuSize
}

export type TNavMenuClasses = 'root' | 'item' | 'itemSelected' | 'icon' | 'title'

export const NavMenu = ({
  items,
  classes,
  hasTitle = true,
  size = 'sm',
  tabPosition = 'left'
}: TNavMenuProps) => {
  const { isDesktop } = useAdaptiveLayout()

  return (
    <Tabs
      tabPosition={tabPosition}
      className={cls(
        styles.root,
        styles[size],
        styles[`root--${tabPosition}`],
        classes?.root,
        isMobileBrowser() && styles.device
      )}
    >
      {items
        .filter(({ hidden }) => !hidden)
        .map((item: TNavMenuItem) => (
          <Tabs.TabPane
            key={item.title}
            closable={true}
            tab={
              <NavLink
                key={item.link}
                className={cls(
                  styles.item,
                  styles[`item--${tabPosition}`],
                  hasTitle && styles.itemWTitle,
                  classes?.item
                )}
                activeClassName={cls(
                  styles.itemSelected,
                  styles[`itemSelected--${tabPosition}`],
                  classes?.itemSelected
                )}
                exact={item.exact}
                to={item.link}
              >
                {!isDesktop && item.icon && (
                  <div className={cls(styles.icon, classes?.icon)}>{item.icon}</div>
                )}
                <span className={cls(styles.title, classes?.title)}>{item.title} </span>
                {item.notification && (
                  <NotificationBadge
                    {...item.notification}
                    className={cls(styles[`notification--${item.notification.variant}`])}
                  />
                )}
              </NavLink>
            }
          />
        ))}
    </Tabs>
  )
}
