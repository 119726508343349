import { useCallback } from 'react'

import { TAvailableSubscriptionContainerProps } from 'App/containers'

import { AvailableSubscriptionView } from './AvailableSubscriptionView'

export type TAvailableSubscriptionProps = TAvailableSubscriptionContainerProps

export const AvailableSubscription = ({
  id,
  activateSubscriptions,
  handleChooseSubscription,
  ...rest
}: TAvailableSubscriptionProps) => {
  const handleActivateSubscription = useCallback(
    () =>
      handleChooseSubscription
        ? handleChooseSubscription(id)
        : activateSubscriptions({ subscriptionId: id, processingId: id }),
    [id, activateSubscriptions, handleChooseSubscription]
  )

  return <AvailableSubscriptionView id={id} {...rest} action={handleActivateSubscription} />
}
