import { Field } from 'react-final-form'
import { isAfter, isBefore } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { validation, rangeDate } from 'utils'
import { DatePicker } from 'App/components/common/Fields/DatePicker'
import { MIN_PICKER_DATE } from 'globalConstants'

export const StartDateField = () => {
  const { t } = useTranslation()

  return (
    <Field name="startDate" validate={validation.required()}>
      {({ input, meta }) => (
        <DatePicker
          {...input}
          picker="month"
          format={rangeDate}
          topLabel={t('modal.userLicenses.form.startDate')}
          disabledDate={(date) => isAfter(date, new Date()) || isBefore(date, MIN_PICKER_DATE)}
          invalid={meta.touched && meta.invalid}
          error={meta.error}
        />
      )}
    </Field>
  )
}
