import { TValidatorResponse } from 'utils'

import styles from './Error.module.scss'

export type TErrorProps = {
  invalid?: boolean
  error?: TValidatorResponse
}

export const Error = ({ invalid, error }: TErrorProps) =>
  invalid && error ? <pre className={styles.root}>{error}</pre> : null
