import { CaseStatusesEnum } from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation, Trans } from 'react-i18next'

import { Help, Alert } from 'App/components'
import { getMapComponent } from 'utils'

import styles from './CaseDetailsWarningPanel.module.scss'

export type TCaseDetailsWarningPanelProps = {
  status: CaseStatusesEnum

  isMember?: boolean
}

type TPanelMapProps = Pick<TCaseDetailsWarningPanelProps, 'isMember'> & {
  t: TFunction
}

const PANEL_MAP = new Map<CaseStatusesEnum, (props: TPanelMapProps) => JSX.Element>()
  .set(CaseStatusesEnum.LOCKED, ({ isMember, t }) => {
    if (isMember) {
      return (
        <Trans
          t={t}
          i18nKey="cases.details.warningPanel.locked.content_member"
          components={{
            span: (
              <span className={styles.help}>
                unlocking <Help content={t('cases.details.warningPanel.locked.help_member')} />
              </span>
            ),
            help: <Help content={t('cases.details.warningPanel.locked.help_member')} />
          }}
        />
      )
    }

    return (
      <Trans
        t={t}
        i18nKey="cases.details.warningPanel.locked.content_owner"
        components={{
          span: (
            <span className={styles.help}>
              unlocking <Help content={t('cases.details.warningPanel.locked.help_owner')} />
            </span>
          ),
          help: <Help content={t('cases.details.warningPanel.locked.help_owner')} />
        }}
      />
    )
  })
  .set(CaseStatusesEnum.ARCHIVED, ({ t }) => (
    <>{t('cases.details.warningPanel.archived.content')}</>
  ))

export const CaseDetailsWarningPanel = ({
  status,
  isMember = false
}: TCaseDetailsWarningPanelProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Alert variant="warning">{getMapComponent(PANEL_MAP, status, { isMember, t })}</Alert>
    </div>
  )
}
