import { call, put, takeLatest } from 'redux-saga/effects'
import { AccountTypeIdEnum } from '@medentee/enums'

import { API, api, APIData } from 'services/api'
import { QueryBuilder } from 'utils/QueryBuilder'
import { getCityNameWithTimezone } from 'utils'
import {
  getAllProfessionsError,
  getAllProfessionsSuccess,
  getPurposesError,
  getPurposesSuccess,
  GET_ALL_PROFESSIONS_REQUEST,
  GET_PURPOSES_REQUEST,
  TPurpose
} from 'store'

import {
  TCity,
  TCompanySpecialization,
  TCountry,
  TGetCitiesRequest,
  TGetCountriesRequest,
  TGetVideoStreamUrlSuccess,
  TProfessionDTO,
  TProfession
} from './misc.types'
import {
  GET_CITIES_REQUEST,
  GET_COMPANY_SPECIALIZATIONS_REQUEST,
  GET_COUNTRIES_REQUEST,
  GET_PROFESSIONS_REQUEST,
  getCitiesError,
  getCitiesSuccess,
  getCompanySpecializationsError,
  getCompanySpecializationsSuccess,
  getCountriesError,
  getCountriesSuccess,
  getProfessionsError,
  getProfessionsSuccess,
  getVideoStreamUrlError,
  getVideoStreamUrlSuccess,
  GET_VIDEO_STREAM_URL_REQUEST
} from './misc.actions'
import { splitProfessions } from './misc.utils'

function* getCountriesSaga({ payload }: TGetCountriesRequest) {
  try {
    const queryBuilder = new QueryBuilder(API.COUNTRIES)
    const url = queryBuilder.custom('mode', payload?.mode).build()

    const { data }: APIData<TCountry[]> = yield call(api.get, url)

    yield put(getCountriesSuccess(data))
  } catch (e) {
    yield put(getCountriesError(e))
  }
}

function* getCitiesSaga({ payload }: TGetCitiesRequest) {
  try {
    const queryBuilder = new QueryBuilder(API.CITIES)
    const url = queryBuilder.custom('countryCode', payload.countryCode).build()

    const { data }: APIData<TCity[]> = yield call(api.get, url)

    const formattedData: TCity[] = data.map((item) => ({
      ...item,
      cityName: getCityNameWithTimezone(item.cityName, item.timezone)
    }))

    yield put(getCitiesSuccess(formattedData))
  } catch (e) {
    yield put(getCitiesError(e))
  }
}

function* getCompanySpecializationsSaga() {
  try {
    const queryBuilder = new QueryBuilder(API.ALL_PROFESSIONS)
    const url = queryBuilder.custom('types', AccountTypeIdEnum.BUSINESS).build()

    const { data }: APIData<TCompanySpecialization[]> = yield call(api.get, url)

    yield put(getCompanySpecializationsSuccess(data))
  } catch (e) {
    yield put(getCompanySpecializationsError(e))
  }
}

function* getProfessionsSaga() {
  try {
    const queryBuilder = new QueryBuilder(API.ALL_PROFESSIONS)
    const url = queryBuilder.custom('types', AccountTypeIdEnum.PROFESSIONAL).build()

    const { data }: APIData<TProfession[]> = yield call(api.get, url)

    yield put(getProfessionsSuccess(data))
  } catch (e) {
    yield put(getProfessionsError(e))
  }
}

function* getAllProfessionsSaga() {
  try {
    const { data }: APIData<TProfessionDTO[]> = yield call(api.get, API.ALL_PROFESSIONS)

    const splittedProfessions = splitProfessions(data)

    yield put(getAllProfessionsSuccess(splittedProfessions))
  } catch (e) {
    yield put(getAllProfessionsError(e))
  }
}

function* getPurposesSaga() {
  try {
    const { data: purposes }: APIData<TPurpose[]> = yield call(api.get, API.PURPOSES)

    yield put(getPurposesSuccess(purposes))
  } catch (e) {
    yield put(getPurposesError(e))
  }
}

function* getVideoStreamUrlSaga({ payload: fileId }: TGetVideoStreamUrlSuccess) {
  try {
    const {
      data: { url }
    }: APIData<{ url: string }> = yield call(api.get, API.VIDEO_STREAM_URL(fileId))

    yield put(getVideoStreamUrlSuccess(url))
  } catch (e) {
    yield put(getVideoStreamUrlError(e))
  }
}

export function* miscSaga() {
  yield takeLatest(GET_COUNTRIES_REQUEST, getCountriesSaga)
  yield takeLatest(GET_CITIES_REQUEST, getCitiesSaga)
  yield takeLatest(GET_COMPANY_SPECIALIZATIONS_REQUEST, getCompanySpecializationsSaga)
  yield takeLatest(GET_PROFESSIONS_REQUEST, getProfessionsSaga)
  yield takeLatest(GET_PURPOSES_REQUEST, getPurposesSaga)
  yield takeLatest(GET_ALL_PROFESSIONS_REQUEST, getAllProfessionsSaga)
  yield takeLatest(GET_VIDEO_STREAM_URL_REQUEST, getVideoStreamUrlSaga)
}
