import cls from 'classnames'
import { ChatTypeEnum } from '@medentee/enums'

import { TChatMessageContainerViewProps } from 'App/containers'
import { Spinner, ChatReadUnreadIcon } from 'App/components'
import { ESendMessageStatus } from 'enums'
import { timeFormat, getMapComponent, formatDate } from 'utils'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as PinIcon } from 'assets/icons/Pin.svg'

import styles from './ChatMessageTime.module.scss'

export type TChatMessageTimeProps = PartialBy<
  Pick<
    TChatMessageContainerViewProps,
    | 'edited'
    | 'editing'
    | 'createdAt'
    | 'recipientRead'
    | 'sendStatus'
    | 'yourMessage'
    | 'chatRoomType'
    | 'pin'
  >,
  'chatRoomType'
> & {
  classes?: Partial<Record<TChatMessageTimeClasses, string>>
  preview?: boolean
}

type TChatMessageTimeClasses = 'root' | 'edited' | 'time' | 'icon'

const MESSAGE_STATUS = new Map<
  ESendMessageStatus,
  (props: Pick<TChatMessageTimeProps, 'recipientRead' | 'chatRoomType'>) => React.ReactNode
>()
  .set(ESendMessageStatus.FAILED, () => <InfoIcon className={styles.failed} />)
  .set(ESendMessageStatus.SENDING, () => <Spinner contentCenter={true} height={16} width={16} />)
  .set(
    ESendMessageStatus.SENT,
    ({ recipientRead, chatRoomType }) =>
      chatRoomType !== ChatTypeEnum.COMMUNITY_NEWS &&
      chatRoomType !== ChatTypeEnum.EVENT_NEWS && <ChatReadUnreadIcon read={recipientRead} />
  )

export const ChatMessageTime = ({
  edited,
  editing,
  createdAt,
  sendStatus,
  recipientRead,
  chatRoomType,
  yourMessage,
  classes,
  preview = false,
  pin
}: TChatMessageTimeProps) => (
  <div
    className={cls(
      styles.root,
      {
        [styles.outgoingMessage]:
          yourMessage &&
          chatRoomType !== ChatTypeEnum.COMMUNITY_NEWS &&
          chatRoomType !== ChatTypeEnum.EVENT_NEWS,
        [styles.preview]: preview
      },
      classes?.root
    )}
  >
    {edited && !editing && <div className={cls(styles.edited, classes?.edited)}>Edited, </div>}
    {pin && (
      <div className={styles.icon}>
        <PinIcon className={styles.pin} />
      </div>
    )}
    <div className={cls(styles.time, classes?.time)}>
      {formatDate(createdAt, timeFormat)}
      {yourMessage && (
        <div className={cls(styles.icon, classes?.icon)}>
          {getMapComponent(MESSAGE_STATUS, sendStatus, { recipientRead, chatRoomType })}
        </div>
      )}
    </div>
  </div>
)
