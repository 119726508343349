import { toast } from 'App/components/ToastContainer'

import { toastDefaultOptions } from '../globalConstants'
import i18n from '../i18n'

export const DEFAULT_RECORD_TIME: number = 1000 * 60 * 2 + 500 // 2 min 0.5sec
const DEFAULT_RECORD_TYPE = 'audio/mpeg'
const DEFAULT_CONSTRAINTS: MediaStreamConstraints = { video: false, audio: true }

type TStopProps = {
  immediate?: boolean
  audioType?: string
}

export type TStopRecordReturn = {
  audioUrl: string
  audioBlob: Blob
}

export type TRecordAudioReturn = {
  start: () => Promise<TStopRecordReturn>
  stop: (props: TStopProps) => Promise<TStopRecordReturn>
}

export const recordAudio = async (): Promise<TRecordAudioReturn | null> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(DEFAULT_CONSTRAINTS)

    const mediaRecorder = new MediaRecorder(stream)
    const audioChunks: BlobPart[] = []

    mediaRecorder.addEventListener('dataavailable', ({ data }) => {
      audioChunks.push(data)
    })

    const start = (): Promise<TStopRecordReturn> => {
      mediaRecorder.start()
      return stop({ immediate: false })
    }

    const stop = ({
      immediate = false,
      audioType = DEFAULT_RECORD_TYPE
    }: TStopProps = {}): Promise<TStopRecordReturn> =>
      new Promise((resolve) => {
        mediaRecorder.addEventListener('stop', (): void => {
          const audioBlob = new Blob(audioChunks, { type: audioType })
          const audioUrl = URL.createObjectURL(audioBlob)

          resolve({ audioUrl, audioBlob })
        })

        const onStopCall = (): void => {
          if (mediaRecorder.state === 'recording') {
            stream.getTracks()[0].stop()
            mediaRecorder.stop()
          }
        }

        if (immediate) {
          onStopCall()
        }
      })

    return { start, stop }
  } catch (e) {
    toast.error(i18n.t('chat.blockedMicroError'), toastDefaultOptions)
    return null
  }
}
