import { useEffect, useLayoutEffect } from 'react'

import { TChannelDepartment } from 'App/components/UnityHub/UnityHubCreateChannels'
import { useQuery } from 'services/query'
import { getOrganizationVoiceRoom } from 'api'

export type TUseVoiceRoomDepartmentsRequestProps = {
  channelId: string
  organizationId: string
  setChannelDepartments: (acc: TChannelDepartment[], prefetched?: boolean) => void

  isEdit?: boolean
}

export const useVoiceRoomDepartmentsRequest = ({
  isEdit,
  channelId,
  organizationId,
  setChannelDepartments
}: TUseVoiceRoomDepartmentsRequestProps) => {
  const {
    data: departments = [],
    refetch,
    isInitialLoading: isLoading
  } = useQuery({
    queryKey: ['get-voice-room-departments', channelId],
    queryFn: () => getOrganizationVoiceRoom({ channelId, organizationId }),
    select: (data) => data?.departments,
    enabled: false,
    cacheTime: 0
  })

  useLayoutEffect(() => {
    if (isEdit) {
      refetch()
    }
  }, [isEdit, refetch])

  useEffect(() => {
    if (isEdit && departments?.length) {
      setChannelDepartments(departments, true)
    }
  }, [isEdit, departments, setChannelDepartments])

  return { isLoading }
}
