import cls from 'classnames'

import { getMapComponent } from 'utils'
import { Button } from 'App/components'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'
import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'

import styles from './Wrapper.module.scss'

export type TWrapperProps = {
  icon: 'success' | 'fail'
  title: string
  description: string
  submitLabel: string
  onClick: () => void

  info?: string
}

const ICON_MAP = new Map<TWrapperProps['icon'], () => JSX.Element>()
  .set('success', () => <CheckCircleOutlineIcon className={cls(styles.icon, styles.iconSuccess)} />)
  .set('fail', () => <CloseCircleOutlinedIcon className={cls(styles.icon, styles.iconError)} />)

export const Wrapper = ({
  icon,
  title,
  description,
  onClick,
  submitLabel,
  info
}: TWrapperProps) => (
  <div className={styles.root}>
    {getMapComponent(ICON_MAP, icon)}

    <h2 className={styles.title}>{title}</h2>

    {info ? (
      <div className={styles.row}>
        <p className={styles.info}>{info}</p>
        <p className={styles.description}>{description}</p>
      </div>
    ) : (
      <p className={styles.description}>{description}</p>
    )}

    <Button onClick={onClick}>{submitLabel}</Button>
  </div>
)
