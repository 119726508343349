import { Skeleton } from 'antd'

import styles from './UnityHubNetworkingDrawerSkeleton.module.scss'

const DetailsSkeleton = () => (
  <Skeleton
    active
    className={styles.details}
    avatar={{ size: 'small', shape: 'square' }}
    title={{ className: styles.detailsTitle }}
    paragraph={false}
  />
)

const SectionSkeleton = () => (
  <Skeleton active avatar={false} title={true} paragraph={{ rows: 4 }} />
)

export const UnityHubNetworkingDrawerSkeleton = () => (
  <div className={styles.root}>
    <div className={styles.header}>
      <Skeleton.Avatar active className={styles.avatar} />
      <div className={styles.account}>
        <Skeleton.Input active size="small" />
        <Skeleton.Input active size="small" />
      </div>
    </div>
    <Skeleton active avatar={false} title={false} paragraph={{ rows: 4 }} />
    <div>
      <DetailsSkeleton />
      <DetailsSkeleton />
      <DetailsSkeleton />
      <DetailsSkeleton />
    </div>
    <SectionSkeleton />
    <SectionSkeleton />
    <SectionSkeleton />
  </div>
)
