import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

import { generateSignupLink } from '../../../utils'
import { createCommunityInviteLink, deactivateCommunityInviteLink } from '../../../api'

import { InvitePopover } from './Popover'

export type TCommunityInvitePopoverProps = {
  id: string
  visible: boolean
  nestIn?: 'body' | 'parent'
  onVisibleChange: (value: boolean) => void
}

const CREATE_COMMUNITY_INVITE_LINK_QUERY_KEY = 'create_community_invite_link'
const DEACTIVATE_COMMUNITY_LINK_QUERY_KEY = 'deactivate_community_link'

export const CommunityInvitePopover = ({
  id,
  nestIn,
  visible,
  onVisibleChange
}: TCommunityInvitePopoverProps) => {
  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: [CREATE_COMMUNITY_INVITE_LINK_QUERY_KEY],
    queryFn: () => createCommunityInviteLink(id),
    cacheTime: 0,
    enabled: visible
  })

  const { mutateAsync: deactivateCommunityLink } = useMutation({
    mutationKey: [DEACTIVATE_COMMUNITY_LINK_QUERY_KEY],
    mutationFn: deactivateCommunityInviteLink,
    onSuccess: () => {
      toast.success(t('common.toast.linkDeactivated'), toastDefaultOptions)
    }
  })

  return (
    <InvitePopover
      nestIn={nestIn}
      visible={visible}
      onVisibleChange={onVisibleChange}
      byLink={{
        isFetching,
        data,
        deactivate: {
          modalTitle: t('modal.deactivateInvitationLinkConfirm.title'),
          modalProps: {
            confirmLabel: t('modal.deactivateInvitationLinkConfirm.submitButton'),
            content: t('modal.deactivateInvitationLinkConfirm.content_community'),
            onConfirm: () => deactivateCommunityLink(id)
          }
        },
        inviteLink: generateSignupLink('community', data?.token ?? '')
      }}
    />
  )
}
