import { TAccount, TChatBaseMessage } from 'store'
import { ChatMessageReferenceContainer } from 'App/containers'

import { useBusinessAccountMessageAuthor } from '../ChatMessage'

import styles from './ChatQuotedMessage.module.scss'

type TChatQuotedMessageProps = {
  outgoing: boolean

  sender?: TAccount | null
  originalSender?: TAccount | null
  quote?: TChatBaseMessage | null
}

export const ChatQuotedMessage = ({
  sender,
  quote,
  outgoing,
  originalSender
}: TChatQuotedMessageProps) => {
  const authorName = useBusinessAccountMessageAuthor(sender, originalSender)

  if (!quote || !sender) {
    return null
  }

  const title = authorName || sender.displayUserName

  return (
    <ChatMessageReferenceContainer
      classes={{ root: styles.root }}
      message={quote}
      title={title}
      icon={null}
      outgoing={outgoing}
    />
  )
}
