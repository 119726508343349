import { createContext, FC, ReactElement, useCallback, useState } from 'react'

import { TCascaderOption, TFilterItem, TFilterValue, useFilters } from '../common'

export type TConfig = {
  search?: string
  searchBy?: string
  filters?: TFilterItem[]
  searchOptions?: TCascaderOption[]
  selectedFilters?: { [key: string]: TFilterValue }
  page?: number
}

export type TPageWrapperContext = {
  page: number
  search: string
  searchBy: string
  hasAppliedFilter: boolean
  reset: (config?: TConfig) => void
  filtersContent: ReactElement | null
  filtersComponent: ReactElement | null
  setPage: (page: number) => void
  setSearch: (value: string) => void
  setSearchBy: (value: string) => void
  searchOptions: TCascaderOption[] | null
  setFilters: (value: TFilterItem[]) => void
  setSearchOptions: (value: TCascaderOption[]) => void
  selectedFilters: { [key: string]: TFilterValue } | null
  setSelectedFilters: (value: { [key: string]: TFilterValue } | null) => void
  selectedFiltersComponent: JSX.Element | undefined
}

export const PageWrapperContext = createContext({} as TPageWrapperContext)

export const PageWrapperContextProvider: FC = ({ children }) => {
  const [page, setPage] = useState(0)
  const [searchBy, setSearchBy] = useState<string>('')
  const [filters, setFilters] = useState<TFilterItem[]>([])
  const [search, setSearch] = useState('')
  const [searchOptions, setSearchOptions] = useState<TCascaderOption[] | null>(null)
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: TFilterValue } | null>(
    null
  )

  const handleReset = useCallback(() => {
    setSelectedFilters(null)
  }, [])

  const handleChange = useCallback((key: string, value: TFilterValue) => {
    setSelectedFilters((currentValue) => ({ ...currentValue, [key]: value }))
  }, [])

  const {
    filters: filtersComponent,
    content: filtersContent,
    hasAppliedFilter,
    selectedFilters: selectedFiltersComponent
  } = useFilters({
    filters,
    value: selectedFilters || {},
    notCollapsedDivider: true,
    onReset: handleReset,
    onChange: handleChange
  })

  // use-case - tab navigation, when we have to override previous state
  const reset = useCallback(
    (config: TConfig = {}) => {
      setSearch(config.search ?? '')
      setFilters(config.filters ?? [])
      setSearchBy(config.searchBy ?? '')
      setSearchOptions(config.searchOptions ?? null)
      setSelectedFilters(config.selectedFilters ?? null)
      setPage(config.page ?? 0)
    },
    [setSearch]
  )

  return (
    <PageWrapperContext.Provider
      value={{
        reset,
        page,
        search,
        searchBy,
        setPage,
        setSearch,
        setFilters,
        setSearchBy,
        searchOptions,
        filtersContent,
        selectedFilters,
        filtersComponent,
        setSearchOptions,
        hasAppliedFilter,
        setSelectedFilters,
        selectedFiltersComponent
      }}
    >
      {children}
    </PageWrapperContext.Provider>
  )
}
