import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Trans, useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { hideModalAction, useAppDispatch } from 'store'
import { createSelectorOptions } from 'utils'

import { Button, Select, TOption } from '../../../../components'
import { updateInvitationLinkSettings } from '../../../../../api/invitations'

import styles from './LinkSettings.module.scss'

const ACCOUNT_NUMBER_OPTIONS = ['10', '50', '100', '300', '500']

export type TLinkSettingsProps = {
  onDeactivate: () => void
  maxRegistrants: number
  createdAccountsNumber: number
}

export const LinkSettings = ({
  onDeactivate,
  maxRegistrants,
  createdAccountsNumber
}: TLinkSettingsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [validFor, setValidFor] = useState<string | null>(
    () => ACCOUNT_NUMBER_OPTIONS.find((item) => Number(item) === maxRegistrants) || null
  )

  const { mutate, isLoading } = useMutation({
    mutationKey: ['update-link-settings', validFor],
    mutationFn: () => updateInvitationLinkSettings({ maxRegistrants: Number(validFor) }),
    onSuccess: () => {
      dispatch(hideModalAction())
      toast.success(t('modal.invitationLinkSettings.successToast'), toastDefaultOptions)
    }
  })

  const options = useMemo(() => {
    const filteredAccountNumberOptions = ACCOUNT_NUMBER_OPTIONS.filter(
      (item) => Number(item) >= maxRegistrants
    )

    return createSelectorOptions(filteredAccountNumberOptions, (item) =>
      t('modal.invitationLinkSettings.selectOption', { number: item })
    )
  }, [maxRegistrants, t])

  const value = useMemo<TOption | null>(
    () =>
      validFor
        ? {
            value: validFor,
            label: t('modal.invitationLinkSettings.selectOption', { number: validFor })
          }
        : null,
    [t, validFor]
  )

  const onChange = useCallback((option: TOption | null) => {
    setValidFor(option?.value || null)
  }, [])

  return (
    <div>
      <p className={styles.description}>{t('modal.invitationLinkSettings.content')}</p>
      <Select
        value={value}
        onChange={onChange}
        isMulti={false}
        options={options}
        isDisabled={options.length <= 1}
        wrapperClassName={styles.select}
        topLabel={
          <div className={styles.label}>
            <Trans
              t={t}
              i18nKey="modal.invitationLinkSettings.validForLabel"
              components={{ span: <span className={styles.caption} /> }}
              values={{ createdAccountsNumber }}
            />
          </div>
        }
      />
      <div className={styles.buttons}>
        <Button disabled={!validFor} loading={isLoading} onClick={() => mutate()}>
          {t('modal.invitationLinkSettings.submitButton')}
        </Button>
        <Button variant="underlined" color="error" onClick={onDeactivate}>
          {t('modal.invitationLinkSettings.deactivateButton')}
        </Button>
      </div>
    </div>
  )
}
