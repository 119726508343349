import {
  TCallsState,
  TAction,
  RECEIVE_CREATE_CALL_SUCCESS,
  TReceiveCreateCallSuccessAction,
  TReceiveAllowCall,
  END_CALL,
  UPDATE_CREATE_CALL,
  TUpdateCreateCallAction,
  RECEIVE_EXIST_CALLS_SUCCESS,
  GET_MEETINGS_SUCCESS
} from 'store'

import {
  SEND_CREATE_CALL_SUCCESS,
  SEND_END_CALL_SUCCESS,
  SEND_ALLOW_CALL_SUCCESS,
  RECEIVE_MISSED_CALL,
  ALLOW_CALL_ACTION,
  SEND_END_CALL_REQUEST,
  UPDATE_CALL_ACCEPTED_MYSELF,
  JOIN_TO_CALL_SUCCESS,
  JOIN_TO_CALL_ERROR,
  JOIN_TO_CALL_REQUEST,
  ADD_USERS_TO_CALL_SUCCESS,
  RECEIVE_CALL_COUNTS_FOR_WIDGET,
  SET_FOLD_CALL
} from './calls.actions'
import {
  TJoinToCallSuccess,
  TSendAllowCallSuccess,
  TSendCreateCallSuccess,
  TReceiveExistCallsSuccessAction,
  TSetFoldCall
} from './calls.types'
import { isMicOn } from './calls.normalize'

export const initialCallsState: TCallsState = {
  answer: {
    isMicOn: true,
    createCall: null,
    isComingCall: false,
    isAcceptedCall: false
  },
  init: {
    isAnsweredCall: false,
    isInitiatedCall: false,
    isVideoCall: false,
    isMicOn: true,
    chatId: null
  },
  isFolded: false,
  joinToCallRequestSent: false,
  ongoingCalls: {
    list: {},
    ids: []
  },
  counter: {
    ongoingCalls: 0,
    activeVoiceRooms: 0,
    todayMeetings: 0,
    tomorrowMeetings: 0
  }
}

export const callsReducer = (state = initialCallsState, action: TAction): TCallsState => {
  switch (action.type) {
    case SEND_CREATE_CALL_SUCCESS:
    case ADD_USERS_TO_CALL_SUCCESS: {
      const { createCall, chatId, video = false } = (action as TSendCreateCallSuccess).payload
      return {
        ...state,
        init: {
          ...state.init,
          isInitiatedCall: true,
          isVideoCall: video,
          chatId,
          isMicOn: isMicOn(createCall.type)
        },
        answer: {
          ...state.answer,
          createCall,
          isMicOn: isMicOn(createCall.type)
        }
      }
    }

    case RECEIVE_CREATE_CALL_SUCCESS: {
      const { isComingCall, ...createCall } = (action as TReceiveCreateCallSuccessAction).payload

      return {
        ...state,
        answer: {
          ...state.answer,
          createCall,
          isComingCall
        }
      }
    }

    case ALLOW_CALL_ACTION: {
      const createCall = (action as TReceiveAllowCall).payload

      return {
        ...state,
        init: {
          ...state.init,
          isAnsweredCall: true
        },
        answer: {
          ...state.answer,
          createCall: {
            ...state.answer.createCall,
            ...createCall
          }
        }
      }
    }

    case SEND_ALLOW_CALL_SUCCESS: {
      const createCall = (action as TSendAllowCallSuccess).payload

      return {
        ...state,
        answer: {
          ...state.answer,
          createCall: {
            ...state.answer.createCall,
            ...createCall
          },
          isComingCall: false,
          isAcceptedCall: true,
          isMicOn: isMicOn(createCall.type)
        },
        isFolded: false
      }
    }

    case UPDATE_CREATE_CALL: {
      return {
        ...state,
        answer: {
          ...state.answer,
          createCall: (action as TUpdateCreateCallAction).payload
        }
      }
    }

    case JOIN_TO_CALL_REQUEST: {
      return {
        ...state,
        joinToCallRequestSent: true
      }
    }

    case JOIN_TO_CALL_SUCCESS: {
      const { createCall } = (action as TJoinToCallSuccess).payload

      return {
        ...state,
        answer: {
          ...state.answer,
          createCall,
          isAcceptedCall: true,
          isComingCall: false,
          isMicOn: isMicOn(createCall.type)
        },
        joinToCallRequestSent: false
      }
    }

    case JOIN_TO_CALL_ERROR:
    case END_CALL:
    case SEND_END_CALL_SUCCESS:
    case SEND_END_CALL_REQUEST:
    case RECEIVE_MISSED_CALL: {
      return {
        ...initialCallsState,
        counter: state.counter
      }
    }

    case UPDATE_CALL_ACCEPTED_MYSELF: {
      return {
        ...state,
        answer: initialCallsState.answer,
        init: initialCallsState.init
      }
    }

    case SET_FOLD_CALL: {
      return {
        ...state,
        isFolded: (action as TSetFoldCall).payload.isFolded
      }
    }

    case RECEIVE_EXIST_CALLS_SUCCESS: {
      const { call, account } = (action as TReceiveExistCallsSuccessAction).payload

      const isOwner = call.offererId === account.id

      return {
        ...state,
        answer: {
          isComingCall: !isOwner,
          createCall: null,
          isAcceptedCall: true,
          isMicOn: isMicOn(call.type)
        },
        init: {
          chatId: call.chatId,
          isVideoCall: false,
          isInitiatedCall: isOwner,
          isAnsweredCall: true,
          isMicOn: isMicOn(call.type)
        }
      }
    }

    case GET_MEETINGS_SUCCESS: {
      return {
        ...state,
        ongoingCalls: action.payload.ongoingCalls
      }
    }

    case RECEIVE_CALL_COUNTS_FOR_WIDGET: {
      return {
        ...state,
        counter: action.payload
      }
    }

    default:
      return state
  }
}
