import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatMessageCall, TChatMessageCallBaseProps } from 'App/components'
import { getChatSenderName } from 'utils'
import { TChatHistoryMessage } from 'store'

// ------------------ Types ------------------
export type TChatMessageCallContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>
// ------------------  END  ------------------

const mapStateToProps = (state: State, { id, showUserName }: TChatMessageCallBaseProps) => {
  const message = state.chat.chatMessages.messages.list[id] as TChatHistoryMessage | undefined // undefined when re=mounting
  const senderName = getChatSenderName({
    senderName: message?.sender?.displayUserName ?? '',
    showUserName,
    yourMessage: message?.yourMessage ?? false
  })

  const accountId = state.global.accountData?.id

  return {
    accountId,
    privateAccountId: state.global.accountData?.professionalAccount?.id || accountId,
    data: message,
    yourMessage: message?.yourMessage,
    senderName
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const ChatMessageCallContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageCall)
