import { useParams } from 'react-router-dom'
import { CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ECaseAccountType } from 'enums'
import { Switch } from 'App/components/common'
import { TCaseOpinionsPublicSwitchContainerProps } from 'App/containers'
import { TCaseParams } from 'store'

import styles from './CaseOpinionsPublicSwitch.module.scss'

export type TCaseOpinionsPublicSwitchProps = TCaseOpinionsPublicSwitchContainerProps

export const CaseOpinionsPublicSwitch = ({
  caseType,
  publicationOpinions,
  publicOpinions
}: TCaseOpinionsPublicSwitchProps) => {
  const { who, id: caseId } = useParams<TCaseParams>()

  const { t } = useTranslation()

  const onSwitch = (checked: boolean) => {
    publicationOpinions({ caseId, currentPublicOpinionStatus: checked })
  }

  return who === ECaseAccountType.OWNER && caseType === CaseTypesEnum.A2A ? (
    <div className={styles.root}>
      <span className={styles.text}>{t('cases.details.opinions.publicSwitch')}</span>
      <Switch defaultChecked={publicOpinions} onChange={onSwitch} />
    </div>
  ) : null
}
