import cls from 'classnames'
import { SubscriptionIdsEnum } from '@medentee/enums'

import { getMapComponent } from 'utils'
import { ReactComponent as AccountOutlineIcon } from 'assets/icons/AccountOutline.svg'
import { ReactComponent as AccountPlusOutlineSubscriptionIcon } from 'assets/icons/AccountPlusOutlineSubscription.svg'
import { ReactComponent as BusinessAccountOutlineIcon } from 'assets/icons/BusinessAccountOutline.svg'
import { ReactComponent as BusinessAccountOutlinePlusIcon } from 'assets/icons/BusinessAccountOutlinePlus.svg'

import styles from './SubscriptionIcon.module.scss'

export type TSubscriptionIconClasses = 'root' | 'icon'
export type TSubscriptionIconProps = {
  id: SubscriptionIdsEnum

  classes?: Partial<Record<TSubscriptionIconClasses, string>>
}

const ICON = new Map<SubscriptionIdsEnum, (className?: string) => JSX.Element>()
  .set(SubscriptionIdsEnum.BASIC_PROFESSIONAL, (className) => (
    <AccountOutlineIcon className={className} />
  ))
  .set(SubscriptionIdsEnum.PROFESSIONAL, (className) => (
    <AccountPlusOutlineSubscriptionIcon className={className} />
  ))
  .set(SubscriptionIdsEnum.BUSINESS, (className) => (
    <BusinessAccountOutlinePlusIcon className={className} />
  ))
  .set(SubscriptionIdsEnum.BASIC_BUSINESS, (className) => (
    <BusinessAccountOutlineIcon className={className} />
  ))

export const SubscriptionIcon = ({ id, classes }: TSubscriptionIconProps) => (
  <div className={cls(styles.root, classes?.root)}>{getMapComponent(ICON, id, classes?.icon)}</div>
)
