import { FileExtensionCategoryEnum } from '@medentee/enums'

import {
  action,
  TError,
  TGetFilesHistoryRequestPayload,
  TGetFilesHistorySuccessPayload,
  TIds
} from 'store'
import { Pagination, SortingTable } from 'types'

import {
  EPermissionSpecificationScope,
  TChangeFileContactPermissionErrorPayload,
  TChangeFileContactPermissionRequestPayload,
  TChangeFileContactPermissionSuccessPayload,
  TDiscardCasesPermissionsRequestPayload,
  TDiscardCasesPermissionsSuccessPayload,
  TFileContactIds,
  TFileContactList,
  TFileList,
  TFilesFilters,
  TGetFilesCountsRequestPayload,
  TGetFilesCountsSuccessPayload
} from './files.types'

export const GET_FILE_LIST = 'GET_FILE_LIST'
export const GET_FILE_LIST_REQUEST = 'GET_FILE_LIST_REQUEST'
export const GET_FILE_LIST_SUCCESS = 'GET_FILE_LIST_SUCCESS'
export const GET_FILE_LIST_ERROR = 'GET_FILE_LIST_ERROR'

export const GET_FILE_CONTACT_LIST = 'GET_FILE_CONTACT_LIST'
export const GET_FILE_CONTACT_LIST_REQUEST = 'GET_FILE_CONTACT_LIST_REQUEST'
export const GET_FILE_CONTACT_LIST_SUCCESS = 'GET_FILE_CONTACT_LIST_SUCCESS'
export const GET_FILE_CONTACT_LIST_ERROR = 'GET_FILE_CONTACT_LIST_ERROR'
export const SET_FILE_CONTACT_LIST_SEARCH = 'SET_FILE_CONTACT_LIST_SEARCH'

export const CHANGE_FILE_CONTACT_PERMISSION = 'CHANGE_FILE_CONTACT_PERMISSION'
export const CHANGE_FILE_CONTACT_PERMISSION_REQUEST = 'CHANGE_FILE_CONTACT_PERMISSION_REQUEST'
export const CHANGE_FILE_CONTACT_PERMISSION_SUCCESS = 'CHANGE_FILE_CONTACT_PERMISSION_SUCCESS'
export const CHANGE_FILE_CONTACT_PERMISSION_ERROR = 'CHANGE_FILE_CONTACT_PERMISSION_ERROR'

export const SET_FILE_LIST = 'SET_FILE_LIST'
export const SET_FILE_LIST_IDS = 'SET_FILE_LIST_IDS'
export const SET_FILE_LIST_PAGINATION = 'SET_FILE_LIST_PAGINATION'
export const SET_FILE_LIST_SORTING = 'SET_FILE_LIST_SORTING'

export const SET_FILE_LIST_FILTERS_REQUEST = 'SET_FILE_LIST_FILTERS_REQUEST'
export const SET_FILE_LIST_FILTERS_SUCCESS = 'SET_FILE_LIST_FILTERS_SUCCESS'
export const RESET_FILE_LIST_FILTERS = 'RESET_FILE_LIST_FILTERS'

export const SET_FILE_LIST_ACTION = 'SET_FILE_LIST_ACTION'
export const SET_FILE_DETAILS_ID = 'SET_FILE_DETAILS_ID'
export const SET_FILE_CONTACT_LIST = 'SET_FILE_CONTACT_LIST'

export const SET_FILE_CONTACT_IDS = 'SET_FILE_CONTACT_IDS'

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE'
export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR'

export const RENAME_FILE = 'RENAME_FILE'
export const RENAME_FILE_REQUEST = 'RENAME_FILE_REQUEST'
export const RENAME_FILE_SUCCESS = 'RENAME_FILE_SUCCESS'
export const RENAME_FILE_ERROR = 'RENAME_FILE_ERROR'

export const DISCARD_CASES_PERMISSIONS = 'DISCARD_CASES_PERMISSIONS'
export const DISCARD_CASES_PERMISSIONS_REQUEST = 'DISCARD_CASES_PERMISSIONS_REQUEST'
export const DISCARD_CASES_PERMISSIONS_SUCCESS = 'DISCARD_CASES_PERMISSIONS_SUCCESS'
export const DISCARD_CASES_PERMISSIONS_ERROR = 'DISCARD_CASES_PERMISSIONS_ERROR'

export const FILES_RESET_ACTION = 'FILES_RESET_ACTION'
export const FILE_DETAILS_RESET_ACTION = 'FILE_DETAILS_RESET_ACTION'

export const GET_FILES_HISTORY = 'GET_FILES_HISTORY'
export const GET_FILES_HISTORY_REQUEST = 'GET_FILES_HISTORY_REQUEST'
export const GET_FILES_HISTORY_SUCCESS = 'GET_FILES_HISTORY_SUCCESS'
export const GET_FILES_HISTORY_ERROR = 'GET_FILES_HISTORY_ERROR'

export const RESET_FILES_HISTORY = 'RESET_FILES_HISTORY'

/** Type for Fetching File list */
export type TGetFileListRequestPayload = {
  permissionSpecificationScope?: EPermissionSpecificationScope
  caseId?: string
  permissionUserIdScope?: string
  /** Search by file name */
  filters?: TFilesFilters
  /** File pagination */
  pagination?: Pagination
  sorting?: SortingTable
  ownerId?: string
  chatId?: string
  extensionCategories?: FileExtensionCategoryEnum[]
  loadMore?: boolean
}

export const getFileListRequest = (payload: TGetFileListRequestPayload) =>
  action(GET_FILE_LIST_REQUEST, payload)

export const getFileListSuccess = () => action(GET_FILE_LIST_SUCCESS)

export const getFileListError = () => action(GET_FILE_LIST_ERROR)

export const changeFileContactPermissionRequest = (
  payload: TChangeFileContactPermissionRequestPayload
) => action(CHANGE_FILE_CONTACT_PERMISSION_REQUEST, payload)

export const changeFileContactPermissionSuccess = (
  payload: TChangeFileContactPermissionSuccessPayload
) => action(CHANGE_FILE_CONTACT_PERMISSION_SUCCESS, payload)

export const changeFileContactPermissionError = (
  payload: TChangeFileContactPermissionErrorPayload
) => action(CHANGE_FILE_CONTACT_PERMISSION_ERROR, payload)

// ------------------ GET_FILE_CONTACT_LIST_REQUEST ----------------

export type TGetFileContactListPayload = {
  fileId: string
}

export const getFileContactListRequest = (payload: TGetFileContactListPayload) =>
  action(GET_FILE_CONTACT_LIST_REQUEST, payload)

// ------------------ SET_FILE_CONTACT_LIST_SEARCH ----------------

export type TSetFileContactListSearchPayload = {
  search: string
  fileId?: string
}

export const setFileContactListSearch = (payload: TSetFileContactListSearchPayload) =>
  action(SET_FILE_CONTACT_LIST_SEARCH, payload)

export const getFileContactListSuccess = () => action(GET_FILE_CONTACT_LIST_SUCCESS)

export const getFileContactListError = () => action(GET_FILE_CONTACT_LIST_ERROR)

export type TSetFileListPayload = {
  fileList: TFileList
}
/** Set new fileList */
export const setFileList = (payload: TSetFileListPayload) => action(SET_FILE_LIST, payload)

export type TSetFileIdsPayload = {
  ids: TIds
}

/** Set new file ids */
export const setFileIds = (payload: TSetFileIdsPayload) => action(SET_FILE_LIST_IDS, payload)

export type TSetFilePaginationPayload = {
  pagination: Pagination
}

export const setFilePagination = (payload: TSetFilePaginationPayload) =>
  action(SET_FILE_LIST_PAGINATION, payload)

export type TSetFileSortingPayload = {
  sorting?: SortingTable
}

export const setFileSorting = (payload: TSetFileSortingPayload) =>
  action(SET_FILE_LIST_SORTING, payload)

export const setFileFiltersRequest = (payload: TFilesFilters) =>
  action(SET_FILE_LIST_FILTERS_REQUEST, payload)
export const setFileFiltersSuccess = (payload: TFilesFilters) =>
  action(SET_FILE_LIST_FILTERS_SUCCESS, payload)

export type TFileDetailsIdPayload = {
  recordId: string | null
}

export const setFileDetailsId = (payload: TFileDetailsIdPayload) =>
  action(SET_FILE_DETAILS_ID, payload)

export type TSetFileContactListPayload = {
  fileContactList: TFileContactList
  fileContactIds: TFileContactIds
}

export const setFileContactList = (payload: TSetFileContactListPayload) =>
  action(SET_FILE_CONTACT_LIST, payload)

export type TSetFileContactIdsPayload = {
  fileContactIds: TFileContactIds
}

export const setFileContactIds = (payload: TSetFileContactIdsPayload) =>
  action(SET_FILE_CONTACT_IDS, payload)

export type TDownloadFilePayload = {
  fileId: string
}

export const downloadFileRequest = (payload: TDownloadFilePayload) =>
  action(DOWNLOAD_FILE_REQUEST, payload)
export const downloadFileSuccess = () => action(DOWNLOAD_FILE_SUCCESS)
export const downloadFileError = (payload: TError) => action(DOWNLOAD_FILE_ERROR, payload)

export type TRenameFilePayload = {
  fileName: string
  fileId: string
  onSuccess?: (newFileName: string) => void
}

export const renameFileRequest = (payload: TRenameFilePayload) =>
  action(RENAME_FILE_REQUEST, payload)
export const renameFileSuccess = () => action(RENAME_FILE_SUCCESS)
export const renameFileError = (payload: TError) => action(RENAME_FILE_ERROR, payload)

export const discardCasesPermissionsRequest = (payload: TDiscardCasesPermissionsRequestPayload) =>
  action(DISCARD_CASES_PERMISSIONS_REQUEST, payload)
export const discardCasesPermissionsSuccess = (payload: TDiscardCasesPermissionsSuccessPayload) =>
  action(DISCARD_CASES_PERMISSIONS_SUCCESS, payload)
export const discardCasesPermissionsError = (payload: TError) =>
  action(DISCARD_CASES_PERMISSIONS_ERROR, payload)

export const filesResetAction = () => action(FILES_RESET_ACTION)
export const fileDetailsResetAction = () => action(FILE_DETAILS_RESET_ACTION)

export const getFilesHistoryRequest = (payload: TGetFilesHistoryRequestPayload) =>
  action(GET_FILES_HISTORY_REQUEST, payload)
export const getFilesHistorySuccess = (payload: TGetFilesHistorySuccessPayload) =>
  action(GET_FILES_HISTORY_SUCCESS, payload)
export const getFilesHistoryError = (payload: TError) => action(GET_FILES_HISTORY_ERROR, payload)

export const resetFilesHistory = () => action(RESET_FILES_HISTORY)

// ------------------ GET_FILES_COUNTS ----------------

export const GET_FILES_COUNTS = 'GET_FILES_COUNTS'
export const GET_FILES_COUNTS_REQUEST = 'GET_FILES_COUNTS_REQUEST'
export const GET_FILES_COUNTS_SUCCESS = 'GET_FILES_COUNTS_SUCCESS'
export const GET_FILES_COUNTS_ERROR = 'GET_FILES_COUNTS_ERROR'

export const getFilesCountsRequest = (payload: TGetFilesCountsRequestPayload) =>
  action(GET_FILES_COUNTS_REQUEST, payload)
export const getFilesCountsSuccess = (payload: TGetFilesCountsSuccessPayload) =>
  action(GET_FILES_COUNTS_SUCCESS, payload)
export const getFilesCountsError = (payload: TError) => action(GET_FILES_COUNTS_ERROR, payload)

// ------------------ RESET_FILES_FOR_CATEGORY ----------------

export const RESET_FILES_FOR_CATEGORY = 'RESET_FILES_FOR_CATEGORY'

export const resetFilesForCategory = () => action(RESET_FILES_FOR_CATEGORY)
