import { useEffect, useLayoutEffect } from 'react'
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { AccountTypeNames, BroadcastTypeEnum, ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { ELayoutNavKey } from 'enums'
import {
  getBroadcastsRequest,
  getChatRoomsRequest,
  resetBroadcastList,
  resetChatAction,
  setEventId,
  useAppDispatch
} from 'store'
import { IEventDTO } from 'interfaces'
import { ChatOrganization, EmptyList, UnityHubViewLayout } from 'App/components'
import { RoleRouteContainer } from 'App/containers'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { PageWrapperContextProvider } from 'App/components/PageWrapper/PageWrapperContext'
import { useSessionStorage } from 'App/hooks/useSessionStorage'
import { BroadcastRoomContainer } from 'App/components/Broadcasts/BroadcastRoomContainer'

import { EventsRoomsNav } from '../EventsRoomsNav'
import { EventHeader } from '../EventHeader'
import { EventsInfo } from '../EventsInfo'
import { EventProfileEdit } from '../EventProfileEdit'

import { useJoinToChannel } from './useJoinToChannel'

export type TEventsViewProps = {
  loading: boolean
  event: IEventDTO
}

export const EventsView = (props: TEventsViewProps) => {
  const { event, loading } = props

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  useJoinToChannel(event.id)

  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()

  const { setSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.EVENTS })

  const defaultPath = `/events/${event.id}/highlights/info`

  useLayoutEffect(() => {
    setSessionStorageData(url)
  }, [setSessionStorageData, pathname, url])

  useEffect(
    () => () => {
      dispatch(resetBroadcastList())
      dispatch(resetChatAction())
      dispatch(setEventId({ nextEventId: null }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (event.id) {
      dispatch(getBroadcastsRequest({ eventIds: [event.id], type: BroadcastTypeEnum.EVENT }))

      dispatch(
        getChatRoomsRequest({
          eventId: event.id,
          chatType: [ChatTypeEnum.EVENT_CHANNEL, ChatTypeEnum.EVENT_NEWS]
        })
      )

      dispatch(setEventId({ nextEventId: event.id }))
    }
  }, [dispatch, event.id])

  return (
    <PageWrapperContextProvider>
      <UnityHubViewLayout
        header={<EventHeader id={event.id} name={event.title} loading={loading} />}
        content={
          <Switch>
            <Redirect exact push={false} to={defaultPath} from={path} />

            <RoleRouteContainer
              exact
              path={`${path}/chat/:id`}
              render={() => (
                <ChatOrganization
                  id={event.id}
                  path={path}
                  keySuffix={ELayoutNavKey.EVENTS}
                  variant="chat"
                />
              )}
            />

            <RoleRouteContainer
              exact
              path={`${path}/news/:id`}
              render={() => (
                <ChatOrganization
                  id={event.id}
                  path={path}
                  keySuffix={ELayoutNavKey.EVENTS}
                  variant="news"
                />
              )}
            />

            <RoleRouteContainer
              exact
              path={`${path}/broadcast/:broadcastId`}
              render={() => <BroadcastRoomContainer />}
            />

            <RoleRouteContainer path={defaultPath} render={() => <EventsInfo id={event.id} />} />

            <RoleRouteContainer
              exact
              path={`${path}/highlights/profile`}
              render={() => <EventProfileEdit eventId={event.id} />}
              allowedAccountTypes={[AccountTypeNames.PROFESSIONAL]}
            />

            <RoleRouteContainer
              render={() => (
                <EmptyList icon={<AccountGroupIcon />} text={t('events.placeholder_channel')} />
              )}
            />
          </Switch>
        }
        nav={
          <Switch>
            <RoleRouteContainer path={path} render={() => <EventsRoomsNav eventId={event.id} />} />
          </Switch>
        }
      />
    </PageWrapperContextProvider>
  )
}
