import { GET_PURPOSES_SUCCESS, TGetPurposesSuccess } from 'store'

import { TAction } from '../store.utils'

import {
  TGetCitiesSuccess,
  TGetCompanySpecializationsSuccess,
  TGetCountriesSuccess,
  TGetProfessionsSuccess,
  TMiscTypes,
  TGetAllProfessionsSuccess
} from './misc.types'
import {
  GET_CITIES_SUCCESS,
  GET_COMPANY_SPECIALIZATIONS_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_PROFESSIONS_SUCCESS,
  GET_ALL_PROFESSIONS_SUCCESS,
  GET_VIDEO_STREAM_URL_SUCCESS
} from './misc.actions'

export const initialMiscState: TMiscTypes = {
  countries: [],
  cities: [],
  companySpecializations: [],
  professions: [],
  purposes: [],
  allProfessions: {
    business: [],
    professional: []
  }
}

export const miscReducer = (state = initialMiscState, action: TAction) => {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS: {
      const countries = (action as TGetCountriesSuccess).payload

      return {
        ...state,
        countries,
        cities: []
      }
    }

    case GET_CITIES_SUCCESS: {
      const cities = (action as TGetCitiesSuccess).payload

      return {
        ...state,
        cities
      }
    }

    case GET_COMPANY_SPECIALIZATIONS_SUCCESS: {
      const companySpecializations = (action as TGetCompanySpecializationsSuccess).payload

      return {
        ...state,
        companySpecializations
      }
    }

    case GET_PROFESSIONS_SUCCESS: {
      const professions = (action as TGetProfessionsSuccess).payload

      return {
        ...state,
        professions
      }
    }

    case GET_ALL_PROFESSIONS_SUCCESS: {
      const allProfessions = (action as TGetAllProfessionsSuccess).payload

      return {
        ...state,
        allProfessions
      }
    }

    case GET_PURPOSES_SUCCESS: {
      const purposes = (action as TGetPurposesSuccess).payload

      return {
        ...state,
        purposes
      }
    }

    case GET_VIDEO_STREAM_URL_SUCCESS: {
      const url = action.payload as string

      return {
        ...state,
        videoStreamUrl: url
      }
    }

    default: {
      return state
    }
  }
}
