import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { showModalAction, useAppDispatch } from 'store'
import { TMenuItemProps } from 'App/components/common/MenuList'
import { ReactComponent as LinkChainIcon } from 'assets/icons/LinkChain.svg'
import { ReactComponent as AccountMultipleOutlineIcon } from 'assets/icons/AccountMultipleOutline.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/Email.svg'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { useBasicSubscription } from 'App/hooks/useBasicSubscription'

type TMenuItemsProps = {
  showLinkPopover: () => void
}

type TMenuItemKey = 'copy' | 'schedule' | 'email'

type TUseMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useMeetingsModalScheduleMenuItems = (): TUseMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleScheduleClick = useCallback(() => {
    const callback = (anchorId?: string) => {
      dispatch(
        showModalAction({
          modalTitle: t('modal.meetings.title'),
          modalType: EModalComponents.MEETINGS,
          modalProps: { anchorId }
        })
      )
    }

    dispatch(
      showModalAction({
        modalTitle: t('modal.scheduleMeeting.title_contact'),
        modalType: EModalComponents.SCHEDULE_MEETING_MODAL,
        modalProps: {
          selectContact: true,
          onClickBack: callback,
          onSuccess: callback
        }
      })
    )
  }, [dispatch, t])

  const inviteUsers = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.inviteByEmail.title'),
        modalType: EModalComponents.INVITE_TO_EXTERNAL_MEETING_MODAL
      })
    )
  }, [dispatch, t])

  const { getOnClickHandler } = useBasicSubscription({
    callback: inviteUsers
  })

  const menuItems = useMemo<Record<TMenuItemKey, (props: TMenuItemsProps) => TMenuItemProps>>(
    () => ({
      copy: ({ showLinkPopover }) => ({
        content: t('modal.meetings.dropdownMenu.copyMeetingLink'),
        icon: <LinkChainIcon />,
        onClick: showLinkPopover
      }),
      schedule: () => ({
        content: t('modal.meetings.dropdownMenu.scheduleOneToOneMeeting'),
        icon: <AccountMultipleOutlineIcon />,
        onClick: handleScheduleClick
      }),
      email: () => ({
        content: t('modal.meetings.dropdownMenu.emailMeetingLink'),
        icon: <EmailIcon />,
        onClick: () => getOnClickHandler()()
      })
    }),
    [getOnClickHandler, handleScheduleClick, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => [
      menuItems.copy(props),
      menuItems.schedule(props),
      menuItems.email(props)
    ],
    [menuItems]
  )

  return {
    getMenuItems
  }
}
