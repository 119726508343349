import { normalize, schema } from 'normalizr'

import { IAdvertsDTO, IMyAdvertsDTO, IMyAdvertRequestsDTO, IMyRequestsDTO } from 'interfaces'
import { TIds } from 'store/store.types'

import {
  TAdvertsNormalized,
  TMyAdvertsNormalized,
  TMyAdvertRequestsNormalized,
  TMyRequestsNormalized
} from './adverts.types'

const listSchema = new schema.Entity('list')
const ids = new schema.Array(listSchema)

export const advertsNormalize = (originalData: IAdvertsDTO[] = []) => {
  const { entities, result } = normalize<IAdvertsDTO, TAdvertsNormalized, TIds>(originalData, ids)

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const myAdvertsNormalize = (originalData: IMyAdvertsDTO[] = []) => {
  const { entities, result } = normalize<IMyAdvertsDTO, TMyAdvertsNormalized, TIds>(
    originalData,
    ids
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const myAdvertsRequestsNormalize = (originalData: IMyAdvertRequestsDTO[] = []) => {
  const { entities, result } = normalize<IMyAdvertRequestsDTO, TMyAdvertRequestsNormalized>(
    originalData,
    ids
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const myRequestsNormalize = (originalData: IMyRequestsDTO[] = []) => {
  const { entities, result } = normalize<IMyRequestsDTO, TMyRequestsNormalized>(originalData, ids)

  return {
    list: entities.list || {},
    ids: result || []
  }
}
