import { action } from 'store/store.utils'
import { TError } from 'store/store.types'

import { TCaseCreateRequestPayload } from './caseCreate.types'

export const CASE_CREATE = 'CASE_CREATE'
export const CASE_CREATE_REQUEST = 'CASE_CREATE_REQUEST'
export const CASE_CREATE_SUCCESS = 'CASE_CREATE_SUCCESS'
export const CASE_CREATE_ERROR = 'CASE_CREATE_ERROR'

export const caseCreateRequest = (payload: TCaseCreateRequestPayload) =>
  action(CASE_CREATE_REQUEST, payload)
export const caseCreateSuccess = () => action(CASE_CREATE_SUCCESS)
export const caseCreateError = (payload: TError) => action(CASE_CREATE_ERROR, payload)
