import { FC, useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  createProcessingBySelector,
  REMOVE_CONTACT_INVITE,
  removeContactInviteRequest,
  removeErrorNotification
} from 'store'
import { State } from 'redux/rootReducer'
import { EInvitationAction } from 'globalConstants'
import { Button, ConfirmationModal } from 'App/components'

import styles from './ConfirmRejectWithdrawContact.module.scss'

export type TConfirmRejectWithdrawContactBaseProps = {
  type: EInvitationAction.REJECT | EInvitationAction.WITHDRAW
  contactId: string

  onSuccess?: () => void
}
export type TConfirmRejectWithdrawContactProps = TConfirmRejectWithdrawContactBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const ConfirmRejectWithdrawContactComponent: FC<TConfirmRejectWithdrawContactProps> = ({
  type,
  processing,
  removeContacts,
  contactId,
  error,
  resetError,
  onSuccess
}) => {
  const { t } = useTranslation()

  const isReject = type === EInvitationAction.REJECT
  const isWithdraw = type === EInvitationAction.WITHDRAW

  const handleRemove = useCallback(() => {
    removeContacts({
      onSuccess,
      contactId,
      processingId: contactId,
      type
    })
  }, [removeContacts, onSuccess, contactId, type])

  return (
    <ConfirmationModal
      content={
        <>
          {isReject && t('modal.rejectContactInvitationConfirm.content')}
          {isWithdraw && t('modal.withdrawContactInvitationConfirm.content')}
        </>
      }
      controls={
        <Button
          className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
          disabled={processing}
          loading={processing}
          onClick={handleRemove}
        >
          {isReject && t('modal.rejectContactInvitationConfirm.submitButton')}
          {isWithdraw && t('modal.withdrawContactInvitationConfirm.submitButton')}
        </Button>
      }
      error={error}
      resetError={resetError}
    />
  )
}
const processingSelector = createProcessingBySelector([REMOVE_CONTACT_INVITE])

const mapStateToProps = (state: State, { contactId }: TConfirmRejectWithdrawContactBaseProps) => ({
  processing: processingSelector(contactId)(state),
  error: state.errors[REMOVE_CONTACT_INVITE]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeContacts: removeContactInviteRequest,
      resetError: () => removeErrorNotification(REMOVE_CONTACT_INVITE)
    },
    dispatch
  )

export const ConfirmRejectWithdrawContact: FC<TConfirmRejectWithdrawContactBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRejectWithdrawContactComponent)
