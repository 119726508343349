import { CaseTypesEnum } from '@medentee/enums'

import { ECaseAccountType, EChatViewType } from 'enums'
import { CaseMemberChatContainer, CaseMembersListContainer } from 'App/containers'
import { ChatContextProvider } from 'App/context'

type TCaseMembersProps = {
  caseView?: string
  who?: ECaseAccountType
  caseType?: CaseTypesEnum | null
}

export const CaseMembers = ({ who, caseType, caseView }: TCaseMembersProps) => {
  const content =
    caseType !== CaseTypesEnum.A2A && who === ECaseAccountType.MEMBER ? (
      <CaseMemberChatContainer caseView={caseView} />
    ) : (
      <CaseMembersListContainer who={who} caseType={caseType} caseView={caseView} />
    )
  return <ChatContextProvider initialState={EChatViewType.CASE}>{content}</ChatContextProvider>
}
