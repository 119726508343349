import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { Button, ConfirmationModal } from 'App/components/common'
import { deleteDepartment, TDeleteDepartmentPayload } from 'api/organizations'
import { queryClient } from 'queryClient'
import { hideModalAction, useAppDispatch } from 'store'

import { ORGANIZATION_DEPARTMENTS_QUERY_KEY } from '../StaffTabAccountItem/useKebabMenu'

export type TDeleteDepartmentDialogProps = TDeleteDepartmentPayload

export const DeleteDepartmentDialog = ({
  departmentId,
  organizationId
}: TDeleteDepartmentDialogProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationKey: ['delete-department'],
    mutationFn: deleteDepartment,
    onSuccess: () => {
      dispatch(hideModalAction())
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_DEPARTMENTS_QUERY_KEY],
        type: 'active'
      })
    }
  })

  const onClick = () => {
    mutate({ departmentId, organizationId })
  }

  return (
    <ConfirmationModal
      content={t('modal.deleteDepartmentConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteDepartmentConfirm.submitButton')}
        </Button>
      }
    />
  )
}
