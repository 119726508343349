import { useQuery } from 'services/query'
import { Spinner } from 'App/components'
import { handleError } from 'api'
import { getBroadcastJoinTokenInfo } from 'api/broadcasts'

import { useCommunitiesJoinViaLinkCore } from '../useCommunitiesJoinViaLinkCore'

const QUERY_KEY = 'get-community-join-to-broadcast-token-info'

export const JoinViaLinkToCommunityBroadcast = () => {
  const { token, isNotProfessional, redirectToDefaultPage, redirectToCommunity } =
    useCommunitiesJoinViaLinkCore({ variant: 'broadcast' })

  const { isFetching } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: () => getBroadcastJoinTokenInfo(token),
    enabled: !isNotProfessional,
    onSuccess: ({ name, isMember, id: channelId }) =>
      redirectToCommunity({
        title: name,
        channelId,
        isMember
      }),
    onError: (err) => {
      handleError(err)
      redirectToDefaultPage()
    }
  })

  if (isFetching) {
    return <Spinner contentCenter />
  }

  return null
}
