import cls from 'classnames'

import styles from './ListCounter.module.scss'

type TListCounter = PropsWithClassName<{
  text: string
}>

export const ListCounter = ({ text, className }: TListCounter) => (
  <span className={cls(styles.root, className)}>{text}</span>
)
