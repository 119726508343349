import { PropsWithChildren } from 'react'
import { Skeleton, SkeletonProps } from 'antd'
import cls from 'classnames'

import styles from './TextFieldSkeleton.module.scss'

type TTextFieldSkeletonProps = Pick<SkeletonProps, 'loading'> &
  PropsWithChildren<{
    variant?: 'input' | 'textArea'
  }>

export const TextFieldSkeleton = ({
  loading,
  children,
  variant = 'input'
}: TTextFieldSkeletonProps) => (
  <Skeleton
    className={cls(styles.skeleton, styles[variant])}
    loading={loading}
    active={true}
    title={true}
    paragraph={{ rows: 1 }}
  >
    {children}
  </Skeleton>
)
