import { Link } from 'react-router-dom'
import cls from 'classnames'

import { useAdaptiveLayout } from 'App/hooks'
import logoSrc from 'assets/images/Logo.svg'
import shortLogoSrc from 'assets/images/ShortLogo.svg'

import styles from './Logo.module.scss'

export const Logo = () => {
  const { isMobile } = useAdaptiveLayout()

  return (
    <div className={styles.wrapper}>
      {isMobile ? (
        <Link to="/" className={cls(styles.logo, styles.shortLogo)}>
          <img src={shortLogoSrc} alt="short-medentee-logo" />
        </Link>
      ) : (
        <Link to="/" className={styles.logo}>
          <img src={logoSrc} alt="medentee-logo" />
        </Link>
      )}
    </div>
  )
}
