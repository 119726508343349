import { FC } from 'react'
import cls from 'classnames'

import { NotificationBadge, TNotificationBadgeProps } from 'App/components'

import styles from './UnityHubNavItemBadge.module.scss'

export type TUnityHubNavItemBadgeProps = {
  count?: number
  inline?: boolean
} & Pick<TNotificationBadgeProps, 'variant' | 'border' | 'size' | 'color' | 'icon'>

export const UnityHubNavItemBadge: FC<TUnityHubNavItemBadgeProps> = ({
  children,
  inline,
  count,
  variant,
  border,
  size,
  color,
  icon
}) => (
  <div className={cls(styles.root, { [styles.rootInline]: inline })}>
    {children}
    {!!count && (
      <div
        className={cls(styles.badge, {
          [styles.badgeInline]: inline,
          [styles.badgeDot]: variant === 'dot'
        })}
      >
        <NotificationBadge
          count={count}
          variant={variant}
          border={border}
          size={size}
          color={color}
          icon={icon}
        />
      </div>
    )}
  </div>
)
