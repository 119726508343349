import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { Table as OriginalTable } from 'antd'
import { TableRowSelection, ColumnsType } from 'antd/lib/table/interface'
import { useTranslation } from 'react-i18next'

import {
  accountIdSelector,
  createLoadingSelector,
  getOrganizationMembersRequest,
  getOrganizationsMembersFiltersSelector,
  GET_ORGANIZATIONS_MEMBERS,
  setOrganizationMemberCurrentPage,
  TIds,
  TOrganizationMember,
  useAppDispatch,
  useAppSelector
} from 'store'
import { SortingTable } from 'types'
import { formatShortDate, getSortDirection } from 'utils'
import { EAvatarSize, EOrganizationMemberSort, ESorting } from 'enums'
import { useAdaptiveLayout, useRefValue } from 'App/hooks'
import {
  AccountName,
  EEmptyListIconSize,
  EmptyList,
  LongDash,
  Pagination,
  TableTitle
} from 'App/components'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'

import { Skeleton } from './Skeleton'
import styles from './Table.module.scss'

export type TTableProps = {
  selectedIds: TIds
  setSelectedIds: Dispatch<SetStateAction<TIds>>
}

const loadingSelector = createLoadingSelector([GET_ORGANIZATIONS_MEMBERS])

export const Table = ({ selectedIds, setSelectedIds }: TTableProps) => {
  const dispatch = useAppDispatch()

  const organizationId = useAppSelector(accountIdSelector)
  const loading = useAppSelector(loadingSelector)
  const { search, current, sortBy, sortOrder, showBy, total } = useAppSelector(
    getOrganizationsMembersFiltersSelector
  )
  const dataSource = useAppSelector((state) => state.organizations.members.list)

  const { getValue: getCurrentFilters } = useRefValue({
    sortBy,
    sortOrder
  })

  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const sortColumnHandler = useCallback(
    (key: string, sortName?: string, sortDirection?: ESorting) => {
      const newSortDirection = getSortDirection(key, sortName, sortDirection)

      return () => {
        if (organizationId) {
          dispatch(
            getOrganizationMembersRequest({
              id: organizationId,
              sortBy: key as EOrganizationMemberSort,
              sortOrder: newSortDirection,
              withPagination: true
            })
          )
        }
      }
    },
    [dispatch, organizationId]
  )

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(setOrganizationMemberCurrentPage({ current: page }))
    },
    [dispatch]
  )

  const columns = useMemo<ColumnsType<TOrganizationMember>>(() => {
    const sorting: undefined | SortingTable =
      sortBy && sortOrder
        ? {
            direction: sortOrder,
            name: sortBy
          }
        : undefined

    const { direction, name } = sorting ?? {}

    const userNameColumn: ColumnsType<TOrganizationMember> = [
      {
        dataIndex: 'account',
        key: EOrganizationMemberSort.USER_NAME,
        sorter: true,
        className: styles.nameWrapper,
        render: ({
          displayUserName,
          id,
          firstName,
          lastName,
          type
        }: TOrganizationMember['account']) => (
          <AccountName
            displayUserName={displayUserName}
            userId={id}
            showAvatar={true}
            firstName={firstName}
            lastName={lastName}
            size={isMobile ? EAvatarSize.XXS : EAvatarSize.XS}
            type={type.name}
            hideIcon={true}
          />
        ),
        title: () => (
          <TableTitle
            title={t('payments.transferMDT.columns.name')}
            sortId={EOrganizationMemberSort.USER_NAME}
            sorting={sorting}
            sorter={true}
          />
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      }
    ]

    const allColumns: ColumnsType<TOrganizationMember> = [
      ...userNameColumn,
      {
        dataIndex: 'lastOperationAmount',
        key: EOrganizationMemberSort.MDT_TOKENS,
        sorter: true,
        className: styles.cellWrapper,
        align: 'right',
        render: (lastOperationAmount: null | number) => <>{lastOperationAmount ?? <LongDash />}</>,
        title: () => (
          <TableTitle
            title={t('payments.transferMDT.columns.mdtCount')}
            sortId={EOrganizationMemberSort.MDT_TOKENS}
            sorting={sorting}
            sorter={true}
          />
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      },
      {
        dataIndex: 'lastOperationTime',
        key: EOrganizationMemberSort.LAST_OPERATION,
        sorter: true,
        className: styles.cellWrapper,
        align: 'right',
        render: (lastOperationAt: string | Date) => (
          <span className={styles.lastCell}>
            {lastOperationAt ? formatShortDate(lastOperationAt) : <LongDash />}
          </span>
        ),
        title: () => (
          <TableTitle
            title={t('payments.transferMDT.columns.lastTransfer')}
            sorting={sorting}
            sortId={EOrganizationMemberSort.LAST_OPERATION}
            sorter={true}
          />
        ),
        onHeaderCell: (column: any) => ({
          onClick: sortColumnHandler(column.key, name, direction)
        })
      }
    ]

    return isMobile ? userNameColumn : allColumns
  }, [sortBy, sortColumnHandler, sortOrder, isMobile, t])

  const rowSelection: TableRowSelection<TOrganizationMember> = {
    selectedRowKeys: selectedIds,
    onChange: (selectedRowIds) => setSelectedIds(selectedRowIds as TIds),
    preserveSelectedRowKeys: true
  }

  useEffect(() => {
    if (organizationId) {
      dispatch(
        getOrganizationMembersRequest({
          ...getCurrentFilters(),
          id: organizationId,
          withPagination: true
        })
      )
    }
  }, [dispatch, organizationId, search, current, getCurrentFilters])

  return dataSource.length || loading ? (
    <>
      <div className={styles.container}>
        <Skeleton loading={loading}>
          <OriginalTable
            rowKey={({ account }) => account.id}
            columns={columns}
            dataSource={dataSource}
            rowSelection={rowSelection}
            pagination={false}
            showSorterTooltip={false}
            tableLayout="fixed"
          />
        </Skeleton>
      </div>

      <Pagination
        classes={{ container: styles.pagination }}
        current={current}
        pageSize={showBy}
        total={total}
        onChange={handlePaginationChange}
      />
    </>
  ) : (
    <div className={styles.container}>
      <EmptyList
        text={t('payments.transferMDT.placeholder')}
        icon={<AccountGroupIcon />}
        className={styles.empty}
        iconSize={EEmptyListIconSize.MD}
        hasAppliedSearch={!!search}
      />
    </div>
  )
}
