import {
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_ERROR,
  TAction,
  SET_CATEGORY_SEARCH,
  RESET_CATEGORY_LIST
} from 'store'
import {
  TCategoryListState,
  TGetCategoryListError,
  TGetCategoryListSuccess,
  TSetCategorySearchAction
} from 'store/categoryList/categoryList.types'

export const initialCategoryListState: TCategoryListState = {
  total: 0,
  results: [],
  filters: {
    search: ''
  }
}

export const categoryListReducer = (state = initialCategoryListState, action: TAction) => {
  switch (action.type) {
    case GET_CATEGORY_LIST_SUCCESS: {
      const { payload } = action as TGetCategoryListSuccess

      return {
        ...state,
        results: payload,
        total: payload.length
      }
    }

    case GET_CATEGORY_LIST_ERROR: {
      return {
        ...state,
        error: (action as TGetCategoryListError).payload
      }
    }

    case SET_CATEGORY_SEARCH: {
      const { search } = (action as TSetCategorySearchAction).payload

      return {
        ...state,
        filters: {
          ...state.filters,
          search
        }
      }
    }

    case RESET_CATEGORY_LIST: {
      return {
        ...initialCategoryListState
      }
    }

    default:
      return state
  }
}
