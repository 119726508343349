import { FileHistorySourcesEnum } from '@medentee/enums'
import cls from 'classnames'

import { FileImage } from 'App/components'
import { TChatMessageFileContainerProps } from 'App/containers'
import { useChatContext } from 'App/context'
import { getExtensionWithoutDot } from 'utils/files'
import { createSlides, ECardSize, EExtendableCardType } from 'store'
import { EChatViewType } from 'enums'
import { formatBytes } from 'utils'
import { IMAGE_WHITELIST } from 'globalConstants'
import { FileNameTruncatedText } from 'App/components/File/FileNameTruncatedText'

import styles from './ChatMessageFile.module.scss'

export type TChatMessageFileBaseProps = {
  id: string
  chatId: string
  showUserName?: boolean
}

export const ChatMessageFile = ({
  fileName,
  extension,
  isMessageOutgoing,
  fileId,
  chatId,
  thumbnailUrl,
  isUnopenable,
  slidesEntity,
  showExtendableCard,
  fileSize = ''
}: TChatMessageFileContainerProps) => {
  const { chatViewType } = useChatContext()

  const isCaseChat = chatViewType === EChatViewType.CASE
  const isImage = IMAGE_WHITELIST.includes(getExtensionWithoutDot(extension))
  const showImagePreview = isImage && !!thumbnailUrl

  const handleFileOnClick = () => {
    showExtendableCard({
      type: EExtendableCardType.FILE_VIEWER,
      initialSlideId: fileId,
      slides: createSlides(slidesEntity, FileHistorySourcesEnum.P2P_CLOUD, chatId),
      allowPosition: isCaseChat,
      allowSize: isCaseChat,
      size: (isCaseChat && ECardSize.COLLAPSED) || undefined
    })
  }

  return (
    <div
      className={cls(styles.root, {
        [styles.rootCase]: isCaseChat,
        [styles.imagePreview]: showImagePreview,
        [styles.outgoingMessage]: isMessageOutgoing,
        [styles.rootProcessing]: isUnopenable
      })}
      onClick={isUnopenable ? undefined : handleFileOnClick}
    >
      <FileImage
        preview={showImagePreview}
        size={isCaseChat ? 'xs' : 'sm'}
        thumbnailUrl={thumbnailUrl}
        fileName={fileName}
        extension={extension}
        hidePlayIcon={true}
        inCase={true}
      />
      {!showImagePreview && (
        <div className={cls(styles.info, isCaseChat && styles.infoCase)}>
          <FileNameTruncatedText
            fileName={fileName}
            extension={extension}
            className={styles.name}
          />
          <span className={styles.size}>{formatBytes(fileSize)}</span>
        </div>
      )}
    </div>
  )
}
