import { useEffect, useState, useCallback } from 'react'
import { Switch, Redirect, useHistory } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import { GetCountryQueryModeEnum, AdvertStatusEnum } from '@medentee/enums'

import { getKeyForRoute, advertsNestedRoutes } from 'App/App.config'
import {
  AsideMenuHeaderAction,
  DropDownMenu,
  EAsideMenuHeaderActionVariant,
  NavMenu,
  TNavMenuItem,
  Scrollable,
  useFilters,
  TFilterItem,
  EFilterTypes
} from 'App/components'
import {
  resetFilters,
  useAppDispatch,
  setSearch,
  getAdvertsRequest,
  getMyAdvertsRequest,
  useAppSelector,
  setFilters,
  createLoadingSelector,
  GET_COUNTRIES,
  getCountriesRequest,
  GET_ALL_PROFESSIONS,
  getAllProfessionsRequest,
  GET_PURPOSES,
  getPurposesRequest
} from 'store'
import { EModalComponents, RoleRouteContainer, ShowModalContainer } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import { ADVERTS_WRAPPER_ID } from 'globalConstants'
import { AdvertsSearch } from 'App/containers/Adverts/AdvertsSearch'
import { createSelectorOptions } from 'utils'
import { ReactComponent as GlobePlusIcon } from 'assets/icons/GlobePlus.svg'
import { useBasicSubscription } from 'App/hooks/useBasicSubscription'

import styles from './Adverts.module.scss'

const STATUSES = createSelectorOptions(
  Object.values(AdvertStatusEnum).filter((status) => status !== AdvertStatusEnum.REMOVED),
  capitalize
)
const DEFAULT_PATH = '/adverts/connector'

const NAV_MENU: TNavMenuItem[] = [
  {
    link: DEFAULT_PATH,
    title: 'Connector',
    exact: true
  },
  {
    link: '/adverts/my-adverts',
    title: 'My Adverts',
    exact: true
  },
  {
    link: '/adverts/my-requests',
    title: 'My Requests',
    exact: true
  }
]

const titleList = NAV_MENU.map(({ title }) => title)

const loadingPurposesSelector = createLoadingSelector([GET_PURPOSES])
const loadingCountriesSelector = createLoadingSelector([GET_COUNTRIES])
const loadingProfessionsSelector = createLoadingSelector([GET_ALL_PROFESSIONS])

const useAdvertFilters = () => {
  const { location } = useHistory()
  const dispatch = useAppDispatch()
  const { locations, statuses, professions, specializations, purposes } = useAppSelector(
    (state) => state.adverts.filters
  )

  const { countries } = useAppSelector((state) => state.misc)
  const { allProfessions } = useAppSelector((state) => state.misc)
  const { purposes: allPurposes } = useAppSelector((state) => state.misc)

  const loadingPurposes = useAppSelector(loadingPurposesSelector)
  const loadingCountries = useAppSelector(loadingCountriesSelector)
  const loadingProfessions = useAppSelector(loadingProfessionsSelector)

  const handleResetFilter = useCallback(() => {
    dispatch(resetFilters())
  }, [dispatch])

  const filterItems: TFilterItem[] = [
    {
      title: 'Connection purpose',
      sectionKey: 'purposes',
      items: [
        {
          filterKey: 'purposes',
          valueKey: 'id',
          labelKey: 'name',
          type: EFilterTypes.CHECKBOX,
          options: allPurposes,
          loading: loadingPurposes,
          onMount: () => dispatch(getPurposesRequest())
        }
      ]
    },
    {
      title: 'Looking for',
      sectionKey: 'professions',
      items: [
        {
          type: EFilterTypes.CHECKBOX,
          options: allProfessions.professional,
          loading: loadingProfessions,
          valueKey: 'id',
          labelKey: 'name',
          filterKey: 'professions',
          onMount: () => dispatch(getAllProfessionsRequest())
        },
        {
          type: EFilterTypes.CHECKBOX,
          options: allProfessions.business,
          loading: loadingProfessions,
          valueKey: 'id',
          labelKey: 'name',
          filterKey: 'specializations'
        }
      ]
    },
    {
      title: 'Based in',
      sectionKey: 'locations',
      items: [
        {
          filterKey: 'locations',
          type: EFilterTypes.SELECT,
          options: countries,
          valueKey: 'code',
          labelKey: 'countryName',
          isLoading: loadingCountries,
          placeholder: 'Select location',
          limit: 3,
          isMulti: true,
          onMount: () =>
            dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.DEFAULT_WITH_UNIONS }))
        }
      ]
    }
  ]

  if (location.pathname === '/adverts/my-adverts') {
    filterItems.unshift({
      title: 'Status',
      sectionKey: 'statuses',
      items: [
        {
          filterKey: 'statuses',
          type: EFilterTypes.CHECKBOX,
          options: STATUSES
        }
      ]
    })
  }

  return useFilters({
    value: {
      statuses,
      locations,
      purposes,
      professions,
      specializations
    },
    filters: filterItems,
    onReset: handleResetFilter,
    onChange: (key, value) => dispatch(setFilters({ [key]: value }))
  })
}

export const Adverts = () => {
  const dispatch = useAppDispatch()
  const { push, location } = useHistory()
  const [title, setTitle] = useState('0')
  const { isMobile, isDesktop } = useAdaptiveLayout()
  const { filters, content, selectedFilters } = useAdvertFilters()
  const { current } = useAppSelector((state) => state.adverts.filters)

  const autoScrollOnPagination = location.pathname !== DEFAULT_PATH ? current : null

  const handleDropDownChange = useCallback(
    (key: string) => {
      const link = NAV_MENU[Number(key)].link

      setTitle(key)
      push(link)
    },
    [push]
  )

  useEffect(() => {
    const newTitle = NAV_MENU.findIndex((item) => item.link === location.pathname)?.toString()

    if (newTitle !== title) {
      setTitle(newTitle)
    }
  }, [location.pathname])

  const handleSearch = useCallback(
    (search: string) => {
      dispatch(setSearch({ search }))
    },
    [dispatch]
  )

  const { shouldShowUpgradeDialog, getOnClickHandler } = useBasicSubscription()

  return (
    <div className={styles.root}>
      <Scrollable variant="aside">
        <div className={styles.content}>
          <ShowModalContainer
            modalTitle="Create advert"
            modalType={EModalComponents.CREATE_ADVERT_DIALOG}
            modalProps={{
              onSuccess: () =>
                location.pathname === advertsNestedRoutes[1].path
                  ? dispatch(getMyAdvertsRequest())
                  : dispatch(getAdvertsRequest())
            }}
          >
            {({ showModalHandler }) => (
              <AsideMenuHeaderAction
                title="Let’s create new advert"
                buttonText="Create"
                icon={<GlobePlusIcon />}
                onClick={shouldShowUpgradeDialog ? getOnClickHandler() : showModalHandler}
                variant={EAsideMenuHeaderActionVariant.BUTTON_BLOCK}
              />
            )}
          </ShowModalContainer>
          {isDesktop && content}
        </div>
      </Scrollable>

      <div className={styles.main}>
        <div className={styles.nav}>
          {isMobile ? (
            <DropDownMenu list={titleList} title={title} onChange={handleDropDownChange} />
          ) : (
            <NavMenu tabPosition="top" size="md" items={NAV_MENU} hasTitle={false} />
          )}
        </div>
        <AdvertsSearch filters={filters} onChange={handleSearch} />
        {!isMobile && selectedFilters}
        <Scrollable id={ADVERTS_WRAPPER_ID} page={autoScrollOnPagination}>
          <Switch>
            {advertsNestedRoutes.map((item, index) => (
              <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
            ))}

            <Redirect to={DEFAULT_PATH} />
          </Switch>
        </Scrollable>
      </div>
    </div>
  )
}
