import { actionCreator, TError } from 'store'
import { TBroadcast } from 'interfaces/api/broadcasts'

import * as actionTypes from './broadcasts.actionTypes'
import {
  TSetBroadcastDataPayload,
  TGetBroadcastsSuccessPayload,
  TGetBroadcastsRequestPayload,
  TSetActiveBroadcastPayload,
  TReceiveUpdateBroadcastWatchersPayload
} from './broadcasts.types'

export const getBroadcastsRequest = (payload: TGetBroadcastsRequestPayload) =>
  actionCreator(actionTypes.GET_BROADCASTS_REQUEST, payload)
export const getBroadcastsSuccess = (payload: TGetBroadcastsSuccessPayload) =>
  actionCreator(actionTypes.GET_BROADCASTS_SUCCESS, payload)
export const getBroadcastsError = (payload: TError) =>
  actionCreator(actionTypes.GET_BROADCASTS_ERROR, payload)

export const setBroadcastData = (payload: TSetBroadcastDataPayload) =>
  actionCreator(actionTypes.SET_BROADCAST_DATA, payload)

export const setActiveBroadcast = (payload: TSetActiveBroadcastPayload) =>
  actionCreator(actionTypes.SET_ACTIVE_BROADCAST, payload)

export const openBroadcast = (payload: TSetActiveBroadcastPayload) =>
  actionCreator(actionTypes.OPEN_BROADCAST, payload)

export const receiveBroadcastWatched = (payload: TBroadcast) =>
  actionCreator(actionTypes.RECEIVE_BROADCAST_WATCHED, payload)

export const receiveBroadcastCreated = (payload: TBroadcast) =>
  actionCreator(actionTypes.RECEIVE_BROADCAST_CREATED, payload)

export const receiveBroadcastDeleted = (payload: TBroadcast) =>
  actionCreator(actionTypes.RECEIVE_BROADCAST_DELETED, payload)

export const receiveUpdateBroadcastWatchers = (payload: TReceiveUpdateBroadcastWatchersPayload) =>
  actionCreator(actionTypes.RECEIVE_UPDATE_BROADCAST_WATCHERS, payload)

export const receiveUpdateBroadcastStatus = (payload: TBroadcast) =>
  actionCreator(actionTypes.RECEIVE_UPDATE_BROADCAST_STATUS, payload)

export const resetBroadcastList = () => actionCreator(actionTypes.RESET_BROADCAST_LIST)
