import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { CaseMemberOpinionsList, TCaseMemberOpinionsListBaseProps } from 'App/components'
import {
  getCaseOpinionsRequest,
  addCaseOpinionRequest,
  createLoadingSelector,
  GET_CASE_OPINIONS,
  createProcessingSelector,
  ADD_CASE_OPINION,
  updateCaseDraftRequest,
  getCaseDraftRequest,
  GET_CASE_DRAFT
} from 'store'
import { State } from 'redux/rootReducer'
import { ECasesDocumentKey } from 'enums'

export type TCaseMemberOpinionsContainerBaseProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TCaseMemberOpinionsContainerProps = TCaseMemberOpinionsListBaseProps &
  TCaseMemberOpinionsContainerBaseProps

const CaseMemberOpinionsContainerView = ({
  caseId,
  ids,
  count,
  loading,
  processing,
  status,
  caseType,
  draft,
  getCaseOpinions,
  addCaseOpinion,
  updateCaseDraft,
  getCaseDraft
}: TCaseMemberOpinionsContainerProps) => {
  useEffect(() => {
    getCaseOpinions({ caseId })
    getCaseDraft({ caseId, type: ECasesDocumentKey.OPINION })
    // Do not add `caseId` as a dependency to avoid unneeded fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCaseOpinions, getCaseDraft])

  return (
    <CaseMemberOpinionsList
      caseId={caseId}
      ids={ids}
      count={count}
      loading={loading}
      processing={processing}
      status={status}
      caseType={caseType}
      draft={draft}
      addCaseOpinion={addCaseOpinion}
      updateCaseDraft={updateCaseDraft}
    />
  )
}

const loadingOpinionsSelector = createLoadingSelector([GET_CASE_OPINIONS])
const loadingDraftSelector = createLoadingSelector([GET_CASE_DRAFT])
const processingSelector = createProcessingSelector([ADD_CASE_OPINION])

const mapStateToProps = (state: State) => ({
  ids: state.caseDetails.opinions.opinionsIds,
  count: state.caseDetails.opinions.count,
  loading: loadingOpinionsSelector(state) || loadingDraftSelector(state),
  processing: processingSelector(state),
  status: state.caseView.data?.status,
  caseType: state.caseView.data?.type,
  draft: state.caseDetails.opinions.draft
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseOpinions: getCaseOpinionsRequest,
      addCaseOpinion: addCaseOpinionRequest,
      updateCaseDraft: updateCaseDraftRequest,
      getCaseDraft: getCaseDraftRequest
    },
    dispatch
  )

export const CaseMemberOpinionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMemberOpinionsContainerView)
