import { useEffect, useCallback } from 'react'

import { useAdaptiveLayout } from 'App/hooks'

import { TUseFileMouseEventsProps, useFileContextMenu, useFileMouseEvents } from '../hooks'

type TTableRowProps = Omit<TUseFileMouseEventsProps, 'onTouch'> & {
  setVisible: (visible: string | null) => void
}

export const TableRow = ({
  file,
  openHandlerProps,
  selectMode,
  disableOpen,
  disableSelect,
  onSelect,
  setVisible
}: TTableRowProps) => {
  const { handleVisibilityChange, visible, ref } = useFileContextMenu()
  const { isDesktop } = useAdaptiveLayout()

  useEffect(() => setVisible(visible ? file.id : null), [file.id, setVisible, visible])

  const { longPressBind, onClick } = useFileMouseEvents({
    file,
    openHandlerProps,
    selectMode,
    disableOpen,
    disableSelect,
    onSelect,
    onTouch: handleVisibilityChange
  })

  const onContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      isDesktop ? handleVisibilityChange : event.preventDefault(),
    [isDesktop, handleVisibilityChange]
  )

  return {
    ...longPressBind,
    ref,
    onClick,
    onContextMenu
  }
}
