import { CSSProperties, FC } from 'react'
import cls from 'classnames'

import { Tooltip } from '../../Tooltip'

import styles from './ButtonChip.module.scss'

export type TButtonChipProps = {
  disabled?: boolean
  className?: string
  style?: CSSProperties
  tabIndex?: number
  variant?: 'fill' | 'outline'
  tooltip?: string
  onClick?: () => void
}

export const ButtonChip: FC<TButtonChipProps> = ({
  className,
  disabled,
  tooltip,
  onClick,
  variant = 'fill',
  ...rest
}) => (
  <Tooltip title={tooltip}>
    <button
      {...rest}
      type="button"
      onClick={disabled ? undefined : onClick}
      className={cls(className, styles.button, {
        [styles.disabled]: disabled,
        [styles[variant]]: variant
      })}
    />
  </Tooltip>
)
