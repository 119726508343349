import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { createProcessingSelector, caseUnLockError, caseUnLockRequest, CASE_UNLOCK } from 'store'
import { State } from 'redux/rootReducer'
import { Button, ConfirmationModal } from 'App/components'

import styles from './CaseUnLockConfirm.module.scss'

export type TCaseUnLockConfirmBaseProps = {
  caseId: string
  caseName: string
}

export type TCaseUnLockConfirmProps = TCaseUnLockConfirmBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseUnLockConfirmComponent: FC<TCaseUnLockConfirmProps> = ({
  processing,
  caseUnLock,
  caseId,
  error,
  setCaseUnLockError,
  caseName
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={t('cases.modal.unlockCaseConfirm.content', { caseName })}
      controls={
        <Button
          className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
          loading={processing}
          onClick={() => caseUnLock({ caseId })}
        >
          {t('cases.modal.unlockCaseConfirm.submitButton')}
        </Button>
      }
      error={error}
      resetError={() => setCaseUnLockError(null)}
    />
  )
}
const processingSelector = createProcessingSelector([CASE_UNLOCK])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.caseView.unlock.error
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      caseUnLock: caseUnLockRequest,
      setCaseUnLockError: caseUnLockError
    },
    dispatch
  )

export const CaseUnLockConfirm: FC<TCaseUnLockConfirmBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseUnLockConfirmComponent)
