import { useMemo } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { formatBytes, formatShortDate, getMapComponent } from 'utils'
import { setCaseCloudActiveFileId } from 'store'
import { ICaseMembersAccount, ICasesCloudFile } from 'interfaces'
import { FileDetailsItem, FileInformationTitle } from 'App/components'
import { PERMISSION_NAME_MAP } from 'globalConstants'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './CaseCloudFileDetailsMember.module.scss'

export type TCaseCloudFileDetailsMemberProps = {
  fileDetails: Pick<
    ICasesCloudFile,
    'casesPermissions' | 'p2pPermissions' | 'fileName' | 'extension' | 'fileSize' | 'sharedAt'
  > | null

  noTitle?: boolean
  closeFileDetails?: typeof setCaseCloudActiveFileId
  owner?: Pick<ICaseMembersAccount, 'displayUserName'> | null
  classes?: Record<Partial<TCaseCloudFileDetailsMemberClasses>, string>
}

export type TCaseCloudFileDetailsMemberClasses = 'root'

export const CaseCloudFileDetailsMember = ({
  fileDetails,
  owner,
  noTitle,
  classes,
  closeFileDetails
}: TCaseCloudFileDetailsMemberProps) => {
  const { t } = useTranslation()

  const closeDetails = () => closeFileDetails && closeFileDetails()

  const {
    fileName = '',
    extension = '',
    fileSize = '',
    sharedAt = '',
    casesPermissions = [],
    p2pPermissions = []
  } = fileDetails || {}

  const displayUserName = owner?.displayUserName ?? ''

  const permissions = useMemo(
    () =>
      (casesPermissions.length ? casesPermissions : p2pPermissions)
        ?.map(({ permission: { id } }) => id)
        ?.map((id) => {
          const permissionName = getMapComponent(PERMISSION_NAME_MAP, id)

          return permissionName && t(`enum.filePermissionsIdsEnum.${permissionName}`)
        })
        .join(', ') || '',
    [casesPermissions, p2pPermissions, t]
  )

  return (
    <section className={cls(styles.root, classes?.root)}>
      {!noTitle && (
        <div className={styles.title}>
          <FileInformationTitle extension={extension} fileName={fileName} />
          <CrossIcon className={styles.closeIcon} onClick={closeDetails} />
        </div>
      )}
      <h3 className={styles.subTitle}>{t('cases.details.attachments.fileDetails.title')}</h3>
      <ul className={styles.list}>
        <FileDetailsItem
          label={t('cases.details.attachments.fileDetails.sizeLabel')}
          value={formatBytes(fileSize)}
        />
        <FileDetailsItem
          label={t('cases.details.attachments.fileDetails.fileHolderLabel')}
          value={displayUserName}
        />
        <FileDetailsItem
          label={t('cases.details.attachments.fileDetails.permissionsLabel')}
          value={permissions}
        />
        {sharedAt && (
          <FileDetailsItem
            label={t('cases.details.attachments.fileDetails.sharingDateLabel')}
            value={formatShortDate(sharedAt)}
          />
        )}
      </ul>
    </section>
  )
}
