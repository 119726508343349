import { FC, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { State } from 'redux/rootReducer'
import {
  changePasswordRequest,
  CHANGE_PASSWORD,
  clearChangePasswordAction,
  createProcessingSelector,
  hideModalAction,
  removeErrorNotification
} from 'store'
import { SettingsSecurityChangePasswordConfirm, TwoFactorConfirm } from 'App/components'
import { parseError, isMatchErrorCode } from 'utils'

export type TSettingsSecurityChangePasswordConfirmContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>

const SettingsSecurityChangePasswordConfirmView = ({
  hasConnectedAccount,
  error,
  changePassword,
  expiredTokenDate,
  processing,
  clearError,
  clearChangePassword,
  hideModal
}: TSettingsSecurityChangePasswordConfirmContainerProps) => {
  const { t } = useTranslation()

  const [show2FA, setShow2FA] = useState(!hasConnectedAccount)
  const [showLimitExpired, setShowLimitExpired] = useState(false)

  const limitExpired = isMatchErrorCode(error, ErrorCodesEnum.EXCEEDING_TOKENS_COUNT_LIMIT)

  const handleAcceptClick = useCallback(() => setShow2FA(true), [])

  useEffect(
    () => () => {
      clearError()
    },
    [clearError]
  )

  useEffect(() => {
    if (limitExpired && !expiredTokenDate) {
      setShow2FA(false)
      setShowLimitExpired(true)
    }
  }, [expiredTokenDate, limitExpired])

  if (showLimitExpired && !show2FA) {
    return (
      <TwoFactorConfirm
        buttonText={t('common.2FA.confirm.submitButton_gotIt')}
        title={parseError(error)}
        onClick={hideModal}
      />
    )
  }

  if (hasConnectedAccount && !show2FA) {
    return <TwoFactorConfirm onClick={handleAcceptClick} error={error} />
  }

  return show2FA ? (
    <SettingsSecurityChangePasswordConfirm
      error={error}
      changePassword={changePassword}
      expiredTokenDate={expiredTokenDate}
      processing={processing}
      clearError={clearError}
      clearChangePassword={clearChangePassword}
    />
  ) : null
}

const processingSelector = createProcessingSelector([CHANGE_PASSWORD])
const clearErrorAction = () => removeErrorNotification(CHANGE_PASSWORD)

const mapStateToProps = (state: State) => ({
  hasConnectedAccount: state.global.accountData?.hasConnectedAccount ?? false,
  processing: processingSelector(state),

  expiredTokenDate: state.settings.securityAndLogin.password.expiredTokenDate,
  error: state.errors[CHANGE_PASSWORD]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changePassword: changePasswordRequest,
      clearError: clearErrorAction,
      hideModal: hideModalAction,
      clearChangePassword: clearChangePasswordAction
    },
    dispatch
  )

export const SettingsSecurityChangePasswordConfirmContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityChangePasswordConfirmView)
