import { ReactNode, useRef, forwardRef, PropsWithChildren, LegacyRef } from 'react'
import cls from 'classnames'
import { isValid } from 'date-fns'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker, { RangePickerProps, PickerProps } from 'antd/es/date-picker/generatePicker'
import { useTranslation } from 'react-i18next'

import { longDate } from 'utils'
import { EInputSize } from 'enums'
import { ReactComponent as CalendarIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as DashIcon } from 'assets/icons/Dash.svg'

import { Error, TErrorProps } from '../Error'
import { HelperText } from '../HelperText'

import styles from './DatePicker.module.scss'

type TCommonCustomProps = {
  topLabel?: ReactNode
  inputSize?: EInputSize
  wrapperClassName?: string
}

export type TDatePickerProps = TCommonCustomProps & PickerProps<Date> & TErrorProps

const AntDatePicker = generatePicker<Date>(dateFnsGenerateConfig)

const MONTH_PICKER = 'month'

type TDatePickerWrapperProps = PropsWithChildren<
  Pick<TDatePickerProps, 'topLabel' | 'error' | 'invalid'> & {
    className?: string
  }
>

const DatePickerWrapperView = (
  { topLabel, className, children, error, invalid }: TDatePickerWrapperProps,
  ref: LegacyRef<HTMLDivElement>
) => (
  <div className={cls(styles.root, { [styles.error]: invalid }, className)} ref={ref}>
    {topLabel && <span className={styles.topLabel}>{topLabel}</span>}
    {children}
    <HelperText>
      <Error invalid={invalid} error={error} />
    </HelperText>
  </div>
)

const DatePickerWrapper = forwardRef<HTMLDivElement, TDatePickerWrapperProps>(DatePickerWrapperView)

export const DatePicker = ({
  value,
  topLabel,
  inputSize = EInputSize.M,
  wrapperClassName,
  className,
  error,
  invalid,
  ...rest
}: TDatePickerProps) => {
  const rootRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  const placeholder = rest.placeholder ?? t('common.field.datePicker.placeholder')

  return (
    <DatePickerWrapper
      ref={rootRef}
      className={wrapperClassName}
      topLabel={topLabel}
      error={error}
      invalid={invalid}
    >
      <AntDatePicker
        format={longDate}
        value={value && isValid(new Date(value)) ? new Date(value) : null}
        className={cls(
          {
            [inputSize]: true
          },
          styles.datePicker,
          className
        )}
        allowClear={!!value}
        suffixIcon={!value && <CalendarIcon className={styles.suffix} />}
        placeholder={placeholder}
        dropdownClassName={cls(
          styles.dropdown,
          rest.picker === MONTH_PICKER ? styles.dropdownMonth : styles.dropdownDate
        )}
        {...{ ...rest, showToday: false }}
      />
    </DatePickerWrapper>
  )
}

export type RangeDatePickerProps = TCommonCustomProps & RangePickerProps<Date> & TErrorProps

export const RangeDatePicker = ({
  topLabel,
  inputSize = EInputSize.M,
  wrapperClassName,
  className,
  error,
  invalid,
  ...rest
}: RangeDatePickerProps) => {
  const rootRef = useRef(null)

  const { t } = useTranslation()

  const placeholder = rest.placeholder ?? [
    t('common.field.datePicker.placeholder'),
    t('common.field.datePicker.placeholder')
  ]

  return (
    <div ref={rootRef}>
      <DatePickerWrapper
        className={wrapperClassName}
        topLabel={topLabel}
        error={error}
        invalid={invalid}
      >
        <AntDatePicker.RangePicker
          className={cls(
            {
              [inputSize]: true
            },
            className
          )}
          dropdownClassName={cls(
            styles.dropdown,
            rest.picker === MONTH_PICKER ? styles.dropdownRangeMonth : styles.dropdownDate
          )}
          suffixIcon={!rest.value && <CalendarIcon className={styles.suffix} />}
          allowClear={!!rest.value}
          separator={<DashIcon className={styles.separator} />}
          placeholder={placeholder}
          {...rest}
        />
      </DatePickerWrapper>
    </div>
  )
}
