import { useCallback } from 'react'
import { ChatMuteEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { muteTextChat, unmuteTextChat } from 'api/chats'
import { TMenuItemProps } from 'App/components/common'

export const useMuteTextChannel = () => {
  const { t } = useTranslation()

  const { mutate, isLoading: isMuteLoading } = useMutation({
    mutationKey: ['mute-text-channel'],
    mutationFn: muteTextChat
  })

  const handleMuteTextChannel = useCallback(
    (channelId: string, muteUntil: ChatMuteEnum) => () => {
      mutate({ channelId, muteUntil })
    },
    [mutate]
  )

  const getInnerMenuItems = useCallback(
    (channelId: string): TMenuItemProps[] => [
      {
        content: t('enum.chatMuteEnum.FIFTEEN_MINUTES'),
        onClick: handleMuteTextChannel(channelId, ChatMuteEnum.FIFTEEN_MINUTES)
      },
      {
        content: t('enum.chatMuteEnum.ONE_HOUR'),
        onClick: handleMuteTextChannel(channelId, ChatMuteEnum.ONE_HOUR)
      },
      {
        content: t('enum.chatMuteEnum.THREE_HOURS'),
        onClick: handleMuteTextChannel(channelId, ChatMuteEnum.THREE_HOURS)
      },
      {
        content: t('enum.chatMuteEnum.EIGHT_HOURS'),
        onClick: handleMuteTextChannel(channelId, ChatMuteEnum.EIGHT_HOURS)
      },
      {
        content: t('enum.chatMuteEnum.PERMANENT'),
        onClick: handleMuteTextChannel(channelId, ChatMuteEnum.PERMANENT)
      }
    ],
    [handleMuteTextChannel, t]
  )

  const { mutate: unmute, isLoading: isUnmuteLoading } = useMutation({
    mutationKey: ['unmute-text-channel'],
    mutationFn: unmuteTextChat
  })

  const handleUnmuteTextChannel = useCallback(
    (channelId: string) => {
      unmute(channelId)
    },
    [unmute]
  )

  return {
    isLoading: isMuteLoading || isUnmuteLoading,
    getInnerMenuItems,
    handleMuteTextChannel,
    handleUnmuteTextChannel
  }
}
