import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateChannelButton } from 'App/containers/Organization/CreateChannels'
import { UnityHubRoomsNavCollapse, TUnityHubRoomsNavCollapseView } from 'App/components/UnityHub'

import { VoiceRoom } from '../VoiceRoom'
import { useKebabMenu } from '../useKebabMenu'
import { usePinnedVoiceChannels } from '../../hooks'

export type TRoomsNavVoiceListProps = {
  organizationId: string
}

export const RoomsNavVoiceList = ({ organizationId }: TRoomsNavVoiceListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const { pinnedIds, ids } = usePinnedVoiceChannels()

  const { t } = useTranslation()

  const shouldShowPinnedRooms = Boolean(pinnedIds.length) && isCollapsed

  const handleChangeView = useCallback((view: TUnityHubRoomsNavCollapseView) => {
    setIsCollapsed(view === 'collapsed')
  }, [])

  const { getMenuItems } = useKebabMenu({ organizationId })

  return (
    <>
      <UnityHubRoomsNavCollapse
        variant="voice"
        title={t('unityHub.channelsList.voice.label')}
        localStorageKey={organizationId}
        onChangeView={handleChangeView}
        action={<CreateChannelButton variant="voice" organizationId={organizationId} />}
      >
        {ids.map((voiceRoomId) => (
          <VoiceRoom
            key={voiceRoomId}
            voiceRoomId={voiceRoomId}
            organizationId={organizationId}
            getMenuItems={getMenuItems}
          />
        ))}
      </UnityHubRoomsNavCollapse>

      {shouldShowPinnedRooms && (
        <div>
          {pinnedIds.map((id) => (
            <VoiceRoom
              key={id}
              voiceRoomId={id}
              organizationId={organizationId}
              getMenuItems={getMenuItems}
            />
          ))}
        </div>
      )}
    </>
  )
}
