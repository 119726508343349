import { AccountTypeNames } from '@medentee/enums'

import { EAvatarSize } from 'enums'
import { Avatar, AvatarTeam, ChatMessageCaseLabel, TruncatedText } from 'App/components'
import { redirectToCaseFromChat, TChatCase } from 'store'

import styles from './ReceivedCallGroup.module.scss'

export type TReceivedCallGroupProps = {
  lastName: string
  firstName: string
  chatId: string
  offererUserId: string
  displayUserName: string
  redirectToCaseFromChatRequest: typeof redirectToCaseFromChat

  caseData?: TChatCase
}

export const ReceivedCallGroup = ({
  lastName,
  firstName,
  chatId,
  offererUserId,
  caseData,
  displayUserName,
  redirectToCaseFromChatRequest
}: TReceivedCallGroupProps) => (
  <>
    <div className={styles.avatar}>
      <AvatarTeam size={EAvatarSize.LG} border={true} />
    </div>

    {caseData && chatId && (
      <div className={styles.caseLabel}>
        <ChatMessageCaseLabel
          chatId={chatId}
          yourMessage={true}
          redirectToCaseFromChatRequest={redirectToCaseFromChatRequest}
          case={caseData}
        />
      </div>
    )}

    {caseData && <div className={styles.title}>{caseData.title}</div>}

    <div className={styles.owner}>
      <Avatar
        hasTooltip={false}
        size={EAvatarSize.XXS}
        userId={offererUserId}
        firstName={firstName}
        lastName={lastName}
        displayUserName={displayUserName}
        type={AccountTypeNames.BUSINESS}
      />
      <TruncatedText classes={{ text: styles.name }} text={displayUserName} />
    </div>
  </>
)
