import { useTranslation } from 'react-i18next'

import { Button } from 'App/components/common/Button'
import { useContactInvitation } from 'App/hooks/useContactInvitation'

import styles from './UnityHubNetworkingDrawerFooter.module.scss'

export type TUnityHubNetworkingDrawerFooterProps = {
  memberId: string
  onSuccess: () => void
}

export const UnityHubNetworkingDrawerFooter = ({
  memberId,
  onSuccess
}: TUnityHubNetworkingDrawerFooterProps) => {
  const { t } = useTranslation()

  const { inviteToContacts } = useContactInvitation()

  const handleInvite = () => {
    inviteToContacts({ accountId: memberId, onSuccess })
  }

  return (
    <Button className={styles.button} onClick={handleInvite}>
      {t('unityHub.networking.member.drawer.footer')}
    </Button>
  )
}
