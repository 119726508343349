import { ReactNode } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import styles from './SubscriptionInfoCard.module.scss'
import { DetailList, TDetailListProps } from './DetailList'

type TSubscriptionInfoCardClasses = 'root' | 'title' | 'details'

type TSubscriptionInfoCardProps = {
  title: string | null
  detailList: TDetailListProps['list']

  detailListAddonBefore?: ReactNode
  loading?: boolean
  footerAction?: JSX.Element
  startAdornment?: JSX.Element
  active?: boolean
  activeLabel?: boolean
  isFuture?: boolean
  classes?: Partial<Record<TSubscriptionInfoCardClasses, string>>
  subTitle?: ReactNode
}

export const SubscriptionInfoCard = ({
  loading,
  title,
  footerAction,
  detailList,
  detailListAddonBefore,
  active,
  isFuture,
  classes,
  subTitle,
  startAdornment
}: TSubscriptionInfoCardProps) => (
  <div
    className={cls(
      styles.root,
      isFuture && styles.future,
      (!isFuture || active) && styles.available,
      classes?.root
    )}
  >
    <Skeleton
      active={true}
      loading={loading}
      title={true}
      paragraph={{ rows: 6 }}
      className={styles.skeleton}
    >
      {title && (
        <div className={cls(styles.title, classes?.title)}>
          {title}
          {subTitle}
        </div>
      )}
      {startAdornment}
      <DetailList
        classes={{ root: classes?.details }}
        variant="column"
        list={detailList}
        addonBefore={detailListAddonBefore}
      />
      {footerAction}
    </Skeleton>
  </div>
)
