import { useState, useCallback } from 'react'

export const useSteps = (defaultStep: number) => {
  const [step, setStep] = useState<number>(defaultStep)

  const handleSetNextStep = useCallback(() => {
    setStep((current) => current + 1)
  }, [setStep])

  const handleSetPrevStep = useCallback(() => {
    setStep((current) => current - 1)
  }, [setStep])

  return {
    step,
    setNextStep: handleSetNextStep,
    setPrevStep: handleSetPrevStep,
    setStep
  } as const
}
