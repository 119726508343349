import { Steps as StepsOriginal } from 'antd'
import { StepProps, StepsProps } from 'antd/es/steps'
import cls from 'classnames'

import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'

import styles from './Steps.module.scss'

const { Step } = StepsOriginal

export type StepsComponentProps = {
  current: number
  stepsArray: StepProps[]
  className?: string
} & StepsProps

export const Steps = ({ stepsArray, current, className, ...rest }: StepsComponentProps) => (
  <div className={cls(styles.root, className)}>
    <StepsOriginal current={current} {...rest} direction="horizontal" responsive={false}>
      {stepsArray.map((step, index) => (
        <Step
          key={index}
          icon={index < current ? <CheckCircleOutlineIcon className={styles.icon} /> : null}
          {...step}
        />
      ))}
    </StepsOriginal>
  </div>
)
