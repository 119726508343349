import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

import { TChatPathParams } from 'App/screens'
import { useSteps } from 'App/hooks'
import {
  TAccount,
  TContactsDataFromTo,
  TIds,
  accountIdSelector,
  useAppSelector,
  getSelectedChatRoomSelector
} from 'store'
import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'

import { ESteps } from './ChatCreateGroup.utils'

type TContextProps = {
  step: ESteps
  chatId: string
  selectedMembers: TContactsDataFromTo[] | TAccount[]
  chatName: string
  touchedChatName: boolean
  file: File | null
  shouldRemovePhoto: boolean
  isChatOwner: boolean
  removedMemberIds: TIds
  chatMembersTotal: number
  prompt: JSX.Element
  setChatMembersTotal: Dispatch<SetStateAction<number>>
  setRemovedMemberIds: Dispatch<SetStateAction<TIds>>
  setShouldRemovePhoto: Dispatch<SetStateAction<boolean>>
  setFile: Dispatch<SetStateAction<File | null>>
  setChatName: Dispatch<SetStateAction<string>>
  setTouchedChatName: Dispatch<SetStateAction<boolean>>
  setSelectedMembers: Dispatch<SetStateAction<TContactsDataFromTo[] | TAccount[]>>
  setStep: Dispatch<SetStateAction<ESteps>>
  ignorePrompt: () => boolean

  accountId?: string
  channelManagerId?: string
  selectedChatId?: string | null
  activeChatId?: string | null
}

type TInitialState = {
  variant: 'create' | 'edit'
}

export type TChatCreateGroupContextProviderProps = {
  children: ReactNode
  initialState: TInitialState
}

const ChatCreateGroupContext = createContext<TContextProps>({} as TContextProps)

export const useChatCreateGroupContext = () => {
  const context = useContext(ChatCreateGroupContext)

  if (context === undefined) {
    throw new Error(
      'useChatCreateGroupContext must be used within a ChatCreateGroupContextProvider'
    )
  }

  return context
}

export const ChatCreateGroupContextProvider = ({
  children,
  initialState
}: TChatCreateGroupContextProviderProps) => {
  const { variant = 'create' } = initialState
  const { chatId = '' } = useParams<TChatPathParams>()

  const [selectedMembers, setSelectedMembers] = useState<TContactsDataFromTo[] | TAccount[]>([])
  const [chatMembersTotal, setChatMembersTotal] = useState(0)
  const [removedMemberIds, setRemovedMemberIds] = useState<TIds>([])
  const [chatName, setChatName] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [touchedChatName, setTouchedChatName] = useState(false)
  const [shouldRemovePhoto, setShouldRemovePhoto] = useState(false)

  const accountId = useAppSelector(accountIdSelector)
  const activeChatId = useAppSelector((state) => state.chat.chatRooms.activeChatId)

  const {
    channelManager,
    name: chatRoomName = '',
    id: selectedChatId
  } = useAppSelector(getSelectedChatRoomSelector(chatId)) ?? {}

  const { step, setStep } = useSteps(variant === 'create' ? ESteps.SELECT_MEMBERS : ESteps.EDIT)

  const { prompt, setFormChanged, ignore: ignorePrompt } = useUnsavedDataPrompt()

  const isChatOwner = accountId === channelManager?.id

  useEffect(() => {
    if (chatRoomName && chatName) {
      setFormChanged(
        Boolean(
          chatRoomName !== chatName ||
            removedMemberIds.length ||
            selectedMembers.length ||
            file ||
            shouldRemovePhoto
        )
      )
    }
  }, [
    chatName,
    chatRoomName,
    setFormChanged,
    removedMemberIds.length,
    selectedMembers.length,
    file,
    shouldRemovePhoto
  ])

  useLayoutEffect(() => {
    if (chatRoomName && step === ESteps.EDIT) {
      setChatName(chatRoomName)
    }
  }, [chatRoomName, setChatName, step])

  return (
    <ChatCreateGroupContext.Provider
      value={{
        selectedChatId,
        activeChatId,
        isChatOwner,
        channelManagerId: channelManager?.id,
        accountId,
        chatId,
        step,
        selectedMembers,
        chatName,
        file,
        touchedChatName,
        shouldRemovePhoto,
        chatMembersTotal,
        prompt,
        removedMemberIds,
        setChatMembersTotal,
        ignorePrompt,
        setRemovedMemberIds,
        setShouldRemovePhoto,
        setSelectedMembers,
        setChatName,
        setFile,
        setTouchedChatName,
        setStep
      }}
    >
      {children}
    </ChatCreateGroupContext.Provider>
  )
}
