import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { BadgeItem, Button } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './SelectedFilters.module.scss'

export type TSelectedFilter = {
  id: string
  name?: string
}

type TSelectedFiltersProps = {
  filters: TSelectedFilter[]
  onClear: () => void
}

export const SelectedFilters = ({ filters, onClear }: TSelectedFiltersProps) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[8, 8]} className={styles.root}>
      {filters.map(
        ({ id, name }) =>
          name && (
            <Col key={id + name} className={styles.col}>
              <BadgeItem title={name} />
            </Col>
          )
      )}

      <Col>
        <Button
          className={styles.button}
          variant="text"
          size="md"
          icon={<CrossIcon />}
          iconPosition="right"
          adaptive={false}
          onClick={onClear}
        >
          {t('common.filters.clearAllButton')}
        </Button>
      </Col>
    </Row>
  )
}
