import { CaseClassificationNameEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IconLabel, TIconLabelPropsClasses } from 'App/components'
import { getMapComponent } from 'utils'
import { ReactComponent as CaseGeneralIcon } from 'assets/icons/CaseGeneral.svg'
import { ReactComponent as MedicalProvidersIcon } from 'assets/icons/MedicalProviders.svg'

export type TCaseClassificationProps = PropsWithClasses<
  TIconLabelPropsClasses,
  { classification: CaseClassificationNameEnum }
>

const CASE_CLASSIFICATION_ICON = new Map<CaseClassificationNameEnum, () => JSX.Element>()
  .set(CaseClassificationNameEnum.PRIVATE, () => <CaseGeneralIcon />)
  .set(CaseClassificationNameEnum.PROFESSIONAL, () => <MedicalProvidersIcon />)

export const CaseClassification = ({ classification, classes }: TCaseClassificationProps) => {
  const { t } = useTranslation()

  return (
    <IconLabel
      classes={classes}
      icon={getMapComponent(CASE_CLASSIFICATION_ICON, classification)}
      label={t(`enum.caseClassificationNameEnum.${classification}`)}
    />
  )
}
