import { useEffect } from 'react'
import { NotificationListTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getNotificationsProtocolList } from 'api/notifications'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { generateRandomArray } from 'utils'
import { DEFAULT_SKELETON_IDS_LENGTH } from 'globalConstants'
import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg'
import { EEmptyListIconSize, EmptyList } from 'App/components'
import { getNotificationsGeneralRequest, useAppDispatch } from 'store'

import { NotificationsPopoverSkeleton } from '../NotificationsPopover/NotificationsPopoverSkeleton'
import { useReadNotifications } from '../NotificationsReadAllButton/useReadNotifications'

import styles from './NotificationsMain.module.scss'
import { NotificationsMainItem } from './NotificationsMainItem'

type TNotificationsMainProps = {
  page?: number
  withPagination?: boolean
}

const DEFAULT_SHOW_BY = 30

const FAKE_ITEMS = generateRandomArray(DEFAULT_SKELETON_IDS_LENGTH)

export const GET_MAIN_NOTIFICATIONS_QUERY_KEY = 'get-main-notifications'

export const NotificationsMain = ({ page, withPagination = false }: TNotificationsMainProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { data, isFetching } = useQuery({
    queryKey: [GET_MAIN_NOTIFICATIONS_QUERY_KEY, page],
    queryFn: () =>
      getNotificationsProtocolList({
        current: page,
        showBy: withPagination ? PAGINATION_DEFAULT_SHOW_BY : DEFAULT_SHOW_BY
      }),
    onSuccess: () => {
      dispatch(getNotificationsGeneralRequest())
    }
  })

  const { mutate } = useReadNotifications()

  useEffect(() => {
    if (!!data?.results) {
      mutate({
        types: [NotificationListTypeEnum.DEFAULT],
        notificationIds: data?.results.map(({ id }) => id)
      })
    }
  }, [data?.results, mutate])

  if (isFetching) {
    return (
      <div className={styles.root}>
        {FAKE_ITEMS.map((index) => (
          <NotificationsPopoverSkeleton key={index} />
        ))}
      </div>
    )
  }

  return (
    <div className={styles.root}>
      {data?.results.length ? (
        data.results.map((item) => <NotificationsMainItem key={item.id} {...item} />)
      ) : (
        <EmptyList
          iconSize={EEmptyListIconSize.MD}
          icon={<BellIcon />}
          text={t('placeholder_main', { ns: 'notifications' })}
        />
      )}
    </div>
  )
}
