import { TIds } from 'store'
import { CaseCloudSharedWithMeFilesItemContainer } from 'App/containers'
import { TCaseCloudFilesItemBaseProps } from 'App/components'

import { CaseCloudFilesListWrapper } from '../CaseCloudFilesListWrapper'

export type TCaseCloudSharedWithMeFilesListProps = Pick<
  TCaseCloudFilesItemBaseProps,
  'caseStatus' | 'caseView' | 'getKebabMenu' | 'onSelect' | 'filesView' | 'selectMode'
> & {
  sharedWithMeIds: TIds
  loading: boolean
  caseId: string

  selectedIds?: TIds
}

export const CaseCloudSharedWithMeFilesList = ({
  sharedWithMeIds,
  caseStatus,
  loading,
  caseId,
  caseView,
  selectedIds,
  filesView,
  selectMode,
  getKebabMenu,
  onSelect
}: TCaseCloudSharedWithMeFilesListProps) => (
  <CaseCloudFilesListWrapper filesView={filesView} loading={loading} ids={sharedWithMeIds}>
    {(id) => (
      <CaseCloudSharedWithMeFilesItemContainer
        key={id}
        id={id}
        originalEntityId={caseId}
        caseStatus={caseStatus}
        caseView={caseView}
        selected={selectedIds?.includes(id)}
        selectedIdsLength={selectedIds?.length}
        filesView={filesView}
        selectMode={selectMode}
        getKebabMenu={getKebabMenu}
        onSelect={onSelect}
      />
    )}
  </CaseCloudFilesListWrapper>
)
