import { PaginationProps } from 'antd'

import { stopPropagation } from 'utils'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/ChevronRight.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ChevronLeft.svg'

import styles from './PaginationItem.module.scss'

type TPaginationItem = Required<Pick<PaginationProps, 'itemRender'>>['itemRender']

export const PaginationItem: TPaginationItem = (page, type, element) => {
  if (type === 'jump-prev' || type === 'jump-next') {
    return (
      <div tabIndex={-1} onClick={stopPropagation} className={styles.separator}>
        ...
      </div>
    )
  }

  if (type === 'prev') {
    return (
      <div className={styles.step}>
        <ArrowLeftIcon />
      </div>
    )
  }

  if (type === 'next') {
    return (
      <div className={styles.step}>
        <ArrowRightIcon />
      </div>
    )
  }

  if (type === 'page') {
    return <div className={styles.page}>{page}</div>
  }

  return element
}
