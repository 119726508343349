import { useTranslation } from 'react-i18next'

import styles from './Organizer.module.scss'

type TOrganizerProps = {
  name: string
}

export const Organizer = ({ name }: TOrganizerProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      {t('meetings.organizerLabel')} <span className={styles.name}>{name}</span>
    </div>
  )
}
