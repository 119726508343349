import cls from 'classnames'

import { ReactComponent as VolumeOffIcon } from 'assets/icons/VolumeOff.svg'

import styles from './ChatMutedIcon.module.scss'

type TChatMutedIconProps = PropsWithClassName<{
  size?: 'md' | 'sm'
}>

export const ChatMutedIcon = ({ className, size = 'sm' }: TChatMutedIconProps) => (
  <VolumeOffIcon className={cls(styles.root, styles[size], className)}></VolumeOffIcon>
)
