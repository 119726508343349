import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ComingSoon.module.scss'

export type TComingSoonProps = { children: ReactNode }

export const ComingSoon = ({ children }: TComingSoonProps) => {
  const { t } = useTranslation()

  return (
    <span className={styles.root}>
      {children}
      <span className={styles.text}>{t('common.comingSoon')}</span>
    </span>
  )
}
