import { useMemo } from 'react'

import { ContextMenu, TMenuItemProps, TruncatedText } from 'App/components/common'

import styles from './Header.module.scss'

type THeaderProps = {
  title: string
  menuItems?: TMenuItemProps[]
}

export const Header = ({ title, menuItems }: THeaderProps) => {
  const shouldShowKebab = useMemo(
    () => !!menuItems?.filter(({ hidden }) => !hidden).length,
    [menuItems]
  )

  return (
    <header className={styles.header}>
      <TruncatedText classes={{ text: styles.title }} text={title} />
      {shouldShowKebab && <ContextMenu menuItems={menuItems} />}
    </header>
  )
}
