import { normalize, schema } from 'normalizr'

import { IChatRoomsDTO } from 'interfaces'
import { TChatRoomsNormalizeResult, TChatRoomsNormalized } from 'store'

const listSchema = new schema.Entity('list', {}, { idAttribute: 'id' })
const ids = new schema.Array(listSchema)

type TChatRoomsNormalize = (data: IChatRoomsDTO[]) => TChatRoomsNormalized

export const chatRoomsNormalize: TChatRoomsNormalize = (originalData) => {
  const { entities, result = [] }: TChatRoomsNormalizeResult = normalize(originalData, ids)

  return {
    list: entities.list || {},
    ids: result || []
  }
}
