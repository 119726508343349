import { ReactNode } from 'react'
import cls from 'classnames'

import { EAvatarSize } from 'enums'
import { AvatarContainer } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'
import {
  AccountName,
  ContextMenu,
  DisplayLocationTime,
  TAvatarProps,
  TContextMenuProps
} from 'App/components'
import { KebabContactStatus } from 'App/components/common/KebabContactStatus'
import { TAccountData } from 'store/store.types'
import { TContactsData } from 'store/contacts/contacts.types'

import styles from './UnityHubNetworkingItem.module.scss'
import {
  TUnityHubNetworkingItemActionsProps,
  UnityHubNetworkingItemActions
} from './UnityHubNetworkingItemActions'

export type TUnityHubNetworkingItemProps = Pick<TContextMenuProps, 'menuItems'> &
  Pick<TUnityHubNetworkingItemActionsProps, 'onContactInviteSuccess' | 'disableInvitation'> &
  PropsWithClassName<{
    account: TAccountData
    currentAccountId: string
    details: ReactNode

    contact?: Pick<TContactsData, 'status' | 'fromId'>
    chatId?: string
    actions?: ReactNode
    avatarProps?: Pick<TAvatarProps, 'isEventProfile' | 'eventId' | 'userId' | 'online'>
    onAccountClick?: () => void
  }>

export const UnityHubNetworkingItem = ({
  account,
  contact,
  chatId,
  currentAccountId,
  menuItems,
  details,
  actions,
  className,
  disableInvitation,
  avatarProps,
  onAccountClick,
  onContactInviteSuccess
}: TUnityHubNetworkingItemProps) => {
  const { isMobile } = useAdaptiveLayout()

  const {
    city,
    country,
    lastName,
    firstName,
    type,
    id: userId,
    displayUserName,
    isContact
  } = account || {}

  const shouldShowActions = currentAccountId !== account.id

  return (
    <div className={cls(styles.root, className)}>
      {!isMobile && (
        <div className={styles.avatar}>
          <AvatarContainer
            userId={userId}
            size={EAvatarSize.LG}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type?.name}
            hasTooltip={false}
            {...avatarProps}
            onClick={onAccountClick}
          />

          {shouldShowActions && (
            <UnityHubNetworkingItemActions
              chatId={chatId}
              userId={userId}
              contactInviteStatus={contact?.status}
              isContact={isContact}
              disableInvitation={disableInvitation}
              onContactInviteSuccess={onContactInviteSuccess}
            />
          )}
        </div>
      )}
      <div className={styles.details}>
        <div className={styles.account}>
          {isMobile && (
            <AvatarContainer
              userId={userId}
              size={EAvatarSize.LG}
              firstName={firstName}
              lastName={lastName}
              displayUserName={displayUserName}
              type={type?.name}
              hasTooltip={false}
              {...avatarProps}
              onClick={onAccountClick}
            />
          )}
          <div className={styles.header}>
            <div className={styles.accountName}>
              <AccountName
                displayUserName={displayUserName}
                type={type?.name}
                hideIcon={isMobile}
                userId={userId}
                classes={{ root: styles.accountRoot }}
                isYourAccountIndicator="name"
                onClick={onAccountClick}
              />
              {shouldShowActions && (
                <div className={styles.contextMenu}>
                  {actions}
                  <ContextMenu
                    menuItems={menuItems}
                    endAdornment={
                      <KebabContactStatus
                        accountId={currentAccountId}
                        fromId={contact?.fromId}
                        status={contact?.status}
                      />
                    }
                  />
                </div>
              )}
            </div>

            {isMobile && shouldShowActions && (
              <UnityHubNetworkingItemActions
                chatId={chatId}
                userId={userId}
                contactInviteStatus={contact?.status}
                isContact={isContact}
                onContactInviteSuccess={onContactInviteSuccess}
              />
            )}
          </div>
        </div>
        <div className={styles.detailsList}>
          <DisplayLocationTime
            city={city?.cityName}
            country={country?.countryName ?? ''}
            timeZone={city?.timezone}
          />

          {details}
        </div>
      </div>
    </div>
  )
}
