import { BusinessAccountRoleNameEnum, SubscriptionIdsEnum } from '@medentee/enums'
import { Link } from 'react-router-dom'
import cls from 'classnames'

import { useAppSelector } from 'store'
import { ActivePlan } from 'App/components'

import { HeaderUserBalance } from '../HeaderUserBalance'

import styles from './Subscription.module.scss'

type TSubscription = {
  role?: BusinessAccountRoleNameEnum
}

export const Subscription = ({ role }: TSubscription) => {
  const { subscription, graceOn } = useAppSelector((state) => state.subscriptions.current) ?? {}
  const { id } = subscription ?? {}

  const isAdmin = role === BusinessAccountRoleNameEnum.ADMIN

  const isBasic =
    id === SubscriptionIdsEnum.BASIC_PROFESSIONAL || id === SubscriptionIdsEnum.BASIC_BUSINESS

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    isAdmin && event.preventDefault()
  }

  return (
    <div className={styles.root}>
      <Link
        to="/subscription-and-payments/subscription"
        className={cls(styles.rootItem, isAdmin && styles.rootItemDisabled)}
        onClick={handleClick}
      >
        <ActivePlan updatable={isBasic} id={id} graceOn={graceOn} />
      </Link>
      {!isAdmin && (
        <Link to="/subscription-and-payments/balance" className={styles.rootItem}>
          <HeaderUserBalance />
        </Link>
      )}
    </div>
  )
}
