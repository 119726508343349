import { useCallback, useMemo, useRef, useEffect } from 'react'
import { AccountTypeNames, MeetingInviteStatusEnum, MeetingStatusEnum } from '@medentee/enums'
import { Col, Row } from 'antd'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { EAvatarSize } from 'enums'
import { AnchorifedText, Avatar, AvatarTeam, Button } from 'App/components'
import {
  Duration,
  Header,
  Organizer,
  ParticipantsStatuses,
  CaseLabel,
  MeetingStatus
} from 'App/components/Meetings/common'
import {
  acceptMeetingInviteRequest,
  ACCEPT_MEETING_INVITE,
  accountIdSelector,
  originalAccountIdSelector,
  createProcessingBySelector,
  showModalAction,
  useAppDispatch,
  useAppSelector,
  rejectMeetingInviteRequest,
  TMeeting,
  TMeetingInvite,
  REJECT_MEETING_INVITE
} from 'store'
import { EModalComponents } from 'App/containers'
import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'

import { useKebabMenu } from '../useKebabMenu'

import { Join } from './Join'
import styles from './Meeting.module.scss'

const acceptingInviteSelector = createProcessingBySelector([ACCEPT_MEETING_INVITE])
const loadingActions = [REJECT_MEETING_INVITE]

export type TMeetingProps = {
  id: string

  anchorId?: string
}
export const Meeting = ({ id, anchorId }: TMeetingProps) => {
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement | null>(null)

  const processing = useAppSelector((state) => acceptingInviteSelector(id)(state))
  const accountId = useAppSelector(accountIdSelector)
  const originalAccountId = useAppSelector(originalAccountIdSelector)
  const {
    organizer,
    invites,
    title,
    startOn,
    endOn,
    status,
    call,
    case: caseMeeting,
    originalOrganizer,
    location,
    type,
    hasReminder,
    id: meetingId,
    timezone,
    inviteAllActiveMembers
  }: Partial<TMeeting> = useAppSelector((state) => state.meetings.list[id] ?? {}, isEqual)

  const { t } = useTranslation()

  const isOrganizer = organizer?.id === accountId
  const isMeetingPending = status === MeetingStatusEnum.PENDING
  const isMeetingOpen = status === MeetingStatusEnum.OPEN

  const myInvitation = useMemo<TMeetingInvite | undefined>(
    () => invites?.find(({ account }) => account.id === accountId) || invites?.[0],
    [invites, accountId]
  )

  const incomingInviteStatus = !isOrganizer ? myInvitation?.status : undefined
  const isMeetingAvailable =
    isMeetingOpen && myInvitation?.status !== MeetingInviteStatusEnum.REJECTED
  const isMeetingInvitePending = incomingInviteStatus === MeetingInviteStatusEnum.PENDING
  const isMeetingInviteAccepted = incomingInviteStatus === MeetingInviteStatusEnum.ACCEPTED

  const {
    id: userId,
    lastName,
    firstName,
    displayUserName,
    type: { name: typeName }
  } = isOrganizer && myInvitation?.account ? myInvitation?.account : organizer

  const shouldShowMeetingInviteActions = !isOrganizer && !isMeetingAvailable
  const shouldShowMeetingRejectAction = isMeetingInvitePending && isMeetingPending
  const shouldShowMeetingAcceptAction =
    incomingInviteStatus !== MeetingInviteStatusEnum.ACCEPTED &&
    status !== MeetingStatusEnum.EXPIRED

  const shouldShowStatus =
    !isMeetingAvailable && (!caseMeeting || (caseMeeting && !isMeetingPending)) && !isMeetingOpen

  const shouldShowParticipantsStatuses = caseMeeting && isOrganizer && isMeetingPending

  const shouldShowJoinButton =
    call?.id &&
    isMeetingOpen &&
    (isOrganizer || (!isOrganizer && (isMeetingInvitePending || isMeetingInviteAccepted)))

  const organizerName = useMemo(() => {
    const isBusinessAccount = organizer.type.name === AccountTypeNames.BUSINESS
    const isOriginalOrganizer = originalOrganizer?.id === originalAccountId

    if (isOrganizer && !isBusinessAccount) {
      return t('modal.meetings.personalIndicatorLabel')
    }

    if (isOrganizer && isBusinessAccount) {
      return isOriginalOrganizer
        ? t('modal.meetings.organizerLabel', { name: organizer.displayUserName })
        : `${organizer.displayUserName} (${originalOrganizer?.displayUserName})`
    }

    return organizer.displayUserName
  }, [
    originalAccountId,
    isOrganizer,
    organizer.displayUserName,
    organizer.type.name,
    originalOrganizer?.displayUserName,
    originalOrganizer?.id,
    t
  ])

  const onCloseCallback = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.meetings.title'),
        modalType: EModalComponents.MEETINGS
      })
    )
  }, [dispatch, t])

  const handleAccept = () => {
    dispatch(acceptMeetingInviteRequest({ id, processingId: id, onSuccess: onCloseCallback }))
  }

  const handleReject = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.rejectMeetingConfirm.title'),
        modalType: EModalComponents.GENERIC_CONFIRMATION,
        modalProps: {
          loadingActions,
          processingId: id,
          cancelLabel: t('modal.rejectMeetingConfirm.closeButton'),
          confirmLabel: t('modal.rejectMeetingConfirm.submitButton'),
          onCancel: onCloseCallback,
          content: t('modal.rejectMeetingConfirm.content'),
          onConfirm: () => {
            dispatch(
              rejectMeetingInviteRequest({ id, processingId: id, onSuccess: onCloseCallback })
            )
          }
        }
      })
    )
  }, [dispatch, id, onCloseCallback, t])

  const { getMenuItems } = useKebabMenu({
    meeting: {
      organizer,
      invites,
      title,
      startOn,
      endOn,
      status,
      call,
      case: caseMeeting,
      originalOrganizer,
      location,
      type,
      hasReminder,
      id: meetingId,
      timezone,
      inviteAllActiveMembers
    },
    cancelOnCloseCallback: onCloseCallback,
    cancelOnSuccessCallback: onCloseCallback,
    rejectOnSuccessCallback: onCloseCallback,
    editOnCloseCallback: onCloseCallback,
    editOnSuccessCallback: onCloseCallback
  })

  const menuItems = useMemo(
    () => getMenuItems({ id, inviteStatus: incomingInviteStatus, isOrganizer, status }),
    [getMenuItems, id, isOrganizer, status, incomingInviteStatus]
  )

  useEffect(() => {
    if (ref.current?.scrollIntoView && anchorId === id) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [anchorId, id])

  return (
    <Row className={styles.root} wrap={false} ref={ref}>
      {caseMeeting ? (
        <AvatarTeam size={EAvatarSize.MD} />
      ) : (
        <Avatar
          size={EAvatarSize.MD}
          userId={userId}
          lastName={lastName}
          firstName={firstName}
          type={typeName}
          displayUserName={displayUserName}
        />
      )}
      <Col flex={1}>
        <Header title={title} menuItems={menuItems} />

        <Duration startOn={startOn} endOn={endOn} />

        {location && (
          <div className={styles.location}>
            <LocationIcon className={styles.locationIcon} />
            <AnchorifedText text={location} />
          </div>
        )}

        <div className={styles.footer}>
          <Row gutter={[0, 8]} className={styles.footerContent}>
            <Col span={24}>
              <Organizer name={organizerName} />
            </Col>

            {caseMeeting && (
              <Col span={24}>
                <CaseLabel
                  caseId={caseMeeting.id}
                  caseTitle={caseMeeting.title}
                  isOwner={isOrganizer}
                />
              </Col>
            )}

            {shouldShowParticipantsStatuses && (
              <Col span={24}>
                <ParticipantsStatuses invites={invites} />
              </Col>
            )}
          </Row>
          {shouldShowStatus && (
            <MeetingStatus
              inviteStatus={invites?.[0]?.status} // Treated either like invite of P2P meeting or incoming invite of group meeting
              meetingStatus={status}
              meetingType={type}
              isOrganizer={isOrganizer}
            />
          )}
        </div>

        <footer className={styles.buttons}>
          {shouldShowJoinButton && <Join callId={call.id} />}

          {shouldShowMeetingInviteActions && (
            <>
              {shouldShowMeetingAcceptAction && (
                <Button variant="text" loading={processing} onClick={handleAccept}>
                  <CheckIcon /> {t('modal.meetings.acceptButton')}
                </Button>
              )}
              {shouldShowMeetingRejectAction && (
                <Button variant="text" color="error" onClick={handleReject}>
                  <CrossIcon /> {t('modal.meetings.rejectButton')}
                </Button>
              )}
            </>
          )}
        </footer>
      </Col>
    </Row>
  )
}
