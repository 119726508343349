import { useTranslation } from 'react-i18next'

import { formatBytes, formatShortDate } from 'utils'
import { FileDetailsItem } from 'App/components'
import { FileDetailsP2PContainer } from 'App/containers'

import styles from './FileDetails.module.scss'

export type TFileDetailsProps = {
  id: string
  fileSize: string
  createdAt: string
  updatedAt: string
  withoutPermissions?: boolean
}

export const FileDetails = ({
  id,
  fileSize,
  createdAt,
  updatedAt,
  withoutPermissions
}: TFileDetailsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ul className={styles.root}>
        <FileDetailsItem
          label={t('files.information.details.fileSize')}
          value={formatBytes(fileSize)}
        />
        <FileDetailsItem label={t('files.information.details.fileHolder')} value="me" />
        <FileDetailsItem
          label={t('files.information.details.uploaded')}
          value={formatShortDate(createdAt)}
        />
        <FileDetailsItem
          label={t('files.information.details.lastModified')}
          value={formatShortDate(updatedAt)}
        />
      </ul>

      {!withoutPermissions && <FileDetailsP2PContainer fileId={id} />}
    </>
  )
}
