import { FC } from 'react'
import { Skeleton } from 'antd'
import cls from 'classnames'

import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { InfiniteScroll, TInfiniteScrollProps } from 'App/components/common'

import styles from './GroupedMembersList.module.scss'

export type TGroupedMembersListProps = Pick<TInfiniteScrollProps, 'scrollableTarget'> &
  PropsWithClasses<
    'root' | 'skeleton' | 'infiniteScroll',
    {
      loading: boolean
      processing: boolean
      total: number
      page: number
      dataLength: number
      setPage: (nextPage: number) => void

      limit?: number
    }
  >

const ROOT_ID = 'grouped_members_list'

export const GroupedMembersList: FC<TGroupedMembersListProps> = ({
  loading,
  processing,
  scrollableTarget,
  total,
  page,
  dataLength,
  children,
  classes,
  setPage,
  limit = PAGINATION_DEFAULT_SHOW_BY
}) => (
  <div id={ROOT_ID} className={cls(styles.root, classes?.root, loading && styles.skeleton)}>
    <Skeleton
      loading={loading}
      title={false}
      active={true}
      paragraph={{ rows: 12 }}
      className={cls(classes?.skeleton)}
    >
      <InfiniteScroll
        next={setPage}
        className={classes?.infiniteScroll}
        loading={processing}
        total={total}
        page={page}
        dataLength={dataLength}
        limit={limit}
        scrollableTarget={scrollableTarget ?? ROOT_ID}
      >
        {children}
      </InfiniteScroll>
    </Skeleton>
  </div>
)
