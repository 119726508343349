import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorModal } from 'App/components'
import { Button } from 'App/components/common'
import { TRenewSubscriptionPlanDialogContainerProps } from 'App/containers'

import styles from './RenewSubscriptionPlanDialog.module.scss'

type TRenewSubscriptionPlanDialogProps = TRenewSubscriptionPlanDialogContainerProps

export const RenewSubscriptionPlanDialog = ({
  onConfirm,
  error,
  processing,
  removeErrorNotification
}: TRenewSubscriptionPlanDialogProps) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification()
    },
    [removeErrorNotification]
  )

  return (
    <div className={styles.root}>
      <p className={styles.rootTitle}>{t('modal.renewSubscriptionPlan.content')}</p>

      <Button className={styles.rootButton} loading={processing} onClick={onConfirm}>
        {t('modal.renewSubscriptionPlan.submitButton')}
      </Button>

      <ErrorModal error={error} />
    </div>
  )
}
