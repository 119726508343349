import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { MINUTE } from 'utils'
import { CHAT_PATH } from 'globalConstants'
import {
  TMeetingType,
  createOrganizationChatRequest,
  resetCaseMembers,
  scheduleMeetingRequest,
  setModalTitleAction
} from 'store'
import { useSteps } from 'App/hooks/useSteps'

import { timeZones, TTimeZoneSelectOption, TTimeRangePickerProps, Steps } from '../common'
import { TAccountsSelectOptionData } from '../common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { ScheduleMeetingModalSecondStep } from './ScheduleMeetingModalSecondStep'
import { ScheduleMeetingModalFirstStep } from './ScheduleMeetingModalFirstStep'
import { ScheduleMeetingSubTitle } from './ScheduleMeetingSubTitle'
import { getMeetingTime, getInitialTime } from './ScheduleMeetingModal.utils'
import { ScheduleMeetingModalForm } from './ScheduleMeetingModalForm'
import { ScheduleMeetingModalSelectContactStep } from './ScheduleMeetingModalSelectContactStep/ScheduleMeetingModalSelectContactStep'

export type TScheduleMeetingModalProps = {
  chatId?: string

  userName?: string
  caseId?: string
  caseTitle?: string
  isGroupMeeting?: boolean
  selectContact?: boolean
  onClickBack?: () => void
  onSuccess?: (anchorId?: string) => void
}

export type TScheduleMeetingModalFormValues = {
  type: TMeetingType
  title: string
  date: Date
  timeZone: TTimeZoneSelectOption | null
  time: TTimeRangePickerProps['value']
  hasReminder: boolean
  inviteAll: boolean
  participants: TAccountsSelectOptionData[]

  location?: string
}

enum STEP {
  SELECT_CONTACT = 'SELECT_CONTACT',
  FIRST = 'FIRST',
  SECOND = 'SECOND'
}

const STEPS_SELECT_CONTACT: Record<number, STEP> = {
  0: STEP.SELECT_CONTACT,
  1: STEP.FIRST,
  2: STEP.SECOND
}

const STEPS: Record<number, STEP> = {
  0: STEP.FIRST,
  1: STEP.SECOND
}

export const ScheduleMeetingModal = ({
  chatId,
  caseId,
  caseTitle,
  isGroupMeeting,
  userName,
  selectContact,
  onClickBack,
  onSuccess
}: TScheduleMeetingModalProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const { step: stepIndex, setNextStep, setPrevStep } = useSteps(0)

  const step = selectContact ? STEPS_SELECT_CONTACT[stepIndex] : STEPS[stepIndex]

  const [shouldShowSubtitle, setShouldShowSubtitle] = useState(true)
  const [selectedAccount, setSelectedAccount] = useState<TAccountsSelectOptionData | null>(null)

  const isOnChatPage = !!useRouteMatch(CHAT_PATH)?.isExact

  const stepsArray = useMemo(
    () => Array.from({ length: selectContact ? 3 : 2 }, () => ({})),
    [selectContact]
  )

  const initialValues = useMemo<TScheduleMeetingModalFormValues>(() => {
    const time = getInitialTime()

    return {
      type: 'ONLINE',
      title: '',
      date: time?.[0] ?? new Date(),
      time,
      timeZone:
        timeZones.find(({ value }) => value === Intl.DateTimeFormat().resolvedOptions().timeZone) ??
        timeZones.find(({ offset }) => offset === -1 * new Date().getTimezoneOffset() * MINUTE) ??
        null,
      hasReminder: true,
      inviteAll: false,
      participants: [],
      location: undefined
    }
  }, [])

  useEffect(
    () => () => {
      if (isOnChatPage) {
        dispatch(resetCaseMembers())
      }
    },
    [dispatch, isOnChatPage]
  )

  const onSubmit = useCallback(
    (values: TScheduleMeetingModalFormValues) => {
      const { time, title, date, hasReminder, timeZone, participants, inviteAll, type, location } =
        values

      const selectedChatId = selectedAccount?.chatId || chatId

      if (date && time && time[0] && time[1] && timeZone) {
        const { endOn, startOn } = getMeetingTime(date, time, timeZone.value)

        const schedule = (id?: string) =>
          id &&
          dispatch(
            scheduleMeetingRequest({
              type,
              title,
              chatId: id,
              hasReminder,
              timezone: timeZone.value,
              inviteAllActiveMembers: inviteAll,
              startOn,
              endOn,
              guestAccountIds:
                !inviteAll && participants.length ? participants.map((item) => item.id) : undefined,
              location,
              onSuccess
            })
          )

        if (!selectedChatId && selectedAccount?.id) {
          dispatch(
            createOrganizationChatRequest({
              partnerAccountId: selectedAccount.id,
              onSuccess: schedule
            })
          )

          return
        }

        schedule(selectedChatId)
      }
    },
    [chatId, dispatch, onSuccess, selectedAccount?.chatId, selectedAccount?.id]
  )

  const handleContactSelect = useCallback(
    (account) => {
      setSelectedAccount(account)
      setNextStep()
    },
    [setNextStep]
  )

  const handleFirstStepBack = useCallback(() => {
    dispatch(setModalTitleAction({ modalTitle: t('modal.scheduleMeeting.title_contact') }))
    setPrevStep()
  }, [dispatch, setPrevStep, t])

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        {shouldShowSubtitle && step !== STEP.SELECT_CONTACT && (
          <ScheduleMeetingSubTitle
            isGroupMeeting={isGroupMeeting}
            caseTitle={caseTitle}
            userName={selectedAccount?.displayUserName ?? userName}
          />
        )}
      </Col>

      <Col xs={24}>
        <Steps current={stepIndex} stepsArray={stepsArray} />
      </Col>

      <Col xs={24}>
        <ScheduleMeetingModalForm onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <>
              {step === STEP.SELECT_CONTACT && (
                <ScheduleMeetingModalSelectContactStep
                  selectedAccount={selectedAccount}
                  onSubmit={handleContactSelect}
                  onClickBack={onClickBack}
                />
              )}

              {step === STEP.FIRST && (
                <ScheduleMeetingModalFirstStep
                  onSubmit={setNextStep}
                  onClickBack={selectContact ? handleFirstStepBack : undefined}
                />
              )}

              {step === STEP.SECOND && (
                <ScheduleMeetingModalSecondStep
                  caseId={caseId}
                  isGroupMeeting={Boolean(isGroupMeeting)}
                  onSubmit={handleSubmit}
                  onToggleMembers={setShouldShowSubtitle}
                  onClickBack={setPrevStep}
                />
              )}
            </>
          )}
        </ScheduleMeetingModalForm>
      </Col>
    </Row>
  )
}
