import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsBusinessInfoEmail } from 'App/components'
import { showModalAction } from 'store'

export type TSettingsBusinessInfoEmailContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State) => ({
  email: state.global.accountData?.email
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ showModal: showModalAction }, dispatch)

export const SettingsBusinessInfoEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsBusinessInfoEmail)
