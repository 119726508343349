import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { TBrandingFormValues, UnityHubBranding } from 'App/components/UnityHub/UnityHubBranding'

import { API } from '../../../../services/api'
import { getCommunity, updateCommunity } from '../../../../api/communities'

export const CommunityBranding = () => {
  const { id } = useParams<{ id: string }>()

  const { t } = useTranslation()

  const { data } = useQuery({
    queryKey: ['get-community', id],
    queryFn: () => getCommunity({ id })
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: updateCommunity,
    onSuccess: () => {
      toast.success(t('common.toast.changesSaved'), toastDefaultOptions)
    }
  })

  const handleSubmit = (payload: FormData) => {
    mutate({ id, payload })
  }

  const initialValues: TBrandingFormValues = {
    cover: API.GET_COMMUNITY_COVER_URL(id),
    avatar: API.GET_COMMUNITY_AVATAR_URL(id)
  }

  return (
    <UnityHubBranding
      displayUserName={data?.title}
      initialValues={initialValues}
      loading={isLoading}
      onUpload={handleSubmit}
    />
  )
}
