import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { GET_FILE_CONTACT_LIST } from 'store/files/myFiles'
import { createLoadingSelector } from 'store/loading'
import { FileContactList } from 'App/components'

export type TFileContactListContainerProps = ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_FILE_CONTACT_LIST])

const mapStateToProps = (state: State) => ({
  search: state.file.fileContacts.search,
  fileContactIds: state.file.fileContacts.ids,
  loading: loadingSelector(state)
})

export const FileContactListContainer = connect(mapStateToProps)(FileContactList)
