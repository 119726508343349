import { useTranslation } from 'react-i18next'

import { TNavMenuItem } from 'App/components'
import { useAppSelector } from 'store'

const DEFAULT_PATH = '/cases'

type TUseCasesRoutesResponse = {
  defaultPath: string
  navMenu: TNavMenuItem[]
}

export const useCasesRoutes = (): TUseCasesRoutesResponse => {
  const casesNotifications = useAppSelector(
    (state) => state.notifications.general.cases.notifications
  )
  const casesInvitationsNotifications = useAppSelector(
    (state) => state.notifications.general.cases.invitations
  )

  const { t } = useTranslation()

  const NAV_MENU: TNavMenuItem[] = [
    {
      link: DEFAULT_PATH,
      title: t('cases.tabs.cases'),
      exact: true,
      notification: {
        count: casesNotifications,
        variant: 'dot'
      }
    },
    {
      link: '/cases/invitations',
      title: t('cases.tabs.invitations'),
      notification: {
        count: casesInvitationsNotifications,
        variant: 'dot'
      }
    }
  ]

  return {
    navMenu: NAV_MENU,
    defaultPath: DEFAULT_PATH
  }
}
