import { action } from './store.utils'
import {
  TGetWhoAmiSuccessPayload,
  TError,
  TSetConnectWebSocketStatusActionPayload
} from './store.types'

// ------------------ GET_WHO_AMI ------------------
export const GET_WHO_AMI = 'GET_WHO_AMI'
export const GET_WHO_AMI_REQUEST = 'GET_WHO_AMI_REQUEST'
export const GET_WHO_AMI_SUCCESS = 'GET_WHO_AMI_SUCCESS'
export const GET_WHO_AMI_ERROR = 'GET_WHO_AMI_ERROR'

export const getWhoAmiRequest = () => action(GET_WHO_AMI_REQUEST)
export const getWhoAmiSuccess = (payload: TGetWhoAmiSuccessPayload) =>
  action(GET_WHO_AMI_SUCCESS, payload)
export const getWhoAmiError = (error: TError) => action(GET_WHO_AMI_ERROR, error)

// ------------------ GET_INITIALIZE_APP ------------------
export const GET_INITIALIZE_APP = 'GET_INITIALIZE_APP'
export const GET_INITIALIZE_APP_REQUEST = 'GET_INITIALIZE_APP_REQUEST'
export const GET_INITIALIZE_APP_SUCCESS = 'GET_INITIALIZE_APP_SUCCESS'
export const GET_INITIALIZE_APP_ERROR = 'GET_INITIALIZE_APP_ERROR'

export const initializeApp = () => action(GET_INITIALIZE_APP_REQUEST)
export const initializeAppSuccess = () => action(GET_INITIALIZE_APP_SUCCESS)
export const initializeAppError = () => action(GET_INITIALIZE_APP_ERROR)

// ------------------ WEB_SOCKET_STATUS ------------------

export const SET_CONNECT_WS_STATUS = 'SET_CONNECT_WS_STATUS'
export const CONNECT_WS_STATUS = 'CONNECT_WS_STATUS'
export const DISCONNECT_WS_STATUS = 'DISCONNECT_WS_STATUS'

export const connectWebSocketAction = () => action(CONNECT_WS_STATUS)
export const disconnectWebSocketAction = () => action(DISCONNECT_WS_STATUS)
export const setConnectWebSocketStatusAction = (payload: TSetConnectWebSocketStatusActionPayload) =>
  action(SET_CONNECT_WS_STATUS, payload)

// ------------------ FOCUS_IN_BROWSER ------------------
export const GET_FOCUS_IN_BROWSER = 'GET_FOCUS_IN_BROWSER'
export const LOST_FOCUS_IN_BROWSER = 'LOST_FOCUS_IN_BROWSER'

export const getFocusInBrowserAction = () => action(GET_FOCUS_IN_BROWSER)
export const lostFocusInBrowserAction = () => action(LOST_FOCUS_IN_BROWSER)
