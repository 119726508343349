import { Skeleton as OriginalSkeleton } from 'antd'

import { generateRandomArray } from 'utils'

import styles from './Skeleton.module.scss'

export const Skeleton = () => (
  <>
    <OriginalSkeleton
      active
      loading
      title={{ className: styles.caption, width: 56 }}
      paragraph={false}
    />
    {generateRandomArray().map((_, index) => (
      <OriginalSkeleton
        key={index}
        avatar={{ size: 30 }}
        active
        loading
        title={{ className: styles.title, width: 120 }}
        paragraph={{ rows: 1, className: styles.paragraph, width: 56 }}
        className={styles.row}
      />
    ))}
  </>
)
