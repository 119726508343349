import { lazy, Suspense } from 'react'

import { Spinner } from '../../Spinner'

import { TModelViewerProps } from './ModelViewer'

const ModelViewerInternal = lazy(() => import('./ModelViewer'))

export const ModelViewer = (props: TModelViewerProps) => (
  <Suspense fallback={<Spinner />}>
    <ModelViewerInternal {...props} />
  </Suspense>
)
