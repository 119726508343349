import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  UnityHubHeaderLayout,
  TUnityHubHeaderLayoutAction,
  TUnityHubHeaderLayoutProps
} from 'App/components/UnityHub'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import {
  accountIdSelector,
  isAdminAccountSelector,
  isBusinessAccountSelector,
  LOST_ACCESS_TO_COMMUNITY,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { skipWSAction } from 'services/skipWSActions'
import { useCommunityInviteLink } from 'App/containers/Communities/CommunityInviteLinkPopover/useCommunityInviteLink'

import { TMenuItemProps } from '../../common'
import { useAdaptiveLayout } from '../../../hooks'
import { EModalComponents } from '../../../containers'
import { deleteCommunity, removeCommunityMember } from '../../../../api'

type TCommunityHeaderProps = Pick<
  TUnityHubHeaderLayoutProps,
  'name' | 'loading' | 'showAvatar' | 'id'
> & {
  organizationId?: string
}

export const CommunityHeader = ({
  name,
  loading,
  id,
  showAvatar,
  organizationId
}: TCommunityHeaderProps) => {
  const { push } = useHistory()

  const dispatch = useAppDispatch()
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const accountId = useAppSelector(accountIdSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const isAdminAccount = useAppSelector(isAdminAccountSelector)

  const { CreateCommunityInviteLink, toggleInviteLinkPopover } = useCommunityInviteLink({
    communityId: id,
    nestIn: isDesktop ? 'body' : 'parent'
  })

  const { mutateAsync: removeCommunity } = useMutation({
    mutationKey: ['delete-community', id],
    mutationFn: () => {
      skipWSAction({ type: LOST_ACCESS_TO_COMMUNITY, payload: { id } })
      return deleteCommunity(id)
    }
  })
  const { mutateAsync: leaveCommunity } = useMutation({
    mutationKey: ['leave-community', id, accountId],
    mutationFn: () => {
      skipWSAction({ type: LOST_ACCESS_TO_COMMUNITY, payload: { id } })
      return removeCommunityMember({ communityId: id, memberId: accountId })
    }
  })

  const path = isBusinessAccount ? '/community' : '/communities'

  const actions = useMemo<TUnityHubHeaderLayoutAction[]>(
    () => [
      {
        key: 'info',
        text: t('communities.communityInfoButton'),
        icon: <InfoIcon />,
        onClick: () => push(`${path}/${id}/info`)
      },
      {
        key: 'link',
        text: CreateCommunityInviteLink,
        icon: <AccountPlusIcon />,
        hidden: !isBusinessAccount,
        onClick: toggleInviteLinkPopover
      }
    ],
    [CreateCommunityInviteLink, id, isBusinessAccount, path, push, toggleInviteLinkPopover, t]
  )

  const menuItems = useMemo<TMenuItemProps[]>(
    () => [
      {
        icon: <PencilOutlineIcon />,
        content: t('communities.kebabMenu.edit'),
        hidden: accountId !== organizationId,
        onClick: () => {
          push(`/community/${id}/edit/basic`)
        }
      },
      {
        icon: <TrashIcon />,
        content: t('communities.kebabMenu.delete'),
        hidden: !isAdminAccount,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteCommunityConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                content: t('modal.deleteCommunityConfirm.content'),
                confirmLabel: t('modal.deleteCommunityConfirm.submitButton'),
                onConfirm: removeCommunity
              }
            })
          )
      },
      {
        icon: <LogoutIcon />,
        content: t('communities.kebabMenu.leave'),
        hidden: isBusinessAccount,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.leaveCommunityConfirm.title'),
              modalType: EModalComponents.GENERIC_CONFIRMATION,
              modalProps: {
                content: t('modal.leaveCommunityConfirm.content'),
                confirmLabel: t('modal.leaveCommunityConfirm.submitButton'),
                onConfirm: leaveCommunity
              }
            })
          )
      }
    ],
    [
      accountId,
      organizationId,
      t,
      isAdminAccount,
      isBusinessAccount,
      push,
      id,
      dispatch,
      removeCommunity,
      leaveCommunity
    ]
  )

  return (
    <UnityHubHeaderLayout
      type="community"
      id={id}
      name={name}
      showAvatar={showAvatar}
      loading={loading}
      actions={actions}
      menuItems={menuItems}
      showKebab
    />
  )
}
