import { useHistory } from 'react-router-dom'

import { NotificationsMain } from '../NotificationsMain'
import { NotificationsReadAllButton } from '../NotificationsReadAllButton'

import { NotificationsPopoverHeader } from './NotificationsPopoverHeader'
import styles from './NotificationsPopover.module.scss'

export enum NotificationsGroup {
  MAIN = 'MAIN',
  SHOWCASES = 'SHOWCASES'
}

type TNotificationsPopoverProps = {
  onClick?: () => void
}

const tabKey = NotificationsGroup.MAIN

export const NotificationsPopover = ({ onClick }: TNotificationsPopoverProps) => {
  // Commented out due to the need to disable functionality for the task MED-7867

  // const [tabKey, setTabKey] = useState<string>(NotificationsGroup.MAIN)

  const { push } = useHistory()

  // const { t } = useTranslation()

  // const accountTypeName = useAppSelector((state) => state.global.accountData?.type.name)
  // const notificationsTotal = useAppSelector(getMainNotificationsTotalSelector)
  // const showcasesNotificationsTotal = useAppSelector(getShowcasesNotificationsTotalSelector)

  // const shouldShowTabs =
  //   accountTypeName !== AccountTypeNames.BUSINESS && accountTypeName !== AccountTypeNames.PATIENT

  const handleRedirectToNotifications = () => {
    const path = tabKey === NotificationsGroup.MAIN ? '/notifications' : '/notifications/showcases'

    onClick && onClick()
    push(path)
  }

  // const getTab = (type: NotificationsGroup, count: number) => (
  //   <div className={styles.tab}>
  //     {type}
  //     <NotificationBadge count={count} variant="dot" gutter={true} className={styles.tabBadge} />
  //   </div>
  // )

  return (
    <div className={styles.root}>
      <NotificationsPopoverHeader onClick={handleRedirectToNotifications} />
      {/* {shouldShowTabs ? (
        <Tabs
          activeKey={tabKey}
          onChange={setTabKey}
          destroyInactiveTabPane={true}
          className={styles.tabs}
        >
          <TabPane
            tab={getTab(t('tabs.main', { ns: 'notifications' }), notificationsTotal)}
            key={NotificationsGroup.MAIN}
          >
            <NotificationsReadAllButton className={styles.readAllButton} />
            <NotificationsMain />
          </TabPane>
          <TabPane
            tab={getTab(t('tabs.showcases', { ns: 'notifications' }), showcasesNotificationsTotal)}
            key={NotificationsGroup.SHOWCASES}
          >
            <NotificationsReadAllButton className={styles.readAllButton} />
            <NotificationsShowcases onClick={onClick} />
          </TabPane>
        </Tabs>
      ) : ( */}
      <>
        <NotificationsReadAllButton className={styles.readAllButton} />
        <NotificationsMain />
      </>
      {/* )} */}
    </div>
  )
}
