import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal, SubscriptionInfoCard } from 'App/components/common'
import {
  createProcessingBySelector,
  removeContactInviteRequest,
  REMOVE_CONTACT_INVITE,
  useAppDispatch,
  useAppSelector
} from 'store'
import { EInvitationAction } from 'globalConstants'

import styles from './ContactRemoveConfirm.module.scss'

type TCondition = {
  title: string
  list: string[]
}

export type TCommonRemoveConfirmProps = {
  contactId: string
}

type TContactRemoveConfirmProps = TCommonRemoveConfirmProps & {
  text?: string
  conditionList?: TCondition[]
}

const processingSelector = createProcessingBySelector([REMOVE_CONTACT_INVITE])

export const ContactRemoveConfirm = ({
  contactId,
  text,
  conditionList
}: TContactRemoveConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const processing = useAppSelector((state) => processingSelector(contactId)(state))

  const onClick = () => {
    dispatch(
      removeContactInviteRequest({
        contactId,
        processingId: contactId,
        onlyHidden: false,
        type: EInvitationAction.REMOVE
      })
    )
  }

  return (
    <ConfirmationModal
      content={
        text && !conditionList ? (
          <p className={styles.text}>{text}</p>
        ) : (
          <div className={styles.cards}>
            {conditionList?.map(({ title, list }) => (
              <SubscriptionInfoCard
                key={title}
                title={title}
                detailList={list}
                classes={{ root: styles.cardsItem, title: styles.title }}
                isFuture={true}
                active={false}
              />
            ))}
          </div>
        )
      }
      controls={
        <Button
          className={styles.rootButton}
          loading={processing}
          disabled={processing}
          onClick={onClick}
        >
          {t('modal.contactRemoveConfirm.submitButton')}
        </Button>
      }
    />
  )
}
