import { actionCreator, TError } from 'store'

import * as actionTypes from './communities.actionTypes'
import {
  TGetCommunitiesSuccessPayload,
  TGetCommunitiesRequestPayload,
  TSetCommunityIdPayload
} from './communities.types'

export const getCommunitiesRequest = (payload?: TGetCommunitiesRequestPayload) =>
  actionCreator(actionTypes.GET_COMMUNITIES_REQUEST, payload)
export const getCommunitiesSuccess = (payload: TGetCommunitiesSuccessPayload) =>
  actionCreator(actionTypes.GET_COMMUNITIES_SUCCESS, payload)
export const getCommunitiesError = (payload: TError) =>
  actionCreator(actionTypes.GET_COMMUNITIES_ERROR, payload)

export const resetCommunities = () => actionCreator(actionTypes.RESET_COMMUNITIES)

export const setCommunityId = (payload: TSetCommunityIdPayload) =>
  actionCreator(actionTypes.SET_COMMUNITY_ID, payload)

export const receiveLostAccessToCommunity = () =>
  actionCreator(actionTypes.LOST_ACCESS_TO_COMMUNITY)
