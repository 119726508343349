import { FC, useRef, useState, useLayoutEffect, ReactElement, useEffect } from 'react'
import debounce from 'lodash/debounce'
import cls from 'classnames'

import { Tooltip } from 'App/components'

import styles from './TruncatedText.module.scss'

type TTruncatedTextClasses = 'root' | 'text'

export type TTruncatedTextProps = PropsWithClasses<
  TTruncatedTextClasses,
  {
    text: string | ReactElement

    rows?: 'one' | 'two'
    wrapperString?: string | [string, string]
    onClick?: () => void
  }
>

export const TruncatedText: FC<TTruncatedTextProps> = ({
  text,
  wrapperString,
  classes,
  onClick,
  rows = 'one'
}) => {
  const elRef = useRef<HTMLSpanElement>(null)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  useLayoutEffect(() => {
    if (elRef.current) {
      setIsTooltipVisible(
        elRef.current.offsetWidth < elRef.current.scrollWidth ||
          elRef.current.offsetHeight < elRef.current.scrollHeight
      )
    }
  }, [text])

  useEffect(() => {
    const handler = debounce(() => {
      if (elRef.current) {
        setIsTooltipVisible(
          elRef.current.offsetWidth < elRef.current.scrollWidth ||
            elRef.current.offsetHeight < elRef.current.scrollHeight
        )
      }
    }, 300)

    window.addEventListener('resize', handler, false)
    window.addEventListener('orientationchange', handler, false)

    return () => {
      window.removeEventListener('resize', handler)
      window.removeEventListener('orientationchange', handler, false)
    }
  }, [])

  const prefix = typeof wrapperString === 'string' ? wrapperString : wrapperString?.[0]
  const suffix = typeof wrapperString === 'string' ? wrapperString : wrapperString?.[1]

  return (
    <Tooltip title={isTooltipVisible ? text : undefined}>
      <span className={cls(styles.root, classes?.root)} onClick={onClick}>
        {prefix}
        <span
          ref={elRef}
          className={cls(
            styles.text,
            styles[rows],
            isTooltipVisible && styles.textTruncated,
            onClick && styles.clickable,
            classes?.text
          )}
        >
          {text}
        </span>
        {suffix}
      </span>
    </Tooltip>
  )
}
