import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useToggle } from 'App/hooks'
import { Chip } from 'App/components'
import {
  UnityHubNetworkingItem,
  TUnityHubNetworkingItemProps
} from 'App/components/UnityHub/UnityHubNetworkingItem'
import { queryClient } from 'queryClient'
import { TEventAttendee } from 'interfaces'

import { EVENT_ATTENDEES_LIST_QUERY_KEY } from '../EventNetworkingList/EventNetworkingList'
import {
  EventNetworkingFavoriteToggle,
  TEventNetworkingFavoriteToggleProps
} from '../EventNetworkingFavoriteToggle'
import { EventNetworkingDrawer } from '../EventNetworkingDrawer'
import { useEventNetworkingWorkInformation } from '../EventNetworkingWorkInformation'

import styles from './EventNetworkingListItem.module.scss'
import { useEventNetworkingListItemKebabMenu } from './useEventNetworkingListItemKebabMenu'

type TEventNetworkingListItemProps = Pick<
  TEventAttendee,
  'contact' | 'openForNetworking' | 'company' | 'position' | 'id' | 'isFavorite' | 'online'
> &
  Pick<TUnityHubNetworkingItemProps, 'currentAccountId' | 'account' | 'chatId'> &
  Pick<TEventNetworkingFavoriteToggleProps, 'eventId'> & {
    isOrganizer: boolean
    isAdmin: boolean
    onToggleFavorite: (attendeeId: string) => void

    personalProfile?: TEventAttendee
  }

export const EventNetworkingListItem = ({
  id,
  eventId,
  account,
  contact,
  currentAccountId,
  chatId,
  company,
  position,
  openForNetworking,
  isOrganizer,
  isFavorite,
  isAdmin,
  online,
  personalProfile,
  onToggleFavorite
}: TEventNetworkingListItemProps) => {
  const { t } = useTranslation()

  const { professions, id: userId, displayUserName } = account || {}

  const disableInvitation =
    !isOrganizer && (!openForNetworking || (personalProfile && !personalProfile.openForNetworking))
  const isCurrentAccountId = currentAccountId === account.id

  const { getMenuItems } = useEventNetworkingListItemKebabMenu({ eventId, isAdmin })

  const menuItems = getMenuItems({
    chatId,
    userId,
    toId: contact?.toId,
    contactId: contact?.id,
    attendeeId: id,
    status: contact?.status,
    userName: displayUserName,
    disableInvitation
  })

  const { value: isDrawerVisible, toggleOff: hideDrawer, toggleOn: showDrawer } = useToggle()

  const { EventNetworkingWorkInformation } = useEventNetworkingWorkInformation({
    company,
    openForNetworking,
    position,
    professions
  })

  const handleContactInviteSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [EVENT_ATTENDEES_LIST_QUERY_KEY], type: 'active' })
  }, [])

  return (
    <>
      <UnityHubNetworkingItem
        className={styles.root}
        account={account}
        contact={contact}
        currentAccountId={currentAccountId}
        avatarProps={{ eventId, userId: id, isEventProfile: true, online }}
        details={
          <div className={styles.workInformation}>
            <EventNetworkingWorkInformation />
            {openForNetworking && (
              <Chip text={t('events.networking.attendee.openForNetworking')} variant="success" />
            )}
          </div>
        }
        actions={
          !isCurrentAccountId && (
            <EventNetworkingFavoriteToggle
              eventId={eventId}
              attendeeId={id}
              isFavorite={isFavorite}
              onSuccess={onToggleFavorite}
            />
          )
        }
        chatId={chatId}
        menuItems={menuItems}
        disableInvitation={disableInvitation}
        onAccountClick={disableInvitation && !isCurrentAccountId ? undefined : showDrawer}
        onContactInviteSuccess={handleContactInviteSuccess}
      />
      <EventNetworkingDrawer
        attendeeAccountId={account.id}
        attendeeId={id}
        eventId={eventId}
        accountId={currentAccountId}
        userName={displayUserName}
        visible={isDrawerVisible}
        isFavorite={isFavorite}
        onClose={hideDrawer}
        onToggleFavorite={onToggleFavorite}
      />
    </>
  )
}
