import { FC, ReactNode } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import {
  resetSelectedChatAction,
  setChatFilters,
  selectOnline,
  selectLastSeen,
  isChatRoomMutedSelector,
  getSelectedChatRoomSelector,
  createLoadingSelector,
  GET_MESSAGES_HISTORY,
  GET_CHAT_ROOM
} from 'store'
import {
  ChatRoomHeaderCaseOwner,
  ChatRoomHeaderCaseMember,
  ChatRoomHeaderP2P
} from 'App/components'

export type TChatRoomHeaderContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export type TChatRoomHeaderP2PContainerProps = {
  title?: ReactNode
}

const loadingMessagesSelector = createLoadingSelector([GET_MESSAGES_HISTORY])
const loadingRoomSelector = createLoadingSelector([GET_CHAT_ROOM])

const mapStateToProps = (state: State) => {
  const selectedChat = getSelectedChatRoomSelector(state.chat.chatRooms.activeChatId)(state)
  const online = selectOnline(state)
  const lastSeen = selectLastSeen(state)
  const caseId = state.caseView.data?.id
  const caseType = state.caseView.data?.type
  const accountId = state.global.accountData?.id
  const filters = state.chat.chatRooms.filters
  const isChatRoomMuted = isChatRoomMutedSelector(selectedChat?.id)(state)
  const loading =
    loadingRoomSelector(state) ||
    (loadingMessagesSelector(state) && !state.chat.chatMessages.messages.ids.length)

  return {
    selectedChat,
    online,
    lastSeen,
    caseId,
    caseType,
    accountId,
    filters,
    isChatRoomMuted,
    loading
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetSelectedChat: resetSelectedChatAction,
      setChatFilters
    },
    dispatch
  )

export const ChatRoomHeaderCaseOwnerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRoomHeaderCaseOwner)

export const ChatRoomHeaderCaseMemberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRoomHeaderCaseMember)

export const ChatRoomHeaderP2PContainer: FC<TChatRoomHeaderP2PContainerProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatRoomHeaderP2P)
