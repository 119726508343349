import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { ChatTypeEnum } from '@medentee/enums'
import cls from 'classnames'

import { EChatViewType, EIconSize } from 'enums'
import { getNotificationsCountByChatTypeSelector, useAppSelector } from 'store'
import { IconButton, NotificationBadge } from 'App/components/common'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { useAdaptiveLayout, useEvent } from 'App/hooks'
import { useChatContext } from 'App/context'

import {
  CASE_MEMBER_FOOTER_WRAPPER_ID,
  CASE_OWNER_FOOTER_WRAPPER_ID,
  P2P_CHAT_FOOTER_WRAPPER_ID
} from '../../ChatRoom'

import styles from './ChatMessageListGoToLast.module.scss'

export type TChatMessageListGoToLastProps = {
  onClick: (unreadCount: number) => void

  chatType?: ChatTypeEnum
}

const BUTTON_MARGIN_SIZES = 28
const BUTTON_MARGIN_SIZES_ADAPTIVE = 16

export const ChatMessageListGoToLast = ({ chatType, onClick }: TChatMessageListGoToLastProps) => {
  const unreadCount = useAppSelector(getNotificationsCountByChatTypeSelector(chatType))
  const [position, setPosition] = useState<Pick<DOMRect, 'left' | 'top'> | null>(null)
  const { isDesktop } = useAdaptiveLayout()
  const { chatViewType } = useChatContext()

  const isCaseView = chatViewType === EChatViewType.CASE

  const buttonSize = Number(styles.buttonSize)

  const [footerSize, setFooterSize] = useState<DOMRect | null>(null)
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  useLayoutEffect(() => {
    const targetElement =
      document.getElementById(P2P_CHAT_FOOTER_WRAPPER_ID) ||
      document.getElementById(CASE_OWNER_FOOTER_WRAPPER_ID) ||
      document.getElementById(CASE_MEMBER_FOOTER_WRAPPER_ID)

    const handleResize = () => {
      const rect = targetElement?.getBoundingClientRect()

      if (rect) {
        setFooterSize(rect)
      }
    }

    if (targetElement) {
      resizeObserverRef.current = new ResizeObserver(handleResize)
      resizeObserverRef.current.observe(targetElement)
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect()
      }
    }
  }, [])

  const adaptiveSizes = useMemo(() => {
    if (isDesktop) {
      return {
        right: buttonSize + BUTTON_MARGIN_SIZES,
        top: buttonSize + BUTTON_MARGIN_SIZES
      }
    }

    return {
      right: buttonSize + BUTTON_MARGIN_SIZES_ADAPTIVE,
      top: buttonSize
    }
  }, [buttonSize, isDesktop])

  useEffect(() => {
    if (footerSize) {
      setPosition({
        left: footerSize.right - adaptiveSizes.right,
        top: footerSize.top - adaptiveSizes.top
      })
    }
  }, [footerSize, adaptiveSizes])

  const handleClick = useEvent(() => onClick(unreadCount))

  if (!position) {
    return null
  }

  return (
    <div
      className={cls(styles.root, { [styles.caseView]: isCaseView })}
      style={{
        left: position.left,
        top: isCaseView ? undefined : position.top
      }}
    >
      <NotificationBadge className={styles.badge} count={unreadCount} border />
      <IconButton
        classes={{ root: styles.button }}
        iconComponent={<ChevronDownIcon />}
        iconSize={EIconSize.CUSTOM}
        variant="filled"
        onClick={handleClick}
      />
    </div>
  )
}
