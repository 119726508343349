import { State } from 'redux/rootReducer'
import { ICaseMembersAccount } from 'interfaces'

export const getMemberListSelector = (state: State, members: string[]) => {
  let memberList: ICaseMembersAccount[] = []

  if (members && members.length) {
    const memberListValues = Object.values(state.cases.memberList)

    memberList = members.reduce<ICaseMembersAccount[]>((acc, memberId) => {
      const item = memberListValues.find((member) => member.id === memberId)

      if (item) {
        acc.push({ ...item.account, status: item.status })
      }

      return acc
    }, [])
  }

  return memberList
}
