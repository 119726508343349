import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FileSharedListContainer, TFileSharedListWrapperContainerProps } from 'App/containers'

import { EmptyFileList } from '../common'

import styles from './FileSharedListWrapper.module.scss'

export type TFileSharedListWrapperProps = TFileSharedListWrapperContainerProps

// TODO: REFACTOR make a component unifying FileSharedListWrapper && FileListWrapper, cause they have the same style properties

const FileSharedListWrapperView = ({
  pagination,
  sorting,
  fileIds,
  loading,
  search,
  accountId,
  loadFileSharedList,
  resetFileSharedList,
  extensionCategories
}: TFileSharedListWrapperProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    loadFileSharedList({
      filters: {
        search,
        extensionCategories
      },
      permissionUserIdScope: accountId
    })
  }, [search, extensionCategories, accountId, loadFileSharedList])

  useEffect(
    () => () => {
      resetFileSharedList()
    },
    [resetFileSharedList]
  )

  return (
    <div className={styles.root}>
      {loading || fileIds.length ? (
        <FileSharedListContainer pagination={pagination} sorting={sorting} loading={loading} />
      ) : (
        <EmptyFileList
          extensionCategories={extensionCategories}
          search={search}
          defaultText={t('files.placeholder_shared')}
        />
      )}
    </div>
  )
}

export const FileSharedListWrapper = memo(FileSharedListWrapperView)
