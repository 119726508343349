import { useCallback } from 'react'
import { SubscriptionIdsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { EModalComponents } from 'App/containers/ModalRoot'
import {
  isBusinessAccountSelector,
  getOrganizationSubscriptionPlanSelector,
  useAppSelector,
  useAppDispatch,
  showModalAction
} from 'store'
import { stopPropagation } from 'utils'

type TVariant = 'upgrade' | 'adminChat'

type TUseOrganizationSubscriptionProps<P> = {
  organizationId?: string
  variant?: TVariant
  callback?: (props?: P) => void
}

export const useOrganizationSubscription = <P>({
  organizationId,
  variant,
  callback
}: TUseOrganizationSubscriptionProps<P>) => {
  const dispatch = useAppDispatch()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const subscriptionPlanId = useAppSelector(getOrganizationSubscriptionPlanSelector(organizationId))
  const currentSubscription = useAppSelector((state) => state.subscriptions.current)

  const { t } = useTranslation()

  const isAvailable =
    subscriptionPlanId === SubscriptionIdsEnum.BUSINESS || currentSubscription?.graceOn

  const getOnClickHandler = useCallback(
    (callbackProps?: P) => (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isAvailable) {
        callback && callback(callbackProps)

        return
      }

      event && stopPropagation(event)
      event?.nativeEvent?.preventDefault()
      event?.preventDefault()

      if ((variant === 'adminChat' || !variant) && !isBusinessAccount) {
        organizationId &&
          dispatch(
            showModalAction({
              modalType: EModalComponents.REDIRECT_TO_ORGANIZATION_ADMIN_CHAT,
              modalTitle: t('modal.unavailableOrganizationFeature.title'),
              modalProps: {
                organizationId
              }
            })
          )

        return
      }

      if (variant === 'upgrade' || !variant) {
        dispatch(
          showModalAction({
            modalType: EModalComponents.REDIRECT_TO_SUBSCRIPTION_PAGE,
            modalTitle: t('modal.unavailableFeature.title'),
            modalProps: { isBusinessAccount }
          })
        )
      }
    },
    [isAvailable, variant, isBusinessAccount, callback, organizationId, dispatch, t]
  )

  return {
    isAvailable,
    getOnClickHandler
  }
}
