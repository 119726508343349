import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CaseClassificationNameEnum,
  CaseStatusesEnum,
  CaseTypesEnum,
  ProducedNotificationsEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { ECaseAccountType, ECasesDocumentKey } from 'enums'
import { getMapComponent } from 'utils'
import { clearNotificationAction } from 'store'
import {
  CaseDescriptionContainer,
  CaseDocumentBadgeContainer,
  CaseMemberOpinionsContainer,
  CaseOpinionsMembersContainer,
  CaseOpinionsPublicSwitchContainer,
  CaseSummaryContainer
} from 'App/containers'
import { DropDownMenu, TabPane, Tabs } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './CaseDetailsMenu.module.scss'

export type TCaseDetailsMenuProps = {
  id: string
  who: ECaseAccountType
  isOwner: boolean
  caseType: CaseTypesEnum | null
  caseStatus: CaseStatusesEnum | null
  classification: CaseClassificationNameEnum
  clearNotification: typeof clearNotificationAction
}

const CASE_OPINIONS_CONTAINER = new Map<
  ECaseAccountType,
  (props: { caseId: string; caseStatus: CaseStatusesEnum | null }) => JSX.Element
>()
  .set(ECaseAccountType.OWNER, ({ caseId, caseStatus }) => {
    const isCaseArchived = caseStatus === CaseStatusesEnum.ARCHIVED

    return (
      <>
        {!isCaseArchived && <CaseOpinionsPublicSwitchContainer />}
        <CaseOpinionsMembersContainer caseId={caseId} />
      </>
    )
  })
  .set(ECaseAccountType.MEMBER, ({ caseId }) => <CaseMemberOpinionsContainer caseId={caseId} />)

type TDescriptionTabProps = {
  t: TFunction
}

const DESCRIPTION_TAB = new Map<ECaseAccountType, (props: TDescriptionTabProps) => JSX.Element>()
  .set(ECaseAccountType.OWNER, ({ t }) => <>{t('cases.details.menu.description')}</>)
  .set(ECaseAccountType.MEMBER, ({ t }) => <>{t('cases.details.menu.description')}</>)

type TDescriptionTitleProps = {
  who: ECaseAccountType
  classification: CaseClassificationNameEnum
  onClick: () => void
}

const DescriptionTitle = ({ classification, onClick, who }: TDescriptionTitleProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (who === ECaseAccountType.OWNER) {
      onClick()
    }
  }

  return classification !== CaseClassificationNameEnum.PRIVATE ? (
    <div onClick={handleClick} className={styles.tab}>
      {getMapComponent(DESCRIPTION_TAB, who, { t })}
      <CaseDocumentBadgeContainer itemKey={ECasesDocumentKey.DESCRIPTION} gutter={true} />
    </div>
  ) : null
}

type TOpinionTitleProps = {
  who: ECaseAccountType
  caseType: CaseTypesEnum | null
  classification: CaseClassificationNameEnum
  onClick: () => void
}

const OpinionTitle = ({ caseType, classification, onClick, who }: TOpinionTitleProps) => {
  const { t } = useTranslation()

  const isMember = who === ECaseAccountType.MEMBER

  const handleClick = () => {
    if (isMember) {
      onClick()
    }
  }

  return caseType && classification === CaseClassificationNameEnum.PROFESSIONAL ? (
    <div onClick={handleClick} className={styles.tab}>
      {isMember && caseType === CaseTypesEnum.A2O
        ? t('cases.details.menu.opinions_member')
        : t('cases.details.menu.opinions')}

      <CaseDocumentBadgeContainer itemKey={ECasesDocumentKey.OPINION} gutter={true} />
    </div>
  ) : null
}

type TNotesTitleProps = {
  who: ECaseAccountType
  onClick: () => void
}

const NotesTitle = ({ onClick, who }: TNotesTitleProps) => {
  const { t } = useTranslation()

  const isOwner = who === ECaseAccountType.OWNER

  const handleClick = () => {
    if (isOwner) {
      onClick()
    }
  }

  return isOwner ? (
    <div onClick={handleClick} className={styles.tab}>
      {t('cases.details.menu.notes')}
    </div>
  ) : null
}

export enum TabTitleIdEnum {
  DESCRIPTION = '0',
  OPINIONS = '1',
  NOTES = '2'
}

export const CaseDetailsMenu = ({
  id,
  who,
  isOwner,
  caseType,
  caseStatus,
  classification,
  clearNotification
}: TCaseDetailsMenuProps) => {
  const [tabTitle, setTabTitle] = useState('0')

  const isPrivateCase = classification === CaseClassificationNameEnum.PRIVATE

  useEffect(() => {
    setTabTitle(
      classification === CaseClassificationNameEnum.PRIVATE
        ? TabTitleIdEnum.NOTES
        : TabTitleIdEnum.DESCRIPTION
    )
  }, [classification])

  const { isMobile } = useAdaptiveLayout()

  const handleOpinionTabClick = useCallback(() => {
    clearNotification({
      producedNotificationType: ProducedNotificationsEnum.CASE_DESCRIPTION_UPDATED
    })
  }, [clearNotification])

  const handleDescriptionTabClick = useCallback(() => {
    clearNotification({
      producedNotificationType: ProducedNotificationsEnum.CASE_OPINION_CREATED
    })
  }, [clearNotification])

  const handleSummaryTabClick = useCallback(() => {
    clearNotification({
      producedNotificationType: ProducedNotificationsEnum.CASE_OPINION_CREATED
    })
  }, [clearNotification])

  const tabsList = useMemo(
    () => ({
      DESCRIPTION: (
        <DescriptionTitle
          who={who}
          classification={classification}
          onClick={handleDescriptionTabClick}
        />
      ),
      OPINIONS: (
        <OpinionTitle
          who={who}
          caseType={caseType}
          classification={classification}
          onClick={handleOpinionTabClick}
        />
      ),
      NOTES: <NotesTitle who={who} onClick={handleSummaryTabClick} />
    }),
    [
      who,
      classification,
      caseType,
      handleDescriptionTabClick,
      handleOpinionTabClick,
      handleSummaryTabClick
    ]
  )

  return (
    <>
      {isMobile && (
        <DropDownMenu list={Object.values(tabsList)} title={tabTitle} onChange={setTabTitle} />
      )}
      <Tabs
        activeKey={tabTitle}
        className={styles.root}
        destroyInactiveTabPane={true}
        onChange={setTabTitle}
      >
        {!isPrivateCase && (
          <>
            <TabPane tab={tabsList.DESCRIPTION} key={TabTitleIdEnum.DESCRIPTION}>
              <CaseDescriptionContainer caseId={id} isOwner={isOwner} who={who} />
            </TabPane>
            {caseType && (
              <TabPane tab={tabsList.OPINIONS} key={TabTitleIdEnum.OPINIONS}>
                {getMapComponent(CASE_OPINIONS_CONTAINER, who, { caseId: id, caseStatus })}
              </TabPane>
            )}
          </>
        )}
        {isOwner && (
          <TabPane tab={tabsList.NOTES} key={TabTitleIdEnum.NOTES}>
            <CaseSummaryContainer caseId={id} />
          </TabPane>
        )}
      </Tabs>
    </>
  )
}
