import { ReactNode } from 'react'
import cls from 'classnames'

import styles from './Title.module.scss'

export type TTitleProps = PropsWithClassName<{
  title: ReactNode
  align?: 'center'
}>

export const Title = ({ title, align, className }: TTitleProps) => (
  <span className={cls(styles.root, className, { [styles.center]: align === 'center' })}>
    {title}
  </span>
)
