import { Redirect, generatePath } from 'react-router-dom'
import { useEffect } from 'react'

import { getMapComponent } from 'utils'
import { CHAT_PATH, EChatTypeMap } from 'globalConstants'
import {
  GET_CHAT_ROOM,
  createLoadingSelector,
  getChatRoomRequest,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Spinner } from 'App/components/Spinner'

import { ChatRoomWrapper } from '../../ChatRoom'

import styles from './ChatCreateGroup.module.scss'
import { ChatCreateGroupHeader } from './ChatCreateGroupHeader'
import { ESteps } from './ChatCreateGroup.utils'
import { ChatCreateGroupSelectMembers } from './ChatCreateGroupSelectMembers'
import { ChatCreateGroupPrepareToCreate } from './ChatCreateGroupPrepareToCreate'
import { useChatCreateGroupContext } from './ChatCreateGroupContext'
import { GroupChatEdit } from './GroupChatEdit'
import { useChatCreateGroupRequest } from './useChatCreateGroupRequest'

const MAIN_MAP = new Map<ESteps, () => JSX.Element>()
  .set(ESteps.SELECT_MEMBERS, () => <ChatCreateGroupSelectMembers />)
  .set(ESteps.CREATE, () => <ChatCreateGroupPrepareToCreate />)
  .set(ESteps.EDIT, () => <GroupChatEdit />)

const loadingSelector = createLoadingSelector([GET_CHAT_ROOM])

export const ChatCreateGroup = () => {
  const dispatch = useAppDispatch()
  const { step, accountId, channelManagerId, activeChatId, selectedChatId } =
    useChatCreateGroupContext()
  const { handleCreate, handleUpdate } = useChatCreateGroupRequest()
  const loading = useAppSelector(loadingSelector)

  const isEdit = step === ESteps.EDIT

  useEffect(() => {
    if (isEdit && activeChatId && !selectedChatId) {
      dispatch(getChatRoomRequest({ chatId: activeChatId }))
    }
  }, [activeChatId, dispatch, isEdit, selectedChatId])

  if (isEdit && (!accountId || !channelManagerId || loading)) {
    return <Spinner contentCenter />
  }

  if (isEdit && accountId !== channelManagerId) {
    return <Redirect to={generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE })} />
  }

  return (
    <ChatRoomWrapper
      dndHide={true}
      header={<ChatCreateGroupHeader onSubmit={isEdit ? handleUpdate : handleCreate} />}
      main={<div className={styles.mainWrapper}>{getMapComponent(MAIN_MAP, step)}</div>}
      footer={null}
      classes={{
        root: styles.root,
        headerWrapper: styles.headerWrapper,
        main: styles.main
      }}
    />
  )
}
