import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { AccountsSelect } from 'App/components/common'
import { getOrganizationMembers } from 'api/organizations'
import { useSelectAccounts, TUseSelectAccountsProps } from 'App/hooks'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { MAX_DEPARTMENT_LEAD_ALLOWED } from 'globalConstants'

export type TManageDepartmentLeadModalProps = Pick<TUseSelectAccountsProps, 'onSubmit'> & {
  organizationId: string

  account?: TAccountsSelectOptionData
}

export const ManageDepartmentLeadModal = ({
  organizationId,
  onSubmit,
  account
}: TManageDepartmentLeadModalProps) => {
  const {
    state: { page, search, selectedAccounts },
    handleSubmit,
    onPageChange,
    onSearch,
    onSelect,
    onSelectAll,
    onUnselect,
    onUnselectAll
  } = useSelectAccounts({ onSubmit, accounts: account ? [account] : [] })

  const { t } = useTranslation()

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ['organization-staff', page, search, selectedAccounts],
    queryFn: () => getOrganizationMembers({ organizationId, page, search }),
    keepPreviousData: true,
    select: (response) => ({
      ...response,
      data: {
        ...response,
        results: response.results.map((item) => item.account)
      }
    })
  })

  const submitDisabled = isFetching || selectedAccounts.length > MAX_DEPARTMENT_LEAD_ALLOWED

  return (
    <AccountsSelect
      items={data?.data.results ?? []}
      selectedItems={selectedAccounts}
      search={search}
      page={page}
      total={data?.data?.total}
      hideClearAll={true}
      autoOnline={true}
      maxSelected={MAX_DEPARTMENT_LEAD_ALLOWED}
      loading={isLoading}
      submitDisabled={submitDisabled}
      emptyListText={t('modal.manageDepartmentLead.placeholder')}
      emptyContentText={t('modal.manageDepartmentLead.selectedListPlaceholder')}
      submitLabel={t('modal.manageDepartmentLead.submitButton')}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={onUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
    />
  )
}
