import { FocusEvent, useRef, useState, KeyboardEvent } from 'react'

import { removeAllEmptyCharacters } from 'utils'

import { BadgeItem } from '../../BadgeItem'
import { TChipVariant } from '../../Chip'
import { SUBMIT_KEYS } from '../chipInput.constants'

export type TChipInputItemValue = {
  text: string

  removable?: boolean
  editable?: boolean
}

export type TChipInputItemProps = {
  index: number
  value: TChipInputItemValue
  onRemove: (index: number) => void
  onEdit: (index: number, newValue: string) => void

  error?: string
  submitKeys?: Set<string>
}

export const ChipInputItem = ({
  value,
  index,
  error,
  onRemove,
  onEdit,
  submitKeys = SUBMIT_KEYS
}: TChipInputItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const { text, editable = true, removable = true } = value

  const handleFocus = (e: FocusEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }
  const handleRemove = (id: string) => onRemove(Number(id))
  const handleClick = () => setIsEditing(true)
  const handleEdit = () => {
    setIsEditing(false)
    onEdit(index, ref.current?.innerText ?? '')
  }
  const handleKeyPress = (e: KeyboardEvent) => {
    if (submitKeys.has(e.key)) {
      handleEdit()
      e.preventDefault()
    }
  }
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      const newValue = removeAllEmptyCharacters(ref.current?.innerText ?? '')

      if (!newValue) {
        onRemove(index)
      }
    }
  }
  const variant: TChipVariant = error ? 'error' : 'light'

  return (
    <BadgeItem
      ref={ref}
      title={text}
      truncate={false}
      id={String(index)}
      editable={isEditing}
      onFocus={handleFocus}
      variant={isEditing ? 'outlined' : variant}
      onBlur={editable ? handleEdit : undefined}
      onClick={editable ? handleClick : undefined}
      onKeyPress={editable ? handleKeyPress : undefined}
      onKeyUp={removable ? handleKeyUp : undefined}
      onRemove={removable ? handleRemove : undefined}
    />
  )
}
