import { call, put, takeLatest, select, debounce, cancel, take, race } from 'redux-saga/effects'

import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { API, api, APIData } from 'services/api'
import { QueryBuilder } from 'utils/QueryBuilder'
import { getDescriptionLineId, getOpinionLineId } from 'store'
import { ECasesDocumentKey } from 'enums'
import { State } from 'redux/rootReducer'
import { handleError } from 'api/utils'

import {
  ICaseDescriptionDTO,
  ICaseSummaryDTO,
  ICaseOpinionsDTO,
  ICaseOpinionsMembersDTO,
  TCaseDraftDTO
} from '../../interfaces/api/caseDetailsDTO'

import {
  getCaseDescriptionError,
  getCaseDescriptionSuccess,
  addCaseDescriptionError,
  addCaseDescriptionSuccess,
  getCaseSummaryError,
  getCaseSummarySuccess,
  addCaseSummaryError,
  addCaseSummarySuccess,
  updateCaseSummaryError,
  updateCaseSummarySuccess,
  getCaseOpinionsMembersSuccess,
  getCaseOpinionsMembersError,
  getCaseOpinionsByMemberSuccess,
  getCaseOpinionsByMemberError,
  getCaseOpinionsError,
  getCaseOpinionsSuccess,
  addCaseOpinionError,
  addCaseOpinionSuccess,
  setNewLineDescriptionAction,
  setNewLineOpinionAction,
  getCaseOpinionsMembersRequest,
  updateCaseDraftRequest,
  updateCaseDraftSuccess,
  updateCaseDraftError,
  getCaseDraftRequest,
  getCaseDraftError,
  getCaseDraftSuccess,
  getCaseDraftCancel
} from './caseDetails.actions'
import {
  TGetCaseDescriptionRequest,
  TAddCaseDescriptionRequest,
  TGetCaseSummaryRequest,
  TAddCaseSummaryRequest,
  TUpdateCaseSummaryRequest,
  TGetCaseOpinionsMembersRequest,
  TGetCaseOpinionsByMemberRequest,
  TGetCaseOpinionsRequest,
  TAddCaseOpinionRequest,
  ECaseDraftMap
} from './caseDetails.types'
import {
  caseDescriptionNormalize,
  caseOpinionsNormalize,
  caseOpinionsMembersNormalize
} from './caseDetails.normalization'
import * as actionTypes from './caseDetails.actionTypes'

function* getCaseDescriptionSaga({ payload: { caseId } }: TGetCaseDescriptionRequest) {
  try {
    const { data }: APIData<ICaseDescriptionDTO> = yield call(api.get, API.CASE_DESCRIPTION(caseId))
    const normalizedData = caseDescriptionNormalize(data)
    const newLineDescriptionId: string | null = yield select(
      getDescriptionLineId,
      normalizedData.ids
    )

    yield put(setNewLineDescriptionAction({ newLineDescriptionId }))
    yield put(getCaseDescriptionSuccess(normalizedData))
  } catch (e) {
    yield put(getCaseDescriptionError(e))
    yield handleError(e)
  }
}

function* addCaseDescriptionSaga({ payload: { caseId, description } }: TAddCaseDescriptionRequest) {
  try {
    const { data }: APIData<ICaseDescriptionDTO> = yield call(
      api.post,
      API.CASE_DESCRIPTION(caseId),
      { data: description }
    )
    const normalizedData = caseDescriptionNormalize(data)

    yield put(updateCaseDraftRequest({ caseId, type: ECasesDocumentKey.DESCRIPTION, message: '' }))

    yield race([
      take(actionTypes.UPDATE_CASE_DRAFT_SUCCESS),
      take(actionTypes.UPDATE_CASE_DRAFT_ERROR)
    ])

    yield put(addCaseDescriptionSuccess(normalizedData))
  } catch (e) {
    yield put(addCaseDescriptionError(e))
    yield handleError(e)
  }
}

function* getCaseSummarySaga({ payload: { caseId } }: TGetCaseSummaryRequest) {
  try {
    const { data }: APIData<ICaseSummaryDTO> = yield call(api.get, API.CASES_SUMMARY(caseId))

    yield put(getCaseSummarySuccess(data))
  } catch (e) {
    yield put(getCaseSummaryError(e))
    yield handleError(e)
  }
}

function* addCaseSummarySaga({ payload: { caseId, summary = '' } }: TAddCaseSummaryRequest) {
  try {
    const { data }: APIData<ICaseSummaryDTO> = yield call(api.post, API.CASES_SUMMARY(caseId), {
      data: summary
    })

    yield put(addCaseSummarySuccess(data))
  } catch (e) {
    yield put(addCaseSummaryError(e))
    yield handleError(e)
  }
}

function* updateCaseSummarySaga({ payload: { caseId, summary = '' } }: TUpdateCaseSummaryRequest) {
  try {
    const { data }: APIData<ICaseSummaryDTO> = yield call(api.patch, API.CASES_SUMMARY(caseId), {
      data: summary
    })

    yield put(updateCaseSummarySuccess(data))
  } catch (e) {
    yield put(updateCaseSummaryError(e))
    yield handleError(e)
  }
}

function* getCaseOpinionsMembersSaga({ payload: { caseId } }: TGetCaseOpinionsMembersRequest) {
  try {
    const { data }: APIData<ICaseOpinionsMembersDTO[]> = yield call(
      api.get,
      API.CASES_OPINIONS_MEMBERS(caseId)
    )

    const normalizedData = caseOpinionsMembersNormalize(data)

    yield put(getCaseOpinionsMembersSuccess(normalizedData))
  } catch (e) {
    yield put(getCaseOpinionsMembersError(e))
    yield handleError(e)
  }
}

function* getCaseOpinionsByMemberSaga({
  payload: { caseId, memberId }
}: TGetCaseOpinionsByMemberRequest) {
  try {
    const queryUrl = new QueryBuilder(API.CASES_OPINIONS_BY_MEMBER(caseId))

    queryUrl.custom('userId', memberId)

    const { data }: APIData<ICaseOpinionsDTO> = yield call(api.get, queryUrl.build())

    const normalizedData = caseOpinionsNormalize(data)

    yield put(getCaseOpinionsByMemberSuccess(normalizedData))
    yield put(setNewLineOpinionAction({ newLineOpinionId: null }))

    const newLineOpinionId: string | null = yield select(
      getOpinionLineId,
      normalizedData.caseOpinionsIds
    )
    yield put(setNewLineOpinionAction({ newLineOpinionId }))
  } catch (e) {
    yield put(getCaseOpinionsByMemberError(e))
    yield handleError(e)
  }
}

function* getCaseOpinionsSaga({ payload: { caseId } }: TGetCaseOpinionsRequest) {
  try {
    const { data }: APIData<ICaseOpinionsDTO> = yield call(api.get, API.CASES_OPINIONS(caseId))

    const normalizedData = caseOpinionsNormalize(data)

    yield put(getCaseOpinionsSuccess(normalizedData))
  } catch (e) {
    yield put(getCaseOpinionsError(e))
    yield handleError(e)
  }
}

function* addCaseOpinionSaga({ payload: { caseId, opinion } }: TAddCaseOpinionRequest) {
  try {
    const { data }: APIData<ICaseOpinionsDTO> = yield call(api.post, API.CASES_OPINIONS(caseId), {
      data: opinion
    })

    const normalizedData = caseOpinionsNormalize(data)

    yield put(updateCaseDraftRequest({ caseId, type: ECasesDocumentKey.OPINION, message: '' }))

    yield race([
      take(actionTypes.UPDATE_CASE_DRAFT_SUCCESS),
      take(actionTypes.UPDATE_CASE_DRAFT_ERROR)
    ])

    yield put(getCaseOpinionsMembersRequest({ caseId }))
    yield put(addCaseOpinionSuccess(normalizedData))
  } catch (e) {
    yield put(addCaseOpinionError(e))
    yield handleError(e)
  }
}

function* updateCaseDraftSaga({
  payload: { caseId, message, type }
}: ReturnType<typeof updateCaseDraftRequest>) {
  try {
    yield call(api.post, API.CASE_DRAFT(caseId, type), {
      message
    })

    yield put(updateCaseDraftSuccess({ [ECaseDraftMap[type]]: message }))
  } catch (e) {
    yield put(updateCaseDraftError(e))
    yield handleError(e)
  }
}

function* getCaseDraftSaga({ payload: { caseId, type } }: ReturnType<typeof getCaseDraftRequest>) {
  try {
    const {
      data: { message }
    }: APIData<TCaseDraftDTO> = yield call(api.get, API.CASE_DRAFT(caseId, type))

    const currentCaseId: string | undefined = yield select(
      (state: State) => state.caseView.data?.id
    )

    if (currentCaseId !== caseId) {
      yield put(getCaseDraftCancel())
      yield cancel()
    }

    yield put(getCaseDraftSuccess({ [ECaseDraftMap[type]]: message }))
  } catch (e) {
    yield put(getCaseDraftError(e))
    yield handleError(e)
  }
}

export function* caseDetailsSaga() {
  yield takeLatest(actionTypes.GET_CASE_DESCRIPTION_REQUEST, getCaseDescriptionSaga)
  yield takeLatest(actionTypes.ADD_CASE_DESCRIPTION_REQUEST, addCaseDescriptionSaga)
  yield takeLatest(actionTypes.GET_CASE_SUMMARY_REQUEST, getCaseSummarySaga)
  yield takeLatest(actionTypes.ADD_CASE_SUMMARY_REQUEST, addCaseSummarySaga)
  yield takeLatest(actionTypes.UPDATE_CASE_SUMMARY_REQUEST, updateCaseSummarySaga)
  yield takeLatest(actionTypes.GET_CASE_OPINIONS_MEMBERS_REQUEST, getCaseOpinionsMembersSaga)
  yield takeLatest(actionTypes.GET_CASE_OPINIONS_BY_MEMBER_REQUEST, getCaseOpinionsByMemberSaga)
  yield takeLatest(actionTypes.GET_CASE_OPINIONS_REQUEST, getCaseOpinionsSaga)
  yield takeLatest(actionTypes.ADD_CASE_OPINION_REQUEST, addCaseOpinionSaga)
  yield debounce(DEFAULT_THROTTLE_MS, actionTypes.UPDATE_CASE_DRAFT_REQUEST, updateCaseDraftSaga)
  yield takeLatest(actionTypes.GET_CASE_DRAFT_REQUEST, getCaseDraftSaga)
}
