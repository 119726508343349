import { generatePath, useHistory, useParams } from 'react-router-dom'
import { CaseTypesEnum } from '@medentee/enums'
import cls from 'classnames'

import { useChatContext } from 'App/context'
import { useAdaptiveLayout } from 'App/hooks'
import { ECaseAccountType, EChatViewType, EIconSize } from 'enums'
import { IconButton } from 'App/components'
import {
  getOrganizationBasePathSelector,
  getCommunityBasePathSelector,
  TCaseParams,
  useAppSelector
} from 'store'
import { CHAT_PATH } from 'globalConstants'
import { TChatPathParams } from 'App/screens'
import { ReactComponent as ReturnIcon } from 'assets/icons/ArrowLeft.svg'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'

import styles from './ChatRoomBackAction.module.scss'

export type TChatRoomBackActionProps = {
  caseType?: CaseTypesEnum | null
  onResetChat?: () => void
}

export const ChatRoomBackAction = ({ caseType, onResetChat }: TChatRoomBackActionProps) => {
  const { who, chatType: pathChatType } = useParams<TCaseParams & TChatPathParams>()
  const {
    push,
    location: { pathname }
  } = useHistory()
  const { isDesktop } = useAdaptiveLayout()
  const { chatViewType } = useChatContext()

  const organizationId = useAppSelector((state) => state.organizations.currentOrganizationId)
  const organizationPath = useAppSelector(getOrganizationBasePathSelector(organizationId))
  const communityId = useAppSelector((state) => state.communities.currentCommunityId)
  const communityPath = useAppSelector(getCommunityBasePathSelector(communityId))
  const eventId = useAppSelector((state) => state.events.currentEventId)

  const { showPinnedMessages, togglePinnedMessages } = useChatContext()

  const isCaseTypeMemberA20 = who === ECaseAccountType.MEMBER && caseType === CaseTypesEnum.A2O
  const showBackButton = !isDesktop && !isCaseTypeMemberA20

  const resetChatList = () => {
    if (organizationId) {
      push(organizationPath)

      return
    }

    if (communityId) {
      push(communityPath)

      return
    }

    if (eventId) {
      push(`/events/${eventId}`)

      return
    }

    if (chatViewType === EChatViewType.CASE) {
      push(pathname)

      return
    }

    onResetChat && onResetChat()
    push(generatePath(CHAT_PATH, { chatType: pathChatType }))
  }

  if (showPinnedMessages) {
    return (
      <IconButton
        iconComponent={<ChevronLeftIcon />}
        iconSize={EIconSize.MD}
        onClick={togglePinnedMessages}
        classes={{ root: cls(styles.root, chatViewType === EChatViewType.CASE && styles.case) }}
      />
    )
  }

  return showBackButton ? (
    <IconButton
      iconComponent={<ReturnIcon />}
      iconSize={EIconSize.MD}
      onClick={resetChatList}
      classes={{ root: styles.root }}
    />
  ) : null
}
