import cls from 'classnames'
import { useRouteMatch } from 'react-router-dom'
import { CaseTypesEnum } from '@medentee/enums'

import { useAdaptiveLayout } from 'App/hooks'
import {
  setFoldCall,
  showModalAction,
  useAppDispatch,
  useAppSelector,
  getOrganizationBasePathSelector
} from 'store'
import { EModalComponents } from 'App/containers/ModalRoot'
import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg'

import { getMeetingsAmount } from './utils'
import { CountersLabel } from './CountersLabel'
import { CallLabel } from './CallLabel'
import styles from './MeetingsWidget.module.scss'

export const MeetingsWidget = () => {
  const dispatch = useAppDispatch()

  const { isMobile } = useAdaptiveLayout()

  const { counter, answer, init, isFolded } = useAppSelector((state) => state.calls)
  const accountId = useAppSelector((state) => state.global.accountData?.id)
  const activeVoiceRoom = useAppSelector((state) => state.organizations.activeVoiceRoom)
  const activeVoiceRoomPath = useAppSelector(
    getOrganizationBasePathSelector(activeVoiceRoom?.organizationId)
  )
  const voiceRoomMatch = useRouteMatch(`${activeVoiceRoomPath}/voice-chat/:id`)

  const text = getMeetingsAmount(counter)

  const { case: createCallCase, startTime, answerers } = answer.createCall || {}
  const {
    id: userId,
    firstName,
    lastName,
    displayUserName,
    type
  } = (answerers?.filter(({ id }) => id !== accountId)[0] || activeVoiceRoom?.organization) ?? {}

  const isAcceptedCall =
    init.isInitiatedCall || answer.isAcceptedCall || (!!activeVoiceRoom && !voiceRoomMatch?.isExact)
  const showCallWidget = isAcceptedCall && isMobile && isFolded

  const active = !!counter.ongoingCalls || showCallWidget

  const handleWidgetClick = () => {
    if (showCallWidget) {
      dispatch(setFoldCall({ isFolded: false }))
      return
    }

    dispatch(
      showModalAction({
        modalTitle: 'Meetings',
        modalType: EModalComponents.MEETINGS
      })
    )
  }

  return (
    <div className={cls(styles.root, active && styles.rootActive)} onClick={handleWidgetClick}>
      <PhoneIcon className={styles.icon} />
      <div className={cls(styles.content, showCallWidget && styles.contentCallWidget)}>
        {showCallWidget ? (
          <CallLabel
            callType={createCallCase?.type ?? CaseTypesEnum.A2O}
            startTime={startTime}
            userId={userId}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={type?.name}
          />
        ) : (
          <CountersLabel text={text} />
        )}
      </div>
    </div>
  )
}
