import { SubscriptionIdsEnum } from '@medentee/enums'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatBytes } from 'utils'

import { TDetailList } from '../common'

type TUseSubscriptionDetails = (
  plan: SubscriptionIdsEnum,
  capacity: string,
  businessAdminLimit: number
) => TDetailList

export const useSubscriptionDetails: TUseSubscriptionDetails = (
  plan,
  capacity,
  businessAdminLimit
) => {
  const { t } = useTranslation()

  return useMemo<TDetailList>(() => {
    const transformationCapacity = formatBytes(capacity)

    switch (plan) {
      case SubscriptionIdsEnum.BASIC_BUSINESS: {
        return {
          organization: [t('subscriptions.shortDetails.organization.useTextChannels')],
          cases: [t('subscriptions.shortDetails.cases.private')],
          cloud: [transformationCapacity],
          communities: ['1'],
          events: [t('subscriptions.shortDetails.events.unlimited')]
        }
      }

      case SubscriptionIdsEnum.BUSINESS: {
        return {
          organization: [
            t('subscriptions.shortDetails.organization.createChannels'),
            t('subscriptions.shortDetails.organization.departments'),
            t('subscriptions.shortDetails.organization.inviteStaff'),
            t('subscriptions.shortDetails.organization.adminLimit', { limit: businessAdminLimit })
          ],
          cases: [
            t('subscriptions.shortDetails.cases.professionalDirect'),
            t('subscriptions.shortDetails.cases.professionalGroup')
          ],
          cloud: [transformationCapacity]
        }
      }

      case SubscriptionIdsEnum.BASIC_PROFESSIONAL: {
        return {
          cases: [t('subscriptions.shortDetails.cases.private')],
          cloud: [transformationCapacity],
          organization: [t('subscriptions.shortDetails.organization.beStaff')],
          communities: [t('subscriptions.shortDetails.communities.beMember')],
          events: [t('subscriptions.shortDetails.events.beAttendee')]
        }
      }

      case SubscriptionIdsEnum.PROFESSIONAL: {
        return {
          cases: [
            t('subscriptions.shortDetails.cases.professionalDirect'),
            t('subscriptions.shortDetails.cases.professionalGroup')
          ],
          cloud: [transformationCapacity]
        }
      }

      default:
        return {}
    }
  }, [plan, capacity, businessAdminLimit, t])
}
