import { useContext } from 'react'

import { Scrollable } from 'App/components/common'

import { PageWrapperContext } from '../../PageWrapper/PageWrapperContext'

export const CommunityMembersFilters = () => {
  const { filtersContent } = useContext(PageWrapperContext)

  return <Scrollable>{filtersContent}</Scrollable>
}
