import { useCallback, useMemo } from 'react'
import { FileHistorySourcesEnum } from '@medentee/enums'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import {
  copyFileToMedcloudRequest,
  createSlides,
  downloadFileRequest,
  EExtendableCardType,
  setFileDetailsId,
  showExtendableCardAction,
  showModalAction,
  TIds,
  useAppDispatch
} from 'store'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'

import { TFileSharedTableProps } from './FileSharedList'

type TUseKebabMenuProps = {
  discardPermissionCallback?: () => void
  setSelectedIds?: (ids: TIds) => void
}

type TFileRecord = Pick<
  TFileSharedTableProps,
  'fileId' | 'fileName' | 'extension' | 'key' | 'isUnopenable' | 'slidesEntity'
>

type TMenuItemsProps = TFileRecord | TFileRecord[]

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = ({
  discardPermissionCallback,
  setSelectedIds
}: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const menuItems = useMemo(
    () => ({
      open: ({ slidesEntity, fileId, isUnopenable }: TFileRecord) => ({
        hidden: isUnopenable,
        content: t('files.kebabMenu.open'),
        icon: <FileIcon />,
        onClick: () =>
          dispatch(
            showExtendableCardAction({
              type: EExtendableCardType.FILE_VIEWER,
              initialSlideId: fileId,
              slides: createSlides(slidesEntity, FileHistorySourcesEnum.MED_CLOUD)
            })
          )
      }),
      'download-to-my-device': ({ fileId }: TFileRecord) => ({
        icon: <DownloadIcon />,
        content: t('files.kebabMenu.downloadToDevice'),
        onClick: () =>
          dispatch(
            downloadFileRequest({
              fileId
            })
          )
      }),
      'download-to-my-medcloud': (props: TMenuItemsProps) => {
        const list = isArray(props) ? props : [props]

        const onSuccess = () => {
          if (setSelectedIds) {
            setSelectedIds([])
          }
        }

        return {
          icon: <DownloadIcon />,
          content: t('files.kebabMenu.copyToMEDcloud'),
          onClick: () =>
            list.forEach(({ fileId, fileName, extension }) =>
              dispatch(
                copyFileToMedcloudRequest({
                  fileId,
                  fileName,
                  fileType: extension,
                  source: FileHistorySourcesEnum.MED_CLOUD,
                  onSuccess
                })
              )
            )
        }
      },
      details: ({ key }: TFileRecord) => ({
        icon: <InfoIcon />,
        content: t('files.kebabMenu.details'),
        onClick: () => dispatch(setFileDetailsId({ recordId: key }))
      }),
      'discard-permission': (props: TMenuItemsProps) => ({
        icon: <TrashIcon />,
        content: t('files.kebabMenu.discardPermission'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalType: EModalComponents.FILE_DISCARD_PERMISSIONS_CONFIRM,
              modalTitle: t('modal.discardFilePermissions.title'),
              modalProps: {
                fileIds: isArray(props) ? props.map(({ fileId }) => fileId) : [props.fileId],
                onSuccess: discardPermissionCallback,
                onError: discardPermissionCallback
              }
            })
          )
      })
    }),
    [discardPermissionCallback, dispatch, setSelectedIds, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => {
      if (isArray(props)) {
        return [menuItems['download-to-my-medcloud'](props), menuItems['discard-permission'](props)]
      }

      return [
        menuItems.open(props),
        menuItems['download-to-my-device'](props),
        menuItems['download-to-my-medcloud'](props),
        menuItems.details(props),
        menuItems['discard-permission'](props)
      ]
    },
    [menuItems]
  )

  return {
    getMenuItems
  }
}
