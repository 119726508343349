import { useTranslation } from 'react-i18next'

import { MAX_LENGTH_50 } from 'globalConstants'
import { TextField, TextFieldSkeleton } from 'App/components/common'
import { useEvent } from 'App/hooks'

import styles from './ChatCreateGroupNameField.module.scss'

export type TChatCreateGroupNameFieldProps = {
  value: string
  onChange: (value: string) => void

  loading?: boolean
}

export const ChatCreateGroupNameField = ({
  value,
  onChange,
  loading = false
}: TChatCreateGroupNameFieldProps) => {
  const { t } = useTranslation()

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useEvent((event) => {
    onChange(event.target.value ?? '')
  })

  return (
    <div className={styles.root}>
      <TextFieldSkeleton loading={loading}>
        <TextField
          classes={{ wrapper: styles.input }}
          value={value}
          onChange={handleChange}
          valueLengthMax={MAX_LENGTH_50}
          topLabel={t('chat.createGroupChat.chatNameFiled')}
        />
      </TextFieldSkeleton>
    </div>
  )
}
