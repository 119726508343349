import { useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import { MutationKey, useMutation, useQuery } from 'services/query'
import eventBus from 'services/eventBus'
import { IShowcaseComment, IShowcaseReply } from 'interfaces'
import { DEFAULT_THROTTLE_MS } from 'globalConstants'
import { useAppSelector } from 'store'
import {
  publishCommentRequest,
  getDraftCommentRequest,
  editCommentRequest,
  getAccountSuggestions
} from 'api/showcase'
import { useEvent } from 'App/hooks'
import { TAccountMention } from 'utils/mentions'
import { formatAccountMentions } from 'utils/chat'

import { TCommentFieldWrapperProps, TOnSearchChangePayload } from './Field/CommentFieldWrapper'

export type TUseFieldRequests = Pick<TCommentFieldWrapperProps, 'event'> & {
  showcaseId: string
  fileId: string
  getCommentsQueryKey: MutationKey
}

const MUTATION_KEY = 'showcase-comment'
const MUTATION_KEY_DRAFT = 'showcase-comment-draft'
const MUTATION_KEY_EDIT = 'showcase-comment-edit'
const QUERY_KEY_DRAFT = 'get-showcase-comment-draft'
const QUERY_KEY_ACCOUNT_SUGGESTIONS = 'showcase-account-suggestions'
const MUTATION_KEY_REPLY = 'showcases-comment-reply'

export const useRequestField = ({
  showcaseId,
  fileId,
  getCommentsQueryKey,
  event
}: TUseFieldRequests) => {
  const accountData = useAppSelector((state) => state.global.accountData)
  const [suggestionSearch, setSuggestionSearch] = useState('')
  const [enableSuggestions, setEnableSuggestions] = useState<boolean>(false)

  const { mutate: publish, isLoading: processing } = useMutation({
    mutationKey: [MUTATION_KEY, fileId, showcaseId],
    mutationFn: publishCommentRequest
  })
  const { mutate: reply, isLoading: replyProcessing } = useMutation({
    mutationKey: [MUTATION_KEY_REPLY, fileId, showcaseId],
    mutationFn: publishCommentRequest
  })
  const { mutate: edit, isLoading: editProcessing } = useMutation({
    mutationKey: [MUTATION_KEY_EDIT, fileId, showcaseId],
    mutationFn: editCommentRequest
  })
  const { mutate: draft } = useMutation({
    mutationKey: [MUTATION_KEY_DRAFT, fileId, showcaseId],
    mutationFn: publishCommentRequest
  })

  const {
    data,
    isLoading: loading,
    isFetching,
    refetch
  } = useQuery({
    queryKey: [QUERY_KEY_DRAFT, showcaseId, fileId],
    queryFn: () => getDraftCommentRequest({ showcaseId, fileId }),
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  const refetchDraft = useCallback(
    async (callback?: () => void) => {
      await refetch()

      if (callback) {
        callback()
      }
    },
    [refetch]
  )

  const onPublishSuccess = useCallback(
    ({ id: newMessageId }: IShowcaseComment) => {
      draft({ message: '', fileId, showcaseId, isDraft: true })

      eventBus.showcaseCommentSuccess.broadcast(newMessageId, {
        extraEventName: String(getCommentsQueryKey)
      })
    },
    [draft, fileId, getCommentsQueryKey, showcaseId]
  )

  const onEditSuccess = useCallback(
    ({ commentId, message, updatedAt, mentions }) => {
      if (event?.type === 'edit') {
        eventBus.showcaseEditCommentSuccess.broadcast(
          {
            commentId,
            message,
            updatedAt,
            mentions
          },
          { extraEventName: String(getCommentsQueryKey) }
        )
        event.close()
      }
    },
    [event, getCommentsQueryKey]
  )

  const onPublishReplySuccess = useCallback(
    ({ relatedComment, id }: IShowcaseReply) => {
      if (event?.type === 'reply') {
        draft({ message: '', fileId, showcaseId, isDraft: true })
        event.close()
      }

      if (relatedComment?.id && id) {
        eventBus.showcaseReplySuccess.broadcast({
          commentId: relatedComment.id,
          anchorId: id,
          repliesAccounts: accountData
            ? [
                {
                  displayUserName: accountData.displayUserName,
                  firstName: accountData.firstName,
                  lastName: accountData.lastName,
                  type: accountData.type,
                  id: accountData.id
                }
              ]
            : []
        })
      }
    },
    [draft, event, fileId, showcaseId, accountData]
  )

  const sendMessage = useCallback(
    (message: string, onSuccess: () => void) => {
      publish(
        { message, fileId, showcaseId },
        {
          onSuccess: (responseData) => {
            onPublishSuccess(responseData)
            onSuccess()
          }
        }
      )
    },
    [fileId, onPublishSuccess, publish, showcaseId]
  )

  const replyMessage = useCallback(
    (message: string, quoteCommentId: string, onSuccess: () => void) => {
      reply(
        { message, fileId, quoteCommentId, showcaseId },
        {
          onSuccess: (responseData) => {
            onPublishReplySuccess(responseData)
            onSuccess()
          }
        }
      )
    },
    [fileId, onPublishReplySuccess, reply, showcaseId]
  )

  const editMessage = useCallback(
    (message: string, commentId: string, onSuccess: () => void) =>
      edit(
        { message, showcaseId, commentId },
        {
          onSuccess: ({ updatedAt, mentions }) => {
            onEditSuccess({ commentId, message, updatedAt, mentions })
            onSuccess()
          }
        }
      ),
    [edit, onEditSuccess, showcaseId]
  )

  const debouncedSetDraft = useMemo(
    () =>
      debounce((message: string) => {
        draft({ message, fileId, showcaseId, isDraft: true })
      }, DEFAULT_THROTTLE_MS),
    [draft, fileId, showcaseId]
  )

  const updateDraftMessage = useEvent(debouncedSetDraft)

  const { data: accountSuggestions } = useQuery({
    queryKey: [QUERY_KEY_ACCOUNT_SUGGESTIONS, showcaseId, suggestionSearch],
    queryFn: () => getAccountSuggestions({ showcaseId, search: suggestionSearch }),
    select: ({ results }) =>
      results.map<TAccountMention>((suggestion) => ({
        variant: 'account',
        name: suggestion.displayUserName,
        trigger: '@',
        ...suggestion
      })),
    enabled: enableSuggestions
  })

  const onSearchChange = useCallback(({ trigger, value }: TOnSearchChangePayload) => {
    if (trigger === '@') {
      setSuggestionSearch(value)
      setEnableSuggestions(true)
    }
  }, [])

  const accountMentions = useMemo(() => {
    if (event?.type === 'edit') {
      return formatAccountMentions(event?.mentions ?? [])
    }

    return formatAccountMentions(data?.mentions ?? [])
  }, [data?.mentions, event?.mentions, event?.type])

  return {
    refetchDraft,
    updateDraftMessage,
    sendMessage,
    replyMessage,
    editMessage,
    onSearchChange,
    accountSuggestions,
    processing: processing || isFetching,
    editProcessing,
    replyProcessing,
    loading,
    draftMessage: data?.message ?? '',
    accountMentions
  }
}
