import { ProducedNotificationsEnum } from '@medentee/enums'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getIsBasicSubscription, isCurrentSubscriptionActive, useAppSelector } from 'store'

import { getAutoPayment } from '../../../../../api'
import { useNotificationTracker } from '../../../../hooks'

import styles from './MonthlyTopUpSetUp.module.scss'
import { MonthlyTopUpDescription } from './MonthlyTopUpDescription'
import { MonthlyTopUpControls } from './MonthlyTopUpControls'
import { MonthlyTopUpCardDetails } from './MonthlyTopUpCardDetails'

const TRACKED_NOTIFICATIONS = new Set([
  ProducedNotificationsEnum.AUTO_TOP_UP_SET,
  ProducedNotificationsEnum.AUTO_TOP_UP_CANCELED
])

export const MonthlyTopUpSetUp = () => {
  const isBasicSubscription = useAppSelector(getIsBasicSubscription)
  const isSubscriptionActive = useAppSelector(isCurrentSubscriptionActive)

  const { t } = useTranslation()

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['get_auto_top_up'],
    queryFn: () => getAutoPayment(),
    enabled: false
  })

  useNotificationTracker({
    channel: 'top-up',
    notifications: TRACKED_NOTIFICATIONS,
    onOccurrence: () => refetch()
  })

  if (isBasicSubscription || !isSubscriptionActive) {
    return null
  }

  return (
    <div className={styles.root}>
      <Skeleton
        active={true}
        title={false}
        loading={isFetching}
        paragraph={{ rows: 1 }}
        className={styles.skeleton}
      >
        <div>
          <h3 className={styles.title}>{t('payments.ballance.monthlyTopUp.title')}</h3>
          <div className={styles.setUp}>
            <MonthlyTopUpDescription data={data} />
            <MonthlyTopUpControls data={data} />
          </div>
        </div>
        <MonthlyTopUpCardDetails data={data} />
      </Skeleton>
    </div>
  )
}
