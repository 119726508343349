import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Clock, IconLabel } from 'App/components'
import { ReactComponent as LocationIcon } from 'assets/icons/Location.svg'
import { getTimezone } from 'utils'

import styles from './DisplayLocationTime.module.scss'

export type TDisplayLocationTimeProps = {
  country: string

  city?: string
  timeZone?: string
} & PropsWithClassName

export const DisplayLocationTime = ({
  city,
  country,
  timeZone,
  className
}: TDisplayLocationTimeProps) => {
  const { t } = useTranslation()

  const timezoneFormatted = useMemo(() => getTimezone(timeZone ?? ''), [timeZone])

  return (
    <IconLabel
      classes={{ root: className, label: styles.label }}
      icon={<LocationIcon />}
      label={
        <>
          {`${city || t('common.notApplicable')}, ${country || t('common.notApplicable')}, `}
          {timezoneFormatted && (
            <>
              <Clock timeZone={timeZone} /> {`(${timezoneFormatted})`}
            </>
          )}
        </>
      }
    />
  )
}
