import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames } from '@medentee/enums'
import { FC } from 'react'

import { createProcessingSelector } from 'store/loading'
import { State } from 'redux/rootReducer'
import { removeErrorNotification } from 'store/errors'
import {
  DELETE_USER_PROFILE_PROFESSION,
  deleteUserProfileProfessionRequest
} from 'store/userProfile'
import {
  UserProfileDeleteProfessionConfirm,
  TUserProfileDeleteProfessionConfirmOwnProps
} from 'App/components/UserProfile/UserProfileDeleteProfessionConfirm'

export type TUserProfileDeleteProfessionConfirmContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const processingSelector = createProcessingSelector([DELETE_USER_PROFILE_PROFESSION])

const mapStateToProps = (state: State) => ({
  accountType: state.global.accountData?.type.name || AccountTypeNames.PROFESSIONAL,
  processing: processingSelector(state),
  error: state.errors[DELETE_USER_PROFILE_PROFESSION]
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteProfession: deleteUserProfileProfessionRequest,
      removeErrorNotification: () => removeErrorNotification(DELETE_USER_PROFILE_PROFESSION)
    },
    dispatch
  )

export const UserProfileDeleteProfessionConfirmContainer: FC<TUserProfileDeleteProfessionConfirmOwnProps> =
  connect(mapStateToProps, mapDispatchToProps)(UserProfileDeleteProfessionConfirm)
