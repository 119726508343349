import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { selectAttachFileAction } from 'store'
import { State } from 'redux/rootReducer'
import { AttachFilesItem, TCaseAttachFilesItemBaseProps } from 'App/components'

export type TCaseAttachFilesItemContainer = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (state: State, { id, isAttached }: TCaseAttachFilesItemBaseProps) => {
  const item = state.medCloud.attachFiles.list[id] ?? {}
  const { fileName, extension } = item

  return {
    fileName,
    extension,
    isAttached: isAttached || item?.isAttached,
    selected: state.medCloud.attachFiles.selectedFiles.includes(id)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectAttachFile: selectAttachFileAction
    },
    dispatch
  )

export const FileAttachFilesItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttachFilesItem)
