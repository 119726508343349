import { useCallback, useEffect } from 'react'

import { THeadNotificationContainerProps } from 'App/containers'
import { NOTIFICATION_COUNTER_MAX_VALUE } from 'globalConstants'

export type THeadNotificationProps = THeadNotificationContainerProps

const RegExp = /^\([0-9]{1,2}\+?\)\s/gm

const FAVICON_NOTIFY_SRC = '/favicon-notify.ico'
const FAVICON_SRC = '/favicon.ico'

export const HeadNotification = ({ count }: THeadNotificationProps) => {
  const updateFavicon = useCallback((faviconSrc: string) => {
    let link = document.querySelector("link[rel~='shortcut icon']")

    if (!link) {
      link = document.createElement('link')
      link.setAttribute('rel', 'icon')

      document.getElementsByTagName('head')[0].appendChild(link)
    }

    link.setAttribute('href', faviconSrc)
  }, [])

  const updateCount = useCallback(() => {
    const oldTitle = document.title.replace(RegExp, '')
    const displayCount =
      count > NOTIFICATION_COUNTER_MAX_VALUE ? `${NOTIFICATION_COUNTER_MAX_VALUE}+` : count
    const newTitle = `(${displayCount}) ${oldTitle}`

    document.title = count ? newTitle : oldTitle
  }, [count])

  useEffect(() => {
    updateCount()
    updateFavicon(count ? FAVICON_NOTIFY_SRC : FAVICON_SRC)
  }, [count, updateCount, updateFavicon])

  return null
}
