import { ReactNode } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import cls from 'classnames'

import { getKeyForRoute } from 'App/App.config'
import { TabMenu, Scrollable, TTabMenuProps } from 'App/components/common'
import { RoleRouteContainer, TRoleRouteContainerOwnProps } from 'App/containers'

import styles from './SettingsPageLayout.module.scss'

type TSettingsPageLayoutMenu = Pick<TTabMenuProps, 'menuList' | 'endAdornment' | 'classes'>

type TSettingsPageLayoutProps = PropsWithClasses<
  'content',
  {
    menu: TSettingsPageLayoutMenu
    nestedRoutes: TRoleRouteContainerOwnProps[]
    defaultPath: LocationDescriptor

    title?: ReactNode
    contentPrefix?: ReactNode
    paper?: boolean
  }
>

export const SettingsPageLayout = ({
  menu,
  nestedRoutes,
  defaultPath,
  title,
  contentPrefix,
  paper,
  classes
}: TSettingsPageLayoutProps) => (
  <div className={styles.root}>
    {title && <h2 className={styles.title}>{title}</h2>}

    <div className={styles.rootWrapper}>
      <TabMenu
        menuList={menu.menuList}
        endAdornment={menu.endAdornment}
        classes={{ root: cls(styles.menu, menu.classes?.root) }}
      />

      <div className={styles.container}>
        <Scrollable>
          <div className={cls(styles.content, { [styles.paper]: paper }, classes?.content)}>
            {contentPrefix}

            <Switch>
              {nestedRoutes.map((item, index) => (
                <RoleRouteContainer key={getKeyForRoute(item.path, index)} {...item} />
              ))}

              <Redirect to={defaultPath} />
            </Switch>
          </div>
        </Scrollable>
      </div>
    </div>
  </div>
)
