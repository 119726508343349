import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { showModalAction } from 'store'
import { Button } from 'App/components'
import { EModalComponents } from 'App/containers'
import { ReactComponent as ConvertIcon } from 'assets/icons/ConvertFilled.svg'
import { useBasicSubscription } from 'App/hooks/useBasicSubscription'

import styles from './CaseConvertWrapper.module.scss'

export type TCaseConvertWrapperProps = { id?: string }

export const CaseConvertWrapper = ({ id }: TCaseConvertWrapperProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const showCaseConvertModal = () => {
    id &&
      dispatch(
        showModalAction({
          modalTitle: t('cases.modal.caseConvert.typeStep.title'),
          modalType: EModalComponents.CASE_CONVERT,
          modalProps: { id }
        })
      )
  }

  const { getOnClickHandler } = useBasicSubscription({
    callback: showCaseConvertModal
  })

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <ConvertIcon />
        <span>{t('cases.details.convert.description')}</span>
      </div>
      <Button onClick={getOnClickHandler()}>{t('cases.details.convert.submitButton')}</Button>
    </div>
  )
}
