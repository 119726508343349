import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'store'
import { getMapComponent } from 'utils'
import { TUseUnityHubCreateChannel, useUnityHubCreateChannel } from 'App/components/UnityHub'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption'

import { MembersStep, TMembersStepProps } from '../MembersStep'
import { useMembersRequest } from '../useMembersRequest'
import { useChatDepartmentsRequest } from '../useChatDepartmentsRequest'

import {
  CreateTextChannelDialogMainStep,
  TCreateTextChannelDialogMainStepProps
} from './CreateTextChannelDialogMainStep'

enum steps {
  MAIN = 0,
  MEMBERS = 1,
  CHANEL_MANAGER = 2
}

const MAX_SELECTED_CHANNEL_MANAGER = 1

type TStepsMapProps = Pick<
  TCreateTextChannelDialogMainStepProps,
  | 'channelId'
  | 'setName'
  | 'channelMembers'
  | 'channelManager'
  | 'channelDepartments'
  | 'isEdit'
  | 'setIsPublic'
  | 'loading'
  | 'onEditSuccess'
  | 'dirty'
  | 'initialValues'
> &
  Pick<
    TMembersStepProps,
    | 'setChannelMembers'
    | 'setChannelManager'
    | 'setChannelDepartments'
    | 'isLeaderInDepartments'
    | 'organizationId'
  > & {
    name: string
    isPublic: boolean
    handleOpenMain: () => void
    handleOpenChanelManager: () => void
    handleOpenMembers: () => void
  }

const STEPS_MAP = new Map<steps, (props: TStepsMapProps) => JSX.Element>()
  .set(
    steps.MAIN,
    ({
      name,
      organizationId,
      channelId,
      channelMembers,
      channelManager,
      isEdit,
      isLeaderInDepartments,
      loading,
      dirty,
      isPublic,
      channelDepartments,
      handleOpenChanelManager,
      handleOpenMembers,
      setName,
      setIsPublic,
      onEditSuccess,
      initialValues
    }) => (
      <CreateTextChannelDialogMainStep
        dirty={dirty}
        organizationId={organizationId}
        name={name}
        channelId={channelId}
        channelMembers={channelMembers}
        channelManager={channelManager}
        channelDepartments={channelDepartments}
        isEdit={isEdit}
        isPublic={isPublic}
        isLeaderInDepartments={isLeaderInDepartments}
        loading={loading}
        setName={setName}
        onOpenChanelManager={handleOpenChanelManager}
        onOpenMembers={handleOpenMembers}
        setIsPublic={setIsPublic}
        onEditSuccess={onEditSuccess}
        initialValues={initialValues}
      />
    )
  )
  .set(
    steps.MEMBERS,
    ({
      channelMembers,
      channelDepartments,
      organizationId,
      isLeaderInDepartments,
      channelManager,
      channelId,
      handleOpenMain,
      setChannelMembers,
      setChannelManager,
      setChannelDepartments
    }) => (
      <MembersStep
        variant="members"
        channelManagerId={channelManager?.id}
        isLeaderInDepartments={isLeaderInDepartments}
        organizationId={organizationId}
        channelMembers={channelMembers}
        channelDepartments={channelDepartments}
        markChatIds={channelId ? [channelId] : undefined}
        onBack={handleOpenMain}
        setChannelMembers={setChannelMembers}
        setChannelManager={setChannelManager}
        setChannelDepartments={setChannelDepartments}
      />
    )
  )
  .set(
    steps.CHANEL_MANAGER,
    ({
      organizationId,
      isLeaderInDepartments,
      channelManager,
      setChannelManager,
      setChannelMembers,
      handleOpenMain
    }) => (
      <MembersStep
        variant="manager"
        maxSelected={MAX_SELECTED_CHANNEL_MANAGER}
        channelManagerId={channelManager?.id}
        isLeaderInDepartments={isLeaderInDepartments}
        organizationId={organizationId}
        channelMembers={channelManager ? [channelManager] : []}
        onBack={handleOpenMain}
        setChannelMembers={setChannelMembers}
        setChannelManager={setChannelManager}
      />
    )
  )

export type TCreateTextChannelDialogProps = Pick<
  TUseUnityHubCreateChannel,
  'channelId' | 'channelName' | 'isPublic'
> &
  Pick<TCreateTextChannelDialogMainStepProps, 'onEditSuccess'> & {
    organizationId: string
  }

export const CreateTextChannelDialog = ({
  organizationId,
  channelName,
  onEditSuccess,
  channelId = '',
  isPublic: propIsPublic
}: TCreateTextChannelDialogProps) => {
  const {
    name,
    isPublic,
    channelMembers,
    channelManager,
    channelDepartments,
    step,
    isEdit,
    dirty,
    handleOpenMain,
    handleOpenMembers,
    handleOpenChanelManager,
    setName,
    setIsPublic,
    setChannelMembers,
    setChannelManager,
    setChannelDepartments
  } = useUnityHubCreateChannel({
    channelName,
    channelId,
    isPublic: propIsPublic,
    maxSelectedChannelManager: MAX_SELECTED_CHANNEL_MANAGER
  })

  const room = useAppSelector((state) => state.chat.chatRooms.list[channelId])
  const accountData = useAppSelector((state) => state.global.accountData)
  const isLeaderInDepartments = useAppSelector(
    (state) => state.organizations.data.list[organizationId]?.isLeaderInDepartments
  )

  const initialValues = useMemo(
    () => ({ name: channelName || '', isPublic: propIsPublic || false }),
    [channelName, propIsPublic]
  )

  const { isLoading, accounts } = useMembersRequest({
    variant: 'chat',
    channelId,
    organizationId,
    isEdit,
    setChannelMembers
  })

  const { isLoading: departmentsLoading } = useChatDepartmentsRequest({
    channelId,
    isEdit,
    setChannelDepartments
  })

  useEffect(() => {
    if (isEdit && accounts?.length && room?.channelManager) {
      if (isLeaderInDepartments && accountData?.id) {
        setChannelMembers(
          accounts.filter((item) => item.id !== accountData.id),
          true
        )
      }

      setChannelManager([room.channelManager], true)
    }
  }, [
    accounts,
    room?.channelManager,
    accountData?.id,
    isEdit,
    isLeaderInDepartments,
    organizationId,
    setChannelManager,
    setChannelMembers
  ])

  useEffect(() => {
    if (isLeaderInDepartments && accountData) {
      const myAccount: TAccountsSelectOptionData = {
        id: accountData.id,
        type: accountData.type,
        displayUserName: accountData.displayUserName,
        firstName: accountData.firstName,
        lastName: accountData.lastName,
        disabled: true
      }

      setChannelManager([myAccount], true)
    }
  }, [isLeaderInDepartments, accountData, setChannelManager])

  return (
    <>
      {getMapComponent(STEPS_MAP, step, {
        name,
        isPublic,
        channelMembers,
        channelManager,
        channelDepartments,
        channelId,
        isLeaderInDepartments,
        isEdit,
        organizationId,
        loading: isLoading || departmentsLoading,
        dirty,
        initialValues,
        setName,
        setIsPublic,
        setChannelMembers,
        setChannelManager,
        setChannelDepartments,
        handleOpenMain,
        handleOpenMembers,
        handleOpenChanelManager,
        onEditSuccess
      })}
    </>
  )
}
