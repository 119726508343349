import { PropsWithChildren } from 'react'

import styles from './UnityHubNetworkingDrawerItem.module.scss'

export type TUnityHubNetworkingDrawerItemProps = PropsWithChildren<{
  title?: string
}>

export const UnityHubNetworkingDrawerItem = ({
  title,
  children
}: TUnityHubNetworkingDrawerItemProps) => (
  <div className={styles.root}>
    {!!title && <div className={styles.title}>{title}</div>}
    {children}
  </div>
)
