import { Skeleton, SkeletonProps } from 'antd'
import cls from 'classnames'

import { FieldLabel, Help } from 'App/components'
import { MAX_SHOWCASE_FILES_AMOUNT, MAX_SHOWCASE_FILES_SIZE } from 'globalConstants'
import { formatBytes } from 'utils'

import { FilesLimit, TFilesLimitProps } from '../FilesLimit'

import styles from './FilesHeader.module.scss'

type TFilesHeaderProps = Pick<TFilesLimitProps, 'amount' | 'size' | 'onAttachFiles'> &
  Pick<SkeletonProps, 'loading'>

export const FilesHeader = ({ amount, size, loading, onAttachFiles }: TFilesHeaderProps) => (
  <div className={cls(styles.root, { [styles.skeleton]: loading })}>
    <Skeleton
      loading={loading}
      active={true}
      title={false}
      className={styles.amountSkeleton}
      paragraph={{ rows: 1, width: '100%' }}
    >
      <FieldLabel
        label={
          <>
            Add Photos or Videos*
            <Help
              content={`You can add up to ${MAX_SHOWCASE_FILES_AMOUNT} items (${formatBytes(
                MAX_SHOWCASE_FILES_SIZE
              )} total size)`}
            />
          </>
        }
      />
    </Skeleton>
    <Skeleton
      loading={loading}
      active={true}
      title={false}
      className={styles.sizeSkeleton}
      paragraph={{ rows: 1, width: '100%' }}
    >
      <FilesLimit amount={amount} size={size} onAttachFiles={onAttachFiles} />
    </Skeleton>
  </div>
)
