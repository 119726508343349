import { useCallback } from 'react'
import cls from 'classnames'

import { Checkbox, FileName } from 'App/components'
import { TCaseAttachFilesItemContainer } from 'App/containers'

import styles from './AttachFilesItem.module.scss'

export type TCaseAttachFilesItemBaseProps = {
  id: string
  isAttached?: boolean
}

export type TCaseAttachFilesItemProps = TCaseAttachFilesItemBaseProps &
  TCaseAttachFilesItemContainer

export const AttachFilesItem = ({
  fileName,
  extension,
  id,
  selected,
  isAttached,
  selectAttachFile
}: TCaseAttachFilesItemProps) => {
  const onToggle = useCallback(() => {
    selectAttachFile({ fileId: id })
  }, [selectAttachFile, id])

  return (
    <Checkbox
      checked={selected || isAttached}
      disabled={isAttached}
      label={
        <FileName
          extension={extension}
          fileName={fileName}
          disabled={isAttached}
          className={styles.fileNameWrapper}
          textClassName={cls(styles.fileName, { [styles.disabled]: isAttached })}
        />
      }
      onChange={onToggle}
    />
  )
}
