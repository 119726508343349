import { useCallback } from 'react'
import {
  CaseClassificationNameEnum,
  CasePermissionsModeEnum,
  CaseStatusesEnum,
  CaseTypesEnum,
  FileHistorySourcesEnum
} from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { showModalAction, TIds } from 'store'
import { CaseCloudFilesItemContainer, EModalComponents } from 'App/containers'
import { CaseCloudPermissionsMode, TCaseCloudFilesItemBaseProps } from 'App/components'
import { useAdaptiveLayout } from 'App/hooks'

import { CaseCloudFilesListWrapper } from '../CaseCloudFilesListWrapper'

export type TCaseCloudFilesListProps = Pick<
  TCaseCloudFilesItemBaseProps,
  'caseStatus' | 'isMember' | 'caseView' | 'getKebabMenu' | 'onSelect' | 'filesView' | 'selectMode'
> & {
  cloudFilesIds: TIds
  loading: boolean
  caseId: string
  showModal: typeof showModalAction

  selectedIds?: TIds
  caseType?: CaseTypesEnum | null
  permissionsMode?: CasePermissionsModeEnum
  caseClassificationName?: CaseClassificationNameEnum
}

export const CaseCloudFilesList = ({
  cloudFilesIds,
  isMember,
  caseStatus,
  loading,
  caseId,
  caseType,
  permissionsMode,
  caseClassificationName,
  caseView,
  selectedIds,
  filesView,
  selectMode,
  getKebabMenu,
  showModal,
  onSelect
}: TCaseCloudFilesListProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const isCaseArchived = caseStatus === CaseStatusesEnum.ARCHIVED
  const isCaseStandardView = caseView === 'standard'

  const shouldShowPermissionsMode =
    !isMember &&
    !isCaseArchived &&
    caseClassificationName !== CaseClassificationNameEnum.PRIVATE &&
    caseType !== null &&
    !!cloudFilesIds.length &&
    !loading

  const showPermissionsModeAtTop = isDesktop && isCaseStandardView && shouldShowPermissionsMode
  const showPermissionsModeAtBottom =
    (!isDesktop || !isCaseStandardView) && shouldShowPermissionsMode

  const handlePermissionsModeChange = useCallback(
    (value: boolean) => {
      showModal({
        modalTitle: value
          ? t('cases.modal.changePermissionModeConfirm.title_auto')
          : t('cases.modal.changePermissionModeConfirm.title_manual'),
        modalType: EModalComponents.CASE_CLOUD_PERMISSIONS_MODE_CHANGE_CONFIRM,
        modalProps: {
          caseId,
          permissionsMode: value ? CasePermissionsModeEnum.AUTO : CasePermissionsModeEnum.MANUAL
        }
      })
    },
    [caseId, showModal, t]
  )

  return (
    <>
      {showPermissionsModeAtTop && (
        <CaseCloudPermissionsMode
          spaces={[0, 16]}
          permissionsMode={permissionsMode}
          onChange={handlePermissionsModeChange}
        />
      )}

      <CaseCloudFilesListWrapper filesView={filesView} loading={loading} ids={cloudFilesIds}>
        {(id) => (
          <CaseCloudFilesItemContainer
            key={id}
            id={id}
            isMember={isMember}
            caseStatus={caseStatus}
            source={FileHistorySourcesEnum.CASE_CLOUD}
            originalEntityId={caseId}
            caseView={caseView}
            selected={selectedIds?.includes(id)}
            selectedIdsLength={selectedIds?.length}
            filesView={filesView}
            selectMode={selectMode}
            getKebabMenu={getKebabMenu}
            onSelect={onSelect}
          />
        )}
      </CaseCloudFilesListWrapper>

      {showPermissionsModeAtBottom && (
        <CaseCloudPermissionsMode
          spaces={[12, 18]}
          permissionsMode={permissionsMode}
          onChange={handlePermissionsModeChange}
        />
      )}
    </>
  )
}
