import { normalize, schema } from 'normalizr'

import { IFilesEntity } from '../../../interfaces/api/files'

import {
  TFileSharedContact,
  TFileSharedContactIds,
  TFileSharedContactList,
  TFileSharedIds,
  TFileSharedList
} from './files.types'

const fileSharedSchema = new schema.Entity('fileList')

const fileSharedListSchema = new schema.Array(fileSharedSchema)

export type TFileSharedNormalized = {
  fileList: TFileSharedList
  ids: TFileSharedIds
}

type TFileSharedNormalizeResult = {
  entities: {
    fileList: TFileSharedList
  }
  result: TFileSharedIds
}

export const fileSharedNormalize = (originalData: IFilesEntity[]): TFileSharedNormalized => {
  const normalizedData: TFileSharedNormalizeResult = normalize(originalData, fileSharedListSchema)
  return {
    fileList: normalizedData.entities.fileList || {},
    ids: normalizedData.result || []
  }
}

const fileSharedContactSchema = new schema.Entity(
  'fileSharedContactList',
  {},
  { idAttribute: 'contactUserId' }
)

const fileSharedContactListSchema = new schema.Array(fileSharedContactSchema)

export type TFileSharedContactNormalized = {
  fileSharedContactList: TFileSharedContactList
  fileSharedContactIds: TFileSharedContactIds
}

type TFileSharedContactNormalizeResult = {
  entities: {
    fileSharedContactList: TFileSharedContactList
  }
  result: TFileSharedContactIds
}

export const fileSharedContactNormalize = (
  originalData: TFileSharedContact
): TFileSharedContactNormalized => {
  const normalizedData: TFileSharedContactNormalizeResult = normalize(
    originalData,
    fileSharedContactListSchema
  )

  return {
    fileSharedContactList: normalizedData.entities.fileSharedContactList || {},
    fileSharedContactIds: normalizedData.result || []
  }
}
