import { useMemo } from 'react'

import { validation } from 'utils'

import { UnityHubInfoLink } from '../UnityHubInfoLink'

import styles from './UnityHubInfoLocation.module.scss'

export type TUnityHubInfoLocationProps = PropsWithClasses<
  'link',
  {
    countryName?: string
    cityName?: string
    location?: string
  }
>

export const UnityHubInfoLocation = ({
  cityName,
  countryName,
  location = ''
}: TUnityHubInfoLocationProps) => {
  const isLocationUrl = useMemo(() => !validation.isUrl()(location), [location])

  return (
    <div className={styles.root}>
      {countryName && cityName && <span>{`${countryName}, ${cityName}`}</span>}

      {isLocationUrl ? (
        <UnityHubInfoLink link={location} />
      ) : (
        <span className={styles.location}>{location}</span>
      )}
    </div>
  )
}
