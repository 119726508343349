import { useEffect, useMemo } from 'react'

import { sortByName } from 'utils'
import { useEvent } from 'App/hooks'

import { ChatCreateGroupMembersItem } from '../ChatCreateGroupMembersItem'
import { ChatCreateGroupNameField } from '../ChatCreateGroupNameField'
import { ChatCreateGroupUploadAvatar } from '../ChatCreateGroupUploadAvatar'
import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

import styles from './ChatCreateGroupPrepareToCreate.module.scss'

const MAX_SELECTED_USERS_FOR_AUTO_NAME = 4

export const ChatCreateGroupPrepareToCreate = () => {
  const { selectedMembers, chatName, touchedChatName, setChatName, setTouchedChatName } =
    useChatCreateGroupContext()

  const sortedMembers = useMemo(
    () => [...selectedMembers].sort((a, b) => sortByName(a.displayUserName, b.displayUserName)),
    [selectedMembers]
  )

  const handleNameChange = useEvent((name: string) => {
    setChatName(name)
    setTouchedChatName(true)
  })

  const handleSetChatName = useEvent(() => {
    if (touchedChatName) {
      return
    }

    const membersLength = selectedMembers.length

    if (membersLength && membersLength <= MAX_SELECTED_USERS_FOR_AUTO_NAME) {
      setChatName(selectedMembers.map(({ displayUserName }) => displayUserName).join(', '))
    }

    if (!membersLength || membersLength > MAX_SELECTED_USERS_FOR_AUTO_NAME) {
      setChatName('')
    }
  })

  useEffect(() => {
    handleSetChatName()
  }, [handleSetChatName])

  return (
    <div className={styles.root}>
      <ChatCreateGroupUploadAvatar />

      <ChatCreateGroupNameField value={chatName} onChange={handleNameChange} />

      <div className={styles.list}>
        {sortedMembers.map((item) => (
          <ChatCreateGroupMembersItem
            key={item.id}
            displayUserName={item.displayUserName}
            userId={item.id}
            firstName={item.firstName}
            lastName={item.lastName}
            type={item.type.name}
          />
        ))}
      </div>
    </div>
  )
}
