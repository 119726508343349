import { useCallback } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  getChatRoomsRequest,
  hideModalAction,
  onChatRoomNotFoundError,
  resetChatAction,
  useAppDispatch
} from 'store'
import { Button, ConfirmationModal } from 'App/components/common'
import { communityDeleteChannel } from 'api'

type TCommunityDeleteTextChannelConfirmProps = {
  channelId: string
  communityId: string
}

export const CommunityDeleteTextChannelConfirm = ({
  communityId,
  channelId
}: TCommunityDeleteTextChannelConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = () => {
    dispatch(hideModalAction())
    dispatch(resetChatAction())
    dispatch(
      getChatRoomsRequest({
        communityId,
        chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS]
      })
    )
  }

  const { mutate, error, isLoading } = useMutation({
    mutationKey: ['community_delete_text_channel', communityId, channelId],
    mutationFn: communityDeleteChannel,
    onSuccess,
    onError: (e) => onChatRoomNotFoundError(e, onSuccess)
  })

  const onClick = useCallback(() => {
    mutate({ communityId, channelId })
  }, [channelId, communityId, mutate])

  return (
    <ConfirmationModal
      error={error}
      content={t('modal.deleteChannelConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteChannelConfirm.submitButton')}
        </Button>
      }
    />
  )
}
