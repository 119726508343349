import { Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'App/components'
import { createProcessingSelector, becomeContactsRequest, BECOME_CONTACTS } from 'store'

import styles from './BecomeContactsDialog.module.scss'

export type TBecomeContactsDialogProps = {
  requestId: string
}

const processingRequest = createProcessingSelector([BECOME_CONTACTS])

export const BecomeContactsDialog = ({ requestId }: TBecomeContactsDialogProps) => {
  const dispatch = useDispatch()
  const processing = useSelector(processingRequest)

  const handleClick = () => {
    dispatch(becomeContactsRequest({ requestId }))
  }

  return (
    <Row gutter={[0, 28]}>
      <Col xs={24}>
        <Row justify="center">
          <span className={styles.text}>
            Are you sure you want to connect with this User? You will become contacts to continue
            communication.
          </span>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Button loading={processing} onClick={handleClick}>
            Confirm
          </Button>
        </Row>
      </Col>
    </Row>
  )
}
