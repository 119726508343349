import { all, call, fork, put, select, spawn, take, takeLatest } from 'redux-saga/effects'
import { ContactStatusEnum, ProducedNotificationsEnum } from '@medentee/enums'
import { ActionCreator } from 'redux'

import { API, api, APIResultsResponse } from 'services/api'
import {
  ESocketNameSpaces,
  initWebSocket,
  socketNameSpaces,
  socketSubscribesEventsActionsMap,
  TWebSocketEventAction
} from 'services/webSocket'
import {
  contactsSaga,
  callsSaga,
  caseCloudSaga,
  caseCreateSaga,
  caseDetailsSaga,
  caseListSaga,
  caseMembersSaga,
  casesInviteListSaga,
  caseViewSaga,
  categoryListSaga,
  categorySaga,
  chatMessagesSaga,
  chatPinSaga,
  chatRoomsSaga,
  GET_WHO_AMI_REQUEST,
  GET_WHO_AMI_SUCCESS,
  getWhoAmiError,
  getWhoAmiRequest,
  getWhoAmiSuccess,
  fileListSaga,
  fileSharedListSaga,
  GET_INITIALIZE_APP_REQUEST,
  initializeAppSuccess,
  inviteContactSaga,
  logoutSaga,
  medCloudSaga,
  setOnlineAction,
  userProfileSaga,
  notificationsSaga,
  invitationsSaga,
  isCallInProgressSelector,
  administrationSaga,
  settingsSaga,
  showcasesSaga,
  subscriptionsSaga,
  onlineSaga,
  systemSaga,
  receivedNotification,
  TTrackedNotificationsState,
  paymentsSaga,
  advertsSaga,
  trashBinSaga,
  meetingsSaga,
  broadcastSaga,
  organizationsSaga,
  communitiesSaga,
  eventsSaga,
  broadcastsSaga
} from 'store'
import { ContactsQueryBuilder } from 'utils'
import { handleError } from 'api/utils'

import { State } from '../redux/rootReducer'
import { ISetAllUsersOnlineDTO } from '../interfaces/api/online'

import {
  CONNECT_WS_STATUS,
  DISCONNECT_WS_STATUS,
  setConnectWebSocketStatusAction
} from './store.actions'
import { miscSaga } from './misc/misc.operations'
import { onlineNormalize } from './online'
import { TContactsData } from './contacts/contacts.types'
import { formatContactsData } from './contacts/utils'
import { getNotificationsGeneralRequest, refreshNotificationCountersRequest } from './notifications'
import { clearCallAction } from './calls'
import { TAction } from './store.utils'
import { getActiveSubscriptionsRequest, getMedCloudCapacityRequest } from './subscriptions'
import { getUserBalanceRequest } from './payments'
import { TTrackedNotificationsChannel } from './trackedNotifications'

export function* initWebsocketSaga(namespace: ESocketNameSpaces) {
  const channel: string = yield call(initWebSocket, namespace)

  while (true) {
    const { payload, eventName }: TWebSocketEventAction = yield take(channel)
    const action: ActionCreator<TAction> = yield socketSubscribesEventsActionsMap[namespace][
      eventName
    ]

    if (
      namespace === ESocketNameSpaces.NOTIFICATIONS ||
      namespace === ESocketNameSpaces.ORGANIZATIONS ||
      namespace === ESocketNameSpaces.COMMUNITIES ||
      namespace === ESocketNameSpaces.EVENTS
    ) {
      const trackedNotifications: TTrackedNotificationsState = yield select(
        (state: State) => state.trackedNotifications
      )
      const isSubscribed: boolean = yield select((state: State) => state.notifications.subscribed)
      const notificationChannels = Object.keys(trackedNotifications)

      if (notificationChannels.length) {
        const notificationChannel = notificationChannels.find((itemChannel) => {
          const data = trackedNotifications[itemChannel]

          return (
            data.notifications &&
            data.notifications.has(eventName as ProducedNotificationsEnum) &&
            (data.filter ? data.filter(payload) : true)
          )
        })

        if (notificationChannel) {
          yield put(receivedNotification({ channel: notificationChannel, payload }))
        }
      }

      if (isSubscribed) {
        yield put(refreshNotificationCountersRequest())
      }

      yield put(getNotificationsGeneralRequest())
    }

    if (
      namespace === ESocketNameSpaces.SYSTEM &&
      eventName === ProducedNotificationsEnum.REFRESH_GENERAL_NOTIFICATIONS
    ) {
      if (payload && payload.type) {
        const trackedNotifications: TTrackedNotificationsChannel = yield select(
          (state: State) => state.trackedNotifications.refresh
        )

        if (
          trackedNotifications &&
          trackedNotifications.notifications &&
          trackedNotifications.notifications.has(payload.type) &&
          (trackedNotifications.filter ? trackedNotifications.filter(payload) : true)
        ) {
          yield put(receivedNotification({ channel: 'refresh', payload }))
        }
      }

      yield put(getNotificationsGeneralRequest())
    }

    if (action) {
      yield put(action(payload))
    }
  }
}

export function* initAllSocketsSaga() {
  for (const namespace of socketNameSpaces) {
    yield fork(initWebsocketSaga, namespace)
  }
}

function* getWhoAmiSaga() {
  try {
    const { data } = yield call(api, API.WHO_AMI)

    yield put(getWhoAmiSuccess({ accountData: data }))
  } catch (e) {
    if (e?.response?.status !== 401) {
      handleError(e)
    }
    put(getWhoAmiError(e))
  }
}

export function* getAllOnlineSaga() {
  try {
    const queryBuilder = new ContactsQueryBuilder(API.CONTACTS)
    const url = queryBuilder.isOnline(true).status(ContactStatusEnum.APPROVED).build()

    const { data }: APIResultsResponse<TContactsData[]> = yield call(api, url)

    const accountId: string = yield select((state: State) => state.global.accountData?.id)

    const formattedOnlineData: ISetAllUsersOnlineDTO[] = formatContactsData(
      data.results,
      accountId
    ).map((item) => ({
      id: item.to.id,
      lastSeen: item.to.lastSeen,
      online: item.online
    }))

    yield put(setOnlineAction(onlineNormalize(formattedOnlineData)))
  } catch (e) {
    yield handleError(e)
  }
}

function* initializeAppSaga() {
  yield all([put(getWhoAmiRequest())])
  yield all([take(GET_WHO_AMI_SUCCESS)])

  yield all([
    // call/put main requests
    put(initializeAppSuccess()),
    put(getMedCloudCapacityRequest()),
    put(getUserBalanceRequest()),
    put(getNotificationsGeneralRequest()),
    put(getActiveSubscriptionsRequest()),
    call(getAllOnlineSaga),
    fork(initAllSocketsSaga),
    // other sagas run
    spawn(rootSaga)
  ])
}

function* connectWebSocket() {
  yield put(setConnectWebSocketStatusAction({ status: true }))
}

function* disconnectWebSocket() {
  const isCallInProgress: boolean = yield select(isCallInProgressSelector)

  if (isCallInProgress) {
    yield put(clearCallAction())
  }

  yield put(setConnectWebSocketStatusAction({ status: false }))
}

export function* rootSaga() {
  yield spawn(logoutSaga)
  yield spawn(inviteContactSaga)
  yield spawn(contactsSaga)
  yield spawn(categorySaga)
  yield spawn(categoryListSaga)
  yield spawn(chatRoomsSaga)
  yield spawn(fileListSaga)
  yield spawn(fileSharedListSaga)
  yield spawn(chatMessagesSaga)
  yield spawn(medCloudSaga)
  yield spawn(caseListSaga)
  yield spawn(caseMembersSaga)
  yield spawn(caseCreateSaga)
  yield spawn(caseCloudSaga)
  yield spawn(caseDetailsSaga)
  yield spawn(caseViewSaga)
  yield spawn(casesInviteListSaga)
  yield spawn(userProfileSaga)
  yield spawn(miscSaga)
  yield spawn(chatPinSaga)
  yield spawn(callsSaga)
  yield spawn(notificationsSaga)
  yield spawn(invitationsSaga)
  yield spawn(administrationSaga)
  yield spawn(settingsSaga)
  yield spawn(showcasesSaga)
  yield spawn(subscriptionsSaga)
  yield spawn(onlineSaga)
  yield spawn(systemSaga)
  yield spawn(paymentsSaga)
  yield spawn(advertsSaga)
  yield spawn(trashBinSaga)
  yield spawn(meetingsSaga)
  yield spawn(broadcastSaga)
  yield spawn(organizationsSaga)
  yield spawn(communitiesSaga)
  yield spawn(eventsSaga)
  yield spawn(broadcastsSaga)
}

export function* initSaga() {
  yield takeLatest(GET_WHO_AMI_REQUEST, getWhoAmiSaga)
  yield takeLatest(GET_INITIALIZE_APP_REQUEST, initializeAppSaga)

  // main requests
  yield takeLatest(CONNECT_WS_STATUS, connectWebSocket)
  yield takeLatest(DISCONNECT_WS_STATUS, disconnectWebSocket)
}
