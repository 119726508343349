import { useEffect } from 'react'

import { TUserProfilePreviewWrapperProps } from 'App/containers/UserProfile/UserProfilePreviewWrapper'
import { UserProfileContactContainer } from 'App/containers/UserProfile/UserProfileContactContainer'

import { Spinner } from '../../Spinner'

export const UserProfilePreviewWrapper = ({
  id,
  loading,
  getUserProfile,
  resetUserProfileView
}: TUserProfilePreviewWrapperProps) => {
  useEffect(() => {
    getUserProfile({ id })
  }, [getUserProfile, id])

  useEffect(() => {
    resetUserProfileView()
  }, [resetUserProfileView])

  return loading ? (
    <Spinner contentCenter={true} />
  ) : (
    <UserProfileContactContainer isPreview={true} />
  )
}
