import { FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { Button, CaseDetailsContainer, CaseSummaryItem, Editor } from 'App/components'
import { TCaseSummaryContainerProps } from 'App/containers'
import { validation } from 'utils/validation'

import { useCaseNotePlaceholder } from '../useCaseNotePlaceholder'

import styles from './CaseSummary.module.scss'

export type TCaseSummaryProps = Omit<TCaseSummaryContainerProps, 'getCaseSummary'>

export type TUpdateSummaryValues = {
  summary: string
}

const SUMMARY_MAX_LENGTH = 6000

export const CaseSummary = ({
  caseId,
  summary,
  loading,
  processing,
  addCaseSummary,
  updateCaseSummary
}: TCaseSummaryProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  const resetForm = useRef<(initialValues?: TUpdateSummaryValues) => void>()
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const placeholder = useCaseNotePlaceholder(t('cases.details.notes.editor.placeholder'))

  const handleSaveSummary = (values: Partial<TUpdateSummaryValues>) => {
    summary
      ? updateCaseSummary({ caseId, summary: values.summary || '' })
      : addCaseSummary({ caseId, summary: values.summary || '' })
  }

  const handleSetEditMode = useCallback(() => {
    setEditMode((prev) => !prev)
  }, [setEditMode])

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      e.target.selectionStart = summary?.data.length || 0
      e.target.selectionEnd = summary?.data.length || 0
      e.target.scroll({
        top: e.target.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    [summary]
  )

  useEffect(() => {
    if (editMode) {
      inputRef.current?.focus()
    }
  }, [editMode])

  useEffect(() => {
    resetForm.current && resetForm.current()
    setEditMode(false)
    // There are props that make it necessary to resetForm such as summary?.createdAt and summary?.updatedAt
  }, [summary?.updatedAt, summary?.createdAt])

  const content = (
    <Skeleton
      loading={loading}
      active={true}
      paragraph={{ rows: 8 }}
      title={{ width: '25%' }}
      className={styles.skeleton}
    >
      <CaseSummaryItem
        createdAt={summary?.createdAt}
        updatedAt={summary?.updatedAt}
        data={summary?.data ?? ''}
        onEdit={handleSetEditMode}
      />
    </Skeleton>
  )

  const form = (
    <Form onSubmit={handleSaveSummary}>
      {({ handleSubmit, form: { getState, reset } }) => (
        <form
          className={styles.form}
          onSubmit={(event) => {
            handleSubmit(event)
            resetForm.current = reset
          }}
        >
          <Field
            name="summary"
            validate={validation.composeValidators(
              validation.maxLength(SUMMARY_MAX_LENGTH),
              validation.onlySpaces()
            )}
            initialValue={summary?.data}
          >
            {({ input }) => (
              <Editor
                {...input}
                classes={{
                  root: styles.editor,
                  wrapper: styles.editorWrapper,
                  input: styles.editorInput
                }}
                autoSize={false}
                valueLengthMax={SUMMARY_MAX_LENGTH}
                placeholder={placeholder}
                loading={processing}
                inputRef={inputRef}
                onFocus={handleOnFocus}
                customBottom={
                  <div className={styles.footer}>
                    <Button
                      className={styles.footerButton}
                      variant="outlined"
                      size="sm"
                      disabled={processing}
                      onClick={handleSetEditMode}
                    >
                      {t('cases.details.notes.editor.cancelButton')}
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      disabled={getState().invalid}
                      loading={processing}
                    >
                      {t('cases.details.notes.editor.submitButton')}
                    </Button>
                  </div>
                }
              />
            )}
          </Field>
        </form>
      )}
    </Form>
  )

  return <CaseDetailsContainer content={editMode ? form : content} />
}
