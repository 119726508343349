import { useCallback } from 'react'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toastDefaultOptions } from 'globalConstants'
import { createVoiceChannel, editVoiceChannel } from 'api/organizations'
import { isMatchErrorCode } from 'utils'
import { getVoiceRoomsRequest, hideModalAction, useAppDispatch } from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { toast } from 'App/components/ToastContainer'
import { TChannelDepartment } from 'App/components'

export type TUseRequest = {
  organizationId: string

  channelMembers?: TAccountsSelectOptionData[]
  channelDepartments?: TChannelDepartment[]
  channelId?: string
  isEdit?: boolean
}

const MUTATION_KEY = 'create-text-channel'
const MUTATION_KEY_EDIT = 'edit-text-channel'

export const useRequest = ({
  organizationId,
  channelMembers,
  channelDepartments,
  isEdit,
  channelId
}: TUseRequest) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleSubmitSuccess = useCallback(() => {
    dispatch(hideModalAction())
    dispatch(getVoiceRoomsRequest({ organizationId }))
  }, [dispatch, organizationId])

  const handleSubmitError = useCallback(
    (error) => {
      if (isMatchErrorCode(error, ErrorCodesEnum.NO_ACCESS_TO_ORGANIZATION)) {
        dispatch(hideModalAction())

        toast.warn(
          t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
          toastDefaultOptions
        )
      }
    },
    [dispatch, t]
  )

  const {
    error: editError,
    isLoading: editLoading,
    mutate: edit
  } = useMutation({
    mutationKey: [MUTATION_KEY_EDIT],
    mutationFn: editVoiceChannel,
    onError: handleSubmitError,
    onSuccess: handleSubmitSuccess
  })

  const { error, isLoading, mutate } = useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: createVoiceChannel,
    onError: handleSubmitError,
    onSuccess: handleSubmitSuccess
  })

  const handleFormSubmit = useCallback(
    (values: { name: string; isPublic: boolean }) => {
      const accountIds = !values.isPublic ? channelMembers?.map(({ id }) => id) : []
      const departmentIds = !values.isPublic ? channelDepartments?.map(({ id }) => id) : []

      if (isEdit && channelId) {
        edit({
          ...values,
          organizationId,
          accountIds,
          departmentIds,
          channelId
        })

        return
      }

      mutate({
        ...values,
        organizationId,
        accountIds,
        departmentIds
      })
    },
    [channelMembers, channelDepartments, isEdit, channelId, mutate, organizationId, edit]
  )

  const isFieldError = isMatchErrorCode(error, [ErrorCodesEnum.VOICE_CHANNEL_ALREADY_EXIST])

  return {
    error: error || editError,
    processing: isLoading || editLoading,
    isFieldError,
    handleFormSubmit
  }
}
