import { memo, ReactNode, useEffect, useMemo, useState } from 'react'
import cls from 'classnames'
import { ChatStatusEnum, ChatTypeEnum, MessageTypeEnum } from '@medentee/enums'
import { ContentState, EditorState } from 'draft-js'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { MAX_GROUP_CHAT_FILE_SIZE } from 'globalConstants'
import { getMapComponent, validation } from 'utils'
import { useAdaptiveLayout, useEvent, usePrevious } from 'App/hooks'
import { EChatMessageAction, EChatViewType, EIconSize, EKeyCodes } from 'enums'
import {
  ChatMessageAudioRecord,
  DraftEditor,
  IconButton,
  TChatMessageAudioRecordClasses,
  TDraftEditorClasses,
  useDraftEditor
} from 'App/components'
import { EModalComponents, TChatFieldContainerProps } from 'App/containers'
import { useChatContext } from 'App/context/ChatContext'
import { ReactComponent as AttachIcon } from 'assets/icons/Attach.svg'
import { createMentionPlugin } from 'App/components/common/DraftEditor/Mention/Mention.utils'
import { createMentionEntities, TMention, wrapMentions } from 'utils/mentions'
import { AccountSuggestion } from 'App/components/common/DraftEditor/AccountSuggestion'

import { END_ADORNMENT } from '../EndAdornment'

import styles from './ChatFieldWrapper.module.scss'
import { createMentionsList, useChatFiledSuggestions } from './useChatFiledSuggestions'

export type TChatFieldWrapperProps = PartialBy<
  Omit<TChatFieldContainerProps, 'getChatDraft' | 'inactive'>,
  'redirectToCaseFromChat' | 'organizationId' | 'channelManagerId'
> & {
  helperText?: ReactNode
  classes?: Partial<
    Record<TDraftEditorClasses | TChatFieldWrapperClasses | TChatMessageAudioRecordClasses, string>
  >
  showMaxLength?: boolean
  allowAttachment?: boolean
}

type TChatFieldWrapperClasses = 'rootWrapper'

const MAX_LENGTH = 5000
const MENTION_TRIGGER = '@'

const { Suggestions: AccountsSuggestions, plugins: accountPlugins } = createMentionPlugin({
  mentionPrefix: MENTION_TRIGGER,
  theme: { mentionSuggestions: styles.mentionSuggestions }
})

const ChatFieldWrapperView = ({
  chatStatus,
  receiverId,
  chatId,
  caseId,
  ownerId,
  actionMessage,
  actionType,
  readAll,
  helperText,
  classes,
  showMaxLength,
  accountType,
  isContact,
  draftMessage,
  isLockedCase,
  loading,
  isDormant,
  chatType,
  isCoworker,
  mentionedAccounts,
  mentionedDepartments,
  organizationId,
  channelManagerId,
  sendTypingStart,
  sendNewMessage,
  sendTypingDone,
  sendNewAudioMessage,
  endMessageEditing,
  cancelQuoting,
  showModal,
  updateChatDraft,
  redirectToCaseFromChat,
  allowAttachment = true
}: TChatFieldWrapperProps) => {
  const { t } = useTranslation()

  const { isDesktop, isMobile } = useAdaptiveLayout()

  const prevActionType = usePrevious(actionType)

  const { chatViewType, hideChatRoomSearchResults } = useChatContext()

  const accountData = useAppSelector((state) => state.global.accountData)

  const [showSuggestions, setShowSuggestions] = useState(false)

  const enableMentions =
    chatType === ChatTypeEnum.ORGANIZATION ||
    chatType === ChatTypeEnum.COMMUNITY_CHANNEL ||
    chatType === ChatTypeEnum.COMMUNITY_NEWS ||
    chatType === ChatTypeEnum.EVENT_CHANNEL ||
    chatType === ChatTypeEnum.EVENT_NEWS ||
    chatType === ChatTypeEnum.CASE_GROUP ||
    chatType === ChatTypeEnum.GROUP

  const isTextChannel =
    chatType &&
    (chatType === ChatTypeEnum.ORGANIZATION ||
      chatType === ChatTypeEnum.COMMUNITY_CHANNEL ||
      chatType === ChatTypeEnum.COMMUNITY_NEWS ||
      chatType === ChatTypeEnum.EVENT_CHANNEL ||
      chatType === ChatTypeEnum.EVENT_NEWS)

  const { suggestions, onSearchChange } = useChatFiledSuggestions({
    chatId,
    organizationId,
    channelManagerId: isTextChannel ? channelManagerId : undefined
  })

  const mentions = useMemo(
    () =>
      createMentionsList({
        accountSuggestions: mentionedAccounts,
        departmentSuggestions: mentionedDepartments
      }),
    [mentionedAccounts, mentionedDepartments]
  )

  const isChatActive = chatStatus === ChatStatusEnum.ACTIVE
  const isOwner = ownerId === accountData?.id
  const isConnected =
    isContact ||
    isCoworker ||
    chatType === ChatTypeEnum.GROUP ||
    chatType === ChatTypeEnum.CASE_GROUP ||
    chatType === ChatTypeEnum.ORGANIZATION ||
    chatType === ChatTypeEnum.COMMUNITY_NEWS ||
    chatType === ChatTypeEnum.COMMUNITY_CHANNEL

  const initialMessage = useMemo(() => {
    if (isLockedCase || !isConnected) {
      return ContentState.createFromText('')
    }

    return draftMessage
      ? createMentionEntities(draftMessage, mentions)
      : ContentState.createFromText('')
  }, [draftMessage, isLockedCase, isConnected, mentions])

  const {
    plainText,
    editorState,
    setEditorStateText,
    setEditorState,
    baseKeyCommandHandler,
    baseKeyBindingHandler
  } = useDraftEditor({
    draftMessage: initialMessage
  })

  const messageId = actionType === EChatMessageAction.EDIT ? actionMessage?.id : undefined

  const [messageType, switchMessageType] = useState<MessageTypeEnum>(MessageTypeEnum.TEXT)
  const changeMessageType = (key: MessageTypeEnum) => () => switchMessageType(key)

  const isAudioRecordEnabled = !plainText.length && !isDormant
  const isSendActionDisabled = plainText.length > MAX_LENGTH

  const showAttachment = allowAttachment && chatStatus === ChatStatusEnum.ACTIVE
  const isEditorDisabled =
    loading || isDormant || chatStatus !== ChatStatusEnum.ACTIVE || isLockedCase

  const moveFocusToEnd = useEvent((content: string, mentionList: TMention[]) => {
    const contentState = createMentionEntities(content, mentionList)

    const newEditorState = EditorState.push(editorState, contentState, 'insert-characters')

    const selectionState = newEditorState.getSelection().merge({
      anchorOffset: contentState.getPlainText().length,
      focusOffset: contentState.getPlainText().length
    })

    setEditorState(
      content
        ? EditorState.forceSelection(newEditorState, selectionState)
        : EditorState.moveFocusToEnd(editorState)
    )
  })

  useEffect(() => {
    if (actionMessage?.message) {
      const mentionList = createMentionsList({
        accountSuggestions: actionMessage.mentionedAccounts,
        departmentSuggestions: actionMessage.mentionedDepartments
      })

      actionType === EChatMessageAction.EDIT &&
        moveFocusToEnd(actionMessage.message as string, mentionList)

      actionType === EChatMessageAction.QUOTE && moveFocusToEnd(plainText, mentionList)
    }
  }, [actionType, actionMessage])

  useEffect(() => {
    if (
      (!actionMessage?.message && prevActionType === EChatMessageAction.EDIT) ||
      (prevActionType === EChatMessageAction.EDIT && actionType === EChatMessageAction.QUOTE)
    ) {
      setEditorStateText('')
    }
  }, [prevActionType, actionMessage?.message])

  const isP2P = chatType && chatType === ChatTypeEnum.DIALOGUE
  const isP2PView = chatViewType === EChatViewType.P2P

  const initialPlaceholder = useMemo(() => {
    if (isLockedCase) {
      return t('chat.messageField.placeholder_lockedCase')
    }

    if (!isChatActive && isP2P) {
      return t('chat.messageField.placeholder_notCoworker')
    }

    if (!isConnected && !isTextChannel) {
      return t('chat.messageField.placeholder_notContact')
    }

    return t('chat.messageField.placeholder')
  }, [isLockedCase, isConnected, isChatActive, isP2P, isTextChannel, t])

  useEffect(() => {
    changeMessageType(MessageTypeEnum.TEXT)()
  }, [chatId, chatStatus])

  const onRedirectToCase = redirectToCaseFromChat
    ? () =>
        redirectToCaseFromChat({
          caseId: caseId ?? '',
          ownerId: ownerId ?? '',
          chatId: chatId ?? ''
        })
    : undefined

  if (!chatId) {
    return null
  }

  const onAttachFile = () => {
    showModal({
      modalTitle: t('modal.attachFile.title'),
      modalType: EModalComponents.CHAT_ATTACH_FILE_DIALOG,
      modalProps: {
        chatId,
        maxFileSize: chatType === ChatTypeEnum.CASE_GROUP ? MAX_GROUP_CHAT_FILE_SIZE : undefined
      }
    })
  }

  const sendAudioMessage = (message: Blob) => {
    const quoteId = actionType === EChatMessageAction.QUOTE ? actionMessage?.id : undefined

    sendNewAudioMessage({
      chatId,
      message,
      quoteId,
      readAll,
      caseId: isP2PView ? undefined : caseId,
      receiverId
    })
    changeMessageType(MessageTypeEnum.TEXT)()
  }

  const onSendMessage = () => {
    if (validation.maxLength(MAX_LENGTH)(plainText) || validation.required()(plainText)) {
      return
    }

    const message = wrapMentions(editorState)

    setEditorStateText('')

    if (actionType === EChatMessageAction.EDIT && actionMessage) {
      endMessageEditing({
        chatId,
        messageId: actionMessage.id,
        message
      })
    } else {
      sendNewMessage({
        chatId,
        message,
        quoteId: actionMessage?.id,
        readAll,
        caseId: isP2PView ? undefined : caseId,
        receiverId
      })
    }
  }

  const onEditorChange = (editorValue: EditorState) => {
    const message = editorValue.getCurrentContent().getPlainText()

    setEditorState(editorValue)

    if (!message.trim()) {
      setShowSuggestions(false)
    }

    if (message !== plainText) {
      sendTypingStart({
        chatId,
        messageId
      })

      sendTypingDone({
        chatId,
        messageId
      })

      updateChatDraft({
        message: actionType === EChatMessageAction.EDIT ? '' : wrapMentions(editorValue),
        chatId,
        quoteMessage: actionType === EChatMessageAction.EDIT ? null : actionMessage
      })
    }
  }

  const keyBindingFn = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === EKeyCodes.ESCAPE && actionType === EChatMessageAction.EDIT && actionMessage) {
      endMessageEditing({
        chatId,
        messageId: actionMessage.id
      })

      setEditorStateText('')
    }

    if (e.key === EKeyCodes.ESCAPE && actionType === EChatMessageAction.QUOTE) {
      cancelQuoting({ chatId })
    }
  }

  const FIELDS = new Map<MessageTypeEnum, () => JSX.Element | null>()
    .set(MessageTypeEnum.AUDIO, () => (
      <ChatMessageAudioRecord
        iconSize={isMobile || caseId ? EIconSize.LG : EIconSize.XXL}
        onAudioEnd={changeMessageType(MessageTypeEnum.TEXT)}
        onAudioSend={sendAudioMessage}
        classes={classes}
      />
    ))
    .set(MessageTypeEnum.TEXT, () => (
      <>
        <DraftEditor
          editorState={editorState}
          onChange={onEditorChange}
          keyBindingFn={baseKeyBindingHandler(keyBindingFn)}
          handleKeyCommand={baseKeyCommandHandler(onSendMessage)}
          classes={classes}
          valueLengthMax={MAX_LENGTH}
          showMaxLength={showMaxLength}
          placeholder={initialPlaceholder}
          disabled={isEditorDisabled}
          helperText={helperText}
          showEmoji={chatStatus === ChatStatusEnum.ACTIVE}
          startAdornment={
            showAttachment && (
              <IconButton
                iconComponent={<AttachIcon />}
                toolTip={t('modal.attachFile.title')}
                disabled={!isAudioRecordEnabled}
                iconSize={EIconSize.MD}
                onClick={onAttachFile}
                classes={{ root: styles.icon }}
              />
            )
          }
          endAdornment={getMapComponent(END_ADORNMENT, chatStatus, {
            isAudioRecordEnabled,
            isSendActionDisabled,
            isDesktop,
            isContact,
            isDormant,
            accountType,
            isOwner,
            isGroupChat: !!caseId,
            changeMessageType,
            onSendMessage,
            onRedirectToCase,
            t
          })}
          plugins={accountPlugins}
          onFocus={hideChatRoomSearchResults}
        />
        <AccountsSuggestions
          open={enableMentions && showSuggestions}
          suggestions={suggestions}
          entryComponent={AccountSuggestion}
          onOpenChange={setShowSuggestions}
          onSearchChange={onSearchChange}
        />
      </>
    ))

  return (
    <div className={cls(styles.rootWrapper, classes?.rootWrapper)}>
      {getMapComponent(FIELDS, messageType)}
    </div>
  )
}

export const ChatFieldWrapper = memo(ChatFieldWrapperView)
