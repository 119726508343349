import React, { memo, useCallback } from 'react'
import cls from 'classnames'

import { EIconSize } from 'enums'
import { ButtonSpinner, Tooltip } from 'App/components'
import { stopPropagation } from 'utils'

import styles from './IconButton.module.scss'

type TIconButtonClasses = 'root'

type TIconButton = {
  iconComponent: JSX.Element
  iconSize: EIconSize
  variant?: 'text' | 'filled' | 'outlined'
  toolTip?: string
  onClick?: () => void
  disabled?: boolean
  classes?: Partial<Record<TIconButtonClasses, string>>
  loading?: boolean
  type?: 'submit' | 'button'
}

const IconButtonView = ({
  iconComponent,
  toolTip,
  disabled,
  iconSize,
  classes,
  loading,
  onClick,
  type = 'button',
  variant = 'text'
}: TIconButton) => {
  const showTooltip = !!toolTip && !disabled

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      stopPropagation(event)
      onClick && onClick()
    },
    [onClick]
  )

  const content = loading ? (
    <ButtonSpinner color={variant === 'filled' ? 'default' : 'secondary'} />
  ) : (
    iconComponent
  )

  return showTooltip ? (
    <Tooltip title={toolTip}>
      <button
        type={type}
        className={cls(styles.root, styles[iconSize], styles[variant], classes?.root)}
        onClick={handleClick}
      >
        {content}
      </button>
    </Tooltip>
  ) : (
    <button
      className={cls(styles.root, styles[iconSize], styles[variant], classes?.root)}
      disabled={disabled}
      onClick={disabled ? undefined : handleClick}
      type={type}
    >
      {content}
    </button>
  )
}

export const IconButton = memo(IconButtonView)
