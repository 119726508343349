import { useCallback } from 'react'
import noop from 'lodash/noop'

import { useMutation } from 'services/query'
import { ITopUpOneTimeCheckoutInfoDTO } from 'interfaces'
import { showModalAction, useAppDispatch } from 'store'
import { api, API, APIData } from 'services/api'
import { EModalComponents } from 'App/containers'

import { ESocketNameSpaces, sockets } from '../../../../../services/webSocket'

type TValues = {
  amount: string
}

type TPayload = {
  tokenAmount: number
  socketSessionId: string
}

const topUpOneTimeCheckoutInfo = async (payload: TPayload) => {
  const { data }: APIData<ITopUpOneTimeCheckoutInfoDTO> = await api.post(
    API.TOP_UP_ONE_TIME,
    payload
  )

  return data
}

export const useRequest = () => {
  const dispatch = useAppDispatch()

  const handleSuccess = useCallback(
    ({ url }: ITopUpOneTimeCheckoutInfoDTO) => {
      window.open(url, '_blank')

      dispatch(
        showModalAction({
          modalType: EModalComponents.TOP_UP_HOLD_ON,
          modalTitle: null,
          closable: false
        })
      )
    },
    [dispatch]
  )

  const {
    mutate,
    isLoading: processing,
    error
  } = useMutation({
    mutationFn: topUpOneTimeCheckoutInfo,
    onSuccess: handleSuccess,
    // disable default toast notification
    onError: noop
  })

  const handleFormSubmit = useCallback(
    ({ amount }: TValues) => {
      mutate({
        tokenAmount: Number(amount),
        socketSessionId: sockets[ESocketNameSpaces.SYSTEM]?.id ?? ''
      })
    },
    [mutate]
  )

  return {
    processing,
    error,
    handleFormSubmit
  }
}
