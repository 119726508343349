import { LegacyRef, ReactNode, forwardRef, useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { LongPressCallback, LongPressDetectEvents, useLongPress } from 'use-long-press'
import cls from 'classnames'
import { Path } from 'history'

import { ENotificationBadgeColor } from 'enums'
import { useAdaptiveLayout, useToggle } from 'App/hooks'
import { isChatRoomNotificationVisible } from 'utils/chat'
import { UnityHubNavItemBadge, TUnityHubNavItemBadgeProps } from 'App/components/UnityHub'
import { ChatPublicIcon } from 'App/components/Chat/ChatPublicIcon'
import { ReactComponent as EyeOffOutlineIcon } from 'assets/icons/EyeOffOutline.svg'

import { ChatMutedIcon } from '../../Chat/ChatMutedIcon'
import { ContextMenu, TContextMenuProps, TruncatedText } from '../../common'

import styles from './UnityHubRoomNavLink.module.scss'

export type TUnityHubRoomNavLinkProps = {
  icon: ReactNode
  name: string
  to: Path

  customBadge?: ReactNode
  isMuted?: boolean
  isInvisible?: boolean
  isPublic?: boolean
  mentionCount?: number
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
} & Pick<TUnityHubNavItemBadgeProps, 'count'> &
  Pick<TContextMenuProps, 'menuItems'>

const UnityHubRoomNavLinkView = (
  {
    to,
    name,
    icon,
    count,
    menuItems,
    isMuted,
    isInvisible,
    isPublic,
    mentionCount,
    customBadge,
    onClick
  }: TUnityHubRoomNavLinkProps,
  ref: LegacyRef<HTMLDivElement>
) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()
  const {
    value: showKebabMenuIcon,
    setValue: setShowKebabMenuIcon,
    toggleOn: toggleOnKebabMenuIcon,
    toggleOff: toggleOffKebabMenuIcon
  } = useToggle()
  const { push } = useHistory()
  const routeMatch = useRouteMatch(to)

  const { value: contextMenuVisible, setValue: setContextMenuVisible } = useToggle()

  const hasMenuItems = useMemo(
    () => !!menuItems?.filter(({ hidden }) => !hidden).length,
    [menuItems]
  )

  const handleHideKebabMenuIcon = useCallback(() => {
    if (!contextMenuVisible) {
      toggleOffKebabMenuIcon()
    }
  }, [contextMenuVisible, toggleOffKebabMenuIcon])

  const onContextMenuVisibleChange = useCallback(
    (visible = false) => {
      setShowKebabMenuIcon(visible)
      setContextMenuVisible(visible)
    },
    [setContextMenuVisible, setShowKebabMenuIcon]
  )

  const onContextMenu = useCallback<LongPressCallback<HTMLDivElement>>(
    (event) => {
      if (event?.cancelable) {
        event.preventDefault()
      }

      onContextMenuVisibleChange(true)
    },
    [onContextMenuVisibleChange]
  )

  const { onMouseLeave, ...longPressBind } = useLongPress<HTMLDivElement>(
    isDesktop ? null : onContextMenu,
    {
      captureEvent: true,
      detect: LongPressDetectEvents.BOTH
    }
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(event)
        return
      }

      push(to)
    },
    [onClick, push, to]
  )

  const shouldShowUnreadCounter = isChatRoomNotificationVisible({
    isMobile,
    mentionCount,
    unreadCount: count
  })

  const shouldShowCustomBadge =
    customBadge && !shouldShowUnreadCounter && !mentionCount && !hasMenuItems

  return (
    <div
      ref={ref}
      className={cls(styles.root, routeMatch?.isExact && styles.active)}
      onClick={handleClick}
      onMouseEnter={isDesktop ? toggleOnKebabMenuIcon : undefined}
      onMouseLeave={isDesktop ? handleHideKebabMenuIcon : onMouseLeave}
      onContextMenu={isDesktop ? undefined : onContextMenu}
      {...longPressBind}
    >
      <span className={styles.wrapper}>
        {icon}
        <TruncatedText text={name} />
        <div
          className={cls(styles.icons, {
            [styles.muted]: isMuted,
            [styles.invisible]: isInvisible
          })}
        >
          {isMuted && <ChatMutedIcon />}
          {isInvisible && <EyeOffOutlineIcon />}
          {isPublic && <ChatPublicIcon />}
        </div>
      </span>
      <div className={styles.end}>
        {showKebabMenuIcon && hasMenuItems ? (
          <ContextMenu
            iconComponent={isDesktop ? undefined : <></>}
            visible={isDesktop ? undefined : contextMenuVisible}
            onVisibleChange={onContextMenuVisibleChange}
            menuItems={menuItems}
            nestIn="body"
          />
        ) : (
          <>
            <UnityHubNavItemBadge variant="icon" icon="@" inline={true} count={mentionCount} />
            {shouldShowUnreadCounter && (
              <UnityHubNavItemBadge
                inline={true}
                count={count}
                color={isMuted || isInvisible ? ENotificationBadgeColor.MUTED : undefined}
              />
            )}
          </>
        )}

        {shouldShowCustomBadge && customBadge}
      </div>
    </div>
  )
}

export const UnityHubRoomNavLink = forwardRef<HTMLDivElement, TUnityHubRoomNavLinkProps>(
  UnityHubRoomNavLinkView
)
