import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PeopleNearbyIcon } from 'assets/icons/PeopleNearby.svg'
import { ReactComponent as MessageTextIcon } from 'assets/icons/MessageText.svg'
import { ReactComponent as WavingHandIcon } from 'assets/icons/Hand.svg'
import { ReactComponent as StreamingIcon } from 'assets/icons/Streaming.svg'

import { useAdaptiveLayout } from '../../../hooks'
import {
  AsideMenuHeaderAction,
  Button,
  EAsideMenuHeaderActionVariant,
  EEmptyListIconSize,
  EmptyList
} from '../../../components'
import { FixedAddButton } from '../../../components/common/FixedAddButton'

import styles from './CommunityEmpyState.module.scss'

export const CommunityEmpyState = () => {
  const { push } = useHistory()

  const { isMobile, isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const handleCreate = () => push('/community/create')

  const createButton = isMobile ? (
    <FixedAddButton icon={<PeopleNearbyIcon />} onClick={handleCreate} />
  ) : (
    <Button onClick={handleCreate}>{t('communities.createCommunity.submitButton')}</Button>
  )

  return (
    <div className={styles.root}>
      <main className={styles.content}>
        <header>
          <h2>{t('communities.createCommunity.title')}</h2>
          <p>{t('communities.createCommunity.description')}</p>
        </header>
        <ul className={styles.list}>
          <li>
            {!isMobile && <MessageTextIcon />}
            <div>
              <h3>
                {isMobile && <MessageTextIcon />}{' '}
                {t('communities.createCommunity.communicationLabel')}
              </h3>
              <p>{t('communities.createCommunity.communicationDescription')}</p>
            </div>
          </li>
          <li>
            {!isMobile && <WavingHandIcon />}
            <div>
              <h3>
                {isMobile && <WavingHandIcon />} {t('communities.createCommunity.networkingLabel')}
              </h3>
              <p>{t('communities.createCommunity.networkingDescription')}</p>
            </div>
          </li>
          <li>
            {!isMobile && <StreamingIcon />}
            <div>
              <h3>
                {isMobile && <StreamingIcon />} {t('communities.createCommunity.streamingLabel')}
              </h3>
              <p>{t('communities.createCommunity.streamingDescription')}</p>
            </div>
          </li>
        </ul>
      </main>
      {!isMobile ? (
        <aside className={styles.aside}>
          {isDesktop ? (
            <EmptyList
              iconSize={EEmptyListIconSize.SM}
              icon={<PeopleNearbyIcon />}
              text={t('communities.createCommunity.asideSubtitle')}
            >
              {createButton}
            </EmptyList>
          ) : (
            <div className={styles.topBar}>
              <AsideMenuHeaderAction
                variant={EAsideMenuHeaderActionVariant.BUTTON_BLOCK}
                title={t('communities.createCommunity.asideSubtitle')}
                icon={<PeopleNearbyIcon />}
                buttonText={t('communities.createCommunity.submitButton')}
                onClick={handleCreate}
                classes={{ title: styles.topBarTitle }}
              />
            </div>
          )}
        </aside>
      ) : (
        createButton
      )}
    </div>
  )
}
