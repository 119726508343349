import { Skeleton, Row } from 'antd'

import styles from './UnityHubInfoSkeleton.module.scss'

export const UnityHubInfoSkeleton = () => (
  <div className={styles.root}>
    <div className={styles.images}>
      <Skeleton active avatar={false} paragraph={false} className={styles.cover} />
      <Skeleton.Avatar active className={styles.avatar} />
    </div>

    <Row wrap={false} justify="space-between">
      <Skeleton active avatar={false} paragraph={false} className={styles.name} />

      <Skeleton active avatar={false} paragraph={false} className={styles.infoItem} />
    </Row>

    <div className={styles.userInfo}>
      <Row align="middle">
        <Skeleton.Avatar active className={styles.userInfoAvatar} />
        <Skeleton active avatar={false} paragraph={false} className={styles.userInfoName} />
      </Row>
    </div>
  </div>
)
