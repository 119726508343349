import cls from 'classnames'

import { parseSpaces } from 'utils'

import styles from './Divider.module.scss'

type TDividerProps = PropsWithClassName<PropsWithSpaces> & {
  variant?: 'horizontal' | 'vertical'
}

export const Divider = ({ className, spaces, variant = 'horizontal' }: TDividerProps) => (
  <div className={cls(styles.root, styles[variant], className)} style={parseSpaces(spaces)} />
)
