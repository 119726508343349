import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { CaseMemberStatusesEnum, ErrorCodesEnum } from '@medentee/enums'

import { API, api, APIData } from 'services/api'
import { State } from 'redux/rootReducer'
import {
  getCaseFilesRequest,
  getCaseMembersRequest,
  hideModalAction,
  LEAVE_MEMBER_REQUEST,
  leaveMembersError,
  REMOVE_MEMBER_FROM_CASE_REQUEST,
  removeMemberFromCaseError,
  removeMemberFromCaseSuccess,
  TLeaveMembersRequest,
  TRemoveMemberFromCaseRequest
} from 'store'
import { parseError, isMatchErrorCode } from 'utils'
import history from 'utils/history'
import { ICaseMembersDTO } from 'interfaces'
import { handleError } from 'api/utils'

import { caseMembersNormalize } from './caseMembers.normalization'
import { TCaseMemberStatusChangedRequest, TGetCaseMembersRequest } from './caseMembers.types'
import {
  GET_CASE_MEMBERS_REQUEST,
  getCaseMembersError,
  getCaseMembersSuccess,
  leaveMembersSuccess,
  CASE_MEMBER_STATUS_CHANGED_REQUEST,
  caseMemberStatusChangedToActiveSuccess,
  caseMemberStatusChangedToInactiveSuccess,
  caseMemberStatusChangedSuccess
} from './caseMembers.actions'

function* getCaseMembersSaga({ payload: { caseId } }: TGetCaseMembersRequest) {
  try {
    const { data }: APIData<ICaseMembersDTO> = yield call(api.get, API.CASE_MEMBERS(caseId))

    const activeMembers = caseMembersNormalize(data.active)
    const inactiveMembers = !!data.inactive.length
      ? caseMembersNormalize(data.inactive)
      : {
          list: {},
          ids: [],
          accountList: {}
        }

    yield put(getCaseMembersSuccess({ activeMembers, inactiveMembers }))
  } catch (e) {
    yield put(getCaseMembersError(e))
    yield handleError(e)
  }
}

function* leaveMembersSaga({ payload: { caseId } }: TLeaveMembersRequest) {
  try {
    yield call(api.post, API.CASES_LEAVE(caseId))

    yield put(leaveMembersSuccess())
    yield put(hideModalAction())
    yield history.push('/cases')
  } catch (e) {
    if (isMatchErrorCode(e, ErrorCodesEnum.IS_NOT_ACTIVE_CASE_MEMBER)) {
      yield put(hideModalAction())
      yield put(leaveMembersError(null))
      yield history.push('/cases')
    } else {
      yield put(leaveMembersError(parseError(e)))
    }
  }
}

function* removeMemberFromCaseSaga({
  payload: { caseId, memberId }
}: TRemoveMemberFromCaseRequest) {
  try {
    yield call(api.delete, API.CASES_KICK(caseId, memberId))

    yield put(getCaseMembersRequest({ caseId }))
    yield put(removeMemberFromCaseSuccess())
    yield put(hideModalAction())
    yield put(getCaseFilesRequest({ caseId }))
  } catch (e) {
    yield put(removeMemberFromCaseError(e))
  }
}

function* changeCaseMemberStatusSaga({
  payload: {
    payload: { caseMemberId, status, caseId }
  }
}: TCaseMemberStatusChangedRequest) {
  const selectedCaseId: string | undefined = yield select((state: State) => state.caseView.data?.id)

  const CHANGE_CASE_MEMBER_STATUS_ACTION = {
    [CaseMemberStatusesEnum.ACTIVE]: caseMemberStatusChangedToActiveSuccess,
    [CaseMemberStatusesEnum.LEFT]: caseMemberStatusChangedToInactiveSuccess,
    [CaseMemberStatusesEnum.INVITE_PENDING]: caseMemberStatusChangedSuccess,
    [CaseMemberStatusesEnum.INVITE_REJECTED]: caseMemberStatusChangedSuccess
  } as const

  try {
    if (CHANGE_CASE_MEMBER_STATUS_ACTION[status] && selectedCaseId === caseId) {
      yield put(CHANGE_CASE_MEMBER_STATUS_ACTION[status]({ caseMemberId, status }))
    }
  } catch (e) {
    yield handleError(e)
  }
}

export function* caseMembersSaga() {
  yield takeLatest(LEAVE_MEMBER_REQUEST, leaveMembersSaga)
  yield takeLatest(REMOVE_MEMBER_FROM_CASE_REQUEST, removeMemberFromCaseSaga)
  yield takeLatest(GET_CASE_MEMBERS_REQUEST, getCaseMembersSaga)
  yield takeEvery(CASE_MEMBER_STATUS_CHANGED_REQUEST, changeCaseMemberStatusSaga)
}
