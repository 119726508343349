import { PlatformInvitationType } from '@medentee/enums'

import { API, api } from '../services/api'
import { IInvitationLinkDTO } from '../interfaces'

export const getInvitationLink = async () =>
  await api.get<IInvitationLinkDTO | null>(API.INVITATIONS_BY_LINK)
export const createInvitationLink = async () => await api.post(API.INVITATIONS_BY_LINK)
export const deleteInvitationLink = async () => await api.delete(API.INVITATIONS_BY_LINK)

export type TUpdateInvitationLinkSettingsPayload = { maxRegistrants: number }
export const updateInvitationLinkSettings = async (payload: TUpdateInvitationLinkSettingsPayload) =>
  await api.patch(API.INVITATIONS_BY_LINK, payload)

export type TSendInviteUsersByEmailPayload = {
  emails: string[]
  type: PlatformInvitationType
}

export const sendInviteUsersByEmail = async (data: TSendInviteUsersByEmailPayload) =>
  await api.post(API.INVITATIONS_BY_EMAIL, data)

export const businessAccountSendCode = async (email: string) => {
  const { data } = await api.post<string>(API.BUSINESS_ACCOUNT_SEND_CODE, { email })
  return data
}

type TBusinessAccountValidateCodePayload = {
  email: string
  token: string
}

export const businessAccountValidateCode = async ({
  email,
  token
}: TBusinessAccountValidateCodePayload) =>
  await api.post(API.BUSINESS_ACCOUNT_VALIDATE_CODE, { email, token })

type TBusinessAccountRegistrationPayload = {
  email: string
  token: string
  companyName: string
  size: string | number
  countryCode: string
  cityId: string
  zip: string
  addressLine1: string
  addressLine2: string
  businessAccountTypeId: string
}

export const businessAccountRegistration = async ({
  email,
  token,
  companyName,
  size,
  countryCode,
  cityId,
  zip,
  addressLine1,
  addressLine2,
  businessAccountTypeId
}: TBusinessAccountRegistrationPayload) => {
  const { data } = await api.post(API.BUSINESS_ACCOUNT_REGISTRATION, {
    email,
    token,
    companyName,
    size,
    countryCode,
    cityId,
    zip,
    addressLine1,
    addressLine2,
    businessAccountTypeId
  })

  return data
}

export const invitationsByEmailWithdraw = async (token: string) => {
  const { data } = await api.delete(API.INVITATIONS_BY_EMAIL_WITHDRAW(token))

  return data
}

export const hideEventAttendeeInvitation = async (token: string) =>
  await api.delete(API.INVITATIONS_BY_EMAIL_HIDE(token))
