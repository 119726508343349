import { FC, useMemo } from 'react'
import { MeetingInviteStatusEnum } from '@medentee/enums'
import groupBy from 'lodash/groupBy'
import { useTranslation } from 'react-i18next'

import { TMeetingInvite } from 'store'
import { Status, TStatusProps } from 'App/components/Meetings/common/Status'
import { useAdaptiveLayout } from 'App/hooks'
import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'
import { ReactComponent as CheckCircleOutlineIcon } from 'assets/icons/CheckCircleOutline.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/Clock.svg'

import styles from './ParticipantsStatuses.module.scss'

export type TParticipantsStatusesProps = Pick<TStatusProps, 'variant'> & {
  invites: TMeetingInvite[]
}

export const ParticipantsStatuses: FC<TParticipantsStatusesProps> = ({ invites, variant }) => {
  const { isMobile } = useAdaptiveLayout()

  const { t } = useTranslation()

  const groupedInvites = useMemo(() => groupBy(invites, 'status'), [invites])

  const pendingInvitesNumber = groupedInvites[MeetingInviteStatusEnum.PENDING]?.length ?? 0
  const acceptedInvitesNumber = groupedInvites[MeetingInviteStatusEnum.ACCEPTED]?.length ?? 0
  const rejectedInvitesNumber = groupedInvites[MeetingInviteStatusEnum.REJECTED]?.length ?? 0

  return (
    <div className={styles.root}>
      <Status
        variant={variant}
        label={
          !isMobile ? (
            t('meetings.participantStatus.ACCEPTED', { number: acceptedInvitesNumber })
          ) : (
            <>
              <CheckCircleOutlineIcon /> {acceptedInvitesNumber}
            </>
          )
        }
        status={MeetingInviteStatusEnum.ACCEPTED}
      />
      <Status
        variant={variant}
        label={
          !isMobile ? (
            t('meetings.participantStatus.PENDING', { number: pendingInvitesNumber })
          ) : (
            <>
              <ClockIcon /> {pendingInvitesNumber}
            </>
          )
        }
        status={MeetingInviteStatusEnum.PENDING}
      />
      <Status
        variant={variant}
        label={
          !isMobile ? (
            t('meetings.participantStatus.REJECTED', { number: rejectedInvitesNumber })
          ) : (
            <>
              <CloseCircleOutlinedIcon /> {rejectedInvitesNumber}
            </>
          )
        }
        status={MeetingInviteStatusEnum.REJECTED}
      />
    </div>
  )
}
