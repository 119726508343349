import { ReactElement, useCallback } from 'react'
import { Col, Row } from 'antd'
import cls from 'classnames'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EIconSize } from 'enums'
import { isBusinessAccountSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { Button, IconButton, Tooltip } from 'App/components/common'
import { useAdaptiveLayout } from 'App/hooks'
import { FixedAddButton } from 'App/components/common/FixedAddButton'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { ReactComponent as FormatListBulletedIcon } from 'assets/icons/FormatListBulleted.svg'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'

import { EModalComponents } from '../../../containers'

import styles from './OrganizationContentHeader.module.scss'

type TOrganizationContentHeaderProps = {
  icon: JSX.Element
  title: string

  organizationId?: string
  iconVariant?: 'circle' | 'square'
  iconColor?: 'secondary' | 'accent'
  shouldShowIcon?: boolean
  shouldShowCreateDepartment?: boolean
  shouldShowBack?: boolean
  buttonIcon?: JSX.Element
  buttonText?: string
  onBack?: () => void
  onClick?: () => void
  customAction?: ReactElement
  buttonContainer?: React.ComponentType
}

export const OrganizationContentHeader = ({
  buttonIcon,
  buttonText,
  icon,
  title,
  onBack,
  onClick,
  customAction,
  organizationId,
  iconVariant = 'square',
  iconColor = 'secondary',
  shouldShowCreateDepartment = true,
  shouldShowIcon = true,
  shouldShowBack = true,
  buttonContainer: ButtonContainer
}: TOrganizationContentHeaderProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { isMobile, isDesktop } = useAdaptiveLayout()
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const { push } = useHistory()

  const showCreateDepartment = shouldShowCreateDepartment && organizationId && isBusinessAccount

  const handleBack = useCallback(() => {
    push(isBusinessAccount ? '/organization' : '/organizations')
  }, [isBusinessAccount, push])

  const { getOnClickHandler: getCreateDepartmentHandler } = useOrganizationSubscription({
    variant: 'upgrade',
    organizationId,
    callback: () => {
      organizationId &&
        dispatch(
          showModalAction({
            modalTitle: t('modal.createOrganizationDepartment.title'),
            modalType: EModalComponents.CREATE_DEPARTMENT,
            modalProps: {
              id: organizationId
            }
          })
        )
    }
  })

  return (
    <Row className={styles.root} align="middle" justify="space-between">
      <Col>
        <Row align="middle">
          {!isDesktop && shouldShowBack && (
            <IconButton
              classes={{ root: styles.action }}
              iconComponent={<FormatListBulletedIcon />}
              iconSize={EIconSize.SM}
              onClick={onBack ?? handleBack}
            />
          )}
          {customAction}
          {shouldShowIcon && (
            <div className={cls(styles.icon, styles[iconVariant], styles[iconColor])}>{icon}</div>
          )}
          <div className={styles.title}>{title}</div>
        </Row>
      </Col>

      {showCreateDepartment && (
        <Col>
          <Tooltip title={t('organizations.createDepartmentTooltip')}>
            <Button
              className={styles.createDepartmentButton}
              onClick={getCreateDepartmentHandler()}
            >
              <AccountMultiplePlusOutlineIcon />
            </Button>
          </Tooltip>
        </Col>
      )}

      {isMobile &&
        buttonIcon &&
        (ButtonContainer ? (
          <ButtonContainer>
            <FixedAddButton icon={buttonIcon} onClick={onClick} />
          </ButtonContainer>
        ) : (
          <FixedAddButton icon={buttonIcon} onClick={onClick} />
        ))}
      {!isMobile && buttonText && (
        <Col>
          {ButtonContainer ? (
            <ButtonContainer>
              <Button className={styles.button} size="xs" onClick={onClick} icon={<PlusIcon />}>
                {buttonText}
              </Button>
            </ButtonContainer>
          ) : (
            <Button className={styles.button} size="xs" onClick={onClick} icon={<PlusIcon />}>
              {buttonText}
            </Button>
          )}
        </Col>
      )}
    </Row>
  )
}
