import { normalize, schema } from 'normalizr'

import {
  INotificationsCommunity,
  INotificationsGeneralChatsPerContact,
  INotificationsOrganization,
  INotificationsProtocolDTO,
  INotificationsProtocolUserDTO
} from 'interfaces'
import { TNotificationsGeneralNormalized, TNotificationsGeneralNormalizeResult } from 'store'

import {
  TNormalizedOrganizationNotifications,
  TNormalizedOrganizationsNotifications,
  TNotificationsProtocolNormalized,
  TNotificationsProtocolNormalizeResult,
  TNotificationsProtocolUsersNormalized,
  TNotificationsProtocolUsersNormalizeResult
} from './notifications.types'

const listSchema = new schema.Entity('list', {}, { idAttribute: 'chatId' })
const ids = new schema.Array(listSchema)

export const generalNotificationNormalize = (
  originalData: INotificationsGeneralChatsPerContact[] = []
): TNotificationsGeneralNormalized => {
  const { entities, result }: TNotificationsGeneralNormalizeResult = normalize(originalData, ids)

  return {
    list: entities.list || {},
    ids: result || []
  }
}

const protocolUsersListSchema = new schema.Entity('list', {}, { idAttribute: 'id' })
const protocolUsersIds = new schema.Array(protocolUsersListSchema)

export const notificationProtocolUsersNormalize = (
  originalData: INotificationsProtocolUserDTO[] = []
): TNotificationsProtocolUsersNormalized => {
  const { entities, result }: TNotificationsProtocolUsersNormalizeResult = normalize(
    originalData,
    protocolUsersIds
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

const protocolListSchema = new schema.Entity('list', {}, { idAttribute: 'id' })
const protocolIds = new schema.Array(protocolListSchema)

export const notificationProtocolNormalize = (
  originalData: INotificationsProtocolDTO[] = []
): TNotificationsProtocolNormalized => {
  const { entities, result }: TNotificationsProtocolNormalizeResult = normalize(
    originalData,
    protocolIds
  )

  return {
    list: entities.list || {},
    ids: result || []
  }
}

export const organizationNotificationsNormalize = (
  organization: INotificationsOrganization
): TNormalizedOrganizationNotifications => {
  let totalUnreadCount = organization.invitesToAdmin + organization.invitesToOrganization

  const chats = organization.chats.reduce(
    (chatsRes, { id, unreadCount, mutedUntil, mentionCount }) => {
      if (!mutedUntil) {
        totalUnreadCount = totalUnreadCount + unreadCount
      }

      totalUnreadCount = totalUnreadCount + mentionCount

      return {
        ...chatsRes,
        [id]: {
          count: unreadCount,
          mentionCount
        }
      }
    },
    {}
  )

  return {
    id: organization.id,
    invitesToAdmin: organization.invitesToAdmin,
    invitesToOrganization: organization.invitesToOrganization,
    chats,
    totalUnreadCount
  }
}

export const organizationsNotificationsNormalize = (
  organizations: INotificationsOrganization[]
) => {
  const totalUnreadCount = organizations.filter(
    (item) =>
      item.invitesToAdmin ||
      item.invitesToOrganization ||
      item.chats.some((value) => (!value.mutedUntil && value.unreadCount) || value.mentionCount)
  ).length

  return organizations.reduce<TNormalizedOrganizationsNotifications>(
    (res, item) => {
      const organization = organizationNotificationsNormalize(item)

      return {
        ...res,
        list: {
          ...res.list,
          [item.id]: organization
        },
        totalInvitesToOrganization:
          res.totalInvitesToOrganization + organization.invitesToOrganization
      }
    },
    {
      totalUnreadCount,
      totalInvitesToOrganization: 0,
      list: {}
    }
  )
}

const unityHubItemNotificationsNormalize = <E extends INotificationsCommunity>(entity: E) => {
  let totalUnreadCount = 0

  const chats = entity.chats.reduce((chatsRes, { id, unreadCount, mutedUntil, mentionCount }) => {
    if (!mutedUntil) {
      totalUnreadCount = totalUnreadCount + unreadCount
    }

    totalUnreadCount = totalUnreadCount + mentionCount + (entity.broadcasts.length > 0 ? 1 : 0)

    return {
      ...chatsRes,
      [id]: {
        count: unreadCount,
        mentionCount
      }
    }
  }, {})

  return {
    id: entity.id,
    chats,
    totalUnreadCount,
    live: entity.broadcasts.length > 0
  }
}

export const unityHubNotificationsNormalize = <E extends INotificationsCommunity>(
  entities: E[] = []
) => {
  const totalUnreadCount = entities.filter(
    (item) =>
      item.broadcasts.length > 0 ||
      item.chats.some((value) => (!value.mutedUntil && value.unreadCount) || value.mentionCount)
  ).length

  return entities.reduce(
    (res, item) => {
      const normalizedItem = unityHubItemNotificationsNormalize<E>(item)

      return {
        ...res,
        list: {
          ...res.list,
          [item.id]: normalizedItem
        }
      }
    },
    {
      totalUnreadCount,
      list: {}
    }
  )
}
