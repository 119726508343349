import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { TFunction } from 'i18next'

import { useIsMutating } from 'services/query'
import { getMapComponent, validation } from 'utils'
import { MAX_GROUP_CHAT_PARTICIPANTS, MAX_LENGTH_50 } from 'globalConstants'
import { useAdaptiveLayout } from 'App/hooks'
import { Button, TruncatedText } from 'App/components'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'

import { ESteps, STEP_KEYS } from '../ChatCreateGroup.utils'
import { useChatCreateGroupContext } from '../ChatCreateGroupContext'

import styles from './ChatCreateGroupHeader.module.scss'

type TChatCreateGroupHeaderProps = {
  onSubmit: () => void
}

type TTitleMapProps = {
  chatName: string
  selectedNumber: number
  t: TFunction
}

const TITLE_MAP = new Map<ESteps, (props: TTitleMapProps) => JSX.Element>()
  .set(ESteps.SELECT_MEMBERS, ({ selectedNumber, t }) => (
    <Trans t={t} i18nKey="chat.createGroupChat.header.title_SELECT_MEMBERS">
      Select members
      <span className={styles.count}>
        {{ selectedNumber }}/{{ limit: MAX_GROUP_CHAT_PARTICIPANTS }}
      </span>
    </Trans>
  ))
  .set(ESteps.CREATE, ({ t }) => (
    <TruncatedText text={t('chat.createGroupChat.header.title_CREATE')} />
  ))
  .set(ESteps.EDIT, ({ t }) => <>{t('chat.createGroupChat.header.title_EDIT')}</>)

export const ChatCreateGroupHeader = ({ onSubmit }: TChatCreateGroupHeaderProps) => {
  const { isMobile } = useAdaptiveLayout()

  const { goBack } = useHistory()

  const { t } = useTranslation()

  const { selectedMembers, step, chatName, chatMembersTotal, setStep } = useChatCreateGroupContext()

  const processing = Boolean(useIsMutating({ mutationKey: ['create-group-chat'] }))
  const updateProcessing = Boolean(useIsMutating({ mutationKey: ['update-group-chat'] }))

  const selectedNumber = selectedMembers.length

  const createUpdateStepDisabled =
    (step === ESteps.CREATE || step === ESteps.EDIT) &&
    Boolean(validation.required()(chatName) || validation.maxLength(MAX_LENGTH_50)(chatName))

  const selectedMembersStepLimitExceeded =
    step === ESteps.SELECT_MEMBERS && selectedNumber > MAX_GROUP_CHAT_PARTICIPANTS

  const editStepLimitExceeded =
    step === ESteps.EDIT && selectedNumber + chatMembersTotal > MAX_GROUP_CHAT_PARTICIPANTS

  const selectedMembersLimitExceeded = selectedMembersStepLimitExceeded || editStepLimitExceeded

  const disabled =
    selectedMembersLimitExceeded || createUpdateStepDisabled || processing || updateProcessing

  const getTitle = useCallback(
    () => (
      <div className={styles.title}>
        {getMapComponent(TITLE_MAP, step, { selectedNumber, chatName, t })}
      </div>
    ),
    [chatName, selectedNumber, step, t]
  )

  const handleBack = () => {
    if (step === ESteps.SELECT_MEMBERS || step === ESteps.EDIT) {
      goBack()
      return
    }

    setStep(ESteps.SELECT_MEMBERS)
  }

  const handleNext = () => {
    if (step === ESteps.SELECT_MEMBERS) {
      setStep(ESteps.CREATE)
      return
    }

    onSubmit()
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerInner}>
        <Button
          className={styles.button}
          variant="inline"
          size="md"
          icon={<ChevronLeftIcon />}
          onClick={handleBack}
          disabled={processing || updateProcessing}
        >
          {t('chat.createGroupChat.header.backButton')}
        </Button>

        {!isMobile && getTitle()}

        <Button
          className={styles.button}
          variant="inline"
          size="md"
          onClick={handleNext}
          disabled={disabled}
        >
          {t('chat.createGroupChat.header.submitButton', { context: STEP_KEYS[step] })}
        </Button>
      </div>

      {isMobile && getTitle()}
    </div>
  )
}
