import { ReactNode } from 'react'
import cls from 'classnames'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'
import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'
import { ReactComponent as GlobeIcon } from 'assets/icons/Globe.svg'
import { ReactComponent as ShowcaseIcon } from 'assets/icons/Showcase.svg'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as PeopleNearbyIcon } from 'assets/icons/PeopleNearby.svg'
import { ReactComponent as EventsIcon } from 'assets/icons/Events.svg'

import styles from './DetailList.module.scss'

type TDisplayVariant = 'row' | 'column'

export type TDetailListClasses = 'root'

export type TDetailListTitle =
  | 'organization'
  | 'cases'
  | 'cloud'
  | 'connector'
  | 'showcases'
  | 'communities'
  | 'events'
export type TDetailList = Partial<Record<TDetailListTitle, string[]>>

export type TDetailListProps = {
  list: string[] | TDetailList

  addonBefore?: ReactNode
  variant?: TDisplayVariant
  classes?: Partial<Record<TDetailListClasses, string>>
}

const TITLE_ICON_MAP = new Map<TDetailListTitle, () => JSX.Element>()
  .set('organization', () => <AccountGroupIcon />)
  .set('cases', () => <CaseIcon />)
  .set('cloud', () => <CloudUploadIcon />)
  .set('connector', () => <GlobeIcon />)
  .set('showcases', () => <ShowcaseIcon />)
  .set('communities', () => <PeopleNearbyIcon />)
  .set('events', () => <EventsIcon />)

export const DetailList = ({ list, classes, addonBefore, variant = 'row' }: TDetailListProps) => {
  const { t } = useTranslation()

  return (
    <>
      {addonBefore && <div className={styles.addonBefore}>{addonBefore}</div>}

      <div className={cls(styles.root, [styles[variant]], classes?.root)}>
        {isArray(list)
          ? list.map((item, index) => (
              <div key={index} className={styles.property}>
                {item}
              </div>
            ))
          : Object.keys(list).map((key) => (
              <div key={key}>
                <div className={styles.title}>
                  {getMapComponent(TITLE_ICON_MAP, key)}
                  {t(`common.subscriptionInfoCard.detailsList.${key as TDetailListTitle}`)}
                </div>

                {list[key as TDetailListTitle]?.map((item, index) => (
                  <div key={index} className={styles.property}>
                    {item}
                  </div>
                ))}
              </div>
            ))}
      </div>
    </>
  )
}
