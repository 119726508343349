import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAdaptiveLayout } from 'App/hooks'
import { Button as ButtonInitial } from 'App/components'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'
import { ReactComponent as MonitorIcon } from 'assets/icons/Monitor.svg'
import { ReactComponent as TabletIcon } from 'assets/icons/Tablet.svg'
import { ReactComponent as CellPhoneIcon } from 'assets/icons/CellPhone.svg'

import styles from './Button.module.scss'

type TButtonProps = {
  isOnCurrentCall: boolean
  ableToSwitch: boolean
  onClick: () => void

  resolveIcon?: ReactElement
  resolveText?: string
}

export const Button = ({
  isOnCurrentCall,
  ableToSwitch,
  resolveText,
  onClick,
  resolveIcon = <PlusIcon />
}: TButtonProps) => {
  const { isMobile, isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const icon = useMemo(() => {
    if (!ableToSwitch) {
      return resolveIcon
    }

    if (isMobile) {
      return <CellPhoneIcon />
    }

    if (!isDesktop) {
      return <TabletIcon />
    }

    return <MonitorIcon />
  }, [ableToSwitch, isDesktop, isMobile, resolveIcon])

  if (isOnCurrentCall) {
    return null
  }

  return (
    <ButtonInitial
      variant={isMobile ? 'text' : 'primary'}
      className={styles.root}
      icon={icon}
      size="xs"
      onClick={onClick}
    >
      {ableToSwitch ? t('meetings.switchButton') : resolveText ?? t('meetings.joinButton')}
    </ButtonInitial>
  )
}
