import { FC } from 'react'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, ConfirmationModal } from 'App/components'
import { TChatDeleteAllMessagesConfirmContainerProps } from 'App/containers'

import styles from './ChatDeleteAllMessagesConfirm.module.scss'

export type TChatDeleteAllMessagesConfirmOwnProps = {
  chatId: string
}

type TChatDeleteAllMessagesConfirmProps = TChatDeleteAllMessagesConfirmOwnProps &
  TChatDeleteAllMessagesConfirmContainerProps

export const ChatDeleteAllMessagesConfirm: FC<TChatDeleteAllMessagesConfirmProps> = ({
  chatId,
  error,
  loading,
  deleteAllMessages,
  removeErrorNotification
}) => {
  const { t } = useTranslation()

  const deleteHandler = () => {
    deleteAllMessages({
      chatId
    })
  }

  return (
    <ConfirmationModal
      content={t('modal.deleteAllMessagesConfirm.content')}
      controls={
        <Button
          className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
          onClick={deleteHandler}
          loading={loading}
        >
          {t('modal.deleteAllMessagesConfirm.submitButton')}
        </Button>
      }
      error={error}
      resetError={() => removeErrorNotification()}
    />
  )
}
