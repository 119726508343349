import { useState } from 'react'
import cls from 'classnames'

import { VIDEO_WHITELIST } from 'globalConstants'
import { getExtensionWithoutDot } from 'utils/files'
import { useAdaptiveLayout } from 'App/hooks'
import { VideoReadinessStatus, TVideoReadinessStatusProps } from 'App/containers'
import { ReactComponent as PlayIcon } from 'assets/icons/Play.svg'

import { FileIcon } from '../../FileIcon'
import { EImagePreviewVariant, ImagePreview, TImagePreviewProps } from '../ImagePreview'

import styles from './FileImage.module.scss'

export type TFileImageSize = 'lg' | 'md' | 'sm' | 'xs' | 'xss' | 'custom'

export type TFileImageProps = PropsWithClasses<
  'root' | 'videoStatus' | 'fileIcon',
  {
    fileName: string
    extension: string

    size?: TFileImageSize
    fileId?: string
    thumbnailUrl?: string | null
    hidePlayIcon?: boolean

    preview?: boolean
    inCase?: boolean
    className?: string
    imagePreviewSize?: TImagePreviewProps['size']
    variant?: TVideoReadinessStatusProps['variant']
  }
>

export const FileImage = ({
  fileName,
  extension,
  thumbnailUrl,
  classes,
  fileId,
  size = 'lg',
  hidePlayIcon = false,
  preview = false,
  inCase = false,
  imagePreviewSize,
  variant,
  className
}: TFileImageProps) => {
  const [imgFailed, setImgFailed] = useState(false)
  const { isDesktop } = useAdaptiveLayout()
  const handelErrorLoadImage = () => setImgFailed(true)

  const isShowPlayIcon =
    VIDEO_WHITELIST.includes(getExtensionWithoutDot(extension)) && !hidePlayIcon

  if (preview && thumbnailUrl && !imgFailed) {
    return (
      <ImagePreview
        alt={fileName}
        src={thumbnailUrl}
        size={imagePreviewSize}
        className={styles.thumbnail}
        onError={handelErrorLoadImage}
        variant={inCase ? EImagePreviewVariant.CASE : undefined}
      />
    )
  }

  return (
    <div className={cls(styles.root, styles[`root-${size}`], classes?.root, className)}>
      {thumbnailUrl && !imgFailed ? (
        <>
          <img
            className={styles.thumbnail}
            src={thumbnailUrl}
            alt={fileName}
            onError={handelErrorLoadImage}
          />

          {isShowPlayIcon && (
            <div className={styles.play}>
              <PlayIcon className={styles.playIcon} />
            </div>
          )}
        </>
      ) : (
        <FileIcon
          className={cls(styles.fileIcon, styles[`fileIcon-${size}`], classes?.fileIcon)}
          extension={extension}
        />
      )}

      {fileId && (
        <VideoReadinessStatus
          className={cls(styles.videoStatus, classes?.videoStatus)}
          fileId={fileId}
          size={isDesktop ? 'md' : 'sm'}
          variant={variant}
        />
      )}
    </div>
  )
}
