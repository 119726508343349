import { Location } from 'history'
import { useTranslation } from 'react-i18next'

import history from 'utils/history'
import { Button } from 'App/components/common/Button'

import { ConfirmationModal } from '../common/ConfirmationModal'

type TLeavePageConfirmProps = {
  onConfirm: () => Promise<void>
  location: Location
  hideModal: () => void
}

export const LeavePageConfirm = ({ location, hideModal, onConfirm }: TLeavePageConfirmProps) => {
  const { t } = useTranslation()

  const onClick = () => {
    onConfirm().then(() => {
      history.push(location)
      hideModal()
    })
  }

  return (
    <ConfirmationModal
      content={t('modal.leavePageConfirm.content')}
      controls={<Button onClick={onClick}>{t('modal.leavePageConfirm.submitButton')}</Button>}
    />
  )
}
