import { useCallback, useState } from 'react'
import cls from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { getMapComponent } from 'utils'
import {
  CheckableList,
  EFilterTypes,
  FilterPopoverFooter,
  RangePicker,
  TOption,
  TRangePickerValue
} from 'App/components'

import { TFilterValue } from '../useFilters'

import styles from './FilterPopoverContent.module.scss'

type TFilterContentType = keyof typeof EFilterTypes

export type TFilterPopoverContentProps = {
  filterKey: string
  contentType: TFilterContentType
  value: TFilterValue
  onChange: (key: string, value: TFilterValue) => void

  onReset?: () => void
  filters?: TOption[]
}

type TContentMapProps = Pick<TFilterPopoverContentProps, 'value' | 'filters'> & {
  onChange: React.Dispatch<React.SetStateAction<TFilterValue>>
}

const CONTENT_MAP = new Map<TFilterContentType, (props: TContentMapProps) => JSX.Element>()
  .set('DATE', ({ value, onChange }) => (
    <RangePicker value={value as TRangePickerValue} onChange={onChange} />
  ))
  .set('CHECKBOX', ({ filters, value, onChange }) => (
    <>
      {filters && (
        <div className={styles.list}>
          <CheckableList items={filters} value={(value as string[]) ?? []} onChange={onChange} />
        </div>
      )}
    </>
  ))

export const FilterPopoverContent = ({
  contentType,
  filters,
  filterKey,
  value,
  onChange,
  onReset
}: TFilterPopoverContentProps) => {
  const isDateFilter = contentType === 'DATE'
  const [filterValue, setFilterValue] = useState<TFilterValue>(value ?? [])

  const handleConfirm = useCallback(() => {
    onChange(filterKey, filterValue)
  }, [filterValue, filterKey, onChange])

  const handleReset = useCallback(() => {
    setFilterValue([])

    if (onReset) {
      onReset()
    }
  }, [onReset])

  return (
    <div className={cls(styles.root, isDateFilter && styles.rootDate)}>
      {getMapComponent(CONTENT_MAP, contentType, {
        filters,
        value: filterValue,
        onChange: setFilterValue
      })}

      <FilterPopoverFooter
        className={cls(styles.buttons, isDateFilter && styles.buttonsDate)}
        disableReset={!filterValue || isEmpty(filterValue)}
        onConfirm={handleConfirm}
        onReset={handleReset}
      />
    </div>
  )
}
