import cls from 'classnames'
import { Skeleton } from 'antd'

import { ChatFieldWrapper } from 'App/components'
import { TChatFieldContainerProps } from 'App/containers'

import styles from './ChatFieldCase.module.scss'

export type TChatFieldCaseProps = TChatFieldContainerProps

export const ChatFieldCase = ({
  actionMessage,
  actionType,
  readAll,
  accountType,
  isContact,
  draftMessage,
  isLockedCase,
  chatStatus,
  receiverId,
  chatId,
  caseId,
  ownerId,
  receiverAccountType,
  loading,
  isDormant,
  chatType,
  inactive,
  isCoworker,
  mentionedAccounts,
  mentionedDepartments,
  sendTypingStart,
  sendNewMessage,
  sendTypingDone,
  sendNewAudioMessage,
  endMessageEditing,
  cancelQuoting,
  showModal,
  updateChatDraft
}: TChatFieldCaseProps) => (
  <Skeleton
    active
    className={cls(styles.skeleton, styles.rootWrapper)}
    title={{ width: '100%', className: styles.skeletonTitle }}
    paragraph={false}
    loading={loading}
  >
    <ChatFieldWrapper
      receiverAccountType={receiverAccountType}
      receiverId={receiverId}
      chatId={chatId}
      caseId={caseId}
      chatStatus={chatStatus}
      ownerId={ownerId}
      actionMessage={actionMessage}
      actionType={actionType}
      readAll={readAll}
      draftMessage={draftMessage}
      sendTypingStart={sendTypingStart}
      sendNewMessage={sendNewMessage}
      sendTypingDone={sendTypingDone}
      sendNewAudioMessage={sendNewAudioMessage}
      endMessageEditing={endMessageEditing}
      cancelQuoting={cancelQuoting}
      updateChatDraft={updateChatDraft}
      classes={{
        rootWrapper: styles.rootWrapper,
        audio: styles.audio,
        editorContainer: cls(
          isDormant ? styles.editorContainerDisabled : styles.editorContainer,
          inactive && styles.editorContainerInactive
        ),
        editorWrapper: cls(styles.editorWrapper),
        editorEndAdornment: cls(
          styles.editorEndAdornment,
          inactive && styles.editorEndAdornmentInactive
        )
      }}
      showModal={showModal}
      accountType={accountType}
      isDormant={isDormant}
      isContact={isContact}
      isLockedCase={isLockedCase}
      loading={loading}
      chatType={chatType}
      isCoworker={isCoworker}
      mentionedAccounts={mentionedAccounts}
      mentionedDepartments={mentionedDepartments}
    />
  </Skeleton>
)
