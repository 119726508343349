import { useCallback, useMemo } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { ProducedOrganizationNotificationsEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { AdministrationListContainer, RoleRouteContainer } from 'App/containers'
import { ReactComponent as GearSettingFillIcon } from 'assets/icons/GearSettingFill.svg'
import { useNotificationTracker } from 'App/hooks'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'
import {
  getOrganizationsRequest,
  isBusinessAccountSelector,
  useAppDispatch,
  useAppSelector
} from 'store'

import { EditDepartmentPage } from '../../../components/Organizations/AdministrationList/EditDepartmentPage'
import { OrganizationContentHeader, TabMenu, TNavMenuItem } from '../../../components'
import { StaffTab } from '../StaffTab'

import styles from './Administration.module.scss'

export type TAdministrationProps = {
  path: string
  organizationId: string
}

export const Administration = ({ path: topPath, organizationId }: TAdministrationProps) => {
  const path = `${topPath}/administration`

  const isBusiness = useAppSelector(isBusinessAccountSelector)

  const { t } = useTranslation()

  const navMenu = useMemo<TNavMenuItem[]>(
    () => [
      {
        link: path,
        title: t('organizations.administration.tabs.staff'),
        exact: true
      },
      {
        link: `${path}/admins`,
        title: t('organizations.administration.tabs.admins'),
        exact: true
      }
    ],
    [path, t]
  )

  const dispatch = useAppDispatch()
  const { replace } = useHistory()

  const handleRedirectFromAdministration = useCallback(() => {
    replace(`/organizations/${organizationId}`)
    toast.warn(t('organizations.administration.warningToast'), toastDefaultOptions)
  }, [organizationId, replace, t])

  useNotificationTracker({
    channel: 'organizations-administration',
    onOccurrence: (payload: { organizationId?: string } | null) => {
      dispatch(
        getOrganizationsRequest({
          onSuccess:
            payload?.organizationId === organizationId
              ? handleRedirectFromAdministration
              : undefined
        })
      )
    },
    notifications: new Set([
      ProducedOrganizationNotificationsEnum.LOST_LEADER_RIGHTS_IN_DEPARTMENTS
    ])
  })

  return (
    <Switch>
      <RoleRouteContainer
        exact
        path={`${path}/departments/:id`}
        render={() => <EditDepartmentPage organizationId={organizationId} />}
      />
      <RoleRouteContainer path={path}>
        <OrganizationContentHeader
          organizationId={organizationId}
          icon={<GearSettingFillIcon />}
          title={t('organizations.administrationLabel')}
        />

        {isBusiness && (
          <TabMenu
            tabVariant="static"
            navMenuProps={{ tabPosition: 'top' }}
            menuList={navMenu}
            classes={{ root: styles.nav }}
          />
        )}

        {isBusiness && (
          <RoleRouteContainer
            exact
            path={`${path}/admins`}
            render={() => <AdministrationListContainer />}
          />
        )}

        <RoleRouteContainer
          exact
          path={path}
          render={() => <StaffTab organizationId={organizationId} />}
        />
      </RoleRouteContainer>
    </Switch>
  )
}
