import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { useCloseModal } from 'App/hooks'
import { EModalComponents } from 'App/containers'

import { Wrapper } from '../Wrapper'

type TPaymentSucceededDialogProps = {
  tokenAmount?: string
}

export const PaymentSucceededDialog = ({ tokenAmount }: TPaymentSucceededDialogProps) => {
  const accountEmail = useAppSelector((state) => state.global.accountData?.email)

  const { t } = useTranslation()

  const handleCloseModal = useCloseModal([EModalComponents.TOP_UP_PAYMENT_SUCCEEDED])

  return (
    <Wrapper
      icon="success"
      title={t('modal.paymentSucceed.title')}
      info={t('modal.paymentSucceed.info', { email: accountEmail })}
      description={t('modal.paymentSucceed.content', { tokenAmount })}
      submitLabel={t('modal.paymentSucceed.submitButton')}
      onClick={handleCloseModal}
    />
  )
}
