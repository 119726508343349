import { State } from 'redux/rootReducer'

export const isCallInProgressSelector = (state: State): boolean => {
  const arr: boolean[] = [
    state.calls.init.isInitiatedCall,
    state.calls.answer.isAcceptedCall,
    state.calls.answer.isComingCall,
    Boolean(state.organizations.activeVoiceRoom?.id)
  ]

  return arr.some((value) => value)
}
