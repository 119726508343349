import { BusinessAccountRoleNameEnum } from '@medentee/enums'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { TAccountRole } from 'store'
import { getMapComponent } from 'utils'

import styles from './OrganizationsInvitationsReceivedItemMessage.module.scss'

export type TOrganizationsInvitationsReceivedItemMessageProps = {
  role: TAccountRole
  displayUserName: string
}

type TInviteMessageMapProps = {
  displayUserName: string
  t: TFunction
}

const AccountName: FC = ({ children }) => <span className={styles.accountName}>{children}</span>

const INVITE_MESSAGE_MAP = new Map<
  BusinessAccountRoleNameEnum,
  (props: TInviteMessageMapProps) => JSX.Element
>()
  .set(BusinessAccountRoleNameEnum.SUPERADMIN, ({ displayUserName, t }) => (
    <Trans
      t={t}
      i18nKey="organizations.invitations.receivedMessage_Superadmin"
      components={{ span: <AccountName /> }}
      values={{ accountName: displayUserName }}
    />
  ))
  .set(BusinessAccountRoleNameEnum.ADMIN, ({ displayUserName, t }) => (
    <Trans
      t={t}
      i18nKey="organizations.invitations.receivedMessage_Admin"
      components={{ span: <AccountName /> }}
      values={{ accountName: displayUserName }}
    />
  ))
  .set(BusinessAccountRoleNameEnum.STAFF, ({ displayUserName, t }) => (
    <Trans
      t={t}
      i18nKey="organizations.invitations.receivedMessage_Staff"
      components={{ span: <AccountName /> }}
      values={{ accountName: displayUserName }}
    />
  ))

export const OrganizationsInvitationsReceivedItemMessage = ({
  role,
  displayUserName
}: TOrganizationsInvitationsReceivedItemMessageProps) => {
  const { t } = useTranslation()

  return getMapComponent(INVITE_MESSAGE_MAP, role.name, {
    displayUserName,
    t
  })
}
