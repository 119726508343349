import { ChatTypeEnum } from '@medentee/enums'

import { TChatRoomCaseRolesFilter } from 'store'
import { QueryBuilder } from 'utils/QueryBuilder/QueryBuilder'
export class ChatRoomQueryBuilder extends QueryBuilder {
  public constructor(url: string) {
    super(url)
  }

  public chatTypes(chatTypes?: ChatTypeEnum[]) {
    if (chatTypes) {
      this.url += `${this.separator}${chatTypes
        .map((chatType) => `chatTypes[]=${chatType}`)
        .join('&')}`
    }

    return this
  }

  public caseId(chatId?: string) {
    if (chatId) {
      this.url += `${this.separator}caseId=${chatId}`
    }

    return this
  }

  public communityId(communityId?: string | null) {
    if (communityId) {
      this.url += `${this.separator}communityId=${communityId}`
    }

    return this
  }

  public eventId(eventId?: string | null) {
    if (eventId) {
      this.url += `${this.separator}eventId=${eventId}`
    }

    return this
  }

  public organizationId(organizationId?: string | null) {
    if (organizationId) {
      this.url += `${this.separator}organizationId=${organizationId}`
    }

    return this
  }

  public caseRoles(caseRole?: TChatRoomCaseRolesFilter) {
    if (caseRole && caseRole !== 'all') {
      this.url += `${this.separator}caseRoles=${caseRole.toUpperCase()}`
    }

    return this
  }
}
