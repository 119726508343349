import { PropsWithChildren } from 'react'
import { Breadcrumb } from 'antd'
import cls from 'classnames'

import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'

import styles from './Breadcrumbs.module.scss'

export type TBreadcrumbsProps = PropsWithChildren<PropsWithClassName>

export const Breadcrumbs = ({ children, className }: TBreadcrumbsProps) => (
  <Breadcrumb className={cls(styles.root, className)} separator={<ChevronRightIcon />}>
    {children}
  </Breadcrumb>
)

export const BreadcrumbsItem = Breadcrumb.Item
