import Progress, { ProgressProps } from 'antd/lib/progress/progress'
import { useTranslation } from 'react-i18next'

import styles from './FileViewerProgress.module.scss'

export type TFileViewerProgressProps = ProgressProps & {
  label?: string
}

export const FileViewerProgress = ({
  percent,
  status,
  label,
  type = 'circle',
  ...rest
}: TFileViewerProgressProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <Progress type={type} percent={percent} status={status} {...rest} />
      <div className={styles.label}>{label ?? t('common.fileViewer.loading')}</div>
    </div>
  )
}
