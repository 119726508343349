import { AccountTypeNames } from '@medentee/enums'

import { MAX_VOICE_ROOM_PARTICIPANTS } from 'globalConstants'
import { IVoiceRoomParticipantDTO } from 'interfaces/api/organizations/voiceRoom'
import { State } from 'redux/rootReducer'

export const getOrganizationsInvitationsFiltersSelector = (state: State) =>
  state.organizations.invitations.filters

export const getOrganizationsMembersFiltersSelector = (state: State) =>
  state.organizations.members.filters

export const getIsVoiceRoomParticipantSelector =
  (accounts?: IVoiceRoomParticipantDTO[]) => (state: State) =>
    accounts?.some(({ id }) => id === state.global.accountData?.id) ?? false

export const getOrganizationBasePathSelector = (organizationId?: string | null) => (state: State) =>
  state.global.accountData?.type.name === AccountTypeNames.PROFESSIONAL
    ? `/organizations/${organizationId}`
    : '/organization'

export const getIsParticipantsLimitReachedSelector = (voiceRoomId: string) => (state: State) =>
  state.organizations.voiceRooms.list[voiceRoomId]?.accounts?.length === MAX_VOICE_ROOM_PARTICIPANTS

export const getOrganizationSubscriptionPlanSelector =
  (organizationId?: string) => (state: State) =>
    organizationId
      ? state.organizations.data.list[organizationId].businessSubscription.id
      : undefined
