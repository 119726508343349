import cls from 'classnames'

import { AnchorifedText } from 'App/components/common'

import styles from './CaseDetailsInfo.module.scss'

export type TCaseDetailsInfoProps = PropsWithClassName<{
  data: string
}>

export const CaseDetailsInfo = ({ data, className }: TCaseDetailsInfoProps) => (
  <p className={cls(styles.root, className)}>
    <AnchorifedText text={data} />
  </p>
)
