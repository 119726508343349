import { memo } from 'react'
import { AccountTypeNames, ChatStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { TChatRoomsType } from 'store'
import { ChatEmptyContactList, ChatRoomP2P, EEmptyListIconSize, EmptyList } from 'App/components'
import { ReactComponent as ForumIcon } from 'assets/icons/Forum.svg'

export type TChatContent = {
  lengthChatRooms: number
  loading: boolean
  search: string
  accountType: AccountTypeNames
  chatType: TChatRoomsType

  isContact?: boolean
  interlocutorAccount?: AccountTypeNames
  selectedChatStatus?: ChatStatusEnum
  activeChatId?: string | null
}

const ChatContentView = ({
  search,
  interlocutorAccount,
  lengthChatRooms,
  loading,
  activeChatId,
  chatType,
  accountType,
  selectedChatStatus,
  isContact
}: TChatContent) => {
  const { t } = useTranslation()

  if (!lengthChatRooms && !loading && !search) {
    return <ChatEmptyContactList chatType={chatType} accountType={accountType} />
  }

  if ((!lengthChatRooms || !activeChatId) && !loading) {
    return (
      <EmptyList
        icon={<ForumIcon />}
        text={t('chat.messages.placeholder')}
        iconSize={EEmptyListIconSize.MD}
      />
    )
  }

  return (
    <ChatRoomP2P
      activeChatId={activeChatId}
      selectedChatStatus={selectedChatStatus}
      interlocutorAccount={interlocutorAccount}
      isContact={isContact}
    />
  )
}

export const ChatContent = memo(ChatContentView)
