import { AccountTypeIdEnum, ShowCaseStatusEnum } from '@medentee/enums'
import axios from 'axios'
import { parseUrl, stringify } from 'query-string'

import config from 'config'
import { ECasesDocumentKey } from 'enums'

import { TUnAuthorizedRedirectOptions, unAuthorizedRedirect } from '../utils'
import i18n from '../i18n'

export type APIData<T = unknown> = {
  data: T
}

export type APIResponse<T = unknown> = {
  data: {
    payload: T
  }
}

export type APIResultsResponse<T = Record<string, unknown>[]> = {
  data: {
    results: T
    total: number
    page: number
  }
}

export const API = {
  URL: `${config.apiUrl}`,
  AUTH_URL: `${config.apiAuthUrl}`,
  WS: config.apiUrlWS,
  // Auth
  LOGOUT: '/auth/logout',
  USER_CHANGE: '/auth/switch',
  // Accounts
  PROTECTED: '/accounts/protected',
  WHO_AMI: '/accounts/whoami',
  EDIT_USER_PROFILE_AVATAR: '/accounts/my-profile/avatar',
  GET_AVATAR_URL: (accountId: string) => `${API.URL}/accounts/${accountId}/avatar`,
  USER_AVAILABLE_COMPANY_TYPES: '/accounts/available-company-types',
  USER_RELATED_ACCOUNTS: '/accounts/related',
  BUSINESS_ACCOUNT_REGISTRATION: '/accounts/business/registration',
  BUSINESS_ACCOUNT_SEND_CODE: '/accounts/business/send-code',
  BUSINESS_ACCOUNT_VALIDATE_CODE: '/accounts/business/validate-code',
  // Contacts
  CONTACTS: '/contacts',
  REMOVE_CONTACT: (contactId: string) => `/contacts/${contactId}`,
  CATEGORIES: '/contacts/categories',
  CATEGORY: (categoryId: string) => `/contacts/categories/${categoryId}`,
  CONTACTS_CATEGORIES: (contactId: string) => `/contacts/${contactId}/categories`,
  CONTACTS_SETTINGS: (contactId: string) => `/contacts/${contactId}/settings`,
  CONTACTS_SETTINGS_BULK: '/contacts/settings/bulk',
  // Chat
  CHAT_ROOMS: '/chat/rooms',
  CHAT_ROOM: (chatId: string) => `/chat/rooms/${chatId}`,
  CHAT_MESSAGES: (chatId: string) => `/chat/${chatId}/messages`,
  CHAT_BROADCAST_MESSAGE: '/chat/broadcast-message',
  SEND_CHAT_AUDIO_MESSAGE: (chatId: string) => `/chat/${chatId}/messages/audio`,
  CHAT_PIN: (chatId: string) => `/chat/${chatId}/messages/pin`,
  CHAT_UNPIN: (chatId: string) => `/chat/${chatId}/messages/unpin`,
  CHAT_MESSAGE_AUDIO: (chatId: string, audioFileId: string) =>
    `/chat/${chatId}/messages/audio/files/${audioFileId}`,
  CHAT_CLEAR_ALL_MESSAGES: (chatId: string) => `/chat/${chatId}/messages`,
  CHANGE_CHAT_STATUS: (chatId: string) => `/chat/${chatId}/chat-status-toggle`,
  CHAT_REQUEST: (chatId: string) => `/chat/${chatId}/chat-request`,
  MARK_AS_READ: (chatId: string) => `/chat/${chatId}/mark-as-read`,
  MUTE_TEXT_CHAT: (chatId: string) => `/chat/${chatId}/mute`,
  UNMUTE_TEXT_CHAT: (chatId: string) => `/chat/${chatId}/unmute`,
  CREATE_GROUP_CHAT: '/chat/group',
  UPDATE_GROUP_CHAT: (chatId: string) => `/chat/group/${chatId}`,
  LEAVE_GROUP_CHAT: (chatId: string) => `/chat/group/${chatId}/leave`,
  GROUP_CHAT_AVATAR: (chatId: string) => `/chat/group/${chatId}/avatar`,
  GET_CHAT_GROUP_AVATAR_URL: (chatId: string) => `${API.URL}/chat/group/${chatId}/avatar`,
  CHAT_MEMBERS: (chatId: string) => `/chat/${chatId}/members`,
  // Cloud
  CHAT_ATTACH_FILES: (chatId: string) => `/cloud/files/chat/${chatId}`,
  FILE_CONTACT_PERMISSIONS: (fileId: string) => `/cloud/files/${fileId}/contact-permissions`,
  FILE_CONTACT_CHANGE_PERMISSION: (fileId: string) => `/cloud/files/${fileId}/permissions`,
  FILE_DOWNLOAD_URL: (fileId: string) => `/cloud/files/${fileId}/download-url`,
  FILE_EDIT: (fileId: string) => `/cloud/files/${fileId}`,
  FILE_DISCARD_PERMISSIONS: (fileId: string) => `/cloud/files/${fileId}/discard-permissions`,
  FILE_COPY_TO_MEDCLOUD: (fileId: string) => `/cloud/files/${fileId}/copy`,
  ATTACH_CASE_LINKED_FILES: (caseId: string) => `/cloud/cases/${caseId}/attach-linked-file`,
  CLOUD_FILES: '/cloud/files/v2',
  CLOUD_COUNTS: '/cloud/counts',
  // Trash
  MOVE_FILE_TO_TRASH_BIN: '/trash/remove',
  TRASH: '/trash',
  RESTORE_FILE: '/trash/restore',
  TRASH_DELETE: '/trash/delete',
  // Cases
  CASES: '/cases',
  CASE: (id: string) => `/cases/${id}`,
  CASES_INVITE_LIST: '/cases/invites',
  FILE_DISCARD_PERMISSIONS_CASE: (caseId: string) => `/cases/${caseId}/file/permissions`,
  FILE_DISCARD_PERMISSIONS_CASES: (fileId: string) =>
    `/cases/files/${fileId}/remove-permissions/all`,
  CASE_MEMBERS: (caseId: string) => `/cases/${caseId}/members`,
  CASES_COUNT: '/cases/count',
  CASES_LINKS: (caseId: string) => `/cases/${caseId}/links`,
  CASE_LINKS_TOGGLE: (caseId: string) => `/cases/${caseId}/links/toggle`,
  CASES_INVITES: (inviteId: string) => `/cases/invites/${inviteId}`,
  CASE_HISTORY: (caseId: string) => `cases/${caseId}/case-history/v2`,
  CASE_HISTORY_FILTERS: (caseId: string) => `cases/${caseId}/case-history/filters`,
  CASES_KICK: (caseId: string, memberId: string) => `/cases/${caseId}/members/${memberId}`,
  CASE_FILE: (caseId: string) => `/cases/${caseId}/files`,
  CASE_COPY_TO_MEDCLOUD: (caseId: string) => `/cases/${caseId}/file/duplicate`,
  PUBLICATION_OPINIONS: (caseId: string) => `/cases/${caseId}/specs`,
  CASES_LEAVE: (caseId: string) => `/cases/${caseId}/leave`,
  DELETE_CASE_CLOUD_USER_PERMISSION: (caseId: string) => `/cases/${caseId}/file/permissions`,
  ADD_CASE_CLOUD_USER_PERMISSION_OWNED: (caseId: string) => `/cases/${caseId}/file/permission`,
  CASE_DESCRIPTION: (caseId: string) => `/cases/${caseId}/description`,
  CASES_FILES_NON_ATTACHED: (caseId: string) => `/cases/${caseId}/files/non-attached`,
  CASE_LINKED_FILES: (caseId: string, linkedCaseId: string) =>
    `/cases/${caseId}/links/${linkedCaseId}/files`,
  CASES_SUMMARY: (caseId: string) => `/cases/${caseId}/summary`,
  CASES_OPINIONS: (caseId: string) => `/cases/${caseId}/opinions`,
  CASES_OPINIONS_MEMBERS: (caseId: string) => `/cases/${caseId}/opinions/users`,
  CASES_OPINIONS_BY_MEMBER: (caseId: string) => `/cases/${caseId}/opinion`,
  CASE_INFO: (id: string) => `/cases/info/${id}`,
  CASES_PERMISSIONS_MODE: (caseId: string) => `/cases/${caseId}/set-permissions-mode`,
  CASE_CLASSIFICATIONS: '/cases/classifications',
  // Upload
  UPLOAD_PRE_SIGN_URL: '/upload/pre-sign-url',
  UPLOAD_CONFIRM: '/upload/confirm-upload',
  UPLOAD_CANCEL: '/upload/cancel-upload',
  UPLOAD_CONTINUE: '/upload/continue-upload',
  // Subscription
  MED_CLOUD_CAPACITY: '/subscriptions/capacity',
  ACTIVE_SUBSCRIPTIONS: '/subscriptions/active',
  AVAILABLE_SUBSCRIPTIONS: (accountTypeId: AccountTypeIdEnum) =>
    `/subscriptions/available/${accountTypeId}`,
  ACTIVATE_SUBSCRIPTION: '/subscriptions/activate',
  SUBSCRIPTIONS_INFO: '/subscriptions/representative',
  // My profile
  USER_PROFILE_BASIC_INFO: '/my-profile/basic-info',
  USER_PROFESSIONS: '/my-profile/professions',
  USER_PROFESSION_SPECIALIZATIONS: (professionId: string) =>
    `/my-profile/professions/${professionId}/specializations`,
  USER_PROFESSION_SPECIALIZATIONS_DELETE: (professionId: string, specializationId: string) =>
    `/my-profile/professions/${professionId}/specializations/${specializationId}`,
  USER_AVAILABLE_PROFESSIONS: '/my-profile/professions/available',
  USER_PROFILE_EDIT_PROFESSION: (professionId: string) => `/my-profile/professions/${professionId}`,
  USER_WEBSITES: '/my-profile/on-the-web',
  USER_WEBSITES_EDIT: (websiteId: string) => `/my-profile/on-the-web/${websiteId}`,
  USER_PROFILE: '/my-profile',
  USER_PROFILE_CONTACT_VIEW: (userId: string) => `/my-profile/contacts/${userId}`,
  EDIT_BUSINESS_ACCOUNT: '/my-profile/business',
  ENABLE_2FA: '/my-profile/enable-2fa',
  DISABLE_2FA: '/my-profile/disable-2fa',
  SETTINGS: '/my-profile/settings',
  LICENSES: '/my-profile/license',
  LICENSES_RECORD: (id: string) => `/my-profile/license/${id}`,
  EDUCATION: '/my-profile/education',
  EDUCATION_RECORD: (id: string) => `/my-profile/education/${id}`,
  WORK_EXPERIENCE: '/my-profile/workplace',
  WORK_EXPERIENCE_RECORD: (id: string) => `/my-profile/workplace/${id}`,
  PUBLICATIONS: '/my-profile/publication',
  PUBLICATIONS_RECORD: (id: string) => `/my-profile/publication/${id}`,
  REMOVE_LICENSE: (id: string) => `/my-profile/license/${id}`,
  REMOVE_EDUCATION: (id: string) => `/my-profile/education/${id}`,
  DEFAULT_ACCOUNT: '/my-profile/default-account',
  CHANGE_PASSWORD: '/my-profile/change-password',
  CHANGE_EMAIL: '/my-profile/change-email',
  SETTINGS_SOUNDS_TOGGLE: '/my-profile/settings/sounds/toggle',
  // Misc
  COUNTRIES: '/misc/countries',
  CITIES: '/misc/cities',
  ALL_PROFESSIONS: '/misc/professions/v2',
  PURPOSES: '/misc/purposes',
  // Notifications
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_GENERAL: '/notifications/general',
  INVITATIONS_COUNT: '/notifications/invitations',
  NOTIFICATIONS_PROTOCOL: '/notifications/protocol',
  NOTIFICATIONS_PROTOCOL_USERS: '/notifications/protocol/users',
  NOTIFICATIONS_PROTOCOL_LIST: '/notifications/protocol/list',
  NOTIFICATIONS_SHOWCASES_LIST: '/notifications/showcases/list',
  // Invites
  INVITATIONS_DATA_BY_LINK_TOKEN: (token: string) => `/invitations/by-link/${token}`,
  CONNECT_DATA_BY_LINK_TOKEN: (token: string) => `/invitations/by-link/${token}/connect`,
  INVITATIONS_BY_LINK: '/invitations/by-link',
  INVITATIONS_BY_EMAIL: '/invitations/by-email',
  INVITATIONS_BY_EMAIL_HIDE: (token: string) => `/invitations/by-email/${token}/hide`,
  INVITATIONS_BY_EMAIL_WITHDRAW: (token: string) => `/invitations/by-email/${token}/withdraw`,
  // Connections
  ADVERTS: '/connections',
  MY_ADVERTS: '/connections/my',
  MY_REQUESTS: '/connections/requests/my',
  MY_ADVERT_REQUESTS: (advertId: string) => `/connections/${advertId}/requests`,
  CHANGE_REQUEST_STATUS: (advertId: string, requestId: string) =>
    `/connections/${advertId}/requests/${requestId}`,
  BECOME_CONTACTS: (requestId: string) => `/connections/${requestId}`,
  CONNECTIONS_COUNTS: '/connections/counts',
  CHANGE_ADVERT_STATUS: (advertId: string) => `/connections/${advertId}/status`,
  REPOST_ADVERT: (advertId: string) => `/adverts/${advertId}/repost`,
  // Files history
  FILES_HISTORY: '/files-history',
  // Business users
  BUSINESS_USERS: '/business-users',
  BUSINESS_USERS_ID: (id: string) => `/business-users/${id}`,
  // Finances
  FINANCES_BALANCE: '/finances/balance',
  FINANCES_OPERATIONS: '/finances/operations',
  FINANCES_TRANSFER: '/finances/transfer',
  // Draft
  CASE_DRAFT: (caseId: string, type: ECasesDocumentKey) => `/drafts/case/${caseId}/${type}`,
  SCHEDULE_MEETING: (id: string) => `/meeting/${id}`,
  CHAT_DRAFT: (chatId: string) => `/drafts/chat/${chatId}`,
  // Meetings
  MEETINGS: '/meeting',
  UPDATE_MEETING_INVITES: (meetingId: string) => `/meeting/${meetingId}/invites`,
  CANCEL_MEETING: (meetingId: string) => `/meeting/cancel/${meetingId}`,
  ADD_TO_GOOGLE_CALENDAR: (meetingId: string) => `/meeting/${meetingId}/as-google-calendar`,
  EXPORT_TO_ICAL_CALENDAR: (meetingId: string) => `/meeting/${meetingId}/as-ical-calendar`,
  CREATE_EXTERNAL_MEETING: '/meeting/external/create',
  EXTERNAL_MEETING_URL: (id: string) => `/meeting/external/${id}`,
  SEND_EXTERNAL_MEETING_LINK_TO_EMAILS: '/meeting/external/send',
  // Video stream
  VIDEO_STREAM_URL: (fileId: string) => `/video-stream/${fileId}/generate-bunny-url`,
  // Calls
  CALLS_ONGOING: '/calls/ongoing',
  //Organizations
  ORGANIZATIONS: '/organizations',
  ORGANIZATIONS_TEXT_CHANNELS: (organizationId: string) => `/organizations/${organizationId}/chats`,
  ORGANIZATIONS_TEXT_CHANNEL: (organizationId: string, chatId: string) =>
    `/organizations/${organizationId}/chats/${chatId}`,
  ORGANIZATIONS_VOICE_CHANNELS: (organizationId: string) =>
    `/organizations/${organizationId}/voice-rooms`,
  ORGANIZATIONS_VOICE_CHANNEL: (organizationId: string, roomId: string) =>
    `/organizations/${organizationId}/voice-rooms/${roomId}`,
  ORGANIZATIONS_LEAVE_VOICE_CHANNEL: (organizationId: string, roomId: string) =>
    `/organizations/${organizationId}/voice-rooms/${roomId}/leave`,
  ORGANIZATIONS_CHATS: (partnerAccountId: string) => `/organizations/chats/${partnerAccountId}`,
  ORGANIZATIONS_REMOVE_MEMBER: (memberId: string) => `/organizations/member/${memberId}`,
  ORGANIZATION_INVITE_ALL_MEMBERS: (organization: string) =>
    `/organizations/${organization}/invite-all`,
  ORGANIZATION_DEPARTMENTS: (id: string) => `/organizations/${id}/departments`,
  ORGANIZATION_DEPARTMENT: (organizationId: string, departmentId: string) =>
    `/organizations/${organizationId}/departments/${departmentId}`,
  ORGANIZATION_DEPARTMENT_MEMBERS: (organizationId: string, departmentId: string) =>
    `/organizations/${organizationId}/departments/${departmentId}/members`,
  ORGANIZATION_MEMBERS: (organizationId: string) => `/organizations/${organizationId}/members`,
  ORGANIZATION_DEPARTMENTS_MEMBERS_GROUPED: (organizationId: string) =>
    `/organizations/${organizationId}/departments/members/grouped`,
  ORGANIZATION_CHANNEL_JOIN: (organizationId: string, token: string) =>
    `/organizations/${organizationId}/chats/${token}/join`,
  ORGANIZATION_LEAVE_CHANNEL: (organizationId: string, channelId: string) =>
    `/organizations/${organizationId}/chats/${channelId}/leave`,
  // TopUp
  TOP_UP_ONE_TIME: '/payments',
  TOP_UP_MONTHLY: '/payments/auto-payment',
  // Showcases
  SHOWCASES_REPLIES: (id: string, commentId: string) =>
    `/showcases/${id}/comments/${commentId}/replies`,
  SHOWCASES_COMMENTS: (id: string) => `/showcases/${id}/comments`,
  SHOWCASES_COMMENT: (showcaseId: string, id: string) => `/showcases/${showcaseId}/comments/${id}`,
  SHOWCASES_COMMENT_COUNTS: (showcaseId: string) =>
    `/showcases/${showcaseId}/comments/summary/counters`,
  SHOWCASES_COMMENT_DRAFT: (id: string, fileId: string) =>
    `/showcases/${id}/comments/${fileId}/draft`,
  SHOWCASES_FEED: '/showcases/feed',
  MY_SHOWCASES: '/showcases/my',
  SAVED_SHOWCASES: '/showcases/saved',
  CHANGE_SHOWCASE_STATUS: (id: string, status: ShowCaseStatusEnum) =>
    `/showcases/${id}/status/${status}`,
  SHOWCASE_DRAFT: (id: string) => `/showcases/${id}/draft`,
  SHOWCASE_AUDIENCE: (id: string) => `/showcases/${id}/audience`,
  SHOWCASE_PRESIGN_URL: (id: string) => `/showcases/${id}/draft/presign-url`,
  SAVE_UNSAVE_SHOWCASE: (id: string) => `/showcases/${id}/save/toggle`,
  PUBLISH_SHOWCASE: (id: string) => `/showcases/${id}/publish`,
  DELETE_SHOWCASE: (id: string) => `/showcases/${id}/delete`,
  SHOWCASE_ACCOUNT_SUGGESTIONS: (id: string) => `/showcases/${id}/tags/suggestions/accounts`,
  SHOWCASE_FILE_SUGGESTIONS: (id: string) => `/showcases/${id}/tags/suggestions/files`,
  SHOWCASE: (id: string) => `/showcases/${id}`,
  // Communities
  COMMUNITIES: '/communities',
  COMMUNITY: (communityId: string) => `/communities/${communityId}`,
  COMMUNITY_JOIN: (communityId: string) => `/communities/${communityId}/join`,
  COMMUNITY_CHANNEL_JOIN: (communityId: string, token: string) =>
    `/communities/${communityId}/chats/${token}/join`,
  GET_COMMUNITY_AVATAR_URL: (communityId: string) => `${API.URL}/communities/${communityId}/avatar`,
  GET_COMMUNITY_COVER_URL: (communityId: string) => `${API.URL}/communities/${communityId}/cover`,
  COMMUNITY_MEMBERS: (communityId: string) => `/communities/${communityId}/members`,
  COMMUNITY_MEMBER: (communityId: string, accountId: string) =>
    `/communities/${communityId}/members/${accountId}`,
  COMMUNITY_TEXT_CHANNELS: (communityId: string) => `/communities/${communityId}/chats`,
  COMMUNITIES_TEXT_CHANNEL: (communityId: string, chatId: string) =>
    `/communities/${communityId}/chats/${chatId}`,
  COMMUNITY_SHARED_LINK: (communityId: string) => `/communities/${communityId}/shared-link`,
  COMMUNITY_LEAVE_CHANEL: (communityId: string, channelId: string) =>
    `/communities/${communityId}/chats/${channelId}/leave`,
  // Events
  EVENTS: '/events',
  EVENT: (eventId: string) => `/events/${eventId}`,
  LEAVE_EVENT: (eventId: string) => `/events/${eventId}/left`,
  EVENT_INVITES: (eventId: string) => `/events/${eventId}/invites`,
  EVENT_INVITE_BY_EMAIL: (eventId: string) => `/events/${eventId}/by-email`,
  EVENT_ORGANIZER_SETTINGS: (eventId: string) => `/events/${eventId}/settings`,
  EVENT_REPRESANTATIVES: (eventId: string) => `/events/${eventId}/represantatives`,
  EVENT_INVITE_BY_LINK: (eventId: string) => `/events/${eventId}/shared-link`,
  EVENT_AVATAR: (eventId: string) => `${API.URL}/events/${eventId}/avatar`,
  EVENT_COVER: (eventId: string) => `${API.URL}/events/${eventId}/cover`,
  EVENT_TEXT_CHANNELS: (eventId: string) => `/events/${eventId}/chats`,
  EVENT_TEXT_CHANNEL: (eventId: string, chatId: string) => `/events/${eventId}/chats/${chatId}`,
  EVENT_TEXT_CHANNEL_LEAVE: (eventId: string, chatId: string) =>
    `/events/${eventId}/chats/${chatId}/leave`,
  EVENT_ATTENDEES: (eventId: string) => `/events/${eventId}/attendees`,
  EVENT_ATTENDEE_PROFILE_AVATAR: (eventId: string, attendeeId: string) =>
    `${API.URL}/events/${eventId}/attendees/${attendeeId}/avatar`,
  EVENT_LINKS: (eventId: string) => `/events/${eventId}/links`,
  EVENT_LINK: (eventId: string, linkId: string) => `/events/${eventId}/links/${linkId}`,
  EVENT_PERSONAL_PROFILE: (eventId: string) => `/events/${eventId}/profile`,
  EVENT_ATTENDEE_PROFILE: (eventId: string, attendeeId: string) =>
    `/events/${eventId}/profile/${attendeeId}`,
  EVENT_ATTENDEE: (eventId: string, attendeeId: string) =>
    `/events/${eventId}/attendees/${attendeeId}`,
  EVENT_ATTENDEE_FAVORITE_TOGGLE: (eventId: string, attendeeId: string) =>
    `/events/${eventId}/attendees/${attendeeId}/favorite`,
  EVENT_JOIN: (token: string) => `/events/${token}/join`,
  EVENT_CHANNEL_JOIN: (eventId: string, token: string) => `/events/${eventId}/chats/${token}/join`,
  // Broadcasts
  BROADCASTS: `/broadcasts`,
  BROADCAST: (broadcastId: string) => `/broadcasts/${broadcastId}`,
  BROADCAST_MEMBERS: (broadcastId: string) => `/broadcasts/${broadcastId}/members`,
  BROADCAST_JOIN: (token: string) => `/broadcasts/${token}/join`
}

// Set config defaults when creating the instance
export const api = axios.create({
  baseURL: API.URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

api.interceptors.response.use(
  (response) => {
    if (response.data?.total) {
      const {
        data: { total },
        config: reqConfig
      } = response
      const parsedUrl = parseUrl(reqConfig.url || '')
      const currentPage = parsedUrl.query?.page ?? 0

      if (parsedUrl.query?.showBy) {
        const lastPage = Math.ceil(total / Number(parsedUrl.query?.showBy ?? 1)) - 1

        if (lastPage < Number(currentPage)) {
          return axios.request({
            ...reqConfig,
            url: `${parsedUrl.url}?${stringify({
              ...parsedUrl.query,
              page: lastPage
            })}`
          })
        }
      }
    }

    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      const redirectState: null | TUnAuthorizedRedirectOptions = Boolean(
        error?.response?.data?.expired
      )
        ? {
            type: 'warn',
            message: i18n.t('common.toast.expiredSession')
          }
        : null

      unAuthorizedRedirect(redirectState)
    }

    if (error?.response?.status === 403 && error?.response?.data?.code === 'ACCOUNT_DELETED') {
      const redirectState: TUnAuthorizedRedirectOptions = {
        type: 'error',
        message: i18n.t('common.toast.accountDeleted')
      }

      unAuthorizedRedirect(redirectState)

      return Promise.reject()
    }

    return Promise.reject(error)
  }
)
api.interceptors.request.use(
  (response) => response,
  (error) => Promise.reject(error)
)
