import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { CaseSummary } from 'App/components'
import {
  getCaseSummaryRequest,
  addCaseSummaryRequest,
  ADD_CASE_SUMMARY,
  updateCaseSummaryRequest,
  UPDATE_CASE_SUMMARY,
  createProcessingSelector,
  GET_CASE_SUMMARY,
  createLoadingSelector
} from 'store'
import { State } from 'redux/rootReducer'

export type TCaseSummaryContainerProps = {
  caseId: string
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseSummaryContainerView = ({
  caseId,
  summary,
  loading,
  processing,
  getCaseSummary,
  addCaseSummary,
  updateCaseSummary
}: TCaseSummaryContainerProps) => {
  useEffect(() => {
    getCaseSummary({ caseId })
  }, [getCaseSummary, caseId])

  return (
    <CaseSummary
      caseId={caseId}
      summary={summary}
      loading={loading}
      processing={processing}
      addCaseSummary={addCaseSummary}
      updateCaseSummary={updateCaseSummary}
    />
  )
}

const loadingSelector = createLoadingSelector([GET_CASE_SUMMARY])
const processingSelector = createProcessingSelector([ADD_CASE_SUMMARY, UPDATE_CASE_SUMMARY])

const mapStateToProps = (state: State) => ({
  summary: state.caseDetails.summary.summary,
  loading: loadingSelector(state),
  processing: processingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseSummary: getCaseSummaryRequest,
      addCaseSummary: addCaseSummaryRequest,
      updateCaseSummary: updateCaseSummaryRequest
    },
    dispatch
  )

export const CaseSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseSummaryContainerView)
