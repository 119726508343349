import { useCallback } from 'react'
import isEqual from 'lodash/isEqual'
import cls from 'classnames'

import {
  getBroadcastSelectedFilesListByIdSelector,
  removeBroadcastSelectedFile,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TVideoReadinessStatusProps } from 'App/containers'
import { FileImage, FileNameTruncatedText } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './AttachedItem.module.scss'

export type TAttachedItemProps = PropsWithClasses<
  'root' | 'remove',
  { id: string; variant?: TVideoReadinessStatusProps['variant'] }
>

export const AttachedItem = ({ id, classes, variant }: TAttachedItemProps) => {
  const dispatch = useAppDispatch()
  const { thumbnailUrl, extension, fileName } = useAppSelector(
    getBroadcastSelectedFilesListByIdSelector(id),
    isEqual
  )

  const handleRemove = useCallback(() => {
    dispatch(removeBroadcastSelectedFile({ id }))
  }, [dispatch, id])

  return (
    <div className={cls(styles.root, classes?.root)}>
      <FileImage
        size="lg"
        thumbnailUrl={thumbnailUrl}
        fileName={fileName}
        fileId={id}
        extension={extension}
        variant={variant}
        classes={{ root: styles.image, fileIcon: styles.fileIcon }}
      />

      <button className={cls(styles.remove, classes?.remove)} onClick={handleRemove}>
        <CrossIcon />
      </button>

      <FileNameTruncatedText
        className={styles.fileName}
        extension={extension}
        fileName={fileName}
        twoLine={true}
      />
    </div>
  )
}
