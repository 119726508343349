import { useCallback } from 'react'
import { FileHistorySourcesEnum, MessageTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { IMAGE_WHITELIST } from 'globalConstants'
import { EChatHistoryEvents } from 'enums'
import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import {
  copyFileToMedcloudRequest,
  createSlides,
  deleteFileFromChatRequest,
  downloadFileFromChatRequest,
  EExtendableCardType,
  FILE_REVOKE_PERMISSION_FROM_CHAT,
  fileRevokePermissionFromChatRequest,
  quoteMessageRequest,
  removeErrorNotification,
  renameMessageFile,
  setFileDetailsId,
  showExtendableCardAction,
  showModalAction,
  TSlideEntity,
  useAppDispatch,
  DELETE_FILE_FROM_CHAT,
  useAppSelector,
  isSelectedChatP2PSelector,
  setChatHistoryEventAction
} from 'store'
import { getExtensionWithoutDot } from 'utils/files'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'
import { ReactComponent as PencilOutlineWriteIcon } from 'assets/icons/PencilOutlineWrite.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/Download.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg'
import { ReactComponent as PinOutlinedIcon } from 'assets/icons/PinOutlined.svg'
import { ReactComponent as UnpinOutlinedIcon } from 'assets/icons/UnpinOutlined.svg'
import { ReactComponent as ShareOutlineIcon } from 'assets/icons/ShareOutline.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import { copyMessage, isTemporaryActionAllowed } from 'utils'

import { useChatContext } from '../../../context'

type TUseKebabMenuProps = {
  discardPermissionCallback?: () => void
  revokeP2PAccessCallback?: () => void
  renameCallback?: () => void
}

export type TUseKebabMenu = {
  getMenuItems: (props: TGetMenuItemsProps) => TMenuItemProps[]
}

type TGetMenuItemsProps = {
  fileId: string
  isP2P?: boolean
  fileName: string
  extension: string
  chatId: string
  isUnopenable: boolean
  slidesEntity: TSlideEntity

  createdAt?: string
  messageId?: string
  caseId?: string
  yourMessage?: boolean
  isPinned?: boolean
  isActive?: boolean
  isLockedCase?: boolean
  isOrganization?: boolean
  isCaseGroup?: boolean
  isChatInfo?: boolean
  isCommunity?: boolean
  isEvent?: boolean
  isCommunityNews?: boolean
  isEventNews?: boolean
  isGroup?: boolean
  isChatAdmin?: boolean
  isChannelManager?: boolean
  isBusinessAccount?: boolean
  isCaseOwner?: boolean
  isPinAble?: boolean
  isDeleteAsMessage?: boolean
  isPinShared?: boolean
  isPinInitiator?: boolean
  isDeleteOptionHidden?: (defaultCondition: boolean) => boolean
}

export const useKebabMenu = ({
  discardPermissionCallback,
  revokeP2PAccessCallback,
  renameCallback
}: TUseKebabMenuProps): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isP2P = useAppSelector(isSelectedChatP2PSelector)

  const { showPinnedMessages, togglePinnedMessages } = useChatContext()

  const handleDeleteFileMessage = useCallback(
    ({
      chatId,
      caseId,
      messageId
    }: NonNullableBy<Pick<TGetMenuItemsProps, 'messageId' | 'chatId' | 'caseId'>, 'messageId'>) => {
      dispatch(
        showModalAction({
          modalType: EModalComponents.DELETE_CHAT_MESSAGE_CONFIRM,
          modalTitle: t('modal.deleteMessageConfirm.title'),
          modalProps: { chatId, messageId, caseId }
        })
      )
    },
    [dispatch, t]
  )

  const handelDeleteFileFromChat = useCallback(
    ({
        isDeleteAsMessage,
        messageId,
        chatId,
        caseId,
        fileId,
        isChatInfo
      }: Pick<
        TGetMenuItemsProps,
        'isDeleteAsMessage' | 'messageId' | 'chatId' | 'caseId' | 'fileId' | 'isChatInfo'
      >) =>
      () => {
        if (isDeleteAsMessage && messageId) {
          handleDeleteFileMessage({ chatId, caseId, messageId })
          return
        }

        dispatch(
          showModalAction({
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalTitle: t('modal.deleteFileConfirm.title_one'),
            modalProps: {
              loadingActions: [DELETE_FILE_FROM_CHAT],
              content: t('modal.deleteFileConfirm.content_one'),
              confirmLabel: t('modal.deleteFileConfirm.submitButton'),
              onConfirm: () => {
                dispatch(
                  deleteFileFromChatRequest({
                    chatId,
                    fileIds: [fileId],
                    isChatInfo,
                    onSuccess: revokeP2PAccessCallback
                  })
                )
              }
            }
          })
        )
      },
    [dispatch, handleDeleteFileMessage, revokeP2PAccessCallback, t]
  )

  const handelFileRevokePermissionFromChat = useCallback(
    ({
        isDeleteAsMessage,
        messageId,
        chatId,
        caseId,
        fileId
      }: Pick<
        TGetMenuItemsProps,
        'isDeleteAsMessage' | 'messageId' | 'chatId' | 'caseId' | 'fileId'
      >) =>
      () => {
        if (isDeleteAsMessage && messageId) {
          handleDeleteFileMessage({ chatId, caseId, messageId })
          return
        }

        dispatch(
          showModalAction({
            modalType: EModalComponents.GENERIC_CONFIRMATION,
            modalTitle: t('modal.deleteFileConfirm.title_one'),
            modalProps: {
              content: t('modal.deleteFileConfirm.content_one'),
              confirmLabel: t('modal.deleteFileConfirm.submitButton'),
              loadingActions: [FILE_REVOKE_PERMISSION_FROM_CHAT],
              onConfirm: () => {
                dispatch(
                  fileRevokePermissionFromChatRequest({
                    fileId,
                    onSuccess: revokeP2PAccessCallback
                  })
                )
              },
              errorKey: FILE_REVOKE_PERMISSION_FROM_CHAT,
              resetError: () => dispatch(removeErrorNotification(FILE_REVOKE_PERMISSION_FROM_CHAT))
            }
          })
        )
      },
    [dispatch, handleDeleteFileMessage, revokeP2PAccessCallback, t]
  )

  const getMenuItems = useCallback(
    ({
      chatId,
      extension,
      fileId,
      fileName,
      yourMessage,
      isUnopenable,
      messageId,
      slidesEntity,
      createdAt,
      isPinned,
      isActive,
      isLockedCase,
      caseId,
      isCaseGroup,
      isOrganization,
      isChatInfo,
      isCommunity,
      isCommunityNews,
      isEvent,
      isEventNews,
      isGroup,
      isChannelManager = false,
      isBusinessAccount = false,
      isDeleteAsMessage,
      isDeleteOptionHidden,
      isCaseOwner = false,
      isPinAble = false,
      isPinShared = false,
      isPinInitiator = false,
      isChatAdmin = false
    }: TGetMenuItemsProps) => {
      const isDeleteAsTemporaryMessageActionHidden = Boolean(
        isDeleteAsMessage && messageId && createdAt && !isTemporaryActionAllowed(createdAt)
      )

      const isDeleteFileFromChatHidden =
        !yourMessage ||
        (!isOrganization && !isCaseGroup && !isCommunity && !isEvent && !isGroup) ||
        isDeleteAsTemporaryMessageActionHidden

      const unpinMessageItem: TMenuItemProps = {
        content: t('chat.message.kebabMenu.unpin'),
        icon: <UnpinOutlinedIcon />,
        hidden:
          !isPinAble ||
          !isPinned ||
          isEventNews ||
          (isPinShared && !isP2P && !isCaseOwner && !isChannelManager && !isBusinessAccount),
        onClick: () => {
          dispatch(
            showModalAction({
              modalType: EModalComponents.CHAT_UNPIN_CONFIRM,
              modalTitle:
                isPinInitiator && isPinShared
                  ? t('modal.unpinMessageConfirm.title_all')
                  : t('modal.unpinMessageConfirm.title'),
              modalProps: {
                chatId,
                isPinShared,
                isPinInitiator,
                showPinnedMessages,
                messageId: messageId || ''
              }
            })
          )
        }
      }

      if (showPinnedMessages) {
        return [
          {
            icon: <ArrowLeftIcon />,
            content: t('chat.message.kebabMenu.goToMessage'),
            onClick: () => {
              dispatch(
                setChatHistoryEventAction({
                  type: EChatHistoryEvents.JUMP,
                  payload: { eventTs: Date.now(), messageId: messageId || '' }
                })
              )
              togglePinnedMessages()
            }
          },
          unpinMessageItem
        ]
      }

      return [
        {
          content: t('chat.message.kebabMenu.open'),
          icon: <FileIcon />,
          hidden: isUnopenable,
          onClick: () => {
            dispatch(
              showExtendableCardAction({
                type: EExtendableCardType.FILE_VIEWER,
                initialSlideId: fileId,
                slides: createSlides(slidesEntity, FileHistorySourcesEnum.P2P_CLOUD, chatId)
              })
            )
          }
        },
        {
          content: t('chat.message.kebabMenu.copy'),
          icon: <CopyIcon />,
          hidden: !IMAGE_WHITELIST.includes(getExtensionWithoutDot(extension)) || true,
          onClick: () => {
            copyMessage({
              type: MessageTypeEnum.FILE,
              file: {
                id: fileId,
                fileName,
                extension
              }
            })
          }
        },
        {
          content: t('chat.message.kebabMenu.rename'),
          icon: <PencilOutlineWriteIcon />,
          hidden: !yourMessage,
          onClick: () =>
            dispatch(
              showModalAction({
                modalType: EModalComponents.FILE_RENAME_DIALOG,
                modalTitle: t('modal.fileRename.title'),
                modalProps: {
                  fileId,
                  defaultFileName: fileName,
                  onSuccess: (newFileName: string) => {
                    messageId &&
                      dispatch(
                        renameMessageFile({
                          messageId,
                          fileName: newFileName
                        })
                      )
                    renameCallback && renameCallback()
                  }
                }
              })
            )
        },
        {
          content: t('chat.message.kebabMenu.quote'),
          icon: <ShareOutlineIcon />,
          hidden:
            !isActive ||
            isLockedCase ||
            !messageId ||
            (!isBusinessAccount && !isChannelManager && (isCommunityNews || isEventNews)),
          onClick: () => {
            dispatch(quoteMessageRequest({ quoteId: messageId || '', chatId }))
          }
        },
        {
          content: t('chat.message.kebabMenu.pin'),
          icon: <PinOutlinedIcon />,
          hidden: !isPinAble || isPinned || isEventNews,
          onClick: () => {
            dispatch(
              showModalAction({
                modalType: EModalComponents.CHAT_PIN_CONFIRM,
                modalTitle: t('modal.pinMessageConfirm.title'),
                modalProps: {
                  chatId,
                  messageId: messageId || '',
                  shareable: isP2P || isChatAdmin || isChannelManager || isCaseOwner
                }
              })
            )
          }
        },
        unpinMessageItem,
        {
          content: t('chat.message.kebabMenu.downloadToDevice'),
          icon: <DownloadIcon />,
          onClick: () => {
            dispatch(
              downloadFileFromChatRequest({
                fileId,
                originalEntityId: chatId
              })
            )
          }
        },
        {
          content: t('chat.message.kebabMenu.copyToMEDcloud'),
          icon: <DownloadIcon />,
          hidden: !!yourMessage,
          onClick: () => {
            dispatch(
              copyFileToMedcloudRequest({
                fileId,
                fileName,
                fileType: extension,
                source: FileHistorySourcesEnum.P2P_CLOUD,
                originalEntityId: chatId
              })
            )
          }
        },
        {
          content: t('chat.message.kebabMenu.details'),
          icon: <InfoIcon />,
          hidden: !!yourMessage || isOrganization || isCaseGroup || isCommunity || isEvent,
          onClick: () => {
            fileId && dispatch(setFileDetailsId({ recordId: fileId }))
          }
        },
        {
          content: t('chat.message.kebabMenu.discardPermission'),
          icon: <TrashIcon />,
          hidden:
            !!yourMessage || isOrganization || isCaseGroup || isCommunity || isEvent || isGroup,
          onClick: () => {
            dispatch(
              showModalAction({
                modalType: EModalComponents.CHAT_DISCARD_PERMISSION_CONFIRM,
                modalTitle: t('modal.discardFilePermissions.title'),
                modalProps: {
                  fileIds: [fileId],
                  onSuccess: discardPermissionCallback,
                  onError: discardPermissionCallback
                }
              })
            )
          }
        },
        {
          content: t('chat.message.kebabMenu.permissions'),
          icon: <InfoIcon />,
          hidden: !yourMessage || isOrganization || isCaseGroup || isCommunity || isEvent,
          onClick: () => {
            fileId && dispatch(setFileDetailsId({ recordId: fileId }))
          }
        },
        {
          content: t('chat.message.kebabMenu.delete'),
          icon: <TrashIcon />,
          hidden:
            !yourMessage ||
            isOrganization ||
            isCaseGroup ||
            isCommunity ||
            isEvent ||
            isGroup ||
            isDeleteAsTemporaryMessageActionHidden,
          onClick: handelFileRevokePermissionFromChat({
            chatId,
            fileId,
            caseId,
            messageId,
            isDeleteAsMessage
          })
        },
        {
          content: t('chat.message.kebabMenu.delete'),
          icon: <TrashIcon />,
          hidden: isDeleteOptionHidden
            ? isDeleteOptionHidden(isDeleteFileFromChatHidden)
            : isDeleteFileFromChatHidden,
          onClick: handelDeleteFileFromChat({
            chatId,
            fileId,
            caseId,
            messageId,
            isChatInfo,
            isDeleteAsMessage
          })
        }
      ]
    },
    [
      isP2P,
      discardPermissionCallback,
      dispatch,
      handelDeleteFileFromChat,
      handelFileRevokePermissionFromChat,
      renameCallback,
      showPinnedMessages,
      togglePinnedMessages,
      t
    ]
  )

  return {
    getMenuItems
  }
}
