import { Trans, useTranslation } from 'react-i18next'

import { TSettingsBusinessInfoCompanyNameContainerProps } from 'App/containers'
import { Help, SettingsInfoRow } from 'App/components'
import { SUPPORT_EMAIL } from 'globalConstants'

import styles from './SettingsBusinessInfoCompanyName.module.scss'

export type TSettingsBusinessInfoCompanyNameProps = TSettingsBusinessInfoCompanyNameContainerProps

export const SettingsBusinessInfoCompanyName = ({
  companyName
}: TSettingsBusinessInfoCompanyNameProps) => {
  const { t } = useTranslation()

  return (
    <SettingsInfoRow
      label={
        <div className={styles.name}>
          <Trans
            t={t}
            i18nKey="settings.personalInformation.companyNameLabel"
            components={{
              help: <Help content={t('userProfile.changeNameHint', { email: SUPPORT_EMAIL })} />
            }}
          />
        </div>
      }
      description={companyName}
    />
  )
}
