import { CaseClassificationNameEnum, CaseStatusesEnum } from '@medentee/enums'
import { NavLink } from 'react-router-dom'
import { Row, Skeleton } from 'antd'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TLinkedCasesItem } from 'store'
import { EAvatarSize } from 'enums'
import { TCaseLinkItemContainerProps } from 'App/containers'
import { AccountName, CaseClassification, CaseType, StatusColored } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/Plus.svg'

import styles from './CaseLinkItem.module.scss'

const SKELETON_PARAGRAPHS_NUM = 5

export type TCaseLinkItemBaseProps = {
  caseItemId: string
  parentCaseId: string
  isView?: boolean
  shouldShowFilesCount?: boolean
  onClick?: (item: TLinkedCasesItem) => void
}
export type TCaseLinkItemProps = TCaseLinkItemContainerProps & TCaseLinkItemBaseProps

export const CaseLinkItem = ({
  loading,
  parentCaseId,
  caseItem,
  caseItemId,
  accountId,
  hasLink,
  shouldShowFilesCount,
  toggleLink,
  onClick
}: TCaseLinkItemProps) => {
  const { t } = useTranslation()

  const {
    owner,
    classification,
    type,
    status = CaseStatusesEnum.ACTIVE,
    title,
    filesCount = 0
  } = caseItem || {}
  const { firstName, lastName, type: ownerType, id: ownerId, displayUserName } = owner || {}
  const isOwner = accountId === ownerId

  const caseLink = `/cases/${caseItemId}/${isOwner ? 'owner' : 'member'}`

  const toggleLinksHandler = () => toggleLink({ caseIdToToggle: caseItemId, caseId: parentCaseId })

  const linkButton = hasLink ? (
    <div className={styles.unlink} onClick={toggleLinksHandler}>
      {t('cases.modal.linkCase.unlinkButton')} <CrossIcon />
    </div>
  ) : (
    <div className={styles.link} onClick={toggleLinksHandler}>
      {t('cases.modal.linkCase.linkButton')} <PlusIcon />
    </div>
  )

  const handleClick = () => {
    if (onClick && caseItem) {
      onClick(caseItem)
    }
  }

  return (
    <Skeleton
      loading={loading}
      active={true}
      paragraph={{ rows: SKELETON_PARAGRAPHS_NUM }}
      className={styles.skeleton}
    >
      <div className={styles.root}>
        <Row className={styles.header} wrap={false}>
          <Row className={styles.rowGap}>
            <StatusColored
              variant="chip"
              status={status}
              statusLabel={t(`cases.caseStatus.${status}`)}
              className={styles.status}
            />

            <Row className={styles.rowGap} wrap={false}>
              {classification?.name === CaseClassificationNameEnum.PRIVATE && (
                <CaseClassification classification={classification?.name} />
              )}

              <CaseType type={type} classification={classification?.name} />
            </Row>
          </Row>

          {shouldShowFilesCount ? (
            <div className={styles.filesCount}>
              {t('cases.modal.linkCase.attachedFilesCount', { quantity: filesCount })}
            </div>
          ) : (
            linkButton
          )}
        </Row>

        {onClick ? (
          <div className={cls(styles.title, styles.clickable)} onClick={handleClick}>
            {title}
          </div>
        ) : (
          <NavLink className={styles.title} to={caseLink} target="_blank" rel="noreferrer noopener">
            {title}
          </NavLink>
        )}

        <Row wrap={false} align="middle">
          <AccountName
            userId={ownerId}
            size={EAvatarSize.XXS}
            firstName={firstName}
            lastName={lastName}
            displayUserName={displayUserName}
            type={ownerType?.name}
            hideAccountType={true}
            hideIcon={true}
            showAvatar={true}
            classes={{ root: styles.accountRoot, name: styles.accountName }}
          />
          {isOwner && (
            <span className={styles.accountOwner}>({t('cases.details.ownerLabel')})</span>
          )}
        </Row>
      </div>
    </Skeleton>
  )
}
