import { BusinessAccountDirectionEnum } from '@medentee/enums'
import { memo, useLayoutEffect, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  useAppSelector,
  GET_CHAT_ROOMS,
  GET_VOICE_ROOMS,
  isBusinessAccountSelector,
  useAppDispatch,
  getOrganizationsInvitationsRequest
} from 'store'
import { EBusinessAdminConfirmFrom } from 'enums'
import {
  UnityHubChannelIcon,
  TUnityHubRoomsNavLayoutItem,
  TUnityHubRoomsNavLayoutControl,
  UnityHubRoomsNavLayout
} from 'App/components/UnityHub'

import { UnityHubRoomsNavEventsList } from '../../UnityHub/UnityHubRoomsNavEventsList'

import { ReceivedAdministrations } from './ReceivedAdministrations'
import { RoomsNavVoiceList } from './RoomsNavVoiceList'
import { RoomsNavTextList } from './RoomsNavTextList'

const loadingChatRoomsSelector = createLoadingSelector([GET_CHAT_ROOMS])
const loadingVoiceRoomsSelector = createLoadingSelector([GET_VOICE_ROOMS])

type TOrganizationRoomsNavProps = {
  organizationId: string
}

const OrganizationRoomsNavView = ({ organizationId }: TOrganizationRoomsNavProps) => {
  const dispatch = useAppDispatch()

  const { url } = useRouteMatch()

  const loadingChatRooms = useAppSelector(
    (state) => loadingChatRoomsSelector(state) && !state.chat.chatRooms.ids.length
  )
  const loadingVoiceRooms = useAppSelector(loadingVoiceRoomsSelector)
  const isLeaderInDepartments = useAppSelector(
    (state) => state.organizations.data.list[organizationId]?.isLeaderInDepartments
  )
  const isBusiness = useAppSelector(isBusinessAccountSelector)
  const { invitesToAdmin, invitesToOrganization } = useAppSelector(
    (state) => state.notifications.general.organizations?.list?.[organizationId] ?? {}
  )
  const receivedInvitesToAdminTotal = useAppSelector(
    (state) => state.organizations.invitations.received.total
  )

  const { t } = useTranslation()

  const shouldShowAdministrationNav = isLeaderInDepartments || isBusiness

  const items = useMemo<TUnityHubRoomsNavLayoutItem[]>(
    () => [
      {
        hidden: isBusiness || !receivedInvitesToAdminTotal,
        key: 'received-administrations',
        item: (
          <ReceivedAdministrations
            url={url}
            count={invitesToAdmin}
            total={receivedInvitesToAdminTotal}
          />
        )
      },
      {
        key: 'events',
        item: <UnityHubRoomsNavEventsList organizationId={organizationId} />,
        loading: loadingChatRooms
      },
      {
        key: 'text-channel',
        item: <RoomsNavTextList organizationId={organizationId} />
      },
      {
        key: 'voice-channel',
        item: <RoomsNavVoiceList organizationId={organizationId} />,
        loading: loadingVoiceRooms
      }
    ],
    [
      url,
      invitesToAdmin,
      isBusiness,
      loadingVoiceRooms,
      loadingChatRooms,
      organizationId,
      receivedInvitesToAdminTotal
    ]
  )

  const controls = useMemo<TUnityHubRoomsNavLayoutControl[]>(
    () => [
      {
        hidden: !isBusiness,
        key: '',
        to: `${url}/invitations`,
        icon: <UnityHubChannelIcon variant="invitation" />,
        name: t('organizations.invitationsLabel'),
        count: invitesToOrganization
      },
      {
        hidden: !shouldShowAdministrationNav,
        key: 'administration',
        to: `${url}/administration`,
        icon: <UnityHubChannelIcon variant="administration" />,
        name: t('organizations.administrationLabel'),
        count: invitesToAdmin
      }
    ],
    [isBusiness, url, t, invitesToOrganization, shouldShowAdministrationNav, invitesToAdmin]
  )

  useLayoutEffect(() => {
    // This query is needed to find out if you want to hide the room nav
    dispatch(
      getOrganizationsInvitationsRequest({
        from: EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION,
        organizationId,
        markRead: false,
        direction: BusinessAccountDirectionEnum.OUTGOING
      })
    )
  }, [dispatch, organizationId])

  return <UnityHubRoomsNavLayout items={items} controls={controls} />
}

export const OrganizationRoomsNav = memo(OrganizationRoomsNavView)
