import { ChatTypeEnum } from '@medentee/enums'

import {
  ICommunityDTO,
  ICommunityMemberDTO,
  TChannelVariant,
  ICommunityInvitationLinkDTO,
  ICommunityJoinToChannelLinkDTO
} from 'interfaces'
import { ICreateTextChannelDTO } from 'interfaces/api/organizations/textChannel'
import { API, APIData, APIResultsResponse, api } from 'services/api'
import { TAccount, TIds } from 'store'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { QueryBuilder } from 'utils'

type TGetCommunityPayload = { id: string }
export const getCommunity = async ({ id }: TGetCommunityPayload) => {
  const { data }: APIData<ICommunityDTO> = await api.get(API.COMMUNITY(id))

  return data
}

type TEditCommunityOrganizationSettingsPayload = Pick<ICommunityDTO, 'publicity'> & {
  id: string
  membershipEventIds: TIds
}

export const editCommunityOrganizationSettings = async ({
  id,
  membershipEventIds,
  publicity
}: TEditCommunityOrganizationSettingsPayload) =>
  await api.patch(API.COMMUNITY(id), { membershipEventIds, publicity })

type TJoinCommunityProps = {
  communityId: string
  invitationToken: string
}

export const joinCommunity = async ({ communityId, invitationToken }: TJoinCommunityProps) => {
  const { data } = await api.post(
    API.COMMUNITY_JOIN(communityId),
    invitationToken ? { invitationToken } : undefined
  )

  return data
}

export type TCreateCommunityPayload = {
  title: string
  description: string
  tags: string[]

  countryCode?: string
}

export const createCommunity = async (payload: TCreateCommunityPayload) => {
  const { data }: APIData<ICommunityDTO> = await api.post(API.COMMUNITIES, payload)

  return data
}

export type TUpdateCommunityPayload = {
  id: string
  payload: TCreateCommunityPayload | FormData
}

export const updateCommunity = async ({ id, payload }: TUpdateCommunityPayload) => {
  const { data }: APIData<ICommunityDTO> = await api.patch(
    API.COMMUNITY(id),
    payload,
    payload instanceof FormData
      ? {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      : undefined
  )

  return data
}

type TGetCommunityRoomAccountsPayload = {
  channelId: string
}

export const getCommunityRoomAccounts = async ({
  channelId = ''
}: TGetCommunityRoomAccountsPayload) => {
  const { data }: APIResultsResponse<TAccount[]> = await api.get(API.CHAT_MEMBERS(channelId))

  return data
}

export type TGetCommunityMembersPayload = {
  communityId: string

  search?: string
  page?: number
  showBy?: number | string
  professionIds?: string[]
  eventIds?: string[]
  countryCodes?: string[]
  chatId?: string
  onlyContacts?: boolean
  online?: 'ONLINE' | 'OFFLINE'
  markChatIds?: string[]
  markVoiceRoomIds?: string[]
}

export const getCommunityMembers = async ({
  communityId,
  search,
  page,
  chatId,
  online,
  onlyContacts,
  professionIds,
  eventIds,
  countryCodes,
  markChatIds,
  markVoiceRoomIds,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetCommunityMembersPayload) => {
  const url = new QueryBuilder(API.COMMUNITY_MEMBERS(communityId))
    .searchQuery(search)
    .page(page)
    .showBy(showBy)
    .custom('chatId', chatId)
    .multiSearch('professionIds', professionIds)
    .multiSearch('eventIds', eventIds)
    .multiSearch('countryCodes', countryCodes)
    .multiSearch('markChatIds', markChatIds)
    .multiSearch('markVoiceRoomIds', markVoiceRoomIds)
    .custom('onlineTypes', online)
    .custom('onlyContacts', onlyContacts)
    .build()

  const { data }: APIResultsResponse<ICommunityMemberDTO[]> = await api.get(url)

  return data
}

type TRemoveCommunityMemberPayload = {
  communityId: string
  memberId: string
}

export const removeCommunityMember = async ({
  memberId,
  communityId
}: TRemoveCommunityMemberPayload) => await api.delete(API.COMMUNITY_MEMBER(communityId, memberId))

type TCreateChannelPayload = {
  communityId: string
  name: string
  accountIds: string[]
  isPublic: boolean
  variant: Extract<TChannelVariant, 'chat' | 'news'>

  channelManagerId?: string | null
}

type TEditChannelPayload = PartialBy<
  TCreateChannelPayload,
  'accountIds' | 'channelManagerId' | 'name' | 'variant'
> & {
  channelId: string
}

export const createCommunityChannel = async ({
  communityId,
  name,
  channelManagerId,
  accountIds,
  isPublic,
  variant
}: TCreateChannelPayload) => {
  const { data }: APIData<ICreateTextChannelDTO> = await api.post(
    API.COMMUNITY_TEXT_CHANNELS(communityId),
    {
      name,
      isPublic,
      channelManagerId,
      accountIds,
      type: variant === 'news' ? ChatTypeEnum.COMMUNITY_NEWS : ChatTypeEnum.COMMUNITY_CHANNEL
    }
  )

  return data
}

export const editCommunityChannel = async ({
  communityId,
  channelId,
  name,
  isPublic,
  channelManagerId,
  accountIds
}: TEditChannelPayload) => {
  const { data }: APIData = await api.patch(API.COMMUNITIES_TEXT_CHANNEL(communityId, channelId), {
    name,
    isPublic,
    channelManagerId,
    accountIds
  })

  return data
}

export const createCommunityInviteLink = async (communityId: string) => {
  const { data }: APIData<ICommunityInvitationLinkDTO> = await api.post(
    API.COMMUNITY_SHARED_LINK(communityId)
  )

  return data
}

export const deactivateCommunityInviteLink = async (communityId: string) =>
  await api.delete(API.COMMUNITY_SHARED_LINK(communityId))

type TCommunityLeaveChannelPayload = {
  communityId: string
  channelId: string
}

export const communityLeaveChannel = async ({
  communityId,
  channelId
}: TCommunityLeaveChannelPayload) =>
  await api.delete(API.COMMUNITY_LEAVE_CHANEL(communityId, channelId))

type TDeleteChannelPayload = {
  communityId: string
  channelId: string
}

export const communityDeleteChannel = async ({ communityId, channelId }: TDeleteChannelPayload) => {
  const response = await api.delete(API.COMMUNITIES_TEXT_CHANNEL(communityId, channelId))

  return response
}

export const deleteCommunityAvatar = async (communityId: string) =>
  await api.delete(API.GET_COMMUNITY_AVATAR_URL(communityId))

export const deleteCommunityCover = async (communityId: string) =>
  await api.delete(API.GET_COMMUNITY_COVER_URL(communityId))

type TJoinToCommunityChannelPayload = {
  communityId: string
  token: string
}

type TGetCommunityTokenPayload = {
  communityId: string
  token: string
}

export const joinToCommunityChannel = async ({
  communityId,
  token
}: TJoinToCommunityChannelPayload) => {
  const { data }: APIData<ICommunityJoinToChannelLinkDTO> = await api.post(
    API.COMMUNITY_CHANNEL_JOIN(communityId, token)
  )

  return data
}

export const getCommunityJoinToChannelTokenInfo = async ({
  communityId,
  token
}: TGetCommunityTokenPayload) => {
  const { data }: APIData<ICommunityJoinToChannelLinkDTO> = await api.get(
    API.COMMUNITY_CHANNEL_JOIN(communityId, token)
  )

  return data
}

export const deleteCommunity = async (communityId: string) =>
  await api.delete(API.COMMUNITY(communityId))
