import { CaseClassificationIdEnum } from '@medentee/enums'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { AlertLink, Alert } from 'App/components'

import styles from './CaseCreateDialogSubscriptionAlert.module.scss'

export type TCaseCreateDialogSubscriptionAlertProps = PropsWithClassName<{
  caseClassificationId?: CaseClassificationIdEnum
}>

export const CaseCreateDialogSubscriptionAlert = ({
  className,
  caseClassificationId
}: TCaseCreateDialogSubscriptionAlertProps) => {
  const { t } = useTranslation()

  const shouldShowAlert =
    caseClassificationId && caseClassificationId !== CaseClassificationIdEnum.PRIVATE

  return shouldShowAlert ? (
    <Alert variant="info" size="sm" className={cls(styles.root, className)}>
      {t('cases.modal.createCase.subscriptionAlert')}
      <AlertLink to="/subscription-and-payments/subscription">
        {t('cases.modal.createCase.subscriptionAlertUpgrade')}
      </AlertLink>
    </Alert>
  ) : null
}
