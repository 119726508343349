import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { toast } from 'App/components/ToastContainer'
import { ECaseAccountType } from 'enums'
import { toastDefaultOptions } from 'globalConstants'
import {
  TIds,
  caseViewRequest,
  getCaseMembersRequest,
  hideModalAction,
  useAppDispatch
} from 'store'
import { handleDefaultError } from 'utils/toast'
import { inviteMember } from 'api/cases'
import { isMatchErrorCode } from 'utils/errorParsing'

type TInviteMemberPayload = {
  caseId: string
  addAccountIds: TIds
}

export const useCaseInviteMembersRequest = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()

  const { isLoading, mutate } = useMutation({
    mutationKey: ['invite-members'],
    mutationFn: inviteMember
  })

  const { t } = useTranslation()

  const invite = useCallback(
    ({ addAccountIds, caseId }: TInviteMemberPayload) => {
      mutate(
        { caseId, addAccountIds },
        {
          onSuccess: () => {
            dispatch(getCaseMembersRequest({ caseId }))
            dispatch(hideModalAction())
            push(`/cases/${caseId}/owner`)
          },
          onError: (error) => {
            if (
              isMatchErrorCode(error, [
                ErrorCodesEnum.CASE_IS_NOT_ACTIVE,
                ErrorCodesEnum.CASE_IS_NOT_A_MANUAL_MODE
              ])
            ) {
              toast.error(t('cases.modal.inviteMembers.invitationError'), toastDefaultOptions)
              dispatch(hideModalAction())
            } else {
              handleDefaultError(error)
            }
          },
          onSettled: () => {
            dispatch(
              caseViewRequest({
                type: ECaseAccountType.OWNER,
                id: caseId
              })
            )
          }
        }
      )
    },
    [dispatch, mutate, push, t]
  )

  return { invite, isLoading }
}
