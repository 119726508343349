import { useEffect, useLayoutEffect } from 'react'
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { BroadcastTypeEnum, ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  getChatRoomsRequest,
  getBroadcastsRequest,
  resetChatAction,
  setCommunityId,
  setOrganizationId,
  useAppDispatch,
  resetBroadcastList
} from 'store'
import { ICommunityDTO } from 'interfaces'
import { ChatOrganization, EmptyList, UnityHubBackButton, UnityHubViewLayout } from 'App/components'
import { RoleRouteContainer } from 'App/containers'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { PageWrapperContextProvider } from 'App/components/PageWrapper/PageWrapperContext'
import { ELayoutNavKey } from 'enums'
import { useSessionStorage } from 'App/hooks/useSessionStorage'
import { BroadcastRoomContainer } from 'App/components/Broadcasts'

import { CommunityHeader } from '../CommunityHeader'
import { CommunityRoomsNav } from '../CommunityRoomsNav'
import { CommunityMembers } from '../CommunityMembers'
import { CommunityMembersFilters } from '../CommunityMembersFilters'
import { CommunityInfo } from '../CommunityInfo'

import { useJoinToChannel } from './useJoinToChannel'

export type TCommunityViewProps = {
  loading: boolean
  community: ICommunityDTO
}

export const CommunityView = (props: TCommunityViewProps) => {
  const { loading, community } = props

  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()

  const { setSessionStorageData } = useSessionStorage({ keySuffix: ELayoutNavKey.COMMUNITIES })

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  useJoinToChannel(community.id)

  const defaultPath = `/${path === '/community' ? 'community' : 'communities'}/${community.id}/info`

  useLayoutEffect(() => {
    setSessionStorageData(url)
  }, [setSessionStorageData, pathname, url])

  useEffect(
    () => () => {
      dispatch(resetBroadcastList())
      dispatch(resetChatAction())
      dispatch(setOrganizationId({ nextOrganizationId: null }))
    },
    [dispatch]
  )
  useEffect(() => {
    if (community.id) {
      dispatch(
        getBroadcastsRequest({ communityIds: [community.id], type: BroadcastTypeEnum.COMMUNITY })
      )

      dispatch(
        getChatRoomsRequest({
          communityId: community.id,
          chatType: [ChatTypeEnum.COMMUNITY_CHANNEL, ChatTypeEnum.COMMUNITY_NEWS]
        })
      )

      dispatch(setCommunityId({ nextCommunityId: community.id }))
    }
  }, [dispatch, community.id])

  return (
    <PageWrapperContextProvider>
      <UnityHubViewLayout
        header={
          <Switch>
            <RoleRouteContainer
              exact
              path={`${path}/members`}
              render={() => <UnityHubBackButton path={path} />}
            />
            <RoleRouteContainer
              path={path}
              render={() => (
                <CommunityHeader
                  loading={loading}
                  id={community.id}
                  organizationId={community.organization?.id}
                  name={community.title}
                />
              )}
            />
          </Switch>
        }
        content={
          <Switch>
            <Redirect exact push={false} to={defaultPath} from={path} />

            <RoleRouteContainer
              exact
              path={`${path}/chat/:id`}
              render={() => (
                <ChatOrganization
                  id={community.id}
                  path={path}
                  keySuffix={ELayoutNavKey.COMMUNITIES}
                  variant="chat"
                />
              )}
            />

            <RoleRouteContainer
              exact
              path={`${path}/news/:id`}
              render={() => (
                <ChatOrganization
                  id={community.id}
                  path={path}
                  keySuffix={ELayoutNavKey.COMMUNITIES}
                  variant="news"
                />
              )}
            />

            <RoleRouteContainer
              exact
              path={`${path}/broadcast/:broadcastId`}
              render={() => <BroadcastRoomContainer />}
            />

            <RoleRouteContainer
              path={defaultPath}
              render={() => <CommunityInfo id={community.id} />}
            />

            <RoleRouteContainer
              exact
              path={`${path}/members`}
              render={() => <CommunityMembers path={path} />}
            />

            <RoleRouteContainer
              render={() => (
                <EmptyList icon={<AccountGroupIcon />} text={t('communities.placeholder')} />
              )}
            />
          </Switch>
        }
        nav={
          <Switch>
            <RoleRouteContainer
              exact
              path={`${path}/members`}
              render={() => <CommunityMembersFilters />}
            />
            <RoleRouteContainer
              path={path}
              render={() => <CommunityRoomsNav communityId={community.id} />}
            />
          </Switch>
        }
      />
    </PageWrapperContextProvider>
  )
}
