import { zonedTimeToUtc } from 'date-fns-tz'
import { getMinutes, add } from 'date-fns'

import { TTimeRangePickerProps } from '../common'

import { TScheduleMeetingModalFormValues } from './ScheduleMeetingModal'

const FIFTEEN_MINUTES = 15
const THIRTY_MINUTES = 30
const FORTY_FIVE_MINUTES = 45
const SIXTY_MINUTES = 60

export const getMeetingTime = (
  date: Date,
  time: TTimeRangePickerProps['value'],
  timeZone: string
) => {
  if (date && timeZone && time && time[0] && time[1]) {
    const startOn = new Date(date)
    const endOn = new Date(date)

    startOn.setHours(time[0].getHours())
    startOn.setMinutes(time[0].getMinutes())
    endOn.setHours(time[1].getHours())
    endOn.setMinutes(time[1].getMinutes())

    return {
      startOn: zonedTimeToUtc(startOn, timeZone).toISOString(),
      endOn: zonedTimeToUtc(endOn, timeZone).toISOString()
    }
  }

  return {
    startOn: '',
    endOn: ''
  }
}

export const getInitialTime = (): TScheduleMeetingModalFormValues['time'] => {
  const now = new Date()
  const currentMinutes = getMinutes(now)

  let addedMinutes = 0

  switch (true) {
    case currentMinutes < FIFTEEN_MINUTES:
      addedMinutes = FIFTEEN_MINUTES - currentMinutes
      break
    case currentMinutes < THIRTY_MINUTES:
      addedMinutes = THIRTY_MINUTES - currentMinutes
      break
    case currentMinutes < FORTY_FIVE_MINUTES:
      addedMinutes = FORTY_FIVE_MINUTES - currentMinutes
      break
    case currentMinutes < SIXTY_MINUTES:
      addedMinutes = SIXTY_MINUTES - currentMinutes
      break

    default:
      break
  }

  const start = add(now, { minutes: addedMinutes })
  const end = add(start, { hours: 1 })

  return [start, end]
}
