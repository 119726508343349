import { useInvitationsRoutes } from 'App/screens/Invitations/useInvitationsRoutes'

import { Menu } from './Menu'

export const InvitationMenu = () => {
  const {
    navMenu: { received, sent }
  } = useInvitationsRoutes()

  const shouldHideMenu =
    (received.length === 0 && sent.length === 1) || (sent.length === 0 && received.length === 1)

  return shouldHideMenu ? null : <Menu received={received} sent={sent} />
}
