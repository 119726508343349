import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { UnityHubRoomsNavCollapse } from 'App/components/UnityHub'
import { BroadcastNavItem } from 'App/components/Broadcasts/BroadcastNavItem/BroadcastNavItem'
import {
  BroadcastCreateIconButton,
  TBroadcastCreateIconButtonProps
} from 'App/components/Broadcasts/BroadcastCreateIconButton'

export type TUnityHubRoomsBroadcastListProps = Pick<
  TBroadcastCreateIconButtonProps,
  'entityId' | 'type'
>

export const UnityHubRoomsBroadcastList = ({
  entityId,
  type
}: TUnityHubRoomsBroadcastListProps) => {
  const ids = useAppSelector((state) => state.broadcasts.data.ids)

  const { t } = useTranslation()

  return (
    <UnityHubRoomsNavCollapse
      variant="broadcast"
      title={t('unityHub.channelsList.liveStreamsLabel')}
      localStorageKey={`broadcast-${entityId}`}
      action={<BroadcastCreateIconButton entityId={entityId} type={type} />}
    >
      {ids.map((id) => (
        <BroadcastNavItem key={id} broadcastId={id} />
      ))}
    </UnityHubRoomsNavCollapse>
  )
}
