import { FileHistorySourcesEnum } from '@medentee/enums'

import { IFilesEntity } from 'interfaces'
import { TIds } from 'store/store.types'

import { TShowExtendableCardSlide } from './extendableCard.types'

export type TSlideEntity = PartialBy<
  Pick<IFilesEntity, 'id' | 'fileName' | 'extension' | 'thumbnailUrl' | 'mimeType'>,
  'thumbnailUrl' | 'mimeType'
>[]

export const createSlidesEntity = <
  L extends Record<
    string,
    Pick<IFilesEntity, 'fileName' | 'extension' | 'mimeType' | 'thumbnailUrl'>
  >
>(
  ids: TIds,
  list: L
): TSlideEntity =>
  ids.map((id) => {
    const { fileName, extension, thumbnailUrl, mimeType } = list[id] ?? {}

    return {
      id,
      fileName,
      extension,
      mimeType,
      thumbnailUrl
    }
  })

export const createSlides = (
  slidesEntity: TSlideEntity,
  source: FileHistorySourcesEnum,
  originalEntityId?: string
): TShowExtendableCardSlide[] =>
  slidesEntity.map(({ fileName, extension, id, mimeType, thumbnailUrl }) => ({
    fileName,
    id,
    mimeType,
    extension,
    thumbnailUrl,
    source,
    originalEntityId
  }))
