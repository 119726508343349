import { ChangeEvent, useCallback, useState } from 'react'
import { Field } from 'react-final-form'
import { FormApi } from 'final-form'
import { ErrorCodesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { businessAccountSendCode } from 'api/invitations'
import { isMatchErrorCode, validation } from 'utils'
import {
  ErrorModal,
  StepsFooterActions,
  TCreateBusinessAccountFormValues,
  TextField
} from 'App/components'

import styles from '../BusinessAccountCreateDialog.module.scss'

type TCompanyEmailProps = {
  form: FormApi<TCreateBusinessAccountFormValues>
  handleStepForward: () => void
  handleStepBack: () => void
}

export const CompanyEmail = ({ form, handleStepBack, handleStepForward }: TCompanyEmailProps) => {
  const { t } = useTranslation()

  const [emailError, setEmailError] = useState('')

  const emailFieldState = form.getFieldState('email')

  const { mutate, error, isLoading, reset } = useMutation({
    mutationKey: ['send-2fa-code-to-business-email'],
    mutationFn: businessAccountSendCode,
    onError: (e) => {
      if (isMatchErrorCode(e, ErrorCodesEnum.ACCOUNT_EXISTS)) {
        return setEmailError(t('serverError.ACCOUNT_EXISTS', { ns: 'errors' }))
      }
    },
    onSuccess: (data) => {
      form.change('expiredTokenDate', data)
      handleStepForward()
    }
  })

  const shouldShowModalError = !isMatchErrorCode(error, ErrorCodesEnum.ACCOUNT_EXISTS)

  const handleSubmit = useCallback(() => {
    const email = emailFieldState?.value ?? ''

    if (!email) {
      return
    }

    mutate(email)
  }, [emailFieldState, mutate])

  return (
    <>
      <Field name="email" validate={validation.required() && validation.isEmail()}>
        {({ input, meta }) => {
          const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            setEmailError('')
            reset()
            input.onChange(e.currentTarget.value.trim())
          }

          return (
            <TextField
              {...input}
              onChange={onChange}
              invalid={meta.touched && (meta.invalid || !!emailError)}
              error={meta.error || emailError}
              hideCounter={true}
              classes={{ wrapper: styles.input }}
              topLabel={t('modal.createBusinessAccount.companyEmailStep.emailFiled')}
            />
          )
        }}
      </Field>

      <StepsFooterActions
        submitLoading={isLoading}
        submitLabel={t('modal.createBusinessAccount.companyEmailStep.submitButton')}
        submitDisabled={emailFieldState?.invalid || !!emailError}
        onClickBack={handleStepBack}
        onSubmit={handleSubmit}
      />

      {shouldShowModalError && <ErrorModal error={error} />}
    </>
  )
}
