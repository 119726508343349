import { useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { List } from 'App/components/Organizations/OrganizationInvitations/List'
import {
  createLoadingSelector,
  getOrganizationNewUsersRequest,
  getOrganizationsInvitationsFiltersSelector,
  GET_ORGANIZATION_NEW_USERS,
  useAppDispatch,
  useAppSelector
} from 'store'

const loadingSelector = createLoadingSelector([GET_ORGANIZATION_NEW_USERS])

export const OrganizationInvitationsNewUsers = () => {
  const dispatch = useAppDispatch()
  const { current, showBy } = useAppSelector(getOrganizationsInvitationsFiltersSelector, isEqual)
  const ids = useAppSelector((state) => state.organizations.invitations.newUsers.ids, isEqual)
  const total = useAppSelector((state) => state.organizations.invitations.newUsers.total)
  const loading = useAppSelector(loadingSelector)

  const handlePaginationChange = useCallback(
    (page: number) => {
      dispatch(getOrganizationNewUsersRequest({ current: page, showBy }))
    },
    [dispatch, showBy]
  )

  useEffect(() => {
    dispatch(getOrganizationNewUsersRequest())
  }, [dispatch])

  return (
    <List
      ids={ids}
      loading={loading}
      type="new-users"
      pagination={{
        total,
        current,
        showBy,
        onChange: handlePaginationChange
      }}
    />
  )
}
