import { MutableRefObject, useEffect, useState } from 'react'
import { differenceInSeconds } from 'date-fns'

import { timerFormatted } from 'utils'
import { DEFAULT_TIME_HELPER, SECOND, SECOND_IN_MINUTE } from 'globalConstants'

export type TTimerRef = MutableRefObject<{ time: number }>

export type TTimerProps = {
  timeRef?: TTimerRef
  startTime?: Date | null
}

export const Timer = ({ timeRef, startTime }: TTimerProps) => {
  const [time, setTime] = useState(Date.now() + DEFAULT_TIME_HELPER)
  const [start] = useState(startTime ? +new Date(startTime) : Date.now())

  useEffect(() => {
    if (timeRef) {
      timeRef.current.time = Math.floor((time - start) / 1000) // Return diff seconds
    }
  }, [time])

  useEffect(() => {
    const intervalId = setInterval(() => setTime(+new Date()), SECOND)
    return () => clearInterval(intervalId)
  }, [])

  const timestamp = differenceInSeconds(new Date(time), start)
  const hours = Math.floor(timestamp / SECOND_IN_MINUTE / SECOND_IN_MINUTE)
  const minutes = Math.floor(timestamp / SECOND_IN_MINUTE) - hours * SECOND_IN_MINUTE
  const seconds = timestamp % SECOND_IN_MINUTE

  return <>{timerFormatted(hours, minutes, seconds)}</>
}
