import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { AccountName } from 'App/components/common'
import { EAvatarSize } from 'enums'
import { TMention } from 'utils/mentions'

import { GeneralSuggestion } from '../GeneralSuggestion'

import styles from './AccountSuggestion.module.scss'

export type TAccountSuggestionProps = EntryComponentProps

export const AccountSuggestion = ({
  mention,
  theme,
  searchValue,
  isFocused,
  selectMention,
  ...parentProps
}: TAccountSuggestionProps) => {
  const { t } = useTranslation()

  const data = (mention as TMention) ?? {}

  if (data.variant === 'account') {
    return (
      <div {...parentProps} className={cls(styles.account, theme?.mentionSuggestionsEntry)}>
        <AccountName
          userId={data.id}
          displayUserName={data.displayUserName}
          firstName={data.firstName}
          lastName={data.lastName}
          type={data.type?.name}
          size={EAvatarSize.XS}
          showAvatar={true}
          autoOnline={true}
          hideAccountType={true}
          classes={{ root: styles.accountName }}
          chipList={[
            {
              hidden: data.id !== data.channelManagerId,
              color: 'success',
              variant: 'square',
              text: t('common.draftEditor.accountSuggestion.channelManagerChip')
            }
          ]}
        />
      </div>
    )
  }

  if (data.variant === 'department') {
    return (
      <div {...parentProps} className={theme?.mentionSuggestionsEntry}>
        <AccountName
          userId={data.id}
          displayUserName={data.name}
          size={EAvatarSize.XS}
          classes={{ root: styles.accountName }}
          showAvatar={true}
          online={false}
          hideAccountType={true}
          variant="team"
        />
      </div>
    )
  }

  if (data.variant === 'general') {
    if (data.id === 'here') {
      return (
        <GeneralSuggestion
          {...parentProps}
          mention={mention}
          theme={theme}
          label={t('common.draftEditor.accountSuggestion.hereDescription')}
        />
      )
    }

    if (data.id === 'everyone') {
      return (
        <GeneralSuggestion
          {...parentProps}
          mention={mention}
          theme={theme}
          label={t('common.draftEditor.accountSuggestion.everyoneDescription')}
        />
      )
    }
  }

  if (data.variant === 'groupHeader') {
    return (
      <div className={styles.header} onClick={(e) => e.preventDefault()}>
        {mention.name}
      </div>
    )
  }

  return (
    <div {...parentProps} className={theme?.mentionSuggestionsEntry}>
      {data.name}
    </div>
  )
}
