import { ReactNode, useMemo } from 'react'

import {
  createProcessingBySelector,
  createProcessingSelector,
  hideModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'

import { Button, ConfirmationModal, TConfirmationModalProps } from '../../components'
import { useIsMounted, useToggle } from '../../hooks'
import { TResponseError } from '../../../utils'

import styles from './GenericConfirmation.module.scss'

export type TGenericConfirmationProps = Omit<TConfirmationModalProps, 'controls'> & {
  confirmLabel: ReactNode

  cancelLabel?: ReactNode
  onConfirm: () => void | Promise<any>
  onCancel?: () => void | Promise<any>
  processingId?: string
  loadingActions?: string[]
  errorKey?: string
}
export const GenericConfirmation = ({
  confirmLabel,
  cancelLabel,
  errorKey,
  processingId,
  loadingActions,
  onConfirm,
  onCancel,
  ...props
}: TGenericConfirmationProps) => {
  const dispatch = useAppDispatch()

  const isMounted = useIsMounted()

  const { value: processing, setValue: setProcessing } = useToggle()
  const { value: cancelling, setValue: setCancelling } = useToggle()

  const loadingSelector = useMemo(() => {
    if (loadingActions) {
      return processingId
        ? createProcessingBySelector(loadingActions)(processingId)
        : createProcessingSelector(loadingActions)
    }
    return () => false
  }, [loadingActions, processingId])

  const loading = useAppSelector(loadingSelector)
  const error: TResponseError | undefined = useAppSelector((state) =>
    errorKey ? state.errors[errorKey] : undefined
  )

  const handleConfirm = () => {
    const result = onConfirm()

    if (result instanceof Promise) {
      setProcessing(true)
      result
        .then(() => {
          dispatch(hideModalAction())
        })
        .finally(() => {
          if (isMounted.current) {
            setProcessing(false)
          }
        })
    } else if (!loadingActions) {
      dispatch(hideModalAction())
    }
  }
  const handleCancel = () => {
    if (!onCancel) {
      return
    }

    const result = onCancel()

    if (result instanceof Promise) {
      setCancelling(true)
      result
        .then(() => {
          dispatch(hideModalAction())
        })
        .finally(() => {
          if (isMounted.current) {
            setCancelling(false)
          }
        })
    }
  }

  return (
    <ConfirmationModal
      {...props}
      classes={{ controls: styles.controls }}
      error={error}
      controls={
        <>
          {onCancel && (
            <Button
              variant="outlined"
              onClick={handleCancel}
              loading={cancelling}
              disabled={loading || processing}
            >
              {cancelLabel}
            </Button>
          )}
          <Button onClick={handleConfirm} loading={loading || processing} disabled={cancelling}>
            {confirmLabel}
          </Button>
        </>
      }
    />
  )
}
