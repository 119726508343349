import { AccountTypeNames } from '@medentee/enums'
import cls from 'classnames'

import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg'
import { ReactComponent as BusinessAccountIcon } from 'assets/icons/BusinessAccount.svg'
import { getMapComponent } from 'utils'

import styles from './AccountType.module.scss'

export type TAccountTypeProps = PropsWithClassName<{
  type: AccountTypeNames
}>

const ICON_MAP = new Map<AccountTypeNames, (className: string) => JSX.Element>()
  .set(AccountTypeNames.BUSINESS, (className) => <BusinessAccountIcon className={className} />)
  .set(AccountTypeNames.PROFESSIONAL, (className) => <AccountIcon className={className} />)

export const AccountType = ({ type, className }: TAccountTypeProps) =>
  getMapComponent(ICON_MAP, type, cls(styles.icon, className))
