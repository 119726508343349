import { ChatTypeEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { TChatHistoryMessage } from 'store'
import {
  getGroupMessageDate,
  getImportantMessages,
  isMessageGroupEnd,
  isMessageGroupStart
} from 'utils'

export const getMoreChatMessagePropsSelector =
  (currentId: string) =>
  (state: State): TChatHistoryMessage => {
    const alreadySawDates: Set<string> = new Set()
    const { ids, list } = state.chat.chatMessages.messages
    const index = ids.findIndex((id) => id === currentId)
    const isFirstMessage = index === 0

    const { currentMessage, prevMessage, nextMessage } = getImportantMessages({
      currentId,
      list,
      ids,
      index
    })

    const [showDate, matchDate] = getGroupMessageDate({
      currentTimestamp: currentMessage?.unixTs ?? Date.now(),
      prevTimestamp: prevMessage?.unixTs ?? Date.now(),
      isFirst: isFirstMessage,
      alreadySawDates
    })

    const isShowDate = alreadySawDates.has(matchDate) ? false : showDate

    const groupStart = isMessageGroupStart({
      accountId: currentMessage?.senderId,
      currentMessageSenderId: currentMessage?.senderId,
      prevMessageSenderId: prevMessage?.senderId,
      prevMessageType: prevMessage?.type,
      isShowDate
    })

    const groupEnd = isMessageGroupEnd({
      accountId: currentMessage?.senderId,
      currentMessageSenderId: currentMessage?.senderId,
      nextMessageSenderId: nextMessage?.senderId,
      nextMessageType: nextMessage?.type
    })

    const lastMessage = ids[ids.length - 1] === currentId

    if (showDate) {
      alreadySawDates.add(matchDate)
    }

    return {
      ...currentMessage,

      // ----------------- custom ---------------------
      senderId: currentMessage?.senderId,
      groupStart,
      groupEnd,
      showDate: isShowDate,
      lastMessage
    }
  }

export const isMessageOutgoingSelector = (yourMessage: boolean) => (state: State) => {
  const type = state.chat.chatRooms.selectedChat?.type

  return yourMessage && type !== ChatTypeEnum.COMMUNITY_NEWS && type !== ChatTypeEnum.EVENT_NEWS
}

export const getLastMessagesSelector = (chatId: string | undefined | null, state: State) => {
  if (chatId) {
    return state.chat.chatRooms.list[chatId]?.lastMessage
  }

  return undefined
}
