import { FC, useEffect, useMemo } from 'react'
import { Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Field, FieldRenderProps } from 'react-final-form'
import { GetCountryQueryModeEnum } from '@medentee/enums'

import { Select, Help } from 'App/components'
import { validation } from 'utils'
import { createLoadingSelector, getCountriesRequest, GET_COUNTRIES, TCountry } from 'store'
import { State } from 'redux/rootReducer'

const LOCATION_MAX_LENGTH = 3
const loadingCountriesSelector = createLoadingSelector([GET_COUNTRIES])

const hasAnywhere = (options?: Readonly<TCountry[]> | null) =>
  options?.some(({ code }) => code === 'any')

export const CreateAdvertThirdStep: FC = () => {
  const dispatch = useDispatch()

  const countries = useSelector((state: State) => state.misc.countries)
  const loadingCountries = useSelector(loadingCountriesSelector)

  const locations = useMemo(
    () => [{ code: 'any', countryName: 'Anywhere' }, ...countries],
    [countries]
  )

  useEffect(() => {
    dispatch(getCountriesRequest({ mode: GetCountryQueryModeEnum.ALL_WITH_UNIONS }))
  }, [dispatch])

  return (
    <>
      <Col xs={24}>
        <Field
          name="location"
          validate={validation.composeValidators(
            validation.required(),
            validation.maxLength(LOCATION_MAX_LENGTH)
          )}
        >
          {({
            input: { onChange, value: currentValue, ...input }
          }: FieldRenderProps<TCountry[]>) => {
            const handleChange = (value: Readonly<TCountry[]> | null) => {
              if (hasAnywhere(currentValue)) {
                onChange(value?.filter(({ code }) => code !== 'any'))
                return
              }

              if (hasAnywhere(value)) {
                onChange(value?.filter(({ code }) => code === 'any'))
                return
              }

              onChange(value)
            }

            return (
              <Select
                topLabel={
                  <>
                    Based in*{' '}
                    <Help content={`You can add maximum ${LOCATION_MAX_LENGTH} countries`} />
                  </>
                }
                labelKey="countryName"
                valueKey="code"
                isMulti={true}
                options={locations}
                isLoading={loadingCountries}
                limit={3}
                showLimitCounter={true}
                value={currentValue}
                onChange={handleChange}
                {...input}
              />
            )
          }}
        </Field>
      </Col>
    </>
  )
}
