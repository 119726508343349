import { RefObject, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatMessage, TScrollChatMessageListRef } from 'App/components'
import { useChatContext } from 'App/context'
import { EChatHistoryEvents, EChatViewType } from 'enums'
import {
  redirectToCaseFromChat,
  readMessagesRequest,
  getMoreChatMessagePropsSelector,
  createLoadingSelector,
  GET_MESSAGES_HISTORY,
  endMessageEditingRequest,
  isMessageOutgoingSelector,
  getSelectedChatRoomSelector
} from 'store'
import { useRefValue } from 'App/hooks'

export type TChatMessageContainerBaseProps = {
  id: string
  scrollListRef: RefObject<TScrollChatMessageListRef>
  onSetMessageRef: (ref: RefObject<HTMLDivElement>) => void

  newMessageLineRef?: null | RefObject<HTMLDivElement>
  chatViewType?: EChatViewType
}

export type TChatMessageContainerProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TChatMessageContainerViewProps = TChatMessageContainerBaseProps &
  TChatMessageContainerProps & {
    caseView?: string
  }

export const ChatMessageContainerView = ({
  id,
  scrollListRef,
  newMessageLineRef,
  endMessageEditing,
  ...props
}: TChatMessageContainerViewProps) => {
  const { chatViewType } = useChatContext()
  const { getValue } = useRefValue(props.editing)

  const { chatId, yourMessage } = props

  useEffect(
    () => () => {
      if (yourMessage && getValue()) {
        endMessageEditing({ chatId, messageId: id })
      }
    },
    [getValue, endMessageEditing, chatId, id, yourMessage]
  )

  return (
    <ChatMessage
      id={id}
      scrollListRef={scrollListRef}
      newMessageLineRef={newMessageLineRef}
      chatViewType={chatViewType}
      endMessageEditing={endMessageEditing}
      {...props}
    />
  )
}

const loadingSelector = createLoadingSelector([GET_MESSAGES_HISTORY])

const mapStateToProps = (state: State, { id, ...own }: TChatMessageContainerBaseProps) => {
  const message = getMoreChatMessagePropsSelector(id)(state)
  const professionalAccount = state.global.accountData?.professionalAccount

  const jumpHistoryEventPayload =
    state.chat.chatMessages.chatHistoryEvent.type === EChatHistoryEvents.JUMP
      ? state.chat.chatMessages.chatHistoryEvent.payload
      : undefined
  const hasReference = jumpHistoryEventPayload?.messageId === id

  return {
    ...message,
    ...own,
    showNewLineSeparator: state.chat.chatMessages.newLineMessageId === id,
    loading: !state.chat.chatMessages.messages.list[id] && loadingSelector(state),
    editing: state.chat.chatMessages.editingIds.includes(id),
    readAll: !!state.chat.chatRooms.selectedChat?.unreadCount || false,
    hasReference,
    jumpHistoryEventPayload: hasReference ? jumpHistoryEventPayload : null,
    receiverId: state.chat.chatRooms.selectedChat?.interlocutorAccount?.id,
    chatRoomType: getSelectedChatRoomSelector(message.chatId)(state)?.type,
    isOriginalSender: professionalAccount
      ? message.originalSender?.id === professionalAccount.id
      : message.yourMessage,
    originalSender: message.call?.originalOfferer ?? message.originalSender,
    accountId: state.global.accountData?.id,
    isMessageOutgoing: isMessageOutgoingSelector(message.yourMessage)(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      redirectToCaseFromChatRequest: redirectToCaseFromChat,
      readMessages: readMessagesRequest,
      endMessageEditing: endMessageEditingRequest
    },
    dispatch
  )

export const ChatMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatMessageContainerView)
