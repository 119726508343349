import { ChatTypeEnum } from '@medentee/enums'

import { IChatInfoUser } from 'interfaces'
import {
  GET_CHAT_ROOMS_ERROR,
  GET_CHAT_ROOMS_SUCCESS,
  READ_ALL_MESSAGES_SUCCESS,
  RECEIVE_NEW_MESSAGE_SUCCESS,
  RESET_CHAT,
  RESET_SELECTED_CHAT,
  SEARCH_CHAT,
  SELECT_CHAT,
  SEND_NEW_MESSAGE_SUCCESS,
  UPDATE_CHAT_ROOM_ACTION,
  LOAD_CHAT_INFO_SUCCESS,
  SET_CHAT_TYPE,
  TAction,
  TChatRoomsState,
  TGetChatRoomsError,
  TGetChatRoomsSuccess,
  TReadAllMessagesSuccess,
  TReceiveNewMessageSuccess,
  TSearchChatAction,
  TSelectChatAction,
  TSendNewMessageSuccess,
  TUpdateChatRoomAction,
  TSetChatTypeAction,
  orderChatRooms,
  UPDATE_CHAT_DRAFT_SUCCESS,
  TUpdateChatDraftSuccess,
  TLoadChatInfoSuccess,
  SET_CHAT_FILTERS,
  TSetChatFilters,
  GET_CHAT_ROOM_SUCCESS,
  TGetChatRoomSuccess,
  TResetSelectedChatAction,
  TSetTypingDoneSuccess,
  SEND_TYPING_DONE_ERROR,
  SEND_TYPING_DONE_SUCCESS,
  SET_TYPING_DONE_SUCCESS,
  SET_TYPING_SUCCESS,
  START_TYPING,
  TSetTypingSuccess,
  TReceiveChangeChatStatusPayload,
  RECEIVE_CHANGE_CHAT_STATUS
} from 'store'

export const initialChatRoomsState: TChatRoomsState = {
  ids: [],
  list: {},
  selectedChat: null,
  activeChatId: null,
  selectedChatInfo: null,
  search: '',
  chatType: ChatTypeEnum.DIALOGUE,
  foundIds: [],
  typingInterlocutors: {},
  typing: false,
  filters: {},
  status: 'IDLE',
  total: 0
}

export const chatRoomsReducer = (
  state = initialChatRoomsState,
  action: TAction
): TChatRoomsState => {
  switch (action.type) {
    case GET_CHAT_ROOMS_SUCCESS: {
      const { list, ids, filters, total } = (action as TGetChatRoomsSuccess).payload

      return {
        ...state,
        list,
        ids,
        filters,
        foundIds: ids,
        error: null,
        total,
        status: 'LOADED'
      }
    }

    case GET_CHAT_ROOMS_ERROR: {
      return {
        ...state,
        status: 'LOADED',
        error: (action as TGetChatRoomsError).payload
      }
    }

    case SELECT_CHAT: {
      const { nextChatId } = (action as TSelectChatAction).payload

      return {
        ...state,
        selectedChatInfo: null,
        activeChatId: nextChatId,
        typing: false,
        typingInterlocutors: {}
      }
    }

    case RESET_SELECTED_CHAT: {
      const { payload } = action as TResetSelectedChatAction

      if (payload?.type === 'pinned') {
        return {
          ...state,
          status: 'IDLE',
          filters: initialChatRoomsState.filters,
          search: ''
        }
      }

      return {
        ...state,
        selectedChat: null,
        selectedChatInfo: null,
        activeChatId: null,
        filters: initialChatRoomsState.filters,
        search: '',
        status: 'IDLE'
      }
    }

    case RESET_CHAT: {
      return {
        ...initialChatRoomsState
      }
    }

    case RECEIVE_NEW_MESSAGE_SUCCESS: {
      const { message, chat } = (action as TReceiveNewMessageSuccess).payload
      const { chatId } = message
      const room = chat ?? state.list[chatId]

      if (!room?.id) {
        return state
      }

      return {
        ...state,
        ids: orderChatRooms(state.list, state.ids, chatId, room?.type),
        foundIds: orderChatRooms(state.list, state.foundIds, chatId, room?.type),
        ...(message.createdAt >= (room.lastMessageDate ?? 0)
          ? {
              list: {
                ...state.list,
                [chatId]: {
                  ...room,
                  lastMessageDate: message.createdAt,
                  lastMessage: message
                }
              }
            }
          : {}),
        activeChatId: state.selectedChat?.id === chatId ? chatId : state.activeChatId
      }
    }

    case SEND_NEW_MESSAGE_SUCCESS: {
      const message = (action as TSendNewMessageSuccess).payload.data
      const { chatId } = message
      const room = state.list[chatId]

      if (!room?.id) {
        return state
      }

      return {
        ...state,
        activeChatId: chatId,
        ids: orderChatRooms(state.list, state.ids, chatId, room?.type),
        foundIds: orderChatRooms(state.list, state.foundIds, chatId, room?.type),
        list: {
          ...state.list,
          [chatId]: {
            ...room,
            lastMessage: message
          }
        }
      }
    }

    case READ_ALL_MESSAGES_SUCCESS: {
      const lastMessage = (action as TReadAllMessagesSuccess).payload.data
      const { chatId } = lastMessage
      const chatType = state.list[chatId]?.type

      return {
        ...state,
        ids: orderChatRooms(state.list, state.ids, chatId, chatType),
        foundIds: orderChatRooms(state.list, state.foundIds, chatId, chatType),
        list: {
          ...state.list,
          [chatId]: {
            ...state.list[chatId],
            lastMessage
          }
        }
      }
    }

    case SEARCH_CHAT: {
      const { search } = (action as TSearchChatAction).payload

      return {
        ...state,
        search
      }
    }

    case SET_CHAT_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...(action as TSetChatFilters).payload
        }
      }
    }

    case UPDATE_CHAT_ROOM_ACTION: {
      return {
        ...state,
        ...(action as TUpdateChatRoomAction).payload
      }
    }

    case RECEIVE_CHANGE_CHAT_STATUS: {
      const { chatId, currentChatStatus, activatedAt } =
        action.payload as TReceiveChangeChatStatusPayload

      return {
        ...state,
        list: {
          ...state.list,
          [chatId]: {
            ...(state.list[chatId] ?? []),
            status: currentChatStatus,
            activatedAt: activatedAt || state.list[chatId]?.activatedAt
          }
        },
        selectedChat:
          state.selectedChat?.id === chatId
            ? {
                ...state.selectedChat,
                status: currentChatStatus,
                activatedAt: activatedAt || state.list[chatId]?.activatedAt
              }
            : state.selectedChat
      }
    }

    case SET_CHAT_TYPE: {
      return {
        ...state,
        chatType: (action as TSetChatTypeAction).payload,
        search: '',
        selectedChat: null,
        activeChatId: null,
        ids: [],
        list: {}
      }
    }

    case UPDATE_CHAT_DRAFT_SUCCESS: {
      const { message, quoteMessage, mentionedAccounts, mentionedDepartments, chatId } =
        (action as TUpdateChatDraftSuccess).payload || {}

      return {
        ...state,
        list: {
          ...state.list,
          [chatId]: {
            ...state.list[chatId],
            draft: {
              message,
              quoteMessage,
              mentionedAccounts,
              mentionedDepartments
            }
          }
        }
      }
    }

    case LOAD_CHAT_INFO_SUCCESS:
      const payload = (action as TLoadChatInfoSuccess).payload

      return {
        ...state,
        selectedChatInfo: {
          ...payload,
          allUsers: new Map([
            ...payload.onlineParticipants.map<[string, IChatInfoUser]>((item) => [item.id, item]),
            ...payload.offlineParticipants.map<[string, IChatInfoUser]>((item) => [item.id, item])
          ])
        }
      }

    case GET_CHAT_ROOM_SUCCESS: {
      const chatRoom = (action as TGetChatRoomSuccess).payload

      return {
        ...state,
        selectedChat: chatRoom
      }
    }

    case SET_TYPING_SUCCESS:
    case SET_TYPING_DONE_SUCCESS: {
      const { chatId, typingInterlocutors } = (action as TSetTypingSuccess | TSetTypingDoneSuccess)
        .payload

      return {
        ...state,
        typingInterlocutors: {
          ...state.typingInterlocutors,
          [chatId]: typingInterlocutors
        }
      }
    }

    case START_TYPING: {
      return {
        ...state,
        typing: true
      }
    }

    case SEND_TYPING_DONE_SUCCESS:
    case SEND_TYPING_DONE_ERROR: {
      return {
        ...state,
        typing: false
      }
    }

    default:
      return state
  }
}
