import { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { EEmptyListIconSize, EmptyList, Pagination, Scrollable } from 'App/components/common'
import { getOrganizationMembers } from 'api/organizations'
import { generateRandomArray } from 'utils'
import { ReactComponent as AccountMultipleRemoveIcon } from 'assets/icons/AccountMultipleRemove.svg'

import { StaffTabAccountItem } from '../StaffTabAccountItem'
import { ORGANIZATION_STAFF_QUERY_KEY } from '../StaffTabAccountItem/useKebabMenu'

import styles from './StaffTabList.module.scss'

export type TStaffTabListProps = {
  search?: string
  organizationId: string
  departmentIds?: string[]
}

const FAKE_ITEMS = generateRandomArray(5)

export const StaffTabList = ({ organizationId, search }: TStaffTabListProps) => {
  const [page, setPage] = useState(0)

  const { t } = useTranslation()

  const { data, isFetching, isRefetching, refetch } = useQuery({
    queryKey: [ORGANIZATION_STAFF_QUERY_KEY, search, page, organizationId],
    queryFn: () => getOrganizationMembers({ page, search, organizationId }),
    cacheTime: 0
  })

  useEffect(() => setPage(0), [search])

  if (isFetching && !isRefetching) {
    return (
      <Scrollable>
        {FAKE_ITEMS.map((_, index) => (
          <Skeleton
            key={index}
            className={styles.skeleton}
            avatar={false}
            paragraph={{ rows: 5 }}
            active={true}
            loading={isFetching}
            title={true}
          />
        ))}
      </Scrollable>
    )
  }

  return (
    <Scrollable>
      {data?.results?.length ? (
        data?.results?.map((item) => (
          <StaffTabAccountItem
            key={item.account.id}
            data={item}
            organizationId={organizationId}
            onChange={refetch}
          />
        ))
      ) : (
        <EmptyList
          iconSize={EEmptyListIconSize.MD}
          icon={<AccountMultipleRemoveIcon />}
          text={t('organizations.staff.placeholder')}
          hasAppliedSearch={!!search}
        />
      )}
      <Pagination
        current={page}
        onChange={setPage}
        total={data?.total}
        classes={{ container: styles.pagination }}
      />
    </Scrollable>
  )
}
