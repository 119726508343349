import { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, Tooltip } from 'App/components'
import { UploadingProgress } from 'App/components/common/UploadingProgress'
import { IFilesEntity } from 'interfaces'
import { getExtension } from 'utils'
import { getFileIcon } from 'utils/files'
import { ReactComponent as AlertCircleOutlineIcon } from 'assets/icons/AlertCircleOutline.svg'

import { Chip } from '../Chip'

import styles from './Uploading.module.scss'
import { useRequest, TUseRequestProps } from './useRequest'

export type TUploadingProps = Pick<TUseRequestProps, 'uuid' | 'file' | 'showcaseId'> & {
  onDelete: (update?: boolean) => void
  setDeleteDisabled: (loading: boolean) => void
  onUploadSuccess: (file: IFilesEntity, uuid: string) => void
  onUploadError: (id: string) => void
}

export const Uploading = ({
  file,
  uuid,
  showcaseId,
  onDelete,
  setDeleteDisabled,
  onUploadSuccess,
  onUploadError
}: TUploadingProps) => {
  const [progress, setProgress] = useState<number>(0)

  const { isError, isLoading, isSuccess, uploadedFile, onCancelUploading, onRefreshUploading } =
    useRequest({
      file,
      uuid,
      showcaseId,
      onProgress: setProgress
    })

  useEffect(() => {
    setDeleteDisabled(isLoading || (!isError && !isSuccess))
  }, [isError, isLoading, isSuccess, setDeleteDisabled])

  useEffect(() => {
    uploadedFile && onUploadSuccess(uploadedFile, uuid)
  }, [onUploadSuccess, uploadedFile, uuid])

  useEffect(() => {
    isError && onUploadError(uuid)
  }, [onUploadError, isError, uuid])

  const shouldShowReload = isError && !isLoading

  const Icon = useMemo(() => {
    const extension = getExtension(!isError ? file.name : '', true).toLowerCase()

    return getFileIcon(extension)
  }, [file.name, isError])

  const handleCancel = useCallback(() => {
    onDelete(false)
    onCancelUploading()
  }, [onCancelUploading, onDelete])

  return (
    <>
      {isError && (
        <Tooltip title="Problem happened">
          <Chip className={styles.error}>
            <AlertCircleOutlineIcon />
          </Chip>
        </Tooltip>
      )}

      {!isSuccess && (
        <div className={styles.wrapper}>
          <Icon className={styles.icon} />

          {shouldShowReload ? (
            <Button className={styles.button} variant="inline" onClick={onRefreshUploading}>
              Reload
            </Button>
          ) : (
            <Button
              className={styles.button}
              variant="inline"
              onClick={handleCancel}
              icon={<UploadingProgress percent={progress} />}
              iconPosition="right"
            >
              Cancel
            </Button>
          )}
        </div>
      )}
    </>
  )
}
