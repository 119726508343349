import { call, put, race, select, take, takeLatest } from 'redux-saga/effects'

import { API, api } from 'services/api'
import {
  getAttachFilesListSelector,
  getChatMedCloudFilesRequest,
  hideModalAction,
  TAttachMedcloudFilesList,
  TBroadcastFilesList
} from 'store'
import {
  GET_CHAT_MED_CLOUD_FILES_ERROR,
  GET_CHAT_MED_CLOUD_FILES_SUCCESS,
  TGetCaseMedCloudFilesSuccessPayload,
  TGetChatMedCloudFilesRequestPayload
} from 'store/medCloud'
import { TError, TIds } from 'store/store.types'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'

import {
  broadcastMessageRequest,
  broadcastMessageSuccess,
  broadcastMessageError,
  selectBroadcastFiles,
  setBroadcastSelectedFiles
} from './broadcast.actions'
import * as actionTypes from './broadcast.actionTypes'
import {
  getBroadcastAudioBlobSelector,
  getBroadcastSelectedFilesIdsSelector
} from './broadcast.selectors'

function* broadcastMessageSaga({
  payload: {
    message,
    recipientIds,
    departmentIds,
    categoryIds,
    allContacts,
    organizationIds,
    organizationId
  }
}: ReturnType<typeof broadcastMessageRequest>) {
  try {
    const file: undefined | Blob = yield select(getBroadcastAudioBlobSelector)
    const fileIds: TIds = yield select(getBroadcastSelectedFilesIdsSelector)

    const formData = new FormData()

    if (file) {
      yield formData.append('file', file)
    }

    if (message?.length) {
      yield formData.append('message', message)
    }

    if (fileIds?.length) {
      yield formData.append('fileIds', JSON.stringify(fileIds))
    }

    if (categoryIds?.length && !categoryIds.some((id) => id === 'allContacts')) {
      yield formData.append('categoryIds', JSON.stringify(categoryIds))
    }

    if (departmentIds?.length) {
      yield formData.append('departmentIds', JSON.stringify(departmentIds))
    }

    if (recipientIds?.length) {
      yield formData.append('receiverIds', JSON.stringify(recipientIds))
    }

    if (organizationIds?.length && !organizationId) {
      yield formData.append('organizationIds', JSON.stringify(organizationIds))
    }

    if (organizationId && !organizationIds?.length) {
      yield formData.append('organizationIds', JSON.stringify([organizationId]))
    }

    if (allContacts || (categoryIds?.length && categoryIds.every((id) => id === 'allContacts'))) {
      yield formData.append('allContacts', String(true))
    }

    yield call(api.post, API.CHAT_BROADCAST_MESSAGE, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    yield put(hideModalAction())
    yield put(broadcastMessageSuccess())
  } catch (e) {
    yield put(broadcastMessageError(e))
  }
}

function* selectBroadcastFilesSaga({
  payload: { ids, onSuccess }
}: ReturnType<typeof selectBroadcastFiles>) {
  try {
    const fileList: TAttachMedcloudFilesList = yield select(getAttachFilesListSelector)
    const list: TBroadcastFilesList = {}
    let filters: TGetChatMedCloudFilesRequestPayload['filters'] = {}

    ids.forEach((id) => {
      const item = fileList[id]

      if (item?.id) {
        list[id] = item
      }
    })

    // If there is a difference, it means that there is no information about the files just downloaded from the device.
    // So need to update the file list
    const diff = ids.length - Object.values(list).length

    if (diff > PAGINATION_DEFAULT_SHOW_BY) {
      filters = {
        page: 0,
        showBy: PAGINATION_DEFAULT_SHOW_BY + diff
      }
    }

    // If there is no difference, there is no need to update
    if (diff === 0) {
      yield put(setBroadcastSelectedFiles({ ids, list }))
      yield call(onSuccess)

      return
    }

    yield put(getChatMedCloudFilesRequest({ filters }))

    const [success, error]: [
      undefined | { payload?: TGetCaseMedCloudFilesSuccessPayload },
      undefined | { payload: TError }
    ] = yield race([take(GET_CHAT_MED_CLOUD_FILES_SUCCESS), take(GET_CHAT_MED_CLOUD_FILES_ERROR)])

    if (error) {
      throw error.payload
    }

    ids.forEach((id) => {
      const item = list[id]
      if (!item?.id && success?.payload?.ids.includes(id)) {
        list[id] = success.payload.list[id]
      }
    })

    yield put(setBroadcastSelectedFiles({ ids, list }))

    yield call(onSuccess)
  } catch (error) {
    console.error(error)
  }
}
export function* broadcastSaga() {
  yield takeLatest(actionTypes.BROADCAST_MESSAGE_REQUEST, broadcastMessageSaga)
  yield takeLatest(actionTypes.SELECT_BROADCAST_FILES, selectBroadcastFilesSaga)
}
