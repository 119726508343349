import { IFilesEntity } from 'interfaces'

import { TVideoStreamingList } from './videoStreaming.types'

export const videoStreamingNormalize = <D extends IFilesEntity>(originalData: D[]) => {
  const list: TVideoStreamingList = {}

  originalData.forEach(({ id, bunnyNetMetadata, videoMetadata }) => {
    if (videoMetadata) {
      list[id] = {
        bunnyNetMetadata,
        videoMetadata
      }
    }
  })

  return list
}
