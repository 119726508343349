import { useCallback } from 'react'

import { CommentFieldWrapper, TCommentFieldWrapperProps } from '../CommentFieldWrapper'
import { TUseFieldRequests, useRequestField } from '../../useRequestField'
import { useFileSuggestions, TUseFileSuggestionsProps } from '../../../useFileSuggestions'
import { TOnSearchChangePayload } from '../../../UpdateShowcaseDialog/DescriptionField'

import styles from './CommentField.module.scss'

export type TCommentFieldProps = Pick<TCommentFieldWrapperProps, 'event'> &
  Pick<TUseFieldRequests, 'getCommentsQueryKey'> &
  Pick<TUseFileSuggestionsProps, 'files'> & {
    fileId: string
    showcaseId: string
    showcaseOwnerId: string

    variant?: 'default' | 'column'
  }

export const CommentField = ({
  fileId,
  showcaseId,
  getCommentsQueryKey,
  event,
  files,
  showcaseOwnerId,
  variant = 'default'
}: TCommentFieldProps) => {
  const {
    loading,
    processing,
    replyProcessing,
    editProcessing,
    draftMessage,
    accountSuggestions,
    accountMentions,
    sendMessage,
    replyMessage,
    updateDraftMessage,
    editMessage,
    refetchDraft,
    onSearchChange: onAccountSearchChange
  } = useRequestField({
    fileId,
    showcaseId,
    getCommentsQueryKey,
    event
  })

  const {
    fileMentions,
    fileSuggestions,
    onSearchChange: onFileSearchChange
  } = useFileSuggestions({
    files,
    showcaseId,
    ownerId: showcaseOwnerId
  })

  const handleSearchChange = useCallback(
    (e: TOnSearchChangePayload) => {
      onAccountSearchChange(e)
      onFileSearchChange(e)
    },
    [onAccountSearchChange, onFileSearchChange]
  )

  return loading ? null : (
    <CommentFieldWrapper
      refetchDraft={refetchDraft}
      updateDraftMessage={updateDraftMessage}
      sendMessage={sendMessage}
      replyMessage={replyMessage}
      editMessage={editMessage}
      onSearchChange={handleSearchChange}
      editProcessing={editProcessing}
      replyProcessing={replyProcessing}
      draftMessage={draftMessage}
      fileId={fileId}
      classes={{
        rootWrapper: styles[variant],
        editorWrapper: styles.editorWrapper,
        editorContainer: styles.editorContainer,
        editorEndAdornment: styles.editorEndAdornment
      }}
      processing={processing}
      event={event}
      accountSuggestions={accountSuggestions}
      fileSuggestions={fileSuggestions}
      accountMentions={accountMentions}
      fileMentions={fileMentions}
    />
  )
}
