import { Link } from 'react-router-dom'

import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg'

import styles from './PreviewLink.module.scss'

type TPreviewLinkProps = {
  link: string
  label: string
}

export const PreviewLink = ({ link, label }: TPreviewLinkProps) => (
  <Link to={link} className={styles.root}>
    <EyeIcon />
    {label}
  </Link>
)
