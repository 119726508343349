import { SubscriptionIdsEnum } from '@medentee/enums'

import { State } from '../../redux/rootReducer'

export const getEmptySpaceSelector = (state: State) => {
  const storageCapacity = state.subscriptions.capacity?.medCloudCapacityAvailable ?? 0
  const capacityOccupied = state.subscriptions.capacity?.medCloudCapacityOccupied ?? 0

  return storageCapacity - capacityOccupied
}

export const getIsBasicSubscription = (state: State) => {
  const { current } = state.subscriptions

  if (current) {
    return (
      current.subscription.id === SubscriptionIdsEnum.BASIC_PROFESSIONAL ||
      current.subscription.id === SubscriptionIdsEnum.BASIC_BUSINESS
    )
  }

  return true
}

export const getIsBusinessSubscription = (state: State) => {
  const { current } = state.subscriptions

  if (current) {
    return (
      current.subscription.id === SubscriptionIdsEnum.BUSINESS ||
      current.subscription.id === SubscriptionIdsEnum.BASIC_BUSINESS
    )
  }

  return false
}

export const getIsProfessionalSubscription = (state: State) => {
  const { current } = state.subscriptions

  if (current) {
    return (
      current.subscription.id === SubscriptionIdsEnum.PROFESSIONAL ||
      current.subscription.id === SubscriptionIdsEnum.BASIC_PROFESSIONAL
    )
  }

  return false
}

export const getIsMedCloudCapacityExceed = (state: State) => {
  const { capacity } = state.subscriptions

  return capacity.medCloudCapacityOccupied >= capacity.medCloudCapacityAvailable
}

export const getCurrentSubscription = (state: State) => state.subscriptions.current

export const isCurrentSubscriptionActive = (state: State) => {
  const current = state.subscriptions.current

  if (current) {
    return new Date(current.endsOn) > new Date() || !!current.graceOn
  }

  return false
}
