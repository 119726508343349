import { useRef } from 'react'
import { RangeValue } from 'rc-picker/lib/interface'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { longDate } from 'utils'
import { ReactComponent as CalendarIcon } from 'assets/icons/Schedule.svg'
import { ReactComponent as DashIcon } from 'assets/icons/Dash.svg'
import styles from 'App/components/common/Fields/RangePicker/RangePicker.module.scss'

export type TRangePickerValue = RangeValue<Date>

export type TRangePickerProps = {
  onChange: (value: TRangePickerValue) => void
  value: TRangePickerValue
}

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig)

export const RangePicker = ({ value, onChange }: TRangePickerProps) => {
  const ref = useRef(null)

  const { t } = useTranslation()

  const activeSeparator = value && value?.length > 1

  const getPopupContainer = () => ref.current!

  return (
    <div ref={ref} className={styles.root}>
      <DatePicker.RangePicker
        className={styles.rangePicker}
        placeholder={[
          t('common.field.datePicker.placeholder'),
          t('common.field.datePicker.placeholder')
        ]}
        getPopupContainer={getPopupContainer}
        open={true}
        onCalendarChange={onChange}
        value={value}
        separator={<DashIcon className={cls(styles.icon, activeSeparator && styles.iconActive)} />}
        format={longDate}
        suffixIcon={<CalendarIcon className={styles.suffix} />}
        allowClear={false}
      />
    </div>
  )
}
