import { useEffect, useState } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import { Skeleton } from 'antd'
import { ProducedNotificationsEnum } from '@medentee/enums'
import sum from 'lodash/sum'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'

import { TNotificationsP2PDetailsListContainerProps } from 'App/containers'
import { NotificationsP2PDetailsRow, TNotificationsP2PDetailsRowProps } from 'App/components'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { getMoreItemsLoader, QueryBuilder } from 'utils'
import { api, API, APIResultsResponse } from 'services/api'
import { INotificationsProtocolDTO } from 'interfaces'
import { useRefreshNotification, useVariableSizeListHeight } from 'App/hooks'
import { handleError } from 'api/utils'

import styles from './NotificationsP2PDetailsList.module.scss'

export type TNotificationsP2PDetailsListProps = TNotificationsP2PDetailsListContainerProps &
  Pick<TNotificationsP2PDetailsRowProps, 'hasNotifications'>

export type TSetRowHeight = (index: number, size: number) => void

type TRow = Omit<ListChildComponentProps, 'data'> &
  Pick<TNotificationsP2PDetailsRowProps, 'className' | 'setRowHeight' | 'hasNotifications'> & {
    data: INotificationsProtocolDTO[]
    loading: boolean
  }

const ALL_NOTIFICATIONS = new Set(Object.values(ProducedNotificationsEnum))
const THRESHOLD = 5
const LIST_CONFIG = {
  ITEM_HEIGHT: 52,
  WIDTH: '100%'
}

const maxHeight = LIST_CONFIG.ITEM_HEIGHT * PAGINATION_DEFAULT_SHOW_BY

const getListHeight = (
  arrLength: number,
  total: number,
  heightList: Record<number, number> = {}
) => {
  if (!total) {
    return LIST_CONFIG.ITEM_HEIGHT
  }

  const defaultArrLengthHeight = LIST_CONFIG.ITEM_HEIGHT * arrLength
  const totalHeight = sum(Object.values(heightList))

  if (totalHeight > defaultArrLengthHeight) {
    return totalHeight >= maxHeight ? maxHeight : totalHeight
  }

  return total <= PAGINATION_DEFAULT_SHOW_BY ? defaultArrLengthHeight : maxHeight
}

const Row = ({ style, index, data, setRowHeight, className, hasNotifications, loading }: TRow) => {
  const {
    id,
    case: caseData,
    file,
    originatedFrom,
    payload,
    producer,
    viewedProtocol,
    receiver,
    type,
    createdAt
  } = data[index] || {}

  return (
    <div style={style}>
      <Skeleton
        loading={loading || !data[index]}
        active={true}
        title={false}
        paragraph={{ rows: 1, width: '100%' }}
        className={styles.skeleton}
      >
        <NotificationsP2PDetailsRow
          id={id}
          case={caseData}
          file={file}
          originatedFrom={originatedFrom}
          payload={payload}
          producer={producer}
          viewedProtocol={viewedProtocol}
          receiver={receiver}
          type={type}
          createdAt={createdAt}
          index={index}
          setRowHeight={setRowHeight}
          className={className}
          hasNotifications={hasNotifications}
        />
      </Skeleton>
    </div>
  )
}

export const NotificationsP2PDetailsList = ({
  id,
  hasNotifications,
  clearNotificationsProtocolUserCount
}: TNotificationsP2PDetailsListProps) => {
  const [rawData, setRawData] = useState<INotificationsProtocolDTO[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [listHeight, setListHeight] = useState<number>(LIST_CONFIG.ITEM_HEIGHT)

  const { listRef, rowsHeights, getRowHeight, setRowHeight } = useVariableSizeListHeight({
    defaultRowHeight: LIST_CONFIG.ITEM_HEIGHT
  })

  const { t } = useTranslation()

  useEffect(() => {
    if (!loading) {
      setListHeight(getListHeight(rawData.length, total, rowsHeights?.current))
    }
  }, [loading, rawData.length, total, rowsHeights])

  useEffect(() => {
    getNotificationsProtocol(0)

    return () => {
      clearNotificationsProtocolUserCount({ userId: id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearNotificationsProtocolUserCount, id])

  const { removeToast } = useRefreshNotification({
    onRefresh: () => getNotificationsProtocol(0),
    message: t('newNotificationToast', { ns: 'notifications' }),
    notifications: ALL_NOTIFICATIONS,
    notificationsFilter: (payload: any) => payload?.producerId === id
  })

  const getNotificationsProtocol = async (page: number) => {
    // TODO: need refactor.
    // Need move Request from local to new useSagas.
    // Need create useSagas.
    // Need discussed with Developers and view this link:
    // this --> https://github.com/redux-saga/redux-saga/issues/1842

    if (page === 0) {
      setLoading(true)
    }

    try {
      const url = new QueryBuilder(API.NOTIFICATIONS_PROTOCOL)
        .custom('userId', id)
        .page(page)
        .showBy(PAGINATION_DEFAULT_SHOW_BY)
        .build()

      const { data }: APIResultsResponse<INotificationsProtocolDTO[]> = await api.get(url)

      let newData: INotificationsProtocolDTO[] = data.results

      if (page > 0) {
        newData = [...rawData, ...data.results]
      }

      setTotal(data.total)
      setRawData(newData)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  /*
      TODO: need to make a choice.

      If use Redux we have bug.

      There is a bug that sometimes slips through here.
      To fix it, you need to choose a way:

      1) Don't use redux store, because problems arise due to global state overuse.
      2) Understand how to fix it, keeping the logic of Redux state.

      The main problem is that if you open 1 collapse and without closing it open the next one,
      then for some reason for the first one once again is executed here,
      (because the total has already come increased, again due to the global state)
      */
  const loadMoreItems = getMoreItemsLoader({
    limit: PAGINATION_DEFAULT_SHOW_BY,
    onLoadNext: getNotificationsProtocol,
    callback: removeToast
  })

  const isItemLoaded = (index: number) => index < rawData.length

  return (
    <Skeleton
      loading={loading}
      active={true}
      title={false}
      paragraph={{ rows: 1, width: '100%' }}
      className={cls(styles.skeleton, styles.skeletonRoot)}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={total}
        loadMoreItems={loadMoreItems}
        threshold={THRESHOLD}
      >
        {({ onItemsRendered, ref }) => (
          <VariableSizeList
            itemCount={total}
            onItemsRendered={onItemsRendered}
            ref={(list) => {
              typeof ref === 'function' && ref(list)
              listRef.current = list
            }}
            itemData={rawData}
            itemSize={getRowHeight}
            height={listHeight}
            width={LIST_CONFIG.WIDTH}
            className={styles.root}
          >
            {(props) => (
              <Row
                {...props}
                className={listHeight !== maxHeight ? styles.row : ''}
                hasNotifications={hasNotifications}
                loading={loading}
                setRowHeight={setRowHeight}
              />
            )}
          </VariableSizeList>
        )}
      </InfiniteLoader>
    </Skeleton>
  )
}
