import { Redirect, Switch, generatePath, useRouteMatch } from 'react-router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  createLoadingSelector,
  isAdminAccountSelector,
  useAppDispatch,
  useAppSelector
} from 'store'
import { GET_EVENTS, getEventsRequest, resetEvents } from 'store/events'
import { UnityHubBackButton, UnityHubLayout } from 'App/components/UnityHub'
import { RoleRouteContainer } from 'App/containers'
import { useAdaptiveLayout, useIsMounted } from 'App/hooks'
import { EmptyList } from 'App/components'

import { EventsNav } from '../../components/Events/EventsNav'

import { EventsContent } from './EventsContent'
import { EventsEmptyState } from './EventsEmptyState'
import { useEventNotificationTracker } from './useEventNotificationTracker'

export const EVENT_CONTENT_PATH = '/events/:id'
export const EVENTS_MEMBER_PROFILE_PATH = '/events/:id/highlights/profile'

const loadingSelector = createLoadingSelector([GET_EVENTS])

export const Events = () => {
  const dispatch = useAppDispatch()

  const { isDesktop } = useAdaptiveLayout()

  const { t } = useTranslation()

  const { isExact } = useRouteMatch()
  const eventPageMatch = useRouteMatch<{ id: string }>(EVENT_CONTENT_PATH)
  const memberProfilePageMatch = useRouteMatch(EVENTS_MEMBER_PROFILE_PATH)

  const loading = useAppSelector(loadingSelector)
  const ids = useAppSelector((state) => state.events.data.ids)
  const isAdminAccount = useAppSelector(isAdminAccountSelector)

  const loaded = useIsMounted()

  useEventNotificationTracker('event')

  const shouldShowBackButton = !isDesktop && memberProfilePageMatch?.isExact
  const shouldShowNav = isDesktop || (!isDesktop && (eventPageMatch?.isExact || isExact))

  const shouldRedirectToFirstEvent =
    !loading &&
    !!ids.length &&
    loaded.current &&
    (isExact || (eventPageMatch?.params.id && !ids.includes(eventPageMatch.params.id)))

  const shouldShowEmpty = !loading && !ids.length

  useEffect(() => {
    dispatch(getEventsRequest())

    return () => {
      dispatch(resetEvents())
    }
  }, [dispatch])

  useEffect(() => {
    loaded.current = true
  }, [loaded, loading])

  if (shouldShowEmpty) {
    return isAdminAccount ? (
      <EventsEmptyState />
    ) : (
      <EmptyList text={t('events.placeholder_noEvents')} />
    )
  }

  return (
    <>
      {shouldShowBackButton && eventPageMatch && <UnityHubBackButton path={eventPageMatch.url} />}

      <UnityHubLayout>
        {shouldShowNav && <EventsNav loading={loading} />}

        <Switch>
          <RoleRouteContainer path={EVENT_CONTENT_PATH}>
            {shouldRedirectToFirstEvent ? (
              <Redirect push={false} to={generatePath(EVENT_CONTENT_PATH, { id: ids[0] })} />
            ) : (
              <EventsContent loading={loading} />
            )}
          </RoleRouteContainer>
          <RoleRouteContainer>
            {shouldRedirectToFirstEvent && (
              <Redirect push={false} to={generatePath(EVENT_CONTENT_PATH, { id: ids[0] })} />
            )}
          </RoleRouteContainer>
        </Switch>
      </UnityHubLayout>
    </>
  )
}
