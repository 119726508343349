import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { SettingsSecurityDefaultAccountItem } from 'App/components'

export type TSettingsSecurityDefaultAccountItemContainerOwnProps = {
  id: string

  disabled?: boolean
}

export type TSettingsSecurityDefaultAccountItemContainerProps =
  TSettingsSecurityDefaultAccountItemContainerOwnProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>

const mapStateToProps = (
  state: State,
  { id }: TSettingsSecurityDefaultAccountItemContainerOwnProps
) => {
  const { type, firstName, lastName, displayUserName, role } =
    state.userProfile.relatedAccounts.list[id] || {}
  return {
    type: type.name,
    role: role?.name,
    firstName,
    lastName,
    displayUserName
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export const SettingsSecurityDefaultAccountItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityDefaultAccountItem)
