import { RefObject, useCallback, useMemo } from 'react'
import cls from 'classnames'
import { AccountTypeNames, MeetingStatusEnum, MessageTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  deleteFailedMessageAction,
  sendNewAudioMessageRequest,
  sendNewMessageRequest,
  setChatHistoryEventAction,
  TSlideEntity,
  useAppDispatch
} from 'store'
import { EChatHistoryEvents, ESendMessageStatus } from 'enums'
import {
  ChatMessageReactionsPicker,
  ContextMenu,
  TContextMenuProps,
  TMenuItemProps
} from 'App/components'
import { EModalComponents, TChatMessageKebabMenuContainerProps } from 'App/containers'
import { useKebabMenu as useMeetingKebabMenu } from 'App/containers/Meetings'
import { useToggle } from 'App/hooks'
import { ReactComponent as ShareOutlineIcon } from 'assets/icons/ShareOutline.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as PinIcon } from 'assets/icons/PinOutlined.svg'
import { ReactComponent as UnpinOutlinedIcon } from 'assets/icons/UnpinOutlined.svg'
import { ReactComponent as PencilOutlineIcon } from 'assets/icons/PencilOutline.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/ArrowLeft.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import { ReactComponent as ResendIcon } from 'assets/icons/Resend.svg'
import { ReactComponent as CancelIcon } from 'assets/icons/Cancel.svg'
import { copyMessage, isTemporaryActionAllowed } from 'utils/chat'

import { useChatContext } from '../../../context'
import { useKebabMenu as useFileKebabMenu } from '../ChatFiles/useKebabMenu'

import styles from './ChatMessageKebabMenu.module.scss'

export type TChatMessageKebabMenuBaseProps = PropsWithClassName<
  {
    isRead: boolean
    chatId: string
    messageId: string
    messageType: MessageTypeEnum
    isOriginalSender: boolean
    slidesEntity: TSlideEntity
    show: boolean
    createdAt: string

    yourMessage?: boolean
    fileId?: string
    fileName?: string
    fileExtension?: string
    caseId?: string
    meetingId?: string
    meetingStatus?: MeetingStatusEnum
    preview?: boolean
    kebabRef?: RefObject<HTMLDivElement>
  } & Pick<TContextMenuProps, 'visible' | 'onVisibleChange'>
>

export type TChatMessageKebabMenuProps = TChatMessageKebabMenuContainerProps

export const ChatMessageKebabMenu = ({
  yourMessage,
  chatId,
  messageId,
  message,
  isPinned,
  messageType,
  fileId,
  fileName,
  fileExtension,
  caseId,
  isOriginalSender,
  isActive,
  isLockedCase,
  isUnopenable,
  slidesEntity,
  meetingId,
  meetingStatus,
  show,
  preview,
  isCaseGroup,
  isOrganization,
  createdAt,
  isBusinessAccount,
  isChannelManager,
  isGroup,
  isCommunityChannel,
  isCommunityNews,
  isEventChannel,
  isEventNews,
  kebabRef,
  pinMessage,
  quoteMessage,
  editMessage,
  hideModalAction,
  showModalAction,
  className = '',
  sendStatus,
  receiverId,
  readAll,
  isCaseOwner,
  isPinAble,
  isPinShared,
  isPinInitiator,
  isP2P,
  isChatAdmin,
  ...rest
}: TChatMessageKebabMenuProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const { showPinnedMessages, togglePinnedMessages } = useChatContext()

  const { value: open, toggleOn, toggleOff } = useToggle()

  const isFile = messageType === MessageTypeEnum.FILE
  const isText = messageType === MessageTypeEnum.TEXT
  const isCommunity = isCommunityChannel || isCommunityNews
  const isEvent = isEventChannel || isEventNews
  const isNewsMember = (isCommunityNews || isEventNews) && !isChannelManager && !isBusinessAccount

  const deleteFailedMessage = useCallback(() => {
    dispatch(
      deleteFailedMessageAction({
        messageId
      })
    )
  }, [dispatch, messageId])
  const resendFailedMessage = useCallback(() => {
    if (!message) {
      return
    }

    const newMessageProps = {
      messageId,
      quoteId: message.quoteMessage?.id,
      readAll,
      chatId,
      caseId,
      receiverId
    }

    if (messageType === MessageTypeEnum.TEXT) {
      dispatch(
        sendNewMessageRequest({
          message: message.message as string,
          ...newMessageProps
        })
      )
    } else if (messageType === MessageTypeEnum.AUDIO) {
      dispatch(
        sendNewAudioMessageRequest({
          message: message.message as Blob,
          ...newMessageProps
        })
      )
    }
  }, [caseId, dispatch, chatId, messageId, message, messageType, readAll, receiverId])
  const isDeleteOptionHidden = useCallback(
    (defaultCondition: boolean) => {
      const isSenderBusiness = message?.sender.type.name === AccountTypeNames.BUSINESS

      if (
        (isGroup && isChannelManager) ||
        ((isCommunity || isOrganization || isEvent) &&
          (isBusinessAccount || (isChannelManager && !isSenderBusiness)))
      ) {
        return false
      }

      return defaultCondition
    },
    [
      isBusinessAccount,
      isChannelManager,
      isCommunity,
      isEvent,
      isGroup,
      isOrganization,
      message?.sender.type.name
    ]
  )

  const getMessageMenuItems = useCallback((): TMenuItemProps[] => {
    const unpinMessageItem: TMenuItemProps = {
      content: t('chat.message.kebabMenu.unpin'),
      icon: <UnpinOutlinedIcon />,
      hidden:
        !isPinAble ||
        !isPinned ||
        (isPinShared && !isP2P && !isCaseOwner && !isChannelManager && !isBusinessAccount),
      onClick: () => {
        dispatch(
          showModalAction({
            modalType: EModalComponents.CHAT_UNPIN_CONFIRM,
            modalTitle:
              isPinInitiator && isPinShared
                ? t('modal.unpinMessageConfirm.title_all')
                : t('modal.unpinMessageConfirm.title'),
            modalProps: {
              chatId,
              messageId,
              isPinShared,
              isPinInitiator,
              showPinnedMessages
            }
          })
        )
      }
    }
    if (showPinnedMessages) {
      return [
        {
          icon: <ArrowLeftIcon />,
          content: t('chat.message.kebabMenu.goToMessage'),
          onClick: () => {
            dispatch(
              setChatHistoryEventAction({
                type: EChatHistoryEvents.JUMP,
                payload: { eventTs: Date.now(), messageId }
              })
            )
            togglePinnedMessages()
          }
        },
        unpinMessageItem
      ]
    }
    if (sendStatus === ESendMessageStatus.FAILED && yourMessage) {
      return [
        {
          content: t('chat.message.kebabMenu.resend'),
          icon: <ResendIcon />,
          onClick: resendFailedMessage
        },
        {
          content: t('chat.message.kebabMenu.cancel'),
          icon: <CancelIcon />,
          onClick: deleteFailedMessage
        }
      ]
    }

    return [
      {
        content: t('chat.message.kebabMenu.quote'),
        icon: <ShareOutlineIcon />,
        hidden: !isActive || isLockedCase || isNewsMember,
        onClick: () => {
          quoteMessage({ quoteId: messageId, chatId })
        }
      },
      {
        content: t('chat.message.kebabMenu.pin'),
        icon: <PinIcon />,
        hidden: !isPinAble || isPinned,
        onClick: () =>
          dispatch(
            showModalAction({
              modalType: EModalComponents.CHAT_PIN_CONFIRM,
              modalTitle: t('modal.pinMessageConfirm.title'),
              modalProps: {
                chatId,
                messageId,
                shareable: isP2P || isChatAdmin || isChannelManager || isCaseOwner
              }
            })
          )
      },
      unpinMessageItem,
      {
        content: t('chat.message.kebabMenu.copy'),
        icon: <CopyIcon />,
        hidden: !isText,
        onClick: () => {
          message && copyMessage(message)
        }
      },
      {
        content: t('chat.message.kebabMenu.edit'),
        icon: <PencilOutlineIcon />,
        hidden:
          !isTemporaryActionAllowed(createdAt) ||
          !yourMessage ||
          messageType !== MessageTypeEnum.TEXT ||
          !isOriginalSender ||
          isLockedCase ||
          isNewsMember,
        onClick: () => editMessage({ chatId, messageId })
      },
      {
        content: t('chat.message.kebabMenu.delete'),
        icon: <TrashIcon />,
        hidden: isDeleteOptionHidden(
          !isTemporaryActionAllowed(createdAt) || !yourMessage || !isOriginalSender || isLockedCase
        ),
        onClick: () =>
          showModalAction({
            modalType: EModalComponents.DELETE_CHAT_MESSAGE_CONFIRM,
            modalTitle: t('modal.deleteMessageConfirm.title'),
            modalProps: { chatId, messageId, caseId }
          })
      }
    ]
  }, [
    isPinAble,
    isPinned,
    showPinnedMessages,
    sendStatus,
    yourMessage,
    isActive,
    isLockedCase,
    isNewsMember,
    isText,
    createdAt,
    messageType,
    isOriginalSender,
    isDeleteOptionHidden,
    dispatch,
    showModalAction,
    isPinInitiator,
    isPinShared,
    chatId,
    messageId,
    togglePinnedMessages,
    resendFailedMessage,
    deleteFailedMessage,
    quoteMessage,
    isP2P,
    isBusinessAccount,
    isChannelManager,
    isCaseOwner,
    message,
    editMessage,
    caseId,
    isChatAdmin,
    t
  ])

  const revokeP2PAccessCallback = useCallback(() => {
    dispatch(hideModalAction())
  }, [dispatch, hideModalAction])

  const { getMenuItems: getFileMenuItems } = useFileKebabMenu({ revokeP2PAccessCallback })
  const { getMenuItems: getMeetingMenuItems } = useMeetingKebabMenu({
    meeting: message?.meeting,
    cancelOnSuccessCallback: hideModalAction,
    editOnSuccessCallback: hideModalAction
  })

  const menuItems = useMemo(() => {
    if (isFile && fileExtension && fileName && fileId) {
      return getFileMenuItems({
        caseId,
        isChatAdmin,
        slidesEntity,
        chatId,
        isP2P,
        extension: fileExtension,
        fileName,
        fileId,
        isUnopenable,
        messageId,
        yourMessage,
        createdAt,
        isPinned,
        isActive,
        isLockedCase,
        isCaseGroup,
        isCommunityNews,
        isEventNews,
        isOrganization,
        isCommunity,
        isEvent,
        isCaseOwner,
        isPinAble,
        isPinShared,
        isGroup,
        isChannelManager,
        isBusinessAccount,
        isDeleteAsMessage: true,
        isDeleteOptionHidden,
        isPinInitiator
      })
    }

    if (meetingId && meetingStatus) {
      return getMeetingMenuItems({
        id: meetingId,
        isOrganizer: !!yourMessage,
        status: meetingStatus,
        disableReject: true
      })
    }

    return getMessageMenuItems()
  }, [
    createdAt,
    isFile,
    fileExtension,
    fileName,
    fileId,
    meetingId,
    meetingStatus,
    getMessageMenuItems,
    getFileMenuItems,
    caseId,
    slidesEntity,
    chatId,
    isP2P,
    isUnopenable,
    messageId,
    yourMessage,
    isPinned,
    isActive,
    isLockedCase,
    isCaseGroup,
    isCommunityNews,
    isEventNews,
    isOrganization,
    isCommunity,
    isEvent,
    isCaseOwner,
    isPinAble,
    isGroup,
    isChannelManager,
    isBusinessAccount,
    isDeleteOptionHidden,
    getMeetingMenuItems,
    isPinInitiator,
    isPinShared,
    isChatAdmin
  ])

  const shouldShowKebab = useMemo(
    () => show && !!menuItems.filter(({ hidden }) => !hidden).length,
    [show, menuItems]
  )
  const shouldShowReactions =
    (messageType === MessageTypeEnum.TEXT ||
      messageType === MessageTypeEnum.FILE ||
      messageType === MessageTypeEnum.AUDIO) &&
    sendStatus !== ESendMessageStatus.FAILED

  if (!shouldShowKebab) {
    return null
  }

  return (
    <div ref={kebabRef} className={cls(styles.wrapper, className)}>
      <ContextMenu
        menuItems={menuItems}
        disableIcon={true}
        hideMenuItems={open}
        startAdornment={
          shouldShowReactions ? (
            <ChatMessageReactionsPicker
              chatId={chatId}
              messageId={messageId}
              open={open}
              toggleOn={toggleOn}
              toggleOff={toggleOff}
            />
          ) : null
        }
        classes={{
          icon: cls(styles.icon, {
            [styles.outgoing]:
              yourMessage &&
              messageType !== MessageTypeEnum.MEETING &&
              !isCommunityNews &&
              !isEventNews,
            [styles.iconPreview]: !!preview
          })
        }}
        {...rest}
      />
    </div>
  )
}
