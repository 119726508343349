import { TAction } from 'store'
import { PAGINATION_DEFAULT } from 'types'

import {
  TInvitationsState,
  TGetInvitationsError,
  TGetInvitationsSuccess,
  TGetInvitationsNewUsersSuccess
} from './invitations.types'
import {
  GET_INVITATIONS_SUCCESS,
  GET_INVITATIONS_ERROR,
  GET_INVITATIONS_NEW_USERS_SUCCESS,
  RESET_INVITATIONS_NEW_USERS
} from './invitations.actions'

export const initialInvitationsState: TInvitationsState = {
  sent: {
    counts: {
      contacts: 0,
      businessAdmin: 0,
      newUsers: 0
    },
    newUsers: {
      list: {},
      ids: [],
      pagination: PAGINATION_DEFAULT
    }
  }
}

export const invitationsReducer = (state = initialInvitationsState, action: TAction) => {
  switch (action.type) {
    case GET_INVITATIONS_SUCCESS: {
      const { sent } = (action as TGetInvitationsSuccess).payload

      return {
        ...state,
        sent: {
          ...state.sent,
          counts: sent
        }
      }
    }

    case GET_INVITATIONS_NEW_USERS_SUCCESS: {
      return {
        ...state,
        sent: {
          ...state.sent,
          newUsers: (action as TGetInvitationsNewUsersSuccess).payload
        }
      }
    }

    case GET_INVITATIONS_ERROR: {
      return {
        ...state,
        error: (action as TGetInvitationsError).payload
      }
    }

    case RESET_INVITATIONS_NEW_USERS: {
      return {
        ...state,
        sent: {
          ...state.sent,
          newUsers: initialInvitationsState.sent.newUsers
        }
      }
    }

    default:
      return state
  }
}
