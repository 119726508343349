import { CaseStatusesEnum, FileHistorySourcesEnum } from '@medentee/enums'
import cls from 'classnames'

import { TCaseCloudFilesItemContainer, VideoReadinessStatus } from 'App/containers'
import {
  ContextMenu,
  FileName,
  Checkbox,
  TCaseViewSelectValue,
  TFilesViewVariant,
  TMenuItemProps
} from 'App/components'
import { TAccount } from 'store'
import {
  TUseFileMouseEventsProps,
  TUseSelection,
  useFileContextMenu,
  useFileMouseEvents,
  VideoStatusContextWrapper
} from 'App/components/File'
import { stopPropagation } from 'utils'
import { useAdaptiveLayout } from 'App/hooks'

import styles from './CaseCloudFilesListItem.module.scss'

export type TCaseCloudFilesListItemProps = {
  id: string
  caseStatus: CaseStatusesEnum
  isUnopenable: boolean

  menuItems?: TMenuItemProps[]
  originalEntityId?: string
  source?: FileHistorySourcesEnum
  fileOwner?: TAccount
  caseView?: TCaseViewSelectValue
  selected?: boolean
  filesView?: TFilesViewVariant
} & Pick<TUseSelection, 'onSelect' | 'selectMode'> &
  PartialBy<TCaseCloudFilesItemContainer, 'videoMetadata' | 'slidesEntity'> &
  Pick<TUseFileMouseEventsProps, 'openHandlerProps'>

export const CaseCloudFilesListItem = ({
  extension,
  fileName,
  id,
  selected,
  caseStatus,
  caseView,
  selectMode,
  openHandlerProps,
  menuItems,
  videoMetadata,
  isUnopenable,
  onSelect
}: TCaseCloudFilesListItemProps) => {
  const { isMobile } = useAdaptiveLayout()
  const isCaseStandardView = caseView === 'standard'

  const { handleVisibilityChange, visible, ref } = useFileContextMenu()

  const { handleSelect, longPressBind, onClick } = useFileMouseEvents({
    file: { id },
    openHandlerProps,
    selectMode,
    disableSelect: isCaseStandardView,
    disableOpen: isUnopenable,
    onSelect,
    onTouch: handleVisibilityChange
  })

  const isShowCheckbox = caseStatus !== CaseStatusesEnum.ARCHIVED && !isCaseStandardView
  const isShowVideoStatusInKebab = videoMetadata && isMobile

  return (
    <div
      ref={ref}
      className={cls(styles.root, selected && styles.selected)}
      {...longPressBind}
      onClick={onClick}
      onContextMenu={handleVisibilityChange}
    >
      {menuItems && (
        <ContextMenu
          nestIn={isCaseStandardView ? 'body' : 'parent'}
          disableIcon={true}
          menuItems={menuItems}
          visible={visible}
          endAdornment={
            isShowVideoStatusInKebab && <VideoStatusContextWrapper size="sm" fileId={id} />
          }
        />
      )}

      <div className={styles.wrapper}>
        {isShowCheckbox && (
          <Checkbox checked={selected} onChange={handleSelect} onClick={stopPropagation} />
        )}

        <FileName
          twoLine
          extension={extension}
          fileName={fileName}
          className={styles.titleWrapper}
          textClassName={styles.title}
          iconClassName={styles.titleIcon}
          fileNameDisabled={isUnopenable}
        />

        {!isMobile && <VideoReadinessStatus fileId={id} size="sm" />}

        {menuItems && (
          <ContextMenu
            nestIn={isCaseStandardView ? 'body' : 'parent'}
            menuItems={menuItems}
            endAdornment={
              isShowVideoStatusInKebab && <VideoStatusContextWrapper size="sm" fileId={id} />
            }
          />
        )}
      </div>
    </div>
  )
}
