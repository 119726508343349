import { useCallback } from 'react'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  getChatRoomsRequest,
  hideModalAction,
  onChatRoomNotFoundError,
  useAppDispatch
} from 'store'
import { Button, ConfirmationModal } from 'App/components/common'
import { eventDeleteChannel } from 'api/events'

type TEventDeleteTextChannelConfirmProps = {
  channelId: string
  eventId: string
}

export const EventDeleteTextChannelConfirm = ({
  eventId,
  channelId
}: TEventDeleteTextChannelConfirmProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const onSuccess = () => {
    dispatch(hideModalAction())
    dispatch(
      getChatRoomsRequest({
        eventId,
        chatType: [ChatTypeEnum.EVENT_CHANNEL, ChatTypeEnum.EVENT_NEWS]
      })
    )
  }

  const { mutate, error, isLoading } = useMutation({
    mutationKey: ['event_delete_text_channel', eventId, channelId],
    mutationFn: eventDeleteChannel,
    onSuccess,
    onError: (e) => onChatRoomNotFoundError(e, onSuccess)
  })

  const onClick = useCallback(() => {
    mutate({ eventId, channelId })
  }, [channelId, eventId, mutate])

  return (
    <ConfirmationModal
      error={error}
      content={t('modal.deleteChannelConfirm.content')}
      controls={
        <Button loading={isLoading} onClick={onClick}>
          {t('modal.deleteChannelConfirm.submitButton')}
        </Button>
      }
    />
  )
}
