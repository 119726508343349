import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'App/components/common/Button'

import styles from './UserProfilePreviewArea.module.scss'

export const UserProfilePreviewArea = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.preview}>
      <span className={styles.previewTitle}>{t('userProfile.profilePreview.alert')}</span>
      <Link to="/profile/edit/basic">
        <Button size="sm" className={styles.previewButton}>
          {t('userProfile.profilePreview.backButton')}
        </Button>
      </Link>
    </div>
  )
}
