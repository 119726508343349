import { ReactNode, useCallback, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import cls from 'classnames'
import { UploadSourcesEnum } from '@medentee/enums'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { WHITELIST_FILES_DOT } from 'globalConstants'
import { TCallbackAfterUploadFile, uploadFilesRequest } from 'store'
import { ReactComponent as CloudUploadIcon } from 'assets/icons/CloudUpload.svg'

import styles from './DragAndDropContainer.module.scss'

type TDragAndDropContainerProps = {
  children: ReactNode

  uploadedFrom?: UploadSourcesEnum
  noClick?: boolean
  noKeyboard?: boolean
  disabled?: boolean
  onDropText?: string
  onAfterUploadFile?: TCallbackAfterUploadFile
  caseId?: string
  chatId?: string | null
  centerOnDropAlert?: boolean
  onDropAlertLayout?: 'fixed'
  layout?: 'fixed'
  hide?: boolean
  onlyUpload?: boolean
  isBroadcast?: boolean
  maxFileSize?: number
} & ReturnType<typeof mapDispatchToProps>

const DragAndDrop = ({
  children,
  onDrop,
  onAfterUploadFile,
  chatId,
  caseId,
  onDropAlertLayout,
  layout,
  onDropText,
  noClick = true,
  disabled = false,
  noKeyboard = true,
  uploadedFrom = UploadSourcesEnum.CLOUD,
  centerOnDropAlert = false,
  hide = false,
  onlyUpload = false,
  isBroadcast = false,
  maxFileSize
}: TDragAndDropContainerProps) => {
  const { t } = useTranslation()

  const [showOnDropAlert, setShowOnDropAlert] = useState(false)

  const onDragEnter = useCallback(() => {
    setShowOnDropAlert(true)
  }, [])

  const onDragLeave = useCallback(() => {
    setShowOnDropAlert(false)
  }, [])

  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setShowOnDropAlert(false)
      onDrop({
        files: [...acceptedFiles, ...fileRejections.map((item) => item.file)],
        uploadedFrom,
        onAfterUploadFile,
        caseId,
        chatId: chatId ?? undefined,
        onlyUpload,
        maxFileSize,
        isBroadcast
      })
    },
    [onDrop, uploadedFrom, onAfterUploadFile, caseId, chatId, onlyUpload, isBroadcast, maxFileSize]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: WHITELIST_FILES_DOT,
    onDrop: handleDrop,
    onDragEnter,
    onDragLeave,
    noClick,
    noKeyboard,
    disabled: disabled || hide
  })

  return (
    <div
      className={cls(styles.dropRoot, {
        [styles.dropRootActive]: isDragActive,
        [styles.fixedLayout]: layout === 'fixed'
      })}
      {...getRootProps()}
    >
      {!hide && <input {...getInputProps()} />}

      {children}
      {!hide && (
        <div
          className={cls(styles.onDropAlert, {
            [styles.onDropAlertShow]: showOnDropAlert,
            [styles.onDropAlertCenter]: centerOnDropAlert,
            [styles.onDropAlertFixed]: onDropAlertLayout === 'fixed'
          })}
        >
          <CloudUploadIcon className={styles.onDropIcon} />
          <span className={styles.onDropText}>
            {onDropText ?? t('common.dragAndDrop.placeholder')}
          </span>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onDrop: uploadFilesRequest
    },
    dispatch
  )

export const DragAndDropContainer = connect(null, mapDispatchToProps)(DragAndDrop)
