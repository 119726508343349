import { useEffect, useState } from 'react'
import cls from 'classnames'

import { getMapComponent } from 'utils'
import { useJitsiPostMessage } from 'App/components'
import { ReactComponent as HighQualityConnectionIcon } from 'assets/icons/HighQualityConnection.svg'
import { ReactComponent as MediumQualityConnectionIcon } from 'assets/icons/MediumQualityConnection.svg'
import { ReactComponent as LowQualityConnectionIcon } from 'assets/icons/LowQualityConnection.svg'

import { CallTimer } from '../CallTimer'
import { EJitsiPostMessageTypes } from '../Call/hooks'

import styles from './CallStatus.module.scss'

export type TCallStatusProps = {
  isAnsweredCall: boolean
  startTime: Date | null | undefined
}

type TConnectionBitrate = {
  audio: {
    upload: number
    download: number
  }
  video: {
    upload: number
    download: number
  }
  download: number
  upload: number
}

/**
 * @EConnectionStatus
 * Status indicating that connection is currently active.
 *
 * ACTIVE: 'active',
 *
 *
 * Status indicating that connection is currently inactive.
 * Inactive means the connection was stopped on purpose from the bridge,
 * like exiting lastN or adaptivity decided to drop video because of not
 * enough bandwidth.
 *
 * INACTIVE: 'inactive',
 *
 *
 * Status indicating that connection is currently interrupted.
 *
 * INTERRUPTED: 'interrupted',
 *
 *
 * Status indicating that connection is currently restoring.
 *
 * RESTORING: 'restoring'
 */

export enum EConnectionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INTERRUPTED = 'interrupted',
  RESTORING = 'restoring'
}

type TConnectionStatusData = {
  bitrate: TConnectionBitrate
  connectionStatus: EConnectionStatus
}

const CALL_STATUS_ICON = new Map<EConnectionStatus, (className: string) => JSX.Element>()
  .set(EConnectionStatus.ACTIVE, (className) => <HighQualityConnectionIcon className={className} />)
  .set(EConnectionStatus.INACTIVE, (className) => (
    <MediumQualityConnectionIcon className={className} />
  ))
  .set(EConnectionStatus.INTERRUPTED, (className) => (
    <LowQualityConnectionIcon className={className} />
  ))

export const CallStatus = ({ isAnsweredCall, startTime }: TCallStatusProps) => {
  const [, setConnectionBitrate] = useState<TConnectionBitrate | null>(null)
  const [connectionStatus, setConnectionStatus] = useState<EConnectionStatus>(
    EConnectionStatus.ACTIVE
  )

  const connectionStatusData = useJitsiPostMessage<TConnectionStatusData>(
    EJitsiPostMessageTypes.JITSI_CONNECTION_STATUS
  )

  useEffect(() => {
    if (connectionStatusData?.connectionStatus) {
      setConnectionStatus(connectionStatusData.connectionStatus)
    }

    if (connectionStatusData?.bitrate) {
      setConnectionBitrate(connectionStatusData.bitrate)
    }
  }, [connectionStatusData])

  return (
    <div className={styles.root}>
      {getMapComponent(
        CALL_STATUS_ICON,
        connectionStatus,
        cls({
          [styles.icon]: true,
          [styles.iconActive]: connectionStatus === EConnectionStatus.ACTIVE,
          [styles.iconInactive]: connectionStatus === EConnectionStatus.INACTIVE,
          [styles.iconInterrupted]: connectionStatus === EConnectionStatus.INTERRUPTED,
          [styles.iconRestoring]: connectionStatus === EConnectionStatus.RESTORING
        })
      )}

      <CallTimer isAnsweredCall={isAnsweredCall} startTime={startTime} />
    </div>
  )
}
