import { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Skeleton } from 'antd'
import { BusinessAccountRoleNameEnum, SubscriptionIdsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import {
  createLoadingSelector,
  getRelatedAccountsRequest,
  GET_RELATED_ACCOUNTS,
  setDefaultAccountRequest,
  SET_DEFAULT_ACCOUNT
} from 'store'
import { SettingsSecurityDefaultAccountDialog } from 'App/components'

export type TSettingsSecurityDefaultAccountDialogContainerProps = ReturnType<
  typeof mapStateToProps
> &
  ReturnType<typeof mapDispatchToProps>

const SettingsSecurityDefaultAccountDialogView = ({
  ids,
  getRelatedAccounts,
  loading,
  error,
  displayUserName,
  defaultAccountId,
  firstName,
  lastName,
  role,
  type,
  setDefaultAccount,
  id,
  disabledList
}: TSettingsSecurityDefaultAccountDialogContainerProps) => {
  useEffect(() => {
    getRelatedAccounts()
  }, [getRelatedAccounts])

  if (loading) {
    return <Skeleton loading={true} active={true} title={false} avatar={false} />
  }

  return (
    <SettingsSecurityDefaultAccountDialog
      id={id}
      error={error}
      ids={ids}
      displayUserName={displayUserName}
      defaultAccountId={defaultAccountId}
      firstName={firstName}
      lastName={lastName}
      role={role}
      type={type}
      setDefaultAccount={setDefaultAccount}
      disabledList={disabledList}
    />
  )
}

const loadingSelector = createLoadingSelector([GET_RELATED_ACCOUNTS])

const mapStateToProps = (state: State) => {
  const { ids, list } = state.userProfile.relatedAccounts
  const disabledList = new Set(
    ids.filter(
      (id) =>
        list[id]?.subscription?.id === SubscriptionIdsEnum.BASIC_BUSINESS &&
        list[id]?.role?.name === BusinessAccountRoleNameEnum.ADMIN
    )
  )

  return {
    firstName: state.global.accountData?.firstName ?? '',
    lastName: state.global.accountData?.lastName ?? '',
    displayUserName: state.global.accountData?.displayUserName ?? '',
    type: state.global.accountData?.type?.name,
    role: state.global.accountData?.role?.name,
    id: state.global.accountData?.id,
    defaultAccountId: state.settings.securityAndLogin.defaultAccount.id,
    loading: loadingSelector(state),
    error: state.errors[SET_DEFAULT_ACCOUNT],
    ids,
    disabledList
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getRelatedAccounts: getRelatedAccountsRequest,
      setDefaultAccount: setDefaultAccountRequest
    },
    dispatch
  )

export const SettingsSecurityDefaultAccountDialogContainer: FC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSecurityDefaultAccountDialogView)
