// ------------------ CASE_DETAILS ------------------
export const CASE_DETAILS_RESET = 'CASE_DETAILS_RESET'

// ------------------ CASE_DESCRIPTION ------------------
export const GET_CASE_DESCRIPTION = 'GET_CASE_DESCRIPTION'
export const GET_CASE_DESCRIPTION_REQUEST = 'GET_CASE_DESCRIPTION_REQUEST'
export const GET_CASE_DESCRIPTION_SUCCESS = 'GET_CASE_DESCRIPTION_SUCCESS'
export const GET_CASE_DESCRIPTION_ERROR = 'GET_CASE_DESCRIPTION_ERROR'

export const ADD_CASE_DESCRIPTION = 'ADD_CASE_DESCRIPTION'
export const ADD_CASE_DESCRIPTION_REQUEST = 'ADD_CASE_DESCRIPTION_REQUEST'
export const ADD_CASE_DESCRIPTION_SUCCESS = 'ADD_CASE_DESCRIPTION_SUCCESS'
export const ADD_CASE_DESCRIPTION_ERROR = 'ADD_CASE_DESCRIPTION_ERROR'

// ------------------ CASE_SUMMARY ------------------
export const GET_CASE_SUMMARY = 'GET_CASE_SUMMARY'
export const GET_CASE_SUMMARY_REQUEST = 'GET_CASE_SUMMARY_REQUEST'
export const GET_CASE_SUMMARY_SUCCESS = 'GET_CASE_SUMMARY_SUCCESS'
export const GET_CASE_SUMMARY_ERROR = 'GET_CASE_SUMMARY_ERROR'

export const ADD_CASE_SUMMARY = 'ADD_CASE_SUMMARY'
export const ADD_CASE_SUMMARY_REQUEST = 'ADD_CASE_SUMMARY_REQUEST'
export const ADD_CASE_SUMMARY_SUCCESS = 'ADD_CASE_SUMMARY_SUCCESS'
export const ADD_CASE_SUMMARY_ERROR = 'ADD_CASE_SUMMARY_ERROR'

export const UPDATE_CASE_SUMMARY = 'UPDATE_CASE_SUMMARY'
export const UPDATE_CASE_SUMMARY_REQUEST = 'UPDATE_CASE_SUMMARY_REQUEST'
export const UPDATE_CASE_SUMMARY_SUCCESS = 'UPDATE_CASE_SUMMARY_SUCCESS'
export const UPDATE_CASE_SUMMARY_ERROR = 'UPDATE_CASE_SUMMARY_ERROR'

// ------------------ CASE_OPINIONS ------------------
export const GET_CASE_OPINIONS_MEMBERS = 'GET_CASE_OPINIONS_MEMBERS'
export const GET_CASE_OPINIONS_MEMBERS_REQUEST = 'GET_CASE_OPINIONS_MEMBERS_REQUEST'
export const GET_CASE_OPINIONS_MEMBERS_SUCCESS = 'GET_CASE_OPINIONS_MEMBERS_SUCCESS'
export const GET_CASE_OPINIONS_MEMBERS_ERROR = 'GET_CASE_OPINIONS_MEMBERS_ERROR'

export const GET_CASE_OPINIONS_BY_MEMBER = 'GET_CASE_OPINIONS_BY_MEMBER'
export const GET_CASE_OPINIONS_BY_MEMBER_REQUEST = 'GET_CASE_OPINIONS_BY_MEMBER_REQUEST'
export const GET_CASE_OPINIONS_BY_MEMBER_SUCCESS = 'GET_CASE_OPINIONS_BY_MEMBER_SUCCESS'
export const GET_CASE_OPINIONS_BY_MEMBER_ERROR = 'GET_CASE_OPINIONS_BY_MEMBER_ERROR'

export const GET_CASE_OPINIONS = 'GET_CASE_OPINIONS'
export const GET_CASE_OPINIONS_REQUEST = 'GET_CASE_OPINIONS_REQUEST'
export const GET_CASE_OPINIONS_SUCCESS = 'GET_CASE_OPINIONS_SUCCESS'
export const GET_CASE_OPINIONS_ERROR = 'GET_CASE_OPINIONS_ERROR'

export const ADD_CASE_OPINION = 'ADD_CASE_OPINION'
export const ADD_CASE_OPINION_REQUEST = 'ADD_CASE_OPINION_REQUEST'
export const ADD_CASE_OPINION_SUCCESS = 'ADD_CASE_OPINION_SUCCESS'
export const ADD_CASE_OPINION_ERROR = 'ADD_CASE_OPINION_ERROR'

export const SET_NEW_LINE_DESCRIPTION = 'SET_NEW_LINE_DESCRIPTION'
export const SET_NEW_LINE_OPINION = 'SET_NEW_LINE_OPINION'

export const SET_NEW_OPINION = 'SET_NEW_OPINION'

export const SET_OPENED_OPINION = 'SET_OPENED_OPINION'

// ------------------ CASE_DRAFT ------------------
export const UPDATE_CASE_DRAFT = 'UPDATE_CASE_DRAFT'
export const UPDATE_CASE_DRAFT_REQUEST = 'UPDATE_CASE_DRAFT_REQUEST'
export const UPDATE_CASE_DRAFT_SUCCESS = 'UPDATE_CASE_DRAFT_SUCCESS'
export const UPDATE_CASE_DRAFT_ERROR = 'UPDATE_CASE_DRAFT_ERROR'

export const GET_CASE_DRAFT = 'GET_CASE_DRAFT'
export const GET_CASE_DRAFT_REQUEST = 'GET_CASE_DRAFT_REQUEST'
export const GET_CASE_DRAFT_SUCCESS = 'GET_CASE_DRAFT_SUCCESS'
export const GET_CASE_DRAFT_ERROR = 'GET_CASE_DRAFT_ERROR'
export const GET_CASE_DRAFT_CANCEL = 'GET_CASE_DRAFT_CANCEL'
