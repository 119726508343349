import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ContactInviteInteractionEnum } from '@medentee/enums'
import { toast } from 'react-toastify'

import { API, api } from 'services/api'
import {
  ACCEPT_CONTACT_INVITE_SUCCESS,
  getAllOnlineSaga,
  getContactsRequest,
  hideModalAction,
  TLastRequest
} from 'store'
import { State } from 'redux/rootReducer'
import { TSendInviteToContactRequest } from 'store/inviteContacts/inviteContacts.types'
import { QueryBuilder } from 'utils'
import { toastDefaultOptions } from 'globalConstants'

import i18n from '../../i18n'

import * as actionTypes from './inviteContacts.actionTypes'
import * as actions from './inviteContacts.actions'

function* inviteApprovedWebsocketSaga() {
  yield call(getAllOnlineSaga)
}

function* sendInviteToContactSaga({ payload }: TSendInviteToContactRequest) {
  const { processingId, onSuccess, ...rest } = payload

  try {
    const lastRequest: TLastRequest = yield select((state: State) => state.contacts.lastRequest)

    const queryBuilder = new QueryBuilder(API.CONTACTS)

    const url = queryBuilder
      .custom('interactionParam', ContactInviteInteractionEnum.CROSS_INVITE)
      .build()

    yield call(api.post, url, {
      ...rest
    })

    if (onSuccess) {
      onSuccess()
    }

    yield put(actions.sendInviteToContactSuccess({ processingId }))
    yield put(hideModalAction())
    yield put(getContactsRequest({ ...lastRequest }))
    yield toast.success(i18n.t('common.toast.invitationSent'), toastDefaultOptions)
  } catch (e) {
    yield put(actions.sendInviteToContactError({ ...e, processingId }))
  }
}

export function* inviteContactSaga() {
  yield takeLatest(actionTypes.SEND_INVITE_TO_CONTACT_REQUEST, sendInviteToContactSaga)
  yield takeLatest(
    [actionTypes.SEND_INVITE_TO_CONTACT_SUCCESS, ACCEPT_CONTACT_INVITE_SUCCESS],
    inviteApprovedWebsocketSaga
  )
}
