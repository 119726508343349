import { generatePath } from 'react-router-dom'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { ELayoutNavKey } from 'enums'
import { supportChatIdSelector, useAppSelector } from 'store'
import { CHAT_PATH, EChatTypeMap, HELP_CENTER_URL } from 'globalConstants'
import { ReactComponent as SupportIcon } from 'assets/icons/Support.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg'

import { TNavClasses } from '../Nav'
import { NavItem } from '../NavItem'
import { useAdaptiveLayout } from '../../../../hooks'
import { Popover } from '../../../common'

import styles from './SupportNavItem.module.scss'

export type TSupportNavItemProps = TNavClasses

export const SupportNavItem = ({ classes }: TSupportNavItemProps) => {
  const { t } = useTranslation()

  const supportChatId = useAppSelector(supportChatIdSelector)
  const { isMobile } = useAdaptiveLayout()

  const itemClasses = {
    ...classes,
    text: cls(classes?.text, { [styles.text]: !isMobile }),
    iconWrapper: cls(classes?.iconWrapper, { [styles.iconWrapper]: !isMobile })
  }
  const variant = isMobile ? undefined : 'horizontal'
  const content = (
    <>
      <NavItem
        type="external"
        classes={itemClasses}
        to={HELP_CENTER_URL}
        label={t('navigation.helpCenter')}
        icon={<InfoIcon />}
        variant={variant}
        itemKey={ELayoutNavKey.HELP_CENTER}
      />
      <NavItem
        counterStateString={`chats.list.${supportChatId}.unreadCount`}
        classes={itemClasses}
        isActive={() => false}
        variant={variant}
        to={generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE, chatId: supportChatId })}
        label={t('navigation.chatWithSupport')}
        icon={<SupportIcon />}
        itemKey={ELayoutNavKey.SUPPORT}
      />
    </>
  )

  if (isMobile) {
    return content
  }

  return (
    <Popover arrow={false} content={<div className={styles.popover}>{content}</div>}>
      <NavItem
        type="text"
        classes={classes}
        isActive={() => false}
        to={generatePath(CHAT_PATH, { chatType: EChatTypeMap.DIALOGUE, chatId: supportChatId })}
        label={t('navigation.helpAndSupport')}
        icon={<SupportIcon />}
        itemKey={ELayoutNavKey.HELP_AND_SUPPORT}
        counterStateString={`chats.list.${supportChatId}.unreadCount`}
      />
    </Popover>
  )
}
