import { useCallback, useState } from 'react'
import isArray from 'lodash/isArray'
import { useRouteMatch } from 'react-router-dom'
import { formatISO } from 'date-fns'
import { toast } from 'react-toastify'
import { utcToZonedTime } from 'date-fns-tz'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import { editEvent, getEvent } from 'api/events'
import { EventInfoForm, TEventInfoFormValues } from 'App/components/Events/EventInfoForm'
import { toastDefaultOptions } from 'globalConstants'
import { isSameDate } from 'utils'

import styles from './EventBasicInfo.module.scss'

const EVENT_QUERY_KEY = 'get-event'

export const EventBasicInfo = () => {
  const { params } = useRouteMatch<{ id: string }>()

  const [initialValues, setInitialValues] = useState<TEventInfoFormValues>()

  const { t } = useTranslation()

  const eventId = params.id

  const { isFetching } = useQuery({
    queryKey: [EVENT_QUERY_KEY],
    queryFn: () => getEvent(eventId),
    enabled: Boolean(eventId),
    onSuccess: ({ city, country, startOn, endOn, title, location, about, headline }) => {
      const endOnUTC = utcToZonedTime(new Date(endOn), 'UTC')
      const isOneDay = isSameDate(startOn, endOn)

      const dates = isOneDay
        ? new Date(startOn)
        : [new Date(startOn), new Date(formatISO(endOnUTC, { representation: 'date' }))]

      setInitialValues({
        city,
        country,
        dates,
        isOneDay,
        title,
        location,
        about,
        headline
      })
    }
  })

  const { isLoading, mutate } = useMutation({
    mutationFn: editEvent,
    onSuccess: () => {
      toast.success(t('common.toast.updatesSaved'), toastDefaultOptions)
    }
  })

  const handleSubmit = useCallback(
    ({ city, dates, title, location = '', about = '', headline = '' }: TEventInfoFormValues) => {
      const isRangeDates = isArray(dates)
      const startOn = isRangeDates ? dates[0] : dates
      const endOn = isRangeDates ? dates[1] : dates

      if (!startOn || !endOn) {
        return
      }

      return mutate({
        id: eventId,
        payload: {
          title,
          startOn,
          endOn,
          cityId: city.id.toString(),
          location,
          about,
          headline
        }
      })
    },
    [eventId, mutate]
  )

  return (
    <div className={styles.root}>
      <EventInfoForm
        mode="edit"
        id={eventId}
        initialValues={initialValues}
        processing={isLoading}
        loading={isFetching}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
