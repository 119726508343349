import { useCallback } from 'react'
import { SwitchChangeEventHandler } from 'antd/lib/switch'
import { useTranslation } from 'react-i18next'

import { EToggleCallOrSoundType } from 'enums'
import { SettingsItemTitle, Switch, SettingsItemSubtitle } from 'App/components'
import { TSoundsContainerProps } from 'App/containers'

export type TSoundsProps = TSoundsContainerProps

export const Sounds = ({ muteCalls, muteNotifications, toggleCallsOrSounds }: TSoundsProps) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (type: EToggleCallOrSoundType): SwitchChangeEventHandler =>
      (checked) => {
        toggleCallsOrSounds({ type, currentStatus: checked })
      },
    [toggleCallsOrSounds]
  )

  return (
    <>
      <SettingsItemTitle>{t('settings.notifications.sounds.title')}</SettingsItemTitle>

      <SettingsItemSubtitle
        addon={
          <Switch onChange={handleChange(EToggleCallOrSoundType.MUTE_CALLS)} checked={muteCalls} />
        }
      >
        {t('settings.notifications.sounds.muteIncomingCalls')}
      </SettingsItemSubtitle>

      <SettingsItemSubtitle
        addon={
          <Switch
            onChange={handleChange(EToggleCallOrSoundType.MUTE_NOTIFICATIONS)}
            checked={muteNotifications}
          />
        }
      >
        {t('settings.notifications.sounds.muteNotifications')}
      </SettingsItemSubtitle>
    </>
  )
}
