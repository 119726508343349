import { Pagination as OriginalPagination } from 'antd'
import { PaginationProps } from 'antd/lib/pagination'
import cls from 'classnames'
import { Trans, useTranslation } from 'react-i18next'

import { PAGINATION_DEFAULT_SHOW_BY, PAGE_SIZE_OPTIONS, PAGINATION_DEFAULT } from 'types'

import { PaginationItem } from '../PaginationItem'

import styles from './Pagination.module.scss'

export type TPaginationProps = Omit<PaginationProps, 'current' | 'onShowSizeChange'> &
  PropsWithClasses<
    'pagination' | 'container',
    {
      current?: number
      wrapperClassName?: string
      onShowSizeChange?: (pageSize: number) => void
    }
  >

export const Pagination = ({
  total,
  classes,
  onChange,
  onShowSizeChange,
  current = PAGINATION_DEFAULT.current,
  defaultPageSize = PAGINATION_DEFAULT.showBy,
  hideOnSinglePage = false,
  showTitle = false,
  itemRender = PaginationItem,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  wrapperClassName,
  pageSize = PAGINATION_DEFAULT_SHOW_BY,
  showSizeChanger = false,
  ...rest
}: TPaginationProps) => {
  const { t } = useTranslation()

  const handleChange = (page: number, newPageSize: number) => {
    const newPage = page - 1

    onChange && onChange(newPage < 0 ? 0 : newPage, newPageSize)
  }

  const handleShowSizeChange = (_: number, newPageSize: number) => {
    onShowSizeChange && onShowSizeChange(newPageSize)
  }

  if (hideOnSinglePage) {
    return null
  }

  const shouldShowTotal = !!total
  const shouldShowPagination = (total ?? 0) > pageSize
  const shouldShowContent = shouldShowTotal || shouldShowPagination

  const content = shouldShowContent ? (
    <div className={cls(styles.container, classes?.container)}>
      {shouldShowTotal && (
        <span className={styles.totalLabel}>
          <Trans t={t} i18nKey="common.pagination.total" values={{ total }}>
            Total: <b>{{ total }}</b>
          </Trans>
        </span>
      )}

      {shouldShowPagination && (
        <OriginalPagination
          pageSizeOptions={pageSizeOptions}
          className={styles.root}
          itemRender={itemRender}
          defaultPageSize={defaultPageSize}
          hideOnSinglePage={hideOnSinglePage}
          showTitle={showTitle}
          current={current + 1}
          onChange={handleChange}
          total={total}
          pageSize={pageSize}
          showSizeChanger={showSizeChanger}
          onShowSizeChange={handleShowSizeChange}
          {...rest}
        />
      )}
    </div>
  ) : null

  if (wrapperClassName) {
    return <div className={wrapperClassName}>{content}</div>
  }

  return content
}
