import { ReactNode } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import { stopPropagation } from 'utils'

import styles from './ChatRoomHeaderWrapper.module.scss'

export type TChatRoomHeaderWrapperProps = PropsWithClasses<
  'root',
  {
    actions: ReactNode
    loading: boolean

    title?: ReactNode
    backButton?: ReactNode
    onClick?: () => void
  }
>

export const ChatRoomHeaderWrapper = ({
  title,
  actions,
  classes,
  backButton,
  loading,
  onClick
}: TChatRoomHeaderWrapperProps) => (
  <div className={cls(styles.root, classes?.root)} onClick={onClick}>
    <Skeleton active loading={loading} title={false} avatar={false} paragraph={{ rows: 4 }}>
      {backButton}
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.actions} onClick={stopPropagation}>
        {actions}
      </div>
    </Skeleton>
  </div>
)
