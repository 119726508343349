import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { TChatMessageDiscardedFilePermissionContainerProps } from 'App/containers'
import { ChatMessagePlaceholder, ChatMessageSenderName } from 'App/components'
import { ReactComponent as FileCancelOutline } from 'assets/icons/FileCancelOutline.svg'

import styles from './ChatMessageDiscardedFilePermission.module.scss'

export type TChatMessageDiscardedFilePermissionBaseProps = {
  id: string
  showUserName?: boolean
}
export type TTChatMessageDiscardedFilePermissionContainerPropsProps =
  TChatMessageDiscardedFilePermissionBaseProps & TChatMessageDiscardedFilePermissionContainerProps

export const ChatMessageDiscardedFilePermission = ({
  isMessageOutgoing,
  senderName
}: TTChatMessageDiscardedFilePermissionContainerPropsProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <ChatMessageSenderName senderName={senderName} />
      <ChatMessagePlaceholder
        icon={<FileCancelOutline className={styles.icon} />}
        label={t('chat.list.lastMessage.placeholder_discardedPermission')}
        isMessageOutgoing={isMessageOutgoing}
        classes={{
          placeholderIcon: styles.placeholderIcon,
          placeholder: cls(styles.placeholder, { [styles.outgoingMessage]: isMessageOutgoing })
        }}
      />
    </div>
  )
}
