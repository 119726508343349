import { Field, Form } from 'react-final-form'
import { Col, Row } from 'antd'
import { CommunityPublicityEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'
import isEqual from 'lodash/isEqual'

import { useUnsavedDataPrompt } from 'App/hooks/useUnsavedDataPrompt'
import { validation } from 'utils/validation'
import { TextFieldSkeleton } from 'App/components/common/Skeletons/TextFieldSkeleton'
import { Button } from 'App/components/common/Button'
import { Checkbox } from 'App/components/common/Checkbox'
import { TIds } from 'store'
import {
  UnityHubSettingsRadio,
  UnityHubSettingsRadioGroup
} from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsRadio'
import { UnityHubSettingsTitle } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsTitle'
import { UnityHubSettingsDescription } from 'App/components/UnityHub/UnityHubSettings/UnityHubSettingsDescription'
import { Divider, SpyOnFormChanged } from 'App/components/common'

import { useCommunityOrganizerSettingsRequest } from './useCommunityOrganizerSettingsRequest'
import styles from './CommunityOrganizerSettings.module.scss'

export type TCommunityOrganizerSettingsFormValues = {
  publicity: CommunityPublicityEnum
  membershipEventIds: TIds
}

export const CommunityOrganizerSettings = () => {
  const { ignore, unIgnore, prompt, setFormChanged } = useUnsavedDataPrompt()

  const { events, initialValues, isLoading, loading, handleFormSubmit } =
    useCommunityOrganizerSettingsRequest({ ignore })

  const { t } = useTranslation()

  const handleSpyChange = (value: boolean) => {
    unIgnore()
    setFormChanged(value)
  }

  return (
    <div className={styles.root}>
      <Form onSubmit={handleFormSubmit} initialValues={initialValues}>
        {({
          handleSubmit,
          submitting,
          valid,
          dirty,
          dirtySinceLastSubmit,
          submitSucceeded,
          values,
          form
        }) => (
          <Row gutter={[0, 50]} justify="center">
            <SpyOnFormChanged onChange={handleSpyChange} prompt={prompt} />

            <Col xs={24}>
              <Row gutter={[0, 30]}>
                <Col xs={24}>
                  <Row gutter={[0, 12]}>
                    <Col xs={24}>
                      <UnityHubSettingsTitle
                        title={t('communities.organizerSettings.publicityTitle')}
                      />
                    </Col>

                    <Col xs={24}>
                      <UnityHubSettingsDescription
                        description={t('communities.organizerSettings.publicityDescription')}
                      />
                    </Col>

                    <Col xs={24}>
                      <TextFieldSkeleton loading={loading}>
                        <Field<TCommunityOrganizerSettingsFormValues['publicity']>
                          name="publicity"
                          validate={validation.required()}
                          type="radio"
                        >
                          {({ input: { name, onChange } }) => (
                            <UnityHubSettingsRadioGroup
                              name={name}
                              value={values.publicity}
                              onChange={onChange}
                            >
                              <UnityHubSettingsRadio value={CommunityPublicityEnum.PUBLIC}>
                                {t('communities.organizerSettings.publicityPublicOption')}
                              </UnityHubSettingsRadio>

                              <UnityHubSettingsRadio value={CommunityPublicityEnum.PRIVATE}>
                                {t('communities.organizerSettings.publicityPrivateOption')}
                              </UnityHubSettingsRadio>
                            </UnityHubSettingsRadioGroup>
                          )}
                        </Field>
                      </TextFieldSkeleton>
                    </Col>
                  </Row>
                </Col>

                <Divider />

                {values?.publicity === CommunityPublicityEnum.PUBLIC && (
                  <Col xs={24}>
                    <Row gutter={[0, 12]}>
                      <Col xs={24}>
                        <UnityHubSettingsTitle
                          title={t('communities.organizerSettings.membershipTitle')}
                        />
                      </Col>

                      <Col xs={24}>
                        <UnityHubSettingsDescription
                          description={t('communities.organizerSettings.membershipDescription')}
                        />
                      </Col>

                      <Col xs={24}>
                        <Row gutter={[0, 16]}>
                          {events?.results.length ? (
                            events.results.map((item) => (
                              <Col xs={24} key={item.id}>
                                <TextFieldSkeleton loading={loading}>
                                  <Field
                                    name="membershipEventIds"
                                    isEqual={isEqual}
                                    type="checkbox"
                                  >
                                    {({ input }) => {
                                      const checked = values.membershipEventIds?.includes(item.id)

                                      return (
                                        <Checkbox
                                          {...input}
                                          label={item.title}
                                          checked={checked}
                                          onChange={(event) => {
                                            input.onChange(event)
                                            form.change(
                                              'membershipEventIds',
                                              checked
                                                ? values.membershipEventIds.filter(
                                                    (id) => id !== item.id
                                                  )
                                                : [...values.membershipEventIds, item.id]
                                            )
                                          }}
                                        />
                                      )
                                    }}
                                  </Field>
                                </TextFieldSkeleton>
                              </Col>
                            ))
                          ) : (
                            <UnityHubSettingsDescription
                              description={
                                <strong>
                                  {t('communities.organizerSettings.membershipPlaceholder')}
                                </strong>
                              }
                            />
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>

            <Col>
              <Button
                onClick={handleSubmit}
                disabled={
                  !valid ||
                  (!submitSucceeded && !dirty) ||
                  (submitSucceeded && !dirtySinceLastSubmit)
                }
                loading={submitting || isLoading}
              >
                {t('communities.organizerSettings.submitButton')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}
