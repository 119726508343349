import { useCallback, useMemo } from 'react'
import isArray from 'lodash/isArray'
import { useTranslation } from 'react-i18next'

import { TMenuItemProps } from 'App/components'
import { EModalComponents } from 'App/containers'
import { setSelectedTrashBinFileId, showModalAction, useAppDispatch } from 'store'
import { ReactComponent as ClockReverseIcon } from 'assets/icons/ClockReverse.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg'
import { ReactComponent as FileIcon } from 'assets/icons/File.svg'

import { TDataSource } from './TrashBin'

type TFileRecord = Pick<TDataSource, 'fileId' | 'fileName' | 'extension'>

type TMenuItemsProps = TFileRecord | TFileRecord[]

export type TUseKebabMenu = {
  getMenuItems: (props: TMenuItemsProps) => TMenuItemProps[]
}

export const useKebabMenu = (): TUseKebabMenu => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const menuItems = useMemo(
    () => ({
      'restore-files': (props: TMenuItemsProps) => ({
        content: t('files.kebabMenu.restore'),
        icon: <ClockReverseIcon />,
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.restoreFileConfirm.title'),
              modalType: EModalComponents.RESTORE_FILE_DIALOG,
              modalProps: {
                files: isArray(props) ? props : [props]
              }
            })
          )
      }),
      'delete-forever': (props: TMenuItemsProps) => ({
        icon: <TrashIcon />,
        content: t('files.kebabMenu.deleteForever'),
        onClick: () =>
          dispatch(
            showModalAction({
              modalTitle: t('modal.deleteFilePermanentlyConfirm.title'),
              modalType: EModalComponents.DELETE_FILE_FROM_MEDCLOUD_CONFIRM,
              modalProps: {
                fileIds: isArray(props) ? props.map(({ fileId }) => fileId) : [props.fileId]
              }
            })
          )
      }),
      history: ({ fileId }: TFileRecord) => ({
        icon: <FileIcon />,
        content: t('files.kebabMenu.history'),
        onClick: () => dispatch(setSelectedTrashBinFileId(fileId))
      })
    }),
    [dispatch, t]
  )

  const getMenuItems = useCallback(
    (props: TMenuItemsProps) => {
      if (isArray(props)) {
        return [menuItems['restore-files'](props), menuItems['delete-forever'](props)]
      }

      return [
        menuItems['restore-files'](props),
        menuItems['delete-forever'](props),
        menuItems.history(props)
      ]
    },
    [menuItems]
  )

  return {
    getMenuItems
  }
}
