import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { BroadcastStatusEnum, BroadcastTypeEnum } from '@medentee/enums'
import { toast } from 'react-toastify'

import { API, api, APIResultsResponse } from 'services/api'
import { normalizeList } from 'store/utils'
import { QueryBuilder } from 'utils'
import { State } from 'redux/rootReducer'
import {
  ESocketEmitEventNamesBroadcasts,
  ESocketNameSpaces,
  wrapperForSocketEmit
} from 'services/webSocket'
import { JOIN_VOICE_ROOM_REQUEST, leaveVoiceRoomRequest } from 'store/organizations'
import {
  JOIN_TO_CALL_REQUEST,
  SEND_ALLOW_CALL_REQUEST,
  SEND_CREATE_CALL_REQUEST,
  sendEndCallRequest
} from 'store/calls'
import { TBroadcast } from 'interfaces/api/broadcasts'
import { handleError } from 'api/utils'
import { LOST_ACCESS_TO_COMMUNITY } from 'store/communities'
import { LOST_ACCESS_TO_EVENT } from 'store/events'

import { toastDefaultOptions } from '../../globalConstants'
import { shouldSkipAction } from '../../services/skipWSActions'
import { BroadCastStartedNotification } from '../../App/components/Broadcasts/BroadCastNotifications'

import * as actions from './broadcasts.actions'
import * as actionTypes from './broadcasts.actionTypes'
import { receiveBroadcastWatched } from './broadcasts.actions'
import { RECEIVE_BROADCAST_CREATED, RECEIVE_BROADCAST_WATCHED } from './broadcasts.actionTypes'

function* getBroadcastsSaga({
  payload: { onSuccess, ...payload }
}: ReturnType<typeof actions.getBroadcastsRequest>) {
  try {
    const communityIds = payload.type === BroadcastTypeEnum.COMMUNITY ? payload.communityIds : []
    const eventIds = payload.type === BroadcastTypeEnum.EVENT ? payload.eventIds : []

    const url = new QueryBuilder(API.BROADCASTS)
      .multiSearch('communityIds', communityIds)
      .multiSearch('eventIds', eventIds)
      .build()

    const {
      data: { results, total }
    }: APIResultsResponse<TBroadcast[]> = yield call(api.get, url)

    const normalized = normalizeList(results)

    yield put(
      actions.getBroadcastsSuccess({
        ...normalized,
        total
      })
    )

    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    handleError(e)
    yield put(actions.getBroadcastsError(e))
  }
}

function* updateBroadcastSaga({
  payload
}: ReturnType<typeof actions.receiveUpdateBroadcastStatus>) {
  const community = payload.type === BroadcastTypeEnum.COMMUNITY ? payload.community : undefined
  const event = payload.type === BroadcastTypeEnum.EVENT ? payload.event : undefined

  const currentCommunityId: string | null = yield select(
    (state: State) => state.communities.currentCommunityId
  )

  const currentEventId: string | null = yield select((state: State) => state.events.currentEventId)

  if (currentCommunityId && community?.id === currentCommunityId) {
    yield put(
      actions.getBroadcastsRequest({
        communityIds: [community.id],
        type: BroadcastTypeEnum.COMMUNITY
      })
    )
    return
  }

  if (currentEventId && event?.id === currentEventId) {
    yield put(actions.getBroadcastsRequest({ eventIds: [event.id], type: BroadcastTypeEnum.EVENT }))
  }
}

function* liveBroadCastNotificationSaga({
  payload
}: ReturnType<typeof actions.receiveUpdateBroadcastStatus>) {
  const currentBroadcastId: string | null = yield select(
    (state: State) => state.broadcasts.activeBroadcast?.id
  )
  const community = payload.type === BroadcastTypeEnum.COMMUNITY ? payload.community : undefined
  const event = payload.type === BroadcastTypeEnum.EVENT ? payload.event : undefined

  if (
    payload.id !== currentBroadcastId &&
    !shouldSkipAction({ type: RECEIVE_BROADCAST_CREATED, payload }) &&
    payload.status === BroadcastStatusEnum.LIVE
  ) {
    const entity = community || event
    toast.info(
      <BroadCastStartedNotification
        broadcast={payload}
        entity={{ name: entity?.title ?? '', id: entity?.id ?? '' }}
      />,
      {
        ...toastDefaultOptions,
        toastId: payload.id,
        autoClose: false
      }
    )
  } else {
    toast.dismiss(payload.id)
  }
}

function* closeActiveCallsSaga({
  payload: { broadcastId }
}: ReturnType<typeof actions.openBroadcast>) {
  const activeBroadcastId: string | undefined = yield select(
    (state: State) => state.broadcasts.activeBroadcast?.id
  )

  const activeVoiceRoomId: string | undefined = yield select(
    (state: State) => state.organizations.activeVoiceRoom?.id
  )

  const activeCallId: string | undefined = yield select(
    (state: State) => state.calls.answer.createCall?.id
  )

  const {
    isAcceptedCall,
    isComingCall,
    isInitiatedCall
  }: Record<'isInitiatedCall' | 'isAcceptedCall' | 'isComingCall', boolean> = yield select(
    (state: State) => ({
      isInitiatedCall: state.calls.init.isInitiatedCall,
      isAcceptedCall: state.calls.answer.isAcceptedCall,
      isComingCall: state.calls.answer.isComingCall
    })
  )

  if (!activeBroadcastId && broadcastId && activeVoiceRoomId) {
    // If there is an activeVoiceRoomId and a broadcast is opened, you need to close VoiceRoom

    yield put(leaveVoiceRoomRequest({ voiceRoomId: activeVoiceRoomId }))
  }

  const callOwner = isInitiatedCall && !isAcceptedCall && !isComingCall
  const callRecipient = !isInitiatedCall && isAcceptedCall && !isComingCall

  if (!activeBroadcastId && broadcastId && activeCallId && (callOwner || callRecipient)) {
    // If there is an active call or meeting and a broadcast is opened, you need to close Call

    yield put(sendEndCallRequest({ callId: activeCallId }))
  }
}

function* closeActiveBroadcastSaga() {
  const activeBroadcastId: string | undefined = yield select(
    (state: State) => state.broadcasts.activeBroadcast?.id
  )

  if (activeBroadcastId) {
    yield put(actions.openBroadcast({ broadcastId: null, entityId: null }))
  }
}

function* openBroadcastSaga({
  payload: { broadcastId, entityId }
}: ReturnType<typeof actions.openBroadcast>) {
  try {
    const activeBroadcastId: string | undefined = yield select(
      (state: State) => state.broadcasts.activeBroadcast?.id
    )
    const ids: string[] = yield select((state: State) => state.broadcasts.data.ids)

    if (broadcastId && !ids.includes(broadcastId)) {
      return
    }

    if (!activeBroadcastId && broadcastId) {
      yield call(
        wrapperForSocketEmit,
        ESocketNameSpaces.BROADCASTS,
        ESocketEmitEventNamesBroadcasts.WATCH_BROADCAST,
        { broadcastId }
      )
    }

    if (activeBroadcastId && !broadcastId) {
      yield call(
        wrapperForSocketEmit,
        ESocketNameSpaces.BROADCASTS,
        ESocketEmitEventNamesBroadcasts.UNWATCH_BROADCAST,
        { broadcastId: activeBroadcastId }
      )
    }

    yield put(actions.setActiveBroadcast({ broadcastId, entityId }))
  } catch (e) {
    console.error(e)
  }
}

function* receiveBroadcastDeletedSaga({
  payload
}: ReturnType<typeof actions.receiveBroadcastDeleted>) {
  try {
    const activeBroadcastId: string | undefined = yield select(
      (state: State) => state.broadcasts.activeBroadcast?.id
    )

    const community = payload.type === BroadcastTypeEnum.COMMUNITY ? payload.community : undefined
    const event = payload.type === BroadcastTypeEnum.EVENT ? payload.event : undefined

    if (activeBroadcastId === payload.id) {
      yield put(actions.openBroadcast({ broadcastId: null, entityId: null }))
      yield put(
        actions.getBroadcastsRequest({
          eventIds: event ? [event.id] : [],
          communityIds: community ? [community.id] : [],
          type: payload.type
        })
      )
    }
  } catch (e) {
    console.error(e)
  }
}

function* removeLiveBroadcastNotificationSaga({
  payload
}: ReturnType<typeof receiveBroadcastWatched>) {
  toast.dismiss(payload.id)
}

export function* broadcastsSaga() {
  yield takeLatest(actionTypes.GET_BROADCASTS_REQUEST, getBroadcastsSaga)
  yield takeLatest(actionTypes.OPEN_BROADCAST, openBroadcastSaga)
  yield takeLatest(
    [
      actionTypes.RECEIVE_BROADCAST_CREATED,
      actionTypes.RECEIVE_BROADCAST_DELETED,
      actionTypes.RECEIVE_UPDATE_BROADCAST_STATUS
    ],
    updateBroadcastSaga
  )
  yield takeLatest(
    [
      actionTypes.RECEIVE_BROADCAST_CREATED,
      actionTypes.RECEIVE_UPDATE_BROADCAST_STATUS,
      actionTypes.RECEIVE_BROADCAST_DELETED
    ],
    liveBroadCastNotificationSaga
  )
  yield takeLatest(actionTypes.RECEIVE_BROADCAST_DELETED, receiveBroadcastDeletedSaga)

  yield takeEvery(actionTypes.OPEN_BROADCAST, closeActiveCallsSaga)
  yield takeEvery(
    [
      SEND_ALLOW_CALL_REQUEST,
      SEND_CREATE_CALL_REQUEST,
      JOIN_TO_CALL_REQUEST,
      JOIN_VOICE_ROOM_REQUEST,
      LOST_ACCESS_TO_COMMUNITY,
      LOST_ACCESS_TO_EVENT
    ],
    closeActiveBroadcastSaga
  )
  yield takeEvery(RECEIVE_BROADCAST_WATCHED, removeLiveBroadcastNotificationSaga)
}
