import { connect } from 'react-redux'

import { State } from 'redux/rootReducer'
import { getChatSenderName } from 'utils'
import {
  ChatMessageDiscardedFilePermission,
  TChatMessageDiscardedFilePermissionBaseProps
} from 'App/components'
import { isMessageOutgoingSelector } from 'store/chatMessages'

export type TChatMessageDiscardedFilePermissionContainerProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (
  state: State,
  { id, showUserName }: TChatMessageDiscardedFilePermissionBaseProps
) => {
  const { sender, yourMessage } = state.chat.chatMessages.messages.list[id] ?? {}

  const senderName = getChatSenderName({
    senderName: sender?.displayUserName,
    showUserName,
    yourMessage
  })

  return {
    senderName,
    isMessageOutgoing: isMessageOutgoingSelector(yourMessage)(state)
  }
}

export const ChatMessageDiscardedFilePermissionContainer = connect(mapStateToProps)(
  ChatMessageDiscardedFilePermission
)
