import { useState } from 'react'

import { useMutation } from 'services/query'
import { EChatHistoryEvents, EIconSize, EInputSize } from 'enums'
import { TChatBaseMessage, setChatHistoryEventAction, useAppDispatch, useAppSelector } from 'store'
import { IconButton, Search } from 'App/components'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg'
import { ReactComponent as CloseCircleOutlinedIcon } from 'assets/icons/CloseCircleOutlined.svg'
import { useChatContext } from 'App/context/ChatContext'
import { getChatRoomMessages } from 'api/chats'
import { EHistoryType } from 'globalConstants'
import { APIResultsResponse } from 'services/api'

import styles from './ChatRoomSearch.module.scss'
import { ChatRoomSearchResults, TChatRoomSearchResultsProps } from './ChatRoomSearchResults'

const GET_CHAT_ROOM_SEARCHED_MESSAGES_MUTATION_KEY = 'get-chat-room-searched-messages'

export const ChatRoomSearch = () => {
  const dispatch = useAppDispatch()

  const chatId = useAppSelector((state) => state.chat.chatRooms.selectedChat?.id)
  const chatType = useAppSelector((state) => state.chat.chatRooms.selectedChat?.type)

  const {
    showChatRoomSearchResults,
    hideChatRoomSearch,
    displayChatRoomSearchResults,
    hideChatRoomSearchResults
  } = useChatContext()

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)
  const [data, setData] = useState<Pick<
    APIResultsResponse<TChatBaseMessage[]>['data'],
    'results' | 'total'
  > | null>()

  const [searchQuery, setSearchQuery] = useState('')

  const { isLoading, mutate: getMessages } = useMutation({
    mutationKey: [GET_CHAT_ROOM_SEARCHED_MESSAGES_MUTATION_KEY],
    mutationFn: getChatRoomMessages,
    onSuccess: ({ results, total }, { historyType }) => {
      setCurrentIndex((prev) => (historyType ? prev : 0))
      setData((prev) => ({
        results: historyType ? [...(prev?.results ?? []), ...results] : results,
        total: historyType ? prev?.total ?? 0 : total
      }))
    }
  })

  const scrollToMessage = (messageId: string) =>
    dispatch(
      setChatHistoryEventAction({
        type: EChatHistoryEvents.JUMP,
        payload: { eventTs: Date.now(), messageId }
      })
    )

  const handleClose = () => {
    hideChatRoomSearch()
  }

  const handleChange = (value: string) => {
    setSearchQuery(value)
    setCurrentIndex(0)

    if (!value) {
      setData(null)
    }

    if (chatId && value) {
      getMessages({ chatId, searchQuery: value.trim() })
    }
  }

  const handleClear = () => {
    setData(null)
    setCurrentIndex(0)
  }

  const handleLoadMore = () => {
    const lastMessageIndex = Number(data?.results?.length) - 1
    const lastMessageId = data?.results?.[lastMessageIndex]?.id

    if (chatId && lastMessageId && searchQuery) {
      getMessages({
        chatId,
        searchQuery: searchQuery.trim(),
        historyType: EHistoryType.BEFORE,
        messageId: lastMessageId
      })
    }
  }

  const handleNext = () => {
    setCurrentIndex((index) => {
      const nextIndex = Number(index) + 1
      const nextMessageId = data?.results?.[nextIndex]?.id

      if (nextIndex === Number(data?.results?.length) - 1 && nextIndex < (data?.total ?? 0) - 1) {
        handleLoadMore()
      }

      nextMessageId && scrollToMessage(nextMessageId)
      hideChatRoomSearchResults()

      return nextIndex
    })
  }

  const handlePrev = () => {
    setCurrentIndex((index) => {
      const prevIndex = Number(index) - 1
      const prevMessageId = data?.results?.[prevIndex]?.id

      prevMessageId && scrollToMessage(prevMessageId)
      hideChatRoomSearchResults()

      return prevIndex
    })
  }

  const handleResultsItemClick: TChatRoomSearchResultsProps['onClick'] = (messageId, index) => {
    scrollToMessage(messageId)
    setCurrentIndex(index)
    hideChatRoomSearchResults()
  }

  const isResultsVisible = showChatRoomSearchResults && !!data?.results?.length && !!data?.total

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.actions}>
          <IconButton
            iconSize={EIconSize.MD}
            disabled={!currentIndex}
            iconComponent={<ChevronUpIcon />}
            classes={{ root: styles.action }}
            onClick={handlePrev}
          />
          <IconButton
            iconSize={EIconSize.MD}
            disabled={!data?.total || currentIndex === data?.total - 1}
            iconComponent={<ChevronDownIcon />}
            classes={{ root: styles.action }}
            onClick={handleNext}
          />
        </div>
        <div className={styles.input}>
          <Search
            autoFocus={true}
            loading={isLoading}
            searchSize={EInputSize.S}
            classes={{ clearIcon: styles.searchClearIcon, suffix: styles.searchSuffix }}
            onChange={handleChange}
            onClear={handleClear}
            onFocus={displayChatRoomSearchResults}
          />
          <IconButton
            iconSize={EIconSize.MD}
            iconComponent={<CloseCircleOutlinedIcon />}
            onClick={handleClose}
          />
        </div>
      </div>
      {isResultsVisible && (
        <ChatRoomSearchResults
          data={data?.results}
          total={data?.total}
          currentIndex={currentIndex}
          chatType={chatType}
          onLoadMore={handleLoadMore}
          onClick={handleResultsItemClick}
        />
      )}
    </div>
  )
}
