import { useCallback, useEffect } from 'react'
import { AccountTypeNames, ErrorCodesEnum } from '@medentee/enums'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isAfter, isBefore } from 'date-fns'

import { useMutation, useQuery } from 'services/query'
import { getEvent, joinEvent } from 'api/events'
import { showModalAction, useAppDispatch, useAppSelector } from 'store'
import { getDataByInvitationToken } from 'api/profile'
import { isMatchErrorCode } from 'utils'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { useAdaptiveLayout } from 'App/hooks'

type TParams = {
  id?: string
  token?: string
}

const EVENTS_PATH = '/events'
const EVENT_JOIN_PATH = '/events/t/info/:token'

export const useEventsInfoRequests = (id?: string) => {
  const dispatch = useAppDispatch()
  const { isDesktop } = useAdaptiveLayout()

  const { token = '', ...params } = useParams<TParams>()

  const { push, replace } = useHistory()

  const match = useRouteMatch(EVENTS_PATH)
  const joinMatch = useRouteMatch(EVENT_JOIN_PATH)

  const { t } = useTranslation()

  const isProfessionalAccount = useAppSelector(
    (state) => state.global.accountData?.type.name === AccountTypeNames.PROFESSIONAL
  )
  const isFirstLogin = useAppSelector((state) => !!state.global.accountData?.isFirstLogin)

  const urlEventId = id ?? params.id

  const onRequestError = () => {
    toast.error(
      t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
      toastDefaultOptions
    )
    replace(EVENTS_PATH)
  }

  const { data, isFetching } = useQuery({
    queryKey: ['get-event-info', id],
    queryFn: () => (urlEventId ? getEvent(urlEventId) : undefined),
    enabled: Boolean(urlEventId),
    onError: onRequestError
  })

  const invitationResponse = useQuery({
    queryKey: ['get-data-by-token'],
    queryFn: () => getDataByInvitationToken(token),
    cacheTime: 0,
    enabled: Boolean(token),
    onError: (err) => {
      if (isMatchErrorCode(err, ErrorCodesEnum.INVITATION_NOT_EXIST)) {
        toast.error(t('serverError.INVITATION_NOT_EXIST', { ns: 'errors' }), toastDefaultOptions)
      }

      onRequestError()
    }
  })
  const startOn = invitationResponse.data?.event?.startOn
  const endOn = invitationResponse.data?.event?.endOn
  const isEventMember = invitationResponse.data?.isEventMember || data?.isEventMember

  const shouldShowJoin =
    Boolean(invitationResponse.data?.event?.id) && isProfessionalAccount && !isEventMember

  const shouldShowSwitchModal =
    invitationResponse.data?.event?.id && token && !isProfessionalAccount

  const shouldShowGoToChannels =
    !shouldShowJoin && !shouldShowSwitchModal && (Boolean(joinMatch?.isExact) || !isDesktop)

  const shouldRedirectToChannels = Boolean(
    token && isFirstLogin && invitationResponse.data?.isEventMember && invitationResponse.isFetched
  )

  const eventId = urlEventId || invitationResponse.data?.event?.id

  const { mutate, isLoading } = useMutation({
    mutationKey: ['join-to-event'],
    mutationFn: joinEvent,
    onSuccess: () => {
      const isEventInProgress =
        startOn && endOn
          ? isAfter(new Date(), new Date(startOn)) && isBefore(new Date(), new Date(endOn))
          : false

      toast.success(t('common.toast.joinedSuccessfully'), toastDefaultOptions)
      if (isEventInProgress) {
        replace(`${EVENTS_PATH}/${eventId}/highlights/info`)
      } else {
        replace(`${EVENTS_PATH}/${eventId}/highlights/profile`)
      }
    }
  })

  const handleGoToChannels = useCallback(() => {
    if (!match?.path) {
      return
    }

    push(`${EVENTS_PATH}/${eventId}`)
  }, [push, eventId, match])

  const handleJoinEvent = useCallback(() => mutate(token), [mutate, token])

  useEffect(() => {
    if (shouldShowSwitchModal) {
      dispatch(
        showModalAction({
          modalType: EModalComponents.SWITCH_ACCOUNT_DIALOG,
          modalTitle: t('modal.switchAccountConfirm.title'),
          modalProps: {
            onSuccess: () => {
              window.location.href = `${window.location.origin}${EVENTS_PATH}/t/info/${token}`
            }
          }
        })
      )
    }
  }, [token, dispatch, shouldShowSwitchModal, t])

  useEffect(() => {
    if (shouldRedirectToChannels) {
      replace(`${EVENTS_PATH}/${eventId}/highlights/info`)
    }
  }, [eventId, replace, shouldRedirectToChannels])

  return {
    token,
    handleGoToChannels,
    handleJoinEvent,
    isLoading,
    data: data ?? invitationResponse.data?.event,
    eventId,
    shouldShowJoin,
    shouldShowGoToChannels,
    shouldRedirectToChannels,
    isFetching: isFetching || invitationResponse.isFetching
  }
}
