import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ContactInviteInteractionEnum, ErrorCodesEnum } from '@medentee/enums'

import { toast } from 'App/components/ToastContainer'
import { API, api, APIResultsResponse } from 'services/api'
import { State } from 'redux/rootReducer'
import { QueryBuilder, ContactsQueryBuilder, isMatchErrorCode } from 'utils'
import { EInvitationAction, EInvitationType, toastDefaultOptions } from 'globalConstants'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { handleError } from 'api/utils'

import { hideModalAction } from '../modal'
import i18n from '../../i18n'

import {
  ACCEPT_CONTACT_INVITE_REQUEST,
  acceptContactInviteError,
  acceptContactInviteSuccess,
  GET_CONTACTS_REQUEST,
  getContactsError,
  getContactsRequest,
  getContactsSuccess,
  REMOVE_CONTACT_INVITE_REQUEST,
  removeContactInviteError,
  removeContactInviteSuccess,
  setLoadingContactsFilter,
  TActionContactRequest,
  TGetContactsRequest,
  TRemoveSentContactsRequest,
  SET_CASES_AUTO_APPROVE_REQUEST,
  setCasesAutoApproveError,
  setCasesAutoApproveSuccess
} from './contacts.actions'
import {
  TContactsData,
  TContactsFilters,
  TLastRequest,
  TSetCasesAutoApproveRequest
} from './contacts.types'
import { formatContactsData } from './utils'
import { contactsNormalize } from './contacts.normalization'

function* getContactsSaga({ payload }: TGetContactsRequest) {
  try {
    const accountId: string = yield select((state: State) => state.global.accountData?.id)
    const filters: TContactsFilters = yield select((state: State) => state.contacts.filters)

    const {
      type,
      sortBy,
      status,
      withoutHidden,
      limit = PAGINATION_DEFAULT_SHOW_BY,
      page = filters.page,
      selectedAccountTypes = filters.selectedAccountTypes
    } = payload ?? {}

    const { search, searchBy, selectedCategories, notAssigned, online } = filters

    yield put(setLoadingContactsFilter({ loading: true }))

    const categoryIds = selectedCategories.map(({ id }) => id)

    if (notAssigned) {
      categoryIds.push('UNCATEGORY')
    }

    const url = new ContactsQueryBuilder(API.CONTACTS)
      .page(page)
      .status(status)
      .showBy(limit)
      .type(type)
      .sortBy(sortBy)
      .withoutHidden(!!withoutHidden)
      .accountType(selectedAccountTypes)
      .search(search, searchBy)
      .isOnline(online.length > 0)
      .categoryIds(categoryIds)
      .build()

    const { data }: APIResultsResponse<TContactsData[]> = yield call(api.get, url)

    if (type !== EInvitationType.RECEIVED) {
      data.results = formatContactsData(data.results, accountId)
    }

    const { ids, list } = contactsNormalize(data.results)

    yield put(setLoadingContactsFilter({ loading: false }))
    yield put(
      getContactsSuccess({
        ids,
        list,
        total: data.total,
        type,
        status,
        sortBy,
        selectedAccountTypes
      })
    )
  } catch (e) {
    yield put(getContactsError(e))
    yield put(setLoadingContactsFilter({ loading: false }))
    handleError(e)
  }
}

function* removeContactSuccess(processingId: string) {
  const lastRequest: TLastRequest = yield select((state: State) => state.contacts.lastRequest)

  yield put(hideModalAction())
  yield put(removeContactInviteSuccess({ processingId }))
  yield put(
    getContactsRequest({
      ...lastRequest
    })
  )
}

function* removeContactSaga({
  payload: { contactId, onlyHidden = true, processingId, type, onSuccess }
}: TRemoveSentContactsRequest) {
  try {
    yield call(api.delete, API.REMOVE_CONTACT(contactId), {
      data: {
        onlyHidden
      }
    })

    if (onSuccess) {
      onSuccess()
    }
    yield removeContactSuccess(processingId)

    if (type === EInvitationAction.REJECT) {
      yield toast.success(i18n.t('common.toast.invitationRejected'), toastDefaultOptions)
    }

    if (type === EInvitationAction.WITHDRAW) {
      yield toast.success(i18n.t('common.toast.invitationWithdrawn'), toastDefaultOptions)
    }
  } catch (e) {
    if (isMatchErrorCode(e, ErrorCodesEnum.NO_CONTACT_EXIST)) {
      yield removeContactSuccess(processingId)

      return
    }

    if (isMatchErrorCode(e, ErrorCodesEnum.CONTACT_HAVE_RELATED_ACCOUNTS)) {
      yield put(hideModalAction())
      yield put(removeContactInviteError({ processingId }))
      handleError(e)

      return
    }

    yield put(removeContactInviteError({ ...e, processingId }))
  }
}

function* acceptContactInviteSaga({
  payload: { email, accountId, processingId, onSuccess }
}: TActionContactRequest) {
  const lastRequest: TLastRequest = yield select((state: State) => state.contacts.lastRequest)

  try {
    const url = new QueryBuilder(API.CONTACTS)
      .custom('interactionParam', ContactInviteInteractionEnum.APPROVE)
      .build()

    yield call(api.post, url, {
      email,
      accountId
    })

    if (onSuccess) {
      onSuccess()
    }
    yield toast.success(i18n.t('common.toast.youAreConnected'), toastDefaultOptions)
    yield put(acceptContactInviteSuccess({ processingId }))
  } catch (e) {
    handleError(e)
    yield put(acceptContactInviteError({ ...e, processingId }))
  } finally {
    yield put(
      getContactsRequest({
        ...lastRequest
      })
    )
  }
}

function* setCasesAutoApproveSaga({
  payload: { id, currentCaseAutoApproveStatus }
}: TSetCasesAutoApproveRequest) {
  try {
    yield call(api.patch, API.CONTACTS_SETTINGS(id))

    yield put(
      setCasesAutoApproveSuccess({
        id,
        currentCaseAutoApproveStatus
      })
    )
  } catch (e) {
    yield put(setCasesAutoApproveError(e))
    handleError(e)
  }
}

export function* contactsSaga() {
  yield takeLatest(REMOVE_CONTACT_INVITE_REQUEST, removeContactSaga)
  yield takeLatest(GET_CONTACTS_REQUEST, getContactsSaga)
  yield takeLatest(ACCEPT_CONTACT_INVITE_REQUEST, acceptContactInviteSaga)
  yield takeLatest(SET_CASES_AUTO_APPROVE_REQUEST, setCasesAutoApproveSaga)
}
