import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { isBusinessAccountSelector, accountIdSelector, useAppSelector } from 'store'
import {
  Scrollable,
  EmptyList,
  Pagination,
  SearchWrapper,
  Search,
  Button
} from 'App/components/common'
import { ReactComponent as AccountGroupIcon } from 'assets/icons/AccountGroup.svg'
import { ReactComponent as AccountPlusIcon } from 'assets/icons/AccountPlus.svg'
import { generateRandomArray } from 'utils/array'
import { useAdaptiveLayout } from 'App/hooks'
import { EInputSize } from 'enums'
import { useCommunityInviteLink } from 'App/containers/Communities/CommunityInviteLinkPopover/useCommunityInviteLink'
import { UnityHubBackButton } from 'App/components/UnityHub/UnityHubBackButton'
import { UnityHubNetworkingItemSkeleton } from 'App/components/UnityHub/UnityHubNetworkingItem/UnityHubNetworkingItemSkeleton'

import { getCommunityMembers } from '../../../../api/communities'
import { PageWrapperContext } from '../../PageWrapper/PageWrapperContext'
import { CommunityMembersItem } from '../CommunityMembersItem'

import {
  TCommunityMembersSelectedFilters,
  useCommunityMembersFilters
} from './useCommunityMembersFilters'
import styles from './CommunityMembers.module.scss'

type TCommunityMembersProps = {
  path: string
}

const FAKE_ITEMS = generateRandomArray(5)
export const COMMUNITY_MEMBERS_QUERY_KEY = 'community_members'

export const CommunityMembers = ({ path }: TCommunityMembersProps) => {
  const { isDesktop } = useAdaptiveLayout()

  const { filters } = useCommunityMembersFilters()

  const { t } = useTranslation()

  const {
    selectedFilters,
    hasAppliedFilter,
    search,
    reset,
    page,
    selectedFiltersComponent,
    filtersComponent,
    setPage,
    setSearch
  } = useContext(PageWrapperContext)

  const { locations, professions, general } =
    (selectedFilters as TCommunityMembersSelectedFilters) ?? {}

  const communityId = useAppSelector((state) => state.communities.currentCommunityId)
  const accountId = useAppSelector(accountIdSelector)
  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)

  const { CreateCommunityInviteLink, toggleInviteLinkPopover } = useCommunityInviteLink({
    communityId
  })

  const { data, isLoading } = useQuery({
    queryKey: [COMMUNITY_MEMBERS_QUERY_KEY, communityId, page, search, selectedFilters],
    queryFn: () =>
      communityId
        ? getCommunityMembers({
            page,
            search,
            communityId,
            countryCodes: locations?.map(({ code }) => code),
            professionIds: professions,
            online: Boolean(general && general.includes('online')) ? 'ONLINE' : undefined,
            onlyContacts: Boolean(general && general.includes('onlyContacts'))
          })
        : null
  })

  useEffect(() => {
    reset({
      filters
    })
  }, [filters, reset])

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className={styles.list}>
          {FAKE_ITEMS.map((_, index) => (
            <UnityHubNetworkingItemSkeleton key={index} loading={isLoading} />
          ))}
        </div>
      )
    }

    if (!data?.results.length) {
      return (
        <EmptyList
          text={t('communities.members.placeholder')}
          hasAppliedFilters={hasAppliedFilter}
          hasAppliedSearch={!!search}
          icon={<AccountGroupIcon />}
        />
      )
    }

    return (
      <div className={styles.list}>
        {communityId &&
          data?.results.map(({ account, contact, chatId }) => (
            <CommunityMembersItem
              key={account.id}
              account={account}
              contact={contact}
              chatId={chatId}
              currentAccountId={accountId}
              communityId={communityId}
              isBusinessAccount={isBusinessAccount}
            />
          ))}
      </div>
    )
  }, [
    isLoading,
    data?.results,
    communityId,
    hasAppliedFilter,
    search,
    accountId,
    isBusinessAccount,
    t
  ])

  return (
    <div className={styles.root}>
      {!isDesktop && <UnityHubBackButton path={path} className={styles.backButton} />}

      <div className={styles.wrapper}>
        <SearchWrapper filters={filtersComponent} hiddenFilter={isDesktop}>
          <Search
            allowClear={true}
            searchSize={EInputSize.L}
            placeholder={t('communities.members.searchPlaceholder')}
            onChange={setSearch}
          />
        </SearchWrapper>

        {selectedFiltersComponent}

        <Scrollable page={page}>
          {isBusinessAccount && (
            <Button
              variant="inline"
              fontWeight="bold"
              className={styles.inviteButton}
              icon={<AccountPlusIcon />}
              onClick={toggleInviteLinkPopover}
            >
              {CreateCommunityInviteLink}
            </Button>
          )}

          {content}

          <Pagination
            current={page}
            total={data?.total}
            onChange={setPage}
            wrapperClassName={styles.pagination}
          />
        </Scrollable>
      </div>
    </div>
  )
}
