import useLocalStorageState from 'use-local-storage-state'

export type TFilesViewVariant = 'grid' | 'list'
export type TFilesViewKey =
  | 'med-cloud-my-files'
  | 'med-cloud-shared-with-me'
  | 'med-cloud-trash-bin'
  | 'case-cloud'

export const useFilesView = (key: TFilesViewKey, defaultValue: TFilesViewVariant = 'list') => {
  const state = useLocalStorageState<TFilesViewVariant>(key, { defaultValue })

  return state
}
