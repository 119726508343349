import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { formatLongDate } from 'utils'
import { State } from 'redux/rootReducer'
import { createLoadingSelector, createProcessingSelector } from 'store/loading'
import {
  GET_USER_PROFILE_BASIC_INFORMATION,
  EDIT_USER_PROFILE_BASIC_INFORMATION
} from 'store/userProfile'
import { TFormCommonMethods, UserProfileBasicInformationPrivateForm } from 'App/components'
import { getCitiesRequest, GET_CITIES, GET_COUNTRIES, TCity, TCountry } from 'store/misc'

type TUserProfileBasicInformationPrivateFormContainerStateProps = ReturnType<typeof mapStateToProps>

export type TUserProfileBasicInformationFormValues = {
  firstName: string
  lastName: string
  country: TCountry
  city: TCity
  about: string
  headline: string

  phone?: string
}

export type UserProfileBasicInformationPrivateFormContainerOwnProps = {
  onSubmit: (values: TUserProfileBasicInformationFormValues) => void
}

export type TUserProfileBasicInformationPrivateFormContainerProps = TFormCommonMethods &
  UserProfileBasicInformationPrivateFormContainerOwnProps &
  TUserProfileBasicInformationPrivateFormContainerStateProps &
  ReturnType<typeof mapDispatchToProps>

const processingEditSelector = createProcessingSelector([EDIT_USER_PROFILE_BASIC_INFORMATION])

const loadingSelector = createLoadingSelector([
  GET_USER_PROFILE_BASIC_INFORMATION,
  GET_COUNTRIES,
  GET_CITIES
])

const mapStateToProps = (state: State) => {
  const { firstName, lastName, email, country, city, about, headline, sex, dateOfBirth } =
    state.userProfile.basicInformation

  return {
    processing: processingEditSelector(state),
    countries: state.misc.countries,
    cities: state.misc.cities,
    userId: state.global.accountData?.id || '',
    email,
    firstName,
    lastName,
    about,
    headline,
    country,
    city,
    sex: sex ? { value: sex, label: sex } : null,
    dateOfBirth: dateOfBirth
      ? formatLongDate(`${dateOfBirth.day}/${dateOfBirth.month}/${dateOfBirth.year}`)
      : null,
    loading: loadingSelector(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCities: getCitiesRequest
    },
    dispatch
  )

export const UserProfileBasicInformationPrivateFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileBasicInformationPrivateForm)
