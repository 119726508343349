import { ContactStatusEnum, BusinessAccountRoleIdEnum } from '@medentee/enums'

import { ContactsQueryBuilder } from '../utils'
import { api, API, APIData } from '../services/api'
import { TGetBusinessUsersResponse } from '../store'
import { EContactsSort } from '../enums'

export type TGetStaffsPayload = Omit<TGetBusinessUsersPayload, 'roleIds'>

export const getStaffs = async (payload: TGetStaffsPayload = {}) => {
  const { sortBy = EContactsSort.ROLES, statuses = [ContactStatusEnum.APPROVED], ...rest } = payload

  return getBusinessUsers({
    ...rest,
    sortBy,
    statuses,
    excludePendingRoleIds: [BusinessAccountRoleIdEnum.ADMIN, BusinessAccountRoleIdEnum.SUPERADMIN],
    roleIds: [BusinessAccountRoleIdEnum.STAFF]
  })
}

export type TGetBusinessUsersPayload = {
  search?: string
  roleIds?: BusinessAccountRoleIdEnum[]
  sortBy?: EContactsSort
  withoutHidden?: boolean
  statuses?: ContactStatusEnum[]
  excludePendingRoleIds?: BusinessAccountRoleIdEnum[]
}

export const getBusinessUsers = async (payload: TGetBusinessUsersPayload = {}) => {
  const {
    search,
    withoutHidden,
    sortBy = EContactsSort.ROLES,
    statuses,
    roleIds,
    excludePendingRoleIds
  } = payload
  const url = new ContactsQueryBuilder(API.BUSINESS_USERS)
    .multiSearch('roleIds', roleIds)
    .searchQuery(search)
    .sortBy(sortBy)
    .multiSearch('statuses', statuses)
    .multiSearch('excludePendingRoleIds', excludePendingRoleIds)
    .withoutHidden(!!withoutHidden)
    .build()
  const { data }: APIData<TGetBusinessUsersResponse> = await api.get(url)

  return data
}
