import isMatch from 'lodash/isMatch'

export type TAction = {
  type: string
  payload: object | null
}

let WS_ACTIONS_TO_SKIP: TAction[] = []

// action - redux action
export const skipWSAction = (action: TAction) => WS_ACTIONS_TO_SKIP.push(action)

export const shouldSkipAction = (action: TAction) => {
  let shouldSkip = false
  WS_ACTIONS_TO_SKIP = WS_ACTIONS_TO_SKIP.filter((item) => {
    const matched = isMatch(action, item)

    if (matched) {
      shouldSkip = true
    }

    return !matched
  })

  return shouldSkip
}
