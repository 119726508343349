export const GET_NOTIFICATIONS_GENERAL = 'GET_NOTIFICATIONS_GENERAL'
export const GET_NOTIFICATIONS_GENERAL_REQUEST = 'GET_NOTIFICATIONS_GENERAL_REQUEST'
export const GET_NOTIFICATIONS_GENERAL_SUCCESS = 'GET_NOTIFICATIONS_GENERAL_SUCCESS'
export const GET_NOTIFICATIONS_GENERAL_ERROR = 'GET_NOTIFICATIONS_GENERAL_ERROR'

export const READ_NOTIFICATION_FROM_ID_REQUEST = 'READ_NOTIFICATION_FROM_ID_REQUEST'
export const READ_NOTIFICATION_FROM_ID_SUCCESS = 'READ_NOTIFICATION_FROM_ID_SUCCESS'
export const READ_NOTIFICATION_FROM_ID_ERROR = 'READ_NOTIFICATION_FROM_ID_ERROR'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'

export const MESSAGE_PUSH_UP = 'MESSAGE_PUSH_UP'

export const GET_NOTIFICATIONS_PROTOCOL_USERS = 'GET_NOTIFICATIONS_PROTOCOL_USERS'
export const GET_NOTIFICATIONS_PROTOCOL_USERS_REQUEST = 'GET_NOTIFICATIONS_PROTOCOL_USERS_REQUEST'
export const GET_NOTIFICATIONS_PROTOCOL_USERS_SUCCESS = 'GET_NOTIFICATIONS_PROTOCOL_USERS_SUCCESS'
export const GET_NOTIFICATIONS_PROTOCOL_USERS_ERROR = 'GET_NOTIFICATIONS_PROTOCOL_USERS_ERROR'

export const GET_NOTIFICATIONS_PROTOCOL = 'GET_NOTIFICATIONS_PROTOCOL'
export const GET_NOTIFICATIONS_PROTOCOL_REQUEST = 'GET_NOTIFICATIONS_PROTOCOL_REQUEST'
export const GET_NOTIFICATIONS_PROTOCOL_SUCCESS = 'GET_NOTIFICATIONS_PROTOCOL_SUCCESS'
export const GET_NOTIFICATIONS_PROTOCOL_ERROR = 'GET_NOTIFICATIONS_PROTOCOL_ERROR'

export const CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT = 'CLEAR_NOTIFICATIONS_PROTOCOL_USER_COUNT'

export const REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE = 'REFRESH_NOTIFICATION_COUNTERS_SUBSCRIBE'
export const REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE = 'REFRESH_NOTIFICATION_COUNTERS_UNSUBSCRIBE'
export const REFRESH_NOTIFICATION_COUNTERS_REQUEST = 'REFRESH_NOTIFICATION_COUNTERS_REQUEST'
export const REFRESH_NOTIFICATION_COUNTERS_SUCCESS = 'REFRESH_NOTIFICATION_COUNTERS_SUCCESS'
export const REFRESH_NOTIFICATION_COUNTERS_ERROR = 'REFRESH_NOTIFICATION_COUNTERS_ERROR'

export const RECEIVE_NEW_MESSAGE_NOTIFICATIONS_REQUEST = 'RECEIVE_NEW_MESSAGE_NOTIFICATIONS_REQUEST'
export const RECEIVE_NEW_MESSAGE_NOTIFICATIONS_SUCCESS = 'RECEIVE_NEW_MESSAGE_NOTIFICATIONS_SUCCESS'

export const REMOVED_CONTACT_RECEIVE = 'REMOVED_CONTACT_RECEIVE'

export const RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS = 'RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS'
export const RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS_SUCCESS =
  'RECEIVE_NEW_ORGANIZATION_NOTIFICATIONS_SUCCESS'

export const RECEIVE_NEW_COMMUNITY_NOTIFICATIONS = 'RECEIVE_NEW_COMMUNITY_NOTIFICATIONS'
export const RECEIVE_NEW_COMMUNITY_NOTIFICATIONS_SUCCESS =
  'RECEIVE_NEW_COMMUNITY_NOTIFICATIONS_SUCCESS'

export const RECEIVE_NEW_EVENT_NOTIFICATIONS = 'RECEIVE_NEW_EVENT_NOTIFICATIONS'
export const RECEIVE_NEW_EVENT_NOTIFICATIONS_SUCCESS = 'RECEIVE_NEW_EVENT_NOTIFICATIONS_SUCCESS'
