import { useCallback, useMemo, useState } from 'react'
import uniq from 'lodash/uniq'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getDepartmentsGroupedMembers } from 'api/organizations'
import { IDepartmentGroupedMembers } from 'interfaces'
import { TIds, TOrganizationMember } from 'store'
import { queryClient } from 'queryClient'

import { useRefValue } from '../../hooks'

export type TUseOrganizationGroupedMember = Pick<
  PartialBy<TOrganizationMember, 'contact'>,
  'account' | 'contact' | 'chatId' | 'userId' | 'online' | 'role'
> & {
  fromPage?: number
}

export type TUseOrganizationGroupedMembers = {
  membersQueryKeyPrefix: string
  organizationId: string
  channelId?: string
}

type TMembersList = Record<string, TUseOrganizationGroupedMember>

type TList = Record<
  string,
  Pick<
    IDepartmentGroupedMembers,
    | 'departmentId'
    | 'departmentLeaderId'
    | 'departmentMembersCount'
    | 'departmentName'
    | 'departmentDescription'
    | 'organizationDepartmentsCount'
    | 'organizationMembersCount'
  > & {
    members?: {
      ids: TIds
      list: TMembersList
    }
  }
>

type TNormalized = {
  ids: string[]
  list: TList
}

const normalize = (arr: IDepartmentGroupedMembers[], page: number) => {
  const ids: TIds = []
  const list: TList = {}

  arr.forEach((item) => {
    if (!list[item.departmentId]) {
      list[item.departmentId] = {
        departmentId: item.departmentId,
        departmentLeaderId: item.departmentLeaderId,
        departmentMembersCount: item.departmentMembersCount,
        departmentName: item.departmentName,
        departmentDescription: item.departmentDescription,
        organizationDepartmentsCount: item.organizationDepartmentsCount,
        organizationMembersCount: item.organizationMembersCount
      }
    }

    if (!ids.includes(item.departmentId)) {
      ids.push(item.departmentId)
    }
  })

  ids.forEach((id) => {
    const membersIds: TIds = []
    const membersList: TMembersList = {}

    arr
      .filter((item) => item.departmentId === id)
      .forEach(({ account, role, contact, online, userId, chatId }) => {
        membersIds.push(account.id)
        membersList[account.id] = {
          fromPage: page,
          account,
          contact,
          chatId,
          userId,
          online,
          role
        }
      })

    list[id] = {
      ...list[id],
      members: {
        ids: membersIds,
        list: membersList
      }
    }
  })

  return { ids, list }
}

const combine =
  (next: TNormalized) =>
  (prev: TNormalized): TNormalized => {
    const ids = [...prev.ids, ...next.ids]
    const list: TList = {}

    ids.forEach((id) => {
      const prevItem = prev.list[id] ?? {}
      const nextItem = next.list[id] ?? {}

      list[id] = {
        ...prevItem,
        ...nextItem,
        members: {
          ids: uniq([...(prevItem?.members?.ids ?? []), ...(nextItem?.members?.ids ?? [])]),
          list: {
            ...prevItem?.members?.list,
            ...nextItem?.members?.list
          }
        }
      }
    })

    return {
      list,
      ids: uniq(ids)
    }
  }

const initialData = { ids: [], list: {} }

export const useOrganizationGroupedMembers = ({
  membersQueryKeyPrefix,
  organizationId,
  channelId
}: TUseOrganizationGroupedMembers) => {
  const { t } = useTranslation()

  const searchOptions = [
    {
      value: 'NAME',
      label: t('organizations.infoDrawer.staffList.searchOptions.staff')
    },
    {
      value: 'DEPARTMENT',
      label: t('organizations.infoDrawer.staffList.searchOptions.department')
    }
  ]

  const [page, setPage] = useState(0)
  const [data, setData] = useState<TNormalized>(initialData)
  const [total, setTotal] = useState(0)
  const [totalUniq, setTotalUniq] = useState(0)

  const [searchBy, setSearchBy] = useState<string>(searchOptions[0].value)
  const [search, setSearch] = useState<string>('')

  const { getValue: getSearch } = useRefValue(search)
  const { getValue: getSearchBy } = useRefValue(searchBy)

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: [membersQueryKeyPrefix, organizationId, page, searchBy, search],
    queryFn: () =>
      getDepartmentsGroupedMembers({
        page,
        channelId,
        organizationId,
        searchBy,
        search
      }),
    enabled: !!organizationId,
    onSuccess: (result) => {
      setTotal(result.total)
      setTotalUniq(result.totalUniq)
      setData(combine(normalize(result.results, page)))
    }
  })

  const handleRefreshMemberList = useCallback(
    async (fromPage: number) => {
      if (!organizationId) {
        return
      }

      const response = await queryClient.fetchQuery<
        ReturnType<typeof getDepartmentsGroupedMembers>
      >({
        queryKey: [membersQueryKeyPrefix, organizationId, channelId, fromPage],
        queryFn: () =>
          getDepartmentsGroupedMembers({
            page: fromPage,
            channelId,
            organizationId,
            searchBy: getSearchBy(),
            search: getSearch()
          })
      })

      if (response) {
        setData(combine(normalize(response.results, fromPage)))
      }
    },
    [membersQueryKeyPrefix, organizationId, channelId, getSearchBy, getSearch]
  )

  const loading = Boolean((isLoading || isFetching) && !data.ids.length)
  const processing = Boolean(isFetching && data.ids.length)
  const shouldShowPlaceholder = Boolean(!loading && search && !data.ids.length)

  const dataLength = useMemo(
    () =>
      data.ids.reduce((acc, id) => {
        const length = data.list[id]?.members?.ids.length ?? 0

        return acc + length
      }, 0),
    [data.ids, data.list]
  )

  const handleSearchBy = useCallback((_: string, nextSearchBy: (string | number)[]) => {
    setSearchBy(nextSearchBy[0]?.toString())
  }, [])

  return {
    dataLength,
    search,
    searchBy,
    data,
    loading,
    processing,
    shouldShowPlaceholder,
    initialData,
    page,
    total,
    totalUniq,
    searchOptions,
    handleSearchBy,
    refetch,
    setData,
    setPage,
    setSearch,
    handleRefreshMemberList
  }
}
