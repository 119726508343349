import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountsSelect, EAccountsSelectType } from 'App/components/common'
import { useSelectAccounts } from 'App/hooks'
import {
  getBroadcastSelectedAccountsSelector,
  getBroadcastSelectedRecipientGroupsSelector,
  setBroadcastCategories,
  setBroadcastDepartments,
  setBroadcastOrganizations,
  setBroadcastRecipients,
  useAppDispatch,
  useAppSelector
} from 'store'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'
import { getMapComponent } from 'utils'
import { TAccountsSelectGroupsItem } from 'App/components/common/AccountsSelect/AccountsSelectGroups'

import { SEARCH_CASCADER_MAP, flattenItem, prepareRequestPayload } from './SelectRecipients.utils'
import {
  TUseSelectRecipientsRequestsProps,
  useSelectRecipientsRequests
} from './useSelectRecipientsRequests'

export type TSelectRecipientsProps = Pick<
  TUseSelectRecipientsRequestsProps,
  'recipientsType' | 'accountType'
> & {
  onBack: () => void

  organizationId?: string
}

export const SelectRecipients = ({
  recipientsType,
  organizationId,
  accountType,
  onBack
}: TSelectRecipientsProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const [selectType, setSelectType] = useState<EAccountsSelectType>(EAccountsSelectType.BY_USER)
  const [categories, setCategories] = useState<TAccountsSelectGroupsItem[]>([])
  const [organizations, setOrganizations] = useState<TAccountsSelectGroupsItem[]>([])
  const [selectedGroups, setSelectedGroups] = useState<TAccountsSelectOptionData[]>([])

  const groups = useMemo(() => [...categories, ...organizations], [categories, organizations])

  const itemsMap = useMemo(
    () =>
      new Map(
        groups?.reduce<[string, TAccountsSelectGroupsItem][]>(
          (res, item): [string, TAccountsSelectGroupsItem][] => [...res, ...flattenItem(item)],
          []
        ) ?? []
      ),
    [groups]
  )

  const selectedRecipients = useAppSelector(getBroadcastSelectedAccountsSelector)
  const selectedRecipientGroups = useAppSelector(getBroadcastSelectedRecipientGroupsSelector)

  const formattedRecipientGroups = useMemo<TAccountsSelectOptionData[]>(
    () =>
      selectedRecipientGroups.map(({ selectId, name }) => ({
        id: selectId,
        displayUserName: name
      })),
    [selectedRecipientGroups]
  )

  useEffect(() => {
    setSelectType((prev) => {
      if (!!formattedRecipientGroups?.length && prev !== EAccountsSelectType.BY_GROUP) {
        return EAccountsSelectType.BY_GROUP
      }

      return EAccountsSelectType.BY_USER
    })
  }, [formattedRecipientGroups.length])

  const onSubmit = useCallback(
    (selectedItems: TAccountsSelectOptionData[]) => {
      if (selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'contacts') {
        dispatch(
          setBroadcastCategories({
            categories: selectedItems.map(({ id, displayUserName }) => ({
              id,
              selectId: id,
              name: displayUserName
            }))
          })
        )
      }

      if (selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'staff') {
        dispatch(
          setBroadcastDepartments({
            departments: selectedItems.map(({ id, displayUserName }) => ({
              id,
              selectId: id,
              name: displayUserName
            }))
          })
        )
      }

      if (selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'all') {
        const payload = prepareRequestPayload(selectedGroups)

        dispatch(setBroadcastDepartments({ departments: payload.departments }))
        dispatch(setBroadcastCategories({ categories: payload.categories }))
        dispatch(setBroadcastOrganizations({ organizations: payload.organizations }))
      }

      if (selectType === EAccountsSelectType.BY_USER) {
        dispatch(setBroadcastRecipients({ recipients: selectedItems }))
      }

      onBack()
    },
    [dispatch, onBack, recipientsType, selectType, selectedGroups]
  )

  const {
    state: { page, search, searchBy, selectedAccounts },
    handleSubmit,
    onPageChange,
    onSearch,
    onSearchBy,
    onSelect,
    onSelectAll,
    onUnselectAll,
    onSelectGroup
  } = useSelectAccounts({
    onSubmit,
    accounts: !!selectedRecipients.length ? selectedRecipients : formattedRecipientGroups
  })

  const { contacts, groupsLoading, isContactsLoading } = useSelectRecipientsRequests({
    page,
    recipientsType,
    search,
    searchBy,
    selectedAccounts,
    selectType,
    accountType,
    organizationId,
    setCategories,
    setOrganizations
  })

  const emptyContent = useMemo(() => {
    if (searchBy?.id) {
      return t('modal.broadcastMessage.recipientsStep.placeholderWithFilters', {
        context: recipientsType
      })
    }

    return t('modal.broadcastMessage.recipientsStep.placeholder', { context: recipientsType })
  }, [recipientsType, searchBy?.id, t])

  const groupsTitle =
    recipientsType === 'all'
      ? t('modal.broadcastMessage.recipientsStep.allContactsTitle')
      : undefined

  const hideSearch = selectType === EAccountsSelectType.BY_GROUP && recipientsType === 'all'

  const handleGroupChange = useCallback(
    (selectedIds: string[]) => {
      const selected = selectedIds.map<TAccountsSelectOptionData>((id) => {
        const item = itemsMap.get(id)

        return {
          id: item?.value ?? '',
          displayUserName: item?.label ?? ''
        }
      })

      onSelectGroup(selected)
    },
    [itemsMap, onSelectGroup]
  )

  const handleUnselect = useCallback(
    (id: string) => {
      const nestedItems = itemsMap.get(id)?.items
      const newValue = selectedAccounts?.filter((item) => {
        if (!!nestedItems?.length) {
          return !nestedItems.find((nestedItem) => nestedItem.value === item.id)
        }
        return item.id !== id
      })

      onSelectGroup(newValue || [])
    },
    [itemsMap, onSelectGroup, selectedAccounts]
  )

  const handleTypeChange = useCallback(
    (nextType: SetStateAction<EAccountsSelectType>) => {
      if (selectType && nextType !== selectType) {
        onUnselectAll()
      }

      setSelectType(nextType)
    },
    [onUnselectAll, selectType]
  )

  useEffect(() => {
    const selectedItems: TAccountsSelectOptionData[] = []
    const selectedMap = new Map(selectedAccounts?.map((item) => [item.id, item]) ?? [])

    itemsMap.forEach((itemMap) => {
      const isFullySelected = itemMap.items?.every((item) => selectedMap.has(item.value))

      if (!!itemMap.items?.length && isFullySelected) {
        itemMap.items.forEach((item) => selectedMap.delete(item.value))

        selectedItems.push({
          id: itemMap.value,
          displayUserName: itemMap.label
        })
      }
    })

    selectedMap.forEach((item) => {
      selectedItems.push(item)
    })

    setSelectedGroups(selectedItems)
  }, [itemsMap, selectedAccounts])

  return (
    <AccountsSelect
      selectType={selectType}
      loading={isContactsLoading}
      items={contacts?.results ?? []}
      selectedItems={selectedAccounts}
      selectedGroupItems={selectedGroups}
      search={search}
      CustomSearchComponent={getMapComponent(SEARCH_CASCADER_MAP, recipientsType, {
        organizationId,
        onChange: onSearchBy,
        onSearchChange: onSearch
      })}
      page={page}
      total={contacts?.total}
      emptyContentText={t('modal.broadcastMessage.recipientsStep.selectedListPlaceholder', {
        context: recipientsType
      })}
      emptyListText={emptyContent}
      autoOnline={true}
      submitDisabled={false}
      allowGroupSelect={true}
      hideSearch={hideSearch}
      submitLabel={t('modal.broadcastMessage.recipientsStep.submitButton')}
      groups={groups}
      groupsLoading={groupsLoading}
      groupsPlaceholder={t('modal.broadcastMessage.recipientsStep.placeholderGroups', {
        context: recipientsType
      })}
      groupsSearchPlaceholder={t('modal.broadcastMessage.recipientsStep.searchPlaceholderGroups', {
        context: recipientsType
      })}
      groupsTitle={groupsTitle}
      onGroupsChange={handleGroupChange}
      onSelectAll={onSelectAll}
      onUnselectAll={onUnselectAll}
      onSelect={onSelect}
      onUnselect={handleUnselect}
      onSearch={onSearch}
      onPageChange={onPageChange}
      onSubmit={handleSubmit}
      onClickBack={onBack}
      onTypeChange={handleTypeChange}
    />
  )
}
