import { FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  createProcessingSelector,
  leaveMembersRequest,
  leaveMembersError,
  LEAVE_MEMBER
} from 'store'
import { State } from 'redux/rootReducer'
import { Button, ConfirmationModal } from 'App/components'

import styles from './CaseMemberLeaveConfirm.module.scss'

// ------------------ Types ------------------
export type TCaseMemberLeaveConfirmBaseProps = {
  caseId: string
}

export type TCaseMemberLeaveConfirmProps = TCaseMemberLeaveConfirmBaseProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>
// ------------------  END  ------------------

const CaseMemberLeaveConfirmComponent: FC<TCaseMemberLeaveConfirmProps> = ({
  processing,
  leaveMembers,
  caseId,
  error,
  setLeaveMembersError
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      content={t('cases.modal.leaveCaseConfirm.content')}
      controls={
        <Button
          className={cls({ [styles.rootButton]: true, [styles.rootError]: !!error })}
          loading={processing}
          onClick={() =>
            leaveMembers({
              caseId
            })
          }
        >
          {t('cases.modal.leaveCaseConfirm.submitButton')}
        </Button>
      }
      error={error}
      resetError={() => setLeaveMembersError(null)}
    />
  )
}
const processingSelector = createProcessingSelector([LEAVE_MEMBER])

const mapStateToProps = (state: State) => ({
  processing: processingSelector(state),
  error: state.caseMembers.leave.error
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      leaveMembers: leaveMembersRequest,
      setLeaveMembersError: leaveMembersError
    },
    dispatch
  )

export const CaseMemberLeaveConfirm: FC<TCaseMemberLeaveConfirmBaseProps> = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseMemberLeaveConfirmComponent)
