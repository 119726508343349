import { call, cancel, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { CallTypeEnum, CallStatusEnum } from '@medentee/enums'

import {
  ESocketEmitEventNamesCall,
  ESocketNameSpaces,
  wrapperForSocketEmit
} from 'services/webSocket'
import { State } from 'redux/rootReducer'
import { hideModalAction, TModalState } from 'store/modal'
import { ICallsCreateCallDTO } from 'interfaces/api/calls/callsCreateCallDTO'
import {
  updateCallAcceptedMyself,
  RECEIVE_EXIST_CALLS,
  TAccount,
  TAccountData,
  leaveVoiceRoomRequest
} from 'store'
import { handleError } from 'api/utils'

import {
  TAddUsersToCallRequest,
  TJoinToCallRequest,
  TReceiveAcceptedMyselfCall,
  TReceiveAllowCall,
  TReceiveCreateCall,
  TReceiveEndCall,
  TReceiveExistCallsAction,
  TSendAllowCallRequest,
  TSendCreateCallRequest,
  TSendEndCallRequest
} from './calls.types'
import {
  endCall,
  RECEIVE_CREATE_CALL,
  RECEIVE_END_CALL,
  receiveCreateCallSuccess,
  SEND_ALLOW_CALL_REQUEST,
  SEND_CREATE_CALL_REQUEST,
  SEND_END_CALL_REQUEST,
  ADD_USERS_TO_CALL_REQUEST,
  sendAllowCallError,
  sendAllowCallSuccess,
  sendCreateCallError,
  sendCreateCallSuccess,
  sendEndCallError,
  sendEndCallSuccess,
  updateCreateCall,
  RECEIVE_ALLOW_CALL,
  allowCallAction,
  RECEIVE_CALL_ACCEPTED_MYSELF,
  JOIN_TO_CALL_REQUEST,
  joinToCallSuccess,
  joinToCallError,
  sendCreateCallCancel,
  clearCallAction,
  receiveExistCallsSuccessAction,
  addUsersToCallError,
  addUsersToCallSuccess
} from './calls.actions'

function* sendCreateCallSaga({
  payload: { chatId, video, caseId, userIds }
}: TSendCreateCallRequest) {
  try {
    const { modalType }: TModalState = yield select((state: State) => state.modal)
    const callId: string = yield select((state: State) => state.calls.answer.createCall?.id)

    const { endCallReceived, createCall } = yield race({
      endCallReceived: take(RECEIVE_END_CALL),
      createCall: wrapperForSocketEmit(
        ESocketNameSpaces.CALL,
        ESocketEmitEventNamesCall.CREATE_CALL,
        {
          chatId,
          caseId,
          answererUserIds: userIds
        }
      )
    })

    if (modalType) {
      yield put(hideModalAction())
    }

    if (endCallReceived || createCall.id === callId) {
      yield put(sendCreateCallCancel())
      yield cancel()
    }

    yield put(sendCreateCallSuccess({ chatId, video, createCall }))
  } catch (error) {
    handleError(error)
    yield put(sendCreateCallError(error))
  }
}

function* addUsersToCallSaga({ payload: { callId, userIds } }: TAddUsersToCallRequest) {
  try {
    const createCall: ICallsCreateCallDTO = yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CALL,
      ESocketEmitEventNamesCall.ADD_USERS_TO_CALL,
      {
        callId,
        answererUserIds: userIds
      }
    )
    yield put(hideModalAction())
    yield put(addUsersToCallSuccess({ callId, createCall }))
  } catch (error) {
    handleError(error)
    yield put(addUsersToCallError(error))
  }
}

function* sendAllowCallSaga({ payload: { callId } }: TSendAllowCallRequest) {
  try {
    const callData: ICallsCreateCallDTO = yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CALL,
      ESocketEmitEventNamesCall.ALLOW_CALL,
      { callId }
    )

    yield put(sendAllowCallSuccess(callData))
  } catch (error) {
    handleError(error)
    yield put(sendAllowCallError(error))
  }
}

function* sendEndCallSaga({ payload: { callId } }: TSendEndCallRequest) {
  try {
    yield call(wrapperForSocketEmit, ESocketNameSpaces.CALL, ESocketEmitEventNamesCall.END_CALL, {
      callId
    })

    yield put(sendEndCallSuccess())
  } catch (error) {
    handleError(error)
    yield put(sendEndCallError(error))
  }
}

function* receiveCreateCallSaga({ payload }: TReceiveCreateCall) {
  try {
    const accountId: string = yield select((state: State) => state.global.accountData?.id)

    yield put(
      receiveCreateCallSuccess({
        ...payload,
        isComingCall: accountId !== payload.offererId
      })
    )
  } catch (e) {
    handleError(e)
  }
}

function* receiveEndCallSaga({ payload }: TReceiveEndCall) {
  try {
    const callId: string = yield select((state: State) => state.calls.answer.createCall?.id)

    if (callId && payload.id !== callId) {
      yield cancel()
    }

    const currentAccountId: string = yield select((state: State) => state.global.accountData?.id)

    if (
      payload.answerersMap.find(
        ({ status, accountId }) =>
          (status === CallStatusEnum.MISSED ||
            status === CallStatusEnum.BUSY ||
            status === CallStatusEnum.REJECTED ||
            status === CallStatusEnum.COMPLETED) &&
          accountId === currentAccountId
      )
    ) {
      yield put(endCall(payload))
    }

    if (payload.type === CallTypeEnum.CASE_GROUP && !payload.endTime) {
      yield put(updateCreateCall(payload))
      return
    }

    yield put(endCall(payload))
  } catch (e) {
    yield handleError(e)
  }
}

function* receiveAllowCallSaga({ payload }: TReceiveAllowCall) {
  try {
    const hasActiveCall: string = yield select((state: State) => !!state.calls.answer.createCall)

    if (hasActiveCall) {
      yield put(allowCallAction(payload))
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* joinToCallSaga({ payload: { callId, onSuccess } }: TJoinToCallRequest) {
  try {
    yield put(clearCallAction())

    const activeVoiceRoomId: string | undefined = yield select(
      (state: State) => state.organizations.activeVoiceRoom?.id
    )

    const createCall: ICallsCreateCallDTO = yield call(
      wrapperForSocketEmit,
      ESocketNameSpaces.CALL,
      ESocketEmitEventNamesCall.ALLOW_CALL,
      { callId }
    )

    if (onSuccess) {
      yield call(onSuccess)
    }

    if (activeVoiceRoomId) {
      yield put(leaveVoiceRoomRequest({ voiceRoomId: activeVoiceRoomId }))
    }

    yield put(joinToCallSuccess({ createCall, processingId: callId }))
  } catch (e) {
    yield handleError(e)
    yield put(joinToCallError({ ...e, processingId: callId }))
  }
}

function* receiveAcceptedMyselfCallSaga({ payload }: TReceiveAcceptedMyselfCall) {
  try {
    const {
      id: currentAccountId,
      professionalAccount
    }: Pick<TAccountData, 'id' | 'professionalAccount'> = yield select(
      (state: State) => state.global.accountData
    )

    const privateAccountId = professionalAccount?.id || currentAccountId
    const joinToCallRequestSent: boolean = yield select(
      (state: State) => state.calls.joinToCallRequestSent
    )
    const isNewCallAlreadyActive: boolean = payload.answerersMap.some(
      (item) =>
        currentAccountId === item.accountId &&
        item.privateAccountId === privateAccountId &&
        item.status === CallStatusEnum.ACTIVE
    )

    if (!joinToCallRequestSent && isNewCallAlreadyActive) {
      yield put(updateCallAcceptedMyself())
    }
  } catch (e) {
    yield handleError(e)
  }
}

function* existCallsSaga({ payload }: TReceiveExistCallsAction) {
  const account: TAccount | null = yield select((state: State) => state.global.accountData)

  if (account && payload) {
    yield put(
      receiveExistCallsSuccessAction({
        call: payload,
        account
      })
    )
  }
}

export function* callsSaga() {
  yield takeLatest(SEND_CREATE_CALL_REQUEST, sendCreateCallSaga)
  yield takeLatest(ADD_USERS_TO_CALL_REQUEST, addUsersToCallSaga)
  yield takeLatest(SEND_ALLOW_CALL_REQUEST, sendAllowCallSaga)
  yield takeLatest(SEND_END_CALL_REQUEST, sendEndCallSaga)
  yield takeLatest(RECEIVE_CREATE_CALL, receiveCreateCallSaga)
  yield takeLatest(RECEIVE_END_CALL, receiveEndCallSaga)
  yield takeLatest(RECEIVE_ALLOW_CALL, receiveAllowCallSaga)
  yield takeLatest(RECEIVE_CALL_ACCEPTED_MYSELF, receiveAcceptedMyselfCallSaga)
  yield takeLatest(JOIN_TO_CALL_REQUEST, joinToCallSaga)
  yield takeLatest(RECEIVE_EXIST_CALLS, existCallsSaga)
}
