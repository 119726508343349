import { Link } from 'react-router-dom'

import styles from './NotificationsP2PDetailsLinkToCase.module.scss'

export type TNotificationsP2PDetailsLinkToCaseProps = {
  caseId?: string
  caseTitle?: string
  isCaseOwner?: boolean
}

export const NotificationsP2PDetailsLinkToCase = ({
  caseId,
  caseTitle,
  isCaseOwner = false
}: TNotificationsP2PDetailsLinkToCaseProps) => {
  if (!caseId || !caseTitle) {
    return null
  }

  const path = isCaseOwner ? `/cases/${caseId}/owner` : `/cases/${caseId}/member`

  return <Link to={path} className={styles.root}>{`${caseTitle}`}</Link>
}
