import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useParams } from 'react-router-dom'
import { ProducedNotificationsEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { CaseOwnerOpinionsList } from 'App/components'
import {
  createLoadingSelector,
  getCaseNotificationsSelector,
  getCaseOpinionsByMemberRequest,
  GET_CASE_OPINIONS_BY_MEMBER,
  readNotificationFromIdRequest,
  TCaseParams
} from 'store'

export type TCaseOwnerOpinionsContainerProps = {
  memberId: string
  loading: boolean
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const CaseOwnerOpinionsContainerView = (props: TCaseOwnerOpinionsContainerProps) => {
  const { memberId, getCaseOpinionsByMember } = props
  const { id: caseId } = useParams<TCaseParams>()

  useEffect(() => {
    getCaseOpinionsByMember({ caseId, memberId })
  }, [getCaseOpinionsByMember, caseId, memberId])

  return <CaseOwnerOpinionsList caseId={caseId} {...props} />
}

const loadingSelector = createLoadingSelector([GET_CASE_OPINIONS_BY_MEMBER])

const mapStateToProps = (state: State) => ({
  ids: state.caseDetails.opinions.opinionsIds,
  loading: loadingSelector(state),
  newLineOpinionId: state.caseDetails.opinions.newLineOpinionId,
  notifications: getCaseNotificationsSelector(state, ProducedNotificationsEnum.CASE_OPINION_CREATED)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCaseOpinionsByMember: getCaseOpinionsByMemberRequest,
      readNotificationFromId: readNotificationFromIdRequest
    },
    dispatch
  )

export const CaseOwnerOpinionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseOwnerOpinionsContainerView)
