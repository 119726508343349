import { ReactNode } from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'
import { EIconSize } from 'enums'
import { isBusinessAccountSelector, showModalAction, useAppDispatch, useAppSelector } from 'store'
import { EModalComponents } from 'App/containers/ModalRoot'
import { IconButton } from 'App/components'
import { ReactComponent as CommentPlusIcon } from 'assets/icons/CommentPlus.svg'
import { ReactComponent as MicrophonePlusIcon } from 'assets/icons/MicrophonePlus.svg'
import { useOrganizationSubscription } from 'App/hooks/useOrganizationSubscription'
import { TChannelVariant } from 'interfaces'

type TCreateChannelButtonProps = {
  organizationId: string
  variant: TChannelVariant
}

type TButtonProps = {
  isDepartmentLead: boolean
  isBusinessAccount: boolean
  handleCreateVoiceChannel: () => void
  handleCreateTextChannel: () => void
  t: TFunction
}

const BUTTONS = new Map<TCreateChannelButtonProps['variant'], (props: TButtonProps) => ReactNode>()
  .set(
    'chat',
    ({ isBusinessAccount, isDepartmentLead, handleCreateTextChannel, t }) =>
      (isBusinessAccount || isDepartmentLead) && (
        <IconButton
          iconComponent={<CommentPlusIcon />}
          iconSize={EIconSize.CUSTOM}
          toolTip={t('unityHub.channelsList.createChannelButtonTooltip')}
          onClick={handleCreateTextChannel}
        />
      )
  )
  .set(
    'voice',
    ({ handleCreateVoiceChannel, isBusinessAccount, t }) =>
      isBusinessAccount && (
        <IconButton
          iconComponent={<MicrophonePlusIcon />}
          iconSize={EIconSize.CUSTOM}
          toolTip={t('unityHub.channelsList.createChannelButtonTooltip')}
          onClick={handleCreateVoiceChannel}
        />
      )
  )

export const CreateChannelButton = ({ organizationId, variant }: TCreateChannelButtonProps) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const isBusinessAccount = useAppSelector(isBusinessAccountSelector)
  const isDepartmentLead = useAppSelector(
    (state) => state.organizations.data.list[organizationId]?.isLeaderInDepartments
  )

  const { getOnClickHandler: handleCreateVoiceChannel } = useOrganizationSubscription({
    variant: 'upgrade',
    organizationId,
    callback: () => {
      dispatch(
        showModalAction({
          modalType: EModalComponents.CREATE_VOICE_CHANNEL,
          modalTitle: t('modal.createChannel.create.title_voice'),
          modalProps: { organizationId }
        })
      )
    }
  })

  const { getOnClickHandler: handleCreateTextChannel } = useOrganizationSubscription({
    organizationId,
    callback: () => {
      dispatch(
        showModalAction({
          modalType: EModalComponents.CREATE_TEXT_CHANNEL,
          modalTitle: t('modal.createChannel.create.title_chat'),
          modalProps: { organizationId }
        })
      )
    }
  })

  return (
    <>
      {getMapComponent(BUTTONS, variant, {
        isDepartmentLead,
        isBusinessAccount,
        handleCreateVoiceChannel: handleCreateVoiceChannel(),
        handleCreateTextChannel: handleCreateTextChannel(),
        t
      })}
    </>
  )
}
