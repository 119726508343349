import { SubscriptionIdsEnum } from '@medentee/enums'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { getMapComponent } from 'utils'

type TActionLabelProps = {
  id: SubscriptionIdsEnum
  isRenew: boolean
}

const BUTTON_LABEL = new Map<SubscriptionIdsEnum, (t: TFunction) => string>()
  .set(SubscriptionIdsEnum.BASIC_PROFESSIONAL, (t) =>
    t('subscriptions.availableSubscription.chooseButton')
  )
  .set(SubscriptionIdsEnum.BASIC_BUSINESS, (t) =>
    t('subscriptions.availableSubscription.chooseButton')
  )
  .set(SubscriptionIdsEnum.PROFESSIONAL, (t) =>
    t('subscriptions.availableSubscription.upgradeButton')
  )
  .set(SubscriptionIdsEnum.BUSINESS, (t) => t('subscriptions.availableSubscription.upgradeButton'))

export const ActionLabel = ({ id, isRenew }: TActionLabelProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isRenew
        ? t('subscriptions.availableSubscription.renewButton')
        : getMapComponent(BUTTON_LABEL, id, t)}
    </>
  )
}
