import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getCategories } from 'api/categories'
import { TCascaderOption, TSearchCascaderProps } from 'App/components'
import { stopPropagation } from 'utils'
import { TSearchBy } from 'api/contacts'

export type TUseContactsSearchByOptionsProps = {
  onChange: (searchBy: TSearchBy) => void
}

type TUseContactsSearchByOptions = (props: TUseContactsSearchByOptionsProps) => {
  options: TCascaderOption[]
  onCascaderChange: TSearchCascaderProps['onSearchChange']
}

export const useContactsSearchByOptions: TUseContactsSearchByOptions = ({ onChange }) => {
  const { t } = useTranslation()

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: () => getCategories()
  })

  const options = useMemo<TCascaderOption[]>(
    () => [
      {
        value: 'allContacts',
        label: (
          <span className="cascader-default">
            {t('contacts.contactsSearchByOptions.all_contacts')}
          </span>
        )
      },
      {
        value: 'categories',
        label: t('contacts.contactsSearchByOptions.categories'),
        children: !!categories?.length
          ? categories.map(({ id, name }) => ({
              value: id,
              label: name
            }))
          : [
              {
                value: t('contacts.contactsSearchByOptions.categoriesPlaceholder'),
                disabled: true,
                label: (
                  <span onClick={stopPropagation} className="cascader-placeholder">
                    {t('contacts.contactsSearchByOptions.categoriesPlaceholder')}
                  </span>
                )
              }
            ]
      }
    ],
    [categories, t]
  )

  const handleChange = useCallback(
    (_: string, searchByValue: (string | number)[]) => {
      const searchByLength = searchByValue.length

      const group =
        searchByLength > 1 ? searchByValue[searchByLength - 2] : searchByValue[searchByLength - 1]
      const id = searchByLength > 1 ? searchByValue[searchByLength - 1] : ''

      onChange({
        group: group.toString(),
        id: id.toString()
      })
    },
    [onChange]
  )

  return {
    options,
    onCascaderChange: handleChange
  }
}
