import cls from 'classnames'
import {
  ToastContainer as OriginalToastContainer,
  ToastContainerProps,
  ToastOptions,
  Zoom
} from 'react-toastify'

import { ToastCustomCloseButton } from './ToastCustomCloseButton'
import styles from './ToastContainer.module.scss'

export enum EContainerId {
  'ALERTS' = 'Alerts'
}

export type TToastOptions = {
  containerId: EContainerId
} & ToastOptions

export type TToastContainerProps = {
  containerId?: EContainerId
  className?: string
} & ToastContainerProps

export const ToastContainer = ({
  closeButton,
  containerId = EContainerId.ALERTS,
  className = '',
  position = 'top-right',
  ...rest
}: TToastContainerProps) => (
  <OriginalToastContainer
    {...rest}
    position={position}
    transition={Zoom}
    closeButton={closeButton ? closeButton : <ToastCustomCloseButton />}
    enableMultiContainer={true}
    containerId={containerId}
    className={cls({
      [styles[containerId.toString()]]: true,
      [className]: !!className
    })}
  />
)
