import { useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { validation } from 'utils/validation'
import { Button, ErrorModal, TextField } from 'App/components'
import { RENAME_CASE_TITLE } from 'store/cases'
import { TCaseEditTitleDialogContainerProps } from 'App/containers'

import styles from './CaseEditTitleDialog.module.scss'

const TITLE_MAX_LENGTH = 50

type EditTitleValues = {
  title?: string
}

export const CaseEditTitleDialog = ({
  renameCaseTitle,
  defaultTitle,
  id,
  loadingButton,
  error,
  removeErrorNotification
}: TCaseEditTitleDialogContainerProps) => {
  const { t } = useTranslation()

  useEffect(
    () => () => {
      removeErrorNotification(RENAME_CASE_TITLE)
    },
    [removeErrorNotification]
  )

  const onSubmit = (values: EditTitleValues) => {
    renameCaseTitle({
      title: values.title || '',
      id: id || ''
    })
  }

  return (
    <div className={styles.form}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="title"
              validate={validation.composeValidators(
                validation.required(),
                validation.maxLength(TITLE_MAX_LENGTH)
              )}
              initialValue={defaultTitle}
            >
              {({ input, meta }) => (
                <TextField
                  {...input}
                  autoFocus={true}
                  invalid={meta.touched && meta.invalid}
                  error={meta.error}
                  valueLengthMax={TITLE_MAX_LENGTH}
                  InputLabelProps={{
                    shrink: false
                  }}
                />
              )}
            </Field>
            <ErrorModal error={error} />
            <Button
              type="submit"
              className={styles.button}
              disabled={form.getState().invalid}
              loading={loadingButton}
            >
              {t('cases.modal.renameCase.submitButton')}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}
