import cls from 'classnames'
import { useTranslation } from 'react-i18next'

import { generateRandomArray } from 'utils'
import { CaseItemWrapperContainer, TCaseItemListContainerProps } from 'App/containers'
import { EmptyList } from 'App/components'
import { ReactComponent as CaseIcon } from 'assets/icons/Case.svg'

import styles from './CaseItemList.module.scss'

export type TCaseItemListProps = TCaseItemListContainerProps

export const CaseItemList = ({
  caseIds,
  loading,
  isFiltersApplied,
  search
}: TCaseItemListProps) => {
  const { t } = useTranslation()

  const cases = loading ? generateRandomArray() : caseIds

  return (
    <>
      {cases.length ? (
        cases.map((caseId: string) => (
          <div key={caseId} className={styles.root}>
            <CaseItemWrapperContainer id={caseId} />
          </div>
        ))
      ) : (
        <div className={cls(styles.root, styles.rootEmpty)}>
          <EmptyList
            icon={<CaseIcon className={styles.rootIcon} />}
            hasAppliedFilters={!!isFiltersApplied}
            hasAppliedSearch={!!search.length}
            text={t('cases.list.placeholder')}
          />
        </div>
      )}
    </>
  )
}
