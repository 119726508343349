import { useEffect, useState } from 'react'

import {
  CaseHistoryFilter,
  ETableColumnFilters,
  TFilterDropdownProps,
  TSelectedKeysOptions
} from 'App/components'
import { CaseHistoryFilterModalContainerProps } from 'App/containers'
import { useAdaptiveLayout } from 'App/hooks'

type TModalCaseHistoryFilterSettings<T extends TSelectedKeysOptions> = Omit<
  TFilterDropdownProps<T>,
  'confirm'
> & {
  type: ETableColumnFilters
  handleDropdownConfirm: () => void
} & CaseHistoryFilterModalContainerProps

export function CaseHistoryFilterModal({
  type,
  selectedKeys,
  filters,
  clearFilters,
  setSelectedKeys,
  hideModal,
  handleDropdownConfirm
}: TModalCaseHistoryFilterSettings<TSelectedKeysOptions>) {
  const { isMobile } = useAdaptiveLayout()
  const [modalSelectedFilters, setModalSelectedFilters] =
    useState<TSelectedKeysOptions>(selectedKeys)

  useEffect(() => {
    if (!isMobile) {
      hideModal()
    }

    return () => {
      setSelectedKeys(modalSelectedFilters)
    }
  }, [isMobile, modalSelectedFilters, setSelectedKeys, hideModal])

  return (
    <CaseHistoryFilter
      setSelectedKeys={setModalSelectedFilters}
      selectedKeys={modalSelectedFilters}
      confirm={handleDropdownConfirm}
      clearFilters={clearFilters}
      filters={filters}
      type={type}
    />
  )
}
