import { useEffect } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import { CheckableList, Search } from 'App/components'
import { EInputSize } from 'enums'

import { EmptyList } from '../../EmptyList'

import styles from './AccountsSelectGroups.module.scss'

export type TAccountsSelectGroupsItem = {
  label: string
  value: string
  selected: boolean

  items?: TAccountsSelectGroupsItem[]
}

export type TAccountsSelectGroupsProps = {
  placeholder: string
  onChange: (value: string[]) => void

  items?: TAccountsSelectGroupsItem[]
  selectedItems?: string[]
  search?: string
  loading?: boolean
  hideSearch?: boolean
  searchPlaceholder?: string
  title?: string
  getGroups?: () => void
  onSearch?: (value: string) => void
}

export const AccountsSelectGroups = ({
  items,
  search,
  searchPlaceholder,
  placeholder,
  title,
  selectedItems = [],
  loading = false,
  hideSearch = false,
  getGroups,
  onSearch,
  onChange
}: TAccountsSelectGroupsProps) => {
  useEffect(() => {
    getGroups && getGroups()
  }, [getGroups])

  return (
    <div className={cls({ [styles.root]: true, [styles.rootWithSkeleton]: loading })}>
      {!hideSearch && (
        <div className={styles.search}>
          <Search onChange={onSearch} searchSize={EInputSize.L} placeholder={searchPlaceholder} />
        </div>
      )}

      <div className={styles.title}>{title}</div>

      <Skeleton
        active={true}
        loading={loading}
        title={false}
        paragraph={{ rows: 1, width: '100%' }}
      >
        {!items?.length ? (
          <EmptyList text={placeholder} hasAppliedSearch={!!search} />
        ) : (
          <CheckableList
            items={items}
            value={selectedItems}
            loading={loading}
            onChange={onChange}
          />
        )}
      </Skeleton>
    </div>
  )
}
