import { useCallback } from 'react'
import { BroadcastTypeEnum, ErrorCodesEnum } from '@medentee/enums'

import { useMutation } from 'services/query'
import { TResponseError, isMatchErrorCode } from 'utils'
import { hideModalAction, RECEIVE_BROADCAST_CREATED, useAppDispatch } from 'store'
import { handleError } from 'api'
import { createBroadcast, editBroadcast } from 'api/broadcasts'
import { TAccountsSelectOptionData } from 'App/components/common/AccountsSelect/AccountsSelectOption/AccountsSelectOption'

import { skipWSAction } from '../../../../services/skipWSActions'

import { TBroadcastCreateDialogFormValues } from './BroadcastCreateDialog'

export const CREATE_BROADCAST_MUTATION_KEY = 'create-broadcast'
export const UPDATE_BROADCAST_MUTATION_KEY = 'update-broadcast'

type TUseBroadcastCreateDialogRequestsProps = {
  entityId: string
  type: BroadcastTypeEnum
  isEdit: boolean
  selectedAccounts: TAccountsSelectOptionData[]

  broadcastId?: string
}

export const useBroadcastCreateDialogRequests = ({
  isEdit,
  broadcastId,
  entityId,
  type,
  selectedAccounts
}: TUseBroadcastCreateDialogRequestsProps) => {
  const dispatch = useAppDispatch()

  const handleMutateError = (err: TResponseError) => {
    if (isMatchErrorCode(err, ErrorCodesEnum.INCORRECT_URL_FOR_LIVE_EVENT)) {
      return
    }

    handleError(err)
  }

  const handleMutateSuccess = () => {
    dispatch(hideModalAction())
  }

  const createMutation = useMutation({
    mutationKey: [CREATE_BROADCAST_MUTATION_KEY],
    mutationFn: createBroadcast,
    onSuccess: handleMutateSuccess,
    onError: handleMutateError
  })

  const editMutation = useMutation({
    mutationKey: [UPDATE_BROADCAST_MUTATION_KEY],
    mutationFn: editBroadcast,
    onSuccess: handleMutateSuccess,
    onError: handleMutateError
  })

  const handleFormSubmit = useCallback(
    ({ name, url, isPublic }: TBroadcastCreateDialogFormValues) => {
      const accountIds = selectedAccounts.map(({ id }) => id)

      if (isEdit && broadcastId) {
        editMutation.mutate({
          accountIds: isPublic ? [] : accountIds,
          name,
          url,
          broadcastId,
          isPublic
        })
        return
      }

      skipWSAction({
        type: RECEIVE_BROADCAST_CREATED,
        payload: { [type === BroadcastTypeEnum.EVENT ? 'eventId' : 'communityId']: entityId }
      })
      createMutation.mutate({
        accountIds: isPublic ? [] : accountIds,
        name,
        url,
        type,
        entityId,
        isPublic
      })
    },
    [selectedAccounts, isEdit, broadcastId, createMutation, type, entityId, editMutation]
  )

  return {
    error: isEdit ? editMutation.error : createMutation.error,
    handleFormSubmit
  }
}
