import { useCallback, useLayoutEffect } from 'react'
import { useHistory } from 'react-router'
import isString from 'lodash/isString'
import { parse } from 'query-string'
import { ChatTypeEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import {
  GET_CHAT_ROOMS,
  getChatRoomsRequest,
  hideModalAction,
  showModalAction,
  useAppDispatch
} from 'store'
import { joinToOrganizationChannel } from 'api/organizations'
import { toast } from 'App/components/ToastContainer'
import { toastDefaultOptions } from 'globalConstants'

export const useJoinToChannel = (organizationId: string) => {
  const dispatch = useAppDispatch()

  const { replace, location } = useHistory()

  const { t } = useTranslation()

  const { pathname, search } = location
  const { title, token, variant } = parse(search)

  const handleClose = useCallback(() => replace({ pathname, search: '' }), [pathname, replace])

  const joinToChannelMutation = useMutation({
    mutationKey: ['join-to-organization-channel'],
    mutationFn: joinToOrganizationChannel
  })

  const handleConfirmJoinToChannel = useCallback(
    () =>
      joinToChannelMutation.mutateAsync(
        { organizationId, token: isString(token) ? token : '' },
        {
          onSuccess: ({ id }) => {
            dispatch(
              getChatRoomsRequest({
                organizationId,
                chatType: [ChatTypeEnum.ORGANIZATION],
                onSuccess: () => {
                  toast.success(t('common.toast.joinedSuccessfully'), toastDefaultOptions)
                  if (isString(variant)) {
                    replace({ pathname: `${location.pathname}/${variant}/${id}`, search: '' })
                  }
                  dispatch(hideModalAction())
                }
              })
            )
          }
        }
      ),
    [organizationId, dispatch, joinToChannelMutation, location.pathname, replace, t, token, variant]
  )

  useLayoutEffect(() => {
    if (isString(token) && isString(title) && isString(variant)) {
      dispatch(
        showModalAction({
          modalTitle: t('modal.joinChannelConfirm.title'),
          modalType: EModalComponents.GENERIC_CONFIRMATION,
          modalProps: {
            loadingActions: [GET_CHAT_ROOMS],
            content: title,
            confirmLabel: t('modal.joinChannelConfirm.submitButton'),
            onConfirm: handleConfirmJoinToChannel,
            onClose: handleClose
          }
        })
      )
    }
  }, [dispatch, handleClose, handleConfirmJoinToChannel, t, title, token, variant])
}
