import { FC, ReactNode } from 'react'
import cls from 'classnames'
import { Skeleton } from 'antd'

import { TIds } from 'store'
import { generateRandomArray } from 'utils'
import { EEmptyListIconSize, EmptyList, FormSubmitButtonSkeleton } from 'App/components'

import styles from './List.module.scss'

type TListProps = PropsWithClassName<{
  ids: TIds
  item: FC<{ id: string }>

  footer?: ReactNode
  loading?: boolean
  skeletonIdsLength?: number
  emptyIcon?: ReactNode
  emptyText?: string
}>
const DEFAULT_SKELETON_IDS_LENGTH = 3

export const List = ({
  className,
  ids,
  item: Item,
  emptyIcon,
  emptyText,
  footer,
  loading = false,
  skeletonIdsLength = DEFAULT_SKELETON_IDS_LENGTH
}: TListProps) => {
  const items = loading ? generateRandomArray(skeletonIdsLength) : ids

  return (
    <div className={cls(styles.root, className)}>
      {items.length ? (
        <div>
          {items.map((id) => (
            <Skeleton
              key={id}
              className={styles.skeleton}
              avatar={false}
              paragraph={{ rows: 5 }}
              active={true}
              loading={loading}
              title={true}
            >
              <Item id={id} />
            </Skeleton>
          ))}
        </div>
      ) : (
        <EmptyList
          className={styles.empty}
          text={emptyText}
          icon={emptyIcon}
          iconSize={EEmptyListIconSize.MD}
        />
      )}
      {footer && (
        <FormSubmitButtonSkeleton className={styles.skeletonButton} loading={loading} size="s">
          <div className={styles.footer}>{footer}</div>
        </FormSubmitButtonSkeleton>
      )}
    </div>
  )
}
