import { useMemo } from 'react'
import { AccountTypeNames, BusinessAccountRoleIdEnum } from '@medentee/enums'
import { LocationDescriptor } from 'history'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { State } from 'redux/rootReducer'
import { TAccountRole } from 'store'
import { settingsNestedRoutes } from 'App/App.config'
import { TNavMenuItem } from 'App/components'
import { SettingsPageLayout } from 'App/components/common/SettingsPageLayout'
import { getMapComponent } from 'utils'

type TNavMapProps = { role?: TAccountRole; t: TFunction }

const NAV_ITEMS_MAP = new Map<AccountTypeNames, (props: TNavMapProps) => TNavMenuItem[]>()
  .set(AccountTypeNames.BUSINESS, ({ role, t }) => [
    {
      link: '/settings/company-info',
      title: t('settings.tabs.companyInformation'),
      exact: true,
      hidden: role?.id !== BusinessAccountRoleIdEnum.SUPERADMIN
    },
    {
      link: '/settings/account-notifications',
      title: t('settings.tabs.notifications'),
      exact: true
    },
    {
      link: '/settings/security',
      title: t('settings.tabs.accountAndSecurity'),
      exact: true,
      hidden: role?.id !== BusinessAccountRoleIdEnum.SUPERADMIN
    },
    {
      link: '/settings/support',
      title: t('settings.tabs.helpAndSupport'),
      exact: true,
      hidden: role?.id !== BusinessAccountRoleIdEnum.SUPERADMIN
    }
  ])
  .set(AccountTypeNames.PROFESSIONAL, ({ t }) => [
    {
      link: '/settings/info',
      title: t('settings.tabs.personalInformation'),
      exact: true
    },
    {
      link: '/settings/account-notifications',
      title: t('settings.tabs.notifications'),
      exact: true
    },
    {
      link: '/settings/security',
      title: t('settings.tabs.accountAndSecurity'),
      exact: true
    },
    {
      link: '/settings/support',
      title: t('settings.tabs.helpAndSupport'),
      exact: true
    }
  ])

const DEFAULT_NAV_PATH_MAP = new Map<
  AccountTypeNames,
  (props: TNavMapProps) => LocationDescriptor
>()
  .set(AccountTypeNames.BUSINESS, ({ role }) =>
    role?.id === BusinessAccountRoleIdEnum.SUPERADMIN
      ? '/settings/company-info'
      : '/settings/account-notifications'
  )
  .set(AccountTypeNames.PROFESSIONAL, () => '/settings/info')

export const Settings = () => {
  const { accountType = AccountTypeNames.PROFESSIONAL, role } = useSelector((state: State) => ({
    accountType: state.global.accountData?.type.name,
    role: state.global.accountData?.role
  }))

  const { t } = useTranslation()

  const menuList = useMemo(
    () => getMapComponent(NAV_ITEMS_MAP, accountType, { role, t }) ?? [],
    [accountType, role, t]
  )
  const defaultPath = getMapComponent(DEFAULT_NAV_PATH_MAP, accountType, { role, t }) ?? '/'

  if (!accountType) {
    return null
  }

  return (
    <SettingsPageLayout
      title={t('settings.title')}
      menu={{ menuList }}
      defaultPath={defaultPath}
      nestedRoutes={settingsNestedRoutes}
    />
  )
}
