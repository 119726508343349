import { ContactStatusEnum } from '@medentee/enums'

import {
  DELETE_CATEGORY_SUCCESS,
  GET_CONTACTS_ERROR,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  SET_CONTACTS_FILTER,
  RESET_CONTACTS_FILTER,
  RESET_CONTACTS,
  SELECT_ALL_CATEGORIES_CONTACTS_FILTER,
  SELECT_CATEGORIES_CONTACTS_FILTER,
  SET_LOADING_CONTACTS_FILTER,
  SET_NOT_ASSIGNED_CONTACTS_FILTER,
  SET_PAGE_CONTACTS_FILTER,
  SET_SEARCH_CONTACTS_FILTER,
  TAction,
  TDeleteCategorySuccess,
  TGetContactsError,
  TGetContactsRequest,
  TGetContactsSuccess,
  TSelectAllCategoriesContactsFilter,
  TSelectCategoriesContactsFilter,
  TSetLoadingContactsFilter,
  TSetNotAssignedContactsFilter,
  TSetPageContactsFilter,
  TSetSearchContactsFilter,
  SET_CASES_AUTO_APPROVE_SUCCESS,
  UPDATED_CONTACT_CATEGORIES
} from 'store'
import { PAGINATION_DEFAULT } from 'types'

import {
  TContactsState,
  TSetContactsFilters,
  TSetCasesAutoApproveSuccess,
  TUpdatedContactCategoriesPayload
} from './contacts.types'

export const initialContactsState: TContactsState = {
  filters: {
    page: 0,
    showBy: PAGINATION_DEFAULT.showBy,
    search: '',
    searchBy: '',
    online: [],
    selectedAccountTypes: [],
    selectedCategories: [],
    loadingContacts: false,
    notAssigned: false,
    total: 0
  },
  lastRequest: {
    page: 0,
    type: undefined,
    status: undefined,
    withoutHidden: undefined,
    selectedAccountTypes: []
  },
  total: 0,
  ids: [],
  list: {},
  contactInvitations: {
    total: 0,
    ids: [],
    list: {}
  }
}

export const contactsReducer = (state = initialContactsState, action: TAction) => {
  switch (action.type) {
    case GET_CONTACTS_REQUEST: {
      const { page, type, status, withoutHidden, sortBy, selectedAccountTypes } =
        (action as TGetContactsRequest).payload ?? {}

      return {
        ...state,
        lastRequest: {
          ...state.lastRequest,
          sortBy,
          type,
          status,
          page,
          withoutHidden,
          selectedAccountTypes: selectedAccountTypes ?? state.filters.selectedAccountTypes
        }
      }
    }

    case GET_CONTACTS_SUCCESS: {
      const { total, ids, list, status, selectedAccountTypes } = (action as TGetContactsSuccess)
        .payload
      const isApproved = status === ContactStatusEnum.APPROVED

      return {
        ...state,
        total: isApproved ? total : state.total,
        ids: isApproved ? ids : state.ids,
        list: isApproved ? list : state.list,
        contactInvitations: {
          ...state.contactInvitations,
          ids: !isApproved ? ids : state.contactInvitations.ids,
          list: !isApproved ? list : state.contactInvitations.list,
          total: !isApproved ? total : state.contactInvitations.total
        },
        filters: {
          ...state.filters,
          total: isApproved ? total : state.filters.total,
          selectedAccountTypes: selectedAccountTypes ?? state.filters.selectedAccountTypes
        }
      }
    }

    case GET_CONTACTS_ERROR: {
      return {
        ...state,
        error: (action as TGetContactsError).payload
      }
    }

    case SET_LOADING_CONTACTS_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          loadingContacts: (action as TSetLoadingContactsFilter).payload.loading
        }
      }
    }

    case SET_SEARCH_CONTACTS_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: (action as TSetSearchContactsFilter).payload.search,
          searchBy: (action as TSetSearchContactsFilter).payload.searchBy,
          page: 0
        }
      }
    }

    case SET_PAGE_CONTACTS_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: (action as TSetPageContactsFilter).payload.page
        }
      }
    }

    case SELECT_CATEGORIES_CONTACTS_FILTER: {
      const { category } = (action as TSelectCategoriesContactsFilter).payload

      const currentIndex = state.filters.selectedCategories.findIndex(
        (predicate) => predicate.id === category.id
      )
      const newChecked = [...state.filters.selectedCategories]

      if (currentIndex === -1) {
        newChecked.push(category)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          page: 0,
          selectedCategories: newChecked
        }
      }
    }

    case SELECT_ALL_CATEGORIES_CONTACTS_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: 0,
          selectedCategories: (action as TSelectAllCategoriesContactsFilter).payload.checked
        }
      }
    }

    case SET_NOT_ASSIGNED_CONTACTS_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          notAssigned: (action as TSetNotAssignedContactsFilter).payload.notAssigned
        }
      }

    case SET_CASES_AUTO_APPROVE_SUCCESS: {
      const { id, currentCaseAutoApproveStatus } = (action as TSetCasesAutoApproveSuccess).payload

      return {
        ...state,
        list: {
          ...state.list,
          [id]: {
            ...state.list[id],
            caseAutoApproveMode: currentCaseAutoApproveStatus
          }
        }
      }
    }

    case DELETE_CATEGORY_SUCCESS: {
      const { id } = (action as TDeleteCategorySuccess).payload

      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategories: state.filters.selectedCategories.filter((item) => item.id !== id)
        }
      }
    }

    case SET_CONTACTS_FILTER:
      const {
        online = state.filters.online,
        selectedAccountTypes = state.filters.selectedAccountTypes
      } = (action as TSetContactsFilters).payload

      return {
        ...state,
        filters: {
          ...state.filters,
          online,
          selectedAccountTypes
        }
      }

    case RESET_CONTACTS_FILTER:
      return {
        ...state,
        filters: {
          ...initialContactsState.filters,
          search: state.filters.search,
          searchBy: state.filters.searchBy
        }
      }

    case RESET_CONTACTS:
      return {
        ...initialContactsState
      }

    case UPDATED_CONTACT_CATEGORIES: {
      const { categories, contactId } = action.payload as TUpdatedContactCategoriesPayload

      if (!state.list[contactId]) {
        return state
      }

      return {
        ...state,
        list: {
          ...state.list,
          [contactId]: {
            ...state.list[contactId],
            categories
          }
        }
      }
    }

    default:
      return state
  }
}
