import cls from 'classnames'

import { FileIcon, TFileIconProps } from '../FileIcon'
import { FileNameTruncatedText } from '../FileNameTruncatedText'

import styles from './FileName.module.scss'

export type TFileNameProps = TFileIconProps & {
  fileName: string

  iconClassName?: string
  textClassName?: string
  twoLine?: boolean
  disabled?: boolean
  fileNameDisabled?: boolean
  onClick?: () => void
}

export const FileName = ({
  extension,
  fileName,
  className,
  onClick,
  textClassName = '',
  iconClassName = '',
  twoLine = false,
  disabled = false,
  fileNameDisabled = false
}: TFileNameProps) => (
  <div className={cls(styles.fileName, className)} onClick={onClick}>
    <FileIcon extension={extension} className={iconClassName} disabled={disabled} />
    <FileNameTruncatedText
      twoLine={twoLine}
      extension={extension}
      fileName={fileName}
      className={textClassName}
      disabled={fileNameDisabled}
    />
  </div>
)
