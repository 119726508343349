import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Spinner } from 'App/components'
import {
  createLoadingSelector,
  getVideoStreamUrlRequest,
  GET_VIDEO_STREAM_URL,
  useAppSelector
} from 'store'

import styles from './MobileVideoPlayer.module.scss'

const loadingSelector = createLoadingSelector([GET_VIDEO_STREAM_URL])

export const MobileVideoPlayer = () => {
  const dispatch = useDispatch()
  const { fileId } = useParams<{ fileId: string }>()
  const loading = useAppSelector(loadingSelector)
  const url = useAppSelector((state) => state.misc.videoStreamUrl)

  useEffect(() => {
    dispatch(getVideoStreamUrlRequest(fileId))
  }, [fileId, dispatch])

  if (loading) {
    return <Spinner />
  }

  return <iframe title="video" src={url} className={styles.iframe} />
}
