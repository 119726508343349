import cls from 'classnames'
import capitalize from 'lodash/capitalize'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import styles from './KebabContactStatus.module.scss'

export type TKebabContactStatusProps = {
  fromId?: string
  accountId?: string
  status?: ContactStatusEnum
}

export const KebabContactStatus = ({ status, accountId, fromId }: TKebabContactStatusProps) => {
  const { t } = useTranslation()

  return status === ContactStatusEnum.PENDING ? (
    <div className={styles.root}>
      <span className={styles.title}>{t('common.kebabInvitationStatus.title')}</span>
      {accountId === fromId ? (
        <span className={cls(styles.status, styles.statusSender)}>
          {capitalize(t(`enum.contactStatusEnum.${status}`))}
        </span>
      ) : (
        <span className={cls(styles.status, styles.statusReceiver)}>
          {t('common.kebabInvitationStatus.incomingInvitation')}
        </span>
      )}
    </div>
  ) : null
}
