import cls from 'classnames'

import { useAdaptiveLayout } from 'App/hooks'
import { HeaderMedCloud, HeaderMenu, Logo, CloudIcon, MeetingsWidget } from 'App/containers'

import styles from './Header.module.scss'

export const Header = () => {
  const { isMobile, isDesktop } = useAdaptiveLayout()

  const medCloud =
    !isMobile && (isDesktop ? <HeaderMedCloud /> : <CloudIcon className={styles.medCloud} />)

  return (
    <header className={cls(styles.header)}>
      <Logo />
      <MeetingsWidget />
      {medCloud}
      <HeaderMenu />
    </header>
  )
}
