import { useCallback, useMemo } from 'react'
import { ContactStatusEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { Drawer, EmptyList, TDrawerProps } from 'App/components/common'
import { getUserProfile } from 'api/profile'
import { queryClient } from 'queryClient'

import { UnityHubNetworkingDrawerSkeleton } from '../../UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerSkeleton'
import {
  UnityHubNetworkingDrawerHeader,
  TUnityHubNetworkingDrawerHeaderProps
} from '../../UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerHeader'
import { UnityHubNetworkingDrawerFooter } from '../../UnityHub/UnityHubNetworkingDrawer/UnityHubNetworkingDrawerFooter'
import { COMMUNITY_MEMBERS_QUERY_KEY } from '../CommunityMembers'

import {
  CommunityMemberDrawerContent,
  TCommunityMemberDrawerContentProps
} from './CommunityMemberDrawerContent'
import styles from './CommunityMemberDrawer.module.scss'

export type TCommunityMemberDrawerProps = Pick<TDrawerProps, 'visible' | 'onClose'> &
  Pick<TCommunityMemberDrawerContentProps, 'contact'> &
  Pick<TUnityHubNetworkingDrawerHeaderProps, 'userName'> & {
    memberId: string
    accountId: string
  }

const COMMUNITY_MEMBER_PROFILE_QUERY_KEY = 'community_member_profile'

export const CommunityMemberDrawer = ({
  visible,
  memberId,
  contact,
  userName,
  accountId,
  onClose
}: TCommunityMemberDrawerProps) => {
  const { t } = useTranslation()

  const { data, isFetching, refetch } = useQuery({
    queryKey: [COMMUNITY_MEMBER_PROFILE_QUERY_KEY, memberId],
    queryFn: () => getUserProfile(memberId),
    enabled: visible && !!memberId,
    cacheTime: 0,
    staleTime: 0
  })

  const onInviteSuccess = useCallback(() => {
    refetch()
    queryClient.invalidateQueries({ queryKey: [COMMUNITY_MEMBERS_QUERY_KEY], type: 'active' })
  }, [refetch])

  const content = useMemo(() => {
    if (!data && isFetching) {
      return <UnityHubNetworkingDrawerSkeleton />
    }

    if (!data) {
      return <EmptyList text={t('unityHub.networking.member.drawer.placeholder')} />
    }

    return (
      <CommunityMemberDrawerContent member={data} contact={contact} onSuccess={onInviteSuccess} />
    )
  }, [contact, data, isFetching, onInviteSuccess, t])

  const shouldShowHeaderActions =
    contact?.status === ContactStatusEnum.PENDING && contact?.fromId === memberId
  const shouldShowFooterActions = !contact && accountId !== memberId

  return (
    <Drawer
      className={styles.root}
      visible={visible}
      headerActions={
        shouldShowHeaderActions ? (
          <UnityHubNetworkingDrawerHeader
            contactId={contact.id}
            memberId={memberId}
            userName={userName}
            onSuccess={onInviteSuccess}
          />
        ) : undefined
      }
      footerActions={
        shouldShowFooterActions ? (
          <UnityHubNetworkingDrawerFooter memberId={memberId} onSuccess={onInviteSuccess} />
        ) : undefined
      }
      onClose={onClose}
    >
      {content}
    </Drawer>
  )
}
