import { ReactNode, SVGProps } from 'react'
import cls from 'classnames'

import { AnchorifedText } from 'App/components/common'

import styles from './ItemLabel.module.scss'

export type TItemLabelVariant = 'default' | 'primary'
export type TItemLabelProps = {
  label: string
  icon: ReactNode | SVGProps<SVGSVGElement>
  variant?: TItemLabelVariant
}

export const ItemLabel = ({
  label,
  className,
  icon,
  variant = 'default'
}: PropsWithClassName<TItemLabelProps>) => (
  <div className={cls(styles.root, styles[variant], className)}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.label}>
      <AnchorifedText text={label} />
    </div>
  </div>
)
