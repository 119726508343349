import { PropsWithChildren, useLayoutEffect, useRef } from 'react'
import { ListChildComponentProps } from 'react-window'

export type TMenuItemProps = {
  setRowHeight: (index: number, size: number) => void
}

export const MenuItem = ({
  index,
  style,
  children,
  setRowHeight
}: PropsWithChildren<Pick<ListChildComponentProps, 'index' | 'style'> & TMenuItemProps>) => {
  const optionRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (optionRef.current) {
      setRowHeight(index, optionRef.current.clientHeight)
    }
  }, [setRowHeight, index])

  return (
    <div style={style}>
      <div ref={optionRef}>{children}</div>
    </div>
  )
}
