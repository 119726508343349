import { call, put, takeLatest } from 'redux-saga/effects'

import { API, api, APIData } from 'services/api'
import { caseDetailsReset, hideModalAction, TCaseCreateRequest } from 'store'
import history from 'utils/history'
import { ICaseDetailsDTO } from 'interfaces'

import { caseCreateError, caseCreateSuccess, CASE_CREATE_REQUEST } from './caseCreate.actions'

function* caseCreate({ payload }: TCaseCreateRequest) {
  try {
    const { data }: APIData<ICaseDetailsDTO> = yield call(api.post, API.CASES, payload)

    yield put(caseCreateSuccess())
    yield put(hideModalAction())

    if (payload.caseIdToLink) {
      yield put(caseDetailsReset())
    }

    yield history.push(`/cases/${data.id}/owner`)
  } catch (e) {
    yield put(caseCreateError(e))
  }
}

export function* caseCreateSaga() {
  yield takeLatest(CASE_CREATE_REQUEST, caseCreate)
}
