import { useHistory, useParams } from 'react-router'
import { useEffect, useCallback } from 'react'
import { AccountTypeIdEnum, BusinessAccountRoleIdEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  accountIdSelector,
  accountTypIdSelector,
  showModalAction,
  useAppDispatch,
  useAppSelector
} from 'store'
import { Spinner } from 'App/components'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { DEFAULT_ROUTE, toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'

type TParams = {
  accountTypeId: AccountTypeIdEnum
  accountId: string
}

const SUBSCRIPTION_PATH = '/subscription-and-payments/subscription'

export const SubscriptionUpgrade = () => {
  const dispatch = useAppDispatch()

  const { replace } = useHistory()

  const { accountTypeId, accountId } = useParams<TParams>()
  const currentAccountId = useAppSelector(accountIdSelector)
  const currentAccountTypeId = useAppSelector(accountTypIdSelector)
  const isAdmin = useAppSelector(
    (state) => state.global.accountData?.role?.id === BusinessAccountRoleIdEnum.ADMIN
  )

  const { t } = useTranslation()

  const isBusiness = accountTypeId === AccountTypeIdEnum.BUSINESS
  const shouldShowModal = currentAccountTypeId !== accountTypeId || isAdmin

  const handleClose = useCallback(() => {
    replace(DEFAULT_ROUTE)
  }, [replace])

  const handleSuccess = useCallback(() => {
    window.location.href = `${window.location.origin}${SUBSCRIPTION_PATH}`
  }, [])

  const redirect = useCallback(() => {
    if (accountId === currentAccountId) {
      replace(SUBSCRIPTION_PATH)
      return
    }

    toast.error(
      t('serverError.DEFAULT_NOT_AVAILABLE_RESOURCE_MESSAGE', { ns: 'errors' }),
      toastDefaultOptions
    )

    replace(DEFAULT_ROUTE)
  }, [accountId, currentAccountId, replace, t])

  useEffect(() => {
    if (!shouldShowModal) {
      redirect()
    }
  }, [redirect, shouldShowModal])

  useEffect(() => {
    if (shouldShowModal) {
      dispatch(
        showModalAction({
          modalTitle: t('modal.switchAccountConfirm.title'),
          modalType: EModalComponents.SWITCH_ACCOUNT_DIALOG,
          modalProps: {
            accountId,
            content: isBusiness
              ? t('modal.switchAccountConfirm.content_upgradeBusiness')
              : t('modal.switchAccountConfirm.content_upgradeProfessional'),
            confirmText: isBusiness
              ? t('modal.switchAccountConfirm.submitButton_business')
              : t('modal.switchAccountConfirm.submitButton'),
            onSuccess: handleSuccess,
            onClose: handleClose
          }
        })
      )
    }
  }, [dispatch, replace, shouldShowModal, handleClose, handleSuccess, accountId, isBusiness, t])

  return <Spinner contentCenter />
}
