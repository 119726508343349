import { put, takeLatest, call, all } from 'redux-saga/effects'
import { MeetingInviteStatusEnum } from '@medentee/enums'

import { API, APIData, api } from 'services/api'
import { toastDefaultOptions } from 'globalConstants'
import { toast } from 'App/components/ToastContainer'
import { ongoingCallsNormalize } from 'store/calls/calls.normalize'
import { handleError } from 'api/utils'

import i18n from '../../i18n'

import {
  acceptMeetingInviteError,
  acceptMeetingInviteRequest,
  acceptMeetingInviteSuccess,
  cancelMeetingError,
  cancelMeetingRequest,
  cancelMeetingSuccess,
  getMeetingsError,
  getMeetingsRequest,
  getMeetingsSuccess,
  rejectMeetingInviteError,
  rejectMeetingInviteRequest,
  rejectMeetingInviteSuccess,
  scheduleMeetingError,
  scheduleMeetingRequest,
  scheduleMeetingSuccess
} from './meetings.actions'
import {
  SCHEDULE_MEETING_REQUEST,
  GET_MEETINGS_REQUEST,
  ACCEPT_MEETING_INVITE_REQUEST,
  CANCEL_MEETING_REQUEST,
  REJECT_MEETING_INVITE_REQUEST
} from '.'
import { meetingsNormalize } from './meetings.normalization'

function* scheduleMeetingSaga({
  payload: { chatId, onSuccess, ...data }
}: ReturnType<typeof scheduleMeetingRequest>) {
  try {
    const response: APIData<{ id: string }> = yield call(
      api.post,
      API.SCHEDULE_MEETING(chatId),
      data
    )

    if (onSuccess) {
      yield call(onSuccess, response.data.id)
    }

    yield put(scheduleMeetingSuccess())

    yield toast.success(i18n.t('common.toast.canceledMeeting'), toastDefaultOptions)
  } catch (e) {
    yield handleError(e)
    yield put(scheduleMeetingError(e))
  }
}

function* getMeetingsSaga() {
  try {
    const { meetings, ongoingCalls } = yield all({
      meetings: call(api.get, API.MEETINGS),
      ongoingCalls: call(api.get, API.CALLS_ONGOING)
    })

    const ongoingCallsNormalized = ongoingCallsNormalize(ongoingCalls.data)
    const meetingsNormalized = meetingsNormalize(meetings.data.results)

    yield put(
      getMeetingsSuccess({
        meetings: meetingsNormalized,
        ongoingCalls: ongoingCallsNormalized
      })
    )
  } catch (e) {
    yield handleError(e)
    yield put(getMeetingsError(e))
  }
}

function* cancelMeetingSaga({
  payload: { id, onSuccess }
}: ReturnType<typeof cancelMeetingRequest>) {
  try {
    yield call(api.delete, API.CANCEL_MEETING(id))

    if (onSuccess) {
      yield call(onSuccess)
    }

    yield put(cancelMeetingSuccess({ processingId: id }))
    yield toast.success(i18n.t('common.toast.canceledMeeting'), toastDefaultOptions)
  } catch (e) {
    yield handleError(e)
    yield put(cancelMeetingError({ ...e, processingId: id }))
  }
}

function* acceptMeetingInviteSaga({
  payload: { id, onSuccess }
}: ReturnType<typeof acceptMeetingInviteRequest>) {
  try {
    yield call(api.patch, API.UPDATE_MEETING_INVITES(id), {
      status: MeetingInviteStatusEnum.ACCEPTED
    })

    yield put(getMeetingsRequest())
    yield put(acceptMeetingInviteSuccess({ processingId: id }))
    if (onSuccess) {
      yield call(onSuccess)
    }
  } catch (e) {
    yield handleError(e)
    yield put(acceptMeetingInviteError({ ...e, processingId: id }))
  }
}

function* rejectMeetingInviteSaga({
  payload: { id, onSuccess, onError }
}: ReturnType<typeof rejectMeetingInviteRequest>) {
  try {
    yield call(api.patch, API.UPDATE_MEETING_INVITES(id), {
      status: MeetingInviteStatusEnum.REJECTED
    })

    if (onSuccess) {
      yield call(onSuccess)
    }
    yield put(rejectMeetingInviteSuccess({ processingId: id }))
  } catch (e) {
    yield handleError(e)
    yield put(rejectMeetingInviteError({ ...e, processingId: id }))

    if (onError) {
      yield call(onError)
    }
  }
}

export function* meetingsSaga() {
  yield takeLatest(SCHEDULE_MEETING_REQUEST, scheduleMeetingSaga)
  yield takeLatest(GET_MEETINGS_REQUEST, getMeetingsSaga)
  yield takeLatest(ACCEPT_MEETING_INVITE_REQUEST, acceptMeetingInviteSaga)
  yield takeLatest(CANCEL_MEETING_REQUEST, cancelMeetingSaga)
  yield takeLatest(REJECT_MEETING_INVITE_REQUEST, rejectMeetingInviteSaga)
}
