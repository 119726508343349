import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AccountTypeNames, PlatformInvitationType } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import { useMutation } from 'services/query'
import {
  hideModalAction,
  resetContacts,
  resetContactsFilter,
  setSearchContactsFilter,
  useAppDispatch,
  useAppSelector
} from 'store'
import { toastDefaultOptions } from 'globalConstants'
import { PageWrapper, TPageWrapperProps } from 'App/components'
import { EModalComponents, useInvitationDialogDescription, InvitationMenu } from 'App/containers'
import { Search } from 'App/components/Contact/Search'
import { ReactComponent as AccountMultiplePlusOutlineIcon } from 'assets/icons/AccountMultiplePlusOutline.svg'

import { sendInviteUsersByEmail } from '../../../api/invitations'

import { useContactsFilters } from './useContactsFilters'
import { useContactsSearchOptions } from './useContactsSearchOptions'
import { useContactsRoutes } from './useContactsRoutes'
import styles from './Contacts.module.scss'
import { InviteButtonContainer } from './InviteButtonContainer'

export type TContactsProps = {
  contactType?: AccountTypeNames
}

const useAsideProps = (
  accountType: AccountTypeNames | undefined,
  onConfirm: (emails: string[]) => Promise<any>
): TPageWrapperProps<EModalComponents.INVITE_BY_EMAIL>['aside'] => {
  const { t } = useTranslation()

  const contactsDescription = useInvitationDialogDescription(
    accountType,
    PlatformInvitationType.CONTACT
  )

  if (accountType === AccountTypeNames.BUSINESS) {
    return {
      modalTitle: t('modal.inviteByEmail.title'),
      modalType: EModalComponents.INVITE_BY_EMAIL,
      modalProps: {
        description: contactsDescription,
        onConfirm
      }
    }
  }

  return {
    buttonContainer: ({ children }) => (
      <InviteButtonContainer invitationType={PlatformInvitationType.CONTACT}>
        {children}
      </InviteButtonContainer>
    )
  }
}

export const Contacts = ({ contactType }: TContactsProps) => {
  const { location } = useHistory()

  const { t } = useTranslation()

  const { mutateAsync } = useMutation({
    mutationKey: ['invite-users'],
    mutationFn: (emails: string[]) =>
      sendInviteUsersByEmail({
        type: PlatformInvitationType.CONTACT,
        emails
      }),
    onSuccess: () => {
      dispatch(hideModalAction())
      toast.success(t('modal.inviteByEmail.invitationSentSuccessToast'), toastDefaultOptions)
    }
  })

  const dispatch = useAppDispatch()

  const { current } = useAppSelector((state) => state.adverts.filters)
  const accountData = useAppSelector((state) => state.global.accountData)

  const accountType = accountData?.type.name

  const resetPage = useCallback(() => dispatch(resetContacts()), [dispatch])
  const { filters, content, selectedFilters } = useContactsFilters({
    contactType,
    accountType,
    classes: { itemWrapper: styles.filtersItemWrapper },
    onReset: resetPage
  })

  const { defaultPath, navMenu, nestedRoutes } = useContactsRoutes()

  const searchOptions = useContactsSearchOptions()

  const isDefaultPath = location.pathname === defaultPath

  const { asideTitle, asideIcon, asideButtonText } = useMemo(
    () => ({
      asideTitle: t('contacts.asideSubtitle', { context: contactType }),
      asideIcon: <AccountMultiplePlusOutlineIcon />,
      asideButtonText: t('contacts.asideButton')
    }),
    [contactType, t]
  )

  const asideProps = useAsideProps(accountType, mutateAsync)

  const handleSearch = useCallback(
    (search: string, searchBy: (string | number)[]) => {
      dispatch(
        setSearchContactsFilter({ search, searchBy: searchBy[searchBy.length - 1].toString() })
      )
    },
    [dispatch]
  )
  const resetFilter = useCallback(() => dispatch(resetContactsFilter()), [dispatch])

  return (
    <PageWrapper
      classes={styles}
      navMenu={navMenu}
      defaultPath={defaultPath}
      nestedRoutes={nestedRoutes}
      autoScrollOnPagination={current}
      addonAfterAside={isDefaultPath ? undefined : <InvitationMenu />}
      aside={{
        icon: asideIcon,
        title: asideTitle,
        buttonText: asideButtonText,
        ...(asideProps || {})
      }}
      search={
        isDefaultPath ? (
          <Search filters={filters} searchOptions={searchOptions} onChange={handleSearch} />
        ) : undefined
      }
      selectedFilters={isDefaultPath ? selectedFilters : undefined}
      filterContent={isDefaultPath ? content : undefined}
      resetFilter={!isDefaultPath ? resetFilter : undefined}
      resetPage={resetPage}
    />
  )
}
