import cls from 'classnames'

import { IChatInfoUser } from 'interfaces'
import { EAvatarSize } from 'enums'
import { TAccount, TContactsData } from 'store'
import {
  AccountName,
  TAccountNameProps,
  ChatInterlocutorOnline,
  TContextMenuProps,
  ContextMenu
} from 'App/components'
import { useRedirectToUserProfile } from 'App/hooks'

import styles from './ChatRoomInfoUserItem.module.scss'

export type TChatRoomInfoItemMember = Pick<
  IChatInfoUser,
  'firstName' | 'lastName' | 'id' | 'displayUserName' | 'lastSeen'
> & {
  type: TAccount['type'] | IChatInfoUser['type']
}

export type TChatRoomInfoUserItemProps = Pick<
  TAccountNameProps,
  'chipList' | 'hideAccountType' | 'isContact'
> &
  PropsWithClasses<
    'root',
    {
      member?: TChatRoomInfoItemMember
      accountId?: string
      isOnline?: boolean
      contact?: Pick<TContactsData, 'status'>
    }
  > &
  Pick<TContextMenuProps, 'menuItems' | 'endAdornment'>

export const ChatRoomInfoUserItem = ({
  member,
  accountId,
  isOnline,
  chipList,
  classes,
  endAdornment,
  menuItems,
  hideAccountType,
  isContact
}: TChatRoomInfoUserItemProps) => {
  const { redirectToUserProfile } = useRedirectToUserProfile()

  if (!member) {
    return null
  }

  const { displayUserName, firstName, id, lastName, lastSeen, type } = member
  const isYourAccount = accountId === id

  return (
    <div
      className={cls(styles.root, classes?.root, {
        [styles.online]: isOnline
      })}
    >
      <AccountName
        showAvatar
        classes={{ title: styles.userName }}
        type={type.name}
        displayUserName={displayUserName}
        size={EAvatarSize.XS}
        firstName={firstName || ''}
        lastName={lastName || ''}
        online={!isYourAccount && isOnline}
        userId={id || ''}
        onClick={redirectToUserProfile(id, type.name)}
        chipList={chipList}
        hideAccountType={hideAccountType}
        isContact={isContact}
        isYourAccountIndicator="caption"
      >
        {!isOnline && !isYourAccount && (
          <ChatInterlocutorOnline lastSeen={lastSeen} online={false} />
        )}
      </AccountName>

      <ContextMenu
        classes={{ icon: styles.contextIcon }}
        menuItems={menuItems}
        endAdornment={endAdornment}
      />
    </div>
  )
}
