import cls from 'classnames'
import { CaseClassificationNameEnum, CaseStatusesEnum, CaseTypesEnum } from '@medentee/enums'
import { useTranslation } from 'react-i18next'

import {
  CaseAll2AllMembersListContainer,
  CaseMembersItemActiveContainer,
  CaseMembersItemInactiveContainer,
  TCaseMembersListContainerProps
} from 'App/containers'
import { CaseMemberListEmpty, CaseMembersItemMessageButton } from 'App/components'
import { ChatMutedIcon } from 'App/components/Chat/ChatMutedIcon'
import { ECaseAccountType } from 'enums'

import styles from './CaseMembersList.module.scss'

export type TCaseMembersListProps = {
  who?: ECaseAccountType
  caseType?: CaseTypesEnum | null
  caseClassification?: CaseClassificationNameEnum
  caseStatus?: CaseStatusesEnum
} & Pick<
  TCaseMembersListContainerProps,
  'activeIds' | 'inactiveIds' | 'all2allChatId' | 'unreadAll2AllChat' | 'isChatRoomMuted'
>

export const CaseMembersList = ({
  activeIds,
  inactiveIds,
  all2allChatId,
  who,
  caseType,
  unreadAll2AllChat,
  caseClassification,
  caseStatus,
  isChatRoomMuted
}: TCaseMembersListProps) => {
  const { t } = useTranslation()

  const all2allList = caseType === CaseTypesEnum.A2A && all2allChatId && (
    <>
      <p className={styles.title}>
        {t('cases.details.membersSection.groupChatLabel')} {isChatRoomMuted && <ChatMutedIcon />}
      </p>
      <div className={styles.all2all}>
        <div className={styles.all2allList}>
          <CaseAll2AllMembersListContainer chatId={all2allChatId} />
        </div>
        <div className={cls({ [styles.all2allButton]: who === ECaseAccountType.OWNER })}>
          <CaseMembersItemMessageButton
            unreadAll2AllChat={unreadAll2AllChat}
            chatId={all2allChatId}
          />
        </div>
      </div>
    </>
  )

  return (
    <div className={styles.root}>
      {all2allList}
      {(!!activeIds.length || !inactiveIds.length) && (
        <>
          {activeIds.length > 0 && (
            <p className={styles.title}>
              {t('cases.details.membersSection.activeMembersLabel', { number: activeIds.length })}
            </p>
          )}
          {activeIds.map((id) => (
            <CaseMembersItemActiveContainer id={id} key={id} who={who} />
          ))}
        </>
      )}

      {!!inactiveIds.length && (
        <>
          <p className={styles.title}>
            {' '}
            {t('cases.details.membersSection.inactiveMembersLabel', { number: inactiveIds.length })}
          </p>

          {inactiveIds.map((id) => (
            <CaseMembersItemInactiveContainer id={id} key={id} who={who} />
          ))}
        </>
      )}

      {!activeIds.length && !inactiveIds.length && caseStatus && caseClassification && (
        <CaseMemberListEmpty caseStatus={caseStatus} />
      )}
    </div>
  )
}
