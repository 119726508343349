import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation, useQuery } from 'services/query'
import {
  TCategoryListData,
  showModalAction,
  updatedContactCategories,
  useAppDispatch,
  useAppSelector
} from 'store'
import { MenuItemCollapse, TMenuItemCollapseOption, TMenuItemProps } from 'App/components/common'
import { EModalComponents } from 'App/containers/ModalRoot/ModalRoot.enums'
import { ReactComponent as AccountRemoveOutlineIcon } from 'assets/icons/AccountRemoveOutline.svg'
import { CasesAutoApproveContainer } from 'App/containers'
import { getCategories, setCategory } from 'api/categories'
import { ECategoryDialogAction } from 'App/components/CategoryDialog'

export type TUseContactsItemKebabMenu = {
  getMenuItems: () => TMenuItemProps[]
}
export type TUseContactsItemKebabMenuProps = {
  userId: string
  contactId: string
  defaultCategories: TCategoryListData[]

  chatId?: string
}

export const CATEGORIES_QUERY_KEY = 'categories-departments'
export const SET_CATEGORY_MUTATION_KEY = 'set-category'

export const useContactsItemKebabMenu = ({
  contactId,
  userId,
  defaultCategories
}: TUseContactsItemKebabMenuProps): TUseContactsItemKebabMenu => {
  const dispatch = useAppDispatch()

  const supportAccountId = useAppSelector((state) => state.global.accountData?.supportAccountId)

  const { t } = useTranslation()

  const isSupportAccount = userId === String(supportAccountId)

  const { data } = useQuery({
    queryKey: [CATEGORIES_QUERY_KEY],
    queryFn: () => getCategories()
  })

  const handleCreateCategory = useCallback(() => {
    dispatch(
      showModalAction({
        modalType: EModalComponents.CATEGORY_DIALOG,
        modalTitle: t('modal.createCategory.title'),
        modalProps: {
          action: ECategoryDialogAction.CREATE,
          preSelectedIds: [contactId]
        }
      })
    )
  }, [dispatch, contactId, t])

  const { mutate, isLoading } = useMutation({
    mutationKey: [SET_CATEGORY_MUTATION_KEY],
    mutationFn: setCategory
  })

  const handleSetCategory = useCallback(
    (checked: boolean, categoryId: string) => {
      const category = data?.find((item) => item.id === categoryId)

      if (!category) {
        return
      }

      const onSuccess = () => {
        dispatch(
          updatedContactCategories({
            contactId,
            categories: checked
              ? [...defaultCategories, category]
              : defaultCategories.filter((item) => item.id !== categoryId)
          })
        )
      }

      mutate(
        {
          categoryId,
          addContactIds: checked ? [contactId] : [],
          deleteContactIds: checked ? [] : [contactId]
        },
        { onSuccess }
      )
    },
    [contactId, data, defaultCategories, dispatch, mutate]
  )

  const handleRemoveContact = useCallback(() => {
    dispatch(
      showModalAction({
        modalTitle: t('modal.contactRemoveConfirm.title'),
        modalType: EModalComponents.REMOVE_CONTACT_CONFIRM,
        modalProps: {
          contactId,
          text: t('modal.contactRemoveConfirm.content')
        }
      })
    )
  }, [dispatch, contactId, t])

  const getOptions = useCallback(
    () =>
      data?.map<TMenuItemCollapseOption>(({ id, name }) => ({
        id,
        name,
        checked: !!defaultCategories.find((category) => category.id === id)
      })) ?? [],
    [data, defaultCategories]
  )

  const menuItems = useMemo(
    () => ({
      removeContact: () => ({
        content: t('contacts.list.item.kebabMenu.removeContact'),
        icon: <AccountRemoveOutlineIcon />,
        onClick: handleRemoveContact
      }),
      caseAutoApprove: () => ({
        content: '',
        dividerTop: true,
        render: () => <CasesAutoApproveContainer id={contactId} />
      }),
      setCategories: () => ({
        content: t('contacts.list.item.kebabMenu.setCategories'),
        dividerTop: true,
        render: (item: TMenuItemProps) => (
          <MenuItemCollapse
            item={item}
            buttonLabel={t('contacts.createNewCategoryButton')}
            options={getOptions()}
            loading={isLoading}
            onChange={handleSetCategory}
          />
        ),
        onClick: handleCreateCategory
      })
    }),
    [
      contactId,
      getOptions,
      handleCreateCategory,
      handleRemoveContact,
      handleSetCategory,
      isLoading,
      t
    ]
  )

  const getMenuItems = useCallback(
    (): TMenuItemProps[] =>
      isSupportAccount
        ? []
        : [menuItems.removeContact(), menuItems.caseAutoApprove(), menuItems.setCategories()],
    [isSupportAccount, menuItems]
  )

  return {
    getMenuItems
  }
}
