import { PropsWithChildren, ReactNode } from 'react'
import cls from 'classnames'

import styles from './SettingsItemSubtitle.module.scss'

export type TSettingsItemSubtitleClasses = 'root' | 'subtitle' | 'addon'

export type TSettingsItemSubtitleProps = {
  addon?: ReactNode
  classes?: Partial<Record<TSettingsItemSubtitleClasses, string>>
}

export const SettingsItemSubtitle = ({
  children,
  classes,
  addon
}: PropsWithChildren<TSettingsItemSubtitleProps>) => (
  <div className={cls(styles.root, classes?.root)}>
    <h4 className={cls(styles.subtitle, classes?.subtitle)}>{children}</h4>

    <div className={(styles.addon, classes?.addon)}>{addon}</div>
  </div>
)
