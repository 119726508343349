import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { CaseStatusesEnum } from '@medentee/enums'

import { State } from 'redux/rootReducer'
import { ECaseAccountType } from 'enums'
import {
  caseUploadFileRequest,
  checkCaseUnlockingAction,
  copyCaseFileToMedcloudRequest,
  createLoadingSelector,
  downloadCaseFileRequest,
  GET_CASE_FILES,
  GET_CASE_SHARED_WITH_ME_FILES,
  GET_CASE_UPLOADED_BY_ME_FILES,
  getCaseFilesRequest,
  getCaseSharedWithMeFilesRequest,
  getCaseUploadedByMeFilesRequest,
  getIsActiveMemberSelector,
  resetCloudFilesListAction,
  setCaseCloudActiveFileId,
  setCaseCloudCurrentTabAction,
  setCaseCloudWhoAction,
  showExtendableCardAction,
  showModalAction,
  setCaseCloudFilters,
  resetCaseCloudFilters
} from 'store'
import { CaseCloud, TCaseViewSelectValue } from 'App/components'

export type TCaseMemberCloudContainerProps = {
  who: ECaseAccountType
  caseId: string
  caseStatus: CaseStatusesEnum
  caseView: TCaseViewSelectValue
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingCaseCloudFilesSelector = createLoadingSelector([GET_CASE_FILES])
const loadingCaseCloudUploadedByMeFilesSelector = createLoadingSelector([
  GET_CASE_UPLOADED_BY_ME_FILES
])
const loadingCaseCloudSharedWithMeFilesSelector = createLoadingSelector([
  GET_CASE_SHARED_WITH_ME_FILES
])
const loadingSelector = createLoadingSelector([GET_CASE_FILES, GET_CASE_SHARED_WITH_ME_FILES])

const mapStateToProps = (state: State) => {
  const {
    ids: cloudFilesIds,
    total: cloudFilesTotal,
    fileDetailsId: showFileDetails
  } = state.caseCloud.cloudFiles
  const { ids: sharedWithMeIds, total: sharedWithMeTotal } = state.caseCloud.sharedWithMeFiles
  const { ids: uploadedByMeIds, total: uploadedByMeTotal } = state.caseCloud.uploadedByMeFiles
  const { permissionsMode, type: caseType, classification } = state.caseView.data || {}

  return {
    loadingCaseCloudFiles: loadingCaseCloudFilesSelector(state),
    loadingCaseCloudUploadedByMeFiles: loadingCaseCloudUploadedByMeFilesSelector(state),
    loadingCaseCloudSharedWithMeFiles: loadingCaseCloudSharedWithMeFilesSelector(state),
    loading: loadingSelector(state),
    cloudFilesIds,
    cloudFilesTotal,
    showFileDetails,
    sharedWithMeIds,
    sharedWithMeTotal,
    uploadedByMeIds,
    uploadedByMeTotal,
    currentTab: state.caseCloud.currentTab,
    caseClassificationName: classification?.name,
    isActiveMember: getIsActiveMemberSelector(state),
    permissionsMode,
    caseType,
    filters: state.caseCloud.filters
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      caseUploadFile: caseUploadFileRequest,
      getCaseFiles: getCaseFilesRequest,
      getCaseSharedWithMeFiles: getCaseSharedWithMeFilesRequest,
      getCaseUploadedByMeFiles: getCaseUploadedByMeFilesRequest,
      downloadFile: downloadCaseFileRequest,
      copyToMedcloud: copyCaseFileToMedcloudRequest,
      setCaseCloudActiveFileIdAction: setCaseCloudActiveFileId,
      showModal: showModalAction,
      checkCaseUnlocking: checkCaseUnlockingAction,
      setCaseCloudWho: setCaseCloudWhoAction,
      showExtendableCard: showExtendableCardAction,
      setCurrentTab: setCaseCloudCurrentTabAction,
      resetFiles: resetCloudFilesListAction,
      setCaseCloudFilters,
      resetCaseCloudFilters
    },
    dispatch
  )

export const CaseCloudContainer = connect(mapStateToProps, mapDispatchToProps)(CaseCloud)
