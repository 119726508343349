import cls from 'classnames'

import { stopPropagation } from 'utils'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/ChevronRight.svg'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/ChevronLeft.svg'

import { Spinner } from '../../../Spinner'

import styles from './MenuItem.module.scss'

export type TMenuItemProps = {
  content: string

  innerMenuItems?: Omit<TMenuItemProps, 'menuItems'>[]
  icon?: JSX.Element
  onClick?: () => void
  disabled?: boolean
  disabledTooltip?: string
  hidden?: boolean
  loading?: boolean
  dividerTop?: boolean
  render?: (item: Omit<TMenuItemProps, 'render'>) => JSX.Element
  closeAfterClick?: () => void
}

type TMenuItemBaseProps = {
  shouldShowInnerIcon?: boolean
  innerIconPosition?: 'left' | 'right'
}

export const MenuItem = ({
  icon,
  content,
  disabled,
  onClick,
  loading,
  closeAfterClick,
  shouldShowInnerIcon = false,
  innerIconPosition = 'right'
}: TMenuItemProps & TMenuItemBaseProps) => {
  const onMenuItemClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopPropagation(event)
    event.preventDefault()

    if (!disabled) {
      onClick && onClick()
      closeAfterClick && closeAfterClick()
    }
  }
  return (
    <>
      <button
        className={cls(styles.root, { [styles.disabled]: disabled })}
        onClick={onMenuItemClick}
      >
        {shouldShowInnerIcon && innerIconPosition === 'left' && <ChevronLeftIcon />}
        {icon && <div className={styles.icon}>{icon}</div>}
        <span className={styles.content}>{content}</span>
        {shouldShowInnerIcon && innerIconPosition === 'right' && (
          <ChevronRightIcon className={styles.innerIconRight} />
        )}
        {loading && <Spinner />}
      </button>
    </>
  )
}
