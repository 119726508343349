import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { State } from 'redux/rootReducer'
import { ChatPin } from 'App/components'
import {
  createLoadingSelector,
  GET_CHAT_PIN,
  getChatPinRequest,
  removeAllChatPinMessagesRequest,
  REMOVE_ALL_CHAT_PIN_MESSAGES,
  createProcessingSelector
} from 'store'

export type TChatPinContainerBaseProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const loadingSelector = createLoadingSelector([GET_CHAT_PIN])
const processingSelector = createProcessingSelector([REMOVE_ALL_CHAT_PIN_MESSAGES])
const mapStateToProps = (state: State) => ({
  selectedChat: state.chat.chatRooms.selectedChat,
  data: state.chat.chatPin.data,
  total: state.chat.chatPin.total,
  loading: loadingSelector(state),
  processing: processingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getChatPin: getChatPinRequest,
      removeAllPin: removeAllChatPinMessagesRequest
    },
    dispatch
  )

export const ChatPinContainer = connect(mapStateToProps, mapDispatchToProps)(ChatPin)
