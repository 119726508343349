import { ChatMuteEnum } from '@medentee/enums'

import { EHistoryType } from 'globalConstants'
import { IChatRoomsDTO, ICreateGroupChatDTO } from 'interfaces'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { TChatBaseMessage, TChatMember, TIds } from 'store'
import { PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { QueryBuilder } from 'utils'

export const markAsRead = async (chatId: string) => await api.post(API.MARK_AS_READ(chatId))

type TMuteTextChannelPayload = {
  channelId: string
  muteUntil: ChatMuteEnum
}

export const muteTextChat = async ({ channelId, muteUntil }: TMuteTextChannelPayload) =>
  await api.post(API.MUTE_TEXT_CHAT(channelId), { muteUntil })

export const unmuteTextChat = async (channelId: string) =>
  await api.post(API.UNMUTE_TEXT_CHAT(channelId))

export const getAudioMessageFileUrl = async (chatId: string, audioFileId: string) => {
  const { data }: APIData<string> = await api.get(API.CHAT_MESSAGE_AUDIO(chatId, audioFileId))

  return data
}

type TCreateGroupChatPayload = {
  name: string
  accountIds: TIds

  file?: File | null
}

export const createGroupeChat = async ({ name, accountIds, file }: TCreateGroupChatPayload) => {
  const formData = new FormData()

  formData.append('name', name)

  if (file) {
    formData.append('file', file)
  }

  if (accountIds.length) {
    accountIds.forEach((id) => {
      formData.append('accountIds[]', id)
    })
  }

  const { data }: APIData<ICreateGroupChatDTO> = await api.post(API.CREATE_GROUP_CHAT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return data
}

type TUpdateGroupChatPayload = {
  name: string
  setAccountIds: TIds
  unsetAccountIds: TIds
  chatId: string

  file?: File | null
}

export const updateGroupeChat = async ({
  chatId,
  name,
  setAccountIds,
  unsetAccountIds,
  file
}: TUpdateGroupChatPayload) => {
  const formData = new FormData()

  formData.append('name', name)

  if (file) {
    formData.append('file', file)
  }

  if (setAccountIds.length) {
    setAccountIds.forEach((id) => {
      formData.append('setAccountIds[]', id)
    })
  }

  if (unsetAccountIds.length) {
    unsetAccountIds.forEach((id) => {
      formData.append('unsetAccountIds[]', id)
    })
  }

  const { data }: APIData<ICreateGroupChatDTO> = await api.patch(
    API.UPDATE_GROUP_CHAT(chatId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )

  return data
}

type TGetChatMembersPayload = {
  chatId: string

  page?: number
  showBy?: number
  search?: string
}

export const getChatMembers = async ({
  page,
  chatId,
  search,
  showBy = PAGINATION_DEFAULT_SHOW_BY
}: TGetChatMembersPayload) => {
  const url = new QueryBuilder(API.CHAT_MEMBERS(chatId))
    .page(page)
    .showBy(showBy)
    .searchQuery(search)
    .build()
  const { data }: APIResultsResponse<TChatMember[]> = await api.get(url)

  return data
}

type TRemoveGroupChatAvatarPayload = {
  chatId: string
}

export const removeGroupChatAvatar = async ({ chatId }: TRemoveGroupChatAvatarPayload) => {
  const response = await api.delete(API.GROUP_CHAT_AVATAR(chatId))

  return response
}

export const leaveGroupChat = async (chatId: string) => {
  const response = await api.delete(API.LEAVE_GROUP_CHAT(chatId))

  return response
}

export const getChatRoomById = async (chatId: string) => {
  const { data }: APIData<IChatRoomsDTO> = await api.get(API.CHAT_ROOM(chatId))

  return data
}

export type TGetChatRoomMessagesPayload = {
  chatId: string
  limit?: number
  historyType?: EHistoryType
  messageId?: string
  onlyPinned?: boolean
  searchQuery?: string
}

export const getChatRoomMessages = async ({
  chatId,
  historyType,
  limit,
  messageId,
  onlyPinned,
  searchQuery
}: TGetChatRoomMessagesPayload) => {
  const url = new QueryBuilder(API.CHAT_MESSAGES(chatId))
    .searchQuery(searchQuery)
    .custom('limit', limit)
    .custom('onlyPinned', onlyPinned)
    .custom(historyType, messageId)
    .build()

  const { data }: APIResultsResponse<TChatBaseMessage[]> = await api.get(url)

  return data
}
