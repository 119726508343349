import { getMapComponent } from 'utils'
import { TCommentProps, TruncatedText } from 'App/components'
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg'

import styles from './EventBar.module.scss'

export type TEventBarProps = Partial<Pick<TCommentProps, 'author'>> & {
  type: 'edit' | 'reply'
  close: () => void
}

const LABEL_MAP = new Map<
  TEventBarProps['type'],
  (props: Pick<TEventBarProps, 'author'>) => JSX.Element
>()
  .set('edit', () => <span>Edit</span>)
  .set('reply', ({ author }) => (
    <div className={styles.reply}>
      <span className={styles.label}>Reply to</span>
      <div className={styles.user}>
        <TruncatedText text={`@${author?.displayUserName}`} />
      </div>
    </div>
  ))

export const EventBar = ({ type, author, close }: TEventBarProps) => (
  <div className={styles.root}>
    {getMapComponent(LABEL_MAP, type, { author })}

    <CrossIcon className={styles.cross} onClick={close} />
  </div>
)
