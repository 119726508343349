import { Skeleton } from 'antd'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'services/query'
import { getEvent } from 'api/events'
import { ChipList } from 'App/components/common'

import styles from './EventProfileEditInterestsChipList.module.scss'

export type TEventProfileEditInterestsChipListProps = {
  eventId: string
  loading: boolean
}

export const EventProfileEditInterestsChipList = ({
  eventId,
  loading
}: TEventProfileEditInterestsChipListProps) => {
  const { t } = useTranslation()

  const { data = [], isFetching } = useQuery({
    queryKey: ['profile-get-event-data'],
    queryFn: () => getEvent(eventId),
    select: (response) =>
      response?.interests
        .filter((interest) => interest.enabled)
        .map(({ id, name }) => ({ id, name }))
  })

  return data.length ? (
    <Skeleton
      loading={loading || isFetching}
      active={true}
      title={false}
      className={styles.skeleton}
      paragraph={{ rows: 3 }}
    >
      <Field name="interests">
        {({ input }) => (
          <div className={styles.wrapper}>
            <span className={styles.label}>{t('events.userProfile.fields.interests')}</span>

            <ChipList
              editable
              removable={false}
              title={t('events.userProfile.fields.interestsPopoverTitle')}
              selected={input.value ?? []}
              items={data}
              onChange={input.onChange}
              loading={isFetching}
              placement="bottomLeft"
              className={styles.chipList}
              align={{ offset: [0, 0] }}
              popoverContentClass={styles.popoverContent}
            />
          </div>
        )}
      </Field>
    </Skeleton>
  ) : null
}
