import { put, takeLatest, call, select } from 'redux-saga/effects'
import { AccountTypeNames, BusinessAccountStatusEnum, ErrorCodesEnum } from '@medentee/enums'

import { IInvitationByEmailDTO, IInvitationsCountDTO } from 'interfaces'
import { API, api, APIData, APIResultsResponse } from 'services/api'
import { INVITATION_TYPE_MAP } from 'globalConstants'
import { QueryBuilder, isMatchErrorCode } from 'utils'
import {
  GET_CONTACTS_SUCCESS,
  CASE_MEMBER_INVITE_REJECT_SUCCESS,
  CASE_MEMBER_INVITE_APPROVE_SUCCESS,
  TGetInvitationsNewUsersRequest,
  TRemoveNewUsersInviteRequest,
  TDeleteBusinessAdminInviteRequest,
  getOrganizationRegisteredUsersRequest,
  getOrganizationsInvitationsRequest,
  getOrganizationAdministrationsRequest
} from 'store'
import { Pagination, PAGINATION_DEFAULT_SHOW_BY } from 'types'
import { State } from 'redux/rootReducer'
import { hideModalAction } from 'store/modal'
import { getBusinessUsersRequest } from 'store/administration'
import {
  getOrganizationNewUsersRequest,
  getOrganizationsInvitationsFiltersSelector,
  TOrganizationsInvitationsFilters,
  getOrganizationsRequest
} from 'store/organizations'
import { EBusinessAdminConfirmFrom } from 'enums'
import { handleError, handleWarning } from 'api/utils'

import {
  TApproveBusinessAdminInviteRequest,
  TRemoveNewUsersInviteSource
} from './invitations.types'
import { invitationsNewUsersNormalize } from './invitations.normalization'
import {
  GET_INVITATIONS_REQUEST,
  getInvitationsSuccess,
  getInvitationsError,
  GET_INVITATIONS_NEW_USERS_REQUEST,
  getInvitationsNewUsersSuccess,
  getInvitationsNewUsersError,
  REMOVE_NEW_USERS_INVITE_REQUEST,
  removeNewUsersInviteSuccess,
  removeNewUsersInviteError,
  getInvitationsNewUsersRequest,
  getInvitationsRequest,
  REMOVE_BUSINESS_ADMIN_INVITE_REQUEST,
  deleteBusinessAdminInviteSuccess,
  deleteBusinessAdminInviteError,
  APPROVE_BUSINESS_ADMIN_INVITE_REQUEST,
  approveBusinessAdminInviteSuccess,
  approveBusinessAdminInviteError,
  WITHDRAW_NEW_USERS_INVITE_REQUEST,
  withdrawNewUsersInviteError,
  withdrawNewUsersInviteSuccess
} from './invitations.actions'

function* invitations() {
  try {
    const { data }: APIData<IInvitationsCountDTO> = yield call(api.get, API.INVITATIONS_COUNT)

    yield put(getInvitationsSuccess(data))
  } catch (e) {
    yield put(getInvitationsError(e))
    yield handleError(e)
  }
}

function* onSuccessRemoveNewUsersInvite(
  source: TRemoveNewUsersInviteSource,
  invitationType?: AccountTypeNames
) {
  if (source === 'invitations') {
    const { current, showBy }: Pagination = yield select(
      (state: State) => state.invitations.sent.newUsers.pagination
    )
    yield put(getInvitationsRequest())
    yield put(getInvitationsNewUsersRequest({ page: current, showBy, invitationType }))
  }

  if (source === 'organizations') {
    const { showBy, current }: TOrganizationsInvitationsFilters = yield select(
      getOrganizationsInvitationsFiltersSelector
    )

    yield put(getOrganizationNewUsersRequest({ current, showBy }))
  }
}

function* removeNewUsersInvite({
  payload: { token, source, contactTypeName }
}: TRemoveNewUsersInviteRequest) {
  try {
    yield call(api.delete, API.INVITATIONS_BY_EMAIL_HIDE(token))
    yield put(removeNewUsersInviteSuccess({ processingId: token }))

    yield call(onSuccessRemoveNewUsersInvite, source, contactTypeName)
  } catch (e) {
    yield put(removeNewUsersInviteError({ ...e, processingId: token }))
    yield handleError(e)
  }
}

function* withdrawNewUsersInvite({
  payload: { token, source, contactTypeName }
}: TRemoveNewUsersInviteRequest) {
  try {
    yield call(api.delete, API.INVITATIONS_BY_EMAIL_WITHDRAW(token))
    yield put(withdrawNewUsersInviteSuccess({ processingId: token }))

    yield call(onSuccessRemoveNewUsersInvite, source, contactTypeName)
  } catch (e) {
    yield put(withdrawNewUsersInviteError({ ...e, processingId: token }))
    yield handleError(e)
  }
}

function* invitationsNewUsers({
  payload: {
    page,
    invitationType = AccountTypeNames.PROFESSIONAL,
    showBy = PAGINATION_DEFAULT_SHOW_BY
  }
}: TGetInvitationsNewUsersRequest) {
  try {
    const types = INVITATION_TYPE_MAP.get(invitationType)

    const url = new QueryBuilder(API.INVITATIONS_BY_EMAIL)
      .page(page)
      .showBy(showBy)
      .multiSearch('types', types ? [types] : undefined)
      .build()

    const { data }: APIResultsResponse<IInvitationByEmailDTO[]> = yield call(api.get, url)

    yield put(
      getInvitationsNewUsersSuccess({
        ...invitationsNewUsersNormalize(data.results),
        pagination: {
          current: page,
          showBy,
          total: data.total
        }
      })
    )
  } catch (e) {
    yield put(getInvitationsNewUsersError(e))
    yield handleError(e)
  }
}
function* approveBusinessAdminInvite({
  payload: { id, from, organizationId, direction }
}: TApproveBusinessAdminInviteRequest) {
  try {
    yield call(api.patch, API.BUSINESS_USERS_ID(id))

    yield put(approveBusinessAdminInviteSuccess({ processingId: id }))

    switch (from) {
      case EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION: {
        yield put(getOrganizationsInvitationsRequest({ from, organizationId, direction }))
        yield put(getOrganizationsRequest())
        break
      }

      case EBusinessAdminConfirmFrom.STAFF_RECEIVED: {
        yield put(getOrganizationsInvitationsRequest({ from, direction }))
        yield put(getOrganizationsRequest())
        break
      }

      case EBusinessAdminConfirmFrom.STAFF_REQUESTS: {
        yield put(
          getOrganizationRegisteredUsersRequest({
            statuses: [BusinessAccountStatusEnum.PENDING],
            direction
          })
        )
        break
      }

      default:
        break
    }
  } catch (e) {
    yield put(approveBusinessAdminInviteError({ ...e, processingId: id }))

    if (isMatchErrorCode(e, ErrorCodesEnum.NO_BUSINESS_USER_INVITE)) {
      yield from === EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION &&
        put(getOrganizationsInvitationsRequest({ from, organizationId, direction }))

      yield from === EBusinessAdminConfirmFrom.STAFF_RECEIVED &&
        put(getOrganizationsInvitationsRequest({ from, direction }))

      yield from === EBusinessAdminConfirmFrom.STAFF_REQUESTS &&
        put(
          getOrganizationRegisteredUsersRequest({
            statuses: [BusinessAccountStatusEnum.PENDING],
            direction
          })
        )
    }

    yield isMatchErrorCode(e, ErrorCodesEnum.MAX_BUSINESS_ACCOUNT_PARTICIPATIONS_APPROVE_EXCEEDED)
      ? handleWarning(e)
      : handleError(e)
  }
}

/**
 * Remove the users business, as well as withdraw or reject the invitation.
 */
function* deleteBusinessAdminInvite({
  payload: { id, from, organizationId, direction, onlyHidden = false, onlyDowngrade = false }
}: TDeleteBusinessAdminInviteRequest) {
  try {
    const url = new QueryBuilder(API.BUSINESS_USERS_ID(id))
      .stringify({ onlyHidden, onlyDowngrade })
      .build()

    yield call(api.delete, url)
    yield put(deleteBusinessAdminInviteSuccess({ processingId: id }))

    switch (from) {
      case EBusinessAdminConfirmFrom.SENT: {
        yield put(getInvitationsRequest())
        yield put(getOrganizationAdministrationsRequest())
        if (!onlyHidden) {
          yield put(hideModalAction())
        }

        break
      }
      case EBusinessAdminConfirmFrom.ADMINISTRATION: {
        yield put(
          getBusinessUsersRequest({
            statuses: [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
          })
        )
        yield put(hideModalAction())

        break
      }

      case EBusinessAdminConfirmFrom.ORGANIZATION_REGISTERED: {
        yield put(getOrganizationRegisteredUsersRequest({ direction }))
        if (!onlyHidden) {
          yield put(hideModalAction())
        }
        break
      }

      case EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION:
      case EBusinessAdminConfirmFrom.STAFF_RECEIVED: {
        yield put(getOrganizationsInvitationsRequest({ from, organizationId, direction }))
        yield put(hideModalAction())

        break
      }

      case EBusinessAdminConfirmFrom.STAFF_SENT: {
        yield put(getOrganizationRegisteredUsersRequest({ direction }))
        yield put(hideModalAction())
        break
      }

      case EBusinessAdminConfirmFrom.STAFF_REQUESTS: {
        yield put(
          getOrganizationRegisteredUsersRequest({
            statuses: [BusinessAccountStatusEnum.PENDING],
            direction
          })
        )
        yield put(hideModalAction())
        break
      }

      default:
        break
    }
  } catch (e) {
    yield put(deleteBusinessAdminInviteError({ ...e, processingId: id }))

    if (isMatchErrorCode(e, ErrorCodesEnum.NO_BUSINESS_USER_INVITE)) {
      yield from === EBusinessAdminConfirmFrom.ADMINISTRATION &&
        put(
          getBusinessUsersRequest({
            statuses: [BusinessAccountStatusEnum.APPROVED, BusinessAccountStatusEnum.PENDING]
          })
        )

      yield (from === EBusinessAdminConfirmFrom.ORGANIZATION_RECEIVED_ADMINISTRATION ||
        from === EBusinessAdminConfirmFrom.STAFF_RECEIVED) &&
        put(getOrganizationsInvitationsRequest({ from, organizationId, direction }))

      yield from === EBusinessAdminConfirmFrom.ORGANIZATION_REGISTERED &&
        put(getOrganizationRegisteredUsersRequest({ direction }))

      yield put(hideModalAction())
    }

    if (onlyHidden) {
      yield handleError(e)
    }
  }
}

export function* invitationsSaga() {
  yield takeLatest(
    [
      GET_INVITATIONS_REQUEST,
      GET_CONTACTS_SUCCESS,
      CASE_MEMBER_INVITE_REJECT_SUCCESS,
      CASE_MEMBER_INVITE_APPROVE_SUCCESS
    ],
    invitations
  )
  yield takeLatest(GET_INVITATIONS_NEW_USERS_REQUEST, invitationsNewUsers)
  yield takeLatest(REMOVE_NEW_USERS_INVITE_REQUEST, removeNewUsersInvite)
  yield takeLatest(WITHDRAW_NEW_USERS_INVITE_REQUEST, withdrawNewUsersInvite)
  yield takeLatest(REMOVE_BUSINESS_ADMIN_INVITE_REQUEST, deleteBusinessAdminInvite)
  yield takeLatest(APPROVE_BUSINESS_ADMIN_INVITE_REQUEST, approveBusinessAdminInvite)
}
