import { State } from 'redux/rootReducer'

import { BROADCAST_MESSAGE } from './broadcast.actionTypes'

export const getBroadcastSelectedAccountsSelector = (state: State) =>
  state.chat.broadcast.recipients

export const getBroadcastSelectedCategoriesSelector = (state: State) =>
  state.chat.broadcast.categories

export const getBroadcastSelectedDepartmentsSelector = (state: State) =>
  state.chat.broadcast.departments

export const getBroadcastSelectedOrganizationsSelector = (state: State) =>
  state.chat.broadcast.organizations

export const isBroadcastSelectedFilesMoreSelector = (count: number) => (state: State) =>
  state.chat.broadcast.selectedFiles.ids.length > count

export const getBroadcastSelectedRestFilesSelector = (count: number) => (state: State) =>
  state.chat.broadcast.selectedFiles.ids.length - count || 0

export const getBroadcastSelectedFilesIdsSelector = (state: State) =>
  state.chat.broadcast.selectedFiles.ids
export const getBroadcastSelectedFilesListSelector = (state: State) =>
  state.chat.broadcast.selectedFiles.list
export const getBroadcastSelectedFilesIdsByCountSelector = (count: number) => (state: State) =>
  state.chat.broadcast.selectedFiles.ids.slice(0, count)
export const getBroadcastSelectedFilesListByIdSelector = (id: string) => (state: State) =>
  state.chat.broadcast.selectedFiles.list[id] ?? {}

export const getBroadcastSelectedSelector = (state: State) => state.chat.broadcast.recipients

export const getBroadcastAudioBlobSelector = (state: State) => state.chat.broadcast.audioBlob
export const getBroadcastAudioUrlSelector = (state: State) => state.chat.broadcast.audioUrl

export const getBroadcastRecipientIdsSelector = (state: State) =>
  state.chat.broadcast.recipients.map(({ id }) => id)

export const getBroadcastDepartmentIdsSelector = (state: State) =>
  state.chat.broadcast.departments.map(({ id }) => id)

export const getBroadcastCategoryIdsSelector = (state: State) =>
  state.chat.broadcast.categories.map(({ id }) => id)

export const getBroadcastOrganizationIdsSelector = (state: State) =>
  state.chat.broadcast.organizations.map(({ id }) => id)

export const getBroadcastMessageErrorSelector = (state: State) => state.errors[BROADCAST_MESSAGE]

export const getBroadcastSelectedRecipientGroupsSelector = (state: State) => {
  const { categories, departments, organizations } = state.chat.broadcast

  return [...categories, ...departments, ...organizations]
}
