import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Help, Switch } from 'App/components'
import { TCasesAutoApproveContainerProps } from 'App/containers'

import styles from './CasesAutoApprove.module.scss'

export type TCasesAutoApproveProps = TCasesAutoApproveContainerProps

export const CasesAutoApprove = ({
  id,
  caseAutoApproveMode,
  setCasesAutoApprove
}: TCasesAutoApproveProps) => {
  const { t } = useTranslation()

  const handleSwitch = useCallback(
    (checked) => setCasesAutoApprove({ id, currentCaseAutoApproveStatus: checked }),
    [id, setCasesAutoApprove]
  )

  return (
    <div className={styles.root}>
      <span className={styles.text}>
        <Trans
          t={t}
          i18nKey="cases.details.attachments.autoApprove.label"
          components={{
            help: <Help content={t('cases.details.attachments.autoApprove.help')} />
          }}
        />
      </span>
      <Switch defaultChecked={caseAutoApproveMode} onChange={handleSwitch} />
    </div>
  )
}
