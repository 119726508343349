import { Link } from 'react-router-dom'

import { Breadcrumbs, BreadcrumbsItem } from 'App/components/common/Breadcrumbs'

export type TUserProfileBreadcrumbsProps = {
  accountName: string
  path: string
  linkName: string
}

export const UserProfileBreadcrumbs = ({
  accountName,
  path,
  linkName
}: TUserProfileBreadcrumbsProps) => (
  <Breadcrumbs>
    <BreadcrumbsItem>
      <Link to={path}>{linkName}</Link>
    </BreadcrumbsItem>
    <BreadcrumbsItem>{accountName}</BreadcrumbsItem>
  </Breadcrumbs>
)
