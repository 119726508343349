import { useTranslation } from 'react-i18next'

import { EModalComponents, TCaseAutoApproveContainerProps } from 'App/containers'
import { Button, SettingsItemTitle, SettingsItemDescription } from 'App/components'

import styles from './CaseAutoApprove.module.scss'

export type TCaseAutoApproveProps = TCaseAutoApproveContainerProps

export const CaseAutoApprove = ({ showModal }: TCaseAutoApproveProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    showModal({
      modalType: EModalComponents.SETTINGS_CASE_AUTO_APPROVE,
      modalTitle: t('modal.caseAutoApprove.title')
    })
  }

  return (
    <>
      <SettingsItemTitle>{t('settings.notifications.caseAutoApprove.title')}</SettingsItemTitle>

      <SettingsItemDescription>
        {t('settings.notifications.caseAutoApprove.description')}
      </SettingsItemDescription>

      <Button onClick={handleClick} variant="outlined" className={styles.button}>
        {t('settings.notifications.caseAutoApprove.setUpButton')}
      </Button>
    </>
  )
}
