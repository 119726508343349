import { useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AccountTypeNames, CaseStatusesEnum, ChatStatusEnum, ChatTypeEnum } from '@medentee/enums'
import { useRouteMatch } from 'react-router-dom'

import { State } from 'redux/rootReducer'
import {
  endMessageEditingRequest,
  quoteMessageCancel,
  sendNewAudioMessageRequest,
  sendNewMessageRequest,
  sendTypingDoneRequest,
  sendTypingRequest,
  showModalAction,
  updateChatDraftRequest,
  getChatDraftRequest,
  GET_CHAT_DRAFT,
  redirectToCaseFromChat,
  TCaseParams,
  GET_CHAT_ROOM
} from 'store'
import { ChatFieldCase, ChatFieldP2P } from 'App/components'
import { createLoadingSelector } from 'store/loading'
import { useChatContext } from 'App/context'
import { getMapComponent } from 'utils'
import { EChatViewType } from 'enums'

export type TChatFieldContainerBaseProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TChatFieldContainerProps = Omit<TChatFieldContainerBaseProps, 'getChatDraft'> & {
  entityId?: string
}

const loadingSelector = createLoadingSelector([GET_CHAT_DRAFT, GET_CHAT_ROOM])

const mapStateToProps = (state: State) => {
  const {
    case: chatCase,
    unreadCount,
    status,
    isContact,
    interlocutorAccount,
    type: chatType
  } = state.chat.chatRooms.selectedChat ?? {}

  const activeChatId = state.chat.chatRooms.activeChatId

  const {
    actions: { message, type },
    draft
  } = state.chat.chatMessages

  const accountType = state.global.accountData?.type.name

  return {
    readAll: !!unreadCount,
    accountType: accountType || AccountTypeNames.PROFESSIONAL,
    actionMessage: message,
    actionType: type,
    isContact,
    chatType,
    isDormant:
      status !== ChatStatusEnum.ACTIVE &&
      ((interlocutorAccount?.type.name === AccountTypeNames.BUSINESS &&
        accountType === AccountTypeNames.BUSINESS) ||
        (interlocutorAccount?.type.name === AccountTypeNames.PROFESSIONAL &&
          accountType === AccountTypeNames.PROFESSIONAL)),
    chatStatus: status,
    chatId: activeChatId,
    caseId: chatCase?.id,
    ownerId: chatCase?.owner?.id ?? '',
    receiverId: interlocutorAccount?.id,
    receiverAccountType: interlocutorAccount?.type.name,
    isLockedCase: chatCase?.status === CaseStatusesEnum.LOCKED,
    draftMessage: draft.message,
    inactive: chatCase?.status === CaseStatusesEnum.LOCKED || status !== ChatStatusEnum.ACTIVE,
    loading: loadingSelector(state),
    isCoworker: interlocutorAccount?.isCoworker,
    organizationId: state.organizations.currentOrganizationId,
    mentionedAccounts: draft.mentionedAccounts,
    mentionedDepartments: draft.mentionedDepartments,
    channelManagerId: state.chat.chatRooms.selectedChat?.channelManager?.id
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendNewMessage: sendNewMessageRequest,
      sendTypingStart: sendTypingRequest,
      sendTypingDone: sendTypingDoneRequest,
      sendNewAudioMessage: sendNewAudioMessageRequest,
      endMessageEditing: endMessageEditingRequest,
      cancelQuoting: quoteMessageCancel,
      showModal: showModalAction,
      updateChatDraft: updateChatDraftRequest,
      getChatDraft: getChatDraftRequest,
      redirectToCaseFromChat
    },
    dispatch
  )

const CHAT_FIELD = new Map<EChatViewType, (props: TChatFieldContainerProps) => JSX.Element | null>()
  .set(EChatViewType.CASE, (props) => <ChatFieldCase {...props} />)
  .set(EChatViewType.P2P, (props) => <ChatFieldP2P {...props} />)

const ChatFieldView = ({
  isContact,
  isCoworker,
  chatStatus,
  chatId,
  chatType,
  getChatDraft,
  ...props
}: TChatFieldContainerBaseProps) => {
  const { chatViewType } = useChatContext()

  const {
    params: { id: caseId }
  } = useRouteMatch<TCaseParams>()

  const isDraftAvailable =
    isContact ||
    isCoworker ||
    chatType === ChatTypeEnum.CASE_GROUP ||
    chatType === ChatTypeEnum.ORGANIZATION ||
    chatType === ChatTypeEnum.COMMUNITY_CHANNEL ||
    chatType === ChatTypeEnum.COMMUNITY_NEWS ||
    chatType === ChatTypeEnum.EVENT_CHANNEL ||
    chatType === ChatTypeEnum.EVENT_NEWS ||
    chatType === ChatTypeEnum.GROUP

  useEffect(() => {
    if (chatId && isDraftAvailable) {
      getChatDraft({ chatId })
    }
  }, [getChatDraft, chatId, isDraftAvailable])

  return getMapComponent(CHAT_FIELD, chatViewType, {
    ...props,
    caseId: props.caseId ?? caseId,
    chatType,
    chatId,
    isContact,
    isCoworker,
    chatStatus
  })
}

export const ChatFieldContainer = connect(mapStateToProps, mapDispatchToProps)(ChatFieldView)
