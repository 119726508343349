import { useMemo } from 'react'
import { Row } from 'antd'

import { AvatarContainer } from 'App/containers'
import { EAvatarSize } from 'enums'
import { TAccount, useAppSelector } from 'store'

import styles from './ChatMessageReactionCountAvatars.module.scss'

export type TChatMessageReactionCountAvatarsProps = {
  messageId: string
  reaction: string
}

export const ChatMessageReactionCountAvatars = ({
  messageId,
  reaction
}: TChatMessageReactionCountAvatarsProps) => {
  const accountsList = useAppSelector((state) => state.chat.chatMessages.reactions.accountList)
  const accountIds = useAppSelector(
    (state) => state.chat.chatMessages.reactions.messageList[messageId]?.list[reaction]
  )

  const accounts = useMemo(() => {
    const result: TAccount[] = []

    accountIds.forEach((accountId) => {
      result.push(accountsList[accountId])
    })

    return result
  }, [accountIds, accountsList])

  return (
    <Row align="middle" wrap={false}>
      {accounts.map(({ id, firstName, lastName, displayUserName, type }) => (
        <AvatarContainer
          key={id}
          userId={id}
          size={EAvatarSize.XXS}
          displayUserName={displayUserName}
          firstName={firstName}
          lastName={lastName}
          type={type.name}
          hasTooltip={false}
          border={true}
          className={styles.avatar}
          showOnline={false}
        />
      ))}
    </Row>
  )
}
