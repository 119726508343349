import { AccountTypeNames, ChatTypeEnum } from '@medentee/enums'
import cls from 'classnames'

import { EAvatarSize } from 'enums'
import { API } from 'services/api'
import { ReactComponent as CameraIcon } from 'assets/icons/Camera.svg'
import { Image } from 'App/components/common/Image'
import { AvatarInitials } from 'App/components/common/Avatar/AvatarInitials'
import { AvatarWrapper } from 'App/components/common/Avatar/AvatarWrapper'
import { TAvatarProps as OriginalAvatarProps } from 'App/components/common/Avatar'

import styles from './Avatar.module.scss'

export type TAvatarProps = {
  file?: File | null
  className?: string
  showEditPreview?: boolean
} & PartialBy<
  Pick<
    OriginalAvatarProps,
    | 'onLoad'
    | 'onError'
    | 'userId'
    | 'firstName'
    | 'lastName'
    | 'displayUserName'
    | 'type'
    | 'chatType'
  >,
  'userId' | 'firstName' | 'lastName'
>

const getSrc = (userId = '', chatType?: ChatTypeEnum, file?: File | null | string) => {
  if (file instanceof File) {
    return URL.createObjectURL(file)
  }

  if (file) {
    return file
  }

  if (!userId) {
    return null
  }

  return chatType === ChatTypeEnum.GROUP
    ? API.GET_CHAT_GROUP_AVATAR_URL(userId)
    : API.GET_AVATAR_URL(userId)
}

export const Avatar = ({
  userId,
  chatType,
  file,
  showEditPreview,
  onLoad,
  onError,
  type = AccountTypeNames.PROFESSIONAL,
  displayUserName = '',
  firstName = '',
  lastName = '',
  className
}: TAvatarProps) => {
  const shouldShowEditPreview = showEditPreview && !userId && !file

  return (
    <AvatarWrapper
      size={EAvatarSize.LG}
      color={chatType ?? type}
      className={cls(shouldShowEditPreview && styles.placeholderImgWrapper, className)}
    >
      {shouldShowEditPreview ? (
        <CameraIcon className={styles.placeholderImg} />
      ) : (
        <Image
          hidden={!userId && !file}
          src={getSrc(userId, chatType, file)}
          alt="avatar"
          placeholder={
            <AvatarInitials
              displayUserName={displayUserName}
              firstName={firstName}
              lastName={lastName}
              type={type}
              chatType={chatType}
            />
          }
          onLoad={onLoad}
          onError={onError}
        />
      )}

      <div className={styles.cameraIcon}>
        <CameraIcon />
      </div>
    </AvatarWrapper>
  )
}
